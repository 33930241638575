import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined } from "@ant-design/icons";
import { Card, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin } from "antd";

import moment from "moment";
import axios from "configs/axios";


class WS2677012_CaptureResult extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    warning: PropTypes.any,
    Li_KekkaNumFiles: PropTypes.any,
    Li_ErrorNumFiles: PropTypes.any,
    Li_QualificationsThereNum: PropTypes.any,
    Li_QualificationWithoutNum: PropTypes.any,
    Li_ErrorCount: PropTypes.any,
    Li_ReserveNonGetNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = '取込結果表示';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      initParams: {
        warning: '',
        Li_KekkaNumFiles: '',
        Li_ErrorNumFiles: '',
        Li_QualificationsThereNum: '',
        Li_QualificationWithoutNum: '',
        Li_ErrorCount: '',
        Li_ReserveNonGetNum: '',
      },

      isLoadingForm: false
    };
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onFinish(values) {
    this.closeModal();
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      Li_warning: this.props.Li_warning ? this.props.Li_warning : '',
      Li_KekkaNumFiles: this.props.Li_KekkaNumFiles ? this.props.Li_KekkaNumFiles : '',
      Li_ErrorNumFiles: this.props.Li_ErrorNumFiles ? this.props.Li_ErrorNumFiles : '',
      Li_QualificationsThereNum: this.props.Li_QualificationsThereNum ? this.props.Li_QualificationsThereNum : '',
      Li_QualificationWithoutNum: this.props.Li_QualificationWithoutNum ? this.props.Li_QualificationWithoutNum : '',
      Li_ErrorCount: this.props.Li_ErrorCount ? this.props.Li_ErrorCount : '',
      Li_ReserveNonGetNum: this.props.Li_ReserveNonGetNum ? this.props.Li_ReserveNonGetNum : '',
    })
  };


  render() {
    return (
      <div className="capture-result">
        <Card title="取込結果表示">
          <div style={{ padding: '10px' }}>
            <Form ref={this.formRef} onFinish={this.onFinish} initialValues={this.state.initParams}>
              <Row>
                <Col span={24}>
                  {this.props.Li_warning ?
                    <Form.Item name="warning" label="" >
                      <Input type="text" readOnly style={{ border: 'none' }} />
                    </Form.Item>
                    : null}
                </Col>
              </Row>
              <Row>
                <Col span={2}></Col>
                <Col span={22}>
                  <Form.Item name="Li_KekkaNumFiles" label="資格結果ファイル件数">
                    <Input type="text" readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={2}></Col>
                <Col span={22}>
                  <Form.Item name="Li_ErrorNumFiles" label="エラーファイル件数&nbsp;&nbsp;&nbsp;&nbsp;">
                    <Input type="text" readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}></Col>
                <Col span={16}>
                  <Form.Item name="Li_QualificationsThereNum" label="資格あり件数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
                    <Input type="text" readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}></Col>
                <Col span={16}>
                  <Form.Item name="Li_QualificationWithoutNum" label="資格なし件数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
                    <Input type="text" readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}></Col>
                <Col span={16}>
                  <Form.Item name="Li_ErrorCount" label="エラー件数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
                    <Input type="text" readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}></Col>
                <Col span={16}>
                  <Form.Item name="Li_ReserveNonGetNum" label="予約取得不可件数">
                    <Input type="text" readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}></Col>
                <Col span={4}>
                  <Form.Item              >
                    <Button type="primary"
                      onClick={(e) => {
                        if (this.props.onFinishScreen) {
                          this.props.onFinishScreen({ test: '12345' });
                        }
                      }}
                    >OK</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2677012_CaptureResult);
