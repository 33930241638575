import React from 'react'
import { connect } from 'react-redux'
import { Card, Modal, Button, Input, Form, } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';

import InputVoteResultInputAction from 'redux/InputBusiness/InputVoteResultInput/InputVoteResultInput.action.js'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '80px' } }
}

class WS0738004_InputVoteResultInputItemSettingsEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const param = {
      ...this.props.record,
    }

    // Form設定
    this.formRef.current?.setFieldsValue(param)
  }

  /**
 * データ保存
 */
  save = () => {
    let param = {
      ...this.props.record,
      newFlag: this.props.newFlag,
      newSeqno: this.formRef.current?.getFieldValue('seqno'),
      newItem: this.formRef.current?.getFieldValue('item')
    }

    InputVoteResultInputAction.saveVoucherEdit(param)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }


  /**
   * データ削除
   */
  delete = () => {
    let param = {
      ...this.props.record
    }

    InputVoteResultInputAction.deleteVoucherEdit(param)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  render() {
    return (
      <div className='target-user-screen'>
        <Card
          title={'伝票 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='伝票コード'
                name='seqno'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  type="number"
                  min="0"
                  className="input-size-number-5"
                  disabled={!this.props.newFlag}
                />
              </Form.Item>

              <Form.Item
                label='伝票名'
                name='item'//対応したものに書き換え
                {...labelCol}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0738004_InputVoteResultInputItemSettingsEdit)
