import React from "react";
import { connect } from "react-redux";

import { Card, Row, Form, Select, Input, Button } from "antd";

const { Option } = Select;

const styleLabel = {
  textAlign: "right",
  paddingRight: 5,
  width: 75,
  color: '#14468C',
  fontWeight: 500
};

const styleRow = {
  margin: 0,
  width: '100%',
  display: 'flex'
}

const styleGroup = {
  display: 'flex'
}

class WS0801006_FormOutput extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '帳票出力';

    this.state = {

    };
  }
  
  confirmProc = () => {
    this.props.onFinishScreen() 
  }

  render() {
    return (
      <div className="form-output">
        <Card title="帳票出力" className="mb-2">
          <Row gutter={24} style={styleRow}>
            <label xl={9} style={styleLabel}>団体向</label>
            <Form.Item
              name="organization"
              initialValue={'普通'}
            >
              <Select style={{ width: 50 }}>
                <Option value="普通">普通</Option>
                <Option value="団体">団体</Option>
              </Select>
            </Form.Item>
          </Row>
          <Row gutter={24} style={styleRow}>
            <label xl={9} style={styleLabel}>帳票名</label>
            <Form.Item name="business_form" style={{ width: 200 }}>
              <Input placeholder="" style={{ width: '100%' }} />
            </Form.Item>
          </Row>
          <Row gutter={24} style={styleRow}>
            <label xl={9} style={styleLabel}>条件</label>
            <Form.Item name="condition">
              <Input placeholder="" style={{ width: 40 }} />
            </Form.Item>
          </Row>
          <Row gutter={24} style={styleRow}>
            <label xl={9} style={styleLabel}>用紙サイズ</label>
            <Form.Item
              name="paper_size"
              initialValue={'指定なし'}
            >
              <Select style={{ width: 200 }}>
                <Option value="指定なし">指定なし</Option>
              </Select>
            </Form.Item>
          </Row>
          <Row gutter={24} style={styleRow}>
            <label xl={9} style={styleLabel}>縦横</label>
            <Form.Item
              name="breathwise"
              initialValue={'指定なし'}
            >
              <Select style={{ width: 200 }}>
                <Option value="指定なし">指定なし</Option>
              </Select>
            </Form.Item>
          </Row>

          <Row gutter={24} style={styleRow}>
            <Form.Item
              style={{ marginLeft: 'auto' }}
            >
              <Button onClick={this.confirmProc} type="primary">出力</Button>
            </Form.Item>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0801006_FormOutput);
