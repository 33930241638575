import React from "react";
import {
  Card, Form, Input, Button,
} from "antd";
import BillingAddressSettingAction from "redux/basicInfo/ContractInfoMaintain/BillingAddressSetting.actions.js"
import NumberFormat from 'components/Commons/NumberFormat'

class WS0309501_BillingEditing extends React.Component {

  formRef = React.createRef();
  refCicContract = React.createRef();
  refCicOption = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "契約作成";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      isLoadingContractData: false,

      dataSource: [],
      columnList: [],
      contractTermData: {},
      setCodeList: [],

      total_price: 0,
      tax: 0,
      unit_price: 0,
      billing_limit: 0,
      serial_number: 0,
      contract_id: 0,
      data_division: 0
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      examName: this.props.examName,
      kanji_name: this.props.values.kanji_name,
      code: this.props.values.code,
      unit_price: this.props.values.unit_price ?? 0,
      tax: this.props.values.tax,
      total_price: this.props.values.total_price ?? 0,
      billing_limit: this.props.values.billing_limit ?? 0,
      data_division: this.props.data_division ?? 0
    })

    this.setState({
      unit_price: this.props.values.unit_price,
      tax: this.props.values.tax,
      total_price: this.props.values.total_price,
      billing_limit: this.props.values.billing_limit,
      setCodeList: this.props.setCodeList,
      serial_number: this.props.values.serial_number,
      contract_id: this.props.contract_id,
      data_division: this.props.data_division
    })
  }

  saveBillingAmount = () => {
    let params = {
      unit_price: this.formRef.current?.getFieldValue('unit_price'),
      tax: this.formRef.current?.getFieldValue('tax'),
      total_price: this.formRef.current?.getFieldValue('total_price'),
      billing_limit: this.formRef.current?.getFieldValue('billing_limit'),
      contract_type: this.props.contract_type,
      contract_office_code: this.props.contract_office_code,
      contract_start_date_on: this.props.contract_start_date_on,
      contract_number: this.props.contract_number,
      serial_number: this.state.serial_number,
      contract_id: this.state.contract_id,
      data_division: this.state.data_division,
      set_code: this.props.set_code,
      course_start_date_on: this.props.course_start_date_on,
      course_end_date_on: this.props.course_end_date_on
    }
    BillingAddressSettingAction.saveBillingAmount(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  savePersonalBillingAmount = () => {
    // let params = {
    //   reservation_number: this.props.reservation_number,
    //   code: this.props.values.code,
    //   unit_price: this.formRef.current?.getFieldValue('unit_price'),
    //   tax: this.formRef.current?.getFieldValue('tax'),
    //   total_price: this.formRef.current?.getFieldValue('total_price'),
    //   billing_limit: this.formRef.current?.getFieldValue('billing_limit'),
    //   contract_type: this.props.contract_type,
    //   contract_office_code: this.props.contract_office_code,
    //   contract_start_date_on: this.props.contract_start_date_on,
    //   contract_number: this.props.contract_number,
    //   medical_exam_course: this.props.medical_exam_course,
    //   billing_type: this.props.values.type,
    //   set_code: this.props.set_code,
    //   setCodeList: this.state.setCodeList,
    // }
    // BillingAddressSettingAction.savePersonalBillingAmount(params)
    //   .then((res) => {
    //     this.props.onFinishScreen()
    //   })
    this.props.onFinishScreen({
      // total_price: this.formRef.current?.getFieldValue('total_price'),
      // billing_limit: this.formRef.current?.getFieldValue('billing_limit'),
      total_price: Number(this.state.total_price),
      tax: this.state.tax,
      unit_price: this.state.unit_price,
      billing_limit: this.formRef.current?.getFieldValue('billing_limit')
    })
  }

  taxCalculation = (totalPrice) => {
    totalPrice = NumberFormat.changeHankaku(totalPrice);
    let taxRate = this.props.taxRate + 1
    let taxValue, unitPrice
    switch (this.props.rounding) {
      case 0:
        unitPrice = totalPrice / taxRate
        taxValue = Math.round(totalPrice - unitPrice)
        this.formRef.current?.setFieldsValue({
          total_price: totalPrice,
          tax: taxValue,
          unit_price: unitPrice
        })
        this.setState({
          total_price: totalPrice,
          tax: taxValue,
          unit_price: unitPrice
        })
        break;
      case 1:
        unitPrice = totalPrice / taxRate
        taxValue = Math.floor(totalPrice - unitPrice)
        this.formRef.current?.setFieldsValue({
          total_price: totalPrice,
          tax: taxValue,
          unit_price: unitPrice
        })
        this.setState({
          total_price: totalPrice,
          tax: taxValue,
          unit_price: unitPrice
        })
        break;
      case 2:
        unitPrice = totalPrice / taxRate
        taxValue = Math.ceil(totalPrice - unitPrice)
        this.formRef.current?.setFieldsValue({
          total_price: totalPrice,
          tax: taxValue,
          unit_price: unitPrice
        })
        this.setState({
          total_price: totalPrice,
          tax: taxValue,
          unit_price: unitPrice
        })
        break;
    }
  }

  render() {
    return (
      <div className="billing-editing">
        <Card title='請求額編集'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className="box_container">
              <Form.Item label='　検査名称' name='examName'>
                <div>{this.formRef.current?.getFieldValue('examName')}</div>
              </Form.Item>
              <Form.Item label='　団体名称' name='kanji_name'>
                <div>{this.formRef.current?.getFieldValue('kanji_name')}</div>
              </Form.Item>
              <Form.Item label='団体コード' name='code'>
                <div>{this.formRef.current?.getFieldValue('code')}</div>
              </Form.Item>
              <Form.Item label='　　合　計' name='total_price'>
                <Input
                  className="input-size-number-10"
                  onPressEnter={(e) => { this.taxCalculation(e.target.value) }}
                  onBlur={(e) => { this.taxCalculation(e.target.value) }}
                />
              </Form.Item>
              <Form.Item label='　　　　税' name='tax'>
                <Input className="input-size-number-10" disabled />
              </Form.Item>
              <Form.Item label='　　限度額' name='billing_limit'
                style={{ display: ((this.state.data_division !== 20) ? 'none' : '') }}
              >
                <Input
                  className="input-size-number-10"
                />
              </Form.Item>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  onClick={() => {
                    if (this.props.flag) {
                      //個人予約用
                      this.savePersonalBillingAmount()
                    } else {
                      //契約情報保守用
                      this.saveBillingAmount()
                    }
                  }}
                >
                  確定
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}
export default WS0309501_BillingEditing
