import React from "react";
import { connect } from "react-redux";

import { Card, Table, Tree, Row, Col, Form, Input, Dropdown, Button, Menu, Modal } from "antd";
import { MoreOutlined, CarryOutOutlined, PlusOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import { AgeManageInfoMaintainAction, getScreenData, subTitleTable, subAgeTable, loadDataAction } from "redux/SM_SystemMaintenance/AgeManageInfoMaintain/AgeManageInfoMaintain.action";
import WS1505010_CopyRegister from "./WS1505010_CopyRegister";
import WS1505011_AgeManageInfoMaintainSub from "./WS1505011_AgeManageInfoMaintainSub";

const treeRoot = {
  title: '年齢テーブル',
  key: 'Root',
  isParent: true,
  children: []
};
class WS1505001_AgeManageInfoMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '年齢管理情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedNodes: [],
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
    };
  }

  componentDidMount = () => {
    this.loadData()
  }

  loadData = (params) => {
    this.setState({ isLoadingPage: true });
    loadDataAction()
      .then(res => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }


  /*   copy_F7(record) {
      console.log(record);
      console.log(this.formRef.current?.getFieldValue('dataSubTitle'));
      let dataSubTitle = this.formRef.current?.getFieldValue('dataSubTitle')
      let age_title
      for (let i = 0; i < dataSubTitle.length; i++) {
        if (dataSubTitle[i].age_id_code === record.age_id_code) {
          age_title = dataSubTitle[i].age_title
        }
      }
      console.log(age_title);
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 550,
          component: (
            <WS1505010_CopyRegister
              record={record}
              age_title={age_title}
              onFinishScreen={(obj) => {
                this.closeModal();
              }}
            />
          ),
        },
      });
    }
   */

  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS1505011_AgeManageInfoMaintainSub
            Li_ageIdCode={record.age_id_code}
            record={record}
            newFlag={false}
            onFinishScreen={(newCode) => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS1505011_AgeManageInfoMaintainSub
            Li_ageIdCode={0}
            newFlag={true}
            newRegistrationFlag={true}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }
  reviewScreen() {
    this.loadData()
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="age-manage-info-maintain">
        <Card title="年齢管理情報保守" className="mb-2">
          <Form ref={this.formRef} >
            <Col span={18}>
              <Table bordered
                pagination={false}
                scroll={{ y: 500 }}
                dataSource={this.state.dataSource}
                loading={false}
                size='small'
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () =>
                    this.editForm(record)
                })}
              >
                <Table.Column width={120} title="識別コード" dataIndex="age_id_code" key="" />
                <Table.Column title="名称" dataIndex="age_title" key="" />
                <Table.Column width={40} align="center" title={<Button type="primary"
                  icon={<PlusOutlined />}
                  onClick={this.addForm}
                  size='small'
                ></Button>} render={(value, record, index) => (
                  <Button
                    size="small"
                    icon={<MoreOutlined />}
                    onClick={() => { this.editForm(record) }}
                  />
                )}
                />
              </Table>
            </Col>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1505001_AgeManageInfoMaintain);
