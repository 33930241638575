import { message } from 'antd'
import JudgeSelectQuerySubService from 'services/InputBusiness/RadiographyFindingInput/JudgeSelectQuerySubService'

const RadiographyFindingInputAction = {
  index(data) {
    return JudgeSelectQuerySubService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  }
}

export default RadiographyFindingInputAction
