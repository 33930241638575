import { message } from 'antd'
import AssociateInsureQualifyConfirmService from 'services/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirmService'

const AssociateInsureQualifyConfirm = {
  searchData(data) {
    return AssociateInsureQualifyConfirmService.searchData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  updateData(data) {
    return AssociateInsureQualifyConfirmService.updateData(data)
  }
}

export default AssociateInsureQualifyConfirm
