import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Table, Dropdown, Menu, message } from 'antd'
import { MoreOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import ModalAdd from 'components/Commons/ModalAdd'
import ModalChange from 'components/Commons/ModalChange'
import ModalDraggable from 'components/Commons/ModalDraggable'
import OptionSettingAction from 'redux/Others/OptionSetting/OptionSetting.actions.js'
import OptionSettingEdit from 'pages/ZZ_Others/OptionSetting/OptionSettingEdit.jsx'

import OptionSettingCsvCapture from "pages/ZZ_Others/OptionSetting/OptionSettingCsvCapture.jsx";

const columns = [
  {
    title: '種別',
    dataIndex: 'type_code',
    type: 1,
  },
  {
    title: 'コード',
    dataIndex: 'option_code',
    type: 1,
  },
  {
    title: '名称',
    dataIndex: 'id_name',
    type: 1,
  },
]

class OptionSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'オプション設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      isLoading: false,
      componentName: '',
    };
    // this.onFinish = this.onFinish.bind(this);
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
    this.setState({componentName: this.props.componentName})
  }

  /**
   * データ取得
   */
  index() {
    const params = {
      SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      componentName: this.state.componentName ? this.state.componentName : this.props.componentName,
    }
    OptionSettingAction.index(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res,
          })
        }
      })
  }

  // insert 新規作成
  Insert = (values) => {
    this.setState({ isLoading: true });
    OptionSettingAction.optionNewSave(values)
      .then(res => {
        console.log(res)
        this.index()
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  //record 更新
  Save = (record, newValues) => {
    this.setState({ isLoading: true });
    OptionSettingAction.optionSave({ ...newValues, ...record })
      .then(res => {
        console.log(res)
        this.index()
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  // delete 削除
  Delete = (values) => {
    this.setState({ isLoading: true });
    OptionSettingAction.delete(values)
      .then(res => {
        console.log(res)
        this.index()
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }
  /**
   * オプション設定　編集画面を表示
   */
  showOptionSettingEdit = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <OptionSettingEdit
            record={record}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * オプション対応表CSV取込
   */
  showUploadModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-contnt',
        component: (
          <OptionSettingCsvCapture
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='optional-info-maintain'>
        <Card title='オプション設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
            onFinish={this.onFinish}
          >
            <div className='box_container'>

              <div className='box_inner_horizontal'>

                <Form.Item
                  name='SearchChar'
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    className='input-size-40'
                    onPressEnter={() => {
                      this.index()
                    }}
                  />
                </Form.Item>

                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.index()
                    }}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>

                <Form.Item
                  name='OptionSetting'
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                >
                  <Button
                    icon={<UploadOutlined />}
                    type='primary'
                    onClick={() => {
                      this.showUploadModal()
                    }}
                  >
                    <span className='btn_label'>
                      オプション対応表CSV取込
                    </span>
                  </Button>
                </Form.Item>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 550 }}
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.showOptionSettingEdit(record)
                    }
                  }
                }}
              >
                <Table.Column title='種別' dataIndex='type_code' width={100} />
                <Table.Column title='コード' dataIndex='option_code' width={150} />
                <Table.Column title='名称' dataIndex='id_name' />
                <Table.Column
                  title={
                    <Button
                      type='primary'
                      size='small'
                      icon={<PlusOutlined />}
                      loading={this.state.isLoadingInsurersList}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '550px',
                            className: '',
                            component:
                              <ModalAdd
                                columns={columns}
                                onFinishScreen={(output) => {
                                  this.Insert(output.itemChange)
                                  this.closeModal()
                                }}
                              />
                          },
                        });
                      }}
                    />}

                  align='center'
                  width={40}
                  render={(value, record) => (
                    <Dropdown
                      trigger="click"
                      size="small"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="1"
                            onClick={() => {
                              this.showOptionSettingEdit(record)
                            }}
                          >
                            確認
                          </Menu.Item>
                          <Menu.Item
                            key="2"
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  className: '',
                                  component:
                                    <ModalChange
                                      columns={columns}
                                      record={record}
                                      onUpdate={(output) => {
                                        this.Save(record, output)
                                        this.closeModal()
                                      }}
                                      onDelete={(output) => {
                                        this.Delete(record)
                                        this.closeModal()
                                      }}
                                    />
                                },
                              });
                            }}
                          >
                            編集
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(OptionSetting)
