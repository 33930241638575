import axios from 'configs/axios'

const API_LIST = {
  index: '/api/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent',
  sendMirais: '/api/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent/send-mirais',
  checkMiraisStartup: '/api/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent/check-mirais-startup',
  getOracleTest: '/api/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent/get-oracle-test',
  mountTest: '/api/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent/mount-test'
}

const MiraisElectronicMedicalRecordsSentService = {
  async index(params) {
    return axios.post(API_LIST.index, params)
  },
  async sendMirais(params) {
    return axios.get(API_LIST.sendMirais, { params })
  },
  async checkMiraisStartup() {
    return axios.get(API_LIST.checkMiraisStartup)
  },
  async getOracleTest() {
    return axios.get(API_LIST.getOracleTest)
  },
  async mountTest(params) {
    return axios.get(API_LIST.mountTest, { params })
  }
}
export default MiraisElectronicMedicalRecordsSentService
