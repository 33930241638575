import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, message, Form, Input } from 'antd'
import OfficeInquiryAction from 'redux/ResultOutput/CourseSpecificStardStyleSetting/OfficeInquiry.action.js'

class WS0445006_OfficeInquiry extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Lo_branch_store_code: PropTypes.any,
    Lo_office_code: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '事業所照会'

    this.state = {
      dataSource: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      SearchChar: this.props.officeCode ?? ''
    })
    this.forceUpdate()
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      SearchChar: this.formRef.current.getFieldValue('SearchChar')
    }
    OfficeInquiryAction.index(params)
      .then((res) => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
  }

  /**
   * 選択処理
   * @param {*} record
   */
  handleRowSelected = (record) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lo_office_code: record.office_code,
        Lo_branch_store_code: record.branch_store_code,
        recordData: record,
      })
    }
  }

  render() {
    return (
      <div className='office-inquiry'>
        <Card title='事業所・支店選択'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item name='SearchChar' label='検索' >
              <Input
                style={{ width: 300 }}
                onPressEnter={() => this.index()}
              />
            </Form.Item>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: 500 }}
              onRow={(record, index) => ({
                onDoubleClick: (e) => {
                  this.handleRowSelected(record)
                },
              })}
            >
              <Table.Column
                title='事業所コード'
                dataIndex='office_code'
                className='column-size-10'
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='支社店コード'
                dataIndex='branch_store_code'
                className='column-size-6'
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='事業所名（カナ）'
                dataIndex='office_kana_name'
              />
              <Table.Column
                title='事業所名称'
                dataIndex='office_kanji_name'
              />
            </Table>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0445006_OfficeInquiry)
