import axios from 'configs/axios'

const apiPaths = {
  index: '/api/personal-info-maintain-directly/patient-info-query-others',
}

const PatientInfoQueryOthersService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
}

export default PatientInfoQueryOthersService
