import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, InputNumber, Input, Select, Button,  Row, Col, Space, DatePicker, Modal, Menu, Dropdown, message, Spin, Tooltip } from "antd";

import { DeleteOutlined,SaveOutlined,SearchOutlined, MoreOutlined, PlusOutlined, CaretDownOutlined } from '@ant-design/icons';
import Checkbox from "antd/lib/checkbox/Checkbox";
import Color from "constants/Color";
import { width } from "dom-helpers";
import axios from "configs/axios";


const styleDiv = {
  textAlign: 'right',
  padding: '7px 0',
  color: '#14468C',
  fontWeight: 'bold',
  background: '#C8DCF5',
}
const styleCol = {
  "border": '1px solid #F0F0F0'
}

const styletg8uq7 = { 
  "borderColor"     : '#000000',
  "borderstyle"     : 'solid',
  "borderwidth"     : '1px',  
  "backgroundColor" : "#409cff",
  "textAlign"       : 'center',
}

const styletgf8tz = { 
    "backgroundColor" :'#409cff',
    "borderColor"     :'black',  
    "borderWidth"     :'1px',   
    "textAlign"       : 'center',
}

const styletgi3c9 = { 
  "borderColor"     :'black',
  "borderStyle"     :'solid',
  "borderWidth"     :'1px',   
  "backgroundColor" :'#409cff',
  "width"           :"16%",
  "height"          :"40px",
  "fontSize"        : "14px"
}

const styletgi3c9_1 = { 
  "borderColor"     :'black',
  "borderStyle"     :'solid',
  "borderWidth"     :'1px',   
  "backgroundColor" :'#409cff',  
  "width"           :"7%",
  "height"          :"40px",
  "fontSize"        :"14px"  
}

const styletgc3ow = { 
  "borderColor"   :'black',
  "borderStyle"   :'solid',
  "borderWidth"   :'0px',   
  "textAlign"     :'right',
  "verticalAlign" :'middle',
  "height"        :"30px",
  "fontSize"      : "12px"
}


const styletg0pky = {   
  "borderWidth"   : '0px',   
  "textAlign"     : 'left',
  "verticalAlign" : 'middle',
  "height"        : "35px",  
  "fontSize"      : "12px"
}

const styletg73oq = { 
  
  "textAlign"     :'right',
  "verticalAlign" :'middle',
  "borderWidth"   :'0px', 
  "height"        :"30px",
  "fontSize"      : "12px"
}

const styletga1v6 = { 
  "backgroundColor" :'#409cff',
  "borderColor"     :'#000000',
  "fontSize"        :'12px',
  "textAlign"       :'left',   
  "height"          :"30px"
}

const mystyle = { 
  border: "1px",
  padding: "10px",
  fontFamily: "Arial"
};

class WS1173001_TotalAmountCorrect extends React.Component {
  formRef = React.createRef();

  static propTypes = {   
    Li_Format : PropTypes.any,       
  };

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06950:   合計金額(修正)';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
  }
}
  
closeModal() {
  this.setState({
    childModal: {
      ...this.state.childModal,
      visible: false,
    },
  });
}

  onFinish(values) {
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {
    
    if (this.props !== prv) {
      this.formRef.current.resetFields();
      this.getScreenData(); 
    }
  }

  getScreenData = () => {
    
    this.setState({ isLoading: true });
    this.setState({ isLoadingTable: true});   

    axios.get('/api/associate-insure-param-maintain/total-amount-correct/getscreenData', {     
      params: {          
                // Li_Format  :  '標準版2017',  
              Li_Format        : this.props.Li_Format,                
      },      
    })
      .then(res => {
        this.formRef.current?.setFieldsValue({         
            // 
            implement_general_01_chest_between_stomach  : res ? res.data.implement_general_01_chest_between_stomach : '',
            unimplement_carried_out_hearing_test        : res ? res.data.unimplement_carried_out_hearing_test : '', 
            unimplement_foo_blood_sugar                 : res ? res.data.unimplement_foo_blood_sugar : '',
            unimplement_carried_out_total_01            : res ? res.data.unimplement_carried_out_total_01 : '', 
            unimplement_carried_tax_01                  : res ? res.data.unimplement_carried_tax_01 : '',
            deduction_general_total_01                  : res ? res.data.deduction_general_total_01 : '', 
            selfpay_general_total_01                    : res ? res.data.selfpay_general_total_01 : '',
            billing_general_sum_01                      : res ? res.data.billing_general_sum_01 : '',                
            consumption_tax_general_sum_01              : res ? res.data.consumption_tax_general_sum_01 : '',
            implement_general_02_chest_straight_stomach : res ? res.data.implement_general_02_chest_straight_stomach : '', 
            unimplement_carried_out_urine_test          : res ? res.data.unimplement_carried_out_urine_test : '',
            unimplement_foo_total_kore                  : res ? res.data.unimplement_foo_total_kore : '', 
            unimplement_carried_out_total_02            : res ? res.data.unimplement_carried_out_total_02 : '',
            unimplement_carried_tax_02                  : res ? res.data.unimplement_carried_tax_02 : '', 
            deduction_general_total_02                  : res ? res.data.deduction_general_total_02 : '',
            selfpay_general_total_02                    : res ? res.data.selfpay_general_total_02 : '', 
            billing_general_sum_02                      : res ? res.data.billing_general_sum_02 : '',
            consumption_tax_general_sum_02              : res ? res.data.consumption_tax_general_sum_02 : '', 
            implement_general_03_chest_between_stomach  : res ? res.data.implement_general_03_chest_between_stomach : '',
            unimplement_carried_out_fecal_occult_blood_1: res ? res.data.unimplement_carried_out_fecal_occult_blood_1 : '',              
            unimplement_foo_neutral_fat                 : res ? res.data.unimplement_foo_neutral_fat : '',
            unimplement_carried_out_total_03            : res ? res.data.unimplement_carried_out_total_03 : '', 
            unimplement_carried_tax_03                  : res ? res.data.unimplement_carried_tax_03 : '',
            deduction_general_total_03                  : res ? res.data.deduction_general_total_03 : '',  
            selfpay_general_total_03                    : res ? res.data.selfpay_general_total_03 : '',
            billing_general_sum_03                      : res ? res.data.billing_general_sum_03 : '', 
            consumption_tax_general_sum_03              : res ? res.data.consumption_tax_general_sum_03 : '',
            implement_general_04_chest_straight_stomach : res ? res.data.implement_general_04_chest_straight_stomach : '',  
            unimplement_carried_out_fecal_occult_blood_2: res ? res.data.unimplement_carried_out_fecal_occult_blood_2 : '',
            unimplement_foo_hdl_kore                    : res ? res.data.billing_general_sum_03 : '', 
            unimplement_carried_out_total_04            : res ? res.data.unimplement_carried_out_total_04 : '',
            unimplement_carried_tax_04                  : res ? res.data.unimplement_carried_tax_04 : '',               
            deduction_general_total_04                  : res ? res.data.deduction_general_total_04 : '',
            selfpay_general_total_04                    : res ? res.data.selfpay_general_total_04 : '', 
            billing_general_sum_04                      : res ? res.data.billing_general_sum_04 : '',
            consumption_tax_general_sum_04              : res ? res.data.consumption_tax_general_sum_04 : '',  
            implement_general_05_uterine_cancer         : res ? res.data.implement_general_05_uterine_cancer : '',
            unimplement_carried_out_blood_general       : res ? res.data.unimplement_carried_out_blood_general : '', 
            unimplement_foo_got                         : res ? res.data.unimplement_foo_got : '',
            unimplement_carried_out_total_05            : res ? res.data.unimplement_carried_out_total_05 : '',               
            unimplement_carried_tax_05                  : res ? res.data.unimplement_carried_tax_05 : '',
            deduction_general_total_05                  : res ? res.data.deduction_general_total_05 : '', 
            selfpay_general_total_05                    : res ? res.data.selfpay_general_total_05 : '',
            billing_general_sum_05                      : res ? res.data.billing_general_sum_05 : '',             
            consumption_tax_general_sum_05              : res ? res.data.consumption_tax_general_sum_05 : '',
            implement_general_06_breast_uterus          : res ? res.data.implement_general_06_breast_uterus : '', 
            unimplement_carried_out_blood_sugar         : res ? res.data.unimplement_carried_out_blood_sugar : '',
            unimplement_foo_gpt                         : res ? res.data.unimplement_foo_gpt : '', 
            unimplement_carried_out_total_06            : res ? res.data.unimplement_carried_out_total_06 : '',
            unimplement_carried_tax_06                  : res ? res.data.unimplement_carried_tax_06 : '', 
            deduction_general_total_06                  : res ? res.data.deduction_general_total_06 : '',
            selfpay_general_total_06                    : res ? res.data.selfpay_general_total_06 : '',            
            billing_general_sum_06                      : res ? res.data.billing_general_sum_06 : '',
            consumption_tax_general_sum_06              : res ? res.data.consumption_tax_general_sum_06 : '', 
            implement_general_07_chest_stomach_not_performed  : res ? res.data.implement_general_07_chest_stomach_not_performed : '',
            unimplement_carried_out_total_kore          : res ? res.data.unimplement_carried_out_total_kore : '',
            unimplement_foo_gamma_gtp                   : res ? res.data.unimplement_foo_gamma_gtp : '',
            unimplement_carried_out_total_07            : res ? res.data.unimplement_carried_out_total_07 : '', 
            unimplement_carried_tax_07                  : res ? res.data.unimplement_carried_tax_07 : '',
            deduction_general_total_07                  : res ? res.data.deduction_general_total_07 : '',            
            selfpay_general_total_07                    : res ? res.data.selfpay_general_total_07 : '',
            billing_general_sum_07                      : res ? res.data.billing_general_sum_07 : '', 
            consumption_tax_general_sum_07              : res ? res.data.consumption_tax_general_sum_07 : '',
            implement_general_08_chest_only             : res ? res.data.implement_general_08_chest_only : '', 
            unimplement_carried_got                     : res ? res.data.unimplement_carried_got : '',
            unimplement_foo_uric_acid                   : res ? res.data.unimplement_foo_uric_acid : '', 
            unimplement_carried_out_total_08            : res ? res.data.unimplement_carried_out_total_08 : '',
            unimplement_carried_tax_08                  : res ? res.data.unimplement_carried_tax_08 : '',              
            deduction_general_total_08                  : res ? res.data.deduction_general_total_08 : '',
            selfpay_general_total_08                    : res ? res.data.selfpay_general_total_08 : '', 
            billing_general_sum_08                      : res ? res.data.billing_general_sum_08 : '',
            consumption_tax_general_sum_08              : res ? res.data.consumption_tax_general_sum_08 : '',  
            implement_general_09_chest_straight_only    : res ? res.data.implement_general_09_chest_straight_only : '',
            unimplement_carried_gpt                     : res ? res.data.unimplement_carried_gpt : '', 
            unimplement_carried_out_total_09            : res ? res.data.unimplement_carried_out_total_09 : '',
            unimplement_carried_tax_09                  : res ? res.data.unimplement_carried_tax_09 : '',  
            deduction_general_total_09                  : res ? res.data.deduction_general_total_09 : '',
            selfpay_general_total_09                    : res ? res.data.selfpay_general_total_09 : '', 
            billing_general_sum_09                      : res ? res.data.billing_general_sum_09 : '',
            consumption_tax_general_sum_09              : res ? res.data.consumption_tax_general_sum_09 : '', 
            implement_general_10_stomach_only           : res ? res.data.implement_general_10_stomach_only : '',
            unimplement_carried_alp                     : res ? res.data.unimplement_carried_alp : '', 
            unimplement_carried_out_total_10            : res ? res.data.unimplement_carried_out_total_10 : '',
            unimplement_carried_tax_10                  : res ? res.data.unimplement_carried_tax_10 : '',  
            deduction_general_total_10                  : res ? res.data.deduction_general_total_10 : '',
            selfpay_general_total_10                    : res ? res.data.selfpay_general_total_10 : '', 
            billing_general_total_10                    : res ? res.data.billing_general_total_10 : '',
            consumption_tax_general_total_10            : res ? res.data.consumption_tax_general_total_10 : '',    
            implement_general_11_stomach_straight_only  : res ? res.data.implement_general_11_stomach_straight_only : '',
            unimplement_carried_gamma_gtp               : res ? res.data.unimplement_carried_gamma_gtp : '', 
            unimplement_carried_out_total_11            : res ? res.data.unimplement_carried_out_total_11 : '',
            unimplement_carried_tax_11                  : res ? res.data.unimplement_carried_tax_11 : '', 
            deduction_general_total_11                  : res ? res.data.deduction_general_total_11 : '',
            selfpay_general_total_11                    : res ? res.data.selfpay_general_total_11 : '', 
            billing_general_total_11                    : res ? res.data.billing_general_total_11 : '',
            consumption_tax_general_total_11            : res ? res.data.consumption_tax_general_total_11 : '',             
            implement_general_out_gastroscope           : res ? res.data.implement_general_out_gastroscope : '',
            unimplement_carried_out_neutral_fat         : res ? res.data.unimplement_carried_out_neutral_fat : '', 
            unimplement_carried_out_total_12_breast_1   : res ? res.data.unimplement_carried_out_total_12_breast_1 : '',
            unimplement_carried_tax_included_12_breast_1: res ? res.data.unimplement_carried_tax_included_12_breast_1 : '', 
            deduction_general_total_12                  : res ? res.data.deduction_general_total_12 : '',
            selfpay_general_total_12                    : res ? res.data.selfpay_general_total_12 : '', 
            billing_general_total_12                    : res ? res.data.billing_general_total_12 : '',
            consumption_tax_general_total_12            : res ? res.data.consumption_tax_general_total_12 : '', 
            implement_general_out_breast_1_direction    : res ? res.data.implement_general_out_breast_1_direction : '',
            unimplement_carried_out_uric_acid           : res ? res.data.unimplement_carried_out_uric_acid : '', 
            unimplement_carried_out_total_13_breast_2   : res ? res.data.unimplement_carried_out_total_13_breast_2 : '',
            unimplement_carried_tax_included_13_breast_2: res ? res.data.unimplement_carried_tax_included_13_breast_2 : '',   
            deduction_general_total_13                  : res ? res.data.deduction_general_total_13 : '',
            selfpay_general_total_13                    : res ? res.data.selfpay_general_total_13 : '', 
            billing_general_total_13                    : res ? res.data.billing_general_total_13 : '',
            consumption_tax_general_total_13            : res ? res.data.consumption_tax_general_total_13 : '', 
            unimplement_carried_out_creatinine          : res ? res.data.unimplement_carried_out_creatinine : '',
            implement_addition_added                    : res ? res.data.implement_addition_added : '', 
            unimplement_carried_hdl_kore                : res ? res.data.unimplement_carried_hdl_kore : '',
            unimplement_addition_total                  : res ? res.data.unimplement_addition_total : '', 
            unimplement_added_tax                       : res ? res.data.unimplement_added_tax : '',
            deduction_addition_total                    : res ? res.data.deduction_addition_total : '', 
            selfpay_addition_total                      : res ? res.data.selfpay_addition_total : '',
            billing_addition_total                      : res ? res.data.billing_addition_total : '',  
            consumption_tax_added_sum                   : res ? res.data.consumption_tax_added_sum : '',
            unimplement_carried_out_electrocardiogram   : res ? res.data.unimplement_carried_out_electrocardiogram : '',
            implement_hepatitis_same_day_implement      : res ? res.data.implement_hepatitis_same_day_implement : '',
            unimplement_carried_out_blood_collection    : res ? res.data.unimplement_carried_out_blood_collection : '',
            deduction_hepatitis_total_same_day_implement: res ? res.data.deduction_hepatitis_total_same_day_implement : '',
            selfpay_hepatitis_total_same_day_implement  : res ? res.data.selfpay_hepatitis_total_same_day_implement : '', 
            billing_hepatitis_total_same_day_implement  : res ? res.data.billing_hepatitis_total_same_day_implement : '',
            consumption_tax_hepatitis_total_same_day_implement : res ? res.data.consumption_tax_hepatitis_total_same_day_implement : '',             
            implement_hepatitis_later_day_implement     : res ? res.data.implement_hepatitis_later_day_implement : '',
            unimplement_carried_5_7_pieces              : res ? res.data.unimplement_carried_5_7_pieces : '', 
            deduction_hepatitis_total_later_day_implement  : res ? res.data.deduction_hepatitis_total_later_day_implement : '',
            selfpay_hepatitis_total_later_day_implement : res ? res.data.selfpay_hepatitis_total_later_day_implement : '',  
            billing_hepatitis_total_later_day_implement : res ? res.data.billing_hepatitis_total_later_day_implement : '',
            consumption_tax_hepatitis_total_later_day_implement : res ? res.data.consumption_tax_hepatitis_total_later_day_implement : '', 
            implement_hepatitis_rna_implement           : res ? res.data.implement_hepatitis_rna_implement : '',
            unimplement_carried_8_9_pieces              : res ? res.data.unimplement_carried_8_9_pieces : '',  
            deduction_hepatitis_total_rna_implement     : res ? res.data.deduction_hepatitis_total_rna_implement : '',
            selfpay_hepatitis_total_rna_implement       : res ? res.data.selfpay_hepatitis_total_rna_implement : '', 
            billing_hepatitis_total_rna_implement       : res ? res.data.billing_hepatitis_total_rna_implement : '',
            consumption_tax_hepatitis_total_rna_implement : res ? res.data.consumption_tax_hepatitis_total_rna_implement : '',             
            unimplement_carried_10_more                 : res ? res.data.unimplement_carried_10_more : '',
            implement_followup_add_none                 : res ? res.data.implement_followup_add_none : '', 
            unimplement_added_fundus                    : res ? res.data.unimplement_added_fundus : '',
            unimplement_followup_total_add              : res ? res.data.unimplement_followup_total_add : '', 
            unimplement_followup_tax_no_additional      : res ? res.data.unimplement_followup_tax_no_additional : '',
            deduction_follow_total_add_none             : res ? res.data.deduction_follow_total_add_none : '', 
            selfpay_follow_total_add_none               : res ? res.data.selfpay_follow_total_add_none : '',
            billing_follow_total_add_none               : res ? res.data.billing_follow_total_add_none : '',   
            consumption_tax_follow_total_add_none       : res ? res.data.consumption_tax_follow_total_add_none : '',
            implement_followup_add_sugar_load           : res ? res.data.implement_followup_add_sugar_load : '', 
            unimplement_followup_total_load_additional  : res ? res.data.unimplement_followup_total_load_additional : '',
            unimplement_followtax_load                  : res ? res.data.unimplement_followtax_load : '',
            deduction_follow_total_add_load             : res ? res.data.deduction_follow_total_add_load : '',
            selfpay_follow_total_load_additional        : res ? res.data.selfpay_follow_total_load_additional : '', 
            billing_follow_total_add_load               : res ? res.data.billing_follow_total_add_load : '',
            consumption_tax_follow_total_add_load       : res ? res.data.consumption_tax_follow_total_add_load : '',  
            implement_followup_add_hba1c                : res ? res.data.implement_followup_add_hba1c : '',
            unimplement_follow_total_hba1c_added        : res ? res.data.unimplement_follow_total_hba1c_added : '', 
            unimplement_followup_tax_hba1c_added        : res ? res.data.unimplement_followup_tax_hba1c_added : '',
            deduction_follow_total_add_hba1c            : res ? res.data.deduction_follow_total_add_hba1c : '',   
            selfpay_follow_total_hba1c_added            : res ? res.data.selfpay_follow_total_hba1c_added : '',
            billing_follow_total_add_hba1c              : res ? res.data.billing_follow_total_add_hba1c : '', 
            consumption_tax_follow_total_hba1c_added    : res ? res.data.consumption_tax_follow_total_hba1c_added : '',
            implement_followup_add_all                  : res ? res.data.implement_followup_add_all : '',              
            unimplement_followup_total_all              : res ? res.data.unimplement_followup_total_all : '',
            unimplement_followtax_all                   : res ? res.data.unimplement_followtax_all : '', 
            deduction_follow_total_add_all              : res ? res.data.deduction_follow_total_add_all : '',
            selfpay_follow_total_add_all                : res ? res.data.selfpay_follow_total_add_all : '', 
            billing_follow_total_add_all                : res ? res.data.billing_follow_total_add_all : '',
            consumption_tax_follow_total_add_all        : res ? res.data.consumption_tax_follow_total_add_all : '', 
            fundus_implement                            : res ? res.data.fundus_implement : '',
            fundus_after_deduction                      : res ? res.data.fundus_after_deduction : '',  
            fundus_selfpay                              : res ? res.data.fundus_selfpay : '',
            fundus_billing                              : res ? res.data.fundus_billing : '', 
            fundus_consumption_tax                      : res ? res.data.fundus_consumption_tax : '',
        })       

      })
      .catch(error => {  

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }


  render() {
    return (
      <div className="total-amount-correct">
        <Card title="V4-VNS06950:   合計金額(修正)">
        <Form
                ref={this.formRef}
                onFinish={this.onFinish}
              >
          <Row gutter={5}>
              <Col span={24} style={mystyle} >  
              <table border={0} width={"100%"} >
              <thead>
                <tr>
                  <th style={styletgi3c9}  colSpan="2">実施一般</th>
                  <th style={styletgi3c9}  colSpan="2">未実施一般</th>
                  <th style={styletgi3c9}  colSpan="2">未実施ﾌｫﾛｰ</th>
                  <th></th>
                  <th style={styletgi3c9_1}>未実施減額</th>
                  <th style={styletgi3c9_1}>未実施税込</th>
                  <th style={styletgi3c9_1}>差引健診費</th>
                  <th style={styletgi3c9_1}>自己負担額</th>
                  <th style={styletgi3c9_1}>請求額</th>
                  <th style={styletgi3c9_1}>うち消費税</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styletgc3ow}>01[胸間胃間]</td>
                  <td style={styletg0pky}>
                    <Form.Item  name="implement_general_01_chest_between_stomach" label=""  >
                        <Input readOnly type="text" style={{width:'100px'}}/>
                    </Form.Item>
                  </td>
                  <td  style={styletgc3ow}>[聴力検査]</td>
                  <td style={styletg0pky}>
                    <Form.Item  name="unimplement_carried_out_hearing_test" label=""  >
                            <Input readOnly type="text" style={{width:'100px'}} />
                    </Form.Item>
                  </td>
                  <td style={styletgc3ow}>[血糖]</td>
                  <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_blood_sugar" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                  <td style={styletg73oq}>01[胸間胃間]</td>
                  <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_01" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                  <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_01" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                  <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_01" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                  <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_01" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                  <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_01" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                  <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_01" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                </tr>
              <tr>
                <td style={styletgc3ow}>02[胸間胃直]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_general_02_chest_straight_stomach" label=""  >
                        <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[尿検査]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_urine_test" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[総ｺﾚ]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_total_kore" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>02[胸間胃直]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_02" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_02" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_02" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_02" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_02" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_02" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>03[胸直胃間]</td>
               <td style={styletg0pky}>
                      <Form.Item name="implement_general_03_chest_between_stomach" label=""  >
                        <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[便潜血1]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_fecal_occult_blood_1" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[中性脂肪]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_neutral_fat" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>03[胸直胃間]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_03" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_03" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_03" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_03" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_03" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_03" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>04[胸直胃直]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_general_04_chest_straight_stomach" label=""  >
                        <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[便潜血2]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_fecal_occult_blood_2" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[HDL-ｺﾚ]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_hdl_kore" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>04[胸直胃直]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_04" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_04" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_04" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_04" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_04" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_04" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>05[子宮がん]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_05_uterine_cancer" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[血液一般]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_blood_general" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[ＧＯＴ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_got" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>05[子宮がん]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_05" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_05" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_05" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_05" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_05" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_05" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>06[乳 子 宮]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_06_breast_uterus" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[血糖]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_blood_sugar" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[ＧＰＴ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_gpt" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>06[乳 子 宮]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_06" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_06" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_06" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_06" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_06" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_06" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>07[全未実施]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_07_chest_stomach_not_performed" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[総ｺﾚ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_kore" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[γ-GTP]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_gamma_gtp" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>07[全未実施]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_07" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_07" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_07" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_07" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_07" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_07" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>08[胸間のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_08_chest_only" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[ＧＯＴ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_got" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[尿酸]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_foo_uric_acid" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg73oq}>08[胸間のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_08" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_08" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_08" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_08" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_08" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_08" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>09[胸直のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_09_chest_straight_only" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[ＧＰＴ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_gpt" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>09[胸直のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_09" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_09" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_09" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_09" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_sum_09" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_sum_09" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>10[胃間のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_10_stomach_only" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[ＡＬＰ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_alp" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>10[胃間のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_10" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_10" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_10" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_10" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_total_10" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_total_10" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>11[胃直のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_11_stomach_straight_only" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[γ-GTP]</td>
                <td style={styletg0pky}>
                        <Form.Item  name="unimplement_carried_gamma_gtp" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>11[胃直のみ]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_11" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_11" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_11" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_11" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_total_11" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_total_11" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>12[ﾏﾝﾓ1方向]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_out_breast_1_direction" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[中性脂肪]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_neutral_fat" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>12[ﾏﾝﾓ1方向]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_12_breast_1" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_included_12_breast_1" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_12" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_12" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_total_12" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_total_12" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>13[ﾏﾝﾓ2方向]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="implement_general_out_gastroscope" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[尿酸]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_uric_acid" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>13[ﾏﾝﾓ2方向]</td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_total_13_breast_2" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_tax_included_13_breast_2" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="deduction_general_total_13" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="selfpay_general_total_13" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="billing_general_total_13" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_general_total_13" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgf8tz}>実施付加</td>
                <td style={styletg0pky}></td>
                <td style={styletgc3ow}>[ｸﾚｱﾁﾆﾝ]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_creatinine" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg8uq7}>実施付加</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[付加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_addition_added" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[HDL-ｺﾚ]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_hdl_kore" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[付加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_addition_total" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_added_tax" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_addition_total" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_addition_total" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_addition_total" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_added_sum" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgf8tz}>実施肝炎</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}>[心電図]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_electrocardiogram" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg8uq7}>実施肝炎</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[同日実施]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_hepatitis_same_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[採血]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_out_blood_collection" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[同日実施]</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_hepatitis_total_same_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_hepatitis_total_same_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_hepatitis_total_same_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_hepatitis_total_same_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[後日実施]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_hepatitis_later_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[5-7個]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_5_7_pieces" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[後日実施]</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_hepatitis_total_later_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_hepatitis_total_later_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_hepatitis_total_later_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_hepatitis_total_later_day_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[RNA実施]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_hepatitis_rna_implement" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[8-9個]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_8_9_pieces" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[RNA実施]</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_hepatitis_total_rna_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_hepatitis_total_rna_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_hepatitis_total_rna_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_hepatitis_total_rna_implement" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgf8tz}>実施ﾌｫﾛｰ</td>
                <td style={styletg0pky}></td>
                <td style={styletgc3ow}>[10-以上]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_carried_10_more" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg8uq7}>実施ﾌｫﾛｰ</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[追加なし]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_followup_add_none" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}>[眼底]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_added_fundus" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[追加なし]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followup_total_add" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followup_tax_no_additional" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_follow_total_add_none" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_follow_total_add_none" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_follow_total_add_none" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_follow_total_add_none" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[糖負荷追加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_followup_add_sugar_load" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[糖負荷追加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followup_total_load_additional" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followtax_load" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_follow_total_add_load" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_follow_total_load_additional" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_follow_total_add_load" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_follow_total_load_additional" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[HbA1c追加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_followup_add_hba1c" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[HbA1c追加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_follow_total_hba1c_added" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followup_tax_hba1c_added" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_follow_total_add_hba1c" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_follow_total_hba1c_added" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_follow_total_add_hba1c" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_follow_total_hba1c_added" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletgc3ow}>[全て追加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="implement_followup_add_all" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg73oq}>[全て追加]</td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followup_total_all" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="unimplement_followtax_all" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="deduction_follow_total_add_all" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="selfpay_follow_total_add_all" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="billing_follow_total_add_all" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="consumption_tax_follow_total_add_all" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
              <tr>
                <td style={styletg8uq7}>一般眼底実施</td>
               <td style={styletg0pky}>
                      <Form.Item  name="fundus_implement" label=""  >
                              <Input readOnly type="text" style={{width:'100px'}} />
                      </Form.Item>
                  </td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
                <td style={styletgc3ow}></td>
                <td style={styletg0pky}></td>
                <td style={styletg8uq7}>一般眼底実施</td>
                <td style={styletg0pky}></td>
                <td style={styletg0pky}></td>
               <td style={styletg0pky}>
                      <Form.Item  name="fundus_after_deduction" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="fundus_selfpay" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="fundus_billing" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
               <td style={styletg0pky}>
                      <Form.Item  name="fundus_consumption_tax" label=""  >
                              <Input readOnly type="text" style={{width:'80px'}} />
                      </Form.Item>
                  </td>
              </tr>
            </tbody>
            </table>
            </Col>
          </Row> 
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1173001_TotalAmountCorrect);
