import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import NotesInputAction from 'redux/InputBusiness/NotInputCheckCategory/NotesInput.action'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '80px' } }
}

class WS2637054_NotesInput extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '注意事項入力 編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isSave: false,
      count: 0,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (!this.props.newFlag) {
      // 変更の場合

      // 親画面のrecordをコピー
      const data = { ...this.props.record }
      // Form設定
      this.formRef.current?.setFieldsValue(data)
    }
  }

  /**
   * データ保存
   */
  onFinish = (values) => {
    const params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CategoryCode: this.props.Li_CategoryCode,
      id: (this.props.newFlag) ? null : this.props.record.id, //新規追加の場合、id は null
      ...values //連番、コード、注意事項の入力値
    }
    NotesInputAction.save(params)
      .then(() =>
        // 親画面に返す
        this.props.onFinishScreen()
      )
  }

  /**
   * データ削除
   * @param {*} id
   */
  delete = () => {
    const params = {
      id: this.props.record.id ?? 0
    }
    NotesInputAction.delete(params)
      .then(() => {
        // 親画面に返す
        this.props.onFinishScreen()
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='notes-input'>
        <Card title={'注意事項入力[' + (this.props.newFlag ? '新規' : '変更') + ']'}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>

              <Form.Item
                label='連番'
                name='serial_number'
                rules={[{ required: true }]}
                {...labelCol}
              >
                <Input
                  className='input-size-number-4'
                  maxLength={4}
                />
              </Form.Item>

              <Form.Item
                label='コード'
                name='category_determining_code'
                rules={[{ required: true }]}
                {...labelCol}
              >
                <Input.Search
                  type='text'
                  className='input-search-size-number-8'
                  onBlur={(event) => {
                    if (event.target.value.length > 0) {
                      this.setState({ isSave: true })
                    }
                  }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 800,
                        component:
                          <WS0272001_CautionGuideNotesSearchQuery
                            onFinishScreen={(output) => {
                              const data = {
                                category_determining_code: output?.Lo_LnkOutCmtCode,
                                notes_comment: output?.Lo_recordData?.comment_content
                              }
                              // Form設定
                              this.formRef.current?.setFieldsValue(data)

                              // モーダル閉じる
                              this.closeModal()
                            }}
                          />
                        ,
                      },
                    })
                  }}
                />
              </Form.Item>

              <Form.Item
                label='注意事項'
                name='notes_comment'
                rules={[{ required: true }]}
                {...labelCol}
              >
                <Input />
              </Form.Item>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637054_NotesInput)
