import { message } from 'antd'
import MutualAidSubjectBillingItemMaintainService from 'services/AccountingBusiness/MutualAidAssociationBilling/MutualAidSubjectBillingItemMaintainService.js'

const MutualAidSubjectBillingItemMaintainAction = {
  index(data) {
    return MutualAidSubjectBillingItemMaintainService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getItemSettings(data) {
    return MutualAidSubjectBillingItemMaintainService.getItemSettings(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getItemSpecify(data) {
    return MutualAidSubjectBillingItemMaintainService.getItemSpecify(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveItemSettings(data) {
    return MutualAidSubjectBillingItemMaintainService.saveItemSettings(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteItemSettings(data) {
    return MutualAidSubjectBillingItemMaintainService.deleteItemSettings(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveItemSpecify(data) {
    return MutualAidSubjectBillingItemMaintainService.saveItemSpecify(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteItemSpecify(data) {
    return MutualAidSubjectBillingItemMaintainService.deleteItemSpecify(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default MutualAidSubjectBillingItemMaintainAction
