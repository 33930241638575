import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Form, Input, Checkbox, Table, Row, Col, Space, Dropdown, Menu, Tooltip } from 'antd'
import { MenuOutlined, MoreOutlined, } from '@ant-design/icons'
import { getScreenData } from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0362011_InspectItemJudgeValueSettingSub from './WS0362011_InspectItemJudgeValueSettingSub.jsx'
import WS0362012_InspectItemComplexJudgeValueSettingSub from './WS0362012_InspectItemComplexJudgeValueSettingSub.jsx'
import WS0362031_CopyJudgementData from './WS0362031_CopyJudgementData.jsx'
import WS0362032_DeleteJudgementData from './WS0362032_DeleteJudgementData.jsx'
import Menubar from 'components/Commons/Menubar'
import Color from 'constants/Color'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0362001_InspectItemJudgeValueSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査項目判定値設定'
    //「判定」の正確なスペルは'judgement'だが、テーブルのカラム名が'judgment'になっているため、そのまま'judgment'とする

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      initValue: {
        SearchChar: '',
        stsUsed: true,
        stsJugdement: true,
        InternalInspectCode: ''
      },
      dataSource: [],
      isLoadingPage: false,
      colorText: 208,

    }
    // this.changeData = debounce(this.changeData, 1000)
    // this.handleChangeValue = debounce(this.handleChangeValue, 1000)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue({ stsUsed: true })
    this.formRef.current.setFieldsValue({ stsJugdement: true })
    const params = {
      ...this.state.initValue,
    }
    this.loadData(params)
  }

  loadData = (params) => {
    this.setState({ isLoadingPage: true })
    getScreenData(params)
      .then(res => {
        if (res) {
          let data = res.data.map((item, index) => ({ ...item, id: index }))
          this.setState({
            dataSource: data,
            pagination: {
              ...this.state.pagination,
              total: res.data.total,
              current: res.data.current_page
            }
          })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  handleChangeValue = (objChange) => {
    const obj = {
      ...this.state.initValue,
      ...objChange
    }
    this.setState({
      initValue: obj
    })
    this.loadData(obj)
  }

  /**
   * 検索入力枠でエンターを押したときに動く
   */
  keyPress(e) {
    if (e.key === 'Enter') {
      this.handleChangeValue({ SearchChar: e.target.value })
    }
  }

  changeData = (obj) => {
    this.handleChangeValue(obj)
  }


  copyJudgementData = (params) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362031_CopyJudgementData
            judgeMethod={params}
            // judgementLevel={this.state.judgementLevel}
            // judgementLevelItem={this.state.judgementLevelItem}
            onFinishScreen={() => {
              this.loadData(this.state.initValue)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  deleteJudgementData = (params) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 500,
        component: (
          <WS0362032_DeleteJudgementData
            judgeMethod={params}
            onFinishScreen={() => {
              this.loadData(this.state.initValue)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editFormSingle = (record, sex) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 900,
        component: (
          <WS0362011_InspectItemJudgeValueSettingSub
            judgeMethod={0}
            record={record}
            sex={sex}
            newFlag={false}
            onFinishScreen={() => {
              this.loadData(this.state.initValue)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editFormComplex = (record, sex) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 900,
        component: (
          <WS0362012_InspectItemComplexJudgeValueSettingSub
            judgeMethod={1}
            record={record}
            sex={sex}
            newFlag={false}
            onFinishScreen={() => {
              this.loadData(this.state.initValue)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='inspect-item-info-maintain'>
        <Card title='検査項目判定値設定' >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}
              // icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={() => { this.copyJudgementData(0) }}>
                      複写（単品）
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={() => { this.copyJudgementData(1) }}>
                      複写（併合）
                    </Menu.Item>
                    <Menu.Item key='4' style={{ width: '100%' }} onClick={() => { this.deleteJudgementData(0) }}>
                      削除（単品）
                    </Menu.Item>
                    <Menu.Item key='5' style={{ width: '100%' }} onClick={() => { this.deleteJudgementData(1) }}>
                      削除（併合）
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <div className='box_inner_horizontal'>
              <Form.Item name='SearchChar' label='検索'>
                <Input
                  maxLength={256}
                  onPressEnter={(e) => this.handleChangeValue({ SearchChar: e.target.value })}
                />
              </Form.Item>
              <Form.Item name='stsJugdement' valuePropName='checked'>
                <Checkbox
                  onChange={(e) => this.handleChangeValue({ stsJugdement: e.target.checked ? 1 : 0 })}>
                  有効
                </Checkbox>
              </Form.Item>
              <Form.Item name='stsUsed' valuePropName='checked'>
                <Checkbox
                  onChange={(e) => this.handleChangeValue({ stsUsed: e.target.checked ? 1 : 0 })}>
                  使用
                </Checkbox>
              </Form.Item>
              <Form.Item name='stsSetting' valuePropName='checked'>
                <Checkbox
                  onChange={(e) => this.handleChangeValue({ stsSetting: e.target.checked ? 1 : 0 })}
                >
                  単品設定
                </Checkbox>
              </Form.Item>
              <Form.Item name='stsComplexSetting' valuePropName='checked'>
                <Checkbox
                  onChange={(e) => this.handleChangeValue({ stsComplexSetting: e.target.checked ? 1 : 0 })}
                >
                  併合設定
                </Checkbox>
              </Form.Item>
            </div>

            {/*
                絞り込みがうまくいかなかったため保留
              <Col span={3} style={{ textAlign: 'center' }}>
                <Form.Item name='stsSetting' valuePropName='checked' style={{ margin: 0 }}>
                  <Checkbox onChange={(e) => this.handleChangeValue({ stsSetting: e.target.checked ? 1 : 0 })}>設定</Checkbox>
                </Form.Item>
              </Col>
               */}
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              // pagination={{
              //   ...this.state.pagination,
              //   onChange: (page, pageSize) => {
              //     this.setState({
              //       initValue: {
              //         ...this.state.initValue,
              //         page,
              //         pageSize
              //       }
              //     }, () => this.loadData(this.state.initValue))
              //   }
              // }}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () =>
                  this.editFormSingle(record, 1)
              })}
              scroll={{ y: resizableTableScroll(160) }}
              // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
              // pagination={this.state.pagination.total > this.state.pagination.pageSize ? this.state.pagination : false}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
            >
              <Table.Column title='コード' dataIndex='test_item_code' width={100} render={(value) => (
                <div style={{ textAlign: 'right' }}>{value === 0 ? null : value}</div>
              )} />
              <Table.Column title='検査名称' dataIndex='exam_name' />
              <Table.Column title='タイプ' dataIndex='InspectType' width={60} />
              <Table.Column title='単位' dataIndex='unit' width={80} />
              <Table.Column title='有効' dataIndex='StsJudgeCode' align='center' width={40} />
              <Table.Column title='単品' align='center' dataIndex='stsSingle' width={40} />
              <Table.Column title='併合' align='center' dataIndex='stsComplex' width={40} />
              <Table.Column title='使用' align='center' dataIndex='stsUsed' width={40} />

              <Table.Column align='center' width={40} fixed='right'
                render={(text, record, index) => (
                  <Space >
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.editFormSingle(record, 1)}>
                            男性単品
                          </Menu.Item>
                          <Menu.Item key='2' style={{ width: '100%' }} onClick={() => this.editFormSingle(record, 2)}>
                            女性単品
                          </Menu.Item>
                          <Menu.Item key='3' style={{ width: '100%' }} onClick={() => this.editFormComplex(record, 1)}>
                            男性併合
                          </Menu.Item>
                          <Menu.Item key='4' style={{ width: '100%' }} onClick={() => this.editFormComplex(record, 2)}>
                            女性併合
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </Space>
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0362001_InspectItemJudgeValueSetting)
