import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Form, Table, Menu, Dropdown, Modal, message } from 'antd'
import { MoreOutlined, PlusOutlined, } from '@ant-design/icons'
import { getDataSiteFindingsMasterMaintainAction } from 'redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions'
import WS0356011_SiteFindingsMasterMaintainSub from './WS0356011_SiteFindingsMasterMaintainSub.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { deleteSiteFindingsData } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";

class WS0356001_SiteFindingsMasterMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '部位所見マスタ'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      dataSource: [],
      colorText: 208,

    }
  }

  componentDidMount = () => {
    this.loadData()
  }

  loadData = (params) => {
    getDataSiteFindingsMasterMaintainAction()
      .then((res) => {
        if (res) {
          let data = res.data
          this.setState({
            dataSource: data
          })
        }
      })
      .catch()
  }

  handleChangeValue = (objChange) => {
    const obj = {
      ...this.state.initValue,
      ...objChange
    }
    this.setState({
      initValue: obj
    })
    this.loadData(obj)
  }


  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <WS0356011_SiteFindingsMasterMaintainSub
            newFlag={false}
            Li_SiteClassificationCode={record.site_classification_code}
            Li_SiteClassificationName={record.site_classification_name}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <WS0356011_SiteFindingsMasterMaintainSub
            newFlag={true}
            Li_SiteClassificationCode={0}
            Li_SiteClassificationName={''}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  reviewScreen() {
    this.loadData(this.state.initValue)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  delete = (record) => {
    deleteSiteFindingsData({
      id: record.id,
      site_classification_code: record.site_classification_code
    })
      .then(async () => {
        this.loadData()
        message.success('削除しました');
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  render() {
    return (
      <div className='use-inspect'>
        <Card title='部位所見マスタ' style={{ width: 'fit-content' }}>
          <Form ref={this.formRef} >
            <Table bordered
              style={{ width: 600 }}
              dataSource={this.state.dataSource}
              size='small'
              pagination={false}
              // pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              scroll={{ y: 600 }}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () =>
                  this.editForm(record)
              })}
            >
              <Table.Column width={70} title='コード' dataIndex='site_classification_code' key='' />
              <Table.Column title='部位分類名称' dataIndex='site_classification_name' key='' />
              <Table.Column width={100} title='カテゴリ使用' dataIndex='stsUsed' key='' align='center' />
              <Table.Column width={40} align='center'
                title={
                  <Button type='primary'
                    icon={<PlusOutlined />}
                    onClick={this.addForm}
                    size='small'
                  ></Button>
                }
                render={(value, record, index) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='edit'
                          onClick={() => { this.editForm(record) }}
                        >
                          編集
                        </Menu.Item>
                        <Menu.Item
                          key='delete'
                          onClick={() => {
                            // 確認モーダルを表示してから、削除
                            Modal.confirm({
                              content: `削除を行いますか?`,
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                this.delete(record)
                              }
                            })
                          }}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0356001_SiteFindingsMasterMaintain)
