import ContractMasterDetailsService from "services/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetailsService"
import { message } from "antd";

const ContractMasterDetailsAction = {
  getListData(data) {
    return ContractMasterDetailsService.getListData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  getContentInput(data) {
    return ContractMasterDetailsService.getContentInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  GetScreenDataCommissionedOriginalInput(data) {
    return ContractMasterDetailsService.GetScreenDataCommissionedOriginalInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  getCommissionedOriginalInput(data) {
    return ContractMasterDetailsService.getCommissionedOriginalInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  insert(data) {
    return ContractMasterDetailsService.insert(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  update(data) {
    return ContractMasterDetailsService.update(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  updateConsignor(data) {
    return ContractMasterDetailsService.updateConsignor(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  save(data) {
    return ContractMasterDetailsService.save(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  deleteContractMaster(data) {
    return ContractMasterDetailsService.deleteContractMaster(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  deleteCommissionedOriginalInput(data) {
    return ContractMasterDetailsService.deleteCommissionedOriginalInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  deleteConsignor(data) {
    return ContractMasterDetailsService.deleteConsignor(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
}
export default ContractMasterDetailsAction;