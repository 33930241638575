import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import { SaveOutlined, PlusOutlined } from '@ant-design/icons'
import { Card, Form, Input, Select, Button, Table, Row, Col, Space, message, } from 'antd'
import WS1485002_AddHistoryOn from './WS1485002_AddHistoryOn'
import WS1485016_HistorySelect from './WS1485016_HistorySelect'
import RefPeopleNumSettingInfoAction from "redux/SystemMaintenance/RefPeopleNumSettingInfo/RefPeopleNumSettingInfo.action";

class WS1485001_RefPeopleNumSettingInfo extends React.Component {
  formRef = React.createRef()
  formRefTable = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '基準人数設定情報'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      FacilityType_List: [],
      tableHeader: [],
      isLoadingTable: false,
      dataSource: []
    }
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  getScreenData = (history) => { // 検索のoption
    const manageDivision = this.formRef.current?.getFieldValue('ManageDivision') ? this.formRef.current?.getFieldValue('ManageDivision') : 1
    const facilityType = this.formRef.current?.getFieldValue('FacilityType') ? this.formRef.current?.getFieldValue('FacilityType') : 1

    this.setState({ isLoadingTable: true })

    RefPeopleNumSettingInfoAction.getScreenData()
      .then((res) => {
        this.setState({
          ManageDivision: manageDivision,
          FacilityType_List: res.FacilityType_List,
          FacilityType: facilityType,
        })

        this.formRef.current?.setFieldsValue({
          ManageDivision: manageDivision,
          FacilityType: facilityType,
          History: history ? history : res.History,
        })

        this.getTableData({
          Li_History: history ? history : res.History,
          Li_ManageDivision: manageDivision,
          Li_FacilityType: facilityType,
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  getTableData(params) { // テーブル
    this.setState({ isLoadingTable: true })

    RefPeopleNumSettingInfoAction.referenceNumPeopleSet(params)
      .then((res) => {
        let tableHeader = []
        let name
        let columnName
        let checked
        let StandardHeadcountSettingInformationCounter = 0
        for (let i = 1; i <= 20; i++) {
          name = 'name_' + String(i).padStart(2, '0')
          columnName = 'limit_' + String(i).padStart(2, '0')
          if (res.StandardHeadcountSettingInformation[StandardHeadcountSettingInformationCounter]) {
            checked = res.StandardHeadcountSettingInformation[StandardHeadcountSettingInformationCounter]?.on_check === 1 ? true : false
          } else {
            checked = false
          }
          tableHeader.push({
            title: res.result.length ? res.result[0][name] : '',
            index: i,
            name: columnName,
            [columnName]: checked
          })
          StandardHeadcountSettingInformationCounter++
        }
        this.setState({
          tableHeader: tableHeader,
          dataSource: res.result ? res.result : []
        })
        this.formRef.current?.setFieldsValue({
          dataTable: res.result ? res.result : [],
        })
      })
      .catch((err) => {
        console.log(err)
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  handleChange() {
    this.getScreenData()
  }

  handleChangeLimit(record, name, index, value) {
    record[name] = value
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  saveReferenceNumPeopleSet() {
    let params = {
      mainSource: this.state.dataSource,
      Li_ManageDivision: this.state.ManageDivision,
      Li_FacilityType: this.state.FacilityType,
    }

    ModalConfirm({
      content: '一括保存してよろしいですか',
      styleIconModal: { color: '#006CC9' },
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        RefPeopleNumSettingInfoAction.saveReferenceNumPeopleSet(params)
          .then(res => message.info(res?.message))
          .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      }
    })
  }

  addHistoryOn() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS1485002_AddHistoryOn
            Lio_History={this.formRef.current.getFieldValue('History')}
            onFinishScreen={(output) => {
              RefPeopleNumSettingInfoAction.addHistoryOn(output)
                .then(res => {
                  if(res?.message) {
                    message.info(res.message)
                  }
                })
                .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
              this.getScreenData(output.CopyTo)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='ref-people-num-setting-info'>
        <Card title='基準人数設定情報'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={this.state.initialValues}
          >
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item
                  name='ManageDivision'
                  label='管理区分'>
                  <Select onChange={() => {
                    this.handleChange()
                  }}>
                    <Select.Option value={1} selected>ｺｰｽ</Select.Option>
                    <Select.Option value={2}>検査</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='FacilityType'
                  label='施設区分'>
                  <Select onChange={() => {
                    this.handleChange()
                  }}>
                    {this.state.FacilityType_List.map((facility) => (
                      <Select.Option key={'FacilityType-' + facility.facility_type} value={facility.facility_type}>{facility.facility_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Space>
                  <Form.Item
                    name='History'
                    label='履歴'>
                    <Input.Search
                      type='text'
                      readOnly
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              // ssd
                              <WS1485016_HistorySelect
                                Lio_History={this.formRef.current.getFieldValue('History')}
                                onFinishScreen={(output) => {
                                  this.getScreenData(output)
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            {/* 履歴追加ボタン */}
            <Button
              type='primary'
              icon={<PlusOutlined />}
              style={{ float: 'right', marginBottom: '1em' }}
              onClick={() => {
                this.addHistoryOn()
              }}
            >
              <span className='btn_label'>
                履歴追加
              </span>
            </Button>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ x: 1800, y: 700 }}
            >
              <Table.Column width={80} title='曜日' align='center' fixed='left' dataIndex='Expression_25'
                render={(text, record, index) => {
                  if (record.week == 1) {
                    return (<span style={{ color: 'red' }}>{text}</span>)
                  }
                  if (record.week == 7) {
                    return (<span style={{ color: 'blue' }}>{text}</span>)
                  }

                  return (<span>{text}</span>)
                }}
              />
              <Table.Column width={80} title='時間帯' align='center' fixed='left' dataIndex='time_type' />
              {
                this.state.tableHeader?.map(
                  (title, idx) => {
                    return (
                      <Table.Column
                        key={title.title + title.name}
                        title={title.title}
                        width={120}
                        align='center'
                        dataIndex={title.name}
                        render={(text, record, index) => {
                          return (
                            <Form.Item name={['dataTable', index, title.name]}>
                              <Input
                                onBlur={(e) => {
                                  this.handleChangeLimit(record, title.name, index, e.target.value)
                                }}
                              />
                            </Form.Item>
                          )
                        }}
                      />
                    )
                  }
                )
              }
            </Table>
            {/* 保存ボタン */}
            <Button
              type='primary'
              icon={<SaveOutlined />}
              style={{ float: 'right', marginTop: '1em' }}
              onClick={() => {
                this.saveReferenceNumPeopleSet()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1485001_RefPeopleNumSettingInfo)
