import { message } from "antd";
import HistoryListService from "services/MN_Basis/HistoryList/HistoryList.Service";

const HistoryListAction ={
    getScreenData(data) {
        return HistoryListService.getScreenData(data)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                const res = err.response;
                if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                }
                message.error(res.data.message);
            });
    },
    addChangeData(data) {
        return HistoryListService.addChangeData(data)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                const res = err.response;
                if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                }
                message.error(res.data.message);
            });
    },
}
export default HistoryListAction;