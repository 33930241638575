/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Modal, Button, message, Tooltip, Dropdown, Menu, Row, Col, Switch, Space } from 'antd'

import { PlusOutlined, MoreOutlined, MenuOutlined } from '@ant-design/icons'

import WS0460008_Copy from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460008_Copy.jsx'
import WS0460005_ParamInput from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460005_ParamInput.jsx'
import WS0449001_UserParamMaintain from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449001_UserParamMaintain.jsx'
import CsvCreateParamMaintainAction from 'redux/ResultOutput/CsvCreateParamMaintain/CsvCreateParamMaintain.action'
import WS0461005_UserParamInput0 from './WS0461005_UserParamInput0'
import WS0460501_CsvCreateParamDetails from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460501_CsvCreateParamDetails.jsx'

const radioStyle = {
  display: 'block',
  lineHeight: 1.5
}

const labelStyle = {
  fontWeight: 'bold',
  color: '#14468c',
  marginTop: 20,
  marginBottom: 5
}
class WS0460001_CsvCreateParamMaintain extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_Format: PropTypes.any,
    Li_Name: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = 'CSV作成パラメータ保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      showDelimiter: false,

      dataSource: [],
      isLoadingTable: true,
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: 0,

      screenSwitch: null,
      isLoadingForm: false,

      nameCondition: '',

      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true,
        showSizeChanger: true
      },

      isSwitchOn: false
    }

    this.handleAddRowTable = this.handleAddRowTable.bind(this)
  }

  componentDidMount() {
    this.getData(true)
  }

  //必要なら戻す
  // componentDidUpdate(prv) {
  //   if (this.props !== prv) {
  //     this.getData(true)
  //   }
  // }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  getData(reload) {
    this.setState({ isLoadingTable: true })
    CsvCreateParamMaintainAction.getListData()
      .then(async (res) => {
        let data = res ? res : []
        let index = reload ? 0 : data.findIndex(x => x.id === this.state.dataSource[this.state.indexTable].id)
        await this.setState({
          dataSource: data,
          isLoadingTable: false,

          rowSelected: data.length > 0 ? [data[index]] : [],
          selectedRowKeys: data.length > 0 ? [data[index].id] : [],
          indexTable: index,

          screenSwitch: data.length > 0 ? data[0].ScreenSwitch : null
        })
        // this.getDataAdvancedSetting()
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  updateRecordData(index) {
    let params = {
      ...this.state.dataSource[index],
      ...this.formRef.current?.getFieldValue()
    }

    CsvCreateParamMaintainAction.saveData(params)
      .then((res) => {
        this.getData(this.state.dataSource[index].id ? false : true)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  btnF12(version) {
    let params = {
      Li_Version: version ? version : this.state.screenSwitch,
      Li_Format: this.state.rowSelected[0].Format
    }

    CsvCreateParamMaintainAction.f12(params)
      .then((res) => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '90%',
            component: (
              <WS0461005_UserParamInput0
                Li_Format={this.state.rowSelected[0].Format}
                Li_Name={this.state.rowSelected[0].DocumentName}
                onFinishScreen={(output) => {

                  this.closeModal()
                }} />
            ),
          },
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource]

    data[index][field] = value

    this.setState({
      dataSource: data
    })
  }

  // check id null
  checkIdTemp(id) {
    if (id === '') {
      return true
    }
    return false
  }

  checkAddItem() {
    if (this.state.dataSource.length > 0) {
      let index = this.state.dataSource.findIndex(x => !x.No)
      if (index === -1) {
        return false
      }
      return true
    }
  }

  checkDuplicateNo() {
    let lstData = [...this.state.dataSource]
    const uniqueValues = new Set(lstData.map(v => v.No))

    if (uniqueValues.size < lstData.length) {
      return true
    } return false
  }

  checkDisabledBtnAdd() {
    if (this.state.rowSelected.length > 0) {
      if (
        // this.checkAddItem() ||
        this.checkIdTemp(this.state.rowSelected[0].id)) {
        return true
      } return false
    } return false
  }

  // check selected record while add new
  changeRow(index) {
    let data = [...this.state.dataSource]

    let idTemp = false
    data.forEach(item => {
      if (this.checkIdTemp(item.id)) {
        idTemp = true
        return idTemp
      }
    })

    if (idTemp) {
      this.setState({
        rowSelected: [data[0]],
        selectedRowKeys: [data[0].id],
        indexTable: 0
      })
    } else {
      this.setState({
        indexTable: index
      })
    }
  }

  // add new record
  async handleAddRowTable() {
    let newRow = {
      No: '',
      PersonalInfo: 0
    }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      rowSelected: [newRow],
      selectedRowKeys: [newRow.id],
      indexTable: 0
    })

    this.getDataAdvancedSetting()
    this.forceUpdate()
  }

  async handleDeleteRowTable() {
    let data = [...this.state.dataSource]
    await data.splice(0, 1)
    await this.setState({
      dataSource: data,
      indexTable: 0,
      rowSelected: data.length > 0 ? [data[0]] : [],
      selectedRowKeys: data.length > 0 ? [data[0].id] : []
    })

    this.getDataAdvancedSetting()
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  updateCourse(course) {
    let params = {
      IH_Course: this.formRef.current?.getFieldValue('Course'),
      IV_Course: course
    }
    CsvCreateParamMaintainAction.gzoomCource(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          Course: res?.Course
        })
      })
  }

  getNameCondition() {
    let params = {
      ConditionNum: this.formRef.current?.getFieldValue('ConditionNum')
    }
    CsvCreateParamMaintainAction.getNameCondition(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          name: res?.data?.name
        })

        this.setState({
          nameCondition: res?.data?.name
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onFinish(values) { }

  ParamInput = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <WS0460005_ParamInput
            onFinishScreen={(output) => {
              this.getData(true)
              this.closeModal()
            }} />
        ,
      },
    })
  }

  editDetails = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS0460501_CsvCreateParamDetails
            record={record}
            advanceSettingDatas={this.state.advanceSettingDatas}
            onFinishScreen={(res) => {
              message.success(res.message)
              this.getData()
              this.closeModal()
            }}
          />
      }
    })
  }

  copyDatas = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS0460008_Copy
            Li_NoF={this.state.rowSelected.length > 0 ? this.state.rowSelected[0].No : ''}
            Li_FormatF={this.state.rowSelected.length > 0 ? this.state.rowSelected[0].Format : ''}
            Li_DocumentName={this.state.rowSelected.length > 0 ? this.state.rowSelected[0].DocumentName : ''}
            dataSource={this.state.dataSource}
            onFinishScreen={(output) => {
              this.getData(true)
              this.closeModal()
            }} />
      },
    })
  }

  editParams = (record) => {
    let format = record.format
    switch (format) {
      case '':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 600,
            component:
              <WS0449001_UserParamMaintain
                onFinishScreen={(output) => {
                  this.closeModal()
                }}
              />
            ,
          },
        })
        break
      default:
        this.btnF12()
        break
    }
  }

  addRow = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS0460501_CsvCreateParamDetails
            onFinishScreen={() => {
              this.getData()
              this.closeModal()
            }}
          />
      }
    })
  }

  deleteData = (record) => {
    Modal.confirm({
      content: '削除してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        const params = {
          id: record.id,
          Li_Format: record.Format
        }
        CsvCreateParamMaintainAction.deleteData(params)
          .then(res => {
            message.success(res.message)
            this.getData(true)
          })
          .catch((err) => {
            message.error(err.message)
          })
      }
    })
  }

  render() {
    return (
      <div className='csv-create-param-maintain'>
        <Card title='CSV作成パラメータ保守'>
          <Tooltip title='オプションメニュー'>
            <Dropdown
              size='small'
              className={(this.props.modalFlag ?? false) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.ParamInput()}>
                      作成
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className={(this.props.modalFlag ?? false) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Table
            size='small'
            style={{ cursor: 'pointer' }}
            rowClassName={(record, index) => record.id === this.state.rowSelected[0]?.id ? 'table-row-light' : ''}
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={this.state.isSwitchOn ? false : this.state.pagination}
            bordered
            rowKey={(record) => record.id}
            scroll={{ x: 800, y: 600 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: async () => {
                  let oldRecord = this.state.rowSelected[0]
                  let index = this.state.dataSource.findIndex(x => x.id === record.id)
                  await this.setState({
                    rowSelected: [record],
                    selectedRowKeys: [record.id],
                    indexTable: index
                  })
                },
                onDoubleClick: async () => {
                  this.editDetails(record)
                }
              }
            }}
          >
            <Table.Column
              title='No' dataIndex='No' width={50}
              render={(value) => <div style={{ textAlign: 'right' }}>{value}</div>}
            />
            <Table.Column title='FORMAT' dataIndex='Format' width={150} />
            <Table.Column title='帳票名' dataIndex='DocumentName' width={220} />
            <Table.Column title='個人情報' dataIndex='PersonalInfo' width={70}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    {record.PersonalInfo === 0 ? 'あり' : ''}
                  </div>
                )
              }}
            />
            <Table.Column title='出力先' dataIndex='CsvFile' />
            <Table.Column
              align='center'
              width={40}
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => this.addRow()}
                />
              }
              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger='click'
                    size='small'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='1'
                          onClick={() => this.editDetails(record)}
                        >
                          編集
                        </Menu.Item>
                        <Menu.Item
                          key='2'
                          onClick={() => this.editParams(record)}
                        >
                          パラメータ
                        </Menu.Item>
                        <Menu.Item
                          key='3'
                          onClick={() => this.copyDatas()}
                        >
                          複写
                        </Menu.Item>
                        <Menu.Item
                          key='4'
                          onClick={() => this.deleteData(record)}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )
              }}
            />
          </Table>

          <Row>
            <Col span={23}></Col>
            <Col span={1}>
              <Space>
                <Switch style={{ marginTop: '10px' }}
                  checkedChildren="全て"
                  defaultChecked={false}
                  onClick={(checked) => { this.setState({ isSwitchOn: checked }) }}></Switch>
              </Space>
            </Col>
          </Row>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0460001_CsvCreateParamMaintain)
