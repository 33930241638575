import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SpecifiedValueConfirmAction from 'redux/InputBusiness/NotInputCheckCategory/SpecifiedValueConfirm.action'
import { Button, Card, Table, Tooltip, Dropdown, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2713014_NormalValueSetting from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713014_NormalValueSetting.jsx'

class WS2644008_SpecifiedValueConfirm extends React.Component {
  static propTypes = {
    Lo_StsSelect: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_PatternCode: PropTypes.any,
    Li_JudgeLevel: PropTypes.any,

  }

  constructor(props) {
    super(props)

    // document.title = '規定値確認'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      indexTable: 0,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    const params = {
      inspectList: this.props.inspectList,
    }
    SpecifiedValueConfirmAction.index(params)
      .then(res => {
        this.setState({ dataSource: res })
      })
  }


  /**
   * 規定値　[実行]ボタン押下処理
   * 正常値をテーブルに反映
   */
  save = () => {
    if (this.props.onFinishScreen) {
      const { Li_ReserveNum, Li_PatternCode, Li_CategoryCode, inspectList, format } = this.props
      const params = {
        Li_ReserveNum: Li_ReserveNum,
        Li_CategoryCode: Li_CategoryCode,
        Li_PatternCode: Li_PatternCode,
        inspectList: inspectList,
        format: format,
      }
      SpecifiedValueConfirmAction.save(params)
        .then(res => {
          this.props.onFinishScreen(true)
        })
    } else {
      this.props.onUpdate(true)
    }
  }

  /**
   * 正常値設定 モーダル
   */
  showWS2713014_NormalValueSetting() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2713014_NormalValueSetting
            onFinishScreen={(obj) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='specified-value-confirm'>
        <Card
          title='規定値確認'
          style={{ position: 'relative' }}>
          {/* <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top_modal'
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.showWS2713014_NormalValueSetting()}
                    >
                      正常値設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top_modal'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip> */}

          <p style={{ marginBottom: '10px' }}>以下の検査に規定値を設定しますか？</p>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            scroll={{ y: 400 }}
            rowKey={(record) => record.id}
            // rowClassName={(record, index) => record.id === this.state.selectedRows[0]?.id ? 'table-row-light' : ''}
            onRow={(record, rowIndex) => {
              return {
                onClick: async (event) => {
                  const nodeName = event.target && event.target.nodeName
                  let index = this.state.dataSource.findIndex(x => x.id === record.id)
                  if (nodeName !== 'svg') {
                    await this.setState({
                      selectedRows: [record],
                      selectedRowKeys: [record.id],
                      indexTable: index
                    })
                  }

                }
              }
            }}
          >
            <Table.Column
              title='検査コード'
              dataIndex='exam_code'
              width={100}
              render={(value) =>
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              }
            />
            <Table.Column
              title='検査略名'
              dataIndex='exam_short_name'
            />
            <Table.Column
              title='規定値'
              dataIndex='specifiedValue'
            />
          </Table>

          {/* Footer */}
          <div className='box_button_bottom_right'>

            <Button
              onClick={() => {
                this.props.onFinishScreen()
              }}>
              キャンセル
            </Button>

            <Button
              type='primary'
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                実行
              </span>
            </Button>
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >

    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2644008_SpecifiedValueConfirm)
