import SettleInputSubService from "services/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/SettleInputSubService"
import { message } from "antd";

const SettleInputSubAction = {
  getScreenData(data) {
    return SettleInputSubService.getScreenData(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  healthCheckupAdd(data) {
    return SettleInputSubService.healthCheckupAdd(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  sameTime(data) {
    return SettleInputSubService.sameTime(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  save(data) {
    return SettleInputSubService.save(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  healthCheckupAddSave(data) {
    return SettleInputSubService.healthCheckupAddSave(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  sameTimeSave(data) {
    return SettleInputSubService.sameTimeSave(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}
export default SettleInputSubAction;