import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import { Card, Table, Form, Button } from 'antd'
import WS0714002_HistoryInputSubChange from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0714002_HistoryInputSubChange.jsx'
import HistoryInputSubAction from 'redux/InputBusiness/NotInputCheckCategory/HistoryInputSub.action'

class WS0714001_HistoryInputSub extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    LnkinQueryMode: PropTypes.any,
    Li_HistoryList: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = '既往歴入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      StateDivisionCharList: [],
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    HistoryInputSubAction.index()
      .then(res => {
        if (res) {
          // 病歴状態区分
          this.setState({ StateDivisionCharList: res.StateDivisionCharList })
        }
        this.getHistoryList()
      })
  }

  /**
   * 既往歴一覧を取得
   */
  getHistoryList() {
    const params = {
      personal_number_id: this.props.Li_PersonalNum,
      LnkinQueryMode: this.props.LnkinQueryMode,
      Li_HistoryList: this.props.Li_HistoryList,
    }
    HistoryInputSubAction.getHistoryList(params)
      .then(res => {
        const data = res ? res : []
        if (data) {
          this.setState({ dataSource: data })
        }
      })
  }

  /**
   * 既往歴入力　モーダル
   * @param {*} record
   */
  showWS0714002_HistoryInputSubChange = (record = null) => {
    console.log();
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0714002_HistoryInputSubChange
            record={record}
            Li_PersonalNum={this.props.Li_PersonalNum}
            StateDivisionCharList={this.state.StateDivisionCharList}
            onUpdate={() => {
              // データ再取得
              this.getHistoryList()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='history-input-sub'>
        <Card title='既往歴入力'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: 500 }}
            >
              <Table.Column
                title='病名コード'
                dataIndex='disease_name_code'
                width={85}
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='病名'
                dataIndex='name_of_a_disease'
                width={210}
              />
              <Table.Column
                title='年齢'
                dataIndex='age'
                width={60}
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='状態区分'
                dataIndex='state_division'
                width={100}
                render={(value, record, index) => {
                  let label = ''
                  // 紐づく名称を取得
                  const obj = this.state.StateDivisionCharList.find(item => item.node_code_name === value)
                  if (obj) {
                    label = obj.name
                  }
                  return label
                }}
              />

              <Table.Column
                title='コメント'
                dataIndex='comment'
              />
              <Table.Column
                width={45}
                align='center'
                title={
                  <Button
                    type='primary'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      // 新規追加の場合
                      this.showWS0714002_HistoryInputSubChange()
                    }}
                  />
                }
                render={(item, record, index) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // 編集の場合
                      this.showWS0714002_HistoryInputSubChange(record)
                    }}
                  />
                )}
              />

            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0714001_HistoryInputSub)
