import { DeleteOutlined, PlusOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Menu, message, Dropdown, Row, Select, Table, Tooltip } from "antd";
import Color from 'constants/Color';
import WS0447011_ColorSample from 'pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0447011_ColorSample.jsx';
import WS0448001_DetermineLevelBulkModify from 'pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0448001_DetermineLevelBulkModify.jsx';
import PropTypes from 'prop-types';
import React from "react";
import { connect } from "react-redux";
import DetermineLevelModifyMasterCoerciveAction from 'redux/InspectionMaintenance/DetermineLevelModify/DetermineLevelModifyMasterCoercive.actions';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0447500_DetermineLevelModifyMasterCoerciveEdit from 'pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0447500_DetermineLevelModifyMasterCoerciveEdit'

class WS0447001_DetermineLevelModifyMasterCoercive extends React.Component {
  static propTypes = {
    Li_JudgeLevel: PropTypes.any,
    Li_BasicJudgeResultList: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS05700:判定レベル変換マスタ保';

    this.state = {
      isLoadding: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      JudgeLevel: [],
      count: "a",
      listColor: [],
      CodeColor: "",
      checkF10: false
    };
  }

  componentDidMount() {
    this.forceUpdate()
    this.GetScreenData()
    this.DisplayList()
    this.GetColor()
  }

  GetColor() {
    DetermineLevelModifyMasterCoerciveAction.GetColor()
      .then(res => {
        this.setState({
          listColor: res?.data
        })
      })
  }

  GetScreenData() {
    DetermineLevelModifyMasterCoerciveAction.GetScreenData().then(res => {
      this.setState({ JudgeLevel: res })
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    })
  }

  DisplayList() {
    this.setState({ isLoadding: true })
    const data = { JudgeLevel: this.formRef.current?.getFieldValue("JudgeLevel") }
    DetermineLevelModifyMasterCoerciveAction.DisplayList(data)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          tableData: res
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadding: false }))
  }

  onFinish(values) {

  }

  closeModal = () => {
    this.setState((prevState) => ({
      childModal: { ...prevState.childModal, visible: false },
    }));
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  AddNewRow() {
    this.handleAdd();
  }

  handleAdd() {
    const { count } = this.state;
    let data = [...this.formRef.current?.getFieldValue("tableData")];
    const newData = {
      id: count,
      JudgeLevelChar: this.state.JudgeLevel?.[0]?.condition_1,
      W1_judge_result: "",
      W1_weight_judge: 0,
      W1_standard_judge_result: "",
      W1_judge_name: "",
      ColorSample: this.state.listColor?.[0]?.ColorSample,
      W1_web_color: ""
    }
    data.length > 0 ? data.unshift(newData) : data.push(newData)
    this.formRef.current?.setFieldsValue({
      tableData: data,
    });
    this.forceUpdate()
    this.setState({
      ...this.state.count,
      count: count + 1,
    })
  }

  Delete(record) {
    if (isNaN(record.id)) {
      let arr = [...this.formRef.current?.getFieldValue("tableData")];
      arr.map((value, index) => {
        if (value.id === record.id) {
          arr.splice(index, 1)
          this.formRef.current?.setFieldsValue({
            tableData: arr
          })
          this.forceUpdate()
        }
      })
    } else {
      this.setState({ isLoadding: true })
      DetermineLevelModifyMasterCoerciveAction.Delete({ id: record.id }).then(res => {
        this.DisplayList()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadding: false }))
    }
  }

  SaveData(record) {
    let arr = [...this.formRef.current?.getFieldValue("tableData")];
    if (isNaN(record.id)) {
      for (let idx = 0; idx < arr.length; idx++) {
        if (arr[idx].id === record.id) {
          if (this.isEmpty(arr[idx].W1_judge_result)) {
            return
          }
          const dataSave = {
            JudgeLevelChar: arr[idx].JudgeLevelChar,
            W1_judge_result: arr[idx].W1_judge_result,
            W1_weight_judge: arr[idx].W1_weight_judge,
            W1_standard_judge_result: arr[idx].W1_standard_judge_result,
            W1_judge_name: arr[idx].W1_judge_name,
            ColorSample: arr[idx].ColorSample,
            W1_web_color: arr[idx].W1_web_color
          }
          this.Save(dataSave)
          return;
        }
      }
    } else {
      for (let idx = 0; idx < arr.length; idx++) {
        if (arr[idx].id === record.id) {
          if (this.isEmpty(arr[idx].W1_judge_result)) {
            return
          }
          this.Save(arr[idx])
          return;
        }
      }
    }
  }

  Save(dataSave) {
    this.setState({ isLoadding: true })
    DetermineLevelModifyMasterCoerciveAction.ListUpdate(dataSave).then(res => {
      this.DisplayList()
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    }).finally(() => this.setState({ isLoadding: false }))
  }

  ShowDetermineLevelBulkModify() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0448001_DetermineLevelBulkModify
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                StsChange: output?.Lo_StsChange
              })
              this.GetScreenData()
              this.DisplayList()
            }}
          />
        ),
      },
    })
  }

  /**
   * 編集画面
   * @param {*} record 
   */
  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0447500_DetermineLevelModifyMasterCoerciveEdit
            Li_EditFlag={true}
            JudgeLevel={this.state.JudgeLevel}
            record={record}
            onSave={(output) => {
              let params = {
                ...output,
                addFlag: 0,
                id: record.id
              }
              this.Save(params)
              this.closeModal()
            }}
            onDelete={(output) => {
              this.Delete(record)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 追加
   */
  addForm() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0447500_DetermineLevelModifyMasterCoerciveEdit
            Li_EditFlag={false}
            JudgeLevel={this.state.JudgeLevel}
            record={''}
            onSave={(output) => {
              let params = {
                ...output,
                addFlag: 1
              }
              this.Save(params)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 判定レベル表示
   * @param {*} condition_1 
   */
  JudgeLevelChar(condition_1) {
    let JudgeLevel = this.state.JudgeLevel.find((value) => value.condition_1 === condition_1)
    return JudgeLevel?.item
  }

  render() {
    const { childModal } = this.state;
    return (
      <div className="determine-level-modify-master-coercive">
        <Card title="判定レベル変換マスタ保守" className="mb-2">
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.ShowDetermineLevelBulkModify()}>
                      判定レベル
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />} />
            </Dropdown>
          </Tooltip>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ JudgeLevel: "" }}
            autoComplete="off"
          >
            <Form.Item
              name="JudgeLevel"
              label="判定レベル"
            >
              <Select style={{ width: 200 }} onChange={() => this.DisplayList()}>
                <Select.Option value="">全て</Select.Option>
                {this.state.JudgeLevel?.map(value => (
                  <Select.Option
                    key={"JudgeLevel-" + Math.random()}
                    value={value?.condition_1}
                  >
                    {value?.item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Table
              bordered
              dataSource={this.formRef.current?.getFieldValue("tableData")}
              size="small"
              pagination={false}
              scroll={{ y: 600 }}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: async () => {
                    await this.setState({
                      checkF10: true
                    })
                  }, // click row
                  onDoubleClick: () => {
                    this.editForm(record)
                  }
                };
              }}
            >
              <Table.Column
                title="判定レベル"
                dataIndex="JudgeLevelChar"
                render={(value, record, index) => {
                  return this.JudgeLevelChar(value)
                }}
              />

              <Table.Column
                title="判定"
                width={70}
                dataIndex="W1_judge_result"
                render={(value, record, index) => {
                  return <div style={{ color: Color(record?.W1_screen_color)?.Foreground }}>{value}</div>
                }}
              />

              <Table.Column
                title="重み"
                width={60}
                dataIndex="W1_weight_judge"
              />
              <Table.Column
                title="基準判定"
                width={100}
                dataIndex="W1_standard_judge_result"
              />
              <Table.Column
                title="判  定  名"
                dataIndex="W1_judge_name"
              />
              <Table.Column
                title="画面色"
                width={150}
                dataIndex="ColorSample"
                render={(value, record, index) => {
                  return <div style={{ color: Color(record?.W1_screen_color)?.Foreground }}>{value}</div>
                }}
              />
              <Table.Column
                title="WEB色"
                dataIndex="W1_web_color"
                render={(value, record, index) => {
                  return <div style={{ color: value }}>{value}</div>
                }}
              />
              <Table.Column
                width={60}
                align="center"
                title={<Button type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => this.addForm()}
                  size='small'
                />}
                render={(value, record, index) => (
                  <Button
                    size="small"
                    icon={<MoreOutlined />}
                    onClick={() => { this.editForm(record) }}
                  />
                )}
              />
              {/* <Table.Column width={80} title={<Button size='small' type='primary' icon={<PlusOutlined />} onClick={() => this.AddNewRow()}  ></Button>}
                render={(text, record, index) => {
                  return <>
                    <Button size='small' style={{ border: 'none' }} icon={<SaveOutlined style={{ color: 'green' }} />}
                      onClick={() => this.SaveData(record)}
                    ></Button>
                    <Button size='small' style={{ border: 'none' }} danger icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          content: '消去してもよろしいですか？',
                          okText: 'は　い',
                          cancelText: 'いいえ',
                          onOk: () => this.Delete(record)
                        })
                      }}
                    ></Button>
                  </>
                }}
              /> */}
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0447001_DetermineLevelModifyMasterCoercive);
