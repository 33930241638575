import React from "react";
import { connect } from "react-redux";
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom';
import PropTypes from "prop-types";
import moment from 'moment';
import { Button, Card, Form, Input, Checkbox, Col, Row, Table, Space, Dropdown, Menu, Tooltip, Select, Popconfirm, message } from "antd";
import {
  MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined,
  CloseCircleOutlined, MoreOutlined, PlusOutlined, DeleteOutlined,
  InfoOutlined, FileTextOutlined, SaveOutlined, CheckOutlined, SyncOutlined
} from "@ant-design/icons";
import { getComplexJudgementData, getStartDate, getStartDateItems, synchronizeJugement, getJudgementLevelList, getStartDateList }
  from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions';
import WS0362022_RegisterJudgementDataComplex from "./WS0362022_RegisterJudgementDataComplex.jsx";
import WS0362031_CopyJudgementData from "./WS0362031_CopyJudgementData.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import ModalChange from "components/Commons/ModalChange";
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}
const dateFormat = 'YYYY/MM/DD';

class WS0362012_InspectItemComplexJudgeValueSettingSub extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);

    // document.title = '検査項目判定値設定Sub';
    //「判定」の正確なスペルは"judgement"だが、テーブルのカラム名が"judgment"になっているため、そのまま"judgment"とする


    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceSingle: [],
      isLoadingPage: false,
      colorText: 208,
      standardDate: moment().format(dateFormat),
      judgementLevel: '00',
      startDate: '0000-00-00'
    };
  }


  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
    // this.getCombobox()
    this.getJudgementLevel();
  }


  getJudgementLevel = () => {
    getJudgementLevelList()
      .then(res => {
        if (res) {
          let judgementLevelItems = res.data
          this.setState({
            judgementLevelItem: judgementLevelItems,
            judgementLevel: judgementLevelItems[0].condition_1,
          })
          this.formRef.current.setFieldsValue({
            judgementLevel: judgementLevelItems[0].condition_1,
          })
        }
        this.getStartDate();
      }
      )
  }

  getStartDate = () => {
    // this.setState({ isLoadingPage: true });
    let values = {
      judgeMethod: this.props.judgeMethod,
      judgment_level: this.state.judgementLevel,
      judgment_code: this.props.record.test_item_code,
      standardDate: this.state.standardDate,
    }
    getStartDateList(values)
      .then(res => {
        if (res) {
          let startDateItems = res.data.dates
          this.setState({
            startDateItem: startDateItems,
            startDate: res.data.date
          })
          this.formRef.current.setFieldsValue({
            startDate: res.data.date
          })
        }
        let params = {
          judgementLevel: this.state.judgementLevel,
          startDate: res.data.date
        }
        this.loadComplexJudgementData(params)
      }
      )
  }


  handleChangeValue = (chengeObject) => {
    let params = {
      judgementLevel: chengeObject.judgementLevel ? chengeObject.judgementLevel : this.state.judgementLevel,
      startDate: chengeObject.startDate ? chengeObject.startDate : this.state.startDate
    }
    this.loadComplexJudgementData(params)
  }

  loadComplexJudgementData = (params) => {
    // this.setState({ isLoadingPage: true });
    let values = this.formRef.current.getFieldsValue(true)
    let paramsS = {
      ...this.props.record,
      ...values,
      ...params,
      sex: this.props.sex
    }
    getComplexJudgementData(paramsS)
      .then(res => {
        if (res) {
          this.setState({
            dataSourceSingle: res.data,
          });
        }
      })
      .catch()
    // .finally(() => this.setState({ isLoadingPage: false }))
  }


  copyJudgementData = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362031_CopyJudgementData
            judgeMethod={1}
            exam_short_name={this.props.record.exam_short_name}
            sex={this.props.sex}
            judgment_code={this.props.record.test_item_code}
            judgementLevel={this.state.judgementLevel}
            judgementLevelItem={this.state.judgementLevelItem}
            startDateF={this.formRef.current.getFieldValue("startDate")}
            startDateItem={this.state.startDateItem}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }




  editJudgementForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362022_RegisterJudgementDataComplex
            id={record.id}
            judgment_code={this.props.record.test_item_code}
            judgment_level={this.formRef.current.getFieldValue("judgementLevel")}
            start_date_on={this.formRef.current.getFieldValue("startDate")}
            sex={this.props.sex}
            exam_type={this.props.record.exam_type}
            exam_name={this.props.record.exam_name}
            exam_comment_code={this.props.record.exam_comment_code}
            record={record}
            newFlag={false}
            // judgement_code={this.props.test_item_code}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addJudgementForm = (params) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362022_RegisterJudgementDataComplex
            id={null}
            exam_type={this.props.record.exam_type}
            exam_name={this.props.record.exam_name}
            exam_comment_code={this.props.record.exam_comment_code}
            judgment_code={this.props.record.test_item_code}
            judgment_level={this.formRef.current.getFieldValue("judgementLevel")}
            start_date_on={this.formRef.current.getFieldValue("startDate")}
            sex={this.props.sex}
            newFlag={true}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  syncJudgement = () => {
    this.setState({ isLoadingPage: true });
    let values = this.formRef.current.getFieldsValue(true);
    let params = {
      ...this.props.record,
      ...values,
      sexF: this.props.sex,
      sexT: this.props.sex === 1 ? 2 : 1,
      judgeMethod: 1
    }
    synchronizeJugement(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let getId = this.props.record ? this.props.record.id : null;
    let params = {
      ...values,
      id: getId
    };
    this.RegisterBtn(params);
  }


  reviewScreen() {
    // let params = {
    // judgementLevel: this.state.judgementLevel,
    // startDate: this.state.startDate
    // }
    // this.loadSingleJudgementData(params)
    this.getStartDate();
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="use-inspect">
        <Card className="mb-2" title={(this.props.sex === 1 ? '検査項目判定値設定（併合）男性：' : '検査項目判定値設定（併合）女性：') + this.props.record.exam_name}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top_modal'
              trigger='click'
              size='small'
              style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}
              overlay={() => (
                <Menu>
                  <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.copyJudgementData()}>
                    複写
                  </Menu.Item>
                </Menu>
              )}
            >
              <Button
                className='option_btn_right_top_modal'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ standardDate: moment(this.state.standardDate) }}>
            <Form.Item name="judgementLevel" label="判定レベル" style={{ width: '40%' }}  {...smGrid}>
              <Select onChange={(e) =>
                // this.handleChangeValue({ judgementLevel: e }),
                this.getStartDate()}
              >
                {this.state.judgementLevelItem?.map(item => (
                  <Select.Option key={item.condition_1} value={item.condition_1}>{item.item}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="box_inner_horizontal" >

              <Form.Item name="startDate" label="履歴" style={{ width: '40%' }}  {...smGrid}>
                <Select onChange={(e) => this.handleChangeValue({ startDate: e })}>
                  {this.state.startDateItem?.map(item => (
                    <Select.Option key={item.start_date} value={item.start_date}>{item.start_date}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>


            <div className="box_inner_horizontal" >
              <div className="box_inner_vertical_label_top">
                <Table bordered
                  dataSource={this.state.dataSourceSingle}
                  loading={false}
                  size='small'
                  pagination={false}
                  scroll={{ y: 200 }}
                  rowKey={(record) => record.id}
                  onRow={(record, rowIndex) => ({
                    onDoubleClick: () => this.editJudgementForm(record)
                  })}
                >
                  <Table.Column width={40} title="連番" align="right" dataIndex="serial_number" key="" />
                  <Table.Column title="条件式" dataIndex="formula" key="" />
                  <Table.Column width={40} title="判定" align="center" dataIndex="judgment_result" key="" />
                  <Table.Column width={200} title="コメント" dataIndex="comment_content" key="" />
                  <Table.Column width={40} title="優先" align="center" dataIndex="priority" key="" />
                  <Table.Column width={40} align="center" title={<Button type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => { this.addJudgementForm() }}
                    size='small'
                  ></Button>} render={(value, record, index) => (
                    <Button
                      size="small"
                      icon={<MoreOutlined />}
                      onClick={() => { this.editJudgementForm(record) }}
                    />
                  )}
                  />
                </Table>
              </div>
            </div>
            <div className="box_button_bottom_right"
              style={{ display: 'flex', gap: '20px' }}>

              <Popconfirm
                // title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>もう一方の性別にも適用しますか？<br /></div>}
                title={(this.props.sex === 1 ? '女性' : '男性') + 'にも適用しますか？'}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'green', borderColor: 'green' } }}
                cancelButtonProps={{ style: { height: '32px' } }}
                // icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                onConfirm={() => this.syncJudgement()}
                okText='ＯＫ'
                cancelText='キャンセル'
              >
                <Button
                  style={{ float: "right" }}
                  icon={<SyncOutlined />}
                  type="primary"
                // onClick={() => {
                // console.log('test');
                // }}
                >
                  <span className='btn_label'>
                    性別同期
                  </span>
                </Button>
              </Popconfirm>

            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0362012_InspectItemComplexJudgeValueSettingSub);
