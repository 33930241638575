/**
 * WS1376: 受診券入力画面
 *
 * [ state ]
 * - isLoading: 一覧表示のテーブル用
 * - dataList.FacilityType: 「施設」のSelectデータ | APIから取得
 * - dataList.KeyNum: 「表示順」のSelectデータ | APIから取得
 * - dataList.InputState: 「設定」のSelectデータ | 固定
 * - dataList.AcceptedState: 「状態」のSelectデータ | 固定
 * - itemLists: テーブル表示用配列
 */
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withScreenPreset } from "modules/common/with-screen-preset";
import { Card, Form, Table, Button, Dropdown, Menu, Input, Select } from "antd";
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import moment from "moment";

// [ 共通コンポーネント ]
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS1290001_InsurerNumberInquiry from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry";
import WS1296010_ConsultTicketInputSub from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296010_ConsultTicketInputSub.jsx"
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

// [ 固有コンポーネント ]
import Api from "redux/SpecificMedicalExamination/ConsultTicketInputProcessList/ConsultTicketInputProcessList.action";


class WS1376001_ConsultTicketInputProcessList extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataList: {
        FacilityType: [],
        KeyNum: [],
        InputState: [
          { label: "未設定", value: 0 },
          { label: "設定済", value: 1 },
          { label: "全て", value: 2 }
        ],
        AcceptedState: [
          { label: "予約", value: 0 },
          { label: "受付", value: 1 },
          { label: "全て", value: 2 }
        ]
      },
      itemLists: []
    };
  }

  componentDidMount = async () => {
    await this.initScreenData();
  };

  /**
   * 画面描画時 初期データ取得
   */
  initScreenData = async () => {
    const response = await Api.GetScreenData({});
    if (!response) {
      return;
    }
    const { data, dataList } = response;
    data["DateFChar"] = moment(data["DateFChar"]);
    data["DateTChar"] = moment(data["DateTChar"]);
    data["InputState"] = 2
    data["FacilityType"] = 0
    data["AcceptedState"] = 2
    data["KeyNum"] = 1
    this.formRef.current.setFieldsValue(data);
    this.setState(prevState => {
      return {
        ...prevState,
        dataList: { ...prevState.dataList, ...dataList }
      };
    });
  };

  /**
   * [SearchBar] 検索ボタン押下時
   * - テーブルの一覧データをAPIから取得
   */
  handleClickSearchButton = async e => {
    const request = this.formRef.current.getFieldsValue();
    request.DateFChar = request?.DateFChar.format("YYYY/MM/DD");
    request.DateTChar = request?.DateTChar.format("YYYY/MM/DD");
    Api.listProcess(request).then((res) => {
      let copyData = [...res]
      for (let i = 0; i < copyData.length; i++) {
        if (copyData[i].insurer_num === 0) {
          copyData[i].insurer_num = ''
        }
        if (copyData[i].consult_ticket_docket_num === 0) {
          copyData[i].consult_ticket_docket_num = ''
        }
      }
      this.setState({ itemLists: copyData ? copyData : [] });
    })
  };

  /**
   * [SearchBar] コース変更時
   */
  handleChangeCourse = (e, name) => {
    const { value } = e.target;
    if (value.length > 4) {
      this.formRef.current?.setFieldsValue({
        [name]: value.slice(0, 4)
      });
      this.forceUpdate();
    }
  };

  /**
   * [SearchBar] コース検索時
   */
  handleSearchCourseF = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                CourseF: output?.Lo_CourseCode,
              });
              this.forceUpdate();
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  handleSearchCourseT = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                CourseT: output?.Lo_CourseCode,
              });
              this.forceUpdate();
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  /**
   * [SearchBar] 保険者番号検索時
   */
  handleSearchInsuranceNum = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "80%",
        component: (
          <WS1290001_InsurerNumberInquiry
            onFinishScreen={({ recordData, Lo_InsurerNum }) => {
              this.formRef.current?.setFieldsValue({
                InsuranceNum: Lo_InsurerNum
              });
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  basic_burden_sect(value) {
    switch (value) {
      case 0:
        return ''
      case 1:
        return '負担なし'
      case 2:
        return '定額負担'
      case 3:
        return '定率負担'
      case 4:
        return '保険者上限'
    }
  }

  ContractAgenciesothervisitPropriety(value) {
    value = value.trim()
    if (value !== '') {
      return '〇'
    } else {
      return '×'
    }
  }

  ExpirationDateChar(value) {
    if (value !== '') {
      return '〇'
    } else {
      return '×'
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  render() {
    return (
      <div className="consult-ticket-input-process-list">
        <Card title="受診券入力処理[一覧]">
          <Form ref={this.formRef} initialValues={{}}>
            <div className="box_inner_horizontal" style={{ marginBottom: '10px' }}>
              <div className="box_search">
                <div className="box_search_inner_border">
                  <div className="box_inner_horizontal">
                    <Form.Item name="DateFChar" label="　日　付　">
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        format="YYYY/MM/DD"
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item name="DateTChar" label="~" style={{ marginRight: 10 }}>
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        format="YYYY/MM/DD"
                        allowClear={false}
                      />
                    </Form.Item>
                  </div>
                  <div className="box_inner_horizontal">
                    <Form.Item
                      name="CourseF"
                      label="　コース　"
                      onChange={e => this.handleChangeCourse(e, "CourseF")}
                    >
                      <Input.Search className="input-search-size-single-byte-4" onSearch={this.handleSearchCourseF} />
                    </Form.Item>
                    <Form.Item
                      name="CourseT"
                      label="~"
                      onChange={e => this.handleChangeCourse(e, "CourseT")}
                    >
                      <Input.Search className="input-search-size-single-byte-4" onSearch={this.handleSearchCourseT} />
                    </Form.Item>
                  </div>
                  <Form.Item name="InsuranceNum" label="保険者番号">
                    <Input.Search className="input-search-size-number-10" onSearch={this.handleSearchInsuranceNum} />
                  </Form.Item>
                </div>
                <div className="box_search_inner">
                  <Form.Item name="InputState" label="設　定">
                    <Select style={{ width: 120 }}>
                      <Select.Option key={0} value={0}>未設定</Select.Option>
                      <Select.Option key={1} value={1}>設定済</Select.Option>
                      <Select.Option key={2} value={2}>全て</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="FacilityType" label="施　設" >
                    <Select style={{ width: 120 }}>
                      {this.state.dataList["FacilityType"].map(item => (
                        <Select.Option key={item["value"]} value={item["value"]}>
                          {item["label"]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="AcceptedState" label="状　態" >
                    <Select style={{ width: 100 }}>
                      <Select.Option key={0} value={0}>予約</Select.Option>
                      <Select.Option key={1} value={1}>受付</Select.Option>
                      <Select.Option key={2} value={2}>全て</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="KeyNum" label="表示順" >
                    <Select style={{ width: 250 }}>
                      {this.state.dataList["KeyNum"].map(item => (
                        <Select.Option key={item["value"]} value={item["value"]}>
                          {item["label"]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="box_search_btn">
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.handleClickSearchButton()
                    }}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
          <Table
            bordered
            size="small"
            dataSource={this.state.itemLists}
            pagination={this.state.itemLists.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={item => item.id}
            scroll={{ y: resizableTableScroll(120) }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '60%',
                      component: (
                        <WS1296010_ConsultTicketInputSub
                          Li_CourseLevel={record.course_level}
                          Li_ReserveNum={record.reservation_number}
                          Li_Expression_33={record.Expression_33}
                          Li_Tab={"0"}
                          Li_insurer_num={record.insurer_num}
                          onFinishScreen={(output) => {
                            this.closeModal();
                            this.handleClickSearchButton()
                          }}
                        />
                      ),
                    },
                  })
                }
              };
            }}
          >
            <Table.Column
              title={`受診日`}
              width={110}
              dataIndex="receipt_number"
              render={(_, item) => {
                return (
                  <div>
                    {moment(item["visit_date_on"]).format("YYYY/MM/DD")}
                  </div>
                );
              }}
            />
            <Table.Column
              title={<div style={{ textAlign: 'left' }}>ID</div>}
              width={100}
              align='right'
              dataIndex="personal_number_id"
              render={(_, item) => {
                return (
                  <div>
                    {item["personal_number_id"]}
                  </div>
                );
              }}
            />
            <Table.Column
              title={`氏名`}
              width={200}
              dataIndex="kanji_name"
              render={(_, item) => {
                return (
                  <div>
                    {item["kanji_name"]}
                  </div>
                );
              }}
            />
            <Table.Column
              title={<div style={{ textAlign: 'left' }}>保険者</div>}
              width={90}
              dataIndex="insurer_num"
              align='right'
              render={(_, item) => {
                return (
                  <div>
                    {item.insurer_num}
                  </div>
                );
              }}
            />
            <Table.Column
              title={`年齢`}
              width={110}
              dataIndex="Expression_94"
              render={(_, item, index) => {
                return (
                  <div>
                    {item["age"]}歳
                  </div>
                );
              }}
            />
            <Table.Column
              title={<div style={{ textAlign: 'left' }}>受診券番号</div>}
              width={140}
              dataIndex="consult_ticket_docket_num"
              align='right'
              render={(_, item, index) => {
                return (
                  <div>
                    {item.consult_ticket_docket_num}
                  </div>
                );
              }}
            />
            <Table.Column
              title={<div style={{ textAlign: 'left' }}>期限</div>}
              width={60}
              dataIndex="ExpirationDateChar"
              render={(_, item, index) => {
                return (
                  <div>
                    {this.ExpirationDateChar(item.ExpirationDateChar)}
                  </div>
                );
              }}
            />
            <Table.Column
              title={`基本部分`}
              width={110}
              dataIndex="basic_burden_sect"
              render={(_, item, index) => {
                return (
                  <div>
                    {this.basic_burden_sect(item.basic_burden_sect)}
                  </div>
                );
              }}
            />
            <Table.Column
              title={`詳細部分`}
              width={110}
              dataIndex="details_burden_sect"
              render={(_, item, index) => {
                return (
                  <div>
                    {this.basic_burden_sect(item.details_burden_sect)}
                  </div>
                );
              }}
            />
            <Table.Column
              title={`追加項目`}
              width={110}
              dataIndex="other_add_burden_sect"
              render={(_, item, index) => {
                return (
                  <div>
                    {this.basic_burden_sect(item.other_add_burden_sect)}
                  </div>
                );
              }}
            />
            <Table.Column
              title={`代行機関`}
              width={80}
              dataIndex="ContractAgenciesothervisitPropriety"
              render={(_, item, index) => {
                return (
                  <div>
                    {this.ContractAgenciesothervisitPropriety(item.ContractAgenciesothervisitPropriety)}
                  </div>
                );
              }}
            />
            <Table.Column align='center' width={40} fixed='right' render={(text, record, index) => (
              <Dropdown size='small' trigger='click' overlay={() => (
                <Menu >
                  <Menu.Item key="1" onClick={() => (
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        centered: true,
                        width: 750,
                        component: (
                          <WS1296010_ConsultTicketInputSub
                            Li_CourseLevel={record.course_level}
                            Li_ReserveNum={record.reservation_number}
                            Li_Expression_33={record.Expression_33}
                            Li_Tab={"0"}
                            Li_insurer_num={record.insurer_num}
                            onFinishScreen={(output) => {
                              this.closeModal();
                              this.handleClickSearchButton()
                            }}
                          />
                        ),
                      },
                    })
                  )}>明細</Menu.Item>
                </Menu>
              )}>
                <Button size='small' icon={<MoreOutlined />}></Button>
              </Dropdown>
            )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withScreenPreset
)(WS1376001_ConsultTicketInputProcessList);
