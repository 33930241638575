import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/style-query',
}

const StyleQueryService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
}

export default StyleQueryService
