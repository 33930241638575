import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Card,
  Input,
  Button,
  Form,
  Popconfirm
} from "antd";
import WS0494004_ConditionExpressSet from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494004_ConditionExpressSet.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";

const labelSize = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 60px)' } }
}

class WS0494100_ConditionInfoMaintainEdit extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_ParentNum: PropTypes.any,
    Lio_ConditionalExpression: PropTypes.any,
    Lio_System: PropTypes.any,
  };
  constructor(props) {
    super(props);

    // document.title = "条件式情報保守編集";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      record: {}
    };
  }

  componentDidMount() {
    if (this.props.record !== '') {
      this.formRef.current?.setFieldsValue(this.props.record)
      this.setState({
        record: this.props.record
      })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  /**
   * 保存処理
   */
  onSave() {
    console.log(this.state.record);
    console.log(this.formRef.current?.getFieldValue());
    this.props.onSave(this.formRef.current?.getFieldValue())
  }

  render() {
    return (
      <div className="condition-info-maintain-edit">
        <Form
          ref={this.formRef}
        >
          <Card title={this.props.Li_EditFlag ? "修正" : "登録"}>
            {this.props.table1 ?
              <div className="box_inner_vertical">
                <Form.Item label='連番' name="serial_number" {...labelSize}>
                  <Input style={{ width: '150px' }} />
                </Form.Item>
                <Form.Item label='名称' name="name" {...labelSize}>
                  <Input style={{ width: '250px' }} />
                </Form.Item>
              </div> :
              <div className="box_inner_vertical">
                <Form.Item label='SEQ' name="serial_number" {...labelSize}>
                  <Input style={{ width: '150px' }} />
                </Form.Item>
                <Form.Item label='条件式' name="EquationDisplay" {...labelSize}>
                  <Input.Search
                    style={{ width: '250px' }}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 900,
                          component: (
                            <WS0494004_ConditionExpressSet
                              Lio_ConditionalExpression={
                                this.props.record.formula === "0" || !this.props.record.formula ? "" : this.props.record.formula
                              }
                              onFinishScreen={(output) => {
                                if (output) {
                                  let record = this.state.record
                                  this.formRef?.current?.setFieldsValue({ 'EquationDisplay': output.Lo_ConditionalExpressionDisplay });
                                  record.formula = output.Lio_ConditionalExpression
                                  record.EquationDisplay = output.Lo_ConditionalExpressionDisplay
                                  this.setState({
                                    record: record
                                  })
                                  this.forceUpdate();
                                  this.closeModal();
                                }
                              }}
                            />
                          ),
                        },
                      });
                    }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 900,
                          component: (
                            <WS0494004_ConditionExpressSet
                              Lio_ConditionalExpression={
                                this.props.record.formula === "0" || !this.props.record.formula ? "" : this.props.record.formula
                              }
                              onFinishScreen={(output) => {
                                console.log(output);
                                if (output) {
                                  let record = this.state.record
                                  this.formRef?.current?.setFieldsValue({
                                    'formula': output.Lio_ConditionalExpression,
                                    'EquationDisplay': output.Lo_ConditionalExpressionDisplay
                                  });
                                  record.formula = output.Lio_ConditionalExpression
                                  record.EquationDisplay = output.Lo_ConditionalExpressionDisplay
                                  this.setState({
                                    record: record
                                  })
                                  this.forceUpdate();
                                  this.closeModal();
                                }
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </div>
            }
            <div className="box_button_bottom_right"
              style={{ display: 'flex', gap: '20px' }}>
              {this.props.Li_EditFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px', display: (this.props.Li_CategoryCode === 0) ? 'none' : '' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.props.onDelete()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    disabled={this.props.record.Li_CategoryCode === 0}
                  >
                    削除
                  </Button>
                </Popconfirm> : null}
              <Button
                type="primary"
                onClick={() => { this.onSave() }}
              >
                保存
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0494100_ConditionInfoMaintainEdit);