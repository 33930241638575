import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Table, Modal, Input } from 'antd'
import FindingsInputRadiographyAction from 'redux/InputBusiness/SpreadInput/FindingsInputRadiography.action'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import WS0729002_FindingsInputRadiographyChange from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729002_FindingsInputRadiographyChange'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import Color from 'constants/Color'

// テーブルの高さ
const scrollY = 300
// 前回結果のテーブルの幅
const previousTableWidth = 400
class WS0729001_FindingsInputRadiography extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '所見入力[読影]'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataForm: {},
      dataSourceThisTime: [],
      dataSourcePrevious: [],

      dataSourceSites1: [],
      dataSourceSites2: [],
      dataSourceFindings1: [],
      dataSourceFindings2: [],

      selectedSitesRow1: null,
      selectedSitesRow2: null,
      selectedFindingRow1: null,
      selectedFindingRow2: null,

      siteClassification: 0,
      maxSerialNum: 0
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    const params = {
      Li_PatternCode: this.props.Li_PatternCode,
      Li_CategoryCode: this.props.Li_CategoryCode,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_JudgeLevel: this.props.Li_JudgeLevel,
      Li_PersonalNum: this.props.Li_PersonalNum,
      Li_FindingsInputNumRows: this.props.Li_FindingsInputNumRows,
      Li_FindingsInputNumDigits: this.props.Li_FindingsInputNumDigits,
    }

    FindingsInputRadiographyAction.index(params)
      .then(async (res) => {
        if (res) {
          await this.setState({
            dataForm: res,
            dataSourceSites1: res.SiteCode1,
            dataSourceSites2: res.SiteCode2,
            dataSourceFindings1: res.FindingsCode1,
            dataSourceFindings2: res.FindingsCode2,
            siteClassification: res.SiteFindingsClassify,
          })

          // 今回データ
          await this.getSiteFindingsInputThisTime()
          // 前回データ
          await this.getSiteFindingsPrevious()
        }
      })
  }

  /**
   * 今回の部位、所見情報を取得
   */
  getSiteFindingsInputThisTime() {
    const params = {
      Li_CourseLevel: this.state.dataForm.Li_CourseLevel,
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InspectCode: this.state.dataForm.Li_InspectCode,
      Li_PatternCode: this.state.dataForm.Li_PatternCode,
      Li_CategoryCode: this.state.dataForm.Li_CategoryCode,
      Li_JudgeLevel: this.state.dataForm.Li_JudgeLevel,
    }
    FindingsInputRadiographyAction.getSiteFindingsInputThisTime(params)
      .then((res) => {
        if (res) {

          let maxNum = 0
          res.forEach((x) => {
            if (x.serial_number > maxNum) {
              maxNum = x.serial_number
            }
          })

          // 今回の部位所見一覧に登録
          this.setState({
            dataSourceThisTime: res,
            maxSerialNum: maxNum
          })
        }
      })
  }

  /**
   * 前回の所見情報を取得
   */
  getSiteFindingsPrevious() {
    const params = {
      Li_ReserveNum: this.state.dataForm.PreviousReserveNum,
      Li_InspectCode: this.state.dataForm.Li_InspectCode,
      LastDate: this.state.dataForm.LastDate, //前回の受診日
    }
    FindingsInputRadiographyAction.getSiteFindingsPrevious(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourcePrevious: res.data ? res.data : [],
            previousDate: res.previousDate
          })
        }
      })
  }

  /**
   * 前回DOを反映する
   */
  setPreviousFindingsDo = () => {
    const previousReserveNum = this.state.dataForm.PreviousReserveNum ?? ''
    if (previousReserveNum === '' || previousReserveNum === null) {
      // 前回のデータが存在しない場合
      Modal.warning({ content: '前回所見は存在しません', okText: 'は　い' })
    } else {
      Modal.confirm({
        content: `前回の所見結果を反映します。よろしいですか？`,
        okText: '保　存',
        cancelText: 'キャンセル',
        onOk: () => {
          const params = {
            Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
            PreviousReserveNum: this.state.dataForm.PreviousReserveNum,
            Li_InspectCode: this.state.dataForm.Li_InspectCode,
          }
          FindingsInputRadiographyAction.setPreviousFindingsDo(params)
            .then((res) => {
              if (res) {
                // 今回の結果を再取得
                this.getSiteFindingsInputThisTime()
                if (this.props.onUpdateFindings) {
                  // 親画面の表示内容更新させる　
                  this.props.onUpdateFindings()
                }
              }
            })
        }
      })
    }
  }

  /**
   * 部位 書簡 候補一覧を取得
   * @param {*} site_code1
   */
  getSitesAndFindings = (site_code1) => {
    const data = {
      InspectClassifyCode: this.state.dataForm.InspectClassifyCode,
      SiteCode1: site_code1.site_code || '',
      SiteCode2: '',
      FindingsCode1: '',
    }
    FindingsInputRadiographyAction.getSitesAndFindings(data)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSites1: res.SiteCode1,
            dataSourceSites2: res.SiteCode2,
            dataSourceFindings1: res.FindingsCode1,
            dataSourceFindings2: res.FindingsCode2,
          })
        }
      })
  }

  /**
   * 部位 候補一覧１を選択
   * @param {*} site_code1
   */
  selectSites1 = (item) => {
    const params = {
      InspectClassifyCode: this.state.dataForm.InspectClassifyCode,
      SiteCode1: item.site_code ?? null,
      SiteCode2: this.state.selectedSitesRow2?.site_code ?? null,
      FindingsCode1: this.state.selectedFindingRow1?.findings_code ?? null,
    }
    FindingsInputRadiographyAction.selectSitesAndFindings(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSites2: res.SiteCode2,
            dataSourceFindings1: res.FindingsCode1,
            dataSourceFindings2: res.FindingsCode2,
          })
        }
      })
  }

  /**
   * 部位 候補一覧２を取得
   * @param {*} site_code1
   */
  selectSites2 = (item) => {
    const params = {
      InspectClassifyCode: this.state.dataForm.InspectClassifyCode,
      SiteCode1: this.state.selectedSitesRow1.site_code ?? null,
      SiteCode2: item.site_code ?? null,
      FindingsCode1: this.state.selectedFindingRow1?.findings_code ?? null,
    }
    FindingsInputRadiographyAction.selectSitesAndFindings(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSites2: res.SiteCode2,
            dataSourceFindings1: res.FindingsCode1,
            dataSourceFindings2: res.FindingsCode2,
          })
        }
      })
  }

  /**
   * 所見 候補一覧１を取得
   * @param {*} finding_code1
   */
  selectFindings1 = (item) => {
    const data = {
      InspectClassifyCode: this.state.dataForm.InspectClassifyCode,
      SiteCode1: this.state.selectedSitesRow1.site_code ?? null,
      SiteCode2: this.state.selectedSitesRow2?.site_code ?? null,
      FindingsCode1: item.findings_code ?? null,
    }
    FindingsInputRadiographyAction.selectSitesAndFindings(data)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSites2: res.SiteCode2,
            dataSourceFindings1: res.FindingsCode1,
            dataSourceFindings2: res.FindingsCode2,
          })
        }
      })
  }


  /**
   * 候補一覧から選択された部位と所見をデータベースに反映する
   */
  setSelectedSiteFinding = () => {
    let serialNum = 1
    const data = [...this.state.dataSourceThisTime]
    data.forEach((item) => {
      if (item.serial_number >= serialNum) {
        // 連番のMAX値＋１をする
        serialNum++;
      }
    })

    // 部位の選択文言を反映
    let strSiteName = ''
    if (this.state.selectedSitesRow1) {
      strSiteName += this.state.selectedSitesRow1.site_name
    }
    if (this.state.selectedSitesRow2) {
      strSiteName += this.state.selectedSitesRow2.site_name
    }

    // 所見の選択文言を反映
    let strFindingsName = ''
    if (this.state.selectedFindingRow1) {
      strFindingsName += this.state.selectedFindingRow1.findings_name
    }
    if (this.state.selectedFindingRow2) {
      strFindingsName += this.state.selectedFindingRow2.findings_name
    }

    const params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_CategoryCode: this.props.Li_CategoryCode,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_JudgeLevel: this.props.Li_JudgeLevel,
      id: null, //新規追加
      serial_number: serialNum,
      site_name: strSiteName,
      site_classification: this.state.siteClassification,
      findings_name: strFindingsName,
      findings_classification: this.state.findingsClassification,
      user: this.props.user
    }

    FindingsInputRadiographyAction.save(params)
      .then((res) => {
        // 今回のデータを再取得
        this.getSiteFindingsInputThisTime()
        if (this.props.onUpdateFindings) {
          // 親画面の表示内容更新させる　
          this.props.onUpdateFindings()
        }
        // 選択状態初期化
        this.setState({
          selectedSitesRow1: null,
          selectedSitesRow2: null,
          selectedFindingRow1: null,
          selectedFindingRow2: null,
        })
        this.formRef.current?.setFieldsValue({
          selectedSitesRow1Search: null,
          selectedSitesRow2Search: null,
          selectedFindingRow1Search: null,
          selectedFindingRow2Search: null
        })
      })
  }

  /**
   * 所見入力　変更モーダル
   * @param {*} record
   */
  showWS0729002_FindingsInputRadiographyChange = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <WS0729002_FindingsInputRadiographyChange
            record={record}
            Li_ReserveNum={this.state.dataForm.Li_ReserveNum}
            Li_CourseLevel={this.state.dataForm.Li_CourseLevel}
            Li_PatternCode={this.state.dataForm.Li_PatternCode}
            Li_CategoryCode={this.state.dataForm.Li_CategoryCode}
            Li_InspectCode={this.state.dataForm.Li_InspectCode}
            Li_JudgeLevel={this.props.Li_JudgeLevel}
            site_classification={this.state.siteClassification}
            maxSerialNum={this.state.maxSerialNum}
            user={this.props.user}
            onUpdateFindings={() => {
              if (this.props.onUpdateFindings) {
                // 親画面の表示内容更新させる　
                this.props.onUpdateFindings()
              }
              if (!this.props.closeFlag) {
                // データ再取得
                this.index()
              }

              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 部位検索
   * リストから入力内容を検索
   * @param {*} value
   * @param {*} list
   * @param {*} setName
   */
  partSearch(value, list, setName) {
    // 入力した内容から一致するものを検索
    let arrayCheck = list.find(name => name.site_code === Number(value))
    // 検索結果があればsetState
    if (arrayCheck) {
      this.setState({
        [setName]: arrayCheck
      })
    }
  }

  /**
   * 所見検索
   * リストから入力内容を検索
   * @param {*} value
   * @param {*} list
   * @param {*} setName
   */
  findingSearch(value, list, setName) {
    // 入力した内容から一致するものを検索
    let arrayCheck = list.find(name => name.findings_code === Number(value))
    // 検索結果があればsetState
    if (arrayCheck) {
      this.setState({
        [setName]: arrayCheck
      })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='findings-input-radiography'>
        <Card title={`${this.props.record.inspect_name} : [${this.props.personalNumberId}] ${this.props.KanjiName}`} >
          <Form
            ref={this.formRef}
            autoComplete='off'
            onFinish={this.onFinish}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_border'>
                  <div style={{ fontWeight: 'bold', marginBottom: 5 }}>
                    部位選択
                  </div>
                  <div className='box_inner_horizontal'>
                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='selectedSitesRow1Search' style={{ marginBottom: 0 }}>
                          <Input
                            className='input-size-number-4'
                            onPressEnter={(e) => this.partSearch(e.target.value, this.state.dataSourceSites1, 'selectedSitesRow1')}
                            id={'selectedSitesRow1Search'}
                            onKeyDown={(e) => moveFocusKeyEvent(e, 'selected', 0, false, 0)}
                          />
                        </Form.Item>

                        <div style={{
                          display: this.state.selectedSitesRow1?.site_code ? '' : 'none',
                          marginLeft: 10,
                          marginTop: '5px'
                        }}>
                          {this.state.selectedSitesRow1?.site_name}
                        </div>
                      </div>

                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceSites1}
                        scroll={{ y: 200 }}
                        rowClassName={(record, index) => record.site_code === this.state.selectedSitesRow1?.site_code ?? '' ? 'table-row-light' : ''}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: (e) => {
                              if (record.site_code === this.state.selectedSitesRow1?.site_code) {
                                this.setState({ selectedSitesRow1: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow1Search: null
                                })
                              } else {
                                this.setState({ selectedSitesRow1: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow1Search: record.site_code
                                })
                              }
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='site_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='部位1 名称'
                          dataIndex='site_name'
                        />
                      </Table>
                    </div>

                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='selectedSitesRow2Search' style={{ marginBottom: 0 }}>
                          <Input
                            className='input-size-number-4'
                            onPressEnter={(e) => this.partSearch(e.target.value, this.state.dataSourceSites2, 'selectedSitesRow2')}
                            id={'selectedSitesRow2Search'}
                            onKeyDown={(e) => moveFocusKeyEvent(e, 'selected', 0, false, 0)}
                          />
                        </Form.Item>

                        <div style={{
                          display: this.state.selectedSitesRow2?.site_code ? '' : 'none',
                          marginLeft: 10,
                          marginTop: '5px'
                        }}>
                          {this.state.selectedSitesRow2?.site_name}
                        </div>
                      </div>

                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceSites2}
                        scroll={{ y: 200 }}
                        rowClassName={(record, index) => record.site_code === this.state.selectedSitesRow2?.site_code ?? '' ? 'table-row-light' : ''}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              if (record.site_code === this.state.selectedSitesRow2?.site_code) {
                                this.setState({ selectedSitesRow2: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow2Search: null
                                })
                              } else {
                                this.setState({ selectedSitesRow2: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow2Search: record.site_code
                                })
                              }
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='site_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='部位2 名称'
                          dataIndex='site_name'
                        />
                      </Table>
                    </div>
                  </div>
                </div>

                <div className='box_border'>
                  <div style={{ fontWeight: 'bold', marginBottom: 5 }}>
                    所見選択
                  </div>
                  <div className='box_inner_horizontal'>
                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='selectedFindingRow1Search' style={{ marginBottom: 0 }}>
                          <Input
                            className='input-size-number-4'
                            onPressEnter={(e) => this.findingSearch(e.target.value, this.state.dataSourceFindings1, 'selectedFindingRow1')}
                            id={'selectedFindingRow1Search'}
                            onKeyDown={(e) => moveFocusKeyEvent(e, 'selected', 0, false, 0)}
                          />
                        </Form.Item>

                        <div style={{
                          display: this.state.selectedFindingRow1?.findings_code ? '' : 'none',
                          marginLeft: 10,
                          marginTop: '5px'
                        }}>
                          {this.state.selectedFindingRow1?.findings_name}
                        </div>
                      </div>

                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceFindings1}
                        scroll={{ y: 200 }}
                        rowClassName={(record, index) => record.findings_code === this.state.selectedFindingRow1?.findings_code ?? '' ? 'table-row-light' : ''}
                        scrollToFirstRowOnChange={true}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              if (record.findings_code === this.state.selectedFindingRow1?.findings_code) {
                                this.setState({ selectedFindingRow1: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow1Search: null
                                })
                              } else {
                                this.setState({ selectedFindingRow1: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow1Search: record.findings_code
                                })
                              }

                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='findings_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='所見1 名称'
                          dataIndex='findings_name'
                        />
                      </Table>
                    </div>

                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='selectedFindingRow2Search' style={{ marginBottom: 0 }}>
                          <Input
                            className='input-size-number-4'
                            onPressEnter={(e) => this.findingSearch(e.target.value, this.state.dataSourceFindings2, 'selectedFindingRow2')}
                            id={'selectedFindingRow2Search'}
                            onKeyDown={(e) => moveFocusKeyEvent(e, 'selected', 0, false, 0)}
                          />
                        </Form.Item>

                        <div style={{
                          display: this.state.selectedFindingRow2?.findings_code ? '' : 'none',
                          marginLeft: 10,
                          marginTop: '5px'
                        }}>
                          {this.state.selectedFindingRow2?.findings_name}
                        </div>
                      </div>

                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceFindings2}
                        scroll={{ y: 200 }}
                        rowClassName={(record, index) => record.findings_code === this.state.selectedFindingRow2?.findings_code ?? '' ? 'table-row-light' : ''}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              if (record.findings_code === this.state.selectedFindingRow2?.findings_code) {
                                this.setState({ selectedFindingRow2: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow2Search: null
                                })
                              } else {
                                this.setState({ selectedFindingRow2: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow2Search: record.findings_code
                                })
                              }
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='findings_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='所見2 名称'
                          dataIndex='findings_name'
                        />
                      </Table>
                    </div>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <Button
                    type='primary'
                    disabled={!(this.state.selectedSitesRow1 || this.state.selectedSitesRow2 || this.state.selectedFindingRow1 || this.state.selectedFindingRow2)}
                    onClick={() => {
                      this.setSelectedSiteFinding()
                    }}
                  >
                    追加
                  </Button>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <div style={{ width: '60%' }}>
                  <div className='table_header_filter'>
                    {'今回'}
                  </div>
                  <Table
                    dataSource={this.state.dataSourceThisTime}
                    rowKey={(record) => record.id}
                    size='small'
                    bordered={true}
                    pagination={false}
                    scroll={{ y: scrollY }}
                    onRow={(record, index) => {
                      return {
                        onDoubleClick: async (event) => {
                          // 編集の場合
                          this.showWS0729002_FindingsInputRadiographyChange(record, index)
                        }
                      }
                    }}
                  >
                    <Table.Column
                      width={50}
                      title='連番'
                      dataIndex='serial_number'
                      render={(value, record, index) => (
                        <div style={{ textAlign: 'right' }}>
                          {value}
                        </div>
                      )}
                    />
                    <Table.Column
                      title='部位'
                      dataIndex='site_name'
                    />
                    <Table.Column
                      title='所見'
                      dataIndex='findings_name'
                    />
                    <Table.Column
                      width={50}
                      title='判定'
                      dataIndex='judgment_value'
                      render={(value, record, index) => {
                        return (
                          <div
                            style={{
                              textAlign: 'center',
                              color: Color(record?.judgmentValueColorNum)?.Foreground,
                            }}
                          >
                            {value}
                          </div>
                        )
                      }}
                    />
                    <Table.Column
                      width={45}
                      style={{ textAlign: 'center' }}
                      title={
                        <Button
                          type='primary'
                          size='small'
                          icon={(<PlusOutlined />)}
                          onClick={() => {
                            // 新規追加の場合
                            this.showWS0729002_FindingsInputRadiographyChange()
                          }}
                        />
                      }
                      render={(item, record, index) => (
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          onClick={() => {
                            // 編集の場合
                            this.showWS0729002_FindingsInputRadiographyChange(record, index)
                          }}
                        />
                      )}
                    />
                  </Table>
                </div>

                <div style={{ width: '40%' }}>
                  <div className='table_header_filter'>
                    {'前回 ' + this.state.previousDate ?? ''}
                  </div>
                  <Table
                    dataSource={this.state.dataSourcePrevious}
                    rowKey={(record) => record.id}
                    size='small'
                    bordered={true}
                    pagination={false}
                    scroll={{ y: scrollY }}
                  >
                    <Table.Column
                      title='部位'
                      dataIndex='site_name'
                    />
                    <Table.Column
                      title='所見'
                      dataIndex='findings_name'
                    />
                    <Table.Column
                      width={50}
                      title='判定'
                      dataIndex='judgment_value'
                      render={(value, record, index) => {
                        return (
                          <div
                            style={{
                              textAlign: 'center',
                              color: Color(record?.judgmentValueColorNum)?.Foreground,
                            }}
                          >
                            {value}
                          </div>
                        )
                      }}
                    />
                  </Table>
                </div>
              </div>

              <div className='box_button_bottom_right' style={{ paddingTop: 0 }} >
                <Button
                  type='primary'
                  onClick={() => {
                    // 前回DO
                    this.setPreviousFindingsDo()
                  }}
                >
                  前回DO
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />

      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0729001_FindingsInputRadiography)
