import axios from 'configs/axios';

const API_LIST = {
  eventAcceptedCancellation: '/api/create-test-for-medical-exam-info/visits-cancel-confirm-action/event-accepted-cancellation',
};

const VisitsCancelConfirmAction = {
  async eventAcceptedCancellation(params) {
    return axios.post(API_LIST.eventAcceptedCancellation, params);
  },
};

export default VisitsCancelConfirmAction;