import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Input, Button, Row, Col, Space, Tooltip, Table, message } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { ReturnIcon } from 'components/Commons/ReturnIcon'
import GetImage from 'constants/Images'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0339001_InsurerInfoMaintain from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain.jsx'
import WS2586020_ConsultHistorySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2586020_ConsultHistorySub.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx'
import WS2593056_BillingInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2593056_BillingInquirySub.jsx'
import WS0612001_PersonalActionItemTreeDisplay from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0612001_PersonalActionItemTreeDisplay.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2587021_InspectChangeQuerySub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2587021_InspectChangeQuerySub.jsx'
import WS0392004_VisitsSupplement from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0392004_VisitsSupplement.jsx'
import { getInfoDataConsultInquirySubAction, VisitsInspectConsultInquirySubAction } from 'redux/InputBusiness/ProgressSetting/ConsultInquirySub.actions'
import moment from 'moment'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub'

const styleDivGender = {
  border: '1px solid blue',
  color: 'blue',
  textAlign: 'center',
  padding: '1px 7px',
  float: 'right'
}

const styleDivResult = {
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '30px',
  padding: '0.3em'
}

const styleDivTitle = {
  background: '#1C66B9',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '30px',
  padding: '0.3em',
  color: '#FFFFFF'
}
class WS2583001_ConsultInquirySub extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '受診照会SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      isLoadPage: true,
      infoPage: {},
      dataSource: [],
      importance: 0,
      inspectChange: [],
      columnList: [],
      dataList: [],
      discountNameList: [],
      discountList: []
    }
  }

  componentDidMount = () => {
    this.loadData(this.props.Li_ReserveNum)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.loadData(this.props.Li_ReserveNum)
    }
  }

  loadData = (params) => {
    this.setState({ isLoadPage: true })
    getInfoDataConsultInquirySubAction({ W1_reserve_num: params })
      .then(res => {
        if (res) {
          let data = {
            ...res.data,
            DateBirth: moment(res.data.DateBirth).format('NNy/MM/DD'),
            Expression_28: moment(res.data.Expression_28).format('YYYY/MM/DD (ddd)'),
            period_time: moment(res.data.period_time, 'hh:mm:ss').format('hh:mm')
          }

          let arrayData = []
          let objectData = {
            insurer_total_price: res.data.insurer_total_price,
            office_total_price: res.data.office_total_price,
            organization_total_price: res.data.organization_total_price,
            personal_1_total_price: res.data.personal_1_total_price,
            personal_2_total_price: res.data.personal_2_total_price,
            personal_3_total_price: res.data.personal_3_total_price,
            Expression_56: res.data.Expression_56,
            id: 1
          }
          arrayData.push(objectData)

          this.setState({
            infoPage: data,
            importance: data.Expression_53 ? data.Expression_53 : 0,
            dataSource: arrayData,
            inspectChange: res.data.inspectChange ?? [],
            columnList: res.data.columnList ?? [],
            dataList: res.data.dataList ?? [],
            discountNameList: res.data.discountNameList ?? [],
            discountList: res.data.discountList ?? []
          })
          this.formRef?.current?.setFieldsValue(data)
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadPage: false }))
  }

  renderInspectNumber = (obj, start, end) => {
    let inspects = []
    for (let i = start; i < end; i++) {
      if (i < 10) {
        inspects.push({
          Inspect: obj[`Inspect0${i}`] ? obj[`Inspect0${i}`] : null,
          color: obj[`Expression_${i + 29}`] ? obj[`Expression_${i + 29}`] : 210
        })
      } else {
        inspects.push({
          Inspect: obj[`Inspect${i}`] ? obj[`Inspect${i}`] : null,
          color: obj[`Expression_${i + 29}`] ? obj[`Expression_${i + 29}`] : 210
        })
      }
    }
    return (
      <Row>
        <Col span={1}></Col>
        {inspects.map((item, index) => (
          <Col span={2} style={{ padding: 0 }} key={index}>
            <div style={{
              textAlign: 'center',
              background: Color(item.color).Background,
              color: item.color === 156 ? '#FFFFFF' : '#ABADB3',
              border: '1px solid #ABADB3',
              height: '30px'
            }}
            >
              {item.Inspect}
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  renderDivCacl = (title, price) => (
    <Col span={3}>
      <div style={styleDivTitle}>{title}</div>
      <div style={styleDivResult}>
        {price === 0 ? null : price?.toLocaleString()}
      </div>
    </Col>
  )

  VisitsInspect = () => {
    VisitsInspectConsultInquirySubAction({ Li_ReserveNum: this.props.Li_ReserveNum })
      .then(res => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 600,
            component:
              <WS0612001_PersonalActionItemTreeDisplay
                Lio_Expansion={true}
                onFinishScreen={() => {
                  this.closeModal()
                }}
              />
            ,
          },
        })
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  expandedRowRender = () => {
    return (
      <WS2587021_InspectChangeQuerySub
        Random={Math.random()}
        Li_ReserveNum={this.state.infoPage.Li_ReserveNum}
        Li_DataClassify={''}
        Li_Course={this.state.infoPage.visit_course}
        Li_ContractStartDate={this.state.infoPage.contract_start_date_on}
      />
    )
  }

  render() {
    return (
      <div className='consult-inquiry-sub' style={{ width: 1200 }}>
        <Card title='受診照会'>
          <Form ref={this.formRef} >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <div style={{ border: '1px solid #cfcfcf', padding: '10px', width: '50%' }}>
                  <div style={{ position: 'relative', borderBottom: '1px solid #cfcfcf' }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='personal_number_id'
                        label='個　人'
                        style={{ marginBottom: 0 }}
                      >
                        <div>{this.state.infoPage.personal_number_id}</div>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          type={this.state.importance === 0 ? 'default' : 'text'}
                          size='small'
                          icon={ReturnIcon(this.state.importance)}
                          disabled={!(this.state.infoPage.personal_number_id !== '')}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component:
                                  <WS2584019_PersonalInfoInquirySub
                                    Li_PersonalNum={this.state.infoPage.personal_number_id}
                                    onFinishScreen={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ,
                              },
                            })
                          }}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item
                      name='KanaName'
                      label='　　　'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.KanaName}</div>
                    </Form.Item>
                    <Form.Item
                      name='KanjiName'
                      label='　　　'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.KanjiName}</div>
                    </Form.Item>
                    <Form.Item
                      label='　　　'
                      style={{ marginBottom: 0 }}
                    >
                      <div className='box_inner_horizontal'>
                        <Form.Item name='DateBirth' style={{ marginBottom: 0 }}>
                          <div>{this.state.infoPage.DateBirth}</div>
                        </Form.Item>
                        <Form.Item name='Expression_27' style={{ marginBottom: 0 }}>
                          <div>{this.state.infoPage.Expression_27}</div>
                        </Form.Item>
                      </div>
                    </Form.Item>
                    <img
                      src={this.state.infoPage?.Expression_22 === '性別-女性.png' ? GetImage('woman') : GetImage('man')}
                      alt='gender'
                      width={'40%'}
                      style={{ position: 'absolute', bottom: '10px', right: '10px', width: '50px' }}
                    />
                  </div>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='office_code'
                      label='事業所'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.office_code}</div>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '70%',
                              component:
                                <WS2585001_OfficeInfoInquirySub
                                  Li_OfficeCode={this.state.infoPage.office_code}
                                  Li_BranchCode={this.state.infoPage.branch_store_code}
                                  onFinishScreen={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name='office_kanji_name'
                    label='　　　'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.office_kanji_name}</div>
                  </Form.Item>

                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='insurer_number'
                      label='保険者'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.insurer_number}</div>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button size='small'
                        disabled={!this.state.infoPage.insurer_number > 0}
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '80%',
                              component:
                                <WS0339001_InsurerInfoMaintain
                                  insurer_code={this.state.infoPage.insurer_number}
                                  onFinishScreen={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name='insurer_kanji_name'
                    label='　　　'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.insurer_kanji_name}</div>
                  </Form.Item>
                  <Form.Item
                    name='InsuranceCardSymbolNum'
                    label='保険証'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.InsuranceCardSymbolNum}</div>
                  </Form.Item>
                  <Form.Item
                    name='name'
                    label='続　柄'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.name}</div>
                  </Form.Item>
                </div>

                <div style={{ position: 'relative', border: '1px solid #cfcfcf', padding: '10px', width: '50%' }}>
                  <Form.Item
                    label='　受診日'
                    name='Expression_28'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.Expression_28}</div>
                  </Form.Item>
                  <Form.Item
                    name='receipt_number'
                    label='受付番号'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.receipt_number}</div>
                  </Form.Item>
                  <div style={{
                    ...styleDivGender,
                    border: `1px solid ${Color(this.state.infoPage?.Expression_25)?.Foreground}`,
                    color: Color(this.state.infoPage?.Expression_25)?.Foreground,
                    position: 'absolute', top: '10px', right: '10px', width: '50px'
                  }}
                  >
                    {this.state.infoPage?.Expression_23}
                  </div>
                  <Form.Item
                    label='　時間帯'
                    name='period_time'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.period_time}</div>
                  </Form.Item>
                  <Form.Item
                    name='next_division'
                    label='Ｎ次区分'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{this.state.infoPage.next_division}</div>
                  </Form.Item>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      label='　施　設'
                      name='facility_name'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.facility_name}</div>
                    </Form.Item>
                    <Form.Item
                      name='short_name'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.short_name}</div>
                    </Form.Item>
                  </div>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      label='　コース'
                      name='visit_course'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.visit_course}</div>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }} >
                      <Button size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '80%',
                              component:
                                <WS0605127_ContractLineItemDisplay
                                  Li_ContractType={this.state.infoPage.contract_type}
                                  Li_ContractOrgCode={this.state.infoPage.contract_organization_code}
                                  Li_ContractStartDate={this.state.infoPage.contract_start_date_on}
                                  Li_ContractNum={this.state.infoPage.contract_number}
                                  onFinishScreen={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name='contract_short_name'
                      style={{ marginBottom: 0 }}
                    >
                      <div>{this.state.infoPage.contract_short_name}</div>
                    </Form.Item>
                  </div>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      label='　備　考'
                      name='remarks'
                      style={{ marginBottom: 0 }}
                    >
                      <div style={{
                        width: 'fit-content',
                        maxWidth: 450, //この受信照会をモーダルで表示する時、widthは'fit-content'にする
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'normal'
                      }}>
                        {this.state.infoPage.remarks}
                      </div>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component:
                                <WS0392004_VisitsSupplement
                                  Li_CourseLevel={this.state.infoPage.course_level}
                                  Li_ReserveNum={this.state.infoPage.Li_ReserveNum}
                                  Li_RealTmp={''}
                                  onFinishScreen={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className='box_inner_vertical'>
                <Table
                  size='small'
                  //協会けんぽ調整用配列を結合
                  dataSource={[...this.state.inspectChange, ...this.state.discountNameList]}
                  rowKey={(record) => record.id}
                  bordered
                  scroll={{ x: 'max-content', y: 250 }}
                  pagination={this.state.inspectChange.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                >
                  <Table.Column
                    title=''
                    dataIndex='changeType'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return <div style={{ textAlign: 'center' }}>
                        {record.changeType ?
                          <img style={{ width: 16 }} src={GetImage(record.changeType)} alt='icon' />
                          : ''
                        }
                      </div>
                    }} />
                  <Table.Column
                    title='名称'
                    dataIndex='Expression_17'
                    className='column-size-40'
                  />
                  {
                    this.state.columnList.map((value, index) => {
                      return (
                        <Table.Column
                          title={() => {
                            return (
                              <Tooltip title={value.kanji_name}>
                                <div>{value.kanji_name.substr(0, 7)}</div>
                              </Tooltip>
                            )
                          }}
                          key={index}
                          width='140px'
                          render={(item, record, index) => {
                            let total_price = 0
                            let data_division = record.data_division > 0 ? record.data_division : record.changeType == 10 ? 20 : record.changeType
                            //協会けんぽ調整用配列を結合
                            let neoDataList = [...this.state.dataList, ...this.state.discountList]
                            return (
                              <div
                                style={{ textAlign: 'right' }}
                              >
                                {neoDataList.map((element, index) => {
                                  if (element.data_division == data_division && element.set_code == record.set_code && element.serial_number == value.serial_number) {
                                    if (element.data_division == 30 || element.data_division == 60 || element.data_division == 90) {
                                      total_price = element.organization_total_price == 0 ? 0 : element.organization_total_price * (-1)
                                      total_price = total_price.toLocaleString()
                                    } else {
                                      total_price = element.organization_total_price
                                      total_price = total_price.toLocaleString()
                                    }
                                  }
                                })}
                                {total_price}
                              </div>
                            )
                          }}
                        />
                      )
                    })
                  }
                </Table>
                {/* <Table
                  // columns={columns}
                  expandable={{ expandedRowRender: this.expandedRowRender }}
                  dataSource={this.state.dataSource}
                  size='small'
                  bordered
                  rowKey={record => record.id}
                  style={{ width: '100%' }}
                >
                  <Table.Column
                    title='保険者'
                    dataIndex='insurer_total_price'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='事業者'
                    dataIndex='office_total_price'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='他団体'
                    dataIndex='organization_total_price'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='個人１'
                    dataIndex='personal_1_total_price'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='個人２'
                    dataIndex='personal_2_total_price'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='個人３'
                    dataIndex='personal_3_total_price'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='合計'
                    dataIndex='Expression_56'
                    className='column-size-5'
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                      )
                    }}
                  />
                </Table> */}

              </div>
            </div>
            {/* </Row> */}
            {/* <Row gutter={24}>
                <Col span={12}>
                  <hr style={{ margin: '15px 0' }}></hr>
                  <WS2586020_ConsultHistorySub Li_PersonalNum={this.state.infoPage.personal_number_id} />
                </Col>

                <Col span={12}>
                  <hr style={{ margin: '15px 0' }}></hr>
                  <WS2587021_InspectChangeQuerySub
                    Random={Math.random()}
                    Li_ReserveNum={this.state.infoPage.Li_ReserveNum}
                    Li_DataClassify={''}
                    Li_Course={this.state.infoPage.visit_course}
                    Li_ContractStartDate={this.state.infoPage.contract_start_date_on}
                  />
                </Col>
              </Row> */}
            {/* </Spin> */}
          </Form>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 800,
                    component:
                      <WS2586020_ConsultHistorySub
                        Li_PersonalNum={this.state.infoPage.personal_number_id}
                        onFinishScreen={(output) => {
                          this.closeModal()
                        }}
                      />
                    ,
                  },
                })
              }}
            >
              履歴
            </Button>
            <Button type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 800,
                    component:
                      <WS2593056_BillingInquirySub
                        Li_ReserveNum={this.state.infoPage.Li_ReserveNum}
                        onFinishScreen={(outputdata) => {
                          this.closeModal()
                        }}
                      />
                    ,
                  },
                })
              }}
            >
              請求照会
            </Button>
            <Button
              type='primary'
              onClick={() => this.VisitsInspect()}>
              検査照会
            </Button>
            <Button type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 'fit-content',
                    centered: true,
                    component:
                      <WS2537001_PersonalReserveProcess
                        Li_CourseLevel={this.state.infoPage.course_level}
                        Li_ReserveNum={this.state.infoPage.Li_ReserveNum}
                        Li_PersonalNum={this.state.infoPage.personal_number_id}
                        Li_Date={this.state.infoPage.Expression_28}
                        Li_Getctxname={''}
                        Li_ProcessDivision={''}
                        Li_Option={''}
                        Li_Child={true}
                        onFinishScreen={(value) => {
                          //this.closeModal()
                          if (!value) {
                            this.props.onFinishScreen()
                          }
                          this.loadData(this.props.Li_ReserveNum)
                        }}
                      />
                    ,
                  },
                })
              }}>
              受診変更
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2583001_ConsultInquirySub)
