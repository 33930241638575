import axios from 'configs/axios'

const apiPaths = {
  index: '/api/radiography-finding-input/judge-select-query-sub',
}

const JudgeSelectQuerySubService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  }
}

export default JudgeSelectQuerySubService
