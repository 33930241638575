/* eslint-disable eqeqeq */
import React from 'react'
import { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, SearchOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Card, Table, Form, Input, Dropdown, Select, Menu, } from 'antd'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS1132001_HigashifukyoDataInput from 'pages/KK_ResultOutput/V4CP0044100_EastFukyoConverseProcess/WS1132001_HigashifukyoDataInput'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx'
import EastFukyoConverseProcessAction from 'redux/ResultOutput/EastFukyoConverseProcess/EastFukyoConverseProcess.action.js'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import Color from 'constants/Color'

const today = moment(new Date().toISOString())
// ※開発用、後で消す
// const today = moment(new Date('2017/01/11').toISOString())

/**
 * 画面表示時のデフォルトのコース from , to
 * @type {string}
 */
const defaultCourseCodeF = 'B01';
const defaultCourseCodeT = 'B99';

class WS1131001_EastFukyoConverseProcess extends React.Component {
  static propsType = {
    Li_Date: PropTypes.any,
    Li_Id: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    Li_Option: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.office = React.createRef()

    // document.title = '東振協変換処理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      selectedRowKeys: [],
      selectedRows: [],
      dataSource: [],
      KeyNumList: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 初期値
    this.formRef.current.setFieldsValue({
      DateFChars: today.format('YYYY/MM/DD'),
      DateTChars: today.format('YYYY/MM/DD'),
      BranchStoreCodeT: 99999,
      CourseCodeF: defaultCourseCodeF,
      CourseCodeT: defaultCourseCodeT,
    })
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    EastFukyoConverseProcessAction.index()
      .then((res) => {
        console.log(res)
        this.setState({
          KeyNumList: res.OutputOrder,
        })
      })

    // ※開発用、後で消す
    // this.getExamineeList()
  }

  /**
   * 受診者一覧を取得
   */
  getExamineeList = () => {
    let params = {
      DateFChars: moment(this.formRef.current.getFieldValue('DateFChars')).format('YYYY/MM/DD'),
      DateTChars: moment(this.formRef.current.getFieldValue('DateTChars')).format('YYYY/MM/DD'),
      OfficeCode: this.formRef.current.getFieldValue('OfficeCode'),
      BranchStoreCodeF: this.formRef.current.getFieldValue('BranchStoreCodeF'),
      BranchStoreCodeT: this.formRef.current.getFieldValue('BranchStoreCodeT'),
      CourseCodeF: this.formRef.current.getFieldValue('CourseCodeF'),
      CourseCodeT: this.formRef.current.getFieldValue('CourseCodeT'),
      KeyNum: 0,
      DataSourceName: ''
    }

    EastFukyoConverseProcessAction.getExamineeList(params)
      .then(res => {
        console.log(res);
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * 東振協データ入力　モーダル
   */
  showWS1132001_HigashifukyoDataInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS1132001_HigashifukyoDataInput
            Li_ReserveNum={record.reservation_number}
            Li_PersonalNumId={record.personal_number_id}
            Li_CourseLevel={record.course_level}
            Li_PatternCode={record.pattern_code}
            Li_JudgeLevel={record.judgment_level_division}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 事業所検索照会　モーダル　
   */
  showWS0247001_OfficeInfoRetrievalQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output?.Lio_OfficeCode,
                BranchStoreCodeF: output?.Lio_BranchStoreCode,
                office_kanji_name: output?.Lo_Kanji_Name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 基本コース照会　モーダル
   * @param {*} name
   */
  showWS0265001_BasicCourseInquiry = (name) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={({ Lo_CourseCode }) => {
              this.formRef.current.setFieldsValue({
                [name]: Lo_CourseCode,
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 個人情報照会　モーダル
   * @param {*} id
   */
  showWS2584019_PersonalInfoInquirySub = (personal_number_id) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personal_number_id}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='east-fukyo-converse-process' >
        <Card title='東振協変換処理'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' >
                <div className='box_search' >
                  <div className='box_inner_vertical' style={{ gap: 0 }}>
                    <div className='box_inner_horizontal' >
                      <Form.Item label='受診日' name='DateFChars'>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                      <Form.Item label='~' name='DateTChars'>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='事業所' name='OfficeCode'>
                        <Input.Search
                          className='input-search-size-number-10'
                          onPressEnter={() => {
                            // 事業所検索照会　モーダル
                            this.showWS0247001_OfficeInfoRetrievalQuery()
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                                // 事業所検索照会　モーダル
                                this.showWS0247001_OfficeInfoRetrievalQuery()
                              }
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='BranchStoreCodeF'>
                        <Input
                          type='number'
                          className='input-size-number-5'
                        />
                      </Form.Item>
                      <Form.Item label='~' name='BranchStoreCodeT'>
                        <Input
                          type='number'
                          className='input-size-number-5'
                        />
                      </Form.Item>
                      <div style={{ marginTop: 5 }}>
                        {this.formRef.current?.getFieldValue('office_kanji_name') ?? ''}
                      </div>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='コース' name='CourseCodeF'>
                        <Input.Search
                          className='input-search-size-4'
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              // コース照会
                              this.showWS0265001_BasicCourseInquiry('CourseCodeF')
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='~' name='CourseCodeT'>
                        <Input.Search
                          className='input-search-size-4'
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              // コース照会
                              this.showWS0265001_BasicCourseInquiry('CourseCodeT')
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item
                      label='表示順'
                      name='KeyNum'
                      style={{ marginBottom: 0 }}>
                      <Select style={{ width: '150px' }}>
                        {this.state.KeyNumList?.map(val => (
                          <Select.Option key={'OutputOrder-' + Math.random()} value={val.Linked} >{val.Display}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_inner_horizontal'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.getExamineeList()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: 400 }}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () => {
                    // 東振協データ入力
                    this.showWS1132001_HigashifukyoDataInput(record)
                  }
                })}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  className='column-size-date'
                  render={(value, record, index) => {
                    return (
                      <div>{moment(value)?.format('YYYY/MM/DD (ddd)')}</div>
                    )
                  }}
                />
                <Table.Column
                  title='受付No'
                  dataIndex='receipt_number'
                  className='column-size-5'
                  render={(value) =>
                    <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                  }
                />
                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  className='column-size-10'
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'right' }}>
                      {value === 0 ? '' : value}
                    </div>
                  } />
                {/* <Table.Column title='メモ' width={40} /> */}
                <Table.Column
                  width={40}
                  title='メモ'
                  dataIndex='importance'
                  render={(value, record, index) => {
                    let icon = null
                    switch (record.importance) {
                      case 1:
                        icon = (<InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />)
                        break
                      case 3:
                        icon = (<WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />)
                        break
                      case 5:
                        icon = (<CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />)
                        break
                      default:
                        icon = (<Button size='small' icon={<MoreOutlined />}></Button>)
                        break
                    }
                    return (
                      <div
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        hidden={!record.personal_number_id}
                        onClick={() => {
                          // 個人情報照会　モーダル
                          this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                        }} >
                        {icon}
                      </div>)
                  }}
                />
                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  className='column-size-40'
                />
                <Table.Column
                  title='契約情報'
                  dataIndex='contract_short_name'
                />
                <Table.Column
                  title='事業所情報'
                  dataIndex='office_kanji_name'
                  className='column-size-40'
                />
                <Table.Column
                  title='総合判定'
                  dataIndex='comprehensive_judgment'
                  width={80}
                  render={(value, record) =>
                    <div
                      style={{
                        color: Color(record.comprehensiveJudgmentColorNum)?.Foreground,
                        textAlign: 'center'
                      }}>
                      {value}
                    </div>
                  }
                />
                <Table.Column
                  width={45}
                  align='center'
                  fixed='right'
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu-1'
                              onClick={() => {
                                // 東振協データ入力
                                this.showWS1132001_HigashifukyoDataInput(record)
                              }}
                            >
                              変換
                            </Menu.Item>
                            <Menu.Item
                              key='menu-2'
                              onClick={() => {
                                // 個人情報照会　モーダル
                                this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                              }}
                            >
                              照会
                            </Menu.Item>
                            <Menu.Item
                              key='menu-3'
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  childModal: {
                                    width: 1200,
                                    visible: true,
                                    component: (
                                      <WS2637001_OverallResultDisplayInput
                                        Li_CourseLevel={record.course_level}
                                        Li_ReserveNum={record.reservation_number}
                                        onClickedCreate={() => {
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                            >
                              結果入力
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size='small' icon={<MoreOutlined />} />
                      </Dropdown>
                    )
                  }}
                />
              </Table>

            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1131001_EastFukyoConverseProcess)
