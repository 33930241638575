import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Form, Checkbox, Button, message } from 'antd'
import AutoJudgeAction from 'redux/InputBusiness/ExamineeList/AutoJudge.action'
import WS0061015_CheckYesNoNo from '../V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS2635011_AutoJudge extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_DataSourceName: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '自動判定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }


  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    let params = {
      Li_DataSourceName: this.props.Li_DataSourceName ? this.props.Li_DataSourceName : ''
    }

    AutoJudgeAction.index(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue(res?.data)
      })

      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 「はい」ボタン押下
   * @param {*} values
   */
  eventYes(values) {
    AutoJudgeAction.eventYes(values)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 実行ボタン押下処理
   * @param {*} values
   */
  runAutoJudge = (values) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component:
          <WS0061015_CheckYesNoNo
            Li_Message={'一覧に表示されている受診者の自動判定を行いますか？'}
            onFinishScreen={({ Lio_StsReturn }) => {
              if (Lio_StsReturn) {
                this.eventYes(values)
              }
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='auto-judge'>
        <Card title='自動判定'>

          <Form
            ref={this.formRef}
          >
            <div className='box_inner_vertical'>
              <div>以下の受診者を一括で自動判定しますか？</div>
              <div className='box_border'>

                {/* 必要なし？？？ */}
                {/* <div hidden>
                  <Form.Item name='ComplexJudge'>  <Input /> </Form.Item>
                  <Form.Item name='OptionSys0000031'>  <Input /> </Form.Item>
                  <Form.Item name='ReplacementInstructions'>  <Input /> </Form.Item>
                </div> */}

                <Form.Item
                  name='StsUndeterminedOnly'
                  valuePropName='checked'
                >
                  <Checkbox>未判定の検査のみ</Checkbox>
                </Form.Item>

                <Form.Item
                  name='StsHierarchical'
                  valuePropName='checked'
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>階層化判定を実行</Checkbox>
                </Form.Item>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen()
                  }
                }}
              >
                キャンセル
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.runAutoJudge()
                }}
              >
                <span className='btn_label'>
                  実行
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2635011_AutoJudge)
