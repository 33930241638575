import axios from 'configs/axios'

const apiPaths = {
  index: '/api/acceptance-batch-process/acceptance-batch-process',
  getVisitList: '/api/acceptance-batch-process/acceptance-batch-process/get-visit-list',
  setAcceptOrCancel: '/api/acceptance-batch-process/acceptance-batch-process/set-accept-or-cancel',
}

const AcceptanceBatchProcessService = {
  async index() {
    return axios.get(apiPaths.index)
  },
  async getVisitList(params) {
    return axios.get(apiPaths.getVisitList, { params })
  },
  async setAcceptOrCancel(params) {
    return axios.post(apiPaths.setAcceptOrCancel, params)
  },
}

export default AcceptanceBatchProcessService
