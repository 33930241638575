import AccountsReceivableListOutputInstructionService from "services/AccountingBusiness/AccountsReceivableListOutputInstruction/AccountsReceivableListOutputInstructionService"
import { message } from "antd";

const AccountsReceivableListOutputInstructionAction = {
  GetScreenData() {
    return AccountsReceivableListOutputInstructionService.GetScreenData()
      .then((res) => {
        // message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  Csv_F11(params) {
    return AccountsReceivableListOutputInstructionService.Csv_F11(params)
  },

  Printer_F12(params) {
    return AccountsReceivableListOutputInstructionService.Printer_F12(params)
  },
}

export default AccountsReceivableListOutputInstructionAction;
