import React from "react";
import { connect } from "react-redux";

import { Card, Checkbox, Form, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";

class WS1470001_GuideInputResult extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-THK00083:指導入力[結果]';

    this.state = {
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="guide-input-result">
        <Card className="mb-2" title="V4-THK00083:指導入力[結果]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row gutter={24} className='mt-3'>
              <Col span={24}>

                <Row gutter={24} >
                  <Col span={2}>
                    <Form.Item name="受診日" style={{ float: "right" }} label="受診日" />
                  </Col>
                  <Col span={3}>
                    <Form.Item name="" label="" >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input type="text" />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="ID" label="ID" />
                  </Col>
                  <Col span={3}>
                    <Input type="text" />
                  </Col>
                  <Col span={4}>
                    <Input type="text" />
                  </Col>
                  <Col span={3}>
                    <Input type="text" />
                  </Col>
                  <Col span={1}>
                    <Input type="text" />
                  </Col>
                  <Col span={1}>
                    <Input type="text" style={{ textAlign: "right" }} />
                  </Col>
                  <Col span={3}>
                    <Form.Item name="11" label="" >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="保険" label="保険" />
                  </Col>
                  <Col span={3}>
                    <Input type="text" style={{ textAlign: "right" }} />
                  </Col>
                  <Col span={6}>
                    <Input type="text" />
                  </Col>
                  <Col span={4}>
                    <Input type="text" />
                  </Col>
                  <Col span={7}>
                    <Input type="text" />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="指導区分" label="指導区分" />
                  </Col>
                  <Col span={3}>
                    <Input type="text" />
                  </Col>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="コース" label="コース" />
                  </Col>
                  <Col span={3}>
                    <Input type="text" />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="実施日" label="実施日" />
                  </Col>
                  <Col span={3}>
                    <Form.Item name="11" label="" >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="評価区分" label="評価区分" />
                  </Col>
                  <Col span={2}>
                    <Input type="text" />
                  </Col>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="支援項目" label="支援項目" />
                  </Col>
                  <Col span={3}>
                    <Input type="text" />
                  </Col>
                  <Col span={2}>
                    <Form.Item style={{ float: "right" }} name="支援量" label="支援量" />
                  </Col>
                  <Col span={1}>
                    <Input type="text" style={{ textAlign: "right" }} />
                  </Col>
                  <Col span={4}>
                    <Input type="text" />
                  </Col>
                  <Col span={3}>
                    <Space>
                      <Form.Item>
                        <Button type="primary">前回DO</Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary">健診連携</Button>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>

              </Col>
              <Col span={24}>
                <Table bordered size='small'
                  dataSource={[]}
                  pagination={true}
                >
                  <Table.Column title="表示順" width={100} dataIndex="" align='' render={(text, record, index) => (
                    <Input readOnly
                      value={text}
                      style={{ textAlign: 'right', border: 'none' }}
                    />
                  )} />
                  <Table.Column title="コード" width={100} dataIndex="" align='' render={(text, record, index) => (
                    <Input readOnly
                      value={text}
                      style={{ textAlign: 'right', border: 'none' }}
                    />
                  )} />
                  <Table.Column title="検査名"  width={250} dataIndex="" align='center' />
                  <Table.Column title="氏名" width={80} dataIndex="" align='' />
                  <Table.Column title="目標" width={130} dataIndex="" align='' render={(text, record, index) => (
                    <Input readOnly
                      value={text}
                      style={{ textAlign: 'right', border: 'none' }}
                    />
                  )} />
                  <Table.Column title="実績" width={130} dataIndex="" align='' />
                  <Table.Column title="内容" dataIndex="" align='' />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1470001_GuideInputResult);
