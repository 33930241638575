import { message } from 'antd'
import PrintParamMaintainService from 'services/ResultOutput/PrintParamMaintain/PrintParamMaintainService.js'

const PrintParamInputAction = {
  index() {
    return PrintParamMaintainService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },

  deletePrintParamMaintainAction(params) {
    return PrintParamMaintainService.deletePrintParamMaintainService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },

  savePrintParamMaintainAction(params) {
    return PrintParamMaintainService.savePrintParamMaintainService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },

  itemF12Action(params) {
    return PrintParamMaintainService.itemF12Service(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },

  stsChangeAction(params) {
    return PrintParamMaintainService.stsChangeService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },
  printf10(params) {
    return PrintParamMaintainService.printf10(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },
}

export default PrintParamInputAction
