import axios from "configs/axios";

const APP_LIST = {
  getScreenData: `/api/specific-medical-exam-settle-process/single-group-classify-input-sub/getscreenData`,
  runBtn: `/api/specific-medical-exam-settle-process/single-group-classify-input-sub/runbtn`,
};
const SingleGroupClassifyInputSubService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async runBtn(params) {
    return axios.post(APP_LIST.runBtn, params);
  },
};

export default SingleGroupClassifyInputSubService;
