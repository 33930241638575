import axios from 'configs/axios'

const apiPaths = {
  index: '/api/mutual-aid-association-billing/fd-create-municipal-mutual-aid-association',
  execution: '/api/mutual-aid-association-billing/fd-create-municipal-mutual-aid-association/execution',
  downloadCSVFile: '/api/mutual-aid-association-billing/fd-create-municipal-mutual-aid-association/download-CSV-File',
  InquiryIndex: '/api/mutual-aid-association-billing/fd-create-municipal-mutual-aid-association/InquiryIndex',
}

const FdCreateMunicipalMutualAidAssociationService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async execution(params) {
    return axios.post(apiPaths.execution, params)
  },
  async downloadCSVFile(params) {
    return axios.post(apiPaths.downloadCSVFile, params, { responseType: 'blob' });
  },
  async InquiryIndex(params) {
    return axios.get(apiPaths.InquiryIndex, { params })
  },
}
export default FdCreateMunicipalMutualAidAssociationService
