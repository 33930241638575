import { Button, Card, Col, Form, Input, message, Row, Table } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import axios from "configs/axios";
import { width } from "dom-helpers";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1181005_CmtConvert extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_InspectCode: PropTypes.any,
    Li_ExamName: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '複写';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingForm: false,
      isLoadingTable: false,
      exam_comment_code: 0,
      InspectCode: props.Li_InspectCode,
      ExamName: props.Li_ExamName,
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onFinish(values) {

  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {

    if (this.props !== prv) {
      this.getScreenData();
    }
  }

  updateOrCreateRecord(index) {

    axios.get('/api/associate-insure-param-maintain/copy/save', {
      params: {
        CopyCode: this.formRef.current.getFieldValue('CopyCode')
      },
    })
      .then((res) => {

        this.closeModal();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  getScreenData = () => {
    this.setState({ 
      isLoading: true,
      InspectCode: this.props.Li_InspectCode,
      ExamName: this.props.Li_ExamName,
      isLoadingTable: true,
    });
    axios.get('/api/associate-insure-param-maintain/cmt-convert/getscreenData', {
      params: {
        InspectCode: this.formRef.current.getFieldValue('InspectCode') ? this.formRef.current.getFieldValue('InspectCode') : this.props.Li_InspectCode
      },
    })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          InspectCode: res ? res.data.InspectCode : '',
          exam_name: res ? res.data.exam_name : '',
          tableData: res ? res.data.SUB_result : [],
        })

        this.setState({
          isLoadingTable: false,
          isLoading: false,
          exam_comment_code: res.data.exam_comment_code,
        })


      })
      .catch(error => {

        this.setState({ isLoading: false });
        this.setState({ isLoadingTable: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  RunBtn = () => {
    this.setState({ isLoading: true, isLoadingTable: true });
    const InspectCode = this.formRef.current.getFieldValue('InspectCode') ?? this.props.Li_InspectCode;
    VenusApiRoutesV2.callApi("API001181005006", {InspectCode}).then(res => {
      VenusApiRoutesV2.callApi("API001181005005", {InspectCode}).then(res => {
          this.setState({
            isLoadingTable: false,
            isLoading: false,
          })
          this.closeModal();
          this.props.onFinishScreen();
        });
    })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  render() {
    return (
      <div className="cmt-convert">
        <Card title="コメント変換">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row className='mt-2' >
              <Col span={2} style={{display: "flex", alignItems: "center"}}>
                検査コード
              </Col>
              <Col span={8} style={{display: "flex", alignItems: "center"}}>
                  <Input
                    type="text" style={{ minWidth: 100 }}
                    readOnly={true}
                    value={this.state.InspectCode}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          component: (
                            <WS0271001_InspectItemSearchQuerySingle
                              onFinishScreen={(output) => {
                                this.setState({InspectCode: output.Lio_InspectItemCode, ExamName: output.Lo_exam_name});
                                this.formRef.current?.setFieldsValue({
                                  InspectCode: output.Lio_InspectItemCode,
                                  exam_name: output.Lo_exam_name
                                })
                                this.closeModal();
                              }}
                            />
                          ),
                          width: "70%",
                        },
                      });
                    }} />
              </Col>
              <Col span={8} style={{display: "flex", alignItems: "center"}}>
                {this.state.ExamName}
              </Col>
              <Col span={6} >
                <Form.Item >
                  <Button type="primary" onClick={() => { this.RunBtn() }} >実行</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row className='mt-2' >
              <Col span={24} >
                <Table bordered
                  dataSource={this.formRef.current?.getFieldValue("tableData")}
                  loading={this.state.isLoadingTable}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: 10,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => { },
                    onShowSizeChange: (page, pageSize) => { },
                  }}
                  rowKey={(record) => record.exam_comment_code}
                >
                  <Table.Column
                    title="コード"
                    dataIndex="exam_comment_code"
                    sorter={(a, b) => a.exam_comment_code.localeCompare(b.exam_comment_code)}
                    showSorterTooltip={false}
                  />
                  <Table.Column
                    title="結果"
                    dataIndex="exam_comment_screen"
                    sorter={(a, b) => a.exam_comment_code.localeCompare(b.exam_comment_code, 'jp')}
                    showSorterTooltip={false}
                  />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1181005_CmtConvert);
