import InspectCmtInfoMaintainService from 'services/InspectionMaintenance/ExamCmtInfoMaintain/InspectCmtInfoMaintainService'


const InspectCmtInfoMaintainAction = {
  index(params) {
    return InspectCmtInfoMaintainService.index(params)
  },
  getExamName(params) {
    return InspectCmtInfoMaintainService.getExamName(params)
  },
  copyRegister(params) {
    return InspectCmtInfoMaintainService.copyRegister(params)
  }
}
export default InspectCmtInfoMaintainAction
