import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReceiptProcessSubAction from 'redux/CounterBusiness/Counter/ReceiptProcessSub.action'

import { Card, Form, Table, Button, Menu, Dropdown, message, } from 'antd'
import { ProfileOutlined, ExclamationOutlined, MoreOutlined, } from '@ant-design/icons'
import { getIntroduceCounterAction, AcceptButtonAction, eventEnterC_CounterAction, userAction3CounterAction, getListDataCounterAction } from 'redux/CounterBusiness/Counter/Counter.action'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0273001_VisitsTargetSearchQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273001_VisitsTargetSearchQuery.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay'
import WS2587021_InspectChangeQuerySub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2587021_InspectChangeQuerySub.jsx'
import WS2621001_PaymentProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621001_PaymentProcessSub.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2622003_ReceiptProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622003_ReceiptProcessSub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub.jsx'
import WS2620036_Refine from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620036_Refine'
import WS2623001_DispensingProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2623001_DispensingProcessSub'
import WS2624003_VisitsCancelConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624003_VisitsCancelConfirm.jsx'
import GetImage from 'constants/Images'
import Color from 'constants/Color'
import WS0956001_PaymentCorrectSub from './WS0956001_PaymentCorrectSub'
import WS2624002_VisitsChangeConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624002_VisitsChangeConfirm'
import { ReturnIcon } from 'components/Commons/ReturnIcon'
import ModalDraggable from 'components/Commons/ModalDraggable'


const styleDiv = {
  border: 'solid 1px #ABADB3',
  width: '80px',
  height: '24px',
  textAlign: 'center',
  lineHeight: '22px',
}
const styleDivExpression = {
  width: '50px',
  height: '24px',
  border: '1px solid #ABADB3',
  textAlign: 'center',
  lineHeight: '22px',
}
const styleDivTitle = {
  background: '#1C66B9',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em',
  color: '#FFFFFF'
}
const styleDivResult = {
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em'
}

class WS2620500_CounterInquiry extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '窓口'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
        margin: 0,
      },
      widthWS2622003: '',
      isLoading: false,
      dataSource: [],
      record: {},
      FacilityTypeList: [],
      iniObj: {},
      importance: 0,
      // initParams: {
      //   DateChar: moment().format(dateFormat),
      //   ReserveNumAndPersonalNum: '',
      //   FacilityType: 0,
      //   State: '1',
      //   Pay: null,
      // },
      Li_Change: '',
      menuItems: [
        // { id: 1, label: '詳細抽出', handleClick: this.HandelF7 },
        { key: 'reDisplay', id: 1, label: '再表示', handleClick: this.eventF10 },
        { key: 'newReception', id: 1, label: '新規受付', handleClick: this.eventF11 },
      ],
    }
  }

  componentDidMount = () => {
    let array = []

    array.push(this.props.Li_Record)
    this.setState({
      record: this.props.Li_Record,
      dataSource: array
    })
  }

  renderDivCacl = (title, price) => (
    <div className='input-size-number-7'>
      <div style={styleDivTitle}>{title}</div>
      <div style={styleDivResult}>{
        price === 0 ? null : price?.toLocaleString()}
      </div>
    </div>
  )

  renderDivCacl2 = (title, price) => (
    <div style={{ width: '8rem' }}>
      <div style={styleDivTitle}>{title}</div>
      <div style={styleDivResult}>{
        price === 0 ? null : price?.toLocaleString()}
      </div>
    </div>
  )


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
        className: ''
      },
    })
  }

  expandedRowRender = (index) => {
    return (
      < WS2587021_InspectChangeQuerySub
        Li_ReserveNum={this.state.record?.reservation_number}
        Li_DataClassify={''}
        Li_Course={this.state.record?.visit_course}
        Li_ContractStartDate={this.state.record?.contract_start_date_on}
        Li_Change={this.state.Li_Change}
        Li_Record={this.state.record}
      />
    )
  }

  ReturnComponent = (component) => {
    let components = {
      WS2583001_ConsultInquirySub,
      WS2537001_PersonalReserveProcess,
      WS2624002_VisitsChangeConfirm,
      WS2624003_VisitsCancelConfirm,
      WS2621001_PaymentProcessSub,
      WS2623001_DispensingProcessSub,
      WS2622003_ReceiptProcessSub,
      WS0956001_PaymentCorrectSub,
      WS0650001_DocumentBatchCreateSub
    }
    return components[component]
  }

  /**
   * 受付処理
   */
  AcceptBtn = () => {
    const { record, iniObj } = this.state
    let params = {
      state_flag: record.state_flag,
      DeskReceptistConfirmed: this.props.Li_iniObj.DeskReceptistConfirmed ? 1 : 0,
      course_level: record.course_level,
      reserve_num: record.reservation_number,
      visit_date_on: record.visit_date_on,
      personal_1_total_price: record.personal_1_total_price,
      personal_2_total_price: record.personal_2_total_price,
      personal_3_total_price: record.personal_3_total_price,
      deposit_price_1: record.deposit_price_1,
      deposit_price_2: record.deposit_price_2,
      deposit_price_3: record.deposit_price_3,
      AcceptNum: record.receipt_number
    }
    AcceptButtonAction(params)
      .then(res => {
        if (res.data) {
          let props = { Li_ReserveNum: res.data.Li_ReserveNum }
          if (res.data.message.includes('WS2621001_PaymentProcessSub')) {
            this.callModal(props, 420, 'WS2621001_PaymentProcessSub')
          } else {
            this.props.onFinishScreen({
              Li_id: record.id
            })
          }
        }
      })
  }

  callModal = (props, width, nameScreen, className) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        className: className,
        component: (
          <Component
            {...props}
            onFinishScreen={(outPut) => {
              this.props.onFinishScreen({ Li_id: this.state.record.id })
            }}
          />
        ),
      },
    })
  }

  personalReserveProcess() {
    const { record, iniObj } = this.state
    let props = {
      Li_CourseLevel: record.course_level,
      Li_ReserveNum: record.reservation_number,
      Li_PersonalNum: record.personal_number_id,
      Li_Date: this.state.iniObj.DateChar,
      Li_Getctxname: '',
      Li_ProcessDivision: '',
      Li_Option: '',
      Li_Child: true
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            {...props}
            onFinishScreen={(outPut) => {
              if (!outPut.closeFlag) {
                this.closeModal()
              }
            }}
          />
        ),
      },
    })
  }

  render() {
    const { FacilityTypeList, dataSource, record } = this.state
    return (
      <div className='counterInquiry' style={{ width: 800 }}>
        <Card title={record?.Expression_56 ? record?.Expression_56 : '受付'}>
          <div className='box_container'>
            <Form ref={this.formRef} onFinish={this.onFinish} >
              <div className='box_inner_horizontal'>

                <div className='box_border' style={{ width: '55%' }}>

                  <div style={{ position: 'relative' }}>

                    <Form.Item label='　個人番号' style={{ marginBottom: 0 }}>
                      <div className='box_inner_horizontal'>
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >{record?.personal_number_id}</div>
                        <Button
                          type={this.state.importance === 0 ? 'default' : 'text'}
                          icon={ReturnIcon(this.state.importance)}
                          size='small'
                          style={{ display: record?.personal_number_id ? '' : 'none' }}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1600,
                                component: (
                                  <WS2584019_PersonalInfoInquirySub
                                    Li_PersonalNum={record?.personal_number_id}
                                    onFinishScreen={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },

                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        ></Button>
                      </div>
                    </Form.Item>
                    <Form.Item label='　　保険証' style={{ marginBottom: 0 }}>
                      <span>{record?.Expression_58}</span>
                    </Form.Item>
                    <Form.Item label='　　氏　名' style={{ marginBottom: 0 }} >
                      <span>{record?.Expression_43}</span>
                    </Form.Item>
                    <Form.Item label='　　　　　' style={{ marginBottom: 0 }} >
                      <span>{record?.Expression_44}</span>
                    </Form.Item>
                    <Form.Item label='　生年月日' style={{ marginBottom: 0 }} >
                      <div className='box_inner_horizontal'>
                        {/* <span>{record?.Expression_45 ? moment(record?.Expression_45).format('NNy/MM/DD') : null}</span> */}

                        <span>{record?.Expression_45 ? record?.Expression_45 : null}</span>
                        <span>{record?.Expression_46}</span>
                      </div>
                    </Form.Item>
                    <div>
                      <img
                        src={record?.Expression_59 === '男性.png' ? GetImage('man') : GetImage('woman')}
                        alt='gender'
                        style={{ display: record?.Expression_59 ? '' : 'none', position: 'absolute', top: '15%', right: '5%', width: '15%' }} />
                    </div>
                  </div>

                  <div className='box_border_bottom_no_padding' />

                  <div style={{ marginTop: '10px' }}>
                    <Form.Item label='　　事業所' style={{ marginBottom: 0 }}>
                      <span>{record?.Expression_47}</span>
                    </Form.Item>
                    <Form.Item label='保険者番号' style={{ marginBottom: 0 }}>
                      <span>{record?.insurer_code}</span>
                    </Form.Item>
                    <Form.Item label='保険者名称' style={{ marginBottom: 0 }}>
                      <span>{record?.insurer_kanji_name}</span>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_border' style={{ width: '45%' }}>
                  <div className='box_inner_horizontal'>
                    <Form.Item label='受診日' style={{ marginBottom: 0 }}>
                      <span>{record?.Expression_48}</span>
                    </Form.Item>
                    <Form.Item
                      style={{
                        marginBottom: 0,
                        float: 'right',
                        display: 'flex',
                        marginLeft: 'auto'
                      }}
                    >
                      <div style={{ ...styleDiv, color: Color(record?.Expression_55)?.Foreground }}>{record?.Expression_54}</div>{/* Expression_54 */}
                    </Form.Item>
                  </div>
                  <Form.Item label='時間帯' style={{ marginBottom: 0 }} >
                    <span>{record?.Expression_49 ? moment(record?.Expression_49, 'HH:mm').format('HH:mm') : null}</span>
                  </Form.Item>
                  <Form.Item label='施　設' style={{ marginBottom: 0 }} >
                    <span>{record?.Expression_52}</span>
                  </Form.Item>
                  <Form.Item label='コース' style={{ marginBottom: 0 }} >
                    <div className='box_inner_horizontal'>
                      <span>{record?.Expression_50}</span>
                      <Button
                        icon={<MoreOutlined />}
                        size='small'
                        disabled={!record?.Expression_50}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1600,
                              component: (
                                <WS0605127_ContractLineItemDisplay
                                  Li_ContractType={record?.contract_type}
                                  Li_ContractOrgCode={record?.contract_organization_code}
                                  Li_ContractStartDate={record?.contract_start_date_on}
                                  Li_ContractNum={record?.contract_number}
                                  onFinishScreen={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },

                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      ></Button>
                      <span>{record?.Expression_51}</span>
                    </div>
                  </Form.Item>
                  <Form.Item label='備　考' style={{ marginBottom: 0 }} >
                    <span>{record?.Expression_53}</span>
                  </Form.Item>
                </div>
              </div>
            </Form>
            <Table
              // columns={columns}
              expandable={{ expandedRowRender: this.expandedRowRender }}
              dataSource={this.state.dataSource}
              size='small'
              bordered
              rowKey={record => record.id}
              style={{ width: '100%' }}
            >
              <Table.Column
                title='保険者'
                dataIndex='insurer_total_price'
                className='column-size-2'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='事業者'
                dataIndex='office_total_price'
                className='column-size-2'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='他団体'
                dataIndex='organization_total_price'
                className='column-size-2'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='個人１'
                dataIndex='personal_1_total_price'
                className='column-size-2'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='個人２'
                dataIndex='personal_2_total_price'
                className='column-size-2'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='個人３'
                dataIndex='personal_3_total_price'
                className='column-size-2'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='合計'
                dataIndex='Expression_87'
                className='column-size-5'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value?.toLocaleString()}</div>
                  )
                }}
              />
            </Table>
          </div>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<ProfileOutlined />}
              disabled={!record?.Expression_86}
              onClick={() => this.personalReserveProcess()}
            >
              <span className='btn_label'>
                {'変更'}
              </span>
            </Button>
            <Button
              type='primary'
              icon={<ProfileOutlined />}
              disabled={!record?.Expression_86}
              onClick={() => this.AcceptBtn()}
            >
              <span className='btn_label'>
                {record?.Expression_56 ? record?.Expression_56 : '受付'}
              </span>
            </Button>
          </div>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
export default WS2620500_CounterInquiry
