import React from "react";
import { connect } from "react-redux";
import CopyingProcessAction from 'redux/SystemMaintenance/UserParamMaintain/CopyingProcess.action'
import { Card, Form, Input, Button, Row, Col } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0449005_CopyingProcess extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '複写処理';

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
  }

  callAPILoadScreenData(params) {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API000449005001", params)
      .then((res) => {
        this.formRef.current?.setFieldsValue(res)
      }).finally(() => this.setState({ isLoading: false }))
  }

  onFinish() {
    let data = this.formRef.current?.getFieldsValue()
    let params = {
      ...data,
      format: this.props.userunitfdmaster.format
    }
    CopyingProcessAction.f12Key(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  render() {
    return (
      <div className="copying-process">
        <Card title="複写処理" className="mb-2">
          <Form
            ref={this.formRef}
          >
            <div className="box_inner_vertical">
              <div className="box_inner_horizontal">
                <div className="div_label" style={{ marginTop: 5, color: '#777' }}>複写元</div>
                <div className="div_data">{this.props.userunitfdmaster.format}</div>
                <div className="div_data">{this.props.userunitfdmaster.remarks}</div>
              </div>
              <div className="box_inner_horizontal" style={{ marginLeft: 5 }}>
                <Form.Item
                  name="Format"
                  label="複写先"
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  name="Remarks"
                  label=""
                >
                  <Input type="text" />
                </Form.Item>
              </div>
            </div>
            <Form.Item style={{ textAlign: "right", marginTop: "2em" }}>
              <Button type="primary" onClick={() => this.onFinish()}>複写</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0449005_CopyingProcess);