import InspectItemJudgeValueSettingService from "services/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSettingService";
import { message } from "antd";

export const getScreenData = (params) => {
  return InspectItemJudgeValueSettingService.getScreenData(params)
}

export const getSingleJudgementData = (params) => {
  return InspectItemJudgeValueSettingService.getSingleJudgementData(params)
}

export const getComplexJudgementData = (params) => {
  return InspectItemJudgeValueSettingService.getComplexJudgementData(params)
}

/* export const getComboboxList = (params) => {
  return InspectItemJudgeValueSettingService.getComboboxList(params)
} */

export const getJudgementLevelList = (params) => {
  return InspectItemJudgeValueSettingService.getJudgementLevelList(params)
}

export const getStartDateList = (params) => {
  return InspectItemJudgeValueSettingService.getStartDateList(params)
}

export const saveJudgementData = (params) => {
  return InspectItemJudgeValueSettingService.saveJudgementData(params)
}

export const deleteJudgementData = (params) => {
  return InspectItemJudgeValueSettingService.deleteJudgementData(params)
}

export const saveToleranceData = (params) => {
  return InspectItemJudgeValueSettingService.saveToleranceData(params)
}

export const deleteToleranceData = (params) => {
  return InspectItemJudgeValueSettingService.deleteToleranceData(params)
}

/* export const getAClassJudgeAction = (params) => {
  return InspectItemJudgeValueSettingService.getAClassJudgeAction(params)
} */

/* export const getNormalValueAction = (params) => {
  return InspectItemJudgeValueSettingService.getNormalValueAction(params)
} */

/* export const getToleranceAction = (params) => {
  return InspectItemJudgeValueSettingService.getToleranceAction(params)
} */

export const saveComplexJudgementData = (params) => {
  return InspectItemJudgeValueSettingService.saveComplexJudgementData(params)
}

export const deleteComplexJudgementData = (params) => {
  return InspectItemJudgeValueSettingService.deleteComplexJudgementData(params)
}

export const synchronizeJugement = (params) => {
  return InspectItemJudgeValueSettingService.synchronizeJugement(params)
}

export const getExamNameAction = (params) => {
  return InspectItemJudgeValueSettingService.getExamNameAction(params)
}

export const copyJudgementDataAction = (params) => {
  return InspectItemJudgeValueSettingService.copyJudgementDataAction(params)
}

export const deleteJudgementDataAction = (params) => {
  return InspectItemJudgeValueSettingService.deleteJudgementDataAction(params)
}



//deleteJudgementData

/* const InspectItemJudgeValueSettingAction = {
  
    GetScreenData() {
        return InspectItemJudgeValueSettingService.GetScreenData()
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                message.error(err.response.data.message);
                console.log(err.response.data.message);
            });
    }, 
    UpdateData(params) {
        return InspectItemJudgeValueSettingService.UpdateData(params)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                message.error(err.response.data.message);
                console.log(err.response.data.message);
            });
    }, 
    ExamList(params) {
        return InspectItemJudgeValueSettingService.ExamList(params)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                message.error(err.response.data.message);
                console.log(err.response.data.message);
            });
    }
     
} */
