import React from 'react'
import { connect } from 'react-redux'
import Color from 'constants/Color'
import PersonRequireExamIntroduceLetterAction from 'redux/IntroductionLetter/PersonRequireExamIntroduceLetter/PersonRequireExamIntroduceLetter.action.js'
import { Card, Table, Form } from 'antd'
import PropTypes from 'prop-types'
class WS0285001_JudgeQuery extends React.Component {

  // static propTypes = {
  //   Li_JudgeLevel: PropTypes.number,
  // }

  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '判定照会'

    this.state = {
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      loading: false,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getjudgeQuery()
  }

  /**
   * 判定一覧を取得
   */
  getjudgeQuery = () => {
    const params = {
      Li_JudgeLevel: this.props.Li_JudgeLevel,
    }
    PersonRequireExamIntroduceLetterAction.getjudgeQuery(params)
      .then(res => {
        this.setState({
          dataSource: res ? res : [],
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    return (
      <div className='judge-query'>
        <Card title='判定選択'>
          <Form
            ref={this.formRef}
          // onFinish={this.onFinish}
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => ({
                // 判定の選択処理
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    // 親画面に反映
                    this.props.onFinishScreen({
                      recordData: record
                    })
                  }
                }
              })}
            >
              <Table.Column
                title='判定'
                dataIndex='judgment_result'
                render={(value, record, index) => {
                  return (
                    <div
                      style={{
                        fontWeight: 'bold',
                        width: '100%',
                        textAlign: 'center',
                        color: Color(record.judgmentColorNum)?.Foreground
                      }}
                    >
                      {value}
                    </div>
                  )
                }}
              />
              <Table.Column
                title='内容'
                dataIndex='judgment_name'
              />
            </Table>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0285001_JudgeQuery)
