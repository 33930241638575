import React from "react";
import { connect } from "react-redux";

import { Card, Modal, Button, Input, Form, } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '120px' } }
}

class WS1545003_OutputPatternTableEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';

  }

  componentDidMount() {
    //console.log(this.props.record);
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
     * データ保存
     */
  onFinish = (values) => {
    const params = {
      id: this.props.record.id,
      number: values.document_management_number,
      title: values.document_title
    }
    this.props.onUpdate(params)
  }

  /**
 * データ削除
 * @param {*} id
 */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={'資料管理保守 [' + (this.props.newFlag ? '新規' : '変更') + ']'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='出力管理番号'
                name='document_management_number'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  type="number"
                  min="0"
                  className="input-size-number-5"
                />
              </Form.Item>

              <Form.Item
                label='出力パターン名称'
                name='document_title'//対応したものに書き換え
                {...labelCol}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newflag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1545003_OutputPatternTableEdit);
