import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined } from "@ant-design/icons";

import { Card, Table, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin, Select } from "antd";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import SetCsvCreateAction from "redux/basicInfo/SetInfoMaintain/SetCsvCreate.action";
import RequestCsvOutputAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/RequestCsvOutput.action";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";


import { SearchOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import Color from "constants/Color";

import moment from "moment";
import axios from "configs/axios";

const grid = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 20 },
};

const styleDiv = {
  display: 'inline-block',
  marginRight: '20px',
  padding: '4px 12px',
  border: '1px solid #000000',
  color: '#000000'
}
const styleFormItem = {
  display: 'inline-block',
  marginRight: '20px',
}


class WS2683001_TransmissionContentsCheckFixFile extends React.Component {

  formRef = React.createRef();

  static propTypes = {
    Li_ReserveNumPersonalSelect: PropTypes.any,
    Li_CaptureFileYyyymmddXx: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '送信内容確認・修正(ファイル)';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingForm: false,
      isLoadingTable: false,

      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],

      selected: "1",

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  handleChange = (value) => {
    this.setState({ selected: value });
  }

  clearSelected = () => {
    this.setState({ selected: null });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  show_sub(params) {
    if (params) {
      this.formRef.current.setFieldsValue({
        insurer_number: params.insurer_number ? params.insurer_number : '',
        visit_date_on: params.visit_date_on ? params.visit_date_on : '',
        birthday_on: params.birthday_on ? params.birthday_on : '',
        insurer_card_number: params.insurer_card_number ? params.insurer_card_number : '',
        personal_number_id: params.personal_number_id ? params.personal_number_id : '',
        insurer_card_symbol: params.insurer_card_symbol ? params.insurer_card_symbol : '',
        courseDivision: params.courseDivision ? params.courseDivision : '',
      })
    }
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {

    if (this.props !== prv) {
      this.formRef.current.resetFields();
      this.getScreenData();
    }
  }

  getScreenData = () => {

    this.setState({ isLoading: true });
    this.setState({ isLoadingTable: true });

    axios.get('/api/associate-insure-qualify-confirm/transmission-contents-check-fix-file/getscreenData', {
      params: {
        Li_ReserveNumPersonalSelect: this.props.Li_ReserveNumPersonalSelect,
        Li_CaptureFileYyyymmddXx: this.props.Li_CaptureFileYyyymmddXx
      },
    })
      .then(res => {
        this.setState({
          dataSource: res ? res.data : [],
          isLoadingTable: false
        })

        this.formRef.current?.setFieldsValue({
          create_file_name: this.props.Li_CaptureFileYyyymmddXx ? this.props.Li_CaptureFileYyyymmddXx : '',
          insurer_number: res ? res.data[0].insurer_number : '',
          visit_date_on: res ? res.data[0].visit_date_on : '',
          birthday_on: res ? res.data[0].birthday_on : '',
          insurer_card_number: res ? res.data[0].insurer_card_number : '',
          personal_number_id: res ? res.data[0].personal_number_id : '',
          insurer_card_symbol: res ? res.data[0].insurer_card_symbol : '',
          courseDivision: res ? res.data[0].courseDivision : '',
        })

        this.setState({
          selected: res ? res.data[0].medical_exam_type : "1"
        });

      })
      .catch(error => {

        this.setState({ isLoading: false });
        this.setState({ isLoadingTable: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  render() {
    return (
      <div className="transmission-contents-check-fix-file">
        <Card title="送信内容確認(ファイル)">

          <Form ref={this.formRef} onFinish={this.onFinish} >
            <Row style={{ marginLeft: 5 }} >
              <Col span={12}>
                <Form.Item name="create_file_name" label="取得ファイル名" >
                  <Input type="text" style={{ border: 1 }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row style={{ marginLeft: 5 }}>
                  <Col span={12}>
                    <Form.Item name="tip" label="出力内容" {...grid}>
                    </Form.Item>
                  </Col>
                </Row>

                <div style={{ ...styleDiv, borderColor: '#0000ff' }}>
                  <Row style={{ marginLeft: 5 }} gutter={20}>
                    <Col span={12}>
                      <Form.Item name="insurer_number" label="保険者番号" {...grid}>
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="visit_date_on" label="受&nbsp;&nbsp;診&nbsp;&nbsp;日" {...grid}>
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: 5 }} gutter={20}>
                    <Col span={12}>
                      <Form.Item name="insurer_card_symbol" label="保険証記号" {...grid}>
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="birthday_on" label="生年月日" {...grid}>
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: 5 }} gutter={20}>
                    <Col span={12}>
                      <Form.Item name="insurer_card_number" label="保険証番号" {...grid}>
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {/* <label style={{ fontSize: '14px', color: "#14468C", fontWeight: 'bold' }} >種&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;別</label>&nbsp;&nbsp; */}
                      <Form.Item name="courseDivision" label="種　別" {...grid}>
                        {/* <Select value={this.state.selected} onChange={this.handleChange} disabled>
                          <Select.Option value="1">一般</Select.Option>
                          <Select.Option value="2">付加</Select.Option>
                          <Select.Option value="3">子宮</Select.Option>
                        </Select> */}
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>

          <Table bordered
            size='small'
            style={{ cursor: 'pointer' }}
            // rowClassName={(record, index) => record.id === this.state.selectedRows?.id ? 'table-row-light' : ''}                 
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            // pagination={this.state.dataSource?.length > 10 ? true : false}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
            rowKey={(record) => record.id}
            scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    rowSelected: [record],
                    selectedRowKeys: [record.id],
                  });
                  this.show_sub(record);
                },
              };
            }}
          >

            <Table.Column width={80} title="個人番号(ID)" dataIndex="personal_number_id" align='center' key="" />
            <Table.Column title="" dataIndex="" key="" align='center'
              render={(text, record) => {
                let icon = null;
                icon = <MoreOutlined style={{ fontSize: 15 }} />;

                return (<Button
                  style={{ color: Color(record.Expression_11)?.Foreground }}

                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '70%',
                        component:
                          <WS2584019_PersonalInfoInquirySub
                            Li_PersonalNum={record.personal_number_id}
                            onFinishScreen={(output) => {
                              this.closeModal()
                            }}
                          />
                        ,
                      },
                    });
                  }}
                  icon={icon}
                />);
              }}
            />
            <Table.Column title="漢字氏名" dataIndex="kanji_name" align='' key="" />
            <Table.Column title="ｶﾅ氏名" dataIndex="kana_name" align='' key="" />
            <Table.Column title="事業所名" dataIndex="office_kanji_name" align='' key="" />
            <Table.Column title="コース名称" dataIndex="contract_short_name" align='center' key="" />
            <Table.Column title="" dataIndex="QualificationPresence" align='center' key="" />
            <Table.Column title="状態" dataIndex="State" align='center' key="" />
            <Table.Column title="乳" dataIndex="AddMedicalExamBreast" align='center' key="" />
            <Table.Column title="子" dataIndex="AddMedicalExamUterus" align='center' key="" />
            <Table.Column width={300} title="補足情報" dataIndex="supplement_info" align='center' key="" />
          </Table>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2683001_TransmissionContentsCheckFixFile);
