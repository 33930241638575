import React from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import 'assets/scss/_reserve_status_calendar.scss'
import PropTypes from 'prop-types'
import { Card, Form, Select, Radio, Button, Input, message, Tooltip, Table, Checkbox } from 'antd'
import { ReloadOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { getDataVariousAggregateAction } from 'redux/SystemMaintenance/NonConsultDateSetting/NonConsultDateSetting.actions'
import CalendarEmptySubAction from 'redux/ReservationBusiness/ReserveStatusSearch/CalendarEmptySub.action.js'
import ReserveAvailability from 'pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveAvailability'
import WS2577004_CalendarEmptySubDetail from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577004_CalendarEmptySubDetail.jsx'

const labelCol = {
  labelCol: { style: { width: 60 } },
}
class WS2577003_CalendarEmptySub extends React.Component {
  static propTypes = {
    // propsの型を調べる
    Lio_Date: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'カレンダー'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      Lio_Date: '',
      day: '',
      screenData: {},
      dataSource: [],
      reservationMemos: [],
      InspectSelect: [],
      detailDisplay: [],
    }

    this.renderDateNum = 0
  }

  /**
   * 各項目情報の取得
   */
  componentDidMount() {
    this.loadScreenData() // 右側の情報の取得
  }

  /**
   * 右側の項目情報の取得
   * コース集計分類・施設情報など
   */
  loadScreenData = () => {
    CalendarEmptySubAction.index()
      .then(res => {
        if (res) {
          this.setState({
            screenData: {
              ...this.state.screenData,
              FacilityTypeList: res.FacilityTypeList,
              CourseList: res.CourseList,
              CourseList_1: res.CourseList_1,
            },
          })

          this.formRef.current?.setFieldsValue({
            FacilityType: res.FacilityTypeList[0]?.facility_type,
          })

          const params = {
            day: moment(this.props.Lio_Date).format('DD'),
            Month: moment(this.props.Lio_Date).format('MM'),  //前画面で入力された、日にちの取得（月のみ）
            Year: moment(this.props.Lio_Date).format('YYYY'), //前画面で入力された、日にちの取得（年のみ）
          }
          this.setState({
            selectedDay: params.day,
            selectedMonth: params.Month,
            selectedYear: params.Year
          })

          //カレンダーの情報取得処理
          this.getDataVariousAggregate(params)
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('項目情報の取得 エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
  * カレンダー処理
  * カレンダー情報を取得
  * @param {*} params
  */
  getDataVariousAggregate = (params) => {
    getDataVariousAggregateAction(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res,
            changeMonthFlg: false
          })

          // 各日にちの詳しい検査項目データの取得
          this.searchConditions(this.formRef.current?.getFieldValue())
        }
      })
  }

  /**
   * 詳しい検査項目データの取得
   * 再表示が押された場合に動く処理
   * @param {*} values
   */
  searchConditions = (values) => {
    // 日付ごとに空き人数を取る
    let InspectSelect = ''
    let data
    let InspectListflag = '00000000000000000000'

    for (let i = 1; i <= 20; i++) {
      const v = this.formRef.current.getFieldValue('Inspect' + (('0' + i).slice(-2)))
      InspectSelect += v ? '1' : '0'
    }

    // 受入状況から飛んだ場合の処理（Lio_CourseCodeが送られているかどうか）
    if (!this.props.Lio_CourseCode) {
      // 初期表示じゃない場合（チェックが付いている）に動く
      if (this.state.detailDisplay.length > 0) {
        InspectListflag = ''
        for (let i = 1; i <= 20; i++) {
          data = this.state.detailDisplay.find(v => v.id === i)
          if (data) {
            if (data.checked) {
              InspectListflag = InspectListflag + '1'
            } else {
              InspectListflag = InspectListflag + '0'
            }
          } else {
            InspectListflag = InspectListflag + '0'
          }
        }
      }
    }

    const params = {
      ...values,
      Date: this.state.selectedYear + '/' + this.state.selectedMonth + '/01',
      FacilityType: this.formRef.current.getFieldValue('FacilityType'),
      TimeDivision: this.formRef.current.getFieldValue('TimeDivision'),
      date: this.props.Lio_Date ?? '',
      contractType: this.props.Lo_ContractType ?? '',
      contractOrgCode: this.props.Lo_ContractOrgCode ?? '',
      contractStartDate: this.props.Lo_ContractStartDate ?? '',
      ContractNum: this.props.Lo_ContractNum ?? '',
      ConsultCourse: this.props.Lio_CourseCode ?? '',
      dataSourceInspectChange: this.props.dataSourceInspectChange ?? '',
      InspectSelect,
      InspectList: InspectListflag
    }

    CalendarEmptySubAction.redisplay(params)
      .then(res => {
        if (res) {
          let resDate = this.state.selectedYear + '/' + this.state.selectedMonth + '/' + this.state.selectedDay
          // let detailDisplay = res.detailDisplay[resDate]
          let setDay = this.state.selectedYear + '-' + this.state.selectedMonth + '-' + this.state.selectedDay
          let setData = []

          // detailDisplay.sort(function (a, b) {
          //   if (a.sortFlag > b.sortFlag) {
          //     return -1
          //   } else {
          //     return 1
          //   }
          // })
          setData = res.detailDisplay[setDay]

          if (!this.props.Lio_CourseCode) {
            if (this.state.detailDisplay.length > 0) {
              let tempdetailDisplay = [...this.state.detailDisplay]
              tempdetailDisplay.sort(function (a, b) {
                if (a.checked > b.checked) {
                  return -1
                } else {
                  return 1
                }
              })
              setData = tempdetailDisplay
            }
          }

          this.setState({
            reservationMemos: res.result,
            detailDisplay: setData,
            dayDetailDisplay: res.detailDisplay,
            day: resDate
          })
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('再表示 エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * チェックボックスが変更された場合に動く
   * @param {} e
   */
  changeCheckbox = (record, e) => {
    let list = [...this.state.detailDisplay]
    let data

    for (let i = 0; i < list.length; i++) {
      if (list[i].id == record.id) {
        data = { ...list[i] }
        data.checked = e.target.checked
        list[i] = data
        break
      }
    }

    this.setState({ detailDisplay: list })
  }

  /**
  * 予約空き人数を表示する表の処理
  * 日付が切り替わった時の処理
  * @param {*} params
  */
  detailInspect = (params) => {
    let setData = []
    let dayFomat = moment(params).format('YYYY/MM/DD')
    let formatDate = moment(params).format('YYYY-MM-DD')
    let dayDetailDisplay = this.state.dayDetailDisplay[formatDate]

    dayDetailDisplay.sort(function (a, b) {
      if (a.sortFlag > b.sortFlag) {
        return -1
      } else {
        return 1
      }
    })
    setData = dayDetailDisplay

    if (!this.props.Lio_CourseCode) {
      if (this.state.detailDisplay.length > 0) {
        let tempdetailDisplay = [...dayDetailDisplay]
        tempdetailDisplay.sort(function (a, b) {
          if (a.checked > b.checked) {
            return -1
          } else {
            return 1
          }
        })
        setData = tempdetailDisplay
      }
    }

    this.setState({
      detailDisplay: setData,
      day: dayFomat
    })
  }

  /**
   * 何かが、更新された場合に稼働
   * @param {*} prevState
   */
  componentDidUpdate(prevState) {
    //休診日の時に、背景色を付ける
    if (this.state.dataSource !== prevState.dataSource) {
      this.getTileClassIsKyusin = (event) => {
        const className = this.getTileClassIsKyusinSub(event)
        return className
      }
    }

    //「◎、×」判定・空き状況
    if (this.state.reservationMemos !== prevState.reservationMemos) {
      this.getTileContent = (event) => {
        const component = this.getTileContentSub(event)
        return component
      }
    }
  }

  /**
   *「◎、×」判定・空き状況
   * ツールチップの表示内容
   * @param {*} param0
   * @returns
   */
  getTileContent = (event) => {
    const Component = this.getTileContentSub(event.date)
    return Component
  }

  /**
   * 「◎、×」判定・空き状況Sub
   * @param {*} date
   * @returns
   */
  getTileContentSub(event) {
    if (event.view !== 'month') {
      return null
    }

    const targetDate = moment(event.date).format('YYYYMMDD')
    let status = ''
    let People = ''
    let memo = ''

    this.state.reservationMemos.map((value, index) => {
      if (value.day === targetDate) {
        status = value.Availability
        People = value.People
        memo = value.Memo
      }
    })

    return (
      <Tooltip
        overlayStyle={{ whiteSpace: 'pre-line' }}
        title={memo}>
        <div
          onDoubleClick={() => { this.returnData(moment(event.date).format('YYYY/MM/DD')) }}
          style={{ height: 50, display: 'flex', flexDirection: 'column', gap: 10, paddingTop: 5 }}
        >
          <div >
            {status}
          </div>
          <div style={{ fontSize: 13, fontWeight: 'normal' }}>
            {People}
          </div>
        </div>
      </Tooltip>
    )
  }

  returnData(data) {
    this.props.onFinishScreen(data)
    this.closeModal()
  }

  /**
  * カレンダー処理
  * @param {*} event
  * @returns
  */
  getTileClassIsKyusin = (event) => {
    const className = this.getTileClassIsKyusinSub(event)
    return className
  }

  /**
   * カレンダー処理 「休診日の時に、背景色を付ける」
   * @param {*} event
   * @returns
   */
  getTileClassIsKyusinSub(event) {
    if (event.view !== 'month') {
      return null
    }
    // フォーマット変更
    const targetDay = moment(event.date).format('YYYY-MM-DD')
    let kyusinkubun = false
    let dateList = this.state.dataSource

    // 祝日判定
    for (let i = 0; i < dateList.length; i++) {
      if (targetDay === dateList[i].date_on) {
        kyusinkubun = false
        if (dateList[i].kyusinkbn === 1) {
          kyusinkubun = true
        }
      }
    }
    return (kyusinkubun) ? 'react-calendar-kyusin' : 'react-calendar-kaijyo'
  }

  /**
   * カレンダー処理
   * @param {} activeStartDate
   * @param {*} view
   */
  moveMonthPage = (activeStartDate, view) => {
    //先月・次月に遷移
    this.reloadDate(activeStartDate, view)
  }

  /**
   * カレンダー処理
   * 次の月に移動した時のデータリロード
   * @param {*} activeStartDate
   * @param {*} view
   */
  reloadDate = (activeStartDate, view) => {
    const params = {
      Month: moment(activeStartDate).format('MM'),
      Year: moment(activeStartDate).format('YYYY'),
    }

    this.setState({
      selectedMonth: params.Month,
      selectedYear: params.Year
    })

    this.getDataVariousAggregate(params)
  }


  /**
   * 時間帯設定モーダル
   */
  showReserveAvailability() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ReserveAvailability
            Li_Date={this.state.day}
            Li_SelectedExamIdTimeList={this.props.Li_SelectedExamIdTimeList}
            Li_SetCodeList={this.props.Li_SetCodeList}
            dataScreen={this.props.dataScreen}
            onFinishScreen={(obj) => {
              // // 受診日・時間帯設定
              // this.formRef.current.setFieldsValue({
              //   DateChar: obj.dateChar,
              //   TimeZone: obj.timeZone
              // })
              // // 検査ごとの予約時間
              // this.setState({
              //   selectedExamIdTimeList: obj.selectedRows,
              //   Am_Pm: obj.timeZone.substr(0, 2) < 12 ? 'AM' : 'PM'
              // })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 予約空き状況（詳細）モーダル
   */
  showWS2577004_CalendarEmptySubDetail(record) {

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2577004_CalendarEmptySubDetail
            calenderId={record.id}
            Li_Name={record.name}
            Li_Date={this.state.day}
            Li_SetCodeList={this.props.Li_SetCodeList ?? []}
            dataScreen={this.props.dataScreen ?? null}
            onFinishScreen={(obj) => {
              this.closeModal()
            }}
          />
        ),
      },
    })

  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='calendar-empty-sub' >
        <Card title={
          <div style={{ display: 'flex' }}>
            {'予約状況照会' + '　'}
            <div style={{
              color: 'red',
              fontWeight: 'normal',
              display: (this.props.Lio_CourseCode) ? 'none' : ''
            }}>
              {'※コースが選択されていない為、空き状況が取得できません'}
            </div>
          </div>
        }>
          <div className='box_inner_horizontal'>
            <div style={{ width: '65%' }}>
              <Calendar
                className='reserve'
                theme='ios'
                themeVariant='light'
                locale={'ja-JP'}
                calendarType={'US'}
                controls={['calendar']}
                touchUi={true}
                display='inline'
                defaultActiveStartDate={new Date(moment(this.props.Lio_Date)?.format('YYYY') + '-' + moment(this.props.Lio_Date)?.format('MM') + '-01')}
                prev2Label={null}
                next2Label={null}
                tileClassName={this.getTileClassIsKyusin}
                tileContent={this.getTileContent}
                formatDay={(locale, date) => moment(date).format('D')}

                //先月翌月に遷移する「→ボタン」が押された場合
                onActiveStartDateChange={({ action, activeStartDate, view }) => {
                  if (action == 'prev' || action == 'next' || (action == 'drillDown' && view == 'month') || (action == 'drillUp' && view == 'year')) {
                    this.moveMonthPage(activeStartDate, view)
                  }
                }}

                // 日付が押された場合
                onClickDay={(value, event) => {
                  this.detailInspect(value)
                }}
              />
            </div>

            <div className='vertical_border' />

            <Form
              autoComplete='off'
              ref={this.formRef}
              initialValues={{
                TimeDivision: '',
                CourseSelect: '',
                PeopleNum: 1
              }}
            >
              <Form.Item hidden name='Date'>
                <Input />
              </Form.Item>

              <Form.Item
                name='CourseSelect'
                label='コース集計分類'
              >
                <Select style={{ width: '50%' }}>
                  {this.state.screenData?.CourseList?.map((value, index) => (
                    <Select.Option
                      value={value}
                      key={`cl-${index}`}
                    >
                      {this.state.screenData?.CourseList_1[index]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div style={{ marginBottom: 5 }}>
                <div
                  className='table_header_filter'
                >
                  {this.state.day}
                </div>
                <Table
                  bordered
                  size='small'
                  rowKey={record => record.id}
                  pagination={false}
                  scroll={{ y: 300 }}
                  dataSource={this.state.detailDisplay}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        if ((record.calenderIdFlag ?? false)) {
                          // 電子カルテと連携している検査ならば詳細な空き状況を表示
                          this.showWS2577004_CalendarEmptySubDetail(record)
                        }
                      }
                    }
                  }}
                >
                  {this.props.Lio_CourseCode ?
                    null
                    // <Table.Column
                    //   align='center'
                    //   width={40}
                    //   dataIndex='checked'
                    //   render={checked => (checked ? <CheckOutlined /> : '')}
                    // />
                    :
                    <Table.Column
                      align='center'
                      width={40}
                      dataIndex='checked'
                      render={(value, record, index) =>
                        <Checkbox
                          onChange={(e) => this.changeCheckbox(record, e)}
                        />
                      }
                    />
                  }

                  <Table.Column
                    title='名称'
                    dataIndex='name'
                    render={(value, record) =>
                      <Tooltip title={(record.calenderIdFlag) ? '電子カルテ連携あり' : ''}>
                        <div style={{ cursor: (record.calenderIdFlag) ? 'pointer' : 'auto' }}>
                          {value}
                        </div>
                      </Tooltip>
                    }
                  />

                  <Table.Column
                    title='残り枠数'
                    dataIndex='empty'
                    render={(value, record) =>
                      <Tooltip title={(record.calenderIdFlag) ? '電子カルテ連携あり' : ''}>
                        <div style={{ textAlign: 'right', cursor: (record.calenderIdFlag) ? 'pointer' : 'auto' }}>
                          {value}
                        </div>
                      </Tooltip>
                    }
                  />
                </Table>
              </div>

              <Form.Item
                name='TimeDivision'
                label='時間区分'
                {...labelCol}
              >
                <Radio.Group>
                  <Radio value=''>全て</Radio>
                  <Radio value='AM'>AM</Radio>
                  <Radio value='PM'>PM</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name='FacilityType'
                label='施設'
                {...labelCol}
              >
                <Select style={{ width: '70%' }}>
                  {this.state.screenData?.FacilityTypeList?.map((value, index) => (
                    <Select.Option
                      value={value.facility_type}
                      key={`ftl-${index}`}
                    >
                      {value.facility_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label='人数'
                name='PeopleNum'
                {...labelCol}
              >
                <Input
                  type='number'
                  style={{ width: 100, textAlign: 'right' }}
                />
              </Form.Item>

              <div className='box_button_bottom_right' >
                <Button
                  type='primary'
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    this.searchConditions(this.formRef.current?.getFieldValue())
                  }}
                >
                  <span className='btn_label'>
                    再表示
                  </span>
                </Button>
              </div>

            </Form>
          </div>
        </Card >

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

export default WS2577003_CalendarEmptySub
