import InspectItem1ChangeSubService from 'services/InspectionMaintenance/InspectItemInfoMaintain/InspectItem1ChangeSubService';
import { message } from "antd";

export const loadInspectItemData = (InspectCode) => {
  return InspectItem1ChangeSubService.loadInspectItemDataService(InspectCode)
}

export const saveInspectItemAction = (params) => {

  return InspectItem1ChangeSubService.saveInspectItemActionService(params)
}