import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Dropdown, Button, Menu, message } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import InspectGroupDetailEdit from 'pages/MS_InspectionMaintenance/InspectGroupMaintain/InspectGroupDetailEdit.jsx'
import InspectGroupMaintainAction from 'redux/InspectionMaintenance/InspectGroupMaintain/InspectGroupMaintain.actions.js'


class InspectGroupDetail extends React.Component {

  constructor(props) {
    super(props)

    // document.title = '検査グループマスタ保守(詳細)'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        centered: false,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getInspectGroupDetail()
  }

  /**
   * データ取得
   */
  getInspectGroupDetail() {
    const params = {
      inspect_group_code: this.props.record.inspect_group_code
    }
    InspectGroupMaintainAction.getInspectGroupDetail(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }


  /**
   * 新規・編集モーダル
   * @param {} record
   */
  showInspectGroupDetailEdit(record = null) {
    let nextSeq = 0
    if (!record) {
      // 新規の時だけ連番を設定
      this.state.dataSource.forEach(item => {
        if (nextSeq < item.seq) {
          nextSeq = item.seq
        }
      })
      // 次の連番を取得
      nextSeq = nextSeq + 1
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        component:
          <InspectGroupDetailEdit
            record={record}
            seq={nextSeq}
            inspectGroupCode={this.props.record?.inspect_group_code ?? ''}
            inspectGroupName={this.props.record?.inspect_group_name ?? ''}
            onFinishScreen={() => {
              this.getInspectGroupDetail()
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='electronic-medical-records-linkage-frame-set'>
        <Card title={`検査グループマスタ保守 詳細 [${this.props.record?.inspect_group_code ?? ''}：${this.props.record?.inspect_group_name ?? ''}]`}>
          <Table
            bordered
            dataSource={this.state.dataSource}
            style={{ cursor: 'pointer' }}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.dataSource : false}
            scroll={{ x: 'max-content', y: 500 }}
            rowKey={(record) => record.id}
            size='small'
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  this.showInspectGroupDetailEdit(record)
                }
              }
            }}
          >
            <Table.Column
              title='連番'
              dataIndex='seq'
              className='column-size-5'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>{value}</div>
              }
            />
            <Table.Column
              title='部位1コード'
              dataIndex='inspect_code_site_1'
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>{(value == 0) ? '' : value}</div>
              }
            />
            <Table.Column
              title='部位1名称'
              dataIndex='inspect_code_site_1_name'
            />

            <Table.Column
              title='部位2コード'
              dataIndex='inspect_code_site_2'
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>{(value == 0) ? '' : value}</div>
              }
            />
            <Table.Column
              title='部位2名称'
              dataIndex='inspect_code_site_2_name'
            />

            <Table.Column
              title='所見1コード'
              dataIndex='inspect_code_findings_1'
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>{(value == 0) ? '' : value}</div>
              }
            />
            <Table.Column
              title='所見1名称'
              dataIndex='inspect_code_findings_1_name'
            />

            <Table.Column
              title='所見2コード'
              dataIndex='inspect_code_findings_2'
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>{(value == 0) ? '' : value}</div>
              }
            />
            <Table.Column
              title='所見2名称'
              dataIndex='inspect_code_findings_2_name'
            />

            <Table.Column
              title='接尾語コード'
              dataIndex='inspect_code_findings_3'
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>{(value == 0) ? '' : value}</div>
              }
            />
            <Table.Column
              title='接尾語名称'
              dataIndex='inspect_code_findings_3_name'
            />
            <Table.Column
              width={40}
              title={
                //新規登録のボタン
                <Button
                  size='small'
                  type='primary'
                  style={{ textAlign: 'center' }}
                  icon={<PlusOutlined />}
                  onClick={() => { this.showInspectGroupDetailEdit() }}
                >
                </Button>
              }
              render={(text, record) => {
                return (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='編集'
                          onClick={() => { this.showInspectGroupDetailEdit(record) }}
                        >編集
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                    ></Button>
                  </Dropdown>
                )
              }}
            />
          </Table>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectGroupDetail)
