import { message } from 'antd'
import MedicalCheckupCalendarService from 'services/Others/MedicalCheckupCalendar/MedicalCheckupCalendarService.js'

const MedicalCheckupCalendarAction = {
  getScreenData() {
    return MedicalCheckupCalendarService.getScreenDataService()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  recreateDisplay(data) {
    return MedicalCheckupCalendarService.recreateDisplay(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  leftTableRecreateDisplay(data) {
    return MedicalCheckupCalendarService.leftTableRecreateDisplay(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getCalendarPresentationHistory(data) {
    return MedicalCheckupCalendarService.getCalendarPresentationHistory(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  confirmReserve(data) {
    return MedicalCheckupCalendarService.confirmReserve(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default MedicalCheckupCalendarAction
