import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-init-input/content-input",
  save: "/api/insure-guide-init-input/content-input/save",
  delete: "/api/insure-guide-init-input/content-input/delete",
};

const ContentInputService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async save(params) {
    return axios.post(API_LIST.save, params);
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },
};

export default ContentInputService;
