/* eslint-disable no-useless-concat */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Card, Form, Input, Radio, Button, Checkbox, Select, Table, Modal, message, Tooltip, InputNumber } from 'antd';
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';

import WS2533001_ConfirmSub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2533001_ConfirmSub.jsx';
import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx';
import GroupBookingsAction from 'redux/ReservationBusiness/GroupBookings/GroupBookings.action';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery';
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx';
import WS2787004_AffiliationSelectSub from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS2787004_AffiliationSelectSub.jsx';
import WS2532008_InsuranceCardInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532008_InsuranceCardInquiry.jsx';
import WS2577003_CalendarEmptySub from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577003_CalendarEmptySub.jsx';
import WS2553003_PeriodTimeInquiry from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2553003_PeriodTimeInquiry.jsx';
import WS2532012_DateOfBirthInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532012_DateOfBirthInquiry.jsx';
import WS2532500_GroupBookingsAdd from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532500_GroupBookingsAdd.jsx';
import ModalDraggable from 'components/Commons/ModalDraggable';
import ContractInfoInquiryAction from 'redux/ReservationBusiness/GroupBookings/ContractInfoInquiry.actions'
import { ReturnIcon } from 'components/Commons/ReturnIcon';
import resizableTableScroll from 'components/Commons/ResizableTableScroll';


const { Option } = Select;
const styleDivTitle = {
  background: '#1C66B9',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em',
  color: '#FFFFFF'
};
const styleDivResult = {
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em'
}
const styleCard = {
  marginBottom: '0.4em'
}
const styleNumberFormat = {
  border: '1px solid #d9d9d9',
  outline: '#d9d9d9',
  width: '100%',
  height: '24px',
  borderRadius: '2px',
  padding: '0 7px',
}
class WS2532001_GroupBookings extends React.Component {
  static propTypes = {
    Li_ReserveDate: PropTypes.any,
    Li_Way: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef();
  inputRefReserveDate = React.createRef();
  inputTimezone = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '団体予約';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      RelationshipList: [],
      FacilityNumList: [],
      listSelectedId: [],
      selectedRow: [],
      initObj: {},
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      importance: 0,
      initParams: {
        OfficeCode: '',
        BranchStoreCode: '',
        SortOrder: 0,
        TmpplaceCode: '',
        Relationship: '',
        Gender: '0',
        StsPreviousYearExtract: 0,
        StsThisYearNotYetVisited: 0,
        InsuranceCardNum: '',
        DateOfBirthChar: '', // 
        DateChar: '', //
        TimeZone: '',
        FacilityNum: 1,
        Course: '',
      },
    };
  }

  componentDidMount = () => {
    let params = {
      Li_ReserveDate: this.props.Li_ReserveDate ?? 0
    }
    this.loadScreenData(params);
  }

  loadScreenData = (params) => {
    GroupBookingsAction.getScreenDataAction(params)
      .then(res => {
        if (res) {
          let data = res.data;
          this.setState({
            RelationshipList: data.Relationship,
            FacilityNumList: data.FacilityNum,
            initParams: {
              ...this.state.initParams,
              DateChar: data.DateChar,
              TimeZone: data.TimeZone,
            },
            initObj: data,
          });
          this.formRef?.current?.setFieldsValue({ DateChar: data.DateChar, TimeZone: data.TimeZone, })
          // this.Clear()
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  onBlur = () => {
    const { OfficeCode, BranchStoreCode, TmpplaceCode } = this.state.initParams;
    this.loadScreenData({
      OfficeCode,
      BranchStoreCode,
      TmpplaceCode,
    })
  }

  subExtract = () => {
    // this.setState({ isLoading: true })
    GroupBookingsAction.subExtractAction()
      .then(res => {
        let data = res?.data;
        if (data && data.length > 0) {
          let arrTempID = [];
          let newSelectedData = [];
          data = data.map(item => {
            if (item.W1_target_flg) {
              arrTempID.push(item.id)
              newSelectedData.push(item)
            }
            return {
              ...item,
              W1_time_zone: item.W1_time_zone ? moment(item.W1_time_zone, 'HH:mm').format('HH:mm') : ''
            }
          })
          this.setState({
            dataSource: data,
            rowSelect: this.state.rowSelect.id ? this.state.rowSelect : data[0],
            listSelectedId: arrTempID,
            selectedRow: newSelectedData
          });
          this.formRef?.current?.setFieldsValue({ rowSelect: this.state.rowSelect.id ? this.state.rowSelect : data[0] })
        } else {
          this.setState({
            dataSource: data,
            rowSelect: {},
            listSelectedId: [],
            selectedRow: []
          });
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  subExtract2 = (Li_Way, confimExtract, initParams, initObj) => {
    // this.setState({ isLoading: true })
    let values = {
      ...initParams,
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      AgeF: this.state.initObj.AgeF,
      AgeT: this.state.initObj.AgeT,
      conditions_age_division: this.state.initObj.conditions_age_division,
      State: this.state.initObj.State,
      ContractType: initObj.ContractType,
      ContractOrgCode: initObj.ContractOrgCode,
      ContractDate: initObj.ContractDate,
      ContractNum: initObj.ContractNum,
      conditions_in_out_hospital: this.state.initObj.conditions_in_out_hospital,
      Date: initParams.DateChar,
      BirthDateDate: moment(this.state.initParams.DateOfBirthChar, 'NNy/MM/DD', true).isValid()
        || moment(this.state.initParams.DateOfBirthChar, 'YYYY/MM/DD', true).isValid()
        ? moment(this.state.initParams.DateOfBirthChar, 'NNy/MM/DD').format('YYYY/MM/DD')
        : '',
      Li_Way: Li_Way,
      AllSelect: this.state.initObj.AllSelect ? 1 : 0,
      Confirm: confimExtract ? 6 : 0, // user click yes
      flg_no: confimExtract ? 0 : 1,
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      list_id: this.state.listSelectedId ?? []
    }
    GroupBookingsAction.subExtractAction(values)
      .then(res => {
        let data = res?.data;
        if (data && data.length > 0) {
          let arrTempID = [];
          data = data.map(item => {
            if (item.W1_target_flg) arrTempID.push(item.id)
            return {
              ...item,
              W1_time_zone: item.W1_time_zone ? moment(item.W1_time_zone, 'HH:mm').format('HH:mm') : ''
            }
          })
          this.setState({
            dataSource: data,
            rowSelect: this.state.rowSelect.id ? this.state.rowSelect : data[0],
            listSelectedId: arrTempID,
          });
          this.formRef?.current?.setFieldsValue({ rowSelect: this.state.rowSelect.id ? this.state.rowSelect : data[0] })
        } else {
          this.setState({
            dataSource: data,
            rowSelect: {},
            listSelectedId: [],
          });
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  Extract(Li_Way, confimExtract, initParams, initObj) {
    let values = {
      ...initParams,
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      AgeF: this.state.initObj.AgeF,
      AgeT: this.state.initObj.AgeT,
      conditions_age_division: this.state.initObj.conditions_age_division,
      State: this.state.initObj.State,
      ContractType: initObj.ContractType,
      ContractOrgCode: initObj.ContractOrgCode,
      ContractDate: initObj.ContractDate,
      ContractNum: initObj.ContractNum,
      conditions_in_out_hospital: this.state.initObj.conditions_in_out_hospital,
      Date: initParams.DateChar,
      BirthDateDate: moment(this.state.initParams.DateOfBirthChar, 'NNy/MM/DD', true).isValid()
        || moment(this.state.initParams.DateOfBirthChar, 'YYYY/MM/DD', true).isValid()
        ? moment(this.state.initParams.DateOfBirthChar, 'NNy/MM/DD').format('YYYY/MM/DD')
        : '',
      Li_Way: Li_Way,
      AllSelect: this.state.initObj.AllSelect ? 1 : 0,
      Confirm: confimExtract ? 6 : 0, // user click yes
      flg_no: confimExtract ? 0 : 1,
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      list_id: this.state.listSelectedId ?? []
    }
    this.setState({ isLoading: true })
    GroupBookingsAction.eventExtractAction(values)
      .then(res => this.subExtract())
      .catch(err => {
        message.error(err.response.data.message || 'エラーが発生しました');
        this.setState({ isLoading: false })
      })
  }

  Clear() {
    this.setState({ isLoading: true })
    GroupBookingsAction.clearAction({ Confirm: 6 })
      .then(res => {
        this.setState({
          dataSource: [],
          rowSelect: {},
          isLoading: false
        })
        this.formRef?.current?.setFieldsValue({
          dataSource: [],
          rowSelect: {
            ReserveDate: '',
            W1_time_zone: '',
            W1_n_follow_sect: '',
            W1_facilities_num: '',
            W1_visits_courses: '',
            W1_remark: '',
          },
        })
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))

  }

  SelectAll = (checkAll) => {
    GroupBookingsAction.selectCheckboxAction({ AllSelect: checkAll })
      .then(() => {
        this.setState({ isLoading: true })
        this.subExtract()
      })
      .catch()
  }

  SelectOneLine = (rowData, StsChangeFromFalseToTrue) => {
    GroupBookingsAction.selectOneLineCheckboxAction(rowData)
      .then(() => {
        this.setState({ isLoading: true })
        this.subExtract()
        // if (StsChangeFromFalseToTrue)
        //   this.eventF12({
        //     list_id: this.state.listSelectedId,
        //     StsChangeFromFalseToTrue: StsChangeFromFalseToTrue ? 1 : 0
        //   })
      })
      .catch()
  }

  eventF12 = (params) => {
    this.setState({ isLoading: true })

    GroupBookingsAction.eventF12Action(params)
      .then((res) => {
        if (res.data.Confirm === 6) {
          if (this.state.listSelectedId.length > 0) {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: '60%',
                component: (
                  <WS2533001_ConfirmSub
                    Lo_ReturnBookReview={res.data.Confirm}
                    Li_Title={'予約処理を行います。'}
                    list_id={res.data.list_id}
                    selectedRow={this.state.selectedRow}
                    StsChangeFromFalseToTrue={res.data.StsChangeFromFalseToTrue}
                    onFinishScreen={({ Lo_ReturnBookReview }) => {
                      this.closeModal();
                      if (Lo_ReturnBookReview === 6) {
                        let params = {
                          StsBatchInspectChangeSetting: this.state.initObj.StsBatchInspectChangeSetting,
                          Confirm: res.data.Confirm,
                          StsWakutoConvert: this.state.initObj.StsWakutoConvert
                        }
                        this.confirmCallScreen(params)
                      }
                    }}
                  />
                ),
              },
              initObj: {
                ...this.state.initObj,
                StsWakutoConvert: res.data.StsWakutoConvert
              }
            });
          }
        }
        this.subExtract()
      })
      .catch(error => {
        const res = error.response;
        console.log(res)
        if (res.data.errorReturn == 'E') {
          if (Array.isArray(res?.data?.errorContent)) {
            Modal.error({
              content: <div>
                {res?.data?.errorContent.map((value, index) => (
                  <div key={index} >{value}</div>
                ))}
              </div>,
              width: 400,
              onOk: () => {
                this.subExtract();
              }
            })
          } else {
            this.ModalError(res.data.errorContent)
          }
        } else if (res.data.errorReturn == 'W') {
          this.ModalWarning();
        } else {
          message.error('エラーが発生しました');
          return;
        }

        // if (!res || !res.data || !res.data.message) {
        //   message.error('エラーが発生しました');
        //   return;
        // }
        // this.ModalWarning();
      })
  }

  confirmCallScreen = (params) => {
    GroupBookingsAction.confirmCallScreenAction(params)
      .then(res => {
        // let data = res.data;
        // if (data) {
        //   this.setState({
        //     childModal: {
        //       ...this.state.childModal,
        //       visible: true,
        //       width: '80%',
        //       component: (
        //         <WS2556001_ReservesBulkChanges
        //           Li_MenuOption={''}
        //           Li_ExtractDateF={data.IJ_W1_consult_date}
        //           LnkinExtractDateT={data.IN_W1_consult_date}
        //           Li_ExtractOffice={data.IH_W1_office_cd}
        //           Li_ExtractBranchStore={data.II_W1_branch_store_cd}
        //           onFinishScreen={() => {
        //             this.closeModal();
        //           }}
        //         />
        //       ),
        //     },
        //   });
        // }
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
        this.closeModal();
      })
      .catch()
  }

  updateData = (record) => {
    let params = {
      id: record.id,
      W1_target_flg: record.W1_target_flg,
      ReserveDate: record.ReserveDate,
      W1_time_zone: record.W1_time_zone,
      W1_n_follow_sect: record.W1_n_follow_sect,
      W1_facilities_num: record.W1_facilities_num,
      W1_visits_courses: record.W1_visits_courses,
      W1_remark: record.W1_remark,
      W1_contract_type: record.W1_contract_type,
      W1_contract_org_cd: record.W1_contract_org_cd,
      W1_contract_date: record.W1_contract_date,
      W1_contract_num: record.W1_contract_num,
    }
    GroupBookingsAction.updateDataAction(params)
      .then()
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  deleteData = (record, index) => {
    let arrTemp = [...this.state.dataSource];
    if (record.id) {
      GroupBookingsAction.deleteDataAction({ id: record.id })
        .then(res => {
          // message.success('成功');
          arrTemp.splice(index, 1);
          this.formRef.current.setFieldsValue({
            dataSource: arrTemp,
            rowSelect: arrTemp.length > 0 ? arrTemp[0] : {}
          });
          this.setState({
            dataSource: arrTemp,
            rowSelect: arrTemp.length > 0 ? arrTemp[0] : {}
          });
        })
        .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
    }
  }

  ModalError = (content, refName) => {
    Modal.error({
      content: content || '事業所が無効です',
      okText: 'OK',
      onOk: () => {
        if (typeof refName == 'string') {
          this[refName]?.current?.focus()
        } else if (typeof refName == 'function') {
          refName();
        }
        this.subExtract();
      }
    })
  }
  ModalWarning() {
    if (this.formRef?.current?.getFieldValue('OfficeCode')) {
      Modal.confirm({
        content: 'エラーの方がいます。このまま予約を行いますか?',
        icon: <WarningOutlined style={{ color: '#faad14' }} />,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          this.eventF12({ list_id: this.state.listSelectedId, StsChangeFromFalseToTrue: 1 });
        },
        onCancel: () => {
          this.subExtract();
        }
      });
    }
  }

  ModalConfirm = (select, Li_Way) => {
    if (this.formRef?.current?.getFieldValue('OfficeCode')) {
      Modal.confirm({
        content: '設定されている予約内容をクリアしますか？',
        icon: <QuestionCircleOutlined style={{ color: '#006FCA' }} />,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          select === 'Extract'
            ? this.Extract(Li_Way, true)
            : this.Clear()
        },
        onCancel: () => {
          if (select === 'Extract') {
            this.Extract(Li_Way, false);
          }
        }
      });
    } else {
      this.ModalError();
    }
  }

  setStateInitParams = (value, name) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        [name]: value
      }
    });
    if (name === 'OfficeCode' || name === 'BranchStoreCode') this.setState({ importance: 0 })
    this.formRef.current.setFieldsValue({ [name]: value });
  }

  handleChangeRecord = (value, name) => {
    let arrTemp = [...this.state.dataSource];
    let obj = { ...this.state.rowSelect }
    let index = arrTemp.findIndex(item => item.id === obj.id);
    if (index !== -1) {
      obj = {
        ...obj,
        [name]: value
      }
      if (name === 'W1_visits_courses') {
        obj = {
          ...obj,
          Expresstion_45: value,
          Expresstion_46: value ? obj.contract_short_name : '',
          contract_short_name: value ? obj.contract_short_name : '',
          W1_contract_date: value ? obj.W1_contract_date : '0000-00-00',
          W1_contract_num: value ? obj.W1_contract_num : 0,
          W1_contract_org_cd: value ? obj.W1_contract_org_cd : '',
          W1_contract_type: value ? obj.W1_contract_type : 0,
        }
      }
      if (name === 'ReserveDate') {
        obj = {
          ...obj,
          Expresstion_42: value
        }
      }
      arrTemp[index] = obj;
      this.setState({
        dataSource: arrTemp,
        rowSelect: obj
      });
      this.formRef.current.setFieldsValue({
        dataSource: arrTemp,
        rowSelect: obj
      });
    }
    // if (moment(obj.ReserveDate, 'YYYY/MM/DD', true).isValid()) {
    //   // this.updateData(obj);
    // }
  }

  ReturnComponent = (component) => {
    let components = {
      WS0247001: WS0247001_OfficeInfoRetrievalQuery,
      WS2585001: WS2585001_OfficeInfoInquirySub,
      WS2787004: WS2787004_AffiliationSelectSub,
      WS2532008: WS2532008_InsuranceCardInquiry,
      WS2577003: WS2577003_CalendarEmptySub,
      WS2553003: WS2553003_PeriodTimeInquiry,
      WS2532012: WS2532012_DateOfBirthInquiry,
    };
    return components[component];
  }

  callbackOpenModal = (screen, props, nameForm) => {
    let Component = this.ReturnComponent(screen);

    switch (screen) {
      case 'WS2532012':
      case 'WS2532008':
      case 'WS2787004':
      case 'WS2585001':
      case 'WS0247001': {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: screen === 'WS2787004' ? '60%' : '80%',
            component: (
              <Component
                // WS0247001 (props)
                Li_NewlyRegisteredPresence={''}
                Lio_OfficeCode={this.state.initParams.OfficeCode}
                Lio_BranchStoreCode={this.state.initParams.BranchStoreCode}
                Li_1HeadOffice2BranchShop={1}
                // WS2585001, WS2787004, WS2532008, WS2532012
                Li_OfficeCode={this.state.initParams.OfficeCode}
                Li_BranchStoreCode={this.state.initParams.BranchStoreCode}
                Lio_AffiliationCode={this.state.initParams.TmpplaceCode} // WS2787004
                Li_Relationship={this.state.initParams.Relationship} // WS2532008, WS2532012

                onFinishScreen={({
                  Lio_OfficeCode,
                  Lio_BranchStoreCode,
                  Lo_Kanji_Name,
                  Lio_AffiliationCode,
                  Lo_InsuranceCardNum,
                  Lo_DateOfBirth,
                  recordData
                }) => {
                  if (screen === 'WS0247001') {
                    this.formRef.current.setFieldsValue({
                      OfficeCode: Lio_OfficeCode,
                      BranchStoreCode: Lio_BranchStoreCode === 0 ? '' : Lio_BranchStoreCode,
                      office_kanji_name: Lo_Kanji_Name
                    });
                    this.setState({
                      initParams: {
                        ...this.state.initParams,
                        BranchStoreCode: Lio_BranchStoreCode,
                        OfficeCode: Lio_OfficeCode,
                      },
                      importance: recordData.importance
                    });
                  }
                  if (screen === 'WS2787004') {
                    this.setStateInitParams(Lio_AffiliationCode, 'TmpplaceCode');
                  }
                  if (screen === 'WS2532008') {
                    this.setStateInitParams(Lo_InsuranceCardNum, 'InsuranceCardNum');
                  }
                  if (screen === 'WS2532012') {
                    this.setStateInitParams(Lo_DateOfBirth, 'DateOfBirthChar');
                    this.formRef?.current?.setFieldsValue({ DateOfBirthChar: moment(Lo_DateOfBirth).format('NNy/MM/DD') })
                  }

                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      }
      case 'WS2553003':
      case 'WS2577003': {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: screen === 'WS2553003' ? '20%' : '60%',
            component: (
              <Component
                {...props}
                onFinishScreen={({ Lio_Date, Lio_TimeZone }) => {
                  if (screen === 'WS2577003') {
                    if (nameForm === 'ReserveDate') {
                      this.handleChangeRecord(Lio_Date, 'ReserveDate');
                    } else {
                      this.setStateInitParams(Lio_Date, 'DateChar');
                    }
                  }
                  if (screen === 'WS2553003') {
                    if (nameForm === 'W1_time_zone') {
                      this.handleChangeRecord(Lio_TimeZone, 'W1_time_zone');
                    } else {
                      this.setStateInitParams(Lio_TimeZone, 'TimeZone');
                    }
                  }

                  this.closeModal()
                }}
              />
            ),
          },
        })
        break;
      }
      case 'WS0289012': {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1400,
            component: (
              <WS0289012_ContractInfoInquiry
                {...props}
                onFinishScreen={({
                  Lio_ConsultCourse,
                  Lo_ContractType,
                  Lo_ContractOrgCode,
                  Lo_ContractStartDate,
                  Lo_ContractNum,
                  Lo_Status,
                  Lo_ErrorMessage,
                  recordData,
                }) => {
                  Lio_ConsultCourse = Lio_ConsultCourse ? Lio_ConsultCourse.replace('-', '') : '';
                  if (nameForm === 'W1_visits_courses') {
                    let total = recordData.Expression_42 + recordData.Expression_43 + recordData.Expression_44 + recordData.Expression_36;
                    let obj = {
                      ...this.state.rowSelect,
                      W1_visits_courses: Lio_ConsultCourse,
                      Expresstion_45: Lio_ConsultCourse,
                      Expresstion_46: recordData.W1_contract_short_name,
                      contract_short_name: recordData.W1_contract_short_name,
                      W1_contract_type: Lo_ContractType,
                      W1_contract_org_cd: Lo_ContractOrgCode,
                      W1_contract_date: Lo_ContractStartDate,
                      W1_contract_num: Lo_ContractNum,
                      insurer_total_price: recordData.Expression_42 ? recordData.Expression_42.toLocaleString() : null,
                      office_total_price: recordData.Expression_43 ? recordData.Expression_43.toLocaleString() : null,
                      organization_total_price: recordData.Expression_44 ? recordData.Expression_44.toLocaleString() : null,
                      Expresstion_57: recordData.Expression_36 ? recordData.Expression_36.toLocaleString() : null,
                      Expresstion_58: total ? total.toLocaleString() : null,
                    }
                    let arr = [...this.state.dataSource];
                    let index = arr.findIndex(item => item.id === obj.id);
                    arr[index] = obj;
                    this.setState({
                      dataSource: arr,
                      rowSelect: obj
                    });
                    this.updateData(obj)
                    this.formRef?.current?.setFieldsValue({ rowSelect: obj, dataSource: arr })
                  } else {
                    this.setStateInitParams(Lio_ConsultCourse, 'Course');
                    this.setState({
                      initObj: {
                        ...this.state.initObj,
                        contract_short_name: recordData.W1_contract_short_name,
                        ContractType: Lo_ContractType,
                        ContractOrgCode: Lo_ContractOrgCode,
                        ContractDate: Lo_ContractStartDate,
                        ContractNum: Lo_ContractNum,
                      }
                    })
                  }
                  this.closeModal()
                }}
              />
            ),
          },
        })
        break;
      }
      default: break;
    }
  }

  returnTimeZone(value = '08:30') {
    var arr = value.split(':');
    if (parseInt(arr[0]) >= 12) {
      if (parseInt(arr[1]) > 0) {
        return 'PM'
      }
    }
    return 'AM'
  }

  getNameCourseCode = (valueCourse, rowSelect, fieldName) => {
    const params = {
      Li_State: 0,
      Li_EffectiveLimitedDisplay: 1,
      Lio_ConsultCourse: valueCourse,
      Li_OfficeCode: rowSelect?.W1_office_cd,
      Li_BranchStoreCode: rowSelect?.W1_branch_store_cd,
      Li_Date: rowSelect?.W1_consult_date,
      Li_Gender: rowSelect?.Expresstion_47 === '男性' ? 1 : 2,
      Li_DateBirth: rowSelect?.birthday_on,
      Li_Relationship: rowSelect?.relationship,
      Li_HospitalOut: rowSelect?.conditions_in_out_hospital,
      Li_Am_Pm: this.returnTimeZone(rowSelect?.W1_time_zone),
      Li_NTsugikenmi: rowSelect?.W1_n_follow_sect,
      Li_Other: '',
      Lio_ContractType: rowSelect?.W1_contract_type,
      Lio_ContractOrgCode: rowSelect?.W1_contract_org_cd,
      Lio_ContractStartDate: rowSelect?.W1_contract_date || '0000/00/00',
      Lio_ContractNum: rowSelect?.W1_contract_num,
      Lo_Status: '',
      Lo_ErrorMessage: '',
    }
    if (valueCourse) {
      ContractInfoInquiryAction.index(params)
        .then((res) => {
          if (res?.message === 'WS0289012_ContractInfoInquiry') {
            this.callbackOpenModal('WS0289012', params, fieldName)
          } else {
            if (fieldName === 'W1_visits_courses') {
              let obj = {
                ...rowSelect,
                ...res,
                Expresstion_46: res?.contract_short_name,
              }
              let arr = [...this.state.dataSource];
              let index = arr.findIndex(item => item.id === obj.id);
              arr[index] = obj;
              this.setState({
                dataSource: arr,
                rowSelect: obj
              });
              this.updateData(obj)
              this.formRef?.current?.setFieldsValue({ rowSelect: obj, dataSource: arr })
            } else {
              this.setState({
                initObj: {
                  ...this.state.initObj,
                  contract_short_name: res?.contract_short_name
                }
              });
            }
          }
        })
        .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
    } else if (this.state.dataSource.length > 0) {
      this.callbackOpenModal('WS0289012', params, fieldName)
    }
  }

  getNameOfficeCode = (objChange) => {
    let params = {
      OfficeCode: this.state.initParams.OfficeCode,
      BranchStoreCode: this.state.initParams.BranchStoreCode
    }
    GroupBookingsAction.getNameOfficeCodeAction({ ...params, ...objChange })
      .then((res) => {
        this.formRef?.current?.setFieldsValue({ office_kanji_name: res?.data?.office_kanji_name || '' })
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  checkTimeZone = (value) => {
    if (value) {
      let arr = value.split(':');
      if ((8 <= parseInt(arr[0]) <= 12) && (parseInt(arr[1]) === 0 || parseInt(arr[1]) === 30)) {
        return true
      }
    }
    return false
  }

  render() {
    const { rowSelect, initParams, dataSource } = this.state;
    return (
      <div className='group-bookings'>
        <Form ref={this.formRef} onFinish={this.onFinish} autoComplete='off' initialValues={this.state.initParams} >
          <Card title='団体予約'>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='　事業所' name='OfficeCode' >
                          <Input.Search
                            allowClear={true}
                            style={{ textAlign: 'right' }}
                            className='input-search-size-4'
                            onPressEnter={() => { this.callbackOpenModal('WS0247001') }}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.formRef.current?.setFieldsValue({
                                  BranchStoreCode: '',
                                  office_kanji_name: '',
                                })
                                this.setState({
                                  OfficeCode: '',
                                  BranchStoreCode: '',
                                  office_kanji_name: '',
                                })
                              } else { this.callbackOpenModal('WS0247001') }
                            }}
                            onChange={(e) => {
                              this.setStateInitParams(e.target.value, 'OfficeCode');
                            }}
                          />
                        </Form.Item>
                        <Form.Item name='BranchStoreCode' >
                          {this.state.initParams.OfficeCode ?
                            <Input.Search
                              style={{ textAlign: 'right' }}
                              className='input-search-size-4'
                              type='number'
                              onPressEnter={() => {
                                this.callbackOpenModal('WS0247001')
                              }}
                              onSearch={(value, event) => {
                                if (event.target.localName === 'input') {
                                  this.formRef.current?.setFieldsValue({
                                    office_kanji_name: '',
                                  })
                                  this.setState({
                                    office_kanji_name: '',
                                  })
                                } else { this.callbackOpenModal('WS0247001') }
                              }}
                            // onChange={(e) => {
                            //   this.setStateInitParams(e.target.value, 'BranchStoreCode');
                            // }}
                            />
                            :
                            <InputNumber
                              className='input-search-size-4'
                              readOnly
                            />
                          }
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type={this.state.importance === 0 ? 'default' : 'text'}
                            icon={ReturnIcon(this.state.importance)}
                            disabled={!this.state.initParams.OfficeCode}
                            onClick={() => this.callbackOpenModal('WS2585001')}
                          />
                        </Form.Item>
                        <Form.Item name='office_kanji_name' style={{ marginRight: '10px' }}>
                          {/* <Input tabindex='-1' bordered={false} readOnly /> */}
                          <div>{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                        </Form.Item>
                      </div>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='　所　属' name='TmpplaceCode' >
                          <Input.Search
                            style={{ textAlign: 'right' }}
                            disabled={!(this.state.initParams.TmpplaceCode > 0 || this.state.initObj.StsAffiliationExists)}
                            onSearch={() => this.callbackOpenModal('WS2787004')}
                            onChange={(e) => this.setStateInitParams(e.target.value, 'TmpplaceCode')}
                          />
                        </Form.Item>
                        <Form.Item label='続　柄' name='Relationship' style={{ marginLeft: '10px' }}>
                          <Select
                            style={{ width: 120 }}
                            onClick={() => {
                              if (!this.state.initParams.OfficeCode) {
                                this.ModalError()
                              }
                            }}
                            onChange={(value) => this.setStateInitParams(value, 'Relationship')}
                          >
                            <Option value=''></Option>
                            {this.state.RelationshipList?.map(value => (
                              <Option key={'Relationship-' + value.node_code_name} value={value.node_code_name}>{value.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='性　別' name='Gender' style={{ marginLeft: '10px', marginRight: '10px' }}>
                          <Select
                            style={{ width: 120 }}
                            onClick={() => {
                              if (!this.state.initParams.OfficeCode) {
                                this.ModalError()
                              }
                            }}
                            onChange={(value) => this.setStateInitParams(value, 'Gender')}
                          >
                            <Option value='0'>　　</Option>
                            <Option value='1'>男性</Option>
                            <Option value='2'>女性</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <Form.Item label='個人番号' name='PersonalNumId'>
                        <Input
                          allowClear={true}
                          className='input-search-size-number-allow-clear-10'
                          style={{ textAlign: 'right' }}
                          onPressEnter={() => {
                            if (this.formRef.current?.getFieldValue('OfficeCode')) {
                              this.Extract('追加', true, this.state.initParams, this.state.initObj)
                            } else {
                              this.ModalError()
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='box_search_inner'>
                    <Tooltip title='前年度に受診されている方のみを抽出します'>
                      <Form.Item name='StsPreviousYearExtract' valuePropName='checked' >
                        <Checkbox
                          onChange={(e) => {
                            if (this.state.initParams.OfficeCode) {
                              this.setStateInitParams(e.target.checked ? 1 : 0, 'StsPreviousYearExtract')
                            } else {
                              this.ModalError()
                              this.formRef?.current?.setFieldsValue({ StsPreviousYearExtract: 0 })
                            }
                          }}
                        >前年度</Checkbox>
                      </Form.Item>
                    </Tooltip>
                    <Tooltip title='今年度に未受診の方のみを抽出します'>
                      <Form.Item name='StsThisYearNotYetVisited' valuePropName='checked' >
                        <Checkbox
                          onChange={(e) => {
                            if (this.state.initParams.OfficeCode) {
                              this.setStateInitParams(e.target.checked ? 1 : 0, 'StsThisYearNotYetVisited')
                            } else {
                              this.ModalError()
                              this.formRef?.current?.setFieldsValue({ StsThisYearNotYetVisited: 0 })
                            }
                          }}
                        >未受診</Checkbox>
                      </Form.Item>
                    </Tooltip>
                    <Form.Item name='SortOrder'>
                      <Radio.Group
                        onChange={(e) => {
                          if (this.state.initParams.OfficeCode) {
                            this.setStateInitParams(e.target.value, 'SortOrder')
                          } else {
                            this.ModalError()
                            this.formRef?.current?.setFieldsValue({ SortOrder: 0 })
                          }
                        }}
                      >
                        <Radio value={0}>カナ順</Radio>
                        <Radio value={1}>保険証順</Radio>
                        <Radio value={2}>処理順</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      type='primary'
                      onClick={() => {
                        if (this.formRef?.current?.getFieldValue('OfficeCode')) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 500,
                              component: (
                                <WS2532500_GroupBookingsAdd
                                  Li_initParams={this.state.initParams}
                                  Li_initObj={this.state.initObj}
                                  Li_FacilityNumList={this.state.FacilityNumList}
                                  onFinishScreen={(output) => {
                                    this.setState({
                                      initParams: output.initParams,
                                      initObj: output.initObj
                                    })
                                    this.Extract('追加', true, output.initParams, output.initObj)
                                    //時短処理作成中(脱メモリーテーブルが必須)
                                    // this.subExtract2('追加', true, output.initParams, output.initObj)
                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        } else {
                          this.ModalError()
                        }
                        // if (this.formRef?.current?.getFieldValue('OfficeCode')) {
                        //   this.state.dataSource.length <= 0
                        //     ? this.Extract('追加', true)
                        //     : this.ModalConfirm('Extract', '追加')
                        // } else {
                        //   this.ModalError()
                        // }
                      }}
                    >
                      一括追加
                    </Button>
                    <Button
                      type='primary'
                      // danger
                      onClick={() => this.ModalConfirm('Clear')}
                    >
                      クリア
                    </Button>
                  </div>
                </div>
              </div>

              {/* body */}
              <Table
                size='small'
                bordered
                dataSource={this.state.dataSource}
                rowKey={record => record.id}
                scroll={{ y: resizableTableScroll(300), x: 'max-content' }}
                rowClassName={(record, index) => record.id === this.state.rowSelect.id ? 'hightlight-row-selected' : ''}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                onRow={(record, index) => ({
                  onClick: (e) => {
                    this.setState({ rowSelect: record });
                    this.formRef?.current?.setFieldsValue({ rowSelect: record })
                  },
                })}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.listSelectedId,
                  onSelect: (record, selected, selectedRows, nativeEvent) => {
                    let arrTemp = [...this.state.listSelectedId];
                    let idx = arrTemp.indexOf(record.id);
                    idx === -1 ? arrTemp.push(record.id) : arrTemp.splice(idx, 1);

                    let newSelectedRow = [...this.state.selectedRow]
                    idx === -1 ? newSelectedRow.push(record) : newSelectedRow.splice(idx, 1)
                    this.setState({
                      listSelectedId: arrTemp,
                      selectedRow: newSelectedRow,
                      rowSelect: record
                    });
                    this.formRef?.current?.setFieldsValue({ rowSelect: record })
                    // this.SelectOneLine({
                    //   id: record.id,
                    //   W1_target_flg: (idx === -1) ? true : false,
                    // }, selected);
                  },
                  onSelectAll: (selected, selectedRows, changeRows) => {
                    let arrTemp = this.state.dataSource.map(item => item.id);
                    this.setState({
                      listSelectedId: selected ? arrTemp : [],
                      initObj: {
                        ...this.state.initObj,
                        AllSelect: selected ? 1 : 0,
                      },
                      selectedRow: selectedRows
                    });
                    // this.SelectAll(selected ? 1 : 0);
                  }
                }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='Expresstion_42'
                  className='column-size-date'
                  render={(text, record) =>
                    <div style={{ textAlign: 'center' }}>
                      {moment(text).format('YYYY/MM/DD (ddd)') ?? ''}
                    </div>}
                />
                <Table.Column
                  title='カナ氏名'
                  dataIndex='Expresstion_43'
                  className='column-size-40'
                  render={(text, record) =>
                    <div>{text}</div>}
                />
                <Table.Column
                  title='漢字氏名'
                  dataIndex='Expresstion_44'
                  className='column-size-40'
                  render={(text, record) =>
                    <div>{text}</div>}
                />
                <Table.Column
                  title='性別'
                  dataIndex='Expresstion_47'
                  className='column-size-5'
                  render={(text, record) =>
                    <div
                      style={{ textAlign: 'center' }}
                    // style={{ color: Color(record.Expresstion_52).Foreground }}
                    >{text}</div>}
                />
                <Table.Column
                  title='年齢'
                  dataIndex='Age'
                  className='column-size-5'
                  render={(text, record) =>
                    <div
                      style={{ textAlign: 'center' }}
                    // style={{ color: Color(record.Expresstion_52).Foreground }}
                    >{text ? text + '歳' : ''}</div>}
                />
                <Table.Column
                  title='コース'
                  dataIndex='Expresstion_45'
                  className='column-size-40'
                  render={(text, record) =>
                    <div
                    // style={{ color: Color(record.Expresstion_52).Foreground }}
                    >{text}</div>}
                />
                <Table.Column
                  title='契約情報'
                  dataIndex='Expresstion_46'
                  className='column-size-60'
                  render={(text, record) =>
                    <div
                    // style={{ color: Color(record.Expresstion_52).Foreground }}
                    >{text}</div>}
                />
                <Table.Column
                  title='エラー内容'
                  dataIndex='error_message'
                  className='column-size-60'
                  render={(text) =>
                    <div
                    // style={{ color: Color(209).Foreground }}
                    >{text}</div>}
                />
              </Table>

            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  if (this.formRef?.current?.getFieldValue('OfficeCode')) {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 500,
                        component: (
                          <WS2532500_GroupBookingsAdd
                            Li_initParams={this.state.initParams}
                            Li_initObj={this.state.initObj}
                            Li_FacilityNumList={this.state.FacilityNumList}
                            onFinishScreen={(output) => {
                              this.setState({
                                initParams: output.initParams,
                                initObj: output.initObj
                              })
                              this.Extract('変更', true, output.initParams, output.initObj)
                              this.closeModal();
                            }}
                          />
                        ),
                      },
                    });
                  } else {
                    this.ModalError()
                  }
                }}
              >
                一括変更
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.state.dataSource.length > 0
                    ? this.eventF12({ list_id: this.state.listSelectedId })
                    : this.ModalError()
                }}>
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2532001_GroupBookings);
