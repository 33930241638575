/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Form, message, Input, Button, Table, Modal, Space } from 'antd'
import { UnlockOutlined, MoreOutlined } from '@ant-design/icons'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import RadiographyFindingInputSubAction from 'redux/InputBusiness/RadiographyFindingInput/RadiographyFindingInputSub.action'
import { QuestionCircleOutlined, PlusOutlined, DeleteOutlined, SaveOutlined, } from '@ant-design/icons'
import WS1868001_JudgeSelectQuerySub from './WS1868001_JudgeSelectQuerySub'
import WS1865001_LeadershipMatterSearchSub from './WS1865001_LeadershipMatterSearchSub'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS1863001_RadiographyFindingInputSub extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '読影所見入力 SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      selectedRowTableSecond: [],
      dataForm: {},
      dataSource1: [],
      dataSource2: [],
      ChangeSiteAndFindingsCode: [],
      site_code1: 0,
      site_code2: 0,
      findings_code1: 0,
      findings_code2: 0,
      rowSelect: {},
      judgment_name: '',
      activeLink: null,
      activeLink2: null,
      activeLink3: null,
      activeLink4: null,
      rowSelect1: {},
      Expression_4: null,
      InterpretationMode: 0,
      selectedRows: [],
      indexTable: 0,

      dataSourceThisTime: [],
      dataSourcePrevious: [],

      dataSourceSites1: [],
      dataSourceSites2: [],
      dataSourceFindings1: [],
      dataSourceFindings2: [],

      selectedSitesRow1: null,
      selectedSitesRow2: null,
      selectedFindingRow1: null,
      selectedFindingRow2: null,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    const data = {
      Li_MenuOption: this.props.Li_MenuOption,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_InterpretationInspectCode: this.props.Li_InterpretationInspectCode,
      Li_Modality: this.props.Li_Modality,
      Li_DoctorCode: this.props.Li_DoctorCode,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_StsDoctors: this.props.Li_StsDoctors,
    }
    RadiographyFindingInputSubAction.index(data)
      .then((res) => {
        if (res) {
          this.setState({
            dataForm: res,

            // 部位1,部位2,所見1,所見2 データ一覧
            dataSourceSites1: res.SiteCode1,
            dataSourceSites2: res.SiteCode2,
            dataSourceFindings1: res.FindingsCode1,
            dataSourceFindings2: res.FindingsCode2,
            // 部位1,部位2,所見1,所見2 選択初期化
            selectedSitesRow1: null,
            selectedSitesRow2: null,
            selectedFindingRow1: null,
            selectedFindingRow2: null,
          })
          this.formRef.current.setFieldsValue({ dataForm: res ? res : {} })
          this.formRef.current.setFieldsValue({
            Expression_21: res.Expression_21,
            Remarks: res.Remarks,
            RemarksOtherPeople: res.RemarksOtherPeople,
            GuideDivision: res.GuideDivision,
            Expression_90: res.Expression_90,
            GuideCmtContent: res.GuideCmtContent,
            Expression_69: res.Expression_69,
            GuideDivisionPrevious: res.GuideDivisionPrevious,
            GuideCmtContentPrevious: res.GuideCmtContentPrevious,
            JudgeConvert: res.JudgeConvert,
            InterpretationMode: res.InterpretationMode,
          })
          this.getListDataFindingsContent()
          this.getListDataPreviousFindingsContent()
        }
      })
  }

  getListDataFindingsContent() {
    const data = {
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InterpretationInspectItemCode: this.state.dataForm.Li_InterpretationInspectCode,
      Li_DoctorCode: this.state.dataForm.Li_DoctorCode,
      Li_JudgeLevel: this.state.dataForm.judgment_level_division,
      JudgeConvert: this.state.dataForm.JudgeConvert,
    }
    RadiographyFindingInputSubAction.getListDataFindingsContent(data)
      .then((res) => {
        if (res) {
          const constvertType = (input) => (input === 0 ? '' : input)
          const newArr = res.Data.map((s) => ({
            ...s,
            W4_serial_num: constvertType(s.W4_serial_num),
          }))
          this.setState({ dataSource1: newArr, selectedRows: [res.Data[0]], indexTable: 0, })
          this.formRef.current.setFieldsValue({ tableData: newArr })
        }
      })
  }

  getListDataPreviousFindingsContent() {
    const data = {
      Li_ReserveNum: this.state.dataForm.LastTimeReserveNum,
      Li_InspectCode: this.state.dataForm.LastTimeInspectCode,
      Li_LastDate: this.state.dataForm.LastDate,
    }
    RadiographyFindingInputSubAction.getListDataPreviousFindingsContent(data)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource2: res.data,
            Expression_4: res.Expression_4,
          })
        }
      })
  }

  onChangeInput = (record, value, name) => {
    let arrTemp = [...this.state.dataSource1]
    let index = arrTemp.indexOf(record)
    if (index !== -1) {
      let objTemp
      switch (name) {
        default:
          objTemp = {
            ...record,
            [name]: value,
          }
          break
      }
      arrTemp[index] = objTemp
      this.setState({
        dataSource1: arrTemp,
        rowSelect: objTemp,
      })
      this.formRef.current.setFieldsValue({ dataSource1: arrTemp })
    }
  }

  saveData = (record) => {
    const data = {
      id: record.id,
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InterpretationInspectItemCode: this.state.dataForm.Li_InterpretationInspectCode,
      Li_DoctorCode: this.state.dataForm.Li_DoctorCode,
      W4_serial_num: record.W4_serial_num || 0,
      W4_site_name: record.W4_site_name ?? '',
      W4_findings_name: record.W4_findings_name ?? '',
      W4_judge: record.W4_judge ?? '',
    }
    RadiographyFindingInputSubAction.saveFindingsContent(data)
      .then((res) => {
        this.getListDataFindingsContent()
      })
      .catch((err) => message.error('エラー'))
  }

  deleteData = (record) => {
    console.log(5656, record)
    if (record.id || record.id === 0) {
      console.log('a')
      RadiographyFindingInputSubAction.deleteFindingsContent({
        id: record.id,
      })
        .then((res) => {
          message.success('成功')
          this.getListDataFindingsContent()
        })
        .catch((err) => message.error('エラー'))
    } else {
      console.log('b')
      let arrTemp = [...this.state.dataSource1]
      arrTemp.splice(arrTemp[0], 1)
      this.formRef.current.setFieldsValue({ tableData: arrTemp })
      this.setState({ dataSource1: arrTemp })
      this.getListDataFindingsContent()
    }
  }

  /**
   * 前回DO
   */
  postLastTimeDoBtn() {
    const data = {
      Li_StsDoctors: this.props.Li_StsDoctors,
      GuideDivision: this.state.dataForm.GuideDivision || '',
      LastTimeInterpretationInspectCode: this.state.dataForm.LastTimeInterpretationInspectCode,
      Li_InterpretationInspectCode: this.state.dataForm.Li_InterpretationInspectCode,
    }
    RadiographyFindingInputSubAction.postLastTimeDoBtn(data)
      .then((res) => {
        if (res) {
          Modal.confirm({
            icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
            content: '前回所見を取込ますか。',
            okText: 'は　い',
            cancelText: 'いいえ',
            onOk: () => {
              this.postLastTimeDoBtnYes(res.StsConfirm)
            },
          })
        } else {
          Modal.confirm({
            icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
            content: '前回所見を取込ますか。',
            okText: 'は　い',
            cancelText: 'いいえ',
            onOk: () => {
              this.postLastTimeDoBtnYes(6)
            },
          })
        }
      })
  }

  postLastTimeDoBtnYes(StsConfirm) {
    const data = {
      Li_ReserveNumPrevious: this.state.dataForm.LastTimeReserveNum,
      Li_InspectCode: this.state.dataForm.LastTimeInspectCode,
      Li_ReserveNumThisTime: this.state.dataForm.Li_ReserveNum,
      Li_InterpretationInspectCode: this.state.dataForm.Li_InterpretationInspectCode,
      Li_DoctorCode: this.state.dataForm.Li_DoctorCode,
      Li_JudgeLevel: this.state.dataForm.judgment_level_division,
      Li_StsDoctors: this.props.Li_StsDoctors,
      GuideDivision: this.state.dataForm.GuideDivision,
      GuideDivisionPrevious: this.state.dataForm.GuideDivisionPrevious,
      StsPreviousJudgeGet: this.state.dataForm.StsPreviousJudgeGet === false ? 0 : 1,
      StsConfirm: StsConfirm,
    }
    RadiographyFindingInputSubAction.postLastTimeDoBtnYes(data)
      .then((res) => {
        if (res.message === 'Success') {
          this.getListDataFindingsContent()
        }
      })
  }

  ChangeSite1(site_code1) {
    const data = {
      InspectClassifyCode: this.state.dataForm.Li_InterpretationInspectCode,
      SiteCode1: site_code1 ? site_code1.site_code : 0,
      SiteCode2: 0,
      FindingsCode1: 0,
    }
    RadiographyFindingInputSubAction.getChangeSiteAndFindingsCode(data)
      .then((res) => {
        if (res) {
          this.setState({
            site_code1: site_code1,
            ChangeSiteAndFindingsCode: res,
            activeLink: site_code1.site_code,
            site_code2: 0,
          })
        }
      })
  }

  ChangeSite2(site_code2) {
    const data = {
      InspectClassifyCode: this.state.dataForm.Li_InterpretationInspectCode,
      SiteCode1: this.state.site_code1.site_code,
      SiteCode2: site_code2 ? site_code2.site_code : 0,
      FindingsCode1: 0,
    }
    RadiographyFindingInputSubAction.getChangeSiteAndFindingsCode(data)
      .then((res) => {
        if (res) {
          this.setState({
            site_code2: site_code2,
            ChangeSiteAndFindingsCode: res,
            activeLink2: site_code2.site_code,
          })
        }
      })
  }

  ChangeFindingsCode1(finding_code1) {
    const data = {
      InspectClassifyCode: this.state.dataForm.Li_InterpretationInspectCode,
      SiteCode1: this.state.site_code1.site_code,
      SiteCode2: this.state.site_code2.site_code
        ? this.state.site_code2.site_code
        : 0,
      FindingsCode1: finding_code1 ? finding_code1.findings_code : 0,
    }
    RadiographyFindingInputSubAction.getChangeSiteAndFindingsCode(data)
      .then((res) => {
        if (res) {
          this.setState({
            findings_code1: finding_code1,
            ChangeSiteAndFindingsCode: res,
            activeLink3: finding_code1.findings_code,
          })
        }
      })
  }

  ChangeFindingsCode2(finding_code2) {
    this.setState({
      findings_code2: finding_code2,
      activeLink4: finding_code2.findings_code,
    })
  }

  /**
   * 追加ボタン　今回情報に部位、所見の情報を追加
   */
  addSiteFindings() {
    const data = {
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InterpretationInspectCode: this.state.dataForm.Li_InterpretationInspectCode,
      Li_DoctorCode: this.state.dataForm.Li_DoctorCode,
      SiteCode1: this.state.selectedSitesRow1.site_code ? this.state.selectedSitesRow1.site_code : 0,
      SiteCode2: this.state.selectedSitesRow2.site_code ? this.state.selectedSitesRow2.site_code : 0,
      FindingsCode1: this.state.selectedFindingRow1.findings_code ? this.state.selectedFindingRow1.findings_code : 0,
      FindingsCode2: this.state.selectedFindingRow2.findings_code ? this.state.selectedFindingRow2.findings_code : 0,
      SiteFindingsOutputConvert: this.state.dataForm.SiteFindingsOutputConvert === false ? 0 : 1,
    }
    RadiographyFindingInputSubAction.postSettingBtn(data)
      .then((res) => {
        if (res.StsEnter === true) {
          this.getListDataFindingsContent()
        } else {
          Modal.error({
            content: '所見が選択されていません',
            okText: 'Ok',
          })
        }
      })
  }

  findIndexByID = (arrayData, recordID) => {
    if (arrayData && arrayData.length > 0) {
      return arrayData.findIndex((item) => recordID === item.id)
    }
  }

  handleSelectRowsTableFirst = (selectedRowTableFirst) => {
    this.setState({ selectedRowTableFirst })
  }

  handleSelectRowsTableSecond = (selectedRowTableSecond) => {
    this.setState({ selectedRowTableSecond })
  }

  /**
   * 確定ボタン
   */
  confirm() {
    const data = {
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InterpretationInspectItemCode: this.state.dataForm.Li_InterpretationInspectCode,
      Li_DoctorCode: this.state.dataForm.Li_DoctorCode,
      Remarks: this.formRef.current?.getFieldValue('Remarks'),
      GuideDivision: this.formRef.current?.getFieldValue('GuideDivision'),
      GuideCmtCode: this.formRef.current?.getFieldValue('GuideCmtCode'),
      GuideCmtContent: this.formRef.current?.getFieldValue('GuideCmtContent'),
      JudgeConvert: this.state.dataForm.JudgeConvert,
      judgment_level_division: this.state.dataForm.judgment_level_division,
    }

    RadiographyFindingInputSubAction.confirm(data)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({})
        }
      })
  }

  /**
   * 個人情報照会 モーダル
   */
  showWS2584019_PersonalInfoInquirySub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={this.state.dataForm.PersonalNumId}
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const { rowSelect } = this.state
    return (
      <div className='radiography-finding-input-sub'>
        <Card title={'所見入力 [' + (this.props.Li_InterpretationInspectCode ?? '') + ']'}>
          <Form
            ref={this.formRef}
          >
            <div className='box_container'>
              <div className='box_search' style={{ width: '100%' }}>

                {/* 受診日、受付番号 / 予約番号 */}
                <div className='box_search_inner_border' style={{ width: '100%' }}>
                  <Space style={{ width: '100%' }}>
                    <Form.Item label='受診日' name='Date' style={{ width: '100%' }}>
                      <div>
                        {moment(this.state.dataForm.Date).format('YYYY/MM/DD')}
                      </div>
                    </Form.Item>
                    <Form.Item label='受付番号' style={{ width: '100%' }} >
                      <div>
                        {this.state.dataForm.AcceptNum}
                      </div>
                    </Form.Item>
                  </Space>

                  <Space>
                    <Form.Item
                      name=''
                      label='予約番号'
                      style={{ textAlign: 'start', marginBottom: 0 }}
                    >
                      <span>{this.state.dataForm.Li_ReserveNum}</span>
                    </Form.Item>
                    <UnlockOutlined />
                  </Space>
                </div>

                {/* 個人番号、性別、続柄、生年月日、年齢 / 氏名 */}
                <div className='box_search_inner_border' style={{ width: '100%' }}>
                  <Space>
                    <Form.Item
                      label='個人番号'
                      style={{ textAlign: 'right' }}
                    >
                      <span>{this.state.dataForm.Expression_26}</span>
                    </Form.Item>
                    <Form.Item name='Expression_21'>
                      <Input
                        type='text'
                        disabled={true}
                        style={{
                          width: 60,
                          background: Color(this.state.dataForm.Expression_22)?.Background,
                          color: Color(this.state.dataForm.Expression_22)?.Foreground,
                          textAlign: 'center',
                        }}
                      />
                    </Form.Item>
                    <div className='box_inner_horizontal' style={{ marginTop: 5, marginRight: 5 }}>
                      <div>
                        {this.state.dataForm.Expression_33}
                      </div>
                      <div>
                        {moment(this.state.dataForm.Expression_23).format('NNy/MM/DD')}
                      </div>
                      <div>
                        {this.state.dataForm.Age}歳
                      </div>
                    </div>
                  </Space>

                  <Space>
                    <Form.Item
                      name=''
                      label='氏名'
                      style={{ textAlign: 'center', marginBottom: 0 }}
                    >
                      <span>{this.state.dataForm.Expression_25}</span>
                    </Form.Item>
                    <Button
                      size='small'
                      style={{ marginTop: 5 }}
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.showWS2584019_PersonalInfoInquirySub()
                      }}
                    />
                  </Space>
                </div>

                {/* 保険者番号、保険記号／番号 / 保険者名称 */}
                <div className='box_search_inner' style={{ width: '100%' }}>
                  <Space style={{ width: '100%' }}>
                    <Form.Item label='保険者番号' >
                      <span style={{ width: '50%' }}>
                        {this.state.dataForm.insurer_number === 0
                          ? null
                          : this.state.dataForm.insurer_number}
                      </span>
                    </Form.Item>

                    <Form.Item label='保険記号／番号'>
                      <span style={{ width: '50%' }}>
                        {this.state.dataForm.Expression_34}
                      </span>
                    </Form.Item>
                  </Space>

                  <Form.Item
                    label='保険者名称'
                    style={{ marginBottom: 0 }}
                  >
                    <span>
                      {this.state.dataForm.Expression_35 === '[  ]'
                        ? null
                        : this.state.dataForm.Expression_35}
                    </span>
                  </Form.Item>
                </div>
              </div>


              <div className='box_inner_horizontal' style={{ alignItems: 'end' }}>

                <div className='box_title_border' style={{ height: 250, width: '100%' }}>
                  <span className='box-title' style={{ color: '#000' }}>
                    部位選択
                  </span>
                  <div className='box_inner_horizontal' >
                    <div style={{ width: '100%' }}>

                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceSites1}
                        scroll={{ y: 180 }}
                        rowClassName={(record, index) => record.site_code === this.state.selectedSitesRow1?.site_code ?? '' ? 'table-row-light' : ''}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: (e) => {
                              if (record.site_code === this.state.selectedSitesRow1?.site_code) {
                                this.setState({ selectedSitesRow1: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow1Search: null
                                })
                              } else {
                                this.setState({ selectedSitesRow1: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow1Search: record.site_code
                                })
                              }
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='site_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='部位1 名称'
                          dataIndex='site_name'
                        />
                      </Table>

                      {/*
                      {ChangeSiteAndFindingsCode.SiteCode1 ? (
                        <Form.Item
                          style={{
                            border: '1px solid #d9d9d9',
                            height: '100%',
                            paddingLeft: '5px',
                          }}
                        >
                          {ChangeSiteAndFindingsCode.SiteCode1 &&
                            ChangeSiteAndFindingsCode.SiteCode1.map((res, index) => {
                              return (
                                <div
                                  key={`site1-${index}`}
                                  className={
                                    res.site_code === activeLink
                                      ? 'active_item'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.ChangeSite1(res)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {res.site_name}
                                </div>
                              )
                            })}
                        </Form.Item>
                      ) : (
                        <Form.Item
                          style={{
                            border: '1px solid #d9d9d9',
                            height: '100%',
                            paddingLeft: '5px',
                          }}
                        >
                          {' '}
                          {this.state.dataForm.SiteCode1 &&
                            this.state.dataForm.SiteCode1.map((res, index) => {
                              return (
                                <div
                                  key={`site2-${index}`}
                                  className={
                                    res.site_code === activeLink
                                      ? 'active_item'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.ChangeSite1(res)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {res.site_name}
                                </div>
                              )
                            })}
                        </Form.Item>
                      )} */}
                    </div>
                    <div style={{ width: '100%' }}>
                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceSites2}
                        scroll={{ y: 180 }}
                        rowClassName={(record, index) => record.site_code === this.state.selectedSitesRow2?.site_code ?? '' ? 'table-row-light' : ''}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              if (record.site_code === this.state.selectedSitesRow2?.site_code) {
                                this.setState({ selectedSitesRow2: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow2Search: null
                                })
                              } else {
                                this.setState({ selectedSitesRow2: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedSitesRow2Search: record.site_code
                                })
                              }
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='site_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='部位2 名称'
                          dataIndex='site_name'
                        />
                      </Table>

                      {/* {ChangeSiteAndFindingsCode.SiteCode1 ? (
                        <Form.Item
                          style={{
                            border: '1px solid #d9d9d9',
                            height: '100%',
                            paddingLeft: '5px',
                          }}
                        >
                          {ChangeSiteAndFindingsCode.SiteCode2 &&
                            ChangeSiteAndFindingsCode.SiteCode2.map((res, index) => {
                              return (
                                <div
                                  key={`site3-${index}`}
                                  className={
                                    res.site_code === activeLink2
                                      ? 'active_item'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.ChangeSite2(res)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {res.site_name}
                                </div>
                              )
                            })}
                        </Form.Item>
                      ) : (
                        <Form.Item
                          style={{
                            border: '1px solid #d9d9d9',
                            height: '100%',
                            paddingLeft: '5px',
                          }}
                        >
                          {this.state.dataForm.SiteCode2 &&
                            this.state.dataForm.SiteCode2.map((res, index) => {
                              return (
                                <div
                                  key={`site4-${index}`}
                                  className={
                                    res.site_code === activeLink2
                                      ? 'active_item'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.ChangeSite2(res.site_code)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {res.site_name}
                                </div>
                              )
                            })}
                        </Form.Item>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className='box_title_border' style={{ height: 250, width: '100%' }}>
                  <span className='box-title' style={{ color: '#000' }}>
                    所見選択
                  </span>
                  <div className='box_inner_horizontal'>
                    <div style={{ width: '100%' }}>

                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceFindings1}
                        scroll={{ y: 180 }}
                        rowClassName={(record, index) => record.findings_code === this.state.selectedFindingRow1?.findings_code ?? '' ? 'table-row-light' : ''}
                        scrollToFirstRowOnChange={true}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              if (record.findings_code === this.state.selectedFindingRow1?.findings_code) {
                                this.setState({ selectedFindingRow1: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow1Search: null
                                })
                              } else {
                                this.setState({ selectedFindingRow1: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow1Search: record.findings_code
                                })
                              }

                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='findings_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='所見1 名称'
                          dataIndex='findings_name'
                        />
                      </Table>

                      {/*
                      {ChangeSiteAndFindingsCode.SiteCode1
                        ? (
                          <Form.Item
                            style={{
                              border: '1px solid #d9d9d9',
                              height: '100%',
                              paddingLeft: '5px',
                            }}
                          >
                            {ChangeSiteAndFindingsCode.FindingsCode1 &&
                              ChangeSiteAndFindingsCode.FindingsCode1.map(
                                (res, index) => {
                                  return (
                                    <div
                                      key={`findings1-${index}`}
                                      className={
                                        res.findings_code === activeLink3
                                          ? 'active_item'
                                          : ''
                                      }
                                      onClick={() => {
                                        this.ChangeFindingsCode1(res)
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {res.findings_name}
                                    </div>
                                  )
                                }
                              )}
                          </Form.Item>
                        ) : (
                          <Form.Item
                            style={{
                              border: '1px solid #d9d9d9',
                              height: '100%',
                              paddingLeft: '5px',
                            }}
                          >
                            {this.state.dataForm.FindingsCode1 &&
                              this.state.dataForm.FindingsCode1.map((res, index) => {
                                return (
                                  <div
                                    key={`findings2-${index}`}
                                    className={
                                      res.findings_code === activeLink3
                                        ? 'active_item'
                                        : ''
                                    }
                                    onClick={() => {
                                      this.ChangeFindingsCode1(res)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {res.findings_name}
                                  </div>
                                )
                              })}
                          </Form.Item>
                        )} */}
                    </div>

                    <div style={{ width: '100%' }}>
                      <Table
                        bordered
                        size='small'
                        pagination={false}
                        rowKey={record => record.id}
                        dataSource={this.state.dataSourceFindings2}
                        scroll={{ y: 180 }}
                        rowClassName={(record, index) => record.findings_code === this.state.selectedFindingRow2?.findings_code ?? '' ? 'table-row-light' : ''}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              if (record.findings_code === this.state.selectedFindingRow2?.findings_code) {
                                this.setState({ selectedFindingRow2: null })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow2Search: null
                                })
                              } else {
                                this.setState({ selectedFindingRow2: record })
                                this.formRef.current?.setFieldsValue({
                                  selectedFindingRow2Search: record.findings_code
                                })
                              }
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='コード'
                          width={60}
                          dataIndex='findings_code'
                          render={(text) => (
                            <div style={{ textAlign: 'right' }}>{text}</div>
                          )}
                        />
                        <Table.Column
                          title='所見2 名称'
                          dataIndex='findings_name'
                        />
                      </Table>

                      {/* {ChangeSiteAndFindingsCode.SiteCode1 ? (
                        <Form.Item
                          style={{
                            border: '1px solid #d9d9d9',
                            height: '100%',
                            paddingLeft: '5px',
                          }}
                        >
                          {ChangeSiteAndFindingsCode.FindingsCode2 &&
                            ChangeSiteAndFindingsCode.FindingsCode2.map(
                              (res, index) => {
                                return (
                                  <div
                                    key={`findings3-${index}`}
                                    className={
                                      res.findings_code === activeLink4
                                        ? 'active_item'
                                        : ''
                                    }
                                    onClick={() => {
                                      this.ChangeFindingsCode2(res)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {res.findings_name}
                                  </div>
                                )
                              }
                            )}
                        </Form.Item>
                      ) : (
                        <Form.Item
                          style={{
                            border: '1px solid #d9d9d9',
                            height: '100%',
                            paddingLeft: '5px',
                          }}
                        >
                          {this.state.dataForm.FindingsCode2 &&
                            this.state.dataForm.FindingsCode2.map((res, index) => {
                              return (
                                <div
                                  key={`findings4-${index}`}
                                  className={
                                    res.findings_code === activeLink4
                                      ? 'active_item'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.ChangeFindingsCode2(res)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {res.findings_name}
                                </div>
                              )
                            })}
                        </Form.Item>
                      )} */}

                    </div>
                  </div>
                </div>


                <Button
                  onClick={() => {
                    this.addSiteFindings()
                  }}
                  type='primary'
                >
                  追加
                </Button>


                <div className='box_title_border' style={{ height: 250, minWidth: '25%' }}>
                  <span className='box-title' style={{ color: '#000' }}>
                    メモ
                  </span>
                  <div className='box_inner_vertical'>
                    <Form.Item name='Remarks' style={{ marginBottom: 0 }}>
                      <Input.TextArea rows={3} />
                    </Form.Item>

                    <Form.Item name='RemarksOtherPeople'>
                      <Input.TextArea readOnly rows={3} />
                    </Form.Item>
                  </div>
                </div>
              </div>


              <div className='box_inner_horizontal'>
                <div className='box_title_border' style={{ width: '100%' }}>
                  <span className='box-title' style={{ color: '#000' }}>
                    今回情報
                  </span>
                  <div className='box_inner_vertical'>

                    <Form.Item
                      label='所見'
                      style={{ marginBottom: 0 }}
                    >
                      <Table
                        bordered
                        size='small'
                        dataSource={this.state.dataSource1}
                        pagination={false}
                        rowKey={(record) => record.id}
                        rowClassName={(record, index) => record.id === this.state.selectedRows[0]?.id ? 'table-row-light' : ''}
                        scroll={{ y: 180 }}
                        onRow={(record, index) => ({
                          onClick: (e) => {
                            console.log(index)
                            this.setState({ rowSelect: record, indexTable: index, selectedRows: [record], })
                          },
                        })}
                      >
                        <Table.Column
                          style={{ padding: '0' }}
                          width={80}
                          title='連番'
                          dataIndex='W4_serial_num'
                          render={(row, record, index) => {
                            return (
                              <Form.Item
                                name={['tableData', index, 'W4_serial_num']}
                                style={{ marginBottom: '0px' }}
                              >
                                <Input
                                  type='number'
                                  style={{ textAlign: 'end' }}
                                  onChange={(e) =>
                                    this.onChangeInput(
                                      rowSelect,
                                      e.target.value,
                                      'W4_serial_num'
                                    )
                                  }
                                  name='W4_serial_num'
                                />
                              </Form.Item>
                            )
                          }}
                        />
                        <Table.Column
                          title='部位'
                          dataIndex='W4_site_name'
                          render={(row, record, index) => {
                            return (
                              <Form.Item
                                name={['tableData', index, 'W4_site_name']}
                                style={{ marginBottom: '0px' }}
                              >
                                <Input
                                  onChange={(e) =>
                                    this.onChangeInput(
                                      rowSelect,
                                      e.target.value,
                                      'W4_site_name'
                                    )
                                  }
                                  name='W4_site_name'
                                />
                              </Form.Item>
                            )
                          }}
                        />
                        <Table.Column
                          title='所見'
                          dataIndex='W4_findings_name'
                          render={(row, record, index) => {
                            return (
                              <Form.Item
                                name={['tableData', index, 'W4_findings_name']}
                                style={{ marginBottom: '0px' }}
                              >
                                <Input
                                  onChange={(e) =>
                                    this.onChangeInput(
                                      rowSelect,
                                      e.target.value,
                                      'W4_findings_name'
                                    )
                                  }
                                  name='W4_findings_name'
                                />
                              </Form.Item>
                            )
                          }}
                        />
                        <Table.Column
                          title='判定'
                          dataIndex='W4_judge'
                          width={50}
                          render={(row, record, index) => {
                            return (
                              <Form.Item
                                name={['tableData', index, 'W4_judge']}
                                style={{ marginBottom: '0px' }}
                              >
                                <Input.Search
                                  className='input-search-size-judgment'
                                  style={{ textAlign: 'center' }}
                                  onSearch={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: 500,
                                        component: (
                                          <WS1868001_JudgeSelectQuerySub
                                            Li_JudgeLevel={record.Li_JudgeLevel}
                                            Lo_Judge=''
                                            onFinishScreen={(output) => {
                                              record = {
                                                ...record,
                                                W4_judge:
                                                  output.Lo_interpretation_judgment_result,
                                              }
                                              let data = [
                                                ...this.state.dataSource1,
                                              ]
                                              data[index] = record
                                              this.setState({
                                                dataSource1: data,
                                              })
                                              this.formRef.current.setFieldsValue(
                                                {
                                                  tableData: data,
                                                }
                                              )

                                              this.closeModal()
                                            }}
                                          />
                                        ),
                                      },
                                    })
                                  }}
                                  onChange={(e) =>
                                    this.onChangeInput(
                                      rowSelect,
                                      e.target.value,
                                      'W4_judge'
                                    )
                                  }
                                  name='W4_judge'
                                />
                              </Form.Item>
                            )
                          }}
                        />
                        <Table.Column
                          width={100}
                          align='center'
                          title={() => (
                            <Button
                              size='small'
                              type='primary'
                              icon={<PlusOutlined />}
                              onClick={() => {
                                let arrTemp = [{ W4_serial_num: '' }]
                                this.formRef.current.setFieldsValue({
                                  tableData: [
                                    ...arrTemp,
                                    ...this.state.dataSource1,
                                  ],
                                })
                                this.setState({
                                  dataSource1: [
                                    ...arrTemp,
                                    ...this.state.dataSource1,
                                  ],
                                })
                              }}
                            />
                          )}
                          render={(text, record, index) => (
                            <>
                              <Button
                                hidden={this.state.indexTable !== this.findIndexByID(this.state.dataSource1, record.id)}
                                style={{ border: 'none', marginRight: '5px', color: 'green' }}
                                size='small'
                                icon={
                                  <SaveOutlined />
                                }
                                onClick={() => this.saveData(record)}
                              />
                              <Button
                                hidden={this.state.indexTable !== this.findIndexByID(this.state.dataSource1, record.id)}
                                style={{ border: 'none', color: 'red' }}
                                size='small'
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  Modal.confirm({
                                    content: '消去してもよろしいですか？',
                                    okText: 'は　い',
                                    cancelText: 'いいえ',
                                    onOk: () => this.deleteData(record),
                                  })
                                }}
                              />
                            </>
                          )}
                        />
                      </Table>
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      {/* {this.props.Li_StsDoctors === 1 ||
                      this.state.InterpretationMode === 2 ? (
                      <span style={{ color: '#14468C', fontWeight: 'bold' }}>
                        判定
                      </span>
                    ) : null} */}

                      {this.props.Li_StsDoctors === 1 ||
                        this.state.InterpretationMode === 2 ? (
                        <Form.Item
                          label='判定'
                          name='GuideDivision'
                          style={{ marginBottom: 0 }}
                        >
                          <Input.Search
                            className='input-search-size-judgment'
                            style={{ textAlign: 'center' }}
                            onSearch={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 500,
                                  component: (
                                    <WS1868001_JudgeSelectQuerySub
                                      Li_JudgeLevel={
                                        this.state.dataForm
                                          .judgment_level_division
                                      }
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          GuideDivision:
                                            output.Lo_interpretation_judgment_result,
                                        })
                                        this.setState({
                                          judgment_name:
                                            output.Lo_judgment_name,
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          />
                        </Form.Item>
                      ) : null}
                      {this.props.Li_StsDoctors === 1 ||
                        this.state.InterpretationMode === 2 ? (
                        <Form.Item
                          style={{ marginBottom: 0 }}
                        >
                          <span>
                            {this.state.judgment_name
                              ? this.state.judgment_name
                              : this.state.dataForm.judgment_name}
                          </span>
                        </Form.Item>
                      ) : (
                        null
                      )}

                      {this.state.dataForm.user_name ?
                        <Form.Item label='技師名'>
                          <div>{this.state.dataForm.user_name}</div>
                        </Form.Item>
                        :
                        null
                      }

                      <Form.Item name='Expression_90'
                        style={{ marginBottom: 0 }}
                      >
                        <Input
                          style={{ color: (this.state.dataForm.judgment_name == '') ? '' : 'red' }}
                          type='text'
                          disabled={true}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      {/* {this.props.Li_StsDoctors === 1 ||
                      this.state.InterpretationMode === 2 ? (
                      <span style={{ color: '#14468C', fontWeight: 'bold' }}>
                        指導
                      </span>
                    ) : null} */}

                      {this.props.Li_StsDoctors === 0 &&
                        this.state.InterpretationMode < 2 ? (
                        <span style={{ color: 'red' }}>※　</span>
                      ) : null}
                      {this.props.Li_StsDoctors === 1 ||
                        this.state.InterpretationMode === 2 ? (
                        <Form.Item
                          label='指導'
                          name='GuideCmtContent'
                          style={{ marginBottom: 0, marginRight: 0, width: '100%' }}
                        >
                          <Input.TextArea
                            rows={3}
                            style={{ width: '100%' }}
                            onDoubleClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1400,
                                  component: (
                                    <WS1865001_LeadershipMatterSearchSub
                                      Li_InspectClassifyCode={this.state.dataForm.InspectClassifyCode}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          GuideCmtContent:
                                            output.Lo_CommentContent,
                                        })
                                        this.formRef.current.setFieldsValue({
                                          GuideCmtCode: output.Lo_CommentCode,
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          />
                        </Form.Item>
                      )
                        :
                        null
                      }

                      <Form.Item
                        style={{ marginBottom: 0 }}
                      >
                        {this.props.Li_StsDoctors === 0 &&
                          this.state.InterpretationMode < 2 ? (
                          <div style={{ color: 'red' }}>
                            読影者が区分：技師のため、判定と指導は入力出来ません。
                            <br />
                            入力する場合は、区分が医師の読影者で入り直してください。
                          </div>
                        )
                          :
                          null
                        }
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='box_title_border' style={{ width: '20%', minWidth: '25%' }}>
                  <span className='box-title' style={{ color: '#000' }}>
                    前回情報
                  </span>

                  <div className='box_inner_vertical'>
                    <Table
                      bordered
                      size='small'
                      dataSource={this.state.dataSource2}
                      pagination={false}
                      scroll={{ y: 180 }}
                      rowKey={(record) => record.id}
                    // rowSelection={{ type: 'radio', ...rowSelectionTableSecond }}
                    >
                      <Table.Column
                        title={this.state.Expression_4}
                        dataIndex='SiteFindings'
                      />
                      <Table.Column
                        title='判定'
                        dataIndex='judgment_value'
                        width={50}
                      />
                    </Table>

                    <div className='box_inner_horizontal'>
                      {(this.state.dataForm.LastTimeInterpretationInspectCode !== this.state.dataForm.Li_InterpretationInspectCode &&
                        this.state.dataForm.LastTimeInterpretationInspectCode !== '')
                        ?
                        <Form.Item name='Expression_69'>
                          <span style={{ color: 'red' }}>
                            {this.state.dataForm.Expression_69}
                          </span>
                        </Form.Item>
                        :
                        <div>{'　'}</div>
                      }
                    </div>

                    {this.props.Li_StsDoctors === 1 ||
                      this.state.InterpretationMode === 2 ?
                      <div>
                        <Form.Item name='GuideDivisionPrevious'>
                          <Input type='text' disabled={true} />
                        </Form.Item>

                        <Form.Item
                          name='GuideCmtContentPrevious'
                          style={{ marginBottom: 0 }}
                        >
                          <Input.TextArea rows={3} disabled={true} />
                        </Form.Item>
                      </div>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>

          </Form >

          <div className='box_button_bottom_right'>
            <Button
              disabled={!this.state.dataSource2[0]}
              onClick={() => {
                this.postLastTimeDoBtn()
              }}
              type='primary'
            >
              <span className='btn_label'>
                前回DO
              </span>
            </Button>
            <Button
              type='primary'
              onClick={() => {
                this.confirm()
              }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1863001_RadiographyFindingInputSub)
