import React from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button } from 'antd'
import ContractHistoricalQuerySubAction from 'redux/basicInfo/ContractInfoMaintain/ContractHistoricalQuerySub.action'
import moment from 'moment'

class WS0330001_ContractHistoricalQuerySub extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Lo_ContractStartDate: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '契約履歴照会'

    this.state = {
      isLoadingList: false,

      startDateInsuran: {},
      //data test
      dataTable: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }

    this.loadHistoryList = this.loadHistoryList.bind(this)
  }

  componentDidMount() {
    this.loadHistoryList()
  }

  loadHistoryList() {
    this.setState({ isLoadingList: true })

    ContractHistoricalQuerySubAction.getHistoryList({
      Li_ContractType: this.props.Li_ContractType,
      Li_ContractOrgCode: this.props.Li_ContractOrgCode,
    })
      .then(res => {
        this.setState({
          dataTable: res.data,
        })
      })
      .catch(error => {

      })
      .finally(() => this.setState({ isLoadingList: false }))
  }

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          ...this.state,
          startDateInsuran: selectedRows[0]
        })
      }
    }
    return (
      <div className='contract-historical-query-sub'>
        <Card title='契約履歴照会'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataTable}
            pagination={this.state.dataTable.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={record => record.id}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  const fn = this.props.onFinishScreen || this.props.onClickedSelect
                  fn({
                    Lo_ContractStartDate: record.contract_start_date_on,
                    recordData: record,
                  })
                }
              }
            }}
          >
            <Table.Column
              title='契約開始日'
              dataIndex='contract_start_date_on'
              className='column-size-10 '
              render={value => (
                moment(value).isValid() ? moment(value).format('YYYY/MM/DD') : '0000/00/00'
              )} />
            <Table.Column
              title='契約名称'
              dataIndex='contract_name'
            />
          </Table>
        </Card>
      </div>
    )
  }
}

export default WS0330001_ContractHistoricalQuerySub
