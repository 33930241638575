import axios from 'configs/axios';

const apiPaths = {
  getPreviousGuideMatter: '/api/not-input-check-category/previous-guide-matter-inquiry/get-previous-guide-matter',
  setPreviousGuideMatter: '/api/not-input-check-category/previous-guide-matter-inquiry/set-previous-guide-matter',

};

const PreviousGuideMatterInquiryService = {
  async getPreviousGuideMatter(params) {
    return axios.get(apiPaths.getPreviousGuideMatter, { params });
  },
  async setPreviousGuideMatter(params) {
    return axios.post(apiPaths.setPreviousGuideMatter, params);
  },
};

export default PreviousGuideMatterInquiryService;
