import axios from 'configs/axios'

const apiPaths = {
  outputCSV: '/api/associate-insure-qualify-confirm/request-csv-output/output-csv',
}

const RequestCsvOutputService = {
  async outputCSV(params) {
    return axios.post(apiPaths.outputCSV, params, { responseType: 'blob' })
  },
}

export default RequestCsvOutputService


