import CounterService from "services/CounterBusiness/Counter/CounterService";
import { message } from "antd";

export const getIntroduceCounterAction = (params) => {
  return CounterService.getIntroduceCounterService(params)
}

export const getListDataCounterAction = (params) => {
  return CounterService.getListDataCounterService(params)
}

export const getReAcquisitionAction = (params) => {
  return CounterService.getReAcquisitionService(params)
}

export const AcceptButtonAction = (params) => {
  return CounterService.AcceptButtonService(params)
}

export const getRefineAction = (params) => {
  return CounterService.getRefineService(params)
}
export const getSrceenDataAction = (params) => {
  return CounterService.getSrceenData(params)
}

export const eventEnterC_CounterAction = (params) => {
  return CounterService.eventEnterC_CounterService(params)
}

export const userAction3CounterAction = (params) => {
  return CounterService.userAction3CounterService(params)
}

export const getUseBarcodeAction = (params) => {
  return CounterService.getUseBarcodeService(params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
}

export const checkAcceptProcess = () => {
  return CounterService.checkAcceptProcess()
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      const res = err.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
        return
      }
      message.error(res.data.message)
    })
}