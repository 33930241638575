import axios from 'configs/axios';
const apiPaths = {
  GetScreenData: '/api/Connect-Hosts-Setting/getscreendata',
  Update: '/api/Connect-Hosts-Setting/update',
  Delete: '/api/Connect-Hosts-Setting/delete',
};

const ConnectHostsSettingService = {
  async GetScreenData() {
    return axios.get(apiPaths.GetScreenData);
  },
  async Update(params) {
    return axios.post(apiPaths.Update, params);
  },
  async Delete(params) {
    return axios.delete(apiPaths.Delete, { params });
  },
};

export default ConnectHostsSettingService;
