import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Form, Button, Dropdown, Menu } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import FollowUpLetterInspectInputAction from 'redux/IntroductionLetter/FollowUpLetter/FollowUpLetterInspectInput.action.js'
import FollowUpLetterInspectInputEdit from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterInspectInputEdit.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

class FollowUpLetterInspectInput extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '検査入力（経過手紙）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRowChange: {
        exam_code: null,
        group_judgement: null,
      }
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    console.log(this.props);
    // データ取得
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    let params = {
      course_level: this.props.Li_CourseLevel,
      reservation_number: this.props.Li_ReserveNum,
      visit_date_on: this.props.Li_Date,
      pattern_code: this.props.Li_PatternCode,
      accepted_no: this.props.Li_AcceptNum,
      department: this.props.Li_Department,
      personal_number_id: this.props.Li_PersonalNum,
      selectedJudgment: this.props.selectedJudgment
    }
    FollowUpLetterInspectInputAction.index(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 検査入力（新規追加・変更）モーダル
   */
  showFollowUpLetterInspectInputEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.statechildModal,
        width: 500,
        visible: true,
        component: (
          <FollowUpLetterInspectInputEdit
            record={record}
            subTitle={this.props.subTitle}
            Li_CourseLevel={this.props.Li_CourseLevel}
            Li_PatternCode={this.props.Li_PatternCode}
            Li_ReserveNum={this.props.Li_ReserveNum}
            Li_Date={this.props.Li_Date}
            Li_AcceptNum={this.props.Li_AcceptNum}
            Li_Department={this.props.Li_Department}
            Li_PersonalNum={this.props.Li_PersonalNum}
            onFinishScreen={() => {
              // データ取得
              this.index()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='inspect-input'>
        <Card title={`検査入力 ${this.props.subTitle}`}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              scroll={{ y: 450 }}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    // 変更
                    this.showFollowUpLetterInspectInputEdit(record)
                  }
                }
              }}
            >
              <Table.Column
                title='検査コード'
                width={100}
                dataIndex='exam_code'
                render={(value, record, index) =>
                  <div style={{ textAlign: 'right' }} >{value}</div>
                } />
              <Table.Column
                title='検査名称'
                dataIndex='exam_name'
              />
              <Table.Column
                title='結果'
                dataIndex='resultValue'
              />
              <Table.Column
                title='判定'
                dataIndex=''
                width={80}
                align='center'
                key='judgment_value'
              />
              <Table.Column
                title='カテゴリ'
                dataIndex='category_judgment'
                width={80}
                align='center'
              />
              {/* <Table.Column　// 紹介状用の判定値は今後使用しない
                title='紹介状'
                dataIndex='group_judgement'
                width='10%'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['tableData', index, 'group_judgement']}>
                      <Input
                        type='text'
                        style={{ textAlign: 'center' }}
                        maxLength={3}
                        onChange={(e) => this.setState({
                          rowSelectedChange: {
                            ...this.state.rowSelectedChange, group_judgement: e.target.value
                          }
                        })}
                      />
                    </Form.Item>
                  )
                }} /> */}
              <Table.Column
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() =>
                      // 新規追加
                      this.showFollowUpLetterInspectInputEdit()
                    }
                  />}
                width={40}
                align='center'
                render={(value, record) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='0'
                          onClick={() => {
                            // 変更
                            this.showFollowUpLetterInspectInputEdit(record)
                          }}
                        >
                          変更
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpLetterInspectInput)
