import axios from 'configs/axios';

const apiPaths = {
  getDataSiteFindingsMasterMaintain: '/api/site-findings-master-maintain/site-findings-master-maintain',
  getSiteTableAndFindingsTable: '/api/site-findings-master-maintain/site-findings-master-maintain/site-table-and-findings-table',
  saveAndUpdateSiteFindingsMasterMaintain: '/api/site-findings-master-maintain/site-findings-master-maintain/save-and-update',
  deleteSiteFindingsMasterMaintain: '/api/site-findings-master-maintain/site-findings-master-maintain/delete',
  deleteSiteTable: '/api/site-findings-master-maintain/site-findings-master-maintain/delete-site-table',
  deleteFindingsTable: '/api/site-findings-master-maintain/site-findings-master-maintain/delete-findings-table',

  registerSiteFindingsData: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/register-site-findings-data',
  getPartsData: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/get-parts-data',
  getFindingsData: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/get-findings-data',
  deleteSiteFindingsData: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/delete-site-findings-data',

  registerParts: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/register-parts',
  deleteParts: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/delete-parts',

  registerFindings: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/register-findings',
  deleteFindings: '/api/site-findings-master-maintain/site-findings-master-maintain-sub/delete-findings',
};

const SiteFindingsMasterMaintainService = {
  async getDataSiteFindingsMasterMaintainService() {
    return axios.get(apiPaths.getDataSiteFindingsMasterMaintain);
  },
  async getSiteTableAndFindingsTableService(params) {
    return axios.get(apiPaths.getSiteTableAndFindingsTable, { params });
  },
  async saveAndUpdateSiteFindingsMasterMaintainService(params) {
    return axios.post(apiPaths.saveAndUpdateSiteFindingsMasterMaintain, params)
  },
  async deleteSiteFindingsMasterMaintainService(params) {
    return axios.delete(apiPaths.deleteSiteFindingsMasterMaintain, { params })
  },
  async deleteSiteTableService(params) {
    return axios.delete(apiPaths.deleteSiteTable, { params })
  },
  async deleteFindingsTableService(params) {
    return axios.delete(apiPaths.deleteFindingsTable, { params })
  },

  //SiteFindingsMasterMaintainSub
  async getPartsDataService(params) {
    return axios.get(apiPaths.getPartsData, { params });
  },

  async getFindingsDataService(params) {
    return axios.get(apiPaths.getFindingsData, { params });
  },

  async registerSiteFindingsDataService(params) {
    return axios.post(apiPaths.registerSiteFindingsData, params)
  },

  async deleteSiteFindingsDataService(params) {
    return axios.delete(apiPaths.deleteSiteFindingsData, { params })
  },


  //WS0356021_RegisterPartsData
  async registerPartsService(params) {
    return axios.post(apiPaths.registerParts, params)
  },

  async deletePartsService(params) {
    return axios.delete(apiPaths.deleteParts, { params })
  },

  //WS0356022_RegisterFindingsData
  async registerFindingsService(params) {
    return axios.post(apiPaths.registerFindings, params)
  },

  async deleteFindingsService(params) {
    return axios.delete(apiPaths.deleteFindings, { params })
  },

};

export default SiteFindingsMasterMaintainService;