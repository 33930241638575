import axios from 'configs/axios';

const apiPaths = {
  registerEventMetabolicSyndromeHierarchical: '/api/specific-health-tokuho-param-maintain/copying-process/register',
};

const CopyingProcessService = {
  async registerEventMetabolicSyndromeHierarchicalService(params) {
    return axios.post(apiPaths.registerEventMetabolicSyndromeHierarchical, params)
      .then((res) => { return res.data.message })
      .catch((err) => {
        throw err.response.data.message
      });

  },
};

export default CopyingProcessService;