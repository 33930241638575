import axios from 'configs/axios'

const API_LIST = {
  index: '/api/follow-up-letter/follow-up-letter-return-input',
  getDataInputSub: '/api/follow-up-letter/follow-up-letter-return-input/get-data-input-sub',
  save: '/api/follow-up-letter/follow-up-letter-return-input/save',
  saveDetail: '/api/follow-up-letter/follow-up-letter-return-input/save-detail',
  delete: '/api/follow-up-letter/follow-up-letter-return-input/delete',
}

const FollowUpLetterReturnInputService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getDataInputSub(params) {
    return axios.get(API_LIST.getDataInputSub, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async saveDetail(params) {
    return axios.post(API_LIST.saveDetail, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
}

export default FollowUpLetterReturnInputService
