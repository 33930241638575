import axios from 'configs/axios'

const API_LIST = {
  index: '/api/sanai-reserve-status-search/reserve-availability',
}

const ReserveAvailabilityService = {
  async index(params) {
    return axios.post(API_LIST.index, params)
  },
}

export default ReserveAvailabilityService
