import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import PrinterSelectAction from "redux/SM_SystemMaintenance/PrinterInfoMaintain/PrinterSelect.action"

class WS2698172_PrinterSelect extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'プリンタ選択';

    this.state = {
      W1CharacterKey: '',
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.getScreenData();
  }

  getScreenData = () => {
    this.setState({ isLoading: true })
    // VenusApiRoutesV2.callApi("API002698172001", {})
    PrinterSelectAction.getScreenData()
      .then((res) => {
        this.setState({ W1CharacterKey: res.W1_character_key })
        // VenusApiRoutesV2.callApi("API002698172002", {})
        PrinterSelectAction.index()
          .then((res) => {
            this.setState({ dataSource: res })
          })
          .finally(() => this.setState({ isLoading: false }))
      }).catch(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
      <div className="printer-select">
        <Card title="プリンタ選択" className="mb-2">
          <Table bordered
            dataSource={this.state.dataSource}
            // loading={isLoading}
            pagination={true}
            rowKey={(record) => record.id}
          >
            <Table.Column title="" dataIndex="" key="" />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2698172_PrinterSelect);
