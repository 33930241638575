import React from "react";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment";
import WS1291001_XmlDocumentQuerySelectSub from 'pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1291001_XmlDocumentQuerySelectSub';
import XmlOutputConfirmAction from 'redux/SpecificInsureGuide/SpecificCoerciveFingerXmlOutput/XmlOutputConfirmAction.action';

import { Card, Form, Input, Select, Button, message, Space } from "antd";
import { FormOutlined } from '@ant-design/icons';

const smGrid = {
  labelCol: { style: { width: 65 } },
};

const dateFormat = "YYYY/MM/DD";
class WS2656096_OutputConfirm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '出力確認';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      GcreationDateChars: '',
      GsubmissionDateChars: '',
      Lio_NumTransmissions: 1,
      Li_ContinuationEndDate: 1,
      xmlData: [],
      xmlDataDefault: null,
      Lio_Output: '',
      ProcessDivision: 0,
      StsOutput: ''
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let param = {
      Li_SpecificAndHealthGuideAndAll: 2
    }
    XmlOutputConfirmAction.GetScreenData(param)
      .then(res => {

        console.log(res);

        this.setState({
          xmlData: res,
          xmlDataDefault: res[0],
          GcreationDateChars: moment(new Date()).format(dateFormat),
          GsubmissionDateChars: moment(new Date()).format(dateFormat),
          Lio_NumTransmissions: 1,
          Li_ContinuationEndDate: 1
        })
        this.formRef.current?.setFieldsValue({
          GcreationDateChars: moment(new Date()).format(dateFormat),
          GsubmissionDateChars: moment(new Date()).format(dateFormat),
          Lio_NumTransmissions: this.state.Lio_NumTransmissions,
          Li_ContinuationEndDate: 1,
          Lio_OutputDocument: res[0].format
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingTable: false }));
  }

  XmlCreate() {
    let stsM40 = 0;
    const GcreationDateChars = this.formRef.current?.getFieldValue('GcreationDateChars');
    const GsubmissionDateChars = this.formRef.current?.getFieldValue('GsubmissionDateChars');
    const Lio_NumTransmissions = this.formRef.current?.getFieldValue('Lio_NumTransmissions');
    if (!this.formRef.current?.getFieldValue('Lio_OutputDocument')) {
      stsM40 = 1
      message.error('帳票を選択してください')
      return
    }
    if (!GcreationDateChars) {
      message.error('作成日を入力してください')
      return
    } else if (!GsubmissionDateChars) {
      message.error('提出日を入力してください')
      return
    }
    if (GsubmissionDateChars < GcreationDateChars) {
      message.error('提出日が作成日より前の日付になっています。')
      return
    }
    if (!Lio_NumTransmissions) {
      message.error('送信回数を入力してください')
      return
    } else if (Lio_NumTransmissions <= 0 || Lio_NumTransmissions > 10) {
      message.error('送信回数は1～10を指定して下さい。')
      return
    }
    let param = {
      StsM40: stsM40,
      GsubmissionDateChars: this.state.GsubmissionDateChars,
      GcreationDateChars: this.state.GcreationDateChars,
      Lio_FilingDate: this.state.GsubmissionDateChars,
      Lio_CreationDate: this.state.GcreationDateChars,
      Lio_Output: this.state.Lio_Output,
      Lio_NumTransmissions: Lio_NumTransmissions,
      Lo_StsOutput: this.state.StsOutput,
      Lio_ProcessDivision: this.state.ProcessDivision,
      GoutputForm: this.formRef.current?.getFieldValue('Lio_OutputDocument'),
      Li_ContinuationEndDate: this.state.Li_ContinuationEndDate
    }
    XmlOutputConfirmAction.XmlCreate(param)
      .then(res => {
        if (res !== undefined) {
          param.Lo_StsOutput = res.Lo_StsOutput;
          param.Lio_ProcessDivision = res.Lio_ProcessDivision;
          param.Lio_Output = res.Lio_Output
          this.props.onFinishScreen(param)
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
        message.error(res.data.message);
      })
  }

  // チェックボタン処理をコメントアウトしているためコメント
  // XmlCheck() {
  //   let stsM40 = 0;
  //   const xmlDataDefault = this.state.xmlData.find(item => item.format === this.formRef.current?.getFieldValue('Lio_OutputDocument'));
  //   if (xmlDataDefault === undefined) {
  //     stsM40 = 1
  //   }
  //   let param = {
  //     StsM40: stsM40,
  //     GsubmissionDateChars: this.state.GsubmissionDateChars,
  //     GcreationDateChars: this.state.GcreationDateChars,
  //     Lio_FilingDate: this.state.GsubmissionDateChars,
  //     Lio_CreationDate: this.state.GcreationDateChars,
  //     Lio_Output: this.state.Lio_Output,
  //     Lio_NumTransmissions: this.state.Lio_NumTransmissions,
  //     Lo_StsOutput: this.state.StsOutput,
  //     Lio_ProcessDivision: this.state.ProcessDivision,
  //     GoutputForm: xmlDataDefault ? xmlDataDefault.format : '',
  //     Li_ContinuationEndDate: this.state.Li_ContinuationEndDate
  //   }
  //   XmlOutputConfirmAction.XmlCheck(param)
  //     .then(res => {
  //       if (res !== undefined) {
  //         param.Lo_StsOutput = res.Lo_StsOutput;
  //         param.Lio_ProcessDivision = res.Lio_ProcessDivision;
  //         param.Lio_Output = res.Lio_Output
  //         this.props.onFinishScreen(param)
  //       }
  //     }).catch(error => {
  //       const res = error.response;
  //       if (!res || res.data || res.data.message) {
  //         message.error('エラーが発生しました');
  //       }
  //       message.error(res.data.message);
  //     })
  // }

  onChangeGcreationDateChars(event) {
    let date = event;
    if (date === null) {
      this.setState({
        GcreationDateChars: ''
      })
      this.formRef.current?.setFieldsValue({
        GcreationDateChars: null
      })
    } else {
      this.setState({
        GcreationDateChars: date.format(dateFormat)
      })
      this.formRef.current?.setFieldsValue({
        GcreationDateChars: date.format(dateFormat)
      })
    }
  }

  onChangeGsubmissionDateChars(event) {
    let date = event;
    if (date === null) {
      this.setState({
        GsubmissionDateChars: ''
      })
      this.formRef.current?.setFieldsValue({
        GsubmissionDateChars: null
      })
    } else {
      this.setState({
        GsubmissionDateChars: date.format(dateFormat)
      })
      this.formRef.current?.setFieldsValue({
        GsubmissionDateChars: date.format(dateFormat)
      })
    }
  }

  changeContinuationEndDate(event) {
    let ContinuationEndDate = event;
    this.formRef.current?.setFieldsValue({
      Li_ContinuationEndDate: ContinuationEndDate
    })
    this.setState({
      Li_ContinuationEndDate: ContinuationEndDate
    })
  }

  onChangeNumTransmissions(event) {
    let NumTransmissions = event.target.value;
    this.formRef.current?.setFieldsValue({
      Lio_NumTransmissions: NumTransmissions
    })
    this.setState({
      Lio_NumTransmissions: NumTransmissions
    })
  }

  show_WS1291001() {
    this.setState({
      childModal: {
        visible: true,
        width: 600,
        component: (
          <WS1291001_XmlDocumentQuerySelectSub
            Li_SpecificAndHealthGuideAndAll={2}
            xmlData={this.state.xmlData}
            xmlDataDefault={this.state.xmlDataDefault}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                Lio_OutputDocument: output.recordData.format
              });
              this.setState({
                ...this.state,
                xmlDataDefault: output.recordData
              });
              this.closeModal();
            }}
          />
        )
      }
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onFinish(values) {
  }

  render() {
    return (
      <div className="output-confirm">
        <Card title="出力確認">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space style={{ display: "flex", overflow: "hidden" }}>
              <Form.Item
                name="Lio_OutputDocument"
                label='帳票'
                {...smGrid}
              >
                <Input.Search
                  style={{ width: "150px" }}
                  type="text"
                  onSearch={() => { this.show_WS1291001() }}
                />
              </Form.Item>
              {this.state.xmlDataDefault && (
                <Form.Item>
                  <span> {this.state.xmlDataDefault.Expresstion_5}</span>
                </Form.Item>
              )}
            </Space>

            <Space style={{ display: "flex" }}>
              <Form.Item
                name="Li_ContinuationEndDate"
                label='終了日'
                {...smGrid}
              >
                <Select onChange={(event) => this.changeContinuationEndDate(event)}>
                  <Select.Option value={1}>最終評価</Select.Option>
                  <Select.Option value={2}>継続最終</Select.Option>
                </Select>
              </Form.Item>
            </Space>

            <Space style={{ display: "flex" }}>
              <Form.Item
                name="GcreationDateChars"
                label='作成日'
                {...smGrid}
              >
                <VenusDatePickerCustom formRefDatePicker={this.formRef} format={dateFormat} onChange={(event) => this.onChangeGcreationDateChars(event)} />
              </Form.Item>
            </Space>

            <Space style={{ display: "flex" }}>
              <Form.Item
                name="GsubmissionDateChars"
                label='提出日'
                {...smGrid}
              >
                <VenusDatePickerCustom formRefDatePicker={this.formRef} format={dateFormat} onChange={(event) => this.onChangeGsubmissionDateChars(event)} />
              </Form.Item>
            </Space>

            <Space style={{ display: "flex" }}>
              <Form.Item
                name="Lio_NumTransmissions"
                label='送信回数'
                {...smGrid}
              >
                <Input type="number" style={{ width: "50px", textAlign: 'right' }} onChange={(event) => this.onChangeNumTransmissions(event)} />
              </Form.Item>
              <Space style={{ paddingBottom: "5px" }}> 回目</Space>
            </Space>

            {/* 出力先を選択しないため */}
            {/* <Space style={{ display: "flex" }}>
              <Space  style={{ width: "50px",justifyContent: "flex-end",color: "#14468C",fontWeight: "bold" }} >
                出力先
              </Space>
              <Form.Item  name="Lio_Output" ><Input type="text" /></Form.Item>
            </Space> */}

            <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>

              {/* チェックのtxtを作る必要がないため */}
              {/* <Form.Item>  <Button type="primary" onClick={() => this.XmlCheck()}>チェック</Button>  </Form.Item> */}

              <Form.Item>
                <Button
                  icon={<FormOutlined />}
                  type="primary"
                  onClick={() => this.XmlCreate()}
                >
                  <span className='btn_label'>作成</span>
                </Button>
              </Form.Item>
            </Space>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2656096_OutputConfirm);
