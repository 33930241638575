import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, Modal, message, Input, Button, InputNumber } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons';
import {
  getDataDetermineLevelBulkModifyAction, saveDataDetermineLevelBulkModifyAction, deleteDataDetermineLevelBulkModifyAction
} from "redux/InspectionMaintenance/DetermineLevelModify/DetermineLevelBulkModify.actions";
import WS0448009_CopyScreen from "./WS0448009_CopyScreen";
import WS0448500_DetermineLevelBulkModifyEdit from 'pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0448500_DetermineLevelBulkModifyEdit'

const styleInput = {
  border: 'none'
}
class WS0448001_DetermineLevelBulkModify extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS05800:判定レベル一括修正';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: true,
      rowSelect: {},
      copyFlag: false
    };
  }

  componentDidMount = () => {
    this.getDataDetermineLevelBulkModify();
  }

  getDataDetermineLevelBulkModify = () => {
    this.setState({ isLoading: true });
    getDataDetermineLevelBulkModifyAction()
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res.data,
          })
        }
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  saveDataDetermineLevelBulkModify = (record) => {
    saveDataDetermineLevelBulkModifyAction(record).then(res => {
      message.success('成功');
      this.getDataDetermineLevelBulkModify();
    })
      .catch(err => message.error(err.response.data.message))
  }

  deleteDataDetermineLevelBulkModify = (record) => {
    if (record.id) {
      deleteDataDetermineLevelBulkModifyAction({ id: record.id })
        .then(res => {
          message.success('成功');
          this.getDataDetermineLevelBulkModify();
        })
        .catch(err => message.error(err.response.data.message ?? 'エラー'))
    } else {
      let arrTemp = [...this.state.dataSource];
      arrTemp.splice(arrTemp[0], 1);
      this.setState({ dataSource: arrTemp });
    }
  }

  /**
   * 複写画面
   */
  Copy_F12 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0448009_CopyScreen
            Li_Condition2F={this.state.rowSelect.condition_2}
            item={this.state.rowSelect.item}
            ItemList={this.state.dataSource}
            onFinishScreen={(isLoad) => {
              if (isLoad) {
                this.getDataDetermineLevelBulkModify();
                this.props.onFinishScreen()
              }
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  handleChangeInput = (event, record) => {
    let { value, name } = event.target;
    let arrTemp = [...this.state.dataSource];
    let index = arrTemp.indexOf(record);
    if (index !== -1) {
      let objTemp = {
        ...record,
        [name]: value
      }
      arrTemp[index] = objTemp
      this.setState({ dataSource: arrTemp })
    }
  }

  /**
  * 編集画面
  * @param {*} record 
  */
  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0448500_DetermineLevelBulkModifyEdit
            Li_EditFlag={true}
            record={record}
            onSave={(output) => {
              this.saveDataDetermineLevelBulkModify(output)
              this.props.onFinishScreen()
              this.closeModal()
            }}
            onDelete={(output) => {
              this.deleteDataDetermineLevelBulkModify(record)
              this.props.onFinishScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 追加
   */
  addForm() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0448500_DetermineLevelBulkModifyEdit
            Li_EditFlag={false}
            record={''}
            onSave={(output) => {
              this.saveDataDetermineLevelBulkModify(output)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className="determine-level-bulk-modify" >
        <Card title="判定レベル一括修正">
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            // rowSelection={{
            //   type: 'radio',
            //   onChange: async (selectedRowKeys, selectedRows) => {
            //     await this.setState({
            //       rowSelect: selectedRows[0],
            //       copyFlag: true
            //     })
            //   }
            // }}
            rowClassName={(record, index) => record === this.state.rowSelect ? 'table-row-light' : ''}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    rowSelect: record,
                    copyFlag: true
                  })
                }
              }
            }}
          >
            <Table.Column title="レベル" width={60} dataIndex="condition_2" />
            <Table.Column title="名称" dataIndex="item" />
            <Table.Column title="備考" dataIndex="remarks" />
            <Table.Column
              width={60}
              align="center"
              title={<Button type="primary"
                icon={<PlusOutlined />}
                onClick={() => this.addForm()}
                size='small'
              />}
              render={(value, record, index) => (
                <Button
                  size="small"
                  icon={<MoreOutlined />}
                  onClick={() => { this.editForm(record) }}
                />
              )}
            />
          </Table>
          <div style={{ float: 'right', marginTop: '1rem' }}>
            <Button disabled={!this.state.copyFlag} type='primary' onClick={this.Copy_F12}>複写</Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0448001_DetermineLevelBulkModify);
