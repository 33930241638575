import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Button, Checkbox, message, Tooltip } from 'antd'
import { SearchOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons'
import KartecoCooperationCsvOutputAction from 'redux/Others/KartecoCooperationCsvOutput/KartecoCooperationCsvOutput.action.js'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS2788013_TargetSelectSub from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS2788013_TargetSelectSub.jsx'
import WS3127002_OutputConfirm from 'pages/ZZ_Others/MDV01000_KartecoCooperationCsvOutput/WS3127002_OutputConfirm.jsx'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { download_file_binary } from 'helpers/CommonHelpers'
import moment from 'moment'

class WS3127001_KartecoCooperationCsvOutput extends React.Component {
  static propTypes = {
    DateChar: PropTypes.any
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'カルテコ連携CSV出力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: true,
      isLoadingPage: false,
      Expression_32: 163,
      message: '',
      isSearch: false,

      OutputPath: '',
      StsExamineeCsv: true,
      StsResultDataCsv: true,
      StsAnonymousProcessing: false,
      StsRapidVersion: false,
      ConfirmedProgressCode: null,
      ResultProgressCode: null,
      selectedRows: [],
      conditionAddData: null,
    }

  }

  componentDidMount() {
    this.index()
  }

  /**
   * 初期処理
   */
  index() {
    KartecoCooperationCsvOutputAction.index()
      .then((res) => {
        if (res) {
          this.formRef.current?.setFieldsValue({
            'DateFChar': moment(res.DateFChar) ?? '',
            'DateTChar': moment(res.DateTChar) ?? '',
            'StsConfirm': res.StsConfirm ?? false,
          })
          this.setState({
            OutputPath: res.OutputPath,
            StsExamineeCsv: res.StsExamineeCsv,
            StsResultDataCsv: res.StsResultDataCsv,
            StsAnonymousProcessing: res.StsAnonymousProcessing,
            StsRapidVersion: res.StsRapidVersion,
            ConfirmedProgressCode: res.ConfirmedProgressCode,
            ResultProgressCode: res.ResultProgressCode,
          })
        }
      })
  }

  /**
   * [検索]ボタン押下処理
   */
  eventSearchButton() {
    let values = this.formRef.current?.getFieldValue()
    const params = {
      DateF: moment(values.DateFChar).format('YYYY/MM/DD'),
      DateFChar: moment(values.DateFChar).format('YYYY/MM/DD'),
      DateT: moment(values.DateTChar).format('YYYY/MM/DD'),
      DateTChar: moment(values.DateTChar).format('YYYY/MM/DD'),
      StsConfirm: values.StsConfirm ? 1 : 0,
      conditionAddData: this.state.conditionAddData,
      ConfirmedProgressCode: this.state.ConfirmedProgressCode,//確定進捗コード
    }
    KartecoCooperationCsvOutputAction.eventSearchButton(params)
      .then((res) => {
        if (res) {
          console.log(res)
          this.loadData(res.message)
          this.setState({
            dataSource: res.visitData,
          })
        }
      })

    this.loadData(params)
  }

  /**
   * [開く] ボタン押下処理
   */
  eventOpenButton() {
    // this.state.OutputPath
  }

  eventCsvCounter(params) {
    KartecoCooperationCsvOutputAction.eventCsvButton(params)
      .then((res) => {
        if (res) {
          // ダウンロード
          this.downloadZipFile(res.outputPath)
          this.eventCsvButton()
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
        message.error(res.data.message)
      })
  }

  /**
   * [CSV]ボタン押下処理
   * @param {子画面からのパラメータ} output
   */
  eventCsvButton(output, index) {
    let selectedRows = this.state.selectedRows
    let values = this.formRef.current?.getFieldValue()
    let data = selectedRows.slice(index, index + 100)
    let nextIndex = index + 100
    let params = {
      ...values,
      ...output,
      selectedRows: data,
      StsAnonymousProcessing: this.state.StsAnonymousProcessing,
      StsRapidVersion: this.state.StsRapidVersion,
      ResultProgressCode: this.state.ResultProgressCode,
    }
    KartecoCooperationCsvOutputAction.eventCsvButton(params)
      .then((res) => {
        if (res) {
          // ダウンロード
          this.downloadZipFile(res.outputPath, selectedRows, nextIndex, output)
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
        message.error(res.data.message)
      })
  }

  /**
   * ZIPファイル ダウンロード
   * @param {*} path
   */
  downloadZipFile(path, selectedRows, nextIndex, output) {
    let params = {
      filePath: path
    }
    KartecoCooperationCsvOutputAction.downloadZipFile(params)
      .then((res) => {
        if (res) {
          // ZIPファイルダウンロード
          download_file_binary(res)
          if (selectedRows.length > nextIndex) {
            this.eventCsvButton(output, nextIndex)
          }
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
        message.error(res.data.message)
      })

  }

  loadData = (message) => {
    this.setState({
      message: message,
      isLoading: false,
      isSearch: true
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='karteco-cooperation-csv-output'>
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Card title='カルテコ連携CSV出力'>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='DateFChar' label={'受診日'} >
                        <VenusDatePickerCustom formRefDatePicker={this.formRef} format={'YYYY/MM/DD'} style={{ width: 110 }} />
                      </Form.Item>
                      <Form.Item>
                        <span>~</span>
                      </Form.Item>
                      <Form.Item name='DateTChar' >
                        <VenusDatePickerCustom formRefDatePicker={this.formRef} format={'YYYY/MM/DD'} style={{ width: 110 }} />
                      </Form.Item>
                    </div>
                    <Form.Item name='StsConfirm' label={'確　定'} valuePropName='checked' >
                      <Checkbox />
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.eventSearchButton()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                    <Tooltip title='詳細な条件追加'>
                      <Button
                        size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component:
                                <WS2786001_ConditionAddSub
                                  Li_DateF={this.formRef.current.getFieldValue('DateFChar')}
                                  Li_DateT={this.formRef.current.getFieldValue('DateTChar')}
                                  Li_ConditionAddData={this.state.conditionAddData}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      KeyInfo: output.Lio_KeyInfo ? output.Lio_KeyInfo : ''
                                    })
                                    this.setState({
                                      conditionAddData: output.conditionAddData
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                      >
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <WS2788013_TargetSelectSub
                isSearch={this.state.isSearch}
                message={this.state.message}
                visitData={this.state.dataSource}
                params={this.formRef.current?.getFieldValue()}
                PrintType={1}
                onSelectedData={(output) => {
                  this.setState({ selectedRows: output })
                }}
              />
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<DownloadOutlined />}
                disabled={!this.state.isSearch}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 500,
                      component:
                        <WS3127002_OutputConfirm
                          OutputPath={this.state.OutputPath}
                          StsExamineeCsv={this.state.StsExamineeCsv}
                          StsResultDataCsv={this.state.StsResultDataCsv}
                          onFinishScreen={(output) => {
                            this.eventCsvButton(output, 0)
                            this.closeModal()
                          }}
                        />
                      ,
                    },
                  })
                }}
              >
                <span className='btn_label'>
                  CSV出力
                </span>
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3127001_KartecoCooperationCsvOutput)
