import CopyService from 'services/InspectionMaintenance/InspectValueCalculateAutoMaintain/CopyService'
import { message } from 'antd'

const CopyAction = {
  index(params) {
    return CopyService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  copyRegister(params) {
    return CopyService.copyRegister(params)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

}
export default CopyAction
