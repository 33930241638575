import React from "react";
import { connect } from "react-redux";

import { Card, Form, Radio, Button, Row, Col, } from "antd";

class WS1518007_SelectSubject extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '選択対象';

    this.state = {
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="select-subject">
        <Card title="選択対象">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Form.Item
                name="SelectMethod" style={{justifyContent:'space-around'}}
                label="選択方法"
              >
                <Radio.Group >
                  <Radio.Button style={{width:'100px'}} value="1">初期</Radio.Button>
                  <Radio.Button style={{width:'100px'}} value="2">全て</Radio.Button>
                  <Radio.Button style={{width:'100px'}} value="3">選択なし</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row gutter={24} style={{width:'100%'}}>
              <Col span={12}>
                <Form.Item
              >
                <Button type="primary" style={{marginLeft:'30px'}}>閉じる</Button>
              </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
              >
                <Button type="primary" style={{float:'right',marginRight:'30px'}} >実行</Button>
              </Form.Item>
              </Col>
                         
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1518007_SelectSubject);
