import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Button,
  Table,
  Tooltip,
  Dropdown,
  Menu,
  Select,
  Modal
} from "antd";
import {
  MoreOutlined,
  PlusOutlined,
  MenuOutlined
} from "@ant-design/icons";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import WS3401002_AutoJudgmentNameSetting from 'pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401002_AutoJudgmentNameSetting.jsx'
import AutoJudgmentManagementAction from 'redux/SM_SystemMaintenance/AutoJudgmentManagement/AutoJudgmentManagement.action.js'
import WS3401004_JudgmentStorageSetting from 'pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401004_JudgmentStorageSetting.jsx'
import WS3401005_JudgmentDetailSetting from 'pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401005_JudgmentDetailSetting.jsx'

class WS3401001_AutoJudgmentManagement extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '資料一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      autoJudgmentNameList: [],
      selectedJudgmentNameCode: ''
    }
  }

  componentDidMount = () => {
    this.getAutoJudgmentNameList()
  }

  getAutoJudgmentNameList = () => {
    AutoJudgmentManagementAction.getAutoJudgmentNameList()
      .then(res => {
        if (res.length > 0) {
          this.formRef.current?.setFieldsValue({
            autoJudgmentNameList: res[0].judgment_name_code
          })
          this.setState({
            selectedJudgmentNameCode: res[0].judgment_name_code,
            autoJudgmentNameList: res
          })
          this.getJudgmentStorageData(res[0].judgment_name_code)
        }
      })
  }

  getJudgmentStorageData = (judgmentNameCode = null) => {
    let params = {
      judgment_name_code: judgmentNameCode ? judgmentNameCode : this.state.selectedJudgmentNameCode
    }
    AutoJudgmentManagementAction.getJudgmentStorageData(params)
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  openAutoJudgmentNameSetting = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: false,
        component: (
          <WS3401002_AutoJudgmentNameSetting />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  openJudgmentStorageSetting = (newFlag = false, record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: false,
        component: (
          <WS3401004_JudgmentStorageSetting
            selectedJudgmentNameCode={this.state.selectedJudgmentNameCode}
            newFlag={newFlag}
            record={record}
            onFinishScreen={() => {
              this.getJudgmentStorageData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  deleteJudgmentStorageSetting = (record) => {
    let params = {
      id: record.id
    }
    AutoJudgmentManagementAction.deleteJudgmentStorageSetting(params)
      .then(res => {
        this.getJudgmentStorageData()
      })
  }

  openJudgmentDetailSetting = (record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: false,
        component: (
          <WS3401005_JudgmentDetailSetting
            judgment_name_code={this.state.selectedJudgmentNameCode}
            inspection_code={record.inspection_code ?? ''}
            onFinishScreen={() => {
              this.getJudgmentStorageData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className="auto-judgment-management">
        <Card title='自動判定管理'>
          <Form ref={this.formRef}>
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className={(this.props.ImplementDateChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                trigger='click'
                size='small'
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key='1'
                        style={{ width: '100%' }}
                        onClick={() => {
                          this.openAutoJudgmentNameSetting()
                        }}
                      >
                        自動判定名設定
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className={(this.props.ImplementDateChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>
            <div className="box_container">
              <Form.Item name='autoJudgmentNameList'>
                <Select
                  style={{ width: 300 }}
                  onChange={(value) => {
                    this.setState({
                      selectedJudgmentNameCode: value
                    })
                    this.getJudgmentStorageData(value)
                  }}
                >
                  {this.state.autoJudgmentNameList?.map(value => (
                    <Select.Option
                      key={value.id}
                      value={value.judgment_name_code}
                    >
                      {value.judgment_name}
                    </Select.Option>
                  ))
                  }
                </Select>
              </Form.Item>
              <Table
                bordered
                dataSource={this.state.dataSource}
                loading={false}
                size='small'
                pagination={false}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(180) }}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () => { this.openJudgmentDetailSetting(record) }
                })}
              >
                <Table.Column
                  className="column-size-4"
                  title="No"
                  dataIndex="display_number"
                />
                <Table.Column
                  className="column-size-10"
                  title="検査コード"
                  dataIndex="inspection_code"
                />
                <Table.Column
                  className="column-size-40"
                  title="検査略名"
                  dataIndex="inspection_short_name"
                />
                <Table.Column
                  className="column-size-60"
                  title="検査名称"
                  dataIndex="inspection_name"
                />
                <Table.Column
                  width={45}
                  title="無効"
                  dataIndex="invalid_flag"
                  render={(value, record) => (
                    <div style={{ textAlign: 'center' }}>{value === 1 ? '✓' : ''}</div>
                  )}
                />
                <Table.Column
                  className="column-size-20"
                  title="開始日"
                  dataIndex="start_date"
                />
                <Table.Column
                  className="column-size-20"
                  title="終了日"
                  dataIndex="end_date"
                />
                <Table.Column
                  width={40}
                  align='center'
                  fixed='right'
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => { this.openJudgmentStorageSetting(true) }}
                    />
                  }
                  render={(value, record) => (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='編集'
                            onClick={() => this.openJudgmentStorageSetting(false, record)}
                          >
                            編集
                          </Menu.Item>
                          <Menu.Item
                            key='詳細'
                            onClick={() => this.openJudgmentDetailSetting(record)}
                          >
                            詳細
                          </Menu.Item>
                          <Menu.Item
                            key='削除'
                            onClick={() => {
                              Modal.confirm({
                                content: '削除しますか？',
                                onOk: () => this.deleteJudgmentStorageSetting(record)
                              })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />} />
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3401001_AutoJudgmentManagement);