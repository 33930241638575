import React from 'react'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import { connect } from 'react-redux'
import { Form, Button, Table, message, Modal, Tooltip, Input, Dropdown, Menu } from 'antd'
import GetImage from 'constants/Images'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0730001_FindingsInputPhysiciiagnosis from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx'
import WS0729001_FindingsInputRadiography from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729001_FindingsInputRadiography.jsx'
import WS0728001_FindingsInputNormal from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0728001_FindingsInputNormal.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import recalculate from 'components/Commons/recalculate'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import InspectionResultsChangeHistory from 'pages/IN_InputBusiness/SANAI_InterviewSupport/InspectionResultsChangeHistory.jsx'
import moment from 'moment'

// 文字サイズ
const resultFontSize = '20px'
class DetailResultDisplayView extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }
  // formRef = React.createRef() //親画面のthis.props.formRefを使用する

  constructor(props) {
    super(props)

    // document.title = '検査別結果一覧 照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      inspectResultList: [],
      changeData: [],
      categoryJudgeColorNum: '',
      categoryJudgmentChangeFlag: false,
      categoryCommentChangeFlag: false,

      disabledUpdateBtn: true,
      user: '',
      categorys: '',
      initialDisplayFlag: true,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getInspectResultListView()
  }

  /**
   * レコード（検査値）が変更（編集）された時の処理
   * @param {*} prevProps
   * @param {*} prevProps
   */
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.record) {
      if (this.props.record !== prevProps.record) {
        this.getInspectResultListView(true)
      }
    }
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user
      })
    }
    if (this.state.disabledUpdateBtn !== prevState.disabledUpdateBtn) {
      if (this.props.onDisabledUpdateBtn) {
        // 保存ボタンの活性変更
        this.props.onDisabledUpdateBtn(this.state.disabledUpdateBtn)
      }
    }
  }

  /**
   * カテゴリ別の詳細な検査結果一覧を取得
   */
  getInspectResultListView = (changeFlg) => {
    // 親画面から引数取得
    const record = { ...this.props.record }
    const values = { ...this.props.values }

    let SelectCategoryList = ''
    const HistoryAdded = values.HistoryAdded //履歴
    const NonInput = values.NonInput
    const Outliers = values.Outliers
    const NonInputOrOutliers = NonInput === 1 ? { NonInput } : { Outliers }
    const NormalJudge = values.NormalJudge

    const params = {
      ...this.props.paramObject,
      categorys: (this.props.record?.category_code !== this.state.category_code) ? '' : this.state.categorys,
      Li_CategoryCode: record.category_code,
      StsInputModeInspect: values.StsInputModeInspect,
      StsInputModeFindings: values.StsInputModeFindings,
      SelectCategoryList: SelectCategoryList,
      Li_JudgeLevel: record.Li_JudgeLevel,
      SelectCategory: record.SelectCategory,
      UndeterminedColorOp: values.UndeterminedColorOp,
      StsParkModeCategoryUpAndDown: values.StsParkModeCategoryUpAndDown,
      StsInputModeInspectCmtChiba: values.StsInputModeInspectCmtChiba,
      HistoryAdded,
      ...NonInputOrOutliers,
      NormalJudge,
      CategoryDisplayPosition: values.CategoryDisplayPosition,
      sex: values.sex,
      birthday_on: values.birthday_on,
      visit_date_on: values.visit_date_on,
      Li_DateOfBirth: values.Li_DateOfBirth,
      pre1ReserveNum: values.pre1ReserveNum ?? '',
      pre2ReserveNum: values.pre2ReserveNum ?? '',
      pre3ReserveNum: values.pre3ReserveNum ?? '',
      pre4ReserveNum: values.pre4ReserveNum ?? '',
    }
    ResultDisplayAction.getInspectResultListView(params)
      .then((res) => {
        if (res) {
          // カテゴリが切り替わった場合、categorysを初期化
          if (this.props.record?.category_code !== this.state.category_code) {
            this.setState({ categorys: '' })
          }

          this.setState({
            inspectResultList: res,
            changeData: res,
            disabledUpdateBtn: true,
            categoryJudgmentChangeFlag: false,
            categoryJudgeColorNum: this.props.record?.categoryJudgeColorNum,
            categoryCommentChangeFlag: false,
            category_code: this.props.record?.category_code,
          })

          this.props.formRef?.current?.setFieldsValue({
            formInspectResultList: res,
            category_judge: this.props.record?.category_judge,
            category_comment: this.props.record?.category_comment,
          })
          this.forceUpdate()
          // カテゴリ判定の文字色設定
          this.changeInputSearchStyle('categoryJudgeInput', this.props.record?.categoryJudgeColorNum, false)
          // 親画面のカテゴリ情報を更新
          if (changeFlg && this.props.onUpdateCategory) {
            this.props.onUpdateCategory()
          }

          // 前回DOを押されたとき
          if (this.props.record.previousDo) {
            // 全てに対して更新をかける可能性があるため、遅くなる可能性がある
            for (let i = 0; i < res.length; i++) {
              // 前回値がなかった場合は空にする
              if (this.props.setPreviousInspectDo.length > 0) {
                for (let k = 0; k < this.props.setPreviousInspectDo.length; k++) {
                  if (res[i].exam_code === this.props.setPreviousInspectDo[k].exam_code) {
                    this.changeResultValue(this.props.setPreviousInspectDo[k].result_value, res[i])

                    // タイプが「所見」の場合
                    if (this.props.setPreviousInspectDo[k].exam_type === 'S') {
                      for (let m = 0; m < this.props.setPreviousCommentDo.length; m++) {
                        if (this.props.setPreviousInspectDo[k].exam_code == this.props.setPreviousCommentDo[m].exam_code) {
                          this.changeFindingsResultValue(this.props.setPreviousCommentDo[m].findings_name, res[i])
                        }
                      }
                    }
                  }
                }
              } else {
                this.changeResultValue('', res[i])
                this.changeFindingsResultValue('', res[i])
              }
            }
          }
        }
      })
  }

  /**
   * データチェック
   * @param {*} value
   * @param {*} type
   * @returns
   */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  getRawValue = (name) => this.props.formRef?.current?.getFieldValue(name)

  /**
   * suffixResultValue のパラメータを取得
   * @param {*} record
   * @returns
   */
  getSuffixResultValueParams = (record) => {

    const params = {
      id: this.checkData(record.id, 'number'),
      display_no: this.checkData(this.props.record?.display_no, 'number'), //カテゴリ
      display_order: this.checkData(record.display_order, 'number'), //検査
      Li_Gender: this.props.values.Li_Gender,
      Li_DateOfBirth: this.props.values.Li_DateOfBirth,
      judgment_value: this.checkData(record.judgment_value, 'text'),
      result_value: this.checkData(record.result_value, 'text'),
      remarks: this.checkData(record.remarks, 'text'),
      StsAutomaticCalculate: this.checkData(record.StsAutomaticCalculate, 'number'),
      exam_code: this.checkData(record.exam_code, 'number'),
      visit_date_on: this.props.values.visit_date_on,
      judgment_code: this.checkData(record.judgment_code, 'number'),
      NormalJudge: this.getRawValue('NormalJudge'),
      UpAndDown: this.checkData(record.UpAndDown, 'text'),
      display_remark: this.checkData(record.display_remark, 'text'),
    }
    return params
  }

  /**
   * 所見のコメントを取る
   * @param {*} value
   * @param {*} record
   */
  getResultValue(value, record) {
    let params = {
      Lio_CmtClassify: record.exam_comment_code,
      value: value
    }
    OverallResultDisplayInputAction.getResultValue(params)
      .then((res) => {
        this.changeResultValue(res?.exam_comment_screen, record)
        // 所見値が変更になった場合
        this.changeFindingsResultValue(res?.exam_comment_form, record)
      })
  }


  /**
   * 編集用のモーダルを表示する
   * @param {*} record
   */
  showEditModal = (record) => {
    // ロックされていない受診者だけ
    if (!this.props.protectFlag) {
      // if (record.StsInputModeInspectCmtChiba === 1 && record.textPositionExamType && record.StsInspectCmtInfo) { ※いるかも！
      if (record.textPositionExamType && record.StsInspectCmtInfo) {
        // 検査コメント検索・照会　モーダル 大体はこのモーダルを使用
        this.showWS0274001_InspectCmtSearchQuery(record)
        // this.showWS0274002_InspectCmtSearchInput(record)

      } else if (record.exam_type.slice(0, 1) === 'N') {
        // 直接入力系（N系）は何も呼ばない
        message.info('入力フォームに直接入力してください')
        return

        // 数値系結果値入力 モーダル
        // this.showWS2637015_InspectResultValueInput(record)

      } else {
        // ※三愛の面談支援では所見関連のモーダルは表示させずに直接入力させる！
        // 検査コメント照会以外(所見関連)のモーダル
        // 検査によって表示する編集用モーダルを切り替える
        // this.switchEditModal(record)
      }
    }
  }

  /**
   * 検査コメント検索・照会　モーダルを開く
   */
  showWS0274001_InspectCmtSearchQuery = (record) => {
    // コメント区分(exam_comment_code)が0の場合はモーダルは表示しない
    if ((record.exam_comment_code ?? 0) > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: '50%',
          component: (
            <WS0274001_InspectCmtSearchQuery
              Li_PatternCode={this.props.paramObject.Li_PatternCode}
              Li_CategoryCode={record.category_code}
              Li_CategoryName={record.category_name}
              Lio_StsNotesChange={record.StsNotesChange}
              Lio_CmtClassify={record.exam_comment_code}
              LnkOutInspectCmtScreen={record.result_value}
              onFinishScreen={(output) => {
                // 選択された検査コメントを反映
                const value = output.LnkOutInspectCmtScreen

                // 結果値が変更になった場合
                this.changeResultValue(value, record)

                // タイプが「所見」の場合
                if (record.exam_type === 'S') {
                  // 備考：exam_comment_form
                  const findingsResultValue = output.recordData.exam_comment_form
                  // 所見値が変更になった場合
                  this.changeFindingsResultValue(findingsResultValue, record)
                }

                // モーダルを閉じる
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  /**
   *　検査コメント照会　モーダル
   * @param {*} record
   */
  // showWS0274002_InspectCmtSearchInput = (record) => {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: 500,
  //       component:
  //         <WS0274002_InspectCmtSearchInput
  //           Li_ReserveNum={this.props.paramObject.Li_ReserveNum}
  //           Li_InspectCode={record.exam_code}
  //           Li_PatternCode={this.props.paramObject.Li_PatternCode}
  //           Li_CategoryCode={record.category_code}
  //           Li_CategoryName={record.category_name}
  //           Lio_StsNotesChange={record.StsNotesChange}
  //           Lio_CmtClassify={record.exam_comment_code}
  //           Lio_JudgmentValue={record.judgment_value}
  //           LnkOutInspectCmtScreen={record.result_value}
  //           onUpdateResultValue={() => {
  //             // 検査結果一覧を再取得
  //             this.getInspectResultList()
  //             // モーダルを閉じる
  //             this.closeModal()
  //           }}
  //         />
  //       ,
  //     },
  //   })
  // }

  /**
   *　結果値入力　モーダル
   * @param {*} record
   */
  // showWS2637015_InspectResultValueInput = (record) => {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: 300,
  //       component:
  //         <WS2637015_InspectResultValueInput
  //           Li_ReserveNum={this.props.paramObject.Li_ReserveNum}
  //           Li_InspectCode={record.exam_code}
  //           Li_PatternCode={this.props.paramObject.Li_PatternCode}
  //           Li_CategoryCode={record.category_code}
  //           Lio_JudgmentValue={record.judgment_value}
  //           Lio_ResultValue={record.result_value}
  //           onUpdateResultValue={() => {
  //             // 検査結果一覧を再取得
  //             this.getInspectResultList()
  //             // モーダルを閉じる
  //             this.closeModal()
  //           }}
  //         />
  //       ,
  //     },
  //   })
  // }

  /**
   *
   * @param {*} record
   */
  switchEditModal = (record) => {
    const params = {
      ...this.props.paramObject,
      Li_JudgeLevel: record.Li_JudgeLevel,
      display_order: record.display_order,
      display_no: record.display_no,
      pattern_code: record.pattern_code,
      category_code: record.category_code,
      personal_number_id: this.props.values.personal_number_id,
      exam_code: record.exam_code,
      GuideHowToAddOp: this.props.values.GuideHowToAddOp,
      StsInputModeFindings: this.props.values.StsInputModeFindings,
    }

    // exam_typeが「S」である場合
    OverallResultDisplayInputAction.findingsEditingBefore(params)
      .then((res) => {
        if (res) {
          const messagex = res.message ?? ''
          const variables = res.variables ?? null
          if (this.props.values.StsInputModeFindings === 1) {

            if (messagex === 'Call Screen WS0730001') {
              // 所見入力[医師診断]
              this.showWS0730001_FindingsInputPhysiciiagnosis(variables, record)
            }
            if (messagex === 'Call Screen WS0729001') {
              // 所見入力[読影]
              this.showWS0729001_FindingsInputRadiography(variables, record)
            }
            if (messagex === 'Call Screen WS0731001' || messagex === 'Call Screen WS0728001') {
              // 所見入力[通常]
              this.showWS0728001_FindingsInputNormal(variables, record)

              // ※「WS0731001_FindingsInputNormalSelect」は「WS0728001_FindingsInputNormal」と同じレイアウト、処理になるので統一する
              // 所見入力[通常選択]
              // this.showWS0731001_FindingsInputNormalSelect(variables, record)
            }
          }
        }
      })
  }

  /**
   * 所見入力[医師診断]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0730001_FindingsInputPhysiciiagnosis = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            user={this.props.user}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultListView()
            }}
          />
        ),
      },
    })
  }

  /**
   * 所見入力[読影]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0729001_FindingsInputRadiography(variables, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0729001_FindingsInputRadiography
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            user={this.props.user}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultListView()
            }}
          />
        ),
      },
    })
  }

  /**
 * 所見入力[通常]　モーダル
 * @param {*} variables
 * @param {*} record
 */
  showWS0728001_FindingsInputNormal = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0728001_FindingsInputNormal
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            user={this.props.user}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultListView(true)
            }}
          />
        ),
      },
    })
  }

  /**
  * 判定選択　(検査コード毎用)
  * @param {*} data
  */
  showWS0285001_JudgeQueryInspect = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {
              if (output.recordData) {
                this.changeJudgmentValue(output.recordData.judgment_result, record)
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   *　判定一覧モーダルを開く（カテゴリ用）
   * @param {*} data
   * @param {*} index
   */
  showWS0285001_JudgeQueryCategory = () => {
    const data = { ...this.props.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={data?.judgment_level_division}
            Lio_Judge={data?.category_judge}
            onFinishScreen={async (output) => {
              if (output.recordData) {
                console.log(output.recordData)
                let judgmentResult = output.recordData.judgment_result

                // カテゴリ判定を保存
                this.props.formRef.current?.setFieldsValue({
                  category_judge: judgmentResult,
                })
                // カテゴリ判定の文字色設定
                this.changeInputSearchStyle('categoryJudgeInput', output.recordData?.judgmentColorNum, true)

                await this.setState({
                  categoryJudgmentChangeFlag: true,
                  categoryJudgeColorNum: output.recordData?.judgmentColorNum
                })
                await this.changeJudgmentValue(null, null, true)

              }
              this.closeModal()
            }
            }
          />
        ),
      },
    })
  }

  /**
   * 結果値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeResultValue(value, record) {
    record.inspectComments.map((element, index) => {
      let newElement = element.exam_comment_code.trim()
      if (value == newElement) {
        value = element.exam_comment_screen.trim()
      }
    })

    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (exam_type.slice(0, 1) === 'N' && value !== '') {
      // exam_typeが「N、N1、N2、N3」の場合
      resultValue = recalculate(exam_type, value)
    } else {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.result_value !== value) {
        // 検査結果値の設定
        element.result_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    this.props.onChangeData({
      changeData: copyData,
      category_comment: this.props.formRef.current?.getFieldValue('category_comment'),
      category_judge: this.props.formRef.current?.getFieldValue('category_judge'),
    })
    // <Table>にも反映
    this.props.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }

  /**
   * 所見値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeFindingsResultValue(value, record) {
    record.inspectComments.map((element, index) => {
      let newElement = element.exam_comment_code.trim()
      if (value == newElement) {
        value = element.exam_comment_screen.trim()
      }
    })

    let copyData = [...this.state.changeData]
    let id = record?.id
    let resultValue
    let disabledChange = false

    // それ以外
    resultValue = value

    copyData.forEach(element => {
      if (element.id === id && element.findingsResultValue !== value) {
        // 検査結果値の設定
        element.findingsResultValue = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    this.props.onChangeData({
      changeData: copyData,
      category_comment: this.props.formRef.current?.getFieldValue('category_comment'),
      category_judge: this.props.formRef.current?.getFieldValue('category_judge'),
    })
    // <Table>にも反映
    this.props.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }

  /**
   * 検査ごとの判定値が変更になった場合
   * @param {*} event
   * @param {*} record
   * @param {*} disabledChange
   * @param {*} commentChange //カテゴリのコメントが変更された時
   */
  changeJudgmentValue(value, record, disabledChange = false, commentChange = false) {

    if (commentChange) {
      // カテゴリ - コメント変更フラグ
      this.setState({
        categoryCommentChangeFlag: true
      })
    }

    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue

    if (value !== '') {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.judgment_value !== value) {
        // 検査結果値の設定
        element.judgment_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    this.props.onChangeData({
      changeData: copyData,
      category_comment: this.props.formRef.current?.getFieldValue('category_comment'),
      category_judge: this.props.formRef.current?.getFieldValue('category_judge'),
      categoryJudgmentChangeFlag: this.state.categoryJudgmentChangeFlag,
      categoryJudgeColorNum: this.state.categoryJudgeColorNum
    })
    // <Table>にも反映
    this.props.formRef.current?.setFieldsValue({
      formInspectResultList: copyData,
    })
  }

  /**
   * 前回の検査結果を反映する
   * @param {*} selectedRecord
   */
  setPreviousExamCodeDo = (selectedRecord) => {
    const pre1ReserveNum = this.props.values.pre1ReserveNum

    if (pre1ReserveNum === '' || pre1ReserveNum === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果 [${selectedRecord.inspect_name}] を反映します。よろしいですか？`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
            PreviousReserveNum: pre1ReserveNum,
            Li_ExamCode: record?.exam_code,
            Li_ExamType: record?.exam_type,
          }
          OverallResultDisplayInputAction.setPreviousExamCodeDo(params)
            .then((res) => {
              // 前回DOを反映
              let copyData = [...this.state.inspectResultList]
              // 前回値がなかった場合は空にする
              if (res.dt0420InsertList.length > 0) {
                for (let i = 0; i < copyData.length; i++) {
                  for (let k = 0; k < res.dt0420InsertList.length; k++) {
                    if (res.dt0420InsertList[k].exam_code === copyData[i]?.exam_code) {
                      this.changeResultValue(res.dt0420InsertList[k].result_value, copyData[i])

                      // タイプが「所見」の場合
                      if (res.dt0420InsertList[k].exam_type === 'S') {
                        if (res.dt0430InsertList.length > 0) {
                          for (let m = 0; m < res.dt0430InsertList.length; m++) {
                            if (res.dt0420InsertList[k].exam_code == res.dt0430InsertList[m].exam_code) {
                              this.changeFindingsResultValue(res.dt0430InsertList[m].findings_name, copyData[i])
                            }
                          }
                        } else {
                          this.changeFindingsResultValue('', copyData[i])
                        }
                      }
                    }
                  }
                }
              } else {
                this.changeResultValue('', record)
                this.changeFindingsResultValue('', record)
              }
              this.closeModal()
            })
        }
      })
    }
  }

  /**
   * 変更履歴を表示する
   */
  showInspectionResultsChangeHistory = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <InspectionResultsChangeHistory
            reservationNumber={this.props.Li_ReserveNum}
            examCode={record.exam_code}
          />
        ,
      },
    })
  }

  /**
   * <Input.Search>のstyle設定
   * @param {*} id <Input.Search>のid
   * @param {*} colorNum 色番号
   * @param {*} changeFlg 変更かどうか
   */
  changeInputSearchStyle = (id, colorNum, changeFlg) => {
    // <Input.Search>のstyleにてcolor設定ができなかった為、getElementByIdでドキュメント要素を取得する
    let target = document.getElementById(id)
    if (target != null) {
      // 文字色設定
      target.style.color = Color(colorNum)?.Foreground
      // フォントの太さ
      target.style.fontWeight = changeFlg ? 'bold' : 'normal'
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='detail-result-display'>

        {/* Warning回避のため、<Form>をコメントアウト。親画面の this.props.formRefを使用*/}
        {/* <Form
          ref={this.formRef}
          autoComplete='off'
        > */}

        <div className='box_container'>
          <div className='box_inner_horizontal'>
            <Form.Item
              name='category_judge'
              label='カテゴリ判定'
              style={{ marginBottom: 0 }}
            >
              <Input.Search
                id='categoryJudgeInput' //getElementByIdの為にid設定
                // disabled={this.props.protectFlag ? true : this.props.paramObject.displayAll == 1 ? true : this.props.paramObject.bloodTest == 1 ? true : false}
                // onSearch={() => this.showWS0285001_JudgeQueryCategory()}
                disabled={true}
                style={{ width: 100 }}
                readOnly
              />
            </Form.Item>
            <Form.Item
              name='category_comment'
              label='コメント'
              style={{ marginBottom: 0, width: 'calc(100% - 414px)' }}
            >
              <Input
                style={{ width: '100%', fontWeight: (this.state.categoryCommentChangeFlag) ? 'bold' : '', }}
                disabled={this.props.paramObject.displayAll == 1 ? true : this.props.paramObject.bloodTest == 1 ? true : false}
                // onPressEnter={() => this.changeJudgmentValue(null, null, true, true)}
                // onBlur={() => this.changeJudgmentValue(null, null, true, true)}
                readOnly
              />
            </Form.Item>
            <Button
              style={{
                color: this.state.categorys !== 'bloodTest' ? '' : '#00AF00',
                borderColor: this.state.categorys !== 'bloodTest' ? '' : '#00AF00'
              }}
              onClick={async () => {
                // if ((this.state.categorys !== 'bloodTest')) {
                //   await this.setState({
                //     categorys: 'bloodTest'
                //   })
                // } else {
                //   await this.setState({
                //     categorys: '',
                //   })
                // }

                // this.getInspectResultListView()
              }}
              disabled
            >
              採血一覧
            </Button>
            <Button
              style={{
                color: this.state.categorys !== 'primaryCareDoctor' ? '' : '#00AF00',
                borderColor: this.state.categorys !== 'primaryCareDoctor' ? '' : '#00AF00'
              }}
              onClick={async () => {
                // if ((this.state.categorys !== 'primaryCareDoctor')) {
                //   await this.setState({
                //     categorys: 'primaryCareDoctor'
                //   })
                // } else {
                //   await this.setState({
                //     categorys: '',
                //   })
                // }
                // this.getInspectResultListView()
              }}
              disabled
            >
              かかりつけ医
            </Button>
          </div>

          <Table
            bordered
            size='small'
            id={'DetailResultDisplay'}
            dataSource={this.state.inspectResultList}
            rowKey={record => record?.id}
            pagination={false}
            scroll={{ x: 'max-content', y: resizableTableScroll(60, 'DetailResultDisplay') }}
            onRow={(record, rowIndex) => {
              return {
              }
            }}
            rowClassName={(record, index) =>
              record.dt0420_id ? '' : 'table-row-no-accepted'
            }
          >
            <Table.Column
              dataIndex='inspect_name'
              title='検査名称'
              className='inspect-table'
              style={{ width: '5rem' }}
              render={(value, record, index) => (
                <Tooltip title={record?.inspectNameTooltip}>
                  <div style={{ textAlign: 'left' }}>
                    <div style={{
                      marginLeft: 5,
                      fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : '',
                      fontSize: resultFontSize
                    }}>
                      {(record?.imageNum === '') ?
                        null
                        :
                        <img src={GetImage(record?.imageNum)} alt='icon' />
                      }
                      {value}
                    </div>
                  </div>
                </Tooltip>
              )}
            />

            <Table.Column
              dataIndex='normal_value'
              title='基準値'
              className='inspect-table'
              style={{ width: '5rem' }}
              render={(item, record, index) => (
                <div style={{ textAlign: 'center', fontSize: resultFontSize }}>
                  {record.normal_value}
                  {record.dataConfirmFlag === 0 ?
                    null
                    :
                    <span
                      style={{
                        float: 'right',
                        fontWeight: record?.resultValueFontWeight === 82 ? 'bold' : '',
                        fontSize: resultFontSize
                      }}>
                      *
                    </span>
                  }
                </div>
              )}
            />

            <Table.Column
              dataIndex='judgment_value'
              title='判'
              width={50}
              align='center'
              className='this-time'
              render={(value, record, index) => (
                <Tooltip
                  overlayStyle={{ whiteSpace: 'pre-line' }} //「\n」で改行させる
                  title={record.valueTooltip}
                >
                  {(!record.dt0420_id) ?
                    // dt0420のテーブルに存在してない検査項目の場合、編集不可
                    <div />
                    :
                    (record.exam_type === 'S') ?
                      // 所見 (モーダルを開いて値を変更)
                      <div
                        style={{
                          textAlign: 'center',
                          fontWeight: (record?.resultValueFontWeight === 82 || (record.change_flg ?? 0)) ? 'bold' : '',
                          fontSize: resultFontSize,
                          color: Color(record.judgmentValueColorNum)?.Foreground,
                          border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {value}
                      </div>
                      :

                      <div
                        onDoubleClick={() => {
                          // if (!this.props.protectFlag) {
                          //   // 編集画面の表示処理
                          //   this.showWS0285001_JudgeQueryInspect(record)
                          // }
                        }}
                        style={{
                          textAlign: 'center',
                          fontWeight: (record?.resultValueFontWeight === 82 || (record.change_flg ?? 0)) ? 'bold' : '',//変更時は太字
                          fontSize: resultFontSize,
                          color: Color(record.judgmentValueColorNum)?.Foreground,
                          border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                          whiteSpace: 'pre-wrap',
                          cursor: 'pointer'
                        }}
                      >
                        {value ? value : '　'}
                      </div>
                  }
                </Tooltip>
              )}
            />

            <Table.Column
              className='this-time'
              dataIndex='result_value'
              title={
                <Tooltip
                  title={
                    <div className='box_inner_vertical'>
                      <div>{moment(this.props.values.DateChar).format('YYYY/MM/DD')}</div>
                      <div>{this.props.values?.contract_short_name}</div>
                    </div>
                  }
                >
                  <div style={{ whiteSpace: 'pre-wrap' }}>{'今回'}</div>
                </Tooltip>
              }
              // width={500}
              render={(value, record, index) => (
                < Tooltip
                  overlayStyle={{ whiteSpace: 'pre-line' }} //「\n」で改行させる
                  title={record.valueTooltip}
                >
                  {(!record.dt0420_id) ?
                    // dt0420のテーブルに存在してない検査項目の場合、編集不可
                    <div />
                    :
                    (record.exam_type === 'S' || (this.props.protectFlag && (record.exam_type.slice(0, 1) !== 'N'))) ?
                      // 所見 (モーダルを開いて値を変更)
                      // または、ロック時 かつ exam_typeが「N」以外
                      (!this.props.protectFlag) ?
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            // gap: 3,
                            cursor: 'pointer',
                            color: Color(record.upAndDownColorNum)?.Foreground,
                          }}
                        >
                          <Form.Item
                            label=''
                            name={['formInspectResultList', index, 'result_value']}
                            style={{ marginBottom: 0 }}
                          >
                            <Input.Search
                              className={(record.change_flg ?? 0) ? 'input-search-size-code-bold' : 'input-search-size-code'}
                              // onSearch={() => {
                              //   // 検査コメントコード照会
                              //   this.showWS0274001_InspectCmtSearchQuery(record)
                              // }}
                              // onPressEnter={(e) => this.getResultValue(e.target.value, record)}
                              // onBlur={(e) => this.changeResultValue(e.target.value, record)}
                              disabled={true}
                              readOnly
                            />
                          </Form.Item>

                          <Tooltip
                            title={this.state.inspectResultList[index].findingsResultValue}
                            overlayStyle={{ maxWidth: 700, fontSize: 25 }}
                          >
                            <Form.Item
                              label=''
                              name={['formInspectResultList', index, 'findingsResultValue']}
                              className='input-size-30'
                              style={{ marginBottom: 0 }}
                            >
                              <Input
                                id={'spreadInput-2-' + record.id + '-' + record.category_code}
                                type='tel'
                                onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', 1, true, 0)}
                                // onPressEnter={(e) => this.changeFindingsResultValue(e.target.value, record)}
                                // onBlur={(e) => this.changeFindingsResultValue(e.target.value, record)}
                                style={{
                                  fontWeight: (record.change_flg ?? 0) ? 'bold' : 'normal', //変更時は太字
                                  fontSize: resultFontSize,
                                  height: '32px',
                                }}
                                readOnly
                              />
                            </Form.Item>
                          </Tooltip>
                        </div>
                        :
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                            cursor: 'pointer',
                            color: Color(record.upAndDownColorNum)?.Foreground,
                            fontSize: resultFontSize,
                          }}
                        >
                          <div>{record.result_value}</div>
                          <div>{record.findingsResultValue}</div>
                        </div>
                      :
                      ((record.StsAutomaticCalculate && !record.StsManualInput) || ((record.exam_type.slice(0, 1) === 'N') && this.props.protectFlag)) ?
                        // 自動計算あり（BMIや基準体重など）入力なし
                        // または、ロック時 かつ exam_typeが「N」
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              width: '30px',
                              cursor: 'pointer',
                              color: Color(record.upAndDownColorNum)?.Foreground,
                              textAlign: 'center',
                              fontSize: resultFontSize,
                            }}>
                            {record.UpAndDown}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', width: '100%' }}>
                            <div
                              style={{
                                color: Color(record.resultValueColorNum)?.Foreground,
                                width: '6rem',
                                textAlign: 'right',
                                fontSize: resultFontSize,
                              }}
                            >
                              {value}
                            </div>
                          </div>
                        </div>
                        :
                        // 値の変更、入力あり
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onDoubleClick={() => {
                            // 編集画面の表示処理
                            // this.showEditModal(record)
                          }}
                        >
                          <div
                            style={{
                              display: (record.UpAndDown !== '') ? '' : 'none',
                              width: '30px',
                              cursor: 'pointer',
                              color: Color(record.upAndDownColorNum)?.Foreground,
                              textAlign: 'center',
                              fontSize: resultFontSize,
                            }}>
                            {record.UpAndDown}
                          </div>
                          <Form.Item
                            label=''
                            name={['formInspectResultList', index, 'result_value']}
                            style={{ marginBottom: 0, width: '100%' }}
                          >
                            <Input
                              id={'spreadInput-2-' + record.id + '-' + record.category_code}
                              type='tel'
                              // onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', 1, true, 0)}
                              // onPressEnter={(e) => this.changeResultValue(e.target.value, record)}
                              // onBlur={(e) => this.changeResultValue(e.target.value, record)}
                              style={{
                                textAlign: (record.exam_type.slice(0, 1) === 'N') ? 'right' : 'left',
                                fontWeight: (record.change_flg ?? 0) ? 'bold' : 'normal', //変更時は太字
                                fontSize: resultFontSize,
                                height: '32px',
                                backgroundColor: value ? '#ffffff' : '#FCD07E'
                              }}
                              readOnly
                            />
                          </Form.Item>
                        </div>
                  }
                </Tooltip>
              )}
            />

            <Table.Column
              dataIndex='pre_1_judgment_value'
              title='判'
              width={50}
              align='center'
              className='inspect-table'
              render={(value, record) => (
                <div
                  style={{
                    textAlign: 'center',
                    color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                    fontSize: resultFontSize,
                  }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              className='inspect-table'
              width={'5rem'}
              // dataIndex='pre1ResultValueFindings'
              title={
                <Tooltip
                  title={
                    <div className='box_inner_vertical'>
                      <div>{this.props.values?.pre1VisitDate}</div>
                      <div>{this.props.values?.pre1CourseName}</div>
                    </div>
                  }
                >
                  <div style={{ whiteSpace: 'pre-wrap' }}>{'前回'}</div>
                </Tooltip>
              }
              render={(value, record) => (
                <div style={{ display: 'flex' }}>
                  {(record.exam_type.slice(0, 1) === 'N') ?
                    //exam_type:N or N1 以外は非表示
                    <div
                      style={{
                        color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                        width: '7rem',
                        textAlign: 'right',
                        fontSize: resultFontSize,
                      }}>
                      {record.exam_type === 'S' ? record.pre1ResultValueFindings : record.pre_1_result_value}
                    </div>
                    :
                    <Tooltip
                      overlayStyle={record.exam_type === 'S' ? { maxWidth: 700, fontSize: 25 } : { maxWidth: 500, fontSize: 18 }}
                      title={record.exam_type === 'S' ? record.pre1ResultValueFindings : record.pre_1_result_value}
                    >
                      <div
                        style={{
                          maxWidth: 250,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                          fontSize: resultFontSize,
                        }}>
                        {record.exam_type === 'S' ? record.pre1ResultValueFindings : record.pre_1_result_value}
                      </div>
                    </Tooltip>
                  }
                </div>
              )}
            />

            <Table.Column
              dataIndex='pre_2_judgment_value'
              className='inspect-table'
              title='判'
              width={50}
              align='center'
              render={(value, record) => (
                <div
                  style={{
                    textAlign: 'center',
                    color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                    fontSize: resultFontSize,
                  }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              className='inspect-table'
              width={'5rem'}
              // dataIndex='pre2ResultValueFindings'
              title={
                <Tooltip
                  title={
                    <div className='box_inner_vertical'>
                      <div>{this.props.values?.pre2VisitDate}</div>
                      <div>{this.props.values?.pre2CourseName}</div>
                    </div>
                  }
                >
                  <div style={{ whiteSpace: 'pre-wrap' }}>{'前々回'}</div>
                </Tooltip>
              }
              render={(value, record) => (
                <div style={{ display: 'flex' }}>
                  {(record.exam_type.slice(0, 1) === 'N') ?
                    //exam_type:N or N1 以外は非表示
                    <div
                      style={{
                        color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                        width: '7rem',
                        textAlign: 'right',
                        fontSize: resultFontSize,
                      }}>
                      {record.exam_type === 'S' ? record.pre2ResultValueFindings : record.pre_2_result_value}
                    </div>
                    :
                    <Tooltip
                      overlayStyle={record.exam_type === 'S' ? { maxWidth: 700, fontSize: 25 } : { maxWidth: 500, fontSize: 18 }}
                      title={record.exam_type === 'S' ? record.pre2ResultValueFindings : record.pre_2_result_value}
                    >
                      <div
                        style={{
                          maxWidth: 250,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                          fontSize: resultFontSize,
                        }}>
                        {record.exam_type === 'S' ? record.pre2ResultValueFindings : record.pre_2_result_value}
                      </div>
                    </Tooltip>
                  }
                </div>
              )}
            />

            <Table.Column
              dataIndex='pre_3_judgment_value'
              className='inspect-table'
              title='判'
              width={50}
              align='center'
              render={(value, record) => (
                <div
                  style={{
                    textAlign: 'center',
                    color: Color(record.pre3JudgmentValueColorNum)?.Foreground,
                    fontSize: resultFontSize,
                  }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              className='inspect-table'
              width={'5rem'}
              dataIndex='pre3ResultValueFindings'
              title={
                <Tooltip
                  title={
                    <div className='box_inner_vertical'>
                      <div>{this.props.values?.pre3VisitDate}</div>
                      <div>{this.props.values?.pre3CourseName}</div>
                    </div>
                  }
                >
                  <div style={{ whiteSpace: 'pre-wrap' }}>{'3回前'}</div>
                </Tooltip>
              }
              render={(value, record) => (
                <div style={{ display: 'flex' }}>
                  {(record.exam_type.slice(0, 1) === 'N') ?
                    //exam_type:N or N1 以外は非表示
                    <div
                      style={{
                        color: Color(record.pre3JudgmentValueColorNum)?.Foreground,
                        width: '7rem',
                        textAlign: 'right',
                        fontSize: resultFontSize,
                      }}>
                      {record.exam_type === 'S' ? record.pre3ResultValueFindings : record.pre_3_result_value}
                    </div>
                    :
                    <Tooltip
                      overlayStyle={record.exam_type === 'S' ? { maxWidth: 700, fontSize: 25 } : { maxWidth: 500, fontSize: 18 }}
                      title={record.exam_type === 'S' ? record.pre3ResultValueFindings : record.pre_3_result_value}
                    >
                      <div
                        style={{
                          maxWidth: 250,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: Color(record.pre3JudgmentValueColorNum)?.Foreground,
                          fontSize: resultFontSize,
                        }}>
                        {record.exam_type === 'S' ? record.pre3ResultValueFindings : record.pre_3_result_value}
                      </div>
                    </Tooltip>
                  }
                </div>
              )}
            />

            <Table.Column
              dataIndex='pre_4_judgment_value'
              className='inspect-table'
              title='判'
              width={50}
              align='center'
              render={(value, record) => (
                <div
                  style={{
                    textAlign: 'center',
                    color: Color(record.pre4JudgmentValueColorNum)?.Foreground,
                    fontSize: resultFontSize,
                  }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              className='inspect-table'
              width={'5rem'}
              dataIndex='pre4ResultValueFindings'
              title={
                <Tooltip
                  title={
                    <div className='box_inner_vertical'>
                      <div>{this.props.values?.pre4VisitDate}</div>
                      <div>{this.props.values?.pre4CourseName}</div>
                    </div>
                  }
                >
                  <div style={{ whiteSpace: 'pre-wrap' }}>{'4回前'}</div>
                </Tooltip>
              }
              render={(value, record) => (
                <div style={{ display: 'flex' }}>
                  {(record.exam_type.slice(0, 1) === 'N') ?
                    //exam_type:N or N1 以外は非表示
                    <div
                      style={{
                        color: Color(record.pre4JudgmentValueColorNum)?.Foreground,
                        width: '7rem',
                        textAlign: 'right',
                        fontSize: resultFontSize,
                      }}>
                      {record.exam_type === 'S' ? record.pre4ResultValueFindings : record.pre_4_result_value}
                    </div>
                    :
                    <Tooltip
                      overlayStyle={record.exam_type === 'S' ? { maxWidth: 700, fontSize: 25 } : { maxWidth: 500, fontSize: 18 }}
                      title={record.exam_type === 'S' ? record.pre4ResultValueFindings : record.pre_4_result_value}
                    >
                      <div
                        style={{
                          maxWidth: 250,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: Color(record.pre4JudgmentValueColorNum)?.Foreground,
                          fontSize: resultFontSize,
                        }}>
                        {record.exam_type === 'S' ? record.pre4ResultValueFindings : record.pre_4_result_value}
                      </div>
                    </Tooltip>
                  }
                </div>
              )}
            />


            {/* {(!this.props.protectFlag) ?
              <Table.Column
                className='inspect-table'
                width={40}
                align='center'
                fixed='right'
                render={(value, record, index) => (
                  (record.dt0420_id) ?
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                            onClick={() => {
                              // 編集画面の表示処理
                              // this.showEditModal(record)
                            }}
                          >
                            編集
                          </Menu.Item>
                          <Menu.Item
                            key='2'
                            disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                            onClick={() => {
                              // this.setPreviousExamCodeDo(record)
                            }}
                          >
                            前回DO
                          </Menu.Item>
                          <Menu.Item
                            key='3'
                            disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                            onClick={() => {
                              // this.showInspectionResultsChangeHistory(record)
                            }}
                          >
                            変更履歴
                          </Menu.Item>
                          <Menu.Item
                            key='4'
                            hidden={record.exam_type === 'S' ? false : true}
                            onClick={() => {
                              // this.setState({
                              //   childModal: {
                              //     ...this.state.childModal,
                              //     visible: true,
                              //     width: 'fit-content',
                              //     component: (
                              //       <ExtensionInputDisplay
                              //         findings_name={record.findingsResultValue}
                              //         onFinishScreen={(output) => {
                              //           this.changeFindingsResultValue(output.result, record)
                              //           this.closeModal()
                              //         }}
                              //       />
                              //     ),
                              //   },
                              // })
                            }}
                          >
                            入力拡張
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                    :
                    // dt0420のテーブルに存在してない検査項目の場合、編集不可
                    null
                )} />
              :
              //ロック時は非表示
              null
            } */}
          </Table>
        </div >

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailResultDisplayView)
