import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Space, message, Tooltip, Dropdown, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import CautionGuideNotesSearchQueryAction from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/CautionGuideNotesSearchQuery.actions'
import WS2701001_CautionGuideMatterCmtMaintain from 'pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS2701001_CautionGuideMatterCmtMaintain.jsx'
class WS0272001_CautionGuideNotesSearchQuery extends React.Component {
  static propTypes = {
    Li_PatternCode: PropTypes.any,
    Li_CategoryCode: PropTypes.any,
    Li_IdentifyCode: PropTypes.any,
    LnkOutCmtCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '注意・指導事項検索・照会'

    this.state = {
      CategoryCode: [],
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      Search: this.props.Li_Search ?? ''
    })

    let params = {
      Li_IdentifyCode: this.props.Li_IdentifyCode ?? '',
      Li_PatternCode: this.props.Li_PatternCode ?? ''
    }
    CautionGuideNotesSearchQueryAction.index(params)
      .then(res => {
        this.setState({
          CategoryCode: res?.CategoryCode
        })
        this.formRef.current?.setFieldsValue({
          CategoryCode: res?.CategoryCode?.[0]?.LinkedField
        })
        this.getGuideItemInfo()
      })
  }

  /**
   * 指導事項内容の一覧を取得
   */
  getGuideItemInfo() {
    this.setState({ isloadding: true })
    let params = {
      Li_IdentifyCode: this.props.Li_IdentifyCode ?? '',
      Search: this.formRef.current?.getFieldValue('Search'),
      Li_CategoryCode: this.formRef.current?.getFieldValue('CategoryCode'),
      GuidanceContentKeyword: this.formRef.current?.getFieldValue('GuideContentKeyword')
    }
    CautionGuideNotesSearchQueryAction.getGuideItemInfo(params)
      .then(res => {
        this.setState({
          dataSource: res
        })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }

      }).finally(() => this.setState({ isloadding: false }))
  }

  /**
   * 注意・指導事項コメント保守 モーダル
   */
  showCautionGuideMatterCmtMaintain() {
    this.setState({
      ...this.state,
      childModal: {
        width: '70%',
        visible: true,
        component: (
          <WS2701001_CautionGuideMatterCmtMaintain
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />)
      }
    })
  }

  Calculation() {
    let params = {
      Li_IdentifyCode: this.props.Li_IdentifyCode ?? '',
      Li_CategoryCode: this.formRef.current?.getFieldValue('CategoryCode'),
    }

    CautionGuideNotesSearchQueryAction.Calculation(params)
      .then(res => {
        this.getGuideItemInfo()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='caution-guide-notes-search-query'>
        <Card title='注意・指導事項検索・照会'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top_modal'
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.Calculation()}
                    >
                      使用回数再計算
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                style={{ marginTop: -4 }}
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>


          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{ Search: '', CategoryCode: '', GuideContentKeyword: '' }}
          >
            <div className='box_inner_horizontal' style={{ alignItems: 'start', gap: 20 }}>
              <Form.Item
                name='Search'
                label='検索キー'>
                <Input
                  maxLength={20}
                  onPressEnter={() => this.getGuideItemInfo()}
                />
              </Form.Item>

              <Form.Item
                name='GuideContentKeyword'
                label='コメント内容'>
                <Input
                  maxLength={20}
                  onPressEnter={() => this.getGuideItemInfo()}
                />
              </Form.Item>

              <Form.Item
                name='CategoryCode'
                label='カテゴリ'>
                <Select
                  onChange={() => this.getGuideItemInfo()}
                  style={{ width: 150 }}
                >
                  {this.state.CategoryCode?.map(value => (
                    <Select.Option
                      key={'CategoryCode-' + Math.random()}
                      value={value.LinkedField}
                    >
                      {value.DisplayField}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Table
              bordered
              dataSource={this.state.dataSource}
              size='small'
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: '60vh' }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    if (this.props.onFinishScreen) {
                      this.props.onFinishScreen({
                        Lo_LnkOutCmtCode: record.comment_code,
                        Lo_recordData: record
                      })
                    }
                  }
                }
              }}
            >
              <Table.Column
                title='コード'
                dataIndex='comment_code'
                width={100}
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />

              <Table.Column
                title='検索キー'
                dataIndex='search_key'
                width={120}
              />

              <Table.Column
                title='コメント内容'
                dataIndex='comment_content'
              />

              <Table.Column
                title='使用回数'
                dataIndex='number_of_times_of_use'
                width={100}
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />

              <Table.Column
                title='最終使用日'
                dataIndex='last_used_on'
                width={110}
              />
            </Table>

            <Space style={{ float: 'right', marginTop: '1em' }}>
              <Button
                type='primary'
                onClick={() => this.showCautionGuideMatterCmtMaintain()}
              >
                保守
              </Button>
            </Space>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0272001_CautionGuideNotesSearchQuery)
