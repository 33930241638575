import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Modal, Button } from "antd";
import { DeleteOutlined, SaveOutlined, } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0397001_ParamPromptedQuerySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx';
import WS2592016_ParamPromptedQueryContent from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS2592016_ParamPromptedQueryContent.jsx';
import WS0640003_Format from "./WS0640003_Format";
const labelCol = {
  labelCol: { style: { width: '110px' } }
}

class WS0640005_NewAddChangeEscortTableEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      change: true
    }
  }

  componentDidMount() {
    let data
    if (this.props.newflag) {
      data = { Expansion: false, DayWeek: false }
    } else {
      // 親画面のrecordをコピー
      data = {
        ...this.props.record,
      }
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
  * データ保存
  */
  onFinish = (values) => {
    this.props.onUpdate(values)
  }

  /**
  * データ削除
  * @param {*} id
  */
  delete = () => {
    this.props.onDelete()
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  ShowParamPromptedQueryContent() {
    this.setState({
      ...this.state,
      childModal: {
        width: '40%',
        visible: true,
        component:
          <WS2592016_ParamPromptedQueryContent
            Li_Format={'Y'}
            Li_IndicationDivision={this.formRef.current?.getFieldValue('medical_exam_course')}
            onFinishScreen={(output) => {
              this.formRef?.current?.setFieldsValue({
                'escort_code': output.item
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
      }
    });
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={"エスコート保守 [" + (this.props.newflag ? '新規登録]' : '変更]')} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='エスコート'
                name='escort_code'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  className="input-size-number-4"
                  maxLength={3}
                />
              </Form.Item>


              <Form.Item
                label='エスコート名称'
                name='escort_name'//対応したものに書き換え
              >
                <Input />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newflag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form >
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0640005_NewAddChangeEscortTableEdit);
