import { message } from 'antd'
import FollowUpLetterReturnInputService from 'services/IntroductionLetter/FollowUpLetter/FollowUpLetterReturnInputService'

const FollowUpLetterReturnInputAction = {
  index(data) {
    return FollowUpLetterReturnInputService.index(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getDataInputSub(data) {
    return FollowUpLetterReturnInputService.getDataInputSub(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(data) {
    return FollowUpLetterReturnInputService.save(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveDetail(data) {
    return FollowUpLetterReturnInputService.saveDetail(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  delete(data) {
    return FollowUpLetterReturnInputService.delete(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default FollowUpLetterReturnInputAction
