import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PassingManageProgressAction from 'redux/Others/PassingManageProgress/PassingManageProgress.action'
import { Form, Card, Table, Button, message, Checkbox, Tooltip, Dropdown, Menu } from 'antd'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import { MenuOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS3059005_TerminalList from 'pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059005_TerminalList.jsx'
import WS3059008_PassingList from 'pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059008_PassingList.jsx'

const titleStyle = { writingMode: 'vertical-rl', marginLeft: -2 }
class WS3059001_PassingManageProgressMock extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '通過管理進捗状況(モック_DevExtreme)'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      ImplementDateChar: '',
      PassingNumList: '',
      PassingNameList: '',
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    let date = ''
    if (this.props.ImplementDateChar) {
      date = this.props.ImplementDateChar
    }

    let param = {
      PassingNumList: '',
      PassingNameList: '',
      ImplementDate: date,
    }
    PassingManageProgressAction.index(param)
      .then(async (res) => {
        await this.formRef.current?.setFieldsValue({
          ImplementDateChar: moment(res.ImplementDateChar),
          StsMore: res.StsMore
        })
        await this.setState({
          PassingNumList: res.PassingNumList,
          ImplementDateChar: res.ImplementDateChar,
          PassingNameList: res.PassingNameList,
        })
        await this.getStatusList(res.ImplementDateChar, res.StsMore)
      })
  }

  /**
   * 再表示
   */
  redisplay() {
    let date = this.formRef.current.getFieldValue('ImplementDateChar') ? moment(this.formRef.current.getFieldValue('ImplementDateChar')).format('YYYY/MM/DD') : ''
    let more = this.formRef.current.getFieldValue('StsMore')
    this.getStatusList(date, more)
  }

  /**
   * 全表示の変更
   * @param {*} event
   */
  changeAllShow(event) {
    let date = this.formRef.current.getFieldValue('ImplementDateChar') ? moment(this.formRef.current.getFieldValue('ImplementDateChar')).format('YYYY/MM/DD') : ''
    let more = event.target.checked
    this.getStatusList(date, more)
  }

  /**
   * 実施日の変更
   * @param {*} event
   */
  changeImplementpDate(event) {
    if (event === null) {
      this.formRef.current?.setFieldsValue({ ImplementDateChar: null })
    } else {
      let date = event.format('YYYY/MM/DD')
      let more = this.formRef.current.getFieldValue('StsMore')
      this.setState({ ImplementDateChar: date })

      this.formRef.current?.setFieldsValue({ ImplementDateChar: moment(date) })
      this.getStatusList(date, more)
    }
  }

  /**
   * データ一覧取得
   * @param {*} date
   * @param {*} more
   */
  getStatusList(date, more) {
    let param = {
      Li_PassingNumList: this.state.PassingNumList,
      Li_ImplementDate: date,
      Li_More: (more) ? 1 : 0,
    }
    PassingManageProgressAction.getStatusList(param)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res,
          })
        }
      })
  }

  /**
   * 端末項目 モーダル
   */
  showWS3059005_TerminalList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS3059005_TerminalList
            ImplementDateChar={this.state.ImplementDateChar}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 通過項目 モーダル
   */
  showWS3059008_PassingList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS3059008_PassingList
            ImplementDateChar={this.state.ImplementDateChar}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const format = 'YYYY/MM/DD'
    return (
      <div className='passing-manage-progress'>
        <Card
          id='passing-manage-progress-mok'
          title='通過管理進捗状況（モック）'
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.ImplementDateChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      disabled={!(this.formRef.current?.getFieldValue('ImplementDateChar') ?? false)}
                      onClick={() => {
                        this.showWS3059005_TerminalList()
                      }}
                    >
                      端末項目
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      disabled={!(this.formRef.current?.getFieldValue('ImplementDateChar') ?? false)}
                      onClick={() => {
                        this.showWS3059008_PassingList()
                      }}
                    >
                      通過項目
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.ImplementDateChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form ref={this.formRef}>
              <div className='box_inner_horizontal' style={{ alignItems: 'center' }}>
                <div className='box_search'>
                  <Form.Item
                    name='ImplementDateChar'
                    label='実施日'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      onChange={(event) => this.changeImplementpDate(event)}
                    />
                  </Form.Item>

                  <Form.Item
                    name='StsMore'
                    valuePropName='checked'
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox
                      onChange={(event) => this.changeAllShow(event)}
                    >
                      全表示
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button
                  type='primary'
                  onClick={() => this.redisplay()}>
                  再表示
                </Button>
              </div>
            </Form>

            {/* DevExtremeのDataGrid */}
            <DataGrid
              style={{ height: 700 }}
              dataSource={this.state.dataSource}
              showBorders={true} //周りの線
              showRowLines={true} //横線
              wordWrapEnabled={true} //折り返し
              allowColumnReordering={true} //カラム移動
              allowColumnResizing={true} //カラムの幅リサイズ
              columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
              // columnChooser={{ enabled: true, mode: 'select' }} //カラム表示選択
              paging={{
                // ペジネーション設定
                enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
              }}
              scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない
              onRowPrepared={(record) => {
                // 行ごとのクラス
                if (record.rowType === 'data') {
                  // record.rowElement.className = record.carried_out_order === 0 ? 'table-row-no-accepted' : ''
                  if (record.data.carried_out_order === 0) {
                    record.rowElement.style.backgroundColor = '#efefef'
                    // record.rowElement.className += ' table-row-no-accepted'
                  }
                }
              }}
            >
              <Column
                caption='時間' //タイトル
                dataField='period_time' //dataIndex
                width={62}
                fixed={true} //カラム固定
              />
              <Column
                caption='受付No'
                dataField='carried_out_order'
                width={62}
                fixed={true}
                cellRender={(event) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {event.value === 0 ? '' : event.value}
                    </div>
                  )
                }}
              />
              <Column
                caption='個人番号'
                dataField='personal_number_id'
                width={100}
                fixed={true}
                cellRender={(event) =>
                  <div style={{ textAlign: 'right' }}>{event.value}</div>
                }
              />
              <Column
                caption='カナ氏名'
                dataField='kana_name'
                width={120}
                fixed={true}
              />
              <Column
                caption='漢字氏名'
                dataField='kanji_name'
                width={120}
                fixed={true}
              />
              <Column
                caption='性別'
                dataField='Gender'
                width={45}
                fixed={true}
                cellRender={(event) =>
                  <span style={{ color: event.value === '男性' ? '#0F3278' : '#B41432' }}>{event.value}</span>
                }
              />
              <Column
                caption='年齢'
                dataField='Age'
                width={45}
                fixed={true}
                cellRender={(event) =>
                  <div style={{ textAlign: 'right' }}>{event.value}</div>
                }
              />
              <Column
                caption='コース'
                dataField='contract_short_name'
                fixed={true}
                width={170}
              />
              {this.state.PassingNumList.split(',')?.map((item, index) => {
                let title = this.state.PassingNameList.split(',')[index]
                let dateIndex = 'Inspect' + index

                // 半角を全角に
                const titleString = (params) =>
                  params.replace(/[A-Za-z0-9]/g, function (s) {
                    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
                  });

                // 各検査のカラム
                return (
                  <Column
                    key={'column-' + index}
                    dataField={dateIndex}
                    width={35}
                    alignment='center'
                    caption={title}
                    headerCellRender={() =>
                      <div style={titleStyle} >
                        {titleString(title)}
                      </div>
                    }
                  />)
              })}
            </DataGrid>

            {/* antdのTable */}
            <Table
              bordered
              dataSource={this.state.dataSource}
              size='small'
              scroll={{ x: 'max-content', y: 300 }}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              rowClassName={(record) => record.carried_out_order === 0 ? 'table-row-no-accepted' : ''}
              style={{ display: 'none' }}
            >
              <Table.Column
                title='時間'
                dataIndex='period_time' //受付時に受付時間をシステム時間で上書きする(オプション設定の「受付時間更新=Y」の場合)
                width={62}
                fixed='left'
              />

              <Table.Column
                title='受付No'
                dataIndex='carried_out_order'
                width={62}
                fixed='left'
                render={(value) =>
                  <div style={{ textAlign: 'right' }}>
                    {value === 0 ? '' : value}
                  </div>
                }
              />

              <Table.Column
                title='個人番号'
                dataIndex='personal_number_id'
                width={100} //半角角10文字の幅
                fixed='left'
                render={(value) =>
                  <div style={{ textAlign: 'right' }}>{value}</div>
                }
              />

              <Table.Column
                title='カナ氏名'
                dataIndex='kana_name'
                width={120} //全角7文字の幅
                fixed='left'
              />

              <Table.Column
                title='漢字氏名'
                dataIndex='kanji_name'
                width={120} //全角7文字の幅
                fixed='left'
              />

              <Table.Column
                title='性別'
                dataIndex='Gender'
                width={45}
                render={(text) => (<span style={{ color: text === '男性' ? '#0F3278' : '#B41432' }}>{text}</span>)}
                fixed='left'
              />

              <Table.Column
                title='年齢'
                dataIndex='Age'
                width={45}
                fixed='left'
                render={(value) =>
                  <div style={{ textAlign: 'right' }}>{value}</div>
                }
              />

              <Table.Column
                title='コース'
                dataIndex='contract_short_name'
                width={170}
                fixed='left'
              />

              {this.state.PassingNumList.split(',')?.map((item, index) => {
                let title = this.state.PassingNameList.split(',')[index]
                let dateIndex = 'Inspect' + index

                // 半角を全角に
                const titleString = (params) =>
                  params.replace(/[A-Za-z0-9]/g, function (s) {
                    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
                  });

                return (
                  <Table.Column
                    key={index}
                    title={<div style={titleStyle} >{titleString(title)}</div>}
                    align='center'
                    dataIndex={dateIndex}
                    width={35}
                    render={(value) => {
                      let tooltip = ''
                      switch (value) {
                        case '○':
                          tooltip = '受付済み'
                          break
                        case '●':
                          tooltip = '実施済み'
                          break
                        case '未':
                          tooltip = '未実施'
                          break
                        case '×':
                          tooltip = '測定不可'
                          break
                        default:
                      }
                      return (
                        <Tooltip title={tooltip}>
                          <div style={{ textAlign: 'center' }}>{value}</div>
                        </Tooltip>
                      )
                    }}
                  />)
              })}

            </Table>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3059001_PassingManageProgressMock)
