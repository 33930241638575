import axios from 'configs/axios'

const API_LIST = {
  getListData: '/api/sanai-medical-checkup-calendar/week-and-course-setting/get-list-data',
  saveData: '/api/sanai-medical-checkup-calendar/week-and-course-setting/save-data',
  delete: '/api/sanai-medical-checkup-calendar/week-and-course-setting/delete'
}

const WeekAndCourseSettingService = {
  async getListDataService(params) {
    return axios.get(API_LIST.getListData, { params })
  },

  async saveData(params) {
    return axios.post(API_LIST.saveData, params)
  },

  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },

}

export default WeekAndCourseSettingService
