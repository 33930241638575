import { message } from 'antd'
import DoctorNameMasterService from 'services/IntroductionLetter/IntroduceLetterMasterMaintain/DoctorNameMasterService'

const DoctorNameMasterAction = {
  index(params) {
    return DoctorNameMasterService.index(params)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(data) {
    return DoctorNameMasterService.save(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  delete(data) {
    return DoctorNameMasterService.delete(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}

export default DoctorNameMasterAction
