import { message } from 'antd'
import VisitsTargetSearchQueryService from 'services/ReservationBusiness/PersonalReserveProcess/VisitsTargetSearchQueryService'

const VisitsTargetSearchQueryAction = {
  index(data) {
    return VisitsTargetSearchQueryService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        console.log(err)
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default VisitsTargetSearchQueryAction 
