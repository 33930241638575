import axios from "configs/axios";

const APP_LIST = {
  displayButton: "/api/vouchers-input-process-list/display-button",
  index: "/api/vouchers-input-process-list",
};

const VouchersInputProcessListService = {
  async displayButton(data) {
    return axios.post(APP_LIST.displayButton, data);
  },
  async index(params) {
    return axios.get(APP_LIST.index, { params });
  },
};

export default VouchersInputProcessListService;
