import React from 'react'
import { connect } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
import { Card, Form, Input, Button, message } from 'antd'
import logoImage from 'assets/img/alpha_salus_logo.png'
import { logoutUserInfo, modifyUserpasswordAction } from 'redux/user/user.actions.js'
import 'pages/MN_Basis/V5MN0001000_Main/WS2517001_SignInScreen.scss'
import moment from 'moment'

const layout = {
  labelCol: { style: { width: 160 } },
}

const format = 'YYYY/MM/DD'

class WS2518002_PasswordUpdate extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'パスワード更新（ログイン時に有効期限が切れている場合に遷移するが画面）'

    this.state = {
      validDays: Number(process.env.REACT_APP_ACCOUNT_VALID_DAYS ?? 0) //アカウント有効日数
    }

    this.onFinish = this.onFinish.bind(this)
  }

  /**
   * パスワード更新 処理　※入力エラーを取得する為「onFinish」を使用
   * @param {*} values
   * @returns
   */
  onFinish(values) {
    const hospitalCode = this.props.userReducer.hospitalCode

    let validF = null
    let validT = null
    if (this.state.validDays > 0) {
      // 有効期限の設定がある場合
      validF = moment().format(format)
      validT = (moment().add(this.state.validDays, 'days')).format(format)
    }

    let params = {
      current_password: values.current_password,
      password: values.password,
      password_confirmation: values.confirm,
      valid_f: validF, //有効期限 開始日
      valid_t: validT, //有効期限 終了日（デフォルト：60日後）
    }
    this.props.modifyUserpasswordAction(params)
      .then((res) => {
        message.success(res.data.message)
        // ログアウト
        this.props.logoutUserInfo(hospitalCode)
      })
      .catch((error) => {
        const res = error.response
        if (this.props.loggedIn && res && (res.status === 401)) {
          // ログアウト
          this.props.logoutUserInfo(hospitalCode)
          return
        } else if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div
        className='login-page'
        style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div>
          <div style={{ width: '80%', height: '100px', display: 'flex', margin: '10px auto 10px auto' }}>
            <img src={logoImage}></img>
          </div>
          <Card
            style={{ width: 500 }}
          >
            <div style={{
              width: '100%',
              marginBottom: 15,
              fontSize: 16,
              color: 'red',
              whiteSpace: 'pre-wrap',
              display: 'flex',
              justifyContent: 'center',
            }}>
              {'※パスワードの有効期限が切れました\n　新しいパスワードを設定してください'}
            </div>
            <Form
              ref={this.formRef}
              {...layout}
              autoComplete='off'
              onFinish={this.onFinish}
            >
              <Form.Item
                label='ユーザーID'
                colon={false}
              >
                <div>{this.props.userReducer.user.username}</div>
              </Form.Item>
              <Form.Item
                name='current_password'
                label='現在のパスワード'
                rules={[{ required: true, }]}
              >
                <Input
                  type='password'
                  className='input-size-30'
                />
              </Form.Item>
              <Form.Item
                name='password'
                label='新パスワード'
                rules={[
                  { required: true, },
                  { min: process.env.REACT_APP_PASSWORD_RESTRICT.toLocaleLowerCase() === 'true'  ? 8 : 1 },
                ]}
              >
                <Input
                  type='password'
                  className='input-size-30'
                />
              </Form.Item>
              <Form.Item
                name='confirm'
                label='パスワード（確認）'
                // dependencies={['password']}
                // hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject('新パスワードとパスワード（確認）が一致しません。')
                    },
                  }),
                ]}
              >
                <Input
                  type='password'
                  className='input-size-30'
                />
              </Form.Item>

              <div className='box_button_bottom_right'>
                <Form.Item style={{ margin: 0 }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    icon={<SaveOutlined />}
                  >
                    <span className='btn_label'>
                      更新
                    </span>
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  alertReducer: alertReducer,
  userReducer: userReducer,
})

const mapDispatchToProps = (dispatch) => ({
  alert: (action, message) => dispatch(action(message)),
  modifyUserpasswordAction: (user) => dispatch(modifyUserpasswordAction(user)),
  logoutUserInfo: hospitalCode => dispatch(logoutUserInfo(hospitalCode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2518002_PasswordUpdate)
