import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, message} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import moment from "moment";

class WS1314008_DateDisplay extends React.Component {

  constructor(props) {
    super(props);

    // document.title = '日付表示';

    this.state = {
      dataSource: [],
      rowSelected: {},
    };
  }

  onFinish(values) {

  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData = () => {
    VenusApiRoutesV2.callApi("API001314008001", {})
      .then(res => {
        let data = [];
        res?.Date_List?.forEach(item => {
          if (!data.includes(item.date_of_adoption_history_on)) {
            data.push(item.date_of_adoption_history_on)
          }
        });
        data = data.map(item => {return {
          Date: moment(item).format('YYYY/MM/DD'),
          id: data.indexOf(item),
        }});
        this.setState({dataSource: data});
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally();
  };

  render() {
    return (
      <div className="date-display">
        <Card className="mb-2" title="日付表示">
          <Table
              bordered
              size="small"
              dataSource={this.state.dataSource ? this.state.dataSource : []}
              loading={this.state.isLoading}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
              }}
              rowKey={(record) => record.id}
              rowSelection={{
                type: 'radio',
                onChange: async (selectedRowKeys, selectedRows) => {
                  await this.setState({
                    rowSelected: selectedRows[0],
                  });
                },
              }}
            >
              <Table.Column
                dataIndex={'Date'}
                />
            </Table>
            <Button type="primary" style={{float:'right'}} onClick={() => {
              if(this.props.onFinishScreen) {
                this.props.onFinishScreen(this.state.rowSelected);
              }
            }}>選択</Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314008_DateDisplay);
