import React from 'react'
import { connect } from 'react-redux'
import { Card, Modal, Button, Input, Form, } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';

import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import InputVoteResultInputAction from 'redux/InputBusiness/InputVoteResultInput/InputVoteResultInput.action.js'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '80px' } }
}

class WS0738005_InputVoteResultInputItemSettingsItemEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      examName: null,
      examType: null
    }
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const param = {
      ...this.props.record,
    }

    this.setState({
      examName: param.exam_short_name ?? ''
    })

    // Form設定
    this.formRef.current?.setFieldsValue(param)
  }

  /**
 * データ保存
 */
  save = () => {
    let param = {
      id: this.formRef.current?.getFieldValue('id') ?? null,
      document_form_1: this.props.invoiceRecord.item_id,
      newFlag: this.props.newFlag,
      newNo: this.formRef.current?.getFieldValue('no'),
      newTestItemCode: this.formRef.current?.getFieldValue('exam_item'),
      examType: this.state.examType
    }

    InputVoteResultInputAction.saveVoucherDetailItemEdit(param)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }


  /**
   * データ削除
   */
  delete = () => {
    let param = {
      id: this.formRef.current?.getFieldValue('id') ?? null,
    }

    InputVoteResultInputAction.deleteVoucherDetailItemEdit(param)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='target-user-screen'>
        <Card
          title={'伝票単位検査 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='SEQ'
                name='no'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  type="number"
                  min="0"
                  className="input-size-number-5"
                />
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='検査コード'
                  name='exam_item'//対応したものに書き換え
                  {...labelCol}
                >
                  <Input.Search
                    className='input-search-size-number-allow-clear-8'
                    maxLength={8}
                    style={{ textAlign: 'right' }}
                    onSearch={(value, event) => {
                      if (event.target.localName !== 'input') {
                        this.formRef.current.setFieldsValue({ exam_name: '' })
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                onFinishScreen={({ recordData }) => {
                                  console.log(recordData);

                                  this.formRef.current.setFieldsValue({
                                    exam_item: recordData.test_item_code
                                  })
                                  this.setState({
                                    examName: recordData.exam_name,
                                    examType: recordData.exam_type
                                  })

                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }
                    }}
                  />
                </Form.Item>

                <div style={{ marginTop: 5, marginRight: 5 }}>
                  {this.state.examName}
                </div>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0738005_InputVoteResultInputItemSettingsItemEdit)
