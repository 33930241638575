import { message } from "antd";
import CreateAResultTableService from "services/ResultOutput/ResultsTblCollectOutput/CreateAResultTableService";


const CreateAResultTableAction = {
  GetDataScreen(data) {
    return CreateAResultTableService.getDataScreen(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  TargetSelectSub(data) {
    return CreateAResultTableService.TargetSelectSub(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  print(data) {
    return CreateAResultTableService.print(data)
  },
  preview(data) {
    return CreateAResultTableService.preview(data)
  }
}

export default CreateAResultTableAction;
