import { message } from "antd";
import InsureGuideInitInputService from "services/SpecificInsureGuide/InsureGuideInitInput/InsureGuideInitInputService";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error("エラーが発生しました");
      }
    });
};

const InsureGuideInitInputAction = {
  getTargetList(request) {
    return post(InsureGuideInitInputService.getTargetList, request);
  },
  delete(request) {
    return post(InsureGuideInitInputService.delete, request);
  }
};

export default InsureGuideInitInputAction;
