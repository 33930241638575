/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import React from "react";
import PropTypes from 'prop-types';
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import moment from 'moment'
import { Card, Form, Input, Checkbox, Select, Button, Tooltip, Space, Row, Col, Tabs, Modal, InputNumber, message, ConfigProvider, Table } from "antd";
import { QuestionCircleTwoTone, SaveOutlined } from '@ant-design/icons';
import { saveAndUpdateContractEditingSubAction } from "redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions.js";
import BillingAddressSettingAction from "redux/basicInfo/ContractInfoMaintain/BillingAddressSetting.actions.js"
import { number_format } from "helpers/CommonHelpers";

import WS0310004_ContractEditingSub from './WS0310004_ContractEditingSub.jsx';
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery';
import WS1505001_AgeManageInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0010000_AgeManageInfoMaintain/WS1505001_AgeManageInfoMaintain';
import WS0605127_ContractLineItemDisplay from "./WS0605127_ContractLineItemDisplay.jsx";
import WS0309500_BillingAddressSetting from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309500_BillingAddressSetting.jsx";
import ContractInspectContent from './WS0309017_ContractItemSub/ContractInspectContent';

import axios from "configs/axios";
import Images from 'constants/Images';
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS0605140_ExaminationSearch from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605140_ExaminationSearch.jsx';
import AnotherInspectItemSettingCategory from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309017_ContractItemSub/AnotherInspectItemSettingCategory.jsx';

const labelCol = {
  labelCol: { style: { width: '75px' } },
}

/**
* @extends {React.Component<{Li_ContractType:number, Li_ContractOrgCode:any, Li_ContractStartDate:any, Li_ContractNum:any, onSaved:Function}>}
*/
class WS0309017_ContractItemSub extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.number,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    Li_BasicOption: PropTypes.any,

    onSaved: PropTypes.func,
  };

  formRef = React.createRef();
  refCicContract = React.createRef();
  refCicOption = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '契約明細';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        style: {}
      },

      screenData: {},
      contractTermData: {},

      isLoadingContractTerm: false,
      isLoadingContentClick: false,
      TotalAmountUpdate: 0,

      changeValueContractEditingSub: false,
      dataContractEditingSub: {},

      defaltStartDate: '',
      defaltEndDate: '',

      dataSource: [],
      columnList: [],
      anotherDataSource: []
    };

    this.onFinish = this.onFinish.bind(this);
    this.loadContractTerm = this.loadContractTerm.bind(this);
    this.getFormFieldValue = this.getFormFieldValue.bind(this);
    this.setFormFieldValue = this.setFormFieldValue.bind(this);
    this.loadScreenData = this.loadScreenData.bind(this);
    this.InspectContentEditing = this.InspectContentEditing.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.loadScreenData();
    }
  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData() {
    if (Object.keys(this.state.screenData).length > 0) {
      this.loadContractTerm();
      return;
    }

    this.setState({ isLoadingContractTerm: true });

    axios.get('/api/contract-info-batch-process/contract-item-sub/getScreenData', {
      params: {
        Li_ContractType: this.props.Li_ContractType,
        Li_ContractOrgCode: this.props.Li_ContractOrgCode,
        Li_ContractStartDate: this.props.Li_ContractStartDate,
        Li_ContractNum: this.props.Li_ContractNum,
      },
    })
      .then(res => {
        this.setState({
          screenData: res.data,
        });
        this.formRef.current.setFieldsValue({
          TaxAfterDecimalPoint: res.data.TaxAfterDecimalPoint,
          Tax: res.data.Tax,
          TotalAmountUpdate: 0
        });
        this.loadContractTerm();
      })
      .catch(() => {
        message.error('画面情報の取得にはエラーが発生しました');
      });
  }

  loadContractTerm() {
    this.setState({ isLoadingContractTerm: true });
    this.refCicContract.current.loadData();
    this.refCicOption.current.loadData();

    const {
      Li_ContractType, Li_ContractOrgCode,
      Li_ContractStartDate, Li_ContractNum,
    } = this.props;

    axios.get('/api/contract-info-batch-process/contract-item-sub', {
      params: {
        Li_ContractType,
        Li_ContractOrgCode,
        Li_ContractStartDate,
        Li_ContractNum,
      },
    })
      .then(res => {
        let data = {
          ...res?.data,
          own_course_label_number: res?.data?.own_course_label_number || null,

          insurer_unit_price: res?.data?.insurer_unit_price || null,
          insurer_consumption_tax: res?.data?.insurer_consumption_tax || null,
          insurer_total_price: res?.data?.insurer_total_price || null,
          office_unit_price: res?.data?.office_unit_price || null,
          office_consumption_tax: res?.data?.office_consumption_tax || null,
          office_total_price: res?.data?.office_total_price || null,
          organization_unit_price: res?.data?.organization_unit_price || null,
          organization_consumption_tax: res?.data?.organization_consumption_tax || null,
          organization_total_price: res?.data?.organization_total_price || null,
          personal_1_unit_price: res?.data?.personal_1_unit_price || null,
          personal_1_consumption_tax: res?.data?.personal_1_consumption_tax || null,
          personal_1_total_price: res?.data?.personal_1_total_price || null,
          personal_2_unit_price: res?.data?.personal_2_unit_price || null,
          personal_2_consumption_tax: res?.data?.personal_2_consumption_tax || null,
          personal_2_total_price: res?.data?.personal_2_total_price || null,
          personal_3_unit_price: res?.data?.personal_3_unit_price || null,
          personal_3_consumption_tax: res?.data?.personal_3_consumption_tax || null,
          personal_3_total_price: res?.data?.personal_3_total_price || null,

          LimitsInsurer: parseInt(res?.data?.LimitsInsurer) || null,
          LimitsOffice: parseInt(res?.data?.LimitsOffice) || null,
          LimitsOtherGroup: parseInt(res?.data?.LimitsOtherGroup) || null,
          LimitsPersonal1: parseInt(res?.data?.LimitsPersonal1) || null,
          LimitsPersonal2: parseInt(res?.data?.LimitsPersonal2) || null,
          LimitsPersonal3: parseInt(res?.data?.LimitsPersonal3) || null,

          id: res?.data?.id
        }
        this.formRef.current.setFieldsValue(data);

        let month = res?.data.contract_start_date_on.substr(5, 2)
        let endDate = ''
        //契約終了年度。1~3月以外は西暦+1
        month == '01' || month == '02' || month == '03' ?
          endDate = res?.data.contract_start_date_on.substr(0, 4) + '/' + '03' + '/' + '31'
          : endDate = (Number(res?.data.contract_start_date_on.substr(0, 4)) + 1) + '/' + '03' + '/' + '31'

        this.setState({
          contractTermData: res?.data,
          defaltStartDate: res?.data.contract_start_date_on.replace(/-/g, '/'),
          defaltEndDate: endDate
        });

        // 請求書情報表示データ取得
        this.getBillingSettingInformation()
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingContractTerm: false });
      });
  }

  // 請求書情報表示データ取得
  getBillingSettingInformation() {
    this.setState({ isLoadingTable: true })
    let params = {
      contract_type: this.state.contractTermData.contract_type,
      contract_office_code: this.state.contractTermData.contract_office_code,
      contract_start_date_on: this.state.contractTermData.contract_start_date_on,
      contract_number: this.state.contractTermData.contract_number,
      medical_exam_course: this.state.contractTermData.medical_exam_course,
      contract_id: this.state.contractTermData.id
    }

    BillingAddressSettingAction.loadTableData(params)
      .then((res) => {
        this.setState({
          columnList: res.columnList,
          dataSource: res.tableData,
          anotherDataSource: res.anotherTableData
        })
      })
      .catch(error => {
        this.setState({ isLoadingTable: false })
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  getFormFieldValue(namePath) {
    return this.formRef.current ? this.formRef.current.getFieldValue(namePath) : undefined;
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }

  onFinish(values) {
    console.log(values)
    this.setState({ isLoadingContractTerm: true });
    if (values.organization_division === 2 && values.organization_code === '') {
      message.error('事業所コードを入力してください')
      return
    }
    if (values.organization_division === 1 && values.organization_code === '') {
      message.error('保険者コードを入力してください')
      return
    }
    const {
      Li_ContractType, Li_ContractOrgCode,
      Li_ContractStartDate, Li_ContractNum,
    } = this.props;
    // let TotalAmountUpdateFlag = this.state.TotalAmountUpdate

    axios.post('/api/contract-info-batch-process/contract-item-sub', {
      Li_ContractType,
      Li_ContractOrgCode,
      Li_ContractStartDate,
      Li_CourseStartDate: values.course_start_date_on ? moment(values.course_start_date_on).format('YYYY/MM/DD') : this.state.defaltStartDate,
      Li_ContractEndDate: values.course_end_date_on ? moment(values.course_end_date_on).format('YYYY/MM/DD') : this.state.defaltEndDate,
      Li_ContractNum,
      courseConditions: this.formRef.current?.getFieldValue('course_conditions'),
      conditionsGender: this.formRef.current?.getFieldValue('conditions_gender'),
      conditionsRelationship: this.formRef.current?.getFieldValue('conditions_relationship'),
      conditionsOther: this.formRef.current?.getFieldValue('conditions_other'),
      conditionsAmPm: this.formRef.current?.getFieldValue('conditions_am_pm'),
      conditionsInOutHospital: this.formRef.current?.getFieldValue('conditions_in_out_hospital'),
      conditionsNextMedicalExam: this.formRef.current?.getFieldValue('conditions_next_medical_exam'),
      conditionsAgeCode: this.formRef.current?.getFieldValue('conditions_age_code'),
      ...values,
    })
      .then(res => {
        message.success('保存しました');

        // this.loadContractTerm();
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        } else if (this.props.onSaved) {
          this.props.onSaved();
        }
      })
      .catch(error => {
        this.setState({ isLoadingContractTerm: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }



  InspectContentEditing(Li_BasicOption) {
    const component = (
      <WS0310004_ContractEditingSub
        Li_ContractType={this.props.Li_ContractType}
        Li_ContractOrgCode={this.props.Li_ContractOrgCode}
        Li_ContractStartDate={this.props.Li_ContractStartDate}
        Li_ContractNum={this.props.Li_ContractNum}
        Li_CourseConditions={this.props.Li_CourseConditions}
        Li_BasicOption={Li_BasicOption}
        Li_Tax={this.getFormFieldValue('Tax')}
        Li_Rounding={this.getFormFieldValue('TaxAfterDecimalPoint')}
        Li_TaxClassify={this.getFormFieldValue('tax_division')}
        Li_OtherGroup={this.getFormFieldValue('organization_division')}
        Li_Course={this.getFormFieldValue('medical_exam_course')}
        Lo_StsUpdate={this.getFormFieldValue('StsContractEdit')}

        onChangeValue={({ Lo_stsChangeValue, data }) => {
          this.setState({
            changeValueContractEditingSub: Lo_stsChangeValue,
            dataContractEditingSub: data
          })
        }}

        onFinishScreen={async () => {
          this.closeModal()
          await this.loadScreenData()
        }}

        update={async () => {
          await this.loadScreenData()
        }}
      />
    );

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1050,
        component: component,
      },
    });
  }

  updateContractEditingSub() {
    let params = {
      ...this.state.dataContractEditingSub
    }
    saveAndUpdateContractEditingSubAction(params)
      .then((res) => {
        this.setFormFieldValue('StsContractEdit', true);
        const StsContractEdit = this.getFormFieldValue('StsContractEdit');

        if (StsContractEdit) {
          this.setFormFieldValue('Vl3StsInspectChange', true);
          if (this.props.Li_BasicOption === 0) {
            // await
            // await
            this.refCicContract.current.loadData();
          } else {
            this.refCicOption.current.loadData();
          }
        }
      })
  }

  getTotalPrice() {
    let sum = (
      (number_format(
        (this.getFormFieldValue('insurer_total_price') || 0) +
        (this.getFormFieldValue('office_total_price') || 0) +
        (this.getFormFieldValue('organization_total_price') || 0) +
        (this.getFormFieldValue('personal_1_total_price') || 0) +
        (this.getFormFieldValue('personal_2_total_price') || 0) +
        (this.getFormFieldValue('personal_3_total_price') || 0)
      ))
    )
    return sum == 0 ? null : sum
  }

  getTotalLimits() {
    let sum = (
      (number_format(
        (this.getFormFieldValue('LimitsInsurer') || 0) +
        (this.getFormFieldValue('LimitsOffice') || 0) +
        (this.getFormFieldValue('LimitsOtherGroup') || 0) +
        (this.getFormFieldValue('LimitsPersonal1') || 0) +
        (this.getFormFieldValue('LimitsPersonal2') || 0) +
        (this.getFormFieldValue('LimitsPersonal3') || 0)
      ))
    )
    return sum == 0 ? null : sum
  }

  getFieldValue(name) {
    let value = number_format(this.getFormFieldValue(name))
    if (value === '0' || value === 'NaN') {
      value = null
    }
    return value
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      changeValueContractEditingSub: false
    });
  }

  render() {
    return (
      <div className="contract-item-sub">
        <Card title={'契約明細　' + this.formRef.current?.getFieldValue('id')}>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Form.Item
              label="コース"
              style={{ marginBottom: 0 }}
              {...labelCol}
            >
              <div className="box_inner_horizontal">
                <div className="div_data_horizontal">{this.getFormFieldValue('medical_exam_course')}</div>
                <div className="div_data_horizontal"> {this.getFormFieldValue(['course_basic_type_info', 'course_name_formal'])}</div>
              </div>
            </Form.Item>
            <Form.Item
              name="contract_short_name"
              label="略式名称"
              {...labelCol}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contract_name"
              label="正式名称"
              {...labelCol}
            >
              <Input />
            </Form.Item>
            <div className="box_inner_horizontal">
              <Form.Item
                name='course_start_date_on'
                label='契約開始日'
                {...labelCol}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format='YYYY/MM/DD'
                  placeholder={this.state.defaltStartDate}
                />
              </Form.Item>
              <Form.Item
                name='course_end_date_on'
                label='契約終了日'
                {...labelCol}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format='YYYY/MM/DD'
                  placeholder={this.state.defaltEndDate}
                />
              </Form.Item>
            </div>

            <Tabs>
              <Tabs.TabPane
                tab="契約金額"
                key="contractAmount"
              >
                <Table
                  size='small'
                  dataSource={this.state.dataSource}
                  rowKey={recode => recode.id}
                  pagination={false}
                  scroll={{ y: resizableTableScroll(550), x: 'max-content' }}
                  loading={this.state.isLoadingTable}
                  bordered
                >
                  <Table.Column
                    title='名称'
                    dataIndex='name'
                    className='column-size-40'
                    render={(value, record) => {
                      return (
                        <div className="box_inner_horizontal">
                          <div><img src={Images(record.data_division == 20 ? 10 : record.data_division)} /></div>
                          <div>{value}</div>
                        </div>
                      )
                    }}
                  />
                  {
                    this.state.columnList.map((value, index) => {
                      return (
                        <Table.Column
                          key={index}
                          width='140px'
                          title={() => {
                            return (
                              <Tooltip title={value.full_name}>
                                <div className="box_inner_horizontal">
                                  <div>{value.kanji_name.substr(0, 7)}</div>
                                </div>
                              </Tooltip>
                            )
                          }}
                          render={(item, record, index) => {
                            let price = null
                            this.state.anotherDataSource.map((element, index) => {
                              if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                                price = element.total_price
                              }
                            })
                            return price ? (<div style={{ textAlign: 'right' }}>{number_format(price)}</div>) : null
                          }}
                        />
                      )
                    })
                  }
                </Table>

                {/* <Row>
                  <Col span={3}>
                    <div style={columnTitle}>金額一覧</div>
                    <div style={columnTitle}>契約金額</div>
                    <div style={columnTitle}>限度金額</div>
                  </Col>
                  <Col span={3}>
                    <div style={{ ...styleDivTitle, borderLeft: '1px solid #FFFFFF' }}>保険者</div>
                    <Form.Item name="insurer_total_price" style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('insurer_total_price')}</div>
                    </Form.Item>
                    <ConfigProvider direction="ltr">
                      <Form.Item name="LimitsInsurer" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                        <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('LimitsInsurer')}</div>
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_42}>事業所</div>
                    <Form.Item name="office_total_price" style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('office_total_price')}</div>
                    </Form.Item>
                    <ConfigProvider direction="ltr">
                      <Form.Item name="LimitsOffice" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                        <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('LimitsOffice')}</div>
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_43}>他団体</div>
                    <Form.Item name="organization_total_price" style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('organization_total_price')}</div>
                    </Form.Item>
                    <ConfigProvider direction="ltr">
                      <Form.Item name="LimitsOtherGroup" style={{ ...styleFormItemBottom }}>
                        <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('LimitsOtherGroup')}</div>
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_44}>個人１</div>
                    <Form.Item name="personal_1_total_price" style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('personal_1_total_price')}</div>
                    </Form.Item>
                    <ConfigProvider direction="ltr">
                      <Form.Item name="LimitsPersonal1" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                        <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('LimitsPersonal1')}</div>
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_45}>個人２</div>
                    <Form.Item name="personal_2_total_price" style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('personal_2_total_price')}</div>
                    </Form.Item>
                    <ConfigProvider direction="ltr">
                      <Form.Item name="LimitsPersonal2" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                        <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('LimitsPersonal2')}</div>
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_46}>個人３</div>
                    <Form.Item name="personal_3_total_price" style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('personal_3_total_price')}</div>
                    </Form.Item>
                    <ConfigProvider direction="ltr">
                      <Form.Item name="LimitsPersonal3" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                        <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFieldValue('LimitsPersonal3')}</div>
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    <div style={{ ...colorExpression_47, borderRight: 0 }}>合計</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getTotalPrice()}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getTotalLimits()}</div>
                    </Form.Item>
                  </Col>
                </Row> */}

                <div className="box_button_bottom_right">
                  <Button
                    type='primary'
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          centered: true,
                          component: (
                            <WS0309500_BillingAddressSetting
                              contractTermData={this.state.contractTermData}
                              rounding={this.getFormFieldValue('TaxAfterDecimalPoint')}
                              taxRate={this.getFormFieldValue('Tax')}
                              course_start_date_on={this.formRef.current?.getFieldValue('course_start_date_on')}
                              course_end_date_on={this.formRef.current?.getFieldValue('course_end_date_on')}
                              LimitsInsurer={this.formRef.current?.getFieldValue('LimitsInsurer')}
                              LimitsOffice={this.formRef.current?.getFieldValue('LimitsOffice')}
                              LimitsOtherGroup={this.formRef.current?.getFieldValue('LimitsOtherGroup')}
                              LimitsPersonal1={this.formRef.current?.getFieldValue('LimitsPersonal1')}
                              LimitsPersonal2={this.formRef.current?.getFieldValue('LimitsPersonal2')}
                              LimitsPersonal3={this.formRef.current?.getFieldValue('LimitsPersonal3')}
                              onSave={() => {
                                this.loadContractTerm()
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  >
                    請求先設定
                  </Button>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={'条件' + (this.state.contractTermData.CourseConditionPresence ? '(〇)' : '(×)')} key="condition">
                <Form.Item label="　条件" valuePropName="checked" name="course_conditions">
                  <Checkbox onChange={() => this.forceUpdate()}>有り</Checkbox>
                </Form.Item>
                <div className="box_inner_horizontal">
                  <Form.Item label="　性別" name="conditions_gender" >
                    <Select style={{ width: '60px' }} disabled={!(this.formRef.current?.getFieldValue('course_conditions'))}>
                      <Select.Option value={0}> </Select.Option>
                      <Select.Option value={1}>男</Select.Option>
                      <Select.Option value={2}>女</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="続柄" name="conditions_relationship">
                    <Select style={{ width: '80px' }} disabled={!(this.formRef.current?.getFieldValue('course_conditions'))} allowClear>
                      {this.state.screenData.conditions_relationship ? this.state.screenData.conditions_relationship.map(value => (
                        <Select.Option key={`cnd-rls-${value.id}`} value={value.node_code_name}>{value.name}</Select.Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                  <Form.Item label="その他" name="conditions_other">
                    <Input maxLength={2} disabled={!(this.formRef.current?.getFieldValue('course_conditions'))} />
                  </Form.Item>
                  <Form.Item label="AMPM" name="conditions_am_pm">
                    <Select style={{ width: '60px' }} disabled={!(this.formRef.current?.getFieldValue('course_conditions'))}>
                      <Select.Option value=""> </Select.Option>
                      <Select.Option value="AM">ＡＭ</Select.Option>
                      <Select.Option value="PM">ＰＭ</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="box_inner_horizontal">
                  <Form.Item label="院内外" name="conditions_in_out_hospital">
                    <Select
                      style={{ width: '60px' }}
                      // allowClear
                      disabled={!(this.formRef.current?.getFieldValue('course_conditions'))}>
                      <Select.Option value={0}> </Select.Option>
                      {this.state.screenData.CourseConditionHospitalOut ? this.state.screenData.CourseConditionHospitalOut.map(value => (
                        <Select.Option key={`cnd-hospital-${value.id}`} value={parseInt(value.node_code_name)}>{value.name}</Select.Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                  <Form.Item label="ｎ次" name="conditions_next_medical_exam">
                    <Select style={{ width: '80px' }} disabled={!(this.formRef.current?.getFieldValue('course_conditions'))}>
                      <Select.Option value={0}> </Select.Option>
                      {this.state.screenData.CourseConditionNTsugikenmi ? this.state.screenData.CourseConditionNTsugikenmi.map(value => (
                        <Select.Option key={`cnd-ntsugi-${value.id}`} value={parseInt(value.node_code_name)}>{value.name}</Select.Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                  <Form.Item label="　年齢" name="conditions_age_code">
                    <Select style={{ width: '225px' }} allowClear disabled={!(this.formRef.current?.getFieldValue('course_conditions'))}>
                      {this.state.screenData.conditions_age_code ? this.state.screenData.conditions_age_code.map(value => (
                        <Select.Option key={`cnd-age-${value.id}`} value={value.age_id_code}>{value.age_title}</Select.Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="button" onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component: (
                            <WS1505001_AgeManageInfoMaintain
                              onClickedSelect={({ Lo_InsurerCode }) => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      });
                    }}>年齢設定</Button>
                  </Form.Item>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="その他" key="other">
                <div className="box_inner_horizontal">
                  <Form.Item label="税区分" name="tax_division" >
                    <Select style={{ width: '90px' }}>
                      <Select.Option value={0}>税指定</Select.Option>
                      <Select.Option value={1}>外税</Select.Option>
                      <Select.Option value={2}>内税</Select.Option>
                      <Select.Option value={3}>非課税</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="box_inner_horizontal">
                  <Form.Item label="年　齢" name="conditions_age_division">
                    <Select style={{ width: '90px' }} onChange={() => {
                      this.forceUpdate();
                    }}>
                      <Select.Option value={0}>受診日</Select.Option>
                      <Select.Option value={1}>年度末</Select.Option>
                      <Select.Option value={2}>学童</Select.Option>
                      <Select.Option value={3}>指定日</Select.Option>
                    </Select>
                  </Form.Item>
                  <ConfigProvider direction="ltr">
                    <Form.Item label="月" name="SpecifiedMonth" hidden={this.getFormFieldValue('conditions_age_division') !== 3}>
                      <InputNumber maxLength={2} min={1} max={12} style={{ maxWidth: 60 }} />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item label="日" name="SpecifiedDate" hidden={this.getFormFieldValue('conditions_age_division') !== 3}>
                      <InputNumber maxLength={2} min={1} max={31} style={{ maxWidth: 60 }} />
                    </Form.Item>
                  </ConfigProvider>
                  <Form.Item label="ラベル" name="own_course_label_number">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item label="備考" name="remark_1" >
                    <Input style={{ width: '120px' }} />
                  </Form.Item>
                </div>
                <div className="box_inner_horizontal">
                  <Form.Item label="他団体" name="organization_division">
                    <Select style={{ width: '90px' }} onChange={() => {
                      this.formRef.current.setFieldsValue({
                        organization_code: null,
                        office: null,
                      });
                      this.forceUpdate();
                    }}>
                      <Select.Option value={0}> </Select.Option>
                      <Select.Option value={1}>保険者</Select.Option>
                      <Select.Option value={2}>事業所</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="organization_code" hidden={(() => { const v = this.getFormFieldValue('organization_division'); return !v || v === 0; })()}>
                    <Input.Search
                      onPressEnter={() => {
                        const organization_division = this.getFormFieldValue('organization_division');
                        if (organization_division === 1) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0246001_InsurerInfoSearchQuery
                                  Li_insurerSearch={this.formRef.current?.getFieldValue('organization_code')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      organization_code: output.Lo_InsurerCode,
                                      insurer: output.recordData,
                                    });

                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        } else if (organization_division === 2) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={this.formRef.current?.getFieldValue('organization_code')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      organization_code: output.Lio_OfficeCode,
                                      office: output.recordData,
                                    });

                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        }
                      }}
                      onSearch={() => {
                        const organization_division = this.getFormFieldValue('organization_division');
                        if (organization_division === 1) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0246001_InsurerInfoSearchQuery
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      organization_code: output.Lo_InsurerCode,
                                      insurer: output.recordData,
                                    });

                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        } else if (organization_division === 2) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0247001_OfficeInfoRetrievalQuery
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      organization_code: output.Lio_OfficeCode,
                                      office: output.recordData,
                                    });

                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        }
                      }} />
                  </Form.Item>
                  <div style={{ marginTop: '5px' }}>{(() => {
                    switch (this.getFormFieldValue('organization_division')) {
                      case 1:
                        return this.getFormFieldValue(['insurer', 'insurer_kanji_name']);
                      case 2:
                        return this.getFormFieldValue(['office', 'office_kanji_name']);
                    }
                  })()}</div>
                </div>
              </Tabs.TabPane>
            </Tabs>

            <Row gutter={10} style={{ marginTop: '10px' }}>
              <Col span={12}>
                <div className="box_border" style={{ borderBottom: 0 }}>
                  <div className="box_inner_horizontal" style={{ justifyContent: "space-between" }}>
                    <div>基本契約</div>
                    <div>
                      <Space>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() =>
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: "1150px",
                                style: { top: 10 },
                                component: (
                                  <AnotherInspectItemSettingCategory
                                    ContractType={this.props.Li_ContractType}
                                    ContractStartDate={this.props.Li_ContractStartDate}
                                    ContractOrgCode={this.props.Li_ContractOrgCode}
                                    ContractNum={this.props.Li_ContractNum}
                                    Medical_exam_course={this.formRef.current?.getFieldValue('medical_exam_course')}
                                  />

                                )
                              }
                            })
                          }
                          loading={this.state.isLoadingContentClick}
                          disabled={this.props.screen === 'WS0605127_ContractLineItemDisplay'}
                        >内容</Button>
                        <Button type="primary" size="small" onClick={() => this.InspectContentEditing(0)}>編集</Button>
                      </Space>
                    </div>
                  </div>
                </div>
                <ContractInspectContent
                  ref={this.refCicContract}
                  Li_ContractType={this.props.Li_ContractType}
                  Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                  Li_ContractStartDate={this.props.Li_ContractStartDate}
                  Li_ContractNum={this.props.Li_ContractNum}
                  Li_MedicalExamCourse={this.formRef.current?.getFieldValue('medical_exam_course')}
                  Li_DisplayCategory={1}
                />
              </Col>

              <Col span={12}>
                <div className="box_border" style={{ borderBottom: 0 }}>
                  <div className="box_inner_horizontal" style={{ justifyContent: "space-between" }}>
                    <label>オプション</label>
                    <div style={{ float: 'right' }}>
                      <Button type="primary" size="small" onClick={() => this.InspectContentEditing(1)}>編集</Button>
                    </div>
                  </div>
                </div>
                <ContractInspectContent
                  ref={this.refCicOption}
                  Li_ContractType={this.props.Li_ContractType}
                  Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                  Li_ContractStartDate={this.props.Li_ContractStartDate}
                  Li_ContractNum={this.props.Li_ContractNum}
                  Li_MedicalExamCourse={this.formRef.current?.getFieldValue('medical_exam_course')}
                  Li_DisplayCategory={2}
                />
              </Col>
            </Row>
            <Space style={{ float: 'right', marginTop: '8px' }}>
              <Button
                type="primary"
                // htmlType="submit"
                icon={<SaveOutlined />}
                onClick={() => this.onFinish(this.formRef.current?.getFieldsValue())}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </Space>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          style={this.state.childModal.style}
          onCancel={() => {
            if (this.state.changeValueContractEditingSub) {
              Modal.confirm({
                title: '変更を適用しますか？',
                width: 300,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.updateContractEditingSub()
                  this.setState({ changeValueContractEditingSub: false })
                  this.closeModal()
                },
                onCancel: () => {
                  this.setState({ changeValueContractEditingSub: false })
                  this.closeModal()
                }
              })
            } else {
              this.closeModal()
            }
          }}
        />
      </div >
    );
  }
}

export default WS0309017_ContractItemSub;
