import { message } from "antd";
import ConditionExpressCmtSubService from "services/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressCmtSubService.js";

const ConditionExpressCmtSubAction = {
  index(data) {
    return ConditionExpressCmtSubService.index(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveAndUpdate(data) {
    return ConditionExpressCmtSubService.saveAndUpdate(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(data) {
    return ConditionExpressCmtSubService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  details(data) {
    return ConditionExpressCmtSubService.details(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveDetails(data) {
    return ConditionExpressCmtSubService.saveDetails(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteDetails(data) {
    return ConditionExpressCmtSubService.deleteDetails(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default ConditionExpressCmtSubAction;