import React from 'react'
import { connect } from 'react-redux'
import { Regex } from 'helpers/CommonHelpers.js'
import { Card, Form, Tabs, Descriptions, Input, Button, Table, Select, message, Modal } from 'antd'
import { SaveOutlined, PlusOutlined, MoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import MemoEditDisplay from 'pages/ZZ_Others/SANAI_Memo/MemoEditDisplay.jsx'
import TabooOptionSetting from 'pages/ZZ_Others/SANAI_Memo/TabooOptionSetting.jsx'
import MemoFunctionAction from 'redux/Others/ReserveStatusSearch/MemoFunction.action.js'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import moment from 'moment'

const { TextArea } = Input

const FormStyle = {
  marginBottom: 0
}

class MemoFunction extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = '診断メモ'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      memos: [],
      date: '',
      reservation_number: '',
      personal_number_id: '',
      saveBtnFlag: true,
      AllMemoFunctionUpdateBtn: true,
      doctorMemoHistory: [],
      nurseMemoHistory: [],
      businessMemoHistory: [],
      disabledFlag: false,
      doctorDisabledFlag: false,
      relationship: [],
      relationship1: '',
      relationship2: '',
      relationship3: '',
    }
  }

  componentDidMount() {
    this.setState({
      date: this.props.date,
      reservation_number: this.props.reservation_number,
      personal_number_id: this.props.personal_number_id
    })

    this.getScreenData(this.props.personal_number_id, this.props.date, this.props.reservation_number, true)
  }

  // メモの情報取得
  getScreenData(id, date, reservationNumber, personal = false) {
    let param = {
      date: date,
      personal_number_id: id,
      reservation_number: reservationNumber,
    }

    MemoFunctionAction.getHistoryData(param)
      .then(res => {
        let businessMemoHistory = res.businessMemoHistory
        let disabledFlag = false
        let doctorDisabledFlag = false
        // 単日メモを表示する際に親画面から送られてきたreservation_numberが一致していないかチェック
        // 一致していた場合単日メモを追加をできないようにする
        businessMemoHistory.forEach((value) => {
          if (value.reservation_number === this.props.reservation_number) {
            disabledFlag = true
          }
        })

        if (res.newAddDisabledFlag) {
          doctorDisabledFlag = true
        }

        this.setState({
          'doctorMemoHistory': res.doctorMemoHistory,
          'nurseMemoHistory': res.nurseMemoHistory,
          'businessMemoHistory': res.businessMemoHistory,
          disabledFlag: disabledFlag,
          doctorDisabledFlag: doctorDisabledFlag,
          relationship1: res.relationship1,
          relationship2: res.relationship2,
          relationship3: res.relationship3,
          relationship: res.relationship,
        })

        if (personal) {
          let business = ''
          res.businessMemo.forEach((value) => {
            business = business + value + '\n'
          })

          this.formRef.current?.setFieldsValue({
            'doctor': res.doctorMemo,
            'nurse': res.nurseMemo,
            'business': business,
            'taboo1': res.taboo1,
            'taboo2': res.taboo2,
            'taboo3': res.taboo3,
            'taboo4': res.taboo4,
            'tabooName1': res.tabooName1,
            'tabooName2': res.tabooName2,
            'tabooName3': res.tabooName3,
            'tabooName4': res.tabooName4,
            'partner1': res.partner1,
            'partnerName1': res.partnerName1,
            'partner2': res.partner2,
            'partnerName2': res.partnerName2,
            'partner3': res.partner3,
            'partnerName3': res.partnerName3,
          })
        }
        this.forceUpdate()
      })
  }

  // メモ全体画面の表示
  viewAllMemos = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <AllMemosFunction
            memos={this.state.memos}
            date={this.state.date}
            KanjiName={this.props.KanjiName}
            reservation_number={this.state.reservation_number}
            personal_number_id={this.state.personal_number_id}
            onFinishScreen={() => {
              if (this.props.onSaveMemos) {
                this.props.onSaveMemos()
              } else if (this.props.onFinishScreen) {
                this.props.onFinishScreen()
              } else {
                this.getScreenData(this.state.personal_number_id, this.state.date, this.state.reservation_number, true)
              }
            }}

            // メモ全体入力画面での保存ボタンの状態
            onFlagUpdate={(bool) => {
              console.log('onFlagUpdate:' + bool);
              this.setState({
                AllMemoFunctionUpdateBtn: bool
              })
            }}
          />
        ),
      }
    })
  }

  // 保存処理
  savePersonalMemo = () => {
    let params = {
      personal_number_id: this.state.personal_number_id,
      doctorMemo: this.formRef.current?.getFieldValue('doctor'),
      nurseMemo: this.formRef.current?.getFieldValue('nurse'),
      businessMemo: this.formRef.current?.getFieldValue('business'),
      taboo1: this.formRef.current?.getFieldValue('taboo1'),
      taboo2: this.formRef.current?.getFieldValue('taboo2'),
      taboo3: this.formRef.current?.getFieldValue('taboo3'),
      taboo4: this.formRef.current?.getFieldValue('taboo4'),
      partner1: this.formRef.current?.getFieldValue('partner1'),
      relationship1: this.state.relationship1,
      partner2: this.formRef.current?.getFieldValue('partner2'),
      relationship2: this.state.relationship2,
      partner3: this.formRef.current?.getFieldValue('partner3'),
      relationship3: this.state.relationship3,
    }
    MemoFunctionAction.savePersonalMemo(params)
      .then(async (res) => {
        if (this.props.onSaveMemos) {
          if (this.props.onFlagUpdate) {
            await this.props.onFlagUpdate(true)
          }
          await this.props.onSaveMemos()
        } else if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        } else {
          this.getScreenData(this.state.personal_number_id, this.state.date, this.state.reservation_number, true)
        }
      })
  }

  // 単日メモの入力画面
  memoEditDisplay = (record, type, newFlag) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <MemoEditDisplay
            reservation_number={this.state.reservation_number}
            personal_number_id={this.props.personal_number_id}
            KanjiName={this.props.KanjiName}
            type={type}
            serial_number={record.serial_number ?? ''}
            memo={record.memo ?? ''}
            newFlag={newFlag}
            onFinishScreen={() => {
              this.getScreenData(this.state.personal_number_id, this.state.date, this.state.reservation_number)
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  // 禁忌設定画面
  tabooOptions = (name) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <TabooOptionSetting
            selectFlag={name ? true : false}
            onSelect={(output) => {
              switch (name) {
                case 'taboo1':
                  this.formRef.current?.setFieldsValue({
                    taboo1: output.serialNumber,
                    tabooName1: output.select
                  })
                  break
                case 'taboo2':
                  this.formRef.current?.setFieldsValue({
                    taboo2: output.serialNumber,
                    tabooName2: output.select
                  })
                  break
                case 'taboo3':
                  this.formRef.current?.setFieldsValue({
                    taboo3: output.serialNumber,
                    tabooName3: output.select
                  })
                  break
                case 'taboo4':
                  this.formRef.current?.setFieldsValue({
                    taboo4: output.serialNumber,
                    tabooName4: output.select
                  })
                  break
              }
              this.closeModal()
              this.onChangeSaveBtn()
            }}
          />
        ),
      }
    })
  }

  /**
   * 個人番号　入力時処理
   * 　debounceで実行タイミング遅らせている
   * 　連続でAPI実行するのを防ぐため
   * @param {*} id
   */
  onChangePersonalNumberID = (id, partnerName) => {
    // 入力チェック
    if (id !== null && id !== '') {
      if (!Regex(id, /[1-9]/)) {
        // 数値以外の場合
        message.error('半角数字以外は使用できません')
        id = null
      }
    }

    // 個人情報取得
    this.searchPersonalNumberID(id, partnerName)
  }

  // 個人情報取得
  searchPersonalNumberID(id, partnerName) {
    let param = {
      personal_number_id: id
    }

    MemoFunctionAction.getPersonalInfo(param)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          [partnerName]: res.kanji_name
        })

        this.forceUpdate()
      })
  }

  // 虫メガネボタンが押された場合
  getPersonalInfo = (partner, partnerName) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '85%',
        component:
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue('partner')}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                [partner]: output.recordData.personal_number_id,
                [partnerName]: output.recordData.kanji_name
              })
              this.closeModal()
              this.onChangeSaveBtn()
            }}
          />
      }
    })
  }

  // 続柄のselectが変更された時の処理
  onChangeBelongs = (valse, name) => {
    this.setState({
      [name]: valse
    })
  }

  // 禁忌入力フォームの削除ボタンが押された場合
  clearTabooItem = (number) => {
    let taboo = 'taboo' + number
    let tabooName = 'tabooName' + number

    this.formRef.current?.setFieldsValue({
      [taboo]: '',
      [tabooName]: ''
    })

    this.forceUpdate()
  }

  // 血縁関係入力フォームの削除ボタンが押された場合
  clearPartnerItem = (number) => {
    let partnerName = 'partnerName' + number
    let relationship = 'relationship' + number

    this.setState({
      [partnerName]: '',
      [relationship]: ''
    })

    this.formRef.current?.setFieldsValue({ [partnerName]: '' })
  }

  // 保存ボタンフラグを変更
  onChangeSaveBtn = () => {
    console.log('保存ボタン' + this.state.saveBtnFlag);
    if (this.state.saveBtnFlag) {
      this.setState({
        businessChengeFlag: true,
        saveBtnFlag: false
      })

      if (this.props.onFlagUpdate) {
        this.props.onFlagUpdate(false)
      }
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='memo-function' style={{ width: 800 }}>
        <Card title={this.props.personal_number_id + '　' + this.props.KanjiName}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Tabs
              defaultActiveKey='2'
              size='small'
            >
              <Tabs.TabPane
                tab={
                  this.formRef.current?.getFieldValue('taboo1') ||
                    this.formRef.current?.getFieldValue('taboo2') ||
                    this.formRef.current?.getFieldValue('taboo3') ||
                    this.formRef.current?.getFieldValue('taboo4') ||
                    this.formRef.current?.getFieldValue('partner1') ||
                    this.formRef.current?.getFieldValue('partner2') ||
                    this.formRef.current?.getFieldValue('partner3') ?
                    '禁忌*' : '禁忌'
                }
                key='1'
              >
                <div className='box_inner_vertical'>
                  <Button
                    size='small'
                    style={{ width: 'fit-content', marginLeft: 'auto' }}
                    onClick={() => this.tabooOptions()}
                  >
                    禁忌設定
                  </Button>
                  <Descriptions
                    title=''
                    column={1}
                    bordered
                    size='small'
                  >
                    <Descriptions.Item
                      label='禁忌1'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                        <Form.Item
                          name='taboo1'
                          style={FormStyle}
                        >
                          <Input.Search
                            className='input-search-size-number-10'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                // ✕ボタン押下
                                this.clearTabooItem(1)
                              } else {
                                this.tabooOptions('taboo1')
                              }
                            }}

                            // 保存ボタン状態変更
                            onChange={() => this.onChangeSaveBtn()}
                          />
                        </Form.Item>

                        <Form.Item
                          name='tabooName1'
                          style={FormStyle}
                        >
                          <div>{this.formRef.current?.getFieldValue('tabooName1')}</div>
                        </Form.Item>
                      </div>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label='禁忌2'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                        <Form.Item
                          name='taboo2'
                          style={FormStyle}
                        >
                          <Input.Search
                            className='input-search-size-number-10'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                // ✕ボタン押下
                                this.clearTabooItem(2)
                              } else {
                                this.tabooOptions('taboo2')
                              }
                            }}

                            // 保存ボタン状態変更
                            onChange={() => this.onChangeSaveBtn()}
                          />
                        </Form.Item>

                        <Form.Item
                          name='tabooName2'
                          style={FormStyle}
                        >
                          <div>{this.formRef.current?.getFieldValue('tabooName2')}</div>
                        </Form.Item>
                      </div>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label='禁忌3'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                        <Form.Item
                          name='taboo3'
                          style={FormStyle}
                        >
                          <Input.Search
                            className='input-search-size-number-10'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                // ✕ボタン押下
                                this.clearTabooItem(3)
                              } else {
                                this.tabooOptions('taboo3')
                              }
                            }}

                            // 保存ボタン状態変更
                            onChange={() => this.onChangeSaveBtn()}
                          />
                        </Form.Item>

                        <Form.Item
                          name='tabooName3'
                          style={FormStyle}
                        >
                          <div>{this.formRef.current?.getFieldValue('tabooName3')}</div>
                        </Form.Item>
                      </div>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label='禁忌4'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                        <Form.Item
                          name='taboo4'
                          style={FormStyle}
                        >
                          <Input.Search
                            className='input-search-size-number-10'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                // ✕ボタン押下
                                this.clearTabooItem(4)
                              } else {
                                this.tabooOptions('taboo4')
                              }
                            }}

                            // 保存ボタン状態変更
                            onChange={() => this.onChangeSaveBtn()}
                          />
                        </Form.Item>

                        <Form.Item
                          name='tabooName4'
                          style={FormStyle}
                        >
                          <div>{this.formRef.current?.getFieldValue('tabooName4')}</div>
                        </Form.Item>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>

                  <Descriptions
                    title=''
                    column={1}
                    bordered
                    size='small'
                  >
                    <Descriptions.Item
                      label='血縁関係'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                          <Form.Item
                            name='partner1'
                            style={FormStyle}
                          >
                            <Input.Search
                              className='input-search-size-number-10'
                              allowClear={true}
                              onSearch={(value, event) => {
                                if (event.target.localName === 'input') {
                                  // ✕ボタン押下
                                  this.clearPartnerItem(1)
                                } else {
                                  // 虫眼鏡マーク押下
                                  this.getPersonalInfo('partner1', 'partnerName1')
                                }
                              }}

                              // Enterが押された処理
                              onPressEnter={(event) => {
                                const personalNum = event.target.value
                                this.onChangePersonalNumberID(personalNum, 'partnerName1')
                              }}

                              // 保存ボタン状態変更
                              onChange={() => this.onChangeSaveBtn()}
                            />
                          </Form.Item>

                          <Form.Item
                            name='partnerName1'
                            style={FormStyle}
                          >
                            <div>{this.formRef.current?.getFieldValue('partnerName1')}</div>
                          </Form.Item>
                        </div>

                        <div>
                          <Form.Item
                            label='続柄'
                            style={{ marginBottom: 0, marginRight: 0 }}
                          >
                            <Select
                              style={{ width: '100px' }}
                              value={this.state.relationship1}
                              onChange={value => {
                                this.onChangeBelongs(value, 'relationship1')
                                this.onChangeSaveBtn()
                              }}
                            >
                              {this.state.relationship === undefined ? [] : this.state.relationship.map((item, index) => (
                                <Select.Option
                                  key={item.id}
                                  value={item.node_code_name}
                                >
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label='血縁関係'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                          <Form.Item
                            name='partner2'
                            style={FormStyle}
                          >
                            <Input.Search
                              className='input-search-size-number-10'
                              allowClear={true}
                              onSearch={(value, event) => {
                                if (event.target.localName === 'input') {
                                  // ✕ボタン押下
                                  this.clearPartnerItem(2)
                                } else {
                                  // 虫眼鏡マーク押下
                                  this.getPersonalInfo('partner2', 'partnerName2')
                                }
                              }}

                              // Enterが押された処理
                              onPressEnter={(event) => {
                                const personalNum = event.target.value
                                this.onChangePersonalNumberID(personalNum, 'partnerName2')
                              }}

                              // 保存ボタン状態変更
                              onChange={() => this.onChangeSaveBtn()}
                            />
                          </Form.Item>

                          <Form.Item
                            name='partnerName2'
                            style={{ marginBottom: 0 }}>
                            <div>{this.formRef.current?.getFieldValue('partnerName2')}</div>
                          </Form.Item>
                        </div>

                        <div>
                          <Form.Item
                            label='続柄'
                            style={{ marginBottom: 0, marginLeft: 15 }}
                          >
                            <Select
                              style={{ width: '100px' }}
                              value={this.state.relationship2}
                              onChange={value => {
                                this.onChangeBelongs(value, 'relationship2')
                                this.onChangeSaveBtn()
                              }}
                            >
                              {this.state.relationship === undefined ? [] : this.state.relationship.map((item, index) => (
                                <Select.Option
                                  key={item.id}
                                  value={item.node_code_name}
                                >
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label='血縁関係'
                      labelStyle={{ width: '120px' }}
                    >
                      <div className='box_inner_horizontal' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='box_inner_horizontal' style={{ width: 'fit-content' }} >
                          <Form.Item
                            name='partner3'
                            style={{ marginBottom: 0 }}
                          >
                            <Input.Search
                              className='input-search-size-number-10'
                              allowClear={true}
                              onSearch={(value, event) => {
                                if (event.target.localName === 'input') {
                                  // ✕ボタン押下
                                  this.clearPartnerItem(3)
                                } else {
                                  // 虫眼鏡マーク押下
                                  this.getPersonalInfo('partner3', 'partnerName3')
                                }
                              }}

                              // Enterが押された処理
                              onPressEnter={(event) => {
                                const personalNum = event.target.value
                                this.onChangePersonalNumberID(personalNum, 'partnerName3')
                              }}

                              // 保存ボタン状態変更
                              onChange={() => this.onChangeSaveBtn()}
                            />
                          </Form.Item>

                          <Form.Item
                            name='partnerName3'
                            style={{ marginBottom: 0 }}
                          >
                            <div>{this.formRef.current?.getFieldValue('partnerName3')}</div>
                          </Form.Item>
                        </div>

                        <div>
                          <Form.Item
                            label='続柄'
                            style={{ marginBottom: 0, marginRight: 0 }}
                          >
                            <Select
                              style={{ width: '100px' }}
                              value={this.state.relationship3}
                              onChange={value => {
                                this.onChangeBelongs(value, 'relationship3')
                                this.onChangeSaveBtn()
                              }}
                            >
                              {this.state.relationship === undefined ? [] : this.state.relationship.map((item, index) => (
                                <Select.Option
                                  key={item.id}
                                  value={item.node_code_name}
                                >
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Tabs.TabPane>

              {/* 医師 */}
              <Tabs.TabPane tab={(this.formRef.current?.getFieldValue('doctor') || this.state.doctorMemoHistory.length > 0) ? '医師*' : '医師'} key='2'>
                <div className='box_inner_vertical'>
                  <div>{'特記事項'}</div>
                  {/* 個人に紐づくメモ */}
                  <Form.Item name='doctor'>
                    <TextArea
                      style={{ height: 300 }}
                      onChange={() => this.onChangeSaveBtn()}
                    />
                  </Form.Item>

                  <div>{'備考'}</div>
                  {/* 予約に紐づくメモ */}
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.doctorMemoHistory}
                    rowKey={(record) => record.id}
                    pagination={false}
                    scroll={{ x: 'max-content', y: 150 }}
                  >
                    <Table.Column
                      title='受診日'
                      className='column-size-date'
                      dataIndex='visit_date_on'
                      render={(value) => {
                        return (
                          <div>{moment(value).format('YYYY/MM/DD (ddd)')}</div>
                        )
                      }}
                    />
                    <Table.Column
                      title='備考（履歴）'
                      dataIndex='memo'
                      render={(value) => (
                        <div style={{ whiteSpace: 'pre-wrap', }} >
                          {value}
                        </div>
                      )}
                    />
                    <Table.Column
                      key='action'
                      fixed='right'
                      className='column-size-2'
                      title={
                        <Button
                          size='small'
                          type='primary'
                          disabled={!this.props.reservation_number > 0 || this.state.doctorDisabledFlag}
                          icon={<PlusOutlined />}
                          onClick={() => this.memoEditDisplay([], 'doctor', 1)}
                        />
                      }
                      render={(value, record) => {
                        return (
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                            onClick={() => this.memoEditDisplay(record, 'doctor', 0)}
                          />
                        )
                      }}
                    />
                  </Table>
                </div>
              </Tabs.TabPane>

              {/* 看護 */}
              <Tabs.TabPane tab={(this.formRef.current?.getFieldValue('nurse') || this.state.nurseMemoHistory.length > 0) ? '看護*' : '看護'} key='3'>
                <div className='box_inner_vertical'>
                  <div>{'特記事項'}</div>
                  {/* 個人に紐づくメモ */}
                  <Form.Item name='nurse'>
                    <TextArea
                      style={{ height: 300 }}
                      onChange={() => this.onChangeSaveBtn()}
                    />
                  </Form.Item>

                  <div>{'備考'}</div>
                  {/* 予約に紐づくメモ */}
                  <Table
                    bordered
                    size='small'
                    pagination={false}
                    dataSource={this.state.nurseMemoHistory}
                    rowKey={(record) => record.id}
                    scroll={{ x: 'max-content', y: 150 }}
                  >
                    <Table.Column
                      title='受診日'
                      dataIndex='visit_date_on'
                      className='column-size-date'
                      render={(value) => {
                        return (
                          <div>{moment(value).format('YYYY/MM/DD (ddd)')}</div>
                        )
                      }}
                    />
                    <Table.Column
                      title='備考（履歴）'
                      dataIndex='memo'
                      className='column-size-60'
                    />
                    <Table.Column
                      key='action'
                      fixed='right'
                      className='column-size-2'
                      title={
                        <Button
                          size='small'
                          type='primary'
                          icon={<PlusOutlined />}
                          disabled={!this.props.reservation_number > 0}
                          onClick={() => this.memoEditDisplay([], 'nurse', 1)}
                        />
                      }
                      render={(value, record) => {
                        return (
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                            onClick={() => this.memoEditDisplay(record, 'nurse', 0)}
                          />
                        )
                      }}
                    />
                  </Table>
                </div>
              </Tabs.TabPane>

              {/* 事務 */}
              <Tabs.TabPane tab={(this.formRef.current?.getFieldValue('business') || this.state.businessMemoHistory.length > 0) ? '事務*' : '事務'} key='4'>
                <div className='box_inner_vertical'>
                  <div>{'特記事項'}</div>
                  {/* 個人に紐づくメモ */}
                  <Form.Item name='business'>
                    <TextArea
                      style={{ height: 300 }}
                      onChange={() => this.onChangeSaveBtn()}
                    />
                  </Form.Item>

                  <div>{'備考'}</div>
                  {/* 予約に紐づくメモ */}
                  <Table
                    bordered
                    size='small'
                    pagination={false}
                    dataSource={this.state.businessMemoHistory}
                    rowKey={(record) => record.id}
                    scroll={{ x: 'max-content', y: 150 }}
                  >
                    <Table.Column
                      title='受診日'
                      dataIndex='visit_date_on'
                      className='column-size-date'
                      render={(value) => {
                        return (
                          <div>{moment(value).format('YYYY/MM/DD (ddd)')}</div>
                        )
                      }}
                    />

                    <Table.Column
                      title='備考（履歴）'
                      dataIndex='memo'
                      className='column-size-60'
                    />

                    <Table.Column
                      key='action'
                      fixed='right'
                      className='column-size-2'
                      title={
                        <Button
                          size='small'
                          type='primary'
                          icon={<PlusOutlined />}
                          style={{ display: this.state.businessMemoHistory.length >= 1 ? 'none' : '' }} //単日メモが既にある場合は追加ボタン非表示
                          disabled={!this.props.reservation_number > 0 || this.state.disabledFlag}
                          onClick={() => this.memoEditDisplay([], 'business', 1)}
                        />
                      }
                      render={(value, record) => {
                        return (
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                            onClick={() => this.memoEditDisplay(record, 'business', 0)}
                          />
                        )
                      }}
                    />
                  </Table>
                </div>
              </Tabs.TabPane>
            </Tabs>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.viewAllMemos()}
              >
                <span className='btn_label'>
                  全体
                </span>
              </Button>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.savePersonalMemo()}
                disabled={this.state.saveBtnFlag}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.AllMemoFunctionUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    AllMemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemoFunction)
