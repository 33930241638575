import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Input, message, Modal, Tooltip, Menu, Dropdown, Popover } from 'antd'
import { MoreOutlined, SaveOutlined, PrinterOutlined, DeleteOutlined } from '@ant-design/icons'
// import WS2635011_AutoJudge from 'pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS2635011_AutoJudge.jsx'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import axios from 'configs/axios'


class ImageDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'キー画像'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      image: {},
      // images: [],

      style: {
        width: 'fit-content',
        minWidth: '300px',
        height: '300px',
        marginLeft: 'auto',
        marginRight: 'auto'
      },

      selectedPlace: '',
      filePathList: [],
      dragIndex: 0
    }
  }

  componentDidMount = () => {
    let param = {
      categoryCode: this.props.categoryCode,
      personalNumberId: this.props.personal_number_id,
      reservationNumber: this.props.reservation_number,
    }
    ResultDisplayAction.getImageRefelenceUrl(param)
      .then(res => {
        if (res) {
          // window.open(res, null, 'top=100,left=800,width=800,height=800')
          // URL実行してプログラムを開く
          this.runImageRefelenceUrl(res)
        }
        this.getTargetPaths()
      })
  }

  /**
   * URL実行してプログラムを開く
   * @param {*} data
   */
  runImageRefelenceUrl = (data) => {

    // const params2 = '&exe=' + data.exe + '&personal=' + data.personal + '&modality=' + data.modality + '&date=' + data.date


    // // URL実行
    // window.open(data.url + params2, null)


    let params = {
      exe: data.exe,
      personal: data.personal,
      modality: data.modality,
      date: data.date,
    }

    // URL実行
    axios({
      method: 'post',
      url: data.url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin': '*'
      },
      params: params,
    })
  }

  /**
   * 画像取得
   */
  getTargetPaths = () => {
    let params = {
      personalNumberId: this.props.personal_number_id ?? '',
      reservationNumber: this.props.reservation_number ?? '',
      categoryCode: this.props.categoryCode ?? ''
    }

    ResultDisplayAction.getTargetPaths(params)
      .then(res => {
        for (let i = 1; i <= res.length; i++) {
          let params2 = {
            filePath: res[i - 1]
          }

          let placeNum = res[i - 1].substr(-7, 3)
          let place = 'image_place' + placeNum

          ResultDisplayAction.getImage(params2)
            .then(res => {

              var reader = new FileReader();
              reader.onload = function (event) {
                document.getElementById(place).src = event.target.result;
              };

              reader.readAsDataURL(res);
            })
        }
        this.setState({ filePathList: res })
      })
  }

  /**
   * ペースト処理
   * @param {*} e 
   */
  onPaste = (e) => {
    if (this.state.selectedPlace) {
      let place = this.state.selectedPlace
      let items = e.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        if (item.type.indexOf("image") != -1) {
          // 画像のみサーバへ送信する
          var file = item.getAsFile();

          var reader = new FileReader();
          reader.onload = function (event) {
            document.getElementById(place).src = event.target.result;
          };

          reader.readAsDataURL(file);

          this.uploadImages(file)
        }
      }
    }
  }

  /**
   * ドラッグ開始
   * @param {*} e 
   * @param {*} index 
   */
  onDrag(e, index) {
    this.setState({
      dragIndex: index
    })
  }

  /**
   * ドロップ処理
   * @param {*} e 
   * @param {*} index 
   */
  dropEvent(e, index) {
    // ドラック処理でstateに入れた値
    let dragIndex = this.state.dragIndex
    // どこにドロップしたのか
    let id = e.target.id
    // 保存した画像リストが入った配列
    let filePathList = this.state.filePathList
    // ファイル名変更の変数
    let reName, oldName
    let replacementFileName = []
    for (let i = 0; i < filePathList.length; i++) {
      // ドラックした場所に画像が含まれているか
      if (filePathList[i].includes(dragIndex)) {
        // ファイル名置き換え
        reName = filePathList[i].replace(dragIndex, index)
        // ファイル名置き換え
        oldName = filePathList[i].replace(index, dragIndex)
        // 置き換えだとファイル名が被る可能性があるので、一旦ファイル名を被らない名前に変更
        replacementFileName.push(filePathList[i].replace(dragIndex, index + '_replacementFileName'))
      }
    }
    // ドラック開始場所が登録されていなかった場合は下記の処理
    // 不要の場合は消してください
    if (oldName === undefined && reName === undefined) {
      for (let l = 0; l < filePathList.length; l++) {
        // ドラックした場所に画像が含まれているか
        if (filePathList[l].includes(index)) {
          // ファイル名置き換え
          reName = filePathList[l].replace(index, dragIndex)
          // ファイル名置き換え
          oldName = filePathList[l].replace(dragIndex, index)
          // 置き換えだとファイル名が被る可能性があるので、一旦ファイル名を被らない名前に変更
          replacementFileName.push(filePathList[l].replace(dragIndex, index + '_replacementFileName'))
        }
      }
    }
    // 入れ替える対象がなかった時はAPIを呼ばない
    if (reName !== undefined && oldName !== undefined) {
      let params = {
        personal_number_id: this.props.personal_number_id ?? '',
        reservation_number: this.props.reservation_number ?? '',
        reName: reName,
        oldName: oldName,
        replacementFileName: replacementFileName,
        categoryCode: this.props.categoryCode ?? '',
        fileNumber: dragIndex
      }
      ResultDisplayAction.reName(params)
        .then(res => {
          // resetFieldsなしで初期表示を呼ぶと入れ替えができなかったため
          this.formRef?.current?.resetFields()
          this.getTargetPaths()
        })
    }
  }

  selectPlace = (place) => {
    this.setState({ selectedPlace: place })
  }

  uploadImages = (file) => {
    const sendFile = new FormData();
    sendFile.append("personalNumberId", this.props.personal_number_id ?? '');
    sendFile.append("reservationNumber", this.props.reservation_number ?? '');
    sendFile.append("selectedPlace", this.state.selectedPlace ?? '');
    sendFile.append("image", file);
    sendFile.append("categoryCode", this.props.categoryCode ?? '');
    ResultDisplayAction.uploadImages(sendFile)
      .then(res => { this.setState({ filePathList: res }) })
  }

  deleteImage = (deletePlace) => {
    for (let i = 1; i <= this.state.filePathList.length; i++) {
      let placeNum = 'image_place' + this.state.filePathList[i - 1].substr(-7, 3)
      if (deletePlace == placeNum) {
        let params = {
          personalNumberId: this.props.personal_number_id,
          reservationNumber: this.props.reservation_number,
          filePath: this.state.filePathList[i - 1]
        }
        ResultDisplayAction.deleteImage(params)
          .then(res => {
            document.getElementById(deletePlace).src = ''
            this.setState({ filePathList: res })
          })
      }
    }
  }

  confirm = () => {
    let params = {
      personalNumberId: this.props.personal_number_id,
      reservationNumber: this.props.reservation_number,
      categoryCode: this.props.categoryCode ?? ''
    }
    ResultDisplayAction.tidyingUp(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  render() {
    let borderStatus = '3px solid #cfcfcf'
    return (
      <div className='image-display' >
        <Card title='キー画像'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item name='image1'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place001')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='001'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place001' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place001')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_001')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_001')}
                    >
                      <img id="image_place001" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
                <Form.Item name='image2'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place002')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='002'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place002' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place002')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_002')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_002')}
                    >
                      <img id="image_place002" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
                <Form.Item name='image3'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place003')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='003'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place003' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place003')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_003')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_003')}
                    >
                      <img id="image_place003" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
                <Form.Item name='image4'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place004')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='004'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place004' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place004')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_004')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_004')}
                    >
                      <img id="image_place004" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
              </div>
              <div className='box_inner_horizontal'>
                <Form.Item name='image5'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place005')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='005'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place005' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place005')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_005')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_005')}
                    >
                      <img id="image_place005" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
                <Form.Item name='image6'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place006')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='006'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place006' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place006')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_006')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_006')}
                    >
                      <img id="image_place006" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
                <Form.Item name='image7'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place007')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='007'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place007' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place007')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_007')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_007')}
                    >
                      <img id="image_place007" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
                <Form.Item name='image8'>
                  <Popover
                    placement="rightTop"
                    content={
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteImage('image_place008')
                        }}
                        size='small'
                      >
                        {/* <span className='btn_label'>
                          削除
                        </span> */}
                      </Button>
                    }
                    trigger="click"
                  >
                    <div
                      id='008'
                      className='keyImageSettingPlace'
                      style={{
                        background: this.state.selectedPlace == 'image_place008' ? '#b5dbff' : ''
                      }}
                      onPaste={(e) => this.onPaste(e)}
                      onClick={() => this.selectPlace('image_place008')}
                      // ドラッグアンドドロップ処理
                      onDrag={(e) => this.onDrag(e, '_008')}
                      // onDragOverがないとonDropが機能しないので注意
                      onDragOver={(event) => event.preventDefault()}
                      onDrop={(e) => this.dropEvent(e, '_008')}
                    >
                      <img id="image_place008" style={{ maxWidth: '274px', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                  </Popover>
                </Form.Item>
              </div>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  onClick={() => this.confirm()}
                // onClick={() => this.test()}
                >
                  {'確定'}
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageDisplay)
