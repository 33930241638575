import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";


class WS0400001_OptionalInputSub extends React.Component {
  
  constructor(props) {
    super(props);

    // document.title = 'オプション入力         SUB';

    this.state = {
      dataSource: [
        {
          key: '1',
          serial_number: 4,
          item: 'test',
          content: 'testtesttesttest'
        },
        {
          key: '2',
          serial_number: 8,
          item: 'test',
          content: 'testtesttesttest'
        }
      ],

      columns: [
        {
          title: '連番',
          dataIndex: 'serial_number',
          key: 'serial_number',
          width: 50
        },
        {
          title: '項目',
          dataIndex: 'item',
          key: 'item',
          width: 150
        },
        {
          title: '内容',
          dataIndex: 'content',
          key: 'content',
        },
      ]
    };
  }

  render() {
    const { dataSource, columns } = this.state

    return (
      <div className="optional-input-sub">
        <Card title="オプション入力SUB" className="mb-2">
          <Table bordered 
            columns={columns}
            dataSource={dataSource}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: 1,
              pageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}
            rowKey={(record) => record.key}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0400001_OptionalInputSub);
