import UserParamMaintainService from "services/SystemMaintenance/UserParamMaintain/UserParamMaintainService"
import { message } from "antd";

const UserParamMaintainAction = {
  getInit() {
    return UserParamMaintainService.getInit()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  save(data) {
    return UserParamMaintainService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  delete2(data) {
    console.log('通過');
    return UserParamMaintainService.delete2(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data;
      })
      .catch((err) => {
        message.error(err.message);
      });
  },

  UserParamInputIndex(data) {
    return UserParamMaintainService.UserParamInputIndex(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  UserParamInputSave(data) {
    return UserParamMaintainService.UserParamInputSave(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  save2(data) {
    console.log('通過');
    return UserParamMaintainService.save2(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data;
      })
      .catch((err) => {
        message.error(err.message);
      });
  },
}
export default UserParamMaintainAction;
