import React from "react";
import { connect } from "react-redux";

import { Card, Form, Table, Button, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined, CheckOutlined } from "@ant-design/icons";
import ConditionsConfigurationEdit from "pages/ZZ_Others/SANAI_ProgressManagement/ConditionsConfigurationEdit.jsx"
import SearchConditionsConfigurationAction from "redux/Others/ProgressManagement/SearchConditionsConfiguration.action.js"
import ModalDraggable from 'components/Commons/ModalDraggable'

class SearchConditionsConfiguration extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "検索条件設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      courseData: []
    };
  }

  componentDidMount() {
    this.getScreenData()
  }

  /**
   * 初期表示処理
   */
  getScreenData() {
    SearchConditionsConfigurationAction.getScreenData()
      .then(res => {
        this.setState({
          dataSource: res.result ? res.result : [],
          courseData: res.stm ? res.stm : []
        })
      })
  }

  showConditionsConfigurationEdit(record = null) {
    let newflag = false
    let data = []

    if (!record) {
      newflag = true
    } else {
      data = {
        id: record.id,
        medical_exam_course: record.medical_exam_course,
        conditions_age_code: record.conditions_age_code,
        enabled: record.enabled,
      }
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <ConditionsConfigurationEdit
            record={data}
            newflag={newflag}
            courseData={this.state.courseData}

            //新規・編集
            onUpdate={(output) => {
              this.closeModal()
              this.getScreenData()
            }}
          />
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Card title="検索条件設定">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <Table
              style={{ cursor: 'pointer' }}
              dataSource={this.state.dataSource}
              size="small"
              bordered={true}
              loading={this.state.isLoadding}
              pagination={false}
              rowKey={(iten) => iten.id}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: async () => {
                    this.showConditionsConfigurationEdit(record)
                  }
                };
              }}
            >
              <Table.Column
                title="コースコード"
                className="column-size-8"
                dataIndex="medical_exam_course"
              />

              <Table.Column
                title="コース名称"
                className="column-size-30"
                dataIndex="course_name_short_name"
              />

              <Table.Column
                title="年齢条件"
                dataIndex="age_title"
              />

              <Table.Column
                title="有効"
                width={45}
                dataIndex="enabled"
                align="center"
                render={(value, record, index) => {
                  if (value) {
                    return <CheckOutlined />
                  } else {
                    return ''
                  }
                }}
              />

              <Table.Column
                width={40}
                title={
                  //新規登録のボタン
                  <Button
                    size="small"
                    type="primary"
                    style={{ textAlign: "center" }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.showConditionsConfigurationEdit()
                    }}
                  ></Button>
                }

                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="変更"
                            onClick={() => {
                              this.showConditionsConfigurationEdit(record)
                            }}
                          >変更
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchConditionsConfiguration);