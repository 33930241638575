import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, InputNumber, Input, Select, Button, Table, Row, Col, Space, DatePicker, Modal, Menu, Dropdown, message, Spin, Tooltip } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1174006_CourseCopyingFunction extends React.Component {

  formRef = React.createRef();

  static propTypes = {
        Li_course_code            : PropTypes.any,
        Li_course_name_short_name : PropTypes.any,   
        Li_course_name_formal     : PropTypes.any, 
  };

  constructor(props) {
    super(props);
    // document.title = 'コース複写機能';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  componentDidMount = () => {   
    this.formRef.current.resetFields();
    this.formRef.current?.setFieldsValue({
      course_code            : this.props.Li_course_code? this.props.Li_course_code : '',
      course_name_short_name : this.props.Li_course_name_short_name? this.props.Li_course_name_short_name : '',
      course_name_formal     : this.props.Li_course_name_formal? this.props.Li_course_name_formal : '',
  })
  }

  componentDidUpdate(prv) {
    
    if (this.props !== prv) {
     
      this.formRef.current.resetFields();
      this.formRef.current?.setFieldsValue({
        course_code            : this.props.Li_course_code? this.props.Li_course_code : '',
        course_name_short_name : this.props.Li_course_name_short_name? this.props.Li_course_name_short_name : '',
        course_name_formal     : this.props.Li_course_name_formal? this.props.Li_course_name_formal : '',
    })
      // this.getScreenData(); 
    }
  } 

  onFinish=(values)=> {
    VenusApiRoutesV2.callApi("API001174006004",{CopyCourseNamePositive:values.CopyCourseNamePositive})
      .then(res => {
        if(res==="unknown error"){
          message.error('エラーが発生しました')
        }else{
          this.setState({
            // dataSource: res ? res.data : []
          })  
          this.props.onFinishScreen()
        }
      })      
      
      
  }  
  render() {
    return (
      <div className="course-copying-function">
        <Card title="コース複写機能">        
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row gutter={16}>
              <Col span={8} >
                <Form.Item name="course_code" label="複写元コース">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name="course_name_short_name" label="" >
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name="course_name_formal" label="" >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8} >
                <Form.Item name="CopyCourse" label="複写先コース" >
                  <Input type="text"
                    onBlur={(e) => {
                      VenusApiRoutesV2.callApi("API001174006002", { CopyCourse: e.target.value })
                        .then(res => {
                          this.closeModal();
                        });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name="CopyCourseNameAlmost" label="" >
                  <Input type="text"
                    onBlur={(e) => {
                      VenusApiRoutesV2.callApi("API001174006003", { CopyCourseNameAlmost: e.target.value })
                        .then(res => {
                          this.closeModal();
                        });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name="CopyCourseNamePositive" label="" >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8} offset={16} align="end">
                <Button type="primary" htmlType="submit">実  行</Button>
              </Col>
            </Row>
          </Form>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal()
            }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1174006_CourseCopyingFunction);
