import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, Table, Row, Col, Form, message, Dropdown, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import PersonalOfficeSearchQueryAction from 'redux/ReservationBusiness/PersonalReserveProcess/PersonalOfficeSearchQuery.actions'
import WS0343001_PersonalInfoMaintain from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS0381001_PersonalOfficeSearchQuery extends React.Component {
  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '個人事業所検索・照会'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRow: {},
      rowSelect: []
    }
  }

  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    const params = {
      PersonalNum: this.isEmpty(this.props.Li_PersonalNum) ? '' : this.props.Li_PersonalNum
    }
    PersonalOfficeSearchQueryAction.index(params)
      .then(res => {
        this.setState({
          dataSource: res,
          selectedRow: res?.[0],
          rowSelect: res[0]
        })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  ShowWS0343001_PersonalInfoMaintain() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true, width: 1500,
        component: (
          <WS0343001_PersonalInfoMaintain
            Li_PersonalNum={this.props.Li_PersonalNum}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='personal-office-search-query'>
        <Card title='個人事業所検索・照会'>
          <Form ref={this.formRef} autoComplete='off' >
            <Table
              dataSource={this.state.dataSource}
              pagination={false}
              size='small'
              bordered
              rowKey={(record) => record.id}
              rowClassName={(record, index) => record.id === this.state.rowSelect?.id ? 'table-row-light' : ''}
              onRow={(record, index) => ({
                onClick: (event) => this.setState({ rowSelect: record }),
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      Lio_OfficeCode: record.office_code,
                      Lio_BranchStoreCode: record.branch_store_code,
                      recordData: record,
                      data: this.state.dataSource
                    })
                  }
                }
              })}

            >
              <Table.Column title='コード' dataIndex='office_code' width={100}
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      <span>{record?.office_code}</span><br />
                      <span>{record?.branch_store_code === 0 ? '' : record?.branch_store_code}</span>
                    </div>
                  )
                }} />
              <Table.Column title='メモ' dataIndex='Expression_14' width={50} align='center'
                render={(value, record, index) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    style={{ display: 'inline-block' }}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true, width: 1050,
                          component: (
                            <WS2585001_OfficeInfoInquirySub
                              Li_OfficeCode={record.office_code}
                              Li_BranchCode={record.branch_store_code}
                              onFinishScreen={(output) => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  ></Button>
                )} />
              <Table.Column title='事業所名' dataIndex='office_kanji_name' render={(value, record, index) => {
                return <>
                  <span>{value}</span><br />
                  <span>{record?.Expression_11}</span>
                </>
              }} />
              <Table.Column title='保険者番号' dataIndex='insurer_number' render={(value, record, index) => {
                return <>
                  <span>{value}</span><br />
                  <span>{record?.insurer_kanji_name}</span>
                </>
              }} />
              <Table.Column title='保険証記号/番号' dataIndex='insurer_card_symbol' render={(value, record, index) => {
                return <>
                  <span>{value}</span><br />
                  <span>{record?.insurer_card_number}</span>
                </>
              }} />
              <Table.Column width={40}
                render={(value, record, index) => {
                  return (
                    <Dropdown
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='menu-1'
                            onClick={() => this.ShowWS0343001_PersonalInfoMaintain()}>
                            個人情報
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button style={{ verticalAlign: 'top', marginLeft: 2 }} size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )
                }} />
            </Table>
            <div className='box_border' style={{ marginTop: '10px' }}>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label='職　　種'><span>{this.state.rowSelect?.Expression_13} </span></Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item label='保険者請求'><span>{this.state.rowSelect?.Expression_16}</span></Form.Item>
                </Col>
                <Col span={12}></Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label='雇用形態'>
                    <span>{this.state.rowSelect?.Expression_12}</span>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item label='受給者番号'>
                    <span>{this.state.rowSelect?.recipient_number}</span>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {/* <Row gutter={16}>
              <Col span={24}>
                <Button type='primary' style={{ float: 'right' }} onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      Lio_OfficeCode: this.state.selectedRow?.office_code,
                      Lio_BranchStoreCode: this.state.selectedRow?.branch_store_code,
                      recordData: this.state.selectedRow
                    })
                  }
                }} >
                  選　択
                </Button>
                <Button type='primary' style={{ float: 'right', marginRight: '10px' }}
                  onClick={() => this.ShowWS0343001_PersonalInfoMaintain()} >
                  個人情報
                </Button>
              </Col>
            </Row> */}
            {/* <div className='box_button_bottom_right'>
              <Button type='primary' style={{ float: 'right' }} onClick={() => {
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    Lio_OfficeCode: this.state.rowSelect?.office_code,
                    Lio_BranchStoreCode: this.state.rowSelect?.branch_store_code,
                    recordData: this.state.rowSelect
                  })
                }
              }} >
                選　択
              </Button>
            </div> */}
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0381001_PersonalOfficeSearchQuery)
