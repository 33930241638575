import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, DatePicker } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1485009_NewHistory extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props);

    // document.title = '新規履歴';
    this.btnClick=this.btnClick.bind(this)
    this.state = {
    };

    // this.onClick=this.onClick.bind(this);
  }

  // onFinish(values) {

  // }

  btnClick() {
    const date = this.formRef.current?.getFieldValue("Date");
    let day_day = date.format("YYYY/MM/DD")

    VenusApiRoutesV2.callApi("API001485009004", { History: day_day })
      .then((res) => {
        console.log("res:", res)
        this.props.onFinishScreen(day_day);
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  render() {
    return (
      <div className="new-history">
        <Card title="新規履歴">
          <Form
            ref={this.formRef}
            // onFinish={this.onFinish}
          >
            <Form.Item
              name="Date"
              label="年月"
            >
              <DatePicker format={"YYYY/MM"} picker="month" />
            </Form.Item>
            <Form.Item>
              <Button type="primary"
                onClick={()=>{
                  this.btnClick();
                }}
              >作成</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1485009_NewHistory);
