import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Button, Card, Form, Input, message } from "antd";
import { ArrowDownOutlined, CopyOutlined } from '@ant-design/icons';
import CopyingProcessAction from "redux/AdvancePreparation/DocumentManageMaintain/CopyingProcess.action";

const labelCol = {
  labelCol: { style: { width: '120px' } },
}

class WS0640004_CopyingProcess extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_CopySourceCode: PropTypes.any,
    Li_CopyName: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '複写処理';

    this.state = {
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    this.setFormValue();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setFormValue();
    }
  }

  setFormValue() {
    this.formRef.current?.setFieldsValue({
      Li_CopySourceCode: this.props.Li_CopySourceCode,
      escort_name: this.props.Li_CopyName,
      CopyCode: ''
    })
  }

  onFinish(values) {

    let params = {
      ...values,
      escort_code: values.Li_CopySourceCode
    }

    if (!params.CopyCode) {
      message.error('複写先が無効です')
    } else {
      if (params.CopyCode === params.escort_code) {
        message.error('複写先が同一です')
      } else {
        CopyingProcessAction.copyData(params)
          .then((res) => {
            if (this.props.onFinishScreen) {
              this.props.onFinishScreen({
              });
            }
          })
          .catch((err) => {
            const res = err.response;
            if (!res || !res.data || !res.data.message) {
              message.error("エラーが発生しました");
              return;
            }
            message.error(res.data.message);
          });
      }
    }
  }

  render() {
    return (
      <div className="copying-process">
        <Card title="複写処理">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_inner_vertical' style={{ padding: '0px', alignItems: 'center' }}>
              <div className='box_inner_vertical specific-box'>
                <span className='specific-title'>
                  複写元
                </span>
                <Form.Item name="Li_CopySourceCode" label="エスコートコード"  {...labelCol}>
                  <Input readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>

                <Form.Item name="escort_name" label="エスコート名称"  {...labelCol}>
                  <Input readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
              </div>

              <div>
                <ArrowDownOutlined style={{ fontSize: 24 }} />
              </div>

              <div className='box_inner_vertical specific-box'
                style={{ marginTop: 0, marginBottom: 0 }}>
                <span className='specific-title'>
                  複写先
                </span>
                <Form.Item name="CopyCode" label="エスコートコード" {...labelCol}>
                  <Input
                    type="number"
                    className="input-size-number-5" />
                </Form.Item>

                <Form.Item name="escortName" label="エスコート名称" {...labelCol}>
                  <Input />
                </Form.Item>
              </div>

            </div>

            <div className='box_button_bottom_right'
              style={{ marginTop: 0 }}
            >
              <Button
                htmlType="submit"
                type='primary'
                style={{ float: 'right' }}
                icon={<CopyOutlined />}
              >
                <span className='btn_label'>
                  複写
                </span>
              </Button>
            </div>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0640004_CopyingProcess);
