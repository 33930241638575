import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";
import moment from 'moment';
import ModalDraggable from "components/Commons/ModalDraggable";
import ListProcessAction from "redux/SpecificInsureGuide/SpecificInsureGuideSettleProcessList/ListProcess.action.js";
import WS1464004_AgreementInformation from "pages/TH_SpecificInsureGuide/V4TH0011102_SpecificInsureGuideSettleProcessList/WS1464004_AgreementInformation.jsx";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

class WS1464003_ListProcess extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '一覧処理';

    this.state = {
      dataSource: [],
      isLoading: false,
      pagination: {
        size: 1,
        pageSize: 10,
        defaultCurrent: 1,
        showQuickJumper: false,
      },
      selectedRows: {},
      selectedRowKeys: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    this.setState({ isLoading: true });
    let params = {
      Li_DateF: moment(this.props.DateFScreen).format('YYYY-MM-DD'),
      Li_DateT: moment(this.props.DateTScreen).format('YYYY-MM-DD'),
      Li_InsurerNum: this.props.InsuranceNum
    }
    ListProcessAction.index(params)
      .then(res => {
        if (res?.length > 0) {
          this.setState({
            dataSource: res,
            isLoading: false,
            selectedRows: res[0],
            selectedRowKeys: [res[0].id]
          });
        } else {
          this.setState({
            dataSource: res,
            isLoading: false
          });
        }
      })
  }

  agreementInformation(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS1464004_AgreementInformation
            data_on={record.W1_consult_date}
            id={record.W1_id_cd}
            onFinishScreen={() => {
              this.forceUpdate()
              this.getScreenData()
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    const { dataSource, isLoading, pagination, selectedRows, selectedRowKeys } = this.state;
    return (
      <Card className="mb-2" title="特定保健指導決算一覧">
        <Table
          bordered
          dataSource={dataSource}
          loading={isLoading}
          scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
          pagination={false}
          size={'small'}
          rowKey={(record) => record.id}
          onRow={(record, index) => {
            return {
              onDoubleClick: () => this.agreementInformation(record),
            }
          }}
        >
          <Table.Column
            className="column-size-10"
            title="受診日"
            dataIndex="W1_consult_date"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div>{value}</div>

            )} />

          <Table.Column
            className="column-size-5"
            title="ＩＤ"
            dataIndex="W1_id_cd"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div style={{ textAlign: "right" }}>{value}</div>
            )} />

          <Table.Column
            className="column-size-40"
            title="カナ氏名"
            dataIndex="W1_kana_name"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div>{value}</div>
            )}
          />

          <Table.Column
            className="column-size-40"
            title="漢字氏名"
            dataIndex="W1_kanji_name"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div>{value}</div>
            )}
          />

          <Table.Column
            className="column-size-4"
            title="性別"
            dataIndex="Gender"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div style={{ textAlign: "center" }}>{value}</div>
            )}
          />

          <Table.Column
            className="column-size-4"
            title="続柄"
            dataIndex="Relationship"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div style={{ textAlign: "center" }}>{value}</div>
            )}
          />

          <Table.Column
            className="column-size-10"
            title="生年月日"
            dataIndex="W1_birthday"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div>{value}</div>
            )}
          />

          <Table.Column
            className="column-size-4"
            title="年齢"
            dataIndex="W1_age"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div style={{ textAlign: "center" }}>{value}</div>
            )}
          />

          <Table.Column
            className="column-size-10"
            title="保険者番号"
            dataIndex="W1_insurer_num"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div style={{ textAlign: "right" }}>{value == 0 ? "" : value}</div>
            )}
          />

          <Table.Column
            title="契約番号"
            dataIndex="contract_number"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div>
                {value}{" / "}
                {record.determining_billing_info_contract_year}{" / "}
                {record.determining_billing_info_contract_serial_number}
              </div>

            )}
          />

          <Table.Column
            title="契約名"
            dataIndex="contract_name"
            showSorterTooltip={false}
            render={(value, record, index) => (
              <div>
                {value}{" / "}
                {record.Expression_11}{" / "}
                {record.Expression_12}
              </div>
            )}
          />
        </Table>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = (userReducer, alertReducer) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1464003_ListProcess);
