import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Table, Button, message } from 'antd'
import { getDataInsuranceSymbolInquiryAction } from 'redux/ResultOutput/MedicalExamDataOutputCsv/InsuranceSymbolInquiry.actions'

class WS0784005_InsuranceSymbolInquiry extends React.Component {

  static propTypes = {
    Lo_InsurerCardSymbol: PropTypes.number,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '保険記号照会'

    this.state = {
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: false,
        showSizeChanger: false,
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {}
    }
  }

  componentDidMount = () => {
    this.loadData({
      Office: this.props.Goffice,
      GbranchStoreF: this.props.GbranchStoreF,
      GbranchStoreT: this.props.GbranchStoreT,
    })
  }

  loadData = () => {
    this.setState({ isLoading: true, dataSource: [], rowSelect: {} })
    getDataInsuranceSymbolInquiryAction()
      .then(res => {
        if (res?.data) {
          this.setState({ dataSource: res.data })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  returnValue = (record) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lo_GinsuranceSign: record.GinsuranceSign,
        Lo_InsurerCardSymbol: record.insurer_card_symbol,
        recordData: record
      })
    }
  }

  render() {
    return (
      <div className='insurance-symbol-inquiry'>
        <Card title='保険記号照会'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({
              onDoubleClick: () => this.returnValue(record)
            })}
          >
            <Table.Column
              title='事業所コード'
              dataIndex='Expresstion_4'
              width={150}
              render={(value) =>
                <div style={{ textAlign: 'right', marginRight: '20px' }}>
                  {value}
                </div>
              }
            />
            <Table.Column
              title='事業所名（漢字）'
              dataIndex='office_kanji_name'
            />
            <Table.Column
              title='保険記号'
              dataIndex='GinsuranceSign'
              width={170}
              render={(text) => text === '0' ? null : text}
            />
          </Table>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0784005_InsuranceSymbolInquiry)
