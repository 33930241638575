import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Input, Modal, message } from 'antd'

import moment from 'moment'
import ModalDraggable from "components/Commons/ModalDraggable"
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import FdCreateMunicipalMutualAidAssociationAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/FdCreateMunicipalMutualAidAssociation.actions.js'
import WS1787002_FdCreateMunicipalMutualAidInquiry from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1787002_FdCreateMunicipalMutualAidInquiry'

import { download_file } from 'helpers/CommonHelpers'

const labelCol = {
  labelCol: { style: { width: 50 } }
}

class WS1787001_FdCreateMunicipalMutualAidAssociation extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '市町村職員共済組合結果FD作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      title: '',
      options: ''
    }
  }

  componentDidMount() {
  }


  // 区分の選択
  DivisionInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS1787002_FdCreateMunicipalMutualAidInquiry
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                division: output.record.division
              })

              this.setState({
                title: output.record.title,
                options: output.record.options,
              })

              this.closeModal();
            }}
          />
        ),
      }
    })
  }

  execution() {
    let ClaimYearsCharS = this.formRef.current?.getFieldValue('ClaimYearsCharS')
    let ClaimYearsCharF = this.formRef.current?.getFieldValue('ClaimYearsCharF')

    let prams = {
      division: this.formRef.current?.getFieldValue('division'),
      options: this.state.options,
      ClaimYearsCharS: ClaimYearsCharS.format("YYYY/MM/DD"),
      ClaimYearsCharF: ClaimYearsCharF.format("YYYY/MM/DD")
    }

    FdCreateMunicipalMutualAidAssociationAction.execution(prams)
      .then((res) => {
        if (res) {
          if (res.error) {
            // エラーファイル
            message.error('エラーファイルが作成されました。')

            // CSV出力処理
            this.downloadCSVFile(res, 0)
          } else {
            // DKEKKA/DMONSINファイル出力処理
            message.success('出力ファイルが作成されました。')

            // CSV出力処理
            this.downloadCSVFile(res, 0)
          }
        }
      })
  }

  /**
  * CSVファイル　ダウンロード
  * @param {*} path
  */
  downloadCSVFile(pathlist, index) {
    let params = {
      filePath: pathlist.list[index]
    }

    FdCreateMunicipalMutualAidAssociationAction.downloadCSVFile(params)
      .then(async (res) => {
        if (res) {
          // xlsxファイルダウンロード
          await download_file(res)

          const nextIndex = index + 1;
          if (pathlist.list.length > nextIndex) {
            // 再帰的にdownloadCSVFileを呼び出す
            this.downloadCSVFile(pathlist, nextIndex)
          }
        }
      })
      .catch(error => {
        console.log(error);
      })

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='fd-create-municipal-mutual-aid-association'>
        <Card
          title='共済組合結果FD作成'
          style={{ width: 600 }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{
              ClaimYearsCharS: moment(),
              ClaimYearsCharF: moment()
            }}
          >
            <div className="box_inner_horizontal">
              <Form.Item
                name="division"
                label="区分"
                {...labelCol}
              >
                <Input.Search
                  style={{ width: 100, textAlign: 'right' }}
                  onSearch={() => {
                    this.DivisionInquiry()
                  }}
                />
              </Form.Item>
              <div style={{ marginTop: '5px' }}>
                {this.state.title}
              </div>
            </div>

            <div className="box_inner_horizontal">
              <Form.Item
                name='ClaimYearsCharS'
                label='受診日'
                {...labelCol}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>
              <div style={{ marginTop: '5px' }}>～</div>
              <Form.Item
                name='ClaimYearsCharF'
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                style={{ float: 'right', marginTop: '1em' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `実行しますか?`,
                    okText: 'はい',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.execution()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  実行
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1787001_FdCreateMunicipalMutualAidAssociation)
