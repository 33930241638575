import React from "react";
import { connect } from "react-redux";
import { ArrowDownOutlined } from '@ant-design/icons';

import { Card, Checkbox, Col, Row, Table, Form, Input, InputNumber } from "antd";

const styleCol = {
  padding: 0,
}

const styleRow = {
  margin: 0
}
class WS1518001_ConfirmOptionItemInfo extends React.Component {

  constructor(props) {
    super(props);

    // document.title = 'オプションアイテム情報確認';

    this.state = {
    };
  }

  render() {
    return (
      <div className="confirm-option-item-info">
        <Card className="mb-2" title="オプションアイテム情報確認">
          <Row>
            <Col span={12}>
              <Table bordered
                style={{ marginRight: '10px' }}
                dataSource={[]}
                loading={false}
                pagination={{
                  defaultCurrent: 1,
                  total: 1,
                  pageSize: 1,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => { },
                  onShowSizeChange: (page, pageSize) => { },
                }}
                rowKey={(record) => record.id}
              >
                <Table.Column title="" dataIndex="" key="" render={(name, record, index) => {
                  <Checkbox></Checkbox>
                }} />
                <Table.Column title="種別" dataIndex="" key="" />
                <Table.Column title="ｵﾌﾟｼｮﾝ" dataIndex="" key="" />
                <Table.Column title=" 名称" dataIndex="" key="" />
                <Table.Column title="状態" dataIndex="" key="" />
              </Table>
            </Col>

            <Col span={12}>
              <Table bordered
                loading={false}
                pagination={{
                  defaultCurrent: 1,
                  total: 1,
                  pageSize: 1,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => { },
                  onShowSizeChange: (page, pageSize) => { },
                }}
                rowKey={(record) => record.id}>
                <Table.Column title="項目" dataIndex="" key="" />
                <Table.Column title="内容" dataIndex="" key="" />
                <Table.Column title="アイテム制限" dataIndex="" key="" />
                <Table.Column title="状態" dataIndex="" key="" />
              </Table>
              <Row gutter={24} style={styleRow}>
                <Col span={12} style={styleCol}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Input type='text' readOnly style={{ background: '#066bc7', color: '#fff' }} />
                  </Form.Item>
                </Col>
                <Col span={12} style={styleCol}>
                  <Form.Item name="Expression_143" style={{ marginBottom: 0 }}>
                    <Input type='text' readOnly style={{ background: '#066bc7', color: '#fff' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={styleCol}>
                  <Form.Item name="W3_optional_item_before_change" style={{ marginBottom: 0 }}>
                    <InputNumber maxLength={4} min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12} style={styleCol}>
                  <Form.Item name="W3_item_limit_before_change" style={{ marginBottom: 0 }}>
                    <InputNumber maxLength={4} min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{margin:'5px 0 5px 0',justifyContent:'space-around'}}>
                <div style={{alignContent:'center'}}>
                <ArrowDownOutlined style={{ fontSize: '16px', color: '#08c' }} />
                </div>             
              </Row>

              <Row>
                <Col span={12} style={styleCol}>
                  <Form.Item name="W3_optional_item" style={{ marginBottom: 0 }}>
                    <InputNumber maxLength={4} min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12} style={styleCol}>
                  <Form.Item name="W3_item_limit" style={{ marginBottom: 0 }}>
                    <InputNumber maxLength={4} min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

            </Col>
          </Row>
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1518001_ConfirmOptionItemInfo);
