import { MoreOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, Dropdown, Form, Input, Menu, Modal, Row, Select, Space, Table, message } from 'antd'
import axios from 'axios'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import moment from 'moment-timezone'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx'
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx'
import WS1303006_ScreenInput from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1303006_ScreenInput.jsx'
import WS1349045_ContractSelect from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1349045_ContractSelect.jsx'
import WS1350013_SettleInputSub from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1350013_SettleInputSub.jsx'
import React from 'react'
import { connect } from 'react-redux'
import VenusApiRoutesV2 from 'redux/VenusApiRoutesV2/VenusApiRoutesV2'

import WS1296010_ConsultTicketInputSub from './WS1296010_ConsultTicketInputSub'

const labelCol = {
  labelCol: { style: { width: 70 } },
}

const today = moment(new Date().toISOString())
const dateFormat = 'YYYY/MM/DD'

const WS1378001_Style = {
  row: {
    margin: '8px 0',
  },
  label: {
    lineHeight: '36px',
    fontWeight: 'bold',
    color: '#0096C7',
  },
  table: {
    marginBottom: '8px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    border: '1.25px solid #d9d9d9',
    justifyContent: 'center',
    padding: 0,
    marginBottom: '2px',
  }
}

const styleLabel = {
  background: '#1264bd',
  padding: '5px',
  border: '1px solid #fff',
  color: '#fff',
  textAlign: 'center'
}

const styleLabelOnly = {
  background: '#b8cada',
  padding: '5px',
  border: '1px solid #fff',
  textAlign: 'center'
}

class WS1378001_SpecificMedicalExamSettleProcess extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '特定健診決済処理'

    this.state = {
      // searchForm: {
      //   DateFScreen: moment(new Date()).format('YYYY/MM/DD'),
      //   DateTScreen: moment(new Date()).format('YYYY/MM/DD'),
      //   CourseF: '',
      //   CourseT: '',
      //   InsuranceNum: '',
      //   insurer_kanji_name: '',
      //   KeyNum: 1,
      // },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTable: false,
      selectedRow: {},
      dataSource: [],

      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
    }
  }

  /**
 * 初期表示処理
 */
  componentDidMount() {
    this.formRef.current.setFieldsValue({
      DateFScreen: today.format('YYYY/MM/DD'),
      DateTScreen: today.format('YYYY/MM/DD'),
      CourseF: '',
      CourseT: '',
      InsuranceNum: '',
      InsurerKanjiName: '',
      KeyNum: 1,
    })
  }

  changeSearchForm(searchField, value) {
    let params = {
      ...this.formRef.current.getFieldValue(),
      DateFScreen: moment(this.formRef.current.getFieldValue('DateFScreen'))?.format('YYYY/MM/DD'),
      DateTScreen: moment(this.formRef.current.getFieldValue('DateTScreen'))?.format('YYYY/MM/DD'),
      CourseF: this.formRef.current.getFieldValue('CourceCode'),
      CourseT: this.formRef.current.getFieldValue('CourceCode'),
      InsuranceNum: this.formRef.current.getFieldValue('InsuranceNum'),
      insurer_kanji_name: this.formRef.current.getFieldValue('InsurerKanjiName'),
      KeyNum: 1,
    }
    params[searchField] = value
    this.setState({ ...this.state, params })
  }

  setInsurance(numValue, kanjiValue) {
    let params = {
      DateFScreen: moment(this.formRef.current.getFieldValue('DateFScreen'))?.format('YYYY/MM/DD'),
      DateTScreen: moment(this.formRef.current.getFieldValue('DateTScreen'))?.format('YYYY/MM/DD'),
      CourseF: this.formRef.current.getFieldValue('CourceCode'),
      CourseT: this.formRef.current.getFieldValue('CourceCode'),
      InsuranceNum: numValue,
      insurer_kanji_name: kanjiValue,
      KeyNum: 1,
    }
    this.setState({ ...this.state, params })
  }

  getTableData(Li_CourseLevel, Li_ReserveNum) {
    axios.get('/api/specific-medical-exam-settle-process/consult-ticket-input-sub', { params: { Li_CourseLevel, Li_ReserveNum } })
      .then(res => {
        this.formRef.current.setFieldsValue({
          billing_division: res.data.specificmedicalexampayment.billing_division,
          consult_ticket_docket_num: res.data.consult_ticket_docket_num,
          GrantDateChars: res.data.GrantDateChars,
          kana_name: res.data.kana_name,
          GenderScreen: res.data.GenderScreen,
          DateBirthEraScreen: res.data.DateBirthEraScreen,
          date_japanese_calendar: res.data.date_japanese_calendar,
          ExpirationDateChars: res.data.ExpirationDateChars,
          MedicalExamContentClassifyScreen: res.data.MedicalExamContentClassifyScreen,
          PresenceOfExamEmployerKenScreen: res.data.PresenceOfExamEmployerKenScreen,
          BasicPartScreen: res.data.BasicPartScreen,
          SpecificMedicalExambasicburdenContent: res.data.SpecificMedicalExambasicburdenContent,
          DetailsScreen: res.data.DetailsScreen,
          SpecificMedicalExamdetailsburdenContent: res.data.SpecificMedicalExamdetailsburdenContent,
          AddItemsScreen: res.data.AddItemsScreen,
          OtheraddburdenContent: res.data.OtheraddburdenContent,
          CheckupScreen: res.data.CheckupScreen,
          OtherdockburdenContent: res.data.OtherdockburdenContent,
          other_insurer_pay_max: res.data.other_insurer_pay_max,

          insurer_num: res.data.insurer_num,
          other_agency: res.data.other_agency,
          OthersScreen: res.data.OthersScreen,
          version: res.data.version,
          ContractScreen: res.data.ContractScreen,
          zenmamoruren_agency: res.data.zenmamoruren_agency,
          ZenmamorurenScreen: res.data.ZenmamorurenScreen,
          tuberculosis_agency: res.data.tuberculosis_agency,
          TuberculosisKyokaiScreen: res.data.TuberculosisKyokaiScreen,
          human_de_agency: res.data.human_de_agency,
          CheckupSocietyScreen: res.data.CheckupSocietyScreen,
          prevent_doctor_agency: res.data.prevent_doctor_agency,
          PreventiveMedicineBusinessScreen: res.data.PreventiveMedicineBusinessScreen,
          doctor_agency: res.data.doctor_agency,
          JapanTotalMedicalExamScreen: res.data.JapanTotalMedicalExamScreen,
          nha_agency: res.data.nha_agency,
          JapanHospitalKyokaiScreen: res.data.JapanHospitalKyokaiScreen,
          tokyo_agency: res.data.tokyo_agency,
          HigashifukyoScreen: res.data.HigashifukyoScreen,
          insurer_name: res.data.insurer_name,
          insurer_party_location: res.data.insurer_party_location,
          insurer_telephone_num: res.data.insurer_telephone_num,
          contract_compiled_sect: res.data.contract_compiled_sect,
          contract_compiled_name: res.data.contract_compiled_name,
          other_chkup_content: res.data.other_chkup_content,

          Expresstion_156: res.data.Expresstion_156,
          contract_number: res.data.contract_number,
          contract_name: res.data.contract_name,
          Expresstion_115: res.data.Expresstion_115,
          Expresstion_116: res.data.Expresstion_116,
          basic_individual_medical_exam_unit_price: res.data.basic_individual_medical_exam_unit_price,
          unit_price_exam_basic_population_health: res.data.unit_price_exam_basic_population_health,
          Expresstion_117: res.data.Expresstion_117,
          Expresstion_122: res.data.Expresstion_122,
          anemia_individual_medical_exam_unit_price: res.data.anemia_individual_medical_exam_unit_price,
          unit_price_exam_anemia_population_health: res.data.unit_price_exam_anemia_population_health,
          Expresstion_118: res.data.Expresstion_118,
          Expresstion_123: res.data.Expresstion_123,
          electrocardiograph_individual_medical_exam_unit_price: res.data.electrocardiograph_individual_medical_exam_unit_price,
          unit_price_diagnosis_electrocardiogram_population_ken: res.data.unit_price_diagnosis_electrocardiogram_population_ken,
          Expresstion_119: res.data.Expresstion_119,
          Expresstion_124: res.data.Expresstion_124,
          fundus_individual_medical_exam_unit_price: res.data.fundus_individual_medical_exam_unit_price,
          fundus_group_medical_exam_unit_price: res.data.fundus_group_medical_exam_unit_price,
          Expresstion_120: res.data.Expresstion_120,
          Expresstion_125: res.data.Expresstion_125,
          h30_creatinine_individual_medical_exam_unit_price: res.data.h30_creatinine_individual_medical_exam_unit_price,
          h30_creatinine_population_checkup_unit_price: res.data.h30_creatinine_population_checkup_unit_price,
          Expresstion_121: res.data.Expresstion_121,
          Expresstion_126: res.data.Expresstion_126,

          additional_medical_exam_name_01: res.data.additional_medical_exam_name_01,
          additional_medical_exam_unit_price_01: res.data.additional_medical_exam_unit_price_01,
          Expresstion_127: res.data.Expresstion_127,
          additional_medical_exam_name_02: res.data.additional_medical_exam_name_02,
          additional_medical_exam_unit_price_02: res.data.additional_medical_exam_unit_price_02,
          Expresstion_128: res.data.Expresstion_128,
          additional_medical_exam_name_03: res.data.additional_medical_exam_name_03,
          additional_medical_exam_unit_price_03: res.data.additional_medical_exam_unit_price_03,
          Expresstion_129: res.data.Expresstion_129,
          additional_medical_exam_name_04: res.data.additional_medical_exam_name_04,
          additional_medical_exam_unit_price_04: res.data.additional_medical_exam_unit_price_04,
          Expresstion_130: res.data.Expresstion_130,
          additional_medical_exam_name_05: res.data.additional_medical_exam_name_05,
          additional_medical_exam_unit_price_05: res.data.additional_medical_exam_unit_price_05,
          Expresstion_131: res.data.Expresstion_131,
          additional_medical_exam_name_06: res.data.additional_medical_exam_name_06,
          additional_medical_exam_unit_price_06: res.data.additional_medical_exam_unit_price_06,
          Expresstion_132: res.data.Expresstion_132,
          additional_medical_exam_name_07: res.data.additional_medical_exam_name_07,
          additional_medical_exam_unit_price_07: res.data.additional_medical_exam_unit_price_07,
          Expresstion_133: res.data.Expresstion_133,
          additional_medical_exam_name_08: res.data.additional_medical_exam_name_08,
          additional_medical_exam_unit_price_08: res.data.additional_medical_exam_unit_price_08,
          Expresstion_134: res.data.Expresstion_134,
          additional_medical_exam_name_09: res.data.additional_medical_exam_name_09,
          additional_medical_exam_unit_price_09: res.data.additional_medical_exam_unit_price_09,
          Expresstion_135: res.data.Expresstion_135,
          additional_medical_exam_name_10: res.data.additional_medical_exam_name_10,
          additional_medical_exam_unit_price_10: res.data.additional_medical_exam_unit_price_10,
          Expresstion_136: res.data.Expresstion_136,

          same_time_name_01: res.data.same_time_name_01,
          same_time_unit_price_01: res.data.same_time_unit_price_01,
          Expresstion_137: res.data.Expresstion_137,
          same_time_name_02: res.data.same_time_name_02,
          same_time_unit_price_02: res.data.same_time_unit_price_02,
          Expresstion_138: res.data.Expresstion_138,
          same_time_name_03: res.data.same_time_name_03,
          same_time_unit_price_03: res.data.same_time_unit_price_03,
          Expresstion_139: res.data.Expresstion_139,
          same_time_name_04: res.data.same_time_name_04,
          same_time_unit_price_04: res.data.same_time_unit_price_04,
          Expresstion_140: res.data.Expresstion_140,
          same_time_name_05: res.data.same_time_name_05,
          same_time_unit_price_05: res.data.same_time_unit_price_05,
          Expresstion_141: res.data.Expresstion_141,
          same_time_name_06: res.data.same_time_name_06,
          same_time_unit_price_06: res.data.same_time_unit_price_06,
          Expresstion_142: res.data.Expresstion_142,
          same_time_name_07: res.data.same_time_name_07,
          same_time_unit_price_07: res.data.same_time_unit_price_07,
          Expresstion_143: res.data.Expresstion_143,
          same_time_name_08: res.data.same_time_name_08,
          same_time_unit_price_08: res.data.same_time_unit_price_08,
          Expresstion_144: res.data.Expresstion_144,
          same_time_name_09: res.data.same_time_name_09,
          same_time_unit_price_09: res.data.same_time_unit_price_09,
          Expresstion_145: res.data.Expresstion_145,
          same_time_name_10: res.data.same_time_name_10,
          same_time_unit_price_10: res.data.same_time_unit_price_10,
          Expresstion_146: res.data.Expresstion_146,

          year: res.data.contractmaster.year,
        })
        this.forceUpdate()
      })
  }

  search = () => {
    let params = {
      ...this.formRef.current.getFieldValue(),
      DateFScreen: moment(this.formRef.current.getFieldValue('DateFScreen'))?.format('YYYY/MM/DD'),
      DateTScreen: moment(this.formRef.current.getFieldValue('DateTScreen'))?.format('YYYY/MM/DD'),
    }
    this.setState({ isLoadingTable: true })
    VenusApiRoutesV2.callApi('API001378001013', params)
      .then(res => {
        VenusApiRoutesV2.callApi('API001378001014', params)
          .then(res => {
            VenusApiRoutesV2.callApi('API001378001006', params)
              .then(res => {
                this.setState({ dataSource: { ...this.state.dataSource, MainSource: res }, isLoadingTable: false })
                if (res[0]) {
                  this.getTableData(res[0].W1_course_level, res[0].W1_reserve_num)
                }
              })
          })
      })
  }

  contractSelect() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: '60%',
        visible: true,
        component: (
          <WS1349045_ContractSelect
            onFinishScreen={() => {
              this.closeModal()
            }}
            Li_InsurerNum={this.formRef.current?.getFieldValue('insurer_num')}
            Li_Year={this.formRef.current?.getFieldValue('year')}
          />
        )
      }
    })
  }

  settleInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: '60%',
        visible: true,
        component: (
          <WS1350013_SettleInputSub
            Li_CourseLevel={record.W1_course_level}
            Li_ReserveNum={record.W1_reserve_num}
            onFinishScreen={output => {
              this.formRef.current.setFieldsValue({
                other_agency: output.Lio_AgencyNum
              })
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  refer(record) {
    console.log(record)

    if (record.id ?? null !== null) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: '60%',
          component: (
            <WS1296010_ConsultTicketInputSub
              Li_CourseLevel={record.W1_course_level}
              Li_ReserveNum={record.W1_reserve_num}
              Li_Tab={'1'}
              onFinishScreen={(output) => {
                this.setInsurance(output.recordData.insurer_number, output?.recordData?.[0].insurer_kanji_name)
                this.closeModal()
              }}
            />
          )
        }
      })
    } else {
      message.error('対象者を選択してください')
    }
  }

  delete(record) {
    Modal.confirm({
      content: '消去してもよろしいですか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        VenusApiRoutesV2.callApi('API001378001027', { W1_reserve_num: record.W1_reserve_num, W1_course_level: record.W1_course_level })
          .then(res => {
            this.search()
          })
      }
    })
  }

  resultInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1800,
        component: (
          <WS2637001_OverallResultDisplayInput
            Li_ContractType={''}
            Li_ContractOrgCode={''}
            Li_ContractStartDate={''}
            Li_ContractNum={''}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  billingSummary() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: '60%',
        visible: true,
        component: (
          <WS1303006_ScreenInput
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  inquiry(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: 'fit-content',
        visible: true,
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.W1_reserve_num}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  renderRightClick = (record) => {
    return (
      <Menu>
        <Menu.Item key={1} type='primary' onClick={() => this.inquiry(record)}>照会</Menu.Item>
        <Menu.Item key={2} type='primary' onClick={() => this.delete(record)}>削除</Menu.Item>
        <Menu.Item key={3} type='primary' onClick={() => this.contractSelect()}>契約選択</Menu.Item>
        <Menu.Item key={4} type='primary' onClick={() => this.refer(record)}>決済入力</Menu.Item>
        <Menu.Item key={5} type='primary' onClick={() => this.resultInput()}>結果入力</Menu.Item>
        <Menu.Item key={6} type='primary' onClick={() => this.billingSummary()}>請求内容</Menu.Item>
      </Menu>
    )
  }

  render() {
    return (
      <div className='specific-medical-exam-settle-process'>
        <Card title='特定健診決済処理'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_inner_vertical'>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='DateFScreen'
                        label='日付'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                      <Form.Item
                        name='DateTScreen'
                        label='~'
                        style={{ marginBottom: 0 }}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='CourseF'
                        label='コース'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-10'
                          type='text'
                          allowClear={true}
                          onSearch={(value, event) => {
                            if (value !== '' || event.target.localName !== 'input') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '60%',
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          CourseF: output.recordData.course_code,
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  )
                                }
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='CourseT'
                        label='~'
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-10'
                          type='text'
                          allowClear={true}
                          onSearch={(value, event) => {
                            if (value !== '' || event.target.localName !== 'input') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '60%',
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          CourseT: output.recordData.course_code,
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  )
                                }
                              })
                            }
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='InsuranceNum'
                        label='保険者番号'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-10'
                          type='text'
                          allowClear={true}
                          onSearch={(value, event) => {
                            if (value !== '' || event.target.localName === 'input') {
                              // 漢字名を空に変更
                              this.formRef.current.setFieldsValue({
                                InsurerKanjiName: ''
                              })
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '60%',
                                  component: (
                                    <WS1290001_InsurerNumberInquiry
                                      searchName={this.formRef.current?.getFieldValue('InsuranceNum')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          InsuranceNum: output.recordData.insurer_number,
                                          InsurerKanjiName: output.recordData.insurer_kanji_name
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  )
                                }
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='InsurerKanjiName'
                        style={{ marginBottom: 0 }}
                      >
                        <Input
                          className='input-size-40'
                          readOnly
                          style={{ background: 'transparent', border: 'none' }}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      label='表示順'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Select
                        style={{ width: 120 }}
                        defaultValue='1'
                        onChange={val => this.changeSearchForm('KeyNum', val)}
                      >
                        <Select.Option value='1'>受付順</Select.Option>
                        <Select.Option value='2'>カナ順</Select.Option>
                        <Select.Option value='3'>ID順</Select.Option>
                        <Select.Option value='4'>時間帯順</Select.Option>
                        <Select.Option value='5'>事業所順</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          this.search()
                        }}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>

            <div className='box_inner_horizontal'>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource.MainSource}
                rowKey={(record) => record.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                loading={this.state.isLoadingTable}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => this.refer(record),
                  }
                }}
                rowSelection={{
                  type: 'radio',
                  onChange: (selectedRowKeys, selectedRows) => {
                    this.setState({ selectedRow: selectedRows[0] })
                    this.getTableData(selectedRows[0]?.W1_course_level, selectedRows[0]?.W1_reserve_num)
                  }
                }}
              >
                <Table.Column
                  title='選択'
                  dataIndex='Expression_15'
                  width={40}
                />

                <Table.Column
                  title='受診日'
                  dataIndex='W1_consult_date'
                  className='column-size-10'
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kana_name'
                  className='column-size-40'
                />

                <Table.Column
                  title='保険者番号'
                  dataIndex='insurer_num'
                  className='column-size-40'
                />

                <Table.Column
                  title='契約名称'
                  dataIndex='contract_name'
                />

                <Table.Column
                  title='請求'
                  dataIndex='Expression_17'
                  className='column-size-10'
                />

                <Table.Column
                  title='基本'
                  dataIndex='Expression_18'
                  className='column-size-10'
                />

                <Table.Column
                  title='貧血'
                  dataIndex=''
                  className='column-size-10'
                />

                <Table.Column
                  title='心電図'
                  dataIndex=''
                  className='column-size-10'
                />

                <Table.Column
                  title='眼底'
                  dataIndex=''
                  className='column-size-10'
                />

                <Table.Column
                  title='クレアチニン'
                  dataIndex=''
                  className='column-size-10'
                />

                <Table.Column
                  title=''
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => this.renderRightClick(record)}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />} />
                      </Dropdown>
                    )
                  }}
                />
              </Table>

              {/* <Col span={8}> */}
              <div className='box_inner_vertical' style={{ marginLeft: 10, width: 400 }}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabel}>No</div>
                      </Col>
                      <Col span={6} style={{ padding: 0 }}>
                        <Form.Item name='billing_division'>
                          <Select className='mb-0'>
                            <Select.Option value={0}>請求なし</Select.Option>
                            <Select.Option value={1}>特定健診</Select.Option>
                            <Select.Option value={2}>人間ドック</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <div style={styleLabel}>個別健診</div>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <div style={styleLabel}>集団検診</div>
                      </Col>
                      <Col span={3} style={{ padding: 0 }}>
                        <div style={styleLabel}>決済</div>
                      </Col>
                      <Col span={3} style={{ padding: 0 }}>
                        <div style={styleLabel}>区分</div>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>1</div>
                      </Col>
                      <Col span={6} style={{ padding: 0 }}>
                        <Input type='text' readOnly value={'基本項目'} />
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='basic_individual_medical_exam_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly style={{ textAlign: 'right' }} />
                        </Form.Item>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='unit_price_exam_basic_population_health'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_117') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>

                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>2</div>
                      </Col>
                      <Col span={6} style={{ padding: 0 }}>
                        <Input type='text' readOnly value={'貧血検査'} />
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='anemia_individual_medical_exam_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly style={{ textAlign: 'right' }} />
                        </Form.Item>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='unit_price_exam_anemia_population_health'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_118') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_123') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>3</div>
                      </Col>
                      <Col span={6} style={{ padding: 0 }}>
                        <Input type='text' readOnly value={'心電図検査'} />
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='electrocardiograph_individual_medical_exam_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly style={{ textAlign: 'right' }} />
                        </Form.Item>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='unit_price_diagnosis_electrocardiogram_population_ken'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_119') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_124') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>4</div>
                      </Col>
                      <Col span={6} style={{ padding: 0 }}>
                        <Input type='text' readOnly value={'眼底検査'} />
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='fundus_individual_medical_exam_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly style={{ textAlign: 'right' }} />
                        </Form.Item>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='fundus_group_medical_exam_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_120') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_125') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>5</div>
                      </Col>
                      <Col span={6} style={{ padding: 0 }}>
                        <Input type='text' readOnly value={'ｸﾚｱﾁﾆﾝ検査'} />
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='h30_creatinine_individual_medical_exam_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly style={{ textAlign: 'right' }} />
                        </Form.Item>
                      </Col>
                      <Col span={5} style={{ padding: 0 }}>
                        <Form.Item
                          name='h30_creatinine_population_checkup_unit_price'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_121') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_126') === '〇'} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: '20px' }}>
                  <Col span={24} style={{ marginRight: '20px' }}>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabel}>No</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <div style={styleLabel}>追加健診名</div>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <div style={styleLabel}>単価</div>
                      </Col>
                      <Col span={3} style={{ padding: 0 }}>
                        <div style={styleLabel}>決済</div>
                      </Col>
                      <Col span={3} style={{ padding: 0 }}>
                        <div style={styleLabel}>区分</div>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>1</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_01'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_01'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_127') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>2</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_02'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_02'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_128') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>3</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_03'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_03'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_129') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>4</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_04'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_04'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_130') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>5</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_05'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_05'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_131') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>6</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_06'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_06'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_132') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>7</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_07'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_07'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_133') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>8</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_08'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_08'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_134') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>9</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_09'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_09'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_135') === '〇'} />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>10</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_name_10'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item
                          name='additional_medical_exam_unit_price_10'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_136') === '〇'} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: '20px' }}>
                  <Col span={24}>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabel}>No</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <div style={styleLabel}>同時実施健診名</div>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <div style={styleLabel}>差引</div>
                      </Col>
                      <Col span={3} style={{ padding: 0 }}>
                        <div style={styleLabel}>決済</div>
                      </Col>
                      <Col span={3} style={{ padding: 0 }}>
                        <div style={styleLabel}>区分</div>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>1</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_01' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_01' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_137') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>2</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_02' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_02' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_138') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>3</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_03' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_03' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_139') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>4</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_04' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_04' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_140') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>5</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_05' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_05' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_141') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>6</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_06' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_06' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_142') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>7</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_07' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_07' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_143') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>8</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_08' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_08' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_144') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>9</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_09' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_09' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_145') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={2} style={{ padding: 0 }}>
                        <div style={styleLabelOnly}>10</div>
                      </Col>
                      <Col span={12} style={{ padding: 0 }}>
                        <Form.Item name='same_time_name_10' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={4} style={{ padding: 0 }}>
                        <Form.Item name='same_time_unit_price_10' style={{ marginBottom: '0' }}>
                          <Input type='text' readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                        <Checkbox checked={this.formRef.current?.getFieldValue('Expresstion_146') === '〇'} />
                      </Col>
                      <Col span={3} style={WS1378001_Style.checkbox}>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            {/* </Col> */}

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.contractSelect()}
              >
                契約選択
              </Button>

              <Button
                type='primary'
                onClick={() => this.refer(this.state.selectedRow)}
              >
                決済入力
              </Button>
            </div>

          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1378001_SpecificMedicalExamSettleProcess)
