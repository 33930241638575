import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, Select, message } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}
class WS0745002_NotInputCheckMaintainInput extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '注意事項入力 編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isSave: false,
      count: 0,
      newFlag: false
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (!this.props.newFlag) {
      // 親画面のrecordをコピー
      const data = {
        ...this.props.record,
      }
      // Form設定
      this.formRef.current?.setFieldsValue(data)
    } else {
      this.formRef.current?.setFieldsValue({ patternCode: this.props.selectedPatternCode })
    }
    this.forceUpdate()

  }

  /**
   * 選択したパターンコードの取得
   * @param {*} e
   */
  patternCodeChange = (e) => {
    this.formRef.current.setFieldsValue({
      patternCode: this.formRef.current.getFieldValue('patternCode')
    })
    this.forceUpdate()
  }

  /**
   * データ保存
   */
  onFinish = (values) => {
    if (!values.Code) {
      message.error('種別を記入してください')
    } else if (!values.remarks) {
      message.error('名称を記入してください')
    } else {
      this.props.onUpdate(values)
    }
  }

  /**
   * データ削除
   * @param {*} id
   */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="not-input-check-category p-td">
        <Card title={'未入力チェック [' + (this.props.newFlag ? '新規' : '名称変更') + ']'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >

            <div className='box_container'>
              <Form.Item
                label='種別'
                name='Code'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  readOnly={!this.props.newFlag}
                  bordered={this.props.newFlag}
                  className='input-size-number-4'
                  maxLength={2}
                  type="number"
                  max="99"
                  min="0"
                />
              </Form.Item>

              <Form.Item
                label='名称'
                name='remarks'//対応したものに書き換え
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <div className="box_inner_horizontal">
                <Form.Item
                  className='input-size-30'
                  name="patternCode"
                  label='パターン'
                  {...labelCol}
                >
                  <Select
                    onChange={(e) => this.patternCodeChange()}
                  >
                    {this.props.patternList?.map((item, index) => (
                      <Select.Option
                        key={"patternList" + index}
                        value={item.pattern_code}>
                        {item.pattern_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div style={{ marginTop: 5 }}>{this.formRef.current?.getFieldValue('patternCode')}</div>
              </div>
            </div>


            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>


        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0745002_NotInputCheckMaintainInput)
