import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, Input, Tooltip, Button, message, Upload, Form, InputNumber } from 'antd'
import { PlusOutlined, DeleteOutlined, SaveOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons'
import WS0397001_ParamPromptedQuerySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx'
import OldItemAction from 'redux/ResultOutput/PrintParamMaintain/OldItem.action'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import Cookies from 'js-cookie'
import { download_file_binary } from 'helpers/CommonHelpers'
import moment from 'moment'

class WS0857001_OldItem extends React.Component {
  static propTypes = {
    Li_StyleCode: PropTypes.string,
    Li_StsListFormat: PropTypes.any,
    paramIndex: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '旧明細'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      paramIndex: {
        Li_StyleCode: '',
        Li_StsListFormat: ''
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        onChange: (current) => {
          // 選択状態ナシ
          this.setState({ selectedRow: {} })
        }
      },
      oldDataSource: [],
      dataSource: [],
      selectedRow: {},

      oldStyleCode: '',

      Valueremarks: '',
      disabledSaveBtn: true,
      addCount: 0,

      uploadFileName: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({ styleCode: this.props.Li_StyleCode })

    let dateTime = moment(new Date()).format('YYYYMMDDHHmmss')

    this.setState({
      oldStyleCode: this.props.Li_StyleCode,
      uploadFileName: 'upload_' + dateTime + '.xlsx'
    })
    // データ取得
    this.index()
  }

  /**
   * データ取得
   * @param {*} reload
   */
  index() {
    const params = {
      Li_StyleCode: this.props.Li_StyleCode,
      Li_StsListFormat: this.props.Li_StsListFormat,
    }
    OldItemAction.index(params)
      .then((res) => {
        if (res) {
          let data = res ? res : []
          let index = 0
          this.setState({
            dataSource: data,
            oldDataSource: data,
            selectedRow: data.length > 0 ? { ...data[index] } : {},
            Valueremarks: data.length > 0 ? data[index].remarks : '',
            disabledSaveBtn: true,
            addCount: 0
          })

          this.formRef.current?.setFieldsValue({
            remarks: data.length > 0 ? data[index].remarks : '',
            dataSource: data
          })
        }
      })
  }

  /**
   * それぞれの値の変更
   * @param {*} id
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(id, field, value) {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // data[index][field] = value

    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.formRef.current?.setFieldsValue({
      dataSource: tempArr
    })
    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
  }

  /**
   * 削除　
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   * @param {*} id
   */
  delete = (id) => {

    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * 新規追加、行追加
   */
  add = async () => {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      selectedRow: { ...newRow },
      addCount: idCount,
    })
    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.forceUpdate()
  }

  /**
   * 保存 処理
   * @param {*} index
   */
  save = () => {
    let params = {
      oldStyleCode: this.state.oldStyleCode,
      newStyleCode: this.formRef.current?.getFieldValue('styleCode'),
      dataSource: this.state.dataSource
    }
    OldItemAction.save(params)
      .then((res) => {
        // this.index(true)
        if (res) {
          this.props.onFinishScreen()
        }
      })
  }

  getInstructionDivisionChange(record) {
    let params = {
      instruction_division: record.instruction_division,
      format_name: record.format_name,
      parameters: record.parameters,
      Li_StsListFormat: this.state.paramIndex.Li_StsListFormat
    }
    OldItemAction.instructiondivisionchange(params)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  // editRemarks = (selectedData, index) => {
  //   this.setState({
  //     ...this.state,
  //     childModal: {
  //       width: 'fit-content',
  //       visible: true,
  //       centered: true,
  //       component: (
  //         <PrintParamRemarks
  //           remarks={selectedData.remarks ?? ''}
  //           onFinishScreen={(output) => {
  //             let newData = this.state.dataSource
  //             newData[index]['remarks'] = output.remarks
  //             this.setState({
  //               dataSource: newData
  //             })
  //             this.closeModal()
  //           }}
  //         />
  //       ),
  //     },
  //   })
  // }

  /**
   * Excel出力
   */
  outputExcel = () => {
    const params = {
      dataList: this.state.dataSource,
      styleCode: this.formRef.current.getFieldValue('styleCode')
    }
    OldItemAction.outputExcel(params)
      .then((res) => {
        if (res) {
          this.downloadExcelFile(res?.filePath)
        }
      })
  }

  /**
   * Excelファイル ダウンロード
   * @param {*} path
   */
  downloadExcelFile(path) {
    let params = {
      filePath: path
    }
    OldItemAction.downloadExcelFile(params)
      .then((res) => {
        if (res) {
          // xlsxファイルダウンロード
          download_file_binary(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  /**
   * Excelファイル 読み込み
   */
  readExcelFile() {
    let params = {
      oldStyleCode: this.state.oldStyleCode,
      uploadFileName: this.state.uploadFileName
    }
    OldItemAction.readExcelFile(params)
      .then((res) => {
        if (res) {
          // データ取得
          this.index()
        }

        // アップロードファイル名を変更
        let dateTime = moment(new Date()).format('YYYYMMDDHHmmss')
        this.setState({
          uploadFileName: 'upload_' + dateTime + '.xlsx'
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='old-item ' style={{ width: '100%' }}>
        <Card title={'印刷パラメータ保守 旧明細 [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                name='styleCode'
                label='様式'
                style={{ marginBottom: 0 }}
              >
                <Input
                  className='input-size-10'
                  onChange={() => {
                    if (this.state.disabledSaveBtn) {
                      this.setState({ disabledSaveBtn: false })
                    }
                    this.forceUpdate()
                  }}
                />
              </Form.Item>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
                scroll={{ x: 'max-content', y: resizableTableScroll(220) }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: async () => {
                      await this.setState({
                        selectedRow: record
                      })
                    }
                  }
                }}
              >
                <Table.Column
                  title='No'
                  dataIndex='record_number'
                  width={80}
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        <InputNumber value={record.record_number} maxLength={6}
                          onBlur={(e) => {
                            this.updateDatasource(record.id, 'record_number', e.target.value)
                          }}
                          onPressEnter={(e) => {
                            this.updateDatasource(record.id, 'record_number', e.target.value)
                          }}
                        />
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='名称'
                  dataIndex='format_name'
                  width={250}
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'format_name']}>
                        <Input
                          onBlur={(e) => {
                            this.updateDatasource(record.id, 'format_name', e.target.value)
                          }}
                          onPressEnter={(e) => {
                            this.updateDatasource(record.id, 'format_name', e.target.value)
                          }}
                        />
                      </Form.Item>
                      // <Input.Search value={record.format_name} style={{ width: '100%' }}
                      //   onSearch={() => {
                      //     this.setState({
                      //       childModal: {
                      //         ...this.state.childModal,
                      //         visible: true,
                      //         width: 700,
                      //         component: (
                      //           <WS0397001_ParamPromptedQuerySub
                      //             Li_Format={this.state.paramIndex.Li_StsListFormat ? 'B' : 'A'}
                      //             Lo_IndicationDivision={record.instruction_division ?? ''}
                      //             onFinishScreen={(output) => {
                      //               this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'instruction_division', output.Lo_IndicationDivision)
                      //               this.closeModal()
                      //             }}
                      //           />
                      //         ),
                      //       },
                      //     })
                      //   }}
                      //   onChange={(e) => {
                      //     this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'format_name', e.target.value)
                      //   }}
                      // />
                    )
                  }} />
                <Table.Column
                  title='指示'
                  dataIndex='instruction_division'
                  width={100}
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'instruction_division']}>
                        <Input.Search
                          value={record.instruction_division}
                          // style={{ width: '100%', textAlign: 'right' }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (
                                  <WS0397001_ParamPromptedQuerySub
                                    Li_Format={this.state.paramIndex.Li_StsListFormat ? 'B' : 'A'}
                                    Lo_IndicationDivision={record.instruction_division ?? ''}
                                    format_name={record.format_name ?? ''}
                                    parameters={record.parameters ?? ''}
                                    format='instruction_division_857001'
                                    onFinishScreen={(output) => {
                                      this.updateDatasource(record.id, 'format_name', output.name)
                                      this.updateDatasource(record.id, 'instruction_division', output.instruction_division)
                                      // this.updateDatasource(index, 'parameters', output.Lio_Option)
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onBlur={(e) => {
                            this.updateDatasource(record.id, 'instruction_division', e.target.value)
                          }}
                        />
                      </Form.Item>
                    )
                  }} />
                <Table.Column
                  title='パラメータ'
                  dataIndex='parameters'
                  className='column-size-60'
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'parameters']}>
                        <Input
                          onBlur={(e) => {
                            this.updateDatasource(record.id, 'parameters', e.target.value)
                          }}
                          onPressEnter={(e) => {
                            this.updateDatasource(record.id, 'parameters', e.target.value)
                          }}
                        />
                      </Form.Item>
                      // <Input.Search value={record.parameters} style={{ width: '100%' }}
                      //   onSearch={() => {
                      //     this.setState({
                      //       childModal: {
                      //         ...this.state.childModal,
                      //         visible: true,
                      //         width: '80%',
                      //         component: (
                      //           <WS0400001_OptionalInputSub
                      //             // Li_Format={this.state.paramIndex.Li_StsListFormat ? 'B' : 'A'}
                      //             // Lo_IndicationDivision={record.instruction_division}
                      //             onFinishScreen={(output) => {
                      //               // this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'format_name', output.Lo_IndicationDivision)
                      //               // this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'parameters', output.instruction_division)
                      //               this.closeModal()
                      //             }}
                      //           />
                      //         ),
                      //       },
                      //     })
                      //   }}
                      //   onChange={(e) => {
                      //     this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'parameters', e.target.value)
                      //   }}
                      // />
                    )
                  }} />
                <Table.Column
                  width={40}
                  fixed='right'
                  title={
                    <div style={{ textAlign: 'center' }}>
                      <Button size='small'
                        onClick={() => this.add()}
                        type='primary'
                        icon={<PlusOutlined />}
                      />
                    </div>
                  }
                  render={(text, record, index) => {
                    return (
                      <Button
                        danger
                        size='small'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.delete(record.id)
                        }}
                      />
                    )
                  }}
                />
              </Table>

              <Form.Item
                label='備考'
                name={['dataSource', this.state.dataSource.findIndex(item => item.id == this.state.selectedRow?.id), 'remarks']}
                // name='remarks'
                defaultValue={this.state.Valueremarks ?? ''}
                style={{ marginBottom: 0 }}
              >
                <Input.TextArea
                  disabled={!(this.state.selectedRow?.id ?? false)}
                  rows={3}
                  onBlur={(event) => {
                    this.updateDatasource(this.state.selectedRow?.id, 'remarks', event.target.value)
                  }}
                  onPressEnter={(event) => {
                    this.updateDatasource(this.state.selectedRow?.id, 'remarks', event.target.value)
                  }}
                />
              </Form.Item>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Tooltip title='Excelファイルを取り込んでパラメータをデータベースに反映します'>
              <Upload
                headers={{
                  'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
                }}
                withCredentials={true}
                showUploadList={false}
                data={{
                  oldStyleCode: this.state.oldStyleCode,
                  uploadFileName: 'upload_' + moment(new Date()).format('YYYYMMDDHHmmss') + '.xlsx'
                }}
                action='/api/print-param-maintain/old-item/upload'
                onChange={(info) => {
                  console.log(info);
                  if (info.file.status === 'uploading') {
                    this.setState({ isLoadingData: true })
                  } else {
                    if (info.file.status === 'done') {
                      // Excelファイル 読み込み
                      // this.readExcelFile()
                      message.info('取込完了')
                      this.index()
                    } else if (info.file.status === 'error') {
                      message.error('エラーが発生しました')
                    }
                  }
                }}
              >
                <Button
                  type='primary'
                  icon={<UploadOutlined />}
                  disabled={!(this.formRef.current?.getFieldValue('styleCode'))}
                >
                  <span className='btn_label'>
                    Excel取込
                  </span>
                </Button>
              </Upload>
            </Tooltip>


            <Tooltip title='画面に表示している内容をExcelファイルに出力します'>
              <Button
                type='primary'
                icon={<DownloadOutlined />}
                disabled={!(this.formRef.current?.getFieldValue('styleCode') && this.state.dataSource.length > 0)}
                onClick={() => this.outputExcel()}
              >
                <span className='btn_label'>
                  Excel出力
                </span>
              </Button>
            </Tooltip>

            <Tooltip title='保存ボタンを押下するまで編集内容や削除はデータベースに反映されません'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
                onClick={() => this.save()}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </Tooltip>
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0857001_OldItem)
