import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const ReverseModalConfirm = ({ title, content, icon, okText, cancelText, onOk, styleIconModal, autoFocusButton, onCancel }) => (
  Modal.confirm({
    className: 'ant-reverse-confirm-modal',
    title: title,
    content: content,
    icon: icon || <QuestionCircleOutlined style={styleIconModal} />,
    cancelText: okText || 'はい',
    okText: cancelText || 'いいえ',
    onOk: onCancel,
    onCancel: onOk,
    autoFocusButton: autoFocusButton || 'ok',
  })
)

export const ModalConfirm = ({ title, content, icon, okText, cancelText, onOk, styleIconModal, autoFocusButton, onCancel }) => {
  Modal.confirm({
    title: title,
    content: content,
    icon: icon || <QuestionCircleOutlined style={styleIconModal} />,
    okText: okText || 'はい',
    cancelText: cancelText || 'いいえ',
    onOk: onOk,
    onCancel: onCancel,
    autoFocusButton: autoFocusButton || 'ok',
  })
}

export const ModalError = (content, okText, cancelText, onOk) => {
  Modal.error({
    content: content,
    okText: okText || 'はい',
    cancelText: cancelText || 'いいえ',
    onOk: onOk
  })
}

export const ModalInfo = (content, okText, cancelText, onOk) => {
  Modal.info({
    content: content,
    okText: okText || 'はい',
    cancelText: cancelText || 'いいえ',
    onOk: onOk
  })
}

export const ModalWarning = (content, okText, onOk) => {
  Modal.warning({
    title: "警告",
    content: content,
    okText: okText || '確認',
  })
}
