import { message } from 'antd'
import FollowUpLetterCourseDepartmentspecificFormatService from 'services/IntroductionLetter/FollowUpLetter/FollowUpLetterCourseDepartmentspecificFormatService.js'

const FollowUpLetterCourseDepartmentspecificFormatAction = {
  getFormatList(data) {
    return FollowUpLetterCourseDepartmentspecificFormatService.getFormatList(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getSettedCourseList(data) {
    return FollowUpLetterCourseDepartmentspecificFormatService.getSettedCourseList(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveCourse(data) {
    return FollowUpLetterCourseDepartmentspecificFormatService.saveCourse(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteCourse(data) {
    return FollowUpLetterCourseDepartmentspecificFormatService.deleteCourse(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveDepartmentspecificFormat(data) {
    return FollowUpLetterCourseDepartmentspecificFormatService.saveDepartmentspecificFormat(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteDepartmentspecificFormat(data) {
    return FollowUpLetterCourseDepartmentspecificFormatService.deleteDepartmentspecificFormat(data)
      .then(res => {
        if (res) {
          message.success(res.data?.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default FollowUpLetterCourseDepartmentspecificFormatAction
