import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Card, Dropdown, Menu, Table, Space, Button } from "antd";
import Color from "constants/Color";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0268001_SiteClassifySearchQuery extends React.Component {
  static propTypes = {
    Lo_SiteClassifyCode: PropTypes.any,

    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);

    // document.title = '部位分類検索・照会';

    this.state = {
      dataSource: [],
      isLoadingTable: false
    };
  }
  componentDidMount() {
    this.getInitialValuse();
  }

  getInitialValuse = () => {
    this.setState({
      isLoadingTable: true
    })
    let params = {

    }
    VenusApiRoutesV2.callApi('API000268001002', params)
      .then(res => {
        this.setState({
          dataSource: res ? res : [],
        })
        console.log("res", res);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  }

  okButtonClick = (record) => {
    this.props.onFinishScreen(record);
  }

  render() {
    return (
      <div className="site-classify-search-query">
        <Card title="部位分類検索・照会" className="mb-2">
          <Table bordered
            size='small'
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={false}

            scroll={{ x: 400, y: 400 }}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () =>
                this.okButtonClick(record)
            })}
          >
            <Table.Column title="ｺｰﾄﾞ" dataIndex="site_classification_code" width={70}
              render={(value, record, index) => {
                return (
                  <div style={{
                    textAlign: 'right',
                    color: Color(record.Expression_3)?.Foreground
                  }}
                  >{value === 0 ? '' : value}</div>
                )
              }} />
            <Table.Column title="名　称" dataIndex="site_classification_name"
              render={(value, record, index) => {
                return (
                  <div style={{ color: Color(record.Expression_3)?.Foreground }}
                  >{value}</div>
                )
              }} />
            {/*  <Table.Column width={100} align='center'
              render={(value, record, index) => {
                return (
                  <Space>
                    <Button type="primary" onClick={() => this.okButtonClick(record)}>保　守</Button>
                  </Space>
                )
              }} /> */}

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0268001_SiteClassifySearchQuery);
