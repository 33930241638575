import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Modal } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import WS0397001_ParamPromptedQuerySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx';
import WS2592016_ParamPromptedQueryContent from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS2592016_ParamPromptedQueryContent.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";

class WS1544003_DetailSubEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      arrLo_ExamList: [],
      change: true
    };
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
     * データ保存
     */
  onFinish = (values) => {
    this.props.onFinishScreen(values)
  }

  /**
 * データ削除
 * @param {*} id
 */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  ShowParamPromptedQuerySub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: '50%',
        visible: true,
        component:
          <WS0397001_ParamPromptedQuerySub
            Li_Format={'Y'}
            onFinishScreen={(output, list) => {
              if (list > 0) {
                this.setState({ change: false });

              } else {
                this.setState({ change: true });
              }

              this.formRef?.current?.setFieldsValue({
                'item': output.SearchInstructionDivision,
                'content': ''
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
      }
    });
  }

  ShowParamPromptedQueryContent() {
    this.setState({
      ...this.state,
      childModal: {
        width: '40%',
        visible: true,
        component:
          <WS2592016_ParamPromptedQueryContent
            Li_Format={'Y'}
            Li_IndicationDivision={this.formRef.current?.getFieldValue('W1_item')}
            onFinishScreen={(output) => {
              this.formRef?.current?.setFieldsValue({
                'content': output.item
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
      }
    });
  }

  CheckMaxLength(data, number, namePath) {
    let val = data.target.value
    if (!this.isEmpty(val)) {
      if (val.length > number) {
        val.slice(0, number)
        this.formRef.current.setFields([{
          name: namePath,
          value: val
        }])
        this.forceUpdate()
      }
    }
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={'パラメーター指示 [' + (this.props.newFlag ? '新規' : '変更') + ']'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='連番'
                name='serial_num'//対応したものに書き換え
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
              >
                <Input
                  readOnly={!this.props.newFlag}
                  bordered={this.props.newFlag} />
              </Form.Item>

              {this.props.newFlag ?
                <Form.Item
                  label='項目'
                  name='item'//対応したものに書き換え
                >
                  <Input.Search
                    maxLength={1000}
                    onSearch={() => this.ShowParamPromptedQuerySub()}
                    className='input-search-size-10'
                  />
                </Form.Item>

                :

                <Form.Item
                  label='項目'
                  name='item'//対応したものに書き換え
                >
                  <Input
                    readOnly={!this.props.newFlag}
                    bordered={this.props.newFlag}
                  />
                </Form.Item>
              }

              {this.state.change ?
                <Form.Item
                  label='内容'
                  name='content'//対応したものに書き換え
                >
                  <Input />
                </Form.Item>

                :

                <Form.Item
                  label='内容'
                  name='content'//対応したものに書き換え
                >
                  <Input.Search
                    maxLength={800}
                    onSearch={() => this.ShowParamPromptedQueryContent()}
                  />
                </Form.Item>
              }
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1544003_DetailSubEdit);
