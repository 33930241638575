import { message } from "antd";
import ConsultTicketInputProcessListService from "services/SpecificMedicalExamination/ConsultTicketInputProcessList/ConsultTicketInputProcessListService";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error("エラーが発生しました");
      }
    });
};

const ConsultTicketInputProcessListAction = {
  GetScreenData(request) {
    return post(ConsultTicketInputProcessListService.GetScreenData, request);
  },
  GetSearchData(request) {
    return post(ConsultTicketInputProcessListService.GetSearchData, request);
  },
  listProcess(request) {
    return post(ConsultTicketInputProcessListService.listProcess, request);
  },
};

export default ConsultTicketInputProcessListAction;
