import { message } from 'antd'
import WeekAndCourseSettingService from 'services/Others/MedicalCheckupCalendar/WeekAndCourseSettingService.js'

const WeekAndCourseSettingAction = {
  getListData(params) {
    return WeekAndCourseSettingService.getListDataService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveData(params) {
    return WeekAndCourseSettingService.saveData(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  delete(params) {
    return WeekAndCourseSettingService.delete(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


}

export default WeekAndCourseSettingAction
