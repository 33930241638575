import axios from "configs/axios";

const APP_LIST = {
  GetScreenData: "/api/introduce-letter-issued-main/introduce-letter-issued-main/get-screen-data",
  loadTableData: "/api/introduce-letter-issued-main/introduce-letter-issued-main/issue-list",
  Print_F12: "/api/introduce-letter-issued-main/introduce-letter-issued-main/print-f12",
  preview: "/api/introduce-letter-issued-main/introduce-letter-issued-main/preview",
  changeDepartmentName: "/api/introduce-letter-issued-main/introduce-letter-issued-main/change-department-name",
  createW2LetterOfIntroduceExtract: "/api/introduce-letter-issued-main/introduce-letter-issued-main/create-w2Letter-of-introduce-extract"
};

const IntroduceLetterIssuedMainService = {
  async GetScreenData(params) {
    return axios.get(APP_LIST.GetScreenData, { params });
  },
  async loadTableData(params) {
    return axios.get(APP_LIST.loadTableData, { params });
  },
  async Print_F12(params) {
    return axios.post(APP_LIST.Print_F12, params);
  },
  async preview(params) {
    return axios.post(APP_LIST.preview, params, { responseType: 'blob' })
  },
  async changeDepartmentName(params) {
    return axios.post(APP_LIST.changeDepartmentName, params)
  },
  async createW2LetterOfIntroduceExtract(params) {
    return axios.post(APP_LIST.createW2LetterOfIntroduceExtract, params)
  }
};

export default IntroduceLetterIssuedMainService;
