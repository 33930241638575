import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NumberFormat from 'components/Commons/NumberFormat'
import { Card, Form, Checkbox, Button, Table, Input, InputNumber } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import MutualAidSubjectBillingUnitPriceMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MutualAidSubjectBillingUnitPriceMaintain.actions.js'

class WS1779001_MutualAidSubjectBillingUnitPriceMaintain extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Lio_DisplayItems: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '共済組合請求金額保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      optionAmountMoneyDataSource: [], //オプション検査金額
      unexecutedAmountMoneyDataSource: [], //未実施金額
      disabledBtn: true,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    MutualAidSubjectBillingUnitPriceMaintainAction.index()
      .then((res) => {
        if (res) {
          this.formRef.current?.setFieldsValue({
            BasicMedicalExamCourseMenC: res.BasicMedicalExamCourseMenC,
            BasicMedicalExamCourseFemaleC: res.BasicMedicalExamCourseFemaleC,
            BasicMedicalExamCourseMenA: res.BasicMedicalExamCourseMenA,
            BasicMedicalExamCourseFemaleA: res.BasicMedicalExamCourseFemaleA
          })
          this.forceUpdate()
          // 金額一覧を取得
          this.getAmountMoneyList()
        }
      })
  }

  /**
   * 金額一覧を取得
   */
  getAmountMoneyList = () => {
    MutualAidSubjectBillingUnitPriceMaintainAction.getAmountMoneyList()
      .then((res) => {
        if (res) {
          this.setState({
            optionAmountMoneyDataSource: res.optionAmountMoney, //オプション検査金額
            unexecutedAmountMoneyDataSource: res.unexecutedAmountMoney, //未実施金額
          })
          this.formRef.current.setFieldsValue({
            optionAmountMoneyTableData: res.optionAmountMoney,
            unexecutedAmountMoneyTableData: res.unexecutedAmountMoney,
          })
        }
      })
  }

  /**
   * 保存
   */
  save = () => {
    const params = {
      BasicMedicalExamCourseMenC: this.formRef.current.getFieldValue('BasicMedicalExamCourseMenC'),
      BasicMedicalExamCourseFemaleC: this.formRef.current.getFieldValue('BasicMedicalExamCourseFemaleC'),
      BasicMedicalExamCourseMenA: this.formRef.current.getFieldValue('BasicMedicalExamCourseMenA'),
      BasicMedicalExamCourseFemaleA: this.formRef.current.getFieldValue('BasicMedicalExamCourseFemaleA'),
      optionAmountMoney: this.state.optionAmountMoneyDataSource,
      unexecutedAmountMoney: this.state.unexecutedAmountMoneyDataSource
    }

    MutualAidSubjectBillingUnitPriceMaintainAction.save(params)
      .then((res) => {
        if (res) {
          this.setState({ disabledBtn: true })
          this.index()
        }
      })
  }

  /**
 * それぞれの値の変更
 * @param {*} id
 * @param {*} field
 * @param {*} value
 * @param {*} type 1:オプション、2:未実施
 */
  updateDatasource(id, field, value, type) {

    let dataSourceName = ''
    let tableDataName = ''
    if (type === 1) {
      // オプション
      dataSourceName = 'optionAmountMoneyDataSource'
      tableDataName = 'optionAmountMoneyTableData'
    } else {
      // 未実施
      dataSourceName = 'unexecutedAmountMoneyDataSource'
      tableDataName = 'unexecutedAmountMoneyTableData'
    }

    let data = [...this.state[dataSourceName]]
    const index = this.findIndexByID(data, id)

    let tempArr = [], obj
    // data[index][field] = value

    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.formRef.current?.setFieldsValue({
      [tableDataName]: tempArr
    })
    this.setState({
      [dataSourceName]: tempArr,
      disabledBtn: false
    })
  }

  /**
 * idから行番号を取得
 * @param {*} arrayData
 * @param {*} recordID
 * @returns
 */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * 値変更時の処理
   */
  onChange = () => {
    if (this.state.disabledBtn) {
      this.setState({
        disabledBtn: false
      })
    }
  }

  render() {
    return (
      <div className='mutual-aid-subject-billing-unit-price-maintain'>
        <Card title='共済組合請求金額保守'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>

              <div className='box_inner_horizontal'>
                <div>
                  <div className='table_header_filter'>{'Cコース'}</div>
                  <div className='box_border'>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div style={{ color: 'blue', marginTop: 5 }}>男性</div>
                      <Form.Item
                        label=''
                        name='BasicMedicalExamCourseMenC'
                      >
                        <InputNumber
                          className='ant-input-number-currency-10'
                          min={0}
                          formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                          onChange={() => this.onChange()}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div style={{ color: 'red', marginTop: 5 }}>女性</div>
                      <Form.Item
                        label=''
                        name='BasicMedicalExamCourseFemaleC'
                        style={{ marginBottom: 0, color: 'red' }}
                      >
                        <InputNumber
                          className='ant-input-number-currency-10'
                          maxLength={12} min={0}
                          formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                          onChange={() => this.onChange()}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='table_header_filter'>{'Aコース'}</div>
                  <div className='box_border'>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div style={{ color: 'blue', marginTop: 5 }}>男性</div>
                      <Form.Item
                        label=''
                        name='BasicMedicalExamCourseMenA'
                      >
                        <InputNumber
                          className='ant-input-number-currency-10'
                          maxLength={12} min={0}
                          formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                          onChange={() => this.onChange()}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div style={{ color: 'red', marginTop: 5 }}>女性</div>
                      <Form.Item
                        label=''
                        name='BasicMedicalExamCourseFemaleA'
                        style={{ marginBottom: 0 }}
                      >
                        <InputNumber
                          className='ant-input-number-currency-10'
                          maxLength={12} min={0}
                          formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                          onChange={() => this.onChange()}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                {/*  オプション */}
                <div style={{ width: '50%' }}>
                  <div className='table_header_filter'>
                    {'オプション'}
                  </div>
                  <Table
                    size='small'
                    bordered
                    dataSource={this.state.optionAmountMoneyDataSource}
                    rowKey={record => record.id}
                    pagination={this.state.optionAmountMoneyDataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    scroll={{ x: 'max-content', y: 500 }}
                    rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
                    onRow={(record) => {
                      // return {
                      //   onClick: async () => {
                      //     if (record.id !== this.state.selectedRow?.id) {
                      //       //　選択変更
                      //       await this.setState({ selectedRow: record })
                      //       // 詳細な検査コードのデータを取得
                      //       await this.getItemSpecify()
                      //     }
                      //   }
                      // }
                    }}
                  >
                    <Table.Column
                      title='名称'
                      dataIndex='name'
                    />
                    <Table.Column
                      title='金額'
                      dataIndex='price'
                      className='column-size-10'
                      render={(value, record, index) => (
                        <Form.Item name={['optionAmountMoneyTableData', index, 'price']}>
                          {/* <Input
                            style={{ textAlign: 'right' }}
                            onBlur={(event) => this.updateDatasource(record.id, 'price', event.target.value, 1)}
                            onPressEnter={(event) => this.updateDatasource(record.id, 'price', event.target.value, 1)}
                          /> */}
                          <InputNumber
                            className='ant-input-number-currency-10'
                            min={0}
                            formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                            onBlur={(event) => this.updateDatasource(record.id, 'price', event.target.value, 1)}
                            onPressEnter={(event) => this.updateDatasource(record.id, 'price', event.target.value, 1)}
                          />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      title='本人負担額'
                      dataIndex='principal_burden_price'
                      className='column-size-10'
                      render={(value, record, index) => (
                        <Form.Item name={['optionAmountMoneyTableData', index, 'principal_burden_price']}>
                          {/* <Input
                            style={{ textAlign: 'right' }}
                            onBlur={(event) => this.updateDatasource(record.id, 'principal_burden_price', event.target.value, 1)}
                            onPressEnter={(event) => this.updateDatasource(record.id, 'principal_burden_price', event.target.value, 1)}
                          /> */}
                          <InputNumber
                            className='ant-input-number-currency-10'
                            maxLength={12} min={0}
                            formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                            onBlur={(event) => this.updateDatasource(record.id, 'principal_burden_price', event.target.value, 1)}
                            onPressEnter={(event) => this.updateDatasource(record.id, 'principal_burden_price', event.target.value, 1)}
                          />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      title='C'
                      dataIndex='StsC'
                      width={40}
                      align='center'
                      render={(value, record, index) => (
                        <Form.Item name={['optionAmountMoneyTableData', index, 'StsC']} valuePropName='checked'>
                          <Checkbox
                            onChange={(event) => {
                              this.updateDatasource(record.id, 'StsC', event.target.checked, 1)
                            }}
                          />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      title='A'
                      dataIndex='StsA'
                      width={40}
                      align='center'
                      render={(value, record, index) => (
                        <Form.Item name={['optionAmountMoneyTableData', index, 'StsA']} valuePropName='checked'>
                          <Checkbox
                            onChange={(event) => {
                              this.updateDatasource(record.id, 'StsA', event.target.checked, 1)
                            }}
                          />
                        </Form.Item>
                      )}
                    />
                  </Table>
                </div>

                {/* 未実施 */}
                <div style={{ width: '50%' }}>
                  <div className='table_header_filter'>
                    {'未実施'}
                  </div>
                  <Table
                    size='small'
                    bordered
                    dataSource={this.state.unexecutedAmountMoneyDataSource}
                    rowKey={record => record.id}
                    pagination={this.state.unexecutedAmountMoneyDataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    scroll={{ x: 'max-content', y: 500 }}
                  >
                    <Table.Column
                      title='名称'
                      dataIndex='name'
                    />
                    <Table.Column
                      title='金額'
                      dataIndex='price'
                      className='column-size-10'
                      render={(value, record, index) => (
                        <Form.Item name={['unexecutedAmountMoneyTableData', index, 'price']}>
                          {/* <Input
                            style={{ textAlign: 'right' }}
                            onBlur={(event) => this.updateDatasource(record.id, 'price', event.target.value, 2)}
                            onPressEnter={(event) => this.updateDatasource(record.id, 'price', event.target.value, 2)}
                          /> */}
                          <InputNumber
                            className='ant-input-number-currency-10'
                            maxLength={12} min={0}
                            formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                            onBlur={(event) => this.updateDatasource(record.id, 'price', event.target.value, 2)}
                            onPressEnter={(event) => this.updateDatasource(record.id, 'price', event.target.value, 2)}
                          />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      title='C'
                      dataIndex='StsC'
                      width={40}
                      align='center'
                      render={(value, record, index) => (
                        <Form.Item name={['unexecutedAmountMoneyTableData', index, 'StsC']} valuePropName='checked'>
                          <Checkbox
                            onChange={(event) => {
                              this.updateDatasource(record.id, 'StsC', event.target.checked, 2)
                            }}
                          />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      title='A'
                      dataIndex='StsA'
                      width={40}
                      align='center'
                      render={(value, record, index) => (
                        <Form.Item name={['unexecutedAmountMoneyTableData', index, 'StsA']} valuePropName='checked'>
                          <Checkbox
                            onChange={(event) => {
                              this.updateDatasource(record.id, 'StsA', event.target.checked, 2)
                            }}
                          />
                        </Form.Item>
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              disabled={this.state.disabledBtn}
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1779001_MutualAidSubjectBillingUnitPriceMaintain)
