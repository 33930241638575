import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Button, Space } from "antd";
import axios from "axios";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
class WS1873002_SiteClassifyInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '部位分類照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      exam_classification_code: null,
      site_name: null,
      id_name: null,
    };
  }

  componentDidMount() {
    this.getInitialValue();
    // console.log(this.state.dataSource);
  }

  getInitialValue = () => {
    this.setState({
      isLoadingTable: true
    })
    let params = {
      Li_InspectClassifyCode: "CT",
      Li_Division: 1
    }

    VenusApiRoutesV2.callApi('API001873002002', params)
      .then(res => {
        console.log("res->", res);
        this.setState({
          dataSource: res ? res : [],
        })

      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  }

  // getInitialValue() {
  //   let params = { Li_InspectClassifyCode: "CT" };
  //   axios.get("/api/radiography-inspect-maintain/site-classify-inquiry_WS1873002/index", { params })
  //   .then(res => {
  //     console.log("res=>", res);
  //     this.setState({
  //       dataSource: res ? res.data : []
  //     })
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   })      
  //  }

  showChildModal(record) {
    const { childModal } = this.state
    this.setState({
      childModal: {
        ...childModal,
        visible: true,
        width: 900,
        component: (<WS1873002_SiteClassifyInquiry
          Li_InspectClassifyCode={this.props?.Li_InspectClassifyCode}
          //Li_SiteClassifyCode={record?.site_classification_io}
          onFinishScreen={(data) => {
            this.setState({
              childModal: {
                ...childModal,
                visible: false,
              },
            });
          }}
        />),
      },
    })
  }

  getDatabutton_Click = (output) => {
    // this.props.
  }

  render() {
    return (
      <div className="site-classify-inquiry">
        <Card title="部位分類照会" className="mb-2">
          <Table bordered
            dataSource={this.state.dataSource}
            //loading={false}
            pagination={false}
            rowKey={(record) => record.id}
          >
            <Table.Column title="部位分類" dataIndex="site_classification_io" key="" />
            <Table.Column title="部位分類名称" dataIndex="site_name" key="" />
            <Table.Column title="" dataIndex="" key="" />
            <Table.Column render={(text, record, index) => (
              <Space>
                <Button type="primary"
                  onClick={this.getDatabutton_Click()}
                >照会</Button>
              </Space>

            )} />
          </Table>

        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1873002_SiteClassifyInquiry);
