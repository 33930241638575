import React from 'react'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Input, Form, Card, Table, Select, Button, Modal, } from 'antd'
import { MoreOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, PlusOutlined, DeleteOutlined, SaveOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { saveInsurerRecordedInfoEdit, deleteInsurerRecordedInfoEdit } from 'redux/basicInfo/InsurerInfoMaintain/InsurerInfoMaintain.actions.js'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}

const ModalEdit = (props) => {
  const formRef = React.createRef()
  const [itemChange, setItemChange] = React.useState([])
  const [disabledSaveBtn, setDisabledSaveBtn] = React.useState(true)
  /**
   * Modalを開いた時の処理
   */
  React.useEffect(() => {
    formRef.current?.setFieldsValue(props.record)
  }, [props.record])

  /**
   * 入力　変更処理
   * @param {*} e
   */
  const handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]
    setItemChange({
      ...itemChange,
      [columnName]: columnValue
    })
    // 登録ボタン 活性
    setDisabledSaveBtn(false)
  }

  return (
    <Card title={props.newFlag ? '記録 [新規]' : '記録 [変更]'}>
      <Form
        ref={formRef}
        onValuesChange={handleFormChange}
        autoComplete='off'
      >
        <div className='box_container'>
          <Form.Item
            name={'importance'}
            required={true}
            label='重要度'
            {...labelCol}
          >
            <Select style={{ width: '100px' }}>
              <Select.Option value={1}>情報</Select.Option>
              <Select.Option value={3}>警告</Select.Option>
              <Select.Option value={5}>重要</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='content'
            label='内容'
            {...labelCol}
          >
            <Input />
          </Form.Item>

        </div>
      </Form>

      <div className='box_button_bottom_right'>
        <Button
          danger
          type='primary'
          style={{ display: (props.newFlag) ? 'none' : '' }}
          icon={<DeleteOutlined />}
          onClick={() => {
            // 親画面に反映する
            props.onDelete(props.record.id)
          }}
        >
          <span className='btn_label'>
            削除
          </span>
        </Button>

        {/* 登録ボタン */}
        <Button
          type='primary'
          icon={<SaveOutlined />}
          disabled={disabledSaveBtn}
          onClick={() => {
            // 親画面に反映
            props.onUpdate(formRef.current?.getFieldsValue())
          }}
        >
          <span className='btn_label'>
            登録
          </span>
        </Button>
      </div>
    </Card>
  )
}

class WS0339501_InsurerRecordedInfo extends React.Component {
  static propTypes = {
    Li_InsurerCode: PropTypes.any
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      modifyEnabled: true,
      isLoadingInsurerInfo: false,
      isLoadingScreenData: false,
      isLoadingContractInfo: false,

      InsurerCode: null,
      InsurerCodeBefore: null,

      // Startup Data
      screenData: {},

      insurerData: {},

      FilestatusInsurerInfo: false,
      FilestatusEdit: false,
      KeyEditting: false,
    }
  }

  componentDidMount = () => {
    this.setState({
      insurerData: this.props.insurerData
    })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.insurerData !== this.props.insurerData) {
      this.setState({
        insurerData: this.props.insurerData
      })
    }
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: ''
      }
    })
  }

  /**
   * 新規・編集・削除処理
   * @param {*} record
   * @param {*} Index
   */
  InsurerRecordedEdit = (record, Index) => {
    let newFlag = true

    if (record) {
      newFlag = false
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <ModalEdit
            newFlag={newFlag}
            record={record}
            onUpdate={(output) => {
              if (newFlag) {
                //新規登録
                Modal.confirm({
                  content: '新規登録します。よろしいですか？',
                  okText: 'は　い',
                  cancelText: 'いいえ',
                  onOk: () => {
                    let params = {
                      content: output.content,
                      importance: output.importance,
                      recorder: output.recorder,
                      insurerCode: this.props.insurerCode,
                    }

                    saveInsurerRecordedInfoEdit(params)
                      .then(res => {
                        this.setState({
                          insurerData: this.state.insurerData.insurer_specials.push(output),
                        })
                        this.props.onFinishScreen({ insurerData: this.state.insurerData, insurerCode: this.props.insurerCode })
                        this.closeModal()
                      })

                  }
                })

              } else {
                //上書き保存
                Modal.confirm({
                  content: '上書き登録します。よろしいですか？',
                  okText: 'は　い',
                  cancelText: 'いいえ',
                  onOk: () => {
                    let params = {
                      content: output.content,
                      importance: output.importance,
                      recorder: output.recorder,
                      insurerCode: this.props.insurerCode,
                      recordingDateOn: record.recording_date_on,
                      millisecond: record.millisecond,
                      id: record.id
                    }
                    saveInsurerRecordedInfoEdit(params)
                      .then(res => {
                        this.setState({
                          insurerData: this.state.insurerData.insurer_specials.push(output),
                        })
                        this.props.onFinishScreen({ insurerData: this.state.insurerData, insurerCode: this.props.insurerCode })
                        this.closeModal()
                      })
                  }
                })
              }

            }}

            //削除
            onDelete={(output) => {
              Modal.confirm({
                title: '確認',
                icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
                content: '削除しますか',
                okText: '削除',
                cancelText: 'キャンセル',
                onOk: () => {
                  let params = {
                    id: record.id
                  }
                  deleteInsurerRecordedInfoEdit(params)
                    .then(res => {
                      let newInsurerSpecials = this.state.insurerData.insurer_specials.filter((value, index) => index !== Index)
                      let newinsurerData = {
                        ...this.state.insurerData,
                        insurer_specials: newInsurerSpecials
                      }
                      this.props.onFinishScreen({ insurerData: newinsurerData, insurerCode: this.props.insurerCode })
                      this.closeModal()
                    })
                }
              });
            }}
          />
        )
      }
    })
  }

  render() {
    return (
      <Card title='記録'>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          onValuesChange={this.onValuesChange}
          onFinishFailed={this.onFinishFailed}
        >
          <Table
            bordered
            dataSource={this.state.insurerData.insurer_specials}
            pagination={false}
            size='small'
            scroll={{ y: 400 }}
            rowKey={record =>
              'record-' +
              (record.id || Math.random()) +
              '-' +
              (record.insurer_number || Math.random())
            }
            onRow={(record, Index) => {
              return {
                onDoubleClick: async () => {
                  this.InsurerRecordedEdit(record, Index)
                }
              };
            }}
          >
            <Table.Column
              width={90}
              title='重要度'
              dataIndex='importance'
              className='column-size-4'
              render={(text, record, index) => {
                return (
                  <div>{text === 1 ? '情報' : text === 3 ? '警告' : '重要'}</div>
                )
              }}
            />

            <Table.Column
              width={40}
              align="center"
              dataIndex='importance'
              key='icon'
              className='column-size-2'
              render={text => {
                let icon = null
                switch (text) {
                  case 1:
                    icon = <InfoCircleOutlined style={{ color: '#1890ff' }} />
                    break
                  case 3:
                    icon = <WarningOutlined style={{ color: '#faad14' }} />
                    break
                  case 5:
                    icon = <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                    break
                  default:
                    icon = <MoreOutlined />
                }

                return <div style={{ fontSize: 16 }}>{icon}</div>
              }}
            />

            <Table.Column
              title='内容'
              dataIndex='content'
            />

            <Table.Column
              title='記録者'
              dataIndex='recorder'
              className='column-size-10'
              width={150}
            />

            <Table.Column
              align='center'
              width={40}
              title={() => (
                <Button
                  type='primary'
                  size='small'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    // this.addNewRowToTable('RecordedInfo');
                    this.InsurerRecordedEdit()
                  }}
                />
              )}

              key='action'
              render={(text, record, Index) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    this.InsurerRecordedEdit(record, Index)
                  }}
                />
              )}
            />
          </Table>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            })
          }}
        />
      </Card>
    )
  }
}

export default WS0339501_InsurerRecordedInfo
