import { message } from 'antd'
import OcrCaptureStartUpService from 'services/CooperationRelated/OcrCaptureStartUp/OcrCaptureStartUpService'

const OcrCaptureStartUpAction = {
  index() {
    return OcrCaptureStartUpService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getInitialData() {
    return OcrCaptureStartUpService.getInitialData()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  read(data) {
    return OcrCaptureStartUpService.read(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getOcrCaptures() {
    return OcrCaptureStartUpService.getOcrCaptures()
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  downloadLog(request) {
    return OcrCaptureStartUpService.downloadLog(request);
  },

  deleteBatchBefore() {
    return OcrCaptureStartUpService.deleteBatchBefore()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  batchDelete() {
    return OcrCaptureStartUpService.batchDelete()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}

export default OcrCaptureStartUpAction
