import ContractHistorySubService from 'services/basicInfo/ContractInfoMaintain/ContractHistorySubService'
import { message, } from 'antd'

const ContractHistorySubAction = {
  loadContractData(params) {
    return ContractHistorySubService.loadContractData(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getTableData(params) {
    return ContractHistorySubService.getTableData(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  onFinish(params) {
    return ContractHistorySubService.onFinish(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  destroyTerm(params) {
    return ContractHistorySubService.destroyTerm(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default ContractHistorySubAction
