import { message } from 'antd'
import BillingAggregationProcessService from 'services/AccountingBusiness/Invoice/BillingAggregationProcessService'

const BillingAggregationProcess = {
  getScreenData(data) {
    return BillingAggregationProcessService.getScreenData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  Extract(data) {
    return BillingAggregationProcessService.Extract(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  aggregateSpecify(data) {
    return BillingAggregationProcessService.aggregateSpecify(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },

  createInvoiceConfirm(data) {
    return BillingAggregationProcessService.createInvoiceConfirm(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default BillingAggregationProcess
