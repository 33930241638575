import React from 'react';
import { connect } from 'react-redux';
import { SaveOutlined, MoreOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ModalDraggable from 'components/Commons/ModalDraggable';
import { Card, Form, Input, Select, Button, Table, Menu, Dropdown, message } from 'antd';
import ModalChange from 'components/Commons/ModalChange'
import ContractMasterDetailsAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetails.actions'
import WS1317008_AddInspectDetailedInput from 'pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1317008_AddInspectDetailedInput.jsx';
import WS1317039_InspectItemSearchQuerySingle from 'pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1317039_InspectItemSearchQuerySingle.jsx';

// 表示行数
const rowCount = 10

const columns = [
  {
    title: '受託',
    dataIndex: 'FiduciaryBusinessAdd',
    classNumber: '10',
    selectList: [{
      value: 0,
      label: '対象外'
    },
    {
      value: 1,
      label: '対象'
    }],
    type: 2,
  },
  {
    title: '追加健診名',
    dataIndex: 'CheckupName',
    classNumber: '30',
    type: 1,
  },
  {
    title: <div style={{ textAlign: 'left' }}>単価</div>,
    dataIndex: 'additional_medical_exam_unit_price_',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
]

class WS1316026_ContractMasterMaintainAddInspection extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = 'V4-TMS01000:契約マスタ保守'; 
    this.state = {
      childModal: {
        visible: false,
        component: null,
        title: null,
        width: 0,
      },
      rowList: [],
      tableData: [],
      changeData: {},
    };
  }
  componentDidMount() {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < rowCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ rowList: array })
    this.getDataSource(array)
  }

  getDataSource(list) {
    let allData = this.props.allData;
    let array = []
    let obj, nameNumber, Fiduciary, Checkup, InspectCd, InspectName, additional
    // 2桁ゼロ埋めリスト
    let numberList = list
    for (let i = 0; i < rowCount; i++) {
      // 2桁ゼロ埋めの値を取得
      nameNumber = numberList[i]
      // 表示名称
      Fiduciary = allData[`FiduciaryBusinessAdd${nameNumber}Screen`]
      Checkup = allData[`CheckupName${nameNumber}`]
      InspectCd = allData[`InspectCd${nameNumber}`]
      InspectName = allData[`InspectName${nameNumber}`]
      additional = allData[`additional_medical_exam_unit_price_${nameNumber}`]
      obj = {
        id: i + 1,
        FiduciaryBusinessAdd: Fiduciary,
        CheckupName: Checkup,
        InspectCd: InspectCd,
        InspectName: InspectName,
        additional_medical_exam_unit_price_: additional
      }
      // 配列に追加
      array.push(obj)
    }
    this.setState({ tableData: array })
    this.formRef.current?.setFieldsValue({
      dataTable: array
    })
  }

  update(output, index) {
    // 変更後されるカラムを反映するため
    let changeData = output.itemChange
    let tableSource = [...this.state.tableData]
    let columnNames = Object.keys(changeData)
    let columnValues = Object.values(changeData);
    for (let i = 0; i < columnNames.length; i++) {
      tableSource[index][columnNames[i]] = columnValues[i]
    }
    this.formRef.current?.setFieldsValue({
      dataTable: tableSource
    })
    // DBの名前に合わせるための処理
    let FiduciaryBusinessAdd = 'servicing_addition_medical_exam_'
    let CheckupName = 'additional_medical_exam_name'
    let additional_medical_exam_unit_price = 'additional_medical_exam_unit_price'
    let ret = ('00' + (index + 1)).slice(-2)
    let obj
    console.log(changeData.FiduciaryBusinessAdd);
    if (changeData.FiduciaryBusinessAdd !== undefined) {
      console.log('受託');
      FiduciaryBusinessAdd = FiduciaryBusinessAdd + ret
      obj = {
        [FiduciaryBusinessAdd]: changeData.FiduciaryBusinessAdd,
      }
    }
    if (changeData.CheckupName) {
      console.log('追加健診名');
      CheckupName = CheckupName + '_' + ret
      obj = {
        ...obj,
        [CheckupName]: changeData.CheckupName,
      }
    }
    // 単価の値は数値のため入力チェックを行っている
    if (changeData.additional_medical_exam_unit_price_) {
      console.log('単価');
      if (!isNaN(changeData.additional_medical_exam_unit_price_)) {
        additional_medical_exam_unit_price = additional_medical_exam_unit_price + '_' + ret
        obj = {
          ...obj,
          [additional_medical_exam_unit_price]: changeData.additional_medical_exam_unit_price_

        }
      } else {
        message.error('単価は数値で入力してください')
        return
      }
    }
    let param = {
      id: this.props.allData.id,
      changeData: obj
    }
    console.log(param);
    ContractMasterDetailsAction.update(param)
      .then((res) => {
        console.log('props');
        this.props.getContentInput()
        this.closeModal()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  render() {
    return (
      <div className='contract-master-maintain-add-inspection'>
        <Card title='追加検査'>
          <Form
            ref={this.formRef}
          >
            <Table
              bordered
              dataSource={this.formRef.current?.getFieldValue('dataTable') ? this.formRef.current?.getFieldValue('dataTable') : []}
              size='small'
              rowKey={(record) => record.id}
              pagination={false}
            >
              {columns.map((item, index) => (
                item.type === 1 ?
                  <Table.Column
                    key={'input' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign ?? ''}
                  /> : item.type === 2 ?
                    <Table.Column
                      key={'check' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      className={'column-size-' + item.classNumber}
                      align={item.textAlign}
                      render={(value, record) => {
                        // findを使ってvalueを元に検索する
                        let findItem = item.selectList.find(selected => selected.value === value);
                        // labelを指定してreturn
                        return findItem.label
                      }}
                    />
                    : item.type === 9 ?
                      <Table.Column
                        key={'input' + index}
                        title={item.title}
                        dataIndex={item.dataIndex}
                        className={'column-size-' + item.classNumber}
                        align={item.textAlign ?? ''}
                      /> : null
              ))}
              <Table.Column
                className='column-size-2'
                align='center'
                title={''}
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      icon={<MoreOutlined />}
                      trigger='click'
                      overlay={() => (
                        <Menu >
                          <Menu.Item
                            key='menu-1'
                            onClick={() => {
                              // 編集、削除
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  component:
                                    <ModalChange
                                      columns={columns}
                                      record={record}
                                      deleteFlag={false}
                                      onUpdate={(output) => {
                                        console.log(output);
                                        this.update(output, index)
                                      }}
                                    />
                                }
                              })
                            }}
                          >
                            編集
                          </Menu.Item>
                          <Menu.Item
                            key='menu-2'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '650px',
                                  className: '',
                                  component:
                                    <WS1317008_AddInspectDetailedInput
                                      year={this.props.allData.year}
                                      serial_number={this.props.allData.serial_number}
                                      Li_AddTableNum={record.id}
                                      Li_TableName={record.CheckupName}
                                      onFinishScreen={(output) => {
                                        console.log(output);
                                        this.closeModal()
                                      }}
                                    />
                                },
                              });
                            }
                            }>
                            詳細
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
            this.setState({ isloadfrm: false })
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1316026_ContractMasterMaintainAddInspection);
