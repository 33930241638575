import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Table, Space } from "antd";

class WS2669001_XmlErrorTargetList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'XMLエラー対象者一覧';

    this.state = {
      errorCount: 0
    };
  }

  onFinish(values) {}

  render() {
    return (
      <div className="xml-error-target-list">
        <Card title="XMLエラー対象者一覧">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <Form.Item name="" label="検索">
                  <Input type="text" />
                </Form.Item>
                <Form.Item style={{ marginLeft: "50px", marginRight: "10px", fontWeight: "bold" }}>
                  エラー人数:
                </Form.Item>
                <Form.Item style={{ fontWeight: "bold" }}>{this.state.errorCount} 人</Form.Item>
              </Space>
              <Form.Item>
                <Button type="primary">再表示</Button>
              </Form.Item>
            </Space>
            <Table>
              <Table.Column title="受診日" dataIndex="" width={150} />
              <Table.Column title="受付番号" dataIndex="" width={150} />
              <Table.Column title="メモ" dataIndex="" width={80} />
              <Table.Column title="氏  名" dataIndex="" width={200} />
              <Table.Column title="エラー" dataIndex="" width={80} />
              <Table.Column title="件数" dataIndex="" width={80} />
              <Table.Column title="コード" dataIndex="" width={80} />
              <Table.Column title="エラー内容" dataIndex="" />
            </Table>
            <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              <Button htmlType="submit" type="primary">
                検索
              </Button>
            </Space>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2669001_XmlErrorTargetList);
