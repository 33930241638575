import axios from 'configs/axios'

const apiPaths = {
  getMenuList: '/api/main-menu/getmenulist'
}

const MainMenuService = {
  async getMenuList() {
    return axios.get(apiPaths.getMenuList)
  },
}
export default MainMenuService
