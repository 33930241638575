import axios from "configs/axios";

const APP_LIST = {
  execCapture:"/api/data-range-check/csv-capture-screen",
};

const CsvCaptureScreenService = {
  async execCapture(params){
    return axios.get(APP_LIST.execCapture, params);
  },
};

export default CsvCaptureScreenService;
