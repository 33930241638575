import axios from 'configs/axios';

const apiPaths = {
  index: '/api/input-vote-result-input/input-vote-result-input/index',
  getTargetList: '/api/input-vote-result-input/input-vote-result-input/get-target-list',
  getTargetInfo: '/api/input-vote-result-input/input-vote-result-input/get-target-info',
  checkDefaultValue: '/api/input-vote-result-input/input-vote-result-input/check-default-value',
  saveDefaultValue: '/api/input-vote-result-input/input-vote-result-input/save-default-value',
  saveResultValueList: '/api/input-vote-result-input/input-vote-result-input/save-result-value-list',
  getInputVoteResultInputItem: '/api/input-vote-result-input/input-vote-result-input/get-input-vote-result-input-item',
  getInputVoteResultInputItemDetail: '/api/input-vote-result-input/input-vote-result-input/get-input-vote-result-input-item-detail',
  saveVoucherEdit: '/api/input-vote-result-input/input-vote-result-input/save-voucher-edit',
  deleteVoucherEdit: '/api/input-vote-result-input/input-vote-result-input/delete-voucher-edit',
  saveVoucherDetailItemEdit: '/api/input-vote-result-input/input-vote-result-input/save-voucher-detail-item-edit',
  deleteVoucherDetailItemEdit: '/api/input-vote-result-input/input-vote-result-input/delete-voucher-detail-item-edit',
  copy: '/api/input-vote-result-input/input-vote-result-input/copy',
};

const InputVoteResultInputService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },

  async getTargetList(params) {
    return axios.post(apiPaths.getTargetList, params)
  },

  async getTargetInfo(params) {
    return axios.get(apiPaths.getTargetInfo, { params })
  },

  async checkDefaultValue(params) {
    return axios.post(apiPaths.checkDefaultValue, params)
  },

  async saveDefaultValue(params) {
    return axios.post(apiPaths.saveDefaultValue, params)
  },

  async saveResultValueList(params) {
    return axios.post(apiPaths.saveResultValueList, params)
  },

  async getInputVoteResultInputItem(params) {
    return axios.get(apiPaths.getInputVoteResultInputItem, { params })
  },

  async getInputVoteResultInputItemDetail(params) {
    return axios.get(apiPaths.getInputVoteResultInputItemDetail, { params })
  },

  async saveVoucherEdit(params) {
    return axios.post(apiPaths.saveVoucherEdit, params)
  },

  async deleteVoucherEdit(params) {
    return axios.delete(apiPaths.deleteVoucherEdit, { params })
  },

  async saveVoucherDetailItemEdit(params) {
    return axios.post(apiPaths.saveVoucherDetailItemEdit, params)
  },

  async deleteVoucherDetailItemEdit(params) {
    return axios.delete(apiPaths.deleteVoucherDetailItemEdit, { params })
  },

  async copy(params) {
    return axios.post(apiPaths.copy, params)
  },
};

export default InputVoteResultInputService;
