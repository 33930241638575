import axios from 'configs/axios'

const API_LIST = {
  index: '/api/option-setting/option-setting',
  getOptionList: '/api/option-setting/option-setting/get-option-list',
  save: '/api/option-setting/option-setting/save',
  optionNewSave: '/api/option-setting/option-setting/option-new-save',
  optionSave: '/api/option-setting/option-setting/option-save',
  itemSave: '/api/option-setting/option-setting/item-save',
  delete: '/api/option-setting/option-setting/delete-option',
  itemDelete: '/api/option-setting/option-setting/delete-item',
  captureFile: '/api/option-setting/option-setting/capture-file'
}

const OptionSettingService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getOptionList(params) {
    return axios.get(API_LIST.getOptionList, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async optionNewSave(params) {
    return axios.post(API_LIST.optionNewSave, params)
  },
  async optionSave(params) {
    return axios.post(API_LIST.optionSave, params)
  },
  async itemSave(params) {
    return axios.post(API_LIST.itemSave, params)
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params)
  },
  async itemDelete(params) {
    return axios.post(API_LIST.itemDelete, params)
  },
  async captureFile(params) {
    return axios.post(API_LIST.captureFile, params)
  },
}

export default OptionSettingService
