import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Input,
  Checkbox,
  Button,
  Select
} from "antd";
import {
  SaveOutlined
} from "@ant-design/icons";
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import AutoJudgmentManagementAction from 'redux/SM_SystemMaintenance/AutoJudgmentManagement/AutoJudgmentManagement.action.js'

const layout = { style: { width: 100 } }

class WS3401003_AutoJudgmentNameEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '資料一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      judgmentLevelList: [],
      judgmentLevelSpecification: false
    }
  }

  componentDidMount = () => {
    AutoJudgmentManagementAction.getScreenData()
      .then((res) => {
        this.setState({
          judgmentLevelList: res
        })
        if (this.props.newFlag == false) {
          this.reflectDatas()
        }
      })
  }

  reflectDatas = () => {
    this.formRef.current?.setFieldsValue({
      "display_number": this.props.record.display_number,
      "judgment_name_code": this.props.record.judgment_name_code,
      "judgment_name": this.props.record.judgment_name,
      "judgment_level_specification": this.props.record.judgment_level_specification,
      "judgment_level": this.props.record.judgment_level,
      "invalid_flag": this.props.record.invalid_flag,
      "start_date": this.props.record.start_date,
      "end_date": this.props.record.end_date
    })
    this.setState({
      judgmentLevelSpecification: this.props.record.judgment_level_specification
    })
  }

  save = () => {
    if (this.props.newFlag === true) {
      let params = this.formRef.current?.getFieldsValue('')

      AutoJudgmentManagementAction.addNewJudgmentName(params)
        .then(res => {
          if (res) {
            this.props.onFinishScreen()
          }
        })
    } else {
      let params = { ...this.formRef.current?.getFieldsValue(''), id: this.props.record.id }

      AutoJudgmentManagementAction.saveJudgmentName(params)
        .then(res => {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        })
    }
  }

  changeJudgmentLevelSpecification = (event) => {
    this.setState({
      judgmentLevelSpecification: event.target.checked
    })
  }

  render() {
    return (
      <div className="auto-judgment-name-edit" style={{ width: 700 }}>
        <Card title='自動判定名編集'>
          <Form
            ref={this.formRef}
            autoComplete={false}
          >
            <div className="box_container">
              <div className="box_inner_vertical">
                <Form.Item
                  name='display_number'
                  label='No'
                  labelCol={layout}
                >
                  <Input
                    className="input-size-number-4"
                  />
                </Form.Item>
                <Form.Item
                  name='judgment_name_code'
                  label='コード'
                  labelCol={layout}
                >
                  <Input
                    className="input-size-number-10"
                    disabled={this.props.newFlag === false}
                  />
                </Form.Item>
                <Form.Item
                  name='judgment_name'
                  label='名称'
                  labelCol={layout}
                >
                  <Input
                    className="input-size-60"
                  />
                </Form.Item>
                <Form.Item
                  name='judgment_level_specification'
                  label='判定レベル指定'
                  labelCol={layout}
                  valuePropName='checked'
                >
                  <Checkbox onChange={(event) => this.changeJudgmentLevelSpecification(event)} />
                </Form.Item>
                <Form.Item
                  name='judgment_level'
                  label='判定レベル'
                  labelCol={layout}
                >
                  <Select
                    style={{ width: 200 }}
                    disabled={!this.state.judgmentLevelSpecification}
                  >
                    {this.state.judgmentLevelList?.map(value => (
                      <Select.Option
                        key={"JudgeLevel-" + Math.random()}
                        value={value?.condition_1}
                      >
                        {value?.item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='invalid_flag'
                  label='無効'
                  labelCol={layout}
                  valuePropName='checked'
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  name='start_date'
                  label='開始日'
                  labelCol={layout}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM/DD'
                  />
                </Form.Item>
                <Form.Item
                  name='end_date'
                  label='終了日'
                  labelCol={layout}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM/DD'
                  />
                </Form.Item>
              </div>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  onClick={() => { this.save() }}
                  icon={<SaveOutlined />}
                >
                  <span className="btn_label">
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3401003_AutoJudgmentNameEdit);