import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Checkbox, Button } from 'antd'
import { SaveOutlined, MenuOutlined } from '@ant-design/icons'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle'
import WS2705501_CategoryInquiry from './WS2705501_CategoryInquiry'
import ModalDraggable from 'components/Commons/ModalDraggable'

const labelCol = {
  labelCol: { style: { width: 80 } }
}
class WS2705500_CategorySetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'カテゴリ別検査項目設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchCategorySettingsDisplayList: [],
      isLoadingTableLeft: false,
      isLoadingTableRight: false,
      PatternCodeDisplayList: [],
      record: {}
    }
  }

  componentDidMount() {
    // console.log(this.props.record)
    if (this.props.record !== null) {
      this.setState({
        record: this.props.record
      })
      this.formRef.current.setFieldsValue(this.props.record)
    }
  }

  transitionInquiry = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2705501_CategoryInquiry
            record={this.props.record}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  Save = () => {
    this.props.onFinishScreen({
      Li_record: this.formRef.current.getFieldsValue()
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  proofreading = (value) => {
    console.log(value)
    value = value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });

    console.log(value)

    this.formRef.current?.setFieldsValue({
      display_no: value
    })
    this.forceUpdate()
  }

  render() {
    return (
      <div className='category-setting'>
        <Card title={this.props.record ? '修正' : '新規追加'} >
          <div className='box_container'>
            <Form
              ref={this.formRef}
            >
              <Form.Item
                name='display_no'
                label='順'
                {...labelCol}
              >
                <Input className='input-size-number-4' onBlur={(e) => this.proofreading(e.target.value)} />
              </Form.Item>
              <Form.Item
                name='category_code'
                label='カテゴリ'
                {...labelCol}
              >
                <Input.Search
                  className='input-size-number-10'
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 800,
                        component: (
                          <WS0267001_CategorySearchQuerySingle
                            Lio_CategoryCode={this.formRef.current.getFieldValue('category_code')}
                            masterSettingFlg={1}
                            searchCategorySettingsDisplayList={this.props.searchCategorySettingsDisplayList}
                            onFinishScreen={(output) => {
                              this.formRef.current.setFieldsValue({
                                category_code: output.Lio_CategoryCode,
                                category_name: output.Lio_CategoryName
                              })
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name='category_name'
                label='カテゴリ名称'
                {...labelCol}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='CategoryDeterminingPresence'
                label='判定'
                valuePropName='checked'
                {...labelCol}
              >
                <Checkbox />
              </Form.Item>
            </Form>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              icon={<MenuOutlined />}
              type='primary'
              disabled={!this.props.record}
              onClick={() => {
                this.transitionInquiry()
              }}
            >
              詳細
            </Button>
            <Button
              icon={<SaveOutlined />}
              type='primary'
              onClick={() => {
                this.Save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>

        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
export default WS2705500_CategorySetting
