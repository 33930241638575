import React from "react";
import { connect } from "react-redux";
import PrintProcessAction from "redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/PrintProcess.action";
import WS0435012_PreviewConfirm from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0435012_PreviewConfirm.jsx";
import { Card, Table, Button, Space, message, } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import ResultConfirmAction from 'redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/ResultConfirm.action'
import { download_file } from 'helpers/CommonHelpers'

class WS1362003_PrintProcess extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '印刷処理';

    this.state = {
      printProcessing: [],
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    let params = {
      dataList: this.props.dataList
    }
    PrintProcessAction.getScreenData(params)
      .then((res) => {
        this.setState({
          printProcessing: this.props?.selectedIDCD ? res.filter(item => this.props?.selectedIDCD.includes(item.W1_id_cd)) : res,
        })
      })
  }

  makeCSV() {
    let params = {
      datalist: this.state.printProcessing
    };
    ResultConfirmAction.makeCSV(params)
      .then((res) => {
        console.log(res)
        download_file(res)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }


  renderMenuBar = () => (
    <Space>
      <Button onClick={() => {
        VenusApiRoutesV2.callApi("API001362003004")
          .then(res => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 500,
                component: (
                  <WS0435012_PreviewConfirm
                    Lio_Preview={res.variables?.Lio_Preview}
                    Lo_StsOutput={res.variables?.Lo_StsOutput}
                    onFinishScreen={(output) => {
                      this.closeModal()
                    }}
                  />
                ),
              },
            });
          })
          .catch(error => {
            message.error('エラーが発生しました');
          })
      }}
        type='primary' style={{ marginBottom: '10px' }}>印刷</Button>
    </Space>
  )

  render() {
    return (
      <div className="print-process">
        <Card title="印刷処理">
          <Table
            dataSource={this.state.printProcessing}
            loading={false}
            size='small'
            bordered
            scroll={{ x: 'max-content', y: resizableTableScroll(70) }}

            pagination={false}
            rowKey={(record) => record.id}
          >
            <Table.Column className="column-size-10" title="受診日" dataIndex="W1_consult_date" align="center" />
            <Table.Column
              className="column-size-60"
              title="ＩＤコード"
              dataIndex="W1_id_cd"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column className="column-size-10" title="コース" dataIndex="W1_visits_courses" align="center" />
            <Table.Column
              className="column-size-10"
              title="受付No"
              dataIndex="W1_visit_no"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="保険者"
              dataIndex="W1_insurers"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column className="column-size-40" title="事業所" dataIndex="Expresstion_5" align="center" />
            <Table.Column className="column-size-40" title="カナ氏名" dataIndex="W1_kana_name" />
            <Table.Column className="column-size-10" title="整理番号" dataIndex="W2_reference_num" />
            <Table.Column
              className="column-size-10"
              title="基本"
              dataIndex="W4_basic_commissioned_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="貧血"
              dataIndex="W4_anemia_chkup_unit_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="心電図"
              dataIndex="W4_ecg_chkup_unit_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="眼底"
              dataIndex="W4_unit_price_exam_fundus_ken"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="クレアチニン"
              dataIndex="W4_creatinine_chkup_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="合計"
              dataIndex="W4_unit_price_total_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="窓口"
              dataIndex="W4_window_burden_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="同時実施"
              dataIndex="W4_simultaneous_burden_price"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="請求金額"
              dataIndex="W4_amount_billed_to_insurer"
              render={(row, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{row}</div>
                )
              }}
            />
            <Table.Column className="column-size-10" title="有効期限" dataIndex="W2_expiration_date" align="center" />
            <Table.Column className="column-size-60" title="保険記号" dataIndex="W2_insurer_card_symbol" />
            <Table.Column className="column-size-60" title="保険番号" dataIndex="W2_insurer_num_char" />
            <Table.Column className="column-size-40" title="郵便番号" dataIndex="W2_examinee_zip_cd" align="center" />
            <Table.Column className="column-size-60" title="受診者住所" dataIndex="W2_examinee_address" ellipsis />

          </Table>
          <Space className="box_button_bottom_right">
            {/* <Button
              onClick={() => {
                VenusApiRoutesV2.callApi("API001362003004")
                  .then(res => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 500,
                        component: (
                          <WS0435012_PreviewConfirm
                            Lio_Preview={res.variables?.Lio_Preview}
                            Lo_StsOutput={res.variables?.Lo_StsOutput}
                            onFinishScreen={(output) => {
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    });
                  })
                  .catch(error => {
                    message.error('エラーが発生しました');
                  })
              }}
              type='primary' style={{ marginBottom: '10px' }}
            >
              印刷
            </Button> */}
            <Button onClick={() => {
              this.makeCSV()
            }}
              type='primary' style={{ marginBottom: '10px' }}>CSV</Button>
          </Space>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1362003_PrintProcess);
