import PersonalAttributesReacquireService from 'services/basicInfo/PersonalInfoMaintain/PersonalAttributesReacquireService'

const PersonalAttributesReacquireAction = {
  index(params) {
    return PersonalAttributesReacquireService.index(params)
  },
  reacquisition(params) {
    return PersonalAttributesReacquireService.reacquisition(params)
  }
}

export default PersonalAttributesReacquireAction
