import React from 'react'
import { Card, Button, Table, Modal, Form, Select, Typography, Input, message } from 'antd'
import { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, PlusOutlined, MoreOutlined, DeleteOutlined, SaveOutlined, } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import PersonalInfoMaintainAction from 'redux/basicInfo/PersonalInfoMaintain/PersonalInfoMaintain.action'

// カラム一覧
const columns = [
  {
    title: '重要度',
    dataIndex: 'importance',
    classNumber: '5',
    type: 2,
    selectList: [
      {
        value: 0,
        label: ''
      },
      {
        value: 1,
        label: '情報'
      },
      {
        value: 3,
        label: '警告'
      },
      {
        value: 5,
        label: '重要'
      }
    ],
  },
  {
    title: '',
    dataIndex: 'importance',
    classNumber: '5',
    textAlign: 'center',
    type: 9,
  },
  {
    title: '特記メモ',
    dataIndex: 'content',
    classNumber: '40',
    type: 1,
  },
]

/**
 * 入力用コンポーネント
 * @param {*} props
 * @returns
 */
const ModalEdit = (props) => {
  const formRef = React.createRef()
  const [itemChange, setItemChange] = React.useState([])
  const [disabledSaveBtn, setDisabledSaveBtn] = React.useState(true)
  /**
   * Modalを開いた時の処理
   */
  React.useEffect(() => {
    if (props.newFlag) return
    formRef.current?.setFieldsValue(props.record)
  }, [props.record])

  /**
   * 入力　変更処理
   * @param {*} e
   */
  const handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]
    setItemChange({
      ...itemChange,
      [columnName]: columnValue
    })
    // 登録ボタン 活性
    setDisabledSaveBtn(false)
  }

  return (
    <Card title={(props.newFlag) ? '新規追加' : '変更'}>
      <Form
        ref={formRef}
        onValuesChange={handleFormChange}
        autoComplete='off'
      >
        {props.columns.map((item, index) => (
          <div key={item + index}>
            {item.type !== 9 ?
              <Typography style={{ marginBottom: '5px' }}>
                {item.title}
              </Typography> : null
            }
            {(item.type === 1) ?
              // <Input>
              <Form.Item name={item.dataIndex}>
                <Input
                  style={{ marginBottom: '5px' }}
                  className={'input-size-' + item.classNumber}
                />
              </Form.Item>
              :
              (item.type === 2) ?
                // <Select>
                <Form.Item
                  name={item.dataIndex}
                  className={'input-size-' + item.classNumber}
                >
                  <Select style={{ marginBottom: '5px' }}>
                    {item.selectList.map((item, index) => (
                      <Select.Option
                        key={item.label + index}
                        value={item.value}
                      >
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                :
                null
            }
          </div>
        ))}
      </Form>
      <div style={{ display: 'flex', paddingTop: '10px', gap: '10px', justifyContent: 'center' }}>
        <Button
          danger
          type='primary'
          style={{ display: (props.newFlag) ? 'none' : '' }}
          icon={<DeleteOutlined />}
          onClick={() => {
            // 親画面に反映する
            props.onDelete(itemChange)

          }}
        >
          <span className='btn_label'>
            削除
          </span>
        </Button>
        {/* 登録ボタン */}
        <Button
          type='primary'
          icon={<SaveOutlined />}
          disabled={disabledSaveBtn}
          onClick={() => {
            // 親画面に反映
            props.onUpdate(itemChange)
          }}
        >
          <span className='btn_label'>
            登録
          </span>
        </Button>
      </div>
    </Card>
  )
}
class WS0343101_SpecialInfo extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)

    // document.title = '個人情報保守 - 特記'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      personalSpecials: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      personalId: this.props.personal.personal_number_id,
      personalSpecials: this.props.personal.personal_specials
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.personal !== prevProps.personal) {
      this.setState({
        personalId: this.props.personal.personal_number_id,
        personalSpecials: this.props.personal.personal_specials
      })
    }
  }

  /**
   * 変更された内容を反映
   * @param {*} itemChange
   * @param {*} index
   */
  setChangeData = (itemChange, index = null) => {
    //　保存ボタン押下
    const arrTemp = [...this.state.personalSpecials]

    // 変更内容を反映
    let obj = null
    if (index !== null) {
      const copyObj = { ...arrTemp[index] }
      //「変更」の場合
      obj = {
        ...copyObj,
        ...itemChange,
        changeFlag: 1 // 変更されたフラグ
      }
      // 配列に反映
      arrTemp[index] = obj
    } else {
      obj = {
        ...itemChange,
        id: Math.round(Math.random() * 1000),
        personal_number_id: this.state.personalId,
        registration_date_on: '',
        milisecond: '',
        changeFlag: 1 // 変更されたフラグ
      }
      //「新規追加」の場合
      arrTemp.push(obj)
    }

    // テーブル更新
    // this.setState({
    //   personalSpecials: arrTemp
    // })
    const data = {
      personal_specials: arrTemp
    }

    // 親画面にてAPIを呼ぶ
    this.props.onUpdate(data)

    // モーダルを閉じる
    this.closeModal()
  }

  /**
   * 特記　削除処理
   * @param {*} record
   */
  onDelete = (record) => {
    let arrTemp = [...this.state.personalSpecials]
    if (record.id) {
      const params = {
        id: record.id
      }
      // 削除
      PersonalInfoMaintainAction.deleteSpecials(params)
        .then(res => {
          const index = arrTemp.findIndex(item => item.id === record.id)
          if (index > -1) {
            // フロント側も削除
            arrTemp.splice(index, 1)
            this.setState({
              personalSpecials: arrTemp
            })
            // 親画面に反映
            this.props.onDelete(arrTemp)

            // モーダルを閉じる
            this.closeModal()
          }
        })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='special-info' >
        <Card title='特記'>
          <Table
            size='small'
            pagination={false}
            dataSource={this.state.personalSpecials}
            rowKey={record => record.id}
            bordered
          >
            {columns.map((item, index) => (
              item.type === 1 ?
                <Table.Column
                  key={'input' + index}
                  title={item.title}
                  dataIndex={item.dataIndex}
                  className={'column-size-' + item.classNumber}
                  align={item.textAlign ?? ''}
                  render={(value, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                      {value}
                    </div>
                  )}
                /> : item.type === 2 ?
                  <Table.Column
                    key={'select' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign}
                    render={(value, record) => {
                      // findを使ってvalueを元に検索する
                      let findItem = item.selectList.find(selected => selected.value === value);
                      // labelを指定してreturn
                      return findItem.label
                    }}
                  /> : item.type === 9 ?
                    <Table.Column
                      key={'icon' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      width={40}
                      align={item.textAlign}
                      render={(code) => {
                        let icon = ''
                        switch (code) {
                          case 1:
                            icon = (<InfoCircleOutlined style={{ color: '#1890ff' }} />)
                            break
                          case 3:
                            icon = (<WarningOutlined style={{ color: '#faad14' }} />)
                            break
                          case 5:
                            icon = (<CloseCircleOutlined style={{ color: '#ff4d4f' }} />)
                            break
                          default: icon = (<InfoCircleOutlined style={{ color: '#1890ff' }} />)
                            break
                        }
                        return (<div style={{ fontSize: '20px' }}>{icon}</div>)
                      }}
                    />
                    : null
            ))}
            <Table.Column
              title='ユーザー'
              dataIndex='registered_person'
              className='column-size-10'
            />
            <Table.Column
              width={40}
              // key='action'
              dataIndex=''
              align='center'
              title={() => (
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 360,
                        component: (
                          <ModalEdit
                            newFlag={true}
                            columns={columns}
                            onUpdate={(output) => {
                              if (!(output.importance ?? false)) {
                                message.error('重要度が設定されていません')
                                return
                              }

                              Modal.confirm({
                                content: '新規登録します。よろしいですか？',
                                okText: 'は　い',
                                cancelText: 'いいえ',
                                onOk: () =>
                                  // 変更内容を反映
                                  this.setChangeData(output)
                              })

                            }}
                          />
                        )
                      }
                    })
                  }
                />
              )}
              render={(text, record, index) => {
                return (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // 編集、削除
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 360,
                          className: '',
                          component:
                            <ModalEdit
                              newFlag={false}
                              columns={columns}
                              record={record}
                              onUpdate={(output) => {
                                Modal.confirm({
                                  content: '上書き登録します。よろしいですか？',
                                  okText: 'は　い',
                                  cancelText: 'いいえ',
                                  onOk: () =>
                                    // 保存ボタン押下、変更内容を反映
                                    this.setChangeData(output, index)
                                })
                              }}
                              onDelete={(output) => {
                                Modal.confirm({
                                  content: '削除します。よろしいですか？',
                                  okText: '削　除',
                                  cancelText: 'キャンセル',
                                  okType: 'danger',
                                  onOk: () =>
                                    // 削除ボタン押下
                                    this.onDelete(record)
                                })
                              }}
                            />
                        },
                      })
                    }}
                  />
                )
              }}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}


export default WS0343101_SpecialInfo
