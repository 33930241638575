import React from "react";
import { connect } from 'react-redux'
import { Card, Modal, Form, Table, Button } from "antd";
import { PlusOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ConnectHostsSettingAction from 'redux/MN_Basis/ConnectHostsSetting/ConnectHostsSetting.action.js'
import ConnectHostsSettingEdit from 'pages/MN_Basis/V5MN0902000_ConnectHostsSetting/ConnectHostsSettingEdit.jsx'

class ConnectHostsSetting extends React.Component {
  forRef = React.createRef();
  constructor(props) {
    super(props)

    // document.title = 'ホスト接続設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      dataSource: []
    }
  }

  componentDidMount() {
    this.GetScreenData()
  }

  GetScreenData() {
    ConnectHostsSettingAction.GetScreenData()
      .then(res => {
        this.setState({
          dataSource: res ? res : []
        })
      })
  }

  AddNewData(newFlag, output) {
    let params = {
      newFlag,
      ...output,
    }

    console.log(params);

    ConnectHostsSettingAction.Update(params)
      .then(res => {
        this.GetScreenData()
      })
  }

  Delete(record) {
    ConnectHostsSettingAction.Delete(record)
      .then(res => {
        this.GetScreenData()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="ConnectHostsSetting">
        <Card title='ホスト接続設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              size='small'
              bordered
              pagination={false}
              rowKey={(record) => record.Id}
              scroll={{ x: 'max-content', y: resizableTableScroll(25) }}
              dataSource={this.state.dataSource}
              onRow={(record) => ({
                onDoubleClick: () => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '400px',
                      component:
                        <ConnectHostsSettingEdit
                          newFlag={false}
                          record={record}
                          onUpdate={(output) => {
                            this.AddNewData(false, output)
                            this.closeModal()
                          }}
                          onDelete={() => {
                            this.Delete(record)
                            this.closeModal()
                          }}
                        />
                    }
                  })
                }
              })}
            >
              <Table.Column
                title='ProgramID'
                dataIndex='ProgramId'
                className="column-size-20"
              />

              <Table.Column
                title='IPアドレス　/　ホスト名'
                dataIndex='IpAddress'
                className="column-size-30"
              />

              <Table.Column
                title='ポート番号'
                dataIndex='Port'
                className="column-size-8"
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                title='ユーザーネーム'
                dataIndex='UserName'
                className="column-size-30"
              />

              {/* <Table.Column
                title='パスワード'
                dataIndex='Password'
                className="column-size-20"
              /> */}

              <Table.Column
                title='ディレクトリ先'
                dataIndex='Directory'
              />

              {/* <Table.Column
                title='Key'
                dataIndex='Key'
              /> */}

              <Table.Column
                width={40}
                style={{ textAlign: 'center' }}
                title={
                  <Button
                    type='primary'
                    size='small'
                    icon={(<PlusOutlined />)}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '400px',
                          component:
                            <ConnectHostsSettingEdit
                              newFlag={true}
                              onUpdate={(output) => {
                                this.AddNewData(true, output)
                                this.closeModal()
                              }}
                            />
                        }
                      })
                    }}
                  />
                }

                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '400px',
                            component:
                              <ConnectHostsSettingEdit
                                newFlag={false}
                                record={record}
                                onUpdate={(output) => {
                                  this.AddNewData(false, output)
                                  this.closeModal()
                                }}
                                onDelete={() => {
                                  this.Delete(record)
                                  this.closeModal()
                                }}
                              />
                          }
                        })
                      }}
                    ></Button>
                  )
                }}
              />
            </Table>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />


      </div >
    );
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectHostsSetting)
