import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Row, Col, Space, Typography, message } from "antd";
import { ModalConfirm } from "components/Commons/ModalConfirm";
import {
  copyingProcessAction
} from "redux/SpecificInsureMaintenance/SpecificHealthTokuhoOptionsFile/SpecificHealthTokuhoOptionsFile.actions";


class WS1306003_CopyingProcess extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '複写処理';

    this.state = {
      option_type: this.props.selectedRow.option_type,
      options: this.props.selectedRow.options,
      Format: '',
    };
  }

  componentDidMount() {

  }



  onFinish(values) {

  }

  callApiCopyData() {

    const params = {
      option_type: this.state.option_type,
      options: this.state.options,
      Format: this.state.Format

    }

    copyingProcessAction(params).then(res => {

      message.success(res.data.message)

      if (this.props.onFinishScreen) {
        this.props.onFinishScreen()
      }
    }).catch(error => {
      message.error(error.message)
    }).finally(() => this.setState({ isLoadingTable: false }));

  }



  render() {
    return (
      <div className="copying-process">
        <Card title="複写処理">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space direction="vertical">
              <Space size={30}>
                <Typography.Text style={{ color: '#777' }}>
                  複写元
                </Typography.Text>

                <Row gutter={[10, 0]}>
                  <Col style={{ width: '200px' }}>
                    <Input readOnly defaultValue={this.state.option_type} style={{ border: 'none', outline: 'none', boxShadow: 'none' }} />
                  </Col>
                  <Col style={{ width: '300px' }}>
                    <Input readOnly defaultValue={this.state.options} style={{ border: 'none', outline: 'none', boxShadow: 'none' }} />
                  </Col>
                </Row>

              </Space>

              <Space size={30}>
                <Typography.Text style={{ color: '#777' }}>
                  複写先
                </Typography.Text>
                <Row gutter={[10, 0]}>
                  <Col style={{ width: '200px' }}>
                    <Input type="text" onChange={(e) => this.setState({ Format: e.target.value })} />
                  </Col>
                </Row>
              </Space>

            </Space>

            <Button type="primary" style={{ float: 'right' }} onClick={() => {
              this.callApiCopyData()
            }}>実  行</Button>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1306003_CopyingProcess);
