/* eslint-disable eqeqeq */
import React from 'react'
import Color from 'constants/Color'
import { PlusCircleOutlined, SearchOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Card, Table, Form, Input, InputNumber, message, Row, Select, Space, Spin, Tooltip, Descriptions } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HigashifukyoDataInputAction from 'redux/ResultOutput/EastFukyoConverseProcess/HigashifukyoDataInput.action.js'
import EastFukyoInputAction from 'redux/ResultOutput/EastFukyoConverseProcess/EastFukyoInput.action'
import WS1132011_EastFukyoInput from 'pages/KK_ResultOutput/V4CP0044100_EastFukyoConverseProcess/WS1132011_EastFukyoInput'
import WS1133001_HigashifukyoDataSelect from 'pages/KK_ResultOutput/V4CP0044100_EastFukyoConverseProcess/WS1133001_HigashifukyoDataSelect'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS1132012_InspectResults extends React.Component {
  static propsType = {
    Li_Date: PropTypes.any,
    Li_Id: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    Li_Option: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.office = React.createRef()

    // document.title = '検査結果'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      paginationInput: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      dataSourceInput: [],
      comment: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getInspectResults()
  }

  /**
   * データ取得
   */
  getInspectResults = () => {
    let params = {
      Li_PatternCode: this.props.Li_PatternCode ?? '',
      Li_CourseLevel: this.props.Li_CourseLevel ?? 0,
      Li_ReserveNum: this.props.Li_ReserveNum ?? 0,
      Li_JudgeLevel: this.props.Li_JudgeLevel ?? 0,
      Li_ItemCode: this.props.Li_ItemCode,
    }

    HigashifukyoDataInputAction.getInspectResults(params)
      .then(res => {
        console.log(res);
        this.setState({ dataSource: res.data, comment: res.GuidanceResult })
        this.index()
      })

  }

  /**
   * データ取得
   */
  index = () => {
    let params = {
      itemCode: this.props.Li_ItemCode,
      reservation_number: this.props.Li_ReserveNum,
    }
    EastFukyoInputAction.index(params)
      .then((res) => {
        console.log(res);
        this.setState({
          dataSourceInput: res
        })
        this.formRef.current?.setFieldsValue({
          tableData: res
        })
      })
  }

  settingChange(outPut, record) {
    let params = {
      inspect_cd: record.inspect_cd,
      reservation_number: this.props.Li_ReserveNum,
      result_value: outPut.code,
      inspectionValueResultId: record.inspectionValueResultId,
    }
    console.log(params);
    EastFukyoInputAction.settingChange(params)
      .then((res) => {
        console.log(res);
        this.index()
        this.closeModal()
      })
  }

  confirmationChange() {
    let params = {
      reserve_num: this.props.Li_ReserveNum,
      item_cd: this.props.Li_ItemCode,
      status: this.props.Li_status,
    }
    EastFukyoInputAction.confirmationChange(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  /**
   * 東振協コード選択　モーダル
   */
  showWS1133001_HigashifukyoDataSelect = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS1133001_HigashifukyoDataSelect
            Li_CmtClassify={record.exam_comment_code}
            onFinishScreen={(outPut) => {
              this.settingChange(outPut, record)
            }}
          />
        ),
      }
    })
  }

  /**
   * 東振協入力　モーダル
   */
  showWS1132011_EastFukyoInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS1132011_EastFukyoInput
            Li_ReserveNum={this.props.Li_ReserveNum}
            Li_CourseLevel={this.props.Li_CourseLevel}
            Li_ItemCode={this.props.Li_ItemCode}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }


  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='inspect-results' >
        <Card
          title='検査結果'
        >
          <Form ref={this.formRef} autoComplete='off'>
            <div className='box_inner_horizontal'>
              <div className='box_inner_vertical'>
                <Table
                  size='small'
                  bordered
                  dataSource={this.state.dataSource}
                  pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  rowKey={(record) => record.id}
                  scroll={{ y: this.state.comment !== 0 ? 250 : 450 }}
                >
                  <Table.Column title='カテゴリ' dataIndex='category_name' width={120} />
                  <Table.Column title='判' dataIndex='category_judgment' width={40} />
                  <Table.Column title='検査項目' dataIndex='exam_name' width={120} />
                  <Table.Column title='結果' dataIndex='result_value' width={120} />
                  <Table.Column title='判' dataIndex='judgment_value' width={40} />
                </Table>
                {this.state.comment.length === 0 ?
                  null :
                  <Descriptions
                    bordered
                    size='small'
                    title=''
                    layout='vertical'
                  >
                    <Descriptions.Item label='コメント'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          overflowY: 'auto',
                          height: 200,
                        }}>
                        <div>
                          {this.state.comment.map((item, index) => (
                            <div
                              key={`comment-${index}`}
                            >
                              {item?.overall_comment}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* {this.state.guideCommentAll} */}
                    </Descriptions.Item>
                  </Descriptions>
                }
              </div>
              <Table
                size='small'
                bordered
                dataSource={this.state.dataSourceInput}
                pagination={this.state.dataSourceInput.length > process.env.REACT_APP_PAGE_SIZE ? this.state.paginationInput : false}
                rowKey={(record) => record.id}
                scroll={{ y: 500 }}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () => {
                    // 東振協コード選択
                    this.showWS1133001_HigashifukyoDataSelect(record)
                  }
                })}
              >
                <Table.Column title='格納先' dataIndex='exam_short_name' width={120} />
                <Table.Column
                  title='設定'
                  dataIndex='HigashifukyoCode'
                  className='this-time'
                  width={80}
                  render={(value, record, index) => {
                    return (
                      <Form.Item style={{ marginBottom: '0' }} name={['tableData', index, 'HigashifukyoCode']}>
                        <Input
                          readOnly
                          onDoubleClick={() => {
                            // 東振協コード選択
                            this.showWS1133001_HigashifukyoDataSelect(record)
                          }}
                        />
                      </Form.Item>
                    )
                  }}
                />
                <Table.Column title='表示内容' dataIndex='exam_comment_form' width={120} />
              </Table>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.confirmationChange()
                }}
              >
                {this.props.Li_status === '済' ? '解除' : '確認'}
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1132012_InspectResults)
