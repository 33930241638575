import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS2752100_MiraisReserveMaintainEdit from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2752100_MiraisReserveMaintainEdit'

import { Card, Col, Row, Table, Menu, Dropdown, Form, Checkbox, Input, Radio, message, Button, Modal } from "antd";
import { MoreOutlined, CheckOutlined } from "@ant-design/icons";
import MiraisReserveMaintainAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisReserveMaintain.actions'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
class WS2752001_MiraisReserveMaintain extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // document.title = 'Mirais予約保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isloadding: false,
      isLoadding2: false,
      selectedRow1: {},
      tableData2: [],
      count: "a",
      count2: "b",
      timeOutId: null,
      rowSelect: {}
    };
  }
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      tableData: [],
    })
    this.GetListData()
  }
  GetListData() {
    this.setState({ isloadding: true })
    MiraisReserveMaintainAction.GetListData().then(res => {
      this.formRef.current?.setFieldsValue({
        tableData: res
      })
      if (res?.[0]) {
        this.setState({ rowSelect: res[0] })
        this.ListDataNum(res[0])
      } else {
        this.setState({ selectedRow1: {} })
      }

    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
    }).finally(() => this.setState({ isloadding: false }))
  }
  ListDataNum(params) {
    // this.forceUpdate()
    MiraisReserveMaintainAction.ListDataNum(params)
      .then(res => {
        let data = res ? res : []
        data.map(x => x.time_at = x.time_at?.substr(0, 5))
        // this.formRef.current?.setFieldsValue({
        //   tableData2: data
        // })
        this.formRef.current.setFieldsValue({
          DayWeek: params?.DayOfWeek
        })
        this.setState({
          tableData2: data
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました!!!!!');
          return;
        }
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }
  AddNewRow() {
    const { count } = this.state;
    const newData = {
      id: count, reservations_department: "", reservations_item_code: "", name: "", reservations_act_code: "", enabled: false,
      reservations_comment: "", DayOfWeek: ""
    };
    let data = [...this.formRef.current?.getFieldValue("tableData")];
    data.length > 0 ? data.unshift(newData) : data.push(newData)
    this.formRef.current?.setFieldsValue({
      tableData: data,
    });
    this.forceUpdate()
    this.setState({
      ...this.state,
      count1: count + 1
    })
  }
  SaveAndInsert(record) {
    let arr = [...this.formRef.current?.getFieldValue("tableData")];
    for (const key in arr) {
      if (arr[key].id === record.id) {
        if (this.isEmpty(arr[key].reservations_department) && this.isEmpty(arr[key].reservations_item_code)) {
          return
        }
        if (isNaN(record.id)) {
          let obj = {
            reservations_department: arr[key].reservations_department,
            reservations_item_code: arr[key].reservations_item_code,
            name: arr[key].name,
            enabled: arr[key].enabled ? 1 : 0,
            reservations_comment: arr[key].reservations_comment, remarks: arr[key].remarks
          }
          this.Save(obj)
          return
        } else {
          this.Save(arr[key])
          return
        }
      }
    }
  }

  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }

  OnchangeDayWeek(e) {
    let obj = this.state.rowSelect
    obj.DayOfWeek = e.target.value
    this.ListDataNum(obj)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className="mirais-reserve-maintain">
        <Card title="Mirais予約保守">
          <Form ref={this.formRef} autoComplete="off" >
            <div className="box_inner_horizontal">
              <div className="box_inner_vertical" style={{ width: '60%' }}>
                <Table
                  dataSource={this.formRef.current?.getFieldValue("tableData") ? this.formRef.current?.getFieldValue("tableData") : []}
                  rowClassName={(record, index) => record.id === this.state.rowSelect?.id ? 'table-row-light' : ''}
                  pagination={false} size="small" scroll={{ y: 500 }}
                  rowKey={(record) => record.id}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: async () => {
                        await this.setState({
                          rowSelect: record
                        });
                        this.ListDataNum(record)
                      }
                    };
                  }}
                  bordered={true}
                >
                  <Table.Column title="科項目" width={150}
                    render={(value, record, index) => (
                      <div className="box_inner_horizontal">
                        <div>{record.reservations_department}</div>
                        <div>{record.reservations_item_code}</div>
                      </div>
                      // <Row >
                      //   <Col span={8}>
                      //     <Form.Item name={['tableData', index, 'reservations_department']} style={{ marginBottom: "0" }}>
                      //       <Input maxLength={2} style={{ width: '98%' }} ></Input>
                      //     </Form.Item>
                      //   </Col>
                      //   <Col span={16}>
                      //     <Form.Item name={['tableData', index, 'reservations_item_code']} style={{ marginBottom: "0" }}>
                      //       <Input maxLength={5} ></Input>
                      //     </Form.Item>
                      //   </Col>
                      // </Row>
                    )}
                  />
                  <Table.Column title="名称"
                    render={(value, record, index) => (
                      // <Form.Item name={['tableData', index, 'name']} style={{ marginBottom: "0" }}>
                      //   <Input maxLength={20}></Input>
                      // </Form.Item>
                      <div>{record.name}</div>
                    )}
                  />
                  <Table.Column title="行為" width={80}
                    render={(value, record, index) => (
                      // <Form.Item name={['tableData', index, 'reservations_act_code']} style={{ marginBottom: "0" }}>
                      //   <Input maxLength={6}></Input>
                      // </Form.Item>
                      <div>{record.reservations_act_code}</div>
                    )}
                  />
                  <Table.Column title="統合" width={60} dataIndex='enabled' align='center'
                    render={(value, record, index) => (
                      // return <Form.Item name={['tableData', index, 'enabled']} style={{ marginBottom: '0px' }} valuePropName="checked" >
                      //   <Checkbox></Checkbox>
                      // </Form.Item>
                      this.checkJudgment(value)
                    )}
                  />
                  <Table.Column title="コメント"
                    render={(value, record, index) => (
                      // <Form.Item name={['tableData', index, 'reservations_comment']} style={{ marginBottom: "0" }}>
                      //   <Input maxLength={60}></Input>
                      // </Form.Item>
                      <div>{record.reservations_comment}</div>
                    )}
                  />
                  <Table.Column width={60} align='center'
                    title={<Button size='small' type='primary' icon={<PlusOutlined />}
                      onClick={() => {
                        this.setState({
                          newFlag: true,
                          selectedBusinessCode: undefined,
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS2752100_MiraisReserveMaintainEdit
                                newFlag={true}
                                table={1}
                                onFinishScreen={() => {
                                  this.GetListData()
                                  this.closeModal();
                                }}
                              />
                            ),
                          }
                        })
                      }}
                    ></Button>}
                    render={(text, record, index) => {
                      return <>
                        <Button size='small' icon={<MoreOutlined />}
                          onClick={() =>
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS2752100_MiraisReserveMaintainEdit
                                    newFlag={false}
                                    record={record}
                                    table={1}
                                    onFinishScreen={(output) => {
                                      this.GetListData()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              }
                            })
                          }
                        ></Button>
                      </>
                    }}
                  />
                </Table>
                <Form.Item name="remarks">
                  <div>{this.state.rowSelect?.remarks}</div>
                </Form.Item>
              </div>
              <div className="box_inner_vertical" style={{ width: '40%' }}>
                <Form.Item name="DayWeek">
                  <Radio.Group onChange={(e) => this.OnchangeDayWeek(e)}  >
                    <Radio value={1}>日</Radio>
                    <Radio value={2}>月</Radio>
                    <Radio value={3}>火</Radio>
                    <Radio value={4}>水</Radio>
                    <Radio value={5}>木</Radio>
                    <Radio value={6}>金</Radio>
                    <Radio value={7}>土</Radio>
                  </Radio.Group>
                </Form.Item>
                <Table
                  dataSource={this.state.tableData2}
                  bordered={true}
                  pagination={false}
                  size="small"
                  scroll={{ y: 600 }}
                  rowKey={(record) => record.id}
                >
                  <Table.Column title="時間帯" width={120}
                    render={(value, record, index) => {
                      // return <Form.Item name={['tableData2', index, 'time_at']} style={{ marginBottom: '0px' }}>
                      //   <Input maxLength={5} onChange={(e) => this.OnChangeTime(e.target.value, index)} />
                      // </Form.Item>
                      return <div>{record.time_at}</div>
                    }} />
                  <Table.Column title="人数枠" width={120}
                    render={(value, record, index) => {
                      // return <Form.Item name={['tableData2', index, 'number_frame']} style={{ marginBottom: '0px' }}>
                      //   <Input maxLength={5} className='floatRight' onChange={(e) => {
                      //     let value = e.target.value;
                      //     const namePath = ['tableData2', index, 'number_frame'];
                      //     if (isNaN(value)) {
                      //       this.formRef.current?.setFields([
                      //         {
                      //           name: namePath,
                      //           value: ""
                      //         }
                      //       ])
                      //       this.forceUpdate()
                      //     } else {
                      //       if (value.length > 5) {
                      //         this.formRef.current?.setFields([
                      //           {
                      //             name: namePath,
                      //             value: value.slice(0, 5)
                      //           }
                      //         ])
                      //         this.forceUpdate()
                      //       }
                      //     }
                      //   }} />
                      // </Form.Item>
                      return <div>{record.number_frame}</div>
                    }} />
                  <Table.Column width={40} align='center'
                    title={<Button size='small' type='primary' icon={<PlusOutlined />}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 500,
                            component: (
                              <WS2752100_MiraisReserveMaintainEdit
                                newFlag={true}
                                rowSelect={this.state.rowSelect}
                                table={2}
                                onFinishScreen={(output) => {
                                  this.ListDataNum(this.state.rowSelect)
                                  this.closeModal()
                                }}
                              />
                            ),
                          }
                        })
                      }
                    ></Button>}
                    render={(text, record, index) => {
                      return <>
                        <Button size='small' icon={<MoreOutlined />}
                          onClick={() =>
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 500,
                                component: (
                                  <WS2752100_MiraisReserveMaintainEdit
                                    newFlag={false}
                                    record={record}
                                    rowSelect={this.state.rowSelect}
                                    table={2}
                                    onFinishScreen={(output) => {
                                      this.ListDataNum(this.state.rowSelect)
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              }
                            })
                          }
                        ></Button>
                        {/* <Button size='small' style={{ border: 'none' }} icon={<SaveOutlined style={{ color: 'green' }} />}
                          onClick={() => this.SaveAndInsertNum(record)}
                        ></Button>
                        <Button size='small' style={{ border: 'none' }} danger icon={<DeleteOutlined />}
                          onClick={() => {
                            Modal.confirm({
                              content: '消去してもよろしいですか？',
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => this.DeleteNum(record)
                            })
                          }}
                        ></Button> */}
                      </>
                    }}
                  />
                </Table>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2752001_MiraisReserveMaintain);
