import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, message, Table, Modal, Button, Input, Form, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';
import {
  getDataCoursesSettingSubAction, saveAndUpdateCoursesSettingSubAction, deleteDataCoursesSettingSubAction, localAcquisitionCoursesSettingSubAction, getScreenCoursesSettingSubAction
} from "redux/CooperationRelated/EMedicalRecordsInspectRequestMaintain/CoursesSettingSub.actions";

const styleFormItem = {
  margin: 0
}
class WS0104001_CoursesSettingSub extends React.Component {

  static propTypes = {
    Li_Title: PropTypes.any,
    Lio_CourseList: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'コース 一覧設定SUB';

    this.formRef = React.createRef();
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      arrCourseList: []
    };
  }

  componentDidMount = () => {
    this.loadInitData();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.loadInitData();
    }
  }

  returnData = () => {
    localAcquisitionCoursesSettingSubAction()
      .then(res => {
        if (this.props.onFinishScreen && res?.data) {
          this.props.onFinishScreen({
            Lio_CourseList: res.data.Lo_CourseList
          })
        }
      })
  }

  loadInitData = () => {
    let params = {
      Li_Title: this.props.Li_Title,
      CourseOr: this.props.Lio_CourseList,
      management_division: this.props.management_division
    }

    getScreenCoursesSettingSubAction(params)
      .then((res) => {
        if (res?.data) {
          this.setState({
            dataSource: res.data,
            arrCourseList: res.data.map(item => res.data.W1_course_cd)
          });
          this.formRef?.current?.setFieldsValue({ 'dataSource': res.data });
        }
      }
      )

      .catch((err) =>
        message.error('エラー')
      )
  }

  loadData = () => {
    this.setState({ isLoading: true });
    getDataCoursesSettingSubAction()
      .then(res => {
        if (res) {
          this.setState({ dataSource: res.data });
          this.formRef?.current?.setFieldsValue({ 'dataSource': res.data });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  setDataToForm = ({ Lo_CourseCode, recordData }, record) => {
    let index = this.findIndexByID(this.state.dataSource, record.id);
    let arrTemp = [...this.state.dataSource];
    let arrTempCourseList = [...this.state.arrCourseList];
    let isAdd = arrTemp.findIndex(item => item.W1_course_cd?.replace('-', '') === Lo_CourseCode);
    if (isAdd === -1) {
      record = {
        ...record,
        W1_course_cd: Lo_CourseCode,
        course_name_short_name: recordData.course_name_short_name,
        course_name_formal: recordData.course_name_formal,
      }
      arrTempCourseList[index] = Lo_CourseCode
      this.createOrUpdateData(record);
    } else {
      message.error('データが存在します');
    }
    arrTemp[index] = record;
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      dataSource: arrTemp,
      rowSelect: record,
      arrCourseList: arrTempCourseList
    });
    this.formRef?.current?.setFieldsValue({ 'dataSource': arrTemp });
  }

  createOrUpdateData = (record) => {
    saveAndUpdateCoursesSettingSubAction(record)
      .then(res => {
        message.success('成功');
        this.loadData();
      })
      .catch(err => message.error('エラー'))
  }

  deleteData = (record, index) => {
    if (record.id) {
      deleteDataCoursesSettingSubAction({ id: record.id })
        .then(res => {
          message.success('成功');
          this.loadData();
        })
        .catch(err => message.error('エラー'))
    } else {
      let arrTemp = [...this.state.dataSource];
      arrTemp.splice(index, 1);
      this.setState({ dataSource: arrTemp });
    }
  }

  findIndexByID = (arrayData, recordID) => arrayData.findIndex((item) => recordID === item.id);

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="courses-setting-sub">
        <Card title={`${this.props.Li_Title}`}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              size='small'
              bordered={true}
              dataSource={this.state.dataSource}
              loading={this.state.isLoading}
              pagination={{
                ...this.state.pagination,
                hideOnSinglePage: this.state.dataSource.length > 10 ? false : true
              }}
              rowKey={(record) => record.id}
              onRow={(record, index) => ({ onClick: event => this.setState({ rowSelect: record }) })}
            >
              <Table.Column
                title="コースコード"
                dataIndex="W1_course_cd"
                width={100}
              />
              <Table.Column
                title="コース略式"
                dataIndex="course_name_short_name"
              />
              <Table.Column
                title="コース名称"
                dataIndex="course_name_formal"
              />
              <Table.Column
                align='center'
                width={40}
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 900,
                          component:
                            <WS0265001_BasicCourseInquiry
                              onFinishScreen={(obj) => {
                                this.props.onFinishScreen({
                                  ...obj,
                                  FieldValue: this.props.Lio_CourseList
                                })
                                this.closeModal()
                              }}
                            />
                          ,
                        },
                      });
                    }}
                  />
                }
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="削除"
                            onClick={() => {
                              this.props.onDelete({
                                record,
                                FieldValue: this.props.Lio_CourseList
                              });
                            }}
                          >削除
                          </Menu.Item>
                        </Menu>
                      )
                      }
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0104001_CoursesSettingSub);
