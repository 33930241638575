import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Radio, Checkbox, message } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import WS1384001_InsureGuideCourseInquiry from './WS1384001_InsureGuideCourseInquiry';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx';
import WS0381001_PersonalOfficeSearchQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0381001_PersonalOfficeSearchQuery";
import WS2653001_GuideInputProvenSub from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653001_GuideInputProvenSub.jsx";
import WS1423001_SupportPlanManualCreate from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1423001_SupportPlanManualCreate.jsx";
import ReserveChangeAction from "redux/SpecificInsureGuide/InsureGuideInitInput/ReserveChange.action"


const smGrid = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 70px)' } }
}
class WS2650016_ReserveChange extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      division: 1,
      editMode: false,
      checkBox: false,
    };
  }

  componentDidMount() {
    // 変更が押された場合でも、実施が「×」なら表示の仕方を新規と同じように
    // 各項目の活性：非活性フラグ
    if (!this.props.newFlag) {
      if (this.props.Li_implementation != "×") {
        this.setState({ checkBox: true })
      }
    }
    this.getScreenData();
  }

  getScreenData() {
    this.formRef.current?.resetFields();
    if (this.props.Li_PersonalNum && this.props.Li_FirstDate) {
      //変更の場合
      let params = {
        Li_FirstDate: this.props.Li_FirstDate,
        Li_PersonalNum: this.props.Li_PersonalNum,
        Course: this.props.Course,
      }
      ReserveChangeAction.getScreenData(params)
        .then(res => {
          this.formRef.current?.setFieldsValue(res);
          this.setState({ editMode: !!res.PersonalNum });
          this.forceUpdate();
        });
    } else {
      //新規の場合
      this.setState({ editMode: false });
      let today = moment(new Date())
      this.formRef.current?.setFieldsValue({ FirstDateChar: today });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.Li_PersonalNum !== prevProps.Li_PersonalNum || this.props.Li_FirstDate !== prevProps.Li_FirstDate) {
      this.getScreenData();
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  onChangeDivision = (value) => {
    this.setState({ division: value });
    this.formRef.current?.setFieldsValue({
      division: value,
      Course: '',
      course_name: ''
    });
  }

  register() {
    const values = this.formRef.current?.getFieldsValue();
    if (values.FirstDateChar === undefined) {
      return message.error('初回日を入力してください')
    }
    if (values.PersonalNum === undefined) {
      return message.error('個人を入力してください')
    }
    if (values.OfficeCode === undefined) {
      return message.error('事業所を入力してください')
    }
    if (values.Course === undefined) {
      return message.error('コースを入力してください')
    }
    if (values.insurer_number === undefined || values.insurer_number === 0) {
      return message.error('保険者が不明です')
    }

    values.FirstDateChar = moment(values.FirstDateChar).format("YYYY/MM/DD");
    values.FirstTimeDate = moment(values.FirstTimeDate).format("YYYY/MM/DD");

    let params = {
      ...values,
      Li_FirstDate: values.FirstDateChar,
      Li_PersonalNum: values.PersonalNum,
      Li_FirstTimeDate: values.FirstTimeDate,
    }
    console.log(params);

    ReserveChangeAction.register(params)
      .then(res => {
        if (this.props.newFlag) {
          this.props.onFinishScreen()
        } else {
          this.props.onFinishScreen()
          this.getScreenData()
        }
      }).catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
  }

  personalInfoSeachQuery() {
    if (this.state.editMode) {
      return;
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue("PersonalNum")}
            onFinishScreen={(data) => {
              this.formRef.current?.setFieldsValue({ PersonalNum: data.Lo_PersonalNumId, kanji_name: data.recordData.kanji_name });
              this.forceUpdate();
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  persionInquiry() {
    const personNum = this.formRef.current?.getFieldValue("PersonalNum");
    // const title = '個人情報照会' + ' [' + personNum + ']';
    if (personNum) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: '70%',
          component: (
            // <Card title={title}>
            <WS2584019_PersonalInfoInquirySub
              Li_PersonalNum={personNum}
              onFinishScreen={(data) => {
                this.formRef.current?.setFieldsValue({ PersonalNum: data.Lo_PersonalNumId, kanji_name: data.recordData.kanji_name });
                this.forceUpdate();
                this.closeModal()
              }}
            />
            // </Card>
          ),
        },
      });
    }
  }

  persionOfficeSeachQuery() {
    const personNum = this.formRef.current?.getFieldValue("PersonalNum");
    if (personNum) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 850,
          component: (
            <WS0381001_PersonalOfficeSearchQuery
              Li_PersonalNum={personNum}
              onFinishScreen={(output) => {
                this.formRef.current?.setFieldsValue({
                  OfficeCode: output.Lio_OfficeCode,
                  BranchStoreCode: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode,
                  office_kanji_name: output.recordData.office_kanji_name,
                  insurer_number: output.recordData.insurer_number,
                  insurer_kanji_name: output.recordData.insurer_kanji_name,
                })
                this.forceUpdate();
                this.closeModal()
              }}
            />
          ),
        },
      });
    }
  }

  InsureGuideCourseInquiry() {
    if (this.state.division === '3' || this.state.division === '4') {
      return
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1050,
        component: (
          <WS1384001_InsureGuideCourseInquiry
            healthGuideDivision={this.state.division}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                Course: output?.insure_guide_course_code,
                course_name: output?.course_name
              })
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  plan() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1423001_SupportPlanManualCreate
            Li_FirstDate={this.formRef.current?.getFieldValue("FirstDateChar")}
            Li_Id={this.formRef.current?.getFieldValue("PersonalNum")}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  input() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS2653001_GuideInputProvenSub
            Li_Date={this.formRef.current?.getFieldValue("FirstDateChar")}
            Li_PersonalNum={this.formRef.current?.getFieldValue("PersonalNum")}
            Li_SerialNum={this.props.Li_SerialNum}
            kanji_name={this.formRef.current?.getFieldValue("kanji_name")}
            onFinishScreen={() => {
              this.props.onFinishScreen()
            }}
          />
        ),
      },
    });
  }

  PersonalNum(value) {
    console.log(value);
    if (value !== '') {
      let params = {
        PersonalNum: value
      }
      ReserveChangeAction.getPersonalBasic(params)
        .then(res => {
          this.formRef.current?.setFieldsValue({
            PersonalNum: value,
            kanji_name: res.kanji_name
          });
          this.forceUpdate()
        });
    }
  }

  render() {
    const { editMode } = this.state;
    return (
      <div className="reserve-change">
        <Card className="mb-2" title={"保健指導" + [editMode ? '変更' : '新規登録']}>
          <Form
            ref={this.formRef}
            initialValues={{ Division: "1" }}
            autoComplete="off"
          >
            <div className="box_inner_vertical">
              <div className="box_border">
                <div className="box_inner_horizontal">
                  {editMode ?
                    <Form.Item
                      name="FirstDateChar"
                      label="初回日"
                      {...smGrid}
                    >
                      <div>{this.formRef.current?.getFieldValue("FirstDateChar")}</div>
                    </Form.Item>
                    :
                    <Form.Item
                      name="FirstDateChar"
                      label="初回日"
                      {...smGrid}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        style={{ width: "120px" }}
                      />
                    </Form.Item>
                  }

                  <Form.Item name="Expression_3">
                    <div>{this.formRef.current?.getFieldValue("Expression_3")}</div>
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  {editMode ?
                    <Form.Item
                      name="PersonalNum"
                      label="個　人"
                      {...smGrid}
                    >
                      <div>{this.formRef.current?.getFieldValue("PersonalNum")}</div>
                    </Form.Item>
                    :
                    <Form.Item
                      name="PersonalNum"
                      label="個　人"
                      {...smGrid}
                    >
                      <Input.Search
                        className="input-search-size-number-10"
                        onSearch={() => this.personalInfoSeachQuery()}
                        onPressEnter={() => this.personalInfoSeachQuery()}
                        onDoubleClick={() => this.personalInfoSeachQuery()}
                        onBlur={(e) => this.PersonalNum(e.target.value)}
                      />
                    </Form.Item>
                  }

                  {this.formRef.current?.getFieldValue("kanji_name") !== undefined ?
                    <Form.Item>
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => this.persionInquiry()}
                      >
                      </Button>
                    </Form.Item>
                    :
                    null
                  }
                  <Form.Item name="kanji_name">
                    <div>{this.formRef.current?.getFieldValue("kanji_name")}</div>
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="OfficeCode"
                    label="事業所"
                    {...smGrid}
                  >
                    <Input.Search
                      disabled={this.formRef.current?.getFieldValue("PersonalNum") === undefined}
                      className="input-search-size-number-8"
                      onSearch={() => this.persionOfficeSeachQuery()}
                      onDoubleClick={() => this.persionOfficeSeachQuery()}
                    />
                  </Form.Item>

                  <Form.Item
                    name="BranchStoreCode"
                  >
                    <Input.Search
                      disabled={this.formRef.current?.getFieldValue("PersonalNum") === undefined}
                      className="input-search-size-number-4"
                      onSearch={() => this.persionOfficeSeachQuery()}
                      onDoubleClick={() => this.persionOfficeSeachQuery()}
                    />
                  </Form.Item>

                  <Form.Item
                    name="office_kanji_name"
                  >
                    <div>{this.formRef.current?.getFieldValue("office_kanji_name")}</div>
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item name="insurer_number" label="保　険" {...smGrid}>
                    <div>{this.formRef.current?.getFieldValue("insurer_number")}</div>
                  </Form.Item>

                  <Form.Item name="insurer_kanji_name">
                    <div>{this.formRef.current?.getFieldValue("insurer_kanji_name")}</div>
                  </Form.Item>
                </div>

              </div>

              <div className="box_border">
                <Form.Item name="Division">
                  <Radio.Group
                    disabled={this.state.checkBox}
                    onChange={(e) => { this.onChangeDivision(e.target.value) }}
                  >
                    <div className="box_inner_horizontal">
                      <Radio value="1">動機付支援</Radio>
                      <Radio value="3">動機付相当</Radio>
                    </div>
                    <div className="box_inner_horizontal">
                      <Radio value="2">積極的支援</Radio>
                      <Radio value="4">モデル実施</Radio>
                    </div>
                  </Radio.Group>
                </Form.Item>

                <div className="box_inner_horizontal">
                  <Form.Item name="Course" label="コース">
                    <Input.Search
                      disabled={this.state.checkBox}
                      style={{ width: "120px" }}
                      readOnly
                      onSearch={() => this.InsureGuideCourseInquiry()}
                      onDoubleClick={() => this.InsureGuideCourseInquiry()}
                    />
                  </Form.Item>

                  <Form.Item name="course_name">
                    <div>{this.formRef.current?.getFieldValue("course_name")}</div>
                  </Form.Item>
                </div>

                {this.state.checkBox ?
                  null
                  :
                  <div>
                    <Form.Item
                      name="FirstTime"
                      label=""
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={this.state.checkBox}
                        onChange={() => this.forceUpdate()}
                      >
                        初回分割を実施
                      </Checkbox>
                    </Form.Item>


                    <Form.Item
                      style={{ display: this.formRef.current?.getFieldValue("FirstTime") && this.formRef.current?.getFieldValue("Division") != "3" ? '' : "none" }}
                      name="FirstTimeDate"
                      label="面接実施日"
                      {...smGrid}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        style={{ width: "120px" }}
                      />
                    </Form.Item>
                  </div>
                }
              </div>
            </div>

            <div className="box_inner_horizontal">
              <div className="box_button_bottom_right">
                <Button type="primary" style={{ float: "right" }} onClick={() => this.register()}>
                  {editMode ? "変更" : "登録"}
                </Button>
                <Button type="primary" style={{ float: "right" }} hidden={!editMode} onClick={() => this.plan()}>
                  計画
                </Button>
                <Button type="primary" style={{ float: "right" }} hidden={!editMode} onClick={() => this.input()}>
                  入力
                </Button>
              </div>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2650016_ReserveChange);
