import React from 'react'
import { connect } from 'react-redux'

import { Card, Form, Select, Button } from 'antd'

class WS1872005_SiteFindingsMigration extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '部位所見移行'

    this.state = {
      initialValues: {
        Li_InspectClassifyCode: ''
      },
    }
  }
  componentDidMount() {
    this.getInitialValues()
  }

  // componentDidUpdate(PropPev) {
  //   if (this.props !== PropPev) {
  //     this.getInitialValues()
  //   }
  // }

  getInitialValues = () => {
    this.setState({
      initialValues: {
        Li_InspectClassifyCode: this.props.Li_InspectClassifyCode
      }
    })
  }

  onFinish = (values) => {
    console.log(values)
  }

  render() {
    return (
      <div className='site-findings-migration'>
        <Card title='部位所見移行' className='mb-2'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                name='F'
                label=''
                style={{ width: '160px' }}
                initialValue={'S1'}
              >
                <Select>
                  <Select.Option value='S1'>所見１</Select.Option>
                  <Select.Option value='S2'>所見２</Select.Option>
                </Select>
              </Form.Item>
              <label> {'->'} </label>
              <Form.Item
                name='T'
                label=''
                style={{ width: '160px', marginRight: '54px' }}
                initialValue={'S2'}
              >
                <Select>
                  <Select.Option value='S1'>所見１</Select.Option>
                  <Select.Option value='S2'>所見２</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item style={{ textAlign: 'end' }}>
              <Button
                type='primary'
                htmlType='submit'>
                実行
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1872005_SiteFindingsMigration)
