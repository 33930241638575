import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { SaveOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Button, Row, Col, Space, Modal, message, Spin, Table, Tooltip, Dropdown, Menu, Tabs, Checkbox, Typography, Radio, InputNumber } from "antd";
import ContractMasterDetailsAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetails.actions'

// 表示行数
const rowCount = 10

class WS1316900_ContractMasterMaintainImplementation extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // document.title = 'V4-TMS01000:契約マスタ保守'; 
    this.state = {
      childModal: {
        visible: false,
        component: null,
        title: null,
        width: 0,
      },
      rowList: [], //2ケタゼロ埋め（表示行）リスト
      tableData: [],
      changeData: {},
    };
  }
  componentDidMount() {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < rowCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ rowList: array })
    this.getDataSource(array)
  }

  getDataSource(list) {
    let allData = this.props.allData;
    let array = []
    let obj, nameNumber, name, price
    // 2桁ゼロ埋めリスト
    let numberList = list
    for (let i = 0; i < rowCount; i++) {
      // 2桁ゼロ埋めの値を取得
      nameNumber = numberList[i]
      // 表示名称
      name = allData[`same_time_name_${nameNumber}`]
      price = allData[`same_time_unit_price_${nameNumber}`]
      obj = {
        id: i + 1,
        same_time_name: name,
        same_time_unit_price: price
      }
      // 配列に追加
      array.push(obj)
    }
    this.setState({ tableData: array })
    this.formRef.current?.setFieldsValue({
      dataTable: array
    })
  }

  // handleFormChange(e) {
  //   let name, number, value
  //   for (let i = 0; i < rowCount; i++) {
  //     if (e.dataTable[i]) {
  //       if (e.dataTable[i]['same_time_name']) {
  //         value = e.dataTable[i]['same_time_name']
  //         number = ('00' + (i + 1)).slice(-2)
  //         name = 'same_time_name_' + number
  //         this.setState({
  //           changeData: {
  //             ...this.state.changeData,
  //             [name]: value
  //           },
  //           columns: {
  //             ...this.state.columns,
  //             [name]: name
  //           }
  //         })
  //       } else {
  //         value = e.dataTable[i]['same_time_unit_price']
  //         number = ('00' + (i + 1)).slice(-2)
  //         name = 'same_time_unit_price_' + number
  //         this.setState({
  //           changeData: {
  //             ...this.state.changeData,
  //             [name]: value
  //           },
  //           columns: {
  //             ...this.state.columns,
  //             [name]: name
  //           }
  //         })
  //       }
  //     }
  //   }
  // }

  onChange(e, record, name) {
    let number = ('00' + record.id).slice(-2)
    let keyName = name + '_' + number
    this.setState({
      changeData: {
        ...this.state.changeData,
        [keyName]: e.target.value
      }
    })
  }

  onFinish(values) {
    let param = {
      id: this.props.allData.id,
      year: '',
      changeData: this.state.changeData,
    }
    ContractMasterDetailsAction.update(param)
      .then((res) => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  render() {
    return (
      <div className="contract-master-maintain-consignor">
        <Card className="mb-2" title="同時実施">
          <Form
            ref={this.formRef}
            // onValuesChange={(event) => this.handleFormChange(event)}
            onFinish={(value) => this.onFinish(value)}
            onFinishFailed={(errorInfo) => this.onFinishFailed(errorInfo)}
          >
            <Table
              bordered
              dataSource={this.state.tableData ? this.state.tableData : []}
              size='small'
              rowKey={(record) => record.id}
              pagination={false}
            >
              <Table.Column
                title='No'
                dataIndex='id'
                className='column-size-5'
              />
              <Table.Column
                title='同時実施健診名'
                dataIndex='same_time_name'
                className='input-size-30'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={["dataTable", index, "same_time_name"]}>
                      <Input onChange={(e) => this.onChange(e, record, 'same_time_name')} />
                    </Form.Item>
                  );
                }}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>差引金額</div>}
                dataIndex='same_time_unit_price'
                className='input-size-12'
                align='right'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={["dataTable", index, "same_time_unit_price"]}
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "半角数字を入れてください"
                        },
                      ]}
                    >
                      <Input style={{ textAlign: 'right' }} onChange={(e) => this.onChange(e, record, 'same_time_unit_price')} />
                    </Form.Item>
                  );
                }}
              />
            </Table>
            <Form.Item>
              <div className="box_button_bottom_right" style={{ display: 'flex', gap: '20px' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  保存</Button>
              </div>
            </Form.Item>
            <ModalDraggable
              width={this.state.childModal.width}
              visible={this.state.childModal.visible}
              component={this.state.childModal.component}
              bodyStyle={{ margin: 0, padding: 0 }}
              maskClosable={false}
              onCancel={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: false,
                  },
                });
              }}
            />
          </Form>
        </Card>
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1316900_ContractMasterMaintainImplementation);