import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, message, Table, Tag, Upload, Dropdown, Menu } from 'antd'
import { DeleteOutlined, DownloadOutlined, UploadOutlined, MoreOutlined } from '@ant-design/icons'
import axios from 'configs/axios'
import { download_file } from 'helpers/CommonHelpers'
import Cookies from 'js-cookie'

/**
 * File manager like Windows 's explorer
 */
class WSFileManager_FileManager extends React.Component {
  static propTypes = {
    // This custom
    Li_Directory: PropTypes.any,
    Params_2612: PropTypes.object,
  }

  constructor(props) {
    super(props)

    // document.title = 'ファイル管理'

    this.state = {
      isLoadingData: false,
      contentList: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.loadScreenData()
  }


  /**
   * データ送信内容
   * @returns
   */
  sendData = () => {
    return {
      Params_2612: this.props.Params_2612,
      Li_Directory: this.props.Li_Directory,
    }
  }

  /**
   * ファイル一覧を取得
   */
  loadScreenData = () => {
    this.setState({ isLoadingData: true })

    const data = this.sendData()
    axios.post('/api/custom/file-manager', data)
      .then(res => {
        console.log(res.data.data);
        this.setState({
          contentList: res.data.data,
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
      .finally(() => this.setState({ isLoadingData: false }))
  }

  /**
   * ファイル ダウンロード
   * @param {*} record
   */
  downloadFile = (record) => {
    const data = this.sendData()
    axios.post('/api/custom/file-manager/download', {
      ...data,
      name: record.name,
    }, { responseType: 'blob' })
      .then(res => {
        download_file(res)
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  /**
   * ファイル 削除
   * @param {*} record
   */
  deleteFile = (record) => {
    this.setState({ isLoadingData: true })

    const data = this.sendData()
    axios.post('/api/custom/file-manager/delete', {
      ...data,
      name: record.name,
    })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
      .finally(() => this.loadScreenData())
  }

  render() {
    return (
      <div className='issue-list-input-control'>
        <Card title='ファイル管理'>
          <div style={{ paddingBottom: '10px' }}>
            パス：
            <Tag color='#2db7f5' style={{ fontSize: '14px' }}>
              {this.props.Li_Directory || (this.props.Params_2612.Li_Division + '/' + this.props.Params_2612.Li_Identify)}
            </Tag>
          </div>
          <Table
            bordered
            // loading={this.state.isLoadingData}
            dataSource={this.state.contentList}
            rowKey={record => record.id}
            pagination={false}
            size='small'>
            <Table.Column
              title='ファイル名'
              dataIndex='name'
              sorter={(a, b) => a.name.localeCompare(b.name, 'ja')}
            />
            <Table.Column
              title='更新日'
              dataIndex='filemtime'
              sorter={(a, b) => a.filemtime.localeCompare(b.filemtime, 'ja')}
            />
            <Table.Column
              title='サイズ'
              dataIndex='fileSize' render={value => Number(value / 1024).toFixed(2) + 'KB'}
              sorter={(a, b) => a.fileSize - b.fileSize}
            />
            <Table.Column
              width={30}
              title=''
              render={(value, record) => (
                <Dropdown size='small'
                  overlay={() => (
                    <Menu >
                      <Menu.Item
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          this.downloadFile(record)
                        }}
                      >
                        ダウンロード
                      </Menu.Item>
                      <Menu.Item
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.deleteFile(record)
                        }}
                      >
                        削除
                      </Menu.Item>
                    </Menu>
                  )}>
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            />
          </Table>
          <div className='box_button_bottom_right'>
            {/* アップロード */}
            <Upload
              headers={{
                'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
              }}
              withCredentials={true}
              showUploadList={false}
              data={{
                ...this.sendData(),
                Params_2612: this.props.Li_Directory?.length ? false : true,
                Params_2612_Li_Division: this.sendData().Params_2612?.Li_Division,
                Params_2612_Li_Identify: this.sendData().Params_2612?.Li_Identify,
              }}
              action='/api/custom/file-manager/upload'
              onChange={(info) => {
                if (info.file.status === 'uploading') {
                  this.setState({ isLoadingData: true })
                } else {
                  if (info.file.status === 'done') {
                    this.loadScreenData()
                  } else if (info.file.status === 'error') {
                    message.error('エラーが発生しました')
                    this.setState({ isLoadingData: false })
                  }
                }
              }}
            >
              <Button
                type='primary'
                icon={<UploadOutlined />} >
                アップロード
              </Button>
            </Upload>
          </div>
        </Card>
      </div>
    )
  }
}

export default WSFileManager_FileManager
