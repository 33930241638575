import InspectValueCalculateAutoMaintainService from 'services/InspectionMaintenance/InspectValueCalculateAutoMaintain/InspectValueCalculateAutoMaintainService'
import { message } from 'antd'

const InspectValueCalculateAutoMaintainAction = {
  index(params) {
    return InspectValueCalculateAutoMaintainService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  delete(params) {
    return InspectValueCalculateAutoMaintainService.delete(params)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

}
export default InspectValueCalculateAutoMaintainAction
