import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Card, Form, Input, Button, Select, Table, Dropdown, Modal, message, Tooltip, Menu } from 'antd'
import { MoreOutlined, SearchOutlined, MenuOutlined, PrinterOutlined, InfoOutlined, PlusOutlined } from '@ant-design/icons'

import WS0810025_PrintSub from 'pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0810025_PrintSub.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0810036_FormInquiry from 'pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0810036_FormInquiry.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import ResultTblBatchCreateAction from 'redux/ResultOutput/ResultsTblCollectOutput/ResultTblBatchCreate.action'
import moment from 'moment-timezone'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub'
import WS0286001_PrintStyleInquiry from '../OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS1527001_SetupResultTblCollectOutput from './WS1527001_SetupResultTblCollectOutput'
import PrintStyleInquiryAction from 'redux/ResultOutput/BindingModeSetting/PrintStyleInquiry.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx'
import WS0341008_BranchShopInquiry from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341008_BranchShopInquiry.jsx'

const labelCol = {
  labelCol: { style: { width: 50 } }
}

const labelCol2 = {
  labelCol: { style: { width: 60 } }
}

class WS0810001_ResultTblBatchCreate extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '結果表一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      isLoadingTable: false,

      ComboBox_GfacilityType: [],
      ComboBox_OutputOrder: [],
      importance: 0,

      dataPrintStyle: [],
      previewIndex: 0,
      params: {},
      isSelectAll: false,

      office_kanji_name: '',
      KeyInfo: '',
      conditionAddData: null,

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount() {
    this.loadDataPrintStyle()
    this.GetDataScreen()

  }

  GetDataScreen() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      IssueStartDateChar: this.formRef.current?.getFieldValue('IssueStartDateChar')?.format('YYYY/MM/DD'),
      IssueEndDateChar: this.formRef.current?.getFieldValue('IssueEndDateChar')?.format('YYYY/MM/DD')
    }
    ResultTblBatchCreateAction.GetDataScreen(params)
      .then(res => {
        if (res) {
          this.setState({
            ComboBox_GfacilityType: res?.ComboBox_GfacilityType,
            ComboBox_OutputOrder: res?.ComboBox_OutputOrder,
          })

          let data = {
            ...res,
            GbranchOfficeShopCdf: res.GbranchOfficeShopCdf,
            GbranchOfficeShopCdfCopy: res.GbranchOfficeShopCdf === 0 ? null : res.GbranchOfficeShopCdf,
            ComboBox_GfacilityType: null,
            ComboBox_OutputOrder: null,
            IssueStartDateChar: moment(res?.IssueStartDateChar),
            IssueEndDateChar: moment(res?.IssueEndDateChar),
            OutputOrder: res?.ComboBox_OutputOrder.length > 0 ? res?.ComboBox_OutputOrder[0].LinkedField : null
          }
          this.formRef.current?.setFieldsValue(data)
        }
      })
  }

  GetDataBySearch() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      IssueStartDateChar: this.formRef.current?.getFieldValue('IssueStartDateChar')?.format('YYYY/MM/DD'),
      IssueEndDateChar: this.formRef.current?.getFieldValue('IssueEndDateChar')?.format('YYYY/MM/DD'),
      GissueStartDate: this.formRef.current?.getFieldValue('IssueStartDateChar')?.format('YYYY/MM/DD'),
      GissueEndDate: this.formRef.current?.getFieldValue('IssueEndDateChar')?.format('YYYY/MM/DD')
    }

    this.setState({ isLoadingTable: true })
    ResultTblBatchCreateAction.extract_F11(params)
      .then((res) => {
        this.loadDataTable()
      })
      .catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  loadDataTable() {
    let params = {
      GissueStartDate: this.formRef.current?.getFieldValue('IssueStartDateChar')?.format('YYYY/MM/DD'),
      GissueEndDate: this.formRef.current?.getFieldValue('IssueEndDateChar')?.format('YYYY/MM/DD'),
      GofficeCd: this.formRef.current?.getFieldValue('GofficeCd'),
      GbranchOfficeShopCdf: this.formRef.current?.getFieldValue('GbranchOfficeShopCdf'),
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      GformType: this.formRef.current?.getFieldValue('GformType'),
      OutputOrder: this.formRef.current?.getFieldValue('OutputOrder'),
      conditionAddData: this.state.conditionAddData,
      KeyInfo: this.state.KeyInfo
    }

    console.log(params);
    ResultTblBatchCreateAction.choice(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res,
            isLoadingTable: false,
            selectedRows: res,
            selectedRowKeys: res.map(x => x.id)
          })
        }
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  selectRecord(id, value) {
    let params = {
      id: id,
      Issuing: value ? 1 : 0
    }
    ResultTblBatchCreateAction.select_one(params)
      .then((res) => {
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  selectAll(value) {
    let params = {
      StsSelectAll: value ? 1 : 0
    }
    ResultTblBatchCreateAction.select_all(params)
      .then((res) => {
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  prePreview(index, Preview, retryCount) {
    let params = {
      ...this.state.params,
      selectedList: this.state.selectedRows[index]
    }

    if (Preview) {
      ResultTblBatchCreateAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          let newIndex = index + 1

          if (this.state.selectedRows.length > newIndex) {
            this.prePreview(newIndex, Preview, 0)
          }
        }).catch(err => {
          const res = err.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }

          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }

          if (err.message.includes('500')) {
            if (retryCount <= 3) {
              let newRetryCount = retryCount + 1;
              this.prePreview(index, Preview, newRetryCount)
            } else {
              return Modal.error({
                title: '処理中にエラーが発生しました。',
                content: '',
              });
            }
          }
        })
    } else {
      ResultTblBatchCreateAction.print(params)
        .then(res => {
          let newIndex = index + 1
          if (this.state.selectedRows.length > newIndex) {
            this.prePreview(newIndex, Preview, 0)
          } else {
            if (res.data === 'true') {
              message.success('印刷が完了しました')
            } else {
              message.error('処理の途中でエラーが発生しました')
            }
          }
        }).catch(err => {
          message.error(err?.response?.data?.message || 'エラーが発生しました')
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }

          if (err.message.includes('500')) {
            if (retryCount <= 3) {
              let newRetryCount = retryCount + 1;
              this.prePreview(index, Preview, newRetryCount)
            } else {
              return Modal.error({
                title: '処理中にエラーが発生しました。',
                content: '',
              });
            }
          }
        })
    }
  }

  async printF12(data) {
    let params = {
      OutputData: data.Lo_StsRun ? 1 : 0,
      StsScreenControl: data.StsScreenControl ? 1 : 0,
      PrinterNum: data.PrinterNo,
      NumCopies: data.NumOfCopies,
      Submission: data.StsSubmission ? 1 : 0,
      GpreviewBool: data.Preview ? 1 : 0,
      OrgsTowards: data.Groups,
      InputFlag: data.Division,
      L2Output: data.StsL2 ? 1 : 0,
      L3Output: data.StsL3 ? 1 : 0,
    }
    await this.setState({
      params: params,
    })
    await this.prePreview(0, data.Preview, 0)
  }

  onFinish(values) {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Style: values.GformType,
        PrinterNo: values.PrinterNum,
        Preview: values.GpreviewBool,
        NumOfCopies: values.NumOfCopies,
        Groups: values.OrgsToward,
        Division: values.InputFlag,
        StsL2: values.L2Output ? 1 : 0,
        StsL3: values.L3Output ? 1 : 0,
        StsScreenControl: values.StsScreenControl ? 1 : 0,
        StsSubmission: values.Submission ? 1 : 0,
      })
    }
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource]
    let selected = [...this.state.selectedRows]
    let tempArray = []
    data[index][field] = value
    data[index] = { ...data[index] } //様式の変更を反映させるために必要
    selected.forEach((item) => {
      if (data[index].id === item.id) {
        item = data[index]
      }
      tempArray.push(item)
    })
    this.setState({
      dataSource: data,
      selectedRows: tempArray
    })
  }

  loadDataPrintStyle() {
    PrintStyleInquiryAction.GetListData()
      .then(res => {
        this.setState({ dataPrintStyle: res })
      })
  }

  getNamePrintStyle(id, W1_style_cd_1) {
    let data = this.state.dataPrintStyle.filter(x => x.style_code === W1_style_cd_1)
    let index = this.findIndexByID(this.state.dataSource, id)
    this.updateDatasource(index, 'W1_style_cd_1', data.length > 0 ? data[0].style_code : W1_style_cd_1)
    this.updateDatasource(index, 'format_name', data.length > 0 ? data[0].format_name : '')
  }

  getNameOffice(value) {
    if (value) {
      let params = {
        GofficeCd: this.formRef.current?.getFieldValue('GofficeCd'),
        GbranchOfficeShopCdf: this.formRef.current?.getFieldValue('GbranchOfficeShopCdf')
      }
      ResultTblBatchCreateAction.getNameOffice(params)
        .then((res) => {
          this.formRef.current?.setFieldsValue({
            office_kanji_name: res?.data?.office_kanji_name
          })
          this.setState({
            office_kanji_name: res?.data?.office_kanji_name
          })
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  getNameType(value) {
    if (value) {
      let params = {
        GformType: this.formRef.current?.getFieldValue('GformType'),
      }
      ResultTblBatchCreateAction.getNameType(params)
        .then((res) => {
          this.formRef.current?.setFieldsValue({
            Expresstion_70: res?.data?.Expresstion_70
          })
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  getNameKanshocd(value) {
    if (value) {
      let params = {
        KanshoCd: this.formRef.current?.getFieldValue('KanshoCd'),
      }
      ResultTblBatchCreateAction.getNameKanshocd(params)
        .then((res) => {
          this.formRef.current?.setFieldsValue({
            insurer_kanji_name: res?.data?.insurer_kanji_name
          })
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  showModalOfficeInfoInquirySub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: (
          <WS2585001_OfficeInfoInquirySub
            Li_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
            Li_BranchCode={this.formRef.current?.getFieldValue('GbranchOfficeShopCdf')}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  eventF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component:
          <WS1527001_SetupResultTblCollectOutput
            onFinishScreen={(ouput) => {
              this.closeModal()
            }}
          />
      },
    })
  }

  eventF11 = () => {
    this.GetDataBySearch()
  }

  eventF12 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS0810025_PrintSub
            Style={this.formRef.current?.getFieldValue('GformType')}
            PrinterNo={this.formRef.current?.getFieldValue('PrinterNum')}
            Preview={this.formRef.current?.getFieldValue('GpreviewBool')}
            NumOfCopies={this.formRef.current?.getFieldValue('NumOfCopies')}
            Groups={0}
            Division={this.formRef.current?.getFieldValue('InputFlag')}
            StsL2={this.formRef.current?.getFieldValue('L2Output')}
            StsL3={this.formRef.current?.getFieldValue('L3Output')}
            StsSubmission={false}
            StsScreenControl={false}
            onFinishScreen={(output) => {
              if (output.Lo_StsRun) {
                this.printF12(output)
              }
              this.closeModal()
            }}
          />
      },
    })
  }

  showModalContractInfoInquiry(save) {
    this.setState({
      nameScreen: 'WS0289012_ContractInfoInquiry',
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0289012_ContractInfoInquiry
            Li_EffectiveLimitedDisplay={1}
            Lio_ConsultCourse={this.formRef.current?.getFieldValue('ConsultCourse')}
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            Li_Date={this.formRef.current?.getFieldValue('IssueStartDateChar') ? moment(this.formRef.current?.getFieldValue('IssueStartDateChar')).format('YYYY/MM/DD') : ''}

            onFinishScreen={(output) => {
              if (output.Lio_ConsultCourse) {
                this.formRef.current?.setFieldsValue({
                  ConsultCourse: output.Lio_ConsultCourse,
                  contract_short_name: output.recordData.contract_short_name,
                  Lo_ContractNum: output.Lo_ContractNum,
                  Lo_ContractOrgCode: output.Lo_ContractOrgCode,
                  Lo_ContractStartDate: output.Lo_ContractStartDate,
                  Lo_ContractType: output.Lo_ContractType
                })
                this.setState({
                  ConsultCourse: output.Lio_ConsultCourse,
                  contract_short_name: output.recordData.contract_short_name,
                  Lo_ContractNum: output.Lo_ContractNum,
                  Lo_ContractOrgCode: output.Lo_ContractOrgCode,
                  Lo_ContractStartDate: output.Lo_ContractStartDate,
                  Lo_ContractType: output.Lo_ContractType,
                  isUnValidConsultCourse: false
                })
              } else {
                this.setState({
                  isUnValidConsultCourse: true
                })
                message.error('受診ｺｰｽを入力してください')
              }

              this.setState({
                nameScreen: '',
              })
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='result-tbl-batch-create'>
        <Card title='結果表一括出力'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={this.eventF10}
                    >
                      結果表一括設定
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      onClick={this.eventF11}
                    >
                      抽出
                    </Menu.Item>
                    <Menu.Item
                      key='3'
                      style={{ width: '100%' }}
                      onClick={this.eventF12()}
                    >
                      印刷
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <div className='box_inner_horizontal'>
                <div className='box_search' style={{ width: 'fit-content' }}>
                  <div className='box_search_inner_border' style={{ width: 'fit-content' }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='受診日'
                        name='IssueStartDateChar'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                          style={{ width: 122 }}
                        />
                      </Form.Item>
                      <Form.Item> ~ </Form.Item>
                      <Form.Item
                        name='IssueEndDateChar'
                        style={{ marginRight: '10px' }}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                          style={{ width: 122 }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal' >
                      <Form.Item
                        label='事業所'
                        name='GofficeCd'
                        {...labelCol}
                      >
                        <Input.Search
                          allowClear={true}
                          style={{ cursor: 'pointer', width: 122 }}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '90%',
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                    Lio_BranchStoreCode={this.formRef.current?.getFieldValue('GbranchOfficeShopCdf')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        GofficeCd: output.Lio_OfficeCode,
                                        GstoreCd: output.Lio_BranchStoreCode,
                                        GbranchOfficeShopCdf: output.Lio_BranchStoreCode,
                                        GbranchOfficeShopCdfCopy: output.Lio_BranchStoreCode === 0 ? null : output.Lio_BranchStoreCode,
                                        office_kanji_name: output.Lo_Kanji_Name
                                      })
                                      this.setState({
                                        importance: output.recordData.importance,
                                        office_kanji_name: output.Lo_Kanji_Name
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}

                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '90%',
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('GbranchOfficeShopCdf')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          GofficeCd: output.Lio_OfficeCode,
                                          GstoreCd: output.Lio_BranchStoreCode,
                                          GbranchOfficeShopCdf: output.Lio_BranchStoreCode,
                                          GbranchOfficeShopCdfCopy: output.Lio_BranchStoreCode === 0 ? null : output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.setState({
                                          importance: output.recordData.importance,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='GbranchOfficeShopCdf'
                      >
                        <Input.Search
                          type='number'
                          style={{ width: 100 }}
                          min={0}
                          onPressEnter={event => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 700,
                                component: (
                                  <WS0341008_BranchShopInquiry
                                    Li_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                    onFinishScreen={({ Li_OfficeCode, Lo_BranchStoreCode, recordData }) => {
                                      this.formRef.current.setFieldsValue({
                                        GbranchOfficeShopCdf: Lo_BranchStoreCode,
                                        office_kanji_name: recordData.office_kanji_name
                                      })
                                      this.setState({
                                        office_kanji_name: recordData.office_kanji_name
                                      })

                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }}

                          onSearch={(value, event) => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 700,
                                component: (
                                  <WS0341008_BranchShopInquiry
                                    Li_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                    onFinishScreen={({ Li_OfficeCode, Lo_BranchStoreCode, recordData }) => {
                                      this.formRef.current.setFieldsValue({
                                        GbranchOfficeShopCdf: Lo_BranchStoreCode,
                                        office_kanji_name: recordData.office_kanji_name
                                      })
                                      this.setState({
                                        office_kanji_name: recordData.office_kanji_name
                                      })

                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='office_kanji_name'
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='コース'
                        name='ConsultCourse'
                        {...labelCol}
                      >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-4'
                          onPressEnter={() => {
                            this.showModalContractInfoInquiry()
                            this.setState({
                              changeConsultCourse: true
                            })
                          }}
                          style={{ width: 122 }}
                          onSearch={(value, event) => {
                            if (event.target.localName === "input") {
                              this.formRef.current?.setFieldsValue({
                                ConsultCourse: '',
                                contract_short_name: '',
                                Lo_ContractNum: '',
                                Lo_ContractOrgCode: '',
                                Lo_ContractStartDate: '',
                                Lo_ContractType: ''
                              })
                              this.setState({
                                ConsultCourse: '',
                                contract_short_name: '',
                                Lo_ContractNum: '',
                                Lo_ContractOrgCode: '',
                                Lo_ContractStartDate: '',
                                Lo_ContractType: ''
                              })
                            } else {
                              this.showModalContractInfoInquiry()
                              this.setState({
                                changeConsultCourse: true
                              })
                            }
                          }}

                          onFocus={() => {
                            this.setState({
                              changeConsultCourse: false
                            })
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='contract_short_name'
                      >
                        <div>{this.formRef.current?.getFieldValue('contract_short_name')}</div>
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <Form.Item
                      label='出力様式'
                      name='GformType'
                      {...labelCol2}
                    >
                      <div className='box_inner_horizontal'>
                        <Button
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '35%',
                                component: (
                                  <WS0810036_FormInquiry
                                    Lio_Style={this.formRef.current?.getFieldValue('GformType')}
                                    onFinishScreen={({ Lio_Style, Lo_Name }) => {
                                      this.formRef.current.setFieldsValue({
                                        GformType: Lio_Style,
                                        Expresion_70: Lo_Name
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}

                          onChange={(e) => {
                            this.formRef.current.setFieldsValue({
                              Expresion_70: ''
                            })
                          }}

                          onBlur={(e) => {
                            this.getNameType(e.target.value)
                          }}
                        >
                          選択
                        </Button>
                        <div
                          style={{
                            display: 'flex',
                            height: 'fit-content',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: 'fit-content'
                          }}
                        >{this.formRef.current?.getFieldValue('Expresion_70')}</div>
                      </div>
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='出力順'
                        name='OutputOrder'
                        {...labelCol2}
                      >
                        <Select
                          style={{ width: 180 }}
                        >
                          {this.state.ComboBox_OutputOrder?.map((item, index) => (
                            <Select.Option
                              key={`select${index}`}
                              value={item.LinkedField}
                            >
                              {item.DisplayField}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.loadDataTable()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button
                        icon={<PlusOutlined />}
                        size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component:
                                <WS2786001_ConditionAddSub
                                  Li_ConditionAddData={this.state.conditionAddData}
                                  Li_DateF={this.formRef.current.getFieldValue('IssueStartDateChar')}
                                  Li_DateT={this.formRef.current.getFieldValue('IssueEndDateChar')}
                                  Li_Office={this.formRef.current.getFieldValue('GofficeCd')}
                                  Li_BranchShop={this.formRef.current.getFieldValue('GbranchOfficeShopCdf')}
                                  Li_CourseF={this.formRef.current.getFieldValue('ConsultCourse')}
                                  keyInfoFlag={false}
                                  onFinishScreen={(output) => {
                                    // 追加条件のデータをstate変数に保持
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                      KeyInfo: output.Lio_KeyInfo ? output.Lio_KeyInfo : ''
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              size='small'
              className='scrollbar'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                  let arrTemp = [...this.state.selectedRowKeys]
                  let arrTempRecord = [...this.state.selectedRows]
                  let idx = arrTemp.indexOf(record.id)
                  if (idx === -1) {
                    arrTemp.push(record.id)
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                  } else {
                    arrTemp.splice(idx, 1)
                    arrTempRecord.splice(idx, 1)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                  }
                },

                onSelectAll: (selected, selectedRows, changeRows) => {
                  if (selected) {
                    let arrTemp = this.state.dataSource.map(item => item.id)
                    let arrTempRecord = this.state.dataSource
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      isSelectAll: true
                    })
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: [],
                      isSelectAll: false
                    })
                  }
                },
              }}
            >
              <Table.Column
                title='受診日'
                dataIndex='W1_reserve_date'
                className='column-size-date'
                render={(value) => {
                  return (
                    <div>{moment(value).format('YYYY/MM/DD (ddd)') ?? ''}</div>
                  )
                }}
              />

              <Table.Column
                title='受付No'
                dataIndex='receipt_number'
                className='column-size-5'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      <span>{record.receipt_number}</span>
                    </div>
                  )
                }}
              />

              <Table.Column
                title='判定'
                dataIndex='comprehensive_judgment'
                className='column-size-5'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='メモ'
                dataIndex='Expression_17'
                className='column-size-5'
                render={(value, record, index) => {
                  let icon = ''
                  switch (record.Expresstion_17) {
                    default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                  }
                  return (
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (
                              <WS2584019_PersonalInfoInquirySub
                                Li_PersonalNum={record.W1_id_cd}
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}>
                      {icon}
                    </div>
                  )
                }}
              />

              <Table.Column
                title='氏名'
                dataIndex='kanji_name'
                className='column-size-40'
                render={(value, record) => {
                  return (
                    <div style={{ textAlign: 'left' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='性別'
                dataIndex='Expresstion_4'
                className='column-size-5'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='受診コース'
                className='column-size-40'
                render={(value, record, index) => {
                  return (
                    <div>
                      <span style={{ marginRight: 5 }}>
                        {record.W1_visits_courses ? (record.W1_visits_courses?.toString().substr(0, 1) + '-' + record.W1_visits_courses?.toString().substr(1, 2)) : ''}
                      </span>
                      <span>{record.contract_short_name}</span>
                    </div>
                  )
                }}
              />

              <Table.Column
                title='様式1'
                dataIndex='W1_style_cd_1'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='様式名称'
                dataIndex='format_name'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'left' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='様式2'
                dataIndex='W1_style_cd_2'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='様式3'
                dataIndex='W1_style_cd_3'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='様式4'
                dataIndex='W1_style_cd_4'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                fixed='right'
                render={(value, record, index) => (
                  <div style={{ textAlign: 'center' }}>
                    <Dropdown size='small'
                      overlay={() => (
                        <Menu >
                          <Menu.Item
                            key='1'
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '600px',
                                  component: (
                                    <WS0286001_PrintStyleInquiry
                                      Lio_StyleCode={record.W1_style_cd_1}
                                      onFinishScreen={(output) => {
                                        this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'W1_style_cd_1', output.Lio_StyleCode)
                                        this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'format_name', output.Lo_FormatName)
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}
                          >
                            様式1設定
                          </Menu.Item>

                          <Menu.Item
                            key='2'
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '600px',
                                  component: (
                                    <WS0286001_PrintStyleInquiry
                                      Lio_StyleCode={record.W1_style_cd_2}
                                      onFinishScreen={(output) => {
                                        this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'W1_style_cd_2', output.Lio_StyleCode)
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}
                          >
                            様式2設定
                          </Menu.Item>

                          <Menu.Item
                            key='3'
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '600px',
                                  component: (
                                    <WS0286001_PrintStyleInquiry
                                      Lio_StyleCode={record.W1_style_cd_3}
                                      onFinishScreen={(output) => {
                                        this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'W1_style_cd_3', output.Lio_StyleCode)
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}
                          >
                            様式3設定
                          </Menu.Item>

                          <Menu.Item
                            key='4'
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '600px',
                                  component: (
                                    <WS0286001_PrintStyleInquiry
                                      Lio_StyleCode={record.W1_style_cd_4}
                                      onFinishScreen={(output) => {
                                        this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'W1_style_cd_4', output.Lio_StyleCode)
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}
                          >
                            様式4設定
                          </Menu.Item>

                          <Menu.Item
                            key='5'
                            onClick={() => {
                              let data = [...this.state.dataSource]
                              let index = this.findIndexByID(this.state.dataSource, record.id)
                              const field = ['W1_style_cd_1', 'format_name', 'W1_style_cd_2', 'W1_style_cd_3', 'W1_style_cd_4']

                              field.map((fieldName) => {
                                data[index][fieldName] = null
                              })

                              data[index] = { ...data[index] } //様式の変更を反映させるために必要

                              this.setState({
                                dataSource: data
                              })
                            }}
                          >
                            様式クリア
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </div>
                )}
              />
            </Table>
          </div>

          <div className='box_button_bottom_right' >
            <Button
              icon={<PrinterOutlined />}
              type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 600,
                    component: (
                      <WS0810025_PrintSub
                        Style={this.formRef.current?.getFieldValue('GformType')}
                        PrinterNo={this.formRef.current?.getFieldValue('PrinterNum')}
                        Preview={this.formRef.current?.getFieldValue('GpreviewBool')}
                        NumOfCopies={this.formRef.current?.getFieldValue('NumOfCopies')}
                        Groups={0}
                        Division={this.formRef.current?.getFieldValue('InputFlag')}
                        StsL2={this.formRef.current?.getFieldValue('L2Output')}
                        StsL3={this.formRef.current?.getFieldValue('L3Output')}
                        StsSubmission={false}
                        StsScreenControl={false}
                        onFinishScreen={(output) => {
                          if (output.Lo_StsRun) {
                            this.printF12(output)
                          }
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              }} >
              <span className='btn_label'>
                印刷
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0810001_ResultTblBatchCreate)
