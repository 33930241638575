import { message } from 'antd'
import AssociateInsureDataCreateService from 'services/AssociationHealthInsuranceReport/AssociateInsureDataCreate/AssociateInsureDataCreateService'

const AssociateInsureDataCreateAction = {
  index(data) {
    return AssociateInsureDataCreateService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  createKenpoData(data) {
    return AssociateInsureDataCreateService.createKenpoData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  downloadZipFile(data) {
    return AssociateInsureDataCreateService.downloadZipFile(data)
  },

}

export default AssociateInsureDataCreateAction
