import React from 'react'
import { Card, Form, Button, Input, Select, Radio, Modal, Tooltip, message } from 'antd'
import { MoreOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'

import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0341001_OfficeInfoMaintain from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341001_OfficeInfoMaintain'
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry'
import WS2787004_AffiliationSelectSub from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS2787004_AffiliationSelectSub'
import PersonalInfoMaintainAction from 'redux/basicInfo/PersonalInfoMaintain/PersonalInfoMaintain.action'

import ModalDraggable from 'components/Commons/ModalDraggable'

/**
 * 個人情報保守 - 所属情報入力　新規追加、編集、削除
 */
class WS0343106_BelongsInput extends React.Component {
  formRef = React.createRef()

  static propTypes = {
  }

  constructor(props) {
    super(props)

    // document.title = '個人情報保守 - 所属情報入力　新規追加、編集、削除'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      dataSource: [],
      selectedId: null,
      itemChange: null,

      officeName: '', //事業所名称
      insurerName: '',//保険者名称
      affiliationName: '', //所属名称
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    if (!this.props.newFlag) {
      // 編集の場合

      const data = { ...this.props.record }
      let values = {
        id: data.id,
        personal_number_id: data.personal_number_id,
        enabled_disabled: data.enabled_disabled,
        office_code: data.office_code,
        branch_store_code: data.branch_store_code,
        insurer_number: data.insurer_number,
        insurer_card_symbol: data.insurer_card_symbol,
        insurer_card_number: data.insurer_card_number,
        relationship: data.relationship,
        workplace_code: data.workplace_code,
        office_kanji_name: data.office_kanji_name,
        insurer_kanji_name: data.insurer_kanji_name,
        short_name: data.short_name,
      }

      // Form設定
      this.formRef.current?.setFieldsValue(values)
      // 名称を設定
      this.setState({
        officeName: data.office_kanji_name, //事業所名称
        insurerName: data.insurer_kanji_name,//保険者名称
        affiliationName: data.short_name, //所属名称
      })
    }
  }

  /**
   * 変更処理
   * @param {*} name
   * @param {*} value
   */
  onChangeData = (name, value) => {
    this.formRef.current?.setFieldsValue({ [name]: value })
  }

  /**
   * モーダルを開く 事業所選択
   */
  openModalOfficeInfo = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            onFinishScreen={({
              Lio_OfficeCode,
              Lio_BranchStoreCode,
              Lo_InsurerNum,
              recordData
            }) => {
              //事業所コード
              this.onChangeData('office_code', Lio_OfficeCode)
              //支店コード
              this.onChangeData('branch_store_code', Lio_BranchStoreCode)
              //事業所名称
              this.onChangeData('office_kanji_name', recordData.office_kanji_name)
              //保険者
              this.onChangeData('insurer_number', recordData.insurer_code)

              this.setState({
                officeName: recordData.office_kanji_name,
                insurerName: recordData.insurer_kanji_name
              })

              // 閉じる
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * 削除ボタン押下処理
   */
  delete = () => {
    const formData = this.formRef.current.getFieldsValue()
    const params = {
      personalNumberId: this.props.record.personal_number_id,
      officeCode: formData.office_code,
      branchStoreCode: formData.branch_store_code,
    }

    // 所属情報を変更
    PersonalInfoMaintainAction.deleteBelongs(params)
      .then(res => {
        if (res) {
          let personal_belongs = []
          const arrTemp = [...res.personal_belongs]
          if (arrTemp.length > 0) {
            // 名称系を設定する
            personal_belongs = arrTemp.map(item => (
              {
                ...item,
                workplace_code: item.personal_workplaces?.workplace_code || '',
                office_kanji_name: item.office?.office_kanji_name || '',
                insurer_kanji_name: item.insurer?.insurer_kanji_name || '',
                short_name: item.personal_workplaces?.office_workplace?.short_name || '',
              }
            ))
          }

          // 親画面に変更内容を渡す
          this.props.onSave(personal_belongs)
        }
      })
  }

  /**
   * 登録ボタン押下処理
   */
  save = () => {
    const formData = this.formRef.current.getFieldsValue()

    // 変更の場合
    if (!this.props.newFlag) {
      // 入力画面が無効の場合
      if (!formData.enabled_disabled) {
        // 所属の有効チェック
        let enabledFlag = false
        const data = this.props.personalBelongs ?? []
        const record = { ...this.props.record }

        data.forEach(item => {
          if (item.enabled_disabled && (record.id !== item.id)) {
            enabledFlag = true
          }
        })
        if (!enabledFlag) {
          return message.error('有効な所属情報を１つ以上設定してください')
        }
      }
    }

    Modal.confirm({
      content: this.props.newFlag ? '新規登録します。よろしいですか？' : '上書き登録します。よろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () =>
        // 更新
        this.updateBelongs(formData)
    })
  }

  /**
   * 更新
   * @param {*} formData
   */
  updateBelongs = (formData) => {
    // 追加・変更された所属データをパラメータ用に加工
    let belongs = []
    const obj = {
      ...formData,
      changeFlag: 1 //変更フラグ追加
    }
    belongs.push(obj)

    const params = {
      personal_number_id: this.props.personalNumId,
      personal_belongs: belongs
    }

    // 所属情報を変更
    PersonalInfoMaintainAction.updateBelongs(params)
      .then(res => {
        if (res) {
          let personal_belongs = []
          const arrTemp = [...res.personal_belongs]
          if (arrTemp.length > 0) {
            // 名称系を設定する
            personal_belongs = arrTemp.map(item => (
              {
                ...item,
                workplace_code: item.personal_workplaces?.workplace_code || '',
                office_kanji_name: item.office?.office_kanji_name || '',
                insurer_kanji_name: item.insurer?.insurer_kanji_name || '',
                short_name: item.personal_workplaces?.office_workplace?.short_name || '',
              }
            ))
          }

          // 親画面に変更内容を渡す
          this.props.onSave(personal_belongs)
        }
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='belongs-input'>
        <Card title={'所属情報入力 [' + (this.props.newFlag ? '新規' : '編集') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>

              <Form.Item name='enabled_disabled' label='有　効'>
                <Radio.Group>
                  <Radio value={1}>有効</Radio>
                  <Radio value={0}>無効</Radio>
                </Radio.Group>
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item name='office_code' label='事業所' >
                  <Input.Search
                    className='input-search-size-number-10'
                    maxLength={8}
                    onSearch={() =>
                      // モーダルを開く 事業所選択
                      this.openModalOfficeInfo()
                    }
                  />
                </Form.Item>
                <Form.Item name='branch_store_code'>
                  <Input.Search
                    className='input-search-size-number-4'
                    maxLength={5}
                    min={0}
                    onSearch={() =>
                      // モーダルを開く 事業所選択
                      this.openModalOfficeInfo()
                    }
                  />

                </Form.Item>

                <Form.Item>
                  <Button
                    size='small'
                    style={{ marginTop: '4px' }}
                    icon={<MoreOutlined />}
                    // disabled={!(this.state.objBelongs.office_code ?? false)}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '80%',
                          component: (
                            <WS0341001_OfficeInfoMaintain
                              Li_ExecModeDef_Sdi={''}
                              Lio_OfficeCode={this.formRef.current?.getFieldValue('office_code')}
                              Lio_BranchStoreCode={this.formRef.current?.getFieldValue('branch_store_code')}
                            />
                          )
                        }
                      })
                    }
                  />
                </Form.Item>
                {/* 事業所名称 */}
                <div style={{ marginTop: '5px' }}>
                  {this.state.officeName}
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item name='insurer_number' label='保険者' >
                  <Input.Search
                    className='input-search-size-number-10'
                    min={0}
                    maxLength={8}
                    onSearch={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '1200px',
                          component: (
                            <WS1290001_InsurerNumberInquiry
                              onFinishScreen={({ Lo_InsurerNum, Lo_InsurerKanjiName, recordData }) => {
                                //保険者番号
                                this.onChangeData('insurer_number', parseInt(Lo_InsurerNum))
                                //保険者名称
                                this.onChangeData('insurer_kanji_name', Lo_InsurerKanjiName)
                                this.setState({ insurerName: Lo_InsurerKanjiName })

                                // 閉じる
                                this.closeModal()
                              }}
                            />
                          )
                        }
                      })
                    }
                  />
                </Form.Item>
                {/* 保険者名称 */}
                <div style={{ marginTop: '5px' }}>
                  {this.state.insurerName}
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item name='insurer_card_symbol' label='保険証' >
                  <Input
                    className='input-size-10'
                  />
                </Form.Item>
                <Form.Item>
                  <span>/</span>
                </Form.Item>
                <Form.Item name='insurer_card_number'>
                  <Input
                    className='input-size-10'
                  />
                </Form.Item>
              </div>

              <Form.Item name='relationship' label='続　柄'  >
                <Select style={{ width: '100px' }}>
                  <Select.Option value='0'>本人</Select.Option>
                  <Select.Option value='1'>配偶者</Select.Option>
                  <Select.Option value='2'>家族</Select.Option>
                </Select>
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item name='workplace_code' label='所　属' >
                  <Input.Search
                    className='input-search-size-number-10'
                    maxLength={8}
                    min={0}
                    onSearch={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS2787004_AffiliationSelectSub
                              Li_OfficeCode={this.formRef.current?.getFieldValue('office_code')}
                              Li_BranchStoreCode={this.formRef.current?.getFieldValue('branch_store_code')}
                              onFinishScreen={async ({
                                Lio_AffiliationCode,
                                recordData
                              }) => {
                                // 所属コード
                                this.onChangeData('workplace_code', Lio_AffiliationCode)
                                // 所属名称
                                this.onChangeData('short_name', recordData.short_name)
                                this.setState({ affiliationName: recordData.short_name })
                                // モーダル閉じる
                                await this.closeModal()
                              }}
                            />
                          )
                        }
                      })
                    }
                  />
                </Form.Item>
                <div style={{ marginTop: '5px' }}>
                  {this.state.affiliationName}
                </div>
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            {/* 削除ボタン */}
            <Tooltip title={'受診履歴が存在する場合、削除できません'}>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: ((this.props.newFlag) ? 'none' : '') }} //新規追加の場合、削除ボタンは非表示
                onClick={() => {
                  // 削除確認モーダル
                  Modal.confirm({
                    content: '削除してもよろしいですか？',
                    okText: '削　除',
                    cancelText: 'キャンセル',
                    onOk: () =>
                      // 削除
                      this.delete()
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>
            </Tooltip>

            {/* 登録ボタン */}
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => this.save()}
            >
              <span className='btn_label'>
                登録
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
export default WS0343106_BelongsInput
