import { message } from "antd";
import InspectCmtMasterService from "services/SpecificInsureMaintenance/InspectCmtMaster/InspectItemCmtMasterService.js";

const InspectCmtMasterAction = {
  deleteInspectCmtMaster(data) {
    console.log("アクションdel")
    return InspectCmtMasterService.deleteInspectCmtMaster(data)
  },
  postInspectCmtMaster(data) {
    console.log("アクション")
    return InspectCmtMasterService.postInspectCmtMaster(data)
  },
}

export default InspectCmtMasterAction;