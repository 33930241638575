import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReceiptProcessSubAction from 'redux/CounterBusiness/Counter/ReceiptProcessSub.action'
import DataGrid, { Column, ColumnChooser, ColumnFixing, Selection } from 'devextreme-react/data-grid';
import { Card, Col, Form, Input, Row, Table, Button, Menu, Dropdown, message } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import {
  getIntroduceCounterAction,
  AcceptButtonAction,
  eventEnterC_CounterAction,
  getListDataCounterAction,
  getReAcquisitionAction,
  getUseBarcodeAction,
  checkAcceptProcess
} from 'redux/CounterBusiness/Counter/Counter.action'
import WS2621001_PaymentProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621001_PaymentProcessSub.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2622003_ReceiptProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622003_ReceiptProcessSub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub.jsx'
import WS2620036_Refine from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620036_Refine'
import WS2620500_CounterInquiry from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620500_CounterInquiry.jsx'
import WS2623001_DispensingProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2623001_DispensingProcessSub'
import WS2624003_VisitsCancelConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624003_VisitsCancelConfirm.jsx'
import Color from 'constants/Color'
import WS0956001_PaymentCorrectSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS0956001_PaymentCorrectSub.jsx'
import WS2624002_VisitsChangeConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624002_VisitsChangeConfirm'
import { ModalCustom } from 'components/Commons/ModalCustom'
import WS2537501_acceptablePersonalReserveProcessMock from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537501_acceptablePersonalReserveProcessMock.jsx'
// import WS2622004_ReceiptProcessSubBulk from 'pages/Mock/UK_CounterBusiness/V5UK0001000_Counter/WS2622004_ReceiptProcessSubBulk.jsx'
import WS2622004_ReceiptProcessSubBulk from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622004_ReceiptProcessSubBulk.jsx'
import WS2620100_ReservationSelect from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620100_ReservationSelect'
import changeHankakuText from 'components/Commons/changeHankakuText'

const styleContentTableColumn = {
  color: Color(209).Foreground,
  fontWeight: 'lighter',
  fontSize: '18px',
  marginBottom: '5px',
  height: '22px',
  textAlign: 'center'
}

const styleDivTitle = {
  background: '#1C66B9',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em',
  color: '#FFFFFF'
}

const styleDivResult = {
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em'
}

const styleDivExpression = {
  width: '50px',
  height: '24px',
  border: '1px solid #ABADB3',
  textAlign: 'center',
  lineHeight: '22px',
}

const styleDiv = {
  border: 'solid 1px #ABADB3',
  width: '50px',
  height: '24px',
  textAlign: 'center',
  lineHeight: '22px',
}
const dateFormat = 'YYYY/MM/DD'
class WS2620001_Counter extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '窓口'
    const queryParameter = {
      DateChar: moment().format(dateFormat),
      ReserveNumAndPersonalNum: '',
      FacilityType: 0,
    }
    const receiptNumber = ''
    // １文字以上の数字のみ
    if (receiptNumber.match(/^[0-9]+$/)) {
      queryParameter.receiptNumber = receiptNumber
    }

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      widthWS2622003: '',
      dataSource: [],
      record: {},
      selectedId: '',
      FacilityTypeList: [],
      iniObj: {},
      importance: 0,
      initParams: queryParameter,
      Li_Change: '',
      menuItems: [
        { id: 1, label: '再表示', handleClick: this.eventF10 },
        { id: 1, label: '新規受付', handleClick: this.eventF11 },
      ]
    }
    this.HandelF7 = this.HandelF7.bind(this)
  }

  componentDidMount = () => {
    this.loadInitData(this.state.initParams)
  }

  loadInitData = (params) => {
    getIntroduceCounterAction(params)
      .then(res => {
        this.setState({
          iniObj: res.data,
          FacilityTypeList: res.data.FacilityType,
        })
        this.loadData()
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  loadData = () => {
    let params = {
      ...this.state.initParams
    }
    getListDataCounterAction(params)
      .then(res => {
        if (res.data) {
          this.setState({
            dataSource: res.data.VisitList,
            record: this.state.record?.id
              ? this.state.record
              : res.data.VisitList.length > 0 ? res.data.VisitList[0] : {},
          })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  checkWidthInWS2622003(props) {
    ReceiptProcessSubAction.getScreenData({ Li_ReserveNum: props.Li_ReserveNum }).then(item => {
      if (item) {
        const width25 = item.sts1 > 0 && item.sts2 === 0 && item.sts3 === 0 ? '25%' : null
        const width50 = item.sts1 > 0 && (item.sts2 > 0 || item.sts3 > 0) ? '50%' : null
        const width80 = item.sts1 > 0 && item.sts2 > 0 && item.sts3 > 0 ? '80%' : null
        const checkShow = item.sts1 === 0 && item.sts2 === 0 && item.sts3 === 0 ? false : true
        const width = width25 ? width25 : (width50 ? width50 : (width80 ? width80 : ''))
        this.setState({ widthWS2622003: width })
        if (checkShow) {
          this.callModal(props, this.state.widthWS2622003, 'WS2622003_ReceiptProcessSub')
        }
      }
    })
  }

  HandelF7 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2620036_Refine
            Lio_State={this.formRef.current.getFieldValue('State')}
            Lio_Pay={this.formRef.current.getFieldValue('Pay')}
            Lio_Facility={this.formRef.current.getFieldValue('FacilityType')}
            onFinishScreen={(output) => {
              if (output) {
                this.closeModal()
              }

            }}
          />
        ),
      },
    })
  }

  eventF10 = () => { }

  eventF11 = () => {
    let props = {
      Li_CourseLevel: '',
      Li_ReserveNum: '',
      Li_PersonalNum: '',
      Li_Date: this.state.initParams.DateChar,
      Li_Getctxname: '',
      Li_ProcessDivision: 1,
      Li_Option: '',
      Li_Child: true,
      state_flag: 0
    }
    this.personalReserveProcess(props)
  }

  personalReserveProcess(props, receipt) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            {...props}
            onFinishScreen={(outPut) => {
              if (outPut?.Lo_Update) {
                this.setState({ Li_Change: Math.round(Math.random() * 1000) })
              }
              if (outPut?.reserveNum !== '' && outPut?.reserveNum !== undefined) {
                let params = {
                  ...outPut,
                  state_flag: props.state_flag ?? 0
                }
                this.acceptButton(params)
              } else {
                this.loadData()
              }
              if (receipt) {
                this.closeModal()
              }
            }}
          />
        ),
      },
    })
  }

  handleChange = (value, name) => {
    if (name === 'State') {
      this.formRef?.current?.setFieldsValue({ Pay: null })
      this.setState({
        initParams: {
          ...this.state.initParams,
          PersonalId: null,
          [name]: value,
          Pay: null,
        }
      }, () => this.loadInitData(this.state.initParams))
    } else if (name === 'Pay') {
      this.formRef?.current?.setFieldsValue({ State: null })
      this.setState({
        initParams: {
          ...this.state.initParams,
          PersonalId: null,
          [name]: value,
          State: null,
        }
      }, () => this.loadInitData(this.state.initParams))
    } else {
      if (name === 'DateChar') {
        // 日付のフォーマットを整える
        value = moment(value).format(dateFormat)
      } else if (name === 'receiptNumber') {
        // 全角数字の場合、半角数字へ
        value = changeHankakuText(value)
      }
      this.setState({
        initParams: {
          ...this.state.initParams,
          PersonalId: null,
          [name]: value,
        }
      }, () => this.loadInitData(this.state.initParams))
    }
  }

  ReturnComponent = (component) => {
    let components = {
      WS2583001_ConsultInquirySub,
      WS2537001_PersonalReserveProcess,
      WS2624002_VisitsChangeConfirm,
      WS2624003_VisitsCancelConfirm,
      WS2621001_PaymentProcessSub,
      WS2623001_DispensingProcessSub,
      WS2622003_ReceiptProcessSub,
      WS0956001_PaymentCorrectSub,
      WS0650001_DocumentBatchCreateSub
    }
    return components[component]
  }

  acceptButton = (reserveNum) => {
    const { record, iniObj } = this.state
    let params = {
      state_flag: reserveNum.state_flag ? reserveNum.state_flag : record.state_flag,
      DeskReceptistConfirmed: this.state.iniObj.DeskReceptistConfirmed ? 1 : 0,
      course_level: reserveNum?.courseLevel ? reserveNum.courseLevel : record.course_level,
      reserve_num: reserveNum.reserveNum,
      visit_date_on: record.visit_date_on,
      personal_1_total_price: record.personal_1_total_price,
      personal_2_total_price: record.personal_2_total_price,
      personal_3_total_price: record.personal_3_total_price,
      deposit_price_1: record.deposit_price_1,
      deposit_price_2: record.deposit_price_2,
      deposit_price_3: record.deposit_price_3,
      AcceptNum: 0
    }
    AcceptButtonAction(params)
      .then(res => {
        if (res.data) {
          let props = { Li_ReserveNum: res.data.Li_ReserveNum }
          this.loadData()
        }
      })
  }

  callModal = (props, width, nameScreen, className) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        className: className,
        component: (
          <Component
            {...props}
            onFinishScreen={(outPut) => {
              if (outPut?.nameScreen === nameScreen) {
                this.loadData()
                if (outPut.StsSend) {
                  this.closeModal()
                }
              }
              this.loadData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  renderMenu = (record) => (
    <Menu >
      {/* user action 1 照会 2583*/}
      <Menu.Item
        key='1'
        onClick={() => {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 'fit-content',
              component:
                <WS2583001_ConsultInquirySub
                  Li_ReserveNum={record.reservation_number}
                  onFinishScreen={(output) => {
                    this.closeModal()
                  }}
                />
              ,
            },
          })
        }}>照会</Menu.Item>

      {/* user action 2 変更 2537*/}
      <Menu.Item
        key='2'
        onClick={() => {
          let props = {
            Li_CourseLevel: record.course_level,
            Li_ReserveNum: record.reservation_number,
            Li_PersonalNum: record.personal_number_id,
            Li_Date: this.state.initParams.DateChar,
            Li_Getctxname: '',
            Li_ProcessDivision: '',
            Li_Option: '',
            Li_Child: true
          }
          this.personalReserveProcess(props)
        }}>変更</Menu.Item>

      <Menu.Item
        key='4'
        hidden={!(record.state_flag === 1)}
        onClick={() => {
          let props = {
            Li_CourseLevel: record.course_level,
            Li_ReserveNum: record.reserve_num,
            Li_AcceptOrCancel: 1,
            Li_Date: '',
            Li_AcceptNum: record.AcceptNum,
            Li_DataSource: this.state.dataSource,
            Li_Record: record,
          }
          this.callModal(props, 600, 'WS2624003_VisitsCancelConfirm')
        }}>取消</Menu.Item>

      {/* user action 5 入金 2621*/}
      <Menu.Item
        key='5'
        hidden={!record?.Expression_37}
        onClick={() => {
          let props = { Li_ReserveNum: record.reservation_number }
          this.callModal(props, 600, 'WS2621001_PaymentProcessSub')
        }}>{record?.Expression_37}</Menu.Item>

      {/* user action 6 出金 2623*/}
      <Menu.Item
        key='6'
        hidden={record.Expression_25 === '×' || record.Expression_25 === ''}
        onClick={() => {
          let props = { Li_ReserveNum: record.reservation_number }
          this.callModal(props, 500, 'WS2623001_DispensingProcessSub')
        }}>出金</Menu.Item>
      {/* user action 7 領収 2622*/}
      <Menu.Item
        key='7'
        hidden={record.Expression_25 !== '○'}
        onClick={() => {
          let props = {
            Li_SpecifiedIdentify: '',
            Li_ReserveNum: record.reservation_number,
          }
          this.checkWidthInWS2622003(props)
        }} >領収</Menu.Item>

      {/* user action 8 入金訂正 0956*/}
      <Menu.Item
        key='8'
        hidden={!record.Expression_38}
        onClick={() => {
          let props = {
            Li_BillingManageNum: record.Expression_61,
            Li_Identify: 1,
            Li_ModificationLevel: '',
          }
          this.callModal(props, 1200, 'WS0956001_PaymentCorrectSub')
        }} >{record.Expression_38}</Menu.Item>

      {/* user action 10 予約関連 0650*/}
      <Menu.Item
        key='9'
        onClick={() => {
          let props = {
            Li_CourseLevel: record.course_level,
            Li_ReserveNum: record.reservation_number,
            Li_OutputUnit: '',
            Li_OutputPattern: ''
          }
          this.callModal(props, 600, 'WS0650001_DocumentBatchCreateSub')
        }}>予約関連</Menu.Item>
    </Menu>
  )

  renderDivExpression = (start, end, object, startColor) => {
    let arrayValue = []
    if (object.id) {
      for (const property in object) {
        if (`Expression_${start}` === property && start <= end) {
          arrayValue.push({
            property: object[`Expression_${start}`] ? object[`Expression_${start}`] : '',
            color: object[`Expression_${startColor}`] ? object[`Expression_${startColor}`] : 210
          })
          start++
          startColor++
        }
      }
    } else {
      for (let index = start; index <= end; index++) {
        arrayValue.push({ property: '', color: 210 })
      }
    }
    return (
      <Row>
        <Col span={1}></Col>
        {arrayValue.map((item, index = start) => (
          <Col span={2} style={{ maxWidth: 'none' }} key={`Expression_${index}`}>
            <div style={{
              ...styleDivExpression,
              background: Color(item.color).Background,
              color: item.color === 156 ? '#FFFFFF' : '#ABADB3'
            }}>
              {item.property}
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  renderDivCacl = (title, price) => (
    <Col span={3}>
      <div style={styleDivTitle}>{title}</div>
      <div style={styleDivResult}>{
        price === 0 ? null : price?.toLocaleString()}
      </div>
    </Col>
  )


  receptionStatus = (record) => {
    if (record.Expression_23 === '○' && record.Expression_25 === '×') {
      let params = {
        state_flag: record.state_flag,
        DeskReceptistConfirmed: this.state.iniObj.DeskReceptistConfirmed ? 1 : 0,
        course_level: record.course_level,
        reserve_num: record.reservation_number,
        visit_date_on: record.visit_date_on,
        personal_1_total_price: record.personal_1_total_price,
        personal_2_total_price: record.personal_2_total_price,
        personal_3_total_price: record.personal_3_total_price,
        deposit_price_1: record.deposit_price_1,
        deposit_price_2: record.deposit_price_2,
        deposit_price_3: record.deposit_price_3,
        AcceptNum: record.receipt_number
      }
      AcceptButtonAction(params)
        .then(res => {
          if (res.data) {
            let props = { Li_ReserveNum: res.data.Li_ReserveNum }
            if (res.data.message.includes('WS2621001_PaymentProcessSub')) {
              // this.callModal(props, 600, 'WS2621001_PaymentProcessSub')
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: 'fit-content',
                  centered: true,
                  component:
                    <WS2622004_ReceiptProcessSubBulk
                      Li_ReserveNum={record.reservation_number}
                      record={record}
                      reloadData={() => {
                        this.loadData()
                      }}
                      onFinishScreen={() => {
                        this.loadData()
                        this.closeModal()
                      }}
                    />
                },
              })
            } else {
              this.loadData()
            }
          }
        })
    } else if (record.Expression_23 === '○') {
      let props = {
        Li_CourseLevel: record.course_level,
        Li_ReserveNum: record.reservation_number,
        Li_PersonalNum: record.personal_number_id,
        Li_Date: this.state.initParams.DateChar,
        Li_Getctxname: '',
        Li_ProcessDivision: '',
        Li_Option: '',
        Li_Child: true
      }
      this.personalReserveProcess(props)
    } else {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 'fit-content',
          centered: true,
          component:
            <WS2537501_acceptablePersonalReserveProcessMock
              Li_iniObj={this.state.iniObj}
              Li_CourseLevel={record.course_level}
              Li_ReserveNum={record.reservation_number}
              Li_PersonalNum={record.personal_number_id}
              Li_Date={this.state.initParams.DateChar}
              Li_Getctxname={''}
              Li_ProcessDivision={''}
              Li_Option={''}
              Li_Child={true}
              onSendMirais={() => {
                this.loadData()
              }}
              onFinishScreen={() => {
                this.loadData()
                this.closeModal()
              }}
            />
        },
      })
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  EnterC = () => {
    eventEnterC_CounterAction({
      ReserveNumAndPersonalNum: this.state.initParams.ReserveNumAndPersonalNum,
      DateChar: this.state.initParams.DateChar
    })
      .then(res => {
        if (res?.data) {
          let dataRes = res.data
          let props = {
            ...dataRes.variables,
            Li_Child: true
          }
          switch (dataRes.message) {
            case 'Call Screen WS2624002':
              this.callModal(props, 600, 'WS2624002_VisitsChangeConfirm')
              break
            case 'Call Screen WS2624003':
              this.callModal(props, 600, 'WS2624003_VisitsCancelConfirm')
              break
            case 'Call Screen WS2537001':
              this.personalReserveProcess(props)
              break
            default:
              break
          }
        }
      })
      .catch()
  }

  reAcquisition = (item) => {
    let params = {
      id: item
    }
    getReAcquisitionAction(params)
      .then(res => {
        const index = this.state.dataSource.findIndex((v) => v.id === res.data.VisitList[0].id)
        const data = [...this.state.dataSource]
        data.splice(index, 1, res.data.VisitList[0])
        this.setState({
          dataSource: data
        })
      })
  }

  useBarcode = (item) => {
    this.formRef.current.setFieldsValue({ ReserveNumAndPersonalNum: '' })
    let PersonalId = Number(item)
    let params = {
      PersonalId: PersonalId,
      DateChar: this.state.initParams.DateChar,
    }

    getUseBarcodeAction(params)
      .then(res => {
        this.loadData()
        if (!res.selectflg) {
          const targetRecord = this.state.dataSource.find((v) => v.reservation_number === res)

          if (targetRecord) {
            this.receptionStatus(targetRecord)
          }
        } else {
          message.warning(res.message)
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 'fit-content',
              centered: true,
              component:
                <WS2620100_ReservationSelect
                  VisitList={res?.visitBasic?.original?.VisitList}
                  onFinishScreen={(record) => {
                    this.closeModal()
                    this.receptionStatus(record)
                  }}
                />
            },
          })
        }

      }).catch(err =>
        this.setState({
          initParams: {
            ...this.state.initParams,
            PersonalId: null,
          }
        }))
  }

  render() {
    const { FacilityTypeList, dataSource, record } = this.state
    return (
      <div className='counter'>
        <Card title='窓口'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              initialValues={{ ...this.state.initParams, DateChar: moment(this.state.initParams.DateChar) }}
              autoComplete='off'
            >
              <div className='box_inner_vertical'>
                <div className='box_inner_horizontal'>
                  <Form.Item
                    name='DateChar'
                    label='日付'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      allowClear={false}
                      onChange={(date, dateString) => this.handleChange(dateString, 'DateChar')}
                    />
                  </Form.Item>
                  <Form.Item
                    name='receiptNumber'
                    label='受付番号'
                    style={{ marginBottom: 0 }}>
                    <Input
                      type='tel'
                      className='input-size-number-8'
                      onPressEnter={(e) => {
                        this.handleChange(e.target.value, 'receiptNumber')
                      }}
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.eventF11()
                    }}
                  >
                    <span className='btn_label'>
                      新規追加
                    </span>
                  </Button>
                </div>

                <DataGrid
                  style={{ height: 'calc(100vh - 215px)' }}
                  dataSource={this.state.dataSource}
                  showBorders={true} //周りの線
                  showRowLines={true} //横線
                  wordWrapEnabled={true} //折り返し
                  allowColumnReordering={true} //カラム移動
                  allowColumnResizing={true} //カラムの幅リサイズ
                  columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                  columnAutoWidth={true}
                  selection={{ mode: 'single' }}
                  hoverStateEnabled={true}
                  paging={{
                    // ペジネーション設定
                    enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                  }}
                  onRowPrepared={(record) => {
                    // 行ごとの背景色設定
                    if (record.rowType === 'data') {
                      let color = ''
                      // クラスネームが効かないので直接カラーコードを入れる
                      if (record.data.id === this.state.selectedId) {
                        color = '#b5dbff';  //table-row-light
                      } else if (record.data.receipt_number > 0) {
                        color = '#ecf8ff'; //table-row-accepted
                      }
                      record.rowElement.style.backgroundColor = color
                    }
                  }}
                  scrolling={{ mode: 'infinite' }} //スクロールがもっさりしないおまじない　無限スクロール対応 それでも設定の入れ方によっては重くなる
                  onRowDblClick={(event) => {
                    const record = event.data
                    this.receptionStatus(record)
                  }}
                >
                  <Column
                    caption='ID'
                    cssClass='column-size-10'
                    dataField='personal_number_id'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value}</div>
                    )}
                  />
                  <Column
                    caption='受付No'
                    cssClass='column-size-5'
                    dataField='receipt_number'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value}</div>
                    )}
                  />
                  <Column
                    caption='時間'
                    cssClass='column-size-4'
                    dataField='period_time'
                  />
                  <Column
                    caption='氏名'
                    cssClass='column-size-40'
                    dataField='kanji_name'
                  />
                  <Column
                    caption='カナ氏名'
                    cssClass='column-size-40'
                    dataField='kana_name'
                  />
                  <Column
                    caption='性別'
                    cssClass='column-size-4'
                    dataField='sex'
                    cellRender={(value) => {
                      return (
                        <div style={{
                          textAlign: 'center',
                          color: Color(value.data.sex_backGroundColor)?.Foreground
                        }}>
                          {value.value}
                        </div>
                      )
                    }}
                  />
                  <Column
                    caption='生年月日'
                    cssClass='column-size-10'
                    dataField='birthday'
                  />
                  <Column
                    caption='年齢'
                    cssClass='column-size-4'
                    dataField='Age'
                    cellRender={(value) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value.value}</div>
                      )
                    }}
                  />
                  <Column
                    caption='コース'
                    cssClass='column-size-60'
                    dataField=''
                    cellRender={(text) => (
                      <div>
                        {text.data.visit_course} {text.data.contract_short_name}
                      </div>
                    )}
                  />
                  <Column
                    caption='事業所'
                    cssClass='column-size-60'
                    dataField='office_kanji_name'
                  />
                  <Column
                    caption='請求額'
                    cssClass='column-size-10'
                    dataField='Expression_21'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value.toLocaleString()}</div>
                    )}
                  />
                  <Column
                    caption='受付'
                    cssClass='column-size-4'
                    dataField='Expression_23'
                    cellRender={(event) => (
                      <div
                        style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                      >
                        {event.value}
                      </div>
                    )} />
                  <Column
                    caption='入金'
                    cssClass='column-size-4'
                    dataField='Expression_25'
                    cellRender={(event) => (
                      <div
                        style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                      >
                        {event.value}
                      </div>
                    )}
                  />
                  <Column
                    caption='領収'
                    cssClass='column-size-4'
                    dataField='Expression_27'
                    cellRender={(event) => (
                      <div
                        style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                      >
                        {event.value}
                      </div>
                    )}
                  />
                  <Column
                    width={45}
                    fixed={true}
                    fixedPosition='right'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'center' }}>
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => this.renderMenu(event.data)}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  />
                </DataGrid>
              </div>

              <div className='box_button_bottom_right' style={{ justifyContent: 'left' }}>
                <Form.Item name='ReserveNumAndPersonalNum' label='バーコード' style={{ marginBottom: 0 }}>
                  <Input
                    onPressEnter={(e) => this.useBarcode(e.target.value)}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </Card>
        {
          ModalCustom({
            className: this.state.childModal.className,
            width: this.state.childModal.width,
            visible: this.state.childModal.visible,
            component: this.state.childModal.component,
            centered: this.state.childModal.centered,
            destroyOnClose: true,
            onCancel: this.closeModal
          })
        }
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2620001_Counter)
