import React, { useState, useEffect, createRef } from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, Typography, Radio, InputNumber } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'

/**
 * 共通コンポーネント
 * 新規追加用モーダル
 * @param {*} props
 * columns・・・
 * @returns
 */
const ModalAdd = (props) => {
  const formRef = createRef()
  const [childModal, setChildModal] = useState({
    visible: false,
    component: null,
    width: 0,
  })
  const [keyChange, setKeyChange] = useState([])
  const [itemChange, setItemChange] = useState([])
  const [totalCnt, setGoukeiChange] = useState(0)

  /**
  * keyChangeが変更されたとき
  */
  useEffect(() => {
    formRef.current?.setFieldsValue(keyChange)
  }, [keyChange])


  /**
   * 子モーダルでonFinishScreenイベント発生時の処理
   * @param {子モーダルの返却値} output
   */
  const onFinish = (output, dataIndex) => {
    // outputのkey名を取得
    let keys = Object.keys(output)
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === dataIndex) {
        // 変更するカラム情報を取る
        let keyName = keys[i]
        setItemChange({
          ...itemChange,
          [keyName]: output[keyName]
        })
        setKeyChange(
          { [keyName]: output[keyName] }
        )
      } else {
        let keyName = keys[i]
        setItemChange({
          ...itemChange,
          [keyName]: output[keyName]
        })
      }
    }
    // 閉じる
    closeModal()
  }

  /**
   * 入力　変更処理
   * @param {*} e
   */
  const handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]

    const SupportATotalPoints = formRef.current?.getFieldValue('SupportATotalPoints')
    const SupportBTotalPoints = formRef.current?.getFieldValue('SupportBTotalPoints')

    let totalCnt = 0
    if (columnName === "SupportATotalPoints" || columnName === "SupportBTotalPoints") {
      if (!isNaN(SupportATotalPoints)) {
        totalCnt = totalCnt + Number(SupportATotalPoints)
        formRef.current?.setFieldsValue({ SupportATotalPoints: Number(SupportATotalPoints) })
      }
      if (!isNaN(SupportBTotalPoints)) {
        totalCnt = totalCnt + Number(SupportBTotalPoints)
        formRef.current?.setFieldsValue({ SupportBTotalPoints: Number(SupportBTotalPoints) })
      }
      setGoukeiChange(totalCnt)
    }

    setItemChange({
      ...itemChange,
      [columnName]: columnValue
    })
  }

  /**
   * モーダルを閉じる
   */
  const closeModal = () => {
    setChildModal({
      ...childModal,
      visible: false,
    })
  }

  const required = [{ required: true, message: '必須項目です' }]

  return (
    <div className='modal-add' >
      <Card title='新規追加'>
        <Form
          ref={formRef}
          onValuesChange={handleFormChange}
          autoComplete='off'
        >
          {props.columns.map((item, index) => (
            <div key={item + index}>
              {item.type !== 9 ?
                <Typography style={{ marginBottom: '5px' }}>
                  {item.title}
                </Typography> : null
              }
              {(item.type === 0) ?
                <Form.Item>
                  <Input readOnly
                    bordered={false}
                    value={totalCnt}
                  >
                  </Input>
                </Form.Item>
                :
                (item.type === 1) ?
                  // <Input>
                  <Form.Item name={item.dataIndex} rules={item.required ? required : ''}>
                    <Input
                      style={{ marginBottom: '5px' }}
                      className={'input-size-' + item.classNumber}
                      onDoubleClick={() => {
                        if (item.childModal !== undefined) {
                          setChildModal({
                            visible: true,
                            width: item.childModal.width,
                            // 可変のコンポーネント設定の為にReact.createElementを使用する
                            component: React.createElement(item.childModal.component, { onFinishScreen: (output) => onFinish(output, item.dataIndex) }, null)
                          })
                        }
                      }}
                    />
                  </Form.Item>
                  :
                  (item.type === 2) ?
                    // <Select>
                    <Form.Item
                      name={item.dataIndex}
                      className={'input-size-' + item.classNumber}
                    >
                      <Select
                        style={{ marginBottom: '5px' }}
                      >
                        {item.selectList === undefined ? [] : item.selectList.map((item, index) => (
                          <Select.Option
                            key={item.label + index}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    :
                    (item.type === 3) ?
                      // <Radio>
                      <Form.Item name={item.dataIndex}>
                        <Radio.Group
                          style={{ marginBottom: '5px', display: 'flex' }}
                        >
                          {item.radioList === undefined ? [] : item.radioList.map((item, index) => (
                            <Radio
                              key={item.label + index}
                              value={item.value}
                            >
                              {item.label}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                      :
                      (item.type === 4) ?
                        //Search
                        <Form.Item name={item.dataIndex}>
                          <Input.Search
                            allowClear={true}
                            className={'input-size-' + item.classNumber}
                            onSearch={() => {
                              if (item.childModal !== undefined) {
                                setChildModal({
                                  visible: true,
                                  width: item.childModal.width,
                                  // 可変のコンポーネント設定の為にReact.createElementを使用する
                                  component: React.createElement(item.childModal.component, { onFinishScreen: (output) => { console.log(output); onFinish(output, item.dataIndex) } }, null)
                                })
                              }
                            }}

                          />
                        </Form.Item>
                        :
                        (item.type === 5) ?
                          //InputNumber
                          <Form.Item name={item.dataIndex} rules={item.required ? required : ''}>
                            <InputNumber
                              style={{ marginBottom: '5px' }}
                              min={item.min}
                              max={item.max}
                              defaultValue={item.defaultValue}
                              onDoubleClick={() => {
                                if (item.childModal !== undefined) {
                                  setChildModal({
                                    visible: true,
                                    width: item.childModal.width,
                                    // 可変のコンポーネント設定の為にReact.createElementを使用する
                                    component: React.createElement(item.childModal.component, { onFinishScreen: (output) => onFinish(output, item.dataIndex) }, null)
                                  })
                                }
                              }}
                            />
                          </Form.Item>
                          :
                          null
              }
            </div>
          ))}
        </Form>

        {/* Footer */}
        <div className='box_button_bottom_right'>
          {/* 保存ボタン */}
          <Button
            type='primary'
            icon={<SaveOutlined />}
            onClick={() => {
              props.onFinishScreen({
                // 既存のオブジェクトにimplementing_agency_codeというキーと値を追加(実施者マスタ：実施機関データ取得で必要となるため)
                itemChange: {
                  ...itemChange,
                  ...keyChange
                }
              })
            }}
          >
            <span className='btn_label'>
              保存
            </span>
          </Button>
        </div>
      </Card>

      <ModalDraggable
        width={childModal.width}
        visible={childModal.visible}
        component={childModal.component}
        onCancel={() => {
          closeModal()
        }}
      />
    </div >
  )
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd)
