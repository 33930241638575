import React from "react";
import { connect } from "react-redux";
import  ModalDraggable  from "components/Commons/ModalDraggable";
import { Card, Button, Form } from "antd";
import { ExportOutlined } from '@ant-design/icons';


class PrinterSetting extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'プリンター設定';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="rising-document-create">
        <Card className="mb-2" title="プリンター設定">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Button
              icon={<ExportOutlined />}
              type='primary'
              onClick={() => {
                window.open(location.protocol + '//' + location.hostname + ':631/admin')
              }} >
              <span className='btn_label'>
                管理画面を開く
              </span>
            </Button>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0}}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PrinterSetting);
