import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Table, Button, Form, Input, message } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0176001_QueryCategoryInfo extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    _Dks050StartUpFlag: PropTypes.any,
    _Dks050GroupCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS70500:照会:カテゴリー情報';

    this.state = {
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      indexTable: 0
    };
  }

  componentDidMount() {
    this.setState({ isLoadingTable: true })

    VenusApiRoutesV2.callApi("API000176001002", {})
      .then((res) => {
        this.setState({
          dataSource: res? res : [],
          isLoadingTable: false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) { }
  }

  onSelectEvent() {
    const { onFinishScreen } = this.props;
    if (onFinishScreen) {
      onFinishScreen({
        gGroupCode: this.state.selectedRows[0].category_code,
      });
    }
  }

  componentWillUnmount() { }
  render() {
    return (
      <div className="query-category-info">
        <Form ref={this.formRef}>
          <Card className="mb-2" title="V4-VNS70500:照会:カテゴリー情報">
            <Table bordered
              size="small"
              dataSource={this.state.dataSource} 
              loading={this.state.isLoadingTable}
              pagination={true}
              scroll={{ y: 600 }}
              rowKey={(record) => record.id}
              rowClassName={(record, index) => record.id === this.state.selectedRows[0]?.id ? 'table-row-light' : ''}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.setState({
                      selectedRows: [record],
                      selectedRowKeys: [record.id],
                      indexTable: rowIndex,
                    })
                  }
                };
              }}

            >
              <Table.Column title="コード" dataIndex="category_code"
                width={100}
                showSorterTooltip={false}
                sorter={(a, b) => a.category_code - b.category_code}
                render={(value, record, index) => {
                  return <Form.Item>
                    <Input value={value} onDoubleClick={() =>
                      this.onSelectEvent()}>
                    </Input>
                  </Form.Item>
                }} />
              <Table.Column title="名　　称" dataIndex="category_name"
                showSorterTooltip={false}
                sorter={(a, b) => a.category_name.localeCompare(b.category_name, 'ja')}
               />
              <Table.Column width={100} title="共通ｶﾃｺﾞﾘ" dataIndex="common_category_code"
                showSorterTooltip={false}
                sorter={(a, b) => a.common_category_code - b.common_category_code}
                render={(value, record, index) => {
                  return <div style={{ textAlign: 'right' }}><span>{value}</span></div>
                }} 
              />
            </Table>
            <div style={{ float: 'right', marginTop: '10px' }}>
              <Button onClick={() => this.onSelectEvent()} type="primary">確定</Button>
            </div>
          </Card>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0176001_QueryCategoryInfo);
