import React from "react";
import { connect } from "react-redux";
import {
  Form, Input, Button, Card, Row, Col, message, Space, TimePicker
} from 'antd';
import moment from 'moment';
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable from "components/Commons/ModalDraggable";
import TodoCreateAction from "redux/MN_Basis/TodoCreate/TodoCreate.action"
class WS2522001_TodoCreate extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'TODO作成';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      modalTitle: ''
    };
  }

  /**
   * 初期設定
   */
  componentDidMount() {

    // 初期表示
    let subTitle = '[新規]';
    let dateChar = moment().format('YYYY/MM/DD');
    // let timeZone = moment().format('HH:00');
    let timeZone = moment(moment().format('HH:00'), 'HH:mm');
    let subject = '';
    let content = '';

    if (this.props.todoData !== null) {
      // 編集モードの場合
      subTitle = '[編集]';
      dateChar = this.props.todoData.date_on;
      timeZone = (this.props.todoData.time_at !== null) ? moment(this.props.todoData.time_at, 'HH:mm') : null
      subject = this.props.todoData.title;
      content = this.props.todoData.content;
    }

    // タイトル
    this.setState({ modalTitle: 'TODO ' + subTitle });
    // Form
    this.formRef.current?.setFieldsValue({
      DateChar: dateChar,
      TimeZone: timeZone,
      Subject: subject,
      Content: content,
    })

  }

  onClickUpdate() {
    let todoId = '';
    if (this.props.todoData !== null) {
      todoId = this.props.todoData.id;
    }

    const dateChar = this.formRef.current?.getFieldValue("DateChar");
    // timeZoneに何も入力されていない場合nullを設定する
    let timeZone = this.formRef.current?.getFieldValue("TimeZone") ?? null
    timeZone = (timeZone !== null) ? timeZone.format('HH:mm') : null
    const subject = this.formRef.current?.getFieldValue("Subject");
    const content = this.formRef.current?.getFieldValue("Content");

    // 日付の入力チェック
    if (dateChar === '') {
      message.error('日付が入力されていません');
      return;
    }

    // 件名の入力チェック
    if (subject === '') {
      message.error('件名が入力されていません');
      return;
    }

    let param = {
      id: todoId,
      Li_userName: this.props.userName,
      Li_dateChar: dateChar,
      Li_timeZone: timeZone,
      Li_subject: subject,
      Li_content: content
    }
    TodoCreateAction.onClickUpdate(param)
      .then((res) => {
        //親画面に渡す
        this.props.onFinishScreen();
        this.props.index();
      }).catch((err) => {
        const res = err.response;
        console.log(err);
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {

    return (
      <div className="todo-create">
        <Card title={this.state.modalTitle}>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className="box_inner_vertical">
              <div className="box_inner_horizontal">
                <Form.Item label="日付" name="DateChar" >
                  <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/DD" />
                </Form.Item>
                <Form.Item name="TimeZone" label="時間">
                  <TimePicker format='HH:mm' />
                </Form.Item>
              </div>
              <Form.Item style={{ width: '100%' }} label="件名" name="Subject" >
                <Input maxLength={100} />
              </Form.Item>
              <Form.Item style={{ width: '100%' }} label="内容" name="Content" >
                <Input.TextArea rows="5" />
              </Form.Item>
            </div>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                onClick={() => { this.onClickUpdate() }}
              >
                {(this.props.todoData !== null) ? '更新' : '作成'}
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2522001_TodoCreate);
