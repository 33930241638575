import { message } from 'antd'
import ReservationStatusService from 'services/Others/ReservationStatus/ReservationStatusService'

const ReservationStatusAction = {
  getDataVariousAggregate(data) {
    return ReservationStatusService.getDataVariousAggregate(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  Redisplay(data) {
    return ReservationStatusService.Redisplay(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  reservation(data) {
    return ReservationStatusService.reservation(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default ReservationStatusAction
