import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Table, Button } from "antd";
import YearSelect from 'redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/YearSelect.actions';
import WS1171001_AggregateInfoCorrect from './WS1171001_AggregateInfoCorrect'
class WS1169004_YearSelect extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '年度選択';

    this.state = {
      format: "",
      format_ys: "",
      tableData: [],
      isLoadingTable: true,
      pagination: {
        defaultPageSize: 10,
        size: "small",
        showQuickJumper: true
      },
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
    };

  }
  componentDidMount() {
    this.getInit()
  }
  getInit() {
    let format = this.props.Li_Format;
    this.setState({ format: format })
    this.getScreenData()
  }
  getScreenData() {
    this.setState({ isLoadingTable: true })
    YearSelect.getScreenData()
      .then(res => {

        this.setState({
          tableData: res ? res : []
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }
  selectYear = (year_value) => {
    this.props.selectYear(year_value)
  }
  render() {
    return (
      <div className="year-select">
        <Card title="年度選択">
          <Table bordered
            dataSource={this.state.tableData}
            loading={false}
            rowKey={(record) => record.id}
            pagination={this.state.pagination}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {

                  this.setState({
                    format_ys: record.format
                  })
                  this.selectYear(record.format)
                },
                onClick: () => {
                  this.setState({
                    format_ys: record.format
                  })
                }
              };
            }}
          >

            <Table.Column title="年度" width={100} dataIndex="format" sorter={(a, b) => a.format.localeCompare(b.format, "ja")} />
            <Table.Column title="名称" width={100} dataIndex="remarks" sorter={(a, b) => a.remarks.localeCompare(b.remarks, "ja")} />

          </Table>
          <Button
            style={{ float: "right", marginTop: "1em" }}
            type="primary"
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: "60%",
                  component: (
                    <WS1171001_AggregateInfoCorrect
                      Li_Format={this.state.format_ys}
                    // onFinishScreen={output => {
                    //   this.closeModal();
                    // }}
                    // selectYear={this.selectYear}
                    />
                  )
                }
              });
            }}
          >
            選択
          </Button>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />

      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1169004_YearSelect);
