import React from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Input, Table, message } from 'antd'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import BasicCourseInquiryAction from 'redux/basicInfo/ContractInfoBatchProcess/BasicCourseInquiry.action'

const grid = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
}

class WS0265001_BasicCourseInquiry extends React.Component {
  static propTypes = {
    Lo_CourseCode: PropTypes.func,
    Lo_CourseName: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '基本コース照会'

    this.state = {
      dataSource: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }

    this.loadCourses = this.loadCourses.bind(this)
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      ShortNameSearch: this.props.Li_CourseName ?? ''
    })
    this.loadCourses()
  }

  loadCourses() {

    const formIns = this.formRef.current
    let params = {
      ShortNameSearch: formIns.getFieldValue('ShortNameSearch'),
    }

    BasicCourseInquiryAction.getCourses(params)
      .then(res => {
        this.setState({
          dataSource: res.data,
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='basic-course-inquiry'>
        <Card title='基本コース照会'>
          <Form
            {...grid}
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              name='ShortNameSearch'
              label='検索'
              style={{ width: 'fit-content' }}
            >
              <Input
                className='input-size-60'
                onPressEnter={() => this.loadCourses()}
              />
            </Form.Item>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.course_code}
              scroll={{ y: 500 }}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    const func = this.props.onSelect || this.props.onFinishScreen
                    const medicalExamCourseFlag = this.props.medicalExamCourseFlag ? true : false

                    func({
                      Lo_CourseCode: record.course_code,
                      Lo_CourseName: record.course_name_short_name,
                      recordData: record,
                      medicalExamCourseFlag: medicalExamCourseFlag
                    })
                  }
                }
              }}
            >
              <Table.Column title='コースコード' dataIndex='course_code' align='center' width={100} />
              <Table.Column title='コース略称' dataIndex='course_name_short_name' />
              <Table.Column title='コース名称' dataIndex='course_name_formal' />
              <Table.Column title='予約項目' dataIndex='reserve_item' width={110} />
              <Table.Column title='パターンコード' dataIndex='pattern_code' align='center' width={110} />
            </Table>
          </Form>
        </Card>
      </div>
    )
  }
}

export default WS0265001_BasicCourseInquiry
