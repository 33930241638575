import { message } from 'antd'
import FollowUpLetterMaintainService from 'services/IntroductionLetter/FollowUpLetter/FollowUpLetterMaintainService'

const FollowUpLetterMaintainAction = {
  index(data) {
    return FollowUpLetterMaintainService.index(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getFollowUpList(data) {
    return FollowUpLetterMaintainService.getFollowUpList(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  printBefore(data) {
    return FollowUpLetterMaintainService.printBefore(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  save(data) {
    return FollowUpLetterMaintainService.save(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteData(data) {
    return FollowUpLetterMaintainService.deleteData(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  print(data) {
    return FollowUpLetterMaintainService.print(data)
  },
  preview(data) {
    return FollowUpLetterMaintainService.preview(data)
  },
  getPreviousData(data) {
    return FollowUpLetterMaintainService.getPreviousData(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default FollowUpLetterMaintainAction
