import React from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Table, Modal, Menu, Popconfirm, Dropdown, Button, message, Tag } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { number_format } from 'helpers/CommonHelpers'
import Color from 'constants/Color'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import moment from 'moment'
import WS0307076_CreateContract from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307076_CreateContract.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx'
import WS0309017_ContractItemSubMock from 'pages/Mock/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0309017_ContractItemSubMock.jsx'
import WS0315002_ConditionCopy from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0315002_ConditionCopy.jsx'
import WS0307082_CodeChanges from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307082_CodeChanges.jsx'
import ContractHistorySubAction from 'redux/basicInfo/ContractInfoMaintain/ContractHistorySub.actions'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0307010_ContractHistorySubTableMock extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
  }

  formRef = React.createRef()
  termsFieldName = 'terms'

  constructor(props) {
    super(props)

    // document.title = '契約作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount() {
    this.loadContractData()
  }

  loadContractData() {
    const { Li_ContractType, Li_ContractOrgCode, Lio_ContractStartDate } = this.props
    let params = {
      Li_ContractType: Li_ContractType,
      Li_ContractOrgCode: Li_ContractOrgCode,
      Lio_ContractStartDate: Lio_ContractStartDate
    }
    ContractHistorySubAction.getTableData(params)
      .then((res) => {
        const formIns = this.formRef.current
        const resData = res.data
        if (!resData) {
          formIns.resetFields()
          return
        }

        // Default data
        resData.contract_start_date_on = moment(resData.contract_start_date_on).isValid() ? moment(resData.contract_start_date_on).format('YYYY/MM/DD') : resData.contract_start_date_on
        resData.bill_rounding = (resData.bill_rounding === null) ? 0 : resData.bill_rounding
        resData.bill_rounding_following = (resData.bill_rounding_following === null) ? 0 : resData.bill_rounding_following
        resData.calculation_division = (resData.calculation_division === null) ? 0 : resData.calculation_division
        resData.personal_claims_division = (resData.personal_claims_division === null) ? 0 : resData.personal_claims_division
        resData.tax_calc_unit_division = (resData.tax_calc_unit_division === null) ? 0 : resData.tax_calc_unit_division
        resData.tax_decimal_point = (resData.tax_decimal_point === null) ? 0 : resData.tax_decimal_point

        this.setState({
          dataSource: resData
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  getFormFieldValue(namePath) {
    return this.formRef.current ? this.formRef.current.getFieldValue(namePath) : undefined
  }

  copyData(record) {
    this.setState({
      ...this.state,
      childModal: {
        width: 850,
        visible: true,
        component: (<WS0315002_ConditionCopy
          Li_Context={null}
          Li_ContractType={record.contract_type}
          contractType={this.props.Li_ContractType}
          Li_ContractOrgCode={record.contract_office_code}
          contractOrgCode={this.props.Li_ContractOrgCode}
          Li_ContractOrgName={this.props.Li_ContractOrgName}
          Li_ContractStartDate={record.contract_start_date_on}
          Li_ContractNum={record.contract_number}
          recordData={record}
          dataSource={this.state.dataSource}
          onFinishScreen={() => {
            this.closeModal()
            this.loadContractData()
          }}
        />)
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='create-contract' style={{ width: '100%' }}>
        <Card title='契約情報（モック）'>
          <Form ref={this.formRef}>
            <Table
              size='small'
              dataSource={this.state.dataSource}
              // scroll={this.state.dataSource?.length >= 20 ? { y: 'calc(100vh - 319px)' } : {}}
              scroll={{ x: 'max-content', y: resizableTableScroll(150) }}
              pagination={this.state.dataSource?.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record => `tbl-chs-${record.id}`)}
              bordered
              onRow={(record) => {
                return {
                  onClick: () => {
                    const selected = record
                    this.setState({
                      selectedRowKeys: [selected.id],
                      selectedRows: selected,
                    })
                  },
                  onDoubleClick: () => {
                    this.setState({
                      ...this.state,
                      childModal: {
                        width: 800,
                        visible: true,
                        component: (<WS0309017_ContractItemSubMock
                          Li_ContractType={record.contract_type}
                          Li_ContractOrgCode={record.contract_office_code}
                          Li_ContractStartDate={record.contract_start_date_on}
                          Li_ContractNum={record.contract_number}
                          Li_CourseConditions={record.course_conditions}
                          onSaved={() => {
                            this.loadContractData()
                          }}
                        />)
                      }
                    })
                  }
                }
              }}
            >
              <Table.Column
                // width={50} 
                className='column-size-10'
                title='番号'
                dataIndex='contract_number'
              />
              <Table.Column
                // width={60} 
                className='column-size-10'
                title='コース'
                dataIndex='medical_exam_course'
              />
              <Table.Column
                title='略名称'
                // width={150} 
                className='column-size-60'
                dataIndex='contract_short_name'
                ellipsis
              />
              <Table.Column
                title='正式名称'
                // width={150} 
                className='column-size-60'
                dataIndex='contract_name'
                ellipsis
              />
              <Table.Column
                // width={40} 
                className='column-size-2'
                title='条'
                dataIndex='course_conditions'
                render={(value) => (
                  <Tag
                    visible={(value == 1)}
                    style={{ textAlign: 'center' }}
                    color={((value == 1) ? Color(156) : Color(161)).Background}
                  >
                    条
                  </Tag>
                )} />
              <Table.Column
                // width={35} 
                className='column-size-2'
                title='性'
                key='gender'
                render={(value, record) => {
                  if (record.course_conditions === 1) {
                    switch (record.conditions_gender) {
                      case 1:
                        return '男'
                      case 2:
                        return '女'
                      default:
                        return ''
                    }
                  }
                  return ''
                }} />
              <Table.Column
                // width={35} 
                className='column-size-2'
                title='続'
                key='continue'
                render={(value, record) => {
                  if (record.course_conditions === 1) {
                    return record.various_names_name?.trim().substring(0, 1)
                  }
                  return ''
                }} />
              <Table.Column
                // width={50} 
                className='column-size-4'
                title='年齢'
                dataIndex='conditions_age_division'
                render={value => {
                  switch (value) {
                    case 0:
                      return '受診'
                    case 1:
                      return '年度'
                    case 2:
                      return '学童'
                    case 3:
                      return '指定'
                    default:
                      return ''
                  }
                }} />
              <Table.Column title='保険者' dataIndex='insurer_total_price' width={70}
                render={(value) => value ? (<div style={{ textAlign: 'right' }}>{number_format(value)}</div>) : null}
              />
              <Table.Column title='事務所' dataIndex='office_total_price' width={70}
                render={(value) => value ? (<div style={{ textAlign: 'right' }}>{number_format(value)}</div>) : null}
              />
              <Table.Column title='他団体' dataIndex='organization_total_price' width={70}
                render={(value) => value ? (<div style={{ textAlign: 'right' }}>{number_format(value)}</div>) : null}
              />
              <Table.Column title='個人' key='personal_total_price' width={70}
                render={(v, record) => {
                  const value = record.personal_1_total_price + record.personal_2_total_price + record.personal_3_total_price
                  return value ? (<div style={{ textAlign: 'right' }}>{number_format(value)}</div>) : null
                }}
              />
              <Table.Column title='合計' key='total_price' width={70} render={(v, record) => {
                const value = record.insurer_total_price + record.office_total_price + record.organization_total_price + record.personal_1_total_price + record.personal_2_total_price + record.personal_3_total_price
                return value ? (<div style={{ textAlign: 'right' }}>{number_format(value)}</div>) : null
              }} />
              <Table.Column width={40} key='action'
                title={(
                  <Button type='primary' size='small' title='新規' icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => {
                    this.setState({
                      ...this.state,
                      childModal: {
                        width: 600,
                        visible: true,
                        component: (<WS0307076_CreateContract
                          Li_ContractType={this.props.Li_ContractType}
                          Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                          Li_ContractStartDate={this.props.Lio_ContractStartDate}
                          onCreateFinish={() => {
                            this.closeModal()
                            this.loadContractData()
                          }}
                          copyClick={(record) => {
                            this.closeModal()
                            this.copyData(record)
                          }}
                        />)
                      }
                    })
                  }} />
                )}
                render={(value, record) => {
                  return (
                    <Dropdown
                      size='small'
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key='照会' onClick={(menuInfo) => {
                            this.setState({
                              ...this.state,
                              childModal: {
                                width: 850,
                                visible: true,
                                component: (<WS0605127_ContractLineItemDisplay
                                  Li_ContractType={record.contract_type}
                                  Li_ContractOrgCode={record.contract_office_code}
                                  Li_ContractStartDate={record.contract_start_date_on}
                                  Li_ContractNum={record.contract_number}
                                  onFinishScreen={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />)
                              }
                            })
                          }}>照会</Menu.Item>
                          <Menu.Item key='変更' onClick={() => {
                            this.setState({
                              ...this.state,
                              childModal: {
                                width: 800,
                                visible: true,
                                component: (<WS0309017_ContractItemSubMock
                                  Li_ContractType={record.contract_type}
                                  Li_ContractOrgCode={record.contract_office_code}
                                  Li_ContractStartDate={record.contract_start_date_on}
                                  Li_ContractNum={record.contract_number}
                                  Li_CourseConditions={record.course_conditions}
                                  onSaved={() => {
                                    this.loadContractData()
                                  }}
                                />)
                              }
                            })
                          }}>変更</Menu.Item>
                          <Menu.Item
                            key='削除'
                            onClick={() => {
                              Modal.confirm({
                                content: '削除を行いますか',
                                onOk: () => {
                                  let params = {
                                    Li_ContractType: record.contract_type,
                                    Li_ContractOrgCode: record.contract_office_code,
                                    Li_ContractStartDate: record.contract_start_date_on,
                                    contract_number: record.contract_number,
                                  }
                                  ContractHistorySubAction.destroyTerm(params)
                                    .then(res => {
                                      if (res) {
                                        this.loadContractData()
                                      }
                                    })
                                }
                              })
                            }}>削除
                          </Menu.Item>
                          <Menu.Item key='複写' onClick={() => {
                            this.setState({
                              ...this.state,
                              childModal: {
                                width: 850,
                                visible: true,
                                component: (<WS0315002_ConditionCopy
                                  Li_Context={null}
                                  Li_ContractType={record.contract_type}
                                  contractType={record.contract_type}
                                  Li_ContractOrgCode={record.contract_office_code}
                                  Li_ContractOrgName={this.props.Li_ContractOrgName}
                                  Li_ContractStartDate={record.contract_start_date_on}
                                  Li_ContractNum={record.contract_number}
                                  recordData={record}
                                  dataSource={this.state.dataSource}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                    this.loadContractData()
                                  }}
                                />)
                              }
                            })
                          }}>複写</Menu.Item>
                          <Menu.Item key='コード変更' onClick={() => {
                            this.setState({
                              ...this.state,
                              childModal: {
                                width: 400,
                                visible: true,
                                component: (<WS0307082_CodeChanges
                                  Li_ContractType={record.contract_type}
                                  Li_ContractOrgCode={record.contract_office_code}
                                  Li_ContractStartDate={record.contract_start_date_on}
                                  Li_ContractNum={record.contract_number}
                                  Li_Course={record.medical_exam_course}
                                  Li_ContractAbbreviation={record.contract_short_name}
                                  Li_ContractName={record.contract_name}
                                  dataSource={this.state.dataSource}
                                  onChanged={() => {
                                    this.closeModal()
                                    this.loadContractData()
                                  }}
                                />)
                              }
                            })
                          }}>コード変更</Menu.Item>
                        </Menu>
                      )}
                    ><Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}
export default WS0307010_ContractHistorySubTableMock
