import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row, Col,Input } from "antd";


class WS1520001_OptionsItemInfoConfirmingSub extends React.Component {

  constructor(props) {
    super(props);

    // document.title = 'オプションアイテム情報確認 SUB';

    this.state = {
    };
  }

  render() {
    return (
      <div className="options-item-info-confirming-sub">
        <Card className="mb-2" title="オプションアイテム情報確認 SUB">
          <Row>
            <Col span={9}>
              <Table bordered
                dataSource={[]} 
                loading={false}
                pagination={{
                  defaultCurrent: 1,
                  total: 1,
                  pageSize: 1,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => { },
                  onShowSizeChange: (page, pageSize) => { },
                }}
                rowKey={(record) => record.id}
              >
                <Table.Column title="項目" width={80} dataIndex="" key="" />
                <Table.Column title="種別" dataIndex="" key="" />
                <Table.Column title="ｵﾌﾟｼｮﾝ" dataIndex="" key="" />
                <Table.Column title=" 名称" dataIndex="" key="" />
                <Table.Column title="ﾁｪｯｸ" width={100} dataIndex="" key="" />
              </Table>
            </Col>
            <Col span={4}>
              <Row style={{margin:'0 0 0 0',padding:'2px',height:'55px', justifyContent:'space-around'}}>
                <Input type='text' readOnly style={{ background: '#066bc7', color: '#fff',textAlign:'center' }} value="ｱｲﾃﾑ" />                          
              </Row> 
              <Table bordered 
              style={{margin:'0 5px 0 5px'}}
              >
                <Table.Column title="項目" dataIndex="" key="" />
              </Table>
            </Col>
            <Col span={11}>
              <Row style={{margin:'0 0 0 0',padding:'2px',height:'55px', justifyContent:'space-around'}}>
                <Input type='text' readOnly style={{ background: '#066bc7', color: '#fff',textAlign:'center' }} value="ｵﾌﾟｼｮﾝ情報" />                          
              </Row> 
            
              <Table bordered >
                <Table.Column title="項目" dataIndex="" key="" />
                <Table.Column title="内容" dataIndex="" key="" />
                <Table.Column title="チェック" dataIndex="" key="" />
              </Table>
            </Col>
            </Row>   
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1520001_OptionsItemInfoConfirmingSub);
