import InspectNameConvertInMaintainTaService from 'services/CooperationRelated/InspectItemConvertInternal/InspectNameConvertInMaintainTaService'
import { message } from "antd";

const InspectNameConvertInMaintainTaAction = {
  index() {
    return InspectNameConvertInMaintainTaService.index()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },

  save(data) {
    return InspectNameConvertInMaintainTaService.save(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(data) {
    return InspectNameConvertInMaintainTaService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

}
export default InspectNameConvertInMaintainTaAction;
