import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Row, Col, Input, Form, Card, Select, Button, Modal, message, InputNumber, Tooltip, Dropdown, Menu } from 'antd'
import { DeleteOutlined, MenuOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons'
import { history } from 'constants/BrowserHistory'
import { getNameZipCodeAction } from 'redux/basicInfo/InsurerInfoMaintain/InsurerInfoMaintain.actions.js'

import axios from 'configs/axios'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0339500_InsurerSupplementaryInfo from './WS0339500_InsurerSupplementaryInfo.jsx'
import WS0339501_InsurerRecordedInfo from './WS0339501_InsurerRecordedInfo.jsx'
import WS0339502_InsurerOfficeInfo from './WS0339502_InsurerOfficeInfo.jsx'
import WS0246001_InsurerInfoSearchQuery from './WS0246001_InsurerInfoSearchQuery.jsx'
import WS0084001_PostCodeSearchEngine from './WS0084001_PostCodeSearchEngine.jsx'
import WS0308017_OrganizationCreateNew from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0308017_OrganizationCreateNew.jsx'
import WS0307008_ContractHistorySub from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx'
import WS0344001_SupplementalInfoSetting from './WS0344001_SupplementalInfoSetting.jsx'
import WS1512001_OptionalInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512001_OptionalInfoMaintain.jsx'
import WSFileManager_FileManager from 'pages/ZZ_Others/CUSTOMIZE_Custom/WSFileManager_FileManager.jsx'
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'

// Form.Itemのラベル幅
const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS0339001_InsurerInfoMaintain extends React.Component {
  static propTypes = {
    Li_InsurerCode: PropTypes.any
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '保険者情報保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      modifyEnabled: true,
      isLoadingInsurerInfo: false,
      isLoadingScreenData: false,
      isLoadingContractInfo: false,

      InsurerCode: null,
      InsurerCodeBefore: null,

      // Startup Data
      screenData: {},
      insurerData: {},
      supplementaryInformation: [],

      FilestatusInsurerInfo: false,
      FilestatusEdit: false,
      KeyEditting: false,
      menuItems: [
        { id: 1, label: 'フォルダ', handleClick: this.eventF7 }
      ],
      menus: [{ id: 1, label: '補足設定', handleClick: this.VariousSetting01 }]
    }
    this.onPressEnterInsurerCode = debounce(this.onPressEnterInsurerCode.bind(this), 200)
    this.onValuesChange = debounce(this.onValuesChange.bind(this), 300)

    this.onFinishFailed = this.onFinishFailed.bind(this)
    this.controlVerificationInsurerCode = this.controlVerificationInsurerCode.bind(this)
    this.loadScreenData = this.loadScreenData.bind(this)
    this.InitialDisplay = this.InitialDisplay.bind(this)
    this.postDataRegister = this.postDataRegister.bind(this)
    this.loadContractInfo = this.reloadContractInfo.bind(this)
  }

  componentDidMount() {
    this.loadScreenData()
  }

  unblock = history.block(targetLocation => {
    if (this.state.FilestatusEdit) {
      this.onFinish(this.formRef.current.getFieldsValue(), () => {
        this.setState(
          {
            FilestatusEdit: false
          },
          () => {
            history.push(targetLocation.pathname)
          }
        )
      })
      return false
    } else {
      return true
    }
  })

  loadScreenData() {
    this.setState({
      isLoadingScreenData: true
    })
    axios
      .get('/api/insurer-info-maintain/insurer-info-maintain/getScreenData')
      .then(res => {
        this.setState({
          screenData: res.data,
          isLoadingScreenData: false
        })

        if (this.props.insurer_code) {
          this.setState({ InsurerCode: this.props.insurer_code }, () => {
            this.formRef.current.setFieldsValue({
              insurer_code: this.props.insurer_code
            })

            this.loadInsurerInfo(this.props.insurer_code)
          }
          )
        }
      })
      .catch(() => {
        message.error('画面情報の取得にはエラーが発生しました')
      })
  }

  InitialDisplay() {
    const formInstance = this.formRef.current
    const InsurerCode = formInstance.getFieldValue('insurer_code')

    formInstance.resetFields()
    formInstance.setFieldsValue({
      insurer_code: InsurerCode,
      insurer_number: InsurerCode,
    })

    this.setState({
      modifyEnabled: true,
      isLoadingInsurerInfo: false,
      insurerData: {},
      InsurerCodeBefore: InsurerCode,
      FilestatusEdit: false,
      FilestatusInsurerInfo: false
    })
  }

  loadInsurerInfo(id, type = 0) {
    this.setState({
      isLoadingInsurerInfo: true,
      modifyEnabled: false
    })
    axios
      .get('/api/insurer-info-maintain/insurer-info-maintain', {
        params: {
          insurer_code: id
        }
      })
      .then(async (res) => {
        const resData = res?.data
        if (!resData || !resData.insurer_code) {
          this.InitialDisplay()
          return
        }

        resData.insurer_number = resData?.insurer_number === 0 ? null : resData.insurer_number

        const formInstance = this.formRef.current
        formInstance.setFieldsValue(resData)

        await this.setState({
          insurerData: resData,
          isLoadingInsurerInfo: false,
          modifyEnabled: true,

          InsurerCodeBefore: resData.insurer_code,
          FilestatusInsurerInfo: true,
          FilestatusEdit: false,
          KeyEditting: false
        })

        if (type === 1) {
          await null
        } else if (type === 2) {
          await this.showWS0339501_InsurerRecordedInfo()
        }
      })
      .catch(error => {
        message.error('エラーが発生しました')
      })
  }

  reloadContractInfo() {
    this.setState(
      {
        childModal: {
          ...this.state.childModal,
          visible: false
        },

        isLoadingContractInfo: true
      },
      () => {
        axios
          .get('/api/insurer-info-maintain/insurer-info-maintain/getContractInfo', {
            params: {
              insurer_code: this.state.insurerData.insurer_code
            }
          })
          .then(res => {
            this.setState({
              insurerData: {
                ...this.state.insurerData,
                StsContract: res.data && res.data.length > 0
              }
            })
          })
          .catch(error => {
            const res = error.response
            if (!res || !res.data || !res.data.message) {
              message.error('エラーが発生しました')
              return
            }

            message.error(res.data.message)
          })
          .finally(() => {
            this.setState({
              isLoadingContractInfo: false
            })
          })
      }
    )
  }

  onValuesChange(changedValues, allValues) {
    // Fix lag
    if (!this.state.FilestatusEdit) {
      if (!changedValues.insurer_code && changedValues.insurer_code !== 0) {
        this.setState({
          FilestatusEdit: true
        })
      }
    }
  }

  onFinish() {
    const { onFinishScreen } = this.props

    let values = this.formRef.current?.getFieldsValue()

    if (this.state.FilestatusEdit) {
      Modal.confirm({
        content: this.state.FilestatusInsurerInfo
          ? '上書き登録します。よろしいですか？'
          : '新規登録します。よろしいですか？',

        onOk: () => {
          let postData = values
          postData = {
            ...postData,
            insurer_supplements: this.state.insurerData.insurer_supplements,
            insurer_specials: this.state.insurerData.insurer_specials
          }
          setTimeout(() => {
            if (onFinishScreen) {
              onFinishScreen({
                recordData: values
              })
            }
          })

          return this.postDataRegister(postData)
        },
      })
    } else {
      message.warning('変更がありません')
    }
  }

  async postDataRegister(postData) {
    try {
      const res = await axios.post(
        '/api/insurer-info-maintain/insurer-info-maintain/DataRegister_F12',
        postData
      )
      const resData = res.data
      if (!resData) {
        message.error('エラーが発生しました')
        return
      }

      this.loadInsurerInfo(postData.insurer_code)

      message.success(resData.message)
    } catch (error) {
      this.setState({
        isLoadingInsurerInfo: false
      })

      const res_1 = error.response
      if (!res_1 || !res_1.data || !res_1.data.message) {
        message.error('エラーが発生しました')
        return
      }

      message.error(res_1.data.message)
    }
  }

  onFinishFailed({ values, errorFields, outOfDate }) {
    // UX: back to error field
    for (let i in errorFields) {
      const itm = errorFields[i]
      this.formRef.current.getFieldInstance(itm.name).focus()
      break
    }
  }

  onPressEnterInsurerCode(event) {
    // Check onChange insurer_code
    const newInsurerCode = parseInt(event.target.value)
    if (newInsurerCode !== this.state.InsurerCode) {
      this.setState(
        {
          KeyEditting: true,
          InsurerCode: newInsurerCode
        },
        () => {
          if (!this.state.childModal.visible && !this.state.isLoadingScreenData) {
            this.controlVerificationInsurerCode()
          }
        }
      )
    }
  }

  controlVerificationInsurerCode() {
    if (this.state.KeyEditting) {
      if (!this.state.InsurerCode) {
        this.setState({
          modifyEnabled: true,
          FilestatusEdit: false,
          InsurerCode: this.state.InsurerCode === 0 ? null : this.state.InsurerCode
        })
        return message.error('保険者ｺｰﾄﾞを入力してください')
      }

      if (this.state.FilestatusEdit) {
        Modal.confirm({
          content: '変更があります。登録しますか？',
          onOk: () => {
            const postData = this.formRef.current.getFieldsValue()
            postData.insurer_code = this.state.InsurerCodeBefore

            return this.postDataRegister(postData)
              .then(() => {
                this.loadInsurerInfo(this.state.InsurerCode)
              })
          },
          onCancel: () => {
            this.loadInsurerInfo(this.state.InsurerCode)
          }
        })
      } else {
        this.loadInsurerInfo(this.state.InsurerCode)
      }
    }
  }

  OptionsDisplay = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1512001_OptionalInfoMaintain
            Li_TypeCode={'ﾏｽﾀ関連'}
            Li_optioCode={'V4MS000100'}
            Li_Expansion={2}
          />
        )
      }
    })
  }

  VariousSetting01 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0344001_SupplementalInfoSetting
            Li_IdentifyChar='MAST3'
            onFinishScreen={() => {
              this.loadScreenData()
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false
                }
              })
            }}
          />
        )
      }
    })
  }

  eventF7 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 700,
        component: (
          <WSFileManager_FileManager
            Params_2612={{
              Li_Division: '保険者',
              Li_Identify: MagicXpaFunc.Str(
                MagicXpaFunc.Val(this.formRef?.current?.getFieldValue('insurer_code') || 0, ''),
                '10P0'
              )
            }}
          />
        )
      }
    })
  }

  getNameZipCode = params => {
    getNameZipCodeAction({ ZipCode: params })
      .then(res => {
        if (res?.data) {
          let Location1 = res.data?.Location1 || ''

          let FilestatusEdit = this.state.FilestatusEdit
          this.formRef?.current?.setFieldsValue({ location_1: Location1 })
          FilestatusEdit = true
          this.setState({ FilestatusEdit })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  /**
   * 補足情報 モーダル
   */
  showWS0339500_InsurerSupplementaryInfo = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0339500_InsurerSupplementaryInfo
            insurerCode={this.formRef.current?.getFieldValue('insurer_code')}
            insurerData={this.state.insurerData}
            onFinishScreen={(output) => {
              let newInsurerData = {
                ...this.state.insurerData,
                insurer_supplements: output.supplementaryInfo
              }
              this.setState({
                insurerData: newInsurerData,
                childModal: {
                  ...this.state.childModal,
                  visible: false
                }
              })
              if (!this.state.FilestatusEdit) {
                if (this.formRef.current?.getFieldValue('insurer_code') && this.formRef.current?.getFieldValue('insurer_code') !== 0) {
                  this.setState({
                    FilestatusEdit: true
                  })
                }
              }

              this.loadInsurerInfo(output.insurerCode, 1)

            }}
          />
        )
      }
    })
  }

  /**
   * 記録　モーダル
   */
  showWS0339501_InsurerRecordedInfo = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0339501_InsurerRecordedInfo
            insurerData={this.state.insurerData}
            insurerCode={this.formRef.current?.getFieldValue('insurer_code')}
            onFinishScreen={(output) => {
              this.setState({
                insurerData: output.insurerData,
              })
              if (!this.state.FilestatusEdit) {
                if (this.formRef.current?.getFieldValue('insurer_code') && this.formRef.current?.getFieldValue('insurer_code') !== 0) {
                  this.setState({
                    FilestatusEdit: true
                  })
                }
              }
              this.loadInsurerInfo(output.insurerCode, 2)
            }}
          />
        )
      }
    })
  }

  /**
   * 事業所一覧　モーダル
   */
  showWS0339502_InsurerOfficeInfo = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0339502_InsurerOfficeInfo
            insurerData={this.state.insurerData}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: ''
      }
    })
  }

  render() {
    return (
      <div className='insurer-info-maintenance'>
        <Card title='保険者情報保守'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.insurer_code) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={this.eventF7}
                      disabled={!this.formRef.current?.getFieldValue('insurer_code')}
                    >
                      フォルダ
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.VariousSetting01}>
                      補足設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            onFinishFailed={this.onFinishFailed}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_search' style={{ width: '100%' }}>
                <Form.Item name='insurer_code' style={{ marginBottom: 0 }}>
                  <Input.Search
                    min={0}
                    autoFocus={true}
                    allowClear
                    className='input-search-size-number-allow-clear-10'
                    // onPressEnter={this.onPressEnterInsurerCode}
                    onSearch={(value, event) => {
                      if (event.target.localName !== 'input') {
                        // 虫眼鏡ボタンが押下された時
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS0246001_InsurerInfoSearchQuery
                                Li_insurerSearch={value}
                                onClickedSelect={({ Lo_InsurerCode }) => {
                                  this.formRef.current.setFieldsValue({
                                    insurer_code: Lo_InsurerCode
                                  })

                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false
                                    },

                                    InsurerCode: Lo_InsurerCode
                                  })

                                  this.loadInsurerInfo(Lo_InsurerCode)
                                }}
                              />
                            )
                          }
                        })
                      } else if (value.length) {
                        this.setState({
                          InsurerCode: value
                        })
                        this.loadInsurerInfo(value)
                        return
                      } else {
                        // 初期化
                        this.setState({
                          InsurerCode: null,
                          FilestatusInsurerInfo: false
                        })
                        this.formRef.current?.resetFields()
                      }

                    }}
                  />
                </Form.Item>
                <div className='box_button_bottom_right' style={{ paddingTop: 5 }}>
                  <div>
                    登録日{': '}
                    {this.state.insurerData.registration_date_on &&
                      moment(this.state.insurerData.registration_date_on).isValid() ? (
                      moment(this.state.insurerData.registration_date_on).format('YYYY/MM/DD')
                    ) : (
                      <span style={{ opacity: '0' }}>0000/00/00</span>
                    )}
                  </div>
                  <div>
                    変更日{': '}
                    {this.state.insurerData.updated_on &&
                      moment(this.state.insurerData.updated_on).isValid() ? (
                      moment(this.state.insurerData.updated_on).format('YYYY/MM/DD')
                    ) : (
                      <span style={{ opacity: '0' }}>0000/00/00</span>
                    )}
                  </div>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <div className='box_border' style={{ width: '50%', gap: 0, position: 'relative' }}>
                  <Form.Item
                    label='保険者番号'
                    name='insurer_number'
                    {...smGrid}>
                    <InputNumber style={{ width: '120px' }} />
                  </Form.Item>
                  <Form.Item
                    label='カナ名称'
                    name='insurer_kana_name'
                    {...smGrid}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='漢字名称'
                    name='insurer_kanji_name'
                    {...smGrid}
                  >
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name='representative'
                        label='代表者'
                        {...smGrid}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='office_personnel'
                        label='先方担当'
                        {...smGrid}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name='sales_representative'
                        label='担当営業'
                        {...smGrid}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='status_division'
                        label='状況区分'
                        className='pdLeft-10'
                        {...smGrid}
                      >
                        <Select allowClear>
                          {this.state.screenData.SituationClassify
                            ? this.state.screenData.SituationClassify.map((item, index) => {
                              return (
                                <Select.Option
                                  key={`sts-div-${index}`}
                                  value={item.node_code_name}
                                >
                                  {item.name}
                                </Select.Option>
                              )
                            })
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Tooltip title='補足、記録、事業所'>
                    <Dropdown
                      placement='bottomRight'
                      trigger='click'
                      size='small'
                      disabled={!this.formRef.current?.getFieldValue('insurer_code') ? true : false}
                      overlay={() => {
                        return (
                          <Menu>
                            <Menu.Item
                              key='1' style={{ width: '100%' }}
                              onClick={() => this.showWS0339500_InsurerSupplementaryInfo()}>
                              補足情報
                            </Menu.Item>
                            <Menu.Item
                              key='2' style={{ width: '100%' }}
                              onClick={() => this.showWS0339501_InsurerRecordedInfo()}>
                              記録
                            </Menu.Item>
                            <Menu.Item
                              key='3' style={{ width: '100%' }}
                              onClick={() => this.showWS0339502_InsurerOfficeInfo()}>
                              事業所
                            </Menu.Item>
                          </Menu>
                        )
                      }}
                    >
                      <Button
                        size='small'
                        style={{
                          top: '10px',
                          right: '10px',
                          position: 'absolute'
                        }}
                        icon={<MenuOutlined />}
                      />
                    </Dropdown>
                  </Tooltip>
                </div>

                <div className='box_border' style={{ width: '50%', gap: 0 }}>
                  <Row>
                    <Col span={12}>
                      <Form.Item label='〒' name='postal_code' {...smGrid}>
                        <Input.Search
                          className='input-size-11'
                          maxLength={8}
                          onBlur={e => this.getNameZipCode(e.target.value)}
                          onSearch={() => {
                            // gZoom GL0_郵便番号
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 600,
                                component: (
                                  <WS0084001_PostCodeSearchEngine
                                    onFinishScreen={({ Lio_ZipCode, Lio_Address }) => {
                                      let FilestatusEdit = this.state.FilestatusEdit
                                      const zipcode = Lio_ZipCode ? Lio_ZipCode.trim() : ''
                                      if (zipcode !== '') {
                                        const formInstance = this.formRef.current
                                        formInstance.setFieldsValue({ postal_code: zipcode })
                                        const address = Lio_Address ? Lio_Address.trim() : ''
                                        if (address !== '') {
                                          formInstance.setFieldsValue({ location_1: address })
                                        }

                                        FilestatusEdit = true
                                      }

                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false
                                        },

                                        FilestatusEdit: FilestatusEdit
                                      })
                                    }}
                                  />
                                )
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='area_code' label='地区' {...smGrid}>
                        <Select allowClear>
                          {this.state.screenData.AreaCode
                            ? this.state.screenData.AreaCode.map((item, index) => {
                              return (
                                <Select.Option key={`areacode-${index}`} value={item.area_code}>
                                  {item.district_name}
                                </Select.Option>
                              )
                            })
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label='住所' name='location_1' {...smGrid}>
                    <Input />
                  </Form.Item>

                  <Form.Item label=' ' name='location_2' {...smGrid}>
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <Form.Item label='電話' {...smGrid} name='phone_number' >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='fax' label='FAX' {...smGrid}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label='産業分類' name='industrial_classification' {...smGrid}>
                    <Select allowClear>
                      {this.state.screenData.IndustrialClassify
                        ? this.state.screenData.IndustrialClassify.map((item, index) => {
                          return (
                            <Select.Option
                              key={`ind-clss-${index}`}
                              value={item.node_code_name}
                            >
                              {item.name}
                            </Select.Option>
                          )
                        })
                        : null}
                    </Select>
                  </Form.Item>
                  <Form.Item label='産業医' name='industrial_physician' {...smGrid} style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </div>


            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                disabled={
                  !this.state.FilestatusInsurerInfo ||
                  (this.state.FilestatusInsurerInfo &&
                    this.state.insurerData.offices &&
                    this.state.insurerData.offices.length > 0)
                }
                onClick={() => {
                  Modal.confirm({
                    content: '保険者の情報がすべて削除されます。削除しますか？',
                    onOk: () => {
                      return axios
                        .post(
                          '/api/insurer-info-maintain/insurer-info-maintain/Delete_F11',
                          {
                            insurer_code: this.state.insurerData.insurer_code
                          }
                        )
                        .then(res => {
                          const formInstance = this.formRef.current
                          formInstance.resetFields()

                          this.setState({
                            FilestatusEdit: false,

                            insurerData: {},
                            modifyEnabled: true
                          })
                        })
                        .catch(error => {
                          const res = error.response
                          if (!res || !res.data || !res.data.message) {
                            message.error('エラーが発生しました')
                            return
                          }

                          message.error(res.data.message)
                        })
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>
              <Button
                type='primary'
                disabled={!this.state.FilestatusInsurerInfo}
                loading={this.state.isLoadingContractInfo}
                onClick={() => {
                  this.setState({
                    childModal: this.state.insurerData.StsContract
                      ? {
                        ...this.state.childModal,
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0307008_ContractHistorySub
                            Li_ContractType={1}
                            Li_ContractOrgCode={this.state.insurerData.insurer_code}
                            Li_ContractOrgName={this.state.insurerData.insurer_kanji_name}
                            Li_ContractStartDate={null}
                            onDeleted={historyDate => {
                              this.reloadContractInfo()
                            }}
                          />
                        )
                      }
                      : {
                        ...this.state.childModal,
                        visible: true,
                        width: 300,
                        component: (
                          <WS0308017_OrganizationCreateNew
                            Li_ContractType={1}
                            Li_ContractOrgCode={this.state.insurerData.insurer_code}
                            onCreated={() => this.reloadContractInfo()}
                          />
                        )
                      }
                  })
                }}
              >
                <span className='btn_label'>
                  {this.state.insurerData.StsContract ? '契約変更' : '契約作成'}
                </span>
              </Button>
              <Button
                type='primary'
                onClick={() => this.onFinish()}
                icon={<SaveOutlined />}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0339001_InsurerInfoMaintain)
