import DepartmentChangeOnceService from 'services/basicInfo/DepartmentChangeOnce/DepartmentChangeOnceService'
import { message } from 'antd'

const DepartmentChangeOnceAction = {
  index() {
    return DepartmentChangeOnceService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getDataTableSelect(params) {
    return DepartmentChangeOnceService.getDataTableSelect(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


  changeOfficeInfo(params) {
    return DepartmentChangeOnceService.changeOfficeInfo(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  displayButton(data) {
    return DepartmentChangeOnceService.displayButton(data)
  },



  allSelected(data) {
    return DepartmentChangeOnceService.allSelected(data)
  },

  selectRecord(data) {
    return DepartmentChangeOnceService.selectRecord(data)
  },

  run_F12(data) {
    return DepartmentChangeOnceService.run_F12(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getInsuranceInfoSet(params) {
    return DepartmentChangeOnceService.getInsuranceInfoSet(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  updateInsuranceInfoSet(params) {
    return DepartmentChangeOnceService.updateInsuranceInfoSet(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  update(params) {
    return DepartmentChangeOnceService.update(params)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default DepartmentChangeOnceAction
