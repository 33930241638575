import React from 'react'
import { connect } from 'react-redux'
import WS0270001_InspectItemSearchQueryMultipleChoice from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0270001_InspectItemSearchQueryMultipleChoice.jsx'
import { Dropdown, message, Card, Table, Form, Row, Col, Space, Input, Button, Modal, InputNumber, Menu } from 'antd'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'
import WS0887001_IntroduceLetterVariousMasterInquiry from '../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import WS0924001_IntroduceLetterTargetCmtList from './WS0924001_IntroduceLetterTargetCmtList'
import WS0920010_CopyingProcess from './WS0920010_CopyingProcess'
import ColumnButtonCustom from 'components/Commons/TableColumn'
import FollowUpLetterTargetCmtMaintainAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/FollowUpLetterTargetCmtMaintain.action'
import WS0921003_IntroduceLetterTargetCmtInspectMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0921003_IntroduceLetterTargetCmtInspectMaintain.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { QuestionCircleOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx'

const { TextArea } = Input;

const labelCol = { style: { width: '100px' } }

class FollowUpLetterTargetCmtEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '経過手紙対象コメント保守'

    this.state = {
      rowSelected: [],
      rowSelected2: [],
      selectedRowKeys: [],
      selectedRowKeys2: [],
      indexTable: 0,
      indexTable2: 0,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      search_key: '',
      remarks: '',
      regional_insurer_group_classifi: null,
      count: 0,
      dataSource: [],
      newFlag: false,
    }
  }

  componentDidMount = () => {
    if (this.props.newFlag === false) {
      this.formRef.current?.setFieldsValue({
        id: this.props.record.id ?? '',
        comment_code: this.props.record.comment_code ?? '',
        FW_comment_content: this.props.record.FW_comment_content ?? '',
        comment_content: this.props.record.comment_content ?? '',
        search_key: this.props.record.search_key ?? '',
        remarks: this.props.record.remarks ?? '',
        regional_insurer_group_classification: this.props.record.regional_insurer_group_classification ?? '',
        category_name: this.props.record.category_name ?? ''
      })
    }
    this.setState({
      newFlag: this.props.newFlag ?? false
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  onSave = () => {
    let alreadyExist = false
    if (this.state.newFlag === true) {
      this.props.dataSource.forEach((value, index) => {
        if (value.comment_code == this.formRef.current?.getFieldValue('comment_code')) {
          alreadyExist = true
        }
      })
    }
    if (alreadyExist == true) {
      Modal.error({
        width: 300,
        title: '登録済みのコードです'
      })
    } else {
      let params = {
        comment_code: this.formRef.current?.getFieldValue('comment_code'),
        comment_content: this.formRef.current?.getFieldValue('comment_content'),
        search_key: this.formRef.current?.getFieldValue('search_key'),
        remarks: this.formRef.current?.getFieldValue('remarks'),
        regional_insurer_group_classification: this.formRef.current?.getFieldValue('regional_insurer_group_classification')
      }

      FollowUpLetterTargetCmtMaintainAction.saveMaintain(params)
        .then(res => {
          this.props.onFinishScreen()
        })
    }
  }

  onDelete = () => {
    Modal.confirm({
      width: 300,
      title: '削除しますか？',
      icon: <QuestionCircleOutlined />,
      onOk: () => {
        FollowUpLetterTargetCmtMaintainAction.deleteMaintain({ id: this.formRef.current?.getFieldValue('id') })
          .then(res => {
            this.props.onFinishScreen()
          })
      }
    })


  }

  //注意・指導事項検索・照会を開く
  openCautionGuideNotesSearchQuery = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0272001_CautionGuideNotesSearchQuery
            Li_Search={value}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                comment_code: output.Lo_LnkOutCmtCode,
                FW_comment_content: output.Lo_recordData.comment_content
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  openCategorySearchQuerySingle = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0267001_CategorySearchQuerySingle
            Lio_CategoryCode={value}
            onFinishScreen={(output) => {
              // 画面に反映
              this.formRef.current?.setFieldsValue({
                regional_insurer_group_classification: output?.Lio_CategoryCode,
                category_name: output?.Lio_CategoryName,
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='follow-up-letter-target-cmt-edit' style={{ width: 1000 }}>
        <Card title='コメント編集'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Form.Item
                name='comment_code'
                label='コード'
                labelCol={labelCol}
              >
                <Input.Search
                  disabled={this.state.newFlag == false ? true : false}
                  allowClear={true}
                  className='input-search-size-allow-clear-4'
                  onPressEnter={(event) => this.openCautionGuideNotesSearchQuery(event.target.value)}
                  onSearch={(value, event) => {
                    if (event.target.localName === 'input') {
                      this.formRef.current?.setFieldsValue({
                        FW_comment_content: ''
                      })
                      this.forceUpdate()
                    } else {
                      this.openCautionGuideNotesSearchQuery(value)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name='FW_comment_content'
                label='コメント名称'
                labelCol={labelCol}
              >
                <TextArea
                  readOnly
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
                {/* <div>{this.formRef.current?.getFieldValue('FW_comment_content')}</div> */}
              </Form.Item>
              <Form.Item
                name='comment_content'
                label='コメント'
                labelCol={labelCol}
              >
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
              <Form.Item
                label='検索キー'
                name='search_key'
                labelCol={labelCol}
              >
                <Input className='input-size-20' />
              </Form.Item>
              <Form.Item
                label='備考'
                name='remarks'
                labelCol={labelCol}
              >
                <Input className='input-size-30' />
              </Form.Item>
              <div className='box_inner_horizontal'>
                <Form.Item
                  label='地域保健'
                  name='regional_insurer_group_classification'
                  labelCol={labelCol}
                >
                  <Input.Search
                    allowClear={true}
                    className='input-search-size-allow-clear-4'
                    onPressEnter={(event) => this.openCategorySearchQuerySingle(event.target.value)}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.formRef.current?.setFieldsValue({
                          category_name: ''
                        })
                        this.forceUpdate()
                      } else {
                        this.openCategorySearchQuerySingle(value)
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name='category_name'>
                  <div>{this.formRef.current?.getFieldValue('category_name')}</div>
                </Form.Item>
              </div>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  danger
                  icon={<DeleteOutlined />}
                  hidden={this.state.newFlag === true ? true : false}
                  onClick={() => { this.onDelete() }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  disabled={this.formRef.current?.getFieldValue('comment_code') ? false : true}
                  onClick={() => { this.onSave() }}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpLetterTargetCmtEdit)