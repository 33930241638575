import { message } from 'antd'
import FdCreateMunicipalMutualAidAssociationService from 'services/AccountingBusiness/MutualAidAssociationBilling/FdCreateMunicipalMutualAidAssociationService.js'

const FdCreateMunicipalMutualAidAssociationAction = {
  index(data) {
    return FdCreateMunicipalMutualAidAssociationService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  execution(data) {
    return FdCreateMunicipalMutualAidAssociationService.execution(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  downloadCSVFile(data) {
    return FdCreateMunicipalMutualAidAssociationService.downloadCSVFile(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  InquiryIndex(data) {
    return FdCreateMunicipalMutualAidAssociationService.InquiryIndex(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


}
export default FdCreateMunicipalMutualAidAssociationAction
