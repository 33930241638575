import SiteInquiryService from 'services/InspectionMaintenance/RadiographyInspectMaintain/SiteInquiryService'
import { message } from 'antd'

const SiteInquiryAction = {
  index() {
    return SiteInquiryService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  // Run_F12(data) {
  //   return SiteInquiryService.Run_F12(data)
  //     .then((res) => {
  //       return res?.data
  //     })
  //     .catch((err) => {
  //       message.error(err.response.data.message)
  //       console.log(err.response.data.message)
  //     })
  // }
}
export default SiteInquiryAction
