import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Card, Table, Tooltip } from 'antd'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'

class InspectionResultsChangeHistory extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'キー画像'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      valueHistories: []
    }
  }

  componentDidMount = () => {
    let prams = {
      reservationNumber: this.props.reservationNumber,
      examCode: this.props.examCode
    }

    ResultDisplayAction.getResultsChangeHistory(prams)
      .then((res) => {
        this.setState({
          valueHistories: res
        })
      })
  }


  render() {
    return (
      <div className='inspection-results-change-history'>
        <Card title='変更履歴'>
          <Table
            size='small'
            bordered={true}
            pagination={false}
            dataSource={this.state.valueHistories}
            scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
            style={{ cursor: 'pointer' }}
            rowKey={(record) => record.id}
          >
            <Table.Column
              title='更新日時'
              dataIndex='update_date_on'
              render={(text) => (<div>{moment(text).format('YYYY/MM/DD (ddd) HH:mm:ss')}</div>)}
            />

            <Table.Column
              title='更新者'
              width={150}
              dataIndex='changer_name'
            />

            <Table.Column
              title='更新前結果'
              width={100}
              dataIndex='update_before'
            />

            <Table.Column
              title='更新前結果内容'
              width={200}
              dataIndex='update_before_content'
              render={(item, record, index) => (
                <Tooltip style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }} title={record.update_before_content}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 250 }} >
                    {record.update_before_content}
                  </div>
                </Tooltip>
              )}
            />

            <Table.Column
              title='更新後結果'
              width={100}
              dataIndex='update_after'
            />

            <Table.Column
              title='更新後結果内容'
              width={200}
              dataIndex='update_after_content'
              render={(item, record, index) => (
                <Tooltip style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }} title={record.update_after_content}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 250 }} >
                    {record.update_after_content}
                  </div>
                </Tooltip>
              )}
            />
          </Table>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionResultsChangeHistory)
