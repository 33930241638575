import ConditionsConfigurationEditService from "services/Others/ProgressManagement/ConditionsConfigurationEditService";
import { message } from "antd";

const ConditionsConfigurationEditAction = {
  getScreenData(data) {
    return ConditionsConfigurationEditService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  setRegister(data) {
    return ConditionsConfigurationEditService.setRegister(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(data) {
    return ConditionsConfigurationEditService.delete(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};

export default ConditionsConfigurationEditAction;