import React from "react";
import { connect } from "react-redux";

import { Card, Form, Checkbox, Radio, Input, Button, Col, Space, message } from "antd";

import CreateTestForMedicalExamInfoAction from "redux/Others/CreateTestForMedicalExamInfo/CreateTestForMedicalExamInfo.action";

const styleDivTitle = {
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}
const styleFormItem = {
  marginTop: '12px',
  marginLeft: '24px',
}
const mb30 = {
  marginBottom: '30px',
}

class WS2249005_AutoCreate extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '自動作成';

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.formRef?.current?.setFieldsValue({
      ResultValueGeneration: 0,
      SiteDigits: 30,
      FindingsDigits: 60,
      JudgeValueList: 'A,B,C,D,E,F',
    })
  };

  onADecision() {
    this.formRef.current.setFieldsValue({
      JudgeValueList: 'A,B,C,D,E,F',
    })
  }

  on1Decision() {
    this.formRef.current.setFieldsValue({
      JudgeValueList: '1,2,3,4,5,6',
    })
  }

  onFinish(values) {
    this.setState({ isLoading: true })
    let params = {
      Li_CourseLevel: this.props.Li_CourseLevel ?? 0,
      Li_ReserveNum: this.props.Li_ReserveNum ?? 0,
      JudgeValueList: this.formRef.current?.getFieldValue('JudgeValueList') ?? '',
      PersonalChange: this.formRef.current?.getFieldValue('PersonalChange') ?? '',
      SiteDigits: this.formRef.current?.getFieldValue('SiteDigits') ?? 0,
      FindingsDigits: this.formRef.current?.getFieldValue('FindingsDigits') ?? 0,
      ResultValueGeneration: this.formRef.current?.getFieldValue('ResultValueGeneration') ?? 0,
    }

    CreateTestForMedicalExamInfoAction.autoCreate(params)
      .then((res) => {
        console.log(res)
        message.info('自動生成が終了しました');
        this.props.onFinishScreen(res)
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
      <div className="auto-create">
        <Card className="mb-2" title="自動作成">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div style={mb30}>
              <Col span={8}>
                <div style={styleDivTitle}>検査変動</div>
              </Col>
              <Form.Item
                style={styleFormItem}
                name="PersonalChange"
                valuePropName="checked"
              >
                <Checkbox>
                  全検査設定できるようにします
                </Checkbox>
              </Form.Item>
            </div>

            <div style={mb30}>
              <Col span={8}>
                <div style={styleDivTitle}>検査結果</div>
              </Col>
              <Form.Item style={styleFormItem} name="ResultValueGeneration">
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={0}>検査略名を設定します</Radio>
                    <Radio value={1}>実際の値に近い内容を設定します</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>

            <div style={mb30}>
              <Col span={8}>
                <div style={styleDivTitle}>所見結果</div>
              </Col>
              <div style={styleFormItem}>
                <Form.Item name='SiteDigits' label="部位桁数">
                  <Input style={{ width: '40%' }} />
                </Form.Item>
                <Form.Item name='FindingsDigits' label="所見桁数">
                  <Input style={{ width: '40%' }} />
                </Form.Item>
              </div>
            </div>

            <div style={mb30}>
              <Col span={8}>
                <div style={styleDivTitle}>判  定</div>
              </Col>
              <div style={styleFormItem}>
                <Form.Item label="判定切替">
                  <Space>
                    <Button type="primary" onClick={() => this.onADecision()}>
                      A判定～
                    </Button>
                    <Button type="primary" onClick={() => this.on1Decision()}>
                      1判定～
                    </Button>
                  </Space>
                </Form.Item>
                <Form.Item name='JudgeValueList' label="判定一覧">
                  <Input style={{ width: '40%' }} />
                </Form.Item>
              </div>
            </div>

            <Form.Item>
              <Button
                type="primary"
                style={{float:'right'}}
                onClick={() => this.onFinish(false)}
              >
                実  行
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2249005_AutoCreate);
