import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Button, message, Table, Modal, Tooltip, Tabs } from 'antd'
import { PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import FollowUpLetterDetail from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterDetail.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import WS2589011_FormatSelect from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS2589011_FormatSelect.jsx'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'
import FollowUpLetterMaintainAction from 'redux/IntroductionLetter/FollowUpLetter/FollowUpLetterMaintain.action.js'
import FollowUpLetterReturnInfoRegister from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterReturnInfoRegister.jsx'
import moment from 'moment'

class FollowUpLetterMaintain extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = '経過手紙一覧'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceIntroduction: [],
      values: {
        visit_date_on: '',
        receipt_number: '',
        kanji_name: '',
        reservation_number: 0,
        personal_number_id: '',
        course_level: 0,
        pattern_code: '',
      },
      subTitle: '',
      visitDate: '',
      thisVisitDate: '',
      previousMode: false,
      selectedJudgment: 'C3'
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    const params = {
      Li_PersonalNum: this.props.Li_PersonalNum ?? '',
      Li_ReserveNum: this.props.Li_ReserveNum ?? 0
    }
    FollowUpLetterMaintainAction.index(params)
      .then((res) => {
        // this.renderForm(res)

        // 受診者の情報設定
        this.setState({
          values: {
            ...this.state.values,
            visit_date_on: res.visit_date_on,
            receipt_number: res.receipt_number,
            kanji_name: res.kanji_name,
            course_level: res.course_level,
            pattern_code: res.pattern_code,
            personal_number_id: this.props.Li_PersonalNum,
            reservation_number: this.props.Li_ReserveNum
          },
          subTitle: ` [${this.props.Li_PersonalNum ?? ''}] ${res.kanji_name ?? ''}`,
          visitDate: `受診日:${moment(res.visit_date_on).format('YYYY/MM/DD (ddd)')}`,
          thisVisitDate: `受診日:${moment(res.visit_date_on).format('YYYY/MM/DD (ddd)')}`,
        })
        // 経過手紙のデータ取得
        this.getFollowUpList()
      })
  }

  /**
   * 経過手紙のデータ取得
   */
  getFollowUpList = (recordId = null, previousReserveNum = null, previousVisitDate = null) => {
    let params = {
      Li_PersonalNum: this.props.Li_PersonalNum ?? '',
      Li_VisitDate: this.props.Li_VisitDate ?? '',
      Li_ReserveNum: this.props.Li_ReserveNum ?? 0,
      selectedJudgment: this.state.selectedJudgment
    }

    if (previousReserveNum && previousVisitDate) {
      // 前回データを表示する場合
      params = {
        ...params,
        Li_VisitDate: previousVisitDate,
        Li_ReserveNum: previousReserveNum,
        selectedJudgment: this.state.selectedJudgment
      }
    }

    FollowUpLetterMaintainAction.getFollowUpList(params)
      .then((res) => {
        if (res) {
          this.setState({ dataSourceIntroduction: res })

          if (recordId) {
            let record = res.find(x => x.id === recordId)
            if (record) {
              //recordIdと一致するデータが存在する場合、印刷・発行処理に進む
              this.printBefore(record)
            }
          }
        }
      })
  }

  /**
   * 前回に移動 前回分のデータを取得
   */
  getPreviousData = () => {
    const params = {
      Li_PersonalNum: this.props.Li_PersonalNum ?? '',
      Li_VisitDate: this.props.Li_VisitDate ?? '',
      Li_ReserveNum: this.props.Li_ReserveNum ?? 0,
      Li_CourseLevel: this.state.values.course_level ?? 0
    }
    // 前回データがあるかチェック
    FollowUpLetterMaintainAction.getPreviousData(params)
      .then((res) => {
        if (res) {
          // 前回データの経過手紙一覧を取得する
          this.getFollowUpList(null, res.previousReserveNum, res.previousVisitDate)
          this.setState({
            previousMode: true,
            visitDate: `受診日:${moment(res.previousVisitDate).format('YYYY/MM/DD (ddd)')}`
          })
        }
      })
  }


  /**
   * 経過手紙発行（印刷・発行前処理）
   */
  printBefore = (record = null) => {
    // record=null以外の場合 → １行の印刷 : record=nullの場合 → 一括印刷
    FollowUpLetterMaintainAction.printBefore()
      .then((res) => {
        if (res && res.message == 'Call Screen WS0433001') {
          this.showWS0433001_PrinterConfirm(res.variables, record)
        }
        if (res && res.message == 'Call Screen WS2589011') {
          this.showWS2589011_FormatSelect(res.variables, record)
        }
      })
  }

  /**
   * 書式選択　モーダル
   * @param {*} record
   */
  showWS2589011_FormatSelect = (res, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS2589011_FormatSelect
            Lio_PrinterNo={res.Lo_PrinterNo}
            Lio_Preview={res.Lo_Preview}
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                // 印刷・プレビュー前処理
                this.prePrintPreview(res, record, output)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * プリンター確認
   * @param {*} record
   */
  showWS0433001_PrinterConfirm(res, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={res.Li_PreviewSpecifiedValue}
            Li_PrinterNoSpecifiedValue={res.Li_PrinterNoSpecifiedValue}
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                // 印刷・プレビュー前処理
                this.prePrintPreview(res, record, output)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 印刷・プレビュー前処理
   * @param {*} res
   * @param {*} record
   * @param {*} output
   */
  prePrintPreview = (res, record, output) => {
    const params = {
      StsOutput: output.Lo_StsOutput ? 1 : 0,
      Preview: output.Lo_Preview ? 1 : 0,
      PrinterNum: output.Lo_PrinterNo,
      FormatList: output.Lo_FormatList,
      OptionHghp18C0: res.OptionHghp18C0,
      course_level: this.state.values.course_level,
      reservation_number: this.props.Li_ReserveNum ?? 0,
      department_name: record ? record.exam_department_name : '',
      // record=null以外の場合 → １行の印刷 : record=nullの場合 → 一括印刷
      printData: (record) ? [record] : this.state.dataSourceIntroduction,
      selectedJudgment: this.state.selectedJudgment
    }
    if (output.Lo_Preview) {
      // プレビュー
      this.preview(params)
    } else {
      // 印刷
      this.print(params)
    }
  }

  /**
   * プレビュー実行
   */
  preview = (params) => {
    FollowUpLetterMaintainAction.preview(params)
      .then(res => {
        // pdfのObject作成　erviceで{ responseType: "blob" }を設定する必要がある
        let fileURL = URL.createObjectURL(res.data)
        // pdfを別タブで表示
        var fileOpen = window.open(fileURL)

        // データ再取得
        this.getFollowUpList()
      })
      .catch(err => {
        const res = err.response
        if (res.request.responseType === 'blob') {
          res.data.text()
            .then(str => {
              let json = JSON.parse(str);
              message.error(json.message)
            })
        }
        if (err.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          })
        }
      })
  }

  /**
   * 印刷実行
   */
  print = (params) => {
    FollowUpLetterMaintainAction.print(params)
      .then(res => {
        if (res.data === 'true') {
          message.success('印刷が完了しました')

          // データ再取得
          this.getFollowUpList()

        } else {
          message.error('処理の途中でエラーが発生しました')
        }
      }).catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
        if (err.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          })
        }
      })
  }

  /**
   * 経過手紙編集 モーダル
   */
  showFollowUpLetterDetail = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <FollowUpLetterDetail
            record={record}
            values={this.state.values}
            subTitle={this.state.subTitle}
            previousMode={this.state.previousMode}
            selectedJudgment={this.state.selectedJudgment}
            onFinishScreen={() => {
              // データ再取得
              this.getFollowUpList()
              this.closeModal()
            }}
            onPrint={(recordId) => {
              // データ再取得
              this.getFollowUpList(recordId)
              this.closeModal()
            }}
          />,
      },
    })
  }

  /**
   * 返送情報登録 モーダル
   */
  showFollowUpLetterReturnInfoRegister = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <FollowUpLetterReturnInfoRegister
            values={this.props.values}
            record={this.props.record}
            paramObject={this.props.paramObject}
            protectFlag={false}
            changeFlag={false}
            subTitle={this.state.subTitle}
            visitDate={this.state.thisVisitDate}
            onChangeData={(output) => {
              this.setState({
                changeData: output.changeData,
                categoryComment: output.category_comment,
                categoryJudge: output.category_judge,
                categoryJudgmentChangeFlag: output.categoryJudgmentChangeFlag
              })
            }}
          />
      },
    })
  }

  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name)
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-extract-maintain'>
        <Card title={`経過手紙一覧　${this.state.subTitle}　${this.state.visitDate}`}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Tabs
                defaultActiveKey='1'
                size='small'
                onChange={async (key) => {
                  await this.setState({ selectedJudgment: key === '1' ? 'C3' : 'C6' })

                  // 経過手紙のデータ取得
                  this.getFollowUpList()
                }}
              >
                <Tabs.TabPane
                  tab='３ヶ月'
                  key='1'
                >
                  <Table
                    bordered
                    dataSource={this.state.dataSourceIntroduction}
                    scroll={{ y: 400 }}
                    size='small'
                    pagination={false}
                    rowKey={(record) => record.id}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          // 変更
                          this.showFollowUpLetterDetail(record)
                        }
                      }
                    }}
                  >
                    {/* 三愛病院用に「診療科(検査部門)」を追加 */}
                    <Table.Column title='診療科(検査部門)' width={200} dataIndex='exam_department_name' />
                    <Table.Column title='受診科' width={200} dataIndex='department_name' />
                    <Table.Column title='判定医' width={200} dataIndex='doctor_name' />
                    <Table.Column title='指導' dataIndex='comment_content' />
                    <Table.Column title='発行' align='center' width={60} dataIndex='issue'
                      render={(value, record, index) =>
                        <div style={{ textAlign: 'center', color: Color(record.issueColorNum)?.Foreground }}>
                          {value}
                        </div>}
                    />
                    <Table.Column title='返送' align='center' width={60} dataIndex='sendingBack'
                      render={(value, record, index) =>
                        <div style={{ textAlign: 'center', color: Color(record.sendingBackColorNum)?.Foreground }}>
                          {value}
                        </div>
                      }
                    />
                    <Table.Column align='center' width={60}
                      title={
                        <Button
                          size='small'
                          type='primary'
                          icon={<PlusOutlined />}
                          hidden={this.state.previousMode}
                          onClick={() =>
                            // 新規作成
                            this.showFollowUpLetterDetail()
                          }
                        />}
                      render={(value, record) => {
                        return (
                          <Button size='small'
                            onClick={() =>
                              // 詳細
                              this.showFollowUpLetterDetail(record)
                            }
                          >
                            詳細
                          </Button>)
                      }}
                    />

                    {(!this.state.previousMode) ?
                      <Table.Column
                        align='center'
                        width={60}
                        title=''
                        render={(value, record) => {
                          return (
                            <Button size='small'
                              onClick={() =>
                                // 印刷・発行前処理
                                this.printBefore(record)
                              }
                            >
                              印刷
                            </Button>)
                        }}
                      />
                      :
                      // 前回データの場合、非表示
                      null
                    }
                  </Table>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab='６ヶ月'
                  key='2'
                >
                  <Table
                    bordered
                    dataSource={this.state.dataSourceIntroduction}
                    scroll={{ y: 400 }}
                    size='small'
                    pagination={false}
                    rowKey={(record) => record.id}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          // 変更
                          this.showFollowUpLetterDetail(record)
                        }
                      }
                    }}
                  >
                    {/* 三愛病院用に「診療科(検査部門)」を追加 */}
                    <Table.Column title='診療科(検査部門)' width={200} dataIndex='exam_department_name' />
                    <Table.Column title='受診科' width={200} dataIndex='department_name' />
                    <Table.Column title='判定医' width={200} dataIndex='doctor_name' />
                    <Table.Column title='指導' dataIndex='comment_content' />
                    <Table.Column title='発行' align='center' width={60} dataIndex='issue'
                      render={(value, record, index) =>
                        <div style={{ textAlign: 'center', color: Color(record.issueColorNum)?.Foreground }}>
                          {value}
                        </div>}
                    />
                    <Table.Column title='返送' align='center' width={60} dataIndex='sendingBack'
                      render={(value, record, index) =>
                        <div style={{ textAlign: 'center', color: Color(record.sendingBackColorNum)?.Foreground }}>
                          {value}
                        </div>
                      }
                    />
                    <Table.Column align='center' width={60}
                      title={
                        <Button
                          size='small'
                          type='primary'
                          icon={<PlusOutlined />}
                          hidden={this.state.previousMode}
                          onClick={() =>
                            // 新規作成
                            this.showFollowUpLetterDetail()
                          }
                        />}
                      render={(value, record) => {
                        return (
                          <Button size='small'
                            onClick={() =>
                              // 詳細
                              this.showFollowUpLetterDetail(record)
                            }
                          >
                            詳細
                          </Button>)
                      }}
                    />

                    {(!this.state.previousMode) ?
                      <Table.Column
                        align='center'
                        width={60}
                        title=''
                        render={(value, record) => {
                          return (
                            <Button size='small'
                              onClick={() =>
                                // 印刷・発行前処理
                                this.printBefore(record)
                              }
                            >
                              印刷
                            </Button>)
                        }}
                      />
                      :
                      // 前回データの場合、非表示
                      null
                    }
                  </Table>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Form>

          <div className='box_button_bottom_right' >
            <Button
              type='primary'
              hidden={!this.state.previousMode}
              onClick={() => {
                this.setState({ previousMode: false })
                this.getFollowUpList()
              }}
            >
              元に戻る
            </Button>

            <Tooltip title={this.props.historyFlag ? '' : '前回データが存在しません'}>
              <Button
                type='primary'
                disabled={!this.props.historyFlag}
                hidden={this.state.previousMode}
                onClick={() => {
                  this.getPreviousData()
                }}
              >
                前回に移動
              </Button>
            </Tooltip>

            <Button
              type='primary'
              hidden={this.state.previousMode}
              onClick={() => {
                this.showFollowUpLetterReturnInfoRegister()
              }}
            >
              返送情報登録
            </Button>

            <Button
              type='primary'
              icon={<PrinterOutlined />}
              hidden={this.state.previousMode}
              disabled={!(this.state.dataSourceIntroduction.length > 0)}
              onClick={() => {
                // 印刷・発行前処理
                this.printBefore()
              }}
            >
              <span className='btn_label'>
                一括印刷
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpLetterMaintain)
