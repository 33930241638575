import axios from 'configs/axios'

const apiPaths = {
  index: '/api/insurer-info-maintain/supplemental-info-setting/',
  save: '/api/insurer-info-maintain/supplemental-info-setting/save',
  delete: '/api/insurer-info-maintain/supplemental-info-setting/delete',
  // getSelectedRange: '/api/insurer-info-maintain/supplemental-info-setting/select-range',
  // saveSelectRange: '/api/insurer-info-maintain/supplemental-info-setting/save-select-range',
}

const SupplementalInfoSettingService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },

  async save(params) {
    return axios.post(apiPaths.save, params)
  },

  async delete(params) {
    console.log(params);
    return axios.delete(apiPaths.delete, { params })
  },

  // async getSelectedRange(params) {
  //   return axios.get(apiPaths.getSelectedRange, { params })
  // },
  // async saveSelectRangeService(params) {
  //   return axios.post(apiPaths.saveSelectRange, params)
  // },
}

export default SupplementalInfoSettingService
