/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Card, Button, Table, Menu, message, Dropdown, Modal, Tooltip } from 'antd'
import {
  MoreOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
  DeleteOutlined,
} from '@ant-design/icons'

import ReserveStatusSearchService from 'services/ReservationBusiness/ReserveStatusSearch/ReserveStatusSearchService'
import moment from 'moment-timezone'
import Color from 'constants/Color'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'

class ReservationDetails extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '予約状況検索（詳細）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        centered: false,
      },

      dataShowColumnExamineeList: {
        ExamineeList: []
      },
      //selected row left table
      selectedRowRecordReserveStatusDisplay: {},
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.getExamineeList()
  }

  /**
   * 予約詳細一覧を取得
   */
  getExamineeList = () => {
    const params = {
      date: moment(this.props.Li_Date).format('YYYY/MM/DD'),
      // CourseDisplay: this.props.Li_CourseDisplay,
      CourseDisplay: '',
      // InspectDisplay: this.props.Li_InspectDisplay,
      InspectDisplay: '',
      FacilityType: this.props.Li_FacilityType,
      TimeZone: this.props.Li_TimeZone
    }

    ReserveStatusSearchService.getExamineeList(params)
      .then((res) => {
        this.setState({
          dataShowColumnExamineeList: res.data,
        })
      })
      .catch((err) => {
        console.log(err);
        message.error('エラーが発生しました')
      })
  }

  /**
   * 予約の削除
   * @param {*} record 
   */
  deleteReserve = (record) => {
    const params = {
      StsConfirm: true,
      course_level: record.course_level,
      reserve_num: record.reserve_num,
    }
    ReserveStatusSearchService.delete(params)
      .then((res) => {
        message.success(res.data.message)
        let newData = [...this.state.dataShowColumnExamineeList.ExamineeList]
        newData = newData.filter(v => v.id !== record.id)
        this.setState({
          dataShowColumnExamineeList: {
            ...this.state.dataShowColumnExamineeList,
            ExamineeList: newData
          }
        })
        this.props.onFinishScreen()
        // this.onSearchRecreateDisplay()
        // this.getExamineeList({ date: this.state.selectedRowRecordReserveStatusDisplay?.date })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 個人情報照会 モーダル
   * @param {*} record
   */
  showWS2584019_PersonalInfoInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={record.personal_number_id}
            Li_updateFlag={1}
            onFinishScreen={(output) => {
              if (output.updateFlag) {
                this.getExamineeList(this.props.Li_Date)
              }
            }}
          />
        ),
      },
    })
  }

  /**
   * 項目をダブルクリックした時に呼ばれる
   * @param {} record
   */
  showWS2583001_ConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reserve_num}
            Li_patternCode={record.patternCode}
            // onFinishScreen={() => {
            //   this.closeModal()
            // }}
            onFinishScreen={() => {
              //this.closeModal()
              this.getExamineeList(this.props.Li_Date)
              this.props.onFinishScreen()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div className='reserve-details' style={{ width: 1450 }}>
        <Card title={'予約状況 [' + this.props.Li_Date + ']'} style={{ height: '100%' }}>
          <div className='box_container'>
            <div className='box_inner_horizontal' style={{ justifyContent: 'right' }}>
              <div
                style={{
                  color: '#0F3278',
                  fontWeight: 'bold',
                  cursor: 'default',
                  padding: '0 5px',
                }}
                type='text'
              >
                {'男性: '}
                {this.state.dataShowColumnExamineeList.MalePersons
                  ?
                  this.state.dataShowColumnExamineeList.MalePersons + '人'
                  :
                  '0人'
                }
              </div>
              <div
                style={{
                  color: '#B41432',
                  fontWeight: 'bold',
                  cursor: 'default',
                  padding: '0 5px',
                }}
                type='text'
              >
                {'女性: '}
                {this.state.dataShowColumnExamineeList.WomanPersons
                  ?
                  this.state.dataShowColumnExamineeList.WomanPersons + '人'
                  :
                  '0人'
                }
              </div>
            </div>

            <Table
              size='small'
              className='custom-table-scroll'
              dataSource={this.state.dataShowColumnExamineeList.ExamineeList}
              pagination={false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(150) }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    this.showWS2583001_ConsultInquirySub(record)
                  }
                }
              }}
            >
              {parseInt(
                this.state.dataShowColumnExamineeList?.period_time_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-5'
                    title='時間'
                    dataIndex='period_time'
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.receipt_number_bool
              ) === 1 && (
                  <Table.Column
                    title='受付No'
                    className='column-size-10'
                    dataIndex='receipt_number'
                    render={(value) => <div style={{ textAlign: 'right' }}>{value}</div>}
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.State_bool
              ) === 1 && (
                  <Table.Column
                    title='状態'
                    className='column-size-4'
                    dataIndex='State'
                    render={(text, record) => {
                      return (
                        <span
                          style={{
                            color: record.stateColorNum
                              ? Color(record.stateColorNum)?.Foreground
                              : '',
                          }}
                        >
                          {text}
                        </span>
                      )
                    }}
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.personal_number_id_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-10'
                    title='個人番号'
                    dataIndex='personal_number_id'
                    render={(value) => <div style={{ textAlign: 'right' }}>{value}</div>}
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.personal_number_id_bool
              ) === 1 && (
                  <Table.Column
                    title='メモ'
                    dataIndex='importance'
                    className='column-size-4'
                    align='center'
                    render={(text, record) => {
                      const iconSize = 20
                      switch (record.importance) {
                        case 1:
                          return (
                            <InfoCircleOutlined
                              style={{ fontSize: iconSize, color: '#1890ff' }}
                              onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                            />)
                        case 3:
                          return (<WarningOutlined
                            style={{ fontSize: iconSize, color: '#faad14' }}
                            onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                          />)
                        case 5:
                          return (<CloseCircleOutlined
                            style={{ fontSize: iconSize, color: '#ff4d4f' }}
                            onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                          />)
                        default:
                          return (<Button
                            size='small'
                            icon={<MoreOutlined />}
                            onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                          />)
                      }
                    }}
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.KanaName_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-40'
                    title='カナ氏名'
                    dataIndex='KanaName'
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.KanjiName_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-40'
                    title='漢字氏名'
                    dataIndex='KanjiName'
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.Gender_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-4'
                    title='性別'
                    dataIndex='Gender'
                    align='center'
                    render={(text, record, index) => {
                      return (
                        <span
                          style={{
                            color: record.sexColorNum
                              ? Color(record.sexColorNum)?.Foreground
                              : '',
                          }}
                        >
                          {text}
                        </span>
                      )
                    }}
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.Age_bool
              ) === 1 && (
                  <Table.Column
                    title='年齢'
                    className='input-size-number-4'
                    dataIndex='Age'
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.DateOfBirth_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-8'
                    title='生年月日'
                    dataIndex='DateOfBirth'
                    render={(text) => <>{moment(text).format('NNy/MM/DD')}</>}
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.office_code_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-10'
                    title='事業所コード'
                    dataIndex='office_code'
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.office_kanji_name_bool
              ) === 1 && (
                  <Table.Column
                    className='column-size-60'
                    title='事業所名称'
                    dataIndex='office_kanji_name'
                  />
                )}

              {parseInt(
                this.state.dataShowColumnExamineeList?.course_bool
              ) === 1 && (
                  <>
                    <Table.Column
                      title='コース'
                      className='column-size-5'
                      dataIndex='visit_course'
                    />
                    <Table.Column
                      title='コース名称'
                      className='column-size-60'
                      dataIndex='contract_short_name'
                    />
                  </>
                )}

              <Table.Column
                width={100}
                title='状態'
                className='stateFlag'
                dataIndex='state_flag'
                render={(text, record) => {
                  switch (record.state_flag) {
                    case 0:
                      return (<div>{'予約'}</div>)
                    case 1:
                      return (<div>{'受付'}</div>)
                    case 2:
                      return (<div>{'保留'}</div>)
                    case 3:
                      return (<div>{'キャンセル'}</div>)
                  }
                }}
              />

              <Table.Column
                title='電子カルテ'
                dataIndex='miraisSendStatus'
                className='column-size-6'
                render={(text, record) => (
                  <Tooltip title={record.miraisSendStatusTooltip}>
                    <div style={{ textAlign: 'center' }}>{text}</div>
                  </Tooltip>
                )}
              />
              <Table.Column
                className='input-size-number-2'
                title={<PlusCircleFilled style={{ fontSize: 18, color: '#FFC001', background: '#FFFFFF' }} />}
                dataIndex='StsOption'
                align='center'
              />
              <Table.Column
                className='input-size-number-2'
                title={<PlusCircleFilled style={{ fontSize: 18, color: '#0000FF', background: '#FFFFFF' }} />}
                dataIndex='StsAdd'
                align='center'
              />
              <Table.Column
                className='input-size-number-2'
                title={<MinusCircleFilled style={{ fontSize: 18, color: '#FF0000', background: '#FFFFFF' }} />}
                dataIndex='StsUnnecessary'
                align='center'
              />
              <Table.Column
                key='action'
                fixed='right'
                align='center'
                className='input-size-number-2'
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            onClick={() => {
                              this.showWS2583001_ConsultInquirySub(record)
                            }}
                          >
                            <label>照会</label>
                          </Menu.Item>
                          <Menu.Item
                            key='2'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  centered: true,
                                  component: (
                                    <WS2537001_PersonalReserveProcess
                                      Li_Date={this.props.Li_Date}
                                      Li_CourseLevel={record.course_level}
                                      Li_ReserveNum={record.reserve_num}
                                      Li_PersonalNum={
                                        record.personal_number_id
                                      }
                                      Li_Getctxname={''}
                                      Li_ProcessDivision={''}
                                      Li_Option={''}
                                      Li_Child={true}
                                      onFinishScreen={() => {
                                        // this.closeModal()
                                        this.getExamineeList(this.props.Li_Date)
                                        this.props.onFinishScreen()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            <label>変更</label>
                          </Menu.Item>
                          <Menu.Item
                            key='3'
                            hidden={record.state_flag === 1}
                            onClick={() => {
                              Modal.confirm({
                                title: '確認',
                                icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
                                okButtonProps: {
                                  danger: true,
                                  icon: <DeleteOutlined />
                                },
                                content: '予約を削除しますか？',
                                okText: <span className='btn_label'>{'削除'}</span>,
                                cancelText: 'キャンセル',
                                onOk: () => {
                                  this.deleteReserve(record)
                                },
                              })
                            }}
                          >
                            <label>削除</label>
                          </Menu.Item>
                          <Menu.Item
                            key='4'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '40%',
                                  component: (
                                    <WS0650001_DocumentBatchCreateSub
                                      Li_CourseLevel={record.course_level}
                                      Li_ReserveNum={record.reserve_num}
                                      onFinishScreen={() => {
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            {/* WS0650001 */}
                            <label>予約関連</label>
                          </Menu.Item>
                          <Menu.Item
                            key='5'
                            hidden={record.state_flag === 0}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS3020036_CoupledPrintInstruction
                                      Li_CourseLevel={record.course_level}
                                      Li_ReserveNum={record.reserve_num}
                                      // Li_Parameters={this.state.values.Li_Parameters}
                                      onFinishScreen={(obj) => {
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            {/* WS0802001 */}
                            <label>結果印刷</label>
                          </Menu.Item>
                          {/* <Menu.Item
                            key='6'
                            onClick={() => {
                              // this.ExcelDownload(
                              // this.state
                              //   .selectedRowRecordReserveStatusDisplay
                              //   ?.date
                              // )
                            }}
                          >
                            <label>EXCEL</label>
                          </Menu.Item> */}
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />} />
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
export default (ReservationDetails)
