import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Table, Form, Dropdown, message, Button, Space, Menu } from "antd";
import { MoreOutlined } from '@ant-design/icons';
import InitInterviewTargetDetermineProcessAction from 'redux/SpecificMedicalExamination/InitInterviewTargetDetermineProcess/InitInterviewTargetDetermineProcess.actions'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx';
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx';
import WS0061009_CheckYesNoYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061009_CheckYesNoYes.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from 'moment';

class WS2847005_ListProcess extends React.Component {
  static propTypes = {
    conditionPage: PropTypes.any,
    dataInit: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = '一覧処理';

    this.state = {
      tableData: [],
      total: 0,
      current_page: 1,
      conditionData: {},
      isloading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      selectedRow: {},

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  componentDidMount() {
    if (this.props.dataInit) {
      this.setState({
        tableData: this.props.dataInit.data,
        total: this.props.dataInit.total,
        current_page: this.props.dataInit.current_page
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.dataInit) {
        this.setState({
          tableData: this.props.dataInit.data,
          total: this.props.dataInit.total
        })
      }
    }
  }

  getDataByPage(page) {
    this.setState({ isloading: true })
    let data = this.props.conditionPage
    data["page"] = page
    InitInterviewTargetDetermineProcessAction.executeButton(data)
      .then(res => {
        this.setState({
          tableData: res.data
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isloading: false }))
  }

  showPersonalReserveProcess(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            Li_CourseLevel={record.W1_course_level ? record.W1_course_level : null}
            Li_ReserveNum={record.W1_reserve_num ? record.W1_reserve_num : null}
            Li_Child={true}
            onFinishScreen={() => {
            }}
          />),
      },
    })
  }

  showOverallResultDisplayInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: (
          <WS2637001_OverallResultDisplayInput
            Li_CourseLevel={record?.W1_course_level ? record.W1_course_level : null}
            Li_ReserveNum={record?.W1_reserve_num ? record.W1_reserve_num : null}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  showChoose(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 300,
        component: (
          <WS0061009_CheckYesNoYes
            Li_DisplayContent={"確認して下さい!"}
            Li_DisplayMethod={0}
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                InitInterviewTargetDetermineProcessAction.run_f12()
              }
              this.closeModal()
            }}
          />),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    });
  }

  render() {
    return (
      <div className="list-process">
        <Card title="一覧処理">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              bordered
              dataSource={this.state.tableData}
              loading={this.state.isloading}
              size={'small'}
              pagination={this.state.tableData.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ y: 500, x: 'max-content' }}
            >

              <Table.Column
                className="column-size-10"
                showSorterTooltip={false}
                title="受診日"
                dataIndex="W1_consult_date"
                render={(value, record, index) => {
                  return <span>{moment(value).format("YYYY/MM/DD")}</span>
                }} />

              <Table.Column
                className="column-size-5"
                showSorterTooltip={false}
                title="コース"
                dataIndex="W1_visits_courses"
              />

              <Table.Column
                className="column-size-5"
                showSorterTooltip={false}
                title="ＩＤ"
                dataIndex="W1_id_cd"
              />

              <Table.Column
                showSorterTooltip={false}
                title="カナ氏名"
                dataIndex="W1_kana_name"
              />

              <Table.Column
                showSorterTooltip={false}
                title="漢字氏名"
                dataIndex="W1_kanji_name"
              />

              <Table.Column
                className="column-size-4"
                showSorterTooltip={false}
                title="性別"
                dataIndex="Expression_1"
                render={(value, record, index) => (
                  <div style={{ textAlign: "center" }}>{value}</div>
                )}
              />

              <Table.Column
                className="column-size-4"
                showSorterTooltip={false}
                title="続柄"
                dataIndex="Expression_2"
                render={(value, record, index) => (
                  <div style={{ textAlign: "center" }}>{value}</div>
                )}
              />

              <Table.Column
                className="column-size-8"
                showSorterTooltip={false}
                title="生年月日"
                dataIndex="W1_birthday"
                render={(value, record, index) => {
                  return <span>{moment(value).format("YYYY/MM/DD")}</span>
                }} />

              <Table.Column
                className="column-size-4"
                showSorterTooltip={false}
                title="年齢"
                dataIndex="W1_age"
                render={(value, record, index) => (
                  <div style={{ textAlign: "center" }}>{value}</div>
                )}
              />

              <Table.Column
                className="column-size-8"
                showSorterTooltip={false}
                title="保険者"
                dataIndex="W1_insurer_num"
                render={(value, record, index) => (
                  <div style={{ textAlign: "right" }}>{value}</div>
                )}
              />

              <Table.Column
                className="column-size-8"
                showSorterTooltip={false}
                title="保険記号"
                dataIndex="W1_insurer_card_symbol"
                render={(value, record, index) => (
                  <div style={{ textAlign: "right" }}>{value}</div>
                )}
              />

              <Table.Column
                className="column-size-8"
                showSorterTooltip={false}
                title="保険番号"
                dataIndex="W1_insurer_card_num"
                render={(value, record, index) => (
                  <div style={{ textAlign: "right" }}>{value}</div>
                )}
              />

              <Table.Column
                className="column-size-8"
                showSorterTooltip={false}
                title="初回面談"
                dataIndex="W1_hierarchy"
              />

              <Table.Column
                width={40}
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="追加"
                            onClick={() => this.showPersonalReserveProcess(record)}
                          >追加
                          </Menu.Item>

                          <Menu.Item
                            key="入力"
                            onClick={() => this.showOverallResultDisplayInput(record)}
                          >入力
                          </Menu.Item>

                          <Menu.Item key='実行'
                            onClick={() => this.showChoose(record)}
                          >
                            実行
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2847005_ListProcess);
