import { message } from 'antd'
import NormalValueSettingInputService from 'services/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSettingInputService'
const NormalValueSettingInputAction = {


  onSave(data) {
    console.log(data)
    return NormalValueSettingInputService.onSave(data)
      .then((res) => {
        console.log(res)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },



}
export default NormalValueSettingInputAction