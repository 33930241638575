
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Card, Form, Input, InputNumber, message, Radio, Button, Checkbox, Table, Modal, Select, Col } from "antd";
import { SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, InfoOutlined, PrinterOutlined } from "@ant-design/icons";
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx";
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery';
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery';
import WS1562001_FormSelect from 'pages/UT_UserTools/V4UT0001000_LabelPrint/WS1562001_FormSelect';
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import AddressLabel_PersonalActions from 'redux/UserTools/LabelPrint/AddressLabel_Personal.actions';
import NumberFormat from 'components/Commons/NumberFormat'
import ModalDraggable from "components/Commons/ModalDraggable";

const today = moment(new Date().toISOString())

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}
class WS1552001_AddressLabel_Personal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    // // document.title = '住所ラベル[個人]';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      selectedRowTableFirst: [],
      isLoadingForm: true,
      isLoadingTable: false,
      dataSource: [], // targetList
      selectedRowKeys: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      ReserveDateF: "",
      ReserveDateT: "",
      ExtractDivision: 0,
      Indent: "",
      Text: "",
      rowSelect: {},
      listID: [],
      StsSelectOne: false,
      W1_urgent_specimen_flg: false,
      GfacilitiesNum: [],
      institutionItem: [],
      institution: 0,
      destinationItem: [],
      destinationCode: 0,
      specifyDestination: 0,
      option_normal: '',
    };
  }

  componentDidMount() {
    this.getScreenDataAction();
    this.formRef.current.setFieldsValue({
      ReserveDateF: today.format('YYYY/MM/DD'),
      ReserveDateT: today.format('YYYY/MM/DD'),
      specifyDestination: 0
    })
  }

  getScreenDataAction() {
    this.setState({ isLoadingForm: true });
    //コンボボックスの一覧を取得
    AddressLabel_PersonalActions.getScreenData()
      .then((res) => {
        // let data = res ? res : [];
        let institutionItems = res.data.institutionItem;
        let destinationItems = res.data.destinationItem;
        this.setState({
          ExtractDivision: res.data.ExtractDivision,
          option_normal: res.data.option_normal,
          institutionItem: institutionItems,
          institution: institutionItems[0] ? institutionItems[0].facility_type : '',
          destinationItem: destinationItems,
          destinationCode: destinationItems[0] ? destinationItems[0].document_management_number : '',
        });
        this.formRef.current.setFieldsValue({
          institution: institutionItems[0] ? institutionItems[0].facility_type : '',
          destinationCode: destinationItems[0] ? destinationItems[0].document_management_number : '',
        })
      })
      .finally(() => this.setState({ isLoadingForm: false }));
  }

  //検索ボタン
  displayBtn() {
    const data = {
      ReserveDateF: moment(this.formRef.current?.getFieldValue('ReserveDateF'))?.format('YYYY/MM/DD'),
      ReserveDateT: moment(this.formRef.current?.getFieldValue('ReserveDateT'))?.format('YYYY/MM/DD'),
      institution: this.formRef.current?.getFieldValue("institution"),
      acceptStatus: this.formRef.current?.getFieldValue("acceptStatus"),
      insurerCode: this.formRef.current?.getFieldValue("insurerCode"),
      officeCode: this.formRef.current?.getFieldValue("officeCode"),
      branchStore: this.formRef.current?.getFieldValue("branchStore"),
      courseF: this.formRef.current?.getFieldValue("courseF"),
      courseT: this.formRef.current?.getFieldValue("courseT"),
      receptionNumberF: this.formRef.current?.getFieldValue("receptionNumberF"),
      receptionNumberT: this.formRef.current?.getFieldValue("receptionNumberT"),
      specifyDestination: this.formRef.current?.getFieldValue("specifyDestination"),
      destinationCode: this.formRef.current?.getFieldValue("destinationCode"),
      requierSecondVisit: this.formRef.current?.getFieldValue("requierSecondVisit") ? 1 : 0,
      option_normal: this.state.option_normal,
    };
    AddressLabel_PersonalActions.displayBtn(data)
      .then((res) => {
        let arrTemp = res.data.filter(x => x.stsPrintFlag === true).map(item => item.id)
        this.setState({
          dataSource: res.data,
          selectedRowKeys: arrTemp,
        })
        this.formRef.current.setFieldsValue({
          tableData: res.data
        })
      })
      .catch((error) => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
      });
  }

  showWS2584019_PersonalInfoInquirySub = (personalNum) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personalNum}
          />
        ),
      },
    })
  }

  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  ChangeCheck(id, value) {
    let array = this.state.dataSource;
    let Index = array.findIndex(item => item.id === id)

    array[Index].W1_urgent_specimen_flg = value;
    this.setState({ dataSource: array });
  }

  onChangeValue = (value, record, index) => {
    let arrTemp = [...this.state.dataSource]
    // 内容を反映
    let obj = {
      ...record,
      copies: NumberFormat.changeHankaku(value), //変更内容
    }
    arrTemp[index] = obj

    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
    })
  }

  print_f12 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PrinterNoSpecifiedValue={0}
            Lo_Preview={false}
            Lo_PrinterNo={0}
            Lo_StsOutput={true}
            onFinishScreen={(res) => {
              this.closeModal()
              if (res.Lo_StsOutput) {
                let values = {
                  previewMode: res.Lo_Preview,
                  printerNumber: res.Lo_PrinterNo
                }
                this.printDataCheck(values)
              }
            }}
          />),
      },
    })
  }

  printDataCheck = (values) => {
    // チェックボックスにチェックがついているデータの一覧を取得
    const selectedIdList = [...this.state.selectedRowKeys]
    const data = [...this.state.dataSource]
    let selectedData = [], obj
    for (let i = 0; i < data.length; i++) {
      obj = { ...data[i] }
      for (let j = 0; j < selectedIdList.length; j++) {
        if (obj.id === selectedIdList[j]) {
          selectedData.push(obj)
          break
        }
      }
    }

    let params = {
      selectedData: selectedData,
      option_normal: this.state.option_normal,
      previewMode: values.previewMode ? 1 : 0,
      printerNumber: values.printerNumber,
      line: this.formRef.current.getFieldValue('line'),
      column: this.formRef.current.getFieldValue('column'),
      formatNumber: this.formRef.current.getFieldValue('formatNumber'),
    }

    //エラーチェック（returnのタイプがblobのため特殊処理）
    AddressLabel_PersonalActions.errorCheck(params)
      //メッセージがあればエラーとして表示
      .then(res => {
        if (res.data.message !== '') {
          message.error(res.data.message)
          //メッセージが無ければ印刷処理
        } else {
          this.printAction(params)
        }
      })
  }

  printAction = (params) => {
    if (params.previewMode) {
      // プレビューのAPI
      AddressLabel_PersonalActions.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
        })
        .catch(err => {
          const res = err.response;
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          message.error(res.data.message);
        });
    } else {
      // printのAPI
      AddressLabel_PersonalActions.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
        .catch(err => {
          const res = err.response;
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          message.error(res.data.message);
        });
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="address-label-personal">
        <Card title='住所ラベル[個人]'>
          <Form
            {...grid}
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
            initialValues={{
              ReserveDate: moment(),
              ExtractDivision: this.state.ExtractDivision,
            }}
          >
            <div className="box_container">
              <div className="box_inner_horizontal">
                <div className="box_search">
                  <div className='box_search_inner_border' style={{ minWidth: 400 }}>
                    <div className="box_inner_horizontal">
                      <Form.Item
                        name="ReserveDateF"
                        label="受診日"
                        {...smGrid}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                        />
                      </Form.Item>

                      <Form.Item
                        name="ReserveDateT"
                        label="～"
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                        />
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name='formatNumber'
                        label='様式'
                        {...smGrid}
                      >
                        <Input.Search
                          style={{ width: 120 }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== "input") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 500,
                                  component: (
                                    <WS1562001_FormSelect
                                      onFinishScreen={(res) => {
                                        this.formRef.current.setFieldsValue({
                                          formatNumber: res.recordData.formatNumber,
                                          formatName: res.recordData.formatName,
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }}

                          onChange={(e) => {
                            if (e.target.value == '') {
                              this.formRef.current.setFieldsValue({
                                formatName: '',
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='formatName'
                        label=''
                      >
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name='officeCode'
                        label='事業所'
                        {...smGrid}
                      >
                        <Input.Search
                          className='input-search-size-allow-clear-4'
                          type="text"
                          maxLength={8}
                          allowClear={true}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current.getFieldValue('officeCode')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        officeCode: output.Lio_OfficeCode,
                                        branchStore: output.Lio_BranchStoreCode,
                                        office_kanji_name: output.Lo_Kanji_Name
                                      });
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      });
                                    }}
                                  />
                                ),
                              },
                            });
                          }}

                          onSearch={(value, event) => {
                            if (event.target.localName !== "input") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current.getFieldValue('officeCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          officeCode: output.Lio_OfficeCode,
                                          branchStore: output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }}

                          onChange={(e) => {
                            if (e.target.value == '') {
                              this.formRef.current.setFieldsValue({
                                branchStore: '',
                                office_kanji_name: ''
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='branchStore'
                        label=''
                      >
                        <InputNumber
                          maxLength={5}
                          style={{ width: 60, textAlign: 'right' }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='office_kanji_name'
                        label=''
                      >
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name='insurerCode'
                        label='保険者'
                        {...smGrid}
                      >
                        <Input.Search
                          className='input-search-size-allow-clear-8'
                          type="text"
                          style={{ width: 160 }}
                          maxLength={10}
                          allowClear={true}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        insurerCode: output.Lo_InsurerCode,
                                        insurer_kanji_name: output.Lo_Name
                                      });
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      });
                                    }}
                                  />
                                ),
                              },
                            });
                          }}

                          onSearch={(value, event) => {
                            if (event.target.localName !== "input") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0246001_InsurerInfoSearchQuery
                                      Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          insurerCode: output.Lo_InsurerCode,
                                          insurer_kanji_name: output.Lo_Name
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }}

                          onChange={(e) => {
                            if (e.target.value == '') {
                              this.formRef.current.setFieldsValue({
                                insurer_kanji_name: ''
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='insurer_kanji_name'
                        label=''
                      >
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_inner_border' style={{ minWidth: 330 }}>
                    <Form.Item
                      name='acceptStatus'
                      label='状態'
                      initialValue={9}
                      {...smGrid}
                    >
                      <Select style={{ width: 80 }}>
                        <Select.Option value={9}>全て</Select.Option>
                        <Select.Option value={0}>予約</Select.Option>
                        <Select.Option value={1}>受付</Select.Option>
                        <Select.Option value={2}>保留</Select.Option>
                        <Select.Option value={3}>キャンセル</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="institution"
                      label="施設"
                      style={{ width: 300 }}
                      {...smGrid}
                    >
                      <Select >
                        {this.state.institutionItem?.map(item => (
                          <Select.Option
                            key={item.facility_type}
                            value={item.facility_type}
                          >
                            {item.facility_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name='courseF'
                        label='コース'
                        {...smGrid}
                      >
                        <Input.Search
                          type='text'
                          style={{ textAlign: 'right', width: 90 }}
                          maxLength={3}
                          onSearch={(value, e) => {
                            if (e.key !== "Enter") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '70%',
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({ 'courseF': output.Lo_CourseCode });
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='courseT'
                        label='～'
                      >
                        <Input.Search
                          type='text'
                          style={{ textAlign: 'right', width: 90 }}
                          maxLength={3}
                          onSearch={(value, e) => {
                            if (e.key !== "Enter") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '70%',
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({ 'courseT': output.Lo_CourseCode });
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name='receptionNumberF'
                        label='受付No.'
                        {...smGrid}
                      >
                        <InputNumber
                          style={{ textAlign: 'right', width: 70 }}
                          maxLength={5}
                        />
                      </Form.Item>

                      <Form.Item
                        name='receptionNumberT'
                        label='～'
                      >
                        <InputNumber
                          style={{ textAlign: 'right', width: 70 }}
                          maxLength={5}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="box_search_inner" style={{ minWidth: 100 }}>
                    <div className="box_inner_horizontal">
                      {/*行 */}
                      <Form.Item
                        name='line'
                        label='印刷開始行'
                        initialValue={1}
                        {...smGrid}
                      >
                        <InputNumber
                          style={{ textAlign: 'right', width: 40, marginBottom: 0 }}
                          maxLength={2}
                        />
                      </Form.Item>

                      {/*列 */}
                      <Col span={12}>
                        <Form.Item
                          name='column'
                          label='列'
                          initialValue={1}
                        >
                          <InputNumber
                            style={{ textAlign: 'right', width: 40, marginBottom: 0 }}
                            maxLength={2}
                          />
                        </Form.Item>
                      </Col>
                    </div>

                    <Form.Item
                      name="specifyDestination"
                      label="送付先"
                      {...smGrid}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          this.setState({ specifyDestination: e.target.value })
                        }}
                      >
                        <Radio value={0}>指定なし</Radio>
                        <Radio value={1}>指定あり</Radio>
                      </Radio.Group>
                    </Form.Item>

                    {this.state.specifyDestination == 1 ?
                      <Form.Item
                        name="destinationCode"
                        label="送付先資料"
                        style={{ width: 260 }}
                        {...smGrid}
                      >
                        <Select >
                          {this.state.destinationItem?.map(item => (
                            <Select.Option
                              key={item.document_management_number}
                              value={item.document_management_number}
                            >
                              {item.document_title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      :
                      <Form.Item
                        name="test"
                        label="送付先資料"
                        style={{ width: 260 }}
                        {...smGrid}
                      >
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    }

                    <Form.Item
                      label="要二次"
                      name="requierSecondVisit"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}{...smGrid}
                    >
                      <Checkbox>　</Checkbox>
                    </Form.Item>
                  </div>
                </div>

                <div className="box_search_btn">
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => this.displayBtn()}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                pagination={false}
                scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected) => {
                    // 選択
                    this.onSelect(record, selected)
                  },
                  onSelectAll: (selected) => {
                    // 全選択
                    this.onSelectAll(selected)
                  }
                }}
              >
                <Table.Column
                  title="部数"
                  dataIndex="copies"
                  className="column-size-5"
                  render={(value, record, index) => (
                    <Form.Item style={{ marginBottom: '0' }} name={['tableData', index, 'copies']}>
                      <Input
                        className='input-size-number-2'
                        value={record.copies}
                        maxLength={2}
                        onBlur={(e) => this.onChangeValue(e.target.value, record, index)}
                        onPressEnter={(e) => this.onChangeValue(e.target.value, record, index)}
                      />
                    </Form.Item>
                  )}
                />

                <Table.Column
                  className='column-size-date'
                  title="受診日"
                  dataIndex="visit_date_on"
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD(ddd)')}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title="受付No."
                  dataIndex="receipt_number"
                  className="column-size-5"
                  render={(value) => (
                    <div style={{ textAlign: "right" }}>{value}</div>
                  )}
                />

                <Table.Column
                  title="個人番号"
                  dataIndex="personal_number_id"
                  className="column-size-10"
                  render={(value) => (
                    <div style={{ textAlign: "right" }}>{value}</div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="メモ"
                  dataIndex="PS_importance"
                  render={(value, record, index) => {
                    let icon = "";
                    switch (record.importance) {
                      case 1: icon = (<InfoCircleOutlined style={{ fontSize: 20, color: "#1890ff" }} />);
                        break;
                      case 3: icon = (<WarningOutlined style={{ fontSize: 20, color: "#faad14" }} />);
                        break;
                      case 5: icon = (<CloseCircleOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />);
                        break;
                      default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>);
                    }
                    return (
                      <div style={{ textAlign: "center", cursor: 'pointer' }}
                        hidden={!record.personal_number_id}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '90%',
                              component: (
                                <WS2584019_PersonalInfoInquirySub
                                  Li_PersonalNum={record.personal_number_id}
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      >
                        {icon}
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="氏名"
                  dataIndex="kanji_name"
                  className="column-size-30"
                />

                <Table.Column
                  title="性別"
                  width={40}
                  dataIndex="genderName"
                  className="column-size-4"
                  render={(res) => {
                    if (res === "女性") {
                      return <div style={{ color: "red", textAlign: 'center' }}>{res}</div>;
                    } else {
                      return <div style={{ color: "blue", textAlign: 'center' }}>{res}</div>;
                    }
                  }}
                />

                <Table.Column
                  className='column-size-60'
                  title="事業所"
                  dataIndex="office_kanji_name"
                />

                <Table.Column
                  className='column-size-10'
                  title=""
                  dataIndex="addressNumber"
                />

                <Table.Column
                  className='column-size-60'
                  title="送付先"
                  dataIndex="address_1"
                />

                <Table.Column
                  className='column-size-4'
                  title="コース"
                  dataIndex="visit_course"
                />
              </Table>

              <div className="box_button_bottom_right">
                <Button
                  icon={<PrinterOutlined />}
                  onClick={() => this.print_f12()}
                  type="primary"
                  style={{ float: "right" }}
                >
                  <span className='btn_label'>
                    印刷
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1552001_AddressLabel_Personal);
