import { message } from 'antd'
import FindingsInputNormalService from 'services/InputBusiness/SpreadInput/FindingsInputNormalService'

const FindingsInputNormalAction = {
  index(data) {
    return FindingsInputNormalService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getSiteFindingsInputThisTime(data) {
    return FindingsInputNormalService.getSiteFindingsInputThisTime(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getSiteFindingsPrevious(data) {
    return FindingsInputNormalService.getSiteFindingsPrevious(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  save(data) {
    return FindingsInputNormalService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  delete(data) {
    return FindingsInputNormalService.delete(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  setPreviousFindingsDo(data) {
    return FindingsInputNormalService.setPreviousFindingsDo(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}

export default FindingsInputNormalAction
