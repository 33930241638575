import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'

import {
  Card,
  Form,
  Input,
  Radio,
  Select,
  Checkbox,
  Button,
  Table,
  Dropdown,
  Menu,
  InputNumber,
  Modal,
  message,
} from 'antd'
import WS2535011_Verification from './WS2535011_Verification.jsx'
import WS2553003_PeriodTimeInquiry from '../V5YK0001000_ReserveStatusSearch/WS2553003_PeriodTimeInquiry.jsx'
import WS0247001_OfficeInfoRetrievalQuery from '../../BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0605127_ContractLineItemDisplay from '../../BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx'
import WS2585001_OfficeInfoInquirySub from '../V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import WS0289012_ContractInfoInquiry from '../V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx'
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import moment from 'moment'
import FrameReserveService from 'services/ReservationBusiness/FrameReserve/FrameReserveService'
import WS2580001_ScheduleChange from '../V5YK0001000_ReserveStatusSearch/WS2580001_ScheduleChange.jsx'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS2535001_FrameReserve extends React.Component {
  Li_ReserveDate
  static propTypes = {
    Li_ReserveDate: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '枠取予約'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      WakutoAtPage: [],
      isLoading: false,
      OfficeCode: '',
      BranchStoreCode: '',
      office_kanji_name: '',
      Course: '',
      contract_short_name: '',
      recordData: {},
      ContractType: '',
      ContractOrgCode: '',
      ContractStartDate: '',
      ContractNum: '',
      count: 0
    }
  }

  componentDidMount = () => {
    this.setState({ isLoading: true })
    FrameReserveService.getScreenDataService({
      Li_Years: this.props.Li_ReserveDate
        ? moment(this.props.Li_ReserveDate).format('YYYY/MM/DD')
        : moment().format('YYYY/MM/DD'),
      Li_FacilityType: 1,
      Li_TimeDivision: 'AM',
      Li_SkyReal: 0,
      Li_StsAlreadySet: 0
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          ...res.data,
          ReserveDisplayItems: '1',
          WakutoYearsChar: this.props.Li_ReserveDate
            ? moment(this.props.Li_ReserveDate)
            : moment(),
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  getCourse() {
    let params = {
      Course: this.formRef.current?.getFieldValue('Course')
    }
    FrameReserveService.getCourseService(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          ReserveDisplayItems: res.data.toString(),
        })
        this.onChangeFilter()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onChangeFilter = () => {
    this.setState({ isLoading: true })
    const {
      WakutoYearsChar,
      FacilityNum,
      TimeZone,
      ReserveDisplayItems,
      SkyRealWakuto,
      StsFrameAlreadySet,
      Course,
    } = this.formRef.current.getFieldsValue()
    FrameReserveService.getScreenDataService({
      Li_Years: moment(WakutoYearsChar).format('YYYY/MM/DD'),
      Li_FacilityType: FacilityNum,
      Li_TimeDivision: TimeZone <= '12:00' ? 'AM' : 'PM',
      Li_ReserveDisplayItems: ReserveDisplayItems,
      Li_SkyReal: SkyRealWakuto,
      Li_StsAlreadySet: StsFrameAlreadySet,
      Course: Course,
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          Wakuto: res.data.Wakuto,
          StsFrameAlreadySet: false,
        })
        this.forceUpdate()
      })
      .catch((err) => {
        // const res = err.response
        // if (!res || !res.data || !res.data.message) {
        //   message.error('エラーが発生しました')
        //   return
        // }
        // message.error(res.data.message)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  onUpdateChangeWakuto = (indexUpdate) => {
    let reserve = this.formRef.current?.getFieldValue('Wakuto')[indexUpdate].person

    let Wakuto = this.formRef.current
      .getFieldValue('Wakuto')
      .map((item, index) => {
        if (indexUpdate === index) {
          if (reserve) {
            return { ...item, change: 'true' }
          } else {
            return { ...item, change: 'false' }
          }
        }
        return item
      })

    this.formRef.current.setFieldsValue({ Wakuto: Wakuto })

    this.forceUpdate()
  }

  onUpdateTitles = (indexUpdate, title) => {
    let Wakuto = this.formRef.current
      .getFieldValue('Wakuto')
      .map((item, index) => {
        if (indexUpdate === index) {
          return { ...item, title: title }
        }
        return item
      })

    this.formRef.current.setFieldsValue({ Wakuto: Wakuto })
    this.setState({ Wakuto: Wakuto })

    // this.forceUpdate()
  }

  onChangeStsFrameAlreadySet = () => {
    if (this.formRef.current.getFieldValue('StsFrameAlreadySet') === true) {
      this.setState({
        WakutoAtPage: this.formRef.current.getFieldValue('Wakuto'),
      })
      let isWakuto = this.formRef.current.getFieldValue('Wakuto')
        ? this.formRef.current.getFieldValue('Wakuto').filter((item) => {
          if (item.change === 'true') return item
        })
        : []
      this.formRef.current.setFieldsValue({ Wakuto: isWakuto })
    } else {
      this.formRef.current.setFieldsValue({ Wakuto: this.state.WakutoAtPage })
      this.setState({
        WakutoAtPage: [],
      })
    }
    this.forceUpdate()
  }

  checkTimeZone(value = '08:30') {
    var arr = value.split(':')
    if (parseInt(arr[0]) >= 12) {
      if (parseInt(arr[1]) > 0) {
        return 'PM'
      }
    }
    return 'AM'
  }

  onFinish = () => {
    const {
      WakutoYearsChar,
      FacilityNum,
      TimeZone,
      ReserveDisplayItems,
      SkyRealWakuto,
      StsFrameAlreadySet,
      Wakuto,
    } = this.formRef.current.getFieldsValue()
    let isWakuto = Wakuto.filter((item) => {
      if (item.change === 'true') return item
    })

    if (isWakuto.length > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 700,
          component: (
            <WS2535011_Verification
              Li_Years={WakutoYearsChar}
              Li_FacilityType={FacilityNum}
              Li_TimeDivision={TimeZone}
              Li_ReserveDisplayItems={ReserveDisplayItems}
              Li_SkyReal={SkyRealWakuto}
              Li_StsAlreadySet={StsFrameAlreadySet}
              Wakuto={isWakuto}
              Li_recordData={this.state.recordData}
              Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
              Li_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
              Li_ConsultCourse={this.formRef.current.getFieldValue('Course')}
              Li_ContractType={this.state.ContractType}
              Li_ContractOrgCode={this.state.ContractOrgCode}
              Li_ContractStartDate={this.state.ContractStartDate}
              Li_ContractNum={this.state.ContractNum}
              Li_Gender={this.formRef.current?.getFieldValue('Gender')}
              onScreenFinish={(data) => {
                if (data.Lo_StsConfirm === true) {
                  this.onChangeFilter()
                }
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: false,
                  },
                })
                this.props.onFinishScreen()
              }}
            />
          ),
        },
      })
    } else {
      Modal.error({
        title: 'Error',
        content: `枠取の人数を設定してください`,
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  editMemo = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2580001_ScheduleChange
            Li_Date={record.date}
            onFinishScreen={(output) => {
              this.onChangeFilter()
              // this.onSearchRecreateDisplay(false, false, record.date)
              // this.closeModal()
            }}
          />
        ),
      },
      isCloseModalLoadReserveStatusDisplayAgain: false,
    })
  }

  render() {
    return (
      <div className='frame-reserve'>
        <Card id="card" title='枠取予約' style={{ minWidth: '1100px' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              Gender: 0,
              SkyRealWakuto: 0,
              StsFrameAlreadySet: 0,
              FacilityNum: 1,
              TimeZone: '08:30'
            }}
          >
            <div className='box_container' id="container">
              <div className='box_search'>
                <div className='box_search_inner_border' style={{ width: 'fit-content' }}>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='OfficeCode'
                      label='事業所'
                    // onChange={() => {
                    //   this.onChangeFilter()
                    //   this.forceUpdate()
                    // }}
                    >
                      <Input.Search
                        allowClear={true}
                        className='input-search-size-allow-clear-4'
                        style={{ textAlign: 'right' }}
                        onPressEnter={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '80%',
                              component: (<WS0247001_OfficeInfoRetrievalQuery
                                Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                onFinishScreen={(output) => {
                                  this.formRef.current?.setFieldsValue({
                                    OfficeCode: output.Lio_OfficeCode,
                                    BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                    office_kanji_name: output.Lo_Kanji_Name,
                                  })
                                  this.setState({
                                    OfficeCode: output.Lio_OfficeCode,
                                    BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                    office_kanji_name: output.Lo_Kanji_Name,
                                  })
                                  this.closeModal()
                                }}
                              />)
                            }
                          })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              BranchStoreCode: '',
                              office_kanji_name: '',
                            })
                            this.setState({
                              OfficeCode: '',
                              BranchStoreCode: '',
                              office_kanji_name: '',
                            })
                          } else {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (<WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                  Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current?.setFieldsValue({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.setState({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                              }
                            })
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='BranchStoreCode' label=''>
                      {this.state.OfficeCode ?
                        <Input.Search
                          className='input-search-size-4'
                          type='number'
                          style={{ textAlign: 'right' }}
                          // min={0}
                          // maxLength={5}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (<WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                  Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current?.setFieldsValue({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.setState({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                              }
                            })
                          }}
                        />
                        :
                        <InputNumber
                          className='input-search-size-4'
                          readOnly
                        />
                      }
                    </Form.Item>
                    <Button
                      disabled={
                        this.formRef.current?.getFieldValue('OfficeCode')
                          ? this.formRef.current.getFieldValue('OfficeCode')
                            ? false
                            : true
                          : true
                      }
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 700,
                            component: (
                              <WS2585001_OfficeInfoInquirySub
                                Li_OfficeCode={this.formRef.current?.getFieldValue(
                                  'OfficeCode'
                                )}
                                Li_BranchCode={this.formRef.current.getFieldValue(
                                  'BranchStoreCode'
                                )}
                                onFinishScreen={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    ></Button>

                    <Form.Item name='office_kanji_name' style={{ marginRight: '10px' }}>
                      <div>{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                    </Form.Item>
                  </div>

                  <Form.Item name='WakutoYearsChar' label='年　月'>
                    {/* <Input type='text' style={{width : '100px'}} /> */}
                    <VenusDatePickerCustom formRefDatePicker={this.formRef}
                      onChange={() => this.onChangeFilter()}
                      picker='month'
                      format='YYYY年MM月'
                      allowClear={false}
                    />
                  </Form.Item>
                  <Form.Item name='TimeZone' label='時間帯'>
                    <Input
                      type='text'
                      autoComplete='off'
                      // readOnly
                      style={{ width: '70px' }}
                      onDoubleClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 400,
                            component: (
                              <WS2553003_PeriodTimeInquiry
                                onFinishScreen={(obj) => {
                                  this.formRef.current.setFieldsValue({
                                    TimeZone: obj.Lio_TimeZone,
                                  })
                                  this.onChangeFilter()
                                  this.forceUpdate()
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    />
                  </Form.Item>
                </div>
                <div className='box_search_inner_border' style={{ width: 'fit-content' }}>
                  <Form.Item name='FacilityNum' label='施　設' style={{ width: 'fit-content' }}>
                    <Select onChange={() => this.onChangeFilter()} style={{ width: '150px' }}>
                      {this.formRef.current
                        ? this.formRef.current.getFieldValue(
                          'SelectListFacilityNum'
                        )
                          ? this.formRef.current
                            .getFieldValue('SelectListFacilityNum')
                            .map((item, index) => {
                              return (
                                <Select.Option
                                  value={item.facility_type}
                                  key={index}
                                >
                                  {item.facility_name}
                                </Select.Option>
                              )
                            })
                          : null
                        : null}
                    </Select>
                  </Form.Item>

                  <Form.Item name='Gender' label='性　別' style={{ width: 'fit-content' }}>
                    <Select
                      onChange={() => this.onChangeFilter()}
                      style={{ width: '150px' }}
                    >
                      <Select.Option value={0}> </Select.Option>
                      <Select.Option value={1}>男性</Select.Option>
                      <Select.Option value={2}>女性</Select.Option>
                    </Select>
                  </Form.Item>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='Course'
                      label='コース'
                    // rules={[{ required: true }]}
                    // style={{ marginLeft: '-10px' }}
                    >
                      <Input.Search
                        type='text'
                        className='input-size-10'
                        allowClear={true}
                        onPressEnter={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component: (
                                < WS0289012_ContractInfoInquiry
                                  Li_State={0}
                                  Li_EffectiveLimitedDisplay={1}
                                  Lio_ConsultCourse={this.formRef.current.getFieldValue('Course') ?? ''}
                                  Li_OfficeCode={this.formRef.current.getFieldValue('OfficeCode') ?? ''}
                                  Li_BranchStoreCode={this.formRef.current.getFieldValue('BranchStoreCode') ?? 0}
                                  Li_Date={moment(this.formRef.current.getFieldValue('WakutoYearsChar')).format('YYYY/MM/01')}
                                  Li_Gender={this.formRef.current.getFieldValue('Gender') ?? 0}
                                  Li_DateBirth={''}
                                  Li_Relationship={''}
                                  Li_HospitalOut={1}
                                  Li_Am_Pm={this.checkTimeZone(this.formRef.current.getFieldValue('TimeZone'))}
                                  Li_NTsugikenmi={''}
                                  Li_Other={''}
                                  Lio_ContractType={0}
                                  Lio_ContractOrgCode={''}
                                  Lio_ContractStartDate={''}
                                  Lio_ContractNum={0}
                                  Lo_Status={0}
                                  Lo_ErrorMessage={''}
                                  onFinishScreen={({
                                    Lio_ConsultCourse,
                                    Lo_ContractType,
                                    Lo_ContractOrgCode,
                                    Lo_ContractStartDate,
                                    Lo_ContractNum,
                                    recordData
                                  }) => {
                                    this.setState({
                                      ContractType: Lo_ContractType,
                                      ContractOrgCode: Lo_ContractOrgCode,
                                      ContractStartDate: Lo_ContractStartDate,
                                      ContractNum: Lo_ContractNum,
                                      recordData: recordData
                                    })
                                    const formInstance = this.formRef.current
                                    formInstance.setFieldsValue({
                                      Course: Lio_ConsultCourse,
                                      contract_short_name: recordData.W1_contract_short_name,
                                    })
                                    this.setState({
                                      Course: Lio_ConsultCourse,
                                      contract_short_name: recordData.W1_contract_short_name,
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                    // this.onChangeFilter()
                                    // this.forceUpdate()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  < WS0289012_ContractInfoInquiry
                                    Li_State={0}
                                    Li_EffectiveLimitedDisplay={1}
                                    Lio_ConsultCourse={this.formRef.current.getFieldValue('Course') ?? ''}
                                    Li_OfficeCode={this.formRef.current.getFieldValue('OfficeCode') ?? ''}
                                    Li_BranchStoreCode={this.formRef.current.getFieldValue('BranchStoreCode') ?? 0}
                                    Li_Date={moment(this.formRef.current.getFieldValue('WakutoYearsChar')).format('YYYY/MM/01')}
                                    Li_Gender={this.formRef.current.getFieldValue('Gender') ?? 0}
                                    Li_DateBirth={''}
                                    Li_Relationship={''}
                                    Li_HospitalOut={1}
                                    Li_Am_Pm={this.checkTimeZone(this.formRef.current.getFieldValue('TimeZone'))}
                                    Li_NTsugikenmi={''}
                                    Li_Other={''}
                                    Lio_ContractType={0}
                                    Lio_ContractOrgCode={''}
                                    Lio_ContractStartDate={''}
                                    Lio_ContractNum={0}
                                    Lo_Status={0}
                                    Lo_ErrorMessage={''}
                                    onFinishScreen={({
                                      Lio_ConsultCourse,
                                      Lo_ContractType,
                                      Lo_ContractOrgCode,
                                      Lo_ContractStartDate,
                                      Lo_ContractNum,
                                      recordData
                                    }) => {
                                      this.setState({
                                        ContractType: Lo_ContractType,
                                        ContractOrgCode: Lo_ContractOrgCode,
                                        ContractStartDate: Lo_ContractStartDate,
                                        ContractNum: Lo_ContractNum,
                                        recordData: recordData
                                      })
                                      const formInstance = this.formRef.current
                                      formInstance.setFieldsValue({
                                        Course: Lio_ConsultCourse,
                                        contract_short_name: recordData.W1_contract_short_name,
                                      })
                                      this.setState({
                                        Course: Lio_ConsultCourse,
                                        contract_short_name: recordData.W1_contract_short_name,
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                      this.getCourse()
                                      // this.onChangeFilter()
                                      // this.forceUpdate()
                                    }}
                                  />
                                ),
                              },
                            })
                          } else {
                            this.setState({
                              Course: ''
                            })
                          }
                        }}
                      />
                    </Form.Item>
                    <Button
                      disabled={
                        this.formRef.current?.getFieldValue('Course')
                          ? this.formRef.current.getFieldValue('Course')
                            ? false
                            : true
                          : true
                      }
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90vw',
                            component: (
                              <WS0605127_ContractLineItemDisplay
                                Li_ContractType={this.state.Li_ContractType}
                                Li_ContractOrgCode={
                                  this.state.Li_ContractOrgCode
                                }
                                Li_ContractStartDate={
                                  this.state.Li_ContractStartDate
                                }
                                Li_ContractNum={this.state.Li_ContractNum}
                                onFinishScreen={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    ></Button>
                    <Form.Item name='contract_short_name' style={{ marginRight: '10px' }}>
                      <div>{this.formRef.current?.getFieldValue('contract_short_name')}</div>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_inner'>
                  <Form.Item name='ReserveDisplayItems' style={{ width: 'fit-content' }}>
                    <Select
                      style={{ width: '150px' }}
                      onChange={() => this.onChangeFilter()}
                    >
                      {this.formRef.current
                        ? this.formRef.current.getFieldValue(
                          'ReserveDisplayItemSelect'
                        )
                          ? this.formRef.current
                            .getFieldValue('ReserveDisplayItemSelect')
                            .map((item, index) => {
                              return (
                                <Select.Option
                                  value={item.ReserveDisplayItemSelect}
                                  key={index}
                                >
                                  {item.ReserveDisplayItem || ' '}
                                </Select.Option>
                              )
                            })
                          : null
                        : null}
                    </Select>
                  </Form.Item>
                  <Form.Item name='SkyRealWakuto' style={{ width: 'fit-content', marginTop: '5px', marginBottom: '10px' }}>
                    <Radio.Group onChange={() => this.onChangeFilter()}>
                      <Radio value={0}>空人数</Radio>
                      <Radio value={1}>実人数</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name='StsFrameAlreadySet'
                    valuePropName='checked'
                    onChange={() => this.onChangeStsFrameAlreadySet()}
                    style={{ width: 'fit-content', marginTop: '5px' }}
                  >
                    <Checkbox>設定済</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <Table
                style={{ marginTop: '10px' }}
                dataSource={
                  this.formRef.current
                    ? this.formRef.current.getFieldValue('Wakuto')
                    : []
                }
                rowKey={(record) => record.Expression_22}
                pagination={false}
                size='small'
                scroll={{
                  y: this.props.modalFlag ? resizableTableScroll(305) : resizableTableScroll(100),
                  x: 'max-content'
                }}
                bordered
              >
                <Table.Column
                  dataIndex='Expression_22'
                  title='日付'
                  width='7%'
                  align='center'
                  // className='column-size-5'
                  render={(text, record, index) => (
                    <span>
                      <Form.Item name={['Wakuto', index, 'date']} hidden>
                        <span>{record.date}</span>
                      </Form.Item>
                      <Form.Item
                        name={['Wakuto', index, 'Expression_22']}
                        hidden
                      >
                        <span>{text}</span>
                      </Form.Item>
                      <Form.Item name={['Wakuto', index, 'Expression_10']}>
                        <span
                          style={{
                            color:
                              record.Expression_10 === '日'
                                ? 'red'
                                : record.Expression_10 === '土'
                                  ? 'blue'
                                  : 'black',
                          }}
                        >
                          {text + ' (' + record.Expression_10 + ')'}
                        </span>
                      </Form.Item>
                    </span>
                  )}
                ></Table.Column>
                <Table.Column
                  dataIndex='Expression_11'
                  title='休'
                  width='7%'
                  // className='column-size-5'
                  align='center'
                  render={(text, record, index) => (
                    <Form.Item name={['Wakuto', index, 'Expression_11']}>
                      <span
                        style={{
                          color: record.Expression_11 === '休' ? 'red' : null,
                        }}
                      >
                        {text}
                      </span>
                    </Form.Item>
                  )}
                ></Table.Column>
                <Table.Column
                  dataIndex='Expression_12'
                  title='合計人数'
                  width='13%'
                  // className='column-size-5'
                  align='center'
                  render={(text, record, index) => {
                    return <div style={{ textAlign: 'right', paddingRight: '35%' }}>{text === '0' || text === 0 ? '' : text}</div>
                  }}
                ></Table.Column>
                <Table.Column
                  dataIndex='Expression_17'
                  title={
                    this.formRef.current
                      ? this.formRef.current.getFieldValue(
                        'SkyRealWakuto'
                      ) === 1
                        ? '実人数'
                        : '空人数'
                      : '空人数'
                  }
                  width='13%'
                  // className='column-size-5'
                  align='center'
                  render={(text, record, index) => {
                    return <div style={{ textAlign: 'right', paddingRight: '35%' }}>{text === '0' || text === 0 ? '' : text}</div>
                  }}
                ></Table.Column>
                <Table.Column
                  dataIndex='person'
                  title='予約人数'
                  // width='13%'
                  className='column-size-10'
                  align='center'
                  render={(text, record, index) => {
                    if (record.person === 0 || record.person === '0') {
                      record.person = ''
                    }
                    return (
                      <span>
                        <Form.Item name={['Wakuto', index, 'change']} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={['Wakuto', index, 'person']}
                          style={{ margin: 0 }}
                        >
                          <Input
                            style={{
                              color:
                                this.formRef.current?.getFieldValue('Wakuto')[index].person > this.formRef.current?.getFieldValue('Wakuto')[index].Expression_17 ? 'white' : 'black',
                              background:
                                this.formRef.current?.getFieldValue('Wakuto')[index].person > this.formRef.current?.getFieldValue('Wakuto')[index].Expression_17 ? 'red' : 'white',
                            }}
                            onChange={() => this.onUpdateChangeWakuto(index)}
                            type='number'
                            min='0'
                            size='small'
                          />
                        </Form.Item>
                      </span>
                    )
                  }}
                ></Table.Column>
                <Table.Column
                  dataIndex='title'
                  title='メモ'
                  // width='40%'
                  className='column-size-30'
                  align='center'
                  render={(text, record, index) => (
                    <Form.Item
                      name={['Wakuto', index, 'title']}
                      style={{ margin: 0 }}
                    >
                      <div>{text}</div>
                      {/* <Input
                        size='small'
                        readOnly
                        onDoubleClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 500,
                              component: (
                                <WS2580001_ScheduleChange
                                  Li_Date={record.date}
                                  onFinishScreen={({ Lo_StsModify }) => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                    this.onChangeFilter()
                                    this.forceUpdate()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      /> */}
                    </Form.Item>
                  )}
                ></Table.Column>
                <Table.Column
                  key='action'
                  fixed='right'
                  className='column-size-5'
                  render={(text, record, index) => {
                    if (record.Expression_3 === '数') return
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='1'
                                onClick={() => {
                                  // for (let idx = 0 idx < this.formRef.current?.getFieldValue('Wakuto').length idx++) {
                                  //   if (this.formRef.current?.getFieldValue('Wakuto')[idx].person !== '') {
                                  //     this.formRef.current?.setFieldsValue({
                                  //       count: this.state.count + 1
                                  //     })
                                  //   }
                                  // }
                                  // if (this.formRef.current?.getFieldValue('count')) {
                                  //   Modal.confirm({
                                  //     title: '入力した予約人数が消去されますがメモを編集しますか？',
                                  //     icon: <ExclamationCircleOutlined />,
                                  //     okText: 'は　い',
                                  //     cancelText: 'いいえ',
                                  //     onOk: () => {
                                  //       this.editMemo(record)
                                  //     }
                                  //   })
                                  // } else {
                                  // this.editMemo(record)
                                  // }
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 500,
                                      component: (
                                        <WS2580001_ScheduleChange
                                          Li_Date={record.date}
                                          wakutoFlag={1}
                                          onFinishScreen={() => { }}
                                          onChangeScreen={(output) => {
                                            this.onUpdateTitles(index, output.title)
                                            // this.onSearchRecreateDisplay(false, false, record.date)
                                            // this.closeModal()
                                          }}
                                        />
                                      ),
                                    },
                                    isCloseModalLoadReserveStatusDisplayAgain: false,
                                  })
                                }}
                              >
                                {/* 2580 */}
                                <label>メモ欄</label>
                              </Menu.Item>
                            </Menu>
                          )
                          }
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />} />
                        </Dropdown>
                      </div>
                    )
                  }}
                />
              </Table>
              <div className='box_button_bottom_right'>
                <Button
                  style={{ float: 'right', margin: '10px' }}
                  type='primary'
                  // htmlType='submit'
                  onClick={() => {
                    if (!this.state.OfficeCode) {
                      Modal.error({
                        title: 'Error',
                        content: `事業所が無効です`,
                      })
                    } else if (!this.state.Course) {
                      Modal.error({
                        title: 'Error',
                        content: `コースを選択してください`,
                      })
                    } else {
                      this.onFinish()
                    }
                  }}
                >
                  確認
                </Button>
              </div>
            </div>
          </Form >
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        </Card >
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2535001_FrameReserve)
