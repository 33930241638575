import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Input, Select, Checkbox, Button, Space, Row, Col, message } from 'antd'
import CalculateFormulaInputAction from 'redux/InspectionMaintenance/InspectValueCalculateAutoMaintain/CalculateFormulaInput.actions'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import { getInspectItemSearchQuerySingleSistAction } from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'

const { TextArea } = Input
const colorBtm = {
  background: '#E1E1E1',
  width: 100,
  colorBn: {
    width: 50,
    height: 26,
    background: '#E1E1E1',
  }
}

const labelCol = {
  labelCol: { style: { width: 70 } },
}

// 左側の検査項目
const leftList = [
  { code: 'A', codeName: 'HJ' },
  { code: 'B', codeName: 'HL' },
  { code: 'C', codeName: 'HN' },
  { code: 'D', codeName: 'HP' },
  { code: 'E', codeName: 'HR' },
]
// 右側の検査項目
const rightList = [
  { code: 'F', codeName: 'HT' },
  { code: 'G', codeName: 'HV' },
  { code: 'H', codeName: 'HX' },
  { code: 'I', codeName: 'HZ' },
  { code: 'J', codeName: 'IB' },
]

class WS2699155_CalculateFormulaInput extends React.Component {
  static propTypes = {
    Li_InspectCode: PropTypes.any,
    Li_Gender: PropTypes.any,
    Lo_Change: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '計算式入力'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      Formula: '',
      timeOutId: null,
      initialValues: {
        SearchChar: '',
        StsUseInspect: 1,
        Type: ''
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.GetListData()
  }

  /**
   * データ取得
   */
  GetListData() {
    if (this.props.Li_RowSelect) {
      let params = {
        exam_item: this.props.Li_RowSelect.exam_item,
        sex: this.props.Li_RowSelect.sex
      }
      CalculateFormulaInputAction.GetListData(params)
        .then(res => {
          this.formRef.current?.setFieldsValue(res)
          this.setState({
            Formula: res?.Formula
          })
        }).catch(error => {
          const res = error.response
          if (!res || res.data || res.data.message) {
            message.error('エラーが発生しました')
            return
          }
        })
    }

  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  showWS0271001_InspectItemSearchQuerySingle(condition, value) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={value}
            onFinishScreen={(output) => {
              this.SetValueByCondition(condition, output)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  GetValueByCondition = (condition) => {
    if (condition === 'A') {
      return this.formRef.current?.getFieldValue('InspectCodeA')
    } else if (condition === 'B') {
      return this.formRef.current?.getFieldValue('InspectCodeB')
    } else if (condition === 'C') {
      return this.formRef.current?.getFieldValue('InspectCodeC')
    } else if (condition === 'D') {
      return this.formRef.current?.getFieldValue('InspectCodeD')
    } else if (condition === 'E') {
      return this.formRef.current?.getFieldValue('InspectCodeE')
    } else if (condition === 'F') {
      return this.formRef.current?.getFieldValue('InspectCodeF')
    } else if (condition === 'G') {
      return this.formRef.current?.getFieldValue('InspectCodeG')
    } else if (condition === 'H') {
      return this.formRef.current?.getFieldValue('InspectCodeH')
    } else if (condition === 'I') {
      return this.formRef.current?.getFieldValue('InspectCodeI')
    } else if (condition === 'J') {
      return this.formRef.current?.getFieldValue('InspectCodeJ')
    } else {
      return this.formRef.current?.getFieldValue('InspectCode')
    }
  }

  SetValueByCondition = (condition, output) => {
    console.log(condition);
    console.log(output);
    if (condition === 'A') {
      this.formRef.current?.setFieldsValue({
        InspectCodeA: output?.recordData?.test_item_code,
        exam_short_name_HJ: output?.recordData?.exam_short_name
      })
    } else if (condition === 'B') {
      this.formRef.current?.setFieldsValue({
        InspectCodeB: output?.recordData?.test_item_code,
        exam_short_name_HL: output?.recordData?.exam_short_name
      })
    } else if (condition === 'C') {
      this.formRef.current?.setFieldsValue({
        InspectCodeC: output?.recordData?.test_item_code,
        exam_short_name_HN: output?.recordData?.exam_short_name
      })
    } else if (condition === 'D') {
      this.formRef.current?.setFieldsValue({
        InspectCodeD: output?.recordData?.test_item_code,
        exam_short_name_HP: output?.recordData?.exam_short_name
      })
    } else if (condition === 'E') {
      this.formRef.current?.setFieldsValue({
        InspectCodeE: output?.recordData?.test_item_code,
        exam_short_name_HR: output?.recordData?.exam_short_name
      })
    } else if (condition === 'F') {
      this.formRef.current?.setFieldsValue({
        InspectCodeF: output?.recordData?.test_item_code,
        exam_short_name_HT: output?.recordData?.exam_short_name
      })
    } else if (condition === 'G') {
      this.formRef.current?.setFieldsValue({
        InspectCodeG: output?.recordData?.test_item_code,
        exam_short_name_HV: output?.recordData?.exam_short_name
      })
    } else if (condition === 'H') {
      this.formRef.current?.setFieldsValue({
        InspectCodeH: output?.recordData?.test_item_code,
        exam_short_name_HX: output?.recordData?.exam_short_name
      })
    } else if (condition === 'I') {
      this.formRef.current?.setFieldsValue({
        InspectCodeI: output?.recordData?.test_item_code,
        exam_short_name_HZ: output?.recordData?.exam_short_name
      })
    } else if (condition === 'J') {
      this.formRef.current?.setFieldsValue({
        InspectCodeJ: output?.recordData?.test_item_code,
        exam_short_name_IB: output?.recordData?.exam_short_name
      })
    } else {
      this.formRef.current?.setFieldsValue({
        InspectCode: output?.recordData?.test_item_code,
        exam_short_name_HH: output?.recordData?.exam_name
      })
    }
    this.forceUpdate()
  }

  setStateFormula(condition) {
    let value = ''
    if (condition === 'A') {
      value = '[検査A]'
    } else if (condition === 'B') {
      value = '[検査B]'
    } else if (condition === 'C') {
      value = '[検査C]'
    } else if (condition === 'D') {
      value = '[検査D]'
    } else if (condition === 'E') {
      value = '[検査E]'
    } else if (condition === 'F') {
      value = '[検査F]'
    } else if (condition === 'G') {
      value = '[検査G]'
    } else if (condition === 'H') {
      value = '[検査H]'
    } else if (condition === 'I') {
      value = '[検査I]'
    } else if (condition === 'J') {
      value = '[検査J]'
    } else if (condition === '年齢') {
      value = '[年齢]'
    } else if (condition === '＋') {
      value = '+'
    } else if (condition === '－') {
      value = '-'
    } else if (condition === '×') {
      value = '*'
    } else if (condition === '÷') {
      value = '/'
    } else if (condition === '累乗') {
      value = '^'
    } else if (condition === '(') {
      value = '('
    } else if (condition === ')') {
      value = ')'
    }
    this.setState((prevState) => {
      let val = (prevState.Formula + value)?.length > 1000 ? (prevState.Formula + value).slice(0, 1000) : prevState.Formula + value
      this.formRef.current?.setFieldsValue({
        Formula: val
      })
      return {
        ...prevState,
        Formula: val
      }
    }, this.forceUpdate())

  }

  /**
   * 登録
   */
  RegisterBtn() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      id: this.props.Li_RowSelect?.id ?? null,
      propsInspectCode: this.props.Li_RowSelect?.exam_item ?? 0,
      propsGender: this.props.Li_RowSelect?.sex ?? 0,
    }

    CalculateFormulaInputAction.RegisterBtn(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  onChangeIn(e, condition) {
    let value = e.target.value
    if (isNaN(value)) {
      let obj = { Lio_InspectItemCode: '' }
      this.SetValueByCondition(condition, obj)
    } else {
      if (value > 8) {
        let obj = { Lio_InspectItemCode: value.slice(0, 8) }
        this.SetValueByCondition(condition, obj)
      }
    }
  }

  // OnBlurIn(condition, value) {
  //   if (!this.isEmpty(value)) {
  //     let item = sessionStorage.getItem('DataItemSearch')
  //     let listData = item ? JSON.parse(item) : []
  //     if (listData.length === 0) {

  //     } else {
  //       let index = 0
  //       for (const obj of listData) {
  //         index++
  //         if (parseInt(value) === parseInt(obj.test_item_code)) {
  //           let objCheck = { Lio_InspectItemCode: value, recordData: obj }
  //           this.SetValueByCondition(condition, objCheck)

  //           return
  //         }
  //         if (index === listData.length) {

  //         }
  //       }
  //     }

  //   }
  // }

  clearExamNameLeft = (index) => {
    switch (index) {
      case 0:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HJ: ''
        })
        break
      case 1:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HL: ''
        })
        break
      case 2:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HN: ''
        })
        break
      case 3:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HP: ''
        })
        break
      case 4:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HR: ''
        })
        break
    }
    this.forceUpdate()
  }

  clearExamNameRight = (index) => {
    switch (index) {
      case 0:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HT: ''
        })
        break
      case 1:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HV: ''
        })
        break
      case 2:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HX: ''
        })
        break
      case 3:
        this.formRef.current?.setFieldsValue({
          exam_short_name_HZ: ''
        })
        break
      case 4:
        this.formRef.current?.setFieldsValue({
          exam_short_name_IB: ''
        })
        break
    }
    this.forceUpdate()
  }

  render() {
    return (
      <div className='calculate-formula-input'>
        <Card title={'計算式入力 [' + ((this.props.Li_RowSelect) ? '修正' : '新規') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{ Gender: '', Rounding: '', StsHandInput: false, UsePresence: false }}
          >
            <div className='box_container'>
              <Space>
                <Form.Item
                  label='検査コード'
                  name='InspectCode'
                  {...labelCol}
                >
                  <Input.Search
                    disabled={this.props.Li_RowSelect}
                    onSearch={() => this.showWS0271001_InspectItemSearchQuerySingle(1)}
                  />
                </Form.Item>
                <Form.Item >
                  <span>{this.formRef.current?.getFieldValue('exam_short_name_HH')}</span>
                </Form.Item>
              </Space>

              <Space>
                <Form.Item
                  label='性別'
                  name='Gender'
                  {...labelCol}
                >
                  <Select style={{ width: '100px' }}>
                    <Select.Option value={0}>共通</Select.Option>
                    <Select.Option value={1}>男性</Select.Option>
                    <Select.Option value={2}>女性</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label='端数処理'
                  name='Rounding'
                  {...labelCol}
                >
                  <Select style={{ width: '150px' }}>
                    <Select.Option value={0}>四捨五入</Select.Option>
                    <Select.Option value={1}>切り捨て</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label='有効'
                  name='UsePresence'
                  valuePropName='checked'
                  {...labelCol}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                  label='入力'
                  name='StsHandInput'
                  valuePropName='checked'
                  {...labelCol}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Space>

              <Form.Item
                label='検査項目'
                {...labelCol}
              >
                <div className='box_inner_horizontal'>
                  <div style={{ width: '50%' }}>
                    {leftList.map((item, index) => (
                      <Space
                        key={'kensa-' + item.code}
                      >
                        <Form.Item
                          name={`InspectCode${item.code}`}
                          label={
                            <Button
                              style={colorBtm.colorBn}
                              onClick={() => { this.setStateFormula(item.code) }} >
                              {item.code}
                            </Button>
                          }>
                          <Input.Search
                            style={{ textAlign: 'right' }}
                            allowClear={true}
                            onChange={(e) => this.onChangeIn(e, item.code)}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.clearExamNameLeft(index)
                              } else {
                                this.showWS0271001_InspectItemSearchQuerySingle(item.code, value)
                              }
                            }} />
                        </Form.Item>
                        <Form.Item >
                          <span>{this.formRef.current?.getFieldValue(`exam_short_name_${item.codeName}`)}</span>
                        </Form.Item>
                      </Space>
                    ))}
                  </div>

                  <div style={{ width: '50%' }}>
                    {rightList.map((item, index) => (
                      <Space
                        key={'kensa-' + item.code}
                      >
                        <Form.Item
                          name={`InspectCode${item.code}`}
                          label={
                            <Button
                              style={colorBtm.colorBn}
                              onClick={() => { this.setStateFormula(item.code) }} >
                              {item.code}
                            </Button>
                          }>
                          <Input.Search
                            style={{ textAlign: 'right' }}
                            allowClear={true}
                            onChange={(e) => this.onChangeIn(e, item.code)}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.clearExamNameRight(index)
                              } else {
                                this.showWS0271001_InspectItemSearchQuerySingle(item.code, value)
                              }
                            }} />
                        </Form.Item>
                        <Form.Item >
                          <span>{this.formRef.current?.getFieldValue(`exam_short_name_${item.codeName}`)}</span>
                        </Form.Item>
                      </Space>
                    ))}
                  </div>
                </div>

                <div className='box_inner_horizontal'>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('年齢')}>年齢</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('＋')}>＋</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('－')}>－</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('×')}>×</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('÷')}>÷</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('累乗')}>累乗</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula('(')}>(</Button>
                  <Button style={colorBtm} onClick={() => this.setStateFormula(')')}>)</Button>
                </div>

              </Form.Item>

              <Form.Item
                label='計算式'
                name='Formula'
                {...labelCol}
              >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={1000}
                  onChange={(e) => {
                    let value = e.target.value
                    if (this.state.timeOutId) {
                      clearTimeout(this.state.timeOutId)
                    }
                    this.setState({
                      timeOutId: setTimeout(() => {
                        this.setState({
                          Formula: value
                        })
                      }, 300)
                    })
                  }} />
              </Form.Item>

              <Form.Item
                label='備考'
                name='Remarks'
                {...labelCol}
                style={{ marginBottom: '0px' }}
              >
                <Input maxLength={100} />
              </Form.Item>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => this.RegisterBtn()}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2699155_CalculateFormulaInput)
