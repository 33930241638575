import React from 'react'
import { connect } from 'react-redux'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Card, Table, Form, Input, Button, Modal, message } from 'antd'
import VenusApiRoutesV2 from 'redux/VenusApiRoutesV2/VenusApiRoutesV2'

class WS0922007_FindingsContentMaster extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '所見内容マスタ'

    this.formRef = React.createRef()
    this.state = {
      isLoadingTable: false,
      pagination: {
        defaultPageSize: 25,
        size: 'small',
      },
      indexTable: 0,
      count: 1001,
      selectedRowKeys: [],
      selectedRows: [],
    }
  }

  componentDidMount() {
    this.getInitValues()
  }

  // 情報取得処理
  getInitValues = () => {
    this.setState({
      isLoadingTable: true
    })
    VenusApiRoutesV2.callApi('API000922007002', this.props)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          mainSource: res ? res : []
        })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }

  // 保存処理
  onFinish = () => {
    let params = this.formRef.current?.getFieldValue('mainSource')
    params = params.map(item => {
      return {
        ...item,
        various_codes: parseInt(item.various_codes),
        id: String(item.id).includes('Test') ? null : item.id
      }
    })

    params = { mainSource: params }
    this.callAPILoadData(params)
  }

  callAPILoadData = (params) => {
    VenusApiRoutesV2.callApi('API000922007010', params)
      .then((res) => {
        this.getInitValues()
        if (res.success) {
          message.success(res.message)
        } else {
          message.error(res)
        }
      })
      .catch(err => { })
  }

  // 新規追加処理
  addRow = () => {
    let data = this.formRef.current?.getFieldValue('mainSource') ? this.formRef.current?.getFieldValue('mainSource') : []
    const { count } = this.state
    const params = {
      id: `Test${count}`,
      isNew: true,
      various_codes: '',
      search_key: '',
      findings_content: '',
      remarks: ''
    }
    let arr = [...data]
    arr.unshift(params)
    this.setState({
      count: count + 1,
      selectedRowKeys: arr.length > 0 ? [arr[0].id] : [],
      selectedRows: arr.length > 1 ? [arr[0]] : [],
      indexTable: 0
    })
    this.setFormFieldValue('mainSource', arr)
    this.renderForm(arr[0])
    this.forceUpdate()
  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }

  renderForm(res) {
    if (res) {
      const keys = Object.keys(res)
      const values = Object.values(res)
      keys.forEach((item, i) => {
        this.setFormFieldValue(`${item}`, values[i])
        if (values[i] == 0) {
          this.setFormFieldValue(`${item}`, '')
        }
      })
    }
  }

  // 削除処理
  deleteData(record) {
    const params = {
      mainSource: [{
        id: record.id,
      }]
    }
    Modal.confirm({
      content: '消去してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        VenusApiRoutesV2.callApi('API000922007011', params)
          .then((res) => {
            this.getInitValues()
            if (res.success) {
              message.success(res.message)
            } else {
              message.error(res)
            }
          }).catch(err => {
            const res = err.response
            if (!res || !res.data || !res.data.message) {
              message.error('エラーが発生しました')
              return
            }
            message.error(res.data.message)
          })
      }
    })
  }

  render() {
    return (
      <div className='findings-content-master'>
        <Card title='所見内容マスタ'>
          <Form
            ref={this.formRef}
          >
            <Table
              bordered
              size='small'
              dataSource={this.formRef.current?.getFieldValue('mainSource') ? this.formRef.current?.getFieldValue('mainSource') : []}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: 450 }}
            >
              <Table.Column
                title='コード'
                width='100px'
                dataIndex='various_codes'
                sorter={(a, b) => a.various_codes - b.various_codes}
                key=''
                render={(value, record, index) => {
                  return (
                    <>
                      <Form.Item name={['mainSource', index, 'id']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={['mainSource', index, 'various_codes']} >
                        <Input disabled={!record.isNew} type='number' />
                      </Form.Item>
                    </>)
                }}
              />

              <Table.Column
                title='検索キー'
                dataIndex='search_key'
                key=''
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['mainSource', index, 'search_key']}>
                      <Input />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='所見内容'
                dataIndex='findings_content'
                key=''
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['mainSource', index, 'findings_content']}>
                      <Input />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='備考'
                dataIndex='remarks'
                key=''
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['mainSource', index, 'remarks']}>
                      <Input />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                key="action"
                width={40}
                title={(
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.addRow()
                    }}
                  />
                )}
                render={(text, record, index) => {
                  return (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.deleteData(record)
                      }}
                    />
                  )
                }}
              />

              {/* {(ColumnButtonCustom({
                addRow: () => this.addRow(), // in FE
                onSaveData: () => this.onSaveInspect(), // Call API
                deleteRow: () => this.removeRow(), // in FE
                deleteData: () => this.deleteData(this.state.selectedRows[0]), // Call API
                dataSource: this.formRef.current?.getFieldValue('mainSource') ? this.formRef.current?.getFieldValue('mainSource') : [],
                tableIndex: this.state.indexTable,
                width: 50,
                hiddenSave: true
              }))} */}
            </Table>

            <div className='box_button_bottom_right'>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.onFinish()
                  }}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0922007_FindingsContentMaster)
