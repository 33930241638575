import React from "react";
import { connect } from "react-redux";
import  ModalDraggable  from "components/Commons/ModalDraggable";

import { Card, Modal, Button, } from "antd";

import ProtectPersonalInfoAction from "redux/Others/ProtectPersonalInfo/ProtectPersonalInfo.action";

class ProtectPersonalInfo extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '個人情報保護';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
    };
  }

  componentDidMount = () => {
  }

  execButton() {
    Modal.confirm({
      content: '個人情報保護対策プログラムを実行しますか',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        this.execution();
      },
      onCancel: () => {
        Modal.info({
          content: 'キャンセルしました',
          okText: 'はい',
        })
      },
      autoFocusButton: 'cancel',
    })
  }

  execution() {
    this.setState({ isLoading: true })
    ProtectPersonalInfoAction.execution()
      .then((res) => {
        console.log(res)
        if (res) {
          Modal.info({
            content: '完了しました',
            okText: 'はい',
          })
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoading: false })
        this.closeModal()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="rising-document-create">
        <Card className="mb-2" title="個人情報保護">
          <div>
            <div style={{margin: '12px 24px'}}>個人情報保護対策プログラム</div>
            <Button
              type='primary'
              style={{marginLeft: '24px'}}
              onClick={() => {
                this.execButton()
              }} >
              <span className='btn_label'>
                実行
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0}}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectPersonalInfo);
