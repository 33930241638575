import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, Table, Modal, } from 'antd'
import { PlusOutlined, MoreOutlined, } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import FindingsInputNormalSelectAction from 'redux/InputBusiness/SpreadInput/FindingsInputNormalSelect.action'
import WS0731002_FindingsInputNormalSelectChange from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0731002_FindingsInputNormalSelectChange'
import Color from 'constants/Color'

// テーブルの高さ
const scrollY = 400
// 前回結果のテーブルの幅
const previousTableWidth = 400

class WS0731001_FindingsInputNormalSelect extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '所見入力[通常選択]'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataForm: {},
      dataSourceThisTime: [],
      dataSourcePrevious: [],
      // judgmentValue: '',
      // judgmentValueColorNum: 0,
      previousDate: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {

    const data = {
      Li_PatternCode: this.props.Li_PatternCode,
      Li_CategoryCode: this.props.Li_CategoryCode,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_JudgeLevel: this.props.Li_JudgeLevel,
      Li_PersonalNum: this.props.Li_PersonalNum,
      Lio_FindingsCategoryChange: this.props.Lio_FindingsCategoryChange ? 1 : 0,
    }
    FindingsInputNormalSelectAction.index(data)
      .then((res) => {
        if (res) {
          this.setState({ dataForm: res })
          // 今回の部位、所見情報を取得
          this.getSiteFindingsInputThisTime()
          // 前回の所見情報を取得
          this.getSiteFindingsPrevious()
        }
      })
  }

  /**
   * 今回の部位、所見情報を取得
   */
  getSiteFindingsInputThisTime() {
    const data = {
      Li_CourseLevel: this.state.dataForm.Li_CourseLevel,
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InspectCode: this.state.dataForm.Li_InspectCode,
      Li_PatternCode: this.state.dataForm.Li_PatternCode,
      Li_CategoryCode: this.state.dataForm.Li_CategoryCode,
      Li_JudgeLevel: this.state.dataForm.Li_JudgeLevel,
    }
    FindingsInputNormalSelectAction.getSiteFindingsInputThisTime(data)
      .then((res) => {
        if (res) {
          // 今回の部位所見一覧に登録
          this.setState({ dataSourceThisTime: res })
        }
      })
  }

  /**
   * 前回の所見情報を取得
   */
  getSiteFindingsPrevious() {
    const data = {
      Li_ReserveNum: this.state.dataForm.PreviousReserveNum,
      Li_InspectCode: this.state.dataForm.Li_InspectCode,
      LastDate: this.state.dataForm.LastDate, //前回の受診日
    }
    FindingsInputNormalSelectAction.getSiteFindingsPrevious(data)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourcePrevious: res.data ? res.data : [],
            previousDate: res.previousDate
          })
        }
      })
  }

  /**
   * 前回DOを反映する
   */
  setPreviousFindingsDo = () => {
    const previousReserveNum = this.state.dataForm.PreviousReserveNum ?? ''
    if (previousReserveNum === '' || previousReserveNum === null) {
      // 前回のデータが存在しない場合
      Modal.warning({ content: '前回所見は存在しません', okText: 'は　い' })
    } else {
      Modal.confirm({
        content: `前回の所見結果を反映します。よろしいですか？`,
        okText: '保　存',
        cancelText: 'キャンセル',
        onOk: () => {
          const params = {
            Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
            PreviousReserveNum: this.state.dataForm.PreviousReserveNum,
            Li_InspectCode: this.state.dataForm.Li_InspectCode,
          }
          FindingsInputNormalSelectAction.setPreviousFindingsDo(params)
            .then((res) => {
              if (res) {
                // 今回の結果を再取得
                this.getSiteFindingsInputThisTime()
                if (this.props.onUpdateFindings) {
                  // 親画面の表示内容更新させる　
                  this.props.onUpdateFindings()
                }
              }
            })
        }
      })
    }
  }

  /**
   * 所見入力　変更モーダル
   * @param {*} record
   */
  showWS0731002_FindingsInputNormalSelectChange = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 520,
        component:
          <WS0731002_FindingsInputNormalSelectChange
            record={record}
            Li_ReserveNum={this.state.dataForm.Li_ReserveNum}
            Li_CourseLevel={this.state.dataForm.Li_CourseLevel}
            Li_PatternCode={this.state.dataForm.Li_PatternCode}
            Li_CategoryCode={this.state.dataForm.Li_CategoryCode}
            Li_InspectCode={this.state.dataForm.Li_InspectCode}
            Li_JudgeLevel={this.props.Li_JudgeLevel}
            site_classification={this.state.dataForm.site_classification}

            onUpdateFindings={() => {
              if (this.props.onUpdateFindings) {
                // 親画面の表示内容更新させる　
                this.props.onUpdateFindings()
              }
              // データ再取得
              this.index()

              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='findings-input-normal-select '>
        <Card
          // title='所見入力'
          title={`${this.props.record.inspect_name} : [${this.props.personalNumberId}] ${this.props.KanjiName}`}
        >
          <div className='box_container'>
            <div className='box_inner_horizontal'>
              <div>
                <div className='table_header_filter'>
                  {'今回'}
                </div>
                <Table
                  dataSource={this.state.dataSourceThisTime}
                  rowKey={(record) => record.id}
                  size='small'
                  bordered
                  onRow={(record, index) => {
                    return {
                      onDoubleClick: async (event) => {
                        // 編集の場合
                        this.showWS0731002_FindingsInputNormalSelectChange(record)
                      }
                    }
                  }}
                  pagination={false}
                  scroll={{ y: scrollY }}
                >
                  <Table.Column
                    width={50}
                    title='連番'
                    dataIndex='serial_number'
                    render={(value, record, index) => (
                      <div style={{ textAlign: 'right' }}>
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    title='部位'
                    dataIndex='site_name'
                  />
                  <Table.Column
                    title='所見'
                    dataIndex='findings_name'
                  />
                  <Table.Column
                    width={50}
                    title='判定'
                    dataIndex='judgment_value'
                    render={(value, record, index) => {
                      return (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record?.judgmentValueColorNum)?.Foreground,
                          }}
                        >
                          {value}
                        </div>
                      )
                    }}
                  />
                  <Table.Column
                    width={45}
                    style={{ textAlign: 'center' }}
                    title={(item, record, index) =>
                      <Button
                        type='primary'
                        size='small'
                        icon={(<PlusOutlined />)}
                        onClick={() => {
                          // 新規追加の場合
                          this.showWS0731002_FindingsInputNormalSelectChange()
                        }}
                      />
                    }
                    render={(item, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集の場合
                          this.showWS0731002_FindingsInputNormalSelectChange(record)
                        }}
                      />
                    )}
                  />
                </Table>
              </div>

              <div>
                <div
                  className='table_header_filter'
                // style={tableTitleStyle}
                >
                  {'前回 ' + this.state.previousDate ?? ''}
                </div>
                <Table
                  dataSource={this.state.dataSourcePrevious}
                  rowKey={(record) => record.id}
                  size='small'
                  bordered={true}
                  pagination={false}
                  scroll={{ y: scrollY }}
                  style={{ minWidth: previousTableWidth, maxWidth: previousTableWidth }}
                >
                  <Table.Column
                    title='部位'
                    dataIndex='site_name'
                  />
                  <Table.Column
                    title='所見'
                    dataIndex='findings_name'
                  />
                  <Table.Column
                    width={50}
                    title='判定'
                    dataIndex='judgment_value'
                    render={(value, record, index) => {
                      return (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record?.judgmentValueColorNum)?.Foreground,
                          }}
                        >
                          {value}
                        </div>
                      )
                    }}
                  />
                </Table>
              </div>
            </div>

            <div className='box_button_bottom_right' style={{ paddingTop: 0 }} >
              <Button
                type='primary'
                onClick={() => {
                  // 前回DO
                  this.setPreviousFindingsDo()
                }}
              >
                前回DO
              </Button>
            </div>
          </div>

        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0731001_FindingsInputNormalSelect)
