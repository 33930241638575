import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { saveReserveInspectGroupItem, deleteReserveInspectGroupItem } from "redux/SM_SystemMaintenance/ReservesDisplayItemSetting/ReservesDisplayItemSetting.action";
import ModalDraggable from "components/Commons/ModalDraggable";

const labelCol = {
  labelCol: { style: { width: 65 } }
}

class WS2702037_InspectionFrameSetting extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props)

    // document.title = '検査枠設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },

      groupNo: null,
      childNo: null
    }
  }

  componentDidMount = () => {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }

    // Form設定
    this.formRef.current?.setFieldsValue(data)

    this.setState({
      groupName: this.props.record?.groupName ?? null,
      displayName: this.props.record?.displayName ?? null
    })
  }

  /**
  * 値変更時の処理
  */
  onChange = (name) => {
    if (name == 'groupNo') {
      this.setState({
        groupName: ''
      })
    } else if (name == 'childNo') {
      this.setState({
        displayName: ''
      })
    }
  }

  // 保存ボタン
  onSave = () => {
    let valse = this.formRef.current?.getFieldsValue()
    let params = {
      ...valse,
      id: this.props.record?.id ?? false
    }

    saveReserveInspectGroupItem(params)
      .then(res => {
        if (res) {
          this.props.onFinishScreen()
        }
      })
  }

  // 削除ボタン
  onDelete = () => {
    let params = this.props.record
    deleteReserveInspectGroupItem(params)
      .then(res => {
        if (res) {
          this.props.onFinishScreen()
        }
      })
  }

  render() {
    return (
      <div className='inspection-frame-setting'>
        <Card title={'検査枠設定'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item
                name='groupNo'
                label="グループ"
                {...labelCol}
              >
                <Input className='input-search-size-number-2' onChange={() => this.onChange('groupNo')} />
              </Form.Item>
              <div style={{ marginTop: 5 }}>{this.state.groupName}</div>
            </div>

            <div className='box_inner_horizontal'>
              <Form.Item
                name='childNo'
                label="検査"
                {...labelCol}
              >
                <Input className='input-search-size-number-2' onChange={() => this.onChange('childNo')} />
              </Form.Item>
              <div style={{ marginTop: 5 }}>{this.state.displayName}</div>
            </div>

            <div className="box_button_bottom_right">
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                disabled={this.props.Li_status}
                onClick={() => {
                  Modal.confirm({
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    okButtonProps: {
                      icon: <DeleteOutlined />,
                      type: 'primary',
                      danger: true,
                    },
                    cancelText: 'キャンセル',
                    onOk: () => {
                      this.onDelete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => { this.onSave(); }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS2702037_InspectionFrameSetting)
