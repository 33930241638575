import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Table, Space, Row, Col, message} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

import  ModalDraggable  from "components/Commons/ModalDraggable";
class WS1313001_XmlMedicalExamItemMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-XML03000:XML用健診項目マスタ';

    this.state = {
      dataSource: [],
      filteredData: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchValue: '',
      rowSelected: [],
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  onFinish(values) {

  }

  componentDidUpdate = prevProps => {
    if (prevProps.item_code_jlac10_15 !== this.props?.item_code_jlac10_15) {
      this.setState({
        filteredData: this.state.dataSource.filter(item => item.item_code_jlac10_15 === this.props?.item_code_jlac10_15)
      })
    }
  }

  loadScreenData = () => {
    VenusApiRoutesV2.callApi("API001313001002", {})
      .then(res => {
        this.setState({dataSource: res, filteredData: res});
        this.formRef.current.setFieldsValue(res);
        this.setState({
          filteredData: this.state.dataSource.filter(item => item.item_code_jlac10_15 === this.props?.item_code_jlac10_15)
        })
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally();
  };

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="xml-medical-exam-item-master">
        <Card title="V4-XML03000:XML用健診項目マスタ">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table scroll={{ x: 4000 }} 
              bordered 
              dataSource={this.state.filteredData}
              rowKey={(record) => record.id}
            >
              <Table.Column title="採用日" dataIndex="DateAdoptionChars" showSorterTooltip={false} sorter={(a, b) => a.DateAdoptionChars?.localeCompare(b.DateAdoptionChars, 'ja')}/>
              <Table.Column title="項目コード" dataIndex="item_code_jlac10_15" showSorterTooltip={false} sorter={(a, b) => a.item_code_jlac10_15?.localeCompare(b.item_code_jlac10_15, 'ja')}/>
              <Table.Column title="結果識別" dataIndex="result_identification_jlac10_2" showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2?.localeCompare(b.result_identification_jlac10_2, 'ja')}/>
              <Table.Column title="区分番号" dataIndex="division_number" showSorterTooltip={false} sorter={(a, b) => a.division_number - b.division_number}/>
              <Table.Column title="順番号" dataIndex="order_number" showSorterTooltip={false} sorter={(a, b) => a.order_number - b.order_number}/>
              <Table.Column title="条件コード" dataIndex="condition_code_string_12" showSorterTooltip={false} sorter={(a, b) => a.condition_code_string_12?.localeCompare(b.condition_code_string_12, 'ja')}/>
              <Table.Column title="厚労省項目名" width="15%" dataIndex="ministry_item_name" showSorterTooltip={false} sorter={(a, b) => a.ministry_item_name?.localeCompare(b.ministry_item_name, 'ja')}/>
              <Table.Column title="ＸＭＬ表示名" dataIndex="xml_display_name" showSorterTooltip={false} sorter={(a, b) => a.xml_display_name?.localeCompare(b.xml_display_name, 'ja')}/>
              <Table.Column title="データタイプ" dataIndex="data_type" showSorterTooltip={false} sorter={(a, b) => a.data_type?.localeCompare(b.data_type, 'ja')}/>
              <Table.Column title="XMLパターン" dataIndex="xml_pattern" showSorterTooltip={false} sorter={(a, b) => a.xml_pattern?.localeCompare(b.xml_pattern, 'ja')}/>
              <Table.Column title="最大バイトＯＲフォーマット" dataIndex="max_bytes_or_format" showSorterTooltip={false} sorter={(a, b) => a.max_bytes_or_format?.localeCompare(b.max_bytes_or_format, 'ja')}/>
              <Table.Column title="XMLデータ型" dataIndex="xml_data_type" showSorterTooltip={false} sorter={(a, b) => a.xml_data_type?.localeCompare(b.xml_data_type, 'ja')}/>
              <Table.Column title="表示単位" dataIndex="display_units" showSorterTooltip={false} sorter={(a, b) => a.display_units?.localeCompare(b.display_units, 'ja')}/>
              <Table.Column title="UCUM単位コード" dataIndex="ucum_unit_code" showSorterTooltip={false} sorter={(a, b) => a.ucum_unit_code?.localeCompare(b.ucum_unit_code, 'ja')}/>
              <Table.Column title="observation要素" dataIndex="observation_element" showSorterTooltip={false} sorter={(a, b) => a.observation_element - b.observation_element}/>
              <Table.Column title="auther要素" dataIndex="auther_element" showSorterTooltip={false} sorter={(a, b) => a.auther_element - b.auther_element}/>
              <Table.Column title="auther要素項目番号" dataIndex="auther_element_item_number" showSorterTooltip={false} sorter={(a, b) => a.auther_element_item_number?.localeCompare(b.auther_element_item_number, 'ja')}/>
              <Table.Column title="一連検査グループ識別" dataIndex="series_exam_group_identification" showSorterTooltip={false} sorter={(a, b) => a.series_exam_group_identification?.localeCompare(b.series_exam_group_identification, 'ja')}/>
              <Table.Column title="一連検査グループ関係コード" dataIndex="series_exam_group_relationship_code" showSorterTooltip={false} sorter={(a, b) => a.series_exam_group_relationship_code?.localeCompare(b.series_exam_group_relationship_code, 'ja')}/>
              <Table.Column title="同一性項目コード" dataIndex="identity_item_code" showSorterTooltip={false} sorter={(a, b) => a.identity_item_code?.localeCompare(b.identity_item_code, 'ja')}/>
              <Table.Column title="同一性項目名称" dataIndex="identity_item_name" showSorterTooltip={false} sorter={(a, b) => a.identity_item_name?.localeCompare(b.identity_item_name, 'ja')}/>
              <Table.Column title="XML検査方法コード" dataIndex="xml_exam_method_code" showSorterTooltip={false} sorter={(a, b) => a.xml_exam_method_code?.localeCompare(b.xml_exam_method_code, 'ja')}/>
              <Table.Column title="検査方法" dataIndex="exam_methods" showSorterTooltip={false} sorter={(a, b) => a.exam_methods?.localeCompare(b.exam_methods, 'ja')}/>
              <Table.Column title="結果コードＯＩＤ" dataIndex="result_code_oid" showSorterTooltip={false} sorter={(a, b) => a.result_code_oid?.localeCompare(b.result_code_oid, 'ja')}/>
              <Table.Column title="項目コードＯＩＤ" dataIndex="item_code_oid" showSorterTooltip={false} sorter={(a, b) => a.item_code_oid?.localeCompare(b.item_code_oid, 'ja')}/>

            </Table>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313001_XmlMedicalExamItemMaster);
