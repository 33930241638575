import axios from 'configs/axios';

const API_LIST = {
  execution: '/api/protect-personal-info/protect-personal-info/execution',
};

const RisingDocumentCreateService = {
  async execution(params) {
    return axios.post(API_LIST.execution, params);
  },
};

export default RisingDocumentCreateService;