import { message } from 'antd'
import CoupledPrintInstructionService from 'services/InputBusiness/NotInputCheckCategory/CoupledPrintInstructionService'

const CoupledPrintInstruction = {
  index(data) {
    return CoupledPrintInstructionService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  print(data) {
    // 印刷処理の為、そのまま返却
    return CoupledPrintInstructionService.print(data);
  },
  preview(data) {
    // 印刷処理の為、そのまま返却
    return CoupledPrintInstructionService.preview(data);
  },
  // Excel出力
  outputExcel(data) {
    return CoupledPrintInstructionService.outputExcel(data);
  }
}

export default CoupledPrintInstruction
