import axios from 'configs/axios';

const apiPaths = {
  getCommentGroupCbx: '/api/condition-express-cmt-setting/condition-express-cmt-setting/get-screen-data',
  getDataTeachChingItemList: '/api/condition-express-cmt-setting/condition-express-cmt-setting/teach-ching-item-list/get-screen-data',
  updateTeachChingItemList: '/api/condition-express-cmt-setting/condition-express-cmt-setting/teach-ching-item-list/update',
  deleteDate: '/api/condition-express-cmt-setting/condition-express-cmt-setting/teach-ching-item-list/delete',
  originCopy: '/api/condition-express-cmt-setting/condition-express-cmt-setting/teach-ching-item-list/originCopy',
};

const ConditionExpressCmtSettingService = {
  async getCommentGroupCbxService() {
    return axios.get(apiPaths.getCommentGroupCbx);
  },

  async getDataTeachChingItemListService(params) {
    return axios.get(apiPaths.getDataTeachChingItemList, { params });
  },

  async updateTeachChingItemListService(params) {
    return axios.post(apiPaths.updateTeachChingItemList, params)
  },

  async deleteDateService(params) {
    return axios.delete(apiPaths.deleteDate, { params })
  },

  async originCopy(params) {
    return axios.post(apiPaths.originCopy, params)
  },
};

export default ConditionExpressCmtSettingService;