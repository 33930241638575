import axios from 'configs/axios';

const apiPaths = {
  GetListData: '/api/not-input-check-category/category-list-setting-sub',
  SaveListData: '/api/not-input-check-category/category-list-setting-sub/save-data',
  getCategorySearchQuery: '/api/print-param-maintain/category-search-query-single',
  setCategoryData: '/api/not-input-check-category/category-list-setting-sub/set-category-data',
  deleteCategoryData: '/api/not-input-check-category/category-list-setting-sub/delete-category-data',
  setChangeCategoryData: '/api/not-input-check-category/category-list-setting-sub/set-change-category-data'
};

const CategoryListSettingSubService = {
  async GetListData(params) {
    return axios.get(apiPaths.GetListData, { params });
  },
  async SaveListData(params) {
    return axios.post(apiPaths.SaveListData, params);
  },
  async getCategorySearchQuery(params) {
    return axios.post(apiPaths.getCategorySearchQuery, params);
  },
  async setCategoryData(params) {
    return axios.post(apiPaths.setCategoryData, params);
  },
  async deleteCategoryData(params) {
    return axios.delete(apiPaths.deleteCategoryData, { params });
  },
  async setChangeCategoryData(params) {
    return axios.post(apiPaths.setChangeCategoryData, params);
  },
};

export default CategoryListSettingSubService;
