import { message } from "antd";
import NotInputCheckMaintainService from "services/InputBusiness/NotInputCheckCategory/NotInputCheckMaintainService";

const NotInputCheckMaintainAction = {
  index(params) {
    return NotInputCheckMaintainService.index(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  getPatternF8(params) {
    return NotInputCheckMaintainService.patternF8(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  clickData(data) {
    return NotInputCheckMaintainService.ClickData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  generateData(data) {
    return NotInputCheckMaintainService.GenerateData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveTypeMaintenance(data) {
    return NotInputCheckMaintainService.SaveTypeMaintenance(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  typeMaintenance(data) {
    return NotInputCheckMaintainService.TypeMaintenance(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  getCategoryDisplayMaintaince(data) {
    return NotInputCheckMaintainService.CategoryDisplayMaintaince(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveCategoryDisplay(data) {
    return NotInputCheckMaintainService.SaveCategoryDisplay(data)
      .then()
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveCategoryDisplayMaintaince(data) {
    return NotInputCheckMaintainService.SaveCategoryDisplayMaintaince(data)
      .then()
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  getUnnecessaryExamList(data) {
    return NotInputCheckMaintainService.UnnecessaryExamList(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveUnnecessaryExamList(data) {
    return NotInputCheckMaintainService.SaveUnnecessaryExamList(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  deleteDataUnnecessaryExamList(data) {
    return NotInputCheckMaintainService.DeleteDataUnnecessaryExamList(data).then()
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  deleteTypeMaintenanceByCode(data) {
    return NotInputCheckMaintainService.DeleteTypeMaintenanceByCode(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  changeCategoryCheckbox(params) {
    return NotInputCheckMaintainService.changeCategoryCheckbox(params)
      .then((res) => {
        // message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  changeCategoryCheckboxAll(params) {
    return NotInputCheckMaintainService.changeCategoryCheckboxAll(params)
      .then((res) => {
        // message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default NotInputCheckMaintainAction;