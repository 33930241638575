import React from 'react'
import PropTypes from 'prop-types'

import { Input, Form, Card, Table, Select, Button, } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { saveSupplementaryInfoAction } from 'redux/basicInfo/InsurerInfoMaintain/InsurerInfoMaintain.actions.js';
import * as wanakana from 'wanakana';

class WS0339500_InsurerSupplementaryInfo extends React.Component {
  static propTypes = {
    Li_InsurerCode: PropTypes.any
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      modifyEnabled: true,
      isLoadingInsurerInfo: false,
      isLoadingScreenData: false,
      isLoadingContractInfo: false,

      InsurerCode: null,
      InsurerCodeBefore: null,

      // Startup Data
      insurerData: {},

      FilestatusInsurerInfo: false,
      FilestatusEdit: false,
      KeyEditting: false,
      dataSource: [],
    }
  }

  componentDidMount = () => {
    this.setState({
      insurerData: this.props.insurerData,
      dataSource: this.props.insurerData.insurer_supplements
    })
    this.formRef.current?.setFieldsValue({
      tableData: this.props.insurerData.insurer_supplements
    })
  }

  saveSupplementaryInfo = () => {
    let params = {
      // OfficeCode: this.props.Li_OfficeCode,
      insurerCode: this.props.insurerCode,
      SupplementaryInfo: this.state.dataSource
    }

    saveSupplementaryInfoAction(params)
      .then(res => {
        this.props.onFinishScreen({
          Li_dataSource: this.state.dataSource,
          Li_isFileEdited: true,
          insurerCode: params.insurerCode
        })
      })
    // this.props.onFinishScreen({
    //   supplementaryInfo: this.formRef.current?.getFieldValue('insurer_supplements')
    // })
  }

  onChangeValue = (value, record, index) => {
    let chnageValue = ''
    console.log(value);
    console.log(record);
    console.log(index);

    if (record.condition_1 === 'X') {
      chnageValue = wanakana.toRomaji(value)
    } else if (record.condition_1 === 'K') {
      chnageValue = wanakana.toKatakana(value)
    } else if (record.condition_1 === 'J') {
      chnageValue = wanakana.toHiragana(value)
    }

    let arrTemp = [...this.state.dataSource]
    let obj = {
      ...record,
      content: chnageValue, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj
    // this.formRef.current?.setFieldsValue({
    //   tableData: arrTemp
    // })
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false
    })
  }

  render() {
    return (
      <Card title='補足情報'>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          onValuesChange={this.onValuesChange}
          onFinishFailed={this.onFinishFailed}
        >
          <Table
            size='small'
            pagination={false}
            dataSource={this.state.dataSource}
            rowKey={(record) => record.id}
            scroll={{ y: 500 }}
            bordered
          >
            <Table.Column
              title='項目'
              dataIndex='item'
            />

            <Table.Column
              title='内容'
              dataIndex='content'
              render={(value, record, index) => {
                return (
                  <Form.Item name={['tableData', index, 'content']}>
                    {(record.StsDirectInput) ?
                      // 選択項目
                      <Select
                        onChange={(event) => {
                          this.onChangeValue(event, record, index)
                        }}
                      >
                        {record.contents.map((item, index) => (
                          <Select.Option
                            key={item.label + index}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      :
                      <Input
                        autoComplete='off'
                        maxLength={record?.condition_2 || 50}
                        onBlur={event => {
                          this.onChangeValue(event.target.value, record, index)
                        }}
                      />
                    }
                  </Form.Item>
                )
              }}
            />


          </Table>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => { this.saveSupplementaryInfo() }}
            >
              <span className='btn_label'>
                登録
              </span>
            </Button>
          </div>

          {/* <Form.List
            name='insurer_supplements'
            // eslint-disable-next-line react/no-children-prop
            children={fields => {
              if (!this.state.screenData.SupplementaryInfo) {
                return null
              }
              return this.state.screenData.SupplementaryInfo.map((value, index) => {
                return (
                  <Fragment key={index}>
                    <Form.Item name={[index, 'attribute_code']} hidden={true}>
                      <Input />
                    </Form.Item>
                    <Row gutter={5}>
                      <Col span={6}>
                        <Tag
                          color={Color(156).Background}
                          style={{ width: '100%', height: 'calc(100% - 0.3rem)' }}
                        >
                          {value.item}
                        </Tag>
                      </Col>
                      <Col span={18}>
                        {value.StsDirectInput === 0 ? (
                          <Form.Item name={[index, 'content']} labelCol={{ span: 5 }}>
                            <Input
                              onBlur={event => {
                                let namePath = ['insurer_supplements', index, 'content']
                                let inputValueJP = ''
                                if (value.condition_1 === 'X') {
                                  inputValueJP = wanakana.toRomaji(event.target.value)
                                }
                                if (value.condition_1 === 'J') {
                                  inputValueJP = wanakana.toKatakana(event.target.value)
                                }
                                if (value.condition_1 === 'K') {
                                  inputValueJP = wanakana.toHiragana(event.target.value)
                                }
                                this.formRef?.current?.setFields([
                                  {
                                    name: namePath,
                                    value: inputValueJP
                                  }
                                ])
                                this.forceUpdate()
                              }}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item name={[index, 'content']} labelCol={{ span: 5 }}>
                            <Select>
                              {value?.Content.map(item => (
                                <Select.Option
                                  key={item.LinkedField + value.DisplayField}
                                  value={item.LinkedField}
                                >
                                  {item.DisplayField}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Fragment>
                )
              })
            }}
          /> */}
        </Form>
      </Card>
    )
  }
} export default WS0339500_InsurerSupplementaryInfo