import React from "react";
import { connect } from "react-redux";
import { download_file } from "helpers/CommonHelpers";
import { Card, Form, Input, Select, Upload, Button, message, Spin } from "antd";
import InputOutputAction from 'redux/SystemMaintenance/UserParamMaintain/InputOutput.action'
import { DeleteOutlined, SaveOutlined, DownloadOutlined, UploadOutlined, MoreOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import axios from 'configs/axios'
import UserParamMaintainAction from 'redux/SystemMaintenance/UserParamMaintain/UserParamMaintain.actions'



class UserParamMaintainAddNew extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '入出力';

    this.state = {
      isLoadingData: false,
      infom: '',
      value: 0,
      InputAndOutput: null,
      path: ''
    };
  }

  componentDidMount() {
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }
  save() {
    let data = this.formRef.current?.getFieldsValue()

    let params = {
      ...data,
      record: this.props.record ? this.props.record : '',
      Id: this.props.record ? this.props.record.id : '',
      format: this.props.record ? this.props.record.format : this.formRef.current?.getFieldValue('format'),
      remarks: this.formRef.current?.getFieldValue('remarks'),
    }
    console.log(params)
    UserParamMaintainAction.save2(params)
      .then(res => {
        this.props.onFinishScreen()
      })
  }






  render() {
    return (

      <div className="user-param-maintain-add-new">
        <Card title={'ユーザーパラメータ [' + ((this.props.newFlag) ? '新規登録' : '編集') + ']'} className="mb-2">
          <Spin spinning={this.state.isLoadingData}>

            <Form
              ref={this.formRef}
              onFinish={this.onFinish}

            >

              <Form.Item
                name="format"
                label="FORMAT"
                labelCol={{ span: 5 }} FORMAT
              >
                <Input readOnly={this.props.newFlag == false}>

                </Input>

              </Form.Item>
              <Form.Item
                name="remarks"
                label="備考"

                labelCol={{ span: 5 }}
              >
                <Input style={{ width: '250' }} type="text" />
              </Form.Item>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  // disabled={this.state.disabledSaveBtn}
                  onClick={() => this.save()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>

            </Form >
          </Spin>
        </Card >
      </div >

    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UserParamMaintainAddNew);
