import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Card, Form, Input, Select, Button, Table, message, Dropdown, Menu, } from "antd";

import WS1444001_DunningInquiry from 'pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444001_DunningInquiry.jsx';
import WS1436001_ImplementRecordRef from 'pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1436001_ImplementRecordRef.jsx';
import WS2653001_GuideInputProvenSub from 'pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653001_GuideInputProvenSub.jsx';
import WS1382001_ImplementorInquiry from 'pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1382001_ImplementorInquiry.jsx';
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx';
import { getIntroduceInsureGuideInputAction } from "redux/SpecificInsureGuide/InsureGuideInput/InsureGuideInput.actions";
import moment from "moment";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

class WS2652001_InsureGuideInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "保健指導入力";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoadingTable: false,
      rowSelect: {},

      kanji_name1: '',
      kanji_name2: '',
    };
  }

  callAPILoadData = () => {
    this.setState({ isLoadingTable: true, rowSelect: {} })

    if (this.formRef.current?.getFieldValue('DateFChar') == '') {
      return message.error('日付を入力してください')
    }
    if (this.formRef.current?.getFieldValue('DateTChar') == '') {
      return message.error('日付を入力してください')
    }

    let params = {
      DateFChar: this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD'),
      DateTChar: this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD'),
      PractitionerCode: this.formRef.current?.getFieldValue('PractitionerCode') ?? '',
      PersonalNum: this.formRef.current?.getFieldValue('PersonalNum') ?? '',
      GuidanceDivision: this.formRef.current?.getFieldValue('GuidanceDivision') ?? '',
      State: this.formRef.current?.getFieldValue('State') ?? '',
      Division: 1
    }

    getIntroduceInsureGuideInputAction(params)
      .then((res) => {
        this.setState({ dataSource: res.data })
        if (res.data[0]) {
          // Set first row as selected yellow
          this.setState({
            rowSelect: res.data[0],
          });
        }
      })
      .catch(err => { })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  ReturnComponent = (component) => {
    let components = {
      WS2653001_GuideInputProvenSub,
    };
    return components[component];
  }

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={(objData) => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  swohWS2653001GuideInputProvenSub = (record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 1050,
        visible: true,
        component: (
          <WS2653001_GuideInputProvenSub
            Li_Date={record.date_on}
            Li_PersonalNum={record.W1_id}
            Li_SerialNum={record.serial_number}
            kanji_name={record.kanji_name}
            record={record}
            onFinishScreen={() => {
              this.callAPILoadData()
            }}
            onUpdate={() => {
              this.callAPILoadData()
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  swhoWS1436001_ImplementRecordRef = (record) => {
    console.log(record.date_on);
    this.setState({
      ...this.state,
      childModal: {
        width: 1100,
        visible: true,
        component: (
          <WS1436001_ImplementRecordRef
            Li_Date={record.date_on}
            Li_PersonalNum={record.W1_id}
            Li_SerialNum={record.serial_number}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  swhoWS1444001_DunningInquiry = (record) => {
    console.log(record);
    this.setState({
      ...this.state,
      childModal: {
        width: 800,
        visible: true,
        component: (
          <WS1444001_DunningInquiry
            Li_Date={record.date_on}
            Li_PersonalNum={record.W1_id}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className="insure-guide-input">

        <Form
          ref={this.formRef}
          initialValues={{
            GuidanceDivision: 0,
            State: 0,
            DateFChar: '',
            DateTChar: ''
          }}
        >
          <Card className="mb-2" title="保健指導入力">
            <div className='box_container'>
              <div className="box_inner_horizontal">
                <div className='box_search'>
                  <div className='box_search_inner_border' style={{ paddingTop: 0, minWidth: 350 }}>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <Form.Item name="DateFChar" label="日　付" style={{ marginLeft: '-10px' }}
                        rules={[{ required: true, message: '日付を入力して下さい' }]}
                      >
                        <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/DD" />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name="DateTChar" rules={[{ required: true, message: '日付を入力して下さい' }]}>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format="YYYY/MM/DD"
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <div style={{ display: 'flex' }}>
                        <Form.Item label="実施者" name="PractitionerCode">
                          <Input.Search
                            style={{ width: '150px' }}
                            onSearch={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1050,
                                  component: (<WS1382001_ImplementorInquiry
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        PractitionerCode: output.practitioner_code
                                      });
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                        kanji_name1: output.kanji_name
                                      });
                                    }}
                                  />),
                                },
                              })
                            }}
                          />
                        </Form.Item>
                        <Form.Item >
                          <div style={{ marginLeft: '10px' }}>{this.state.kanji_name1}</div>
                        </Form.Item>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <Form.Item label="個　人" name="PersonalNum" style={{ marginBottom: 0 }}>
                          <Input.Search
                            style={{ width: '150px' }}
                            onSearch={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (<WS0248001_PersonalInfoSearchQuery
                                    date={moment(new Date()).format('YYYY/MM/DD')}
                                    onFinishScreen={(data) => {
                                      this.formRef.current.setFieldsValue({
                                        PersonalNum: data.Lo_PersonalNumId
                                      });
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                        kanji_name2: data.recordData.kanji_name
                                      });
                                    }}
                                  />),
                                },
                              })
                            }} />
                        </Form.Item>
                        <Form.Item >
                          <div style={{ marginTop: '10px', marginLeft: '10px' }}>{this.state.kanji_name2}</div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className='box_search_inner' style={{ paddingTop: 0, minWidth: 200 }}>
                    <Form.Item label="指　導" name="GuidanceDivision">
                      <Select style={{ width: '150px' }}>
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={1}>動機付支援</Select.Option>
                        <Select.Option value={2}>積極的支援</Select.Option>
                        <Select.Option value={3}>動機付相当</Select.Option>
                        <Select.Option value={4}>モデル実施</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="状　態" name="State">
                      <Select style={{ width: '150px' }}>
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={1}>未完了</Select.Option>
                        <Select.Option value={2}>完了</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => this.callAPILoadData()}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <Table
                bordered size='small'
                dataSource={this.state.dataSource}
                pagination={false}
                scroll={{ y: resizableTableScroll(130) }}
                rowKey={(record) => record.id}
                rowClassName={(record, index) => record.id === this.state.rowSelect.id ? 'hightlight-row-selected' : ''}
                onRow={(record, index) => ({
                  onClick: (event) => this.setState({ rowSelect: record }),
                  onDoubleClick: () => {
                    this.swohWS2653001GuideInputProvenSub(record)
                  }
                })}
              >
                <Table.Column
                  title="実施予定日"
                  dataIndex="Expression_1"
                  width={130}
                />

                <Table.Column
                  title="個人番号"
                  dataIndex="W1_id"
                  width={130}
                  render={(value) => (
                    <div
                      style={{ textAlign: 'right' }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title="氏　名"
                  dataIndex="kanji_name"
                  width={400}
                />

                <Table.Column
                  title="性別"
                  dataIndex="Expression_10"
                  width={80}
                  render={(text, record, index) => (
                    <span
                      style={{ color: text === '男性' ? '#0000FF' : '#FF0000' }}>
                      {text}
                    </span>
                  )}
                />

                <Table.Column
                  title="指導区分"
                  dataIndex="Expression_12"
                  width={150}
                  render={(text, record, index) => {
                    let color = '';
                    switch (text) {
                      case '動機付支援':
                        color = '#808000';
                        break;

                      case '積極的支援':
                        color = '#0000FF';
                        break;

                      default: color = '';
                        break;
                    }
                    return <span style={{ color: color }}>{text}</span>
                  }}
                />

                <Table.Column
                  title="評価区分"
                  dataIndex="Expression_14"
                  width={130}
                />

                <Table.Column
                  title="支援項目"
                  dataIndex="support"
                  width={230}
                />

                <Table.Column
                  title="支援量"
                  dataIndex="support_price"
                  width={100}
                />

                <Table.Column
                  title="実施"
                  dataIndex="Expression_17"
                  width={50}
                  align='center'
                />

                <Table.Column
                  title="督促"
                  dataIndex="Expression_18"
                  width={50}
                  align='center'
                />

                <Table.Column
                  width={40}
                  fixed='right'
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key="menu-1"
                              onClick={() => {
                                this.swohWS2653001GuideInputProvenSub(record)
                              }}>
                              入力
                            </Menu.Item>

                            <Menu.Item
                              key="menu-2"
                              onClick={() => {
                                this.swhoWS1436001_ImplementRecordRef(record)
                              }}
                            >
                              照会
                            </Menu.Item>

                            <Menu.Item
                              key="menu-3"
                              onClick={() => {
                                this.swhoWS1444001_DunningInquiry(record)
                              }}
                            >
                              督促
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          icon={<MoreOutlined />}
                          size="small"
                        ></Button>
                      </Dropdown>
                    );
                  }}
                />
              </Table>
            </div>
          </Card >
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />

      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2652001_InsureGuideInput);
