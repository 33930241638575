import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Table, Modal, message, Dropdown, Menu, Tooltip, Checkbox } from 'antd'
import { SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, QuestionCircleOutlined, MenuOutlined, DeleteOutlined, PlusOutlined, InfoOutlined } from '@ant-design/icons'
import WS2786002_ConditionAddSubPlus from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786002_ConditionAddSubPlus.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import ExamineeSearchService from 'services/ReservationBusiness/ExamineeSearch/ExamineeSearchService'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import moment from 'moment'
import { download_file } from 'helpers/CommonHelpers'
import ResizableColumn from 'components/Commons/ResizableColumn'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
class WS2783001_ExamineeSearch extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '受診者検索'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: null,
      },
      selectedRows: {},
      screenData: {
        KeyInfo: '',
        DataSourceName: '',
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      conditionAddData: null,
      Li_Change: '',
      columns: [
        {
          title: '状態',
          dataIndex: 'stateName',
          className: 'column-size-12',
          render: (text) => (
            <span
              style={{
                color:
                  text === '受付' ? 'red' : text === '予約' ? 'blue' : 'black',
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: '受診日',
          dataIndex: 'visit_date_on',
          className: 'column-size-date',
          render: (text) => (
            <div>{text ? moment(text).format('YYYY/MM/DD (ddd)') : ''}</div>
          ),
        },
        {
          title: '時間帯',
          dataIndex: 'period_time',
          className: 'column-size-5',
          render: (text) => (
            <div>{text ? moment(text, 'HH:mm').format('HH:mm') : ''}</div>
          ),
        },
        {
          title: '個人番号',
          dataIndex: 'personal_number_id',
          className: 'column-size-10',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text}
            </div>
          ),
        },
        {
          title: 'メモ',
          dataIndex: 'importance',
          width: 40,
          render: (text, record) => {
            let icon = '';
            switch (record.importance) {
              case 1: icon = (<InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />);
                break;
              case 3: icon = (<WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />);
                break;
              case 5: icon = (<CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />);
                break;
              default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>);
            }
            return (
              <div style={{ textAlign: 'center', cursor: 'pointer' }}
                hidden={!record.personal_number_id}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 1500,
                      component: (
                        <WS2584019_PersonalInfoInquirySub
                          Li_PersonalNum={record.personal_number_id}
                          onFinishScreen={(output) => {
                            if (output.flg === 1) {
                              this.index()
                            }
                          }}
                        />
                      ),
                    },
                  })
                }}>
                {icon}
              </div>
            )
          },
        },
        {
          title: '氏名',
          dataIndex: 'PersonalName',
          className: 'column-size-40',
        },
        {
          title: '性別',
          dataIndex: 'Gender',
          className: 'column-size-4',
          render: (text) => (
            <div
              style={{
                color:
                  text === '女性' ? 'red' : text === '男性' ? 'blue' : 'black',
                textAlign: 'center'
              }}
            >
              {text}
            </div>
          ),
        },
        {
          title: '生年月日',
          dataIndex: 'DateBirth',
          className: 'column-size-8',
          render: (text) => <div>{text ? moment(text).format('NNy/MM/DD') : ''}</div>,
        },
        {
          title: '年齢',
          dataIndex: 'Age',
          className: 'column-size-5',
          render: (text) => <div style={{ textAlign: 'right' }}>{text}</div>,
        },
        {
          title: '受付No',
          dataIndex: 'receipt_number',
          className: 'column-size-5',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text}
            </div>
          ),
        },
        {
          title: '契約情報',
          dataIndex: 'visit_course',
          className: 'column-size-60',
          render: (text, record) => (
            <div className='box_inner_horizontal'>
              <div>{record.visit_course}</div>
              <div style={{ marginLeft: '3px' }}>{record.contract_short_name}</div>
            </div>
          ),
        },
        {
          title: '事業所情報',
          dataIndex: 'office_kanji_name',
          className: 'column-size-60',
        },
        {
          title: '保険者',
          dataIndex: 'insurer_total_price',
          className: 'input-size-number-7',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text?.toLocaleString()}
            </div>
          ),
        },
        {
          title: '事業所',
          dataIndex: 'office_total_price',
          className: 'input-size-number-7',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text?.toLocaleString()}
            </div>
          ),
        },
        {
          title: '他団体',
          dataIndex: 'organization_total_price',
          className: 'input-size-number-7',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text?.toLocaleString()}
            </div>
          ),
        },
        {
          title: '個人',
          dataIndex: 'personal_total_price',
          className: 'input-size-number-7',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text?.toLocaleString()}
            </div>
          ),
        },
        {
          title: '請求額',
          dataIndex: 'total_price',
          className: 'input-size-number-7',
          render: (text) => (
            <div style={{ textAlign: 'right' }}>
              {text === '0' || text === 0 ? '' : text?.toLocaleString()}
            </div>
          ),
        },
        {
          title: '検査状況',
          dataIndex: 'InspectStatus',
          className: 'column-size-60',
        },
        {
          title: '電子カルテ',
          dataIndex: 'miraisSendStatus',
          className: 'column-size-6',
          render: (text, record) => (
            <Tooltip title={record.miraisSendStatusTooltip}>
              <div style={{ textAlign: 'center' }}>{text}</div>
            </Tooltip>
          )
        },
        {
          title: '備考',
          dataIndex: 'remarks',
          className: 'column-size-60',
        },
        {
          key: 'action',
          className: 'column-size-2',
          fixed: 'right',
          title: () =>
            <Button
              size='small'
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => { this.addReserve() }}
            />,
          render: (text, record) => this.renderRightMenu(record),
        },
      ],
      /**
       * 氏名順で検索するか？
       * @type {boolean}
       */
      isNameSort: false,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.getScreenData()
    this.formRef.current.setFieldsValue({
      DateFChar: '',
      DateTChar: '',
    })
    this.forceUpdate()
  }

  getScreenData = () => {
    ExamineeSearchService.getScreenData()
      .then((res) => {
        this.setState({ screenData: res.data }, () => { })
      })
      .catch((error) => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 受診者一覧を取得
   * 検索ボタン押下処理
   */
  index = () => {
    if (!this.formRef.current?.getFieldValue('DateFChar') && !this.formRef.current?.getFieldValue('DateTChar') && !this.formRef.current?.getFieldValue('PersonalNum')) {
      Modal.error({
        width: 380,
        title: '日付または個人を選択してください',
      });
      return
    }

    this.setState({ selectedRows: {}, selectedRowKeys: [] })

    const {
      DateFChar,
      DateTChar,
      PersonalNum,
      OfficeCode,
      BranchStoreCode,
      Search,
    } = this.formRef.current.getFieldsValue(true)

    let conditionAddData = this.state.conditionAddData

    let today =
      new Date().getFullYear() + '/' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '/' + ('0' + new Date().getDate()).slice(-2);

    let params = {
      DateFChar: DateFChar ? moment(DateFChar).format('YYYY/MM/DD') : '',
      DateTChar: DateTChar ? moment(DateTChar).format('YYYY/MM/DD') : '',
      PersonalNum: conditionAddData?.PersonalNum ? conditionAddData.PersonalNum : PersonalNum,
      OfficeCode: conditionAddData?.OfficeCode ? conditionAddData.OfficeCode : OfficeCode,
      BranchStoreCode: conditionAddData?.BranchStoreCode ? conditionAddData?.BranchStoreCode : BranchStoreCode,
      conditionAddData: conditionAddData,
      Search: Search,
      KeyInfo: this.state.screenData.KeyInfo,
      DataSourceName: this.state.screenData.DataSourceName,
      IsNameSort: this.state.isNameSort,
    }

    ExamineeSearchService.index(params)
      .then((res) => {
        this.setState({
          dataSource: res.data,
        })
        if (res.data[0])
          this.setState({
            selectedRows: res.data[0],
            selectedRowKeys: [res.data[0].id],
          })
      })
      .catch((error) => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  ExcelDownload = (date) => {
    ExamineeSearchService.excel({
      date: moment(date).format('YYYY/MM/DD'),
    })
      .then((res) => {
        console.log(res)
        download_file(res)
        message.success('成功')
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 予約の削除処理
   */
  delete = (record) => {
    ExamineeSearchService.delete({
      id: record.id,
      W1_course_level: record.W1_course_level,
      W1_reserve_num: record.W1_reserve_num,
    })
      .then((res) => {
        message.success('成功')
        this.index()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRows: selectedRows[0],
      selectedRowKeys: selectedRowKeys,
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  renderRightMenu = (record) => (
    <Dropdown
      trigger='click'
      size='small'
      overlay={() => (
        <Menu>
          <Menu.Item
            key='1'
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: 'fit-content',
                  component: (
                    <WS2583001_ConsultInquirySub
                      Li_ReserveNum={record.reservation_number}
                      onFinishScreen={() => {
                        this.closeModal()
                      }}
                    />
                  ),
                },
              })
            }}
          >
            <label>照会</label>
          </Menu.Item>
          <Menu.Item
            key='2'
            hidden={record.state_flag === 1}
            onClick={() => {
              Modal.confirm({
                title: '確認',
                icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
                content: '予約を削除しますか？',
                okText: '削除',
                okButtonProps: {
                  icon: <DeleteOutlined />,
                  type: 'primary',
                  danger: true,
                },
                cancelText: 'キャンセル',
                onOk: () => {
                  this.delete(record)
                },
              })
            }}
          >
            <label>取消</label>
          </Menu.Item>
          <Menu.Item
            key='4'
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: 850,
                  component: (
                    <WS0650001_DocumentBatchCreateSub
                      Li_ReserveNum={record.W1_reserve_num}
                      Li_CourseLevel={record.W1_course_level}
                      Li_OutputUnit={''}
                      Li_OutputPattern={''}
                      selectedList={record}
                      onFinishScreen={() => {
                        this.closeModal()
                      }}
                    />
                  ),
                },
              })
            }}
          >
            <label>予約関連</label>
          </Menu.Item>
          <Menu.Item
            key='5'
            hidden={record.state_flag === 0}
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: 'fit-content',
                  component: (
                    <WS3020036_CoupledPrintInstruction
                      Li_CourseLevel={record.W1_course_level}
                      Li_ReserveNum={record.W1_reserve_num}
                      onFinishScreen={(obj) => {
                        this.closeModal()
                      }}
                    />
                  ),
                },
              })
            }}
          >
            <label>結果印刷</label>
          </Menu.Item>

          {/* Backlog:ALPHASALUS-1065 対応が困難な為、一時的に非活性（2022/11/14：浜辺） */}
          {/* <Menu.Item
            key='6'
            onClick={() => {
              this.ExcelDownload(
                this.state.selectedRowRecordReserveStatusDisplay?.date
              )
            }}
          >
            <label>EXCEL</label>
          </Menu.Item> */}
        </Menu>
      )}
    ><Button size='small' icon={<MoreOutlined />}></Button>
    </Dropdown>
  )

  components = {
    header: {
      cell: ResizableColumn,
    },
  }

  handleResize =
    (index) =>
      (e, { size }) => {
        this.setState(({ columns }) => {
          const nextColumns = [...columns]
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          }
          return { columns: nextColumns }
        })
      }

  sortDate = (a, b) => {
    let dateA = new Date(a)
    let dateB = new Date(b)
    return isFinite(dateA - dateB) ? dateA - dateB : isFinite(dateA) ? 1 : -1
  }

  addReserve = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            Li_Child={true}
            onFinishScreen={() => {
            }}
          />
        ),
      },
    })
  }

  callInquiry = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 氏名順検索ON/OFFステータスアップデート処理
   * @param {boolean} checked 
   */
  updateIsNameSort = (checked) => {
    this.setState({ isNameSort: checked });
  };

  render() {
    const { selectedRowKeys, selectedRows } = this.state
    const rowSelection = {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange,
    }
    const tableColums = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }))

    return (
      <div className='examinee-search'>
        <Card title='受診者検索'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.addReserve()}>
                      予約
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>
                <div className='box_inner_horizontal' style={{ width: 'fit-content' }}>
                  <div className='box_search'>
                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <div className='box_search_inner_border'>
                          <div className='box_inner_horizontal'>
                            <Form.Item name='DateFChar' label='日付'>
                              <VenusDatePickerCustom
                                formRefDatePicker={this.formRef}
                                allowClear={true}
                              />
                            </Form.Item>
                            <Form.Item>~</Form.Item>
                            <Form.Item name='DateTChar' style={{ marginRight: '10px' }}>
                              <VenusDatePickerCustom
                                allowClear={true}
                              />
                            </Form.Item>
                          </div>
                          <Form.Item name='Search' label='検索' style={{ marginBottom: 0 }}>
                            <Input
                              className='input-size-30'
                              type='text'
                            />
                          </Form.Item>

                          <Form.Item
                            name="isNameSort"
                            label="氏名順"
                            valuePropName="checked"
                          >
                            <Checkbox onChange={(e) => this.updateIsNameSort(e.target.checked)}></Checkbox>
                          </Form.Item>
                        </div>

                        <div className='box_search_inner' style={{ minWidth: 100 }}>
                          <Form.Item
                            name='PersonalNum'
                            label='　個人'
                          >
                            <Input.Search
                              allowClear={true}
                              className='input-search-size-allow-clear-4'
                              onPressEnter={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1500,
                                    component: (
                                      <WS0248001_PersonalInfoSearchQuery
                                        date={moment(new Date()).format('YYYY/MM/DD')}
                                        searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                        onFinishScreen={({ Lo_PersonalNumId }) => {
                                          this.formRef.current.setFieldsValue({
                                            PersonalNum: Lo_PersonalNumId,
                                          })
                                          this.forceUpdate()
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          })
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                              onSearch={(value, event) => {
                                if (event.target.localName !== 'input') {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 1500,
                                      component: (
                                        <WS0248001_PersonalInfoSearchQuery
                                          date={moment(new Date()).format('YYYY/MM/DD')}
                                          searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                          onFinishScreen={({ Lo_PersonalNumId }) => {
                                            this.formRef.current.setFieldsValue({
                                              PersonalNum: Lo_PersonalNumId,
                                            })
                                            this.forceUpdate()
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            })
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            name='OfficeCode'
                            label='事業所'
                            style={{ marginBottom: 0 }}
                          >
                            <Input.Search
                              className='input-search-size-allow-clear-4'
                              type='text'
                              allowClear={true}
                              onPressEnter={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1500,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
                                        onFinishScreen={({
                                          Lio_OfficeCode,
                                          Lio_BranchStoreCode,
                                        }) => {
                                          this.formRef.current.setFieldsValue({
                                            OfficeCode: Lio_OfficeCode,
                                            BranchStoreCode: Lio_BranchStoreCode,
                                          })
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          })
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                              onSearch={(value, event) => {
                                if (event.target.localName !== 'input') {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 1500,
                                      component: (
                                        <WS0247001_OfficeInfoRetrievalQuery
                                          Lio_OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
                                          onFinishScreen={({
                                            Lio_OfficeCode,
                                            Lio_BranchStoreCode,
                                          }) => {
                                            this.formRef.current.setFieldsValue({
                                              OfficeCode: Lio_OfficeCode,
                                              BranchStoreCode: Lio_BranchStoreCode,
                                            })
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            })
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        size='middle'
                        onClick={() => this.index()}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                      <Tooltip title='詳細な条件追加'>
                        <Button
                          size='small'
                          type='primary'
                          style={{ borderRadius: '12px' }}
                          icon={<PlusOutlined />}
                          className={(this.state.conditionAddData) ? 'condition-add-btn' : ''}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                centered: true,
                                component: (
                                  <WS2786002_ConditionAddSubPlus
                                    Li_DateF={
                                      this.formRef.current?.getFieldValue('DateFChar') ?
                                        moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY-MM-DD')
                                        :
                                        ''
                                    }
                                    Li_DateT={
                                      this.formRef.current?.getFieldValue('DateTChar') ?
                                        moment(this.formRef.current?.getFieldValue('DateTChar')).format('YYYY-MM-DD')
                                        :
                                        ''
                                    }
                                    Li_Office={this.formRef.current?.getFieldValue('OfficeCode')}
                                    Li_BranchShop={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                    Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNum')}
                                    Li_ConditionAddData={this.state.conditionAddData}

                                    onFinishScreen={({
                                      Lio_KeyInfo,
                                      conditionAddData,
                                    }) => {
                                      this.formRef.current.setFieldsValue({
                                        KeyInfo: Lio_KeyInfo,
                                      })
                                      this.forceUpdate()

                                      this.setState({
                                        conditionAddData: conditionAddData,
                                        screenData: { KeyInfo: Lio_KeyInfo }
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', marginRight: '10px' }}>
                  {this.state.dataSource.length > 0 ?
                    '検索結果件数：' + this.state.dataSource.length + '件'
                    :
                    null
                  }
                </div>
              </div>

              <Table
                bordered
                size='small'
                rowKey={(record) => record.id}
                dataSource={this.state.dataSource}
                rowClassName={(record, index) => record.id === this.state.selectedRows.id ? 'hightlight-row-selected' : ''}
                onRow={(record, index) => ({
                  onDoubleClick: () => this.callInquiry(record)
                })}
                scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                columns={tableColums}
                components={this.components}
              />
            </div>
          </Form>
        </Card>

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2783001_ExamineeSearch)
