import SupplementalInfoSettingService from 'services/basicInfo/InsurerInfoMaintain/SupplementalInfoSettingService'
import { message } from 'antd'

const SupplementalInfoSettingAction = {

  index(personal) {
    return SupplementalInfoSettingService.index(personal)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  save(personal) {
    return SupplementalInfoSettingService.save(personal)
      .then((res) => {
        message.success(res.data.message)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  delete(personal) {
    console.log(personal);
    return SupplementalInfoSettingService.delete(personal)
      .then((res) => {
        message.success(res.data.message)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  getSelectedRange(personal) {
    return SupplementalInfoSettingService.getSelectedRange(personal)
  },

  saveSelectedRange(personal) {
    return SupplementalInfoSettingService.saveSelectedRange(personal)
  },
}

export default SupplementalInfoSettingAction
