import axios from 'configs/axios';

const apiPaths = {
  loadScreenData: '/api/contract-info-maintain/create-contract/getScreenData',
  loadCourses: '/api/contract-info-batch-process/basic-course-inquiry',
  onFinish: '/api/contract-info-maintain/create-contract/CreateBtn',
}

const ContractHistorySubService = {
  async loadScreenData(params) {
    return axios.get(apiPaths.loadScreenData, { params })
  },
  async loadCourses(params) {
    return axios.get(apiPaths.loadCourses, { params })
  },
  async onFinish(params) {
    return axios.post(apiPaths.onFinish, params)
  }
}

export default ContractHistorySubService;