import React from "react";
import { connect } from "react-redux";
import { Card, Table, Row, Col, Form, Input, Space, Select, Button, Modal, TimePicker, message, Popconfirm } from "antd";
import { PlusOutlined, SaveOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import man from "assets/img/性別-男性.png";
import woman from "assets/img/性別-女性.png";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1383001_SupportItemInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1383001_SupportItemInquiry.jsx";
import WS1382001_ImplementorInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1382001_ImplementorInquiry.jsx";
import WS1388001_ImplementLocationQuery from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1388001_ImplementLocationQuery";
import SupportPlanManualCreateAction from "redux/SpecificInsureGuide/InsureGuideInitInput/SupportPlanManualCreate.action"
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import moment from "moment";

const format = 'HH:mm'

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 80px)' } }
}

const rightGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 80px)' } }
}

class WS1423100_SupportPlanManualCreateEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      data: {},
      dataSource: [],
    };
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      this.getScreenData()
    }
  }

  getScreenData() {
    let copyData = this.props.data
    switch (copyData.EvaluationDivisionScreen) {
      case '初回①':
        copyData.EvaluationDivisionScreen = '0'
        break;
      case '初期面談':
        copyData.EvaluationDivisionScreen = '2'
        break;
      case '中間評価':
        copyData.EvaluationDivisionScreen = '3'
        break;
      case '継続中':
        copyData.EvaluationDivisionScreen = '4'
        break;
      case '終了評価':
        copyData.EvaluationDivisionScreen = '9'
        break;
    }
    this.formRef.current?.setFieldsValue({
      serial_number: copyData.serial_number,
      EvaluationDivisionScreen: copyData.EvaluationDivisionScreen,
      start_period_time: moment(copyData.start_period_time, format),
      end_period_time: moment(copyData.end_period_time, format),
      support_price: copyData.support_price,
      ScheduledImplementDateChars: moment(copyData.ScheduledImplementDateChars).format('YYYY/MM/DD'),
      support_code: copyData.support_code,
      implement_prospective_code: copyData.implement_prospective_code,
      implement_location_code: copyData.implement_location_code,
    });
  }

  onFinish(values) {
    let startPeriodTime = this.formRef.current?.getFieldValue("start_period_time")
    let endPeriodTime = this.formRef.current?.getFieldValue("end_period_time")

    if (startPeriodTime) {
      startPeriodTime = moment(this.formRef.current?.getFieldValue("start_period_time")).format(format)
    }
    if (endPeriodTime) {
      endPeriodTime = moment(this.formRef.current?.getFieldValue("end_period_time")).format(format)
    }

    let params = {
      id: this.props.newFlag ? null : this.props.data.id,
      Li_FirstDate: this.props.Li_FirstDate,
      Li_Id: this.props.Li_Id,
      PlanningPointA: this.props.newFlag ? null : this.props.data.PlanningPointA,
      PlanningPointB: this.props.newFlag ? null : this.props.data.PlanningPointB,
      date_on: this.props.newFlag ? this.props.date_on : this.props.data.date_on,
      serial_number: this.formRef.current?.getFieldValue("serial_number"),
      EvaluationDivisionScreen: this.formRef.current?.getFieldValue("EvaluationDivisionScreen"),
      start_period_time: startPeriodTime ?? '00:00',
      end_period_time: endPeriodTime ?? '00:00',
      support_price: this.formRef.current?.getFieldValue("support_price"),
      ScheduledImplementDateChars: moment(this.formRef.current?.getFieldValue("ScheduledImplementDateChars")).format('YYYY/MM/DD'),
      support_code: this.formRef.current?.getFieldValue("support_code"),
      implement_prospective_code: this.formRef.current?.getFieldValue("implement_prospective_code"),
      implement_location_code: this.formRef.current?.getFieldValue("implement_location_code"),
    }
    console.log(params);
    SupportPlanManualCreateAction.save(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  onDelete() {
    let params = {
      id: this.props.data.id
    }
    SupportPlanManualCreateAction.delete(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className="support-plan-manual-create-edit">
        <Card className="mb-2" title="支援計画編集">
          <Form ref={this.formRef} onFinish={(values) => this.onFinish(values)} autoComplete="off" >
            <div className="box_inner_horizontal">
              <div className="box_inner_vertical">
                <Form.Item name="serial_number" label="連番" {...smGrid} rules={[{ required: true }]}>
                  <Input className="input-size-number-7" />
                </Form.Item>
                <Form.Item name="EvaluationDivisionScreen" label="評価区分" {...smGrid} rules={[{ required: true }]}>
                  <Select style={{ width: '120px' }}>
                    <Select.Option value='0'>初回①</Select.Option>
                    <Select.Option value='2'>初期面談</Select.Option>
                    <Select.Option value='3'>中間評価</Select.Option>
                    <Select.Option value='4'>継続中</Select.Option>
                    <Select.Option value='9'>終了評価</Select.Option>
                  </Select>
                </Form.Item>
                <div className="box_inner_horizontal">
                  <Form.Item name="start_period_time" label="時間帯" {...smGrid}>
                    <TimePicker style={{ width: '100px' }} format={format} minuteStep={10} />
                  </Form.Item>
                  <Form.Item name="end_period_time" label="~" >
                    <TimePicker style={{ width: '100px' }} format={format} minuteStep={10} />
                  </Form.Item>
                </div>
                <Form.Item name="support_price" label="支援量" {...smGrid}>
                  <Input className="input-size-number-2" />
                </Form.Item>
              </div>
              <div className="box_inner_vertical">
                <Form.Item name="ScheduledImplementDateChars" label="実施予定日"  {...smGrid} rules={[{ required: true }]}>
                  <VenusDatePickerCustom format="YYYY/MM/DD" formRefDatePicker={this.formRef} />
                </Form.Item>
                <Form.Item name="support_code" label="支援項目"  {...smGrid} rules={[{ required: true }]}>
                  <Input
                    className="input-size-number-10"
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          centered: true,
                          width: 1200,
                          component: (
                            <WS1383001_SupportItemInquiry
                              onFinishScreen={(output) => {
                                this.closeModal();
                                this.formRef.current?.setFieldsValue({
                                  support_code: output.support_code
                                })
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item name="implement_prospective_code" label="実施予定者"  {...smGrid}>
                  <Input
                    className="input-size-number-5"
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          centered: true,
                          width: 700,
                          component: (
                            <WS1382001_ImplementorInquiry
                              onFinishScreen={(output) => {
                                this.closeModal();
                                this.formRef.current?.setFieldsValue({
                                  implement_prospective_code: output.practitioner_code
                                })
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item name="implement_location_code" label="実施場所" {...smGrid} style={{ marginBottom: 0 }}>
                  <Input
                    className="input-size-number-4"
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          centered: true,
                          width: 700,
                          component: (
                            <WS1388001_ImplementLocationQuery
                              onFinishScreen={(output) => {
                                this.closeModal();
                                this.formRef.current?.setFieldsValue({
                                  implement_location_code: output.implement_location_code
                                })
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
                <div className="box_button_bottom_right">
                  {!this.props.newFlag ?
                    < Popconfirm
                      title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                      overlayStyle={{ width: '250px' }}
                      overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                      icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                      okText='削除'
                      okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                      onConfirm={() => {
                        this.onDelete()
                      }}
                      cancelText='キャンセル'
                      cancelButtonProps={{ style: { height: '32px' } }}
                    >
                      <Button
                        danger
                        type='primary'
                        icon={<DeleteOutlined />}
                      >
                        削除
                      </Button>
                    </Popconfirm>
                    : null}
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    保存
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </Card>
      </div >
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1423100_SupportPlanManualCreateEdit);