import axios from 'configs/axios';

const apiPaths = {
  registerBtn: '/api/course-basic-type-setting/register-modification/register-btn',
};

const RegisterModificationService = {
  async postRegisterBtnService(params) {
    return axios.post(apiPaths.registerBtn, params);
  },
};

export default RegisterModificationService;