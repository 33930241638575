import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Modal, Button, Checkbox } from "antd";
import { DeleteOutlined, SaveOutlined, } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0397001_ParamPromptedQuerySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx';
import WS2592016_ParamPromptedQueryContent from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS2592016_ParamPromptedQueryContent.jsx';

class WS0638004_EscortManageMaintainOptionInputEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      change: true
    }
  }

  componentDidMount() {
    let data
    if (this.props.newflag) {
      data = { Expansion: false, DayWeek: false }
    } else {
      // 親画面のrecordをコピー
      data = {
        ...this.props.record,
      }
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
  * データ保存
  */
  onFinish = (values) => {
    this.props.onUpdate(values)
  }

  /**
  * データ削除
  * @param {*} id
  */
  delete = () => {
    this.props.onDelete()
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  ShowParamPromptedQueryContent() {
    this.setState({
      ...this.state,
      childModal: {
        width: '40%',
        visible: true,
        component:
          <WS2592016_ParamPromptedQueryContent
            Li_Format={'Y'}
            Li_IndicationDivision={this.formRef.current?.getFieldValue('medical_exam_course')}
            onFinishScreen={(output) => {
              this.formRef?.current?.setFieldsValue({
                'escort_code': output.item
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
      }
    });
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={" エスコート保守　オプション - [" + (this.props.newflag ? '新規登録]' : '変更]')} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='連番'
                name='serial_num'//対応したものに書き換え
                style={{ display: (this.props.newflag) ? 'none' : '' }}
              >
                <Input
                  readOnly={!this.props.newflag}
                  bordered={this.props.newflag} />
              </Form.Item>

              {this.props.newflag ?
                <Form.Item
                  label='項目'
                  name='medical_exam_course'//対応したものに書き換え
                >
                  <Input.Search
                    className="input-search-size-10"
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component: (
                            <WS0397001_ParamPromptedQuerySub
                              Li_Format={'Y'}
                              onFinishScreen={(output, list) => {
                                if (list > 0) {
                                  this.setState({ change: false });

                                } else {
                                  this.setState({ change: true });
                                }
                                this.formRef.current?.setFieldsValue({
                                  medical_exam_course: output.instruction_division
                                })
                                this.forceUpdate()
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>

                :

                <Form.Item
                  label='項目'
                  name='medical_exam_course'//対応したものに書き換え
                >
                  <Input
                    readOnly={!this.props.newflag}
                    bordered={this.props.newflag}
                  />
                </Form.Item>
              }

              {this.state.change ?
                <Form.Item
                  label='内容'
                  name='escort_code'//対応したものに書き換え
                >
                  <Input />
                </Form.Item>

                :

                <Form.Item
                  label='内容'
                  name='escort_code'//対応したものに書き換え
                >
                  <Input.Search
                    maxLength={800}
                    onSearch={() => this.ShowParamPromptedQueryContent()}
                  />
                </Form.Item>
              }
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newflag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form >
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0638004_EscortManageMaintainOptionInputEdit);
