import { message } from "antd";
import ProtectPersonalInfoService from "services/Others/ProtectPersonalInfo/ProtectPersonalInfoService";


const ProtectPersonalInfoAction = {
  execution(data) {
    return ProtectPersonalInfoService.execution(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default ProtectPersonalInfoAction;