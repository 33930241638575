import React from "react";
import { connect } from "react-redux";

import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  InputNumber,
  Input,
  Select,
  Button,
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Modal,
  Menu,
  Dropdown,
  message,
  Spin,
  Tooltip
} from "antd";

import {
  SearchOutlined,
  MoreOutlined,
  PlusOutlined,
  CaretDownOutlined,
  SaveOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Color from "constants/Color";
import { width } from "dom-helpers";
import axios from "configs/axios";

// import AssociateInsureQualifyConfirmAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirm.action";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1177001_GovernmentTubePrimaryAggregate extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS05200:政管一次集計';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      isLoadingForm: false,
      isLoadingTable: false,

      count: "a",
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true,
      },

      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      selected: 0
    };

    this.addNewData = this.addNewData.bind(this);
    this.saveData = this.saveData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.getScreenData();
    }
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource];

    data[index][field] = value;

    this.setState({
      dataSource: data
    });
  }

  findIndexByID = recordID => {
    return this.state.dataSource.findIndex(item => recordID === item.id);
  };

  changeRow(record) {
    let data = [...this.state.dataSource];

    let index = this.findIndexByID(record.id);
    this.setState({
      selectedRow: data[index],
      selectedRowKeys: [data[index]?.id],
      indexTable: index
    });
  }

  getScreenData = () => {
    this.setState({ isLoadingTable: true });
    VenusApiRoutesV2.callApi("API001177001002", {}).then(res => {
        this.setState({
          dataSource: res ?? []
        });
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  };

  addNewData() {
    // if (!this.state.dataSource.length || this.state.dataAddNew) {
    //   return;
    // }
    const { count } = this.state;
    const newData = {
      id:  Math.random().toString(36).slice(-5),
      medical_exam_course: "",
      medical_exam_course_name: "",
      invoice_summary_division: 0,
      course_exam_basic_health: "",
      course_name_short_name: ""
    };

    this.forceUpdate();
    this.setState({
      ...this.state,
      count: count + 1,
      dataAddNew: newData,
      dataSource: [newData, ...this.state.dataSource]
    });
  }

  saveData(record) {
    VenusApiRoutesV2.callApi("API001177001006", {mainSource: [record]}).then(res => {
      this.getScreenData();
    }).catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
  }

  deleteData(record) {
    VenusApiRoutesV2.callApi("API001177001007", {
      mainSource: [{ id: record.id }] 
    }).then(res => {
      this.getScreenData();
    }).catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
  }

  onFinish() {}

  render() {
    return (
      <div className="government-tube-primary-aggregate">
        <Card title="V4-VNS05200:政管一次集計">
          <Table
            bordered
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={this.state.pagination}
            rowClassName={(record, index) =>
              record.id === this.state.selectedRow.id ? "table-row-light" : ""
            }
            rowKey={record => record.id}
            scroll={{ y: "85vh" }}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  this.setState({
                    ...this.state,
                    selectedRow: record,
                    selectedRowKeys: [record.id]
                  });
                }
              };
            }}
          >
            <Table.Column
              title="健診コース"
              dataIndex=""
              showSorterTooltip={false}
              sorter={(a, b) => a.medical_exam_course.localeCompare(b.medical_exam_course)}
              render={(value, record, index) => {
                return (
                  <div>
                    <Row
                      gutter={5}
                      onDoubleClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: "60%",
                            component: (
                              <WS0265001_BasicCourseInquiry
                                onFinishScreen={output => {
                                  this.updateDatasource(
                                    this.findIndexByID(record.id),
                                    "medical_exam_course",
                                    output.Lo_CourseCode,
                                    1
                                  );
                                  this.updateDatasource(
                                    this.findIndexByID(record.id),
                                    "medical_exam_course_name",
                                    output.Lo_CourseName,
                                    0
                                  );

                                  this.closeModal();
                                }}
                              />
                            )
                          }
                        });
                      }}
                    >
                      <Col span={5} style={{ textAlign: "center" }}>
                        <Input
                          readOnly
                          value={record.medical_exam_course}
                          onChange={e => {
                            this.updateDatasource(
                              this.findIndexByID(record.id),
                              "medical_exam_course",
                              e.target.value
                            );
                          }}
                        />
                      </Col>
                      <Col span={14} style={{ textAlign: "center" }}>
                        <Input
                          value={record.medical_exam_course_name}
                          onChange={e => {
                            this.updateDatasource(
                              this.findIndexByID(record.id),
                              "medical_exam_course_name",
                              e.target.value
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
            <Table.Column
              title="集計区分"
              dataIndex="invoice_summary_division"
              showSorterTooltip={false}
              sorter={(a, b) => a.invoice_summary_division - b.invoice_summary_division}
              width={150}
              render={(value, record, index) => {
                return (
                  <Select
                    value={record.invoice_summary_division}
                    style={{ width: "100%" }}
                    onChange={value => {
                      this.updateDatasource(
                        this.findIndexByID(record.id),
                        "invoice_summary_division",
                        value
                      );
                    }}
                  >
                    <Select.Option value={0}>&nbsp;</Select.Option>
                    <Select.Option value={1}>一般</Select.Option>
                    <Select.Option value={2}>付加</Select.Option>
                    <Select.Option value={3}>子宮</Select.Option>
                  </Select>
                );
              }}
            />
            <Table.Column
              title="基本コース"
              dataIndex=""
              showSorterTooltip={false}
              sorter={(a, b) =>
                a.course_exam_basic_health.localeCompare(b.course_exam_basic_health)
              }
              render={(value, record, index) => {
                return (
                  <div>
                    <Row gutter={10}>
                      <Col span={5} style={{ textAlign: "center" }}>
                        <Input
                          value={record.course_exam_basic_health}
                          onChange={e => {
                            this.updateDatasource(
                              this.findIndexByID(record.id),
                              "course_exam_basic_health",
                              e.target.value
                            );
                          }}
                        />
                      </Col>
                      <Col span={14} style={{ textAlign: "center" }}>
                        <div style={{textAlign: "left"}}>{record.course_name_short_name}</div>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
            <Table.Column
              width={70}
              align="center"
              title={
                <Button
                  size="small"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => this.addNewData()}
                ></Button>
              }
              render={(text, record, index) => {
                return (
                  <div hidden={record.id === this.state.selectedRow.id ? false : true}>
                    <Space size={1}>
                      <Button
                        size="small"
                        style={{ color: "green", marginRight: "5px" }}
                        icon={<SaveOutlined />}
                        onClick={() => {
                          this.saveData(record);
                        }}
                      />
                      <Button
                        size="small"
                        style={{ color: "red" }}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            content: "消去してもよろしいですか？",
                            okText: "は　い",
                            cancelText: "いいえ",
                            onOk: () => this.deleteData(record)
                          });
                        }}
                      />
                    </Space>
                  </div>
                );
              }}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1177001_GovernmentTubePrimaryAggregate);
