import React from "react";
import { connect } from "react-redux";

import {
  Card,
  Col,
  Table,
  Row,
  Form,
  message,
  Button,
  Modal,
  Space,
  Menu,
  Dropdown,
  Input,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import SpreadsheetInputSettingService from "services/InputBusiness/SpreadInput/SpreadsheetInputSettingService";
import WS0456005_BatchCapture from "./WS0456005_BatchCapture";
import WS0456100_SpreadsheetInputSettingAdd from "./WS0456100_SpreadsheetInputSettingAdd.jsx"
import WS0456007_Copy from "./WS0456007_Copy";
import WS0456009_Change from "./WS0456009_Change";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle";
import ModalDraggable from "components/Commons/ModalDraggable";
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'

class WS0456001_SpreadsheetInputSetting extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "スプレットシート入力設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTableParent: false,
      isLoadingTableChild: false,
      onBlurInput: true,
      count: 0,
      selectedRowRecordListParent: null,
      oldTableData: null,
    };
  }

  componentDidMount = () => {
    this.getListParent();
  };

  getListParent = () => {
    this.setState({ isLoadingTableParent: true });
    SpreadsheetInputSettingService.getListParentService()
      .then((res) => {
        // this.setState({
        //   selectedRowRecordListParent: null,
        // });
        this.formRef.current.setFieldsValue({
          ListParent: res.data,
        });
        this.forceUpdate();
        this.getListChild(res.data[0].Seqno)
        this.setState({
          selectedRowRecordListParent: res.data[0],
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableParent: false });
      });
  };

  getListChild = (Li_Seq) => {
    this.setState({ isLoadingTableChild: true });
    SpreadsheetInputSettingService.getListChildService({ Li_Seq: Li_Seq })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          ListChild: res.data,
        });
        this.setState({
          oldTableData: res.data,
        });
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableChild: false });
      });
  };
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  findIndexByID = (arrayName, keyName, record) => {
    let arrayFieldValue = this.formRef.current?.getFieldValue([arrayName]);
    return arrayFieldValue.findIndex(
      (item) => record[keyName] === item[keyName]
    );
  };

  addNewRowToTable = (arrayName, keyName) => {
    let arrayFieldValue = this.formRef.current?.getFieldValue([arrayName]);
    let newData = {};
    newData = {
      child_id: "new" + this.state.count,
      no: "",
      exam_item: "",
      exam_type: "",
      Li_Seq: this.state.selectedRowRecordListParent.item_id,
    };
    arrayFieldValue.push(newData)
    this.formRef.current?.setFieldsValue({ [arrayName]: arrayFieldValue });
    this.forceUpdate();
    this.setState({ count: this.state.count + 1 });
  };

  createParentSpreadsheetInputSetting = (record) => {
    let getFieldValue = this.formRef.current.getFieldValue("ListChild")
    let oldTableData = this.state.oldTableData
    let changeData = []
    let comparison, obj, increment, array1, array2
    for (let i = 0; i < getFieldValue.length; i++) {
      array1 = getFieldValue[i]
      array2 = oldTableData[i]
      // comparison = JSON.stringify(array1[k]) === JSON.stringify(array2[k])
      increment = _.omitBy(array1, (v, k) => oldTableData[k] === v)
      // comparison = _.isEqual(array1[k], array2[k]);
      if (increment.exam_item !== undefined || increment.no !== undefined) {
        obj = {
          id: array1.child_id,
          Li_Seq: array1.Li_Seq,
          columnList: increment
        }
        changeData.push(obj)
      }
    }
    if (changeData.length === 0) {
      return
    }
    let params = {
      changeData: changeData,
    }
    SpreadsheetInputSettingService.saveChildSpreadsheetInputSettingService(
      params
    )
      .then((res) => {
        message.success("成功");
        this.getListParent();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  deleteParentSpreadsheetInputSetting = (record) => {
    SpreadsheetInputSettingService.deleteParentSpreadsheetInputSettingService({
      parent_id: this.state.selectedRowRecordListParent.parent_id,
    })
      .then((res) => {
        this.setState({ selectedRowRecordListParent: null });
        this.formRef.current.setFieldsValue({
          ListChild: [],
        });
        this.forceUpdate();
        message.success("成功");
        this.getListParent();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  createChildSpreadsheetInputSetting = (record) => {
    let child_id = record.child_id.toString()
    if (child_id.indexOf('new') > -1) {
      let copyData = [...this.formRef.current.getFieldValue("ListChild")]
      for (let i = 0; i < copyData.length; i++) {
        if (copyData[i].child_id === record.child_id) {
          copyData.splice(i, 1)
          this.formRef.current.setFieldsValue({
            ListChild: copyData
          });
          this.forceUpdate();
          return
        }
      }
    }
    let params = {
      child_id: record.child_id,
    };
    SpreadsheetInputSettingService.deleteChildSpreadsheetInputSettingService(
      params
    )
      .then((res) => {
        message.success("成功");
        this.getListChild(this.state.selectedRowRecordListParent.Seqno);
        this.props.listGet()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  deleteChildSpreadsheetInputSetting = (record) => {
    SpreadsheetInputSettingService.deleteChildSpreadsheetInputSettingService({
      child_id: record.child_id,
    })
      .then((res) => {
        message.success("成功");
        this.getListChild(this.state.selectedRowRecordListParent.Seqno);
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  onFocus = () => {
    this.setState({ onBlurInput: false });
  };
  onBlur = () => {
    this.setState({ onBlurInput: true });
  };


  render() {
    const functionInputCheck = {
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    };
    return (
      <div className="spreadsheet-input-setting">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <Card title="スプレットシート入力設定">
            <div className="box_inner_horizontal" >
              <div>
                <Table
                  bordered={true}
                  dataSource={
                    this.formRef.current
                      ? this.formRef.current.getFieldValue("ListParent")
                      : []
                  }
                  style={{ width: 500 }}
                  size='small'
                  pagination={false}
                  rowKey={(record) => record.parent_id}
                  scroll={{ y: "69vh" }}
                  rowClassName={(record, index) => {
                    if (this.state.selectedRowRecordListParent) {
                      return record.parent_id ===
                        this.state.selectedRowRecordListParent.parent_id
                        ? "ant-table-row-selected"
                        : "";
                    }
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        if (this.state.onBlurInput === true) {
                          this.getListChild(record.Seqno);
                          this.setState({
                            selectedRowRecordListParent: record,
                          });
                        }
                      },
                    };
                  }}
                >
                  <Table.Column
                    width={150}
                    title="シート"
                    dataIndex="Seqno"
                  />
                  <Table.Column
                    title="シ  ー  ト  名"
                    dataIndex="item"
                  />
                  <Table.Column
                    width={40}
                    key="action"
                    align="center"
                    title={() => (
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 450,
                              component: (
                                <WS0456100_SpreadsheetInputSettingAdd
                                  onFinishScreen={() => {
                                    this.getListParent()
                                    this.props.listGet()
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      />
                    )}
                    render={(text, record, index) => {
                      return (
                        <Space>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: 500,
                                        component: (
                                          <WS0456007_Copy
                                            Li_Seq={record.Seqno}
                                            Li_Item={record.item}
                                            onFinishScreen={() => {
                                              this.getListParent();
                                              this.closeModal();
                                            }}
                                          />
                                        ),
                                      },
                                    });
                                  }}
                                >
                                  複写
                                </Menu.Item>
                                <Menu.Item
                                  key='2'
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: 500,
                                        component: (
                                          <WS0456009_Change
                                            Li_Seq={record.Seqno}
                                            Li_Item={record.item}
                                            onFinishScreen={() => {
                                              this.getListParent();
                                              this.closeModal();
                                            }}
                                          />
                                        ),
                                      },
                                    });
                                  }}
                                >
                                  変更
                                </Menu.Item>
                                <Menu.Item
                                  key='3'
                                  onClick={() => {
                                    Modal.confirm({
                                      title: "確認",
                                      icon: (
                                        <QuestionCircleOutlined
                                          style={{ color: "#1890ff" }}
                                        />
                                      ),
                                      content: "削除しますか",
                                      onOk: () => {
                                        this.deleteParentSpreadsheetInputSetting(
                                          record
                                        );
                                      },
                                    });
                                  }}>
                                  削除
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              icon={<MoreOutlined />}
                              size="small"
                            ></Button>
                          </Dropdown>
                        </Space>
                      );
                    }}
                  />
                </Table>
              </div>
              <div>
                <Table
                  bordered={true}
                  dataSource={
                    this.formRef.current
                      ? this.formRef.current.getFieldValue("ListChild")
                      : []
                  }
                  style={{}}
                  size={'small'}
                  pagination={false}
                  scroll={{ y: "69vh" }}
                  rowKey={(record) => record.child_id}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          selectedRowRecordListChild: record,
                        });
                      },
                    };
                  }}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="no"
                    render={(text, record, index) => {
                      return (
                        <Form.Item
                          name={[
                            "ListChild",
                            this.findIndexByID("ListChild", "child_id", record),
                            "no",
                          ]}
                        >
                          <Input
                            onChange={(value) => {
                              record.no = value;
                            }}
                            onKeyDown={(e) => moveFocusKeyEvent(e, 'ListChild', 2, true, 1)}
                          />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="コード"
                    dataIndex="exam_item"
                    render={(text, record, index) => {
                      return (
                        <Form.Item
                          name={[
                            "ListChild",
                            this.findIndexByID("ListChild", "child_id", record),
                            "exam_item",
                          ]}
                        >
                          <Input
                            onChange={(value) => {
                              record.exam_item = value;
                            }}
                            onKeyDown={(e) => moveFocusKeyEvent(e, 'ListChild', 2, true, 1)}
                            onDoubleClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 600,
                                  component: (
                                    <WS0271001_InspectItemSearchQuerySingle
                                      InspectCode={record.exam_item}
                                      onFinishScreen={({ recordData }) => {
                                        let copyData = [...this.formRef.current.getFieldValue("ListChild")]
                                        copyData[index].exam_item = recordData.test_item_code
                                        copyData[index].exam_short_name = recordData.exam_short_name
                                        copyData[index].exam_name = recordData.exam_name
                                        copyData[index].exam_type = recordData.exam_type
                                        copyData[index].Division = recordData.StsCategoryInspectBreakdown
                                        this.formRef.current.setFieldsValue({
                                          ListChild: copyData,
                                        });
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column title="略称" dataIndex="exam_short_name" />
                  <Table.Column title="名称" dataIndex="exam_name" />
                  <Table.Column
                    title="タイプ"
                    dataIndex="exam_type"
                    align="center"
                  />
                  <Table.Column
                    title="使用"
                    dataIndex="Division"
                    align="center"
                  />
                  <Table.Column
                    width={50}
                    key="action"
                    align="center"
                    title={() =>
                      this.state.selectedRowRecordListParent &&
                      isNaN(
                        this.state.selectedRowRecordListParent.parent_id
                      ) === false && (
                        <Button
                          type="primary"
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            this.addNewRowToTable("ListChild", "child_id");
                          }}
                        />
                      )
                    }
                    render={(text, record, index) => {
                      return (
                        <Space>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  onClick={() => {
                                    Modal.confirm({
                                      title: "確認",
                                      icon: (
                                        <QuestionCircleOutlined
                                          style={{ color: "#1890ff" }}
                                        />
                                      ),
                                      content: "削除しますか",
                                      onOk: () => {
                                        this.createChildSpreadsheetInputSetting(
                                          record
                                        );
                                      },
                                    });
                                  }}>
                                  削除
                                </Menu.Item>
                              </Menu>
                            )}
                          ><Button
                            icon={<MoreOutlined />}
                            size="small"
                          ></Button>
                          </Dropdown>
                        </Space>
                      );
                    }}
                  />
                </Table>
                <div className="box_button_bottom_right">
                  <Button
                    type='primary'
                    icon={<SaveOutlined />}
                    onClick={() => {
                      this.createParentSpreadsheetInputSetting();
                    }}
                  >
                    <span className='btn_label'>
                      保存
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0456001_SpreadsheetInputSetting);
