import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row, Col, Form, Input, message, Button } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import HealthCheckupInfoSelectAction from "redux/SpecificInsureGuide/InsureGuideInitInput/HealthCheckupInfoSelect.action.js"

class WS2653079_HealthCheckupInfoSelect extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '健診情報選択';

    this.state = {
      mainSource: [],
      selectMainSource: [],
      inspectContentQuery: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  componentDidMount = () => {
    this.getScreenData();
  }

  getScreenData() {
    this.setState({ isLoading: true, rowSelect: {} });
    let params = {
      Li_PersonalNum: this.props.Li_Id
    }
    HealthCheckupInfoSelectAction.getScreenData(params)
      .then((res) => {
        console.log(res);
        this.formRef.current?.setFieldsValue({
          mainSource: res ? res : []
        })
        this.forceUpdate();
        if (res.length !== 0) {
          this.inspectContentQuery(res[0])
          this.setState({
            supportCodeList: res.SupportCode_List,
            selectMainSource: res[0],
            mainSource: res
          });
        }
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
      })
  }

  inspectContentQuery(record) {
    console.log(record.reservation_number);
    console.log(this.state.selectMainSource.reservation_number);
    if (record.reservation_number === this.state.selectMainSource.reservation_number) {
      return
    }
    let params = {
      Li_ReserveNum: record ? record.reservation_number : this.state.selectMainSource.reservation_number
    }
    console.log(params);
    HealthCheckupInfoSelectAction.inspectContentQuery(params)
      .then((res) => {
        console.log(res);
        this.setState({ inspectContentQuery: res });
        this.formRef.current?.setFieldsValue({
          inspectContentQuery: res ? res : []
        })
        this.forceUpdate();
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
      })
  }

  select() {
    let params = {
      Li_Date: this.props.Li_Date,
      Li_PersonalNum: this.props.Li_Id,
      Li_SerialNum: this.props.Li_SerialNum,
      reservation_number: this.state.selectMainSource.reservation_number ?? 0,
    }
    HealthCheckupInfoSelectAction.select(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
      })
  }

  render() {
    return (
      <div className="health-checkup-info-select">
        <Card className="mb-2" title="健診情報選択">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={12} style={{ paddingRight: '10px' }}>
                <Table
                  bordered
                  size="small"
                  dataSource={this.state.mainSource}
                  rowClassName={(record, index) => record.id === this.state.selectMainSource?.id ? 'table-row-light' : ''}
                  pagination={this.state.mainSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  rowKey={(record) => record.id}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          selectMainSource: record,
                        });
                        this.inspectContentQuery(record)
                      }
                    };
                  }}
                >
                  <Table.Column title="受診日" dataIndex="visit_date_on" />
                  <Table.Column
                    title="コース情報"
                    dataIndex="contract_short_name"
                    render={(value, record, index) => {
                      return (
                        <Row>
                          <Form.Item name={['mainSource', index, 'visit_course']} style={{ marginRight: '15px' }}>
                            <span>{this.formRef.current?.getFieldValue(['mainSource', index, 'visit_course'])}</span>
                          </Form.Item>
                          <Form.Item name={['mainSource', index, 'contract_short_name']}>
                            <span>{this.formRef.current?.getFieldValue(['mainSource', index, 'contract_short_name'])}</span>
                          </Form.Item>
                        </Row>
                      )
                    }}
                  />

                </Table>
              </Col>
              <Col span={12}>
                <Table
                  bordered
                  dataSource={this.state.inspectContentQuery}
                  size="small"
                  pagination={this.state.inspectContentQuery.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  rowKey={(record) => record.id}
                >
                  <Table.Column title="検査名称" dataIndex="formal_name" />
                  <Table.Column title="結果値" dataIndex="result_value" />
                  <Table.Column title="判定" dataIndex="judgment_value" />
                </Table>
              </Col>
              <div className="box_button_bottom_right">
                <Button type='primary'
                  // icon={<SaveOutlined />}
                  onClick={() => this.select()}
                >
                  選択
                </Button>
              </div>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2653079_HealthCheckupInfoSelect);
