import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Table, Space, Row, Col, message, DatePicker, Modal} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import WS1314005_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314005_XmlMedicalExamItemRef.jsx";
import WS1314007_CsvOutputScreen from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314007_CsvOutputScreen.jsx";
import WS1314008_DateDisplay from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314008_DateDisplay";
import WS1314010_CsvCaptureScreen from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314010_CsvCaptureScreen";
import  ModalDraggable  from "components/Commons/ModalDraggable";
import WS1313001_XmlMedicalExamItemMaster from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314011_XmlMedicalExamItemMaster.jsx";

const styleFormItem = {
  margin: 0
}
class WS1314001_DataRangeCheck extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-XML03001:データ範囲チェック';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      rowSelected: [],
      startDateChar: '',
      filteredData: [],
    };
    this.handleAddRowTable = this.handleAddRowTable.bind(this)
    this.setFormFieldValue = this.setFormFieldValue.bind(this)
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      tableData: []
    });
    this.loadScreenData();
  }

  loadScreenData = () => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API001314001002", {})
      .then(res => {
        this.setState({dataSource: res, filteredData: res});
        this.formRef.current?.setFieldsValue({
          tableData: res ? res : [],
        });
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally(() => this.setState({ isLoading: false }))
  };

  onFinish(values) {
  }

  async handleAddRowTable() {
    let newRow = {
      DateAdoptionChars: null,
      item_code_jlac10_15: '',
      result_identification_jlac10_2: '',
      input_min_value: null,
      input_max_value: null,
      character_input_min_value: '',
      character_input_max_value: '',
      option_remark: '',
    };
    let data = [...this.state.dataSource];

    data.unshift(newRow);

    await this.setState({
      dataSource: data,
    });

    this.forceUpdate();
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  createOrUpdateData = (record) => {
    let mainSource = [];
    mainSource.push(record);
    VenusApiRoutesV2.callApi("API001314001013", {mainSource})
      .then(() => {
        message.success('成功');
        this.loadScreenData();
        this.forceUpdate();
      })
      .catch(err => message.error(err.response.data.message || "エラーが発生しました"))
  }

  async deleteData(record) {
    let mainSource = [];
    mainSource.push(record);
    VenusApiRoutesV2.callApi("API001314001014", {mainSource})
      .then(() => {
        message.success('成功');
        this.loadScreenData();
        this.forceUpdate();
      })
      .catch(err => message.error(err.response.data.message || "エラーが発生しました"))
  }

  handleF8 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS1313001_XmlMedicalExamItemMaster 
            onFinishScreen={(output) => { 
              this.closeModal()
            }}
            item_code_jlac10_15={this.state.rowSelected[0]?.item_code_jlac10_15}
          />
        ),
      },
    });
  }

  handleF9 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS1314007_CsvOutputScreen 
            onFinishScreen={(output) => { 
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  handleF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS1314010_CsvCaptureScreen 
            onFinishScreen={(output) => { 
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  renderMenuBar = () => (
    <Space>
      <Button type="primary" onClick={() => this.handleF8()}>
        JLac10
      </Button>

      <Button type="primary" onClick={() => this.handleF9()}>出力</Button>

      <Button type="primary" onClick={() => this.handleF10()}>取込</Button>
    </Space>
  )

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }

  handleFilter = () => {
    this.setState({
      filteredData: this.state.dataSource
    })
    if (this.state.startDateChar) {
      this.setState({
        filteredData: this.state.dataSource.filter(item => item.DateAdoptionChars === this.state.startDateChar)
      })
    }
  }

  render() {
    return (
      <div className="data-range-check">
        <Card className="mb-2" title="V4-XML03001:データ範囲チェック">
          {this.renderMenuBar()}
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row style={{ margin: '5px 0 10px 0px' }}>
              <Col span={3}>
                <Input.Search
                  type="text" 
                  value={this.state.startDateChar}
                  onSearch={() => 
                    this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 400,
                      component: (
                        <WS1314008_DateDisplay
                          onFinishScreen={async (output) => {
                            await this.setState({startDateChar: output.Date });
                            this.closeModal();
                          }}
                        />),
                    }
                  })}
                />
              </Col>
              <Col span={21}>
                <Form.Item
                >
                  <Button type="primary" style={{ float: 'right'}} onClick={() => this.handleFilter()}>表  示</Button>
                </Form.Item>
              </Col>
            </Row>

            <Table
              bordered
              size="small"
              dataSource={this.state.filteredData}
              loading={this.state.isLoading}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                showSizeChanger: true,
                onChange: (page, pageSize) => { },
                onShowSizeChange: (page, pageSize) => { },
              }}
              rowKey={(record) => record.id}
              rowSelection={{
                type: 'radio',
                onChange: async (selectedRowKeys, selectedRows) => {
                  await this.setState({
                    rowSelected: selectedRows,
                  });
                },
              }}
            >

              <Table.Column  width={'15%'} 
                title="採用日"
                dataIndex={'DateAdoptionChars'}
                showSorterTooltip={false} 
                sorter={(a, b) => a.DateAdoptionChars.localeCompare(b.DateAdoptionChars, 'jp')}
                />
              <Table.Column 
                width={'12%'} 
                title="項目コード"
                dataIndex="item_code_jlac10_15" 
                showSorterTooltip={false} 
                sorter={(a, b) => a.item_code_jlac10_15.localeCompare(b.item_code_jlac10_15, 'jp')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['tableData', index, 'item_code_jlac10_15']} style={styleFormItem}>
                        <Input.Search
                          onSearch={() => {
                            this.setState({
                              ...this.state,
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1000,
                                component: (<WS1314005_XmlMedicalExamItemRef
                                  onFinishScreen={async (output) => {
                                    let data = [...this.state.dataSource];
                                    data[index]['item_code_jlac10_15'] = output.recordData.item_code_jlac10_15;
                                    await this.setState({
                                      dataSource: data
                                    })
                                    this.formRef.current.setFields([{
                                      name: ['tableData', index, 'item_code_jlac10_15'],
                                      value: output.recordData.item_code_jlac10_15,
                                    }]);
                                    this.forceUpdate();
                                    this.closeModal();
                                  }}
                                />),
                              },
                            })
                          }} 
                        />
                      </Form.Item>
                  
                }}  
              />
              <Table.Column  width={'10%'} title="結果識別" dataIndex="result_identification_jlac10_2" showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2.localeCompare(b.result_identification_jlac10_2, 'jp')}/>
              <Table.Column  width={'9%'} title="入力最小値" dataIndex="input_min_value" showSorterTooltip={false} sorter={(a, b) => a.input_min_value - b.input_min_value}/>
              <Table.Column  width={'9%'} title="入力最大値" dataIndex="input_max_value" showSorterTooltip={false} sorter={(a, b) => a.input_max_value - b.input_max_value}/>
              <Table.Column  width={'10%'} title="文字入力最小値" dataIndex="character_input_min_value" showSorterTooltip={false} sorter={(a, b) => a.character_input_min_value.localeCompare(b.character_input_min_value, 'jp')}/>
              <Table.Column  width={'10%'} title="文字入力最大値" dataIndex="character_input_max_value"showSorterTooltip={false} sorter={(a, b) => a.character_input_max_value.localeCompare(b.character_input_max_value, 'jp')}/>
              <Table.Column  width={'17%'} title="オプション" dataIndex="option_remark" showSorterTooltip={false} sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, 'jp')}/>
              <Table.Column width={'8%'} align='center'
                  title={() => (
                    <Button size='small' type='primary' icon={<PlusOutlined />}
                      onClick={() => {
                        if (!this.state.isChangeValue) {
                          this.handleAddRowTable()
                        } else {
                          message.error('情報が保存されていません')
                        }
                      }}
                    />
                  )}
                  render={(text, record, index) => (
                    <>
                      <Button size='small' style={{ border: 'none', }}
                        icon={<SaveOutlined style={{ color: 'green' }} />}
                        onClick={() => {
                          this.createOrUpdateData(record)}}
                      />
                      <Button size='small' style={{ border: 'none', }}
                        danger icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            content: '消去してもよろしいですか？',
                            okText: 'は　い',
                            cancelText: 'いいえ',
                            onOk: () => this.deleteData(record)
                          })
                        }}
                      />
                    </>
                  )}
                />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314001_DataRangeCheck);
