import { message } from 'antd'
import DocumentBatchCreateSubService from 'services/AdvancePreparation/DocumentBatchCreate/DocumentBatchCreateSubService'

const DocumentBatchCreateSubAction = {
  index(data) {
    return DocumentBatchCreateSubService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  selectForm(data) {
    return DocumentBatchCreateSubService.selectForm(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeSpecifiedIssue(data) {
    return DocumentBatchCreateSubService.changeSpecifiedIssue(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeOutputPattern(data) {
    return DocumentBatchCreateSubService.changeOutputPattern(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeOutputUnit(data) {
    return DocumentBatchCreateSubService.changeOutputUnit(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  print(data) {
    return DocumentBatchCreateSubService.print(data)
  },

  preview(data) {
    return DocumentBatchCreateSubService.preview(data)
  },

}
export default DocumentBatchCreateSubAction
