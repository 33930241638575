import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Tooltip, message } from 'antd'
import { SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS2786002_ConditionAddSubPlus from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786002_ConditionAddSubPlus.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import moment from 'moment'
import VisitsTargetSearchQueryService from 'services/ReservationBusiness/PersonalReserveProcess/VisitsTargetSearchQueryService'
import VisitsTargetSearchQueryAction from 'redux/ReservationBusiness/PersonalReserveProcess/VisitsTargetSearchQuery.action.js'
import Color from 'constants/Color'
class WS0273001_VisitsTargetSearchQuery extends React.Component {
  static propTypes = {
    LnkOutReserveNum: PropTypes.any,
    Li_StateFlagSpecify: PropTypes.any,
    Li_DateSpecify: PropTypes.any,
    Li_StsConditionSpecify: PropTypes.any,
    Li_StsReceiptAmountDisplay: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '受診対象者検索・照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: {},
      colorConditionAddBtn: 163,
      dataSource: [],
      conditionAddData: null,
    }
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      DateFChar:
        this.props.Li_StsConditionSpecify && this.props.Li_DateSpecify > 0
          ? this.props.Li_DateSpecify
          : moment(),
      DateTChar:
        this.props.Li_StsConditionSpecify && this.props.Li_DateSpecify > 0
          ? this.props.Li_DateSpecify
          : moment(),
    })
    this.forceUpdate()
    this.index()
  }

  /**
   * 受診者データを取得
   */
  index = () => {
    this.setState({ selectedRows: {} })

    const {
      DateFChar,
      DateTChar,
      CourseCodeF,
      CourseCodeT,
      FacilityType,
      OfficeCode,
      BranchStoreCodeF,
      PersonalNum,
      KeyNum,
    } = this.formRef.current.getFieldsValue(true)

    const params = {
      DateFChar:
        DateFChar > DateTChar
          ? moment(DateTChar).format('YYYY/MM/DD')
          : moment(DateFChar).format('YYYY/MM/DD'),
      DateTChar: moment(DateTChar).format('YYYY/MM/DD'),
      PersonalNum: PersonalNum,
      OfficeCode: OfficeCode,
      BranchStoreCodeF: BranchStoreCodeF,
      CourseCodeF: CourseCodeF,
      CourseCodeT: CourseCodeT,
      FacilityType: FacilityType,
      KeyNum: KeyNum,
      conditionAddData: this.state.conditionAddData,
    }

    VisitsTargetSearchQueryAction.index(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res.DisplayList
          })
          this.formRef.current.setFieldsValue({
            ...res,
          })
          this.forceUpdate()
        }
      })
      .catch((err) => {
        this.forceUpdate()
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
    this.formRef.current.setFieldsValue({
      DateFChar: DateFChar > DateTChar ? DateTChar : DateFChar,
    })
  }

  /**
   * 個人情報照会　モーダル
   * @param {*} personalNumId 
   */
  showWS2584019_PersonalInfoInquirySub = (personalNumId) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personalNumId}
          />
        ),
      },
    })

  }
  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='visits-target-search-query'>
        <Card title='受診対象者検索・照会'>
          <Form
            ref={this.formRef}
            initialValues={{
              FacilityType: 0,
              KeyNum: 'カナ順',
            }}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <div className='box_inner_horizontal' style={{ marginRight: '10px' }}>
                      <Form.Item name='DateFChar' label='受診日'>
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          className='input-size-10'
                          allowClear={false}
                        />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='DateTChar' >
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          className='input-size-10'
                          allowClear={false}
                        />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='PersonalNum'
                        label='個　人'
                      >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-allow-clear-4'
                          style={{ textAlign: 'right' }}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0248001_PersonalInfoSearchQuery
                                    date={moment(new Date()).format('YYYY/MM/DD')}
                                    searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                    onFinishScreen={({
                                      Lo_PersonalNumId,
                                      recordData,
                                    }) => {
                                      this.formRef.current.setFieldsValue({
                                        PersonalNum: Lo_PersonalNumId,
                                        kanji_name: recordData.kanji_name,
                                      })
                                      this.forceUpdate()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              }
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                kanji_name: '',
                                PersonalNum: ''
                              })
                              this.setState({
                                kanji_name: '',
                                PersonalNum: ''
                              })
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0248001_PersonalInfoSearchQuery
                                      date={moment(new Date()).format('YYYY/MM/DD')}
                                      searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                      onFinishScreen={({
                                        Lo_PersonalNumId,
                                        recordData,
                                      }) => {
                                        this.formRef.current.setFieldsValue({
                                          PersonalNum: Lo_PersonalNumId,
                                          kanji_name: recordData.kanji_name,
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                }
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <div style={{ paddingLeft: '10px' }}>
                          {this.formRef.current
                            ? this.formRef.current.getFieldValue('kanji_name')
                            : ''}
                        </div>
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='OfficeCode'
                        label='事業所'
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Search
                          allowClear={true}
                          style={{ textAlign: 'right' }}
                          className='input-search-size-allow-clear-4'
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                    getDataOfficeInfoRetrievalQuery={({
                                      office_code,
                                      branch_store_code,
                                      office_kanji_name,
                                    }) => {
                                      this.formRef.current.setFieldsValue({
                                        OfficeCode: office_code,
                                        BranchStoreCodeF: branch_store_code,
                                        office_kanji_name: office_kanji_name,
                                      })
                                      this.forceUpdate()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                BranchStoreCodeF: '',
                                office_kanji_name: ''
                              })
                              this.setState({
                                BranchStoreCodeF: '',
                                office_kanji_name: ''
                              })
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                      getDataOfficeInfoRetrievalQuery={({
                                        office_code,
                                        branch_store_code,
                                        office_kanji_name,
                                      }) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeCode: office_code,
                                          BranchStoreCodeF: branch_store_code,
                                          office_kanji_name: office_kanji_name,
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='BranchStoreCodeF'
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Search
                          style={{ textAlign: 'right' }}
                          className='input-search-size-allow-clear-4'
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                    getDataOfficeInfoRetrievalQuery={({
                                      office_code,
                                      branch_store_code,
                                      office_kanji_name,
                                    }) => {
                                      this.formRef.current.setFieldsValue({
                                        OfficeCode: office_code,
                                        BranchStoreCodeF: branch_store_code,
                                        office_kanji_name: office_kanji_name,
                                      })
                                      this.forceUpdate()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                OfficeCode: '',
                                office_kanji_name: ''
                              })
                              this.setState({
                                OfficeCode: '',
                                office_kanji_name: ''
                              })
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                      getDataOfficeInfoRetrievalQuery={({
                                        office_code,
                                        branch_store_code,
                                        office_kanji_name,
                                      }) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeCode: office_code,
                                          BranchStoreCodeF: branch_store_code,
                                          office_kanji_name: office_kanji_name,
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item style={{ marginRight: '10px' }}>
                        <div>
                          {this.formRef.current
                            ? this.formRef.current.getFieldValue(
                              'office_kanji_name'
                            )
                            : ''}
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='コース' name='CourseCodeF'>
                        <Input.Search
                          allowClear={true}
                          className='input-size-10'
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    Li_CourseName={this.formRef.current?.getFieldValue('CourseCodeF')}
                                    onFinishScreen={({ Lo_CourseCode }) => {
                                      this.formRef.current.setFieldsValue({
                                        CourseCodeF: Lo_CourseCode,
                                      })
                                      this.forceUpdate()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      Li_CourseName={this.formRef.current?.getFieldValue('CourseCodeF')}
                                      onFinishScreen={({ Lo_CourseCode }) => {
                                        this.formRef.current.setFieldsValue({
                                          CourseCodeF: Lo_CourseCode,
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='CourseCodeT'>
                        <Input.Search
                          allowClear={true}
                          className='input-size-10'
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    Li_CourseName={this.formRef.current?.getFieldValue('CourseCodeT')}
                                    onFinishScreen={({ Lo_CourseCode }) => {
                                      this.formRef.current.setFieldsValue({
                                        CourseCodeT: Lo_CourseCode,
                                      })
                                      this.forceUpdate()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      Li_CourseName={this.formRef.current?.getFieldValue('CourseCodeT')}
                                      onFinishScreen={({ Lo_CourseCode }) => {
                                        this.formRef.current.setFieldsValue({
                                          CourseCodeT: Lo_CourseCode,
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item name='FacilityType' label='施　設'>
                      <Select>
                        {this.formRef.current
                          ? this.formRef.current.getFieldValue(
                            'FacilityTypeList'
                          )
                            ? this.formRef.current
                              .getFieldValue('FacilityTypeList')
                              .map((item, index) => {
                                return (
                                  <Select.Option
                                    value={item.LinkedField}
                                    key={index}
                                  >
                                    {item.DisplayField}
                                  </Select.Option>
                                )
                              })
                            : null
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='KeyNum'
                      label='表示順'
                      style={{ marginBottom: 0 }}
                    >
                      <Select>
                        {this.formRef.current
                          ? this.formRef.current.getFieldValue('KeyNumList')
                            ? this.formRef.current
                              .getFieldValue('KeyNumList')
                              .map((item, index) => {
                                return (
                                  <Select.Option
                                    value={item.LinkedField}
                                    key={index}
                                  >
                                    {item.DisplayField}
                                  </Select.Option>
                                )
                              })
                            : null
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => this.index()}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                    <Tooltip title='詳細な条件追加'>
                      <Button
                        icon={<PlusOutlined />}
                        size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        onClick={() => {
                          const {
                            DateFChar,
                            DateTChar,
                            CourseCodeF,
                            CourseCodeT,
                            FacilityType,
                            OfficeCode,
                            BranchStoreCodeF,
                            PersonalNum,
                            KeyNum,
                          } = this.formRef.current.getFieldsValue(true)
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              centered: true,
                              component: (
                                <WS2786002_ConditionAddSubPlus
                                  Li_DateF={DateFChar}
                                  Li_DateT={DateTChar}
                                  Li_CourseF={CourseCodeF}
                                  Li_CourseT={CourseCodeT}
                                  Li_FacilityType={FacilityType}
                                  Li_Office={OfficeCode}
                                  Li_BranchShop={BranchStoreCodeF}
                                  Li_PersonalNum={PersonalNum}
                                  Lio_KeyInfo={KeyNum}
                                  Li_Insurer={''}
                                  Li_KeySerialNumF={''}
                                  Li_KeySerialNumT={''}
                                  Li_ConditionAddData={this.state.conditionAddData}

                                  onFinishScreen={(output) => {
                                    // 追加条件のデータをstate変数に保持
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                      KeyInfo: output.Lio_KeyInfo ? output.Lio_KeyInfo : '',
                                    })
                                    this.formRef.current?.setFieldsValue({
                                      DateFChar: output.conditionAddData.DateFChar,
                                      DateTChar: output.conditionAddData.DateTChar,
                                      CourseCodeF: output.conditionAddData.CourseCodeF,
                                      CourseCodeT: output.conditionAddData.CourseCodeT,
                                      FacilityType: output.conditionAddData.FacilityType,
                                      OfficeCode: output.conditionAddData.OfficeCode,
                                      BranchStoreCodeF: output.conditionAddData.BranchStoreCode,
                                      PersonalNum: output.conditionAddData.PersonalNum,
                                      KeyNum: output.Lio_KeyInfo ? output.Lio_KeyInfo : '',
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                pagination={false}
                scroll={{ y: 400, x: 'max-content' }}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      if (this.props.onFinishScreen) {
                        this.props.onFinishScreen({
                          LnkOutReserveNum: record.reservation_number,
                          recordData: record,
                        })
                      }
                    }
                  }
                }}
              >
                <Table.Column
                  title='状態'
                  dataIndex='state'
                  className='column-size-4'
                  render={(text) => (
                    <span
                      style={{
                        color: (text === '受付') ? 'red' : (text === '予約') ? 'blue' : 'black',
                      }}
                    >
                      {text}
                    </span>
                  )}
                />
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  className='column-size-8'
                  render={(text) => (
                    <>{text ? moment(text).format('YYYY/MM/DD') : ''}</>
                  )}
                />
                <Table.Column
                  title='時間'
                  dataIndex='period_time'
                  className='column-size-6'
                  render={(text) => (
                    <>{text ? moment(text, 'HH:mm').format('HH:mm') : ''}</>
                  )}
                />
                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  className='column-size-10'
                  render={(text) =>
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  }
                />
                <Table.Column
                  title='メモ'
                  dataIndex='importance'
                  width={50}
                  align='center'
                  render={(text, record) => {
                    switch (record.importance) {
                      case 1:
                        return (
                          <InfoCircleOutlined
                            style={{ cursor: 'pointer', fontSize: 20, color: '#1890ff', }}
                            onClick={() => {
                              this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                            }}
                          />
                        )
                      case 3:
                        return (
                          <WarningOutlined
                            style={{ cursor: 'pointer', fontSize: 20, color: '#faad14' }}
                            onClick={() => {
                              this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                            }}
                          />
                        )
                      case 5:
                        return (
                          <CloseCircleOutlined
                            style={{ cursor: 'pointer', fontSize: 20, color: '#ff4d4f' }}
                            onClick={() => {
                              this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                            }}
                          />
                        )
                      default:
                        return (
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                            onClick={() => {
                              this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                            }}
                          />
                        )
                    }
                  }}
                />
                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name' />
                <Table.Column
                  title='性別'
                  dataIndex='gender'
                  className='column-size-4'
                  render={(text) => (
                    <span
                      style={{
                        color:
                          text === '女性'
                            ? 'red'
                            : text === '男性'
                              ? 'blue'
                              : 'black',
                      }}
                    >
                      {text}
                    </span>
                  )}
                />
                <Table.Column
                  title='年齢'
                  dataIndex='age'
                  className='column-size-4'
                  render={(text) =>
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  }
                />
                <Table.Column
                  title='生年月日'
                  dataIndex='birthday_on'
                  className='column-size-8'
                  render={(text) => <>{moment(text).format('NNy/MM/DD')}</>}
                />
                <Table.Column
                  title='受付No'
                  dataIndex='receipt_number'
                  className='column-size-5'
                  render={(text) =>
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  }
                />
                <Table.Column
                  title='事業所情報'
                  dataIndex='office_kanji_name'
                />
                <Table.Column
                  title='契約情報'
                  dataIndex='visit_course'
                  render={(text, record) => (
                    <span>{text + ' ' + record.contract_short_name}</span>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0273001_VisitsTargetSearchQuery)
