import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withScreenPreset } from "modules/common/with-screen-preset";
import { Card, Row, Col, Form, Input, Table, message, Radio } from "antd";
import ConsultTicketInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ConsultTicketInputSub.action'

const styleLabel = {
  background: "#1264bd",
  padding: "5px",
  border: "1px solid #fff",
  color: "#fff",
  textAlign: "center"
};

const styleLabelOnly = {
  background: "#b8cada",
  padding: "5px",
  border: "1px solid #fff",
  textAlign: "center"
};

class WS1296100_ConsultTicketInputSubDetailedInspection extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        defaultPageSize: 10,
        size: 1,
      },
      tableData: []
    };
  }
  componentDidMount = async () => {
    this.getScreenData()
  };

  getScreenData() {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.props.Li_CourseLevel
    }
    ConsultTicketInputSubAction.DetailedInspectionData(params)
      .then(res => {
        let obj = []
        let list = {
          id: 1,
          detailedInspection: '基　本',
          individualMedicalCheckup: res.data.basic_individual_medical_exam_unit_price,
          groupMedicalExamination: res.data.unit_price_exam_basic_population_health,
          settlement: res.data.Expresstion_117,
          classification: res.data.Expresstion_122
        }
        obj.push(list)
        let list2 = {
          id: 2,
          detailedInspection: '貧　血',
          individualMedicalCheckup: res.data.anemia_individual_medical_exam_unit_price,
          groupMedicalExamination: res.data.unit_price_exam_anemia_population_health,
          settlement: res.data.Expresstion_118,
          classification: res.data.Expresstion_123,
        }
        obj.push(list2)
        let list3 = {
          id: 3,
          detailedInspection: '心電図',
          individualMedicalCheckup: res.data.electrocardiograph_individual_medical_exam_unit_price,
          groupMedicalExamination: res.data.unit_price_diagnosis_electrocardiogram_population_ken,
          settlement: res.data.Expresstion_119,
          classification: res.data.Expresstion_124,
        }
        obj.push(list3)
        let list4 = {
          id: 4,
          detailedInspection: '眼　底',
          individualMedicalCheckup: res.data.fundus_individual_medical_exam_unit_price,
          groupMedicalExamination: res.data.fundus_group_medical_exam_unit_price,
          settlement: res.data.Expresstion_120,
          classification: res.data.Expresstion_125,
        }
        obj.push(list4)
        let list5 = {
          id: 5,
          detailedInspection: 'ｸﾚｱﾁﾆﾝ',
          individualMedicalCheckup: res.data.h30_creatinine_individual_medical_exam_unit_price,
          groupMedicalExamination: res.data.h30_creatinine_population_checkup_unit_price,
          settlement: res.data.Expresstion_121,
          classification: res.data.Expresstion_126,
        }
        obj.push(list5)
        this.setState({
          tableData: obj
        })
        this.formRef.current?.setFieldsValue({ individual_population_segments: res.data.individual_population_segments })
        this.forceUpdate()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingFm: false }))
  }

  render() {
    return (
      <div className="consult-ticket-input-process-list">
        <Card title="詳細検査">
          <Form
            ref={this.formRef}
          >
            <div className="box_inner_horizontal">
              <div className="div_label">区分</div>
              {this.formRef.current?.getFieldValue("individual_population_segments") !== 0 && this.formRef.current?.getFieldValue("individual_population_segments") !== undefined ?
                <div className="div_data" style={{ marginTop: 0 }}>
                  {this.formRef.current?.getFieldValue("individual_population_segments") === 1 ?
                    '個別健診' :
                    '集団健診'}
                </div> :
                <div className="div_data">--</div>
              }
            </div>
            <Table
              bordered
              dataSource={this.state.tableData ? this.state.tableData : []}
              size='small'
              rowKey={(record) => record.id}
              pagination={false}
            >
              <Table.Column
                title='詳細検査'
                dataIndex='detailedInspection'
                align="center"
              />
              <Table.Column
                title='個別健診'
                dataIndex='individualMedicalCheckup'
                align="center"
              />
              <Table.Column
                title='集団検診'
                dataIndex='groupMedicalExamination'
                align="center"
              />
              <Table.Column
                title='決済'
                dataIndex='settlement'
                align="center"
              />
              <Table.Column
                title='区分'
                dataIndex='classification'
                align="center"
              />
            </Table>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withScreenPreset
)(WS1296100_ConsultTicketInputSubDetailedInspection);