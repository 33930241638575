import { message } from 'antd'
import DockDisplayItemSettingService from 'services/Others/MedicalCheckupCalendar/DockDisplayItemSettingService'

const DockDisplayItemSettingAction = {
  getListData(params) {
    return DockDisplayItemSettingService.getListDataService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getLeftTable(params) {
    return DockDisplayItemSettingService.getLeftTableService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getRightTable(params) {
    return DockDisplayItemSettingService.getRightTableService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(params) {
    return DockDisplayItemSettingService.saveService(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default DockDisplayItemSettingAction
