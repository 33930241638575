import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/frame-reserve/frame-reserve",
  getCourse: "/api/frame-reserve/frame-reserve/get-course",
};

const FrameReserveService = {
  async getScreenDataService(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async getCourseService(params) {
    return axios.get(API_LIST.getCourse, { params });
  },
};

export default FrameReserveService;
