import React from 'react'
import { connect } from 'react-redux'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Card, Form, Input, Checkbox, Col, Row, Table, Space, Dropdown, Menu, Tooltip, Select, Popconfirm, message } from 'antd'
import {
  MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined,
  CloseCircleOutlined, MoreOutlined, PlusOutlined, DeleteOutlined,
  InfoOutlined, FileTextOutlined, SaveOutlined, CheckOutlined, SyncOutlined
} from '@ant-design/icons'
import { getSingleJudgementData, synchronizeJugement, getJudgementLevelList, getStartDateList, }
  from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions'
import WS0362021_RegisterJudgementDataSingle from './WS0362021_RegisterJudgementDataSingle.jsx'
import WS0362031_CopyJudgementData from './WS0362031_CopyJudgementData.jsx'
import WS0362023_RegisterTolerance from './WS0362023_RegisterTolerance.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import ModalChange from 'components/Commons/ModalChange'
import { ModalConfirm, ModalError } from 'components/Commons/ModalConfirm'

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}
const dateFormat = 'YYYY/MM/DD'

class WS0362011_InspectItemJudgeValueSettingSub extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)

    // document.title = '検査項目判定値設定Sub'
    //「判定」の正確なスペルは'judgement'だが、テーブルのカラム名が'judgment'になっているため、そのまま'judgment'とする


    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceSingle: [],
      isLoadingPage: false,
      colorText: 208,
      standardDate: moment().format(dateFormat),
      judgementLevel: '00',
      startDate: '0000/00/00',
      AClassJudge: 'A'
    }
  }


  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
    this.getJudgementLevel()
  }


  getJudgementLevel = () => {
    getJudgementLevelList()
      .then(res => {
        if (res) {
          let judgementLevelItems = res.data
          this.setState({
            judgementLevelItem: judgementLevelItems,
            judgementLevel: judgementLevelItems[0].condition_1,
          })
          this.formRef.current.setFieldsValue({
            judgementLevel: judgementLevelItems[0].condition_1,
          })
        }
        this.getStartDate()
      }
      )
  }

  getStartDate = () => {
    let values = {
      judgeMethod: this.props.judgeMethod,
      // judgment_level: this.state.judgementLevel,
      judgment_level: this.formRef.current.getFieldValue('judgementLevel'),
      judgment_code: this.props.record.test_item_code,
      standardDate: this.state.standardDate,
    }

    getStartDateList(values)
      .then(res => {
        if (res) {
          let startDateItems = res.data.dates
          this.setState({
            startDateItem: startDateItems,
            startDate: res.data.date
          })
          this.formRef.current.setFieldsValue({
            startDate: res.data.date
          })
        }
        let params = {
          judgementLevel: values.judgment_level,
          startDate: res.data.date
        }
        this.loadSingleJudgementData(params)
      }
      )
  }


  handleChangeValue = (chengeObject) => {
    let params = {
      judgementLevel: chengeObject.judgementLevel ? chengeObject.judgementLevel : this.state.judgementLevel,
      startDate: chengeObject.startDate ? chengeObject.startDate : this.state.startDate
    }
    this.loadSingleJudgementData(params)
  }

  loadSingleJudgementData = (params) => {
    let values = this.formRef.current.getFieldsValue(true)
    let paramsS = {
      ...this.props.record,
      ...values,
      ...params,
      sex: this.props.sex
    }
    getSingleJudgementData(paramsS)
      .then(res => {
        let judgement = res.data.judgementData
        let normalValue = res.data.normalValueData
        let toleranceValue = res.data.toleranceData
        if (res) {
          this.setState({
            dataSourceSingle: judgement,
            AClassJudge: res.data.AClassJudgeData.judgment_result,
          })
        }
        this.formRef.current.setFieldsValue({
          normal_value_pattern_character: normalValue ? normalValue.normal_value_pattern_character : '',
          low_tolerance_value: toleranceValue ? toleranceValue.lower_limit_value : '',
          high_tolerance_value: toleranceValue ? toleranceValue.upper_limit_value : '',
        })
      })
      .catch()
  }


  copyJudgementData = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362031_CopyJudgementData
            judgeMethod={0}
            exam_short_name={this.props.record.exam_short_name}
            sex={this.props.sex}
            judgment_code={this.props.record.test_item_code}
            judgementLevel={this.formRef.current.getFieldValue('judgementLevel')}
            judgementLevelItem={this.state.judgementLevelItem}
            startDateF={this.formRef.current.getFieldValue('startDate')}
            startDateItem={this.state.startDateItem}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }




  editJudgementForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362021_RegisterJudgementDataSingle
            id={record.id}
            judgment_code={this.props.record.test_item_code}
            judgment_level={this.formRef.current.getFieldValue('judgementLevel')}
            start_date_on={this.formRef.current.getFieldValue('startDate')}
            sex={this.props.sex}
            exam_type={this.props.record.exam_type}
            exam_name={this.props.record.exam_name}
            exam_comment_code={this.props.record.exam_comment_code}
            record={record}
            newFlag={false}
            AClassJudge={this.state.AClassJudge}
            normal_value_pattern_character={this.formRef.current.getFieldValue('normal_value_pattern_character')}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addJudgementForm = (params) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0362021_RegisterJudgementDataSingle
            id={null}
            exam_type={this.props.record.exam_type}
            exam_name={this.props.record.exam_name}
            exam_comment_code={this.props.record.exam_comment_code}
            judgment_code={this.props.record.test_item_code}
            judgment_level={this.formRef.current.getFieldValue('judgementLevel')}
            start_date_on={this.formRef.current.getFieldValue('startDate')}
            sex={this.props.sex}
            newFlag={true}
            AClassJudge={this.state.AClassJudge}
            normal_value_pattern_character={this.formRef.current.getFieldValue('normal_value_pattern_character')}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editToleranceForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 350,
        component: (
          <WS0362023_RegisterTolerance
            judgment_code={this.props.record.test_item_code}
            judgment_level={this.formRef.current.getFieldValue('judgementLevel')}
            start_date_on={this.formRef.current.getFieldValue('startDate')}
            sex={this.props.sex}
            exam_type={this.props.record.exam_type}
            exam_name={this.props.record.exam_name}
            low_tolerance_value={this.formRef.current.getFieldValue('low_tolerance_value')}
            high_tolerance_value={this.formRef.current.getFieldValue('high_tolerance_value')}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }



  syncJudgement = () => {
    this.setState({ isLoadingPage: true })
    let values = this.formRef.current.getFieldsValue(true)
    let params = {
      ...this.props.record,
      ...values,
      sexF: this.props.sex,
      sexT: this.props.sex === 1 ? 2 : 1,
      judgeMethod: 0
    }
    synchronizeJugement(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  RegisterBtn() {
    let values = this.formRef.current.getFieldsValue(true)
    let getId = this.props.record ? this.props.record.id : null
    let params = {
      ...values,
      id: getId
    }
    this.RegisterBtn(params)
  }


  reviewScreen() {
    this.getStartDate()
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='use-inspect'>
        <Card title={(this.props.sex === 1 ? '検査項目判定値設定（単品）男性：' : '検査項目判定値設定（単品）女性：') + this.props.record.exam_name}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top_modal'
              trigger='click'
              size='small'
              style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}
              overlay={() => (
                <Menu>
                  <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.copyJudgementData()}>
                    複写
                  </Menu.Item>
                  {this.props.record.exam_type !== 'X' && this.props.record.exam_type !== 'J' ?
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={() => this.editToleranceForm()}>
                      許容範囲
                    </Menu.Item>
                    : null}
                </Menu>
              )}
            >
              <Button
                className='option_btn_right_top_modal'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>


          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ standardDate: moment(this.state.standardDate) }}
          >
            <div className='box_inner_horizontal' >
              <Form.Item name='judgementLevel' label='判定レベル' style={{ width: '40%' }}  {...smGrid}>
                <Select onChange={(e) =>
                  this.getStartDate()}
                >
                  {this.state.judgementLevelItem?.map((item, index) => (
                    <Select.Option key={index} value={item.condition_1}>{item.item}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>


            <div className='box_inner_horizontal' >
              <Form.Item name='startDate' label='履歴' style={{ width: '40%' }}  {...smGrid}>
                <Select onChange={(e) => this.handleChangeValue({ startDate: e })}>
                  {this.state.startDateItem?.map(item => (
                    <Select.Option key={item.start_date} value={item.start_date}>{item.start_date}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' >
              <Form.Item name='normal_value_pattern_character' label='基準値' {...smGrid}>
                <Input bordered={false} readOnly maxLength={25} style={{ width: 200 }}
                />
              </Form.Item>

              <Form.Item name='low_tolerance_value' label='許容範囲' {...smGrid}>
                <Input bordered={false} readOnly maxLength={10} style={{ width: 120 }}
                />
              </Form.Item>

              <Form.Item name='high_tolerance_value' label='～' >
                <Input bordered={false} readOnly maxLength={10} style={{ width: 120 }}
                />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' >
              <div className='box_inner_vertical_label_top'>
                <Table bordered
                  dataSource={this.state.dataSourceSingle}
                  loading={false}
                  size='small'
                  pagination={false}
                  scroll={{ y: 200 }}
                  rowKey={(record) => record.id}
                  onRow={(record, rowIndex) => ({
                    onDoubleClick: () => this.editJudgementForm(record)
                  })}
                >
                  <Table.Column width={100} title='下限値' dataIndex='lower_limit_value' key='' />
                  <Table.Column width={100} title='上限値' dataIndex='upper_limit_value' key='' />
                  <Table.Column width={40} title='判定' align='center' dataIndex='judgment_result' key='' />
                  <Table.Column title='コメント' dataIndex='comment_content' key='' />
                  <Table.Column width={40} title='優先' align='center' dataIndex='priority' key='' />
                  <Table.Column width={40} align='center' title={<Button type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => { this.addJudgementForm() }}
                    size='small'
                  ></Button>} render={(value, record, index) => (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => { this.editJudgementForm(record) }}
                    />
                  )}
                  />
                </Table>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Popconfirm
                title={(this.props.sex === 1 ? '女性' : '男性') + 'にも適用しますか？'}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                okButtonProps={{ style: { width: '100px', height: '32px' } }}
                cancelButtonProps={{ style: { height: '32px' } }}
                onConfirm={() => this.syncJudgement()}
                okText='ＯＫ'
                cancelText='キャンセル'
              >
                <Button
                  icon={<SyncOutlined />}
                  type='primary'
                >
                  <span className='btn_label'>
                    性別同期
                  </span>
                </Button>
              </Popconfirm>
            </div>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0362011_InspectItemJudgeValueSettingSub)
