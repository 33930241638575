/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Form, Table, Menu, Button, Dropdown, Card } from 'antd'
import { PlusOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons'

import EscortDetailSubAction from 'redux/AdvancePreparation/DocumentManageMaintain/EscortDetailSub.action'
import WS0641002_EscortDetailSubEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641002_EscortDetailSubEdit.jsx'
import WS0641003_EscortDataLineEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641003_EscortDataLineEdit.jsx'


class WS0641001_EscortDetailSub extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      CompChecked: false,
      RowList: [],
      LineInfo: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.initial()
  }

  /**
   * 初期データ取得
   */
  initial() {
    EscortDetailSubAction.initial(this.props.record)
      .then((res) => {
        if (res) {
          this.index(res[0])
        }
      })
  }

  /**
   * データ取得
   * @param {*} record
   */
  index(record) {
    let params = {
      ...record,
      SearchChar: this.props.SearchChar,
    }

    EscortDetailSubAction.getDataTable(params)
      .then((res) => {
        let data = res ? [...res.Data] : []
        if (data.length > 0) {
          data.map((x, index) => {
            x.fontWeight = this.props.SearchChar ? (this.checkTextBoldBySearch(index, data) ? 'bold' : '') : ''
          })
        }
        this.setState({
          dataSource: data,
          CompChecked: res ? res.Comp : '',
          RowList: res.RowList ?? [],
          LineInfo: res.LineInfo ?? [],
        })
      })
  }

  checkTextBoldBySearch(index, data) {
    let rerult = false
    if (this.props.SearchChar && data.length > 0) {
      if (data[index].Message?.toString().includes(this.props.SearchChar)
        || data[index].Inspect?.toString().includes(this.props.SearchChar)
        || data[index].Condition?.toString().includes(this.props.SearchChar)
        || data[index].Gender?.toString().includes(this.props.SearchChar)
        || data[index].Remark?.toString().includes(this.props.SearchChar)
        || data[index].Gender?.toString().includes(this.props.SearchChar)
      ) {
        rerult = true
      }
    }
    return rerult
  }

  /**
   * 項目の新規追加・変更
   * @param {*} record
   */
  newAddChangeTable = (record = null) => {
    let data = [...this.state.dataSource]
    let lastData = data.length > 0 ? [data[data.length - 1].Num] : null
    let newflag = false
    let escort_code = this.props.record?.escort_code
    let index = null

    if (!record) {
      newflag = true
      if (lastData) {
        record = {
          ...record, Num: Number(lastData) + 1 //連番の最大値
        }
      } else {
        record = {
          ...record, Num: 1 //初めの連番値「1」
        }
      }
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0641002_EscortDetailSubEdit
            record={record}
            newflag={newflag}
            escort_code={escort_code}
            index={index}
            dataSource={this.state.dataSource}
            onFinishScreen={async () => {
              await this.closeModal()
              await this.initial()
            }}
          />
        )
      }
    })
  }

  /**
   * 開始行、行範囲の編集
   */
  fillTheGaps() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0641003_EscortDataLineEdit
            LineInfo={this.state.LineInfo}
            id={this.props.record.id}
            onFinishScreen={async () => {
              await this.closeModal()
              await this.initial()
            }}
          />
        )
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='escort-detail-sub'>
        <Card title='エスコート保守　内容設定 [詳細]' style={{ position: 'relative' }}>
          <div className='box_container'>
            <div className='box_search'>
              <div className='box_inner_horizontal'>

                <Table
                  size='small'
                  dataSource={this.state.RowList}
                  style={{ cursor: 'pointer' }}
                  pagination={false}
                  rowKey={(record) => record.id}
                  bordered
                  showHeader={false}
                >
                  <Table.Column
                    className='row-color'
                    width={90}
                    dataIndex='name'
                  />
                  {this.state.LineInfo.map((item, index) =>
                    <Table.Column
                      key={'Column' + index}
                      width='40px'
                      dataIndex={'line' + index}
                      render={(text, record, index) => {
                        return (
                          //表示するデータがあれば'right'、なければ'center'
                          <div
                            style={{
                              textAlign: (text === '-') ? 'center' : 'right'
                            }}
                          >
                            {text}
                          </div>
                        )
                      }}
                    />
                  )}
                </Table>

                <div className='box_search_btn'>
                  <Button
                    type='primary'
                    onClick={() => { this.fillTheGaps() }}
                    icon={<EditOutlined />}
                  >
                    <span className='btn_label'>
                      編集
                    </span>
                  </Button>
                </div>
              </div>
            </div>

            <Table
              size='small'
              style={{ cursor: 'pointer' }}
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ x: 500, y: 550 }}
              bordered
              onRow={(record) => {
                return {
                  onDoubleClick: async () => {
                    this.newAddChangeTable(record)
                  }
                }
              }}
            >
              <Table.Column
                title='連番'
                width={90}
                dataIndex='Num'
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />
              <Table.Column
                title='内容'
                dataIndex='Message'
              />
              <Table.Column
                title='基本条件'
                dataIndex='Inspectname'
              />
              <Table.Column
                width={40}
                align='center'
                title={
                  <Button
                    size='small'
                    onClick={() => { this.newAddChangeTable() }}
                    type='primary'
                    icon={<PlusOutlined />}
                  />
                }
                render={(text, record) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='menu-1'
                          onClick={() => {
                            this.newAddChangeTable(record)
                          }}
                        >
                          変更
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )}
              />
            </Table>
          </div>
        </Card>


        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0641001_EscortDetailSub)
