/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import moment from 'moment-timezone'
import Color from 'constants/Color'
import { connect } from 'react-redux'
import { Row, Col, Card, Form, Table, message, Input, Tooltip, Button, Dropdown, Menu, Modal } from 'antd'
import { DoubleLeftOutlined, LeftOutlined, RightOutlined, DoubleRightOutlined, MoreOutlined, QuestionCircleOutlined, SearchOutlined, HistoryOutlined, MenuOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import CheckupCalendarPresentationMock from 'pages/Mock/ZZ_Others/SANAI_MedicalCheckupCalendar/CheckupCalendarPresentationMock.jsx'
import CheckupCalendarDetailSearch from 'pages/ZZ_Others/SANAI_MedicalCheckupCalendar/CheckupCalendarDetailSearch.jsx'
import ReserveStatusSearchService from 'services/ReservationBusiness/ReserveStatusSearch/ReserveStatusSearchService'
import DockTentativeReserve from 'pages/YK_ReservationBusiness/SANAI_DockTentativeReserve/DockTentativeReserve.jsx'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import MedicalCheckupCalendarAction from 'redux/Others/MedicalCheckupCalendar/MedicalCheckupCalendar.action'
import CheckupCalendarPresentationHistoryMock from 'pages/Mock/ZZ_Others/SANAI_MedicalCheckupCalendar/CheckupCalendarPresentationHistoryMock.jsx'
import DockDisplayItemSettingMock from 'pages/Mock/ZZ_Others/SANAI_MedicalCheckupCalendar/DockDisplayItemSettingMock'
import ReserveStatusSearchCustom from 'pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveStatusSearchCustom.jsx'
import WS2580001_ScheduleChange from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2580001_ScheduleChange.jsx'
import WS1490001_SpecificDatePeopleNumSetting from 'pages/SM_SystemMaintenance/V4SM0004000_SpecificDatePeopleNumSetting/WS1490001_SpecificDatePeopleNumSetting'
import WS2543001_ApplicationInfoRegister from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2543001_ApplicationInfoRegister.jsx'

const { TextArea } = Input

const styles = {
  alignCenter: {
    align: 'center',
  },
  textAlign: {
    textAlign: 'center',
  },
}

const columnCount = 15

const cardStyle = {
  padding: 10
}

class MedicalCheckupCalendarMock extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '予約状況検索'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataTitle: {},
      dataShowColumnExamineeList: {},
      screenData: {},
      //Fields search
      YearsChar: '',
      FacilityType: '',
      TimeZone: '',
      PersonsSituation: '',
      WS2528047_DetailsExtract: {
        Lio_OfficeCode: '', // Office_Extract
        Lio_TimeZone: '',  // TimeZone_Extract
        office_kanji_name: '',
      },
      //selected row left table
      selectedRowRecordReserveStatusDisplay: {},
      selectedRowRecordReserveStatusDisplay2: {},
      isCloseModalLoadReserveStatusDisplayAgain: false,
      isCloseModalLoadExamListAgain: false,
      courseItemList: [],
      examItemList: [],
      selectedDate: '',
      selectedIndex: null,

      // selected Column
      selectedColumn: {
        num_01: false,
        num_02: false,
        num_03: false,
        num_04: false,
        num_05: false,
        num_06: false,
        num_07: false,
        num_08: false,
        num_09: false,
        num_10: false,
        num_11: false,
        num_12: false,
        num_13: false,
        num_14: false,
        num_15: false,
      },
      num_final: null,

      CourseList: null,
      ExamList: null,
      SelectState: null,

      isLoadingPage: false,
      columnList: [], //2ケタゼロ埋め（表示行）リスト,

      Confirm: 0,
      ProvisionalConfirmation: 0,
      ProvisionalReservation: 0,
      totalPeople: 0,
      detailConditions: '',
      detailConditionsName: '',
      dataSourceOld: [],
      nameScreen: '',
      ReserveStatusDisplay: [],
      ReserveStatusColumnList: [],
      rowSelectLeft: {},
      groupingFlagList: []
    }
  }

  formRef = React.createRef()

  componentDidMount = () => {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < columnCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({
      WS2577003_CalendarEmptySub: {
        Lio_Date: moment(new Date()).format('YYYY/MM/DD')
      },
      ReserveStatusColumnList: array
    })
    this.getScreenData()
  }

  getScreenData = () => {
    MedicalCheckupCalendarAction.getScreenData()
      .then((res) => {
        let CourseDisplay = res.CourseDisplay.split('')
        let InspectDisplay = res.InspectDisplay.split('')

        this.setState({
          screenData: res,
          CourseDisplay: CourseDisplay,
          InspectDisplay: InspectDisplay,
        })

        this.formRef.current.setFieldsValue({
          ...res,
          FacilityType: 1,
          YearsChar: moment(res.YearsDate).isValid()
            ? moment(res.YearsDate)
            : moment(),
        })

        this.onSearchRecreateDisplay()
      })
  }

  onSearchRecreateDisplay = () => {
    this.setState({
      isLoadingPage: true
    })
    const { YearsChar, FacilityType, TimeZone, PersonsSituation } = this.formRef.current.getFieldsValue()

    this.setState({ YearsChar, FacilityType, TimeZone, PersonsSituation })

    MedicalCheckupCalendarAction.recreateDisplay({
      YearsChar: moment(YearsChar).format('YYYY/MM/DD'),
      FacilityType,
      TimeZone,
      PersonsSituation,
      OfficeExtract: this.state.WS2528047_DetailsExtract.Lio_OfficeCode,
      TimeZoneExtract: this.state.WS2528047_DetailsExtract.Lio_TimeZone,
    })
      .then((res) => {

        let newObj1 = res.itemList1
        let Array1 = []
        if (newObj1 !== null) {
          Array1 = Object.values(newObj1)
        }
        let newObj2 = res.itemList2
        let Array2 = []
        if (newObj2 !== null) {
          Array2 = Object.values(newObj2)
        }

        this.setState({
          dataSource: res.ExamineeList,
          dataSourceOld: res.ExamineeList,
          courseItemList: Array1,
          examItemList: Array2,
          Confirm: res.Confirm,
          ProvisionalConfirmation: res.ProvisionalConfirmation,
          ProvisionalReservation: res.ProvisionalReservation,
          totalPeople: res.Confirm + res.ProvisionalConfirmation + res.ProvisionalReservation,
        })

        if (this.state.detailConditions === '') {
          this.setState({
            detailConditionsName: '',
            detailConditions: ''
          })
        } else {
          let mojiretu = this.state.detailConditionsName
          let itemJudgement = this.state.detailConditions
          let nameList = mojiretu.slice(5);
          this.detailReflectDisplay(itemJudgement, nameList)
        }
        this.leftTableRecreateDisplay()
      })
  }

  /**
   * 左のテーブル取得
   */
  leftTableRecreateDisplay() {
    const { YearsChar, FacilityType, TimeZone, PersonsSituation } = this.formRef.current.getFieldsValue()

    this.setState({ YearsChar, FacilityType, TimeZone, PersonsSituation })

    MedicalCheckupCalendarAction.leftTableRecreateDisplay({
      YearsChar: moment(YearsChar).format('YYYY/MM/DD'),
      FacilityType,
      TimeZone,
      PersonsSituation,
      OfficeExtract: this.state.WS2528047_DetailsExtract.Lio_OfficeCode,
      TimeZoneExtract: this.state.WS2528047_DetailsExtract.Lio_TimeZone,
    })
      .then((res) => {
        let datas = res?.ReserveStatusDisplay?.length > 0 ? res?.data?.ReserveStatusDisplay : {}
        // let date = dateCurrent ? moment(dateCurrent).format('YYYY/MM/DD') : reload ? moment(new Date()).format('YYYY/MM/DD') : moment(YearsChar).format('YYYY/MM/DD')
        let CourseDisplay = res.CourseDisplay.split('')
        let InspectDisplay = res.InspectDisplay.split('')

        this.setState({
          dataTitle: res,
          CourseList: '',
          ExamList: '',
          SelectState: '',
          CourseDisplay: CourseDisplay,
          InspectDisplay: InspectDisplay,
          // rowSelectRight: datas.find(x => moment(x.date).format('YYYY/MM/DD') == date),
          ReserveStatusDisplay: res.ReserveStatusDisplay,
          groupingFlagList: res.groupingFlagList
        })
        this.formRef.current.setFieldsValue({
          ...res,
          ReserveStatusDisplay: res.ReserveStatusDisplay,
        })
      })
  }

  previousMonth = () => {
    let newMonth = (Number(this.formRef.current.getFieldValue('YearsChar').format('MM')) - 1)
    let year = newMonth == 0 ? this.formRef.current.getFieldValue('YearsChar').format('YYYY') - 1 : this.formRef.current.getFieldValue('YearsChar').format('YYYY') //1月で前月ボタンを押した場合、前年に
    let month = newMonth == 0 ? 12 : ('00' + newMonth).slice(-2) //1月で前月ボタンを押した場合、12月に
    let day = ('00' + this.formRef.current.getFieldValue('YearsChar').format('DD')).slice(-2)
    let previousMonth = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(previousMonth) })
    this.onSearchRecreateDisplay()
  }

  previousYear = () => {
    let year = (Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) - 1)
    let month = this.formRef.current.getFieldValue('YearsChar').format('MM')
    let day = this.formRef.current.getFieldValue('YearsChar').format('DD')
    let previousYear = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(previousYear) })
    this.onSearchRecreateDisplay()
  }

  nextMonth = () => {
    let newMonth = (Number(this.formRef.current.getFieldValue('YearsChar').format('MM')) + 1)
    let year = newMonth == 13 ? Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) + 1 : this.formRef.current.getFieldValue('YearsChar').format('YYYY') //12月で次月ボタンを押した場合、翌年に
    let month = newMonth == 13 ? ('00' + 1).slice(-2) : ('00' + newMonth).slice(-2) //12月で次月ボタンを押した場合、1月に
    let day = ('00' + this.formRef.current.getFieldValue('YearsChar').format('DD')).slice(-2)
    let nextMonth = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(nextMonth) })
    this.onSearchRecreateDisplay()
  }

  nextYear = () => {
    let year = (Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) + 1)
    let month = this.formRef.current.getFieldValue('YearsChar').format('MM')
    let day = this.formRef.current.getFieldValue('YearsChar').format('DD')
    let nextYear = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(nextYear) })
    this.onSearchRecreateDisplay()
  }

  delete_Exam_User_Action_3A = (record) => {
    ReserveStatusSearchService.delete({
      StsConfirm: true,
      course_level: record.course_level,
      reserve_num: record.reserve_num,
    })
      .then((res) => {
        message.success('成功')
        this.onSearchRecreateDisplay()
      })
  }

  /**
   * 提示処理
   * @param {*} record
   */
  showCheckupCalendarPresentation = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <CheckupCalendarPresentationMock
            record={record}
            MedicalCheckFlag={true}
            user={this.props.user.name}

            onFinishScreen={(output) => {
              this.onSearchRecreateDisplay()
              this.closeModal()
            }}

            onDelete={() => {
              this.onSearchRecreateDisplay()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 本確定が押された時
   */
  confirmReserve = (record) => {
    MedicalCheckupCalendarAction.confirmReserve(record)
      .then((res) => {
        this.onSearchRecreateDisplay()
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 条件検索
   * @param {*} itemJudgement
   * @param {*} nameList
   */
  detailReflectDisplay = (itemJudgement, nameList) => {
    let dataSource = this.state.dataSourceOld
    let data = []
    let filterdataSource = []
    let recordArray = []
    let examStatusArray = []

    // 予備絞り込み（予約者がいない日,colour_flag「1」排除）
    for (let i = 0; i < dataSource.length; i++) {
      let count = dataSource[i].count ?? '0'
      let colourFlag = dataSource[i].colour_flag
      if (count !== 0) {
        if (colourFlag !== 1 || 0) {
          data.push(dataSource[i]);
        }
      }
    }

    // 指定した条件を満たす、項目の選別
    for (let i = 0; i < data.length; i++) {
      let judgment = true
      let examStatus = data[i].exam_status ?? ''
      recordArray = itemJudgement.split(",")
      examStatusArray = examStatus.split(",")

      for (let i = 0; i < 20; i++) {
        if (recordArray[i] == '1') {
          if (examStatusArray[i] == '0') {
            // 検索条件に当てはまらない
            judgment = false
            break
          }
        }
      }

      // 条件に対応するデータだけ、格納
      if (judgment) {
        filterdataSource.push(data[i]);
      }
    }

    this.setState({
      dataSource: filterdataSource,
      detailConditionsName: '条件検索：' + nameList,
      detailConditions: itemJudgement
    })

    this.forceUpdate()
  }

  detailReflectRelease = () => {
    this.setState({
      dataSource: this.state.dataSourceOld,
      detailConditionsName: '',
      detailConditions: '',
    })
    this.forceUpdate()
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    });
  }

  render() {
    return (
      <div className='non-consult-date-setting'>
        <Card title='人間ドックカレンダー （モック）' >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              TimeZone: '',
              PersonsSituation: 0,
            }}
          >
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className={(this.props.Li_Flag) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                // style={{ position: 'absolute', top: 16, right: 10, borderRadius: 10 }}
                trigger='click'
                size='small'
                // icon={<MenuOutlined />}
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1' style={{ width: '100%' }}
                        onClick={() =>
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <DockDisplayItemSettingMock
                                  onFinishScreen={(output) => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }
                      >
                        設定
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>
                <div className='box_inner_horizontal' style={{ width: 'fit-content', alignItems: 'center' }}>
                  <Tooltip title='前年'>
                    <Button
                      type='primary'
                      icon={<DoubleLeftOutlined />}
                      onClick={() => { this.previousYear() }}
                    />
                  </Tooltip>

                  <Tooltip title='前月'>
                    <Button
                      type='primary'
                      icon={<LeftOutlined />}
                      onClick={() => { this.previousMonth() }}
                    />
                  </Tooltip>

                  <Form.Item name='YearsChar' style={{ marginBottom: 0 }}>
                    <VenusDatePickerCustom
                      picker='month'
                      format='YYYY年MM月'
                      allowClear={false}
                      onChange={() => this.onSearchRecreateDisplay()}
                    />
                  </Form.Item>

                  <Tooltip title='次月'>
                    <Button
                      type='primary'
                      icon={<RightOutlined />}
                      onClick={() => { this.nextMonth() }}
                    />
                  </Tooltip>

                  <Tooltip title='次年'>
                    <Button
                      type='primary'
                      icon={<DoubleRightOutlined />}
                      onClick={() => { this.nextYear() }}
                    />
                  </Tooltip>

                  <Button
                    icon={<SearchOutlined />}
                    type='primary'
                    onClick={() => {
                      this.setState({
                        nameScreen: 'CheckupCalendarDetailSearch',
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 350,
                          component: (
                            <CheckupCalendarDetailSearch
                              examItemList={this.state.examItemList}
                              detailConditions={this.state.detailConditions}
                              onFinishScreen={(itemJudgement, nameList) => {
                                this.detailReflectDisplay(itemJudgement, nameList)
                                this.setState({
                                  nameScreen: '',
                                })
                                this.closeModal()
                              }}
                              onRelease={() => {
                                this.detailReflectRelease()
                                this.setState({
                                  nameScreen: '',
                                })
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  >
                    詳細検索
                  </Button>

                  {
                    <div>{this.state.detailConditionsName}</div>
                  }
                </div>

                <div style={{ border: '1px solid #808080', width: 'fit-content' }}>
                  <Row>
                    <Col style={{ width: '120px' }} >
                      <div className='style-div-title' style={{ backgroundColor: '#ffffff' }}>{'確定人数'}</div>
                      <div
                        style={{ width: '100%', textAlign: 'center' }}
                        className='style-form-item'
                      >
                        {this.state.Confirm + '人'}
                      </div>
                    </Col>

                    <Col style={{ width: '120px' }} >
                      <div className='style-div-title' style={{ backgroundColor: '#92E1B8' }}>{'提示中人数'}</div>
                      <div
                        style={{ width: '100%', textAlign: 'center' }}
                        className='style-form-item'
                      >
                        {this.state.ProvisionalConfirmation + '人'}
                      </div>
                    </Col>

                    <Col style={{ width: '120px' }} >
                      <div className='style-div-title' style={{ backgroundColor: '#f9f5bf' }}>{'仮予約状態人数'}</div>
                      <div
                        style={{ width: '100%', textAlign: 'center' }}
                        className='style-form-item'
                      >
                        {this.state.ProvisionalReservation + '人'}
                      </div>
                    </Col>

                    <Col style={{ width: '120px' }} >
                      <div className='style-div-title' style={{ backgroundColor: '#a4ebeb' }}>{'合計人数'}</div>
                      <div
                        style={{ width: '100%', textAlign: 'center' }}
                        className='style-form-item'
                      >
                        {this.state.totalPeople + '人'}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* Table1 */}
              <div className='box_inner_horizontal' >
                <div className='box_inner_vertical' style={{ width: '17%' }}>

                  <Table
                    id='customTable'
                    className='custom-table-scroll handle-table-calendar2'
                    size='small'
                    dataSource={this.state.ReserveStatusDisplay}
                    pagination={false}
                    bordered={true}
                    rowKey={(record) => record.id}
                    scroll={{ y: resizableTableScroll(75), x: 'max-content' }}
                    rowClassName={(record, index) => record.date === this.state.rowSelectLeft?.date ?
                      'table-row-light'
                      :
                      record.holidayColor === 281 ?
                        'table-row-holiday'
                        :
                        this.formRef.current.getFieldValue('PersonsSituation') == 1 ?
                          'table-row-upperlimit'
                          :
                          ''
                    }
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          if (record.date !== this.state.rowSelectLeft?.date) {
                            // this.getExamineeList({ date: record.date })
                          }
                          this.setState({
                            selectedDate: record.date,
                            rowSelectLeft: record
                          })
                        },
                      }
                    }}
                  >

                    <Table.Column
                      fixed='left'
                      title='日付'
                      width={40}
                      dataIndex='dateStr'
                      {...styles.alignCenter}
                      render={(text, record, index) => {
                        return (
                          <span
                            style={{
                              color: record.dateColor
                                ? Color(record.dateColor)?.Foreground
                                : '',
                            }}
                          >
                            {text + ' ' + record.weekStr}
                          </span>
                        )
                      }}
                    />
                    <Table.Column
                      title='注意事項'
                      width={60}
                      dataIndex='memo'
                    />
                    <Table.Column
                      width={30}
                      title='男'
                      dataIndex='MalePersons'
                      render={(text, record, index) => {
                        return text === '0' || text === 0 ? '' :
                          <div
                            style={{
                              textAlign: 'right',
                              paddingRight: '20%'
                            }}
                          >
                            {text}
                          </div>
                      }}
                    />
                    <Table.Column
                      width={30}
                      title='女'
                      dataIndex='WomanPersons'
                      render={(text, record, index) => {
                        return text === '0' || text === 0 ? '' :
                          <div
                            style={{
                              textAlign: 'right',
                              paddingRight: '20%'
                            }}
                          >
                            {text}
                          </div>
                      }}
                    />
                    {this.state.courseItemList.map((item, index) => {
                      const numIndex = ('0' + (index + 1)).slice(-2)
                      let columnComp = null
                      if (item !== '') {
                        columnComp = (
                          <Table.Column
                            key={`table-column-${index}`}
                            {...styles.alignCenter}
                            width={50}
                            title={item}
                            dataIndex={`num_${numIndex}`}
                          />
                        )
                        return columnComp
                      }
                    })}
                  </Table>
                </div>

                <div className='box_inner_vertical' style={{ width: '83%' }}>
                  <Table
                    className='custom-table-scroll handle-table-calendar3'
                    id='customTable'
                    size='small'
                    dataSource={this.state.dataSource}
                    pagination={false}
                    bordered
                    rowKey={(record) => record.id}
                    scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
                    rowClassName={(record, index) =>
                      index === this.state.selectedIndex ? 'table-row-light'
                        : record.colour_flag === 0 ? record.Expression_6 === 281 ? 'table-row-sunday' : ''
                          // 確定
                          : record.colour_flag === 1 ? ''
                            // 提示中
                            : record.colour_flag === 2 ? 'table-row-dock-tentative-onfirm'
                              // 仮予約状態
                              : record.colour_flag === 3 ? 'table-row-dock-tentative'
                                : ''
                    }

                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          this.setState({
                            selectedDate: record.date,
                            selectedIndex: rowIndex
                          })
                        },
                        onDoubleClick: () => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              centered: true,
                              component: (
                                <WS2537001_PersonalReserveProcess
                                  Li_CourseLevel={record.course_level}
                                  Li_ReserveNum={record.reserve_num}
                                  Li_PersonalNum={
                                    record.personal_number_id
                                  }
                                  Li_Date={record.date}
                                  Users={this.state.screenData.Users}
                                  Li_Getctxname={''}
                                  Li_ProcessDivision={''}
                                  Li_Option={''}
                                  Li_Child={true}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                    this.onSearchRecreateDisplay()
                                  }}
                                />
                              ),
                            },
                          })
                        },
                      }
                    }}
                  >

                    <Table.Column
                      title='日付'
                      className='column-size-5'
                      dataIndex='date_on'
                      render={(text, record, index) => {
                        return (
                          index > 0 ?   //ひとつ前の日付と同じときは非表示にする
                            this.state.dataSource[index - 1].date_on !== this.state.dataSource[index].date_on ?
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: record.Expression_5
                                    ? Color(record.Expression_5)?.Foreground
                                    : '',
                                }}
                              >
                                {text + ' ' + record.Expression_3}
                              </div> : null :
                            <div
                              style={{
                                textAlign: 'center',
                                color: record.Expression_5
                                  ? Color(record.Expression_5)?.Foreground
                                  : '',
                              }}
                            >
                              {text + ' ' + record.Expression_3}
                            </div>
                        )
                      }}
                    />

                    <Table.Column
                      title='人数'
                      dataIndex='count'
                      width={50}
                      render={(text, record, index) => {
                        return (
                          index > 0 ?
                            //ひとつ前の日付と同じときは非表示にする
                            this.state.dataSource[index - 1].date_on !== this.state.dataSource[index].date_on ?
                              <div style={{ textAlign: 'right' }}>
                                {text}
                              </div>
                              :
                              null
                            :
                            <div style={{ textAlign: 'right' }}>
                              {text}
                            </div>
                        )
                      }}
                    />

                    <Table.Column
                      className='column-size-2'
                      title='時間'
                      dataIndex='period_time'
                      render={(value, record, index) => {
                        return (
                          index > 0 ?
                            this.state.dataSource[index - 1].date_on !== this.state.dataSource[index].date_on || this.state.dataSource[index - 1].period_time !== this.state.dataSource[index].period_time ?
                              <div >{value}</div> : null : <div >{value}</div>
                        )
                      }}
                    />

                    <Table.Column
                      className='column-size-2'
                      title='ID'
                      align='left'
                      dataIndex='personal_number_id'
                      render={(text, record) => {
                        return (
                          <div style={{ textAlign: 'right' }}>{text}</div>
                        )
                      }}
                    />

                    <Table.Column
                      className='column-size-2'
                      dataIndex=''
                      render={(text, record) => {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              size='small'
                              icon={<HistoryOutlined />}
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 800,
                                    component: (
                                      <CheckupCalendarPresentationHistoryMock
                                        record={record}
                                      />
                                    ),
                                  },
                                })
                              }}
                            />
                          </div>
                        )
                      }}
                    />

                    <Table.Column
                      className='column-size-40'
                      title='氏名'
                      dataIndex='KanjiName'
                      align='left'
                    />

                    <Table.Column
                      className='column-size-4'
                      title='生年月日'
                      dataIndex='DateOfBirth'
                      render={(text) => text ? <>{moment(text).format('NNy/MM/DD')}</> : null}
                    />

                    <Table.Column
                      title='年齢'
                      width={50} //ソートあり幅
                      align='left'
                      dataIndex='Age'
                      render={(text, record) => {
                        return (
                          <div style={{ textAlign: 'right' }}>{text}</div>
                        )
                      }}
                    />

                    <Table.Column
                      className='column-size-2'
                      title='性'
                      dataIndex='Gender'
                      render={(text, record, index) => {
                        return (
                          <div
                            style={{
                              textAlign: 'center',
                              color: record.sexColorNum
                                ? Color(record.sexColorNum)?.Foreground
                                : '',
                            }}
                          >
                            {text}
                          </div>
                        )
                      }}
                    />

                    <Table.Column
                      title='主コース'
                      className='column-size-40'
                      align='left'
                      dataIndex='contract_short_name'
                    />

                    <Table.Column
                      title='担当者'
                      className='column-size-40'
                      dataIndex='UserName'
                    />

                    {
                      this.state.examItemList.map((item, index) => {
                        return (
                          this.state.InspectDisplay[index] == 1 ?
                            <Table.Column
                              key={index}
                              title={<div style={{ textAlign: 'left' }}>{item}</div>}
                              width={45}
                              render={(text, record) => {
                                let examStatus = record.exam_status ? record.exam_status.split(',') : []
                                const status = examStatus[index] ?? ''
                                let label = ''
                                if (status === '1') {
                                  label = '〇'
                                } else if (status === '0') {
                                  label = '　'
                                } else {
                                  // MRIやCTなどの予約時間
                                  label = status
                                }
                                return (
                                  <div style={{ textAlign: 'center' }}>{label}</div>
                                )
                              }}
                            /> : null
                        )
                      })
                    }

                    {/* <Table.Column
                      title='頸エコー'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='甲エコー'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='乳エコー'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='マンモ'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='子宮'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='MRI'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='CT'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='OP'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='心エコー'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    />
                    <Table.Column
                      title='EUS'
                      className='column-size-40'
                      // 後で決める
                      dataIndex=''
                    /> */}

                    <Table.Column
                      title={
                        <div >メモ</div>
                      }
                      className='column-size-40'
                      dataIndex='memo'
                    />

                    <Table.Column
                      className='column-size-40'
                      title='事業所名称'
                      dataIndex='office_kanji_name'
                      align='left'
                    />

                    <Table.Column
                      key='action'
                      fixed='right'
                      className='column-size-2'
                      render={(text, record, index) => {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            <Dropdown
                              trigger='click'
                              size='small'
                              overlay={() => (
                                <Menu>
                                  <Menu.Item
                                    key='1'
                                    onClick={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: true,
                                          centered: true,
                                          width: 1000,
                                          component: (
                                            <WS2583001_ConsultInquirySub
                                              Li_ReserveNum={record.reserve_num}
                                              Li_PersonalNum={record.personal_number_id}
                                              Users={this.state.screenData.Users}
                                              onFinishScreen={() => {
                                                this.closeModal()
                                              }}
                                            />
                                          ),
                                        },
                                      })
                                    }}
                                  >
                                    {/* 2583 */}
                                    <label>照会</label>
                                  </Menu.Item>

                                  <Menu.Item
                                    key='5'
                                    hidden={record.colour_flag == 0 || record.colour_flag == 1}
                                    onClick={() => {
                                      this.showCheckupCalendarPresentation(record)
                                    }}
                                  >
                                    <label>提示</label>
                                  </Menu.Item>

                                  <Menu.Item
                                    key='6'
                                    hidden={record.colour_flag !== 2}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '確認',
                                        icon: (
                                          <QuestionCircleOutlined
                                            style={{ color: '#1890ff' }}
                                          />
                                        ),
                                        content: '予約を確定しますか？',
                                        okText: '確定',
                                        cancelText: 'キャンセル',
                                        onOk: () => {
                                          this.confirmReserve(record)
                                        },
                                      })
                                    }}
                                  >
                                    <label>本確定</label>
                                  </Menu.Item>

                                  <Menu.Item
                                    key='3'
                                    hidden={record.state_flag === 1}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '確認',
                                        icon: (
                                          <QuestionCircleOutlined
                                            style={{ color: '#1890ff' }}
                                          />
                                        ),
                                        content: '予約を削除しますか？',
                                        okText: '削除',
                                        cancelText: 'キャンセル',
                                        onOk: () => {
                                          this.delete_Exam_User_Action_3A(record)
                                        },
                                      })
                                    }}
                                  >
                                    {/* Delete */}
                                    <label>削除</label>
                                  </Menu.Item>

                                  <Menu.Item
                                    key='4'
                                    onClick={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: true,
                                          width: '40%',
                                          component: (
                                            <WS0650001_DocumentBatchCreateSub
                                              Li_CourseLevel={record.course_level}
                                              Li_ReserveNum={record.reserve_num}
                                              onFinishScreen={() => {
                                                this.closeModal()
                                              }}
                                            />
                                          ),
                                        },
                                      })
                                    }}
                                  >
                                    {/* WS0650001 */}
                                    <label>予約関連</label>
                                  </Menu.Item>
                                </Menu>
                              )
                              }
                            >
                              <Button
                                size='small'
                                icon={<MoreOutlined />} />
                            </Dropdown>
                          </div>
                        )
                      }}
                    />
                  </Table>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label='フリーコメント'
                >
                  <Input.TextArea
                    rows={1}
                    style={{ width: 900 }}
                  />
                </Form.Item>

                <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
                  <Button
                    type='primary'
                    onClick={() => { window.open(location.protocol + '//' + location.host + '/reserve-status-search-custom/reserve-status-search-custom') }}
                  >
                    予約状況検索
                  </Button>

                  <Button
                    type='primary'
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 'fit-content',
                          centered: true,
                          component: (
                            <WS2537001_PersonalReserveProcess
                              Li_Date={this.state.selectedDate}
                              Li_Child={true}
                              Users={this.state.screenData.Users}
                              onFinishScreen={() => {
                                this.closeModal()
                                this.onSearchRecreateDisplay()
                              }}
                            />
                          ),
                        },
                        isCloseModalLoadExamListAgain: false,
                        isCloseModalLoadReserveStatusDisplayAgain: false,
                      })
                    }}
                  >
                    個人予約
                  </Button>

                  <Button
                    type='primary'
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 450,
                          component: (
                            <DockTentativeReserve
                              modalFlg={true}
                              onFinishScreen={() => {
                                this.closeModal()
                                this.onSearchRecreateDisplay()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  >
                    仮予約
                  </Button>
                </div>
              </div>
            </div>
          </Form >
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(MedicalCheckupCalendarMock)
