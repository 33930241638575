import { message } from 'antd'
import FindingsInputPhysiciiagnosisService from 'services/InputBusiness/SpreadInput/FindingsInputPhysiciiagnosisService'

const FindingsInputPhysiciiagnosisAction = {
  index(data) {
    return FindingsInputPhysiciiagnosisService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getCauseList(data) {
    return FindingsInputPhysiciiagnosisService.getCauseList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getDoctorDiagnosisList(data) {
    return FindingsInputPhysiciiagnosisService.getDoctorDiagnosisList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  save(data) {
    return FindingsInputPhysiciiagnosisService.save(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  delete(data) {
    return FindingsInputPhysiciiagnosisService.delete(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },


  ChangeDataAction(data) {
    return FindingsInputPhysiciiagnosisService.ChangeDataService(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  exitAction(data) {
    return FindingsInputPhysiciiagnosisService.exitService(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
      })
  },
}

export default FindingsInputPhysiciiagnosisAction
