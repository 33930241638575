import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { DeleteOutlined, SaveOutlined, } from '@ant-design/icons'
import { Card, Form, Input, Button, Select, Modal } from 'antd'
import WS2320001_QueryNameOfDisease from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2320001_QueryNameOfDisease.jsx'
import HistoryInputSubAction from 'redux/InputBusiness/NotInputCheckCategory/HistoryInputSub.action'

const formItemStyle = {
  labelCol: { style: { width: 90 } },
  wrapperCol: { style: { width: 'calc(100% - 90px)' } }
}

class WS0714002_HistoryInputSubChange extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    LnkinQueryMode: PropTypes.any,
    Li_HistoryList: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = '既往歴入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      disabledSaveBtn: true,
    }
    this.onValuesChange = this.onValuesChange.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {

    // 変更モードの場合
    if (this.props.record) {
      const data = { ...this.props.record }
      // 内容をFormに反映
      this.formRef.current?.setFieldsValue(data)
      // 強制的に再描画
      this.forceUpdate()
    }
  }

  /**
   * 保存
   */
  save = () => {
    const id = this.props.record?.id ?? null
    // Fromに設定されている値を取得(disease_name_code/name_of_a_disease/age/state_division/comment)
    const data = this.formRef.current.getFieldsValue(true)

    const params = {
      id: id,
      personal_number_id: this.props.Li_PersonalNum,
      ...data
    }
    console.log(params);

    HistoryInputSubAction.save(params)
      .then((res) => {
        if (this.props.onUpdate) {
          // 親画面に反映
          this.props.onUpdate()
        }
      })
  }

  /**
   * 削除
   */
  delete = () => {
    // 削除確認
    Modal.confirm({
      content: '削除します。よろしいですか？',
      okText: '削　除',
      cancelText: 'キャンセル',
      okType: 'danger',
      onOk: () => {
        const id = this.props.record?.id ?? null
        const params = {
          id: id,
        }
        // 削除
        HistoryInputSubAction.delete(params)
          .then((res) => {
            if (this.props.onUpdate) {
              // 親画面に反映
              this.props.onUpdate()
            }
          })
      }
    })
  }

  onFinish = (values) => { }


  /**
   * 病名照会 モーダル
   */
  showWS2320001_QueryNameOfDisease = (value = '') => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS2320001_QueryNameOfDisease
            Li_DiseaseNameCode={value}
            onFinishScreen={(output) => {
              // 病名コードと病名を反映
              this.formRef.current.setFieldsValue({
                disease_name_code: output?.Lio_DiseaseNameCode,
                name_of_a_disease: output?.recordData?.name_of_a_disease,
              })
              // 保存ボタンの活性
              this.changeDisabledSaveBtn()

              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   *  変更された場合、保存ボタンを活性
   */
  changeDisabledSaveBtn = () => {
    // 変更された場合、保存ボタンを活性する
    this.setState({ disabledSaveBtn: false })
  }

  /**
   * Form内で変更処理
   */
  onValuesChange = (event) => {
    // 保存ボタンの活性
    this.changeDisabledSaveBtn()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {

    return (
      <div className='history-input-sub'>
        <Card
          title={`既往歴入力 [${(this.props.record === null) ? '新規追加' : '変更'}]`}
        >
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            autoComplete='off'
          >
            <div className='box_container' style={{ gap: 0 }}>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='病名コード'
                  name='disease_name_code' //病名コード
                  {...formItemStyle}
                >
                  <Input.Search
                    className='input-search-size-number-8'
                    onSearch={(value) => {
                      // 病名照会
                      this.showWS2320001_QueryNameOfDisease(value)
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>
                  {this.formRef.current?.getFieldValue('name_of_a_disease')}
                </div>
              </div>

              <Form.Item
                name='age'
                label='年齢'
                {...formItemStyle}
              >
                <Input
                  className='input-size-number-4'
                />
              </Form.Item>

              <Form.Item
                name='state_division'
                label='状態区分'
                {...formItemStyle}
              >
                <Select
                  style={{ width: 100 }}
                >
                  {this.props.StateDivisionCharList.map((item, index) => (
                    <Select.Option
                      key={index}
                      value={item.node_code_name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>


              <Form.Item
                name='comment'
                label='コメント'
                {...formItemStyle}
              >
                <Input />
              </Form.Item>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: ((this.props.record === null) ? 'none' : '') }} //新規追加の場合、削除ボタンは非表示
                onClick={() => {
                  // 削除
                  this.delete()
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
                onClick={() => {
                  // 保存
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0714002_HistoryInputSubChange)
