import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Table, Dropdown, Menu, Form } from "antd";
import { PlusOutlined, CheckOutlined, MoreOutlined } from '@ant-design/icons';

import ModalDraggable from "components/Commons/ModalDraggable";
import WS0493001_ConditionCopyingScreen from "./WS0493001_ConditionCopyingScreen";
import WS0483002_ConditionExpressCmtSubDetail from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0483002_ConditionExpressCmtSubDetail.jsx"
import WS0483005_ConditionExpressCmtSubEdit from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0483005_ConditionExpressCmtSubEdit.jsx"
import ConditionExpressCmtSubAction from 'redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressCmtSub.action'

class WS0483001_ConditionExpressCmtSub extends React.Component {

  static propTypes = {
    Li_GuideMattersCode: PropTypes.number,
    Li_AdoptionDate: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '条件式コメントSUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      isLoadingTable1: false,
      selectedRow1: [],
      selectedRowKeys1: [],
      indexTable1: 0,
      old_serial_number: '',

      dataSource2: [],
      isLoadingTable2: false,
      selectedRow2: [],
      selectedRowKeys2: [],
      indexTable2: 0,
      old_branch_number: '',

      Lio_System: 0
    };
  }

  componentDidMount() {
    this.getData()
  }

  /**
   * データ取得
   */
  getData() {
    let param = {
      Li_GuideMattersCode: this.props.record.comment_code,
      Li_AdoptionDate: this.props.record.start_date_on ?? ''
    }

    ConditionExpressCmtSubAction.index(param)
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
        })
      })
  }

  /**
   * 複写処理
   */
  WS0493001ConditionCopyingScreen() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component:
          <WS0493001_ConditionCopyingScreen
            Li_GuideCmt={''}
            Li_StartDate={''}
            Li_SerialNum={''}
            Li_StsGuideCmt={true}
            Li_StsStartDate={true}
            Li_StsSerialNum={true}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ,
      },
    });
  }

  /**
   * 条件式編集
   */
  conditionExpressConfirm(record) {
    console.log(record);

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1200,
        component: (
          <WS0483002_ConditionExpressCmtSubDetail
            record={record}
          />
        ),
      },
    })
  }

  /**
   * コメント新規・編集
   * @param {*} record
   */
  handleAddRowTable(record, newFlag) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 600,
        component: (
          <WS0483005_ConditionExpressCmtSubEdit
            newFlag={newFlag}
            record={record}
            onSave={(output) => {
              let param = {
                ...output,
                guidance_comment_code: this.props.record.comment_code,
                start_date_on: this.props.record.start_date_on
              }

              ConditionExpressCmtSubAction.saveAndUpdate(param)
                .then(res => {
                  this.closeModal()
                  this.getData()
                  this.props.onFinishScreen()
                })
            }}

            onDelete={() => {
              let param = {
                ...record,
              }

              ConditionExpressCmtSubAction.delete(param)
                .then(res => {
                  this.closeModal()
                  this.getData()
                  this.props.onFinishScreen()
                })
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="condition-express-cmt-sub">
        <Card title={"指導事項【" + this.props.record.comment_code + "】を【" + this.props.record.start_date_on + "】以降に出力する条件"}>
          <div className='box_inner_vertical' >
            <Form.Item
              label='指導内容'
              style={{ marginBottom: 0 }}
            >
              <div >{this.props.record.comment_content}</div>
            </Form.Item>

            <div className='box_inner_horizontal'>
              <div className='box_inner_vertical' >
                <Table
                  bordered
                  size='small'
                  style={{ cursor: 'pointer' }}
                  dataSource={this.state.dataSource}
                  loading={this.state.isLoadingTable}
                  pagination={false}
                  scroll={{ x: 350, y: 700 }}
                  rowKey={(res) => res.id}
                  onRow={(record, index) => ({
                    onDoubleClick: () => { this.conditionExpressConfirm(record) }
                  })}
                >

                  <Table.Column
                    title="有効"
                    dataIndex="enabled"
                    width={45}
                    align='center'
                    render={(value, record, index) => {
                      if (value) {
                        return <CheckOutlined />
                      } else {
                        return ''
                      }
                    }}
                  />

                  <Table.Column
                    title="連番"
                    dataIndex="serial_number"
                    width={80}
                    render={(record) => {
                      return <div style={{ textAlign: 'right' }}>{record}</div>
                    }}
                  />

                  <Table.Column
                    title="名称"
                    dataIndex="name"
                    width={200}
                  />

                  <Table.Column
                    title="備考"
                    dataIndex="Remarks"
                    render={(value) => (
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          width: 250
                        }}
                      >
                        {value}
                      </div>
                    )}
                  />

                  <Table.Column
                    title="優先"
                    dataIndex="priority"
                    width={70}
                    render={(record) => {
                      return <div style={{ textAlign: 'right' }}>{record}</div>
                    }}
                  />

                  <Table.Column
                    title="条件式"
                    dataIndex="method"
                    width={60}
                    render={(record) => {
                      return <div style={{ textAlign: 'center' }}>{record == 0 ? 'OR' : 'AND'}</div>
                    }}
                  />

                  <Table.Column
                    title="条件"
                    dataIndex="Expression_14"
                    width={45}
                    render={(record) => {
                      return <div style={{ textAlign: 'center' }}>{record}</div>
                    }}
                  />

                  <Table.Column
                    width={40}
                    title={
                      <div style={{ textAlign: "center" }}>
                        <Button
                          size='small'
                          onClick={() => this.handleAddRowTable('Condition', true)}
                          type="primary"
                          icon={<PlusOutlined />}
                        >
                        </Button>
                      </div>
                    }
                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger='click'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='変更'
                                onClick={() => (
                                  this.handleAddRowTable(record, false)
                                )}
                              >変更
                              </Menu.Item>

                              <Menu.Item
                                key='条件式編集'
                                onClick={() => (
                                  this.conditionExpressConfirm(record)
                                )}
                              >条件式編集
                              </Menu.Item>

                              {/* <Menu.Item
                                key='複写'
                              onClick={() => (
                                this.WS0493001ConditionCopyingScreen()
                              )}
                              >複写
                              </Menu.Item> */}
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                            style={{ width: '100%' }}
                          ></Button>
                        </Dropdown>
                      )
                    }}
                  />
                </Table>
              </div>
            </div>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0483001_ConditionExpressCmtSub);
