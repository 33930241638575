import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Table, Menu, Dropdown, Button, Card } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { getListDataConsultHistorySubAction } from 'redux/InputBusiness/ProgressSetting/ConsultHistorySub.actions'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub.jsx'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx'
import WS0802001_PrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0802001_PrintInstruction.jsx'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import moment from 'moment-timezone'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS2586020_ConsultHistorySub extends React.Component {

  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    scrollY: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '受診履歴SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {}
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    if (this.props.Li_PersonalNum) {
      this.getListDataConsultHistorySub(this.props.Li_PersonalNum)
    }
  }

  /**
   * 履歴 取得
   * @param {*} params
   */
  getListDataConsultHistorySub = (params) => {
    this.setState({ dataSource: [] })
    if (params) {
      this.setState({ isLoading: true, dataSource: [] })
      getListDataConsultHistorySubAction({ personal_number_id: params || '' })
        .then(res => {
          if (res) {
            let data = res.data.map((item, index) => ({ ...item, id: index }))
            this.setState({ dataSource: data })
          }
        })
        .catch()
        .finally(() => this.setState({ isLoading: false }))
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='consult-history-sub'>
        <Card
          title={this.props.hideTitle ? null : '履歴'} // ※場合によってタイトルを非表示にする
          style={{ border: (this.props.hideTitle ?? false) ? 'none' : '' }}
          bodyStyle={{ padding: (this.props.hideTitle ?? false) ? 0 : '' }}
        >
          <Table
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            bordered
            scroll={{
              y: this.props.scrollY ? this.props.scrollY : 400,
              x: 'max-content',
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column
              title='受診日'
              className='column-size-date'
              dataIndex='visit_date_on'
              render={(text) => (<div>{moment(text).format('YYYY/MM/DD (ddd)')}</div>)}
            />

            <Table.Column
              title='状態'
              className='column-size-6'
              dataIndex='Expression_7'
              render={(text) => (
                <div
                  style={{
                    color: text === '受付' ? 'red' : 'blue',
                    textAlign: 'center'
                  }}>
                  {text}
                </div>
              )}
            />

            <Table.Column
              title='契約情報'
              dataIndex='Expression_9'
            />

            <Table.Column
              className='column-size-2'
              fixed='right'
              align='center'
              render={(text, record, index) => (
                <Dropdown
                  trigger='click'
                  overlay={() => (
                    <Menu >
                      <Menu.Item key='受診照会' onClick={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            width: 'fit-content',
                            visible: true,
                            component: (
                              <WS2583001_ConsultInquirySub
                                Li_ReserveNum={record.reservation_number}
                                onClick={() => {
                                  this.closeModal()
                                }}
                              />)
                          }
                        })
                      }}>受診照会</Menu.Item>

                      <Menu.Item key='予約関連' onClick={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            width: '60%',
                            visible: true,
                            component: (
                              <WS0650001_DocumentBatchCreateSub
                                Li_CourseLevel={''}
                                Li_ReserveNum={record.reservation_number}
                                Li_OutputUnit={''}
                                Li_OutputPattern={''}
                                onClick={() => {
                                  this.closeModal()
                                }}
                              />)
                          }
                        })
                      }}>予約関連</Menu.Item>

                      <Menu.Item key='結果照会'
                        hidden={record.state_flag !== 1}
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: '100%',
                              visible: true,
                              component: (
                                <WS2637001_OverallResultDisplayInput
                                  Li_MenuOption={''}
                                  Li_MenuAdminRights={''}
                                  Li_MenuAuthority={''}
                                  Li_SubjectNotBeChangedMode={''}
                                  Li_CourseLevel={''}
                                  Li_ReserveNum={record.reservation_number}
                                  onClick={() => {
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                        }}>結果照会</Menu.Item>

                      <Menu.Item key='結果印刷'
                        hidden={record.state_flag !== 1}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              component: (
                                <WS3020036_CoupledPrintInstruction
                                  Li_CourseLevel={''}
                                  Li_ReserveNum={record.reservation_number}
                                  onFinishScreen={(obj) => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}>結果印刷</Menu.Item>

                      <Menu.Item key='紹介状'
                        hidden={record.state_flag !== 1}
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 1200,
                              visible: true,
                              component: (
                                <WS0898001_IntroduceLetterExtractMaintain
                                  Li_MenuOption={''}
                                  Li_PersonalNum={record.personal_number_id}
                                  Li_ReserveNum={record.reservation_number}
                                  onClick={() => {
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                        }}>紹介状</Menu.Item>
                    </Menu>
                  )}>
                  <Button
                    size='small'
                    icon={<MoreOutlined />}></Button>
                </Dropdown>
              )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2586020_ConsultHistorySub)
