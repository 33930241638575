import { message } from 'antd'
import ComprehensiveMedicalExamBillingService from 'services/AccountingBusiness/MutualAidAssociationBilling/ComprehensiveMedicalExamBillingService.js'

const ComprehensiveMedicalExamBillingAction = {
  index(data) {
    return ComprehensiveMedicalExamBillingService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  totalling(data) {
    return ComprehensiveMedicalExamBillingService.totalling(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  preview(data) {
    return ComprehensiveMedicalExamBillingService.preview(data)
    // .then((res) => {
    //   return res?.data
    // })
    // .catch((err) => {
    //   const res = err.response
    //   if (!res || !res.data || !res.data.message) {
    //     message.error('エラーが発生しました')
    //     return
    //   }
    //   message.error(res.data.message)
    // })
  },

  print(data) {
    return ComprehensiveMedicalExamBillingService.print(data)
    // .then((res) => {
    //   return res?.data
    // })
    // .catch((err) => {
    //   const res = err.response
    //   if (!res || !res.data || !res.data.message) {
    //     message.error('エラーが発生しました')
    //     return
    //   }
    //   message.error(res.data.message)
    // })
  },
}
export default ComprehensiveMedicalExamBillingAction
