import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Button, Table, } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0411001_MasterListOutputCategoryInfo extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力[カテゴリ情報]';

    this.state = {
      CategoryCodeF: 0,
      CategoryCodeT: 0,
      dataSource: [],
      indexTable: 0
    };
  }

  componentDidMount() {
    this.setState({ isLoadingTable: true })

    VenusApiRoutesV2.callApi("API000411001001", {})
      .then((res) => {
        this.setState({
          categoryCodeF: res.CategoryCodeF,
          categoryCodeT: res.CategoryCodeT,
          isLoadingTable: false,
        });

        this.formRef.current.setFieldsValue({
          categoryCodeF: res.CategoryCodeF,
          categoryCodeT: res.CategoryCodeT,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    VenusApiRoutesV2.callApi("API000411001002", {})
      .then((res) => {
        this.setState({
          dataSource: res? res : [],
          isLoadingTable: false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) { }
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="master-list-output-category-info">
        <Card className="mb-2" title="マスタ一覧出力[カテゴリ情報]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="categoryCodeF"
              label="カテゴリコード[F]"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="categoryCodeT"
              label="カテゴリコード[T]"
            >
              <Input type="text" />
            </Form.Item>
            

          </Form>

          <Table bordered 
            size="small"
            dataSource={this.state.dataSource} 
            loading={this.state.isLoadingTable}
            pagination={true}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="カテゴリコード" dataIndex="category_code" key="1" 
              showSorterTooltip={false}
              sorter={(a, b) => a.category_code - b.category_code}
            />
            <Table.Column title="カテゴリ名称" dataIndex="category_name" key="2" 
              showSorterTooltip={false}
              sorter={(a, b) => a.category_name.localeCompare(b.category_name, 'ja')}
            />
            <Table.Column title="部位分類コード" dataIndex="site_classification" key="3" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification - b.site_classification}
            />
            <Table.Column title="部位分類名称" dataIndex="site_classification_name" key="4" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification_name.localeCompare(b.site_classification_name, 'ja')}
            />
            <Table.Column title="共通カテゴリコード" dataIndex="common_category_code" key="5" 
              showSorterTooltip={false}
              sorter={(a, b) => a.common_category_code - b.common_category_code}
            />
            <Table.Column title="検査区分" dataIndex="exam_division" key="6" 
              showSorterTooltip={false}
              sorter={(a, b) => a.exam_division - b.exam_division}
            />
          </Table>
          <div style={{ paddingTop: '1em'}}>
            <Button type="primary">閉じる</Button>
            <Button type="primary">検索</Button>
            <Button type="primary">出力</Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0411001_MasterListOutputCategoryInfo);
