import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/sales-situation-table-instructs/sales-situation-table-instructs/get-screen-data',
  preview: '/api/sales-situation-table-instructs/sales-situation-table-instructs/preview',
  print: '/api/sales-situation-table-instructs/sales-situation-table-instructs/print'
};

const SalesSituationTableInstructsService = {
  async getScreenData() {
    return axios.get(apiPaths.getScreenData);
  },
  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' })
  },
  async print(params) {
    return axios.post(apiPaths.print, params, { responseType: 'blob' })
  }
};

export default SalesSituationTableInstructsService;