import { message } from 'antd'
import PrinterInfoMaintainService from 'services/SM_SystemMaintenance/PrinterInfoMaintain/PrinterInfoMaintainService'

const PrinterInfoMaintainAction = {
  getScreenData(params) {
    return PrinterInfoMaintainService.getScreenData(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  printerInfoMaintenanceSub(data) {
    return PrinterInfoMaintainService.printerInfoMaintenanceSub(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default PrinterInfoMaintainAction
