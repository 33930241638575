import { message } from 'antd'
import InspectCmtSearchQueryService from 'services/InputBusiness/SpreadInput/InspectCmtSearchQueryService'

const InspectCmtSearchQueryAction = {
  index(data) {
    return InspectCmtSearchQueryService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}

export default InspectCmtSearchQueryAction
