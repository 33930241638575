
/**
 * ドキュメントタイトルの変更
 * @param {メニューの配列} data
 * @returns
 */
const getDocumentTitle = (data) => {

  const path = location.pathname

  let title = ''

  for (let i = 0; i < data.length; i++) {
    const submenus = data[i].submenu

    for (let j = 0; j < submenus.length; j++) {
      const obj = submenus[j]

      if (obj.path === path) {
        // 画面のタイトルを取得
        title = obj.title
        break
      }
    }
    if (title !== '') {
      break
    }
  }

  return title + ' アルファ・サルース'
}

export default getDocumentTitle

