import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Modal } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ConditionExpressCmtSubAction from 'redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressCmtSub.action'
import ModalDraggable from "components/Commons/ModalDraggable";


// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '40px' } }
}

class WS0487500_ConditionExpressEdit extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);


    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      minuteSelectDisabled: true,
      minuteDetailSelectDisabled: true,
      SelectOrSearchOrInput: 'Input',
      screenName: '',
      Code: '',
      andBox: '',

      HeaderCodeList: [],
      HeaderNameList: [],
      minuteSelect: [],
      selectDetailDataList: [],
      record: {}
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue(this.props.record)
    this.setState({
      record: this.props.record
    })
  }

  /**
   * 保存が押された場合
   */
  save() {
    let values = this.state.record
    let params
    if (values.minuteSelect === " ") {
      params = {
        ...values,
        id: this.props.record.id,
        code: this.state.Code,
        serial_number: this.props.params.serial_number,
        guidance_comment_code: this.props.params.guidance_comment_code,
        branchNumber: this.formRef.current.getFieldValue('branch_number')
      }
    } else {
      params = {
        ...values,
        id: this.props.record.id,
        code: this.state.Code,
        serial_number: this.props.params.serial_number,
        guidance_comment_code: this.props.params.guidance_comment_code,
        branchNumber: this.formRef.current.getFieldValue('branch_number')
      }
    }

    ConditionExpressCmtSubAction.saveDetails(params)
      .then((res) => {
        this.props.onSave()
      })
  }

  /**
   * 削除が押された場合
   */
  delete() {
    let values = this.state.record
    ConditionExpressCmtSubAction.deleteDetails(values)
      .then(res => {
        this.props.onSave()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="condition-express-add-sub">
        <Card title="編集" className="mb-2">
          <Form
            ref={this.formRef}
          >
            <div className="box_inner_vertical">
              <Form.Item
                name="branch_number"
                label='SEQ'
                {...labelCol}
              >
                <Input style={{ marginBottom: 10, width: 110 }} />
              </Form.Item>

              <div className="box_inner_horizontal">
                <Form.Item
                  name="EquationDisplay"
                  label='条件式'
                  {...labelCol}
                >
                </Form.Item>
                <div style={{ marginTop: 5 }}>{this.formRef.current?.getFieldValue('EquationDisplay')}</div>
              </div>

              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    // 確認モーダルを表示してから、削除
                    Modal.confirm({
                      okButtonProps: {
                        danger: true,
                        icon: <DeleteOutlined />
                      },
                      content: '削除しますか？',
                      okText: <span className='btn_label'>{'削除'}</span>,
                      cancelText: 'キャンセル',
                      onOk: () => {
                        this.delete()
                      }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.save()
                  }}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0487500_ConditionExpressEdit);
