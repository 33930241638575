import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Radio, Dropdown, Menu, Space, Modal, message, Select, Table, InputNumber, AutoComplete, Popconfirm } from 'antd'
import { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, PrinterOutlined, SearchOutlined } from '@ant-design/icons'
import { DeleteOutlined } from '@ant-design/icons'
import React from 'react'
import axios from 'configs/axios'
import Color from 'constants/Color'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import IntroduceLetterIssuedMainAction from 'redux/SK_IntroductionLetter/IntroduceLetterIssuedMain/IntroduceLetterIssuedMain.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0898002_IntroduceLetterEdit from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898002_IntroduceLetterEdit.jsx'
import IntroduceLetterExtractService from 'services/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtract'

const dateFormat = 'YYYY/MM/DD'

const labelCol = {
  labelCol: { style: { width: 60 } }
}
class WS0904001_IntroduceLetterIssuedMain extends React.Component {
  formRef = React.createRef()
  tableRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '紹介状発行'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      values: {
        visit_date_on: '',
        receipt_number: '',
        kanji_name: '',
        reservation_number: 0,
        personal_number_id: '',
        course_level: 0,
        pattern_code: '',
      },
      mainSourceData: [],
      screenData: {},
      rowSelect: {},
      isLoadingRedeploy: false,
      isLoadingInit: false,
      previousMode: false,
      subTitle: '',
      selectedRowKeys: [],
      selectedRows: [],
      introduceLetterVariousMasters: [],
      RemarksList: [
        { value: "受診してください" },
        { value: "当院予約済み" }
      ],
      checkedItem: [],
      selectAllFlag: 0,
    }

    this.handleSearch = this.handleSearch.bind(this)
  }

  componentDidMount() {
    this.loadScreenData()
  }

  loadScreenData = () => {
    IntroduceLetterIssuedMainAction.GetScreenData()
      .then(res => {
        this.setState({
          screenData: res.result,
          introduceLetterVariousMasters: res.introduceLetterVariousMasters
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  showPersonalInfoInquirySub = id => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_personal_num={id}
            onClickedSelect={obj => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false
                }
              })
            }}
          />
        )
      }
    })
  }

  showPrintModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                this.Print_F12(output)
              }
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  showBasicCourseInquiry = (type) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={(output) => {

              if (type == 'MedicalExamCourseStart') {
                this.formRef.current.setFieldsValue({ MedicalExamCourseStart: output.Lo_CourseCode })

              } else {
                this.formRef.current.setFieldsValue({ MedicalExamCourseFinal: output.Lo_CourseCode })
              }

              this.closeModal()
              this.checkCourseCode()
            }}
          />
        ),
      },
    })
  }

  checkCourseCode() {
    let CourseCodeF = this.formRef.current?.getFieldValue('MedicalExamCourseStart')
    let CourseCodeT = this.formRef.current?.getFieldValue('MedicalExamCourseFinal')
    if (!CourseCodeT || CourseCodeF > CourseCodeT) {
      this.formRef.current?.setFieldsValue({
        MedicalExamCourseFinal: CourseCodeF,
      })
    }
  }

  handleSearch() {
    this.setState({ isLoadingSearch: true })

    const values = this.formRef.current.getFieldsValue()

    axios
      .post('/api/introduce-letter-issued-main/introduce-letter-issued-main/display', {
        ...values
      })
      .then(res => {
        this.loadTableData()
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => this.setState({ isLoadingSearch: false }))
  }

  loadTableData = () => {
    // 画面の更新が上手く機能しないので、一度変数をリセットする
    this.setState({
      listID: null,
      mainSourceData: [],
      rowSelect: {},
      selectedRowKeys: [],
      selectedRows: [],
    })

    let data = this.formRef.current?.getFieldValue()
    let params = {
      Li_1DateF: data.GdateFChar.format('YYYY/MM/DD'),
      Li_1DateT: data.GdateTChar.format('YYYY/MM/DD'),
      Li_1ReceiptNumF: data.ReceiptNumF,
      Li_1ReceiptNumT: data.ReceiptNumT,
      Li_1FacilityType: data.FacilityType,
      Li_1IssuingDivision: data.IssuingDivision,
      Li_1InsurerCode: data.InsurerCode,
      Li_1OfficeCode: data.OfficeCode,
      Li_1CourseStart: data.MedicalExamCourseStart,
      Li_1CourseFinal: data.MedicalExamCourseFinal
    }
    IntroduceLetterIssuedMainAction.loadTableData(params)
      .then(res => {
        if (res?.data) {
          let arr = []
          let arrTemp = []
          res?.data.forEach(item => {
            arr.push(item.H58exId)
            arrTemp.push(item)
          })
          this.state.checkedItem = arrTemp

          let arrID = []
          if (res.data.length > 0) {
            res.data.forEach(element => {
              if (element.W1_instruction_flg) arrID.push(element.H58exId)
            })
          }

          this.setState({
            listID: arrID,
            mainSourceData: res.data,
            rowSelect: res.data.length > 0 ? res.data[0] : {},
            selectedRowKeys: arr,
            selectedRows: arrTemp,
            subTitle: ` [${res?.data.personal_number_id ?? ''}] ${res?.data.kanji_name ?? ''}`,
          })
        }
        return res.data;
      })
      .then(res => {
        IntroduceLetterIssuedMainAction.createW2LetterOfIntroduceExtract(res)
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  Print_F12 = (output) => {
    let params = {
      ...this.state.screenData,
      selectedRows: this.state.selectedRows,
      PreviewMode: output.Lo_Preview ? 1 : 0
    }
    if (output.Lo_Preview) {
      IntroduceLetterIssuedMainAction.preview(params)
        .then(res => {
          // pdfのObject作成　erviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data)
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          this.loadTableData()
        })
        .catch(error => {
          const res = error.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    } else {
      IntroduceLetterIssuedMainAction.Print_F12(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            // message.error('処理の途中でエラーが発生しました')
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
          this.loadTableData()
        })
        .catch(error => {
          const res = error.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  ReturnComponent = component => {
    let components = {
      WS2583001_ConsultInquirySub,
      WS2584019_PersonalInfoInquirySub
    }
    return components[component]
  }

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
  * 詳細モーダル
  */
  showWS0898002_IntroduceLetterEdit = (data) => {
    let values = {
      visit_date_on: data.visit_date_on,
      receipt_number: data.accepted_no
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS0898002_IntroduceLetterEdit
            record={data}
            values={values}
            subTitle={this.state.subTitle}
            previousMode={this.state.previousMode}
            introduceLetterVariousMasters={this.state.introduceLetterVariousMasters}
          />,
      },
    })
  }

  /**
  * 照会モーダル
  */
  showWWS2583001_ConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
          />,
      },
    })
  }

  // 受診科の変更
  ChangeDepartmentName = (selection, record, changevalue) => {
    let params = {
      ...record,
      selection: selection,
      changevalue: changevalue
    }

    IntroduceLetterIssuedMainAction.changeDepartmentName(params)
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  onDeleteSwitching = (checkedItem) => {

    let params = {
      checkedItem: checkedItem
    }

    console.log(params)

    this.setState({
      loading: true
    })
    IntroduceLetterExtractService.onDeleteSwitching(params)
      .then(res => {
        console.log('削除完了しました')
        this.loadTableData()
      })
      .catch(error => {
        message.error(error)
      })
  }

  render() {
    return (
      <div className='consult-info-reconstruction'>
        <Card title='紹介状発行'>
          <Form
            ref={this.formRef}
            initialValues={{
              GdateFChar: moment(moment().format(dateFormat)),
              GdateTChar: moment(moment().format(dateFormat)),
              ReceiptNumF: 0,
              ReceiptNumT: 999999,
              IssuingDivision: 0,
              FacilityType: this.state.screenData?.FacilityType_1
            }}
            autoComplete='off'
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>

                  <div className='box_search_inner_border'>
                    <div className='box_inner_horizontal' >
                      <Form.Item
                        name='GdateFChar'
                        label='受診日'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                      <Form.Item
                        name='GdateTChar'
                        label='～'
                        style={{ marginRight: '10px' }}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal' >
                      <Form.Item
                        name='ReceiptNumF'
                        label='受付No'
                        {...labelCol}
                      >
                        <InputNumber
                          maxLength={6}
                          className='input-size-number'
                        />
                      </Form.Item>
                      <Form.Item
                        name='ReceiptNumT'
                        label='～'
                      >
                        <InputNumber
                          maxLength={6}
                          className='input-size-number'
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='FacilityType'
                      label='施設'
                      {...labelCol}
                    >
                      <Select style={{ width: 150 }}>
                        {this.state.screenData.FacilityType_List?.map((value, index) => (
                          <Select.Option key={index} value={value.Linked}>
                            {value.Display}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='IssuingDivision'
                      label='対象'
                      {...labelCol}
                      style={{ marginBottom: 0 }}
                    >
                      <Radio.Group>
                        <Radio value={0}>全て</Radio>
                        <Radio value={1}>検査のみ</Radio>
                        <Radio value={2}>請求のみ</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='InsurerCode'
                        label='保険者'
                      >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-8'
                          onSearch={(value, event) => {
                            this.formRef.current.setFieldsValue
                              ({
                                OfficeCode: ''
                                , office_kanji_name: ''
                              })

                            switch (event.currentTarget.type) {
                              case 'button':
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '90%',
                                    component: (
                                      <WS0246001_InsurerInfoSearchQuery
                                        Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerCode')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue
                                            ({
                                              InsurerCode: output.Lo_InsurerCode
                                              , insurer_kanji_name: output.Lo_Name
                                            })
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                                break;
                              case 'text':
                                if (value == '') {
                                  this.formRef.current.setFieldsValue({ insurer_kanji_name: '' })
                                }
                                break;

                              default:
                                break;
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='insurer_kanji_name'>
                        <Input
                          type='text'
                          readOnly
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='OfficeCode'
                        label='事業所'
                      >
                        <Input.Search
                          allowClear={true}
                          type='text'
                          className='input-search-size-8'
                          onSearch={(value, event) => {
                            this.formRef.current.setFieldsValue
                              ({
                                InsurerCode: ''
                                , insurer_kanji_name: ''
                              })

                            switch (event.currentTarget.type) {
                              case 'button':
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '90%',
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                        Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchCodeStart')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue({
                                            OfficeCode: output.Lio_OfficeCode,
                                            BranchCodeStart: output.Lio_BranchStoreCode,
                                            office_kanji_name: output.Lo_Kanji_Name
                                          })
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                                break;

                              case 'text':
                                if (value == '') {
                                  this.formRef.current.setFieldsValue({ office_kanji_name: '' })
                                }
                                break;

                              default:
                                break;
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='office_kanji_name'
                        label='　　　'
                        style={{ width: 500 }}
                      >
                        <Input
                          type='text'
                          readOnly
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal' >
                      <Form.Item
                        name='MedicalExamCourseStart'
                        label='コース'
                      >
                        <Input.Search
                          type='text'
                          style={{ width: '100px' }}
                          allowClear={true}
                          onSearch={(value, event) => {
                            switch (event.currentTarget.type) {
                              case 'button':
                                this.showBasicCourseInquiry('MedicalExamCourseStart')
                                break;
                              case 'text':
                                if (value == '') {
                                  this.formRef.current?.setFieldsValue({ MedicalExamCourseStart: '' })
                                }

                                break;

                              default:
                                break;
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='MedicalExamCourseFinal'
                        label='~'
                        style={{ marginRight: '10px' }}
                      >
                        <Input.Search
                          type='text'
                          style={{ width: '100px' }}
                          allowClear={true}
                          onSearch={(value, event) => {
                            switch (event.currentTarget.type) {
                              case 'button':
                                this.showBasicCourseInquiry('MedicalExamCourseFinal')
                                break;
                              case 'text':
                                if (value == '') {
                                  this.formRef.current?.setFieldsValue({ MedicalExamCourseFinal: '' })
                                }
                                break;

                              default:
                                break;
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => this.loadTableData()}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.mainSourceData}
                rowKey={record => record.H58exId}
                rowSelection={{
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record) => {
                    let arrTemp = [...this.state.selectedRowKeys];
                    let arrTempRecord = [...this.state.selectedRows];
                    let idx = arrTemp.indexOf(record.H58exId);

                    if (idx === -1) {
                      arrTemp.push(record.H58exId);
                      arrTempRecord.push(record)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      });
                    } else {
                      arrTemp.splice(idx, 1);
                      arrTempRecord.splice(idx, 1);
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      });
                    }

                    this.state.checkedItem = arrTempRecord
                  },
                  onSelectAll: (selected) => {
                    if (selected) {
                      let arrTemp = this.state.mainSourceData.map(item => item.H58exId);
                      let arrTempRecord = this.state.mainSourceData;
                      this.state.checkedItem = this.state.mainSourceData
                      this.state.selectAllFlag = 1
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                      });
                    } else {
                      this.state.selectAllFlag = 0
                      this.setState({
                        selectedRowKeys: [],
                        selectedRows: [],
                      });
                    }
                  }
                }}
                onRow={(record, rowIndex) => ({
                  onClick: event => this.setState({ rowSelect: record })
                })}
                pagination={this.state.mainSourceData.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  className='column-size-date'
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_11)?.Foreground }}>
                        {moment(value).format('YYYY/MM/DD (ddd)')}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='受付No'
                  dataIndex='accepted_no'
                  width={60}
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          color: Color(record.Expression_11)?.Foreground,
                          textAlign: 'right'
                        }}
                      >
                        {value}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          color: Color(record.Expression_11)?.Foreground,
                          textAlign: 'right'
                        }}
                      >
                        {value}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='メモ'
                  dataIndex='Expression_16'
                  width={40}
                  align='center'
                  render={(text, record) => {
                    let icon = null
                    switch (record.Expression_16) {
                      case 1:
                        icon = (
                          <InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />
                        )
                        break
                      case 3:
                        icon = <WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />
                        break
                      case 5:
                        icon = (
                          <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
                        )
                        break
                      default:
                        icon = <MoreOutlined />
                    }
                    return (
                      <Button
                        size='small'
                        style={{ color: Color(record.Expression_11)?.Foreground }}
                        icon={icon}
                        onClick={() =>
                          this.callModal(
                            { Li_PersonalNum: record.personal_number_id },
                            '70%',
                            'WS2584019_PersonalInfoInquirySub'
                          )
                        }
                      />
                    )
                  }}
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_11)?.Foreground }}>
                        {value}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='診療科(検査部門)'
                  dataIndex='exam_department_name'
                />

                <Table.Column
                  title='受診科'
                  width={200}
                  dataIndex='department_name'
                  render={(value, record, index) => (
                    <AutoComplete
                      style={{ width: '100%' }}
                      options={this.state.introduceLetterVariousMasters}
                      defaultValue={value}
                      filterOption={(inputValue, option) =>
                        (option?.label ?? '').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onBlur={(e) =>
                        this.ChangeDepartmentName('department_name', record, e.target.value)
                      }
                    />
                  )}
                />

                <Table.Column
                  title='備考'
                  width={200}
                  dataIndex='Remarks'
                  render={(value, record, index) => (
                    <AutoComplete
                      style={{ width: '100%' }}
                      options={this.state.RemarksList}
                      defaultValue={value}
                      filterOption={(inputValue, option) =>
                        (option?.label ?? '').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onBlur={(e) =>
                        this.ChangeDepartmentName('Remarks', record, e.target.value)
                      }
                    />
                  )}
                />

                <Table.Column
                  title='発行'
                  dataIndex='Expresstion_13'
                  width={45}
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(record.Expression_11)?.Foreground
                        }}>
                        {value}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  width={40}
                  align='center'
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='編集'
                              onClick={() => { this.showWS0898002_IntroduceLetterEdit(record) }}
                            >
                              詳細
                            </Menu.Item>

                            <Menu.Item
                              key='2'
                              onClick={() => { this.showWWS2583001_ConsultInquirySub(record) }}
                            >
                              照会
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

            <div className='box_button_bottom_right' style={{ paddingTop: 10 }}>
              <Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>クリアを行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                okText='クリア'
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                onConfirm={() =>
                  // 削除
                  this.onDeleteSwitching(this.state.checkedItem)
                }
                cancelText='キャンセル'
                cancelButtonProps={{ style: { height: '32px' } }}
              >
                <Button
                  danger
                  type='primary'
                  // style={{
                  //   display: (props.deleteFlag ?? true) ? '' : 'none' // deleteFlag:falseの場合、非表示
                  // }}
                  icon={<DeleteOutlined />}
                >
                  <span className='btn_label'>
                    クリア
                  </span>
                </Button>
              </Popconfirm>

              <Button
                type='primary'
                onClick={() => this.showPrintModal()}
                icon={<PrinterOutlined />}
              >
                <span className='btn_label'>
                  印刷
                </span>
              </Button>

            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0904001_IntroduceLetterIssuedMain)
