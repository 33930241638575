import React from "react";
import { connect } from "react-redux";
import moment from 'moment'
import { Card, Form, Input, Select, Button, Radio, Space } from "antd";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS0104001_CoursesSettingSub from "pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0104001_CoursesSettingSub";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";

const dateFormat = "YYYY/MM/DD";

class WS2655083_DetailsExtract extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '詳細抽出';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      isLoading: false
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue(this.props)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  confirmProc = () => {
    this.props.onFinishScreen()
  }

  clearProc = () => {
    this.props.onFinishScreen()
  }

  render() {
    return (
      <div className="details-extract">
        <Card title="詳細抽出">
          <Form
            ref={this.formRef}
            initialValues={{
              ...this.props.date,
              ReceiptNumF: "",
              ReceiptNumT: "",
              CourseCodeF: "",
              CourseCodeT: "",
              CourseList: "",
              TimeDivision: 1,
              FacilityType: 1,
              StateFlag: 1,
              Lio_Insurer: "",
              OfficeCode: "",
              BranchStoreCode: "",
              PersonalNum: "",
              Lio_DisplayOrder: 1,
              Lio_AgeF: "",
              Lio_AgeT: "",
              Lio_Relationship: 9,
              Lio_ContractDivision: 0,
              Lio_ReCreateNewAnd: 3
            }}
          >
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                受診日
              </Space>
              <Form.Item name="DateFChar" style={{ display: "inline-block" }}>
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                  disabled={true}
                />
              </Form.Item>
              ~
              <Form.Item name="DateTChar" style={{ display: "inline-block" }}>
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                  disabled={true}
                />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                受付No
              </Space>
              <Form.Item name="ReceiptNumF" style={{ display: "inline-block" }}>
                <Input type="text" style={{ width: "50px" }} />
              </Form.Item>
              ~
              <Form.Item name="ReceiptNumT" style={{ display: "inline-block" }}>
                <Input type="text" style={{ width: "50px" }} />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  display: "flex",
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                コース
              </Space>
              <Form.Item name="CourseCodeF">
                <Input.Search
                  readOnly
                  style={{ width: "100px" }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0265001_BasicCourseInquiry
                            onFinishScreen={output => {
                              this.formRef.current.setFieldsValue({
                                CourseCodeF: output.Lo_CourseCode
                              });
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    });
                  }}
                />
              </Form.Item>
              <Form.Item name="CourseCodeT">
                <Input.Search
                  readOnly
                  style={{ width: "100px" }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0265001_BasicCourseInquiry
                            onFinishScreen={output => {
                              this.formRef.current.setFieldsValue({
                                CourseCodeT: output.Lo_CourseCode
                              });
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    });
                  }}
                />
              </Form.Item>
              <Form.Item name="CourseList">
                <Input.Search
                  readOnly
                  style={{ flexGrow: "1" }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 500,
                        component: (
                          <WS0104001_CoursesSettingSub
                            Li_Title={"ｺｰｽ条件"}
                            Lio_CourseList={this.formRef.current?.getFieldValue("CourseList")}
                            onFinishScreen={output => {
                              this.formRef.current?.setFieldsValue({
                                CourseList: output.Lio_CourseList
                              });
                              this.forceUpdate();
                            }}
                          />
                        )
                      }
                    });
                  }}
                />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                AMPM
              </Space>
              <Form.Item name="TimeDivision">
                <Select style={{ width: "70px" }}>
                  <Select.Option value={1}>全て</Select.Option>
                  <Select.Option value={2}>AM</Select.Option>
                  <Select.Option value={3}>PM</Select.Option>
                </Select>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                施設
              </Space>
              <Form.Item name="FacilityType">
                <Select style={{ width: "150px" }}>
                  <Select.Option value={1}>すべて</Select.Option>
                  <Select.Option value={2}>健診センター</Select.Option>
                </Select>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                状態
              </Space>
              <Form.Item name="StateFlag">
                <Select style={{ width: "120px" }}>
                  <Select.Option value={1}>受付</Select.Option>
                  <Select.Option value={2}>保留</Select.Option>
                  <Select.Option value={3}>キャンセル</Select.Option>
                  <Select.Option value={4}>全て</Select.Option>
                </Select>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                管　掌
              </Space>
              <Form.Item name="Lio_Insurer">
                <Input.Search
                  readOnly
                  style={{ width: "150px" }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0246001_InsurerInfoSearchQuery
                            onFinishScreen={output => {
                              this.formRef.current.setFieldsValue({
                                Lio_Insurer: output.Lo_InsurerCode
                              });
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    });
                  }}
                />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                事業所
              </Space>
              <Form.Item name="OfficeCode">
                <Input.Search
                  readOnly
                  style={{ width: "150px" }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0247001_OfficeInfoRetrievalQuery
                            onFinishScreen={output => {
                              this.formRef.current.setFieldsValue({
                                OfficeCode: output.Lio_OfficeCode
                              });
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    });
                  }}
                />
              </Form.Item>
              <Form.Item name="BranchStoreCode">
                <Input type="text" style={{ width: "50px" }} />
              </Form.Item>
              {this.formRef.current?.getFieldValue("OfficeCode")
                ? `事業所: ${this.formRef.current?.getFieldValue("OfficeCode")}`
                : ""}
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                個　人
              </Space>
              <Form.Item name="PersonalNum">
                <Input.Search
                  readOnly
                  style={{ width: "150px" }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0248001_PersonalInfoSearchQuery
                            date={moment(new Date()).format('YYYY/MM/DD')}
                            onFinishScreen={output => {
                              this.formRef.current.setFieldsValue({
                                PersonalNum: output.Lo_PersonalNumId
                              });
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    });
                  }}
                />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                表示順
              </Space>
              <Form.Item style={{ marginBottom: "0" }} name="Lio_DisplayOrder">
                <Radio.Group value={1}>
                  <Radio value={1}>保険者</Radio>
                  <Radio value={2}>受診日</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                年　齢
              </Space>
              <Form.Item name="Lio_AgeF" style={{ display: "inline-block" }}>
                <Input type="text" style={{ width: "50px" }} />
              </Form.Item>
              ~
              <Form.Item name="Lio_AgeT" style={{ display: "inline-block" }}>
                <Input type="text" style={{ width: "50px" }} />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                続　柄
              </Space>
              <Form.Item name="Lio_Relationship">
                <Select style={{ width: "150px" }}>
                  <Select.Option value={'9'}>全て</Select.Option>
                  <Select.Option value={'0'}>本人</Select.Option>
                  <Select.Option value={'1'}>配偶者</Select.Option>
                  <Select.Option value={'2'}>家族</Select.Option>
                </Select>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                契　約
              </Space>
              <Form.Item style={{ marginBottom: "0" }} name="Lio_ContractDivision">
                <Radio.Group value={0}>
                  <Radio value={0}>全　て</Radio>
                  <Radio value={1}>個　別</Radio>
                  <Radio value={2}>集　合</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "50px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                出力済
              </Space>
              <Form.Item style={{ marginBottom: "0" }} name="Lio_ReCreateNewAnd">
                <Radio.Group value={1}>
                  <Radio value={3}>全　て</Radio>
                  <Radio value={1}>新　規</Radio>
                  <Radio value={2}>再作成</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
            <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={() => {
                  this.formRef.current?.resetFields();
                  const fields = this.formRef.current.getFieldsValue();
                  const { DateFChar, DateTChar, ...params } = fields;
                  this.props.onFinishScreen(params);
                }}
              >
                クリア
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  const fields = this.formRef.current.getFieldsValue();
                  const { DateFChar, DateTChar, ...params } = fields;
                  this.props.onFinishScreen(params);
                }}
              >
                確　定
              </Button>
            </Space>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2655083_DetailsExtract);
