import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Modal, Checkbox, message, Tooltip, Dropdown, Menu } from 'antd'
import { InfoCircleOutlined, SearchOutlined, DeleteOutlined, PrinterOutlined, WarningOutlined, CloseCircleOutlined, InfoOutlined } from '@ant-design/icons'
import moment from 'moment'
import Color from 'constants/Color'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import FollowUpAction from 'redux/IntroductionLetter/FollowUp/FollowUp.action.js'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import FollowUpInput from 'pages/SK_IntroductionLetter/FollowUp/FollowUpInput.jsx'
import DeleteConfirm from 'pages/SK_IntroductionLetter/FollowUp/DeleteConfirm.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import { download_file } from 'helpers/CommonHelpers'

class PostmanageAndTrackingFollowup extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '事後管理・追跡経過観察'

    this.state = {
      loading: false,
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
    }
  }

  componentDidMount = () => {
    FollowUpAction.getInitialData()
      .then(res => {
        console.log(res)
        this.formRef.current?.setFieldsValue({
          yearAndMonthF: res.yearRangeF,
          yearAndMonthT: res.yearRangeT
        })
        this.setState({
          yearAndMonthF: res.yearRangeF,
          yearAndMonthT: res.yearRangeT
        })
      })
  }

  outputCSV = () => {
    let params = {
      yearAndMonthF: this.formRef.current?.getFieldValue('yearAndMonthF'),
      yearAndMonthT: this.formRef.current?.getFieldValue('yearAndMonthT')
    }
    FollowUpAction.outputCSV(params)
      .then(res => {
        download_file(res)
        this.statistOutput()
      })
  }

  statistOutput = () => {
    let params = {
      yearAndMonthF: this.formRef.current?.getFieldValue('yearAndMonthF'),
      yearAndMonthT: this.formRef.current?.getFieldValue('yearAndMonthT')
    }
    FollowUpAction.statistOutput(params)
      .then(res => {
        download_file(res)
      })
  }

  render = () => {
    return (
      <div className='postmanage-and-tracking-follow-up'>
        <Card title='事後管理・追跡経過観察'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item name='yearAndMonthF' label='　年　月'>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM'
                    picker='month'
                    style={{ width: '60px' }}
                  // onChange={(date, dateString) => this.changeFYearAndMonth(date, dateString)}
                  />
                </Form.Item>
                <Form.Item>~</Form.Item>
                <Form.Item name='yearAndMonthT'>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM'
                    picker='month'
                    style={{ width: '60px' }}
                  // onChange={(date, dateString) => this.changeTYearAndMonth(date, dateString)}
                  />
                </Form.Item>
              </div>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  onClick={() => this.outputCSV()}
                >
                  <span className='btn_label'>
                    出力
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostmanageAndTrackingFollowup)
