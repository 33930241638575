import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Modal, Checkbox, message, Tooltip, Dropdown, Menu, Popconfirm } from 'antd'
import { MenuOutlined, SearchOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import IntroduceLetterExtractService from 'services/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtract'
import moment from 'moment'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS0919001_IntroduceLetterMasterMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0919001_IntroduceLetterMasterMaintain.jsx'
import Color from 'constants/Color'

const labelCol = {
  labelCol: { style: { width: 50 } },
}
class WS0892001_IntroduceLetterExtract extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '紹介状抽出'

    this.state = {
      loading: false,
      dataSource: [],
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      StsAllSelectSwitch: 0,
      rowSelect: [],
      actionType: 0,
      selectReserveNum: [],
      checkedItem: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.onGetScreenData()
  }

  onEventSearchIssueList = () => {
    let DateF = moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD')
    let DateT = moment(this.formRef.current.getFieldValue('DateTChar')).format('YYYY/MM/DD')
    let ReceiptNumF = this.formRef.current.getFieldValue('ReceiptNumF')
    let ReceiptNumT = this.formRef.current.getFieldValue('ReceiptNumT')
    if (DateF > DateT) {
      return Modal.error({
        title: '日付の範囲指定に誤りがあります!!',
        okText: 'OK'
      })
    }
    if (ReceiptNumF > ReceiptNumT) {
      return Modal.error({
        title: '受付番号の範囲指定に誤りがあります!!',
        okText: 'OK'
      })
    }
    this.onEventDisplayBtn()
  }

  onEventDisplayBtn = () => {
    this.setState({ loading: true })
    let params = {
      DateFChar: moment(this.formRef.current?.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
      DateTChar: moment(this.formRef.current?.getFieldValue('DateTChar')).format('YYYY/MM/DD'),
      ReceiptNumF: this.formRef.current?.getFieldValue('ReceiptNumF') ? this.formRef.current.getFieldValue('ReceiptNumF') : '',
      ReceiptNumT: this.formRef.current?.getFieldValue('ReceiptNumT') ? this.formRef.current.getFieldValue('ReceiptNumT') : '',
      ProcessDivision: this.formRef.current?.getFieldValue('ProcessDivision'),
      FacilityType: this.formRef.current?.getFieldValue('FacilityType'),
    }
    IntroduceLetterExtractService.onEventDisplayBtn(params)
      .then(res => {
        this.onGetIssueList()
      })
      .catch(error => {
        message.error(error)
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  onEventF12 = (ProcessDivision) => {
    this.setState({
      loading: true
    })
    IntroduceLetterExtractService.onEventF12({ ProcessDivision })
      .then(res => {
        this.onEventSearchIssueList()
      })
      .catch(error => {
        message.error(error)
      })
  }

  onGetScreenData = () => {
    this.setState({
      loading: true,
    })
    IntroduceLetterExtractService.onGetScreenData()
      .then(res => {
        this.formRef.current?.setFieldsValue({
          DateFChar: moment(res.data.DateFChar, 'YYYY/MM/DD'),
          DateTChar: moment(res.data.DateTChar, 'YYYY/MM/DD'),
          FacilityType: 1,
          ProcessDivision: res.data.ProcessDivision,
          ComboBox_FacilityType: res.data.ComboBox_FacilityType
        })
      })
      .catch(error => {
        message.error(error)
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  onGetIssueList = () => {
    this.setState({
      loading: true,
    })
    IntroduceLetterExtractService.onGetIssueList()
      .then(res => {
        const checkedItem = res.data.filter((item) => item.StsAllSelectSwitch == 1)
        this.state.selectReserveNum = checkedItem.map(item => item.W1_reserve_num)
        this.setState({
          dataSource: res.data,
        })
        this.onSelectedChange(res.data)
      })
      .catch(error => {
        message.error(error)
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  onSelectedChange = (data) => {
    data.map(item => {
      if (item.StsAllSelectSwitch == 0) {
        this.setState({
          StsAllSelectSwitch: 0
        })
        return
      } else {
        this.setState({
          StsAllSelectSwitch: 1
        })
      }
    })
  }

  onSelectSingleSwitching = (id, checked, reserve_num) => {
    this.state.selectReserveNum.push(reserve_num)
    if (!checked) {
      this.state.selectReserveNum = this.state.selectReserveNum.filter(item => { return item !== reserve_num })
    }

    this.setState({
      loading: true,
    })
    IntroduceLetterExtractService.onSelectSingleSwitching({ id, StsAllSelectSwitch: checked ? 1 : 0 })
      .then(res => {
        this.onGetIssueList()
      })
      .catch(error => {
        message.error(error)
      })
  }

  onSelectAllSwitching = (e) => {
    this.setState({
      loading: true
    })
    IntroduceLetterExtractService.onSelectAllSwitching({ StsAllSelectSwitch: e.target.checked ? 1 : 0 })
      .then(res => {
        if (e.target.checked) {
          this.setState({
            StsAllSelectSwitch: 0
          })
        } else {
          this.setState({
            StsAllSelectSwitch: 1
          })
        }
        this.onGetIssueList()
      })
      .catch(error => {
        message.error(error)
      })
  }

  /**
   * 抽出保守
   */
  openModalIntroduceLetterExtractMaintain = () => {
    if (this.state.rowSelect) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 1200,
          component: (
            <WS0898001_IntroduceLetterExtractMaintain
              Li_PersonalNum={this.state.rowSelect.W1_id_cd}
              Li_ReserveNum={this.state.rowSelect.W1_reserve_num}
              onFinishScreen={(output) => {
                console.log(output)
                this.closeModal()
              }}
            />
          )
        }
      })
    } else {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 1200,
          component: (
            <WS0898001_IntroduceLetterExtractMaintain
              Li_PersonalNum={0}
              Li_ReserveNum={0}
              onFinishScreen={(output) => {
                console.log(output)
                this.closeModal()
              }}
            />
          )
        }
      })
    }
  }

  /**
   * 紹介状設定
   */
  introduceLetterMasterMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS0919001_IntroduceLetterMasterMaintain
          />
        )
      }
    })
  }

  /**
   * 受付日　始め
   */
  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateFChar')
        .format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateTChar')
        .format('YYYY/MM/DD')
      : null
    if (dateEnd && dateStart > dateEnd) {
      this.formRef.current?.setFieldsValue({
        ReserveDateFChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      })
    }
  }

  /**
   * 受付日　終わり
   */
  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateFChar')
        .format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateTChar')
        .format('YYYY/MM/DD')
      : null
    if ((!dateEnd && dateStart) || (dateStart && dateStart > dateEnd)) {
      this.formRef.current?.setFieldsValue({
        ReserveDateTChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      })
    }
  }

  eventF12 = () => {
    const ProcessDivision = this.formRef.current.getFieldValue('ProcessDivision')
    this.onEventF12(ProcessDivision)
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  onChangeActionType = e => {
    this.setState({ actionType: e.target.value })
  }


  onDeleteSingleSwitching = () => {
    let params = {
      reservation_number: this.state.selectReserveNum ?? ''
    }

    this.setState({
      loading: true
    })
    IntroduceLetterExtractService.onDeleteSingleSwitching({ params })
      .then(res => {
        console.log('削除完了しました')
      })
      .catch(error => {
        message.error(error)
      })
  }


  render() {
    return (
      <div className='introduce-letter-extract'>
        <Card title='紹介状抽出'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='2'
                      onClick={() => {
                        this.openModalIntroduceLetterExtractMaintain()
                      }}
                    >
                      抽出保守
                    </Menu.Item>

                    <Menu.Item key='3'
                      onClick={() => {
                        this.introduceLetterMasterMaintain()
                      }}
                    >
                      紹介状設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ ProcessDivision: 0 }}
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal' style={{ width: '100%' }}>
                <div className='box_inner_horizontal'>
                  <div className='box_search' >
                    <div className='box_search_inner'>
                      <div className='box_inner_horizontal' >
                        <Form.Item
                          label='受診日'
                          name='DateFChar'
                          {...labelCol}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            onBlur={() => {
                              this.checkDateStart()
                            }} />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item
                          name='DateTChar'
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            onBlur={() => {
                              this.checkDateEnd()
                            }} />
                        </Form.Item>
                      </div>

                      <div className='box_inner_horizontal' >
                        <Form.Item
                          label='受付No'
                          name='ReceiptNumF'
                          {...labelCol}
                        >
                          <Input
                            className='input-size-number-4'
                          />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item
                          name='ReceiptNumT'
                        >
                          <Input
                            className='input-size-number-4'
                          />
                        </Form.Item>
                      </div>

                      <Form.Item
                        name='FacilityType'
                        label='施設'
                        {...labelCol}
                        style={{ marginBottom: 0 }}
                      >
                        <Select style={{ width: 120 }}>
                          <Select.Option value={1}>{'院内'}</Select.Option>
                          <Select.Option value={2}>{'院外'}</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() =>
                          this.onEventSearchIssueList()
                        }
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </div>
                  </div>

                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                onRow={(record, index) => ({
                  onClick: event => this.setState({
                    rowSelect: record,
                    StsAllSelectSwitch: record.StsAllSelectSwitch,
                    id: record.id,
                  })
                })}
                rowKey={(record) => record.id}
                rowClassName={(record, index) => { return record.id === this.state.rowSelect.id ? 'active-row' : '' }}
              >
                <Table.Column
                  title={
                    this.state.dataSource.length > 0 ?
                      <Form.Item
                        name='StsAllSelectSwitch'
                        style={{ justifyContent: 'center' }}
                      >
                        <Checkbox
                          checked={this.state.StsAllSelectSwitch}
                          onChange={(e) => {
                            this.onSelectAllSwitching(e)
                          }}
                        >
                        </Checkbox>
                      </Form.Item>
                      :
                      ''
                  }
                  align='center'
                  width='40px'
                  dataIndex='Issuing'
                  render={(value, record) => {
                    return (
                      <Form.Item
                        name='Issuing'
                        style={{ justifyContent: 'center' }}
                      >
                        <Checkbox
                          checked={record.Issuing}
                          onChange={(e) => this.onSelectSingleSwitching(record.id, e.target.checked, record.W1_reserve_num)
                          }>
                        </Checkbox>
                      </Form.Item>
                    )
                  }} />

                <Table.Column
                  title='区分'
                  dataIndex='Expression_20'
                  // sorter={(a, b) => a.Expression_20 - b.Expression_20}
                  render={(value, record) => {
                    return (
                      <span style={{ color: Color(record?.Expression_21)?.Foreground }}>
                        {value}
                      </span>
                    )
                  }} />

                <Table.Column
                  title='受診日'
                  dataIndex='W1_reserve_date'
                  className='column-size-date'
                  render={(value, record) => {
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground }}>
                        {moment(record?.W1_reserve_date).format('YYYY/MM/DD (ddd)')}
                      </div>
                    )
                  }} />

                <Table.Column
                  title='受付No'
                  dataIndex='receipt_number'
                  // sorter={(a, b) => a.receipt_number - b.receipt_number}
                  render={(value, record) => {
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground, textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  // sorter={(a, b) => a.personal_number_id - b.personal_number_id}
                  render={(value, record) => {
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground, textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  // sorter={(a, b) => a.kanji_name.localeCompare(b.kanji_name, 'ja')}
                  render={(value, record) => {
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='性別'
                  dataIndex='Expression_5'
                  className='column-size-5'
                  // sorter={(a, b) => a.Expression_5.localeCompare(b.Expression_5, 'ja')}
                  render={(value, record) => {
                    return (
                      <div style={{ color: Color(record?.Expression_24)?.Foreground }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='生年月日'
                  dataIndex='birthday_on'
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground }}>
                        {moment(record?.birthday_on).format('YYYY/MM/DD')}
                      </div>
                    )
                  }} />

                <Table.Column
                  title='契約情報'
                  dataIndex='W1_visits_courses'
                  render={(text, record) => (
                    <div style={{ color: Color(record?.Expression_23)?.Foreground }}>
                      {record.W1_visits_courses + ' ' + record.contract_short_name}
                    </div>
                  )}
                />
                {
                  this.formRef.current?.getFieldValue('ProcessDivision') === 9
                    ? <Table.Column
                      title='診療科名'
                      dataIndex='department_name'
                      render={(value, record, index) => {
                        return (
                          <div style={{ color: Color(record?.Expression_23)?.Foreground }}>{value}</div>
                        )
                      }}
                    />
                    : ''
                }
              </Table>

              <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  okText='削除'
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  onConfirm={() =>
                    // 削除
                    this.onDeleteSingleSwitching(this.state.selectReserveNum)
                  }
                  cancelText='キャンセル'
                  cancelButtonProps={{ style: { height: '32px' } }}
                >
                  <Button
                    danger
                    type='primary'
                    // style={{
                    //   display: (props.deleteFlag ?? true) ? '' : 'none' // deleteFlag:falseの場合、非表示
                    // }}
                    icon={<DeleteOutlined />}
                  >
                    <span className='btn_label'>
                      削除
                    </span>
                  </Button>
                </Popconfirm>

                <Button
                  icon={<SaveOutlined />}
                  type='primary'
                  style={{ float: 'right' }}
                // onClick={this.eventF12}
                >
                  一括作成
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0892001_IntroduceLetterExtract)
