import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, message } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import InspectGroupMaintainAction from 'redux/InspectionMaintenance/InspectGroupMaintain/InspectGroupMaintain.actions.js'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import ModalDraggable from "components/Commons/ModalDraggable"

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: 120 } }
}

class InspectGroupMaintainEdit extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '検査グループマスタ保守 新規・編集'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の時のみ
      // 親画面のrecordをコピー
      const data = {
        ...this.props.record,
      }
      // Form設定
      this.formRef.current?.setFieldsValue(data)
    }
  }

  /**
   *  データ保存
   */
  save = () => {
    const values = this.formRef.current?.getFieldsValue()
    if (!values.inspect_group_code) {
      return message.error('検査グループコードを入力してください')
    }

    const params = {
      id: this.props.record?.id ?? null,
      inspect_group_code: values.inspect_group_code,
      inspect_group_name: values.inspect_group_name,
      exam_code: values.exam_code
    }
    InspectGroupMaintainAction.save(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            // 親画面に戻る
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * データ削除
   */
  delete = () => {
    const params = {
      id: this.props.record?.id ?? null,
      inspect_group_code: this.props.record?.inspect_group_code ?? null,
    }
    InspectGroupMaintainAction.delete(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            // 親画面に戻る
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
  * サーチが押された処理(検査コード)
  */
  showMs0670InspectionItems = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component:
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={value}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                exam_code: output.recordData.test_item_code,
                exam_name: output.recordData.exam_name
              })
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
  * コードと名称をリセット
  * @param {*} formName
  */
  resetForm = (formName) => {
    this.formRef.current?.setFieldsValue({
      [formName]: '',
      [formName + '_name']: ''
    })
    this.forceUpdate()
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='Electronic-Medical-Records-Linkage-Frame-Set-Edit'>
        <Card
          title={'検査グループマスタ保守 [' + (this.props?.record ? '変更' : '新規') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='検査グループコード'
                name='inspect_group_code'
                readOnly
                {...labelCol}
              >
                <Input
                  className='input-size-10'
                />
              </Form.Item>

              <Form.Item
                label='検査グループ名'
                name='inspect_group_name'
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='検査コード'
                  name='exam_code'//対応したものに書き換え
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-10'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        // ✕ボタン押下
                        this.resetForm('exam_name')
                      } else {
                        // 虫眼鏡マーク押下
                        this.showMs0670InspectionItems(value)
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('exam_name')}</div>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.record) ? '' : 'none' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    okButtonProps: {
                      danger: true,
                      icon: <DeleteOutlined />
                    },
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    cancelText: 'キャンセル',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectGroupMaintainEdit)
