import React from 'react'
import PropTypes from 'prop-types'
import { Card, Popconfirm, Button, Form, message, Select, Tooltip, Input, Modal } from 'antd'
import { SaveOutlined, DeleteOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import SupplementalInfoSettingAction from 'redux/basicInfo/InsurerInfoMaintain/SupplementalInfoSetting.actions'

const formItemStyle = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 70px)' } }
}

class WS0344003_SupplementalInfoInput extends React.Component {
  static propTypes = {
    Li_IdentifyChar: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '補足情報変更'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      itemChange: {},
      selectList: [],
      disabledBtn: true
    }

    this.handleFormChange = this.handleFormChange.bind(this)
    this.addSelectItem = this.addSelectItem.bind(this)
    this.deleteSelectItem = this.deleteSelectItem.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // フィールドにデータ設定
    this.formRef.current.setFieldsValue(this.props.record)

    // 選択項目 入力用
    this.setState({
      selectList: this.props.record.combo_box ?? []
    })
  }


  /**
   * 補足情報一覧を取得
   * @param {*} IdentifyChar
   */
  getSupplementList(IdentifyChar) {
    const params = {
      IdentifyChar: IdentifyChar
    }
    SupplementalInfoSettingAction.index(params)
      .then(res => {
        if (!res) return
        // テーブルに反映
        this.setState({ dataSource: res })

      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  /**
   * 入力　変更処理
   * @param {*} e
   */
  handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]

    this.setState({
      itemChange: {
        ...this.state.itemChange,
        [columnName]: columnValue
      },
      disabledBtn: false //保存ボタン活性
    })
  }

  /**
   * 追加ボタン押下処理
   * 選択肢を追加する
   */
  addSelectItem = () => {

    // 既に設定されている選択項目情報を取得
    let arrTemp = [...this.formRef.current?.getFieldValue('combo_box')]
    let obj = {
      value: arrTemp.length + 1,
      label: ''
    }
    arrTemp.push(obj)

    // フィールドにデータ反映
    this.formRef.current.setFieldsValue({ combo_box: arrTemp })
    // 選択項目 データ反映
    this.setState({
      selectList: arrTemp,
      itemChange: {
        ...this.state.itemChange,
        combo_box: arrTemp
      }
    })
  }

  /**
   * 選択肢の削除ボタン押下処理
   * @param {*} deleteIndex
   */
  deleteSelectItem = (deleteIndex) => {

    // 既に設定されている選択項目情報を取得
    let arrTemp = [...this.formRef.current?.getFieldValue('combo_box')]
    // 該当箇所を削除
    arrTemp.splice(deleteIndex, 1)

    let obj
    // valueの値を繰り上げ
    for (let i = deleteIndex; i < arrTemp.length; i++) {
      obj = { ...arrTemp[i] }
      obj.value = i + 1
      arrTemp[i] = obj
    }

    // フィールドにデータ反映
    this.formRef.current.setFieldsValue({ combo_box: arrTemp })
    // 選択項目 データ反映
    this.setState({
      selectList: arrTemp,
      itemChange: {
        ...this.state.itemChange,
        combo_box: arrTemp
      },
      disabledBtn: false //保存ボタン活性
    })
  }

  /**
   * 保存の前処理
   */
  preUpdate = () => {
    let changeData = { ...this.state.itemChange }

    if (!this.formRef.current?.getFieldValue('item')) {
      Modal.error({
        content: "表示名称が設定されていません",
        okText: "Ok",
      });
    } else {
      // 選択項目の変更がある場合
      if (changeData.combo_box) {
        let selectItems = [...this.formRef.current?.getFieldValue('combo_box')]
        let arrValues = [], arrLabels = []

        let check = false
        // valueとlabelを配列化
        selectItems.forEach((item) => {
          // labelの空チェック
          if (item.label === '') {
            check = true
          }

          arrValues.push(item.value)
          arrLabels.push(item.label)
        })

        if (check) {
          message.error('入力されていない選択肢があります')
          return
        }

        // テーブル更新用にカンマ区切りに変更する
        changeData = {
          ...changeData,
          combo_box_selection: arrValues.join(','),
          combo_box_display: arrLabels.join(','),
        }
      }
      console.log(changeData);
      // 親画面でデータ更新する
      this.props.onUpdate({
        itemChange: changeData,
      })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='supplemental-info-setting'>
        <Card title={`補足情報変更  ${this.props.record.toolTip}`} >
          <Form
            ref={this.formRef}
            onValuesChange={this.handleFormChange}
            autoComplete='off'
          >
            <Form.Item
              label='表示名称'
              name='item'
              {...formItemStyle}>
              <Input
                className='input-size-40'
              />
            </Form.Item>

            <Form.Item
              label='順番'
              name='serialNum'
              {...formItemStyle}
              rules={[
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: "入力値が正しくありません",
                },
              ]}
            >
              <Input
                className='input-size-number-2' //1ケタ数字のみ
                maxLength={1}
              />
            </Form.Item>

            <Form.Item
              label='タイプ'
              name='condition_1'
              {...formItemStyle}>
              <Select style={{ width: '80px' }}>
                {this.props.typeList.map((item, index) => (
                  <Select.Option
                    key={item.label + index}
                    value={item.value}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='桁数'
              name='condition_2'
              {...formItemStyle}
              rules={[
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: "入力値が正しくありません",
                },
              ]}
            >
              <Input
                className='input-size-number-4'
              />
            </Form.Item>

            <Form.Item
              label='備考'
              name='remarks'
              {...formItemStyle}>
              <Input />
            </Form.Item>
            <Card
              title={'選択項目'}
              size='small'
              style={{
                backgroundColor: '#f3f3f3', marginTop: 20, marginBottom: 10
              }}
              bodyStyle={{
                display: 'flex', flexDirection: 'column', gap: '5px'
              }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}
              >
                {(this.state.selectList.length > 0) ?
                  '※ 選択内容を入力してください'
                  :
                  '※ 設定なし'}
                <Tooltip title='選択肢を追加'>
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={this.addSelectItem}
                  >
                    追加
                  </Button>
                </Tooltip>
              </div>
              {this.state.selectList.map((item, index) => {
                return (
                  <div
                    key={`select_combo_${index}`}
                    style={{ width: '100%', display: 'flex', gap: '5px', alignItems: 'center' }}
                  >
                    {/* 選択肢 */}
                    <Form.Item
                      label={`選択肢${item.value}`}
                      name={['combo_box', index, 'label']}
                      style={{ width: '100%', marginBottom: 0 }}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                    {/* 選択肢の削除ボタン */}
                    <Tooltip title='削除'>
                      <Button
                        size='small'
                        type='text'
                        icon={<DeleteFilled style={{ fontSize: 16, color: '#666' }} />}
                        onClick={() =>
                          this.deleteSelectItem(index)
                        }
                      />
                    </Tooltip>
                  </div>
                )
              })}
            </Card>

            <div className='box_button_bottom_right'>
              {/* 削除ボタン */}
              < Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                okText='削除'
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                onConfirm={() =>
                  // 削除
                  this.props.onDelete()
                }
                cancelText='キャンセル'
                cancelButtonProps={{ style: { height: '32px' } }}
              >
                <Button
                  danger
                  type='primary'
                  icon={<DeleteOutlined />}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
              </Popconfirm>
              {/* 保存ボタン */}
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.preUpdate()
                }}
                disabled={this.state.disabledBtn}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

export default WS0344003_SupplementalInfoInput
