import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Dropdown, Menu, Button, Modal } from 'antd'
import { MoreOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons'
import MutualAidTargetKenCourseMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MutualAidTargetKenCourseMaintain.actions.js'
import WS1780002_MutualAidTargetKenCourseMaintainEdit from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1780002_MutualAidTargetKenCourseMaintainEdit.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
class WS1780001_MutualAidTargetKenCourseMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '共済対象健コース保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    MutualAidTargetKenCourseMaintainAction.index()
      .then((res) => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
  }

  /**
   * 新規、変更画面を表示
   * @param {*} record 
   */
  showWS1780002_MutualAidTargetKenCourseMaintainEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        component: (
          <WS1780002_MutualAidTargetKenCourseMaintainEdit
            record={record}
            onFinishScreen={(output) => {
              this.closeModal()
              this.index()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      },
    })
  }

  render() {
    return (
      <div className='mutual-aid-target-ken-course-maintain'>
        <Card title='共済対象健コース保守' style={{ width: 1000 }}>
          <Table
            size='small'
            bordered
            dataSource={this.state.dataSource}
            rowKey={record => record.id}
            pagination={false}
            scroll={{ x: 'max-content', y: 500 }}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  // 変更
                  this.showWS1780002_MutualAidTargetKenCourseMaintainEdit(record)
                }
              }
            }}
          >
            <Table.Column
              title='有効'
              dataIndex='enabled_disabled'
              width={50}
              render={(value) => (
                <div style={{ textAlign: 'center' }}>
                  {value === 0 ? '' : <CheckOutlined />}
                </div>
              )}
            />
            <Table.Column
              title='コース'
              dataIndex='exam_course'
              className='column-size-5'
            />
            <Table.Column
              title='名称'
              dataIndex='course_name_formal'
            />
            <Table.Column
              title='任継'
              dataIndex='handover_division'
              width={50}
              render={(value) => (
                <div style={{ textAlign: 'center' }}>
                  {value === 0 ? '' : <CheckOutlined />}
                </div>
              )}
            />
            <Table.Column
              title='出力名'
              dataIndex='course_name'
              className='column-size-30'
            />
            <Table.Column
              width={40}
              align='center'
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.showWS1780002_MutualAidTargetKenCourseMaintainEdit()
                  }}
                />
              }
              render={(text, record) => (
                <Dropdown
                  trigger='click'
                  size='small'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='menu-1'
                        onClick={() => {
                          this.showWS1780002_MutualAidTargetKenCourseMaintainEdit(record)
                        }}>
                        変更
                      </Menu.Item>
                    </Menu>
                  )}>
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1780001_MutualAidTargetKenCourseMaintain)
