import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import { Card, Button, Modal, message } from 'antd'

import DeleteIncorrectData from 'pages/ZZ_Others/RecoveryProcesses/DeleteIncorrectData'
import InspectToFindingInfoChange from 'pages/ZZ_Others/RecoveryProcesses/InspectToFindingInfoChange'
import ChangePastInspectCode from 'pages/ZZ_Others/RecoveryProcesses/ChangePastInspectCode.jsx'

import RecoveryProcessesAction from 'redux/Others/RecoveryProcesses/RecoveryProcesses.action'
import ContractInfoMaintainAction from 'redux/basicInfo/ContractInfoMaintain/ContractInfoMaintain.actions'

const mb12 = {
  marginBottom: '12px',
}
const btn = {
  width: '160px',
}


class RecoveryProcesses extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'リカバリー処理関係'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      nameScreen: '',
    }
  }

  componentDidMount() {
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  contractExpand() {
    ModalConfirm({
      content: '契約情報の内容を再展開しますか？',
      styleIconModal: { color: '#006CC9' },
      onOk: () => {
        ContractInfoMaintainAction.eventF11()
          .then((res) => {
            if(Array.isArray(res) && !res.length){
              Modal.info({
                content: '再展開処理が終了しました',
                onOk: this.props.onFinishScreen,
              })
            } else {
              reject(new Error('エラーが発生しました'));
            }
          })
          .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      },
      onCancel: () => {
        Modal.info({
          content: 'キャンセルしました',
        })
      },
    })
  }

  deleteIncorrectData() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <DeleteIncorrectData
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  examTypeConversion() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <InspectToFindingInfoChange
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  examCodeChange() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <ChangePastInspectCode
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  render() {
    return (
      <div className='recovery-processes'>
        <Card title='リカバリー処理関係'>
          <div className='box_inner_horizontal' style={mb12}>
            <Button
              type='primary'
              style={btn}
              onClick={() => {
                this.contractExpand();
              }}
            >
              <span className='btn_label'>契約再展開</span>
            </Button>
          </div>
          <div className='box_inner_horizontal' style={mb12}>
            <Button
              type='primary'
              style={btn}
              onClick={() => {
                this.deleteIncorrectData();
              }}
            >
              <span className='btn_label'>不正データ削除</span>
            </Button>
          </div>
          <div className='box_inner_horizontal' style={mb12}>
            <Button
              type='primary'
              style={btn}
              onClick={() => {
                this.examTypeConversion();
              }}
            >
              <span className='btn_label'>検査タイプ変換</span>
            </Button>
          </div>
          <div className='box_inner_horizontal' style={mb12}>
            <Button
              type='primary'
              style={btn}
              onClick={() => {
                this.examCodeChange();
              }}
            >
              <span className='btn_label'>過去検査コード変更</span>
            </Button>
          </div>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryProcesses)