import axios from 'configs/axios'

const API_LIST = {
  download: '/api/associate-insure-data-create/associate-insure-error-list/download',
}

const ErrorListCreateService = {
  async download(params) {
    return axios.post(API_LIST.download, params, { responseType: 'blob' })
  },
}

export default ErrorListCreateService;
