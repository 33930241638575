import React from "react";
import PropTypes, { checkPropTypes } from "prop-types";

import {
  Card,
  Form,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Button,
  message,
  Typography
} from "antd";

import { DoubleRightOutlined } from "@ant-design/icons";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";

import InputFormService from "services/CooperationRelated/InspectItemConvertInternal/InputForm.js";

const labelStyle = { span: 4 }

class WS2700099_InputForm extends React.Component {
  static propTypes = {
    Li_InspectCodeOut: PropTypes.any,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "入力フォーム";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingData: false,

      showConvertCoefficientChar: false,
    };

    this.onFinish = this.onFinish.bind(this);
    this.loadInfo = this.loadInfo.bind(this);
  }

  componentDidMount() {
    this.loadInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.loadInfo();
    }
  }

  loadInfo() {
    this.setState({ isLoadingData: true });
    this.getScreenData();
  }

  onFinish(values) {
    this.setState({ isLoadingData: true });
  }

  getScreenData = () => {
    InputFormService.GetScreenData({
      Li_InspectCodeExternal: this.props.Li_InspectCodeOut,
    })
      .then((res) => {

        let convertcode = res.data.ConvertCoefficientChar;
        if (convertcode && convertcode.indexOf('△') > -1) {
          convertcode = convertcode.replace('△', '-');
        }

        this.formRef.current.setFieldsValue(res.data);
        this.showConvertCoefficientChar();
        this.formRef.current.setFieldsValue({
          ConvertCoefficientChar: convertcode,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  externalInspectRegister = () => {
    InputFormService.ExternalInspectRegister({
      Li_InspectCodeExternal: this.props.Li_InspectCodeOut,
      InspectCodeOut: this.formRef.current?.getFieldValue("InspectCodeOut"),
      InspectName: this.formRef.current?.getFieldValue("InspectName"),
      InspectTypeOut: this.formRef.current?.getFieldValue("InspectTypeOut"),
      ItemCodeMedicalExam: this.formRef.current?.getFieldValue(
        "ItemCodeMedicalExam"
      ),
      InspectValueConvertDivision: this.formRef.current?.getFieldValue(
        "InspectValueConvertDivision"
      ),
      ConvertCoefficientChar: this.formRef.current?.getFieldValue(
        "ConvertCoefficientChar"
      ),
    })
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen(this.formRef.current?.getFieldValue("ConvertCoefficientChar2"));
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  inspectChange = () => {
    InputFormService.InspectItem({
      ItemCodeMedicalExam: this.formRef.current?.getFieldValue(
        "ItemCodeMedicalExam"
      ),
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          exam_short_name: res.data.exam_short_name,
          Expression_21: res.data.Expression_21,
          exam_name: res.data.exam_name,
        });
        if (!this.formRef.current?.getFieldValue("InspectName")) {
          this.formRef.current.setFieldsValue({
            InspectName: res.data.exam_name,
          });
        }
        if (!this.formRef.current?.getFieldValue("InspectTypeOut")) {
          this.formRef.current.setFieldsValue({
            InspectTypeOut: res.data.Expression_21,
          });
        }

      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  showConvertCoefficientChar = () => {
    this.setState({
      showConvertCoefficientChar:
        this.formRef.current?.getFieldValue("InspectValueConvertDivision") == 2,
    });
  };

  setConvertCoefficientChar2 = (value) => {
    if (value === '0') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '' });
    } else if (value === '1') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 10' });
    } else if (value === '2') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 100' });
    } else if (value === '3') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 1000' });
    } else if (value === '4') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 10000' });
    } else if (value === '-1') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 0.1' });
    } else if (value === '-2') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 0.01' });
    } else if (value === '-3') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 0.001' });
    } else if (value === '-4') {
      this.formRef.current.setFieldsValue({ ConvertCoefficientChar2: '× 0.0001' });
    }
  };

  render() {
    return (
      <div className="input-form" >
        <Card title="入力フォーム">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className="box_container">
              <div className="box_inner_horizontal">
                <div className="box_inner_vertical" style={{ width: 'fit-content' }}>
                  <Typography
                    level={2}
                    style={{
                      fontWeight: 'bold',
                      width: 'fit-content'
                    }}
                  >
                    外部検査
                  </Typography>
                  <div className="box_search">
                    <div className="box_search_inner">
                      <Form.Item
                        name="InspectCodeOut"
                        label="コード"
                        labelCol={labelStyle}
                      >
                        <Input
                          className="input-search-size-number-allow-clear-8"
                          style={{ textAlign: 'right' }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="InspectName"
                        label="名称"
                        labelCol={labelStyle}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="InspectTypeOut"
                        label="タイプ"
                        labelCol={labelStyle}
                      >
                        <Select>
                          <Select.Option value=""></Select.Option>
                          <Select.Option value="N">整数</Select.Option>
                          <Select.Option value="N1">小数1</Select.Option>
                          <Select.Option value="N2">小数2</Select.Option>
                          <Select.Option value="N3">小数3</Select.Option>
                          <Select.Option value="N4">小数4</Select.Option>
                          <Select.Option value="X">文字</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div
                  className="box_inner_vertical"
                  style={{
                    width: 'fit-content',
                    marginLeft: 50,
                    marginRight: 50,
                    marginTop: 'auto',
                    marginBottom: 'auto'
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#419087',
                      color: 'white',
                      padding: 5,
                      borderRadius: 5,
                      textAlign: 'center'
                    }}
                  >
                    取込 <DoubleRightOutlined />
                  </div>
                  <Form.Item
                    name="InspectValueConvertDivision"
                    label="変 換"
                    labelCol={labelStyle}
                  >
                    <Select
                      onChange={this.showConvertCoefficientChar}
                      style={{ width: 200 }}
                    >
                      <Select.Option value={0}> </Select.Option>
                      <Select.Option value={1}>変換なし</Select.Option>
                      <Select.Option value={2}>桁数変換</Select.Option>
                      <Select.Option value={3}>文字変換</Select.Option>
                    </Select>
                  </Form.Item>
                  {this.state.showConvertCoefficientChar && (
                    <Form.Item
                      name="ConvertCoefficientChar2"
                      label="　"
                      labelCol={labelStyle}
                    >
                      <Select
                        style={{ width: 200 }}
                        onChange={async (value) => {
                          await this.setConvertCoefficientChar2(value),
                            await this.formRef.current.setFieldsValue({
                              ConvertCoefficientChar: value,
                            });
                        }}>
                        <Select.Option value="-4">×0.0001</Select.Option>
                        <Select.Option value="-3">× 0.001</Select.Option>
                        <Select.Option value="-2">× 0.01</Select.Option>
                        <Select.Option value="-1">× 0.1</Select.Option>
                        <Select.Option value="0"> </Select.Option>
                        <Select.Option value="1">× 10</Select.Option>
                        <Select.Option value="2">× 100</Select.Option>
                        <Select.Option value="3">× 1000</Select.Option>
                        <Select.Option value="4">× 10000</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </div>
                <div className="box_inner_vertical" style={{ width: 'fit-content' }}>
                  <Typography
                    level={2}
                    style={{ fontWeight: 'bold', width: 'fit-content' }}
                  >
                    健診
                  </Typography>
                  <div className="box_search">
                    <div className="box_search_inner">
                      <Form.Item
                        name="ItemCodeMedicalExam"
                        label="コード"
                        labelCol={labelStyle}
                      >
                        <Input.Search
                          className="input-search-size-number-allow-clear-8"
                          style={{ textAlign: "right" }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0271001_InspectItemSearchQuerySingle
                                    InspectCode={this.formRef.current?.getFieldValue('ItemCodeMedicalExam')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        ItemCodeMedicalExam: output.recordData.test_item_code,
                                        exam_name: output.recordData.exam_name,
                                        exam_short_name: output.recordData.exam_short_name,
                                        Expression_21: output.recordData.InspectType
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            });
                          }}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0271001_InspectItemSearchQuerySingle
                                    InspectCode={this.formRef.current?.getFieldValue('ItemCodeMedicalExam')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        ItemCodeMedicalExam: output.recordData.test_item_code,
                                        exam_name: output.recordData.exam_name,
                                        exam_short_name: output.recordData.exam_short_name,
                                        Expression_21: output.recordData.InspectType
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="exam_name"
                        label='名称'
                        labelCol={labelStyle}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name="exam_short_name"
                        label="略名"
                        labelCol={labelStyle}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name="Expression_21"
                        label="タイプ"
                        labelCol={labelStyle}
                      >
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box_button_bottom_right">
                <Button
                  style={{ float: "right" }}
                  type="primary"
                  onClick={this.externalInspectRegister}
                >
                  登録
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS2700099_InputForm;
