import axios from 'configs/axios'
const apiPaths = {
  index: '/api/passing-manage-csv/passing-manage-csv',
  createCSV: '/api/passing-manage-csv/passing-manage-csv/create-csv',
  downloadCSVFile: '/api/passing-manage-csv/passing-manage-csv/download-csv-file'
}

const PassingManageCSVService = {
  async index(params) {
    return axios.post(apiPaths.index, params)
  },
  async createCSV(params) {
    return axios.post(apiPaths.createCSV, params)
  },
  async downloadCSVFile(params) {
    return axios.post(apiPaths.downloadCSVFile, params, { responseType: 'blob' });
  },
}
export default PassingManageCSVService
