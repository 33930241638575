import { message } from 'antd';
import DetailInfoCorrect from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/DetailInfoCorrect'

const DetailInfoCorrectAction = {
    getScreenData(data){
        return DetailInfoCorrect.getScreenData(data)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    }
}
export default DetailInfoCorrectAction;