import axios from 'configs/axios'

const API_LIST = {
  getInspectMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-inspect-master',
  getTreatmentMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-treatment-master',
  getVictimMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-victim-master',
  getDoctorMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-doctor-master',
  getMedicalInstitutionMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-medical-institution-master',
  getDepartmentMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-department-master',
  getFindingsMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-findings-master',
  getExamDepartmentMaster: '/api/ask-issued/introduce-letter-various-master-inquiry/get-exam-department-master',
}
const IntroduceLetterVariousMasterInquiryService = {

  async getInspectMaster(params) {
    return axios.get(API_LIST.getInspectMaster, { params })
  },
  async getTreatmentMaster(params) {
    return axios.get(API_LIST.getTreatmentMaster, { params })
  },
  async getVictimMaster(params) {
    return axios.get(API_LIST.getVictimMaster, { params })
  },
  async getDoctorMaster(params) {
    return axios.get(API_LIST.getDoctorMaster, { params })
  },
  async getMedicalInstitutionMaster(params) {
    return axios.get(API_LIST.getMedicalInstitutionMaster, { params })
  },
  async getDepartmentMaster(params) {
    return axios.get(API_LIST.getDepartmentMaster, { params })
  },
  async getFindingsMaster(params) {
    return axios.get(API_LIST.getFindingsMaster, { params })
  },
  async getExamDepartmentMaster(params) {
    return axios.get(API_LIST.getExamDepartmentMaster, { params })
  },
}

export default IntroduceLetterVariousMasterInquiryService
