import ConditionAddSubService from 'services/basicInfo/ConsultInfoReconstruction/ConditionAddSubService'

const ConditionAddSubAction = {
  index(data) {
    return ConditionAddSubService.index(data)
  },

  update(data) {
    return ConditionAddSubService.update(data)
  },

  clearData(data) {
    return ConditionAddSubService.clearData(data)
  },

  getNameOfficeCodeAction(params) {
    return ConditionAddSubService.getNameOfficeCodeService(params)
  },

  getNamePersonalAction(params) {
    return ConditionAddSubService.getNamePersonalService(params)
  },

  indexPlus(data) {
    return ConditionAddSubService.indexPlus(data)
  },
}

export default ConditionAddSubAction
