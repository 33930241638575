import AgeManageInfoMaintainService from 'services/SM_SystemMaintenance/AgeManageInfoMaintain/AgeManageInfoMaintainService';
import { message, } from "antd";

export const getScreenData = (params) => {
  return AgeManageInfoMaintainService.getScreenDataService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const index = (params) => {
  return AgeManageInfoMaintainService.indexService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const subTitleTable = (params) => {
  return AgeManageInfoMaintainService.subTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const subAgeTable = (params) => {
  return AgeManageInfoMaintainService.subAgeTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const copy_F7 = (params) => {
  return AgeManageInfoMaintainService.copy_F7Service(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const query_F8 = (params) => {
  return AgeManageInfoMaintainService.query_F8Service(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const output_F12 = (params) => {
  return AgeManageInfoMaintainService.output_F12Service(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const f7KeySubAgeTable = (params) => {
  return AgeManageInfoMaintainService.f7KeySubAgeTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const tabKeyAgeSubAgeTable = (params) => {
  return AgeManageInfoMaintainService.tabKeyAgeSubAgeTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const exitSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.exitSubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const recordPrefixSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.recordPrefixSubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const verificationCtrlAge_id_codeSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.verificationCtrlAge_id_codeSubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const onblurCtrlAge_titleSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.onblurCtrlAge_titleSubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const f7KeySubTitleTable = (params) => {
  return AgeManageInfoMaintainService.f7KeySubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const tabKeyAge_titleSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.tabKeyAge_titleSubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const saveSubAgeTable = (params) => {
  return AgeManageInfoMaintainService.saveSubAgeTable(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const deleteSubAgeTable = (params) => {
  return AgeManageInfoMaintainService.deleteSubAgeTable(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const saveSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.saveSubTitleTable(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const deleteSubTitleTable = (params) => {
  return AgeManageInfoMaintainService.deleteSubTitleTableService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const getContractInspectCondition = (params) => {
  return AgeManageInfoMaintainService.getContractInspectConditionService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

// WS1505010_CopyRegister
export const getScreenDataCopyRegister = (params) => {
  return AgeManageInfoMaintainService.getScreenDataCopyRegisterService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const copyCreateCopyRegister = (params) => {
  return AgeManageInfoMaintainService.copyCreateCopyRegisterService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

// WS1505013_CsvOutputScreen
export const getScreenDataCsvOutputScreen = (params) => {
  return AgeManageInfoMaintainService.getScreenDataCsvOutputScreenService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const output_F12CsvOutputScreen = (params) => {
  return AgeManageInfoMaintainService.output_F12CsvOutputScreenService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const gzoomLio_Output = (params) => {
  return AgeManageInfoMaintainService.gzoomLio_OutputService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const next_FieldLio_OutputCsvOutputScreen = (params) => {
  return AgeManageInfoMaintainService.next_FieldLio_OutputCsvOutputScreenService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });
};

export const loadDataAction = (params) => {
  return AgeManageInfoMaintainService.loadDataActionService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });


};

export const loadAgeSubDataAction = (params) => {
  return AgeManageInfoMaintainService.loadAgeSubDataActionService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });


};

export const registerDataAction = (params) => {
  return AgeManageInfoMaintainService.registerDataActionService(params)
    .then((res) => {

      return res.data
    })
  /*     .catch((err) => {
        console.log(err.response?.data?.message);
      }); */


};

export const deleteDataAction = (params) => {
  return AgeManageInfoMaintainService.deleteDataActionService(params)
    .then((res) => {

      return res.data
    })
    .catch((err) => {
      console.log(err.response?.data?.message);
    });


};