import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, Dropdown, Menu, message } from "antd";

import { PlusOutlined, CheckOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons';

import WS0638002_EscortManageMaintainEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638002_EscortManageMaintainEdit.jsx';
import WS0638005_EscortManageMaintainOptionInput from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638005_EscortManageMaintainOptionInput.jsx';
import WS0641001_EscortDetailSub from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641001_EscortDetailSub.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import EscortManageMaintainAction from "redux/AdvancePreparation/DocumentManageMaintain/EscortManageMaintain.action";
import WS0638007_Copy from "./WS0638007_Copy";
import WS0640001_EscortMaintain from "./WS0640001_EscortMaintain";

class WS0638001_EscortManageMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'エスコート管理保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource0: [],
      isLoadingTable0: false,
      selectedRowKeys0: [],
      rowSelected0: [],
      indexTable0: 0,
      old_medical_exam_course: null,
      dataSourceOld: [],

      dataSource1: [],
      isLoadingTable1: false,
      selectedRowKeys1: [],
      rowSelected1: [],
      indexTable1: 0,
      old_W1_serial_num: null,
    };
    this.handleAddRowTable = this.handleAddRowTable.bind(this);
  }

  componentDidMount() {
    this.getDataFormat(true);
  }

  // Format
  /**
   * 初期表示
   * @param {} reload
   */
  getDataFormat(reload) {
    this.setState({ isLoadingTable0: reload ? true : false })

    // 選択されたデータのエスコート情報を取得（this.props.Li_Format）
    let params = {
      Li_Format: this.props.Li_Format
    }

    EscortManageMaintainAction.getDataFormat(params)
      .then(async (res) => {
        let data = res ? res.Data : []
        let index = reload ? 0 : this.state.indexTable0
        await this.setState({
          dataSource0: data,
          dataSourceOld: data,
          isLoadingTable0: false,

          rowSelected0: res && data.length > 0 ? data[index] : null,
          selectedRowKeys0: res && data.length > 0 ? [data[index].id] : [],
          indexTable0: index,
          old_medical_exam_course: res && data.length > 0 ? data[index].medical_exam_course : null,
        })

        this.formRef.current?.setFieldsValue({
          Format: res ? res.Format : ''
        })
      })
      .finally(() => this.setState({ isLoadingTable0: false }))
  }


  // add new record
  /**
   * Format新規登録・編集
   * @param {} type
   */
  handleAddRowTable(record) {
    let newflag = false

    if (!record.id) {
      newflag = true
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0638002_EscortManageMaintainEdit
            record={record}
            newflag={newflag}

            //新規・編集
            onUpdate={(output) => {
              const inputData = {
                id: record.id,
                Format: this.props.Li_Format,
                Expansion: output.Expansion,
                medical_exam_course: output.medical_exam_course,
                escort_code: output.escort_code,
                format_name: output.format_name,
                output_number_of_copies: output.output_number_of_copies,
                DayWeek: output.DayWeek,
              }
              EscortManageMaintainAction.createAndUpdateFormat(inputData)
                .then((res) => {
                  this.closeModal()
                  this.getDataFormat(true)
                })
            }}

            //削除
            onDelete={() => {
              const inputData = {
                id: record.id
              }
              EscortManageMaintainAction.deleteFormat(inputData)
                .then((res) => {
                  this.closeModal()
                  this.getDataFormat(true)
                })
            }}
          />
        ),
      },
    });
  }

  show_WS0641001(data) {
    let record = {
      escort_code: data.escort_code,
      escort_name: data.escort_name,
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1300,
        className: '',
        component:
          <WS0641001_EscortDetailSub
            record={record}
          />
      },
    })
  }

  optionInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0638005_EscortManageMaintainOptionInput
            id={record.id}
          />
        )
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="escort-manage-maintain">
        <Card
          title="エスコート管理保守"
          className="mb-3"
        >
          <Dropdown
            className='option_btn_right_top_modal'
            trigger='click'
            size='small'
            icon={<MenuOutlined />}
            overlay={() => {
              return (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 430,
                          component: (
                            <WS0638007_Copy
                              Li_FormatF={this.props.Li_Format}
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  Format: output.Lo_FormatT
                                })
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  >
                    複写
                  </Menu.Item>

                  <Menu.Item
                    key='2'
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS0640001_EscortMaintain
                              Format={this.props.Li_Format}
                              onFinishScreen={() => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  >
                    内容設定
                  </Menu.Item>
                </Menu>
              )
            }}
          >
            <Button
              className='option_btn_right_top'
              size='small'
              icon={<MenuOutlined />}
            />
          </Dropdown>

          <div className="box_inner_horizontal"
            style={{ marginBottom: 15 }}>
            <div>フォーマット</div>
            <div style={{ fontWeight: 'bold' }}>{this.props.Li_Format}</div>
          </div>

          <Table
            style={{ cursor: 'pointer' }}
            size='small'
            dataSource={this.state.dataSource0}
            loading={this.state.isLoadingTable0}
            pagination={false}
            scroll={{ y: 500 }}
            bordered
            rowKey={(res) => res.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  this.show_WS0641001(record)
                }
              };
            }}
          >

            <Table.Column
              title="有効"
              dataIndex="Expansion"
              width={45}
              align="center"
              render={(value, record, index) => {
                if (value) {
                  return <CheckOutlined />
                } else {
                  return ''
                }
              }}
            />

            <Table.Column
              title="コース"
              dataIndex="medical_exam_course"
              width={80}
            />

            <Table.Column
              title="コース名称"
              dataIndex="course_name_short_name"
            />

            <Table.Column
              title="エスコート"
              dataIndex="escort_code"
              width={100}
            />

            <Table.Column
              title="エスコート内容"
              dataIndex="escort_name"
            />

            <Table.Column
              title="書式"
              dataIndex="format_name"
            />

            <Table.Column
              title="部数"
              dataIndex="output_number_of_copies"
              width={65}
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              title="曜日"
              dataIndex="DayWeek"
              align="center"
              width={45}
              render={(value, record, index) => {
                if (value) {
                  return <CheckOutlined />
                } else {
                  return ''
                }
              }}
            />

            <Table.Column
              width={40}
              title={
                //新規登録のボタン
                <Button size='small'
                  onClick={this.handleAddRowTable}
                  type="primary"
                  icon={<PlusOutlined />}>
                </Button>
              }

              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger="click"
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key="変更"
                          onClick={() =>
                            this.handleAddRowTable(record)
                          }
                        >変更
                        </Menu.Item>

                        <Menu.Item
                          key="オプション"
                          onClick={() =>
                            this.optionInput(record)
                          }
                        >オプション
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size="small"
                      icon={<MoreOutlined />}
                      style={{ width: "100%" }}
                    ></Button>
                  </Dropdown>
                );
              }}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0638001_EscortManageMaintain);
