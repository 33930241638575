import axios from 'configs/axios'
const API_LIST = {
  index: '/api/inspect-value-calculate-auto-maintain/copy',
  copyRegister: '/api/inspect-value-calculate-auto-maintain/copy/copy-register',
}

const CopyService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async copyRegister(params) {
    return axios.put(API_LIST.copyRegister, params)
  },
}

export default CopyService
