import { message } from "antd";
import ConditionCorrectSubService from "services/InspectionMaintenance/ConditionExpressCmtSetting/ConditionCorrectSubService";

const ConditionCorrectSubAction = {
  redisplay_F11(data) {
    return ConditionCorrectSubService.redisplay_F11(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getEquationDisplay(data) {
    return ConditionCorrectSubService.getEquationDisplay(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default ConditionCorrectSubAction;
