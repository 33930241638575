import { createAction, handleActions } from "redux-actions";

export const loading = createAction("LOADING");

const defaultState = {
  loading: false
};

const handlers = {
  /**
   * メインコンテンツ全体のローディング
   */
  [loading]: (state, action) => {
    return {
      ...state,
      ...{
        loading: action.payload
      }
    };
  }
};

export default handleActions(handlers, defaultState);
