///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
// import './WS0743001_NotInputCheckCategoryMock.scss'
import Color from 'constants/Color'
import moment from 'moment'
import { connect } from 'react-redux'
import { Card, Form, Input, Radio, Button, Table, Modal, Tooltip, Space, Menu, Dropdown, message, } from 'antd'
import { SearchOutlined, MoreOutlined, CaretUpOutlined, CloseOutlined, MenuOutlined, PlusOutlined, } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers'
import GetImage from 'constants/Images'
import WS0745001_NotInputCheckMaintain from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745001_NotInputCheckMaintain.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS0743010_TypeQuery from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0743010_TypeQuery.jsx'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx'
import WS0739001_InputVoteResultInputInspectInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0739001_InputVoteResultInputInspectInput.jsx'
import status_input_lock from 'assets/img/status_input_lock.png'
import NotInputCheckCategorytAction from 'redux/InputBusiness/NotInputCheckCategory/NotInputCheckCategory.action'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import WS0739012_DataUpdateConfirm from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0739012_DataUpdateConfirm.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ResultDisplay from 'pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx'
import MemoFunction from 'pages/ZZ_Others/SANAI_Memo/MemoFunction.jsx'
import DataGrid, { Column } from 'devextreme-react/data-grid'

// Form labelの幅
const labelCol = { labelCol: { style: { width: '50px' } } }
const titleStyle = { writingMode: 'vertical-rl', textAlign: 'center' }
class WS0743001_NotInputCheckCategoryMock extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '未入力チェック'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
      disabled: true,

      rowSelected: {},
      selectedRowKeys: [],
      tableIndex: 0,
      updateDataInspectInput: false,
      afterUpdateDataInspectInput: false,
      paramsUpdateInspectInput: {},

      isLoadingPage: false,

      Expression_36: 163,

      categoryList: [],
      conditionAddData: null
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    NotInputCheckCategorytAction.getScreenData().
      then((res) => {
        this.setFormFieldValue('Type', res && res.Type ? res.Type : '')
        this.setFormFieldValue('remarks', res && res.remarks ? res.remarks : '')
        this.formRef.current?.setFieldsValue({ DateF: moment(new Date().toISOString()) })
        this.forceUpdate()
      })
  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ])
  }

  WS2583001_ConsultInquirySub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.W1_reserve_num}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ),
      },
    })
  }

  WS3020036_CoupledPrintInstruction(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={record.W1_course_level}
            Li_ReserveNum={record.W1_reserve_num}
            Li_Parameters={record.W1_parameters}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ),
      },
    })
  }

  WS2637001_OverallResultDisplayInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1700,
        component: (
          <WS2637001_OverallResultDisplayInput
            Li_CourseLevel={record.W1_course_level}
            Li_ReserveNum={record.W1_reserve_num}
            onClickedCreate={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ),
      },
    })
  }

  excelReport() {
    let stateDataSource = this.state.dataSource
    let data = []

    for (var i = 0; i < stateDataSource.length; i++) {
      data[i] = {
        consultDate: stateDataSource[i].W1_consult_date,
        receiptNum: stateDataSource[i].W1_receipt_num,
        kanjiName: stateDataSource[i].W1_kanji_name,
        courseCd: stateDataSource[i].W1_course_cd,
        contractShortName: stateDataSource[i].contract_short_name,
        displayResults: stateDataSource[i].DisplayResults,
      }
    }

    let param = {
      data: [...data],
      categoryNameList: this.state.dataSource[0].CategoryName
    }

    NotInputCheckCategorytAction.exportExcel(param)
      .then((res) => {
        download_file(res)
      })
      .catch()
  }

  /**
   * 検索ボタンが押された処理
   */
  onStatusList() {
    const formValues = this.formRef.current.getFieldValue()
    const params = {
      State: formValues && formValues.State ? formValues.State : 0, //タイプ（0：検査 , 1：判定）
      Type: formValues && formValues.Type ? formValues.Type : 0, //種別
      DateFDate: formValues && formValues.DateF ? formValues.DateF.format('YYYY-MM-DD') : '',  //日付
      conditionAddData: this.state.conditionAddData
    }
    this.setState({ isLoading: true })

    // 日付が空白の時はエラー
    if (!formValues.DateF) {
      message.error('日付を入力してください')
      return
    }

    console.log('onStatusList')

    NotInputCheckCategorytAction.StatusList(params)
      .then((res) => {
        if (res) {
          const dataRes = res.result ? res.result : []
          const maxCounter = res.maxCounter ?? 0
          let tempArray = []
          let counter = 1

          while (counter <= maxCounter) {
            let number = ('00' + counter).slice(-2)
            tempArray.push(number)
            counter++
          }

          const data = dataRes
          this.setState({
            dataSource: data,
            rowSelected: data.length > 0 ? data[0] : {},
            selectedRowKeys: data.length > 0 ? [data[0].id] : [],
            indexTable: 0,
            disabled: dataRes.length > 0 ? false : true,
            categoryList: tempArray,
          })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  showWS0802001_PrintInstruction() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={this.state.rowSelected?.W1_course_level}
            Li_ReserveNum={this.state.rowSelected?.W1_reserve_num}
            onFinishScreen={(obj) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  detailF7() {
    let params = {
      W1_consult_date: this.state.rowSelected?.W1_consult_date,
      W1_reserve_num: this.state.rowSelected?.W1_reserve_num,
      W1_course_level: this.state.rowSelected?.W1_course_level,
      personal_number_id: this.state.rowSelected?.personal_number_id,
      StsPersonalInfoBasic: this.state.rowSelected?.StsPersonalInfoBasic,
    }

    this.setState({ isLoadingPage: true })
    NotInputCheckCategorytAction.detailF7(params)
      .then((res) => {
        if (res?.data?.isData) {
          this.showModalInputVoteResultInputInspectInput(res?.data)
        } else {
          Modal.info({
            title: '未入力の検査情報がありません',
          })
        }
        this.setState({ isLoadingPage: false })
      })
      .catch((err) => {
        this.setState({ isLoadingPage: false })
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  showModalInputVoteResultInputInspectInput(data) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0739001_InputVoteResultInputInspectInput
            Li_CourseLevelThisTime={data.Li_CourseLevelThisTime}
            Li_ReserveNumThisTime={data.Li_ReserveNumThisTime}
            Li_CourseLevelPrevious={data.Li_CourseLevelPrevious}
            Li_ReserveNumPrevious={data.Li_ReserveNumPrevious}
            Li_PublicName={data.Li_PublicName}
            Li_GuidanceInstructionsAdd={data.Li_GuideInstructionsAdd}
            Li_InputProtectionIn={data.Li_InputProtectionIn}
            Li_InputProtectionImage={data.Li_InputProtectionImage}
            Li_EscInvalid={data.Li_EscInvalid}
            onUpdateValue={(output) => {
              this.setState({
                updateDataInspectInput: output.Lo_Update,
                paramsUpdateInspectInput: output.paramsUpdate,
                afterUpdateDataInspectInput: output.Lo_After_Update,
              })
            }}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  updateDataInspectInput() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 280,
        component: (
          <WS0739012_DataUpdateConfirm
            Li_Division={this.state.paramsUpdateInspectInput.Li_Division}
            Li_CourseLevel={this.state.paramsUpdateInspectInput.Li_CourseLevel}
            Li_ReserveNum={this.state.paramsUpdateInspectInput.Li_ReserveNum}
            Li_TotalJudge={''}
            Lo_GuideMatters={''}
            onFinishScreen={(output) => {
              if (output.Lo_Update) {
                this.detailF7After()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  detailF7After() {
    let params = {
      W1_reserve_num: this.state.rowSelected?.W1_reserve_num,
      W1_course_level: this.state.rowSelected?.W1_course_level,
      Li_CategoryCodeList: this.state.rowSelected?.Li_CategoryCodeList,
      Li_Format: this.formRef.current?.getFieldValue('Type'),
      Li_State: this.formRef.current?.getFieldValue('State'),
      W1_key_info: this.state.rowSelected?.W1_key_info,
      Li_ColorList: this.state.rowSelected?.ColorList,
      W1_input_protection_state: this.state.rowSelected?.W1_input_protection_state,
      FixedPattern: this.state.rowSelected?.FixedPattern,
      StsSeparateJudgeCheck: this.state.rowSelected?.StsSeparateJudgeCheck,
    }

    this.setState({ isLoading: true })
    NotInputCheckCategorytAction.detailF7After(params)
      .then((res) => {
        this.onStatusList()
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 結果値　render 設定
   * @param {*} result
   * @param {*} colorNum
   * @returns
   */
  renderDisplayResult = (result, colorNum) => {
    let tooltip = ''
    switch (result) {
      case '○':
        tooltip = '全入力'
        break
      case '▲':
        tooltip = '一部入力'
        break
      case '×':
        tooltip = '未入力'
        break
      default:
        break
    }

    return (
      <Tooltip title={tooltip}>
        <div
          style={{
            textAlign: 'center',
            color: Color(colorNum)?.Foreground,
          }}
        >
          <span>{result}</span>
        </div>
      </Tooltip>
    )
  }

  /**
   *  総合結果表示/入力 モーダル
   * @param {*} record
   */
  interviewSupport = (record) => {
    console.log(record.W1_reserve_num)
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.W1_reserve_num}
            protectFlag={record.protectFlag}
            provisionalFlag={record.provisionalFlag}
          />
        ),
      },
    })
  }

  render() {
    const titleString = (params) =>
      this.state && this.state.dataSource && this.state.dataSource[0]
        ? this.state.dataSource[0][params]
        : ''
    const format = 'YYYY/MM/DD'
    return (
      <div className='not-input-check-category'>
        <Card title='未入力チェック(モック)' style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              State: 0
            }}
          >
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className='option_btn_right_top'
                trigger='click'
                size='small'
                icon={<MenuOutlined />}
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0745001_NotInputCheckMaintain
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      >
                        種別保守
                      </Menu.Item>

                      <Menu.Item key='1'
                        onClick={() => {
                          window.open(location.protocol + '//' + location.host + '/progress-setting/progress-set')
                        }}
                      >
                        進捗状況設定
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner' style={{ paddingTop: 0 }}>
                    <Form.Item
                      name='DateF'
                      label='日付'
                      {...labelCol}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='Type'
                        label='種別'
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-4'
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 400,
                                component: (
                                  <WS0743010_TypeQuery
                                    onFinishScreen={(output) => {
                                      this.setFormFieldValue(
                                        'Type',
                                        output.Lo_Type
                                      )
                                      this.setFormFieldValue(
                                        'remarks',
                                        output.Lo_Remark
                                      )
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        />
                      </Form.Item>
                      <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('remarks')}</div>
                    </div>

                    <Form.Item
                      name='State'
                      label='タイプ'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Radio.Group
                        // defaultValue={0}
                        onChange={() => this.forceUpdate()}
                      >
                        <Radio value={0}>検査</Radio>
                        <Radio value={1}>判定</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </div>
                </div>


                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.onStatusList()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component: (
                                <WS2786001_ConditionAddSub
                                  Li_DateF={this.formRef.current?.getFieldValue('DateF')}
                                  Li_DateT={this.formRef.current?.getFieldValue('DateF')}
                                  Li_State='1'
                                  Lio_KeyInfo={this.formRef.current?.getFieldValue('KeyNum')}
                                  Li_ConditionAddData={this.state.conditionAddData}
                                  onFinishScreen={(output) => {
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                    })
                                    this.formRef.current?.setFieldsValue({
                                      KeyNum: output.Lio_KeyInfo
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <DataGrid
                style={{ height: 600 }}
                dataSource={this.state.dataSource}
                showBorders={true} //周りの線
                showRowLines={true} //横線
                wordWrapEnabled={true} //折り返し
                allowColumnReordering={true} //カラム移動
                allowColumnResizing={true} //カラムの幅リサイズ
                columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                columnChooser={{ enabled: true, mode: 'select' }} //カラム表示選択
                paging={{
                  // ペジネーション設定
                  enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                }}
                scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない
              >
                <Column
                  caption='ロック' //タイトル
                  dataField='Expression_134' //dataIndex
                  width={40}
                  fixed={true} //カラム固定
                  alignment='center'
                  headerCellRender={() =>
                    <img src={status_input_lock}></img>
                  }
                  cellRender={(event) =>
                    <img
                      style={{ textAlign: 'center' }}
                      src={GetImage(event.value)}
                    />
                  }
                />
                <Column
                  caption='受診日'
                  dataField='W1_consult_date'
                  width={140}
                  fixed={true}
                  cellRender={(event) =>
                    <span>
                      {moment(event.W1_consult_date).format('YYYY/MM/DD (ddd)')}
                    </span>
                  }
                />
                <Column
                  caption='受付No'
                  dataField='W1_receipt_num'
                  width={62}
                  fixed={true}
                  cellRender={(event) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        {event.value === 0 ? '' : event.value}
                      </div>
                    )
                  }}
                />
                <Column
                  caption='氏名'
                  dataField='W1_kanji_name'
                  width={180}
                  fixed={true}
                  cellRender={(event) =>
                    <Tooltip title={event.data.Expression_137}>
                      <span>{event.value}</span>
                    </Tooltip>
                  }
                />
                <Column
                  caption='メモ'
                  dataField='consultationMemo'
                  width={45}
                  fixed={true}
                  cellRender={(event) =>
                    <div
                      style={{ color: '#0000cd', textAlign: 'center' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 'fit-content',
                            component: (
                              <MemoFunction
                                date={this.formRef.current?.getFieldValue('DateF')}
                                KanjiName={event.data.W1_kanji_name}
                                personal_number_id={event.data.personal_number_id}
                                reservation_number={event.data.W1_reserve_num}
                                onSaveMemos={() => this.closeModal()}
                              />
                            ),
                          }
                        })
                      }}
                    >
                      {event.value}
                    </div>
                  }
                />
                <Column
                  caption='発送日'
                  dataField='postDate'
                  width={100}
                  fixed={true}
                  cellRender={(event) =>
                    <div style={{ textAlign: 'center' }}>
                      {event.value}
                    </div>
                  }
                />
                <Column
                  fixed={true}
                  caption='契約情報'
                  dataField='contract_short_name'
                  width={200}
                  render={(event) => {
                    return (
                      <Tooltip title={event.data.Expression_136}>
                        <span>{event.value}</span>
                      </Tooltip>
                    )
                  }}
                />

                <Column
                  caption='総合判定'
                  dataField='comprehensive_judgment'
                  width={40}
                  headerCellRender={() =>
                    <div style={titleStyle} >{'総合判定'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(event.data.Expression_150)?.Foreground,
                        }}
                      >
                        <span>{event.value}</span>
                      </div>
                    )
                  }}
                />

                <Column
                  caption='指導事項'
                  width={40}
                  dataField='Expression_145'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'指導事項'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <span>{event.value}</span>
                      </div>
                    )
                  }}
                />

                <Column
                  caption='階層化'
                  width={40}
                  dataField='Expression_146'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'階層化'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <Tooltip caption={event.data.Expression_14}>
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(event.data.Expression_147)?.Foreground,
                          }}
                        >
                          <span>{event.value}</span>
                        </div>
                      </Tooltip>
                    )
                  }}
                />
                <Column
                  caption='メタボ'
                  width={40}
                  dataField='Expression_148'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'メタボ'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <Tooltip caption={event.data.Expression_15}>
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(event.data.Expression_149)?.Foreground,
                          }}
                        >
                          <span>{event.value}</span>
                        </div>
                      </Tooltip>
                    )
                  }}
                />

                {/* 項目の表示 */}
                {this.state.categoryList.map(number =>
                  <Column
                    key={'Column' + number}
                    caption={titleString('CategoryName_' + number)}
                    width={35}
                    dataField={'DisplayResult' + number}
                    alignment='center'
                    headerCellRender={() =>
                      <div style={titleStyle} >
                        {titleString('CategoryName_' + number)}
                      </div>
                    }
                    cellRender={(event) => {
                      return this.renderDisplayResult(event.data['DisplayResult' + number], event.data['CategoryColor_' + number])
                    }}
                  />
                )}

                <Column
                  caption=''
                  dataField=''
                  width={40}
                  fixed={true}
                  fixedPosition='right'
                  cellRender={(event) => {
                    const record = event.data
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='照会'
                              onClick={() =>
                                this.WS2583001_ConsultInquirySub(record)
                              }
                            >
                              照会
                            </Menu.Item>
                            <Menu.Item
                              key='入力'
                              onClick={() =>
                                this.WS2637001_OverallResultDisplayInput(record)
                              }
                            >
                              入力
                            </Menu.Item>
                            <Menu.Item
                              key='出力'
                              onClick={() =>
                                this.WS3020036_CoupledPrintInstruction(record)
                              }
                            >
                              出力
                            </Menu.Item>
                            <Menu.Item
                              key='EXCEL'
                              onClick={() =>
                                this.excelReport()
                              }
                            >
                              EXCEL
                            </Menu.Item>
                            <Menu.Item
                              key='面談支援'
                              onClick={() =>
                                this.interviewSupport(record)
                              }
                            >
                              面談支援
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )
                  }}
                />

              </DataGrid>

              <Table
                style={{ display: 'none' }}
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={false}
                rowClassName={(record, index) => record.id === this.state.rowSelected.id ? 'table-row-light' : ''}
                rowKey={(record) => record.id}
                scroll={{ y: 300 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: async () => {
                      await this.setState({
                        rowSelected: record,
                        selectedRowKeys: [record.id],
                        tableIndex: rowIndex,
                      })
                    },
                    onDoubleClick: () => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1700,
                          component: (
                            <WS2637001_OverallResultDisplayInput
                              Li_ReserveNum={
                                this.state.rowSelected?.W1_reserve_num
                              }
                              Li_CourseLevel={
                                this.state.rowSelected?.W1_course_level
                              }
                              onFinishScreen={() => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    },
                  }
                }}
              >
                <Table.Column
                  fixed='left'
                  title={<img src={status_input_lock}></img>}
                  dataIndex=''
                  width='40px'
                  render={(item, record) => {
                    return (
                      <img
                        style={{ textAlign: 'center' }}
                        src={GetImage(record.Expression_134)}
                      />
                    )
                  }}
                />

                <Table.Column
                  fixed='left'
                  title='受診日'
                  dataIndex='W1_consult_date'
                  width='100px'
                  render={(value, record) => {
                    return (
                      <span format={format} style={{ cursor: 'pointer' }}>
                        {moment(record.W1_consult_date).format('YYYY/MM/DD')}
                      </span>
                    )
                  }}
                />

                <Table.Column
                  fixed='left'
                  title='受付No'
                  dataIndex='W1_receipt_num'
                  width='70px'
                  render={(index, record) => {
                    return (
                      <div
                        hidden={record.W1_receipt_num > 0 ? false : true}
                        style={{ textAlign: 'right' }}
                      >
                        <span>{record.W1_receipt_num}</span>
                      </div>
                    )
                  }}
                />

                <Table.Column
                  fixed='left'
                  title='氏名'
                  dataIndex='W1_kanji_name'
                  width='180px'
                  render={(value, record) => {
                    return (
                      <Tooltip title={record.Expression_137}>
                        <span>{record.W1_kanji_name}</span>
                      </Tooltip>
                    )
                  }}
                />

                <Table.Column
                  fixed='left'
                  title='メモ'
                  dataIndex='consultationMemo'
                  width='45px'
                  render={(value, record) => (
                    <div
                      style={{ color: '#0000cd', textAlign: 'center' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 'fit-content',
                            component: (
                              <MemoFunction
                                date={this.formRef.current?.getFieldValue('DateF')}
                                KanjiName={record.W1_kanji_name}
                                personal_number_id={record.personal_number_id}
                                reservation_number={record.W1_reserve_num}
                                onSaveMemos={() => this.closeModal()}
                              />
                            ),
                          }
                        })
                      }}
                    >
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  fixed='left'
                  title='発送日'
                  dataIndex='postDate'
                  width='100px'
                  render={(value, record, index) => {
                    return (
                      <Tooltip title={record.postUserName}>
                        <div style={{ textAlign: 'center' }}>{value}</div>
                      </Tooltip>
                    )
                  }}
                />

                {/* <Table.Column
                  fixed='left'
                  title='コース'
                  dataIndex='W1_course_cd'
                  width='60px'
                  render={(value, record) => {
                    return (
                      <Tooltip title={record.Expression_136}>
                        <span style={{ cursor: 'pointer' }}>
                          {record.W1_course_cd}
                        </span>
                      </Tooltip>
                    )
                  }}
                /> */}

                <Table.Column
                  fixed='left'
                  title='契約情報'
                  dataIndex='contract_short_name'
                  width='200px'
                  render={(value, record) => {
                    return (
                      <Tooltip title={record.Expression_136}>
                        <span>{record.contract_short_name}</span>
                      </Tooltip>
                    )
                  }}
                />

                <Table.Column
                  title={
                    <div style={titleStyle} >{'総合判定'}</div>
                  }
                  dataIndex='comprehensive_judgment'
                  width='40px'
                  render={(value, record) => {
                    return (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(record.Expression_150)?.Foreground,
                        }}
                      >
                        <span>{record.comprehensive_judgment}</span>
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title={
                    <div style={titleStyle} >{'指導事項'}</div>
                  }
                  width='40px'
                  dataIndex='Expression_145'
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <span>{record.Expression_145}</span>
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title={
                    <div style={titleStyle} >{'階層化'}</div>
                  }
                  width='40px'
                  dataIndex='Expression_146'
                  render={(value, record) => {
                    return (
                      <Tooltip title={record.Expression_14}>
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.Expression_147)?.Foreground,
                          }}
                        >
                          <span>{record.Expression_146}</span>
                        </div>
                      </Tooltip>
                    )
                  }}
                />

                <Table.Column
                  title={
                    <div style={titleStyle} >{'メタボ'}</div>
                  }
                  width='40px'
                  dataIndex='Expression_148'
                  render={(value, record) => {
                    return (
                      <Tooltip title={record.Expression_15}>
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.Expression_149)?.Foreground,
                          }}
                        >
                          <span>{record.Expression_148}</span>
                        </div>
                      </Tooltip>
                    )
                  }}
                />

                {/*
                  　項目の表示
                  */}
                {this.state.categoryList.map(number =>
                  <Table.Column
                    key={'Column' + number}
                    title={
                      <div style={titleStyle} >{titleString('CategoryName_' + number)}</div>
                    }
                    width='40px'
                    dataIndex={'DisplayResult' + number}
                    render={(value, record) => {
                      return this.renderDisplayResult(record['DisplayResult' + number], record['CategoryColor_' + number])
                    }}
                  />
                )}


                <Table.Column
                  title=''
                  dataIndex=''
                  key=''
                  width='40px'
                  fixed='right'
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='照会'
                              onClick={() =>
                                this.WS2583001_ConsultInquirySub(record)
                              }
                            >
                              照会
                            </Menu.Item>
                            <Menu.Item
                              key='入力'
                              onClick={() =>
                                this.WS2637001_OverallResultDisplayInput(record)
                              }
                            >
                              入力
                            </Menu.Item>
                            <Menu.Item
                              key='出力'
                              onClick={() =>
                                this.WS3020036_CoupledPrintInstruction(record)
                              }
                            >
                              出力
                            </Menu.Item>
                            <Menu.Item
                              key='EXCEL'
                              onClick={() =>
                                this.excelReport()
                              }
                            >
                              EXCEL
                            </Menu.Item>
                            <Menu.Item
                              key='面談支援'
                              onClick={() =>
                                this.interviewSupport(record)
                              }
                            >
                              面談支援
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '40' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

            <Space style={{ float: 'right', display: (this.formRef.current?.getFieldValue('State')) ? 'none' : '' }}>
              <span style={{ paddingRight: '10px' }}>
                全入力 ○</span>
              <span style={{ paddingRight: '10px' }}>
                一部入力 <CaretUpOutlined style={{ color: 'blue' }} />
              </span>
              <span style={{ paddingRight: '10px' }}>
                未入力 <CloseOutlined style={{ color: 'red' }} />
              </span>
            </Space>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.updateDataInspectInput) {
              this.updateDataInspectInput()
              this.setState({
                updateDataInspectInput: false,
              })
            } else {
              if (this.state.afterUpdateDataInspectInput) {
                this.detailF7After()
              }
              this.closeModal()
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0743001_NotInputCheckCategoryMock)
