import React from "react";
import { connect } from "react-redux";
import { DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  InputNumber,
  Input,
  Button,
  Table,
  Row,
  Col,
  Space,
  Modal,
  message
} from "antd";

import {
  getSetSubprocessAction,
  getCourseSubInputAction,
  saveAndUpdateCourseSubInputAction,
  BatchProcess_F10Action
} from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/CourseSelect.action";

import WS1174006_CourseCopyingFunction from "./WS1174006_CourseCopyingFunction";
import WS0267001_CategorySearchQuerySingle from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS2704001_CategoryMasterMaintain from "pages/MS_InspectionMaintenance/V4MS0102000_CategoryMasterMaintain/WS2704001_CategoryMasterMaintain.jsx";

import WS1176001_InspectSetInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176001_InspectSetInfo.jsx";
import WS0181001_QueryInspectSet from "./WS0181001_QueryInspectSet";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1176001_InspectSetInfoSubInspectSetInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176001_InspectSetInfoSubInspectSetInfo.jsx";

const styleCol = {
  borderRight: "1px solid #F0F0F0"
};

class WS1174003_CourseSelect extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      mainPageSize: 10,
      subSitePageSize: 10,
      subFindingPageSize: 10,

      classificationCode: "",

      dataSourceMain: [],
      rowSelectMain: {},
      isLoadingTableMain: false,

      dataSourceSubSite: [],
      rowSelectSubSite: {},
      isLoadingTableSubSite: false,

      dataSourceSubFinding: [],
      rowSelectSubFinding: {},
      isLoadingTableSubFinding: false,

      isAddRow: false,
      courseCode: ""
    };
  }

  componentDidMount = () => {
    this.loadDataMainTable();
  };

  BatchProcess_F10 = () => {
    BatchProcess_F10Action()
      .then(res => {
        if (res?.data) {
          message.info(res.data.message);
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  loadDataMainTable = () => {
    VenusApiRoutesV2.callApi("API001174003002").then(res => {
      if (res) {
        this.setState({
          dataSourceMain: res,
          rowSelectMain: this.state.rowSelectMain.id ? this.state.rowSelectMain : res[0]
        });

        if (res.length > 0) {
          this.getCourseSubInput(res[0]["course_code"]);
        } else {
          this.setState({
            dataSourceSubSite: [],
            dataSourceSubFinding: []
          });
        }
      }
    });
  };

  getCourseSubInput(Li_CourseCode) {
    this.setState({
      isLoadingTableSubSite: true,
      isLoadingTableSubFinding: true
    });
    getCourseSubInputAction({ Li_CourseCode })
      .then(res => {
        if (res) {
          this.setState({
            dataSourceSubSite: res.data
            // dataSourceSubFinding: res.data.FindingsTable
          });
          this.formRef.current?.setFieldsValue({
            dataSourceSubSite: res.data
          });
          this.forceUpdate();
          if (res.data.length > 0) {
            this.getSetSubprocess(res.data[0]["test_set"]);
          } else {
            this.setState({
              dataSourceSubFinding: []
            });
          }
        }
      })
      .catch()
      .finally(() =>
        this.setState({
          isLoadingTableSubSite: false,
          isLoadingTableSubFinding: false
        })
      );
  }

  getSetSubprocess = Li_SetCode => {
    getSetSubprocessAction({ Li_SetCode })
      .then(res => {
        if (res?.data) {
          this.setState({
            dataSourceSubFinding: res.data,
            rowSelectMain: this.state.rowSelectMain.id ? this.state.rowSelectMain : res.data[0]
          });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  updateDatasource(index, field, flag = 0) {
    let data = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    if (flag === 0) {
      for (let k in field) {
        data[index][k] = field[k];
      }
      this.formRef.current?.setFieldsValue({
        dataSourceSubSite: data
      });
      this.forceUpdate();
    } else {
      for (let k in field) {
        data[index][k] = field[k];
      }
      this.formRef.current?.setFieldsValue({
        dataSourceSubSite: data
      });
      this.forceUpdate();

    }
  }

  ReturnComponent = component => {
    let components = {
      WS2704001_CategoryMasterMaintain,
      WS1176001_InspectSetInfo,
      WS1174006_CourseCopyingFunction,
      WS0181001_QueryInspectSet
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  renderMenuBar = () => (
    <Space>
      <Button
        type="primary"
        onClick={() => {
          this.callModal({ Li_Flag: true }, "80%", "WS2704001_CategoryMasterMaintain");
        }}
      >
        ｶﾃｺﾞﾘ
      </Button>
      <Button
        type="primary"
        onClick={() =>
          this.callModal({ Li_BillingManageNum: "" }, "70%", "WS1176001_InspectSetInfo")
        }
      >
        ｾｯﾄ
      </Button>
      <Button
        type="primary"
        onClick={() => {
          this.callModal(
            {
              Li_course_code: this.state.rowSelectMain ? this.state.rowSelectMain.course_code : "",
              Li_course_name_short_name: this.state.rowSelectMain
                ? this.state.rowSelectMain.course_name_short_name
                : "",
              Li_course_name_formal: this.state.rowSelectMain
                ? this.state.rowSelectMain.course_name_formal
                : ""
            },
            "70%",
            "WS1174006_CourseCopyingFunction"
          );
        }}
      >
        複写
      </Button>
      <Button type="primary" onClick={this.BatchProcess_F10}>
        一括処理
      </Button>
    </Space>
  );

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex(item => recordID === item.id);
  };

  addRowTableMain = () => {
    const count = Math.random().toString(36).slice(-5);
    const newData = {
      id: count,
      screen_display_order: "",
      exam_group: "",
      group_name: "",
      test_set: "",
      test_set_name: ""
    };

    let data = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    data.length > 0 ? data.unshift(newData) : data.push(newData);
    this.formRef.current?.setFieldsValue({
      dataSourceSubSite: data
    });
    this.forceUpdate();
  };

  SaveSourceSubSite(record) {
    let arr = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    for (let indx = 0; indx < arr.length; indx++) {
      if (arr[indx].id === record.id) {
        // if (isNaN(record.id)) {
        let obj = {
          ...arr[indx],
          course_code: record.courseCode,
        };
        saveAndUpdateCourseSubInputAction(obj)
          .then(res => {
            let data = this.formRef.current?.getFieldValue("dataSourceSubSite")
            console.log(res.data)
            data[0] = res.data
            this.formRef.current?.setFieldsValue({
              dataSourceSubSite: data
            });

            message.info('登録しました。')
            this.forceUpdate();

          })
          .catch(error => {
            const res = error.response;
            if (!res || res.data || res.data.message) {
              message.error('エラーが発生しました')
            }
          })
        // } else {
        //   this._SaveDataSourceSubSite(arr[indx])
        //   return
        // }
      }
    }
  }

  _SaveDataSourceSubSite(record) {
    this.setState({ isLoadingTableSubSite: true })
    // const payload = {
    //   ...record,
    //   test_set_code: this.state.rowSelectMain.test_set_code
    // }
    VenusApiRoutesV2.callApi("API001174003018", { mainSource: [record] }).then(res => {
      this.forceUpdate();
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    }).finally(() => this.setState({ isLoadingTableSubSite: false }))
  }

  DeleteSourceSubSite(record) {
    let arr = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];

    arr.map((value, index) => {
      if (value.id === record.id) {
        arr.splice(index, 1)
        this.formRef.current?.setFieldsValue({
          dataSourceSubSite: arr
        })
        this.forceUpdate()
      }
    })
    if (!isNaN(record.id)) {
      this.setState({
        isLoadingTableSubSite: true
      })
      VenusApiRoutesV2.callApi("API001174003019", { mainSource: [{ id: record.id }] }).finally(() => this.setState({ isLoadingTableSubSite: false }))
    }
  }

  render() {
    return (
      <div className="course-select">
        <Card title="コース選択">
          {this.renderMenuBar()}
          <hr></hr>
          <Row>
            <Col span={5} style={styleCol}>
              <Table
                bordered
                size="small"
                scroll={{ y: "85vh" }}
                dataSource={this.state.dataSourceMain}
                loading={this.state.isLoadingTableMain}
                pagination={{
                  defaultCurrent: 1,
                  pageSize: this.state.mainPageSize,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => {
                    this.setState({
                      mainPageSize: pageSize
                    });
                  },
                  onShowSizeChange: (page, pageSize) => { }
                }}
                onRow={(record, index) => ({
                  onClick: event => {
                    this.getCourseSubInput(record.course_code);
                    this.setState({
                      rowSelectMain: record,
                      selectCourseFlag: true
                    });
                  }
                })}
                rowKey={record => record.id}
              >
                <Table.Column
                  title="基本コース"
                  dataIndex="course_code"
                  sorter={(a, b) => a.course_code.localeCompare(b.course_code)}
                  showSorterTooltip={false}
                  render={(value, record, index) => {
                    return (
                      <div>
                        <Row gutter={5}>
                          <Col span={6} style={{ textAlign: "center" }}>
                            <div
                              style={{ textAlign: "right" }}
                              onDoubleClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: "60%",
                                    component: (
                                      <WS1174006_CourseCopyingFunction
                                        onFinishScreen={output => {
                                          this.closeModal();
                                        }}
                                        Li_course_code={record.course_code}
                                        Li_course_name_short_name={record.course_name_short_nam}
                                        Li_course_name_formal={record.course_name_formal}
                                      />
                                    )
                                  }
                                });
                              }}
                            >
                              {record.course_code}
                            </div>
                          </Col>
                          <Col span={16} style={{ textAlign: "center" }}>
                            <Input
                              value={record.course_name_formal}
                              // style={{border: this.state.indexTable !== index ? 'none' : '', background: this.state.indexTable !== index ? 'transparent' : '',}}
                              onClick={() => {
                                this.setState({ courseCode: record.course_code })
                                // this.updateDatasource(this.findIndexByID(record.id), 'course_name_short_name', e.target.value)
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                />
              </Table>
            </Col>
            <Col span={11} style={styleCol}>
              <Form ref={this.formRef}>
                <Table
                  bordered
                  size="small"
                  scroll={{ y: "85vh" }}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: this.state.subSitePageSize,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                      this.setState({
                        subSitePageSize: pageSize
                      });
                    },
                    onShowSizeChange: (page, pageSize) => { }
                  }}
                  dataSource={
                    this.formRef.current?.getFieldValue("dataSourceSubSite")
                      ? this.formRef.current?.getFieldValue("dataSourceSubSite")
                      : []
                  }
                  loading={this.state.isLoadingTableSubSite}
                  rowKey={record => record.id}
                  onRow={(record, index) => ({
                    onClick: event => {
                      this.getSetSubprocess(record.test_set);
                      this.setState({
                        rowSelectSubSite: record
                      });
                    }
                  })}
                >
                  {/*  */}
                  <Table.Column
                    title="SEQ"
                    dataIndex="screen_display_order"
                    sorter={(a, b) => a.screen_display_order - b.screen_display_order}
                    showSorterTooltip={false}
                    width={60}
                    align={"center"}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["dataSourceSubSite", index, "screen_display_order"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="グループ"
                    dataIndex="exam_group"
                    sorter={(a, b) => a.exam_group - b.exam_group}
                    showSorterTooltip={false}
                    render={(value, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <div>
                          <Row gutter={5}>
                            <Col span={8} style={{ textAlign: "center" }}>
                              <Form.Item
                                name={["dataSourceSubSite", index, "exam_group"]}
                                style={{ marginBottom: "0px" }}
                              >
                                <Input
                                  style={{ textAlign: "right", cursor: 'pointer' }}
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: "60%",
                                        component: (
                                          <WS0267001_CategorySearchQuerySingle
                                            onFinishScreen={output => {
                                              let val = {
                                                exam_group: output.Lio_CategoryCode,
                                                group_name: output.Lio_CategoryName
                                              };
                                              this.closeModal();
                                              this.updateDatasource(index, val, 0);

                                            }}
                                          />
                                        )
                                      }
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={16} style={{ textAlign: "center" }}>
                              <Form.Item
                                name={["dataSourceSubSite", index, "group_name"]}
                                style={{ marginBottom: "0px" }}
                              >
                                <Input
                                  value={record.group_name}
                                // style={{border: this.state.indexTable !== index ? 'none' : '', background: this.state.indexTable !== index ? 'transparent' : '',}}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      );
                    }}
                  />

                  <Table.Column
                    title="セットコード"
                    dataIndex="test_set"
                    sorter={(a, b) => a.test_set.localeCompare(b.test_set)}
                    showSorterTooltip={false}
                    render={(value, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <div>
                          <Row gutter={5}>
                            <Col span={8} style={{ textAlign: "center" }}>
                              <Form.Item
                                name={["dataSourceSubSite", index, "test_set"]}
                                style={{ marginBottom: "0px" }}
                              >
                                <Input
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: "60%",
                                        component: (
                                          <WS1176001_InspectSetInfoSubInspectSetInfo
                                            onFinishScreen={output => {
                                              let val = {
                                                test_set: output.test_set,
                                                test_set_name: output.test_set_name
                                              };

                                              this.closeModal();

                                              this.updateDatasource(index, val, 0);
                                            }}
                                          />
                                        )
                                      }
                                    });
                                  }}
                                  style={{ textAlign: "right", cursor: "pointer" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={16} style={{ textAlign: "center" }}>
                              <Form.Item
                                name={["dataSourceSubSite", index, "test_set_name"]}
                                style={{ marginBottom: "0px" }}
                              >
                                <Input
                                  value={record.test_set_name}
                                  // style={{border: this.state.indexTable !== index ? 'none' : '', background: this.state.indexTable !== index ? 'transparent' : '',}}
                                  onChange={e => {
                                    // this.updateDatasource(this.findIndexByID(record.id), 'course_name_short_name', e.target.value)
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      );
                    }}
                  />
                  <Table.Column
                    width={70}
                    align="center"
                    title={
                      <Button
                        hidden={this.state.selectCourseFlag ? false : true}
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={this.addRowTableMain}
                      ></Button>
                    }
                    render={(text, record, index) => {
                      return (
                        <div hidden={record.id === this.state.rowSelectSubSite.id ? false : true}>
                          <Space size={1}>
                            <Button
                              size="small"
                              style={{ color: "green", marginRight: "5px" }}
                              icon={<SaveOutlined />}
                              onClick={() => {
                                record = {
                                  ...record,
                                  courseCode: this.state.courseCode
                                }
                                this.SaveSourceSubSite(record)
                              }}
                            />
                            <Button
                              size="small"
                              style={{ color: "red" }}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  content: "消去してもよろしいですか？",
                                  okText: "は　い",
                                  cancelText: "いいえ",
                                  onOk: () => this.DeleteSourceSubSite(record)
                                });
                              }}
                            />
                          </Space>
                        </div>
                      );
                    }}
                  />
                </Table>
              </Form>
            </Col>
            <Col span={8} style={styleCol}>
              <Table
                bordered
                size="small"
                pagination={{
                  defaultCurrent: 1,
                  pageSize: this.state.subFindingPageSize,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => {
                    this.setState({
                      subFindingPageSize: pageSize
                    });
                  },
                  onShowSizeChange: (page, pageSize) => { }
                }}
                scroll={{ y: "85vh" }}
                dataSource={this.state.dataSourceSubFinding}
                loading={this.state.isLoadingTableSubFinding}
                rowKey={record => record.id}
                onRow={(record, index) => ({
                  onClick: event => this.setState({ rowSelectSubFinding: record })
                })}
              >
                <Table.Column
                  title="SEQ"
                  dataIndex="screen_display_order"
                  sorter={(a, b) => a.screen_display_order - b.screen_display_order}
                  showSorterTooltip={false}
                  width={60}
                  align={"center"}
                />
                <Table.Column
                  title="コード"
                  dataIndex="exam_item"
                  sorter={(a, b) => a.exam_item - b.exam_item}
                  showSorterTooltip={false}
                  width={80}
                  render={(text, record, index) => (
                    <InputNumber
                      value={text === 0 ? null : text}
                      maxLength={30}
                      bordered={record.id === this.state.rowSelectSubSite.id}
                      onDoubleClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: "60%",
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                InspectCode={record.exam_item}
                                onFinishScreen={output => {
                                  let val = {
                                    exam_item: output.recordData.test_item_code,
                                    exam_name: output.recordData.exam_name,
                                    exam_type: output.recordData.exam_type
                                  };

                                  this.updateDatasource(index, val, 1);
                                  // this.updateDatasource(index, 'group_name',output.recordData.category_name,0)

                                  this.closeModal();
                                }}
                              />
                            )
                          }
                        });
                      }}

                    // onChange={(e) => this.onChangeInput({ search_short_name: e.target.value }, 'dataSourceSubSite', record)}
                    />
                  )}
                />
                <Table.Column
                  title="検査名称"
                  dataIndex="exam_name"
                  sorter={(a, b) => a.exam_name.localeCompare(b.exam_name, "jp")}
                  showSorterTooltip={false}
                />
                <Table.Column
                  title="タイプ"
                  dataIndex="exam_type"
                  sorter={(a, b) => a.exam_type.localeCompare(b.exam_type)}
                  showSorterTooltip={false}
                  width={50}
                />
              </Table>
            </Col>
          </Row>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1174003_CourseSelect);
