import React from "react";
import { connect } from "react-redux";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import WS0268001_SiteClassifySearchQuery from "./WS0268001_SiteClassifySearchQuery";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Col, Modal, message } from "antd";
import { registerDataAction, deleteDataAction } from "redux/InspectionMaintenance/CategoryMasterMaintain/CategoryMasterMaintain.action"
import { ModalError } from "components/Commons/ModalConfirm";

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS2704011_CategoryMasterMaintainSub extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_CategoryCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = 'カテゴリーマスタ SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        stsUse: false,
        site_classification_name: ''
      },
      site_classification: 0
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)

    if (this.props.Li_CategoryCode != 0) {
      this.setState({
        site_classification_name: this.props.record.site_classification_name ?? '',
        stsUse: this.props.record.stsUse
      })
    }
  }

  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let getId = this.props.record ? this.props.record.id : null

    let params = {
      ...values,
      changeData: values,
      id: getId,
    };
    this.RegisterBtn(params);
  }

  RegisterBtn(params) {
    this.setState({ isLoading: true })
    registerDataAction(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しましたaaaaa"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    deleteDataAction({
      id: this.props.record.id
    })
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="登録・修正">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Col>
              <Form.Item
                name="category_code"
                label="カテゴリコード"
                {...smGrid}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}>
                <Input
                  maxLength={5}
                  disabled={!(this.props.Li_CategoryCode === 0)}
                  style={{ width: 70, textAlign: 'right' }}
                />
              </Form.Item>
            </Col>

            <Form.Item
              name="category_name"
              label="カテゴリー名称"
              {...smGrid}
            >
              <Input
                type="text"
                maxLength={30}
                style={{ width: 300 }}
              />
            </Form.Item>


            <div className="box_inner_horizontal">
              <Form.Item
                name="site_classification"
                label="部位分類"
                {...smGrid}
              >
                <Input.Search
                  allowClear
                  className="input-search-size-number-allow-clear-5"
                  type="text"
                  maxLength={5}
                  cleartriggersearch="true"
                  onSearch={(value, event) => {
                    if (event.target.localName !== "input") {
                      this.formRef.current.setFieldsValue({ site_classification_name: '' })
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <WS0268001_SiteClassifySearchQuery
                              Lo_SiteClassifyCode={this.state.site_classification}
                              onFinishScreen={output => {
                                this.formRef.current?.setFieldsValue({
                                  site_classification: output.site_classification_code,
                                });
                                this.setState({
                                  site_classification: output.site_classification_code,
                                  site_classification_name: output.site_classification_name ?? ''
                                });
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }
                  }}

                  onChange={(event) => {
                    if (event.target.value === '') {
                      this.setState({
                        site_classification_name: ''
                      })
                    }
                  }}
                />
              </Form.Item>

              <div style={{ marginTop: 5 }}>
                {this.state.site_classification_name}
              </div>
            </div>

            <Form.Item
              name="common_category_code"
              label="地域老人保健分類"
              {...smGrid}
            >
              <Input
                type="text"
                maxLength={5}
                style={{ width: 70, textAlign: 'right' }}
              />
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                disabled={this.state.stsUse}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.deleteData()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => { this.onFinish(); }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2704011_CategoryMasterMaintainSub);