import { message } from 'antd'
import TargetSelectSubService from 'services/AdvancePreparation/ConsultInfoList/TargetSelectSubService'

const TargetSelectSubAction = {
  index(params) {
    return TargetSelectSubService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  excelReport(visitData) {
    console.log("actions")
    console.log(visitData)
    return TargetSelectSubService.excelReport(visitData)
      .then((res) => {
        console.log("アクションだよ")
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          console.log("エラー")
          return
        }
        message.error(res.data.message)
      })
  },

  changeLogicAction(params) {
    return TargetSelectSubService.changeLogicService(params)
  },

  changeLogicAllAction(params) {
    return TargetSelectSubService.changeLogicAllService(params)
  }
}
export default TargetSelectSubAction
