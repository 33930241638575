import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Table, Form, Input, Select, Menu, Dropdown, Popconfirm, Button, message } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import InspectMaintainAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/InspectMaintain.actions'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import InspectValueConvertSubService from "services/CooperationRelated/InspectItemConvertInternal/InspectValueConvertSubService";

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS2751100_InspectMaintainEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '検査保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isloadding: false,
      count: "a"
    };
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      this.formRef.current?.setFieldsValue(this.props.record)
    }
  }

  saveData() {
    let getFieldValue = this.formRef.current?.getFieldValue()
    let params = {
      id: this.props?.record?.id ? this.props.record.id : null,
      kind: getFieldValue.kind,
      test_item_code: getFieldValue.test_item_code,
      exam_name: getFieldValue.exam_name,
      remarks: getFieldValue.remarks,
    }
    InspectMaintainAction.SaveData(params).then(res => {
      this.props.onFinishScreen()
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
    })
  }
  delete() {
    //delete DB  
    let params = {
      id: this.props.record.id
    }
    InspectMaintainAction.Delete(params).then(res => {
      this.props.onFinishScreen()
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
    })
  }


  render() {
    return (
      <div className="mirais-reserve-maintain">
        <Card title={this.props.newFlag ? "検査保守追加" : "検査保守編集"}>
          <Form ref={this.formRef} autoComplete="off" >
            <div className="box_inner_vertical">
              <div className="box_inner_horizontal">
                <Form.Item label='種別' name='kind' {...smGrid}>
                  <Select style={{ width: '120px' }}>
                    <Select.Option value={"00"}>予約</Select.Option>
                    <Select.Option value={"60"}>検査</Select.Option>
                    <Select.Option value={"70"}>画像</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label='ｺｰﾄﾞ' name='test_item_code' {...smGrid}>
                  <Input className="input-size-40" />
                </Form.Item>
              </div>
              <Form.Item label='名称' name='exam_name' {...smGrid}>
                <Input className="input-size-60" />
              </Form.Item>
              <Form.Item label='備考' name='remarks' {...smGrid}>
                <Input className="input-size-60" />
              </Form.Item>
            </div>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() =>
                  this.saveData()
                }
              >保存</Button>
              {!this.props.newFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.delete()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                  >
                    削除
                  </Button>
                </Popconfirm>
                : null
              }
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2751100_InspectMaintainEdit);