import React from "react";
import { Card, Form, Button, Input, Select } from "antd";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

const { TextArea } = Input

class WS0963002_RemarkChangeModal extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '請求書　備考編集';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      kanren_num: this.props.kanren_num ?? '',
      kan_sect: this.props.kan_sect ?? '',
      content: this.props.content ?? '',
      remarks: this.props.remarks ?? '',
    })
  }

  render() {
    return (
      <div className="select-breakdown">
        <Card title="請求書[編集]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className="box_inner_horizontal">
              <div
                className="box_search_inner_border"
                style={{ width: '45%', gap: '10px' }}
              >
                <Form.Item
                  name='kanren_num'
                  label='連番'
                >
                  <Input
                    ref={(input) => { this.name_sequential_num = input; }}
                    className="input-size-number-4"
                    type='text'
                    style={{ textAlign: 'right' }}
                    onBlur={(e) => {
                      if (e.target.value < 10) {
                        Modal.error({
                          width: 300,
                          title: '使用できない番号です',
                          onOk: () => {
                            this.name_sequential_num.focus();
                          }
                        })
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="kan_sect"
                  label="区分"
                >
                  <Select
                    onChange={(value) => {
                      this.setState({ SpecifyClassify: value })
                      this.forceUpdate()
                    }}
                    style={{ width: '150px' }}
                  >
                    <Select.Option value={1}>セット</Select.Option>
                    <Select.Option value={3}>コース</Select.Option>
                    <Select.Option value={5}>コメント</Select.Option>
                    <Select.Option value={7}>明細無効</Select.Option>
                    <Select.Option value={9}>無効</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="content"
                  label="名称"
                >
                  <Input
                    type="text"
                    style={{ width: '90%' }}
                  />
                </Form.Item>
              </div>

              <div
                className="box_inner_vertical"
                style={{ width: '55%' }}
              >
                <Form.Item
                  name="remarks"
                  label="備考"
                >
                  <TextArea
                    type="text"
                    rows={4}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="box_button_bottom_right">
              <Button
                type='primary'
                onClick={() => {
                  this.props.onFinishScreen({
                    kanren_num: this.formRef.current?.getFieldValue('kanren_num'),
                    kan_sect: this.formRef.current?.getFieldValue('kan_sect'),
                    content: this.formRef.current?.getFieldValue('content'),
                    remarks: this.formRef.current?.getFieldValue('remarks'),
                  })
                }}
              >
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0963002_RemarkChangeModal);
