import React from "react";
import { connect } from "react-redux";
import ComprehensiveMenuMaintenanceSubAction from 'redux/MN_Basis/ComprehensiveMenuMaintenance/ComprehensiveMenuMaintenanceSub.action';
import { getMenuList } from "redux/user/user.actions";
import { SaveOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Form, Table, Button, Input, message, Row, Col, Checkbox, Tooltip, Modal, Dropdown, Menu } from "antd"
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'

const authorityList = [
  { name: 'UsageRightsA' },
  { name: 'UseAuthorityB' },
  { name: 'UseAuthorityC' },
  { name: 'UsageRightsD' },
  { name: 'UsageRightsE' },
  { name: 'UseAuthorityF' },
  { name: 'UsageRightsG' },
  { name: 'UsageRightsH' },
  { name: 'UseAuthorityI' },
]
class WS2690501_ComprehensiveMenuMaintenanceSub extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      businessName: [],
      managementList: [],
      selectedBusinessCode: '',
      selectedId: '',
      isLoadingTable: false,
      isLoadingPage: false,
      changeData: [[], []],
      selectedRowIndex: 0,
      newRowCount: 0,
      doubleCheck: false,
      changeBusinessCode: '',
      businessNameList: this.props.businessNameList,
    };
    this.createLineTable = this.createLineTable.bind(this);
  }
  componentDidMount() {
    this.getScreenData();
  }
  getScreenData() {
    this.setState({ isLoadingTable: true })
    let param = {
      Li_BusinessCode: (this.state.changeBusinessCode === '') ? this.props.selectedBusinessCode : this.state.changeBusinessCode
    }
    ComprehensiveMenuMaintenanceSubAction.getScreenData(param)
      .then((res) => {
        if (this.props.selectedBusinessCode === undefined) {
          this.setState({
            businessName: [{ StsDisabled: null, business_code: null, business_name: null, id: '' }],
            changeBusinessCode: ''
          })
        } else {
          this.formRef.current?.setFieldsValue({
            dataTable: res[0] ? res[0] : [],
          })
          this.setState({
            businessName: res[0] ? res[0] : [],
            changeBusinessCode: '',
          })
        }
        let resManagement = res[1]
        if (resManagement.length === 0) {
          this.setState({
            managementList: [],
            selectedId: 0,
            selectedRowIndex: 0,
            changeData: [[], []],
          })
          return
        } else {
          let initObj = resManagement[0]
          this.formRef.current?.setFieldsValue({
            tableData: resManagement ? resManagement : [],
            UsageRightsA: initObj.UsageRightsA ?? false,
            UseAuthorityB: initObj.UseAuthorityB ?? false,
            UseAuthorityC: initObj.UseAuthorityC ?? false,
            UsageRightsD: initObj.UsageRightsD ?? false,
            UsageRightsE: initObj.UsageRightsE ?? false,
            UseAuthorityF: initObj.UseAuthorityF ?? false,
            UsageRightsG: initObj.UsageRightsG ?? false,
            UsageRightsH: initObj.UsageRightsH ?? false,
            UseAuthorityI: initObj.UseAuthorityI ?? false,
            Option: initObj.Option ?? '',
            published_name: initObj.published_name ?? '',
            icon: initObj.icon ?? ''
          })
          this.setState({
            managementList: resManagement ? resManagement : [],
            selectedId: initObj.id,
            selectedRowIndex: 0,
            changeData: [[], []],
          })
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingTable: false }))
  }

  selectIndex(id) {
    if (id === "") return
    let data = this.state.managementList;
    let obj;
    for (let i = 0; i < data.length; i++) {
      if (id === data[i].id) {
        obj = data[i];
        break;
      }
    }
    this.formRef.current?.setFieldsValue({
      UsageRightsA: obj.UsageRightsA ? true : false,
      UseAuthorityB: obj.UseAuthorityB ? true : false,
      UseAuthorityC: obj.UseAuthorityC ? true : false,
      UsageRightsD: obj.UsageRightsD ? true : false,
      UsageRightsE: obj.UsageRightsE ? true : false,
      UseAuthorityF: obj.UseAuthorityF ? true : false,
      UsageRightsG: obj.UsageRightsG ? true : false,
      UsageRightsH: obj.UsageRightsH ? true : false,
      UseAuthorityI: obj.UseAuthorityI ? true : false,
      Option: obj.Option ?? '',
      published_name: obj.published_name ?? '',
      icon: obj.icon ?? ''
    })
  }

  addChangeData(event, name, inputFlag, businessNameFlag, record) {
    let data = this.state.managementList;
    let businessData = this.state.businessName;
    let obj;
    let businessObj;
    //mapで1行分のデータ取得
    if (record !== null) {
      for (let i = 0; i < data.length; i++) {
        if (record.id === data[i].id) {
          obj = data[i];
          break;
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        if (this.state.selectedId === data[i].id) {
          obj = data[i];
          break;
        }
      }
    }
    businessObj = businessData[0];
    let businessCode = this.state.changeBusinessCode;

    // if (obj === undefined) {
    //     return
    // }
    // if (businessObj === undefined) {
    //     return
    // }
    //取ってきたイベントを１行分のデータに反映する
    let array = this.state.changeData;
    if (businessNameFlag) {
      if (inputFlag) {
        businessObj[name] = event.target.value;
        if (name === 'business_code') {
          businessCode = event.target.value
        }
      } else {
        businessObj[name] = event.target.checked ? 1 : 0;
      }
      array[0] = [];
      array[0].push(businessObj);
    } else {
      if (inputFlag) {
        if (name === 'DisplaySerialNum') {
          obj[name] = Number(event.target.value);
        } else {
          obj[name] = event.target.value;
        }
      } else {
        obj[name] = event.target.checked ? 1 : 0;
      }

      // let changeBool = array.some((u) => u.DisplaySerialNum === obj.DisplaySerialNum)
      let changeBool = false;
      let keyIndex;
      let managementArray = array[1];
      let changeData = this.state.changeData
      for (let j = 0; j < managementArray.length; j++) {
        if (managementArray[j].id === obj.id) {
          changeBool = true;
          keyIndex = j;
          break;
        }
      }
      //仮のデータにpushする
      if (changeBool && array[1]) {
        array[1].splice(keyIndex, 1, obj);
      } else {
        array[1].push(obj);
      }
    }
    this.forceUpdate();
    this.setState({ changeData: array, changeBusinessCode: businessCode })
    if (this.checkDuplicateCode()) {
      message.error('連番が重複しています');
    }
    if (this.checkBusinessCode(businessCode)) {
      message.error('業務コードが重複しています');
    }
  }


  onSaveData(changeData) {
    let param = {
      Li_changeData: changeData,
      Li_BusinessCode: this.props.selectedBusinessCode,
      Li_changeBusinessCode: (this.state.changeBusinessCode === this.props.selectedBusinessCode) ? '' : this.state.changeBusinessCode
    }
    ComprehensiveMenuMaintenanceSubAction.onSaveData(param)
      .then((res) => {
        if (this.props.selectedBusinessCode === undefined) {
          this.props.getScreenData(this.state.changeBusinessCode)
          this.props.onFinishScreen()
          this.setState({
            changeData: [[], []],
          })
        } else {
          // this.getScreenData()
          this.props.onFinishScreen()
          this.props.getScreenData((this.state.changeBusinessCode === '' || this.state.changeBusinessCode === this.props.selectedBusinessCode) ? this.props.selectedBusinessCode : this.state.changeBusinessCode)
          this.setState({
            changeData: [[], []],
          })
        }
        this.props.getMenuList();
        message.success('更新しました。');
      }).catch((err) => {
        const res = err.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  deleteData(id) {
    let param = {
      Li_selectedId: id
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        ComprehensiveMenuMaintenanceSubAction.deleteData(param)
          .then((res) => {
            this.getScreenData()
            this.setState({
              changeData: [[], []]
            })
            this.props.getMenuList();
            message.success('正常に削除されました');
          }).catch((err) => {
            message.error(err.response.data.message);
          })
      }
    });
  }

  checkAddItem() {
    if (this.state.managementList.length > 0 || this.state.businessName.length > 0) {
      let index = -1
      if (this.state.managementList.length > 0) {
        index = this.state.managementList.findIndex(x => !x.DisplaySerialNum);
      } else {
        index = this.state.businessName.findIndex(x => !x.business_code);
      }
      if (index === -1) {
        return false;
      }
      return true
    }
  }

  checkDuplicateCode() {
    let lstData = [...this.state.managementList];

    const uniqueValues = new Set(lstData.map(v => v.DisplaySerialNum));
    const uniqueIds = new Set(lstData.map(v => v.id));


    if (uniqueIds.size < lstData.length || uniqueValues.size < lstData.length) {
      // this.setState({
      //     doubleCheck: duplicate
      // })
      // message.error('連番が重複しています');
      return true;
    }
    return false;
  }

  checkBusinessCode(businessCode = '') {
    let lstData = [...this.props.businessNameList];
    // businessCodeの判定
    let strCode = (businessCode === '') ? this.state.changeBusinessCode : businessCode;
    // ０埋めをする
    let code = strCode.toString().padStart(10, '0');

    for (let i = 0; i < lstData.length; i++) {
      if (code === lstData[i].business_code) {
        return true;
      }
    }

    // if (businessCord < lstData.length) {
    // this.setState({
    //     doubleCheck: duplicate
    // })
    //     return true;
    // }
    return false;
  }

  checkNumber() {
    let lstData = [...this.state.changeBusinessCode];
    let result = '';
    for (let k = 0; k < lstData.length; k++) {
      // 入力して値が数値化を判定する
      result = lstData[k].match(/[0-9]+/g);
      if (result === null) {
        return true;
      }
    }
    return false
  }

  async createLineTable() {
    let count = this.state.newRowCount + 1;
    let newRow = {
      id: 'newRow' + count
    };


    let management = [...this.state.managementList]

    let setIndex = 0;

    if (management.length > 0) {
      setIndex = this.state.selectedRowIndex + 1;
    }


    management.splice(setIndex, 0, newRow);

    this.formRef.current?.setFieldsValue({
      tableData: management,
      UsageRightsA: false,
      UseAuthorityB: false,
      UseAuthorityC: false,
      UsageRightsD: false,
      UsageRightsE: false,
      UseAuthorityF: false,
      UsageRightsG: false,
      UsageRightsH: false,
      UseAuthorityI: false,
      Option: '',
      published_name: '',
      icon: ''
    })

    await this.setState({
      managementList: management,
      newRowCount: count
    });

    let dataCopy = [...management]

    this.setState({
      managementList: dataCopy
    })

    this.formRef.current?.setFieldsValue({
      tableData: dataCopy
    })


    // 新しい行を選択状態にする
    this.setState({
      selectedId: newRow.id,
      selectedRowIndex: setIndex
    })
    this.forceUpdate();
  }

  deleteCreateLine(index) {
    let management = [...this.state.managementList];
    let changedata = [...this.state.changeData];
    let changedata1 = [...this.state.changeData[1]];
    let deleteId = management[index].id;
    let deleteIndex = null;
    // 追加した行を取るためのfor文
    for (let j = 0; j < changedata1.length; j++) {
      if (deleteId === changedata1[j].id) {
        deleteIndex = j;
        break;
      }
    }
    if (changedata1.length > 0) {
      changedata1.splice(deleteIndex, 1);
    }
    changedata[1] = changedata1;
    management.splice(index, 1);
    // this.formRef.current.getFieldValue("tableData").splice(index, 1);

    this.setState({
      managementList: management
    })

    let dataCopy = [...management]


    if (dataCopy.length > 0) {
      this.setState({
        managementList: dataCopy,
        selectedRowIndex: 0,
        selectedId: dataCopy[0].id,
        changeData: changedata
      })
      this.formRef.current?.setFieldsValue({
        tableData: dataCopy,
      });
      this.selectIndex(dataCopy[0].id);
    }
  }

  render() {
    return (
      <div className='comprehensive-menu-maintenance' >
        <Card title={
          <div>
            <div style={{ float: "left" }}>{this.props.newFlag ? '新規追加' : '編集'}</div>
            <div style={{ float: "right" }}></div>
          </div>
        }>
          <Form ref={this.formRef} autoComplete="off" onFinish={this.onFinish} style={{ padding: '5px' }}>
            <div className="box_inner_vertical">
              <Table bordered
                style={{ cursor: 'pointer' }}
                id='table1'
                rowKey={(record) => record.id}
                size="small"
                rowClassName={(record, index) => record.id === this.state.selectedBusinessCode ? 'table-row-light' : ''}
                dataSource={this.state.businessName}

                pagination={false}
              >
                <Table.Column
                  title="無効"
                  width={50}
                  dataIndex="StsDisabled"
                  align='center'
                  style={{ border: 'none' }}
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["dataTable", index, 'StsDisabled']} valuePropName="checked">
                        <Checkbox
                          onChange={(event) => { this.addChangeData(event, 'StsDisabled', false, true, record) }}
                        />
                      </Form.Item>
                    )
                  }}
                />
                <Table.Column
                  title="業務コード"
                  width={150}
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["dataTable", index, "business_code"]}
                        rules={[
                          {
                            required: true,
                            pattern: (record.business_code) ? /^[\d]+$/ : '',
                            message: "数字で入力してください"
                          },
                        ]}
                      >
                        <Input
                          name="business_code"
                          style={{ textAlign: 'right' }}
                          onChange={(event) => { this.addChangeData(event, "business_code", true, true, record) }}
                        />
                      </Form.Item>
                    );
                  }}
                />
                <Table.Column
                  title="業務名称"
                  // width={350}
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["dataTable", index, "business_name"]}>
                        <Input
                          name="business_name"
                          onChange={(event) => { this.addChangeData(event, "business_name", true, true, record) }}
                        />
                      </Form.Item>
                    );
                  }}
                />

              </Table>
              <Table bordered
                style={{ cursor: 'pointer', display: this.props.newFlag ? 'none' : '' }}
                id='table2'
                size="small"
                rowKey={(record) => record.id}
                rowClassName={(record, index) => record.id === this.state.selectedId ? 'table-row-light' : ''}
                dataSource={this.state.managementList}
                // loading={this.state.isLoadingTable}

                pagination={false}
                // className="name"
                scroll={{ y: 250 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      let id = record.id
                      if (record.id === "") {
                        return
                      } else {
                        this.selectIndex(id)
                        this.setState({
                          selectedId: record.id,
                          selectedRowIndex: rowIndex
                        })
                      }
                    }
                  }
                }}
              >
                <Table.Column
                  title="無効"
                  dataIndex="invalid"
                  width={50}
                  align='center'
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["tableData", index, 'invalid']} valuePropName="checked" >
                        <Checkbox
                          onChange={(event) => { this.addChangeData(event, 'invalid', false, false, record) }}
                        />
                      </Form.Item>
                    )
                  }}
                />
                <Table.Column
                  title="連番"
                  dataIndex="DisplaySerialNum"
                  width={120}
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["tableData", index, "DisplaySerialNum"]}
                        rules={[
                          {
                            required: true,
                            message: "必須項目です"
                          },
                        ]}
                      >
                        <Input
                          name="DisplaySerialNum"
                          style={{ textAlign: 'right' }}
                          onKeyDown={(e) => moveFocusKeyEvent(e, 'tableData', 3, true, 2)}
                          onChange={(event) => { this.addChangeData(event, "DisplaySerialNum", true, false, record) }}
                        />
                      </Form.Item>
                    );
                  }}
                ></Table.Column>
                <Table.Column
                  title="名称"
                  dataIndex="name"
                  width={200}
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["tableData", index, "name"]}>
                        <Input
                          name="name"
                          onKeyDown={(e) => moveFocusKeyEvent(e, 'tableData', 3, true, 2)}
                          onChange={(event) => { this.addChangeData(event, "name", true, false, record) }}
                        />
                      </Form.Item>
                    );
                  }}
                />
                <Table.Column
                  title="コマンド"
                  dataIndex="Exe"
                  // width={280}
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["tableData", index, "Exe"]}>
                        <Input
                          name="Exe"
                          onKeyDown={(e) => moveFocusKeyEvent(e, 'tableData', 3, true, 2)}
                          onChange={(event) => { this.addChangeData(event, "Exe", true, false, record) }}
                        />
                      </Form.Item>
                    );
                  }}
                />
                <Table.Column title="権限" dataIndex="usage_rights" width={60} />
                {/* 追加ボタン */}
                <Table.Column
                  width={40}
                  align='center'
                  title={
                    <Tooltip placement="topLeft" title={'追加'}>
                      <Button
                        size="small"
                        disabled={this.checkAddItem() || this.checkDuplicateCode()}
                        onClick={() => { this.createLineTable() }}
                        type="primary" icon={<PlusOutlined />}>
                      </Button>
                    </Tooltip>
                  }
                  // 削除ボタン
                  render={(row, record, index) => {
                    return (
                      <Dropdown
                        trigger="click"
                        size="small"
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key="2"
                              onClick={() => {
                                (String(record.id).indexOf('newRow') > -1) ? this.deleteCreateLine(index) : this.deleteData(record.id)
                              }}
                            >
                              削除
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size="small" icon={<MoreOutlined />}></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
              <div style={{ border: '1px solid #cccccc', padding: '5px', marginTop: '5px' }} >
                <div className="box_inner_horizontal" >
                  <div className="box_inner_vertical">
                    <div style={{ display: "flex", paddingLeft: '37px' }}>
                      {authorityList.map((val, index) => {
                        return (
                          <Form.Item key={val.name + '_sub'} label={(index === 0) ? '権限' : ''}
                            style={{ paddingLeft: '5px' }}>
                            <div className="box_inner_vertical" style={{ gap: 0 }}>
                              <div style={{ margin: 'auto', color: '#777', height: 17 }}>{val.name.slice(-1)}</div>
                              <Form.Item name={val.name} key={val.name + '_check'} valuePropName="checked">
                                <Checkbox onChange={(event) => this.addChangeData(event, val.name, false, false, null)}></Checkbox>
                              </Form.Item>
                            </div>
                          </Form.Item>
                        )
                      })
                      }
                    </div>
                    <Form.Item label="&emsp;アイコン" name="icon">
                      <Input maxLength={30} onChange={(event) => this.addChangeData(event, "icon", true, false, null)} />
                    </Form.Item>
                  </div>
                  <div className="box_inner_vertical">
                    <Form.Item label="&emsp;&emsp;&emsp;公開名" name="published_name">
                      <Input maxLength={220} onChange={(event) => { this.addChangeData(event, "published_name", true, false, null) }} />
                    </Form.Item>
                    <Form.Item label="&emsp;オプション" name="Option">
                      <Input maxLength={50} onChange={(event) => this.addChangeData(event, "Option", true, false, null)} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="box_button_bottom_right">
                {/* 保存ボタン */}
                <Button
                  // style={{ height: '40px', width: '150px', fontSize: '16px', }}
                  type="primary"
                  // width={500}
                  icon={<SaveOutlined />}
                  disabled={this.checkAddItem() || this.checkDuplicateCode() || this.checkBusinessCode() || this.checkNumber()}
                  onClick={() => {
                    this.onSaveData(this.state.changeData)
                  }}
                >保存
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <Modal
          footer={null}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        >
          {this.state.childModal.component}
        </Modal>
      </div >
    );
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getMenuList: () => dispatch(getMenuList())
});
export default connect(mapStateToProps, mapDispatchToProps)(WS2690501_ComprehensiveMenuMaintenanceSub);
