import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Button, Input, message, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import WS0344001_SupplementalInfoSetting from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx'
import VisitsSupplementWkAction from 'redux/ReservationBusiness/PersonalReserveProcess/VisitsSupplementWk.actions'
class WS0392005_VisitsSupplementWk extends React.Component {
  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    Lio_Remarks: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = '受診補足WK'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isloading: false,

      dataSource: [],

      StsDirectInput: [],

      disabledSaveBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.setState({
      Lio_Remarks: this.props.Lio_Remarks ?? ''
    })
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    let params = {
      ReserveNum: this.props.Li_ReserveNum ? this.props.Li_ReserveNum : '',
      Lio_Remarks: this.props.Lio_Remarks ? this.props.Lio_Remarks : ''
    }
    VisitsSupplementWkAction.index(params)
      .then(res => {
        let InputCheck = []
        let datas = res ? res.VisitSupplementWK : []
        datas.forEach(x => {
          InputCheck.push(true)
        })

        this.setState({
          dataSource: datas,
          isloading: false,
          StsDirectInput: InputCheck
        })

      })
      .catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
      .finally(() => this.setState({ isloading: false }))
  }

  UpdateContent(value, record, index) {
    // this.setState({ isloading: true })
    let data = {
      ReserveNum: this.props.Li_ReserveNum ? this.props.Li_ReserveNum : '',
      Content: value,
      item_id: record.item_id
    }

    let datas = [...this.state.dataSource]
    datas[index]['Content'] = value

    this.setState({
      dataSource: datas
    })

    VisitsSupplementWkAction.UpdateContent(data)
      .then(res => {
      })
      .catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
    // .finally(() => this.setState({ isloading: false }))
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * 入力変更処理
   * @param {*} value
   * @param {*} record
   * @param {*} index
   */
  onChangeValue = (value, record, index) => {
    let chnageValue = ''
    if (record.condition_1 === 'X') {
      chnageValue = wanakana.toRomaji(value)
    } else if (record.condition_1 === 'K') {
      chnageValue = wanakana.toKatakana(value)
    } else if (record.condition_1 === 'J') {
      chnageValue = wanakana.toHiragana(value)
    }

    let arrTemp = [...this.state.dataSource]
    // 内容を反映
    let obj = {
      ...record,
      content: chnageValue, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj

    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false // 登録ボタン 活性
    })
  }

  /**
   * 設定　モーダル
   */
  showWS0344001_SupplementalInfoSetting() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0344001_SupplementalInfoSetting
            Li_IdentifyChar={'MAST4'}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='visits-supplement-wk'>
        <Card title='予約受付拡張情報'>

          <Form
            ref={this.formRef}
            // onFinish={this.onFinish}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: 450 }}
            >
              <Table.Column
                title='項目'
                dataIndex='item'
                width={250}
              />

              <Table.Column
                title='内容'
                dataIndex='Content'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'content']} >
                    {(record.StsDirectInput && (record.contents ?? false)) ?
                      // 選択項目
                      <Select
                        onChange={(event) => {
                          this.onChangeValue(event, record, index)
                        }}
                      >
                        {record.contents.map((item, index) => (
                          <Select.Option
                            key={item.label + index}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      :
                      // テキスト入力
                      <Input
                        maxLength={record?.condition_2 || 50}
                        onChange={(event) => {
                          this.onChangeValue(event.target.value, record, index)
                        }}
                      />
                    }
                  </Form.Item>
                  // return (
                  //   <div>
                  //     {this.state.StsDirectInput[index] ?
                  //       <Input value={record.Content} style={{ width: '100%' }}
                  //         onChange={(e) => this.UpdateContent(e.target.value, record, index)} >
                  //       </Input>
                  //       :
                  //       <Select value={record.Content} style={{ width: '100%' }}
                  //         onChange={(e) => this.UpdateContent(e, record,)} >
                  //         {record?.ContentCombobox?.map(value => (
                  //           <Select.Option key={'ContentCombobox-' + Math.random()} value={value.LinkedField}>{value.DisplayField}</Select.Option>
                  //         ))}
                  //       </Select>
                  //     }
                  //   </div>
                  // )
                )}
              />
            </Table>

            <Input.TextArea defaultValue={this.state.Lio_Remarks} row={3} maxLength={80} style={{ marginTop: '10px' }}
              onChange={(e) => {
                this.setState({
                  Lio_Remarks: e.target.value
                })
              }} />

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.showWS0344001_SupplementalInfoSetting()
                }}
              >
                設定
              </Button>

              {/* 登録ボタン */}
              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0392005_VisitsSupplementWk)
