import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Descriptions, Button, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import ScreenInputAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ScreenInput.action'
import { number_format } from "helpers/CommonHelpers";

const contentStyle = {
  textAlign: 'right',
  padding: '0px 3px'
}

const formItem = {
  marginBottom: 0,
  width: '100%'
}

const formInput = {
  marginBottom: 0,
  width: '100%'
}

const descriptionsStyle = {
  marginBottom: 0,
  marginTop: 0
}

const labelStyle = {
  width: '110px'
}

const inputRight = {
  textAlign: 'right'
}
class WS1303006_ScreenInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '画面入力';

    this.state = {
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.props.Li_CourseLevel
    }
    ScreenInputAction.getScreenData(params)
      .then(res => {
        this.formRef.current.setFieldsValue(res.data)
        this.totalData()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  totalData() {
    // 合計を再計算
    let unit_price_total_price = number_format(
      (Number(this.formRef.current?.getFieldValue('basic_commissioned_price')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('anemia_chkup_unit_price')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('ecg_chkup_unit_price')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('unit_price_exam_fundus_ken')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('creatinine_chkup_price')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('add_chkup_unit_price')) ?? 0)
    )
    let window_burden_price = number_format(
      (Number(this.formRef.current?.getFieldValue('basic_window_burden_price')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('details_window_burden_price')) ?? 0) +
      (Number(this.formRef.current?.getFieldValue('add_window_burden_price')) ?? 0)
    )
    this.formRef.current.setFieldsValue({ unit_price_total_price: unit_price_total_price, window_burden_price: window_burden_price })
    this.forceUpdate()
  }

  digitCheck(e, name, maxLength) {
    let value = e.target.value.slice(0, maxLength);
    this.formRef.current.setFieldsValue({ [name]: value })
  }

  outsourcingFee(e, name, maxLength) {
    let value = e.target.value.slice(0, maxLength);
    this.formRef.current.setFieldsValue({ [name]: value })
    this.totalData()
  }

  onSave(values) {
    let value = this.formRef.current?.getFieldValue()
    let params = {
      ...value,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_Division: this.props.Li_Division,
      id: this.formRef.current?.getFieldValue('id')
    }
    ScreenInputAction.onSave(params)
      .then(res => {
        this.formRef.current.setFieldsValue(res.data)
        this.getScreenData()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {
    return (
      <div className="screen-Input">
        <Card title="請求情報">
          <Form
            ref={this.formRef}
          // onFinish={(value) => }
          >
            <div className="box_border" style={{ marginBottom: '10px' }}>
              <Form.Item
                name=""
                label="予約管理番号"
              >
                <div>{this.props.Li_ReserveNum}</div>
              </Form.Item>
              <Form.Item
                name="chkup_institution_num"
                label="医療機関番号"
              >
                <Input type="text" className="input-size-number-10" />
              </Form.Item>
            </div>

            <div className="box_inner_horizontal">
              <div className="box_inner_vertical" style={{ gap: 0 }}>
                <div
                  className="table_header_filter"
                  style={{ justifyContent: 'center' }}
                >
                  <Form.Item style={{ margin: "0", width: '50%', textAlign: 'center' }}>
                    <label style={{ color: "#fff" }}> 負担区分</label>
                  </Form.Item>
                  <Form.Item style={{ margin: "0", width: '50%', textAlign: 'center' }}>
                    <label style={{ color: "#fff" }}> 負担内容</label>
                  </Form.Item>
                </div>

                <Descriptions
                  title=''
                  column={1}
                  bordered
                  size='small'
                  labelStyle={labelStyle}
                >
                  <Descriptions.Item
                    label='基本部分'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <Form.Item name='basic_part_burden_sect' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'basic_part_burden_sect', 1)} style={inputRight} />
                      </Form.Item>
                      <div className="descriptions_item_boder" />
                      <Form.Item name='basic_part_burden_content' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'basic_part_burden_content', 6)} style={inputRight} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='詳細部分'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <Form.Item name='xexam_details_burden_sect' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'xexam_details_burden_sect', 1)} style={inputRight} />
                      </Form.Item>
                      <div className="descriptions_item_boder" />
                      <Form.Item name='detail_burden_content' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'detail_burden_content', 6)} style={inputRight} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='追加項目'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <Form.Item name='other_add_item_burden_sect' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'other_add_item_burden_sect', 1)} style={inputRight} />
                      </Form.Item>
                      <div className="descriptions_item_boder" />
                      <Form.Item name='add_item_burden_content' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'add_item_burden_content', 6)} style={inputRight} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='ドック'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <Form.Item name='dock_burden_sect' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'dock_burden_sect', 1)} style={inputRight} />
                      </Form.Item>
                      <div className="descriptions_item_boder" />
                      <Form.Item name='dock_burden_content' style={formItem}>
                        <Input onChange={(e) => this.digitCheck(e, 'dock_burden_content', 6)} style={inputRight} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='保険者上限'
                    contentStyle={contentStyle}>
                    <Form.Item name='insurer_burden_upper_limit' style={formInput}>
                      <Input onChange={(e) => this.digitCheck(e, 'insurer_burden_upper_limit', 6)} style={inputRight} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  title=''
                  column={2}
                  bordered
                  size='small'
                  style={{ marginTop: '10px' }}
                  labelStyle={labelStyle}
                >
                  <Descriptions.Item
                    label='請求区分'
                    contentStyle={contentStyle}>
                    <Form.Item name='billing_sect' style={formInput}>
                      <Input onChange={(e) => this.digitCheck(e, 'billing_sect', 1)} style={inputRight} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='個人集団'
                    contentStyle={contentStyle}>
                    <Form.Item name='person_population_segments' style={formInput}>
                      <Input onChange={(e) => this.digitCheck(e, 'person_population_segments', 1)} style={inputRight} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </div>

              <div className="box_inner_vertical">
                <div className="box_inner_horizontal">
                  <div className="box_inner_vertical" style={{ gap: 0 }}>
                    <div
                      className="table_header_filter"
                      style={{ justifyContent: 'center' }}
                    >
                      <Form.Item style={{ margin: "0" }}>
                        <label style={{ color: "#fff" }}> 委託金額</label>
                      </Form.Item>
                    </div>
                    <Descriptions
                      title=''
                      column={1}
                      bordered
                      size='small'
                      labelStyle={labelStyle}
                    >
                      <Descriptions.Item
                        label='基本'
                        contentStyle={contentStyle}
                      >
                        <Form.Item name='basic_commissioned_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'basic_commissioned_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='貧血'
                        contentStyle={contentStyle}>
                        <Form.Item name='anemia_chkup_unit_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'anemia_chkup_unit_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='心電図'
                        contentStyle={contentStyle}>
                        <Form.Item name='ecg_chkup_unit_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'ecg_chkup_unit_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='眼底'
                        contentStyle={contentStyle}>
                        <Form.Item name='unit_price_exam_fundus_ken' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'unit_price_exam_fundus_ken', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='ｸﾚｱﾁﾆﾝ'
                        contentStyle={contentStyle}>
                        <Form.Item name='creatinine_chkup_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'creatinine_chkup_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='追加'
                        contentStyle={contentStyle}>
                        <Form.Item name='add_chkup_unit_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'add_chkup_unit_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='合計'
                        contentStyle={contentStyle}>
                        {this.formRef.current?.getFieldValue('unit_price_total_price') ?? 0}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div className="box_inner_vertical" style={{ gap: 0 }}>
                    <div
                      className="table_header_filter"
                      style={{ justifyContent: 'center' }}
                    >
                      <Form.Item style={{ margin: "0" }}>
                        <label style={{ color: "#fff" }}> 窓口負担</label>
                      </Form.Item>
                    </div>
                    <Descriptions
                      title=''
                      column={1}
                      bordered
                      size='small'
                      labelStyle={labelStyle}
                    >
                      <Descriptions.Item
                        label='基本'
                        contentStyle={contentStyle}>
                        <Form.Item name='basic_window_burden_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'basic_window_burden_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='詳細部分'
                        contentStyle={contentStyle}
                      >
                        <Form.Item name='details_window_burden_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'details_window_burden_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='追加項目'
                        contentStyle={contentStyle}>
                        <Form.Item name='add_window_burden_price' style={formInput}>
                          <Input onBlur={(e) => this.outsourcingFee(e, 'add_window_burden_price', 6)} style={inputRight} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label='合計'
                        contentStyle={contentStyle}>
                        {this.formRef.current?.getFieldValue('window_burden_price') ?? 0}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <Descriptions
                  title=''
                  column={1}
                  bordered
                  size='small'
                >
                  <Descriptions.Item
                    label='同時実施負担'
                    contentStyle={contentStyle}>
                    <Form.Item name='simultaneous_burden_price' style={formInput}>
                      <Input onChange={(e) => this.digitCheck(e, 'simultaneous_burden_price', 9)} style={inputRight} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='保険者請求額'
                    contentStyle={contentStyle}>
                    <Form.Item name='amount_billed_to_insurer' style={formInput}>
                      <Input onChange={(e) => this.digitCheck(e, 'amount_billed_to_insurer', 9)} style={inputRight} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>

            <div className="box_button_bottom_right" style={{ display: 'flex', gap: '20px' }}>
              <Button
                type="primary"
                onClick={() => this.onSave()}
                icon={<SaveOutlined />}
              >
                保存</Button>
            </div>
          </Form>
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1303006_ScreenInput);
