import React, { useState, createRef, useEffect } from 'react'
import { Card, Form, Input, Select, Button, message, Popconfirm } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

const WS1178001_GovernmentTubeHepatitisAggregateSub = (props) => {
  const formRef = createRef()
  const [childModal, setChildModal] = useState({
    visible: false,
    component: null,
    width: 0,
  })

  const [itemChange, setItemChange] = useState([])
  const [selectItem, setSelectItem] = useState(null)

  useEffect(() => {
    // 編集での初期表示で保存値を表示
    if (props.record) {
      formRef.current?.setFieldsValue({ "medical_exam_course": props.record.medical_exam_course })
      formRef.current?.setFieldsValue({ "invoice_summary_division": props.record.invoice_summary_division })
      formRef.current?.setFieldsValue({ "course_exam_basic_health": props.record.course_exam_basic_health })
      setItemChange({
        id: props.record.id,
        medical_exam_course: props.record.medical_exam_course,
        invoice_summary_division: props.record.invoice_summary_division,
        course_exam_basic_health: props.record.course_exam_basic_health
      })
    }
  }, []);

  useEffect(() => {
    // コースと基本コースの選択値を表示
    if (selectItem) {
      formRef.current?.setFieldsValue({ "medical_exam_course": itemChange.medical_exam_course })
      formRef.current?.setFieldsValue({ "course_exam_basic_health": itemChange.course_exam_basic_health })
    }
  }, [selectItem]);

  const onSearch = (output) => {
    // コース選択
    if (output.medicalExamCourseFlag) {
      setItemChange({
        ...itemChange,
        medical_exam_course: output.Lo_CourseCode
      })
      setSelectItem({
        ...selectItem,
        medical_exam_course: output.Lo_CourseCode
      })
    } else {
      // 基本コース選択
      setItemChange({
        ...itemChange,
        course_exam_basic_health: output.Lo_CourseCode
      })
      setSelectItem({
        ...selectItem,
        course_exam_basic_health: output.Lo_CourseCode
      })
    }
    closeModal()
  }

  const handleChange = () => {
    const invoice_summary_division = formRef.current?.getFieldValue("invoice_summary_division")

    setItemChange({
      ...itemChange,
      invoice_summary_division: invoice_summary_division
    })
  }

  /**
   * モーダルを閉じる
   */
  const closeModal = () => {
    setChildModal({
      ...childModal,
      visible: false,
    })
  }

  const onSave = () => {
    VenusApiRoutesV2.callApi("API001178001007", {
      id: itemChange.id ?? null,
      course_exam_basic_health: itemChange.course_exam_basic_health,
      medical_exam_course: itemChange.medical_exam_course,
      invoice_summary_division: itemChange.invoice_summary_division ?? 0
    }).then(res => {
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.info(res.message);
      props.getScreenData()
    }).catch(error => {
      message.error('エラーが発生しました');
    });
    props.onFinishScreen()
  }

  const deleteData = (record) => {
    VenusApiRoutesV2.callApi("API001178001008", {
      mainSource: [{ id: record }]
    }).then(res => {
      message.info('削除しました');
      props.getScreenData()
    }).catch(error => {
      const res = error.response;
      console.log(res)
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
    props.onFinishScreen()
  }

  // const required = [{ required: true, message: '必須項目です' }]

  return (

    <div className='modal-add' >
      <Card title={(props.newFlag ?? false) === true ? '新規' : '変更'}>
        <Form
          ref={formRef}
          autoComplete='off'
        >
          <Form.Item name="medical_exam_course" label="コース">
            <Input.Search
              allowClear={true}
              className={'input-size-12'}
              onSearch={() => {
                setChildModal({
                  visible: true,
                  width: '60%',
                  component: (
                    <WS0265001_BasicCourseInquiry
                      medicalExamCourseFlag={true}
                      onFinishScreen={onSearch}
                    />)
                })
              }}
            />
          </Form.Item>

          <Form.Item name='invoice_summary_division' className={'input-size-12'} label="集計区分">
            <Select
              onChange={handleChange}
              style={{ marginBottom: '5px' }}>

              <Select.Option value={0}>&nbsp;</Select.Option>
              <Select.Option value={1}>肝炎</Select.Option>

            </Select>
          </Form.Item>

          <Form.Item name="course_exam_basic_health" label="基本コース">
            <Input.Search
              allowClear={true}
              className={'input-size-12'}
              onSearch={() => {
                setChildModal({
                  visible: true,
                  width: '60%',
                  component: (
                    <WS0265001_BasicCourseInquiry
                      medicalExamCourseFlag={false}
                      onFinishScreen={onSearch}
                    />)
                })
              }}
            />
          </Form.Item>
        </Form>

        <div className='box_button_bottom_right'>
          {/* {props.deleteFlag !== false ? */}
          < Popconfirm
            title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
            overlayStyle={{ width: '250px' }}
            overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
            icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
            okText='削除'
            okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
            onConfirm={() =>
              // 削除
              deleteData(props.record.id)
            }
            cancelText='キャンセル'
            cancelButtonProps={{ style: { height: '32px' } }}
          >
            <Button
              danger
              type='primary'
              style={{
                display: (props.deleteFlag ?? true) ? '' : 'none' // deleteFlag:falseの場合、非表示
              }}
              icon={<DeleteOutlined />}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>
          </Popconfirm>

          {/* 保存ボタン */}
          <Button
            type='primary'
            icon={<SaveOutlined />}
            onClick={() => {
              onSave()
            }}
          >
            <span className='btn_label'>
              保存
            </span>
          </Button>
        </div>

      </Card >

      <ModalDraggable
        width={childModal.width}
        visible={childModal.visible}
        component={childModal.component}
        onCancel={() => {
          closeModal()
        }}
      />
    </div >
  )
}

export default WS1178001_GovernmentTubeHepatitisAggregateSub
