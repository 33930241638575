import axios from "configs/axios";

const APP_LIST = {
  GetScreenData: "/api/follow-up/follow-up/get-screen-data",
  getCategoryJudges: "/api/follow-up/follow-up-input/get-category-judges",
  saveFollowUpInput: "/api/follow-up/follow-up-input/save-follow-up-input",
  deleteFollowUp: "/api/follow-up/delete-confirm/delete-follow-up",
  preview: "/api/follow-up/follow-up/preview",
  print: "/api/follow-up/follow-up/print",
  getInitialData: "/api/follow-up/postmanage-and-tracking-follow-up/get-initial-data",
  outputCSV: "/api/follow-up/postmanage-and-tracking-follow-up/output-csv",
  statistOutput: "/api/follow-up/postmanage-and-tracking-follow-up/statist-output"
};

const FollowUpService = {
  async GetScreenData(params) {
    return axios.get(APP_LIST.GetScreenData, { params });
  },
  async getCategoryJudges(params) {
    return axios.get(APP_LIST.getCategoryJudges, { params });
  },
  async saveFollowUpInput(params) {
    return axios.post(APP_LIST.saveFollowUpInput, params);
  },
  async deleteFollowUp(params) {
    return axios.post(APP_LIST.deleteFollowUp, params);
  },
  async preview(params) {
    return axios.post(APP_LIST.preview, params, { responseType: 'blob' });
  },
  async print(params) {
    return axios.post(APP_LIST.print, params);
  },
  async getInitialData(params) {
    return axios.get(APP_LIST.getInitialData, { params });
  },
  async outputCSV(params) {
    return axios.post(APP_LIST.outputCSV, params, { responseType: 'blob' });
  },
  async statistOutput(params) {
    return axios.post(APP_LIST.statistOutput, params, { responseType: 'blob' });
  }
};

export default FollowUpService;