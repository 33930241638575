import axios from 'configs/axios'

const apiPaths = {
  index: '/api/radiography-finding-input/findings-history-list',
}

const FindingsHistoryListService = {
  async index(params) {
    return axios.post(apiPaths.index, params)
  }
}

export default FindingsHistoryListService
