import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row, Col, Button, Form, message, } from "antd";
import { MoreOutlined, PlusOutlined, } from "@ant-design/icons";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import SentenceMasterService from "services/SpecificInsureMaintenance/SentenceMaster/SentenceMasterService";

const columns = [
  {
    title: '文章コード',
    dataIndex: 'sentence_code',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },

  {
    title: '検索文字',
    dataIndex: 'search_character',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '内容',
    dataIndex: 'content',
    // classNumber: '40',
    type: 1,
  },
]

class WS1403009_SentenceMaster extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "文章マスタ";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
      isLoadingTableLeft: false,
      isLoadingTableRight: false,
      document_classification_code: ""
    };
  }

  componentDidMount = () => {
    this.getSentenceMaster();
  };


  // 初期表示
  getSentenceMaster = () => {
    this.setState({ isLoadingTableLeft: true });
    SentenceMasterService.getSentenceMasterService()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          SentenceMasterDisplayList: res.data,
        });
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableLeft: false });
      });
  };


  // 選択された文章分類コードの保持
  getSentenceMasterSub = (document_classification_code) => {
    this.setState({
      document_classification_code: document_classification_code,
      isLoadingTableRight: true,
    });
    SentenceMasterService.getSentenceMasterSubService({
      document_classification_code,
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          SentenceMasterSubDisplayList: res.data,
        });
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableRight: false });
      });
  };


  // 文章コード保存
  saveButton = (result, flag) => {
    let params = {
      document_classification_code: this.state.document_classification_code,
      sentence_code: result.itemChange.sentence_code,
      search_character: result.itemChange.search_character,
      content: result.itemChange.content,
      newFlag: flag
    }

    SentenceMasterService.saveAndUpdateService(params)
      .then((res) => {
        message.success("成功");
        this.getSentenceMasterSub(this.state.document_classification_code)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  };


  // 文章コードの削除
  deleteButton = (result) => {
    let params = {
      document_classification_code: this.state.document_classification_code,
      sentence_code: result.itemChange.sentence_code,
      search_character: result.itemChange.search_character,
      content: result.itemChange.content
    }

    SentenceMasterService.deleteSentenceMasterSubService(params)
      .then((res) => {
        message.success("成功");
        this.getSentenceMasterSub(this.state.document_classification_code)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  };


  checkEdit = (id) => {
    let isReadOnly = false;
    if (id) {
      isReadOnly = true;
      if (id === "create_new") {
        isReadOnly = false;
      }
    }
    return isReadOnly;
  };


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="sentence-master">
        <Card className="mb-2" title="文章マスタ">
          <Form ref={this.formRef}>

            <Row gutter={24}>
              <Col span={9} style={{ marginRight: "10px" }}>
                <Table bordered
                  dataSource={
                    this.formRef.current
                      ? this.formRef.current.getFieldValue(
                        "SentenceMasterDisplayList"
                      )
                      :
                      []
                  }
                  loading={this.state.isLoadingTableLeft}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{ y: 700 }}
                  pagination={{
                    defaultCurrent: 1,
                    showSizeChanger: true,
                    defaultPageSize: 10,
                  }}

                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.getSentenceMasterSub(
                          record.document_classification_code
                        );
                      },
                    };
                  }}
                >
                  <Table.Column
                    showSorterTooltip={false}
                    sorter={(a, b) => a.document_classification_code.localeCompare(b.document_classification_code, 'ja')}
                    title="文章分類コード"
                    dataIndex="document_classification_code"
                  />
                  <Table.Column
                    showSorterTooltip={false}
                    sorter={(a, b) => a.document_classification_name.localeCompare(b.document_classification_name, 'ja')}
                    title="文章分類名"
                    dataIndex="document_classification_name"
                  />
                </Table>
              </Col>

              <Col span={14}>
                <Table bordered
                  dataSource={
                    this.formRef.current
                      ? this.formRef.current.getFieldValue(
                        "SentenceMasterSubDisplayList"
                      )
                      :
                      []
                  }
                  loading={this.state.isLoadingTableRight}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
                  pagination={{
                    defaultCurrent: 1,
                    showSizeChanger: true,
                    defaultPageSize: 10,
                    onChange: (page, pageSize) => { },
                    onShowSizeChange: (page, pageSize) => { },
                  }}
                  onRow={(record, rowIndex) => ({
                    onDoubleClick: () =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalChange
                              columns={columns}
                              record={record}
                              onUpdate={(output) => {
                                this.closeModal()
                              }}
                              onDelete={(output) => {
                                this.closeModal()
                              }}
                            />
                        },
                      })
                  })}
                >
                  <Table.Column
                    showSorterTooltip={false}
                    sorter={(a, b) => a.sentence_code.localeCompare(b.sentence_code, 'ja')}
                    title="文章コード"
                    dataIndex="sentence_code"
                  />
                  <Table.Column
                    showSorterTooltip={false}
                    sorter={(a, b) => a.search_character.localeCompare(b.search_character, 'ja')}
                    title="検索文字"
                    dataIndex="search_character"
                  />

                  <Table.Column
                    showSorterTooltip={false}
                    sorter={(a, b) => a.content.localeCompare(b.content, 'ja')}
                    title="内容"
                    dataIndex="content"
                  />

                  <Table.Column
                    align='center'
                    width={40}
                    fixed='right'
                    title={
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        loading={this.state.isLoadingInsurersList}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalAdd
                                  columns={columns}
                                  onFinishScreen={(output) => {
                                    this.saveButton(output, true)
                                    this.closeModal()
                                  }}
                                />
                            },
                          });
                        }}
                      />
                    }
                    render={(text, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集、削除
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalChange
                                  columns={columns}
                                  record={record}
                                  onUpdate={(output) => {
                                    this.saveButton(output, false)
                                    this.closeModal()
                                  }}
                                  onDelete={(output) => {
                                    this.deleteButton(output)
                                    this.closeModal()
                                  }}
                                />
                            },
                          });
                        }}
                      />
                    )}
                  />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1403009_SentenceMaster);
