import { message } from "antd";
import RadiographyInspectMaintainService from "services/InspectionMaintenance/RadiographyInspectMaintain/RadiographyInspectMaintainService";

export const getRadioGraphyInspectMaintainAction = () => {
  return RadiographyInspectMaintainService.getRadioGraphyInspectMaintainService()
}

export const saveAndUpdateRadioGraphyInspectMaintainAction = (params) => {
  return RadiographyInspectMaintainService.saveAndUpdateRadioGraphyInspectMaintainService(params)
    .then((res) => {
      message.success(res.data.message);
      return res?.data;
    })
    .catch((err) => {
      const res = err.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
}

export const deleteRadioGraphyInspectMaintainAction = (params) => {
  return RadiographyInspectMaintainService.deleteRadioGraphyInspectMaintainService(params)
    .then((res) => {
      message.success(res.data.message);
      return res?.data;
    })
    .catch((err) => {
      const res = err.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
}