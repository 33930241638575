import { message } from 'antd'
import { saveAs } from 'file-saver'

/**
 * ダウンロード関連の共通関数
 *
 * @function number_format
 * @function download_file　CSV、TXTのダウンロード
 * @function download_file_binary　、TXTのダウンロード
 * @function saveFilePicker
 * @function basename
 * @function Regex
 */


function PrintHTML(htmlTxt, preview) {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
  const systemZoom = width / window.screen.availWidth;
  const w = width;
  const h = height;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open('', '', `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
  `);
  const newDoc = newWindow.document;
  newDoc.write(htmlTxt);
  // window.onclose = newWindow.close;
  newWindow.focus();
  if (!preview) {
    newWindow.print();
    newWindow.close();
  }
}

export function number_format(number) {
  return (new Intl.NumberFormat()).format(number);
}


/**
 * TXT、CSVファイルのダウンロード関数
 * @param {*} axiosResponse 
 * @param {*} preview 
 * @param {boolean} [isHtmlDownload] htmlの場合にダウンロードするか？
 * @returns 
 */
export async function download_file(axiosResponse, preview = false, isHtmlDownload = false) {
  let headerLine = axiosResponse.headers['Content-Disposition'] || axiosResponse.headers['content-disposition'];
  if (!headerLine) {
    return;
  }
  const fnameArr = headerLine.split(';');
  let filename = fnameArr[fnameArr.length - 1].split('=')[1].replace('"', '').replace('"', '');
  const utf8Name = "utf-8''";
  if (filename.indexOf(utf8Name) !== -1) {
    filename = decodeURI(filename.replace(utf8Name, ''))
  }

  if (filename.indexOf('.html') !== -1 && !isHtmlDownload) {
    const dataText = axiosResponse.data.text ? (await axiosResponse.data.text()) : axiosResponse.data;
    PrintHTML(dataText, preview);
  } else {
    const downloadUrl = window.URL.createObjectURL(new Blob([axiosResponse.data]));
    const link = document.createElement('a')
    link.href = downloadUrl;
    link.setAttribute('download', filename) //any other extension
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}

/**
 * ZIP,XLSXファイルのダウンロード関数
 * @param {*} axiosResponse
 * @returns
 */
export async function download_file_binary(axiosResponse) {
  let headerLine = axiosResponse.headers['Content-Disposition'] || axiosResponse.headers['content-disposition']
  if (!headerLine) {
    return
  }
  // ファイル名取得
  let fileName = ''
  var filenameRegex = /filename[^;=\n]*=((['']).*?\2|[^\n]*)/
  var matches = filenameRegex.exec(axiosResponse.headers['content-disposition'])
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['']/g, '')
  }
  // content-type取得
  let mineType = axiosResponse.headers['content-type']
  const blob = new Blob([axiosResponse.data], { type: mineType })
  // ファイルダウンロード
  saveAs(blob, fileName)
}

/**
 * 保存先の選択　※使用できない
 * @param {*} filename
 * @param {*} extension
 * @returns
 */
export async function saveFilePicker(filename, extension) {
  let options = ''
  if (extension == 'csv') {
    options = {
      suggestedName: filename,
      types: [
        {
          description: "CSVファイル",
          accept: { "text/csv": [".csv"] }
        },
      ],
    };

  } else if (extension == 'txt') {
    options = {
      suggestedName: filename,
      types: [
        {
          description: "TXTファイル",
          accept: { "text/txt": [".txt"] }
        },
      ],
    };
  }

  //保存先指定のダイヤログを表示
  const savePlace = await window.showSaveFilePicker(options);
  return savePlace
}

/**
 * ファイル書き込み
 * @param {*} res 
 * @param {*} savePlace 
 */
export async function writeFile(res, savePlace) {
  try {
    // writable作成
    const writable = await savePlace.createWritable();

    //blobの作成
    const blob = new Blob([res.data]);

    // コンテンツを書き込む
    await writable.write(blob);

    // ファイル閉じる
    await writable.close();

    message.success("ダウンロードが完了しました")

  } catch (e) {
    message.error("ダウンロードに失敗しました")
  }

}

/**
 * ファイル名を取得
 * @param {*} path
 * @returns
 */
export function basename(path) {
  return path?.split(/[\\/]/).pop()
}

/**
 * 文字列検索
 * @param {*} value
 * @param {*} regexString
 * @returns
 */
export const Regex = (value, regexString) => {
  const regex = new RegExp(regexString);
  return regex.test(value) // return true or false
}
