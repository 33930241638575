import axios from 'configs/axios'

const apiPaths = {
  index: '/api/mutual-aid-association-billing/comprehensive-medical-exam-billing',
  totalling: '/api/mutual-aid-association-billing/comprehensive-medical-exam-billing/totalling',

  preview: '/api/mutual-aid-association-billing/comprehensive-medical-exam-invoice/preview',
  print: '/api/mutual-aid-association-billing/comprehensive-medical-exam-invoice/print',
}

const ComprehensiveMedicalExamBillingService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async totalling(params) {
    return axios.get(apiPaths.totalling, { params })
  },
  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' })
  },
  async print(params) {
    return axios.post(apiPaths.preview, params)
  },
}
export default ComprehensiveMedicalExamBillingService
