import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, message, Button, Table } from 'antd'
import FindingsInputPhysiciiagnosisAction from 'redux/InputBusiness/SpreadInput/FindingsInputPhysiciiagnosis.action'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import Color from 'constants/Color'
import WS0730002_FindingsInputPhysiciiagnosisChange from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730002_FindingsInputPhysiciiagnosisChange'
import ModalDraggable from 'components/Commons/ModalDraggable'

// ※医師の診断のsite_codeは4　マスタが変更になったら、ここも変更する
const DOCTER_DIAGNOSIS_TITLE = '医師の診断'
const DOCTER_DIAGNOSIS_SITE_CODE = 4
class WS0730001_FindingsInputPhysiciiagnosis extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '所見入力[医師診断]'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataForm: {},

      dataSourceCause: [],
      dataSourceDoctorDiagnosis: [],

      selectedRows1: [],
      selectedRows2: [],
      indexTable: 0,
      rowSelect: {},
      rowSelect1: {},
      Lio_FindingsCategoryChange: 0,
      Lio_GuidanceAndAttentionChange: 0,
      maxSerialNum: 0
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    const data = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_CategoryCode: this.props.Li_CategoryCode,
      Li_PersonalNum: this.props.Li_PersonalNum,
    }
    FindingsInputPhysiciiagnosisAction.index(data)
      .then((res) => {
        if (res) {
          this.formRef.current.setFieldsValue({ tableData: res })
          this.setState({ dataForm: res })
          // 「実施理由」の一覧
          this.getCauseList()
        }
      })
  }

  /**
   * 「実施理由」の一覧を取得
   */
  getCauseList = () => {
    const data = {
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InspectCode: this.state.dataForm.Li_InspectCode,
      site_classification: this.state.dataForm.site_classification,
      Li_JudgeLevel: this.state.dataForm.Li_JudgeLevel,
    }
    FindingsInputPhysiciiagnosisAction.getCauseList(data)
      .then((res) => {
        if (res) {
          this.setState({ dataSourceCause: res })
        }
        // 「医師の診断」の一覧
        this.getDoctorDiagnosisList()
      })
  }

  /**
   * 「医師の診断」の一覧を取得
   */
  getDoctorDiagnosisList = () => {
    const data = {
      Li_ReserveNum: this.state.dataForm.Li_ReserveNum,
      Li_InspectCode: this.state.dataForm.Li_InspectCode,
      Li_JudgeLevel: this.state.dataForm.Li_JudgeLevel,
      site_classification: this.state.dataForm.site_classification,
      site_code: DOCTER_DIAGNOSIS_SITE_CODE,
    }
    FindingsInputPhysiciiagnosisAction.getDoctorDiagnosisList(data)
      .then((res) => {
        if (res) {
          let maxNum = 0
          res.forEach((x) => {
            if (x.serial_number > maxNum) {
              maxNum = x.serial_number
            }
          })
          this.setState({
            dataSourceDoctorDiagnosis: res,
            maxSerialNum: maxNum
          })
        }
      })
  }


  onFinish(values) { }


  /**
   * 所見入力　変更モーダル
   * @param {} siteName
   * @param {*} record
   */
  showWS0730002_FindingsInputPhysiciiagnosisChange = (type, record = null) => {

    let siteCode = 0
    let siteName = ''
    if (type === 1) {
      // 実施理由
      siteCode = record.site_code ?? ''
      siteName = record.site_name ?? ''
    } else {
      // 医師の診断
      siteCode = record?.site_code ?? DOCTER_DIAGNOSIS_SITE_CODE
      siteName = DOCTER_DIAGNOSIS_TITLE
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 520,
        component:
          <WS0730002_FindingsInputPhysiciiagnosisChange
            type={type} //type:1 実施理由、type:2 医師の診断
            siteCode={siteCode}
            siteName={siteName}
            record={record}
            Li_ReserveNum={this.state.dataForm.Li_ReserveNum}
            Li_CourseLevel={this.state.dataForm.Li_CourseLevel}
            Li_PatternCode={this.state.dataForm.Li_PatternCode}
            Li_CategoryCode={this.state.dataForm.Li_CategoryCode}
            Li_InspectCode={this.state.dataForm.Li_InspectCode}
            Li_JudgeLevel={this.props.Li_JudgeLevel}
            site_classification={this.state.dataForm.site_classification}
            maxSerialNum={this.state.maxSerialNum}
            user={this.props.user}
            onUpdateFindings={async () => {
              if (this.props.onUpdateFindings) {
                // 親画面の表示内容更新させる　
                await this.props.onUpdateFindings()
              }
              if (!this.props.closeFlag) {
                // データ再取得
                await this.index()
              }

              await this.closeModal()
            }}
          />
        ,
      },
    })
  }


  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    // const { rowSelect } = this.state
    // const { rowSelect1 } = this.state
    return (
      <div className='findings-input-physiciiagnosis'>
        <Card
          // title='所見入力[医師診断]'
          title={`${this.props.record.inspect_name} : [${this.props.personalNumberId}] ${this.props.KanjiName}`}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className='box_container'>
              <div>
                <div className='table_header_filter'>
                  {'実施理由'}
                </div>
                <Table
                  bordered
                  size='small'
                  pagination={false}
                  dataSource={this.state.dataSourceCause}
                  rowKey={(record) => record.site_code}
                  // rowClassName={(record, index) => record.site_code === this.state.selectedRows1[0]?.site_code ? 'table-row-light' : ''}
                  onRow={(record, index) => ({
                    // onClick: () => {
                    //   this.setState({ rowSelect1: record, selectedRows1: [record], })
                    // },
                    onDoubleClick: () => {

                    }
                  })
                  }
                  scroll={{ y: '282px' }}
                >
                  <Table.Column
                    title='実施検査'
                    width={180}
                    dataIndex='site_name'
                  />
                  <Table.Column
                    title='理由'
                    dataIndex='findings_name'
                  />

                  <Table.Column
                    width={45}
                    style={{ textAlign: 'center' }}
                    title=''
                    // title={(item, record, index) =>
                    //   <Button
                    //     type='primary'
                    //     size='small'
                    //     icon={(<PlusOutlined />)}
                    //     onClick={() => {
                    //       // 新規追加の場合
                    //       // this.showWS0730002_FindingsInputPhysiciiagnosisChange()
                    //     }}
                    //   />
                    // }
                    render={(item, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集の場合
                          this.showWS0730002_FindingsInputPhysiciiagnosisChange(1, record)
                        }}
                      />
                    )}
                  />


                </Table>
              </div>

              <div>
                <div className='table_header_filter'>
                  {DOCTER_DIAGNOSIS_TITLE}
                </div>
                <Table
                  bordered
                  size='small'
                  pagination={false}
                  dataSource={this.state.dataSourceDoctorDiagnosis}
                  rowKey={(record) => record.id}
                  onRow={(record, index) => {
                    return {
                      onDoubleClick: () => {

                      }
                    }
                  }}
                  scroll={{ y: '282px' }}
                >
                  <Table.Column
                    title='連番'
                    width={90}
                    dataIndex='serial_number'
                    render={(value, record, index) => (
                      <div style={{ textAlign: 'right' }}>
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    title='診断内容'
                    dataIndex='findings_name'
                  />

                  <Table.Column
                    width={45}
                    style={{ textAlign: 'center' }}
                    title={
                      <Button
                        type='primary'
                        size='small'
                        icon={(<PlusOutlined />)}
                        onClick={() => {
                          // 新規追加の場合
                          this.showWS0730002_FindingsInputPhysiciiagnosisChange(2)
                        }}
                      />
                    }
                    render={(item, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集の場合
                          this.showWS0730002_FindingsInputPhysiciiagnosisChange(2, record)
                        }}
                      />
                    )}
                  />

                </Table>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0730001_FindingsInputPhysiciiagnosis)
