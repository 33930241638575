import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Dropdown, Button, Menu } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WeekAndCourseSettingEdit from 'pages/ZZ_Others/SANAI_MedicalCheckupCalendar/WeekAndCourseSettingEdit.jsx'
import WeekAndCourseSettingAction from 'redux/Others/MedicalCheckupCalendar/WeekAndCourseSetting.action.js'

class WeekAndCourseSetting extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      YearsChar: '0000/00/00'
    }
  }

  componentDidMount = async () => {
    await this.formRef.current.setFieldsValue({ YearsChar: moment(this.props.YearsChar) })
    await this.setState({ YearsChar: moment(this.props.YearsChar).format('YYYY/MM/DD') })
    await this.getListData()
  }

  /**
   * 初期表示
   */
  getListData() {
    // let contractStartDateOn = this.formRef.current.getFieldValue('YearsChar').format('YYYY/MM/DD');
    let params = {
      contractStartDateOn: this.state.YearsChar
    }

    console.log(params);

    WeekAndCourseSettingAction.getListData(params)
      .then((res) => {
        this.setState({
          dataSource: res
        })

        this.forceUpdate()
      })
  }

  /**
   * 編集処理
   */
  WeekAndCourseSettingEdit = (record) => {
    let newflag = true
    if (record) {
      newflag = false
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WeekAndCourseSettingEdit
            newFlag={newflag}
            YearsChar={this.state.YearsChar}
            record={record ?? ''}

            onFinishScreen={() => {
              this.closeModal()
              this.getListData()
            }}
          />
        )
      }
    })
  }

  changeDate = async (date) => {
    console.log(moment(date).format('YYYY/MM/DD'));
    await this.formRef.current.setFieldsValue({ YearsChar: moment(date).format('YYYY/MM/DD') })
    await this.setState({ YearsChar: moment(date).format('YYYY/MM/DD') })
    await this.getListData()
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='week-and-course-setting'>
        <Card title='曜日・コース設定 [人間ドックカレンダー]' >
          <Form ref={this.formRef} >
            <div>
              <Form.Item
                name='YearsChar'
                label='年月'
              >
                <VenusDatePickerCustom
                  format='YYYY/MM/DD'
                  onChange={(date) => {
                    this.changeDate(date)
                  }}
                />
              </Form.Item>

            </div>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              loading={false}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(200) }}
            >
              <Table.Column
                title='曜日'
                className='column-size-7'
                dataIndex='week_name'
              />

              <Table.Column
                title='奇数・偶数'
                className='column-size-10'
                dataIndex='odd_even_name'
              />

              <Table.Column
                title='契約種別'
                className='column-size-10'
                dataIndex='contract_type_name'
              />

              <Table.Column
                title='契約年度'
                className='column-size-10'
                dataIndex='contract_start_date_on'
              />

              <Table.Column
                title='契約団体'
                className='column-size-10'
                dataIndex='contract_office_code'
                render={(text, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {text}
                    </div>
                  )
                }}
              />

              <Table.Column
                title='契約番号'
                className='column-size-10'
                dataIndex='contract_number'
                render={(text, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {text}
                    </div>
                  )
                }}
              />

              <Table.Column
                title='コース'
                className='column-size-10'
                dataIndex='medical_exam_course'
              />

              <Table.Column
                title='コース名'
                dataIndex='course_name'
              />

              <Table.Column
                title='人数'
                className='column-size-5'
                dataIndex='people'
                render={(text, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {text}
                    </div>
                  )
                }}
              />

              <Table.Column
                width={40}
                title={
                  <Button
                    size='small'
                    type='primary'
                    style={{ textAlign: 'center' }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.WeekAndCourseSettingEdit()
                    }}
                  >
                  </Button>
                }
                render={(text, record, index) => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              onClick={() => {
                                this.WeekAndCourseSettingEdit(record)
                              }}
                            >
                              <label>編集</label>
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </div>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WeekAndCourseSetting)
