import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, Dropdown, Button, Modal, message, Menu } from 'antd'
import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  MoreOutlined
} from '@ant-design/icons'
import {
  getDataPatternSettingSubAction, saveDataPatternSettingSubAction, deleteDataPatternSettingSubAction
} from 'redux/InspectionMaintenance/AnotherInspectItemSettingCategory/PatternSettingSub.actions'
import WS2705076_CopyPattern from './WS2705076_CopyPattern'
import ModalDraggable from 'components/Commons/ModalDraggable'
import ModalChange from 'components/Commons/ModalChange'
import ModalAdd from 'components/Commons/ModalAdd.jsx'

const columns = [
  {
    title: 'ﾊﾟﾀｰﾝ',
    dataIndex: 'pattern_code',
    type: 1,
  },
  {
    title: 'ﾊﾟﾀｰﾝ名称',
    dataIndex: 'pattern_name',
    type: 1,
  },
]
class WS2705075_PatternSettingSub extends React.Component {
  static propTypes = {
    Lo_PatternCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = ' ﾊﾟﾀｰﾝ設定SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }
  }

  componentDidMount = () => {
    this.loadData()
  }

  loadData = () => {
    this.setState({ isLoading: true })
    getDataPatternSettingSubAction()
      .then(res => {
        if (res?.data)
          this.setState({
            dataSource: res.data
          })
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  createOrUpdateRecord = (record) => {
    if (!record.pattern_code) {
      Modal.error({
        content: 'ｺｰﾄﾞに空白は設定できません',
        icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />
      })
    } else {
      if (record.isNew) delete record.id
      saveDataPatternSettingSubAction(record)
        .then(res => {
          message.success('保存が完了しました')
          // this.loadData()
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        })
        .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
    }
  }

  deleteRecord = (record, index) => {
    if (record.id && !record.isNew) {
      deleteDataPatternSettingSubAction({ id: record.id })
        .then(res => {
          message.success('削除が完了しました')
          this.loadData()
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        })
        .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  Copy_F07 = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        component: (
          <WS2705076_CopyPattern
            dataSource={this.state.dataSource}
            Li_CopySourcePatternCode={record.pattern_code}
            pattern_name={record.pattern_name}
            onFinishScreen={(isLoad) => {
              // if (isLoad) {
              //   this.loadData()
              // }
              this.closeModal()
              this.props.onFinishScreen()
            }}
          />
        ),
      },
    })
  }

  addRow = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '550px',
        className: '',
        component:
          <ModalAdd
            columns={columns}
            onFinishScreen={(output) => {
              this.createOrUpdateRecord(output.itemChange)
              this.closeModal()
            }}
          />
        // <ModalChange
        //   newFlag={true}
        //   columns={columns}
        //   // record={record}
        //   onUpdate={(output) => {
        //     let params = {
        //       // ...record,
        //       ...output.itemChange
        //     }
        //     this.createOrUpdateRecord(params)
        //     this.closeModal()
        //   }}
        //   onDelete={(output) => {
        //     this.closeModal()
        //   }}
        // />
      },
    })

  }

  renderSaveAndDeleteRecord = (record, index) => (
    <>
      <Button
        size='small'
        style={{ border: 'none' }}
        icon={<SaveOutlined style={{ color: 'green' }} />}
        onClick={() => this.createOrUpdateRecord(record)}
      />
      <Button
        size='small'
        style={{ border: 'none', }}
        danger icon={<DeleteOutlined />}
        onClick={() => {
          Modal.confirm({
            content: '消去してもよろしいですか？',
            okText: 'は　い',
            cancelText: 'いいえ',
            onOk: () => this.deleteRecord(record, index)
          })
        }}
      />
    </>
  )

  changeData = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '550px',
        className: '',
        component:
          <ModalChange
            columns={columns}
            record={record}
            onUpdate={(output) => {
              let params = {
                ...record,
                ...output.itemChange
              }
              this.createOrUpdateRecord(params)
              this.closeModal()
            }}
            onDelete={(output) => {
              this.closeModal()
            }}
          />
      },
    })
  }

  render() {
    return (
      <div className='pattern-setting-sub'>
        <Card title=' ﾊﾟﾀｰﾝ設定'>
          <Table bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({
              // onClick: event => this.setState({ rowSelect: record }),
              onDoubleClick: () =>
                // 変更
                this.changeData(record)
            })}
          >
            <Table.Column
              title='ﾊﾟﾀｰﾝ'
              dataIndex='pattern_code'
              width={300}
            />
            <Table.Column
              title='ﾊﾟﾀｰﾝ名称'
              dataIndex='pattern_name'
            />
            <Table.Column
              align='center'
              width={70}
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={this.addRow}
                />
              }
              render={(text, record, index) => (
                <Dropdown
                  size='small'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='変更'
                        onClick={() => {
                          // 変更
                          this.changeData(record)

                        }}
                      >
                        変更
                      </Menu.Item>

                      <Menu.Item
                        key='複写'
                        onClick={() => {
                          this.Copy_F07(record)
                        }}
                      >
                        複写登録
                      </Menu.Item>

                      <Menu.Item
                        key='削除'
                        onClick={() => {
                          Modal.confirm({
                            title: '確認',
                            icon: (
                              <QuestionCircleOutlined
                                style={{ color: '#1890ff' }}
                              />
                            ),
                            content: '削除しますか',
                            okText: '削除',
                            cancelText: 'キャンセル',
                            onOk: () => {
                              this.deleteRecord(record, index)
                            },
                          })
                        }}
                      >
                        削除
                      </Menu.Item>
                    </Menu>
                  )}>
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            // render={(text, record, index) => this.renderSaveAndDeleteRecord(record, index)}
            />
          </Table>
          {/* <div style={{ float: 'right' }}>
            <Button
              type='primary'
              onClick={this.Copy_F07}>
              複写登録
            </Button>
          </div> */}
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2705075_PatternSettingSub)
