import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/cancer-screening-csv-output/cancer-screening-csv-output/get-screen-data",
  outputCSV: "/api/cancer-screening-csv-output/cancer-screening-csv-output/output-csv",
};

const CancerScreeningCSVOutputService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async outputCSV(params) {
    return axios.post(API_LIST.outputCSV, params, { responseType: 'blob' });
  },
};

export default CancerScreeningCSVOutputService;