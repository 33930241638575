import PatientInfoQueryStardService from 'services/basicInfo/PersonalInfoMaintain/PatientInfoQueryStardService'

const PatientInfoQueryStardAction = {
  index(params) {
    return PatientInfoQueryStardService.index(params)
  },
  delete(params) {
    return PatientInfoQueryStardService.delete(params)
  }
}
export default PatientInfoQueryStardAction
