import axios from "configs/axios";

const API_LIST = {
  implementRecordRef: "/api/insure-guide-input/implement-record-ref",
  getScreenData: "/api/insure-guide-input/implement-record-ref/getscreendata",
  dunningInquiry: "/api/insure-guide-input/implement-record-ref/dunninginquiry",
};

const ImplementRecordRefService = {
  async getImplementRecordRef(params) {
    return axios.get(API_LIST.implementRecordRef, { params });
  },  
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async dunningInquiry(params) {
    return axios.get(API_LIST.dunningInquiry, { params });
  },
};

export default ImplementRecordRefService;
