import AddressLabelPrintService from "services/UserTools/LabelPrint/AddressLabel_OfficeReservedService";
import { message } from "antd";

const AddressLabelOfficeReservedAction = {
  getScreenData(params) {
    return AddressLabelPrintService.getScreenData(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  displayBtn(params) {
    return AddressLabelPrintService.displayBtn(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  print(params) {
    return AddressLabelPrintService.print(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  preview(params) {
    return AddressLabelPrintService.preview(params)
  },

  errorCheck(params) {
    return AddressLabelPrintService.errorCheck(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}
export default AddressLabelOfficeReservedAction;