
import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Form, Table } from 'antd'
import { SaveOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import MemoEditDisplay from 'pages/ZZ_Others/SANAI_Memo/MemoEditDisplay.jsx'
import MemoFunctionAction from 'redux/Others/ReserveStatusSearch/MemoFunction.action.js'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class MemoHistoryDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: []
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  // 備考のデータ取得
  getScreenData() {
    let param = {
      type: this.props.type,
      personalNumberId: this.props.personalNumberId
    }
    MemoFunctionAction.getRemarksHistoryData(param)
      .then(res => {
        this.setState({ dataSource: res })
      })
  }

  // 単日メモの入力画面
  memoEditDisplay = (record, newFlag) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <MemoEditDisplay
            reservation_number={this.props.reservationNumber}
            personal_number_id={this.props.personalNumberId}
            KanjiName={this.props.KanjiName}
            type={this.props.type}
            serial_number={record.serial_number ?? ''}
            memo={record.memo ?? ''}
            newFlag={newFlag}
            onFinishScreen={() => {
              this.getScreenData()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='memo-history-display'>
        <Card title={this.props.typeName + '　備考 履歴'}>
          <Form ref={this.formRef}>
            <Table
              bordered
              size='small'
              pagination={false}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(25) }}
            >
              <Table.Column
                title='受診日'
                className='column-size-date'
                dataIndex='visit_date_on'
                render={(value) => {
                  return (
                    <div>{moment(value).format('YYYY/MM/DD(ddd)')}</div>
                  )
                }}
              />

              <Table.Column
                title='備考（履歴）'
                dataIndex='memo'
                className='column-size-60'
              />

              <Table.Column
                key='action'
                fixed='right'
                className='column-size-2'
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    disabled={!this.state.dataSource.length}
                    onClick={() => this.memoEditDisplay([], 1)}
                  />
                }
                render={(value, record) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => this.memoEditDisplay(record, 0)}
                    />
                  )
                }
                }

              />

            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MemoHistoryDisplay)
