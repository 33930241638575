import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Table, Row, Col, Tabs, Modal, Space, Spin, Dropdown, Menu, InputNumber } from "antd";
import { MoreOutlined, EditOutlined } from '@ant-design/icons';

import GetImage from "constants/Images";
import { getContractLineItemDisplayAction } from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions';

import WS0333001_SetIncludesQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQuery";
import WS0332001_ContractCourseBreakdownInquiry from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0332001_ContractCourseBreakdownInquiry.jsx';
import WS0309017_ContractItemSub from "./WS0309017_ContractItemSub";
import WS0605130_ContractInspectCondition from './WS0605130_ContractInspectCondition';
import WS0339001_InsurerInfoMaintain from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain.jsx';
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx';
import WS0605140_ExaminationSearch from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605140_ExaminationSearch.jsx';

const msGrid = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 }
}
const styleInput = {
  width: '100px'
}
const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  // borderRight: 0,
  margin: 0,
  textAlign: 'right',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}
const styleInputRight = {
  textAlign: 'right'
}
const styleSpan = {
  float: 'right',
  padding: '0px 5px',
  background: '#1A60AD',
  color: 'white'
}
class WS0605127_ContractLineItemDisplay extends React.Component {

  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '契約照会';

    this.state = {
      initParams: {
        Li_ContractType: '',
        Li_ContractOrgCode: '',
        Li_ContractStartDate: '',
        Li_ContractNum: '',
        Zoning: '',
        Search: ''
      },
      selectedRows: {},
      objContract: {},
      isLoadPage: true,
      isContractInspectContentSub1: true,
      isContractInspectContentSub2: true,
      isExamList: true,
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      ContractInspectContentSub1: [],
    };
  }

  componentDidMount = () => {
    console.log(this.props)
    console.log(this.props.ContractType);
    const { Li_ContractType, Li_ContractOrgCode, Li_ContractStartDate, Li_ContractNum } = this.props;
    const params = {
      Li_ContractType: Li_ContractType,
      Li_ContractOrgCode: Li_ContractOrgCode,
      Li_ContractStartDate: Li_ContractStartDate,
      Li_ContractNum: Li_ContractNum,
      Zoning: 0,
      Search: ''
    }
    this.callAPILoadData();
    this.setState({
      initParams: {
        ...this.state.initParams,
        Li_ContractType: Li_ContractType,
        Li_ContractOrgCode: Li_ContractOrgCode,
        Li_ContractStartDate: Li_ContractStartDate,
        Li_ContractNum: Li_ContractNum,
      }
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps !== this.props) {
      const { Li_ContractType, Li_ContractOrgCode, Li_ContractStartDate, Li_ContractNum } = this.props;
      const params = {
        Li_ContractType: Li_ContractType,
        Li_ContractOrgCode: Li_ContractOrgCode,
        Li_ContractStartDate: Li_ContractStartDate,
        Li_ContractNum: Li_ContractNum,
        Zoning: 0,
        Search: ''
      }
      this.callAPILoadData(params);
      this.setState({
        initParams: {
          ...this.state.initParams,
          Li_ContractType: Li_ContractType,
          Li_ContractOrgCode: Li_ContractOrgCode,
          Li_ContractStartDate: Li_ContractStartDate,
          Li_ContractNum: Li_ContractNum,
        }
      })
    }
  }

  callAPILoadData = () => {
    if (this.props) {
      const { Li_ContractType, Li_ContractOrgCode, Li_ContractStartDate, Li_ContractNum } = this.props;
      const params = {
        Li_ContractType: Li_ContractType,
        Li_ContractOrgCode: Li_ContractOrgCode,
        Li_ContractStartDate: Li_ContractStartDate,
        Li_ContractNum: Li_ContractNum,
        Zoning: 0,
        Search: ''
      }
      this.setState({
        isContractInspectContentSub1: true,
        isContractInspectContentSub2: true,
        isExamList: true
      })
      getContractLineItemDisplayAction(params)
        .then((res) => {
          if (res) {
            let data = {
              ...res,
              Subtask1: {
                ...res.Subtask1,
                insurer_unit_price: res.Subtask1.insurer_unit_price !== 0 ? res.Subtask1.insurer_unit_price?.toLocaleString() : '',
                office_unit_price: res.Subtask1.office_unit_price !== 0 ? res.Subtask1.office_unit_price?.toLocaleString() : '',
                organization_unit_price: res.Subtask1.organization_unit_price !== 0 ? res.Subtask1.organization_unit_price?.toLocaleString() : '',
                personal_1_unit_price: res.Subtask1.personal_1_unit_price !== 0 ? res.Subtask1.personal_1_unit_price?.toLocaleString() : '',
                personal_2_unit_price: res.Subtask1.personal_2_unit_price !== 0 ? res.Subtask1.personal_2_unit_price?.toLocaleString() : '',
                personal_3_unit_price: res.Subtask1.personal_3_unit_price !== 0 ? res.Subtask1.personal_3_unit_price?.toLocaleString() : '',
                GrandTotalUnitPrice: res.Subtask1.GrandTotalUnitPrice !== 0 ? res.Subtask1.GrandTotalUnitPrice?.toLocaleString() : '',

                insurer_consumption_tax: res.Subtask1.insurer_consumption_tax !== 0 ? res.Subtask1.insurer_consumption_tax?.toLocaleString() : '',
                office_consumption_tax: res.Subtask1.office_consumption_tax !== 0 ? res.Subtask1.office_consumption_tax?.toLocaleString() : '',
                organization_consumption_tax: res.Subtask1.organization_consumption_tax !== 0 ? res.Subtask1.organization_consumption_tax?.toLocaleString() : '',
                personal_1_consumption_tax: res.Subtask1.personal_1_consumption_tax !== 0 ? res.Subtask1.personal_1_consumption_tax?.toLocaleString() : '',
                personal_2_consumption_tax: res.Subtask1.personal_2_consumption_tax !== 0 ? res.Subtask1.personal_2_consumption_tax?.toLocaleString() : '',
                personal_3_consumption_tax: res.Subtask1.personal_3_consumption_tax !== 0 ? res.Subtask1.personal_3_consumption_tax?.toLocaleString() : '',
                GrandTotalTax: res.Subtask1.GrandTotalTax !== 0 ? res.Subtask1.GrandTotalTax?.toLocaleString() : '',

                Expression_12: res.Subtask1.Expression_12 !== 0 ? res.Subtask1.Expression_12?.toLocaleString() : '',
                Expression_13: res.Subtask1.Expression_13 !== 0 ? res.Subtask1.Expression_13?.toLocaleString() : '',
                Expression_14: res.Subtask1.Expression_14 !== 0 ? res.Subtask1.Expression_14?.toLocaleString() : '',
                Expression_15: res.Subtask1.Expression_15 !== 0 ? res.Subtask1.Expression_15?.toLocaleString() : '',
                Expression_16: res.Subtask1.Expression_16 !== 0 ? res.Subtask1.Expression_16?.toLocaleString() : '',
                Expression_17: res.Subtask1.Expression_17 !== 0 ? res.Subtask1.Expression_17?.toLocaleString() : '',
                Expression_18: res.Subtask1.Expression_18 !== 0 ? res.Subtask1.Expression_18?.toLocaleString() : '',
              },
              Subtask2: {
                ...res.Subtask2,
                Tax: res.Subtask2.Tax ? parseFloat(res.Subtask2.Tax).toFixed(2) : '',
                own_course_label_number: res.Subtask2.own_course_label_number ? res.Subtask2.own_course_label_number + '枚' : ''
              }
            }

            this.setState({
              objContract: data,
              ContractInspectContentSub1: res.ContractInspectContentSub1
            });

            console.log(this.state.ContractInspectContentSub1);

            this.formRef?.current?.setFieldsValue(data);
          }
        }).finally(() => this.setState({
          isContractInspectContentSub1: false,
          isContractInspectContentSub2: false,
          isExamList: false,
          isLoadPage: false
        }))
    }
  }

  handleSearch = (e) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        Search: e.target.value,
      }
    }, () => this.callAPILoadData(this.state.initParams));
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  render() {
    const { pagination, isContractInspectContentSub1, isContractInspectContentSub2, isExamList, childModal, objContract } = this.state;
    const formInstance = this.formRef?.current;
    const colorExpression_42 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_42 ? '#FF0000' : '#1c66b9' };
    const colorExpression_43 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_43 ? '#FF0000' : '#1c66b9' };
    const colorExpression_44 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_44 ? '#FF0000' : '#1c66b9' };
    const colorExpression_45 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_45 ? '#FF0000' : '#1c66b9' };
    const colorExpression_46 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_46 ? '#FF0000' : '#1c66b9' };
    const colorExpression_47 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_47 ? '#FF0000' : '#1c66b9' };
    return (
      <div className="contract-line-res-displays">
        <Form ref={this.formRef}>
          <Card title="契約情報一括照会">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="基本情報" key="1">
                <Row>
                  <Col span={12}>
                    <div className="div_label">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_19']) === '' ? '共通' : this.formRef.current?.getFieldValue(['Subtask1', 'Expression_19'])}</div>
                    <div className="box_inner_horizontal">
                      <div className="div_data">{this.formRef.current?.getFieldValue(['Subtask1', 'Li_ContractOrgCode'])}</div>
                      <Button
                        icon={<MoreOutlined />}
                        style={{ marginLeft: '8px', marginBottom: '10px', marginTop: '5px' }}
                        size="small"
                        disabled={!this.props.Li_ContractType > 0}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...childModal,
                              visible: true,
                              width: this.props.ContractType === 1 || this.props.ContractType === '1' ? '80%' : 1200,
                              component: (
                                // this.props.ContractType === 1 || this.props.ContractType === '1'
                                this.props.Li_ContractType == 1
                                  ? <WS0339001_InsurerInfoMaintain insurer_code={this.props.Li_ContractOrgCode} />
                                  : <WS2585001_OfficeInfoInquirySub Li_OfficeCode={this.props.Li_ContractOrgCode} Li_BranchCode={0} />
                              ),
                            },
                          })
                        }}
                      />
                      <div className="div_data">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_36'])}</div>
                    </div>
                    <div className="div_label">コース</div>
                    <div className="box_inner_horizontal">
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask1', "medical_exam_course"]) !== "" &&
                          this.formRef.current?.getFieldValue(['Subtask1', "Expression_39"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask1', "medical_exam_course"])
                          : <div>--</div>
                        }
                      </div>
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask1', "Expression_39"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask1', "Expression_39"])
                          : <div></div>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="div_label">他団体</div>
                    <div className="box_inner_horizontal">
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask1', "organization_code"]) !== "" &&
                          this.formRef.current?.getFieldValue(['Subtask1', "Expression_19"]) !== "" &&
                          this.formRef.current?.getFieldValue(['Subtask1', "Expression_10"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask1', "organization_code"])
                          :
                          <div>--</div>
                        }
                      </div>
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask1', "Expression_19"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask1', "Expression_19"])
                          :
                          <div></div>
                        }
                      </div>
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask1', "Expression_10"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask1', "Expression_10"])
                          :
                          <div></div>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={3}>
                    <div style={styleDivTitle}>契約金額</div>
                    <div style={styleDivTitle}>単価金額</div>
                    <div style={styleDivTitle}>消費税額</div>
                    <div style={styleDivTitle}>合計金額</div>
                  </Col>
                  <Col span={3}>
                    <div style={styleDivTitle}>保険者</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'insurer_unit_price'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'insurer_consumption_tax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_12'])}</div>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_43}>事業所</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'office_unit_price'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'office_consumption_tax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_13'])}</div>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_44}>他団体</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'organization_unit_price'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'organization_consumption_tax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_14'])}</div>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_45}>個人１</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'personal_1_unit_price'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'personal_1_consumption_tax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_15'])}</div>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_46}>個人２</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'personal_2_unit_price'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'personal_2_consumption_tax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_16'])}</div>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div style={colorExpression_47}>個人３</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'personal_3_unit_price'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'personal_3_consumption_tax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_17'])}</div>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div style={styleDivTitle}>合　計</div>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'GrandTotalUnitPrice'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItem}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'GrandTotalTax'])}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue(['Subtask1', 'Expression_18'])}</div>
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>

              <Tabs.TabPane tab="オプション" key="2">
                <Table
                  size='small'
                  bordered
                  dataSource={formInstance?.getFieldValue('ContractInspectContentSub1')}
                  rowKey={record => record?.id}
                  loading={isContractInspectContentSub1}
                  scroll={{ x: 'max-content', y: 500 }}
                  pagination={this.state.ContractInspectContentSub1.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                >
                  <Table.Column
                    title="セット情報"
                    dataIndex="set_short_name"
                    render={(text, record, index) => {
                      return <>
                        <Space>
                          <img src={GetImage(record.Expression_14)} alt='icon' />
                          <span>{text}</span>
                        </Space>
                      </>
                    }} />
                  <Table.Column
                    width={100}
                    title="保険者"
                    dataIndex="insurer_total_price"
                    render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                  />
                  <Table.Column
                    width={100}
                    title="事業所"
                    dataIndex="office_total_price"
                    render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                  />
                  <Table.Column
                    width={100}
                    title="他団体"
                    dataIndex="organization_total_price"
                    render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                  />
                  <Table.Column
                    width={100}
                    title="個人"
                    dataIndex="personal_total"
                    render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                  />
                  <Table.Column
                    width={100}
                    title="合計"
                    dataIndex="SetSum"
                    render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                  />
                  <Table.Column
                    width={40}
                    render={(text, record, index) => (
                      <Dropdown size='small' trigger='click' overlay={() => (
                        <Menu >
                          <Menu.Item key='1' onClick={() => (
                            this.setState({
                              childModal: {
                                ...childModal,
                                visible: true,
                                width: 900,
                                component: (<WS0333001_SetIncludesQuery
                                  Li_StartDate={record.Li_ContractStartDate}
                                  Li_SetCode={record.set_code}
                                  Li_CourseCode={record.medical_exam_course}
                                  onRow={record}
                                  batch={true}
                                  onFinishScreen={(data) => {
                                    this.setState({
                                      childModal: {
                                        ...childModal,
                                        visible: false,
                                      },
                                    });
                                  }}
                                />),
                              },
                            })
                          )}>照会</Menu.Item>
                          <Menu.Item
                            key='2'
                            hidden={!record.Vl4StsContractInspectCondition}
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '60%',
                                  component: (
                                    <WS0605130_ContractInspectCondition
                                      Li_ContractType={this.props.Li_ContractType}
                                      Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                                      Li_ContractStartDate={this.props.Li_ContractStartDate}
                                      Li_ContractNum={this.props.Li_ContractNum}
                                      Li_SetCode={record.JB_set_code}
                                      onClick={() => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}>条件照会</Menu.Item>
                        </Menu>
                      )}><Button size='small' icon={<MoreOutlined />}></Button></Dropdown>
                    )} />
                </Table>
              </Tabs.TabPane>

              <Tabs.TabPane tab="条件・備考等" key="3">
                <Row>
                  <Col span={12}>
                    <div className="div_label">条　件</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_21"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_21"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">性　別</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_22"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_22"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">続　柄</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_28"]) !== null ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_28"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">院　内</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_29"]) !== null ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_29"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">時間区</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_26"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_26"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">ｎ次</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_30"]) !== null ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_30"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">その他</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_31"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_31"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">年　齢</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_35"]) !== null ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_35"])
                        : <div>--</div>
                      }
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="div_label">税区分</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_20"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_20"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">消費税</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Tax"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Tax"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">端数処理</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_24"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_24"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">ﾗﾍﾞﾙ枚数</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "own_course_label_number"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "own_course_label_number"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">未　収</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_25"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_25"])
                        : <div>--</div>
                      }
                    </div>
                    <div className="div_label">年齢計算</div>
                    <div className="box_inner_horizontal">
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask2', "Expression_23"]) !== "" &&
                          this.formRef.current?.getFieldValue(['Subtask2', "Expression_11"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask2', "Expression_23"])
                          : <div>--</div>
                        }
                      </div>
                      <div className="div_data_horizontal">
                        {this.formRef.current?.getFieldValue(['Subtask2', "Expression_11"]) !== "" ?
                          this.formRef.current?.getFieldValue(['Subtask2', "Expression_11"])
                          : <div></div>
                        }
                      </div>
                    </div>
                    <div className="div_label">備　考</div>
                    <div className="div_data_horizontal">
                      {this.formRef.current?.getFieldValue(['Subtask2', "Expression_11"]) !== "" ?
                        this.formRef.current?.getFieldValue(['Subtask2', "Expression_32"])
                        : <div>--</div>
                      }
                    </div>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                // icon={<EditOutlined />}
                onClick={() =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 550,
                      component: (
                        <WS0605140_ExaminationSearch
                          contractType={this.props.Li_ContractType}
                          ContractStartDate={this.props.Li_ContractStartDate}
                          ContractOrgCode={this.props.Li_ContractOrgCode}
                          ContractNum={this.props.Li_ContractNum}
                          medical_exam_course={this.formRef.current?.getFieldValue(['Subtask1', "medical_exam_course"])}
                          onFinishScreen={output => {
                            this.getContentInput();
                            this.closeModal();
                            this.props.onFinishScreen()
                          }}
                        />
                      )
                    }
                  })
                }
              >検査情報検索</Button>
              <Button
                type="primary"
                icon={<EditOutlined />}
                disabled={this.props.screen === 'WS0309017_ContractItemSub'}
                onClick={() =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      centered: true,
                      width: 750,
                      component: (
                        <WS0309017_ContractItemSub
                          Li_ContractType={Number(this.props.Li_ContractType)}
                          Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                          Li_ContractStartDate={this.props.Li_ContractStartDate}
                          Li_ContractNum={this.props.Li_ContractNum}
                          screen={'WS0605127_ContractLineItemDisplay'}
                          onFinishScreen={output => {
                            this.callAPILoadData();
                          }}
                        />
                      )
                    }
                  })
                }
              >編集</Button>
            </div>
          </Card>
          {/* <Card>
              <hr style={{ border: '1px solid #F0F0F0', marginBottom: '1.2rem' }} />
              <Button type="primary" style={{ float: "right" }} onClick={() => {
                this.setState({
                  childModal: {
                    ...childModal,
                    visible: true,
                    width: 700,
                    component: (<WS0309017_ContractItemSub
                      Li_ContractType={this.props.Li_ContractType}
                      Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                      Li_ContractStartDate={this.props.Li_ContractStartDate}
                      Li_ContractNum={this.props.Li_ContractNum}

                      onFinishScreen={(data) => {
                        this.setState({
                          childModal: {
                            ...childModal,
                            visible: false,
                          },
                        });
                      }}
                    />),
                  },
                })
              }}>
                変更
              </Button>
            </Card> */}
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0605127_ContractLineItemDisplay);
