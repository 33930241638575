import axios from "configs/axios";
import ApiPaths from "../constants/ApiPaths";

const CsrfService = {
  async CreateCSRFCookie() {
    const res = await axios.get(ApiPaths.SANCTUM.CSRF_COOKIE);
    if (res.status === 204) {
      return res;
    } else {
      console.error("CSRF status is NOT 204.")
      throw new Error()
    }
  }
};

export default CsrfService;
