import axios from "configs/axios";

const groupPath = "/api/specific-health-data-xml-output";

const APP_LIST = {
  getScreenData: `${groupPath}/xml-document-query-select-sub`,
  XmlCreate: `${groupPath}/output-confirm/xml-create`,
  xmlCheck: `${groupPath}/output-confirm/xml-check`,
  downloadCSVFile: `${groupPath}/output-confirm/download-csv-file`,
};

const XmlDocumentQuerySelectSub = {
  async GetScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async XmlCreate(params) {
    return axios.post(APP_LIST.XmlCreate, params);
  },
  async xmlCheck(params) {
    return axios.post(APP_LIST.xmlCheck, params);
  },
  async downloadCSVFile(params) {
    return axios.get(APP_LIST.downloadCSVFile, { params })
  },
};

export default XmlDocumentQuerySelectSub;
