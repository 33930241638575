import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, message } from 'antd'
import PrintStyleInquiryAction from 'redux/ResultOutput/CourseSpecificStardStyleSetting/PrintStyleInquiry.action.js'
class WS0445007_PrintStyleInquiry extends React.Component {
  static propTypes = {
    Lo_standard_printing_style: PropTypes.any,
    Lo_format_name: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '印刷様式照会'

    this.state = {
      dataSource: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount = () => {
    PrintStyleInquiryAction.index()
      .then((res) => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
  }

  handleRowSelected = (record) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lo_standard_printing_style: record.style_code,
        Lo_format_name: record.format_name,
      })
    }
  }

  render() {
    return (
      <div className='print-style-inquiry'>
        <Card title='印刷様式選択'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            scroll={{ x: 'max-content', y: 500 }}
            onRow={(record, index) => ({
              onDoubleClick: (e) => {
                this.handleRowSelected(record)
              },
            })}
          >
            <Table.Column
              title='様式'
              dataIndex='style_code'
              className='column-size-5'
            />
            <Table.Column
              title='様式名称'
              dataIndex='format_name'
            />
          </Table>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0445007_PrintStyleInquiry)
