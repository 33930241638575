import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import axios from "configs/axios";
import { Card, Checkbox, Form, Popconfirm, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Color from "constants/Color";



const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
/** Program [1304] CompiledClassify V4-TMS00030:取りまとめ区分 Prg_1280.xml */
class WS1304001_CompiledClassify extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // document.title = "取りまとめ区分";
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      listID: [],
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      initObj: {},
      count: "a",
    }; this.onFinish = this.onFinish.bind(this);
  }


  componentDidMount() {
    const params = {};
    this.loadMainSource(params);
  }

  loadMainSource = (params) => {
    this.setState({ isLoading: true });

    axios.get('/api/compiled-classify/compiled-classify', { params })
      .then(res => {
        this.setState({ dataSource: res.data });
        this.formRef.current.setFieldsValue({
          MainSource: res.data.MainSource,
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }
  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    axios.post('/api/compiled-classify/compiled-classify', params)
      .then((res) => {
        console.log(res.data);
        this.loadMainSource(params);

      })
      .catch(err => { })
      .finally(() => this.setState({ isLoading: false }))
  }
  onFinish(values) {
    const params = values;
    console.log(params)
    this.callAPILoadData(params)
  }

  renderRightClick = (record) => {
    const {
      delete_flag,
      integrated_destination_manageme,
      integrated_order,
      invoice_number,
      billing_management_number,
      identification,
      W1_billing_manage_num,
      W1_identify
    } = record;
    // IF(delete_flag=0,IF(invoice_number=0,'削除','欠損'),IF(integrated_order=0,'復元',''))
    let eventName3 =
      delete_flag === 0
        ? invoice_number === 0 ? '削除' : '欠損'
        : integrated_order === 0 ? '復元' : '';
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            this.callModal({ Li_BillingManageNum: '' }, '70%', 'WS0963001_InvoiceMaintain')
          }}
        >新規</Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            // NOT( identification=1 OR billing_management_number=2 OR billing_management_number=3)
            !(identification === 1 || billing_management_number === 2 || billing_management_number === 3)
              ? this.callModal({ Li_BillingManageNum: billing_management_number }, '70%', 'WS0963001_InvoiceMaintain')
              : this.callModal({ Li_BillingManageNum: billing_management_number }, '70%', 'WS0952001_CounterPayment')
          }}
        >修正</Menu.Item>
        <Menu.Item
          key="3"
          hidden={!eventName3}
          onClick={() => {
            const params = {
              invoice_number,
              W1_identify,
              W1_billing_manage_num,
              delete_flag: eventName3 === '復元' || eventName3 === ''
                ? 0
                : eventName3 === '削除' ? 1 : 2
            }
            this.MissingOrDeleted(params)
          }}
        >{eventName3}</Menu.Item>
        {/* IF(delete_flag=0 AND integrated_destination_manageme>0 AND integrated_order=0,'統合解除','') */}
        <Menu.Item
          key="4"
          hidden={!(delete_flag === 0 && integrated_destination_manageme > 0 && integrated_order === 0)}
          onClick={() => {
            this.callModal({ Li_Identify: W1_identify, Li_BillingManageNum: W1_billing_manage_num }, '40%', 'WS2626028_IntegrationReleased')
          }}
        >統合解除</Menu.Item>
      </Menu >
    )
  }

  deleteData(record) {
    this.setState({ isLoading: true });
    axios.post('/api/compiled-classify/compiled-classify/delete', { id: record.id })
      .then(() => this.loadMainSource())
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  render() {
    return (
      <div className='invoice'>
        <Card title={document.title}>
          <Form
            ref={this.formRef}
            initialValues={{
              Outstanding: 2,
              Target: 0,
              ClaimIdentify: 0,
              ClaimYearsChar: moment()
            }}>
            <Row gutter={24} className='mt-3'>
              <Col span={24}>
                <Table bordered size='small'
                  dataSource={this.formRef.current?.getFieldValue('MainSource')}
                  loading={this.state.isLoading}
                  pagination={this.state.pagination}

                  rowKey={(record) => record.id}
                >
                  <Table.Column title="取りまとめ区分" dataIndex="compiled_division" align='center' showSorterTooltip={false} sorter={(a, b) => a.compiled_division - b.compiled_division} render={(value, record) => {
                    let index = this.findIndexByID(this.state.dataSource.MainSource, record.id);
                    return (
                      <>
                        <Form.Item name={['MainSource', index, 'id']} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={['MainSource', index, 'compiled_division']}>
                          <Input />
                        </Form.Item>
                      </>
                    )
                  }} />
                  <Table.Column title="名  称" dataIndex="name" align='center' showSorterTooltip={false} sorter={(a, b) => a.name.localeCompare(b.name, 'jp')} render={(value, record) => {
                    let index = this.findIndexByID(this.state.dataSource.MainSource, record.id);
                    return (
                      <Form.Item name={['MainSource', index, 'name']}>
                        <Input />
                      </Form.Item>
                    )
                  }} />
                  <Table.Column title={<Button type="primary" size="small" icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => {
                    const { count } = this.state;
                    const newData = {
                      id: count,
                      compiled_division: "",
                      name: "",
                    };
                    this.setState({
                      ...this.state,
                      count: count + 1,
                      dataSource: {
                        MainSource: [
                          newData,
                          ...this.formRef.current.getFieldValue('MainSource'),
                        ]
                      }
                    });
                    this.formRef.current.setFieldsValue({
                      MainSource: [
                        newData,
                        ...this.formRef.current.getFieldValue('MainSource'),
                      ]
                    });
                    this.forceUpdate();
                  }} />} dataIndex="act" align='center' render={(value, record, index) => (
                    <>
                      <Button size='small' style={{ border: 'none', }}
                        icon={<SaveOutlined style={{ color: 'green' }} />}
                        onClick={() => this.onFinish({ MainSource: this.formRef.current.getFieldValue('MainSource') })}
                      ></Button>
                      <Button
                        size="small"
                        danger
                        style={{ border: 'none' }}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            content: "消去してもよろしいですか？",
                            okText: "はい",
                            cancelText: "いいえ",
                            onOk: () => this.deleteData(record)
                          });
                        }}
                      ></Button>
                    </>
                  )} />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const { Search } = Input;

const onSearch = value => console.log(value);

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WS1304001_CompiledClassify)
