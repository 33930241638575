import React from "react";
import { connect } from "react-redux";
import moment from 'moment'
import { Card, Form, Input, Select, Button, Table, Space, Modal, message, Menu, Dropdown, } from "antd";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable from "components/Commons/ModalDraggable";
import { SearchOutlined, MoreOutlined } from '@ant-design/icons'

import CreateTestForMedicalExamInfoAction from "redux/Others/CreateTestForMedicalExamInfo/CreateTestForMedicalExamInfo.action";
import { VisitsInspectConsultInquirySubAction } from 'redux/InputBusiness/ProgressSetting/ConsultInquirySub.actions'

import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS0612001_PersonalActionItemTreeDisplay from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0612001_PersonalActionItemTreeDisplay.jsx';

import WS0811001_CommunicationRosterOutput from "pages/KK_ResultOutput/V4KK0300000_CommunicationRosterOutput/WS0811001_CommunicationRosterOutput.jsx";

import WS2528001_ReserveStatusSearch from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2528001_ReserveStatusSearch";
import WS2537501_acceptablePersonalReserveProcessMock from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537501_acceptablePersonalReserveProcessMock.jsx';

import WS3020036_CoupledPrintInstruction from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction";
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx'

import WS2249005_AutoCreate from "./WS2249005_AutoCreate";

class WS2249001_CreateTestForMedicalExamInfo extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'テスト用受診情報作成';

    this.state = {
      isLoading: false,
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      iniObj: {},
      initParams: {
        DateChar: moment(new Date()).format('YYYY/MM/DD'),
        ReserveNumAndPersonalNum: '',
        FacilityType: 0,
        PersonalId: null,
        receiptNumber: null,
      },
    };

    this.searchDisplayList = this.searchDisplayList.bind(this)
  }

  componentDidMount = () => {
    this.formRef?.current?.setFieldsValue({
      DateFChar: moment(new Date()).format('YYYY/MM/DD'),
      DateTChar: moment(new Date()).format('YYYY/MM/DD'),
      StatusFlag: 4,
    })
  };

  searchDisplayList = () => {
    this.setState({ isLoading: true })

    let DateFChar = this.formRef.current.getFieldValue('DateFChar');
    let DateTChar = this.formRef.current.getFieldValue('DateTChar');
    let DateF = moment(DateFChar).format('YYYY/MM/DD');
    let DateT = moment(DateTChar).format('YYYY/MM/DD');

    if (!DateFChar || !DateTChar) {
      return Modal.error({
        title: '受診日を選択してください',
        okText: 'OK'
      })
    }

    if (DateF > DateT) {
      return Modal.error({
        title: '受診日の範囲指定に誤りがあります',
        okText: 'OK'
      })
    }

    let params = {
      Li_PersonalNum: this.formRef.current?.getFieldValue('PersonalNum'),
      Li_DateF: DateF,
      Li_DateT: DateT,
      Li_Course: this.formRef.current?.getFieldValue('CourseCode'),
      Li_OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      Li_StateFlag: this.formRef.current?.getFieldValue('StatusFlag'),
    }

    CreateTestForMedicalExamInfoAction.displayList(params)
      .then(res => {
        console.log(res)
        this.setState({
          dataSource: res,
        })
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
      })
      .finally(() => { this.setState({ isLoading: false }) })
  }

  VisitsInspect = (ReserveNum) => {
    VisitsInspectConsultInquirySubAction({ Li_ReserveNum: ReserveNum })
      .then(res => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 'fit-content',
            component:
              <WS0612001_PersonalActionItemTreeDisplay
                Lio_Expansion={true}
                onFinishScreen={() => {
                  this.closeModal()
                }}
              />
            ,
          },
        })
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  render() {
    return (
      <div className="create-test-for-medical-exam-info">
        <Card className="mb-2" title="テスト用受診情報作成">
          <div className='box_inner_vertical'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_inner_vertical'>
                    <Form.Item label="受診日" style={{ marginBottom: 0 }}>
                      <Space>
                        <Form.Item name="DateFChar" style={{ marginBottom: 0 }}>
                          <VenusDatePickerCustom formRefDatePicker={this.formRef} format='YYYY/MM/DD' />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item name="DateTChar" style={{ marginBottom: 0 }}>
                          <VenusDatePickerCustom formRefDatePicker={this.formRef} format='YYYY/MM/DD' />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item name='PersonalNum' label='個　人' style={{ marginBottom: 0 }}>
                      <Input.Search
                        allowClear={true}
                        className='input-search-size-allow-clear-4'
                        onPressEnter={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              component: (
                                <WS0248001_PersonalInfoSearchQuery
                                  date={moment(new Date()).format('YYYY/MM/DD')}
                                  searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                  onFinishScreen={({ Lo_PersonalNumId }) => {
                                    this.formRef.current.setFieldsValue({
                                      PersonalNum: Lo_PersonalNumId,
                                    })
                                    this.forceUpdate()
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 'fit-content',
                                component: (
                                  <WS0248001_PersonalInfoSearchQuery
                                    date={moment(new Date()).format('YYYY/MM/DD')}
                                    searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                    onFinishScreen={({ Lo_PersonalNumId }) => {
                                      this.formRef.current.setFieldsValue({
                                        PersonalNum: Lo_PersonalNumId,
                                      })
                                      this.forceUpdate()
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item name='OfficeCode' label='事業所' style={{ marginBottom: 0 }}>
                      <Input.Search
                        className='input-search-size-allow-clear-4'
                        type='text'
                        allowClear={true}
                        onPressEnter={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              component: (
                                <WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
                                  onFinishScreen={({
                                    Lio_OfficeCode,
                                    Lio_BranchStoreCode,
                                  }) => {
                                    this.formRef.current.setFieldsValue({
                                      OfficeCode: Lio_OfficeCode,
                                      BranchStoreCode: Lio_BranchStoreCode,
                                    })
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 'fit-content',
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
                                    onFinishScreen={({
                                      Lio_OfficeCode,
                                      Lio_BranchStoreCode,
                                    }) => {
                                      this.formRef.current.setFieldsValue({
                                        OfficeCode: Lio_OfficeCode,
                                        BranchStoreCode: Lio_BranchStoreCode,
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item name='CourseCode' label='コース' style={{ marginBottom: 0 }}>
                      <Input.Search
                        allowClear={true}
                        className='input-search-size-allow-clear-4'
                        onPressEnter={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              component: (
                                <WS0265001_BasicCourseInquiry
                                  onFinishScreen={(value) => {
                                    this.formRef.current?.setFieldsValue({
                                      CourseCode: value.Lo_CourseCode,
                                    });
                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 'fit-content',
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={(value) => {
                                      this.formRef.current?.setFieldsValue({
                                        CourseCode: value.Lo_CourseCode,
                                      });
                                      this.closeModal();
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item name="StatusFlag" label="状　態" style={{ marginBottom: 0 }}>
                      <Select style={{ width: 120 }}>
                        <Select.Option value={4}>全て</Select.Option>
                        <Select.Option value={0}>予約 </Select.Option>
                        <Select.Option value={1}>受付 </Select.Option>
                        <Select.Option value={2}>保留 </Select.Option>
                        <Select.Option value={3}>キャンセル </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() =>
                        this.searchDisplayList()
                      }
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              bordered
              size='small'
              rowKey={(record) => record.id}
              dataSource={this.state.dataSource}
            >
              <Table.Column title="受診日" dataIndex="visit_date_on"/>
              <Table.Column title="受付No" dataIndex="receipt_number"/>
              <Table.Column title="状態" dataIndex="Expresstion_29"/>
              <Table.Column title="個人番号" dataIndex="personal_number_id"/>
              <Table.Column title="氏名" dataIndex="Expresstion_23"/>
              <Table.Column title="生年月日" dataIndex="Expresstion_24"/>
              <Table.Column title="性別" dataIndex="Expresstion_25"/>
              <Table.Column title="契約" dataIndex="contract_short_name"/>
              <Table.Column title="事業所" dataIndex="office_kanji_name"/>
              <Table.Column
                // className='column-size-5'
                width={45}
                align='center'
                fixed='right'
                render={(value, record) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='menu-1'
                            hidden={record.Expresstion_29 != '受付'}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: 'fit-content',
                                  visible: true,
                                  component: (
                                    <WS2637001_OverallResultDisplayInput
                                      Li_ReserveNum={record.reservation_number}
                                      protectFlag={record.protectFlag}
                                      onUpdate={() => {
                                        // 子モーダルでテーブルが更新された為、受診者一覧の最新を取得する
                                        this.searchDisplayList()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            結果入力
                          </Menu.Item>
                          <Menu.Item
                            key='menu-2'
                            onClick={() => {
                              this.VisitsInspect(record.reservation_number)
                            }}
                          >
                            健診内容
                          </Menu.Item>
                          <Menu.Item
                            key='menu-3'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS2528001_ReserveStatusSearch
                                      onFinishScreen={(value) => {
                                        console.log(value)
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >
                            予約状況
                          </Menu.Item>
                          <Menu.Item
                            key='menu-4'
                            hidden={record.Expresstion_29 == '受付'}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  centered: true,
                                  component:
                                    <WS2537501_acceptablePersonalReserveProcessMock
                                      Li_iniObj={this.state.iniObj}
                                      Li_CourseLevel={record.course_level}
                                      Li_ReserveNum={record.reservation_number}
                                      Li_PersonalNum={record.personal_number_id}
                                      Li_Date={this.state.initParams.DateChar}
                                      Li_Getctxname={''}
                                      Li_ProcessDivision={''}
                                      Li_Option={''}
                                      Li_Child={true}
                                      onSendMirais={() => {
                                        this.searchDisplayList()
                                      }}
                                      onFinishScreen={() => {
                                        this.searchDisplayList()
                                        this.closeModal()
                                      }}
                                    />
                                },
                              })
                            }}
                          >
                            受付
                          </Menu.Item>
                          <Menu.Item
                            key='menu-5'
                            hidden={record.Expresstion_29 != '受付'}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS3020036_CoupledPrintInstruction
                                      Li_CourseLevel={record.course_level}
                                      Li_ReserveNum={record.reservation_number}
                                      onFinishScreen={(value) => {
                                        console.log(value)
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >
                            結果表
                          </Menu.Item>
                          <Menu.Item
                            key='menu-6'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS0811001_CommunicationRosterOutput
                                      onFinishScreen={(value) => {
                                        console.log(value)
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >
                            連名簿
                          </Menu.Item>
                          <Menu.Item
                            key='menu-7'
                            hidden={record.Expresstion_29 != '受付'}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS2249005_AutoCreate
                                      Li_CourseLevel={record.course_level}
                                      Li_ReserveNum={record.reservation_number}
                                      onFinishScreen={(value) => {
                                        console.log(value)
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >
                            自動生成
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />} />
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2249001_CreateTestForMedicalExamInfo);
