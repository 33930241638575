///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col, Input, Form, Table, Tabs, Button, Space, Card, List } from 'antd'
import { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons'

import man from 'assets/img/性別-男性.png'
import woman from 'assets/img/性別-女性.png'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0343001_PersonalInfoMaintain from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess'
import WS2586020_ConsultHistorySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2586020_ConsultHistorySub'
import moment from 'moment-timezone'
import WSFileManager_FileManager from 'pages/ZZ_Others/CUSTOMIZE_Custom/WSFileManager_FileManager'
import MagicXpaFunc from 'helpers/MagicXpaFunc'
import PersonalInfoInquirySubAction from 'redux/CooperationRelated/InspectRequestMain/PersonalInfoInquirySub.actions.js'

// タブの中にあるテーブルの高さ、揃えないと変な挙動をする
const heightSize = 200

class WS2584019_PersonalInfoInquirySub extends React.Component {
  static propTypes = {
    Li_PersonalNum: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '個人情報照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      personData: {},
    }

  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 親画面でthis.props.Li_PersonalNumが変更された時の処理
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.Li_PersonalNum !== this.props.Li_PersonalNum) {
      // 個人番号変更
      this.index()
    }
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      Li_PersonalNum: this.props.Li_PersonalNum,
    }

    PersonalInfoInquirySubAction.index(params)
      .then((res) => {
        if (res) {
          this.setState({
            personData: res,
          })
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div className='personal-info-inquiry-sub'>
        <Form ref={this.formRef} >
          <Card title={'個人情報照会' + ' [' + this.props?.Li_PersonalNum + ']'}>
            <div className='box_inner_horizontal'>
              <div className='box_inner_vertical' style={{ width: '50%' }}>
                <div className='box_border'>
                  <Row>
                    <Col span={18}>
                      <Form.Item name='personal_2'>
                        <span>{this.state.personData?.kana_name}</span>
                      </Form.Item>
                      <Form.Item name='personal_2'>
                        <span>{this.state.personData?.kanji_name}</span>
                      </Form.Item>
                      <Form.Item name='personal_2'>
                        <span>
                          {this.state.personData?.birthday_on
                            ? moment(this.state.personData?.birthday_on).format(
                              'NNNNy年MM月DD日'
                            )
                            : ''}{' '}
                          {this.state.personData?.birthday_on
                            ? '(' +
                            moment(this.state.personData.birthday_on).format(
                              'YYYY'
                            ) +
                            ')'
                            : ''}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <img
                        src={
                          this.state.personData?.sex === 1
                            ? man
                            : this.state.personData?.sex === 2
                              ? woman
                              : ''
                        }
                        style={{
                          position: 'absolute',
                          bottom: '30px',
                          right: '0',
                        }}
                      />
                    </Col>
                  </Row>
                  <div style={{ textAlign: 'right' }}>
                    <Space>
                      <Button
                        type='primary'
                        htmlType='submit'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              centered: true,
                              component: (
                                <WS2537001_PersonalReserveProcess
                                  Li_Date={this.props.Li_SelectDay ?? ''}
                                  Li_PersonalNum={this.props.Li_PersonalNum}
                                  Li_Child={true}
                                  onFinishScreen={() => {
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      >
                        予約
                      </Button>
                      <Button
                        type='primary'
                        htmlType='submit'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              component: (
                                <WS0343001_PersonalInfoMaintain
                                  Li_SelectDay={this.props.Li_SelectDay ?? ''}
                                  Li_PersonalNum={this.props.Li_PersonalNum}
                                  onFinishScreen={(output) => {
                                    if (output.flg === 1) {
                                      this.index()
                                    }
                                  }}
                                  onUpdate={(output) => {
                                    let param = {
                                      updateFlag: 1
                                    }
                                    // 登録した特記を予約状況に反映させるため
                                    this.index()
                                    if (this.props.Li_updateFlag === 1) {
                                      this.props.onFinishScreen(param)
                                    }
                                  }}
                                />
                              ),
                              width: '90vw',
                            },
                          })
                        }}
                      >
                        変更
                      </Button>
                      <Button
                        type='primary'
                        htmlType='submit'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              component: (
                                <WSFileManager_FileManager
                                  Params_2612={{
                                    Li_Division: '個人',
                                    Li_Identify: MagicXpaFunc.Str(
                                      MagicXpaFunc.Val(
                                        this.props.Li_PersonalNum,
                                        '10'
                                      ),
                                      '10P0'
                                    ),
                                  }}
                                />
                              ),
                              width: '500px',
                            },
                          })
                        }}
                      >
                        ﾌｫﾙﾀﾞ
                      </Button>
                    </Space>
                  </div>
                </div>

                <List
                  dataSource={this.state.personData?.personal_belongs}
                  itemLayout='vertical'
                  pagination={{
                    pageSize: 1,
                    size: 1,
                    showQuickJumper: false //※paginationが表示されない。。。
                  }}
                  size='small'
                  bordered
                  renderItem={(item) => (
                    <List.Item>
                      <Form.Item label='事業所'>
                        <span>{item?.office?.office_kanji_name}</span>
                      </Form.Item>
                      <Form.Item label='保険者'>
                        <span>{item?.insurer?.insurer_number}</span>
                        <span hidden={!item?.insurer?.insurer_kanji_name}> / </span>
                        <span>{item?.insurer?.insurer_kanji_name}</span>
                      </Form.Item>
                      <Form.Item label='保険証'>
                        <span>{item?.insurer_card_symbol}</span>
                        <span hidden={!item?.insurer_card_number}> / </span>
                        <span>{item?.insurer_card_number}</span>
                        <span hidden={!item?.insurerCard?.insurer_card_serial_number}> / </span>
                        <span>{item?.insurerCard?.insurer_card_serial_number}</span>
                      </Form.Item>
                      <Form.Item label='続　柄'>
                        <span>{item?.variousname?.name}</span>
                      </Form.Item>
                    </List.Item>
                  )}
                />
              </div>

              <div className='box_inner_vertical' style={{ width: '50%' }}>
                <div className='box_border'>
                  <Form.Item label='　　〒'>
                    <Input
                      readOnly
                      value={
                        this.state.personData?.personal_addresses?.postal_code
                      }
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='住　所'>
                    <Input
                      readOnly
                      value={this.state.personData?.personal_addresses?.address_1}
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='　　　'>
                    <Input
                      readOnly
                      value={this.state.personData?.personal_addresses?.address_2}
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='宛　名'>
                    <Input
                      readOnly
                      value={this.state.personData?.personal_addresses?.address}
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='ＴＥＬ'>
                    <Input
                      readOnly
                      value={
                        this.state.personData?.personal_addresses?.phone_number
                      }
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='　　　'>
                    <Input
                      readOnly
                      value={
                        this.state.personData?.personal_addresses
                          ?.cell_phone_number
                      }
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='メール'>
                    <Input
                      readOnly
                      value={this.state.personData?.personal_addresses?.email}
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                  <Form.Item label='　　　'>
                    <Input
                      readOnly
                      value={
                        this.state.personData?.personal_addresses?.mobile_mail
                      }
                      style={{ border: '0' }}
                    />
                  </Form.Item>
                </div>

                <Tabs
                  type='card'
                  defaultActiveKey={this.props.importancePersonal == 5 ? '2' : '1'}
                >
                  <Tabs.TabPane
                    tab='履歴'
                    key='1'>
                    <WS2586020_ConsultHistorySub
                      Li_PersonalNum={this.props.Li_PersonalNum}
                      hideTitle={true} //タイトルを非表示
                      scrollY={heightSize}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab='特記'
                    key='2'>
                    <Table
                      bordered
                      rowKey={record => record.id}
                      dataSource={this.state.personData?.personal_specials}
                      size='small'
                      pagination={false}
                      scroll={{ y: heightSize }}
                    >
                      <Table.Column
                        width={45}
                        dataIndex='importance'
                        render={(code, record, index) => {
                          let icon = null
                          switch (code) {
                            case 1:
                              icon = <InfoCircleOutlined style={{ color: '#1890ff' }} />
                              break
                            case 3:
                              icon = <WarningOutlined style={{ color: '#faad14' }} />
                              break
                            case 5:
                              icon = <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                              break
                            default:
                              break
                          }
                          return (
                            <div style={{ textAlign: 'center', fontSize: 16 }}>
                              {icon}
                            </div>
                          )
                        }}
                      />
                      <Table.Column title='特記メモ' dataIndex='content' />
                    </Table>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab='補足'
                    key='3'
                  >
                    <Table
                      bordered
                      rowKey={record => record.id}
                      dataSource={this.state.personData?.user_item_settings}
                      size='small'
                      pagination={false}
                      scroll={{ y: heightSize }}
                    >
                      <Table.Column
                        title='項目'
                        dataIndex='item'
                      />
                      <Table.Column
                        title='内容'
                        dataIndex='content'
                      />
                    </Table>
                  </Tabs.TabPane>
                </Tabs>

              </div>
            </div>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            centered={this.state.childModal.centered}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Form>
      </div >
    )
  }
}

export default WS2584019_PersonalInfoInquirySub
