import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Button, Card, Form, Table, message, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';

import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import InspectConditionSettingAction from "redux/AdvancePreparation/DocumentManageMaintain/InspectConditionSetting.action";

class WS0641021_InspectConditionSetting extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    InspectCondition: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '検査条件設定';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getDataTable();
  }

  componentDidUpdate(prevProps) {
    if (this.props.InspectCondition !== prevProps.InspectCondition) {
      this.getDataTable();
    }
  }

  getDataTable() {
    this.setState({ isLoadingTable: true })

    let params = {
      condition: this.props.InspectCondition
    }

    InspectConditionSettingAction.getDataTable(params)
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
          isLoadingTable: false,

          rowSelected: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeys: res && res.length > 0 ? [res[0].id] : [],
          indexTable: 0,
          old_W1_inspect_cd: res && res.length > 0 ? res[0].W1_inspect_cd : null,
        });
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  //検査条件の新規追加
  newAddTest() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            //項目を追加する場合
            onFinishScreen={(output) => {
              let outputItem = this.setTestItem(output)
              this.props.onFinishScreen(outputItem)
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  /**
   * 基本条件の追加用関数
   * @param {*} record
   * @returns
   */
  setTestItem(record) {
    let formItem = this.props.InspectCondition
    let arrayItem = []
    let duplication = ''
    let outputItem
    let zero_cd = null

    zero_cd = String(record.recordData.test_item_code).padStart(8, '0')

    if (formItem === '') {
      //フォームに何も登録されていない場合
      outputItem = zero_cd + ";"

    } else {
      //フォームに項目が登録されている場合
      formItem = formItem.substr(0, formItem.length - 1)
      arrayItem = formItem.split(',')
      arrayItem.push(zero_cd)
      arrayItem.sort()

      duplication = arrayItem.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplication.length === 0) {
        //重複がない場合
        arrayItem.join(',');
        outputItem = arrayItem + ";"

      } else {
        //重複がある場合
        message.error("検査コードが重複しています")

        //重複を削除したリスト
        arrayItem = arrayItem.filter((x, i, self) => self.indexOf(x) === i)
        arrayItem.join(',');
        outputItem = arrayItem + ";"
      }
    }
    return outputItem
  }

  /**
   * 基本条件の削除用関数
   */
  deleteTest(record) {
    let formItem = this.props.InspectCondition
    let arrayItem = ''
    let linkArrayItem = ''
    let outputItem = ''
    let zero_cd = null

    zero_cd = String(record.inspect_cd).padStart(8, '0')

    arrayItem = formItem.substr(0, formItem.length - 1)
    arrayItem = arrayItem.split(',')

    //削除対象の絞り込み・削除
    arrayItem.map((value, index) => {
      if (value === zero_cd) {
        arrayItem.splice(index, 1)
      }
    })

    arrayItem.sort()
    linkArrayItem = arrayItem.join(',')

    if (linkArrayItem === "") {
      //消去後、項目が残らない場合
      outputItem = ""

    } else {
      //消去後、項目が残る場合
      outputItem = linkArrayItem + ";"
    }

    this.props.onDelete(outputItem)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onFinish(values) {
  }

  render() {
    return (
      <div className="inspect-condition-setting">
        <Card title="検査条件設定">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              size='small'
              style={{ cursor: 'pointer' }}
              dataSource={this.state.dataSource}
              loading={this.state.isLoadingTable}
              pagination={false}
              bordered
              rowKey={(res) => res.id}
              scroll={{ x: 400, y: 600 }}
            >
              <Table.Column
                title="コード"
                dataIndex="inspect_cd"
                width={180}
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                title="名称"
                dataIndex="exam_name"
              />

              <Table.Column width={40}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Button size='small'
                      onClick={() => {
                        this.newAddTest()
                      }}
                      type="primary"
                      icon={<PlusOutlined />}>
                    </Button>
                  </div>
                }

                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="削除"
                            onClick={() => {
                              this.deleteTest(record)
                            }}
                          >削除
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  );
                }}
              />
            </Table >
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0641021_InspectConditionSetting);
