import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Select, Table, } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0412001_MasterListOutputSiteInfo extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力[部位情報]';

    this.state = {
      siteCodeF: 0,
      siteCodeT: 0,
      siteClassify: [],
      dataSource: [],
      indexTable: 0
    };
  }

  componentDidMount() {
    this.setState({ isLoadingTable: true })

    VenusApiRoutesV2.callApi("API000412001001", {})
      .then((res) => {
        this.setState({
          siteCodeF: res.SiteCodeF,
          siteCodeT: res.SiteCodeT,
          siteClassify: res.SiteClassify,
          isLoadingTable: false,
        });

        this.formRef.current.setFieldsValue({
          siteCodeF: res.SiteCodeF,
          siteCodeT: res.SiteCodeT,
          siteClassify: res.SiteClassify,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    VenusApiRoutesV2.callApi("API000412001002", {})
      .then((res) => {
        this.setState({
          dataSource: res? res : [],
          isLoadingTable: false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) { }
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="master-list-output-site-info">
        <Card className="mb-2" title="マスタ一覧出力[部位情報]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="siteCodeF"
              label="部位コード[F]"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="siteCodeT"
              label="部位コード[T]"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name=""
              label="部位分類"
            >
              <Select>
                <Select.Option value="">\</Select.Option>

              </Select>
            </Form.Item>

          </Form>

          <Table bordered 
            size="small"
            dataSource={this.state.dataSource} 
            loading={this.state.isLoadingTable}
            pagination={true}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="部位分類コード" dataIndex="site_classification" key="1" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification - b.site_classification}
            />
            <Table.Column title="部位分類名称" dataIndex="site_classification_name" key="2" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification_name.localeCompare(b.site_classification_name, 'ja')}
            />
            <Table.Column title="部位コード" dataIndex="site_code" key="3" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_code - b.site_code}
            />
            <Table.Column title="部位名称" dataIndex="site_name" key="4" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_name.localeCompare(b.site_name, 'ja')}
            />
            <Table.Column title="検索略名" dataIndex="search_short_name" key="5" 
              showSorterTooltip={false}
              sorter={(a, b) => a.search_short_name.localeCompare(b.search_short_name, 'ja')}
            />
            <Table.Column title="部位アドレス" dataIndex="site_address" key="6" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_address - b.site_address}
            />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0412001_MasterListOutputSiteInfo);
