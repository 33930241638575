import { message } from "antd";
import CourseSelectService from "services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/CourseSelectService";

export const getDataMainCosAction = () => {
    return CourseSelectService.getDataMainCosService()
}

export const getCourseSubInputAction = (params) => {
    return CourseSelectService.getCourseSubInputService(params)
}

export const getSetSubprocessAction = (params) => {
    return CourseSelectService.getSetSubprocessService(params)
}

export const saveAndUpdateCourseSubInputAction = (params) => {
    return CourseSelectService.saveAndUpdateCourseSubInputService(params)
}

export const saveAndUpdateCourseSetSubprocessAction = (params) => {
    return CourseSelectService.saveAndUpdateCourseSetSubprocessService(params)
}

export const BatchProcess_F10Action = (params) => {
    return CourseSelectService.BatchProcess_F10Service(params)
}






