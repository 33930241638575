import React from "react";
import { connect } from "react-redux";

import { Card, Table } from "antd";
import QueryInspectSetAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/QueryInspectSet.actions';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1176001_InspectSetInfo from "./WS1176001_InspectSetInfo";

const styleTable = {
  width: "80%",
  margin: 'auto'
}
class WS0181001_QueryInspectSet extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS71400:照会:検査セット';

    this.state = {
      tableData: [],
      isLoading: true,
      isLoadingTable: true,

      pageSize: 15,

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

    };
  }
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }
  componentDidMount() {
    this.getInit()
  }
  getInit = () => {
    this.setState({ isLoading: true, isLoadingTable: true })
    QueryInspectSetAction.getInit()
      .then(res => {
        this.setState({
          tableData: res ? res : [],
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))

  }
  onFinish(values) {

  }


  render() {
    return (
      <div className="query-inspect-set">
        <Card title="V4-VNS71400:照会:検査セット">
          <Table bordered 
            style={styleTable}

            dataSource={this.state.tableData}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '50%',
                      component: (
                        <WS1176001_InspectSetInfo
                          Li_test_set_code={record.test_set_code}
                          onFinishScreen={(output) => {
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  });
                }
              };
            }}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              pageSize: this.state.pageSize,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                this.setState({ pageSize: pageSize })
              },
              onShowSizeChange: (page, pageSize) => { },
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column align="center" title="コード" render={(value, record, index) => {
              return <div>{value.test_set_code}</div>
            }} />
            <Table.Column align="center" title="セット名称" render={(value, record, index) => {
              return <div>{value.test_set_name}</div>
            }} />

          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0181001_QueryInspectSet);
