import axios from "configs/axios";

const APP_LIST = {
  getInit: "/api/specific-health-tokuho-param-maintain",
  save: "/api/specific-health-tokuho-param-maintain/save",
  saveAndUpdateSpecificHealthTokuhoParamMaintain: '/api/specific-health-tokuho-param-maintain/save-and-update',
  deleteSpecificHealthTokuhoParamMaintain: '/api/specific-health-tokuho-param-maintain/delete',
  inputEventSpecificHealthTokuhoParamMaintain: '/api/specific-health-tokuho-param-maintain/input',
};

const SpecificHealthTokuhoParamMaintainService = {
  async getInit() {
    return axios.get(APP_LIST.getInit);
  },
  async save(data) {
    return axios.post(APP_LIST.save, { data: data })
      .then((res) => { return res.data.message })
      .catch((err) => { throw err.response.data.message });
  },
  async saveAndUpdateSpecificHealthTokuhoParamMaintainService(params) {
    return axios.post(APP_LIST.saveAndUpdateSpecificHealthTokuhoParamMaintain, params)
      .then((res) => { return res.data.message })
      .catch((err) => { throw err.response.data.message });
  },
  async deleteSpecificHealthTokuhoParamMaintainService(params) {
    return axios.delete(APP_LIST.deleteSpecificHealthTokuhoParamMaintain, { params })
      .then((res) => { return res.data.message })
      .catch((err) => { throw err.response.data.message });
  },
  async inputEventSpecificHealthTokuhoParamMaintainService(params) {
    return axios.post(APP_LIST.inputEventSpecificHealthTokuhoParamMaintain, params)
      .then((res) => { return res })
      .catch((err) => { throw err.response.data.message });
  },
};

export default SpecificHealthTokuhoParamMaintainService;
