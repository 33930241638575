import React from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Button, Card, Form, Input, Checkbox, Table, Space, Dropdown, Modal, Menu, message, InputNumber, Tooltip } from 'antd'
import { MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, PlusOutlined, InfoOutlined, FileTextOutlined, SaveOutlined, CheckOutlined } from '@ant-design/icons'
import { getScreenDataAction, deleteDataAction } from 'redux/InspectionMaintenance/InspectItemInfoMaintain/InspectItemInfoMaintain.actions'

import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0253001_InternalInspectItemSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS0253001_InternalInspectItemSearchQuery.jsx'
import WS2717011_InspectItem1ChangeSub from './WS2717011_InspectItem1ChangeSub'
import WS2716057_InspectByPatternCategoryDisplay from './WS2716057_InspectByPatternCategoryDisplay'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery.jsx'
import Menubar from 'components/Commons/Menubar'
import Color from 'constants/Color'
import WS2705001_AnotherInspectItemSettingCategory from '../V4KB0011000_AnotherInspectItemSettingCategory/WS2705001_AnotherInspectItemSettingCategory'
import WS1043001_InspectRequestConvertMasterMaintain from 'pages/KS_CooperationRelated/V4CP0002000_InspectRequestConvertMasterMaintain/WS1043001_InspectRequestConvertMasterMaintain'
import WS2700017_InspectItemConvertInternal from 'pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS2700017_InspectItemConvertInternal'
import WS0358001_InspectCmtInfoMaintain from '../V4MS0105000_ExamCmtInfoMaintain/WS0358001_InspectCmtInfoMaintain'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
class WS2716001_InspectItemInfoMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査項目情報保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      /*
       pagination: {
       defaultPageSize: 10,
       total: 1,
        size: 'small',
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1
        },
       */
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      initValue: {
        SearchChar: '',
        stsUsed: 0,
        InternalInspectCode: ''
      },
      dataSource: [],
      colorText: 208,

    }
    this.changeData = debounce(this.changeData, 1000)
  }

  componentDidMount = () => {

  }

  loadData = () => {
    let params = {
      SearchChar: this.formRef.current.getFieldValue('SearchChar'),
      stsUsed: this.formRef.current.getFieldValue('stsUsed') ? 1 : 0,
      InternalInspectCode: this.formRef.current.getFieldValue('InternalInspectCode'),
    }

    getScreenDataAction(params)
      .then(res => {
        if (res) {

          let data = res.data.map((item, index) => ({ ...item, id: index }))
          this.setState({
            dataSource: data,
          })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  handleChangeValue = (objChange) => {
    const obj = {
      ...this.state.initValue,
      ...objChange,
      page: 1
    }
    this.setState({
      initValue: obj
    })
    this.loadData(obj)
  }

  changeData = (obj) => {
    this.handleChangeValue(obj)
  }


  showWInternalInspectItemSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0253001_InternalInspectItemSearchQuery
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                internal_exam_code: output.Lio_InternalInspectCode,
                InternalInspectCode: output.Lio_InternalInspectCode,
                exam_name: output.recordData.exam_name
              })
              let colorText = 208
              if (output.Lio_InternalInspectCode % 100000 === 0) {
                colorText = 253
              } else if (output.Lio_InternalInspectCode % 10000 === 0) {
                colorText = 254
              } else if (output.Lio_InternalInspectCode % 1000 === 0) {
                colorText = 255
              }
              this.setState({ colorText })
              this.closeModal()
            }}
          />),
      },
    })
  }

  eventF4 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS2717011_InspectItem1ChangeSub
            Lio_InspectCode={0}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />),
      },
    })
  }

  eventF8 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0358001_InspectCmtInfoMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  eventF9 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS2705001_AnotherInspectItemSettingCategory
            record={true}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  eventF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1043001_InspectRequestConvertMasterMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  eventF11 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: '95%',
        component: (
          <WS2700017_InspectItemConvertInternal
            onFinishScreen={() => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  reviewScreen() {
    this.loadData(this.state.initValue)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='inspect-item-info-maintain'>
        <Card title='検査項目情報保守' className='mb-2'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.eventF4}>
                      新規
                    </Menu.Item>

                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.eventF8}>
                      ｺﾒﾝﾄ情報保守
                    </Menu.Item>

                    <Menu.Item key='3' style={{ width: '100%' }} onClick={this.eventF9}>
                      ｶﾃｺﾞﾘ別検査項目設定
                    </Menu.Item>

                    <Menu.Item key='4' style={{ width: '100%' }} onClick={this.eventF10}>
                      検査依頼変換
                    </Menu.Item>

                    <Menu.Item key='5' style={{ width: '100%' }} onClick={this.eventF11}>
                      検査項目変換
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item name='SearchChar' label='検索' style={{ marginBottom: 0 }}>
                  <Input maxLength={256}
                    onPressEnter={(e) => {
                      if (e.key === 'Enter') {
                        this.loadData()
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item name='stsUsed' valuePropName='checked' style={{ marginBottom: 0 }}>
                  <Checkbox
                  >使用</Checkbox>
                </Form.Item>

                <Form.Item name='InternalInspectCode' label='内部ｺｰﾄﾞ' style={{ width: 180, marginBottom: 0 }}>
                  <Input.Search
                    maxLength={8}
                    onChange={(event) => {
                      if (!event.target.value) {
                        this.formRef.current.setFieldsValue({ exam_name: '' })
                      }
                    }}
                    onSearch={this.showWInternalInspectItemSearchQuery}
                  />
                </Form.Item>
                <Form.Item name='exam_name' style={{ marginBottom: 0 }}>
                  <Input bordered={false} readOnly style={{ color: Color(this.state.colorText).Foreground }} />
                </Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    this.loadData()
                  }}
                >
                  <span className='btn_label'>
                    検索
                  </span>
                </Button>

              </div>
              <Table
                bordered
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                size='small'
                scroll={{ y: resizableTableScroll(160) }}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        centered: true,
                        width: 600,
                        component: (
                          <WS2717011_InspectItem1ChangeSub
                            Lio_InspectCode={record.test_item_code}
                            record={record}
                            onFinishScreen={() => {
                              this.reviewScreen()
                            }}
                          />
                        ),
                      },
                    })
                })}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              >

                <Table.Column title='コード' dataIndex='test_item_code' width={80} render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value === 0 ? null : value}</div>
                )} />
                <Table.Column title='略名' dataIndex='exam_short_name' width={120} />
                <Table.Column title='検査名称' dataIndex='exam_name' width={200} />
                <Table.Column title='タイプ' dataIndex='InspectType' width={60} />

                <Table.Column title='判定' dataIndex='StsJudgeCode' align='center' width={50}
                  render={(value, record, index) => (
                    <div >{!value ? '' : <CheckOutlined />}</div>
                  )} />

                <Table.Column title='日付' dataIndex='StsType' align='center' width={50} render={(value, record, index) => (
                  <div >{!value ? '' : <CheckOutlined />}</div>
                )} />


                <Table.Column title='コメント' dataIndex='exam_comment_code' width={80} render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value === 0 ? null : value}</div>
                )} />
                <Table.Column title='コメント内容' dataIndex='exam_comment_screen' width={160} />
                <Table.Column title='単位' dataIndex='unit' width={80} />
                <Table.Column title='内部ｺｰﾄﾞ' dataIndex='internal_exam_code' width={80} render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value === 0 ? null : value}</div>
                )} />
                <Table.Column title='内部検査名称' dataIndex='internalExamName' width={160} />
                <Table.Column title='使用' align='center' dataIndex='stsUse' width={40} />



                <Table.Column align='center' width={40} fixed='right'
                  title={<Button type='primary' size='small'
                    icon={<PlusOutlined />}

                    onClick={this.eventF4}></Button>}
                  render={(text, record, index) => (
                    <Space >
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item key='1' style={{ width: '100%' }} onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  centered: true,
                                  width: 600,
                                  component: (
                                    <WS2717011_InspectItem1ChangeSub
                                      Lio_InspectCode={record.test_item_code}
                                      record={record}
                                      onFinishScreen={() => {
                                        this.reviewScreen()
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}>修正
                            </Menu.Item>

                            <Menu.Item key='2' style={{ width: '100%' }} hidden={record.exam_comment_code !== '0'} onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  centered: true,
                                  width: 600,
                                  component: (
                                    <WS0274001_InspectCmtSearchQuery
                                      Lio_CmtClassify={record.exam_comment_code}
                                      onFinishScreen={(data) => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        })
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}>コメント照会
                            </Menu.Item>

                            <Menu.Item key='3' style={{ width: '100%' }} onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  centered: true,
                                  width: '60%',
                                  component: (
                                    <WS2716057_InspectByPatternCategoryDisplay
                                      Li_InspectCode={record.test_item_code}
                                      onFinishScreen={(data) => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        })
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}>カテゴリ表示
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size='small' icon={<MoreOutlined />}></Button>
                      </Dropdown>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2716001_InspectItemInfoMaintain)
