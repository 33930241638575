import axios from 'configs/axios'

const API_LIST = {
  index: '/api/create-document-batch/target-select-document',
  excelReport: '/api/create-document-batch/target-select-document/user-action-2',
  changeLogic: '/api/create-document-batch/target-select-document/change-logic',
  changeLogicAll: '/api/create-document-batch/target-select-document/change-logic-all',
}

const TargetSelectDocumentService = {
  async index(params) {
    // paramsの容量が大きい為、「post」を使用
    return axios.post(API_LIST.index, params)
  },

  async excelReport(params) {
    return axios.post(API_LIST.excelReport, params, { responseType: 'blob' })
  },

  async changeLogicService(params) {
    return axios.post(API_LIST.changeLogic, params)
  },

  async changeLogicAllService(params) {
    return axios.post(API_LIST.changeLogicAll, params)
  }
}

export default TargetSelectDocumentService
