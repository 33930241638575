import axios from 'configs/axios'

const apiPaths = {
  index: '/api/group-bookings/contract-info-inquiry',
  getScreenData: '/api/group-bookings/contract-info-inquiry/get-screen-data',
}

const ContractInfoInquiryService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
}

export default ContractInfoInquiryService
