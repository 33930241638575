import SupportItemInquiryService from "services/SpecificInsureMaintenance/InsureGuideCourse/SupportItemInquiryService";
import { message } from "antd";

const SupportItemInquiryAction = {
  getScreenData(data) {
    return SupportItemInquiryService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  index(data) {
    return SupportItemInquiryService.index(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
};

export default SupportItemInquiryAction;