import React from "react";
import { connect } from "react-redux";

import { Card, Form, Button, Select, message, Space } from "antd";
import DetailsExtractAction from 'redux/SpecificInsureGuide/SpecificCoerciveFingerXmlOutput/DetailsExtractAction.action';

class WS2656088_DetailsExtract extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '詳細抽出';

    this.state = {
      ExamPersonGroupHealthDivision: 0,
      Relationship: 9,
      CreateDivision1New2ReCreate: 3,
      Relationship_List: []
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let param = {
      ExamPersonGroupHealthDivision: 0,
      Relationship: 9,
      CreateDivision1New2ReCreate: 3
    }
    DetailsExtractAction.getScreenData(param)
      .then(res => {
        this.setState({
          CreateDivision1New2ReCreate: res.CreateDivision1New2ReCreate,
          ExamPersonGroupHealthDivision: res.ExamPersonGroupHealthDivision,
          Relationship: res.Relationship,
          Relationship_List: res.Relationship_List
        })
        this.formRef.current?.setFieldsValue({
          CreateDivision1New2ReCreate: res.CreateDivision1New2ReCreate,
          ExamPersonGroupHealthDivision: res.ExamPersonGroupHealthDivision,
          Relationship: res.Relationship
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  changeExamPersonGroupHealthDivision(event) {
    let ExamPersonGroupHealthDivision = event;
    this.formRef.current?.setFieldsValue({
      ExamPersonGroupHealthDivision: ExamPersonGroupHealthDivision
    })
    this.setState({
      ExamPersonGroupHealthDivision: ExamPersonGroupHealthDivision
    })
  }

  changeRelationship(event) {
    let Relationship = event;
    this.formRef.current?.setFieldsValue({
      Relationship: Relationship
    })
    this.setState({
      Relationship: Relationship
    })
  }

  changeCreateDivision1New2ReCreate(event) {
    let CreateDivision1New2ReCreate = event;
    this.formRef.current?.setFieldsValue({
      CreateDivision1New2ReCreate: CreateDivision1New2ReCreate
    })
    this.setState({
      CreateDivision1New2ReCreate: CreateDivision1New2ReCreate
    })
  }

  onFinish() {
    let param = {
      Lio_ExamPersonGroupHealthDivisi: this.state.ExamPersonGroupHealthDivision,
      Lio_Relationship: this.state.Relationship,
      Lio_CreateDivision1New2ReCreate: this.state.CreateDivision1New2ReCreate
    }
    this.props.onFinishScreen(param)
  }

  render() {
    return (
      <div className="details-extract">
        <Card title="詳細抽出">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "70px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                契約区分
              </Space>
              <Form.Item
                name="ExamPersonGroupHealthDivision"
              >
                <Select style={{ width: "130px" }} onChange={(event) => this.changeExamPersonGroupHealthDivision(event)}>
                  <Select.Option value={2}>集合契約</Select.Option>
                  <Select.Option value={1}>個別契約</Select.Option>
                  <Select.Option value={0}>全　　て</Select.Option>
                </Select>

              </Form.Item>
            </Space>

            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "70px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                続&emsp;&emsp;柄
              </Space>
              <Form.Item
                name="Relationship"
              >
                <Select style={{ width: "130px" }} onChange={(event) => this.changeRelationship(event)}>
                  <Select.Option value={9}>全て</Select.Option>
                  {this.state.Relationship_List?.map(value => (
                    <Select.Option value={value.node_code_name}>{value.name}</Select.Option>
                  ))}
                </Select>

              </Form.Item>
            </Space>

            <Space style={{ display: "flex" }}>
              <Space
                style={{
                  width: "70px",
                  justifyContent: "flex-end",
                  color: "#14468C",
                  fontWeight: "bold"
                }}
              >
                出力状態
              </Space>
              <Form.Item
                name="CreateDivision1New2ReCreate"
              >
                <Select style={{ width: "130px" }} onChange={(event) => this.changeCreateDivision1New2ReCreate(event)}>
                  <Select.Option value={1}>新　規</Select.Option>
                  <Select.Option value={2}>再作成</Select.Option>
                  <Select.Option value={3}>全　て</Select.Option>
                </Select>

              </Form.Item>
            </Space>

            <Form.Item
            >
              <Button style={{ float: 'right' }} type="primary" onClick={() => this.onFinish()}>確定</Button>
            </Form.Item>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2656088_DetailsExtract);
