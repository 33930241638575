import axios from 'configs/axios';

const apiPaths = {
  getSite1: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/get-site-1',
  getSite2: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/get-site-2',
  getfindings1: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/get-findings-1',
  getfindings2: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/get-findings-2',
  saveSite1: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/save-site-1',
  saveSite2: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/save-site-2',
  saveFindings1: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/save-findings-1',
  saveFindings2: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/save-findings-2',
  deleteSite1: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/delete-site-1',
  deleteSite2: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/delete-site-2',
  deleteFindings1: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/delete-findings-1',
  deleteFindings2: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-maintain/delete-findings-2',
};

const RadiographySiteFindingsMaintainService = {
  async getSite1(params) {
    return axios.get(apiPaths.getSite1, { params });
  },
  async getSite2(params) {
    return axios.get(apiPaths.getSite2, { params });
  },
  async getfindings1(params) {
    return axios.get(apiPaths.getfindings1, { params });
  },
  async getfindings2(params) {
    return axios.get(apiPaths.getfindings2, { params });
  },
  async saveSite1(params) {
    return axios.post(apiPaths.saveSite1, params);
  },
  async saveSite2(params) {
    return axios.post(apiPaths.saveSite2, params);
  },
  async saveFindings1(params) {
    return axios.post(apiPaths.saveFindings1, params);
  },
  async saveFindings2(params) {
    return axios.post(apiPaths.saveFindings2, params);
  },
  async deleteSite1(params) {
    return axios.delete(apiPaths.deleteSite1, { params });
  },
  async deleteSite2(params) {
    return axios.delete(apiPaths.deleteSite2, { params });
  },
  async deleteFindings1(params) {
    return axios.delete(apiPaths.deleteFindings1, { params });
  },
  async deleteFindings2(params) {
    return axios.delete(apiPaths.deleteFindings2, { params });
  },
};

export default RadiographySiteFindingsMaintainService;