import React from "react";
import { connect } from "react-redux";

import { Button, Card, Dropdown, Form, Input, Menu, Space, Table, Popconfirm } from "antd";
import { MoreOutlined, SaveOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ContentInputAction from "redux/SpecificInsureGuide/InsureGuideInitInput/ContentInput.action"
import WS1385001_SentenceMasterInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1385001_SentenceMasterInquiry.jsx";

class WS2654100_ContentInputAdd extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '内容入力';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      enterCodeMode: false,
      newData: false,
      SentenceCode: "",
      DocumentClassify: "",
    };
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      this.formRef.current?.setFieldsValue(this.props.record)
    }
  }

  saveData() {
    let params = {
      id: !this.props.newFlag ? this.props.record.id : '',
      date_on: this.props.date_on,
      personal_number_id: this.props.personal_number_id,
      serial_number: this.props.serial_number,
      Li_InspectCode: this.props.Li_InspectCode,
      DocumentClassify: this.props.DocumentClassify,
      NumDigits: this.props.Li_NumDigits,
      display_order: this.formRef.current?.getFieldValue('display_order'),
      sentence_code: this.formRef.current?.getFieldValue('sentence_code'),
      content: this.formRef.current?.getFieldValue('content'),
    }
    ContentInputAction.save(params)
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  onDelete() {
    let params = {
      id: this.props.record.id,
      date_on: this.props.date_on,
      personal_number_id: this.props.personal_number_id,
      serial_number: this.props.serial_number,
      Li_InspectCode: this.props.Li_InspectCode,
      DocumentClassify: this.props.DocumentClassify,
      NumDigits: this.props.Li_NumDigits,
    }
    ContentInputAction.delete(params)
      .then(res => {
        console.log(res);
        this.props.onFinishScreen()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className="content-input">
        <Card title={'内容入力 [' + (this.props.newFlag ? '新規' : '編集') + ']'}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className="box_inner_vertical">
              <Form.Item label="　　表示順" name="display_order">
                <Input className="input-size-number-5" />
              </Form.Item>
              <Form.Item label="文章コード" name="sentence_code">
                <Input
                  className="input-size-number-7"
                  onDoubleClick={() =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 800,
                        component: (
                          <WS1385001_SentenceMasterInquiry
                            DocumentClassify={this.props.DocumentClassify}
                            onFinishScreen={(output) => {
                              console.log(output);
                              this.formRef.current?.setFieldsValue({
                                sentence_code: output.sentence_code,
                                content: output.content
                              })
                              this.closeModal()
                              this.forceUpdate()
                            }}
                          />
                        ),
                      },
                    })}
                />
              </Form.Item>
              <Form.Item label="内　　　容" name="content" style={{ marginBottom: 0 }}>
                <Input className="input-size-number-60" />
              </Form.Item>
            </div>
            <div className="box_button_bottom_right">
              {!this.props.newFlag ?
                < Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  okText='削除'
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  onConfirm={() => {
                    this.onDelete()
                  }}
                  cancelText='キャンセル'
                  cancelButtonProps={{ style: { height: '32px' } }}
                >
                  <Button
                    danger
                    type='primary'
                    icon={<DeleteOutlined />}
                  >
                    削除
                  </Button>
                </Popconfirm>
                : null}
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.saveData()
                }}
              >
                保存
              </Button>
            </div>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </Card>
      </div>
    );
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2654100_ContentInputAdd);
