import axios from "configs/axios";


const APP_LIST = {
  getScreenData: `/api/specific-coercive-finger-xml-output/print-process/`,
};
const PrintProcessService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
};

export default PrintProcessService;
