import React from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { Card, Form, Table, Space, Button, Modal, message, Dropdown, Menu } from 'antd'
import { PlusOutlined, QuestionCircleOutlined, MoreOutlined } from '@ant-design/icons'
import AnotherInspectItemSettingCategoryService from 'services/InspectionMaintenance/AnotherInspectItemSettingCategory/AnotherInspectItemSettingCategoryService'
import WS2705502_CategoryInquirySetting from './WS2705502_CategoryInquirySetting'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS2705501_CategoryInquiry extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'カテゴリ別検査項目詳細'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchCategorySettingsDisplayList: [],
      searchInspectSetDisplayList: [],
      PatternCodeDisplayList: [],
      record: {}
    }
  }

  componentDidMount() {
    this.setState({
      record: this.props.record
    })
    this.searchInspectSet()
  }

  searchInspectSet = (category_code) => {
    AnotherInspectItemSettingCategoryService.getInspectSetService({
      category_code: this.props.record.category_code,
      pattern_code: this.props.record.pattern_code,
    })
      .then((res) => {
        // this.formRef.current.setFieldsValue({
        //   searchInspectSetDisplayList: res.data,
        // })
        this.setState({
          searchInspectSetDisplayList: res.data
        })
        // this.savePatternInspection();
        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  deleteDataInspectSet = (id, pattern_code, category_code , exam_code) => {
    AnotherInspectItemSettingCategoryService.deleteDataInspectSetService({
      id,
      pattern_code,
      category_code,
      exam_code
    })
      .then((res) => {
        message.success('成功')
        this.savePatternInspection();
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        this.searchInspectSet(category_code)
      })
  }

  //ms0611を更新する
  savePatternInspection = () => {
    let params = { pattern: this.props.record.pattern_code }
    AnotherInspectItemSettingCategoryService.reconstructionPatternData(params)
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='category-inquiry'>
        <Card title='検査項目詳細'>
          <div className='box_container'>
            <Form ref={this.formRef} onFinish={this.onFinish}>
              <Table
                bordered
                size='small'
                pagination={false}
                dataSource={this.state.searchInspectSetDisplayList}
                rowKey={(record) => record.id}
                scroll={{ y: 600 }}
                onRow={(record, rowIndex) => {
                  return {
                    // double click row
                    onDoubleClick: () => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 350,
                          component: (
                            <WS2705502_CategoryInquirySetting
                              record={record}
                              onFinishScreen={(output) => {
                                this.searchInspectSet(this.props.category_code)
                                this.closeModal()
                              }}
                            />
                          ),
                        }
                      })
                    },
                  }
                }}
              >
                <Table.Column
                  width={100}
                  title='順'
                  dataIndex='display_order'
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}
                />
                <Table.Column
                  width={100}
                  title='検査コード'
                  dataIndex='exam_code'
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}
                />
                <Table.Column
                  title='略名'
                  dataIndex='HE_exam_name'
                />
                <Table.Column
                  title='検査名称'
                  dataIndex='GY_exam_name'
                />
                <Table.Column
                  title='タイプ'
                  dataIndex='Expression_24'
                />
                <Table.Column
                  width={60}
                  title='判定'
                  dataIndex='Expression_23'
                  render={(text) => {
                    return <div style={{ textAlign: 'center' }}>{text}</div>
                  }}
                />
                <Table.Column
                  title='外部検査'
                  dataIndex='item_code_external'
                  render={(value, record, index) => {
                    return (
                      <Space>
                        <Form.Item style={{ marginBottom: '0px' }}>
                          <span>{value}</span>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '0px' }}>
                          <span>{record.exam_name}</span>
                        </Form.Item>
                      </Space>
                    )
                  }}
                />
                <Table.Column
                  title='検査依頼'
                  dataIndex='exam_code_external'
                  render={(value, record, index) => {
                    return (
                      <Space>
                        <Form.Item style={{ marginBottom: '0px' }}>
                          <span>{value}</span>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '0px' }}>
                          <span>{record.exam_kanji_name_external}</span>
                        </Form.Item>
                      </Space>
                    )
                  }}
                />
                <Table.Column
                  width={50}
                  align='center'
                  title={() => (
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        let record = {
                          ...this.state.record,
                          ...{ id: '' }
                        }
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 350,
                            component: (
                              <WS2705502_CategoryInquirySetting
                                record={record}
                                onFinishScreen={(output) => {
                                  this.searchInspectSet(this.props.category_code)
                                  this.closeModal()
                                }}
                              />
                            ),
                          }
                        })
                      }}
                    />
                  )}
                  dataIndex=''
                  render={(text, record, index) => (
                    <Dropdown
                      size='small'
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='修正'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 350,
                                  component: (
                                    <WS2705502_CategoryInquirySetting
                                      record={record}
                                      onFinishScreen={(output) => {
                                        this.searchInspectSet(this.props.category_code)
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                }
                              })
                            }}
                          >
                            修正
                          </Menu.Item>
                          <Menu.Item
                            key='削除'
                            onClick={() => {
                              Modal.confirm({
                                title: '確認',
                                icon: (
                                  <QuestionCircleOutlined
                                    style={{ color: '#1890ff' }}
                                  />
                                ),
                                content: '削除しますか',
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onOk: () => {
                                  this.deleteDataInspectSet(
                                    record.id,
                                    record.pattern_code,
                                    record.category_code,
                                    record.exam_code
                                  )
                                },
                              })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button size='small' icon={<MoreOutlined />} />
                    </Dropdown>
                  )}
                />
              </Table>
            </Form>
          </div>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
export default WS2705501_CategoryInquiry
