import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Input, Tooltip, Button, message } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

import PropTypes from 'prop-types'
import moment from 'moment'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import recalculate from 'components/Commons/recalculate'

import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import InputVoteResultInputAction from 'redux/InputBusiness/InputVoteResultInput/InputVoteResultInput.action.js'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import WS0730001_FindingsInputPhysiciiagnosis from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx'
import WS0729001_FindingsInputRadiography from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729001_FindingsInputRadiography.jsx'
import WS0728001_FindingsInputNormal from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0728001_FindingsInputNormal.jsx'
import WS2644008_SpecifiedValueConfirm from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2644008_SpecifiedValueConfirm.jsx'


const labelCol = {
  labelCol: { style: { width: 80 } },
}

class WS0738002_TargetUserScreen extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_MenuOption: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      dataSource: [],
      changeData: [],
      disabledUpdateBtn: true,

      personal_number_id: null,
      kanji_name: null,
      receipt_number: null,
      visit_course: null,
      contract_short_name: null,
      protectFlag: null,

      dateChar: null,
      pre1DateChar: null,
      pre2DateChar: null,
    }
  }


  componentDidMount() {
    this.setState({
      personal_number_id: this.props.personal_number_id,
      kanji_name: this.props.kanji_name,
      receipt_number: this.props.receipt_number,
      visit_course: this.props.visit_course,
      contract_short_name: this.props.contract_short_name,
      protectFlag: this.props.protectFlag
    })

    this.index();
  }


  index = () => {
    // 検査情報を取得
    let param = {
      reservation_number: this.props.reservation_number,
      personal_number_id: this.props.personal_number_id,
      visit_date_on: this.props.visit_date_on,
      pattern_code: this.props.pattern_code,
      protectFlag: this.props.protectFlag,
      Li_MenuOption: this.props.Li_MenuOption,
      slipNumber: this.props.slipNumber
    }

    InputVoteResultInputAction.getTargetInfo(param)
      .then((res) => {
        this.setState({
          dataSource: res.result ?? [],
          changeData: res.result ?? [],
          dateChar: res.visitDateOn,
          pre1DateChar: res.pre1visitDateOn,
          pre2DateChar: res.pre12isitDateOn,
        })

        this.formRef?.current?.setFieldsValue({
          formInspectResultList: res.result
        })
      })
  }


  /**
  * 編集用のモーダルを表示する
  * @param {*} record
  */
  showEditModal = async (record) => {
    if (!this.state.disabledUpdateBtn) {
      await this.setState({ saveFlag: true })
    }

    // ロックされていない受診者だけ
    if (!this.props.protectFlag) {
      if (record.textPositionExamType && record.StsInspectCmtInfo) {
        // 検査コメント検索・照会　モーダル 大体はこのモーダルを使用
        this.showWS0274001_InspectCmtSearchQuery(record)

      } else if (record.exam_type.slice(0, 1) === 'N') {
        // 直接入力系（N系）は何も呼ばない
        message.info('入力フォームに直接入力してください')
        return

      } else {
        if (!this.state.disabledUpdateBtn) {
          this.saveResultValueList()
        }

        // 検査コメント照会以外(所見関連)のモーダル
        // 検査によって表示する編集用モーダルを切り替える
        this.switchEditModal(record)
      }
    }
  }


  /**
  * 検査コメント検索・照会　モーダルを開く
  */
  showWS0274001_InspectCmtSearchQuery = (record) => {
    // コメント区分(exam_comment_code)が0の場合はモーダルは表示しない
    if ((record.exam_comment_code ?? 0) > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 600,
          component: (
            <WS0274001_InspectCmtSearchQuery
              Li_CategoryCode={record.category_code}
              Li_CategoryName={record.category_name}
              Lio_CmtClassify={record.exam_comment_code}
              LnkOutInspectCmtScreen={record.result_value}
              onFinishScreen={(output) => {
                // 選択された検査コメントを反映
                const value = output.LnkOutInspectCmtScreen
                // 結果値が変更になった場合
                this.changeResultValue(value, record)
                // モーダルを閉じる
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }


  /**
  *
  * @param {*} record
  */
  switchEditModal = (record) => {
    const params = {
      StsKeyEnable: true,
      Li_CourseLevel: this.props.course_level,
      Li_ReserveNum: this.props.reservation_number,
      exam_code: record.exam_code,
      Li_PatternCode: this.props.pattern_code,
      category_code: record.category_code,
      personal_number_id: this.props.personal_number_id,
      Li_JudgeLevel: record.judgment_level_division,
      GuideHowToAddOp: record.guideHowToAddOp,
    }

    // exam_typeが「S」である場合
    OverallResultDisplayInputAction.findingsEditingBefore(params)
      .then((res) => {
        if (res) {
          const messagex = res.message ?? ''
          const variables = res.variables ?? null

          if (messagex === 'Call Screen WS0730001') {
            // 所見入力[医師診断]
            this.showWS0730001_FindingsInputPhysiciiagnosis(variables, record)
          }
          if (messagex === 'Call Screen WS0729001') {
            // 所見入力[読影]
            this.showWS0729001_FindingsInputRadiography(variables, record)
          }
          if (messagex === 'Call Screen WS0731001' || messagex === 'Call Screen WS0728001') {
            // 所見入力[通常]
            this.showWS0728001_FindingsInputNormal(variables, record)
          }
        }
      })
  }


  /**
  * 所見入力[医師診断]　モーダル
  * @param {*} variables
  * @param {*} record
  */
  showWS0730001_FindingsInputPhysiciiagnosis = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.personal_number_id}
            KanjiName={this.props.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }


  /**
  * 所見入力[読影]　モーダル
  * @param {*} variables
  * @param {*} record
  */
  showWS0729001_FindingsInputRadiography(variables, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0729001_FindingsInputRadiography
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.personal_number_id}
            KanjiName={this.props.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }


  /**
  * 所見入力[通常]　モーダル
  * @param {*} variables
  * @param {*} record
  */
  showWS0728001_FindingsInputNormal = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1100,
        component: (
          <WS0728001_FindingsInputNormal
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.personal_number_id}
            KanjiName={this.props.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.index();
            }}
          />
        ),
      },
    })
  }


  /**
  * 結果値が変更になった場合
  * @param {*} event
  * @param {*} record
  */
  changeResultValue = (value, record) => {
    record.inspectComments.map((element, index) => {
      let newElement = element.exam_comment_code.trim()
      if (value == newElement) {
        value = element.exam_comment_screen.trim()
      }
    })

    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (exam_type.slice(0, 1) === 'N' && value !== '') {
      // exam_typeが「N、N1、N2、N3」の場合
      resultValue = recalculate(exam_type, value)
    } else {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.result_value !== value) {
        // 検査結果値の設定
        element.result_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }


  /**
  * 判定選択　(検査コード毎用)
  * @param {*} data
  */
  showWS0285001_JudgeQueryInspect = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={Number(record.judgment_level_division)}
            onFinishScreen={async (output) => {
              if (output.recordData) {
                this.changeJudgmentValue(output.recordData.judgment_result, record)
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * 検査ごとの判定値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeJudgmentValue(value, record) {
    let copyData = [...this.state.changeData]
    let id = record?.id
    let resultValue
    let disabledChange = false

    if (value !== '') {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.judgment_value !== value) {
        // 検査結果値の設定
        element.judgment_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }


  /**
    * 変更された検査結果の更新
    */
  saveResultValueList = () => {
    let param = {
      courseLevel: this.props.course_level,
      reservationNumber: this.props.reservation_number,
      changeData: this.state.changeData,
    }

    InputVoteResultInputAction.saveResultValueList(param)
      .then((res) => {
        this.index();
      })
  }


  /**
   * 規定値
   */
  checkDefaultValue = () => {
    let param = {
      dataSource: this.state.dataSource
    }

    InputVoteResultInputAction.checkDefaultValue(param)
      .then((res) => {
        const mess = 'Call Screen WS2644008'
        if (res) {
          if (res && res?.message === mess) {
            // 該当の規定値があれば、規定値確認モーダルを開く
            this.showWS2644008_SpecifiedValueConfirm(res.inspectList)
          } else {
            // 規定値設定が可能な検査が存在しません
            message.warning(res?.message)
          }
        }
      })
  }


  /**
  * 規定値確認　モーダル
  * @param {*} inspectList
  * @param {*} format
  */
  showWS2644008_SpecifiedValueConfirm = (inspectList) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2644008_SpecifiedValueConfirm
            inspectList={inspectList}
            onUpdate={async (output) => {
              let param = {
                inspectList: inspectList,
                dataSource: this.state.dataSource
              }
              InputVoteResultInputAction.saveDefaultValue(param)
                .then((res) => {
                  this.index()
                  this.closeModal()
                })
            }}
          />
        ),
      },
    })
  }


  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='target-user-screen'>
        <Card title='伝票 対象者' className="mb-3">
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <div className='box_inner_horizontal'>
                <div className='box_search_inner'>
                  <Form.Item
                    label='個人番号'
                    {...labelCol}
                  >
                    <div>{this.state.personal_number_id}</div>
                  </Form.Item>

                  <Form.Item
                    label='漢字氏名'
                    {...labelCol}
                  >
                    <div>{this.state.kanji_name}</div>
                  </Form.Item>
                </div>

                <div className='box_search_inner'>
                  <Form.Item
                    label='受付番号'
                    {...labelCol}
                  >
                    <div>{this.state.receipt_number}</div>
                  </Form.Item>

                  <Form.Item
                    label='コース'
                    {...labelCol}
                  >
                    <div>{this.state.visit_course} {this.state.contract_short_name}</div>
                  </Form.Item>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={record => record.id}
                scroll={{ y: '500px' }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              >
                <Table.Column
                  title='検査項目'
                  dataIndex='inspect_name'
                  width={155}
                />

                <Table.Column
                  title='参考値'
                  dataIndex='normal_value'
                  width={140}
                  render={(item, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                      {record.normal_value}
                      {record.dataConfirmFlag === 0 ?
                        null
                        :
                        <span
                          style={{
                            float: 'right',
                            fontWeight: record?.resultValueFontWeight === 82 ? 'bold' : ''
                          }}>
                          *
                        </span>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  title='判'
                  dataIndex='judgment_value'
                  width={50}
                  align='center'
                  className='this-time'
                  render={(value, record, index) => (
                    <Tooltip title={record.valueTooltip}>
                      {(record.exam_type === 'S') ?
                        // 所見 (モーダルを開いて値を変更)
                        <div
                          style={{
                            textAlign: 'center',
                            fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : '',
                            color: Color(record.judgmentValueColorNum)?.Foreground,
                            border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {value}
                        </div>
                        :
                        <div
                          name={['formInspectResultList', index, 'judgment_value']}
                          onDoubleClick={() => {
                            if (!this.props.protectFlag) {
                              // 編集画面の表示処理
                              this.showWS0285001_JudgeQueryInspect(record)
                            }
                          }}
                          style={{
                            textAlign: 'center',
                            fontWeight: (record?.resultValueFontWeight === 82 || (record.change_flg ?? 0)) ? 'bold' : '',//変更時は太字
                            color: Color(record.judgmentValueColorNum)?.Foreground,
                            border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                            whiteSpace: 'pre-wrap',
                            cursor: 'pointer'
                          }}
                        >
                          {value ? value : '　'}
                        </div>
                      }
                    </Tooltip>
                  )}
                />

                <Table.Column
                  title={
                    <div style={{ display: (this.state.dateChar) ? '' : 'none' }}>
                      <div>{'今回'}</div>
                      <div>{moment(this.state.dateChar).format('YYYY/MM/DD')}</div>
                    </div>
                  }
                  dataIndex='result_value'
                  className='this-time'
                  render={(value, record, index) => {
                    return (
                      record.exam_type === 'S' || (this.props.protectFlag && (record.exam_type.slice(0, 1) !== 'N')) ?
                        < div
                          style={{
                            color: Color(record.resultValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap',
                            padding: '5px 8px'
                          }}
                          onDoubleClick={() => {
                            // 編集画面の表示処理
                            this.showEditModal(record)
                          }}
                        >
                          {value}
                        </div>
                        :
                        record.StsAutomaticCalculate || (this.props.protectFlag && (record.exam_type.slice(0, 1) === 'N')) ?
                          // 自動計算あり（BMIや基準体重など）入力なし
                          // または、ロック時 かつ exam_typeが「N」
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', width: '100%' }}>
                              <div
                                style={{
                                  color: Color(record.resultValueColorNum)?.Foreground,
                                  width: '6rem',
                                  textAlign: 'right'
                                }}
                              >
                                {value}
                              </div>
                            </div>
                          </div>
                          :
                          // 値の変更、入力あり
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            onDoubleClick={() => {
                              // 編集画面の表示処理
                              this.showEditModal(record)
                            }}
                          >
                            <div
                              style={{
                                display: (record.UpAndDown !== '') ? '' : 'none',
                                width: '30px',
                                cursor: 'pointer',
                                color: Color(record.upAndDownColorNum)?.Foreground,
                                textAlign: 'center'
                              }}>
                              {record.UpAndDown}
                            </div>
                            <Form.Item
                              name={['formInspectResultList', index, 'result_value']}
                              style={{ marginBottom: '0' }}
                            >
                              <Input
                                id={record.id}
                                type='tel'
                                onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', 1, true, 0)}
                                onPressEnter={(e) => this.changeResultValue(e.target.value, record)}
                                onBlur={(e) => this.changeResultValue(e.target.value, record)}
                                style={{
                                  textAlign: (record.exam_type.slice(0, 1) === 'N') ? 'right' : 'left',
                                  fontWeight: (record.change_flg ?? 0) ? 'bold' : 'normal', //変更時は太字
                                }}
                              />
                            </Form.Item>
                          </div>
                    )
                  }}
                />

                <Table.Column
                  title='判'
                  dataIndex='pre_1_judgment_value'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre1JudgmentValueColorNum)?.Foreground
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title={
                    <div style={{ display: (this.state.pre1DateChar) ? '' : 'none' }}>
                      <div>{'前回'}</div>
                      <div>{moment(this.state.pre1DateChar).format('YYYY/MM/DD')}</div>
                    </div>
                  }
                  dataIndex='pre_1_result_value'
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right'
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap'
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  title='判'
                  dataIndex='pre_2_judgment_value'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre2JudgmentValueColorNum)?.Foreground
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title={
                    <div style={{ display: (this.state.pre2DateChar) ? '' : 'none' }}>
                      <div>{'前々回'}</div>
                      <div>{moment(this.state.pre2DateChar).format('YYYY/MM/DD')}</div>
                    </div>
                  }
                  dataIndex='pre_2_result_value'
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right'
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap'
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

              </Table>

              <div
                className='box_button_bottom_right'>
                <Button
                  icon={<SaveOutlined />}
                  type='primary'
                  onClick={() =>
                    // 規定値の取得
                    this.checkDefaultValue()
                  }
                >
                  <span className='btn_label'>
                    規定値
                  </span>
                </Button>

                <Button
                  icon={<SaveOutlined />}
                  type='primary'
                  disabled={this.state.disabledUpdateBtn}
                  onClick={() =>
                    // 変更した検査結果を反映
                    this.saveResultValueList()
                  }
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </Form>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0738002_TargetUserScreen);
