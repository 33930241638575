import axios from "configs/axios";
const apiPaths = {
    getScreenData: "/api/comprehensive-menu-maintenance/getscreendata",
    selectBusinessCode: "/api/comprehensive-menu-maintenance/selectbusinesscode",
    deleteData: "/api/comprehensive-menu-maintenance/deletedata",
};
const ComprehensiveMenuMaintenanceService = {
    async getScreenData() {
      return axios.get(apiPaths.getScreenData);
    },
    async selectBusinessCode(params) {
      return axios.get(apiPaths.selectBusinessCode,{params});
    },
    async deleteData(params) {
      return axios.get(apiPaths.deleteData, { params });
    },
};

export default ComprehensiveMenuMaintenanceService;