import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, Card, Form, message, Select, } from 'antd'
// import WS0061012_CheckYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061012_CheckYes.jsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import CopyAction from 'redux/InspectionMaintenance/InspectValueCalculateAutoMaintain/Copy.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'

const labelCol = {
  labelCol: { style: { width: 70 } },
}
class WS2699183_Copy extends React.Component {
  static propTypes = {
    Li_InspectItemCopySource: PropTypes.any,
    Li_GenderCopySource: PropTypes.any,
    Li_TableCheck: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '複写'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    const param = {
      InspectItemCopySource: this.props.Li_InspectItemCopySource ?? '',
      GenderCopySource: this.props.Li_GenderCopySource ?? ''
    }
    CopyAction.index(param)
      .then(res => {
        this.formRef.current?.setFieldsValue(res)
        this.forceUpdate()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  /**
   * 複写登録
   * @returns
   */
  copyRegister() {
    const param = {
      InspectItemCopySource: this.props.Li_InspectItemCopySource ? this.props.Li_InspectItemCopySource : '',
      GenderCopySource: this.props.Li_GenderCopySource ?? '',
      GenderCopy: this.formRef.current?.getFieldValue('GenderCopy'),
    }

    CopyAction.copyRegister(param)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='copy'>
        <Card title='複写'>
          <Form
            ref={this.formRef}
            initialValues={{ GenderCopy: 0 }}
            autoComplete='off'
          >

            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item
                  name='InspectItems'
                  label='検査項目'
                  {...labelCol}
                >
                  <div>{this.formRef.current?.getFieldValue('InspectItems')}</div>
                </Form.Item>
                <div style={{ marginTop: 5 }}>
                  {this.formRef.current?.getFieldValue('exam_name')}
                </div>
              </div>

              <Form.Item
                label='複写元性別'
                name='GenderCopySourceChar'
                {...labelCol}
              >
                <div>{this.formRef.current?.getFieldValue('GenderCopySourceChar')}</div>
              </Form.Item>

              <div style={{ width: '100%' }}>
                <ArrowDownOutlined style={{ fontSize: '20px', color: '#08c' }} />
              </div>

              <Form.Item
                name='GenderCopy'
                label='複写先性別'
                {...labelCol}
              >
                <Select style={{ width: 100 }}>
                  <Select.Option value={0}>共通</Select.Option>
                  <Select.Option value={1}>男</Select.Option>
                  <Select.Option value={2}>女</Select.Option>
                </Select>
              </Form.Item>


            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => this.copyRegister()}
            >
              複写登録
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2699183_Copy)
