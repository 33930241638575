/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Input, Form, Modal, Button, Select, message, Card } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import EscortDetailSubAction from 'redux/AdvancePreparation/DocumentManageMaintain/EscortDetailSub.action'
import WS0641008_BasicConditionSet from './WS0641008_BasicConditionSet'
import WS0641021_InspectConditionSetting from './WS0641021_InspectConditionSetting'
import WS0104001_CoursesSettingSub from 'pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0104001_CoursesSettingSub'

const labelCol = {
  labelCol: { style: { width: 50 } },
}

const labelCol2 = {
  labelCol: { style: { width: 95 } },
}

const labelCol3 = {
  labelCol: { style: { width: 70 } },
}

const boxTitleStyle = {
  textAlign: 'center', backgroundColor: '#4cb0a4', color: '#ffffff',
  padding: '5px 10px', margin: '-10px 0 0 -10px'
}


class WS0641002_EscortDetailSubEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: 0,
      old_W1_text_num: null,

      CompChecked: false,
      id: null,
      disabledCourse: true,

      oldNumber: 0
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    let data
    // 親画面のrecordをコピー
    data = {
      ...this.props.record,
    }

    console.log(data);

    this.formRef.current?.setFieldsValue(data)

    this.setState({ oldNumber: data.Num })

  }

  /**
   * 基本のサーチが押された時の処理
   */
  ShowWS0641008_BasicConditionSet() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0641008_BasicConditionSet
            BasicCondition={this.formRef.current.getFieldValue('Inspect') ?? ''}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                Inspect: output
              })
              this.forceUpdate()
              this.ShowWS0641008_BasicConditionSet()
            }}
            onDelete={(output) => {
              this.formRef.current?.setFieldsValue({
                Inspect: output
              })
              this.forceUpdate()
              this.ShowWS0641008_BasicConditionSet()
            }}
          />
        ),
      },
    })
  }

  /**
   * 検査のサーチが押された時の処理
   */
  ShowWS0641021_InspectConditionSetting() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0641021_InspectConditionSetting
            InspectCondition={this.formRef.current.getFieldValue('Condition') ?? ''}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                Condition: output
              })
              this.forceUpdate()
              this.ShowWS0641021_InspectConditionSetting()
            }}
            onDelete={(output) => {
              this.formRef.current?.setFieldsValue({
                Condition: output
              })
              this.forceUpdate()
              this.ShowWS0641021_InspectConditionSetting()
            }}
          />
        ),
      },
    })
  }

  /**
   * コースのサーチが押された時の処理
   * @param {*} condition
   */
  ShowWS0104001_CoursesSettingSub() {
    this.setState({
      ...this.state,
      childModal: {
        width: '50%',
        visible: true,
        component: (
          <WS0104001_CoursesSettingSub
            Li_Title={'コース条件'}
            Lio_CourseList={this.formRef.current.getFieldValue('CourseList')}
            //項目を追加する場合
            onFinishScreen={(output) => {
              this.setDataCourses(output)
            }}
            //項目を削除する場合
            onDelete={(output) => {
              this.deleteDataCourses(output)
            }}
          />)
      }
    })
  }

  /**
 * コースフォーム項目の登録用関数
 * @param {*} condition
 * @param {*} record
 */
  setDataCourses(record) {
    const item = record.Lo_CourseCode
    let formItem = record.FieldValue ?? ''
    let arrayItem = ''
    let duplication = ''
    let outputItem

    if (formItem === '') {
      //フォームに何も登録されていない場合
      outputItem = item + ';'

    } else {
      //フォームに項目が登録されている場合
      arrayItem = formItem.substr(0, formItem.length - 1)
      arrayItem = arrayItem.split(',')
      arrayItem.push(item)
      arrayItem.sort()

      duplication = arrayItem.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x)
      })

      if (duplication.length === 0) {
        //重複がない場合
        arrayItem.join(',')
        outputItem = arrayItem + ';'

      } else {
        //重複がある場合
        message.error('検査コードが重複しています')

        //重複を削除したリスト
        arrayItem = arrayItem.filter((x, i, self) => self.indexOf(x) === i)
        arrayItem.join(',')
        outputItem = arrayItem + ';'
      }
    }

    //フォームに登録
    this.formRef.current?.setFieldsValue({
      CourseList: outputItem
    })

    this.forceUpdate()
    this.ShowWS0104001_CoursesSettingSub(outputItem)
  }

  /**
  * コースフォーム項目の削除用関数
  * @param {*} condition
  * @param {*} record
  */
  deleteDataCourses(record) {
    const item = record.record.W1_course_cd
    let formItem = record.FieldValue
    let arrayItem = ''
    let linkArrayItem = ''
    let outputItem

    arrayItem = formItem.substr(0, formItem.length - 1)
    arrayItem = arrayItem.split(',')

    //削除対象の絞り込み・削除
    arrayItem.map((value, index) => {
      if (value === item) {
        arrayItem.splice(index, 1)
      }
    })

    arrayItem.sort()
    linkArrayItem = arrayItem.join(',')

    if (linkArrayItem === '') {
      //消去後、項目が残らない場合
      outputItem = ''

    } else {
      //消去後、項目が残る場合
      outputItem = linkArrayItem + ';'
    }

    //フォームに登録
    this.formRef.current?.setFieldsValue({
      CourseList: outputItem
    })

    this.forceUpdate()
    this.ShowWS0104001_CoursesSettingSub(outputItem)
  }

  /**
  * 重複チェック
  */
  deplicateCheck = async () => {
    let newFlag = this.props.newflag
    let deplicateFalg = false
    let id = this.state.oldNumber
    let dataSource = this.props.dataSource

    if (this.formRef.current.getFieldValue('Num') == 0) {
      message.error("番号は1以上を入力してください")
    } else {

      // this.props.dataSourceの「id（連番)」に対して重複チェックを行う
      // 重複チェックをするかどうかを判断する(変更前後の番号が違う→重複チェック)
      if (this.state.oldNumber != this.formRef.current.getFieldValue('Num')) {
        // 重複チェックをまわす→今のnum　getFieldValue('Num')　とデータベースのnumを比較する
        for (let i = 0; i < dataSource.length; i++) {
          if (this.formRef.current.getFieldValue('Num') == dataSource[i].Num) {
            deplicateFalg = true
          }
        }
      }


      //重複チェック
      if (deplicateFalg) {
        //重複した場合
        // 重複エラー
        message.error("番号が重複しています")
      } else {
        //重複しなっかた場合
        // 削除処理を行う条件（新規以外）
        if (newFlag) {
          // 新規登録処理
          this.save()
        } else {
          // 削除処理
          await this.delete()
          // 変更処理
          await this.save()
        }
      }
    }
  }


  /**
   * 保存
   */
  save = () => {
    const values = this.formRef.current.getFieldsValue();
    let newData = {
      ...values,
      id: this.props.record?.id,
      escort_code: this.props.escort_code,
      num: this.formRef.current.getFieldValue('Num'),
    }

    console.log('更新処理')
    EscortDetailSubAction.updateRecordData(newData)
      .then((res) => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 削除
   */
  delete() {
    let params = {
      id: this.props.record?.id,
      escort_code: this.props.escort_code,
    }
    EscortDetailSubAction.deleteRecordData(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  // 検査のselectが変更された場合
  onChangeSelectCondition(value) {
    console.log(value);
    if (value == 'NONE') {
      this.formRef.current.setFieldsValue({
        ConditionLabel: ''
      })
    }
  }

  // コースのselectが変更された場合
  onChangeSelectCourse(value) {
    console.log(value);
    if (value == 'NONE') {
      this.formRef.current.setFieldsValue({
        CourseList: ''
      })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='escort-detail-sub'>
        <Card title={'エスコート保守　内容設定 [' + (this.props.newflag ? '新規登録]' : '変更]')} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              label='連番'
              name='Num'//対応したものに書き換え
              {...labelCol3}
            >
              <Input
                style={{ width: 504 }}
                type='number'
              />
            </Form.Item>

            <Form.Item
              label='内容'
              name='Message'//対応したものに書き換え
              {...labelCol3}
              rules={[
                {
                  required: true,
                  message: '記入して下さい',
                },
              ]}
            >
              <Input
                //className='input-size-60'
                style={{ width: 504 }}
              />
            </Form.Item>

            <Form.Item
              label='基本条件'
              name='Inspectname'//対応したものに書き換え
              style={{ marginBottom: 20 }}
              {...labelCol3}
            >
              <Input
                readOnly
                style={{ border: 'none', background: 'transparent' }}
              />
            </Form.Item>

            <div className='box_inner_horizontal'>
              <div className='box_border'>
                <div className='box_inner_horizontal'>
                  <div style={boxTitleStyle}>
                    条件
                  </div>
                </div>

                <div className='box_inner_vertical'
                  style={{ gap: 0, padding: '10px' }}
                >
                  <Form.Item
                    label='基本'
                    name='Inspect'
                    {...labelCol}
                  >
                    <Input.Search
                      onSearch={() => {
                        this.ShowWS0641008_BasicConditionSet()
                      }}
                    />
                  </Form.Item>

                  <div className='box_inner_horizontal'>
                    <Form.Item
                      label='検査'
                      name='ConditionLabel'
                      style={{ minWidth: '150px' }}
                      initialValue={'NONE'}
                      {...labelCol}
                    >
                      <Select>
                        <Select.Option value={'NONE'}>　</Select.Option>
                        <Select.Option value={'NOT'}>NOT</Select.Option>
                        <Select.Option value={'OR'}>OR</Select.Option>
                        <Select.Option value={'AND'}>AND</Select.Option>
                      </Select>
                    </Form.Item >

                    <Form.Item
                      name='Condition'
                      style={{ width: '100%' }}
                    >
                      <Input.Search
                        onSearch={() => {
                          this.ShowWS0641021_InspectConditionSetting('Condition')
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    label='性別'
                    name='Gender'
                    initialValue={''}
                    {...labelCol}
                  >
                    <Select style={{ width: '100px' }}>
                      <Select.Option value={''}>全て</Select.Option>
                      <Select.Option value={'M'}>男性</Select.Option>
                      <Select.Option value={'F'}>女性</Select.Option>
                    </Select>
                  </Form.Item>

                  <div className='box_inner_horizontal'>
                    <Form.Item
                      label='コース'
                      name='CourseClassify'
                      style={{ minWidth: '150px' }}
                      initialValue={'NONE'}
                      {...labelCol}
                    >
                      <Select
                        onChange={(value) => this.onChangeSelectCourse(value)}
                      >
                        <Select.Option value={'NONE'}>　</Select.Option>
                        <Select.Option value={'OR'}>OR</Select.Option>
                        <Select.Option value={'NOT'}>NOT</Select.Option>
                      </Select>
                    </Form.Item >

                    <Form.Item
                      name='CourseList'
                      style={{ width: '100%' }}
                    >
                      <Input.Search
                        onSearch={() => {
                          this.ShowWS0104001_CoursesSettingSub('CourseList')
                        }}
                      />
                    </Form.Item>
                  </div>

                </div>
              </div>

              <div className='box_border'>
                <div className='box_inner_horizontal'>
                  <div style={boxTitleStyle}>
                    内容の置き換え
                  </div>
                  <span style={{ marginTop: '-3px' }}>
                    ※基本条件によって切り替え
                  </span>
                </div>

                <div className='box_inner_vertical'
                  style={{ gap: 0, padding: '10px' }}
                >

                  <Form.Item
                    label='契約'
                    name='Contract'
                    {...labelCol2}
                  >
                    <Input
                    />
                  </Form.Item>

                  <Form.Item
                    label='オプション'
                    name='Option'
                    {...labelCol2}
                  >
                    <Input
                    />
                  </Form.Item>

                  <Form.Item
                    label='追加'
                    name='Added'
                    {...labelCol2}
                  >
                    <Input
                    />
                  </Form.Item>

                  <Form.Item
                    label='不要'
                    name='Unnecessary'
                    {...labelCol2}
                  >
                    <Input
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <Form.Item
              style={{ marginTop: 20 }}
              label='備考'
              name='Remark'
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 5 }}
                style={{ width: 1200 }}
              />
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newflag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    okButtonProps: {
                      danger: true,
                      icon: <DeleteOutlined />
                    },
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    cancelText: 'キャンセル',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                className='btn_label'
                onClick={() => this.deplicateCheck()}
                icon={<SaveOutlined />}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        < ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0641002_EscortDetailSubEdit)
