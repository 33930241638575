import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Table, Menu, Dropdown, message, Tooltip, } from 'antd'
import { MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, InfoOutlined, FileTextOutlined, } from '@ant-design/icons'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS1220004_CodeSetting from 'pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1220004_CodeSetting.jsx'
import WS1220005_SendDaySetting from 'pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1220005_SendDaySetting.jsx'
import WS1227001_AssociateInsureErrorListCreatedSub from 'pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1227001_AssociateInsureErrorListCreatedSub.jsx'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import ModalDraggable from 'components/Commons/ModalDraggable'
import AssociateInsureDataCreateAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureDataCreate/AssociateInsureDataCreate.actions.js'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import moment from 'moment'
import { download_file_binary } from 'helpers/CommonHelpers'


class WS1220001_AssociateInsureDataCreate extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '協会けんぽデータ作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRowKeys: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      rowSelect: {},
      KeyInfo: '',
      DataSourceName: '',
      DateSChar: moment().format('YYYY/MM/DD'), //システム日付を設定
      disabledBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
  }

  /**
   * 協会けんぽデータを取得
   */
  index = () => {
    const {
      DateFChar,
      DateTChar,
      PrintGivenYear,
      PrintSpecifiedMonth,
      PrintingASpecifiedDateF,
      PrintingASpecifiedDateT,
    } = this.formRef.current.getFieldsValue(true)

    let params = {
      ...this.formRef.current.getFieldValue(),
      DateFChar: DateFChar ? moment(DateFChar).format('YYYY/MM/DD') : '',
      DateTChar: DateTChar ? moment(DateTChar).format('YYYY/MM/DD') : '',
      DateSChar: this.state.DateSChar,
      PrintGivenYear: PrintGivenYear,
      PrintSpecifiedMonth: PrintSpecifiedMonth,
      PrintingASpecifiedDateF: PrintingASpecifiedDateF,
      PrintingASpecifiedDateT: PrintingASpecifiedDateT,
      KeyInfo: this.state.KeyInfo,
      DataSourceName: this.state.DataSourceName,
    }

    AssociateInsureDataCreateAction.index(params)
      .then((res) => {
        if (res) {
          const data = [...res]
          let bool = true
          if (data.length > 0) {
            bool = false
          }
          // 作成ボタン活性/非活性
          this.setState({
            dataSource: data,
            disabledBtn: bool
          })

          // チェックボックス設定
          let arrTemp = res.map(item => item.id)
          this.setState({
            selectedRowKeys: arrTemp,
          })
        }
      })
  }

  /**
   * 請求日 月の変更処理
   * @param {*} e
   */
  onChangeSpecifiedMonth = (e) => {
    const value = e.target.value ?? ''
    if (value === '') return

    let PrintSpecifiedMonth = value

    // 月の開始日と最終日を取得
    let {
      PrintGivenYear,
    } = this.formRef.current.getFieldsValue(true)

    PrintGivenYear = PrintGivenYear.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    })

    PrintSpecifiedMonth = PrintSpecifiedMonth.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    })

    var startDay = new Date(PrintGivenYear, PrintSpecifiedMonth - 1, 1)
    var endDay = new Date(PrintGivenYear, PrintSpecifiedMonth, 0)

    this.formRef.current.setFieldsValue({
      DateFChar: moment(startDay).format('YYYY/MM/DD'),
      DateTChar: moment(endDay).format('YYYY/MM/DD'),
      PrintingASpecifiedDateF: 1,
      PrintingASpecifiedDateT: moment(endDay).format('D'),
      PrintGivenYear: PrintGivenYear,
      PrintSpecifiedMonth: PrintSpecifiedMonth
    })
  }

  /**
   * 請求日 開始日の変更処理
   * @param {*} e
   */
  onChangeSpecifiedDateF = (e) => {
    const value = e.target.value ?? ''
    if (value === '') return

    let PrintingASpecifiedDateF = value

    PrintingASpecifiedDateF = PrintingASpecifiedDateF.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    })

    const {
      PrintGivenYear, PrintSpecifiedMonth
    } = this.formRef.current.getFieldsValue(true)
    var startDay = new Date(PrintGivenYear, PrintSpecifiedMonth - 1, PrintingASpecifiedDateF)

    this.formRef.current.setFieldsValue({
      DateFChar: moment(startDay).format('YYYY/MM/DD'),
      PrintingASpecifiedDateF: PrintingASpecifiedDateF
    })
  }

  /**
   * 請求日 終了日の変更処理
   * @param {*} e
   */
  onChangeSpecifiedDateT = (e) => {
    const value = e.target.value ?? ''
    if (value === '') return

    let PrintingASpecifiedDateT = value

    PrintingASpecifiedDateT = PrintingASpecifiedDateT.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    })

    const {
      PrintGivenYear, PrintSpecifiedMonth,
    } = this.formRef.current.getFieldsValue(true)
    var endDay = new Date(PrintGivenYear, PrintSpecifiedMonth - 1, PrintingASpecifiedDateT)

    this.formRef.current.setFieldsValue({
      DateTChar: moment(endDay).format('YYYY/MM/DD'),
      PrintingASpecifiedDateT: PrintingASpecifiedDateT
    })
  }

  /**
   * 作成ボタン押下処理
   * @returns
   */
  createOutputFile = () => {
    const {
      DateFChar,
      DateTChar,
      PrintGivenYear,
      PrintSpecifiedMonth,
      PrintingASpecifiedDateF,
      PrintingASpecifiedDateT
    } = this.formRef.current.getFieldsValue(true)

    if (PrintGivenYear == '' || PrintSpecifiedMonth == '' || PrintingASpecifiedDateF == '' ||
      PrintingASpecifiedDateT == '') {
      message.error('請求日を入力してください')
      return
    }
    if (DateFChar == '' || DateTChar == '') {
      message.error('受診日を入力してください')
      return
    }
    if (this.state.DateSChar == '') {
      message.error('送付日を入力してください')
      return
    }
    if (Number(PrintingASpecifiedDateF) > Number(PrintingASpecifiedDateT) || new Date(DateFChar) > new Date(DateTChar)) {
      message.error('日付入力エラー')
      return
    }

    // チェックボックスにチェックがついているデータの一覧を取得
    const selectedIdList = [...this.state.selectedRowKeys]
    const data = [...this.state.dataSource]
    let selectedData = [], obj
    for (let i = 0; i < data.length; i++) {
      obj = { ...data[i] }
      for (let j = 0; j < selectedIdList.length; j++) {
        if (obj.id === selectedIdList[j]) {
          selectedData.push(obj)
          break
        }
      }
    }

    let params = {
      ...this.formRef.current.getFieldValue(),
      DateF: DateFChar ? moment(DateFChar).format('YYYY/MM/DD') : '',
      DateT: DateTChar ? moment(DateTChar).format('YYYY/MM/DD') : '',
      SendingDate: this.state.DateSChar,
      PrintGivenYear: PrintGivenYear,
      PrintSpecifiedMonth: PrintSpecifiedMonth,
      PrintingASpecifiedDateF: PrintingASpecifiedDateF,
      PrintingASpecifiedDateT: PrintingASpecifiedDateT,
      KeyInfo: this.state.KeyInfo,
      DataSourceName: this.state.DataSourceName,
      selectedData: selectedData,
    }

    AssociateInsureDataCreateAction.createKenpoData(params)
      .then((res) => {
        if (res?.message == 'Call Screen WS1227001') {
          // 協会けんぽエラーリスト出力 モーダル
          this.showWS1227001_AssociateInsureErrorListCreatedSub(res)
        } else {
          if (res?.filePath) {
            // ダウンロード
            this.downloadZipFile(res?.filePath)
          }
        }
      })
      .catch((error) => {
        const res = error.response
        console.log(res)
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 協会けんぽエラーリスト出力 モーダル
   * @param {*} res
   */
  showWS1227001_AssociateInsureErrorListCreatedSub = (res) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1227001_AssociateInsureErrorListCreatedSub
            Li_Child={true}
            Li_TargetPeopleNum={res?.variables.Li_TargetPeopleNum}
            Li_ErrorPeopleNum={res?.variables.Li_ErrorPeopleNum}
            onFinishScreen={() => {
              this.closeModal()
              // ダウンロード実行
              this.downloadZipFile(res?.filePath)
            }}
          />
        ),
      },
    })
  }

  /**
   * チェックボックス 選択
   * @param {*} record
   * @param {*} selected
   */
  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * 照会 - 個人情報照会
   * @param {*} record
   */
  showWS2584019_PersonalInfoInquirySub = (personalNum) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personalNum}
          />
        ),
      },
    })
  }

  /**
   * ZIPファイル ダウンロード
   * @param {*} path
   */
  downloadZipFile(path) {
    let params = {
      filePath: path
    }
    AssociateInsureDataCreateAction.downloadZipFile(params)
      .then((res) => {
        if (res) {
          // ZIPファイルダウンロード
          download_file_binary(res)
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
        message.error(res.data.message)
      })
  }

  /**
   * コード設定
   */
  showWS1220004_CodeSetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 350,
        component: (
          <WS1220004_CodeSetting
            Li_Child={true}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 送付日設定 ※必要なしの可能性あり
   */
  showWS1220005_SendDaySetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 350,
        component:
          <WS1220005_SendDaySetting
            DateSChar={this.state.DateSChar}
            onFinishScreen={(value) => {
              if (value !== null) {
                this.setState({
                  DateSChar: moment(value).format('YYYY/MM/DD')
                })
              }
              this.closeModal()
            }}
          />
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        component: null
      }
    })
  }

  render() {
    return (
      <div className='associate-insure-data-create'>
        <Card
          title='協会けんぽデータ作成'
          style={{ position: 'relative' }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              // icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      onClick={() => this.showWS1220004_CodeSetting()}>
                      コード設定
                    </Menu.Item>

                    <Menu.Item
                      key='4'
                      style={{ width: '100%' }}
                      onClick={() => this.showWS1220005_SendDaySetting()}>
                      送付日設定

                      {/* 送付日を設定する場合は↓の入力インプットをモーダルで設定させる
                      　　基本的には本日を送付日に設定してるので変更する必要ないらしい
                      　　送付日設定するか、しないかは保留中

                      <Form.Item name='DateSChar' label='送付日'>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          style={{ width: '100%' }}
                          allowClear={true}
                        />
                      </Form.Item> */}
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner' style={{ paddingTop: 0 }}>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <Form.Item
                        label='請求日'
                        name='PrintGivenYear'
                      >
                        <Input
                          className='input-size-number-4'
                          maxLength={4}
                          rules={[{ type: 'number' }]}
                          onKeyDown={(e) => moveFocusKeyEvent(e, 'kenpo-date', 4)}
                        />
                      </Form.Item>
                      <label id='input'>年</label>
                      <Form.Item name='PrintSpecifiedMonth' label='' >
                        <Input
                          id={'kenpo-date-1'}
                          className='input-size-number-2'
                          maxLength={2}
                          rules={[{ type: 'number' }]}
                          onBlur={(e) => this.onChangeSpecifiedMonth(e)}
                          onKeyDown={(e) => moveFocusKeyEvent(e, 'kenpo-date', 4)}
                        />
                      </Form.Item>
                      <label id='input'>月</label>
                      <Form.Item name='PrintingASpecifiedDateF' label='' >
                        <Input
                          id={'kenpo-date-2'}
                          className='input-size-number-2'
                          maxLength={2}
                          rules={[{ type: 'number' }]}
                          onBlur={(e) => this.onChangeSpecifiedDateF(e)}
                          onKeyDown={(e) => moveFocusKeyEvent(e, 'kenpo-date', 4)}
                        />
                      </Form.Item>
                      <label id='input'>日</label>
                      <label id='input'>～</label>
                      <Form.Item name='PrintingASpecifiedDateT' label='' >
                        <Input
                          id={'kenpo-date-3'}
                          className='input-size-number-2'
                          maxLength={2}
                          rules={[{ type: 'number' }]}
                          onBlur={(e) => this.onChangeSpecifiedDateT(e)}
                        />
                      </Form.Item>
                      <label id='input'>日まで</label>
                    </div>

                    <div style={{ display: 'flex', gap: '5px' }}>
                      <Form.Item
                        label='受診日'
                        name='DateFChar'
                        style={{ marginBottom: 0 }}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          allowClear={true}
                        />
                      </Form.Item>
                      <label >～</label>
                      <Form.Item name='DateTChar'
                        style={{ marginBottom: 0 }}
                      >
                        <VenusDatePickerCustom
                          format='YYYY/MM/DD'
                          allowClear={true}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/* 条件追加もかく！！ */}
                <div className='box_search_btn'>
                  <Button
                    onClick={() =>
                      // 協会けんぽデータを取得
                      this.index()
                    }
                    icon={<SearchOutlined />}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>
            </Form>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              onRow={(record, index) => ({
                onClick: (event) => {
                },
              })}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record, selected) => {
                  // 選択
                  this.onSelect(record, selected)
                },
                onSelectAll: (selected) => {
                  // 全選択
                  this.onSelectAll(selected)
                }
              }}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
            >
              <Table.Column
                title='受診日'
                className='column-size-10'
                dataIndex='visit_date_on'
                render={(text) => (
                  <div>{text ? moment(text).format('YYYY/MM/DD') : ''}</div>
                )}
                key=''
              />
              <Table.Column
                title='個人番号'
                dataIndex='personal_number_id'
                className='column-size-10'
                render={(value, record, index) =>
                  <div style={{ textAlign: 'right' }}>
                    {value === 0 ? '' : value}
                  </div>
                } />
              <Table.Column
                title='メモ'
                dataIndex='importance'
                width={45}
                render={(value, record, index) => {
                  let icon = null
                  switch (record.importance) {
                    case 1:
                      icon = (<InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />)
                      break
                    case 3:
                      icon = (<WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />)
                      break
                    case 5:
                      icon = (<CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />)
                      break
                    default:
                      icon = (<Button size='small' icon={<InfoOutlined />} style={{ borderRadius: '12px' }} />)
                      break
                  }
                  return (
                    <div
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center'
                      }}
                      hidden={!record.personal_number_id}
                      onClick={() => {
                        // 個人情報照会 モーダル
                        this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                      }}>
                      {icon}
                    </div>)
                }} />
              <Table.Column
                title='漢字氏名'
                dataIndex='kanji_name'
                className='column-size-40'
              />
              <Table.Column
                title='性別'
                dataIndex='Gender'
                width={60}
                render={(value) =>
                  <div
                    style={{
                      textAlign: 'center',
                      color: (value === '男性') ? 'blue' : 'red'
                    }}>
                    {value}
                  </div>
                }
              />
              <Table.Column
                title='年齢'
                dataIndex='Age'
                width={60}
                render={(value) =>
                  <div style={{ textAlign: 'right' }}>
                    {value === 0 ? '' : value}
                  </div>
                }
              />
              <Table.Column
                title='続柄'
                dataIndex='name'
                width={70}
              />
              <Table.Column
                title='記号'
                dataIndex='insurer_card_symbol'
                className='column-size-10'
              />
              <Table.Column
                title='番号'
                dataIndex='insurer_card_number'
                className='column-size-5'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                  )
                }}
              />
              <Table.Column
                title='事業所'
                dataIndex='office_kanji_name'
                className='column-size-40'
              />
              <Table.Column
                title='契約情報'
                dataIndex='visit_course'
                className='column-size-40'
                render={(value, record) => (
                  <div>
                    <span> {record.visit_course} </span>
                    <span style={{ marginLeft: '3px' }}> {record.contract_short_name} </span>
                  </div>
                )}
              />
              <Table.Column
                title='協会請求日'
                dataIndex='association_request_consultation_date_on'
                className='column-size-10'
                render={(text) => (
                  <>{text ? moment(text).format('YYYY/MM/DD') : ''}</>
                )}
              />
              <Table.Column
                title='協会受付No'
                dataIndex='association_acceptance_number'
                className='column-size-10'
                render={(value, record) =>
                  <div style={{ textAlign: 'right' }}>
                    {value === 0 ? '' : value}
                  </div>
                }
              />
              <Table.Column
                fixed='right'
                width={50}
                align='center'
                render={(value, record) =>
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='sub-1'
                          onClick={() => {
                            // 個人情報照会 モーダル
                            this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                          }}>
                          照会
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />} />
                  </Dropdown>
                }
              />
            </Table>
          </div>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<FileTextOutlined />}
              onClick={() => {
                this.createOutputFile()
              }}
              disabled={this.state.disabledBtn}
            >
              <span className='btn_label'>
                作成
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1220001_AssociateInsureDataCreate)
