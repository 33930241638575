import { message } from "antd";
import RomotoArticle52Service from "services/ResultOutput/RomotoArticle52/RomotoArticle52Service"

const RomotoArticle52Actions = {
  getScreenDataRomotoArticle52Action(params) {
    return RomotoArticle52Service.getScreenDataRomotoArticle52Service(params)
  },

  extractRomotoArticle52Action(params) {
    return RomotoArticle52Service.extractRomotoArticle52Service(params)
  },

  getDataIssueListAction(params) {
    return RomotoArticle52Service.getDataIssueListService(params)
  },

  selectAllRomotoArticle52Action(params) {
    return RomotoArticle52Service.selectAllRomotoArticle52Service(params)
  },

  selectRomotoArticle52Action(params) {
    return RomotoArticle52Service.selectRomotoArticle52Service(params)
  },

  getValueEnableAction(params) {
    return RomotoArticle52Service.getValueEnableService(params)
  },

  print(params) {
    return RomotoArticle52Service.print(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  preview(params) {
    return RomotoArticle52Service.preview(params)
  },

  getNameOfficeCodeRomotoArticle52Action(params) {
    return RomotoArticle52Service.getNameOfficeCodeService(params)
  }
}
export default RomotoArticle52Actions;
