import React from 'react';
import PropTypes from 'prop-types';
import axios from 'configs/axios';
import moment from 'moment';
import Color from 'constants/Color';
import { number_format } from 'helpers/CommonHelpers';
import { ReloadOutlined, PlusOutlined, } from '@ant-design/icons';
import { Form, Input, Card, Col, Row, Select, Table, Modal, Menu, Popconfirm, Dropdown, Button, Space, Spin, message, Tag, } from 'antd';
import { connect } from "react-redux";
import { Next } from 'react-bootstrap/esm/PageItem';
import WS3468002 from 'pages/ZZ_Others/Amendment/2024/WS3468002.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS3468001 extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '2024年度　特定健診・特定保健指導改正';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      contract: 0,
      Data: [],
      component: null,
    };
  }

  componentDidMount() {
    this.index()
  }

  index = () => {
    axios.get('/api/amendment/amendment-2024')
      .then(res => {
        console.log(res);
        let params = res.data
        axios.get('/api/amendment/amendment-2024/index', { params })
          .then(res => {
            console.log(res);
            this.setState({ Data: res.data })
            this.formRef.current?.setFieldsValue({
              Data: res ? res.data : ''
            })
          })
          .catch(error => {
            message.error(error)
          })
      })
      .catch(error => {
        message.error(error)
      })
  }

  next = () => {
    let params = this.state.Data
    axios.get('/api/amendment/amendment-2024/next', { params })
      .then(res => {
        console.log(res);
        // this.setState({ nextData: res })
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '60%',
            component: (
              <WS3468002
                params={this.state.Data}
                onFinishScreen={() => {
                  this.closeModal()
                }}
              />
            ),
          }
        })
      })
      .catch(error => {
        message.error(error)
      })
  }

  /**
 * モーダルを閉じる
 */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }




  render() {
    return (
      <div className="xml-document-query-select-sub">
        <Card title="2024年度　特定健診・特定保健指導改正">
          <Form ref={this.formRef}>
            <div>アルファサルースの2024年度、特定健診・特定保健指導改正処理を行います。<br />「次へ」ボタンを押してください。</div>
            {/* //!項目の取得の処理を追加する!! */}
            <Form.Item label='　　中性脂肪' style={{ paddingTop: 20 }}>
              <div className='box_inner_horizontal'>
                <div style={{ width: 80, textAlign: 'right' }}>{this.state.Data.VL0_中性脂肪検査ｺｰﾄﾞ}</div>
                <div>{this.state.Data.VL0_中性脂肪検査ｺｰﾄﾞ_exam_short_name}</div>
                <div>{this.state.Data.VL0_中性脂肪検査ｺｰﾄﾞ_exam_name}</div>
              </div>
            </Form.Item>

            <Form.Item label='内部中性脂肪' style={{ paddingBottom: 0 }}>
              <div className='box_inner_horizontal'>
                <div style={{ width: 80, textAlign: 'right' }}>{this.state.Data.VL0_内部中性脂肪検査ｺｰﾄﾞ}</div>
                <div>{this.state.Data.VL0_内部中性脂肪検査ｺｰﾄﾞ_exam_short_name}</div>
                <div>{this.state.Data.VL0_内部中性脂肪検査ｺｰﾄﾞ_exam_name}</div>
              </div>
            </Form.Item>

            <div style={{ color: 'red' }} hidden={this.state.Data.VL0_STS契約履歴情報}>２０２４年度の契約が作成されていません。<br />２０２４年度の契約を作成して、再度実行して下さい。</div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                disabled={!this.state.Data.VL0_STS契約履歴情報}
                onClick={() => { this.next() }}
              >
                次へ</Button>
            </div>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>

    );
  }
}


const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3468001);
