import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Card, Form, Input, Button, Row, Col } from "antd";

class WS0487007_JudgeHighlow extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Lio_RightHandSide: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = '判定のHighLow';

    this.state = {
    };
  }


  render() {
    return (
      <div className="judge-highlow">
        <Card title="判定のHighLow" className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div style={{ marginBottom: 15 }}>{'判定が' + this.props.comment + 'で基準値よりも'}</div>

            <div className="box_button_bottom_center">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    let data = 'Large'
                    this.props.onFinishScreen(data)
                  }}
                >
                  <span className='btn_label'>
                    大きい
                  </span>
                </Button>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    let data = 'Small'
                    this.props.onFinishScreen(data)
                  }}
                >
                  <span className='btn_label'>
                    小さい
                  </span>
                </Button>
              </Form.Item>
            </div>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0487007_JudgeHighlow);
