import React from "react";
import { connect } from "react-redux";
import { LeftOutlined, RightOutlined, MoreOutlined, DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import WS2712016_UseInspect from 'pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS2712016_UseInspect.jsx'
import WS0061015_CheckYesNoNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo.jsx';
import WS0061012_CheckYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061012_CheckYes.jsx';
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Table, Row, Col, Modal, Checkbox, Popconfirm, InputNumber, message } from "antd";
import { registerDataAction, deleteDataAction } from "redux/ExamCmtInfoMaintain/InspectCmtInfoMaintainSub.action";
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class WS2712001_InspectCmtInfoMaintainSub extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Lio_CmtCode: PropTypes.any,
    Lio_CmtClassify: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = '検査コメント情報保守 SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
    this.formRef.current.setFieldsValue({
      StsEnable: this.props.StsEnable
    })
    if (this.props.comment_division) {
      this.formRef.current.setFieldsValue({
        comment_division: this.props.comment_division
      })
    }
  }

  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let getId = this.props.record ? this.props.record.id : null

    let params = {
      ...values,
      changeData: values,
      id: getId,
      StsEnable: values.StsEnable,
      Lio_CmtClassify: this.props.Lio_CmtClassify,
      Lio_CmtCode: this.props.Lio_CmtCode
    };
    this.RegisterBtn(params);
  }

  RegisterBtn(params) {
    this.setState({ isLoading: true })
    registerDataAction(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => {
        ModalError(err?.response?.data?.message || "エラーが発生しました")
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    deleteDataAction({
      id: this.props.record.id
    })
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }
  showWS2712016_UserInspect() {
    const { childModal } = this.state;
    this.setState({
      childModal: {
        ...childModal,
        visible: true,
        width: 600,
        component: (<WS2712016_UseInspect
          Li_InspectCmtCode={this.formRef?.current?.CmtClassify}
          onFinishScreen={(data) => {
            this.closeModal()
          }}
        />),
      },
    })
  }


  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="登録・修正">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <Col span={7}>
              <Form.Item
                name="comment_division"
                label="コード"
                hidden={this.props.screenData ? true : false}
                {...smGrid}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}>
                <InputNumber
                  maxLength={8}
                  disabled={!(this.props.Lio_CmtClassify === 0)}
                />
              </Form.Item>

              <Form.Item
                name="exam_comment_code"
                label={this.props.screenData ? 'コード' : "入力値"}
                {...smGrid}
              >
                <Input type="text" maxLength={10} />
              </Form.Item>

              <Form.Item
                name="exam_comment_screen"
                label={this.props.screenData ? this.props.screenData.examCommentScreenTitle : "コメント(画面)"}
                {...smGrid}
              >
                <Input type="text" maxLength={10} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="exam_comment_form"
                label={this.props.screenData ? this.props.screenData.examCommentFormTitle : "コメント(帳票)"}
                {...smGrid}
              >
                <Input type="text" maxLength={1000} />
              </Form.Item>
            </Col>

            <Form.Item name="exam_comment_options" label="オプション" {...smGrid}>
              <Input type="text" maxLength={100} />
            </Form.Item>

            <Form.Item label="有効" name='StsEnable' valuePropName='checked' {...smGrid}>
              <Checkbox />
            </Form.Item>

            <Col span={7}>
              <Form.Item
                  name="display_order"
                  label='表示順'
                  {...smGrid}
                >
                  <Input type="text" maxLength={10} oninput="value = value.replace(/[^0-9]+/i,'');" />
              </Form.Item>
            </Col>

            <Form.Item >
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>

                <Button type="primary" onClick={() => { this.onFinish(); }}>
                  保存
                </Button>

                {this.props.Lio_CmtClassify ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.record.comment_division === '') ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.deleteData()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.record.Lio_CmtClassify === 0}
                    >
                      削除
                    </Button>
                  </Popconfirm> : null}

              </div>
            </Form.Item>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2712001_InspectCmtInfoMaintainSub);