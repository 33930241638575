import axios from "configs/axios";

const API_LIST = {
    getInit: "/api/associate-insure-param-maintain/aggregate-info-correct",
    save:"/api/associate-insure-param-maintain/aggregate-info-correct/save",
}

const AggregateInfoCorrect = {
    async getInit(params) {
        console.log('services_params->', params)
        return axios.get(API_LIST.getInit, {params});
    },
    async save(data) {
        return axios.post(API_LIST.save, data);
    },

};
export default AggregateInfoCorrect;