import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row, Col, Input, Form, message, Button } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import InsureGuideCourseInquiryAction from "redux/SpecificInsureGuide/InsureGuideInitInput/InsureGuideCourseInquiry.action"


class WS1384001_InsureGuideCourseInquiry extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00140:保健指導コース照会';

    this.state = {
      inSureGuideCourseInquiryData: [],
      finalcialSupportInquiryData: [],
      CourseName: '',
      insuranceLeadershipCourse: '',
      selectedRowKey: [],
      selectedRow: {},
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  componentDidUpdate = prevProps => {
    if (prevProps?.healthGuideDivision !== this.props?.healthGuideDivision) {
      this.loadScreenData();
    }
  }

  loadScreenData() {
    let params = {
      HealthGuideDivision: this.props?.healthGuideDivision
    }
    InsureGuideCourseInquiryAction.loadScreenData(params)
      .then(res => {
        // 右のテーブルを取得する
        this.getFinalcialSupportInquiryData(res[0])
        // 左のテーブルを選択状態にする
        this.setState({ inSureGuideCourseInquiryData: res, selectedRowKeys: res[0] });
        this.formRef.current?.setFieldsValue({ 'CourseName': res[0].course_name });
      }).catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
  }

  getFinalcialSupportInquiryData(record) {
    // ダブルクリックに対応するため
    if (record.insure_guide_course_code === this.state.selectedRowKeys?.insure_guide_course_code) {
      return
    }
    let params = {
      InsuranceLeadershipCourse: record.insure_guide_course_code
    }
    console.log(params);
    InsureGuideCourseInquiryAction.getScreenData(params)
      .then(res => {
        console.log(res);
        this.setState({ finalcialSupportInquiryData: res });
        this.formRef.current?.setFieldsValue({ 'CourseName': record.course_name });
      }).catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
  }

  render() {
    return (
      <div className="insure-guide-course-inquiry">
        <Card title="保健指導コース照会">
          <Form
            ref={this.formRef}
          >
            <div className="box_inner_vertical">
              <Form.Item name="CourseName" label="指導区分">
                <Input readOnly style={{ width: '250px' }} />
              </Form.Item>
              <div className="box_inner_horizontal">
                <Table
                  size="small"
                  style={{ width: '45%' }}
                  bordered
                  dataSource={this.state.inSureGuideCourseInquiryData ? this.state.inSureGuideCourseInquiryData : []}
                  loading={false}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: 10,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => { },
                    onShowSizeChange: (page, pageSize) => { },
                  }}
                  rowKey={(record) => record.id}
                  rowClassName={(record, index) => record.id === this.state.selectedRowKeys?.id ? 'table-row-light' : ''}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          selectedRowKeys: record,
                        });
                        this.getFinalcialSupportInquiryData(record)
                      },
                      onDoubleClick: () => {
                        if (this.props.onFinishScreen) {
                          this.props.onFinishScreen(this.state.selectedRowKeys)
                        }
                      }
                    };
                  }}
                >
                  <Table.Column title="保健指導コース" dataIndex="insure_guide_course_code" />
                  <Table.Column title="略    称" dataIndex="short_name" />
                  <Table.Column title="コース名称" dataIndex="course_name" />
                </Table>
                <Table
                  size="small"
                  style={{ width: '55%' }}
                  bordered
                  dataSource={this.state.finalcialSupportInquiryData ? this.state.finalcialSupportInquiryData : []}
                  loading={false}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: 10,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => { },
                    onShowSizeChange: (page, pageSize) => { },
                  }}
                  rowKey={(record) => record.id}
                >
                  <Table.Column title="支援内容" dataIndex="support" />
                  <Table.Column title="区分" dataIndex="SupportDivision" />
                  <Table.Column title="形態" dataIndex="SupportForm" />
                  <Table.Column title="値" dataIndex="support_unit_info_per_unit_value"
                    showSorterTooltip={false} />
                  <Table.Column title="単位" dataIndex="Unit" />
                  <Table.Column title="ポイント"
                    dataIndex="support_unit_info_per_unit_point" />
                  <Table.Column title="最低値"
                    dataIndex="restriction_info_lowest_value"
                    showSorterTooltip={false} />
                  <Table.Column title="最高値"
                    dataIndex="restriction_info_highest_value"
                    showSorterTooltip={false} />
                </Table>
              </div>
              <div className="box_button_bottom_right">
                <Button type="primary" onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen(this.state.selectedRowKeys);
                  }
                }}>選択</Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1384001_InsureGuideCourseInquiry);
