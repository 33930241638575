import axios from 'configs/axios'

const apiPaths = {
  index: '/api/mirais-single-transmission/mirais-single-transmission',
  getListData: '/api/mirais-single-transmission/mirais-single-transmission/get-list-data',
  modifyData: '/api/mirais-single-transmission/mirais-single-transmission/update',
  submitBtn: '/api/mirais-single-transmission/mirais-single-transmission/submit-btn',
  extractBtn: '/api/mirais-single-transmission/mirais-single-transmission/extract-btn',
  destroy: '/api/mirais-single-transmission/mirais-single-transmission/destroy',
  submitBtnBefore: '/api/mirais-single-transmission/mirais-single-transmission/submit-btn-before',
  sendMirais: '/api/mirais-single-transmission/mirais-single-transmission/send-mirais',
  sendStatusModifyOrder: '/api/mirais-single-transmission/mirais-single-transmission/send-status-modify-order',
  checkMiraisStartup: '/api/mirais-single-transmission/mirais-single-transmission/check-mirais-startup',
  editConfirm: '/api/mirais-single-transmission/mirais-single-transmission/edit-confirm',
  transmissionstatuschange: '/api/mirais-single-transmission/mirais-single-transmission/transmission-status-change'
}

const MiraisSingleTransmissionService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getListData(params) {
    return axios.get(apiPaths.getListData, { params })
  },
  async modifyData(params) {
    return axios.put(apiPaths.modifyData, params)
  },
  async submitBtnBefore(params) {
    return axios.get(apiPaths.submitBtnBefore, { params })
  },
  async submitBtn(params) {
    return axios.get(apiPaths.submitBtn, { params })
  },
  async extractBtn(params) {
    return axios.get(apiPaths.extractBtn, { params })
  },
  async destroy(params) {
    return axios.delete(apiPaths.destroy, { params })
  },
  async sendMirais(params) {
    return axios.get(apiPaths.sendMirais, { params })
  },
  async sendStatusModifyOrder(params) {
    return axios.post(apiPaths.sendStatusModifyOrder, params)
  },
  async checkMiraisStartup() {
    return axios.get(apiPaths.checkMiraisStartup)
  },
  async editConfirm(params) {
    return axios.post(apiPaths.editConfirm, params)
  },
  async transmissionstatuschange(params) {
    return axios.post(apiPaths.transmissionstatuschange, params)
  }
}

export default MiraisSingleTransmissionService
