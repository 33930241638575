import axios from 'configs/axios'

const API_LIST = {
  index: '/api/spread-input/findings-input-normal',
  getSiteFindingsInputThisTime: '/api/spread-input/findings-input-normal/get-site-findings-input-this-time',
  getSiteFindingsPrevious: '/api/spread-input/findings-input-normal/get-site-findings-previous',
  save: '/api/spread-input/findings-input-normal/save',
  delete: '/api/spread-input/findings-input-normal/delete',
  setPreviousFindingsDo: '/api/spread-input/findings-input-normal/set-previous-findings-do',
}

const FindingsInputNormalService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getSiteFindingsInputThisTime(params) {
    return axios.get(API_LIST.getSiteFindingsInputThisTime, { params })
  },
  async getSiteFindingsPrevious(params) {
    return axios.get(API_LIST.getSiteFindingsPrevious, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
  async setPreviousFindingsDo(params) {
    return axios.post(API_LIST.setPreviousFindingsDo, params)
  },
}

export default FindingsInputNormalService
