import axios from 'configs/axios'

const API_LIST = {
  index: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting',
  save: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting/save',
  // Delete: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting/delete',
  captureCourse: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting/capture-course',
  ChangeOfficeCode: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting/change-office-code',
  ChangeMedicalExamCourse: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting/change-medical-exam-course',
  ChangeStandardPrintingStyle: '/api/course-specific-stard-style-setting/course-specific-stard-style-setting/change-standard-printing-style',
}

const CourseSpecificStardStyleSettingService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  // async delete(params) {
  //   return axios.delete(API_LIST.Delete, { params })
  // },
  async captureCourse() {
    return axios.post(API_LIST.captureCourse)
  },
  async ChangeOfficeCode(params) {
    return axios.get(API_LIST.ChangeOfficeCode, { params })
  },
  async ChangeMedicalExamCourse(params) {
    return axios.get(API_LIST.ChangeMedicalExamCourse, { params })
  },
  async ChangeStandardPrintingStyle(params) {
    return axios.get(API_LIST.ChangeStandardPrintingStyle, { params })
  },
}

export default CourseSpecificStardStyleSettingService
