import React from 'react'
import { connect } from 'react-redux'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Tabs, Form, Input, Card, Table, Button, message, Checkbox, Modal, Tooltip } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import PassingManageSettingsAction from 'redux/Others/PassingManageSettings/PassingManageSettings.action'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'

const tableColumns = {
  0: [ // [通過項目]タブ
    { title: '通過番号', dataIndex: 'passing_number', width: 200, type: 'input', maxLength: 10 },
    { title: '略名', dataIndex: 'passing_informal_name', width: null, type: 'input', maxLength: 10 },
    { title: '正式', dataIndex: 'passing_official_name', width: null, type: 'input', maxLength: 30 },
    { title: '参照', dataIndex: 'check_inspection_results', width: 50, type: 'checkbox' },
    { title: '表示順', dataIndex: 'display_order', width: 80, type: 'input', textAlign: 'right' },
    { title: '一括', dataIndex: 'once_completed', width: 50, type: 'checkbox' },
    { title: '有効', dataIndex: 'enabled_disabled', width: 50, type: 'checkbox' },
    { title: '備考', dataIndex: 'option_remark', width: null, type: 'input' },
    { title: '', dataIndex: '', width: 40, type: 'event' },
    // { title: 'ME機器', dataIndex: 'passingitem_', width: null, type: 'input' },
  ],
  1: [ // [端末項目]タブ
    { title: '端末番号', dataIndex: 'terminal_num', width: 200, type: 'input', maxLength: 10 },
    { title: '略名', dataIndex: 'terminal_informal_name', width: null, type: 'input', maxLength: 10 },
    { title: '正式', dataIndex: 'terminal_official_name', width: null, type: 'input', maxLength: 30 },
    { title: '経過時間(秒)', dataIndex: 'seconds', width: 100, type: 'input', textAlign: 'right' },
    { title: '表示順', dataIndex: 'display_order', width: 80, type: 'input', textAlign: 'right' },
    { title: '同時', dataIndex: 'simultaneous_act', width: 50, type: 'checkbox' },
    { title: '有効', dataIndex: 'enabled_disabled', width: 50, type: 'checkbox' },
    { title: '備考', dataIndex: 'option', width: null, type: 'input' },
    { title: '', dataIndex: '', width: 40, type: 'event' },
  ],
  2: [ // [健診変換]タブ
    { title: '通過番号', dataIndex: 'passing_number', width: 200, type: 'input', maxLength: 10 },
    { title: '通過名称', dataIndex: 'passing_official_name', width: null, type: 'label' },
    { title: '健診検査', dataIndex: 'exam_item_code', width: 200, type: 'search', maxLength: 10 },
    { title: '検査名称', dataIndex: 'exam_name', width: null, type: 'label' },
    { title: '備考', dataIndex: 'option_remark', width: null, type: 'input' },
    { title: '', dataIndex: '', width: 40, type: 'event' },
  ],
  3: [ // [端末変換]タブ
    { title: '端末番号', dataIndex: 'terminal_num', width: 200, type: 'input', maxLength: 10 },
    { title: '端末名称', dataIndex: 'terminal_official_name', width: null, type: 'label' },
    { title: '通過番号', dataIndex: 'passing_num', width: 200, type: 'input', maxLength: 10 },
    { title: '検査名称', dataIndex: 'passing_official_name', width: null, type: 'label' },
    { title: '表示順', dataIndex: 'display_order', width: 80, type: 'input', textAlign: 'right' },
    { title: '管理', dataIndex: 'passing_manage', width: 50, type: 'checkbox' },
    { title: '', dataIndex: '', width: 40, type: 'event' },
  ]
}

// const pageSize = process.env.REACT_APP_PAGE_SIZE
const pageSize = 30

class WS3062001_PassingManageSettings extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '通過管理設定'

    this.selectedRows = []
    this.isNew = false

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: pageSize,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      deleteIdList: [],
      selectedRow: {},

      tabNum: 0,
      addCount: 0,
      disabledSaveBtn: true,
    }
    this.onChangeTab = this.onChangeTab.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getTabData(this.state.tabNum)
  }

  /**
   * タブ切替
   * @param {*} key
   */
  onChangeTab(key) {
    const index = Number(key)
    //初期化
    this.formRef.current?.setFieldsValue({
      dataTable: [],
    })
    this.setState({
      tabNum: index,
      dataSource: [],
    })
    this.getTabData(index)
  }

  /**
   * 各タブのデータ取得切替
   * @param {*} tabNum
   */
  getTabData(tabNum) {

    // 初期化
    this.setState({
      selectedRow: {},
      addCount: 0,
      deleteIdList: [],
      disabledSaveBtn: true,
    })

    // 各タブごとにデータ取得
    switch (tabNum) {
      case 0:
        this.getPassingItemList()
        break
      case 1:
        this.getTerminalEntryList()
        break
      case 2:
        this.getPassingMedicalExamList()
        break
      case 3:
        this.getTerminalPassingList()
        break
      default:
    }
  }

  /**
   * 通過項目　データ取得
   */
  getPassingItemList() {
    PassingManageSettingsAction.getPassingItemList()
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res
          })
          this.formRef.current.setFieldsValue({
            dataTable: res,
          })
        }
      })
  }

  /**
   * 端末項目　データ取得
   */
  getTerminalEntryList() {
    PassingManageSettingsAction.getTerminalEntryList()
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res
          })
          this.formRef.current.setFieldsValue({
            dataTable: res,
          })
        }
      })
  }

  /**
   * 健診変換　データ取得
   */
  getPassingMedicalExamList() {
    PassingManageSettingsAction.getPassingMedicalExamList()
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res
          })
          this.formRef.current.setFieldsValue({
            dataTable: res,
          })
        }
      })
  }

  /**
   * 端末変換　データ取得
   */
  getTerminalPassingList() {
    PassingManageSettingsAction.getTerminalPassingList()
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res
          })
          this.formRef.current.setFieldsValue({
            dataTable: res,
          })
        }
      })
  }

  /**
   * 入力値の変更
   * @param {*} record
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(record, field, value) {
    let data = [...this.state.dataSource]
    // 該当の行番号を取得
    let index = this.findIndexByID(data, record.id)

    let tempArr = [], obj
    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.formRef.current?.setFieldsValue({
      dataTable: tempArr
    })
    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
  }

  /**
   * 行の新規追加
   */
  async add() {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      selectedRow: { ...newRow },
      addCount: idCount,
    })

    this.formRef.current?.setFieldsValue({
      dataTable: data,
    })

    this.forceUpdate()
  }

  /**
   * 必須のコードが入力されているかのチェック
   * @returns
   */
  checkAddItem() {
    const data = [...this.state.dataSource]
    if (data.length > 0) {

      let index = -1
      switch (this.state.tabNum) {
        case 0:
          index = data.findIndex(x => !x.passing_number || !x.passing_informal_name || !x.passing_official_name)
          break
        case 1:
          index = data.findIndex(x => !x.terminal_num || !x.terminal_informal_name || !x.terminal_official_name)
          break
        case 2:
          index = data.findIndex(x => !x.passing_number || x.exam_item_code === '' || x.exam_item_code === null)
          break
        case 3:
          index = data.findIndex(x => !x.terminal_num || !x.passing_num)
          break
        default:
      }
      if (index === -1) {
        // 未入力あり
        return false
      }
      return true
    }
  }

  /**
   * 重複チェック
   * @returns
   */
  checkDuplicateCode() {
    let lstData = [...this.state.dataSource]

    let uniqueValues = 0
    switch (this.state.tabNum) {
      case 0:
        uniqueValues = new Set(lstData.map(v => v.passing_number))
        break
      case 1:
        uniqueValues = new Set(lstData.map(v => v.terminal_num))
        break
      case 2:
        uniqueValues = new Set(lstData.map(v => v.passing_number + String(v.exam_item_code)))
        break
      case 3:
        uniqueValues = new Set(lstData.map(v => v.passing_num + v.terminal_num))
        break
      default:
    }
    const uniqueIds = new Set(lstData.map(v => v.id))
    if (uniqueIds.size < lstData.length || uniqueValues.size < lstData.length) {
      // 重複あり
      return true
    }
    return false
  }

  /**
   * 保存ボタン　処理
   * @returns
   */
  save = () => {
    if (this.checkDuplicateCode()) {
      message.error('番号が重複しています')
      return
    }
    if (this.checkAddItem()) {
      message.error('必須項目の入力ができていません')
      return
    }

    // パラメータ
    let params = {
      changeData: this.state.dataSource,
      deleteIdList: this.state.deleteIdList
    }

    // 各タブごとの保存処理
    switch (this.state.tabNum) {
      case 0:
        this.savePassingItemList(params)
        break
      case 1:
        this.saveTerminalEntryList(params)
        break
      case 2:
        this.savePassingMedicalExamList(params)
        break
      case 3:
        this.saveTerminalPassingList(params)
        break
      default:
    }
  }

  /**
   * 保存処理
   * @param {*} index
   */
  saveRecord(index) {

    let params = this.formRef.current.getFieldValue('dataTable')[index]

    switch (this.state.tabNum) {
      case 0:
        this.savePassingItemList(params)
        break
      case 1:
        this.saveTerminalEntryList(params)
        break
      case 2:
        this.savePassingMedicalExamList(params)
        break
      case 3:
        this.saveTerminalPassingList(params)
        break
      default:
    }
  }

  /**
   * 保存　通過項目
   * @param {*} params 
   */
  savePassingItemList(params) {
    PassingManageSettingsAction.savePassingItemList(params)
      .then((res) => {
        if (res) {
          // データ再取得
          this.getTabData(this.state.tabNum)
        }
      })
  }

  /**
   * 保存　端末項目
   * @param {*} params 
   */
  saveTerminalEntryList(params) {
    PassingManageSettingsAction.saveTerminalEntryList(params)
      .then((res) => {
        if (res) {
          // データ再取得
          this.getTabData(this.state.tabNum)
        }
      })
  }

  /**
   * 保存　健診変換
   * @param {*} params 
   */
  savePassingMedicalExamList(params) {
    PassingManageSettingsAction.savePassingMedicalExamList(params)
      .then((res) => {
        if (res) {
          // データ再取得
          this.getTabData(this.state.tabNum)
        }
      })
  }

  /**
   * 保存　端末変換
   * @param {*} params 
   */
  saveTerminalPassingList(params) {
    PassingManageSettingsAction.saveTerminalPassingList(params)
      .then((res) => {
        if (res) {
          // データ再取得
          this.getTabData(this.state.tabNum)
        }
      })

  }

  /**
   * 削除処理
   * @param {*} id
   */
  delete = (id) => {

    let data = [...this.state.dataSource]
    const index = this.findIndexByID(data, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.formRef.current?.setFieldsValue({
      dataTable: data
    })
    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })

    // 削除リストに追加
    let list = [...this.state.deleteIdList]
    list.push(id)
    this.setState({
      deleteIdList: list
    })
  }

  /**
   * 検査項目検索・照会(単品)　モーダル
   * @param {*} record 
   */
  showWS0271001_InspectItemSearchQuerySingle = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        centered: true,
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            onFinishScreen={async (event) => {
              // ※exam_item_code注意
              await this.updateDatasource(record, 'exam_item_code', event.recordData.test_item_code)
              await this.updateDatasource(record, 'exam_name', event.recordData.exam_name)
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  /**
  * idから行番号を取得
  * @param {*} arrayData
  * @param {*} recordID
  * @returns
  */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * 各タブの内容（テーブルカラムの切替）
   * @param {*} tabNum
   * @returns
   */
  renderColumnTable = (val, rowIndex, tabNum) => {
    const columnKey = `${val.dataIndex}_${tabNum}_${rowIndex}`

    switch (val.type) {
      case 'input':
        return (
          <Table.Column
            title={val.title}
            dataIndex={val.dataIndex}
            width={val.width}
            key={columnKey}
            render={(value, record, index) => {
              return (
                <Form.Item name={['dataTable', this.findIndexByID(this.state.dataSource, record.id), val.dataIndex]} style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      pattern: (
                        (val.dataIndex === 'terminal_num' || val.dataIndex === 'display_order' || val.dataIndex === 'exam_item_code' || val.dataIndex === 'seconds') ? /^[\d]+$/ : //半角数字のみ
                          (val.dataIndex === 'passing_number' || val.dataIndex === 'passing_num') ? /^[ -~]+$/ : //半角英数字のみ
                            ''
                      ),
                      message: (
                        (val.dataIndex === 'terminal_num' || val.dataIndex === 'display_order' || val.dataIndex === 'exam_item_code' || val.dataIndex === 'seconds') ? '半角数字で入力してください' : //半角数字のみ
                          (val.dataIndex === 'passing_number' || val.dataIndex === 'passing_num') ? '半角英数字で入力してください' : //半角英数字のみ
                            ''
                      )
                    },
                  ]}
                >
                  <Input
                    style={{
                      textAlign: ((val.textAlign ?? '') === 'right') ? 'right' : '',
                      fontWeight: (record.changeFlag ?? 0) ? 'bold' : 'normal', //変更時は太字
                    }}
                    type={(val.dataIndex === 'terminal_num' || val.dataIndex === 'display_order' || val.dataIndex === 'exam_item_code' || val.dataIndex === 'seconds') ? 'tel' : 'text'}
                    maxLength={val.maxLength}
                    onPressEnter={(e) => { this.updateDatasource(record, val.dataIndex, e.target.value) }}
                    onBlur={(e) => { this.updateDatasource(record, val.dataIndex, e.target.value) }}
                  />
                </Form.Item>
              )
            }}
          />
        )
      case 'search':
        return (
          <Table.Column
            title={val.title}
            dataIndex={val.dataIndex}
            width={val.width}
            key={columnKey}
            render={(value, record, index) => {
              return (
                <Form.Item name={['dataTable', this.findIndexByID(this.state.dataSource, record.id), val.dataIndex]} style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      pattern: /^[\d]+$/,
                      message: '半角数字で入力してください'
                    },
                  ]}
                >
                  <Input.Search
                    className={(record.changeFlag ?? 0) ? 'input-search-bold' : ''}
                    maxLength={val.maxLength}
                    onSearch={(value, event) => {
                      // 検査照会
                      this.showWS0271001_InspectItemSearchQuerySingle(record)

                    }}
                  />
                </Form.Item>
              )
            }}
          />
        )
      case 'checkbox':
        return (
          <Table.Column
            title={val.title}
            dataIndex={val.dataIndex}
            width={val.width}
            key={columnKey}
            render={(value, record, index) => {
              return (
                <div style={{ textAlign: 'center' }}>
                  <Form.Item name={['dataTable', this.findIndexByID(this.state.dataSource, record.id), val.dataIndex]}
                    valuePropName='checked'>
                    <Checkbox
                      onChange={(e) => { this.updateDatasource(record, val.dataIndex, e.target.checked) }}
                    />
                  </Form.Item>
                </div>
              )
            }}
          />
        )
      case 'label':
        return (
          <Table.Column
            title={val.title}
            dataIndex={val.dataIndex}
            width={val.width}
            key={columnKey}
            render={(value, record) =>
              <div style={{
                fontWeight: (record.changeFlag ?? 0) ? 'bold' : 'normal', //変更時は太字
              }}
              > {value}</ div>
            }
          />
        )
      case 'event':
        return (
          <Table.Column
            key={`event_${tabNum}_${rowIndex}`}
            width={val.width}
            fixed={'right'}
            title={
              <div style={{ textAlign: 'center' }}>
                <Tooltip title={'新規追加'}>
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => this.add()}
                  >
                  </Button>
                </Tooltip>
              </div>
            }
            render={(text, record, index) => {
              return (
                <div style={{ textAlign: 'center' }}>
                  <Tooltip title={'削除'}>
                    <Button
                      size='small'
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => this.delete(record.id)}
                    >
                    </Button>
                  </Tooltip>
                </div>
              )
            }}
          />
        )
      default:
        return null
    }
  }

  /**
   * 各タブの内容（テーブル）
   * @param {*} tabNum
   * @returns
   */
  renderBodyTab = (tabNum) => {
    return (
      <div className='box_container'>
        <Table
          key={`table-${tabNum}`}
          bordered
          size='small'
          dataSource={this.state.dataSource}
          pagination={this.state.dataSource.length > pageSize ? this.state.pagination : false}
          rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
          scroll={{ y: 550 }}
          rowKey={(record) => record.id}
          onRow={(record, rowIndex) => {
            return {
              onClick: async () => {
                await this.setState({
                  selectedRow: record
                })
              }
            }
          }}
        >
          {tableColumns[tabNum].map((val, index) => this.renderColumnTable(val, index, tabNum))}
        </Table>
      </div>
    )
  }

  render() {
    return (
      <div className='passing-manage-settings'>
        <Card title='通過管理設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Tabs
              type='card'
              defaultActiveKey={this.state.tabNum}
              onChange={this.onChangeTab}
            >
              <Tabs.TabPane tab='通過項目' key={0} >
                {this.renderBodyTab(0)}
              </Tabs.TabPane>
              <Tabs.TabPane tab='端末項目' key={1} >
                {this.renderBodyTab(1)}
              </Tabs.TabPane>
              <Tabs.TabPane tab='健診変換' key={2}>
                {this.renderBodyTab(2)}
              </Tabs.TabPane>
              <Tabs.TabPane tab='端末変換' key={3} >
                {this.renderBodyTab(3)}
              </Tabs.TabPane>
            </Tabs>
          </Form>

          <div className='box_button_bottom_right'>
            <Tooltip title='保存ボタンを押下するまで編集内容や削除はデータベースに反映されません'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.save()}
                disabled={this.state.disabledSaveBtn}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </Tooltip>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3062001_PassingManageSettings)
