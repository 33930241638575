import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'assets/scss/_devextreme_customize_export.scss';
import PassingManageProgressAction from 'redux/Others/PassingManageProgress/PassingManageProgress.action'
import { Form, Card, Button, Checkbox, Tooltip, Dropdown, InputNumber, Menu, Modal, Skeleton } from 'antd'
import DataGrid, { Column, Grouping, Export } from 'devextreme-react/data-grid'
// Excel出力
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
// Excel出力に使う
import { exportDataGrid } from 'devextreme/excel_exporter'
import { MenuOutlined, QuestionCircleTwoTone, PrinterOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS3059005_TerminalList from './WS3059005_TerminalList'
import WS3059008_PassingList from './WS3059008_PassingList'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import ResultDisplay from "pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx"

const titleStyle = { writingMode: 'vertical-rl', marginLeft: -2 }

const exportFormats = ['pdf'];

/**
 * リロード時間最低値(s)
 * @type {number}
 */
const reloadTimeMin = 10;

class WS3059001_PassingManageProgress extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '通過管理進捗状況'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      ImplementDateChar: '',
      PassingNumList: '',
      PassingNameList: '',
      dataSource: [],
      targetReserveNum: '',
      ColumnIsvisible: true,
      ResultDisplayUpdateBtn: true,

      // 全体メモ保存ボタンの活性化情報
      AllMemoFunctionUpdateBtn: true,
      /**
       * リロード時間(s)
       * @type {number}
       */
      reloadTime: 60,
      /**
       * 前回の再帰処理が入力によって更新されているかどうかを判別する数字
       * @type {number}
       */
      reloadTimeChangeCount: 0,
    }
    // 初回のリロード処理を起動
    this.recursionReload(this.state.reloadTimeChangeCount);
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 繰り返し実行　開始
    // this.startInterval()
    this.index()
  }

  /**
   * モーダルが開閉する時
   * @param {*} prevState
   */
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.childModal) {
  //     if (this.state.childModal.visible !== prevState.childModal.visible) {
  //       // 小モーダルが開いている時は繰り返し実行はしない
  //       if (this.state.childModal.visible) {
  //         // 繰り返し実行　停止
  //         clearInterval(this.interval)
  //       } else {
  //         // 繰り返し実行　開始
  //         this.startInterval()
  //       }
  //     }
  //   }
  // }

  /**
   * ページから離れる時
   */
  // componentWillUnmount() {
  //   // 繰り返し実行　停止
  //   clearInterval(this.interval)
  // }

  // /**
  //  * 繰り返し実行　開始
  //  */
  // startInterval() {
  //   this.interval = setInterval(() => {

  //     // データ再取得
  //     this.redisplay()
  //   }, process.env.REACT_APP_INTERVAL)
  // }

  /**
   * データ取得
   */
  index() {
    let date = ''
    if (this.props.ImplementDateChar) {
      date = this.props.ImplementDateChar
    }

    let param = {
      PassingNumList: '',
      PassingNameList: '',
      ImplementDate: date,
    }
    PassingManageProgressAction.index(param)
      .then(async (res) => {
        await this.formRef.current?.setFieldsValue({
          ImplementDateChar: moment(res.ImplementDateChar),
          StsMore: res.StsMore
        })
        await this.setState({
          PassingNumList: res.PassingNumList,
          ImplementDateChar: res.ImplementDateChar,
          PassingNameList: res.PassingNameList,
        })
        await this.getStatusList(res.ImplementDateChar, res.StsMore)
      })
  }

  /**
   * リロード時間入力時の処理 stateの更新
   * @param {number} val リロード時間(入力値)
   */
  onChangeReloadTime(val) {
    this.setState({ reloadTime: val !== null ? val : '' })
  }

  /**
   * リロード再帰処理開始用メソッド
   */
  onBlurReloadTime() {
    // onChangeとonBlurが同時に走った場合onBlurを後に走らせるためにsetTimeoutする
    setTimeout(() => {
      // 前回の再帰処理を止めるためにそれを判別する変数を+1する
      this.state.reloadTimeChangeCount++;
      this.recursionReload(this.state.reloadTimeChangeCount);
    });
  }

  /**
   * リロードを実行する再帰処理
   * @param {number} reloadTimeChangeCount 前回の再帰処理が入力によって更新されているかどうかを判別する数字
   * これがstateの同名の変数と一致しない場合再帰を抜ける
   */
  recursionReload(reloadTimeChangeCount) {
    if (
      this.state.reloadTime === '' ||
      reloadTimeChangeCount !== this.state.reloadTimeChangeCount
    ) { return; }

    setTimeout(() => {
      if (reloadTimeChangeCount !== this.state.reloadTimeChangeCount) { return; }
      // 再表示実行
      this.redisplay();
      this.recursionReload(reloadTimeChangeCount);
    }, this.state.reloadTime * 1000)
  }

  /**
   * 再表示
   */
  redisplay() {
    let date = this.formRef.current.getFieldValue('ImplementDateChar') ? moment(this.formRef.current.getFieldValue('ImplementDateChar')).format('YYYY/MM/DD') : ''
    let more = this.formRef.current.getFieldValue('StsMore')
    this.getStatusList(date, more)
  }

  /**
   * 全表示の変更
   * @param {*} event
   */
  changeAllShow(event) {
    let date = this.formRef.current.getFieldValue('ImplementDateChar') ? moment(this.formRef.current.getFieldValue('ImplementDateChar')).format('YYYY/MM/DD') : ''
    let more = event.target.checked
    this.getStatusList(date, more)
  }

  /**
   * 実施日の変更
   * @param {*} event
   */
  changeImplementpDate(event) {
    if (event === null) {
      this.formRef.current?.setFieldsValue({ ImplementDateChar: null })
    } else {
      let date = event.format('YYYY/MM/DD')
      let more = this.formRef.current.getFieldValue('StsMore')
      this.setState({ ImplementDateChar: date })

      this.formRef.current?.setFieldsValue({ ImplementDateChar: moment(date) })
      this.getStatusList(date, more)
    }
  }

  /**
   * データ一覧取得
   * @param {*} date
   * @param {*} more
   */
  getStatusList(date, more) {
    let param = {
      Li_PassingNumList: this.state.PassingNumList,
      Li_ImplementDate: date,
      Li_More: (more) ? 1 : 0,
    }
    this.setState({ ColumnIsvisible: false })
    PassingManageProgressAction.getStatusList(param)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res,
            ColumnIsvisible: true
          })
        }
      })
  }

  /**
   * 端末項目 モーダル
   */
  showWS3059005_TerminalList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS3059005_TerminalList
            ImplementDateChar={this.state.ImplementDateChar}
            history={this.props.history}
            onFinishScreen={() => {
              this.closeModal()
            }}
            onRedisplay={() => {
              this.redisplay()
            }}
          />
        ),
      }
    })
  }

  /**
   * 通過項目 モーダル
   */
  showWS3059008_PassingList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS3059008_PassingList
            ImplementDateChar={this.state.ImplementDateChar}
            history={this.props.history}
            onFinishScreen={() => {
              this.closeModal()
            }}
            onRedisplay={() => {
              this.redisplay()
            }}
          />
        ),
      }
    })
  }

  /**
 * 総合結果表示/入力 モーダル
 * @param {*} record
 */
  showResultDisplay = (record, index) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.reserve_num}
            protectFlag={record.protectFlag}
            provisionalFlag={record.provisionalFlag}
            onUpdate={() => {
              this.index()
            }}
            // 結果入力画面での保存ボタンの状態
            onFlagUpdate={(bool) => {
              this.setState({ ResultDisplayUpdateBtn: bool })
            }}
          />
        ),
      },
    })
  }

  /**
   * 受付後のキャンセル処理
   */
  receptionAfterCancel(record) {
    let prams = {
      ...record,
      Li_PassingNumList: this.state.PassingNumList,
    }

    PassingManageProgressAction.receptionAfterCancel(prams)
      .then((res) => {
        if (res) {
          this.redisplay()
        }
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  /**
   * csv出力
   * @param {*} e 
   */
  csvCreate(e) {
    console.log(e.component);
    // Excel
    const workbook = new Workbook();
    // シート指定
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      // Excel出力 ファイル名指定
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), '通過管理エクセル出力.xlsx');
      });
    });
  }

  render() {
    const format = 'YYYY/MM/DD'
    return (
      <div className='passing-manage-progress'>
        <Card title='通過管理進捗状況'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.ImplementDateChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      disabled={!(this.formRef.current?.getFieldValue('ImplementDateChar') ?? false)}
                      onClick={() => {
                        this.showWS3059005_TerminalList()
                      }}
                    >
                      端末項目
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      disabled={!(this.formRef.current?.getFieldValue('ImplementDateChar') ?? false)}
                      onClick={() => {
                        this.showWS3059008_PassingList()
                      }}
                    >
                      通過項目
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.ImplementDateChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form ref={this.formRef}>
              <div className='box_inner_horizontal' style={{ alignItems: 'center' }}>
                <div className='box_search'>
                  <Form.Item
                    name='ImplementDateChar'
                    label='実施日'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      onChange={(event) => this.changeImplementpDate(event)}
                    />
                  </Form.Item>

                  <Form.Item
                    name='StsMore'
                    valuePropName='checked'
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox
                      onChange={(event) => this.changeAllShow(event)}
                    >
                      全表示
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button
                  type='primary'
                  onClick={() => this.redisplay()}>
                  再表示
                </Button>
                {/* <Form.Item
                  label='バーコード'
                  style={{ marginBottom: 0, marginLeft: 'auto' }}
                  name='barcode'
                >
                  <Input
                    placeholder='予約番号'
                    disabled={this.state.dataSource.length === 0}
                    className='input-size-20'
                    onPressEnter={(e) => {
                      this.setState({
                        targetReserveNum: e.target.value
                      })
                    }}
                  />
                </Form.Item> */}
              </div>
            </Form>
            <div id='passing_manage_table'>
              <Skeleton loading={!this.state.ColumnIsvisible}>
                <DataGrid
                  style={{ height: 700 }}
                  dataSource={this.state.dataSource}
                  showBorders={true} //周りの線
                  showRowLines={true} //横線
                  wordWrapEnabled={true} //折り返し
                  allowColumnReordering={true} //カラム移動
                  allowColumnResizing={true} //カラムの幅リサイズ
                  columnResizingMode={'widget'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                  // columnChooser={{ enabled: true, mode: 'select' }} //カラム表示選択
                  paging={{
                    // ペジネーション設定
                    enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                  }}
                  scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない
                  onRowPrepared={(record) => {
                    // 行ごとのクラス
                    if (record.rowType === 'data') {
                      if (record.data.carried_out_order === 0) {
                        record.rowElement.style.backgroundColor = '#efefef'
                      }
                      if (record.data.waiting_Number > 20) {
                        record.rowElement.style.backgroundColor = '#FFFACD'
                      }
                    }
                  }}
                  onRowDblClick={(event) => {
                    const record = event.data
                    if (record.sort_Flag === 0) {
                      // 総合結果表示/入力 モーダル
                      this.showResultDisplay(record)
                    }
                  }}
                  // Excel出力 ExportとonExportingはセット
                  onExporting={(e) => this.csvCreate(e)}
                  // exportに対してもクラスネーム
                  className="export_button"
                >
                  {/* エクセル出力ボタン */}
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Column
                    caption='時間' //タイトル
                    dataField='period_time' //dataIndex
                    width={62}
                    fixed={true} //カラム固定
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='受付No'
                    dataField='carried_out_order'
                    width={45}
                    fixed={true}
                    cellRender={(event) => {
                      return (
                        <div style={{ textAlign: 'right' }}>
                          {event.value === 0 ? '' : event.value}
                        </div>
                      )
                    }}
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='個人番号'
                    dataField='personal_number_id'
                    cssClass='column-size-8'
                    fixed={true}
                    cellRender={(event) =>
                      <div style={{ textAlign: 'right' }}>{event.value}</div>
                    }
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='メモ'
                    dataField='consultationMemo'
                    width={35}
                    fixed={true}
                    cellRender={(event) => {
                      const record = event.data
                      const value = event.value
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 'fit-content',
                                centered: true,
                                component: (
                                  <AllMemosFunction
                                    date={this.formRef.current?.getFieldValue('date')}
                                    reservation_number={record.reserve_num}
                                    personal_number_id={record.personal_number_id}
                                    KanjiName={record.kanji_name}
                                    onFinishScreen={async () => {
                                      await this.closeModal();
                                      await this.redisplay();
                                    }}

                                    // メモ全体入力画面での保存ボタンの状態
                                    onFlagUpdate={(bool) => {
                                      console.log('onFlagUpdate:' + bool);
                                      this.setState({
                                        AllMemoFunctionUpdateBtn: bool
                                      })
                                    }}
                                  />
                                ),
                              }
                            })
                          }}
                        >
                          {value.length === 0 ?
                            null
                            :
                            <div
                              style={{
                                color: value[0].color,
                              }}
                            >
                              {value[0].text}
                            </div>
                          }
                        </div>
                      )
                    }}
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='カナ氏名'
                    dataField='kana_name'
                    width={75}
                    fixed={true}
                    cellRender={(event) =>
                      <Tooltip
                        title={event.value}
                      >
                        <div style={{
                          color: event.data.Gender === '男性' ? '#0F3278' : '#B41432', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                        }}>
                          {event.value}
                        </div>
                      </Tooltip>
                    }
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='漢字氏名'
                    dataField='kanji_name'
                    width={75}
                    fixed={true}
                    cellRender={(event) =>
                      <Tooltip
                        title={event.value}
                      >
                        <div style={{
                          overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                        }}>
                          {event.value}
                        </div>
                      </Tooltip>
                    }
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='性別'
                    dataField='Gender'
                    width={35}
                    fixed={true}
                    cellRender={(event) =>
                      <span style={{ color: event.value === '男性' ? '#0F3278' : '#B41432' }}>
                        {event.value === '男性' ? '男' : ''}
                        {event.value === '女性' ? '女' : ''}
                      </span>
                    }
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='年齢'
                    dataField='Age'
                    width={35}
                    fixed={true}
                    cellRender={(event) =>
                      <div style={{ textAlign: 'right' }}>{event.value}</div>
                    }
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='コース'
                    dataField='contract_short_name'
                    fixed={true}
                    width={85}
                    cellRender={(event) =>
                      <Tooltip
                        title={event.value}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {event.value}
                        </div>
                      </Tooltip>
                    }
                    visible={this.state.ColumnIsvisible}
                  />

                  <Column
                    caption='待ち時間'
                    dataField='waiting_time'
                    fixed={true}
                    width={50}
                    visible={this.state.ColumnIsvisible}
                  />

                  {this.state.PassingNumList.split(',')?.map((item, index) => {
                    let title = this.state.PassingNameList.split(',')[index]
                    let dateIndex = 'Inspect' + index

                    // 半角を全角に
                    const titleString = (params) =>
                      params.replace(/[A-Za-z0-9]/g, function (s) {
                        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
                      });

                    // 各検査のカラム
                    return (
                      <Column
                        key={'column-' + index}
                        dataField={dateIndex}
                        width={35}
                        alignment='center'
                        caption={title}
                        headerCellRender={() =>
                          <div style={titleStyle} >
                            {titleString(title)}
                          </div>
                        }
                        visible={this.state.ColumnIsvisible}
                      />)
                  })}

                  <Column
                    alignment='center'
                    fixedPosition='right'
                    width={40}
                    cellRender={(event) => {
                      const record = event.data
                      return (
                        record.id == 'total' ?
                          null
                          :
                          <Dropdown
                            trigger='click'
                            size='small'
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='キャンセル'
                                  onClick={() =>
                                    this.receptionAfterCancel(record)
                                  }
                                >
                                  キャンセル
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              size='small'
                              icon={<MoreOutlined />}
                            />
                          </Dropdown>
                      )
                    }}
                    visible={this.state.ColumnIsvisible}
                  />
                </DataGrid>
              </Skeleton>
            </div>
            <div className='box_button_bottom_right'>
              <Form.Item
                label='リロード時間'
              >
                <InputNumber min={reloadTimeMin}
                  value={this.state.reloadTime}
                  onChange={(val) => {
                    this.onChangeReloadTime(val)
                  }}
                  onBlur={() => {
                    this.onBlurReloadTime()
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.ResultDisplayUpdateBtn && this.state.AllMemoFunctionUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    AllMemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3059001_PassingManageProgress)
