import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import { WarningOutlined, } from '@ant-design/icons';
import { Modal, } from "antd";
import { Card, Form, Input, Button, Table, Checkbox, Space, Radio, message, Tooltip, Dropdown, Menu, Spin } from "antd";
import { SearchOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import SpecificHealthDataXMLOutputAction from "redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/SpecifiHealthDataXMLOutput.action";
import WS1290001_InsurerNumberInquiry from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry";
import WS1302001_AgencyInquirySub from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302001_AgencyInquirySub";
import WS2655083_DetailsExtract from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655083_DetailsExtract";
import WS2655078_OutputConfirm from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655078_OutputConfirm";
import WS2655106_ResultConfirm from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655106_ResultConfirm"
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import WS1367002_MemberList from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1367002_MemberList";
import WS1296010_ConsultTicketInputSub from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296010_ConsultTicketInputSub.jsx"

const dateFormat = "YYYY/MM/DD";

class WS2655001_SpecificHealthDataXmlOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '特健データXML出力';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      Paginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      initialValues: {
        AgencyName: null,
        KanaName: null,
        insurer_kanji_name: "",
        GdateFChar: '',
        GdateTChar: '',
        Gagency: null,
        GsettlementInfoCreation: true,
        Gtype: 1,
        GinsuranceNum: '',
        GsettlementFreeConsultTicketYes: true,
        ReceiptNumF: "",
        ReceiptNumT: "",
        CourseCodeF: "",
        CourseCodeT: "",
        CourseList: "",
        TimeDivision: 1,
        FacilityType: "",
        StateFlag: 1,
        Lio_Insurer: null,
        OfficeCode: null,
        BranchStoreCode: null,
        PersonalNum: null,
        Lio_DisplayOrder: 1,
        Lio_AgeF: '',
        Lio_AgeT: '',
        Lio_Relationship: '9',
        Lio_ContractDivision: 0,
        Lio_ReCreateNewAnd: 3,
        Target: 0,
        StsSettlement: true,
        GfilingDate: moment(new Date()),
        GcreationDate: moment(new Date()),
        OptionXml01010: '',
        XmlCheckVersion: '',
        Goutput: '',
        GinsurerCode: ''
      },
      Gl0AgencyName: "",
      insurer_kanji_name: '',
      isShow: true,
      isChecked: false,
      dataSpecificHealth: [],
      selectedRowKeys: [],
      selectedRows: [],
      isLoading: false,
      MemberListFlag: false,
      selectAll: false,
    };

    this.onChangeCreation = this.onChangeCreation.bind(this);
    this.showModalInquiry = this.showModalInquiry.bind(this);
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    SpecificHealthDataXMLOutputAction.getScreenData()
      .then((res) => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            ...res,
            Target: 0,
            GinsuranceNum: 0,
            GinsurerCode: 0
          }
        });
        this.formRef.current?.setFieldsValue({
          GdateFChar: moment(res.GdateFChar),
          GdateTChar: moment(res.GdateTChar),
          Gagency: res.Gagency
        })
      })
  }

  MemberList(bool, name) {
    const Gagency = this.formRef.current.getFieldValue("Gagency");
    const GinsuranceNum = this.formRef.current.getFieldValue("GinsuranceNum");
    const GdateFChar = this.formRef.current.getFieldValue("GdateFChar")
    const GdateTChar = this.formRef.current.getFieldValue("GdateTChar")
    if (!Gagency && this.state.initialValues.Gtype === 1) {
      this.showModalInquiry();
      return;
    }
    if (!GinsuranceNum && this.state.initialValues.Gtype === 6) {
      this.insurerNumModal();
      return;
    }
    if (!GdateFChar || !GdateTChar) {
      message.error('受診日を入力して下さい')
      return
    }
    let TimeDivision = this.state.initialValues.TimeDivision
    if (TimeDivision === 1) {
      TimeDivision = ''
    } else if (TimeDivision === 2) {
      TimeDivision = 'AM'
    } else if (TimeDivision === 3) {
      TimeDivision = 'PM'
    }
    let params = {
      Li_DateF: this.state.initialValues.GdateFChar,
      Li_DateT: this.state.initialValues.GdateTChar,
      Li_KanaName: (name !== undefined) ? name : this.state.initialValues.KanaName,
      Li_PersonalNum: this.state.initialValues.PersonalNum,
      Li_Office: this.state.initialValues.OfficeCode,
      Li_CourseF: this.state.initialValues.CourseCodeF,
      Li_CourseT: this.state.initialValues.CourseCodeT,
      TimeDivision: TimeDivision,
      Li_AcceptNoF: this.state.initialValues.ReceiptNumF ?? '',
      Li_AcceptNoT: this.state.initialValues.ReceiptNumT ?? '',
      Li_AgeF: this.state.initialValues.Lio_AgeF ?? '',
      Li_AgeT: this.state.initialValues.Lio_AgeT ?? '',
      Lio_Relationship: this.state.initialValues.Lio_Relationship ?? '',
      Lio_StateFlag: this.state.initialValues.StateFlag ?? '',
      Lio_FacilityType: this.state.initialValues.FacilityType ?? '',
      Lio_ReCreateNewAnd: this.state.initialValues.Lio_ReCreateNewAnd ?? '',
      Lio_DisplayOrder: this.state.initialValues.Lio_DisplayOrder ?? '',
      Li_Target: 1,
      GsettlementInfoCreation: this.formRef.current?.getFieldValue("GsettlementInfoCreation") ? 1 : 0,
      Li_Excluded: (bool !== undefined) ? bool : this.state.initialValues.Target,
      Gtype: this.formRef.current?.getFieldValue("Gtype"),
      Gagency: this.formRef.current?.getFieldValue("Gagency"),
      GinsuranceNum: this.formRef.current?.getFieldValue("GinsuranceNum"),
    };

    SpecificHealthDataXMLOutputAction.MemberList(params)
      .then(res => {
        if (res.length !== 0) {
          const dataSpecificHealth = [];
          const selectedRowKeys = [];

          for (let i = 0; i < res.length; i++) {
            selectedRowKeys.push(i);
            dataSpecificHealth.push({ ...res[i] });
          }
          let arrTemp = res.map(item => item.id);
          this.setState({
            dataSpecificHealth: dataSpecificHealth,
            MemberListFlag: true,
            selectAll: true,
            selectedRowKeys: arrTemp,
            selectedRows: res,
            XmlCheckVersion: 1
          });
          this.formRef.current?.setFieldsValue({
            dataSpecificHealth: dataSpecificHealth,
            selectAll: true
          })
        } else {
          this.setState({
            dataSpecificHealth: res
          });
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  onChange = e => {
    let gtype = e.target.value;
    if (gtype === 6 || gtype === 9) {
      this.setState({
        disabled: true,
        Gl0AgencyName: ''
      })
    }
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        Gtype: gtype,
        Gagency: '',
        GinsuranceNum: 0
      },
      insurer_kanji_name: ''
    })
    this.formRef.current?.setFieldsValue({
      ...this.formRef.current?.getFieldsValue,
      Gtype: e.target.value,
      Gagency: '',
      GinsuranceNum: '',
      insurer_kanji_name: ''
    });
  };

  onChangeCreation(event) {
    let checked = event.target.checked;
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        GsettlementInfoCreation: checked
      },
      isChecked: !checked ? true : false
    })
    this.formRef.current?.setFieldsValue({ GsettlementInfoCreation: checked, isChecked: !checked ? true : false })
  }

  onChangeSettlement(event) {
    let checked = event.target.checked;
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        GsettlementFreeConsultTicketYes: checked
      }
    })
    this.formRef.current?.setFieldsValue({ GsettlementFreeConsultTicketYes: checked })
  }

  Extract() {
    const Gagency = this.formRef.current.getFieldValue("Gagency");
    const GinsuranceNum = this.formRef.current.getFieldValue("GinsuranceNum");
    const GdateFChar = this.formRef.current.getFieldValue("GdateFChar")
    const GdateTChar = this.formRef.current.getFieldValue("GdateTChar")
    if (!Gagency && this.state.initialValues.Gtype === 1) {
      this.showModalInquiry();
      return;
    }
    if (!GinsuranceNum && this.state.initialValues.Gtype === 6) {
      this.insurerNumModal();
      return;
    }
    if (!GdateFChar || !GdateTChar) {
      message.error('受診日を入力して下さい')
      return
    }
    let param = {
      GdateFChar: this.state.initialValues.GdateFChar,
      GdateTChar: this.state.initialValues.GdateTChar,
      GinsuranceNum: this.state.initialValues.GinsuranceNum,
      Gagency: this.state.initialValues.Gagency,
      GinsurerCode: this.state.initialValues.GinsurerCode,
      Gtype: this.state.initialValues.Gtype,
      Relationship: this.state.initialValues.Lio_Relationship,
      GsettlementInfoCreation: this.state.initialValues.GsettlementInfoCreation,
      GpersonSetContractDivision: this.state.initialValues.Lio_ContractDivision,
      Gv1CreateDivision1New2ReCreate: this.state.initialValues.Lio_ReCreateNewAnd,
      AgeF: this.state.initialValues.Lio_AgeF,
      AgeT: this.state.initialValues.Lio_AgeT,
      GvisitsCourseF: this.state.initialValues.CourseCodeF,
      GvisitsCourseT: this.state.initialValues.CourseCodeT,
      Li_Target: 1,
      Li_KanaName: this.state.initialValues.KanaName,
      Li_Excluded: this.state.initialValues.Target,
    }
    this.setState({ isLoading: true });
    SpecificHealthDataXMLOutputAction.Extract(param)
      .then((res) => {
        this.setState({
          isLoading: false,
          MemberListFlag: true,
          selectAll: true
        });
        if (!res.message) {
          this.setState({
            initialValues: {
              ...this.state.initialValues,
              XmlCheckVersion: 1
            },
            dataSpecificHealth: res,
          });
          this.formRef.current?.setFieldsValue({
            dataSpecificHealth: res,
            selectAll: true
          })
        }
      })
  }

  F12_KeyEvent(output) {
    // Goutputはoutput.Lio_Outputを設定する
    let param = {
      Gtype: this.state.initialValues.Gtype,
      GinsuranceNum: Number(this.state.initialValues.GinsuranceNum) > 0 ? Number(this.state.initialValues.GinsuranceNum) : Number(this.formRef.current.getFieldValue("GinsuranceNum")) ?? 0,
      OptionXml01010: this.state.initialValues.OptionXml01010,
      GsettlementInfoCreation: output.Lio_SettlementPresence,
      selectRows: this.state.selectedRows,
      GoutputForm: output.GoutputForm,
      Gagency: this.state.initialValues.Gagency == null ? this.formRef.current.getFieldValue("Gagency") : this.state.initialValues.Gagency,
      MedicalExamInstitutionNum: '',
      SubmittedYourInstitutionNum: '',
      RootFolderName: '',
      GfilingDate: this.state.initialValues.GfilingDate,
      NumTransmissions: output.Lio_NumTransmissions,
      NumFiles: 0,
      Visits_UserTotal: 0,
      ErrorPeopleNum: '',
      NumErrorsItem: '',
      CheckErrorPeopleNum: '',
      CheckNumErrorsItem: '',
      StsConfirm: output.Lo_StsOutput,
      V1ProcessDivision1ContentCheck2Create: output.Lio_ProcessDivision,
      UnexecutedOutput: this.state.initialValues.UnexecutedOutput,
      Goutput: this.state.initialValues.Goutput,
      GcreationDate: this.state.initialValues.GcreationDate,
      GdateFChar: this.state.initialValues.GdateFChar,
      GdateTChar: this.state.initialValues.GdateTChar,
      SettlementFreeConsultTicketPresence: 'Y',
      Gv1CreateDivision1New2ReCreate: this.state.initialValues.Lio_ReCreateNewAnd,
      GsettlementFreeConsultTicketYes: this.state.initialValues.GsettlementFreeConsultTicketYes,
    }

    this.setState({ isLoading: true });
    SpecificHealthDataXMLOutputAction.F12_KeyEvent(param)
      .then((res) => {
        if (res === undefined) {
          return
        }
        let reservation_numberList = []
        let selectedRows = this.state.selectedRows
        for (let i = 0; i < selectedRows.length; i++) {
          reservation_numberList.push(selectedRows[i].reservation_number)
        }

        this.setState({
          isLoading: false,
          childModal: {
            visible: true,
            width: 500,
            component: (
              <WS2655106_ResultConfirm
                ErrorPeopleNum={res.ErrorPeopleNum}
                CheckErrorPeopleNum={res.CheckErrorPeopleNum}
                Visits_UserTotal={res.Visits_UserTotal}
                Goutput={res.Goutput}
                RootFolderName={res.RootFolderName}
                fileName={res.fileName}
                dataList={reservation_numberList}
                onFinishScreen={output => {
                  this.closeModal();
                }}
              />
            )
          }
        })
      })
  }

  changeGdateChar(event, name) {
    if (event === null && name === 'GdateFChar') {
      // GdateFCharがnullだった時
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          GdateFChar: ''
        }
      });
      this.formRef.current?.setFieldsValue({ GdateFChar: null })
    } else if (event === null && name === 'GdateTChar') {
      // GdateTCharがnullだった時
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          GdateTChar: ''
        }
      });
      this.formRef.current?.setFieldsValue({ GdateTChar: null })
    } else if (name === 'GdateFChar') {
      // GdateFCharの日付変更
      let date = event
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          GdateFChar: date.format(dateFormat)
        }
      });
      this.formRef.current?.setFieldsValue({ GdateFChar: moment(date) });
    } else if (name === 'GdateTChar') {
      // GdateTCharの日付変更
      let date = event
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          GdateTChar: date.format(dateFormat)
        }
      });
      this.formRef.current?.setFieldsValue({ GdateTChar: moment(date) });
    }
  }

  changeGagency(event) {
    let param = {
      Li_AgencyNum: event.target.value
    }
    SpecificHealthDataXMLOutputAction.changeGagency(param)
      .then((res) => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            Gagency: event.target.value
          },
          Gl0AgencyName: res.Gl0AgencyName
        })
      })
  }

  changeGinsuranceNum(event) {
    let param = {
      GinsuranceNum: event.target.value
    }
    SpecificHealthDataXMLOutputAction.changeGinsuranceNum(param)
      .then((res) => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            GinsuranceNum: event.target.value,
          },
          insurer_kanji_name: res.insurer_kanji_name
        })
      })
  }

  changeName(e) {
    let name = e.target.value;
    this.setState({
      ...this.state,
      initialValues: { ...this.state.initialValues, KanaName: name }
    });
    this.formRef.current?.setFieldsValue({ KanaName: name });
    if (this.state.MemberListFlag) {
      const Gagency = this.formRef.current.getFieldValue("Gagency");
      const GinsuranceNum = this.formRef.current.getFieldValue("GinsuranceNum");
      const GdateFChar = this.formRef.current.getFieldValue("GdateFChar")
      const GdateTChar = this.formRef.current.getFieldValue("GdateTChar")
      if (!Gagency && this.state.initialValues.Gtype === 1) {
        this.showModalInquiry();
        return;
      }
      if (!GinsuranceNum && this.state.initialValues.Gtype === 6) {
        this.insurerNumModal();
        return;
      }
      if (!GdateFChar || !GdateTChar) {
        message.error('受診日を入力して下さい')
        return
      }
      this.MemberList(this.state.initialValues.Target, name);
    }
  }

  updateCheckbox(event, record) {
    let checked = event.target.checked;
    let id = record.id;
    let xml01010W1_4Id = '';
    let data = [...this.state.dataSpecificHealth];
    let selectall = false;
    for (var i = 0; i < data.length; i++) {
      if (id === data[i].id) {
        data[i].W1_sts_output = checked ? 1 : 0;
        xml01010W1_4Id = data[i].id;
      }
      if (data[i].W1_sts_output) {
        selectall = true;
      }
    }
    let param = {
      Li_StsSelect: checked ? 1 : 0,
      id: xml01010W1_4Id
    }
    SpecificHealthDataXMLOutputAction.updateCheckbox(param)
      .then((res) => {
        this.setState({
          dataSpecificHealth: data,
          selectAll: selectall
        })
        this.formRef.current?.setFieldsValue({ selectAll: selectall });
      })
  }

  selectAll(event) {
    let checked = event.target.checked;
    let data = [...this.state.dataSpecificHealth];
    for (let i = 0; i < data.length; i++) {
      data[i].W1_sts_output = checked ? 1 : 0;
    }
    let param = {
      Li_StsSelect: checked ? 1 : 0
    }
    SpecificHealthDataXMLOutputAction.selectAll(param)
      .then((res) => {
        this.setState({
          selectAll: checked,
          dataSpecificHealth: data
        })
        this.formRef.current?.setFieldsValue({ dataSpecificHealth: data });
      })
  }

  onChangeShow(event) {
    let bool = event.target.checked ? 1 : 0
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        Target: bool
      }
    })
    if (this.state.MemberListFlag) {
      const Gagency = this.formRef.current.getFieldValue("Gagency");
      const GinsuranceNum = this.formRef.current.getFieldValue("GinsuranceNum");
      const GdateFChar = this.formRef.current.getFieldValue("GdateFChar")
      const GdateTChar = this.formRef.current.getFieldValue("GdateTChar")
      if (!Gagency && this.state.initialValues.Gtype === 1) {
        this.showModalInquiry();
        return;
      }
      if (!GinsuranceNum && this.state.initialValues.Gtype === 6) {
        this.insurerNumModal();
        return;
      }
      if (!GdateFChar || !GdateTChar) {
        message.error('受診日を入力して下さい')
        return
      }
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          Target: bool
        }
      })
      this.formRef.current?.setFieldsValue({ Target: bool });
      this.MemberList(bool);
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  showModalInquiry() {
    this.setState({
      childModal: {
        visible: true,
        width: 500,
        component: (
          <WS1302001_AgencyInquirySub
            onFinishScreen={output => {
              this.formRef.current.setFieldsValue({
                Gagency: output.Gl0AgencyNum
              });
              this.setState({
                ...this.state,
                initialValues: {
                  ...this.state.initialValues,
                  Gagency: output.Gl0AgencyNum
                },
                Gl0AgencyName: output.recordData.Gl0AgencyName
              });
              this.closeModal();
            }}
          />
        )
      }
    });
  }

  insurerNumModal(value) {
    this.setState({
      childModal: {
        visible: true,
        width: 1200,
        component: (
          <WS1290001_InsurerNumberInquiry
            InputValue={value}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                GinsuranceNum: output.Lo_InsurerNum
              });
              this.setState({
                ...this.state,
                initialValues: {
                  ...this.state.initialValues,
                  GinsuranceNum: output.Lo_InsurerNum,
                },
                insurer_kanji_name: output.Lo_InsurerKanjiName
              });
              this.closeModal();
            }}
          />
        )
      }
    });
  }

  renderMenuBar = () => (
    <Space className="mb-2">
      <Button type="primary" onClick={() => this.handleF8Button()}>
        リスト
      </Button>
      <Button type="primary" onClick={() => this.handleLOGButton()}>
        LOG
      </Button>
      <Button type="primary" onClick={() => this.handleXMLButton()}>
        XML
      </Button>
    </Space>
  );

  handleF8Button = () => {
    Modal.confirm({
      icon: <WarningOutlined />,
      content: '取込みを完了しました',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        this.setState({
          isLoading: false,
          childModal: {
            visible: true,
            width: '80%',
            component: (
              <WS1367002_MemberList
                dataSource={this.state.dataSpecificHealth ? this.state.dataSpecificHealth : []}
                onFinishScreen={output => {
                  this.closeModal();
                }}
              />
            )
          }
        });
      },
      onCancel: () => {
        this.closeModal();
      }
    });
  }

  handleLOGButton() {
    VenusApiRoutesV2.callApi("API002655001010", {})
      .then((res) => {
        this.setState({
          isLoading: false,
          childModal: {
            visible: true,
            width: '80%',
            component: (
              <WS2655106_ResultConfirm
                onFinishScreen={output => {
                  this.closeModal();
                }}
              />
            )
          }
        });
      })
  }

  handleXMLButton() {
    if (!this.state.dataSpecificHealth.length) {
      message.error("対象者を検索してください");
      return;
    }
    if (!this.state.selectedRows.length) {
      message.error("対象者を選択してください");
      return;
    }

    this.setState({
      childModal: {
        visible: true,
        width: 700,
        component: (
          <WS2655078_OutputConfirm
            XmlCheckVersion={this.state.initialValues.XmlCheckVersion}
            Goutput={this.state.initialValues.Goutput}
            Lio_SettlementPresence={this.state.initialValues.GsettlementInfoCreation}
            selectRows={this.state.selectedRows}
            onFinishScreen={output => {
              this.closeModal();
              this.F12_KeyEvent(output);
            }}
          />
        )
      }
    });
  }


  render() {
    return (
      <div className="specific-health-data-xml-output">
        <Card
          title='特健データXML出力'
          style={{ position: 'relative' }}
        >
          <Spin spinning={this.state.isLoading}>
            <div className='box_container'>
              <Form
                ref={this.formRef}
                initialValues={this.state.initialValues}
              >
                <div className='box_inner_horizontal'>
                  <div className='box_search'>
                    <div className='box_search_inner'>
                      <Form.Item name="Gtype" label='種　別　'>
                        <Radio.Group
                          onChange={this.onChange}
                          style={{ display: "inline-flex" }}
                        >
                          <Radio value={1} >代行機関</Radio>
                          <Radio value={6} >保険者</Radio>
                          <Radio value={9} >その他</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Form.Item
                          name="Gagency"
                          label='代行機関'
                        >
                          <Input.Search
                            disabled={this.state.initialValues.Gtype === 6 || this.state.initialValues.Gtype === 9}
                            className='input-search-size-allow-clear-8'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.setState({
                                  Gl0AgencyName: ''
                                })
                              } else {
                                this.showModalInquiry()
                              }
                            }}
                          />
                        </Form.Item>
                        <label id='input'>
                          {this.state.Gl0AgencyName}
                        </label>
                      </div>

                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Form.Item
                          name="GinsuranceNum"
                          label='保険者　'
                        >
                          <Input.Search
                            className='input-search-size-allow-clear-8'
                            allowClear={true}
                            onPressEnter={(e) => {
                              this.insurerNumModal(e.target.value)
                            }}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.setState({
                                  insurer_kanji_name: ''
                                })
                              } else {
                                this.insurerNumModal(value)
                              }
                            }}
                          />
                        </Form.Item>
                        <label id='input'>
                          {this.state.insurer_kanji_name}
                        </label>
                      </div>
                      <Space>
                        <Form.Item name="GdateFChar" label='受診日　' >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={dateFormat}
                            onChange={(event) => this.changeGdateChar(event, 'GdateFChar')}
                          />
                        </Form.Item>
                        ~
                        <Form.Item
                          name="GdateTChar"
                          style={{ display: "inline-block" }}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={dateFormat}
                            onChange={(event) => this.changeGdateChar(event, 'GdateTChar')}
                          />
                        </Form.Item>
                      </Space>
                      <Space>
                        <Form.Item
                          name="GsettlementInfoCreation"
                          valuePropName="checked"
                          label='決　済　'
                        >
                          <Checkbox onChange={(event) => this.onChangeCreation(event)} />
                        </Form.Item>
                      </Space>
                    </div>
                  </div>

                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          this.MemberList()
                        }}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                      <Tooltip title='詳細な条件追加'>
                        <Button
                          size="small"
                          type="primary"
                          icon={<PlusOutlined />}
                          style={{ borderRadius: '12px' }}
                          onClick={() => {
                            const GdateFChar = this.formRef.current?.getFieldValue("GdateFChar");
                            const GdateTChar = this.formRef.current?.getFieldValue("GdateTChar");
                            this.setState({
                              childModal: {
                                visible: true,
                                width: 500,
                                component: (
                                  <WS2655083_DetailsExtract
                                    DateFChar={GdateFChar}
                                    DateTChar={GdateTChar}
                                    ReceiptNumF={this.state.initialValues?.ReceiptNumF}
                                    ReceiptNumT={this.state.initialValues?.ReceiptNumT}
                                    CourseCodeF={this.state.initialValues?.CourseCodeF}
                                    CourseCodeT={this.state.initialValues?.CourseCodeT}
                                    CourseList={this.state.initialValues?.CourseList}
                                    TimeDivision={this.state.initialValues?.TimeDivision}
                                    FacilityType={this.state.initialValues?.FacilityType}
                                    StateFlag={this.state.initialValues?.StateFlag}
                                    Lio_Insurer={this.state.initialValues?.Lio_Insurer}
                                    BranchStoreCode={this.state.initialValues?.BranchStoreCode}
                                    PersonalNum={this.state.initialValues?.PersonalNum}
                                    Lio_DisplayOrder={this.state.initialValues?.Lio_DisplayOrder}
                                    Lio_AgeF={this.state.initialValues?.Lio_AgeF}
                                    Lio_AgeT={this.state.initialValues?.Lio_AgeT}
                                    Lio_Relationship={this.state.initialValues?.Lio_Relationship}
                                    Lio_ContractDivision={this.state.initialValues?.Lio_ContractDivision}
                                    Lio_ReCreateNewAnd={this.state.initialValues?.Lio_ReCreateNewAnd}
                                    onFinishScreen={output => {
                                      this.setState({
                                        ...this.state,
                                        initialValues: {
                                          ...this.state.initialValues,
                                          ...output
                                        }
                                      });
                                      this.closeModal();
                                    }}
                                  />
                                )
                              }
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div
                  className="table_header_filter"
                  style={{ marginTop: '10px' }}
                >
                  <Space>
                    カナ氏名：
                  </Space>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="KanaName"
                  >
                    <Input
                      type="text"
                      size="small"
                      onPressEnter={e => this.changeName(e)}
                    />
                  </Form.Item>
                  <Space >
                    <Space>
                      受診日のみで抽出：
                    </Space>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name="Target"
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(event) => this.onChangeShow(event)}
                      />
                    </Form.Item>
                  </Space>
                </div>

                <Table
                  size="small"
                  scroll={{ y: resizableTableScroll(120) }}
                  rowKey={record => record.id}
                  dataSource={this.state?.dataSpecificHealth ? this.state.dataSpecificHealth : []}
                  bordered
                  pagination={this.state.dataSpecificHealth.length > process.env.REACT_APP_PAGE_SIZE ? this.state.Paginate : false}
                  rowSelection={{
                    selectedRowKeys: this.state.selectedRowKeys,
                    onSelect: (record) => {
                      let arrTemp = [...this.state.selectedRowKeys];
                      let arrTempRecord = [...this.state.selectedRows];
                      let idx = arrTemp.indexOf(record.id);
                      if (idx === -1) {
                        arrTemp.push(record.id);
                        arrTempRecord.push(record)
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          selectedRowOnly: arrTempRecord,
                        });
                      } else {
                        arrTemp.splice(idx, 1);
                        arrTempRecord.splice(idx, 1);
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          selectedRowOnly: arrTempRecord,
                        });
                      }
                    },
                    onSelectAll: (selected) => {
                      if (selected) {
                        let arrTemp = this.state.dataSpecificHealth.map(item => item.id);
                        let arrTempRecord = this.state.dataSpecificHealth;
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          selectedRowOnly: arrTempRecord
                        });
                      } else {
                        this.setState({
                          selectedRowKeys: [],
                          selectedRows: [],
                          selectedRowOnly: []
                        });
                      }
                    }
                  }}
                >
                  <Table.Column
                    width={100}
                    title="受診日"
                    dataIndex="W1_consult_date"
                    render={(row, record, index) => {
                      return (
                        <div >{row}</div>
                      )
                    }}
                  />
                  <Table.Column
                    width={200}
                    title="漢字氏名"
                    dataIndex="kanji_name"
                    render={(row, record, index) => {
                      return (
                        <div >{row}</div>
                      )
                    }}
                  />
                  <Table.Column
                    width={50}
                    title="年齢"
                    dataIndex="W1_age"
                    align="center"
                    render={(row, record, index) => {
                      return (
                        <div >{row}</div>
                      )
                    }}
                  />
                  <Table.Column
                    width={100}
                    title="代行機関"
                    dataIndex="Agency"
                    render={(row, record, index) => {
                      return (
                        <div >{row}</div>
                      )
                    }}
                  />
                  <Table.Column
                    width={100}
                    title={<div style={{ textAlign: 'left' }}>保険者</div>}
                    dataIndex="W1_insurers"
                    align="right"
                    render={(row, record, index) => {
                      return (
                        record.W1_insurers !== 0 ?
                          <div style={{ color: record.W1_insurers === record.insurer_num ? '#000000' : '' }}>{row}</div>
                          :
                          null)
                    }}
                  />
                  <Table.Column
                    title="契約情報"
                    dataIndex="W1_visits_courses"
                    ellipsis
                    render={(value, row) => {
                      return (
                        <div >{value} {row.contract_short_name}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="受診券"
                    dataIndex="ConsultTicket"
                    width={80}
                    render={(row, record, index) => {
                      return (
                        <div >{row}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="決済"
                    dataIndex="Settlement"
                    width={80}
                    render={(row, record, index) => {
                      return (
                        <div >{row}</div>
                      )
                    }}
                  />
                  <Table.Column
                    align='center'
                    width={40}
                    fixed='right'
                    render={(text, record, index) => (
                      <Dropdown size='small' trigger='click' overlay={() => (
                        <Menu >
                          <Menu.Item key="1" onClick={() => (
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                centered: true,
                                width: 1050,
                                component: (
                                  <WS1296010_ConsultTicketInputSub
                                    Li_CourseLevel={record.course_level}
                                    Li_ReserveNum={record.reservation_number}
                                    Li_Tab={"0"}
                                    onFinishScreen={(output) => {
                                      this.closeModal();
                                    }}
                                  />
                                ),
                              },
                            })
                          )}
                          >
                            明細
                          </Menu.Item>
                        </Menu>
                      )}>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        >
                        </Button>
                      </Dropdown>
                    )}
                  />
                </Table>

                <div className='box_button_bottom_right'>
                  <Button
                    type="primary"
                    onClick={() => this.handleXMLButton()}
                  >
                    <span className='btn_label'>
                      ＸＭＬ
                    </span>
                  </Button>
                </div>
              </Form>
            </div>
          </Spin>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              }
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2655001_SpecificHealthDataXmlOutput);
