/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, message, Radio, Modal } from 'antd'
import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons';
import CheckupCalendarPresentationHistory from 'pages/ZZ_Others/SANAI_MedicalCheckupCalendar/CheckupCalendarPresentationHistory'
import CheckupCalendarPresentationAction from 'redux/Others/MedicalCheckupCalendar/CheckupCalendarPresentation.action.js'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import hankakuMoji from 'components/Commons/HankakuMoji'
import * as wanakana from 'wanakana'

const grid = {
  labelCol: { style: { width: 60 } },
}

class CheckupCalendarPresentation extends React.Component {
  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    Li_Data: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.KanaName = React.createRef()
    // document.title = '提示'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      deleteFlag: true,
      KanaName: '',
      Dt0320Id: '',
      RelationshipList: [],
      userList: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.GetScreenData()
  }

  /**
   * 対象データの取得
   */
  GetScreenData() {
    let prams = {
      ReserveNum: this.props.record.reserve_num,
      Date: this.props.record.date,
    }

    CheckupCalendarPresentationAction.getScreenData(prams)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          personal_number_id: res.personal_number_id,
          KanaName: res.KanaName,
          KanjiName: res.KanjiName,
          Gender: res.Gender,
          DateBirthChar: res.DateBirthChar,
          Relationship: res.Relationship,
          user: this.props.user.name
        })

        this.setState({
          Dt0320Id: res.Dt0320Id,
          RelationshipList: res.RelationshipList,
          userList: res.UserList
        })
      })
  }

  /**
   * IDのSearchが押された時の処理
   */
  showWS0248001_PersonalInfoSearchQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '85%',
        component:
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue('personal_number_id')}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                personal_number_id: output.recordData.personal_number_id,
                KanaName: output.recordData.kana_name,
                KanjiName: output.recordData.kanji_name,
                Gender: output.recordData.sex,
                DateBirthChar: output.recordData.birthday_on
              })

              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * 登録ボタンが押された処理
   */
  save() {
    let data = {
      ReserveNum: this.props.record.reserve_num ?? '',
      personalNumberId: this.formRef.current?.getFieldValue('personal_number_id') ?? '',
      KanaName: this.formRef.current?.getFieldValue('KanaName') ?? '',
      KanjiName: this.formRef.current?.getFieldValue('KanjiName') ?? '',
      Gender: this.formRef.current?.getFieldValue('Gender') ?? '',
      Relationship: this.formRef.current?.getFieldValue('Relationship') ?? '',
      BirthDateDate: moment(this.formRef.current?.getFieldValue('DateBirthChar')).format('YYYY/MM/DD') ?? '',
      HomePhoneNum: this.formRef.current?.getFieldValue('HomePhoneNum') ?? '',
      MobilePhoneNum: this.formRef.current?.getFieldValue('MobilePhoneNum') ?? '',
      User: this.formRef.current?.getFieldValue('user') ?? '',
    }

    CheckupCalendarPresentationAction.save(data)
      .then(res => {
        this.props.onFinishScreen(res)
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 解除ボタンが押された処理
   */
  delete() {
    let data = {
      ReserveNum: this.props.record.reserve_num ?? '',
      personalNumberId: this.formRef.current?.getFieldValue('personal_number_id') ?? '',
    }

    CheckupCalendarPresentationAction.delete(data)
      .then(res => {
        this.props.onFinishScreen()
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })

  }

  /**
   * カナ氏名を半角カナに変換して設定
   * @param {*} event
   */
  setKaneName = (event) => {
    let zenkaku_kana = wanakana.toKatakana(event.target.value)
    let hannkaku_kana = hankakuMoji(zenkaku_kana)
    this.formRef.current?.setFieldsValue({
      KanaName: hannkaku_kana
    })
    this.setState({
      KanaName: hannkaku_kana
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='application-info-register'>
        <Card title={'提示：' + (this.state.Dt0320Id == '' ? '新規' : '変更')}>
          <Form
            //  {...grid}
            autoComplete='off'
            ref={this.formRef}
          >
            <div style={{ display: 'flex' }}>
              <Form.Item
                name='personal_number_id'
                label='ID'
                {...grid}
              >
                <Input.Search
                  style={{ marginRight: '5px' }}
                  className='input-search-size-number-allow-clear-10'
                  onSearch={(value, event) => {
                    this.showWS0248001_PersonalInfoSearchQuery()
                  }}
                />
              </Form.Item>

              <Button
                size='small'
                style={{ marginTop: '4px' }}
                icon={<HistoryOutlined />}
                onClick={() => {
                  const record = { ...this.props.record }
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 800,
                      component: (
                        <CheckupCalendarPresentationHistory
                          personalNumberId={this.formRef.current?.getFieldValue('personal_number_id') ?? ''}
                          KanjiName={this.formRef.current?.getFieldValue('KanjiName') ?? ''}
                          ReserveNum={record.reserve_num ?? ''}
                          Date={record.date ?? ''}
                        />
                      ),
                    },
                  })
                }}
              />

            </div>

            <Form.Item
              name='KanaName'
              label='カナ氏名'
              {...grid}
            >
              <Input
                style={{ width: 300 }}
                maxLength={40}
                ref={this.KanaName}
                onBlur={(event) => {
                  this.setKaneName(event)
                }}
                onPressEnter={(event) => {
                  this.setKaneName(event)
                }}
              />
            </Form.Item>

            <Form.Item
              name='KanjiName'
              label='漢字氏名'
              {...grid}
            >
              <Input
                style={{ width: 300 }}
                maxLength={40} />
            </Form.Item>

            <Form.Item
              name='Gender'
              label='性別'
              {...grid}
            >
              <Radio.Group>
                <Radio value={1}>男性</Radio>
                <Radio value={2}>女性</Radio>
              </Radio.Group>

            </Form.Item>

            <Form.Item
              name='DateBirthChar'
              label='生年月日'
              {...grid}
            >
              <VenusDatePickerCustom
                formRefDatePicker={this.formRef}
                format='NNy/MM/DD' />
            </Form.Item>

            <Form.Item
              name='HomePhoneNum'
              label='自宅電話'
              {...grid}
            >
              <Input
                maxLength={20}
                style={{ width: 150 }}
              />
            </Form.Item>

            <Form.Item
              name='MobilePhoneNum'
              label='携帯電話'
              {...grid}
            >
              <Input
                maxLength={20}
                style={{ width: 150 }}
              />
            </Form.Item>

            <Form.Item
              name='Relationship'
              label='続柄'
              {...grid}
            >
              <Select style={{ width: 100 }}>
                {this.state.RelationshipList.map(value => {
                  return (
                    <Select.Option
                      key={'Relationship-' + value.LinkedField}
                      value={value.LinkedField}
                    >
                      {value.DisplayField}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name='user'
              label='担当者'
              {...grid}
            >
              <Select style={{ width: '200px' }}>
                {this.state.userList.map((value) => {
                  return (
                    <Select.Option
                      key={value.id}
                      value={value.userId}
                    >
                      {value.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              danger
              icon={<DeleteOutlined />}
              style={{ display: this.state.Dt0320Id ? '' : 'none' }}
              onClick={() => {
                // 確認モーダルを表示してから、削除
                Modal.confirm({
                  content: `提示の取り消しを行いますか?`,
                  okText: 'は　い',
                  cancelText: 'いいえ',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                解除
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => this.save()} >
              <span className='btn_label'>
                {this.state.Dt0320Id ? '編集' : '登録'}
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckupCalendarPresentation)
