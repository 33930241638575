import axios from 'configs/axios';

const API_LIST = {
  exec: '/api/space-trimming/space-trimming/exec',
  getScreenData: '/api/space-trimming/space-trimming/get-screen-data',
};

const SpaceTrimmingService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, {params});
  },
  async exec(params) {
    return axios.post(API_LIST.exec, params);
  },
};

export default SpaceTrimmingService;