import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, message, Row, Col} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1313008_DateDisplay from 'pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313008_DateDisplay.jsx';
import  ModalDraggable  from "components/Commons/ModalDraggable";

class WS1313007_CsvOutputScreen extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'CSV出力画面';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      DateChars: "",
    };
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="csv-output-screen">
        <Card title="CSV出力画面">
          <Row gutter={24}>
            <Col span={8}>日付（文字）</Col>
            <Col span={16}>
              <Input.Search type="text" 
                value={this.state.DateChars}
                onSearch={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 400,
                    component: (
                      <WS1313008_DateDisplay
                        onFinishScreen={({ selectedDate }) => {
                          this.setState({ DateChars: selectedDate });
                          this.closeModal();
                        }}
                      />),
                  }
                })
              }} />
            </Col>
          </Row>
          <Row gutter={24} className="mt-2">
            <Col span={16} offset={8}>
            <Button type="primary" style={{ float: 'right' }} onClick={() => {
              const {DateChars} = this.state;
              VenusApiRoutesV2.callApi("API001313007002", {DateChars})
                .then(res => {
                  console.log(res);
                });
            }}>実行</Button>
            </Col>
          </Row>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313007_CsvOutputScreen);
