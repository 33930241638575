import { message } from 'antd';
import ConnectHostsSettingService from 'services/MN_Basis/ConnectHostsSetting/ConnectHostsSetting.Service';

const ConnectHostsSettingAction = {
  GetScreenData() {
    return ConnectHostsSettingService.GetScreenData()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  },

  Update(data) {
    return ConnectHostsSettingService.Update(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  Delete(data) {
    return ConnectHostsSettingService.Delete(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

}
export default ConnectHostsSettingAction;
