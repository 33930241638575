
import { message } from "antd";
// import ConvertTblSubAllService from "services/ResultOutput/CsvCreateParamMaintain/ConvertTblSubAllService";
import ConvertTblSubAllService from "services/ResultOutput/CsvCreateParamMaintain/ConvertTblSubAllService";



const ConvertTblSubAllAction = {
  index(params) {
    console.log('通過');
    return ConvertTblSubAllService.index(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  save(data) {
    console.log('通過1');
    return ConvertTblSubAllService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  gettestresult(data) {
    console.log('通過1');
    return ConvertTblSubAllService.gettestresult(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  delete(data) {
    return ConvertTblSubAllService.delete(data)
      .then((res) => {
        console.log(res)
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.message)
      })
  }


  // saveData(data) {
  //   return ConvertTblSubAllService.saveData(data)
  // },
  // deleteData(data) {
  //   return ConvertTblSubAllService.deleteData(data)
  // },
  // changeexamcode(data) {
  //   return ConvertTblSubAllService.changeexamcode(data)
  // },
};

export default ConvertTblSubAllAction;
