import SingleGroupClassifyInputSubService from "services/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/SingleGroupClassifyInputSubService"
import { message } from "antd";

const ConsultTicketInputSubAction = {
  getScreenData(data) {
    return SingleGroupClassifyInputSubService.getScreenData(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  runBtn(data) {
    return SingleGroupClassifyInputSubService.runBtn(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}
export default ConsultTicketInputSubAction;