import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Checkbox, Button, Radio, Table, Menu, Dropdown, Tooltip, message, Modal } from 'antd'
import { MoreOutlined, MenuOutlined, SendOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import MiraisSingleTransmissionAction from 'redux/CooperationRelated/MiraisSingleTransmission/MiraisSingleTransmission.actions.js'


class TransmissionStatusChange extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'Mirais単体送信'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
    }
  }
  /**
     * 初期表示処理
     */
  componentDidMount = () => {

    this.setState({
      dataSource: this.props.dataSource,
    })
  }

  // 状態変更
  transmissionstatuschange = () => {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
    }

    MiraisSingleTransmissionAction.transmissionstatuschange(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({})
        }
      })

  }

  // 削除

  // 抽出


  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Card title={'システムテスト用'}>
          <div className='box_inner_vertical'>
            <Button
              type='primary'
              style={{ width: 170 }}
              onClick={() => {
                this.transmissionstatuschange()
              }}
            >
              <span className='btn_label'>
                送信
              </span>
            </Button>
            <Button
              type='primary'
              style={{ width: 170 }}
              onClick={() => {
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>
            <Button
              type='primary'
              style={{ width: 170 }}
              onClick={() => {
              }}
            >
              <span className='btn_label'>
                抽出
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default TransmissionStatusChange
