import CreatingContractHistoryTogetherService from 'services/basicInfo/CreatingContractHistoryTogether/CreatingContractHistoryTogetherService'
import { message } from 'antd'

const CreatingContractHistoryTogetherAction = {
  index(params) {
    return CreatingContractHistoryTogetherService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getData(params) {
    return CreatingContractHistoryTogetherService.getData(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  createData(params) {
    return CreatingContractHistoryTogetherService.createData(params)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}
export default CreatingContractHistoryTogetherAction
