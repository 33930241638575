import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, InputNumber, Form, Input, Select, Button, Dropdown, Menu, Space, Modal, message, } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { SearchOutlined, DownloadOutlined, InfoOutlined, MoreOutlined } from '@ant-design/icons'

import WS0578001_CourseMultipleExtractScreenSub from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0578001_CourseMultipleExtractScreenSub'
import WS0784003_FormInquiry from './WS0784003_FormInquiry'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0784005_InsuranceSymbolInquiry from './WS0784005_InsuranceSymbolInquiry'
import WS0495001_ConditionExpressInfoInquiry from '../OITA0310_BindingModeSetting/WS0495001_ConditionExpressInfoInquiry'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'

import MedicalExamDataOutputCsvAction from 'redux/ResultOutput/MedicalExamDataOutputCsv/MedicalExamDataOutputCsv.action'
import moment from 'moment'
import Color from 'constants/Color'
import { download_file_binary, download_file } from 'helpers/CommonHelpers'
import { debounce } from 'lodash'
import { object } from 'prop-types'

const labelCol = {
  labelCol: { style: { width: 65 } }
}

const labelCol2 = {
  labelCol: { style: { width: 60 } }
}

class WS0784001_MedicalExamDataOutputCsv extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '健診データ出力[CSV]'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      RelationshipChar: [],
      GfacilitiesNum: [],
      message: '',
      isSearch: false,
      parramOutputF12: {
        Userdate: '',
        FileUav02: '',
      },
    }
    this.changeDataFormApi = debounce(this.changeDataFormApi, 300)
    this.setFormFieldValue = this.setFormFieldValue.bind(this)
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    MedicalExamDataOutputCsvAction.GetScreenData().then((res) => {
      this.renderFiledGcreendata(res)
    })
  }

  renderFiledGcreendata(data) {
    if (data) {
      this.setState({
        Userdate: data.Userdate,
        FileUav02: data.FileUav0,
      })
      const keys = !this.isEmpty(Object.keys(data)) ? Object.keys(data) : []
      const values = !this.isEmpty(Object.values(data))
        ? Object.values(data)
        : []
      if (values.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          for (let y = 0; y < values.length; y++) {
            if (
              keys[i] === 'EndDateChar' ||
              keys[i] === 'StartDateChar' ||
              keys[i] === 'FileCreationDateChar'
            ) {
              this.setFormFieldValue(keys[i], moment(values[i]))
            } else {
              if (keys[i] === 'GfacilitiesNum') {
                this.setState({ GfacilitiesNum: values[i] })
              } else {
                if (keys[i] === 'RelationshipChar') {
                  this.setState({ RelationshipChar: values[i] })
                } else {
                  this.setFormFieldValue(keys[i], values[i])
                }
              }
            }
          }
        }
      }
      document.getElementById('event').focus()
    }
  }

  isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false
  }

  checkData(value, type) {
    const val = type === 'number' ? 0 : ''
    return !this.isEmpty(value) ? value : val
  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ])
  }

  downloadFile() {
    const formRefValue = this.formRef?.current?.getFieldValue()

    const params = {
      selectedRows: this.state.selectedRows,
      FileUav02: formRefValue?.FileUav02 ? formRefValue?.FileUav02 : '',
      GfileCreationDate: formRefValue?.GfileCreationDate ? formRefValue?.GfileCreationDate : '',
      Sys010Status: formRefValue?.Sys010Status ? formRefValue?.Sys010Status : 1,
      Goutput: formRefValue?.Goutput ? formRefValue?.Goutput : '',
      TextFile: formRefValue?.TextFile ? formRefValue?.TextFile : '',
      StsUserUnitFdPara: formRefValue?.StsUserUnitFdPara ? formRefValue?.StsUserUnitFdPara : 1,
      options: formRefValue?.options ? formRefValue?.options : '',
      OutputCharSet: formRefValue?.OutputCharSet ? formRefValue?.OutputCharSet : '',
      Format: formRefValue?.Format ? formRefValue?.Format : '',
      PersonalInfoOutputOption: formRefValue?.PersonalInfoOutputOption ? formRefValue?.PersonalInfoOutputOption : '',
      HeaderHeadingOption: formRefValue?.HeaderHeadingOption ? formRefValue?.HeaderHeadingOption : '',
      IdOutputOption: formRefValue?.IdOutputOption ? formRefValue?.IdOutputOption : '',
      AgeCalculateOption: formRefValue?.AgeCalculateOption ? formRefValue?.AgeCalculateOption : '',
      FixOption: formRefValue?.FixOption ? formRefValue?.FixOption : '',
      Exec: formRefValue?.Exec ? formRefValue?.Exec : '',
      Type: formRefValue?.Type ? formRefValue?.Type : '',
      Run: formRefValue?.Run ? formRefValue?.Run : '',
      GformType: formRefValue?.GformType ? formRefValue?.GformType : '',
      fileName: formRefValue?.fileName ? formRefValue?.fileName : '',
    }

    MedicalExamDataOutputCsvAction.Downloadfile(params)
      .then((res) => {
        if (res) {
          if (res.status == 400) {
            return message.error(res.data.message)
          }

          if (res.Type == 0) {
            // CSVを出力
            this.downloadCSVFile(res)
          } else {
            // Excelを出力
            this.downloadExcelFile(res)
          }
        }
      }).catch(error => {
        console.log(error);
      })
  }

  /**
   * CSVファイル　ダウンロード
   * @param {*} path
   */
  downloadCSVFile(path) {
    let params = {
      filePath: path.filePath,
      FileName: path.FileName
    }
    MedicalExamDataOutputCsvAction.downloadCSVFile(params)
      .then((res) => {
        if (res) {
          // xlsxファイルダウンロード
          download_file(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  /**
  * Excelファイル ダウンロード
  * @param {*} path
  */
  downloadExcelFile(path) {
    console.log(path);
    let params = {
      filePath: path.filePath.original.filePath,
      FileName: path.FileName
    }
    console.log(params);
    MedicalExamDataOutputCsvAction.downloadExcelFile(params)
      .then((res) => {
        if (res) {
          // xlsxファイルダウンロード
          download_file_binary(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  Extract_F11() {
    const value = this.formRef?.current?.getFieldValue()
    const RelationshipChar = value.RelationshipChar
    const GfacilitiesNum = value.GfacilitiesNum
    const GacceptStatus = value.GacceptStatus
    const params = {
      GstartDate: this.checkData(
        moment(value.StartDateChar).format('YYYY/MM/DD'),
        'text'
      ),
      GendDate: this.checkData(
        moment(value.EndDateChar).format('YYYY/MM/DD'),
        'text'
      ),
      GformType: this.checkData(value.GformType, 'text'),
      GcourseF: this.checkData(value.GcourseF, 'text'),
      GcourseT: this.checkData(value.GcourseT, 'text'),
      GtubePalm: this.checkData(value.GtubePalm, 'text'),
      Goffice: this.checkData(value.Goffice, 'text'),
      GbranchStoreF: this.checkData(value.GbranchStoreF, 'text'),
      GbranchStoreT: this.checkData(value.GbranchStoreT, 'text'),
      GinsuranceSign: this.checkData(value.GinsuranceSign, 'text'),
      ConditionNum: this.checkData(value.ConditionNum, 'text'),
      Ggender: this.checkData(value.Ggender, 'number'),
      RelationshipChar: RelationshipChar,
      GfacilitiesNum: GfacilitiesNum,
      GacceptStatus: GacceptStatus,
      Gselect: this.checkData(value.Gselect, 'text'),
    }

    if (
      (value.GformType === undefined && value.formatName === undefined) ||
      (value.GformType === '' && value.formatName === '') ||
      value.formatName === undefined ||
      value.GformType === undefined
    ) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 500,
          component: (
            <WS0784003_FormInquiry
              onFinishScreen={(output) => {
                if (output.flg === 1) {
                  Modal.error({
                    width: 260,
                    content: '帳票を選択してください',
                    okText: 'はい',
                  })
                }
                this.setFormFieldValue('GformType', output.Lo_Code)
                this.setFormFieldValue('formatName', output.Lo_DocumentName)

                this.closeModal()
              }}
            />
          ),
        },
      })

    } else {
      MedicalExamDataOutputCsvAction.GetListDataF11(params)
        .then((res) => {
          if (res.data && res.data.PersonalInfoOutputOption.length > 0) {
            this.setState({
              dataSource: res.result,
              message: 'Success',
              isSearch: true,
              selectedRows: res.result.filter(x => x.W1_logic_01),
              selectedRowKeys: res.result.map(x => x.id)
            })
          }
          this.renderFiledGcreendata(res.data)
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          this.setState({ message: 'Faild', isSearch: true })
          message.error(res.data.message)
        })
    }
  }

  changeDataFormApi(name, nameleft) {
    const value = this.formRef?.current?.getFieldValue(name)
    if (value === undefined || value === '' || value === null) {
      if (name === 'GformType') {
        this.formRef.current.setFieldsValue({
          formatName: '',
          ConditionNum: '',
          name: ''
        })
        return
      }
      if (name === 'ConditionNum') {
        this.formRef.current.setFieldsValue({
          name: '',
        })
        return
      }
    }
    MedicalExamDataOutputCsvAction[name]({ [name]: value }).then((res) => {
      if (Object.keys(res)[0] == 'formatName') {
        this.setFormFieldValue('formatName', res['formatName'])
      }
      if (name == 'GtubePalm') {
        this.setFormFieldValue('insurer_kanji_name', res['insurer_kanji_name'])
      }
      if (name == 'Goffice') {
        this.setFormFieldValue('office_kanji_name', res['office_kanji_name'])
      }
      if (name == 'ConditionNum') {
        this.setFormFieldValue('name', res['name'])
      }
      this.forceUpdate()
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  onFinish(values) { }

  render() {
    const { RelationshipChar, GfacilitiesNum } = this.state
    return (
      <div className='medical-exam-data-output-csv'>
        <Card title='健診データ出力[CSV]'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              autoComplete='off'
            >
              <div className='box_inner_horizontal'>
                <div className='box_search' style={{ width: 'fit-content' }}>
                  <div className='box_search_inner_border' style={{ minWidth: 425 }}>
                    <Space>
                      <Form.Item
                        name='StartDateChar'
                        label='予約日'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          id={`event`}
                          tabIndex={`event`}
                          allowClear={false}
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>

                      <Form.Item name='EndDateChar' label='～' >
                        <VenusDatePickerCustom
                          allowClear={false}
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                    </Space>

                    <Space>
                      <Form.Item name='GformType' label='出力帳票' {...labelCol}>
                        <Input.Search
                          type='text'
                          onPressEnter={() => {
                            this.changeDataFormApi('GformType', 'formatName')
                          }}
                          onBlur={() => {
                            this.changeDataFormApi('GformType', 'formatName')
                          }}
                          style={{ width: 80 }}
                          maxLength={2}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 450,
                                component: (
                                  <WS0784003_FormInquiry
                                    onFinishScreen={(output) => {
                                      if (output.flg === 1) {
                                        Modal.error({
                                          width: 260,
                                          content: '帳票を選択してください',
                                          okText: 'はい',
                                        })
                                      }
                                      this.setFormFieldValue(
                                        'GformType',
                                        output.Lo_Code
                                      )
                                      this.setFormFieldValue(
                                        'formatName',
                                        output.Lo_DocumentName
                                      )

                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onKeyUp={(e) => {
                            if (e.keyCode === 9) {
                              if (
                                !this.formRef.current?.getFieldValue('GformType') ||
                                !this.formRef.current?.getFieldValue('formatName')
                              ) {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '30%',
                                    component: (
                                      <WS0784003_FormInquiry
                                        onFinishScreen={(output) => {
                                          if (output.flg === 1) {
                                            Modal.error({
                                              width: 260,
                                              content: '帳票を選択してください',
                                              okText: 'はい',
                                            })
                                          }
                                          this.setFormFieldValue(
                                            'GformType',
                                            output.Lo_Code
                                          )
                                          this.setFormFieldValue(
                                            'formatName',
                                            output.Lo_DocumentName
                                          )

                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                                e.preventDefault()
                              }
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='formatName' label=''>
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </Space>

                    <Space>
                      <Form.Item name='GcourseF' label='コース' {...labelCol}>
                        <Input.Search
                          type='text'
                          style={{ textAlign: 'right', width: 90 }}
                          maxLength={3}
                          onSearch={(value, e) => {
                            if (e.key !== 'Enter') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '70%',
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={(output) => {
                                        this.setFormFieldValue(
                                          'GcourseF',
                                          output.Lo_CourseCode
                                        )
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='GcourseT' label='～'>
                        <Input.Search
                          type='text'
                          style={{ textAlign: 'right', width: 90 }}
                          maxLength={3}
                          onSearch={(value, e) => {
                            if (e.key !== 'Enter') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '70%',
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={(output) => {
                                        this.setFormFieldValue(
                                          'GcourseT',
                                          output.Lo_CourseCode
                                        )

                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>


                    </Space>

                    <label
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '20%',
                            component: (
                              <WS0578001_CourseMultipleExtractScreenSub
                                onFinishScreen={(output) => {
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    >
                    </label>

                    <Form.Item name='GacceptStatus' label='状態' initialValue={9} {...labelCol}>
                      <Select style={{ width: 80 }}>
                        <Select.Option value={9}>全て</Select.Option>
                        <Select.Option value={0}>予約</Select.Option>
                        <Select.Option value={1}>受付</Select.Option>
                        <Select.Option value={2}>保留</Select.Option>
                        <Select.Option value={3}>待ち</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className='box_search_inner_border'>
                    <Form.Item name='GtubePalm' label='保険者' {...labelCol}>
                      <Input.Search
                        className='input-search-size-allow-clear-8'
                        type='text'
                        maxLength={10}
                        allowClear={true}
                        onPressEnter={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1500,
                              component: (
                                <WS0246001_InsurerInfoSearchQuery
                                  Li_insurerSearch={this.formRef.current.getFieldValue('GtubePalm')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      GtubePalm: output.Lo_InsurerCode,
                                      insurer_kanji_name: output.Lo_Name
                                    })
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    Li_insurerSearch={this.formRef.current.getFieldValue('GtubePalm')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        GtubePalm: output.Lo_InsurerCode,
                                        insurer_kanji_name: output.Lo_Name
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }
                        }
                        onChange={(e) => {
                          if (e.target.value == '') {
                            this.formRef.current.setFieldsValue({
                              insurer_kanji_name: ''
                            })
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item name='insurer_kanji_name' label=''>
                      <Input
                        readOnly
                        type='text'
                        style={{ border: 'none', background: 'transparent' }}
                      />
                    </Form.Item>

                    <Space>
                      <Form.Item name='Goffice' label='事業所' {...labelCol}>
                        <Input.Search
                          className='input-search-size-allow-clear-4'
                          type='text'
                          maxLength={8}
                          allowClear={true}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current.getFieldValue('Goffice')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        Goffice: output.Lio_OfficeCode,
                                        GbranchStoreF: output.Lio_BranchStoreCode,
                                        office_kanji_name: output.Lo_Kanji_Name
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current.getFieldValue('Goffice')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          Goffice: output.Lio_OfficeCode,
                                          GbranchStoreF: output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        })
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }
                          }
                          onChange={(e) => {
                            if (e.target.value == '') {
                              this.formRef.current.setFieldsValue({
                                GbranchStoreF: '',
                                office_kanji_name: ''
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='GbranchStoreF' label=''>
                        <InputNumber maxLength={5} style={{ width: 60, textAlign: 'right' }} />
                      </Form.Item>

                    </Space>

                    <Form.Item name='office_kanji_name' label=''>
                      <Input
                        readOnly
                        type='text'
                        style={{ border: 'none', background: 'transparent' }}
                      />
                    </Form.Item>

                    <Form.Item name='GinsuranceSign' label='保険記号' {...labelCol} style={{ marginBottom: 0 }}>
                      <Input.Search
                        type='text'
                        className='input-search-size-allow-clear-4'
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <WS0784005_InsuranceSymbolInquiry
                                  onFinishScreen={(output) => {
                                    this.setFormFieldValue(
                                      'GinsuranceSign',
                                      output.Lo_GinsuranceSign
                                    )
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className='box_search_inner'>

                    <Form.Item name='Ggender' label='性別' initialValue={0} {...labelCol2}>
                      <Select style={{ width: 100 }}>
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={1}>男性</Select.Option>
                        <Select.Option value={2}>女性</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name='RelationshipChar' label='続柄' initialValue='9' {...labelCol2}>
                      <Select style={{ width: 100 }}>
                        <Select.Option value={'9'}>全て</Select.Option>
                        {RelationshipChar.map((item, index) => (
                          <Select.Option
                            // key={item.}
                            // value={item.node_code_name}
                            key={item.node_code_name + index}
                            value={item.node_code_name}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>



                    <Form.Item name='GfacilitiesNum' label='施設' initialValue={0} {...labelCol2}>
                      <Select style={{ width: 100 }}>
                        {GfacilitiesNum.map((item, index) => {
                          return (
                            <Select.Option
                              key={item.facility_type + index}
                              value={item.facility_type}>
                              {item.facility_name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>

                    <Space>
                      <Form.Item name='ConditionNum' label='条件式' {...labelCol2}>
                        <Input.Search
                          onPressEnter={() => {
                            this.changeDataFormApi('ConditionNum', 'name')
                          }}
                          onBlur={() => {
                            this.changeDataFormApi('ConditionNum', 'name')
                          }}
                          style={{ textAlign: 'right', width: 80 }}
                          maxLength={4}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0495001_ConditionExpressInfoInquiry
                                    onFinishScreen={(output) => {
                                      this.setFormFieldValue(
                                        'ConditionNum',
                                        output.Lo_ConditionSerialNum
                                      )
                                      this.setFormFieldValue(
                                        'name',
                                        output.Lo_Name
                                      )
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='name' label=''>
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </Space>


                    <Form.Item name='FileCreationDateChar' label='作成日' {...labelCol2} style={{ marginBottom: 0 }}>
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.Extract_F11()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>

            {/*/////////////////////////////////////////////ここからテーブル///////////////////////////////////////////////////*/}

            <Table
              size='small'
              className='scrollbar'
              dataSource={this.state.dataSource}
              bordered={true}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: 400 }}
              rowSelection={{
                type: 'checkbox',
                fixed: 'left',
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                  let arrTemp = [...this.state.selectedRowKeys]
                  let arrTempRecord = [...this.state.selectedRows]
                  let idx = arrTemp.indexOf(record.id)
                  if (idx === -1) {
                    arrTemp.push(record.id)
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                    if (this.props.setValueChildren) {
                      this.props.setValueChildren(arrTempRecord)
                    }
                  } else {
                    arrTemp.splice(idx, 1)
                    arrTempRecord.splice(idx, 1)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                    if (this.props.setValueChildren) {
                      this.props.setValueChildren(arrTempRecord)
                    }
                  }
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen(arrTemp)
                  }
                },

                onSelectAll: (selected, selectedRows, changeRows) => {
                  let arrTemp = []
                  if (selected) {
                    arrTemp = this.state.dataSource.map(item => item.id)
                    let arrTempRecord = this.state.dataSource
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                    if (this.props.setValueChildren) {
                      this.props.setValueChildren(arrTempRecord)
                    }
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: []
                    })
                    if (this.props.setValueChildren) {
                      this.props.setValueChildren([])
                    }
                  }
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen(arrTemp)
                  } else {
                    //  this.changeLogicAll({ Logic: selected })
                  }
                },
              }}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
            >
              <Table.Column
                className='column-size-date'
                title='受診日'
                dataIndex='visit_date_on'
                render={(value, record, index) => {
                  return (
                    <div style={{ color: Color(record.selectColor)?.Foreground }}>
                      {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD (ddd)')}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                title='時間'
                dataIndex='period_time'
                render={(value, record, index) => {
                  return (
                    <div style={{ color: Color(record.selectColor)?.Foreground, textAlign: 'center' }}>
                      {value ? value?.substr(0, 5) : ''}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                title='受付No'
                dataIndex='receipt_number'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right', color: Color(record.selectColor)?.Foreground }}>
                      <span>{record.receipt_number === 0 ? '' : record.receipt_number}</span>
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-10'
                title='個人番号'
                dataIndex='personal_number_id'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right', color: Color(record.selectColor)?.Foreground }}>
                      <span>{record.personal_number_id}</span>
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                title='メモ'
                dataIndex='PS_importance'
                render={(value, record, index) => {
                  let icon = ''
                  switch (record.importance) {
                    // case 1: icon = (<InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />)
                    //   break
                    // case 3: icon = (<WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />)
                    //   break
                    // case 5: icon = (<CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />)
                    //   break
                    default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                  }
                  return (
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}
                      hidden={!record.personal_number_id}
                      onClick={() => {
                        // let title = '個人情報照会' + ' [' + record.personal_number_id + ']'
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (
                              // <Card title={title}>
                              <WS2584019_PersonalInfoInquirySub
                                Li_PersonalNum={record.personal_number_id}
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                              // </Card>
                            ),
                          },
                        })
                      }}>
                      {icon}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-40'
                title='漢字氏名'
                dataIndex='kanjiName'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'left', color: Color(record.selectColor)?.Foreground }}>
                      {value}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                title='性別'
                dataIndex='genderName'
                render={(value, record, index) => {
                  return (<div style={{ color: Color(record.genderColor)?.Foreground, textAlign: 'center' }}>{value}</div>)
                }}
              />

              <Table.Column
                className='column-size-10'
                title='生年月日'
                dataIndex='birthDay'
                render={(value, record, index) => {
                  return (
                    <div style={{ color: Color(record.selectColor)?.Foreground, textAlign: 'center' }}>
                      {value === '0000-00-00' ? '' : moment(value)?.format('NNy/MM/DD')}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                title='年齢'
                dataIndex='Age'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right', color: Color(record.selectColor)?.Foreground }}>
                      <span>{record.Age > 0 ? record.Age : ''}</span>
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-5'
                title='メモ'
                dataIndex='OS_importance'
                render={(value, record, index) => {
                  let icon = ''
                  switch (record.OS_importance) {
                    // case 1: icon = (<InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />)
                    //   break
                    // case 3: icon = (<WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />)
                    //   break
                    // case 5: icon = (<CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />)
                    //   break
                    default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                  }
                  return (
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS2585001_OfficeInfoInquirySub
                                Li_OfficeCode={record.office_code}
                                Li_BranchCode={record.branch_store_code}
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}>
                      {icon}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-60'
                title='事業所'
                dataIndex='office_kanji_name'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'left', color: Color(record.selectColor)?.Foreground }}>
                      {value}
                    </div>
                  )
                }}
              />

              <Table.Column
                className='column-size-60'
                title='契約情報'
                render={(value, record, index) => {
                  return <div style={{ textAlign: 'left' }}>
                    <span style={{ color: Color(record.selectColor)?.Foreground }}>
                      {record.visit_course ? record.visit_course?.toString().substr(0, 1) + '-' + record.visit_course?.toString().substr(1, 2) : ''}
                    </span>
                    <span style={{ color: Color(record.selectColor)?.Foreground }}>{record.contract_short_name}
                    </span>
                  </div>
                }}
              />

              <Table.Column
                className='column-size-5'
                title='状態'
                dataIndex='status'
                render={(value, record, index) => {
                  return <div style={{ color: Color(record.statusColor)?.Foreground, textAlign: 'center' }}>{value}</div>
                }}
              />

              <Table.Column
                className='column-size-5'
                fixed='right'
                render={(value, record, index) => (
                  <div style={{ textAlign: 'center' }}>
                    <Dropdown size='small'
                      overlay={() => (
                        <Menu >
                          <Menu.Item onClick={() => (
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 'fit-content',
                                component: (
                                  <WS2583001_ConsultInquirySub
                                    Li_ReserveNum={record.reservation_number}
                                    onClick={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          )}>照会</Menu.Item>
                        </Menu>
                      )}>
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </div>
                )}
              />
            </Table>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              icon={<DownloadOutlined />}
              type='primary'
              onClick={() => {
                this.downloadFile()
              }} >
              <span className='btn_label'>
                出力
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0784001_MedicalExamDataOutputCsv)
