import HistorySwitchingService from 'services/basicInfo/ContractInfoMaintain/HistorySwitchingService'
import { message, } from 'antd'

const HistorySwitchingAction = {
  index(params) {
    return HistorySwitchingService.index(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getDataAll() {
    return HistorySwitchingService.getDataAll()
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default HistorySwitchingAction
