import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import AggregateInfoCorrectAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/AggregateInfoCorrect.actions";
import { Card, Form, Input, Col, Row, Button, message } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
const styleBlueTitle = {
  backgroundColor: "#1890ff",
  textAlign: 'center',
  padding: "5px 0",
  color: "#fff"
}
const styleSpaceBetween = {
  justifyContent: "space-between"
}
const styleMargin05 = {
  margin: '0 5px'
}
const styleTextRight = {
  textAlign: 'right'
}
class WS1171001_AggregateInfoCorrect extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_Format: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06930:   集計情報(修正)';

    this.state = {
      tableData: [],
      isLoading: true,
      isLoadingTable: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.Li_Format !== prevProps.Li_Format) {
      this.formRef.current.setFieldsValue({ Li_Format: this.props.Li_Format });
      this.setState({ isLoading: true })
      this.getScreenData(this.props.Li_Format)

    }
  }

  componentDidMount() {
    this.getInit()
  }

  getInit() {
    this.setState({ isLoading: true })
    this.getScreenData(this.props.Li_Format)
  }

  getScreenData = (value) => {
    this.setState({ isLoadingTable: true })
    this.setState({
      isLoadingForm: true,
    })

    VenusApiRoutesV2.callApi("API001171001002", { Li_Format: value }).then(res => {
      console.log(res)
      this.setState({
        tableData: res
      });
    }).finally(() => this.setState({ isLoadingTable: false }));
  }

  render() {
    return (
      <div className="aggregate-info-correct">
        <Card title="V4-VNS06930:   集計情報(修正)">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row style={{paddingBottom: '8px'}}>
              <Col span={2} style={styleBlueTitle}>FORMAT</Col>
              <Col span={4} style={{padding: "5px 10px"}}>{this.props.Li_Format}</Col>
            </Row>
            <Row style={styleSpaceBetween}>
              <Col span={5} style={styleMargin05}>
                <div style={styleBlueTitle}>健診種類</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[一般]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="medical_exam_type_general" type="text" value={this.state.tableData.medical_exam_type_general} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[付加]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="medical_exam_type_additional" type="text" value={this.state.tableData.medical_exam_type_additional} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[乳子]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="medical_exam_type_infant" type="text" value={this.state.tableData.medical_exam_type_infant} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[肝炎]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="medical_exam_type_hepatitis" type="text" value={this.state.tableData.medical_exam_type_hepatitis} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ﾌｫﾛｰ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="medical_exam_type_follow" type="text" value={this.state.tableData.medical_exam_type_follow} />
                  </Col>
                </Row>
              </Col>
              <Col span={5} style={styleMargin05}>
                <div style={styleBlueTitle}>実施一般</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="01[胸間胃間]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_01_chest_between_stomach" type="text" value={this.state.tableData.implement_general_01_chest_between_stomach} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="02[胸間胃直]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_02_chest_straight_stomach" type="text" value={this.state.tableData.implement_general_02_chest_straight_stomach} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="03[胸直胃間]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_03_chest_between_stomach" type="text" value={this.state.tableData.implement_general_03_chest_between_stomach} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="04[胸直胃直]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_04_chest_straight_stomach" type="text" value={this.state.tableData.implement_general_04_chest_straight_stomach} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="05[子宮がん]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_05_uterine_cancer" type="text" value={this.state.tableData.implement_general_05_uterine_cancer} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="06[乳子宮]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_06_breast_uterus" type="text" value={this.state.tableData.implement_general_06_breast_uterus} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="07[胸胃未実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_07_chest_stomach_not_performed" type="text" value={this.state.tableData.implement_general_07_chest_stomach_not_performed} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="08[胸間のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_08_chest_only" type="text" value={this.state.tableData.implement_general_08_chest_only} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="09[胸直のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_09_chest_straight_only" type="text" value={this.state.tableData.implement_general_09_chest_straight_only} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="10[胃間のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_10_stomach_only" type="text" value={this.state.tableData.implement_general_10_stomach_only} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="11[胃直のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_11_stomach_straight_only" type="text" value={this.state.tableData.implement_general_11_stomach_straight_only} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="胃内視鏡検査"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_out_gastroscope" type="text" value={this.state.tableData.implement_general_out_gastroscope} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="乳房ﾏﾝﾓ1方向"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_out_breast_1_direction" type="text" value={this.state.tableData.implement_general_out_breast_1_direction} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="乳房ﾏﾝﾓ2方向"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_out_breast_2_direction" type="text" value={this.state.tableData.implement_general_out_breast_2_direction} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="眼底"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_general_out_fundus" type="text" value={this.state.tableData.implement_general_out_fundus} />
                  </Col>
                </Row>
                <div style={styleBlueTitle}>実施付加</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[付加]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_addition_added" type="text" value={this.state.tableData.implement_addition_added} />
                  </Col>
                </Row>
                <div style={styleBlueTitle}>実施肝炎</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[同日実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_hepatitis_same_day_implement" type="text" value={this.state.tableData.implement_hepatitis_same_day_implement} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[後日実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_hepatitis_later_day_implement" type="text" value={this.state.tableData.implement_hepatitis_later_day_implement} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[RNA実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_hepatitis_rna_implement" type="text" value={this.state.tableData.implement_hepatitis_rna_implement} />
                  </Col>
                </Row>
                <div style={styleBlueTitle}>実施ﾌｫﾛｰ</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[追加なし]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_followup_add_none" type="text" value={this.state.tableData.implement_followup_add_none} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[糖負荷追加]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_followup_add_sugar_load" type="text" value={this.state.tableData.implement_followup_add_sugar_load} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[HbA1c追加]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_followup_add_hba1c" type="text" value={this.state.tableData.implement_followup_add_hba1c} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[全て追加]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="implement_followup_add_all" type="text" value={this.state.tableData.implement_followup_add_all} />
                  </Col>
                </Row>

              </Col>
              <Col span={5} style={styleMargin05} >
                <div style={styleBlueTitle}>未実施付加</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[眼底]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_added_fundus" type="text" value={this.state.tableData.unimplement_added_fundus} />
                  </Col>
                </Row>
                <br />
                <div style={styleBlueTitle}>未実施ﾌｫﾛｰ</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[血糖]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_blood_sugar" type="text" value={this.state.tableData.unimplement_foo_blood_sugar} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[総ｺﾚ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_total_kore" type="text" value={this.state.tableData.unimplement_foo_total_kore} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[中性脂肪]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_neutral_fat" type="text" value={this.state.tableData.unimplement_foo_neutral_fat} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[HDL-ｺﾚ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_hdl_kore" type="text" value={this.state.tableData.unimplement_foo_hdl_kore} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ＧＯＴ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_got" type="text" value={this.state.tableData.unimplement_foo_got} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ＧＰＴ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_gpt" type="text" value={this.state.tableData.unimplement_foo_gpt} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[γ-GTP]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_gamma_gtp" type="text" value={this.state.tableData.unimplement_foo_gamma_gtp} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[尿酸]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_foo_uric_acid" type="text" value={this.state.tableData.unimplement_foo_uric_acid} />
                  </Col>
                </Row>
              </Col>
              <Col span={5} style={styleMargin05} >
                <div style={styleBlueTitle}>未実施一般[内訳]</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[空腹時血糖]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_fasting_blood_sugar" type="text" value={this.state.tableData.unimplement_fasting_blood_sugar} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[総ｺﾚ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_total_kore" type="text" value={this.state.tableData.unimplement_total_kore} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ＧＯＴ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_got" type="text" value={this.state.tableData.unimplement_got} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ＧＰＴ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_gpt" type="text" value={this.state.tableData.unimplement_gpt} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ＡＬＰ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_alp" type="text" value={this.state.tableData.unimplement_alp} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[γ－ＧＴＰ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_gamma_gtp" type="text" value={this.state.tableData.unimplement_gamma_gtp} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[中性脂肪]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_neutral_fat" type="text" value={this.state.tableData.unimplement_neutral_fat} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[尿酸]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_uric_acid" type="text" value={this.state.tableData.unimplement_uric_acid} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[ｸﾚｱﾁﾆﾝ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_creatinine" type="text" value={this.state.tableData.unimplement_creatinine} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="[HDL-ｺﾚｽﾃ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={12} style={styleTextRight}>
                    <Input readonly style={styleTextRight} name="unimplement_hdl_cholesterol" type="text" value={this.state.tableData.unimplement_hdl_cholesterol} />
                  </Col>
                </Row>

              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1171001_AggregateInfoCorrect);
