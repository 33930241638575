import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Input, Button, message, Form, Modal } from 'antd'
import PropTypes from 'prop-types'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
// import InspectCmtSearchQueryAction from 'redux/InputBusiness/SpreadInput/InspectCmtSearchQuery.action.js'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import { SaveOutlined } from '@ant-design/icons'

const formItemStyle = {
  labelCol: { style: { width: 100 } }
}

class WS0274002_InspectCmtSearchInput extends React.Component {
  static propTypes = {
    Lio_CmtClassify: PropTypes.any,
    LnkOutInspectCmtScreen: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査コメント入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      screenData: {},
      disabledSaveBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    // 検査コメントの初期値を設定
    this.formRef.current.setFieldsValue({
      judgmentValue: this.props.Lio_JudgmentValue,
      InspectCmtScreen: this.props.LnkOutInspectCmtScreen
    })
  }

  /**
   * 検査コメント検索・照会　モーダルを開く
   */
  showWS0274001_InspectCmtSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0274001_InspectCmtSearchQuery
            Li_PatternCode={this.props.Li_PatternCode}
            Li_CategoryCode={this.props.Li_CategoryCode}
            Li_CategoryName={this.props.Li_CategoryName}
            Lio_StsNotesChange={this.props.Lio_StsNotesChange}
            Lio_CmtClassify={this.props.Lio_CmtClassify}
            LnkOutInspectCmtScreen={this.props.LnkOutInspectCmtScreen}
            onFinishScreen={(output) => {
              // 選択された検査コメントを反映
              this.formRef.current.setFieldsValue({
                InspectCmtScreen: output.LnkOutInspectCmtScreen
              })
              // 保存ボタン活性
              this.changeDisabledSaveBtn()
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
  * 判定選択
  * @param {*} data
  */
  showWS0285001_JudgeQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {
              if (output.recordData) {
                // 判定 変更
                await this.formRef?.current?.setFieldsValue({
                  judgmentValue: output.recordData.judgment_result
                })
                // 保存ボタン活性
                await this.changeDisabledSaveBtn()
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 保存ボタン押下処理
   */
  save = () => {
    // Fromに設定されている値を取得
    const data = this.formRef.current.getFieldsValue(true)

    const params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_JudgmentValue: data.judgmentValue ?? '', //Input入力値
      Li_ResultValue: data.InspectCmtScreen ?? '', //Input入力値
    }

    OverallResultDisplayInputAction.saveIspectResultValue(params)
      .then((res) => {
        if (this.props.onUpdateResultValue) {
          // 親画面に反映
          this.props.onUpdateResultValue()
        }
      })
  }

  /**
   * 保存ボタンの活性
   */
  changeDisabledSaveBtn = () => {
    if (this.state.disabledSaveBtn) {
      // 保存ボタンの活性
      this.setState({ disabledSaveBtn: false })
    }
  }


  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='inspect-cmt-search-query'>
        <Card title='検査コメント入力'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                name='judgmentValue'
                label='判定'
                {...formItemStyle}
              >
                <Input.Search
                  className='input-search-size-judgment'
                  onSearch={() => {
                    // 判定選択
                    this.showWS0285001_JudgeQuery()
                  }}
                />
              </Form.Item>
              <div className='box_inner_horizontal'>
                <Form.Item
                  label='検査コメント'
                  name='InspectCmtScreen' //検査コメント
                  {...formItemStyle}
                  style={{ width: '100%' }}
                >
                  <Input
                    onChange={() => {
                      // 保存ボタン活性
                      this.changeDisabledSaveBtn()
                    }} />
                </Form.Item>

                <Button
                  type='primary'
                  onClick={() => {
                    // 検査コメント検索・照会　モーダルを開く
                    this.showWS0274001_InspectCmtSearchQuery()
                  }}
                >
                  参照
                </Button>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
                onClick={() => {
                  // 保存
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0274002_InspectCmtSearchInput)
