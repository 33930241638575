// 紹介状関連の固定データ
const WS0898001_dataList = {
  initData: {
    "GpersonalNum": "9999023",
    "visit_date_on": "2023/03/04",
    "receipt_number": 1,
    "kanji_name": "テスト　震災",
    "course_level": 0,
    "pattern_code": "Patern01",
    "OptionHghp18C0": "//桁数=96//ｺﾒﾝﾄ抽出=N//ﾌﾟﾘﾝﾀｰNo=1//ﾌﾟﾚﾋﾞｭｰ=0//検査名称=正式//H64=YES//単位=出力//出力基本判定=D1,D2//出力ｸﾞﾙｰﾌﾟ判定=D1,D2//出力方法=AMKS//ｺｰｽ・診療科別書式使用=Y//抽出方法=AMKS//ｶﾃｺﾞﾘ名称印刷有無=Y//ｶﾃｺﾞﾘ名称印刷ﾀｲﾌﾟ=G//FORM=紹介状_帳票4.xlsx//様式選択=N"
  },
  introductionsData: [
    {
      "id": 6,
      "GdateChars": "2023/03/04",
      "accepted_no": 1,
      "department": 10,
      "exam_department": 20,
      "doctor_code": 1,
      "general_comment_code": 6,
      "comment_content": "",
      "Remarks": "",
      "issued_flg": 1,
      "visitDateOn": "2023/03/04",
      "department_name": "内科",
      "exam_department_name": "身体計測",
      "doctor_name": "ＸＸ　ＸＸ",
      "issue": "〇",
      "issueColorNum": 211,
      "sendingBack": "〇",
      "sendingBackColorNum": 211,
      "editContentDisabled": false,
      "reservation_number": 202303030000045,
      "visit_date_on": "2023-03-04",
      "StsJ41MedicalInstitutions": 0,
      "medical_institution_code": 6,
      "StsJ41Doctor": 0,
      "StsJ42": 0,
      "personal_number_id": "9999023",
      "BeforeDepartment": 10,
      "StsJ41Department": 0,
      "medical_exam_course": "C00"
    },
    {
      "id": 8,
      "GdateChars": "2023/03/04",
      "accepted_no": 1,
      "department": 20,
      "exam_department": 0,
      "doctor_code": 1,
      "general_comment_code": 24,
      "comment_content": "",
      "Remarks": "",
      "issued_flg": 1,
      "visitDateOn": "2023/03/04",
      "department_name": "循環器科",
      "exam_department_name": "血圧",
      "doctor_name": "ＸＸ　ＸＸ",
      "issue": "〇",
      "issueColorNum": 211,
      "sendingBack": "×",
      "sendingBackColorNum": 208,
      "editContentDisabled": false,
      "reservation_number": 202303030000045,
      "visit_date_on": "2023-03-04",
      "StsJ41MedicalInstitutions": 1,
      "medical_institution_code": 0,
      "StsJ41Doctor": 0,
      "StsJ42": 0,
      "personal_number_id": "9999023",
      "BeforeDepartment": 20,
      "StsJ41Department": 0,
      "medical_exam_course": "C00"
    },
    {
      "id": 7,
      "GdateChars": "2023/03/04",
      "accepted_no": 1,
      "department": 40,
      "exam_department": 10,
      "doctor_code": 1,
      "general_comment_code": 20,
      "comment_content": "",
      "Remarks": "",
      "issued_flg": 1,
      "visitDateOn": "2023/03/04",
      "department_name": "消化器科",
      "exam_department_name": "胃部X線",
      "doctor_name": "ＸＸ　ＸＸ",
      "issue": "〇",
      "issueColorNum": 211,
      "sendingBack": "×",
      "sendingBackColorNum": 208,
      "editContentDisabled": false,
      "reservation_number": 202303030000045,
      "visit_date_on": "2023-03-04",
      "StsJ41MedicalInstitutions": 1,
      "medical_institution_code": 0,
      "StsJ41Doctor": 0,
      "StsJ42": 0,
      "personal_number_id": "9999023",
      "BeforeDepartment": 40,
      "StsJ41Department": 0,
      "medical_exam_course": "C00"
    }
  ]

}

export default WS0898001_dataList
