import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Dropdown, Button, Menu, message } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import ProgressInfoMaintainAction from 'redux/SystemMaintenance/ProgressInfoMaintain/ProgressInfoMaintain.actions'
import WS1499002_ProgressInfoMaintainEdit from 'pages/SM_SystemMaintenance/V4SM0006001_ProgressInfoMaintain/WS1499002_ProgressInfoMaintainEdit.jsx';
import WS1499003_ProgressInfoMaintainDetail from 'pages/SM_SystemMaintenance/V4SM0006001_ProgressInfoMaintain/WS1499003_ProgressInfoMaintainDetail.jsx';

const programList = [
  { value: '予約関連', label: '予約関連' },
  { value: '保健指導進捗', label: '保健指導進捗' },
  { value: '結果表', label: '結果表' },
  { value: '紹介状', label: '紹介状' }
]

const processList = [
  { value: 'print', label: '印刷' },
  { value: 'post', label: '郵送' },
  { value: 'confirm', label: '確定' },
  // { value: 'confirm2', label: '確定2' },
  { value: 'temporary', label: '仮確定' },
  { value: 'protection', label: '保護' }
]

class WS1499001_ProgressInfoMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '進捗情報保守'

    this.state = {
      isLoadingTable: false,
      dataTable: [],
      rowSelect: {},

      treeData: [],
      nodeSelect: {},
      isLoadTree: false,

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount = () => {
    this.loadData()
  }

  loadData() {
    this.setState({
      isLoadTree: true,
    })

    ProgressInfoMaintainAction.getScreenDataProgressInfoMaintain()
      .then(res => {
        this.setState({
          dataTable: res.data
        })
      })
  }

  showWS1499002_ProgressInfoMaintainEdit = (params) => {
    let newFlag = true
    if (params) {
      newFlag = false
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '400px',
        className: '',
        component:
          <WS1499002_ProgressInfoMaintainEdit
            newFlag={newFlag}
            record={params}
            programList={programList}
            processList={processList}
            onUpdate={(params) => {
              console.log(params);
              this.addUpdateNodeData(params)
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen()
              }
            }}
            onDelete={() => {
              console.log(params);
              this.deleteNodeData(params)
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen()
              }
            }}
          />
      },
    });
  }

  showWS1499003_ProgressInfoMaintainDetail = (params) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        className: '',
        component:
          <WS1499003_ProgressInfoMaintainDetail
            record={params}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
      },
    });
  }

  deleteNodeData(params) {
    ProgressInfoMaintainAction.deleteNodeData(params)
      .then(res => {
        if (res) {
          this.loadData()
          this.closeModal()
        }
      })
      .catch(err => message.error(err.message))
  }

  addUpdateNodeData(params) {
    ProgressInfoMaintainAction.addUpdateNodeData(params)
      .then(res => {
        if (res) {
          this.loadData()
          this.closeModal()
        }
      })
      .catch(err => message.error(err.message))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='progress-info-maintain'>
        <Card title='進捗情報保守'>
          <Form ref={this.formRef} >
            <div>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataTable}
                loading={this.state.isLoadingTable}
                rowKey={(record) => record.id}
                pagination={false}
                onRow={(record, index) => {
                  return {
                    onDoubleClick: async () => {
                      this.showWS1499003_ProgressInfoMaintainDetail(record)
                    }
                  };
                }}
              >
                <Table.Column
                  title='コード'
                  dataIndex='child_node'
                  width={150}
                />

                <Table.Column
                  title='名称'
                  dataIndex='name'
                />

                <Table.Column
                  title='機能'
                  dataIndex='program'
                  width={150}
                  render={(value) => {
                    let programName = ''
                    if (value !== '') {
                      // 続柄名称の取得
                      programName = programList.find(item => item.value === String(value))?.label
                    }
                    return programName
                  }}
                />

                <Table.Column
                  title='発行'
                  dataIndex='process'
                  width={150}
                  render={(value) => {
                    let processName = ''
                    if (value !== '') {
                      // 続柄名称の取得
                      processName = processList.find(item => item.value === String(value))?.label
                    }
                    return processName
                  }}
                />

                <Table.Column
                  align='center'
                  width={40}
                  title={() => (
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        this.showWS1499002_ProgressInfoMaintainEdit()
                      }}
                    />
                  )}
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger="click"
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key="変更"
                              onClick={() => {
                                this.showWS1499002_ProgressInfoMaintainEdit(record)
                              }}
                            >変更
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size="small"
                          icon={<MoreOutlined />}
                          style={{ width: "100%" }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1499001_ProgressInfoMaintain)
