import React from "react";
import { connect } from "react-redux";

import { Card, Checkbox, Col, Row, Table, } from "antd";

class WS1519002_OptionTypeSetting extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'オプション種別設定';

    this.state = {
    };
  }

  render() {
    return (
      <div className="option-type-setting">
        <Card className="mb-2" title="オプション種別設定">
          <Row>
            <Col span={6}>
              <Table bordered
                style={{ marginRight: '10px' }}
                loading={false}
                pagination={{
                  defaultCurrent: 1,
                  total: 1,
                  pageSize: 1,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => { },
                  onShowSizeChange: (page, pageSize) => { },
                }}
                rowKey={(record) => record.id}
              >
                <Table.Column title="" width={80} dataIndex="" key="" render={(value,recod,index)=>{
                  <Checkbox></Checkbox>
                }} />
                <Table.Column title="種別" dataIndex="" key="" />
              </Table>
            </Col>
            <Col span={18}>
              <Table bordered
                loading={false}
                pagination={{
                  defaultCurrent: 1,
                  total: 1,
                  pageSize: 1,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => { },
                  onShowSizeChange: (page, pageSize) => { },
                }}
                rowKey={(record) => record.id}
              >
                <Table.Column title="" width={80} dataIndex="" key="" render={(value,recod,index)=>{
                  <Checkbox></Checkbox>
                }} />
                <Table.Column title="オプションコード" width={350} dataIndex="" key="" />
                <Table.Column title="識別名称" dataIndex="" key="" />
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1519002_OptionTypeSetting);
