import ConfirmScreenService from 'services/basicInfo/ContractInfoMaintain/ConfirmScreenService';
import { message, } from "antd"

const ConfirmScreenAction = {
  getScreenData(params) {
    return ConfirmScreenService.getScreenData(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  CopyExec(params) {
    return ConfirmScreenService.CopyExec(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  getContractName(params) {
    return ConfirmScreenService.getContractName(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

};
export default ConfirmScreenAction;