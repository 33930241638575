import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Space, Button, Input, Modal } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import FollowUpLetterCourseDepartmentspecificFormatAction from 'redux/IntroductionLetter/FollowUpLetter/FollowUpLetterCourseDepartmentspecificFormat.action.js'

class FollowUpLetterCourseEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コース・診療科別書式(経過観察用)'

    this.state = {
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      id: '',
      course_code: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    if (this.props.record) {
      // 変更の場合
      this.formRef.current?.setFieldsValue({
        id: this.props.record.id,
        course_code: this.props.record.course_code,
        format_name: this.props.record.format_name
      })
      this.setState({
        id: this.props.record.id,
        course_code: this.props.record.course_code
      })
    }
  }

  /**
   * 基本コース照会 モーダル
   * @param {*} value
   */
  searchBasicCourse = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0265001_BasicCourseInquiry
            Li_CourseName={value}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                course_code: output.Lo_CourseCode
              })
              this.setState({
                course_code: output.Lo_CourseCode
              })
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * 保存処理
   */
  saveCourse = () => {
    let params = {
      id: this.state.id,
      course_code: this.formRef.current?.getFieldValue('course_code'),
      format_name: this.formRef.current?.getFieldValue('format_name')
    }
    FollowUpLetterCourseDepartmentspecificFormatAction.saveCourse(params)
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render = () => {
    return (
      <div className='course-edit' style={{ width: 700 }}>
        <Card title={`コース書式(経過観察用) [${(this.props.record) ? '変更' : '新規追加'}] `}>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Form.Item name='course_code' label='コース'>
                <Input.Search
                  className='input-size-10'
                  allowClear={true}
                  onPressEnter={(e) => this.searchBasicCourse(e.target.value)}
                  onSearch={(value, event) => {
                    if (event.target.localName === 'input') {
                      this.formRef.current?.setFieldsValue({
                        course_code: ''
                      })
                      this.setState({
                        course_code: ''
                      })
                    } else {
                      this.searchBasicCourse(value)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name='format_name' label='　書式'>
                <Input />
              </Form.Item>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  onClick={() => this.saveCourse()}
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpLetterCourseEdit)
