import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Row, Col, DatePicker, Divider } from "antd";

class WS1442001_ContractDisplay extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '*  契約表示';

    this.state = {
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="contract-display">
        <Card className="mb-2" title="契約表示">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row gutter={24} style={{ marginTop: '30px' }}>
              <Col style={{ width: '13%' }}>
                <Form.Item style={{ float: 'right' }} label="受 診 日">
                </Form.Item>
              </Col>
              <Col style={{ width: '12%' }}>
                <Form.Item name="Date" label="">
                  <DatePicker format={'YYYY/MM/DD'}></DatePicker >
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="Ｉ　　Ｄ">
                </Form.Item>
              </Col>
              <Col style={{ width: '12%' }}>
                <Form.Item name="P1_Id" label="">
                  <Input type="text" style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col style={{ width: '16%' }}>
                <Form.Item name="kanji_name" label="">
                  <Input type="text" readOnly />
                </Form.Item>
              </Col>
              <Col style={{ width: '16%' }}>
                <Form.Item name="kana_name" label="">
                  <Input type="text" readOnly />
                </Form.Item>
              </Col>
              <Col style={{ width: '5%' }}>
                <Form.Item name="" label="">
                  <Input type="text" readOnly />
                </Form.Item>
              </Col>
              <Col style={{ width: '6%' }}>
                <Form.Item name="name" label="">
                  <Input type="text" readOnly />
                </Form.Item>
              </Col>
              <Col style={{ width: '11%' }}>
                <Form.Item name="Date" label="">
                  <DatePicker format={'YYYY/MM/DD'}></DatePicker >
                </Form.Item>
              </Col>
              <Col style={{ width: '8%' }}>
                <Form.Item name="Date" label="">
                  <Input type="text" readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <label style={{ fontWeight: 'bold', color: 'black', margin: '30px 0 10px 150px' }}>【　利用券情報　】</label>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="保険者番号">
                </Form.Item>
              </Col>
              <Col style={{ width: '10%' }}>
                <Form.Item name="insurer_number" label="">
                  <Input type="text" style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col style={{ width: '26%' }}>
                <Form.Item name="insurer_kanji_name" label="">
                  <Input type="text" readOnly style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="利用券整理番号">
                </Form.Item>
              </Col>
              <Col style={{ width: '11%' }}>
                <Form.Item name="vouchers_docket_num" label="">
                  <Input type="text" style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col style={{ width: '8%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="受診券整理番号">
                </Form.Item>
              </Col>
              <Col style={{ width: '11%' }}>
                <Form.Item name="consult_ticket_docket_num" label="">
                  <Input type="text" style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col style={{ width: '8%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="交付年月日">
                </Form.Item>
              </Col>
              <Col style={{ width: '13%' }}>
                <Form.Item name="date_issuance" label="">
                  <DatePicker format={'YYYY/MM/DD'}></DatePicker>
                </Form.Item>
              </Col>
              <Col style={{ width: '8%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="有効期限">
                </Form.Item>
              </Col>
              <Col style={{ width: '13%' }}>
                <Form.Item name="expiration_date" label="">
                  <DatePicker format={'YYYY/MM/DD'}></DatePicker>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="保健指導区分">
                </Form.Item>
              </Col>
              <Col style={{ width: '11%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: '30px' }}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="負担内容">
                </Form.Item>
              </Col>
              <Col style={{ width: '35%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="" label="">
                  <Input type="text" style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col style={{ width: '8%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="保険者負担上限額">
                </Form.Item>
              </Col>
              <Col style={{ width: '10%' }}>
                <Form.Item name="specify_insurer_pay_max" label="">
                  <Input type="text" style={{ float: 'right' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <label style={{ fontWeight: 'bold', color: 'black', margin: '30px 0 10px 150px' }}>【　契約情報　】</label>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="契約番号">
                </Form.Item>
              </Col>
              <Col style={{ width: '10%' }}>
                <Form.Item name="contract_number" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '10%' }}>
                <Form.Item name="determining_billing_info_contra" label="">
                  <Input type="text" readOnly style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col style={{ width: '10%' }}>
                <Form.Item name="determining_billing_info_contra" label="">
                  <Input type="text" readOnly style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="契約名称">
                </Form.Item>
              </Col>
              <Col style={{ width: '30%' }}>
                <Form.Item name="contract_name" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="契約形態">
                </Form.Item>
              </Col>
              <Col style={{ width: '10%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="初回徴収方式">
                </Form.Item>
              </Col>
              <Col style={{ width: '27%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="動機付け単価">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="motivation_unit_price" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '4%' }}>
                <Form.Item name="" style={{ float: 'center' }} label="初回">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="price_motivation_rate_01" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '4' }}>
                <Form.Item name="" style={{ float: 'center' }} label="終了">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="price_motivation_rate_02" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col style={{ width: '13%' }}>
                <Form.Item name="" style={{ float: 'right' }} label="積極的単価">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="actively_unit_price" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '4%' }}>
                <Form.Item name="" style={{ float: 'center' }} label="初回">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="aggressive_price_rate_01" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '4%' }}>
                <Form.Item name="" style={{ float: 'center' }} label="継続">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="aggressive_price_rate_02" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '4%' }}>
                <Form.Item name="" style={{ float: 'center' }} label="終了">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="" label="">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="aggressive_price_rate_03" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>
              <Col style={{ width: '8%' }}>
                <Form.Item name="" style={{ float: 'center' }} label="実施ポイント数">
                </Form.Item>
              </Col>
              <Col style={{ width: '7%' }}>
                <Form.Item name="active_constant_support_enforce" label="">
                  <Input style={{ textAlign: 'right' }} type="text" />
                </Form.Item>
              </Col>

            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col style={{ width: '50%' }}>
                <Form.Item >
                  <Button type="primary" style={{ marginLeft: '100px', width: '120px' }}>閉じる</Button>
                </Form.Item>
              </Col>
              <Col style={{ width: '50%' }}>
                <Form.Item >
                  <Button type="primary" style={{ marginRight: '100px', width: '120px', float: 'right' }}>契約選択</Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1442001_ContractDisplay);
