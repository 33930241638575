import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, message, Modal } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import DunningPrintAction from 'redux/SpecificInsureGuide/InsureGuideInput/DunningPrint.action'
import { download_file } from "helpers/CommonHelpers";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

class WS1445001_DunningPrint extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '督促状印刷';

    this.state = {
      dataSource: [],
      rowSelect: [],
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.callAPILoadData();
  }

  callAPILoadData = () => {
    this.setState({ isLoading: true });
    let params = {
      Li_Date: this.props.Li_Date,
      Li_Id: this.props.Li_Id,
    }
    DunningPrintAction.getScreenData(params)
      .then(res => {
        this.setState({
          dataSource: res,
          rowSelect: res[0]
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleF12UserPrint = () => {
    DunningPrintAction.handleF12UserPrint()
      .then(res => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 'fit-content',
            component: (
              <WS0433001_PrinterConfirm
                Li_PreviewSpecifiedValue={res.variables?.Li_PreviewSpecifiedValue}
                Li_PrinterNoSpecifiedValue={res.variables?.Li_PrinterNoSpecifiedValue}
                onFinishScreen={(output) => {
                  // this.formRef.current?.setFieldsValue({
                  //   PreviewMode: output?.Lo_Preview,
                  //   PrinterNo: output?.Lo_PrinterNo,
                  //   StsConfirm: output?.Lo_StsOutput
                  // })
                  this.closeModal()
                  this.print(output)
                }}
              />
            ),
          },
        });
      }).catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  print(output) {
    if (output.Lo_Preview) {
      let params = {
        date_on: this.state.rowSelect.date_on,
        dunning_implement_date_on: this.state.rowSelect.dunning_implement_date_on,
        personal_number_id: this.state.rowSelect.personal_number_id,
        PreviewMode: output?.Lo_Preview,
        PrinterNum: output?.Lo_PrinterNo,
        StsConfirm: output?.Lo_StsOutput
      }
      DunningPrintAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          // fileOpen.onload = function () {
          //   fileOpen.print();
          // };
        }).catch((error) => {
          const res = error.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
    } else {
      let params = {
        date_on: this.state.rowSelect.date_on,
        dunning_implement_date_on: this.state.rowSelect.dunning_implement_date_on,
        personal_number_id: this.state.rowSelect.personal_number_id,
        output: output,
      }
      DunningPrintAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        }).catch((error) => {
          const res = error.response;
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          if (!res || !res.data || !res.data.message) {
            message.error("エラーが発生しました");
            return;
          }
          message.error(res.data.message);
        })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="dunning-print">
        <Card className="mb-2" title="督促状印刷">
          <Table
            bordered
            size="small"
            dataSource={this.state.dataSource ? this.state.dataSource : []}
            rowClassName={(record, index) => record.id === this.state.rowSelect?.id ? 'table-row-light' : ''}
            loading={this.state.isLoading}
            scroll={{ y: resizableTableScroll(130) }}
            pagination={false}
            onRow={(record, index) => ({
              onClick: (event) => this.setState({ rowSelect: record }),
            })}
            rowKey={(record) => record.id}
          >
            <Table.Column title="督促日" dataIndex="dunning_implement_date_on" />
            <Table.Column title="督促内容" dataIndex="content" />
            <Table.Column title="発行" dataIndex="support_code" width={40} />

          </Table>
          <div className="box_button_bottom_right">
            <Button type="primary"
              onClick={() => {
                this.handleF12UserPrint()
              }}>印刷</Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1445001_DunningPrint);
