import React from "react";
import { connect } from "react-redux";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import DunningInputAction from "redux/SpecificInsureGuide/InsureGuideInput/DunningInput.action"
import WS1444016_DunningInputAdd from 'pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444016_DunningInputAdd.jsx'
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Select, Button, message, Row, Col, Modal, Table, Dropdown, Menu } from "antd";
import { CloseCircleOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons';
import moment from "moment";
class WS1444016_DunningInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '督促入力';

    this.state = {
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 1,
        showQuickJumper: true
      },
      particularsList: []
    };
  }

  componentDidMount() {
    console.log(this.props);
    this.getScreenData();
    this.formRef.current.setFieldsValue({ ReminderImplementDateChar: this.props?.Li_ScheduledImplementDate });
    if (this.props?.Li_SupportCode) {
      this.formRef.current.setFieldsValue({ SupportCode: this.props?.Li_SupportCode });
    }
  }

  getScreenData = async () => {
    this.setState({ isLoading: true, rowSelect: {} });
    let params = {
      Li_DunningImplementDate: this.props?.Li_ScheduledImplementDate,
      Li_Id: this.props.Li_Id,
      Li_SupportCode: this.props.Li_SupportCode,
      Li_Date: this.props.Li_Date
    }
    DunningInputAction.getScreenData(params)
      .then((res) => {
        this.setState({
          dataSource: res,
          particularsList: res.D30HealthGuideDunningDatas
        })
        this.formRef.current?.setFieldsValue({
          particularsList: res.D30HealthGuideDunningDatas
        })
        this.forceUpdate()
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  update() {
    let ReminderImplementDateChar = this.formRef.current.getFieldValue('ReminderImplementDateChar')
    let SupportCode = this.formRef.current.getFieldValue('SupportCode') ? this.formRef.current.getFieldValue('SupportCode') : ""
    let tableData = this.props.tableData

    for (let k = 0; k < tableData.length; k++) {
      if (ReminderImplementDateChar === moment(tableData[k].Li_Date).format('YYYY/MM/DD') && SupportCode === tableData[k].supportitem.support_code) {
        message.error('データが重複しています')
        return
      }
    }
    let params = {
      ReminderImplementDateChar: ReminderImplementDateChar,
      SupportCode: SupportCode,
      Li_Date: this.props?.Li_Date,
      Li_Id: this.props?.Li_Id,
      Li_DunningImplementDate: this.formRef.current.getFieldValue('ReminderImplementDateChar'),
      particularsList: this.state.particularsList,
    }
    DunningInputAction.update(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  createNew() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 650,
        component: (
          < WS1444016_DunningInputAdd
            newFlag={true}
            onFinishScreen={(output) => {
              console.log(output);
              this.closeModal();
              let copyData = [...this.state.particularsList]
              let particulars = {
                id: Math.round(Math.random() * 10000),
                serial_number: output.serial_number,
                content: output.content
              }
              copyData.push(particulars)
              console.log(copyData);
              copyData.sort(function (a, b) {
                if (a.serial_number > b.serial_number) {
                  return 1;
                } else {
                  return -1;
                }
              })
              this.setState({
                particularsList: copyData
              })
              this.formRef.current?.setFieldsValue({
                particularsList: copyData
              })
              this.update()
            }}
          />
        ),
      },
    });
  }

  edit(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 650,
        component: (
          < WS1444016_DunningInputAdd
            newFlag={false}
            record={record}
            onFinishScreen={(output) => {
              this.closeModal();
              let copyData = [...this.state.particularsList]
              for (let i = 0; i < copyData.length; i++) {
                if (record.id === copyData[i].id) {
                  copyData[i].serial_number = output.serial_number
                  copyData[i].content = output.content
                }
              }
              copyData.sort(function (a, b) {
                if (a.serial_number > b.serial_number) {
                  return 1;
                } else {
                  return -1;
                }
              })
              this.setState({
                particularsList: copyData
              })
              this.formRef.current?.setFieldsValue({
                particularsList: copyData
              })
            }}
          />
        ),
      },
    });
  }

  onFinish(values) {

  }

  closeModal = () => {
    this.setState((prevState) => ({
      childModal: { ...prevState.childModal, visible: false },
    }));
  };

  render() {
    return (
      <div className="dunning-input">
        <Card className="mb-2" title="督促入力">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className="box_inner_vertical">
              <Form.Item
                name="ReminderImplementDateChar"
                label="督促日"
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format="YYYY/MM/DD"
                  style={{ width: "100%" }}
                  allowClear={true}
                />
              </Form.Item>
              <Form.Item
                name="SupportCode"
                label="方　法"
              >
                <Select>
                  <Select.Option value="0">{" "}</Select.Option>
                  {this.state.dataSource?.SupportCode_List?.map(item => (
                    <Select.Option key={item.support_code} value={item.support_code}>{item.support}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Table
                bordered
                size="small"
                rowKey={(record) => record.id}
                rowClassName={(record, index) => record.id === this.state.rowSelect.id ? 'hightlight-row-selected' : ''}
                onRow={(record, index) => ({
                  onClick: (event) => this.setState({ rowSelect: record }),
                })}
                pagination={{ ...this.state.pagination }}
                dataSource={this.state.particularsList}
              >
                <Table.Column
                  title="連番"
                  dataIndex="serial_number"
                  width={60}

                />
                <Table.Column
                  title="内容"
                  dataIndex="content"
                />
                <Table.Column width={40} title={<Button type='primary' icon={<PlusOutlined />} size="small" onClick={() => this.createNew()}></Button>} align='center' render={(value, record) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key="menu-1"
                            onClick={() => this.edit(record)}
                          >編集</Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        icon={<MoreOutlined />}
                        size="small"
                      ></Button>
                    </Dropdown>
                  )
                }} />
              </Table>
            </div>
            <div className="box_button_bottom_right">
              <Button type="primary" onClick={async () => {
                if (!this.formRef.current.getFieldValue('SupportCode') || this.formRef.current.getFieldValue('SupportCode') == 0) {
                  Modal.error({
                    content: '方法を選択して下さい',
                    icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />
                  });
                  return;
                }
                this.update()
              }}>更　新</Button>
            </div>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1444016_DunningInput);
