import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Checkbox, Button, Radio, Table, Menu, Dropdown, Tooltip, message, Modal } from 'antd'
import { MoreOutlined, MenuOutlined, SendOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS0273001_VisitsTargetSearchQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273001_VisitsTargetSearchQuery.jsx'
import WS2739001_ErrorConfirmSub from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2739001_ErrorConfirmSub.jsx'
import WS2737500_MiraisSingleTransmissionEdit from './WS2737500_MiraisSingleTransmissionEdit.jsx'
import WS2751001_MiraisInspectMaintain from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2751001_MiraisInspectMaintain.jsx'
import WS2749001_CheckAvailability from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2749001_CheckAvailability.jsx'
import WS2745009_ConfirmScreen from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2745009_ConfirmScreen.jsx'
import WS2738001_LaboratoryInspectConfirmSub from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2738001_LaboratoryInspectConfirmSub.jsx'
import WS2737005_MiraisSingleDoctorSetting from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737005_MiraisSingleDoctorSetting.jsx'
import MiraisSingleTransmissionAction from 'redux/CooperationRelated/MiraisSingleTransmission/MiraisSingleTransmission.actions.js'
import GetImage from 'constants/Images'
import TransmissionStatusChange from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/TransmissionStatusChange.jsx'

const labelStyle = {
  labelCol: {
    style: {
      width: 80,
      textAlignLast: 'justify',
      textJustify: 'inter-ideograph'
    }
  },
  wrapperCol: {
    style: {
      marginLeft: '10px',
    }
  }
}
class WS2737001_MiraisSingleTransmission extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = 'Mirais単体送信'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      title: '',
      dataSource: [],
      infoPage: {},

      initParams: {
        Li_ReserveNum: '',
        Li_CourseLevel: 0,
        TransmissionState: '0',
        OrderSpecies: '',
        StsModify: 0
      },
      isChange: true,
      StsConfirmScreen: false,
      StsRun: false,

      MiraisStartupCheck: false,
      MiraisStartupStatus: '',
      timeSettedOnly: 0
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    if (this.props.Li_ReserveNum) {
      this.setState({
        initParams: {
          ...this.state.initParams,
          Li_ReserveNum: this.props.Li_ReserveNum || '',
          Li_CourseLevel: this.props.Li_CourseLevel || 0
        }
      }, () => this.index(this.state.initParams, true))
    }

    // Mirais端末の起動状態チェック
    MiraisSingleTransmissionAction.checkMiraisStartup()
      .then(res => {
        this.setState({
          MiraisStartupCheck: true,
          MiraisStartupStatus: res.message
        })
      })
  }

  /**
   * 初期データ取得
   * @param {*} params
   * @param {*} initialFlag
   */
  index = (params, initialFlag = false) => {
    this.formRef?.current?.resetFields()
    MiraisSingleTransmissionAction.index(params)
      .then(res => {
        if (res) {
          let data = {
            ...res,
            period_time: res.period_time ? moment(res.period_time, 'HH:mm').format('HH:mm') : '',
            birthday_on: res.birthday_on ? moment(res.birthday_on).format('NNy/MM/DD') : '',
            Expression_13: res.StsConsult && res.StsPerson,
            Expression_15: res.StsConsult,
          }
          this.formRef?.current?.setFieldsValue({
            'infoPage': data,
          })

          // state_flagが1の時（受付）は、時間枠のチェックはしない
          if (initialFlag == true && !res.stateFlag) {
            this.formRef.current?.setFieldsValue({
              'orderStartTimeAt': res.timeSettedOnly
            })
          }

          this.setState({
            infoPage: data
          })

          // 送信データ取得
          this.getListData()
        }
      })
  }

  /**
   * 送信データ取得
   * @param {*} params
   */
  getListData = () => {
    this.setState({ dataSource: [] })

    const params = {
      ...this.state.initParams,
      orderStartTimeAt: this.formRef.current?.getFieldValue('orderStartTimeAt') ? 1 : 0,
    }

    MiraisSingleTransmissionAction.getListData(params)
      .then(res => {
        if (res) {
          let data = res.map((item, index) => ({
            ...item,
            DateDisplay: item.DateDisplay === '0000/00/00' || item.DateDisplay === '0000-00-00'
              ? ''
              : moment(item.DateDisplay).format('YYYY/MM/DD'),
            OrderStartDate: item.OrderStartDate === '0000/00/00' || item.OrderStartDate === '0000-00-00'
              ? null
              : moment(item.OrderStartDate, 'YYYY/MM/DD'),
            order_start_time_at: item.order_start_time_at === '00:00:00' ? null : moment(item.order_start_time_at, 'HH:mm:ss'),
            TimeDisplay: item.TimeDisplay === '00:00:00' ? '' : item.TimeDisplay,
            order_number: item.order_number !== 0 ? item.order_number : null,
            order_subnumber: item.order_subnumber !== 0 ? item.order_subnumber : null,
            reservation_number: item.reservation_number !== 0 ? item.reservation_number : null,
          }))
          this.setState({
            dataSource: data,
            rowSelect: data.length > 0 ? data[0] : {}
          })
          this.formRef?.current?.setFieldsValue({ dataSource: data })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
  }

  createOrUpdateData = (record) => {
    const params = {
      id: record.isNew ? '' : record.id,
      Li_ReserveNum: this.state.initParams.Li_ReserveNum,
      personal_number_id: this.state.infoPage.personal_number_id || '',
      OrderSpecies: this.state.initParams.OrderSpecies,
      kind: record.kind,
      StsModify: this.state.initParams.StsModify,
      OrderStartDate: record.OrderStartDate ? moment(record.OrderStartDate).format('YYYY/MM/DD') : '',
      order_start_time_at: record.order_start_time_at ? moment(record.order_start_time_at).format('HH:mm:ss') : '',
      order_number: record.order_number || '',
      order_subnumber: record.order_subnumber || '',
      reservation_number: record.reservation_number || '',
      reservations_department: record.reservations_department || '',
      reservations_item_code: record.reservations_item_code || '',
      image_item_code: record.image_item_code || '',
      reservations_comment: record.reservations_comment || '',
      Submit: record.Submit || 0,
      processing_division: record.processing_division || '1'
    }
    MiraisSingleTransmissionAction.modifyData(params)
      .then(res => {
        // message.success('成功')
        this.getListData()
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  /**
   * 削除
   * @param {*} record
   * @param {*} index
   */
  deleteData = (record, index) => {
    if (record.id && !record.isNew) {
      MiraisSingleTransmissionAction.destroy({ id: record.id })
        .then(res => {
          message.success('削除が完了しました')
          // 送信データ取得
          this.getListData()
        })
        .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
    }
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  handleChangeInput = (record, value, name) => {
    let arrTemp = [...this.state.dataSource]
    let index = arrTemp.indexOf(record)
    if (index !== -1) {
      let objTemp
      switch (name) {
        case 'OrderStartDate':
          objTemp = {
            ...record,
            [name]: value,
            DateDisplay: value ? moment(value)?.format('YYYY/MM/DD') : null
          }
          break
        case 'order_start_time_at':
          objTemp = {
            ...record,
            [name]: value,
            TimeDisplay: value ? moment(value, 'HH:mm:ss')?.format('HH:mm:ss') : null
          }
          break
        case 'processing_division':
          objTemp = {
            ...record,
            [name]: value,
            ProcessSegmentDisplay: value === '1' ? '追加' : '削除'
          }
          break

        default:
          objTemp = {
            ...record,
            [name]: value,
          }
          break
      }
      arrTemp[index] = objTemp
      this.setState({
        dataSource: arrTemp,
        rowSelect: objTemp
      })
      this.formRef.current.setFieldsValue({ 'dataSource': arrTemp })
    }
  }

  /**
   * 各種設定の変更
   * @param {*} value
   * @param {*} name
   */
  handleChange = (value, name) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        [name]: value
      }
    }, () => this.getListData())
  }

  /**
   * 「抽出」処理
   */
  ExtractBtn = () => {
    const params = {
      Li_ReserveNum: this.state.initParams.Li_ReserveNum
    }
    MiraisSingleTransmissionAction.extractBtn(params)
      .then(res => {
        this.index(this.state.initParams)
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
  }

  /**
   * 医師設定が押された処理
   */
  doctorSetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component:
          <WS2737005_MiraisSingleDoctorSetting
          />,
      },
    })
  }

  /**
   * 送信ボタン押下処理
   */
  preSendMirais = () => {
    if (this.props.bulkSend ?? false) {
      this.bulkSendMirais()
    } else {
      // 送信処理 初回
      this.sendMirais(0)
    }
  }

  /**
  * 送信処理　ループ　(id ごとにAPIを呼ぶ)
  * @param {*} index
  */
  sendMirais(index) {
    const list = [...this.state.dataSource]
    if (list.length === 0) return

    let params = {
      Li_ReserveNum: this.state.initParams.Li_ReserveNum,
      OrderSpecies: this.state.initParams.OrderSpecies,
      Miraisdt0100Id: list[index].id ?? 0 //Miraisdt0100TransmissionContents の id
    }

    MiraisSingleTransmissionAction.sendMirais(params)
      .then(res => {
        if (res.Lo_StsRun) {
          // 送信結果を反映
          let tempArray = []
          list.forEach((obj, i) => {
            obj = { ...obj }
            if (i === index) {
              // 送信状態を更新
              obj.miraisSendStatus = res.miraisSendStatus ?? ''
              obj.miraisSendStatusTooltip = res.miraisSendStatusTooltip ?? ''
            }
            tempArray.push(obj)
          })
          this.setState({
            dataSource: tempArray
          })

          const nextIndex = index + 1
          if (list.length > nextIndex) {
            // ループ続行
            // 再びMirais送信をする
            this.sendMirais(nextIndex)

          } else {
            // ループ終了
            // データ再取得
            this.getListData()
            // MiraisSingleTransmissionAction.sendStatusModifyOrder({ reserveNum: this.state.initParams.Li_ReserveNum })
            //   .then(res => {
            //     this.getListData()
            //   })
          }
        } else {
          if (!(res.miraisStartup ?? false)) {
            // Mirais端末エラー
            Modal.error({
              title: 'Mirais端末エラー',
              content: res.message ?? '',
              okText: 'OK',
            })
            this.setState({
              MiraisStartupStatus: res.message ?? ''
            })
          } else {
            // 確認
            this.ShowWS2745009_ConfirmScreen(index)
          }
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res || !res.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.message)
      })
  }

  /**
  * 送信処理　(受付処理後のオーダー送信)
  * @param {*} index
  */
  bulkSendMirais(index) {
    this.props.onFinishScreen()

    let params = {
      Li_ReserveNum: this.state.initParams.Li_ReserveNum,
      OrderSpecies: this.state.initParams.OrderSpecies,
      // Miraisdt0100Id: list[index].id ?? 0 //Miraisdt0100TransmissionContents の id
    }

    MiraisSingleTransmissionAction.sendMirais(params)
      .catch(error => {
        const res = error.response
        if (!res || !res || !res.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.message)
      })
  }

  /**
   * 確認画面
   */
  ShowWS2745009_ConfirmScreen = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS2745009_ConfirmScreen
            Li_ExamDateF={''}
            Li_ExamDateT={''}
            Li_ReserveNum={this.state.initParams.Li_ReserveNum}
            Li_StsConfirm={this.state.StsConfirmScreen}
            onFinishScreen={(output) => {
              if (output?.Lo_StsConfirm) {
                this.sendMirais(index)
              }
              this.closeModal()
            }}
          />
        ),
      }
    })
  }


  /**
  * 開発用画面
  */
  ShowTransmissionStatusChange = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 200,
        component: (
          <TransmissionStatusChange
            dataSource={this.state.dataSource}
            Li_ReserveNum={this.state.initParams.Li_ReserveNum}
            onFinishScreen={(output) => {
              this.getListData()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    const { rowSelect, infoPage } = this.state
    const { Expression_13, Expression_15 } = infoPage
    return (
      <div className='mirais-single-transmission'>
        <Card title={'Mirais単体送信' + this.state.title}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.Li_ReserveNum) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1100,
                            component: <WS2751001_MiraisInspectMaintain
                              onFinishScreen={(data) => {
                                this.closeModal()
                              }}
                            />,
                          },
                        })
                      }}
                    >
                      保守
                    </Menu.Item>

                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1500,
                            component: <WS2749001_CheckAvailability
                              onFinishScreen={(data) => {
                                this.closeModal()
                              }}
                            />,
                          },
                        })
                      }}
                    >
                      空き状況
                    </Menu.Item>

                    <Menu.Item
                      key='3'
                      style={{ width: '100%' }}
                      onClick={() => this.ExtractBtn()}
                      disabled={!this.state.initParams.Li_ReserveNum}
                    >
                      抽出
                    </Menu.Item>

                    <Menu.Item
                      key='4'
                      style={{ width: '100%' }}
                      onClick={() => this.doctorSetting()}

                    >
                      医師設定
                    </Menu.Item>
                    <Menu.Item
                      key='4'
                      style={{ width: '100%' }}
                      onClick={() => this.ShowTransmissionStatusChange()}
                    >
                      状態変更
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.Li_ReserveNum) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form ref={this.formRef} initialValues={this.state.initParams}>
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>
                <div className='box_inner_horizontal' style={{ width: 'fit-content' }}>
                  <div className='box_search' >
                    <div className='box_search_inner_border'>
                      <img
                        src={infoPage.sex == 1 ? GetImage('man') : GetImage('woman')}
                        alt='gender'
                        style={{ position: 'absolute', marginLeft: 240, width: 50 }}
                        hidden={!infoPage.personal_number_id}
                      />
                      <Form.Item
                        label='個人番号'
                        name={['infoPage', 'personal_number_id']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div>{this.state.infoPage.personal_number_id}</div>
                      </Form.Item>

                      <Form.Item
                        label='氏名'
                        name={['infoPage', 'kana_name']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div>{this.state.infoPage.kana_name}</div>
                      </Form.Item>

                      <Form.Item
                        label='　'
                        name={['infoPage', 'kanji_name']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div>{this.state.infoPage.kanji_name}</div>
                      </Form.Item>

                      <Form.Item
                        label='生年月日'
                        name={['infoPage', 'birthday_on']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div>{this.state.infoPage.birthday_on}</div>
                      </Form.Item>
                    </div>

                    <div className='box_search_inner_border'>
                      <Form.Item
                        label='受診日時'
                        name={['infoPage', 'visit_date_on']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div>{moment(this.state.infoPage.visit_date_on).format('YYYY/MM/DD (ddd)')}</div>
                      </Form.Item>

                      <Form.Item
                        label='　'
                        name={['infoPage', 'period_time']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div>{this.state.infoPage.period_time}</div>
                      </Form.Item>

                      <div className='box_inner_horizontal'>
                        <Form.Item
                          label='コース'
                          name={['infoPage', 'visit_course']}
                          style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                          {...labelStyle}
                        >
                          <div>{this.state.infoPage.visit_course}</div>
                        </Form.Item>

                        <Form.Item
                          name={['infoPage', 'contract_short_name']}
                          style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                        >
                          <div>{this.state.infoPage.contract_short_name}</div>
                        </Form.Item>
                      </div>

                      <Form.Item
                        name={['infoPage', 'remarks']}
                        style={{ opacity: Expression_13 ? 1 : 0, marginBottom: 0 }}
                      >
                        <Input readOnly bordered={false} />
                      </Form.Item>

                      <Form.Item
                        name='orderStartTimeAt'
                        label='時間枠管理のみ'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'>
                        <Checkbox
                          onChange={() => {
                            this.getListData()
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_search_inner'>
                      <div className='box_inner_vertical'>
                        <Form.Item
                          label='状態'
                          name='TransmissionState'
                          style={{ marginBottom: 0 }}
                        >
                          <Radio.Group onChange={(e) => this.handleChange(e.target.value, 'TransmissionState')} >
                            <Radio value={''}>全て</Radio>
                            <Radio value={'0'}>未送</Radio>
                            <Radio value={'1'}>送信</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          label='種別'
                          name='OrderSpecies'
                          style={{ marginBottom: 0 }}
                        >
                          <Radio.Group
                            onChange={(e) => this.handleChange(e.target.value, 'OrderSpecies')}
                            style={{ width: 'fit-content' }}
                          >
                            <Radio value={''}>全て</Radio>
                            <Radio value={'00'}>予約</Radio>
                            <Radio value={'60'}>検査</Radio>
                            <Radio value={'70'}>画像</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className='box_search_btn'
                  style={{
                    display: (this.state.MiraisStartupCheck ? '' : 'none'),
                  }}
                >
                  <Tooltip title={this.state.MiraisStartupStatus}>
                    <div className='box_search_btn_inner'
                      style={{
                        fontWeight: 'bold',
                        color: (this.state.MiraisStartupStatus === '') ? 'black' : 'red',
                        textAlign: 'right',
                      }}
                    >
                      <div>{'※Mirais端末'}</div>
                      <div>{(this.state.MiraisStartupStatus === '') ? '正常' : 'エラー'}</div>
                    </div>
                  </Tooltip>
                </div>
              </div>

              <Table
                size='small'
                bordered
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 360 }}
                onRow={(record, index) => ({
                  onDoubleClick: () => {
                    if (this.state.initParams.Li_ReserveNum)
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1000,
                          component: (
                            <WS2738001_LaboratoryInspectConfirmSub
                              Li_ReserveNum={record?.reservation_number_medical_exam}
                              Li_TransmissionState={record?.transmission_state}
                              Li_ProcessDivision={record?.processing_division}
                              Li_Type={record?.kind}
                              Li_Identify={record?.identification}
                              onFinishScreen={(output) => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                  }
                })}
              >
                <Table.Column
                  title='済'
                  dataIndex='Submit'
                  width={40}
                  align='center'
                  render={(text, record) => {
                    let index = this.findIndexByID(this.state.dataSource, record.id)
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Checkbox disabled={!this.state.initParams.StsModify} checked={record.Submit}
                          onChange={(e) => this.handleChangeInput(record, e.target.checked ? 1 : 0, 'Submit')}
                        />
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='検査日'
                  dataIndex='DateDisplay'
                  className='column-size-date'
                  render={(text) => (<div>{moment(text).format('YYYY/MM/DD (ddd)')}</div>)}
                />

                <Table.Column
                  title='検査時間'
                  dataIndex='TimeDisplay'
                  className='column-size-8'
                />

                <Table.Column
                  title='種別'
                  dataIndex='TypeDisplay'
                  className='column-size-5'
                />

                <Table.Column
                  title='方法'
                  dataIndex='ProcessSegmentDisplay'
                  className='column-size-5'
                  render={(text, record) => {
                    let color = () => { }
                    switch (text) {
                      case '追加':
                        color = Color(211)
                        break
                      case '修正':
                        color = Color(208)
                        break
                      case '削除':
                        color = Color(209)
                        break
                      case '中止':
                        color = Color(209)
                        break
                      default:
                        color = Color(208)
                        break
                    }
                    return <p style={{ color: color.Foreground, marginBottom: 0, textAlign: 'center' }}>{text}</p>
                  }}
                />

                <Table.Column
                  title='項目'
                  dataIndex='InspectName'
                  className='column-size-30'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'left' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='送信状態'
                  dataIndex='miraisSendStatus'
                  className='column-size-6'
                  render={(text, record) => (
                    <Tooltip title={record.miraisSendStatusTooltip}>
                      <div style={{ textAlign: 'center' }}>{text}</div>
                    </Tooltip>
                  )}
                />

                <Table.Column
                  title='エラー内容'
                  dataIndex='error_text'
                  render={(text, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <div className='box_inner_horizontal'>
                          <Button
                            shape='circle'
                            size='small'
                            style={{ background: 'red', minWidth: 16, height: 16, fontSize: 5 }}
                            hidden={record.error_text === ''}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 600,
                                  component: (
                                    <WS2739001_ErrorConfirmSub
                                      Li_ReserveNum={record.reservation_number_medical_exam}
                                      Li_ProcessDivision={record.processing_division}
                                      Li_Type={record.kind}
                                      Li_Identify={record.identification}
                                      Li_transmission_state={record.transmission_state}
                                      onFinishScreen={(output) => {
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }} >
                            {' '}
                          </Button>
                          <div>{text}</div>
                        </div>
                      </div>
                    )
                  }}
                />

                <Table.Column
                  width={40}
                  align='center'
                  fixed='right'
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key="1"
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 800,
                                    component: (
                                      <WS2737500_MiraisSingleTransmissionEdit
                                        Li_dataSource={this.state.dataSource}
                                        Li_initParams={this.state.initParams}
                                        // Li_rowSelect={record}
                                        Li_record={record}
                                        onFinishScreen={(output) => {
                                          // let arrTemp = [...this.state.dataSource]
                                          // let index = arrTemp.indexOf(record)
                                          // this.setState({
                                          //   dataSource: arrTemp,
                                          // })

                                          // 送信データ再取得
                                          this.getListData()
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  }
                                })
                              }}
                            >
                              編集
                            </Menu.Item>
                            <Menu.Item key="2"
                              onClick={() => {
                                Modal.confirm({
                                  content: "削除を行いますか",
                                  onOk: () => {
                                    this.deleteData(record, index)
                                  }
                                })
                              }}
                            >
                              <div>削除</div>
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size="small" icon={<MoreOutlined />}></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

            <div className='box_button_bottom_right'>
              {/* 受診者ボタンは動作確認後に削除 */}
              <Button
                danger
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 1500,
                      component:
                        <WS0273001_VisitsTargetSearchQuery
                          LnkOutReserveNum={this.state.initParams.Li_ReserveNum}
                          Li_StateFlagSpecify={0}
                          Li_DateSpecify={this.state.infoPage?.visit_date_on}
                          Li_StsConditionSpecify={''}
                          Li_StsReceiptAmountDisplay={''}
                          onFinishScreen={({ LnkOutReserveNum }) => {
                            this.setState({
                              initParams: {
                                ...this.state.initParams,
                                Li_ReserveNum: LnkOutReserveNum
                              }
                            }, () => this.index(this.state.initParams))
                            this.closeModal()
                          }}
                        />,
                    },
                  })
                }}
              >
                受診者
              </Button>

              <Button
                disabled={!Expression_15}
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 'fit-content',
                      centered: true,
                      component:
                        <WS2537001_PersonalReserveProcess
                          Li_CourseLevel={this.state.initParams.Li_CourseLevel}
                          Li_ReserveNum={this.state.initParams.Li_ReserveNum}
                          Li_PersonalNum={''}
                          Li_Date={''}
                          Li_Getctxname={null}
                          Li_ProcessDivision={''}
                          Li_Option={''}
                          Li_Child={true}
                          onFinishScreen={() => {
                            this.index(this.state.initParams)
                          }}
                        />,
                    },
                  })
                }}
              >
                予約変更
              </Button>

              <Button
                disabled={!this.state.initParams.Li_ReserveNum}
                type='primary'
                icon={<SendOutlined />}
                onClick={() => {
                  this.preSendMirais()
                }}
              >
                <span className='btn_label'>
                  送信
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2737001_MiraisSingleTransmission)
