import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moment from 'moment-timezone'
import Color from 'constants/Color'
import { MoreOutlined, MenuOutlined, EditOutlined, QuestionCircleOutlined, RightOutlined, DoubleRightOutlined, LeftOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import ReserveAvailability from 'pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveAvailability'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import ParticularsScreenAction from 'redux/Others/ParticularsScreen/ParticularsScreen.action'
import {
  Card,
  Form,
  Input,
  Radio,
  Select,
  Checkbox,
  Button,
  Table,
  Dropdown,
  Menu,
  InputNumber,
  Modal,
  message,
  Row,
  Tooltip
} from 'antd'


const minWidth = '500px'

const labelCol = {
  labelCol: { style: { width: 75 } },
}

class ParticularsScreen extends React.Component {
  Li_ReserveDate
  static propTypes = {
    Li_ReserveDate: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '枠取予約'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      examItemList: [],
      courseItemList: [],
      dataSource: [],
      CourseDisplay: '',
      InspectDisplay: ''
    }
  }

  componentDidMount = () => {
    // 予約された事業所のリストを取る
    this.loadScreenData()
  }

  loadScreenData = () => {
    let params = {
      Li_Office: this.props.OfficeCode,
      reserve_nums: this.props.reserve_nums
    }
    ParticularsScreenAction.loadScreenData(params)
      .then((res) => {
        if (res.result.length !== 0) {
          let CourseDisplay = res.CourseDisplay.split('')
          let InspectDisplay = res.InspectDisplay.split('')
          let newObj1 = res.displayitemsettings1
          let Array1 = Object.values(newObj1)
          let newObj2 = res.displayitemsettings2
          let Array2 = Object.values(newObj2)
          this.setState({
            courseItemList: Array1 ?? [],
            CourseDisplay: CourseDisplay,
            InspectDisplay: InspectDisplay,
            examItemList: Array2 ?? [],
            dataSource: res.result
          })
        }
      })
  }

  // 削除
  delete(record) {
    let params = {
      course_level: record.course_level,
      reserve_num: record.reserve_num
    }
    ParticularsScreenAction.delete(params)
      .then((res) => {
        message.success('削除が完了しました。')
        if (this.state.dataSource.length > 1) {
          this.loadScreenData()
        } else {
          this.props.onFinishScreen()
        }
      })
  }

  // 予約時間変更
  changeTime(time, record) {
    let params = {
      course_level: record.course_level,
      reserve_num: record.reserve_num,
      time: time.timeZone
    }
    ParticularsScreenAction.timeChange(params)
      .then((res) => {
        message.success('時間の変更が完了しました。')
        this.loadScreenData()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className='frame-reserve'>
        <Card id="card" title='明細'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              id='customTable'
              size='small'
              className='custom-table-scroll'
              // dataSource={this.state.dataShowColumnExamineeList.ExamineeList}
              // dataSource={dataSource}
              dataSource={this.state.dataSource}
              pagination={false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(212) }}
              // rowClassName={(record, index) => record.id === this.state.rowSelectRight?.id ? 'table-row-light' : record.tentativeReserveFlag ? 'table-row-waku' : ''}
              onRow={(record) => {
                return {
                  onClick: async () => {
                    await this.setState({
                      rowSelectRight: record
                    })
                  },
                  onDoubleClick: () => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 'fit-content',
                        centered: true,
                        component: (
                          <ReserveAvailability
                            Li_Date={record.visit_date_on}
                            dataScreen={this.props.dataScreen}
                            onFinishScreen={(time) => {
                              this.closeModal()
                              this.changeTime(time, record)
                            }}
                          />
                        ),
                      },
                    })
                  }
                }
              }}
            // style={{ marginTop: '10px' }}
            >
              {/* <Table.Column
                className='column-size-2'
                // width={40}
                title='時間'
                dataIndex='period_time'
                sorter={(a, b) => {
                  let a1 = a.period_time.slice(0, 2) //上2桁
                  let a2 = a.period_time.slice(-2) //下2桁
                  let aSum = a1 + a2 //文字列として結合
                  let b1 = b.period_time.slice(0, 2)
                  let b2 = b.period_time.slice(-2)
                  let bSum = b1 + b2
                  return (aSum - bSum)
                }}
                render={(value) => {
                  return (<div >{value}</div>)
                }}
              /> */}
              <Table.Column
                className='column-size-2'
                title='ID'
                align='left'
                dataIndex='personal_number_id'
                render={(text, record) =>
                  <div style={{ textAlign: 'right' }}>{text}</div>
                }
              />
              <Table.Column
                className='column-size-40'
                title='氏名'
                dataIndex='KanjiName'
                align='left'
                sorter={(a, b) => a.KanjiName.localeCompare(b.KanjiName, 'ja')}
              />
              <Table.Column
                className='column-size-4'
                title='生年月日'
                dataIndex='DateOfBirth'
                render={(text) => <>{moment(text).format('NNy/MM/DD')}</>}
              />
              <Table.Column
                title='年齢'
                // className='column-size-5'
                // width={40} //ソートなし幅
                width={50} //ソートあり幅
                align='left'
                dataIndex='Age'
                sorter={(a, b) => a.Age - b.Age}
                render={(text, record) =>
                  <div style={{ textAlign: 'right' }}>{text}</div>
                }
              />
              <Table.Column
                className='column-size-2'
                // width={40}
                title='性'
                dataIndex='Gender'
                render={(text, record, index) => {
                  return (
                    <div
                      style={{
                        textAlign: 'center',
                        color: record.sexColorNum
                          ? Color(record.sexColorNum)?.Foreground
                          : '',
                      }}
                    >
                      {text}
                    </div>
                  )
                }}
              />
              <Table.Column
                className='column-size-2'
                title='メモ'
                dataIndex='memo'
                render={(text, record, index) => {
                  return (
                    <div
                      style={{
                        textAlign: 'center',
                        color: '#FF0000',
                      }}
                    >
                      {'〇'}
                    </div>
                  )
                }}
              />
              <Table.Column
                className='column-size-40'
                title='事業所名称'
                dataIndex='office_kanji_name'
                align='left'
              />
              <Table.Column
                title='主コース'
                className='column-size-40'
                align='left'
                dataIndex='contract_short_name'
                sorter={(a, b) => a.contract_short_name.localeCompare(b.contract_short_name, 'ja')}
              />
              <Table.Column
                title='オプション'
                className='column-size-40'
                dataIndex='options'
                render={(value, record, index) => {
                  return (
                    value ?
                      <Tooltip
                        title={() => {
                          return (
                            value.map((value, index) => (
                              <div key={index}>{value.set_name}</div>
                            )))
                        }}
                      >
                        <div style={{ textAlign: 'center' }}>{value.length > 0 ? '〇' : ''}</div>
                      </Tooltip>
                      : null
                  )
                }}
              />
              {
                this.state.courseItemList.map((item, index) => {
                  return (
                    this.state.CourseDisplay[index] == 1 ?
                      <Table.Column
                        key={index}
                        title={item}
                        width={45}
                        render={(text, record) => {
                          return (
                            <div style={{ textAlign: 'center' }}>{record.reservation_display_item_number == (index + 1) ? '〇' : '　'}</div>
                          )
                        }}
                      /> : null
                  )
                })
              }
              {
                this.state.examItemList.map((item, index) => {
                  return (
                    this.state.InspectDisplay[index] == 1 ?
                      <Table.Column
                        key={index}
                        title={item}
                        width={45}
                        render={(text, record) => {
                          const examStatus = record.exam_status.split(',')
                          const status = examStatus[index] ?? ''
                          let label = ''
                          if (status === '1') {
                            label = '〇'
                          } else if (status === '0') {
                            label = '　'
                          } else {
                            // MRIやCTなどの予約時間
                            label = status
                          }
                          return (
                            <div style={{ textAlign: 'center' }}>{label}</div>
                          )
                        }}
                      /> : null
                  )
                })
              }
              <Table.Column
                title='送付'
                // className='column-size-5'
                width={40}
                dataIndex='send'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value === 1 ? '〇' : ''}</div>
                  )
                }}
              />
              <Table.Column
                title='担当'
                className='column-size-40'
                dataIndex='user'
              />
              <Table.Column
                key='action'
                fixed='right'
                align='center'
                width={40}
                render={(text, record, index) =>
                  <Dropdown
                    trigger='click'
                    size='small'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='1'
                          onClick={() => {
                            this.delete(record)
                          }}
                        >
                          <label>削除</label>
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                }
              />
            </Table>
            {/* <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.props.onFinishScreen()
                }}
              >
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div> */}
          </Form >
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        </Card >
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticularsScreen)
