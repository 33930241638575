import React from "react";
import { connect } from "react-redux";
import { Card, Input, Form, Modal, Button, } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
const { TextArea } = Input;
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

class WS1871002_RadiographyInspectEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '読影検査保守 編集';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  // 検査コードのサーチが押された場合
  medicalExamCodeDoubleClick = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 700,
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            Li_SearchChar={this.formRef.current?.getFieldValue("medical_exam_exam_code")}
            onFinishScreen={({ recordData }) => {
              // 検査コード
              this.formRef.current.setFieldsValue({ medical_exam_exam_code: recordData.internal_exam_code, });

              // 読影略称
              let examShortName = this.formRef.current?.getFieldValue("interpretation_exam_short_name")
              if (examShortName == '') {
                this.formRef.current.setFieldsValue({ interpretation_exam_short_name: recordData.exam_short_name })
              }

              // 読影名称
              let examName = this.formRef.current?.getFieldValue("interpretation_exam_name")
              if (examName == '') {
                this.formRef.current.setFieldsValue({ interpretation_exam_name: recordData.exam_name })
              }

              this.closeModal();
            }}
          />
        ),
      }
    })
  }

  // 読影コードが記入された場合
  setFields = () => {
    let itemCode = this.formRef.current?.getFieldValue("interpretation_exam_item_code")

    // モダリティ
    let modality = this.formRef.current?.getFieldValue("modality_code")
    if (modality == '') {
      this.formRef.current.setFieldsValue({ modality_code: itemCode })
    }

    // マスタ分類
    let classification = this.formRef.current?.getFieldValue("exam_classification_code")
    if (classification == '') {
      this.formRef.current.setFieldsValue({ exam_classification_code: itemCode })
    }
  }

  // 保存ボタンが押された場合
  save = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      id: this.props.record.id ?? ''
    }

    this.props.onSave(values)
  }

  // 削除ボタンが押された場合
  delete = () => {
    let values = {
      id: this.props.record.id ?? ''
    }

    this.props.onDelete(values)
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="RadiographyInspectEdit">
        <Card
          title={'読影検査保守 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='読影コード'
                name="interpretation_exam_item_code"
                {...labelCol}
              >
                <Input
                  onBlur={(e) => {
                    this.setFields()
                  }}
                />
              </Form.Item>

              <Form.Item
                label='検査コード'
                name="medical_exam_exam_code"
                {...labelCol}
              >
                <Input.Search
                  onSearch={() => { this.medicalExamCodeDoubleClick("medical_exam_exam_code") }}
                />
              </Form.Item>

              <Form.Item
                label='読影略称'
                name="interpretation_exam_short_name"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='読影名称'
                name="interpretation_exam_name"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='モダリティ'
                name="modality_code"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='検査項目群'
                name="exam_item_group"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='マスタ分類'
                name="exam_classification_code"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='前回分類'
                name="group"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='医師'
                name="doctor_persons"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='技師'
                name="technician_persons"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='メモ'
                {...labelCol}
                name="options"
              >
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1871002_RadiographyInspectEdit);