import React, { createRef, Component } from 'react';
import { Card, Form, InputNumber, Popconfirm, Button, Input } from "antd";
import moment from 'moment';
import { SaveOutlined, DeleteOutlined, } from "@ant-design/icons";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import 'pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1400001_InspectItemMaster.scss';
import FacilityConsumptionTaxSettingAction from "redux/SystemMaintenance/FacilityConsumptionTaxSetting/FacilityConsumptionTaxSetting.action";

const dateFormat = "YYYY/MM/DD";
class AddApplyDate extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '郵便番号検索';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ""
      },
    };
  }

  addApplyDate = () => {
    let params = {
      data: { apply_date_on: moment(this.formRef.current.getFieldValue('apply_date_on').format(dateFormat)) }
    }
    FacilityConsumptionTaxSettingAction.postAddApplyDate(params)
      .then(res => {
        console.log('post:' + res.data.id)
        this.props.onFinishScreen(res.data.id)
      })
  }

  render() {
    return (
      <div className="tax-inquiry" >
        <Form ref={this.formRef}>
          <Card>
            <div className='box_inner_horizontal' style={{ marginTop: '40px', marginBottom: '10px', justifyContent: 'center' }}>
              <Form.Item name="apply_date_on" >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={"YYYY/MM/DD"}
                />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', paddingTop: '10px', gap: '10px', justifyContent: 'center' }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => {
                  // props.onFinishScreen()
                  this.addApplyDate()
                  // this.props.onFinishScreen()
                }}
              >追加
              </Button>
            </div>
          </Card>
        </Form>
      </div >
    )
  }
}
export default (AddApplyDate)