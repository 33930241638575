import axios from 'configs/axios'

const API_LIST = {
  dockTentativeReserve: '/api/tentative-reserve/dock-tentative-reserve',
  dockTentativeReserveCancel: '/api/tentative-reserve/dock-tentative-reserve-cancel',
  getTentativeReserve: "/api/tentative-reserve/determine-tentative-reserve/get-tentative-reserve",
  getPersonalInfo: "/api/tentative-reserve/determine-tentative-reserve/get-personal-info",
  getPeriodTime: "/api/tentative-reserve/determine-tentative-reserve/get-period-time",
  getAvailableTime: "/api/tentative-reserve/determine-tentative-reserve/get-available-time",
  determineProcess: "/api/tentative-reserve/determine-tentative-reserve/determine-process",
  outputExcel: '/api/tentative-reserve/determine-tentative-reserve/output-excel',
  downloadExcelFile: '/api/tentative-reserve/determine-tentative-reserve/download-excel-file',
}

const TentativeReserveService = {
  async dockTentativeReserve(params) {
    return axios.post(API_LIST.dockTentativeReserve, params)
  },
  async dockTentativeReserveCancel(params) {
    return axios.post(API_LIST.dockTentativeReserveCancel, params)
  },
  async getTentativeReserve(params) {
    return axios.post(API_LIST.getTentativeReserve, params)
  },
  async getPersonalInfo(params) {
    return axios.get(API_LIST.getPersonalInfo, { params })
  },
  async getPeriodTime(params) {
    return axios.get(API_LIST.getPeriodTime, { params })
  },
  async getAvailableTime(params) {
    return axios.get(API_LIST.getAvailableTime, { params })
  },
  async determineProcess(params) {
    return axios.post(API_LIST.determineProcess, params)
  },
  async outputExcel(params) {
    return axios.post(API_LIST.outputExcel, params);
  },
  async downloadExcelFile(params) {
    return axios.post(API_LIST.downloadExcelFile, params, { responseType: 'arraybuffer' });
  },
}
export default TentativeReserveService
