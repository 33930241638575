import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from 'moment';
import { Card, Form, Input, Button, Modal } from "antd";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import CancerScreeningCSVOutputAction from "redux/Others/CancerScreeningCSVOutput/CancerScreeningCSVOutput.action.js"
import WS0449001_UserParamMaintain from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449001_UserParamMaintain.jsx"
import { download_file } from 'helpers/CommonHelpers'

class CancerScreeningCSVOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '大分県庁がん健診提出';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        startDateCharacter: '',
        endDateCharacter: '',
        resultFileName: '',
        format: '',
        course: '',
        inspectionCode_doctorCode: '',
        examiningAgencyCode: '',
        personalSupplement_affiliationCode: '',
        personalSupplement_employeeNumber: '',
        personalSupplement_office: '',
        judgmentConversion: '',
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidMount = () => {
    CancerScreeningCSVOutputAction.getScreenData()
      .then(res => {
        this.formRef.current?.setFieldsValue({
          DateFChar: res.startDateCharacter,
          DateTChar: res.endDateCharacter,
          resultFileName: res.resultFileName
        })
        this.setState({
          startDateCharacter: res.startDateCharacter,
          endDateCharacter: res.endDateCharacter,
          resultFileName: res.resultFileName,
          format: res.format,
          course: res.course,
          inspectionCode_doctorCode: res.inspectionCode_doctorCode,
          examiningAgencyCode: res.examiningAgencyCode,
          personalSupplement_affiliationCode: res.personalSupplement_affiliationCode,
          personalSupplement_employeeNumber: res.personalSupplement_employeeNumber,
          personalSupplement_office: res.personalSupplement_office,
          judgmentConversion: res.judgmentConversion,
          startDate: res.startDate,
          endDate: res.endDate
        })
      })
  }

  outputCSV = () => {
    if (moment(this.formRef.current?.getFieldValue('DateFChar')).format('YYYY/MM/DD') > moment(this.formRef.current?.getFieldValue('DateTChar')).format('YYYY/MM/DD')) {
      Modal.error({
        content: '不正な日付です'
      })
    } else {
      let params = {
        startDateCharacter: moment(this.formRef.current?.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
        endDateCharacter: moment(this.formRef.current?.getFieldValue('DateTChar')).format('YYYY/MM/DD'),
        resultFileName: this.formRef.current?.getFieldValue('resultFileName'),
        format: this.state.format,
        course: this.state.course,
        inspectionCode_doctorCode: this.state.inspectionCode_doctorCode,
        examiningAgencyCode: this.state.examiningAgencyCode,
        personalSupplement_affiliationCode: this.state.personalSupplement_affiliationCode,
        personalSupplement_employeeNumber: this.state.personalSupplement_employeeNumber,
        personalSupplement_office: this.state.personalSupplement_office,
        judgmentConversion: this.state.judgmentConversion,
        startDate: moment(this.formRef.current?.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
        endDate: moment(this.formRef.current?.getFieldValue('DateTChar')).format('YYYY/MM/DD'),
      }
      CancerScreeningCSVOutputAction.outputCSV(params)
        .then(res => { download_file(res) })
    }
  }

  openUserParamMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS0449001_UserParamMaintain
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  render = () => {
    return (
      <div className="cancer-screening-csv-output" style={{ width: 800 }}>
        <Card title='大分県庁がん健診提出'>
          <Form
            ref={this.formRef}
          >
            <div className="box_inner_horizontal">
              <Form.Item name="DateFChar" label="受　診　日">
                <VenusDatePickerCustom width={150} formRefDatePicker={this.formRef} format='YYYY/MM/DD' />
              </Form.Item>
              <Form.Item label='~' name="DateTChar">
                <VenusDatePickerCustom width={150} formRefDatePicker={this.formRef} format='YYYY/MM/DD' />
              </Form.Item>
            </div>
            <Form.Item label='ファイル名' name='resultFileName'>
              <Input />
            </Form.Item>
            <div className="box_button_bottom_right">
              <Button
                onClick={() => this.openUserParamMaintain()}
              >
                <span className="btn_label">
                  設定
                </span>
              </Button>
              <Button
                type='primary'
                onClick={() => this.outputCSV()}
              >
                <span className="btn_label">
                  実行
                </span>
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CancerScreeningCSVOutput);
