import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Table, Button, Form, Dropdown, Menu, } from "antd";
import { PlusOutlined, MoreOutlined } from "@ant-design/icons";
import WS1875002_RadiographyGuideMaintainEdit from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1875002_RadiographyGuideMaintainEdit.jsx'
import RadiographyGuideMaintainAction from 'redux/InspectionMaintenance/RadiographyInspectMaintain/RadiographyGuideMaintain.action.js'
class WS1875001_RadiographyGuideMaintain extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '読影指導保守';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      tableData: [],
      selectedRows: [],
      selectedRowKeys: [],

    };
  }

  componentDidMount() {
    this.getInitialValuse();
  }

  getInitialValuse = () => {
    let params = {
      Li_InspectClassifyCode: this.props.Li_InspectClassifyCode,
    }

    RadiographyGuideMaintainAction.getScreenData(params)
      .then(res => {
        this.setState({
          tableData: res
        })
      })
  }

  // 新規登録・編集
  editData = (newFlag, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 700,
        component: (
          <WS1875002_RadiographyGuideMaintainEdit
            newFlag={newFlag}
            record={record}
            exam_classification_code={this.props.Li_InspectClassifyCode}
            onSave={(output) => {
              RadiographyGuideMaintainAction.save(output)
                .then(res => {
                  this.closeModal()
                  this.getInitialValuse()
                })
            }}

            onDelete={(output) => {
              RadiographyGuideMaintainAction.delete(output)
                .then(res => {
                  this.closeModal()
                  this.getInitialValuse()
                })
            }}
          />
        )
      }
    })
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="radiography-guide-maintain">
        <Card
          title='指導'
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              dataSource={this.state.tableData}
              size='small'
              scroll={{ x: 'max-content', y: 650 }}
              rowKey={(record) => record.id}
              pagination={false}
            >
              <Table.Column
                className="column-size-10"
                title="指導コード"
                dataIndex="comment_code"
                render={(text, record, index) => (
                  <div style={{ textAlign: 'right' }}> {text} </div>
                )}
              />

              <Table.Column
                title="指導内容"
                dataIndex="comment_content"
              />


              <Table.Column
                align='center'
                width={40}
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    style={{ textAlign: 'center' }}
                    disabled={this.state.isAddRow}
                    onClick={() => {
                      this.editData(true, false)
                    }}
                  />
                }

                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='変更'
                            onClick={() => {
                              this.editData(false, record)
                            }}
                          >変更
                          </Menu.Item>

                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        style={{ width: '100%' }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>


        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1875001_RadiographyGuideMaintain);
