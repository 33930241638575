import axios from "configs/axios";

const APP_LIST = {
  GetScreenData: "/api/ask-issued/ask-issued/get-screen-data",
  Print_F12: "/api/ask-issued/ask-issued/print-btn",
  preview: "/api/ask-issued/ask-issued/preview",
};

const AskIssuedService = {
  async GetScreenData(params) {
    return axios.get(APP_LIST.GetScreenData, { params });
  },
  async Print_F12(params) {
    return axios.post(APP_LIST.Print_F12, params);
  },
  async preview(params) {
    return axios.post(APP_LIST.preview, params, { responseType: 'blob' })
  }
};

export default AskIssuedService;