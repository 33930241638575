import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Card, Table, Button } from "antd";
import UserParamQueryAction from "redux/ResultOutput/CsvCreateParamMaintain/UserParamQuery.action";

class WS0451001_UserParamQuery extends React.Component {

  static propTypes = {
    Lio_Format: PropTypes.any,
    Li_Displayed: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'ユーザーパラメータ照会';

    this.state = {
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      dataSource: [],
      isLoadingTable: false,
      selectedRowKeys: '',
      rowSelected: {},
    };
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.getData()
    }
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  getData() {
    let params = {
      Lo_Format: this.isEmpty(this.props.Lio_Format) ? '' : this.props.Lio_Format
    }
    this.setState({ isLoadingTable: true })
    UserParamQueryAction.getListData(params)
      .then((res) => {
        this.setState({
          dataSource: res ? res.Result : [],
          isLoadingTable: false
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  select = () => {
    this.props.onFinishScreen(this.state.rowSelected)
  }

  render() {
    return (
      <div className="user-param-query">
        <Card title="ユーザーパラメータ照会">
          <Table
            size='small'
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            bordered
            rowKey={(record) => record.id}
            scroll={{ x: 'max-content', y: 500 }}
            rowClassName={(record, index) => record.id === this.state.rowSelected?.id ? 'table-row-light' : ''}
            onRow={(record, rowIndex) => {
              return {
                onClick: async () => {
                  this.setState({
                    rowSelected: record,
                    selectedRowKeys: record.id
                  })
                },
                onDoubleClick: () => {
                  this.select()
                }
              }
            }}

          >
            <Table.Column title="フォーマット" dataIndex="format" width={150} />
            <Table.Column title="名称" dataIndex="remarks" />
          </Table>
          <div className="box_button_bottom_right">
            <Button
              type='primary'
              onClick={() => this.select()}
            >
              <span className="btn_label">
                選択
              </span>
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0451001_UserParamQuery);
