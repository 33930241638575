import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Popconfirm } from "antd";
import { DeleteOutlined, } from "@ant-design/icons";

const { TextArea } = Input

class WS3061500_PassingControlInspectsDisplayExclusion extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource1: [],
      dataSource2: [],
      selectedNodes: [],
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
    };
  }

  componentDidMount = () => {
    if (this.props.acceptingState == 9) {
      if (this.props.flag == 4) {
        // 測定不可
        this.formRef.current?.setFieldsValue({
          comment: this.props.comment
        })
      }
    } else if (this.props.acceptingState == '') {
      if (this.props.flag == 5) {
        // 除外
        this.formRef.current?.setFieldsValue({
          comment: this.props.comment
        })
      }
    }
  }


  render() {
    return (
      <div className="reserves-displayitem-setting">
        <Card
          title={this.props.flag == 4 ? "測定不可" : "除外"}
          className="mb-2"
        >
          <Form ref={this.formRef} >
            <div className="box_inner_vertical">
              {this.props.flag == 4 ?
                <div>この検査を測定不可にする理由を記載してください</div>
                :
                <div>この検査を除外にする理由を記載してください</div>
              }

              <Form.Item
                label='理由'
                name='comment'
              >
                <TextArea
                  style={{ height: 64 }}
                />
              </Form.Item>

              <div className="box_button_bottom_right"
                style={{ marginTop: 0 }}>
                {this.props.comment !== '' ?
                  < Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    okText='削除'
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    onConfirm={() =>
                      // 削除
                      this.props.onFinishScreen()
                    }
                    cancelText='キャンセル'
                    cancelButtonProps={{ style: { height: '32px' } }}
                  >
                    <Button
                      danger
                      type='primary'
                      icon={<DeleteOutlined />}
                    >
                      <span className='btn_label'>
                        削除
                      </span>
                    </Button>
                  </Popconfirm>
                  :
                  null
                }
                <Button
                  type="primary"
                  onClick={() => { this.props.onFinishScreen(this.formRef.current?.getFieldValue()) }}
                >
                  {this.props.flag == 4 ?
                    <span className='btn_label'>
                      測定不可
                    </span>
                    :
                    <span className='btn_label'>
                      除外
                    </span>
                  }
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }


}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3061500_PassingControlInspectsDisplayExclusion);
