import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Select, Checkbox, Tooltip, Input, Modal } from 'antd'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import WS2788013_TargetSelectSub from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS2788013_TargetSelectSub.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import AcceptanceBatchProcessAction from 'redux/CounterBusiness/AcceptanceBatchProcess/AcceptanceBatchProcess.actions'
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moment from 'moment'

const smGrid = {
  labelCol: { style: { width: 55 } },
}

// 日付型
const dateFormat = 'YYYY/MM/DD'
class WS0699001_AcceptanceBatchProcess extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '受付一括処理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      initObj: {},

      selectedData: [],
      disabledBtn: true,
      message: '', // WS2788013_TargetSelectSub
      isSearch: false, // WS2788013_TargetSelectSub
      visitData: [], // WS2788013_TargetSelectSub

      KeyInfo: '',

      conditionAddData: null,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    AcceptanceBatchProcessAction.index()
      .then(res => {
        if (res) {
          this.setState({
            initObj: res,
            KeyInfo: res.KeyInfo
          })
        }
      })
  }

  /**
   * 検索条件に該当する受診者一覧を取得
   * @param {*} params
   */
  getVisitList = () => {

    const date = this.formRef.current.getFieldValue('DateChar')
    const DateFChar = this.state.conditionAddData == null ? '' : this.state.conditionAddData.DateFChar
    const DateTChar = this.state.conditionAddData == null ? '' : this.state.conditionAddData.DateTChar
    const params = {
      DateFChar: date ? date.format(dateFormat) : DateFChar,
      DateTChar: date ? date.format(dateFormat) : DateTChar,
      StateFlag: this.formRef.current.getFieldValue('StateFlag'),
      KeyInfo: this.state.KeyInfo ?? '',
      StartNumOp: this.formRef.current.getFieldValue('StartNumOp'),
      SkyNumsUsePresenceOp: this.formRef.current.getFieldValue('SkyNumsUsePresenceOp'),
      conditionAddData: this.state.conditionAddData,
    }

    AcceptanceBatchProcessAction.getVisitList(params)
      .then(res => {
        if (res) {
          this.setState({
            message: res.message,
            isSearch: true,
            visitData: res.visitData,
          })
        }
      })
  }

  /**
   * 「受付」または「取消」の実行
   */
  setAcceptOrCancel = () => {
    const params = {
      StateFlag: this.formRef.current.getFieldValue('StateFlag'),
      StartNumOp: this.formRef.current.getFieldValue('StartNumOp'),
      SkyNumsUsePresenceOp: this.formRef.current.getFieldValue('SkyNumsUsePresenceOp'),
      selectedData: this.state.selectedData
    }

    AcceptanceBatchProcessAction.setAcceptOrCancel(params)
      .then(async (res) => {
        if (res) {
          // データ再取得
          await this.getVisitList()
          // メッセージ表示
          await Modal.success({
            okText: 'OK',
            title: res?.Warning
          })
        }
      })
  }

  /**
   * 選択されているデータを設定
   * @param {*} arr
   */
  onSelectedData = (arr) => {
    console.log(arr);
    let bool = true
    if (arr.length > 0) {
      bool = false
    }
    // 配列とボタンの活性
    this.setState({
      selectedData: arr,
      disabledBtn: bool
    })
  }

  /**
   * 条件追加　モーダル
   */
  show_WS2786001_ConditionAddSub = () => {
    const date = this.formRef.current.getFieldValue('DateChar') ? moment(this.formRef.current.getFieldValue('DateChar')).format(dateFormat) : null
    // 処理と受診日の情報を渡す
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component:
          <WS2786001_ConditionAddSub
            Li_DateF={date}
            Li_DateT={date}
            Li_State={this.formRef.current.getFieldValue('StateFlag')}
            Li_ConditionAddData={this.state.conditionAddData}
            onFinishScreen={(output) => {
              // 追加条件のデータをstate変数に保持
              this.setState({
                conditionAddData: output.conditionAddData,
                KeyInfo: output.Lio_KeyInfo ? output.Lio_KeyInfo : '',
              })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='acceptance-batch-process'>
        <Card
          title='受付一括処理'
        >
          <Form
            ref={this.formRef}
            initialValues={{
              StateFlag: 0,
              DateChar: moment(new Date().toISOString()),
              SkyNumsUsePresenceOp: 1
            }}
          >
            <div className='box_container'>

              <div className='box_inner_horizontal'>
                <div className='box_search' style={{ width: 250 }}>
                  <div className='box_search_inner'>
                    <Form.Item
                      name='StateFlag'
                      label='処理'
                      {...smGrid}
                    >
                      <Select
                        allowClear={false}
                        style={{ width: 128 }}
                      >
                        <Select.Option value={0}>受付</Select.Option>
                        <Select.Option value={1}>取消</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='DateChar'
                      label='受診日'
                      {...smGrid}
                      style={{ marginBottom: 0 }}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>
                  </div>

                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      onClick={() =>
                        // データ取得
                        this.getVisitList()
                      }
                      icon={<SearchOutlined />}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Tooltip title='詳細な条件追加'>
                        <Button
                          icon={<PlusOutlined />}
                          size='small'
                          type='primary'
                          style={{ borderRadius: '12px' }}
                          className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                          onClick={() => {
                            // 条件追加モーダル
                            this.show_WS2786001_ConditionAddSub()
                          }} />
                      </Tooltip>
                    </Form.Item>
                  </div>
                </div>
              </div>

              {/* 対象受診者テーブル */}
              <WS2788013_TargetSelectSub
                isSearch={this.state.isSearch}
                message={this.state.message}
                visitData={this.state.visitData}
                KeyInfo={this.state.KeyInfo}
                onSelectedData={(output) => {
                  // 対象者の選択が変更になった時
                  this.onSelectedData(output)
                }}
              />

            </div>

            <div className='box_button_bottom_right'>
              {/* 受付Noの開始番号 */}
              <Form.Item
                name='StartNumOp'
                label='開始番号'
                style={{ marginBottom: 0 }}
              >
                <Input
                  className='input-size-number-10'
                  min={1}
                  maxLength={6}
                  disabled={this.state.disabledBtn}
                />
              </Form.Item>
              <Form.Item
                name='SkyNumsUsePresenceOp'
                label='空き発番'
                valuePropName='checked'
                style={{ marginBottom: 0, marginRight: 10 }}
              >
                <Checkbox disabled={this.state.disabledBtn} />
              </Form.Item>

              <Button
                type='primary'
                icon={<ReloadOutlined />}
                onClick={() => {
                  let StateFlag = (this.formRef.current.getFieldValue('StateFlag') === 0) ? '受付' : '取消'
                  let content = '一括で' + StateFlag + '処理を実行しますか？'
                  ModalConfirm({
                    title: content,
                    okText: '実行',
                    cancelText: 'キャンセル',
                    onOk: () => {
                      // 「受付」または「取消」の実行
                      this.setAcceptOrCancel()
                    },
                  })
                }}
                disabled={this.state.disabledBtn}
              >
                <span className='btn_label'>
                  実行
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0699001_AcceptanceBatchProcess)
