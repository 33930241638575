import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Select, Input, Modal } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WeekAndCourseSettingAction from 'redux/Others/MedicalCheckupCalendar/WeekAndCourseSetting.action.js'
import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx'

const smGrid = {
  labelCol: { style: { width: 80 } }
}

class WeekAndCourseSettingEdit extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      weekList: []
    }
  }

  /**
  * 初期表示処理
  */
  componentDidMount() {
    this.formRef.current.setFieldsValue({
      YearsChar: this.props.YearsChar
      // YearsChar: moment(new Date())?.format('YYYY/MM/DD')
    })

    if (this.props.record) {
      // 変更の場合
      this.formRef.current.setFieldsValue(this.props.record);
    }

    this.forceUpdate()
  }

  /**
  * 保存ボタン処理
  */
  save = () => {
    let data = this.formRef.current?.getFieldValue()
    let params = []

    if (this.props.newFlag) {
      params = {
        ...data,
        newFlag: this.props.newFlag
      }
    } else {
      params = {
        ...data,
        id: this.props.record.id,
        newFlag: this.props.newFlag
      }
    }

    WeekAndCourseSettingAction.saveData(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
  * 削除ボタン処理
  */
  delete = () => {
    let params = {
      id: this.props.record.id
    }

    WeekAndCourseSettingAction.delete(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
  * 契約情報照会 モーダル
  * @param {*} save
  */
  showWS0289012_ContractInfoInquiry() {
    this.setState({

      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0289012_ContractInfoInquiry
            Lio_ConsultCourse={this.formRef.current?.getFieldValue('medical_exam_course')}
            Li_Date={moment(this.formRef.current?.getFieldValue('YearsChar')).format('YYYY/MM/DD')}
            Lio_ContractStartDate={moment(this.formRef.current?.getFieldValue('YearsChar')).format('YYYY/MM/DD')}

            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                course_name: output.recordData.contract_short_name,
                contract_type: output.recordData.Lo_ContractType,
                contract_office_code: output.recordData.Lo_ContractOrgCode,
                contract_number: output.recordData.Lo_ContractNum,
                medical_exam_course: output.recordData.Lio_ConsultCourse,
              })

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="reserves-displayitem-setting-edit">
        <Card title={"曜日・コース設定 [" + (this.props.newFlag ? '新規' : '変更') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              label='曜日'
              name='week'
              {...smGrid}
            >
              <Select
                style={{ width: 150 }}
              >
                <Select.Option key={0} value={0}> {'日曜日'}</Select.Option>
                <Select.Option key={1} value={1}> {'月曜日'}</Select.Option>
                <Select.Option key={2} value={2}> {'火曜日'}</Select.Option>
                <Select.Option key={3} value={3}> {'水曜日'}</Select.Option>
                <Select.Option key={4} value={4}> {'木曜日'}</Select.Option>
                <Select.Option key={5} value={5}> {'金曜日'}</Select.Option>
                <Select.Option key={6} value={6}> {'土曜日'}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label='偶数・奇数'
              name='odd_even'
              {...smGrid}
            >
              <Select
                style={{ width: 150 }}
              >
                <Select.Option key={0} value={0}> {''}</Select.Option>
                <Select.Option key={1} value={1}> {'奇数'}</Select.Option>
                <Select.Option key={2} value={2}> {'偶数'}</Select.Option>
              </Select>
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='コース'
                name='medical_exam_course'
                {...smGrid}
              >
                <Input.Search
                  className="input-search-size-number-7 "

                  onPressEnter={() => {
                    this.showWS0289012_ContractInfoInquiry()
                  }}

                  onSearch={() => {
                    this.showWS0289012_ContractInfoInquiry()
                  }}
                />
              </Form.Item>
              <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('course_name') ?? ''}</div>
            </div>

            <Form.Item
              label='人数'
              name='people'
              {...smGrid}
            >
              <Input
                className="input-size-number-7"
              />
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              hidden={!(this.props.record)}
              onClick={() => {
                Modal.confirm({
                  content: '削除しますか？',
                  okText: <span className='btn_label'>{'削除'}</span>,
                  okButtonProps: {
                    icon: <DeleteOutlined />,
                    type: 'primary',
                    danger: true,
                  },
                  cancelText: 'キャンセル',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WeekAndCourseSettingEdit)
