import { message } from "antd";
import ImplementorMasterService from "services/SpecificInsureMaintenance/ImplementorMaster/ImplementorMasterService";

const ImplementorMasterAction = {
  GetTreeData(params) {
    return ImplementorMasterService.getTreeData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteMasterInfo(params) {
    return ImplementorMasterService.deleteMasterInfo(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  AddNewMaster(params) {
    return ImplementorMasterService.AddNewMaster(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  postMaster(params) {
    return ImplementorMasterService.postMaster(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

}

export default ImplementorMasterAction;