import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import PropTypes from 'prop-types';

import {
  Card, Form, Col, Row, Input, Checkbox, Select, Button, Modal, DatePicker, message, Spin
} from "antd";
import WS0246001_InsurerInfoSearchQuery from "../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "../V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0265001_BasicCourseInquiry from "../V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS0431001_CsvPreviewConfirm from "pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm";
import MasterListOutputContractInfoAction from 'redux/basicInfo/ContractInfoMaintain/MasterListOutputContractInfo.action'
import ModalDraggable from "components/Commons/ModalDraggable";
import axios from "axios";
import { download_file } from "helpers/CommonHelpers";

class WS0426001_MasterListOutputContractInfo extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    Li_StsContractType: PropTypes.any,
    Li_StsContractOrgCode: PropTypes.any,
    Li_StsContractStartDate: PropTypes.any,
    Li_StsContractNum: PropTypes.any,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "マスタ一覧出力";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoading: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    this.formRef.current.resetFields();
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.formRef.current.resetFields();
      this.forceUpdate();
    }
  }

  hideChildModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  downloadFile = (output) => {
    this.setState({ isLoading: true })
    let OutputMethod = output.Lio_OutputMethod
    let Output = output.Lio_Output + '.csv'
    let Preview = output.Lio_Preview
    let params = {
      ...this.formRef.current.getFieldValue(),
      OutputMethod,
      Output,
      Preview,
    }
    MasterListOutputContractInfoAction.OutputF12(params)
      .then(res => {
        console.log(res);
        download_file(res, Preview);
      })
      .catch(error => {
        const res = error.response;
        if (res && res.status === 404) {
          // 印刷対象がなかった場合
          message.error('対象データがありません');
          return;
        } else if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      }).finally(() => {
        this.setState({ isLoading: false });
      });
  }

  onFinish(values) {
    if (!(values.TermsAndCondition || values.ContractSet || values.ContractInspect)) {
      message.error('出力様式を選択してください');
      return;
    }

    let OutputMethod = '';
    let Output = '';
    let Preview = false;

    this.setState({
      childModal: {
        ...this.state.childModal,
        width: 500,
        visible: true,
        component: (<WS0431001_CsvPreviewConfirm
          onFinishScreen={(output) => {
            OutputMethod = output.Lio_OutputMethod;
            Output = output.Lio_Output;
            Preview = output.Lio_Preview;

            if (OutputMethod !== '') {
              this.hideChildModal();
              this.downloadFile(output)
            }
          }}
        />),
      }
    });
  }

  render() {
    return (
      <div className="master-list-output-contract-info">
        <Card title="マスタ一覧出力">
          <Spin spinning={this.state.isLoading}>
            <Form ref={this.formRef} onFinish={this.onFinish}
              initialValues={{
                ContractType: this.props.Li_ContractType || 0,
                ContractOrgCode: this.props.Li_ContractOrgCode || 0,
                ContractStartDate: this.props.Li_ContractStartDate,
                ContractNum: this.props.Li_ContractNum,
                Expression_22: this.props.Li_ContractName,

                StsContractType: this.props.Li_StsContractType,
                StsContractOrgCode: this.props.Li_StsContractOrgCode,
                StsContractStartDate: this.props.Li_StsContractStartDate,
                StsContractNum: this.props.Li_StsContractNum,

                StsLainspect: !this.props.Li_StsContractStartDate,
              }}>
              <div className="box_inner_vertical">
                <div className="div_label" style={{ fontSize: '15px' }}>契約抽出</div>
                <div className="box_border">
                  <div className="box_inner_horizontal">
                    <Form.Item name="StsContractType" valuePropName="checked">
                      <Checkbox onChange={() => this.forceUpdate()}>　種　別</Checkbox>
                    </Form.Item>
                    <Form.Item name="ContractType">
                      <Select disabled={!this.formRef.current?.getFieldValue('StsContractType')} onChange={(value) => {
                        if (value === 0) {
                          this.formRef.current.resetFields(['ContractOrgCode']);
                        } else {
                          this.formRef.current.setFieldsValue({
                            ContractOrgCode: '',
                          });
                        }
                        this.forceUpdate();
                      }}>
                        <Select.Option value={0}>共　通</Select.Option>
                        <Select.Option value={1}>保険者</Select.Option>
                        <Select.Option value={2}>事業所</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="box_inner_horizontal">
                    <Form.Item name="StsContractOrgCode" valuePropName="checked">
                      <Checkbox onChange={() => this.forceUpdate()}>　団　体</Checkbox>
                    </Form.Item>
                    <Form.Item name="ContractOrgCode">
                      <Input.Search
                        disabled={!(this.formRef.current?.getFieldValue('StsContractOrgCode') && (this.formRef.current?.getFieldValue('ContractType') > 0))}
                        onSearch={() => {
                          const ContractType = this.formRef.current.getFieldValue('ContractType');
                          let component = null;
                          if (ContractType === 1) {
                            component = (<WS0246001_InsurerInfoSearchQuery
                              onFinishScreen={(output) => {
                                this.formRef.current.setFieldsValue({
                                  ContractOrgCode: output.Lo_InsurerCode,
                                  Expression_22: output.recordData.insurer_kanji_name,
                                });

                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: false,
                                  }
                                });
                              }}
                            />);
                          } else if (ContractType === 2) {
                            component = (<WS0247001_OfficeInfoRetrievalQuery
                              onFinishScreen={(output) => {
                                this.formRef.current.setFieldsValue({
                                  ContractOrgCode: output.Lio_OfficeCode,
                                  Expression_22: output.recordData.office_kanji_name,
                                });

                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: false,
                                  }
                                });
                              }}
                            />);
                          }

                          if (component) {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                width: 800,
                                visible: true,
                                component: component,
                              }
                            });
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          width: '180px',
                          overflow: 'hidden',
                          marginTop: '5px'
                        }}
                      >{(() => {
                        const CType = this.formRef.current?.getFieldValue('ContractType');
                        switch (CType) {
                          case 1:
                            break;
                          case 2:
                            break;
                          default: return '共通';
                        }
                        return this.formRef.current?.getFieldValue('ContractOrgCode') ? this.formRef.current?.getFieldValue('Expression_22') : false;
                      })()}</div>
                    </Form.Item>
                  </div>
                  <div className="box_inner_horizontal">
                    <Form.Item name="StsContractStartDate" valuePropName="checked">
                      <Checkbox onChange={(checked) => {
                        if (checked) {
                          this.formRef.current.setFieldsValue({
                            StsLainspect: false,
                          });
                        }
                        this.forceUpdate();
                      }}>　年　度</Checkbox>
                    </Form.Item>
                    <Form.Item name="ContractStartDateChar" >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef}
                        disabled={!this.formRef.current?.getFieldValue('StsContractStartDate')}
                        format="YYYY/MM/DD"
                      />
                    </Form.Item>
                    <Form.Item name="StsLainspect" valuePropName="checked">
                      <Checkbox onChange={(checked) => {
                        if (checked) {
                          this.formRef.current.setFieldsValue({
                            StsContractStartDate: false,
                          });
                        }
                        this.forceUpdate();
                      }}>最新</Checkbox>
                    </Form.Item>
                  </div>
                  <div className="box_inner_horizontal">
                    <Form.Item name="StsContractNum" valuePropName="checked" style={{ marginBottom: 0 }}>
                      <Checkbox onChange={() => this.forceUpdate()}>　コース</Checkbox>
                    </Form.Item>
                    {/* <div className="box_inner_vertical"> */}
                    <Form.Item name="ContractNum" style={{ marginBottom: 0 }}>
                      <Input
                        className="input-size-10"
                        disabled={!this.formRef.current?.getFieldValue('StsContractNum')}
                      />
                    </Form.Item>
                    <Form.Item name="Course" style={{ marginBottom: 0 }}>
                      <Input.Search
                        className="input-search-size-4"
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              width: 800,
                              visible: true,
                              component: (<WS0265001_BasicCourseInquiry
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    Course: output.Lo_CourseCode,
                                  });

                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    }
                                  });
                                }}
                              />),
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    {/* </div> */}
                  </div>
                </div>

                <div className="div_label" style={{ fontSize: '15px', marginTop: '10px' }}>出力様式</div>
                <div className="box_border">
                  <Form.Item name="TermsAndCondition" valuePropName="checked">
                    <Checkbox>契約の一覧を出力します</Checkbox>
                  </Form.Item>
                  <Form.Item name="ContractSet" valuePropName="checked">
                    <Checkbox>契約で使用しているセットの一覧を出力します</Checkbox>
                  </Form.Item>
                  <Form.Item name="ContractInspect" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Checkbox>契約で使用している検査の一覧を出力します</Checkbox>
                  </Form.Item>
                </div>
              </div>

              <Button type="primary" htmlType="submit" style={{ float: "right", margin: "10px 0 0 0" }}>
                <span className='btn_label'>
                  出力
                </span>
              </Button>
            </Form>
          </Spin>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS0426001_MasterListOutputContractInfo;
