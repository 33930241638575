import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/scss/_frame_taking_status_calendar.scss';
import PropTypes from 'prop-types';
import { Card, Form, Select, Button, Menu, Dropdown, Input, Table, message, Modal } from "antd";
import { ReloadOutlined, MoreOutlined, } from '@ant-design/icons';
import moment from "moment-timezone";
import AddDate from 'pages/ZZ_Others/SANAI_FrameReserve/AddDate'
import ModalDraggable from "components/Commons/ModalDraggable";
import ReservationStatusAction from 'redux/Others/ReservationStatus/ReservationStatus.action'
import NumberFormat from 'components/Commons/NumberFormat'



class ReservationStatus extends React.Component {
  static propTypes = {
    // propsの型を調べる
    Lio_Date: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'カレンダー';

    //const Lio_Date = moment(this.props.Lio_Date).format('MM');

    this.state = {
      loading: false,

      screenData: {},
      formDefault: {},
      AvailabilityList: '',
      dataSource: [],

      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: "",
      },

      selectedMonth: null,
      selectedYear: null,
      CourseList: [],
      CourseList_1: [],
      detailDisplay: [],
      dayDetailDisplay: [],
      reservationMemos: [],
      timeDataList: [],
      selectedDay: null,
      Lio_Date: '',
      changeFlag: false
    };

    this.renderDateNum = 0;
  }

  /**
   * 各項目情報の取得
   */
  componentDidMount() {
    const params = {
      Month: moment(this.props.Lio_Date).format('MM'),  //前画面で入力された、日にちの取得（月のみ）
      Year: moment(this.props.Lio_Date).format('YYYY'), //前画面で入力された、日にちの取得（年のみ）
    }
    this.formRef.current.setFieldsValue({
      PeopleNum: 1,
      totalPople: 0,
    })
    this.setState({
      selectedMonth: params.Month, //Stateに保存
      selectedYear: params.Year,    //Stateに保存
    })
    this.getDataVariousAggregate(params); //カレンダーの情報取得処理
  }

  /**
   * 再表示が押された場合
   * @param {*} values
   */
  searchConditions = (values) => {
    // 日付ごとに空き人数を取る
    let InspectSelect = '';
    for (let i = 1; i <= 20; i++) {
      const v = this.formRef.current.getFieldValue('Inspect' + (('0' + i).slice(-2)));
      InspectSelect += v ? '1' : '0';
    }
    let params = {
      ...values,
      Date: this.state.selectedYear + "/" + this.state.selectedMonth + "/01",
      FacilityType: 1,
      Li_TargetCourse: this.props.Lio_CourSe,
      date: this.props.Lio_Date,
      contractType: this.props.Lio_DataScreen.Lo_ContractType,
      contractOrgCode: this.props.Lio_DataScreen.Lo_ContractOrgCode,
      contractStartDate: this.props.Lio_DataScreen.Lo_ContractStartDate,
      ContractNum: this.props.Lio_DataScreen.Lo_ContractNum,
      ConsultCourse: this.props.Lio_DataScreen.Lio_ConsultCourse,
      dataSourceInspectChange: this.props.dataSourceInspectChange,
      InspectSelect,
    }
    ReservationStatusAction.Redisplay(params)
      .then(res => {
        let date = this.state.selectedYear + "-" + this.state.selectedMonth + "-01"
        let detailDisplay = res.detailDisplay[date]
        detailDisplay.sort(function (a, b) {
          if (a.sortFlag > b.sortFlag) {
            return -1;
          } else {
            return 1;
          }
        });
        this.setState({
          reservationMemos: res.result,
          detailDisplay: res.detailDisplay[date],
          CourseList: res.CourseList,
          CourseList_1: res.CourseList_1,
          dayDetailDisplay: res.detailDisplay,
          day: this.state.selectedYear + "/" + this.state.selectedMonth + "/01"
        });
        this.formRef.current.setFieldsValue({
          CourseSelect: '',
        })
      })
      .catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  /**
   * 何かが、更新された場合に稼働
   * @param {*} prevState
   */
  componentDidUpdate(prevState) {
    //休診日の時に、背景色を付ける
    if (this.state.timeDataList !== prevState.timeDataList) {
      this.getTileClassIsKyusin = (event) => {
        const className = this.getTileClassIsKyusinSub(event)
        return className
      }
    }
    //「◎、×」判定・空き状況
    if (this.state.reservationMemos !== prevState.reservationMemos) {
      this.getTileContent = (event) => {
        const component = this.getTileContentSub(event)
        return component
      }
    }
  }

  /**
   *「◎、×」判定・空き状況
   * @param {*} param0
   * @returns
   */
  getTileContent = (event) => {
    const Component = this.getTileContentSub(event.date)
    return Component
  }

  /**
   * 「◎、×」判定・空き状況Sub
   * @param {*} date
   * @returns
   */
  getTileContentSub(event) {
    if (event.view !== 'month') {
      return null
    }

    const targetDate = moment(event.date).format('YYYYMMDD')
    let status = ''
    let memo = ''

    this.state.reservationMemos.map((value, index) => {
      if (value.day === targetDate) {
        status = value.Availability
        memo = value.People
      }
    })

    return (
      <div
        onDoubleClick={() => { this.addDate(event.date) }}
        style={{ height: 50, display: 'flex', flexDirection: 'column', gap: 10, paddingTop: 5 }}
      >
        <div >
          {status}
        </div>
        <div style={{ fontSize: 13, fontWeight: 'normal' }}>
          {memo}
        </div>
      </div>
    )
  }

  returnData(data) {
    this.props.onFinishScreen(data)
    this.closeModal()
  }

  /**
  * カレンダー処理
  * @param {*} event
  * @returns
  */
  getTileClassIsKyusin = (event) => {
    const className = this.getTileClassIsKyusinSub(event)
    return className
  }

  /**
   * カレンダー処理 「休診日の時に、背景色を付ける」
   * @param {*} event
   * @returns
   */
  getTileClassIsKyusinSub(event) {
    if (event.view !== 'month') {
      return null
    }
    // フォーマット変更
    const targetDay = moment(event.date).format('YYYY/MM/DD')
    let kyusinkubun = false
    let dateList = this.state.timeDataList
    // 祝日判定
    for (let i = 0; i < dateList.length; i++) {
      if (targetDay === dateList[i].day) {
        kyusinkubun = true
        // if (dateList[i].kyusinkbn === 1) {
        //   kyusinkubun = true
        // }
      }
    }
    return (kyusinkubun) ? 'react-calendar-selectDay' : 'react-calendar-kaijyo'
  }

  /**
   * カレンダー処理
   * @param {} activeStartDate
   * @param {*} view
   */
  moveMonthPage = (activeStartDate, view) => {
    //先月・次月に遷移
    this.reloadDate(activeStartDate, view)
  }

  /**
   * カレンダー処理
   * 次の月に移動した時のデータリロード
   * @param {*} activeStartDate
   * @param {*} view
   */
  reloadDate = (activeStartDate, view) => {
    const params = {
      Month: moment(activeStartDate).format('MM'),
      Year: moment(activeStartDate).format('YYYY'),
    }

    this.setState({
      selectedMonth: params.Month,
      selectedYear: params.Year
    })

    this.getDataVariousAggregate(params);
  }

  /**
   * カレンダー処理
   * カレンダー情報を取得
   * @param {*} params
   */
  getDataVariousAggregate = (params) => {
    ReservationStatusAction.getDataVariousAggregate(params)
      .then(res => {
        if (res) {
          console.log(res)
          this.setState({
            dataSource: res,
            changeMonthFlg: false
          });
          this.searchConditions(this.formRef.current?.getFieldValue())

        }
      })
  }

  /**
   * コース詳細　ファイル名はミスです
   */
  callScreenAddDate() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <AddDate
            dataSourceInspectChange={this.props.dataSourceInspectChange}
            DataScreen={this.props.Lio_DataScreen}
            date={this.props.Lio_Date}
            onFinishScreen={(output) => {
              // this.onChangeFilter()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addDate(date) {
    console.log(date)
    console.log(this.formRef.current.getFieldValue('PeopleNum'))
    let dateFormat = moment(date).format('YYYY/MM/DD')
    let datedayofweek = date.getDay()
    var dayofweek = ["日", "月", "火", "水", "木", "金", "土"]
    let copyData
    // 人数変更をしているときに追加された想定
    if (this.state.changeFlag) {
      copyData = [...this.formRef.current.getFieldValue('tableData')]
    } else {
      copyData = [...this.state.timeDataList]
    }
    let totalNumbers = []
    let addData
    let duplicationFlag
    for (let i = 0; i < copyData.length; i++) {
      if (dateFormat === copyData[i].day) {
        duplicationFlag = true
        break
      } else {
        duplicationFlag = false
      }
    }
    if (duplicationFlag) return
    addData = {
      id: dateFormat,
      day: dateFormat,
      date: date,
      datedayofweek: dayofweek[datedayofweek] + '曜日',
      people: this.formRef.current.getFieldValue('PeopleNum')
    }
    copyData.push(addData)
    // 日付順に並び替え
    copyData.sort(function (a, b) {
      if (a.day > b.day) {
        return 1;
      } else {
        return -1;
      }
    });
    // 人数を計算するために配列化
    for (let k = 0; k < copyData.length; k++) {
      totalNumbers.push(copyData[k].people)
    }
    // 人数合計を出す
    let sum
    sum = totalNumbers.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
    this.setState({
      timeDataList: copyData
    })
    this.formRef.current.setFieldsValue({
      totalPople: sum,
      tableData: copyData
    })
    console.log(copyData)
  }

  deleteRecord(record) {
    let copyData = [...this.state.timeDataList]
    let totalNumbers = []
    for (let l = 0; l < copyData.length; l++) {
      if (record.id === copyData[l].id) {
        copyData.splice(l, 1);
        break
      }
    }
    // 人数を計算するために配列化
    for (let k = 0; k < copyData.length; k++) {
      totalNumbers.push(copyData[k].people)
    }
    // 人数合計を出す
    let sum
    if (totalNumbers.length !== 0) {
      sum = totalNumbers.reduce(function (a, b) {
        return Number(a) + Number(b);
      });
    } else {
      sum = 0
    }
    this.setState({
      timeDataList: copyData
    })
    this.formRef.current.setFieldsValue({
      totalPople: sum,
      tableData: copyData
    })
  }

  reservation() {
    if (this.state.timeDataList.length === 0) {
      message.error('日付を選択してください')
      return
    }
    for (let i = 0; i < this.state.timeDataList.length; i++) {
      if (this.state.timeDataList[i].people === '0') {
        message.error('人数を1人以上設定してください')
        return
      }
    }
    let date = new Date(this.state.selectedYear, this.state.selectedMonth, 0)
    let lastDate = date.getDate()
    let params = {
      startDate: 1,
      endDate: lastDate,
      course: this.props.Lio_CourSe,
      Gender: this.props.Gender,
      dayList: this.state.timeDataList,
      officeCode: this.props.OfficeCode,
      BranchStoreCode: this.props.BranchStoreCode,
      TimeZone: this.props.TimeZone,
      facility: this.props.facility,
      contractNum: this.props.Lio_DataScreen.Lo_ContractNum,
      contractOrgCode: this.props.Lio_DataScreen.Lo_ContractOrgCode,
      contractStartDate: this.props.Lio_DataScreen.Lo_ContractStartDate,
      contractType: this.props.Lio_DataScreen.Lo_ContractType,
      dataSourceInspectChange: this.props.dataSourceInspectChange,
      columnList: this.props.columnList,
      dataList: this.props.dataList,
      user: this.props.user
    }

    ReservationStatusAction.reservation(params)
      .then(res => {
        if (res) {
          let timeDataList = this.state.timeDataList

          if (res.warning === 0) {
            message.success('予約が完了しました。')
            let DateFChar = moment(timeDataList[0].date)
            let DateTChar = moment(timeDataList[timeDataList.length - 1].date)
            let propsParams = {
              timeDataList: this.state.timeDataList,
              reserve_nums: res.reserve_nums
            }
            this.props.onFinishScreen(propsParams, DateFChar, DateTChar)
          } else {
            Modal.warning({
              title: '仮予約できる人数の上限を超えていたため' + res.warning + '人予約できませんでした。',
              width: 650,
              onOk: () => {
                let DateFChar = moment(timeDataList[0].date)
                let DateTChar = moment(timeDataList[timeDataList.length - 1].date)
                let propsParams = {
                  timeDataList: this.state.timeDataList,
                  reserve_nums: res.reserve_nums
                }
                this.props.onFinishScreen(propsParams, DateFChar, DateTChar)
              }
            })
          }
        }
      })
      .catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
      })
  }

  // 人数変更を反映させるとき
  change() {
    let newData = this.formRef.current.getFieldValue('tableData')
    let totalNumbers = []
    // 人数を計算するために配列化
    for (let k = 0; k < newData.length; k++) {
      totalNumbers.push(newData[k].people)
    }
    // 人数合計を出す
    let sum
    if (totalNumbers.length !== 0) {
      sum = totalNumbers.reduce(function (a, b) {
        return Number(a) + Number(b);
      });
    } else {
      sum = 0
    }
    this.setState({
      changeFlag: false,
      timeDataList: newData
    })
    this.formRef.current.setFieldsValue({
      totalPople: sum
    })
  }

  // 日付を変更したとき
  changeDate(date) {
    let dayFomat = moment(date).format('YYYY/MM/DD')
    let formatDate = moment(date).format('YYYY-MM-DD')
    let dayDetailDisplay = this.state.dayDetailDisplay[formatDate]
    dayDetailDisplay.sort(function (a, b) {
      if (a.sortFlag > b.sortFlag) {
        return -1;
      } else {
        return 1;
      }
    });
    this.setState({
      detailDisplay: dayDetailDisplay,
      day: dayFomat
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="calendar-empty-sub">
        <Card title="予約状況照会">
          <Form
            autoComplete='off'
            ref={this.formRef}
            initialValues={this.state.formDefault}
          >
            <div className='box_inner_horizontal'>
              <div style={{ width: '500px' }}>
                <Calendar
                  className='frame_taking'
                  //style={{ width: '800px' }}
                  theme='ios'
                  themeVariant='light'
                  locale={'ja-JP'}
                  calendarType={'US'}
                  controls={['calendar']}
                  touchUi={true}
                  display='inline'
                  defaultActiveStartDate={new Date(moment(this.props.Lio_Date)?.format('YYYY') + '-' + moment(this.props.Lio_Date)?.format('MM') + '-01')}
                  prev2Label={null}
                  next2Label={null}
                  tileClassName={this.getTileClassIsKyusin}
                  tileContent={this.getTileContent}
                  formatDay={(locale, date) => moment(date).format('D')}
                  onActiveStartDateChange={({ action, activeStartDate, view }) => {
                    if (action == 'prev' || action == 'next' || (action == 'drillDown' && view == 'month') || (action == 'drillUp' && view == 'year')) {
                      this.moveMonthPage(activeStartDate, view)
                    }

                  }}
                  onClickDay={(value, event) => {
                    this.changeDate(value)
                  }}
                />
              </div>

              <div className="vertical_border" style={{ marginLeft: 10, marginRight: 10 }} />

              <div>
                <div className="box_inner_vertical" style={{ marginBottom: '10px' }}>
                  <Form.Item
                    name="CourseSelect"
                    label="コース集計分類"
                    style={{ marginBottom: 0 }}
                  >
                    <Select style={{ width: '120px' }}>
                      {this.state.CourseList?.map((value, index) => (
                        <Select.Option
                          value={value}
                          key={`cl-${index}`}
                        >
                          {this.state.CourseList_1[index]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div>
                    <div className='table_header_filter'>
                      {this.state.day}
                    </div>
                    <Table
                      bordered
                      size='small'
                      className='custom-table-scroll handle-table-calendar'
                      rowKey={record => record.id}
                      pagination={false}
                      scroll={{ y: 400 }}
                      dataSource={this.state.detailDisplay}
                    >

                      <Table.Column
                        title='名称'
                        dataIndex='name'
                        render={(value, record, index) =>
                          <div style={{ lineHeight: 2 }}>
                            {value !== '' ?
                              value : null}
                          </div>
                        }
                      />

                      <Table.Column
                        title='空き人数'
                        dataIndex='emptinessInspectNum'
                        render={(value, record, index) =>
                          <div style={{ textAlign: 'right' }}>
                            {value}
                          </div>
                        }
                      />
                      <Table.Column
                        title='残り枠数'
                        dataIndex='empty'
                        render={(value, record, index) =>
                          <div style={{ textAlign: 'right' }}>
                            {value}
                          </div>
                        }
                      />
                    </Table>
                  </div>
                </div>
                <div className="box_inner_horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Form.Item
                    label="人数"
                    name="PeopleNum"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      type="number"
                      style={{ width: 100, textAlign: "right" }}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      this.searchConditions(this.formRef.current?.getFieldValue())
                    }}
                  >
                    <span className='btn_label'>
                      再表示
                    </span>
                  </Button>
                </div>
              </div>
              <div className="vertical_border" style={{ marginLeft: 10, marginRight: 10 }} />
              <div>
                <Table
                  bordered
                  size='small'
                  rowKey={record => record.id}
                  dataSource={this.state.timeDataList}
                  pagination={false}
                  scroll={{ y: 450 }}
                >
                  <Table.Column
                    title='日付'
                    dataIndex='day'
                    width={60}
                  />
                  <Table.Column
                    title='曜日'
                    dataIndex='datedayofweek'
                    width={40}
                  />
                  <Table.Column
                    title='人数'
                    dataIndex='people'
                    width={40}
                    render={(value, record, index) => {
                      return (
                        this.state.changeFlag ?
                          <Form.Item style={{ marginBottom: '0' }} name={['tableData', index, 'people']}>
                            <Input
                              type="number"
                              style={{ width: 50, textAlign: "right" }}
                            />
                          </Form.Item>
                          :
                          <div>
                            {value + '人'}
                          </div>
                      )
                    }}
                  />
                  <Table.Column
                    width={20}
                    align='center'
                    fixed='right'
                    render={(value, record) => (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu-1'
                              onClick={() => this.deleteRecord(record)}
                            >
                              削除
                            </Menu.Item>
                            <Menu.Item
                              key='menu-2'
                              onClick={() =>
                                this.setState({
                                  changeFlag: true
                                })
                              }
                            >
                              人数変更
                            </Menu.Item>
                          </Menu>
                        )}>
                        <Button size='small' icon={<MoreOutlined />} />
                      </Dropdown>
                    )}
                  />
                </Table>
                {
                  this.state.changeFlag ?
                    <div className="box_button_bottom_right">
                      <Button
                        type='primary'
                        onClick={() => {
                          this.change()
                        }}
                      >
                        <span className='btn_label'>
                          変更
                        </span>
                      </Button>
                    </div>
                    :
                    null
                }
                <Form.Item
                  label="合計"
                  name="totalPople"
                  style={{ marginTop: '10px', float: 'right', marginBottom: 0 }}
                >
                  <Input
                    type="number"
                    readOnly
                    style={{ width: 100, textAlign: "right" }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="box_button_bottom_right">
              <Button
                type='primary'
                onClick={() => {
                  this.reservation()
                }}
              >
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

export default ReservationStatus;
