import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, message } from "antd";
import { CopyOutlined, ArrowDownOutlined } from '@ant-design/icons'
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'


// Form labelの幅
const labelCol = {
  labelCol: { style: { width: 60 } }
}

class WS0493002_ConditionCopying extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '条件式コメント複写画面';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      originCommentCode: '',
      originCommentContent: '',
      copyCommentCode: '',
      copyCommentContent: '',
    };
  }

  // setFormFieldValue(namePath, value) {
  //   this.formRef?.current?.setFields([
  //     {
  //       name: namePath,
  //       value,
  //     },
  //   ]);
  // }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)

    if (data.start_date_on == '0000/00/00') {
      this.formRef.current?.setFieldsValue({
        copy_start_date_on: '',
      })
    } else {
      this.formRef.current?.setFieldsValue({
        copy_start_date_on: this.props.record.start_date_on,
      })
    }

    this.setState({
      originCommentCode: this.props.record.comment_code,
      originCommentContent: this.props.record.comment_content,
      originstartDateOn: this.props.record.start_date_on
    })

    // this.renderForm()
    // this.checkEnabledBtn(data)
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props !== prevProps) {
  //     this.renderForm()
  //     this.checkEnabledBtn(data)
  //   }
  // }

  // renderForm() {
  //   const keys = Object.keys(data);
  //   const values = Object.values(data);
  //   keys.forEach((value, index) => {
  //     this.setFormFieldValue(`${value}`, values[index])
  //   })
  // }

  // checkEnabledBtn(value) {
  //   const enabledBtn =
  //     value?.StsGuideCmt === 1 ||
  //     value?.StsStartDate === 1 ||
  //     value?.StsSerialNum === 1;
  //   this.setFormFieldValue('Expression_21', enabledBtn ? 1 : 0);
  // }

  // runF12() {
  //   //api
  //   // hard mess error
  //   this.ErorrMessage('ｺﾒﾝﾄｺｰﾄﾞを指定してください')
  // }

  // ErorrMessage(content) {
  //   Modal.error({ content: content })
  // }

  // onFinish = (values) => {
  //   // F12
  //   this.runF12()
  // }

  // getRawValue = (name) => this.formRef?.current?.getFieldValue(name)

  // checkExpression = (name) => this.formRef?.current?.getFieldValue(name) === 1 ? 0 : 1;

  // onChangeCheckBox(event, expressionName, checkNameCheckBox) {
  //   this.setFormFieldValue(expressionName, event.target.checked ? 1 : 0)
  //   this.setFormFieldValue('Expression_21', event.target.checked ? 1 : 0)
  //   if (this.getRawValue(expressionName) === 0) {
  //     switch (checkNameCheckBox) {
  //       case 'StsGuideCmt': {
  //         this.setFormFieldValue('GuideCmtF', '')
  //         this.setFormFieldValue('comment_content', '')
  //         this.setFormFieldValue('GuideCmtT', '')
  //       } break;
  //       case 'StsStartDate': {
  //         this.setFormFieldValue('StartDateFChar', '')
  //         this.setFormFieldValue('StartDateTChar', '')
  //       } break;
  //       case 'StsSerialNum': {
  //         this.setFormFieldValue('SerialNumF', '')
  //         this.setFormFieldValue('SerialNumT', '')
  //         this.setFormFieldValue('name', '')
  //       }
  //     }
  //   }
  //   this.forceUpdate()
  // }

  // card() {
  //   return (
  //     <Row gutter={24}>
  //       <Col span="5">
  //         <Form.Item></Form.Item>
  //         <Form.Item {...grid}
  //           name="StsGuideCmt"
  //           label=""
  //           valuePropName="checked"
  //           style={{ marginBottom: '30px' }}
  //         >
  //           <Checkbox onChange={(e) => this.onChangeCheckBox(e, 'Expression_4', 'StsGuideCmt')}>
  //             {'コメント'}
  //           </Checkbox>
  //         </Form.Item>

  //         <Form.Item
  //           {...grid}
  //           name="StsStartDate"
  //           label=""
  //           valuePropName="checked"
  //         >
  //           <Checkbox onChange={(e) => this.onChangeCheckBox(e, 'Expression_5', 'StsStartDate')}>
  //             {'開始日'}
  //           </Checkbox>
  //         </Form.Item>

  //         <Form.Item
  //           {...grid}
  //           name="StsSerialNum"
  //           label=""
  //           valuePropName="checked"
  //         >
  //           <Checkbox onChange={(e) => this.onChangeCheckBox(e, 'Expression_6', 'StsSerialNum')}>
  //             {'連　　番'}
  //           </Checkbox>
  //         </Form.Item>
  //       </Col>

  //       <Col span="10">
  //         <Form.Item><span > 複写元</span></Form.Item>
  //         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '30px' }}>
  //           <Form.Item
  //             width="100px"
  //             name="GuideCmtF"
  //           >
  //             <InputNumber
  //               disabled={this.checkExpression('Expression_4')}
  //               style={{ width: '100px' }}
  //             />
  //           </Form.Item>
  //           <Form.Item
  //             name="comment_content"
  //           >
  //             <Input
  //               readOnly
  //               style={{ border: 'none', width: '400px' }}
  //             />
  //           </Form.Item>
  //         </div>

  //         <Form.Item name="StartDateFChar">
  //           <VenusDatePickerCustom
  //             formRefDatePicker={this.formRef}
  //             disabled={this.checkExpression('Expression_5')}
  //             style={{ width: '135px' }}
  //             format={"YYYY/MM/DD"}
  //           />
  //         </Form.Item>

  //         <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
  //           <Form.Item name="SerialNumF" >
  //             <InputNumber
  //               disabled={this.checkExpression('Expression_6')}
  //               style={{ width: '135px' }}
  //             />
  //           </Form.Item>
  //           <Form.Item
  //             name="name"
  //           >
  //             <Input
  //               readOnly
  //               style={{ border: 'none', width: '400px' }}
  //             />
  //           </Form.Item>
  //         </div>

  //       </Col>
  //       <Col span="9">
  //         <Form.Item><span >  複写先</span></Form.Item>
  //         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '30px' }}>
  //           <Form.Item
  //             width="100px"
  //             name="GuideCmtT"
  //           >
  //             <InputNumber
  //               disabled={this.checkExpression('Expression_4')}
  //               style={{ width: '100px' }}
  //             />
  //           </Form.Item>
  //           <Form.Item
  //             name="comment_content"
  //           >
  //             <Input
  //               readOnly
  //               style={{ border: 'none', width: '400px' }}
  //             />
  //           </Form.Item>
  //         </div>
  //         <Form.Item name="StartDateTChar">
  //           <VenusDatePickerCustom
  //             formRefDatePicker={this.formRef}
  //             disabled={this.checkExpression('Expression_5')}
  //             style={{ width: '135px' }}
  //             format={"YYYY/MM/DD"}
  //           />
  //         </Form.Item>
  //         <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
  //           <Form.Item name="SerialNumT" >
  //             <InputNumber
  //               disabled={this.checkExpression('Expression_6')}
  //               style={{ width: '135px' }}
  //             />
  //           </Form.Item>
  //           <Form.Item
  //             name="name"
  //           >
  //             <Input
  //               readOnly
  //               style={{ border: 'none', width: '400px' }}
  //             />
  //           </Form.Item>
  //         </div>
  //       </Col>
  //     </Row >)
  // }

  show_WS0272001_CautionGuideNotesSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0272001_CautionGuideNotesSearchQuery
            onFinishScreen={(obj) => {

              this.formRef.current.setFieldsValue({
                copy_comment_code: obj.Lo_LnkOutCmtCode,
                copy_comment_content: obj.Lo_recordData.comment_content
              })

              this.setState({
                copyCommentCode: obj.Lo_LnkOutCmtCode,
                copyCommentContent: obj.Lo_recordData.comment_content
              })

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  onCopy = () => {
    let values = this.formRef.current?.getFieldsValue()

    if (values.copy_comment_code ?? '' == '') {
      message.error("コードが入力されていません");
      return;
    } else {
      this.props.onFinishScreen(values)
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };


  render() {
    return (
      <div className="condition-copying-screen">
        <Card title="条件式コメント複写画面" className="mb-2">
          <div className='box_inner_vertical' >
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              autoComplete='off'
            >
              <div className='box_inner_vertical' style={{ padding: '0px', alignItems: 'center' }}>
                <div className='box_inner_vertical specific-box'>
                  <span className='specific-title'>
                    複写元
                  </span>

                  <Form.Item
                    name="comment_code"
                    label="コード"
                    {...labelCol}
                  >
                    <div style={{ marginTop: '-2px' }}>{this.state.originCommentCode}</div>
                  </Form.Item>

                  <Form.Item
                    name="comment_content"
                    label="コメント"
                    {...labelCol}
                  >
                    <div style={{ marginTop: 3 }}>{this.state.originCommentContent}</div>
                  </Form.Item>

                  <Form.Item
                    name="start_date_on"
                    label="適用日"
                    {...labelCol}
                  >
                    <div style={{ marginTop: '-2px' }}>{this.state.originstartDateOn}</div>
                  </Form.Item>
                </div>

                <div>
                  <ArrowDownOutlined style={{ fontSize: 24 }} />
                </div>

                <div className='box_inner_vertical specific-box'
                  style={{ marginTop: 0, marginBottom: 0 }}>
                  <span className='specific-title'>
                    複写先
                  </span>

                  <Form.Item
                    name="copy_comment_code"
                    label="コード"
                    {...labelCol}
                  >
                    <Input.Search
                      type="number"
                      className="input-size-number-10"
                      onSearch={() => {
                        this.show_WS0272001_CautionGuideNotesSearchQuery()
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="copy_comment_content"
                    label="コメント"
                    {...labelCol}
                  >
                    <div style={{ marginTop: 5 }}>{this.state.copyCommentContent}</div>
                  </Form.Item>

                  <Form.Item
                    name="copy_start_date_on"
                    label="適用日"
                    {...labelCol}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      format='YYYY/MM/DD'
                    />
                  </Form.Item>
                </div>

                <div className='box_button_bottom_right'
                  style={{ marginTop: 0 }}>
                  <Button
                    type='primary'
                    icon={<CopyOutlined />}
                    onClick={() => (
                      this.onCopy()
                    )}
                  >
                    <span className='btn_label'>
                      複写
                    </span>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0493002_ConditionCopying);
