import React from 'react'
import moment from "moment";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import { EditOutlined, PlusOutlined, CheckOutlined, MoreOutlined } from '@ant-design/icons';
import RadiographyJudgeMaintainAction from "redux/InputBusiness/RadiographyJudgeMaintain/RadiographyJudgeMaintain.action"
import {
  Card, Table, message, Button, Form, Input, Popconfirm, Space,
  Modal, Checkbox, InputNumber, Dropdown, Menu,
} from 'antd'
import axios from 'configs/axios'

const columns = [
  {
    title: '判定ﾚﾍﾞﾙ',
    dataIndex: 'judgment_level',
    // classNumber: '10',
    // textAlign: 'right',
    type: 1,
  },

  {
    title: '読影判定',
    dataIndex: 'interpretation_judgment_result',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '健診判定',
    dataIndex: 'medical_exam_judgment_result',
    // classNumber: '40',
    type: 1,
  },

  {
    title: '正常',
    dataIndex: 'normal_value_flag',
    // classNumber: '40',
    type: 3,
    radioList: [
      {
        value: 1,
        label: 'はい'
      },
      {
        value: 0,
        label: 'いいえ'
      }],
  },
  {
    title: '備考',
    dataIndex: 'options',
    // classNumber: '5',
    type: 1,
  }

]

class WS1876001_RadiographyJudgeMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '読影判定保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },

      selectedRow: {},
      selectedRowKeys: [],
      applyDateList: [],

      isLoading: false,
    }
  }

  componentDidMount() {
    this.loadMainSource()
  }


  loadMainSource = () => {
    ////確かめ方
    console.log("テスト")
    console.log(RadiographyJudgeMaintainAction.index())
    this.setState({ isLoading: true })
    RadiographyJudgeMaintainAction.index()
      .then(res => {
        console.log("aaaaa")
        console.log(res);
        this.formRef.current.setFieldsValue({
          MainSource: res.MainSource,
        })
      })
      // this.setState({ isLoading: true })
      // // this.formRef.current.setFieldsValue({
      // //   MainSource: RadiographyJudgeMaintainAction.index().MainSource,
      // // })
      // this.setState({ isLoading: false })
      // axios.get(RadiographyJudgeMaintainAction.index())
      //   .then(res => {
      //     console.log("aaaaa")
      //     console.log(res.data.MainSource);
      //     this.formRef.current.setFieldsValue({
      //       MainSource: res.data.MainSource,
      //     })
      //   })
      //     .catch(error => {
      //       console.log(error)

      //       const res = error.response
      //       if (!res || !res.data || !res.data.message) {
      //         message.error('エラーが発生しました')
      //         return
      //       }

      //       message.error(res.data.message)
      //     })
      .then(() => this.setState({ isLoading: false }))
  }

  //★★編集保存ボタン★★//
  onSave = (output, id) => {
    console.log(output);
    let params = {
      id: id ?? null
    }
    let itemChange = output.itemChange
    Object.keys(itemChange).forEach(key => {
      console.log(itemChange)
      console.log(key)
      params = {
        ...params,
        [key]: itemChange[key]
      }
    })
    // let params = {
    //   data: record.itemChange,
    //   id: id ?? undefined,
    // }

    console.log(params);
    console.log("params");
    console.log(output);
    RadiographyJudgeMaintainAction.saveRadioInfo(params)
      .then((res) => {
        console.log(res)
        console.log("ここ")
        this.loadMainSource()
      })
      .catch((err) => {
        const res = err.response
        if (!res || res.data || res.data.massage) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }


  //★★削除ボタン★★//
  deleteRadioInfo = (id) => {
    // console.log(RadiographyJudgeMaintainAction.deleteRadioInfo())
    let params = {
      id: Number(id)
    }
    RadiographyJudgeMaintainAction.deleteRadioInfo(params)
      .then(res => {
        // console.log(res)
        this.setState({
          applyDateList: res
        })
        this.loadMainSource()
      })
  }


  onFinish = (values) => {
    this.setState({ isLoading: true })
    axios.post('/api/radiography-judge-maintain/radiography-judge-maintain', values)
      .then(res => {
        message.success('保存完了')
        this.loadMainSource()
      })
      .catch(error => {
        console.log(error)

        this.setState({ isLoading: false })

        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }



  render() {
    return (
      <div className='radiography-judge-maintain'>
        <Card title='読影判定保守'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}>
            <Table bordered
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={(record) => record.id}
              size='small'
              // rowSelection={{
              // type: 'radio',
              //   onSelect: (record) => {
              //     this.setState({
              //       selectedRow: record,
              //       selectedRowKeys: [record?.id],
              //     })
              //   },
              //   selectedRowKeys: this.state.selectedRowKeys,
              // }}
              // rowKey={(record) => (record.id || Math.random())}
              onRow={(record, rowIndex) => {
                return {
                  //   return {
                  onClick: () => {
                    this.setState({
                      selectedRow: record,
                      selectedRowKeys: [record?.id],
                    })
                  },
                  onDoubleClick: () => {
                    // judgment_weightに無理やり一つ階層下のjudgment_weightを格納している
                    // 配列として追加している
                    // record['judgment_weight'] = record.judgment_level_conversion_master.judgment_weight;
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '550px',
                        className: '',
                        component:
                          <ModalChange
                            columns={columns}
                            record={record}
                            onUpdate={(output) => {
                              this.onSave(output, record.id)
                              this.closeModal()
                              console.log(output)
                              console.log("更新")
                            }}
                            onDelete={(output) => {
                              this.deleteRadioInfo(record.id)
                              console.log("削除")
                              // console.log(record)
                              this.closeModal()
                            }}
                          />
                      },
                    })
                  }
                }
              }}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}

            >
              <Table.Column title='判定ﾚﾍﾞﾙ' showSorterTooltip={false} dataIndex='judgment_level' />
              <Table.Column title='読影判定' showSorterTooltip={false} dataIndex='interpretation_judgment_result' />
              <Table.Column title='健診判定' showSorterTooltip={false} dataIndex='medical_exam_judgment_result' />
              <Table.Column title="正常" showSorterTooltip={false} dataIndex="normal_value_flag" align='center' width={40}
                render={(value, record, index) => {
                  return (
                    this.checkJudgment(value)
                  )
                }}
              />
              {/* <Table.Column title='正常' showSorterTooltip={false} width={100} dataIndex='normal_value_flag' render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'normal_value_flag']} valuePropName='checked'>
                  <Checkbox />
                </Form.Item>
              )} /> */}

              <Table.Column title='重さ' showSorterTooltip={false} dataIndex='judgment_weight' />

              {/* <Table.Column title='重さ' showSorterTooltip={false} key='judgment_weight' render={(value, record, index) => (
                <Form.Item >
                  {value.judgment_level_conversion_master.judgment_weight}
                </Form.Item>
              )} /> */}

              {/* <Table.Column title='判定ﾚﾍﾞﾙ'  dataIndex='judgment_level' render={(value, record, index) => (<>
                <Form.Item name={['MainSource', index, 'id']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={['MainSource', index, 'judgment_level']}>
                  <InputNumber readOnly />
                </Form.Item>
              </>)} />
              <Table.Column title='読影判定'dataIndex='interpretation_judgment_result' render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'interpretation_judgment_result']}>
                  <Input readOnly />
                </Form.Item>
              )} />
              <Table.Column title='健診判定' dataIndex='medical_exam_judgment_result' render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'medical_exam_judgment_result']}>
                  <Input readOnly />
                </Form.Item>
              )} />
              <Table.Column title='正常'width={40} dataIndex='normal_value_flag' render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'normal_value_flag']} valuePropName='checked'>
                  <Checkbox />
                </Form.Item>
              )} />
              <Table.Column title='重さ' key='judgment_weight' render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'judgment_level_conversion_master', 'judgment_weight']}>
                  <InputNumber readOnly />
                </Form.Item>
              )} /> */}
              {/*<Table.Column
                width={30}
                key='action'
                title={(
                  <Button type='primary' size='small' icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => {
                    this.formRef.current.setFieldsValue({
                      MainSource: [
                        ...this.formRef.current.getFieldValue('MainSource'),
                        { id: `n-${Math.random()}` },
                      ]
                    })
                    this.forceUpdate()
                  }} />
                )}
                render={(value, record) => (<Space>
                  <Popconfirm title='削除しますか？' onConfirm={() => {
                    this.setState({ isLoading: true })

                    return axios.post('/api/radiography-judge-maintain/radiography-judge-maintain/delete', { id: record.id })
                      .then(() => this.loadMainSource())
                      .catch(error => {
                        console.log(error)
                        this.setState({ isLoading: false })
                        const res = error.response
                        if (!res || !res.data || !res.data.message) {
                          message.error('エラーが発生しました')
                          return
                        }

                        message.error(res.data.message)
                      })
                    }}
                  >
                    <Button type='primary' size='small' danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>
                )}
              />  */}

              {/* ＋ボタンの設定 */}
              <Table.Column align='center' width={40} fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {
                                console.log(output)
                                this.onSave(output)
                                this.closeModal()
                              }}
                            />
                        },
                      });
                    }}
                  />
                }
                render={(text, record, index) => (
                  <Space>
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key='1' style={{ width: '100%' }}
                            size="small"
                            onClick={() => {
                              // 編集、削除
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  className: '',
                                  component:
                                    <ModalChange
                                      columns={columns}
                                      record={record}
                                      onUpdate={(output) => {
                                        this.closeModal()
                                      }}
                                      onDelete={(output) => {
                                        this.closeModal()
                                      }}
                                    />
                                },
                              });
                            }}
                          >編集</Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </Space>
                )}
              />

            </Table>
            {/* ボタン押すと下にテキストエリアができる */}

            {/* <Form.Item name='MainSource' className='mt-3'> 
            <div>
              {this.formRef.current?.getFieldValue('MainSource')}
            </div>
            /* </Form.Item> */}

            {/* optionsになにか入っていたら中身が表示される */}
            <div>
              {this.state.selectedRow.options}
            </div>

            {/* {this.formRef.current?.getFieldValue('MainSource')?.map((value, index) => (<>
              <Form.Item name={['MainSource', index, 'options']} hidden={(value.id !== this.state.selectedRow.id)} className='mt-3'>
                <Input.TextArea />
              </Form.Item>
            </>))} */}

            <Form.Item
              className='mt-3'
              style={{ textAlign: 'right' }}
            >
              {/* <Button type='primary' htmlType='submit' loading={this.state.isLoading}>保存</Button> */}
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          // afterClose={this.state.childModal.afterClose}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

export default WS1876001_RadiographyJudgeMaintain