import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Checkbox, Select, Table, Row, Col, Button, Space, Dropdown, Menu, message, Modal } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import PropTypes from "prop-types";
import NormalValueSettingEditAction from "redux/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSettingEdit.action";
// ★★★★★下のコンポーネントが新しく作ったやつ
import WS2713502_NormalValueSettingInput from "./WS2713502_NormalValueSettingInput.jsx";

const labelCol = {
  span: 4
}
const styleFormItem = {
  margin: 0,
}

const columns = [
  {
    title: '判定ﾚﾍﾞﾙ',
    dataIndex: 'judgment_level',
    // classNumber: '10',
    // textAlign: 'right',
    type: 4,
  },
]

class WS2713501_NormalValueSettingEdit extends React.Component {
  static propTypes = {
    Li_FormatCopySource: PropTypes.string,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)
    console.log(props)
    // document.title = '正常値設定詳細'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },

    }
  }

  componentDidMount() {
    this.loadMainSource(this.props);
  }

  loadMainSource = () => {
    console.log("aaa")
    this.setState({ isLoading: true })
    let format = {
      format: this.props.Lio_FormatCopySource
    }
    this.setState({ isLoadingExec: true });
    NormalValueSettingEditAction.GetData(format)
      .then((res) => {
        console.log(res)
        const formIns = this.formRef.current;
        const resData = res;
        if (!resData) {
          console.log("!")
          formIns.resetFields();
          return;
        }
        console.log(resData)
        this.setState({
          dataSource: res
        })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingExec: false }));
  }

  onFinish(values) { }

  /**
     * モーダル閉じる
     */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }
  //★★★★★追加した関数　関数名は考えてないからとりあえずsample★★//
  sample(order, text, record) {
    console.log(order)
    console.log(text)
    console.log(record)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS2713502_NormalValueSettingInput
            order={order}
            value={text}
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
              this.loadMainSource(this.props);
            }}
          />
        ),
      },
    });
  }
  //★★ここまで★★//

  render() {
    const { dataSource } = this.state
    return (
      <div className='normal-value-setting' style={{ width: '100%' }}>
        <Card title='正常値設定保守詳細' >
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ Pattern: 1 }}>
            <Row gutter={24} className='mb-3'>
              <Col>
                <Form.Item name='SearchChar' label='検索' >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name='StsUse' label='使用' valuePropName='checked' >
                  <Checkbox style={{ paddingTop: 5 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name='StsConfigured' label='設定済み' valuePropName='checked' >
                  <Checkbox style={{ paddingTop: 5 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name='Pattern' label='パターン' >
                  <Select >
                    <Select.Option value='1'></Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col >
                <Table bordered style={{ borderRight: '1px solid #F0F0F0' }} dataSource={dataSource} rowKey={record => record.key}>
                  <Table.Column title='検　査' dataIndex='InspectCodeTbl' />
                  <Table.Column title='名　称' dataIndex='InspectNameTbl' width={300} />
                  <Table.Column title='タイプ' dataIndex='TypeTbl' />
                  <Table.Column title='判定' dataIndex='Expression_49' />
                  <Table.Column title='規定値' dataIndex='Expression_50' />
                  {/* <Table.Column title='使用' dataIndex='Expression_44' /> */}

                  {/* ★★★★★ここらへんから★★ */}
                  <Table.Column align='center' width={40} fixed='right'
                    title={
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        loading={this.state.isLoadingInsurersList}
                        onClick={() => {
                          let order = "add";
                          this.sample(order)
                        }}
                      />
                    }
                    render={(text, record, index) => (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item key='1' style={{ width: '100%' }}
                              size="small"
                              onClick={() => {
                                let order = "edit";
                                this.sample(order, text, record)
                              }}
                            >編集</Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size='small' icon={<MoreOutlined />}></Button>
                      </Dropdown>

                    )}
                  />
                </Table>
                {/* ★★ここらへんまで★★ */}
              </Col >
            </Row>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}












export default WS2713501_NormalValueSettingEdit