import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-input/dunning-input/get-screen-data",
  update: "/api/insure-guide-input/dunning-input/update-btn",
};

const DunningInputService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async update(params) {
    return axios.post(API_LIST.update, params);
  },
};

export default DunningInputService;
