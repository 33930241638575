import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Col, InputNumber, message } from "antd";
import { ModalConfirm, ModalError, ModalInfo } from "components/Commons/ModalConfirm";
import InspectCmtInfoMaintainAction from 'redux/ExamCmtInfoMaintain/InspectCmtInfoMaintain.action'


const smGrid = {
  labelCol: { style: { width: 240 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class CopyRegister extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Lio_CmtCode: PropTypes.any,
    Lio_CmtClassify: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = '一括複写登録';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
  }

  copyBtn() {
    const oldCmtDivision = this.formRef.current.getFieldValue('comment_division')
    const newCmtDivision = this.formRef.current.getFieldValue('comment_division_copy')
    const params = {
      oldCmtDivision: oldCmtDivision,
      newCmtDivision: newCmtDivision,
    };
    const pattern = new RegExp(/^[0-9]+$/)
    if (!pattern.test(oldCmtDivision) || !oldCmtDivision) {
      ModalError('複写元の入力値が正しくありません')
      return
    } else if (!pattern.test(newCmtDivision) || !newCmtDivision || oldCmtDivision == newCmtDivision) {
      ModalError('複写先の入力値が正しくありません')
      return
    }
    ModalConfirm({
      content: 'コードを一括で複写しますがよろしいですか',
      styleIconModal: { color: '#006CC9' },
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        this.copyExec(params)
      }
    })
  }

  copyExec(params) {
    InspectCmtInfoMaintainAction.copyRegister(params)
      .then((res) => {
        ModalInfo(res?.data?.message)
        this.onFinish()
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  onFinish() {
    this.props.onFinishScreen()
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="一括複写登録">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Col span={7}>
              <Form.Item
                name="comment_division"
                label="コード複写元"
                {...smGrid}
              >
                <InputNumber
                  maxLength={8}
                />
              </Form.Item>
              <Form.Item
                name="comment_division_copy"
                label="コード複写先"
                {...smGrid}
              >
                <InputNumber
                  maxLength={8}
                />
              </Form.Item>
            </Col>
            <Form.Item >
              <div className="box_button_bottom_right" style={{ display: 'flex', gap: '20px' }}>
                <Button type="primary" onClick={() => { this.copyBtn(); }}>
                  複写登録
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyRegister);