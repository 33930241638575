///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, Row, Col, Button, Form, message, Radio } from 'antd'
import WS0061015_CheckYesNoNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo.jsx'
import PreviousCourseAcquisitionSubAction from 'redux/ReservationBusiness/PersonalReserveProcess/PreviousCourseAcquisitionSub.actions'
import WS0333001_SetIncludesQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQuery.jsx'
import GetImage from 'constants/Images'
import ModalDraggable from 'components/Commons/ModalDraggable'
import Color from 'constants/Color'

class WS2542001_PreviousCourseAcquisitionSub extends React.Component {
  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    Lio_CourseCode: PropTypes.any,
    Lio_ContractType: PropTypes.any,
    Lio_ContractOrgCode: PropTypes.any,
    Lio_ContractStartDate: PropTypes.any,
    Lio_ContractNum: PropTypes.any,
    Lo_Sts: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '前回コース取得'

    this.state = {
      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      inspectData: {},
      inspectList: [],
      tableTabs: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    let data = {
      PersonalNumId: this.isEmpty(this.props.Li_PersonalNum) ? '' : this.props.Li_PersonalNum,
      ConsultCourse: this.isEmpty(this.props.Lio_CourseCode) ? '' : this.props.Lio_CourseCode,
      ContractType: this.isEmpty(this.props.Lio_ContractType) ? '' : this.props.Lio_ContractType,
      ContractClassifyCode: this.isEmpty(this.props.Lio_ContractOrgCode) ? '' : this.props.Lio_ContractOrgCode,
      ContractDate: this.isEmpty(this.props.Lio_ContractStartDate) ? '' : this.props.Lio_ContractStartDate,
      ContractNum: this.isEmpty(this.props.Lio_ContractNum) ? '' : this.props.Lio_ContractNum
    }
    PreviousCourseAcquisitionSubAction.index(data)
      .then(res => {
        if (res) {
          this.formRef.current?.setFieldsValue({
            DataClassify: res?.inspectData?.DataClassify
          })
          const data = res.visitList ?? []

          let tempArray = [], i = 0, obj
          while (i < (res.inspectCount ?? 0)) {
            i++
            // ゼロ埋めした値を設定
            obj = {
              id: i,
              num: ('00' + i).slice(-2)
            }
            tempArray.push(obj)
          }

          this.setState({
            dataSource: data,
            selectedRow: data.length > 0 ? data[0] : {},
            selectedRowKeys: data.length > 0 ? [data[0].id] : [],
            inspectData: res?.inspectData,
            inspectList: tempArray
          })
          this.ContractInspectContentSelect(data[0])
        } else {
          this.setState({
            dataSource: [],
            selectedRow: {},
            inspectData: {},
            tableTabs: [],
          })
        }
      })
  }

  /**
   * 選択されたコースのオプションデータを取得する
   * @param {*} record 
   */
  ContractInspectContentSelect(record) {
    if (this.isEmpty(this.state.selectedRow?.id)) return
    if (this.state.selectedRow?.id === record.id) return
    let data = {
      reservation_number: this.isEmpty(record?.reservation_number) ? '' : record?.reservation_number,
      DataClassify: this.isEmpty(this.formRef.current?.getFieldValue('DataClassify')) ? '' : this.formRef.current?.getFieldValue('DataClassify'),
      visit_course: this.isEmpty(record?.visit_course) ? '' : record?.visit_course,
      contract_start_date_on: this.isEmpty(record?.contract_start_date_on) ? '' : record?.contract_start_date_on
    }
    PreviousCourseAcquisitionSubAction.ContractInspectContentSelect(data)
      .then(res => {

        const data = {
          ...this.state.inspectData,
          ...res.inspectData
        }
        this.setState({
          tableTabs: res.optionData ? res.optionData : [],
          inspectData: data,
          selectedRow: record,
        })
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  DbClick() {
    let data = {
      reservation_number: this.isEmpty(this.state.selectedRow?.reservation_number) ? '' : this.state.selectedRow.reservation_number,
      contract_organization_code: this.isEmpty(this.state.selectedRow?.contract_organization_code) ? '' : this.state.selectedRow.contract_organization_code,
      visit_course: this.isEmpty(this.state.selectedRow?.visit_course) ? '' : this.state.selectedRow.visit_course,
      contract_start_date_on: this.isEmpty(this.state.selectedRow?.contract_start_date_on) ? '' : this.state.selectedRow.contract_start_date_on,
      contract_number: this.isEmpty(this.state.selectedRow?.contract_number) ? '' : this.state.selectedRow.contract_number,
      contract_type: this.isEmpty(this.state.selectedRow?.contract_type) ? '' : this.state.selectedRow.contract_type
    }
    PreviousCourseAcquisitionSubAction.DbClick(data)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            Lio_CourseCode: res?.data?.Lo_CourseCode,
            Lio_ContractType: res?.data?.Lo_ContractType,
            Lio_ContractOrgCode: res?.data?.Lo_ContractOrgCode,
            Lio_ContractStartDate: res?.data?.Lo_ContractStartDate,
            Lio_ContractNum: res?.data?.Lo_ContractNum,
            recordData: this.state.selectedRow,
            Lo_Sts: true,
          })
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  Submit() {
    console.log('submit');
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0061015_CheckYesNoNo
            Li_Message={'このコース内容を取得しますか？'}
            onFinishScreen={(output) => {
              if (output?.Lio_StsReturn) {
                this.DbClick()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  ShowWS0333001_SetIncludesQuery(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0333001_SetIncludesQuery
            Li_StartDate={this.isEmpty(this.props.Lio_ContractStartDate) ? '' : this.props.Lio_ContractStartDate}
            Li_SetCode={record?.set_and_exam_code}
            Li_CourseCode={this.isEmpty(this.props.Lio_CourseCode) ? '' : this.props.Lio_CourseCode}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='previous-course-acquisition-sub'>
        <Card title='前回コース取得'>
          <Form
            ref={this.formRef}
            autoComplete='off'>
            <div className='box_inner_vertical'>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={false}
                rowKey={(record) => record.id}
                rowClassName={(record) => (record.id === this.state.selectedRow?.id) ? 'table-row-light' : ''}
                scroll={{ y: 250 }}
                onRow={(record, index) => {
                  return {
                    onClick: () => {
                      // await this.setState({
                      //   selectedRow: record,
                      // })
                      this.ContractInspectContentSelect(record)
                    },
                    onDoubleClick: async () => {
                      // 契約選択
                      await this.Submit()
                    }
                  }
                }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  width={110} />
                <Table.Column
                  title='受付No'
                  dataIndex='receipt_number'
                  width={70}
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'right' }}>
                      {record.receipt_number === 0 ? '' : record.receipt_number}
                    </div>
                  } />
                <Table.Column
                  title='時間帯'
                  dataIndex='period_time'
                  align='center'
                  width={70}
                  render={(value) => <div>{value === '00:00:00' ? '' : value?.substr(0, 5)}</div>} />
                <Table.Column
                  title='受診コース'
                  dataIndex='contract_short_name' />
                <Table.Column
                  title='判定'
                  dataIndex='comprehensive_judgment'
                  align='center'
                  width={60} />
              </Table>

              <div className='box_border' style={{ gap: 5 }} >
                <Row gutter={[3, 3]}>
                  {this.state.inspectList.map((item, index) =>
                    <Col
                      key={'inspect-' + index}
                      span={3} >
                      <div className='box_border'
                        style={{
                          height: 24,
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: Color(this.state.inspectData['InspectStatus_' + item.num] ?? 210).Background,
                          color: Color(this.state.inspectData['InspectStatus_' + item.num] ?? 210).Foreground,
                        }}
                      >
                        {this.state.inspectData['Inspect_' + item.num] ?? ''}
                      </div>
                    </Col>
                  )}
                </Row>

                <Form.Item
                  name='DataClassify'
                  initialValue='40'
                  style={{ marginBottom: 0, marginTop: 5 }}
                >
                  <Radio.Group onChange={() => this.ContractInspectContentSelect(this.state.selectedRow)} >
                    <Radio value='40' >オプション</Radio>
                    <Radio value='50' >追加</Radio>
                    <Radio value='60' >不要</Radio>
                    <Radio value='00' >全件</Radio>
                  </Radio.Group>
                </Form.Item>

                <Table
                  bordered
                  size='small'
                  pagination={false}
                  dataSource={this.state.tableTabs}
                  rowKey={(record) => record.id}
                  scroll={{ y: 200 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: (event) => {
                        this.ShowWS0333001_SetIncludesQuery(record)
                      },
                    }
                  }}
                >
                  <Table.Column
                    title=''
                    dataIndex='dataClassify'
                    width={30}
                    render={(text, record, index) =>
                      <div>
                        {record.dataClassify === 0 ? '' : <img src={GetImage(record.dataClassify)} />}
                      </div>
                    }
                  />
                  <Table.Column
                    title='名称'
                    dataIndex='set_name'
                  />
                  <Table.Column
                    title='金額'
                    dataIndex='price'
                    width={90}
                    render={(text, record, index) =>
                      <div>
                        {record.price === 0 ? null : record.price}
                      </div>
                    }
                  />
                </Table>
              </div>
            </div>
            {/* <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.Submit()}
              >
                契約取得
              </Button>
            </div> */}
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2542001_PreviousCourseAcquisitionSub)
