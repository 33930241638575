import axios from "configs/axios";

const API_LIST = {
  verification: "/api/progress-management/progress-management/verification",
  Downloadfile: '/api/progress-management/progress-management/download',
  getDataBySearch: "/api/progress-management/progress-management/search-data",
};

const ProgressManagementService = {
  async verification(params) {
    return axios.post(API_LIST.verification, params);
  },
  async Downloadfile(params) {
    return axios.post(API_LIST.Downloadfile, params, { responseType: 'blob' });
  },
  async getDataBySearchService(params) {
    return axios.get(API_LIST.getDataBySearch, { params });
  },
};

export default ProgressManagementService;