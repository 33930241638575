import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Modal, Button, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import FollowUpLetterDepartmentspecificFormat from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterDepartmentspecificFormat.jsx'
import FollowUpLetterCourseEdit from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterCourseEdit.jsx'
import FollowUpLetterCourseDepartmentspecificFormatAction from 'redux/IntroductionLetter/FollowUpLetter/FollowUpLetterCourseDepartmentspecificFormat.action.js'

class FollowUpLetterCourseDepartmentspecificFormat extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コース・診療科別書式保守(経過観察用)'

    this.state = {
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.getSettedCourseList()
  }

  /**
   * データ取得
   */
  getSettedCourseList = () => {
    FollowUpLetterCourseDepartmentspecificFormatAction.getSettedCourseList()
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 診療科別書式保守 (経過観察用) モーダル
   * @param {*} record 
   */
  showFollowUpLetterDepartmentspecificFormat = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <FollowUpLetterDepartmentspecificFormat
            course_code={record ? record.course_code : ''}
          />
        )
      }
    })
  }

  /**
   * コース・診療科別書式 編集モーダル
   * @param {*} record
   */
  showFollowUpLetterCourseEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <FollowUpLetterCourseEdit
            record={record}
            onFinishScreen={() => {
              this.closeModal()
              this.getSettedCourseList()
            }}
          />
        )
      }
    })
  }

  /**
   * 削除処理
   * @param {*} record
   */
  deleteCourse = (record) => {
    let param = {
      course_code: record.course_code
    }
    FollowUpLetterCourseDepartmentspecificFormatAction.deleteCourse(param)
      .then(res => {
        if (res) {
          this.getSettedCourseList()
        }
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render = () => {
    return (
      <div className='course-departmentspecific-format'>
        <Card title='コース・診療科別書式保守(経過観察用)'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                scroll={{ y: 600 }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.showFollowUpLetterDepartmentspecificFormat(record)
                    }
                  };
                }}
              >
                <Table.Column
                  title='コース'
                  dataIndex='course_code'
                  className='column-size-10'
                />
                <Table.Column
                  title='名称'
                  dataIndex='course_name'
                  className='column-size-60'
                />
                <Table.Column
                  title='書式'
                  dataIndex='format_name'
                  className='column-size-60'
                />
                <Table.Column
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        // 新規追加
                        this.showFollowUpLetterCourseEdit()
                      }}
                    />
                  }
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              onClick={() => {
                                this.showFollowUpLetterDepartmentspecificFormat(record)
                              }}
                            >
                              診療科設定
                            </Menu.Item>
                            <Menu.Item
                              key='2'
                              onClick={() => {
                                // 変更
                                this.showFollowUpLetterCourseEdit(record)
                              }}
                            >
                              コース編集
                            </Menu.Item>
                            <Menu.Item
                              key='3'
                              onClick={() => {
                                Modal.confirm({
                                  content: '削除してもよろしいですか？',
                                  onOk: () => this.deleteCourse(record)
                                })
                              }}
                            >
                              削除
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpLetterCourseDepartmentspecificFormat)
