import React from 'react'
import { connect } from 'react-redux'
import Color from 'constants/Color'
import PersonRequireExamIntroduceLetterAction from 'redux/IntroductionLetter/PersonRequireExamIntroduceLetter/PersonRequireExamIntroduceLetter.action.js'
import { Card, Table, Form } from 'antd'
import PropTypes from 'prop-types'
import { getScreenData } from 'redux/UserTools/LabelPrint/AddressLabelFormSelect.actions';


class WS1562001_FormSelect extends React.Component {

  static propTypes = {
    Li_JudgeLevel: PropTypes.number,
  }

  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '住所ラベル様式選択'

    this.state = {
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      loading: false,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getFormat()
  }

  /**
   * 判定一覧を取得
   */
  getFormat = () => {
    getScreenData()
      .then(res => {
        this.setState({
          dataSource: res.data,
        })
      })
      .catch(error => {
      })
  }

  render() {
    return (
      <div className='form-select'>
        <Card title='住所ラベル様式照会'>
          <Form
            ref={this.formRef}
          // onFinish={this.onFinish}
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => ({
                // 選択処理
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    // 親画面に反映
                    this.props.onFinishScreen({
                      recordData: record
                    })
                  }
                }
              })}
            >
              <Table.Column
                title='様式'
                dataIndex='formatNumber'
                className="column-size-5"
                style={{
                  textAlign: 'center',
                }}
              />
              <Table.Column
                title='名称'
                dataIndex='formatName'
              />
            </Table>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1562001_FormSelect)
