import axios from 'configs/axios'

const API_LIST = {
  getScreenData: '/api/sanai-medical-checkup-calendar/checkup-calendar-presentation/get-screen-data',
  save: '/api/sanai-medical-checkup-calendar/checkup-calendar-presentation/save',
  delete: '/api/sanai-medical-checkup-calendar/checkup-calendar-presentation/delete',
}

const MedicalCheckupCalendarService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
}

export default MedicalCheckupCalendarService
