import axios from 'configs/axios'

const API_LIST = {
  index: '/api/radiography-judge-maintain/radiography-judge-maintain',
  deleteRadioInfo: '/api/radiography-judge-maintain/radiography-judge-maintain/delete',
  saveRadioInfo: '/api/radiography-judge-maintain/radiography-judge-maintain/',
  // excelReport: '/api/radiography-judge-maintain/target-select-sub/user-action-2',
  // changeLogic: '/api/radiography-judge-maintain/target-select-sub/change-logic',
  // changeLogicAll: '/api/radiography-judge-maintain/target-select-sub/change-logic-all',
}

const RadiographyJudgeMaintainService = {
  async index() {
    // paramsの容量が大きい為、「post」を使用
    return axios.get(API_LIST.index)
  },

  // //確かめ方
  // //index(params) {
  // //  return "テスト"
  // //},

  async deleteRadioInfo(params) {
    console.log("サービスdel")
    return axios.post(API_LIST.deleteRadioInfo, params);
  },

  async saveRadioInfo(params) {
    console.log("サービスsave")
    return axios.post(API_LIST.saveRadioInfo, params);
  },
  // async excelReport() {
  //   return axios.post(API_LIST.excelReport, null, { responseType: 'blob' })
  // },

  // async changeLogicService(params) {
  //   return axios.post(API_LIST.changeLogic, params)
  // },

  // async changeLogicAllService(params) {
  //   return axios.post(API_LIST.changeLogicAll, params)
  // }
}

export default RadiographyJudgeMaintainService
