import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Button, Card, Col, Form, Row, Select, Space, Table, message, Switch, Typography } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import UserOptionInfoMaintainAction from "redux/SystemMaintenance/UserOptionInfoMaintain/UserOptionInfoMaintain.actions";
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import { generateUUID } from 'components/Commons/generateUUID'
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from 'components/Commons/ModalChange'
import ImplementorMasterAction from "redux/SpecificInsureMaintenance/ImplementorMaster/ImplementorMaster.action";

//共通コンポーネントModalAddで使用(新規・編集)
//一覧表の方 新規登録用
const columns = [
  {
    title: 'コード',
    dataIndex: 'option_code',
    type: 1,
  },

  {
    title: '名称',
    dataIndex: 'identification_name',
    type: 1,
  },

  {
    title: 'オプション',
    dataIndex: 'options',
    type: 1,
  },
]

//一覧表の方 編集用(コード非編集用)
const edit = [
  {
    title: 'コード',
    dataIndex: 'option_code',
    type: 0, //データ編集不可にする
  },

  {
    title: '名称',
    dataIndex: 'identification_name',
    type: 1,
  },

  {
    title: 'オプション',
    dataIndex: 'options',
    type: 1,
  }
]

//オプション変更の方
const lines = [
  {
    title: '項目',
    dataIndex: 'W1_item',
    type: 1,
  },

  {
    title: '内容',
    dataIndex: 'W1_content',
    type: 1,
  },

  {
    title: '補足説明',
    dataIndex: 'Description',
    type: 1,
  },
]

//オプション修正の方 編集用(項目非編集用)
const noChangeItem = [
  {
    title: '項目',
    dataIndex: 'W1_item',
    type: 0, //データ編集不可にする
  },

  {
    title: '内容',
    dataIndex: 'W1_content',
    type: 1,
  },

  {
    title: '補足説明',
    dataIndex: 'Description',
    type: 1,
  },
]

class WS1523001_UserOptionInfoMaintain extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_TypeCode: PropTypes.any,
    Li_OptionCode: PropTypes.any,
    Li_Expansion: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = 'ユーザーオプション情報保守'

    this.state = {
      pagination: {
        defaultPageSize: process.env.REACT_APP_PAGE_SIZE, //envに設定されている件数データを表示　上回ったらpagination表示するように
        size: 1,
        showQuickJumper: false, //何ページから何ページのやつを表示
        showSizeChanger: false //データ数の表示数変更するやつを表示
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
        centered: true,
      },

      OptionTypeList: [],
      OptionType: '',
      OptionTypeValue: 1,
      UserOptionSubject_Fixed: '',

      dataOptionCode: [],
      rowSelectOptionCode: undefined,
      isLoadOptionCode: true,

      dataOptionInput: [],
      rowSelectOptionInput: {},
      isLoadOptionInput: false,

      colLeft: 10,
      colRight: 14,
      isEdit: false,

      SearchScreenArray: [],

      isOptionCodeAdded: false,
      isOptionInputAdded: false,

      isSwitchOn: false,
      buttonColor: '#a0b9c8',
      isList: false,
    }
  }

  componentDidMount = () => {
    this.loadScreenData()
  }

  loadScreenData = () => {
    UserOptionInfoMaintainAction.getScreenData()
      .then(res => {
        if (res) {
          let dataAPI = res
          this.setState({
            OptionTypeList: dataAPI.result,
            OptionType: dataAPI.result[0].type_code,
            OptionTypeValue: dataAPI.result[0].id,
            UserOptionSubject_Fixed: dataAPI.UserOptionSubject_Fixed
          })
          this.formRef?.current?.setFieldsValue({
            OptionType: dataAPI.result[0].type_code,
          })
          const params = {
            OptionType: dataAPI.result[0].type_code,
            UserOptionSubject_Fixed: dataAPI.UserOptionSubject_Fixed
          }
          this.loadDataOptionCode(params)
        }
      })
      .finally(() => this.setState({ isLoadOptionInput: false }))
  }

  loadDataOptionCode(params) {
    UserOptionInfoMaintainAction.getDataOptionCode(params)
      .then(res => {
        if (res) {
          let data = res.resUO.map((item, index) => ({ ...item, id: item.parent_id, changed: false }))
          const rowSelectOptionCode = this.state.rowSelectOptionCode ?? data[0]

          this.setState({
            dataOptionCode: data,
            isOptionCodeAdded: false,
            isOptionInputAdded: false,
            rowSelectOptionCode,
          })

          if (rowSelectOptionCode) {
            const tmp = data.find(el => el.id == rowSelectOptionCode.id)
            if (tmp) {
              this.loadDataOptionInput(tmp)
            } else {
              this.loadDataOptionInput(data[0])
            }
          }

          // (削除でリロードしても更新されない対策)
          this.setState({ dataOptionCode: [] })
          this.setState(() => { },
            () => { this.setState({ dataOptionCode: data }) })
        }
      })
      .catch()
  }

  loadDataOptionInput(params) {
    UserOptionInfoMaintainAction.getDataOptionInput(params)
      .then(res => {
        if (res) {
          const data = { ...res, parent_id: params.parent_id } //res.data
          // 画面更新のために一度空にする
          this.setState({ dataOptionInput: [] })
          this.setState(() => { }, () => { this.setState({ dataOptionInput: data, isOptionInputAdded: false }) })
        }
      })
      .catch()
  }

  createOrUpdateData = (record) => {
    UserOptionInfoMaintainAction.saveOrUpdateDataOptionCode(record)
      .then(res => {
        if (!res) { //resの値がundefinedの時、Action側のみの処理を行わせる。message.success(res.message)はresの値が無いと画面をフリーズさせる
          console.log(res)
        } else {
          message.success(res.message)
          const params = {
            OptionType: this.state.OptionType,
            UserOptionSubject_Fixed: this.state.UserOptionSubject_Fixed
          }
          this.loadDataOptionCode(params)
        }
      })
  }

  deleteData = (record) => {
    if (record?.id) {
      UserOptionInfoMaintainAction.deleteOptionCode(record)
        .then(res => {
          if (!res) { //resの値がundefinedの場合、catchで例外として掴んでやると一応画面のフリーズはしないが、Action側でもcatchを回しているため、2回エラー表示が出る
            console.log(res)
          } else {
            message.success(res.message)
            const params = {
              OptionType: this.state.OptionType,
              UserOptionSubject_Fixed: this.state.UserOptionSubject_Fixed
            }
            this.loadDataOptionCode(params)
          }
        })
    }
  }

  //オプション修正での削除用
  deleteOption = (record) => {
    UserOptionInfoMaintainAction.deleteOptionalItems(record)
      .then(res => {
        if (!res) {
          console.log(res)
        } else {
          message.success(res.message)
          const params = {
            OptionType: this.state.OptionType,
            UserOptionSubject_Fixed: this.state.UserOptionSubject_Fixed
          }
          this.loadDataOptionCode(params)
        }
      })
      .catch(err => message.error(err.message))
  }

  //オプション修正で連番毎に情報を更新する用
  updateOption = (record) => {
    UserOptionInfoMaintainAction.updateOption(record)
      .then(res => {
        if (!res) {
          console.log(res)
        } else {
          message.success(res.message)
          const params = {
            OptionType: this.state.OptionType,
            UserOptionSubject_Fixed: this.state.UserOptionSubject_Fixed
          }
          this.loadDataOptionCode(params)
        }
      }).catch(err => message.error(err.message))
  }

  handleChangSelect = (value) => {
    let index = this.state.OptionTypeList.findIndex(item => item.id === value)

    if (index !== -1) {
      this.setState({
        OptionType: this.state.OptionTypeList[index].type_code
      }, () => {
        this.setState({ dataOptionInput: [] })
        this.loadDataOptionCode({
          OptionType: this.state.OptionTypeList[index].type_code,
          UserOptionSubject_Fixed: this.state.UserOptionSubject_Fixed
        })
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  tableRowClick = (record) => {
    this.setState({ rowSelectOptionCode: record })
    if (!this.state.isList && record !== this.state.rowSelectOptionCode)
      this.loadDataOptionInput(record)
  }

  // 新規追加:一覧モード
  addNewRowDataOptionCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <ModalAdd
            columns={columns}
            onFinishScreen={(output) => {
              //こっちは一覧表の方
              const newdata = {
                id: generateUUID(),
                type_code: this.state.OptionType,
                option_code: output.itemChange.option_code,
                identification_name: output.itemChange.identification_name,
                options: output.itemChange.options,
                changed: false
              }
              this.createOrUpdateData(newdata)
              this.closeModal()
            }}
          />
        )
      },
    })

  }

  // 新規追加：オプション修正モード
  addNewRowDataOptionInput = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <ModalAdd
            columns={lines}
            onFinishScreen={(output) => {
              //こちらはオプション変更の方
              const newdata = { //ここDBにデータが入るべきカラムと値入れとかないとDBに登録されず、表示処理がおかしくなる
                id: this.state.rowSelectOptionCode.id,
                type_code: this.state.OptionType,
                option_code: this.state.rowSelectOptionCode.option_code,
                identification_name: this.state.rowSelectOptionCode.identification_name,
                options: this.state.rowSelectOptionCode.options, //一覧表から列選択時にoptionsの値を取ってくるため必須
                W1_serial_num: output.itemChange.W1_serial_num,
                W1_content: output.itemChange.W1_content,
                W1_item: output.itemChange.W1_item,
                Description: output.itemChange.Description,
                changed: false,
                parent_id: this.state.rowSelectOptionCode.parent_id, //sy2110UserOptions.phpにて
                child_id: this.state.rowSelectOptionCode.child_id, //parent_id,child_idに値が無いとinsert処理を行う。値があればupdate処理
              }
              this.createOrUpdateData(newdata)
              this.closeModal()
            }}
          />
        )
      },
    })
  }

  render() {
    const capturedDataOptionCode = this.state.dataOptionCode.filter(item => {
      return item.type_code == this.state.OptionType
    })

    const { parent_id, ...dataOptionInput } = this.state.dataOptionInput
    const optionInput = Object.values(dataOptionInput)
    const row = this.state.dataOptionCode.filter(item => item.parent_id == parent_id)

    return (
      <div className='user-option-info-maintain'>
        <Form ref={this.formRef} initialValues={{ OptionType: this.state.OptionTypeValue }}>
          <Card
            title={
              <Space>
                <div>ユーザーオプション情報保守</div>
              </Space>
            }
            className='mb-2'>
            <Space className='mb-3'>
              <Form.Item name='OptionType' label='種別'>
                <Select style={{ width: '150px' }} onChange={this.handleChangSelect}>
                  {this.state.OptionTypeList?.map((option) => (
                    <Select.Option key={`OptionType-${option.id}`} value={option.id}>{option.type_code}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Switch checkedChildren="一覧" unCheckedChildren="一覧"
                style={{ marginTop: '-6px', background: this.state.buttonColor }}
                onClick={(checked) => { this.setState({ buttonColor: this.state.buttonColor === '#a0b9c8' ? '#419087' : '#a0b9c8', isList: checked }) }}
                defaultValue={false}
              />

            </Space>
            <div className='box_inner_horizontal'>
              <div style={{ width: this.state.isList ? '100%' : '30%' }}>
                <Table
                  bordered
                  size='small'
                  dataSource={capturedDataOptionCode}
                  //dataSourceの大きさよりも小さいならpaginationを表示させないように　全てボタンを押した際にも表示させないように
                  pagination={this.state.isSwitchOn || capturedDataOptionCode.length > process.env.REACT_APP_PAGE_SIZE ? false : this.state.pagination}
                  scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                  rowKey={(record) => record.id}
                  rowClassName={(record, index) => record.id === this.state.rowSelectOptionCode?.id ? 'table-row-light' : ''}
                  onRow={(record, index) => ({
                    onClick: event => {
                      this.tableRowClick(record)
                    }
                  })}
                >
                  <Table.Column
                    title='ｺｰﾄﾞ'
                    dataIndex='option_code'
                    width={this.state.isList ? '10%' : '40%'}
                    render={(text, record) => (
                      <Typography.Text>{text || record.format}</Typography.Text>
                    )}
                  />
                  <Table.Column
                    title='名称'
                    dataIndex='identification_name'
                    width={this.state.isList ? '20%' : '53%'}
                    render={(text, record) => (
                      <Typography.Text>{text || record.format}</Typography.Text>
                    )}
                  />

                  {this.state.isList ? <Table.Column
                    title='ｵﾌﾟｼｮﾝ'
                    dataIndex='options'
                    width='68%'
                    render={(text, record) => (
                      <Typography.Text>{text || record.format}</Typography.Text>
                    )}

                  /> : null}

                  <Table.Column
                    width={this.state.isList ? '2%' : '7%'}
                    align='center'
                    fixed='right'
                    title={
                      <Button
                        size='small'
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => this.addNewRowDataOptionCode()} //一覧表の方
                      />}
                    render={(text, record, index) => (
                      <>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          onClick={() => {
                            ImplementorMasterAction.GetTreeData(record.id)
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '550px',
                                className: '',
                                component:
                                  //ポップアップ表示
                                  <ModalChange
                                    columns={edit}
                                    record={record}
                                    onUpdate={(output) => {
                                      const updateData = {
                                        parent_id: record.parent_id, //sy2110UserOptions.phpにて
                                        child_id: record.child_id, //parent_id,child_idに値が無いとinsert処理を行う。値があればupdate処理
                                        id: record.id,
                                        type_code: this.state.OptionType,
                                        option_code: output.itemChange.option_code,
                                        identification_name: output.itemChange.identification_name,
                                        options: output.itemChange.options,
                                      }
                                      this.createOrUpdateData(updateData)
                                      this.closeModal()
                                    }}
                                    onDelete={(output) => {
                                      this.deleteData(record);
                                      this.closeModal()
                                    }}
                                  />
                              },
                            })
                          }}
                        />
                      </>
                    )}
                  />

                </Table>
                <Row>
                  <Col span={23}></Col>
                  <Col span={1}>
                    <Space>
                      <Switch style={{ marginTop: '10px' }}
                        checkedChildren="全て"
                        defaultChecked={false}
                        onClick={(checked) => { this.setState({ isSwitchOn: checked }) }}></Switch>
                    </Space>
                  </Col>
                </Row>
              </div>

              {
                this.state.isList ? null :
                  <div style={{ width: '70%' }}>
                    <Table
                      bordered
                      size='small'
                      dataSource={optionInput}
                      pagination={optionInput.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                      scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                      rowKey={(record, i) => i}
                      onRow={(record, index) => ({ onClick: event => this.setState({ rowSelectOptionInput: record }) })}
                    >
                      <Table.Column
                        title='連番'
                        dataIndex='W1_serial_num'
                        width={80}
                        render={(text, record, index) => {
                          return <Typography.Text>{text || record.format}</Typography.Text>
                        }}
                      />
                      <Table.Column
                        title='項目'
                        dataIndex='W1_item'
                        render={(text, record, index) => {
                          return <Typography.Text>{text || record.format}</Typography.Text>
                        }}
                      />
                      <Table.Column
                        title='内容'
                        dataIndex='W1_content'
                        render={(text, record, index) => {
                          return <Typography.Text>{text || record.format}</Typography.Text>
                        }}
                      />
                      <Table.Column
                        title='補足説明'
                        dataIndex='Description'
                        render={(text, record, index) => {
                          return <Typography.Text>{text || record.format}</Typography.Text>
                        }}
                      />
                      <Table.Column
                        width={40}
                        align='center'
                        fixed='right'
                        title={
                          <Button
                            size='small'
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                              this.addNewRowDataOptionInput() //オプション変更の方
                            }}
                          />}
                        render={(value, record) => (
                          <>
                            <Button
                              size='small'
                              icon={<MoreOutlined />}
                              onClick={() => {
                                ImplementorMasterAction.GetTreeData(record.id)
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '550px',
                                    className: '',
                                    component:
                                      //ポップアップ表示
                                      <ModalChange
                                        columns={noChangeItem}
                                        record={record}
                                        onUpdate={(output) => {
                                          const updateData = {
                                            parent_id: this.state.rowSelectOptionCode.parent_id, //sy2110UserOptions.phpにて
                                            child_id: this.state.rowSelectOptionCode.child_id, //parent_id,child_idに値が無いとinsert処理を行う。値があればupdate処理
                                            id: record.id,
                                            type_code: this.state.OptionType,
                                            option_code: this.state.rowSelectOptionCode.option_code,
                                            options: this.state.rowSelectOptionCode.options,
                                            W1_serial_num: output.itemChange.W1_serial_num, //連番
                                            W1_content: output.itemChange.W1_content, //内容
                                            W1_item: output.itemChange.W1_item, //項目
                                            Description: output.itemChange.Description, //補足説明
                                          }
                                          this.updateOption(updateData)
                                          this.closeModal()
                                        }}
                                        onDelete={(output) => {
                                          const deleteData = {
                                            parent_id: this.state.rowSelectOptionCode.parent_id, //sy2110UserOptions.phpにて
                                            child_id: this.state.rowSelectOptionCode.child_id, //parent_id,child_idに値が無いとinsert処理を行う。値があればupdate処理
                                            id: record.id,
                                            type_code: this.state.OptionType,
                                            option_code: this.state.rowSelectOptionCode.option_code,
                                            options: this.state.rowSelectOptionCode.options,
                                            W1_serial_num: output.itemChange.W1_serial_num, //連番
                                            W1_content: output.itemChange.W1_content, //内容
                                            W1_item: output.itemChange.W1_item, //項目
                                            Description: output.itemChange.Description, //補足説明
                                          }
                                          this.deleteOption(deleteData)
                                          this.closeModal()
                                        }}
                                      />
                                  },
                                })
                              }}
                            />
                          </>
                        )}
                      />
                    </Table>
                  </div>
              }
            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1523001_UserOptionInfoMaintain)
