import axios from "configs/axios";

const APP_LIST = {
  getScreenData: "/api/insure-guide-init-input/vouchers-input/get-screen-data",
  getContractData: "/api/insure-guide-init-input/vouchers-input/get-contract-data",
  contractSelect: "/api/insure-guide-init-input/vouchers-input/contract-select",
  onsave: "/api/insure-guide-init-input/vouchers-input/on-save",
};

const VouchersInputService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async getContractData(params) {
    return axios.get(APP_LIST.getContractData, { params });
  },
  async contractSelect(params) {
    return axios.get(APP_LIST.contractSelect, { params });
  },
  async onsave(params) {
    return axios.post(APP_LIST.onsave, params);
  },
};

export default VouchersInputService;
