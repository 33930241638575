import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Menu, message, Select, Button, Popconfirm } from 'antd'
import { DeleteOutlined, SaveOutlined, } from '@ant-design/icons'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import MiraisInspectMaintainAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisInspectMaintain.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS2751100_MiraisInspectMaintainAdd extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'Mirais検査保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRow: {},
      count: 'a'
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (!this.props.newFlag) {
      this.formRef.current?.setFieldsValue(this.props.record)
    }
  }

  Save() {
    let getFieldValue = this.formRef.current?.getFieldValue()
    if (getFieldValue?.medical_exam_exam_code === undefined) {
      return message.error('健診検査は必須項目です')
    }
    if (getFieldValue?.kind === undefined) {
      return message.error('種別は必須項目です')
    }
    let params = {
      id: this.props?.record?.id ? this.props.record.id : null,
      medical_exam_exam_code: getFieldValue?.medical_exam_exam_code,
      kind: getFieldValue?.kind,
      reservations_department: getFieldValue?.reservations_department,
      reservations_item_code: getFieldValue?.reservations_item_code,
      test_item_code: getFieldValue?.test_item_code,
      remarks: getFieldValue?.remarks,
    }
    MiraisInspectMaintainAction.SaveData(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  Delete() {
    //delete DB  
    let params = {
      id: this.props.record.id
    }
    MiraisInspectMaintainAction.Delete(params).then(res => {
      this.props.onFinishScreen()
    }).catch(error => {
      const res = error.response
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました')
        return
      }
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='mirais-inspect '>
        <Card title={this.props.newFlag ? 'Mirais検査保守追加' : 'Mirais検査保守編集'}>
          <Form ref={this.formRef} autoComplete='off'>
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <div className='box_inner_vertical'>
                  <div className='box_inner_horizontal'>
                    <Form.Item label='健診検査' name='medical_exam_exam_code' {...smGrid}>
                      <Input.Search style={{ width: 120, textAlign: 'right' }}
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 850,
                              component: (
                                <WS0271001_InspectItemSearchQuerySingle
                                  InspectCode={this.formRef.current?.getFieldValue('medical_exam_exam_code')}
                                  onFinishScreen={(output) => {
                                    console.log(output.recordData)
                                    this.formRef.current?.setFieldsValue({
                                      medical_exam_exam_code: output.recordData.test_item_code,
                                      FO_exam_name: output.recordData.exam_name
                                    })
                                    this.forceUpdate()
                                    this.closeModal()
                                  }}
                                />
                              ),
                            }
                          })
                        }} />
                    </Form.Item>
                    <div style={{ marginTop: '5px' }}>{this.formRef.current?.getFieldValue('FO_exam_name')}</div>
                  </div>
                  <Form.Item label='種別' name='kind' {...smGrid}>
                    <Select style={{ width: '120px' }}>
                      <Select.Option value={'00'}>予約</Select.Option>
                      <Select.Option value={'60'}>検査</Select.Option>
                      <Select.Option value={'70'}>画像</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className='box_inner_vertical'>
                  <div className='box_inner_horizontal'>
                    <Form.Item label='予約科項目' name='reservations_department' {...smGrid}>
                      <Input className='input-size-number-2' />
                    </Form.Item>
                    <Form.Item name='reservations_item_code'>
                      <Input className='input-size-number-5' />
                    </Form.Item>
                  </div>
                  <div className='box_inner_horizontal'>
                    <Form.Item label='項目コード' name='test_item_code' {...smGrid}>
                      <Input className='input-size-number-6' />
                    </Form.Item>
                    <div>{this.formRef.current?.getFieldValue('FU_exam_name')}</div>
                  </div>
                </div>
              </div>
              <Form.Item label='備考' name='remarks' {...smGrid}>
                <Input className='input-size-60' />
              </Form.Item>
            </div>
            <div className='box_button_bottom_right'>

              {!this.props.newFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.Delete()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type='primary'
                    icon={<DeleteOutlined />}
                  >
                    <span className='btn_label'>
                      削除
                    </span>
                  </Button>
                </Popconfirm>
                : null
              }
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() =>
                  this.Save()
                }
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2751100_MiraisInspectMaintainAdd)
