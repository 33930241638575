import axios from 'configs/axios';

const apiPaths = {
  registerData: '/api/inspect-cmt-info-maintain/inspect-cmt-info-maintain-sub/register-data',
  deleteData: '/api/inspect-cmt-info-maintain/inspect-cmt-info-maintain-sub/delete-data'
};

const InspectCmtInfoMaintainSubService = {
  async registerDataService(params) {
    return axios.put(apiPaths.registerData, params);
  },
  async deleteDataService(params) {
    return axios.delete(apiPaths.deleteData, { params })
  },
};
export default InspectCmtInfoMaintainSubService;