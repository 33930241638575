import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/label-print/address-label-form-select/get-screen-data',
};

const AddressLabelFormSelectService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
};

export default AddressLabelFormSelectService;