import { message } from "antd";
import TransmissionCaptureConfirmService from "services/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/TransmissionCaptureConfirmService";


const TransmissionCaptureConfirm = {
    getScreenData(params) {
      return TransmissionCaptureConfirmService.getScreenData(params)
        .then((res) => {
          return res?.data;
        })
        .catch((err) => {
          const res = err.response;
          if (!res || !res.data || !res.data.message) {
            message.error("エラーが発生しました");
            return;
          }
          message.error(res.data.message);
        });
    },  
    
    // updateData(data) {
    //   return RequestCsvOutputService.updateData(data)
    // }
  }

  export default TransmissionCaptureConfirm;  
