import React from "react";
import { connect } from "react-redux";

import { Card, Table, message, Button, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ImplementLocationQueryAction from "redux/SpecificInsureMaintenance/InsureGuideCourse/ImplementLocationQuery.action.js"

class WS1388001_ImplementLocationQuery extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00180:実施場所照会';

    this.state = {
      dataSource: [],
      selectedRows: [],
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    this.setState({ isLoading: true });
    ImplementLocationQueryAction.getScreenData()
      .then(res => {
        this.setState({ dataSource: res });
      }).catch(error => {
        message.error("エラーが発生しました");
      }).finally(
        this.setState({ isLoading: false })
      );
  }

  render() {
    return (
      <div className="implement-location-query">
        <Card title="V4-TSB00180:実施場所照会">
          <Table
            bordered
            size="small"
            dataSource={this.state.dataSource ? this.state.dataSource : []}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => { },
              onShowSizeChange: (page, pageSize) => { },
            }}

            // rowSelection={{
            //   type: 'radio',
            //   onChange: async (selectedRowKeys, selectedRows) => {
            //     await this.setState({
            //       selectedRow: selectedRows[0]
            //     });
            //   }
            // }}
            onRow={(record, index) => ({
              onClick: () => {
                this.setState({
                  ...this.state.selectedRows,
                  selectedRows: record,
                });
              },
              onDoubleClick: () => {
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    implement_location: this.state.selectedRows.implement_location,
                    implement_location_code: this.state.selectedRows.implement_location_code,
                  });
                }
              },
            })
            }
            rowKey={(record) => record.id}
          >
            <Table.Column
              title="コード"
              dataIndex="implement_location_code"
              showSorterTooltip={false}
              key="implement_location_code"
            />

            <Table.Column
              title="実施場所"
              dataIndex="implement_location"
              showSorterTooltip={false}
              key="implement_location"
            />

            <Table.Column
              title="実施区分"
              dataIndex="implement_division"
              showSorterTooltip={false}
              key="implement_division"
            />

          </Table>
          {/* <Button type="primary" style={{ float: 'right' }}
            onClick={() => {
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen({
                  implement_location_code: this.state.selectedRow ? this.state.selectedRow?.implement_location_code : null,
                  implement_location: this.state.selectedRow ? this.state.selectedRow?.implement_location : null,
                });
              }
            }}
          >
            選択
          </Button> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1388001_ImplementLocationQuery);
