import { Card, Dropdown, Form, Input, Menu, message, Modal, Select, Space, Table, Button, Tooltip } from 'antd'
import { CopyOutlined, DeleteOutlined, MoreOutlined, PlusOutlined, SaveOutlined, ScissorOutlined, SearchOutlined, SnippetsOutlined, MenuOutlined } from '@ant-design/icons'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS2751100_MiraisInspectMaintainAdd from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2751100_MiraisInspectMaintainAdd.jsx'
import WS2751002_InspectMaintain from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2751002_InspectMaintain.jsx'
import WS2752001_MiraisReserveMaintain from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2752001_MiraisReserveMaintain.jsx'
import WS2753001_MiraisImplementorMaintain from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2753001_MiraisImplementorMaintain.jsx'
import React from 'react'
import { connect } from 'react-redux'
import MiraisInspectMaintainAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisInspectMaintain.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS2751001_MiraisInspectMaintain extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'Mirais検査保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRow: {},
      count: 'a'
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      tableData: []
    })
    this.GetListData()
  }

  GetListData() {
    this.setState({ loaddingTable: true })
    MiraisInspectMaintainAction.GetListData()
      .then(res => {
        // this.formRef.current?.setFieldsValue({
        //   tableData: res
        // })
        this.setState({
          selectedRow: {},
          dataSource: res
        })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      }).finally(() => this.setState({ loaddingTable: false }))
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  F8() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS2751002_InspectMaintain
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  F9() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2752001_MiraisReserveMaintain
            reservations_item_code={this.state.selectedRow?.reservations_item_code}
            remarks={this.state.selectedRow?.remarks}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  F10() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2753001_MiraisImplementorMaintain
            reservations_item_code={this.state.selectedRow?.reservations_item_code}
            remarks={this.state.selectedRow?.remarks}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  Insert(record) {
    let arr = [...this.formRef.current?.getFieldValue('tableData')]
    for (const objValue of arr) {
      if (objValue.id === record.id) {
        if (this.isEmpty(objValue.medical_exam_exam_code)) {
          return
        } else {
          if (isNaN(record.id)) {
            let objSave = {
              medical_exam_exam_code: objValue.medical_exam_exam_code,
              kind: objValue.kind,
              test_item_code: objValue.test_item_code,
              reservations_department: objValue.reservations_department,
              reservations_item_code: objValue.reservations_item_code,
              remarks: objValue.remarks
            }
            this.Save(objSave)
          } else {
            this.Save(objValue)
          }
        }
      }
    }
  }

  Save(data) {
    this.setState({ loaddingTable: true })
    MiraisInspectMaintainAction.SaveData(data)
      .then(res => {
        this.GetListData()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  Delete(record) {
    let arr = [...this.formRef.current?.getFieldValue('tableData')]
    if (isNaN(record.id)) {
      arr.map((value, index) => {
        if (value.id === record.id) {
          arr.splice(index, 1)
          this.formRef.current?.setFieldsValue({
            tableData: arr
          })
          this.forceUpdate()
        }
      })
    } else {
      //delete DB  
      this.setState({ loaddingTable: true })
      MiraisInspectMaintainAction.Delete({ id: record.id }).then(res => {
        this.GetListData()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      }).finally(() => this.setState({ loaddingTable: false }))
    }
  }

  AddNewData() {
    this.setState({ loaddingTable: true })
    const { count } = this.state
    const newData = {
      id: count,
      medical_exam_exam_code: '',
      FO_exam_name: '',
      kind: '00',
      reservations_department: '',
      reservations_item_code: '',
      name: '',
      test_item_code: '',
      FU_exam_name: '',
      remarks: ''
    }
    let data = [...this.formRef.current?.getFieldValue('tableData')]
    data.length > 0 ? data.unshift(newData) : data.push(newData)
    this.formRef.current?.setFieldsValue({
      tableData: data,
    })
    this.forceUpdate()
    this.setState({
      ...this.state,
      count: count + 1,
      loaddingTable: false
    })
  }

  classification(value) {
    switch (value) {
      case '00':
        return '予約'
      case '60':
        return '検査'
      case '70':
        return '画像'
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      loaddingTable: false
    })
  }

  render() {
    return (
      <div className='mirais-inspect '>
        <Card title='Mirais検査保守'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top_modal'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.F8()}>
                      検査保守
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={() => this.F9()}>
                      予約保守
                    </Menu.Item>
                    <Menu.Item key='3' style={{ width: '100%' }} onClick={() => this.F10()}>
                      実施者保守
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          {/* <Space>
            <Button onClick={() => this.F8()} >検査保守</Button>
            <Button onClick={() => this.F9()} >予約保守</Button>
            <Button onClick={() => this.F10()} >実施者保守</Button>
          </Space>
          <hr style={{ margin: '15px 0' }} /> */}
          <Form ref={this.formRef} autoComplete='off'  >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              scroll={{ y: 600, x: 'max-content' }}
              pagination={false}
              bordered={true}
              rowKey={(record) => record.id}
            >
              <Table.Column
                title='健診検査'
                dataIndex='medical_exam_exam_code'
                render={(value, record, index) => {
                  return <div className='box_inner_horizontal'>
                    <div>{record.medical_exam_exam_code}</div>
                    <div>{record.FO_exam_name}</div>
                  </div>
                }}
              />
              <Table.Column
                title='種別'
                dataIndex='kind'
                className='column-size-5'
                render={(value, record, index) => {
                  return <div style={{ textAlign: 'center' }}>{this.classification(value)}</div>
                }}
              />
              <Table.Column
                title='予約科項目'
                render={(value, record, index) => {
                  return <div className='box_inner_horizontal'>
                    <div>{record.reservations_department}</div>
                    <div>{record.reservations_item_code}</div>
                    <div>{record.name}</div>
                  </div>
                }}
              />
              <Table.Column
                title='項目コード'
                render={(value, record, index) => {
                  return <div className='box_inner_horizontal'>
                    <div>{record.test_item_code}</div>
                    <div>{record.FU_exam_name}</div>
                  </div>
                }}
              />
              <Table.Column
                title='備考'
                width={200}
                dataIndex='remarks'
              />
              <Table.Column
                width={40}
                align='center'
                fixed='right'
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS2751100_MiraisInspectMaintainAdd
                              newFlag={true}
                              onFinishScreen={(output) => {
                                this.GetListData()
                                this.closeModal()
                              }}
                            />
                          ),
                        }
                      })
                    }
                  />}
                render={(text, record, index) =>
                  <Button size='small'
                    icon={<MoreOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS2751100_MiraisInspectMaintainAdd
                              newFlag={false}
                              record={record}
                              onFinishScreen={(output) => {
                                this.GetListData()
                                this.closeModal()
                              }}
                            />
                          ),
                        }
                      })
                    }
                  />
                }
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2751001_MiraisInspectMaintain)
