import ReservesDisplayItemSettingService from "services/SM_SystemMaintenance/ReservesDisplayItemSetting/ReservesDisplayItemSettingService";

//初期データチェック
export const checkDataExistenceAction = () => {
  return ReservesDisplayItemSettingService.checkDataExistenceAction()
}

//表示項目一覧の取得
export const getReserveCourseItem = () => {
  return ReservesDisplayItemSettingService.getReserveCourseItem()
}

export const getReserveInspectItem = () => {
  return ReservesDisplayItemSettingService.getReserveInspectItem()
}

export const getReserveInspectGroupItem = () => {
  return ReservesDisplayItemSettingService.getReserveInspectGroupItem()
}

//コースSub
export const registerCourseDataAction = (params) => {
  return ReservesDisplayItemSettingService.registerCourseDataAction(params)
}

export const getCourseItems = (params) => {
  return ReservesDisplayItemSettingService.getCourseItems(params)
}

export const getTargetCourse = (params) => {
  return ReservesDisplayItemSettingService.getTargetCourse(params)
}

//検査Sub
export const registerInspectDataAction = (params) => {
  return ReservesDisplayItemSettingService.registerInspectDataAction(params)
}

export const getInspectItems = (params) => {
  return ReservesDisplayItemSettingService.getInspectItems(params)
}

export const getTargetInspect = (params) => {
  return ReservesDisplayItemSettingService.getTargetInspect(params)
}


// グループ
export const saveReserveInspectGroupItem = (params) => {
  return ReservesDisplayItemSettingService.saveReserveInspectGroupItem(params)
}

export const deleteReserveInspectGroupItem = (params) => {
  return ReservesDisplayItemSettingService.deleteReserveInspectGroupItem(params)
}
