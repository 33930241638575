import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Card, Form, Input, Checkbox, Button, Space, message } from "antd";

import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1291001_XmlDocumentQuerySelectSub from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1291001_XmlDocumentQuerySelectSub";
import XmlDocumentQuerySelectSubAction from "redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/XmlDocumentQuerySelectSub.action";
import { download_file } from 'helpers/CommonHelpers'

const dateFormat = "YYYY/MM/DD";
const smGrid = {
  labelCol: { style: { width: 50 } },
  wrapperCol: { style: { width: 'calc(100% - 50px)' } }
}

class WS2655078_OutputConfirm extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '出力確認';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      xmlData: [],
      xmlDataDefault: null,
      isLoadingTable: false,
      isChecked: null,
      GcreationDateChars: moment(new Date()),
      GsubmissionDateChars: moment(new Date()),
      InputFile: '',
      numberCount: 1,
      StsOutput: false,
      ProcessDivision: 0,
      SettlementPresence: true,
      VisitsPresence: true
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.loadTable()
    this.setState({
      ...this.state,
      isChecked: (!this.props.Lio_SettlementPresence) ? true : false,
      SettlementPresence: this.props.Lio_SettlementPresence,
      VisitsPresence: this.props.Lio_VisitsPresence
    });
  }

  loadTable() {
    this.setState({ isLoadingTable: true });

    let param = {
      Li_SpecificAndHealthGuideAndAll: 1
    }
    XmlDocumentQuerySelectSubAction.GetScreenData(param)
      .then(res => {
        const xmlDataDefault = res.find(item => item.format === "H30000000003");
        if (xmlDataDefault !== undefined) {
          this.formRef.current?.setFieldsValue({
            Lio_OutputDocument: xmlDataDefault.format
          });
        }
        this.setState({
          ...this.state,
          xmlData: res,
          xmlDataDefault,
          isChecked: (!this.props.Lio_SettlementPresence) ? true : false,
          SettlementPresence: this.props.Lio_SettlementPresence,
          VisitsPresence: this.props.Lio_VisitsPresence
        });
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  onChange(event) {
    let checked = event.target.checked;
    this.setState({
      SettlementPresence: checked,
      isChecked: !checked ? true : false
    })
    this.formRef.current?.setFieldsValue({ SettlementPresence: checked, isChecked: !checked ? true : false })
  }

  checkBoxChange(event) {
    let checked = event.target.checked;
    this.setState({
      VisitsPresence: checked
    })
    this.formRef.current?.setFieldsValue({ VisitsPresence: checked })
  }

  changeDate(event, name) {
    if (name === 'GcreationDateChars') {
      let date = event;
      this.setState({
        GcreationDateChars: date
      });
    } else {
      let date = event;
      this.setState({
        GsubmissionDateChars: date
      });
    }
  }

  changeSending(event) {
    let number = event.target.value;
    this.setState({
      numberCount: number
    });
  }

  xmlCheck() {
    // Lio_Outputはthis.state.InputFileを設定する
    let stsM40 = 0;
    const xmlDataDefault = this.state.xmlData.find(item => item.format === this.formRef.current?.getFieldValue('Lio_OutputDocument'));
    const GcreationDateChars = this.formRef.current?.getFieldValue('GcreationDateChars');
    const GsubmissionDateChars = this.formRef.current?.getFieldValue('GsubmissionDateChars');
    const Lio_NumTransmissions = this.formRef.current?.getFieldValue('Lio_NumTransmissions');

    if (!GcreationDateChars || !GsubmissionDateChars) {
      message.error('日付を入力して下ださい')
      return
    }
    if (!Lio_NumTransmissions) {
      message.error('送信回数を入力してください')
      return
    }
    if (xmlDataDefault === undefined) {
      message.error('帳票を選択してください')
      stsM40 = 1
      return
    }

    const param = {
      Lio_ProcessDivision: this.state.ProcessDivision,
      StsM40: stsM40,
      XmlCheckVersion: this.props.XmlCheckVersion,
      Lio_SettlementPresence: this.state.SettlementPresence,
      Lio_VisitsPresence: this.state.VisitsPresence,
      Lio_OutputDocument: this.formRef.current?.getFieldValue('Lio_OutputDocument'),
      GsubmissionDateChars: this.state.GsubmissionDateChars.format(dateFormat),
      GcreationDateChars: this.state.GcreationDateChars.format(dateFormat),
      Lio_Output: this.props.Goutput,
      Lio_NumTransmissions: Lio_NumTransmissions,
      selectRows: this.props.selectRows
    }

    XmlDocumentQuerySelectSubAction.xmlCheck(param)
      .then(async res => {
        if (res !== undefined) {
          // thenの後でもparamの値が先に変わってしまうから
          let paramCopy = { ...param }
          paramCopy.Lo_StsOutput = res.Lo_StsOutput;
          paramCopy.Lio_ProcessDivision = res.V1ProcessDivision1ContentCheck2Create;

          if (res.errorCsv) {
            await this.downloadCsv()
            await this.props.onFinishScreen(paramCopy)
          } else {
            this.props.onFinishScreen(paramCopy)
          }
        }
      })
  }

  XmlCreate() {
    // Lio_Outputはthis.state.InputFileを設定する
    let stsM40 = 0;
    const GcreationDateChars = this.formRef.current?.getFieldValue('GcreationDateChars');
    const GsubmissionDateChars = this.formRef.current?.getFieldValue('GsubmissionDateChars');
    const Lio_NumTransmissions = this.formRef.current?.getFieldValue('Lio_NumTransmissions');
    if (!GcreationDateChars || !GsubmissionDateChars) {
      message.error('日付を入力して下ださい')
      return
    }
    if (!Lio_NumTransmissions) {
      message.error('送信回数を入力してください')
      return
    }
    if (!this.formRef.current?.getFieldValue('Lio_OutputDocument')) {
      message.error('帳票を選択してください')
      stsM40 = 1
      return
    }

    const param = {
      GcreationDateChars: this.state.GcreationDateChars.format(dateFormat),
      GsubmissionDateChars: this.state.GsubmissionDateChars.format(dateFormat),
      StsM40: stsM40,
      Lio_Output: this.props.Goutput,
      Lio_NumTransmissions: Lio_NumTransmissions,
      Lio_FilingDate: this.state.GcreationDateChars.format(dateFormat),
      Lio_CreationDate: this.state.GsubmissionDateChars.format(dateFormat),
      Lo_StsOutput: this.state.StsOutput,
      Lio_ProcessDivision: this.state.ProcessDivision,
      GoutputForm: this.formRef.current?.getFieldValue('Lio_OutputDocument'),
      Lio_SettlementPresence: this.state.SettlementPresence,
      Lio_VisitsPresence: this.state.VisitsPresence
    }

    XmlDocumentQuerySelectSubAction.XmlCreate(param)
      .then(res => {
        // thenの後でもparamの値が先に変わってしまうから
        if (res !== undefined) {
          let paramCopy = { ...param }
          paramCopy.Lo_StsOutput = res.Lo_StsOutput;
          paramCopy.Lio_ProcessDivision = res.V1ProcessDivision1ContentCheck2Create;
          this.props.onFinishScreen(paramCopy)
        }
      })
  }

  downloadCsv(res) {
    let selectRows = this.props.selectRows

    let list = ''
    for (let i = 0; i < selectRows.length; i++) {
      list = list + selectRows[i].reservation_number + ','
    }

    const param = {
      selectRows: list
    }

    XmlDocumentQuerySelectSubAction.downloadCSVFile(param)
      .then(res => {
        if (res.data.message) {
          // Modal.warning({
          //   title: res.data.message,
          //   width: 300,
          // });
        } else {
          download_file(res);
          message.success("エラー項目をダウンロードしました。");
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className="output-confirm">
        <Card title="出力確認">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              Lio_OutputDocument: this.state.xmlDataDefault
                ? this.state.xmlDataDefault.format
                : null,
              Lio_SettlementPresence: this.props.Lio_SettlementPresence,
              Lio_VisitsPresence: this.props.Lio_VisitsPresence,
              GcreationDateChars: moment(new Date()),
              GsubmissionDateChars: moment(new Date()),
              Lio_NumTransmissions: 1
            }}
          >
            <div className="box_inner_vertical">
              <div className="box_inner_horizontal">
                <Form.Item label='帳票' name="Lio_OutputDocument" {...smGrid}>
                  <Input.Search
                    style={{ width: "200px" }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          visible: true,
                          width: 600,
                          component: (
                            <WS1291001_XmlDocumentQuerySelectSub
                              Li_SpecificAndHealthGuideAndAll={1}
                              onFinishScreen={output => {
                                this.formRef.current?.setFieldsValue({
                                  Lio_OutputDocument: output.recordData.format
                                });
                                this.setState({
                                  xmlDataDefault: output.recordData
                                });
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  />
                </Form.Item>
                {this.state.xmlDataDefault && (
                  <div style={{ marginTop: '5px' }}>
                    {this.state.xmlDataDefault.Expresstion_5}
                  </div>
                )}
              </div>

              <Form.Item
                label='決済'
                name="Lio_SettlementPresence"
                valuePropName="checked"
                {...smGrid}
              >
                <Checkbox onChange={(event) => this.onChange(event)} />
              </Form.Item>

              <Form.Item
                label='作成日'
                name="GcreationDateChars"
                {...smGrid}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                  onChange={(event) => this.changeDate(event, 'GcreationDateChars')}
                />
              </Form.Item>

              <Form.Item
                label='提出日'
                name="GsubmissionDateChars"
                {...smGrid}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                  onChange={(event) => this.changeDate(event, 'GsubmissionDateChars')}
                />
              </Form.Item>

              <div className="box_inner_horizontal">
                <Form.Item
                  label='送信'
                  name="Lio_NumTransmissions"
                  {...smGrid}
                >
                  <Input
                    type="text"
                    style={{ width: "50px" }}
                    onChange={(event) => this.changeSending(event)}
                  />
                </Form.Item>
                <div style={{ paddingTop: "5px" }}> 回目</div>
              </div>
            </div>

            <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={() => this.xmlCheck()}
              >
                チェック
              </Button>

              <Button
                htmlType="submit"
                type="primary"
                onClick={() => this.XmlCreate()}
              >
                作成
              </Button>
            </Space>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2655078_OutputConfirm);
