import { message } from "antd";
import AgencyInquirySub from "services/SpecificMedicalExamination/ConsultTicketInputProcessList/AgencyInquirySub";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error("エラーが発生しました");
      }
    });
};

const AgencyInquirySubAction = {
  Index(request) {
    return post(AgencyInquirySub.Index, request);
  },
  delete(request) {
    return post(AgencyInquirySub.delete, request);
  },
  GetScreenData(request) {
    return post(AgencyInquirySub.GetScreenData, request);
  },
  AddTo(request) {
    return post(AgencyInquirySub.AddTo, request);
  },
  Correct(request) {
    return post(AgencyInquirySub.Correct, request);
  },
  F4User(request) {
    return post(AgencyInquirySub.F4User, request);
  },
  F7User(request) {
    return post(AgencyInquirySub.F7User, request);
  },
  F12User(request) {
    return post(AgencyInquirySub.F12User, request);
  },
  Registration(request) {
    return post(AgencyInquirySub.Registration, request);
  }
};

export default AgencyInquirySubAction;
