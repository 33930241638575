import { message } from "antd";
import InspectSetInfoService from "services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/InspectSetInfoService";

export const getDataMainCosAction = () => {
    return InspectSetInfoService.getDataMainCosService()
}

export const getCourseSubInputAction = (params) => {
    return InspectSetInfoService.getCourseSubInputService(params)
}

export const getSetSubprocessAction = (params) => {
    return InspectSetInfoService.getSetSubprocessService(params)
}

export const saveAndUpdateCourseSubInputAction = (params) => {
    return InspectSetInfoService.saveAndUpdateCourseSubInputService(params)
}

export const saveAndUpdateCourseSetSubprocessAction = (params) => {
    return InspectSetInfoService.saveAndUpdateCourseSetSubprocessService(params)
}

export const BatchProcess_F9Action = (params) => {
    return InspectSetInfoService.BatchProcess_F9Service(params)
}






