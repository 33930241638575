import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/progress-management/conditions-configuration-edit/get-screen-data",
  setRegister: "/api/progress-management/conditions-configuration-edit/set-register",
  delete: "/api/progress-management/conditions-configuration-edit/delete"
};

const ConditionsConfigurationEditService = {
  async getScreenData(params) {
    return axios.post(API_LIST.getScreenData, params);
  },
  async setRegister(params) {
    return axios.post(API_LIST.setRegister, params);
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params });
  },
};

export default ConditionsConfigurationEditService;