import { message } from "antd";
import XmlDocumentQuerySelectSub from "services/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/XmlDocumentQuerySelectSubService";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
};

const XmlDocumentQuerySelectSubAction = {
  GetScreenData(request) {
    return post(XmlDocumentQuerySelectSub.GetScreenData, request);
  },
  XmlCreate(request) {
    return post(XmlDocumentQuerySelectSub.XmlCreate, request);
  },
  xmlCheck(request) {
    return post(XmlDocumentQuerySelectSub.xmlCheck, request);
  },
  downloadCSVFile(request) {
    // return post(XmlDocumentQuerySelectSub.downloadCSVFile, request);
    return XmlDocumentQuerySelectSub.downloadCSVFile(request)
  }
};

export default XmlDocumentQuerySelectSubAction;
