import React from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import 'assets/scss/_calendar.scss'
import { connect } from 'react-redux'
import { Card, Button, Modal } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { getDataVariousAggregateAction, updateAction } from 'redux/SystemMaintenance/NonConsultDateSetting/NonConsultDateSetting.actions'
import moment from 'moment'


const WS1494006_SingleSetting = (props) => {

  //const formRef = React.createRef()
  const [changeMonthFlg, setChangeMonthFlg] = React.useState(false)

  const [dataSource, setDataSource] = React.useState([])

  const [selectedMonth, setSelectedMonth] = React.useState(null)
  const [selectedYear, setSelectedYear] = React.useState(null)

  /**
  * Modalを開いた時の処理
  */
  React.useEffect(() => {
    const params = {
      Month: props.selectedMonth,
      Year: props.selectedYear
    }
    setSelectedMonth(params.Month);
    setSelectedYear(params.Year);
    getDataVariousAggregate(params);
  }, [])

  //カレンダー情報を取得
  const getDataVariousAggregate = (params) => {
    getDataVariousAggregateAction(params)
      .then(res => {
        if (res) {
          setDataSource(res.data)
          setChangeMonthFlg(false)
        }
      })
      .catch(err => console.log(err.response.data.message))
  }

  //日付のクラスを付与 (祝日用)
  const getTileClassIsHoliday = (event) => {
    // 月表示のときのみ
    if (event.view !== 'month') {
      return null
    }
    // フォーマット変更
    const targetDay = moment(event.date).format('YYYY-MM-DD')

    let kyusinkubun = false
    let date = dataSource

    // 祝日判定
    for (let i = 0; i < date.length; i++) {
      if (targetDay === date[i].date_on) {
        kyusinkubun = false
        if (date[i].kyusinkbn === 1) {
          kyusinkubun = true
        }
      }
    }
    //休診日だったら、色を赤く
    return (kyusinkubun) ? 'react-calendar-kyusin' : 'react-calendar-kaijyo'
  }

  //休診日を診療日に、診療日を休診日
  const changeSituation = async (value) => {
    const targetDay = moment(value).format('YYYY-MM-DD')

    setChangeMonthFlg(true)

    let dateList = [...dataSource]

    // 祝日判定
    for (let i = 0; i < dateList.length; i++) {
      if (targetDay === dateList[i].date_on) {
        let obj = { ...dateList[i] }
        obj.kyusinkbn = obj.kyusinkbn ? 0 : 1
        obj.changeFlg = 1
        dateList[i] = obj
        break
      }
    }

    // state更新
    setDataSource(dateList)
  }

  /**
   * 次の月に移動した時のデータリロード
   * @param {*} activeStartDate
   * @param {*} view
   */
  const reloadDate = (activeStartDate, view) => {

    if (view === 'month') {
      const params = {
        Month: moment(activeStartDate).format('MM'),
        Year: moment(activeStartDate).format('YYYY'),
      }
      setSelectedMonth(params.Month);
      setSelectedYear(params.Year);
      getDataVariousAggregate(params);
    }
  }

  const updateKyusinDate = () => {
    const params = {
      changeData: dataSource
    }

    updateAction(params)
      .then(res => {
        props.onFinishScreen()
      })
  }

  const moveMonthPage = (activeStartDate, view) => {
    if (changeMonthFlg) {
      Modal.confirm({
        content: `${moment(selectedMonth).format('M')}月に変更された内容を保存しますか`,
        okText: '保　存',
        cancelText: '保存しない',
        onOk: () => {
          //保存処理に遷移
          updateKyusinDate()
        },
        onCancel: () => {
          //先月・次月に遷移
          reloadDate(activeStartDate, view)
        }
      })

    } else {
      //先月・次月に遷移
      reloadDate(activeStartDate, view)
    }
  }


  return (
    <div className='single-setting'>
      <Card title={'個別設定' + " [ " + props.selectedYear + "年 " + Number(props.selectedMonth) + "月 ]"}>
        <div style={{ marginBottom: 10 }}>変更したい日付をクリックしてください</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Calendar
            className='kyusinbi'
            theme='ios'
            themeVariant='light'
            locale={'ja-JP'}
            calendarType={'US'}
            controls={['calendar']}
            touchUi={true}
            display='inline'
            defaultActiveStartDate={new Date(props.selectedYear + '-' + props.selectedMonth + '-01')}
            prevLabel={null}
            nextLabel={null}
            prev2Label={null}
            next2Label={null}
            showNavigation={false}
            tileClassName={getTileClassIsHoliday}
            formatDay={(locale, date) => moment(date).format('D')}

            onClickDay={(value, event) => {
              changeSituation(value)
            }}
          />

        </div>

        <div className='box_button_bottom_right'>
          <Button type="primary"
            icon={<SaveOutlined />}
            onClick={() => {
              updateKyusinDate()
            }}
          >
            <span className='btn_label'>
              保存
            </span>
          </Button>
        </div>
      </Card>
    </div>
  )

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1494006_SingleSetting)