import VouchersInputService from "services/SpecificInsureGuide/InsureGuideInitInput/VouchersInputService"
import { message } from "antd";

const VouchersInputAction = {
  getScreenData(params) {
    return VouchersInputService.getScreenData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  getContractData(params) {
    return VouchersInputService.getContractData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  contractSelect(params) {
    return VouchersInputService.contractSelect(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  onsave(params) {
    return VouchersInputService.onsave(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

}
export default VouchersInputAction;