

import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Table, Form, Input, Modal, Button, Menu } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import WS2700099_InputForm from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS2700099_InputForm.jsx";
import axios from "axios";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1873001_RadiographySiteMaintain extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "読影部位保守";

    this.state = {
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTable: false,
    };
  }
  componentDidMount() {
    // this.formRef.current?.setFieldsValue({

    // })
    // this.getScreen();
    this.getInitialValuse();
  }

  getInitialValuse = () => {
    this.setState({
      isLoadingTable: true
    })
    let params = {
      Li_InspectClassifyCode: "CT",
      Li_Division: 1
    }
    VenusApiRoutesV2.callApi('API001873001002', params)
      .then(res => {
        console.log("res->", res);
        this.setState({
          dataSource: res ? res.data : [],
        })

      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreen();
    }
  }

  getScreen() {
    const data = {}
    this.formRef.current?.setFieldsValue({ tableData: this.state.dataSource });
  }

  deleteProc = (record) => {
    Modal.confirm({
      content: "消去してもよろしいですか？",
      okText: "は　い",
      cancelText: "いいえ",
      onOk: () => this.deleteData(record),
    });
  }

  render() {
    return (
      <div className="radiography-site-maintain">
        <Card title="読影部位保守" className="mb-2">
          <button type="primary"
            key="(F12)"
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: "80%",
                  component: (
                    <WS2700099_InputForm
                      Li_InspectCodeOut="0"
                      onFinishScreen={() => {
                        this.searchInspectItemConverseSub();
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: false,
                          },
                        });
                      }}
                    />
                  ),
                },
              });
            }}
          >新規
          </button>

          <Table bordered
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ y: "700px" }}
          >
            <Table.Column
              title="部位ｺｰﾄﾞ"
              dataIndex="site_code"
              render={(row, record, index) => {
                return (
                  <Form.Item name={["tableData", index, "site_code"]}>
                    <Input style={{ textAlign: 'right' }} name="site_code" type="number" />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              title="部位名称"
              dataIndex="site_name"
              render={(row, record, index) => {
                return (
                  <Form.Item>
                    <Input style={{ textAlign: 'left' }} value={record.site_name} />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              title="部位分類1"
              dataIndex="site_classification_io"
              render={(row, record, index) => {
                return (
                  <Form.Item>
                    <Input style={{ textAlign: 'left' }} value={record.site_classification_io} />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              title="所見分類"
              dataIndex="findings_classification_i"
              render={(row, record, index) => {
                return (
                  <Form.Item>
                    <Input style={{ textAlign: 'left' }} value={record.findings_classification_i} />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              title="表示順"
              dataIndex="display_order"
              render={(row, record, index) => {
                return (
                  <Form.Item>
                    <Input style={{ textAlign: 'right' }} type="number" value={record.display_order} />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              title="出力文字"
              dataIndex="OutputChar"
              render={(row, record, index) => {
                return (
                  <Form.Item>
                    <Input style={{ textAlign: 'left' }} value={record.OutputChar} />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              align="center"
              width={100}
              title={() => (
                <Button
                  size="small"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    let arrTemp = [];
                    this.formRef.current.setFieldsValue({
                      tableData: [...arrTemp, ...this.state.dataSource],
                    });
                    this.setState({
                      dataSource: [...arrTemp, ...this.state.dataSource],
                    });
                  }}
                ></Button>
              )}
              render={(text, record, index) => (
                <>
                  <Button
                    size="small"
                    style={{ border: "none" }}
                    icon={<SaveOutlined style={{ color: "green" }} />}
                    onClick={() => this.saveDataUp(record)}
                  ></Button>
                  <Button
                    size="small"
                    style={{ border: "none" }}
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      this.deleteProc(record)
                    }}
                  ></Button>
                </>
              )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1873001_RadiographySiteMaintain);





