import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import {
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Modal,
  Input,
  Space,
  message,
} from "antd";
import WS1041001_ExternalInspectAmountOfQualitativeTransformation from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1041001_ExternalInspectAmountOfQualitativeTransformation.jsx";
import QuantitativeQualitativeTransformationSubService from "services/CooperationRelated/InspectItemConvertInternal/QuantitativeQualitativeTransformationSubService";
import PropTypes from "prop-types";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import moment from "moment";
const dateFormat = "YYYY/MM/DD";
class WS1035001_QuantitativeQualitativeTransformationSub extends React.Component {
  static propTypes = {
    Li_ExternalCode: PropTypes.any,
    Li_InternalInspectCode: PropTypes.any,
    Li_Name: PropTypes.any,
  };
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "定量定性変換SUB";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      screenData: {
        DateAdoption: "",
        DateAdoptionChar: "",
      },
      count: 0,
      listDataMen: [],
      listDataWomen: [],
      index_option_remark_men: "",
      index_option_remark_women: "",
      selectedRecordMen: {},
      selectedRecordWomen: {}
    };
  }

  componentDidMount = () => {
    const { Li_ExternalCode, Li_InternalInspectCode } = this.props;

    if (Li_ExternalCode && Li_InternalInspectCode) {
      this.setState(
        {
          Li_ExternalCode: Li_ExternalCode,
          Li_InternalInspectCode: Li_InternalInspectCode,
        },
        async () => {
          await this.getDataMenAndWomen(3, true);
        }
      );
    }
  };

  addNewRowToTable = (arrayName) => {
    let number = this.state.count + 1
    let arrTemp = [{
      'id': 'new' + number,
      'date_of_adoption_on': '',
      'quantitative_numerical_quantitative_range_check': '0.00',
      'lower_limit_value_qualitative_and_quantitative': '',
      'upper_limit_quantitative_only': '',
      'conversion_result': '',
      'option_remark': ''
    }];
    if (arrayName == 'listDataMen') {
      this.formRef.current?.setFieldsValue({
        'option_remark_men': ''
      })
      this.setState({
        listDataMen: [...arrTemp, ...this.state.listDataMen],
        count: number
      });
    } else {
      this.formRef.current?.setFieldsValue({
        'option_remark_women': ''
      })
      this.setState({
        listDataWomen: [...arrTemp, ...this.state.listDataWomen],
        count: number
      });
    }
  };

  getDataMenAndWomen = async (statusLoad, initial = false) => {
    if (initial) {
      await this.getScreenData(this.state.Li_ExternalCode);
    }
    if (statusLoad === 1 || statusLoad === 3) {
      await this.getData(
        this.state.Li_ExternalCode,
        this.state.screenData.DateAdoption,
        1,
        this.state.Li_InternalInspectCode
      );
    }
    if (statusLoad === 2 || statusLoad === 3) {
      await this.getData(
        this.state.Li_ExternalCode,
        this.state.screenData.DateAdoption,
        2,
        this.state.Li_InternalInspectCode
      );
    }
  };

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  saveData = () => {
    QuantitativeQualitativeTransformationSubService.saveDataService({
      Li_ExternalCode: this.state.Li_ExternalCode,
      Li_InternalInspectCode: this.state.Li_InternalInspectCode,
      listDataMen: this.state.listDataMen,
      listDataWomen: this.state.listDataWomen,
    })
      .then((res) => {
        message.success("成功");
        this.getDataMenAndWomen(3);
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };
  getScreenData = async (Li_ExternalCode) => {
    await QuantitativeQualitativeTransformationSubService.getScreenDataService({
      Li_ExternalCode,
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          DateAdoptionChar: moment(res.data.DateAdoptionChar).isValid()
            ? moment(res.data.DateAdoptionChar)
            : "",
        });
        this.setState({ screenData: res.data });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  };
  getData = (
    Li_ExternalCode,
    DateAdoption,
    Li_Gender,
    Li_InternalInspectCode
  ) => {
    QuantitativeQualitativeTransformationSubService.getListDataService({
      Li_ExternalCode,
      DateAdoption: DateAdoption ? moment(DateAdoption).format(dateFormat) : "",
      Li_Gender,
      Li_InternalInspectCode,
    })
      .then((res) => {
        if (Li_Gender === 1) {
          this.formRef.current.setFieldsValue({
            listDataMen: res.data
          });
          this.setState({
            listDataMen: res.data,
            index_option_remark_men: ""
          })
        } else {
          this.formRef.current.setFieldsValue({
            listDataWomen: res.data,
          })
          this.setState({
            listDataWomen: res.data,
            index_option_remark_women: ""
          })
        }
        // this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  };

  deleteRecordTable = (index, arrayName) => {
    let targetTable = []
    if (arrayName == 'listDataMen') {
      targetTable = [...this.state.listDataMen]
    } else {
      targetTable = [...this.state.listDataWomen]
    }

    // 該当の行を削除
    targetTable.splice(index, 1)

    if (arrayName == 'listDataMen') {
      this.formRef.current?.setFieldsValue({
        'option_remark_men': '',
        'index_option_remark_men': ''
      })
      this.setState({
        listDataMen: targetTable
      });
    } else {
      this.formRef.current?.setFieldsValue({
        'option_remark_women': '',
        'index_option_remark_women': ''
      })
      this.setState({
        listDataWomen: targetTable
      });
    }
  };

  input_text_area_option_remark = (arrayName, index) => {
    const namePath = [arrayName, index, "option_remark"];
    return (
      <Form.Item name={namePath} className="w-100" key={namePath}>
        <Input.TextArea
          rows={3}
          onChange={(event) => {
            const formIns = this.formRef.current;
            formIns.setFields([
              {
                name: namePath,
                value: event.target.value,
              },
            ]);
            this.forceUpdate();
          }}
        />
      </Form.Item>
    );
  };

  onChangeInput = (name, value, record, arrayName) => {
    let targetTable = []
    if (arrayName == 'listDataMen') {
      targetTable = [...this.state.listDataMen]
    } else {
      targetTable = [...this.state.listDataWomen]
    }
    let index = targetTable.indexOf(record);
    if (index !== -1) {
      let objTemp = {
        ...record,
        [name]: value,
      };
      targetTable[index] = objTemp;
      if (arrayName == 'listDataMen') {
        this.setState({
          listDataMen: targetTable
        });
      } else {
        this.setState({
          listDataWomen: targetTable
        });
      }
    }
  };

  onChangeOption = (value, index, arrayName) => {
    let targetTable = []
    if (arrayName == 'listDataMen') {
      targetTable = [...this.state.listDataMen]
    } else {
      targetTable = [...this.state.listDataWomen]
    }

    let record = targetTable[index]
    if (index !== -1) {
      let objTemp = {
        ...record,
        'option_remark': value,
      };
      targetTable[index] = objTemp;
      if (arrayName == 'listDataMen') {
        this.setState({
          listDataMen: targetTable
        });
      } else {
        this.setState({
          listDataWomen: targetTable
        });
      }
    }
  }

  render() {
    return (
      <div className="quantitative-qualitative-transformation-sub">
        <Card title="定量定性変換SUB">
          <Form ref={this.formRef} onFinish={this.onFinish} size="small">
            <div className="box_container">
              <Form.Item name="DateAdoptionChar" label="採用日">
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                  onChange={async (value) => {
                    await this.setState({
                      screenData: {
                        DateAdoption: value,
                        DateAdoptionChar: value,
                      }
                    });
                    this.getDataMenAndWomen(3)
                  }}
                />
              </Form.Item>
              <div className="box_inner_horizontal">
                <div className="box_inner_vertical" style={{ gap: 0 }}>
                  <div
                    style={{
                      background: "#E5FFFF",
                      padding: "0.2em",
                      border: "1px solid #f0f0f0"
                    }}
                  >
                    男性
                  </div>
                  <Table
                    bordered
                    dataSource={this.state.listDataMen}
                    pagination={false}
                    rowKey={(record) => record.id}
                    scroll={{ y: "40vh" }}
                    size="small"
                    rowClassName={(record, index) =>
                      index === this.formRef.current?.getFieldValue('index_option_remark_men')
                        ? "table-row-light"
                        : ""
                    }
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          this.formRef.current?.setFieldsValue({
                            'index_option_remark_men': rowIndex,
                            'option_remark_men': record.option_remark
                          })
                          this.setState({
                            index_option_remark_men: rowIndex
                          });
                        },
                      };
                    }}
                  >
                    <Table.Column
                      title="採用日"
                      dataIndex="date_of_adoption_on"
                      width={180}
                      render={(text, record, index) => {
                        return (
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            value={
                              moment(text).isValid() ? moment(text) : ""
                            }
                            onChange={(value) => {
                              this.onChangeInput('date_of_adoption_on', new Date(value), record, 'listDataMen')
                            }}
                            format={dateFormat}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="SEQ"
                      dataIndex="quantitative_numerical_quantitative_range_check"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => this.onChangeInput('quantitative_numerical_quantitative_range_check', event.target.value, record, 'listDataMen')}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="下限"
                      dataIndex="lower_limit_value_qualitative_and_quantitative"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => this.onChangeInput('lower_limit_value_qualitative_and_quantitative', event.target.value, record, 'listDataMen')}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="上限"
                      dataIndex="upper_limit_quantitative_only"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => this.onChangeInput('upper_limit_quantitative_only', event.target.value, record, 'listDataMen')}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="変換結果"
                      dataIndex="conversion_result"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => this.onChangeInput('conversion_result', event.target.value, record, 'listDataMen')}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      key="action"
                      align="center"
                      width={45}
                      title={() => (
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            this.addNewRowToTable("listDataMen");
                          }}
                        />
                      )}
                      render={(text, record, index) => {
                        return (
                          <Space>
                            <Button
                              size="small"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  title: "確認",
                                  icon: (
                                    <QuestionCircleOutlined
                                      style={{ color: "#1890ff" }}
                                    />
                                  ),
                                  content: "削除しますか",
                                  okText: "削除",
                                  cancelText: "キャンセル",
                                  onOk: () => {
                                    this.deleteRecordTable(index, 'listDataMen');
                                  },
                                });
                              }}
                            ></Button>
                          </Space>
                        );
                      }}
                    />
                  </Table>
                  <Form.Item name='option_remark_men' className="w-100">
                    <Input.TextArea
                      rows={3}
                      onChange={(event) => {
                        this.onChangeOption(
                          event.target.value,
                          this.formRef.current?.getFieldValue('index_option_remark_men'),
                          'listDataMen'
                        )
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="box_inner_vertical" style={{ gap: 0 }}>
                  <div
                    style={{
                      background: "red",
                      padding: "0.2em",
                      border: "1px solid #f0f0f0",
                      color: '#ffff'
                    }}
                  >
                    女性
                  </div>
                  <Table
                    bordered
                    dataSource={this.state.listDataWomen}
                    pagination={false}
                    rowKey={(record) => record.id}
                    scroll={{ y: "40vh" }}
                    size="small"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          this.formRef.current?.setFieldsValue({
                            'index_option_remark_women': rowIndex,
                            'option_remark_women': record.option_remark
                          })
                          this.setState({
                            index_option_remark_women: rowIndex
                          });
                        },
                      };
                    }}
                  >
                    <Table.Column
                      title="採用日"
                      width={180}
                      dataIndex="date_of_adoption_on"
                      render={(text, record, index) => {
                        return (
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            value={
                              moment(text).isValid() ? moment(text) : ""
                            }
                            onChange={(value) => {
                              this.onChangeInput('date_of_adoption_on', new Date(value), record, 'listDataWomen')
                            }}
                            format={dateFormat}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="SEQ"
                      dataIndex="quantitative_numerical_quantitative_range_check"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => { this.onChangeInput('quantitative_numerical_quantitative_range_check', event.target.value, record, 'listDataWomen') }}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="下限"
                      dataIndex="lower_limit_value_qualitative_and_quantitative"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => { this.onChangeInput('lower_limit_value_qualitative_and_quantitative', event.target.value, record, 'listDataWomen') }}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="上限"
                      dataIndex="upper_limit_quantitative_only"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => { this.onChangeInput('upper_limit_quantitative_only', event.target.value, record, 'listDataWomen') }}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="変換結果"
                      dataIndex="conversion_result"
                      render={(text, record, index) => {
                        return (
                          <Input
                            value={text}
                            onChange={(event) => { this.onChangeInput('conversion_result', event.target.value, record, 'listDataWomen') }}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      key="action"
                      align="center"
                      width={45}
                      title={() => (
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            this.addNewRowToTable("listDataWomen");
                          }}
                        />
                      )}
                      render={(text, record, index) => {
                        return (
                          <Space>
                            <Button
                              size="small"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  title: "確認",
                                  icon: (
                                    <QuestionCircleOutlined
                                      style={{ color: "#1890ff" }}
                                    />
                                  ),
                                  content: "削除しますか",
                                  okText: "削除",
                                  cancelText: "キャンセル",
                                  onOk: () => {
                                    this.deleteRecordTable(index, 'listDataWomen');
                                  },
                                });
                              }}
                            ></Button>
                          </Space>
                        );
                      }}
                    />
                  </Table>
                  <Form.Item name='option_remark_women' className="w-100">
                    <Input.TextArea
                      rows={3}
                      onChange={(event) => {
                        this.onChangeOption(
                          event.target.value,
                          this.formRef.current?.getFieldValue('index_option_remark_women'),
                          'listDataWomen'
                        )
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="box_button_bottom_right">
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "75%",
                        component: (
                          <WS1041001_ExternalInspectAmountOfQualitativeTransformation
                            onFinishScreen={() => {
                              this.getDataMenAndWomen(3)
                            }}
                          />
                        ),
                      },
                    });
                  }}
                >
                  一覧形式
                </Button>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => this.saveData()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1035001_QuantitativeQualitativeTransformationSub);
