import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Modal, Checkbox, message } from "antd";
import { ModalConfirm } from 'components/Commons/ModalConfirm'

import RecoveryProcessesAction from 'redux/Others/RecoveryProcesses/RecoveryProcesses.action'

class DeleteIncorrectData extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props);

    // document.title = '不正データ削除';

    this.state = {
      fileList: []
    };
    this.onFinish = this.onFinish.bind(this);
  }

  onFinish = () => {
    const params = {
      personal_info: this.formRef.current?.getFieldValue('personal_info') ?? false,
      office_info: this.formRef.current?.getFieldValue('office_info') ?? false,
      insurer_info: this.formRef.current?.getFieldValue('insurer_info') ?? false,
      exam_info: this.formRef.current?.getFieldValue('exam_info') ?? false,
    }
    ModalConfirm({
      content: '不正データの削除を実行しますか？',
      styleIconModal: { color: '#006CC9' },
      onOk: () => {
        RecoveryProcessesAction.deleteIncorrectData(params)
          .then((res) => {
            if(res) {
              Modal.info({
                content: res.message,
                onOk: this.props.onFinishScreen,
              })
            }
          })
          .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      },
      onCancel: () => {
        Modal.info({
          content: 'キャンセルしました',
        })
      },
    })
  }

  render() {
    return (
      <div className="delete-incorrect-data">
        <Card title="不正データ削除">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div style={{marginBottom: '12px'}}>コードが「0」や空白などのゴミデータを削除する機能です</div>
            <Form.Item
              name='personal_info'
              valuePropName='checked'
            >
              <Checkbox>個人情報</Checkbox>
            </Form.Item>
            <Form.Item
              name='office_info'
              valuePropName='checked'
            >
              <Checkbox>事業所情報</Checkbox>
            </Form.Item>
            <Form.Item
              name='insurer_info'
              valuePropName='checked'
            >
              <Checkbox>保険者情報</Checkbox>
            </Form.Item>
            <Form.Item
              name='exam_info'
              valuePropName='checked'
              style={{marginBottom: '24px'}}
            >
              <Checkbox>受診情報</Checkbox>
            </Form.Item>
            <Button style={{ float: 'left' }} onClick={() => this.props.onFinishScreen()} type="primary">閉じる</Button>
            <Button style={{ float: 'right' }} htmlType='submit' type="primary">実行</Button>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteIncorrectData);