import axios from 'configs/axios';

const apiPaths = {
  loadContractData: '/api/contract-info-maintain/contract-history-sub',
  getTableData: '/api/contract-info-maintain/contract-history-sub/get-table-data',
  onFinish: '/api/contract-info-maintain/contract-history-sub',
  destroyTerm: '/api/contract-info-maintain/contract-history-sub/destroyTerm',
}

const ContractHistorySubService = {
  async loadContractData(params) {
    return axios.get(apiPaths.loadContractData, { params })
  },
  async getTableData(params) {
    return axios.get(apiPaths.getTableData, { params })
  },
  async onFinish(params) {
    return axios.post(apiPaths.onFinish, params)
  },
  async destroyTerm(params) {
    return axios.delete(apiPaths.destroyTerm, { params })
  },
}

export default ContractHistorySubService;