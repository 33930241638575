import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Table,
  Input,
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  Select,
  Space,
  Form,
  message,
  Dropdown,
  Menu
} from "antd";

import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import WS0495001_ConditionExpressInfoInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0495001_ConditionExpressInfoInquiry.jsx";
import WS3022500_BindingModeSettingEdit from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS3022500_BindingModeSettingEdit.jsx'
import BindingModeSettingService from "services/ResultOutput/BindingModeSetting/BindingModeSettingService";
import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MoreOutlined,
  CheckOutlined
} from "@ant-design/icons";
class WS3022100_BindingModeSettingDetails extends React.Component {
  static propTypes = {
    Li_ParentCode: PropTypes.any,
  };
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "結合様式設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      tableData: []
    };
  }

  componentDidMount = () => {
    BindingModeSettingService.getScreenDataService()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          PrinterNoList: res.data,
        });
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    this.ModeUse(true);
  };

  ModeUse = () => {
    let params = {
      parent_code: this.props.parent_code
    }
    BindingModeSettingService.getDataModeUseBindingModeSettingService(params)
      .then((res) => {
        this.setState({
          tableData: res.data
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
        this.setState({ isLoadingTable: false });
      });
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  saveAndUpdateBindingModeSettingRightTable = () => {
    let selectedRows = this.formRef.current.getFieldValue("selectedRows");
    this.saveAndUpdateBindingModeSetting(selectedRows ? selectedRows.id : "");
  };

  saveAndUpdateBindingModeSetting = (id, editData) => {
    let params = {
      id: id,
      parent_code: this.props.parent_code,
      child_code: editData?.child_code,
      format_name: editData?.format_name,
      display_order: editData?.display_order,
      enabled: editData?.enabled,
      conditional_expression: editData?.conditional_expression,
    }
    BindingModeSettingService.modeSaveAndUpdateBindingModeSettingService(params)
      .then((res) => {

        message.success("成功");
        this.ModeUse();

        this.props.onFinishScreen()

      })
      .catch((err) => {
        const res = err.response;
        message.error(res.data.message);
        this.setState({ isLoadingTable: false });
      });
  };

  deleteModeUseBindingModeSetting = (id) => {
    this.setState({ isLoadingTable: true });

    BindingModeSettingService.deleteModeUseBindingModeSettingService({
      id: id,
    })
      .then((res) => {
        message.success("成功");
        this.ModeUse();
        this.setState({ isLoadingTable: false });
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
        this.setState({ isLoadingTable: false });
      });
  };

  /**
   * チェックのアイコンを出す出さないの判定
   * @param {*} value
   * @returns
   */
  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }

  /**
   * WS3022500_BindingModeSettingEdit呼び出し
   * @param {*} recordData
   */
  callEdit(recordData) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "800px",
        component: (
          <WS3022500_BindingModeSettingEdit
            newFlag={false}
            BindingModeSettingFlag={false}
            record={recordData}
            onFinishScreen={(output) => {
              this.saveAndUpdateBindingModeSetting(recordData.id, output);
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  render() {
    return (
      <div className="binding-mode-setting">
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Card title="結合様式設定">
            <div className="box_inner_horizontal">
              <div className="box_inner_vertical">
                <Space style={{ marginBottom: "10px" }}>
                  <Form.Item name="PrinterNo" label='プリンタ'>
                    <Select style={{ width: "200px" }}>
                      {this.formRef.current
                        ? this.formRef.current.getFieldValue("PrinterNoList")
                          ? this.formRef.current
                            .getFieldValue("PrinterNoList")
                            .map((item, index) => {
                              return (
                                <Select.Option value={item.key} key={index}>
                                  {item.value}
                                </Select.Option>
                              );
                            })
                          : null
                        : null}
                    </Select>
                  </Form.Item>
                </Space>

                <Table
                  style={{ cursor: "pointer" }}
                  rowClassName={(record, index) =>
                    record.id === this.state.selectedRowRight?.id
                      ? "table-row-light"
                      : ""
                  }
                  dataSource={this.state.tableData}
                  pagination={false}
                  bordered={true}
                  rowKey={(record) => record.id}
                  scroll={{ y: 700 }}
                  size="small"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          selectedRowRight: record,
                          selectedRowKeyRight: [record.id],
                        });
                      },
                    };
                  }}
                >
                  <Table.Column
                    title="表示順"
                    dataIndex="display_order"
                    width={70}
                    key=""
                  />
                  <Table.Column
                    title="様式"
                    dataIndex="child_code"
                    width={70}
                    key=""
                  />
                  <Table.Column
                    title="様式名"
                    dataIndex="format_name"
                    key=""
                  />
                  <Table.Column
                    title="有効"
                    dataIndex="enabled"
                    align="center"
                    width={50}
                    key=""
                    render={(text, record, index) => (
                      this.checkJudgment(text)
                    )}
                  />
                  <Table.Column
                    title="条件"
                    dataIndex="conditional_expression"
                    key=""
                    width={100}
                  />
                  <Table.Column
                    title="条件名称"
                    dataIndex="name"
                    key=""
                  />
                  <Table.Column
                    width={50}
                    align="center"
                    title={() => (
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: "800px",
                              component: (
                                <WS3022500_BindingModeSettingEdit
                                  newFlag={true}
                                  BindingModeSettingFlag={false}
                                  onFinishScreen={(output) => {
                                    this.saveAndUpdateBindingModeSetting(null, output);
                                    this.closeModal();


                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      />
                    )}
                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='1'
                                onClick={() => {
                                  this.callEdit(record)
                                }}
                              >
                                <label>編集</label>
                              </Menu.Item>
                              <Menu.Item
                                key='2'
                                onClick={() => {
                                  Modal.confirm({
                                    title: '確認',
                                    icon: (
                                      <QuestionCircleOutlined
                                        style={{ color: '#1890ff' }}
                                      />
                                    ),
                                    content: '削除しますか？',
                                    okText: '削除',
                                    cancelText: 'キャンセル',
                                    onOk: () => {
                                      this.deleteModeUseBindingModeSetting(
                                        record.id
                                      )
                                    },
                                  })
                                }}
                              >
                                <label>削除</label>
                              </Menu.Item>

                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      )

                    }}
                  />
                </Table>
              </div>
            </div>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Form>
      </div>
    );
  }
}

export default WS3022100_BindingModeSettingDetails;
