/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import Color from 'constants/Color'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import { Card, Table, Tooltip, Button, Form, message, Dropdown, Menu } from 'antd'
import { RightOutlined, DoubleRightOutlined, LeftOutlined, DoubleLeftOutlined, MoreOutlined, MenuOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import BankHolidaySettingAction from 'redux/SM_SystemMaintenance/BankHolidaySetting/BankHolidaySetting.action'
import BankHolidaySettingEdit from 'pages/SM_SystemMaintenance/BankHolidaySetting/BankHolidaySettingEdit'
import BankHolidaySettingCapturing from 'pages/SM_SystemMaintenance/BankHolidaySetting/BankHolidaySettingCapturing'

class BankHolidaySetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '法定休日設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      tableData: []
    }
  }

  componentDidMount() {
    // 初期表示
    // 本日を作成.
    var date = new Date();

    // 日付に1を設定.
    date.setDate(1);
    console.log(moment(date));
    this.formRef.current?.setFieldsValue({
      YearsChar: moment(date)
    })
    this.getOneMonthList()
  }

  getOneMonthList() {
    let params = {
      YearsChar: this.formRef.current.getFieldValue('YearsChar').format('YYYY/MM/DD')
    }
    BankHolidaySettingAction.getOneMonthList(params)
      .then((res) => {
        this.setState({
          tableData: res.ReserveStatusDisplay
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 前月を押した場合
   */
  previousMonth = () => {
    let newMonth = (Number(this.formRef.current.getFieldValue('YearsChar').format('MM')) - 1)
    //1月で前月ボタンを押した場合、前年に
    let year = newMonth == 0 ? this.formRef.current.getFieldValue('YearsChar').format('YYYY') - 1 : this.formRef.current.getFieldValue('YearsChar').format('YYYY')
    //1月で前月ボタンを押した場合、12月に
    let month = newMonth == 0 ? 12 : ('00' + newMonth).slice(-2)
    let day = ('00' + this.formRef.current.getFieldValue('YearsChar').format('DD')).slice(-2)
    let previousMonth = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(previousMonth) })
    this.getOneMonthList()
  }

  /**
   * 前年を押した場合
   */
  previousYear = () => {
    let year = (Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) - 1)
    let month = this.formRef.current.getFieldValue('YearsChar').format('MM')
    let day = this.formRef.current.getFieldValue('YearsChar').format('DD')
    let previousYear = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(previousYear) })
    this.getOneMonthList()
  }

  /**
   * 次月を押した場合
   */
  nextMonth = () => {
    let newMonth = (Number(this.formRef.current.getFieldValue('YearsChar').format('MM')) + 1)
    //12月で次月ボタンを押した場合、翌年に
    let year = newMonth == 13 ? Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) + 1 : this.formRef.current.getFieldValue('YearsChar').format('YYYY')
    //12月で次月ボタンを押した場合、1月に
    let month = newMonth == 13 ? ('00' + 1).slice(-2) : ('00' + newMonth).slice(-2)
    let day = ('00' + this.formRef.current.getFieldValue('YearsChar').format('DD')).slice(-2)
    let nextMonth = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(nextMonth) })
    this.getOneMonthList()
  }

  /**
   * 次年を押した場合
   */
  nextYear = () => {
    let year = (Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) + 1)
    let month = this.formRef.current.getFieldValue('YearsChar').format('MM')
    let day = this.formRef.current.getFieldValue('YearsChar').format('DD')
    let nextYear = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(nextYear) })
    this.getOneMonthList()
  }

  /**
   * 法定休日保存処理
   * @param {*} output 
   * @param {*} record 
   */
  save(output, record) {
    let params = {
      ...output,
      date: record.date_on
    }
    console.log(params);
    BankHolidaySettingAction.save(params)
      .then((res) => {
        message.success('保存が完了しました')
        this.getOneMonthList()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: '',
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='bank-holiday-setting'>
        <Card title={'法定休日設定'} style={{ width: 600 }}>
          <Form
            ref={this.formRef}
          >
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                size='small'
                className='option_btn_right_top'
                trigger='click'
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1' style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <BankHolidaySettingCapturing
                                  onFinishScreen={() => {
                                    this.getOneMonthList()
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      >
                        法定休日取り込み
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  size='small'
                  className='option_btn_right_top'
                  icon={<MenuOutlined />} />
              </Dropdown>
            </Tooltip>
            <div className='box_inner_horizontal' style={{ width: 'fit-content' }}>
              <Tooltip title='前年'>
                <Button
                  type='primary'
                  icon={<DoubleLeftOutlined />}
                  onClick={() => { this.previousYear() }}
                />
              </Tooltip>
              <Tooltip title='前月'>
                <Button
                  type='primary'
                  icon={<LeftOutlined />}
                  onClick={() => { this.previousMonth() }}
                />
              </Tooltip>
              <Form.Item name='YearsChar'>
                <VenusDatePickerCustom
                  picker='month'
                  format='YYYY年MM月'
                  allowClear={false}
                  onChange={() => this.getOneMonthList()}
                />
              </Form.Item>
              <Tooltip title='次月'>
                <Button
                  type='primary'
                  icon={<RightOutlined />}
                  onClick={() => { this.nextMonth() }}
                />
              </Tooltip>
              <Tooltip title='次年'>
                <Button
                  type='primary'
                  icon={<DoubleRightOutlined />}
                  onClick={() => { this.nextYear() }}
                />
              </Tooltip>
            </div>
            <Table
              size='small'
              style={{ width: 600 }}
              dataSource={this.state.tableData}
              pagination={false}
              bordered={true}
              rowKey={(record) => record.id}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
              rowClassName={(record, index) =>
                record.tableRowColor === 281 ?
                  'table-row-holiday'
                  :
                  ''
              }
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 500,
                        component: (
                          <BankHolidaySettingEdit
                            record={record}
                            onFinishScreen={(output) => {
                              console.log(output);
                              this.closeModal()
                              this.save(output, record)
                              // this.getOneMonthList()
                            }}
                          />
                        ),
                      },
                      isCloseModalLoadReserveStatusDisplayAgain: false,
                    })
                  }
                }
              }}
            >
              <Table.Column
                title='日付'
                width={60}
                dataIndex='day'
                render={(text, record, index) => {
                  return (
                    <span
                      style={{
                        color: record.dateColor
                          ? Color(record.dateColor)?.Foreground
                          : '',
                      }}
                    >
                      {text + ' ' + record.weekStr}
                    </span>
                  )
                }}
              />
              <Table.Column
                title='名称'
                dataIndex='holiday_name'
              />
              <Table.Column
                key='action'
                width={50}
                align='center'
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      icon={<MoreOutlined />}
                      size='small'
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 500,
                                  component: (
                                    <BankHolidaySettingEdit
                                      record={record}
                                      onFinishScreen={(output) => {
                                        console.log(output);
                                        this.closeModal()
                                        this.save(output, record)
                                        // this.getOneMonthList()
                                      }}
                                    />
                                  ),
                                },
                                isCloseModalLoadReserveStatusDisplayAgain: false,
                              })
                            }}
                          >
                            <label>編集</label>
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankHolidaySetting)
