import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, Table, Dropdown, Menu, Modal, message, InputNumber, Tooltip } from 'antd'
import { SearchOutlined, MoreOutlined, InfoOutlined, MenuOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moment from 'moment'
import Color from 'constants/Color'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import status_input_lock from 'assets/img/status_input_lock.png'
import status_input_lock_disabled from 'assets/img/status_input_lock_disabled.png'

import ConditionAddSubAction from 'redux/basicInfo/ConsultInfoReconstruction/ConditionAddSub.actions'
import InputVoteResultInputAction from 'redux/InputBusiness/InputVoteResultInput/InputVoteResultInput.action.js'

import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS2786020_SelectCourseList from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786020_SelectCourseList.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS0738002_TargetUserScreen from "pages/IN_InputBusiness/V4IN0201000_InputVoteResultInput/WS0738002_TargetUserScreen.jsx"
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0738003_InputVoteResultInputItemSettings from 'pages/IN_InputBusiness/V4IN0201000_InputVoteResultInput/WS0738003_InputVoteResultInputItemSettings.jsx'

const today = moment(new Date().toISOString())

const dateFormat = 'YYYY/MM/DD'

const labelCol = {
  labelCol: { style: { width: 60 } },
}

class WS0738001_InputVoteResultInput extends React.Component {
  formRef = React.createRef()

  // 2024/08/06 浜辺
  // Venusではメニューオプションで画面表示の制御しているが、メニューのオプションが無いため割愛


  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      officeKanjiName: null,
      personalkanjiName: null,

      dataSource: [],
      slipNumberList: [],
      facilityList: [],
      displayOrder: []
    }
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      dateFChar: today.format('YYYY/MM/DD'),
      dateTChar: today.format('YYYY/MM/DD')
    })

    this.index();
  }

  index(params) {
    // 伝票・施設・表示順の情報を取得
    InputVoteResultInputAction.index()
      .then((res) => {
        this.setState({
          slipNumberList: res.slipNumberList,
          facilityList: res.facilityList,
          displayOrder: res.visitKeyInfoList
        })
      })
  }


  /**
   * コース情報の画面表示
   */
  openModalWS0265001(item) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={(output) => {
              if (item == 'courseCodeF') {
                this.formRef.current?.setFieldsValue({
                  courseCodeF: output.Lo_CourseCode,
                })
              } else if (item == 'courseCodeT') {
                this.formRef.current?.setFieldsValue({
                  courseCodeT: output.Lo_CourseCode,
                })
              }

              // コース番号チェック
              this.checkCourseCode()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * コース一覧選択の画面
   */
  openModalWS2786020() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS2786020_SelectCourseList
            Lio_CourseList={this.formRef.current?.getFieldValue('courseList')}

            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                courseList: output?.Lio_CourseList,
              })

              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * コース番号チェック(大きさ比較)
   */
  checkCourseCode() {
    let courseCodeF = this.formRef.current?.getFieldValue('courseCodeF')
    let courseCodeT = this.formRef.current?.getFieldValue('courseCodeT')
    if (!courseCodeT || courseCodeF > courseCodeT) {
      this.formRef.current?.setFieldsValue({
        courseCodeT: courseCodeF,
      })
    }
  }


  /**
   * 事業所情報の画面表示
   */
  openModalWS0247001() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('officeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('branchStoreCode')}
            Li_1HeadOffice2BranchShop={1}

            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                officeCode: output.Lio_OfficeCode,
                branchStoreCode: output.Lio_BranchStoreCode === 0 ? null : output.Lio_BranchStoreCode,
              })
              this.setState({
                officeKanjiName: output.recordData.office_kanji_name
              })

              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * 事業所情報の取得
   */
  getNameOfficeCode(office) {
    const params = {
      officeCode: this.formRef?.current?.getFieldValue('officeCode') || '',
      branchStoreCode: this.formRef?.current?.getFieldValue('branchStoreCode') || ''
    }

    ConditionAddSubAction.getNameOfficeCodeAction(params)
      .then(res => {
        if (res?.data?.office_kanji_name) {
          this.setState({
            officeKanjiName: res?.data?.office_kanji_name
          })
        } else {
          this.setState({
            officeKanjiName: null
          })
          Modal.error({
            title: '事業所コードに誤りがあります',
            width: 326
          })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
  }


  /**
   * 個人情報の画面表示
   */
  openModalWS0248001() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}

            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                personalNum: output.recordData.personal_number_id
              })
              this.setState({
                personalkanjiName: output.recordData?.kanji_name
              })

              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * 個人番号情報の取得
   */
  getNamePersonal() {
    const params = {
      PersonalNum: this.formRef?.current?.getFieldValue('personalNum') || '',
    }

    ConditionAddSubAction.getNamePersonalAction(params)
      .then(res => {
        if (res?.data?.kanji_name) {
          this.setState({
            personalkanjiName: res?.data?.kanji_name
          })
        } else {
          this.setState({
            personalkanjiName: null
          })
          Modal.error({
            title: '指定の個人番号は存在しません。',
            width: 350
          })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
  }


  /**
  * 個人番号チェック
  */
  ChangePersonalInfoSearchQuery(value) {
    this.formRef.current?.setFieldsValue({
      kanji_name: '',
    })
    if (value.length > 10) {
      this.formRef.current?.setFieldsValue({
        personalNum: value.slice(0, 10),
      })
    }
  }


  /**
   * 検索処理
   */
  searchProcess() {
    let dateFChar = moment(this.formRef.current?.getFieldValue('dateFChar'))?.format(dateFormat)
    let dateTChar = moment(this.formRef.current?.getFieldValue('dateTChar'))?.format(dateFormat)
    let receiptNumF = this.formRef.current?.getFieldValue('receiptNumF')
    let receiptNumT = this.formRef.current?.getFieldValue('receiptNumT')

    // 受診日チェック
    if (dateTChar < dateFChar) {
      message.error("不正な日付です")
    }

    // 受付Noチェック
    if (Number(receiptNumT) > 0 && Number(receiptNumF) > 0) {
      if (Number(receiptNumT) < Number(receiptNumF)) {
        message.error("不正な受付Noです")
      }
    }

    let params = {
      ...this.formRef.current?.getFieldValue(),
      dateFChar: moment(this.formRef.current?.getFieldValue('dateFChar'))?.format(dateFormat),
      dateTChar: moment(this.formRef.current?.getFieldValue('dateTChar'))?.format(dateFormat),
    }

    InputVoteResultInputAction.getTargetList(params)
      .then((res) => {
        this.setState({
          dataSource: res
        })
      })
  }


  /**
   * 詳細画面
   */
  TargetUserScreen(record) {
    if (record.slipNumberFlag) {
      Modal.error({
        title: '入力する対象の検査がありません。',
        width: 400,
        okText: 'OK'
      })
    } else {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 1200,
          className: '',
          component:
            <WS0738002_TargetUserScreen
              course_level={record.course_level}
              personal_number_id={record.personal_number_id}
              reservation_number={record.reservation_number}
              kanji_name={record.kanji_name}
              receipt_number={record.receipt_number}
              visit_course={record.visit_course}
              contract_short_name={record.contract_short_name}
              visit_date_on={record.visit_date_on}
              pattern_code={record.pattern_code}
              protectFlag={record.protectFlag}
              judgment_level_division={record.judgment_level_division}
              slipNumber={record.slipNumber}
            />
        },
      });
    }
  }


  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }


  render() {
    return (
      <div className='invoice-result-input'>
        <Card title='伝票単位入力' className="mb-3">
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <Tooltip title='オプションメニュー' placement='topRight'>
                <Dropdown
                  className='option_btn_right_top'
                  trigger='click'
                  size='small'
                  icon={<MenuOutlined />}
                  overlay={() => {
                    return (
                      <Menu>
                        <Menu.Item
                          key='1'
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0738003_InputVoteResultInputItemSettings
                                    onFinishScreen={() => {
                                      this.closeModal();
                                    }}
                                  />
                                ),
                              },
                            });
                          }}
                        >
                          伝票単位入力項目
                        </Menu.Item>
                      </Menu>
                    )
                  }}
                >
                  <Button
                    className='option_btn_right_top'
                    size='small'
                    icon={<MenuOutlined />}
                  />
                </Dropdown>
              </Tooltip>

              <div className='box_inner_horizontal' style={{ marginBottom: 10 }}>
                <div className='box_search'>
                  <div className='box_inner_horizontal'>
                    <div className='box_search_inner_border'>
                      <div className='box_inner_horizontal' style={{ marginRight: 10 }}>
                        <Form.Item
                          label='受診日'
                          name='dateFChar'
                          {...labelCol}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={dateFormat}
                          />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item
                          name='dateTChar'
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </div>

                      <div className='box_inner_horizontal' >
                        <Form.Item
                          label='受付No'
                          name='receiptNumF'
                          {...labelCol}
                        >
                          <InputNumber
                            className='input-size-number-4'
                          />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item
                          name='receiptNumT'
                        >
                          <InputNumber
                            className='input-size-number-4'
                          />
                        </Form.Item>
                      </div>

                      <Form.Item
                        name='slipNumber'
                        label='伝票'
                        {...labelCol}
                        style={{ marginRight: 10 }}
                      >
                        <Select >
                          {this.state.slipNumberList?.map((value) => {
                            return (
                              <Select.Option
                                key={value.id}
                                value={value.item_id}
                              >
                                {value.item}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>

                    <div className='box_search_inner_border' >
                      <div className='box_inner_horizontal' style={{ marginRight: 10 }} >
                        <Form.Item
                          label='コース'
                          name='courseCodeF'
                          {...labelCol}
                        >
                          <Input.Search
                            maxLength={3}
                            style={{ width: 150 }}
                            onSearch={() => {
                              this.openModalWS0265001('courseCodeF')
                            }}
                            onBlur={() => { this.checkCourseCode() }}
                          />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item
                          name='courseCodeT'
                        >
                          <Input.Search
                            maxLength={3}
                            style={{ width: 150 }}
                            onSearch={() => {
                              this.openModalWS0265001('courseCodeT')
                            }}

                            onBlur={() => { this.checkCourseCode() }}
                          />
                        </Form.Item>

                        <Form.Item
                          name='courseList'
                        >
                          <Input.Search
                            className='input-search-size-10'
                            onSearch={() => {
                              this.openModalWS2786020()
                            }}
                          />
                        </Form.Item>
                      </div>

                      <div className='box_inner_horizontal' >
                        <Form.Item
                          label='事業所'
                          name='officeCode'
                          {...labelCol}
                        >
                          <Input.Search
                            style={{ width: 150 }}
                            maxLength={8}
                            onSearch={() => {
                              this.openModalWS0247001()
                            }}

                            onBlur={(e) => {
                              let val = e.target.value
                              if (val) {
                                // 入力された事業所の情報を取得
                                this.getNameOfficeCode()
                              } else {
                                this.formRef.current?.setFieldsValue({
                                  OfficeCode: '',
                                })
                                this.setState({
                                  officeKanjiName: ''
                                })
                              }
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          name='branchStoreCode'
                        >
                          <Input.Search
                            className="input-search-size-number-5"
                            maxLength={5}
                            min={0}
                            onSearch={() => {
                              this.openModalWS0247001()
                            }}

                            onBlur={(e) => {
                              let val = e.target.value
                              let office_code = this.formRef.current?.getFieldValue('officeCode')
                              if (val > 0 && office_code) {
                                this.getNameOfficeCode()
                              }
                            }}
                          />
                        </Form.Item>

                        <div style={{ marginTop: 5, marginRight: 5 }}>
                          {this.state.officeKanjiName}
                        </div>
                      </div>

                      <Form.Item
                        label='施設'
                        name='facilityType'
                        {...labelCol}
                      >
                        <Select
                          style={{ width: 150 }}
                        >
                          {this.state.facilityList?.map((value) => (
                            <Select.Option
                              key={value.id}
                              value={value.facility_type}
                            >
                              {value.facility_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div className='box_search_inner'>
                      <Form.Item
                        label='表示順'
                        name='displayOrder'
                        {...labelCol}
                      >
                        <Select>
                          {this.state.displayOrder?.map((value) => (
                            <Select.Option
                              key={value.id}
                              value={value.key_serial_number}
                            >
                              {value.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <div className='box_inner_horizontal' style={{ width: 400 }}>
                        <Form.Item
                          label='個人番号'
                          name='personalNum'
                          {...labelCol}
                        >
                          <Input.Search
                            className='input-search-size-number-allow-clear-10'
                            type='text'
                            allowClear={true}
                            onSearch={() => {
                              this.openModalWS0248001()
                            }}

                            onBlur={(e) => {
                              let val = e.target.value
                              this.ChangePersonalInfoSearchQuery(val)
                              if (val == 0 || !val) {
                                this.formRef.current?.setFieldsValue({
                                  PersonalNum: ''
                                })
                                this.setState({
                                  personalkanjiName: null
                                })
                              } else {
                                this.formRef.current?.setFieldsValue({
                                  PersonalNum: val,
                                })
                                this.getNamePersonal()
                              }
                            }}
                          />
                        </Form.Item>

                        <div style={{ marginTop: 5, marginRight: 5 }}>
                          {this.state.personalkanjiName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          this.searchProcess()
                        }}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={record => record.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 600 }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.TargetUserScreen(record)
                    },
                  }
                }}
              >
                <Table.Column
                  width={45}
                  dataIndex='protectImage'
                  title={<img src={status_input_lock} />}
                  align='center'
                  render={(value, record) => {
                    return (
                      <img src={(value === 'アンロック.png') ? status_input_lock_disabled : status_input_lock} />
                    )
                  }}
                />

                <Table.Column
                  title='受付No'
                  dataIndex="receipt_number"
                  className='column-size-5'
                  render={(text) => (
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  )}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex="personal_number_id"
                  className='column-size-10'
                  render={(text) => (
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  )}
                />

                <Table.Column
                  title='メモ'
                  dataIndex='importance'
                  width={45}
                  render={(values, record, index) => {
                    return (
                      <Button
                        size='small'
                        icon={<InfoOutlined />}
                        style={{ borderRadius: '12px' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component: (
                                <WS2584019_PersonalInfoInquirySub
                                  Li_PersonalNum={record.personal_number_id}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    )
                  }}
                />

                <Table.Column
                  title='カナ氏名'
                  dataIndex="kana_name"
                  className='column-size-30'
                />

                <Table.Column
                  title='漢字氏名'
                  dataIndex="kanji_name"
                  className='column-size-30'
                />

                <Table.Column
                  title='性別'
                  dataIndex='sex'
                  className='column-size-4'
                  render={(value, record, index) => {
                    return (
                      <div style={{
                        color: Color(record.GenderColor)?.Foreground
                      }}>
                        {record.sex}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='コース'
                  dataIndex='visit_course'
                  className='column-size-8'
                />

                <Table.Column
                  title='名称'
                  dataIndex='contract_short_name'
                />

                <Table.Column
                  title='施設'
                  dataIndex='facility_name'
                  className='column-size-10'
                />

                <Table.Column
                  title='受診日'
                  dataIndex="visit_date_on"
                  className='column-size-10'
                />

                <Table.Column
                  width={40}
                  align='center'
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='編集'
                              onClick={() => {
                                this.TargetUserScreen(record)
                              }}
                            >
                              詳細
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>

            </Form>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0738001_InputVoteResultInput)
