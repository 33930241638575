import React from "react";
import { connect } from "react-redux";
import { Card, Button, message } from "antd";

import NumberFormat from 'components/Commons/NumberFormat';
import ModalDraggable from "components/Commons/ModalDraggable";
import AgreementInformationAction from 'redux/SpecificInsureGuide/SpecificInsureGuideSettleProcessList/AgreementInformation.action.js';
import WS1465001_ContractSelectScreen from "./WS1465001_ContractSelectScreen";

const dataStyle = {
  marginTop: 0
}

const titleStyle = {
  marginTop: 0,
  width: "100px"
}

const titleStyleS = {
  marginTop: 0,
  width: "60px"
}

const titleStyleT = {
  marginTop: 0,
  marginLeft: "120px"
}

const titleStyleSide = {
  width: "700px"
}

class WS1464004_AgreementInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let params = {
      date_on: this.props.data_on,
      personal_number_id: this.props.id
    };

    AgreementInformationAction.getScreenData(params)
      .then(res => {
        res.motivation_unit_price = NumberFormat.formatter(res.motivation_unit_price);
        res.actively_unit_price = NumberFormat.formatter(res.actively_unit_price);

        if (res.specify_insurer_pay_max == '') {
          res.specify_insurer_pay_max = '-'
        }

        if (res.consult_ticket_docket_num == '') {
          res.consult_ticket_docket_num = '-----------'
        }

        if (res.motivation_first01_item == '') {
          res.motivation_first01_item = '----'
        }

        if (res.price_motivation_rate_01 == 0) {
          res.price_motivation_rate_01 = '--'
        }

        if (res.actively_first01_item == '') {
          res.actively_first01_item = '----'
        }

        if (res.price_actively_rate_01 == 0) {
          res.price_actively_rate_01 = '--'
        }

        this.setState({ dataSource: res })

      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  contractSelect() {
    let params = {
      Li_Date: this.props.data_on,
      Li_PersonalNum: this.props.id,
      insurer_num: this.state.dataSource.insurer_number,
      insure_guide_division: this.state.dataSource.insure_guide_division_num,
    }

    console.log(params);

    AgreementInformationAction.contractBtn(params)
      .then(res => {
        if (res.variables) {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 850,
              component: (
                <WS1465001_ContractSelectScreen
                  {...res.variables}
                  onFinishScreen={() => {
                    this.forceUpdate()
                    this.closeModal()
                    this.getScreenData()

                    this.props.onFinishScreen()
                  }}
                />
              ),
            },
          });
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className='agreement_information'>
        <Card title='保健指導決算 [詳細]'>
          <div className="box_inner_vertical">
            <div className="box_inner_horizontal">
              <div style={titleStyle}>契約番号</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.serial_number}</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.determining_billing_info_contract_year + '年度'}</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.Expression_12}</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.contract_name}</div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyle}>初回徴収</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.first_time_levy}</div>
            </div>

            <div style={{ marginTop: '-13px' }} className="box_border_bottom_no_padding" />

            <div className="box_inner_horizontal">
              <div style={titleStyle}>動機付</div>
              <div style={{ marginLeft: '10px' }}>単価</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.motivation_unit_price}</div>
            </div>

            <div className="box_inner_horizontal">
              <div className="box_inner_horizontal" style={titleStyleSide}>
                <div style={titleStyleT}>初回</div>
                <div className="div_data" style={titleStyleS}>{this.state.dataSource.motivation_first_item}</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.price_motivation_rate_first}</div>
              </div>

              <div className="box_inner_horizontal" >
                <div >初回①</div>
                <div className="div_data" style={titleStyleS}>{this.state.dataSource.motivation_first01_item}</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.price_motivation_rate_01}</div>
              </div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyleT}>終了</div>
              <div className="div_data" style={titleStyleS}>{this.state.dataSource.motivation_finish_item}</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.price_motivation_rate_finish}</div>
            </div>

            <div style={{ marginTop: '-13px' }} className="box_border_bottom_no_padding" />

            <div className="box_inner_horizontal">
              <div style={titleStyle}>積極付</div>
              <div style={{ marginLeft: '10px' }}>単価</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.actively_unit_price}</div>
            </div>

            <div className="box_inner_horizontal">
              <div className="box_inner_horizontal" style={titleStyleSide}>
                <div style={titleStyleT}>初回</div>
                <div className="div_data" style={titleStyleS}>{this.state.dataSource.actively_first_item}</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.price_actively_rate_first}</div>
              </div>

              <div className="box_inner_horizontal" >
                <div >初回①</div>
                <div className="div_data" style={titleStyleS}>{this.state.dataSource.actively_first01_item}</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.price_actively_rate_01}</div>
              </div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyleT}>継続</div>
              <div className="div_data" style={titleStyleS}>{this.state.dataSource.actively_continuation_item}</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.price_actively_rate_continuation}</div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyleT}>終了</div>
              <div className="div_data" style={titleStyleS}>{this.state.dataSource.actively_finish_item}</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.price_actively_rate_finish}</div>
            </div>

            <div className="box_inner_horizontal">
              <div style={{ titleStyle }}>実績ポイント数</div>
              <div className="div_data" style={titleStyleS}>{this.state.dataSource.point_performance}</div>
            </div>

            <div style={{ marginTop: '-13px' }} className="box_border_bottom_no_padding" />

            <div className="box_inner_horizontal">
              <div className="box_inner_horizontal" style={titleStyleSide}>
                <div style={titleStyle}>負担内容</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.specify_health_burden_sect}</div>
              </div>

              <div className="box_inner_horizontal" >
                <div>保険者負担上限額</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.specify_insurer_pay_max}</div>
              </div>
            </div>

            <div style={{ marginTop: '-13px' }} className="box_border_bottom_no_padding" />

            <div className="box_inner_horizontal">
              <div className="box_inner_horizontal" style={titleStyleSide}>
                <div style={titleStyle}>保険者番号</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.insurer_number}</div>
              </div>

              <div className="box_inner_horizontal">
                <div className="div_data" style={{ fontWeight: 'bold', marginTop: '0px', marginLeft: '0px' }}>{this.state.dataSource.insurer_kanji_name}</div>
              </div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyle}>利用券番号</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.vouchers_docket_num}</div>
            </div>

            <div className="box_inner_horizontal">
              <div className="box_inner_horizontal" style={titleStyleSide}>
                <div style={titleStyle}>受診券番号</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.consult_ticket_docket_num}</div>
              </div>

              <div className="box_inner_horizontal" >
                <div>交付年月日</div>
                <div className="div_data" style={dataStyle}>{this.state.dataSource.date_issuance}</div>
              </div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyle}>有効期限</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.expiration_date}</div>
            </div>

            <div className="box_inner_horizontal">
              <div style={titleStyle}>保健指導区分</div>
              <div className="div_data" style={dataStyle}>{this.state.dataSource.insure_guide_division}</div>
            </div>
          </div>


          <div className='box_button_bottom_right'>
            <Button
              type="primary"
              htmlType='submit'
              onClick={() => this.contractSelect()}
            >
              契約選択
            </Button>
          </div>


          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </Card >
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1464004_AgreementInformation);