import axios from "configs/axios";

const apiPaths = {
  getTreeData: "/api/implementor-master/implementor-master",
  deleteMasterInfo: "/api/implementor-master/implementor-master/delete",
  AddNewMaster: "/api/implementor-master/implementor-master/new-master-info",
  postMaster: "/api/implementor-master/implementor-master"
};

const ImplementorMasterService = {

  async getTreeData(params) {
    return axios.get(apiPaths.getTreeData, { params, responseType: 'json' });
  },

  async deleteMasterInfo(params) {
    return axios.delete(apiPaths.deleteMasterInfo, { data: params }, { responseType: 'json' });
  },

  async AddNewMaster(params) {
    return axios.post(apiPaths.AddNewMaster, params);
  },

  async postMaster(params) {
    return axios.post(apiPaths.postMaster, params);
  },

};


export default ImplementorMasterService;