import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import  ModalDraggable  from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined,SaveOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Card, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin,Table } from "antd";

import moment from "moment";
import axios from "configs/axios";

import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import {isNull} from "lodash";
class WS2674013_MultipleSelectOffice extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '事業所複数選択';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      data: [],
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      isLoading:false
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData = async() => {
        
    this.setState({ isLoading: true });
    
    await VenusApiRoutesV2.callApi("API002674013002")
      .then(res => {
        let data = res ? res : [];
        this.formRef.current?.setFieldsValue({
          dataSource: data
        })
        this.setState({
          dataSource: data  
        })

      })
      .catch(error => {       
        this.setState({ isLoading: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      }).finally(this.setState({ isLoading: false }));

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  handleAddNew = () => {
    let newRow = {
      id: null,
      W1_office_cd: '',
      W1_branch_store_cd: '',
      office_kanji_name: ''
    };

    let data = [...this.state.dataSource];

    data.push(newRow);

    this.setState({
      dataSource: data,
    });

    this.forceUpdate();
  }
  
  
  onChangeInput = (output, index) => {
    console.log(output);
    console.log(index);

    let arrTemp   =   JSON.parse(JSON.stringify(this.state.dataSource));
            
    if (index !== -1) {
      console.log(index); 

      arrTemp[index].W1_office_cd       = output.Lio_OfficeCode;
      arrTemp[index].W1_branch_store_cd = output.Lio_BranchStoreCode;
      arrTemp[index].office_kanji_name  = output.Lo_Kanji_Name;
      this.setState({ dataSource: arrTemp });
      this.formRef.current?.setFieldsValue({
        dataSource: arrTemp
      })
      this.forceUpdate()
    }
  }
  onChangeInput1 = (event, index) => {
   
    let { value, name } = event.target;
    let arrTemp   =   JSON.parse(JSON.stringify(this.state.dataSource));
            
    if (index !== -1) {
      console.log(index);     
      arrTemp[index].W1_branch_store_cd = value;   
      this.setState({ dataSource: arrTemp });
    }
  }

  handleCreateOrUpdate(record) {
    if(isNull(record.id)){
      this.CreateRecord(record)
    } else {
      this.Save(record)
    }
  }


  CreateRecord(record) {
    this.setState({ isLoading: true });
    axios.post("/api/associate-insure-qualify-confirm/multiple-select-office/updateOrCreate", {record: record})
      .then((res) => {
        message.success(res?.data.message);
        this.getScreenData();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      }).finally(this.setState({ isLoading: false }));    
  }

  Save(record) {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API002674013006", {mainSource: [record]})
      .then((res) => {
        message.success(res?.message);
        this.getScreenData();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      }).finally(this.setState({ isLoading: false }));    
  }

  deleteRecord(record) {

    Modal.confirm({
      width: "250px",
      content: "削除を行いますか ?",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
      this.setState({ isLoading: true });
      VenusApiRoutesV2.callApi("API002674013007", {mainSource: [record]})
      .then((res) => {
          message.success(res?.message);
          this.getScreenData();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      }).finally(this.setState({ isLoading: false }));
      }
    })    
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  render() {
    return (
      <div className="multiple-select-office">
        <Card title="事業所複数選択">
          <Form ref={this.formRef}>
          <Table bordered             
            dataSource={this.state.dataSource}         
            rowKey={(record) => record.id}
            loading={this.state.isLoading}
            size='middle'
            pagination={{
              total: this.state.dataSource.length,
              pageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}            
          >          
            <Table.Column title="事業所コード" dataIndex="W1_office_cd"
              render={(value, record) => {
                let index = this.findIndexByID(this.state.dataSource, record.id);
                return (
                  <>
                  <Form.Item hidden name={['dataSource', index, 'id']}>
                    <Input />
                  </Form.Item>
                  <Form.Item name={['dataSource', index, 'W1_office_cd']}>
                    <Input.Search key={index} type="number"  min={0} name='W1_office_cd'
                      onSearch={(event) => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: "70%",
                            component:
                              <WS0247001_OfficeInfoRetrievalQuery                                   
                                onFinishScreen={(output) => {
                                  this.onChangeInput(output, index)                                    
                                  this.closeModal()
                                }}
                              />
                            ,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                  </>
                )
              }}
            />
            <Table.Column title="支社店" dataIndex="W1_branch_store_cd"
            
              render={(value, record) => {
                let index = this.findIndexByID(this.state.dataSource, record.id);
                return (
                  <Form.Item name={['dataSource', index, 'W1_branch_store_cd']}>
                    <Input key={index} type='number'  onChange={(e) => this.onChangeInput1(e, index)} />
                  </Form.Item>
                )
              }}
            />
            <Table.Column title="事業所名" dataIndex="office_kanji_name"
              render={(value, record) => {
                let index = this.findIndexByID(this.state.dataSource, record.id);
                return (
                  <Form.Item name={['dataSource', index, 'office_kanji_name']}>
                    <Input key={index} type='text' value={this.state.dataSource[index]?.office_kanji_name} />
                  </Form.Item>
                  
                )
              }}
            />            
            <Table.Column width={70} fixed={'right'}
              title={
                <div style={{ textAlign: "center" }}>
                  <Button size='small'
                    onClick={this.handleAddNew}
                    type="primary" icon={<PlusOutlined />}
                    >
                  </Button>
                </div>
              }
              render={(text, record, index) => {
                return <div style={{ textAlign: "center", display:"flex" }}>
                  <Button size='small'
                    // hidden={this.state.indexTable !== index || this.checkAddItem() || !(this.state.dataSource[this.state.indexTable] && this.state.dataSource[this.state.indexTable].FormatOp)}
                    onClick={() => {
                      this.handleCreateOrUpdate(record)
                    }}
                    style={{ color: '#42b10b', border: 'none', marginRight: '5px' }}
                    icon={<SaveOutlined />} >
                  </Button>
                  <Button size='small' style={{ border: 'none' }}
                    onClick={() => this.deleteRecord(record)}
                    // danger
                    icon={<DeleteOutlined />}
                  >
                  </Button>
                </div>
              }}
            />               
          </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => { 
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2674013_MultipleSelectOffice);
