import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-course/support-item-inquiry",
  index: "/api/insure-guide-course/inspect-inquiry",
};

const SupportItemInquiryService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async index(params) {
    return axios.get(API_LIST.index, { params });
  },
};

export default SupportItemInquiryService;
