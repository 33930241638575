import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Button, Card, Form, Input, Table, Modal } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import WS1523007_UserOptionsInquiry from './WS1523007_UserOptionsInquiry'

const labelSize = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 60px)' } }
}
class WS1523500_UserOptionInfoMaintainEdit extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_TypeCode: PropTypes.any,
    Li_OptionCode: PropTypes.any,
    Li_Expansion: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = 'ユーザーオプション情報保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue(this.props.record)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='user-option-info-maintain'>
        <Form ref={this.formRef} autoComplete='off'>
          <Card
            title={'ユーザーオプション情報保守' + (this.props.newFlag ? '新規' : '編集')}
            className='mb-2'
          >
            {this.props.leftTable ?
              <div>
                <Form.Item name='option_code' label='ｺｰﾄﾞ'{...labelSize}>
                  <Input
                    style={{ width: 120 }}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '80%',
                          component: (
                            <WS1523007_UserOptionsInquiry
                              Li_TypeCode={this.props.OptionType}
                              Li_OptionCodeList
                              Lio_OptionCode
                              Lio_IdentifyName
                              Lio_OptionItem
                              onFinishScreen={({ recordData }) => {
                                console.log(recordData);
                                // this.formRef?.current?.setFieldsValue({ 'option_code':  })

                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item name='identification_name' label='名称' {...labelSize}>
                  <Input style={{ width: 240 }} />
                </Form.Item>
              </div>
              :
              <div>
                {/* <Form.Item name='W1_serial_num' label='連番' {...labelSize}>
                  <Input style={{ width: 120 }} />
                </Form.Item> */}
                <Form.Item name='W1_item' label='項目' {...labelSize}>
                  <Input style={{ width: 240 }} />
                </Form.Item>
                <Form.Item name='W1_content' label='内容' {...labelSize}>
                  <Input style={{ width: 240 }} />
                </Form.Item>
                {/* <Form.Item name='Description' label='補足説明' {...labelSize}>
                  <Input style={{ width: 240 }} />
                </Form.Item> */}
              </div>
            }
            <div className='box_button_bottom_right'>
              {this.props.deleteFlag ?
                <Button
                  type='danger'
                  onClick={() => {
                    Modal.confirm({
                      content: '削除を行いますか？',
                      onOk: () => { this.props.onDelete() }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button> : null
              }
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.props.onFinishScreen(this.formRef.current?.getFieldValue())}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1523500_UserOptionInfoMaintainEdit)
