import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Table, } from 'antd'
import FindingsHistoryListAction from 'redux/InputBusiness/RadiographyFindingInput/FindingsHistoryList.action'
class WS1870011_FindingsHistoryList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    FindingsHistoryListAction.index()
      .then((res) => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
  }

  render() {
    return (
      <div className='findings-history-list'>
        <Card title='所見履歴一覧'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={record => record.id}
            scroll={{ y: 450 }}
          >
            <Table.Column title='受診日' dataIndex='visit_date_on' />
            <Table.Column title='所見内容' dataIndex='FindingsContent' />
            <Table.Column title='' dataIndex='InspectResults' />
            <Table.Column title='判定' dataIndex='Judge' />

          </Table>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1870011_FindingsHistoryList)
