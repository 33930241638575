import React from "react";
import { connect } from "react-redux";
import SingleGroupClassifyInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/SingleGroupClassifyInputSub.action'
import { Card, Form, Input, Radio, Button, Descriptions, message } from "antd";
import PropTypes from "prop-types";


const contentStyle = {
  textAlign: 'right',
  padding: '0px 3px'
}

const formItem = {
  marginBottom: 0,
  width: '100%'
}

const formInput = {
  marginBottom: 0,
  width: '100%',
  padding: '0px 10px'
}

const descriptionsStyle = {
  marginBottom: 0,
  marginTop: 0
}

const labelStyle = {
  width: '120px'
}

const inputRight = {
  textAlign: 'right'
}
class WS1379001_SingleGroupClassifyInputSub extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_Tab: PropTypes.any,
    Lio_DocketNum: PropTypes.any,

    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = "受診券入力SUB";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      dataList: {},
    };
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    let params = {
      year: this.props.year,
      serial_number: this.props.serial_number
    }
    SingleGroupClassifyInputSubAction.getScreenData(params)
      .then(res => {
        this.formRef.current?.setFieldsValue(res.data)
        this.setState({ dataList: res.data })
        this.forceUpdate()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  onFinish(values) {
    let servicing_specific_medical_exam = this.state.dataList.servicing_specific_medical_exam
    if (servicing_specific_medical_exam === 0) {
      message.error('特定健診の受託が、対象外に設定されています。')
      return
    }
    if (values.PersonPopulationSegments === 1) {
      if (
        this.formRef.current?.getFieldValue('basic_individual_medical_exam_unit_price') +
        this.formRef.current?.getFieldValue('anemia_individual_medical_exam_unit_price') +
        this.formRef.current?.getFieldValue('electrocardiograph_individual_medical_exam_unit_price') +
        this.formRef.current?.getFieldValue('fundus_individual_medical_exam_unit_price') +
        this.formRef.current?.getFieldValue('h30_creatinine_individual_medical_exam_unit_price') === 0
      ) {
        message.error('個別健診の金額が設定されていません。')
        return
      }
    }
    if (values.PersonPopulationSegments === 2) {
      if (
        this.formRef.current?.getFieldValue('unit_price_exam_basic_population_health') +
        this.formRef.current?.getFieldValue('unit_price_exam_anemia_population_health') +
        this.formRef.current?.getFieldValue('unit_price_diagnosis_electrocardiogram_population_ken') +
        this.formRef.current?.getFieldValue('fundus_group_medical_exam_unit_price') +
        this.formRef.current?.getFieldValue('h30_creatinine_population_checkup_unit_price') === 0
      ) {
        message.error('集団健診の金額が設定されていません。')
        return
      }
    }
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.props.Li_CourseLevel,
      PersonPopulationSegments: values.PersonPopulationSegments,
      year: this.props.year,
      serial_number: this.props.serial_number
    }
    SingleGroupClassifyInputSubAction.runBtn(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {
    return (
      <div className="single-group-classify-input-sub">
        <Card title="個別集団区分入力SUB">
          <Form
            ref={this.formRef}
            onFinish={(values) => this.onFinish(values)}
          >
            <div className="box_inner_horizontal">
              <div className="box_inner_vertical" style={{ gap: 0 }}>
                <Form.Item name="PersonPopulationSegments">
                  <Radio.Group style={{ marginBottom: '10px' }}>
                    <Radio value="1">個別健診</Radio>
                    <Radio value="2">集団健診</Radio>
                  </Radio.Group>
                </Form.Item>
                <div
                  className="table_header_filter"
                  style={{ justifyContent: 'center' }}
                >
                  <Form.Item style={{ margin: "0", width: '15%', textAlign: 'center' }}>
                    <label style={{ color: "#fff" }}> 区分</label>
                  </Form.Item>
                  <Form.Item style={{ margin: "0", width: '45%', textAlign: 'center' }}>
                    <label style={{ color: "#fff" }}> 個別健診</label>
                  </Form.Item>
                  <Form.Item style={{ margin: "0", width: '45%', textAlign: 'center' }}>
                    <label style={{ color: "#fff" }}> 集団健診</label>
                  </Form.Item>
                </div>

                <Descriptions
                  title=''
                  column={1}
                  bordered
                  size='small'
                  labelStyle={labelStyle}
                >
                  <Descriptions.Item
                    label='基本項目'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <div style={formInput}> {this.formRef.current?.getFieldValue('basic_individual_medical_exam_unit_price').toLocaleString() ?? 0}</div>
                      <div className="descriptions_item_boder" />
                      <div style={formInput}>{this.formRef.current?.getFieldValue('unit_price_exam_basic_population_health').toLocaleString() ?? 0}</div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='貧血検査'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <div style={formInput}> {this.formRef.current?.getFieldValue('anemia_individual_medical_exam_unit_price').toLocaleString() ?? 0}</div>
                      <div className="descriptions_item_boder" />
                      <div style={formInput}>{this.formRef.current?.getFieldValue('unit_price_exam_anemia_population_health').toLocaleString() ?? 0}</div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='心電図検査'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <div style={formInput}> {this.formRef.current?.getFieldValue('electrocardiograph_individual_medical_exam_unit_price').toLocaleString() ?? 0}</div>
                      <div className="descriptions_item_boder" />
                      <div style={formInput}>{this.formRef.current?.getFieldValue('unit_price_diagnosis_electrocardiogram_population_ken').toLocaleString() ?? 0}</div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='眼底検査'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <div style={formInput}> {this.formRef.current?.getFieldValue('fundus_individual_medical_exam_unit_price').toLocaleString() ?? 0}</div>
                      <div className="descriptions_item_boder" />
                      <div style={formInput}>{this.formRef.current?.getFieldValue('fundus_group_medical_exam_unit_price').toLocaleString() ?? 0}</div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='クレアチニン'
                    contentStyle={contentStyle}>
                    <div className="box_inner_horizontal" style={{ gap: 0 }}>
                      <div style={formInput}> {this.formRef.current?.getFieldValue('h30_creatinine_individual_medical_exam_unit_price').toLocaleString() ?? 0}</div>
                      <div className="descriptions_item_boder" />
                      <div style={formInput}>{this.formRef.current?.getFieldValue('h30_creatinine_population_checkup_unit_price').toLocaleString() ?? 0}</div>
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className="box_button_bottom_right" >
              <Button type="primary" htmlType="submit">実　行</Button>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1379001_SingleGroupClassifyInputSub);
