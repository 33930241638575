import React from 'react'
import { connect } from 'react-redux'

import { Card, message, Form, Space, Button, Checkbox } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import ResultConfirmAction from 'redux/SpecificInsureGuide/SpecificCoerciveFingerXmlOutput/ResultConfirmAction.action'
import WS1456003_PrintProcess from 'pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS1456003_PrintProcess.jsx'
import { download_file, download_file_binary } from 'helpers/CommonHelpers'


class WS2656103_ResultConfirm extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '結果確認'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      Expresstion_14: '',
      StsConfirm: '',
      Confirmation: false
    }
  }
  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    let param = {
      Visits_UserTotal: this.props.Visits_UserTotal,
      CheckErrorPeopleNum: this.props.CheckErrorPeopleNum,
      ErrorPeopleNum: this.props.ErrorPeopleNum
    }
    ResultConfirmAction.getScreenData(param)
      .then((res) => {
        this.setState({
          Expresstion_14: res.Expression_14,
          StsConfirm: res.StsConfirm,
          Confirmation: res.StsConfirm ? true : false,
        })
        this.formRef.current?.setFieldsValue({ Confirmation: res.StsConfirm ? true : false })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  xml() {
    let param = {
      fileName: this.props.fileName,
      RootFolderName: this.props.RootFolderName,
      Goutput: this.props.Goutput
    }
    ResultConfirmAction.xml(param)
      .then((res) => {
        this.downloadXml(res.outputPath)
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  downloadXml(filePath) {
    let param = {
      filePath: filePath
    }
    ResultConfirmAction.downloadXml(param)
      .then((res) => {
        // ZIPファイルダウンロード
        download_file_binary(res)
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  LogDisplay() {
    let param = {
      Goutput: this.props.Goutput
    }
    ResultConfirmAction.LogDisplay(param)
      .then((res) => {
        this.downloadLog(res)
      })
  }

  downloadLog(filePaths) {
    // 引数として受け取ったファイルパスの数APIを呼ぶ
    for (let i = 0; i < filePaths.length; i++) {
      let filePath = filePaths[i].filePath
      let param = {
        filePath: filePath
      }
      ResultConfirmAction.downloadLog(param)
        .then((res) => {
          console.log(res)
          download_file(res)
        })
    }
  }


  updateCheckbox(event) {
    let checked = event.target.checked
    this.setState({
      Confirmation: checked,
    })
  }

  render() {
    return (
      <div className='result-confirm'>
        <Card title='結果確認'>
          <Form ref={this.formRef}>
            <Space style={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.StsConfirm ? (
                <CloseCircleOutlined style={{ color: '#f00', fontSize: '40px' }} />
              ) : (
                <ExclamationCircleOutlined style={{ color: '#00f', fontSize: '40px' }} />
              )}
              {this.state.Expresstion_14}
            </Space>
            <Space
              className='box_border_bottom'
            >
              {this.state.StsConfirm ?
                <Button type='primary' onClick={() => { this.LogDisplay() }}>ログ</Button>
                : null}
              <Button type='primary ' onClick={() => {
                this.setState({
                  childModal: {
                    visible: true,
                    width: '90%',
                    component: (
                      <WS1456003_PrintProcess
                        StsConfirm={this.state.StsConfirm}
                        onFinishScreen={output => {
                          this.closeModal()
                        }}
                      />
                    )
                  }
                })
              }}>
                対象者
              </Button>
              <Button type='primary' onClick={() => { this.xml() }} >XML</Button>
            </Space>
            <Space
              style={{
                paddingTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Form.Item name='Confirmation' valuePropName='checked' style={{ marginBottom: '0' }}>
                <Checkbox onChange={(event) => { this.updateCheckbox(event) }} />
              </Form.Item>
              <Space style={{ display: 'inline-block' }}>内容を確認しました</Space>
              <Button type='primary' disabled={!this.state.Confirmation} onClick={() => { this.props.onFinishScreen() }} >確認</Button>
            </Space>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              }
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2656103_ResultConfirm)
