import axios from 'configs/axios';

const API_LIST = {
  getMainInit: '/api/document-manage-maintain/document-manage-maintain',
  getDetailSub: '/api/document-manage-maintain/document-manage-maintain/detail-sub',
  delete: '/api/document-manage-maintain/document-manage-maintain/delete',
  saveAndUpdate: '/api/document-manage-maintain/document-manage-maintain/save-and-update',
  deleteDetailSub: '/api/document-manage-maintain/document-manage-maintain/delete-other-options',
  addNewData: '/api/document-manage-maintain/document-manage-maintain/addNewData',
  update: '/api/document-manage-maintain/document-manage-maintain/update',
  changeCheckbox: '/api/document-manage-maintain/document-manage-maintain/changeCheckbox',
  saveAndUpdateParameter: '/api/document-manage-maintain/document-manage-maintain/save-and-update-parameter',
  deleteParameter: '/api/document-manage-maintain/document-manage-maintain/delete-parameter',
  getDetailSubEdit: '/api/document-manage-maintain/document-manage-maintain/get-detail-sub-edit',
};

const DocumentManageMaintainService = {
  async getMainInit(params) {
    return axios.get(API_LIST.getMainInit, { params });
  },
  async getDetailSub(params) {
    return axios.get(API_LIST.getDetailSub, { params });
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params });
  },
  async saveAndUpdate(data) {
    return axios.post(API_LIST.saveAndUpdate, data);
  },
  async deleteDetailSub(params) {
    return axios.delete(API_LIST.deleteDetailSub, { params });
  },
  async addNewData(params) {
    return axios.post(API_LIST.addNewData, params);
  },
  async update(params) {
    return axios.post(API_LIST.update, params);
  },
  async changeCheckbox(params) {
    return axios.post(API_LIST.changeCheckbox, params);
  },
  async saveAndUpdateParameter(data) {
    return axios.post(API_LIST.saveAndUpdateParameter, data);
  },
  async deleteParameter(params) {
    return axios.delete(API_LIST.deleteParameter, { params });
  },
  async getDetailSubEdit(params) {
    return axios.get(API_LIST.getDetailSubEdit, { params });
  }
};

export default DocumentManageMaintainService;
