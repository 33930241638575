import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Button, Table, Form, Tooltip } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import ReserveAvailabilityAction from 'redux/Others/ReserveStatusSearch/ReserveAvailability.action.js'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import moment from 'moment-timezone'

const formatDate = 'YYYY/MM/DD'

// 検査予約時間テーブルの幅
const examTableWidth = 180
const examBoxWidth = (examTableWidth * 5) + 40

class ReserveAvailability extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '予約空き状況'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      periodTimes: [],
      examIdTimes: [],
      showTable: false,

      selectedRows: [],
      selectedReserveTime: null,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 日付設定
    this.formRef.current.setFieldsValue({ DateChar: this.props.Li_Date ?? '' })
    this.index()
  }

  /**
   * 空き状況のデータを取得
   * @param {*} params
   */
  index = (initFlag = false) => {
    const params = {
      Li_Date: moment(this.formRef.current.getFieldValue('DateChar')).format(formatDate),
      Li_SetCodeList: this.props.Li_SetCodeList ?? [],
      Li_ContractType: this.props.dataScreen.ContractType ?? 0,
      Li_ContractClassifyCode: this.props.dataScreen.ContractClassifyCode ?? '',
      Li_ContractDate: this.props.dataScreen.ContractDate ?? '',
      Li_ContractNumber: this.props.dataScreen.ContractNum ?? 0,
      Li_ConsultCourse: this.props.dataScreen.ConsultCourse ?? '',
      initFlag: initFlag ? 1 : 0
    }

    ReserveAvailabilityAction.index(params)
      .then(res => {
        if (res) {
          // 受付時間帯
          const periodData = [...res.periodTimes]
          this.setState({
            periodTimes: periodData,
            selectedReserveTime: this.props.TimeZone ?? '' //予約受付時間が既に登録されている場合
          })

          // 検査ごとの予約時間帯
          const examIdData = [...res.examIdTimes]
          let showTableBool = false
          if (examIdData.length > 0) {
            this.setState({ examIdTimes: examIdData })
            const seletedList = [...this.props.Li_SelectedExamIdTimeList]
            if (seletedList.length > 0) {
              // 既に検査ごとの予約時間が設定されている場合
              let examIdRow, seleted, timeRows, tempArr = [], obj
              // 検査の一覧でループ
              for (let i = 0; i < examIdData.length; i++) {
                examIdRow = examIdData[i]
                // exam_idとexam_itemで比較して選択されている時間帯があるか抽出
                seleted = seletedList.find((x) => x.exam_id === examIdRow.exam_id && x.exam_item === examIdRow.exam_item)
                if (seleted) {
                  timeRows = [...examIdRow.data]
                  // 選択されている時間帯をもとに選択行データを抽出
                  obj = timeRows.find((y) => y.app_time === seleted.app_time)
                  if (obj) {
                    tempArr[i] = obj
                  }
                }
              }
              // 選択一覧を設定
              this.setState({ selectedRows: tempArr })
            }
            // 検査の時間帯を表示
            showTableBool = true
          }
          // 検査の時間帯を表示・非表示
          this.setState({ showTable: showTableBool })
        }
      })
  }

  /**
   * 確定ボタン押下処理
   */
  confirm = () => {
    const date = moment(this.formRef.current.getFieldValue('DateChar'))
    let obj = {
      dateChar: date.format(formatDate),
      timeZone: this.state.selectedReserveTime,
      selectedRows: this.state.selectedRows
    }
    if (this.props.onFinishScreen) {
      // 親画面に受付時間と検査ごとの時間を返却
      this.props.onFinishScreen(obj)
    }
  }

  /**
   * 前日
   */
  previousDay = () => {
    const date = this.formRef.current.getFieldValue('DateChar')
    // 前日に設定
    this.formRef.current.setFieldsValue({
      DateChar: moment(date).add(-1, 'days').format(formatDate)
    })
    this.index()
  }

  /**
   * 翌日
   */
  nextDay = () => {
    const date = this.formRef.current.getFieldValue('DateChar')
    // 翌日に設定
    this.formRef.current.setFieldsValue({
      DateChar: moment(date).add(1, 'days').format(formatDate)
    })
    this.index()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='reserve-availability' style={{ width: 'fit-content' }} >
        <Card
          title={'予約空き状況'}
          style={{ minWidth: 280 }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_inner_horizontal' style={{ width: 'fit-content' }}>
              <Tooltip title='前日'>
                <Button
                  icon={<LeftOutlined />}
                  onClick={() => { this.previousDay() }}
                />
              </Tooltip>
              <Form.Item name='DateChar'>
                <VenusDatePickerCustom
                  format='YYYY/MM/DD (ddd)'
                  width={160}
                  allowClear={false}
                  onChange={() => this.index()}
                />
              </Form.Item>
              <Tooltip title='翌日'>
                <Button
                  icon={<RightOutlined />}
                  onClick={() => { this.nextDay() }}
                />
              </Tooltip>
            </div>
            <div className='box_inner_horizontal'>
              {(this.state.showTable) ?

                <div className='box_inner_horizontal'
                  style={{ maxWidth: examBoxWidth, overflowX: 'auto' }} >
                  {this.state.examIdTimes.map((item, index) =>
                    <div
                      key={'kensa-' + index}
                      style={{ minWidth: examTableWidth, maxWidth: examTableWidth }}
                    >
                      <div className='table_header_filter'>
                        {item.exam_name}
                      </div>
                      <Table
                        bordered
                        size='small'
                        rowKey={record => record.id}
                        dataSource={item.data}
                        pagination={false}
                        scroll={{ y: 360 }}
                        rowClassName={(record) => record.id === this.state.selectedRows[index]?.id ? 'table-row-light'
                          : record.used_point >= record.app_point ? 'table-row-waku'
                            : ''
                        }
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: () => {
                              let rows = [...this.state.selectedRows]

                              // 左から順番にselectedRow情報を格納
                              rows[index] = { ...record }
                              // 選択行を設定
                              this.setState({ selectedRows: rows })
                            }
                          }
                        }}
                      >
                        <Table.Column
                          title='時間'
                          dataIndex='app_time'
                          render={(value, record, index) =>
                            <div>
                              {value}
                            </div>
                          }
                        />
                        <Table.Column
                          title='空き状況'
                          dataIndex='app_point'
                          className='column-size-4'
                          render={(value, record, index) =>
                            <div style={{ textAlign: 'right' }}>
                              {record.used_point + ' / ' + value}
                            </div>
                          }
                        />
                      </Table>
                    </div>
                  )}
                </div>
                :
                null
              }

              <div
                className='box_border'
                style={{ minWidth: 245, maxWidth: 245, gap: 10 }}
              >
                <div>受付をする時間帯</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: 380,
                    gap: 10
                  }}>
                  {this.state.periodTimes.map((item, index) => {
                    // 縦に並ぶボタンのラベル開始位置を揃える為、空白を設定
                    // もっといい方法があれば、そっちにする
                    let label = ''
                    let others = item.othersFlag ? '　　' : ''
                    if (item.othersCount > 0) {
                      // 保留、キャンセルの件数
                      others = '(' + item.othersCount + ')'
                    }
                    // 時間と予約済み件数を表示する
                    label = item.time + '　' + item.reserveCount + '人 ' + others
                    return (
                      <Button
                        key={'btn-' + index}
                        type={(this.state.selectedReserveTime === item.time) ? 'primary' : 'default'}
                        style={{ fontWeight: (this.state.selectedReserveTime === item.time) ? 'bold' : 'normal' }}
                        onClick={() => {
                          this.setState({ selectedReserveTime: item.time })
                        }}
                      >
                        {label}
                      </Button>
                    )
                  })}
                </div>
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                this.index(true)
              }}
            >
              再取得
            </Button>
            <Button
              type='primary'
              disabled={!(this.state.selectedReserveTime)}
              onClick={() => {
                if (this.props.onFinishScreen) {
                  this.confirm()
                }
              }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ReserveAvailability)
