import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Table, Row, Col, Menu, Dropdown, message } from 'antd'
import moment from 'moment-timezone'
import { MoreOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
// import { getListDataPatientInfoQueryStardAction, deleteDataPatientInfoQueryStardAction } from 'redux/basicInfo/PersonalInfoMaintain/PatientInfoQueryStard.actions'
import PatientInfoQueryStardAction from 'redux/basicInfo/PersonalInfoMaintain/PatientInfoQueryStard.actions.js'
import WS0061009_CheckYesNoYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061009_CheckYesNoYes'
export class WS2727001_PatientInfoQueryStard extends Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '患者情報照会[標準]'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      rowSelect: {},
      initParams: {
        KanaSearch: '',
        DateOfBirthSearch: ''
      }
    }
    this.handleSearch = debounce(this.handleSearch, 700)
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.loadData(this.state.initParams)
  }

  /**
   * 患者一覧を取得
   * @param {*} params
   */
  loadData = (params) => {
    PatientInfoQueryStardAction.index(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res.data,
          })
        }
      })
  }

  /**
   * 削除処理
   * @param {*} params
   */
  deleteData = (params) => {
    PatientInfoQueryStardAction.delete(params)
      .then(res => {
        this.loadData(this.state.initParams)
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  /**
   * 検索処理
   * @param {*} value
   * @param {*} name
   */
  handleSearch = (value, name) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        [name]: value
      }
    }, () => this.loadData(this.state.initParams))
  }

  /***
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='patient-info-query-stard'>
        <Card
          title={this.props.hideTitle ? null : '患者情報照会'} // ※[WS0343001_個人情報保守]にてthis.props.hideTitleをtrueにしている
          style={{ border: (this.props.hideTitle ?? false) ? 'none' : '' }}
          bodyStyle={{ padding: (this.props.hideTitle ?? false) ? 0 : '' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label='カナ氏名' name='KanaSearch'>
                  <Input onPressEnter={e => this.handleSearch(e.target.value, 'KanaSearch')} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='生年月日' name='DateOfBirthSearch'>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format={'NNy/MM/DD'}
                    onChange={date => this.handleSearch(moment(date).format('YYYY/MM/DD'), 'DateOfBirthSearch')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Table
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              bordered
              rowKey={(record) => record.id}
              onRow={(record, index) => ({
                onClick: event => {
                  this.setState({ rowSelect: record })
                },
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    // 選択
                    this.props.onFinishScreen({
                      Lo_PersonalNumId: record.Lo_PersonalNumId,
                      recordData: record
                    })
                  }
                }
              })}
              scroll={{ y: 450 }}
            >
              <Table.Column
                title='個人番号'
                dataIndex='patient_no'
                width={200}
                render={(text) =>
                  <div style={{ textAlign: 'right' }}>{text}</div>
                }
              />
              <Table.Column title='カナ氏名' dataIndex='kana_name' />
              <Table.Column title='漢字氏名' dataIndex='kanji_name' />
              <Table.Column
                title='性別'
                dataIndex='GenderChars'
                width={80}
                render={(value) =>
                  <div style={{
                    color: value === '男性' ? '#0F3278' : '#B41432',
                    textAlign: 'center'
                  }}>
                    {value}
                  </div>
                }
              />
              <Table.Column
                title='生年月日'
                dataIndex='birthday_on'
                width={160}
                render={(text) => moment(text).isValid() ? moment(text).format('NNy/MM/DD') : ''}
              />
              <Table.Column
                width={40}
                align='center'
                render={(value, record) => (
                  <Dropdown
                    type="primary"
                    trigger='click'
                    overlay={(<Menu>
                      <Menu.Item onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 350,
                            component: (
                              <WS0061009_CheckYesNoYes
                                Li_DisplayContent={'この患者情報を削除しますか？'}
                                onFinishScreen={(output) => {
                                  if (output.Lio_StsReturn) {
                                    let params = {
                                      patient_no: record.patient_no,
                                      StsConfirm: 1 // user confirm
                                    }
                                    this.deleteData(params)
                                  }
                                  this.closeModal()
                                }}
                              />),
                          },
                        })
                      }}>
                        削除
                      </Menu.Item>
                    </Menu>)}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )} />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS2727001_PatientInfoQueryStard)
