import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Radio, Button, Modal } from "antd";
import { PrinterOutlined, MoreOutlined } from '@ant-design/icons'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx';
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';
import WS0578001_CourseMultipleExtractScreenSub from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0578001_CourseMultipleExtractScreenSub.jsx';
import WS0435012_PreviewConfirm from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0435012_PreviewConfirm.jsx';
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from 'moment';

const labelCol = { labelCol: { style: { width: '70px' } } }
class WS1000001_ItemJudgeClassifyTbl extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '項目判定分類表';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      officeName: ''
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      GdateStartChars: moment(new Date()).format('YYYY/MM/DD (ddd)'),
      GdateEndChars: moment(new Date()).format('YYYY/MM/DD (ddd)')
    })
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }

  /**
   * 事業所情報検索・照会
   * @param {*} fieldName
   */
  showOfficeInfoRetrievalQuery(fieldName) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            _Dks020StartUpFlag={1}
            _Dks020KanshoCode={this.formRef.current?.getFieldValue("GkanshoCdStart")}
            _Dks0200fficeCd={this.formRef.current?.getFieldValue("GplantCdlnput")}
            _Dks020BranchShopCd={this.formRef.current?.getFieldValue(fieldName)}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                GplantCdInput: output.Lio_OfficeCode
              })
              this.setState({
                officeName: output.Lo_Kanji_Name
              })
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  showBasicCourseInquiry = (fieldName) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0265001_BasicCourseInquiry
            _Dks040StartUpFlag={2}
            _Dks040CourseCode={this.formRef.current?.getFieldValue(fieldName)}
            _Dks040QueryClassify={1}
            onFinishScreen={(output) => {
              this.setFormFieldValue(fieldName, output.Lo_CourseCode)
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  showWCourseMultipleExtractScreenSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 300,
        component: (
          <WS0578001_CourseMultipleExtractScreenSub
            Li_XCoordinate={null} //WinBox(0,'X')+ClickWX()
            Li_YCoordinate={null} //WinBox(0,'Y')+ClickWY()
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  showInspectItemSearchQuerySingle(fieldName) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            _Dks060StartUpFlag={1}
            _Dks060InspectCd={this.formRef.current?.getFieldValue(fieldName)}
            onFinishScreen={(output) => {
              console.log(output)
              this.setFormFieldValue(fieldName, output.Lio_InspectItemCode)
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="item-judge-classify-tbl" style={{ width: 'fit-content' }}>
        <Card title="項目判定分類表">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <Form.Item
                  label="受診日"
                  name="GdateStartChars"
                  {...labelCol}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>~</div>
                <Form.Item
                  name="GdateEndChars"
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                  />
                </Form.Item>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label="事業所"
                  name="GplantCdInput"
                  {...labelCol}
                >
                  <Input.Search
                    readOnly
                    className='input-search-size-number-10'
                    onSearch={() => this.showOfficeInfoRetrievalQuery("GplantCdInput")}
                  />
                </Form.Item>

                <Form.Item
                  name="GbranchStoreCdStart"
                  maxLength={5}
                  min={0}
                >
                  <Input.Search
                    className='input-search-size-number-4'
                    onSearch={() => this.showOfficeInfoRetrievalQuery('GbranchStoreCdStart')}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>~</div>
                <Form.Item
                  name="GbranchStoreCdEnd"
                  maxLength={5}
                  min={0}
                >
                  <Input.Search
                    className='input-search-size-number-4'
                    onSearch={() => this.showOfficeInfoRetrievalQuery('GbranchStoreCdEnd')}
                  />
                </Form.Item>

                {/* 事業所名称 */}
                <div style={{ marginTop: '5px' }}>
                  {this.state.officeName}
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label="健診コース"
                  name="GmedicalExamCourseStart"
                  {...labelCol}
                >
                  <Input.Search
                    readOnly
                    className='input-search-size-4'
                    onSearch={() => this.showBasicCourseInquiry('GmedicalExamCourseStart')}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>~</div>
                <Form.Item
                  name="GmedicalExamCourseEnd"
                >
                  <Input.Search
                    readOnly
                    className='input-search-size-4'
                    onSearch={() => this.showBasicCourseInquiry('GmedicalExamCourseEnd')}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    size='small'
                    style={{ marginTop: '4px' }}
                    icon={<MoreOutlined />}
                    onClick={() =>
                      this.showWCourseMultipleExtractScreenSub()}
                  >
                  </Button>
                </Form.Item>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  name="GinspectCodeStart"
                  label="検査項目"
                  {...labelCol}
                >
                  <Input.Search
                    className='input-search-size-number-10'
                    onSearch={() => this.showInspectItemSearchQuerySingle('GinspectCodeStart')}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>~</div>
                <Form.Item
                  name="GinspectCodeEnd"
                >
                  <Input.Search
                    className='input-search-size-number-10'
                    onSearch={() => this.showInspectItemSearchQuerySingle('GinspectCodeEnd')}
                  />
                </Form.Item>
              </div>

              <Form.Item
                name="Gv1PrintInstruction_"
                label="印刷指示"
                {...labelCol}
              >
                <Radio.Group>
                  <Radio value={1}>明細のみ</Radio>
                  <Radio value={2}>総計あり</Radio>
                  <Radio value={3}>総計のみ</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type="primary"
                icon={<PrinterOutlined />}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 300,
                      component: (
                        < WS0435012_PreviewConfirm
                          onFinishScreen={(output) => {
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  });
                }}
              >
                <span className='btn_label'>
                  印刷
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1000001_ItemJudgeClassifyTbl);
