import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, InputNumber, Button, Space, Tooltip, Modal, message, Dropdown, Menu, Select, Table } from 'antd'
import { SearchOutlined, PlusOutlined, MenuOutlined, MoreOutlined, DownloadOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers';
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';
import moment from 'moment'
import Color from 'constants/Color'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx';
import CollectJudgeAction from 'redux/InputBusiness/CollectJudge/CollectJudge.action'
import ProgressManagementAction from 'redux/Others/ProgressManagement/ProgressManagement.action.js'
import SearchConditionsConfiguration from 'pages/ZZ_Others/SANAI_ProgressManagement/SearchConditionsConfiguration.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import MemoFunction from 'pages/ZZ_Others/SANAI_Memo/MemoFunction'
import ResultDisplay from 'pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx'
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";

const dateFormat = 'YYYY/MM/DD'

const allMode = {
  allMode: 'allPages'
}

const checkBoxesMode = {
  checkBoxesMode: 'onClick'
};

const grid = {
  labelCol: { span: 4 },
}

const titleStyle = { textAlign: 'center' }
/**
 * リロード時間最低値(s)
 * @type {number}
 */
const reloadTimeMin = 10;

class ProgressManagement extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '一括判定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      FacilityType: [],
      isLoadingTable: false,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      dataScreen: {},
      Expression_39: 163,
      width: 50,
      MemoFunctionUpdateBtn: true,
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      /**
       * リロード時間(s)
       * @type {number}
       */
      reloadTime: 60,
      /**
       * 前回の再帰処理が入力によって更新されているかどうかを判別する数字
       * @type {number}
       */
      reloadTimeChangeCount: 0,
      conditionAddData: null,
      checkBoxesMode: 'always',
      checkBoxesMode2: 'none'
    }

    this.getDataTableBySearch = this.getDataTableBySearch.bind(this);
    // 初回のリロード処理を起動
    this.recursionReload(this.state.reloadTimeChangeCount);
  }
  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    CollectJudgeAction.getScreenData()
      .then(res => {
        this.setState({
          FacilityType: res ? res.FacilityType : [],
          dataScreen: res ? res : {}
        })

        this.formRef.current?.setFieldsValue({
          FacilityType: res ? res.FacilityType[0].LinkedField : '',
          Tiering: res ? res.Tiering : '',
          ReplacementInstructions: res ? res.ReplacementInstructions : '',
          ReceiptNumFormCopy: '',
          ReceiptNumTCopy: '',
          CourseCodeF: '',
          CourseCodeT: '',
          DateFChar: moment(new Date().toISOString()),
          DateTChar: moment(new Date().toISOString()),
          outputStatus: 9
        });
        this.getDataTableBySearch();
      })
  }

  // 検索処理
  getDataTableBySearch() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      DateFChar: this.formRef.current?.getFieldValue('DateFChar')?.format('YYYY/MM/DD'),
      DateTChar: this.formRef.current?.getFieldValue('DateTChar')?.format('YYYY/MM/DD'),
      conditionAddData: this.state.conditionAddData,
      InsurerCode: this.formRef.current?.getFieldValue('insurer_code')
    }

    this.setState({ isLoadingTable: true })
    ProgressManagementAction.getDataBySearch(params)
      .then(res => {
        let data = res ? res.data?.filter((x) => x.W1_logic_01) : []
        this.setState({
          dataSource: res.data,
          isLoadingTable: false,
          selectedRowKeys: data?.map(x => x.id),
          selectedRows: res.data
        })
      })
      .catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => { this.setState({ isLoadingTable: false }) })
  }

  // 判定処理
  verification() {
    this.setState({ isLoadingTable: true })
    let params = {
      Tiering: 1,
      CompositeJudge: this.state.dataScreen.CompositeJudge,
      list_id: this.state.selectedRowKeys,
      selectedRows: this.state.selectedRows
    }
    ProgressManagementAction.verification(params)
      .then((res) => {
        Modal.info({
          width: 300,
          title: '一括判定を終了しました',
          onOk: () => {
            this.getDataTableBySearch()
          }
        })
      })
      .catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res?.data?.message)
      })
  }

  // 受診日選択
  checkDate() {
    let dateStart = this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : null
    let dateEnd = this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : null

    if ((dateEnd && (dateStart > dateEnd)) || (!dateEnd && dateStart)) {
      this.formRef.current?.setFieldsValue({
        DateTChar: this.formRef.current?.getFieldValue('DateFChar')
      })
    }
  }

  /**
   * CSV複数出力準備
   */
  multipleDownloadFile() {
    const formRefValue = this.formRef?.current?.getFieldValue();

    let newSelectedRows = this.state.selectedRows.filter((value) => {
      return value.receipt_number > 0
    })

    const params = {
      selectedRows: newSelectedRows,
      Sys010Status: formRefValue?.Sys010Status ? formRefValue?.Sys010Status : 1,
      GformType: 5,
    };

    // 出力処理
    this.DownloadFile(params);
  }

  /**
   *　CSV単体出力準備
   */
  oneDownloadFile(record) {
    let list = []
    list.push(record)

    const params = {
      selectedRows: list,
      Sys010Status: record?.Sys010Status ? record?.Sys010Status : 1,
      GformType: 5,
    };

    // 出力処理
    this.DownloadFile(params);
  }

  /**
   *　出力処理
   */
  DownloadFile(params) {
    ProgressManagementAction.Downloadfile(params)
      .then((res) => {
        download_file(res)

        // 再表示
        this.getDataTableBySearch()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  // 検索条件設定
  showSearchConditionsConfiguration() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <SearchConditionsConfiguration />
        ),
      }
    })
  }

  /**
   * リロード時間入力時の処理 stateの更新
   * @param {number} val リロード時間(入力値)
   */
  onChangeReloadTime(val) {
    this.setState({ reloadTime: val !== null ? val : '' })
  }

  /**
   * リロード再帰処理開始用メソッド
   */
  onBlurReloadTime() {
    // onChangeとonBlurが同時に走った場合onBlurを後に走らせるためにsetTimeoutする
    setTimeout(() => {
      // 前回の再帰処理を止めるためにそれを判別する変数を+1する
      this.state.reloadTimeChangeCount++;
      this.recursionReload(this.state.reloadTimeChangeCount);
    });
  }

  /**
   * リロードを実行する再帰処理
   * @param {number} reloadTimeChangeCount 前回の再帰処理が入力によって更新されているかどうかを判別する数字
   * これがstateの同名の変数と一致しない場合再帰を抜ける
   */
  recursionReload(reloadTimeChangeCount) {
    if (
      this.state.reloadTime === '' ||
      reloadTimeChangeCount !== this.state.reloadTimeChangeCount
    ) { return; }

    setTimeout(() => {
      if (reloadTimeChangeCount !== this.state.reloadTimeChangeCount) { return; }
      // 検索実行
      this.getDataTableBySearch();
      this.recursionReload(reloadTimeChangeCount);
    }, this.state.reloadTime * 1000)
  }

  /**
   * テーブルのコンテキストメニュー押下時の処理
   * @param {Event} event クリックイベント
   * @param {Object} record 選択行のデータ
   */
  onClickContextMenu(event, record) {
    switch (event.key) {
      case '0': // 受診照会
        this.showConsultInquirySubModal(record);
        break;
      case '1': // 入力
        this.showOverallResultDisplayInputModal(record);
        break;
      case '2': // csv単体出力
        this.oneDownloadFile(record);
        break;
    }
  }

  /**
   * 受診照会モーダルを開く
   * @param {Object} record 選択行のデータ
   */
  showConsultInquirySubModal(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  /**
   * 個人単位入力のモーダルを開く
   * @param {Object} record 選択行のデータ
   */
  showOverallResultDisplayInputModal(record) {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.reservation_number}
            // Li_MenuOption={this.props.Li_MenuOption}
            protectFlag={record.protectFlag}
            provisionalFlag={record.provisionalFlag}
            // dataSource={this.state.dataSource}
            // currentIndex={index}
            protectImage={record.protectImage}
          // onUpdate={() => {
          //   // 子モーダルでテーブルが更新された為、受診者一覧の最新を取得する
          //   this.getExamineeList()
          // }}
          />

          // <WS2637001_OverallResultDisplayInput
          //   Li_ReserveNum={record.reservation_number}
          //   onFinishScreen={() => {
          //     this.closeModal();
          //   }}
          // />
        ),
      },
    })
  }

  // 三点メニュー用 Menu-Item
  renderMenu = (record) => (
    <Menu >
      <Menu.Item
        key='1'
        onClick={() => { this.showConsultInquirySubModal(record) }}
      >
        照会
      </Menu.Item>

      <Menu.Item
        key='2'
        onClick={() => { this.showOverallResultDisplayInputModal(record) }}
      >
        入力
      </Menu.Item>

      <Menu.Item
        key='3'
        onClick={() => { this.oneDownloadFile(record) }}
      >
        CSV単体出力
      </Menu.Item>
    </Menu>
  )

  // チェックボックが押された時の処理
  onSelectionChanged = (value) => {
    let data = value.selectedRowsData

    this.setState({
      selectedRowKeys: data.map(x => x.id),
      selectedRows: data
    })
  }

  memo = (value) => {
    console.log('メモ');
    console.log(value);

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  render() {
    return (
      <div className='collect-judge'>
        <Card title='保健指導進捗管理' className='mb-3'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.showSearchConditionsConfiguration()}
                    >
                      検索条件設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />} />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            initialValues={{
              KeyNum: 1
            }}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner' style={{ paddingRight: '20px' }}>
                    <div hidden>
                      <Form.Item name='Id'><Input /></Form.Item>
                      <Form.Item name='KeyNum'><Input /></Form.Item>
                      <Form.Item name='CourseLevel'><Input /></Form.Item>
                      <Form.Item name='ReplacementInstructions'><Input /></Form.Item>
                      <Form.Item name='Tiering'><Input /></Form.Item>
                      <Form.Item name='JudgeStart'><Input /></Form.Item>
                      <Form.Item name='ReceiptNumForm'><Input /></Form.Item>
                      <Form.Item name='ReceiptNumT'><Input /></Form.Item>
                      <Form.Item name='BranchCode'><Input /></Form.Item>
                    </div>

                    <Form.Item
                      label='受診日'
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='DateFChar'
                          style={{ marginBottom: 0 }}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            allowClear={false}
                            onBlur={() => { this.checkDate() }}
                          />
                        </Form.Item>

                        <label>~</label>

                        <Form.Item
                          name='DateTChar'
                          style={{ marginBottom: 0 }}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            allowClear={false}
                            onBlur={() => { this.checkDate() }}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item label='保険者' {...grid}>
                      <Space>
                        <Form.Item name='insurer_code' style={{ marginBottom: 0 }}>
                          <Input.Search

                            allowClear={true}
                            className='input-search-size-number-allow-clear-10'
                            onSearch={(value, event) => {
                              switch (event.type) {
                                case 'click':
                                case 'keydown':
                                  this.setState(
                                    {
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: '80%',
                                        component: (
                                          <WS0246001_InsurerInfoSearchQuery
                                            Li_insurerSearch={value}
                                            onClickedSelect={({ Lo_InsurerCode }) => {
                                              this.formRef.current.setFieldsValue({
                                                insurer_code: Lo_InsurerCode
                                              })

                                              this.closeModal()

                                            }}
                                          />
                                        )
                                      }
                                    })

                                  break;

                                default:
                                  break;
                              }
                            }}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item label='事業所'  {...grid}>
                      <Space>
                        <Form.Item name='CompanyCode' style={{ marginBottom: 0 }}>
                          <Input.Search style={{ textAlign: 'right' }}
                            allowClear={true}
                            className='input-search-size-number-allow-clear-8'
                            onSearch={(value, event) => {
                              if (value !== '' || event.target.localName !== 'input') {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '80%',
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current?.getFieldValue('CompanyCode')}
                                        Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchCode')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current?.setFieldsValue({
                                            CompanyCode: output.Lio_OfficeCode,
                                            BranchCode: output.Lio_BranchStoreCode,
                                            BranchCodeCopy: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode
                                          })

                                          this.closeModal()
                                        }}
                                      />)
                                  }
                                })
                              }
                            }}
                            onBlur={(e) => {
                              this.formRef.current?.setFieldsValue({
                                CompanyCode: e.target.value,
                              })

                              if (!e.target.value) {
                                this.formRef.current?.setFieldsValue({
                                  BranchCode: '',
                                  BranchCodeCopy: ''
                                })
                              }
                            }}
                          />
                        </Form.Item>

                        <Form.Item name='BranchCodeCopy' style={{ marginBottom: 0 }}>
                          <Input.Search style={{ marginLeft: '0.2em', textAlign: 'right' }}
                            allowClear={true}
                            className='input-search-size-number-allow-clear-5'
                            onSearch={(value, event) => {
                              if (value !== '' || event.target.localName !== 'input') {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '80%',
                                    component: (<WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('CompanyCode')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current?.setFieldsValue({
                                          CompanyCode: output.Lio_OfficeCode,
                                          BranchCode: output.Lio_BranchStoreCode,
                                          BranchCodeCopy: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode
                                        })

                                        this.closeModal()
                                      }}
                                    />)
                                  }
                                })
                              }
                            }}
                            onBlur={(e) => {
                              this.formRef.current?.setFieldsValue({
                                BranchCode: e.target.value,
                                BranchCodeCopy: e.target.value == 0 ? '' : e.target.value
                              })
                            }}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      name='outputStatus'
                      label='状態'
                      style={{ marginBottom: 0 }}
                      {...grid}
                    >
                      <Select style={{ width: 110 }}>
                        <Select.Option value={9}>{'全て'}</Select.Option>
                        <Select.Option value={0}>{'未出力'}</Select.Option>
                        <Select.Option value={1}>{'出力済み'}</Select.Option>
                      </Select>
                    </Form.Item>

                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => { this.getDataTableBySearch() }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button
                        size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              centered: true,
                              component: (
                                <WS2786001_ConditionAddSub
                                  Li_DateF={this.formRef.current?.getFieldValue('DateFChar')}
                                  Li_DateT={this.formRef.current?.getFieldValue('DateTChar')}
                                  Li_AcceptNoF={this.formRef.current?.getFieldValue('ReceiptNumForm')}
                                  Li_AcceptNoT={this.formRef.current?.getFieldValue('ReceiptNumT')}
                                  Li_CourseF={this.formRef.current?.getFieldValue('CourseCodeF')}
                                  Li_CourseT={this.formRef.current?.getFieldValue('CourseCodeT')}
                                  Li_FacilityType={this.formRef.current?.getFieldValue('FacilityType')}
                                  Li_State='1'
                                  Li_Office={this.formRef.current?.getFieldValue('CompanyCode')}
                                  Li_BranchShop={this.formRef.current?.getFieldValue('BranchCode')}
                                  Lio_KeyInfo={this.formRef.current?.getFieldValue('KeyNum')}
                                  Li_ConditionAddData={this.state.conditionAddData}

                                  onFinishScreen={(output) => {
                                    this.formRef.current?.setFieldsValue({
                                      CompanyCode: output.conditionAddData.OfficeCode,
                                      BranchCodeCopy: output.conditionAddData.BranchStoreCode,
                                      KeyNum: output.Lio_KeyInfo,
                                    })
                                    this.setState({
                                      Expression_39: output.Expression_36,
                                      conditionAddData: output.conditionAddData
                                    })
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                        }} />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <DataGrid
                style={{ height: 'calc(100vh - 380px)' }}
                dataSource={this.state.dataSource}
                keyExpr='id'
                showBorders={true} //周りの線
                showRowLines={true} //横線
                wordWrapEnabled={true} //折り返し
                allowColumnReordering={true} //カラム移動
                allowColumnResizing={true} //カラムの幅リサイズ
                columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                columnAutoWidth={true}
                hoverStateEnabled={true}
                scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない
                selection={{
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'always',
                }}

                onRowPrepared={(record) => {
                  if (record?.data?.receipt_number === 0) {
                    console.log(record)
                    record.rowElement.bgColor = '#efefef'
                    record.rowElement.cells[0].style.pointerEvents = 'none'
                  }
                }}

                // 初表示チェックボックス設定
                selectedRowKeys={this.state.selectedRowKeys}

                // チェックボックスの状態が変更された時
                onSelectionChanged={(value) => {
                  this.onSelectionChanged(value)
                }}

                // ペジネーション設定
                paging={{
                  enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                }}

                // ヘッダーの左寄席
                onCellPrepared={(record) => {
                  if (record.rowType === 'header' && record.column.dataType === 'number') {
                    record.cellElement.style.textAlign = 'left'
                  }
                }}

                // ダブルクリックされた時の処理
                onRowDblClick={(event) => {
                  const record = event.data
                  if (record.receipt_number > 0) {
                    this.oneDownloadFile(record)
                  }
                }}
              >
                <Column
                  caption='受診日'
                  dataField='visit_date_on'
                  cssClass='column-size-10'
                />

                <Column
                  caption='受付番号'
                  dataField='receipt_number'
                  cssClass='column-size-6'
                />

                <Column
                  caption='ＩＤ'
                  dataField='personal_number_id'
                  cssClass='column-size-10'
                />

                <Column
                  caption='氏名'
                  dataField='kanji_name'
                  cssClass='column-size-40'
                />

                <Column
                  caption='年齢'
                  dataField='Age'
                  cssClass='column-size-4'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'年齢'}</div>
                  }
                  cellRender={(value) => (
                    <div
                      style={{ color: Color(value.data.Age_ColorNum).Foreground, textAlign: 'center' }}
                    >
                      {value.value}
                    </div>
                  )}
                />

                <Column
                  caption='事業所'
                  dataField='office_kanji_name'
                  cssClass='column-size-40'
                />

                <Column
                  caption='保険者'
                  dataField='insurer_kanji_name'
                  cssClass='column-size-40'
                />

                <Column
                  caption='受診コース'
                  dataField='visit_course'
                  cssClass='column-size-40'
                  cellRender={(value) => (
                    <div>
                      {value.data.visit_course} {value.data.contract_short_name}
                    </div>
                  )}
                />

                <Column
                  caption='判定'
                  dataField='comprehensive_judgment'
                  cssClass='column-size-5'
                  cellRender={(value) => (
                    <div
                      style={{ color: Color(value.data.Expression_14).Foreground, textAlign: 'center' }}
                    >
                      {value.value}
                    </div>
                  )}
                />

                <Column
                  caption='メタボ'
                  dataField='result_value_1'
                  cssClass='column-size-40'
                  cellRender={(value) => (
                    <div
                      style={{ color: Color(value.data.result_value_1_ColorNum).Foreground, textAlign: 'center' }}
                    >
                      {value.value}
                    </div>
                  )}
                />

                <Column
                  caption='階層化'
                  dataField='result_value_2'
                  cssClass='column-size-40'
                  cellRender={(value) => (
                    <div
                      style={{ color: Color(value.data.result_value_2_ColorNum).Foreground, textAlign: 'center' }}
                    >
                      {value.value}
                    </div>
                  )}
                />

                <Column
                  caption='血糖'
                  dataField='BloodSugar'
                  width={this.state.width}
                  cellRender={(value) => (
                    <div
                      style={{ textAlign: 'center' }}
                    >
                      {value.value ? '●' : ''}
                    </div>
                  )}
                />

                <Column
                  caption='脂質'
                  dataField='Lipid'
                  width={this.state.width}
                  cellRender={(value) => (
                    <div
                      style={{ textAlign: 'center' }}
                    >
                      {value.value ? '●' : ''}
                    </div>
                  )}
                />

                <Column
                  caption='問診'
                  dataField='interview'
                  width={this.state.width}
                  cellRender={(value) => (
                    <div
                      style={{ textAlign: 'center' }}
                    >
                      {value.value ? '●' : ''}
                    </div>
                  )}
                />

                <Column
                  caption='計測'
                  dataField='Measurement'
                  width={this.state.width}
                  cellRender={(value) => (
                    <div
                      style={{ textAlign: 'center' }}
                    >
                      {value.value ? '●' : ''}
                    </div>
                  )}
                />

                <Column
                  caption='状態'
                  dataField='outputStatus'
                  width={this.state.width}
                  cellRender={(value) => (
                    <div
                      style={{ textAlign: 'center' }}
                    >
                      {value.value}
                    </div>
                  )}
                />

                <Column
                  caption='メモ'
                  dataField='consultationMemo'
                  cssClass='column-size-6'
                  cellRender={(value) => (
                    <div
                      style={{ display: 'flex' }}
                      // ダブルクリックされた時の処理
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 'fit-content',
                            component: (
                              <MemoFunction
                                date={this.formRef.current?.getFieldValue('visit_date_on')}
                                KanjiName={value.data.kanji_name}
                                personal_number_id={value.data.personal_number_id}
                                reservation_number={value.data.reservation_number}

                                onSaveMemos={async () => {
                                  // await this.getExamineeList()
                                  await this.closeModal()
                                }}

                                // メモ入力画面での保存ボタンの状態
                                onFlagUpdate={(bool) => {
                                  console.log('onFlagUpdate:' + bool);
                                  this.setState({
                                    MemoFunctionUpdateBtn: bool
                                  })
                                }}
                              />
                            ),
                          }
                        })
                      }}
                    >
                      {value.value.map((items, index) =>
                        <div key={index} style={{ display: 'flex' }}>
                          <div style={{ color: items.color }}>
                            {items.text}
                          </div>
                          {((value.value.length - 1) > index) ?
                            <div >
                              {'・'}
                            </div>
                            :
                            null
                          }
                        </div>
                      )
                      }
                    </div>
                  )}
                />

                <Column
                  width={45}
                  fixed={true}
                  fixedPosition='right'
                  cellRender={(event) => (
                    <div style={{ textAlign: 'center' }}>
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => this.renderMenu(event.data)}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </div>
                  )
                  }
                />
              </DataGrid>
            </div>
          </Form >

          <div className='box_button_bottom_right'>
            <Form.Item
              label='リロード時間'
            >
              <InputNumber min={reloadTimeMin}
                value={this.state.reloadTime}
                onChange={(val) => {
                  this.onChangeReloadTime(val)
                }}
                onBlur={() => {
                  this.onBlurReloadTime()
                }}
              />
            </Form.Item>
            <Button
              type='primary'
              onClick={() => {
                if (this.state.selectedRowKeys.length > 0) {
                  this.verification()
                } else {
                  Modal.info({
                    width: 300,
                    title: '一括判定を終了しました',
                    onOk: () => {

                    }
                  })
                }
              }}
            >
              <span className='btn_label'>
                実行
              </span>
            </Button>

            <Button
              style={{ float: 'right' }}
              icon={<DownloadOutlined />}
              type='primary'
              onClick={() => {
                this.multipleDownloadFile();
              }}
            >
              <span className='btn_label'>
                CSV出力
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.MemoFunctionUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgressManagement)
