import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Checkbox, Button, Row, Col, Select } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '1px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center',
  margin: '0 10px 5px 5px'
}
const styleFormItem = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  margin: 0,
}

class WS1319003_Copy extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '複写';

    this.state = {
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="copy">
        <Card className="mb-2" title="複写">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={1}>
                <Form.Item
                  name="StsFiscalYear"
                  valuePropName="checked" style={{ float: 'right' }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <div style={styleDivTitle}>年  度</div>
              </Col>
              <Col span={3}>
                <Form.Item name="Li_YearF">
                  <Input style={{ textAlign: 'right' }}></Input>
                </Form.Item>
              </Col>
              <Col span={1}>
                <Row justify="center" >
                  <ArrowRightOutlined style={{ color: "blue", fontSize: '12pt' }} />
                </Row>
              </Col>
              <Col span={3}>
                <Form.Item name="YearT">
                  <Input style={{ textAlign: 'right' }}></Input>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={1}>
                <Form.Item
                  name="StsInsurerNum"
                  valuePropName="checked" style={{ float: 'right' }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <div style={styleDivTitle}>年  度</div>
              </Col>
              <Col span={3}>
                <Form.Item name="Li_InsuranceNumF">
                  <Input style={{ textAlign: 'right' }}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="insurer_kanji_name">
                  <Input readOnly style={{ textAlign: 'right' }}></Input>
                </Form.Item>
              </Col>
              <Col span={1}>
                <Row justify="center" >
                  <ArrowRightOutlined style={{ color: "blue", fontSize: '12pt' }} />
                </Row>
              </Col>
              <Col span={3}>
                <Form.Item name="InsuranceNumT">
                  <Input style={{ textAlign: 'right' }}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="insurer_kanji_name">
                  <Input readOnly style={{ textAlign: 'right' }}></Input>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
            >
              <Button style={{ float: 'right', margin: '10px' }} type="primary">実  行</Button>
            </Form.Item>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1319003_Copy);
