import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, Select } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import MutualAidSubjectBillingItemMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MutualAidSubjectBillingItemMaintain.actions.js'
import ModalDraggable from 'components/Commons/ModalDraggable'


const labelCol = {
  labelCol: { style: { width: 85 } }
}

class WS1778002_MutualAidSubjectBillingItemItemSettingsEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '共済組合請求項目保守 [変更]'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      AgeIdentifyList: []
    }

  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の場合
      this.formRef.current.setFieldsValue({
        serial_number: this.props.record.serial_number,
        name: this.props.record.name,
        AgeIndentifyCode: this.props.record.AgeIndentifyCode,
      })
      this.forceUpdate()
    }
    if (this.props.Li_AddAndImplement === 'B') { //オプション：Bだけ共通年齢区分を設定する
      this.setState({ AgeIdentifyList: this.props.Li_AgeIdentifyList })
    }
  }

  /**
 * 保存ボタン処理
 */
  save = () => {
    const values = this.formRef.current?.getFieldsValue()
    const params = {
      id: this.props.record?.id ?? null,
      add_and_unimplemented: this.props.Li_AddAndImplement,
      options: this.props.record?.options ?? '',
      ...values
    }
    MutualAidSubjectBillingItemMaintainAction.saveItemSettings(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 削除ボタン処理
   */
  delete = () => {
    const params = {
      id: this.props.record?.id ?? null,
    }
    MutualAidSubjectBillingItemMaintainAction.deleteItemSettings(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='mutual-aid-subject-billing-item-item-settings-edit'>
        <Card title={`共済組合請求項目 [${(this.props.record === null) ? '新規追加' : '変更'}]`}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              label='連番'
              name='serial_number'
              {...labelCol}
            >
              <Input
                className='input-search-size-number-5'
              />
            </Form.Item>
            <Form.Item
              label='名称'
              name='name'
              {...labelCol}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='共通年齢区分'
              name='AgeIndentifyCode'
              {...labelCol}
            >
              <Select
                disabled={!(this.props.Li_AddAndImplement === 'B')} //オプション：Bだけ共通年齢区分を設定する
              >
                <Select.Option value=''></Select.Option>
                {this.state.AgeIdentifyList.map(item => (
                  <Select.Option
                    key={'Relationship-' + item.LinkedField}
                    value={item.LinkedField}>
                    {item.DisplayField}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              hidden={!(this.props.record)}
              onClick={() => {
                Modal.confirm({
                  content: '削除しますか？',
                  okText: <span className='btn_label'>{'削除'}</span>,
                  okButtonProps: {
                    icon: <DeleteOutlined />,
                    type: 'primary',
                    danger: true,
                  },
                  cancelText: 'キャンセル',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1778002_MutualAidSubjectBillingItemItemSettingsEdit)
