import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Modal, message } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: 90 } }
}

class WS1544004_FormatEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';

  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
  * データ保存
  */
  save = () => {
    let value = this.formRef.current?.getFieldsValue('format')
    this.props.onUpdate(value)
  }

  /**
  * データ削除
  * @param {*} id
  */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={'フォーマット [新規]'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='フォーマット'
                name='format'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1544004_FormatEdit);
