import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ItemAmountCorrectAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/ItemAmountCorrect.actions";
import WS1173001_TotalAmountCorrect from './WS1173001_TotalAmountCorrect'
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Button, Row, Col } from "antd";

const styleMargin05 = {
  margin: '0 5px'
}
const styleMarginRight48 = {
  marginRight: '48px'
}

const styleTextRight = {
  textAlign: 'right'
}
const styleBlueTitle = {
  backgroundColor: "#1890ff",
  textAlign: 'center',
  padding: "5px 0",
  color: "#fff"
}

class WS1172001_ItemAmountCorrect extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_Format: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06940:   明細金額(修正)';

    this.state = {
      tableData: [],
      Li_format: "",
      isLoading: true,
      isLoadingTable: true,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.Li_Format !== prevProps.Li_Format) {
      this.formRef.current.setFieldsValue({ Li_Format: this.props.Li_Format });
      this.setState({ isLoading: true, Li_format: this.props.Li_Format })
      this.getScreenData(this.props.Li_Format)
    }
  }

  componentDidMount() {
    this.getInit()
  }

  getInit() {
    let params = {
      Li_Format: this.props.Li_Format,
    }
    this.setState({ isLoading: true, Li_format: params.Li_Format })
    this.getScreenData(params.Li_Format)
  }

  getScreenData = (value) => {
    this.setState({ isLoadingTable: true })
    this.setState({
      isLoadingForm: true,
    })
    ItemAmountCorrectAction.getInit({ Li_Format: value })
      .then(res => {
        this.setState({
          tableData: res ? res : []
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  onFinish(values) {

  }

  ReturnComponent = component => {
    let components = {
      WS1173001_TotalAmountCorrect
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}

          />
        )
      }
    });
  };

  render() {
    return (
      <div className="item-amount-correct">
        <Card title="V4-VNS06940:   明細金額(修正)">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={2}>
                    <Form.Item
                      label="ﾌｫｰﾏｯﾄ"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3} style={styleMarginRight48}>
                    <Input style={styleTextRight} name="format" type="text" value={this.state.tableData.format} />
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label="受診日"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3} style={styleMarginRight48}>
                    <Input style={styleTextRight} name="visit_date_on" type="text" value={this.state.tableData.visit_date_on} />
                  </Col>
                  <Col span={1}>
                    <Form.Item
                      label="受付"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={2} style={styleMarginRight48}>
                    <Input style={styleTextRight} name="accepted_no" type="text" value={this.state.tableData.accepted_no} />
                  </Col>
                  <Col span={1}>
                    <Form.Item
                      label="ｺｰｽ"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={2} style={styleMarginRight48}>
                    <Input style={styleTextRight} name="visit_course" type="text" value={this.state.tableData.visit_course} />
                  </Col>
                  <Col span={4}>
                    <span>{this.state.tableData.course_name_short_name}</span>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={7} style={styleMargin05}>
                    <div style={styleBlueTitle}>実施一般</div>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="01[胸間胃間]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_01_chest_between_stomach" type="text" value={this.state.tableData.implement_general_01_chest_between_stomach} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="02[胸間胃直]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_02_chest_straight_stomach" type="text" value={this.state.tableData.implement_general_02_chest_straight_stomach} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="03[胸直胃間]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_03_chest_between_stomach" type="text" value={this.state.tableData.implement_general_03_chest_between_stomach} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="04[胸直胃直]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_04_chest_straight_stomach" type="text" value={this.state.tableData.implement_general_04_chest_straight_stomach} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="05[子宮がん]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_05_uterine_cancer" type="text" value={this.state.tableData.implement_general_05_uterine_cancer} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="06[乳子宮]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_06_breast_uterus" type="text" value={this.state.tableData.implement_general_06_breast_uterus} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="07[全未実施]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_07_chest_stomach_not_performed" type="text" value={this.state.tableData.implement_general_07_chest_stomach_not_performed} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="08[胸間のみ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_08_chest_only" type="text" value={this.state.tableData.implement_general_08_chest_only} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="09[胸直のみ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_09_chest_straight_only" type="text" value={this.state.tableData.implement_general_09_chest_straight_only} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="10[胃間のみ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_10_stomach_only" type="text" value={this.state.tableData.implement_general_10_stomach_only} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="11[胃直のみ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_11_stomach_straight_only" type="text" value={this.state.tableData.implement_general_11_stomach_straight_only} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="12[ﾏﾝﾓ1方向]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_out_gastroscope" type="text" value={this.state.tableData.implement_general_out_gastroscope} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="13[ﾏﾝﾓ2方向]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_general_out_breast_1_direction" type="text" value={this.state.tableData.implement_general_out_breast_1_direction} />
                      </Col>
                    </Row>
                    <div style={styleBlueTitle}>実施付加</div>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[付加]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_addition_added" type="text" value={this.state.tableData.implement_addition_added} />
                      </Col>
                    </Row>
                    <div style={styleBlueTitle}>実施肝炎</div>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[同日実施]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_hepatitis_same_day_implement" type="text" value={this.state.tableData.implement_hepatitis_same_day_implement} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[後日実施]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_hepatitis_later_day_implement" type="text" value={this.state.tableData.implement_hepatitis_later_day_implement} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[RNA実施]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="implement_hepatitis_rna_implement" type="text" value={this.state.tableData.implement_hepatitis_rna_implement} />
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="一般眼底実施"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="fundus_implement" type="text" value={this.state.tableData.fundus_implement} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7} style={styleMargin05}>
                    <div style={styleBlueTitle}>未実施一般</div>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[聴力検査]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_general_carried_out_hearing_test" type="text" value={this.state.tableData.unimplement_general_carried_out_hearing_test} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[尿検査]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_general_carried_out_urine_test" type="text" value={this.state.tableData.unimplement_general_carried_out_urine_test} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[便潜血1]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_general_carried_out_fecal_occult_blood_1" type="text" value={this.state.tableData.unimplement_general_carried_out_fecal_occult_blood_1} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[便潜血2]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_fecal_occult_blood_2" type="text" value={this.state.tableData.unimplement_carried_out_fecal_occult_blood_2} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[血液一般]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_blood_general" type="text" value={this.state.tableData.unimplement_carried_out_blood_general} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[血糖]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_blood_sugar" type="text" value={this.state.tableData.unimplement_carried_out_blood_sugar} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[総ｺﾚ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_total_kore" type="text" value={this.state.tableData.unimplement_carried_out_total_kore} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[ＧＯＴ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_got" type="text" value={this.state.tableData.unimplement_carried_got} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[ＧＰＴ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_gpt" type="text" value={this.state.tableData.unimplement_carried_gpt} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[ＡＬＰ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_alp" type="text" value={this.state.tableData.unimplement_carried_alp} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[γ-GTP]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_gamma_gtp" type="text" value={this.state.tableData.unimplement_carried_gamma_gtp} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[中性脂肪]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_neutral_fat" type="text" value={this.state.tableData.unimplement_carried_out_neutral_fat} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[尿酸]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_uric_acid" type="text" value={this.state.tableData.unimplement_carried_out_uric_acid} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[ｸﾚｱﾁﾆﾝ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_creatinine" type="text" value={this.state.tableData.unimplement_carried_out_creatinine} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[HDL-ｺﾚ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_hdl_kore" type="text" value={this.state.tableData.unimplement_carried_hdl_kore} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[心電図]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_electrocardiogram" type="text" value={this.state.tableData.unimplement_carried_out_electrocardiogram} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[採血]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_out_blood_collection" type="text" value={this.state.tableData.unimplement_carried_out_blood_collection} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[5-7個]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_5_7_pieces" type="text" value={this.state.tableData.unimplement_carried_5_7_pieces} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[8-9個]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_8_9_pieces" type="text" value={this.state.tableData.unimplement_carried_8_9_pieces} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[10-以上]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_carried_10_more" type="text" value={this.state.tableData.unimplement_carried_10_more} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[眼底]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_added_fundus" type="text" value={this.state.tableData.unimplement_added_fundus} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7} style={styleMargin05}>
                    <div style={styleBlueTitle}>未実施ﾌｫﾛｰ</div>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[血糖]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_blood_sugar" type="text" value={this.state.tableData.unimplement_foo_blood_sugar} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[総ｺﾚ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_total_kore" type="text" value={this.state.tableData.unimplement_foo_total_kore} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[中性脂肪]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_neutral_fat" type="text" value={this.state.tableData.unimplement_foo_neutral_fat} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[HDL-ｺﾚ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_hdl_kore" type="text" value={this.state.tableData.unimplement_foo_hdl_kore} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[ＧＯＴ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_got" type="text" value={this.state.tableData.unimplement_foo_got} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[ＧＰＴ]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_gpt" type="text" value={this.state.tableData.unimplement_foo_gpt} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[γ-GTP]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_gamma_gtp" type="text" value={this.state.tableData.unimplement_foo_gamma_gtp} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="[尿酸]"
                        >
                        </Form.Item>
                      </Col>
                      <Col span={12} style={styleTextRight}>
                        <Input style={styleTextRight} name="unimplement_foo_uric_acid" type="text" value={this.state.tableData.unimplement_foo_uric_acid} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <span>{this.state.tableData.course_name_formal}</span>
                <br />
                <br />
                <br />
                <Row>
                  <Col span={3}></Col>
                  <Col span={3} >
                    <Form.Item
                      style={{ textAlign: 'center' }}
                      label="未実施減額"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="未実施税込"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="差引健診費"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="自己負担額"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="請求額"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="うち消費税"
                    >
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="01[胸間胃間]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_01" type="text" value={this.state.tableData.unimplement_carried_out_total_01} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_01" type="text" value={this.state.tableData.unimplement_carried_tax_01} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_01" type="text" value={this.state.tableData.deduction_general_total_01} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_01" type="text" value={this.state.tableData.selfpay_general_total_01} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_01" type="text" value={this.state.tableData.billing_general_sum_01} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_01" type="text" value={this.state.tableData.consumption_tax_general_sum_01} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="02[胸間胃直]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_02" type="text" value={this.state.tableData.unimplement_carried_out_total_02} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_02" type="text" value={this.state.tableData.unimplement_carried_tax_02} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_02" type="text" value={this.state.tableData.deduction_general_total_02} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_02" type="text" value={this.state.tableData.selfpay_general_total_02} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_02" type="text" value={this.state.tableData.billing_general_sum_02} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_02" type="text" value={this.state.tableData.consumption_tax_general_sum_02} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="03[胸直胃間]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_03" type="text" value={this.state.tableData.unimplement_carried_out_total_03} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_03" type="text" value={this.state.tableData.unimplement_carried_tax_03} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_03" type="text" value={this.state.tableData.deduction_general_total_03} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_03" type="text" value={this.state.tableData.selfpay_general_total_03} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_03" type="text" value={this.state.tableData.billing_general_sum_03} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_03" type="text" value={this.state.tableData.consumption_tax_general_sum_03} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="04[胸直胃直]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_04" type="text" value={this.state.tableData.unimplement_carried_out_total_04} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_04" type="text" value={this.state.tableData.unimplement_carried_tax_04} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_04" type="text" value={this.state.tableData.deduction_general_total_04} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_04" type="text" value={this.state.tableData.selfpay_general_total_04} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_04" type="text" value={this.state.tableData.billing_general_sum_04} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_04" type="text" value={this.state.tableData.consumption_tax_general_sum_04} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="05[子宮がん]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_05" type="text" value={this.state.tableData.unimplement_carried_out_total_05} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_05" type="text" value={this.state.tableData.unimplement_carried_tax_05} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_05" type="text" value={this.state.tableData.deduction_general_total_05} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_05" type="text" value={this.state.tableData.selfpay_general_total_05} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_05" type="text" value={this.state.tableData.billing_general_sum_05} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_05" type="text" value={this.state.tableData.consumption_tax_general_sum_05} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="06[乳子宮]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_06" type="text" value={this.state.tableData.unimplement_carried_out_total_06} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_06" type="text" value={this.state.tableData.unimplement_carried_tax_06} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_06" type="text" value={this.state.tableData.deduction_general_total_06} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_06" type="text" value={this.state.tableData.selfpay_general_total_06} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_06" type="text" value={this.state.tableData.billing_general_sum_06} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_06" type="text" value={this.state.tableData.consumption_tax_general_sum_06} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="07[全未実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_07" type="text" value={this.state.tableData.unimplement_carried_out_total_07} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_07" type="text" value={this.state.tableData.unimplement_carried_tax_07} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_07" type="text" value={this.state.tableData.deduction_general_total_07} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_07" type="text" value={this.state.tableData.selfpay_general_total_07} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_07" type="text" value={this.state.tableData.billing_general_sum_07} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_07" type="text" value={this.state.tableData.consumption_tax_general_sum_07} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="08[胸間のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_08" type="text" value={this.state.tableData.unimplement_carried_out_total_08} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_08" type="text" value={this.state.tableData.unimplement_carried_tax_08} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_08" type="text" value={this.state.tableData.deduction_general_total_08} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_08" type="text" value={this.state.tableData.selfpay_general_total_08} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_08" type="text" value={this.state.tableData.billing_general_sum_08} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_08" type="text" value={this.state.tableData.consumption_tax_general_sum_08} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="09[胸直のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_09" type="text" value={this.state.tableData.unimplement_carried_out_total_09} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_09" type="text" value={this.state.tableData.unimplement_carried_tax_09} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_09" type="text" value={this.state.tableData.deduction_general_total_09} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_09" type="text" value={this.state.tableData.selfpay_general_total_09} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_sum_09" type="text" value={this.state.tableData.billing_general_sum_09} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_sum_09" type="text" value={this.state.tableData.consumption_tax_general_sum_09} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="10[胃間のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_10" type="text" value={this.state.tableData.unimplement_carried_out_total_10} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_10" type="text" value={this.state.tableData.unimplement_carried_tax_10} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_10" type="text" value={this.state.tableData.deduction_general_total_10} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_10" type="text" value={this.state.tableData.selfpay_general_total_10} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_total_10" type="text" value={this.state.tableData.billing_general_total_10} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_total_10" type="text" value={this.state.tableData.consumption_tax_general_total_10} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="11[胃直のみ]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_11" type="text" value={this.state.tableData.unimplement_carried_out_total_11} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_11" type="text" value={this.state.tableData.unimplement_carried_tax_11} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_11" type="text" value={this.state.tableData.deduction_general_total_11} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_11" type="text" value={this.state.tableData.selfpay_general_total_11} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_total_11" type="text" value={this.state.tableData.billing_general_total_11} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_total_11" type="text" value={this.state.tableData.consumption_tax_general_total_11} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="12[ﾏﾝﾓ1方向]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_12_breast_1" type="text" value={this.state.tableData.unimplement_carried_out_total_12_breast_1} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_included_12_breast_1" type="text" value={this.state.tableData.unimplement_carried_tax_included_12_breast_1} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_12" type="text" value={this.state.tableData.deduction_general_total_12} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_12" type="text" value={this.state.tableData.selfpay_general_total_12} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_total_12" type="text" value={this.state.tableData.billing_general_total_12} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_total_12" type="text" value={this.state.tableData.consumption_tax_general_total_12} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="13[ﾏﾝﾓ2方向]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_out_total_13_breast_2" type="text" value={this.state.tableData.unimplement_carried_out_total_13_breast_2} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_carried_tax_included_13_breast_2" type="text" value={this.state.tableData.unimplement_carried_tax_included_13_breast_2} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_general_total_13" type="text" value={this.state.tableData.deduction_general_total_13} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_general_total_13" type="text" value={this.state.tableData.selfpay_general_total_13} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_general_total_13" type="text" value={this.state.tableData.billing_general_total_13} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_general_total_13" type="text" value={this.state.tableData.consumption_tax_general_total_13} />
                  </Col>
                </Row>

                <Row>
                  <Col span={3}>
                    <div style={styleBlueTitle}>実施付加</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="[付加]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_addition_total" type="text" value={this.state.tableData.unimplement_addition_total} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="unimplement_added_tax" type="text" value={this.state.tableData.unimplement_added_tax} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_addition_total" type="text" value={this.state.tableData.deduction_addition_total} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_addition_total" type="text" value={this.state.tableData.selfpay_addition_total} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_addition_total" type="text" value={this.state.tableData.billing_addition_total} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_added_sum" type="text" value={this.state.tableData.consumption_tax_added_sum} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <div style={styleBlueTitle}>実施肝炎</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="[同日実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_hepatitis_total_same_day_implement" type="text" value={this.state.tableData.deduction_hepatitis_total_same_day_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_hepatitis_total_same_day_implement" type="text" value={this.state.tableData.selfpay_hepatitis_total_same_day_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_hepatitis_total_same_day_implement" type="text" value={this.state.tableData.billing_hepatitis_total_same_day_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_hepatitis_total_same_day_implement" type="text" value={this.state.tableData.consumption_tax_hepatitis_total_same_day_implement} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="[後日実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_hepatitis_total_later_day_implement" type="text" value={this.state.tableData.deduction_hepatitis_total_later_day_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_hepatitis_total_later_day_implement" type="text" value={this.state.tableData.selfpay_hepatitis_total_later_day_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_hepatitis_total_later_day_implement" type="text" value={this.state.tableData.billing_hepatitis_total_later_day_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_hepatitis_total_later_day_implement" type="text" value={this.state.tableData.consumption_tax_hepatitis_total_later_day_implement} />
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="[RNA実施]"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="deduction_hepatitis_total_rna_implement" type="text" value={this.state.tableData.deduction_hepatitis_total_rna_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="selfpay_hepatitis_total_rna_implement" type="text" value={this.state.tableData.selfpay_hepatitis_total_rna_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="billing_hepatitis_total_rna_implement" type="text" value={this.state.tableData.billing_hepatitis_total_rna_implement} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="consumption_tax_hepatitis_total_rna_implement" type="text" value={this.state.tableData.consumption_tax_hepatitis_total_rna_implement} />
                  </Col>
                </Row>
                <br />
                <br />
                <br />
                <Row>
                  <Col span={3}>
                    <Form.Item
                      label="一般眼底実施"
                    >
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="fundus_after_deduction" type="text" value={this.state.tableData.fundus_after_deduction} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="fundus_selfpay" type="text" value={this.state.tableData.fundus_selfpay} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="fundus_billing" type="text" value={this.state.tableData.fundus_billing} />
                  </Col>
                  <Col span={3}>
                    <Input style={styleTextRight} name="fundus_consumption_tax" type="text" value={this.state.tableData.fundus_consumption_tax} />
                  </Col>
                </Row>

              </Col>
            </Row>



          </Form>
          <Button style={{ float: 'right', marginTop: '1em' }} type="primary" onClick={() => {
            this.callModal({ Li_Format: this.state.Li_format }, "80%", "WS1173001_TotalAmountCorrect");
          }} >集計金額</Button>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1172001_ItemAmountCorrect);
