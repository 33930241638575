/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { MoreOutlined, SearchOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Card, Dropdown, Form, Input, Menu, Modal, message, Select, Tooltip } from 'antd'
import DataGrid, { Column, Grouping, Export } from 'devextreme-react/data-grid'
import { connect } from 'react-redux'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import MiraisTransmissionInquiryAction from 'redux/CooperationRelated/MiraisTransmissionInquiry/MiraisTransmissionInquiry.actions.js'
import WS2737001_MiraisSingleTransmission from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737001_MiraisSingleTransmission'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}

const labelCol2 = {
  labelCol: { style: { width: '75px' } }
}

class WS2737600_MiraisTransmissionInquiry extends React.Component {
  static propsType = {
    Li_Date: PropTypes.any,
    Li_Id: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    Li_Option: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'Mirais自動送信照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      visit_date_on: '',
      disableBranch: true,
      timeOutId: null,
      selectedRowKeys: [],
      selectedRows: [],

      message: '',
      isSearch: false,
      Expression_36: 163,
      recordData: {},
      visitData: {},
      dataSource: [],
      conditionAddData: null,
    }
  }

  componentDidMount() {

  }


  /**
 * 実施日の変更
 * @param {*} event
 */
  changeImplementpDate(event) {
    if (event === null) {
      this.formRef.current?.setFieldsValue({ visit_date_on: null })
    } else {
      let date = event.format('YYYY/MM/DD')
      this.setState({ visit_date_on: date })
      this.formRef.current?.setFieldsValue({ visit_date_on: moment(date) })
    }
  }

  getInfoPersonal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0248001_PersonalInfoSearchQuery
            Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNum')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                PersonalNum: output?.Lo_PersonalNumId,
                kanji_name: output?.recordData.kanji_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  getStatuList() {
    let data = this.formRef.current?.getFieldValue()
    let params = {
      visitDate: data.visit_date_on ? data.visit_date_on.format('YYYY/MM/DD') : '',
      PersonalNum: data.PersonalNum ? this.formRef.current?.getFieldValue('PersonalNum') : '',
      sended_state: data.sended_state ? this.formRef.current?.getFieldValue('sended_state') : '',
    }

    MiraisTransmissionInquiryAction.getStatuList(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res,
            ColumnIsvisible: true
          })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  openMiraisSingleTransmission = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centerd: true,
        component:
          <WS2737001_MiraisSingleTransmission
            Li_ReserveNum={record.reserve_num}
          />
        ,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='create-document-batch'>
        <Card
          title='Mirais自動送信照会'
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{
              visit_date_on: moment()
            }}
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal' style={{ width: '100%' }}>

                <div className='box_search' >
                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal' >
                      <Form.Item
                        label='受診日'
                        name='visit_date_on'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onChange={(event) => this.changeImplementpDate(event)}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='個人番号'
                        name='PersonalNum'
                        {...labelCol}
                      >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-allow-clear-8'
                          onPressEnter={() => {
                            this.getInfoPersonal()
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.getInfoPersonal()
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='kanji_name' >
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                          }}
                        >{this.formRef.current?.getFieldValue('kanji_name')}</div>
                      </Form.Item>
                    </div>
                    <Form.Item
                      name='sended_state'
                      label='自動送信'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Select style={{ width: 122 }}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({ sended_state: value })
                        }}>
                        <Select.Option value={''} >{'全て'}</Select.Option>
                        <Select.Option value={'1'} >{'完了'}</Select.Option>
                        <Select.Option value={'0'}>{'待ち'}</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.getStatuList()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='box_container'>
                <DataGrid
                  style={{ height: 500 }}
                  dataSource={this.state.dataSource}
                  showBorders={true} //周りの線
                  showRowLines={true} //横線
                  wordWrapEnabled={true} //折り返し
                  allowColumnReordering={true} //カラム移動
                  allowColumnResizing={true} //カラムの幅リサイズ
                  columnResizingMode={'widget'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                  // columnChooser={{ enabled: true, mode: 'select' }} //カラム表示選択
                  paging={{
                    // ペジネーション設定
                    enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                  }}
                  scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない
                  onRowDblClick={(event) => {
                    const record = event.data
                    this.openMiraisSingleTransmission(record)
                  }}

                >
                  <Column
                    caption='受診日' //タイトル
                    dataField='visit_date_on' //dataIndex
                    width={150}
                    fixed={true} //カラム固定
                    cellRender={(event) =>
                      <div>{moment(event.value).format('YYYY/MM/DD (ddd)')}</div>
                    }
                  />
                  <Column
                    caption='個人番号'
                    dataField='personal_number_id'
                    width={120}
                    fixed={true}
                    cellRender={(event) =>
                      <div style={{ textAlign: 'right' }}>{event.value}</div>
                    }
                  />

                  <Column
                    caption='カナ氏名'
                    dataField='kana_name'
                    width={180}
                    fixed={true}
                    cellRender={(event) =>
                      <Tooltip
                        title={event.value}
                      >
                        <div style={{
                          overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                        }}>
                          {event.value}
                        </div>
                      </Tooltip>
                    }
                  />

                  <Column
                    caption='漢字氏名'
                    dataField='kanji_name'
                    width={180}
                    fixed={true}
                    cellRender={(event) =>
                      <Tooltip
                        title={event.value}
                      >
                        <div style={{
                          overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                        }}>
                          {event.value}
                        </div>
                      </Tooltip>
                    }
                  />
                  {/* <Column
                    caption='予約番号'
                    dataField='reserve_num'
                    width={120}
                    fixed={true}
                  /> */}

                  <Column
                    caption='自動送信'
                    alignment='center'
                    dataField='sended'
                    fixed={true}
                    width={80}
                    cellRender={(event) =>
                      <div>
                        {event.value === 0 ? '待ち' : event.value === 1 ? '完了' : ''}
                      </div>
                    }
                  />

                  <Column
                    caption='送信状態'
                    alignment='center'
                    dataField='transmission_state'
                    fixed={true}
                    width={80}
                    cellRender={(event) =>
                      <div>
                        {event.value === 0 ? '△' : event.value === 1 ? '●' : ''}
                      </div>
                    }
                  />
                  <Column
                    caption='エラー'
                    dataField='error_flag'
                    fixed={true}
                    width={80}
                    cellRender={(event) =>
                      <div>
                        {event.value === true ? '有' : event.value === false ? '' : ''}
                      </div>
                    }
                  />
                  <Column
                    caption='作成日時'
                    dataField='created_at'
                    fixed={true}
                    width={150}
                  />
                  <Column
                    caption='更新日時'
                    dataField='updated_at'
                    fixed={true}
                    width={150}
                  />

                  <Column
                    alignment='center'
                    fixedPosition='right'
                    width={40}
                    cellRender={(event) => {
                      const record = event.data;
                      return (
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='mirais単体送信'
                                onClick={() =>
                                  this.openMiraisSingleTransmission(record)
                                }
                              >
                                Mirais単体送信
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      )
                    }}
                  />
                </DataGrid>
              </div>
            </div>
          </Form>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            centered={this.state.childModal.centered}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2737600_MiraisTransmissionInquiry)
