/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Upload, Input, Button, Form, message } from 'antd'
import { SaveOutlined, UploadOutlined } from '@ant-design/icons'
import BankHolidaySettingAction from 'redux/SM_SystemMaintenance/BankHolidaySetting/BankHolidaySetting.action'
import Cookies from 'js-cookie'

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

class BankHolidaySettingCapturing extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '予約状況検索（カスタム）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataSource: [{ id: 1, CaptureFolderName: '', color: '' }],
      readList: [],
      listId: 0,
      selectList: [],
    }
  }

  componentDidMount() {
    console.log(this.props);

    // this.formRef.current.setFieldsValue(this.props.record)
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: '',
        centered: false
      },
    })
  }

  /**
   * 取り込み処理
   */
  capturing() {
    //select:アップロード対象ファイル
    //read:読み取ったデータ一覧
    //upload:実際にアップロードするデータ
    let select = this.state.selectList;
    let read = this.state.readList;
    let upload = [];

    //readの中にあるファイル名がselectに存在し、まだuplodに入力していないものをpush
    for (var i = 0; i < read.length; i++) {
      if (select.find(({ name }) => name == read[i].name) &&
        !upload.find(({ name }) => name == read[i].name)) {
        upload.push(read[i])
      }
    }
    let params = {
      dataList: upload
    }
    BankHolidaySettingAction.capturing(params)
      .then((res) => {
        message.success('取込処理が完了しました。')
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='bank-holiday-setting'>
        <Card title={'法定休日設定'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Upload
              headers={{
                'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
              }}
              // directory
              withCredentials={true}

              //dataList:テキスト内容を含むデータ一覧
              //uploadList:アップロード対象のファイル一覧
              beforeUpload={() => false}
              onChange={(info) => {
                let files = info.file;
                //アップロードボタンを押した場合の処理
                if (files.status !== 'removed') {
                  const reader = new FileReader();
                  reader.readAsText(files, 'SJIS');
                  reader.onload = (e) => {

                    let tempArry = [...this.state.readList]
                    let obj = new Object();
                    obj.id = this.state.listId;
                    obj.name = files.name;
                    obj.text = reader.result;

                    tempArry.push(obj)
                    this.setState({
                      readList: tempArry,
                      listId: obj.id + 1,
                      selectList: info.fileList
                    })
                  };
                  //削除（ゴミ箱）ボタンを押した場合の処理
                } else {
                  this.setState({
                    selectList: info.fileList
                  })
                }
              }}
            >
              <Button
                type='primary'
                icon={<UploadOutlined />} >
                アップロード
              </Button>
            </Upload>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.capturing()}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankHolidaySettingCapturing)