import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StyleQueryAction from 'redux/InputBusiness/NotInputCheckCategory/StyleQuery.action'
import { Card, Table } from 'antd'

class WS3020054_StyleQuery extends React.Component {
  static propTypes = {
    Lio_ParentCode: PropTypes.any,

  }

  constructor(props) {
    super(props)

    // document.title = '様式照会'

    this.state = {
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  index() {
    StyleQueryAction.index()
      .then(res => {
        this.setState({ dataSource: res })
      })
  }

  render() {
    return (
      <div className='style-query'>
        <Card title='様式照会'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record, recordIndex) => ({
              onDoubleClick: () => {
                // 親画面に反映
                this.props.onFinishScreen({
                  Lio_ParentCode: record.parent_code,
                  recordData: record,
                })
              }
            })}
          >
            <Table.Column
              title='様式'
              dataIndex='parent_code'
              width={100}
              render={(value) =>
                <div style={{ textAlign: 'center' }}>{value}</div>
              }
            />
            <Table.Column
              title='様式名称'
              dataIndex='style_name'
            />
            {/* <Table.Column width={100} align='center'
              render={(value, record) => (
                <Button size='small' type='primary'
                  onClick={() => {
                    this.props.onFinishScreen({
                      Lio_ParentCode: record.parent_code,
                      recordData: record,
                    })
                  }}>選択</Button>
              )} /> */}
          </Table>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3020054_StyleQuery)
