// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/img/background/リボン改.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.login-page {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-attachment: fixed;\n  height: 125vh;\n}", "",{"version":3,"sources":["webpack://src/pages/MN_Basis/V5MN0001000_Main/WS2517001_SignInScreen.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,yDAAA;EACA,sBAAA;EACA,4BAAA;EACA,aAAA;AAEF","sourcesContent":[".login-page {\n  background-image: url(../../../assets/img/background/リボン改.jpg);\n  background-size: cover;\n  background-attachment: fixed;\n  height: 125vh;\n  // &::before {\n  //   content: \"\";\n  //   display: block;\n  //   position: absolute;\n  //   left: 0;\n  //   right: 0;\n  //   top: 0;\n  //   bottom: 0;\n  //   filter: blur(4px);\n  // }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
