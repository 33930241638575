import axios from 'configs/axios'

const API_LIST = {
  index: '/api/inspect-request-main/personal-info-inquiry-sub',
}
const PersonalInfoInquirySubService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default PersonalInfoInquirySubService
