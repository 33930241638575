import React from "react";
import { connect } from "react-redux";

import { Button, Card, Input, } from "antd";


class WS1512066_ItemModification extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '項目修正';

    this.state = {
    };
  }

  render() {
    return (
      <div className="item-modification">
        <Card className="mb-2" title="項目修正">
          <Input value="項目説明" readOnly style={{border:'none', margin:'10px'}}/> 
          <Input name="ItemDescription"/> 
          <Button type="primary" style={{float:'right',margin:'20px'}}>更  新</Button>

        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1512066_ItemModification);
