import axios from 'configs/axios'

const apiPaths = {
  index: '/api/interview-support/result-display',
  getCategoryJudgeList: '/api/interview-support/result-display/get-category-judgeList',
  getInspectResultList: '/api/interview-support/result-display/get-inspect-result-list',
  getDetailInspectResultList: '/api/interview-support/result-display/get-detail-inspect-result-list',
  uploadImages: '/api/interview-support/image-display/upload-images',
  getTargetPaths: '/api/interview-support/image-display/get-target-paths',
  getImage: '/api/interview-support/image-display/get-image',
  deleteImage: '/api/interview-support/image-display/delete-image',
  getImageRefelenceUrl: '/api/interview-support/image-display/get-image-refelence-url',
  reName: '/api/interview-support/image-display/rename',
  tidyingUp: '/api/interview-support/image-display/tidying-up',
  changeProgressData: '/api/interview-support/result-display/change-progress-data',
  runEMedical: '/api/interview-support/result-display/run-e-medical',
  getResultsChangeHistory: '/api/interview-support/inspection-results-change-history',

  indexView: '/api/external/interview-support-view',
  getCategoryJudgeListView: '/api/external/interview-support-view/get-category-judgeList',
  getInspectResultListView: '/api/external/interview-support-view/get-inspect-result-list',
  getDetailInspectResultListView: '/api/external/interview-support-view/get-detail-inspect-result-list',
  getReserveNum: '/api/external/interview-support-view/get-reserve-num',
}

const ResultDisplayService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getCategoryJudgeList(params) {
    return axios.get(apiPaths.getCategoryJudgeList, { params })
  },
  async getInspectResultList(params) {
    return axios.get(apiPaths.getInspectResultList, { params })
  },
  async getDetailInspectResultList(params) {
    return axios.get(apiPaths.getDetailInspectResultList, { params })
  },
  async uploadImages(params) {
    return axios.post(
      apiPaths.uploadImages,
      params,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    )
  },
  async getTargetPaths(params) {
    return axios.get(apiPaths.getTargetPaths, { params })
  },
  async getImage(params) {
    return axios.post(apiPaths.getImage, params, { responseType: 'blob' })
  },
  async deleteImage(params) {
    return axios.post(apiPaths.deleteImage, params)
  },
  async getImageRefelenceUrl(params) {
    return axios.get(apiPaths.getImageRefelenceUrl, { params })
  },
  async reName(params) {
    return axios.post(apiPaths.reName, params)
  },
  async tidyingUp(params) {
    return axios.post(apiPaths.tidyingUp, params)
  },
  async changeProgressData(params) {
    return axios.post(apiPaths.changeProgressData, params)
  },
  async runEMedical(params) {
    return axios.post(apiPaths.runEMedical, params)
  },
  async getResultsChangeHistory(params) {
    return axios.get(apiPaths.getResultsChangeHistory, { params })
  },

  async indexView(params) {
    return axios.get(apiPaths.indexView, { params })
  },
  async getCategoryJudgeListView(params) {
    return axios.get(apiPaths.getCategoryJudgeListView, { params })
  },
  async getInspectResultListView(params) {
    return axios.get(apiPaths.getInspectResultListView, { params })
  },
  async getDetailInspectResultListView(params) {
    return axios.get(apiPaths.getDetailInspectResultListView, { params })
  },
  async getReserveNum(params) {
    return axios.get(apiPaths.getReserveNum, { params })
  },
}
export default ResultDisplayService
