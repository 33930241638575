import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Space, Row, Col, message } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1386001_InspectItemInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000100_SupportItem/WS1386001_InspectItemInquiry";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import AddInspectAction from 'redux/SpecificInsureGuide/InsureGuideInitInput/AddInspect.action'

class WS2654025_AddInspect extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '検査追加';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      data: {
        DisplayOrder: "",
        InspectCode: "",
        InspectName: "",
      }
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  selectInspectCode() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS1386001_InspectItemInquiry
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({ InspectCode: output.exam_code })
              this.setState({ data: { ...this.state.data, InspectCode: output.exam_code, InspectName: output.formal_name } });
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  confirm() {
    const { Li_Date, Li_Id, Li_SerialNum } = this.props;
    let InspectCode = this.formRef.current?.getFieldValue('InspectCode')
    let DisplayOrder = this.formRef.current?.getFieldValue('DisplayOrder')

    if (DisplayOrder === undefined) {
      return message.error('表示順を入力してください')
    }
    if (InspectCode === undefined) {
      return message.error('検査を入力してください')
    }

    let dataSource = this.props.dataSource
    for (let i = 0; i < dataSource.length; i++) {
      if (InspectCode === dataSource[i].display_order) {
        return message.error('登録済みの検査です')
      }
    }

    const param = {
      Li_Date,
      Li_PersonalNum: Li_Id,
      Li_SerialNum,
      InspectCode,
      DisplayOrder,
    };
    AddInspectAction.confirm(param)
      .then(res => {
        this.setState({
          data: {
            DisplayOrder: "",
            InspectCode: "",
            InspectName: "",
          }
        });
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen();
        }
      })
  }

  render() {
    return (
      <div className="add-inspect">
        <Card title="検査追加">
          <Form
            ref={this.formRef}
            //onFinish={(value) => this.confirm()}
            autoComplete='off'
          >
            <div className="box_inner_vertical">
              <Form.Item
                label="表示順"
                name="DisplayOrder"
                rules={[{ required: true }]}
              >
                <Input style={{ width: '120px' }} />
              </Form.Item>

              <div className="box_inner_horizontal">
                <Form.Item
                  label="検　査"
                  name="InspectCode"
                  style={{ marginBotom: 0 }}
                  rules={[{ required: true }]}
                >
                  <Input.Search
                    style={{ width: '120px' }}
                    onSearch={() => this.selectInspectCode()}
                  />
                </Form.Item>
                <div style={{ marginTop: '5px' }}>{this.state.data.InspectName}</div>
              </div>
            </div>

            <div className="box_button_bottom_right" style={{ paddingTop: 0 }}>
              <Button
                type="primary"
                onClick={() => {
                  this.confirm()
                }}
              >
                確定
              </Button>
            </div>
          </Form>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2654025_AddInspect);
