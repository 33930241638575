import axios from 'configs/axios'

const API_LIST = {
  getLogLaravel: '/api/log-query/log-query/get-log-laravel',
  getLogDetailLaravel: '/api/log-query/log-query/get-log-detail-laravel',
  getLogApi: '/api/log-query/log-query/get-log-api',
  getLogSql: '/api/log-query/log-query/get-log-sql',
  getLogApplication: '/api/log-query/log-query/get-log-application',
  getLogKind: '/api/log-query/log-query/get-log-kind',
  getCapacity: '/api/log-query/log-query/get-capacity',
  getLogTop: '/api/log-query/log-query/get-log-top',
  getLogFree: '/api/log-query/log-query/get-log-free',
  getLogDf: '/api/log-query/log-query/get-log-df',
  getLogDocker: '/api/log-query/log-query/get-log-docker',
  getLogSerrerError: '/api/log-query/log-query/get-log-serrer-error',
  getLogSerrerOut: '/api/log-query/log-query/get-log-serrer-out',
}

const LogQueryService = {
  async getLogLaravel(params) {
    return axios.post(API_LIST.getLogLaravel, params)
  },
  async getLogDetailLaravel(params) {
    return axios.post(API_LIST.getLogDetailLaravel, params)
  },
  async getLogApi(params) {
    return axios.post(API_LIST.getLogApi, params)
  },
  async getLogSql(params) {
    return axios.post(API_LIST.getLogSql, params)
  },
  async getLogApplication(params) {
    return axios.post(API_LIST.getLogApplication, params)
  },
  async getLogKind(params) {
    return axios.post(API_LIST.getLogKind, params)
  },
  async getCapacity(params) {
    return axios.post(API_LIST.getCapacity, params)
  },
  async getLogTop(params) {
    return axios.post(API_LIST.getLogTop, params)
  },

  async getLogFree(params) {
    return axios.post(API_LIST.getLogFree, params)
  },

  async getLogDf(params) {
    return axios.post(API_LIST.getLogDf, params)
  },

  async getLogDocker(params) {
    return axios.post(API_LIST.getLogDocker, params)
  },
  async getLogSerrerError(params) {
    return axios.post(API_LIST.getLogSerrerError, params)
  },

  async getLogSerrerOut(params) {
    return axios.post(API_LIST.getLogSerrerOut, params)
  },
}

export default LogQueryService
