/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { SearchOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Modal, message, Select, Tooltip } from 'antd'
import { connect } from 'react-redux'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2786002_ConditionAddSubPlus from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786002_ConditionAddSubPlus.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS0650002_TargetSelectDocument from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650002_TargetSelectDocument'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub.jsx'
import CreateDocumentBatchAction from 'redux/AdvancePreparation/DocumentBatchCreate/CreateDocumentBatch.actions'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}

const labelCol2 = {
  labelCol: { style: { width: '75px' } }
}

class WS0649001_CreateDocumentBatch extends React.Component {
  static propsType = {
    Li_Date: PropTypes.any,
    Li_Id: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    Li_Option: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.office = React.createRef()
    // document.title = '資料一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      FacilityNum: [],
      OutputOrder: [],
      disableBranch: true,
      initialValues: {
        office_kana_name: '',
        phone_number: '',
        insurer_card_symbol: '',
        methods_op: true,
      },
      timeOutId: null,
      errorOfficeCode: false,
      errorBranchStore: false,
      selectedRowKeys: [],
      selectedRows: [],

      message: '',
      isSearch: false,
      Expression_36: 163,
      recordData: {},
      visitData: {},
      dataSource: [],
      conditionAddData: null,
    }
  }

  componentDidMount() {
    this.GetScreenData()
  }

  GetScreenData = () => {
    let data = {
      Li_Date: this.isEmpty(this.props.Li_Date) ? '' : this.props.Li_Date,
      Li_Id: this.isEmpty(this.props.Li_Id) ? '' : this.props.Li_Id,
      Li_Course: this.isEmpty(this.props.Li_Course) ? '' : this.props.Li_Course,
      Li_OutputPattern: this.isEmpty(this.props.Li_OutputPattern)
        ? ''
        : this.props.Li_OutputPattern,
      Li_Option: this.isEmpty(this.props.Li_Option) ? '' : this.props.Li_Option,
    }
    CreateDocumentBatchAction.GetScreenData(data)
      .then((res) => {
        let data = res.dataView
        data.ReserveDateFChar = this.isEmpty(data?.ReserveDateFChar)
          ? moment(null)
          : moment(data?.ReserveDateFChar)
        data.ReserveDateTChar = this.isEmpty(data?.ReserveDateTChar)
          ? moment(null)
          : moment(data?.ReserveDateTChar)
        data.FacilityNum = this.isEmpty(data.FacilityNum)
          ? 0
          : data.FacilityNum
        this.formRef.current?.setFieldsValue(data)
        this.formRef.current?.setFieldsValue({
          updateFlag: false,
          printCondition: 2
        })
        this.setState({
          OutputOrder: res?.OutputOrder ? res?.OutputOrder : [],
          FacilityNum: res?.FacilityNum ? res?.FacilityNum : [],
        })
      })
      .catch((error) => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false
  }

  DisplayBtn() {
    if (!this.formRef.current?.getFieldValue('ReserveDateFChar') || !this.formRef.current?.getFieldValue('ReserveDateTChar')) {
      Modal.error({
        content: '日付を選択してください',
        okText: 'OK',
        onOk: () => {
          this.closeModal()
        }
      })
      return
    }

    let data = this.formRef.current?.getFieldValue()
    let dataExe = {
      ReserveDateF: data.ReserveDateFChar ? data.ReserveDateFChar.format('YYYY/MM/DD') : '',
      ReserveDateT: data.ReserveDateTChar ? data.ReserveDateTChar.format('YYYY/MM/DD') : '',
      OfficeCode: data.OfficeCode ?? '',
      PersonalNum: data.PersonalNum ? this.formRef.current?.getFieldValue('PersonalNum') : '',
      OutputOrder: data.OutputOrder,
      conditionAddData: this.state.recordData,
    }

    CreateDocumentBatchAction.DisplayBtn(dataExe)
      .then(async (res) => {
        await this.setState({
          message: res.message,
          visitData: res.visitData,
          isSearch: true,
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  postChange(value) {
    let dataSource = [...this.state.dataSource]
    let reserve_num = []
    let copyDataSource = []
    let copyObj
    const currentDate = moment(new Date()).format('YYYY/MM/DD')
    for (let i = 0; i < dataSource.length; i++) {
      copyObj = { ...dataSource[i] }
      // 入力した個人番号が一致するまで回す 一致したら配列に格納
      if (copyObj.personal_number_id === value) {
        if (copyObj.printDate !== '') {
          reserve_num.push(copyObj.W1_reserve_num)
          copyObj.postDate = currentDate
        }
      }
      copyDataSource.push(copyObj)
    }
    // 個人番号が一つも一致しなかった場合APIを呼ばない
    if (reserve_num.length !== 0) {
      let params = {
        reserve_num: reserve_num
      }
      CreateDocumentBatchAction.postChange(params)
        .then(async (res) => {
          this.formRef.current?.setFieldsValue({
            updateFlag: true,
            postDateUpdate: ''
          })
          this.setState({
            dataSource: copyDataSource
          })
        })
    } else {
      // 一人も対象にならずにreserve_numの中身がない場合以下のエラーを出す
      message.error('印刷を行ってください')
      this.formRef.current?.setFieldsValue({
        postDateUpdate: ''
      })
    }
  }

  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateFChar')
        .format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateTChar')
        .format('YYYY/MM/DD')
      : null
    if (dateEnd && dateStart > dateEnd) {
      this.formRef.current?.setFieldsValue({
        ReserveDateFChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      })
    }
  }

  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateFChar')
        .format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateTChar')
        .format('YYYY/MM/DD')
      : null
    if ((!dateEnd && dateStart) || (dateStart && dateStart > dateEnd)) {
      this.formRef.current?.setFieldsValue({
        ReserveDateTChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      })
    }
  }

  renderTargetSub() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      Li_Random: Math.random()
    }
    return (
      <WS0650002_TargetSelectDocument
        isSearch={this.state.isSearch}
        message={this.state.message}
        params={params}
        OutputOrder={1}
        onSelectedRowKeys={(output) => {
          this.setState({ selectedRowKeys: output })
        }}
      />
    )
  }

  allowClear = () => {
    this.formRef.current?.setFieldsValue({
      office_kanji_name: ''
    })
    this.forceUpdate()
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='create-document-batch'>
        <Card
          title='予約関連資料出力'
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{
              ReserveDateFChar: moment(),
              ReserveDateTChar: moment()
            }}
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal' style={{ width: '100%' }}>

                <div className='box_search' >
                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal' >
                      <Form.Item
                        label='受診日'
                        name='ReserveDateFChar'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onBlur={() => {
                            this.checkDateStart()
                          }} />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='ReserveDateTChar'>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onBlur={() => {
                            this.checkDateEnd()
                          }} />
                      </Form.Item>
                    </div>

                    {/* <Form.Item label='事務所'> */}
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='事業所'
                        name='OfficeCode'
                        {...labelCol}
                      >
                        <Input.Search
                          allowClear={true}
                          ref={this.office}
                          className='input-search-size-number-allow-clear-8'
                          onPressEnter={() => {
                            if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '80%',
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCodeF')}
                                      onFinishScreen={(output) => {
                                        if (output?.Lio_OfficeCode) {
                                          this.setState({ disableBranch: false })
                                        }
                                        this.formRef.current?.setFieldsValue({
                                          OfficeCode: output?.Lio_OfficeCode,
                                          BranchStoreCodeF: output?.Lio_BranchStoreCode,
                                          office_kanji_name: output?.recordData?.office_kanji_name
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '80%',
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                        Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCodeF')}
                                        onFinishScreen={(output) => {
                                          if (output?.Lio_OfficeCode) {
                                            this.setState({ disableBranch: false })
                                          }
                                          this.formRef.current?.setFieldsValue({
                                            OfficeCode: output?.Lio_OfficeCode,
                                            BranchStoreCodeF: output?.Lio_BranchStoreCode,
                                            office_kanji_name: output?.recordData?.office_kanji_name
                                          })
                                          this.forceUpdate()
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }
                            }
                          }
                          }
                        />
                      </Form.Item>
                      <Form.Item name='office_kanji_name' >
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                          }}
                        >{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='個人番号'
                        name='PersonalNum'
                        {...labelCol}
                      >
                        <Input.Search
                          allowClear={true}
                          ref={this.office}
                          className='input-search-size-number-allow-clear-8'
                          onPressEnter={() => {
                            if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '80%',
                                  component: (
                                    <WS0248001_PersonalInfoSearchQuery
                                      Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNum')}
                                      onFinishScreen={(output) => {
                                        if (output?.Lio_OfficeCode) {
                                          this.setState({ disableBranch: false })
                                        }
                                        this.formRef.current?.setFieldsValue({
                                          PersonalNum: output?.Lo_PersonalNumId,
                                        })
                                        this.forceUpdate()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '80%',
                                    component: (
                                      <WS0248001_PersonalInfoSearchQuery
                                        Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNum')}
                                        onFinishScreen={(output) => {
                                          if (output?.Lio_OfficeCode) {
                                            this.setState({ disableBranch: false })
                                          }
                                          this.formRef.current?.setFieldsValue({
                                            PersonalNum: output?.Lo_PersonalNumId,
                                            kanji_name: output?.recordData.kanji_name
                                          })
                                          this.forceUpdate()
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }
                            }
                          }
                          }
                        />
                      </Form.Item>
                      <Form.Item name='kanji_name' >
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                          }}
                        >{this.formRef.current?.getFieldValue('kanji_name')}</div>
                      </Form.Item>
                    </div>
                    <Form.Item
                      name='OutputOrder'
                      label='出力順'
                      {...labelCol}
                    >
                      <Select>
                        {this.state.OutputOrder?.map(val => (
                          <Select.Option key={'OutputOrder-' + Math.random()} value={val.Linked} >{val.Display}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='printCondition'
                      label='印刷状態'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Select style={{ width: 122 }}>
                        <Select.Option value={0} >{'全て'}</Select.Option>
                        <Select.Option value={1} >{'印刷済み'}</Select.Option>
                        <Select.Option value={2} >{'未印刷'}</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        if (!this.state.errorBranchStore && !this.state.errorOfficeCode)
                          this.formRef.current?.setFieldsValue({
                            updateFlag: false
                          })
                        this.DisplayBtn()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                            this.setState({ isSearch: false })
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '850px',
                                centered: true,
                                component: (
                                  <WS2786002_ConditionAddSubPlus
                                    Li_DateF={this.formRef.current?.getFieldValue('ReserveDateFChar') ? this.formRef.current?.getFieldValue('ReserveDateFChar')?.format('YYYY/MM/DD') : ''}
                                    Li_DateT={this.formRef.current?.getFieldValue('ReserveDateTChar') ? this.formRef.current?.getFieldValue('ReserveDateTChar')?.format('YYYY/MM/DD') : ''}
                                    Li_Office={this.formRef.current?.getFieldValue('OfficeCode')}
                                    Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNum')}
                                    Lio_KeyInfo={this.formRef.current?.getFieldValue('OutputOrder')}
                                    Li_ConditionAddData={this.formRef.current?.getFieldValue('recordData')}

                                    onFinishScreen={(output) => {
                                      this.formRef.current?.setFieldsValue({
                                        OutputOrder: output?.Lio_KeyInfo,
                                        recordData: output?.conditionAddData
                                      })
                                      this.setState({
                                        Expression_36: output.Expression_36,
                                        recordData: output?.conditionAddData ? output?.conditionAddData : {},
                                        conditionAddData: output.conditionAddData,
                                      })

                                      this.closeModal()
                                    }
                                    }
                                  />
                                ),
                              },
                            })
                          }
                        }} />
                    </Tooltip>
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'end', margin: '0 0 0 auto' }}>
                  <div className='box_search_inner' style={{ minWidth: '100px' }}>
                    <Form.Item
                      label='人数'
                      name='totalPeople'
                      style={{ marginBottom: 0 }}
                      {...labelCol2}
                    >
                      <div
                        style={{ textAlign: 'right', marginRight: 5 }}
                      >{this.state.dataSource.length + '人'}</div>
                    </Form.Item>

                    <Form.Item
                      label='バーコード'
                      name='postDateUpdate'
                      style={{ marginBottom: 0 }}
                      {...labelCol2}
                    >
                      <Input
                        disabled={this.state?.dataSource?.length === 0}
                        type='number'
                        className='input-size-20'
                        onPressEnter={(e) => this.postChange(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className='box_container'>
                {/* 対象受診者テーブル */}
                <WS0650002_TargetSelectDocument
                  isSearch={this.state.isSearch}
                  message={this.state.message}
                  visitData={this.state.visitData}
                  changeFlag={this.state.changeFlag}
                  dataSource={this.state.dataSource}
                  KeyInfo={this.formRef.current?.getFieldValue('OutputOrder')}
                  updateFlag={this.formRef.current?.getFieldValue('updateFlag')}
                  printCondition={this.formRef.current?.getFieldValue('printCondition')}
                  onSelectedRowKeys={(output) => {
                    this.setState({ selectedRowKeys: output })
                  }}
                  onSelectedData={(output) => {
                    this.setState({ selectedRows: output })
                  }}
                  onDataSource={(output) => {
                    this.setState({ dataSource: output })
                  }}
                  onPrint={() => {
                    this.formRef.current?.setFieldsValue({
                      updateFlag: true
                    })
                    this.DisplayBtn()
                  }}
                />
              </div>

              <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
                <Button
                  icon={<PrinterOutlined />}
                  type='primary'
                  onClick={() => {
                    if (!this.state.errorBranchStore && !this.state.errorOfficeCode)
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS0650001_DocumentBatchCreateSub
                              selectedList={this.state.selectedRows}
                              Li_CourseLevel={''}
                              Li_ReserveNum={''}
                              Li_OutputUnit={this.props?.Li_Option}
                              Li_OutputPattern={this.props?.Li_OutputPattern}
                              onFinishScreen={(output) => {
                                this.closeModal()
                                this.formRef.current?.setFieldsValue({
                                  updateFlag: true
                                })
                                this.DisplayBtn()
                              }}
                            />
                          ),
                        },
                      })
                  }} >
                  <span className='btn_label'>
                    印刷
                  </span>
                </Button>
              </div>
            </div>
          </Form>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            centered={this.state.childModal.centered}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0649001_CreateDocumentBatch)
