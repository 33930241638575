import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";

import {
  Card,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  message,
  Modal,
  InputNumber
} from "antd";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx";
import WS0887001_IntroduceLetterVariousMasterInquiry from "pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx";
import WS0433001_PrinterConfirm from "pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import AskIssuedAction from 'redux/SK_IntroductionLetter/AskIssued/AskIssued.action'
import moment from "moment";

const labelSize = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 70px)' } }
}
class WS0915001_AskIssued extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'おたずね発行';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      FacilityType: []
    };
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    AskIssuedAction.GetScreenData()
      .then(res => {
        console.log(res);
        this.formRef.current?.setFieldsValue({
          DateFChar: res.DateFChar,
          DateTChar: res.DateTChar,
          FacilityType: res.FacilityType_1[0].facility_type
        })
        this.setState({
          FacilityType: res.FacilityType_1,
          TextFile: res.TextFile,
          LetterIntroductionIssuedByState: res.LetterIntroductionIssuedByState
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onFinish(values) { }
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }
  showOfficeInfoRetrievalQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "80%",
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue("OfficeCode")}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue("BranchStoreCode")}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output.Lio_OfficeCode,
                BranchStoreCode: output.Lio_BranchStoreCode
              });
              this.forceUpdate();
              this.closeModal();
            }}
          />
        )
      }
    });
  }

  showIntroduceLetterVariousMasterInquiry = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "80%",
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={7}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                Department: output.Lo_VariousCodes
              });
              this.forceUpdate();
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  showPrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={this.formRef.current?.getFieldValue("PreviewMode")}
            Li_PrinterNoSpecifiedValue={this.formRef.current?.getFieldValue("PrinterNum")}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                PreviewMode: output.Lo_Preview,
                PrinterNum: output.Lo_PrinterNo,
                StsConfirm: output.Lo_StsOutput
              });
              this.printBtn(output);
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  printBtn(output) {
    let data = this.formRef.current?.getFieldValue()
    console.log(data);
    let params = {
      DateF: moment(data.DateFChar).format('YYYY/MM/DD'),
      DateT: moment(data.DateTChar).format('YYYY/MM/DD'),
      ReceiptNumF: data.ReceiptNumF,
      ReceiptNumT: data.ReceiptNumT,
      OfficeCode: data.OfficeCode,
      BranchStoreCode: data.BranchStoreCode,
      FacilityType_1: data.FacilityType,
      Department: data.Department,
      AskIssuingState: data.AskIssuingState,
      LetterIntroductionIssuedByState: this.state.LetterIntroductionIssuedByState,
      TextFile: this.state.TextFile,
      PreviewMode: output.Lo_Preview,
      PrinterNum: output.Lo_PrinterNo
    }
    if (output.Lo_Preview) {
      AskIssuedAction.preview(params)
        .then(res => {
          // pdfのObject作成　erviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data)
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
        })
        .catch(error => {
          const res = error.response
          if (res) {
            if (res.request.responseType === 'blob') {
              res.data.text()
                .then(str => {
                  let json = JSON.parse(str);
                  message.error(json.message)
                })
            }
            if (error.message.includes('504')) {
              return Modal.error({
                title: 'タイムアウトしました',
                content: '',
              });
            }
          }
        })
    } else {
      AskIssuedAction.Print_F12(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            // message.error('処理の途中でエラーが発生しました')
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
        })
        .catch(error => {
          const res = error.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  render() {
    var today =
      new Date().getFullYear() +
      "/" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + new Date().getDate()).slice(-2);
    return (
      <div className="ask-issued" style={{ width: 700 }}>
        <Card title="おたずね発行">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              ReceiptNumT: 999999,
              AskIssuingState: 1,
              FacilityType: "",
              DateFChar: moment(today),
              DateTChar: moment(today)
            }}
          >
            <div className="box_inner_horizontal">
              <Form.Item name="DateFChar" label="受診日" {...labelSize}>
                <VenusDatePickerCustom formRefDatePicker={this.formRef} />
              </Form.Item>
              <Form.Item name="DateTChar" label="〜">
                <VenusDatePickerCustom formRefDatePicker={this.formRef} />
              </Form.Item>
            </div>
            <div className="box_inner_horizontal">
              <Form.Item name="ReceiptNumF" label="受付No" {...labelSize}>
                <InputNumber
                  maxLength={6}
                  style={{ textAlign: "right", marginLeft: 3 }}
                />
              </Form.Item>
              <Form.Item name="ReceiptNumT" label="〜">
                <InputNumber maxLength={6} style={{ textAlign: "right" }} />
              </Form.Item>
            </div>
            <div className="box_inner_horizontal">
              <Form.Item name="OfficeCode" label="事業所" {...labelSize}>
                <Input.Search
                  readOnly
                  style={{ width: "150px" }}
                  onSearch={() => this.showOfficeInfoRetrievalQuery()}
                />
              </Form.Item>
              <Form.Item name="BranchStoreCode">
                <Input.Search
                  type="number"
                  readOnly
                  style={{ width: "100px" }}
                  onSearch={() => {
                    if (this.formRef.current?.getFieldValue("OfficeCode")) {
                      this.showOfficeInfoRetrievalQuery();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span>{this.formRef.current?.getFieldValue("office_kanji_name")}</span>
              </Form.Item>
            </div>
            <Form.Item name="FacilityType" label="施設" {...labelSize}>
              <Select style={{ width: '120px' }}>
                {this.state.FacilityType?.map((value, index) => (
                  <Select.Option key={index} value={value.facility_type}>
                    {value.facility_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="box_inner_horizontal">
              <Form.Item name="Department" label="診療科" {...labelSize}>
                <Input.Search
                  style={{ width: "130px" }}
                  onSearch={() => {
                    this.showIntroduceLetterVariousMasterInquiry();
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span>{this.formRef.current?.getFieldValue("department_name")}</span>
              </Form.Item>
            </div>
            <Form.Item name="AskIssuingState" label="発行状態" {...labelSize}>
              <Select style={{ width: "150px" }}>
                <Select.Option value={0}>全て</Select.Option>
                <Select.Option value={1}>未発行</Select.Option>
                <Select.Option value={2}>発行済</Select.Option>
              </Select>
            </Form.Item>
            <div className='box_button_bottom_right'>
              <Button
                type="primary"
                style={{ marginTop: "1em", float: "right" }}
                onClick={() => {
                  this.showPrinterConfirm();
                }}
              >
                印刷
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS0915001_AskIssued);
