import React from "react";
import { connect } from "react-redux";

import { Card, Form, Radio, Button } from "antd";
class WS0487008_HeaderInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'その他 選択';

    this.state = {
      Header: [],
      change: true
    };
  }

  componentDidMount() {
    let all = []
    let HeaderCodeList = this.props.HeaderCodeList.split(',');
    let HeaderNameList = this.props.HeaderNameList.split(',');

    for (let i = 0; i < HeaderCodeList.length; i++) {
      all.push({ value: HeaderCodeList[i], name: HeaderNameList[i] });
    }

    this.setState({
      Header: all
    })
  }

  onChange(record) {
    if (record !== '') {
      this.setState({
        change: false
      })
    }
  }

  render() {
    return (
      <div className="header-input">
        <Card title="その他 選択" className="mb-2">
          <Form
            ref={this.formRef}
          >
            <Form.Item name='select'>
              <Radio.Group
                onChange={(e) => this.onChange({ Target: e.target.value })}
                style={{ marginLeft: '20%' }}
              >
                {this.state.Header.map((item, index) => (
                  <Radio
                    key={item.value}
                    value={item.value}
                    style={{ marginBottom: 5 }}
                  >
                    {item.name}
                  </Radio>
                ))}

              </Radio.Group>
            </Form.Item>

            <Button
              disabled={this.state.change}
              style={{ marginTop: 15, float: "right" }}
              type="primary"
              onClick={() => {
                if (this.props.onFinishScreen) {
                  let values = this.formRef.current?.getFieldValue('select')
                  let data = this.state.Header.find((item) => item.value == values)

                  this.props.onFinishScreen(data)
                }
              }}
            >
              選択
            </Button>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0487008_HeaderInput);
