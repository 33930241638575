import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Row } from "antd";

class WS1517001_OptionsItemOutputCapture extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'オプションアイテム出力・取込';

    this.state = {
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="options-item-output-capture">
        <Card className="mb-2" title="オプションアイテム出力・取込">
          <Form labelCol={{ span: 3 }}
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Form.Item
                name="Output"  style={{width:'70%'}}
                label="出力先"
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
              >
                <Button type="primary" style={{margin:' 0 10px 0 10px'}}>出力</Button>
              </Form.Item>
              <Form.Item
              >
                <Button type="primary">取込</Button>
              </Form.Item>

            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1517001_OptionsItemOutputCapture);
