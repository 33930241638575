import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/comprehensive-menu-maintenance-sub/getscreendata",
  onSaveData: "/api/comprehensive-menu-maintenance-sub/onsavedata",
  deleteData: "/api/comprehensive-menu-maintenance-sub/deletedata",
};
const ComprehensiveMenuMaintenanceSubService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
  async onSaveData(params) {
    console.log(apiPaths.onSaveData);
    return axios.post(apiPaths.onSaveData, params);
  },
  async deleteData(params) {
    return axios.get(apiPaths.deleteData, { params });
  },
};

export default ComprehensiveMenuMaintenanceSubService;