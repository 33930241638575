import axios from "configs/axios";

const apiPaths = {
  deleteInspectCmtMaster: "/api/inspect-cmt-master/inspect-cmt-master/delete",
  postInspectCmtMaster: "/api/inspect-cmt-master/inspect-cmt-master/save",
};

const InspectItemCmtMasterService = {
  async deleteInspectCmtMaster(params) {
    console.log("サービスdel")
    return axios.delete(apiPaths.deleteInspectCmtMaster, { params });
  },
  async postInspectCmtMaster(params) {
    console.log("サービス")
    //console.log(axios.post(apiPaths.postInspectCmtMaster, { params }))
    //JSON.stringify(params)
    return axios.post(apiPaths.postInspectCmtMaster, params);
  },
};

export default InspectItemCmtMasterService;
