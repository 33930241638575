import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';

import WS0638004_EscortManageMaintainOptionInputEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638004_EscortManageMaintainOptionInputEdit.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import EscortManageMaintainAction from "redux/AdvancePreparation/DocumentManageMaintain/EscortManageMaintain.action";

class WS0638005_EscortManageMaintainOptionInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'エスコート管理保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource0: [],
      isLoadingTable0: false,
      selectedRowKeys0: [],
      rowSelected0: [],
      indexTable0: 0,
      old_medical_exam_course: null,
      dataSourceOld: [],

      dataSource1: [],
      isLoadingTable1: false,
      selectedRowKeys1: [],
      rowSelected1: [],
      indexTable1: 0,
      old_W1_serial_num: null,
    };
  }

  componentDidMount() {
    this.getDataOptionInput();
  }

  // OptionInput
  /**
   * 初期表示
   */
  getDataOptionInput() {
    this.setState({ isLoadingTable1: true })
    let params = {
      id: this.props.id
    }

    EscortManageMaintainAction.getDataOptionInputDetail(params)
      .then((res) => {
        this.setState({
          dataSource1: res ? res : [],
          isLoadingTable1: false,

          rowSelected1: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeys1: res && res.length > 0 ? [res[0].id] : [],
          indexTable1: 0,
          old_W1_serial_num: res && res.length > 0 ? res[0].serial_num : null,
        })
      })
      .finally(() => this.setState({ isLoadingTable1: false }))
  }

  /**
   * OptionInput新規登録・編集
   * @param {} type
   */
  optionInputHandleAddRowTable = (record = null) => {
    let newflag = false
    let sendData = []

    if (!record) {
      newflag = true
    } else {
      sendData = {
        medical_exam_course: record.item,
        escort_code: record.content,
        serial_num: record.serial_num,
      }
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0638004_EscortManageMaintainOptionInputEdit
            record={sendData}
            newflag={newflag}

            onUpdate={(output) => {
              let params = {
                id: this.props.id,
                course: output.medical_exam_course, //内容
                courseContent: output.escort_code //項目
              }

              EscortManageMaintainAction.optionInputCreateAndUpdate(params)
                .then((res) => {
                  this.closeModal()
                  this.getDataOptionInput();
                })
            }}

            onDelete={() => {
              let params = {
                id: this.props.id,
                course: record.item, //項目
              }
              EscortManageMaintainAction.optionInputDelete(params)
                .then((res) => {
                  this.closeModal()
                  this.getDataOptionInput();
                })
            }}
          />
        )
      }
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="escort-manage-maintain">
        <Card
          title="エスコート管理保守 [オプション]"
          className="mb-3-OptionInput"
        >
          <Table
            style={{ cursor: 'pointer' }}
            size='small'
            dataSource={this.state.dataSource1}
            loading={this.state.isLoadingTable1}
            pagination={false}
            scroll={{ y: 500 }}
            bordered
            rowKey={(res) => res.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  this.optionInputHandleAddRowTable(record)
                }
              };
            }}
          >

            <Table.Column
              title="連番"
              dataIndex="serial_num"
              width={100}
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              title="項目"
              dataIndex="item"
            />

            <Table.Column
              title="内容"
              dataIndex="content"
            />

            <Table.Column
              width={40}
              title={
                <div
                  style={{ textAlign: "center" }}
                >
                  <Button
                    size='small'
                    onClick={() => { this.optionInputHandleAddRowTable() }}
                    type="primary"
                    icon={<PlusOutlined />}>
                  </Button>
                </div>
              }

              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger="click"
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key="変更"
                          onClick={() => {
                            this.optionInputHandleAddRowTable(record)
                          }}
                        >変更
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size="small"
                      icon={<MoreOutlined />}
                      style={{ width: "100%" }}
                    ></Button>
                  </Dropdown>
                );
              }}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0638005_EscortManageMaintainOptionInput);
