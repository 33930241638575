import axios from "configs/axios";

const APP_LIST = {
  getscreendata: "/api/convert-tbl-replication-process/getscreendata",
  convertTableReplicateRegister: "/api/convert-tbl-replication-process/convertTableReplicateRegister",

};

const ConvertTblReplicationProcessService = {
  async getscreendata(params) {

    return axios.get(APP_LIST.getscreendata, { params });
  },

  async convertTableReplicateRegister(params) {

    return axios.post(APP_LIST.convertTableReplicateRegister, params).then((res) => {
      return res
    }).catch((err) => {
      throw err.response.data
    })
  },

};

export default ConvertTblReplicationProcessService;
