import React from "react";
import { connect } from "react-redux";
import { getDataJudgeSelectAction } from "redux/ResultOutput/RomotoArticle52/JudgeSelect.actions";
import { Card, Form, Input, Checkbox, Select, Table, Row, Col, Tooltip, message, InputNumber, Button, Popconfirm } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons';
import Color from "constants/Color";
import WS1004007_JudgeSelect from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1004007_JudgeSelect.jsx';
import WS0104001_CoursesSettingSub from 'pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0104001_CoursesSettingSub.jsx';
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx';
import {
  deleteSettingRomotoArticle52Action, getDataSettingRomotoArticle52Action, getScreenSettingRomotoArticle52Action,
  saveAndUpdateSettingRomotoArticle52Action, saveDataSbufixSettingRomotoArticle52Action, getNameCategoryInspectCodeRomotoArticle52Action
} from "redux/ResultOutput/RomotoArticle52/SettingRomotoArticle52.actions";
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";
import ModalDraggable from "components/Commons/ModalDraggable";
import { ModalCustom } from "components/Commons/ModalCustom";
import { Regex } from "helpers/CommonHelpers";

const styleDivTitle = { background: '#1C66B9', color: '#FFFFFF', padding: '2px 6px', textAlign: 'center' };

const smGrid = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 70px)' } }
}
class WS1004100_SettingRomotoArticle52Edit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props);

    // document.title = '設定[労基５２条]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      isChangeError: false,
      CategoryAndInspectDivisionTemp: '',
      initparams: {
        YesAbnormalFindings: '',
        DoctorInstructs: '',
        StsModify: false,
        OrNot: '',
        CourseList: '',
        StyleCode: '',
      },
    };
  }
  componentDidMount() {
    // this.loadInitData()
    if (this.props.edit === 1) {
      this.loadData({
        Lio_JudgeList: this.props.Lio_JudgeList,
        Lio_YesFindingsList: this.props.Lio_YesFindingsList,
        Lio_DoctorInstructedList: this.props.Lio_DoctorInstructedList
      });
    } else {
      this.loadData({
        Li_InspectAndTotal: 1,
        Lio_JudgeList: '',
        Lio_YesFindingsList: this.props.YesAbnormalFindings,
        Lio_DoctorInstructedList: this.props.DoctorInstructs,
      });
    }
    if (this.props.record) {
      this.formRef.current?.setFieldsValue(this.props.record)
      this.forceUpdate()
    }
  }

  loadData = (params) => {
    this.setState({ dataSource: [], rowSelect: {} });
    getDataJudgeSelectAction(params)
      .then(res => {
        if (res?.data) {
          let tempJudgeList = []
          let tempYesFindingsList = []
          let tempDoctorInstructedList = []

          let data = res.data.map((item, index) => {
            if (item.StsTargetJudge) {
              tempJudgeList.push(item.judgment_result)
            }
            if (item.StsTargetYesFindings) {
              tempYesFindingsList.push(item.judgment_result)
            }
            if (item.StsTargetPhysicianInstructions) {
              tempDoctorInstructedList.push(item.judgment_result)
            }
            return { ...item }
          });

          this.setState({
            dataSource: data,
            JudgeList: tempJudgeList,
            YesFindingsList: tempYesFindingsList,
            DoctorInstructedList: tempDoctorInstructedList
          });
        }
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
  }

  ReturnComponent = (component) => {
    let components = {
      WS0271001_InspectItemSearchQuerySingle,
      WS0267001_CategorySearchQuerySingle
    };
    return components[component];
  }

  openModalValueCategoryAndInspectCode = (props, nameScreen, width) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={(output) => {
              if (nameScreen === 'WS0271001_InspectItemSearchQuerySingle') {
                this.formRef.current?.setFieldsValue({
                  CategoryAndInspectCode: output.recordData.internal_exam_code,
                  Expression_13: output.recordData.exam_name
                })
              } else {
                this.formRef.current?.setFieldsValue({
                  CategoryAndInspectCode: output.Lio_CategoryCode,
                  Expression_13: output.Lio_CategoryName
                })
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  handleChange = (record, value, name) => {
    let arrTemp = [...this.state.dataSource];
    let tempJudgeList = [...this.state.JudgeList]
    let tempYesFindingsList = [...this.state.YesFindingsList]
    let tempDoctorInstructedList = [...this.state.DoctorInstructedList]

    let index = arrTemp.findIndex(item => item.id === record.id);

    if (index !== -1) {
      let objTemp = {
        ...record,
        [name]: value
      }

      if (name === 'StsTargetJudge') {
        let idx = tempJudgeList.findIndex(item => item === record.judgment_result);
        value
          ? tempJudgeList.push(record.judgment_result)
          : tempJudgeList.splice(idx, 1);
        tempJudgeList.sort()
      }
      if (name === 'StsTargetYesFindings') {
        let idx = tempYesFindingsList.findIndex(item => item === record.judgment_result);
        value
          ? tempYesFindingsList.push(record.judgment_result)
          : tempYesFindingsList.splice(idx, 1);
        tempYesFindingsList.sort()
      }
      if (name === 'StsTargetPhysicianInstructions') {
        let idx = tempDoctorInstructedList.findIndex(item => item === record.judgment_result);
        value
          ? tempDoctorInstructedList.push(record.judgment_result)
          : tempDoctorInstructedList.splice(idx, 1);
        tempDoctorInstructedList.sort()
      }

      arrTemp[index] = objTemp;
      this.setState({
        dataSource: arrTemp,
        JudgeList: tempJudgeList,
        YesFindingsList: tempYesFindingsList,
        DoctorInstructedList: tempDoctorInstructedList
      });
    }
  }

  saveDataSubfix = () => {
    let params = {
      StyleCode: this.props.Li_StyleCode,
      YesAbnormalFindings: this.state.YesFindingsList.join(','),
      DoctorInstructs: this.state.DoctorInstructedList.join(','),
      OrNot: this.formRef.current?.getFieldValue('OrNot'),
      CourseList: this.formRef.current?.getFieldValue('CourseList'),
    }
    saveDataSbufixSettingRomotoArticle52Action(params)
      .then(res => {
        message.success('保存が完了しました')
        this.props.onFinishScreen()
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
  }

  saveAndUpdateRecord = () => {
    if (this.formRef.current?.getFieldValue('AggregateClassify') === undefined || this.formRef.current?.getFieldValue('AggregateClassify') === '') {
      return message.error('集計は必須項目です')
    }
    const params = {
      id: this.props.newFlag ? null : this.props.record.id,
      format_name: this.formRef.current?.getFieldValue('format_name'),
      remarks: this.formRef.current?.getFieldValue('remarks'),
      AggregateClassify: this.formRef.current?.getFieldValue('AggregateClassify'),
      CategoryAndInspectDivision: this.formRef.current?.getFieldValue('CategoryAndInspectDivision'),
      CategoryAndInspectCode: this.formRef.current?.getFieldValue('CategoryAndInspectCode'),
      Judge: this.state.JudgeList.join(','),
      YesAbnormalFindings: this.state.YesFindingsList.join(','),
      DoctorInstructs: this.state.DoctorInstructedList.join(','),
      Li_StyleCode: this.props.Li_StyleCode,
      StsInspect: this.formRef.current?.getFieldValue('CategoryAndInspectCode') > 0 ? true : false,
      StsCategory: ''
    }
    saveAndUpdateSettingRomotoArticle52Action(params)
      .then(res => {
        message.success('保存が完了しました')
        this.props.onFinishScreen()
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
  }

  deleteRecord = () => {
    deleteSettingRomotoArticle52Action({ id: this.props.record.id })
      .then(res => {
        message.success('削除が完了しました')
        this.props.onFinishScreen()
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="setting-romoto-article52">
        <Card title={this.props.newFlag && this.props.edit === 1 ? "設定[労基５２条]追加" : "設定[労基５２条]編集"}>
          <Form ref={this.formRef} autoComplete='off'>
            {this.props.edit === 1 ?
              <div className="box_inner_vertical">
                <Form.Item label='集計' name='AggregateClassify' {...smGrid}>
                  <Input className="input-size-number-2" />
                </Form.Item>
                <Form.Item label='名称' name='Expression_26' {...smGrid}>
                  <Input />
                </Form.Item>
                <Table
                  bordered
                  size='small'
                  dataSource={this.state.dataSource}
                  pagination={false}
                  rowKey={(record) => record.id}
                >
                  <Table.Column title="判定" dataIndex="judgment_result" align='center'
                    render={(value, record, index) => (
                      <span style={{ color: (record?.Expression_5 ? Color(record.Expression_5) : Color(208)).Foreground }}>{value}</span>
                    )}
                  />
                  {this.props.Li_InspectAndTotal === 1
                    ? null
                    : <Table.Column title="異常判定" dataIndex="StsTargetJudge" align='center'
                      render={(value, record, index) => (
                        <Checkbox checked={record.StsTargetJudge}
                          onChange={(e) => this.handleChange(record, e.target.checked, 'StsTargetJudge')}
                        />
                      )}
                    />
                  }

                  <Table.Column title="有所見者" dataIndex="StsTargetYesFindings" align='center'
                    render={(value, record, index) => (
                      <Checkbox checked={record.StsTargetYesFindings}
                        onChange={(e) => this.handleChange(record, e.target.checked, 'StsTargetYesFindings')}
                      />
                    )}
                  />
                  <Table.Column title="医師指示" dataIndex="StsTargetPhysicianInstructions" align='center'
                    render={(value, record, index) => (
                      <Checkbox checked={record.StsTargetPhysicianInstructions}
                        onChange={(e) => this.handleChange(record, e.target.checked, 'StsTargetPhysicianInstructions')}
                      />
                    )} />
                </Table>
                <div className="box_inner_horizontal">
                  <Form.Item label='判定対象' name='CategoryAndInspectDivision' {...smGrid}>
                    <Select style={{ width: '120px' }}>
                      <Select.Option value=""></Select.Option>
                      <Select.Option value="K">検査</Select.Option>
                      <Select.Option value="C">ｶﾃｺﾞﾘ</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name='CategoryAndInspectCode'>
                    <Input.Search
                      className='input-search-size-number-7'
                      onSearch={() => {
                        if (this.formRef.current?.getFieldValue('CategoryAndInspectDivision') !== '') {
                          let props = this.formRef.current?.getFieldValue('CategoryAndInspectDivision') === 'K'
                            ? {
                              Lio_InspectItemCode: this.formRef.current?.getFieldValue('CategoryAndInspectCode'),
                              Li_UnusedInspectDisplay: ''
                            }
                            : {
                              Lio_CategoryCode: this.formRef.current?.getFieldValue('CategoryAndInspectCode'),
                              Li_UnusedInspectDisplay: ''
                            }
                          this.formRef.current?.getFieldValue('CategoryAndInspectDivision') === 'K'
                            ? this.openModalValueCategoryAndInspectCode(props, 'WS0271001_InspectItemSearchQuerySingle', 850)
                            : this.openModalValueCategoryAndInspectCode(props, 'WS0267001_CategorySearchQuerySingle', 500)
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='Expression_13'>
                    <div>{this.formRef.current?.getFieldValue('Expression_13')}</div>
                  </Form.Item>
                </div>
                <Form.Item label='表示名称' name='format_name' {...smGrid}>
                  <Input />
                </Form.Item>
                <Form.Item label='備考' name='remarks' {...smGrid}>
                  <Input />
                </Form.Item>
              </div> :
              this.props.edit === 2 ?
                <div className="box_inner_vertical">
                  {/* <Form.Item label='有所見者' name='CategoryAndInspectDivision' {...smGrid}>
                    <Input
                      className="input-size-10"
                      onDoubleClick={() => {
                        let props = {
                          Li_InspectAndTotal: 1,
                          Lio_JudgeList: '',
                          Lio_YesFindingsList: this.props.YesAbnormalFindings,
                          Lio_DoctorInstructedList: this.props.DoctorInstructs,
                        }
                        this.openModalWS1004007(props)
                      }}
                    />
                  </Form.Item>
                  <Form.Item label='医師指示' name='DoctorInstructs' {...smGrid}>
                    <Input
                      className="input-size-10"
                      onDoubleClick={() => {
                        let props = {
                          Li_InspectAndTotal: 1,
                          Lio_JudgeList: '',
                          Lio_YesFindingsList: this.props.YesAbnormalFindings,
                          Lio_DoctorInstructedList: this.props.DoctorInstructs,
                        }
                        this.openModalWS1004007(props)
                      }}
                    />
                  </Form.Item> */}
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.dataSource}
                    pagination={false}
                    rowKey={(record) => record.id}
                  >
                    <Table.Column title="判定" dataIndex="judgment_result" align='center'
                      render={(value, record, index) => (
                        <span style={{ color: (record?.Expression_5 ? Color(record.Expression_5) : Color(208)).Foreground }}>{value}</span>
                      )}
                    />
                    <Table.Column title="有所見者" dataIndex="StsTargetYesFindings" align='center'
                      render={(value, record, index) => (
                        <Checkbox checked={record.StsTargetYesFindings}
                          onChange={(e) => this.handleChange(record, e.target.checked, 'StsTargetYesFindings')}
                        />
                      )}
                    />
                    <Table.Column title="医師指示" dataIndex="StsTargetPhysicianInstructions" align='center'
                      render={(value, record, index) => (
                        <Checkbox checked={record.StsTargetPhysicianInstructions}
                          onChange={(e) => this.handleChange(record, e.target.checked, 'StsTargetPhysicianInstructions')}
                        />
                      )} />
                  </Table>
                  <div className="box_inner_horizontal">
                    <Form.Item label='受診ｺｰｽ' name='OrNot' {...smGrid}>
                      <Select style={{ width: '120px' }} onChange={value => this.forceUpdate()}>
                        <Select.Option value="">全　て</Select.Option>
                        <Select.Option value="OR">対　象</Select.Option>
                        <Select.Option value="NOT">対象外</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name='CourseList' hidden={this.formRef.current?.getFieldValue('OrNot') === ""}>
                      <Input
                        onDoubleClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '40%',
                              component: (
                                <WS0104001_CoursesSettingSub
                                  Li_Title={'対象ｺｰｽ一覧'}
                                  Lio_CourseList={this.formRef.current?.getFieldValue('CourseList')}
                                  onFinishScreen={({ Lio_CourseList }) => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                </div> :
                null
            }
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() =>
                  this.props.edit === 1 ? this.saveAndUpdateRecord() : this.saveDataSubfix()
                }
              >保存</Button>
              {!this.props.newFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.deleteRecord()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                  >
                    削除
                  </Button>
                </Popconfirm>
                : null
              }
            </div>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            destroyOnClose={true}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1004100_SettingRomotoArticle52Edit);