import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Table } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import TabooEditDisplay from 'pages/ZZ_Others/SANAI_Memo/TabooEditDisplay.jsx'
import MemoFunctionAction from 'redux/Others/ReserveStatusSearch/MemoFunction.action.js'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class TabooOptionSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = 'メモ編集画面'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: []
    }
  }

  componentDidMount = () => {
    this.getTabooOptions()
  }

  getTabooOptions = () => {
    MemoFunctionAction.getTabooOptions()
      .then(res => {
        this.setState({ dataSource: res })
      })
  }

  editTaboo = (record, newFlag) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <TabooEditDisplay
            dataSource={this.state.dataSource}
            newFlag={newFlag}
            id={record.id}
            serial_number={record.serial_number}
            select={record.select}
            convert={record.convert}
            onFinishScreen={() => {
              this.getTabooOptions()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='taboo-option-setting' style={{ width: '800px' , height:'600px' }}>
        <Card title='禁忌一覧'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      if (this.props.selectFlag == true) {
                        this.props.onSelect({ select: record.select, serialNumber: record.serial_number })
                      }
                    }
                  }
                }}
              >
                <Table.Column
                  title='番号'
                  dataIndex='serial_number'
                  className='column-size-10'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='内容'
                  dataIndex='select'
                  className='column-size-60'
                />

                <Table.Column
                  title='記号'
                  dataIndex='convert'
                  className='column-size-4'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'center', fontSize: 'large' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  key='action'
                  fixed='right'
                  className='column-size-2'
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => this.editTaboo([], 1)}
                    />
                  }
                  render={(value, record) => {
                    return (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => this.editTaboo(record, 0)}
                      />
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabooOptionSetting)