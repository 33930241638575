import { message } from "antd";
import InsureGuideCourseDetailService from "services/SpecificInsureMaintenance/InsureGuideCourse/InsureGuideCourseDetailService";

const InsureGuideCourseDetailAction = {
  getIndex(data) {
    return InsureGuideCourseDetailService.getIndex(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addData(data) {
    return InsureGuideCourseDetailService.addData(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  postData(data) {
    return InsureGuideCourseDetailService.postData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteData(data) {
    return InsureGuideCourseDetailService.deleteData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  // ポイントの再計算
  pointCalculate(data) {
    return InsureGuideCourseDetailService.pointCalculate(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }
}

export default InsureGuideCourseDetailAction;
