/**
 * キーイベントによるフォーカス移動
 * @param {*} event
 * @param {*} inputName Formに設定しているinputName
 * @param {*} ColumnLength inputがあるカラム数
 * @param {*} buttonFlag 右にボタンがあるか
 * @param {*} inputeEclude input以外の要素がいくつあるか
 * @returns
 */

const moveFocusKeyEvent = (event, inputName, ColumnLength, buttonFlag = false, inputeEclude = 0) => {
  let addColumnLength
  if (buttonFlag) {
    // inputがあるカラム+1
    addColumnLength = ColumnLength + inputeEclude
  } else {
    addColumnLength = ColumnLength
  }
  // inputがどこのformの値になるかを取る
  const form = event.target.form;
  // formが何個あるかを取る
  const formLength = form.length;
  // イベント開始時のinputFormを取得
  const index = [...form].indexOf(event.target)
  // 次にフォーカス移動するための値
  let enterFocous, endFocous, homeFocous
  let count = 1
  // returnで返す値
  let returnFocous
  // キーイベントから分岐してフォーカス移動
  if (event.key === 'Enter') {
    for (let i = index; i < formLength; i++) {
      enterFocous = form[i + count]
      // input以外にフォーカスが当たらないため
      if (enterFocous !== undefined) {
        // 次に当たるフォーカスの設定
        if (enterFocous?.id.indexOf(inputName) !== -1 && !enterFocous.disabled && enterFocous.className.indexOf('ant-input') !== -1) {
          returnFocous = enterFocous.focus()
          break
        } else if (i === formLength) {
          return
        }
      }
    }
  } else if (event.keyCode === 38) {
    let nextLength
    // formを値を引いていくため、i-addColumnLengthを行っている
    for (let i = index; 0 < i; i = i - addColumnLength) {
      // keyCode40が上矢印キー
      nextLength = form[i - addColumnLength]
      // input以外にフォーカスが当たらないため
      if (nextLength !== undefined) {
        if (nextLength?.id.indexOf(inputName) > -1 && !nextLength.disabled) {
          returnFocous = nextLength.focus()
          break
        }
      }
    }
  } else if (event.keyCode === 40) {
    let beforeLenght
    // formを値を足していくため、i-addColumnLengthを行っている
    for (let i = index; i < formLength; i = i + addColumnLength) {
      // keyCode38が下矢印キー
      beforeLenght = form[i + addColumnLength]
      if (beforeLenght !== undefined) {
        if (beforeLenght?.id.indexOf(inputName) > -1 && !beforeLenght.disabled) {
          if (!beforeLenght.disabled) {
            returnFocous = beforeLenght.focus()
            break
          }
        }
      }
    }
  } else if (event.keyCode === 35) {
    // keyCode40がENDキー
    for (let i = 0; i < formLength; i++) {
      endFocous = form[formLength - i]
      // input以外にフォーカスが当たらないため
      if (endFocous !== undefined) {
        // 最後のinputの時のみ入る
        if (endFocous?.id.indexOf(inputName) !== -1) {
          returnFocous = endFocous.focus()
          break
        } else if (i === formLength) {
          return
        }
      }
    }
  } else if (event.keyCode === 36) {
    // keyCode40がHOMEキー
    for (let i = 0; i < formLength; i++) {
      homeFocous = form[i]
      // input以外にフォーカスが当たらないため
      if (homeFocous !== undefined) {
        // 最後のinputの時のみ入る
        if (homeFocous?.id.indexOf(inputName) !== -1) {
          returnFocous = homeFocous.focus()
          break
        } else if (i === formLength) {
          return
        }
      }
    }
  }
  return returnFocous
}
export default moveFocusKeyEvent
