import React from "react";
import "./PageNotFound.scss";

function NoLoginHospitalCode() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="error-template">
            <h1>ログインする施設IDが指定されていません。</h1>
            <h2>No Hospital Code</h2>
            <div className="error-details">Sorry, an error has occured. check url /login/:hospital_code </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoLoginHospitalCode;
