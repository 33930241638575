import axios from 'configs/axios'

const API_LIST = {
  index: '/api/associate-insure-data-create/associate-insure-data-create',
  createKenpoData: '/api/associate-insure-data-create/associate-insure-data-create/create-kenpo-data',
  downloadZipFile: '/api/associate-insure-data-create/associate-insure-data-create/download-zip-file',

  displayindex: '/api/associate-insure-data-create/associate-insure-data-create/display',
  selectAllAPI: '/api/associate-insure-data-create/associate-insure-data-create/select-all',
  selectOneAPI: '/api/associate-insure-data-create/associate-insure-data-create/select-one',
}

const AssociateInsureDataCreateService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },

  async createKenpoData(params) {
    return axios.post(API_LIST.createKenpoData, params)
  },

  async downloadZipFile(params) {
    // zipダウンロードの為、{responseType: 'arraybuffer'} を設定
    return axios.post(API_LIST.downloadZipFile, params, {
      responseType: 'arraybuffer'
    })
  },

  // async displayAssociateInsureDataCreateService(params) {
  //   return axios.post(API_LIST.displayindex, params)
  // },
  // async selectAll(params) {
  //   return axios.post(API_LIST.selectAllAPI, params)
  // },
  // async selectOne(params) {
  //   return axios.post(API_LIST.selectOneAPI, params)
  // },

}

export default AssociateInsureDataCreateService
