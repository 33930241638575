import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Tag, } from "antd";
import './WS0486001_ConditionCorrectSub.scss'
import PropTypes from "prop-types";
import WS0487001_ConditionExpressAddSub from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487001_ConditionExpressAddSub.jsx';
import WS0486002_Redisplay from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0486002_Redisplay.jsx'
import ModalDraggable from "components/Commons/ModalDraggable";
import ConditionCorrectSubAction from 'redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionCorrectSub.action'

const styleTag = {
  fontSize: '16px',
  lineHeight: 2,
  marginRight: 0
};

class WS0486001_ConditionCorrectSub extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Lio_ConditionalExpression: PropTypes.any,
    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = '条件式訂正SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      ConditionalExpression: this.props.record.formula
    })
  }

  getRawValue = (name) => this.formRef?.current?.getFieldValue(name)

  onChangeConditionalExpression(value) {
    this.setFormFieldValue('ConditionalExpression', value)
  }

  setFormFieldValue(namePath, value) {
    let copy = this.formRef.current?.getFieldValue(namePath)
    let newValue = copy + value
    this.formRef?.current?.setFieldsValue({
      [namePath]: newValue,
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  showWS0487001_ConditionExpressAddSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0487001_ConditionExpressAddSub
            callScreen={'WS0486001_ConditionCorrectSub'}
            onFinishScreen={(output) => {
              // truyen Lo setting tu Output vao day
              // ConditionalExpressionが入っていたら追記する
              let copy = this.formRef.current?.getFieldValue('ConditionalExpression')
              let newValue = copy + output
              this.formRef.current?.setFieldsValue({
                ConditionalExpression: newValue
              })
              this.closeModal()
              this.forceUpdate()
            }}
          />
        ),
      },
    })
  }

  ConditionalExpressionDisplaySub(ConditionalExpression) {
    // hard code 
    if (ConditionalExpression && ConditionalExpression.length > 0) {
      switch (ConditionalExpression) {
        case 'AND': return '∩';
        case 'OR': return '∪';
        case 'NOT': return 'NOT';
        case '+': return '＋';
        case '-': return '－';
        case '*': return '×';
        case '/': return '÷';
        default: return ConditionalExpression;
      }
    } else {
      return ConditionalExpression
    }
  }

  showWS0486002_Redisplay(ConditionalExpression) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0486002_Redisplay
            Li_ConditionalExpression={this.ConditionalExpressionDisplaySub(ConditionalExpression)}
            onFinishScreen={({ output }) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  Redisplay_F11() {
    // check dieu
    const ConditionalExpression = this.getRawValue('ConditionalExpression')
    let params = {
      ConditionalExpression: ConditionalExpression
    }
    // 条件式チェック
    ConditionCorrectSubAction.redisplay_F11(params)
      .then((res) => {
        if (res?.variables?.Li_ConditionalExpression) {
          this.showWS0486002_Redisplay(res?.variables?.Li_ConditionalExpression)
        }
      })
  }

  onSubmit() {
    const ConditionalExpression = this.getRawValue('ConditionalExpression')
    let params = {
      Li_GuideCmtCode: this.props.record.guidance_comment_code,
      Li_StartDate: this.props.record.start_date_on,
      Li_SerialNum: this.props.record.serial_number,
      Li_BranchNumb: this.props.record.branch_number,
      ConditionalExpression: ConditionalExpression
    }

    // 条件式を更新する・親画面の表示に合わせるためにバックエンドから取得
    ConditionCorrectSubAction.getEquationDisplay(params)
      .then((res) => {
        const { onFinishScreen } = this.props
        if (onFinishScreen) {
          let output = {
            ConditionalExpression: ConditionalExpression,
            ConditionalExpressionDisplay: res.ConditionalExpressionDisplay
          }
          onFinishScreen(output)
        }
      })
  }

  render() {
    return (
      <div className="condition-correct-sub">
        <Card title="条件式訂正" className="mb-2">
          <Form
            ref={this.formRef}
          >
            <div className="box_inner_horizontal">
              <div className="box_inner_horizontal">
                <Tag color='green' style={styleTag}>論理</Tag>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('AND')}
                >
                  かつ
                </Button>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('OR')}
                >
                  または
                </Button>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('NOT')}

                >
                  否定
                </Button>
              </div>

              <div className="box_inner_horizontal">
                <Tag color='green' style={styleTag}>論理</Tag>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('+')}
                >
                  ＋
                </Button>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('-')}
                >
                  ―
                </Button>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('*')}
                >
                  X
                </Button>
                <Button
                  type='primary'
                  onClick={() => this.onChangeConditionalExpression('/')}
                >
                  ÷
                </Button>
              </div>
            </div>

            <Form.Item
              name="ConditionalExpression"
              label=""
              style={{ marginTop: 5 }}
            >
              <Input.TextArea rows={10} type="text" />
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.showWS0487001_ConditionExpressAddSub()}
              >
                <span className='btn_label'>
                  追加
                </span>
              </Button>

              <Button
                type='primary'
                onClick={() => this.Redisplay_F11()}
              >
                <span className='btn_label'>
                  再表示
                </span>
              </Button>

              <Button
                type='primary'
                onClick={() => this.onSubmit()}
              >
                <span className='btn_label'>
                  更新
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0486001_ConditionCorrectSub);
