import axios from 'configs/axios';
const API_LIST = {
  getScreenData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting/get-screen-data',
  getSingleJudgementData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-single-judgement-data',
  getComplexJudgementData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-complex-judgement-data',
  getComboboxList: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-combobox-list',
  getJudgementLevelList: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-judgement-level-list',
  getStartDateList: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-start-date-list',
  saveJudgementData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/save-judgement-data',
  deleteJudgementData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/delete-judgement-data',
  saveToleranceData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/save-tolerance-data',
  deleteToleranceData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/delete-tolerance-data',
  // getAClassJudgeAction: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-aclass-judge-action',
  // getNormalValueAction: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-normal-value-action',
  // getToleranceAction: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-tolerance-action',
  saveComplexJudgementData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/save-complex-judgement-data',
  deleteComplexJudgementData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/delete-complex-judgement-data',
  synchronizeJugement: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/synchronize-jugement',
  getExamNameAction: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/get-exam-name-action',
  copyJudgementDataAction: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/copy-judgement-data-action',
  deleteJudgementDataAction: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting-sub/delete-judgement-data-action',

  // UpdateData: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting/exam-list/update',
  // ExamList: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting/exam-list',
}

const InspectItemJudgeValueSettingService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },

  async getSingleJudgementData(params) {
    return axios.get(API_LIST.getSingleJudgementData, { params });
  },

  async getComplexJudgementData(params) {
    return axios.get(API_LIST.getComplexJudgementData, { params });
  },

  /*  async getComboboxList(params) {
     return axios.get(API_LIST.getComboboxList, { params });
   }, */

  async getJudgementLevelList(params) {
    return axios.get(API_LIST.getJudgementLevelList, { params });
  },

  async getStartDateList(params) {
    return axios.get(API_LIST.getStartDateList, { params });
  },

  async saveJudgementData(params) {
    return axios.put(API_LIST.saveJudgementData, params);
  },

  async deleteJudgementData(params) {
    return axios.delete(API_LIST.deleteJudgementData, { params });
  },

  async saveToleranceData(params) {
    return axios.put(API_LIST.saveToleranceData, params);
  },

  async deleteToleranceData(params) {
    return axios.delete(API_LIST.deleteToleranceData, { params });
  },


  /*   async getAClassJudgeAction(params) {
      return axios.get(API_LIST.getAClassJudgeAction, { params });
    },
  
    async getNormalValueAction(params) {
      return axios.get(API_LIST.getNormalValueAction, { params });
    },
  
    async getToleranceAction(params) {
      return axios.get(API_LIST.getToleranceAction, { params });
    }, */

  async saveComplexJudgementData(params) {
    return axios.put(API_LIST.saveComplexJudgementData, params);
  },

  async deleteComplexJudgementData(params) {
    return axios.delete(API_LIST.deleteComplexJudgementData, { params });
  },

  async synchronizeJugement(params) {
    return axios.put(API_LIST.synchronizeJugement, params);
  },

  async getExamNameAction(params) {
    return axios.get(API_LIST.getExamNameAction, { params });
  },

  async copyJudgementDataAction(params) {
    return axios.put(API_LIST.copyJudgementDataAction, params);
  },

  async deleteJudgementDataAction(params) {
    return axios.delete(API_LIST.deleteJudgementDataAction, { params });
  },


  /*   async UpdateData(params) {
      return axios.put(API_LIST.UpdateData, params);
    },
    async ExamList(params) {
      return axios.get(API_LIST.ExamList, { params });
    }, */
};

export default InspectItemJudgeValueSettingService;
