import React from "react";
import { connect } from "react-redux";

import { Button, Card, Dropdown, Input, Menu, Space, Table, } from "antd";
import { MoreOutlined, SaveOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ContentInputAction from "redux/SpecificInsureGuide/InsureGuideInitInput/ContentInput.action"
import WS1385001_SentenceMasterInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1385001_SentenceMasterInquiry.jsx";
import WS2654100_ContentInputAdd from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654100_ContentInputAdd.jsx"
import ModalChange from "components/Commons/ModalChange"

// props: {Li_Date, Li_Id, Li_SerialNum, Li_InspectCode, exam_type} Lio_DisplayOrder, Lio_Goal, Lio_Result

const columns = [
  {
    title: '表示順',
    dataIndex: 'display_order',
    classNumber: '10',
    type: 1,
  },
  {
    title: '文章コード',
    dataIndex: 'sentence_code',
    classNumber: '10',
    type: 1,
    childModal: {
      width: 800,
      component: WS1385001_SentenceMasterInquiry
    },
  }

]
class WS2654013_ContentInput extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '内容入力';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      enterCodeMode: false,
      newData: false,
      SentenceCode: "",
      DocumentClassify: "",
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    const { Li_Date, Li_Id, Li_SerialNum, Li_InspectCode } = this.props;
    let params = {
      Li_Date: Li_Date,
      Li_Id: Li_Id,
      Li_SerialNum: Li_SerialNum,
      Li_InspectCode: Li_InspectCode
    }
    ContentInputAction.getScreenData(params)
      .then(res => {
        this.setState({
          dataSource: res[0],
          newData: false,
          enterCodeMode: true,
          DocumentClassify: res[1]
        });
        // VenusApiRoutesV2.callApi("API002654013004", {}).then(res => {
        //   if (res.variables) {
        //     const { SentenceCode, DocumentClassify } = res.variables;
        //     this.setState({ enterCodeMode: true, SentenceCode, DocumentClassify });
        //   }
        // });
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  enterCode(index, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1385001_SentenceMasterInquiry
            DocumentClassify={this.state.DocumentClassify}
            onFinishScreen={(output) => {
              this.updateRecordInDataSource(index, { ...record, sentence_code: output.sentence_code, content: output.content });
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  handleAddRowTable() {
    if (!this.state.newData) {
      this.setState({
        dataSource: [
          {
            id: 0,
            display_order: "",
            sentence_code: "",
            content: "",
          },
          ...this.state.dataSource,
        ],
        newData: true,
      })
    }
  }

  saveData(record) {
    const { Li_Date, Li_Id, Li_SerialNum, Li_InspectCode } = this.props;
    const { display_order, content, sentence_code } = record
    VenusApiRoutesV2.callApi("API002654013006", {
      mainSource: [{ Li_Date, Li_Id, Li_SerialNum, Li_InspectCode, display_order, content, sentence_code, id: record.id }]
    }).then(res => {
      this.setState({ newData: false });
      this.getScreenData();
    })
  }

  handleDeleteRowTable(record) {
    if (record.id === 0) {
      this.getScreenData();
      return;
    }
    VenusApiRoutesV2.callApi("API002654013007", {
      mainSource: [{ id: record.id }]
    }).then(res => {
      this.getScreenData();
    })
  }

  updateRecordInDataSource(index, record) {
    const copySource = [...this.state.dataSource];
    copySource.splice(index, 1, record);
    this.setState({ dataSource: copySource });
  }

  render() {
    return (
      <div className="content-input">
        <Card title="内容入力">
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.pagination}
            rowKey={(record) => record.id}
          >
            {columns.map((item, index) => (
              item.type === 1 ?
                <Table.Column
                  key={'input' + index}
                  title={item.title}
                  dataIndex={item.dataIndex}
                  className={'column-size-' + item.classNumber}
                  align={item.textAlign ?? ''}
                  onDoubleClick={(record) => {
                    // 編集、削除
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 400,
                        className: '',
                        component:
                          <WS2654100_ContentInputAdd
                            newFlag={false}
                            record={record}
                            DocumentClassify={this.state.DocumentClassify}
                            date_on={this.props.Li_Date}
                            personal_number_id={this.props.Li_Id}
                            serial_number={this.props.Li_SerialNum}
                            Li_InspectCode={this.props.Li_InspectCode}
                            NumDigits={this.props.Li_NumDigits}
                            onFinishScreen={(output) => {
                              this.closeModal()
                              this.props.onFinishScreen()
                              this.getScreenData()
                            }}
                          />
                      },
                    });
                  }}
                /> : item.type === 2 ?
                  <Table.Column
                    key={'select' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign}
                    render={(value, record) => {
                      // findを使ってvalueを元に検索する
                      let findItem = item.selectList.find(selected => selected.value === value);
                      // labelを指定してreturn
                      return findItem.label
                    }}
                  /> : null
            ))}
            {/* <Table.Column title="表示順" width={150}
              dataIndex="display_order"
              showSorterTooltip={false}
              sorter={(a, b) => a.display_order - b.display_order}
              render={(value, record, index) => {
                return (
                  <Input value={value} type="number" onChange={event => this.updateRecordInDataSource(index, { ...record, display_order: event.target.value })} />
                )
              }} />
            <Table.Column title="文章コード" width={250}
              dataIndex="sentence_code"
              showSorterTooltip={false}
              sorter={(a, b) => a.sentence_code.localeCompare(b.sentence_code)}
              render={(value, record, index) => {
                return (
                  <Input value={value}
                    readOnly={this.state.enterCodeMode}
                    onDoubleClick={() => this.enterCode(index, record)}
                    onChange={event => {
                      if (!this.state.enterCodeMode) {
                        this.updateRecordInDataSource(index, { ...record, sentence_code: event.target.value });
                      }
                    }}
                  />
                )
              }} />*/}
            <Table.Column title="内　　容" dataIndex="content" />
            <Table.Column
              fixed='right'
              title={
                <Button
                  type="primary"
                  size="small"
                  icon={<PlusOutlined />}
                  loading={this.state.isLoadingInsurersList}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 450,
                        className: '',
                        component:
                          <WS2654100_ContentInputAdd
                            newFlag={true}
                            DocumentClassify={this.state.DocumentClassify}
                            date_on={this.props.Li_Date}
                            personal_number_id={this.props.Li_Id}
                            serial_number={this.props.Li_SerialNum}
                            Li_InspectCode={this.props.Li_InspectCode}
                            NumDigits={this.props.Li_NumDigits}
                            onFinishScreen={(output) => {
                              this.closeModal()
                              this.props.onFinishScreen()
                              this.getScreenData()
                            }}
                          />
                      },
                    });
                  }} />} dataIndex="" align="center" width={30} render={(value, record, index) => (
                    <Button
                      size="small"
                      icon={<MoreOutlined />}
                      onClick={() => {
                        // 編集、削除
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 400,
                            className: '',
                            component:
                              <WS2654100_ContentInputAdd
                                newFlag={false}
                                record={record}
                                DocumentClassify={this.state.DocumentClassify}
                                date_on={this.props.Li_Date}
                                personal_number_id={this.props.Li_Id}
                                serial_number={this.props.Li_SerialNum}
                                Li_InspectCode={this.props.Li_InspectCode}
                                NumDigits={this.props.Li_NumDigits}
                                onFinishScreen={(output) => {
                                  this.closeModal()
                                  this.props.onFinishScreen()
                                  this.getScreenData()
                                }}
                              />
                          },
                        });
                      }}
                    />

                  )} />
            {/* <Table.Column style={{ textAlign: 'center' }}
              width={120}
              title={(<Button type="primary" size="small" onClick={() => this.handleAddRowTable()} icon={<PlusOutlined />} />)}
              render={(item, record, index) => {
                return (<Space>
                  <Button
                    onClick={() => this.saveData(record)}
                    style={{ color: '#42b10b', border: 'none', marginRight: '5px' }}
                    icon={<SaveOutlined />}></Button>
                  <Button
                    danger
                    onClick={() => { this.handleDeleteRowTable(record) }}
                    icon={<DeleteOutlined />}></Button>
                </Space>)
              }} /> */}
          </Table>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2654013_ContentInput);
