import React from "react";
import { connect } from "react-redux";
import { LeftOutlined, RightOutlined, MoreOutlined, DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Table, Row, Col, Modal, Checkbox, Popconfirm, InputNumber, message, Space } from "antd";
import { saveComplexJudgementData, deleteComplexJudgementData } from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions';
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'
import WS0285001_JudgeQuery from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery"
import WS0272001_CautionGuideNotesSearchQuery from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx";
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'


const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


//「判定」の正確なスペルは"judgement"だが、テーブルのカラム名が"judgment"になっているため、そのまま"judgment"とする


class WS0362022_RegisterJudgementDataComplex extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    // exam_name: PropTypes.any,
    Li_InspectType: PropTypes.any,
    Li_judgement_code: PropTypes.any,
    newFlag: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);
    // document.title = '';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
  }

  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let getId = this.props.id
    let exam_type = this.props.exam_type;
    let judgment_code = this.props.judgment_code;
    let sex = this.props.sex;
    let judgment_level = this.props.judgment_level;
    let start_date_on = this.props.start_date_on;
    let params = {
      ...values,
      id: getId,
      exam_type,
      judgment_code,
      judgment_level,
      sex,
      start_date_on,
      newFlag: this.props.newFlag,
    };
    this.saveData(params);
  }

  saveData(params) {
    this.setState({ isLoading: true })
    saveComplexJudgementData(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    deleteComplexJudgementData({
      id: this.props.record.id
    })
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()

      })
      .catch(() => message.success('エラーが発生しました'))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title={"登録・修正：" + this.props.exam_name}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <Form.Item name="serial_number" label="連番" {...smGrid}>
              <InputNumber
                maxLength={3}
                style={{ width: 40 }}
              />
            </Form.Item>

            <Form.Item name="formula" label="計算式" {...smGrid}>
              <Input
              // style={{ width: 100 }}
              />
            </Form.Item>


            <Form.Item name="judgment_result" label="判定" {...smGrid}>
              <Input.Search type="text" maxLength={3} style={{ width: 80 }}
                onSearch={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: "40%",
                      component: (
                        <WS0285001_JudgeQuery
                          Li_JudgeLevel={Number(this.props.judgment_level)}
                          onFinishScreen={(res) => {
                            this.formRef.current.setFieldsValue({
                              judgment_result: res.recordData.judgment_result,
                            })
                            this.closeModal()
                          }} />
                      ),
                    },
                  })
                }} />
            </Form.Item>

            <div className="box_inner_horizontal">
              <Form.Item name="guidance_comment_code" label="コメントコード" {...smGrid}>
                <Input.Search
                  type="text"
                  allowClear={true}
                  maxLength={5}
                  style={{ width: 120 }}
                  onSearch={(value, event) => {
                    if (event.target.localName === "input") {
                      this.formRef.current?.setFieldsValue({ comment_content: '' })
                      this.forceUpdate()
                    } else {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "60%",
                          component: (
                            <WS0272001_CautionGuideNotesSearchQuery
                              onFinishScreen={(obj) => {
                                this.formRef.current.setFieldsValue({
                                  guidance_comment_code: obj.Lo_LnkOutCmtCode,
                                  comment_content: obj.Lo_recordData.comment_content
                                });
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      })
                    }
                  }} />
              </Form.Item>

              <Form.Item name='comment_content'>
                <div style={{ width: 500 }}>{this.formRef.current?.getFieldValue('comment_content')}</div>
                {/* <Input bordered={false} readOnly style={{ width: 500 }} /> */}
              </Form.Item>
            </div>

            <Form.Item name="priority" label="優先" {...smGrid}>
              <InputNumber maxLength={1} style={{ width: 40 }} />
            </Form.Item>

            <div className="box_button_bottom_right"
              style={{ display: 'flex', gap: '20px' }}>

              {!this.props.newFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px', display: (this.props.Li_id === 0) ? 'none' : '' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  // onConfirm={() => this.deleteTableData()}
                  onConfirm={() => this.deleteData()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    disabled={this.props.Li_id === 0}
                  >
                    削除
                  </Button>
                </Popconfirm> : null}


              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => { this.onFinish(); }}
              >
                保存
              </Button>
            </div>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0362022_RegisterJudgementDataComplex);