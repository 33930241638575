import { message } from 'antd'
import RadiographyFindingInputService from 'services/InputBusiness/RadiographyFindingInput/RadiographyFindingInputService'

const RadiographyFindingInputAction = {
  index(data) {
    return RadiographyFindingInputService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  retrieval(data) {
    return RadiographyFindingInputService.retrieval(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getDataTable(data) {
    return RadiographyFindingInputService.getDataTable(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  checkPreFindings(data) {
    return RadiographyFindingInputService.checkPreFindings(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  updateSubEvent(data) {
    return RadiographyFindingInputService.updateSubEvent(data)
  },

  deleteSubEvent(data) {
    return RadiographyFindingInputService.deleteSubEvent(data)
  },

  updateData(data) {
    return RadiographyFindingInputService.updateData(data)
  },
}

export default RadiographyFindingInputAction
