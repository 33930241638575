import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/age-manage-info-maintain/age-manage-info-maintain/get-screen-data',
  index: '/api/age-manage-info-maintain/age-manage-info-maintain/index',
  subAgeTable: '/api/age-manage-info-maintain/age-manage-info-maintain/sub-age-table',
  subTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/sub-title-table',
  copy_F7: '/api/age-manage-info-maintain/age-manage-info-maintain/copy-f7',
  query_F8: '/api/age-manage-info-maintain/age-manage-info-maintain/query-f8',
  output_F12: '/api/age-manage-info-maintain/age-manage-info-maintain/output_F12',
  f7KeySubAgeTable: '/api/age-manage-info-maintain/age-manage-info-maintain/f7-key-sub-age-table',
  tabKeyAgeSubAgeTable: '/api/age-manage-info-maintain/age-manage-info-maintain/tab-key-age-sub-age-table',
  exitSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/exit-sub-title-table',
  recordPrefixSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/record-prefix-sub-title-table',
  verificationCtrlAge_id_codeSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/verification-ctrl-age-id-code-sub-title-table',
  onblurCtrlAge_titleSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/onblur-ctrl-age-title-sub-title-table',
  f7KeySubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/f7-key-sub-title-table',
  tabKeyAge_titleSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/tab-key-age-title-sub-title-table',
  saveSubAgeTable: '/api/age-manage-info-maintain/age-manage-info-maintain/save-sub-age-table',
  deleteSubAgeTable: '/api/age-manage-info-maintain/age-manage-info-maintain/delete-sub-age-table',
  saveSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/save-sub-title-table',
  deleteSubTitleTable: '/api/age-manage-info-maintain/age-manage-info-maintain/deleteSubTitleTable',
  getScreenDataCopyRegister: '/api/age-manage-info-maintain/copy-register/get-screen-data',
  copyCreateCopyRegister: '/api/age-manage-info-maintain/copy-register/copy-create',
  getScreenDataCsvOutputScreen: '/api/age-manage-info-maintain/csv-output-screen/get-screen-data',
  output_F12CsvOutputScreen: '/api/age-manage-info-maintain/csv-output-screen/output-f12',
  gzoomLio_Output: '/api/age-manage-info-maintain/csv-output-screen/gzoom-lio-output',
  next_FieldLio_OutputCsvOutputScreen: '/api/age-manage-info-maintain/csv-output-screen/next-field-lio-output',
  loadDataAction: '/api/age-manage-info-maintain/age-manage-info-maintain/load-data-action',
  loadAgeSubData: '/api/age-manage-info-maintain/age-manage-info-maintain-sub/load-age-sub-data',
  registerData: '/api/age-manage-info-maintain/age-manage-info-maintain-sub/regester-data',
  deleteData: '/api/age-manage-info-maintain/age-manage-info-maintain-sub/delete-data'
};


const AgeManageInfoMaintainService = {
  // WS0331001_ContractInfoBatchProcess
  async getScreenDataService(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },

  async indexService(params) {
    return axios.get(apiPaths.index, { params });
  },

  async subAgeTableService(params) {
    return axios.get(apiPaths.subAgeTable, { params });
  },

  async subTitleTableService(params) {
    return axios.get(apiPaths.subTitleTable, { params });
  },

  async copy_F7Service(params) {
    return axios.post(apiPaths.copy_F7, params);
  },

  async query_F8Service(params) {
    return axios.post(apiPaths.query_F8, params);
  },

  async output_F12Service(params) {
    return axios.post(apiPaths.output_F12, params);
  },

  async f7KeySubAgeTableService(params) {
    return axios.post(apiPaths.f7KeySubAgeTable, params);
  },
  async tabKeyAgeSubAgeTableService(params) {
    return axios.post(apiPaths.tabKeyAgeSubAgeTable, params);
  },

  async exitSubTitleTableService(params) {
    return axios.post(apiPaths.exitSubTitleTable, params);
  },

  async recordPrefixSubTitleTableService(params) {
    return axios.post(apiPaths.recordPrefixSubTitleTable, params);
  },

  async verificationCtrlAge_id_codeSubTitleTableService(params) {
    return axios.get(apiPaths.verificationCtrlAge_id_codeSubTitleTable, { params });
  },

  async onblurCtrlAge_titleSubTitleTableService(params) {
    return axios.get(apiPaths.onblurCtrlAge_titleSubTitleTable, { params });
  },

  async f7KeySubTitleTableService(params) {
    return axios.post(apiPaths.f7KeySubTitleTable, params);
  },

  async tabKeyAge_titleSubTitleTableService(params) {
    return axios.post(apiPaths.tabKeyAge_titleSubTitleTable, params);
  },

  async saveSubAgeTable(params) {
    return axios.post(apiPaths.saveSubAgeTable, params);
  },

  async deleteSubAgeTable(params) {
    return axios.delete(apiPaths.deleteSubAgeTable, params);
  },

  async saveSubTitleTable(params) {
    return axios.get(apiPaths.saveSubTitleTable, { params });
  },

  async deleteSubTitleTableService(params) {
    return axios.delete(apiPaths.deleteSubTitleTable, params);
  },

  async getContractInspectConditionService(params) {
    return axios.get(apiPaths.getContractInspectCondition, { params });
  },

  // WS1505010_CopyRegister
  async getScreenDataCopyRegisterService(params) {
    return axios.get(apiPaths.getScreenDataCopyRegister, { params });
  },

  async copyCreateCopyRegisterService(params) {
    return axios.post(apiPaths.copyCreateCopyRegister, params);
  },

  // WS1505013_CsvOutputScreen
  async getScreenDataCsvOutputScreenService(params) {
    return axios.get(apiPaths.getScreenDataCsvOutputScreen, { params });
  },

  async output_F12CsvOutputScreenService(params) {
    return axios.post(apiPaths.output_F12CsvOutputScreen, params);
  },

  async gzoomLio_OutputService(params) {
    return axios.post(apiPaths.gzoomLio_Output, params);
  },

  async next_FieldLio_OutputCsvOutputScreenService(params) {
    return axios.post(apiPaths.next_FieldLio_OutputCsvOutputScreen, params);
  },

  async loadDataActionService(params) {
    return axios.get(apiPaths.loadDataAction, { params });
  },

  async loadAgeSubDataActionService(params) {
    return axios.get(apiPaths.loadAgeSubData, { params });
  },

  async registerDataActionService(params) {
    return axios.post(apiPaths.registerData, params);
  },

  async deleteDataActionService(params) {
    return axios.delete(apiPaths.deleteData, { params });
  },

};

export default AgeManageInfoMaintainService;
