import { message } from "antd";
import EscortManageMaintainService from "services/AdvancePreparation/DocumentManageMaintain/EscortManageMaintainService";

const EscortManageMaintainAction = {
  // Format
  getDataFormat(data) {
    return EscortManageMaintainService.getDataFormat(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getDataChangeFormat(data) {
    return EscortManageMaintainService.getDataChangeFormat(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  createAndUpdateFormat(data) {
    return EscortManageMaintainService.createAndUpdateFormat(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  optionInputCreateAndUpdate(data) {
    return EscortManageMaintainService.optionInputCreateAndUpdate(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  optionInputDelete(data) {
    return EscortManageMaintainService.optionInputDelete(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },


  deleteFormat(data) {
    return EscortManageMaintainService.deleteFormat(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  // OptionInput
  getDataOptionInput(data) {
    return EscortManageMaintainService.getDataOptionInput(data)
  },

  getDataOptionInputDetail(data) {
    return EscortManageMaintainService.getDataOptionInputDetail(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  createAndUpdateOptionInput(data) {
    return EscortManageMaintainService.createAndUpdateOptionInput(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteOptionInput(data) {
    return EscortManageMaintainService.deleteOptionInput(data)
  }
}
export default EscortManageMaintainAction;