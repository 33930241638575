import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { connect } from 'react-redux'
import moment from 'moment'
import { getMenuList } from 'redux/user/user.actions'
import { PlusOutlined, MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Card, Table, Button, Form, Dropdown, Menu, Modal } from 'antd'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import SendingHistoryEdit from 'pages/ZZ_Others/SANAI_CorporateHearingSheet/SendingHistoryEdit.jsx'
import DestinationManagementAction from 'redux/Others/CorporateHearingSheet/DestinationManagement.action'

class DestinationManagement extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '送付先管理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      dataSource: [],
      office_code: '',
      branch_store_code: 0,
    }
  }

  componentDidMount = () => {
    this.setState({
      office_code: this.props.office_code,
      branch_store_code: this.props.branch_store_code,
    })

    this.getSendingHistory()
  }

  getSendingHistory = () => {
    let params = {
      office_code: this.props.office_code,
      branch_store_code: this.props.branch_store_code,
    }

    DestinationManagementAction.getSendingHistory(params)
      .then(res => { this.setState({ dataSource: res }) })
  }

  editSendingHistory = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <SendingHistoryEdit
            id={record.id}
            office_code={this.state.office_code}
            branch_store_code={this.state.branch_store_code}
            date={record.date}
            destination={record.destination}
            sending_product={record.sending_product}
            number_of_people={record.number_of_people}
            remarks={record.remarks}
            onFinishScreen={() => {
              this.getSendingHistory()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='destination-management' style={{ width: 1200 }}>
        <Card title='送付先管理'>
          <div className='box_container'>
            <Form ref={this.formRef}>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
              >
                <Table.Column
                  title='日付'
                  dataIndex='date'
                  className='column-size-8'
                />
                <Table.Column
                  title='送付先'
                  dataIndex='destination'
                  className='column-size-60'
                />
                <Table.Column
                  title='送付物'
                  dataIndex='sending_product'
                  className='column-size-60'
                />
                <Table.Column
                  title='人数'
                  dataIndex='number_of_people'
                  className='column-size-6'
                />
                <Table.Column
                  title='備考'
                  dataIndex='remarks'
                  className='column-size-60'
                />
                <Table.Column
                  key='action'
                  className='column-size-2'
                  fixed='right'
                  title={() => {
                    return (
                      <Button
                        type='primary'
                        size='small'
                        icon={<PlusOutlined />}
                        onClick={() => { this.editSendingHistory({}) }}
                      />
                    )
                  }}
                  render={(text, record) => {
                    return (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => this.editSendingHistory(record)}
                      />
                    )
                  }}
                />
              </Table>
            </Form>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
  getMenuList: () => dispatch(getMenuList())
})
export default connect(mapStateToProps, mapDispatchToProps)(DestinationManagement)