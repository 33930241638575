import React from "react";
import { connect } from "react-redux";
import RouteDefined from "constants/Route";
import { Link, Switch, Route } from "react-router-dom";
import { message, Card, Form, List, Table, Row, Col } from "antd";
import StartupAndMaintenanceAction from 'redux/MN_Basis/StartupAndMaintenance/StartupAndMaintenance.action';
import WS2690001_ComprehensiveMenuMaintenance from "../V5MN0901000_ComprehensiveMenuMaintenance/WS2690001_ComprehensiveMenuMaintenance";
// import WS2519500_Dashboard from "../V5MN0001000_Main/WS2519500_Dashboard";
import "./WS3123001_StartupAndMaintenance.scss"

const linkList = [
    { pathName: '/startup-and-maintenance/comprehensive-menu-maintenance', title: 'メニューカスタマイズ' },
    { pathName: '/startup-and-maintenance/main/dashboard', title: 'ダッシュボード' }
]
class WS3123001_StartupAndMaintenance extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        // document.title = '';

        this.state = {
            link: linkList,
            businessNameList: [],
            menuPath: '../V5MN0901000_ComprehensiveMenuMaintenance/WS2690001_ComprehensiveMenuMaintenance'
        };
    }

    componentDidMount() {
        this.getScreenData();
    }

    getScreenData() {
        this.setState({
            businessNameList: 'メニューカスタマイズ'
        });
        // StartupAndMaintenanceAction.getScreenData()
        //     .then((res) => {
        //         console.log(res);
        //         if (res.length === 0) {
        //             this.setState({
        //                 businessNameList: []
        //             })
        //         } else {
        //             this.setState({
        //                 businessNameList: res
        //             })
        //         }
        //     }).catch(error => {
        //         const res = error.response;
        //         if (!res || res.data || res.data.message) {
        //             message.error('エラーが発生しました');
        //         }
        //     })
    }

    render() {
        return (
            <div className='startupandmaintenance' style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }} >
                <Card title={'システム管理設定'}
                    style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                    headStyle={{ color: '#A6B8C1', backgroundColor: "#00354e" }}
                    bodyStyle={{ height: '100%', overflow: "auto", display: 'flex', flexDirection: 'column', width: '100%', padding: 0 }}>
                    {/* 右並びにする */}
                    <div style={{ height: '100%', width: '100%', display: 'flex' }}>
                        {/* <div style={{ height: '100%', width: '25%', display: 'flex', flexDirection: 'column', backgroundColor: "#c8dcf5" }}> */}
                        <List
                            dataSource={this.state.link}
                            style={{ overflow: "auto", backgroundColor: "#c8dcf5", height: '100%', width: 250, maxWidth: 250, minWidth: 250 }}
                            renderItem={item => (
                                <List.Item key={item.pathName}
                                    style={{ cursor: 'pointer', paddingLeft: 10 }}
                                    onClick={() => this.props.history.push(item.pathName)}
                                >
                                    {/* <List.Item.Meta
                                            title={<label style={{ fontWeight: 'bold' }}>{item.title}</label>}
                                        /> */}
                                    {/* <Link to={{
                                            pathname: item.pathName
                                        }}>
                                            {item.title}
                                        </Link> */}
                                    {item.title}
                                </List.Item>
                            )}
                        />
                        {/* </div> */}
                        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                            {/* <WS2690001_ComprehensiveMenuMaintenance /> */}
                            <Switch>
                                {RouteDefined.map((routeItem, i) => (
                                    <Route
                                        path={'/startup-and-maintenance' + routeItem.path}
                                        component={routeItem.component}
                                        exact={routeItem.exact}
                                        key={`route_startup_${i}`}
                                    />
                                ))}
                            </Switch>
                        </div>
                    </div>
                </Card>
            </div >
        );
    }
}
const mapStateToProps = ({ userReducer }) => ({
    // menuList: userReducer.menuList,
    // userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
    // getMenuList: () => dispatch(getMenuList())
});
export default connect(mapStateToProps, mapDispatchToProps)(WS3123001_StartupAndMaintenance);