import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Table, Row, Col, message } from 'antd'
import moment from 'moment-timezone'
import PatientInfoQueryOthersAction from 'redux/basicInfo/PersonalInfoMaintain/PatientInfoQueryOthers.actions.js'
import JapaneseDateCoustom from 'components/Commons/JapaneseDateCoustom'

export class WS0343010_PatientInfoQueryOthers extends Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '患者情報照会[その他]'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      rowSelect: {},
      initParams: {
        KanaSearch: '',
        DateOfBirthSearch: ''
      },
      // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          // 選択されたページ番号で再取得
          this.index(current)
        }
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    // 患者一覧を取得
    this.index(this.state.pagination.current)
  }

  /**
   * 患者一覧を取得
   * @param {*}
   */
  index = (current) => {
    const date = this.formRef.current?.getFieldValue('DateOfBirthSearch')
    const params = {
      Search: this.formRef.current?.getFieldValue('Search') ?? '',
      DateOfBirthSearch: date ? moment(date).format('YYYY/MM/DD') : '',
      OptionUser: this.props.Li_OptionUser ?? '',
      pageSize: this.state.pagination.pageSize, //1ページあたりの件数
      current: current //
    }

    PatientInfoQueryOthersAction.index(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res.items,
            pagination: { //ペジネーション変更
              ...this.state.pagination,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }

  /**
   * 削除処理
   * @param {*} params
   */
  deleteData = (params) => {
    PatientInfoQueryOthersAction.delete(params)
      .then(res => {
        this.index(this.state.initParams)
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  /***
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='patient-info-query-others'>
        <Card
          title={this.props.hideTitle ? null : '患者情報照会'} // ※[WS0343001_個人情報保守]にてthis.props.hideTitleをtrueにしている
          style={{ border: (this.props.hideTitle ?? false) ? 'none' : '' }}
          bodyStyle={{ padding: (this.props.hideTitle ?? false) ? 0 : '' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label='検索' name='Search'>
                  <Input
                    onPressEnter={(e) => this.index()}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>

                {/* <Form.Item label='生年月日' name='DateOfBirthSearch'>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format={'YYYY/MM/DD'}
                    onChange={(e) => this.index()}
                  />
                </Form.Item> */}

                <Form.Item
                  name='DateOfBirthSearch'
                  label='生年月日'
                  style={{ marginBottom: 0, marginLeft: 10 }}>
                  <JapaneseDateCoustom
                    formRef={this.formRef}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Table
              size='small'
              dataSource={this.state.dataSource}
              bordered
              rowKey={(record) => record.id}
              // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
              pagination={this.state.pagination.total > this.state.pagination.pageSize ? this.state.pagination : false}
              // pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              onRow={(record, index) => ({
                onClick: event => {
                  this.setState({ rowSelect: record })
                },
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    // 選択
                    this.props.onFinishScreen({
                      Lo_PersonalNumId: record.Lo_PersonalNumId,
                      recordData: record
                    })
                  }
                }
              })}
              scroll={{ y: 500 }}
            >
              <Table.Column
                title='個人番号'
                dataIndex='patient_no'
                width={120}
                render={(text) =>
                  <div style={{ textAlign: 'right' }}>{text}</div>
                }
              />
              <Table.Column title='カナ氏名' dataIndex='kana_name' />
              <Table.Column title='漢字氏名' dataIndex='kanji_name' />
              <Table.Column
                title='性別'
                dataIndex='GenderChars'
                width={80}
                render={(value) =>
                  <div style={{ color: value === '男性' ? '#0F3278' : '#B41432', textAlign: 'center' }}>
                    {value}
                  </div>
                }
              />
              <Table.Column
                title='生年月日'
                dataIndex='birthday_on'
                width={160}
                render={(text) => moment(text).isValid() ? moment(text).format('YYYY/MM/DD') : ''}
              // render={(text) => moment(text).isValid() ? moment(text).format('NNy/MM/DD') : ''}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0343010_PatientInfoQueryOthers)
