import { message } from 'antd'
import IntroduceLetterSubjectCmtInquiryService from 'services/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterSubjectCmtInquiryService.js'

const IntroduceLetterSubjectCmtInquiryAction = {
  index(data) {
    return IntroduceLetterSubjectCmtInquiryService.index(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default IntroduceLetterSubjectCmtInquiryAction
