import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Button, Dropdown, Menu } from 'antd'
import ExamDepartmentMasterAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/ExamDepartmentMaster.action'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import WS0922012_ExamDepartmentMasterEdit from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922012_ExamDepartmentMasterEdit.jsx'

const columns = [
  {
    title: 'コード',
    dataIndex: 'exam_department_code',
    classNumber: '5',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '検索キー',
    dataIndex: 'search_key',
    classNumber: '20',
    type: 1,
  },
  {
    title: '診療科(検査部門)',
    dataIndex: 'exam_department_name',
    classNumber: '40',
    type: 1,
  },
  {
    title: '受診科',
    dataIndex: 'remarks',
    type: 1,
  }
]

class WS0922011_ExamDepartmentMaster extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '診療科(検査部門)マスタ'

    this.formRef = React.createRef()
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      LnkInmanageCode: 9
    }
    ExamDepartmentMasterAction.index(params)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          mainSource: res ? res : []
        })
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * 保存ボタン処理
   */
  save = (output) => {
    const params = {
      LnkInmanageCode: 9,
      ...output.itemChange
    }
    ExamDepartmentMasterAction.save(params)
      .then(res => {
        if (res) {
          // データ再取得
          this.index()
          this.closeModal()
        }
      })
  }

  /**
   * 削除ボタン処理
   */
  delete = (output) => {
    const params = {
      LnkInmanageCode: 9,
      id: output.itemChange.id
    }
    ExamDepartmentMasterAction.delete(params)
      .then(res => {
        // データ再取得
        this.index()
        this.closeModal()
      })
  }

  /**
   * 新規追加・編集
   * @param {*} record
   */
  showWS0922012_ExamDepartmentMasterEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        className: '',
        component:
          <WS0922012_ExamDepartmentMasterEdit
            record={record}
            onFinishScreen={() => {
              this.index()
              this.closeModal()
            }}
          />
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='doctor-name-master'>
        <Card title='診療科(検査部門)マスタ'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            loading={false}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ y: 400 }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: event => {
                  // 編集、削除
                  this.showWS0922012_ExamDepartmentMasterEdit(record)
                }
              }
            }}
          >
            <Table.Column
              title='コード'
              dataIndex='exam_department_code'
              width={100}
            />
            <Table.Column
              title='検索キー'
              dataIndex='search_key'
              width={120}
            />
            <Table.Column
              title='診療科(検査部門)'
              dataIndex='exam_department_name'
            />
            <Table.Column
              title='受診科'
              dataIndex='remarks'
            />
            <Table.Column
              title='セット情報'
              dataIndex='setInfo'
              render={(value) => {
                let setText = ''
                value.forEach((item, index) => {
                  setText += item.set_short_name
                  if (index < (value.length - 1)) {
                    setText += '/'
                  }
                })

                return (
                  <div>{setText}</div>
                )
              }}
            />
            <Table.Column
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() =>
                    // 新規作成
                    this.showWS0922012_ExamDepartmentMasterEdit()
                  }
                />
              }
              width={40}
              align='center'
              render={(value, record) => (
                <Dropdown
                  trigger='click'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='1'
                        onClick={() =>
                          // 編集、削除
                          this.showWS0922012_ExamDepartmentMasterEdit(record)
                        }
                      >
                        変更
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            />
          </Table>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0922011_ExamDepartmentMaster)
