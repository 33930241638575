import axios from "configs/axios";

const API_LIST = {
    getScreenData: "/api/associate-insure-param-maintain/detail-info-correct"
}


const DetailInfoCorrect = {
    async getScreenData(params){
        console.log('services_params->', params)
        return axios.get(API_LIST.getScreenData, {params});
    }
};
export default DetailInfoCorrect;