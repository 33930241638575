import React from "react"
import { connect } from "react-redux"
import { Card, Form, Input, Button, Modal, message } from "antd"
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'

import ModalDraggable from "components/Commons/ModalDraggable"
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '80px' } }
}

class WS3200006_detailSubEdit extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '電子カルテ連携枠設定 新規・編集'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
   *  データ保存
   */
  save = () => {
    const values = this.formRef.current.getFieldsValue()

    if (values.exam_code === undefined) {
      return message.error('検査コードを入力してください')
    }

    if (this.props.newFlag) {
      this.props.onNewData(values)
    } else {
      this.props.onUpdate(values)
    }
  }

  /**
   * データ削除
   */
  delete = () => {
    this.props.onDelete(this.props.record)
  }

  /**
   * サーチが押された処理
   */
  showMs0670InspectionItems = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component:
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={value}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                exam_code: output.recordData.test_item_code,
                exam_name: output.recordData.exam_name
              })
              this.closeModal()
            }}
          />
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className="detail-sub-edit">
        <Card
          title={'電子カルテ連携枠設定 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='検査コード'
                name='exam_code'//対応したものに書き換え
                readOnly
                {...labelCol}
              >
                <Input.Search
                  type='number'
                  onSearch={(value) => this.showMs0670InspectionItems(value)}
                />
              </Form.Item>

              <Form.Item
                label='検査名称'
                name='exam_name'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  readOnly
                  bordered={false}
                />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.save()}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3200006_detailSubEdit)
