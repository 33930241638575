import { message } from "antd";
import ResultConfirm from "services/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/ResultConfirmService";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error("エラーが発生しました");
      }
    });
};

const ResultConfirmAction = {
  getScreenData(request) {
    return post(ResultConfirm.getScreenData, request);
  },
  LogDisplay(request) {
    return post(ResultConfirm.LogDisplay, request);
  },
  xml(request) {
    return post(ResultConfirm.xml, request);
  },
  downloadXml(request) {
    return ResultConfirm.downloadXml(request);
  },
  downloadLog(request) {
    return ResultConfirm.downloadLog(request);
  },
  makeCSV(request) {
    return ResultConfirm.makeCSV(request)
    // return post(ResultConfirm.makeCSV, request);
  },
  downloadCSV(request) {
    return post(ResultConfirm.downloadCSV, request);
  },
};

export default ResultConfirmAction;
