import CsvCaptureScreenService from  "services/SpecificInsureMaintenance/DataRangeCheck/CsvCaptureScreenService"
import { message } from "antd";

const CsvCaptureScreenAction = {
    ExecCapture(data) {
        return CsvCaptureScreenService.execCapture(data)
            .then((res) => {
                
            })
            .catch((err) => {
                message.error(err.response.data.message);
            });
    },
}
export default CsvCaptureScreenAction;
