import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/specific-health-data-xml-output/xml-document-query-select-sub",
  XmlCreate: "/api/specific-coercive-finger-xml-output/xml-output-confirm/event-xml-create",
  XmlCheck: "/api/specific-coercive-finger-xml-output/xml-output-confirm/event-xml-check"
};
const XmlOutputConfirmService = {
  async GetScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
  async XmlCreate(params) {
    return axios.get(apiPaths.XmlCreate, { params });
  },
  async XmlCheck(params) {
    return axios.get(apiPaths.XmlCheck, { params });
  },
};

export default XmlOutputConfirmService;