import axios from "configs/axios";

const API_LIST = {
  getCourseData: "/api/provisional-reservation/provisional-reservation/get-course-data",
};

const ProvisionalReservationService = {
  async getCourseData(params) {
    return axios.post(API_LIST.getCourseData, params);
  },
};

export default ProvisionalReservationService;
