import axios from 'configs/axios'

const apiPaths = {
  index: '/api/personal-info-maintain-directly/patient-info-query-stard',
  delete: '/api/personal-info-maintain-directly/patient-info-query-stard/delete',
}

const PatientInfoQueryStardService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },
}

export default PatientInfoQueryStardService
