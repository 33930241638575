import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, InputNumber, message, Button, Checkbox, Table, Modal, Col, } from "antd";
import { SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, InfoOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from "moment";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery';
import WS1562001_FormSelect from 'pages/UT_UserTools/V4UT0001000_LabelPrint/WS1562001_FormSelect';
import WS0339001_InsurerInfoMaintain from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain'
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import AddressLabelInsurerAction from 'redux/UserTools/LabelPrint/AddressLabel_Insurer.actions';
import NumberFormat from 'components/Commons/NumberFormat'

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 100 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS1556001_AddressLabel_Insurer extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    // // document.title = '住所ラベル[保険者]';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      isLoadingForm: true,
      isLoadingTable: false,
      dataSource: [], // targetList
      selectedRowKeys: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      rowSelect: {},
      listID: [],
      StsSelectOne: false,
      W1_urgent_specimen_flg: false,
      specifyDestination: 0,
      option_normal: '',
    };
  }

  componentDidMount() {
    this.getScreenDataAction();
  }

  getScreenDataAction() {
    this.setState({ isLoadingForm: true });

    AddressLabelInsurerAction.getScreenData()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          stsPersonInCharge: res.data.stsPersonInCharge,
          stsRespectCode: true,
        })
        this.setState({
          option_normal: res.data.option_normal,
        });
      })
      .finally(() => this.setState({ isLoadingForm: false }));
  }

  //検索ボタン
  displayBtn() {
    const data = {
      insurerCodeF: this.formRef.current?.getFieldValue("insurerCodeF"),
      insurerCodeT: this.formRef.current?.getFieldValue("insurerCodeT"),
      option_normal: this.state.option_normal,
    };
    AddressLabelInsurerAction.displayBtn(data)
      .then((res) => {
        let arrTemp = res.data.filter(x => x.stsPrintFlag === true).map(item => item.id)
        this.setState({
          dataSource: res.data,
          selectedRowKeys: arrTemp,
        })
        this.formRef.current.setFieldsValue({
          tableData: res.data
        })
      })
      .catch((error) => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
      });
  }

  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  ChangeCheck(id, value) {
    let array = this.state.dataSource;
    let Index = array.findIndex(item => item.id === id)

    array[Index].W1_urgent_specimen_flg = value;
    this.setState({ dataSource: array });
  }

  onChangeValue = (value, record, index) => {
    let arrTemp = [...this.state.dataSource]
    // 内容を反映
    let obj = {
      ...record,
      copies: NumberFormat.changeHankaku(value), //変更内容
    }
    arrTemp[index] = obj

    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
    })
  }

  print_f12 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={false}
            Li_PrinterNoSpecifiedValue={0}
            Lo_Preview={false}
            Lo_PrinterNo={0}
            Lo_StsOutput={true}
            onFinishScreen={(res) => {
              this.closeModal()
              if (res.Lo_StsOutput) {
                let values = {
                  previewMode: res.Lo_Preview,
                  PrinterNum: res.Lo_PrinterNo
                }
                this.printDataCheck(values)
              }
            }}
          />),
      },
    })
  }

  printDataCheck = (values) => {
    // チェックボックスにチェックがついているデータの一覧を取得
    const selectedIdList = [...this.state.selectedRowKeys]
    const data = [...this.state.dataSource]
    let selectedData = [], obj
    for (let i = 0; i < data.length; i++) {
      obj = { ...data[i] }
      for (let j = 0; j < selectedIdList.length; j++) {
        if (obj.id === selectedIdList[j]) {
          selectedData.push(obj)
          break
        }
      }
    }

    let params = {
      selectedData: selectedData,
      option_normal: this.state.option_normal,
      previewMode: values.previewMode,
      PrinterNum: values.PrinterNum,
      line: this.formRef.current.getFieldValue('line'),
      column: this.formRef.current.getFieldValue('column'),
      formatNumber: this.formRef.current.getFieldValue('formatNumber'),
      stsPersonInCharge: this.formRef.current.getFieldValue('stsPersonInCharge') ? 1 : 0,
      stsRespectCode: this.formRef.current.getFieldValue('stsRespectCode') ? 1 : 0,
    }

    //エラーチェック（returnのタイプがblobのため特殊処理）
    AddressLabelInsurerAction.errorCheck(params)
      //メッセージがあればエラーとして表示
      .then(res => {
        if (res.data.message !== '') {
          message.error(res.data.message)
          //メッセージが無ければ印刷処理
        } else {
          this.printAction(params)
        }
      })
  }

  printAction = (params) => {
    if (params.previewMode) {
      AddressLabelInsurerAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          // fileOpen.onload = function () {
          //   fileOpen.print();
          // };
        })
        .catch(err => {
          const res = err.response;
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        });
    } else {
      AddressLabelInsurerAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
        .catch(err => {
          const res = err.response;
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          if (!res || !res.data || !res.data.message) {
            message.error("エラーが発生しました");
            return;
          }
          message.error(res.data.message);
        });
    }
  }

  render() {
    return (
      <div className="address-label-personal">
        <Card title='住所ラベル[保険者]'>
          <Form
            {...grid}
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
            initialValues={{
              ReserveDate: moment(),
              ExtractDivision: this.state.ExtractDivision,
            }}
          >
            <div className="box_container">
              <div className="box_inner_horizontal">
                <div className="box_search">
                  <div className='box_search_inner_border' style={{ minWidth: 500 }}>
                    <div className="box_inner_horizontal">
                      <Form.Item name="insurerCodeF" label="保険者コード" {...smGrid}>
                        <Input.Search
                          className='input-search-size-allow-clear-8'
                          type="text"
                          style={{ width: 160 }}
                          maxLength={10}
                          allowClear={true}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        insurerCodeF: output.Lo_InsurerCode,
                                      });
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      });
                                    }}
                                  />
                                ),
                              },
                            });
                          }}

                          onSearch={(value, event) => {
                            if (event.target.localName !== "input") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0246001_InsurerInfoSearchQuery
                                      Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          insurerCodeF: output.Lo_InsurerCode,
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }
                          }
                        />
                      </Form.Item>

                      <Form.Item name="insurerCodeT" label="～" >
                        <Input.Search
                          className='input-search-size-allow-clear-8'
                          type="text"
                          style={{ width: 160 }}
                          maxLength={10}
                          allowClear={true}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        insurerCodeT: output.Lo_InsurerCode,
                                      });
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      });
                                    }}
                                  />
                                ),
                              },
                            });
                          }}

                          onSearch={(value, event) => {
                            if (event.target.localName !== "input") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0246001_InsurerInfoSearchQuery
                                      Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          insurerCodeT: output.Lo_InsurerCode,
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }
                          }
                        />
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name='formatNumber'
                        label='様式'
                        {...smGrid}
                      >
                        <Input.Search style={{ width: 120 }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== "input") {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 500,
                                  component: (
                                    <WS1562001_FormSelect
                                      onFinishScreen={(res) => {
                                        this.formRef.current.setFieldsValue({
                                          formatNumber: res.recordData.formatNumber,
                                          formatName: res.recordData.formatName,
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }}

                          onChange={(e) => {
                            if (e.target.value == '') {
                              this.formRef.current.setFieldsValue({
                                formatName: '',
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='formatName' label=''>
                        <Input
                          readOnly
                          type='text'
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="box_search_inner" style={{ minWidth: 300 }}>
                    <div className="box_inner_horizontal">
                      {/*行 */}
                      <Form.Item
                        name='line'
                        label='印刷開始行'
                        initialValue={1}
                        {...smGrid}
                      >
                        <InputNumber
                          style={{ textAlign: 'right', width: 40, marginBottom: 0 }}
                          maxLength={2}
                        />
                      </Form.Item>

                      {/*列 */}
                      <Col span={12}>
                        <Form.Item
                          name='column'
                          label='列'
                          initialValue={1}
                        >
                          <InputNumber
                            style={{ textAlign: 'right', width: 40, marginBottom: 0 }}
                            maxLength={2}
                          />
                        </Form.Item>
                      </Col>
                    </div>

                    <Form.Item
                      label="担当者印字"
                      name="stsPersonInCharge"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                      {...smGrid}
                    >
                      <Checkbox>　</Checkbox>
                    </Form.Item>

                    <Form.Item
                      label="敬称"
                      name="stsRespectCode"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                      {...smGrid}
                    >
                      <Checkbox>　</Checkbox>
                    </Form.Item>
                  </div>
                </div>

                <div className="box_search_btn">
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => this.displayBtn()}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                pagination={false}
                scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected) => {
                    // 選択
                    this.onSelect(record, selected)
                  },
                  onSelectAll: (selected) => {
                    // 全選択
                    this.onSelectAll(selected)
                  }
                }}
              >
                <Table.Column
                  title="部数"
                  dataIndex="copies"
                  className="column-size-5"
                  render={(value, record, index) => (
                    <Form.Item style={{ marginBottom: '0' }} name={['tableData', index, 'copies']}>
                      <Input
                        className='input-size-number-2'
                        value={record.copies}
                        maxLength={2}
                        onBlur={(e) => this.onChangeValue(e.target.value, record, index)}
                        onPressEnter={(e) => this.onChangeValue(e.target.value, record, index)}
                      />
                    </Form.Item>
                  )}
                />
                <Table.Column
                  title="保険者コード"
                  dataIndex="insurer_code"
                  className="column-size-10"
                  render={(value) => (
                    <div style={{ textAlign: "right" }}>{value}</div>
                  )}
                />

                <Table.Column
                  title="保険者番号"
                  dataIndex="insurer_number"
                  className="column-size-10"
                  render={(value) => (
                    <div style={{ textAlign: "right" }}>{value}</div>
                  )}
                />

                <Table.Column
                  width={50}
                  title="メモ"
                  dataIndex="importance"
                  render={(value, record, index) => {
                    let icon = "";
                    switch (record.importance) {
                      case 1: icon = (<InfoCircleOutlined style={{ fontSize: 20, color: "#1890ff" }} />);
                        break;
                      case 3: icon = (<WarningOutlined style={{ fontSize: 20, color: "#faad14" }} />);
                        break;
                      case 5: icon = (<CloseCircleOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />);
                        break;
                      default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>);
                    }
                    return (
                      <div style={{ textAlign: "center", cursor: 'pointer' }}
                        hidden={!record.insurer_code}
                        onClick={() => {
                          let title = '保険者情報検索・照会' + ' [' + record.insurer_code + ']'
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '90%',
                              component: (
                                <Card title={title}>
                                  <WS0339001_InsurerInfoMaintain
                                    insurer_code={record.insurer_code}
                                    onClick={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                </Card>
                              ),
                            },
                          })
                        }}>
                        {icon}
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="保険者名"
                  dataIndex="insurer_kanji_name"
                  className="column-size-30"
                />

                <Table.Column
                  className='column-size-10'
                  title=""
                  dataIndex="postal_code
                " />

                <Table.Column
                  className='column-size-60'
                  title="送付先"
                  dataIndex="location_1"
                />
              </Table>

              <div className="box_button_bottom_right">
                <Button
                  icon={<PrinterOutlined />}
                  onClick={() => this.print_f12()}
                  type="primary"
                  style={{ float: "right" }}
                >
                  <span className='btn_label'>
                    印刷
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1556001_AddressLabel_Insurer);
