import axios from "configs/axios";

const API_LIST = {
    getInit: "/api/associate-insure-param-maintain/query-inspect-set",
    
}


const QueryInspectSet = {
    async getInit(params){
        console.log('services_params->', params)
        return axios.get(API_LIST.getInit, {params});
    },
    
};
export default QueryInspectSet;