import React from "react";
import { connect } from "react-redux";
import { LeftOutlined, RightOutlined, MoreOutlined, DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Table, Row, Col, Modal, Checkbox, Popconfirm, InputNumber, message, Space } from "antd";
import { saveToleranceData, deleteToleranceData } from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions';
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'
import WS0285001_JudgeQuery from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery"
import WS0272001_CautionGuideNotesSearchQuery from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx";
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'


const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


//「判定」の正確なスペルは"judgement"だが、テーブルのカラム名が"judgment"になっているため、そのまま"judgment"とする


class WS0362023_RegisterTolerance extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    // exam_name: PropTypes.any,
    Li_InspectType: PropTypes.any,
    Li_judgement_code: PropTypes.any,
    newFlag: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);
    // document.title = '';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      AClassJudge: 'A',
      stsNormalValue: false,
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props)
    console.log(this.props)
  }

  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let judgment_code = this.props.judgment_code;
    let sex = this.props.sex;
    let judgment_level = this.props.judgment_level;
    let start_date_on = this.props.start_date_on;
    let params = {
      ...values,
      judgment_code,
      judgment_level,
      sex,
      start_date_on,
    };
    this.saveData(params);
  }

  saveData(params) {
    this.setState({ isLoading: true })
    saveToleranceData(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    let judgment_code = this.props.judgment_code;
    let sex = this.props.sex;
    let judgment_level = this.props.judgment_level;
    let start_date_on = this.props.start_date_on;
    let params = {
      judgment_code,
      judgment_level,
      sex,
      start_date_on,
    }
    deleteToleranceData(params)
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  keyPress = (params) => {
    if (params.key === 'Enter') {
      this.digitConversion(params);
    }
  }

  roundValue = (params) => {
    let value = params.value;
    let digit = params.digit;
    let newValue = (Math.round(value * (10 ^ (digit - 1)))) / (10 ^ (digit - 1));
    if (!Number.isFinite(newValue) || value === '') {
      newValue = '';
    } else {
      newValue = newValue.toFixed(digit);
    }
    return (newValue);
  }

  digitConversion = (params) => {
    let newValue = 0;
    switch (this.props.exam_type) {
      case 'N':
      case '+': {
        newValue = Math.round(params.value);
        break;
      }
      case 'N1':
      case '+1': {
        let item = {
          value: params.value,
          digit: 1
        }
        newValue = this.roundValue(item);
        break;
      }

      case 'N2':
      case '+2': {
        let item = {
          value: params.value,
          digit: 2
        }
        newValue = this.roundValue(item);
        break;
      }

      case 'N3':
      case '+3': {
        let item = {
          value: params.value,
          digit: 3
        }
        newValue = this.roundValue(item);
        break;
      }

      case 'N4':
      case '+4': {
        let item = {
          value: params.value,
          digit: 4
        }
        newValue = this.roundValue(item);
        break;
      }
      default:
    }
    this.formRef.current.setFieldsValue({ [params.field]: newValue })
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title={"許容範囲：" + this.props.exam_name}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className="box_inner_horizontal" >
              <Form.Item name="low_tolerance_value" label="許容範囲">
                <Input
                  maxLength={10}
                  style={{ width: 100 }}
                  onBlur={(e) => this.digitConversion({ value: e.target.value, field: "low_tolerance_value" })}
                  onPressEnter={(e) => this.digitConversion({ value: e.target.value, field: "low_tolerance_value" })}
                />
              </Form.Item>

              <Form.Item name="high_tolerance_value" label="～">
                <Input
                  maxLength={10}
                  style={{ width: 100 }}
                  onBlur={(e) => this.digitConversion({ value: e.target.value, field: "high_tolerance_value" })}
                  onPressEnter={(e) => this.digitConversion({ value: e.target.value, field: "high_tolerance_value" })}
                />
              </Form.Item>
            </div>
            <div className="box_button_bottom_right"
              style={{ display: 'flex', gap: '20px' }}>

              {!this.props.newFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px', display: (this.props.Li_id === 0) ? 'none' : '' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  // onConfirm={() => this.deleteTableData()}
                  onConfirm={() => this.deleteData()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    disabled={this.props.Li_id === 0}
                  >
                    削除
                  </Button>
                </Popconfirm> : null}

              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => { this.onFinish(); }}
              >
                保存
              </Button>
            </div>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0362023_RegisterTolerance);