import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import DockDisplayItemSettingAction from 'redux/Others/MedicalCheckupCalendar/DockDisplayItemSetting.action'
import DockDisplayItemSettingEditMock from 'pages/ZZ_Others/SANAI_MedicalCheckupCalendar/DockDisplayItemSettingEdit'

class DockDisplayItemSetting extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource1: [],
      dataSource2: [],
      selectedNodes: [],
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      section: 1,
    })
    this.getListData()
  }

  /**
   * 初期表示
   */
  getListData() {
    DockDisplayItemSettingAction.getListData()
      .then((res) => {
        console.log(res);
        this.setState({
          dataSource1: res.result_1,
          dataSource2: res.result_2
        })
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  /**
   * 編集画面呼び出し
   */
  callEdit(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <DockDisplayItemSettingEditMock
            record={record}
            onFinishScreen={(output) => {
              this.getListData()
              this.props.onFinishScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  render() {
    return (
      <div className="reserves-displayitem-setting">
        <Card title="人間ドック表示項目設定" className="mb-2">
          <Form ref={this.formRef} >

            <div className="box_inner_horizontal" style={{ gap: 40, }}>
              <div className="box_inner_vertical_label_top">
                <Card>
                  <div className="table_header_filter">
                    <div>コース</div>
                  </div>
                  <Table
                    bordered
                    dataSource={this.state.dataSource1}
                    loading={false}
                    size='small'
                    pagination={false}
                    rowKey={(record) => record.nameId}
                    scroll={{ x: 'max-content', y: 360 }}
                    onRow={(record, rowIndex) => ({
                      onDoubleClick: () =>
                        this.callEdit(record)
                    })}
                  >
                    <Table.Column
                      width={20}
                      title="No"
                      dataIndex="nameId"
                      key=""
                    />

                    <Table.Column
                      title="名称"
                      dataIndex="displayName"
                      key=""
                    />

                    <Table.Column
                      width={20}
                      align="center"
                      render={(value, record, index) => (
                        <Button
                          size="small"
                          icon={<MoreOutlined />}
                          onClick={() => {
                            this.callEdit(record)
                          }}
                        />
                      )}
                    />
                  </Table>
                </Card>
              </div>

              <div className="box_inner_vertical_label_top">
                <Card>
                  <div
                    className="table_header_filter"
                  >
                    <div>検査</div>
                  </div>

                  <Table bordered
                    dataSource={this.state.dataSource2}
                    loading={false}
                    size='small'
                    pagination={false}
                    rowKey={(record) => record.nameId}
                    scroll={{ x: 'max-content', y: 360 }}
                    onRow={(record, rowIndex) => ({
                      onDoubleClick: () =>
                        this.callEdit(record)
                    })}
                  >
                    <Table.Column
                      width={20}
                      title="No"
                      dataIndex="nameId"
                      key=""
                    />

                    <Table.Column
                      title="名称"
                      dataIndex="displayName"
                      key=""
                    />

                    <Table.Column
                      width={20}
                      align="center"
                      render={(value, record, index) => (
                        <Button
                          size="small"
                          icon={<MoreOutlined />}
                          onClick={() => {
                            this.callEdit(record)
                          }}
                        />
                      )}
                    />
                  </Table>
                </Card>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }


}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DockDisplayItemSetting);
