
import { message } from "antd";
import UserParamInput0Service from "services/ResultOutput/CsvCreateParamMaintain/UserParamInput0Service";


const UserParamInput0Action = {
  getListData(data) {
    return UserParamInput0Service.getListData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteData(data) {
    return UserParamInput0Service.deleteData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveData(data) {
    return UserParamInput0Service.saveData(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },


  f9(data) {
    return UserParamInput0Service.f9(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  // f10(data) {
  //   return UserParamInput0Service.f10(data)
  //     .then((res) => {
  //       return res?.data;
  //     })
  //     .catch((err) => {
  //       const res = err.response;
  //       if (!res || !res.data || !res.data.message) {
  //         message.error("エラーが発生しました");
  //         return;
  //       }
  //       message.error(res.data.message);
  //     });
  // },

  GZoomBefore(data) {
    return UserParamInput0Service.GZoomBefore(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  GzoomAfter(data) {
    return UserParamInput0Service.GzoomAfter(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};

export default UserParamInput0Action;