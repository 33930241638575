import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/overall-result-display-input',
  getCategoryJudgeList: '/api/not-input-check-category/overall-result-display-input/category-judge',
  getPreviousCategoryDo: '/api/not-input-check-category/overall-result-display-input/category-judge/get-previous-category-do',
  changeCategoryJudge: '/api/not-input-check-category/overall-result-display-input/category-judge/change-category-judge',
  getInspectResultList: '/api/not-input-check-category/overall-result-display-input/inspect-result',
  suffixResultValue: '/api/not-input-check-category/overall-result-display-input/inspect-result/suffix-result-value',
  setPreviousInspectDo: '/api/not-input-check-category/overall-result-display-input/inspect-result/set-previous-inspect-do',
  saveResultValueList: '/api/not-input-check-category/overall-result-display-input/inspect-result/save-result-value-list',
  saveIspectResultValue: '/api/not-input-check-category/overall-result-display-input/inspect-result/save-ispect-result-value',
  getGuideCommentInput: '/api/not-input-check-category/overall-result-display-input/guide-comment-input',
  checkJudgmentExist: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/check-judgment-exist',
  saveJudgmentBasic: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/save-judgment-basic',
  saveJudgmentCategory: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/save-judgment-category',
  checkDefaultValue: '/api/not-input-check-category/overall-result-display-input/check-default-value',
  setPreviousExamCodeDo: '/api/not-input-check-category/overall-result-display-input/inspect-result/set-previous-exam-code-do',
  getResultValue: '/api/not-input-check-category/overall-result-display-input/get-result-value',
  setPreviousExamCategoryCodeDo: '/api/not-input-check-category/overall-result-display-input/inspect-result/set-previous-exam-category-code-do',

  // ↓使用していない
  EventChangeStsInputModeAll: '/api/not-input-check-category/overall-result-display-input/change-input-mode-all',
  EventChangeGuideItemDisplay: '/api/not-input-check-category/overall-result-display-input/change-guide-item-display',
  CategoryJudgeSaveRow: '/api/not-input-check-category/overall-result-display-input/category-judge/save-row-data', //post
  EventClickExpression4: '/api/not-input-check-category/overall-result-display-input/category-judge/click-expression-4',
  EventDbClickExpression4: '/api/not-input-check-category/overall-result-display-input/category-judge/db-click-expression-4',
  EventAllSelect: '/api/not-input-check-category/overall-result-display-input/category-judge/all-select',
  Ctrl_F: '/api/not-input-check-category/overall-result-display-input/inspect-result/search',
  PrefixW1ResultValue: '/api/not-input-check-category/overall-result-display-input/inspect-result/prefix-w1-result-value',
  SuffixW1InspectJugde: '/api/not-input-check-category/overall-result-display-input/inspect-result/suffix-w1-inspect-jugde',
  ChangeW1InspectJudge: '/api/not-input-check-category/overall-result-display-input/inspect-result/change-w1-inspect-jugde',
  SaveRowInspectResult: '/api/not-input-check-category/overall-result-display-input/inspect-result/save-row-data', //post
  GuideCommentInputSaveRow: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/save-row-data',
  EventChangeW3GeneralCmtCd: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/change-W3-general-comments-cd',
  EventChangeW3OverallCmt: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/change-W3-overall-comments',
  acceptedNo: '/api/not-input-check-category/overall-result-display-input/accepted-no',
  acceptedNoIt: '/api/not-input-check-category/overall-result-display-input/accepted-no-lt',
  accepted: '/api/not-input-check-category/overall-result-display-input/accepted',
  GuideCommentInputDeleteRow: '/api/not-input-check-category/overall-result-display-input/guide-comment-input/delete-row',
  findingsEditingBefore: '/api/not-input-check-category/overall-result-display-input/inspect-result/findings-editing-before',
  findingsEditingAfter: '/api/not-input-check-category/overall-result-display-input/inspect-result/findings-editing-after',
  FindingsDelete: '/api/not-input-check-category/overall-result-display-input/inspect-result/findings-delete',

}

const OverallResultDisplayInputService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getCategoryJudgeList(params) {
    return axios.get(apiPaths.getCategoryJudgeList, { params })
  },
  async getPreviousCategoryDo(params) {
    return axios.get(apiPaths.getPreviousCategoryDo, { params })
  },
  async changeCategoryJudge(params) {
    return axios.post(apiPaths.changeCategoryJudge, params)
  },
  async getInspectResultList(params) {
    return axios.get(apiPaths.getInspectResultList, { params })
  },
  async suffixResultValue(params) {
    return axios.post(apiPaths.suffixResultValue, params)
  },
  async setPreviousInspectDo(params) {
    return axios.post(apiPaths.setPreviousInspectDo, params)
  },
  async saveResultValueList(params) {
    return axios.post(apiPaths.saveResultValueList, params)
  },
  async saveIspectResultValue(params) {
    return axios.post(apiPaths.saveIspectResultValue, params)
  },
  async getGuideCommentInput(params) {
    return axios.get(apiPaths.getGuideCommentInput, { params })
  },
  async checkJudgmentExist(params) {
    return axios.post(apiPaths.checkJudgmentExist, params)
  },
  async saveJudgmentBasic(params) {
    return axios.post(apiPaths.saveJudgmentBasic, params)
  },
  async saveJudgmentCategory(params) {
    return axios.post(apiPaths.saveJudgmentCategory, params)
  },
  async checkDefaultValue(params) {
    return axios.get(apiPaths.checkDefaultValue, { params })
  },
  async setPreviousExamCodeDo(params) {
    return axios.post(apiPaths.setPreviousExamCodeDo, params)
  },
  async setPreviousExamCategoryCodeDo(params) {
    return axios.post(apiPaths.setPreviousExamCategoryCodeDo, params)
  },

  // ↓使用していない
  async acceptedNo(params) {
    return axios.get(apiPaths.acceptedNo, { params }) //1
  },
  async accepted(params) {
    return axios.get(apiPaths.accepted, { params }) //1
  },
  async acceptedNoIt(params) {
    return axios.get(apiPaths.acceptedNoIt, { params }) //1
  },
  async EventChangeStsInputModeAll(params) {
    return axios.get(apiPaths.EventChangeStsInputModeAll, { params }) //2
  },
  async EventChangeGuideItemDisplay(params) {
    return axios.get(apiPaths.EventChangeGuideItemDisplay, { params }) //3
  },
  async CategoryJudgeSaveRow(params) {
    return axios.post(apiPaths.CategoryJudgeSaveRow, params) //6
  },
  async EventClickExpression4(params) {
    return axios.get(apiPaths.EventClickExpression4, { params }) //7
  },
  async EventDbClickExpression4(params) {
    return axios.get(apiPaths.EventDbClickExpression4, { params })//8
  },
  async EventAllSelect(params) {
    return axios.get(apiPaths.EventAllSelect, { params }) //9
  },
  async Ctrl_F(params) {
    return axios.get(apiPaths.Ctrl_F, { params }) //11
  },
  async PrefixW1ResultValue(params) {
    return axios.post(apiPaths.PrefixW1ResultValue, params) //12
  },
  async SuffixW1InspectJugde(params) {
    return axios.post(apiPaths.SuffixW1InspectJugde, params)//14
  },
  async ChangeW1InspectJudge(params) {
    return axios.post(apiPaths.ChangeW1InspectJudge, params)//15
  },
  async SaveRowInspectResult(params) {
    return axios.post(apiPaths.SaveRowInspectResult, params) //16
  },
  async GuideCommentInputSaveRow(params) {
    return axios.post(apiPaths.GuideCommentInputSaveRow, params) //18
  },
  async EventChangeW3GeneralCmtCd(params) {
    return axios.get(apiPaths.EventChangeW3GeneralCmtCd, { params })//20
  },
  async EventChangeW3OverallCmt(params) {
    return axios.get(apiPaths.EventChangeW3OverallCmt, { params })//21
  },
  async GuideCommentInputDeleteRow(params) {
    return axios.delete(apiPaths.GuideCommentInputDeleteRow, { params })//22
  },
  async findingsEditingBefore(params) {
    return axios.get(apiPaths.findingsEditingBefore, { params }) //1
  },
  async FindingsDelete(params) {
    return axios.get(apiPaths.FindingsDelete, { params }) //1
  },
  async findingsEditingAfter(params) {
    return axios.get(apiPaths.findingsEditingAfter, { params }) //1
  },
  async getResultValue(params) {
    return axios.get(apiPaths.getResultValue, { params }) //1
  },
}

export default OverallResultDisplayInputService
