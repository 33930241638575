import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Row, Col, Space } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const style = {
  inputGroup: {
    border: "1px solid #1C66B9"
  },
  inputGroupTitle: {
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    pdading: '8px 10px',
    backgroundColor: '#1C66B9',
  },
  inputGroupContent: {
    padding: '5px 15px',
  }
}

class WS1538001_MedicalInstitutionNumberQuerySub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '医療機関番号照会SUB';

    this.state = {
      initialValues: {
        CooperationCodeNo: "",
        CooperationImplementingAgencyName: "",
        CooperationInfoMedicalInstitutionCodeKencd: "",
        CooperationInfoMedicalInstitutionCodeSibu: "",
        ImplementInstitutionNum: "",
        ImplementKanji: "",
        SupportHealthGuideInstitutionNum: "",
        SupportHealthGuideOrg: "",
        TokuhoCreateInstitutions: "",
        TokuhoCreateInstitutionsAddress: "",
        TokuhoCreateInstitutionsPostal: "",
        TokuhoCreateInstitutionsTel: "",
        TokuhoCreatedInstitutionName: "",
        TokuhoSentOriginalInstitutions: "",
        TokukenCreateInstitutions: "",
        TokukenCreateInstitutionsAddress: "",
        TokukenCreateInstitutionsPostal: "",
        TokukenCreateInstitutionsTel: "",
        TokukenCreatedInstitutionName: "",
        TokukenSentOriginalInstitutions: "",
      },
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.callAPILoadData({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.callAPILoadData({});
    }
  }

  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API001538001001", params)
      .then((res) => {
        this.formRef.current?.setFieldsValue(res)
      }).finally(() => this.setState({ isLoading: false }))
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="medical-institution-number-query-sub">
        <Card title="医療機関番号照会SUB">
          <Form
            ref={this.formRef}
            {...formItemLayout}
            onFinish={this.onFinish}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Space direction="vertical" size={15} style={{width: "100%"}}>
                  <div style={style.inputGroup}>
                    <h3 style={style.inputGroupTitle}>協会けんぽ-データ出力</h3>
                    <div style={style.inputGroupContent}>
                      <Form.Item
                        name="CooperationInfoMedicalInstitutionCodeKencd"
                        label="KENCD"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="CooperationInfoMedicalInstitutionCodeSibu"
                        label="SIBU"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={style.inputGroup}>
                    <h3 style={style.inputGroupTitle}>協会けんぽ-請求集計</h3>
                    <div style={style.inputGroupContent}>
                      <Form.Item
                        name="CooperationCodeNo"
                        label="コードＮＯ"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="CooperationImplementingAgencyName"
                        label="実施機関名"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={style.inputGroup}>
                    <h3 style={style.inputGroupTitle}>特定保健指導-XML</h3>
                    <div style={style.inputGroupContent}>
                      <Form.Item
                        name="TokuhoSentOriginalInstitutions"
                        label="送付元機関"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokuhoCreateInstitutions"
                        label="作成機関"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokuhoCreatedInstitutionName"
                        label="作成機関名"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokuhoCreateInstitutionsPostal"
                        label="作成機関郵便"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokuhoCreateInstitutionsAddress"
                        label="作成機関住所"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokuhoCreateInstitutionsTel"
                        label="作成機関TEL"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size={15} style={{width: "100%"}}>
                  <div style={style.inputGroup}>
                    <h3 style={style.inputGroupTitle}>特定健診-XML</h3>
                    <div style={style.inputGroupContent}>
                      <Form.Item
                        name="TokukenSentOriginalInstitutions"
                        label="送付元機関"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokukenCreateInstitutions"
                        label="作成機関"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokukenCreatedInstitutionName"
                        label="作成機関名"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokukenCreateInstitutionsPostal"
                        label="作成機関郵便"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokukenCreateInstitutionsAddress"
                        label="作成機関住所"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="TokukenCreateInstitutionsTel"
                        label="作成機関TEL"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={style.inputGroup}>
                    <h3 style={style.inputGroupTitle}>特定保健指導-支援計画</h3>
                    <div style={style.inputGroupContent}>
                      <Form.Item
                        name="SupportHealthGuideInstitutionNum"
                        label="保健指導機関番号"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="SupportHealthGuideOrg"
                        label="保健指導機関名"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={style.inputGroup}>
                    <h3 style={style.inputGroupTitle}>特定保健指導-実施機関</h3>
                    <div style={style.inputGroupContent}>
                      <Form.Item
                        name="ImplementInstitutionNum"
                        label="機関番号"
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="ImplementKanji"
                        label="漢字"
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1538001_MedicalInstitutionNumberQuerySub);
