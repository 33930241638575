import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, message } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import FollowUpLetterInspectInputAction from 'redux/IntroductionLetter/FollowUpLetter/FollowUpLetterInspectInput.action.js'
import WS0903002_InspectInquiry from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0903002_InspectInquiry.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

class FollowUpLetterInspectInputEdit extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '検査入力（経過手紙）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowChange: {
        exam_code: null,
        group_judgement: null,
      }
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の場合
      this.formRef.current.setFieldsValue({
        exam_code: this.props.record.exam_code,
        exam_name: this.props.record.exam_name
      })
      this.forceUpdate()
    }
  }

  /**
   * 照会:検査照会 モーダル
   */
  showWS0903002_InspectInquiry = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component:
          <WS0903002_InspectInquiry
            Li_ReserveNum={this.props.Li_ReserveNum}
            Li_Date={this.props.Li_Date}
            Li_AcceptNum={this.props.Li_AcceptNum}
            Li_Department={this.props.Li_Department}
            Li_PersonalNum={this.props.Li_PersonalNum}
            subTitle={this.props.subTitle}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                exam_code: output.exam_code,
                exam_name: output.exam_name
              })
              this.closeModal()
            }}
          />,
      },
    })
  }

  /**
   * 削除ボタン処理
   * @param {*} record 
   */
  delete = () => {
    let params = {
      id: this.props.record?.id ?? null,
    }
    FollowUpLetterInspectInputAction.delete(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 保存ボタン処理
   * @returns
   */
  save = () => {
    const examCode = this.getRawValue('exam_code')
    if (examCode === '' || !examCode) {
      message.error('検査値が入力されていません')
    }

    let params = {
      id: this.props.record?.id ?? null,
      exam_code: examCode,
      reservation_number: this.props.Li_ReserveNum,
      course_level: this.props.Li_CourseLevel,
      visit_date_on: this.props.Li_Date,
      personal_number_id: this.props.Li_PersonalNum,
      accepted_number: this.props.Li_AcceptNum,
      department: this.props.Li_Department,
      group_judgement: '',
    }
    FollowUpLetterInspectInputAction.save(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * getFieldValue　Formから取得
   */
  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name) ?? ''
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='inspect-input-edit'>
        <Card
          title={`検査入力 [${(this.props.record === null) ? '新規追加' : '変更'}]`}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item
                label='検査コード'
                name='exam_code'>
                <Input.Search
                  type='number'
                  className='input-search-size-number-10'
                  onSearch={(e) =>
                    // 照会:検査照会 モーダル
                    this.showWS0903002_InspectInquiry()
                  }
                  style={{ textAlign: 'right' }}
                />
              </Form.Item>
              <div style={{ marginTop: 5 }} >{this.getRawValue('exam_name')}</div>
            </div>
          </Form>
          <div className='box_button_bottom_right'>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              hidden={!(this.props.record)}
              onClick={() => {
                Modal.confirm({
                  content: '削除しますか？',
                  okText: '削除',
                  cancelText: 'キャンセル',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpLetterInspectInputEdit)
