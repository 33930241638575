import React from 'react'
import { connect } from 'react-redux'
import PropTypes, { number } from 'prop-types'
import { Card, Form, Input, Button, Table, } from 'antd'
import IntroduceLetterVariousMasterInquiryAction from 'redux/IntroductionLetter/AskIssued/IntroduceLetterVariousMasterInquiry.action.js'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0922005_InspectNameMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922005_InspectNameMaster'
import WS0922008_TreatmentContentMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922008_TreatmentContentMaster'
import WS0922006_VictimNameMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922006_VictimNameMaster'
import WS0922007_FindingsContentMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922007_FindingsContentMaster'
import WS0922003_MedicalInstitutionsMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922003_MedicalInstitutionsMaster'
import WS0922004_DoctorNameMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922004_DoctorNameMaster'
import WS0922009_DepartmentMaster from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922009_DepartmentMaster'
import WS0887001_dataList from './WS0887001_dataList.js'

class WS0887001_IntroduceLetterVariousMasterInquiryMock extends React.Component {

  static propTypes = {
    Li_ManageCode: PropTypes.any,
    Li_SearchKey: PropTypes.any,
    Li_SearchChar: PropTypes.any,
    Lo_VariousCodes: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '紹介状各種マスタ照会'
    this.state = {
      childModal: {
        width: 0,
        visible: false,
        component: null
      },
      dataSource: [],
      title: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current.setFieldsValue({
      SearchChar: this.props.Li_SearchChar ?? ''
    })
    // データ取得
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    let params = {
      Li_ManageCode: this.props.Li_ManageCode,
      Lo_VariousCodes: this.props.Lo_VariousCodes,
      Li_SearchKey: this.formRef.current?.getFieldValue('SearchKey') ?? '',
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar') ?? '',
    }
    let strTitle = ''
    switch (this.props.Li_ManageCode) {
      case 2:
        // 検査照会
        // this.getInspectMaster(params)
        strTitle = '検査内容照会'
        this.setState({ dataSource: WS0887001_dataList.data7 })
        break
      case 3:
        // 治療内容照会
        // this.getTreatmentMaster(params)
        strTitle = '治療内容照会'
        this.setState({ dataSource: WS0887001_dataList.data7 })
        break
      case 4:
        // 医療機関照会
        // this.getMedicalInstitutionMaster(params)
        strTitle = '医療機関照会'
        this.setState({ dataSource: WS0887001_dataList.data7 })
        break
      case 5:
        // 傷病名照会
        // this.getVictimMaster(params)
        strTitle = '傷病名照会'
        this.setState({ dataSource: WS0887001_dataList.data7 })
        break
      case 6:
        // 医師照会
        // this.getDoctorMaster(params)
        strTitle = '医師照会'
        this.setState({ dataSource: WS0887001_dataList.data6 })
        break
      case 7:
        // 診療科照会
        // this.getDepartmentMaster(params)
        strTitle = '診療科照会'
        this.setState({ dataSource: WS0887001_dataList.data7 })
        break
      case 8:
        // 所見内容照会
        // this.getFindingsMaster(params)
        strTitle = '所見内容照会'
        this.setState({ dataSource: WS0887001_dataList.data7 })
        break
      case 9:
        // 所見内容照会
        // this.getFindingsMaster(params)
        strTitle = '診療科(検査部門)照会'
        this.setState({ dataSource: WS0887001_dataList.data9 })
        break
      default:
    }

    this.setState({ title: strTitle })
  }

  /**
   * 検査照会 データを取得　Li_ManageCode：2
   * @param {*} params
   */
  getInspectMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getInspectMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 治療内容照会 データを取得　Li_ManageCode：3
   * @param {*} params
   */
  getTreatmentMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getTreatmentMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 医療機関照会 データを取得　Li_ManageCode：4
   * @param {*} params
   */
  getMedicalInstitutionMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getMedicalInstitutionMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 傷病名照会 データを取得　Li_ManageCode：5
   * @param {*} params
   */
  getVictimMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getVictimMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 医師照会 データを取得　Li_ManageCode：6
   * @param {*} params
   */
  getDoctorMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getDoctorMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 診療科照会 データを取得　department-master-display　Li_ManageCode：7
   * @param {*} params
   */
  getDepartmentMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getDepartmentMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 所見内容照会 データを取得　department-master-display　Li_ManageCode：8
   * @param {*} params
   */
  getFindingsMaster = (params) => {
    IntroduceLetterVariousMasterInquiryAction.getFindingsMaster(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 検査名称マスタ モーダル
   */
  showWS0922005_InspectNameMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922005_InspectNameMaster LnkInmanageCode={2} />
        ),
      },
    })
  }

  /**
   * 治療内容マスタ モーダル
   */
  showWS0922008_TreatmentContentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922008_TreatmentContentMaster LnkInmanageCode={3} />
        ),
      },
    })
  }

  /**
   * 医療機関マスタ モーダル
   */
  showWS0922003_MedicalInstitutionsMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922003_MedicalInstitutionsMaster LnkInmanageCode={4} />
        ),
      },
    })
  }

  /**
   * ドクター名称マスタ モーダル
   */
  showWS0922004_DoctorNameMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922004_DoctorNameMaster LnkInmanageCode={6} />
        ),
      },
    })
  }

  /**
   * 傷病名マスタ　モーダル
   */
  showWS0922006_VictimNameMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922006_VictimNameMaster LnkInmanageCode={5} />
        ),
      },
    })
  }

  /**
   * 診療科マスタ モーダル
   */
  showWS0922009_DepartmentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922009_DepartmentMaster LnkInmanageCode={7} />
        ),
      },
    })
  }

  /**
   * 所見内容マスタ　モーダル
   */
  showWS0922007_FindingsContentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0922007_FindingsContentMaster LnkInmanageCode={8} />
        ),
      },
    })
  }

  /**
  *  設定ボタン押下時、表示モーダルを切り替える
   */
  switchModal = () => {
    switch (this.props.Li_ManageCode) {
      case 2:
        // 検査内容
        this.showWS0922005_InspectNameMaster()
        break
      case 3:
        // 治療内容
        this.showWS0922008_TreatmentContentMaster()
        break
      case 4:
        // 医療機関
        this.showWS0922003_MedicalInstitutionsMaster()
        break
      case 5:
        // 傷病名
        this.showWS0922006_VictimNameMaster()
        break
      case 6:
        // 医師
        this.showWS0922004_DoctorNameMaster()
        break
      case 7:
        // 診療科
        this.showWS0922009_DepartmentMaster()
        break
      case 8:
        // 所見内容
        this.showWS0922007_FindingsContentMaster()
        break
      default:
    }
  }

  /**
   * データ選択
   */
  selectData = (record) => {
    if (this.props.onFinishScreen) {
      const output = {
        variousCodes: record.various_codes,
        recordData: record
      }
      // 親画面に反映する
      this.props.onFinishScreen(output)
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='introduce-letter-various-master-inquiry'>
        <Card title={this.state.title}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item name='SearchKey' label='検索キー'>
                <Input
                  onPressEnter={() => this.index()}
                  maxLength={8} />
              </Form.Item>
              <Form.Item name='SearchChar' label='検索'>
                <Input
                  onPressEnter={() => this.index()}
                  maxLength={500} />
              </Form.Item>
            </div>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: 450 }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    // データ選択
                    this.selectData(record)
                  }
                }
              }}
            >
              <Table.Column
                title='コード'
                dataIndex='various_codes'
                width={100}
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='検索キー'
                width={100}
                dataIndex='search_key'
              />
              {(this.props.Li_ManageCode === 2) ?
                <Table.Column
                  title='検査内容'
                  dataIndex='exam_content'
                />
                : null}
              {(this.props.Li_ManageCode === 3) ?
                <Table.Column
                  title='治療内容'
                  dataIndex='treatment_content'
                />
                : null}
              {(this.props.Li_ManageCode === 4) ?
                <Table.Column
                  title='医療機関名称'
                  dataIndex='medical_institution_name'
                  render={(value, record, index) => {
                    return (
                      <>
                        <span style={{ float: 'left' }}>{record.medical_institution_name}</span>
                        <span style={{ float: 'right' }}>{record.medical_institutions_short_name}</span>
                      </>
                    )
                  }} />
                : null}
              {(this.props.Li_ManageCode === 4) ?
                <Table.Column
                  title='当院/他院'
                  dataIndex='our_hospital_another_hospital'
                  width={80}
                  render={(value, record, index) => {
                    return (
                      // <>
                      //   <span style={{ float: 'left' }}>{record.our_hospital_another_hospital}</span>
                      <span>{record.hospitalType}</span>
                      // </>
                    )
                  }} />
                : null}
              {(this.props.Li_ManageCode === 5) ?
                <Table.Column
                  title='傷病名'
                  dataIndex='sick_name'
                // sorter={(a, b) => a.doctor_name.localeCompare(b.doctor_name, 'ja')}
                />
                : null}
              {(this.props.Li_ManageCode === 6) ?
                <Table.Column
                  title='医師名称'
                  dataIndex='doctor_name'
                // sorter={(a, b) => a.doctor_name.localeCompare(b.doctor_name, 'ja')}
                />
                : null}
              {(this.props.Li_ManageCode === 7) ?
                <Table.Column
                  title='診療科'
                  dataIndex='department_name'
                  style={{ display: (this.props.Li_ManageCode === 7) ? '' : 'none' }}
                // sorter={(a, b) => a.department_name.localeCompare(b.department_name, 'ja')}
                />
                : null}
              {(this.props.Li_ManageCode === 8) ?
                <Table.Column
                  title='所見内容'
                  dataIndex='findings_content'
                // sorter={(a, b) => a.doctor_name.localeCompare(b.doctor_name, 'ja')}
                />
                : null}

              {(this.props.Li_ManageCode === 9) ?
                <Table.Column
                  title='診療科(検査部門)'
                  dataIndex='exam_department_name'
                // sorter={(a, b) => a.doctor_name.localeCompare(b.doctor_name, 'ja')}
                />
                : null}
              <Table.Column title='備考' dataIndex='remarks' />
            </Table>

            {/* Footer */}
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  // 開くモーダルを切り替える
                  this.switchModal()
                }}>
                設定
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0887001_IntroduceLetterVariousMasterInquiryMock)
