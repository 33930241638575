/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ResultAcquisitionProcessAction from 'redux/CooperationRelated/ResultAcquisitionProcess/ResultAcquisitionProcess.action.js'
import { Button, Card, Form, Table, Modal, Tooltip, Dropdown, Menu, Input } from 'antd'
import { DownloadOutlined, MenuOutlined } from '@ant-design/icons'
import moment from 'moment'

const modalWidth = 420

const labelCol = {
  labelCol: { style: { width: 70 } },
}

class WS3008001_ResultAcquisitionProcess extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      isShowUnder: false,
      isLoadingForm: false,
      dataSource: []
    }
  }

  /**
   * 「取込」ボタン押下
   *  読み込み前の確認処理
   */
  confirmRead = () => {
    const dateF = moment(this.formRef.current.getFieldValue('DateFChar'))
    const dateT = moment(this.formRef.current.getFieldValue('DateTChar'))
    const personalNumberId = this.formRef.current.getFieldValue('personal_number_id') ?? 0
    const dateFChar = dateF?.format('YYYY-MM-DD')
    const dateTChar = dateT?.format('YYYY-MM-DD')


    if (dateF.valueOf() > dateT.valueOf()) {
      // エラーメッセージ
      let content = '「' + dateFChar + '」' + ' 以降の日付を設定してください'
      Modal.error({
        width: modalWidth,
        content: content,
        okText: 'OK',
      })
    } else {
      let content = (
        <div>
          Miraisのデータ読み取りを行います
          <br />
          よろしいですか？
        </div>
      )
      Modal.confirm({
        width: modalWidth,
        title: '確認',
        content: content,
        okText: '読込開始',
        cancelText: 'キャンセル',
        onOk: () => {
          this.getMiraisData(dateFChar, dateTChar, false, personalNumberId)
        },
      })
    }
  }

  /**
   *　Oracl接続
   */
  getMiraisData = (dateFChar, dateTChar, isCheck, personalNumberId) => {

    let param = {
      startDay: dateFChar,
      endDay: dateTChar,
      isCheck: isCheck,
      personalNumberId: personalNumberId ?? 0,
    }

    ResultAcquisitionProcessAction.getMiraisData(param)
      .then((res) => {
        if (res) {
          const data = [...res]
          this.setState({ dataSource: data })
        }
      })
  }

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='ocr-capture-start-up'>
        <Card
          title='Mirais検査結果取込'
          style={{ width: 750 }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      onClick={() => {
                        this.getMiraisData(
                          moment(this.formRef.current.getFieldValue('DateFChar'))?.format('YYYY-MM-DD'),
                          moment(this.formRef.current.getFieldValue('DateTChar'))?.format('YYYY-MM-DD'),
                          true,
                          this.formRef.current.getFieldValue('personal_number_id'))

                      }}
                    >
                      確認テスト
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            initialValues={{
              DateFChar: new Date(),
              DateTChar: new Date(),
            }}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item label='対象日付'  {...labelCol}>
                <div className='box_inner_horizontal'>
                  <Form.Item
                    name='DateFChar'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      allowClear={false}
                    />
                  </Form.Item>

                  <div style={{ marginTop: '5px' }}>
                    ~
                  </div>

                  <Form.Item
                    name='DateTChar'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      allowClear={false}
                    />
                  </Form.Item>

                  <div style={{ marginTop: '5px' }}>
                    に受診の方
                  </div>
                </div>
              </Form.Item>
            </div>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='個人ID'
                name='personal_number_id'
                style={{ marginBottom: 0 }}
                {...labelCol}
              >
                <Input className='input-size-number-15' />
              </Form.Item>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              onClick={() => {
                // 取込ボタン押下
                this.confirmRead()
              }}
            >
              <span className='btn_label'>
                取込
              </span>
            </Button>
          </div>

          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            scroll={{ y: resizableTableScroll(130) }}
            style={{ marginTop: 10 }}
          >
            <Table.Column
              title='ID'
              dataIndex='personal_number_id'
              // width={80}
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              }
            />

            <Table.Column
              title='氏名'
              dataIndex='kanji_name'
              // width={150}
              className='column-size-40'
            />

            <Table.Column
              title='日付'
              dataIndex='visit_date_on'
              // width={100}
              className='column-size-date'
              render={(text) => (<div>{moment(text).format('YYYY/MM/DD (ddd)')}</div>)}
            />

            <Table.Column
              title='健診検査名称'
              dataIndex='InspectName'
            />

            <Table.Column
              title='健診検査結果'
              dataIndex='InspectResultsMedicalExam'
              // width={100}
              className='column-size-10'
              render={(value) =>
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              }
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3008001_ResultAcquisitionProcess)
