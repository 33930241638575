import { message } from 'antd'
import FormatSelectService from 'services/IntroductionLetter/IntroduceLetterExtract/FormatSelectService.js'

const FormatSelectAction = {
  index(data) {
    return FormatSelectService.index(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  confirm(data) {
    return FormatSelectService.confirm(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default FormatSelectAction
