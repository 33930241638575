import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Table, Row, Col, Button } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2"
import axios from "axios";

// const dataSource = [
//   { id: 1, site_classification_code: '5', site_classification_name: '30' },
//   { id: 2, site_classification_code: '51', site_classification_name: '40' }]
// const dataSource2 = [{ id: 1, findings_code: '5Z', search_short_name: '30', findings_name: '60', judgment_value: '3' }]
class WS1874004_FindingInquiry extends React.Component {
  static propTypes = {
    Lo_SiteClassify: PropTypes.any,
    Li_Division: PropTypes.any,
    Li_InspectClassifyCode: PropTypes.any,
    Li_SiteClassifyCode: PropTypes.any,
    Lo_FindingsClassifyCode: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);
    // document.title = '所見照会';
    this.state = {
      isLoading: true,
      isLoadingTable: true,
      dataSource: [],
      //dataSource_2: [],
      format: "",
      remarks: "",
      arrKeyCheck: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }
  componentDidMount() {
    //this.getTableSiteTable();
    this.getInitialValue_firstTable()
    //console.log('111111')    

  }

  getInitialValue_firstTable = () => {
    this.setState({ isLoading: true })
    let params = {}
    VenusApiRoutesV2.callApi('API001874004002', params)
      .then(res => {
        console.log("res111->", res);
        this.setState({
          dataSource: res ? res : [],
          remarks: res ? res[0].remarks : ''
        })
        console.log(res);
        this.getInitialValue_secondTable(res[0].format);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });

  }


  //API001874004003
  getInitialValue_secondTable = (value) => {
    let params = { Li_SiteClassifyCode: value }
    VenusApiRoutesV2.callApi('API001874004002', params)
      .then(res => {
        console.log("res111->", res);
        this.formRef.current?.setFieldsValue({
          tableData: res.data
        })

        console.log(res);
        this.getInitialValue_secondTable(res[0].format);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });

  }

  // getInitialValue_secondTable(value) {
  //   // console.log(this.state.format)
  //   let params = { Li_SiteClassifyCode: value };
  //   axios.get("/api/v2/radiography-inspect-maintain/finding-inquiry/findings-table", { params })
  //     .then(res => {
  //       // console.log("res222=>", res);
  //       this.formRef.current?.setFieldsValue({
  //         tableData: res.data
  //       })
  //       this.forceUpdate()
  //       // this.setState({
  //       //   dataSource_2: res.data
  //       // })
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  //     .finally(() => this.setState({ isLoadingTable: false }))
  // }

  // getTableSiteTable() {
  //   let SiteTableDetail = this.dataSource2.filter(data => data.site_code === this.state.selectedRows[0].site_classification_code)
  //   this.setState({ dataSource2: SiteTableDetail })
  // }

  // getDataScreenInput(value) {
  //   this.setState({ isLoadingTable: true, format: value })
  //   AssociateInsureParamMaintainAction.input({ format: value }).then(res => {
  //     this.formRef.current?.setFieldsValue({
  //       tableData: res
  //     })
  //     this.forceUpdate()
  //   })
  //     .finally(() => this.setState({ isLoadingTable: false }))
  // }


  TableFindingInquiry() {
    return (
      <Table bordered
        dataSource={this.state.dataSource}

        // rowClassName={(record, index) => record.id === this.state.selectedRows[0]?.id ? 'table-row-light' : ''}
        size="small"
        scroll={{ y: 500 }}
        rowKey={(record) => record.id}
        pagination={false}
        loading={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              this.setState({
                format: record.format,
                remarks: record.remarks,
              })
              this.getInitialValue_secondTable(record.format);
            }
          };
        }}
      >
        <Table.Column width={90} title="ｺｰﾄﾞ" dataIndex="site_classification_code"
          render={(value, record, index) => {
            return (<div style={{ textAlign: 'right' }}><span>{record?.site_classification_code}</span></div>)
          }} />
        <Table.Column title="部位分類名称" dataIndex="site_classification_name" />
      </Table>)
  }
  TableFindingTable() {
    return (
      <Table bordered
        // dataSource={this.formRef.current?.getFieldValue("tableData") ? this.formRef.current?.getFieldValue("tableData") : []}
        size="small"
        pagination={false}
        loading={this.state.isLoadingTable}
        rowKey={(record) => record.id}
        scroll={{ y: 680 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              const found = this.state.arrKeyCheck.indexOf(record.id)
              if (found < 0) {
                let data = [...this.state.arrKeyCheck]
                data.push(record.id)
                this.setState({
                  arrKeyCheck: data
                })
              }
            }

          };
        }}
      >
        <Table.Column title="ｺｰﾄﾞ" dataIndex="findings_code"
          width={90}
          render={(value, record, index) => {
            return (<div style={{ textAlign: 'right' }}><span>{record?.findings_code}</span></div>)
          }}
        />
        <Table.Column width={180} title="検索略名" dataIndex="search_short_name" />
        <Table.Column title="所見名称" dataIndex="findings_name" />
        <Table.Column width={160} title="判定値" dataIndex="judgment_value" />
      </Table>
    )
  }
  onSubmit() {
    const { onFinishScreen } = this.props;
    if (onFinishScreen) {
      onFinishScreen({})
    }

  }
  render() {
    return (
      <div className="finding-inquiry">
        <Card title="所見照会">
          <Row gutter={24}>
            <Col span={8} style={{ textAlign: 'center' }}>【 部位分類 】</Col>
            <Col span={16} style={{ textAlign: 'center' }}>【 所 見 】</Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              {this.TableFindingInquiry()}
            </Col>
            <Col span={16}>
              {this.TableFindingTable()}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={20}></Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button onClick={() => this.onSubmit()} type="primary">選択</Button></Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1874004_FindingInquiry);
