import axios from 'configs/axios';

const apiPaths = {
    
    getDataMainCos          : '/api/associate-insure-param-maintain/course-select',  
    getCourseSubInput       : '/api/associate-insure-param-maintain/course-select/getCourseSubInput', 
    getSetSubprocess        : '/api/associate-insure-param-maintain/course-select/getSetSubprocess', 

    saveCourseSubInput      : '/api/associate-insure-param-maintain/course-select/saveCourseSubInput', 
    saveSubprocess          : '/api/associate-insure-param-maintain/course-select/saveSubprocess',
    BatchProcess_F10        : '/api/associate-insure-param-maintain/course-select/BatchProcess_F10',

};

const CourseSelectService = {

    async getDataMainCosService() {
        return axios.get(apiPaths.getDataMainCos);
    },  
    async getCourseSubInputService(params) {
        return axios.get(apiPaths.getCourseSubInput, {params});
    },
    async getSetSubprocessService(params) {
        return axios.get(apiPaths.getSetSubprocess, {params});
    },

    async saveAndUpdateCourseSubInputService(params){
        return axios.get(apiPaths.saveCourseSubInput, {params})
    },

    async saveAndUpdateCourseSetSubprocessService(params){
        return axios.get(apiPaths.saveSubprocess, {params})
    },   

    async deleteSiteFindingsMasterMaintainService(params){
        return axios.delete(apiPaths.deleteSiteFindingsMasterMaintain, {params})
    },
    async deleteSiteTableService(params){
        return axios.delete(apiPaths.deleteSiteTable, {params})
    },
    async deleteFindingsTableService(params){
        return axios.delete(apiPaths.deleteFindingsTable, {params})
    },

    async BatchProcess_F10Service(params){
        return axios.get(apiPaths.BatchProcess_F10, {params})
    },  
    
};

export default CourseSelectService;