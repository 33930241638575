import axios from 'configs/axios'

const apiPaths = {
  index: '/api/specific-date-people-num-setting/specific-date-people-num-setting/index',
  getNumPeople: '/api/specific-date-people-num-setting/specific-date-people-num-setting/get-num-people',
  updateNumPeople: '/api/specific-date-people-num-setting/specific-date-people-num-setting/update-num-people',
  deleteSpecificNumPeople: '/api/specific-date-people-num-setting/specific-date-people-num-setting/delete-specific-num-people',
  setClosedDay: '/api/specific-date-people-num-setting/specific-date-people-num-setting/set-closed-day',
};

const SpecificDatePeopleNumSettingService = {
  async index() {
    return axios.get(apiPaths.index);
  },

  async getNumPeople(params) {
    return axios.get(apiPaths.getNumPeople, { params });
  },

  async updateNumPeople(params) {
    return axios.put(apiPaths.updateNumPeople, params);
  },

  async deleteSpecificNumPeople(params) {
    return axios.delete(apiPaths.deleteSpecificNumPeople, { params });
  },

  async setClosedDay(params) {
    return axios.post(apiPaths.setClosedDay, params);
  }
};

export default SpecificDatePeopleNumSettingService;
