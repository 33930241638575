import { message } from 'antd';
import QueryInspectSet from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/QueryInspectSet'

const QueryInspectSetAction = {
    getInit(param){
        return QueryInspectSet.getInit(param)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    },

}
export default QueryInspectSetAction;