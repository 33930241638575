import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, Form, Tooltip } from 'antd'
import CalendarEmptySubAction from 'redux/ReservationBusiness/ReserveStatusSearch/CalendarEmptySub.action.js'
import moment from 'moment-timezone'

const formatDate = 'YYYY/MM/DD'

// 検査予約時間テーブルの幅
const examTableWidth = 220
const examBoxWidth = (examTableWidth * 5) + 40

class WS2577004_CalendarEmptySubDetail extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '予約空き状況'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      periodTimes: [],
      examIdTimes: [],
      examIdFlag: false
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index(true)
  }

  /**
   * 空き状況のデータを取得
   * @param {*} params
   */
  index = (initFlag = false) => {
    // 契約情報（コースやオプション設定）があるかないか
    let contractFlag = false //予約状況検索からの遷移の時
    if (this.props.dataScreen) {
      // 契約情報あり
      contractFlag = true //個人予約画面からの遷移の時
    }

    const params = {
      calenderId: this.props.calenderId,
      Li_Date: moment(this.props.Li_Date ?? '').format(formatDate),

      Li_SetCodeList: this.props.Li_SetCodeList ?? [],
      Li_ContractType: this.props.dataScreen?.ContractType ?? 0,
      Li_ContractClassifyCode: this.props?.dataScreen?.ContractClassifyCode ?? '',
      Li_ContractDate: this.props.dataScreen?.ContractDate ?? '',
      Li_ContractNumber: this.props.dataScreen?.ContractNum ?? 0,
      Li_ConsultCourse: this.props.dataScreen?.ConsultCourse ?? '',
      initFlag: initFlag ? 1 : 0, //サルースのテーブルからとるか、電子カルテからとるか？
      contractFlag: contractFlag ? 1 : 0 //サルースのテーブルからとるか、電子カルテからとるか？
    }

    CalendarEmptySubAction.getDataDetail(params)
      .then(res => {
        if (res) {
          // 検査ごとの予約時間帯
          const examIdData = [...res.examIdTimes]
          if (examIdData.length > 0) {
            this.setState({ examIdTimes: examIdData })
          }
        }
      })
  }

  /**
   * 確定ボタン押下処理
   */
  confirm = () => {
    const date = moment(this.formRef.current.getFieldValue('DateChar'))
    let obj = {
      dateChar: date.format(formatDate),
      timeZone: this.state.selectedReserveTime,
      selectedRows: this.state.selectedRows
    }
    if (this.props.onFinishScreen) {
      // 親画面に受付時間と検査ごとの時間を返却
      this.props.onFinishScreen(obj)
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='calendar-empty-sub-detail' style={{ width: 'fit-content' }} >
        <Card
          title={'予約空き状況' + ' [' + this.props.Li_Name + ']'}
        // title={'予約空き状況' + ' ' + (this.formRef.current?.getFieldValue('DateChar') ? moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD (ddd)') : '')}
        >
          <div className='box_inner_vertical' >
            {moment(this.props.Li_Date ?? '').format('YYYY/MM/DD (ddd)')}
            <div className='box_inner_horizontal'
              style={{ maxWidth: examBoxWidth, overflowX: 'auto' }} >
              {this.state.examIdTimes.map((item, index) =>
                <div
                  key={'kensa-' + index}
                  style={{ minWidth: examTableWidth, maxWidth: examTableWidth }}
                >
                  <div className='table_header_filter'>
                    {item.exam_name}
                  </div>
                  <Table
                    bordered
                    size='small'
                    rowKey={record => record.id}
                    dataSource={item.data}
                    pagination={false}
                    scroll={{ y: 360 }}
                    rowClassName={(record) => record.used_point >= record.app_point ? 'table-row-waku' : ''}
                  >
                    <Table.Column
                      title='時間'
                      dataIndex='app_time'
                    />
                    <Table.Column
                      title='空き状況'
                      dataIndex='app_point'
                      className='column-size-4'
                      render={(value, record) =>
                        <div style={{ textAlign: 'right' }}>
                          {record.used_point + ' / ' + value}
                        </div>
                      }
                    />
                  </Table>
                </div>
              )}
            </div>
          </div>
        </Card >

      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2577004_CalendarEmptySubDetail)
