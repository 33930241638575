import axios from 'configs/axios'

const apiPaths = {
  getOCRData: '/api/ocr-capture-start-up/confirm-acquisition-target/get-ocr-data',
  update: '/api/ocr-capture-start-up/confirm-acquisition-target/update',
}

const ConfirmAcquisitionTargetService = {
  async getOCRData(params) {
    return axios.post(apiPaths.getOCRData, params)
  },

  async update(params) {
    return axios.post(apiPaths.update, params)
  }
}

export default ConfirmAcquisitionTargetService
