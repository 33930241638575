import MiraisTransmissionInquiryService from 'services/CooperationRelated/MiraisTransmissionInquiry/MiraisTransmissionInquiryService.js'
import { message } from "antd";

const MiraisTransmissionInquiryAction = {
  getStatuList(data) {
    return MiraisTransmissionInquiryService.getStatuList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
}
export default MiraisTransmissionInquiryAction;