import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Select, Checkbox, Row, Col, Space, Table, message, Tooltip, Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import axios from "axios"
import SettleInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/SettleInputSub.action'
import { MenuOutlined } from "@ant-design/icons";

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

const styleLabel = {
  background: "#1264bd",
  padding: "5px",
  border: "1px solid #fff",
  color: "#fff",
  textAlign: "center"
};

const styleLabelOnly = {
  background: "#b8cada",
  padding: "5px",
  border: "1px solid #fff",
  textAlign: "center"
};

const stylesCheckbox = {
  display: "flex",
  alignItems: "center",
  border: "1.25px solid #d9d9d9",
  justifyContent: "center",
  padding: 0,
  marginBottom: "2px",
}

// 表示行数
const rowCount = 10
class WS1350200_SettleInputSubSameTime extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  };


  constructor(props) {
    super(props);

    // document.title = '決済入力SUB';

    this.state = {
      billing_division: 1
    };
  }

  renderSelect(data) {
    switch (data) {
      case "0":
        return ""
      case "1":
        return "負担なし"
      case "2":
        return "定額負担"
      case "3":
        return "定率負担"
      case "4":
        return "保険者上限負担"
      default:
        break;
    }
  }

  getScreenData(list) {
    let params = {
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum
    }
    SettleInputSubAction.sameTime(params)
      .then(res => {
        console.log(res);
        let array = []
        let data = res.data
        let obj, nameNumber, same_time_name, same_time_unit_price, SimultaneousImplementMedicalExam
        // 2桁ゼロ埋めリスト
        let numberList = list
        for (let i = 0; i < rowCount; i++) {
          // 2桁ゼロ埋めの値を取得
          nameNumber = numberList[i]
          // 表示名称
          same_time_name = data[`same_time_name_${nameNumber}`]
          same_time_unit_price = data[`same_time_unit_price_${nameNumber}`].toLocaleString()
          SimultaneousImplementMedicalExam = data[`SimultaneousImplementMedicalExam${nameNumber}`]
          obj = {
            id: i + 1,
            same_time_name: same_time_name,
            same_time_unit_price: same_time_unit_price,
            SimultaneousImplementMedicalExam: SimultaneousImplementMedicalExam
          }
          // 配列に追加
          array.push(obj)
        }
        this.setState({ tableData: array, data: data })
        this.formRef.current?.setFieldsValue({
          tableData: array
        })
        this.forceUpdate();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  componentDidMount() {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < rowCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ rowList: array })
    this.getScreenData(array)
  }

  onChange(e, index, name) {
    let copyData = [...this.state.tableData]
    copyData[index][name] = e.target.checked
    this.setState({ tableData: copyData })
  }


  onFinish() {
    let params = {
      id: this.state.data.id,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      SimultaneousImplementMedicalExam01: this.state.tableData[0].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam02: this.state.tableData[1].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam03: this.state.tableData[2].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam04: this.state.tableData[3].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam05: this.state.tableData[4].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam06: this.state.tableData[5].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam07: this.state.tableData[6].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam08: this.state.tableData[7].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam09: this.state.tableData[8].SimultaneousImplementMedicalExam,
      SimultaneousImplementMedicalExam10: this.state.tableData[9].SimultaneousImplementMedicalExam,
    }
    SettleInputSubAction.sameTimeSave(params)
      .then(res => {
        console.log(res);
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {
    return (
      <div className="settle-input-sub">
        <Card title="同時実施健診名">
          <Form
            ref={this.formRef}
          >

            <Table
              bordered
              dataSource={this.state.tableData ? this.state.tableData : []}
              size='small'
              rowKey={(record) => record.id}
              pagination={false}
            >
              <Table.Column
                title='No'
                dataIndex='id'
                width={40}
              />
              <Table.Column
                title='同時実施健診名'
                dataIndex='additional_medical_exam_name'
              />
              <Table.Column
                title='差引金額'
                dataIndex='same_time_unit_price'
                align="right"
                width={80}
              />
              <Table.Column
                title='決済'
                dataIndex='SimultaneousImplementMedicalExam'
                align="center"
                width={40}
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'SimultaneousImplementMedicalExam']} valuePropName='checked'>
                    <Checkbox onChange={(e) => this.onChange(e, index, 'SimultaneousImplementMedicalExam')} />
                  </Form.Item>
                )}
              />
            </Table>
            <div className="box_button_bottom_right">
              <Button type="primary" onClick={() => this.onFinish()}>保存</Button>
            </div>
          </Form>
        </Card >
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1350200_SettleInputSubSameTime);
