import axios from "configs/axios";

const APP_LIST = {
  getFormatList: "/api/course-departmentspecific-format/course-departmentspecific-format/get-format-list",
  getSettedCourseList: "/api/course-departmentspecific-format/course-departmentspecific-format/get-setted-course-list",
  saveCourse: "/api/course-departmentspecific-format/course-departmentspecific-format/save-course",
  deleteCourse: "/api/course-departmentspecific-format/course-departmentspecific-format/delete-course",
  saveDepartmentspecificFormat: "/api/course-departmentspecific-format/course-departmentspecific-format/save-departmentspecific-format",
  deleteDepartmentspecificFormat: "/api/course-departmentspecific-format/course-departmentspecific-format/delete-departmentspecific-format"
};

const CourseDepartmentspecificFormatService = {
  async getFormatList(params) {
    return axios.get(APP_LIST.getFormatList, { params });
  },

  async getSettedCourseList(params) {
    return axios.get(APP_LIST.getSettedCourseList, { params });
  },

  async saveCourse(params) {
    return axios.post(APP_LIST.saveCourse, params);
  },

  async deleteCourse(params) {
    return axios.post(APP_LIST.deleteCourse, params);
  },

  async saveDepartmentspecificFormat(params) {
    return axios.post(APP_LIST.saveDepartmentspecificFormat, params);
  },

  async deleteDepartmentspecificFormat(params) {
    return axios.post(APP_LIST.deleteDepartmentspecificFormat, params);
  },
};

export default CourseDepartmentspecificFormatService;