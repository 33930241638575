import CancerScreeningCSVOutputService from "services/Others/CancerScreeningCSVOutput/CancerScreeningCSVOutputService.js";
import { message } from "antd";

const CancerScreeningCSVOutputAction = {
  getScreenData(data) {
    return CancerScreeningCSVOutputService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  outputCSV(data) {
    return CancerScreeningCSVOutputService.outputCSV(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};

export default CancerScreeningCSVOutputAction;