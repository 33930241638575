import React from "react";
import { connect } from "react-redux";
import  ModalDraggable  from "components/Commons/ModalDraggable";

import { Card, Form, Input, Modal, Button, Space } from "antd";
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';

import { download_file_binary, download_file } from 'helpers/CommonHelpers'

import WS2290003_JudgeUnitList from 'pages/ZZ_Others/DEVTOOL1000_RisingDocumentCreate/WS2290003_JudgeUnitList.jsx';

import RisingDocumentCreateAction from "redux/Others/RisingDocumentCreate/RisingDocumentCreate.action";

const styleFormItem = {
  marginTop: '12px',
  marginLeft: '24px',
}
const mb30 = {
  marginBottom: '30px',
}
const outputbtn = {
  marginLeft: '24px',
  marginBottom: '30px',
}

class WS2290001_RisingDocumentCreate extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '立上資料作成';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.formRef?.current?.setFieldsValue({
      StsDocumentChecklist: true,
      StsJudgeAndUnitList: true,
    })
  }

  showWS2290003_JudgeUnitList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS2290003_JudgeUnitList
            Lio_JudgeLevel={this.formRef.current?.getFieldValue("JudgeLevel")}
            Lio_Pattern={this.formRef.current?.getFieldValue("Pattern")}
            width='fit-content'
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                JudgeLevel: output.JudgeLevel,
                Pattern: output.Pattern,
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />),
      },
    })
  }

  downloadFile(document) {
    const params = {
      JudgeLevel: this.formRef.current?.getFieldValue("JudgeLevel"),
      Pattern: this.formRef.current?.getFieldValue("Pattern"),
    }
    if (document === 'JudgeAndUnitList') {
      if (!params.JudgeLevel || !params.Pattern) {
        Modal.info({
          content: '設定してください',
          okText: 'はい',
          onOk: () => {
            this.showWS2290003_JudgeUnitList()
          },
        })
        return
      }
      params.StsJudgeAndUnitList = true;
    } else {
      params.StsDocumentChecklist = true;
    }
    console.log(params)
    RisingDocumentCreateAction.downloadFile(params)
      .then((res) => {
        console.log(res)
        if (res) {
          // csvファイルダウンロード
          download_file(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoading: false })
        this.closeModal()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }
  render() {
    return (
      <div className="rising-document-create">
        <Card className="mb-2" title="立上資料作成">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div style={{ display: 'none' }}>
              <Form.Item name="JudgeLevel"><Input /></Form.Item>
              <Form.Item name="Pattern"><Input /></Form.Item>
            </div>
            <div>発行資料</div>
            <div>
              <Space>
                <Form.Item
                  style={styleFormItem}
                  label="判定・単位一覧表"
                >
                  <Button
                    icon={<DoubleRightOutlined />}
                    onClick={() => {
                      this.showWS2290003_JudgeUnitList()
                    }}
                  >
                    設定
                  </Button>
                </Form.Item>
              </Space>
              <div>
                <Button
                  icon={<DownloadOutlined />}
                  type='primary'
                  style={outputbtn}
                  onClick={() => {
                    this.downloadFile('JudgeAndUnitList')
                  }} >
                  <span className='btn_label'>
                    出力
                  </span>
                </Button>
              </div>
            </div>
            <div>
              <Form.Item
                style={styleFormItem}
                label="帳票チェック表"
              />
              <div>
                <Button
                  icon={<DownloadOutlined />}
                  type='primary'
                  style={outputbtn}
                  onClick={() => {
                    this.downloadFile('DocumentChecklist')
                  }} >
                  <span className='btn_label'>
                    出力
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0}}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2290001_RisingDocumentCreate);
