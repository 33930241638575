import React from "react";
import { connect } from "react-redux";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import DunningInputAction from "redux/SpecificInsureGuide/InsureGuideInput/DunningInput.action"

import { Card, Form, Input, Select, Button, message, Row, Col, Modal, Table, Dropdown, Menu } from "antd";
import { CloseCircleOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons';

const smGrid = {
  labelCol: { style: { width: 50 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 50px)' } }
}
class WS1444016_DunningInputAdd extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '督促入力';

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      this.formRef.current.setFieldsValue(this.props.record);
    }
  }

  onFinish() {
    this.props.onFinishScreen({
      serial_number: this.formRef.current.getFieldValue('serial_number'),
      content: this.formRef.current.getFieldValue('content'),
    })
  }

  render() {
    return (
      <div className="dunning-input">
        <Card className="mb-2" title="明細入力">
          <Form
            ref={this.formRef}
            autoComplete="off"
            onFinish={() => this.onFinish()}
          >
            <Form.Item label="連番" name='serial_number' rules={[{ required: true }]} {...smGrid}>
              <Input className="input-size-number-2" />
            </Form.Item>
            <Form.Item label="内容" name='content' {...smGrid}>
              <Input className="input-size-60" />
            </Form.Item>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                htmlType="submit"
              >
                保存
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1444016_DunningInputAdd);
