import axios from 'configs/axios';

const apiPaths = {
  getSpecificHealthTokuhoOptionsFile: '/api/specific-health-tokuho-options-file/specific-health-tokuho-options-file',
  saveAndUpdateSpecificHealthTokuho: '/api/specific-health-tokuho-options-file/specific-health-tokuho-options-file/save-and-update',
  deleteSpecificHealthTokuhoOptionsFile: '/api/specific-health-tokuho-options-file/specific-health-tokuho-options-file/delete',
  copyingProcess: '/api/specific-health-tokuho-options-file/specific-health-tokuho-options-file/copying-process',
};

const SpecificHealthTokuhoOptionsFileService = {
  async getSpecificHealthTokuhoOptionsFileService() {
    return axios.get(apiPaths.getSpecificHealthTokuhoOptionsFile);
  },
  async saveAndUpdateSpecificHealthTokuhoService(params) {
    return axios.post(apiPaths.saveAndUpdateSpecificHealthTokuho, params)
      .then(res => { return res })
      .catch(err => { throw err.response.data })
  },
  async deleteSpecificHealthTokuhoOptionsFileService(params) {
    return axios.delete(apiPaths.deleteSpecificHealthTokuhoOptionsFile, { params })
      .then(res => { return res })
      .catch(err => { throw err.response.data })
  },

  async copyingProcessService(params) {
    return axios.post(apiPaths.copyingProcess, params)
      .then(res => { return res })
      .catch(err => { throw err.response.data })
  },
};

export default SpecificHealthTokuhoOptionsFileService;