import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { Card, Table, message, Button } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import {
  getDataSelectBreakdownAction,
  selectRecordSelectBreakdownAction,
  selectAllRecordSelectBreakdownAction
} from "redux/ResultOutput/RomotoArticle52/SelectBreakdown.actions";

const styleTagP = { textAlign: 'right', margin: 0 }
class WS1001034_SelectBreakdown extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '内訳選択';

    this.state = {
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      listID: [],
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      IssuedFlag: false,
      changeData: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = () => {
    let params = {
      GissueStartDate: this.props.searchCriteria.GissueStartDate,
      GissueEndDate: this.props.searchCriteria.GissueEndDate,
      office_code: this.props.recordData.office_cd,
      branch_store_code: this.props.recordData.branch_store_cd,
    }

    this.setState({ dataSource: [], rowSelect: {} });
    getDataSelectBreakdownAction(params)
      .then(res => {
        if (res?.data) {
          let data = res.data;
          let arrID = [];
          if (this.props.selectIdList.length > 0) {
            this.props.selectIdList.forEach(element => {
              data.forEach((data, index) => {
                if (element === data.id) {
                  arrID.push(element)
                }
              })
            });
          } else if (data.length > 0) {
            data.forEach(element => {
              arrID.push(element.id)
            });
          }

          this.setState({
            dataSource: data,
            listID: arrID,
            selectedRowKeys: arrID,
            selectedRows: data,
          });
        }
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  save = () => {
    let params = {
      [this.props.recordData.id]: this.state.selectedRowKeys.length,
    }

    let selectIdList = this.state.selectedRowKeys
    this.props.onFinishScreen({
      output: params,
      selectIdList: selectIdList,
      office_code: this.props.searchCriteria.Office
    })
  }


  render() {
    const formatDate = 'YYYY/MM/DD';
    return (
      <div className="select-breakdown">
        <Card title="内訳選択">
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            scroll={{ y: 450 }}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({ onClick: event => this.setState({ rowSelect: record }) })}
            rowSelection={{
              selectedRowKeys: this.state.selectedRowKeys,
              onSelect: (record) => {
                let arrTemp = [...this.state.selectedRowKeys];
                let arrTempRecord = [...this.state.selectedRows];
                let idx = arrTemp.indexOf(record.id);
                if (idx === -1) {
                  arrTemp.push(record.id);
                  arrTempRecord.push(record)
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord,
                    selectedRowOnly: arrTempRecord,
                  });
                } else {
                  arrTemp.splice(idx, 1);
                  arrTempRecord.splice(idx, 1);
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord,
                    selectedRowOnly: arrTempRecord,
                  });
                }
              },

              onSelectAll: (selected) => {
                if (selected) {
                  let arrTemp = this.state.dataSource.map(item => item.id);
                  let arrTempRecord = this.state.dataSource;
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord,
                    selectedRowOnly: arrTempRecord,
                  });
                } else {
                  this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                    selectedRowOnly: [],
                  });
                }
              }
            }}
          >
            <Table.Column
              title="受 診 日"
              dataIndex="visit_date_on"
              width={100}
              render={(text, record) => text ? moment(text).format(formatDate) : null}
            />

            <Table.Column
              title="受付ＮＯ"
              dataIndex="receipt_number"
              width={80}
              render={(text, record) => (<p style={styleTagP}>{text}</p>)}
            />

            <Table.Column
              title="カナ氏名"
              dataIndex="kana_name"
            />

            <Table.Column
              title="漢字氏名"
              dataIndex="kanji_name"
            />

            <Table.Column
              title="ＩＤコード"
              dataIndex="personal_number_id"
              width={80}
              render={(text, record) => (<p style={styleTagP}>{text}</p>)}
            />

            <Table.Column
              title="ｺｰｽ"
              dataIndex="visit_course"
              width={60}
            />

            <Table.Column
              title="コース名称"
              dataIndex="contract_short_name"
            />

            <Table.Column
              title="予約事業所"
              dataIndex="office_code"
              width={80}
              render={(text, record) => (<p style={styleTagP}>{text}</p>)}
            />

            <Table.Column
              title="事業所名"
              dataIndex="office_kanji_name"
            />
          </Table>

          <div className="box_button_bottom_right">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={() =>
                this.save()
              }
            >
              保存
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1001034_SelectBreakdown);
