import React from "react";
import { connect } from "react-redux";
import { Card, Form, Radio, Button } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import PaymentProcessSubAction from "redux/CounterBusiness/Counter/PaymentProcessSub.action";

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '70px' } }
}

class WS2623002_PayTypeChangeSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      PayType: this.props.DepositAndWithdrawalForm
    })


  }

  ChangePayType = () => {
    let params = {
      PayType: this.formRef.current?.getFieldValue('PayType'),
      ReserveNum: this.props.ReserveNum,
      identify: this.props.identify
    }

    console.log(params);
    PaymentProcessSubAction.ChangePayType(params)
      .then((res) => {
        if (this.props.onFinish) {
          this.props.onFinish()
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className="receipt-process-sub-mock">
        <Card title="入金区分変更">
          <Form ref={this.formRef} >
            <Form.Item
              name='PayType'
              label='入金区分'
              {...labelCol}
            >
              <Radio.Group
                style={{ display: 'flex', marginTop: '5px', marginLeft: '10px' }}
                className="box_inner_vertical"
              >
                <Radio key='1' value={1}>現金</Radio>
                <Radio key='2' value={2}>クレジット</Radio>

              </Radio.Group>
            </Form.Item>

            <div className="box_inner_horizontal">
              <div className="box_button_bottom_right" style={{ paddingTop: 10 }}>
                <Button
                  type="primary"
                  onClick={() => { this.ChangePayType() }}
                >
                  確定
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2623002_PayTypeChangeSub);
