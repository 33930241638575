import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Space, Button, Form, InputNumber, Input, Modal } from 'antd'
import Color from 'constants/Color'
import { DeleteOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import WS1873003_SiteInquiry from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1873003_SiteInquiry.jsx'
import WS1873002_SiteClassifyInquiry from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1873002_SiteClassifyInquiry.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusApiRoutesV2 from 'redux/VenusApiRoutesV2/VenusApiRoutesV2'
import axios from 'axios'
import Checkbox from 'antd/lib/checkbox/Checkbox'

// const dataSource = [{
//   id: 1,
//   site_code: '5',
//   site_name: '30',
//   findings_classification_i: 10,
//   site_classification_io: 10,
//   display_order: 1,
//   OutputChar: '60'
// },
// {
//   id: 2,
//   site_code: '6',
//   site_name: '70',
//   findings_classification_i: 10,
//   site_classification_io: 10,
//   display_order: 1,
//   OutputChar: '30'
// },
// ]
const dataSource = []
class WS1874001_RadiographyFindingsMaintain extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_Division: PropTypes.any,
    Li_InspectClassifyCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = '読影所見保守'

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      //selectedRowKeys: [dataSource[0].id],
      tableIndex: 0,
      count: 1001,
      dataSource: [],
    }
  }
  // setFormFieldValue(namePath, value) {
  //   this.formRef?.current?.setFields([
  //     {
  //       name: namePath,
  //       value,
  //     },
  //   ])
  // }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      //this.setFormFieldValue('dataSource', dataSource)
    }
  }
  componentDidMount() {
    //this.setFormFieldValue('dataSource', dataSource)
    this.getInitialValuse()
  }

  // getInitialValuse = () => {
  //   let params = { Li_InspectClassifyCode: 'CT', Li_Division: 1 }
  //   axios.get('/api/v2/radiography-inspect-maintain/radiography-findings-maintain/index', { params })
  //     .then(res => {
  //       console.log('res=>', res)
  //       this.setState({
  //         dataSource: res ? res.data : []
  //       })
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }

  getInitialValuse = () => {
    this.setState({
      isLoadingTable: true
    })
    let params = {
      Li_InspectClassifyCode: 'CT',
      Li_Division: 1
    }

    VenusApiRoutesV2.callApi('API001874001002', params)
      .then(res => {
        console.log('res->', res)
        this.setState({
          dataSource: res ? res : [],
        })

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }

  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name)
  }

  showWS1873003_SiteInquiry(record) {
    const { childModal } = this.state
    this.setState({
      childModal: {
        ...childModal,
        visible: true,
        width: 900,
        component: (<WS1873003_SiteInquiry
          selectRowData={record}
          onFinishScreen={(data) => {
            this.setState({
              childModal: {
                ...childModal,
                visible: false,
              },
            })
          }}
        />),
      },
    })
  }
  showWS1873002_SiteClassifyInquiry(record) {
    if (this.props?.Li_Division == 2) {
      const { childModal } = this.state
      this.setState({
        childModal: {
          ...childModal,
          visible: true,
          width: 900,
          component: (<WS1873002_SiteClassifyInquiry
            Li_InspectClassifyCode={this.props?.Li_InspectClassifyCode}
            Li_SiteClassifyCode={record?.site_classification_io}
            onFinishScreen={(data) => {
              this.setState({
                childModal: {
                  ...childModal,
                  visible: false,
                },
              })
            }}
          />),
        },
      })
    }

  }
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }
  handleAdd() {
    const { count } = this.state
    let data = this.getRawValue('dataSource') ?
      this.getRawValue('dataSource') : []
    const params = {
      id: count,
      isNew: true,
      site_code: '0',
      site_name: '',
      findings_classification_i: '',
      site_classification_io: '',
      display_order: '',
      OutputChar: ''
    }
    let arr = [...data]
    arr.unshift(params)
    this.forceUpdate()
    this.setFormFieldValue('dataSource', arr)
    this.setState({
      count: count + 1,
      selectedRowKeys: arr.length > 0 ? [arr[0].id] : [],
      selectedRows: arr.length > 1 ? [arr[0]] : [],
      tableIndex: 0
    })
  }
  addRow() {
    let data = this.getRawValue('dataSource') ?
      this.getRawValue('dataSource') : []
    let arr = [...data]
    if (arr.length === 0) {
      this.handleAdd()
    } else {
      for (let index = 0; index < arr.length; index++) {
        if (arr[index]['isNew']) {
          return
        }
        if (index === arr.length - 1) {
          this.handleAdd()
        }
      }
    }
  }
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }
  removeRow(index) {
    let data = this.getRawValue('dataSource') ?
      this.getRawValue('dataSource') : []
    const table = data.filter((item, i) => index !== i)
    setTimeout(() => {
      this.setState({
        selectedRows: table.length > 0 ? [table[0]] : [],
        selectedRowKeys: table.length > 0 ? [table[0]?.id] : [],
        tableIndex: 0
      })
    }, 300)
    this.setFormFieldValue('dataSource', table)
    this.forceUpdate()
  }
  deleteData(id) {
    //api => param @Id
  }
  onSave(index) {
    // click event save data
    const record = this.getRawValue('dataSource')[index]
    record.isNew ?
      this.insertData(record, index) : this.SaveRowData(record, false)
  }
  insertData(record, index) {
    // save data form FE
    const value = this.getRawValue('dataSource')[index]
    const isNew = true
    this.SaveRowData(value, isNew)
  }
  SaveRowData(record, isNew) {
    // api
    // isNew => điều kiện check Thêm mới -ko truyền id # Update - Truyền id
  }
  onFinish = (values) => { }

  // onChange_check = (e) => {
  //   console.log('checked = ', checkedValues)
  //   // setCheckedList (e.target.checked ? plainOptions : [])
  // }

  render() {
    //  let data = this.formRef?.current?.getFieldValue('dataSource')
    //const { isLoading, childModal } = this.state
    return (
      <div className='radiography-findings-maintain'>
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Card className='mb-2' title='読影所見保守'>
            <Space>

            </Space>
            <hr style={{ border: '1px solid #F0F0F0', marginBottom: '1.2rem' }} />
            <Table bordered
              //dataSource={[]}
              //rowClassName={(record, index) => record.id === this.state.selectedRows[0]?.id ? 'table-row-light' : ''}
              size='small'
              scroll={{ y: 600 }}
              dataSource={this.state.dataSource}
              //loading={false}
              //pagination={false}
              rowKey={(record) => record.id}
              onRow={(record, index) => {
                return {
                  onClick: () => {
                    const selected = record
                    this.setState({
                      selectedRowKeys: [selected.id],
                      selectedRows: [selected],
                      tableIndex: index,
                    })
                  },
                }
              }}
            >
              <Table.Column
                style={{ textAlign: 'right' }}
                width={100} title='所見ｺｰﾄﾞ'
                dataIndex='findings_code'
                sorter={(a, b) => a.findings_code - b.findings_code}
              // render={(item, record, index) => {
              //   return (
              //     <Form.Item name={['dataSource', index, 'site_code']}>
              //       <InputNumber />
              //     </Form.Item>
              //   )
              // }}
              />
              <Table.Column
                width={300}
                title='所見名称'
                dataIndex='findings_name'
                sorter={(a, b) => a.findings_name - b.findings_name}
              />
              <Table.Column
                width={60}
                style={{ alignSelf: 'center' }}
                title='判定'
                dataIndex='judgement'
                sorter={(a, b) => a.judgement - b.judgement} />
              <Table.Column
                width={120}
                title={'所見分類1'}
                dataIndex='site_classification_o1'
                sorter={(a, b) => a.site_classification_o1 - b.site_classification_o1}
              />
              <Table.Column
                width={120}
                title={'所見分類2'}
                dataIndex='site_classification_o2'
                sorter={(a, b) => a.site_classification_o2 - b.site_classification_o2}
              />
              <Table.Column
                width={120}
                title={'所見分類3'}
                dataIndex='findings_classification_io'
                sorter={(a, b) => a.findings_classification_io - b.findings_classification_io}
              />
              <Table.Column style={{ textAlign: 'center' }} width={60} title={'正常'} dataIndex='normal_value_flag'
                sorter={(a, b) => a.normal_value_flag - b.normal_value_flag}
                render={(item, record, index) => {
                  return (
                    <Checkbox key={record.normal_value_flag} defaultChecked={index} dataIndex='normal_value_flag'

                    ></Checkbox>
                  )
                }}
              />
              <Table.Column width={100} style={{ textAlign: 'right' }} title='表示順' dataIndex='display_order'
                sorter={(a, b) => a.display_order - b.display_order}
              />
              <Table.Column width={200} title='出力文字' dataIndex='OutputChar' />
              <Table.Column
                render={(item, record, index) => {
                  return (
                    <Button type='primary' onClick={() => { this.showWS1873003_SiteInquiry(record) }}>
                      部位取り込み
                    </Button>
                  )
                }}
              // width={100}
              // dataIndex=''
              // style={{ textAlign: 'center' }}
              // title={(item, record, index) => (
              //   <Button
              //     onClick={() => this.addRow()}
              //     type='primary'
              //     icon={(<PlusOutlined />)}></Button>
              // )}
              // render={(item, record, index) => (
              //   <div style={{ textAlign: 'center' }}>
              //     <Button hidden={this.state.tableIndex !== this.findIndexByID(dataSource, record.id)}
              //       style={{ marginLeft: '5px', color: 'green' }} onClick={() =>
              //         this.onSave(index)} icon={(< SaveOutlined />)}></Button>
              //     <Button hidden={this.state.tableIndex !== this.findIndexByID(dataSource, record.id)}
              //       danger onClick={() => record?.isNew ? this.removeRow(index) : this.deleteData(record.id)} icon={(< DeleteOutlined />)}></Button>
              //   </div>
              // )}
              />
            </Table>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1874001_RadiographyFindingsMaintain)
