import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Form, Input, Card, Tabs, Table, Button, Checkbox, Dropdown, Menu, Modal, Radio, Tooltip } from 'antd'
import { MenuOutlined, MoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import PassingControlInspectsDisplayAction from 'redux/Others/PassingControlInspectsDisplay/PassingControlInspectsDisplay.action'
import WS3059005_TerminalList from 'pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059005_TerminalList.jsx'
import WS3059008_PassingList from 'pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059008_PassingList.jsx'
import WS3061500_PassingControlInspectsDisplayExclusion from 'pages/ZZ_Others/V5IS01010_PassingControlInspectsDisplay/WS3061500_PassingControlInspectsDisplayExclusion'
import ResultDisplay from "pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx"
import changeHankakuText from 'components/Commons/changeHankakuText'

const labelCol = {
  labelCol: { style: { marginBottom: 0, width: 70 } }
}

class WS3061002_PassingControlInspectsDisplay2 extends React.Component {
  static propTypes = {
    Li_ImplementDate: PropTypes.any,
    Li_PassNum: PropTypes.any,
    Li_TerminalNum: PropTypes.any,
    Li_MenuOption: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '通過管理検査表示'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      tabNum: 0,

      tabList: [],

      passingDataList: [],
      implementDateChar: '',
      passingNumList: [],
      passingNameList: [],

      dataSource: [],
      PassNum: '',
      TerminalNum: '',
      more: false,
      stateUpdate: false,
      selectedId: '',
      barcodeFlag: false,
      urlPassing: false,
      ResultDisplayUpdateBtn: true,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.prefix()
  }

  /**
   * URLの変更時処理
   * @param {*} prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.prefix()
    }
  }

  /**
   * URLの変更時処理（詳細）
   */
  prefix = () => {
    let urlNum = this.props.match.params.num ?? '' //URLの端末番号
    let urlDate = this.props.match.params.date ?? '' //URLの受診日
    let urlPassing = this.props.match.params.passingFalg ?? '' //通過項目かどうか

    // 通過項目切り替えフラグ
    if (urlPassing === 'true') {
      this.setState({
        urlPassing: true
      })
    }

    let barcodeFlag = false
    if (urlDate == 'barcode') {
      //バーコードで端末番号（検査）を切り替えている場合
      barcodeFlag = true
    }

    if (!barcodeFlag) {
      // this.formRefを初期化
      this.formRef.current.resetFields()
    } else {
      // 担当者の固定
      this.formRef.current.setFieldsValue({
        StsInspector: true,
        StsAssistance: true
      })
    }

    let params
    if (this.props.ImplementDateChar && !barcodeFlag || urlPassing === 'true') {
      // 通過管理進捗状況からのモーダル表示の場合
      let date = moment(urlDate).format('YYYY/MM/DD')
      this.setState({ implementDateChar: date })
      this.formRef.current.setFieldsValue({
        TerminalNum: this.props.terminal_num ?? '',
        PassNum: this.props.passing_number ?? urlNum,
        DateFlag: 2,
        DateChar: date,
      })
      params = {
        Li_ImplementDate: date,
        Li_PassNum: this.props.passing_number ?? urlNum,
        Li_TerminalNum: this.props.terminal_num ?? '',
        Li_MenuOption: '',
      }
      // データ取得
      this.index(params)

    } else if ((urlDate === '' && urlNum !== '' && !barcodeFlag) || barcodeFlag === 'true') {
      // バーコードで端末番号（検査）を切り替えている場合 →　 path='/passing-control-inspects-display/passing-control-inspects-display/:num/barcode'
      // または
      // URLから直接表示された場合 → path='/passing-control-inspects-display/passing-control-inspects-display/:num/'
      // 端末番号のみ（受診日は 本日）
      let date = moment(new Date()).format('YYYY/MM/DD')
      this.formRef.current.setFieldsValue({
        TerminalNum: urlNum,
        DateFlag: 1,
        DateChar: date,
      })
      this.setState({ implementDateChar: date })
      params = {
        Li_ImplementDate: date,
        Li_PassNum: '',
        Li_TerminalNum: urlNum,
        Li_MenuOption: '',
      }
      // データ取得
      this.index(params)

    } else if (urlDate !== '' && !barcodeFlag) {
      // URLから直接表示された場合
      // 端末番号と受診日：指定有り
      // path='/passing-control-inspects-display/passing-control-inspects-display/:num/:date'
      let date = moment(urlDate).format('YYYY/MM/DD')
      this.formRef.current.setFieldsValue({
        TerminalNum: urlNum,
        DateFlag: 2,
        DateChar: date,
      })
      this.setState({ implementDateChar: date })
      params = {
        Li_ImplementDate: moment(date).format('YYYY/MM/DD'),
        Li_PassNum: '',
        Li_TerminalNum: urlNum,
        Li_MenuOption: '',
      }
      // データ取得
      this.index(params)
    }
  }

  /**
   * データ取得
   * @param {*} params
   */
  index(params) {
    let tabListNameArr = []
    PassingControlInspectsDisplayAction.index(params)
      .then((res) => {
        if (res) {
          const passNum = res.PassNum
          const terminalNum = res.TerminalNum
          const implementDateChar = moment(res.ImplementDate)?.format('YYYY/MM/DD')
          const more = res.StsMore ?? 0
          const stateUpdate = res.StateUpdate ?? 0

          this.formRef.current?.setFieldsValue({
            PersonalNum: res.PersonalNum ?? '',
            StsMore: more,
            StateUpdate: stateUpdate,
            TerminalName: res.TerminalName ?? '',
            PassName: res.PassName ?? '',
          })

          // 通過項目を配列化
          tabListNameArr = res.PassingNameList?.split(',')

          this.setState({
            tabList: tabListNameArr,
            passingNumList: res.PassingNumList?.split(','),
            passingNameList: res.PassingNameList?.split(','),
            PassNum: passNum,
            TerminalNum: terminalNum,
            more: more,
            stateUpdate: stateUpdate,
            implementDateChar: implementDateChar
          })
          // テーブルのデータ取得
          this.getStatusList(implementDateChar, passNum, terminalNum, more)

          // フォーカスを担当者バーコードに移動
          this.formRef.current?.getFieldInstance('InspectorId').focus()
        }
      })
  }

  /**
   * テーブルのデータ取得
   * @param {*} implementDateChar
   * @param {*} passNum
   * @param {*} terminalNum
   * @param {*} more
   */
  getStatusList(implementDateChar, passNum, terminalNum, more, personal_number_id = null) {
    let params = {
      Li_ImplementDate: implementDateChar,
      Li_PassNum: passNum,
      Li_TerminalNum: terminalNum,
      Li_MenuOption: '',
      Li_More: (more) ? 1 : 0,
    }
    PassingControlInspectsDisplayAction.getStatusList(params)
      .then((res) => {
        if (res) {
          let length = res.length
          let obj
          for (let i = 0; i < length; i++) {
            obj = res[i]
            if (personal_number_id == obj.personal_number_id) {
              this.formRef.current?.setFieldsValue({
                carried_out_order: obj.carried_out_order,
                personal_number_id: obj.personal_number_id,
                kana_name: obj.kana_name,
                kanji_name: obj.kanji_name,
                Gender: obj.Gender,
                Age: obj.Age,
                State: obj.State
              })

              this.setState({ selectedId: obj.id })
            }
          }

          this.setState({ dataSource: res })

          // 受診者をリセット
          this.formRef.current?.setFieldsValue({ PersonalNum: '' })

          // 診察介助担当者の固定フラグは外れている場合
          if (!(this.formRef.current?.getFieldValue('StsAssistance') ?? false)) {
            // 診察介助担当者をリセット
            this.formRef.current?.setFieldsValue({
              AssistanceId: '',
              AssistanceName: ''
            })
          }

          // 検査担当者の固定フラグは外れている場合
          if (!(this.formRef.current?.getFieldValue('StsInspector') ?? false)) {
            // 担当者をリセット
            this.formRef.current?.setFieldsValue({
              InspectorId: '',
              InspectorName: ''
            })
            // フォーカスを担当者バーコードに移動
            this.formRef.current?.getFieldInstance('InspectorId').focus()
          } else {
            // フォーカスを受診者バーコードに移動
            this.formRef.current?.getFieldInstance('PersonalNum').focus()
          }

          this.forceUpdate()
        }
      })
  }

  /**
   * 端末項目 モーダル
   */
  showWS3059005_TerminalList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS3059005_TerminalList
            selectFlag={true}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                TerminalNum: output.terminal_num ?? '',
                TerminalName: output.terminal_informal_name ?? '',
              })
              this.forceUpdate()
              this.closeModal()

              // URL変更
              this.changeUrl()
            }}
          />
        ),
      }
    })
  }

  /**
   * 通過項目 モーダル
   */
  showWS3059008_PassingList = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS3059008_PassingList
            selectFlag={true}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                PassNum: output.passing_number ?? '',
                PassName: output.passing_informal_name ?? '',
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * URLの変更処理
   */
  changeUrl = () => {
    // 端末番号
    let terminalNum = this.formRef.current.getFieldValue('TerminalNum')
    // 受診日フラグ
    let dateFlag = this.formRef.current.getFieldValue('DateFlag')
    // 指定日
    let date = this.formRef.current.getFieldValue('DateChar')
    let url = ''

    if (this.state.barcodeFlag) {
      // バーコードで端末項目（検査）を限かる場合、受診日フラグは(1)「受診日：本日」とする
      dateFlag = 1
    }
    // 端末番号や受診日フラグがせってされていない場合、return
    if (!terminalNum) return
    if (!dateFlag) return

    // 必要な部分のURLだけ取得
    const [url0, url1, url2] = this.props.location.pathname.split('/')

    if (dateFlag === 1) {
      // 受診日：本日
      // 例）/passing-control-inspects-display/passing-control-inspects-display/:num
      url = '/' + url1 + '/' + url2 + '/' + String(terminalNum)

      if (this.state.barcodeFlag) {
        // バーコードで切替 バーコードがあることをURLに反映
        // 例）/passing-control-inspects-display/passing-control-inspects-display/:num/:date/barcode
        // url = url + '/' + moment(new Date()).format('YYYYMMDD') + '/' + 'barcode'
      }
    } else if (dateFlag === 2) {
      if (!date) return
      // 受診日：指定
      // 例）/passing-control-inspects-display/passing-control-inspects-display/:num/:date
      url = '/' + url1 + '/' + url2 + '/' + String(terminalNum) + '/' + moment(date).format('YYYYMMDD')
    }

    // }
    // URL変更 react-router-domライブラリを仕様
    this.props.history.push(url)
  }

  /**
   * 担当者入力・診察介助（バーコード）
   */
  changeInspectorId = (event, target) => {
    const userId = changeHankakuText(event.target.value)
    const params = {
      userId: userId
    }
    PassingControlInspectsDisplayAction.getUserInfo(params)
      .then((res) => {
        if (res) {
          if (target == 'inspector') {
            // 担当者
            this.formRef.current.setFieldsValue({
              InspectorId: res.username ?? '',
              InspectorName: res.name ?? ''
            })

            // フォーカスを受診者バーコードに移動
            this.formRef.current?.getFieldInstance('AssistanceId').focus()
          } else if (target == 'Assistance') {
            // 診察介助
            this.formRef.current.setFieldsValue({
              AssistanceId: res.username ?? '',
              AssistanceName: res.name ?? ''
            })

            // フォーカスを受診者バーコードに移動
            this.formRef.current?.getFieldInstance('PersonalNum').focus()
          }

          this.forceUpdate()
        }
      })
  }

  /**
   * 受付済み
   * @param {*} personal_number_id
   */
  acceptedState(personal_number_id) {
    let param = {
      Li_PersonalNum: personal_number_id,
      Li_ImplementDate: this.state.implementDateChar,
      Li_PassNum: this.state.PassNum,
      Li_TerminalNum: this.state.TerminalNum,
    }
    PassingControlInspectsDisplayAction.acceptedState(param)
      .then((res) => {
        this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, this.state.more)
      })
  }

  /**
   * 実施済み
   * @param {*} personal_number_id
   */
  carriedOutState(personal_number_id) {
    let param = {
      Li_PersonalNum: personal_number_id,
      Li_ImplementDate: this.state.implementDateChar,
      Li_PassNum: this.state.PassNum,
      Li_TerminalNum: this.state.TerminalNum,
    }
    PassingControlInspectsDisplayAction.carriedOutState(param)
      .then((res) => {
        this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, this.state.more)
      })
  }

  /**
   * キャンセル
   * @param {*} personal_number_id
   */
  cancel(personal_number_id) {
    let param = {
      Li_PersonalNum: personal_number_id,
      Li_ImplementDate: this.state.implementDateChar,
      Li_PassNum: this.state.PassNum,
      Li_TerminalNum: this.state.TerminalNum,
    }
    PassingControlInspectsDisplayAction.cancel(param)
      .then((res) => {
        this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, this.state.more)
      })
  }

  /**
   * 測定不可
   * @param {*} personal_number_id
   */
  unmeasurable(personal_number_id, comment) {
    let param = {
      Li_PersonalNum: personal_number_id,
      Li_ImplementDate: this.state.implementDateChar,
      Li_PassNum: this.state.PassNum,
      Li_TerminalNum: this.state.TerminalNum,
      comment: comment
    }
    PassingControlInspectsDisplayAction.unmeasurable(param)
      .then((res) => {
        this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, this.state.more)
      })
  }

  /**
   * 受診者：バーコードの入力処理、受診者の検査受付状態を変更
   * @param {*} event
   */
  changeStateUpdate(event) {
    let personal_number_id = event.target.value
    let param = {
      Li_StateUpdate: (this.state.stateUpdate) ? 1 : 0,
      Li_PersonalNum: personal_number_id,
      Li_ImplementDate: this.state.implementDateChar,
      Li_PassNum: this.state.PassNum,
      Li_TerminalNum: this.state.TerminalNum,
      Li_InspectorId: this.formRef.current.getFieldValue('InspectorId'),
      Li_AssistanceId: this.formRef.current.getFieldValue('AssistanceId')
    }
    PassingControlInspectsDisplayAction.changeStateUpdate(param)
      .then((res) => {
        this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, this.state.more, personal_number_id)
      })
  }

  /**
   * 更新 チェックボックス
   * @param {*} event
   */
  changeUpdate(event) {
    const bool = event.target.checked
    this.setState({ stateUpdate: bool })
  }

  /**
   * 全表示 チェックボックス
   * @param {*} event
   */
  changeMore(event) {
    const bool = event.target.checked
    this.setState({ more: bool })
    this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, bool)
  }

  /**
   * タブ選択を変更
   * @param {*} activeKey
   */
  changeTab(activeKey) {
    this.setState({ tabNum: activeKey, PassNum: this.state.passingNumList[activeKey] })
    this.getStatusList(this.state.implementDateChar, this.state.passingNumList[activeKey], this.state.TerminalNum, this.state.more)
  }

  /**
   * 除外理由保存
   * @param {*} output
   * @param {*} record
   */
  exclusionSave(output, record) {
    let params = {
      implement_date_on: record.Li_ImplementDate,
      personal_number_id: record.personal_number_id,
      passing_number: record.Li_PassNum,
      comment: output?.comment
    }

    PassingControlInspectsDisplayAction.exclusionSave(params)
      .then((res) => {
        this.getStatusList(this.state.implementDateChar, this.state.PassNum, this.state.TerminalNum, this.state.more)
      })
  }

  /**
    * 総合結果表示/入力 モーダル
    * @param {*} record
    */
  showResultDisplay = (record, index) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.reservation_number}
            protectFlag={record.protectFlag}
            provisionalFlag={record.provisionalFlag}
            onUpdate={() => {
              this.index()
            }}
            // 結果入力画面での保存ボタンの状態
            onFlagUpdate={(bool) => {
              this.setState({ ResultDisplayUpdateBtn: bool })
            }}
          />
        ),
      },
    })
  }


  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  resetFields = () => {
    // 必要な部分のURLだけ取得
    const [url0, url1, url2] = this.props.location.pathname.split('/')

    let url = '/' + url1 + '/' + url2 + '/'

    // URL変更 react-router-domライブラリを使用
    this.props.history.push(url)
  }

  /**
   * タブのレイアウト設定
   * @param {*} tabNum
   * @returns
   */
  renderBodyTab = (tabNum) => {
    return (
      <div>
        <Table
          bordered
          dataSource={this.state.dataSource}
          pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
          size='small'
          scroll={{ y: 450 }}
          rowClassName={(record, index) => record.id === this.state.selectedId ? 'table-row-dock-tentative'
            : record.carried_out_order === 0 ? 'table-row-no-accepted'
              : ''}
          rowKey={(record) => record.id}
          key={'table' + tabNum}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: () => {
                // 総合結果表示/入力 モーダル
                this.showResultDisplay(record, rowIndex)
              }
            }
          }}
        >
          <Table.Column
            title='受付No'
            dataIndex='carried_out_order'
            width={100}
            render={(value) => {
              return (
                <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value}</div>
              )
            }}
          />

          <Table.Column
            title='個人番号'
            dataIndex='personal_number_id'
            width={120}
            render={(value) => {
              return (
                <div style={{ textAlign: 'right' }}>{value}</div>
              )
            }}
          />

          <Table.Column
            title='カナ氏名'
            dataIndex='kana_name'
          />

          <Table.Column
            title='漢字氏名'
            dataIndex='kanji_name'
          />

          <Table.Column
            title='性別'
            dataIndex='Gender'
            width={50}
            align='center'
            render={(text) => (<span style={{ color: text === '男性' ? '#0F3278' : '#B41432' }}>{text}</span>)}
          />

          <Table.Column
            title='年齢'
            dataIndex='Age'
            width={50}
            render={(value) => {
              return (
                <div style={{ textAlign: 'right' }}>{value}</div>
              )
            }}
          />

          <Table.Column
            title='状態'
            dataIndex='State'
            width={50}
            align='center'
            render={(value, record) => {
              let tooltip = ''
              switch (value) {
                case '○':
                  tooltip = '受付済み'
                  break
                case '●':
                  tooltip = '実施済み'
                  break
                case '未':
                  tooltip = '未実施'
                  break
                case '×':
                  tooltip = '測定不可'
                  break
                default:
              }
              if (record.comment !== '') {
                tooltip = tooltip + '\r\n' + record.comment
              }
              return (
                <Tooltip
                  overlayStyle={{ whiteSpace: 'pre-line' }}
                  title={tooltip}>
                  <div style={{ textAlign: 'center' }}>{value}</div>
                </Tooltip>
              )
            }}
          />

          <Table.Column
            key='action'
            width={40}
            render={(row, record) => {
              return (
                <Dropdown
                  trigger='click'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='menu-1'
                        onClick={() => {
                          this.acceptedState(record.personal_number_id)
                        }}
                      >
                        受付済み
                      </Menu.Item>

                      <Menu.Item
                        key='menu-2'
                        onClick={() => {
                          this.carriedOutState(record.personal_number_id)
                        }}
                      >
                        実施済み
                      </Menu.Item>

                      <Menu.Item
                        key='menu-3'
                        onClick={() => {
                          this.cancel(record.personal_number_id)
                        }}
                      >
                        クリア
                      </Menu.Item>

                      <Menu.Item
                        key='menu-4'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <WS3061500_PassingControlInspectsDisplayExclusion
                                  flag={4}
                                  acceptingState={record.acceptingState}
                                  comment={record.comment}
                                  onFinishScreen={(output) => {
                                    this.unmeasurable(record.personal_number_id, output.comment)
                                    this.closeModal()
                                  }}
                                />
                              ),
                            }
                          })
                        }}
                      >
                        測定不可
                      </Menu.Item>

                      <Menu.Item
                        key='menu-5'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <WS3061500_PassingControlInspectsDisplayExclusion
                                  flag={5}
                                  acceptingState={record.acceptingState}
                                  comment={record.comment}
                                  onFinishScreen={(output) => {
                                    this.exclusionSave(output, record)
                                    this.closeModal()
                                  }}
                                />
                              ),
                            }
                          })
                        }}
                      >
                        集計除外
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Button
                    icon={<MoreOutlined />}
                    size='small' />
                </Dropdown>
              )
            }
            } />
        </Table >
      </div >
    )
  }

  render() {
    return (
      <div className='passing-control-inspects-display'>
        <Card title={'通過管理検査表示 診察介助 ' + (this.state.implementDateChar ? ('[' + this.state.implementDateChar + ']') : '')}>
          <Dropdown
            trigger='click'
            size='small'
            overlay={() => (
              <Menu>
                <Menu.Item
                  key='通過管理進捗状況'
                  onClick={() => { window.open(location.protocol + '//' + location.host + '/passing-manage-progress/passing-manage-progress') }}
                >
                  通過管理進捗状況
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              className='option_btn_right_top'
              size='small'
              icon={<MenuOutlined />}
            />
          </Dropdown>


          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <div className='box_inner_horizontal' >
                {this.props.match.params.passingFalg !== 'false' && this.state.urlPassing ?
                  // 通過番号が指定されいる場合
                  <div
                    className='box_inner_horizontal'
                    style={{
                      width: 'fit-content',
                      marginRight: 20
                    }}>
                    <Form.Item
                      name='PassNum'
                      label='通過番号'
                    >
                      <Input.Search
                        className='input-search-size-single-byte-8'
                        onSearch={() => {
                          // 通過項目 モーダル
                          this.showWS3059008_PassingList()
                        }}
                      />
                    </Form.Item>
                    <div style={{ marginTop: 5 }}>
                      {this.formRef.current?.getFieldValue('PassName') ?? ''}
                    </div>
                  </div>
                  :
                  // 端末番号が指定されいる場合
                  <div
                    className='box_inner_horizontal'
                    style={{
                      width: 'fit-content',
                      marginRight: 20
                    }}>
                    <Form.Item
                      name='TerminalNum'
                      label='端末番号'
                    >
                      <Input.Search
                        className='input-search-size-single-byte-8'
                        type='number'
                        onChange={(event) => {
                          let bool = false
                          if (event.target.value != '') {
                            bool = true
                          }
                          this.setState({ barcodeFlag: bool })
                        }}
                        onSearch={() => {
                          // 端末項目 モーダル
                          this.showWS3059005_TerminalList()
                        }}
                        onPressEnter={() => {
                          if (this.props.match.params.num === undefined && this.props.match.params.date === undefined) {
                            this.formRef.current?.setFieldsValue({
                              DateFlag: 1
                            })
                            this.changeUrl()
                          }
                        }}
                      />
                    </Form.Item>
                    <div style={{ marginTop: 5 }}>
                      {this.formRef.current?.getFieldValue('TerminalName') ?? ''}
                    </div>
                  </div>
                }
                <div className='box_inner_horizontal' style={{ width: 'fit-content', gap: 0 }}>
                  <Form.Item
                    name='DateFlag'
                    label='受診日'>
                    <Radio.Group
                      onChange={() => {
                        this.forceUpdate()
                        // URL変更
                        this.changeUrl()
                      }}
                    >
                      <Radio value={1}>本日</Radio>
                      <Radio value={2}>指定日</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name='DateChar'
                    label=''
                    hidden={(this.formRef.current?.getFieldValue('DateFlag') ?? 0) !== 2}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      format={'YYYY/MM/DD'}
                      onChange={() =>
                        // URL変更
                        this.changeUrl()
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <Button
                    onClick={() => this.resetFields()}
                  >
                    {'クリア'}
                  </Button>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <div
                  className='box_title_border'
                  style={{
                    display: (this.state.passingNameList.length === 0) ? 'none' : '',
                    width: 'fit-content'
                  }}
                >
                  <span className='box-title'>バーコード</span>
                  <div className='box_inner_vertical'>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='InspectorId'
                        label='担当者'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input
                          id='inspectorId'
                          onPressEnter={(event) => this.changeInspectorId(event, 'inspector')}
                        />
                      </Form.Item>

                      <div style={{ marginTop: 5 }}>
                        {this.formRef.current?.getFieldValue('InspectorName') ?? ''}
                      </div>

                      <Form.Item
                        name='StsInspector'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          disabled={((this.formRef.current?.getFieldValue('InspectorName') ?? '') === '')}
                          onChange={() => this.forceUpdate()}
                        >
                          固定
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='AssistanceId'
                        label='診察介助者'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input
                          id='AssistanceId'
                          onPressEnter={(event) => this.changeInspectorId(event, 'Assistance')}
                        />
                      </Form.Item>

                      <div style={{ marginTop: 5 }}>
                        {this.formRef.current?.getFieldValue('AssistanceName') ?? ''}
                      </div>

                      <Form.Item
                        name='StsAssistance'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          disabled={((this.formRef.current?.getFieldValue('AssistanceName') ?? '') === '')}
                          onChange={() => this.forceUpdate()}
                        >
                          固定
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='PersonalNum'
                        label='受診者'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input
                          disabled={((this.formRef.current?.getFieldValue('InspectorName') ?? '') === '')}
                          type='number'
                          onPressEnter={(event) => this.changeStateUpdate(event)}
                        />
                      </Form.Item>

                      <Form.Item
                        name='StateUpdate'
                        valuePropName='checked'
                        style={{ marginBottom: 0, marginLeft: 10 }}
                      >
                        <Checkbox
                          onChange={(event) => this.changeUpdate(event)}>
                          更新
                        </Checkbox>
                      </Form.Item>

                      <Form.Item
                        name='StsMore'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          onChange={(event) => this.changeMore(event)}>
                          全表示
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div
                  className='box_title_border'
                  style={{
                    display: (this.state.passingNameList.length === 0) ? 'none' : this.state.selectedId === '' ? 'none' : '',
                    width: 'fit-content'
                  }}
                >
                  <div className='box_inner_horizontal'>
                    <div className='box_inner_vertical'>
                      <Form.Item
                        name='kana_name'
                        label='カナ氏名'
                        style={{ marginBottom: 0 }}
                      >
                        <div className='input-size-40'>{this.formRef.current?.getFieldValue('kana_name')}</div>
                      </Form.Item>

                      <Form.Item
                        name='kanji_name'
                        label='漢字氏名'
                        style={{ marginBottom: 0 }}
                      >
                        <div className='input-size-40'>{this.formRef.current?.getFieldValue('kanji_name')}</div>
                      </Form.Item>
                    </div>

                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal' style={{ gap: '30px' }}>
                        <Form.Item
                          name='carried_out_order'
                          label='受付No'
                          style={{ marginBottom: 0 }}
                        >
                          <div>{this.formRef.current?.getFieldValue('carried_out_order')}</div>
                        </Form.Item>

                        <Form.Item
                          name='personal_number_id'
                          label='個人番号'
                          style={{ marginBottom: 0 }}
                        >
                          <div>{this.formRef.current?.getFieldValue('personal_number_id')}</div>
                        </Form.Item>
                      </div>

                      <div className='box_inner_horizontal' style={{ gap: '30px' }}>
                        <Form.Item
                          name='Gender'
                          label='性別'
                          style={{ marginBottom: 0 }}
                        >
                          <div>{this.formRef.current?.getFieldValue('Gender')}</div>
                        </Form.Item>

                        <Form.Item
                          name='Age'
                          label='年齢'
                          style={{ marginBottom: 0 }}
                        >
                          <div>{this.formRef.current?.getFieldValue('Age')}</div>
                        </Form.Item>

                        <Form.Item
                          name='State'
                          label='状態'
                          style={{ marginBottom: 0 }}
                        >
                          <div>{this.formRef.current?.getFieldValue('State')}</div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <Tabs
              type='card'
              defaultActiveKey={this.state.tabNum}
              onChange={(activeKey) => this.changeTab(activeKey)}
              style={{ display: (this.state.passingNameList.length === 0) ? 'none' : '' }}
            >
              {/* タブを表示 */}
              {this.state.passingNameList.map((item, itemIndex) =>
                <Tabs.TabPane
                  tab={item}
                  key={itemIndex}
                >
                  {this.renderBodyTab(itemIndex)}
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            if (this.state.ResultDisplayUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div >
    )
  }
}

export default withRouter(WS3061002_PassingControlInspectsDisplay2)
