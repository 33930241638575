import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Table, Tooltip, message } from 'antd'
import ModalAdd from 'components/Commons/ModalAdd'
import ModalChange from 'components/Commons/ModalChange'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { MoreOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import OptionSettingAction from 'redux/Others/OptionSetting/OptionSetting.actions.js'

const columns = [
  {
    title: '項目',
    dataIndex: 'item',
    type: 1,
  },
  {
    title: '補足説明',
    dataIndex: 'supplement',
    type: 1,
  },
  {
    title: '制限',
    dataIndex: 'limit',
    type: 1,
  },
]

class OptionSettingEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'オプション内容 編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      disabledSaveBtn: true,
      dataSource: [],
      isLoading: false,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    const params = {
      type_code: this.props.record?.type_code,
      option_code: this.props.record?.option_code,
      id_name: this.props.record?.id_name,
    }

    OptionSettingAction.getOptionList(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res,
            disabledSaveBtn: true
          })
          this.formRef.current?.setFieldsValue({
            tableData: res
          })
        }
      })
  }

  // insert 新規作成
  Insert = (record) => {
    let params = {
      type_code: this.props.record?.type_code,
      option_code: this.props.record?.option_code,
      id_name: this.props.record?.id_name,
    }
    this.setState({ isLoading: true });
    OptionSettingAction.itemSave({ ...params, ...record })
      .then(res => {
        console.log(res)
        this.index()
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  // delete 項目削除
  delete = (values) => {
    this.setState({ isLoading: true });
    OptionSettingAction.itemDelete(values)
      .then(res => {
        console.log(res)
        this.index()
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  /**
   * 値の変更
   * @param {*} value
   * @param {*} record
   * @param {*} index
   */
  onChangeValue = (value, record, index) => {

    let arrTemp = [...this.state.dataSource]
    let obj = {
      ...record,
      content: value, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj

    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
  }

  /**
   * 保存処理
   */
  save = () => {
    let params = {
      type_code: this.props.record?.type_code,
      option_code: this.props.record?.option_code,
      id_name: this.props.record?.id_name,
      dataSource: this.state.dataSource
    }

    OptionSettingAction.save(params)
      .then(res => {
        if (res) {
          // データ再取得
          this.index()
        }
      })
  }
  /**
 * idから行番号を取得
 * @param {*} arrayData
 * @param {*} recordID
 * @returns
 */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    // const { treeData, selectedNodes } = this.state
    return (
      <div className='optional-info-maintain'>
        <Card title={'オプション内容 [種別：' + this.props.record?.type_code + ' / コード：' + this.props.record?.option_code + '] ' + this.props.record?.id_name + ' 編集'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 550 }}
                rowKey={(record) => record.id}
              >
                <Table.Column title='項目' dataIndex='item' />
                <Table.Column
                  title='内容'
                  dataIndex='content'
                  width={300}
                  render={(value, record) => {
                    const index = this.findIndexByID(this.state.dataSource, record.id)
                    return (
                      <Form.Item name={['tableData', index, 'content']}>
                        <Input
                          style={{
                            fontWeight: (record.changeFlag ?? 0) ? 'bold' : 'normal', //変更時は太字
                          }}
                          onPressEnter={event => {
                            this.onChangeValue(event.target.value, record, index)
                          }}
                          onBlur={event => {
                            this.onChangeValue(event.target.value, record, index)
                          }}
                        />

                      </Form.Item>
                    )
                  }}
                />
                <Table.Column title='補足説明' dataIndex='supplement' />
                <Table.Column
                  title={
                    <Button
                      type='primary'
                      size='small'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '550px',
                            className: '',
                            component:
                              <ModalAdd
                                columns={columns}
                                onFinishScreen={(output) => {
                                  this.Insert(output.itemChange)
                                  this.closeModal()
                                }}
                              />
                          },
                        });
                      }}
                    />}

                  align='center'
                  width={40}
                  render={(value, record, index) => (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '550px',
                            className: '',
                            component:
                              <ModalChange
                                columns={columns}
                                record={record}
                                onUpdate={(output) => {
                                  this.Insert(output.itemChange)
                                  this.closeModal()
                                }}
                                onDelete={(output) => {
                                  // let newArray = [...this.state.dataSource]
                                  // newArray.splice(index, 1)
                                  // this.formRef.current.setFieldsValue({ tableData: newArray })
                                  // this.setState({ dataSource: newArray, disabledSaveBtn: false })
                                  this.delete(output.itemChange)
                                  this.closeModal()
                                }}
                              />
                          }
                        })
                      }}
                    />
                  )}
                />
              </Table>

            </div>
            <div className='box_button_bottom_right'>
              <Tooltip title='保存ボタンを押下するまで編集内容はデータベースに反映されません'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  disabled={this.state.disabledSaveBtn}
                  onClick={() => {
                    // 保存
                    this.save()
                  }}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Form>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(OptionSettingEdit)
