import React from "react";
import { connect } from "react-redux";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import { Card, Table, Button, message, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ImplementorInquiryAction from "redux/SpecificInsureMaintenance/InsureGuideCourse/ImplementorInquiry.action.js";

class WS1382001_ImplementorInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00120:実施者照会';

    this.state = {
      dataSource: [],
      isLoading: false,
      selectedRow: {},
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    this.setState({ isLoading: true });
    ImplementorInquiryAction.getScreenData()
      .then(res => {
        console.log(res);
        this.setState({ dataSource: res });
      }).catch(error => {
        message.error("エラーが発生しました");
      }).finally(
        this.setState({ isLoading: false })
      );
  }

  render() {
    return (
      <div className="implementor-inquiry">
        <Card className="mb-2" title="実施者照会">
          <Table
            bordered
            size="small"
            dataSource={this.state.dataSource ? this.state.dataSource : []}
            pagination={false}
            scroll={{ y: resizableTableScroll(120) }}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      practitioner_code: record.practitioner_code,
                      kanji_name: record.kanji_name
                    });
                  }
                }
              }
            }}
          >
            <Table.Column
              title="コード"
              width={80}
              dataIndex="practitioner_code"
              showSorterTooltip={false}
            />

            <Table.Column
              title="カ　　ナ"
              width={120}
              dataIndex="kana_name"
              showSorterTooltip={false}
            />

            <Table.Column
              title="氏　　名"
              width={150}
              dataIndex="kanji_name"
              showSorterTooltip={false}
            />

            <Table.Column
              title="資格"
              width={80}
              dataIndex="Eligibility"
              showSorterTooltip={false}
            />

            <Table.Column
              title="実施機関"
              width={250}
              dataIndex="" key=""
              showSorterTooltip={false}
              render={(value, record) => {
                return (
                  <div>
                    <span style={{ marginRight: '10px' }}>{record.implementing_agency_code}</span>
                    <span>({record.implementation_kanji_name})</span>
                  </div>
                );
              }} />
          </Table>
          {/* <Button type="primary" style={{ float: 'right' }}
            onClick={() => {
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen({
                  practitioner_code: this.state.selectedRow ? this.state.selectedRow?.practitioner_code : null,
                  kanji_name: this.state.selectedRow ? this.state.selectedRow?.kanji_name : null,
                });
              }
            }}
          >
            選択
          </Button> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1382001_ImplementorInquiry);
