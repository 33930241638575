import React from "react";
import { Card, Checkbox, Form, Popconfirm, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Color from "constants/Color";
import InsurerNumberConversionMasterAction from 'redux/SpecificInsureMaintenance/InsurerNumberConversionMaster/InsurerNumberConversionMaster.action'
import ModalDraggable from "components/Commons/ModalDraggable"
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry'

class InsurerNumberConversionMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  /**
   * 初期表示
   */
  componentDidMount() {
    this.formRef.current.setFieldsValue(this.props.record)
    this.forceUpdate()
  }

  /**
   * 保険者番号照会呼び出し
   * @param {*} value 
   */
  callbackOpenModal(value, conversion_insurer_number, insurer_name) {
    console.log(value);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS1290001_InsurerNumberInquiry
            InputValue={value}
            onFinishScreen={({
              Lo_InsurerNum,
              Lo_InsurerKanjiName,
              recordData
            }) => {
              this.formRef.current?.setFieldsValue({
                [conversion_insurer_number]: Lo_InsurerNum,
                [insurer_name]: Lo_InsurerKanjiName
              })
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * 保存処理
   */
  save() {
    let getFieldsValue = this.formRef.current?.getFieldValue()
    let params = {
      ...this.props.record,
      ...getFieldsValue,
      newFlag: this.props.newFlag,
    }
    InsurerNumberConversionMasterAction.save(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  onDelete() {
    let params = {
      id: this.props.record.id
    }
    InsurerNumberConversionMasterAction.delete(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  /**
 * モーダルを閉じる
 */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }


  render() {
    return (
      <div className="insurer-number-conversion-master">
        <Card title={'保険者番号変換マスタ [' + (this.props.newFlag ? '新規' : '編集') + ']'}>
          <Form ref={this.formRef} autoComplete='off'>
            <div className="box_inner_vertical">
              {this.props.newFlag ?
                <div className="box_inner_horizontal">
                  <Form.Item name='conversion_insurer_number_from' label='変更元保健番号'>
                    <Input.Search
                      className="input-search-size-number-8"
                      onSearch={(value, e) =>
                        // 保険者番号照会
                        this.callbackOpenModal(value, 'conversion_insurer_number_from', 'insurer_name_from')
                      }
                      onPressEnter={(e) =>
                        // 保険者番号照会
                        this.callbackOpenModal(e.target.value, 'conversion_insurer_number_from', 'insurer_name_from')
                      }
                    />
                  </Form.Item>
                  <Form.Item name='insurer_name_from' label='保険者名称'>
                    <div>{this.formRef.current?.getFieldValue('insurer_name_from')}</div>
                  </Form.Item>
                </div> :
                <div className="box_inner_horizontal">
                  {/* 無理やりstyleを合わせているためいい方法があれば別の方法にする */}
                  <Form.Item name='conversion_insurer_number_from' label='変更元保健番号' style={{ marginRight: '67px' }}>
                    <div>{this.formRef.current?.getFieldValue('conversion_insurer_number_from')}</div>
                  </Form.Item>
                  <Form.Item name='insurer_name_from' label='保険者名称'>
                    <div>{this.formRef.current?.getFieldValue('insurer_name_from')}</div>
                  </Form.Item>
                </div>
              }
              <div className="box_inner_horizontal">
                <Form.Item name='conversion_insurer_number_to' label='変更後保健番号'>
                  <Input.Search
                    className="input-search-size-number-8"
                    onSearch={(value, e) =>
                      // 保険者番号照会
                      this.callbackOpenModal(value, 'conversion_insurer_number_to', 'insurer_name_to')
                    }
                    onPressEnter={(e) =>
                      // 保険者番号照会
                      this.callbackOpenModal(e.target.value, 'conversion_insurer_number_to', 'insurer_name_to')
                    }
                  />
                </Form.Item>
                <Form.Item name='insurer_name_to' label='保険者名称'>
                  <div>{this.formRef.current?.getFieldValue('insurer_name_to')}</div>
                </Form.Item>
              </div>
            </div>
            <div className='box_button_bottom_right'>
              < Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                okText='削除'
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                onConfirm={() =>
                  // 削除
                  this.onDelete()
                }
                cancelText='キャンセル'
                cancelButtonProps={{ style: { height: '32px' } }}
              >
                <Button
                  danger
                  type='primary'
                  style={{
                    display: this.props.newFlag ? 'none' : ''   // deleteFlag:falseの場合、非表示
                  }}
                  icon={<DeleteOutlined />}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
              </Popconfirm>

              {/* 保存ボタン */}
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default InsurerNumberConversionMaster;
