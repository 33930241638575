import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Card, Form, Input, Button, Radio, TimePicker, InputNumber } from 'antd'
import MiraisSingleTransmissionAction from 'redux/CooperationRelated/MiraisSingleTransmission/MiraisSingleTransmission.actions.js'


const grid = {
  labelCol: { style: { width: 90 } },
}
class WS2737500_MiraisSingleTransmissionEdit extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = 'Mirais単体送信 編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },

      name: '',
      exam_name: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      OrderStartDate: this.props.Li_record.OrderStartDate,
      order_start_time_at: this.props.Li_record.order_start_time_at,
      processing_division: this.props.Li_record.processing_division,
      order_number: this.props.Li_record.order_number,
      order_subnumber: this.props.Li_record.order_subnumber,
      reservation_number: this.props.Li_record.reservation_number,
      reservations_department: this.props.Li_record.reservations_department,
      reservations_item_code: this.props.Li_record.reservations_item_code,
      image_item_code: this.props.Li_record.image_item_code,
      reservations_comment: this.props.Li_record.reservations_comment,
      transmission_state: this.props.Li_record.transmission_state
    })

    this.setState({
      name: this.props.Li_record.name,
      exam_name: this.props.Li_record.exam_name,
    })
  }

  /**
   * 保存処理
   */
  onSave = () => {
    let getFieldValue = this.formRef.current?.getFieldValue()

    let prams = {
      ...getFieldValue,
      id: this.props.Li_record.id,
      reservation_number_medical_exam: this.props.Li_record.reservation_number_medical_exam,
      orderStartDate: moment(getFieldValue.OrderStartDate)?.format('YYYY-MM-DD'),
      orderStartTimeAt: moment(getFieldValue.order_start_time_at)?.format('HH:mm:ss')
    }

    MiraisSingleTransmissionAction.editConfirm(prams)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({})
        }
      })
  }

  render() {
    return (
      <div className='mirais-single-transmission-edit' >
        <Card title='Mirais単体送信 編集'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item
                label='検査日時'
                name="OrderStartDate"
                {...grid}
              >
                <VenusDatePickerCustom
                  format='YYYY/MM/DD'
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>

              <Form.Item
                name="order_start_time_at"
              >
                <TimePicker
                  format='HH:mm:ss'
                />
              </Form.Item>
            </div>

            <Form.Item
              label='処理方法'
              name='processing_division'
              {...grid}
            >
              <Radio.Group >
                <Radio value='1'>追加</Radio>
                <Radio value='3'>削除</Radio>
              </Radio.Group>
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='オーダー番号'
                name='order_number'
                {...grid}
              >
                <InputNumber
                  maxLength={13}
                  min={1}
                  style={{ width: '200px' }}
                />
              </Form.Item>

              <Form.Item
                name='order_subnumber'
              >
                <InputNumber
                  maxLength={3}
                  min={1}
                />
              </Form.Item>
            </div>

            <Form.Item
              label='予約番号'
              name='reservation_number'
              {...grid}
            >
              <InputNumber
                maxLength={13}
                min={1}
                style={{ width: '200px' }}

              />
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='予約項目'
                name="reservations_department"
                {...grid}
              >
                <Input
                  maxLength={2}
                />
              </Form.Item>
              <Form.Item
                name='reservations_item_code'
              >
                <Input
                  maxLength={5}
                />
              </Form.Item>
              <div style={{ marginTop: 5 }}>{this.state.name}</div>
            </div>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='画像項目'
                name='image_item_code'
                {...grid}
              >
                <Input
                  maxLength={6}
                />
              </Form.Item>
              <div style={{ marginTop: 5 }}>{this.state.exam_name}</div>
            </div>

            <Form.Item
              label='コメント'
              name='reservations_comment'
              {...grid}
            >
              <Input
                maxLength={60}
              />
            </Form.Item>

            <Form.Item
              label='送信状態'
              name='transmission_state'
              {...grid}
            >
              <Radio.Group
              >
                <Radio value={1}>送信済</Radio>
                <Radio value={0}>未送信</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => { this.onSave() }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card>
      </div>
    )
  }
}
export default WS2737500_MiraisSingleTransmissionEdit
