import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Select, Button, Row, Col, message, Modal, } from "antd";
import { DownCircleOutlined } from '@ant-design/icons';
import WS1288001_ContractYearSelectSub from 'pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1288001_ContractYearSelectSub.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1318005_SerialNumberRef from "./WS1318005_SerialNumberRef";

class WS1318001_ContractCopyingProcessSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-TMS01020:契約 複写処理 SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  onFinish = async (values) => {
    try {
      let params = {
        Li_ProcessMode: this.props.selectedRow ? 2 : 1,
        ContractYearBasedOnYear: parseInt(this.formRef.current.getFieldsValue().ContractYearBasedOnYear),
        Li_ContractYearOriginal: parseInt(this.formRef.current.getFieldsValue().ContractYearBasedOnYear),
        ContractYearBasedOnMonth: parseInt(this.formRef.current.getFieldsValue().ContractYearBasedOnMonth),
        ContractYearToYear: parseInt(this.formRef.current.getFieldsValue().ContractYearToYear),
        Li_ContractYearTo: parseInt(this.formRef.current.getFieldsValue().ContractYearToYear),
        ContractYearToMonth: parseInt(this.formRef.current.getFieldsValue().ContractYearToMonth),
      }
      if (this.props.selectedRow) {
        params.InternalNumTo = parseInt(this.formRef.current.getFieldsValue().InternalNumTo);
        params.InternalNumOriginal = parseInt(this.formRef.current.getFieldsValue().InternalNumOriginal);
      }
      await VenusApiRoutesV2.callApi("API001318001001", params);
      let res = await VenusApiRoutesV2.callApi("API001318001002", params);
      if (res.message) {
        message.success(res.message);
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            ContractYear: this.formRef.current.getFieldsValue().ContractYearToYear,
            ContractMonth: this.formRef.current.getFieldsValue().ContractYearToMonth,
            ContractName: this.formRef.current.getFieldsValue().ContractName,
          });
        }
      }
      if (typeof res === "string") {
        message.error(res);
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
          });
        }
      }
      this.closeModal();
    } catch (error) {
        message.error('エラーが発生しました');
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  componentDidMount () {
    this.formRef.current.setFieldsValue({
      ContractYearBasedOnYear: this.props.Lio_ContractYear,
      ContractYearBasedOnMonth: this.props.Lio_ContractMonth,
      ContractYearToYear: this.props.Lio_ContractYear,
      ContractYearToMonth: this.props.Lio_ContractMonth,
      InternalNumOriginal: this.props.selectedRow?.serial_number,
      InternalNumTo: this.props.maxSerialNumber + 1
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.formRef.current.setFieldsValue({
        ContractYearBasedOnYear: this.props.Lio_ContractYear,
        ContractYearBasedOnMonth: this.props.Lio_ContractMonth,
        ContractYearToYear: this.props.Lio_ContractYear,
        ContractYearToMonth: this.props.Lio_ContractMonth,
        InternalNumOriginal: this.props.selectedRow?.serial_number,
        InternalNumTo: this.props.maxSerialNumber + 1
      });
    }
  }

  render() {
    return (
      <div className="contract-copying-process-sub">
        <Card className="mb-2" title="V4-TMS01020:契約 複写処理 SUB">
          <Form
            ref={this.formRef} labelCol={{ span: 6 }} style={{ marginTop: '30px' }}
            onFinish={this.onFinish}
            initialValues={{ContractYearBasedOnMonth: 4, ContractYearToMonth: 4}}
          >
            <Row justify="center">
              <Col span={8}>
                <Form.Item
                  name="ContractYearBasedOnYear"
                  label="契約年度"
                >
                  <Input type="number" style={{ textAlign: 'right' }} disabled={!this.props.selectedRow} onDoubleClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "70%",
                        component: (
                          <WS1288001_ContractYearSelectSub
                            onFinishScreen={(output) => {
                              let yearMonthDisplay = output.recordData?.YearDisplay;
                              let year = yearMonthDisplay?.split("年")?.[0];
                              let month = yearMonthDisplay?.split("年")?.[1]?.split("月")?.[0];
                              this.formRef.current?.setFieldsValue({
                                ContractYearBasedOnYear: year,
                                ContractYearBasedOnMonth: month ? month.trim() : 0,
                              })
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    });
                  }} />
                </Form.Item>
              </Col>

              <label style={{ width: '40px', textAlign: 'center' }}>年</label>
              <Col span={4}>
                <Form.Item
                  name="ContractYearBasedOnMonth"
                  label=""
                >
                  <Select disabled={!this.props.selectedRow}>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <label style={{ width: '40px', textAlign: 'center' }}>月</label>
              {this.props.selectedRow && <Col>
                <Form.Item
                  name="InternalNumOriginal"
                  label=""
                >
                  <Input type="number" style={{ textAlign: 'right' }} onDoubleClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "70%",
                        component: (
                          <WS1318005_SerialNumberRef
                            Li_ContractYear={this.formRef.current?.getFieldValue("ContractYearBasedOnYear")}
                            onFinishScreen={(output) => {
                              this.formRef.current?.setFieldsValue({
                                InternalNumOriginal: output.serial_number,
                              })
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    });
                  }} />
                </Form.Item>
              </Col>}
            </Row>
            <Row justify="center" >
              <DownCircleOutlined style={{ color: "blue", fontSize: '20pt', margin: '20px' }} />
            </Row>
            <Row justify="center">
              <Col span={8}>
                <Form.Item
                  name="ContractYearToYear"
                  label="契約年度"
                >
                  <Input disabled={this.props.selectedRow} type="number" style={{ textAlign: 'right' }} onDoubleClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "70%",
                        component: (
                          <WS1288001_ContractYearSelectSub
                            onFinishScreen={(output) => {
                              let yearMonthDisplay = output.recordData?.YearDisplay;
                              let year = yearMonthDisplay?.split("年")?.[0];
                              let month = yearMonthDisplay?.split("年")?.[1]?.split("月")?.[0];
                              this.formRef.current?.setFieldsValue({
                                ContractYearToYear: year,
                                ContractYearToMonth: month ? month.trim() : 0,
                                ContractName: output.recordData.contract_name ? output.recordData.contract_name : '',
                              })
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    });
                  }} />
                </Form.Item>
              </Col>
              <label style={{ width: '40px', textAlign: 'center' }}>年</label>
              <Col span={4}>
                <Form.Item
                  name="ContractYearToMonth"
                  label=""
                >
                  <Select disabled={this.props.selectedRow}>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <label style={{ width: '40px', textAlign: 'center' }}>月</label>
              {this.props.selectedRow && <Col>
                <Form.Item
                  name="InternalNumTo"
                  label=""
                >
                  <Input type="number" style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>}
            </Row>

            <Form.Item>
              <Button type="primary" style={{ float: 'right', margin: '10px 10px 30px 0' }} onClick={() => {
                Modal.confirm({
                  width: 350,
                  title: '複写処理を実行しますか？',
                  okText: 'はい',
                  cancelText: 'いいえ',
                  onOk: () => {
                    this.onFinish();
                  }
                })
              }}>
                複 写
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1318001_ContractCopyingProcessSub);
