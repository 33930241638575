import { message } from "antd";
import SetIncludesQueryService from "services/ReservationBusiness/PersonalReserveProcess/SetIncludesQueryService";

export const SetIncludesQueryAction = {
  getSetIncludesQuery(data) {
    return SetIncludesQueryService.getSetIncludesQuery(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        console.log(err);
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  onSave(data) {
    return SetIncludesQueryService.onSave(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  onDelete(data) {
    return SetIncludesQueryService.onDelete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
}