import { message } from 'antd'
import ReserveAvailabilityService from 'services/Others/ReserveStatusSearch/ReserveAvailabilityService'

const ReserveAvailabilityAction = {
  index(data) {
    return ReserveAvailabilityService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default ReserveAvailabilityAction
