import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Row, Col, Button, Space, Modal, message } from 'antd'
import PropTypes from "prop-types"
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery.jsx'
import WS0178001_QuerySiteInfo from './WS0178001_QuerySiteInfo.jsx'
import WS0179001_InquiryFindingInfo from './WS0179001_InquiryFindingInfo.jsx'
import { PlusOutlined, MoreOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import NormalValueSettingAction from 'redux/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSetting.action'

// ★★

const labelCol = {
  span: 4
}
const styleFormItem = {
  margin: 0,
}

class WS2713502_NormalValueSettingInput extends React.Component {
  static propTypes = {
    Li_FormatCopySource: PropTypes.string,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      order: this.props.order,
      id: this.props.a6_id,
      findingsCode: this.props.findingsCode,
      classification: this.props.site_classification,
      siteCode: this.props.site_code
    }
    this.onFinish = this.onFinish.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      id: this.props.value?.a6_id ?? "",
      kind: this.props.value?.inspection ?? "",
      Judge: this.props.value?.judgement ?? "",
      SiteName: this.props.value?.site_name ?? "",
      FindingsName: this.props.value?.minimal_required_value ?? "",
      SiteClassification: this.props.value?.site_classification ?? "",
      SiteCode: this.props.value?.site_code ?? "",
      FindingsCode: this.props.value?.findings_code ?? "",
      FindingsName: this.props.value?.minimal_required_value ?? "",
    })
  }

  /**
     * モーダル閉じる
     */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  findingCodeSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0179001_InquiryFindingInfo
          Li_FindingsClassify
          Lo_FindingsCode
          Lo_JudgeValue
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              FindingsName: value,
            })
            this.closeModal()
          }}
        />),
      },
    })
  }

  normalValueSettingSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0285001_JudgeQuery
          Li_JudgeLevel={''}
          Lio_Judge={''}
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              Judge: value.recordData.judgment_result
            })
            this.closeModal()
          }}
        />),
      },
    })
  }


  // onSave() {
  //   NormalValueSettingAction.onSave()
  //     .then(res => {
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       const res = error.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // }

  onFinish(values) {
    const { a6_id, site_code, site_classification, findings_code } = this.props.value

    values = { ...values, a6_id, site_code, site_classification, findings_code }
    console.log(values)
    NormalValueSettingAction.onSave(values)
      .then((res) => {
        this.setState({ mainSource: res })
      })
      .catch(error => {
        message.error(res.data.message);
      })
  }

  render(record) {
    // const { dataSource } = this.state
    return (
      <div className='normal-value-setting'>
        <Card title='編集' >
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ Pattern: 1 }}>
            <Col>

              <Form.Item label='規定値設定' labelCol={{ span: 4 }} className='mt-3'></Form.Item>
              <div style={{ border: '1px solid #F0F0F0', padding: '7px' }}>
                <Form.Item name='Judge' label='判定' labelCol={labelCol} style={{ marginTop: '1rem' }}>
                  <Input.Search
                    className='input-search-size-judgment'
                    onSearch={this.normalValueSettingSearch}
                  />
                </Form.Item>
                {/* <Form.Item name='InspectResults' label='検査結果' labelCol={labelCol}>
                  <Input.Search onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 500,
                        component: (<WS0274001_InspectCmtSearchQuery
                          Lio_CommentsClassify={''}
                          LnkOutInspectCommentsScreen={''}
                          onFinishScreen={(value) => {
                            this.formRef.current.setFieldsValue({
                              InspectResults: value
                            })
                            this.closeModal()
                          }}
                        />),
                      },
                    })
                  }} />
                </Form.Item> */}
                <Form.Item label='部位' labelCol={labelCol} style={styleFormItem}>
                  <Space>
                    <Form.Item name='SiteName'>
                      <Input.Search onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 500,
                            component: (<WS0178001_QuerySiteInfo
                              Li_SiteClassify={2}
                              Lo_SiteCode
                              onFinishScreen={(value) => {
                                this.formRef.current.setFieldsValue({
                                  SiteName: value.site_name
                                })
                                this.closeModal()
                              }}
                            />),
                          },
                        })
                      }} />
                    </Form.Item>
                    {/* <Form.Item>
                      <span>{this.formRef.current?.getFieldValue('Expression_53') ?? "-"}</span>
                    </Form.Item> */}
                  </Space>
                </Form.Item>
                <Form.Item label='所見' labelCol={labelCol} style={styleFormItem}>
                  <Space>
                    <Form.Item name='FindingsName'>
                      <Input.Search onSearch={() => {
                        this.findingCodeSearch
                      }} />
                    </Form.Item>
                    {/* <Form.Item>
                      <span>{this.formRef.current?.getFieldValue('Expression_54') ?? "-"}</span>
                    </Form.Item> */}
                  </Space>
                </Form.Item>
                {/* <Form.Item label='規定値' labelCol={labelCol}>
                  <span>{this.formRef.current?.getFieldValue('Expression_50') ?? "-"}</span>
                </Form.Item> */}
              </div>
            </Col>
            {this.state.order === "edit" ? (
              <div className='box_button_bottom_right'>
                {/* <Button
                  type='primary'
                  danger
                  icon={<DeleteOutlined />}
                  style={{ display: (this.props.newFlag) ? 'none' : '' }}
                  onClick={() => {
                    // 確認モーダルを表示してから、削除
                    Modal.confirm({
                      content: `削除を行いますか?`,
                      okText: 'は　い',
                      cancelText: 'いいえ',
                      onOk: () => {
                        this.delete()
                      }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button> */}

                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  htmlType='submit'
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            ) :
              <div className='box_button_bottom_right'
                style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            }
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

export default WS2713502_NormalValueSettingInput
