import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Table } from 'antd'
import WS1107001_SettingOcrCapture from 'pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS1107001_SettingOcrCapture.jsx'
import ProcessSelectQueryAction from 'redux/CooperationRelated/OcrCaptureStartUp/ProcessSelectQuery.action'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS3108008_ProcessSelectQuery extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '処理選択照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    ProcessSelectQueryAction.getListData()
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
          isLoadingTable: false
        })
      })
  }

  /**
   * 設定[OCR取込] モーダル
   */
  showWS1107001_SettingOcrCapture = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component:
          <WS1107001_SettingOcrCapture
            onClickedCreate={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ,
      },
    })
  }

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='process-select-query'>
        <Card title='処理選択'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}

            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  // 行選択
                  if (this.props.onFinishScreen) {
                    // 親画面に反映
                    this.props.onFinishScreen({
                      recordData: record
                    })
                  }
                }
              }
            }}
          >
            <Table.Column
              title='処理選択項目'
              dataIndex='ProcessSelectName'
            />
            {/* <Table.Column width={60}
              render={(text, record, index) => {
                return <div style={{ textAlign: 'center' }}>
                  <Button type='primary'
                    onClick={() => {
                      if (this.props.onFinishScreen) {
                        this.props.onFinishScreen({
                          recordData: record
                        })
                      }
                    }}
                  >選択
                  </Button>
                </div>
              }}
            /> */}
          </Table>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                // 設定[OCR取込] モーダル
                this.showWS1107001_SettingOcrCapture()
              }}
            >
              設定
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3108008_ProcessSelectQuery)
