import React from "react";
import { connect } from "react-redux";
import  ModalDraggable  from "components/Commons/ModalDraggable";
import { Card, Modal, Button, Form, Checkbox } from "antd";
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { download_file_binary, download_file } from 'helpers/CommonHelpers'

import CsvCapture from "pages/ZZ_Others/CsvInputOutput/CsvCapture.jsx";

import CsvInputOutputAction from "redux/Others/CsvInputOutput/CsvInputOutput.action";

class CsvInputOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'データ一括入出力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
      TableNames: [],
    };
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  getScreenData() {
    CsvInputOutputAction.getScreenData()
      .then((res) => {
        this.setState({
          TableNames: res ? res.TableNames : [],
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoading: false })
        this.closeModal()
      })
  }

  downloadBtn() {
    let fields = this.formRef.current?.getFieldsValue();

    let tableNames = [];
    Object.keys(fields).filter((key) => {
      if (fields[key] == true) {
        tableNames.push(key);
      }
    });

    if (tableNames.length == 0) {
      Modal.warning({
        content: 'テーブルを選択してください',
        okText: '確認',
      })
      return
    }

    let params = {
      tableNames: tableNames,
    }

    this.setState({ isLoading: true })
    CsvInputOutputAction.downloadFile(params)
      .then((res) => {
        console.log(res)
        if (res) {
          // zipファイルダウンロード
          download_file_binary(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoading: false })
        this.closeModal()
      })
  }

  uploadBtn() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 700,
        component: (
          <CsvCapture
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="rising-document-create">
        <Card className="mb-2" title="データ一括入出力">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            {this.state.TableNames?.map(value => (
              <Form.Item
                name={value}
                key={value}
                valuePropName="checked"
                style={{marginLeft: '24px'}}
              >
                <Checkbox>{value}</Checkbox>
              </Form.Item>
            ))}
            <div style={{textAlign: 'right', marginRight: '24px'}}>
              <Button
                icon={<DownloadOutlined />}
                type='primary'
                onClick={() => {
                  this.downloadBtn()
                }} >
                <span className='btn_label'>
                  出力
                </span>
              </Button>
              <Button
                icon={<UploadOutlined />}
                type='primary'
                style={{marginLeft: '12px'}}
                onClick={() => {
                  this.uploadBtn()
                }} >
                <span className='btn_label'>
                  入力
                </span>
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0}}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CsvInputOutput);
