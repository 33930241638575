import React from "react";
import { connect } from "react-redux";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import { Button, Card, Checkbox, Input, Row, Select, Table, Typography, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ContractSelectScreenAction from 'redux/SpecificInsureGuide/SpecificInsureGuideSettleProcessList/ContractSelectScreen.action'

class WS1465001_ContractSelectScreen extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '契約選択画面';

    this.state = {
      data: {
        AllContract: false,
        InitialCollectionSystem: 0,
        YearDisplay: "",
      },
      dataSource: [],
      isLoading: false,
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    this.setState({ isLoading: false });
    const { Li_Date, Li_Id, Li_InsurerNum, Li_GuideDivision, Li_Year, Li_YearAdded } = this.props;
    console.log(this.props);
    let params = {
      Li_Date: Li_Date,
      Li_Id: Li_Id,
      Li_InsurerNum: Li_InsurerNum,
      Li_GuideDivision: Li_GuideDivision,
      Li_Year: Li_Year,
      Li_YearAdded: Li_YearAdded
    }
    ContractSelectScreenAction.getScreenData(params)
      .then(res => {
        this.setState({
          data: {
            AllContract: res.AllContract,
            InitialCollectionSystem: res.InitialCollectionSystem,
            YearDisplay: res.YearDisplay,
            Li_Date,
            Li_Id,
            Li_InsurerNum,
            Li_GuideDivision,
            Li_Year,
            Li_YearAdded
          }
        })
        let data = {
          Li_Date: Li_Date,
          Li_Id: Li_Id,
          Li_InsurerNum: Li_InsurerNum,
          Li_GuideDivision: Li_GuideDivision,
          Li_Year: Li_Year,
          Li_YearAdded: Li_YearAdded
        }
        this.contractSelectScreen_1(data)
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      });
  }

  contractSelectScreen_1(params) {
    ContractSelectScreenAction.contractSelectScreen_1(params)
      .then(res => {
        this.setState({
          dataSource: res,
          isLoading: false,
          selectedRow: res[0]
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      });
  }

  PreviousYear() {
    this.setState({ isLoading: false });
    const { data } = this.state;
    let params = {
      ...data,
      Li_Year: data.Li_Year - 1
    }
    ContractSelectScreenAction.getScreenData(params)
      .then(res => {
        this.setState({
          data: {
            ...data,
            AllContract: res.AllContract,
            InitialCollectionSystem: res.InitialCollectionSystem,
            YearDisplay: res.YearDisplay,
            Li_Year: data.Li_Year - 1
          }
        });
        this.contractSelectScreen_1(params)
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      });
  }

  FollowingYear() {
    this.setState({ isLoading: false });
    const { data } = this.state;
    let params = {
      ...data,
      Li_Year: data.Li_Year + 1
    }
    ContractSelectScreenAction.getScreenData(params)
      .then(({ YearDisplay }) => {
        this.setState({
          data: {
            ...data,
            YearDisplay,
            Li_Year: data.Li_Year + 1
          }
        })
        this.contractSelectScreen_1(params)
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      });
  }

  select(record) {
    const { Li_Date, Li_Id, Li_Year } = this.props;
    let params = {
      Li_Date: Li_Date,
      Li_Id: Li_Id,
      Year: this.state.data.Li_Year,
      SerialNum: record.serial_number,
      InitialCollectionSystem: this.state.data.InitialCollectionSystem
    }
    ContractSelectScreenAction.exit(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({ ...record, YearDisplay: this.state.data.YearDisplay });
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      });
  }

  render() {
    const { data, dataSource, pagination, isLoading } = this.state;
    return (
      <div className="contract-select-screen">
        <Card className="mb-2" title="契約選択画面">
          <div style={{ display: "flex", alignItems: "center" }} className="mb-2">
            <Input readOnly value={data.YearDisplay} style={{ width: 120, textAlign: "right", marginRight: "0.5rem" }} />
            <Button icon={<LeftOutlined />} onClick={() => this.PreviousYear()} className="mx-1" />
            <Button icon={<RightOutlined />} onClick={() => this.FollowingYear()} className="mx-1" />
            <Typography.Text strong className="mx-4">全契約 <Checkbox checked={data.AllContract} onChange={(e) => this.setState({ data: { ...data, AllContract: e.target.checked } })} /></Typography.Text>
            <span style={{ flex: 1 }} />
            <Typography.Text strong className="mx-2">初回徴収</Typography.Text>
            <Select value={data.InitialCollectionSystem} onChange={val => this.setState({ data: { ...data, InitialCollectionSystem: val } })}>
              <Select.Option value={1}>全額</Select.Option>
              <Select.Option value={2}>一部</Select.Option>
            </Select>
          </div>
          <Table
            size="small"
            bordered
            dataSource={dataSource}
            rowClassName={(record, index) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
            pagination={false}
            scroll={{ y: resizableTableScroll(120) }}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    selectedRow: record
                  })
                },
                onDoubleClick: () => {
                  this.select(record)
                }
              };
            }}
          >
            <Table.Column title="契約番号" width={100} dataIndex="contract_number" />
            <Table.Column title="契約名" dataIndex="contract_name" />
            <Table.Column
              title={<div style={{ textAlign: 'left' }}>金額</div>}
              width={100}
              align='right'
              dataIndex="Expression_5"
              render={(_, item) => {
                return (
                  <div>
                    {item["Expression_5"].toLocaleString()}
                  </div>
                );
              }}
            />
          </Table>
          <div className="box_button_bottom_right">
            <Button type="primary" onClick={() => this.select(this.state.selectedRow)}>契約選択</Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1465001_ContractSelectScreen);
