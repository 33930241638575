import React from 'react'
import { connect } from 'react-redux'

import { Card, Form, Input, Button, Tooltip } from 'antd'
import WS0341008_BranchShopInquiry from './WS0341008_BranchShopInquiry.jsx'
import WS0247001_OfficeInfoRetrievalQuery from './WS0247001_OfficeInfoRetrievalQuery.jsx'
import OfficeInfoMaintainAction from 'redux/basicInfo/OfficeInfoMaintain/OfficeInfoMaintain.action.js'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import * as wanakana from 'wanakana'
import hankakuMoji from 'components/Commons/HankakuMoji'

const grid = {
  labelCol: { style: { width: 85 } }
}
class WS0341009_BranchStoreGenerated extends React.Component {
  static propTypes = {
    Li_OfficeCode: PropTypes.any,
    Lo_BranchStoreCode: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '支社店生成'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      OfficeCode: '',
      BranchStoreCode: '',
      office_kana_name: '',
      office_kanji_name: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    if (this.props.Li_OfficeCode) {
      this.setState({ OfficeCode: this.props.Li_OfficeCode })
      this.getDataOfficeByOfficeCodeAndBranch(this.props.Li_OfficeCode)
    }
  }

  /**
   * 
   * @param {*} OfficeCode 
   */
  getDataOfficeByOfficeCodeAndBranch = (OfficeCode) => {
    OfficeInfoMaintainAction.getBranchStoreGeneratedAction(OfficeCode)
      .then(
        (res) => {
          if (res.branch_store_code) {
            this.formRef.current.setFieldsValue({
              OfficeCode: OfficeCode,
              branch_store_code_new: res.branch_store_code,
              office_kana_name_new: this.state.office_kana_name,
              office_kanji_name_new: this.state.office_kanji_name,
            })
            this.forceUpdate()
          }
        }
      )
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  /**
   * 支店の生成
   */
  create = () => {
    if (this.props.onFinishScreen) {
      const values = this.formRef.current.getFieldsValue()
      this.props.onFinishScreen({
        Li_OfficeCode: this.state.OfficeCode,
        Lo_BranchStoreCode: values.branch_store_code_new,
        OfficeCodeParent: this.state.OfficeCode,
        BranchStoreCodeParent: this.state.BranchStoreCode,
        office_kana_name: values.office_kana_name_new,
        office_kanji_name: values.office_kanji_name_new,
      })
    }
  }

  render() {
    return (
      <div className='branch-store-generated'>
        <Card title='支社店生成'>
          <Form
            ref={this.formRef}
            autoComplete='off'
            {...grid}
          >
            <div className='box_container'>
              <div className='box_title_border'>
                <span className='box-title'>
                  本社
                </span>
                <div className='box_inner_horizontal'>
                  <Form.Item
                    label='事務所コード'
                    name='OfficeCode'
                    style={{ marginBottom: 0 }}
                  >
                    <Input.Search
                      style={{ width: 120 }}
                      type='number'
                      min={1}
                      value={this.state.OfficeCode}
                      onChange={(event) =>
                        this.setState(
                          {
                            OfficeCode: event.target.value,
                            office_kana_name: '',
                            office_kanji_name: '',
                          },
                          () => {
                            this.getDataOfficeByOfficeCodeAndBranch(
                              this.state.OfficeCode
                            )
                          }
                        )
                      }
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS0247001_OfficeInfoRetrievalQuery
                                onFinishScreen={({
                                  Lio_OfficeCode,
                                  Lio_BranchStoreCode,
                                  Lo_Kanji_Name,
                                  recordData,
                                }) => {
                                  this.setState({
                                    OfficeCode: Lio_OfficeCode,
                                    BranchStoreCode: Lio_BranchStoreCode,
                                    office_kana_name: recordData.office_kana_name,
                                    office_kanji_name: Lo_Kanji_Name,
                                  })
                                  this.getDataOfficeByOfficeCodeAndBranch(
                                    Lio_OfficeCode
                                  )
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                  >
                    <Input.Search
                      style={{ width: 100, textAlign: 'right' }}
                      value={this.state.BranchStoreCode}
                      type='number'
                      min={0}
                      onChange={(event) => {
                        this.setState(
                          {
                            BranchStoreCode: event.target.value,
                            office_kana_name: '',
                            office_kanji_name: '',
                          },
                          () => {
                            this.getDataOfficeByOfficeCodeAndBranch(
                              this.state.BranchStoreCode
                            )
                          }
                        )
                      }}
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS0341008_BranchShopInquiry
                                Li_OfficeCode={this.state.OfficeCode}
                                onFinishScreen={({
                                  Li_OfficeCode,
                                  Lo_BranchStoreCode,
                                  recordData,
                                }) => {
                                  this.setState({
                                    OfficeCode: Li_OfficeCode,
                                    BranchStoreCode: Lo_BranchStoreCode,
                                    office_kana_name: recordData.office_kana_name,
                                    office_kanji_name: recordData.office_kanji_name,
                                  })
                                  this.getDataOfficeByOfficeCodeAndBranch(
                                    Li_OfficeCode
                                  )
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    />
                  </Form.Item>
                  <div style={{ marginTop: 5 }}>
                    {this.state.office_kanji_name}
                  </div>

                </div>
              </div>

              <div className='box_title_border'>
                <span className='box-title'>
                  支社店
                </span>
                <Form.Item
                  label='支社店コード'
                  name='branch_store_code_new'
                >
                  <Input style={{ width: 100, textAlign: 'right' }} type='number' />
                </Form.Item>
                <Form.Item
                  label='カナ名称'
                  name='office_kana_name_new'
                >
                  <Input
                    onBlur={(e) => {
                      let zenkaku_kana = wanakana.toKatakana(e.target.value)
                      let hannkaku_kana = hankakuMoji(zenkaku_kana)

                      this.formRef.current?.setFieldsValue({
                        office_kana_name_new: hannkaku_kana
                      })
                      this.forceUpdate()
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label='漢字名称'
                  name='office_kanji_name_new'
                  style={{ marginBottom: 0 }}
                >
                  <Input />
                </Form.Item>
              </div>

            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Tooltip title='※最終的なデータベースへの登録は事業所情報保守の「登録」ボタンで実行してください'>
              <Button
                disabled={this.state.OfficeCode ? false : true}
                type='primary'
                onClick={() => this.create()}
              >
                <span className='btn_label'>
                  生成
                </span>
              </Button>
            </Tooltip>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0341009_BranchStoreGenerated)
