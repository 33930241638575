import React from "react";
import { connect } from "react-redux";
import { Card, Table, message } from "antd";
import ContractSelectAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ContractSelect.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

class WS1349045_ContractSelect extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '契約選択';

    this.state = {
      pagination: {
        size: 1,
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        showQuickJumper: false,
        showSizeChanger: false,
      },
      dataSource: []
    };
  }

  getScreenData() {
    let params = {
      Li_InsurerNum: this.props.Li_InsurerNum,
      Li_Year: this.props.Li_Year
    }
    ContractSelectAction.getScreenData(params)
      .then(res => {
        console.log(res.data);
        this.setState({
          dataSource: res.data
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  componentDidMount() {
    this.getScreenData();
  }


  render() {
    return (
      <div className="contract-select">
        <Card title="契約選択">
          <Table
            bordered
            size="small"
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            scroll={{ y: resizableTableScroll(120) }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.props.onFinishScreen(record)
                }
              };
            }}
          >
            <Table.Column
              width={100}
              title="契約番号"
              dataIndex="contract_number"
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )
              }}
            />

            <Table.Column
              title="契約名"
              dataIndex="contract_name"
            />

            <Table.Column
              width={70}
              title="形態"
              dataIndex="Expresstion_5"
            />

            <Table.Column
              width={90}
              title="特定健診"
              dataIndex="Expresstion_6"
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />

            <Table.Column
              width={90}
              title="動機付"
              dataIndex="Expresstion_7"
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />

            <Table.Column
              width={90}
              title="積極的"
              dataIndex="Expresstion_8"
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1349045_ContractSelect);
