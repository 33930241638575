import AddressLabelPrintService from "services/UserTools/LabelPrint/AddressLabel_PersonalService";
import { message } from "antd";

const AddressLabel_PersonalActions = {
  getScreenData(params) {
    return AddressLabelPrintService.getScreenData(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  displayBtn(params) {
    return AddressLabelPrintService.displayBtn(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  print(params) {
    return AddressLabelPrintService.print(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  preview(params) {
    return AddressLabelPrintService.preview(params)
  },

  errorCheck(params) {
    return AddressLabelPrintService.errorCheck(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default AddressLabel_PersonalActions;