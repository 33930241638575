import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/main/history-list/getscreendata",
  addChangeData: "/api/main/history-list/addchangedata"
};
const HistoryListService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
  async addChangeData(params) {
    return axios.get(apiPaths.addChangeData, { params });
  },
};

export default HistoryListService;