import { message } from "antd";
import AgreementInformationService from "services/SpecificInsureGuide/SpecificInsureGuideSettleProcessList/AgreementInformationService.js"

const AgreementInformationAction = {
  getScreenData(data) {
    return AgreementInformationService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  contractBtn(data) {
    return AgreementInformationService.contractBtn(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default AgreementInformationAction;