import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Button, Dropdown, Menu } from "antd"
import { PlusOutlined, MoreOutlined } from "@ant-design/icons"
import ModalDraggable from 'components/Commons/ModalDraggable'
import NotInputCheckMaintainAction from "redux/InputBusiness/NotInputCheckCategory/NotInputCheckMaintain.action";
import WS0745004_NotInputCheckMaintainDetailChange from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745004_NotInputCheckMaintainDetailChange.jsx";
import WS0103001_CategoryListSettingSub from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0103001_CategoryListSettingSub.jsx";

class WS0745003_NotInputCheckMaintainDetail extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '注意事項入力 編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isSave: false,
      count: 0,
      newFlag: false,
      record: [],
      dataSourceCategory: [],
      dataSourceUnnecessary: [],
      selectedRowKeys: [],
      id: [],
      patternName: ""
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 親画面のrecordをコピー
    const data = { ...this.props.record }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
    this.setState({ patternName: data.patternName })

    //表示カテゴリ 指定パターン[基本分類]の表取得
    this.getCategoryDisplayMaintaince();

    //不要検査の表取得
    this.getUnnecessaryExamList();

  }

  /**
   * データ保存
   */
  onFinish = (values) => {
    this.props.onUpdate(values)
  }

  /**
   * データ削除
   * @param {*} id
   */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  /**
   * チェックボックスの状態変化
   * @param {*} record
   */
  eventSelectRecord = (record) => {
    const params = {
      id: record.record.id,
      selected: record.selected,
    };

    NotInputCheckMaintainAction.changeCategoryCheckbox(params)
      .then((res) => {
        this.getCategoryDisplayMaintaince();
      })
  }

  /**
   * 全体チェックボックスの状態変化
   * @param {*} param
   */
  eventSelectAllRecord = (param) => {
    let list = []
    list = this.state.dataSourceCategory.map((item) => item.id)

    const params = {
      id: list,
      selected: param
    };

    NotInputCheckMaintainAction.changeCategoryCheckboxAll(params)
      .then((res) => {
        this.getCategoryDisplayMaintaince();
      })
  }

  rowSelectionCheckbox(dataSource) {
    const rowSelection = {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        const checkIndex = this.state.selectedRowKeys.findIndex(
          (s) => s === record.id
        );
        const indexOf = this.state[dataSource].findIndex(
          (s, index) => s.id === record.id
        );
        let rows = this.state.selectedRowKeys.map((s) => s);
        if (checkIndex !== -1) {
          rows[indexOf] = undefined;
          this.setState({
            selectedRows: selectedRows,
            selectedRowKeys: rows,
          });
        } else {
          rows[indexOf] = record.id;
          this.setState({
            selectedRows: selectedRows,
            selectedRowKeys: rows,
          });
        }
      },
      onChange: async (selectedRowKeys, selectedRows) => {
        await this.setState({
          selectedRows: selectedRows,
        });
      },
    };
    return rowSelection;
  }

  //表示カテゴリ 指定パターン[基本分類]の表取得
  getCategoryDisplayMaintaince(record = null) {
    const params = {
      Li_Format: this.props.record.format
    }
    NotInputCheckMaintainAction.getCategoryDisplayMaintaince(params)
      .then(async (res) => {
        let rows = []
        // enabled_disabledがtrueのidを配列化
        res.forEach((item) => {
          if (item.enabled_disabled) {
            rows.push(item.id)
          }
        })

        await this.setState({
          dataSourceCategory: res,
          selectedRowKeys: rows
        })

        if (record) {
          const newRecord = res.find(item => item.id === record.id)
          await this.showWS0103001_CategoryListSettingSub(newRecord)
        }

      })
  }

  //表示カテゴリ・変更
  displayCategoryChange(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '400px',
        className: '',
        component:
          <WS0745004_NotInputCheckMaintainDetailChange
            record={record}
            newFlag={false}
            type={1}

            onUpdate={(output) => {
              let params = {
                categoryName: output.category_name,
                seq: output.display_order,
                Li_Format: this.props.record.format,
                id: record.id
              }

              NotInputCheckMaintainAction.saveCategoryDisplay(params)
                .then(async (res) => {
                  this.getCategoryDisplayMaintaince()
                  this.closeModal()
                })
            }}
          />
      },
    });
  }

  //不要検査の表取得
  getUnnecessaryExamList() {
    const params = {
      Li_Format: this.props.record.format
    }

    NotInputCheckMaintainAction.getUnnecessaryExamList(params)
      .then((res) => {
        this.setState({
          dataSource2: res,
          isRemove: true,
          isCancel: false,
          id: 0,
          disabledRadio: false,
          dataSourceUnnecessary: res
        });
        this.setFormFieldValue("tableData2", res);
      })
      .catch(() => { })
      .finally(() => this.setState({ isLoadingData2: false }));
  }

  //不要検査・変更
  onUpdate(output, id) {
    const params = {
      id: id,
      inspect_code: output.inspect_code,
      exam_name: output.exam_name,
      group: output.group,
      Li_Format: this.props.record.format,
    }
    NotInputCheckMaintainAction.saveUnnecessaryExamList(params)
      .then((res) => {
        this.getUnnecessaryExamList()
      })

  }

  //不要検査・新規
  onSignUp(output) {
    const params = {
      inspect_code: output.inspect_code,
      exam_name: output.exam_name,
      group: output.group,
      Li_Format: this.props.record.format,
    }
    NotInputCheckMaintainAction.saveUnnecessaryExamList(params)
      .then((res) => {
        this.getUnnecessaryExamList()
      })
  }

  //不要検査・削除
  onDelete(id) {
    const params = {
      id: id,
    }
    console.log(params);
    NotInputCheckMaintainAction.deleteDataUnnecessaryExamList(params)
      .then((res) => {
        this.getUnnecessaryExamList()
      })
  }

  showWS0103001_CategoryListSettingSub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0103001_CategoryListSettingSub
            record={record}
            patternCode={this.props.record.patternCode}
            multipleCategories={record.multiple_categories}
            node_id={this.props.node_id}
            categoryCode={record.category_code}
            onFinishScreen={(obj) => {
              this.getCategoryDisplayMaintaince(record);
            }}
          />
        ),
      }
    })
  }

  render() {
    return (
      <div className='notes-input'>
        <Card title='未入力チェック（詳細）'>
          <div className='box_container'>
            <div className='box_inner_horizontal'>
              <div style={{ width: '60%' }}>
                <div className='table_header_filter'>
                  {'表示カテゴリ 指定パターン[' + this.state.patternName + ']'}
                </div>
                <Table
                  dataSource={this.state.dataSourceCategory}
                  loading={this.state.isLoadingData1}
                  pagination={false}
                  scroll={{ y: 600 }}
                  bordered={true}
                  rowKey={(record) => record.id}
                  size="small"
                  rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: this.state.selectedRowKeys,
                    onSelect: (record, selected) =>
                      this.eventSelectRecord({
                        record,
                        selected
                      }),

                    onSelectAll: (selected) =>
                      this.eventSelectAllRecord({
                        selected: selected,
                      }),
                  }}

                  onRow={(record) => {
                    return {
                      onDoubleClick: async () => {
                        this.displayCategoryChange(record)
                      }
                    }
                  }}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="display_order"
                    width={80}
                    render={(value) => (
                      <div style={{ textAlign: 'right' }}>
                        {value}
                      </div>
                    )}
                  />

                  <Table.Column
                    title="名称"
                    dataIndex="category_name"
                  />

                  <Table.Column
                    title="カテゴリコード"
                    width={120}
                    dataIndex="ms1100category_code"
                    render={(value) => (
                      <div style={{ textAlign: 'right' }}>
                        {value}
                      </div>
                    )}
                  />

                  <Table.Column
                    title="カテゴリ名称"
                    dataIndex="ms1100category_name"
                  />

                  <Table.Column
                    title="統合"
                    dataIndex="integration"
                    width={44}
                    render={(value) => (
                      <div
                        style={{ textAlign: 'center' }}>
                        {value}
                      </div>)}
                  />

                  <Table.Column
                    width={40}
                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger="click"
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key="変更"
                                onClick={() => {
                                  this.displayCategoryChange(record)
                                }}
                              >変更
                              </Menu.Item>
                              <Menu.Item
                                key="統合"
                                onClick={() => {
                                  this.showWS0103001_CategoryListSettingSub(record)
                                }}
                              > 統合
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size="small"
                            icon={<MoreOutlined />}
                            style={{ width: "100%" }}
                          ></Button>
                        </Dropdown>
                      );
                    }}
                  />
                </Table>

              </div>
              <div style={{ width: '40%' }}>
                <div className='table_header_filter'>
                  {'不要検査'}
                </div>
                <Table
                  dataSource={this.state.dataSourceUnnecessary}
                  loading={this.state.isLoadingData2}
                  pagination={false}
                  size="small"
                  bordered={true}
                  rowKey={(record) => record.id}
                  scroll={{ y: 600 }}

                  onRow={(record) => {
                    return {
                      onDoubleClick: async () => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '300px',
                            className: '',
                            component:
                              <WS0745004_NotInputCheckMaintainDetailChange
                                newFlag={false}
                                type={2}
                                record={record}
                                onUpdate={(output) => {
                                  this.onUpdate(output, record.id)
                                  this.closeModal()
                                }}
                                onDelete={() => {
                                  this.onDelete(record.id)
                                  this.closeModal()
                                }}
                              />
                          }
                        })
                      }
                    }
                  }}
                >

                  <Table.Column
                    title="検査コード"
                    width={90}
                    dataIndex="inspect_code"
                    render={(value) => (
                      <div
                        style={{ textAlign: 'right' }}>
                        {value}
                      </div>)}
                  />

                  <Table.Column
                    title="名称"
                    dataIndex="exam_name"
                  />

                  <Table.Column
                    title="ｸﾞﾙｰﾌﾟ"
                    dataIndex="group"
                  />

                  <Table.Column
                    width={40}
                    title={
                      <Button
                        size="small"
                        type="primary"
                        style={{ textAlign: "center" }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '350px',
                              className: '',
                              component:
                                <WS0745004_NotInputCheckMaintainDetailChange
                                  newFlag={true}
                                  type={2}
                                  onUpdate={(output) => {
                                    this.onSignUp(output)
                                    this.closeModal()
                                  }}
                                />
                            },
                          });
                        }}
                      ></Button>
                    }
                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger="click"
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key="変更"
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '300px',
                                      className: '',
                                      component:
                                        <WS0745004_NotInputCheckMaintainDetailChange
                                          newFlag={false}
                                          type={2}
                                          record={record}
                                          onUpdate={(output) => {
                                            this.onUpdate(output, record.id)
                                            this.closeModal()
                                          }}
                                          onDelete={() => {
                                            this.onDelete(record.id)
                                            this.closeModal()
                                          }}
                                        />
                                    },
                                  });
                                }}
                              >変更
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size="small"
                            icon={<MoreOutlined />}
                            style={{ width: "100%" }}
                          ></Button>
                        </Dropdown>
                      );
                    }}
                  />
                </Table>
              </div>
            </div>
          </div >
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0745003_NotInputCheckMaintainDetail)
