import { message } from "antd";
import CreateTestForMedicalExamInfoService from "services/Others/CreateTestForMedicalExamInfo/CreateTestForMedicalExamInfoService";


const CreateTestForMedicalExamInfoAction = {
  displayList(data) {
    return CreateTestForMedicalExamInfoService.displayList(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  autoCreate(data) {
    return CreateTestForMedicalExamInfoService.autoCreate(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default CreateTestForMedicalExamInfoAction;