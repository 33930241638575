import { message } from 'antd'
import RadiographyInfoMaintainService from 'services/InputBusiness/RadiologistsInfoMaintain/RadiographyInfoMaintainService'

const RadiographyInfoMaintainAction = {
  index() {
    ////確かめ方
    ////console.log(RadiographyInfoMaintainService.index())
    //// return RadiographyInfoMaintainService.index()
    return RadiographyInfoMaintainService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteRadioInfo(data) {
    console.log("アクションdel")
    return RadiographyInfoMaintainService.deleteRadioInfo(data)
      .then((res) => {
        console.log(res)
        console.log("アクション削除")
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveRadioInfo(data) {
    console.log("アクションsave")
    return RadiographyInfoMaintainService.saveRadioInfo(data)
      .then((res) => {
        console.log(res)
        console.log("アクションセーブ")
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}
export default RadiographyInfoMaintainAction