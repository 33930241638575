import React from "react";
import { connect } from "react-redux";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import { Card, Form, Input, Select, Checkbox, Button, Tabs, message } from "antd";
import { DeleteOutlined, MenuOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';
import WS1302001_AgencyInquirySub from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302001_AgencyInquirySub.jsx";
import WS1465001_ContractSelectScreen from "../V4TH0011102_SpecificInsureGuideSettleProcessList/WS1465001_ContractSelectScreen";
import VouchersInputAction from 'redux/SpecificInsureGuide/InsureGuideInitInput/VouchersInput.action'
import moment from 'moment';
const labelStyle = { marginLeft: '58px' };

const smGrid = {
  labelCol: { style: { width: 90 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 90px)' } }
}

const dataStyle = {
  marginTop: 0
}

const DATE_FORMAT = 'YYYY/MM/DD';
class WS2657078_VouchersInput extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let params = {
      date_on: this.props.Li_Date,
      personal_number_id: this.props.Li_PersonalNum
    }
    VouchersInputAction.getScreenData(params)
      .then(res => {
        if (res.ExpirationDateChars === '0000/00/00') {
          res.ExpirationDateChars = ''
        }
        if (res.GrantDateChars === '0000/00/00') {
          res.GrantDateChars = ''
        }
        let date_japanese_calendar = res.date_japanese_calendar
        date_japanese_calendar = date_japanese_calendar.substr(0, 4)
        if (date_japanese_calendar > 1988) {
          // 値４
          res.date_birth_era = '平成';
        } else if (date_japanese_calendar > 1925) {
          // 値３
          res.date_birth_era = '昭和';
        } else if (date_japanese_calendar > 1911) {
          // 値２
          res.date_birth_era = '大正';
        } else if (date_japanese_calendar > 1867) {
          // 値１
          res.date_birth_era = '明治';
        }
        if (res.specify_health_burden_sect === 2 || res.specify_health_burden_sect === 4) {
          res.SpecificMedicalExambasicburdenContent = parseFloat(res.SpecificMedicalExambasicburdenContent).toFixed(3)
        }
        res.date_japanese_calendar = moment(res.date_japanese_calendar).format('yMMDD')

        if (res.other_agency === 0) {
          res.other_agency = ''
        }
        if (res.vouchers_docket_num === 0) {
          res.vouchers_docket_num = ''
        }
        if (res.consult_ticket_docket_num === 0) {
          res.consult_ticket_docket_num = ''
        }
        if (res.SpecificMedicalExambasicburdenContent === 0) {
          res.SpecificMedicalExambasicburdenContent = ''
        }
        if (res.specify_insurer_pay_max === 0) {
          res.specify_insurer_pay_max = ''
        }
        this.formRef.current?.setFieldsValue(res);
        this.getContractData()
        this.forceUpdate();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingFm: false }));
  }

  getContractData() {
    let params = {
      date_on: this.props.Li_Date,
      personal_number_id: this.props.Li_PersonalNum
    }
    VouchersInputAction.getContractData(params)
      .then(res => {
        this.formRef.current?.setFieldsValue(res);
        this.forceUpdate();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }


  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.getScreenData();
    }
  }

  contractSelect() {
    let params = {
      Li_Date: this.props.Li_Date,
      Li_PersonalNum: this.props.Li_PersonalNum,
      insurer_num: this.formRef.current?.getFieldValue('insurer_num'),
      insure_guide_division: this.formRef.current?.getFieldValue('insure_guide_division'),
    }
    VouchersInputAction.contractSelect(params)
      .then(res => {
        if (res.variables) {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 850,
              component: (
                <WS1465001_ContractSelectScreen
                  {...res.variables}
                  onFinishScreen={(output) => {
                    this.formRef.current?.setFieldsValue({ Expression_54: `(${output.YearDisplay}) ${output.contract_name}` });
                    this.forceUpdate()
                    this.closeModal()
                    this.getContractData()
                  }}
                />
              ),
            },
          });
        }
      })
  }

  SpecificMedicalExambasicburdenContent(e) {
    let specify_health_burden_sect = this.formRef.current?.getFieldValue('specify_health_burden_sect')
    let inputData
    if (e.target.value !== '') {
      if (specify_health_burden_sect === 3 || specify_health_burden_sect === 6) {
        let value = e.target.value
        // 整数のみ抽出
        let integer = Math.floor(value)
        let number
        number = String(integer).substring(0, 3)
        // 小数点以下を抽出
        let negativeNumber = String(value).split(".")[1]
        inputData = number + '.' + negativeNumber
        // 小数第三位まで表示　０埋め
        inputData = parseFloat(inputData).toFixed(3)
      } else {
        let value = e.target.value.slice(0, 6);
        inputData = value
      }
    } else {
      let value = e.target.value.slice(0, 6);
      inputData = value
    }
    this.formRef.current?.setFieldsValue({ SpecificMedicalExambasicburdenContent: inputData });
  }

  //保存ボタンが押された時の処理
  onsave() {
    let getFieldsValue = this.formRef.current?.getFieldValue()
    if (getFieldsValue.ExpirationDateChars === '') {
      return message.error('有効期限を入力してください')
    }
    let ExpirationDateChars = moment(getFieldsValue.ExpirationDateChars).format('YYYYMMDD')
    getFieldsValue.ExpirationDateChars = ExpirationDateChars

    // 代行機関が空の時または文字列の0のときに数値の0にする
    if (getFieldsValue.other_agency === '') {
      getFieldsValue.other_agency = 0
    } else if (getFieldsValue.other_agency === '0') {
      getFieldsValue.other_agency = 0
    }

    // チェックボックスの値をtrueまたはfalseにする
    if (getFieldsValue.other_agency !== 0) {
      getFieldsValue.StsMore = true
    } else {
      getFieldsValue.StsMore = false
    }

    if (getFieldsValue.sex === '男') {
      getFieldsValue.sex = 1
    } else if (getFieldsValue.sex === '女') {
      getFieldsValue.sex = 2
    }

    if (getFieldsValue.date_birth_era === '平成') {
      getFieldsValue.date_birth_era = 4
    } else if (getFieldsValue.date_birth_era === '昭和') {
      getFieldsValue.date_birth_era = 3
    } else if (getFieldsValue.date_birth_era === '大正') {
      getFieldsValue.date_birth_era = 2
    } else if (getFieldsValue.date_birth_era === '明治') {
      getFieldsValue.date_birth_era = 1
    }

    let params = {
      ...getFieldsValue,
      date_on: this.props.Li_Date,
      personal_number_id: this.props.Li_PersonalNum,
    }

    VouchersInputAction.onsave(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingFm: false }));
  }

  activeChange(e) {
    this.setState({ activeTab: e })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className="vouchers-input">
        <Card title="利用券・契約入力">
          <Form ref={this.formRef} autoComplete='off'>
            <Tabs defaultActiveKey={1} onChange={(e) => this.activeChange(e)} activeKey={this.state.activeTab}>
              {/* 利用券ページ */}
              <Tabs.TabPane tab="利用券" key={1}>
                <div className="box_inner_vertical">
                  {/* 上段 */}
                  <div className="box_inner_horizontal">
                    <Form.Item
                      label="利用券番号"
                      name="vouchers_docket_num"
                      {...smGrid}
                    >
                      <Input className="input-size-number-11" />
                    </Form.Item>

                    <Form.Item
                      label="交付年月日"
                      name="GrantDateChars"
                      {...smGrid}
                    >
                      <VenusDatePickerCustom format={DATE_FORMAT} style={{ width: 150 }} />
                    </Form.Item>
                  </div>

                  <Form.Item
                    label="受診券番号"
                    name="consult_ticket_docket_num"
                    {...smGrid}
                  >
                    <Input className="input-size-number-11" />
                  </Form.Item>

                  {/* 中段 */}
                  <div className="box_border_bottom_no_padding" />
                  <Form.Item
                    label="カナ氏名"
                    name="kana_name"
                    {...smGrid}
                  >
                    <div>{this.formRef.current?.getFieldValue('kana_name')}</div>
                  </Form.Item>

                  <Form.Item
                    label="性別"
                    name="sex"
                    {...smGrid}
                  >
                    <div>{this.formRef.current?.getFieldValue('sex')}</div>
                  </Form.Item>

                  <div className="box_inner_horizontal">
                    <Form.Item
                      label="生年月日"
                      name="date_birth_era"
                      {...smGrid}
                    >
                      <div>{this.formRef.current?.getFieldValue('date_birth_era')}</div>
                    </Form.Item>

                    <Form.Item name="date_japanese_calendar">
                      <div>{this.formRef.current?.getFieldValue('date_japanese_calendar')}</div>
                    </Form.Item>
                  </div>

                  <Form.Item
                    label="有効期限"
                    name="ExpirationDateChars"
                    {...smGrid}
                  >
                    <VenusDatePickerCustom
                      format={DATE_FORMAT}
                      style={{ width: 150 }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="指導区分"
                    name="insure_guide_division"
                    {...smGrid}
                  >
                    <Select style={{ width: '120px' }}>
                      <Select.Option value={''}>　</Select.Option>
                      <Select.Option value={'1'}>動機付支援</Select.Option>
                      <Select.Option value={'2'}>積極的支援</Select.Option>
                      <Select.Option value={'3'}>動機付相当</Select.Option>
                      <Select.Option value={'4'}>モデル実施</Select.Option>
                    </Select>
                  </Form.Item>
                  <div className="box_border_bottom_no_padding" />

                  {/* 下段 */}
                  <div className="box_inner_horizontal">
                    <Form.Item
                      label="負担内容"
                      name="specify_health_burden_sect"
                      {...smGrid}
                    >
                      <Select
                        style={{ width: '190px' }}
                        onChange={() => this.formRef.current?.setFieldsValue({ SpecificMedicalExambasicburdenContent: '' })}
                      >
                        <Select.Option value={0}>　</Select.Option>
                        <Select.Option value={1}>負担なし</Select.Option>
                        <Select.Option value={2}>定額負担</Select.Option>
                        <Select.Option value={3}>定率負担</Select.Option>
                        <Select.Option value={4}>保険者の上限負担</Select.Option>
                        <Select.Option value={5}>定率負担（上限以下）</Select.Option>
                        <Select.Option value={6}>定額負担（上限以下）</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="SpecificMedicalExambasicburdenContent">
                      <Input
                        className="input-size-number-6"
                        onPressEnter={(e) => this.SpecificMedicalExambasicburdenContent(e)}
                        onBlur={(e) => this.SpecificMedicalExambasicburdenContent(e)}
                      />
                    </Form.Item>

                    <Form.Item
                      label="上限額"
                      name="specify_insurer_pay_max"
                      {...smGrid}
                    >
                      <Input
                        type="text"
                        className="input-size-number-6"
                        maxLength={6}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    label="保険者番号"
                    name="insurer_num"
                    {...smGrid}
                  >
                    <Input.Search
                      className="input-search-size-number-10"
                      onDoubleClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 750,
                            component: (
                              <WS1290001_InsurerNumberInquiry
                                InputValue={this.formRef.current?.getFieldValue('insurer_num')}
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    insurer_num: output?.Lo_InsurerNum
                                  });
                                  this.closeModal()
                                }}
                              />),
                          },
                        })
                      }}

                      onPressEnter={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            width: 750,
                            visible: true,
                            component: (
                              <WS1290001_InsurerNumberInquiry
                                InputValue={this.formRef.current?.getFieldValue('insurer_num')}
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    insurer_num: output?.Lo_InsurerNum
                                  });
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }}

                      onSearch={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            width: 750,
                            visible: true,
                            component: (
                              <WS1290001_InsurerNumberInquiry
                                InputValue={this.formRef.current?.getFieldValue('insurer_num')}
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    insurer_num: output?.Lo_InsurerNum
                                  });
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        });
                      }}
                    />
                  </Form.Item>

                  <div className="box_inner_horizontal">
                    <Form.Item
                      label="代行機関"
                      name="other_agency"
                      {...smGrid}
                    >
                      <Input.Search
                        className="input-search-size-number-10"
                        onBlur={() => {
                          this.formRef.current.setFieldsValue({
                            StsMore: false
                          });
                          this.forceUpdate()
                        }}

                        onPressEnter={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 500,
                              visible: true,
                              component: (
                                <WS1302001_AgencyInquirySub
                                  onFinishScreen={output => {
                                    this.formRef.current.setFieldsValue({
                                      other_agency: output.Gl0AgencyNum,
                                      StsMore: true
                                    });
                                    this.closeModal();
                                  }}
                                />
                              )
                            }
                          })
                        }
                        }

                        onDoubleClick={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 500,
                              visible: true,
                              component: (
                                <WS1302001_AgencyInquirySub
                                  onFinishScreen={output => {
                                    this.formRef.current.setFieldsValue({
                                      other_agency: output.Gl0AgencyNum,
                                      StsMore: true
                                    });
                                    this.closeModal();
                                  }}
                                />
                              )
                            }
                          });
                        }}

                        onSearch={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 500,
                              visible: true,
                              component: (
                                <WS1302001_AgencyInquirySub
                                  onFinishScreen={output => {
                                    this.formRef.current.setFieldsValue({
                                      other_agency: output.Gl0AgencyNum,
                                      StsMore: true
                                    });
                                    this.closeModal();
                                  }}
                                />
                              )
                            }
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name='StsMore'
                    >
                      <Checkbox
                        style={{ marginTop: '5px' }}
                        checked={this.formRef.current?.getFieldValue("other_agency") !== ''}
                      />
                    </Form.Item>
                  </div>

                  <div className="box_border_bottom_no_padding" />
                  <Form.Item
                    label="契約情報"
                    name="Expression_54"
                    {...smGrid}
                  >
                    <div>{this.formRef.current?.getFieldValue("Expression_54")}</div>
                  </Form.Item>
                </div>
              </Tabs.TabPane>

              {/* 契約ページ */}
              <Tabs.TabPane tab="契約" key={2}>
                <div className="box_inner_vertical">
                  <div className="box_inner_horizontal">
                    <div className="div_label">初回徴収</div>
                    <div className="div_data" style={dataStyle}>{this.formRef.current?.getFieldValue("contract_compiled_name")}</div>
                  </div>
                  <div className="box_inner_horizontal">
                    <div className="div_label">ポイント</div>
                    <div className="div_data" style={dataStyle}>{this.formRef.current?.getFieldValue("active_constant_support_enforcement_point")}</div>
                  </div>
                  <div className="box_border_bottom_no_padding" />
                  <div className="box_inner_horizontal">
                    <div className="div_label">動機付</div>
                    <div className="div_label">単価</div>
                    {this.formRef.current?.getFieldValue("motivation_unit_price") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("motivation_unit_price")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                  <div className="box_inner_horizontal">
                    <div className="div_label" style={labelStyle}>初回</div>
                    {this.formRef.current?.getFieldValue("Expression_47") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_47")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("price_motivation_rate_01") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("price_motivation_rate_01")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    <div className="div_label">初回①</div>
                    {this.formRef.current?.getFieldValue("Expression_48") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_48")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("price_motivation_rate_04") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("price_motivation_rate_04")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                  <div className="box_inner_horizontal">
                    <div className="div_label" style={labelStyle}>終了</div>
                    {this.formRef.current?.getFieldValue("Expression_49") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_49")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("price_motivation_rate_02") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("price_motivation_rate_02")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                  <div className="box_border_bottom_no_padding" />
                  <div className="box_inner_horizontal">
                    <div className="div_label">積極的</div>
                    <div className="div_label">単価</div>
                    {this.formRef.current?.getFieldValue("actively_unit_price") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("actively_unit_price")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                  <div className="box_inner_horizontal">
                    <div className="div_label" style={labelStyle}>初回</div>
                    {this.formRef.current?.getFieldValue("Expression_50") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_50")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("aggressive_price_rate_01") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("aggressive_price_rate_01")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    <div className="div_label">初回①</div>
                    {this.formRef.current?.getFieldValue("Expression_51") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_51")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("aggressive_price_rate_04") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("aggressive_price_rate_04")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                  <div className="box_inner_horizontal">
                    <div className="div_label" style={labelStyle}>継続</div>
                    {this.formRef.current?.getFieldValue("Expression_52") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_52")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("aggressive_price_rate_02") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("aggressive_price_rate_02")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                  <div className="box_inner_horizontal">
                    <div className="div_label" style={labelStyle}>終了</div>
                    {this.formRef.current?.getFieldValue("Expression_53") !== '' ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("Expression_53")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                    {this.formRef.current?.getFieldValue("aggressive_price_rate_03") !== 0 ?
                      <div className="div_data" style={dataStyle}>
                        {this.formRef.current?.getFieldValue("aggressive_price_rate_03")}
                      </div> :
                      <div className="div_data" style={dataStyle}>--</div>
                    }
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>

            {/* ボタン */}
            <div className="box_border_bottom_no_padding" />
            <div className="box_button_bottom_right">
              <Button type="primary" onClick={() => this.contractSelect()}>契約選択</Button>
              {this.state.activeTab !== '2' ?
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => this.onsave()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
                :
                null
              }
            </div>
          </Form>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2657078_VouchersInput);
