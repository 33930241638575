import axios from 'configs/axios'

const API_LIST = {
  index: '/api/personal-reserve-process/visits-target-search-query',
}

const VisitsTargetSearchQueryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default VisitsTargetSearchQueryService
