import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

class WS1220005_SendDaySetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '送付日設定'

    this.state = {
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 親画面から送付日を設定
    this.formRef.current?.setFieldsValue({ DateSChar: this.props.DateSChar })
  }

  render() {
    return (
      <div className='send-day-setting'>
        <Card title='送付日設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div>
              <Form.Item
                name='DateSChar'
                label='送付日'
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format='YYYY/MM/DD'
                  style={{ width: '100%' }}
                  allowClear={true}
                />
              </Form.Item>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    const dateSChar = this.formRef.current?.getFieldsValue('DateSChar')
                    // 設定されている日付を返す
                    this.props.onFinishScreen(dateSChar)
                  }
                }}>
                <span className='btn_label'>
                  登録
                </span>
              </Button>

            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1220005_SendDaySetting)
