import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";

import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Card, Form, Input, Button, Select, message, Modal, InputNumber } from "antd";
import moment from 'moment';
import NumberFormat from 'components/Commons/NumberFormat'
import PaymentProcessSubAction from "redux/CounterBusiness/Counter/PaymentProcessSub.action";
import WS2621008_SplitPayment from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621008_SplitPayment.jsx';
import WS2622003_ReceiptProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622003_ReceiptProcessSub.jsx';
import ReceiptProcessSubAction from "redux/CounterBusiness/Counter/ReceiptProcessSub.action";

const labelCol = {
  labelCol: { style: { width: 50 } },
}
class WS2621001_PaymentProcessSub extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_ReserveNum: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '入金処理SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initialValues: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.loadData(this.props.Li_ReserveNum)
    }
  }

  componentDidMount() {
    this.loadData(this.props.Li_ReserveNum)
  }

  loadData(params) {
    PaymentProcessSubAction.getScreenData({ Li_ReserveNum: params, })
      .then(res => {
        if (res) {
          const data = res?.data;
          const obj = { Linked: '', Display: '' }
          const card = data.Card
          card.unshift(obj)
          this.setState({ initialValues: { ...data, Card: card } })
          this.renderForm()
        }
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })
  }

  renderForm() {
    const value = this.state.initialValues
    let DepositAmount = value?.DepositAmount
    if (DepositAmount !== '') {
      DepositAmount = NumberFormat.formatter(value?.DepositAmount)
    }

    this.setFormFieldValue('DepositAmount', DepositAmount)
    this.setFormFieldValue('AmountBilled', value?.AmountBilled)
    this.setFormFieldValue('PayType', value?.PayType)
    this.setFormFieldValue('PayDay', value?.PayDay)
    this.setFormFieldValue('Expression_6', 0)
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }

  payDateCharChange = () => {
    const params = {
      PayDateChar: moment(this.formRef.current.getFieldValue('PayDateChar')).format('YYYY/MM/DD'),
      PayDay: this.state?.initialValues.PayDay,
      StsDateConvert: this.state?.initialValues.StsDateConvert
    }

    PaymentProcessSubAction.payDateCharChange(params).then(res => {
      if (res && res.data) {
        this.setFormFieldValue('PayDay', res.PayDay)
      }
    })
  }

  checkWidthInWS2622003(props, res) {
    const params = {
      Li_ReserveNum: res.Li_ReserveNum,
      Li_SpecifiedIdentify: res.Li_SpecifiedIdentify
    }

    ReceiptProcessSubAction.getScreenData(params).then(item => {
      if (item) {
        const value = this.state.initialValues;
        const width25 = item.sts1 > 0 && item.sts2 === 0 && item.sts3 === 0 ? '25%' : null
        const width50 = item.sts1 > 0 && (item.sts2 > 0 || item.sts3 > 0) ? '60%' : null
        const width80 = item.sts1 > 0 && item.sts2 > 0 && item.sts3 > 0 ? '90%' : null
        const width = width25 ? width25 : (width50 ? width50 : (width80 ? width80 : ''))
        if (res && res.message == 'WS2622003_ReceiptProcessSub') {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: width,
              component:
                <WS2622003_ReceiptProcessSub
                  Li_ReserveNum={res.Li_ReserveNum}
                  Li_SpecifiedIdentify={res.Li_SpecifiedIdentify}
                  Li_Sts1={this.convertBoleanToNum(value.StsAnotherDay1)}
                  Li_Sts2={this.convertBoleanToNum(value.StsAnotherDate2)}
                  Li_Sts3={this.convertBoleanToNum(value.StsAnotherDate3)}
                  Li_BillingManageNum={this.state.initialValues.BillingManageNum}
                  onFinishScreen={(output) => {
                    if (output.StsSend) {
                      this.props.onFinishScreen({
                        Li_ReserveNum: this.props.Li_ReserveNum,
                        Li_PersonalNumId: item.PersonalNum,
                        Date: item.Date,
                        _Li_AcceptNum: item.AcceptNum,
                        nameScreen: 'WS2622003_ReceiptProcessSub',
                      });
                      this.props.onFinishScreen({
                        StsSend: true, nameScreen: 'WS2621001_PaymentProcessSub'
                      })
                    }
                    this.props.onFinishScreen()
                    this.closeModal()
                  }}
                />
            }
          })
        } else {
          this.props.onFinishScreen({
            StsSend: true, nameScreen: 'WS2621001_PaymentProcessSub'
          })
          this.closeModal()
        }
      }
    })
  }

  convertBoleanToNum = (input) => input ? 1 : 0

  depositConfirmed() {
    const item = this.state.initialValues;
    const value = this.formRef?.current?.getFieldValue()

    // console.log(values);
    console.log(value);

    let DepositAmount = parseInt(NumberFormat.parser(value.DepositAmount)) > 0 ? parseInt(NumberFormat.parser(value.DepositAmount)) : value.DepositAmount
    const params = {
      PayDay: value.PayDay ? value.PayDay : (item.PayDateChar ? item.PayDateChar : ''),
      AmountBilled: value.AmountBilled,
      DepositAmount: DepositAmount,
      ReceiptAmount: item.ReceiptAmount,
      Otsuri: item.Otsuri,
      PayManageNum: item.PayManageNum,
      StsAnotherDay1: this.convertBoleanToNum(item.StsAnotherDay1),
      Li_ReserveNum: item.Li_ReserveNum,
      BillingManageNum: item.BillingManageNum,
      PayType: value.PayType,
      Card: value.Card ? value.Card : '',
      RegisterClassify: item.RegisterClassify,
      Date: item.Date,
      StsPreReceipt: this.convertBoleanToNum(item.StsPreReceipt),
      ReceiptDateInitialValue: item.ReceiptDateInitialValue,
      StsAnotherDate2: this.convertBoleanToNum(item.StsAnotherDate2),
      StsAnotherDate3: this.convertBoleanToNum(item.StsAnotherDate3),
    }

    PaymentProcessSubAction.confirmF12(params).then(res => {
      this.checkWidthInWS2622003(this.props, res.data)
    })
      .catch(error => {
        Modal.error({
          content: '受取額が不足しています',
          okText: "はい",
          icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />
        })
      })

  }

  checkShowSplitPayment(value) {
    const {
      Personal1Claim, Personal1PayAlready,
      Person2Claim, Person2PayAlready,
      _3PersonClaim, Personal3PayAlready } = value
    const Personal1ClaimNum = Personal1Claim > 0 && Personal1PayAlready === 0 ? 1 : 0
    const Person2ClaimNum = Person2Claim > 0 && Person2PayAlready === 0 ? 1 : 0
    const _3PersonClaimNum = _3PersonClaim > 0 && Personal3PayAlready === 0 ? 1 : 0
    const checkShowScreen = (Personal1ClaimNum + Person2ClaimNum + _3PersonClaimNum) > 1
    return checkShowScreen ? true : false
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const value = this.state.initialValues;
    let CardList = this.state.initialValues.Card
    return (
      <div className="payment-process-sub">
        <Card title="入金処理">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              ...this.state.initialValues,
              PayType: 1,
              PayDateChar: moment(this.state.initialValues?.PayDateChar)
            }}
          >
            <div className="box_container">
              <div className="box_inner_horizontal">
                <Form.Item
                  style={{ textAlign: "left", marginRight: '20px' }}
                  name=""
                  label="請求額"
                >
                  <span style={{ fontSize: 15 }}>
                    {this.state.initialValues?.AmountBilled === undefined ? 0 :
                      (NumberFormat.formatter(this.state.initialValues?.AmountBilled) ? NumberFormat.formatter(this.state.initialValues?.AmountBilled) : 0)}
                  </span>
                </Form.Item>

                {this.checkShowSplitPayment(value) ?
                  <Form.Item>
                    <Button type="primary" style={{ float: "right" }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 300,
                            component:
                              <WS2621008_SplitPayment
                                Lio_StsPerson1={this.convertBoleanToNum(value.StsAnotherDay1)}
                                Lio_StsPerson2={this.convertBoleanToNum(value.StsAnotherDate2)}
                                Lio_StsPersonal3={this.convertBoleanToNum(value.StsAnotherDate3)}
                                Personal1PayAlready={value.Personal1PayAlready}
                                Person2PayAlready={value.Person2PayAlready}
                                Personal3PayAlready={value.Personal3PayAlready}
                                Personal1Claim={value.Personal1Claim}
                                Person2Claim={value.Person2Claim}
                                Person3Claim={value._3PersonClaim}
                                AmountBilled={value.AmountBilled}
                                DepositAmount={value.DepositAmount}
                                onOk={(output) => {
                                  if (output) {
                                    // let DepositAmount = String(output?.DepositAmount)
                                    const AmountBilled = NumberFormat.formatter(output?.AmountBilled);
                                    const DepositAmount = NumberFormat.formatter(output?.DepositAmount);

                                    this.setState({
                                      initialValues: {
                                        ...this.state.initialValues,
                                        StsAnotherDate3: output.StsAnotherDate3,
                                        StsAnotherDate2: output.StsAnotherDate2,
                                        StsAnotherDay1: output.StsAnotherDay1,
                                        DepositAmount: output.DepositAmount,
                                        AmountBilled: output.AmountBilled
                                      }
                                    })

                                    this.setFormFieldValue('Expression_6', NumberFormat.formatter(output?.DepositAmount - output?.AmountBilled))
                                    this.setFormFieldValue('AmountBilled', AmountBilled)
                                    this.setFormFieldValue('DepositAmount', DepositAmount)
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    });
                                  }
                                }}
                              />
                            ,
                          },
                        });
                      }}
                    >
                      分割入金
                    </Button>
                  </Form.Item>
                  :
                  null
                }
              </div>

              <div className="box_inner_horizontal">
                <Form.Item
                  name="PayType"
                  label="支　払"
                  style={{ marginRight: '25px' }}
                >
                  <Select
                    style={{ width: '120px' }}
                    onChange={(event) => this.setState({
                      initialValues: {
                        ...this.state.initialValues, PayType: event
                      }
                    })}
                  >
                    <Select.Option value={1}>現金</Select.Option>
                    <Select.Option value={2}>クレジット</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Card"
                  label="会　社"
                >
                  <Select
                    style={{ width: '120px' }}
                    disabled={this.state.initialValues.PayType === 1 ? true : false}
                  >
                    {CardList?.map((item, index) => (
                      <Select.Option key={index} value={item.Linked}>{item.Display}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="box_inner_horizontal">
                <Form.Item
                  name="DepositAmount"
                  label="受取額"
                  style={{ marginRight: '25px' }}
                >
                  <Input
                    style={{
                      textAlign: "right",
                      float: 'left',
                      width: '120px'
                    }}
                    onBlur={(event) => {
                      let result = event.target.value
                      if (!result.match(/^[0-9]+$/)) {
                        result = NumberFormat.changeHankaku(result)
                      }
                      result.replace(/\$\s?|(,*)/g, '')
                      result = NumberFormat.formatter(result)
                      const AmountBilled = this.state.initialValues?.AmountBilled;
                      let input = NumberFormat.parser(result);
                      this.setFormFieldValue('Expression_6', NumberFormat.formatter(input - AmountBilled))
                      this.setFormFieldValue('DepositAmount', NumberFormat.formatter(result))
                    }}
                    disabled={this.state.initialValues.PayType === 2 ? true : false}
                  />
                </Form.Item>

                <Form.Item
                  name="Expression_6"
                  label="釣　銭"
                >
                  <Input style={{ textAlign: 'right', width: '120px' }}
                    readOnly disabled={this.state.initialValues.PayType === 2 ? true : false}
                  />
                </Form.Item>
              </div>

              <div className="box_inner_horizontal">
                <Form.Item
                  name="PayDateChar"
                  label="入金日"
                  style={{ marginRight: '20px' }}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format={'YYYY/MM/DD'}
                    onBlur={this.payDateCharChange}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>

              <div className="box_button_bottom_right" style={{ paddingTop: 0 }}>
                <Button
                  type="primary"
                  // htmlType="submit"
                  disabled={this.state.initialValues?.DepositAmount === undefined ? true : false}
                  onClick={() => { this.depositConfirmed() }}
                >
                  確定
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={(output) => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
            // this.props.onFinishScreen()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2621001_PaymentProcessSub);
