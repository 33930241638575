import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Tabs, Row, Col, message } from 'antd'
import MedicalExamResultCorrectService from 'services/AssociationHealthInsuranceReport/AssociateInsureDataCreate/MedicalExamResultCorrectService'

const style = {
  label: {
    fontWeight: 'bold',
    color: '#14468C',
  },
}

class WS1226012_MedicalExamResultCorrect extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '健診結果訂正'

    this.state = {
      fields: [],
    }
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  getScreenData = () => {
    const data = JSON.parse(window.localStorage.getItem('WS1226_RESULT'))
    const fields = data[+this.props.seq - 1]
    this.setState({ fields })

    // MedicalExamResultCorrectService.getScreenData(params)
    //   .then((res) => {
    //     console.log(res.data)
    //     this.formRef.current.setFieldsValue({
    //       ...res.data,
    //     })
    //     this.forceUpdate()
    //   })
    //   .catch((error) => {
    //     const res = error.response
    //     if (!res || !res.data || !res.data.message) {
    //       message.error('エラーが発生しました')
    //       return
    //     }
    //     message.error(res.data.message)
    //   })
  }

  render() {
    const { fields } = this.state
    return (
      <Card title='健診結果訂正'>
        <div className='mb-3' style={{ padding: 12, border: '1px solid #5ba3f1' }}>
          <Form ref={this.formRef}>
            <Tabs defaultActiveKey='1'>
              <Tabs.TabPane tab='項目 1' key='1'>
                <Row className='mb-2'>
                  <Col span={8}>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>健診機関</label>
                      </Col>
                      <Col span={10}>
                        <Input type='text' value={fields[0]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right' }}>
                        <label style={style.label}>健診区分</label>
                      </Col>
                      <Col span={4}>
                        <Input type='text' value={fields[1]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right' }}>
                        <label style={style.label}>12区分</label>
                      </Col>
                      <Col span={4}>
                        <Input type='text' value={fields[2]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>氏名</label>
                      </Col>
                      <Col span={12}>
                        <Input type='text' value={fields[3]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right' }}>
                        <label style={style.label}>本人・配偶者</label>
                      </Col>
                      <Col span={5}>
                        <Input type='text' value={fields[4]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>生年月日</label>
                      </Col>
                      <Col span={7}>
                        <Input type='text' value={fields[5]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right' }}>
                        <label style={style.label}>性別</label>
                      </Col>
                      <Col span={4}>
                        <Input type='text' value={fields[5]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>保険者番号</label>
                      </Col>
                      <Col span={5}>
                        <Input type='text' value={fields[7]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>健保記号</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[8]} />
                      </Col>
                      <Col span={6}>
                        <Input type='text' value={fields[9]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>健保番号</label>
                      </Col>
                      <Col span={6}>
                        <Input type='text' value={fields[10]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>受診日</label>
                      </Col>
                      <Col span={7}>
                        <Input type='text' value={fields[11]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>診察指導</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[12]} />
                      </Col>
                      <Col span={3}>
                        <Input type='text' value={fields[13]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>身    長</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[14]} />
                      </Col>
                      <Col span={5} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[15]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>体    重</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[16]} />
                      </Col>
                      <Col span={5} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[17]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>標準体重</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[18]} />
                      </Col>
                      <Col span={5} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[19]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>Ｂ Ｍ Ｉ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[20]} />
                      </Col>
                      <Col span={5} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[21]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>腹    囲</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[22]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[23]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[24]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[25]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[26]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[27]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>内臓脂肪</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[28]} />
                      </Col>
                      <Col span={5} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[29]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>既往歴</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[30]} />
                      </Col>
                      <Col span={11} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[31]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>自覚症状</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[32]} />
                      </Col>
                      <Col span={11} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[33]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>他覚症状</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[34]} />
                      </Col>
                      <Col span={11} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[35]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>胸部・腹部所見</label>
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[36]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>視力裸/矯[右]</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[37]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Input type='text' value={fields[38]} />
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[39]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[40]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>[左]</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[41]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Input type='text' value={fields[42]} />
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[43]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 5 }}>
                        <Input type='text' value={fields[44]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>聴力右[1000]</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 5 }}>
                        <Input type='text' value={fields[45]} />
                      </Col>
                      [4000]
                      <Col span={4}>
                        <Input type='text' value={fields[46]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>聴力左[1000]</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 5 }}>
                        <Input type='text' value={fields[47]} />
                      </Col>
                      [4000]
                      <Col span={4}>
                        <Input type='text' value={fields[48]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <Row className='my-1' gutter={8}>
                          <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                            <label style={style.label}>血圧区分</label>
                          </Col>
                          <Col span={3}>
                            <Input type='text' value={fields[49]} />
                          </Col>
                          <Col span={12}>
                            <div style={style.label}>最高</div>
                            <div style={style.label}>最低</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={15}>
                        <Row className='my-1'>
                          <Col span={3}>
                            <Input type='text' value={fields[50]} />
                          </Col>
                          <Col span={5}>
                            <Input type='text' value={fields[51]} />
                          </Col>
                          <Col span={3}>
                            <Input type='text' value={fields[52]} />
                          </Col>
                          <Col span={5}>
                            <Input type='text' value={fields[53]} />
                          </Col>
                          <Col span={3}>
                            <Input type='text' value={fields[54]} />
                          </Col>
                          <Col span={5}>
                            <Input type='text' value={fields[55]} />
                          </Col>
                        </Row>
                        <Row className='my-1'>
                          <Col span={3}>
                            <Input type='text' value={fields[56]} />
                          </Col>
                          <Col span={5}>
                            <Input type='text' value={fields[57]} />
                          </Col>
                          <Col span={3}>
                            <Input type='text' value={fields[58]} />
                          </Col>
                          <Col span={5}>
                            <Input type='text' value={fields[59]} />
                          </Col>
                          <Col span={3}>
                            <Input type='text' value={fields[60]} />
                          </Col>
                          <Col span={5}>
                            <Input type='text' value={fields[61]} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>脂質区分</label>
                      </Col>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>採血時間(食後)</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[62]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={1} offset={3}>
                        <Input type='text' value={fields[63]} />
                      </Col>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>総ｺﾚｽﾃﾛｰﾙ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[64]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[65]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={6} style={{ textAlign: 'right', paddingRight: 5 }}>
                        <label style={style.label}>中性脂肪</label>
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[66]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[67]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[68]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[69]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[70]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[71]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={6} style={{ textAlign: 'right', paddingRight: 5 }}>
                        <label style={style.label}>HDLｺﾚｽﾃﾛｰﾙ</label>
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[72]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[73]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[74]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[75]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[76]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[77]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={6} style={{ textAlign: 'right', paddingRight: 5 }}>
                        <label style={style.label}>LDLｺﾚｽﾃﾛｰﾙ</label>
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[78]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[79]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[80]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[81]} />
                      </Col>
                      <Col span={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[82]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[83]} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right' }}>
                        <label style={style.label}>肝機能指導区分</label>
                      </Col>
                      <Col span={4}>
                        <Input type='text' value={fields[84]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＧＯＴ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[85]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                        <Input type='text' value={fields[86]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[87]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[88]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＧＰＴ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[89]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                        <Input type='text' value={fields[90]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[91]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[92]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>γ－ＧＰＴ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[93]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                        <Input type='text' value={fields[94]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[95]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[96]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＡＬＰ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[97]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 5 }}>
                        <Input type='text' value={fields[98]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[99]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[100]} />
                      </Col>
                    </Row>


                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>総蛋白</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[101]} />
                      </Col>
                      <Col span={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[102]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>アルブミン</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[103]} />
                      </Col>
                      <Col span={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[104]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>総ビリルビン</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[105]} />
                      </Col>
                      <Col span={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[106]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＬＤＨ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[107]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[108]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[109]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[110]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>アミラーゼ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[111]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[112]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[113]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[114]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>代謝系指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[115]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>空腹時血糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[116]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[117]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[118]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[119]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[120]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[121]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[122]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[123]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>随時血糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[124]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[125]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[126]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[127]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[128]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[129]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[130]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[131]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>負荷前・血糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[132]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[133]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[134]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[135]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>60分後・血糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[136]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[137]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[138]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[139]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>120分後・血糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[140]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[141]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[142]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[143]} />
                      </Col>
                    </Row>


                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＨｂＡ１Ｃ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[144]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[145]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[146]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[147]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[148]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[149]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[150]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[151]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿  糖</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[152]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[153]} />
                      </Col>
                      <Col span={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[154]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[155]} />
                      </Col>
                    </Row>

                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿酸指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[156]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿酸</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[157]} />
                      </Col>
                      <Col span={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[158]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿一般腎機能指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[159]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿蛋白</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[160]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[161]} />
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[162]} />
                      </Col>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[163]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>尿潜血</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[164]} />
                      </Col>
                      <Col span={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input type='text' value={fields[165]} />
                      </Col>
                    </Row>


                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>沈渣－赤血球</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[166]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>沈渣－白血球</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[167]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>沈渣－上皮細胞</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[168]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>沈渣－円柱</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[169]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>沈渣－その他</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[170]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ｸﾚｱﾁﾆﾝ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[171]} />
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[172]} />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={8} >
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>血液一般指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[173]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＨＴ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[174]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[175]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＨＢ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[176]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[177]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>赤血球数</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[178]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[179]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>白血球数</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[180]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[181]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>血小板数</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[182]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[183]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>血液像・ＢＡＳＯ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[184]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[185]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＥＯＳＩＮＯ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[186]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[187]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＳＴＡＢ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[188]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[189]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＳＥＧ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[190]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[191]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＮＥＵＴＲＯ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[192]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[193]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＬＹＭＰＨＯ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[194]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[195]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＭＯＮＯ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[196]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[197]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＯＴＨＥＲ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[198]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[199]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                      </Col>
                      <Col span={14} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[200]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>心電図指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[201]} />
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[202]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[203]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                      </Col>
                      <Col span={13} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[204]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>眼底指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[205]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>Ｋ．Ｗ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[206]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[207]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＳＣＨＥＩＥＨ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[208]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[209]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ＳＣＨＥＩＥＳ</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[210]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[211]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>SCOTT</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[212]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[213]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}></label>
                      </Col>
                      <Col span={14} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[214]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}></label>
                      </Col>
                      <Col span={14} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[215]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>肺機能指導区分</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[216]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>肺活量</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[217]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[218]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>一秒量</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[219]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[220]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>一秒率</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[221]} />
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[222]} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab='項目 2' key='2'>
                <Row className='mb-2'>
                  <Col span={8}>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>胸部Ｘ線指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[223]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[224]} />
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[225]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>胃部Ｘ線指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[226]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[227]} />
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[228]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>胃内視鏡指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[229]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[230]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>腹部超音波指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[231]} />
                      </Col>
                      <Col span={11} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[232]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>便潜血反応指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[233]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>便潜血１</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[234]} />
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[235]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>便潜血２</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[236]} />
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[237]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>直腸診指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[238]} />
                      </Col>
                      <Col span={11} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[239]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>乳房指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[240]} />
                      </Col>
                      <Col span={11} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[241]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>Ｘ線実施区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[242]} />
                      </Col>
                      <Col span={11} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[243]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>子宮指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[244]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[245]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>Ｂ型肝炎区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[246]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[247]} />
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[248]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>Ｃ型肝炎区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[249]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[250]} />
                      </Col>
                      <Col span={4} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[251]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>総合所見指導区分</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[252]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[253]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[254]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[255]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[256]} />
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[257]} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className='my-1'>
                  <Col span={3} style={{ textAlign: 'right', marginLeft: 10, paddingRight: 0 }}>
                    <label style={style.label}>注意事項</label>
                  </Col>
                  <Col span={20} style={{ paddingLeft: 15 }}>
                    <Input.TextArea rows='7' autosize='true' value={fields[258]} />
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <Col span={8}>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>判定区分[フォロー]</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[259]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>メタボ判定</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[260]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>階層化</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[261]} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <Col span={16}>
                    <Row className='my-1' gutter={24}>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>医師の判断</label>
                      </Col>
                      <Col span={8} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[262]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>医師名</label>
                      </Col>
                      <Col span={5} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[263]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>服薬[血圧]</label>
                      </Col>
                      <Col span={1} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[264]} />
                      </Col>
                      <Col span={9} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[265]} />
                      </Col>
                      <Col span={9} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[266]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>服薬[血糖]</label>
                      </Col>
                      <Col span={1} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[267]} />
                      </Col>
                      <Col span={9} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[268]} />
                      </Col>
                      <Col span={9} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[268]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>服薬[脂質]</label>
                      </Col>
                      <Col span={1} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[269]} />
                      </Col>
                      <Col span={9} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[270]} />
                      </Col>
                      <Col span={9} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[271]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={5} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>質問4-22</label>
                      </Col>
                      <Col span={6} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[272]} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab='項目 3' key='3'>
                <Row className='mb-2'>
                  <Col span={8}>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>LDLｺﾚｽﾃﾛｰﾙ</label>
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[273]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>Non-HDLC</label>
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[274]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ｸﾚｱﾁﾆﾝ可視</label>
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[275]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>対象者</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[276]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>実施理由</label>
                      </Col>
                      <Col span={10} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[277]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>eGFR</label>
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[278]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>対象者</label>
                      </Col>
                      <Col span={3} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[279]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>ﾍﾞｾｽﾀﾞ</label>
                      </Col>
                      <Col span={4} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[280]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>WongMitchell</label>
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[281]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>改変Davis</label>
                      </Col>
                      <Col span={7} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[282]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>対象者</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[283]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>問診13</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[284]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>問診16</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[285]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>情報提供</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[286]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>健康相談</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[287]} />
                      </Col>
                    </Row>
                    <Row className='my-1' gutter={24}>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <label style={style.label}>初回面談実施</label>
                      </Col>
                      <Col span={2} style={{ paddingRight: 0 }}>
                        <Input type='text' value={fields[288]} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </div>
      </Card>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1226012_MedicalExamResultCorrect)
