import axios from 'configs/axios'

const API_LIST = {
  index: '/api/associate-insure-data-create/code-setting',
  updateCodeSetting: '/api/associate-insure-data-create/code-setting/update-code-setting',
}

const CodeSettingService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async updateCodeSetting(params) {
    return axios.post(API_LIST.updateCodeSetting, params)
  },
}

export default CodeSettingService
