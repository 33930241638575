import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import { Card, Form, Button, Table, message, Modal, Tooltip, Menu, Descriptions, Dropdown } from 'antd'
import { MoreOutlined, EditOutlined, PrinterOutlined } from '@ant-design/icons'
import GetImage from 'constants/Images'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import WS0273001_VisitsTargetSearchQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273001_VisitsTargetSearchQuery.jsx'
import WS0714001_HistoryInputSub from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0714001_HistoryInputSub.jsx'
import WS2637036_DesignatedInspectVisitsHistory from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637036_DesignatedInspectVisitsHistory.jsx'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'

import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import WS2637031_CharacterStringSearch from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637031_CharacterStringSearch.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'

import WS2637046_TypeSelect from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637046_TypeSelect.jsx'
import PreviousGuideMatterInquiryAction from 'redux/InputBusiness/NotInputCheckCategory/PreviousGuideMatterInquiry.action'
import WS2641030_PreviousGuideMatterInquiry from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2641030_PreviousGuideMatterInquiry.jsx'
// import WS2635011_AutoJudge from 'pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS2635011_AutoJudge.jsx'
import WS0723001_AutoJudgeScreen from 'pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS0723001_AutoJudgeScreen.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'
import WS0061003_ConfirmCheckYesYesNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061003_ConfirmCheckYesYesNo.jsx'
import WS2637014_InspectResult from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637014_InspectResult.jsx'
import WS2637002_OverallResultDisplayChange from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637002_OverallResultDisplayChange.jsx'

import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'

const labelSize = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 70px)' } },
  style: { marginBottom: 0 }
}

class WS2637001_OverallResultDisplayInput extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Li_MenuAdminRights: PropTypes.any,
    Li_MenuAuthority: PropTypes.any,
    Li_SubjectNotBeChangedMode: PropTypes.any,
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '総合結果表示/入力'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      values: {},
      initialValues: {},

      categoryJudgeList: [], // カテゴリ別結果一覧
      InspectResult: [], // 検査別結果一覧
      guideCommentInputList: [], // 指導事項コメント一覧
      selectedInspectId: null,

      selectedRowKeys: [],
      selectedRows: [],
      SelectCategoryList: '',
      loadScreenData: false,
      AllSelect: true,
      selectedInspectRowKeys: [],
      selectedInspectRows: [],
      indexInspectTable: 0,
      HitoryCount: 0,
      isSelectCategoryList: 0,
      defaultCategoryList: '',
      checkCategoryAll: 0,
      Lio_FindingsCategoryUpdate: 0,
      error: { message: '', id: '' },
      ReserveNum: 0,
      guideCommentAll: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props?.Li_ReserveNum) {
      this.setStateValues('Li_ReserveNum', this.props.Li_ReserveNum)
      this.index()
    }
  }

  /**
   * 初期データ取得
   * @param {*} modalUpdateFlag
   */
  // index = (param) => {
  index = (modalUpdateFlag = false) => {
    // パラメータ取得
    const params = this.requestParamScreenData()

    // データ取得
    OverallResultDisplayInputAction.index(params)
      .then(async (res) => {
        // state変数：valuesに設定
        await this.setStateValuesAll(res)
        await this.setState({
          initialValues: res
        })

        if (modalUpdateFlag) {
          // 総合結果入力 モーダルに最新の総合判定を反映させる
          this.showWS2637002_OverallResultDisplayChange()
          // 最新の総合判定を取得したら、カテゴリ別判定結果と指導事項コメントは取得しない
          return
        }

        // カテゴリ別判定結果の一覧を取得
        await this.getCategoryJudgeList()
        // 指導事項コメントを取得
        await this.getGuideCommentInput()
      })
  }

  /**
   * データチェック
   * @param {*} value
   * @param {*} type
   * @returns
   */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  /**
   * 空チェック
   * @param {*} val
   * @returns
   */
  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * 共通でparamsに
   * @returns
   */
  getParamObject() {
    let result = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.checkData(this.props.Li_CourseLevel, 'number'),
      Li_PatternCode: this.state.values.pattern_code,
      Li_MenuOption: this.checkData(this.props.Li_MenuOption, 'number'),
      Li_MenuAdminRights: this.checkData(this.props.Li_MenuAdminRights, 'number'),
      Li_MenuAuthority: this.checkData(this.props.Li_MenuAuthority, 'number'),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
      // Li_ContractStartDate: this.state.values.contract_start_date_on,
    }
    return result
  }

  requestParamScreenData(param = undefined) {
    const NonInput = this.state.values.NonInput
    const Outliers = this.state.values.Outliers
    const HistoryAdded = this.state.values.HistoryAdded
    const isNonInput = (NonInput === 1) ? { NonInput } : { Outliers }
    const params = {
      ...this.getParamObject(),
      HistoryAdded
    }
    const request = param === undefined ? params : { ...params, ...isNonInput }
    return request
  }

  /**
   * カテゴリ別判定結果の一覧を取得
   * @param {*} modalUpdateFlag
   */
  getCategoryJudgeList = (modalUpdateFlag = false) => {
    const HistoryAdded = this.state.values.HistoryAdded
    // const SelectCategoryList = !record.changeCategoryJudge ? record.SelectCategoryList : this.state.values.SelectCategoryList
    const SelectCategoryList = this.state.values.SelectCategoryList

    const params = {
      ...this.getParamObject(),
      Li_PersonalNum: this.state.values.personal_number_id,
      Li_VisitDate: moment(this.state.values.DateChar).format('YYYY/MM/DD'),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
      SelectCategoryList: SelectCategoryList,
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeCategoryJudge: this.state.values.StsInputModeCategoryJudge,
      InspectNormalValueUpdateOp: this.state.values.InspectNormalValueUpdateOp,
      HandInputConfirm: this.state.values.HandInputConfirm ? 1 : 0,
      pattern_code: this.state.values.pattern_code,
      Li_PatternCode: this.state.values.pattern_code,
      HistoryAdded,
      AllSelect: this.state.values.AllSelect,
      pre1ReserveNum: this.state.values.pre1ReserveNum,
      pre1VisitDate: this.state.values.pre1VisitDate,
      pre2ReserveNum: this.state.values.pre2ReserveNum,
      pre2VisitDate: this.state.values.pre2VisitDate,
    }

    OverallResultDisplayInputAction.getCategoryJudgeList(params)
      .then((res) => {
        if (res) {
          const data = [...res.categoryJudgeList]
          this.setState({
            categoryJudgeList: data,
          })

          if (modalUpdateFlag) {
            // 子モーダルでテーブル更新がされた場合
            const index = data.findIndex(x => x.id === this.state.selectedInspectId)
            let record = null
            if (index > -1) {
              record = { ...data[index] }
            }
            //  判定一覧モーダルに最新カテゴリ別判定を反映させる
            this.showWS2637014_InspectResult(record)
          }
        }
      })
  }

  /**
   * 指導事項コメントを取得
   * @param {*} modalUpdateFlag
   */
  getGuideCommentInput = (modalUpdateFlag = false) => {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
    }

    OverallResultDisplayInputAction.getGuideCommentInput(params)
      .then((res) => {
        if (res) {
          // 返却値をコピー
          let data = [...res]

          // コメントまとめ用変数
          let commentAll = ''
          data.forEach(item => {
            // general_comment_codeを加工
            item.general_comment_code = (item.general_comment_code === 0) ? '' : item.general_comment_code
            // １つの変数にコメントをまとめる
            commentAll += (item.overall_comment + '\r\n')
          })

          this.setState({
            guideCommentInputList: data,
            guideCommentAll: commentAll
          })

          if (modalUpdateFlag) {
            // 総合結果入力 モーダルに最新の指導事項コメントを反映させる
            this.showWS2637002_OverallResultDisplayChange()
          }
        }
      })
  }

  EventChangeStsInputModeAll() {
    const CheckNum = this.state.values.StsInputModeAll ? 1 : 0
    const params = {
      ...this.getParamObject(),
      StsInputModeAll: CheckNum
    }
    const value = this.state.values.SelectCategoryList
    const SelectCategoryList = value === '' ? '' : value
    OverallResultDisplayInputAction.EventChangeStsInputModeAll(params)
      .then((res) => {
        this.getScreenDataForHitoryBtn({ eventHitory: 1, SelectCategoryList })
      })
  }

  EventChangeGuideItemDisplay = () => {
    const CheckNum = this.state.values.GuideItemDisplay ? 1 : 0
    const params = {
      ...this.getParamObject(),
      GuideItemDisplay: CheckNum
    }
    const value = this.state.values.SelectCategoryList
    const SelectCategoryList = value === '' ? '' : value
    OverallResultDisplayInputAction.EventChangeGuideItemDisplay(params)
      .then((res) => {
        this.getScreenDataForHitoryBtn({ eventHitory: 1, SelectCategoryList })
      })
  }

  changeSuffixW1InspectJugde(record, value) {
    const params = {
      ...this.getParamObject(),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      W1_inspect_judge: value,
      id: this.checkData(record.id, 'number')
    }

    if (value.length > 0) {
      OverallResultDisplayInputAction.ChangeW1InspectJudge(params)
        .then((res) => {
          this.saveRowInspectResult(record, value)
        })
    }
  }

  saveRowInspectResult(record, value) {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      W1_inspect_judge: value,
      id: this.checkData(record.id, 'number'),
      ResultValueRBefore: this.checkData(record.ResultValueRBefore, 'text'),
      W1_change_flg: this.checkData(record.W1_change_flg, 'number'),
      W1_result_val: this.checkData(record.W1_result_val, 'text'),
      InspectJudgeRBefore: this.checkData(record.InspectJudgeRBefore, 'text'),
    }
    OverallResultDisplayInputAction.SaveRowInspectResult(params)
      .then((res) => {
        const value = this.state.values.SelectCategoryListForLastTimeDo
        // const SelectCategoryList = value

        // モーダルで呼ぶ
        // this.ListdataInspectResult(record, SelectCategoryList)
      })
  }

  clickAcceptedNoIt() {
    const params = {
      DateChar: this.checkData(moment(this.state.values.DateChar).format('YYYY/MM/DD'), 'text'),
      AcceptNum: this.checkData(this.state.values.AcceptNum, 'number'),
    }
    OverallResultDisplayInputAction.AcceptedNoIt(params).then((res) => {
      this.setState({
        ReserveNum: res && res.ReserveNum,
        CourseLevel: res && res.CourseLevel, isSelectCategoryList: 0, checkCategoryAll: 0
      })
      this.setStateValues('HistoryAdded', 0)
      this.setStateValues('Li_ReserveNum', res && res.ReserveNum)
      this.setStateValues('AllSelect', 0)
      this.index()
    })
  }

  clickAcceptedNo() {
    const acceptedVal = this.state.values.AcceptNum
    const AcceptNum = acceptedVal ? acceptedVal : 0
    const params = {
      DateChar: this.checkData(moment(this.state.values.DateChar).format('YYYY/MM/DD'), 'text'),
      AcceptNum: AcceptNum,
    }
    OverallResultDisplayInputAction.acceptedNo(params).then((res) => {
      this.setState({
        ReserveNum: res && res.ReserveNum,
        CourseLevel: res && res.CourseLevel, isSelectCategoryList: 0, checkCategoryAll: 0
      })
      this.setStateValues('HistoryAdded', 0)
      this.setStateValues('Li_ReserveNum', res && res.ReserveNum)
      this.setStateValues('AllSelect', 0)
      this.index()
    })
  }

  clickAccepted() {
    const params = {
      DateChar: this.checkData(moment(this.state.values.DateChar).format('YYYY/MM/DD'), 'text'),
      AcceptNum: this.checkData(this.state.values.AcceptNum, 'number'),
    }
    OverallResultDisplayInputAction.accepted(params).then((res) => {
      this.setState({
        ReserveNum: res && res.ReserveNum,
        CourseLevel: res && res.CourseLevel, isSelectCategoryList: 0, checkCategoryAll: 0
      })
      this.setStateValues('HistoryAdded', 0)
      this.setStateValues('SelectCategoryList', '')
      this.setStateValues('Li_ReserveNum', res && res.ReserveNum)
      this.setStateValues('AllSelect', 0)
      this.setStateValues('DateChar', res && res.DateChar)
      this.index()
    })
  }

  getScreenDataForHitoryBtn(param) {
    this.setState({ loadScreenData: true })
    OverallResultDisplayInputAction.GetScreenData(this.requestParamScreenData(param))
      .then((res) => {
        this.setState({ initialValues: res })
        const SelectCategoryList = (input) => input == 1 || !input ? param.SelectCategoryList : ''
        const data = { ...res, SelectCategoryList: SelectCategoryList(param.eventHitory) }
        this.setStateValuesAll(data)
        this.getCategoryJudgeList({
          isSelectCategoryList: 1,
          SelectCategoryList: param.SelectCategoryList,
        })
        this.getGuideCommentInput()
      })
  }

  Ctrl_F(record) {
    const params = {
      ...this.getParamObject(),
      W1_sect_display_num: this.checkData(record.W1_sect_display_num, 'number'),
      W1_inspect_item_display_num: this.checkData(record.W1_inspect_item_display_num, 'number'),
      CharStringSearch: this.checkData(record.Lio_SearchString, 'text')
    }
    OverallResultDisplayInputAction.Ctrl_F(params).then((res) => {
    })
  }

  requestW1_result_val(record) {
    const params = {
      W1_sect_display_num: this.checkData(record.W1_sect_display_num, 'number'),
      W1_inspect_item_display_num: this.checkData(record.W1_inspect_item_display_num, 'number'),
      Li_Gender: this.state.values.Li_Gender,
      Li_DateOfBirth: this.state.values.Li_DateOfBirth,
      W1_inspect_judge: this.checkData(record.W1_inspect_judge, 'text'),
      W1_result_val: this.checkData(record.W1_result_val, 'text'),
      remarks: this.checkData(record.remarks, 'text'),
      StsAutomaticCalculate: this.checkData(record.StsAutomaticCalculate, 'number'),
      Li_ReserveNum: this.checkData(Number(this.state.values.Li_ReserveNum), 'number'),
      Li_CourseLevel: this.checkData(Number(this.state.values.Li_CourseLevel), 'number'),
      W1_inspect_cd: this.checkData(record.W1_inspect_cd, 'number'),
      visit_date_on: this.state.values.visit_date_on,
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      judgment_code: this.checkData(record.judgment_code, 'number'),
      NormalJudge: this.state.values.NormalJudge,
      UpAndDownDisplay: this.state.values.UpAndDownDisplay,
      UpAndDown: this.checkData(record.UpAndDown, 'text'),
      id: this.checkData(record.id, 'number'),
      W1_display_remark: this.checkData(record.W1_display_remark, 'text'),
    }
    return params
  }

  clickPreFixW1_result_val(record, value) {
    const params = {
      ...this.getParamObject(),
      ...this.requestW1_result_val(record)
    }
    OverallResultDisplayInputAction.PrefixW1ResultValue(params).then((res) => {
      const data = res && res.data
      if (record.StsInputModeInspect == '1') {
        if (record?.StsAutomaticCalculate === 1) {
          record.W1_result_val = data.W1_result_val
          this.saveRowInspectResult(record, record.W1_inspect_judge)
        } else {
          if (!value) {
            if (record.W1_result_val != value) {
              record.W1_result_val = value
              this.saveRowInspectResult(record, record.W1_inspect_judge)
            }
          } else {
            record.W1_result_val = value
            this.saveRowInspectResult(record, record.W1_inspect_judge)
          }
        }
      }
    })
  }

  clickSuffixW1_result_val(record, index) {
    const params = {
      ...this.getParamObject(),
      ...this.requestW1_result_val(record)
    }
    OverallResultDisplayInputAction.SuffixW1ResultValue(params)
      .then((res) => {
        this.showInspectCmtSearchQuery(record, index)
        this.findingsEditingBefore(record)

      })
  }

  findingsEditingAfter(record, output) {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      display_order: record && record.display_order,
      W1_sect_display_num: record && record.W1_sect_display_num,
      W1_inspect_item_display_num: record && record.W1_inspect_item_display_num,
      pattern_code: this.state.values.pattern_code,
      W1_category_cd: record && record.W1_category_cd,
      personal_number_id: this.state.values.personal_number_id,
      GuideHowToAddOp: this.state.values.GuideHowToAddOp,
      StsInputModeFindings: this.state.values.StsInputModeFindings,
      FindingsCategoryUpdate: this.state.Lio_FindingsCategoryUpdate
    }
    const value = this.state.values.SelectCategoryListForLastTimeDo
    const SelectCategoryList = value
    OverallResultDisplayInputAction.findingsEditingAfter(params).then((res) => {

      // モーダルで呼ぶ
      // this.ListdataInspectResult(record, SelectCategoryList)
    })
  }

  clickSuffixW1InspectJugde(record) {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      W1_inspect_judge: this.checkData(record.W1_inspect_judge, 'text'),
      id: this.checkData(record.id, 'number')
    }
    OverallResultDisplayInputAction.SuffixW1InspectJugde(params).then((res) => {
    })
  }

  changeCategoryJudge(record, value) {
    const params = {
      ...this.getParamObject(),
      category_code: this.checkData(record.category_code, 'number'),
      id: this.checkData(record.id, 'number'),
      category_judge: this.checkData(value, 'text')
    }
    OverallResultDisplayInputAction.changeCategoryJudge(params).then((res) => {
      this.saveCategoryRow(record, value, true)
    }).catch((err) => {
      const res = err.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
        return
      }
    })
  }

  selectInspcectListInCategory(record) {
    const value = `${record.categoryCodeStr},`
    const SelectCategoryList = value === '' ? this.state.defaultCategoryList : value
    this.setStateValues('displayOrder', record.display_order)
    this.setStateValues('SelectCategoryListForLastTimeDo', value)
    // モーダルで呼ぶ
    // this.ListdataInspectResult(record, SelectCategoryList)
  }

  EventChangeW3AutoBasicJudge(value) {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
      judgment_basic: this.checkData(value.judgment_basic, 'text'),
    }
    OverallResultDisplayInputAction.checkJudgmentExist(params).then((res) => {
    }).catch((err) => {
      const res = err.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
        return
      }
      Modal.error({ content: res.data.message })
      const data = this.state.guideCommentInputList
      const index = data.findIndex(x => x.id === value.id)
      data[index].judgment_basic = ''
      this.forceUpdate()
    })
  }

  EventChangeW3OverallCmt(record, value) {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
      input_flag: this.checkData(value.input_flag, 'text'),
    }
    OverallResultDisplayInputAction.EventChangeW3OverallCmt(params).then((res) => {
    })
  }

  EventChangeW3GeneralCmtCd(record, value) {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
      input_flag: this.checkData(value.input_flag, 'text'),
      general_comment_code: this.checkData(value.general_comment_code, 'text'),
    }
    OverallResultDisplayInputAction.EventChangeW3GeneralCmtCd(params).then((res) => {
    })
  }

  GuideCommentInputSaveRowData(value, isInsert) {
    const serial_number = !isInsert ? value.serial_number : value.serial_number
    const request = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
      serial_number: this.checkData(serial_number, 'text'),
      auto_judgment_weight: this.checkData(value.auto_judgment_weight, 'text'),
      general_comment_code: this.checkData(value?.general_comment_code, 'text'),
      overall_comment: this.checkData(value?.overall_comment, 'text'),
      input_flag: this.checkData(value.input_flag, 'text'),
      course_level_2: this.checkData(value.course_level_2, 'text'),
      course_level_3: this.checkData(value.course_level_3, 'text'),
      judgment_category_code: this.checkData(value.judgment_category_code, 'text'),
      judgment_basic: this.checkData(value.judgment_basic, 'text'),
      auto_judgment_output_type: this.checkData(value.auto_judgment_output_type, 'text'),
    }
    let params = !isInsert ? request : { ...request, id: this.checkData(value.id, 'number'), }
    OverallResultDisplayInputAction.GuideCommentInputSaveRow(params).then((res) => {
      this.setStateValues('StsLeadershipMattersChange', res && res?.data?.StsLeadershipMattersChange)
      this.getGuideCommentInput()
    })
  }

  selectedRowCategoryJude(res) {
    const checkSelectAll = res.AllSelect === 1 ? true : false
    let arrTempRecord = res.categoryJudgeList
    if (checkSelectAll) {
      const keys = res.categoryJudgeList.map(s => s.id)
      this.setState({ selectedRowKeys: keys, selectedRows: arrTempRecord })
    } else {
      this.setState({ selectedRowKeys: [], selectedRows: [] })
    }
  }

  insertData(record, index) {
    const value = this.state.guideCommentInputList[index]
    this.GuideCommentInputSaveRowData(value, true)

  }

  onFinish(values) {

  }

  /**
   * state変数：values の更新
   * @param {*} namePath
   * @param {*} value
   */
  setStateValues = (namePath, value) => {
    this.setState({
      values: {
        ...this.state.values, //現行のvaluesをコピー
        [namePath]: value
      }
    })
  }

  /**
   * state変数：values の更新 ※返却値のデータをまるごと
   * @param {*} data
   */
  setStateValuesAll = (data) => {
    if (data) {
      const keys = !this.isEmpty(Object.keys(data)) ? Object.keys(data) : []
      const values = !this.isEmpty(Object.values(data)) ? Object.values(data) : []
      let dataObj = null, key = null, value = null
      if (values.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          key = keys[i]
          value = values[i]

          // 日付の値はフォーマットを変えて設定する
          if (key === 'DateChar') {
            if (value) {
              value = moment(value)
            }
          } else if (key === 'birthday_on') {
            if (value) {
              value = moment(value).format('NNy/MM/DD')
            }
          } else if (key === 'pre1VisitDate' || key === 'pre2VisitDate') {
            if (value) {
              value = moment(value).format('YYYY/MM/DD')
            }
          }

          dataObj = {
            ...dataObj,
            [key]: value
          }
        }
        this.setState({
          values: {
            ...this.state.values,
            ...dataObj
          }
        })
      }
    }
  }

  ShowWS2637046_TypeSelect(record, index) {
    const TypeSelectList = this.state.values.TypeSelectList
    if (TypeSelectList !== '') {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 400,
          component:
            <WS2637046_TypeSelect
              Li_TypeSelectList={this.state.values.TypeSelectList}
              Lio_Type={record?.auto_judgment_output_type}
              onScreenFinish={(output) => {
                const data = this.state.guideCommentInputList
                data[index].auto_judgment_output_type = output?.Lio_Type
                // this.setStateValues('guideCommentInputList', data)
                const value = this.state.guideCommentInputList[index]
                this.EventChangeW3AutoBasicJudge(value)
                this.closeModal()
                this.forceUpdate()

              }}
            />
          ,
        },
      })
    }
  }

  showWS0612002_CharacterStringSearch(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <WS2637031_CharacterStringSearch
            onFinishScreen={(output) => {
              const params = {
                W1_sect_display_num: record.W1_sect_display_num,
                W1_inspect_item_display_num: record.W1_inspect_item_display_num,
                Lio_SearchString: output.Lio_SearchString
              }
              if (output.Lio_SearchString.length > 0) {
                this.Ctrl_F(params)
              }

            }}
          />
        ,
      },
    })
  }

  /**
   * 検査コメント照会　モーダル
   * @param {*} record
   * @param {*} index
   */
  showInspectCmtSearchQuery = (record, index) => {
    if (record.textPositionExamType) {
      if (record.StsInputModeInspectCmtChiba === 1 && record.StsInspectCmtInfo) {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 800,
            component:
              <WS0274001_InspectCmtSearchQuery
                Li_PatternCode={record.pattern_code}
                Li_CategoryCode={record.category_code}
                Li_CategoryName={record.category_name}
                Lio_StsNotesChange={record.StsNotesChange}
                Lio_CmtClassify={record.exam_comment_code}
                LnkOutInspectCmtScreen={record.W1_result_val}
                onFinishScreen={(output) => {
                  const data = output.recordData
                  const InspectResult = this.state.values.InspectResult
                  InspectResult[index].W1_result_val = data.exam_comment_screen
                  this.setStateValues('InspectResult', InspectResult)
                  this.saveRowInspectResult(InspectResult[index], record.W1_inspect_judge)
                  this.closeModal()
                }}
              />
            ,
          },
        })
      }
    }
  }

  /**
   * 結合印刷指示 モーダル
   */
  showWS3020036_CoupledPrintInstruction = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            Li_Parameters={this.state.values.Li_Parameters}
            onFinishScreen={(obj) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   *　判定一覧モーダルを開く
   * @param {*} data
   * @param {*} index
   */
  showWS0285001_JudgeQuery(data, index) {
    if (this.state.values.StsInputModeAll === 1) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 500,
          component: (
            <WS0285001_JudgeQuery
              Li_JudgeLevel={data?.judgment_level_division}
              Lio_Judge={data?.category_judge}
              onFinishScreen={(obj) => {
                const value = this.state.guideCommentInputList[index]
                if (value && !value?.isNew) {
                  this.EventChangeW3AutoBasicJudge(value)
                }
              }}
            />
          ),
        },
      })
    }
  }

  findingEditLastTimeDo(record, output) {
    if (output && output.Lio_FindingsCategoryChange) {
      this.setState({ Lio_FindingsCategoryUpdate: output.Lio_FindingsCategoryChange })
    } else {

      this.findingsEditingAfter(record, output)
      this.closeModal()
    }
  }

  /**
   * 前回指導事項照会のモーダルを開く
   * @param {*} data
   */
  showWS02641030_PreviousGuideMatterInquiry(data) {
    const params = {
      ReserveNumOnceBefore: this.state.values.ReserveNumOnceBefore,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
    }
    PreviousGuideMatterInquiryAction.BeforeCallScreen(params)
      .then((res) => {
        const message = '前回指導事項は存在しません'
        if (res && res.message === message) {
          Modal.warning({ component: res.message, okText: 'は　い' })
        } else {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 700,
              component: (
                <WS2641030_PreviousGuideMatterInquiry
                  Li_PreviousGuideMatters={res?.PreviousGuidanceMatters}
                  Li_ReserveNum={this.state.values.ReserveNumOnceBefore}
                  onFinishScreen={(obj) => {
                    const params2 = {
                      ReserveNumOnceBefore: obj.ReserveNumOnceBefore,
                      Lo_StsPreviousDoRun: obj.Lo_StsPreviousGuide
                    }
                    PreviousGuideMatterInquiryAction.AfterCallScreen(params2).then((res) => {
                      this.getGuideCommentInput()
                      this.closeModal()
                    })

                  }}
                />
              ),
            },
          })
        }
      }).catch((err) => {
        const message = '前回指導事項は存在しません'
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        if (res && res.data && res.data.message === message) {
          Modal.warning({ content: res.data.message, okText: 'は　い' })
        }
      })
  }

  showWS0272001_CautionGuideNotesSearchQuery(record, index) {
    const value = this.state.guideCommentInputList
    if (this.state.values.StsInputModeAll === 1) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 700,
          component: (
            <WS0272001_CautionGuideNotesSearchQuery
              Li_PatternCode={this.state.values.pattern_code}
              LnkOutCmtCode={this.checkData(value && value[index]?.general_comment_code, 'number')}
              onFinishScreen={(obj) => {
                value[index].general_comment_code = obj.Lo_LnkOutCmtCode
                value[index].overall_comment = obj.Lo_recordData?.comment_content
                // this.setStateValues('guideCommentInputList', value)
                this.forceUpdate()
                if (value && !value[index].isNew) {
                  this.EventChangeW3AutoBasicJudge(value[index])
                }
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  changeDataUpdateF12 = () => {
    let categoryJudgeList = this.state.categoryJudgeList
    const changeCategoryJudge = categoryJudgeList.some(s => s.changeColorNum === 82)
    let InspectResult = this.state.values.InspectResult

    let changeInspectResult = false
    if (InspectResult) {
      changeInspectResult = InspectResult.some(s => s.Expression_83 === 82)
    }
    const StsLeadershipMattersChange = this.state.values.StsLeadershipMattersChange

    if (!StsLeadershipMattersChange || changeCategoryJudge || changeInspectResult) {
      return true
    } else {
      return false
    }
  }

  /**
   * 自動判定
   */
  showWS0723001_AutoJudgeScreen = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0723001_AutoJudgeScreen
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_TrueOrFalse={1} //メモリーテーブルを使用しない為には「1」にする
            TotalJudge={this.state.values.OverallJudgeG}
            onFinishScreen={(output) => {
              if (output) {
                // 自動判定でデータが変更になった場合
                // データ再取得
                this.index()
                if (this.props.onUpdate) {
                  // 親画面の受診者一覧もデータを再取得させる
                  this.props.onUpdate()
                }
              }
              this.closeModal()
            }} />
        ),
      },
    })
  }

  ShowWS0605127_ContractLineItemDisplay() {
    const ReserveNum = this.state.ReserveNum === 0 ? this.props.Li_ReserveNum : this.state.ReserveNum
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0605127_ContractLineItemDisplay
            Li_ContractType={this.state.values.contract_type}
            Li_ContractOrgCode={this.state.values.contract_organization_code}
            Li_ContractStartDate={this.state.values.contract_start_date_on}
            Li_ContractNum={this.state.values.contract_number}
            Li_MenuOption={''}
            Li_MenuAdminRights={''}
            Li_MenuAuthority={''}
            Li_SubjectNotBeChangedMode={''}
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_ReserveNum={this.checkData(ReserveNum, 'number')}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 個人情報照会　モーダルを表示
   */
  showWS2584019_PersonalInfoInquirySub() {
    this.setState({
      ...this.state,
      childModal: {
        width: 1000,
        visible: true,
        component:
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={this.state.values.personal_number_id}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
      },
    })
  }

  showWS0273001_VisitsTargetSearchQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1600,
        component:
          <WS0273001_VisitsTargetSearchQuery
            // LinkOutReserveNum
            // Li_StateFlagSpecify
            Li_DateSpecify={this.state.values.DateChar}
            Li_StsConditionSpecify={1}
            // Li_StsReceiptAmountDisplay
            onFinishScreen={(output) => {
              const value = output.recordData
              this.setState({
                initialValues: output.recordData,
                ReserveNum: output.LnkOutReserveNum
              })
              this.setStateValues('AcceptNum', value.receipt_number)
              this.setStateValues('DateChar', moment(value.visit_date_on))
              this.setStateValues('Li_ReserveNum', value.reservation_number)
              setTimeout(() => {
                this.setStateValues('HistoryAdded', 0)
                this.setStateValues('SelectCategoryList', '')
                this.index()
                this.getCategoryJudgeList()
                this.getGuideCommentInput()
                this.closeModal()
              }, 200)
            }}
          />
        ,
      },
    })
  }

  /**
   * 既往歴入力　モーダル
   */
  showWS0714001_HistoryInputSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 950,
        component:
          <WS0714001_HistoryInputSub
            Li_PersonalNum={this.state.values.personal_number_id}
            LnkinQueryMode={this.state.values.StsInputModeAll}
            Li_HistoryList={this.state.values.CurrentHistoryListOp}
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 指定検査受診履歴　モーダル
   */
  showWS2637036_DesignatedInspectVisitsHistory = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component:
          <WS2637036_DesignatedInspectVisitsHistory
            Li_PersonalNum={this.state.values.personal_number_id}
            Li_VisitDate={this.state.values.visit_date_on}
            Li_InspectHistoryOp={this.state.values.InspectHistoryOp}
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 紹介状抽出保守　モーダル
   */
  showWS0898001_IntroduceLetterExtractMaintain = () => {
    console.log(this.state.values);

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0898001_IntroduceLetterExtractMaintain
            Li_PersonalNum={this.state.values.personal_number_id}
            Li_VisitDate={this.state.values.visit_date_on}
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            values={this.state.values}
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  LastTimeDos(record) {
    if (record.StsPreviousDoEnable === 1) {
      const StsAutomaticCalculate = record && record.StsAutomaticCalculate
      const remarks = record && record.remarks
      const NotRemark = (value) => value === null || value === undefined || value === '' ? true : false
      const NotStsAutomaticAndRemark = (StsAutomaticCalculate === 0 && NotRemark(remarks))
      if (NotStsAutomaticAndRemark) {
        Modal.confirm({
          content: '前回値を取得しますか?', okText: 'はい',
          onOk: () => {
            const params = {
              ...this.getParamObject(),
              Li_JudgeLevel: this.state.values.Li_JudgeLevel,
              display_order: record && record.display_order,
              W1_sect_display_num: record && record.W1_sect_display_num,
              W1_inspect_item_display_num: record && record.W1_inspect_item_display_num,
              StsAutomaticCalculate: record && record.StsAutomaticCalculate,
              remarks: record && record.remarks,
              StsConfirmMsg: 1,
              PreviousDoConfirm: 1
            }
            const value = this.state.values.SelectCategoryListForLastTimeDo
            const SelectCategoryList = value
            OverallResultDisplayInputAction.LastTimeDo(params).then((res) => {

              // モーダルで呼ぶ
              // this.ListdataInspectResult(record, SelectCategoryList)
            })
          }
        })
      }
    }
  }

  deleteFindings(record) {
    Modal.confirm({
      content: '所見内容を削除しますか', okText: 'はい',
      onOk: () => {
        const params = {
          ...this.getParamObject(),
          Li_JudgeLevel: this.state.values.Li_JudgeLevel,
          W1_sect_display_num: record && record.W1_sect_display_num,
          W1_inspect_item_display_num: record && record.W1_inspect_item_display_num,
          StsConfirm: 1
        }
        const value = this.state.values.SelectCategoryListForLastTimeDo
        const SelectCategoryList = value
        OverallResultDisplayInputAction.FindingsDelete(params).then((res) => {

          // モーダルで呼ぶ
          // this.ListdataInspectResult(record, SelectCategoryList)
        })
      }
    })
  }

  /**
   * カテゴリ別結果一覧のメニューリスト
   * @param {*} record
   * @returns
   */
  renderMenuCategory = (record) => (
    <Menu>
      <Menu.Item
        key='category-1'
        onClick={() => (this.showCheckCofirmYesOrNo(record))}>
        前回DO
      </Menu.Item>
      <Menu.Item
        key='category-2'
        onClick={() => (this.showWS2637053_NotesInquiry(record))}>
        指導事項
      </Menu.Item>
    </Menu>
  )

  /**
   * カテゴリ前回DO モーダル開く
   * @param {*} record
   */
  showCheckCofirmYesOrNo(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component:
          <WS0061003_ConfirmCheckYesYesNo
            Li_Title={'カテゴリ前回DO'}
            Li_Message={'カテゴリ内の前回検査結果を取得します。よろしいですか？'}
            onFinishScreen={(output) => {
              if (output && output.Lio_StsReturn) {
                // 「はい」ボタンが押下された時
                this.getPreviousCategoryDo(record)
              }
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 検査別結果一覧 モーダル
   * @param {*} record
   */
  showWS2637014_InspectResult = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637014_InspectResult
            values={this.state.values}
            record={record}
            paramObject={this.getParamObject()}
            protectFlag={this.props.protectFlag}
            onUpdateCategory={() => {
              // WS2637014_InspectResultモーダルの表示内容を更新するため、第１引数をtrueにする
              this.getCategoryJudgeList(true)
            }}
          />
        ),
      },
    })
  }

  /**
   * 変更ボタン押下処理
   * 総合結果入力 モーダルを表示
   */
  showWS2637002_OverallResultDisplayChange = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637002_OverallResultDisplayChange
            values={this.state.values}
            paramObject={this.getParamObject()}
            guideCommentInputList={this.state.guideCommentInputList}
            onUpdateGuide={() => {
              // 指導事項コメントを取得
              // WS2637002_OverallResultDisplayChangeモーダルの表示内容を更新するため、第１引数をtrueにする
              this.getGuideCommentInput(true)

              if (this.props.onUpdate) {
                // 親画面の受診者一覧も更新
                this.props.onUpdate()
              }
            }}
            onUpdateJudgment={() => {
              // 総合判定を取得
              // WS2637002_OverallResultDisplayChangeモーダルの表示内容を更新するため、第１引数をtrueにする
              this.index(true)

              if (this.props.onUpdate) {
                // 親画面の受診者一覧も更新
                this.props.onUpdate()
              }
            }}

          />
        ),
      },
    })
  }

  /**
  * 前回の検査結果を反映する（全体）
  */
  setPreviousInspectDo = (selectedRecord) => {
    const pre1categoryjudge = selectedRecord.pre_1_category_judge

    if (pre1categoryjudge === '' || pre1categoryjudge === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果を反映します。よろしいですか？`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: this.getParamObject().Li_ReserveNum,
            PreviousReserveNum: this.state.values.pre1ReserveNum,
            SelectCategory: record?.SelectCategory,
            Li_PatternCode: record?.pattern_code,
            Li_CategoryCode: record?.category_code
          }

          OverallResultDisplayInputAction.setPreviousExamCategoryCodeDo(params)
            .then((res) => {
              this.getCategoryJudgeList()
            })
        }
      })
    }
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }


  render() {
    return (
      <div className='overall-result-display-input'>
        <Card title='総合結果表示'
          style={{ position: 'relative', minWidth: '890px' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={this.state.DataScreenData}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div
                  className='box_border'
                  style={{ position: 'relative' }}
                >
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Form.Item
                      name='personal_number_id'
                      label='個人番号'
                      {...labelSize}
                    >
                      <span>
                        {this.state.values.personal_number_id}
                      </span>
                    </Form.Item>
                    <Button
                      size='small'
                      onClick={() => this.showWS2584019_PersonalInfoInquirySub()}
                      style={{ float: 'right', display: 'inline-block' }}
                      icon={<MoreOutlined />}
                    />
                  </div>

                  <Form.Item
                    name='kanji_name'
                    label='氏名'
                    {...labelSize}
                  >
                    <span>
                      {this.state.values.kanji_name}
                    </span>
                  </Form.Item>

                  <Form.Item
                    name='relationshipName'
                    label='続柄'
                    {...labelSize}
                  >
                    <span>
                      {this.state.values.relationshipName}
                    </span>
                  </Form.Item>

                  <Form.Item
                    name='birthday_on'
                    label='生年月日'
                    {...labelSize}
                  >
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <span>
                        {this.state.values.birthday_on}
                      </span>
                      {this.state.values.Age ?
                        <span>
                          {this.state.values.Age} 歳
                        </span>
                        : null
                      }
                    </div>
                  </Form.Item>

                  <img
                    src={GetImage(this.state.values.genderImage)}
                    style={{
                      position: 'absolute',
                      width: '50px', height: '50px',
                      bottom: '20px', right: '10px',
                      display: (this.state.values.genderImage) ? '' : 'none'
                    }}
                  />
                </div>

                <div className='box_border'>
                  <div
                    className='box_border_bottom_no_padding'
                    style={{
                      paddingBottom: '10px',
                      gap: '10px'
                    }}>

                    <Form.Item
                      name='DateChar'
                      label='受診日'
                      {...labelSize}
                    >
                      <span>
                        {this.state.values.DateChar ? moment(this.state.values.DateChar).format('YYYY/MM/DD') : ''}
                      </span>
                    </Form.Item>

                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <Form.Item
                        name='AcceptNum'
                        label='受付No'
                        {...labelSize}
                      >
                        <span>
                          {this.state.values.AcceptNum}
                        </span>
                      </Form.Item>
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: '10px', paddingTop: '10px' }}>
                    <Form.Item
                      name='visit_course'
                      label='契約'
                      {...labelSize}
                    >
                      <div style={{ display: 'flex', gap: '20px' }}>
                        <span>
                          {this.state.values.visit_course}
                        </span>
                        <span>
                          {this.state.values.contract_short_name}
                        </span>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <div className='box_border' style={{ gap: '10px' }}>
                  <div style={{
                    widht: '100%', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                  }}>
                    <Descriptions
                      bordered
                      size='small'
                      title=''
                      style={{ width: 300 }}
                    >
                      <Descriptions.Item
                        label='総合判定'
                        labelStyle={{ width: 100 }}
                      >
                        <div
                          style={{
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            color: Color(this.state.values.OverallJudgeGColorNum)?.Foreground
                          }}
                        >
                          {this.state.values.OverallJudgeG}
                        </div>
                      </Descriptions.Item>
                    </Descriptions>

                    <Button
                      type='primary'
                      icon={<EditOutlined />}
                      onClick={() => this.showWS2637002_OverallResultDisplayChange()}
                      hidden={this.props.protectFlag}
                    >
                      変更
                    </Button>
                  </div>

                  <Descriptions
                    bordered
                    size='small'
                    title=''
                    layout='vertical' >
                    <Descriptions.Item label='指導事項'>
                      <div>
                        {this.state.guideCommentInputList.map((item, index) => (
                          <div
                            key={`comment-${index}`}
                            style={{ color: Color(item.changeColorNum)?.Foreground }}
                          >
                            {item.overall_comment}
                          </div>
                        ))}

                      </div>
                    </Descriptions.Item>
                  </Descriptions>


                </div>

                {/* カテゴリ別結果一覧 */}
                <Table
                  bordered
                  size='small'
                  pagination={false}
                  dataSource={this.state.categoryJudgeList}
                  rowKey={record => record.id}
                  scroll={{ y: 400 }}
                  style={{ width: 500, minWidth: 500 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: async (event) => {
                        // 選択されている検査のidを保持
                        this.setState({ selectedInspectId: record.id })
                        // 検査別結果一覧モーダルを開く
                        this.showWS2637014_InspectResult(record)
                      }
                    }
                  }}
                >
                  <Table.Column
                    title='カテゴリ名称'
                    dataIndex='category_name'
                    width={140}
                    render={(item, record, index) => (
                      <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: (record?.changeColorNum === 82) ? 'bold' : ''
                        }}>
                          <Tooltip title={record.categoryCodeStr}>
                            <span>{record.category_name}</span>
                          </Tooltip>
                        </div>

                        <div style={{ display: 'flex', gap: 5 }}>
                          <div style={{ display: record.categoryNameChangeFlag ? '' : 'none' }}>
                            <Tooltip title='判定の内容を直接変更しています。'>*</Tooltip>
                          </div>

                          <div style={{ display: record.alreadyInput ? '' : 'none' }}>
                            *
                          </div>
                        </div>
                      </div>
                    )}
                  />

                  <Table.Column
                    // title='今回'
                    title={
                      <div style={{ display: (this.state.values.DateChar) ? '' : 'none' }}>
                        <div>{'今回'}</div>
                        <div>{moment(this.state.values.DateChar).format('YYYY/MM/DD')}</div>
                      </div>
                    }
                    dataIndex='category_judge'
                    className='this-time'
                    render={(item, record, index) => (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(record.categoryJudgeColorNum)?.Foreground,
                        }}>
                        {item}
                      </div>
                    )}
                  />

                  <Table.Column
                    title={
                      <div style={{ display: (this.state.values?.pre1VisitDate) ? '' : 'none' }}>
                        <div>{'前回'}</div>
                        <div>{this.state.values?.pre1VisitDate}</div>
                      </div>
                    }
                    dataIndex='pre_1_category_judge'
                    className='column-size-2'
                    render={(item, record, index) => (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(record.pre1CategoryJudgeColorNum)?.Foreground
                        }}>
                        {item}
                      </div>
                    )}
                  />

                  <Table.Column
                    title={
                      <div style={{ display: (this.state.values?.pre2VisitDate) ? '' : 'none' }}>
                        <div>{'前々回'}</div>
                        <div>{this.state.values?.pre2VisitDate}</div>
                      </div>
                    }
                    dataIndex='pre_2_category_judge'
                    className='column-size-2'
                    render={(item, record, index) => (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(record.pre2CategoryJudgeColorNum)?.Foreground
                        }}>
                        {item}
                      </div>
                    )}
                  />

                  <Table.Column
                    width={45}
                    style={{ textAlign: 'center' }}
                    render={(text, record, index) => (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                              onClick={() => {
                                // 検査別結果一覧モーダルを開く
                                this.showWS2637014_InspectResult(record)
                              }}
                            >
                              編集
                            </Menu.Item>

                            <Menu.Item
                              key='2'
                              disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                              onClick={() => {
                                this.setPreviousInspectDo(record)
                              }}
                            >
                              前回DO
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )}>
                  </Table.Column>
                </Table>
              </div>
            </div>

            {/* Footer */}
            <div className='box_button_bottom_right'>

              <Button
                type='primary'
                onClick={() => this.showWS0723001_AutoJudgeScreen()}
                hidden={this.props.protectFlag}
              >
                自動判定
              </Button>

              <Button
                type='primary'
                onClick={() => this.showWS0714001_HistoryInputSub()}
              >
                {(!this.state.values.HistoryNameOp) ?
                  '既往歴'
                  :
                  this.state.values.HistoryNameOp
                }
              </Button>

              <Button
                type='primary'
                onClick={() => { this.showWS2637036_DesignatedInspectVisitsHistory() }}
              >
                {(this.state.values.InspectHistoryNameOp) ?
                  this.state.values.InspectHistoryNameOp
                  :
                  '検査歴'
                }
              </Button>

              <Button
                type='primary'
                onClick={() => { this.showWS0898001_IntroduceLetterExtractMaintain() }}
              >
                紹介状
              </Button>

              <Button
                type='primary'
                icon={<PrinterOutlined />}
                onClick={() => this.showWS3020036_CoupledPrintInstruction()}
              >
                <span className='btn_label'>
                  印刷
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637001_OverallResultDisplayInput)
