import axios from 'configs/axios';

const apiPaths = {
  CreateBtn: '/api/contract-info-maintain/create-new/CreateBtn',
  yearProcess: '/api/contract-info-maintain/create-new/yearProcess'
}

const CreateNewService = {
  async yearProcess(params) {
    return axios.get(apiPaths.yearProcess, { params })
  },
  async CreateBtn(params) {
    return axios.post(apiPaths.CreateBtn, params)
  }
}

export default CreateNewService;