import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, message, Radio } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '115px' } }
}

class WS3200002_ElectronicMedicalRecordsLinkageFrameSetEdit extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '電子カルテ連携枠設定 新規・編集'
    this.state = {

    }


  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
   *  データ保存
   * @param {*} values
   */
  onFinish = (values) => {
    if (values.exam_id === undefined) {
      return message.error('exam_idを入力してください')
    }
    // if (values.exam_name === undefined) {
    //   return message.error('exam_nameを入力してください')
    // }
    // if (values.app_code === undefined) {
    //   return message.error('app_codeを入力してください')
    // }
    // if (values.seq === undefined) {
    //   return message.error('seqを入力してください')
    // }
    // if (values.calender_id === undefined) {
    //   return message.error('calender_idを入力してください')
    // }

    if (this.props.newFlag) {
      this.props.onNewData(values)
    } else {
      this.props.onUpdate(values)
    }
  }

  /**
   * データ削除
   */
  delete = () => {
    this.props.onDelete(this.props.record)
  }

  render() {
    return (
      <div className='Electronic-Medical-Records-Linkage-Frame-Set-Edit'>
        <Card
          title={'電子カルテ連携枠設定 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='exam_id'
                name='exam_id'//対応したものに書き換え
                readOnly
                {...labelCol}
              >
                <Input
                  className='input-size-10'
                />
              </Form.Item>

              <Form.Item
                label='exam_item'
                name='exam_item'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  className='input-size-10'
                />
              </Form.Item>

              <Form.Item
                label='exam_name'
                name='exam_name'//対応したものに書き換え
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='app_code'
                name='app_code'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  className='input-size-10'
                />
              </Form.Item>

              <Form.Item
                label='seq'
                name='seq'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  type='number'
                  className='input-size-number-4'
                />
              </Form.Item>

              <Form.Item
                label='calender_id'
                name='calender_id'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  className='input-size-10'
                />
              </Form.Item>

              <Form.Item
                label='dock_calender_id'
                name='dock_calender_id'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  className='input-size-10'
                />
              </Form.Item>

              <Form.Item
                label='sort_type'
                name='sort_type'
                {...labelCol}
              >
                <Radio.Group >
                  <Radio value={0} >昇順</Radio>
                  <Radio value={1} >降順</Radio>
                </Radio.Group>
              </Form.Item>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3200002_ElectronicMedicalRecordsLinkageFrameSetEdit)
