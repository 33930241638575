import axios from 'configs/axios'

const APP_LIST = {
  getOneMonthList: '/api/bank-holiday-setting/bank-holiday-setting/get-one-month-list',
  save: '/api/bank-holiday-setting/bank-holiday-setting/save',
  capturing: '/api/bank-holiday-setting/bank-holiday-setting/capturing'

}

const BankHolidaySettingService = {
  async getOneMonthList(params) {
    return axios.get(APP_LIST.getOneMonthList, { params })
  },
  async save(params) {
    return axios.post(APP_LIST.save, params)
  },
  async capturing(params) {
    return axios.post(APP_LIST.capturing, params)
  },
}

export default BankHolidaySettingService
