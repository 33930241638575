import React from "react";
import { connect } from "react-redux";

import { Button, Card, Form, Input, Modal, Col, Row, Table, Space, Dropdown, Menu, Tooltip, Popconfirm, message } from "antd";
import {
  MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined,
  CloseCircleOutlined, MoreOutlined, PlusOutlined, DeleteOutlined,
  InfoOutlined, FileTextOutlined, SaveOutlined, CheckOutlined
} from "@ant-design/icons";
import { getPartsData, getFindingsData, deleteSiteFindingsData, registerSiteFindingsData } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS0356021_RegisterPartsData from "./WS0356021_RegisterPartsData.jsx";
import WS0356022_RegisterFindingsData from "./WS0356022_RegisterFindingsData.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import ModalChange from "components/Commons/ModalChange"
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";
import { deleteFindings } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";
import { deleteParts } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";

const smGrid = {
  labelCol: { style: { width: 100 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class WS0356011_SiteFindingsMasterMaintainSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '部位所見マスタ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        total: 1,
        size: 'small',
        showQuickJumper: true,
        current: 1
      },

      dataSource_parts: [],
      dataSource_findings: [],
      isLoadingPage: false,
      colorText: 208,

    };
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      site_classification_code: this.props.Li_SiteClassificationCode,
      site_classification_name: this.props.Li_SiteClassificationName
    })
    this.loadPartsData()
    this.loadFindingsData()
  }

  loadPartsData = () => {
    this.setState({ isLoadingPage: true });
    let params = {
      site_classification: this.formRef.current?.getFieldValue('site_classification_code')
    }
    getPartsData(params)
      .then(res => {
        if (res) {
          let data = res.data
          this.setState({
            dataSource_parts: data
          });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  loadFindingsData = () => {
    this.setState({ isLoadingPage: true });
    let params = {
      site_classification_code: this.formRef.current?.getFieldValue('site_classification_code')
    }
    getFindingsData(params)
      .then(res => {
        if (res) {
          let data = res.data
          this.setState({
            dataSource_findings: data
          });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }


  editPartsForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 700,
        component: (
          <WS0356021_RegisterPartsData
            record={record}
            newFlag={false}
            Li_SiteClassificationCode={this.props.Li_SiteClassificationCode}
            Li_site_code={record.site_code}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addPartsForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 700,
        component: (
          <WS0356021_RegisterPartsData
            Li_SiteClassificationCode={this.props.Li_SiteClassificationCode}
            newFlag={true}
            Li_site_code={0}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  deletePartsData(record) {
    deleteParts({
      id: record.id
    })
      .then(() => {
        message.success('削除しました');
        this.loadPartsData()
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  editFindingsForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 700,
        component: (
          <WS0356022_RegisterFindingsData
            record={record}
            newFlag={false}
            Li_findings_classification={this.props.Li_SiteClassificationCode}
            Li_findings_code={record.findings_code}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addFindingsForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 700,
        component: (
          <WS0356022_RegisterFindingsData
            Li_findings_classification={this.props.Li_SiteClassificationCode}
            newFlag={true}
            Li_findings_code={0}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let params = {
      ...values,
      isNew: this.props.newFlag == true ? 1 : 0
    };
    this.RegisterBtn(params);
  }

  RegisterBtn(params) {
    this.setState({ isLoading: true })
    registerSiteFindingsData(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteFindingsData(record) {
    deleteFindings({
      id: record.id
    })
      .then(() => {
        message.success('削除しました');
        this.loadFindingsData()
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  reviewScreen() {
    this.loadPartsData()
    this.loadFindingsData()
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="use-inspect" style={{ width: this.props.newFlag ? 'fit-content' : 1200 }}>
        <Card className="mb-2" title="部位所見マスタ">
          <Form ref={this.formRef} onFinish={this.onFinish} >
            <div className='box_container'>
              <Form.Item name="site_classification_code" label="分類コード" {...smGrid}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}>
                <Input
                  maxLength={5}
                  style={{ width: 70, textAlign: 'right' }}
                  disabled={!(this.props.Li_SiteClassificationCode === 0)}
                />
              </Form.Item>

              <Form.Item name="site_classification_name" label="名　称" {...smGrid}>
                <Input type="text" maxLength={30} style={{ width: 250 }} />
              </Form.Item>

              {this.props.Li_SiteClassificationCode ?
                <div className="box_inner_horizontal">
                  <div className="box_inner_vertical_label_top">
                    <div
                      className="table_header_filter"
                    ><div>部位</div>
                    </div>
                    <Table bordered
                      visible={this.props.Li_SiteClassificationCode !== 0}
                      dataSource={this.state.dataSource_parts}
                      loading={false}
                      size='small'
                      pagination={false}
                      scroll={{ y: 400 }}
                      rowKey={(record) => record.id}
                      onRow={(record, rowIndex) => ({
                        onDoubleClick: () =>
                          this.editPartsForm(record)
                      })}
                    >
                      <Table.Column width={60} title="コード" dataIndex="site_code" />
                      <Table.Column width={200} title="検索略名" dataIndex="search_short_name" />
                      <Table.Column width={200} title="部位名" dataIndex="site_name" />
                      <Table.Column width={40} align="center" title={<Button type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => { this.addPartsForm() }}
                        size='small'
                      ></Button>}
                        render={(value, record, index) => (
                          <Dropdown
                            trigger='click'
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='edit'
                                  onClick={() => { this.editPartsForm(record) }}
                                >
                                  編集
                                </Menu.Item>
                                <Menu.Item
                                  key='delete'
                                  onClick={() => {
                                    // 確認モーダルを表示してから、削除
                                    Modal.confirm({
                                      content: `削除を行いますか?`,
                                      okText: 'は　い',
                                      cancelText: 'いいえ',
                                      onOk: () => {
                                        this.deletePartsData(record)
                                      }
                                    })
                                  }}
                                >
                                  削除
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button size='small' icon={<MoreOutlined />}></Button>
                          </Dropdown>
                          // <Button
                          //   size="small"
                          //   icon={<MoreOutlined />}
                          //   onClick={() => { this.editPartsForm(record) }}
                          // />
                        )}
                      />
                    </Table>
                  </div>
                  <div className="box_inner_vertical_label_top">
                    <div
                      className="table_header_filter"
                    ><div>所見</div>
                    </div>
                    <Table bordered
                      dataSource={this.state.dataSource_findings}
                      loading={false}
                      size='small'
                      pagination={false}
                      scroll={{ y: 400 }}
                      rowKey={(record) => record.id}
                      onRow={(record, rowIndex) => ({
                        onDoubleClick: () =>
                          this.editFindingsForm(record)
                      })}
                    >
                      <Table.Column width={60} title="コード" dataIndex="findings_code" />
                      <Table.Column width={200} title="検索略名" dataIndex="search_short_name" />
                      <Table.Column width={200} title="所見名" dataIndex="findings_name" />
                      <Table.Column width={40} align="center" title="判定" dataIndex="judgment_value" key="" />
                      <Table.Column width={40} align="center"
                        title={<Button type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => { this.addFindingsForm() }}
                          size='small'
                        ></Button>}
                        render={(value, record, index) => (
                          <Dropdown
                            trigger='click'
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='edit'
                                  onClick={() => { this.editFindingsForm(record) }}
                                >
                                  編集
                                </Menu.Item>
                                <Menu.Item
                                  key='delete'
                                  onClick={() => {
                                    // 確認モーダルを表示してから、削除
                                    Modal.confirm({
                                      content: `削除を行いますか?`,
                                      okText: 'は　い',
                                      cancelText: 'いいえ',
                                      onOk: () => {
                                        this.deleteFindingsData(record)
                                      }
                                    })
                                  }}
                                >
                                  削除
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button size='small' icon={<MoreOutlined />}></Button>
                          </Dropdown>
                        )}
                      />
                    </Table>
                  </div>
                </div>
                : null}
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>

                <Button type="primary" onClick={() => { this.onFinish(); }}>
                  保存
                </Button>

                {/* {this.props.Li_SiteClassificationCode ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？（同じ分類の部位・所見も削除されます。）<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_SiteClassificationCode === '') ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.deleteData()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.Li_SiteClassificationCode === 0}
                    >
                      削除
                    </Button>
                  </Popconfirm> : null} */}
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0356011_SiteFindingsMasterMaintainSub);
