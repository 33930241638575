import axios from "configs/axios";

const APP_LIST = {
  index: "/api/specific-insure-guide-settle-process-list/list-process"
};

const ListProcessService = {
  async index(params) {
    return axios.get(APP_LIST.index, { params });
  },
};

export default ListProcessService;