import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable"

import { Card, Table, Menu, Button, Dropdown } from "antd"
import { PlusOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons'

import WS1544002FormatQueryAction from "redux/AdvancePreparation/DocumentManageMaintain/WS1544002_FormatQuery.action"
import WS0638001_EscortManageMaintain from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638001_EscortManageMaintain.jsx'
import WS1544004_FormatEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1544004_FormatEdit.jsx'
class WS1544002_FormatQuery extends React.Component {
  static propTypes = {
    Lio_Format: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'フォーマット照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceQuery: [],
      isLoadingQuery: true,
      rowSelectedTableQuery: [],
      selectedRowKeysQuery: [],

      dataSourceContent: [],
      isLoadingContent: true,
      rowSelectedTableContent: [],
      selectedRowKeysContent: []
    };
  }

  componentDidMount() {
    this.getDataFormatQuery();
  }

  /**
   * フォーマット情報の取得
   */
  getDataFormatQuery() {
    this.setState({ isLoadingQuery: true });

    let params = {
      Format: this.props.Lio_Format
    }

    WS1544002FormatQueryAction.getDataFormatQuery(params)
      .then(async (res) => {
        let index = 0;
        if (res && this.props.Lio_Format) {
          let inx = res.findIndex(x => x.format === this.props.Lio_Format);
          index = inx === -1 ? 0 : inx
        }
        await this.setState({
          dataSourceQuery: res ? res : [],
          isLoadingQuery: false,
          rowSelectedTableQuery: res && res.length > 0 ? [res[index]] : [],
          selectedRowKeysQuery: res && res.length > 0 ? [res[index].id] : []
        });

        if (res && res.length > 0) {
          this.getDataFormatContent();
        }
      })
      .finally(() => this.setState({ isLoadingQuery: false }))
  }

  getDataFormatContent() {
    let params = {
      format: this.state.rowSelectedTableQuery[0].format
    }

    this.setState({ isLoadingContent: true });

    WS1544002FormatQueryAction.getDataFormatContent(params)
      .then((res) => {
        this.setState({
          dataSourceContent: res ? res : [],
          isLoadingContent: false,
          rowSelectedTableContent: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeysContent: res && res.length > 0 ? [res[0].id] : []
        })
      })
      .finally(() => this.setState({ isLoadingContent: false }))
  }

  escortManageMaintain(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1100,
        component: (
          <WS0638001_EscortManageMaintain
            Li_Format={record.format}
          />
        ),
      },
    })
  }

  /**
   * フォーマットの新規登録画面
   * @param {*} record 
   */
  ShowWS1544004_FormatEdit(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        className: '',
        component:
          <WS1544004_FormatEdit
            newFlag={record ? false : true}
            onUpdate={(output) => {
              this.closeModal()
              this.AddNewData(output)
            }}
          />
      },
    })
  }

  /**
   * フォーマットの新規登録処理
   * @param {*} record
   */
  AddNewData(record) {
    WS1544002FormatQueryAction.save(record)
      .then((res) => {
        this.getDataFormatQuery()
        this.escortManageMaintain(record)
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="format-query">
        <Card title="フォーマット照会">
          <div className="box_inner_horizontal">

            <Table
              size='small'
              style={{ cursor: 'pointer' }}
              dataSource={this.state.dataSourceQuery}
              loading={this.state.isLoadingQuery}
              pagination={false}
              bordered={true}
              rowKey={(record) => record.id}
              scroll={{ y: 600 }}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      Lio_Format: record.format,
                    });
                  }
                }
              })}
            >
              <Table.Column
                title="フォーマット"
                dataIndex="format"
              />

              <Table.Column
                width={40}
                title={
                  //新規登録のボタン
                  <Button
                    size='small'
                    type='primary'
                    style={{ textAlign: 'center' }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.ShowWS1544004_FormatEdit()
                    }}
                  ></Button>
                }
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='詳細'
                            onClick={() => {
                              this.escortManageMaintain(record)
                            }}
                          >
                            詳細
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        style={{ width: '100%' }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </div>

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />

        </Card >
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1544002_FormatQuery);
