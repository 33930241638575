import React from "react";
import { connect } from "react-redux";
import { Card, Input, Form, Modal, Button, } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1875003_RadiographyGuideMaintainCommentMenu from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1875003_RadiographyGuideMaintainCommentMenu.jsx';

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

const { TextArea } = Input

class WS1875002_RadiographyGuideMaintainEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '読影検査保守 編集';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      commentContent: ''
    };
  }

  componentDidMount() {
    // Form設定
    this.formRef.current?.setFieldsValue({
      comment_code: this.props.record.comment_code,
      comment_content: this.props.record.comment_content
    })

    this.setState({
      comment_content: this.props.record.comment_content
    })
  }

  サーチが押された場合
  searchGuidanceComment = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS1875003_RadiographyGuideMaintainCommentMenu
            onSave={(output) => {
              this.closeModal()

              this.formRef.current?.setFieldsValue({
                comment_code: output.comment_code,
                comment_content: output.comment_content
              })

              this.setState({
                comment_content: output.comment_content
              })
            }}
          />
        )
      }
    })
  }

  // 保存ボタンが押された場合
  save = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      id: this.props.record.id ?? '',
      exam_classification_code: this.props.exam_classification_code ?? ''
    }

    this.props.onSave(values)
  }

  // 削除ボタンが押された場合
  delete = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      id: this.props.record.id ?? '',
      exam_classification_code: this.props.exam_classification_code ?? ''
    }

    this.props.onDelete(values)
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="RadiographyInspectEdit">
        <Card
          title={'指導 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='指導コード'
                name="comment_code"
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-allow-clear-10'
                  onSearch={() => { this.searchGuidanceComment() }}
                />
              </Form.Item>

              <Form.Item
                label='指導内容'
                name="comment_content"
                {...labelCol}
              >
                <div style={{ marginTop: 5 }}>{this.state.comment_content}</div>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                fixed="right"
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1875002_RadiographyGuideMaintainEdit);