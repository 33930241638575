import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { Card, Form, Input, Button, Table, Row, Col, Select, Checkbox, Space, Dropdown, Menu, Modal } from 'antd'
import { MoreOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { getInspectItemSearchQuerySingleSistAction } from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions'
import WS2717011_InspectItem1ChangeSub from 'pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS2717011_InspectItem1ChangeSub.jsx'


class WS0271001_InspectItemSearchQuerySingle extends React.Component {

  static propTypes = {
    Li_SearchChar: PropTypes.any,
    Li_StsUseInspect: PropTypes.any,
    Li_Type: PropTypes.any,
    Lio_InspectItemCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '検査項目検索・照会(単品)'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initialValues: {
        SearchChar: '',
        StsUseInspect: 1,
        Type: '',
        limit: 10,
        page: 1,
      },
      data: [],
      selectedRows: {},
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount = () => {
    let value = {
      SearchChar: this.props.Li_SearchChar ? this.props.Li_SearchChar : this.state.initialValues.SearchChar,
      StsUseInspect: this.props.Li_StsUseInspect ? this.props.Li_StsUseInspect : this.state.initialValues.StsUseInspect,
      Type: this.props.Li_Type ? this.props.Li_Type : this.state.initialValues.Type,
    }
    // this.callAPILoadData(value)
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        ...value
      }
    })
    // this.formRef.current.setFieldsValue({
    //   SearchChar: this.props.examCode
    // })
    // if (this.props.examCode) {
    //   this.handleSearch(this.props.examCode)
    // }

    if (this.props.InspectCode) {
      this.formRef.current.setFieldsValue({
        SearchChar: this.props.InspectCode
      })
      this.forceUpdate()
      this.callAPILoadData()
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps !== this.props) {
  //     let value = {
  //       SearchChar: this.props.Li_SearchChar,
  //       StsUseInspect: this.props.Li_StsUseInspect,
  //       Type: this.props.Li_Type,
  //     }
  //     this.callAPILoadData(value)
  //     this.setState({
  //       initialValues: {
  //         ...this.state.initialValues,
  //         ...value
  //       }
  //     })
  //   }
  // }

  callAPILoadData = () => {
    let params = {
      SearchChar: this.formRef.current.getFieldValue('SearchChar'),
      StsUseInspect: this.formRef.current.getFieldValue('StsUseInspect') ? 1 : 0,
      Type: this.formRef.current.getFieldValue('Type'),
    }
    getInspectItemSearchQuerySingleSistAction(params)
      .then((res) => {
        if (res) {
          this.setState({
            data: res.data,
          })
        }
      })
      .catch()
  }


  handleSearch = (e) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        SearchChar: this.formRef.current.getFieldValue('SearchChar'),
        // page: 1,
      }
    }, () => this.callAPILoadData())
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const { initialValues, data, pagination, isLoading, childModal } = this.state
    const rowSelection = {
      onSelect: (record, selected, selectedRows, nativeEvent) => this.setState({ selectedRows: record })
    }
    return (
      <div className='inspect-item-search-query-single'>
        <Card title='検査項目検索・照会(単品)' >
          <Form
            ref={this.formRef}
            initialValues={initialValues}
            autoComplete='off'>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item
                  name='SearchChar'
                  label='検索'
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    // className='input-size-40'
                    onPressEnter={(e) => {
                      if (e.key === 'Enter') {
                        this.callAPILoadData()
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='Type'
                  style={{ marginBottom: 0 }}
                >
                  <Select name='Type' style={{ width: 120 }} >
                    <Select.Option value='' >全て</Select.Option>
                    <Select.Option value='N'>定量</Select.Option>
                    <Select.Option value='X'>定性</Select.Option>
                    <Select.Option value='S'>所見</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name='StsUseInspect'
                  valuePropName='checked'
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                >
                  <Checkbox
                    className='input-size-10'
                    onChange={this.handleCheckbox}
                  >
                    使用
                  </Checkbox>
                </Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    this.callAPILoadData()
                  }}
                >
                  <span className='btn_label'>
                    検索
                  </span>
                </Button>
              </div>

              <Table
                size='small'
                bordered
                dataSource={data}
                rowKey={record => record.test_item_code}
                pagination={data.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ y: 600 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedRows: record
                      })
                    },
                    onDoubleClick: () => {
                      if (this.props.onFinishScreen) {
                        this.props.onFinishScreen({
                          recordData: record
                        })
                      }
                    }
                  }
                }}
              >
                <Table.Column title='コード' dataIndex='test_item_code' width={90}
                  render={(text) => <div style={{ textAlign: 'right' }}>{text}</div>}
                />
                <Table.Column title='名　称' dataIndex='exam_name' />
                <Table.Column title='略　名' dataIndex='exam_short_name' />
                <Table.Column title='種別' dataIndex='exam_kind' width={40} />
                <Table.Column title='タイプ' dataIndex='InspectType' align='center' width={60} />
                <Table.Column title='使用' dataIndex='StsCategoryInspectBreakdown' align='center' width={40} />
                <Table.Column
                  title={
                    <Button
                      type='primary'
                      size='small'
                      icon={<PlusOutlined />}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...childModal,
                            visible: true,
                            width: 600,
                            component: (<WS2717011_InspectItem1ChangeSub
                              register={0}
                              onFinishScreen={(data) => {
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: false,
                                  },
                                })
                              }}
                            />),
                          },
                        })
                      } />
                  }
                  align='center'
                  width={40}
                  render={(text, record, index) => (
                    <Dropdown overlay={() => (
                      <Menu expandIcon trigger='click' >
                        <Menu.Item key='変更' onClick={() => {
                          this.setState({
                            childModal: {
                              ...childModal,
                              visible: true,
                              width: 600,
                              component: (<WS2717011_InspectItem1ChangeSub
                                Lio_InspectCode={record.test_item_code}
                                onFinishScreen={(data) => {
                                  this.setState({
                                    childModal: {
                                      ...childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />),
                            },
                          })
                        }}>変更</Menu.Item>
                      </Menu>
                    )}>
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )} />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={childModal.width}
          visible={childModal.visible}
          component={childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0271001_InspectItemSearchQuerySingle)
