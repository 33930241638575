import React from "react";
import { connect } from "react-redux";

import { Button, Card, Table, } from "antd";
import { getScreenDataUserOptionsInquiryAction } from "redux/SystemMaintenance/UserOptionInfoMaintain/UserOptionsInquiry.actions";

class WS1523007_UserOptionsInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'ユーザーオプション照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      rowSelect: {},
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.loadData({ Li_TypeCode: this.props.Li_TypeCode });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.loadData({ Li_TypeCode: this.props.Li_TypeCode });
    }
  }

  loadData = (params) => {
    this.setState({ isLoading: true });
    getScreenDataUserOptionsInquiryAction(params)
      .then(res => {
        if (res) {
          this.setState({ dataSource: res.data });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  onSelectRecord = (record) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lio_OptionCode: record.option_code,
        Lio_IdentifyName: record.identification_name,
        Lio_OptionItem: record.optional_items,
        recordData: record
      })
    }
  }

  render() {
    return (
      <div className="user-options-inquiry">
        <Card title="ユーザーオプション照会" className="mb-2">
          <Table bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record, index) => index}
            onRow={(record, index) => ({
              onClick: event => this.setState({ rowSelect: record }),
              onDoubleClick: event => this.onSelectRecord(record)
            })}
            scroll={{ x: 'max-content', y: 600 }}
          >
            <Table.Column title="コード" dataIndex="option_code" />
            <Table.Column title="名称" dataIndex="optional_items" />
          </Table>
          <div className='mt-3' style={{ float: 'right', display: 'none' }}>
            <Button type='primary' onClick={this.onSelectRecord}>選択</Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1523007_UserOptionsInquiry);
