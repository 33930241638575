import axios from "configs/axios";

const API_LIST = {
  getLog: "/api/virus-check-log/virus-check-log/get-log",
  getLogDetail: "/api/virus-check-log/virus-check-log/get-log-detail",
};

const VirusCheckLogService = {
  async getLog(params) {
    return axios.post(API_LIST.getLog, params);
  },
  async getLogDetail(params) {
    return axios.post(API_LIST.getLogDetail, params);
  },
};

export default VirusCheckLogService;
