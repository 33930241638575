import React from "react";
import { connect } from "react-redux";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import { Card, Table, message, Input, Col, Row, Button } from "antd";
import { MoreOutlined, SearchOutlined, ScissorOutlined, CopyOutlined, SnippetsOutlined, ZoomInOutlined } from '@ant-design/icons';
import WS1384002_FinancialSupportInquiry from '../../TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1384002_FinancialSupportInquiry.jsx';
import SupportItemInquiryAction from 'redux/SpecificInsureMaintenance/InsureGuideCourse/SupportItemInquiry.action';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1396009_SupportItemSub from "pages/TM_SpecificInsureMaintenance/V4TH0000100_SupportItem/WS1396009_SupportItemSub.jsx";


class WS1383001_SupportItemInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00130:支援項目照会';

    //childModal子画面に必要
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
      inspectInquiryData: [],
      selectedRow: [],
      isInspectInquiryLoading: false,
      //とりあえず追加
      // rowSelect: {},
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    this.setState({ isLoading: true });
    SupportItemInquiryAction.getScreenData()
      .then(res => {
        console.log(res);
        this.setState({ dataSource: res, selectedRow: res[0] });
        this.index(res[0])
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally(
        this.setState({ isLoading: false })
      );
  }

  index(record) {
    if (this.state.selectedRow.support_code === record.support_code) {
      return
    }
    this.setState({ isLoading: true });
    let params = {
      SupportCode: record.support_code
    }
    SupportItemInquiryAction.index(params)
      .then(res => {
        console.log(res);
        this.setState({
          inspectInquiryData: res,
        });
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally(
        this.setState({ isLoading: false })
      );
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  //三点ボタン　支援項目サブが開く
  // openPopUp = () => {
  openPopUp(record) {
    // console.log(this.state.rowSelect.support_code)
    console.log(record.support_code)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '100%',
        component: (
          <WS1396009_SupportItemSub
            SupportCode={record.support_code}
            // SupportCode={this.state.rowSelect.support_code}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  render() {
    return (
      <div className="support-item-inquiry"
      >
        <Card className="mb-2" title="V4-TSB00130:支援項目照会">
          <Row>
            {/* <Col span={18} > */}
            <Table
              bordered
              size="small"
              dataSource={this.state.dataSource ? this.state.dataSource : []}
              loading={false}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                showSizeChanger: true,
                onChange: (page, pageSize) => { },
                onShowSizeChange: (page, pageSize) => { },
              }}
              rowKey={(record) => record.id}
              // rowClassName={(record, index) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
              onRow={(record, index) => ({
                onClick: () => {
                  this.setState({
                    ...this.state.selectedRow,
                    selectedRow: record,
                  });
                },
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      support: this.state.selectedRow.support,
                      support_code: this.state.selectedRow.support_code,
                    })
                  }
                }
              })}
            >
              <Table.Column
                title="支援コード"
                dataIndex="support_code"
                showSorterTooltip={false}
                sorter={(a, b) => a.support_code?.localeCompare(b.support_code, 'ja')}
                key="support_code"
              />

              <Table.Column
                title="略称名"
                width={180}
                dataIndex="short_name"
                showSorterTooltip={false}
                sorter={(a, b) => a.short_name?.localeCompare(b.short_name, 'ja')}
                key="short_name"
              />

              <Table.Column
                title="支援内容"
                width={400}
                dataIndex="support"
                showSorterTooltip={false}
                sorter={(a, b) => a.support?.localeCompare(b.support, 'ja')}
                key="support"
              />

              <Table.Column
                title="支援区分"
                width={180}
                dataIndex="SupportDivision"
                showSorterTooltip={false}
                sorter={(a, b) => a.SupportDivision?.localeCompare(b.SupportDivision, 'ja')}
                key="SupportDivision"
              />

              <Table.Column
                title="支援形態"
                width={180}
                dataIndex="SupportForm"
                showSorterTooltip={false}
                sorter={(a, b) => a.SupportForm?.localeCompare(b.SupportForm, 'ja')}
                key="SupportForm"
              />

              {/* 分をつけたい */}
              <Table.Column
                title="値"
                width={180}
                dataIndex="support_unit_info_per_unit_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.support_unit_info_per_unit_value - b.support_unit_info_per_unit_value}
                key="support_unit_info_per_unit_value"
                render={(value, record) => {
                  return (
                    <div>
                      {record.support_unit_info_unit_division == '1' ? value + '分' : value + '往復'}
                    </div>
                  )
                }}
              />

              <Table.Column
                title="ポイント"
                dataIndex="support_unit_info_per_unit_point"
                showSorterTooltip={false}
                sorter={(a, b) => a.support_unit_info_per_unit_point - b.support_unit_info_per_unit_point}
                key="support_unit_info_per_unit_point"
              />

              {/* 分をつけたい */}
              <Table.Column
                title="最低値"
                dataIndex="restriction_info_lowest_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.restriction_info_lowest_value - b.restriction_info_lowest_value}
                key="restriction_info_lowest_value"
                //recordに行のデータが入っている。
                render={(value, record) => {
                  return (
                    <div>
                      {record.support_unit_info_unit_division == '1' ? value + '分' : value + '往復'}
                    </div>
                  )
                }}
              />

              {/* 分をつけたい */}
              <Table.Column
                title="最高値"
                dataIndex="restriction_info_highest_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.restriction_info_highest_value - b.restriction_info_highest_value}
                key="restriction_info_highest_value"
                render={(value, record) => {
                  return (
                    <div>
                      {record.support_unit_info_unit_division == '1' ? value + '分' : value + '往復'}
                    </div>
                  )
                }}
              />

              <Table.Column
                title="検査名"
                dataIndex="formal_name"
              />
              {/* 備考にしたい部分 */}
              <Table.Column
                width={60}
                title={
                  <div style={{ textAlign: "center" }}>
                  </div>
                }

                // 第１・２・３引数にデータが入ってきている。その引数の名称をrender={(text, record, index)で決定
                // 第１＝カラムのデータ　第２＝行全体のデータ　第３＝配列の何番目のデータか。
                // antdesinが引数のデータ内容をきめている。
                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                      onClick={() => { this.openPopUp(record) }}
                    ></Button>
                  )
                }}
              />

            </Table>
            {/* </Col> */}

            {/* <Col span={6}> */}
            {/* FinancialSupportInquiry */}
            {/* <Table
                bordered
                size="small"
                dataSource={this.state.inspectInquiryData ? this.state.inspectInquiryData : []}
                loading={this.state.isInspectInquiryLoading}
                pagination={{
                  defaultCurrent: 1,
                  pageSize: 10,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => { },
                  onShowSizeChange: (page, pageSize) => { },
                }}
                rowKey={(record) => record.id}
              >
                <Table.Column title="検査名" dataIndex="formal_name" />
              </Table> */}
            {/* </Col> */}
          </Row>
          {/* <Button type="primary" style={{ float: 'right' }}
            onClick={() => {
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen({
                  support_code: this.state.selectedRow ? this.state.selectedRow?.support_code : null,
                  support: this.state.selectedRow ? this.state.selectedRow?.support : null,
                  restriction_info_lowest_value: this.state.selectedRow ? this.state.selectedRow?.restriction_info_lowest_value : null,
                  restriction_info_highest_value: this.state.selectedRow ? this.state.selectedRow?.restriction_info_highest_value : null,
                });
              }
            }}
          >
            選択
          </Button> */}
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />

      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1383001_SupportItemInquiry);
