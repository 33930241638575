import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/history-input-sub',
  getHistoryList: '/api/not-input-check-category/history-input-sub/get-history-list',
  save: '/api/not-input-check-category/history-input-sub/save',
  delete: '/api/not-input-check-category/history-input-sub/delete'
}

const HistoryInputSubService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getHistoryList(params) {
    return axios.get(apiPaths.getHistoryList, { params })
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },
}

export default HistoryInputSubService
