import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Table, Button, Dropdown, Menu, message } from "antd";

import { PlusOutlined, MoreOutlined } from '@ant-design/icons';

import WS0641009_BasicConditionSetRegister from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641009_BasicConditionSetRegister.jsx";

import BasicConditionSetAction from "redux/AdvancePreparation/DocumentManageMaintain/BasicConditionSet.action";

class WS0641008_BasicConditionSet extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Lio_BasicCondition: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '基本条件設定';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      checkRadio: 0,

      dataSource0: [],
      isLoadingTable0: false,
      selectedRowKeys0: [],
      rowSelected0: [],
      indexTable0: 0,
    };

    this.handleAddRowTable = this.handleAddRowTable.bind(this);
  }

  componentDidMount() {
    this.getDataInspectCategory();
  }

  // inspect-category-set
  getDataInspectCategory() {
    let params = {
      inspect: this.props.BasicCondition ?? ''
    }
    this.setState({ isLoadingTable0: true })

    BasicConditionSetAction.getDataInspectCategory(params)
      .then((res) => {
        this.setState({
          dataSource0: res ? res : [],
          isLoadingTable0: false,

          rowSelected0: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeys0: res && res.length > 0 ? [res[0].id] : [],
          indexTable0: 0,
          old_W2_cd: res && res.length > 0 ? res[0].cd : null,
        })
      })
      .finally(() => this.setState({ isLoadingTable0: false }))
  }

  componentDidUpdate(prevProps) {
    if (this.props.BasicCondition !== prevProps.BasicCondition) {
      this.getDataInspectCategory();
    }
  }

  // add new record
  async handleAddRowTable(type) {
    let newRow = { id: '' };

    let data = [];

    if (type === 'category') {
      newRow = { ...newRow, W2_classific: 'J' }
      data = [...this.state.dataSource0]
      data.unshift(newRow);
      await this.setState({
        dataSource0: data,
        rowSelected0: [newRow],
        selectedRowKeys0: [newRow.id],
        indexTable0: 0
      });
    }
    this.forceUpdate();
  }

  // check selected record while add new
  changeRow(index, type) {
    let data = [];
    if (type === 'category') {
      data = [...this.state.dataSource0]

      let idTemp = false;
      data.forEach(item => {
        if (this.checkIdTemp(item.id)) {
          idTemp = true;
          return idTemp;
        }
      })

      if (idTemp) {
        this.setState({
          rowSelected0: [data[0]],
          selectedRowKeys0: [data[0].id],
          indexTable0: 0
        });
      } else {
        this.setState({
          indexTable0: index
        });
      }
    }
  }

  //基本条件の新規追加
  newAddConditions() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0641009_BasicConditionSetRegister
            //項目を追加する場合
            onFinishScreen={(output) => {
              let outputItem = this.setConditionsItem(output)
              this.props.onFinishScreen(outputItem)
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  /**
   * 基本条件の追加用関数
   * @param {*} record
   * @returns
   */
  setConditionsItem(record) {
    let formItem = this.props.BasicCondition
    let arrayItem = []
    let duplication = ''
    let outputItem
    let zero_cd = null

    if (record.CourseClassify == 'J') {
      zero_cd = String(record.cd).padStart(8, '0')
    } else {
      zero_cd = "G" + String(record.cd).padStart(5, '0')
    }

    if (formItem === '') {
      //フォームに何も登録されていない場合
      outputItem = zero_cd + ";"

    } else {
      //フォームに項目が登録されている場合
      formItem = formItem.substr(0, formItem.length - 1)
      arrayItem = formItem.split(',')
      arrayItem.push(zero_cd)
      arrayItem.sort()

      console.log(arrayItem);

      duplication = arrayItem.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplication.length === 0) {
        //重複がない場合
        arrayItem.join(',');
        outputItem = arrayItem + ";"

      } else {
        //重複がある場合
        message.error("検査コードが重複しています")

        //重複を削除したリスト
        arrayItem = arrayItem.filter((x, i, self) => self.indexOf(x) === i)
        arrayItem.join(',');
        outputItem = arrayItem + ";"
      }
    }
    return outputItem
  }

  /**
   * 基本条件の削除用関数
   * @param {*} record
   */
  deleteConditions(record) {
    let formItem = this.props.BasicCondition
    let arrayItem = ''
    let linkArrayItem = ''
    let outputItem = ''
    let zero_cd = null

    if (record.expression_5 == '8Z') {
      zero_cd = String(record.cd).padStart(8, '0')
    } else {
      zero_cd = "G" + String(record.cd).padStart(5, '0')
    }

    arrayItem = formItem.substr(0, formItem.length - 1)
    arrayItem = arrayItem.split(',')

    //削除対象の絞り込み・削除
    arrayItem.map((value, index) => {
      if (value === zero_cd) {
        arrayItem.splice(index, 1)
      }
    })

    arrayItem.sort()
    linkArrayItem = arrayItem.join(',')

    if (linkArrayItem === "") {
      //消去後、項目が残らない場合
      outputItem = ""

    } else {
      //消去後、項目が残る場合
      outputItem = linkArrayItem + ";"
    }

    this.props.onDelete(outputItem)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="basic-condition-set">
        <Card title="基本条件設定">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ Division: 0 }}
          >
            {/* 検査 */}
            <div style={{ display: this.state.checkRadio === 0 ? '' : 'none' }}>
              <Table
                size='small'
                dataSource={this.state.dataSource0}
                loading={this.state.isLoadingTable0}
                pagination={false}
                scroll={{ y: 700 }}
                bordered
                rowKey={(res) => res.id}
              >

                <Table.Column
                  title="区分"
                  dataIndex="classific"
                  width={90}
                />

                <Table.Column
                  title="コード"
                  dataIndex="cd"
                  width={100}
                  render={(value) => (
                    <div
                      style={{ textAlign: 'right' }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title="名称"
                  dataIndex="expression_6"
                />

                <Table.Column
                  width={40}
                  title={
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size='small'
                        onClick={() => this.newAddConditions()}
                        type="primary"
                        icon={<PlusOutlined />}>
                      </Button>
                    </div>
                  }

                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger="click"
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key="削除"
                              onClick={() => {
                                this.deleteConditions(record)
                              }}
                            >削除
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size="small"
                          icon={<MoreOutlined />}
                          style={{ width: "100%" }}
                        ></Button>
                      </Dropdown>
                    );
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0641008_BasicConditionSet);
