import axios from 'configs/axios'

const API_LIST = {
  onSave: '/api/normal-value-setting-maintain/normal-value-setting-input/onsave',
}

const NormalValueSettingInputService = {

  async onSave(params) {
    console.log(params)
    return axios.post(API_LIST.onSave, params);
  },

}

export default NormalValueSettingInputService
