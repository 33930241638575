import axios from 'configs/axios'

const API_LIST = {
  index: '/api/inspect-value-calculate-auto-maintain/inspect-value-calculate-auto-maintain',
  delete: '/api/inspect-value-calculate-auto-maintain/inspect-value-calculate-auto-maintain/delete',
}

const InspectValueCalculateAutoMaintainService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
}

export default InspectValueCalculateAutoMaintainService
