import { message } from 'antd';
import ItemAmountCorrect from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/ItemAmountCorrect'

const ItemAmountCorrectAction = {
    getInit(param){
        return ItemAmountCorrect.getInit(param)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    },
    save(data) {
        return ItemAmountCorrect.save(data)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                message.error(err.response.data.message);
                console.log(err.response.data.message);
            });
    },
}
export default ItemAmountCorrectAction;