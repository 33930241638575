import ContractSelectScreenService from "services/SpecificInsureGuide/SpecificInsureGuideSettleProcessList/ContractSelectScreenService.js"
import { message } from "antd";

const ContractSelectScreenAction = {
  getScreenData(params) {
    return ContractSelectScreenService.getScreenData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  contractSelectScreen_1(params) {
    return ContractSelectScreenService.contractSelectScreen_1(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  exit(params) {
    return ContractSelectScreenService.exit(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

}
export default ContractSelectScreenAction;