import axios from 'configs/axios';

const API_LIST = {
  downloadFile: '/api/rising-document-create/rising-document-create/download-file',
  getLists: '/api/rising-document-create/rising-document-create/get-lists',
};

const RisingDocumentCreateService = {
  async downloadFile(params) {
    return axios.post(API_LIST.downloadFile, params, { responseType: 'blob' });
  },

  async getLists(params) {
    return axios.get(API_LIST.getLists, {params});
  },
};

export default RisingDocumentCreateService;