import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Table, Row, Col, Space, Modal, Tooltip, Dropdown, Menu, Popconfirm, Descriptions, Tabs } from "antd";
import SetInfoChangeSubAction from "redux/basicInfo/SetInfoMaintain/SetInfoChangeSub.action";
import WS0333200_SetIncludesQueryEditMock from 'pages/Mock/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333200_SetIncludesQueryEditMock.jsx'
import { EditOutlined } from '@ant-design/icons';


class WS0333100_SetIncludesQueryInquiryMock extends React.Component {
  static propTypes = {
    Li_StartDate: PropTypes.any,
    Li_SetCode: PropTypes.any,
    Li_CourseCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = 'セット内容照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      isLoading: true,
      dataExamList: [],
      objMoreDetail: {
        Li_StartDate: '',
        Li_SetCode: ''
      },
      initParams: {
        Li_StartDate: '',
        Li_SetCode: '',
        Li_CourseCode: '',
        Search: ''
      },
      textCondition: '条件なし',
      colorCondition: 156,
      title: '',
      tableDataPaginate: {},
      allData: [],
      activeTab: '0',
      identify_cd: '',
      condition_relation: '',
      target_cd: ''
    };
  }

  componentDidMount() {
    this.getCondition();
  }

  getCondition() {
    this.setState({ isLoadingForm: true })
    let params = {
      Li_SetCode: this.props.onRow.set_code,
      SetCode: this.props.onRow.set_code,
      StartDate: this.props.onRow.start_date_on
    };
    SetInfoChangeSubAction.getCondition(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue(res)
        this.setState({
          allData: res
        })
        // 年齢
        let identify_cd = res.conditions_age_id_code_selectList.find(item => item.age_id_code === res.conditions_age_id_code)
        if (identify_cd) {
          this.setState({
            identify_cd: identify_cd.age_title
          })
        }
        // 続柄
        let condition_relation = res.conditions_relationship_selectList.find(item => item.node_code_name === res.conditions_relationship)
        console.log(condition_relation);
        if (condition_relation) {
          this.setState({
            condition_relation: condition_relation.name
          })
        }
        // 科目
        let target_cd = res.subject_code_selectList.find(item => item.node_code_name === res.subject_code)
        if (target_cd) {
          this.setState({
            target_cd: target_cd.name
          })
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  tabChange(e) {
    this.setState({ activeTab: e })
    this.formRef.current?.setFieldsValue(this.state.allData)
  }

  getFieldConv(name) {
    if (this.formRef.current?.getFieldValue(name) !== null) {
      return this.formRef.current?.getFieldValue(name)
    } else {
      return '--'
    }
  }

  render() {
    return (
      <div className="set-includes-query-inquiry">
        <Card title={'セット明細（モック）'} >
          <Form ref={this.formRef}>
            <Tabs
              activeKey={this.state.activeTab}
              onChange={(e) => this.tabChange(e)}
            >
              <Tabs.TabPane tab='単価照会' key={"0"}>
                <div className="box_container">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical" >
                      <div className="div_label">ラベル</div>
                      <div className="div_data">
                        {this.getFieldConv("additional_label_number")}
                      </div>
                      <div className="div_label">単価</div>
                      <div className="div_data">
                        {this.getFieldConv("unit_price")}
                      </div>
                      <div className="div_label">原価</div>
                      <div className="div_data">
                        {this.getFieldConv("cost_price")}
                      </div>
                    </div>
                    <div className="box_inner_vertical" >
                      <div className="div_label">科目</div>
                      <div className="div_data">{this.state.target_cd}</div>
                      <div className="div_label">備考</div>
                      <div className="div_data">{this.formRef.current?.getFieldValue("remarks")}</div>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab='条件照会' key={"1"}>
                <div className="box_container">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical" >
                      <div className="div_label">条件</div>
                      <div className="div_data">{this.formRef.current?.getFieldValue("conditions_effective") === 0 ? '無効' : '有効'}</div>
                      <div className="div_label">性別</div>
                      {this.formRef.current?.getFieldValue("conditions_gender") !== 0 ?
                        <div className="div_data">{this.formRef.current?.getFieldValue("conditions_gender") === 1 ? '男' : '女'}</div>
                        : <div className="div_data"></div>
                      }
                      <div className="div_label">N次</div>
                      {this.formRef.current?.getFieldValue("conditions_screening") !== 0 ?
                        <div className="div_data">{this.formRef.current?.getFieldValue("conditions_screening") === 1 ? '1次' :
                          this.formRef.current?.getFieldValue("conditions_screening") === 2 ? '2次' : '3次'}</div>
                        : <div className="div_data"></div>
                      }
                      <div className="div_label">続柄</div>
                      <div className="div_data">{this.state.condition_relation}</div>
                      <div className="div_label">初回</div>
                      <div className="div_label">{this.formRef.current?.getFieldValue('conditions_first_time')}</div>
                    </div>
                    <div className="box_inner_vertical" >
                      <div className="div_label">院内外</div>
                      {this.formRef.current?.getFieldValue("conditions_in_out_hospital") !== 0 ?
                        <div className="div_data">{this.formRef.current?.getFieldValue("conditions_in_out_hospital") === 1 ? '院内' : '院外'}</div>
                        : <div className="div_data"></div>
                      }
                      <div className="div_label">AMPM</div>
                      {this.formRef.current?.getFieldValue("conditions_time_division") !== null ?
                        <div className="div_data">{this.formRef.current?.getFieldValue("conditions_time_division") ? this.formRef.current?.getFieldValue("conditions_time_division") : ''}</div>
                        : <div className="div_data"></div>
                      }
                      <div className="div_label">年齢</div>
                      <div className="div_data">{this.state.identify_cd}</div>
                      <div className="div_label">年齢計算</div>
                      <div className="div_data">{this.formRef.current?.getFieldValue("conditions_age_calc_division") === 0 ? '受診日' :
                        this.formRef.current?.getFieldValue("conditions_age_calc_division") === 1 ? '年度末' : '学童'}</div>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 550,
                      component: (
                        <WS0333200_SetIncludesQueryEditMock
                          activeTab={this.state.activeTab}
                          onRowData={this.props.onRow}
                          StartDate={this.props.Li_StartDate}
                          data={this.state.allData}
                          onFinishScreen={output => {
                            this.getCondition()
                            this.closeModal()
                            this.props.onFinishScreen()
                          }}
                        />
                      )
                    }
                  })
                }
              >編集</Button>
            </div>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Card>
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0333100_SetIncludesQueryInquiryMock);