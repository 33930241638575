import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/ref-people-num-setting-info/ref-people-num-setting-info/get-screen-data',
  referenceNumPeopleSet: '/api/ref-people-num-setting-info/ref-people-num-setting-info/reference-num-people-set',
  saveReferenceNumPeopleSet: '/api/ref-people-num-setting-info/ref-people-num-setting-info/save-reference-num-people-set',
  getHistoryOn: '/api/ref-people-num-setting-info/ref-people-num-setting-info/get-history-on',
  addHistoryOn: '/api/ref-people-num-setting-info/ref-people-num-setting-info/add-history-on',
  deleteHistoryOn: '/api/ref-people-num-setting-info/ref-people-num-setting-info/delete-history-on',
};

const RefPeopleNumSettingInfoService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
  async referenceNumPeopleSet(params) {
    return axios.get(apiPaths.referenceNumPeopleSet, { params });
  },
  async saveReferenceNumPeopleSet(params) {
    return axios.post(apiPaths.saveReferenceNumPeopleSet, params);
  },
  async getHistoryOn(params) {
    return axios.get(apiPaths.getHistoryOn, { params });
  },
  async addHistoryOn(params) {
    return axios.post(apiPaths.addHistoryOn, params);
  },
  async deleteHistoryOn(params) {
    return axios.delete(apiPaths.deleteHistoryOn, { params });
  },
};

export default RefPeopleNumSettingInfoService;