import { message } from "antd";
import RadiographySiteFindingsMaintainService from "services/InspectionMaintenance/RadiographyInspectMaintain/RadiographySiteFindingsMaintainService";

const RadiographySiteFindingsMaintainAction = {
  getSite1(params) {
    return RadiographySiteFindingsMaintainService.getSite1(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  getSite2(params) {
    return RadiographySiteFindingsMaintainService.getSite2(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  getfindings1(params) {
    return RadiographySiteFindingsMaintainService.getfindings1(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  getfindings2(params) {
    return RadiographySiteFindingsMaintainService.getfindings2(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  saveSite1(params) {
    return RadiographySiteFindingsMaintainService.saveSite1(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  saveSite2(params) {
    return RadiographySiteFindingsMaintainService.saveSite2(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  saveFindings1(params) {
    return RadiographySiteFindingsMaintainService.saveFindings1(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  saveFindings2(params) {
    return RadiographySiteFindingsMaintainService.saveFindings2(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  deleteSite1(params) {
    return RadiographySiteFindingsMaintainService.deleteSite1(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  deleteSite2(params) {
    return RadiographySiteFindingsMaintainService.deleteSite2(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  deleteFindings1(params) {
    return RadiographySiteFindingsMaintainService.deleteFindings1(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  deleteFindings2(params) {
    return RadiographySiteFindingsMaintainService.deleteFindings2(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}

export default RadiographySiteFindingsMaintainAction;