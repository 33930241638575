import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Select, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers'
import moment from 'moment'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import PassingManageCSVAction from 'redux/Others/PassingManageCSV/PassingManageCSV.action'

const labelCol = {
  labelCol: { style: { width: '55px' } }
}

let today1 = moment(new Date())
let today2 = moment(new Date())

class WS3064001_PassingManageCSV extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);

    // document.title = '通過管理CSV出力'

    this.state = {
      managerList: [],
      terminalList: [],
      inspectionList: [],
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    PassingManageCSVAction.index()
      .then((res) => {
        this.setState({
          managerList: res.managerList,
          terminalList: res.terminalList,
          inspectionList: res.inspectionList
        })
      })
  }

  // CSVファイルの作成
  createCSV() {
    let DateFChar = this.formRef.current?.getFieldValue('DateFChar')
    let DateTChar = this.formRef.current?.getFieldValue('DateTChar')

    let prams = {
      manager: this.formRef.current?.getFieldValue('manager') ?? '',
      terminal: this.formRef.current?.getFieldValue('terminal') ?? '',
      inspection: this.formRef.current?.getFieldValue('inspection') ?? '',
      DateFChar: DateFChar.format("YYYY/MM/DD"),
      DateTChar: DateTChar.format("YYYY/MM/DD")
    }

    console.log(prams);
    PassingManageCSVAction.createCSV(prams)
      .then((res) => {
        this.downloadCSVFile(res)
      })
  }

  /**
  * CSVファイル　ダウンロード
  * @param {*} path
  */
  downloadCSVFile(path) {
    let params = {
      filePath: path.filePath,
      FileName: path.FileName
    }
    PassingManageCSVAction.downloadCSVFile(params)
      .then((res) => {
        if (res) {
          // xlsxファイルダウンロード
          download_file(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    return (
      <div className='passing-manage-csv'>
        <Card
          title='通過管理CSV出力'
          style={{ width: 600 }}
        >
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
              initialValues={{
                DateFChar: today1,
                DateTChar: today2,
              }}
            >
              <div className='box_inner_vertical'>
                <div className='box_inner_horizontal'>
                  <Form.Item
                    name='DateFChar'
                    label='受診日'
                    {...labelCol}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                    />
                  </Form.Item>
                  <Form.Item
                    name='DateTChar'
                    label='~'
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  name='manager'
                  label='担当者'
                  {...labelCol}
                >
                  <Select style={{ width: 350 }} >
                    {this.state.managerList?.map((value) => {
                      return (
                        <Select.Option
                          key={value.id}
                          value={value.username}
                        >
                          {value.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name='terminal'
                  label='端末'
                  {...labelCol}
                >
                  <Select style={{ width: 350 }} >
                    {this.state.terminalList?.map((value) => {
                      return (
                        <Select.Option
                          key={value.id}
                          value={value.terminal_num}
                        >
                          {value.terminal_official_name}
                        </Select.Option>
                      )
                    })}

                  </Select>
                </Form.Item>

                <Form.Item
                  name='inspection'
                  label='検査'
                  {...labelCol}
                >
                  <Select style={{ width: 350 }}>
                    {this.state.inspectionList?.map((value) => {
                      return (
                        <Select.Option
                          key={value.id}
                          value={value.passing_number}
                        >
                          {value.passing_official_name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              icon={<DownloadOutlined />}
              type='primary'
              onClick={() => {
                this.createCSV()
              }}
            >
              <span className='btn_label'>
                出力
              </span>
            </Button>
          </div>
        </Card >
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3064001_PassingManageCSV)
