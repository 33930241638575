import axios from 'configs/axios';

const API_LIST = {
  codeChange: '/api/contract-info-maintain/code-changes/RunBtn_Tab0',
};

const CodeChangesService = {
  async codeChange(data) {
    return axios.post(API_LIST.codeChange, data);
  },
};

export default CodeChangesService;
