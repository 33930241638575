import axios from 'configs/axios'

const API_LIST = {
  index: '/api/reserve-status-search/calendar-empty-sub',
  redisplay: '/api/reserve-status-search/calendar-empty-sub/redisplay',
  getDataDetail: '/api/reserve-status-search/calendar-empty-sub/get-data-detail',
}

const CalendarEmptySubService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async redisplay(params) {
    return axios.post(API_LIST.redisplay, params)
  },
  async getDataDetail(params) {
    return axios.post(API_LIST.getDataDetail, params)
  },
}

export default CalendarEmptySubService
