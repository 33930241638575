import axios from "configs/axios";

const APP_LIST = {
  getScreenData: "/api/input-output/get-screen-data",
  runBtn_F12: "/api/input-output/run-btn-f12",
  Input_F12: "/api/input-output/input-f12",
  upload: "/api/input-output/upload",
};

const InputOutputService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async runBtn_F12(data) {
    return axios.post(APP_LIST.runBtn_F12, data, { responseType: 'blob' });
  },
  async Input_F12(data) {
    return axios.post(APP_LIST.Input_F12, data);
  },
  async upload(data) {
    return axios.post(APP_LIST.upload, data);
  },
};

export default InputOutputService;
