import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import { Card, Form, Button, Table, message, Modal, Menu, Descriptions, InputNumber } from 'antd'
import { MoreOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import GuideMatterContentAction from 'redux/InputBusiness/NotInputCheckCategory/GuideMatterContent.action'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS2637053_NotesInquiry from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637053_NotesInquiry.jsx'
import WS2644008_SpecifiedValueConfirm from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2644008_SpecifiedValueConfirm.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import WS2637041_GuideMatterContent from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637041_GuideMatterContent.jsx'
import PreviousGuideMatterInquiryAction from 'redux/InputBusiness/NotInputCheckCategory/PreviousGuideMatterInquiry.action'
import WS2641030_PreviousGuideMatterInquiry from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2641030_PreviousGuideMatterInquiry.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx'
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'
import WS0061003_ConfirmCheckYesYesNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061003_ConfirmCheckYesYesNo.jsx'
import WS0730001_FindingsInputPhysiciiagnosis from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'


class WS2637002_OverallResultDisplayChange extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Li_MenuAdminRights: PropTypes.any,
    Li_MenuAuthority: PropTypes.any,
    Li_SubjectNotBeChangedMode: PropTypes.any,
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '総合結果表示/入力'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      values: {},
      initialValues: {},

      guideCommentInputList: [], // 注意事項コメント一覧

      ReserveNum: 0,
      // categoryJudge1PreVisitDate: null,
      // categoryJudge2BeforeVisitDate: null,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 注意事項一覧を設定
    this.setState({
      values: this.props.values,
      guideCommentInputList: this.props.guideCommentInputList
    })
  }

  /**
   * 親画面で変更があった場合、state変数を変更
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    // 総合判定を取得
    if (this.props.values !== prevProps.values) {
      this.setState({
        values: this.props.values
      })
    }
    // 指導事項内容（コメント）を取得
    if (this.props.guideCommentInputList !== prevProps.guideCommentInputList) {
      this.setState({
        guideCommentInputList: this.props.guideCommentInputList
      })
    }
  }

  onChange = (value) => {
    // console.log(value)
    this.setState({ serial_number: value })
  }
  saveInput = (record) => {
    // console.log(record.id)
    // console.log(this.state.serial_number)

    const params = {
      ...this.props.record,
      id: record.id, // 新規追加の場合、idはnull
      Li_ReserveNum: this.checkData(this.state.values.Li_ReserveNum, 'number'),
      serial_number: this.checkData(this.state.serial_number, 'number'),
      general_comment_code: this.checkData(record.general_comment_code, 'number'),
      overall_comment: this.checkData(record.overall_comment, 'text'),
      judgment_category_code: this.checkData(record.judgment_category_code, 'number'),
      judgment_basic: this.checkData(record.judgment_basic, 'text'),
      auto_judgment_weight: this.checkData(record.auto_judgment_weight, 'number'),
      auto_judgment_output_type: this.checkData(record.auto_judgment_output_type, 'text'),
      course_level_2: record.course_level_2 ? 1 : 0,
      course_level_3: record.course_level_3 ? 1 : 0,
      secretMode: this.checkData(record.secretMode, 'number'),
      manual: this.checkData(record.manual, 'number'),
      judgeBasicColorNum: this.checkData(record.judgeBasicColorNum, 'number'),
      input_flag: record.secretMode ? 'S' : 'H' //保存ボタンを押した場合、手動にチェックがつく状態にする為
    }
    // console.log(params)

    GuideMatterContentAction.save(params)
      .then(res => {
        if (this.props.onUpdateGuide) {
          // 親画面の表示内容更新させる　
          // （最終的にWS2637001_OverallResultDisplayInputのgetGuideCommentInputを実行させる）
          this.props.onUpdateGuide()
        }
      })
  }


  /**
   * データチェック
   * @param {*} value
   * @param {*} type
   * @returns
   */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  /**
   * 空チェック
   * @param {*} val
   * @returns
   */
  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * 共通でparamsに
   * @returns
   */
  getParamObject() {
    let result = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.checkData(this.props.Li_CourseLevel, 'number'),
      Li_PatternCode: this.props.values.pattern_code,
      Li_MenuOption: this.checkData(this.props.Li_MenuOption, 'number'),
      Li_MenuAdminRights: this.checkData(this.props.Li_MenuAdminRights, 'number'),
      Li_MenuAuthority: this.checkData(this.props.Li_MenuAuthority, 'number'),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
      // Li_ContractStartDate: this.state.values.contract_start_date_on,
    }
    return result
  }

  requestParamScreenData(param) {
    const NonInput = this.state.values.NonInput
    const Outliers = this.state.values.Outliers
    const HistoryAdded = this.state.values.HistoryAdded
    const isNonInput = (NonInput === 1) ? { NonInput } : { Outliers }
    const params = {
      ...this.getParamObject(),
      HistoryAdded
    }
    const request = param === undefined ? params : { ...params, ...isNonInput }
    return request
  }

  handleAdd() {
    const { count } = this.state
    let data = this.state.guideCommentInputList ?
      this.state.guideCommentInputList : []
    const params = {
      id: count,
      isNew: true,
      serial_number: (data[data?.length - 1]?.serial_number + 1),
      general_comment_code: '',
      overall_comment: '',
      auto_judgment_output_type: '',
      judgment_basic: ''
    }
    let arr = [...data]
    arr.unshift(params)
    this.forceUpdate()
    // this.setStateValues('guideCommentInputList', arr)
    this.setState({
      count: count + 1,
      guideCommentInputList: arr,
    })
  }

  onFinish(values) {

  }

  /**
   * state変数：values の更新
   * @param {*} namePath
   * @param {*} value
   */
  setStateValues = (namePath, value) => {
    this.setState({
      values: {
        ...this.state.values, //現行のvaluesをコピー
        [namePath]: value
      }
    })
  }

  /**
   * 指導事項内容　モーダル　※
   * @param {*} record
   */
  showWS2637041_GuideMatterContent(record = null) {
    if (this.state.values.StsInputModeAll === 1) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 800,
          component:
            <WS2637041_GuideMatterContent
              record={record}
              personalNumberId={this.state.values.personal_number_id}
              KanjiName={this.state.values.kanji_name}
              TypeSelectList={this.state.values.TypeSelectList}
              Li_JudgeLevel={this.state.values.Li_JudgeLevel}
              Li_ReserveNum={this.state.values.Li_ReserveNum}
              Li_PatternCode={this.state.values.pattern_code}
              onUpdateGuide={() => {
                if (this.props.onUpdateGuide) {
                  // 親画面の表示内容更新させる　
                  this.props.onUpdateGuide()
                }
                this.closeModal()
              }}
            />
          ,
        },
      })
    }
  }

  showAllCaseSpecifiedValue() {
    Modal.warning({
      content: '規定値設定が可能な検査が存在しません',
      okText: 'は　い'
    })
  }

  showBtnF11WS3020036_CoupledPrintInstruction() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            Li_Parameters={this.state.values.Li_Parameters}
            onFinishScreen={(obj) => {
              this.closeModal()

            }}
          />
        ),
      },
    })
  }

  checkF11() {
    this.showBtnF11WS3020036_CoupledPrintInstruction()
  }

  showWS0730001_FindingsInputPhysiciiagnosis(variables, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '100%',
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            onFinishScreen={(obj) => {
              if (obj) {
                this.setState({ Lio_FindingsCategoryUpdate: obj && obj?.Lio_FindingsCategoryChange })
                setTimeout(() => this.findingsEditingAfter(record, obj))
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 前回指導事項チェック　モーダル
   * @param {*} data
   */
  showWS02641030_PreviousGuideMatterInquiry = () => {

    const params = {
      pre1ReserveNum: this.state.values.pre1ReserveNum, //前回の予約番号
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
    }

    PreviousGuideMatterInquiryAction.getPreviousGuideMatter(params)
      .then((res) => {
        const message = '前回指導事項は存在しません'
        if (res && res.message === message) {
          Modal.warning({ content: res.message, okText: 'は　い' })
        } else {
          // 前回指導事項が存在する場合モーダルを開く
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 700,
              component: (
                <WS2641030_PreviousGuideMatterInquiry
                  Li_ReserveNum={this.state.values.pre1ReserveNum}
                  Li_PreviousGuideMatters={res?.PreviousGuidanceMatters}
                  onFinishScreen={(output) => {
                    // 前回指導事項を今回のコメントに設定する
                    this.setPreviousGuideMatter(output)
                  }}
                />
              ),
            },
          })
        }
      }).catch((err) => {
        const message = '前回指導事項は存在しません'
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        if (res && res.data && res.data.message === message) {
          Modal.warning({ content: res.data.message, okText: 'は　い' })
        }
      })
  }

  /**
   * 前回指導事項を今回のコメントに設定する
   * @param {*} data
   */
  setPreviousGuideMatter = (data) => {

    Modal.confirm({
      content: `前回のコメントを追加します。よろしいですか？`,
      okText: '保　存',
      cancelText: 'キャンセル',
      onOk: () => {
        const params = {
          reserveNum: this.state.values.Li_ReserveNum,
          pre1ReserveNum: data.pre1ReserveNum
        }
        PreviousGuideMatterInquiryAction.setPreviousGuideMatter(params)
          .then((res) => {
            if (this.props.onUpdateGuide) {
              // 親画面の表示内容更新させる　
              this.props.onUpdateGuide()
            }
            this.closeModal()
          })
      }
    })
  }

  showWS0272001_CautionGuideNotesSearchQuery(record, index) {
    const value = this.state.guideCommentInputList
    if (this.state.values.StsInputModeAll === 1) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 700,
          component: (
            <WS0272001_CautionGuideNotesSearchQuery
              Li_PatternCode={this.props.values.pattern_code}
              LnkOutCmtCode={this.checkData(value && value[index]?.general_comment_code, 'number')}
              onFinishScreen={(obj) => {
                value[index].general_comment_code = obj.Lo_LnkOutCmtCode
                value[index].overall_comment = obj.Lo_recordData?.comment_content
                // this.setStateValues('guideCommentInputList', value)
                this.forceUpdate()
                if (value && !value[index].isNew) {
                  this.EventChangeW3AutoBasicJudge(value[index])
                }
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  changeDataUpdateF12 = () => {
    let categoryJudgeList = this.state.categoryJudgeList
    const changeCategoryJudge = categoryJudgeList.some(s => s.changeColorNum === 82)
    let InspectResult = this.state.values.InspectResult

    let changeInspectResult = false
    if (InspectResult) {
      changeInspectResult = InspectResult.some(s => s.Expression_83 === 82)
    }
    const StsLeadershipMattersChange = this.state.values.StsLeadershipMattersChange

    if (!StsLeadershipMattersChange || changeCategoryJudge || changeInspectResult) {
      return true
    } else {
      return false
    }
  }

  ShowWS0605127_ContractLineItemDisplay() {
    const ReserveNum = this.state.ReserveNum === 0 ? this.props.Li_ReserveNum : this.state.ReserveNum
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0605127_ContractLineItemDisplay
            Li_ContractType={this.state.values.contract_type}
            Li_ContractOrgCode={this.state.values.contract_organization_code}
            Li_ContractStartDate={this.state.values.contract_start_date_on}
            Li_ContractNum={this.state.values.contract_number}
            Li_MenuOption={''}
            Li_MenuAdminRights={''}
            Li_MenuAuthority={''}
            Li_SubjectNotBeChangedMode={''}
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_ReserveNum={this.checkData(ReserveNum, 'number')}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  deleteFindings(record) {
    Modal.confirm({
      content: '所見内容を削除しますか', okText: 'はい',
      onOk: () => {
        const params = {
          ...this.getParamObject(),
          Li_JudgeLevel: this.state.values.Li_JudgeLevel,
          W1_sect_display_num: record && record.W1_sect_display_num,
          W1_inspect_item_display_num: record && record.W1_inspect_item_display_num,
          StsConfirm: 1
        }
        const value = this.state.values.SelectCategoryListForLastTimeDo
        const SelectCategoryList = value
        OverallResultDisplayInputAction.FindingsDelete(params).then((res) => {

          // モーダルで呼ぶ
          // this.ListdataInspectResult(record, SelectCategoryList)
        })
      }
    })
  }

  /**
   * カテゴリ別結果一覧のメニューリスト
   * @param {*} record
   * @returns
   */
  renderMenuCategory = (record) => (
    <Menu>
      <Menu.Item
        key='category-1'
        onClick={() => (this.showCheckCofirmYesOrNo(record))}>
        前回DO
      </Menu.Item>
      <Menu.Item
        key='category-2'
        onClick={() => (this.showWS2637053_NotesInquiry(record))}>
        注意事項
      </Menu.Item>
    </Menu>
  )

  /**
   * カテゴリ前回DO モーダル開く
   * @param {*} record
   */
  showCheckCofirmYesOrNo(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component:
          <WS0061003_ConfirmCheckYesYesNo
            Li_Title={'カテゴリ前回DO'}
            Li_Message={'カテゴリ内の前回検査結果を取得します。よろしいですか？'}
            onFinishScreen={(output) => {
              if (output && output.Lio_StsReturn) {
                // 「はい」ボタンが押下された時
                this.getPreviousCategoryDo(record)
              }
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * カテゴリ前回DO 結果値を取得
   * @param {*} record
   */
  getPreviousCategoryDo(record) {
    const displayOrder = !record.display_order
    const params = {
      ...this.getParamObject(),
      Li_DisplayOrder: displayOrder ? displayOrder : this.state.values.displayOrder,
    }
    OverallResultDisplayInputAction.getPreviousCategoryDo(params)
      .then((res) => {
        this.setStateValues('CategoryDisplayPosition', res && res.CategoryDisplayPosition)
      })
  }

  /**
   * 注意事項入力　モーダル
   * @param {*} record
   */
  showWS2637053_NotesInquiry = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS2637053_NotesInquiry
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            Li_CategoryCode={record.category_code}
            onScreenFinish={(output) => {
              // if (output.Exit) {
              this.closeModal()
              // }
            }}
          />
      }
    })
  }

  /**
   * 判定選択
   * @param {*} data
   */
  showWS0285001_JudgeQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={this.state.values.Li_JudgeLevel}
            onFinishScreen={async (output) => {
              if (output.recordData) {
                Modal.confirm({
                  content: `総合判定を [${output.recordData.judgment_result}] に変更します。よろしいですか？`,
                  okText: '保　存',
                  cancelText: 'キャンセル',
                  onOk: () =>
                    // 総合判定を保存
                    this.saveJudgmentBasic(output.recordData)
                })
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 総合判定を保存
   * @param {*} value
   */
  saveJudgmentBasic = (value) => {
    const params = {
      Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
      Li_CourseLevel: this.props.paramObject.Li_CourseLevel,
      Li_TotalJudge: value.judgment_result,
      Li_JudgeLevel: this.props.values.Li_JudgeLevel,
    }
    OverallResultDisplayInputAction.saveJudgmentBasic(params)
      .then((res) => {
        if (this.props.onUpdateJudgment) {

          this.setState({
            values: {
              OverallJudgeG: value.judgment_result
              , OverallJudgeGColorNum: value.judgmentColorNum
            }
          })

          // 親画面で変更された総合判定を反映する
          this.props.onUpdateJudgment()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({ content: res.data.message })
      })
  }

  /**
   * 判定存在チェック
   * @param {*} value
   */
  checkJudgmentExist(value) {
    const params = {
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      judgment_basic: this.state.values.OverallJudgeG,
    }
    OverallResultDisplayInputAction.checkJudgmentExist(params)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({ content: res.data.message })
      })
  }


  onSave(index) {
    const record = this.state.guideCommentInputList[index]
    !record.isNew ?
      this.insertData(record, index) : this.GuideCommentInputSaveRowData(record, false)
  }


  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='overall-result-display-input'>
        <Card
          // title='総合結果入力'
          title={`総合判定・指導事項内容 : [${this.props.values.personal_number_id}] ${this.props.values.kanji_name}`}
          style={{ position: 'relative', minWidth: '890px' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={this.state.DataScreenData}
          >
            <div className='box_container'>
              <div className='box_inner_vertical'>
                <div
                  className='box_inner_horizontal'
                  style={{ alignItems: 'center' }}
                >
                  <Descriptions
                    bordered
                    size='small'
                    title=''
                    // layout='vertical'
                    style={{ width: '300px' }}
                  >
                    <Descriptions.Item
                      label='総合判定'
                      labelStyle={{ width: '100px' }}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          width: '100%',
                          textAlign: 'center',
                          color: Color(this.state.values.OverallJudgeGColorNum)?.Foreground
                        }}
                      >
                        {this.state.values.OverallJudgeG}
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    type='primary'
                    icon={<EditOutlined />}
                    onClick={() =>
                      // 判定選択
                      this.showWS0285001_JudgeQuery()
                    }
                  >
                    判定
                  </Button>
                </div>

                <Table
                  bordered
                  pagination={false}
                  dataSource={this.state.guideCommentInputList}
                  size='small'
                  scroll={{ y: '400px' }}
                  rowKey={record => record.id}
                  onRow={(record) => {
                    return {
                      onDoubleClick: async () => {
                        // 編集
                        await this.showWS2637041_GuideMatterContent(record)
                      }
                    }
                  }}
                >
                  <Table.Column
                    width={80}
                    title='連番'
                    dataIndex='serial_number'
                    render={(value, record, index) => (
                      <div
                        style={{
                          textAlign: 'right',
                          color: Color(record.changeColorNum)?.Foreground
                        }}
                      >
                        <InputNumber
                          className='input-size-number-4'
                          value={value}
                          onChange={this.onChange}
                          onPressEnter={(event) => { this.saveInput(record) }}
                        />
                      </div>
                    )}
                  />

                  <Table.Column
                    width={100}
                    title='コード'
                    dataIndex='general_comment_code'
                    render={(value, record, index) => (
                      <div
                        style={{
                          textAlign: 'right',
                          color: Color(record.changeColorNum)?.Foreground
                        }}
                      >
                        {value}
                      </div>
                    )}
                  />

                  <Table.Column
                    title='指導事項内容'
                    dataIndex='overall_comment'
                    render={(value, record, index) => (
                      <div style={{ color: Color(record.changeColorNum)?.Foreground }}>
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    dataIndex='auto_judgment_output_type'
                    title='種別'
                    width={80}
                  />
                  <Table.Column
                    dataIndex='judgment_basic'
                    title='判'
                    width={80}
                    render={(value, record, index) => (
                      <div
                        style={{
                          color: Color(record.judgeBasicColorNum)?.Foreground,
                          textAlign: 'center'
                        }}
                      >
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    dataIndex='printType'
                    title='印字'
                    width={80}
                  />

                  <Table.Column
                    width={45}
                    style={{ textAlign: 'center' }}
                    title={
                      <Button
                        type='primary'
                        size='small'
                        icon={(<PlusOutlined />)}
                        onClick={() =>
                          // 新規追加の場合
                          this.showWS2637041_GuideMatterContent()
                        }
                      />
                    }
                    render={(item, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() =>
                          // 編集の場合
                          this.showWS2637041_GuideMatterContent(record, index)
                        }
                      />
                    )}
                  />
                </Table>
              </div>

              {/* Footer */}
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  onClick={() =>
                    // 前回コメント取得
                    this.showWS02641030_PreviousGuideMatterInquiry()
                  }
                >
                  前回コメント
                </Button>
              </div>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637002_OverallResultDisplayChange)
