import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Card, Table } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import TerminalListAction from 'redux/Others/PassingManageProgress/TerminalList.action'
import WS3061001_PassingControlInspectsDisplay from '../V5IS01010_PassingControlInspectsDisplay/WS3061001_PassingControlInspectsDisplay'

class WS3059005_TerminalList extends React.Component {
  static propTypes = {
    ImplementDateChar: PropTypes.any
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    document.title = '端末項目一覧'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  index() {
    TerminalListAction.index()
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
        })
      })
  }

  /**
   * 通過管理検査表示 モーダル
   * @param {*} record
   */
  showWS3061001_PassingControlInspectsDisplay = (record) => {
    // 端末番号
    let terminalNum = record.terminal_num
    // 指定日
    let date = this.props.ImplementDateChar
    let url = ''
    // 端末番号や受診日フラグがせってされていない場合、return
    if (!terminalNum) return
    // 受診日：指定
    // 例）/passing-control-inspects-display/passing-control-inspects-display/:num/:date
    url = '/passing-control-inspects-display/passing-control-inspects-display/' + String(terminalNum) + '/' + moment(date).format('YYYYMMDD') + '/false'

    // }
    // URL変更 react-router-domライブラリを仕様
    this.props.history.push(url)
    // this.setState({
    //   childModal: {
    //     ...this.state.childModal,
    //     visible: true,
    //     width: 1400,
    //     component: (
    //       <WS3061001_PassingControlInspectsDisplay
    //         ImplementDateChar={this.props.ImplementDateChar}
    //         terminal_num={record.terminal_num}
    //         match={match}
    //         onFinishScreen={() => {
    //           this.closeModal()
    //         }}
    //       />
    //     ),
    //   }
    // })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='terminal-list'>
        <Card title='端末項目一覧'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  if (this.props.selectFlag ?? false) {
                    if (this.props.onFinishScreen) {
                      this.props.onFinishScreen(record)
                    }
                  } else {
                    this.showWS3061001_PassingControlInspectsDisplay(record)
                  }
                }
              }
            }}
          >
            <Table.Column title='端末番号' dataIndex='terminal_num' />
            <Table.Column title='略式名称' dataIndex='terminal_informal_name' />
            <Table.Column title='正式名称' dataIndex='terminal_official_name' />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3059005_TerminalList)
