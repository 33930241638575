import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Modal, message, } from 'antd'
import FindingsInputPhysiciiagnosisAction from 'redux/InputBusiness/SpreadInput/FindingsInputPhysiciiagnosis.action'
// import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery'
import WS0178001_QuerySiteInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0178001_QuerySiteInfo'
import WS0179001_InquiryFindingInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0179001_InquiryFindingInfo'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'

const formItemStyle = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 770px)' } }
}

class WS0730002_FindingsInputPhysiciiagnosisChange extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '所見入力（編集用）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataForm: {},
      siteClassification: 0,
      findingsClassification: 0,
      disabledSaveBtn: true,
      siteCode: 0
    }
    this.onValuesChange = this.onValuesChange.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 変更モードの場合
    if (this.props.record) {
      // 内容をFormに反映
      this.formRef.current.setFieldsValue({
        serial_number: this.props.record.serial_number,
        findings_code: this.props.record.findings_code,
        findings_name: this.props.record.findings_name,
      })
    } else {
      // 新規の場合、連番はMAX値＋１で表示する
      this.formRef.current.setFieldsValue({
        serial_number: (this.props.maxSerialNum + 1)
      })
    }
    this.setState({
      siteClassification: this.props.site_classification ?? 0,
      siteCode: this.props.siteCode ?? 0
    })
  }


  /**
   * 所見照会 モーダル
   */
  showWS0179001_InquiryFindingInfo = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0179001_InquiryFindingInfo
            Li_FindingsClassify={this.state.siteClassification}
            onFinishScreen={(output) => {
              // 選択された部位情報を反映
              this.formRef.current.setFieldsValue({
                findings_code: output.Lo_FindingsCode,
                findings_name: output.Lo_FindingsName,
                // judgment_value: output.Lo_JudgmentValue ※医師の診断では判定が不要
              })
              this.setState({
                findingsClassification: output.recordData.findings_classification
              })
              // 保存ボタンの活性
              this.changeDisabledSaveBtn()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * 保存
   */
  save = () => {
    const id = this.props.record?.id ?? null
    // Fromに設定されている値を取得(serial_number/site_code/site_name/findings_code/findings_name/judgment_value)
    const data = this.formRef.current.getFieldsValue(true)

    const params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_CategoryCode: this.props.Li_CategoryCode,
      Li_CourseLevel: this.props.Li_CourseLevel,
      id: id,
      ...data,
      site_code: this.state.siteCode,
      site_classification: this.state.siteClassification,
      findings_classification: this.state.findingsClassification,
      user: this.props.user
    }

    FindingsInputPhysiciiagnosisAction.save(params)
      .then((res) => {
        if (this.props.onUpdateFindings) {
          // 親画面に反映
          this.props.onUpdateFindings()
        }
      })
  }

  /**
   * 削除
   */
  delete = () => {
    // 削除確認
    Modal.confirm({
      content: '削除します。よろしいですか？',
      okText: '削　除',
      cancelText: 'キャンセル',
      okType: 'danger',
      onOk: () => {
        const id = this.props.record?.id ?? null
        const params = {
          id: id,
          Li_ReserveNum: this.props.Li_ReserveNum,
          Li_InspectCode: this.props.Li_InspectCode,
          user: this.props.user
        }
        // 削除
        FindingsInputPhysiciiagnosisAction.delete(params)
          .then((res) => {
            if (this.props.onUpdateFindings) {
              // 親画面に反映
              this.props.onUpdateFindings()
            }
          })
      }
    })
  }

  /**
   * Form内で変更処理
   */
  onValuesChange = (event) => {
    // 保存ボタンの活性
    this.changeDisabledSaveBtn()
  }

  /**
   *  変更された場合、保存ボタンを活性
   */
  changeDisabledSaveBtn = () => {
    // 変更された場合、保存ボタンを活性する
    this.setState({ disabledSaveBtn: false })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='findings-input-normal-change'>
        <Card
          title={`所見入力[${this.props.siteName}] [${(this.props.record === null) ? '新規追加' : '変更'}]`}
        >
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                name='serial_number'
                label='連番'
                {...formItemStyle}
                style={{ display: (this.props.type === 1) ? 'none' : '' }} //実施理由の場合、非表示
              >
                <Input
                  style={{ textAlign: 'right' }}
                  className='input-size-5'
                />
              </Form.Item>

              <div className='box_inner_horizontal' style={{ alignItems: 'start' }}>

                <Form.Item
                  label={(this.props.type === 1) ? '理由' : '診断内容'}
                  name='findings_code' //所見コード
                  {...formItemStyle}
                >
                  <Input
                    style={{ textAlign: 'right' }}
                    className='input-size-5'
                  />
                </Form.Item>

                <Form.Item
                  name='findings_name'
                  label=''
                  {...formItemStyle}
                >
                  <Input.TextArea
                    rows={2}
                    maxLength={60}
                    className='input-size-30'
                  />
                </Form.Item>
                <Button
                  type='primary'
                  onClick={() => {
                    this.showWS0179001_InquiryFindingInfo()
                  }}
                >
                  参照
                </Button>
              </div>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: ((this.props.record === null) ? 'none' : '') }} //新規追加の場合、削除ボタンは非表示
                onClick={() => {
                  // 削除
                  this.delete()
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
                onClick={() => {
                  // 保存
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0730002_FindingsInputPhysiciiagnosisChange)
