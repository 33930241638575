import axios from "configs/axios";

const API_LIST = {
  introduceLetterReturnInfoInput: "/api/introduce-letter-return-info-input/introduce-letter-return-info-input",
  askInput: '/api/introduce-letter-return-info-input/ask-input',
  returnInfoInput: '/api/introduce-letter-return-info-input/return-info-input',
  getScreenData: '/api/introduce-letter-return-info-input/return-info-input/get-screen-data',
  saveIntroduceLetterInfo: '/api/introduce-letter-return-info-input/return-info-input/save-introduce-letter-info',
  saveReturnInfo: '/api/introduce-letter-return-info-input/return-info-input/save-return-info',
  deleteReturnInfo: '/api/introduce-letter-return-info-input/return-info-input/delete-return-info'
};

const IntroduceLetterReturnInfoInputService = {
  async filterIntroduceLetterReturnInfoInputService(params) {
    return axios.get(API_LIST.introduceLetterReturnInfoInput, { params });
  },
  async fetchAskInputService(params) {
    return axios.get(API_LIST.askInput, { params })
  },
  async fetchReturnInfoInputService(params) {
    return axios.get(API_LIST.returnInfoInput, { params })
  },
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params })
  },
  async saveIntroduceLetterInfo(params) {
    return axios.post(API_LIST.saveIntroduceLetterInfo, params)
  },
  async saveReturnInfo(params) {
    return axios.post(API_LIST.saveReturnInfo, params)
  },
  async deleteReturnInfo(params) {
    return axios.post(API_LIST.deleteReturnInfo, params)
  },
};

export default IntroduceLetterReturnInfoInputService;
