import { PlusOutlined, MoreOutlined, SearchOutlined, SaveOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Dropdown, Form, Input, Menu, message, Modal, Space, Table } from "antd"
import WS1309003_InputList from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309003_InputList.jsx'
import WS1309004_Input from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309004_Input.jsx'
import React from "react"
import { connect } from "react-redux"
import XmlParamMaintainAction from 'redux/SpecificInsureMaintenance/XmlParamMaintain/XmlParamMaintain.actions'
import WS1309011_CopyingProcess from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309011_CopyingProcess.jsx'
import WS1310001_ItemConvertCodeTblMaintain from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1310001_ItemConvertCodeTblMaintain.jsx'
import WS2485001_InsurerByCourseCheckupsType from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS2485001_InsurerByCourseCheckupsType.jsx'
import WS1315001_ItemConvertCdMaintainE from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1315001_ItemConvertCdMaintainE.jsx'
import WS1312001_XmlInspectValueConvertEM from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1312001_XmlInspectValueConvertEM.jsx'
import WS1311001_XmlInspectValueConvertMaster from 'pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1311001_XmlInspectValueConvertMaster.jsx'
import ModalDraggable from "components/Commons/ModalDraggable"

class WS1309001_XmlParamMaintain extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'V4-XML01025:XMLパラメータ保守'

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,

      },
      count: "a",
      // pagination: {
      //   defaultPageSize: 10,
      //   size: 'small',
      //   showQuickJumper: true
      // },
      dataSource: [],
      beforeDataSource: [],
      selectedRows: {},
    }
  }
  componentDidMount() {
    this.loadInit()
  }
  loadInit() {
    // this.setState({ isLoading: true })
    XmlParamMaintainAction.getInit().then(res => {
      this.formRef.current?.setFieldsValue({
        tableData: res ? res : []
      })
      this.setState({ dataSource: res, beforeDataSource: JSON.parse(JSON.stringify(res)) })
      this.forceUpdate()
    })
    // .finally(() => this.setState({ isLoading: false }))
  }
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }
  // handleAdd = async (record) => {
  //   const { count } = this.state
  //   const newData = {
  //     id: count,
  //     check: true
  //   }
  //   let index = this.formRef.current?.getFieldValue("tableData").findIndex(value => value === record)
  //   let data = [...this.formRef.current?.getFieldValue("tableData")]
  //   data.splice(index + 1, 0, newData)
  //   this.formRef.current?.setFieldsValue({
  //     tableData: data,
  //   })
  //   this.forceUpdate()
  //   await this.setState({
  //     ...this.state,
  //     count: count + 1,
  //     dataSource: data,
  //   })
  // }


  save(record) {
    XmlParamMaintainAction.save(record).then(res => {

      message.success(res?.message)

      this.setState({ beforeDataSource: JSON.parse(JSON.stringify(this.formRef.current?.getFieldValue("tableData"))) })


    }).catch(err => {

      const tmp = JSON.stringify(this.state.beforeDataSource)

      this.formRef.current?.setFieldsValue({ tableData: tmp })

      this.setState({ dataSource: tmp })

      console.log('XmlParamMaintainAction.save:err:', err)

    })
  }

  delete(record) {
    XmlParamMaintainAction.delete({ format: record.format }).then(res => {
      message.success(res?.message)

      let tmp = [...this.formRef.current?.getFieldValue("tableData")]
      let deleated = tmp.filter(obj => obj.id !== record.id)

      this.setState({ dataSource: deleated, beforeDataSource: JSON.parse(JSON.stringify(deleated)) })

      this.formRef.current?.setFieldsValue({ tableData: deleated })

      this.forceUpdate()

    }).catch(err => {
      console.log('XmlParamMaintainAction.delete:err:', err)
    })
  }
  handleF9() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS1310001_ItemConvertCodeTblMaintain
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  renderMenuBar = () => (
    <Space>
      {/* <Button type='primary' onClick={() => this.handleF7(this.state.selectedRows)}>複写</Button> */}
      <Button type='primary' onClick={() => this.handleF8()}>健診種別</Button>
      <Button type='primary' onClick={() => this.handleF9()} >特権CD</Button>
      <Button type='primary' onClick={() => this.handleF10()}>特保CD</Button>
      <Button type='primary' onClick={() => this.handleF11()}>特権変換</Button>
      <Button type='primary' onClick={() => this.handleF12()}>特保変換</Button>
    </Space>
  )

  handleF7 = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS1309011_CopyingProcess
            format={data.format}
            remarks={data.remarks}
            kind={data.kind}
            onFinishScreen={(output) => {
              this.loadInit()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  handleF8 = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS2485001_InsurerByCourseCheckupsType
            onFinishScreen={(output) => {
              this.loadInit()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  handleF10 = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS1315001_ItemConvertCdMaintainE
            onFinishScreen={(output) => {
              this.loadInit()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  handleF11 = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS1311001_XmlInspectValueConvertMaster
            onFinishScreen={(output) => {
              this.loadInit()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  handleF12 = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS1312001_XmlInspectValueConvertEM
            onFinishScreen={(output) => {
              this.loadInit()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  isDisabled = (record) => {

    if (!this.state.beforeDataSource) return true

    try {

      const foundItem = this.state.beforeDataSource.find((item) => item.id === record.id)

      return foundItem.format === record.format
        && foundItem.remarks === record.remarks
        && foundItem.Name === record.Name

    } catch (error) {

      return true

    }
  }

  render() {
    return (
      <div className="xml-param-maintain">
        <Card title="XMLパラメータ保守">
          {this.renderMenuBar()}
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete="off"
            className='mt-3'>
            <div style={{ display: 'none' }}>
              <Form.Item name="StsInspect"> <Input /></Form.Item>
              <Form.Item name="StsGuidance"> <Input /></Form.Item>
            </div>
            <Table bordered
              dataSource={this.formRef.current?.getFieldValue("tableData")}
              size="small"
              // pagination={this.state.pagination}
              pagination={false}
              // loading={this.state.isLoading}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {

                return {

                  onDoubleClick: (event) => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '90%',
                        component: (
                          <WS1309004_Input
                            format={record.format}
                            remarks={record.remarks}

                            onFinishScreen={(output) => {
                              this.loadInit()
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    })
                  },

                };
              }}

            >
              <Table.Column title="種　別" dataIndex="Expression_10" showSorterTooltip={false} sorter={(a, b) => a.Expression_10.localeCompare(b.Expression_10, 'jp')} />
              {/* <Table.Column title="ＦＯＲＭＡＴ" showSorterTooltip={false} sorter={(a, b) => a.format.localeCompare(b.format, 'jp')} render={(value, record) => {
                let index = this.findIndexByID(this.state.dataSource, record.id)
                return <Form.Item name={['tableData', index, 'format']} style={{ marginBottom: '0px' }}>
                  <Input style={{ border: 'none' }}
                    maxLength={12}
                    onChange={(e) => {
                      record.format = e.target.value
                      this.forceUpdate()
                    }} /></Form.Item>
              }} /> */}
              <Table.Column title="ＦＯＲＭＡＴ" dataIndex={'format'} showSorterTooltip={false} sorter={(a, b) => a.format.localeCompare(b.format, 'jp')} />

              <Table.Column title="備考" showSorterTooltip={false} sorter={(a, b) => a.remarks.localeCompare(b.remarks, 'jp')} render={(value, record) => {
                let index = this.findIndexByID(this.state.dataSource, record.id)
                return <Form.Item name={['tableData', index, 'remarks']} style={{ marginBottom: '0px' }}>
                  <Input
                    maxLength={20}
                    onChange={(e) => {
                      record.remarks = e.target.value
                      this.forceUpdate()
                    }} /></Form.Item>
              }} />
              <Table.Column title="名称" dataIndex="Name" showSorterTooltip={false} sorter={(a, b) => a.Name.localeCompare(b.Name, 'jp')} render={(value, record) => {
                let index = this.findIndexByID(this.state.dataSource, record.id)
                return <Form.Item name={['tableData', index, 'Name']} style={{ marginBottom: '0px' }}><Input onChange={(e) => {
                  record.Name = e.target.value
                  this.forceUpdate()
                }} /></Form.Item>
              }} />

              <Table.Column align='center' width={40}
                render={
                  (text, record, index) => (
                    <Button
                      size='small'
                      type='primary'
                      disabled={this.isDisabled(record)}
                      icon={<SaveOutlined />}
                      onClick={() => {
                        this.save(record)
                      }}
                    />
                  )
                }
              >


              </Table.Column>

              <Table.Column
                align='center'
                width={40}

                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>

                          <Menu.Item
                            key='6'
                            onClick={() => this.delete(record)}
                          >
                            削除
                          </Menu.Item>

                          <Menu.Item
                            key='5'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '40%',
                                  component: (
                                    <WS1309011_CopyingProcess
                                      format={record.format}
                                      remarks={record.remarks}
                                      kind={record.kind}
                                      onFinishScreen={(output) => {
                                        this.loadInit()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })

                            }}
                          >
                            複写
                          </Menu.Item>


                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )
                }}
              />

            </Table>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1309001_XmlParamMaintain)
