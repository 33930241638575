import axios from 'configs/axios';

const API_LIST = {
  OutputF12: '/api/contract-info-maintain/master-list-output-contract-info/Output_F12',
};

const MasterListOutputContractInfoService = {
  async OutputF12(params) {
    return axios.post(API_LIST.OutputF12, params, { responseType: 'blob' });
  },
};

export default MasterListOutputContractInfoService;
