import axios from "configs/axios";

const API_LIST = {
  loadScreenData: "/api/provisional-reservation/particulars-screen/load-screen-data",
  delete: "/api/provisional-reservation/particulars-screen/delete",
  timeChange: "/api/provisional-reservation/particulars-screen/time-change",
};

const ParticularsScreenService = {
  async loadScreenData(params) {
    return axios.post(API_LIST.loadScreenData, params);
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params });
  },
  async timeChange(params) {
    return axios.post(API_LIST.timeChange, params);
  },
};

export default ParticularsScreenService;
