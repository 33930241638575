import { message } from 'antd'
import CategoryListSettingSubService from 'services/InputBusiness/NotInputCheckCategory/CategoryListSettingSubService'

const CategoryListSettingSubAction = {
  getListData(data) {
    return CategoryListSettingSubService.GetListData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  },
  saveListData(data) {
    return CategoryListSettingSubService.SaveListData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  },
  getCategorySearchQuery(data) {
    return CategoryListSettingSubService.getCategorySearchQuery(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  },
  setCategoryData(data) {
    return CategoryListSettingSubService.setCategoryData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  },

  deleteCategoryData(data) {
    return CategoryListSettingSubService.deleteCategoryData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  },

  setChangeCategoryData(data) {
    return CategoryListSettingSubService.setChangeCategoryData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  },
}

export default CategoryListSettingSubAction
