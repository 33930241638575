import ConditionCopyService from "services/basicInfo/ContractInfoMaintain/ConditionCopyService.js";
import { message, } from "antd"

const ContractHistorySubAction = {
  loadScreenData(params) {
    return ConditionCopyService.loadScreenData(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  fillInput(params) {
    return ConditionCopyService.fillInput(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  CopyExec(params) {
    return ConditionCopyService.CopyExec(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default ContractHistorySubAction;