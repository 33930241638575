import { message } from 'antd'
import KartecoCooperationCsvOutputService from 'services/Others/KartecoCooperationCsvOutput/KartecoCooperationCsvOutputService'

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data
    })
    .catch(error => {
      const res = error.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
      }
      message.error(res.data.message)
    })
}

const KartecoCooperationCsvOutputAction = {
  index() {
    return post(KartecoCooperationCsvOutputService.index)
  },
  eventSearchButton(request) {
    return post(KartecoCooperationCsvOutputService.eventSearchButton, request)
  },
  eventCsvButton(request) {
    return post(KartecoCooperationCsvOutputService.eventCsvButton, request)
  },
  downloadZipFile(request) {
    // ダウンロード処理の為、上記のpost関数は使用しない
    return KartecoCooperationCsvOutputService.downloadZipFile(request)
  },

}
export default KartecoCooperationCsvOutputAction
