import PersonalInfoInquirySubService from 'services/CooperationRelated/InspectRequestMain/PersonalInfoInquirySubService'
import { message } from "antd"

const PersonalInfoInquirySubAction = {
  index(data) {
    return PersonalInfoInquirySubService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
}

export default PersonalInfoInquirySubAction
