import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Input, Select, Row, Col, Space, Button, message, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center',
  marginBottom: '10px'
}
const styleFormItem = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  margin: 0,
}

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;

    return (
      <Tooltip
        trigger={['focus']}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder="Input a number"
          maxLength={25}
        />
      </Tooltip>
    );
  }
}

class WS1319001_InsurerInfo extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-TMS01030:保険者情報';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      count: 'a',
      selectedRow: {},
    };

    this.handleAddRowTable = this.handleAddRowTable.bind(this);
  }

  componentDidMount() {
    this.loadScreenData();
    this.formRef.current?.setFieldsValue({
      tableData: [],
    })
  }

  loadScreenData() {
    VenusApiRoutesV2.callApi("API001319001002", { Li_Year: this.props.Li_Year })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          tableData: res,
        })
        this.setState({ dataSource: res });
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally();
  }

  save(record) {
    VenusApiRoutesV2.callApi("API001319001023", { mainSource: [{ id: record.id, insurer_number: record.insurer_number, year: this.props.Li_Year }] })
      .then(res => {
        if (res.message) {
          message.success(res.message)
        } else {
          message.error(res);
        }
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally();
  }

  delete(record) {
    VenusApiRoutesV2.callApi("API001319001024", { mainSource: [{ id: record.id, insurer_number: record.insurer_number, year: this.props.Li_Year }] })
      .then(res => {
        if (res.message) {
          message.success(res.message)
          this.loadScreenData();
        } else {
          message.error(res);
        }
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally();
  }

  async handleAddRowTable() {
    let data = [...this.formRef.current?.getFieldValue("tableData")];
    let newObj = { id: this.state.count, insurer_code: null, insurer_kanji_name: '' }

    data.unshift(newObj);
    this.formRef.current?.setFieldsValue({
      tableData: data
    })

    await this.setState({
      dataSource: data,
      count: this.state.count + 1,
    });
    this.forceUpdate();
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="insurer-info">
        <Card className="mb-2" title="V4-TMS01030:保険者情報">
          <Form
            ref={this.formRef} labelCol={{ span: 6 }} style={{ marginTop: '30px' }}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={12}>
                <Table bordered
                  style={{ paddingRight: '10px' }}
                  dataSource={this.formRef.current?.getFieldValue("tableData") ? this.formRef.current?.getFieldValue("tableData") : []}
                  pagination={false}
                  rowKey={(record) => record.id}
                  scroll={{ y: resizableTableScroll(120) }}
                  rowSelection={{
                    type: 'radio',
                    onChange: async (selectedRowKeys, selectedRows) => {
                      await this.setState({
                        selectedRow: selectedRows[0]
                      })
                    }
                  }}
                >
                  <Table.Column
                    title="保険者コード"
                    dataIndex="insurer_number"
                    showSorterTooltip={false}
                    render={(value, record, index) => {
                      return (
                        <Form.Item name={["tableData", index, "insurer_number"]} style={{ textAlign: 'right' }}>
                          <Input.Search onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '70%',
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    onFinishScreen={(output) => {
                                      let data = this.formRef.current?.getFieldValue("tableData");
                                      data[index]['insurer_number'] = output.Lo_InsurerCode;
                                      data[index]['insurer_kanji_name'] = output.Lo_Name;
                                      this.closeModal()
                                    }}
                                  />),
                              },
                            })
                          }} />
                        </Form.Item>
                      )
                    }} />
                  <Table.Column
                    title="保険者名"
                    dataIndex="insurer_kanji_name"
                    showSorterTooltip={false}
                    render={(value, record, index) => {
                      return (
                        <Form.Item name={["tableData", index, "insurer_kanji_name"]} style={{ textAlign: 'right' }}>
                          <span>{record.insurer_kanji_name}</span>
                        </Form.Item>
                      )
                    }} />
                  <Table.Column align="center" width={'90px'} title={<Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />} onClick={this.handleAddRowTable}  ></Button>}
                    render={(item, record, index) => {
                      return (
                        <Row>
                          <Col span={12}>
                            <Button style={{ border: 'none' }} icon={<SaveOutlined style={{ color: 'green' }} />} onClick={() => { this.save(record) }}></Button>
                          </Col>
                          <Col span={12}>
                            <Button danger style={{ border: 'none' }} onClick={() => { this.delete(record) }} icon={<DeleteOutlined />}></Button>
                          </Col>
                        </Row>
                      )
                    }} />
                </Table>
              </Col>

              <Col span={12}>
                <Row>
                  <Col span={4}>
                    <div style={styleDivTitle}>基本情報</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div style={{ float: 'right', marginRight: '20px', marginBottom: '15px' }}>
                      健診内容区分
                    </div>
                  </Col>
                  <Col span={16}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.medical_exam_content_division}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.medical_exam_content_division = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={1}>特定健診のみ</Select.Option>
                        <Select.Option value={2}>その他健診項目あり</Select.Option>
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row >
                  <Col span={6}>
                    <div style={{ float: 'right', marginRight: '20px' }}>
                      事業主健診の有無
                    </div>
                  </Col>
                  <Col span={16}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.presence_or_absence_of_exam_employer_health}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.presence_or_absence_of_exam_employer_health = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={1}>事業主健診を含む</Select.Option>
                        <Select.Option value={2}>被扶養者として受診</Select.Option>
                      </Select>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={4}>
                    <div style={styleDivTitle}>特定健診</div>
                  </Col>
                </Row>

                <Row style={{ paddingBottom: '10px' }}>
                  <Col span={4}>
                    <div style={{ float: 'right', marginRight: '20px' }}>
                      基本部分
                    </div>
                  </Col>
                  <Col span={11} style={{ paddingRight: '10px' }}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.specific_medical_exam_basic_burden_division}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.specific_medical_exam_basic_burden_division = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={0}>{" "}</Select.Option>
                        <Select.Option value={1}> 負担なし</Select.Option>
                        <Select.Option value={2}> 定額負担</Select.Option>
                        <Select.Option value={3}> 定率負担</Select.Option>
                        <Select.Option value={4}> 保険者上限</Select.Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={7}>
                    <div>
                      <NumericInput
                        style={{ textAlign: 'right' }}
                        value={this.state.selectedRow?.specific_medical_exam_basic_burden_contents ?
                          this.state.selectedRow?.specific_medical_exam_basic_burden_contents :
                          0
                        }
                        onChange={(value) => {
                          let selectedRow = { ...this.state.selectedRow };
                          selectedRow.specific_medical_exam_basic_burden_contents = value;
                          this.setState({ selectedRow });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ paddingBottom: '10px' }}>
                  <Col span={4}>
                    <div style={{ float: 'right', marginRight: '20px' }}>
                      詳細部分
                    </div>
                  </Col>
                  <Col span={11} style={{ paddingRight: '10px' }}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.specific_medical_exam_details_burden_division}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.specific_medical_exam_details_burden_division = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={0}>{" "}</Select.Option>
                        <Select.Option value={1}> 負担なし</Select.Option>
                        <Select.Option value={2}> 定額負担</Select.Option>
                        <Select.Option value={3}> 定率負担</Select.Option>
                        <Select.Option value={4}> 保険者上限</Select.Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={7}>
                    <div>
                      <NumericInput
                        style={{ textAlign: 'right' }}
                        value={this.state.selectedRow?.specific_medical_exam_details_burden_contents ?
                          this.state.selectedRow?.specific_medical_exam_details_burden_contents :
                          0
                        }
                        onChange={(value) => {
                          let selectedRow = { ...this.state.selectedRow };
                          selectedRow.specific_medical_exam_details_burden_contents = value;
                          this.setState({ selectedRow });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: '10px' }}>
                  <Col span={4}>
                    <div style={{ float: 'right', marginRight: '20px' }}>
                      追加項目
                    </div>
                  </Col>
                  <Col span={11} style={{ paddingRight: '10px' }}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.other_add_burden_division}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.other_add_burden_division = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={0}>{" "}</Select.Option>
                        <Select.Option value={1}> 負担なし</Select.Option>
                        <Select.Option value={2}> 定額負担</Select.Option>
                        <Select.Option value={3}> 定率負担</Select.Option>
                        <Select.Option value={4}> 保険者上限</Select.Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={7}>
                    <div>
                      <NumericInput
                        style={{ textAlign: 'right' }}
                        value={this.state.selectedRow?.other_add_burden_contents ?
                          this.state.selectedRow?.other_add_burden_contents :
                          0
                        }
                        onChange={(value) => {
                          let selectedRow = { ...this.state.selectedRow };
                          selectedRow.other_add_burden_contents = value;
                          this.setState({ selectedRow });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ paddingBottom: '10px' }}>
                  <Col span={4}>
                    <div style={{ float: 'right', marginRight: '20px' }}>
                      人間ﾄﾞｯｸ
                    </div>
                  </Col>
                  <Col span={11} style={{ paddingRight: '10px' }}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.other_dock_burden_division}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.other_dock_burden_division = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={0}>{" "}</Select.Option>
                        <Select.Option value={1}> 負担なし</Select.Option>
                        <Select.Option value={2}> 定額負担</Select.Option>
                        <Select.Option value={3}> 定率負担</Select.Option>
                        <Select.Option value={4}> 保険者上限</Select.Option>
                        <Select.Option value={5}> 保険者上限時定率負担</Select.Option>
                        <Select.Option value={6}> 保険者上限時定額負担</Select.Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={7}>
                    <div>
                      <NumericInput
                        style={{ textAlign: 'right' }}
                        value={this.state.selectedRow?.other_dock_burden_contents ?
                          this.state.selectedRow?.other_dock_burden_contents :
                          0
                        }
                        onChange={(value) => {
                          let selectedRow = { ...this.state.selectedRow };
                          selectedRow.other_dock_burden_contents = value;
                          this.setState({ selectedRow });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row >
                  <Col span={9}></Col>
                  <Col span={6}>
                    <div style={{ float: 'right', marginRight: '10px' }}>
                      保険者上限額
                    </div>
                  </Col>

                  <Col span={7}>
                    <div>
                      <NumericInput
                        style={{ textAlign: 'right' }}
                        value={this.state.selectedRow?.other_dock_insurer_burden_upper_limit ?
                          this.state.selectedRow?.other_dock_insurer_burden_upper_limit :
                          0
                        }
                        onChange={(value) => {
                          let selectedRow = { ...this.state.selectedRow };
                          selectedRow.other_dock_insurer_burden_upper_limit = value;
                          this.setState({ selectedRow });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={4}>
                    <div style={styleDivTitle}>代行機関</div>
                  </Col>
                </Row>

                <Row>
                  <Col span={15} style={{ paddingLeft: '300px', paddingRight: '10px' }}>
                    <div>
                      <Select style={{ width: '100%' }}
                        value={this.state.selectedRow?.contract_agencies_other_visit_able}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.contract_agencies_other_visit_able = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      >
                        <Select.Option value={1}> 可能</Select.Option>
                        <Select.Option value={0}> 不可</Select.Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={7}>
                    <div>
                      <Input
                        value={this.state.selectedRow?.contract_agencies_other_agency}
                        style={{ textAlign: 'right' }}
                        onChange={(value) => {
                          let data = { ...this.state.selectedRow };
                          data.contract_agencies_other_agency = value;
                          this.setState({
                            selectedRow: data,
                          })
                        }}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1319001_InsurerInfo);
