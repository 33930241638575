import axios from 'configs/axios';

const apiPaths = {
  getDataInputMetabolicSyndromeHierarchical: '/api/specific-health-tokuho-param-maintain/input-metabolic-syndrome-hierarchical',
  saveInputMetabolicSyndromeHierarchical: '/api/specific-health-tokuho-param-maintain/input-metabolic-syndrome-hierarchical/save-and-update',
  inspectCodeInputMetabolicSyndromeHierarchical: '/api/specific-health-tokuho-param-maintain/input-metabolic-syndrome-hierarchical/inspect-code',
  deleteInputMetabolicSyndromeHierarchical: '/api/specific-health-tokuho-param-maintain/input-metabolic-syndrome-hierarchical/delete'
};

const InputMetabolicSyndromeHierarchicalService = {
  async getDataInputMetabolicSyndromeHierarchicalService(params) {
    return axios.get(apiPaths.getDataInputMetabolicSyndromeHierarchical, { params })
      .then(res => { return res })
      .catch(err => { throw err.response.data.message });
  },
  async saveInputMetabolicSyndromeHierarchicalService(params) {
    return axios.post(apiPaths.saveInputMetabolicSyndromeHierarchical, params)
      .then(res => { return res.data.message })
      .catch(err => { throw err.response.data.message });
  },
  async inspectCodeInputMetabolicSyndromeHierarchicalService(params) {
    return axios.post(apiPaths.inspectCodeInputMetabolicSyndromeHierarchical, params)
  },
  async deleteInputMetabolicSyndromeHierarchicalService(params) {
    return axios.delete(apiPaths.deleteInputMetabolicSyndromeHierarchical, { params })
      .then(res => { return res.data.message }).catch(err => { throw err.response.data.message })
  },
};

export default InputMetabolicSyndromeHierarchicalService;