import { message } from "antd";
import DocumentManageMaintainService from "services/AdvancePreparation/DocumentManageMaintain/DocumentManageMaintainService";

const DocumentManageMaintainAction = {
  GetMainInit(data) {
    return DocumentManageMaintainService.getMainInit(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  GetDetailSub(data) {
    return DocumentManageMaintainService.getDetailSub(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  Delete(data) {
    return DocumentManageMaintainService.delete(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  SaveAndUpdate(data) {
    return DocumentManageMaintainService.saveAndUpdate(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  DeleteDetailSub(data) {
    return DocumentManageMaintainService.deleteDetailSub(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  AddNewData(data) {
    return DocumentManageMaintainService.addNewData(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  Update(data) {
    return DocumentManageMaintainService.update(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  ChangeCheckbox(data) {
    return DocumentManageMaintainService.changeCheckbox(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  SaveAndUpdateParameter(data) {
    return DocumentManageMaintainService.saveAndUpdateParameter(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  DeleteParameter(data) {
    return DocumentManageMaintainService.deleteParameter(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  GetDetailSubEdit(data) {
    return DocumentManageMaintainService.getDetailSubEdit(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }
}
export default DocumentManageMaintainAction;