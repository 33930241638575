import React from "react";
import { connect } from "react-redux";
import { Card, Input, Form, Modal, Button, Table, Dropdown, Menu } from "antd";
import { SaveOutlined, MoreOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";

class WS1872002_ExpansionDisplay extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '読影検査保守 拡大';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      displayName: '',
      record: []
    };
  }

  componentDidMount() {
    let name = ''
    if (this.props.number == 1) {
      name = '部位1'
    } else if (this.props.number == 2) {
      name = '部位2'
    } else if (this.props.number == 3) {
      name = '所見1'
    } else if (this.props.number == 4) {
      name = '所見2'
    }

    this.formRef.current?.setFieldsValue({
      record: this.props.record
    })

    this.setState({
      displayName: name
    })
  }

  render() {
    return (
      <div className="ExpansionDisplay">
        <Card
          title={this.state.displayName}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Table
                bordered
                dataSource={this.formRef.current?.getFieldValue('record')}
                loading={this.state.isLoading}
                size="small"
                className='mt-3'
                pagination={false}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: 650 }}
                onRow={(record, index) => ({
                  // onClick: e => this.setState({ rowSelect: record }),
                  // onDoubleClick: () => { this.editData(false, record) }
                })}
              >

                {
                  this.props.number == 1 || this.props.number == 2 ?
                    <Table.Column
                      className='column-size-10'
                      title="部位コード"
                      dataIndex="site_code"
                      render={(text, record, index) => (
                        <div style={{ textAlign: 'right' }}> {text} </div>
                      )}
                    />
                    :
                    null
                }

                {
                  this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      className="column-size-10"
                      title="所見コード"
                      dataIndex="findings_code"
                      render={(text, record, index) => (
                        <div style={{ textAlign: 'right' }}> {text} </div>
                      )}
                    />
                    :
                    null
                }

                {
                  this.props.number == 1 || this.props.number == 2 ?
                    <Table.Column
                      width={'10rem'}
                      title="部位名称"
                      dataIndex="site_name"
                    />
                    :
                    null
                }

                {
                  this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      width={'10rem'}
                      title="所見名称"
                      dataIndex="findings_name"
                    />
                    :
                    null
                }

                {
                  this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      className='column-size-4'
                      title="判定"
                      dataIndex="judgement"
                      render={(text, record, index) => (
                        <div style={{ textAlign: 'center' }}> {text} </div>
                      )}
                    />
                    :
                    null
                }

                {
                  this.props.number == 1 || this.props.number == 2 ?
                    <Table.Column
                      className="column-size-10"
                      title="部位分類1"
                      dataIndex="site_classification_io"
                    />
                    :
                    null
                }

                {
                  this.props.number == 1 || this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      className="column-size-10"
                      title="所見分類1"
                      dataIndex="findings_classification_i"
                    />
                    :
                    null
                }

                {
                  this.props.number == 2 || this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      className="column-size-10"
                      title="所見分類2"
                      dataIndex="findings_classification_i"
                    />
                    :
                    null
                }

                {
                  this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      className="column-size-10"
                      title="所見分類3"
                      dataIndex=""
                    />
                    :
                    null
                }

                {
                  this.props.number == 3 || this.props.number == 4 ?
                    <Table.Column
                      className='column-size-4'
                      title="正常"
                      dataIndex="normal_value_flag"
                      render={(text, record, index) => (
                        <div style={{ textAlign: 'center' }}>
                          {text === 0 ? '' : <CheckOutlined />}
                        </div>
                      )}
                    />
                    :
                    null
                }

                <Table.Column
                  className='column-size-5'
                  title="表示順"
                  dataIndex="display_order"
                  render={(text, record, index) => (
                    <div style={{ textAlign: 'right' }}> {text} </div>
                  )}
                />

                <Table.Column
                  title="出力文字"
                  dataIndex=""
                />

                {/* <Table.Column
                  fixed="right"
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      style={{ textAlign: 'center' }}
                      disabled={this.state.isAddRow}
                      onClick={() => {
                        this.editDataSite2(true, false)
                      }}
                    />
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => {
                                this.editDataSite2(false, record)
                              }}
                            >変更
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                /> */}

              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1872002_ExpansionDisplay);