import axios from "configs/axios";

const APP_LIST = {
  getScreenData: "/api/specific-insure-guide-settle-process-list/contract-select-screen/get-screen-data",
  contractSelectScreen_1: "/api/specific-insure-guide-settle-process-list/contract-select-screen/contract-select-screen-1",
  exit: "/api/specific-insure-guide-settle-process-list/contract-select-screen/exit",
};

const ContractSelectScreenService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async contractSelectScreen_1(params) {
    return axios.get(APP_LIST.contractSelectScreen_1, { params });
  },
  async exit(params) {
    return axios.post(APP_LIST.exit, params);
  },
};

export default ContractSelectScreenService;
