import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Form, Input, Select, Checkbox, Button, Modal, message, } from 'antd'
import WS3020054_StyleQuery from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020054_StyleQuery.jsx'
import WS3022038_BindingModeSetting from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS3022038_BindingModeSetting.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import CoupledPrintInstruction from 'redux/InputBusiness/NotInputCheckCategory/CoupledPrintInstruction.action'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { download_file } from 'helpers/CommonHelpers'
import { PrinterOutlined } from '@ant-design/icons'
import moment from 'moment'

const smGrid = {
  labelCol: { style: { width: '65px' } }
}
class WS3020036_CoupledPrintInstruction extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_Parameters: PropTypes.any,

  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '結合印刷指示'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      Style: '',
      style_name: '',
      NumCopies: '',
      InputFlag: [],
      L1Output: false,
      L2Output: false,
      L3Output: false,
      Submission: false,
      OrgsTowards: null,
      ComboBox_OrgsTowards: [],
      isLoadingPrint: false,
      disabledBtn: true,
    }
  }

  /**
   * 初期表示
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    const params = {
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_Parameters: this.props.Li_Parameters || '',
    }
    CoupledPrintInstruction.index(params)
      .then(res => {
        this.setState({
          Style: res.Style,
          style_name: res.style_name,
          NumCopies: res.NumCopies,
          InputFlag: res.InputFlag,
          L2Output: res.L2Output,
          L3Output: res.L3Output,
          Submission: res.Submission,
          OrgsTowards: res.OrgsTowards,
          // Expression_12: res.CommentControl,
          Expression_13: res.UseSubmission,
          ComboBox_OrgsTowards: res.ComboBox_OrgsTowards,
          disabledBtn: !res.Style
        })
        let data = {
          ...res,
          OrgsTowards: 0,
          style_name: res.style_name,
        }
        this.formRef.current.setFieldsValue(data)
        if (this.props.Style || this.props.style_name) {
          this.setFixedStyle()
        }
      })
  }

  setFixedStyle = () => {
    this.setState({
      Style: this.props.Style,
      style_name: this.props.style_name
    })
    this.formRef.current?.setFieldsValue({
      Style: this.props.Style,
      style_name: this.props.style_name
    })
  }

  /**
   * 印刷処理
   * @param {*} output
   */
  print = (output) => {
    this.setState({ isLoadingPrint: true })

    if (output.Excel) {
      let params = {
        StsConfirm: 6,
        Li_CourseLevel: this.props.Li_CourseLevel || '',
        Li_ReserveNum: this.props.Li_ReserveNum || '',
        Style: this.formRef.current.getFieldValue('Style'),
        PrinterNum: 0,
        Preview: 2,
        NumCopies: Number(this.formRef.current?.getFieldValue('NumCopies')) ?? this.state.NumCopies,
        InputFlag: this.formRef.current.getFieldValue('InputFlag'),
        L1Output: this.state.L1Output ? 1 : 0,
        L2Output: this.state.L2Output ? 1 : 0,
        L3Output: this.state.L3Output ? 1 : 0,
        OrgsTowards: this.formRef.current.getFieldValue('OrgsTowards'),
        Submission: this.state.Submission ? 1 : 0,
      }

      CoupledPrintInstruction.outputExcel(params)
        .then(res => {
          let disposition = res.headers['content-disposition']
          let pattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          let filename = disposition.match(pattern)
          let extension = filename[1].split('.').pop()
          let today = moment()
          let saveas = today.format("YYYYMMDDHHmmss") + '.' + extension

          let fileURL = URL.createObjectURL(res.data)
          let a = document.createElement("a")
          a.href = fileURL
          a.download = saveas
          a.click()
          URL.revokeObjectURL(fileURL)
        })
        .catch(error => {
          const res = error.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
        .finally(() => {
          this.setState({ isLoadingPrint: false })
        })

      return
    }
    if (output.Lo_Preview) {
      let params = {
        StsConfirm: 6,
        Li_CourseLevel: this.props.Li_CourseLevel || '',
        Li_ReserveNum: this.props.Li_ReserveNum || '',
        Style: this.formRef.current.getFieldValue('Style'),
        PrinterNum: output.Lo_PrinterNo,
        Preview: output.Lo_Preview ? 1 : 0,
        NumCopies: Number(this.formRef.current?.getFieldValue('NumCopies')) ?? this.state.NumCopies,
        InputFlag: this.formRef.current.getFieldValue('InputFlag'),
        L1Output: this.state.L1Output ? 1 : 0,
        L2Output: this.formRef.current.getFieldValue('L2Output') ? 1 : 0,
        L3Output: this.formRef.current.getFieldValue('L3Output') ? 1 : 0,
        OrgsTowards: this.formRef.current.getFieldValue('OrgsTowards') ? 1 : 0,
        Submission: this.formRef.current.getFieldValue('Submission') ? 1 : 0,
      }
      CoupledPrintInstruction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);

          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
        })
        .catch(error => {
          const res = error.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
        .finally(() => {
          this.setState({ isLoadingPrint: false })
        })
    } else {
      let params = {
        StsConfirm: 6,
        Li_CourseLevel: this.props.Li_CourseLevel || '',
        Li_ReserveNum: this.props.Li_ReserveNum || '',
        Style: this.formRef.current.getFieldValue('Style'),
        PrinterNum: output.Lo_PrinterNo,
        Preview: output.Lo_Preview ? 1 : 0,
        NumCopies: Number(this.formRef.current?.getFieldValue('NumCopies')) ?? this.state.NumCopies,
        InputFlag: this.formRef.current.getFieldValue('InputFlag'),
        L1Output: this.state.L1Output ? 1 : 0,
        L2Output: this.formRef.current.getFieldValue('L2Output') ? 1 : 0,
        L3Output: this.formRef.current.getFieldValue('L3Output') ? 1 : 0,
        OrgsTowards: this.formRef.current.getFieldValue('OrgsTowards') ? 1 : 0,
        Submission: this.formRef.current.getFieldValue('Submission') ? 1 : 0,
      }
      CoupledPrintInstruction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
        .catch(error => {
          const res = error.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
        .finally(() => {
          this.setState({ isLoadingPrint: false })
        })
    }
  }

  /**
   * 様式照会 モーダル
   */
  showStyleInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS3020054_StyleQuery
            onFinishScreen={(Output) => {
              this.formRef.current.setFieldsValue({
                Style: Output.Lio_ParentCode,
                style_name: Output.recordData.style_name
              })
              this.setState({ style_name: Output.recordData.style_name })
              this.setState({ disabledBtn: false })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  /**
   * 結合様式設定 モーダル
   */
  showWS3022038_BindingModeSetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component:
          <WS3022038_BindingModeSetting
            onClickedCreate={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ,
      },
    })
  }

  /**
   * プリンター確認 モーダル
   */
  showWS0433001_PrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={this.formRef.current?.getFieldValue("PreviewPresence")}
            Li_PrinterNoSpecifiedValue={this.formRef.current?.getFieldValue("PrinterNum")}
            excelOutput={true}
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                this.print(output)
              }
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ,
      },
    })
  }

  preview = () => {
    const data = {
      Lo_Preview: true,
      Lo_PrinterNo: 0,
      Lo_StsOutput: true,
      Preview: true
    }
    this.print(data)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='coupled-print-instruction' style={{ width: 700 }}>
        <Card title='結合印刷指示'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              initialValues={{ InputFlag: '', OrgsTowards: 0 }}
            >
              <div className='box_inner_horizontal'>
                <Form.Item
                  name='Style'
                  label='様式'
                  {...smGrid}
                >
                  <Input.Search type='text'
                    className='input-search-size-number-5'
                    onSearch={() => {
                      this.showStyleInquiry()
                    }}

                    onDoubleClick={() => {
                      this.showStyleInquiry()
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='style_name'
                >
                  <span>{this.state.style_name}</span>
                </Form.Item>
              </div>

              <Form.Item
                name='NumCopies'
                label='部数'
                {...smGrid}
              >
                <Input className='input-size-number-5' type='tel' min='0' />
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item
                  name='InputFlag'
                  label='コメント'
                  {...smGrid}
                >
                  <Select
                    style={{ width: '150px' }}
                  >
                    <Select.Option value='0'>通常</Select.Option>
                    <Select.Option value='1'>シークレット</Select.Option>
                    <Select.Option value='2'>全て</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name='L2Output'
                  label=''
                  valuePropName='checked'
                >
                  <Checkbox
                    style={{ color: '#14468C', fontWeight: 'bold' }}>
                    L2
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name='L3Output'
                  label=''
                  valuePropName='checked'
                >
                  <Checkbox
                    style={{ color: '#14468C', fontWeight: 'bold' }}>
                    L3
                  </Checkbox>
                </Form.Item>
              </div>

              <Form.Item
                name='Submission'
                label='提出用'
                valuePropName='checked'
                {...smGrid}
              >
                <Checkbox disabled={!this.state.Expression_13}></Checkbox>
              </Form.Item>

              <Form.Item
                name='OrgsTowards'
                label='提出先'
                {...smGrid}
              >
                <Select style={{ width: 100 }}>
                  {this.state.ComboBox_OrgsTowards.map((res, index) => (
                    <Select.Option
                      key={'OrgsTowards' + index}
                      value={res.LinkedField}
                    >
                      {res.DisplayField}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  onClick={() => {
                    // 結合様式設定 モーダル
                    this.showWS3022038_BindingModeSetting()
                  }}
                >
                  設定
                </Button>

                <Button
                  type='primary'
                  disabled={this.state.disabledBtn}
                  onClick={() => {
                    this.preview()
                  }}
                >
                  <span className='btn_label'>
                    プレビュー
                  </span>
                </Button>

                <Button
                  type='primary'
                  icon={<PrinterOutlined />}
                  disabled={this.state.disabledBtn}
                  onClick={() => {
                    // プリンター確認 モーダル
                    this.showWS0433001_PrinterConfirm()
                  }}
                >
                  <span className='btn_label'>
                    印刷
                  </span>
                </Button>
              </div>
            </Form>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3020036_CoupledPrintInstruction)
