import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Space, Checkbox } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

class WS3127002_OutputConfirm extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '出力確認'

    this.state = {
    }
  }
  componentDidMount() {
    // 親画面からパラメータを設定
    this.formRef.current?.setFieldsValue({
      'OutputPath': this.props.OutputPath,
      'StsExamineeCsv': this.props.StsExamineeCsv,
      'StsResultDataCsv': this.props.StsResultDataCsv
    })
  }

  /**
   * [出力]ボタン押下処理
   */
  outputButton() {
    const output = {
      'OutputPath': this.formRef.current?.getFieldValue('OutputPath'),
      'StsExamineeCsv': this.formRef.current?.getFieldValue('StsExamineeCsv') ? 1 : 0,
      'StsResultDataCsv': this.formRef.current?.getFieldValue('StsResultDataCsv') ? 1 : 0,
      'StsOutput': 1,
    }
    // 親画面の処理を実行
    this.props.onFinishScreen(output)
  }

  render() {
    return (
      <div>
        <Form
          ref={this.formRef}
        >
          <Card title='出力確認' >
            <Space style={{ display: 'block', padding: '15px 20px 10px 20px', border: '1px solid #DDD' }}>

              {/* 出力先のパス指定は現在していない。。。 */}
              <Form.Item
                name='OutputPath'
                label='出力先'
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>

              <Space style={{ padding: '5px 0', }}>
                <Form.Item
                  label='データ'
                  name='StsExamineeCsv'
                  valuePropName='checked'
                >
                  <Checkbox >受診予定者CSV</Checkbox>
                </Form.Item>
                <Form.Item
                  name='StsResultDataCsv'
                  valuePropName='checked'
                >
                  <Checkbox >結果データCSV</Checkbox>
                </Form.Item>
              </Space>
            </Space>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<DownloadOutlined />}
                onClick={() => this.outputButton()}
              >
                <span className='btn_label'>
                  出力
                </span>
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3127002_OutputConfirm)
