import CopyingProcessService from "services/SystemMaintenance/UserParamMaintain/CopyingProcessService"
import { message } from "antd";

const CopyingProcessAction = {
  getScreenData() {
    return CopyingProcessService.getScreenData()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  f12Key(data) {
    return CopyingProcessService.f12Key(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }

}
export default CopyingProcessAction;