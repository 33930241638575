import axios from "configs/axios";

const API_LIST = {
  confirm: "/api/insure-guide-init-input/add-inspect/sub-update",
};

const AddInspectService = {
  async confirm(params) {
    return axios.post(API_LIST.confirm, params);
  },
};

export default AddInspectService;
