import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { Regex } from "helpers/CommonHelpers";
import { Card, Form, Input, Checkbox, Select, Button, Table, Modal, Row, Col, message, InputNumber, Tooltip, Dropdown, Menu } from "antd";
import { SearchOutlined, MenuOutlined, MoreOutlined } from "@ant-design/icons";
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx';
import WS2787004_AffiliationSelectSub from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS2787004_AffiliationSelectSub.jsx';
import WS1001033_BreakdownInquiry from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1001033_BreakdownInquiry.jsx';
import WS1001034_SelectBreakdown from "./WS1001034_SelectBreakdown";
import RomotoArticle52Actions from "redux/ResultOutput/RomotoArticle52/RomotoArticle52.actions";
import WS1004001_SettingRomotoArticle52 from "./WS1004001_SettingRomotoArticle52";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

const dateFormat = 'YYYY/MM/DD';

const marginBottom = {
  marginBottom: 0
}

const labelCol = {
  labelCol: { style: { width: 60 } },
}

class WS1001001_RomotoArticle52 extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '労基５２条';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      initparams: {
        Office: '',
        BranchOfficeShop: '',
        StsOfficeIntegration: 0,
        AffiliationCodeF: '',
        AffiliationCodeT: '',
        GissueStartDate: moment().format(dateFormat), // GissueStartDateChar
        GissueEndDate: moment().format(dateFormat), // GissueEndDateChar
        GextractConditionScreen: '0',
        GbreakdownOutput: 0,
        GselectScreen: '',
        AggregateStyle: '',
        GextractCondition: '',
        // personCntList: {}
      },
      menuItems: [
        { id: 1, label: '印刷', handleClick: this.Print_F12 },
      ],
      menus: [
        { id: 1, label: '労基52条設定', handleClick: this.VariousSetting01 }
      ],
      objInit: {},
      GextractConditionScreenList: [],
      ReportingYear: moment().format('YYYY'),
      Expression_5: true, // check show table column 該　当　者
      Expression_79: false,
      Expression_70: true,
      listIDIssueList: [],
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      objIssueList: {},
      selectedRowKeys: [],
      selectedRows: [],
      selectIdList: [],
      selectOfficeCode: '',
      selectBranchOfficeShop: ''
    };
  }


  componentDidMount = () => {
    this.loadInitData(this.state.ReportingYear);
  }


  loadInitData = (params) => {
    RomotoArticle52Actions.getScreenDataRomotoArticle52Action({ ReportingYear: params })
      .then(res => {
        if (res.data) {
          this.setState({
            objInit: res.data,
            ReportingYear: res.data.ReportingYear,
            Expression_70: !res.data.BreakdownFormat !== '',
            GextractConditionScreenList: res.data.GextractConditionScreen_List,
            initparams: {
              ...this.state.initparams,
              GselectScreen: res.data.GselectScreen,
              AggregateStyle: res.data.AggregateStyle,
              GextractCondition: res.data.GextractCondition,
            }
          });
          this.formRef?.current?.setFieldsValue({
            ReportingYear: res.data.ReportingYear,
            ReportingYearSumHistory: res.data.ReportingYearSumHistory,
          })
          this.forceUpdate()
        }
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
  }


  /**
   * 検索ボタン処理
   */
  loadData = () => {
    this.setState({
      isLoading: true,
      dataSource: [],
      rowSelect: {}
    });

    let params = {
      ...this.state.initparams,
      GissueStartDate: moment(this.state.initparams.GissueStartDate).format('YYYY/MM/DD'),
      GissueEndDate: moment(this.state.initparams.GissueEndDate).format('YYYY/MM/DD')
    }

    RomotoArticle52Actions.getDataIssueListAction(params)
      .then(res => {
        if (res?.data) {
          let data = res.data.IssueList;
          let arrID = [];
          if (data.length > 0) {
            data.forEach(element => {
              if (element.id) arrID.push(element.id)
              element.selectIdList = []
            });
          }

          this.setState({
            dataSource: data,
            selectedRows: data,
            selectedRowKeys: arrID,
          })

          // if ((this.state.selectOfficeCode !== this.state.initparams.Office)) {
          //   this.setState({
          //     objIssueList: res.data,
          //     dataSource: data,
          //     listIDIssueList: arrID,
          //     selectedRowKeys: arrID,
          //     selectedRows: data,
          //     selectOfficeCode: '',
          //     selectBranchOfficeShop: '',
          //     selectIdList: []
          //   });
          // } else {
          //   this.setState({
          //     objIssueList: res.data,
          //     dataSource: data,
          //     listIDIssueList: arrID,
          //     selectedRowKeys: arrID,
          //     selectedRows: data,
          //   });
          // }
        }
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }


  /**
   * 事業所情報検索・照会
   */
  openModalWS0247001 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.state.initparams.Office}
            Lio_BranchStoreCode={this.state.initparams.BranchOfficeShop}

            onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name }) => {
              this.formRef.current.setFieldsValue({
                Office: Lio_OfficeCode,
                BranchOfficeShop: Lio_BranchStoreCode === 0 ? '' : Lio_BranchStoreCode,
                office_kanji_name: Lo_Kanji_Name,
              });

              this.setState({
                initparams: {
                  ...this.state.initparams,
                  Office: Lio_OfficeCode,
                  BranchOfficeShop: Lio_BranchStoreCode,
                },
                Expression_5: Lio_OfficeCode ? false : true,
              })
              this.closeModal()
            }}
          />),
      },
    })
  }


  /**
  * 事業所名取得処理
  * @param {*} objChange
  */
  getNameOfficeCode = (objChange) => {
    let params = {
      Office: this.state.initparams.Office,
      BranchOfficeShop: this.state.initparams.BranchOfficeShop
    }
    RomotoArticle52Actions.getNameOfficeCodeRomotoArticle52Action({ ...params, ...objChange })
      .then((res) => {
        this.formRef?.current?.setFieldsValue({
          office_kanji_name: res?.data?.office_kanji_name || ''
        })
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
  }


  /**
   * 所属選択
   * @param {*} name 
   * @param {*} value 
   */
  openModalWS2787004 = (name, value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS2787004_AffiliationSelectSub
            Li_OfficeCode={this.state.initparams.Office}
            Li_BranchStoreCode={this.state.initparams.BranchOfficeShop}
            Lio_AffiliationCode={value}
            onFinishScreen={({ Lio_AffiliationCode }) => {
              this.formRef.current.setFieldsValue({
                [name]: Lio_AffiliationCode
              })

              this.handleChangeInitParams(Lio_AffiliationCode, name)
              this.closeModal()
            }}
          />),
      },
    })
  }


  /**
   * 内訳選択
   * @param {*} record
   * @returns
   */
  renderRightClickTable = (record) => (
    <Button
      size='small'
      icon={<MoreOutlined />}
      onClick={() => {
        this.state.initparams.Office === '' ?
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 1200,
              component: (
                <WS1001033_BreakdownInquiry
                  GissueStartDate={this.state.initparams.GissueStartDate}
                  GissueEndDate={this.state.initparams.GissueEndDate}
                  W1_office_cd={record.office_code}
                  W1_branch_store_cd={record.W1_branch_store_cd}
                  GextractCondition={this.state.objInit.GextractCondition}
                  ConditionalExpression={this.state.objIssueList.ConditionalExpression}
                  TargetCourseTable={this.state.objIssueList.TargetCourseTable}
                  StsOfficeIntegration={this.state.objInit.StsOfficeIntegration}
                  branch_store_code={record.branch_store_code}
                  onFinishScreen={(output) => {
                    this.closeModal()
                  }}
                />),
            },
          })
          :
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 1200,
              component: (
                <WS1001034_SelectBreakdown
                  searchCriteria={this.state.initparams}
                  selectIdList={record.selectIdList}
                  selectOfficeCode={record.office_code}
                  recordData={record}
                  onFinishScreen={async ({ output, selectIdList, office_code }) => {
                    await this.setState({
                      selectIdList: selectIdList,
                      selectOfficeCode: office_code,
                    })

                    // 選択されているIDの配列を保持
                    record.selectIdList = selectIdList
                    if (selectIdList.length !== 0) {
                      record.person_total = selectIdList.length
                    }

                    await this.closeModal()
                  }}
                />),
            },
          })
      }}
    >
    </Button>
  )


  /**
   * 印刷ボタン
   */
  Print_F12 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={false}
            Li_PrinterNoSpecifiedValue={0}
            Lo_Preview={false}
            Lo_PrinterNo={0}
            Lo_StsOutput={true}
            onFinishScreen={({ Lo_Preview, Lo_PrinterNo, Lo_StsOutput }) => {
              this.closeModal()
              this.printF12({
                ...this.state.initparams,
                selectedRows: this.state.selectedRows,
                ListSts: Lo_StsOutput ? true : false,
                Option: this.state.objInit.Option,
                GbreakdownOutput: this.state.objInit.GbreakdownOutput ? true : false,
                GpreviewMode: Lo_Preview ? true : false,
                AggregateStyle: this.state.objInit.AggregateStyle,
                BreakdownFormat: this.state.objInit.BreakdownFormat,
                PrinterNum: Lo_PrinterNo,
              })
            }}
          />),
      },
    })
  }


  /**
   * 印刷処理
   * @param {*} params
   */
  printF12 = (params) => {
    if (params.GpreviewMode) {
      RomotoArticle52Actions.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
        })
        .catch(err => {
          const res = err.response;
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        });
    } else {
      RomotoArticle52Actions.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
    }
  }


  /**
   * 労基52条設定
   */
  VariousSetting01 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS1004001_SettingRomotoArticle52
            onFinishScreen={() => {
              this.closeModal()
            }}
          />),
      },
    })
  }


  /**
  * 報告年度変換関数
  * @param {*} value
  * @returns
  */
  reportingYear(value) {
    if (value !== null) {
      let date = moment(value).format('YYYY')
      if (value !== undefined) {
        let formatter = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
          year: 'numeric'
        })
        return formatter.format(new Date(date, 0, 1))
      }
    }
  }


  /**
   * 入力データ　保持処理
   * @param {*} value
   * @param {*} name
   */
  handleChangeInitParams = (value, name) => {
    if (name == 'GissueStartDate' || name == 'GissueEndDate') {
      // 日付のフォーマットを整える
      value = moment(value).format(dateFormat)
    }

    this.setState({
      initparams: {
        ...this.state.initparams,
        [name]: value
      }
    })

    if (name === 'Office') {
      this.setState({ Expression_5: value ? false : true })
      this.formRef?.current?.setFieldsValue({ office_kanji_name: '' })
    }

    if (name === 'BranchOfficeShop') {
      this.formRef?.current?.setFieldsValue({ office_kanji_name: '' })
    }
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="romoto-article52">
        <Card title='労基５２条'>
          <Form
            ref={this.formRef}
            labelAlign='right'
            autoComplete='off'
            initialValues={{
              ...this.state.initparams,
              GissueStartDate: moment(),
              GissueEndDate: moment(),
            }}>
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className='option_btn_right_top'
                trigger='click'
                size='small'
                icon={<MenuOutlined />}
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key='1'
                        style={{ width: '100%' }}
                        onClick={() => this.VariousSetting01()}
                      >
                        労基52条設定
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <div className='box_inner_horizontal' style={{ marginBottom: '10px' }}>
              <div className='box_search'>
                <div className='box_search_inner_border' style={{ width: '100%' }}>
                  <Form.Item
                    label="報告年度"
                    style={marginBottom}
                    {...labelCol}
                  >
                    <div className="box_inner_horizontal">
                      <Form.Item
                        name="ReportingYear"
                        type="tel"
                      >
                        <VenusDatePickerCustom
                          width={80}
                          picker={'year'}
                          formRefDatePicker={this.formRef}
                          format={'YYYY'}
                          onChange={(e) => this.forceUpdate()}
                        />
                      </Form.Item>
                      <Form.Item
                        name="ReportingYearSumHistory"
                        label="年度（"
                      >
                        <div>{this.reportingYear(this.formRef.current?.getFieldValue("ReportingYear"))}</div>
                      </Form.Item>
                      <Form.Item label="）"></Form.Item>
                    </div>
                  </Form.Item>

                  <Form.Item
                    label="事業所"
                    style={marginBottom}
                    {...labelCol}
                  >
                    <div className="box_inner_horizontal">
                      <Form.Item
                        name="Office"
                      >
                        <Input.Search
                          className="input-search-size-number-8"
                          onChange={(e) => this.handleChangeInitParams(e.target.value, 'Office')}
                          onSearch={this.openModalWS0247001}
                        />
                      </Form.Item>

                      <Form.Item
                        name="BranchOfficeShop"
                      >
                        <Input.Search
                          className="input-search-size-number-5"
                          readOnly={!this.state.initparams.Office}
                          onChange={(e) => {
                            if (Regex(e.target.value, /[1-9]/)) {
                              this.handleChangeInitParams(e.target.value, 'BranchOfficeShop')
                            } else {
                              this.handleChangeInitParams(null, 'BranchOfficeShop')
                            }
                          }}
                          onSearch={() => {
                            if (this.state.initparams.Office) this.openModalWS0247001()
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="office_kanji_name"
                      >
                        <div className="column-size-60">{this.formRef.current?.getFieldValue("office_kanji_name")}</div>
                      </Form.Item>
                    </div>
                  </Form.Item>

                  <div className="box_inner_horizontal">
                    <Form.Item
                      label="受診日"
                      name="GissueStartDate"
                      style={marginBottom}
                      {...labelCol}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        onChange={(date, dateString) =>
                          this.handleChangeInitParams(dateString, 'GissueStartDate')
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="GissueEndDate"
                      label="~"
                      style={marginBottom}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        onChange={(date, dateString) =>
                          this.handleChangeInitParams(dateString, 'GissueEndDate')
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="box_search_inner" style={{ gap: '10px' }}>
                  <Form.Item
                    label="統合"
                    name="StsOfficeIntegration"
                    valuePropName="checked"
                    style={marginBottom}
                    {...labelCol}
                  >
                    <Checkbox>支社店を親事業所に統合する</Checkbox>
                  </Form.Item>

                  <div className="box_inner_horizontal">
                    <Form.Item
                      name="AffiliationCodeF"
                      label="所属"
                      style={marginBottom}
                      {...labelCol}
                    >
                      <InputNumber
                        maxLength={8}
                        min={1}
                        disabled={this.state.Expression_79}
                        onChange={(value) => this.handleChangeInitParams(value, 'AffiliationCodeF')}
                        onDoubleClick={() => this.openModalWS2787004('AffiliationCodeF', this.state.initparams.AffiliationCodeF)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="AffiliationCodeT"
                      label="~"
                      style={marginBottom}
                    >
                      <InputNumber
                        maxLength={8}
                        min={1}
                        disabled={this.state.Expression_79}
                        readOnly={!this.state.initparams.AffiliationCodeF}
                        onChange={(value) => this.handleChangeInitParams(value, 'AffiliationCodeT')}
                        onDoubleClick={() => {
                          if (this.state.initparams.AffiliationCodeF) {
                            this.openModalWS2787004('AffiliationCodeT', this.state.initparams.AffiliationCodeT)
                          }
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="GextractConditionScreen"
                    label="抽出条件"
                    style={marginBottom}
                    {...labelCol}
                  >
                    <Select
                      onChange={(value) => this.handleChangeInitParams(value, 'GextractConditionScreen')}
                    >
                      <Select.Option value='9'>全て</Select.Option>
                      {this.state.GextractConditionScreenList?.map((item, index) => (
                        <Select.Option
                          key={`GextractConditionScreen${index}`}
                          value={item.node_code_name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className='box_search_btn'>
                <div className='box_inner_vertical'>
                  <Form.Item
                    label={this.state.Expression_70 ? '' : "内訳出力"}
                    name="GbreakdownOutput"
                    valuePropName="checked"
                    hidden={this.state.Expression_70}
                    style={marginBottom}
                    {...labelCol}
                  >
                    <Checkbox
                      onChange={(e) => this.handleChangeInitParams(e.target.checked ? 1 : 0, 'GbreakdownOutput')}
                    />
                  </Form.Item>

                  <Button
                    style={{ float: 'right' }}
                    icon={<SearchOutlined />}
                    onClick={this.loadData}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>
            </div>


            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              onRow={(record, index) => ({ onClick: event => this.setState({ rowSelect: record }) })}
              rowSelection={{
                selectedRowKeys: this.state.selectedRowKeys,

                onSelect: (record) => {
                  let arrTemp = [...this.state.selectedRowKeys];
                  let arrTempRecord = [...this.state.selectedRows];
                  let idx = arrTemp.indexOf(record.id);
                  if (idx === -1) {
                    arrTemp.push(record.id);
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord,
                    });
                  } else {
                    arrTemp.splice(idx, 1);
                    arrTempRecord.splice(idx, 1);
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord,
                    });
                  }
                },

                onSelectAll: (selected) => {
                  if (selected) {
                    let arrTemp = this.state.dataSource.map(item => item.id);
                    let arrTempRecord = this.state.dataSource;
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord
                    });
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: [],
                      selectedRowOnly: []
                    });
                  }
                }
              }}
            >
              <Table.Column
                title="事業所"
                dataIndex=""
                render={(text, record, index) => {
                  return (
                    <Row gutter={10}>
                      <Col span={6} style={{ textAlign: 'right' }}>
                        {record.office_code}
                      </Col>
                      <Col span={1}>-</Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        {record.branch_store_cd}
                      </Col>
                      <Col span={13}>
                        {record.office_kanji_name}
                      </Col>
                    </Row>
                  )
                }}
              />

              <Table.Column
                title="受診者"
                dataIndex="examinee_total"
                width={'10%'}
                render={(text, record) => (<div style={{ textAlign: 'right' }}>{text !== 0 ? text + '人' : null}</div>)}
              />

              {this.state.Expression_5 ?
                null
                :
                <Table.Column
                  title="該当者"
                  dataIndex="person_total"
                  width={'10%'}
                  render={(text, record) => (<div style={{ textAlign: 'right' }}>{text !== 0 ? text + '人' : null}</div>)}
                />
              }

              <Table.Column
                title="所在地"
                dataIndex="address_1"
                width={'40%'}
              />

              <Table.Column
                title=""
                dataIndex=""
                width={40}
                render={(value, record) => this.renderRightClickTable(record)}
              />
            </Table>

            <Form.Item
              style={{ float: "right", marginTop: '8px' }}
            >
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={this.Print_F12}
              >
                印刷
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1001001_RomotoArticle52);
