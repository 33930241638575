import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import axios from 'configs/axios';
import {
  Card,
  Form,
  Input,
  Radio,
  Select,
  Checkbox,
  Button,
  Table,
  Dropdown,
  Menu,
  InputNumber,
  Modal,
  message,
  Row,
  Col
} from 'antd'
import AddDateAction from 'redux/Others/AddDate/AddDate.action'


const minWidth = '500px'

const labelCol = {
  labelCol: { style: { width: 110 } },
}

class AddDate extends React.Component {
  Li_ReserveDate
  static propTypes = {
    Li_ReserveDate: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '枠取予約'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      WakutoAtPage: [],
      isLoading: false,
      // OfficeCode: '',
      OfficeCode: 105,
      BranchStoreCode: '',
      office_kanji_name: '',
      Course: '',
      contract_short_name: '',
      recordData: {},
      ContractType: '',
      ContractOrgCode: '',
      ContractStartDate: '',
      ContractNum: '',
      count: 0,
      discount: 0,
      totalAmount: 0,
      dataSourceInspectChange: [],
      discountNameList: [],
      columnList: [],
      Li_TimeDivision: 'AM',
      dataList: [],
      patternCode: '',
      dataSourceSelect: [],
      checkList: []
    }
  }

  componentDidMount = () => {
    this.setState({ isLoading: true })
    this.loadScreenData()
  }

  loadScreenData = () => {
    let params = {
      date: this.props.date,
      contractType: this.props.DataScreen.Lo_ContractType,
      contractOrgCode: this.props.DataScreen.Lo_ContractOrgCode,
      contractStartDate: this.props.DataScreen.Lo_ContractStartDate,
      ContractNum: this.props.DataScreen.Lo_ContractNum,
      ConsultCourse: this.props.DataScreen.Lio_ConsultCourse,
      dataSourceInspectChange: this.props.dataSourceInspectChange,
    }
    AddDateAction.loadScreenData(params)
      .then(res => {
        this.setState({
          screenData: {
            ...this.state.screenData,
            FacilityTypeList: res.data?.FacilityTypeList,
            CourseList: res.data?.CourseList,
            CourseList_1: res.data?.CourseList_1,
            InspectList: res.data?.InspectList,
          },
          formDefault: {
            ...this.state.formDefault,
            FacilityType: res.data?.FacilityTypeList[0]?.facility_type,
            TimeDivision: '',
            CourseSelect: '',
            PeopleNum: '1'
          },
          checkList: res.data.checkList
        });
        // this.setState({
        //   screenData: {
        //     ...this.state.screenData,
        //     FacilityTypeList: res.data?.FacilityTypeList,
        //     CourseList: res.data?.CourseList?.split(','),
        //     CourseList_1: res.data?.CourseList_1?.split(','),
        //     InspectList: res.data?.InspectList?.split(','),
        //   },
        //   formDefault: {
        //     ...this.state.formDefault,
        //     FacilityType: res.data?.FacilityTypeList[0]?.facility_type,
        //     TimeDivision: '',
        //     CourseSelect: '',
        //     PeopleNum: '1'
        //   },
        //   checkList: res.data.checkList
        // });

        this.formRef.current.setFieldsValue({
          CourseSelect: '',
          FacilityType: 1
        })

      })
      .catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }


  render() {
    return (
      <div className='frame-reserve'>
        <Card id="card" title='コース詳細'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="CourseSelect"
              label="コース集計分類"
              {...labelCol}
            >
              <Select style={{ width: '50%' }}>
                {this.state.screenData?.CourseList?.map((value, index) => (
                  <Select.Option
                    value={value}
                    key={`cl-${index}`}
                  >
                    {this.state.screenData?.CourseList_1[index]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="検査集計分類"
              {...labelCol}
            >
              <Row style={{ marginTop: 4 }}>
                {this.state.screenData?.InspectList?.map((value, index) => (
                  value.name !== '' ?
                    <Col span={6} key={index}>
                      <Form.Item
                        name={'Inspect' + ('00' + (index + 1)).slice(-2)}
                        // valuePropName="checked"
                        initialValue={false}
                        {...labelCol}
                      >
                        <Checkbox checked={value.checked}>
                          {value.name}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    :
                    null

                ))}
              </Row>
            </Form.Item>
          </Form >
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        </Card >
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDate)
