import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Button,
  Checkbox,
  Table,
  Tabs,
  Modal,
  Select,
  Tooltip,
  Dropdown,
  Menu,
  Space,
  Col,
  Row,
  Spin
} from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";

import { MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, InfoOutlined, FileTextOutlined, SendOutlined, PrinterOutlined } from "@ant-design/icons";
// import { getExamNameAction } from 'redux/ExamCmtInfoMaintain/InspectCmtInfoMaintain.action'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx";
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery';
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery';
import WS0668002_LabelFormSelect from 'pages/JZ_AdvancePreparation/V4JZ0104000_SpecimenLabel/WS0668002_LabelFormatSelect';
import InspectItemMasterAction from "redux/CooperationRelated/InspectRequestMain/InspectRequestMain.action";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import SpecimenLabelPrintAction from 'redux/AdvancePreparation/SpecimenLabelPrint/SpecimenLabelPrint.actions';
import NumberFormat from 'components/Commons/NumberFormat'

const today = moment(new Date().toISOString())

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS0668001_SpecimenLabelPrint extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    // // document.title = '検体ラベル';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      isLoadingForm: true,
      isLoadingTable: false,
      dataSource: [], // targetList
      selectedRowKeys: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      ReserveDateF: "",
      ReserveDateT: "",
      ExtractDivision: 0,
      Indent: "",
      Text: "",
      rowSelect: {},
      listID: [],
      StsSelectOne: false,
      W1_urgent_specimen_flg: false,
      GfacilitiesNum: [],
      institutionItem: [],
      institution: 0,
      // option_normal: '',
    };
  }
  componentDidMount() {
    this.getScreenDataAction();
    this.formRef.current.setFieldsValue({
      ReserveDateF: today.format('YYYY/MM/DD'),
      ReserveDateT: today.format('YYYY/MM/DD'),
    })
  }
  getScreenDataAction() {
    this.setState({ isLoadingForm: true });
    //コンボボックスの一覧を取得
    SpecimenLabelPrintAction.getScreenData()
      .then((res) => {
        // let data = res ? res : [];
        let institutionItems = res.data.institutionItem;
        this.setState({
          ExtractDivision: res.data.ExtractDivision,
          // option_normal: res.data.option_normal,
          institutionItem: institutionItems,
          institution: institutionItems[0].facility_type,
        });
        this.formRef.current.setFieldsValue({
          institution: institutionItems[0].facility_type,
        })
      })
      .finally(() => this.setState({ isLoadingForm: false }));
  }

  //検索ボタン
  displayBtn() {
    const data = {
      ReserveDateF: moment(this.formRef.current?.getFieldValue('ReserveDateF'))?.format('YYYY/MM/DD'),
      ReserveDateT: moment(this.formRef.current?.getFieldValue('ReserveDateT'))?.format('YYYY/MM/DD'),
      institution: this.formRef.current?.getFieldValue("institution"),
      acceptStatus: this.formRef.current?.getFieldValue("acceptStatus"),
      insurerCode: this.formRef.current?.getFieldValue("insurerCode"),
      officeCode: this.formRef.current?.getFieldValue("officeCode"),
      branchStore: this.formRef.current?.getFieldValue("branchStore"),
      courseF: this.formRef.current?.getFieldValue("courseF"),
      courseT: this.formRef.current?.getFieldValue("courseT"),
    };
    SpecimenLabelPrintAction.displayBtn(data)
      .then((res) => {
        // let arrTemp = res.data.map(item => item.id)
        console.log(res.data)
        let arrTemp = res.data.filter(x => x.stsPrintFlag === true).map(item => item.id)
        this.setState({
          dataSource: res.data,
          selectedRowKeys: arrTemp,
        })
        this.formRef.current?.setFieldsValue({
          tableData: res.data
        })
      })
      .catch((error) => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
      });
  }


  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }


  onFinish(values) { }
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  ChangeCheck(id, value) {
    let array = this.state.dataSource;
    let Index = array.findIndex(item => item.id === id)

    array[Index].W1_urgent_specimen_flg = value;
    this.setState({ dataSource: array });
  }

  onChangeValue = (value, record, index) => {
    console.log(value);
    let arrTemp = [...this.state.dataSource]
    // 内容を反映
    let obj = {
      ...record,
      copies: value, //変更内容
    }

    arrTemp[index] = obj


    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
    })
  }



  print_f12 = (params) => {
    let tabNumber = params.tabNumber;
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={false}
            Li_PrinterNoSpecifiedValue={0}
            Lo_Preview={false}
            Lo_PrinterNo={0}
            Lo_StsOutput={true}
            onFinishScreen={(res) => {
              this.closeModal()
              if (res.Lo_StsOutput) {
                let values = {
                  previewMode: res.Lo_Preview,
                  PrinterNum: res.Lo_PrinterNo
                }
                //個人ラベル
                if (tabNumber === 1) {
                  this.printDataCheck(values)
                  //番号ラベル
                } else {
                  this.numberPrintDataCheck(values)
                }
              }
            }}
          />),
      },
    })
  }

  //*******************個人ラベル発行*****************************

  printDataCheck = (values) => {
    // チェックボックスにチェックがついているデータの一覧を取得
    const selectedIdList = [...this.state.selectedRowKeys]
    const data = [...this.state.dataSource]
    let selectedData = [], obj
    for (let i = 0; i < data.length; i++) {
      obj = { ...data[i] }
      for (let j = 0; j < selectedIdList.length; j++) {
        if (obj.id === selectedIdList[j]) {
          selectedData.push(obj)
          break
        }
      }
    }

    let params = {
      tabNumber: 1,
      selectedData: selectedData,
      previewMode: values.previewMode,
      PrinterNum: values.PrinterNum,
      line: this.formRef.current.getFieldValue('line'),
      column: this.formRef.current.getFieldValue('column'),
      formatNumber: this.formRef.current.getFieldValue('formatNumber'),
    }
    //エラーチェック（returnのタイプがblobのため特殊処理）
    SpecimenLabelPrintAction.errorCheck(params)
      //メッセージがあればエラーとして表示
      .then(res => {
        if (res.data.message !== '') {
          message.error(res.data.message)
          //メッセージが無ければ印刷処理
        } else {
          this.printAction(params)
        }
      })
  }

  printAction = (params) => {
    if (params.previewMode) {
      SpecimenLabelPrintAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          // fileOpen.onload = function () {
          //   fileOpen.print();
          // };
        })
        .catch(err => {
          const res = err.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        });
    } else {
      SpecimenLabelPrintAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
        .catch(err => {
          const res = err.response;
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          if (!res || !res.data || !res.data.message) {
            message.error("エラーが発生しました");
            return;
          }
          message.error(res.data.message);
        });
    }
  }

  //*******************番号ラベル発行*****************************
  numberPrintDataCheck = (values) => {

    let params = {
      tabNumber: 2,
      previewMode: values.previewMode,
      PrinterNum: values.PrinterNum,
      line: this.formRef.current.getFieldValue('line_numberLabel'),
      column: this.formRef.current.getFieldValue('column_numberLabel'),
      formatNumber: this.formRef.current.getFieldValue('formatNumber_numberLabel'),
      labelNumberF: this.formRef.current.getFieldValue('labelNumberF'),
      labelNumberT: this.formRef.current.getFieldValue('labelNumberT'),
      numberLabelCopies: this.formRef.current.getFieldValue('copies_numberLabel'),
    }
    //エラーチェック（returnのタイプがblobのため特殊処理）
    SpecimenLabelPrintAction.errorCheck(params)
      //メッセージがあればエラーとして表示
      .then(res => {
        if (res.data.message !== '') {
          message.error(res.data.message)
          return
          //メッセージが無ければ印刷処理
        } else {
          this.printNumberAction(params)
        }
      })
  }

  printNumberAction = (params) => {
    if (params.previewMode) {
      SpecimenLabelPrintAction.previewNumberLabel(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          // fileOpen.onload = function () {
          //   fileOpen.print();
          // };
        })
        .catch(err => {
          const res = err.response;
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          message.error(res.data.message);
        });
    } else {
      SpecimenLabelPrintAction.printNumberLabel(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
        .catch(err => {
          const res = err.response;
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
          if (!res || !res.data || !res.data.message) {
            message.error("エラーが発生しました");
            return;
          }
          message.error(res.data.message);
        });
    }
  }

  //***************************印刷イベントここまで****************************

  render() {
    const { rowSelect } = this.state;
    const format = "YYYY/MM/DD";

    return (
      <div className="address-label-personal">
        <Card title='検体ラベル'>
          <Tabs
            defaultActiveKey='1'
            size='small'
          >
            <Tabs.TabPane tab='個人ラベル' key='1'>
              <Form
                {...grid}
                ref={this.formRef}
                onFinish={this.onFinish}
                autoComplete='off'
                initialValues={{
                  ReserveDate: moment(),
                  ExtractDivision: this.state.ExtractDivision,

                }}
              >
                <div className="box_container">
                  <div className="box_inner_horizontal">
                    <div className="box_search">
                      <div className='box_search_inner_border' style={{ minWidth: 500 }}>

                        <div className="box_inner_horizontal">
                          <Form.Item name="ReserveDateF" label="受診日" {...smGrid}>
                            <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={false} />
                          </Form.Item>

                          <Form.Item name="ReserveDateT" label="～" >
                            <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={false} />
                          </Form.Item>
                        </div>

                        <div className="box_inner_horizontal">
                          <Form.Item name='formatNumber' label='様式' {...smGrid} >
                            <Input.Search style={{ width: 80 }}
                              maxLength={2}
                              onSearch={(value, event) => {
                                if (event.target.localName !== "input") {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 500,
                                      component: (
                                        <WS0668002_LabelFormSelect
                                          onFinishScreen={(res) => {
                                            this.formRef.current.setFieldsValue({
                                              // formatNumber: recordData.formatNumber,
                                              formatNumber: res.recordData.formatNumber,
                                              formatName: res.recordData.formatName,
                                            });
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  });
                                }
                              }}
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.formRef.current.setFieldsValue({
                                    formatName: '',
                                  })
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item name='formatName' label=''>
                            <Input
                              readOnly
                              type='text'
                              style={{ border: 'none', background: 'transparent' }}
                            />
                          </Form.Item>
                        </div>

                        <div className="box_inner_horizontal">
                          <Form.Item name='officeCode' label='事業所'{...smGrid}>
                            <Input.Search
                              className='input-search-size-allow-clear-4'
                              type="text"
                              maxLength={8}
                              allowClear={true}
                              onPressEnter={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1500,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current.getFieldValue('officeCode')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue({
                                            officeCode: output.Lio_OfficeCode,
                                            branchStore: output.Lio_BranchStoreCode,
                                            office_kanji_name: output.Lo_Kanji_Name
                                          });
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  },
                                });
                              }}
                              onSearch={(value, event) => {
                                if (event.target.localName !== "input") {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 1500,
                                      component: (
                                        <WS0247001_OfficeInfoRetrievalQuery
                                          Lio_OfficeCode={this.formRef.current.getFieldValue('officeCode')}
                                          onFinishScreen={(output) => {
                                            this.formRef.current.setFieldsValue({
                                              officeCode: output.Lio_OfficeCode,
                                              branchStore: output.Lio_BranchStoreCode,
                                              office_kanji_name: output.Lo_Kanji_Name
                                            });
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  });
                                }
                              }
                              }
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.formRef.current.setFieldsValue({
                                    branchStore: '',
                                    office_kanji_name: ''
                                  })
                                }
                              }}
                            />
                          </Form.Item>

                          <Form.Item name='branchStore' label=''>
                            <InputNumber maxLength={5} style={{ width: 60, textAlign: 'right' }} />
                          </Form.Item>


                          <Form.Item name='office_kanji_name' label=''>
                            <Input
                              readOnly
                              type='text'
                              style={{ border: 'none', background: 'transparent' }}
                            />
                          </Form.Item>
                        </div>

                        <div className="box_inner_horizontal">
                          <Form.Item name='insurerCode' label='保険者'{...smGrid}>
                            <Input.Search
                              className='input-search-size-allow-clear-8'
                              type="text"
                              style={{ width: 160 }}
                              maxLength={10}
                              allowClear={true}
                              onPressEnter={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1500,
                                    component: (
                                      <WS0246001_InsurerInfoSearchQuery
                                        Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue({
                                            insurerCode: output.Lo_InsurerCode,
                                            insurer_kanji_name: output.Lo_Name
                                          });
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  },
                                });
                              }}
                              onSearch={(value, event) => {
                                if (event.target.localName !== "input") {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 1500,
                                      component: (
                                        <WS0246001_InsurerInfoSearchQuery
                                          Li_insurerSearch={this.formRef.current.getFieldValue('insurerCode')}
                                          onFinishScreen={(output) => {
                                            this.formRef.current.setFieldsValue({
                                              insurerCode: output.Lo_InsurerCode,
                                              insurer_kanji_name: output.Lo_Name
                                            });
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            });
                                          }}
                                        />
                                      ),
                                    },
                                  });
                                }
                              }
                              }
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.formRef.current.setFieldsValue({
                                    insurer_kanji_name: ''
                                  })
                                }
                              }}
                            />
                          </Form.Item>

                          <Form.Item name='insurer_kanji_name' label=''>
                            <Input
                              readOnly
                              type='text'
                              style={{ border: 'none', background: 'transparent' }}
                            />
                          </Form.Item>
                        </div>

                      </div>

                      <div className='box_inner_vertical'>
                        <Form.Item name='acceptStatus' label='状態' initialValue={9} {...smGrid}>
                          <Select style={{ width: 80 }}>
                            <Select.Option value={9}>全て</Select.Option>
                            <Select.Option value={0}>予約</Select.Option>
                            <Select.Option value={1}>受付</Select.Option>
                            <Select.Option value={2}>保留</Select.Option>
                            <Select.Option value={3}>キャンセル</Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item name="institution" label="施設" style={{ width: 300 }} {...smGrid}>
                          <Select >
                            {this.state.institutionItem?.map(item => (
                              <Select.Option key={item.facility_type} value={item.facility_type}>{item.facility_name}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <div className="box_inner_horizontal">
                          <Form.Item name='courseF' label='コース'{...smGrid}>
                            <Input.Search
                              type='text'
                              style={{ textAlign: 'right', width: 90 }}
                              maxLength={3}
                              onSearch={(value, e) => {
                                if (e.key !== "Enter") {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '70%',
                                      component: (
                                        <WS0265001_BasicCourseInquiry
                                          onFinishScreen={(output) => {
                                            this.formRef.current.setFieldsValue({ 'courseF': output.Lo_CourseCode });
                                            this.closeModal();
                                          }}
                                        />
                                      ),
                                    },
                                  });
                                }
                              }}
                            />
                          </Form.Item>

                          <Form.Item name='courseT' label='～'>
                            <Input.Search
                              type='text'
                              style={{ textAlign: 'right', width: 90 }}
                              maxLength={3}
                              onSearch={(value, e) => {
                                if (e.key !== "Enter") {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '70%',
                                      component: (
                                        <WS0265001_BasicCourseInquiry
                                          onFinishScreen={(output) => {
                                            this.formRef.current.setFieldsValue({ 'courseT': output.Lo_CourseCode });
                                            this.closeModal();
                                          }}
                                        />
                                      ),
                                    },
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className="box_inner_horizontal">
                          {/*行 */}
                          <Form.Item name='line' label='印刷開始行' initialValue={1}{...smGrid}>
                            <InputNumber style={{ textAlign: 'right', width: 40, marginBottom: 0 }} maxLength={2} />
                          </Form.Item>

                          {/*列 */}
                          <Col span={12}>
                            <Form.Item name='column' label='列' initialValue={1}>
                              <InputNumber style={{ textAlign: 'right', width: 40, marginBottom: 0 }} maxLength={2} />
                            </Form.Item>
                          </Col>
                        </div>

                      </div>

                    </div>

                    <div className="box_search_btn">
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.displayBtn()}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </div>

                  </div>

                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.dataSource}
                    rowKey={(record) => record.id}
                    onRow={(record, index) => ({
                      onClick: (event) => {
                      },
                    })}
                    rowSelection={{
                      type: 'checkbox',
                      selectedRowKeys: this.state.selectedRowKeys,
                      onSelect: (record, selected) => {
                        // 選択
                        this.onSelect(record, selected)
                      },
                      onSelectAll: (selected) => {
                        // 全選択
                        this.onSelectAll(selected)
                      }
                    }}
                    // pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    pagination={false}
                    scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
                  >

                    <Table.Column title="部数" dataIndex="copies" className="column-size-5"
                      render={(value, record, index) => (
                        <Form.Item style={{ marginBottom: '0' }} name={['tableData', index, 'copies']}>
                          <Input
                            type='number'
                            className='input-size-number-2'
                            maxLength={2}
                            onBlur={(e) => { this.onChangeValue(e.target.value, record, index) }}
                            onPressEnter={(e) => { this.onChangeValue(e.target.value, record, index) }}
                          />
                        </Form.Item>
                      )}
                    />

                    <Table.Column
                      className='column-size-10'
                      title="受診日"
                      dataIndex="visit_date_on"
                      render={(value, record, index) => {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD(ddd)')}
                          </div>
                        )
                      }} />

                    <Table.Column title="受付No." dataIndex="receipt_number" className="column-size-5"
                      render={(value) => (
                        <div style={{ textAlign: "right" }}>{value}</div>
                      )}
                    />


                    <Table.Column title="個人番号" dataIndex="personal_number_id" className="column-size-10"
                      render={(value) => (
                        <div style={{ textAlign: "right" }}>{value}</div>
                      )}
                    />

                    <Table.Column
                      className='column-size-4'
                      title="メモ"
                      dataIndex="PS_importance"
                      render={(value, record, index) => {
                        let icon = "";
                        switch (record.importance) {
                          case 1: icon = (<InfoCircleOutlined style={{ fontSize: 20, color: "#1890ff" }} />);
                            break;
                          case 3: icon = (<WarningOutlined style={{ fontSize: 20, color: "#faad14" }} />);
                            break;
                          case 5: icon = (<CloseCircleOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />);
                            break;
                          default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>);
                        }
                        return (
                          <div style={{ textAlign: "center", cursor: 'pointer' }}
                            hidden={!record.personal_number_id}
                            onClick={() => {
                              // let title = '個人情報照会' + ' [' + record.personal_number_id + ']'
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '90%',
                                  component: (
                                    // <Card title={title}>
                                    <WS2584019_PersonalInfoInquirySub
                                      Li_PersonalNum={record.personal_number_id}
                                      onClick={() => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                    // </Card>
                                  ),
                                },
                              })
                            }}>
                            {icon}
                          </div>
                        );
                      }} />

                    <Table.Column title="氏名" dataIndex="kanji_name" className="column-size-30" />
                    <Table.Column title="性別" width={40} dataIndex="genderName" className="column-size-4"
                      render={(res) => {
                        if (res === "女") {
                          return <div style={{ color: "red", textAlign: 'center' }}>{res}</div>;
                        } else {
                          return <div style={{ color: "blue", textAlign: 'center' }}>{res}</div>;
                        }
                      }}
                    />

                    {/* <Table.Column title="状態" width={60} dataIndex="acceptStatus" className="column-size-4" /> */}
                    <Table.Column className='column-size-60' title="事業所" dataIndex="office_kanji_name" />
                    <Table.Column className='column-size-4' title="コース" dataIndex="visit_course" />
                    <Table.Column className='column-size-60' title="コース名" dataIndex="contract_short_name" />
                    <Table.Column
                      className='column-size-10'
                      title="前回受診日"
                      dataIndex="lastTimeVisitDate"
                      render={(value, record, index) => {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD')}
                          </div>
                        )
                      }} />
                  </Table>

                  <div className="box_button_bottom_right">
                    <Button
                      icon={<PrinterOutlined />}
                      // onClick={() => this.printLabelAction()}
                      onClick={() => this.print_f12({ tabNumber: 1 })}
                      type="primary"
                      style={{ float: "right" }}
                    >
                      <span className='btn_label'>
                        印刷
                      </span>
                    </Button>
                  </div>
                </div>
              </Form>
            </Tabs.TabPane>


            <Tabs.TabPane tab='番号ラベル' key='2'>
              <Form
                {...grid}
                ref={this.formRef}
                onFinish={this.onFinish}
                autoComplete='off'
              >
                <div className="box_container" >
                  <div className="box_search" style={{ width: 400 }}>
                    <div className='box_inner_vertical'>
                      <div className="box_inner_horizontal">

                        <Form.Item name='labelNumberF' label='ラベルNo.'{...smGrid}>
                          <InputNumber style={{ textAlign: 'right', width: 70 }} maxLength={5} />
                        </Form.Item>

                        <Form.Item name='labelNumberT' label='～'>
                          <InputNumber style={{ textAlign: 'right', width: 70 }} maxLength={5} />
                        </Form.Item>

                      </div>

                      <div className="box_inner_horizontal">
                        <Form.Item name='formatNumber_numberLabel' label='様式' {...smGrid}>
                          <Input.Search style={{ width: 80 }}
                            maxLength={2}
                            onSearch={(value, event) => {
                              if (event.target.localName !== "input") {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 500,
                                    component: (
                                      <WS0668002_LabelFormSelect
                                        onFinishScreen={(res) => {
                                          this.formRef.current.setFieldsValue({
                                            formatNumber_numberLabel: res.recordData.formatNumber_numberLabel,
                                            formatName_numberLabel: res.recordData.formatName_numberLabel,
                                          });
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  },
                                });
                              }
                            }}
                            onChange={(e) => {
                              if (e.target.value == '') {
                                this.formRef.current.setFieldsValue({
                                  formatName: '',
                                })
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name='formatName_numberLabel' label=''>
                          <Input
                            readOnly
                            type='text'
                            style={{ border: 'none', background: 'transparent' }}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item name='copies_numberLabel' label='部数'{...smGrid} initialValue={1}>
                        <InputNumber style={{ textAlign: 'right', width: 40 }} maxLength={2} />
                      </Form.Item>

                      <div className="box_inner_horizontal">
                        {/*行 */}
                        <Form.Item name='line_numberLabel' label='印刷開始行' initialValue={1}{...smGrid}>
                          <InputNumber style={{ textAlign: 'right', width: 40, marginBottom: 0 }} maxLength={2} />
                        </Form.Item>

                        {/*列 */}
                        <Col span={12}>
                          <Form.Item name='column_numberLabel' label='列' initialValue={1}>
                            <InputNumber style={{ textAlign: 'right', width: 40, marginBottom: 0 }} maxLength={2} />
                          </Form.Item>
                        </Col>
                      </div>

                      <div className="box_button_bottom_right">
                        <Button
                          icon={<PrinterOutlined />}
                          // onClick={() => this.printLabelAction()}
                          onClick={() => this.print_f12({ tabNumber: 2 })}
                          type="primary"
                          style={{ float: "right" }}
                        >
                          <span className='btn_label'>
                            印刷
                          </span>
                        </Button>
                      </div>

                    </div>
                  </div>
                </div>
              </Form>
            </Tabs.TabPane>
          </Tabs>

        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0668001_SpecimenLabelPrint);
