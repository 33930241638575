import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Select, Table, Button, Modal, message, Tooltip, Dropdown, Menu, Input, Row, Col, Checkbox } from 'antd'
import { PlusOutlined, QuestionCircleOutlined, MoreOutlined, DoubleRightOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import axios from "configs/axios";
import Icon from 'patterns/Icon'

const API_LIST = {
  getScreenData:
    "/api/another-inspect-item-setting-category/another-inspect-item-setting-category/get-screen-data-sub",
  getCategorySettings:
    "/api/another-inspect-item-setting-category/another-inspect-item-setting-category/get-screen-data-category-settings-sub",
  getInspectSet:
    "/api/another-inspect-item-setting-category/another-inspect-item-setting-category/get-screen-data-inspect-set-sub",

};

class AnotherInspectItemSettingCategory extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'カテゴリ別検査項目設定'

    this.state = {

      searchCategorySettingsDisplayList: [],
      searchCategorySettingsDisplayListNofil: [],
      PatternCodeDisplayList: [],
      searchInspectSetDisplayListNofil: [], // 詳細
      searchInspectSetDisplayList: [], // 詳細
      searchInspectSetDisplayListAll: [], //右のテーブル全て
      record: {}, //詳細
      checked: false,
      selectedCategory: ''

    }


  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 初期表示からチェックを付ける
    if (this.props.checkFlag) {
      this.setState({
        checked: true
      })
    }
    this.getScreenData()
  }

  /**
   * パターン一覧を取得
   */
  getScreenData = async () => {

    let params = {
      ContractType: this.props.ContractType
      , ContractStartDate: this.props.ContractStartDate
      , ContractOrgCode: this.props.ContractOrgCode
      , ContractNum: this.props.ContractNum
      , medical_exam_course: this.props.Medical_exam_course
    }

    await axios.get(API_LIST.getScreenData, { params })

      .then((res) => {
        const data = [...res.data]

        let value = null
        if (data.length > 0) {
          value = data[0].pattern_code
        }
        // Select選択
        this.formRef.current.setFieldsValue({
          PatternCode: value
        })

        this.forceUpdate()
        this.searchCategorySettings()
      })
  }

  /**
   * パターンに紐づくカテゴリ一覧を取得
   * getだと親画面からコード一覧をバックエンドに渡せないためpost
   */
  searchCategorySettings = async () => {
    let params = {
      PatternCode: this.formRef.current.getFieldValue('PatternCode')
      , ContractType: this.props.ContractType
      , ContractStartDate: this.props.ContractStartDate
      , ContractOrgCode: this.props.ContractOrgCode
      , ContractNum: this.props.ContractNum
      , medical_exam_course: this.props.Medical_exam_course
      , JInspectGCategory: this.props.JInspectGCategory ? this.props.JInspectGCategory : null

    }

    axios.post(API_LIST.getCategorySettings, params)
      .then((res) => {
        let searchInspectSetDisplayList = res.data.rightTableData
        let checked = this.props.checkFlag ? true : this.state.checked
        // 左のテーブルで最初のカテゴリーコードが一致する配列を取る
        let array = []
        for (let i = 0; i < searchInspectSetDisplayList.length; i++) {
          if (searchInspectSetDisplayList[i].category_code === res.data.leftTableData[0]?.category_code) {
            array.push(searchInspectSetDisplayList[i])
          }
        }
        this.setState({
          searchCategorySettingsDisplayList: checked ? res.data.leftTableData : res.data.leftTableData.filter(d => { return d.enable })
          , searchCategorySettingsDisplayListNofil: res.data.leftTableData
          , searchInspectSetDisplayList: checked ? array : array.filter(d => { return d.enable })
          , searchInspectSetDisplayListNofil: res.data
          , searchInspectSetDisplayListAll: res.data.rightTableData
          , selectedCategory: res.data.leftTableData[0]?.category_code
        })
        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 一覧側
   * getだと親画面からコード一覧をバックエンドに渡せないためpost
   * @param {*} record 
   */
  searchInspectSet = async (record) => {
    let params = {
      category_code: record.category_code
      , pattern_code: record.pattern_code
      , ContractType: this.props.ContractType
      , ContractStartDate: this.props.ContractStartDate
      , ContractOrgCode: this.props.ContractOrgCode
      , ContractNum: this.props.ContractNum
      , medical_exam_course: this.props.Medical_exam_course
      , JInspectGCategory: this.props.JInspectGCategory ? this.props.JInspectGCategory : null
    }

    await axios.post(API_LIST.getInspectSet, params)
      .then((res) => {

        this.setState({
          searchInspectSetDisplayList: this.state.checked ? res.data : res.data.filter(d => { return d.enable })
          , searchInspectSetDisplayListNofil: res.data
        })
        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  checkState = (ischeck) => {
    let searchInspectSetDisplayList = this.state.searchInspectSetDisplayListAll
    let selectedCategory = this.state.selectedCategory
    let array = []
    // クリックしたカテゴリーコードと一致する行のみ抽出
    for (let i = 0; i < searchInspectSetDisplayList.length; i++) {
      if (searchInspectSetDisplayList[i].category_code === selectedCategory) {
        array.push(searchInspectSetDisplayList[i])
      }
    }
    this.setState({
      searchCategorySettingsDisplayList: ischeck ?
        this.state.searchCategorySettingsDisplayListNofil
        : this.state.searchCategorySettingsDisplayList.filter(d => { return d.enable })

      , searchInspectSetDisplayList: ischeck ? array : array.filter(d => { return d.enable })
    })

  }

  /**
   * 検査切り替え
   * @param {*} record 
   */
  changeInspectSet(record) {
    let searchInspectSetDisplayList = this.state.searchInspectSetDisplayListAll
    let array = []
    // クリックしたカテゴリーコードと一致する行のみ抽出
    for (let i = 0; i < searchInspectSetDisplayList.length; i++) {
      if (searchInspectSetDisplayList[i].category_code === record.category_code) {
        array.push(searchInspectSetDisplayList[i])
      }
    }
    this.setState({
      searchInspectSetDisplayList: this.state.checked ? array : array.filter(d => { return d.enable })
    })
  }

  /**
   * 検査追加
   * @param {*} record 
   */
  addOption(record) {
    if (this.props.onAdd) {
      let searchInspectSetDisplayList = [...this.state.searchInspectSetDisplayList]
      let index = searchInspectSetDisplayList.findIndex(x => x.exam_code === record.exam_code)
      if (index > -1) {
        searchInspectSetDisplayList[index].enable = true
      }
      this.setState({
        searchInspectSetDisplayList: searchInspectSetDisplayList
      })
      this.props.onAdd(record)
    }
  }

  /**
   * 検査削除
   * @param {*} record 
   */
  deleteOption(record) {
    if (this.props.onDelete) {
      let searchInspectSetDisplayList = [...this.state.searchInspectSetDisplayList]
      let index = searchInspectSetDisplayList.findIndex(x => x.exam_code === record.exam_code)
      if (index > -1) {
        searchInspectSetDisplayList[index].enable = false
      }
      this.setState({
        searchInspectSetDisplayList: searchInspectSetDisplayList
      })
      this.props.onDelete(record)
    }
  }

  render() {
    return (
      <div className='another-inspect-item-setting-category'>
        <Row>
          <Col span={8}>
            <Card title='カテゴリ別検査' >
              <Row>
                <Col span={24}><Checkbox style={{ "visibility": "hidden" }} /></Col>
              </Row>

              <div className='box_inner_vertical'>
                <Table
                  bordered={true}
                  size='small'
                  pagination={false}
                  dataSource={
                    this.state.searchCategorySettingsDisplayList
                  }
                  rowKey={(record) => record.id}
                  filterMultiple={false}
                  scroll={{ y: resizableTableScroll(30), x: 'max-content' }}
                  onRow={(record, rowIndex) => {
                    return {

                      onClick: (e) => {
                        // 初期表示で全て取っているので、コメントアウト
                        // this.searchInspectSet(record)
                        // 速度改善が出来ない場合または、テーブル情報が更新されてその情報が取れない場合は元に戻す
                        this.changeInspectSet(record)
                        this.setState({ selectedCategory: record.category_code })

                      },

                    }
                  }}

                  rowClassName={(record, index) =>
                    this.state.selectedCategory == record.category_name ? 'table-row-light' : ''
                  }

                >
                  <Table.Column
                    width={20}
                    render={record => {
                      if (!record.enable)
                        return <CloseOutlined style={{ color: 'red' }} />
                    }}
                  />

                  <Table.Column
                    width={100}
                    title='コード'
                    render={record => {
                      return <div style={{ textAlign: 'right', color: record.enable ? 'black' : 'lightslategray' }}>{record.category_code}</div>
                    }}
                  />
                  <Table.Column
                    width={200}
                    className='column-size-40'
                    title='カテゴリ名称'
                    render={record => {
                      return <div style={{ color: record.enable ? 'black' : 'lightslategray' }}>{record.category_name}</div>
                    }}
                  />
                </Table>

              </div>

            </Card>
          </Col>

          <Col span={16}>
            <Card title='検査項目詳細'>
              <Row>
                <Col span={20}></Col>
                <Col span={4}>
                  <Checkbox
                    checked={this.state.checked}
                    onChange={(e) => {

                      this.setState({ checked: e.target.checked })
                      this.checkState(e.target.checked)

                    }}>全て表示
                  </Checkbox>
                </Col>
              </Row>

              <div className='box_container'>
                <Form ref={this.formRef} onFinish={this.onFinish}>
                  <Table
                    bordered
                    size='small'
                    pagination={false}
                    dataSource={this.state.searchInspectSetDisplayList}
                    rowKey={(record) => record.id}
                    scroll={{ y: 600 }}
                    onRow={(record, index) => {
                      return {
                        onDoubleClick: () => {
                          // 親画面で何かする場合は下記の処理を行う
                          if (!record.enable) {
                            this.addOption(record)
                          } else {
                            this.deleteOption(record)
                          }
                        }
                      }
                    }}
                  >
                    <Table.Column
                      width={20}
                      render={record => {
                        if (!record.enable)
                          return <CloseOutlined style={{ color: 'red' }} />
                      }}
                    />

                    <Table.Column
                      width={100}
                      title='コード'
                      dataIndex='exam_code'
                      render={(record) => {
                        return <div style={{ textAlign: 'right' }}>{record}</div>
                      }}
                    />
                    <Table.Column
                      width={100}
                      title='略名'
                      dataIndex='HE_exam_name'
                    />
                    <Table.Column
                      width={200}
                      title='検査名称'
                      dataIndex='GY_exam_name'
                    />
                    <Table.Column
                      width={60}
                      title='タイプ'
                      dataIndex='Expression_24'
                    />
                    {this.props.checkFlag && !this.props.viewOnly ?
                      //オプション変更画面から開いたときのみ表示
                      <Table.Column width={40}
                        render={(value, record, index) => (
                          !record.enable ?
                            <div style={{ textAlign: 'center' }}>
                              <Dropdown
                                trigger='click'
                                style={{
                                  display: 'inline-block',
                                }} overlay={() => (
                                  <Menu >
                                    <Menu.Item key='追加'
                                      onClick={() => {
                                        this.addOption(record)
                                      }}
                                    >
                                      追加
                                    </Menu.Item>
                                  </Menu>
                                )}>
                                <Button size='small' icon={<MoreOutlined />} />
                              </Dropdown>
                            </div> :
                            <div style={{ textAlign: 'center' }}>
                              <Dropdown
                                trigger='click'
                                style={{
                                  display: 'inline-block',
                                }} overlay={() => (
                                  <Menu >
                                    <Menu.Item key='追加'
                                      onClick={() => {
                                        this.deleteOption(record)
                                      }}
                                    >
                                      削除
                                    </Menu.Item>
                                  </Menu>
                                )}>
                                <Button size='small' icon={<MoreOutlined />} />
                              </Dropdown>
                            </div>
                        )}
                      /> : null
                    }
                  </Table>
                </Form>
              </div>
            </Card >

          </Col>
        </Row>

      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnotherInspectItemSettingCategory)
