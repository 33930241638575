import { message } from "antd";
import AskIssuedService from "services/SK_IntroductionLetter/AskIssued/AskIssuedService";

const AskIssuedAction = {
  GetScreenData(data) {
    return AskIssuedService.GetScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  Print_F12(data) {
    return AskIssuedService.Print_F12(data)
      .then((res) => {
        return res;
      })
  },
  preview(data) {
    return AskIssuedService.preview(data)
      .then((res) => {
        return res;
      })
  },

}
export default AskIssuedAction;