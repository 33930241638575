import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Button,
  Input,
  Checkbox,
  Table,
  Select,
  InputNumber,
  Modal
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import WS3401002_AutoJudgmentNameSetting from 'pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401002_AutoJudgmentNameSetting.jsx'
import AutoJudgmentManagementAction from 'redux/SM_SystemMaintenance/AutoJudgmentManagement/AutoJudgmentManagement.action.js'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx'

class WS3401005_JudgmentDetailSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '資料一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      addCount: 0,
      selectedRow: {},
    }
  }

  componentDidMount = () => {
    this.getJudgmentDetailSettingData()
  }

  getJudgmentDetailSettingData = () => {
    let params = {
      judgment_name_code: this.props.judgment_name_code,
      inspection_code: this.props.inspection_code,
    }
    AutoJudgmentManagementAction.getJudgmentDetailSettingData(params)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          dataSource: res
        })
        this.setState({
          dataSource: res
        })
      })
  }

  add = () => {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    this.setState({
      dataSource: data,
      addCount: idCount,
    })
    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.forceUpdate()
  }

  /**
   * 削除　
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   * @param {*} id
   */
  delete = (id) => {

    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * それぞれの値の変更
   * @param {*} id
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(id, field, value) {
    let data = [...this.formRef.current?.getFieldValue('dataSource')]
    // let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // data[index][field] = value

    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })
    this.formRef.current?.setFieldsValue({
      dataSource: tempArr
    })
    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
  }

  onSave = () => {
    let params = {
      judgment_name_code: this.props.judgment_name_code,
      inspection_code: this.props.inspection_code,
      dataSource: this.state.dataSource
    }
    AutoJudgmentManagementAction.saveJudgmentDetailSetting(params)
      .then(res => {
        this.getJudgmentDetailSettingData()
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div className="judgment-detail-setting" style={{ width: 1000 }}>
        <Card title='判定詳細設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className="box_container">
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(220) }}
                rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedRow: record
                      })
                    }
                  }
                }}
              >
                <Table.Column
                  title='No'
                  dataIndex='display_number'
                  className="column-size-5"
                  render={(value, record) => (
                    <Form.Item
                      name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'display_number']}
                    >
                      <InputNumber
                        onBlur={(e) => this.updateDatasource(record.id, 'display_number', e.target.value)}
                      />
                    </Form.Item>
                  )}
                />
                <Table.Column
                  title='区分'
                  dataIndex='inspection_division'
                  className="column-size-10"
                  render={(value, record) => (
                    <Form.Item
                      name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'inspection_division']}
                    >
                      <Select
                        style={{ width: 100 }}
                        onSelect={(value) => this.updateDatasource(record.id, 'inspection_division', value)}
                      >
                        <Select.Option
                          key={'カテゴリ'}
                          value={1}
                        >
                          {'カテゴリ'}
                        </Select.Option>
                        <Select.Option
                          key={'検査'}
                          value={2}
                        >
                          {'検査'}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                />
                <Table.Column
                  title='コード'
                  dataIndex='category_inspection_code'
                  className="column-size-20"
                  render={(value, record) => (
                    <Form.Item
                      name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'category_inspection_code']}
                    >
                      <Input.Search
                        onSearch={() => {
                          if (record.inspection_division == 1) {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0267001_CategorySearchQuerySingle
                                    // Lio_CategoryCode={this.formRef.current.getFieldValue('category_code')}
                                    masterSettingFlg={1}
                                    onFinishScreen={(output) => {
                                      let error = false
                                      this.state.dataSource.forEach((value, index) => {
                                        if (value.inspection_division == 1 && (value.category_inspection_code == output.Lio_CategoryCode)) {
                                          error = true
                                        }
                                      })

                                      if (error == true) {
                                        Modal.error({
                                          content: '同一の区分・コードが存在します'
                                        })
                                      } else {
                                        this.updateDatasource(record.id, 'category_inspection_code', output.Lio_CategoryCode ?? '')
                                        this.updateDatasource(record.id, 'category_inspection_name', output.Lio_CategoryName ?? '')
                                      }
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          } else if (record.inspection_division == 2) {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0271001_InspectItemSearchQuerySingle
                                    onFinishScreen={(output) => {
                                      let error = false
                                      this.state.dataSource.forEach((value, index) => {
                                        if (value.inspection_division == 2 && (value.category_inspection_code == output.recordData.test_item_code)) {
                                          error = true
                                        }
                                      })

                                      if (error == true) {
                                        Modal.error({
                                          content: '同一の区分・コードが存在します'
                                        })
                                      } else {
                                        this.updateDatasource(record.id, 'category_inspection_code', output.recordData.test_item_code ?? '')
                                        this.updateDatasource(record.id, 'category_inspection_name', output.recordData.exam_name ?? '')
                                      }
                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                />
                <Table.Column
                  title='カテゴリ・検査名称'
                  dataIndex='category_inspection_name'
                  className="column-size-60"
                />
                <Table.Column
                  title='無効'
                  dataInde='invalid_flag'
                  width={45}
                  render={(value, record) => (
                    <Form.Item
                      name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'invalid_flag']}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <Checkbox
                          defaultChecked={this.formRef.current?.getFieldValue(['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'invalid_flag'])}
                          onChange={(e) => this.updateDatasource(record.id, 'invalid_flag', e.target.checked)}
                        />
                      </div>
                    </Form.Item>
                  )}
                />
                <Table.Column
                  width={45}
                  fixed='right'
                  title={
                    <div style={{ textAlign: 'center' }}>
                      <Button size='small'
                        onClick={() => this.add()}
                        type='primary'
                        icon={<PlusOutlined />}
                      />
                    </div>
                  }
                  render={(text, record, index) => {
                    return (
                      <Button
                        danger
                        size='small'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.delete(record.id)
                        }}
                      />
                    )
                  }}
                />
              </Table>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  onClick={() => this.onSave()}
                >
                  <span className="btn_label">
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3401005_JudgmentDetailSetting);