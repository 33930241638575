/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import { MoreOutlined, MenuOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Tooltip, Menu, Button, Select, Table, message, Dropdown } from 'antd'
import ReserveStatusSearchService from 'services/ReservationBusiness/ReserveStatusSearch/ReserveStatusSearchService'
import moment from 'moment-timezone'
import Color from 'constants/Color'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS2580001_ScheduleChange from './WS2580001_ScheduleChange'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2528031_SettingDisplayItem from './WS2528031_SettingDisplayItem'
import WS1490001_SpecificDatePeopleNumSetting from 'pages/SM_SystemMaintenance/V4SM0004000_SpecificDatePeopleNumSetting/WS1490001_SpecificDatePeopleNumSetting'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import WS2577003_CalendarEmptySub from './WS2577003_CalendarEmptySub'
import WS2532001_GroupBookings from '../V5YK0002000_GroupBookings/WS2532001_GroupBookings'
import WS2535001_FrameReserve from '../V5YK0003000_FrameReserve/WS2535001_FrameReserve'
import WS0548001_ReservesPersonsBulkUpdate from './WS0548001_ReservesPersonsBulkUpdate'
import ReservationDetails from './ReservationDetails'

const styles = {
  alignCenter: {
    align: 'left',
  },
  textAlign: {
    textAlign: 'center',
  },
}

const columnCount = 15
class WS2528001_ReserveStatusSearch extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '予約状況検索'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        centered: false
      },
      dataTitle: {},
      dataShowColumnExamineeList: {},
      screenData: {},
      //Fields search
      YearsChar: '',
      FacilityType: '',
      TimeZone: '',
      PersonsSituation: '',
      WS2528047_DetailsExtract: {
        Lio_OfficeCode: '', // Office_Extract
        Lio_TimeZone: '',  // TimeZone_Extract
        office_kanji_name: '',
      },
      //selected row left table
      selectedRowRecordReserveStatusDisplay: {},
      selectedRowRecordReserveStatusDisplay2: {},
      isCloseModalLoadReserveStatusDisplayAgain: false,
      isCloseModalLoadExamListAgain: false,
      // selected Column
      selectedColumn: {
        num_01: false,
        num_02: false,
        num_03: false,
        num_04: false,
        num_05: false,
        num_06: false,
        num_07: false,
        num_08: false,
        num_09: false,
        num_10: false,
        num_11: false,
        num_12: false,
        num_13: false,
        num_14: false,
        num_15: false,
      },
      num_final: null,

      CourseList: null,
      ExamList: null,
      SelectState: null,

      selectedDate: '',

      columnList: [], //2ケタゼロ埋め（表示行）リスト
      groupingFlagList: [] //検査項目のグルーピングフラグリスト
    }
  }

  /**
   *  初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      WS2577003_CalendarEmptySub: {
        Lio_Date: moment(new Date()).format('YYYY/MM/DD')
      }
    })

    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < columnCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ columnList: array })

    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    ReserveStatusSearchService.index()
      .then((res) => {

        this.formRef.current.setFieldsValue({
          ...res.data,
          FacilityType: 1, //施設
          YearsChar: moment(res.data?.YearsDate).isValid() //年月
            ? moment(res.data.YearsDate)
            : moment(),
        })
        this.setState({
          screenData: res.data
        })
        // １か月分のデータを取得
        this.onSearchRecreateDisplay()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * １か月分のデータを取得
   * @param {*} reload
   * @param {*} dateCurrent
   */
  onSearchRecreateDisplay = (reload = false, dateCurrent = null) => {

    const { YearsChar, FacilityType, TimeZone, PersonsSituation } = this.formRef.current.getFieldsValue()
    this.setState({ YearsChar, FacilityType, TimeZone, PersonsSituation })

    const params = {
      YearsChar: moment(YearsChar).format('YYYY/MM/DD'),
      FacilityType,
      TimeZone,
      PersonsSituation,
      OfficeExtract: this.state.WS2528047_DetailsExtract.Lio_OfficeCode,
      TimeZoneExtract: this.state.WS2528047_DetailsExtract.Lio_TimeZone,
    }

    ReserveStatusSearchService.recreateDisplay(params)
      .then((res) => {
        let datas = res?.data?.ReserveStatusDisplay?.length > 0 ? res?.data?.ReserveStatusDisplay : {}
        let date = dateCurrent ? moment(dateCurrent).format('YYYY/MM/DD') : reload ? moment(new Date()).format('YYYY/MM/DD') : moment(YearsChar).format('YYYY/MM/DD')
        this.setState({
          dataTitle: res.data,
          CourseList: '',
          ExamList: '',
          SelectState: '',
          WS2577003_CalendarEmptySub: {
            Lio_Date: date
          },
          selectedRowRecordReserveStatusDisplay: datas,
          groupingFlagList: res.data.groupingFlagList
        })
        this.formRef.current.setFieldsValue({
          ...res.data,
          ReserveStatusDisplay: res.data.ReserveStatusDisplay,
        })
        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 予約詳細一覧を取得
   * @param {*} param0
   * @param {*} bool
   */
  getExamineeList = (date, bool = false) => {
    const { FacilityType, TimeZone, } = this.formRef.current.getFieldsValue()
    const params = {
      date: moment(date).format('YYYY/MM/DD'),
      CourseDisplay: '',
      InspectDisplay: '',
      FacilityType: FacilityType,
      TimeZone: TimeZone
    }

    ReserveStatusSearchService.getExamineeList(params)
      .then((res) => {
        this.setState({
          dataShowColumnExamineeList: res.data,
        })
        this.formRef.current.setFieldsValue({
          ExamineeList: res.data?.ExamineeList,
        })

        //モーダルを開くタイミングで不具合が生じたためgetExamineeList内で実行
        if (bool) {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 'fit-content',
              component:
                <ReservationDetails
                  Li_Date={date}
                  Li_Examinee={{ ...res.data }}
                  onFinishScreen={() => {
                    this.closeModal()
                    this.onSearchRecreateDisplay()
                  }}
                />
            },
          })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  closeModal = () => {
    if (this.state.isCloseModalLoadReserveStatusDisplayAgain) {
      this.onSearchRecreateDisplay()
      this.setState({
        isCloseModalLoadReserveStatusDisplayAgain: false,
      })
    }
    if (this.state.isCloseModalLoadExamListAgain) {
      this.getExamineeList(this.state.selectedRowRecordReserveStatusDisplay?.date)
      this.setState({ isCloseModalLoadExamListAgain: false })
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: '',
        centered: false,
      },
    })
  }

  recalculation = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0548001_ReservesPersonsBulkUpdate
            Li_DateF={this.state.YearsChar}
            Li_DateT={this.state.YearsChar}
            Li_Facility={this.state.FacilityType}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
      isCloseModalLoadExamListAgain: true,
    })
  }

  displaySetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS2528031_SettingDisplayItem
            Lio_CourseList={
              this.state
                .WS2528031_SettingDisplayItem
                ?.Lio_CourseList
            }
            Lio_ExamList={
              this.state
                .WS2528031_SettingDisplayItem
                ?.Lio_ExamList
            }
            onFinishScreen={({
              Lio_CourseList,
              Lio_ExamList,
            }) => {
              this.setState({
                WS2528031_SettingDisplayItem: {
                  Lio_CourseList,
                  Lio_ExamList,
                },
              })
              this.closeModal()
            }}
          />
        ),
      },
      isCloseModalLoadReserveStatusDisplayAgain: true,
    })
  }

  render() {
    return (
      <div className='reserve-status-search'>
        <Card title='予約状況検索' >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.recalculation}>
                      人数再計算
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.displaySetting}>
                      表示設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />} />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
              initialValues={{
                TimeZone: '',
                PersonsSituation: 0,
              }}
            >
              <div className='box_inner_horizontal'>
                <div className='box_inner_horizontal' style={{ gap: 20 }}>
                  <Form.Item
                    name='YearsChar'
                    label='年月'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      width='130px'
                      picker='month'
                      format='YYYY年MM月'
                      allowClear={false}
                      onChange={() => this.onSearchRecreateDisplay()}
                    />
                  </Form.Item>
                  <Form.Item
                    name='FacilityType'
                    label='施設'
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      onChange={() => this.onSearchRecreateDisplay()}
                    >
                      {this.state.screenData.FacilityType_ComboBox?.map(
                        (item, index) => {
                          return (
                            <Select.Option
                              key={'facility-type-' + index}
                              value={item.LinkedField}
                            >
                              {item.DisplayedField}
                            </Select.Option>
                          )
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='TimeZone'
                    label='時間区分'
                    style={{ marginBottom: 0 }}
                  >
                    <Select onChange={() => this.onSearchRecreateDisplay()}>
                      <Select.Option value={''}>すべて</Select.Option>
                      <Select.Option value={'AM'}>ＡＭ</Select.Option>
                      <Select.Option value={'PM'}>ＰＭ</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='PersonsSituation'
                    label='人数表示'
                    style={{ marginBottom: 0 }}
                  >
                    <Select onChange={() => this.onSearchRecreateDisplay()}>
                      <Select.Option value={0}>実人数</Select.Option>
                      <Select.Option value={1}>空状況</Select.Option>
                    </Select>
                  </Form.Item>
                  <Button
                    type='primary'
                    onClick={() => this.onSearchRecreateDisplay()}
                  >
                    再表示
                  </Button>
                </div>
              </div>
            </Form>

            <div className='box_inner_vertical'>
              {/* Table1 */}
              <Table
                className='custom-table-scroll handle-table-calendar'
                size='small'
                dataSource={
                  this.formRef.current
                    ? this.formRef.current.getFieldValue('ReserveStatusDisplay')
                    : []
                }
                pagination={false}
                bordered={true}
                rowKey={(record) => record.id}
                scroll={{ y: resizableTableScroll(70), x: 'max-content' }}
                rowClassName={(record, index) => record.date === this.state.selectedDate ? 'table-row-light'
                  :
                  record.holidayColor === 281 ?
                    'table-row-holiday'
                    :
                    ''
                }
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedDate: record.date
                      })
                    },
                    onDoubleClick: () => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 'fit-content',
                          component:
                            <ReservationDetails
                              Li_Date={record.date}
                              Li_CourseDisplay={this.state.screenData.CourseDisplay ?? ''}
                              Li_InspectDisplay={this.state.screenData.InspectDisplay ?? ''}
                              Li_FacilityType={this.formRef.current.getFieldValue('FacilityType')}
                              Li_TimeZone={this.formRef.current.getFieldValue('TimeZone')}
                              onFinishScreen={() => {
                                this.onSearchRecreateDisplay()
                              }}
                            />
                        },
                      })
                    },
                  }
                }}
              >
                <Table.Column
                  title='日付'
                  className='column-size-4'
                  dataIndex='dateStr'
                  {...styles.alignCenter}
                  render={(text, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'center',
                          color: record.dateColor
                            ? Color(record.dateColor)?.Foreground
                            : '',
                        }}
                      >
                        {text + ' ' + record.weekStr}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  {...styles.alignCenter}
                  className='column-size-4'
                  title='人数'
                  dataIndex='totalPersons'
                  render={(text, record, index) => {
                    return text === '0' || text === 0 ? '' :
                      <div
                        style={{
                          textAlign: 'right',
                          paddingRight: '25%'
                        }}
                      >
                        {text}
                      </div>
                  }}
                />
                <Table.Column
                  {...styles.alignCenter}
                  width={250}
                  title='メモ欄'
                  dataIndex='title'
                />

                {this.state.columnList.map((item, index) => {
                  const numIndex = index + 1
                  const groupingFlag = this.state.groupingFlagList[index] ?? 0
                  let columnComp = null
                  if (!groupingFlag) {
                    // グルーピングされていないデータだけ表示
                    columnComp = (
                      <Table.Column
                        key={`table-column-${index}`}
                        {...styles.alignCenter}
                        className='column-size-4'
                        title={this.state.dataTitle?.[`columnTitle_${numIndex}`]}
                        dataIndex={`customPersonNum_${numIndex}`}
                        render={(text, record) => {
                          return (
                            text === '0' || text === 0 ? '' :
                              <Tooltip title={record[`customPersonNum_${numIndex}`]}>
                                <div
                                  style={{
                                    color: record[`skyPersonNumColor_${numIndex}`]
                                      ? Color(record[`skyPersonNumColor_${numIndex}`])?.Foreground
                                      : '',
                                    textAlign: 'right',
                                    paddingRight: '25%'
                                  }}
                                >
                                  {text}
                                </div>
                              </Tooltip>
                          )
                        }}
                      />
                    )
                  }
                  return columnComp
                })}

                <Table.Column
                  key='action'
                  fixed='right'
                  width={40}
                  align='center'
                  render={(text, record, index) => {
                    if (record.weekStr === '数') return <div style={{ minHeight: 22 }} />
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 500,
                                    component: (
                                      <WS2580001_ScheduleChange
                                        Li_Date={record.date}
                                        onFinishScreen={() => {
                                          this.onSearchRecreateDisplay(false, record.date)
                                        }}
                                      />
                                    ),
                                  },
                                  isCloseModalLoadReserveStatusDisplayAgain: false,
                                })
                              }}
                            >
                              {/* 2580 */}
                              <label>メモ欄</label>
                            </Menu.Item>
                            <Menu.Item
                              key='2'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 'fit-content',
                                    centered: true,
                                    component: (
                                      <WS2537001_PersonalReserveProcess
                                        Li_CourseLevel={''}
                                        Li_ReserveNum={''}
                                        Li_PersonalNum={''}
                                        Li_Date={record.date}
                                        Li_Getctxname={''}
                                        Li_ProcessDivision={''}
                                        Li_Option={''}
                                        Li_Child={true}
                                        onFinishScreen={() => {
                                          this.closeModal()
                                          this.onSearchRecreateDisplay()
                                        }}
                                      />
                                    ),
                                  },
                                  isCloseModalLoadExamListAgain: false,
                                  isCloseModalLoadReserveStatusDisplayAgain: false,
                                })
                              }}
                            >
                              {/* 2537 */}
                              <label>個人予約</label>
                            </Menu.Item>
                            <Menu.Item
                              key='5'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '600px',
                                    component: (
                                      <WS1490001_SpecificDatePeopleNumSetting
                                        Li_Date={record.date}
                                        Li_Facility={this.state.FacilityType}
                                        onFinishScreen={() => {
                                          //データ再取得　休診日が変更になった為
                                          this.onSearchRecreateDisplay()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                            >
                              {/* 232 */}
                              <label>人数枠設定</label>
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />} />
                      </Dropdown>
                    )
                  }}
                />

                {/* Table1 */}
              </Table>
            </div>
            <div className='box_inner_horizontal'>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 900,
                      component: (
                        <WS2577003_CalendarEmptySub
                          Lio_Date={this.state.YearsChar}

                          onFinishScreen={({ Lio_Date }) => {
                            this.setState({
                              WS2577003_CalendarEmptySub: {
                                Lio_Date: Lio_Date,
                              },
                            })
                            this.formRef.current.setFieldsValue({
                              YearsChar: moment(Lio_Date).format('YYYY/MM'),
                            })
                            this.onSearchRecreateDisplay(false, Lio_Date)
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  })
                }}
              >
                受入状況
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '90%',
                      component: (
                        <WS0248001_PersonalInfoSearchQuery
                          date={this.state.selectedDate}
                          Li_Date={this.state.selectedDate}
                          onFinishScreen={({ Lo_PersonalNumId }) => {
                            this.setState({
                              WS0248001_PersonalInfoSearchQuery: {
                                Lo_PersonalNumId: Lo_PersonalNumId,
                              },
                            })
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  })
                }}
              >
                個人情報
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 'fit-content',
                      centered: true,
                      component: (
                        <WS2537001_PersonalReserveProcess
                          Li_Date={this.state.selectedDate}
                          Li_Child={true}
                          onFinishScreen={() => {
                            this.onSearchRecreateDisplay()
                          }}
                        />
                      ),
                    },
                    isCloseModalLoadExamListAgain: false,
                    isCloseModalLoadReserveStatusDisplayAgain: false,
                  })
                }}
              >
                個人予約
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '80%',
                      component: (
                        <WS2532001_GroupBookings
                          Li_ReserveDate={
                            this.state.selectedRowRecordReserveStatusDisplay
                              ?.date
                          }
                          Li_Way={''}
                          onFinishScreen={() => {
                            this.onSearchRecreateDisplay()
                          }}
                        />
                      ),
                    },
                  })
                }}
              >
                団体予約
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '70%',
                      component: (
                        // 事業所およびコース選択時にレイアウトが崩れるため検索ボックスとテーブル幅を合わせることを断念
                        <WS2535001_FrameReserve
                          modalFlag={true}
                          Li_ReserveDate={
                            this.formRef.current
                              ? this.formRef.current.getFieldValue('YearsChar')
                              : ''
                          }
                          onFinishScreen={() => {
                            this.closeModal()
                            this.onSearchRecreateDisplay()
                          }}
                        />
                      ),
                    },
                  })
                }}
              >
                枠取予約
              </Button>
            </div>
          </div>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2528001_ReserveStatusSearch)
