import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import PropTypes from "prop-types";
import moment from 'moment'
import { connect } from "react-redux";
import { Card, Form, Input, Select, Modal, Button, InputNumber } from "antd";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0302001_SetInfoSearch from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0302001_SetInfoSearch";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";
import ModalDraggable from "components/Commons/ModalDraggable";
import ItemizedAction from "redux/AccountingBusiness/DepositWithdrawalInquiry/Itemized.action";
import NumberFormat from 'components/Commons/NumberFormat'

const labelCol = {
  labelCol: { style: { width: '65px' } },
}

const labelCol2 = {
  labelCol: { style: { width: '50px' } },
}

const { TextArea } = Input

class WS0964020_SelectBreakdown extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = "内訳選択";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      SpecifyClassify: null,
      TaxClassify: null,
      Tax: 0,
    };

    this.setFormFieldValue = this.setFormFieldValue.bind(this);
  }


  componentDidMount() {
    this.setState({
      SpecifyClassify: this.props.Lio_SpecifyClassify,
      TaxClassify: this.props.Lio_TaxClassify
    })
    this.formRef.current.setFieldsValue({
      ...this.props,
      Lio_Tax_ExcludedAmount: this.props.Lio_Tax_ExcludedAmount == 0 ? null : this.props.Lio_Tax_ExcludedAmount,
      Lio_Tax: this.props.Lio_Tax == 0 ? null : this.props.Lio_Tax,
      Lio_Tax_IncludedAmount: this.props.Lio_Tax_IncludedAmount,
    });

    // 設定されている税率の取得
    ItemizedAction.getTax(this.props.Li_BillingPeriodBeginning)
      .then((res) => {
        this.setState({ Tax: res.data })
      })
    this.forceUpdate();
  }


  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }


  // 合計計算
  onTotalAmount() {
    let price = this.formRef.current?.getFieldValue("Lio_Tax_ExcludedAmount")
    let tax = this.formRef.current?.getFieldValue("Lio_Tax")
    let people = this.formRef.current?.getFieldValue("Lio_PeopleNum")

    // 税込み金額
    let taxIncluded = (Number(price) + Number(tax))
    // 総合計
    let total = Number(taxIncluded) * Number(people);

    this.setFormFieldValue("Lio_Tax_IncludedAmount", taxIncluded);
    this.setFormFieldValue("Lio_TotalAmount", total);
  }


  // 名称取得
  search_Lio_Content = () => {
    switch (this.state.SpecifyClassify) {
      case 0:
      case 10:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "60%",
            component: (
              <WS0247001_OfficeInfoRetrievalQuery
                onFinishScreen={(output) => {
                  this.setFormFieldValue("Lio_Content", output.Lo_Kanji_Name);
                  this.setFormFieldValue("Lio_OfficeCode", output.Lio_OfficeCode);

                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      case 1:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1000,
            component: (
              <WS0302001_SetInfoSearch
                onFinishScreen={(output) => {
                  this.setFormFieldValue("Lio_Content", output.recordData.set_name);
                  this.setFormFieldValue("Lio_SetCode", output.recordData.set_code);

                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      case 3:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "60%",
            component: (
              <WS0265001_BasicCourseInquiry
                onFinishScreen={(output) => {
                  this.setFormFieldValue("Lio_Content", output.Lo_CourseName);
                  this.setFormFieldValue("Lio_CourseCode", output.Lo_CourseCode);

                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      default:
        break;
    }
  }


  // 個人情報取得
  search_Lio_PersonalNum = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "60%",
        component: (
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            onFinishScreen={(output) => {
              this.setFormFieldValue("Lio_PersonalNum", output.Lo_PersonalNumId);
              this.setFormFieldValue("Lio_Name", output.recordData.kanji_name);

              this.closeModal();
            }}
          />
        ),
      },
    });
  }


  // 税区分変更
  update_Lio_Tax_IncludedAmount = () => {
    let Lio_Tax_ExcludedAmount = this.formRef.current.getFieldValue('Lio_Tax_ExcludedAmount')
    let Lio_Tax = this.formRef.current?.getFieldValue('Lio_Tax')
    let Lio_Tax_IncludedAmount = this.formRef.current?.getFieldValue('Lio_Tax_IncludedAmount')

    if (this.state.TaxClassify == 0) {
      // 税指定
      this.formRef.current?.setFieldsValue({
        Lio_Tax_ExcludedAmount: Math.round(Lio_Tax_ExcludedAmount),
        Lio_Tax: 0,
      })

    } else if (this.state.TaxClassify == 1) {
      // 外税
      Lio_Tax = parseInt(Number(Lio_Tax_ExcludedAmount) * (Number(this.state.Tax)))

      this.formRef.current?.setFieldsValue({
        Lio_Tax_ExcludedAmount: Math.round(Lio_Tax_ExcludedAmount),
        Lio_Tax: Math.round(Lio_Tax),
      })

    } else if (this.state.TaxClassify == 2) {
      // 内税
      // 消費税額＝税込価格÷（1＋消費税率）× 消費税率
      Lio_Tax = parseInt(Number(Lio_Tax_IncludedAmount) / (1 + (Number(this.state.Tax))) * (Number(this.state.Tax)))
      Lio_Tax_ExcludedAmount = Number(Lio_Tax_IncludedAmount) - Number(Lio_Tax)

      this.formRef.current?.setFieldsValue({
        Lio_Tax_ExcludedAmount: Math.round(Lio_Tax_ExcludedAmount),
        Lio_Tax: Math.round(Lio_Tax)
      })

    } else if (this.state.TaxClassify == 3) {
      this.formRef.current?.setFieldsValue({
        Lio_Tax: 0,
        Lio_Tax_IncludedAmount: Math.round(Lio_Tax_ExcludedAmount)
      })
    }

    this.onTotalAmount()
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="select-breakdown">
        <Card className="mb-2" title="内訳選択">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className="box_inner_horizontal">
              <div
                className="box_search_inner_border"
                style={{ width: '45%', gap: '10px' }}
              >
                <Form.Item
                  name='Lio_ItemSequentialNum'
                  label='連番'
                  {...labelCol}
                >
                  <Input
                    ref={(input) => { this.name_sequential_num = input; }}
                    className="input-size-number-4"
                    type='text'
                    style={{ textAlign: 'right' }}
                    onBlur={(e) => {
                      if (e.target.value < 10) {
                        Modal.error({
                          width: 300,
                          title: '使用できない番号です',
                          onOk: () => {
                            this.name_sequential_num.focus();
                          }
                        })
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="Lio_SpecifyClassify"
                  label="区分"
                  {...labelCol}
                >
                  <Select
                    onChange={(value) => {
                      this.setState({ SpecifyClassify: value })
                      this.forceUpdate()
                    }}
                    style={{ width: '150px' }}
                  >
                    <Select.Option value={0}>ｺﾒﾝﾄ区切</Select.Option>
                    <Select.Option value={1}>セット</Select.Option>
                    <Select.Option value={3}>コース</Select.Option>
                    <Select.Option value={5}>コメント</Select.Option>
                    <Select.Option value={7}>明細無効</Select.Option>
                    <Select.Option value={9}>無効</Select.Option>
                    <Select.Option value={10}>区切</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Lio_Content"
                  label="名称"
                  {...labelCol}
                >
                  <Input.Search
                    disabled={
                      this.state.SpecifyClassify === 5 ||
                      this.state.SpecifyClassify === 7 ||
                      this.state.SpecifyClassify === 9
                    }
                    className='input-size-40'
                    type="text"
                    onSearch={() => {
                      this.search_Lio_Content()
                    }}
                  />
                </Form.Item>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="Lio_Tax_ExcludedAmount"
                    label="単価"
                    {...labelCol}
                  >
                    <InputNumber
                      className='ant-input-number-currency-10'
                      formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                      disabled={
                        this.state.TaxClassify === 2 ||
                        this.state.SpecifyClassify === 0 ||
                        this.state.SpecifyClassify === 5 ||
                        this.state.SpecifyClassify === 10
                      }
                      onBlur={() => {
                        this.update_Lio_Tax_IncludedAmount()
                      }}
                      onPressEnter={() => {
                        this.update_Lio_Tax_IncludedAmount()
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Lio_TaxClassify"
                    label="税区分"
                    {...labelCol}
                  >
                    <Select
                      onChange={async (value) => {
                        await this.setState({ TaxClassify: value })
                        await this.update_Lio_Tax_IncludedAmount()
                        await this.onTotalAmount()
                      }}
                      disabled={
                        this.state.SpecifyClassify === 0 ||
                        this.state.SpecifyClassify === 5 ||
                        this.state.SpecifyClassify === 10
                      }
                      style={{ width: '150px' }}
                    >
                      <Select.Option value={0}>税指定</Select.Option>
                      <Select.Option value={1}>外税</Select.Option>
                      <Select.Option value={2}>内税</Select.Option>
                      <Select.Option value={3}>非課税</Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                <Form.Item
                  name="Lio_Tax"
                  label="消費税"
                  {...labelCol}
                >
                  <InputNumber
                    className='ant-input-number-currency-10'
                    formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                    disabled={
                      this.state.TaxClassify !== 0 ||
                      this.state.SpecifyClassify === 0 ||
                      this.state.SpecifyClassify === 5 ||
                      this.state.SpecifyClassify === 10
                    }
                    onBlur={() => {
                      this.onTotalAmount()
                    }}
                    onPressEnter={() => {
                      this.onTotalAmount()
                    }}
                  />
                </Form.Item>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="Lio_Tax_IncludedAmount"
                    label="合計"
                    {...labelCol}
                  >
                    <InputNumber
                      className='ant-input-number-currency-10'
                      formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                      disabled={
                        this.state.TaxClassify !== 2 ||
                        this.state.SpecifyClassify === 0 ||
                        this.state.SpecifyClassify === 5 ||
                        this.state.SpecifyClassify === 10
                      }
                      onBlur={() => {
                        this.update_Lio_Tax_IncludedAmount()
                      }}
                      onPressEnter={() => {
                        this.update_Lio_Tax_IncludedAmount()
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Lio_PeopleNum"
                    label="x"
                  >
                    <InputNumber
                      type="number"
                      min={1}
                      initialvalues={1}
                      className="input-size-10"
                      style={{ textAlign: 'right' }}
                      disabled={
                        this.state.SpecifyClassify === 0 ||
                        this.state.SpecifyClassify === 5 ||
                        this.state.SpecifyClassify === 10
                      }
                      onChange={(event) => {
                        this.onTotalAmount()
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Lio_TotalAmount"
                    label="="
                  >
                    <InputNumber
                      readOnly
                      style={{ border: "none", background: "transparent" }}

                      formatter={value => value == 0 ? '' : NumberFormat.formatter(value)}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="box_inner_vertical" style={{ width: '55%' }}>
                <Form.Item
                  name="Lio_DateChar"
                  label="日付"
                  {...labelCol2}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format="YYYY/MM/DD"
                  />
                </Form.Item>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="Lio_PersonalNum"
                    label="氏名"
                    {...labelCol2}
                  >
                    <Input.Search
                      type="text"
                      className="input-size-30"
                      onSearch={() => {
                        this.search_Lio_PersonalNum()
                      }}
                    />
                  </Form.Item>

                  <div style={{ marginTop: 5, marginLeft: 10 }}>{this.formRef.current?.getFieldValue('Lio_Name')}</div>
                </div>

                <Form.Item
                  name="Lio_Remarks"
                  label="備考"
                  style={{ marginRight: 10 }}
                  {...labelCol2}
                >
                  <TextArea
                    type="text"
                    style={{ height: 170 }}
                    disabled={
                      this.state.SpecifyClassify === 0 ||
                      this.state.SpecifyClassify === 5 ||
                      this.state.SpecifyClassify === 10
                    }
                  />
                </Form.Item>
              </div>
            </div>

            <div className="box_button_bottom_right">
              <Button
                danger
                type='primary'
                onClick={() => {
                  Modal.confirm({
                    content: '明細を削除します。よろしいですか？',
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () =>
                      this.props.onRowDelete({
                        id: this.props.Li_id
                      })
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                onClick={() => {
                  this.props.onFinishScreen({
                    id: this.props.Li_id,
                    W2_item_sequential_num: this.formRef.current?.getFieldValue('Lio_ItemSequentialNum') ?? '',
                    W2_item_sect: this.formRef.current?.getFieldValue('Lio_SpecifyClassify') ?? '',
                    W2_content: this.formRef.current?.getFieldValue('Lio_Content') ?? '',
                    W2_price_excluding_tax: this.formRef.current?.getFieldValue('Lio_Tax_ExcludedAmount') ?? 0,
                    W2_tax_sect: this.formRef.current?.getFieldValue('Lio_TaxClassify') ?? '',
                    W2_tax: this.formRef.current?.getFieldValue('Lio_Tax') ?? 0,
                    W2_include_tax_price: this.formRef.current?.getFieldValue('Lio_Tax_IncludedAmount') ?? 0,
                    W2_person: this.formRef.current?.getFieldValue('Lio_PeopleNum') ?? '',
                    W2_total_price: this.formRef.current?.getFieldValue('Lio_TotalAmount') ?? 0,
                    W2_consult_date_char: moment(this.formRef.current?.getFieldValue('Lio_DateChar')).format('YYYY/MM/DD') ?? '',
                    W2_person_num: this.formRef.current?.getFieldValue('Lio_PersonalNum') ?? '',
                    W2_full_name: this.formRef.current?.getFieldValue('Lio_PersonalNum') == '' ? '' : this.formRef.current?.getFieldValue('Lio_Name') ?? '',
                    W2_remark: this.formRef.current?.getFieldValue('Lio_Remarks') ?? '',
                    Lio_OfficeCode: this.formRef.current?.getFieldValue('Lio_OfficeCode') ?? '',
                    Lio_SetCode: this.formRef.current?.getFieldValue('Lio_SetCode') ?? '',
                    Lio_CourseCode: this.formRef.current?.getFieldValue('Lio_CourseCode') ?? '',
                  })
                }}
              >
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0964020_SelectBreakdown);
