import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withScreenPreset } from "modules/common/with-screen-preset";
import { Card, Form, message, Table } from "antd";
import ConsultTicketInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ConsultTicketInputSub.action'

const styleLabel = {
  background: "#1264bd",
  padding: "5px",
  border: "1px solid #fff",
  color: "#fff",
  textAlign: "center"
};

const styleLabelOnly = {
  background: "#b8cada",
  padding: "5px",
  border: "1px solid #fff",
  textAlign: "center"
};

// 表示行数
const rowCount = 10

class WS1296200_ConsultTicketInputSubAdditionalMedicalExamination extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        defaultPageSize: 10,
        size: 1,
      },
      rowList: [], //2ケタゼロ埋め（表示行）リスト
      tableData: [],
    };
  }
  componentDidMount = async () => {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < rowCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ rowList: array })
    this.getDataSource(array)
  };

  getDataSource(list) {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.props.Li_CourseLevel
    }
    ConsultTicketInputSubAction.AdditionalMedicalExamination(params)
      .then(res => {
        let array = []
        let data = res.data
        let obj, nameNumber, name, price, Expresstion
        let expressionNumber = 127
        // 2桁ゼロ埋めリスト
        let numberList = list
        for (let i = 0; i < rowCount; i++) {
          // 2桁ゼロ埋めの値を取得
          nameNumber = numberList[i]
          // 表示名称
          name = data[`additional_medical_exam_name_${nameNumber}`]
          price = data[`additional_medical_exam_unit_price_${nameNumber}`]
          Expresstion = data[`Expresstion_${expressionNumber}`]
          obj = {
            id: i + 1,
            additional_medical_exam_name: name,
            additional_medical_exam_unit_price: price,
            Expresstion: Expresstion
          }
          // 配列に追加
          array.push(obj)
          expressionNumber++
        }
        this.setState({ tableData: array })
        this.formRef.current?.setFieldsValue({
          dataTable: array
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {
    return (
      <div className="consult-ticket-input-process-list">
        <Card title="追加健診">
          <Table
            bordered
            dataSource={this.state.tableData ? this.state.tableData : []}
            size='small'
            rowKey={(record) => record.id}
            pagination={false}
          >
            <Table.Column
              title='No'
              dataIndex='id'
              align="center"
              className='column-size-5'
            />
            <Table.Column
              title='追加健診名'
              dataIndex='additional_medical_exam_name'
              align="center"
            />
            <Table.Column
              title='単価'
              dataIndex='additional_medical_exam_unit_price'
              align="center"
            />
            <Table.Column
              title='決済'
              dataIndex='Expresstion'
              align="center"
            />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withScreenPreset
)(WS1296200_ConsultTicketInputSubAdditionalMedicalExamination);