import { message } from "antd";
import StartupAndMaintenanceService from "services/MN_Basis/StartupAndMaintenance/StartupAndMaintenance.Service";

const StartupAndMaintenanceAction = {
  getScreenData() {
    return StartupAndMaintenanceService.getScreenData()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default StartupAndMaintenanceAction;
