import React from 'react'
import { connect } from 'react-redux'
import { Card, Modal, Button, List, Space, Row, Col } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0920008_IntroduceLetterTargetCmtMaintain from './WS0920008_IntroduceLetterTargetCmtMaintain'
import WS0922009_DepartmentMaster from './WS0922009_DepartmentMaster'
import WS0922005_InspectNameMaster from './WS0922005_InspectNameMaster'
import WS0922007_FindingsContentMaster from './WS0922007_FindingsContentMaster'
import WS0922010_DayOfWeekBasedDeterminePhysicianMaster from './WS0922010_DayOfWeekBasedDeterminePhysicianMaster'
import WS0922003_MedicalInstitutionsMaster from './WS0922003_MedicalInstitutionsMaster'
import WS0922006_VictimNameMaster from './WS0922006_VictimNameMaster'
import WS0922004_DoctorNameMaster from './WS0922004_DoctorNameMaster'
import WS0922008_TreatmentContentMaster from './WS0922008_TreatmentContentMaster'
import WS0922011_ExamDepartmentMaster from './WS0922011_ExamDepartmentMaster'
import FollowUpLetterTargetCmtMaintain from './FollowUpLetterTargetCmtMaintain'

class WS0919001_IntroduceLetterMasterMaintain extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '紹介状マスタ保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0
      }
    }
  }

  /**
   * 診療科(検査部門)マスタ 三愛用
   */
  showDepartmentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: <WS0922009_DepartmentMaster LnkInmanageCode={7} />
      }
    })
  }

  /**
   * 紹介状対象コメント保守
   */
  showIntroduceLetterTargetCmtMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: <WS0920008_IntroduceLetterTargetCmtMaintain />
      }
    })
  }

  /**
   * 経過手紙対象コメント保守
   */
  showFollowUpLetterTargetCmtMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: <FollowUpLetterTargetCmtMaintain />
      }
    })
  }

  showMedicalInstitutionsMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922003_MedicalInstitutionsMaster LnkInmanageCode={4} />
      }
    })
  }

  showDoctorNameMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922004_DoctorNameMaster LnkInmanageCode={6} />
      }
    })
  }

  showDayOfWeekBasedDeterminePhysicianMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922010_DayOfWeekBasedDeterminePhysicianMaster LnkInmanageCode={1} />
      }
    })
  }

  showInspectNameMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922005_InspectNameMaster LnkInmanageCode={2} />
      }
    })
  }

  showVictimNameMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922006_VictimNameMaster LnkInmanageCode={5} />
      }
    })
  }

  showFindingsContentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922007_FindingsContentMaster LnkInmanageCode={8} />
      }
    })
  }

  showTreatmentContentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: <WS0922008_TreatmentContentMaster LnkInmanageCode={3} />
      }
    })
  }

  /**
   * 診療科(検査部門)マスタ ※使用していない
   */
  showExamDepartmentMaster = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: <WS0922011_ExamDepartmentMaster LnkInmanageCode={9} />
      }
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-master-maintain'>
        <Card title='紹介状マスタ保守'>
          <Row>
            <Col span={24}>
              <List itemLayout='horizontal'>
                {/* <List.Item>
                  <List.Item.Meta
                    title='診療科'
                    description='診療科の設定を行います'
                  ></List.Item.Meta>
                  <div>
                    <Button
                      type='primary'
                      onClick={() => {
                        this.showDepartmentMaster()
                      }}
                    >
                      診療科
                    </Button>
                  </div>
                </List.Item> */}
                <List.Item>
                  <List.Item.Meta
                    title='診療科(検査部門)'
                    description='診療科(検査部門)の設定を行います'
                  ></List.Item.Meta>
                  <div>
                    <Button
                      type='primary'
                      onClick={() => {
                        // this.showExamDepartmentMaster()
                        this.showDepartmentMaster()
                      }}
                    >
                      {'診療科(検査部門)'}
                    </Button>
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title='コメント'
                    description='紹介状を自動作成する設定を行います'
                  ></List.Item.Meta>
                  <div>
                    <Button
                      type='primary'
                      onClick={() => {
                        this.showIntroduceLetterTargetCmtMaintain()
                      }}
                    >
                      コメント
                    </Button>
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title='コメント(経過観察用)'
                    description='経過手紙を自動作成する設定を行います'
                  ></List.Item.Meta>
                  <div>
                    <Button
                      type='primary'
                      onClick={() => {
                        this.showFollowUpLetterTargetCmtMaintain()
                      }}
                    >
                      コメント(経過観察用)
                    </Button>
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title='施設関係'
                    description='医療機関や判定医の設定を行います'
                  ></List.Item.Meta>
                  <div>
                    <Space>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showMedicalInstitutionsMaster()
                        }}
                      >
                        医療機関
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showDoctorNameMaster()
                        }}
                      >
                        判定医
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showDayOfWeekBasedDeterminePhysicianMaster()
                        }}
                      >
                        曜日別
                      </Button>
                    </Space>
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title='返送内容'
                    description='返送内容を登録する際に使用する、照会選択の設定'
                  ></List.Item.Meta>
                  <div>
                    <Space>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showInspectNameMaster()
                        }}
                      >
                        検査内容
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showVictimNameMaster()
                        }}
                      >
                        傷病名
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showFindingsContentMaster()
                        }}
                      >
                        所見内容
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          this.showTreatmentContentMaster()
                        }}
                      >
                        治療内容
                      </Button>
                    </Space>
                  </div>
                </List.Item>
              </List>
            </Col>
          </Row>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0919001_IntroduceLetterMasterMaintain)
