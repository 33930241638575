import EastFukyoInputService from 'services/ResultOutput/EastFukyoConverseProcess/EastFukyoInputService'
import { message } from 'antd'

const EastFukyoInputAction = {
  index(data) {
    return EastFukyoInputService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  settingChange(data) {
    return EastFukyoInputService.settingChange(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  confirmationChange(data) {
    return EastFukyoInputService.confirmationChange(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}

export default EastFukyoInputAction
