import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Input, Button, Popconfirm, Space, message, Modal, InputNumber } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1386001_InspectItemInquiry from "./WS1386001_InspectItemInquiry";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined
} from "@ant-design/icons";
class WS1396009_SupportItemSub extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = '支援項目サブ';

    this.state = {
      isLoading: true,
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      count: 'a',
    };
  }

  componentDidMount() {
    this.getData(this.props.SupportCode);
  }

  componentDidUpdate(prevProps) {
    if (this.props.SupportCode !== prevProps.SupportCode) {
      this.getData(this.props.SupportCode);
    }
  }


  getData(params) {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API001396009002", { SupportCode: params }).then(res => {
      this.setState({
        dataSource: res,
      })
      this.formRef?.current?.setFieldsValue({ 'dataSource': res ? res : [] });
    }).finally(() => this.setState({ isLoading: false }));
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  handleAddNew = () => {
    const newData = {
      id: this.state.count,
      exam_code: null,
      formal_name: '',
      display_order: null,
    }
    this.setState({
      ...this.state,
      count: this.state.count + 1,
    })
    let data = [...this.formRef.current?.getFieldValue("dataSource")];
    data.unshift(newData)
    this.formRef.current?.setFieldsValue({
      dataSource: data,
    });
    this.forceUpdate()
  }

  saveData = async (record) => {
    this.setState({ isLoading: true });
    await VenusApiRoutesV2.callApi("API001396009005", {mainSource: [record]})
    .then(async (res) => {
      this.getData(this.props.SupportCode);
      if(res.success) {
        message.success(res.message);
      } else {
        message.error(res);
      }
    })
    .catch((err) => {
      const res = err.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    }).finally(() => this.setState({ isLoading: false }));
  }

  deleteData = async (record) => {
    if(!isNaN(record.id)) {
      this.setState({ isLoading: true });
      await VenusApiRoutesV2.callApi("API001396009006", {mainSource: [record]})
      .then(async (res) => {
        if(res.success) {
          message.success(res.message);
        } else {
          message.error(res);
        }
        this.getData(this.props.SupportCode)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      }).finally(() => this.setState({ isLoading: false }));
    } else {
      let array = [...this.formRef.current?.getFieldValue("dataSource")];
      array.splice(array.findIndex(item => item.id === record.id), 1);
      this.formRef.current?.setFieldsValue({
        dataSource: array,
      });
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div className="support-item-sub">
        <Card className="mb-2" title="支援項目サブ">
          <Form ref={this.formRef}>
            <Table bordered
              dataSource={this.formRef.current?.getFieldValue("dataSource") ? this.formRef.current?.getFieldValue("dataSource") : []}
              loading={this.state.isLoading}
              rowKey={(record) => record.id}
            >
              <Table.Column title="コード" dataIndex="exam_code" showSorterTooltip={false} sorter={(a, b) => a.exam_code - b.exam_code}
                render={(text, record) => {
                  let index = this.findIndexByID(this.formRef.current?.getFieldValue("dataSource"), record.id);
                  return isNaN(record.id) ? (
                    <Form.Item name={['dataSource', index, 'exam_code']} >
                      <Input readOnly style={{width: '100px'}}
                        onDoubleClick={() => this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '60%',
                            component: (
                              <WS1386001_InspectItemInquiry
                                exam_code={record.exam_code}
                                onFinishScreen={(output) => {
                                  record.exam_code = output.exam_code;
                                  record.formal_name = output.formal_name;
                                  record.support_code = this.props.SupportCode;
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  });
                                  this.forceUpdate();
                                }}
                                
                              />
                            ),
                          },
                        })}/>
                    </Form.Item>
                  ) : (<span>{record.exam_code}</span> )}}
              />
              <Table.Column title="検査名" dataIndex="formal_name" showSorterTooltip={false} sorter={(a, b) => a.formal_name.localeCompare(b.formal_name, 'ja')} />
              <Table.Column title="表示順" width={150} dataIndex="display_order" showSorterTooltip={false} sorter={(a, b) => a.display_order - b.display_order} 
                render={(value, record) => {
                  let index = this.findIndexByID(this.formRef.current?.getFieldValue("dataSource"), record.id);
                  return <Form.Item name={['dataSource', index, 'display_order']}>
                    <InputNumber value={record.display_order} onChange={(e) => {record.display_order = e}}/>
                  </Form.Item>
                }}
              />
              <Table.Column
                align="center"
                title={() => (
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => this.handleAddNew()}
                  />
                )}
                width={100}
                key="action"
                render={(text, record) => (
                  <Space>
                    <Button
                      size="small"
                      style={{ border: "none" }}
                      icon={<SaveOutlined style={{ color: "green" }} />}
                      onClick={() => this.saveData(record)}
                    ></Button>

                    <Button
                      size="small"
                      style={{ border: "none" }}
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          content: "削除処理を実行しますか？",
                          okText: "はい",
                          cancelText: "いいえ",
                          onOk: () => this.deleteData(record),
                        });
                      }}
                    ></Button>
                  </Space>
                )}
              />
            </Table>
        </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1396009_SupportItemSub);
