import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Dropdown, Button, Menu, message } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ModalDraggable from 'components/Commons/ModalDraggable'
import InspectGroupDetail from 'pages/MS_InspectionMaintenance/InspectGroupMaintain/InspectGroupDetail.jsx'
import InspectGroupMaintainEdit from 'pages/MS_InspectionMaintenance/InspectGroupMaintain/InspectGroupMaintainEdit.jsx'
import InspectGroupMaintainAction from 'redux/InspectionMaintenance/InspectGroupMaintain/InspectGroupMaintain.actions.js'

class InspectGroupMaintain extends React.Component {

  constructor(props) {
    super(props)

    // document.title = '検査グループマスタ保守'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    InspectGroupMaintainAction.index()
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 詳細　モーダル
   * @param {*} record
   */
  showInspectGroupDetail(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component:
          <InspectGroupDetail
            record={record}
          />
      }
    })
  }

  /**
   * 新規・編集モーダル
   * @param {} record
   */
  showInspectGroupMaintainEdit(record = null) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <InspectGroupMaintainEdit
            record={record}
            onFinishScreen={async () => {
              await this.closeModal()
              await this.index()
            }}
          />
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='electronic-medical-records-linkage-frame-set'>
        <Card title='検査グループマスタ保守'>
          <Table
            bordered
            dataSource={this.state.dataSource}
            style={{ cursor: 'pointer' }}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.dataSource : false}
            scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
            rowKey={(record) => record.id}
            size='small'
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  // 詳細モーダルを開く
                  this.showInspectGroupDetail(record)
                }
              }
            }}
          >
            <Table.Column
              width={150}
              title='検査グループコード'
              dataIndex='inspect_group_code'
            />

            <Table.Column
              title='検査グループ名'
              dataIndex='inspect_group_name'
            />

            <Table.Column
              title='変換先コ―ド'
              width={150}
              dataIndex='exam_code'
              render={(value) => (
                <div style={{ textAlign: 'right' }}>{value}</div>
              )}
            />

            <Table.Column
              title='変換先検査名'
              width={300}
              dataIndex='exam_name'
            />

            <Table.Column
              width={40}
              title={
                //新規登録のボタン
                <Button
                  size='small'
                  type='primary'
                  style={{ textAlign: 'center' }}
                  icon={<PlusOutlined />}
                  onClick={() => { this.showInspectGroupMaintainEdit() }}
                >
                </Button>
              }
              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='詳細'
                          onClick={() => { this.showInspectGroupMaintainEdit(record) }}
                        >詳細
                        </Menu.Item>

                        <Menu.Item
                          key='編集'
                          onClick={() => { this.showInspectGroupMaintainEdit(record) }}
                        >編集
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                    ></Button>
                  </Dropdown>
                )
              }}
            />
          </Table>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectGroupMaintain)
