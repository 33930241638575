import axios from 'configs/axios';

const API_LIST = {
    displayList: '/api/create-test-for-medical-exam-info/create-test-for-medical-exam-info/display-list',
    autoCreate: '/api/create-test-for-medical-exam-info/create-test-for-medical-exam-info/auto-create',
};

const CreateTestForMedicalExamInfoService = {
  async displayList(params) {
    return axios.get(API_LIST.displayList, {params});
  },

  async autoCreate(params) {
    return axios.post(API_LIST.autoCreate, params);
  },
};

export default CreateTestForMedicalExamInfoService;