import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Row, Col, Input, Form, Spin, Card, Table, Button, Space, message } from 'antd'

import {
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons'
// import { debounce } from 'lodash'

import WS0343001_PersonalInfoMaintain from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain'

import moment from 'moment'
import axios from 'configs/axios'

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS2674011_InsuranceInfo extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_Date: PropTypes.any,
    Li_kanjiname: PropTypes.any,
    Li_insurerError: PropTypes.any,
    // show/hide card title
    showTitle: PropTypes.bool,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '保険情報'

    this.state = {
      visit_date_on: moment(new Date())?.format('YYYY/MM/DD'),
      personal_number_id: '',
      Li_KanjiName: '',
      ErrorContent: '',
      dataSource: [],
      isLoading: false,

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  componentDidMount = () => {

    this.formRef.current?.setFieldsValue({
      visit_date_on: this.props.Li_Date ? this.props.Li_Date : '',
      personal_number_id: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : '',
      Li_KanjiName: this.props.Li_kanjiname ? this.props.Li_kanjiname : '',
      Li_office_kanji_name: this.props.office_kanji_name ? this.props.office_kanji_name : '',
      ErrorContent: this.props.Li_insurerError ? this.props.Li_insurerError : '',
      // Li_PersonalNum: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : '',  
      // Li_CourseLevel: this.props.Li_PersonalNum ? this.props.Li_CourseLevel : '', 
      // Li_ReserveNum:  this.props.Li_PersonalNum ? this.props.Li_ReserveNum : '', 
      // Li_Date:        this.props.Li_PersonalNum ? this.props.Li_Date : '',   
    })
    // this.onSearchData()
  }

  componentWillUnmount = () => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen()
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props !== prevProps) {
  //     this.formRef.current?.setFieldsValue({
  //       visit_date_on: this.props.Li_Date ? this.props.Li_Date : '',
  //       personal_number_id: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : '',
  //       Li_KanjiName: this.props.Li_kanjiname ? this.props.Li_kanjiname : '',
  //     })
  //     this.onSearchData()
  //   }
  // }

  // axios.post('/api/contract-info-maintain/organization-create-new/CreateBtn',
  // onSearchData = () => {

  //   this.setState({ isLoading: true })
  //   axios.get('/api/associate-insure-qualify-confirm/insurance-info/getinsuranceinfo', {

  //     params: {
  //       Li_PersonalNum: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : '',
  //       Li_CourseLevel: this.props.Li_PersonalNum ? this.props.Li_CourseLevel : '',
  //       Li_ReserveNum: this.props.Li_PersonalNum ? this.props.Li_ReserveNum : '',
  //       Li_Date: this.props.Li_PersonalNum ? this.props.Li_Date : '',
  //     },

  //   })
  //     .then(res => {

  //       this.formRef.current?.setFieldsValue({

  //         visit_date_on: res ? res.data.Li_Date : '',
  //         personal_number_id: res ? res.data.Li_PersonalNum : '',
  //         Li_KanjiName: res ? res.data.kanji_name : '',
  //         ErrorContent: res ? res.data.ErrorContent : '',
  //         // Li_PersonalNum: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : '',  
  //         // Li_CourseLevel: this.props.Li_PersonalNum ? this.props.Li_CourseLevel : '', 
  //         // Li_ReserveNum:  this.props.Li_PersonalNum ? this.props.Li_ReserveNum : '', 
  //         // Li_Date:        this.props.Li_PersonalNum ? this.props.Li_Date : '',   
  //       })

  //     })
  //     .catch(error => {

  //       this.setState({ isLoading: false })

  //       const res = error.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }

  //       message.error(res.data.message)
  //     })
  // }
  // onFinish(values) {
  //   this.onSearchData()
  // }

  showPersonalReference = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (

          <WS0343001_PersonalInfoMaintain
            Li_PersonalNum={this.props.Li_PersonalNum}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='insurance-info'>
        <Row>
          <Col span={24}>
            <Card title='保険情報'>
              <Form
                ref={this.formRef}
                onFinish={this.onFinish}
              >
                <Row gutter={40} style={{ margin: 0 }}>
                  <Col span={24}>
                    <Space style={{ marginRight: '5px' }}>
                      <Form.Item
                        name=''
                        label='※保険情報に誤りがあります。修正または再提出してください。'
                      >
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>

                <Row gutter={50} style={{ margin: 0 }}>
                  <Col span={8}>
                    <Space style={{ marginRight: '5px' }}>
                      <Form.Item name='visit_date_on' label='受診日'{...smGrid} >
                        <Input type='text' readOnly style={{ border: 'none' }} />
                      </Form.Item>
                    </Space>
                  </Col>

                </Row>
                <Row gutter={50} style={{ margin: 0 }}>
                  <Col span={8}>
                    <Form.Item name='personal_number_id' label='個人番号' {...smGrid} >
                      <Input type='text' readOnly style={{ border: 'none' }} />
                    </Form.Item>
                  </Col>

                </Row>
                <Row gutter={50} style={{ margin: 0 }}>
                  <Col span={8}>
                    <Form.Item name='Li_KanjiName' label='漢字氏名' {...smGrid}>
                      <Input type='text' readOnly style={{ border: 'none' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30} style={{ margin: 0 }}>
                  <Col span={24}>
                    <Form.Item label='エラー内容' name='' >
                      <Input type='text' readOnly style={{ border: 'none' }} />
                    </Form.Item>
                    <Form.Item name='ErrorContent' style={{ border: '1px solid #5ba3f1' }}>
                      <Input.TextArea rows={3} readOnly style={{ border: 'none', background: 'transparent' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30} style={{ margin: 0 }}>
                  <Col span={18}></Col>
                  <Col span={6}>
                    <Space style={{ marginRight: '5px', Align: 'baseline' }}>
                      <Button
                        type='primary'
                        // htmlType='submit'
                        onClick={this.showPersonalReference}
                      >個人情報</Button>
                    </Space>
                  </Col>
                </Row>

              </Form>
            </Card>
          </Col>
        </Row>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2674011_InsuranceInfo)
