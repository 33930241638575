import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Button, Select, Table, Row, Col, message, Checkbox, Spin } from "antd";
import { download_file } from "helpers/CommonHelpers";
import ExportDataAction from "redux/AccountingBusiness/DepositWithdrawalInquiry/ExportData.action";

/**
 * 入出金問合せ画面
 * データ出力モーダル
 */
class WS0963001_ExportData extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    onFinishScreen: PropTypes.func,
    /**
     * 出力するデータの配列
     * @type {Array.<Object>}
     */
    exportData: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      /**
       * ローディング中か？
       * @type {boolean}
       */
      isLoading: false,
      /**
       * ファイル出力タイプ 0: txt, 1: html, 2:xml
       * @type {number}
       */
      type: 0,
      /**
       * ファイル名
       * @type {string}
       */
      fileName: '',
      /**
       * 保存ファイルにヘッダーを含めるか
       * txt, htmlの時に使用
       * @type {boolean}
       */
      hasHeader: false,
      /**
       * 保存ファイルにスキーマを含めるか
       * xmlの時に使用
       * @type {boolean}
       */
      hasSchema: false,
      /**
       * 表示するメニュー 0: 1ページ目, 1: 2ページ目
       * @type {number}
       */
      showMenuType: 0,
      /**
       * 出力するデータの配列のヘッダー
       * @type {Array.<Object>}
       */
      exportHeader: [
        { index: 1, name: 'W1_payment_day' },
        { index: 2, name: 'W1_receipt_issue_num' },
        { index: 3, name: 'W1_amount_received' },
        { index: 4, name: 'W1_change' },
        { index: 5, name: 'W1_transfer_fee' },
        { index: 6, name: 'W1_person_num' },
        { index: 7, name: 'W1_payment_comments' },
        { index: 8, name: 'visit_date_on' },
        { index: 9, name: 'visit_course' },
        { index: 10, name: 'office_kanji_name' },
        { index: 11, name: 'W1_billing_manage_num' },
      ],

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };

    /**
     * 拡張子定義
     * @enum {string}
     */
    this.extensions = {
      0: 'txt',
      1: 'html',
      2: 'xml',
    }
  }
  /**
   * init処理
   */
  componentDidMount() {
    // 出力データを整形
    this.props.exportData.forEach((data) => {
      // 日付の区切り文字を修正
      data.visit_date_on = data.visit_date_on.replace(/-/g, '/');
      data.W1_payment_day = data.W1_payment_day.replace(/-/g, '/');
    });
  }
  /**
   * 表示ページの変更
   * @param {number} val 
   */
  menuChange = (val) => {
    this.setState({ showMenuType: val });
  }
  /**
   * フォーム入力時のsetState処理
   * @param {string} e 入力値
   * @param {string} targetValueName 更新対象変数名
   */
  onChangeForm = (e, targetValueName) => {
    let change = {
      type: this.state.type,
      fileName: this.state.fileName,
      hasHeader: this.state.hasHeader,
      hasSchema: this.state.hasSchema,
      showMenuType: this.state.showMenuType,
    };
    change[targetValueName] = e;
    this.setState({
      type: change.type,
      fileName: change.fileName,
      hasHeader: change.hasHeader,
      hasSchema: change.hasSchema,
      showMenuType: change.showMenuType,
    });
  }
  /**
   * ヘッダーカラムの順序変更時の処理
   * @param {Object} record {name: string, index: number} 入力行のデータ
   * @param {string} val 入力値
   */
  onChangeColumnIndex = (record, val) => {
    val = Number(val);
    // 更新対象
    const targetHeaderIndex = this.state.exportHeader.findIndex((header) => {
      return header.name === record.name;
    });
    // 同じindexを持っているデータ
    const exchangeHeaderIndex = this.state.exportHeader.findIndex((header) => {
      return header.index === val;
    });
    const targetHeaderVal = this.state.exportHeader[targetHeaderIndex].index;
    if (exchangeHeaderIndex === -1) { // 不正な値
      // setState時何かしら値が更新されないと再描画されないため、一度不正な値にしてからもとに戻す
      this.state.exportHeader[targetHeaderIndex].index = val;
      this.setState({ exportHeader: this.state.exportHeader }, () => this.forceUpdate(() => {
        this.state.exportHeader[targetHeaderIndex].index = targetHeaderVal;
        this.setState({ exportHeader: this.state.exportHeader }, this.forceUpdate);
        message.error('不正なカラム番号です。');
      }));
    } else {
      this.state.exportHeader[exchangeHeaderIndex].index = targetHeaderVal;
      this.state.exportHeader[targetHeaderIndex].index = val;
      this.setState({ exportHeader: this.state.exportHeader }, this.forceUpdate);
    }
  }
  /**
   * 出力処理開始
   */
  export = async () => {
    // バリデーション
    if (this.state.fileName === '') {
      message.error('ファイル名を入力してください。');
      return;
    }
    /**
     * 出力用ヘッダー
     * ソート後名前だけを取り出す
     * @type {Array.<String>}
     */
    const exportHeader = (Array.from(this.state.exportHeader).sort((a, b) => a.index - b.index)).map((header) => {
      return header.name;
    });
    /**
     * 出力用データ
     * ヘッダーをもとに作成する出力用２重配列
     * @type {Array.<Array.<string>>}
     */
    // ヘッダーの順番にデータをソートして取得する
    const exportData = this.props.exportData.map((data) => {
      return exportHeader.map((header) => {
        return data[header];
      })
    });

    let params = {
      // 出力するヘッダーのデータ
      exportHeader: exportHeader,
      // 出力する行のデータ
      exportData: exportData,
      // ファイル名
      fileName: '',
      // ヘッダーあり
      hasHeader: this.state.hasHeader,
      // スキーマあり
      hasSchema: this.state.hasSchema,
    }
    let promise;

    switch (this.state.type) {
      case 0: // text
        params.fileName = `${this.state.fileName}.txt`;
        promise = ExportDataAction.ExportTxt(params);
        this.downloadFile(promise);
        break;
      case 1:// html
        params.fileName = `${this.state.fileName}.html`;
        promise = ExportDataAction.ExportHtml(params);
        this.downloadFile(promise, true, true);
        break;
      case 2: //xml
        params.fileName = `${this.state.fileName}.xml`;
        promise = ExportDataAction.ExportXml(params);
        this.downloadFile(promise, !this.state.hasSchema);
        if (this.state.hasSchema) { // スキーマ
          params.fileName = `${this.state.fileName}.xsd`;
          const xsdPromise = ExportDataAction.ExportXsd(params);
          this.downloadFile(xsdPromise);
        }
        break;
    }
  }
  /**
   * ファイル保存
   * @param {Promise} promise 
   * @param {boolean} [isClose] 成功時モーダルを閉じる
   * @param {boolean} [isHtmlDownload] htmlの場合にダウンロードするか？
   */
  downloadFile(promise, isClose = true, isHtmlDownload = false) {
    this.setState({ isLoading: true });
    promise.then(res => {
      download_file(res, false, isHtmlDownload);
      if (isClose) {
        // 成功時モーダルを閉じる
        this.props.onFinishScreen();
      }
    }).catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
      message.error(res.data.message);
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div className="invoice-maintain">
        <Card className="mb-2" title="データ出力">
          <Spin spinning={this.state.isLoading}>
            <Form ref={this.formRef} onFinish={this.onFinish}
              initialValues={{
                type: this.state.type,
                fileName: this.state.fileName,
                hasHeader: this.state.hasHeader,
                hasSchema: this.state.hasSchema,
                exportHeader: this.state.exportHeader,
              }}
            >
              <div className="box_container">

                {/* ページ１ 基本設定*/}
                {this.state.showMenuType === 0 &&
                  <>
                    <p>出力タイプを選択してください</p>
                    <Row gutter={24}>
                      <Col span={4}>
                        <label>タイプ</label>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="type">
                          <Select onChange={(e) => this.onChangeForm(e, "type")}>
                            <Select.Option value={0}>テキストファイル</Select.Option>
                            <Select.Option value={1}>HTMLファイル</Select.Option>
                            <Select.Option value={2}>XMLファイル</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={4}>
                        <label>ファイル名</label>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="fileName">
                          <Input onChange={(e) => this.onChangeForm(e.target.value, "fileName")} />
                        </Form.Item>
                      </Col>
                    </Row>

                    {this.state.type !== 2 &&
                      <Row gutter={24}>
                        <Col span={20}>
                          <Form.Item name="hasHeader" label="ヘッダ行の出力" valuePropName="checked">
                            <Checkbox value={this.state.hasHeader} onChange={(e) => this.onChangeForm(e, "hasHeader")} />
                          </Form.Item>
                        </Col>
                      </Row>
                    }

                    {this.state.type === 2 &&
                      <Row gutter={24}>
                        <Col span={20}>
                          <Form.Item name="hasSchema" label="スキーマファイルの作成" valuePropName="checked">
                            <Checkbox value={this.state.hasSchema} onChange={(e) => this.onChangeForm(e, "hasSchema")} />
                          </Form.Item>
                        </Col>
                      </Row>
                    }
                  </>
                }

                {/* ページ２ カラム並べ替え*/}
                {this.state.showMenuType === 1 &&
                  <>
                    <p>カラムを選択</p>
                    <Table bordered
                      size='small'
                      dataSource={this.state.exportHeader}
                      rowKey={(header) => header.name}
                      pagination={{ defaultPageSize: 11, hideOnSinglePage: true, }}
                    >
                      <Table.Column // index
                        title="#"
                        key="i"
                        render={(value, item, i) => i + 1}
                      />
                      <Table.Column title="名前" dataIndex="name" />
                      <Table.Column title="順序" width={120} align='center'
                        render={(value, record) => (
                          <Input type="number" key={record.index} defaultValue={record.index}
                            onBlur={(e) => this.onChangeColumnIndex(record, e.target.value)}
                          />
                        )}
                      />
                    </Table>
                  </>
                }

                {/* ページ選択 */}
                <Row gutter={24}>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button onClick={() => { this.menuChange(0) }} disabled={this.state.showMenuType === 0}>
                      戻る
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button onClick={() => { this.menuChange(1) }} disabled={this.state.showMenuType === 1} >
                      次へ
                    </Button>
                  </Col>
                </Row>

                {/* 保存ボタン */}
                <Form.Item>
                  <Button style={{ float: "right" }} type="primary" htmlType="submit" onClick={this.export}>
                    保存
                  </Button>
                </Form.Item>
              </div>
            </Form >
          </Spin>
        </Card >

        <ModalDraggable
          footer={null}
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            if (this.state.isClose974) {
              this.getInitData()
              this.setState({ isClose974: false })
            }
            this.closeModal();
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0963001_ExportData);
