///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Checkbox, Button, Row, Col, Space } from 'antd'
import warning from 'assets/img/warning.png'
class WS0061003_ConfirmCheckYesYesNo extends React.Component {
  static propTypes = {
    Li_Title: PropTypes.any,
    Li_Message: PropTypes.any,
    Lio_StsReturn: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = 'カテゴリ前回DO'

    this.state = {
      StsConfirm: false
    }
  }

  /**
   * 初期表示処理
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ StsConfirm: false })
    }
  }

  /**
   * ボタン活性・日活性
   * @param {*} input
   * @returns
   */
  disabled = (input) => {
    return !input ? true : false
  }

  render() {
    return (
      <div className='confirm-check-yes-yes-no'>
        <Card
          title={this.props.Li_Title ? this.props.Li_Title : '確認[チェック有]はい・いいえ'}
        >
          <div className='box_container'>
            <div
              style={{
                width: '100%', textAlign: 'center',
                fontSize: 14, fontWeight: 'bold'
              }}>
              確認事項
            </div>
            <div className='box_inner_horizontal'>
              <img src={warning} />
              <div style={{ width: 'calc(100% - 80px)' }}>
                {this.props.Li_Message}
              </div>
            </div>
            <div style={{ paddingLeft: '15px' }}>
              <Checkbox
                checked={this.state.StsConfirm}
                onChange={(e) => this.setState({ StsConfirm: e.target.checked })}>
                内容を確認しました
              </Checkbox>
            </div>
            <div
              className='box_button_bottom_right'
              style={{ paddingTop: 0 }}>
              <Button
                type='primary'
                disabled={this.disabled(this.state.StsConfirm)}
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      Lio_StsReturn: true
                    })
                  }
                }}
              >
                <span className='btn_label'>
                  はい
                </span>
              </Button>
              <Button
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      Lio_StsReturn: false,
                    })
                  }
                }}
              >
                <span className='btn_label'>
                  いいえ
                </span>
              </Button>
            </div>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0061003_ConfirmCheckYesYesNo)
