import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Input, Button, message, Space } from "antd";

import ModalDraggable from "components/Commons/ModalDraggable";

import WS1226012_MedicalExamResultCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226012_MedicalExamResultCorrect";
import WS1226009_MediaManage from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226009_MediaManage";


class WS1226007_CorrectSelect extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '訂正選択';

    this.state = {
      isLoading: false,
      dataSource: [],
      selectedRow: {},
      selectedRowKey: "",
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },

      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
    };
  }

  componentDidMount = () => {
    this.getScreenData();
  }

  getScreenData = () => {
    this.setState({ isLoading: true });
    const data = JSON.parse(window.localStorage.getItem("WS1226_RESULT"));
    const items = data.filter(val => val.length > 0).map((val, index) => (
      {
        seq: index + 1,
        chkup_institution_cd: val[0],
        chkup_sect: val[1],
        _12_sect: val[2],
        full_name: val[3],
        person_spouse: val[4],
        birthday: val[5],
        sex: val[6],
        insurer_num: val[7],
        insurer_card_symbol_symbol: val[8],
        insurer_card_symbol_sign: val[9],
        insurer_card_num: val[10],
      }
    ))
    this.setState({
      dataSource: items,
      isLoading: false,
      selectedRowKey: items.length > 0 ? items[0].seq : "",
      selectedRow: items.length > 0 ? items[0] : {},
    });
  }

  resultProc = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "100%",
        component: (
          <WS1226012_MedicalExamResultCorrect
            seq={record.seq}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                  className: "",
                },
              });
            }}
          />
        ),
      },
    });
  }

  mediaManage = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "20%",
        component: (
          <WS1226009_MediaManage
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                  className: "",
                },
              });
            }}
          />
        ),
      },
    });
  }

  render() {
    const { dataSource, isLoading, pagination, selectedRowKey, selectedRow } = this.state;
    return (
      <Card title="訂正選択">
        <Table bordered
          loading={isLoading}
          rowKey={(record) => record.seq}
          onRow={(record) => ({
            onClick: () => this.setState({ selectedRow: record, selectedRowKey: record.seq }),
          })}
          dataSource={dataSource}
          pagination={pagination}
          rowSelection={{
            type: "radio",
            selectedRowKeys: [selectedRowKey],
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              this.setState({ selectedRow: selectedRows[0], selectedRowKey: selectedRowKeys })
            },
          }}
        >
          <Table.Column title="SEQ" dataIndex="seq" showSorterTooltip={false} sorter={(a,b) => a.seq - b.seq} />
          <Table.Column title="健診機関" dataIndex="chkup_institution_cd" showSorterTooltip={false} sorter={(a,b) => a.chkup_institution_cd.localeCompare(b.chkup_institution_cd)} />
          <Table.Column title="健診区分" dataIndex="chkup_sect" showSorterTooltip={false} sorter={(a,b) => a.chkup_sect.localeCompare(b.chkup_sect)} />
          <Table.Column title="区分" dataIndex="_12_sect" showSorterTooltip={false} sorter={(a,b) => a._12_sect.localeCompare(b._12_sect)} />
          <Table.Column title="氏名" dataIndex="full_name" showSorterTooltip={false} sorter={(a,b) => a.full_name.localeCompare(b.full_name)} />
          <Table.Column title="本人・配偶者" dataIndex="person_spouse" showSorterTooltip={false} sorter={(a,b) => a.person_spouse.localeCompare(b.person_spouse)} />
          <Table.Column title="生年月日" dataIndex="birthday" showSorterTooltip={false} sorter={(a,b) => a.birthday.localeCompare(b.birthday)} />
          <Table.Column title="性別" dataIndex="sex" showSorterTooltip={false} sorter={(a,b) => a.sex.localeCompare(b.sex)} />
          <Table.Column title="保険者番号" dataIndex="insurer_num" showSorterTooltip={false} sorter={(a,b) => a.insurer_num.localeCompare(b.insurer_num)} />
          <Table.Column title="保険記号[記号]" dataIndex="insurer_card_symbol_symbol" showSorterTooltip={false} sorter={(a,b) => a.insurer_card_symbol_symbol.localeCompare(b.insurer_card_symbol_symbol)} />
          <Table.Column title="保険記号[符号]" dataIndex="insurer_card_symbol_sign" showSorterTooltip={false} sorter={(a,b) => a.insurer_card_symbol_sign.localeCompare(b.insurer_card_symbol_sign)} />
          <Table.Column title="健保番号" dataIndex="insurer_card_num" showSorterTooltip={false} sorter={(a,b) => a.insurer_card_num.localeCompare(b.insurer_card_num)} />
        </Table>
        <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" className="mx-2" onClick={() => this.resultProc(selectedRow)}>健診結果</Button>
          <Button type="primary" className="mx-2" onClick={() => this.mediaManage()}>媒体管理</Button>
        </div>
        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0, backgroundColor: "#1890ff" }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
                component: null,
              },
            });
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1226007_CorrectSelect);
