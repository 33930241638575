import axios from 'configs/axios'

const APP_LIST = {
  getAutoJudgmentNameList: '/api/auto-judgment-management/auto-judgment-management/get-auto-judgment-name-list',
  getJudgmentStorageData: '/api/auto-judgment-management/auto-judgment-management/get-judgment-storage-data',
  addNewJudgmentStorageSetting: '/api/auto-judgment-management/auto-judgment-management/add-new-judgment-storage-setting',
  saveJudgmentStorageSetting: '/api/auto-judgment-management/auto-judgment-management/save-judgment-storage-setting',
  deleteJudgmentStorageSetting: '/api/auto-judgment-management/auto-judgment-management/delete-judgment-storage-setting',

  getAutoJudgmentNameData: '/api/auto-judgment-management/auto-judgment-name-setting/get-auto-judgment-name-data',
  getScreenData: '/api/auto-judgment-management/auto-judgment-name-setting/get-screen-data',
  addNewJudgmentName: '/api/auto-judgment-management/auto-judgment-name-setting/add-new-judgment-name',
  saveJudgmentName: '/api/auto-judgment-management/auto-judgment-name-setting/save-judgment-name',
  deleteAutoJudgmentName: '/api/auto-judgment-management/auto-judgment-name-setting/delete-auto-judgment-name',

  getJudgmentDetailSettingData: '/api/auto-judgment-management/judgment-detail-setting/get-judgment-detail-setting-data',
  saveJudgmentDetailSetting: '/api/auto-judgment-management/judgment-detail-setting/save-judgment-detail-setting'
}

const AutoJudgmentManagementService = {
  async getAutoJudgmentNameList(params) {
    return axios.get(APP_LIST.getAutoJudgmentNameList, { params })
  },
  async getJudgmentStorageData(params) {
    return axios.get(APP_LIST.getJudgmentStorageData, { params })
  },
  async addNewJudgmentStorageSetting(params) {
    return axios.post(APP_LIST.addNewJudgmentStorageSetting, params)
  },
  async saveJudgmentStorageSetting(params) {
    return axios.post(APP_LIST.saveJudgmentStorageSetting, params)
  },
  async deleteJudgmentStorageSetting(params) {
    return axios.post(APP_LIST.deleteJudgmentStorageSetting, params)
  },

  //自動判定名設定関係
  async getAutoJudgmentNameData(params) {
    return axios.get(APP_LIST.getAutoJudgmentNameData, { params })
  },
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params })
  },
  async addNewJudgmentName(params) {
    return axios.post(APP_LIST.addNewJudgmentName, params)
  },
  async saveJudgmentName(params) {
    return axios.post(APP_LIST.saveJudgmentName, params)
  },
  async deleteAutoJudgmentName(params) {
    return axios.post(APP_LIST.deleteAutoJudgmentName, params)
  },

  //自動判定詳細設定
  async getJudgmentDetailSettingData(params) {
    return axios.get(APP_LIST.getJudgmentDetailSettingData, { params })
  },
  async saveJudgmentDetailSetting(params) {
    return axios.post(APP_LIST.saveJudgmentDetailSetting, params)
  },
}

export default AutoJudgmentManagementService
