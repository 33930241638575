import React from "react";
import { connect } from "react-redux";
import { Button, Card, Form, Input, Select, Modal } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

//Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

class WS1398002_InsureGuideCourseEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      // 親画面のrecordをコピー
      const data = {
        ...this.props.record,
      }

      //formの設定
      this.formRef.current?.setFieldsValue(data)
    }
  }

  // 新規・編集
  save() {
    if (this.props.newFlag) {
      // 新規
      this.props.onFinishScreen({
        itemChange: this.formRef.current.getFieldsValue()
      })
    } else {
      // 編集
      this.props.onUpdate({
        id: this.props.record.id,
        ...this.formRef.current.getFieldsValue()
      })
    }
  }

  // 削除
  delete() {
    this.props.onDelete({
      id: this.props.record.id,
      ...this.formRef.current.getFieldsValue()
    })
  }

  render() {
    return (
      <div className="Insure-Guide-Course-Edit">
        <Card title={'保健指導コース [' + (this.props.newFlag ? '新規' : '変更') + ']'} stye={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            autoComplete="off"
          >
            <div className="box_container">
              <Form.Item
                label='コースコード'
                name='insure_guide_course_code'
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='略称名'
                name='short_name'
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='コース名称'
                name='course_name'
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='保健指導区分'
                name='insure_guide_division_value'
                {...labelCol}
              >
                <Select>
                  <Select.Option value="1">動機付支援</Select.Option>
                  <Select.Option value="2">積極的支援</Select.Option>
                  <Select.Option value="3">動機付相当</Select.Option>
                  <Select.Option value="4">モデル実施</Select.Option>
                </Select>
              </Form.Item>

              {this.props.newFlag ?
                null
                :
                <div className="box_container">
                  <Form.Item
                    label='支援A'
                    style={{ marginBottom: 0 }}
                    {...labelCol}
                  >
                    <div style={{ marginTop: -2 }}>{this.props.record.SupportATotalPoints}</div>
                  </Form.Item>

                  <Form.Item
                    label='支援B'
                    style={{ marginBottom: 0 }}
                    {...labelCol}
                  >
                    <div style={{ marginTop: -2 }}>{this.props.record.SupportBTotalPoints}</div>
                  </Form.Item>

                  <Form.Item
                    label='合計'
                    style={{ marginBottom: 0 }}
                    {...labelCol}
                  >
                    <div style={{ marginTop: -2 }}>{this.props.record.SupportBTotalPoints + this.props.record.SupportATotalPoints}</div>
                  </Form.Item>
                </div>
              }

            </div>

            <div className="box_button_bottom_right">
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1398002_InsureGuideCourseEdit);
