import { message } from 'antd'
import DiseaseNameMasterMaintainService from 'services/InspectionMaintenance/DiseaseNameMaster/DiseaseNameMasterMaintain/DiseaseNameMasterMaintainService'

const DiseaseNameMasterMaintainAction = {
  index() {
    return DiseaseNameMasterMaintainService.index()
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(data) {
    return DiseaseNameMasterMaintainService.save(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}

export default DiseaseNameMasterMaintainAction
