import React from "react";
import { connect } from "react-redux";
import ComprehensiveMenuMaintenanceSubAction from 'redux/MN_Basis/ComprehensiveMenuMaintenance/ComprehensiveMenuMaintenanceSub.action';
import { getMenuList } from "redux/user/user.actions";
import { SaveOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Form, Table, Button, Input, message, Row, Col, Checkbox, Avatar, Modal, List, InfiniteScroll } from "antd";
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'

const smGrid = {
  labelCol: { style: { width: 90 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 90px)' } }
}
const style = {
  textAlign: 'center',
  marginBottom: '20px'
}
const data = [
  { id: 1, specimen: '生化学' },
  { id: 2, specimen: '血糖' },
  { id: 3, specimen: 'HbA1c' },
]

class AuthenticationCheck extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      staffName: '',
      examineeName: '',
      listData: [],
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    console.log('3点認証チェック');
  }

  staffCodeChange() {
    this.setState({
      staffName: '健診　太郎'
    })
  }

  examineeIDChange() {
    this.setState({
      examineeName: '山田　花子'
    })
  }

  specimenCodeChange() {
    let copyData = [...this.state.listData]
    for (let i = 0; i < data.length; i++) {
      if (copyData[i]?.id !== data[i]?.id) {
        copyData.push(data[i])
        this.setState({
          listData: copyData
        })
        break
      }
    }
  }

  render() {
    return (
      <div className='comprehensive-menu-maintenance' >
        <Card title='3点認証チェック' style={{ width: '500px' }}>
          <Form ref={this.formRef} autoComplete="off" >
            <div className="box_inner_horizontal">
              <Form.Item
                name="staffCode"
                label="職員コード"
                // style={{ margin: 'auto' }}
                {...smGrid}
              >
                <Input
                  className="input-size-number-10"
                  id={'authenticationCheck-' + 1 + '-' + 10}
                  onKeyDown={(e) => moveFocusKeyEvent(e, 'authenticationCheck', 1, false, 0)}
                  onPressEnter={(e) => this.staffCodeChange()}

                />
              </Form.Item>
              <div style={{ marginTop: '5px' }}>{this.state.staffName}</div>
            </div>
            <div className="box_inner_horizontal" style={{ marginTop: '10px' }}>
              <Form.Item
                name="examineeID"
                label="受診者ID"
                // style={{ marginLeft: '35%' }}
                {...smGrid}
              >
                <Input
                  className="input-size-number-10"
                  id={'authenticationCheck-' + 2 + '-' + 20}
                  onKeyDown={(e) => moveFocusKeyEvent(e, 'authenticationCheck', 1, false, 0)}
                  onPressEnter={(e) => this.examineeIDChange()}
                />
              </Form.Item>
              <div style={{ marginTop: '5px' }}>{this.state.examineeName}</div>
            </div>
            <Form.Item
              name="specimenCode"
              label="検体コード"
              style={{ marginTop: '10px' }}
              {...smGrid}
            >
              <Input
                className="input-size-number-10"
                id={'authenticationCheck-' + 3 + '-' + 30}
                onKeyDown={(e) => moveFocusKeyEvent(e, 'authenticationCheck', 1, false, 0)}
                onPressEnter={() => this.specimenCodeChange()}
              />
            </Form.Item>
            <List
              dataSource={this.state.listData}
              className='scrollbar'
              style={{ height: '400px', overflowY: 'auto', margin: 'auto', border: '1px solid #cccccc' }}
              renderItem={item => (
                <List.Item
                  key={item.id}
                  style={{ paddingLeft: '8px' }}
                >
                  {item.specimen}
                </List.Item>
              )}
            />
            <div className="box_button_bottom_right">
              <Button type="primary" icon={<SaveOutlined />}>登録</Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getMenuList: () => dispatch(getMenuList())
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationCheck);
