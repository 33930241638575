import axios from 'configs/axios';

const apiPaths = {
  getScreenData: "/api/insure-guide-init-input/health-checkup-info-select",
  inspectContentQuery: "/api/insure-guide-init-input/health-checkup-info-select/inspect-content-query",
  select: "/api/insure-guide-init-input/health-checkup-info-select/select",
}

const HealthCheckupInfoSelectService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
  async inspectContentQuery(params) {
    return axios.get(apiPaths.inspectContentQuery, { params })
  },
  async select(params) {
    return axios.post(apiPaths.select, params)
  },
}

export default HealthCheckupInfoSelectService;