import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CategoryListSettingSubAction from 'redux/InputBusiness/NotInputCheckCategory/CategoryListSettingSub.action'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx';
import { Card, Table, Input, Form, Modal, Button, Footer, Dropdown, Menu } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '50px' } }
}

class WS0103002_CategoryListSettingEdit extends React.Component {
  formRef = React.createRef();
  static propTypes = {
  };

  constructor(props) {
    super(props);

    // document.title = 'カテゴリ一覧設定SUB';

    this.state = {
      dataSource: [],
      isLoading: false,
      selectedRows: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      count: 1000
    };
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      this.formRef.current.setFieldsValue({
        codeName: this.props.record.category_name,
        Code: this.props.record.CategoryCode
      })
    }
  }

  /**
   * データ保存
   * @param {*} values
   */
  onFinish = (values) => {
    const params = {
      selectCode: values.Code,
      selectCodeName: values.codeName,
      categoryCode: this.props.categoryCode,
      patternCode: this.props.patternCode,
      multipleCategories: this.props.multipleCategories,
      node_id: this.props.node_id,
      id: this.props.record.id,
      targetId: this.props.targetId,
      targetCategoryCode: this.props.targetCategoryCode
    }

    if (this.props.newFlag) {
      CategoryListSettingSubAction.setCategoryData(params)
        .then((res) => {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        })

    } else {
      CategoryListSettingSubAction.setChangeCategoryData(params)
        .then((res) => {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        })
    }

  }

  /**
   * データ削除
   */
  delete = (values) => {
    const params = {
      id: this.props.targetId,
      CategoryCode: this.props.targetCategoryCode
    }

    CategoryListSettingSubAction.deleteCategoryData(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    const data = this.formRef.current?.getFieldValue('tableData') ?
      this.formRef.current?.getFieldValue('tableData') : []
    return (
      <div className="category-list-setting-sub">
        <Card title={"カテゴリ一覧設定 [" + (this.props.newFlag ? '新規' : '変更') + ']'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                name="Code"
                label="コード"
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-5'
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 550,
                        component: (
                          <WS0267001_CategorySearchQuerySingle
                            Lio_CategoryCode={this.formRef.current?.getFieldValue('Code')}
                            Li_UnusedInspectDisplay={false}
                            onFinishScreen={(output) => {
                              this.formRef.current.setFieldsValue({
                                codeName: output.Lio_CategoryName,
                                Code: output.Lio_CategoryCode
                              })
                              this.closeModal()
                              // let data = {
                              //   id: s.id,
                              //   CategoryCode: s.Lio_CategoryCode,
                              //   category_name: s.recordData.category_name
                              // }
                              // let tableData = this.formRef.current?.getFieldValue('tableData') ?
                              //   this.formRef.current?.getFieldValue('tableData') : [];
                              // tableData[rowIndex] = data
                              // this.setFormFieldValue('tableData', tableData)
                              // this.saveListData()
                              // this.closeModal()
                              // console.log(rowIndex)
                            }}
                          />
                        ),
                      }
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                label='名称'
                name='codeName'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  readOnly
                  bordered={false} />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0103002_CategoryListSettingEdit);
