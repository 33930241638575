import axios from 'configs/axios'

const API_LIST = {
  index: '/api/east-fukyo-converse-process/east-fukyo-converse-process',
  getExamineeList: '/api/east-fukyo-converse-process/east-fukyo-converse-process/get-examinee-list',
}

const EastFukyoConverseProcessService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getExamineeList(params) {
    return axios.get(API_LIST.getExamineeList, { params })
  },
}

export default EastFukyoConverseProcessService
