import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/designated-inspect-visits-history',
}

const DesignatedInspectVisitsHistoryService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
}

export default DesignatedInspectVisitsHistoryService
