export const UserActionTypes = {
  SET_USER: "SET_USER",
  LOG_OUT_USER: "LOG_OUT_USER",
  SET_HOSPITAL_CODE: "SET_HOSPITAL_CODE",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  SET_SUB_MENUS: 'SET_SUB_MENUS',
  SET_DISPLAY_COMPONENT: 'SET_DISPLAY_COMPONENT',
  GET_MENU_LIST: 'GET_MENU_LIST',
  GET_MY_MENU_LIST: 'GET_MY_MENU_LIST',
};
