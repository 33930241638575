import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import axios from "configs/axios";
import { Card, Checkbox, Form, Popconfirm, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Color from "constants/Color";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1391001_ImplementAgencyInquiry from "./WS1391001_ImplementAgencyInquiry";
// import WS1406001_ImplementorMasterAdd from "./WS1406001_ImplementorMasterAdd"
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import ImplementorMasterAction from "redux/SpecificInsureMaintenance/ImplementorMaster/ImplementorMaster.action";


const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const columns = [
  {
    title: '実施者コード',
    dataIndex: 'practitioner_code',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },

  {
    title: 'カナ氏名',
    dataIndex: 'kana_name',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '漢字氏名',
    dataIndex: 'kanji_name',
    // classNumber: '40',
    type: 1,
  },

  {
    title: '資格',
    dataIndex: 'qualification',
    classNumber: '40',
    type: 2,
    selectList: [{
      value: 1,
      label: '医師'
    },
    {
      value: 2,
      label: '保健師'
    },
    {
      value: 3,
      label: '管理栄養士'
    },
    {
      value: 4,
      label: '看護師'
    },
    {
      value: 5,
      label: '専門知識のある者'
    }],
  },

  {
    title: '有効無効',
    dataIndex: 'enabled_disabled',
    classNumber: '6',
    type: 2,
    selectList: [{
      value: 1,
      label: '有効'
    },
    {
      value: 9,
      label: '無効'
    }],
  },

  {
    title: '実施機関',
    dataIndex: 'implementing_agency_code',
    classNumber: '60',
    type: 4,
    childModal: {
      width: '60%',
      component: WS1391001_ImplementAgencyInquiry
    },
  },

  {
    title: 'オプション',
    dataIndex: 'option_remark',
    classNumber: '60',
    type: 1,
  },

]

/** Program [1406] ImplementorMaster V4-TMS02110:実施者マスタ Prg_1391.xml */
class WS1406001_ImplementorMaster extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // document.title = "実施者マスタ";
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      listID: [],
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      initObj: {},
    }; this.onFinish = this.onFinish.bind(this);
  }


  componentDidMount() {
    const params = {};
    this.loadMainSource(params);
  }

  loadMainSource = (params) => {
    this.setState({ isLoading: true });
    ImplementorMasterAction.GetTreeData({ params })
      .then(res => {
        this.setState({ dataSource: res.MainSource });
        this.formRef.current.setFieldsValue({
          MainSource: res.MainSource,
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API001406001002", params)
      .then((res) => {
        this.loadMainSource(params);

      })
      .catch(err => { })
      .finally(() => this.setState({ isLoading: false }))
  }

  onFinish(values) {
    const params = values;
    console.log(params)
    this.callAPILoadData(params)
  }


  //insert 新規作成
  Insert = (values) => {
    this.setState({ isLoading: true });
    ImplementorMasterAction.AddNewMaster(values)
      .then(res => {
        console.log(res)
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }


  //record　更新
  Save = (newValues) => {
    this.setState({ isLoading: true });
    ImplementorMasterAction.postMaster({ ...newValues })
      .then(res => {
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  Delete(values) {
    this.setState({ isLoading: true })
    ImplementorMasterAction.deleteMasterInfo({ id: Number(values.id) })
      .then(res => {
        this.loadMainSource({});
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  renderRightClick = (record) => {
    const {
      delete_flag,
      integrated_destination_manageme,
      integrated_order,
      invoice_number,
      billing_management_number,
      identification,
      W1_billing_manage_num,
      W1_identify
    } = record;
    // IF(delete_flag=0,IF(invoice_number=0,'削除','欠損'),IF(integrated_order=0,'復元',''))
    let eventName3 =
      delete_flag === 0
        ? invoice_number === 0 ? '削除' : '欠損'
        : integrated_order === 0 ? '復元' : '';
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            this.callModal({ Li_BillingManageNum: '' }, '70%', 'WS0963001_InvoiceMaintain')
          }}
        >新規</Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            // NOT( identification=1 OR billing_management_number=2 OR billing_management_number=3)
            !(identification === 1 || billing_management_number === 2 || billing_management_number === 3)
              ? this.callModal({ Li_BillingManageNum: billing_management_number }, '70%', 'WS0963001_InvoiceMaintain')
              : this.callModal({ Li_BillingManageNum: billing_management_number }, '70%', 'WS0952001_CounterPayment')
          }}
        >修正</Menu.Item>
        <Menu.Item
          key="3"
          hidden={!eventName3}
          onClick={() => {
            const params = {
              invoice_number,
              W1_identify,
              W1_billing_manage_num,
              delete_flag: eventName3 === '復元' || eventName3 === ''
                ? 0
                : eventName3 === '削除' ? 1 : 2
            }
            this.MissingOrDeleted(params)
          }}
        >{eventName3}</Menu.Item>
        {/* IF(delete_flag=0 AND integrated_destination_manageme>0 AND integrated_order=0,'統合解除','') */}
        <Menu.Item
          key="4"
          hidden={!(delete_flag === 0 && integrated_destination_manageme > 0 && integrated_order === 0)}
          onClick={() => {
            this.callModal({ Li_Identify: W1_identify, Li_BillingManageNum: W1_billing_manage_num }, '40%', 'WS2626028_IntegrationReleased')
          }}
        >統合解除</Menu.Item>
      </Menu >
    )
  }

  render() {
    return (
      <div className='invoice'>
        <Card className="mb-2" title='実施者マスタ' >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              Outstanding: 2,
              Target: 0,
              ClaimIdentify: 0,
              ClaimYearsChar: moment()

            }}>
            <Table
              bordered
              size='small'
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={true}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => ({
                //ダブルクリックイベント
                onDoubleClick: () => {
                  ImplementorMasterAction.GetTreeData(record.id)
                    .then(res => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            //ポップアップ表示
                            <ModalChange
                              columns={columns}
                              record={res.MainSource[0]}
                              onUpdate={(output) => {
                                this.Save(output)
                                this.closeModal()
                              }}
                              onDelete={(output) => {
                                this.Delete(record);
                                this.closeModal()
                              }}
                            />
                        },
                      })
                    })
                    .catch(error => {
                      console.log(error);

                      const res = error.response;
                      if (!res || !res.data || !res.data.message) {
                        message.error('エラーが発生しました');
                        return;
                      }

                      message.error(res.data.message);
                    })
                }
              })}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
            >
              {/* 実施期間は機関名を設定する */}
              {/* データ表示 */}
              {columns.map((item, index) => (
                item.type === 1 || item.type === 4 ?
                  <Table.Column
                    key={'input' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign ?? ''}
                  // onDoubleClick={() => {
                  //   // 編集、削除
                  //   this.setState({
                  //     childModal: {
                  //       ...this.state.childModal,
                  //       visible: true,
                  //       width: '550px',
                  //       className: '',
                  //       component:
                  //         //ポップアップ表示
                  //         <ModalChange
                  //           columns={columns}
                  //           record={this.state.dataSource[index]}
                  //           onUpdate={(output) => {
                  //             this.closeModal()
                  //             console.log("内部更新")
                  //           }}
                  //           onDelete={(output) => {
                  //             this.closeModal()
                  //             console.log("内部削除")
                  //           }}
                  //         />
                  //     },
                  //   });
                  // }}
                  /> : item.type === 2 ?
                    /*実施者コード～実施者機関までのタイトル行表示*/
                    <Table.Column
                      key={'select' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      className={'column-size-' + item.classNumber}
                      align={item.textAlign}
                      render={(value, record) => {
                        // findを使ってvalueを元に検索する
                        let findItem = item.selectList.find(selected => selected.value === value);
                        // labelを指定してreturn
                        return findItem.label
                      }}
                    /> : null
              ))}
              {/*タイトルの行＋ボタン*/}
              <Table.Column
                fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {
                                if (!output.itemChange.practitioner_code) {
                                  message.error('実施者コードが入力されていません');
                                }

                                if (!output.itemChange.qualification) {
                                  message.error('資格が入力されていません');
                                }

                                if (!output.itemChange.enabled_disabled) {
                                  message.error('有効無効が入力されていません');
                                }

                                if (output.itemChange.practitioner_code &&
                                  output.itemChange.qualification &&
                                  output.itemChange.enabled_disabled) {
                                  this.Insert(output.itemChange)
                                  this.closeModal()
                                }
                              }}
                            />
                        },
                      });
                    }} />} dataIndex="" align="center" width={30} render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => {
                          ImplementorMasterAction.GetTreeData(record.id)
                            .then(res => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  className: '',
                                  component:
                                    //ポップアップ表示
                                    <ModalChange
                                      columns={columns}
                                      record={res.MainSource[0]}
                                      onUpdate={(output) => {
                                        this.Save(output)
                                        this.closeModal()
                                      }}
                                      onDelete={(output) => {
                                        this.Delete(record);
                                        this.closeModal()
                                      }}
                                    />
                                },
                              })
                            })
                        }}
                      />

                    )} />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>

    );
  }

}
//消すとなにも表示されなくなる
const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const { Search } = Input;

const onSearch = value => console.log(value);

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WS1406001_ImplementorMaster)
