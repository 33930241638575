import React from 'react'
import { connect } from 'react-redux'

import { Card, Table, Modal, Form, Input, Button, Menu, Dropdown, Switch, Row, Col, Space, message } from 'antd'
import { MoreOutlined, PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'

import ModalDraggable from 'components/Commons/ModalDraggable'

import WS0449009_InputOutput from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449009_InputOutput.jsx'
import WS0449005_CopyingProcess from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449005_CopyingProcess.jsx'
import WS0449007_ConvertTblReplicationProcess from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449007_ConvertTblReplicationProcess.jsx'
import UserParamMaintainAction from 'redux/SystemMaintenance/UserParamMaintain/UserParamMaintain.actions'
import VenusApiRoutesV2 from 'redux/VenusApiRoutesV2/VenusApiRoutesV2'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS0450004_UserParamInput from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0450004_UserParamInput'
import WS0452005_ConvertTblSubAll from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0452005_ConvertTblSubAll.jsx'
import UserParamMaintainAddNew from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/UserParamMaintainAddNew.jsx'
import { generateUUID } from 'components/Commons/generateUUID'


class WS0449001_UserParamMaintain extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'ユーザーパラメータ保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        centered: true
      },

      disabledSaveBtn: true,
      addCount: 0,
      arrCheck: [],
      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],
      oldData: [],
      deleteData: [],
      isSwitchOn: false,
    }
  }

  componentDidMount() {
    this.getInit()
  }



  getInit() {
    UserParamMaintainAction.getInit()
      .then(res => {

        let tmp = res.map(item => ({ ...item, changed: false }))

        this.setState({
          oldData: res ? res : [],
          dataSource: res ? tmp : [],
          addCount: 0,
        })
        // this.formRef.current?.setFieldsValue({
        //   tableData: res ? res : []
        // })
      })
  }


  /**
  * idから行番号を取得
  * @param {*} arrayData
  * @param {*} recordID
  * @returns
  */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }


  delete = (record) => {
    Modal.confirm({
      content: '削除してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        // let params = {
        //   format: record.format
        // }
        // console.log(params)
        UserParamMaintainAction.delete2(record)
          .then(res => {
            this.getInit()
            this.forceUpdate()
          }).catch(err => {
            console.log(err)
            message.error(err.message)
          })
      }
    })
  }



  /**
   * 保存ボタンが押された場合
   */
  save(record) {
    UserParamMaintainAction.save2(record)
      .then(res => {
        if (res) {
          this.getInit()
        }
      }).catch(err => {
        console.log(err)
        message.error(err.message)
      })
  }

  /**
   * 入出力
   * @param {*} format
   */
  inputOutput_F08(format) {

    this.setState({
      childModal: {
        // ...this.state.childModal,
        visible: true,
        width: '600px',
        component: (
          <WS0449009_InputOutput
            Li_Format={format}
            onFinishScreen={(output) => {
              this.getInit()
              this.closeModal()
            }}
          />
        )
      }
    })

  }

  /**
   * 複写
   * @param {*} record
   */
  copy_F9(record) {
    let params = {
      userunitfdmaster: record
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS0449005_CopyingProcess
            userunitfdmaster={record}
            onFinishScreen={(output) => {
              this.getInit()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * 変換保守
   */
  convertTable_F10(format) {


    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0452005_ConvertTblSubAll
            Li_Format={format}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        )
      }
    })

  }

  /**
   * 変換複写
   */
  convertTableReplicate_F11(record) {


    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0449007_ConvertTblReplicationProcess
            record={record}
            onFinishScreen={(output) => {
              console.log('WS0449007_ConvertTblReplicationProcess:close')
              this.getInit()
              this.closeModal()
            }}
          />
        )
      }
    })

  }

  // 一覧表示
  UserParamInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS0450004_UserParamInput
            newFlag={false}
            record={record}
            onFinishScreen={(output) => {
              this.getInit()
              this.closeModal()
            }}
          />
        )
      }
    })
  }
  // 新規
  UserParamInputNew(record) {

    const dataSource = [...this.state.dataSource]
    this.setState({ dataSource: [] })
    const newdata = { id: generateUUID(), format: '', remarks: '' }
    dataSource.length > 0 ? dataSource.unshift(newdata) : dataSource.push(newdata)
    this.setState({ dataSource: dataSource })
  }

  // 変更
  UserParamInputChange(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <UserParamMaintainAddNew
            newFlag={false}
            record={record}
            onFinishScreen={(output) => {
              this.getInit()
              this.closeModal()
              this.forceUpdate()
            }}
          />
        )
      }
    })
  }




  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }


  render() {
    return (
      <div className='user-param-maintain' >
        <Card title='ユーザーパラメータ保守'>
          <div className='box_container'>
            {/* <Form
              ref={this.formRef}
              autoComplete='off'
            > */}
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.isSwitchOn ? false : this.state.pagination}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(62) }}
              onRow={(record, rowIndex) => ({
                // 選択処理
                onDoubleClick: () => {
                  console.log('onRow: ', record)
                  this.UserParamInput(record)
                }


              })}
            >
              <Table.Column
                title='FORMAT'
                dataIndex='format'
                className='column-size-30'
                sorter={(a, b) => a.format.localeCompare(b.format, 'ja')}
                render={(text, record) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      if (record.format !== e.target.value) {
                        record.format = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }
                    }}
                    onPressEnter={(e) => {
                      if (record.format !== e.target.value) {
                        record.format = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }
                    }}
                  />
                )}
              />

              <Table.Column
                title='備考'
                dataIndex='remarks'
                sorter={(a, b) => a.remarks.localeCompare(b.remarks, 'ja')}
                render={(text, record) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      if (record.remarks !== e.target.value) {
                        console.log('remarks:', e.target.value)
                        record.remarks = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }
                    }}
                    onPressEnter={(e) => {
                      if (record.remarks !== e.target.value) {
                        record.remarks = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }
                    }}
                  />
                )}
              />

              <Table.Column
                width={40}
                align='center'
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      onClick={() => this.UserParamInputNew()}
                      type='primary'
                      icon={<PlusOutlined />}
                    />
                  </div>
                }

                render={(value, record) => (
                  <>
                    <Space>
                      <Button size='small' style={{ border: 'none', }}
                        icon={<SaveOutlined style={{ color: !record.changed ? 'gainsboro' : 'green' }} />}

                        disabled={!record.changed}
                        onClick={() => {
                          this.save(record)
                        }}
                      ></Button>

                      <Button size='small' style={{ border: 'none', }}
                        danger icon={<DeleteOutlined />}
                        onClick={() => { this.delete(record) }}
                      ></Button>

                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            {/* <Menu.Item
                            key='6'
                            onClick={() =>
                              this.UserParamInputChange(record)
                            }
                          >
                            編集
                          </Menu.Item> */}
                            <Menu.Item
                              key='1'
                              onClick={() =>
                                this.inputOutput_F08(record.format)
                              }
                            >
                              入出力
                            </Menu.Item>

                            <Menu.Item
                              key='2'
                              onClick={() =>
                                this.copy_F9(record)
                              }
                            >
                              複写
                            </Menu.Item>

                            <Menu.Item
                              key='3'
                              onClick={() =>
                                this.convertTable_F10(record.format)
                              }
                            >
                              変換保守
                            </Menu.Item>

                            <Menu.Item
                              key='4'
                              onClick={() =>
                                this.convertTableReplicate_F11(record)
                              }
                            >
                              変換複写
                            </Menu.Item>

                            {/* <Menu.Item
                            key='5'
                            onClick={async () =>
                              await this.delete(record)
                            }

                          >
                            削除
                          </Menu.Item> */}
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </Space>
                  </>
                )}
              />
            </Table>
            <Row>
              <Col span={23}></Col>
              <Col span={1}>
                <Space>
                  <Switch style={{ marginTop: '10px' }}
                    checkedChildren="全て"
                    defaultChecked={false}
                    onClick={(checked) => { this.setState({ isSwitchOn: checked }) }}></Switch>
                </Space>
              </Col>
            </Row>
            {/* </Form> */}
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0449001_UserParamMaintain)
