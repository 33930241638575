import SupportPlanManualCreateService from "services/SpecificInsureGuide/InsureGuideInitInput/SupportPlanManualCreateService";
import { message } from "antd";

const SupportPlanManualCreateAction = {
  getScreenData(data) {
    return SupportPlanManualCreateService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  tableGet(data) {
    return SupportPlanManualCreateService.tableGet(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  save(data) {
    return SupportPlanManualCreateService.save(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  delete(data) {
    return SupportPlanManualCreateService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
};

export default SupportPlanManualCreateAction;