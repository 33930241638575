import React from "react";
import { connect } from "react-redux";
import moment from 'moment'
import { Card, Form, Button, Table, Space, message } from "antd";
import { DeleteOutlined } from '@ant-design/icons'
import RefPeopleNumSettingInfoAction from "redux/SystemMaintenance/RefPeopleNumSettingInfo/RefPeopleNumSettingInfo.action";

const today = moment(new Date().toISOString())

class WS1485016_HistorySelect extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '履歴選択';

    this.state = {
      dataSource: [],
      selectedRowKeys: [],
      isLoadingTable: false,
    };
  }

  onFinish(values) {

  }

  componentDidMount() {
    this.getInitialValuse();
  }

  getInitialValuse = () => {
    this.setState({ isLoadingTable: true })
    RefPeopleNumSettingInfoAction.getHistoryOn({ Lio_History: this.props.Lio_History })
      .then(res => {
        this.setState({
          selectedRowKeys: res ? [res.History]: [],
          dataSource: res ? res.History_List : []
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => { this.setState({ isLoadingTable: false }) });
  }

  deleteHistoryOn = (history) => {
    let params = {
      history_on: history,
    }
    RefPeopleNumSettingInfoAction.deleteHistoryOn(params)
      .then(res => message.info(res?.message))
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  render() {
    return (
      <div className="history-select">
        <Card title="履歴選択">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space
              direction={'vertical'}
              style={{ width: '100%' }}
            >
              <Table
                dataSource={this.state.dataSource}
                rowKey={(record) => record.history_on}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected, selectedRows) => {
                    let index = this.state.dataSource.findIndex(x => x.history_on === record.history_on)
                    this.setState({
                      rowSelected: selectedRows,
                      selectedRowKeys: selectedRows.map(x => x.history_on),
                      indexTable: index
                    });
                  },
                }}
                size={'small'}
                loading={this.state.isLoadingTable}
                pagination={false}
                showHeader={false}
              >
                <Table.Column dataIndex="history_on" />
                <Table.Column
                  render={(value, record, index) => {
                    return (
                      <Button
                        danger
                        type="primary"
                        icon={<DeleteOutlined />}
                        disabled={moment(record.history_on).format('YYYYMMDD') < today.format('YYYYMMDD')}
                        onClick={() => {
                          this.deleteHistoryOn(record.history_on)
                          this.props.onFinishScreen()
                        }}
                      >
                        削除
                      </Button>
                    )
                  }}
                />
              </Table>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => this.props.onFinishScreen(this.state.selectedRowKeys[0])}
                >選択</Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1485016_HistorySelect);
