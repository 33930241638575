import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Modal, Checkbox, message, Tooltip, Dropdown, Menu } from 'antd'
import { MoreOutlined, SaveOutlined } from '@ant-design/icons'
import IntroduceLetterExtractService from 'services/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtract'
import moment from 'moment'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS0919001_IntroduceLetterMasterMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0919001_IntroduceLetterMasterMaintain.jsx'
import Color from 'constants/Color'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import FollowUpAction from 'redux/IntroductionLetter/FollowUp/FollowUp.action.js'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx'

class DeleteConfirm extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '経過観察'

    this.state = {
      loading: false,
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      selectedReserveNumList: [],
      reserveNumList: [],
      allDelete: false
    }
  }

  componentDidMount = () => {
    let newReserveNumList = []
    this.props.dataSource.forEach((value) => {
      newReserveNumList.push(value.reservation_number)
    })

    let newSelectedReserveNumList = []
    this.props.selectedRows.forEach((value) => {
      newSelectedReserveNumList.push(value.reservation_number)
    })

    this.setState({
      selectedReserveNumList: newSelectedReserveNumList,
      reserveNumList: newReserveNumList
    })
  }

  delete = () => {
    let params = {
      selectedReserveNumList: this.state.selectedReserveNumList,
      reserveNumList: this.state.reserveNumList,
      allDelete: this.state.allDelete === true ? 1 : 0
    }
    FollowUpAction.deleteFollowUp(params)
      .then(res => {
        this.props.onDeleteDatas()
      })
  }

  render = () => {
    return (
      <div className='delte-confirm' style={{ width: 350 }}>
        <Card title='削除'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div style={{ width: '100%', textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>{'選択対象の経過観察情報を削除しますか？'}</div>
              <Checkbox
                defaultChecked={false}
                onChange={(e) => { this.setState({ allDelete: e.target.checked }) }}
              >
                把握分も含めて削除する
              </Checkbox>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  size='small'
                  onClick={() => this.delete()}
                >
                  <span className='btn_label'>
                    はい
                  </span>
                </Button>
                <Button
                  type='primary'
                  size='small'
                  onClick={() => this.props.onFinishScreen()}
                >
                  <span className='btn_label'>
                    いいえ
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConfirm)
