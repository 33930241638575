import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Form, Input, Select, Table, Row, Col, Space, Button, Dropdown, Menu } from "antd";
import { SearchOutlined } from '@ant-design/icons'
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS0267001_CategorySearchQuerySingle from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle";
import UseInspectCheckAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/UseInspectCheck.actions";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

const styleMarginRight10 = {
  marginRight: "10px"
};
const styleBorder0 = {
  border: 0
};

class WS0294001_UseInspectCheck extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_PatternCode: PropTypes.any,
    Li_CategoryCode: PropTypes.any,
    Li_InspectCode: PropTypes.any,

    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = '使用検査チェック';

    this.state = {
      tableData: [],
      requestParams: {
        Li_PatternCode: "",
        Li_CategoryCode: 0,
        Li_InspectCode: 0
      },

      pageSize: 15,

      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      isLoading: true,
      isLoadingTable: true,
      patternCodeList: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }
  ReturnComponent = component => {
    let components = {
      WS0294001_UseInspectCheck
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  componentDidMount() {
    this.getInit();
  }
  getInit() {
    this.setState({ isLoading: true, isLoadingTable: true });
    UseInspectCheckAction.getScreenData(this.state.requestParams)
      .then(res => {
        let s_list = [];
        for (let i = 0; i < res.PatternCode_List.length; i++) {
          s_list.push(
            <Select.Option key={i + 1} value={res.PatternCode_List[i].pattern_code}>
              {res.PatternCode_List[i].pattern_code}
            </Select.Option>
          );
        }
        this.setState({ patternCodeList: res ? s_list : [] });
        this.formRef.current?.setFieldsValue({ Li_PatternCode: this.props.patternCode })
      })
      .finally(() => { this.onChangePatternCode(this.props.patternCode) });
    // UseInspectCheckAction.getInit(this.props.patternCode)
    //   .then(res => {
    //     this.setState({ tableData: res ? res : [] });
    //   })
    //   .finally(() => this.setState({ isLoadingTable: false }));
  }

  onFinish(values) { }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  onFilterChange = (chagnedState, formState) => {
    if (formState.Li_CategoryCode && formState.Li_CategoryCode !== "") {
      UseInspectCheckAction.getNameCategoryCode({ CategoryCode: formState.Li_CategoryCode }).then(
        res => {
          this.formRef.current?.setFieldsValue({
            category_name: res ? res.category_name : ""
          });
        }
      );
    } else {
      this.formRef.current?.setFieldsValue({
        category_name: ""
      });
    }
    if (formState.Li_InspectCode && formState.Li_InspectCode !== "") {
      UseInspectCheckAction.getNameInspectCode({ InspectCode: formState.Li_InspectCode }).then(
        res => {
          this.formRef.current?.setFieldsValue({
            exam_name: res ? res.exam_name : ""
          });
        }
      );
    } else {
      this.formRef.current?.setFieldsValue({
        exam_name: ""
      });
    }

    let filters = {};

    for (const [key, value] of Object.entries(formState)) {
      if (value) {
        filters[key] = value;
      }
    }

    this.setState({ filter: formState });

    this.getFilteredData(filters);
  };

  getFilteredData = filters => {
    UseInspectCheckAction.getInit(filters)
      .then(res => {
        this.setState({ tableData: res ? res : [] });
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  };

  tableDataUpdate = () => {
    let filters = {};
    const formData = this.formRef.current.getFieldsValue();

    for (const [key, value] of Object.entries(formData)) {
      if (value) {
        filters[key] = value;
      }
    }

    this.setState({ filter: formData });

    this.getFilteredData(filters);
  };

  searchCategoryCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0267001_CategorySearchQuerySingle
            Lio_CategoryCode={this.formRef.current?.getFieldValue(
              "Li_CategoryCode"
            )}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                Li_CategoryCode: output?.Lio_CategoryCode,
                category_name: output?.Lio_CategoryName
              });

              this.tableDataUpdate();
              this.closeModal();
            }}
          />
        )
      }
    });
  }

  onChangePatternCode = (value) => {
    let param = {
      Li_PatternCode: value ?? ''
    }
    UseInspectCheckAction.getInit(param)
      .then(res => {
        this.setState({ tableData: res ? res : [] });
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  searchInspectCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={this.formRef.current?.getFieldValue("Li_InspectCode")}
            onFinishScreen={output => {
              this.formRef.current?.setFieldsValue({
                Li_InspectCode: output?.Lio_InspectItemCode,
                exam_name: output?.recordData?.exam_name
              });
              this.tableDataUpdate();
              this.closeModal();
            }}
          />
        )
      }
    });
  }
  GetDataBySearch = () => {
    let params = {
      Li_PatternCode: this.formRef.current?.getFieldValue('Li_PatternCode'),
      Li_CategoryCode: this.formRef.current?.getFieldValue("Li_CategoryCode"),
      Li_InspectCode: this.formRef.current?.getFieldValue("Li_InspectCode"),
    }
    UseInspectCheckAction.getInit(params)
      .then(res => {
        this.setState({ tableData: res ? res : [] });
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  render() {
    return (
      <div className="use-inspect-check">
        <Card title="使用検査チェック">
          <div className="box_container">
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            // onValuesChange={this.onFilterChange}
            >
              <div className="box_inner_horizontal">
                <div className="box_search">
                  <Form.Item name="Li_PatternCode" label="パターンコード" initialValue="" style={{ marginBottom: 0, marginRight: '10px' }}>
                    <Select
                      style={{ width: 150 }}
                      onChange={(e) => this.onChangePatternCode(e)}
                    >
                      <Select.Option value="">全て</Select.Option>
                      {this.state.patternCodeList}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="Li_CategoryCode"
                    label="カテゴリコード"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      type="text"
                      onPressEnter={() => this.searchCategoryCode()}
                      className='input-size-10'
                    />
                  </Form.Item>
                  <Form.Item name="category_name" label="" style={{ marginBottom: 0, marginRight: '10px' }}>
                    <div>{this.formRef.current?.getFieldValue("category_name")}</div>
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="Li_InspectCode"
                    label="検査コード"
                  >
                    <Input
                      type="text"
                      onPressEnter={() => { this.searchInspectCode() }}
                      className='input-size-10'
                    />
                  </Form.Item>
                  <Form.Item name="exam_name" label="" style={{ marginBottom: 0 }}>
                    <div>{this.formRef.current?.getFieldValue('exam_name')}</div>
                  </Form.Item>
                </div>
                <div className="box_search_btn">
                  <div className="box_search_btn_inner">
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.GetDataBySearch()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              bordered
              dataSource={this.state.tableData}
              pagination={this.state.tableData.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={record => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(200) }}
            >
              <Table.Column
                title="パターンコード"
                dataIndex="pattern_code"
                className="input-size-12"
              // render={(value, record, index) => {
              //   return <div>{value.pattern_code}</div>;
              // }}
              />
              <Table.Column
                title="パターン名称"
                dataIndex="pattern_name"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.pattern_name}</div>;
              // }}
              />
              <Table.Column
                title="カテゴリ"
                dataIndex="category_code"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.category_code}</div>;
              // }}
              />
              <Table.Column
                title="カテゴリ名称"
                dataIndex="category_name"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.category_name}</div>;
              // }}
              />
              <Table.Column
                title="検査コード"
                dataIndex="exam_code"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.exam_code}</div>;
              // }}
              />
              <Table.Column
                title="検査略名"
                dataIndex="exam_short_name"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.exam_short_name}</div>;
              // }}
              />
              <Table.Column
                title="結果コード"
                dataIndex="item_code_external"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.item_code_external}</div>;
              // }}
              />
              <Table.Column
                title="外部名称"
                dataIndex="exam_name"
                className="column-size-40"
              // render={(value, record, index) => {
              //   return <div>{value.exam_name}</div>;
              // }}
              />
              <Table.Column
                title="依頼コード"
                dataIndex="RequestCode"
                className="column-size-10"
              // render={(value, record, index) => {
              //   return <div>{value.RequestCode}</div>;
              // }}
              />
              <Table.Column
                title="依頼名称"
                dataIndex="exam_name_external_kana"
                className="column-size-40"
              // render={(value, record, index) => {
              //   return <div>{value.exam_name_external_kana}</div>;
              // }}
              />
            </Table>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS0294001_UseInspectCheck);
