import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Button, message, Table, Dropdown, Menu } from "antd";
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';
import WS2657078_VouchersInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2657078_VouchersInput.jsx";
import moment from 'moment';
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import VouchersInputProcessListAction from 'redux/SpecificInsureGuide/VouchersInputProcessList/VouchersInputProcessList.actions.js'

const DATE_FORMAT = 'YYYY/MM/DD';

const smGrid = {
  labelCol: { style: { width: 90 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 90px)' } }
}

class WS1462001_VouchersInputProcessList extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '利用券入力処理[一覧]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchInfo: {
        DateFScreen: moment(new Date()).format(DATE_FORMAT),
        DateTScreen: moment(new Date()).format(DATE_FORMAT),
        InsuranceNum: "",
        Setting: 2,
      },
      insurer_kanji_name: "",
    };
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      DateF: this.state.searchInfo.DateFScreen,
      DateT: this.state.searchInfo.DateTScreen,
      InsuranceNum: this.state.searchInfo.InsuranceNum,
      Setting: this.state.searchInfo.Setting
    });
  }

  displayButton = () => {
    const { DateFScreen, DateTScreen, Setting: Setting_1, InsuranceNum } = this.state.searchInfo;
    if (!this.formRef.current?.getFieldValue('DateF') || !this.formRef.current?.getFieldValue('DateT')) {
      message.error('日付を入力して下さい');
      return;
    }
    this.tableData()
  }

  tableData() {
    let getFieldValue = this.formRef.current?.getFieldValue()
    let params = {
      Li_DateF: moment(getFieldValue.DateF).format('YYYY/MM/DD'),
      Li_DateT: moment(getFieldValue.DateT).format('YYYY/MM/DD'),
      InsuranceNum: getFieldValue.InsuranceNum,
      Setting: getFieldValue.Setting
    }

    VouchersInputProcessListAction.index(params)
      .then(res => {
        let copyData = res.data
        for (let i = 0; i < copyData.length; i++) {
          if (copyData[i].ExpirationDate === "0000/00/00") {
            copyData[i].ExpirationDate = ''
          }
        }
        this.setState({
          dataSource: copyData
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingFm: false }))
  }

  basic_burden_sect(value) {
    switch (value) {
      case '0':
        return ''
      case '1':
        return '動機付支援'
      case '2':
        return '積極的支援'
      case '3':
        return '動機付相当'
      case '4':
        return 'モデル実施'
    }
  }

  health_burden_sect(value) {
    switch (value) {
      case 0:
        return ''
      case 1:
        return '負担なし'
      case 2:
        return '定額負担'
      case 3:
        return '定率負担'
      case 4:
        return '保険者上限'
      case 5:
        return '定率(上限以下)'
      case 6:
        return '定額(上限以下)'
    }
  }

  ExpirationDateChar(value) {
    if (value !== '') {
      return '〇'
    } else {
      return '×'
    }
  }

  VisitsPropriety(value) {
    switch (value) {
      case 0:
        return '受診不可'
      case 1:
        return '受診可能'
    }
  }

  ContractAgenciesothervisitPropriety(value) {
    if (value !== '0') {
      return '〇'
    } else {
      return '×'
    }
  }

  showWS2657078VouchersInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 750,
        component: (
          <WS2657078_VouchersInput
            Li_Date={moment(record.date_on).format("YYYY/MM/DD")}
            Li_PersonalNum={record.personal_number_id}
            Li_insurer_num={record.insurer_num}
            Li_office_code={record.office_code}
            onFinishScreen={(output) => {
              this.tableData();
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    const { searchInfo: { DateFScreen, DateTScreen, InsuranceNum, Setting }, insurer_kanji_name } = this.state;
    return (
      <div className="vouchers-input-process-list">
        <Card className="mb-2" title="利用券入力処理[一覧]">
          <Form ref={this.formRef}>
            <div className="box_inner_horizontal" style={{ marginBottom: '10px' }}>
              <div className="box_search">
                <div className="box_search_inner">
                  <div className="box_inner_horizontal">
                    <Form.Item label='初回予定日' {...smGrid} name='DateF'>
                      <VenusDatePickerCustom format={DATE_FORMAT} style={{ width: 150 }}
                      />
                    </Form.Item>
                    <Form.Item label='~' name='DateT'>
                      <VenusDatePickerCustom format={DATE_FORMAT} style={{ width: 150 }}
                      />
                    </Form.Item>
                  </div>

                  <div className="box_inner_horizontal">
                    <Form.Item
                      label='保険者番号'
                      {...smGrid}
                      name='InsuranceNum'
                    >
                      <Input.Search
                        allowClear
                        // value={InsuranceNum}
                        onChange={() => this.setState({ insurer_kanji_name: '' })}
                        className="input-search-size-single-byte-8"
                        onSearch={(value, event) => {
                          if (event.target.localName === "input") {
                            this.setState({
                              searchInfo: {
                                InsuranceNum: '',
                                insurer_kanji_name: ''
                              }
                            })
                          } else {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (
                                  <WS1290001_InsurerNumberInquiry
                                    onFinishScreen={(output) => {
                                      this.setState({
                                        searchInfo: { ...this.state.searchInfo, InsuranceNum: output.Lo_InsurerNum },
                                        insurer_kanji_name: output?.Lo_InsurerKanjiName
                                      })
                                      this.formRef.current.setFieldsValue({
                                        InsuranceNum: output?.Lo_InsurerNum
                                      });
                                      this.closeModal()
                                    }}
                                  />),
                              },
                            })
                          }
                        }} />
                    </Form.Item>
                    <div style={{ marginTop: '5px' }}>{insurer_kanji_name}</div>
                  </div>

                  <div className="box_inner_horizontal">
                    <Form.Item label='設定' {...smGrid} name='Setting'>
                      <Select>
                        <Select.Option value={0}>未設定</Select.Option>
                        <Select.Option value={1}>設定済</Select.Option>
                        <Select.Option value={2}>全て</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="box_search_btn">
                <Button
                  icon={<SearchOutlined />}
                  onClick={this.displayButton}
                >
                  検　索
                </Button>
              </div>
            </div>

            <Table
              bordered
              size="small"
              dataSource={this.state.dataSource}
              pagination={false}
              scroll={{ y: resizableTableScroll(120) }}
              rowKey={(record) => record.id}
              onRow={(record, index) => ({
                onDoubleClick: () => {
                  this.showWS2657078VouchersInput(record)
                }
              })}
            >
              <Table.Column
                title="受診日"
                dataIndex="date_on"
                width={120}
                render={(_, item) => {
                  return (
                    <div>
                      {moment(item["date_on"]).format("YYYY/MM/DD")}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>ＩＤ</div>}
                dataIndex="personal_number_id"
                align="right"
                width={100}
              />
              <Table.Column
                title="氏名"
                width={200}
                dataIndex="kanji_name"
              />
              <Table.Column
                title="性別"
                dataIndex="sex"
                width={80}
              />
              <Table.Column
                title="生年月日"
                dataIndex="birthday_on"
                width={120}
                render={(_, item) => {
                  return (
                    <div>
                      {moment(item["birthday_on"]).format("YYYY/MM/DD")}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>保険者番号</div>}
                dataIndex="insurer_num"
                align="right"
                width={100}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>利用</div>}
                dataIndex="vouchers_docket_num"
                align="right"
                width={120}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>受診券番号</div>}
                dataIndex="consult_ticket_docket_num"
                align="right"
                width={120}
              />
              <Table.Column
                title="有効期限"
                dataIndex="ExpirationDate"
                width={120}
              />
              <Table.Column
                title="指導区分"
                dataIndex="health_guide_sect"
                width={110}
                render={(value, record, index) => (
                  <div>{this.basic_burden_sect(value)}</div>
                )}
              />
              <Table.Column
                title="保険負担"
                dataIndex="specify_health_burden_sect"
                width={120}
                render={(value, record, index) => (
                  <div>{this.health_burden_sect(value)}</div>
                )}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>率額</div>}
                dataIndex="SpecificInsuranceburdenContent"
                align="right"
                width={100}
                render={(_, item) => {
                  return (
                    <div>
                      {item["SpecificInsuranceburdenContent"].toLocaleString()}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={<div style={{ textAlign: 'left' }}>上限</div>}
                dataIndex="specify_insurer_pay_max"
                align="right"
                width={80}
                render={(_, item) => {
                  return (
                    <div>
                      {item["specify_insurer_pay_max"].toLocaleString()}
                    </div>
                  );
                }}
              />
              <Table.Column
                title="代行機関"
                dataIndex="Agency"
                width={80}
                render={(value, record, index) => (
                  <div>{this.ContractAgenciesothervisitPropriety(value)}</div>
                )}
              />
              <Table.Column
                title="受診"
                dataIndex="VisitsPropriety"
                width={110}
                render={(value, record, index) => (
                  <div>{this.VisitsPropriety(value)}</div>
                )}
              />
              <Table.Column
                align='center'
                width={40}
                fixed='right'
                render={(value, record, index) => (
                  <Dropdown size='small' trigger='click' overlay={() => (
                    <Menu >
                      <Menu.Item
                        key="1"
                        onClick={() => (
                          this.showWS2657078VouchersInput(record)
                        )}>明細</Menu.Item>
                    </Menu>
                  )}>
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1462001_VouchersInputProcessList);
