import { message } from "antd";
import axios from "configs/axios";

const API_LIST = {
    getScreenData: "/api/associate-insure-qualify-confirm/transmission-capture-confirm/getscreenData",   
};

const TransmissionCaptureConfirmService = {
    async getScreenData(params) {

        // console.log(params);
        return axios.get(API_LIST.getScreenData, { params });
    },   
};

export default TransmissionCaptureConfirmService;

