import axios from 'configs/axios'

const API_LIST = {
  index: '/api/introduce-letter-extract/introduce-letter-subject-cmt-inquiry/',
}

const IntroduceLetterSubjectCmtInquiryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },

}

export default IntroduceLetterSubjectCmtInquiryService
