import axios from 'configs/axios'

const API_LIST = {
  index: '/api/spread-input/findings-input-radiography',
  getSiteFindingsInputThisTime: '/api/spread-input/findings-input-radiography/get-site-findings-input-this-time',
  getSiteFindingsPrevious: '/api/spread-input/findings-input-radiography/get-site-findings-previous',
  setSelectedSiteFinding: '/api/spread-input/findings-input-radiography/set-selected-site-finding',
  save: '/api/spread-input/findings-input-radiography/save',
  delete: '/api/spread-input/findings-input-radiography/delete',
  setPreviousFindingsDo: '/api/spread-input/findings-input-radiography/set-previous-findings-do',
  selectSitesAndFindings: '/api/spread-input/findings-input-radiography/select-sites-and-findings',
}

const FindingsInputRadiographyService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getSiteFindingsInputThisTime(params) {
    return axios.get(API_LIST.getSiteFindingsInputThisTime, { params })
  },
  async getSiteFindingsPrevious(params) {
    return axios.get(API_LIST.getSiteFindingsPrevious, { params })
  },
  async setSelectedSiteFinding(params) {
    return axios.post(API_LIST.setSelectedSiteFinding, params)
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
  async setPreviousFindingsDo(params) {
    return axios.post(API_LIST.setPreviousFindingsDo, params)
  },
  async selectSitesAndFindings(params) {
    return axios.get(API_LIST.selectSitesAndFindings, { params })
  },

}

export default FindingsInputRadiographyService
