import axios from 'configs/axios'
const apiPaths = {
  getScreenData: '/api/printer-info-maintain/printer-select/get-screen-data',
  index: '/api/printer-info-maintain/printer-select/index',
}
const PrinterSelectService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
}

export default PrinterSelectService
