import axios from "configs/axios";

const APP_LIST = {
  getInit: "/api/user-param-maintain",
  save: "/api/user-param-maintain/save",
  delete2: "/api/user-param-maintain/delete2",
  UserParamInputIndex: "/api/user-param-maintain/user-param-input-index",
  UserParamInputSave: "/api/user-param-maintain/user-param-input-save",
  save2: "/api/user-param-maintain/save2",
};

const UserParamMaintainService = {
  async getInit() {
    return axios.get(APP_LIST.getInit);
  },
  async save(params) {
    return axios.post(APP_LIST.save, params);
  },
  async delete2(params) {

    return axios.post(APP_LIST.delete2, params).then((res) => {
      return res
    }).catch((err) => {
      throw err.response.data
    });
  },
  async UserParamInputIndex(params) {
    return axios.get(APP_LIST.UserParamInputIndex, { params });
  },
  async UserParamInputSave(params) {
    return axios.post(APP_LIST.UserParamInputSave, params);
  },
  async save2(params) {
    console.log('通過');
    return axios.post(APP_LIST.save2, params).then((res) => {
      return res
    }).catch((err) => {
      throw err.response.data
    });
  },
};

export default UserParamMaintainService;
