import axios from "configs/axios";

const APP_LIST = {
  getScreenData: `/api/specific-medical-exam-settle-process/settle-input-sub`,
  healthCheckupAdd: `/api/specific-medical-exam-settle-process/settle-input-sub/health-checkup-add`,
  sameTime: `/api/specific-medical-exam-settle-process/settle-input-sub/same-time`,
  save: `/api/specific-medical-exam-settle-process/settle-input-sub/save`,
  healthCheckupAddSave: `/api/specific-medical-exam-settle-process/settle-input-sub/health-checkup-add-save`,
  sameTimeSave: `/api/specific-medical-exam-settle-process/settle-input-sub/same-time-save`,
};
const SettleInputSubService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async healthCheckupAdd(params) {
    return axios.get(APP_LIST.healthCheckupAdd, { params });
  },
  async sameTime(params) {
    return axios.get(APP_LIST.sameTime, { params });
  },
  async save(params) {
    return axios.post(APP_LIST.save, params);
  },
  async healthCheckupAddSave(params) {
    return axios.post(APP_LIST.healthCheckupAddSave, params);
  },
  async sameTimeSave(params) {
    return axios.post(APP_LIST.sameTimeSave, params);
  },
};

export default SettleInputSubService;
