import axios from 'configs/axios';

const apiPaths = {
  index: '/api/condition-express-cmt-setting/condition-express-cmt-sub',
  saveAndUpdate: '/api/condition-express-cmt-setting/condition-express-cmt-sub/save-and-update',
  delete: '/api/condition-express-cmt-setting/condition-express-cmt-sub/delete',
  details: '/api/condition-express-cmt-setting/condition-express-cmt-sub/details',
  saveDetails: '/api/condition-express-cmt-setting/condition-express-cmt-sub/save-details',
  deleteDetails: '/api/condition-express-cmt-setting/condition-express-cmt-sub/delete-details',
};

const ConditionExpressCmtSubService = {
  async index(params) {
    return axios.get(apiPaths.index, { params });
  },

  async saveAndUpdate(params) {
    return axios.post(apiPaths.saveAndUpdate, params);
  },

  async delete(params) {
    return axios.delete(apiPaths.delete, { params });
  },

  async details(params) {
    return axios.get(apiPaths.details, { params });
  },

  async saveDetails(params) {
    return axios.post(apiPaths.saveDetails, params);
  },

  async deleteDetails(params) {
    return axios.delete(apiPaths.deleteDetails, { params });
  },

};

export default ConditionExpressCmtSubService;