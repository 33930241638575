import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Table, Dropdown, Button, Menu } from 'antd'
import { PlusOutlined, MoreOutlined, SaveOutlined } from '@ant-design/icons'
import IntroduceLetterReturnInputAction from 'redux/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterReturnInput.action.js'
import PropTypes from 'prop-types'
import WS0887001_IntroduceLetterVariousMasterInquiry from '../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import WS0936003_IntroduceLetterReturnInputEdit from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0936003_IntroduceLetterReturnInputEdit.jsx'
import moment from 'moment-timezone'
import ModalDraggable from 'components/Commons/ModalDraggable'
// import { VenusDatePickerCustom as DatePicker } from 'components/Commons/VenusDatePickerCustom'

const labelCol = {
  labelCol: { style: { width: 80 } },
}

const today = moment(new Date().toISOString())
class WS0936002_IntroduceLetterReturnInput extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_Department: PropTypes.any,
    Li_ReturnInfoDisplay: PropTypes.any,
    onFinishScreen: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '紹介状返送入力'

    this.state = {
      screenData: {},
      count: 0,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      ComboBox_PrecisionResult: [],
      LandOldCoerciveGroupClassify: '',
      H58ex_id: null,
      maxSeq: 0
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      ExamDateChar: today.format('YYYY/MM/DD'),
    })
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      course_level: this.props.Li_CourseLevel,
      reservation_number: this.props.Li_ReserveNum,
      department: this.props.Li_Department,
      Li_ReturnInfoDisplay: this.props.Li_ReturnInfoDisplay,
    }
    IntroduceLetterReturnInputAction.index(params)
      .then((res) => {
        if (res) {
          this.formRef.current.setFieldsValue({
            ExamDateChar: res.ExamDateChar,
            old_insurance_precise_result: res.old_insurance_precise_result,
            medical_institution_code: res.medical_institution_code,
            medical_institutions_short_name: res.medical_institutions_short_name,
            remarks: res.remarks,
          })
          this.setState({
            H58ex_id: res.H58ex_id,
            ComboBox_PrecisionResult: res.ComboBox_PrecisionResult,
            LandOldCoerciveGroupClassify: res.LandOldCoerciveGroupClassify ?? ''
          })
          this.forceUpdate()
          this.getDataInputSub()
        }
      })
  }

  /**
   * 結果値データ取得
   */
  getDataInputSub = () => {
    const params = {
      course_level: this.props.Li_CourseLevel,
      reservation_number: this.props.Li_ReserveNum,
      department: this.props.Li_Department,
      Li_ReturnInfoDisplay: this.props.Li_ReturnInfoDisplay,
    }

    IntroduceLetterReturnInputAction.getDataInputSub(params)
      .then((res) => {
        if (res) {
          let maxSeqNum = 0
          res.forEach((item) => {
            if (item.seq > maxSeqNum) {
              // 連番の最大値を取得
              maxSeqNum = item.seq
            }
          })

          this.setState({
            dataSource: res,
            maxSeq: maxSeqNum
          })
        }
      })
  }

  /**
   * 保存処理
   * @param {*} record
   */
  save = () => {

    const time = this.formRef.current.getFieldValue('ExamDateChar')
    const params = {
      id: this.state.H58ex_id,
      personal_number_id: this.props.Li_PersonalNum,
      accepted_no: this.props.Li_AcceptNum,
      department: this.props.Li_Department,
      course_level: this.props.Li_CourseLevel,
      reservation_number: this.props.Li_ReserveNum,
      visit_date_on: this.props.Li_Date,
      exam_date_on: time ? moment(time).format('YYYY/MM/DD') : null,
      medical_institution_code: this.formRef.current.getFieldValue('medical_institution_code'),
      old_insurance_precise_result: this.formRef.current.getFieldValue('old_insurance_precise_result'),
      remarks: this.formRef.current.getFieldValue('remarks')
    }

    IntroduceLetterReturnInputAction.save(params)
      .then((res) => {

      })
  }


  /**
   * 紹介状各種マスタ照会　モーダル
   * 4 : 医療機関照会
   * @param {*} manageCode
   */
  showWS0887001_IntroduceLetterVariousMasterInquiry = (manageCode) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={manageCode}
            onFinishScreen={(output) => {
              let codeName = 'medical_institution_code'
              let name = 'medical_institutions_short_name'
              if (codeName) {
                this.formRef.current?.setFieldsValue({
                  [codeName]: output.variousCodes,
                  [name]: output.recordData[name],
                  // [tempName]: output.recordData['findings_content'] ???
                })
              }
              this.forceUpdate()
              this.closeModal()
            }
            }
          />
        ),
      },
    })
  }

  /**
   * 紹介状返送入力 編集　モーダル
   * @param {*} record
   */
  showWS0936003_IntroduceLetterReturnInputEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 750,
        component: (
          <WS0936003_IntroduceLetterReturnInputEdit
            record={record}
            maxSeq={this.state.maxSeq}
            Li_Date={this.props.Li_Date}
            Li_PatternCode={this.props.Li_PatternCode}
            Li_PersonalNum={this.props.Li_PersonalNum}
            Li_ReserveNum={this.props.Li_ReserveNum}
            Li_AcceptNum={this.props.Li_AcceptNum}
            Li_CourseLevel={this.props.Li_CourseLevel}
            Li_Department={this.props.Li_Department}
            onFinishScreen={(output) => {
              // データを再取得
              this.getDataInputSub()
              this.closeModal()
            }
            }
          />
        ),
      },
    })
  }

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-return-input'>
        <Card
          title={`紹介状返送入力 ${this.props.subTitle ?? ''}`}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            labelCol={{ span: 2 }}
            autoComplete='off'
          >
            <Form.Item
              name='ExamDateChar'
              label='検査日'
              {...labelCol}
            >
              <VenusDatePickerCustom
                formRefDatePicker={this.formRef}
                allowClear={true}
              />
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                name='medical_institution_code'
                label='医療機関'
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-5'
                  maxLength={4}
                  onSearch={() => {
                    // 4:医療機関照会 モーダル
                    this.showWS0887001_IntroduceLetterVariousMasterInquiry(4)
                  }}
                />
              </Form.Item>
              <div style={{ marginTop: 5 }}>
                {this.formRef.current?.getFieldValue('medical_institutions_short_name') ?? ''}
              </div>
            </div>


            <Form.Item
              name='old_insurance_precise_result'
              label='地域保健'
              {...labelCol}
            >
              <Select style={{ width: '250px' }}>
                {this.state.ComboBox_PrecisionResult?.map((item, index) =>
                  <Select.Option
                    key={index}
                    value={item.LinkedField}
                  >
                    {item.DisplayField}
                  </Select.Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item
              name='remarks'
              label='備考'
              {...labelCol}
            >
              <Input type='text' maxLength={40} />
            </Form.Item>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              scroll={{ y: 400 }}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    // 変更
                    this.showWS0936003_IntroduceLetterReturnInputEdit(record)
                  }
                }
              }}
            >
              <Table.Column
                title='連番'
                dataIndex='seq'
                width={80}
                render={(value, record, index) =>
                  <div style={{ textAlign: 'right' }}>{value}</div>
                }
              />
              <Table.Column
                title='検査'
                dataIndex='exam_code'
                render={(value, record, index) =>
                  <div className='box_inner_horizontal'>
                    <div style={{ textAlign: 'right' }}>{(value === 0) ? '' : value}</div>
                    <div>{record.exam_content}</div>
                  </div>
                }
              />
              <Table.Column
                title='所見'
                dataIndex='findings_code'
                render={(value, record, index) =>
                  <div className='box_inner_horizontal'>
                    <div style={{ textAlign: 'right' }}>{(value === 0) ? '' : value}</div>
                    <div>{record.findings_content}</div>
                  </div>
                }
              />
              <Table.Column
                title='傷病'
                dataIndex='sick_name_code'
                render={(value, record, index) =>
                  <div className='box_inner_horizontal'>
                    <div style={{ textAlign: 'right' }}>{(value === 0) ? '' : value}</div>
                    <div>{record.sick_name}</div>
                  </div>
                }
              />
              <Table.Column
                title='治療'
                dataIndex='treatment_code'
                render={(value, record, index) =>
                  <div className='box_inner_horizontal'>
                    <div style={{ textAlign: 'right' }}>{(value === 0) ? '' : value}</div>
                    <div>{record.treatment_content}</div>
                  </div>
                }
              />

              <Table.Column
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() =>
                      // 新規追加
                      this.showWS0936003_IntroduceLetterReturnInputEdit()
                    }
                  />}
                width={40}
                align='center'
                render={(value, record) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='0'
                          onClick={() => {
                            // 変更
                            this.showWS0936003_IntroduceLetterReturnInputEdit(record)
                          }}
                        >
                          変更
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )}
              />
            </Table>
          </Form>

          {/* Footer */}
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() =>
                this.save()
              }
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0936002_IntroduceLetterReturnInput)
