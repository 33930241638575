import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Table, Form, Input, Button, message, Checkbox } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import { ModalError } from "components/Commons/ModalConfirm";
import {
  registerInspectDataAction,
  getInspectItems,
  getTargetInspect,
} from "redux/SM_SystemMaintenance/ReservesDisplayItemSetting/ReservesDisplayItemSetting.action";
import { SearchOutlined } from '@ant-design/icons'

class WS2702027_ReservesDisplayItemSettingSub_Inspect extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    nameId: PropTypes.any,
    displayName: PropTypes.any,
    keyName: PropTypes.any,
  };
  constructor(props) {
    super(props);

    // document.title = '予約表示項目Sub(検査)';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      inspectItems: [],
      targetInspect: [],
      rowSelectLeft: {},
      rowSelectRight: {},
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      unregisteredFlg: false
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = async () => {
    this.formRef.current.setFieldsValue(this.props.record)
    //右→左の順にテーブル作成
    await this.loadDataRight()
    await this.loadDataLeft()
  }

  loadDataRight = async () => {
    let params = this.props.record
    await getTargetInspect(params)
      .then(res => {
        if (res) {
          this.setState({ targetInspect: res.data })
          this.formRef.current?.setFieldsValue({
          })
        }
      })
      .catch()
  }

  //検索枠でEnterキーを押すことで左のテーブルを再生成する
  keyPress(e) {
    if (e.key === 'Enter') {
      this.loadDataLeft();
    }
  }


  loadDataLeft = async () => {
    this.setState({ isLoadingPage: true });
    let params = {
      ...this.props.record,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
    }
    await getInspectItems(params)
      .then(res => {
        if (res) {
          let textColor
          for (let l = 0; l < res.data.length; l++) {
            for (let k = 0; k < this.state.targetInspect.length; k++) {
              if (res.data[l].test_item_code === this.state.targetInspect[k].test_item_code) {
                textColor = {
                  ...res.data[l],
                  textColor: '#b9b9b9'
                }
                res.data[l] = textColor
              }
            }
          }
          // res.data.map(x => x.textColor = this.state.targetInspect.findIndex(y => y.test_item_code === x.test_item_code) === -1 ? '' : '#b9b9b9')
          this.setState({ inspectItems: res.data })
          this.formRef.current?.setFieldsValue({
          })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  //左側のテーブルから右側に移す
  onForward(selectValue) {
    let rightData = [...this.state.targetInspect]
    selectValue = {
      ...selectValue,
    }
    rightData.push(selectValue)

    // 重複チェック
    let duplicationCheck = rightData.filter((e, index, self) => {
      return self.findIndex((data) => data.test_item_code === e.test_item_code) === index
    })

    // 並び替え
    duplicationCheck.sort(function (a, b) {
      if (a.test_item_code > b.test_item_code) {
        return 1;
      } else {
        return -1;
      }
    })
    // 追加された行の色を変える
    let copyDataLeft = [...this.state.inspectItems]
    for (let i = 0; i < copyDataLeft.length; i++) {
      if (copyDataLeft[i].test_item_code === selectValue.test_item_code) {
        copyDataLeft[i].textColor = '#b9b9b9';
        copyDataLeft[i].reservation_display_item_number = this.props.record.nameId;
      }
    }

    this.setState({
      inspectItems: copyDataLeft,
      rowSelectLeft: null,
      targetInspect: duplicationCheck,
      enabledForward: false
    })
  }

  onBackward(record) {
    let copyTargetInspect = [...this.state.targetInspect]
    for (let i = 0; i < copyTargetInspect.length; i++) {
      if (copyTargetInspect[i] === record) {
        let delteLenght = i
        copyTargetInspect.splice(delteLenght, 1)
      }
    }
    // 選択した行の色を変える
    let copyInspectItems = [...this.state.inspectItems]
    for (let l = 0; l < copyInspectItems.length; l++) {
      if (copyInspectItems[l].test_item_code === record.test_item_code) {
        copyInspectItems[l].textColor = '';
        copyInspectItems[l].reservation_display_item_number = 0;
      }
    }

    this.setState({
      inspectItems: copyInspectItems,
      targetInspect: copyTargetInspect,
      rowSelectLeft: null,
      enabledBackward: false
    })
  }

  onFinish = (value) => {
    let params = {
      ...value,
      keyName: this.props.record.keyName,
      nameId: this.props.record.nameId,
      displayName: this.formRef.current?.getFieldValue('displayName'),
      targetInspect: this.state.targetInspect
    };
    this.RegisterBtn(params);
  }

  RegisterBtn(params) {
    this.setState({ isLoading: true })
    registerInspectDataAction(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しましたaaa"))
      .finally(() => this.setState({ isLoading: false }))
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onSearch() {
    const params = {
      unregisteredFlg: this.state.unregisteredFlg,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      // parentCourse: this.props.course
    }
    getInspectItems(params)
      .then(res => {
        if (res) {
          let textColor
          let emptySeqData = []
          for (let l = 0; l < res.data.length; l++) {
            for (let k = 0; k < this.state.targetInspect.length; k++) {
              if (res.data[l].test_item_code === this.state.targetInspect[k].test_item_code) {
                textColor = {
                  ...res.data[l],
                  textColor: '#b9b9b9'
                }
                res.data[l] = textColor
              }
            }
          }

          emptySeqData = res.data.filter(item => {
            return item.seq == '';
          })

          if (this.state.unregisteredFlg) {
            this.setState({ inspectItems: emptySeqData })
          } else {
            this.setState({ inspectItems: res.data })
          }
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="予約表示項目（検査）" style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <Form.Item name='nameId' label="No.">
              <Input bordered={false} readOnly style={{ width: 100 }} />
            </Form.Item>

            <Form.Item name="displayName" label="表示名称" >
              <Input type="text" maxLength={10} style={{ width: 100 }} />
            </Form.Item>


            <div className="box_inner_horizontal">
              <div className="box_inner_vertical_label_top">
                <div
                  className="table_header_filter" ><div>検査一覧</div>

                  <Form.Item name='SearchChar' style={{ margin: 0 }}>
                    <Input size='small' onKeyPress={(e) => this.keyPress(e)} />
                  </Form.Item>

                  <Checkbox onClick={() => this.setState({ unregisteredFlg: !(this.state.unregisteredFlg) })}>未登録</Checkbox>
                  <Button type="primary" size="small" onClick={() => { this.onSearch() }} icon={<SearchOutlined />}>
                  </Button>

                </div>
                <Table bordered
                  dataSource={this.state.inspectItems}
                  pagination={this.state.inspectItems.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  loading={false}
                  size='small'
                  showHeader={true}
                  rowKey={(record) => record.test_item_code}
                  rowClassName={(record, index) => record.id === this.state.rowSelectLeft?.id ? 'table-row-light' : ''}
                  scroll={{ x: 'max-content', y: 450 }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        this.onForward(record)
                      },
                      onClick: async () => {
                        this.setState({
                          rowSelectRight: null,
                          rowSelectLeft: record,
                          enabledForward: true,
                          enabledBackward: false,
                        });
                      }
                    };
                  }}
                >
                  <Table.Column width={120} title="コード" dataIndex="test_item_code" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.test_item_code}</span>
                    )} />

                  <Table.Column title="名称" dataIndex="exam_name" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.exam_name}</span>
                    )} />

                  <Table.Column width={150} title="登録済み予約項目" dataIndex="reservation_display_item_number" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.seq}</span>
                    )} />

                </Table>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  gap: '16px',
                  padding: '16px',
                  margin: 'auto',
                  minWidth: '50px'
                }}>
                <Button size="small" type='primary'
                  disabled={!this.state.enabledForward}
                  onClick={() => this.onForward(this.state.rowSelectLeft)}>
                  追加 <DoubleRightOutlined /></Button>
                <Button size="small" type='primary'
                  icon={<DoubleLeftOutlined />}
                  disabled={!this.state.enabledBackward}
                  onClick={() => this.onBackward(this.state.rowSelectRight)}>
                  削除</Button>
              </div>
              <div className="box_inner_vertical_label_top">
                <div className="table_header_filter"                >
                  <div>予約表示検査</div>
                </div>

                <Table bordered
                  dataSource={this.state.targetInspect}
                  pagination={this.state.targetInspect.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  loading={false}
                  size='small'
                  showHeader={true}
                  rowKey={(record) => record.test_item_code}
                  rowClassName={(record, index) => record.id === this.state.rowSelectRight?.id ? 'table-row-light' : ''}
                  scroll={{ x: 'max-content', y: 450 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.onBackward(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectRight: record,
                          rowSelectLeft: null,
                          enabledForward: false,
                          enabledBackward: true,
                        });
                      }
                    };
                  }}
                >
                  <Table.Column width={120} title="コード" dataIndex="test_item_code" key="" />
                  <Table.Column title="名称" dataIndex="exam_name" key="" />
                </Table>
              </div>
            </div>

            <div className="box_button_bottom_right"
              style={{ marginTop: 0 }}>
              <Button type="primary" onClick={() => { this.onFinish(); }} icon={<SaveOutlined />}>
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2702027_ReservesDisplayItemSettingSub_Inspect);
