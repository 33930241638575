import React from "react";
import { connect } from "react-redux";
import { MoreOutlined, PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import { Card, Form, Button, Table, Input, Select, message, Tooltip, Dropdown, Menu, Space } from 'antd'
import ConvertTblSubAllAction from 'redux/ResultOutput/CsvCreateParamMaintain/ConvertTblSubAll.action';
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import ModalDraggable from "components/Commons/ModalDraggable"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import { generateUUID } from 'components/Commons/generateUUID'

class WS0452005_ConvertTblSubAll extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);

    // document.title = '変換テーブルSUB[全て]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      disabledSaveBtn: true,
      addCount: 0,
      arrCheck: [],
      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],
      oldData: [],
      deleteData: []
    }
  }

  componentDidMount() {
    this.index()
  }
  index() {
    let params = {
      Li_Format: this.props.Li_Format
    }

    ConvertTblSubAllAction.index(params)
      .then((res) => {

        const tmp = res.map(item => ({ ...item, changed: false }))

        this.setState({
          dataSource: tmp
        })

      })
  }

  /**
  * 検査項目検索・照会
  * @param {*} value
  */
  InspectItemSearchQuerySingle = (record, id, field, value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={record.exam_code}
            onFinishScreen={async (output) => {

              record.exam_code = output.recordData.test_item_code
              record.exam_name = output.recordData.exam_name
              record.test_result = output.recordData.test_result
              record.conversion_after_exam_value = output.recordData.conversion_after_exam_value
              record.exam_comment_code = output.recordData.exam_comment_code

              this.closeModal()

            }
            }
          />
        ),
      },
    })
  }

  // 削除
  delete = (record) => {

    ConvertTblSubAllAction.delete(record)
      .then((res) => {
        if (res) {
          this.index()
        }
      })

  }


  // 保存
  save(record) {

    let params = {
      data: this.state.dataSource,
      Li_Format: this.props.Li_Format
    }
    ConvertTblSubAllAction.save(record)
      .then((res) => {
        if (res) {
          this.index()
        }
      })
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * 新規追加、行追加
   */
  add = () => {

    const dataSource = [...this.state.dataSource]
    this.setState({ dataSource: [] })
    const newdata = {
      id: generateUUID(),
      exam_code: '',
      exam_name: '',
      test_result: '',
      conversion_after_exam_value: '',
      format: this.props.Li_Format,
      changed: true
    }
    dataSource.length > 0 ? dataSource.unshift(newdata) : dataSource.push(newdata)
    this.setState({ dataSource: dataSource })

  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  render() {
    return (
      <div className="convert-tbl-sub-all" style={{ width: 1000 }}>
        <Card title="変換テーブル[全て]" className="mb-2">

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table bordered
              size='small'
              dataSource={this.state.dataSource}
              loading={false}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(250) }}

            >
              <Table.Column
                title="検査コード"
                dataIndex="exam_code"
                className='column-size-20'
                render={(value, record, index) => (

                  <Input.Search
                    style={{ width: '100%', textAlign: 'right' }}
                    onSearch={() => {
                      this.InspectItemSearchQuerySingle(record)
                    }}
                    onPressEnter={() => { this.InspectItemSearchQuerySingle(record) }}
                    value={value}
                    onChange={(e) => {
                      if (record.exam_code !== e.target.value) {
                        record.exam_code = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }
                    }}
                  />
                )}
              />
              <Table.Column title="検査名称" dataIndex="exam_name" className='column-size-40'
                render={(value, record, index) => (
                  value
                )}
              />
              <Table.Column title="検査値" dataIndex="test_result" className='column-size-20'
                render={(value, record, index) => (
                  <Input
                    value={value}
                    onChange={(e) => {

                      if (record.test_result !== e.target.value) {
                        record.test_result = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }


                    }}
                  >
                  </Input>
                )}
              />
              <Table.Column title="変換後" dataIndex="conversion_after_exam_value" className='column-size-20'
                render={(value, record, index) => (

                  <Input
                    value={value}
                    onChange={(e) => {

                      if (record.conversion_after_exam_value !== e.target.value) {
                        record.conversion_after_exam_value = e.target.value
                        record.changed = true
                        this.forceUpdate()
                      }

                    }}
                  >
                  </Input>

                )} />

              <Table.Column title="コメントコード" dataIndex="exam_comment_code" className='column-size-20'
                render={(value, record, index) => (
                  value
                )} />

              <Table.Column
                width={40}
                align='center'
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      type='primary'
                      onClick={() => this.add()}
                      icon={<PlusOutlined />}
                    />
                  </div>
                }
                render={(value, record) => (
                  <Space>
                    <Button size='small' style={{ border: 'none', }}
                      icon={<SaveOutlined style={{ color: !record.changed ? 'gainsboro' : 'green' }} />}

                      disabled={!record.changed}
                      onClick={() => {
                        this.save(record)
                      }}
                    ></Button>

                    <Button size='small' style={{ border: 'none', }}
                      danger icon={<DeleteOutlined />}
                      onClick={() => { this.delete(record) }}
                    ></Button>
                  </Space>


                )}
              />
            </Table>

          </Form>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0452005_ConvertTblSubAll);
