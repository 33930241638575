import axios from 'configs/axios'

const API_LIST = {
  getListDataAPI: '/api/sanai-medical-checkup-calendar/dock-display-item-setting/get-list-data',
  GetLeftTableAPI: '/api/sanai-medical-checkup-calendar/dock-display-item-setting/get-left-table',
  GetRightTableAPI: '/api/sanai-medical-checkup-calendar/dock-display-item-setting/get-right-table',
  saveServiceAPI: '/api/sanai-medical-checkup-calendar/dock-display-item-setting/save',
}

const DockDisplayItemSettingService = {
  async getListDataService(params) {
    return axios.get(API_LIST.getListDataAPI, { params })
  },
  async getLeftTableService(params) {
    return axios.get(API_LIST.GetLeftTableAPI, { params })
  },
  async getRightTableService(params) {
    return axios.get(API_LIST.GetRightTableAPI, { params })
  },
  async saveService(params) {
    return axios.post(API_LIST.saveServiceAPI, params)
  },
}

export default DockDisplayItemSettingService
