import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-course/implement-location-query",
};

const GuideInputProvenSubService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
};

export default GuideInputProvenSubService;
