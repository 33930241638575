import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Select, Button, message, InputNumber } from "antd";
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { download_file } from "helpers/CommonHelpers";
import WS2788013_TargetSelectSub from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS2788013_TargetSelectSub.jsx';
import WS0435012_PreviewConfirm from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0435012_PreviewConfirm.jsx';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx';
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import ConsultInfoListAction from "redux/AdvancePreparation/ConsultInfoList/ConsultInfoList.action";
import moment from "moment";

const grid = {
  labelCol: { style: { width: 50 } }
};

class WS0643001_ConsultInfoList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '受診情報一覧表';

    this.state = {
      checkRadio: "1",
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      FacilitiesNums: [],
      isLoadingTable: false,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],

      OfficeFState: '',

      message: '',
      isSearch: false,
      random: null,
      visitData: null
    };
  }

  componentDidMount() {
    this.getDataScreen();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props !== prevProps) {
  //     this.getDataScreen();
  //   }
  // }

  getDataScreen() {
    ConsultInfoListAction.getScreenData()
      .then((res) => {
        this.setState({
          FacilitiesNums: res ? res : []
        })

        this.formRef.current?.setFieldsValue({
          FacilitiesNum: res ? res[0].LinkedField : ''
        })
      })
  }

  // 検索処理
  getDataBySearch() {
    this.setState({ isLoadingTable: true });

    let params = {
      ...this.formRef.current?.getFieldValue(),
      DateFChar: this.formRef.current?.getFieldValue('DateFChar')?.format('YYYY/MM/DD'),
      DateTChar: this.formRef.current?.getFieldValue('DateTChar')?.format('YYYY/MM/DD'),
    }

    ConsultInfoListAction.getDataBySearch(params)
      .then(async (res) => {
        await this.setState({
          visitData: res.data,
          message: res.message,
          isLoadingTable: false,
          isSearch: true,
          random: Math.random()
        })

        this.renderTargetSub()
      })
      .catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  // テーブルデータの取得
  renderTargetSub() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      random: this.state.random
    }
    return (
      <WS2788013_TargetSelectSub
        visitData={this.state.visitData}
        isSearch={this.state.isSearch}
        message={this.state.message}
        params={params}
        PrintType={1}
      />
    )
  }

  // 印刷処理
  EventF12(params) {
    let Preview = true;
    ConsultInfoListAction.PrintF12(params)
      .then(async (res) => {
        console.log(res);
        download_file(res, Preview);
      })
      .catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : null
    let dateEnd = this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : null
    if ((dateEnd && (dateStart > dateEnd)) || (dateEnd && !dateStart)) {
      this.formRef.current?.setFieldsValue({
        DateFChar: this.formRef.current?.getFieldValue('DateTChar')
      })
    }
  }

  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : null
    let dateEnd = this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : null
    if ((!dateEnd && dateStart) || (dateStart && (dateStart > dateEnd))) {
      this.formRef.current?.setFieldsValue({
        DateTChar: this.formRef.current?.getFieldValue('DateFChar')
      })
    }

    if ((!dateStart && dateEnd)) {
      this.formRef.current?.setFieldsValue({
        DateFChar: this.formRef.current?.getFieldValue('DateTChar')
      })
    }
  }

  checkCourseCode() {
    let VisitsCourseF = this.formRef.current?.getFieldValue('VisitsCourseF')
    let VisitsCourseT = this.formRef.current?.getFieldValue('VisitsCourseT')
    if (!VisitsCourseT || VisitsCourseF > VisitsCourseT) {
      this.formRef.current?.setFieldsValue({
        VisitsCourseT: VisitsCourseF,
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="consult-info-list">
        {/* <Card title="受診情報一覧表" className="mb-3">
          <Space>
            <Button
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 300,
                    component: (
                      <WS0435012_PreviewConfirm
                        onFinishScreen={(output) => {
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              }}
            >印刷</Button>
          </Space>
        </Card> */}

        <Card title="受診情報一覧表" className="mb-3">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              OutputCondition: 1,
              _Ampm: '全て',
              SubjectClassify: 1,
              PrintType: 1,
              DateFChar: moment(new Date()),
              DateTChar: moment(new Date())
            }}
          >
            <div className='box_inner_horizontal' style={{ marginBottom: 10 }}>
              <div className='box_search'>
                <div className='box_inner_horizontal'>
                  <div className='box_search_inner_border'>
                    <Form.Item
                      name='OutputCondition'
                      label="状態"
                      {...grid}
                    >
                      <Select style={{ width: 150 }}>
                        <Select.Option value={1}>予約 </Select.Option>
                        <Select.Option value={2}>受付</Select.Option>
                      </Select>
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label="日付"
                        name="DateFChar"
                        {...grid}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                          onBlur={() => { this.checkDateStart() }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="DateTChar"
                        label='~'
                        style={{ marginRight: '10px' }}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                          onBlur={() => { this.checkDateEnd() }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label="事業所"
                        name="OfficeF"
                        {...grid}
                      >
                        <Input.Search
                          style={{ textAlign: 'right' }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    onFinishScreen={(output) => {
                                      this.formRef.current?.setFieldsValue({
                                        OfficeF: output.Lio_OfficeCode,
                                        BranchStoreCodeF: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode
                                      })
                                      this.setState({ OfficeFState: output.Lio_OfficeCode })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onChange={(e) => {
                            this.formRef.current?.setFieldsValue({
                              OfficeF: e.target.value,
                              BranchStoreCodeF: ''
                            })
                            this.setState({ OfficeFState: e.target.value })
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="BranchStoreCodeF"
                      >
                        <InputNumber
                          className='custom-input-number'
                          readOnly={!this.state.OfficeFState}
                          onBlur={(e) => {
                            if (this.formRef.current?.getFieldValue('BranchStoreCodeF') == 0) {
                              this.formRef.current?.setFieldsValue({
                                BranchStoreCodeF: ''
                              })
                            }
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label="コース"
                        name="VisitsCourseF"
                        {...grid}
                      >
                        <Input.Search
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={(output) => {
                                      this.formRef.current?.setFieldsValue({
                                        VisitsCourseF: output.Lo_CourseCode
                                      })
                                      this.checkCourseCode()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onBlur={() => { this.checkCourseCode() }}
                        />
                      </Form.Item>

                      <Form.Item
                        label='~'
                        name="VisitsCourseT"
                      >
                        <Input.Search
                          style={{ width: '85%' }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={(output) => {
                                      this.formRef.current?.setFieldsValue({
                                        VisitsCourseT: output.Lo_CourseCode
                                      })
                                      this.checkCourseCode()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onBlur={() => { this.checkCourseCode() }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_inner'>
                    <Form.Item
                      name="_Ampm"
                      label="AMPM"
                      {...grid}
                    >
                      <Select  >
                        <Select.Option value="全て">全て</Select.Option>
                        <Select.Option value="ＡＭ">ＡＭ</Select.Option>
                        <Select.Option value="ＰＭ">ＰＭ</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="FacilitiesNum"
                      label="施設"
                      {...grid}
                    >
                      <Select >
                        {this.state.FacilitiesNums?.map(value => (
                          <Select.Option key={"FacilitiesNum_" + Math.random()} value={parseInt(value.LinkedField)}>{value.DisplayField}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="SubjectClassify"
                      label="対象者"
                      {...grid}
                    >
                      <Select >
                        <Select.Option value={1}>1:全員</Select.Option>
                        <Select.Option value={2}>2:追加/不要者</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="PrintType"
                      label="種別"
                      {...grid}
                    >
                      <Select >
                        <Select.Option value={1}>1:明細なし</Select.Option>
                        <Select.Option value={2}>2:明細あり</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className='box_search_btn'>
                <div className='box_search_btn_inner'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.getDataBySearch()
                    }}
                  > <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>
            </div>

            {/* テーブルの表示 */}
            <div style={{ marginTop: 10, marginBottom: 5 }}>
              {this.state.isLoadingTable ? this.renderTargetSub() : this.renderTargetSub()}
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type="primary"
                icon={<PrinterOutlined />}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 300,
                      component: (
                        <WS0435012_PreviewConfirm
                          onFinishScreen={(output) => {
                            let parram = {
                              OutputCondition: this.formRef.current?.getFieldValue('OutputCondition'),
                              PrintType: this.formRef.current?.getFieldValue('PrintType'),
                              SubjectClassify: this.formRef.current?.getFieldValue('SubjectClassify'),
                              facility_name: this.formRef.current?.getFieldValue('FacilitiesNum'),
                              PreviewMode: output.Lio_Preview,
                              Sys010Status: output.Lo_StsOutput,
                            }
                            this.EventF12(parram)
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  })
                }}
              >
                <span className='btn_label'>
                  印刷
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0643001_ConsultInfoList);
