import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from 'moment'
import { Card, Col, Row, Table, Menu, TimePicker, Form, Checkbox, Input, Popconfirm, message, Button, Modal } from "antd";
import { MoreOutlined, CheckOutlined } from "@ant-design/icons";
import MiraisReserveMaintainAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisReserveMaintain.actions'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const format = 'HH:mm'

class WS2752100_MiraisReserveMaintainEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // document.title = 'Mirais予約保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isloadding: false,
      isLoadding2: false,
      selectedRow1: {},
      count: "a",
      count2: "b",
      timeOutId: null
    };
  }

  componentDidMount() {
    if (!this.props.newFlag) {
      let copyData = { ...this.props.record }
      if (this.props.table === 2) {
        copyData.time_at = moment(copyData.time_at, format)
      }
      this.formRef.current?.setFieldsValue(copyData)
    }
  }

  save() {
    let getFieldValue = this.formRef.current?.getFieldValue()
    let params = {
      id: this.props?.record?.id ? this.props.record.id : null,
      reservations_department: getFieldValue?.reservations_department,
      reservations_item_code: getFieldValue?.reservations_item_code,
      reservations_act_code: getFieldValue?.reservations_act_code,
      name: getFieldValue?.name,
      enabled: getFieldValue?.enabled,
      reservations_comment: getFieldValue?.reservations_comment,
      remarks: getFieldValue?.remarks,
    }
    MiraisReserveMaintainAction.SaveData(params).then(res => {
      this.props.onFinishScreen()
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
    })
  }

  saveNum = () => {
    let getFieldValue = this.formRef.current?.getFieldValue()
    let time_at = getFieldValue?.time_at.format('HH:mm:ss')
    let params = {
      id: this.props?.record?.id ? this.props.record.id : null,
      reservations_department: this.props.rowSelect.reservations_department,
      reservations_item_code: this.props.rowSelect.reservations_item_code,
      DayOfWeek: this.props.rowSelect.DayOfWeek,
      time_at: time_at,
      number_frame: getFieldValue?.number_frame,
    }
    MiraisReserveMaintainAction.SaveDataNum(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        } else {
          this.props.onUpdate()
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました!');
          return;
        }
      })
  }

  delete() {
    //delete DB  
    MiraisReserveMaintainAction.Delete({ id: this.props.record.id }).then(res => {
      this.props.onFinishScreen()
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
    })
  }

  deleteNum() {
    //delete DB  
    MiraisReserveMaintainAction.DeleteNum({
      id: this.props.record.id, reservations_department: this.props.rowSelect.reservations_department,
      reservations_item_code: this.props.rowSelect.reservations_item_code
    }).then(res => {
      this.props.onFinishScreen()
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
    })
  }

  render() {
    return (
      <div className="mirais-reserve-maintain">
        <Card title={this.props.newFlag ? "Mirais予約保守追加" : "Mirais予約保守編集"}>
          <Form ref={this.formRef} autoComplete="off" >
            {this.props.table === 1 ?
              <div className="box_inner_vertical">
                <div className="box_inner_horizontal">
                  <div className="box_inner_vertical">
                    <div className="box_inner_horizontal">
                      <Form.Item label='科項目' name='reservations_department' {...smGrid}>
                        <Input className="input-size-number-2" />
                      </Form.Item>
                      <Form.Item name='reservations_item_code'>
                        <Input className="input-size-number-5" />
                      </Form.Item>
                    </div>
                    <Form.Item label='名称' name='name' {...smGrid}>
                      <Input className="input-size-40" />
                    </Form.Item>
                  </div>
                  <div className="box_inner_vertical">
                    <Form.Item label='行為' name='reservations_act_code' {...smGrid}>
                      <Input className="input-size-number-6" />
                    </Form.Item>
                    <Form.Item label='統合' name='enabled' valuePropName='checked' {...smGrid}>
                      <Checkbox />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item label='コメント' name='reservations_comment' {...smGrid}>
                  <Input className="input-size-60" />
                </Form.Item>
                <Form.Item label='備考' name='remarks' {...smGrid}>
                  <Input className="input-size-60" />
                </Form.Item>
              </div> :
              this.props.table === 2 ?
                <div className="box_inner_horizontal">
                  <Form.Item label='時間帯' name='time_at' {...smGrid}>
                    <TimePicker format={format} />
                  </Form.Item>
                  <Form.Item label='人数枠' name='number_frame'>
                    <Input className="input-size-number-5" />
                  </Form.Item>
                </div> :
                null
            }
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() =>
                  this.props.table === 1 ? this.save() : this.saveNum()
                }
              >保存</Button>
              {!this.props.newFlag ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.props.table === 1 ? this.delete() : this.deleteNum()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                  >
                    削除
                  </Button>
                </Popconfirm>
                : null
              }
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2752100_MiraisReserveMaintainEdit);
