import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Checkbox, Button, Row, Col, message, InputNumber, Popconfirm } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
// import ChangeSubAction from 'redux/InspectionMaintenance/InspectItemInfoMaintain/InspectItem1ChangeSub.action.js'
import { getScreenDataAction, deleteDataAction } from 'redux/InspectionMaintenance/InspectItemInfoMaintain/InspectItemInfoMaintain.actions'
import { loadInspectItemData, saveInspectItemAction } from 'redux/InspectionMaintenance/InspectItemInfoMaintain/InspectItem1ChangeSub.action'

import WS0253001_InternalInspectItemSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS0253001_InternalInspectItemSearchQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import { ModalError } from 'components/Commons/ModalConfirm'

class WS2717011_InspectItem1ChangeSub extends React.Component {
  static propTypes = {
    Lio_InspectCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '検査項目1件変更SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
      initObj: {},
      Lio_InspectCode: 0,
      InspectType: 'N',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.loadInitData({ Lio_InspectCode: this.props.Lio_InspectCode })
    console.log(this.props.record)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps !== this.props) {
  //     this.loadInitData({ Lio_InspectCode: this.props.Lio_InspectCode })
  //   }
  // }

  /**
   * 詳細データ取得
   * @param {*} params
   */
  loadInitData = (params) => {
    this.formRef?.current?.resetFields()

    loadInspectItemData(params)
      .then((res) => {
        let dataRes = res.data[0]
        if (dataRes) {
          let obj = {
            ...dataRes,
            InspectCode: dataRes?.InspectCode === 0 ? null : dataRes?.InspectCode,
            InternalInspectItemCode: dataRes?.InternalInspectItemCode === 0 ? null : dataRes?.InternalInspectItemCode,
            InspectCmtCode: dataRes?.InspectCmtCode === 0 ? null : dataRes?.InspectCmtCode,
          }
          this.setState({ initObj: obj, InspectType: obj.InspectType })
          this.formRef?.current?.setFieldsValue(obj)
          // バグ修正（コメントのデータが表示されない）
          this.formRef?.current?.setFieldsValue({ InspectCommentCode: dataRes?.InspectCommentCode === 0 ? null : dataRes?.InspectCommentCode })
          // 修正前
          // this.formRef?.current?.setFieldsValue({ InspectCommentCode: dataRes?.InspectCmtCode === 0 ? null : dataRes?.InspectCmtCode })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  RegisterBtn = (params) => {
    this.setState({ isLoading: true })
    saveInspectItemAction(params)
      .then(() => this.props.onFinishScreen && this.props.onFinishScreen())
      .catch(err => ModalError(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  openModal_WS0253001 = (option) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0253001_InternalInspectItemSearchQuery
            onFinishScreen={({ recordData }) => {
              if (option === 'InspectCode') {
                this.formRef?.current?.setFieldsValue({
                  InspectCode: recordData.internal_exam_code,
                  InspectName: recordData.exam_name,
                  InspectShortName: recordData.exam_short_name
                })
              } else {
                this.formRef?.current?.setFieldsValue({
                  InternalInspectItemCode: recordData.internal_exam_code,
                  InspectName: recordData.exam_name,
                  InspectShortName: recordData.exam_short_name
                })
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  onFinish() {
    let formData = this.formRef.current.getFieldsValue(true)
    let params = {
      ...formData,
      Lio_InspectCode: this.props.Lio_InspectCode,
      InternalInspectItemCode: formData.InternalInspectItemCode || 0,
      SignPresence: formData.SignPresence ? true : false,
      ExamType2: formData.ExamType2 ? true : false,
      DeterminingPresence: formData.DeterminingPresence ? true : false,
      item_code_external: formData.item_code_external || '',
      exam_name: formData.exam_name || ''
    }
    this.RegisterBtn(params)
  }

  /**
   * 削除処理
   * @returns
   */
  deleteData() {
    if (this.props.record.StsCategoryInspectBreakdown) {
      message.error('カテゴリー登録済みの検査は削除できません。')
      return
    }
    deleteDataAction({ test_item_code: this.props.Lio_InspectCode })
      .then(() => {
        message.success('削除しました')
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  render() {
    return (
      <div className='inspect-item1-change-sub' style={{ width: '600px' }}>
        <Card title={this.props.Lio_InspectCode ? '修正' : '新規登録'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{ InspectType: 'N', NumOfDecimalPlaces: 0 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name='InspectCode' label='　コード'>
                  <Input.Search
                    maxLength={8}
                    disabled={this.props.Lio_InspectCode}
                    onSearch={() => this.openModal_WS0253001('InspectCode')}
                  />
                </Form.Item>
              </Col>
              <Col span={12} hidden={!(this.props.Lio_InspectCode > 0)}>
                <Form.Item name='InternalInspectItemCode' label='内部コード' >
                  <Input.Search
                    maxLength={8}
                    readOnly={!(this.props.Lio_InspectCode > 0)}
                    onSearch={() => this.openModal_WS0253001('InternalInspectItemCode')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name='InspectName' label='検査名称'>
              <Input maxLength={30} />
            </Form.Item>

            <Form.Item name='InspectShortName' label='検査略名' >
              <Input maxLength={10} />
            </Form.Item>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label='　タイプ' name='InspectType'  >
                  <Select onChange={(value) => this.setState({ InspectType: value })}>
                    <Select.Option value='N'>数字</Select.Option>
                    <Select.Option value='X'>文字</Select.Option>
                    <Select.Option value='S'>所見</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='NumOfDecimalPlaces' label='小数'>
                  <Select disabled={!(this.state.InspectType === 'N')}>
                    <Select.Option value={0}>0</Select.Option>
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='SignPresence' label='桁　符号(+)' valuePropName='checked'>
                  <Checkbox disabled={!(this.state.InspectType === 'N')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label='日付変換' name='ExamType2' valuePropName='checked'>
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='自動判定' name='DeterminingPresence' valuePropName='checked'>
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label='コメント' name='InspectCommentCode'>
                  <Input.Search
                    maxLength={8}
                    disabled={!(this.state.InspectType === 'X' || this.state.InspectType === 'J' || this.state.InspectType === 'S')} //タイプ：文字と所見は活性にする
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 900,
                          component: (
                            <WS0274001_InspectCmtSearchQuery
                              // Lio_CmtClassify={this.state.initObj.InspectCommentCode}
                              Lio_CmtClassify={this.formRef.current.getFieldValue('InspectCommentCode')}
                              LnkOutInspectCmtScreen={''}
                              onFinishScreen={({ recordData }) => {
                                this.formRef.current.setFieldsValue({
                                  exam_comment_screen: recordData.exam_comment_screen,
                                  InspectCommentCode: recordData.comment_division
                                })
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name='exam_comment_screen'>
                  <Input bordered={false} readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name='Unit' label='　　単位' >
              <Input maxLength={10} />
            </Form.Item>

            <Row gutter={16}>
              <Col span={6} hidden={!(this.props.Lio_InspectCode > 0)}>
                <Form.Item label='外部検査' name='item_code_external'>
                  <InputNumber readOnly bordered={false} />
                </Form.Item>
              </Col>
              <Col span={18} hidden={!(this.props.Lio_InspectCode > 0)}>
                <Form.Item name='exam_name'>
                  <Input bordered={false} readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6} hidden={!(this.props.Lio_InspectCode > 0)}>
                <Form.Item label='検査依頼' name='Expression_34'>
                  <InputNumber readOnly bordered={false} />
                </Form.Item>
              </Col>
              <Col span={18} hidden={!(this.props.Lio_InspectCode > 0)}>
                <Form.Item name='exam_kanji_name_external'>
                  <Input bordered={false} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <div className='box_button_bottom_right'>

              {this.props.Lio_InspectCode ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.deleteData()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type='primary'
                    icon={<DeleteOutlined />}
                  >
                    <span className='btn_label'>
                      削除
                    </span>
                  </Button>
                </Popconfirm> : null}

              <Button
                type='primary'
                onClick={() => { this.onFinish() }}
                icon={<SaveOutlined />}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>

            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2717011_InspectItem1ChangeSub)
