import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Input, Table, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import QueryNameOfDiseaseAction from 'redux/InputBusiness/NotInputCheckCategory/QueryNameOfDisease.action'

class WS2320001_QueryNameOfDisease extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Lio_DiseaseNameCode: PropTypes.any,
    Li_SearchChar: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '病名照会'

    this.state = {
      dataSource: [],
      isLoading: false
    }
  }


  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.Li_DiseaseNameCode) {
      // 親画面からの病名コードを設定
      this.formRef.current?.setFieldsValue({ SearchChar: this.props.Li_DiseaseNameCode })
    }

    // データ取得
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    let params = {
      SearchChar: this.formRef.current?.getFieldValue('SearchChar')
    }
    this.setState({ isLoading: true })
    QueryNameOfDiseaseAction.index(params)
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
        })
      })
  }

  // onFinish(values) { }

  render() {
    return (
      <div className='query-name-of-disease'>
        <Card title='病名照会'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            // onFinish={this.onFinish}
            >
              <div className='box_inner_horizontal'>
                <Form.Item
                  label='検索'
                  name='SearchChar'
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    className='input-size-40'
                    onPressEnter={() => {
                      this.index()
                    }}
                  />
                </Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    this.index()
                  }}
                >
                  <span className='btn_label'>
                    検索
                  </span>
                </Button>
              </div>
            </Form>

            <Table
              size='small'
              bordered
              pagination={false}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              scroll={{ y: 500 }}
              onRow={(record, index) => {
                return {
                  onDoubleClick: async (event) => {
                    if (this.props.onFinishScreen) {
                      // 親画面に反映
                      this.props.onFinishScreen({
                        Lio_DiseaseNameCode: record.disease_name_code,
                        recordData: record
                      })
                    }
                  }
                }
              }}
            >
              <Table.Column
                title='病名コード'
                dataIndex='disease_name_code' width={100}
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column title='略称名' dataIndex='search_key' width={100} />
              <Table.Column title='病名' dataIndex='name_of_a_disease' />
            </Table>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2320001_QueryNameOfDisease)
