import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Form, Checkbox, Button, Row, Col } from 'antd'

import DisplayItemAction from 'redux/AccountingBusiness/DisplayItem/DisplayItem.action'

class WS1784001_TableComprehensiveMedicalExamReport extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Lio_DisplayItems: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '総合健診受診者報告'

    this.state = {
    }

    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    DisplayItemAction.getInit({ Lio_DisplayItems: this.props.Lio_DisplayItems ? this.props.Lio_DisplayItems : '111110001111111' }).then((res) => {
      this.formRef.current.setFieldsValue(res)
    })
  }

  onFinish(values) {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lio_DisplayItems: values,
      })
    }

  }

  render() {
    return (
      <div className='table-comprehensive-medical-exam-report'>
        <Card title='総合健診受診者報告'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item >
              <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '1em' }}>
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1784001_TableComprehensiveMedicalExamReport)
