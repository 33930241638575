import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Table } from 'antd'

import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import FdCreateMunicipalMutualAidAssociationAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/FdCreateMunicipalMutualAidAssociation.actions.js'


class WS1787002_FdCreateMunicipalMutualAidInquiry extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '区分選択'

    this.state = {
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount() {
    FdCreateMunicipalMutualAidAssociationAction.InquiryIndex()
      .then((res) => {
        this.setState({
          dataSource: res
        })
      })
  }

  render() {
    return (
      <div className='fd-create-municipal-mutual-aid-inquiry'>
        <Card
          title='市町村職員共済組合結果FD 区分選択'
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(250) }}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    this.props.onFinishScreen({
                      record: record,
                    })
                  }
                }
              }}
            >
              <Table.Column
                title='区分'
                dataIndex='division'
                className='column-size-5'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right', margin: 0 }}>{value}</div>
                  )
                }}
              />

              < Table.Column
                title='タイトル'
                dataIndex='title'
              />
            </Table>
          </Form>
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1787002_FdCreateMunicipalMutualAidInquiry)