import React from 'react'
import PropTypes from 'prop-types'

import { Card, Form, Input, Select, Button, message } from 'antd'

import ConfirmScreenAction from 'redux/basicInfo/ContractInfoMaintain/ConfirmScreen.action'
import WS0246001_InsurerInfoSearchQuery from '../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from '../V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0330001_ContractHistoricalQuerySub from './WS0330001_ContractHistoricalQuerySub'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'

/**
* @extends {React.Component<{Li_ContractTypeF:any, Li_ContractOrgCodeF:any, Li_ContractStartDateF:any, Li_ContractTypeT:any, Li_ContractOrgCodeT:any, Li_ContractStartDateT:any}>}
*/
class WS0316026_ConfirmScreen extends React.Component {
  static propTypes = {
    Li_ContractTypeF: PropTypes.any,
    Li_ContractOrgCodeF: PropTypes.any,
    Li_ContractStartDateF: PropTypes.any,

    Li_ContractTypeT: PropTypes.any,
    Li_ContractOrgCodeT: PropTypes.any,
    Li_ContractStartDateT: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '確認画面'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      screenData: {},
    }

    this.loadScreenData = this.loadScreenData.bind(this)
    this.getFormFieldValue = this.getFormFieldValue.bind(this)

  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.loadScreenData()
  }

  loadScreenData() {

    ConfirmScreenAction.getScreenData()
      .then(res => {
        this.setState({
          screenData: res.data,
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  getFormFieldValue(namePath) {
    return this.formRef.current?.getFieldValue(namePath)
  }

  /**
   * 複写　実行
   * @param {*} values
   * @returns
   */
  copyExec(values) {
    if (values.ContractTypeT === 0) {
      message.error('共通は複写対象外です。')
      return
    }
    let params = {
      ...values,
      selectedRows: this.props.selectedRows
    }
    ConfirmScreenAction.CopyExec(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen(res)
          }
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  /**
   * 入力した値から事業所名か保険者を取る
   * @param {*} value 
   */
  getContractName(value) {
    let ContractTypeT = this.getFormFieldValue('ContractTypeT')
    let params = {
      ContractTypeT: this.getFormFieldValue('ContractTypeT'),
      value: value
    }
    ConfirmScreenAction.getContractName(params)
      .then(res => {
        if (ContractTypeT === 1) {
          this.formRef.current.setFieldsValue({
            contract_name: res.data?.StsInsurer?.insurer_kanji_name
          })
        } else if (ContractTypeT === 2) {
          this.formRef.current.setFieldsValue({
            contract_name: res.data?.StsOffice?.office_kanji_name
          })
        }
        this.forceUpdate()
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })

  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='confirm-screen'>
        <Card title='確認画面'>
          <Form
            ref={this.formRef}
            initialValues={{
              ContractTypeT: this.props.Li_ContractTypeT,
              ContractOrgCodeT: this.props.Li_ContractOrgCodeT,
              ContractStartDateT: this.props.Li_ContractStartDateT,
              Li_ContractTypeF: this.props.Li_ContractTypeF,
              Li_ContractOrgCodeF: this.props.Li_ContractOrgCodeF,
              Li_ContractStartDateF: this.props.Li_ContractStartDateF,
              Li_ContractTypeT: this.props.Li_ContractTypeT,
              Li_ContractOrgCodeT: this.props.Li_ContractOrgCodeT,
              Li_ContractStartDateT: this.props.Li_ContractStartDateT,
            }}
          >
            <Input.Group style={{ display: 'none' }}>
              <Form.Item name='Li_ContractTypeF'><Input /></Form.Item>
              <Form.Item name='Li_ContractOrgCodeF'><Input /></Form.Item>
              <Form.Item name='Li_ContractStartDateF'><Input /></Form.Item>
              <Form.Item name='Li_ContractTypeT'><Input /></Form.Item>
              <Form.Item name='Li_ContractOrgCodeT'><Input /></Form.Item>
              <Form.Item name='Li_ContractStartDateT'><Input /></Form.Item>
              <Form.Item name='ContractNameT'><Input /></Form.Item>
            </Input.Group>

            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <Form.Item label='契　約' name='ContractTypeT'>
                  <Select
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e === 0) {
                        this.formRef.current.setFieldsValue({
                          ContractOrgCodeT: 0,
                          ContractTypeT: e,
                          contract_name: ''
                        })
                        this.forceUpdate()
                      } else {
                        this.formRef.current.setFieldsValue({
                          ContractTypeT: e,
                          ContractOrgCodeT: 0,
                          contract_name: ''
                        })
                        this.forceUpdate()
                      }
                    }}>
                    {this.state.screenData.ContractTypeTChar?.map(value => (
                      <Select.Option
                        key={`ContractTypeTChar-${value.node_code_name}`}
                        value={parseInt(value.node_code_name)}
                      >
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name='ContractOrgCodeT'>
                  <Input.Search
                    disabled={this.formRef.current?.getFieldValue('ContractTypeT') === 0}
                    allowClear={true}
                    onPressEnter={(e) => {
                      this.getContractName(e.target.value)
                    }}
                    onSearch={(value, e) => {
                      const ContractTypeT = this.getFormFieldValue('ContractTypeT')
                      let component = null
                      if (e.target.localName === 'input') {
                        this.formRef.current?.setFieldsValue({
                          contract_name: ''
                        })
                        this.forceUpdate()
                      } else {
                        if (ContractTypeT === 1) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              centered: true,
                              component: (
                                <WS0246001_InsurerInfoSearchQuery
                                  Li_insurerSearch={value}
                                  onFinishScreen={(output) => {
                                    console.log(output);
                                    this.formRef.current.setFieldsValue({
                                      ContractOrgCodeT: output.Lo_InsurerCode,
                                      contract_name: output.Lo_Name
                                    })
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                        } else if (ContractTypeT === 2) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              centered: true,
                              width: 1200,
                              component: (
                                <WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={value}
                                  onFinishScreen={(output) => {
                                    console.log(output);
                                    this.formRef.current.setFieldsValue({
                                      ContractOrgCodeT: output.Lio_OfficeCode,
                                      contract_name: output.Lo_Kanji_Name
                                    })
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                        }

                        if (component) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              width: 800,
                              visible: true,
                              component: component,
                            }
                          })
                        }
                      }
                    }} />
                </Form.Item>
                <span style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '180px',
                  overflow: 'hidden',
                  marginTop: '5px'
                }}>{this.formRef.current?.getFieldValue('contract_name')}</span>
              </div>
              <div className='box_inner_horizontal'>
                <Form.Item label='履　歴' name='ContractStartDateT'>
                  <Input.Search onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        width: 800,
                        visible: true,
                        component: (<WS0330001_ContractHistoricalQuerySub
                          Li_ContractType={this.getFormFieldValue('ContractTypeT')}
                          Li_ContractOrgCode={this.getFormFieldValue('ContractOrgCodeT')}

                          onFinishScreen={({ Lo_ContractStartDate, recordData }) => {
                            this.formRef.current.setFieldsValue({
                              ContractStartDateT: moment(Lo_ContractStartDate).format('YYYY/MM/DD'),
                              ContractNameT: recordData.contract_name,
                              contract_histories: {
                                contract_name: recordData.contract_name,
                              },
                            })

                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              }
                            })
                          }}
                        />),
                      }
                    })
                  }} />
                </Form.Item>
                <Form.Item>
                  <span style={{ padding: '15px' }}>
                    {this.formRef.current?.getFieldValue(['contract_histories', 'contract_name'])}
                  </span>
                </Form.Item>
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => this.copyExec(this.formRef.current?.getFieldValue())}
            >
              <span className='btn_label'>
                実行
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

export default WS0316026_ConfirmScreen
