import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Table, message, Form, Input, Button } from 'antd'
import { getDataAffiliationSelectSubAction } from 'redux/ResultOutput/RomotoArticle52/AffiliationSelectSub.actions'

class WS2787004_AffiliationSelectSub extends React.Component {

  static propTypes = {
    Li_OfficeCode: PropTypes.any,
    Li_BranchStoreCode: PropTypes.any,
    Lio_AffiliationCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '所属選択';

    this.state = {
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},

      /**
       * 検索用コード
       * @type {string}
       */
      searchCode: '',
      /**
       * 検索用略称
       * @type {string}
       */
      searchShortName: '',
      /**
       * 検索用正式名称
       * @type {string}
       */
      searchName: '',
    };
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      searchCode: this.props.workplace_code ?? ''
    })
    this.loadData();
  }

  /**
   * データ取得
   */
  loadData = () => {
    const params = {
      Li_OfficeCode: this.props.Li_OfficeCode || '',
      Li_BranchStoreCode: this.props.Li_BranchStoreCode || '',
      Li_Code: this.props.workplace_code ? this.props.workplace_code : this.state.searchCode ?? '',
      Li_ShortName: this.state.searchShortName,
      Li_Name: this.state.searchName,
    };
    this.setState({ isLoading: true });
    getDataAffiliationSelectSubAction(params)
      .then(res => {
        if (res?.data) {
          this.setState({
            dataSource: res.data,
            isLoading: false
          })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  /**
   * 選択された所属データを返却
   * @param {*} record
   */
  returnValue = (record) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lio_AffiliationCode: record.affiliation_code,
        recordData: record
      })
    }
  }

  /**
   * 検索対象コード入力時の処理 stateの更新
   * @param {string} val 入力値
   */
  onChangeSearchCode(val) {
    this.setState({ searchCode: val !== null ? val : '' });
  }
  /**
   * 検索対象略称入力時の処理 stateの更新
   * @param {string} val 入力値
   */
  onChangeSearchShortName(val) {
    this.setState({ searchShortName: val !== null ? val : '' });
  }
  /**
   * 検索対象正式名称入力時の処理 stateの更新
   * @param {string} val 入力値
   */
  onChangeSearchName(val) {
    this.setState({ searchName: val !== null ? val : '' });
  }

  render() {
    return (
      <div className='affiliation-select-sub'>
        <Card title='所属選択'>
          <Form>
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <Form.Item>
                  <Input className="input-size-5"
                    placeholder="コード"
                    value={this.state.searchCode}
                    onChange={(e) => {
                      this.onChangeSearchCode(e.target.value)
                    }}
                  />
                </Form.Item>

                <Form.Item>
                  <Input className="input-size-10"
                    placeholder="略称"
                    value={this.state.searchShortName}
                    onChange={(e) => {
                      this.onChangeSearchShortName(e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Input className="input-size-20"
                    placeholder="正式名称"
                    value={this.state.searchName}
                    onChange={(e) => {
                      this.onChangeSearchName(e.target.value)
                    }}
                  />
                </Form.Item>

                <Button
                  onClick={() => {
                    this.loadData();
                  }}>
                  検索
                </Button>

              </div>
            </div>
          </Form>

          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={{
              ...this.state.pagination,
              hideOnSinglePage: this.state.dataSource.length > 10 ? false : true
            }}
            rowKey={(record) => record.affiliation_code}
            onRow={(record) => ({
              onClick: (event) => {
                this.setState({ rowSelect: record })
              },
              onDoubleClick: (event) => {
                // 選択された所属情報を親画面に反映
                this.returnValue(record)
              }
            })}
          >
            <Table.Column title='コード' dataIndex='affiliation_code'
              render={(text) => (<div style={{ textAlign: 'right' }}>{text}</div>)}
            />
            <Table.Column title='略称' dataIndex='short_name' />
            <Table.Column title='正式名称' dataIndex='formal_name' />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2787004_AffiliationSelectSub);
