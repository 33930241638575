import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Space, Button, Input, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import DepartmentMasterAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/DepartmentMaster.action'
import WS0887001_IntroduceLetterVariousMasterInquiry from '../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import WS0922009_DepartmentMasterEdit from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922009_DepartmentMasterEdit'

class WS0922009_DepartmentMaster extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '診療科(検査部門)マスタ'
    this.formRef = React.createRef()
    this.state = {
      childModal: {
        width: 0,
        visible: false,
        component: null
      },

      selectedRowIndex: 0,
      allValues: [],
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    let params = {
      LnkInmanageCode: 7
    }
    DepartmentMasterAction.index(params)
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  // onFinish = (values) => {
  //   const params = values
  //   console.log('pa->', params)
  //   this.callAPILoadData(params)
  // }

  // callAPILoadData = (params) => {
  //   this.setState({ isLoadingTable: true })
  //   VenusApiRoutesV2.callApi('API000922009013', params)
  //     .then(res => {
  //       console.log('res-2->', res)
  //       this.index()
  //     })
  //     .catch(err => { })
  //     .finally(() => this.setState({ isLoadingTable: false }))
  // }

  // updateDatasource(index, field, value) {
  //   let mainSource = this.formRef.current?.getFieldValue('mainSource') ? this.formRef.current?.getFieldValue('mainSource') : []
  //   let data = [...mainSource]

  //   data[index][field] = value

  //   // this.setState({
  //   //   dataSource: data,
  //   // })

  //   this.formRef.current?.setFieldsValue({
  //     mainSource: data,
  //   })
  // }

  //api/introduce-letter-master-maintain/department-master/save
  // API000922009013

  // updateData = (record) => {
  //   let params = {
  //     mainSource : [{
  //       department_name: record.department_name,
  //       id: record.id,
  //       // management_code: record.management_code,
  //       // medical_institution_name: record.medical_institution_name,
  //       medical_institutions_cd: record.medical_institutions_cd,
  //       remarks: record.remarks,
  //       search_key: record.search_key,
  //       various_codes: record.various_codes,
  //     }]
  //   }
  //   VenusApiRoutesV2.callApi('API000922009013', params)
  //     .then(res => {
  //       console.log('res-2->', res)
  //       this.index()
  //     })
  //     .catch(err => { })
  //     .finally(() => this.setState({ isLoadingTable: false }))
  // }


  // api/introduce-letter-master-maintain/department-master/delete
  //API000922009014
  // deleteData = (record) => {
  //   let params = {
  //     mainSource: [{
  //       department_name: record.department_name,
  //       id: record.id,
  //       // management_code: record.management_code,
  //       // medical_institution_name: record.medical_institution_name,
  //       medical_institutions_cd: record.medical_institutions_cd,
  //       remarks: record.remarks,
  //       search_key: record.search_key,
  //       various_codes: record.various_codes,
  //     }]
  //   }
  //   if (params) {
  //     VenusApiRoutesV2.callApi('API000922009014', params)
  //       .then(res => {
  //         console.log('res-2->', res)
  //         this.index()
  //       })
  //       .catch(err => { })
  //       .finally(() => this.setState({ isLoadingTable: false }))
  //   }
  // }

  showIntroduceLetterVariousMasterInquiry = (record, index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: '80%',
        visible: true,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={4}
            Lo_VariousCodes={record.medical_institutions_cd}
            onFinishScreen={(output) => {
              console.log('outoutput->', output)
              this.updateDatasource(index, 'medical_institutions_cd', output?.Lo_VariousCodes)
              this.updateDatasource(index, 'medical_institution_name', output?.recordData.medical_institution_name)
              this.closeModal()
            }
            }
          />
        )
      }
    })
  }

  // appendRow = () => {
  //   this.setState({ isLoadingTable: true })
  //   let mainSource = this.formRef.current?.getFieldValue('mainSource') ? this.formRef.current?.getFieldValue('mainSource') : []
  //   let newItem = {
  //     department_name: '',
  //     id: '',
  //     medical_institutions_cd: '',
  //     remarks: '',
  //     search_key: '',
  //     various_codes: 0,
  //   }

  //   mainSource.splice(parseInt(this.state.selectedRowIndex) + 1, 0, newItem)
  //   this.formRef.current?.setFieldsValue({
  //     mainSource: mainSource,
  //   })
  //   this.setState({ isLoadingTable: false })
  // }

  /**
   * 保存ボタン処理
   */
  save = (output) => {
    const params = {
      LnkInmanageCode: 7,
      ...output.itemChange
    }
    DepartmentMasterAction.save(params)
      .then(res => {
        if (res) {
          // データ再取得
          this.index()
          this.closeModal()
        }
      })
  }

  /**
   * 削除ボタン処理
   */
  delete = (output) => {
    const params = {
      LnkInmanageCode: 7,
      id: output.itemChange.id
    }
    DepartmentMasterAction.delete(params)
      .then(res => {
        if (res) {
          // データ再取得
          this.index()
          this.closeModal()
        }
      })
  }


  /**
   * 診療科(検査部門)照会　編集
   * @param {*} record
   */
  showWS0922009_DepartmentMasterEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: 800,
        visible: true,
        component: (
          <WS0922009_DepartmentMasterEdit
            record={record}
            onFinishScreen={(output) => {
              // データ再取得
              this.index()
              this.closeModal()
            }
            }
          />
        )
      }
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='department-master'>
        <Card title='診療科(検査部門)マスタ'>
          {/* <Form
            ref={this.formRef}
            onValuesChange={(changedValues, allValues) => this.setState({ allValues })}
            onFinish={this.onFinish}
          > */}
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            scroll={{ y: 400 }}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  // 編集、削除
                  this.showWS0922009_DepartmentMasterEdit(record)
                }
              }
            }}
          >
            <Table.Column
              title='コード'
              dataIndex='various_codes'
              width={100}
            />
            <Table.Column
              title='検索キー'
              dataIndex='search_key'
              width={120}
            />
            <Table.Column
              title='診療科(検査部門)'
              dataIndex='department_name'
            />
            <Table.Column
              title='医療機関名称'
              dataIndex='medical_institution_name'
            />
            {/* <Table.Column
              title='備考'
              dataIndex='remarks'
            /> */}
            {/* 三愛用に追加 */}
            <Table.Column
              title='受診科'
              dataIndex='remarks'
            />
            <Table.Column
              title='セット情報'
              dataIndex='setInfo'
              render={(value) => {
                let setText = ''
                value.forEach((item, index) => {
                  setText += item.set_short_name
                  if (index < (value.length - 1)) {
                    setText += '/'
                  }
                })

                return (
                  <div>{setText}</div>
                )
              }}
            />
            <Table.Column
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() =>
                    // 新規作成
                    this.showWS0922009_DepartmentMasterEdit()
                  }
                />
              }
              width={40}
              align='center'
              render={(value, record) => (
                <Dropdown
                  trigger='click'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='1'
                        onClick={() =>
                          // 編集、削除
                          this.showWS0922009_DepartmentMasterEdit(record)
                        }
                      >
                        変更
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            />
          </Table>
          {/* </Form> */}
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0922009_DepartmentMaster)
