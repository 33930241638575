import { message } from 'antd'
import IntroduceLetterVariousMasterInquiryService from 'services/IntroductionLetter/AskIssued/IntroduceLetterVariousMasterInquiryService'


const IntroduceLetterVariousMasterInquiryAction = {
  getInspectMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getInspectMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getTreatmentMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getTreatmentMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getVictimMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getVictimMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getDoctorMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getDoctorMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getMedicalInstitutionMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getMedicalInstitutionMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getDepartmentMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getDepartmentMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getFindingsMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getFindingsMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getExamDepartmentMaster(data) {
    return IntroduceLetterVariousMasterInquiryService.getExamDepartmentMaster(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default IntroduceLetterVariousMasterInquiryAction
