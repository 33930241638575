import React from "react";
import { connect } from "react-redux";
import { Card, Form, Checkbox, Button } from "antd";
import { RedoOutlined, UnlockOutlined } from '@ant-design/icons'
import ModalDraggable from "components/Commons/ModalDraggable";

class CheckupCalendarDetailSearch extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "人間ドックカレンダー 詳細検索";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      examItemList: [],
      detailConditions: []
    };
  }

  componentDidMount() {
    let detailConditions = this.props.detailConditions.split(",")

    for (let i = 0; i < 20; i++) {
      if (detailConditions[i] == '1') {
        this.formRef.current.setFieldsValue({
          ['exam' + i]: true
        })
      }
    }

    this.setState({
      examItemList: this.props.examItemList
    })
  }

  change() {
    let values = this.formRef.current.getFieldsValue()
    let itemJudgement = ''
    let nameList = ''
    let name = ''

    for (let i = 0; i < 20; i++) {
      name = 'exam' + i

      if (values[name] == true) {
        itemJudgement = itemJudgement + '1,'
        nameList = nameList + this.state.examItemList[i] + '・'
      } else {
        itemJudgement = itemJudgement + '0,'
      }
    }

    itemJudgement = itemJudgement.slice(0, -1);
    nameList = nameList.slice(0, -1);

    if (itemJudgement == '0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0') {
      this.release()
    } else {
      this.props.onFinishScreen(itemJudgement, nameList)
    }
  }

  release() {
    this.props.onRelease()
  }

  render() {
    return (
      <div className="checkup-calendar-detail-search">
        <Card title="人間ドックカレンダー 詳細検索">
          <Form
            ref={this.formRef}
          >
            {
              this.state.examItemList.map((item, index) => (
                <Form.Item
                  name={'exam' + index}
                  style={{ display: item == '' ? 'none' : '', marginLeft: 20 }}
                  key={index}
                  valuePropName="checked"
                >
                  <Checkbox>
                    {item}
                  </Checkbox>
                </Form.Item>
              ))
            }

            <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
              <Button
                danger
                icon={<UnlockOutlined />}
                type="primary"
                style={{ float: 'right', marginTop: '1em' }}
                onClick={() => {
                  this.release()
                }}
              >
                <span className='btn_label'>
                  条件解除
                </span>
              </Button>

              <Button
                icon={<RedoOutlined />}
                type="primary"
                style={{ float: 'right', marginTop: '1em' }}
                onClick={() => {
                  this.change()
                }}
              >
                <span className='btn_label'>
                  再表示
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          destroyOnClose={true}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}


const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckupCalendarDetailSearch);