/* eslint-disable eqeqeq */
import React from "react";
import PropTypes from "prop-types";

import { Card, Form, Input, Radio, Button, Row, Col, Select, InputNumber, message, Spin, ConfigProvider } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { number_format } from "helpers/CommonHelpers";
import WS0302001_SetInfoSearch from './WS0302001_SetInfoSearch.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import { getNumberOfCodeAction } from "redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions.js";
import axios from "configs/axios";

const smGrid = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const smGrid2 = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderLeft: 0,
  borderBottom: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const columnTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  margin: 0,
  textAlign: 'right',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}

class WS0331020_BatchUpdateProcessAdd extends React.Component {
  static propTypes = {
    Li_TaxClassify: PropTypes.any,
    Li_Rounding: PropTypes.any,
    Li_TaxRate: PropTypes.any,
    Li_OtherGroupDivision: PropTypes.any,
    Li_Title: PropTypes.any,
    Lio_InsurerUnitPriceAmount: PropTypes.any,
    Lio_InsurerTax: PropTypes.any,
    Lio_InsurerTotal: PropTypes.any,
    Lio_OfficeUnitPriceAmount: PropTypes.any,
    Lio_OfficeTax: PropTypes.any,
    Lio_OfficeTotal: PropTypes.any,
    Lio_OtherGroupUnitPriceAmount: PropTypes.any,
    Lio_OtherGroupTax: PropTypes.any,
    Lio_OtherGroupTotal: PropTypes.any,
    Lio_Personal1UnitPriceAmount: PropTypes.any,
    Lio_Personal1Tax: PropTypes.any,
    Lio_Personal1Total: PropTypes.any,
    Lio_Personal2UnitPriceAmount: PropTypes.any,
    Lio_Personal2Tax: PropTypes.any,
    Lio_Personal2Total: PropTypes.any,
    Lio_Personal3UnitPriceAmount: PropTypes.any,
    Lio_Personal3Tax: PropTypes.any,
    Lio_Personal3Total: PropTypes.any,
    Lio_StsChange: PropTypes.any,
    Li_Protection: PropTypes.any,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "金額入力";

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      Li_Protection: props.Li_Protection || "      ",
      shortName: null,
      saveFlag: true
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.loadScreenData();
    }
  }

  loadScreenData = () => {
    this.setState({ isLoading: true });

    this.setFormFieldsValue(this.props);
    this.setState({
      shortName: this.props.set_short_name
    })
    this.totalReset()
    this.setState({ isLoading: false });
  };

  TaxCalculateBtn = (callback) => {
    this.setState({ isLoading: true });

    axios
      .post(
        "/api/personal-reserve-process/money-amount-input-sub/TaxCalculateBtn",
        this.formRef.current.getFieldsValue()
      )
      .then((res) => {
        this.setFormFieldsValue(res.data);
        this.setFormFieldsValue({
          Lio_StsChange: true,
        });

        if (callback) {
          callback();
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }));
  };

  /**
   * 指定
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxDesignated(e, name, taxName, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    let unitPrice = this.getFormFieldValue(name)
    let tax = this.getFormFieldValue(taxName)
    let allTotal, result, taxResult
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(unitPrice)
        // 消費税
        taxResult = Math.round(tax)
        allTotal = parseInt(result) + parseInt(taxResult)
        break;
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(unitPrice)
        // 消費税
        taxResult = Math.floor(tax)
        allTotal = result + taxResult
        break;
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(unitPrice)
        // 消費税
        taxResult = Math.ceil(tax)
        allTotal = result + taxResult
        break;
    }

    this.formRef.current?.setFieldsValue({ [name]: result, [taxName]: taxResult, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 外税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxExclusive(e, name, taxName, totalName) {
    // 消費税
    let taxCalculation = this.getFormFieldValue('TaxRate')
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    let value = e.target.value
    let result, taxResult, allTotal
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(value)
        // 消費税
        taxResult = Math.round(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break;
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(value)
        // 消費税
        taxResult = Math.floor(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break;
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(value)
        // 消費税
        taxResult = Math.ceil(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break;
    }

    this.formRef.current?.setFieldsValue({ [name]: result, [taxName]: taxResult, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 内税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxInclusive(e, name, taxName, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    // 消費税
    let taxCalculation = this.getFormFieldValue('TaxRate')
    let tax = this.getFormFieldValue('TaxRate') + 1
    let unitPrice, taxValue
    switch (list) {
      case 0:
        // 四捨五入
        // 消費税
        taxValue = Math.round(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break;
      case 1:
        // 切り捨て
        // 消費税
        taxValue = Math.floor(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break;
      case 2:
        // 切り上げ
        // 消費税
        taxValue = Math.ceil(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break;
    }

    this.formRef.current?.setFieldsValue({ [name]: unitPrice, [taxName]: taxValue, [totalName]: e.target.value })
    this.totalReset()
  }

  /**
   * 非課税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} totalName 合計の名前
   */
  taxExemption(e, name, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    let unitPrice = this.getFormFieldValue(name)
    let allTotal, result
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(unitPrice)
        // 合計
        allTotal = result
        break;
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(unitPrice)
        // 合計
        allTotal = result
        break;
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(unitPrice)
        // 合計
        allTotal = result
        break;
    }

    this.formRef.current?.setFieldsValue({ [name]: result, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 合計値再設定
   */
  totalReset() {
    // 合計の値を設定しなおす
    // 単価合計
    let unitTotal = number_format(
      (this.getFormFieldValue('Lio_InsurerUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OfficeUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal1UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal2UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal3UnitPriceAmount') || 0)
    )

    // 消費税合計
    let taxTotal = number_format(
      (this.getFormFieldValue('Lio_InsurerTax') || 0) +
      (this.getFormFieldValue('Lio_OfficeTax') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupTax') || 0) +
      (this.getFormFieldValue('Lio_Personal1Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal2Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal3Tax') || 0)
    )

    // 全ての合計
    let totalNumber = number_format(
      (this.getFormFieldValue('Lio_InsurerUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OfficeUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal1UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal2UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal3UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_InsurerTax') || 0) +
      (this.getFormFieldValue('Lio_OfficeTax') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupTax') || 0) +
      (this.getFormFieldValue('Lio_Personal1Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal2Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal3Tax') || 0)
    )

    this.setFormFieldsValue({ unitPriceTotal: unitTotal, taxTotal: taxTotal, allTotal: totalNumber });
    this.forceUpdate()
  }

  getStsTaxChange(e, name, taxName, totalName) {
    let taxType = this.getFormFieldValue("TaxClassify")
    if (taxType === 0) {
      // 指定
      this.taxDesignated(e, name, taxName, totalName)
    } else if (taxType === 1) {
      // 外税
      this.taxExclusive(e, name, taxName, totalName)
    } else if (taxType === 2) {
      // 内税
      this.taxInclusive(e, name, taxName, totalName)
    } else if (taxType === 3) {
      // 非課税
      this.taxExemption(e, name, totalName)
    }
  }

  /**
   * 四捨五入
   * @returns
   */
  rounding() {
    let taxType = this.getFormFieldValue("TaxClassify")
    if (taxType === 0 || taxType === 3) {
      // 指定 非課税
      return
    } else if (taxType === 1) {
      // 外税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.round(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.round(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.round(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.round(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.round(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.round(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 合計
      let insurerTotal = this.getFormFieldValue('Lio_InsurerUnitPriceAmount') + insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeUnitPriceAmount') + officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') + otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1UnitPriceAmount') + personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2UnitPriceAmount') + person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3UnitPriceAmount') + personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
        Lio_InsurerTotal: insurerTotal,
        Lio_OfficeTotal: officeTotal,
        Lio_OtherGroupTotal: otherGroupTotal,
        Lio_Personal1Total: personal1Total,
        Lio_Personal2Total: person2Total,
        Lio_Personal3Total: personal3Total
      })
    } else if (taxType === 2) {
      // 内税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.round(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.round(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.round(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.round(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.round(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.round(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 単価
      let insurerTotal = this.getFormFieldValue('Lio_InsurerTotal') - insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeTotal') - officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupTotal') - otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1Total') - personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2Total') - person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3Total') - personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerUnitPriceAmount: insurerTotal,
        Lio_OfficeUnitPriceAmount: officeTotal,
        Lio_OtherGroupUnitPriceAmount: otherGroupTotal,
        Lio_Personal1UnitPriceAmount: personal1Total,
        Lio_Personal2UnitPriceAmount: person2Total,
        Lio_Personal3UnitPriceAmount: personal3Total,
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
      })
    }

    this.totalReset()
  }

  /**
   * 切り捨て
   */
  roundingDown() {
    let taxType = this.getFormFieldValue("TaxClassify")
    if (taxType === 0 || taxType === 3) {
      // 指定 非課税
      return
    } else if (taxType === 1) {
      // 外税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.floor(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.floor(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.floor(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.floor(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.floor(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.floor(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 合計
      let insurerTotal = this.getFormFieldValue('Lio_InsurerUnitPriceAmount') + insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeUnitPriceAmount') + officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') + otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1UnitPriceAmount') + personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2UnitPriceAmount') + person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3UnitPriceAmount') + personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
        Lio_InsurerTotal: insurerTotal,
        Lio_OfficeTotal: officeTotal,
        Lio_OtherGroupTotal: otherGroupTotal,
        Lio_Personal1Total: personal1Total,
        Lio_Personal2Total: person2Total,
        Lio_Personal3Total: personal3Total
      })
    } else if (taxType === 2) {
      // 内税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.floor(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.floor(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.floor(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.floor(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.floor(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.floor(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 単価
      let insurerTotal = this.getFormFieldValue('Lio_InsurerTotal') - insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeTotal') - officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupTotal') - otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1Total') - personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2Total') - person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3Total') - personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerUnitPriceAmount: insurerTotal,
        Lio_OfficeUnitPriceAmount: officeTotal,
        Lio_OtherGroupUnitPriceAmount: otherGroupTotal,
        Lio_Personal1UnitPriceAmount: personal1Total,
        Lio_Personal2UnitPriceAmount: person2Total,
        Lio_Personal3UnitPriceAmount: personal3Total,
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
      })
    }

    this.totalReset()
  }

  /**
   * 切り上げ
   */
  roundingUp() {
    let taxType = this.getFormFieldValue("TaxClassify")
    if (taxType === 0 || taxType === 3) {
      // 指定 非課税
      return
    } else if (taxType === 1) {
      // 外税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.ceil(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.ceil(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.ceil(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.ceil(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.ceil(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.ceil(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 合計
      let insurerTotal = this.getFormFieldValue('Lio_InsurerUnitPriceAmount') + insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeUnitPriceAmount') + officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') + otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1UnitPriceAmount') + personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2UnitPriceAmount') + person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3UnitPriceAmount') + personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
        Lio_InsurerTotal: insurerTotal,
        Lio_OfficeTotal: officeTotal,
        Lio_OtherGroupTotal: otherGroupTotal,
        Lio_Personal1Total: personal1Total,
        Lio_Personal2Total: person2Total,
        Lio_Personal3Total: personal3Total
      })
    } else if (taxType === 2) {
      // 内税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.ceil(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.ceil(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.ceil(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.ceil(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.ceil(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.ceil(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 単価
      let insurerTotal = this.getFormFieldValue('Lio_InsurerTotal') - insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeTotal') - officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupTotal') - otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1Total') - personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2Total') - person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3Total') - personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerUnitPriceAmount: insurerTotal,
        Lio_OfficeUnitPriceAmount: officeTotal,
        Lio_OtherGroupUnitPriceAmount: otherGroupTotal,
        Lio_Personal1UnitPriceAmount: personal1Total,
        Lio_Personal2UnitPriceAmount: person2Total,
        Lio_Personal3UnitPriceAmount: personal3Total,
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
      })
    }

    this.totalReset()
  }

  /**
   * 計算のやり方切り替えまたは、再計算が必要な場合
   * @param {*} value セレクトの値
   */
  roundingChange(value) {
    // 四捨五入
    if (value === 0) {
      this.rounding()
    }
    // 切り捨て
    else if (value === 1) {
      this.roundingDown()
    }
    // 切り上げ
    else if (value === 2) {
      this.roundingUp()
    }
  }

  /**
   * 消費税変更
   * @param {*} e 変更する消費税の値
   */
  taxChange(e) {
    this.setFormFieldsValue({ 'TaxRate': e.target.value })
    let round = this.getFormFieldValue('Rounding')
    this.roundingChange(round)
  }

  /**
   * 税の設定変更
   * @param {*} e 設定した税の種類
   */
  taxClassifyChange(e) {
    this.setFormFieldsValue({ 'TaxClassify': e })
    let round = this.getFormFieldValue('Rounding')
    this.roundingChange(round)
  }

  getFormFieldValue(namePath) {
    return this.formRef.current?.getFieldValue(namePath);
  }

  setFormFieldsValue = (data) => {
    this.formRef.current?.setFieldsValue(data);
  };

  handleFormChange(e) {
    let columnName = Object.keys(e)[0];
    let columnValue = Object.values(e)[0];
    if (columnValue === '') {
      this.setFormFieldsValue({ [columnName]: undefined })
    }
  }

  /**
   * 保存処理
   * @param {*} values
   */
  save = (values) => {
    this.setFormFieldsValue({
      Lio_StsChange: true,
    });

    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        ...this.formRef.current.getFieldsValue()
      })
    }
  };

  /**
   * コード番号のチェック
   * @param {*} valse
   */
  validCheck = (valse) => {
    if (valse) {
      getNumberOfCodeAction({ set_code: valse })
        .then((res) => {
          if (res.ms0650Count) {
            this.setState({
              shortName: res.set_short_name,
              saveFlag: false
            })
            this.formRef.current?.setFieldsValue({
              set_code: res.set_code
            })
          } else {
            message.error('存在しない番号です')
            this.setState({
              saveFlag: true,
              shortName: ''
            })

          }
        })
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centerd: false
      },
    })
  };

  render() {
    return (
      <div className="money-amount-input-sub">
        <Card title="金額入力">
          <Spin spinning={this.state.isLoading}>
            <Form
              ref={this.formRef}
              autoComplete='off'
              onValuesChange={(event) => this.handleFormChange(event)}
            >
              <div className="box_search" style={{ marginBottom: '10px', width: '100%' }}>
                <div className="box_search_inner_border" style={{ width: '50%' }}>
                  <Form.Item
                    name="ChangeType"
                    label="種別"
                    rules={[{ required: true }]}
                    {...smGrid}
                  >
                    <Select
                      style={{ width: '120px' }}
                      onChange={(value) =>
                        this.setState({
                          initValue: { ...this.state.initValue, ChangeType: value }
                          , saveFlag: false
                        })}
                    >
                      <Select.Option value={10}>コース</Select.Option>
                      <Select.Option value={20}>追加</Select.Option>
                      <Select.Option value={30}>不要</Select.Option>
                      <Select.Option value={40}>オプション</Select.Option>
                      <Select.Option value={50}>追加選択</Select.Option>
                      <Select.Option value={60}>不要選択</Select.Option>
                    </Select>
                  </Form.Item>

                  <div className="box_inner_horizontal">
                    <Form.Item
                      name="set_code"
                      label="コード"
                      rules={[{ required: true }]}
                      {...smGrid}
                    >
                      <Input.Search
                        style={{ width: '150px' }}
                        onSearch={(value, event) => {
                          // 検索ボタンを押したとき
                          if (event.target.localName !== "input") {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1000,
                                component: (
                                  <WS0302001_SetInfoSearch
                                    onFinishScreen={({ Lo_SetCode, recordData }) => {
                                      this.formRef.current?.setFieldsValue(recordData);
                                      this.setState({
                                        shortName: recordData.set_short_name,
                                        saveFlag: false
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }}
                        onPressEnter={(e) => { this.validCheck(e.target.value) }}
                        onBlur={(e) => { this.validCheck(e.target.value) }}
                      />
                    </Form.Item>
                    <Form.Item name='set_short_name' >
                      <div>{this.state.shortName}</div>
                    </Form.Item>
                  </div>
                </div>

                <div className="box_search_inner">
                  <Form.Item
                    label="税区分"
                    name="TaxClassify"
                    {...smGrid2}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        this.roundingChange(this.getFormFieldValue("Rounding"))
                        this.setState({ TaxClassify: e.target.value, saveFlag: false });
                        if (e.target.value === 3) {
                          this.setFormFieldsValue({
                            Lio_InsurerTax: 0,
                            Lio_OfficeTax: 0,
                            Lio_OtherGroupTax: 0,
                            Lio_Personal1Tax: 0,
                            Lio_Personal2Tax: 0,
                            Lio_Personal3Tax: 0,
                            Lio_InsurerTotal: this.getFormFieldValue('Lio_InsurerUnitPriceAmount'),
                            Lio_OfficeTotal: this.getFormFieldValue('Lio_OfficeUnitPriceAmount'),
                            Lio_OtherGroupTotal: this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount'),
                            Lio_Personal1Total: this.getFormFieldValue('Lio_Personal1UnitPriceAmount'),
                            Lio_Personal2Total: this.getFormFieldValue('Lio_Personal2UnitPriceAmount'),
                            Lio_Personal3Total: this.getFormFieldValue('Lio_Personal3UnitPriceAmount')
                          })
                          this.totalReset()
                        }
                      }}
                    >
                      <Radio value={0}>指定</Radio>
                      <Radio value={1}>外税</Radio>
                      <Radio value={2}>内税</Radio>
                      <Radio value={3}>非課</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="小数点以下"
                    name="Rounding"
                    {...smGrid2}
                  >
                    <Select
                      style={{ width: "120px" }}
                      // 後で復活させる
                      // disabled={!this.getFormFieldValue("StsTaxChange")}
                      disabled={this.getFormFieldValue("TaxClassify") === 0 || this.getFormFieldValue("TaxClassify") === 3}
                      onChange={(e) => {
                        this.roundingChange(e);
                        this.setState({ saveFlag: false });
                      }}
                    >
                      <Select.Option value={0}>四捨五入</Select.Option>
                      <Select.Option value={1}>切捨</Select.Option>
                      <Select.Option value={2}>切上</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="税率"
                    name="TaxRate"
                    rules={[{ required: true }]}
                    {...smGrid2}
                  >
                    <InputNumber
                      // 後で復活させる
                      // disabled={!this.getFormFieldValue("StsTaxChange")}
                      disabled={this.getFormFieldValue("TaxClassify") === 0 || this.getFormFieldValue("TaxClassify") === 3}
                      onBlur={() => { this.roundingChange(this.getFormFieldValue("Rounding")) }}
                      onChange={(e) => { this.setState({ saveFlag: false });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Row>
                <Col span={3}>
                  <div style={columnTitle}>コース</div>
                  <div style={columnTitle}>単　価</div>
                  <div style={columnTitle}>消費税</div>
                  <div style={columnTitle}>合　計</div>
                </Col>
                <Col span={3}>
                  <div style={{ ...styleDivTitle, borderLeft: '1px solid #FFFFFF' }}>保険者</div>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_InsurerUnitPriceAmount"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={(this.getFormFieldValue("TaxClassify") === 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_InsurerUnitPriceAmount', 'Lio_InsurerTax', 'Lio_InsurerTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_InsurerTax"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={(this.getFormFieldValue("TaxClassify") !== 0)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_InsurerUnitPriceAmount', 'Lio_InsurerTax', 'Lio_InsurerTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItemBottom, padding: '0 3px' }}
                      name="Lio_InsurerTotal"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_InsurerUnitPriceAmount', 'Lio_InsurerTax', 'Lio_InsurerTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>

                <Col span={3}>
                  <div style={styleDivTitle}>事業所</div>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_OfficeUnitPriceAmount"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_OfficeUnitPriceAmount', 'Lio_OfficeTax', 'Lio_OfficeTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_OfficeTax"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_OfficeUnitPriceAmount', 'Lio_OfficeTax', 'Lio_OfficeTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItemBottom, padding: '0 3px' }}
                      name="Lio_OfficeTotal"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_OfficeUnitPriceAmount', 'Lio_OfficeTax', 'Lio_OfficeTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>

                <Col span={3}>
                  <div style={styleDivTitle}>他団体</div>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_OtherGroupUnitPriceAmount"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_OtherGroupUnitPriceAmount', 'Lio_OtherGroupTax', 'Lio_OtherGroupTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_OtherGroupTax"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_OtherGroupUnitPriceAmount', 'Lio_OtherGroupTax', 'Lio_OtherGroupTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItemBottom, padding: '0 3px' }}
                      name="Lio_OtherGroupTotal"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_OtherGroupUnitPriceAmount', 'Lio_OtherGroupTax', 'Lio_OtherGroupTotal')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>

                <Col span={3}>
                  <div style={styleDivTitle}>個人１</div>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_Personal1UnitPriceAmount"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal1UnitPriceAmount', 'Lio_Personal1Tax', 'Lio_Personal1Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_Personal1Tax"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal1UnitPriceAmount', 'Lio_Personal1Tax', 'Lio_Personal1Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItemBottom, padding: '0 3px' }}
                      name="Lio_Personal1Total"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal1UnitPriceAmount', 'Lio_Personal1Tax', 'Lio_Personal1Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>

                <Col span={3}>
                  <div style={styleDivTitle}>個人２</div>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_Personal2UnitPriceAmount"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal2UnitPriceAmount', 'Lio_Personal2Tax', 'Lio_Personal2Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_Personal2Tax"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal2UnitPriceAmount', 'Lio_Personal2Tax', 'Lio_Personal2Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItemBottom, padding: '0 3px' }}
                      name="Lio_Personal2Total"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal2UnitPriceAmount', 'Lio_Personal2Tax', 'Lio_Personal2Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>

                <Col span={3}>
                  <div style={styleDivTitle}>個人３</div>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_Personal3UnitPriceAmount"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal3UnitPriceAmount', 'Lio_Personal3Tax', 'Lio_Personal3Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItem, padding: '0 3px' }}
                      name="Lio_Personal3Tax"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal3UnitPriceAmount', 'Lio_Personal3Tax', 'Lio_Personal3Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                  <ConfigProvider direction="ltr">
                    <Form.Item
                      style={{ ...styleFormItemBottom, padding: '0 3px' }}
                      name="Lio_Personal3Total"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: '100%', margin: 0, textAlign: 'right' }}
                        disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                        onBlur={(e) => this.getStsTaxChange(e, 'Lio_Personal3UnitPriceAmount', 'Lio_Personal3Tax', 'Lio_Personal3Total')}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>

                <Col span={3}>
                  <div style={{ ...styleDivTitle, borderRight: 0 }}>合計</div>
                  <Form.Item
                    style={{ ...styleFormItem, padding: '0 3px' }}
                  >
                    <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue('unitPriceTotal')}</div>
                  </Form.Item>
                  <Form.Item
                    style={{ ...styleFormItem, padding: '0 3px' }}
                    name="taxTotal"
                  >
                    <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFormFieldValue('taxTotal')}</div>
                  </Form.Item>
                  <Form.Item
                    style={{ ...styleFormItemBottom, padding: '0 3px' }}
                    name="allTotal"
                  >
                    <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFormFieldValue('allTotal')}</div>
                  </Form.Item>
                </Col>
              </Row>

              <div className="box_button_bottom_right">
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    disabled={this.state.saveFlag}
                    onClick={() => {
                      this.save()
                    }}
                  >
                    <span className='btn_label'>
                      保存
                    </span>
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS0331020_BatchUpdateProcessAdd;
