import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Row, Col, Button, Space, Modal, message } from 'antd'
import PropTypes from "prop-types"
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery.jsx'
import WS0178001_QuerySiteInfo from './WS0178001_QuerySiteInfo.jsx'
import WS0179001_InquiryFindingInfo from './WS0179001_InquiryFindingInfo.jsx'
import { PlusOutlined, MoreOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'

// ★★action追加
import NormalValueSettingInputAction from 'redux/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSettingInput.action'
// ★★

const labelCol = {
  span: 4
}
const styleFormItem = {
  margin: 0,
}

class WS2713502_NormalValueSettingInput extends React.Component {
  static propTypes = {
    Li_FormatCopySource: PropTypes.string,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      order: this.props.order,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      kind: this.props.value?.kind ?? ""
    })
  }

  /**
     * モーダル閉じる
     */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  findingCodeSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0179001_InquiryFindingInfo
          Li_FindingsClassify
          Lo_FindingsCode
          Lo_JudgeValue
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              Judge: ''
            })
            this.closeModal()
          }}
        />),
      },
    })
  }

  normalValueSettingSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0285001_JudgeQuery
          Li_JudgeLevel={''}
          Lio_Judge={''}
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              Judge: ''
            })
            this.closeModal()
          }}
        />),
      },
    })
  }


  onSave() {
    NormalValueSettingInputAction.onSave()
      .then(res => {
      })
      .catch(error => {
        console.log(error)
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onFinish(values) {
    console.log(values)
    // NormalValueSettingAction.GetScreenData(values)
    //   .then((res) => {
    //     this.setState({ mainSource: res })
    //     console.log(res)
    //   })
    //   .catch(error => {
    //     message.error(res.data.message);
    //   })
  }

  render() {
    // const { dataSource } = this.state
    return (
      <div className='normal-value-setting'>
        <Card title='タイトル' >
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ Pattern: 1 }}>
            <Col>
              <Form.Item label='検査設定' labelCol={labelCol}>
                {this.state.order === "add" ? (
                  <Button type='primary' style={{ float: 'right' }}>新規</Button>
                ) : ""}
              </Form.Item>
              <div style={{ border: '1px solid #F0F0F0', padding: '7px' }}>
                {/* ▽検査設定について、旧システムと持ってきているDBが異なる
                　　　他もそんな感じで持ってこれてない
                      旧システム：kind remarks を　A6_user_unit_fd_mastersから
                      　　　　　　exam_type　を　ms0670_inspection_itemsから
                      呼び出したコンポーネント：ms0670_inspection_items　kindというカラムはない
                      　　　　　　　　　　　　　テーブルの結合をしたいが、val(kind, '8')とかの意味が不明なため飛ばす
                      呼び出したコンポーネントで呼ぶAPI：WS0271001_InspectItemSearchQuerySingle.php　の indexメソッド */}
                <Form.Item label='検査' labelCol={labelCol} style={{ ...styleFormItem, marginTop: '1rem' }}>
                  <Space>
                    <Form.Item name='kind'>
                      <Input.Search onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 500,
                            component: (<WS0271001_InspectItemSearchQuerySingle
                              InspectCode={''}
                              Li_UnusedInspectDisplay={''}
                              onFinishScreen={(record) => {
                                this.formRef.current.setFieldsValue({
                                  Judge: '',
                                  exam_type: record.recordData.exam_type
                                })
                                this.closeModal()
                              }}
                            />),
                          },
                        })
                      }} />
                    </Form.Item>
                    <Form.Item>
                      {/* <span>{this.props.value.InspectNameTbl}</span> */}
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item label='タイプ' labelCol={labelCol} style={styleFormItem}>
                  <Space>
                    <Form.Item >
                      {/* <span>{this.props.value.TypeTbl ?? "-"}</span> */}
                    </Form.Item>
                    <Form.Item>
                      {/* <span>{this.props.value.exam_type ?? "-"}</span> */}
                    </Form.Item>
                  </Space>
                </Form.Item>
              </div>

              <Form.Item label='規定値設定' labelCol={{ span: 4 }} className='mt-3'></Form.Item>
              <div style={{ border: '1px solid #F0F0F0', padding: '7px' }}>
                <Form.Item name='Judge' label='判定' labelCol={labelCol} style={{ marginTop: '1rem' }}>
                  <Input.Search
                    className='input-search-size-judgment'
                    onSearch={this.normalValueSettingSearch}
                  />
                </Form.Item>
                <Form.Item name='InspectResults' label='検査結果' labelCol={labelCol}>
                  <Input.Search onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 500,
                        component: (<WS0274001_InspectCmtSearchQuery
                          Lio_CommentsClassify={''}
                          LnkOutInspectCommentsScreen={''}
                          onFinishScreen={(value) => {
                            this.formRef.current.setFieldsValue({
                              Judge: ''
                            })
                            this.closeModal()
                          }}
                        />),
                      },
                    })
                  }} />
                </Form.Item>
                <Form.Item label='部位' labelCol={labelCol} style={styleFormItem}>
                  <Space>
                    <Form.Item name='SiteCode'>
                      <Input.Search onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 500,
                            component: (<WS0178001_QuerySiteInfo
                              Li_SiteClassify
                              Lo_SiteCode
                              onFinishScreen={(value) => {
                                this.formRef.current.setFieldsValue({
                                  Judge: ''
                                })
                                this.closeModal()
                              }}
                            />),
                          },
                        })
                      }} />
                    </Form.Item>
                    <Form.Item>
                      <span>{this.formRef.current?.getFieldValue('Expression_53') ?? "-"}</span>
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item label='所見' labelCol={labelCol} style={styleFormItem}>
                  <Space>
                    <Form.Item name='FindingsCode'>
                      <Input.Search onSearch={() => {
                        this.findingCodeSearch
                      }} />
                    </Form.Item>
                    <Form.Item>
                      <span>{this.formRef.current?.getFieldValue('Expression_54') ?? "-"}</span>
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item label='規定値' labelCol={labelCol}>
                  <span>{this.formRef.current?.getFieldValue('Expression_50') ?? "-"}</span>
                </Form.Item>
              </div>
            </Col>
            {this.state.order === "edit" ? (
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  danger
                  icon={<DeleteOutlined />}
                  style={{ display: (this.props.newFlag) ? 'none' : '' }}
                  onClick={() => {
                    // 確認モーダルを表示してから、削除
                    Modal.confirm({
                      content: `削除を行いますか?`,
                      okText: 'は　い',
                      cancelText: 'いいえ',
                      onOk: () => {
                        this.delete()
                      }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>

                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  htmlType='submit'
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            ) :
              <div className='box_button_bottom_right'
                style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            }
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

export default WS2713502_NormalValueSettingInput
