import axios from 'configs/axios';

const API_LIST = {
  Print: '/api/office-visits-achievement-situation/business-establishments-subject-to-select-service/Print',
  ExportCsv: '/api/office-visits-achievement-situation/business-establishments-subject-to-select-service/ExportCsv',
};

const BusinessEstablishmentsSubjectToSelectService = {
  /**
   * 印刷/プレビュー
   */
  async Print(params) {
    return axios.post(API_LIST.Print, params, { responseType: 'blob' });
  },
  /**
   * csv出力 
   */
  async ExportCsv(params) {
    return axios.post(API_LIST.ExportCsv, params, { responseType: 'blob' });
  },
};

export default BusinessEstablishmentsSubjectToSelectService;
