import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  Button,
  Space,
  Spin,
  Row,
  Col,
  Form,
  message,
  Input,
  DatePicker,
  Checkbox,
  Table,
  Select
} from "antd";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";

import ConsultInfoListAction from "redux/AdvancePreparation/ConsultInfoList/ConsultInfoList.action";

import ModalDraggable from "components/Commons/ModalDraggable";

import WS0801006_FormOutput from "pages/UT_UserTools/V4UT4050000_UserFormOutput/WS0801006_FormOutput.jsx";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS2786001_ConditionAddSub from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx";
import WS2788013_TargetSelectSub from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS2788013_TargetSelectSub.jsx";

const { Option } = Select;

const styleLabel = {
  textAlign: "right",
  paddingRight: 5,
  width: 75,
  color: "#14468C",
  fontWeight: 500
};

const styleRow = {};

const styleGroup = {
  display: "flex"
};

class WS0801001_UserFormOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'ユーザー帳票出力';

    this.state = {
      isLoadingForm: false,
      isLoadingTable: false,
      isLoading: false,
      DateFChar: moment(),
      DateTChar: moment(),
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      random: null,
      message: "", // WS2788013_TargetSelectSub
      isSearch: false, // WS2788013_TargetSelectSub
      conditionAddData: []
    };

    this.setFormFieldValue = this.setFormFieldValue.bind(this);
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value
      }
    ]);
  }

  renderTargetSub() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      random: this.state.random
    };
    return (
      <WS2788013_TargetSelectSub
        isSearch={this.state.isSearch}
        message={this.state.message}
        params={params}
        PrintType={1}
      />
    );
  }

  onFinish(values) { }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  getDataBySearch() {
    this.setState({ isLoadingTable: true });

    let params = {
      ...this.formRef.current?.getFieldValue(),
      DateFChar: this.formRef.current?.getFieldValue("DateFChar")?.format("YYYY/MM/DD"),
      DateTChar: this.formRef.current?.getFieldValue("DateTChar")?.format("YYYY/MM/DD")
    };
    ConsultInfoListAction.getDataBySearch(params)
      .then(res => {
        this.setState({
          message: res.data.message,
          isLoadingTable: false,
          isSearch: true,
          random: Math.random()
        });
      })
      .catch(err => {
        this.setState({ isLoadingTable: false });
        const res = err.response;
        if (!res || !res.data) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  exportProc = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "30%",
        component: (
          <WS0801006_FormOutput
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  render() {
    return (
      <div className="user-form-output">
        <Card title="ユーザー帳票出力" className="mb-2">
          <Space className="mb-3">
            <Button type="primary" style={{ marginRight: 4 }} onClick={this.exportProc}>
              出力
            </Button>
            <Button type="primary" onClick={() => { }}>
              CSV
            </Button>
          </Space>
          <hr style={{ margin: "15px 0" }} />
          <Spin spinning={this.state.isLoadingForm}>
            <Row gutter={12}>
              <Col xl={7} lg={24} style={{ borderRight: "1px solid #d9d9d9", marginBottom: 15 }}>
                <Form ref={this.formRef} onFinish={this.onFinish}>
                  <Row gutter={12} style={styleRow}>
                    <Col>
                      <Form.Item
                        label="受診日"
                        name="DateFChar"
                        initialValue={this.state.DateFChar}
                      >
                        <DatePicker
                          onChange={this.onChangeFromDate}
                          style={{ marginLeft: 5, width: 130 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="〜" name="DateTChar" initialValue={this.state.DateTChar}>
                        <DatePicker onChange={this.onChangeToDate} style={{ width: 130 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} style={styleRow}>
                    <Col>
                      <Form.Item label="保険者" name="InsurerCode">
                        <Input.Search
                          type="number"
                          style={{ width: 140, marginLeft: 5, textAlign: "right" }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: "60%",
                                className: "",
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    onFinishScreen={output => {
                                      this.formRef.current?.setFieldsValue({
                                        InsurerCode: output.Lo_InsurerCode,
                                        insurer_kanji_name: output.Lo_Name
                                      });

                                      this.closeModal();
                                    }}
                                  />
                                )
                              }
                            });
                          }}
                          onChange={e => {
                            this.formRef.current?.setFieldsValue({
                              insurer_kanji_name: ""
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item style={{ marginLeft: 60 }} name="insurer_kanji_name" label="">
                        <span>{this.formRef.current?.getFieldValue("insurer_kanji_name")}</span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} style={styleRow}>
                    <Col>
                      <Form.Item label="事業所" name="OfficeCode">
                        <Input.Search
                          type="text"
                          style={{ marginLeft: 5, width: 100, textAlign: "right" }}
                          onChange={e =>
                            this.checkInputField(e, ["office_kanji_name", "BranchStoreCodeF"])
                          }
                          onBlur={e => this.onChangeOfficeCode(e)}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: "70%",
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    onFinishScreen={output => {
                                      this.setFormFieldValue("OfficeCode", output.Lio_OfficeCode);
                                      this.setFormFieldValue(
                                        "BranchStoreCodeF",
                                        output.Lio_BranchStoreCode
                                      );
                                      this.setFormFieldValue(
                                        "office_kanji_name",
                                        output.Lo_Kanji_Name
                                      );
                                      this.closeModal();
                                    }}
                                  />
                                )
                              }
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="BranchStoreCodeF">
                        <Input placeholder="" style={{ width: 50 }} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        style={{ marginLeft: 10, width: 150 }}
                        name="office_kanji_name"
                        label=""
                      >
                        <span>{this.formRef.current?.getFieldValue("office_kanji_name")}</span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} style={styleRow}>
                    <Col>
                      <Form.Item label="コース" name="CourseCode">
                        <Input.Search
                          type="text"
                          style={{ marginLeft: 5, width: 80 }}
                          onChange={e => this.checkInputField(e, ["course_name_formal"])}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: "60%",
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={output => {
                                      this.setFormFieldValue("CourseCode", output.Lo_CourseCode);
                                      this.setFormFieldValue(
                                        "course_name_formal",
                                        output.Lo_CourseName
                                      );
                                      this.closeModal();
                                    }}
                                  />
                                )
                              }
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} style={styleRow}>
                    <Col>
                      <Form.Item label="施設" name="FacilityType" initialValue={"全て"}>
                        <Select style={{ marginLeft: 16 }}>
                          <Option value="全て">全て</Option>
                          <Option value="院内">院内</Option>
                          <Option value="院外">院外</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} style={styleRow}>
                    <Col>
                      <Form.Item label="状態" name="StatusFlagRange" initialValue={"全て"}>
                        <Select style={{ marginLeft: 16 }}>
                          <Option value="全て">全て</Option>
                          <Option value="予約">予約</Option>
                          <Option value="受付">受付</Option>
                          <Option value="保留">保留</Option>
                          <Option value="キャンセル">キャンセル</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} style={styleRow}>
                    <Form.Item style={{ marginLeft: "auto" }}>
                      <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component: (
                                <WS2786001_ConditionAddSub
                                  Li_DateF={this.formRef.current.getFieldValue("DateFChar")}
                                  Li_DateT={this.formRef.current.getFieldValue("DateTChar")}
                                  Li_Insurer={this.formRef.current.getFieldValue("InsurerCode")}
                                  Li_Office={this.formRef.current.getFieldValue("OfficeCode")}
                                  Li_CourseF={this.formRef.current.getFieldValue("CourseCode")}
                                  Li_CourseT={this.formRef.current.getFieldValue("CourseCode")}
                                  Li_State={1}
                                  Li_ConditionAddData={this.state.conditionAddData}
                                  onFinishScreen={(output) => {
                                    this.formRef.current?.setFieldsValue({
                                      KeyNum: output.Lio_KeyInfo,
                                      // CourseCodeF: output.recordData.CourseCodeF,
                                      // CourseCodeT: output.recordData.CourseCodeT
                                    })
                                    // 追加条件のデータをstate変数に保持
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                    })
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          });
                        }}
                      >
                        <PlusCircleOutlined />
                        条件追加
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          this.getDataBySearch();
                        }}
                      >
                        <SearchOutlined />
                        検　　索
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
              <Col xl={17} lg={24} className="mt-3">
                <Spin spinning={this.state.isLoadingTable}>
                  {this.state.isLoadingTable ? this.renderTargetSub() : this.renderTargetSub()}
                </Spin>
              </Col>
            </Row>
          </Spin>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS0801001_UserFormOutput);
