import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Input,
  Checkbox,
  Button,
  Table,
  Tabs,
  Row,
  Space,
  Col,
  Modal,
  message,
  Radio
} from "antd";
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import DockDisplayItemSettingAction from 'redux/Others/MedicalCheckupCalendar/DockDisplayItemSetting.action'
import DockDisplayItemSettingEditMock from 'pages/Mock/ZZ_Others/SANAI_MedicalCheckupCalendar/DockDisplayItemSettingEditMock'

const dataSource1 = [
  { id: 1, nameId: 1, displayName: 'ドックA' },
  { id: 2, nameId: 2, displayName: 'ドックB' },
  { id: 3, nameId: 3, displayName: 'プレミアムドック' },
  { id: 4, nameId: 4, displayName: '脳ク' },
  { id: 5, nameId: 5, displayName: '脳ス' },
  { id: 6, nameId: 6, displayName: '心' },
  { id: 7, nameId: 7, displayName: '膵' },
  { id: 8, nameId: 8, displayName: '' },
  { id: 9, nameId: 9, displayName: '' },
  { id: 10, nameId: 10, displayName: '' },
  { id: 11, nameId: 11, displayName: '' },
  { id: 12, nameId: 12, displayName: '' },
  { id: 13, nameId: 13, displayName: '' },
  { id: 14, nameId: 14, displayName: '' },
  { id: 15, nameId: 15, displayName: '' },
  { id: 16, nameId: 16, displayName: '' },
  { id: 17, nameId: 17, displayName: '' },
  { id: 18, nameId: 18, displayName: '' },
  { id: 19, nameId: 19, displayName: '' },
  { id: 20, nameId: 20, displayName: '' },
]

const dataSource2 = [
  { id: 1, nameId: 1, displayName: '頸エコー' },
  { id: 2, nameId: 2, displayName: '甲エコー' },
  { id: 3, nameId: 3, displayName: '乳エコー' },
  { id: 4, nameId: 4, displayName: 'マンモ' },
  { id: 5, nameId: 5, displayName: '子宮' },
  { id: 6, nameId: 6, displayName: 'MRI' },
  { id: 7, nameId: 7, displayName: 'CT' },
  { id: 8, nameId: 8, displayName: 'OP' },
  { id: 9, nameId: 9, displayName: '心エコー' },
  { id: 10, nameId: 10, displayName: 'EUS' },
  { id: 11, nameId: 11, displayName: '' },
  { id: 12, nameId: 12, displayName: '' },
  { id: 13, nameId: 13, displayName: '' },
  { id: 14, nameId: 14, displayName: '' },
  { id: 15, nameId: 15, displayName: '' },
  { id: 16, nameId: 16, displayName: '' },
  { id: 17, nameId: 17, displayName: '' },
  { id: 18, nameId: 18, displayName: '' },
  { id: 19, nameId: 19, displayName: '' },
  { id: 20, nameId: 20, displayName: '' },
]

class DockDisplayItemSettingMock extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource1: [],
      dataSource2: [],
      selectedNodes: [],
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      dataSource1: dataSource1,
      dataSource2: dataSource2
    })
    this.formRef.current?.setFieldsValue({
      section: 1,
    })
    this.getListData()
  }

  /**
   * 初期表示
   */
  getListData() {
    DockDisplayItemSettingAction.getListData()
      .then((res) => {
        console.log(res);
        this.setState({
          dataSource1: res.result_1,
          dataSource2: res.result_2
        })
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  /**
   * 編集画面呼び出し
   */
  callEdit(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <DockDisplayItemSettingEditMock
            record={record}
            onFinishScreen={(output) => {
              this.getListData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  render() {
    return (
      <div className="reserves-displayitem-setting">
        <Card title="人間ドック表示項目設定" className="mb-2">
          <Form ref={this.formRef} >

            <div className="box_inner_horizontal" style={{ gap: 40, }}>
              <div className="box_inner_vertical_label_top">
                <Card>
                  <div className="table_header_filter">
                    <div>コース</div>
                  </div>
                  <Table
                    bordered
                    dataSource={this.state.dataSource1}
                    loading={false}
                    size='small'
                    pagination={false}
                    rowKey={(record) => record.nameId}
                    scroll={{ x: 'max-content', y: 360 }}
                    onRow={(record, rowIndex) => ({
                      onDoubleClick: () =>
                        this.callEdit(record)
                    })}
                  >
                    <Table.Column width={20} title="No" dataIndex="nameId" key="" />
                    <Table.Column title="名称" dataIndex="displayName" key="" />
                    <Table.Column width={20} align="center" render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.callEdit(record)
                        }}
                      />
                    )}
                    />

                  </Table>
                </Card>
              </div>

              <div className="box_inner_vertical_label_top">
                <Card>
                  <div
                    className="table_header_filter"
                  ><div>検査</div>
                  </div>
                  <Table bordered
                    dataSource={this.state.dataSource2}
                    loading={false}
                    size='small'
                    pagination={false}
                    rowKey={(record) => record.nameId}
                    scroll={{ x: 'max-content', y: 360 }}
                    onRow={(record, rowIndex) => ({
                      onDoubleClick: () =>
                        this.callEdit(record)
                    })}
                  >
                    <Table.Column width={20} title="No" dataIndex="nameId" key="" />
                    <Table.Column title="名称" dataIndex="displayName" key="" />
                    <Table.Column width={20} align="center" render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.callEdit(record)
                        }}
                      />
                    )}
                    />
                  </Table>
                </Card>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }


}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DockDisplayItemSettingMock);
