/* eslint-disable eqeqeq */
import React from 'react'
import PropTypes from 'prop-types'

import { Card, Form, Input, Radio, Button, Row, Col, Select, Tag, Checkbox, } from 'antd'
import Text from 'antd/lib/typography/Text'
import Color from 'constants/Color'
import { SaveOutlined } from '@ant-design/icons'
import { number_format } from 'helpers/CommonHelpers'
import MagicXpaFunc from 'helpers/MagicXpaFunc'
import changeHankakuText from 'components/Commons/changeHankakuText'

import axios from 'configs/axios'

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  // borderRight: 0,
  margin: 0,
  textAlign: 'right',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}
class WS0311005_MoneyAmountInputSub extends React.Component {
  static propTypes = {
    Li_TaxClassify: PropTypes.any,
    Li_Rounding: PropTypes.any,
    Li_TaxRate: PropTypes.any,
    Li_OtherGroupDivision: PropTypes.any,
    Li_Title: PropTypes.any,
    Lio_InsurerUnitPriceAmount: PropTypes.any,
    Lio_InsurerTax: PropTypes.any,
    Lio_InsurerTotal: PropTypes.any,
    Lio_OfficeUnitPriceAmount: PropTypes.any,
    Lio_OfficeTax: PropTypes.any,
    Lio_OfficeTotal: PropTypes.any,
    Lio_OtherGroupUnitPriceAmount: PropTypes.any,
    Lio_OtherGroupTax: PropTypes.any,
    Lio_OtherGroupTotal: PropTypes.any,
    Lio_Personal1UnitPriceAmount: PropTypes.any,
    Lio_Personal1Tax: PropTypes.any,
    Lio_Personal1Total: PropTypes.any,
    Lio_Personal2UnitPriceAmount: PropTypes.any,
    Lio_Personal2Tax: PropTypes.any,
    Lio_Personal2Total: PropTypes.any,
    Lio_Personal3UnitPriceAmount: PropTypes.any,
    Lio_Personal3Tax: PropTypes.any,
    Lio_Personal3Total: PropTypes.any,
    Lio_StsChange: PropTypes.any,
    Li_Protection: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '金額入力'

    this.state = {
      isLoading: false,

      Li_Protection: props.Li_Protection || '      ',
    }
  }

  componentDidMount() {
    console.log(this.props)
    this.loadScreenData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.loadScreenData()
    }
  }

  loadScreenData = () => {
    this.setState({ isLoading: true })

    this.setFormFieldsValue(this.props)

    axios
      .get('/api/personal-reserve-process/money-amount-input-sub/getScreenData')
      .then((res) => {
        this.setState(res.data)
        this.totalReset()
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  TaxCalculateBtn = (callback) => {
    this.setState({ isLoading: true })

    axios
      .post(
        '/api/personal-reserve-process/money-amount-input-sub/TaxCalculateBtn',
        this.formRef.current.getFieldsValue()
      )
      .then((res) => {
        this.setFormFieldsValue(res.data)
        this.setFormFieldsValue({
          Lio_StsChange: true,
        })

        if (callback) {
          callback()
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  /**
   * 指定
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxDesignated(e, name, taxName, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    let unitPrice = this.getFormFieldValue(name)
    let tax = this.getFormFieldValue(taxName)
    let allTotal, result, taxResult
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(unitPrice)
        // 消費税
        taxResult = Math.round(tax)
        allTotal = result + taxResult
        break
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(unitPrice)
        // 消費税
        taxResult = Math.floor(tax)
        allTotal = result + taxResult
        break
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(unitPrice)
        // 消費税
        taxResult = Math.ceil(tax)
        allTotal = result + taxResult
        break
    }
    this.formRef.current?.setFieldsValue({ [name]: result, [taxName]: taxResult, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 外税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxExclusive(e, name, taxName, totalName) {
    // 消費税
    let taxCalculation = this.getFormFieldValue('TaxRate')
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    let value = e.target.value
    let result, taxResult, allTotal
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(value)
        // 消費税
        taxResult = Math.round(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(value)
        // 消費税
        taxResult = Math.floor(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(value)
        // 消費税
        taxResult = Math.ceil(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break
    }
    this.formRef.current?.setFieldsValue({ [name]: result, [taxName]: taxResult, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 内税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxInclusive(e, name, taxName, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    // 消費税
    let taxCalculation = this.getFormFieldValue('TaxRate')
    let unitPrice, taxValue
    switch (list) {
      case 0:
        // 四捨五入
        // 消費税
        taxValue = Math.round(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break
      case 1:
        // 切り捨て
        // 消費税
        taxValue = Math.floor(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break
      case 2:
        // 切り上げ
        // 消費税
        taxValue = Math.ceil(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break
    }
    this.formRef.current?.setFieldsValue({ [name]: unitPrice, [taxName]: taxValue, [totalName]: e.target.value })
    this.totalReset()
  }

  /**
   * 非課税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} totalName 合計の名前
   */
  taxExemption(e, name, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = this.getFormFieldValue('Rounding')
    let unitPrice = this.getFormFieldValue(name)
    let allTotal, result
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(unitPrice)
        // 合計
        allTotal = result
        break
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(unitPrice)
        // 合計
        allTotal = result
        break
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(unitPrice)
        // 合計
        allTotal = result
        break
    }
    this.formRef.current?.setFieldsValue({ [name]: result, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 合計値再設定
   */
  totalReset() {
    // 合計の値を設定しなおす
    // 単価合計
    let unitTotal = number_format(
      (this.getFormFieldValue('Lio_InsurerUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OfficeUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal1UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal2UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal3UnitPriceAmount') || 0)
    )
    // 消費税合計
    let taxTotal = number_format(
      (this.getFormFieldValue('Lio_InsurerTax') || 0) +
      (this.getFormFieldValue('Lio_OfficeTax') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupTax') || 0) +
      (this.getFormFieldValue('Lio_Personal1Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal2Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal3Tax') || 0)
    )
    // 全ての合計
    let totalNumber = number_format(
      (this.getFormFieldValue('Lio_InsurerUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OfficeUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal1UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal2UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_Personal3UnitPriceAmount') || 0) +
      (this.getFormFieldValue('Lio_InsurerTax') || 0) +
      (this.getFormFieldValue('Lio_OfficeTax') || 0) +
      (this.getFormFieldValue('Lio_OtherGroupTax') || 0) +
      (this.getFormFieldValue('Lio_Personal1Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal2Tax') || 0) +
      (this.getFormFieldValue('Lio_Personal3Tax') || 0)
    )
    this.setFormFieldsValue({ unitPriceTotal: unitTotal, taxTotal: taxTotal, allTotal: totalNumber })
    this.forceUpdate()
  }

  setValueHankaku(e, targetInputName,) {

    let value = e.target.value
    let hankakuValue = changeHankakuText(value)

    this.formRef.current?.setFieldsValue({ [targetInputName]: hankakuValue })
  }

  getStsTaxChange(e, name, taxName, totalName) {
    let taxType = this.getFormFieldValue('TaxClassify')
    if (taxType === 0) {
      // 指定
      this.taxDesignated(e, name, taxName, totalName)
    } else if (taxType === 1) {
      // 外税
      this.taxExclusive(e, name, taxName, totalName)
    } else if (taxType === 2) {
      // 内税
      this.taxInclusive(e, name, taxName, totalName)
    } else if (taxType === 3) {
      // 非課税
      this.taxExemption(e, name, totalName)
    }
  }

  /**
   * 四捨五入
   * @returns
   */
  rounding() {
    let taxType = this.getFormFieldValue('TaxClassify')
    if (taxType === 0 || taxType === 3) {
      // 指定 非課税
      return
    } else if (taxType === 1) {
      // 外税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.round(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.round(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.round(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.round(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.round(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.round(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 合計
      let insurerTotal = this.getFormFieldValue('Lio_InsurerUnitPriceAmount') + insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeUnitPriceAmount') + officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') + otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1UnitPriceAmount') + personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2UnitPriceAmount') + person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3UnitPriceAmount') + personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
        Lio_InsurerTotal: insurerTotal,
        Lio_OfficeTotal: officeTotal,
        Lio_OtherGroupTotal: otherGroupTotal,
        Lio_Personal1Total: personal1Total,
        Lio_Personal2Total: person2Total,
        Lio_Personal3Total: personal3Total
      })
    } else if (taxType === 2) {
      // 内税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.round(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.round(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.round(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.round(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.round(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.round(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 単価
      let insurerTotal = this.getFormFieldValue('Lio_InsurerTotal') - insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeTotal') - officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupTotal') - otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1Total') - personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2Total') - person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3Total') - personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerUnitPriceAmount: insurerTotal,
        Lio_OfficeUnitPriceAmount: officeTotal,
        Lio_OtherGroupUnitPriceAmount: otherGroupTotal,
        Lio_Personal1UnitPriceAmount: personal1Total,
        Lio_Personal2UnitPriceAmount: person2Total,
        Lio_Personal3UnitPriceAmount: personal3Total,
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
      })
    }
    this.totalReset()
  }

  /**
   * 切り捨て
   */
  roundingDown() {
    let taxType = this.getFormFieldValue('TaxClassify')
    if (taxType === 0 || taxType === 3) {
      // 指定 非課税
      return
    } else if (taxType === 1) {
      // 外税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.floor(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.floor(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.floor(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.floor(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.floor(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.floor(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 合計
      let insurerTotal = this.getFormFieldValue('Lio_InsurerUnitPriceAmount') + insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeUnitPriceAmount') + officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') + otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1UnitPriceAmount') + personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2UnitPriceAmount') + person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3UnitPriceAmount') + personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
        Lio_InsurerTotal: insurerTotal,
        Lio_OfficeTotal: officeTotal,
        Lio_OtherGroupTotal: otherGroupTotal,
        Lio_Personal1Total: personal1Total,
        Lio_Personal2Total: person2Total,
        Lio_Personal3Total: personal3Total
      })
    } else if (taxType === 2) {
      // 内税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.floor(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.floor(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.floor(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.floor(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.floor(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.floor(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 単価
      let insurerTotal = this.getFormFieldValue('Lio_InsurerTotal') - insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeTotal') - officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupTotal') - otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1Total') - personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2Total') - person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3Total') - personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerUnitPriceAmount: insurerTotal,
        Lio_OfficeUnitPriceAmount: officeTotal,
        Lio_OtherGroupUnitPriceAmount: otherGroupTotal,
        Lio_Personal1UnitPriceAmount: personal1Total,
        Lio_Personal2UnitPriceAmount: person2Total,
        Lio_Personal3UnitPriceAmount: personal3Total,
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
      })
    }
    this.totalReset()
  }

  /**
   * 切り上げ
   */
  roundingUp() {
    let taxType = this.getFormFieldValue('TaxClassify')
    if (taxType === 0 || taxType === 3) {
      // 指定 非課税
      return
    } else if (taxType === 1) {
      // 外税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.ceil(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.ceil(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.ceil(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.ceil(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.ceil(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.ceil(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 合計
      let insurerTotal = this.getFormFieldValue('Lio_InsurerUnitPriceAmount') + insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeUnitPriceAmount') + officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') + otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1UnitPriceAmount') + personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2UnitPriceAmount') + person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3UnitPriceAmount') + personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
        Lio_InsurerTotal: insurerTotal,
        Lio_OfficeTotal: officeTotal,
        Lio_OtherGroupTotal: otherGroupTotal,
        Lio_Personal1Total: personal1Total,
        Lio_Personal2Total: person2Total,
        Lio_Personal3Total: personal3Total
      })
    } else if (taxType === 2) {
      // 内税
      // 消費税
      let taxCalculation = this.getFormFieldValue('TaxRate')
      let insurerTax = Math.ceil(this.getFormFieldValue('Lio_InsurerUnitPriceAmount') * taxCalculation)
      let officeTax = Math.ceil(this.getFormFieldValue('Lio_OfficeUnitPriceAmount') * taxCalculation)
      let otherGroupTax = Math.ceil(this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount') * taxCalculation)
      let personal1Tax = Math.ceil(this.getFormFieldValue('Lio_Personal1UnitPriceAmount') * taxCalculation)
      let person2Tax = Math.ceil(this.getFormFieldValue('Lio_Personal2UnitPriceAmount') * taxCalculation)
      let personal3Tax = Math.ceil(this.getFormFieldValue('Lio_Personal3UnitPriceAmount') * taxCalculation)
      // 単価
      let insurerTotal = this.getFormFieldValue('Lio_InsurerTotal') - insurerTax
      let officeTotal = this.getFormFieldValue('Lio_OfficeTotal') - officeTax
      let otherGroupTotal = this.getFormFieldValue('Lio_OtherGroupTotal') - otherGroupTax
      let personal1Total = this.getFormFieldValue('Lio_Personal1Total') - personal1Tax
      let person2Total = this.getFormFieldValue('Lio_Personal2Total') - person2Tax
      let personal3Total = this.getFormFieldValue('Lio_Personal3Total') - personal3Tax
      // 再計算した値を設定しなおす
      this.formRef.current?.setFieldsValue({
        Lio_InsurerUnitPriceAmount: insurerTotal,
        Lio_OfficeUnitPriceAmount: officeTotal,
        Lio_OtherGroupUnitPriceAmount: otherGroupTotal,
        Lio_Personal1UnitPriceAmount: personal1Total,
        Lio_Personal2UnitPriceAmount: person2Total,
        Lio_Personal3UnitPriceAmount: personal3Total,
        Lio_InsurerTax: insurerTax,
        Lio_OfficeTax: officeTax,
        Lio_OtherGroupTax: otherGroupTax,
        Lio_Personal1Tax: personal1Tax,
        Lio_Personal2Tax: person2Tax,
        Lio_Personal3Tax: personal3Tax,
      })
    }
    this.totalReset()
  }

  /**
   * 計算のやり方切り替えまたは、再計算が必要な場合
   * @param {*} value セレクトの値
   */
  roundingChange(value) {
    // 四捨五入
    if (value === 0) {
      this.rounding()
    }
    // 切り捨て
    else if (value === 1) {
      this.roundingDown()
    }
    // 切り上げ 
    else if (value === 2) {
      this.roundingUp()
    }
  }

  /**
   * 消費税変更
   * @param {*} e 変更する消費税の値
   */
  taxChange(e) {
    this.setFormFieldsValue({ 'TaxRate': e.target.value })
    let round = this.getFormFieldValue('Rounding')
    this.roundingChange(round)
  }

  /**
   * 税の設定変更
   * @param {*} e 設定した税の種類
   */
  taxClassifyChange(e) {
    this.setFormFieldsValue({ 'TaxClassify': e })
    let round = this.getFormFieldValue('Rounding')
    this.roundingChange(round)
  }

  getFormFieldValue(namePath) {
    return this.formRef.current?.getFieldValue(namePath)
  }

  setFormFieldsValue = (data) => {
    this.formRef.current?.setFieldsValue(data)
  }

  onFinish = (values) => {
    // this.TaxCalculateBtn(() => {
    this.setFormFieldsValue({
      Lio_StsChange: true,
    })
    const func = this.props.onTaxCalculateBtn || this.props.onFinishScreen
    func(this.formRef.current.getFieldsValue())
    // })
  }

  render() {
    return (
      <div className='money-amount-input-sub'>
        <Card title='金額入力'>
          <Form
            ref={this.formRef}
            // onFinish={(value) => this.onFinish(value)}
            initialValues={{
              TaxClassify: this.props.Li_TaxClassify,
              Rounding: this.props.Li_Rounding,
              TaxRate: this.props.Li_TaxRate,
              Protection: this.state.Li_Protection.replace(/ /g, '0'),
              StsTaxChange: false,
              StsTaxCalculated: false,
              Lio_StsChange: false,
            }}
            autoComplete='off'
          >
            <Form.Item name='StsTaxChange' valuePropName='checked' hidden>
              <Checkbox />
            </Form.Item>
            <Form.Item name='StsTaxCalculated' valuePropName='checked' hidden>
              <Checkbox />
            </Form.Item>
            <Form.Item name='Protection' hidden>
              <Input />
            </Form.Item>
            <Form.Item name='Lio_StsChange' valuePropName='checked' hidden>
              <Checkbox />
            </Form.Item>
            <Form.Item name='Li_Protection' hidden>
              <Input />
            </Form.Item>
            {/* <div style={{ marginTop: 0 }} className='box_button_bottom_right'> */}
            {/* <Form.Item label='　　税区分' name='TaxClassify'>
              <Radio.Group
                disabled={
                  !(
                    this.getFormFieldValue('StsTaxChange') ||
                    this.props.Li_TaxClassify == 0
                  )
                }
                onChange={(e) => {
                  this.roundingChange(this.getFormFieldValue('Rounding'))
                  this.setState({ TaxClassify: e.target.value })
                  if (e.target.value === 3) {
                    this.setFormFieldsValue({
                      Lio_InsurerTax: 0,
                      Lio_OfficeTax: 0,
                      Lio_OtherGroupTax: 0,
                      Lio_Personal1Tax: 0,
                      Lio_Personal2Tax: 0,
                      Lio_Personal3Tax: 0,
                      Lio_InsurerTotal: this.getFormFieldValue('Lio_InsurerUnitPriceAmount'),
                      Lio_OfficeTotal: this.getFormFieldValue('Lio_OfficeUnitPriceAmount'),
                      Lio_OtherGroupTotal: this.getFormFieldValue('Lio_OtherGroupUnitPriceAmount'),
                      Lio_Personal1Total: this.getFormFieldValue('Lio_Personal1UnitPriceAmount'),
                      Lio_Personal2Total: this.getFormFieldValue('Lio_Personal2UnitPriceAmount'),
                      Lio_Personal3Total: this.getFormFieldValue('Lio_Personal3UnitPriceAmount')
                    })
                    this.totalReset()
                  }
                }}
              >
                <Radio value={0}>指定</Radio>
                <Radio value={1}>外税</Radio>
                <Radio value={2}>内税</Radio>
                <Radio value={3}>非課</Radio>
              </Radio.Group>
            </Form.Item> */}
            <Form.Item label='小数点以下' name='Rounding'>
              <Select
                style={{ width: '120px' }}
                // 後で復活させる
                // disabled={!this.getFormFieldValue('StsTaxChange')}
                disabled={this.getFormFieldValue('TaxClassify') === 0 || this.getFormFieldValue('TaxClassify') === 3}
                onChange={(e) => { this.roundingChange(e) }}
              >
                <Select.Option value={0}>四捨五入</Select.Option>
                <Select.Option value={1}>切捨</Select.Option>
                <Select.Option value={2}>切上</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label='　　 税率' name='TaxRate' rules={[{ required: true }]}>
              <Input
                // 後で復活させる
                // disabled={!this.getFormFieldValue('StsTaxChange')}
                disabled={this.getFormFieldValue('TaxClassify') === 0 || this.getFormFieldValue('TaxClassify') === 3}
                onBlur={() => { this.roundingChange(this.getFormFieldValue('Rounding')) }}
              />
            </Form.Item>
            {/* </div> */}
            <Row>
              <Col span={3}>
                <div style={styleDivTitle}>コース</div>
                <div style={styleDivTitle}>単　価</div>
                <div style={styleDivTitle}>消費税</div>
                <div style={styleDivTitle}>合　計</div>
              </Col>
              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  > */}
                {/* <Tag
                      color={Color(156).Background}
                      style={{ width: '100%' }}
                    >
                      保険者
                    </Tag> */}
                <div style={styleDivTitle}>保険者</div>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_InsurerUnitPriceAmount'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(0, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') != 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_InsurerUnitPriceAmount')
                      await this.getStsTaxChange(e, 'Lio_InsurerUnitPriceAmount', 'Lio_InsurerTax', 'Lio_InsurerTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_InsurerUnitPriceAmount')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_InsurerTax'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(0, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 0)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_InsurerTax')
                      await this.getStsTaxChange(e, 'Lio_InsurerUnitPriceAmount', 'Lio_InsurerTax', 'Lio_InsurerTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_InsurerTax')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='Lio_InsurerTotal'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(0, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_InsurerTotal')
                      await this.getStsTaxChange(e, 'Lio_InsurerUnitPriceAmount', 'Lio_InsurerTax', 'Lio_InsurerTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_InsurerTotal')}
                  />
                </Form.Item>

                {/* </Text> */}
              </Col>

              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  > */}
                {/* <Tag
                      color={Color(156).Background}
                      style={{ width: '100%' }}
                    >
                      事業所
                    </Tag> */}
                <div style={styleDivTitle}>事業所</div>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_OfficeUnitPriceAmount'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(1, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') != 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_OfficeUnitPriceAmount')
                      await this.getStsTaxChange(e, 'Lio_OfficeUnitPriceAmount', 'Lio_OfficeTax', 'Lio_OfficeTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_OfficeUnitPriceAmount')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_OfficeTax'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(1, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 0)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_OfficeTax')
                      await this.getStsTaxChange(e, 'Lio_OfficeUnitPriceAmount', 'Lio_OfficeTax', 'Lio_OfficeTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_OfficeTax')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='Lio_OfficeTotal'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(1, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_OfficeTotal')
                      await this.getStsTaxChange(e, 'Lio_OfficeUnitPriceAmount', 'Lio_OfficeTax', 'Lio_OfficeTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_OfficeTotal')}
                  />
                </Form.Item>

                {/* </Text> */}
              </Col>

              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  >
                    <Tag
                      color={
                        MagicXpaFunc.MID(
                          this.getFormFieldValue('Protection'),
                          3,
                          1
                        ) == '0' && this.props.Li_OtherGroupDivision > 0
                          ? Color(156).Background
                          : 'grey'
                      }
                      style={{ width: '100%' }}
                    >
                      他団体
                    </Tag>
                  </Text> */}
                <div style={styleDivTitle}>他団体</div>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_OtherGroupUnitPriceAmount'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(2, 1) ==
                        '0' && this.props.Li_OtherGroupDivision > 0
                      ) || !(this.getFormFieldValue('TaxClassify') != 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_OtherGroupUnitPriceAmount')
                      await this.getStsTaxChange(e, 'Lio_OtherGroupUnitPriceAmount', 'Lio_OtherGroupTax', 'Lio_OtherGroupTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_OtherGroupUnitPriceAmount')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_OtherGroupTax'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(2, 1) ==
                        '0' && this.props.Li_OtherGroupDivision > 0
                      ) || !(this.getFormFieldValue('TaxClassify') == 0)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_OtherGroupTax')
                      await this.getStsTaxChange(e, 'Lio_OtherGroupUnitPriceAmount', 'Lio_OtherGroupTax', 'Lio_OtherGroupTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_OtherGroupTax')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='Lio_OtherGroupTotal'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(2, 1) ==
                        '0' && this.props.Li_OtherGroupDivision > 0
                      ) || !(this.getFormFieldValue('TaxClassify') == 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_OtherGroupTax')
                      await this.getStsTaxChange(e, 'Lio_OtherGroupUnitPriceAmount', 'Lio_OtherGroupTax', 'Lio_OtherGroupTotal')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_OtherGroupTotal')}
                  />
                </Form.Item>

              </Col>

              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: '100%' }}
                    >
                      個人１
                    </Tag>
                  </Text> */}
                <div style={styleDivTitle}>個人１</div>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_Personal1UnitPriceAmount'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(3, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') != 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal1UnitPriceAmount')
                      await this.getStsTaxChange(e, 'Lio_Personal1UnitPriceAmount', 'Lio_Personal1Tax', 'Lio_Personal1Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal1UnitPriceAmount')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_Personal1Tax'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(3, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 0)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal1Tax')
                      await this.getStsTaxChange(e, 'Lio_Personal1UnitPriceAmount', 'Lio_Personal1Tax', 'Lio_Personal1Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal1Tax')}
                  />

                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='Lio_Personal1Total'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(3, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal1Total')
                      await this.getStsTaxChange(e, 'Lio_Personal1UnitPriceAmount', 'Lio_Personal1Tax', 'Lio_Personal1Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal1Total')}
                  />
                </Form.Item>

              </Col>

              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: '100%' }}
                    >
                      個人２
                    </Tag>
                  </Text> */}
                <div style={styleDivTitle}>個人２</div>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_Personal2UnitPriceAmount'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(4, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') != 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal2UnitPriceAmount')
                      await this.getStsTaxChange(e, 'Lio_Personal2UnitPriceAmount', 'Lio_Personal2Tax', 'Lio_Personal2Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal2UnitPriceAmount')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_Personal2Tax'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(4, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 0)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal2Tax')
                      await this.getStsTaxChange(e, 'Lio_Personal2UnitPriceAmount', 'Lio_Personal2Tax', 'Lio_Personal2Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal2Tax')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='Lio_Personal2Total'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(4, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal2Total')
                      await this.getStsTaxChange(e, 'Lio_Personal2UnitPriceAmount', 'Lio_Personal2Tax', 'Lio_Personal2Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal2Total')}
                  />
                </Form.Item>

              </Col>

              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: '100%' }}
                    >
                      個人３
                    </Tag>
                  </Text> */}
                <div style={styleDivTitle}>個人３</div>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_Personal3UnitPriceAmount'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(5, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') != 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal3UnitPriceAmount')
                      await this.getStsTaxChange(e, 'Lio_Personal3UnitPriceAmount', 'Lio_Personal3Tax', 'Lio_Personal3Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal3UnitPriceAmount')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='Lio_Personal3Tax'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(5, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 0)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal3Tax')
                      await this.getStsTaxChange(e, 'Lio_Personal3UnitPriceAmount', 'Lio_Personal3Tax', 'Lio_Personal3Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal3Tax')}
                  />
                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='Lio_Personal3Total'>
                  <Input
                    size='small'
                    style={{ width: '100%', margin: 0, textAlign: 'right' }}
                    disabled={
                      !(
                        this.getFormFieldValue('Protection')?.substr(5, 1) ==
                        '0'
                      ) || !(this.getFormFieldValue('TaxClassify') == 2)
                    }
                    onBlur={async (e) => {
                      await this.setValueHankaku(e, 'Lio_Personal3Total')
                      await this.getStsTaxChange(e, 'Lio_Personal3UnitPriceAmount', 'Lio_Personal3Tax', 'Lio_Personal3Total')
                    }}
                    onPressEnter={(e) => this.setValueHankaku(e, 'Lio_Personal3Total')}
                  />
                </Form.Item>

              </Col>

              <Col span={3}>
                {/* <Text
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      padding: '4px 0',
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: '100%' }}
                    >
                      合計
                    </Tag>
                  </Text> */}
                <div style={styleDivTitle}>合計</div>
                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='unitPriceTotal'>
                  <div style={{ marginRight: '10px' }} className='div_data_horizontal'>{this.getFormFieldValue('unitPriceTotal')}</div>
                </Form.Item>

                <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name='taxTotal'>
                  <div style={{ marginRight: '10px' }} className='div_data_horizontal'>{this.getFormFieldValue('taxTotal')}</div>
                </Form.Item>

                <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name='allTotal'>
                  <div style={{ marginRight: '10px' }} className='div_data_horizontal'>{this.getFormFieldValue('allTotal')}</div>
                </Form.Item>
              </Col>
            </Row >
            <div className='box_button_bottom_right'>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type='primary'
                  // htmlType='submit'
                  icon={<SaveOutlined />}
                  onClick={() => this.onFinish()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form >
        </Card >
      </div >
    )
  }
}

export default WS0311005_MoneyAmountInputSub
