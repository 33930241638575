import CreateContractService from 'services/basicInfo/ContractInfoMaintain/CreateContractService'
import { message, } from "antd"

const CreateContractAction = {
  loadScreenData(params) {
    return CreateContractService.loadScreenData(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  loadCourses(params) {
    return CreateContractService.loadCourses(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  onFinish(params) {
    return CreateContractService.onFinish(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default CreateContractAction;