import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";

class WS1314006_XmlMedicalExamItemDataInput extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'XML用健診項目(データ入力)';

    this.state = {
    };
  }

  render() {
    return (
      <div className="xml-medical-exam-item-data-input">
        <Card className="mb-2" title="XML用健診項目(データ入力)">
          <Table bordered 
            dataSource={[]}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: 1,
              pageSize: 1,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="採用日" dataIndex="" key="" />
            <Table.Column title="項目コード" dataIndex="" key="" />
            <Table.Column title="結果識別" dataIndex="" key="" />
            <Table.Column title="区分番号" dataIndex="" key="" />
            <Table.Column title="順番号" dataIndex="" key="" />
            <Table.Column title="条件コード" dataIndex="" key="" />
            <Table.Column title="厚労省項目名" dataIndex="" key="" />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314006_XmlMedicalExamItemDataInput);
