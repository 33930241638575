import React from 'react'
import { connect } from 'react-redux'
import { Card, Input, Button, Form } from 'antd'

const { TextArea } = Input;

class ExtensionInputDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '総合結果表示'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      findings_name: this.props.findings_name ?? ''
    })
    this.forceUpdate()
  }

  render = () => {
    return (
      <div className='extension-input-display' style={{ width: 1000 }}>
        <Card title='入力拡張'>
          <Form ref={this.formRef}>
            <Form.Item name='findings_name'>
              <TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.props.onFinishScreen({
                    result: this.formRef.current?.getFieldValue('findings_name')
                  })
                }}
              >
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionInputDisplay)
