import OldItemService from 'services/ResultOutput/PrintParamMaintain/OldItemService'
import { message } from 'antd'

const OldItemAction = {
  index(data) {
    return OldItemService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        console.log(res.data.message)
        message.error(res.data.message)
      })
  },
  paramIndicationNameObtainingSu(data) {
    return OldItemService.paramIndicationNameObtainingSu(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  save(data) {
    return OldItemService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  instructiondivisionchange(data) {
    return OldItemService.instructiondivisionchange(data)
  },
  outputExcel(data) {
    return OldItemService.outputExcel(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  downloadExcelFile(data) {
    return OldItemService.downloadExcelFile(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })

  },

  readExcelFile(data) {
    return OldItemService.readExcelFile(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })

  },
}
export default OldItemAction
