import axios from "configs/axios";

const API_LIST = {
  getScreenDataAPI: "/api/associate-insure-data-create/medical-exam-result-correct",
};

const MedicalExamResultCorrectService = {
  async getScreenData(params) { 
    return axios.get(API_LIST.getScreenDataAPI, { params });  
  },
};

export default MedicalExamResultCorrectService;
