import React from "react";
import { connect } from "react-redux";

import { Card, Table, Space, Form, Button } from "antd";

class WS2669006_DetailedErrorContentPersonal extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'エラー内容詳細(個人)';

    this.state = {
      date: "2021/11/17",
      number: 10,
      name: "Test"
    };
  }

  render() {
    return (
      <div className="detailed-error-content-personal">
        <Card title="エラー内容詳細(個人)">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <Form.Item label="受診日" style={{ display: "inline-flex", alignItems: "center" }}>
                  <Space style={{ fontWeight: "bold", marginLeft: "10px" }}>
                    {this.state.date}
                  </Space>
                </Form.Item>
                <Form.Item
                  label="受付番号"
                  style={{ display: "inline-flex", alignItems: "center", marginLeft: "20px" }}
                >
                  <Space style={{ fontWeight: "bold", marginLeft: "10px" }}>
                    {this.state.number}
                  </Space>
                </Form.Item>
                <Form.Item
                  label="氏名"
                  style={{ display: "inline-flex", alignItems: "center", marginLeft: "20px" }}
                >
                  <Space style={{ fontWeight: "bold", marginLeft: "10px" }}>
                    {this.state.name}
                  </Space>
                </Form.Item>
              </Space>
              <Form.Item>
                <Button type="primary">再チェック</Button>
              </Form.Item>
            </Space>
            <Table loading={this.state.isLoadingTable} pagination={false} scroll={{ y: 400 }}>
              <Table.Column title="レベル" dataIndex="" width={100} />
              <Table.Column title="コード" dataIndex="" width={100} />
              <Table.Column title="エラー内容" dataIndex="" />
            </Table>
            <Space style={{ display: "block", marginTop: "10px" }}>
              <Space style={{ color: "#14468C", fontWeight: "bold", marginBottom: "10px" }}>
                対処法
              </Space>
              <div style={{ height: "150px", border: "1px solid #eee", padding: "10px" }}>1000</div>
            </Space>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2669006_DetailedErrorContentPersonal);
