import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Table, } from 'antd'
import LeadershipMatterSearchSubAction from 'redux/InputBusiness/RadiographyFindingInput/LeadershipMatterSearchSub.action'
class WS1865001_LeadershipMatterSearchSub extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '指導事項検索 SUB'

    this.state = {
      dataSource: [],
      dataForm: {},
      Li_InspectClassifyCode: null,
      CategoryCode: '全て'
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    this.setState({ isLoadingForm: true })
    const data = {
      Li_PatternCode: this.props.Li_PatternCode ? this.props.Li_PatternCode : '0000-000',
      Li_CategoryCode: this.props.Li_CategoryCode ? this.props.Li_CategoryCode : 0,
      Li_IdentifyCode: this.props.Li_IdentifyCode ? this.props.Li_IdentifyCode : 'S',
      Li_InspectClassifyCode: this.props.Li_InspectClassifyCode,
      LnkOutCmtCode: 0
    }
    LeadershipMatterSearchSubAction.getScreenDataAction(data)
      .then((res) => {
        if (res) {
          this.setState({ dataForm: res, Li_InspectClassifyCode: this.props.Li_InspectClassifyCode })
          this.getListGuideItemInfoTableDisplay()
        }
      })
      .finally(() => {
        this.setState({ isLoadingForm: false })
      })
  }

  getListGuideItemInfoTableDisplay(change) {
    console.log(change)
    console.log(this.state.Li_InspectClassifyCode)
    this.setState({ isLoadingTable: true })
    const data = {
      Li_CategoryCode: change ? change.CategoryCode : 0,
      Li_IdentifyCode: this.props.Li_IdentifyCode ? this.props.Li_IdentifyCode : 'S',
      Li_InspectClassifyCode: this.state.Li_InspectClassifyCode,
      Li_Search: change ? change.Search : '',
      Li_GuideContentKeyword: change ? change.GuideContentKeyword : ''
    }
    LeadershipMatterSearchSubAction.getListGuideItemInfoTableDisplayAction(data)
      .then((res) => {
        if (res) {
          console.log(res)
          this.setState({ dataSource: res.Result })
        }
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }

  handleSelectRowsTableFirst = selectedRowTableFirst => {
    console.log(selectedRowTableFirst)
    this.setState({ selectedRowTableFirst })
  }

  selectDataDisplay(comment_code) {
    const data = {
      Li_CategoryCode: this.props.Li_CategoryCode ? this.props.Li_CategoryCode : 0,
      comment_code: comment_code
    }
    LeadershipMatterSearchSubAction.selectDataDisplayAction(data)
      .then((res) => {
        if (res) {
          console.log(res)
        }
      })
      .finally(() => {
      })
  }

  onChangeInput(e, name) {
    let change = {
      Search: '',
      GuideContentKeyword: '',
      CategoryCode: ''
    }
    change[name] = e
    if (change.CategoryCode > 0) {
      this.getListGuideItemInfoTableDisplayCategory(change)
    } else {
      this.getListGuideItemInfoTableDisplay(change)
    }
  }

  getListGuideItemInfoTableDisplayCategory(change) {
    this.setState({ isLoadingTable: true })
    const data = {
      Li_CategoryCode: change.CategoryCode ? change.CategoryCode : 0,
      Li_IdentifyCode: this.props.Li_IdentifyCode ? this.props.Li_IdentifyCode : 'S',
      Li_Search: change.Search ? change.Search : '',
      Li_GuideContentKeyword: change.GuideContentKeyword ? change.GuideContentKeyword : ''
    }
    LeadershipMatterSearchSubAction.getListGuideItemInfoTableDisplayCategoryAction(data)
      .then((res) => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }

  render() {
    const dataForm = this.state.dataForm
    return (
      <div className='leadership-matter-search-sub'>
        <Card title='指導事項検索'>
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{ CategoryCode: this.state.CategoryCode }}
          >
            <div className='box_inner_horizontal'>
              <Form.Item
                name='Search'
                label='検索'
              >
                <Input
                  onBlur={(e) => (this.onChangeInput(e.target.value, 'Search'))}
                  onPressEnter={(e) => (this.onChangeInput(e.target.value, 'Search'))}
                />
              </Form.Item>

              <Form.Item
                name='GuideContentKeyword'
                label='内容'
              >
                <Input
                  onBlur={(e) => this.onChangeInput(e.target.value, 'GuideContentKeyword')}
                  onPressEnter={(e) => this.onChangeInput(e.target.value, 'GuideContentKeyword')}
                />
              </Form.Item>

              <Form.Item
                name='CategoryCode'
                label='検査区分'
              >
                <Select onChange={(e) => this.onChangeInput(e, 'CategoryCode')}>
                  {dataForm.Expression_2_3 && dataForm.Expression_2_3.map((res) => {
                    return (
                      <Select.Option
                        key={res.id}
                        value={res.id} >
                        {res.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>
          </Form>

          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            // rowSelection={{ type: 'radio', ...rowSelectionTableFirst }}
            scroll={{ y: 500 }}

            onRow={(record, index) => ({
              onDoubleClick: (e) => {
                // this.setState({ rowSelect: record })
                this.selectDataDisplay(record.comment_code)
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    Lo_CommentContent: record.comment_content,
                    Lo_CommentCode: record.comment_code,
                  })
                }
              }
            })}
          >
            <Table.Column
              title='コメントコード'
              dataIndex='comment_code'
              width={100}
              render={(row, record, index) => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0px', textAlign: 'center' }}
                    name='comment_code'
                  >
                    <span>{record.comment_code}</span>
                  </Form.Item>
                )
              }}
            />
            <Table.Column
              title='検索キー'
              dataIndex='search_key'
              width={150}
            />
            <Table.Column
              title='コメント内容'
              dataIndex='comment_content'
              width={600}
            />
            <Table.Column
              title='優先度'
              dataIndex='priority'
              width={100}
              render={(row, record, index) => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0px', textAlign: 'center' }}
                    name='priority'
                  >
                    <span>{record.priority}</span>
                  </Form.Item>
                )
              }}
            />
            <Table.Column width={100} style={{ textAlign: 'end' }} title='使用回数' dataIndex='number_of_times_of_use'
              render={(row, record, index) => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0px', textAlign: 'center' }}
                    name='number_of_times_of_use'
                  >
                    <span>{record.number_of_times_of_use}</span>
                  </Form.Item>
                )
              }}
            />
            <Table.Column width={150} title='最終使用日' dataIndex='last_used_on'
              render={(row, record, index) => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0px', textAlign: 'center' }}
                    name='last_used_on'
                  >
                    <span> {record.last_used_on}</span>
                  </Form.Item>
                )
              }}
            />
          </Table>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1865001_LeadershipMatterSearchSub)
