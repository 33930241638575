import React from "react";
import { connect } from "react-redux";

import { Card, Input, Table, Form, message, Button, Modal, Space, Row, Col, Dropdown, Menu } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons';
import {
  getSpecificHealthTokuhoOptionsFileAction, saveAndUpdateSpecificHealthTokuhoAction, deleteSpecificHealthTokuhoOptionsFileAction
} from "redux/SpecificInsureMaintenance/SpecificHealthTokuhoOptionsFile/SpecificHealthTokuhoOptionsFile.actions";
import Checkbox from "antd/lib/checkbox/Checkbox";
import WS1306003_CopyingProcess from "pages/TM_SpecificInsureMaintenance/V4TK0030000_SpecificHealthTokuhoOptionsFile/WS1306003_CopyingProcess.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import { generateUUID } from 'components/Commons/generateUUID'

const styleFormItem = {
  margin: 0
}
/* const styleInput = {
  border: 'none'
} */
class WS1306001_SpecificHealthTokuhoOptionsFile extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '特健特保オプションファイル';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRowKey: [],
      rowSelect: [],
      selectedRow: {},
      isLoadingTable: false,
      editPage: true,
      remarks: '',
    };
    this.updateOrCreateData = this.updateOrCreateData.bind(this);
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData(values) {
    this.setState({ isLoadingTable: true })
    this.setState({ dataSource: [], rowSelect: [] })

    getSpecificHealthTokuhoOptionsFileAction()
      .then(res => {

        const data = res.data.map((item) => ({ ...item, changed: false }))

        this.setState({ dataSource: data, rowSelect: data.length > 0 ? data[0] : [] })



      })
      .catch()
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  addNewRow = () => {

    const newdata = { id: generateUUID(), option_type: '', options: '', remarks: '', changed: false }

    const tmp = [...this.state.dataSource]

    tmp.length > 0 ? tmp.unshift(newdata) : tmp.push(newdata)

    this.setState({ dataSource: [] }, () => { this.setState({ dataSource: tmp }) })

  }

  updateOrCreateData = (record) => {

    this.setState({ isLoadingTable: true });

    saveAndUpdateSpecificHealthTokuhoAction(record).then(res => {
      message.success(res.data.message)
      this.loadData()
    }).catch(err => {
      console.log(err)
      message.error(err.message)
    }).finally(() => this.setState({ isLoadingTable: false }))

  }

  delete = (record) => {
    deleteSpecificHealthTokuhoOptionsFileAction({ id: record.id })
      .then(res => {
        message.success(res.data.message)
        this.loadData()
      }).catch(err => {
        console.log(err)
        message.error(err.message)
      })
  }

  copyingProcess = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (<WS1306003_CopyingProcess
          selectedRow={record}
          onFinishScreen={(output) => {
            this.closeModal();
            this.loadData();
          }}
        />),
      },
    });
  }



  render() {
    return (
      <div className="specific-health-tokuho-options-file">
        <Form ref={this.formRef} onFinish={this.onFinish} autoComplete='off'>
          <Card className="mb-3" title='特健特保オプションファイル'>

            <Table bordered style={{ height: "70vh", overflow: 'auto' }} className='mt-3'
              dataSource={this.state.dataSource} loading={this.state.isLoadingTable}
              pagination={false}
              rowKey={(record, i) => i}
              onRow={(record, index) => ({
                onClick: e => {
                  this.setState({ rowSelect: record });
                }
              })}

            >
              <Table.Column width={'300px'} title="種　別" dataIndex="option_type"
                sorter={(a, b) => a.option_type?.localeCompare(b.option_type, 'jp')}
                showSorterTooltip={false}
                size='small'
                render={(text, record) => (
                  <Input
                    size='small'
                    defaultValue={text}
                    onChange={(e) => {
                      if (record.option_type !== e.target.value) {
                        record.changed = true
                      }
                      record.option_type = e.target.value
                    }}

                  ></Input>
                )}

              />
              <Table.Column title="オ　プ　シ　ョ　ン" dataIndex="options"
                sorter={(a, b) => a.options?.localeCompare(b.options, 'jp')}
                size='small'
                showSorterTooltip={false}
                render={(text, record) => (
                  <Input
                    size='small'
                    defaultValue={text}
                    onChange={(e) => {
                      if (record.options !== e.target.value) {
                        record.changed = true
                      }


                      if (e.target.value.includes('　')) {
                        record.options = e.target.value.replace(/　/g, ' ')
                      } else {
                        record.options = e.target.value
                      }

                      // 上記処理では画面上のデータは更新されないので以下の処理で更新する
                      const tmp = [...this.state.dataSource]
                      this.setState({ dataSource: [] }, () => { this.setState({ dataSource: tmp }) })


                    }}

                  ></Input>
                )}
              />
              <Table.Column align='center' width={'100px'}
                title={() => (
                  <Button size='small' style={{ display: this.state.editPage ? '' : 'none' }} type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.addNewRow()
                    }}></Button>
                )}
                render={(text, record, index) => (
                  <>
                    <Button size='small' style={{ border: 'none', }}
                      icon={<SaveOutlined style={{ color: !record.changed ? 'gainsboro' : 'green' }} />}
                      disabled={!record.changed}
                      onClick={() => {
                        this.updateOrCreateData(record)
                      }}
                    ></Button>


                    <Button size='small' style={{ border: 'none', display: this.state.editPage ? '' : 'none' }}
                      danger icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          content: '消去してもよろしいですか？',
                          okText: 'は　い',
                          cancelText: 'いいえ',
                          onOk: () => {
                            this.delete(record)
                          }
                        })
                      }}
                    ></Button>
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            onClick={() => this.copyingProcess(record)}
                          >
                            複写
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>



                  </>
                )}
              />
            </Table>
          </Card>

          <Card>
            <Row>
              <Col span={1}>
                {'備考'}
              </Col>
              <Col span={23}>
                <Input name='remarks'
                  style={styleFormItem}
                  onChange={(e) => {
                    const { ...rowSelect } = this.state.rowSelect
                    rowSelect.remarks = e.target.value
                    this.setState({ rowSelect: rowSelect })

                  }}
                  defaultValue={this.state.rowSelect.remarks}
                  value={this.state.rowSelect.remarks}
                />
              </Col>
            </Row>



          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1306001_SpecificHealthTokuhoOptionsFile);
