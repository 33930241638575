import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Button } from 'antd'
import FormatSelectAction from 'redux/IntroductionLetter/IntroduceLetterExtract/FormatSelect.action.js'
import PropTypes from 'prop-types'
import WS0433001_PrinterConfirm from '../V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm'
class WS2589011_FormatSelect extends React.Component {
  static propTypes = {
    Lio_PrinterNo: PropTypes.any,
    Lio_Preview: PropTypes.any,
  }
  constructor(props) {
    super(props)

    // document.title = '書式選択'

    this.state = {
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    FormatSelectAction.index()
      .then(res => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
  }

  /**
   * 印刷確認
   */
  showWS0433001_PrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: 'fit-content',
        visible: true,
        component:
          <WS0433001_PrinterConfirm
            {...this.props}
            onFinishScreen={(output) => {
              this.setDataOutput(output)
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * 印刷・発行
   * @param {*} output
   */
  setDataOutput = (output) => {
    let Lio_Preview = output.Lo_Preview
    let Lio_PrinterNo = output.Lo_PrinterNo
    let Lo_StsEnter = output.Lo_StsOutput

    if (!Lo_StsEnter) return

    const params = {
      // 選択された
      selectedIdList: this.state.selectedRowKeys
    }
    FormatSelectAction.confirm(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          console.log(res);
          const output = {
            Lo_Preview: Lio_Preview,
            Lo_PrinterNo: Lio_PrinterNo,
            Lo_StsOutput: res.StsOutput ?? false,
            Lo_FormatList: res.FormatList ?? ''
          }
          console.log(output);
          // 親画面に渡す
          this.props.onFinishScreen(output)
        }
      })

  }

  /**
 * チェックボックス 選択
 * @param {*} record
 * @param {*} selected
 */
  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='format-select'>
        <Card title='書式選択'>
          <Form>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record, selected) => {
                  // 選択
                  this.onSelect(record, selected)
                },
                onSelectAll: (selected) => {
                  // 全選択
                  this.onSelectAll(selected)
                }
              }}
            >
              <Table.Column
                title='書式'
                dataIndex='Expression_4'
              />
            </Table>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.showWS0433001_PrinterConfirm()}
              >
                <span className='btn_label'>
                  確定
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2589011_FormatSelect)
