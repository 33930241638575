import React from 'react'
import { connect } from 'react-redux'

import { Form, Input, Button, Card, Alert, } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { loginUserInfo, setHospital } from 'redux/user/user.actions'
import { clear } from 'redux/alert/alert.actions'
import { history } from 'constants/BrowserHistory'

import './WS2517001_SignInScreen.scss'
import logoImage from 'assets/img/alpha_salus_logo.png'

// import { Layout } from 'antd'

class WS2517001_SignInScreen extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    document.title = props.title ?? 'サインイン' + ' - アルファ・サルース'

    this.state = {
      hospitalCode: props.match.params.hospital,

      // Status
      loading: false,
      modifyModalVisible: false,
      checkbox: true,
    }

    this.props.setHospital(this.state.hospitalCode)

    this.onFinish = this.onFinish.bind(this)

    if (props.currentUser) {
      history.push('/')
    }
  }

  onFinish(values) {
    this.props.clear()
    this.props.loginUserInfo({
      hospital: this.state.hospitalCode,
      username: values.username,
      remember: this.state.checkbox,
      password: values.password,
    }).then(() => {

    })
  }

  onChange = e => {
    this.setState({
      checkbox: e.target.checked,
    })
  }

  render() {

    return (
      <div className='login-page'>
        {/* <div style={{ border: true, width: '100%', height: '60px', display: 'flex', backgroundColor: 'white' }}><img src={logoImage}></img></div> */}

        <div style={{ minHeight: '75vh' }}>
          <div style={{ maxWidth: '600px', margin: '25vh auto' }}>
            <div style={{ width: '80%', height: '100px', display: 'flex', margin: '10px auto 10px auto' }}>
              <img src={logoImage}></img>
            </div>
            <Card
              // title={'ログイン施設先名：' + this.state.hospitalCode}
              // title={<div style={{ border: true, width: '100%', height: '50px', display: 'flex', backgroundColor: 'white' }}><img src={logoImage}></img></div>}
              style={{ fontFamily: 'Open Sans,Meiryo,メイリオ,Arial,sans-serif', fontSize: '30' }}
            >
              {/* <Spin> */}
              {this.props.alertReducer.message ? (
                <Alert
                  style={{ marginBottom: '15px' }}
                  showIcon
                  message={this.props.alertReducer.message}
                  type={this.props.alertReducer.type || 'error'}
                />
              )
                :
                null
              }
              <Form
                ref={this.formRef}
                name='login_form'
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                style={{ margin: '0 auto', width: '80%', padding: '20px 20px' }}
                autoComplete='off'
              >
                <Form.Item
                  name='username'
                  style={{ height: '60px' }}
                // label='ユーザー名'
                // rules={[{ required: true, }]}
                >
                  <Input
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    placeholder='　ログイン名'
                    style={{ width: '100%', height: '45px' }}
                  />
                </Form.Item>

                <Form.Item
                  name='password'
                  style={{ height: '60px' }}
                // label='パスワード'
                // rules={[{ required: true, }]}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder='　パスワード'
                    style={{ fontFamily: 'sans-serif', width: '100%', height: '45px', margin: 'auto 10' }}
                  />
                </Form.Item>

                {/*
                <Form.Item
                  {...tailLayout}
                  name='remember' valuePropName='checked'
                >
                  <Checkbox
                    checked={this.state.checkbox}
                    onChange={this.onChange} >
                    ログイン状態を保持する
                  </Checkbox>
                </Form.Item> */}

                {/* <Form.Item
                  {...tailLayout}
                > */}

                <Form.Item
                  style={{ height: '60px', fontSize: '30' }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%', height: '45px', fontSize: '20px' }}
                  >
                    サインイン
                  </Button>
                </Form.Item>

              </Form>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
  alertReducer: alertReducer,
  currentUser: userReducer.user,
})
const mapDispatchToProps = (dispatch) => ({
  loginUserInfo: (userInfo) => dispatch(loginUserInfo(userInfo)),
  setHospital: (hospitalCode) => dispatch(setHospital(hospitalCode)),
  clear: () => dispatch(clear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2517001_SignInScreen)
