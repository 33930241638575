import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Input, Button, Table, Row, Col, message, Select } from 'antd'
import { DoubleRightOutlined, DoubleLeftOutlined, SaveOutlined } from '@ant-design/icons'
import SetInfoChangeSubAction from 'redux/basicInfo/SetInfoMaintain/SetInfoChangeSub.action'
import { SetIncludesQueryAction } from 'redux/ReservationBusiness/PersonalReserveProcess/SetIncludesQuery.action'
import Color from 'constants/Color'

class WS0333400_SetIncludesQueryInquiryAdd extends React.Component {
  static propTypes = {
    Li_StartDate: PropTypes.any,
    Li_SetCode: PropTypes.any,
    Li_CourseCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = 'セット内容照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataExamList: [],
      objMoreDetail: {
        Li_StartDate: '',
        Li_SetCode: ''
      },
      initParams: {
        Li_StartDate: '',
        Li_SetCode: '',
        Li_CourseCode: '',
        Search: ''
      },
      textCondition: '条件なし',
      colorCondition: 156,
      title: '',
      tableDataPaginate: {},
      rightTableDataPaginate: {},
      selectOption: 0,
      saveData: [],
      deleteData: [],
      rightTableOrg: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.getRightListData()
  }

  /**
   * 右側テーブル　データ取得
   */
  getRightListData = () => {
    const params = {
      Li_StartDate: this.props.onRow.start_date_on || '',
      Li_SetCode: this.props.onRow.set_code || '',
      Li_CourseCode: this.props.onRow.set_code || '',
      Search: '',
    }
    SetIncludesQueryAction.getSetIncludesQuery(params)
      .then(async (res) => {
        await this.setState({
          dataSourceRight: res.DataTable ? res.DataTable : [],
          rightTableOrg: res.DataTable ? res.DataTable : [],
          rowSelectRight: [],
          enabledBackward: false
        })

        if (this.state.selectOption === 0) {
          this.getLeftExamListData()
        } else {
          this.getLeftSetListData()
        }

        if (this.state.changeValue) {
          this.onChange()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 検査選択　データ取得
   */
  getLeftExamListData() {
    let params = {
      Li_SetCode: this.props.onRow.set_code,
      SetCode: this.props.onRow.set_code,
      StartDate: this.props.onRow.start_date_on,
      SearchInspect: this.formRef.current?.getFieldValue('SearchInspect')
    }
    SetInfoChangeSubAction.getLeftExamListData(params)
      .then((res) => {
        let data = res ? res : []
        data.map(x => (
          x.textColor = this.state.dataSourceRight.findIndex(y => y.test_item_code === x.test_item_code) === -1 ? '' : '#b9b9b9'
        ))

        this.setState({
          dataSourceLeft: res ? data : [],
          rowSelectLeft: [],
          enabledForward: false,
        })
        this.formRef.current?.setFieldsValue({ Select: this.state.selectOption })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * セット選択　データ取得
   */
  getLeftSetListData() {
    let params = {
      Li_SetCode: this.props.onRow.set_code,
      SetCode: this.props.onRow.Lio_SetCode,
      Select: this.formRef.current?.getFieldValue('Select'),
      StartDate: this.props.onRow.start_date_on,
      SearchInspect: this.formRef.current?.getFieldValue('SearchInspect')
    }

    SetInfoChangeSubAction.getLeftSetListData(params)
      .then((res) => {
        let data = res[0] ? res[0] : []
        data.map(x => (
          x.textColor = this.state.dataSourceRight.findIndex(y => y.W2_inspect_cd === x.set_code) === -1 ? '#b9b9b9' : ''
        ))

        this.setState({
          dataSourceLeft: res[0] ? data : [],
          rowSelectLeft: [],
          enabledForward: false,
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onAddItem = (value) => {
    let addData = [...this.state.saveData]
    let copyData = [...this.state.dataSourceRight]
    copyData.push(value)
    addData.push(value)
    copyData.map(x => (
      x.textColor = this.state.dataSourceLeft.findIndex(y => y.test_item_code === x.W1_inspect_code) === -1 ? '#b9b9b9' : ''
    ))
    let duplicationCheck = copyData.filter((e, index, self) => {
      return self.findIndex((data) => data.test_item_code === e.test_item_code) === index
    })
    let addDataduplicationCheck = addData.filter((e, index, self) => {
      return self.findIndex((addValue) => addValue.test_item_code === e.test_item_code) === index
    })
    duplicationCheck.sort(function (a, b) {
      if (a.test_item_code > b.test_item_code) {
        return 1
      } else {
        return -1
      }
    })
    this.setState({
      dataSourceRight: duplicationCheck,
      rowSelectLeft: null,
      saveData: addDataduplicationCheck,
      enabledForward: false
    })
  }


  onRemoveItem(value) {
    let copyData = [...this.state.dataSourceRight]
    let addData = [...this.state.saveData]
    let copyTable = [...this.state.dataSourceLeft]
    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i] === value) {
        let delteLenght = i
        copyData.splice(delteLenght, 1)
      }
    }
    for (let k = 0; k < addData.length; k++) {
      if (addData[k] === value) {
        let dataDelete = k
        addData.splice(dataDelete, 1)
      }
    }
    // copyData.map(x => (
    //   x.textColor = this.state.dataSourceLeft.findIndex(y => y.test_item_code === x.W1_inspect_code) === -1 ? '#b9b9b9' : ''
    // ))
    copyTable.forEach((item) => {
      if (value.test_item_code === item.test_item_code) {
        item.textColor = ''
      }
    })
    this.setState({
      dataSourceRight: copyData,
      saveData: addData,
      dataSourceLeft: copyTable,
      // deleteData: {
      //   ...this.state.deleteData,
      //   value: value.id
      // },
      rowSelectLeft: null,
      enabledBackward: false
    })
  }

  onAddSetSelect() {
    let params = {
      selectSetCode: this.state.rowSelectLeft.set_code,
      SetCode: this.props.onRow.set_code,
      StartDate: this.props.onRow.start_date_on,
      Select: this.state.selectOption
    }
    SetInfoChangeSubAction.getSetSelectExamList(params)
      .then((res) => {
        let copyData = [...this.state.dataSourceRight]
        let rightTableData = copyData.concat(res)
        let newTableData
        let duplicationCheck = rightTableData.filter((e, index, self) => {
          return self.findIndex((value) => value.test_item_code === e.test_item_code) === index
        })
        this.setState({
          dataSourceRight: duplicationCheck,
          rowSelectLeft: null,
          enabledForward: false
        })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  /**
   * 保存処理
   */
  save() {
    let params = {
      setTable: this.state.dataSourceRight,
      setTableOrg: this.state.rightTableOrg,
      SetCode: this.props.onRow.set_code,
      StartDate: this.props.onRow.start_date_on,
      Select: this.state.selectOption
    }
    SetInfoChangeSubAction.saveSetInfo(params)
      .then((res) => {
        message.success('保存が完了しました')
        // this.callApiGetRightListData()
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  render() {
    return (
      <div className='set-includes-query-add'>
        <Card title='セット内容追加'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Row>
              <Col span={11}>
                <div
                  className='table_header_filter'
                >
                  <Form.Item name='Select' style={{ margin: '0 20px 0 0' }}>
                    <Select style={{ width: 110 }}
                      size='small'
                      onChange={(value) => {
                        this.setState({ selectOption: value })
                        if (value === 0) {
                          this.getLeftExamListData()
                        } else {
                          this.getLeftSetListData()
                        }
                      }}
                    >
                      <Select.Option value={0}>検査選択</Select.Option>
                      <Select.Option value={1}>セット選択</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ margin: '0' }}>
                    <label style={{ color: '#fff' }}> 検索</label>
                  </Form.Item>
                  <Form.Item
                    name='SearchInspect'
                    style={{ margin: '0' }}
                  >
                    <Input
                      size='small'
                      style={{ width: '100%', gap: '10px', marginLeft: '10px' }}
                      onPressEnter={() => {
                        if (this.state.selectOption === 0) {
                          this.getLeftExamListData()
                        } else {
                          this.getLeftSetListData()
                        }
                      }}
                    />
                  </Form.Item>
                </div>
                <div hidden={this.state.selectOption === 1}>
                  <Table
                    size='small'
                    style={{ cursor: 'pointer' }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectLeft?.id ? 'table-row-light' : ''}
                    dataSource={this.state.dataSourceLeft}
                    pagination={this.state?.dataSourceLeft?.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    bordered={true}
                    rowKey={(record) => record.id}
                    scroll={{ y: 350 }}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.onAddItem(record)
                        },
                        onClick: async () => {
                          await this.setState({
                            rowSelectLeft: record,
                            enabledForward: true,
                            enabledBackward: false,
                          })
                        }
                      }
                    }}
                  >
                    <Table.Column dataIndex='test_item_code' title='検査ｺｰﾄﾞ'
                      render={(text, record, index) => (
                        <span style={{ color: record.textColor }}>{record.test_item_code}</span>
                      )}
                    />
                    <Table.Column dataIndex='exam_short_name' title='検査略名'
                      render={(text, record, index) => (
                        <span style={{ color: record.textColor }}>{record.exam_short_name}</span>
                      )} />
                    <Table.Column dataIndex='exam_name' title='名称'
                      render={(text, record, index) => (
                        <span style={{ color: record.textColor }}>{record.exam_name}</span>
                      )} />
                  </Table>
                </div>
                <div hidden={this.state.selectOption === 0}>
                  <Table
                    size='small'
                    style={{ cursor: 'pointer' }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectLeft?.id ? 'table-light' : ''}
                    dataSource={this.state.dataSourceLeft}
                    pagination={this.state?.dataSourceLeft?.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    bordered={true}
                    rowKey={(record) => record.id}
                    scroll={{ y: 350 }}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.onAddSetSelect()
                        },
                        onClick: async () => {
                          await this.setState({
                            rowSelectLeft: record,
                            enabledForward: true,
                            enabledBackward: false,
                          })
                        }
                      }
                    }}
                  >
                    <Table.Column dataIndex='set_code' title='ｾｯﾄｺｰﾄﾞ'
                      render={(text, record, index) => (
                        <span style={{ color: Color(record.Expression_10)?.Foreground }}>{record.set_code}</span>
                      )}
                    />
                    <Table.Column dataIndex='set_short_name' title='略名'
                      render={(text, record, index) => (
                        <span style={{ color: Color(record.Expression_10)?.Foreground }}>{record.set_short_name}</span>
                      )} />
                    <Table.Column dataIndex='set_name' title='名称'
                      render={(text, record, index) => (
                        <span style={{ color: Color(record.Expression_10)?.Foreground }}>{record.set_name}</span>
                      )} />
                  </Table>
                </div>
              </Col>

              <Col
                span={2}
                style={{ textAlign: 'center', alignSelf: 'center' }}
              >
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type='primary'
                    disabled={!this.state.enabledForward}
                    size='small'
                    onClick={() => this.state.selectOption === 0 ? this.onAddItem(this.state.rowSelectLeft) : this.onAddSetSelect()}
                  >
                    追加 <DoubleRightOutlined />
                  </Button>
                  <br />
                  <br />
                  <Button
                    type='primary'
                    disabled={!this.state.enabledBackward}
                    size='small'
                    icon={<DoubleLeftOutlined />}
                    onClick={() => this.onRemoveItem(this.state.rowSelectRight)}
                  >
                    削除
                  </Button>
                </div>
              </Col>

              <Col span={11}>
                <div
                  className='table_header_filter'
                >
                  <Form.Item style={{ margin: '0' }}>
                    <label style={{ color: '#fff' }}> セット内容</label>
                  </Form.Item>
                </div>
                <Table
                  size='small'
                  style={{ cursor: 'pointer' }}
                  rowClassName={(record, index) => record.id === this.state.rowSelectRight?.id ? 'table-row-light' : ''}
                  dataSource={this.state.dataSourceRight}
                  // pagination={{ ...this.state.pagination }}
                  pagination={this.state?.dataSourceRight?.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  bordered={true}
                  rowKey={(record) => record.id}
                  scroll={{ y: 350 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.onRemoveItem(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectRight: record,
                          enabledForward: false,
                          enabledBackward: true,
                        })
                      }
                    }
                  }}
                >
                  <Table.Column dataIndex='test_item_code' title='検査ｺｰﾄﾞ' width={80}
                    render={(value, record) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      )
                    }}
                  />
                  <Table.Column dataIndex='exam_short_name' title='検査略名' />
                  <Table.Column dataIndex='exam_name' title='検査名称' />
                  <Table.Column dataIndex='Expression_10' title='使用' align='center' width={50} />
                </Table>
              </Col>
            </Row>
          </Form>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => this.save()}
              icon={<SaveOutlined />}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
            {/* <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  // onConfirm={() => this.deleteCommissionedOriginalInput()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type='primary'
                    icon={<DeleteOutlined />}
                  >
                    削除
                  </Button>
                </Popconfirm> */}
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0333400_SetIncludesQueryInquiryAdd)
