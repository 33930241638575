import axios from "configs/axios";

const APP_LIST = {
  getScreenData: "/api/copying-process/get-screen-data",
  f12Key: "/api/copying-process/f12-key",
};

const CopyingProcessService = {
  async getScreenData() {
    return axios.get(APP_LIST.getScreenData);
  },
  async f12Key(data) {
    return axios.post(APP_LIST.f12Key, data);
  }
};

export default CopyingProcessService;