import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Modal, Select, Checkbox, InputNumber } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import WS0276001_ProgramSearch from 'pages/SM_SystemMaintenance/V4SM0006001_ProgressInfoMaintain/WS0276001_ProgramSearch.jsx';

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}

class WS1499002_ProgressInfoMaintainEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '進捗情報保守'

    this.state = {
      programList: [],
      processList: [],
      disabledButton: true,

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount = () => {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
    console.log(data)

    this.setState({
      programList: this.props.programList,
      processList: this.props.processList
    })

  }

  /**
   * データ保存
   * @param {*} values
   */
  onFinish = (values) => {
    let id = ''
    if (this.props.record) {
      id = this.props.record.id
    } else {
      id = ''
    }

    let param = {
      ...values,
      id: id
    }
    this.props.onUpdate(param)
  }

  /**
   * データ削除
   * @param {*} event
   */
  delete = () => {
    this.props.onDelete()
  }

  onValuesChange = (event) => {
    let data = this.formRef.current?.getFieldsValue()

    if (data.child_node && data.name) {
      this.setState({ disabledButton: false })
    } else {
      this.setState({ disabledButton: true })
    }

  }

  render() {
    return (
      <div className='progress-info-maintain'>
        <Card title={'進捗情報保守 [' + (this.props.newFlag ? '新規' : '変更') + ']'}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={(event) => this.onValuesChange(event)}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='コード'
                name='child_node'//対応したものに書き換え
                {...labelCol}
              >
                <Input maxLength="6"
                  readOnly={!this.props.newFlag}
                  bordered={this.props.newFlag}
                />
              </Form.Item>

              <Form.Item
                label='名称'
                name='name'//対応したものに書き換え
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='手動'
                name='division'//対応したものに書き換え
                valuePropName='checked'
                {...labelCol}
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                label='表示順'
                name='options'//対応したものに書き換え
                {...labelCol}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label='機能'
                name='program'//対応したものに書き換え
                {...labelCol}
              >
                <Select style={{ width: 150 }}>
                  {this.state.programList?.map((value, index) => (
                    <Select.Option
                      key={index}
                      value={value.value}
                    >
                      {value.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label='発行'
                name='process'//対応したものに書き換え
                {...labelCol}
              >
                <Select style={{ width: 150 }}>
                  {this.state.processList?.map((value, index) => (
                    <Select.Option
                      key={index}
                      value={value.value}
                    >
                      {value.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
                disabled={this.state.disabledButton}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1499002_ProgressInfoMaintainEdit)
