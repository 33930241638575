///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './WS0743001_NotInputCheckCategory.scss'
import Color from 'constants/Color'
import moment from 'moment'
import { connect } from 'react-redux'
import { Card, Form, Input, Radio, Button, Tooltip, Space, Menu, Modal, Dropdown, message, } from 'antd'
import { SearchOutlined, MoreOutlined, CaretUpOutlined, CloseOutlined, MenuOutlined, PlusOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers'
import GetImage from 'constants/Images'
import WS0745001_NotInputCheckMaintain from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745001_NotInputCheckMaintain.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS0743010_TypeQuery from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0743010_TypeQuery.jsx'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx'
import status_input_lock from 'assets/img/status_input_lock.png'
import NotInputCheckCategorytAction from 'redux/InputBusiness/NotInputCheckCategory/NotInputCheckCategory.action'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import ResultDisplay from "pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx"
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import DataGrid, { Column } from 'devextreme-react/data-grid'
// import resizableTableScroll from 'components/Commons/ResizableTableScroll'
// import MemoFunction from "pages/ZZ_Others/SANAI_Memo/MemoFunction.jsx"

// Form labelの幅
const labelCol = { labelCol: { style: { width: '50px' } } }
const titleStyle = { writingMode: 'vertical-rl', textAlign: 'center' }
class WS0743001_NotInputCheckCategory extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '未入力チェック';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
      disabled: true,

      rowSelected: {},
      selectedRowKeys: [],
      tableIndex: 0,

      paramsUpdateInspectInput: {},
      isLoadingPage: false,
      Expression_36: 163,
      categoryList: [],
      conditionAddData: null,
      menuFlag: false,

      // 全体メモ保存ボタンの活性化情報
      AllMemoFunctionUpdateBtn: true
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    });
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    NotInputCheckCategorytAction.getScreenData().
      then((res) => {
        this.setFormFieldValue('Type', res && res.Type ? res.Type : '');
        this.setFormFieldValue('remarks', res && res.remarks ? res.remarks : '');
        this.formRef.current?.setFieldsValue({ DateF: moment(new Date().toISOString()) })
        this.setState({
          menuFlag: res.menuFlag
        })
        this.forceUpdate()
      });
  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }

  // 照会処理
  WS2583001_ConsultInquirySub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.W1_reserve_num}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  // 出力処理
  WS3020036_CoupledPrintInstruction(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={record.W1_course_level}
            Li_ReserveNum={record.W1_reserve_num}
            Li_Parameters={record.W1_parameters}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  // 入力処理
  WS2637001_OverallResultDisplayInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1700,
        component: (
          <WS2637001_OverallResultDisplayInput
            Li_CourseLevel={record.W1_course_level}
            Li_ReserveNum={record.W1_reserve_num}
            onClickedCreate={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  //  EXCEL処理
  excelReport() {
    let stateDataSource = this.state.dataSource
    let data = []

    for (var i = 0; i < stateDataSource.length; i++) {
      data[i] = {
        consultDate: stateDataSource[i].W1_consult_date,
        receiptNum: stateDataSource[i].W1_receipt_num,
        kanjiName: stateDataSource[i].W1_kanji_name,
        courseCd: stateDataSource[i].W1_course_cd,
        contractShortName: stateDataSource[i].contract_short_name,
        displayResults: stateDataSource[i].DisplayResults,
      }
    }

    let param = {
      data: [...data],
      categoryNameList: this.state.dataSource[0].CategoryName
    }

    NotInputCheckCategorytAction.exportExcel(param)
      .then((res) => {
        download_file(res);
      })
      .catch();
  }

  /**
   * 検索ボタンが押された処理
   */
  onStatusList() {
    const formValues = this.formRef.current.getFieldValue();
    const params = {
      State: formValues && formValues.State ? formValues.State : 0, //タイプ（0：検査 , 1：判定）
      Type: formValues && formValues.Type ? formValues.Type : 0, //種別
      DateFDate: formValues && formValues.DateF ? formValues.DateF.format('YYYY-MM-DD') : '',  //日付
      conditionAddData: this.state.conditionAddData
    };

    this.setState({ isLoading: true });

    // 日付が空白の時はエラー
    if (!formValues.DateF && (params.conditionAddData == null || (params.conditionAddData.DateFChar == null || params.conditionAddData.DateTChar == null))) {
      // if (!formValues.DateF) {
      message.error('日付を入力してください')
      return
    }

    NotInputCheckCategorytAction.StatusList(params)
      .then((res) => {
        if (res) {
          const dataRes = res.result ? res.result : [];
          const maxCounter = res.maxCounter ?? 0;
          let tempArray = [];
          let counter = 1;

          while (counter <= maxCounter) {
            let number = ('00' + counter).slice(-2);
            tempArray.push(number)
            counter++
          }

          const data = dataRes;
          this.formRef.current.setFieldsValue({
            dataSource: data
          })

          this.setState({
            dataSource: data,
            rowSelected: data.length > 0 ? data[0] : {},
            selectedRowKeys: data.length > 0 ? [data[0].id] : [],
            indexTable: 0,
            disabled: dataRes.length > 0 ? false : true,
            categoryList: tempArray,
          });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }));
  }

  /**
   * 結果値　render 設定
   * @param {*} result
   * @param {*} colorNum
   * @returns
   */
  renderDisplayResult = (result, colorNum) => {
    let tooltip = ''
    switch (result) {
      case '○':
        tooltip = '全入力'
        break;
      case '▲':
        tooltip = '一部入力'
        break;
      case '×':
        tooltip = '未入力'
        break;
      default:
        break;
    }

    return (
      <Tooltip title={tooltip}>
        <div
          style={{
            textAlign: 'center',
            color: Color(colorNum)?.Foreground,
          }}
        >
          <span>{result}</span>
        </div>
      </Tooltip>
    )
  }

  /**
   *  総合結果表示/入力 モーダル
   * @param {*} record
   */
  interviewSupport = (record) => {
    console.log(record.W1_reserve_num);
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.W1_reserve_num}
            protectFlag={record.protectFlag}
            provisionalFlag={record.provisionalFlag}
          />
        ),
      },
    })
  }

  render() {
    const titleString = (params) =>
      this.state && this.state.dataSource && this.state.dataSource[0]
        ? this.state.dataSource[0][params]
        : '';
    const format = 'YYYY/MM/DD';
    return (
      <div className='not-input-check-category'>
        <Card title='未入力チェック' style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              State: 0
            }}
          >
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className='option_btn_right_top'
                trigger='click'
                size='small'
                icon={<MenuOutlined />}
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1'
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0745001_NotInputCheckMaintain
                                  onFinishScreen={() => {
                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      >
                        種別保守
                      </Menu.Item>

                      <Menu.Item key='1'
                        onClick={() => {
                          window.open(location.protocol + '//' + location.host + '/progress-setting/progress-set')
                        }}
                      >
                        進捗状況設定
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner' style={{ paddingTop: 0 }}>
                    <Form.Item
                      name='DateF'
                      label='日付'
                      {...labelCol}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='Type'
                        label='種別'
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-4'
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 400,
                                component: (
                                  <WS0743010_TypeQuery
                                    onFinishScreen={(output) => {
                                      this.setFormFieldValue(
                                        'Type',
                                        output.Lo_Type
                                      );
                                      this.setFormFieldValue(
                                        'remarks',
                                        output.Lo_Remark
                                      );
                                      this.closeModal();
                                    }}
                                  />
                                ),
                              },
                            });
                          }}
                        />
                      </Form.Item>
                      <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('remarks')}</div>
                    </div>

                    <Form.Item
                      name='State'
                      label='タイプ'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Radio.Group
                        onChange={() => this.forceUpdate()}
                      >
                        <Radio value={0}>検査</Radio>
                        <Radio value={1}>判定</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </div>
                </div>


                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.onStatusList();
                      }}
                    >
                      検　索
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component: (
                                <WS2786001_ConditionAddSub
                                  Li_DateF={this.formRef.current?.getFieldValue('DateF')}
                                  Li_DateT={this.formRef.current?.getFieldValue('DateF')}
                                  Li_State='1'
                                  Lio_KeyInfo={this.formRef.current?.getFieldValue('KeyNum')}
                                  Li_ConditionAddData={this.state.conditionAddData}
                                  onFinishScreen={(output) => {
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                    })
                                    this.formRef.current?.setFieldsValue({
                                      KeyNum: output.Lio_KeyInfo
                                    })
                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <DataGrid
                style={{ height: 600 }}
                dataSource={this.state.dataSource}
                showBorders={true} //周りの線
                showRowLines={true} //横線
                wordWrapEnabled={true} //折り返し
                allowColumnReordering={true} //カラム移動
                allowColumnResizing={true} //カラムの幅リサイズ
                columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                columnChooser={{ enabled: true, mode: 'select' }} //カラム表示選択
                paging={{
                  // ペジネーション設定
                  enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                }}
                scrolling={{ mode: 'standard' }}//スクロールがもっさりしないおまじない
              >
                <Column
                  caption='ロック' //タイトル
                  dataField='Expression_134' //dataIndex
                  width={40}
                  fixed={true} //カラム固定
                  alignment='center'
                  headerCellRender={() =>
                    <img src={status_input_lock}></img>
                  }
                  cellRender={(event) =>
                    <img
                      style={{ textAlign: 'center' }}
                      src={GetImage(event.value)}
                    />
                  }
                />

                <Column
                  caption='受診日'
                  dataField='W1_consult_date'
                  width={140}
                  fixed={true}
                  cellRender={(event) =>
                    <span>
                      {moment(event.value).format('YYYY/MM/DD (ddd)')}
                    </span>
                  }
                />

                <Column
                  caption='受付No'
                  dataField='W1_receipt_num'
                  width={62}
                  fixed={true}
                  cellRender={(event) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        {event.value === 0 ? '' : event.value}
                      </div>
                    )
                  }}
                />

                <Column
                  caption='氏名'
                  dataField='W1_kanji_name'
                  width={180}
                  fixed={true}
                  cellRender={(event) =>
                    <Tooltip title={event.data.Expression_137}>
                      <span>{event.value}</span>
                    </Tooltip>
                  }
                />

                <Column
                  caption='メモ'
                  dataField='consultationMemo'
                  width={45}
                  fixed={true}
                  cellRender={(event) => {
                    const record = event.data
                    const value = event.value
                    return (
                      <div
                        style={{
                          textAlign: 'center'
                        }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              centered: true,
                              component: (
                                <AllMemosFunction
                                  date={this.formRef.current?.getFieldValue('DateF')}
                                  reservation_number={record.W1_reserve_num}
                                  personal_number_id={record.personal_number_id}
                                  KanjiName={record.W1_kanji_name}
                                  onFinishScreen={async () => {
                                    await this.closeModal();
                                    await this.onStatusList();
                                  }}

                                  // メモ全体入力画面での保存ボタンの状態
                                  onFlagUpdate={(bool) => {
                                    console.log('onFlagUpdate:' + bool);
                                    this.setState({
                                      AllMemoFunctionUpdateBtn: bool
                                    })
                                  }}
                                />
                              ),
                            }
                          })
                        }}
                      >
                        {value.map((items, index) =>
                          <div
                            key={index}
                            style={{ color: items.color }}
                          >
                            {items.text}
                          </div>
                        )}
                      </div>
                    )
                  }}
                />

                <Column
                  caption='発送日'
                  dataField='postDate'
                  width={100}
                  fixed={true}
                  cellRender={(event) => (
                    <Tooltip title={event.data.postUserName}>
                      <div style={{ textAlign: 'center' }}>
                        {event.value}
                      </div>
                    </Tooltip>
                  )
                  }
                />

                <Column
                  fixed={true}
                  caption='契約情報'
                  dataField='contract_short_name'
                  width={200}
                  render={(event) => {
                    return (
                      <Tooltip title={event.data.Expression_136}>
                        <span>{event.value}</span>
                      </Tooltip>
                    )
                  }}
                />

                <Column
                  caption='総合判定'
                  dataField='comprehensive_judgment'
                  width={40}
                  headerCellRender={() =>
                    <div style={titleStyle} >{'総合判定'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <div
                        style={{
                          textAlign: 'center',
                          color: Color(event.data.Expression_150)?.Foreground,
                        }}
                      >
                        <span>{event.value}</span>
                      </div>
                    )
                  }}
                />

                <Column
                  caption='指導事項'
                  width={40}
                  dataField='Expression_145'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'指導事項'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <span>{event.value}</span>
                      </div>
                    )
                  }}
                />

                <Column
                  caption='階層化'
                  width={40}
                  dataField='Expression_146'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'階層化'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <Tooltip caption={event.data.Expression_14}>
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(event.data.Expression_147)?.Foreground,
                          }}
                        >
                          <span>{event.value}</span>
                        </div>
                      </Tooltip>
                    )
                  }}
                />

                <Column
                  caption='メタボ'
                  width={40}
                  dataField='Expression_148'
                  headerCellRender={() =>
                    <div style={titleStyle} >{'メタボ'}</div>
                  }
                  cellRender={(event) => {
                    return (
                      <Tooltip caption={event.data.Expression_15}>
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(event.data.Expression_149)?.Foreground,
                          }}
                        >
                          <span>{event.value}</span>
                        </div>
                      </Tooltip>
                    )
                  }}
                />

                {/* 項目の表示 */}
                {this.state.categoryList.map(number =>
                  <Column
                    key={'Column' + number}
                    caption={titleString('CategoryName_' + number)}
                    width={35}
                    dataField={'DisplayResult' + number}
                    alignment='center'
                    headerCellRender={() =>
                      <div style={titleStyle} >
                        {titleString('CategoryName_' + number)}
                      </div>
                    }
                    cellRender={(event) => {
                      return this.renderDisplayResult(event.data['DisplayResult' + number], event.data['CategoryColor_' + number])
                    }}
                  />
                )}

                <Column
                  caption=''
                  dataField=''
                  width={40}
                  fixed={true}
                  fixedPosition='right'
                  cellRender={(event) => {
                    const record = event.data
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='照会'
                              onClick={() =>
                                this.WS2583001_ConsultInquirySub(record)
                              }
                            >
                              照会
                            </Menu.Item>
                            <Menu.Item
                              key='入力'
                              onClick={() =>
                                this.WS2637001_OverallResultDisplayInput(record)
                              }
                            >
                              入力
                            </Menu.Item>
                            <Menu.Item
                              key='出力'
                              onClick={() =>
                                this.WS3020036_CoupledPrintInstruction(record)
                              }
                            >
                              出力
                            </Menu.Item>
                            <Menu.Item
                              key='EXCEL'
                              onClick={() =>
                                this.excelReport()
                              }
                            >
                              EXCEL
                            </Menu.Item>

                            {/* オプション設定によって 表示・非表示を切り替える*/}
                            {
                              this.state.menuFlag ?
                                <Menu.Item
                                  key='面談支援'
                                  onClick={() =>
                                    this.interviewSupport(record)
                                  }
                                >
                                  面談支援
                                </Menu.Item>
                                :
                                null
                            }
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )
                  }}
                />

              </DataGrid>
            </div>

            <Space style={{ float: 'right', display: (this.formRef.current?.getFieldValue('State')) ? 'none' : '' }}>
              <span style={{ paddingRight: '10px' }}>
                全入力 ○</span>
              <span style={{ paddingRight: '10px' }}>
                一部入力 <CaretUpOutlined style={{ color: 'blue' }} />
              </span>
              <span style={{ paddingRight: '10px' }}>
                未入力 <CloseOutlined style={{ color: 'red' }} />
              </span>
            </Space>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.AllMemoFunctionUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    AllMemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS0743001_NotInputCheckCategory);
