import { message } from 'antd';
import UseInspectCheck from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/UseInspectCheck'

const UseInspectCheckAction = {
    getInit(param){
        return UseInspectCheck.getInit(param)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    },
    getScreenData(param){
        return UseInspectCheck.getScreenData(param)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    },
    getNameCategoryCode(param){
        return UseInspectCheck.getNameCategoryCode(param)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    },
    getNameInspectCode(param){
        return UseInspectCheck.getNameInspectCode(param)
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    },

}
export default UseInspectCheckAction;