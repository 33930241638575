import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined } from "@ant-design/icons";

import { Card, Table, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin, Select } from "antd";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import SetCsvCreateAction from "redux/basicInfo/SetInfoMaintain/SetCsvCreate.action";
import RequestCsvOutputAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/RequestCsvOutput.action";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";

import { SearchOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import Color from "constants/Color";

import moment from "moment";
import axios from "configs/axios";

const grid = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 20 },
};

const styleDiv = {
  display: 'inline-block',
  marginRight: '20px',
  padding: '4px 12px',
  border: '0px solid #000000',
  color: '#000000'
}
const styleFormItem = {
  display: 'inline-block',
  marginRight: '20px',
}


class WS2682001_TransmissionContentsCheckCorrectPersonal extends React.Component {

  formRef = React.createRef();

  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    Li_CaptureFileYyyymmddXx: PropTypes.any,
    Li_State: PropTypes.any,
    Li_medicalExamType: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '送信内容確認・修正(ファイル)';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingForm: false,
      isLoadingTable: false,

      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],

      selected: 0,

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  handleChange(value) {
    this.setState({ selected: value });
  }

  clearSelected() {
    this.setState({ selected: null });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  show_sub() {
    if (this.state.rowSelected) {
      let val = this.state.rowSelected;
      if (val.length > 0) {
        this.formRef.current.setFieldsValue({
          // create_file_name          :  this.props.Li_CaptureFileYyyymmddXx ? this.props.Li_CaptureFileYyyymmddXx : '',
          insurer_number: val[0].insurer_number ? val[0].insurer_number : '',
          visit_date_on: val[0].visit_date_on ? val[0].visit_date_on : '',
          birthday_on: val[0].birthday_on ? val[0].birthday_on : '',
          insurer_card_number: val[0].insurer_card_number ? val[0].insurer_card_number : '',
          personal_number_id: val[0].personal_number_id ? val[0].personal_number_id : '',
          insurer_card_symbol: val[0].insurer_card_symbol ? val[0].insurer_card_symbol : '',
          Expresstion_12: val[0].medical_exam_type ? val[0].medical_exam_type : 1,
        })
        // console.log(val)
      }
    }
  }

  componentDidMount() {
    // console.log(this.props.Li_ReserveNum)
    this.getScreenData();
  }

  componentDidUpdate(prv) {

    if (this.props !== prv) {
      // console.log(this.props.Li_ReserveNum)
      this.formRef.current.resetFields();
      this.getScreenData();
    }
  }

  getScreenData = () => {

    this.setState({ isLoading: true });
    this.setState({ isLoadingTable: true });


    axios.get('/api/associate-insure-qualify-confirm/transmission-contents-check-correct-personal/getscreenData', {
      params: {
        Li_ReserveNum: this.props.Li_ReserveNum,
        Li_CaptureFileYyyymmddXx: this.props.Li_CaptureFileYyyymmddXx,
      },
    })
      .then(res => {

        this.setState({
          dataSource: res ? res.data : [],
          isLoadingTable: false,
          isLoading: false

        })

        this.formRef.current?.setFieldsValue({

          create_file_name: res ? res.data[0].creating_file_name : '',
          insurer_number: res ? res.data[0].insurer_number : '',
          visit_date_on: res ? res.data[0].visit_date_on : '',
          birthday_on: res ? res.data[0].birthday_on : '',
          insurer_card_number: res ? res.data[0].insurer_card_number : '',
          personal_number_id: res ? res.data[0].personal_number_id : '',
          insurer_card_symbol: res ? res.data[0].insurer_card_symbol : '',
          kanji_name: res ? res.data[0].kanji_name : '',
          office_kanji_name: res ? res.data[0].office_kanji_name : '',
          contract_short_name: res ? res.data[0].contract_short_name : '',
          selected: res ? res.data[0].medical_exam_type : 1,
          Li_State: this.props.Li_State ? this.props.Li_State : '',

        })

      })
      .catch(error => {

        this.setState({ isLoading: false });
        this.setState({ isLoadingTable: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  render() {
    return (
      <div className="transmission-contents-check-correct-personal">
        <Card title="送信内容確認(個人)">

          <Form ref={this.formRef} onFinish={this.onFinish} >
            <Row style={{ marginLeft: 5 }} >
              <Col span={16}>

                <div style={{ ...styleDiv, borderColor: '#0000ff' }}>
                  <Row style={{ marginLeft: 5 }} gutter={20}>
                    <Col span={8}>
                      <Form.Item name="personal_number_id" label="個人番号ID">
                        <Input type="text" readOnly style={{ width: 120 }} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="kanji_name" label="" >
                        <Input type="text" readOnly style={{ width: 220 }} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="office_kanji_name" label="事業所">
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: 5 }} gutter={24} >
                    <Col span={16}>
                      <Form.Item name="visit_date_on" label="受&nbsp;&nbsp;&nbsp;診&nbsp;&nbsp;&nbsp;日" >
                        <Input type="text" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="contract_short_name" label="コース" >
                        <Input type="text" readOnly style={{ width: 300 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col span={8} >
                <Form.Item name="Li_State" label="状態" >
                  <Input type="text" style={{ border: 1 }} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Table bordered
            size='small'
            style={{ cursor: 'pointer' }}
            // rowClassName={(record, index) => record.id === this.state.selectedRows?.id ? 'table-row-light' : ''}                 
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            // pagination={this.state.dataSource?.length > 10 ? true : false}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
            rowKey={(record) => record.id}
            scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    rowSelected: [record],
                    selectedRowKeys: [record.id],
                  });
                  this.show_sub();
                  // console.log("ok1"+this.state.selectedRowKeys);
                },
              };
            }}
          >
            <Table.Column title="作成ファイル名" dataIndex="creating_file_name" align='center' key="" />
            <Table.Column title="保険者番号" dataIndex="insurer_number" align='' key="" />
            <Table.Column title="保険証記号" dataIndex="insurer_card_symbol" align='' key="" />
            <Table.Column title="保険証番号" dataIndex="insurer_card_number" align='' key="" />
            <Table.Column title="生年月日" dataIndex="birthday_on" align='center' key="" />
            <Table.Column title="受診日" dataIndex="visit_date_on" align='center' key="" />
            <Table.Column title="種別" dataIndex="examTypeName" align='center' key="" />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2682001_TransmissionContentsCheckCorrectPersonal);
