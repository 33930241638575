import axios from "configs/axios";

const APP_LIST = {
  getScreenData: `/api/specific-medical-exam-settle-process/screen-input`,
  onSave: `/api/specific-medical-exam-settle-process/screen-input/save`,
};
const ConsultTicketInputSubService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async onSave(params) {
    return axios.post(APP_LIST.onSave, params);
  },
};

export default ConsultTicketInputSubService;
