import React from "react";
import { connect } from "react-redux";
import { CopyOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { Card, Form, Input, Button, message, Modal } from "antd";

import InputVoteResultInputAction from 'redux/InputBusiness/InputVoteResultInput/InputVoteResultInput.action.js'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '70px' } }
}

class WS0738006_Copy extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '複写';

    this.state = {
    };
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const param = {
      ...this.props.record,
    }

    // Form設定
    this.formRef.current?.setFieldsValue(param)
  }


  copy() {
    let param = {
      ...this.props.record,
      newSeqno: this.formRef.current?.getFieldValue('newSeqno'),
      newItem: this.formRef.current?.getFieldValue('newItem'),
    }

    InputVoteResultInputAction.copy(param)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }


  render() {
    return (
      <div className="copy">
        <Card title="複写">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_inner_vertical' style={{ padding: '0px', alignItems: 'center' }}>
              <div className='box_inner_vertical specific-box'>
                <span className='specific-title'>
                  複写元
                </span>

                <Form.Item
                  name="seqno"
                  label="伝票コード"
                  {...labelCol}
                >
                  <Input
                    readOnly
                    style={{ border: 'none', background: 'transparent' }}
                  />
                </Form.Item>

                <Form.Item
                  name="item"
                  label="伝票名"
                  {...labelCol}
                >
                  <Input
                    readOnly
                    style={{ border: 'none', background: 'transparent' }}
                  />
                </Form.Item>
              </div>

              <div>
                <ArrowDownOutlined style={{ fontSize: 24 }} />
              </div>

              <div className='box_inner_vertical specific-box'
                style={{ marginTop: 0, marginBottom: 0 }}>
                <span className='specific-title'>
                  複写先
                </span>

                <Form.Item
                  name="newSeqno"
                  label="伝票コード"
                  {...labelCol}
                >
                  <Input className="input-size-number-5" />
                </Form.Item>

                <Form.Item
                  name="newItem"
                  label="伝票名"
                  {...labelCol}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className='box_button_bottom_right'
                style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  icon={<CopyOutlined />}
                  onClick={() => {
                    this.copy()
                  }}
                >
                  <span className='btn_label'>
                    複写
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0738006_Copy);
