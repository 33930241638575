import React from "react";
import { connect } from "react-redux";
import { SaveOutlined } from '@ant-design/icons';
import { Card, Form, Input, Button, Select, Checkbox } from "antd";
import PropTypes from 'prop-types';

import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import TextArea from "antd/lib/input/TextArea";

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

class WS0482011_CmtContentModification extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Lio_CommentContent: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'コメント内容修正';
    this.state = {
    };
  }


  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }

    // Form設定
    this.formRef.current?.setFieldsValue(data)

    if (data.start_date_on == '0000/00/00') {
      this.formRef.current?.setFieldsValue({
        start_date_on: '',
      })
    }

    this.setState({
      CommentGroup: this.props.CommentGroup,
      StsEnable: this.props.record.StsEnable
    })
  }


  /**
   * チェックボックスが更新された場合
   * @param {*} e
   */
  changeData = (e) => {
    if (e.target.checked) {
      this.setState({
        StsEnable: true
      })
    } else {
      this.setState({
        StsEnable: false
      })
    }
  }


  setCommentGroup = (value) => {
    this.formRef.current?.setFieldsValue({
      comment_group: value
    })
  }


  /**
   * 保存ボタンが押された場合
   */
  save = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      StsEnable: this.state.StsEnable ?? '',
    }

    this.props.onFinishScreen(values)
  }


  render() {
    return (
      <div className="cmt-content-modification">
        <Card title={"内容編集 コード：" + this.props.record.comment_code} className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="StsEnable"
              label='条件'
              {...labelCol}
            >
              <Checkbox
                checked={this.state.StsEnable}
                onChange={(event) => { this.changeData(event) }}
              >
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="start_date_on"
              label="適用日"
              {...labelCol}
            >
              <VenusDatePickerCustom
                formRefDatePicker={this.formRef}
                format='YYYY/MM/DD'
              />
            </Form.Item>

            <Form.Item
              name="comment_group"
              label='コメント群'
              {...labelCol}
            >
              <Input style={{ width: '50%' }} />
            </Form.Item>

            <Form.Item
              name="WeightOfJudge"
              label='重み'
              {...labelCol}
            >
              <Input className="input-size-number-5" />
            </Form.Item>

            <Form.Item
              name="priority"
              label='優先'
              {...labelCol}
            >
              <Input className="input-size-number-5" />
            </Form.Item>

            <Form.Item
              name="comment_content"
              label="コメント内容"
              {...labelCol}
            >
              <TextArea rows={5} type="text" />
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0482011_CmtContentModification);
