import axios from 'configs/axios'

const API_LIST = {
  getPeriodTimeInquiry: '/api/reserve-status-search/time-acquisition',
  index: '/api/reserve-status-search/reserve-status-search',
  recreateDisplay: '/api/reserve-status-search/reserve-status-search/recreate-display',
  getExamineeList: '/api/reserve-status-search/reserve-status-search/examinee-list',
  delete: '/api/reserve-status-search/reserve-status-search/delete',
  cancel: '/api/reserve-status-search/reserve-status-search/cancel',
  downloadExcel: '/api/reserve-status-search/reserve-status-search/excel',
  doubleClick: '/api/reserve-status-search/reserve-status-search/db-click',
  Downloadfile: '/api/reserve-status-search/reserve-status-search/download-file',
  changeLabel: '/api/reserve-status-search/reserve-status-search/change-label',
  // Route::post('/download-file', 'MemoFunction@downloadFile');
}

const ReserveStatusSearchService = {
  async getPeriodTimeInquiry() {
    return axios.get(API_LIST.getPeriodTimeInquiry)
  },
  async index() {
    return axios.get(API_LIST.index)
  },
  async recreateDisplay(params) {
    return await axios.get(API_LIST.recreateDisplay, { params })
  },
  async getExamineeList(params) {
    return await axios.get(API_LIST.getExamineeList, { params })
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
  async cancel(params) {
    return axios.delete(API_LIST.cancel, { params })
  },
  async downloadExcel(params) {
    return axios.post(API_LIST.downloadExcel, params, { responseType: 'blob' })
  },
  async doubleClick(params) {
    return axios.post(API_LIST.doubleClick, params)
  },
  async Downloadfile(params) {
    return axios.post(API_LIST.Downloadfile, params, { responseType: 'blob' });
  },
  async changeLabel(params) {
    return axios.post(API_LIST.changeLabel, params)
  },
}

export default ReserveStatusSearchService
