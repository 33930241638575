import axios from "configs/axios";

const groupPath = "/api/consult-ticket-input-process-list";

const APP_LIST = {
  index: `${groupPath}/agency-inquiry-sub`,
  delete: `${groupPath}/agency-inquiry-sub/delete`,
  getScreenData: `${groupPath}/agency-inquiry-sub/get-screen-data`,
  addTo: `${groupPath}/agency-inquiry-sub/get-screen-data`,
  correct: `${groupPath}/agency-inquiry-sub/correct`,
  f4User: `${groupPath}/agency-inquiry-sub/f4_user`,
  f7User: `${groupPath}/agency-inquiry-sub/f7_user`,
  f12User: `${groupPath}/agency-inquiry-sub/f12_user`,
  Registration: `${groupPath}/agency-inquiry-sub/registration`
};
const ConsultTicketInputProcessListService = {
  async Index(params) {
    return axios.get(APP_LIST.index, { params });
  },
  async delete(params) {
    return axios.post(APP_LIST.delete, params);
  },
  async GetScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async AddTo(params) {
    return axios.post(APP_LIST.addTo, { params });
  },
  async Correct(params) {
    return axios.get(APP_LIST.correct, { params });
  },
  async F4User(params) {
    return axios.post(APP_LIST.f4User, { params });
  },
  async F7User(params) {
    return axios.get(APP_LIST.f7User, { params });
  },
  async F12User(params) {
    return axios.get(APP_LIST.f12User, { params });
  },
  async Registration(params) {
    return axios.post(APP_LIST.Registration, params);
  }
};

export default ConsultTicketInputProcessListService;
