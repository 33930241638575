// 紹介状関連の固定データ
const WS0887001_dataList = {
  data6: [
    {
      "id": 1036,
      "various_codes": 1,
      "search_key": "ＸＸDr",
      "doctor_name": "ＸＸ　ＸＸ",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1045,
      "various_codes": 10,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1048,
      "various_codes": 13,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1049,
      "various_codes": 14,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1050,
      "various_codes": 15,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1046,
      "various_codes": 16,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 123,
      "various_codes": 17,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1954,
      "various_codes": 18,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1955,
      "various_codes": 19,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    },
    {
      "id": 1957,
      "various_codes": 21,
      "search_key": "",
      "doctor_name": "",
      "remarks": "",
      "management_code": 6
    }
  ],

  data9: [
    {
      "id": 1805,
      "various_codes": 10,
      "search_key": "",
      "exam_department_name": "身体計測",
      "medical_institution_name": "",
      "remarks": "内科",
      "management_code": 7,
      "medical_institutions_cd": 0
    },
    {
      "id": 22,
      "various_codes": 20,
      "search_key": "",
      "exam_department_name": "血圧",
      "medical_institution_name": "",
      "remarks": "内科",
      "management_code": 7,
      "medical_institutions_cd": 0
    },
    {
      "id": 21,
      "various_codes": 30,
      "search_key": "",
      "exam_department_name": "胸部X線",
      "medical_institution_name": "",
      "remarks": "呼吸器科",
      "management_code": 7,
      "medical_institutions_cd": 0
    },
    {
      "id": 20,
      "various_codes": 40,
      "search_key": "",
      "exam_department_name": "胃部X線",
      "medical_institution_name": "",
      "remarks": "消化器科",
      "management_code": 7,
      "medical_institutions_cd": 0
    },
    {
      "id": 19,
      "various_codes": 50,
      "search_key": "",
      "exam_department_name": "腹部超音波",
      "medical_institution_name": "",
      "remarks": "消化器科",
      "management_code": 7,
      "medical_institutions_cd": 0
    },
  ]


}

export default WS0887001_dataList
