import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Form, Button, Checkbox, message, Tooltip, Dropdown, Menu } from 'antd'
import WS1544001_DetailSub from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1544001_DetailSub.jsx'
import DocumentManageMaintainAction from 'redux/AdvancePreparation/DocumentManageMaintain/DocumentManageMaintain.actions'
import { PlusOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons'
import WS1543004_Copy from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1543004_Copy.jsx'
import WS1545001_OutputPatternSub from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1545001_OutputPatternSub.jsx'
import WS1546001_DestinationSub from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1546001_DestinationSub.jsx'
import WS0638001_EscortManageMaintain from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638001_EscortManageMaintain.jsx'
import WS1543002_DocumentEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1543002_DocumentEdit.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS1543001_DocumentManageMaintain extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '資料管理保守'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataAddNew: [],
      count: 'a',
      selectedRow: {},
      selectedRowKeys: [],
      selectedRowChange: {},
      checkSave: false,
      selectId: 0
    }
    this.setSave = this.setSave.bind(this)
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      tableData: []
    })
    this.GetMainInit()
  }

  /**
   * 初期表示処理
   */
  GetMainInit() {
    this.setState({ dataAddNew: [] })
    let params = {
      Li_MaterialManageNum: this.props.Li_MaterialManageNum ? this.props.Li_MaterialManageNum : ''
    }
    DocumentManageMaintainAction.GetMainInit(params)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          tableData: res ? res : []
        })
        this.setState({
          selectedRow: res && res.length > 0 ? res[0] : {},
          selectedRowKeys: res && res.length > 0 ? [res[0].id] : []
        })

      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * 新規登録処理
   * @param {*} value
   */
  AddNewData(value) {
    const params = {
      managementNumber: value.document_management_number,
      documentTitle: value.document_title,
    }

    DocumentManageMaintainAction.AddNewData(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          tableData: []
        })
        this.GetMainInit()
      })
  }

  /**
   * 編集処理
   * @param {*} value
   * @param {*} record
   */
  Update(value, record) {
    const params = {
      managementNumber: value.document_management_number,
      documentTitle: value.document_title,
      id_ms1600: record.id,
      id_ms1605: record.id_ms1605
    }
    DocumentManageMaintainAction.Update(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          tableData: []
        })
        this.GetMainInit()
      })
  }

  /**
   * 削除処理
   * @param {} record
   */
  Delete(record) {
    const params = {
      id_ms1600: record.id,
      id_ms1605: record.id_ms1605
    }

    DocumentManageMaintainAction.Delete(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          tableData: []
        })
        this.GetMainInit()
      })
  }

  setSave(value) {
    this.setState({
      checkSave: value
    })
    this.GetRe_LoadData()
  }

  GetRe_LoadData() {
    DocumentManageMaintainAction.GetMainInit().then(res => {
      this.formRef.current?.setFieldsValue({
        tableData: res ? res : []
      })
      if (res && res.length > 0) {
        for (let index = 0; index < res.length; index++) {
          if (res[index].id === this.state.selectedRow.id) {
            this.setState({ ...this.state.selectedRow, selectedRow: res[index] })
            break
          }
        }
      }
    }).catch(error => {
      const res = error.response
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました')
      }
    })
  }

  /**
   * 複写処理
   */
  copy(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS1543004_Copy
            Managedivision={record.management_division}
            Managetype={record.document_printing_type}
            MaterialManageNum={record.document_management_number}
            MaterialManageName={record.document_title}
            id_ms1600={record.id}
            id_ms1605={record.id_ms1605}

            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                StsRun: output?.Lo_StsRun
              })
              if (output?.Lo_StsRun) {
                this.GetMainInit()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
  * パターン処理
  */
  outputPatternSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 700,
        component: (
          <WS1545001_OutputPatternSub
          />
        ),
      },
    })
  }

  /**
   * 送付先画面処理
   */
  destinationSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS1546001_DestinationSub
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * エスコート画面処理
   * @param {*} record
   */
  escortManageMaintain(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1100,
        component: (
          <WS0638001_EscortManageMaintain
            Li_selectedRow={this.state.selectedRow}
            Li_Format={record.Li_Format}
          />
        ),
      },
    })
  }

  /**
   * チェックボックス処理
   * @param {*} record
   * @param {*} e
   */
  changeCheckbox(record, e) {
    const params = {
      checkbox: e.target.checked ? 1 : 0,
      id_ms1605: record.id_ms1605
    }

    DocumentManageMaintainAction.ChangeCheckbox(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          tableData: []
        })
        this.GetMainInit()
      })
  }

  /**
   * 詳細画面の処理
   * @param {} record
   */
  showDetailSub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS1544001_DetailSub
            record={record}
            setSaveData={(output) => {
              this.setSave(output)
              this.closeModal()
            }}
          />
      },
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='document-manage-maintain'>
        <Card title='資料管理保守' style={{ position: 'relative' }}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='2'
                      onClick={() => {
                        this.outputPatternSub()
                      }}
                    >
                      パターン
                    </Menu.Item>

                    <Menu.Item key='3'
                      onClick={() => {
                        this.destinationSub()
                      }}
                    >
                      送付先
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>

            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              autoComplete='off'
            >
              <Table
                style={{ cursor: 'pointer' }}
                dataSource={this.formRef.current?.getFieldValue('tableData') ? this.formRef.current?.getFieldValue('tableData') : []}
                size='small'
                bordered
                pagination={false}
                rowKey={(iten) => iten.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(25) }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: async () => {
                      this.setState({ selectId: record.id })
                      this.showDetailSub(record)
                    }
                  }
                }}
              >
                <Table.Column
                  width={120}
                  title='管理番号'
                  dataIndex='document_management_number'
                  render={(value, record, index) => (
                    <div style={{ textAlign: 'right', color: record.StsEnable ? '' : '#b9b9b9' }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title='資料名称'
                  dataIndex='document_title'
                  render={(value, record, index) =>
                    <div style={{ color: record.StsEnable ? '' : '#b9b9b9' }}>
                      {value}
                    </div>
                  }
                />

                <Table.Column
                  width={40}
                  align='center'
                  render={(value, record, index) => {
                    return (
                      <Checkbox
                        checked={record.StsEnable}
                        onChange={(e) => {
                          this.changeCheckbox(record, e)
                        }}
                      >
                      </Checkbox>
                    )
                  }} />

                <Table.Column
                  width={40}
                  title={
                    //新規登録のボタン
                    <Button
                      size='small'
                      type='primary'
                      style={{ textAlign: 'center' }}
                      icon={<PlusOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '400px',
                            className: '',
                            component:
                              <WS1543002_DocumentEdit
                                newFlag={true}
                                onUpdate={(output) => {
                                  this.AddNewData(output)
                                  this.closeModal()
                                }}
                              />
                          },
                        })
                      }}
                    ></Button>
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '400px',
                                    className: '',
                                    component:
                                      <WS1543002_DocumentEdit
                                        newFlag={false}
                                        record={record}
                                        onUpdate={(output) => {
                                          this.Update(output, record)
                                          this.closeModal()
                                        }}
                                        onDelete={() => {
                                          this.Delete(record)
                                          this.closeModal()
                                        }}
                                      />
                                  }
                                })
                              }}
                            >変更
                            </Menu.Item>

                            <Menu.Item
                              key='詳細'
                              onClick={() => {
                                this.setState({ selectId: record.id })
                                this.showDetailSub(record)
                              }}
                            >詳細
                            </Menu.Item>

                            <Menu.Item key='複写'
                              onClick={() => {
                                this.copy(record)
                              }}
                            >
                              複写
                            </Menu.Item>

                            <Menu.Item key='エスコート'
                              onClick={() => {
                                this.escortManageMaintain(record)
                              }}
                            >
                              エスコート
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </Form>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1543001_DocumentManageMaintain)
