import ConsultTicketInputSubService from 'services/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ConsultTicketInputSubService'
import { message } from 'antd'

const ConsultTicketInputSubAction = {
  getScreenData(data) {
    return ConsultTicketInputSubService.getScreenData(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  onSave(data) {
    return ConsultTicketInputSubService.onSave(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  contractSelect(data) {
    return ConsultTicketInputSubService.contractSelect(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  DetailedInspectionData(data) {
    return ConsultTicketInputSubService.DetailedInspectionData(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  AdditionalMedicalExamination(data) {
    return ConsultTicketInputSubService.AdditionalMedicalExamination(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  ConcurrentMedicalExaminations(data) {
    return ConsultTicketInputSubService.ConcurrentMedicalExaminations(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}
export default ConsultTicketInputSubAction
