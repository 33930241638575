import axios from 'configs/axios'

const apiPaths = {
  getSendingHistory: '/api/corporate-hearing-sheet/destination-management/get-sending-history',
  saveSendingHistory: '/api/corporate-hearing-sheet/destination-management/save-sending-history',
  deleteSendingHistory: '/api/corporate-hearing-sheet/destination-management/delete-sending-history',
}

const DestinationManagementService = {
  async getSendingHistory(params) {
    return axios.get(apiPaths.getSendingHistory, { params })
  },
  async saveSendingHistory(params) {
    return axios.post(apiPaths.saveSendingHistory, params)
  },
  async deleteSendingHistory(params) {
    return axios.post(apiPaths.deleteSendingHistory, params)
  },
}

export default DestinationManagementService
