import axios from "configs/axios";

const groupPath = "/api/specific-health-data-xml-output";

const APP_LIST = {
  getScreenData: `${groupPath}/result-confirm/get-screen-data`,
  LogDisplay: `${groupPath}/result-confirm/log-display`,
  xml: `${groupPath}/result-confirm/xml`,
  downloadXml: `${groupPath}/result-confirm/download-xml`,
  downloadLog: `${groupPath}/result-confirm/download-log`,
  makeCSV: `${groupPath}/result-confirm/make-CSV`,
  downloadCSV: `${groupPath}/result-confirm/download-CSV`,
};
const ResultConfirm = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async LogDisplay(params) {
    return axios.post(APP_LIST.LogDisplay, params);
  },
  async xml(params) {
    return axios.post(APP_LIST.xml, params);
  },
  async downloadXml(params) {
    return axios.post(APP_LIST.downloadXml, params, {
      responseType: 'arraybuffer'
    });
  },
  async downloadLog(params) {
    return axios.post(APP_LIST.downloadLog, params, {
      responseType: 'blob'
    });
  },
  async makeCSV(params) {
    return axios.post(APP_LIST.makeCSV, params, { responseType: 'blob' });
  },
  async downloadCSV(params) {
    return axios.post(APP_LIST.downloadCSV, params);
  },
};

export default ResultConfirm;
