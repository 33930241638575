import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Modal, message, Input, Checkbox } from "antd";
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import moment from 'moment-timezone'

import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'

import RecoveryProcessesAction from 'redux/Others/RecoveryProcesses/RecoveryProcesses.action'

const labelCol = {
  labelCol: { style: { width: 80 } }
}
const mb12 = {
  marginBottom: '12px',
}
const mb0 = {
  marginBottom: 0,
}

class ChangePastInspectCode extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props);

    // document.title = '過去検査コード変更';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      nameScreen: '',
      exam_type: '',
    };
    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    this.getDate()
  }

  getDate() {
    RecoveryProcessesAction.getDate()
      .then(res => {
        if (res) {
          let data = {
            startDate: moment(res?.startDate),
            endDate: moment(res?.endDate),
          }
          this.formRef.current?.setFieldsValue(data)
        }
      })
  }

  checkCourseCode() {
    let startCourse = this.formRef.current?.getFieldValue('startCourse')
    let endCourse = this.formRef.current?.getFieldValue('endCourse')
    if (!endCourse || startCourse > endCourse) {
      this.formRef.current?.setFieldsValue({
        endCourse: startCourse
      })
    }
  }

  onFinish = () => {
    const params = {
      startDate: this.formRef.current?.getFieldValue('startDate') ?? '',
      endDate: this.formRef.current?.getFieldValue('endDate') ?? '',
      startCourse: this.formRef.current?.getFieldValue('startCourse') ?? '',
      endCourse: this.formRef.current?.getFieldValue('endCourse') ?? '',
      oldInspectCode: this.formRef.current?.getFieldValue('oldInspectCode') ?? 0,
      newInspectCode: this.formRef.current?.getFieldValue('newInspectCode') ?? 0,
      contractExpand: this.formRef.current?.getFieldValue('contractExpand') ?? false,
    }

    if(!params.oldInspectCode) {
      Modal.warning({
        content: '変更前検査コードを入力してください',
      })
      return
    }

    ModalConfirm({
      content: params.newInspectCode ? '検査コード変更処理を実行しますか？' : '検査コード削除処理を実行しますか？',
      styleIconModal: { color: '#006CC9' },
      onOk: () => {
        RecoveryProcessesAction.changePastInspectCode(params)
          .then((res) => {
            if(res) {
              Modal.info({
                content: res.message,
                onOk: this.props.onFinishScreen,
              })
            }
          })
          .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      },
      onCancel: () => {
        Modal.info({
          content: 'キャンセルしました',
        })
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className="inspect-to-finding-info-change">
        <Card title="過去検査コード変更">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='受診日'
                name='startDate'
                style={mb0}
                {...labelCol}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  allowClear={false}
                  style={{ width: 122 }}
                />
              </Form.Item>
              <Form.Item style={mb0}> ~ </Form.Item>
              <Form.Item
                name='endDate'
                style={mb0}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  allowClear={false}
                  style={{ width: 122 }}
                />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='変更前検査'
                name='oldInspectCode'
                style={mb0}
                {...labelCol}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-number-allow-clear-4'
                  onSearch={(value, event) => {
                    if (value !== '' || event.target.localName !== 'input') {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0271001_InspectItemSearchQuerySingle
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  oldInspectCode: output.recordData.test_item_code
                                })
                                this.closeModal();
                              }}
                            />
                          ),
                        }
                      })
                    }
                  }}
                />
              </Form.Item>
            </div>
            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='変更後検査'
                name='newInspectCode'
                style={mb0}
                {...labelCol}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-number-allow-clear-4'
                  onSearch={(value, event) => {
                    if (value !== '' || event.target.localName !== 'input') {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0271001_InspectItemSearchQuerySingle
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  newInspectCode: output.recordData.test_item_code
                                })
                                this.closeModal();
                              }}
                            />
                          ),
                        }
                      })
                    }
                  }}
                />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='コース'
                name='startCourse'
                style={mb0}
                {...labelCol}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-number-allow-clear-4'
                  onSearch={(value, event) => {
                    if (value !== '' || event.target.localName !== 'input') {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0265001_BasicCourseInquiry
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  startCourse: output.Lo_CourseCode
                                })
                                this.checkCourseCode()
                                this.closeModal()
                              }}
                            />
                          )
                        }
                      })
                    }
                  }}
                  onBlur={() => { this.checkCourseCode() }}
                />
              </Form.Item>
              <Form.Item style={mb0}> ~ </Form.Item>
              <Form.Item
                name='endCourse'
                style={mb0}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-number-allow-clear-4'
                  onSearch={(value, event) => {
                    if (value !== '' || event.target.localName !== 'input') {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0265001_BasicCourseInquiry
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  endCourse: output.Lo_CourseCode
                                })
                                this.checkCourseCode()
                                this.closeModal()
                              }}
                            />
                          )
                        }
                      })
                    }
                  }}
                  onBlur={() => { this.checkCourseCode() }}
                />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='再展開'
                name='contractExpand'
                valuePropName="checked"
                style={mb0}
                {...labelCol}
              >
                <Checkbox>カテゴリが移動するときのみ使用</Checkbox>
              </Form.Item>
            </div>

            <Button style={{ float: 'left' }} onClick={() => this.props.onFinishScreen()} type="primary">閉じる</Button>
            <Button style={{ float: 'right' }} htmlType='submit' type="primary">実行</Button>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePastInspectCode);