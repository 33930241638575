import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Select, Input } from "antd";

import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import WS0267001_CategorySearchQuerySingle from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle";
const labelCol = {
  labelCol: { style: { width: 50 } },
}

class WS0641009_BasicConditionSetRegister extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '基本条件設定-[新規登録]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  onFinish(param) {
    this.props.onFinishScreen(param)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="basic-condition-set">
        <Card title="基本条件設定-[新規登録]" style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ Division: 0 }}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='コース'
                name='CourseClassify'
                {...labelCol}
                initialValue={'J'}
              >
                <Select
                  style={{ width: '150px' }}
                  onChange={() => {
                    this.formRef.current?.setFieldsValue({
                      cd: null,
                      name: ''
                    })
                    this.setState({
                      cd: null,
                      name: ''
                    })
                  }}
                >
                  <Select.Option value={'J'}>検査</Select.Option>
                  <Select.Option value={'G'}>コード</Select.Option>
                </Select>
              </Form.Item >

              <Form.Item
                label='コード'
                name='cd'
                {...labelCol}
              >
                <Input.Search
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '80%',
                        component: (
                          this.formRef.current?.getFieldValue('CourseClassify') === 'J') ?
                          <WS0271001_InspectItemSearchQuerySingle
                            InspectCode={this.formRef.current?.getFieldValue('cd')}
                            onFinishScreen={(output) => {
                              this.formRef.current?.setFieldsValue({
                                cd: output.recordData.test_item_code,
                                name: output.recordData.exam_name
                              })
                              this.closeModal();
                            }}
                          />
                          :
                          <WS0267001_CategorySearchQuerySingle
                            Lio_CategoryCode={this.formRef.current?.getFieldValue('cd')}
                            onFinishScreen={(output) => {
                              this.formRef.current?.setFieldsValue({
                                cd: output.Lio_CategoryCode,
                                name: output.Lio_CategoryName
                              })
                              this.closeModal();
                            }}
                          />
                      }
                    })
                  }}
                />
              </Form.Item>

              <Form.Item
                name="name"
                label="名称"
                {...labelCol}
              >
                <div>
                  {this.formRef.current?.getFieldValue('name')}
                </div>
              </Form.Item>

              <div className='box_button_bottom_right'>
                <Button
                  type="primary"
                  className='btn_label'
                  onClick={() =>
                    this.onFinish(this.formRef.current?.getFieldsValue())
                  }
                >
                  保存
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0641009_BasicConditionSetRegister);
