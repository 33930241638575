import axios from "configs/axios";

const APP_LIST = {
  getScreenData: "/api/specific-insure-guide-settle-process-list/agreement-information/get-screen-data",
  contractBtn: "/api/specific-insure-guide-settle-process-list/agreement-information/contract-btn"
};

const AgreementInformationService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },

  async contractBtn(params) {
    return axios.get(APP_LIST.contractBtn, { params });
  },
};

export default AgreementInformationService;