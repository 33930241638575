import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Button, Checkbox, Radio, Select, Table, message, Dropdown, Menu } from "antd";
import WS0397001_ParamPromptedQuerySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx';
import WS2592016_ParamPromptedQueryContent from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS2592016_ParamPromptedQueryContent.jsx';
import WS1544002_FormatQuery from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1544002_FormatQuery.jsx';
import WS1544003_DetailSubEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1544003_DetailSubEdit.jsx';
import WS0102001_InspectListSettingSub from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS0102001_InspectListSettingSub.jsx';
import WS0104001_CoursesSettingSub from 'pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0104001_CoursesSettingSub.jsx';
import DocumentManageMaintainAction from 'redux/AdvancePreparation/DocumentManageMaintain/DocumentManageMaintain.actions'
import { PlusOutlined, MoreOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";

const labelCol = {
  labelCol: { style: { width: '80px' } },
  wrapperCol: { style: { width: '200px' } }
}

const stFm = {
  marginBottom: 0
}
class WS1544001_DetailSub extends React.Component {
  static propTypes = {
    Lio_MaterialOption: PropTypes.any,
    Lio_MaterialOptionExpansion: PropTypes.any,
    Lio_MaterialOptionDescription: PropTypes.any,
    selectedRowsTable: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // document.title = '明細SUB';

    this.state = {
      isLoadding: false,
      loadingFm: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      count: "a",
      objNull: {
        Format: "",
        AgeCalculate: "",
        BirthDateFormat: "",
        CourseNot: "",
        CourseOr: "",
        DateFormat: "",
        Gender: 0,
        IdEditing: "",
        InspectAnd: "",
        InspectNot: "",
        InspectOr1: "",
        InspectOr2: "",
        Lio_MaterialOption: "",
        Lio_MaterialOptionExpansion: "",
        OtherOptions: [],
        OutputPattern: "",
        StsBatchFormat: false,
        StsDocumentUnit: false,
        StsSpecifiedOutput: false,
        StsThereAreOther: false,
        escort_code: null,
        expression_73: "",
      }
    };
  }

  componentDidMount() {
    this.GetDetailSub(this.props.record)
  }

  componentDidUpdate(PropPev) {
    if (this.props.selectedRowsTable !== PropPev.selectedRowsTable) {
      this.GetDetailSub()
    }
    //save Data
    if (this.props.conditionSave !== PropPev.conditionSave) {
      if (this.props.conditionSave) {
        this.SaveData()
      }
    }
  }

  GetDetailSub() {
    let data = {
      id_ms1600: this.props.record.id,
      id_ms1605: this.props.record.id_ms1605,
    }

    DocumentManageMaintainAction.GetDetailSub(data)
      .then(res => {
        this.formRef.current?.setFieldsValue(res)
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ ...this.state.loadingFm, loadingFm: false }))
  }

  onFinish = () => {
    let dataSave = {
      ...this.formRef.current.getFieldsValue(),
      id_ms1600: this.props.record.id,
      id_ms1605: this.props.record.id_ms1605,
      document_management_number: this.props.record.document_management_number,
      document_title: this.props.record.document_title
    }

    DocumentManageMaintainAction.SaveAndUpdate(dataSave)
      .then(res => {
        if (this.props.setSaveData) {
          this.props.setSaveData(false)
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
      .finally(() => this.setState({ ...this.state.loadingFm, loadingFm: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  /**
   * 各検査のサーチが押された時の処理
   * @param {*} condition
   */
  ShowWS0102001_InspectListSettingSub(condition) {
    //どの検査フォームなのか判定
    let data = this.GetDataInspectListSettingSub(condition)
    this.setState({
      ...this.state,
      childModal: {
        width: '50%',
        visible: true,
        component: (
          //対象検査項目の表示画面
          <WS0102001_InspectListSettingSub
            Li_Title={data.Li_Title}
            Lio_ExamList={data.Lio_ExamList}
            //項目を追加する場合
            onFinishScreen={(output) => {
              this.setDataInspectListSettingSub(condition, output)
            }}
            //項目を削除する場合
            onDelete={(output) => {
              this.deleteDataInspectListSettingSub(condition, output)
            }}
          />)
      }
    });
  }

  GetDataInspectListSettingSub(condition) {
    if (condition === "InspectOr1") {
      return { Li_Title: '対象の検査', Lio_ExamList: this.formRef.current?.getFieldValue("InspectOr1") }
    } else if (condition === "InspectOr2") {
      return { Li_Title: '対象の検査', Lio_ExamList: this.formRef.current?.getFieldValue("InspectOr2") }
    } else if (condition === "InspectNot") {
      return { Li_Title: '不要な検査', Lio_ExamList: this.formRef.current?.getFieldValue("InspectNot") }
    } else if (condition === "InspectAnd") {
      return { Li_Title: '必須の検査', Lio_ExamList: this.formRef.current?.getFieldValue("InspectAnd") }
    }
  }

  /**
   * 各種検査フォームの項目追加
   * @param {*} condition
   * @param {*} output
   */
  setDataInspectListSettingSub(condition, output) {

    if (condition === "InspectOr1") {
      //対象検査
      const FieldValue = this.formRef.current?.getFieldValue("InspectOr1")
      const outputItem = this.setDataInspectListSettingSubFunction(FieldValue, output)

      //返却値をフィールドセット
      this.formRef.current?.setFieldsValue({
        InspectOr1: outputItem
      })

    } else if (condition === "InspectOr2") {
      //対象検査2
      const FieldValue = this.formRef.current?.getFieldValue("InspectOr2")
      const outputItem = this.setDataInspectListSettingSubFunction(FieldValue, output)
      this.formRef.current?.setFieldsValue({
        InspectOr2: outputItem
      })

    } else if (condition === "InspectAnd") {
      //必要検査
      const FieldValue = this.formRef.current?.getFieldValue("InspectAnd")
      const outputItem = this.setDataInspectListSettingSubFunction(FieldValue, output)
      this.formRef.current?.setFieldsValue({
        InspectAnd: outputItem
      })

    } else if (condition === "InspectNot") {
      //不要検査
      const FieldValue = this.formRef.current?.getFieldValue("InspectNot")
      const outputItem = this.setDataInspectListSettingSubFunction(FieldValue, output)
      this.formRef.current?.setFieldsValue({
        InspectNot: outputItem
      })
    }

    this.forceUpdate()
    this.ShowWS0102001_InspectListSettingSub(condition)
  }

  /**
   * 検査フォーム項目の追加用関数
   * @param {*} FieldValue
   * @param {*} output
   * @returns
   */
  setDataInspectListSettingSubFunction(FieldValue, output) {
    let zero_item_code = String(output.recordData.test_item_code).padStart(8, '0')
    let formItem = FieldValue
    let arrayItem = ''
    let outputItem
    let duplication = ''

    if (formItem === '') {
      //フォームに何も登録されていない場合
      outputItem = zero_item_code + ";"

    } else {
      //フォームに項目が登録されている場合
      arrayItem = formItem.substr(0, formItem.length - 1)
      arrayItem = arrayItem.split(',')
      arrayItem.push(zero_item_code)
      arrayItem.sort()

      duplication = arrayItem.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplication.length === 0) {
        //重複がない場合
        arrayItem.join(',');
        outputItem = arrayItem + ";"

      } else {
        //重複がある場合
        message.error("検査コードが重複しています")

        //重複を削除したリスト
        arrayItem = arrayItem.filter((x, i, self) => self.indexOf(x) === i)
        arrayItem.join(',');
        outputItem = arrayItem + ";"
      }

    }
    return outputItem
  }

  /**
   * 各種検査フォームの項目削除
   * @param {*} condition
   * @param {*} output
   */
  deleteDataInspectListSettingSub(condition, output) {

    if (condition === "InspectOr1") {
      //対象検査
      const FieldValue = this.formRef.current?.getFieldValue("InspectOr1")

      //検査フォーム項目の削除用関数
      const outputItem = this.deleteDataInspectListSettingSubFunction(FieldValue, output)

      //返却値をフィールドセット
      this.formRef.current?.setFieldsValue({
        InspectOr1: outputItem
      })

    } else if (condition === "InspectOr2") {
      //対象検査2
      const FieldValue = this.formRef.current?.getFieldValue("InspectOr2")
      const outputItem = this.deleteDataInspectListSettingSubFunction(FieldValue, output)
      this.formRef.current?.setFieldsValue({
        InspectOr2: outputItem
      })

    } else if (condition === "InspectAnd") {
      //必要検査
      const FieldValue = this.formRef.current?.getFieldValue("InspectAnd")
      const outputItem = this.deleteDataInspectListSettingSubFunction(FieldValue, output)
      this.formRef.current?.setFieldsValue({
        InspectAnd: outputItem
      })

    } else if (condition === "InspectNot") {
      //不要検査
      const FieldValue = this.formRef.current?.getFieldValue("InspectNot")
      const outputItem = this.deleteDataInspectListSettingSubFunction(FieldValue, output)
      this.formRef.current?.setFieldsValue({
        InspectNot: outputItem
      })
    }

    this.forceUpdate()
    this.ShowWS0102001_InspectListSettingSub(condition)
  }

  /**
   * 検査フォーム項目の削除用関数
   * @param {*} FieldValue
   * @param {*} output
   * @returns
   */
  deleteDataInspectListSettingSubFunction(FieldValue, output) {
    let formItem = FieldValue
    let arrayItem = ''
    let linkArrayItem = ''
    let outputItem = ''

    arrayItem = formItem.substr(0, formItem.length - 1)
    arrayItem = arrayItem.split(',')

    //削除対象の絞り込み・削除
    arrayItem.map((value, index) => {
      if (value === output.W1_inspect_cd) {
        arrayItem.splice(index, 1)
      }
    })

    arrayItem.sort()
    linkArrayItem = arrayItem.join(',')

    if (linkArrayItem === "") {
      //消去後、項目が残らない場合
      outputItem = ""

    } else {
      //消去後、項目が残る場合
      outputItem = linkArrayItem + ";"
    }

    return outputItem
  }

  /**
   * 各コースのサーチが押された時の処理
   * @param {*} condition
   */
  ShowWS0104001_CoursesSettingSub(condition) {
    this.setState({
      ...this.state,
      childModal: {
        width: '50%',
        visible: true,
        component: (<WS0104001_CoursesSettingSub
          Li_Title={condition === "CourseOr" ? '対象のコース' : '不要なコース'}
          Lio_CourseList={condition === "CourseOr" ? this.formRef.current?.getFieldValue("CourseOr") : this.formRef.current?.getFieldValue("CourseNot")}
          management_division={this.props.record.management_division}
          //項目を追加する場合
          onFinishScreen={(output) => {
            this.setDataCourses(condition, output)
          }}
          //項目を削除する場合
          onDelete={(output) => {
            this.deleteDataCourses(condition, output)
          }}
        />)
      }
    });
  }

  /**
   * コースフォーム項目の登録用関数
   * @param {*} condition
   * @param {*} record
   */
  setDataCourses(condition, record) {
    const item = record.recordData.course_code
    let formItem = record.FieldValue
    let arrayItem = ''
    let duplication = ''
    let outputItem

    if (formItem === '') {
      //フォームに何も登録されていない場合
      outputItem = item + ";"

    } else {
      //フォームに項目が登録されている場合
      arrayItem = formItem.substr(0, formItem.length - 1)
      arrayItem = arrayItem.split(',')
      arrayItem.push(item)
      arrayItem.sort()

      duplication = arrayItem.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplication.length === 0) {
        //重複がない場合
        arrayItem.join(',');
        outputItem = arrayItem + ";"

      } else {
        //重複がある場合
        message.error("検査コードが重複しています")

        //重複を削除したリスト
        arrayItem = arrayItem.filter((x, i, self) => self.indexOf(x) === i)
        arrayItem.join(',');
        outputItem = arrayItem + ";"
      }
    }

    //登録するフォームの選択
    if (condition === "CourseOr") {
      //コース〇
      this.formRef.current?.setFieldsValue({
        CourseOr: outputItem
      })

    } else {
      //コース×
      this.formRef.current?.setFieldsValue({
        CourseNot: outputItem
      })
    }

    this.forceUpdate()
    this.ShowWS0104001_CoursesSettingSub(condition)
  }

  /**
   * コースフォーム項目の削除用関数
   * @param {*} condition
   * @param {*} record
   */
  deleteDataCourses(condition, record) {
    const item = record.record.W1_course_cd
    let formItem = record.FieldValue
    let arrayItem = ''
    let linkArrayItem = ''
    let outputItem

    arrayItem = formItem.substr(0, formItem.length - 1)
    arrayItem = arrayItem.split(',')

    //削除対象の絞り込み・削除
    arrayItem.map((value, index) => {
      if (value === item) {
        arrayItem.splice(index, 1)
      }
    })

    arrayItem.sort()
    linkArrayItem = arrayItem.join(',')

    if (linkArrayItem === "") {
      //消去後、項目が残らない場合
      outputItem = ""

    } else {
      //消去後、項目が残る場合
      outputItem = linkArrayItem + ";"
    }

    //削除するフォームの選択
    if (condition === "CourseOr") {
      //コース〇
      this.formRef.current?.setFieldsValue({
        CourseOr: outputItem
      })

    } else {
      //コース×
      this.formRef.current?.setFieldsValue({
        CourseNot: outputItem
      })
    }

    this.forceUpdate()
    this.ShowWS0104001_CoursesSettingSub(condition)
  }

  ShowWS1544002_FormatQuery() {
    this.setState({
      ...this.state,
      childModal: {
        width: '30%',
        visible: true,
        component: (
          <WS1544002_FormatQuery
            Lio_Format={this.formRef.current?.getFieldValue("Format")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                Format: output?.Lio_Format
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />)
      }
    });
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  CheckMaxLength(data, number, namePath) {
    let val = data.target.value
    if (!this.isEmpty(val)) {
      if (val.length > number) {
        val.slice(0, number)
        this.formRef.current.setFields([{
          name: namePath,
          value: val
        }])
        this.forceUpdate()
      }
    }
  }

  /**
   * パラメーター指示表・新規項目追加・編集
   */
  AddNewDataParameter(record) {

    let newFlag = true;
    let flag = 'new'

    if (record) {
      newFlag = false
      flag = 'change'
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '400px',
        className: '',
        component:
          <WS1544003_DetailSubEdit
            newFlag={newFlag}
            record={record}

            onFinishScreen={(output) => {
              this.SaveAndUpdateParameter(output, flag)
            }}

            onDelete={(output) => {
              this.DeleteDataParameter(record)
            }}
          />
      }
    })
  }

  /**
   * パラメーター指示表・項目追加
   */
  SaveAndUpdateParameter(output, flag) {
    //データベースに情報を保存
    let Params = {
      content: output.content,
      item: output.item,
      id_ms1600: this.props.record.id,
      id_ms1605: this.props.record.id_ms1605,
      flag: flag
    }

    DocumentManageMaintainAction.SaveAndUpdateParameter(Params)
      .then((res) => {
        this.closeModal()
        this.GetDetailSubEdit(Params)
      })
  }

  /**
   * パラメーター指示表・項目削除
   */
  DeleteDataParameter(output) {
    let Params = {
      content: output.content, //output.W1_content,
      item: output.item, //output.W1_item,
      id_ms1600: this.props.record.id,
      id_ms1605: this.props.record.id_ms1605,
    }

    DocumentManageMaintainAction.DeleteParameter(Params)
      .then((res) => {
        this.closeModal()
        this.GetDetailSubEdit(Params)
      })
  }

  /**
  * パラメーター指示表・取得
  */
  GetDetailSubEdit(Params) {
    DocumentManageMaintainAction.GetDetailSubEdit(Params)
      .then(res => {
        console.log(res);
        this.formRef.current?.setFieldsValue(res)
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ ...this.state.loadingFm, loadingFm: false }))
  }

  ShowParamPromptedQuerySub(index) {
    this.setState({
      ...this.state,
      childModal: {
        width: '70%',
        visible: true,
        component: (<WS0397001_ParamPromptedQuerySub
          Li_Format={'Y'}
          onFinishScreen={(output) => {
            this.formRef.current?.setFields([
              {
                name: ['OtherOptions', index, 'W1_item'],
                value: output?.Lo_IndicationDivision
              }
            ])
            this.forceUpdate()
            this.closeModal()
          }}
        />)
      }
    });
  }

  ShowParamPromptedQueryContent(index) {
    this.setState({
      ...this.state,
      childModal: {
        width: '80%',
        visible: true,
        component: (<WS2592016_ParamPromptedQueryContent
          Li_Format={'Y'}
          Li_IndicationDivision={this.formRef.current?.getFieldValue('OtherOptions')[index].W1_item}
          Li_content={this.formRef.current?.getFieldValue('OtherOptions')[index].W1_content}
          onFinishScreen={(output) => {
            this.formRef.current?.setFields([
              {
                name: ['OtherOptions', index, 'W1_content'],
                value: output?.Lo_Item
              }
            ])
            this.forceUpdate()
            this.closeModal()
          }}
        />)
      }
    });
  }

  handleAdd() {
    const { count } = this.state;
    const newData = {
      id: count,
      W1_item: "",
      W1_content: "",
      W1_serial_num: ""
    }
    let data = [...this.formRef.current?.getFieldValue("OtherOptions")];
    data.length > 0 ? data.unshift(newData) : data.push(newData)
    this.formRef.current?.setFieldsValue({
      OtherOptions: data,
    });
    this.forceUpdate()
    this.setState({
      ...this.state,
      count: count + 1,
    })
  }

  Delete(record) {
    let arr = [...this.formRef.current?.getFieldValue("OtherOptions")];
    if (isNaN(record.id)) {
      arr.map((value, index) => {
        if (value.id === record.id) {
          arr.splice(index, 1)
          this.formRef.current?.setFieldsValue({
            OtherOptions: arr
          })
          this.forceUpdate()
        }
      })
    } else {
      if (this.props?.selectedRowsTable?.id_ms1605) {
        for (let indx = 0; indx < arr.length; indx++) {
          if (arr[indx].id === record.id) {
            arr[indx].id_ms1605 = this.props?.selectedRowsTable?.id_ms1605;
            DocumentManageMaintainAction.DeleteDetailSub(arr[indx]).then(res => {
              if (this.props.setDeleteDetail) {
                this.props.setDeleteDetail(true)
              }
            })
          }
        }
      }
    }
  }

  SaveData() {
    if (!this.isEmpty(this.props.selectedRowsTable?.document_management_number)) {
      this.setState({ ...this.state.loadingFm, loadingFm: true })
      const dataSave = { ...this.props.selectedRowsTable, ...this.formRef.current?.getFieldValue() }
      dataSave.StsDocumentUnit = dataSave.StsDocumentUnit ? 1 : 0
      dataSave.StsBatchFormat = dataSave.StsBatchFormat ? 1 : 0
      dataSave.StsSpecifiedOutput = dataSave.StsSpecifiedOutput ? 1 : 0
      dataSave.StsEnable = dataSave.StsEnable ? 1 : 0
      DocumentManageMaintainAction.SaveAndUpdate(dataSave).then(res => {
        if (this.props.setSaveData) {
          this.props.setSaveData(false)
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ ...this.state.loadingFm, loadingFm: false }))
    }
  }

  render() {
    return (

      <div className="detail-sub">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete="off"
          initialValues={{
            InspectOr1: "",
            InspectOr2: "",
            InspectAnd: "",
            InspectNot: "",
            CourseOr: "",
            CourseNot: "",
            AgeCalculate: 0
          }}
        >
          <Card title={this.props.record?.document_title ? this.props.record.document_title + ' [詳細]' : "資料管理保守 [詳細]"} style={{ position: 'relative' }}>
            <div className='box_inner_vertical'>
              <div className='box_border'>
                <div className="box_inner_horizontal">
                  <Form.Item
                    label="エスコート"
                    name="Format"
                    style={stFm}
                    {...labelCol}
                  >
                    <Input.Search
                      className='input-search-size-10'
                      maxLength={30}
                      onSearch={() => this.ShowWS1544002_FormatQuery()}
                      onChange={(e) => this.CheckMaxLength(e, 30, "Format")}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className='box_border'>
                <div className="box_inner_horizontal"
                  style={{ gap: 30 }}>

                  <Form.Item
                    name="StsDocumentUnit"
                    valuePropName="checked"
                    style={stFm}>
                    <Checkbox>帳票単位</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="StsBatchFormat"
                    valuePropName="checked"
                    style={stFm}>
                    <Checkbox>一括印刷</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="StsSpecifiedOutput"
                    valuePropName="checked"
                    style={stFm}>
                    <Checkbox>指定発行</Checkbox>
                  </Form.Item>

                </div>
              </div>

              <div className='box_border'>
                <div className="box_inner_vertical">
                  <Form.Item
                    name="InspectOr1"
                    label="対象検査"
                    style={stFm}
                    {...labelCol}>
                    <Input.Search
                      onSearch={() => this.ShowWS0102001_InspectListSettingSub("InspectOr1")}
                      onChange={(e) => this.CheckMaxLength(e, 2500, "InspectOr1")} />
                  </Form.Item>

                  <Form.Item
                    name="InspectOr2"
                    label=" "
                    style={stFm}
                    {...labelCol}>
                    <Input.Search
                      onSearch={() => this.ShowWS0102001_InspectListSettingSub("InspectOr2")}
                      onChange={(e) => this.CheckMaxLength(e, 2500, "InspectOr2")} />
                  </Form.Item>

                  <Form.Item
                    name="InspectAnd"
                    label="必須検査"
                    style={stFm}
                    {...labelCol}>
                    <Input.Search
                      onSearch={() => this.ShowWS0102001_InspectListSettingSub("InspectAnd")}
                      onChange={(e) => this.CheckMaxLength(e, 2500, "InspectAnd")} />
                  </Form.Item>

                  <Form.Item
                    name="InspectNot"
                    label="不要検査"
                    style={stFm}
                    {...labelCol}>
                    <Input.Search
                      onSearch={() => this.ShowWS0102001_InspectListSettingSub("InspectNot")}
                      onChange={(e) => this.CheckMaxLength(e, 2000, "InspectNot")} />
                  </Form.Item>

                  <Form.Item
                    name="CourseOr"
                    label="コース 〇"
                    style={stFm}
                    {...labelCol}>
                    <Input.Search
                      onSearch={() => this.ShowWS0104001_CoursesSettingSub("CourseOr")}
                      onChange={(e) => this.CheckMaxLength(e, 2000, "CourseOr")} />
                  </Form.Item>

                  <Form.Item
                    name="CourseNot"
                    label="コース ×"
                    style={stFm}
                    {...labelCol}>
                    <Input.Search
                      onSearch={() => this.ShowWS0104001_CoursesSettingSub("CourseNot")}
                      onChange={(e) => this.CheckMaxLength(e, 2000, "CourseNot")} />
                  </Form.Item>

                  <Form.Item
                    name="Gender"
                    label="性別"
                    style={stFm}
                    {...labelCol}>
                    <Radio.Group >
                      <Radio value={0}>全て&emsp;&emsp;</Radio>
                      <Radio value={1}>男性&emsp;&emsp;</Radio>
                      <Radio value={2}>女性&emsp;&emsp;</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>

              <div className="box_inner_horizontal">
                <div className='box_border' style={{ gap: 10, minWidth: '30%' }} >
                  <Form.Item
                    name="DateFormat"
                    label="受診日"
                    style={stFm}
                    {...labelCol}
                  >
                    <Select>
                      <Select.Option value={'YYYY/MM/DD'}>YYYY/MM/DD</Select.Option>
                      <Select.Option value={'MM/DD'}>MM/DD</Select.Option>
                      <Select.Option value={'YYYY-MM-DD'}>YYYY-MM-DD</Select.Option>
                      <Select.Option value={'MM-DD'}>MM-DD</Select.Option>
                      <Select.Option value={'YYYY年MM月DD日'}>YYYY年MM月DD日</Select.Option>
                      <Select.Option value={'MM月DD日'}>MM月DD日</Select.Option>
                      <Select.Option value={'JJJJYY年MM月DD日'}>JJJJYY年MM月DD日</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="IdEditing"
                    label="ID編集"
                    style={stFm}
                    {...labelCol}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="BirthDateFormat"
                    label="生年月日"
                    style={stFm}
                    {...labelCol}
                  >
                    <Select>
                      <Select.Option value={'JJJJYY年MM月DD日'}>JJJJYY年MM月DD日</Select.Option>
                      <Select.Option value={'YYYY/MM/DD'}>YYYY/MM/DD</Select.Option>
                      <Select.Option value={'MM/DD'}>MM/DD</Select.Option>
                      <Select.Option value={'YYYY-MM-DD'}>YYYY-MM-DD</Select.Option>
                      <Select.Option value={'MM-DD'}>MM-DD</Select.Option>
                      <Select.Option value={'YYYY年MM月DD日'}>YYYY年MM月DD日</Select.Option>
                      <Select.Option value={'MM月DD日'}>MM月DD日</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="AgeCalculate"
                    label="年齢計算"
                    style={stFm}
                    {...labelCol}>
                    <Select>
                      <Select.Option value={0}>受診日</Select.Option>
                      <Select.Option value={1}>年度末</Select.Option>
                    </Select>
                  </Form.Item>

                </div>

                <div style={{ display: "flex", flexDirection: 'column', minWidth: '65%' }}>
                  {/* パラメーター表 「右下の小さい表」*/}
                  <Table
                    dataSource={this.formRef.current?.getFieldValue("OtherOptions") ? this.formRef.current.getFieldValue("OtherOptions") : []}
                    size="small"
                    bordered
                    loading={this.state.isLoadding}
                    pagination={false}
                    scroll={{ y: 180 }}
                    rowKey={"serial_num"}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: async () => {
                          this.AddNewDataParameter(record)
                        }
                      };
                    }}
                  >
                    <Table.Column
                      title="連番"
                      width={80}
                      dataIndex="serial_num"
                      render={(value) => (
                        <div
                          style={{ textAlign: 'right' }}>
                          {value}
                        </div>
                      )}
                    />

                    <Table.Column
                      title="項目"
                      dataIndex="item"
                    />

                    <Table.Column
                      title="内容"
                      dataIndex="content"
                    />

                    <Table.Column
                      width={40}
                      title={
                        <Button
                          size='small'
                          type='primary'
                          icon={<PlusOutlined />}
                          onClick={() => this.AddNewDataParameter()}
                        >
                        </Button>}

                      render={(text, record, index) => {
                        return (
                          <Dropdown
                            trigger="click"
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key="変更"
                                  onClick={() => {
                                    this.AddNewDataParameter(record)
                                  }}
                                >変更
                                </Menu.Item>
                              </Menu>
                            )
                            }
                          >
                            <Button
                              size="small"
                              icon={<MoreOutlined />}
                              style={{ width: "100%" }}
                            ></Button>
                          </Dropdown>
                        )
                      }}
                    />
                  </Table>
                </div>
              </div>

              <div className='box_button_bottom_right'
                style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>

            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1544001_DetailSub);
