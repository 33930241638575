import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Table,
  Menu,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Modal,
} from "antd";
import moment from "moment";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

import { SearchOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import WS2786001_ConditionAddSub from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS2583001_ConsultInquirySub from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub";
import WS0963001_InvoiceMaintain from "../V4BL0031000_DepositWithdrawalInquiry/WS0963001_InvoiceMaintain";
import BillingAggregationProcess from "redux/AccountingBusiness/Invoice/BillingAggregationProcess";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';


class WS0948500_BillingAggregationPerson extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_Ctxgetname: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = "請求集計処理";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      AggregateSpecifiedState: null,
      showOffice: false,
      showInsurer: false,

      pagination: {
        size: 1,
        showQuickJumper: false
      },
    };
  }

  componentDidMount = () => {
    // let params = {
    //   Li_Identify: this.props.Li_Identify,
    //   Li_InsurerCode: this.props.Li_InsurerCode,
    //   Li_OfficeCode: this.props.Li_OfficeCode,
    //   Li_BranchStoreCode: this.props.Li_BranchCodeStart,
    //   Li_DateF: this.props.PeriodAStart,
    //   Li_DateT: this.props.PeriodAFinal,
    //   MedicalExamCourseStart: this.props.MedicalExamCourseStart,
    //   MedicalExamCourseFinal: this.props.MedicalExamCourseFinal,
    // }
    let params = {
      reservation_numberList: this.props.Li_Reservation_number,
      Li_Identify: this.props.Li_Identify,
      Li_InsurerCode: this.props.Li_InsurerCode
    }
    BillingAggregationProcess.aggregateSpecify(params)
      .then(res => {
        let arrID = [];
        if (res.data.length > 0) {
          res.data.forEach(element => {
            arrID.push(element.id)
          });
        }
        this.setState({
          dataSource: res.data,
          selectedRowKeys: arrID,
          selectedRows: res.data
        })
      })
  }

  showConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(output) => { }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="billing-aggregation-process">
        <Card title='個人'>
          <Table
            bordered
            dataSource={this.state.dataSource}
            pagination={false}
            scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
            rowKey={(record) => record.id}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: this.state.selectedRowKeys,
              onSelect: (record, selected, selectedRows, nativeEvent) => {
                let arrTemp = [...this.state.selectedRowKeys];
                let arrTempRecord = [...this.state.selectedRows];
                let idx = arrTemp.indexOf(record.id);
                if (idx === -1) {
                  arrTemp.push(record.id);
                  arrTempRecord.push(record)
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord
                  });
                } else {
                  arrTemp.splice(idx, 1);
                  arrTempRecord.splice(idx, 1);
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord
                  });
                }
                // this.selectRecord(record.id, selected)
              },

              onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected) {
                  let arrTemp = this.state.dataSource.map(item => item.id);
                  let arrTempRecord = this.state.dataSource;
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord,
                    isSelectAll: true
                  });
                } else {
                  this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                    isSelectAll: false
                  });
                }
                // this.selectAll(selected)
              },
            }}
          >
            <Table.Column
              title="－"
              dataIndex="MinusAmountMark"
              // width={"5%"}
              className="column-size-5"
              align="center"
            />
            <Table.Column
              title="集計"
              dataIndex="BillingExist"
              // width={"10%"}
              className="column-size-5"
              align="center"
            />
            <Table.Column
              title="合計額"
              dataIndex="W2_total_price"
              // width={"10%"}
              className="column-size-10"
              align="center"
              render={(value, record) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title="受診日"
              dataIndex="W2_consult_date"
              style={{ width: 'fit-content' }}
              align="center"
            />
            <Table.Column
              title="個人ID"
              dataIndex="W2_person_num"
              // width={"10%"}
              className="column-size-10"
              align="center"
              render={(value, record) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title="氏名"
              dataIndex="W2_full_name"
              className="column-size-30"
              align="center"
              render={(value, record) => {
                return (
                  <div style={{ textAlign: 'left' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title="契約情報"
              dataIndex=""
              className="column-size-30"
              align="center"
              render={(value, record) => {
                return (
                  <div style={{ textAlign: 'left' }}>
                    <span>{record.W2_visits_courses}</span>
                    <span>{record.contract_short_name}</span>
                  </div>
                );
              }}
            />
            <Table.Column
              title="事業所名"
              dataIndex="office_kanji_name"
              className="column-size-30"
              align="center"
              render={(value, record) => {
                return (
                  <div style={{ textAlign: 'left' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title=""
              dataIndex=""
              // width={80}
              className="column-size-2"
              fixed='right'
              render={(value, record) => {
                return (
                  <Button
                    icon={<MoreOutlined />}
                    size='small'
                    onClick={() => {
                      this.showConsultInquirySub(record)
                    }}
                  />
                )
              }}
            />
          </Table>
          <div className="box_button_bottom_right">
            <Button
              onClick={() => [
                this.props.onFinishScreen(this.state.selectedRows)
              ]}
              type="primary"
              style={{ float: "right" }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
export default WS0948500_BillingAggregationPerson
