/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Button, Dropdown, Menu } from 'antd'
import { PlusOutlined, MoreOutlined, CheckOutlined } from '@ant-design/icons'

import WS0641001_EscortDetailSub from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641001_EscortDetailSub.jsx'
import WS0640004_CopyingProcess from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0640004_CopyingProcess.jsx'
import WS0640005_NewAddChangeEscortTableEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0640005_NewAddChangeEscortTableEdit.jsx'
import EscortMaintainAction from 'redux/AdvancePreparation/DocumentManageMaintain/EscortMaintain.action'


class WS0640001_EscortMaintain extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_EscortCode: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = 'エスコート保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: 0,
      old_escort_code: null,
      SearchChar: '',

      isReload: true,

      isLoadDataDetail: false,
    }
  }

  componentDidMount() {
    this.getDataTable()
  }

  getDataTable() {

    let param = {
      Format: this.props.Format
    }

    EscortMaintainAction.getDataTable(param)
      .then((res) => {
        let index = this.state.indexTable
        let data = res ? res : []
        if (data.length > 0) {
          data.map((x, index) => {
            x.fontWeight = this.state.SearchChar ? (this.checkTextBoldBySearch(index, data) ? 'bold' : '') : ''
          })
        }

        if (this.state.isReload) {
          let indexCode = (res && this.props.Li_EscortCode) ? res.findIndex(x => x.escort_code === this.props.Li_EscortCode) : 0
          this.setState({
            dataSource: res ? data : [],
            rowSelected: res && res.length > 0 ? [data[indexCode]] : [],
            selectedRowKeys: res && res.length > 0 ? [data[indexCode].id] : [],
            indexTable: indexCode,
            old_escort_code: res && res.length > 0 ? data[indexCode].escort_code : null,
          })
        } else {
          this.setState({
            dataSource: res ? data : [],
            rowSelected: res && res.length > 0 ? [data[index]] : [],
            selectedRowKeys: res && res.length > 0 ? [data[index].id] : [],
          })
        }
      })
  }

  checkDuplicateCode() {
    let lstData = [...this.state.dataSource]
    const uniqueValues = new Set(lstData.map(v => v.escort_code))

    if (uniqueValues.size < lstData.length) {
      return true
    } return false
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  // check id null
  checkIdTemp(id) {
    if (id === '') {
      return true
    }
    return false
  }

  checkAddItem() {
    if (this.state.dataSource.length > 0) {
      let index = this.state.dataSource.findIndex(x => !x.escort_code)
      if (index === -1) {
        return false
      }
      return true
    }
  }

  checkDisabledBtnAdd() {
    if (this.state.rowSelected.length > 0) {
      if (this.checkAddItem() || this.checkIdTemp(this.state.rowSelected[0].id) ||
        (!this.checkIdTemp(this.state.rowSelected[0].id) &&
          this.state.rowSelected[0].escort_code !== this.state.old_escort_code)) {
        return true
      } return false
    } return false
  }

  // add new record
  async handleAddRowTable() {
    let newRow = { id: '' }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      rowSelected: [newRow],
      selectedRowKeys: [newRow.id],
      indexTable: 0
    })

    this.forceUpdate()
  }

  // check selected record while add new
  changeRow(index) {
    let data = [...this.state.dataSource]

    let idTemp = false
    data.forEach(item => {
      if (this.checkIdTemp(item.id)) {
        idTemp = true
        return idTemp
      }
    })

    if (idTemp) {
      this.setState({
        rowSelected: [data[0]],
        selectedRowKeys: [data[0].id],
        indexTable: 0
      })
    } else {
      this.setState({
        indexTable: index
      })
    }
  }

  async handleDeleteRowTable() {
    let data = [...this.state.dataSource]
    await data.splice(0, 1)
    await this.setState({
      dataSource: data,
      indexTable: 0,
      rowSelected: data.length > 0 ? [data[0]] : [],
      selectedRowKeys: data.length > 0 ? [data[0].id] : [],
      old_escort_code: data.length > 0 ? data[0].escort_code : null
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  checkTextBoldBySearch(index, data) {
    let rerult = false
    if (this.state.SearchChar && data.length > 0) {
      if (data[index].escort_content_1?.toString().includes(this.state.SearchChar)
        || data[index].escort_content_2?.toString().includes(this.state.SearchChar)
        || data[index].escort_content_3?.toString().includes(this.state.SearchChar)
        || data[index].escort_content_4?.toString().includes(this.state.SearchChar)
        || data[index].escort_content_5?.toString().includes(this.state.SearchChar)
      ) {
        rerult = true
      }
    }
    return rerult
  }

  showDetailSub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1300,
        className: '',
        component:
          <WS0641001_EscortDetailSub
            record={record}
          />
      },
    })
  }

  newAddChangeTable = (record = null) => {
    let newflag = false
    let id = null

    if (!record) {
      newflag = true
    } else {
      id = record.id
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0640005_NewAddChangeEscortTableEdit
            record={record}
            newflag={newflag}

            onUpdate={(output) => {
              let params = {
                id: id,
                escort_code: output.escort_code,
                escort_name: output.escort_name,
              }
              EscortMaintainAction.createAndUpdateData(params)
                .then((res) => {
                  this.closeModal()
                })
            }}
            onDelete={() => {
              let params = {
                id: id
              }
              EscortMaintainAction.deleteData(params)
                .then((res) => {
                  this.closeModal()
                })
            }}
          />
        )
      }
    })
  }

  render() {
    return (
      <div className='escort-maintain'>
        <Card title='エスコート保守　内容設定' style={{ position: 'relative' }}>
          <Table
            size='small'
            style={{ cursor: 'pointer' }}
            dataSource={this.state.dataSource}
            pagination={false}
            bordered
            rowKey={(record) => record.id}
            scroll={{ x: 400, y: 700 }}
            onRow={(record, rowIndex) => {
              let index = this.state.dataSource.findIndex(x => x.id === record.id)
              return {
                onDoubleClick: async () => {
                  this.showDetailSub(record)
                }

              }
            }}
          >

            <Table.Column
              title='使用'
              dataIndex='useFlag'
              width={45}
              align='center'
              render={(value, record, index) => {
                if (value) {
                  return <CheckOutlined />
                } else {
                  return ''
                }
              }}
            />

            <Table.Column
              title='エスコート'
              dataIndex='escort_code'
              width={100}
            />

            <Table.Column
              title='エスコート名称'
              dataIndex='escort_name'
            />

            <Table.Column
              width={40}
              title={
                <div
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    size='small'
                    disabled={this.checkDisabledBtnAdd()}
                    onClick={() => { this.newAddChangeTable() }}
                    type='primary'
                    icon={<PlusOutlined />}>
                  </Button>
                </div>
              }

              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='変更'
                          onClick={() => {
                            this.newAddChangeTable(record)
                          }}
                        >変更
                        </Menu.Item>

                        <Menu.Item
                          key='詳細'
                          onClick={() => {
                            this.showDetailSub(record)
                          }}
                        >詳細
                        </Menu.Item>

                        <Menu.Item
                          key='複写'
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 500,
                                component: (
                                  <WS0640004_CopyingProcess
                                    Li_CopySourceCode={record.escort_code}
                                    Li_CopyName={record.escort_name}
                                    onFinishScreen={(output) => {
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        >複写
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                    ></Button>
                  </Dropdown>
                )
              }}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0640001_EscortMaintain)
