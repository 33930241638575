import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Form, Input ,Card,} from "antd";  
class WS0612002_CharacterStringSearch extends React.Component {
  static propTypes = {
    Lio_SearchString: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  formRef = React.createRef(); 
  constructor(props) {
    super(props); 
    this.state = {
    };
  } 
  componentDidMount(){
    this.formRef.current?.getFieldValue({
      Lio_SearchString: this.props.Lio_SearchString
    })
  }
  componentDidUpdate(preV) {
    if (this.props != preV) {
      this.formRef.current?.getFieldValue({
        Lio_SearchString: this.props.Lio_SearchString
      })
    }
  }
  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }
  render() {
    return (
      <div className="character-string-search">
        <Card title="CharacterSearchString">
        <Form ref={this.formRef} autoComplete="off" >
        <Form.Item name="Lio_SearchString"  label="文字列検索" >
          <Input maxLength={256} onBlur={()=>{
            if(this.props.onFinishScreen){
              this.props.onFinishScreen({Lio_SearchString: this.isEmpty(this.formRef.current?.getFieldValue("Lio_SearchString")) ? "": this.formRef.current?.getFieldValue("Lio_SearchString")})
            }
          }} />
        </Form.Item>
        </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0612002_CharacterStringSearch);
