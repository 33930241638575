import axios from "configs/axios";

const API_LIST = {
  loadScreenData: "/api/provisional-reservation/add-date/get-screen-data",
};

const ReservationStatusService = {
  async loadScreenData(params) {
    return axios.post(API_LIST.loadScreenData, params);
  }
};

export default ReservationStatusService;
