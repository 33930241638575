import axios from 'configs/axios'

const API_LIST = {
  index: '/api/consult-info-reconstruction/condition-add-sub',
  update: '/api/consult-info-reconstruction/condition-add-sub/update',
  clearData: '/api/consult-info-reconstruction/condition-add-sub/clear',
  getNameOfficeCode: '/api/consult-info-reconstruction/condition-add-sub/get-name-office-code',
  getNamePersonal: '/api/consult-info-reconstruction/condition-add-sub/get-name-personal-num',
  indexPlus: '/api/consult-info-reconstruction/condition-add-sub/indexPlus'
}

const ConditionAddSubService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },

  async update(params) {
    return axios.post(API_LIST.update, params)
  },

  async clearData(params) {
    return axios.post(API_LIST.clearData, params)
  },

  async getNameOfficeCodeService(params) {
    return axios.get(API_LIST.getNameOfficeCode, { params })
  },

  async getNamePersonalService(params) {
    return axios.get(API_LIST.getNamePersonal, { params })
  },

  async indexPlus(params) {
    return axios.get(API_LIST.indexPlus, { params })
  },
}

export default ConditionAddSubService
