import React from 'react'

import { Card, Form, Input, Select, Table, Dropdown, Menu, Button, Tooltip, message, Tag, Modal } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0307008_ContractHistorySub from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx'
import WS0306011_CreateNew from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0306011_CreateNew.jsx'
import WS0316001_CopyContractSelect from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0316001_CopyContractSelect.jsx'
import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import moment from 'moment'
import WS0426001_MasterListOutputContractInfo from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0426001_MasterListOutputContractInfo.jsx'
import Color from 'constants/Color'
import MagicXpaFunc from 'helpers/MagicXpaFunc'
// import Menubar from 'components/Commons/Menubar'
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ContractInfoMaintainAction from 'redux/basicInfo/ContractInfoMaintain/ContractInfoMaintain.actions'
import WS0307010_ContractHistorySubTableMock from 'pages/Mock/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307010_ContractHistorySubTableMock.jsx'

class WS0306001_ContractInfoMaintainMock extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '契約情報登録'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      tableData: [],
      selectedRows: {},
      selectedRowKeys: [],
    }
  }

  componentDidMount() {
    this.loadContracts()
  }

  loadContracts = () => {
    let params = {
      Li_ContractType: this.formRef.current.getFieldValue('ContractType'),
      Lio_SearchName: this.formRef.current.getFieldValue('SearchChar'),
    }
    ContractInfoMaintainAction.loadContracts(params)
      .then((res) => {
        res.sort(function (a, b) { // ソート第一キー: 種別, 第二キー: コード
          if (a.contract_type < b.contract_type) {
            return -1;
          } else if (a.contract_type > b.contract_type) {
            return 1;
            // 種別が同一の場合コードでソート
          } else if (Number(a.contract_organization_code) < Number(b.contract_organization_code)) {
            return -1;
          } else {
            return 1
          }
        })
        const lastSelected = res?.find(elmt => elmt.id === this.state.selectedRows.id)
        const selected = lastSelected ? lastSelected : (res[0] || {})
        this.setState({
          tableData: res,
          selectedRows: selected,
          selectedRowKeys: [selected.id],
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  onFinish(values) {

  }
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  eventF4 = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: 500,
        visible: true,
        component: ( // WS0308017_OrganizationCreateNew
          <WS0306011_CreateNew
            Li_ContractType={this.state.selectedRows?.contract_type}
            Li_ContractOrgCode={this.state.selectedRows?.contract_organization_code}
            onFinishScreen={() => {
              this.loadContracts()
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        )
      }
    })
  }

  eventF9 = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: 600,
        visible: true,
        component: (
          <WS0426001_MasterListOutputContractInfo
            Li_ContractType={this.state.selectedRows?.contract_type}
            Li_ContractOrgCode={''}
            Li_ContractStartDate={''}
            Li_ContractNum={''}
            Li_StsContractType={this.state.selectedRows?.contract_type || ''}
            Li_StsContractOrgCode={false}
            Li_StsContractStartDate={false}
            Li_StsContractNum={false}
            onFinishScreen={() => {
              this.loadContracts()
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        )
      }
    })
  }

  eventF11 = () => {
    let params = {
      StsConfirm: 6
    }
    ModalConfirm({
      content: '契約情報の内容を再展開しますか',
      styleIconModal: { color: '#006CC9' },
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        ContractInfoMaintainAction.eventF11(params)
          .then(() => this.loadContracts())
          .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      }
    })
  }

  setRCV = () => {
    let params = {}
    ContractInfoMaintainAction.setRCV(params)
      .then(() => this.loadContracts())
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  billRCV = () => {
    let params = {}
    ContractInfoMaintainAction.billRCV(params)
      .then(() => this.loadContracts())
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  render() {
    return (
      <div className='contract-info-maintain'>
        <Card title='契約情報保守（モック）'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              ContractType: '',
            }}
          >
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className={(this.props.Li_Flag) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                trigger='click'
                size='small'
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1' style={{ width: '100%' }} onClick={this.eventF9}>
                        出力
                      </Menu.Item>
                      <Menu.Item key='2' style={{ width: '100%' }} onClick={this.eventF11}>
                        展開 再作成
                      </Menu.Item>
                      <Menu.Item key='3' style={{ width: '100%' }} onClick={this.billRCV}>
                        請求先 再作成
                      </Menu.Item>
                      <Menu.Item key='4' style={{ width: '100%' }} onClick={this.setRCV}>
                        セット日付リカバリ
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>
            <div className='box_inner_horizontal'>
              <Form.Item name='ContractType' label='種別' style={{ maxWidth: '200px', }}>
                <Select onChange={() => this.loadContracts()}>
                  <Select.Option value=''>全　て</Select.Option>
                  <Select.Option value={0}>共　通</Select.Option>
                  <Select.Option value={1}>保険者</Select.Option>
                  <Select.Option value={2}>事業所</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name='SearchChar' label='検索'>
                <Input onChange={debounce(() => this.loadContracts(), 300)} />
              </Form.Item>
            </div>

            <Table
              size='small'
              bordered
              scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
              dataSource={this.state.tableData}
              pagination={false}
              rowKey={record => record.id}
              onRow={(record) => {
                return {
                  onClick: () => {
                    const selected = record
                    this.setState({
                      selectedRowKeys: [selected.id],
                      selectedRows: selected,
                    })
                  },
                  onDoubleClick: () => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 'fit-content',
                        component: (
                          <WS0307010_ContractHistorySubTableMock
                            Li_ContractType={record.contract_type}
                            Li_ContractOrgCode={record.contract_organization_code}
                            Lio_ContractStartDate={record.ContractStartDate}
                            onFinishScreen={() => {
                              this.loadContracts()
                              this.closeModal()
                            }}
                          />
                        )
                      }
                    })
                  }
                }
              }}
            >
              <Table.Column
                // width={70}
                className='column-size-10'
                title='種別'
                dataIndex='contract_type'
                render={(value) => (
                  <Tag color={Color(MagicXpaFunc.CASE(value, 1, 297, 2, 298, 295)).Background}
                    style={{ color: '#000', textAlign: 'center', padding: 0, border: '1px solid #808080', width: '100%' }}>
                    {MagicXpaFunc.CASE(value, 1, '保険者', 2, '事業所', '共通')}
                  </Tag>
                )}
              />
              <Table.Column
                title='コード'
                dataIndex='contract_organization_code'
                className='column-size-10'
                render={(text) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  )
                }}
              />
              <Table.Column
                title='団体名'
                dataIndex='contract_name'
                ellipsis
                className='column-size-60'
              />
              <Table.Column
                // width={120}
                className='column-size-10'
                title='契約年度'
                dataIndex='ContractStartDate'
                render={(value) => (
                  <div style={{ textAlign: 'center' }}>{moment(value).isValid() ? moment(value).format('YYYY年MM月') : value}</div>
                )}
              />
              <Table.Column
                // width={40}
                className='column-size-2'
                key='action'
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <WS0306011_CreateNew
                              Li_ContractType={0}
                              newFlag={true}
                              onFinishScreen={() => {
                                this.formRef.current.setFieldsValue({
                                  ContractType: '',
                                  SearchChar: ''
                                })
                                this.loadContracts()

                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: false,
                                  },
                                })
                              }}
                            />
                          )
                        }
                      })
                    }
                  />}
                render={(row) => {
                  return (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: 600,
                                  visible: true,
                                  component: (
                                    <WS0307008_ContractHistorySub
                                      Li_ContractType={this.state.selectedRows.contract_type}
                                      Li_ContractOrgCode={this.state.selectedRows.contract_organization_code}
                                      Li_ContractStartDate={this.state.selectedRows.ContractStartDate}
                                    />)
                                }
                              })
                            }}>契約照会</Menu.Item>
                          <Menu.Item key='2'
                            onClick={() => {
                              let params = {
                                id: row.id,
                              }
                              Modal.confirm({
                                content: '削除を行いますか',
                                onOk: () => {
                                  ContractInfoMaintainAction.DeleteLine(params)
                                    .then((res) => {
                                      this.loadContracts()
                                    })
                                    .catch(error => {
                                      const res = error.response
                                      if (!res || !res.data || !res.data.message) {
                                        message.error('エラーが発生しました')
                                        return
                                      }

                                      message.error(res.data.message)
                                    })
                                }
                              })
                            }}
                          >
                            <div>削除</div>
                          </Menu.Item>
                          <Menu.Item key='3' onClick={() => {
                            this.setState({
                              ...this.state,
                              childModal: {
                                width: 500,
                                visible: true,
                                component: (<WS0306011_CreateNew
                                  newFlag={false}
                                  Li_ContractType={row.contract_type}
                                  Li_ContractOrgCode={row.contract_organization_code}
                                  onFinishScreen={() => {
                                    this.loadContracts()

                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />)
                              }
                            })
                          }}>
                            <div>年度更新</div>
                          </Menu.Item>
                          <Menu.Item key='4' onClick={() => {
                            let params = {
                              id: row.id,
                              ContractStartDate: row.ContractStartDate
                            }
                            Modal.confirm({
                              content: '削除を行いますか',
                              onOk: () => {
                                ContractInfoMaintainAction.DeleteHistoryContract(params)
                                  .then((res) => {
                                    this.loadContracts()
                                  })
                                  .catch(error => {
                                    const res = error.response
                                    if (!res || !res.data || !res.data.message) {
                                      message.error('エラーが発生しました')
                                      return
                                    }

                                    message.error(res.data.message)
                                  })
                              }
                            })
                          }}>
                            <div>年度削除</div>
                          </Menu.Item>
                          <Menu.Item
                            key='5'
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: '80vw',
                                  visible: true,
                                  component: (<WS0316001_CopyContractSelect
                                    Li_ContractType={row.contract_type}
                                    Li_ContractOrgCode={row.contract_organization_code}
                                    Li_ContractStartDate={row.ContractStartDate}

                                    onFinishScreen={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                      this.loadContracts()
                                    }}
                                  />)
                                }
                              })
                            }}>
                            <div>複写</div>
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )
                }} />
            </Table>
          </Form>
        </Card >

        < ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

export default WS0306001_ContractInfoMaintainMock
