import React from "react";

import {
  Card, Table, message, Button, Form, Input, Select, Popconfirm, Space, Menu, Dropdown,
  Modal
} from "antd";
import {
  MoreOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined
} from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import resizableTableScroll from "components/Commons/ResizableTableScroll";

import axios from "configs/axios";
import WS1390001_ImplementClassifyInquiry from "./WS1390001_ImplementClassifyInquiry";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ImplementLocationMasterAction from "redux/SpecificInsureMaintenance/ImplementLocationMaster/ImplementLocationMaster.action";
import { forEach } from "lodash";

const columns = [
  {
    title: '実施場所コード',
    dataIndex: 'implement_location_code',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },

  {
    title: '実施場所',
    dataIndex: 'implement_location',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '実施区分',
    dataIndex: 'implement_division',
    // classNumber: '40',
    type: 4,
    childModal: {
      width: '60%',
      component: WS1390001_ImplementClassifyInquiry
    },
  },
  {
    title: '実施区分コード',
    dataIndex: 'implement_division_code',
    // classNumber: '40',
    type: 9,
  },
]


class WS1405001_ImplementLocationMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '実施場所マスタ';

    this.state = {
      childModal: {
        implement_division: null,
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    ImplementLocationMasterAction.getIndex()
      .then(res => {
        this.formRef.current.setFieldsValue({
          MainSource: res.MainSource,
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  Delete(params) {
    this.setState({ isLoading: true });
    ImplementLocationMasterAction.deleteLocation(params.itemChange)
      .then(res => {
        this.loadMainSource({});
        this.closeModal();
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  onSave(params) {
    ImplementLocationMasterAction.postLocation(params.itemChange)
      .then(res => {
        this.loadMainSource({});
        this.closeModal();
      })
  }

  onAdd(params) {
    ImplementLocationMasterAction.addLocation(params.itemChange)
      .then(res => {
        this.loadMainSource({});
        this.closeModal();
      })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  handleAdd = () => {
    const newData = {
      id: Math.random(),
      implement_location_code: '',
      implement_location: '',
      implement_division_code: '',
      implement_division: '',

    }
    let data = [...this.formRef.current?.getFieldValue("MainSource")];
    data.push(newData)
    this.formRef.current?.setFieldsValue({
      MainSource: data,
    });
    this.setState({
      dataSource: data
    })
    this.forceUpdate()
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card className="mb-2" title="実施場所マスタ">
          <Form ref={this.formRef}>
            <Table bordered
              size="small"
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
              }}
              rowKey={(record) => (record.id || Math.random())}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () => {
                  ImplementLocationMasterAction.getIndex(record.id)
                    .then(res => {
                      console.log(res.MainSource[0])
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalChange
                              columns={columns}
                              record={res.MainSource[0]}
                              onUpdate={(output) => {

                                if (!output.itemChange.implement_location_code) {
                                  message.error('実施場所コードが入力されていません');
                                }

                                if (!output.itemChange.implement_division) {
                                  message.error('実施区分が入力されていません');
                                }

                                if (output.itemChange.implement_location_code &&
                                  output.itemChange.implement_division) {
                                  this.onSave(output)
                                  this.closeModal()
                                }

                              }}
                              onDelete={(output) => {
                                this.Delete(output)
                              }}
                            />
                        },
                      })
                    })
                    .catch(error => {
                      console.log(error);

                      const res = error.response;
                      if (!res || !res.data || !res.data.message) {
                        message.error('エラーが発生しました');
                        return;
                      }

                      message.error(res.data.message);
                    })
                }
              })}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
            >
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implement_location_code.localeCompare(b.implement_location_code, 'ja')} title="実施場所コード" dataIndex="implement_location_code" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implement_location.localeCompare(b.implement_location, 'ja')} title="実施場所" dataIndex="implement_location" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implement_division.localeCompare(b.implement_division, 'ja')} title="実施区分" dataIndex="implement_division" />
              <Table.Column align='center' width={40} fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {

                                if (!output.itemChange.implement_location_code) {
                                  message.error('実施場所コードが入力されていません');
                                }

                                if (!output.itemChange.implement_division) {
                                  message.error('実施区分が入力されていません');
                                }

                                if (output.itemChange.implement_location_code &&
                                  output.itemChange.implement_division) {
                                  this.onAdd(output)
                                  this.closeModal()
                                }

                              }}
                            />
                        },
                      });
                    }}
                  />
                }
                render={(text, record, index) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // 編集、削除
                      ImplementLocationMasterAction.getIndex(record.id)
                        .then(res => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalChange
                                  columns={columns}
                                  record={res.MainSource[0]}
                                  onUpdate={(output) => {

                                    if (!output.itemChange.implement_location_code) {
                                      message.error('実施場所コードが入力されていません');
                                    }

                                    if (!output.itemChange.implement_division) {
                                      message.error('実施区分が入力されていません');
                                    }

                                    if (output.itemChange.implement_location_code &&
                                      output.itemChange.implement_division) {
                                      this.onSave(output)
                                      this.closeModal()
                                    }

                                  }}
                                  onDelete={(output) => {
                                    this.Delete(output)
                                  }}
                                />
                            },
                          })
                        })
                        .catch(error => {
                          console.log(error);

                          const res = error.response;
                          if (!res || !res.data || !res.data.message) {
                            message.error('エラーが発生しました');
                            return;
                          }

                          message.error(res.data.message);
                        })
                    }}
                  />

                )}
              />
            </Table>

            {/* <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              <Button type="primary" htmlType="submit" loading={this.state.isLoading}>保存</Button>
            </Form.Item> */}
          </Form>
        </Card>

        <ModalDraggable
          // destroyOnClose={true}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS1405001_ImplementLocationMaster;
