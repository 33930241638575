import axios from "configs/axios";

const API_LIST = {
  loadScreenData: "/api/insure-guide-init-input/insure-guide-course-inquiry",
  getScreenData: "/api/insure-guide-init-input/financial-support-inquiry",
};

const TargetivesResultInputService = {
  async loadScreenData(params) {
    return axios.get(API_LIST.loadScreenData, { params });
  },
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
};

export default TargetivesResultInputService;
