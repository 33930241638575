import axios from "configs/axios";
const apiPaths = {
    getScreenData: "/api/main/send-email/getscreendata",
    onClickSend: "/api/main/send-email/onclicksend",
};
const SendEmailService = {
    async getScreenData(params) {
        return axios.get(apiPaths.getScreenData, { params });
    },
    async onClickSend(params) {
        return axios.get(apiPaths.onClickSend, { params });
    },
};

export default SendEmailService;