import axios from 'configs/axios'
const apiPaths = {
  index: '/api/mirais-single-transmission/mirais-singleDoctor-setting',
  saveAndUpdateData: '/api/mirais-single-transmission/mirais-singleDoctor-setting/save-and-update-data',
  delete: '/api/mirais-single-transmission/mirais-singleDoctor-setting/delete'
}

const MiraisSingleDoctorSettingService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async saveAndUpdateData(params) {
    return axios.post(apiPaths.saveAndUpdateData, params)
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },
}

export default MiraisSingleDoctorSettingService