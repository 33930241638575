import { message } from 'antd'
import PatientInfoQueryOthersService from 'services/basicInfo/PersonalInfoMaintain/PatientInfoQueryOthersService'

const PatientInfoQueryOthersAction = {
  index(params) {
    return PatientInfoQueryOthersService.index(params)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  }
}
export default PatientInfoQueryOthersAction
