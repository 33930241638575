import axios from 'configs/axios';

const API_LIST = {
  exportTxt: '/api/deposit-withdrawal-inquiry/export-data/exportTxt',
  exportHtml: '/api/deposit-withdrawal-inquiry/export-data/exportHtml',
  exportXml: '/api/deposit-withdrawal-inquiry/export-data/exportXml',
  exportXsd: '/api/deposit-withdrawal-inquiry/export-data/exportXsd',
};

const ExportDataService = {
  /**
   * txt出力
   */
  async ExportTxt(params) {
    return axios.post(API_LIST.exportTxt, params, { responseType: 'blob' });
  },
  /**
   * html出力
   */
  async ExportHtml(params) {
    return axios.post(API_LIST.exportHtml, params, { responseType: 'blob' });
  },
  /**
   * txt出力
   */
  async ExportXml(params) {
    return axios.post(API_LIST.exportXml, params, { responseType: 'blob' });
  },
  /**
   * txt出力
   */
  async ExportXsd(params) {
    return axios.post(API_LIST.exportXsd, params, { responseType: 'blob' });
  },
};

export default ExportDataService;
