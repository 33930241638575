import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";

class WS0358003_UseInspect extends React.Component {
  formRef = React.createRef();
  
  constructor(props) {
    super(props);

    // document.title = '検査コメント情報保守 SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [{
        Expression_29: 1,
        id: 1, exam_comment_code: '1', exam_comment_screen: 'exam', exam_comment_form: '3', Option: '10', StsEnable: 1
      }],
    };
  }

  
  onCheckStsEnabled(record, event) {
    const array = [...this.state.dataSource];
    const checked = event.target.checked ? 1 : 0;
    const index = this.state.dataSource.findIndex(s => s.id === record.id);
    array[index].StsEnable = checked;
    this.setState({ dataSource: array });
    this.forceUpdate()
  }
  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }
  render() {
    return (
      <div className="use-inspect">
        <Card title="使用検査">
          <Table bordered 
            dataSource={this.state.dataSource}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: 1,
              pageSize: 1,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="検査コード" dataIndex="test_item_code" key="" />
            <Table.Column title="名称" dataIndex="exam_name" key="" />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0358003_UseInspect);
