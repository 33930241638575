import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Input,
  Checkbox,
  Button,
  Table,
  Tabs,
  Row,
  Space,
  Col,
  Modal,
  message,
  Radio
} from "antd";
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import DockDisplayItemSettingAction from 'redux/Others/MedicalCheckupCalendar/DockDisplayItemSetting.action'

class DockDisplayItemSettingEditMock extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      inspectItems: [],
      targetInspect: [],
      rowSelectLeft: {},
      rowSelectRight: {},
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue(this.props.record)
    this.loadDataLeft()
    this.loadDataRight()
  }

  /**
   * 右側のテーブル取得
   */
  loadDataRight = () => {
    let params = this.props.record
    DockDisplayItemSettingAction.getRightTable(params)
      .then(res => {
        if (res) {
          this.setState({ targetInspect: res })
        }
      })
      .catch()
  }


  /**
   * 左側のテーブル取得
   */
  loadDataLeft = () => {
    let params = {
      ...this.props.record,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
    }
    DockDisplayItemSettingAction.getLeftTable(params)
      .then(res => {
        if (res) {
          let textColor
          for (let l = 0; l < res.length; l++) {
            for (let k = 0; k < this.state.targetInspect.length; k++) {
              if (res[l].course_code === this.state.targetInspect[k].course_code) {
                textColor = {
                  ...res[l],
                  textColor: '#b9b9b9'
                }
                res[l] = textColor
              }
            }
          }
          this.setState({ inspectItems: res })
        }
      })
      .catch()
  }

  /**
   * 左側のテーブルから右側に移す
   * @param {*} selectValue 
   */
  onForward(selectValue) {
    let rightData = [...this.state.targetInspect]
    selectValue = {
      ...selectValue,
    }

    rightData.push(selectValue)

    // 重複チェック
    let duplicationCheck = rightData.filter((e, index, self) => {
      return self.findIndex((data) => data.course_code === e.course_code) === index
    })

    // 並び替え
    duplicationCheck.sort(function (a, b) {
      if (a.course_code > b.course_code) {
        return 1;
      } else {
        return -1;
      }
    })
    // 追加された行の色を変える
    let copyDataLeft = [...this.state.inspectItems]
    for (let i = 0; i < copyDataLeft.length; i++) {
      if (copyDataLeft[i].course_code === selectValue.course_code) {
        copyDataLeft[i].textColor = '#b9b9b9';
        copyDataLeft[i].reservation_display_item_number = this.props.record.nameId;
      }
    }

    this.setState({
      inspectItems: copyDataLeft,
      rowSelectLeft: null,
      targetInspect: duplicationCheck,
      enabledForward: false
    })
  }

  /**
   * 右のテーブルから削除処理
   * @param {*} record 
   */
  onBackward(record) {
    let copyTargetInspect = [...this.state.targetInspect]
    for (let i = 0; i < copyTargetInspect.length; i++) {
      if (copyTargetInspect[i] === record) {
        let delteLenght = i
        copyTargetInspect.splice(delteLenght, 1)
      }
    }
    // 選択した行の色を変える
    let copyInspectItems = [...this.state.inspectItems]
    for (let l = 0; l < copyInspectItems.length; l++) {
      if (copyInspectItems[l].course_code === record.course_code) {
        copyInspectItems[l].textColor = '';
        copyInspectItems[l].reservation_display_item_number = 0;
      }
    }

    this.setState({
      inspectItems: copyInspectItems,
      targetInspect: copyTargetInspect,
      rowSelectLeft: null,
      enabledBackward: false
    })
  }

  /**
   * 保存処理
   * @param {*} params 
   */
  RegisterBtn() {
    let params = {
      keyName: this.props.record.keyName,
      nameId: this.props.record.nameId,
      managementDivision: this.props.record.managementDivision,
      displayName: this.formRef.current?.getFieldValue('displayName'),
      data: this.state.targetInspect
    };
    DockDisplayItemSettingAction.save(params)
      .then((res) => {
        console.log('保存処理');
        this.props.onFinishScreen()
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="reserves-displayitem-setting">
        <Card title="人間ドック表示項目設定編集" className="mb-2">
          <Form ref={this.formRef} autoComplete='off'>
            <Form.Item name='nameId' label="No.">
              <Input bordered={false} readOnly style={{ width: 100 }} />
            </Form.Item>

            <Form.Item name="displayName" label="表示名称" >
              <Input type="text" maxLength={10} style={{ width: 100 }} />
            </Form.Item>


            <div className="box_inner_horizontal">
              <div className="box_inner_vertical_label_top">
                <div
                  className="table_header_filter" ><div>検査一覧</div>

                  <Form.Item name='SearchChar' style={{ margin: 0 }}>
                    <Input size='small' onPressEnter={(e) => this.loadDataLeft()} />
                  </Form.Item>

                </div>
                <Table
                  bordered
                  dataSource={this.state.inspectItems}
                  pagination={this.state.inspectItems.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  loading={false}
                  size='small'
                  showHeader={false}
                  rowKey={(record) => record.course_code}
                  rowClassName={(record, index) => record.id === this.state.rowSelectLeft?.id ? 'table-row-light' : ''}
                  scroll={{ x: 300, y: 350 }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        this.onForward(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectRight: null,
                          rowSelectLeft: record,
                          enabledForward: true,
                          enabledBackward: false,
                        });
                      }
                    };
                  }}
                >
                  <Table.Column width={80} title="コード" dataIndex="course_code" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.course_code}</span>
                    )} />

                  <Table.Column width={260} title="名称" dataIndex="exam_name" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.exam_name}</span>
                    )} />

                </Table>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  gap: '16px',
                  padding: '16px',
                  margin: 'auto',
                  minWidth: '50px'
                }}>
                <Button size="small" type='primary'
                  disabled={!this.state.enabledForward}
                  onClick={() => this.onForward(this.state.rowSelectLeft)}>
                  追加 <DoubleRightOutlined /></Button>
                <Button size="small" type='primary'
                  icon={<DoubleLeftOutlined />}
                  disabled={!this.state.enabledBackward}
                  onClick={() => this.onBackward(this.state.rowSelectRight)}>
                  削除</Button>
              </div>
              <div className="box_inner_vertical_label_top">
                <div className="table_header_filter"                >
                  <div>予約表示検査</div>
                </div>

                <Table
                  bordered
                  dataSource={this.state.targetInspect}
                  pagination={this.state.targetInspect.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  loading={false}
                  size='small'
                  showHeader={false}
                  rowKey={(record) => record.course_code}
                  rowClassName={(record, index) => record.id === this.state.rowSelectRight?.id ? 'table-row-light' : ''}
                  scroll={{ x: 300, y: 350 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.onBackward(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectRight: record,
                          rowSelectLeft: null,
                          enabledForward: false,
                          enabledBackward: true,
                        });
                      }
                    };
                  }}
                >
                  <Table.Column width={80} title="コード" dataIndex="course_code" key="" />
                  <Table.Column width={260} title="名称" dataIndex="exam_name" key="" />
                </Table>
              </div>
            </div>

            <div className="box_button_bottom_right"
              style={{ marginTop: 0 }}>
              <Button
                type="primary"
                onClick={() => { this.RegisterBtn() }}
                icon={<SaveOutlined />}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }


}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DockDisplayItemSettingEditMock);
