import axios from 'configs/axios';

const apiPaths = {
  redisplay_F11: '/api/condition-express-cmt-setting/condition-correct-sub/redisplay-f11',
  getEquationDisplay: '/api/condition-express-cmt-setting/condition-correct-sub/get-equation-display',
};

const ConditionExpressCmtSubService = {
  async redisplay_F11(params) {
    return axios.get(apiPaths.redisplay_F11, { params });
  },

  async getEquationDisplay(params) {
    return axios.get(apiPaths.getEquationDisplay, { params });
  },
};

export default ConditionExpressCmtSubService;
