import axios from "configs/axios";

const groupPath = "/api/consult-ticket-input-process-list";

const APP_LIST = {
  getScreenData: `${groupPath}/consult-ticket-input-process-list/get-screen-data`,
  getSearchData: `${groupPath}/consult-ticket-input-process-list/get-search-data`,
  listProcess: `${groupPath}/consult-ticket-input-process-list/list-process`,
};
const ConsultTicketInputProcessListService = {
  async GetScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async GetSearchData(params) {
    return axios.get(APP_LIST.getSearchData, { params });
  },
  async listProcess(params) {
    return axios.post(APP_LIST.listProcess, params);
  },
};

export default ConsultTicketInputProcessListService;
