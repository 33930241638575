import { message } from 'antd'
import FindingsInputNormalSelectService from 'services/InputBusiness/SpreadInput/FindingsInputNormalSelectService'

const FindingsInputNormalSelectAction = {
  index(data) {
    return FindingsInputNormalSelectService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getSiteFindingsInputThisTime(data) {
    return FindingsInputNormalSelectService.getSiteFindingsInputThisTime(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getSiteFindingsPrevious(data) {
    return FindingsInputNormalSelectService.getSiteFindingsPrevious(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  save(data) {
    return FindingsInputNormalSelectService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  delete(data) {
    return FindingsInputNormalSelectService.delete(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  setPreviousFindingsDo(data) {
    return FindingsInputNormalSelectService.setPreviousFindingsDo(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}

export default FindingsInputNormalSelectAction
