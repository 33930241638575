import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Row, Col, Space, Typography, message } from "antd";

import ConvertTblReplicationProcessAction from "redux/SystemMaintenance/UserParamMaintain/ConvertTblReplicationProcess.action";



class WS0449007_ConvertTblReplicationProcess extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '変換テーブル複写処理';

    this.state = {
      isLoading: false,
      destinationFormat: '',
      // destinationRemarks: ''
    };
  }

  componentDidMount() {

    this.getscreendata()
  }


  getscreendata() {
    ConvertTblReplicationProcessAction.getscreendata()
      .then(res => {
        console.log(res)
      })
  }

  onFinish() {

    if (this.state.destinationFormat === '') {
      message.error('必須項目を入力してください')
      return
    }

    const params = {
      ...this.props.record,
      destinationFormat: this.state.destinationFormat,
      // destinationRemarks: this.state.destinationRemarks
    }

    console.log(params)

    ConvertTblReplicationProcessAction.convertTableReplicateRegister(params)
      .then(res => {
        console.log(res)
        this.props.onFinishScreen()

      }).catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
      <div className="convert-tbl-replication-process">
        <Card title="変換テーブル複写処理" className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space direction="vertical">
              <Space size={30}>
                <Typography.Text style={{ color: '#777' }}>
                  複写元
                </Typography.Text>

                <Input type="text" readOnly defaultValue={this.props.record.format} />
                <Input type="text" readOnly defaultValue={this.props.record.remarks} />

              </Space>

              <Space size={30}>
                <Typography.Text style={{ color: '#777' }}>
                  複写先
                </Typography.Text>
                <Input type="text" onChange={(e) => this.setState({ destinationFormat: e.target.value })} />
                {/* <Input type="text" onChange={(e) => this.setState({ destinationRemarks: e.target.value })} /> */}
              </Space>

            </Space>
            <Form.Item style={{ textAlign: "right", marginTop: "2em" }}>
              <Button type="primary" onClick={() => this.onFinish()}>複写</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0449007_ConvertTblReplicationProcess);
