import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, DatePicker, Row, Col,TimePicker } from "antd";

const styleDivTitle = {
  marginRight: '10px',
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}
const styleFormItem = {
  marginRight: '10px',
  textAlign: 'right'
}
const styleFormLabel = {
  textAlign: 'right',
  float: 'right'
}

class WS2624002_VisitsChangeConfirm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '受診変更確認';

    this.state = {
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="visits-change-confirm">
        <Card className="mb-2" title="受診変更確認">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={3}>
                <Form.Item name='' label="個人番号" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={18} >
                <Form.Item name='SiteDigits' style={styleFormItem}>
                  <Input readOnly bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <Form.Item name='' label="氏  名" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={18} >
                <Form.Item name='Li_KanaName' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={3}></Col>
              <Col span={18} >
                <Form.Item name='Li_KanjiName' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <Form.Item name='' label="性  別" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={5} >
                <Form.Item name='' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='' label="生年月日" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name='Li_DateBirth' style={styleFormItem}>
                  <DatePicker format="JYY/MM/DD" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: '40px' }}>
              <Col span={3}>
                <Form.Item name='' label="受診日" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name='Li_Date' style={styleFormItem}>
                  <DatePicker format="JYY/MM/DD" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item style={{ float: 'right' }} >
                  <div style={styleDivTitle}>受 付</div>
                </Form.Item>
              </Col>
            </Row>
            <Row >
              <Col span={3}>
                <Form.Item name='' label="時間帯" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={8} >
                <Form.Item name='Li_TimeZone' style={styleFormItem}>
                  <TimePicker format="HH:mm" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='Li_Am_PmDivision' style={styleFormItem}>
                  <Input readOnly bordered={false} />
                </Form.Item>
              </Col>
            </Row>

            <Row >
              <Col span={3}>
                <Form.Item name='' label="施設" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={19}>
                <Form.Item name='facility_name' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row >
              <Col span={3}>
                <Form.Item name='' label="ｎ次区分" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name=' ' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row >
              <Col span={3}>
                <Form.Item name='' label="コ ー ス" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name='Li_ConsultCourse' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item name='contract_short_name' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row >
              <Col span={3}>
                <Form.Item name='' label="備考" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={19}>
                <Form.Item name='Li_Remarks' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: '40px',height:'40px' }}>
              <Col span={12}>
                <Form.Item style={{ float: 'right' }} >
                  <div style={styleDivTitle}>受付番号</div>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='LnkOutAcceptNum' label="" >
                  <Input style={{textAlign:'right',float:'right'}}>
                  </Input>
                </Form.Item>
              </Col>
              <Col span={8} >
              <Form.Item
              >
                <Button type="primary" style={{ float: 'right', margin: '20px' }}>受　付</Button>
              </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2624002_VisitsChangeConfirm);
