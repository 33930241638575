import axios from 'configs/axios'

const API_LIST = {
  index: '/api/karteco-cooperation-csv-output/karteco-cooperation-csv-output',
  eventSearchButton: '/api/karteco-cooperation-csv-output/karteco-cooperation-csv-output/event-search-button',
  eventCsvButton: '/api/karteco-cooperation-csv-output/karteco-cooperation-csv-output/event-csv-button',
  downloadZipFile: '/api/karteco-cooperation-csv-output/karteco-cooperation-csv-output/download-zip-file',
}

const KartecoCooperationCsvOutputService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async eventSearchButton(params) {
    return axios.post(API_LIST.eventSearchButton, params)
  },
  async eventCsvButton(params) {
    return axios.post(API_LIST.eventCsvButton, params)
  },
  async downloadZipFile(params) {
    // zipダウンロードの為、{responseType: 'arraybuffer'} を設定
    return axios.post(API_LIST.downloadZipFile, params, {
      responseType: 'arraybuffer'
    })
  },
}

export default KartecoCooperationCsvOutputService
