import axios from 'configs/axios';

const apiPaths = {
  loadInspectItemData: '/api/inspect-item-info-maintain/inspect-item-1-change-sub/load-inspect-item-data',
  saveInspectItemAction: '/api/inspect-item-info-maintain/inspect-item-1-change-sub/save-inspect-item-aciton'
};


const InspectItem1ChangeSubService = {
  async loadInspectItemDataService(params) {
    return axios.get(apiPaths.loadInspectItemData, { params });
  },
  async saveInspectItemActionService(params) {
    return axios.post(apiPaths.saveInspectItemAction, params);
  }
};

export default InspectItem1ChangeSubService;
