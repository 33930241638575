import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import { download_file } from "helpers/CommonHelpers";
import { Card, Form, Input, Select, Checkbox, Button, Row, Col, Space, message } from "antd";
import WS0431001_CsvPreviewConfirm from "pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm";
import BillingManageLedgerInstruction from "redux/AccountingBusiness/BillingManageLedgerInstruction/BillingManageLedgerInstruction.action";

const labelCol = {
  labelCol: { style: { width: 70 } }
}

const inputStyle = {
  width: '70px'
}

const selectStyle = {
  width: '150px'
}
class WS2354001_BillingManageLedgerInstruction extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Li_Year: PropTypes.any,
    Li_Month: PropTypes.any,
    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '請求管理台帳指示';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      ClaimYear: null,
      BillingMonth: null,
      TargetData: 0,
      PrintingCondition: 0,
      PrintingOrder: 0,
      ClaimClassify: 0,
      ContentOutput: 0,
      CsvOutputInfo: 0,
      Preview: 0
    };
    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    this.GetScreenData();
  }

  GetScreenData() {
    const params = {
      Li_MenuOption: this.props.Li_MenuOption || "",
      Li_Year: this.props.Li_Year,
      Li_Month: this.props.Li_Month
    };
    this.setState({ isLoading: true });
    BillingManageLedgerInstruction.GetScreenData(params)
      .then(res => {
        this.setState({
          ClaimYear: res.ClaimYear,
          BillingMonth: res.BillingMonth,
          TargetData: res.TargetData,
          PrintingCondition: res.PrintingCondition,
          PrintingOrder: res.PrintingOrder,
          ClaimClassify: res.ClaimClassify,
          ContentOutput: res.ContentOutput,
          CsvOutputInfo: res.CsvOutputInfo,
          Preview: res.Preview
        });
        this.formRef.current?.setFieldsValue(res);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  Output_F12(params) {
    BillingManageLedgerInstruction.Output_F12(params)
      .then(res => {
        this.CallScreen431();
      })
  }
  CallScreen431() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0431001_CsvPreviewConfirm
            Lio_OutputMethod={this.state.OutputMethod}
            Lio_Output={this.state.CsvOutputInfo}
            Lio_Preview={this.state.Preview}
            onFinishScreen={output => {
              this.setState({
                OutputMethod: output.Lio_OutputMethod,
                CsvOutputInfo: output.Lio_Output,
                childModal: {
                  ...this.state.childModal,
                  visible: false
                }
              });
              let params = {
                OutputMethod: output.Lio_OutputMethod,
                ClaimYear: this.formRef.current?.getFieldValue('ClaimYear'),
                BillingMonth: this.formRef.current?.getFieldValue('BillingMonth'),
                TargetData: this.formRef.current?.getFieldValue('TargetData'),
                PrintingCondition: this.formRef.current?.getFieldValue('PrintingCondition'),
                PrintingOrder: this.formRef.current?.getFieldValue('PrintingOrder'),
                ClaimClassify: this.formRef.current?.getFieldValue('ClaimClassify'),
                Preview: output?.Lio_Preview !== undefined ? output?.Lio_Preview : null,
                ContentOutput: this.formRef.current?.getFieldValue('ContentOutput'),
                CsvOutputInfo: output?.Lio_Output ? output?.Lio_Output : null
              }
              this.OutputFinish(params)
            }}
          />
        )
      }
    });
  }

  OutputFinish(params) {
    this.setState({ isLoading: true });
    BillingManageLedgerInstruction.OutputFinish(params)
      .then(res => {
        download_file(res);
      }).catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  onFinish() {
    this.Output_F12(this.formRef.current?.getFieldValue())
  }

  render() {
    return (
      <div className="billing-manage-ledger-instruction" style={{ width: "600px" }}>
        <Card className="mb-2" title="請求管理台帳指示">
          <Form ref={this.formRef}>
            <div className="box_inner_horizontal">
              <Form.Item name="ClaimYear" label='請求年月' {...labelCol}>
                <Input type="number" style={{ ...inputStyle }} />
              </Form.Item>
              <label style={{ marginTop: '5px' }}>年</label>
              <Form.Item name="BillingMonth" label="">
                <Input type="number" style={{ ...inputStyle }} />
              </Form.Item>
              <label style={{ marginTop: '5px' }}>月</label>
            </div>

            <Form.Item name="TargetData" label="対象ﾃﾞｰﾀ" {...labelCol}>
              <Select style={{ ...selectStyle }}>
                <Select.Option value={0}>有効分</Select.Option>
                <Select.Option value={9}>無効分</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="PrintingCondition" label="抽出条件" {...labelCol}>
              <Select style={{ ...selectStyle }}>
                <Select.Option value={0}>全　て</Select.Option>
                <Select.Option value={8}>未請求</Select.Option>
                <Select.Option value={9}>請求済</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="PrintingOrder" label="出力順" {...labelCol}>
              <Select style={{ ...selectStyle }}>
                <Select.Option value={0}>請求書番号順</Select.Option>
                <Select.Option value={1}>請求日順</Select.Option>
                <Select.Option value={2}>コード順</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="ClaimClassify" label="請求分類" {...labelCol}>
              <Select style={{ ...selectStyle }}>
                <Select.Option value={0}>全　　件</Select.Option>
                <Select.Option value={4}>保 険 者</Select.Option>
                <Select.Option value={5}>事 業 所</Select.Option>
                <Select.Option value={6}>他 団 体</Select.Option>
                <Select.Option value={9}>個人未収</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="ContentOutput" label="内容出力" valuePropName="checked" {...labelCol}>
              <Checkbox />
            </Form.Item>

            <Form.Item style={{ float: "right" }}>
              <Button type="primary" onClick={() => this.Output_F12(this.formRef.current?.getFieldValue())}>
                出力
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          footer={null}
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2354001_BillingManageLedgerInstruction);
