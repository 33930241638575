import React from 'react'
import { connect } from 'react-redux'
import { Card, Table } from 'antd'
import Color from 'constants/Color'
import RadiographyFindingInputAction from 'redux/InputBusiness/RadiographyFindingInput/JudgeSelectQuerySub.action'

class WS1868001_JudgeSelectQuerySub extends React.Component {
  constructor(props) {
    super(props)

    // document.title = '判定選択照会 SUB'

    this.state = {
      dataSource: [],
    }
  }

  /**
   * 初期データ取得
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index() {
    const data = {
      Li_JudgeLevel: this.props.Li_JudgeLevel
    }
    RadiographyFindingInputAction.index(data)
      .then((res) => {
        if (res) {
          this.setState({ dataSource: res })
        }
      })
  }

  render() {
    return (
      <div className='judge-select-query-sub'>
        <Card title='判定選択照会'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({
              onDoubleClick: (e) => {
                // this.setState({ rowSelect: record })
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    Lo_interpretation_judgment_result: record.interpretation_judgment_result,
                    Lo_judgment_name: record.judgment_name,
                  })
                }
              }
            })}
          >
            <Table.Column
              title='判定'
              dataIndex='interpretation_judgment_result'
              render={(value, record, index) => {
                return (
                  <div
                    style={{
                      fontWeight: 'bold',
                      width: '100%',
                      textAlign: 'center',
                      color: Color(record.judgmentColorNum)?.Foreground
                    }}
                  >
                    {value}
                  </div>
                )
              }}
            />
            <Table.Column title='名称' dataIndex='judgment_name' />
          </Table>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1868001_JudgeSelectQuerySub)
