import { message } from 'antd'
import AutoJudgmentManagementService from 'services/SM_SystemMaintenance/AutoJudgmentManagement/AutoJudgmentManagementService.js'

const AutoJudgmentManagement = {
  getAutoJudgmentNameList(data) {
    return AutoJudgmentManagementService.getAutoJudgmentNameList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getJudgmentStorageData(data) {
    return AutoJudgmentManagementService.getJudgmentStorageData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  addNewJudgmentStorageSetting(data) {
    return AutoJudgmentManagementService.addNewJudgmentStorageSetting(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveJudgmentStorageSetting(data) {
    return AutoJudgmentManagementService.saveJudgmentStorageSetting(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteJudgmentStorageSetting(data) {
    return AutoJudgmentManagementService.deleteJudgmentStorageSetting(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  //自動判定名設定関係
  getAutoJudgmentNameData(data) {
    return AutoJudgmentManagementService.getAutoJudgmentNameData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getScreenData(data) {
    return AutoJudgmentManagementService.getScreenData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  addNewJudgmentName(data) {
    return AutoJudgmentManagementService.addNewJudgmentName(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveJudgmentName(data) {
    return AutoJudgmentManagementService.saveJudgmentName(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteAutoJudgmentName(data) {
    return AutoJudgmentManagementService.deleteAutoJudgmentName(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  //自動判定詳細設定
  getJudgmentDetailSettingData(data) {
    return AutoJudgmentManagementService.getJudgmentDetailSettingData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveJudgmentDetailSetting(data) {
    return AutoJudgmentManagementService.saveJudgmentDetailSetting(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default AutoJudgmentManagement
