import React from 'react'
import { connect } from 'react-redux'
import WS0270001_InspectItemSearchQueryMultipleChoice from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0270001_InspectItemSearchQueryMultipleChoice.jsx'
import { Dropdown, message, Card, Table, Form, Row, Col, Space, Input, Button, Modal, InputNumber, Menu } from 'antd'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'
import WS0887001_IntroduceLetterVariousMasterInquiry from '../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import WS0924001_IntroduceLetterTargetCmtList from './WS0924001_IntroduceLetterTargetCmtList'
import WS0920010_CopyingProcess from './WS0920010_CopyingProcess'
import WS0920008_IntroduceLetterTargetCmtchange from './WS0920008_IntroduceLetterTargetCmtchange.jsx'
import ColumnButtonCustom from 'components/Commons/TableColumn'
import IntroduceLetterTargetCmtMaintainAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/IntroduceLetterTargetCmtMaintain.action'
import WS0921003_IntroduceLetterTargetCmtInspectMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0921003_IntroduceLetterTargetCmtInspectMaintain.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { MoreOutlined } from '@ant-design/icons'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'


class WS0920008_IntroduceLetterTargetCmtRight extends React.Component {

  constructor(props) {
    super(props)
    // document.title = '紹介状対象コメント保守'
    this.formRef = React.createRef()
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      FdataSource: [],
      selectedRow: {},
      comment_code: 0,
      addCount: 0,
      disabledSaveBtn: true
    }
  }


  componentDidMount = () => {
    this.setState({
      comment_code: this.props.comment_code
    })
    this.getInspectMaintain()
  }



  getInspectMaintain(data) {
    IntroduceLetterTargetCmtMaintainAction.getInspectMaintain({ comment_code: this.props.comment_code ?? 0 })
      .then((res) => {
        if (res) {
          this.formRef?.current?.setFieldsValue({
            dataSource: res,
          })
          this.setState({
            dataSource: res,
          })
        }
      })
  }


  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name)
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  onSave = () => {
    let params = {
      dataSource: this.state.dataSource,
      comment_code: this.state.comment_code
    }
    IntroduceLetterTargetCmtMaintainAction.saveDataInspect(params)
      .then(res => this.getInspectMaintain())
  }

  deleteMaintain() {
    const params = {
      id: this.state.selectedRowKeys[0],
      comment_code: this.state.rowSelected[0]?.comment_code
    }
    Modal.confirm({
      content: '消去してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => IntroduceLetterTargetCmtMaintainAction.DeleteInspectMaintain(params).then(() => {
        this.getMaintain()
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
    })
  }
  insertInspectData(record) {
    // save data form FE
    const isNew = true
    this.SaveInspectData(record, isNew)
  }
  SaveInspectData(value, isNew) {
    const req = {
      remarks: value.remarks,
      exam_code: value.exam_code,
      department_code: value.department_code,
      comment_code: this.state.rowSelected[0].comment_code
    }
    const params = isNew ? { ...req } : { ...req, id: value.id }
    IntroduceLetterTargetCmtMaintainAction.saveDataInspect(params).then(() => {
      message.success('保存しました')
      this.getInspectMaintain({ comment_code: this.state.rowSelected[0].comment_code })
    }).catch(err => {
      const res = err.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
        return
      }
      message.error(res.data.message)
    })
  }
  onSaveInspect(index = this.state.indexTable2) {
    // click event save data
    const record = this.getRawValue('dataSource2')[index]
    record?.isNew ?
      this.insertInspectData(record) : this.SaveInspectData(record, false)
  }
  deleteInspect() {
    const params = {
      id: this.state.selectedRowKeys2[0],
      exam_code: this.state.rowSelected2[0]?.exam_code,
      department_code: this.state.rowSelected2[0]?.department_code,

    }
    Modal.confirm({
      content: '消去してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => IntroduceLetterTargetCmtMaintainAction.deleteDataInspect(params).then(() => {
        this.getInspectMaintain({ comment_code: this.state.rowSelected[0].comment_code })
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
    })
  }
  renderForm(record) {
    const keys = Object.keys(record)
    const values = Object.values(record)
    keys.forEach((item, i) => {
      if (item == 'regional_insurer_group_classification') {
        this.setFormFieldValue(item, values[i] === 0 ? '' : values[i])
      } else {
        this.setFormFieldValue(item, values[i])
      }
    })
  }
  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }



  add = () => {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.setState({
      dataSource: data,
      addCount: idCount
    })
  }

  /**
   * 削除　
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   * @param {*} id
   */
  delete = (id) => {

    let data = [...this.state.dataSource]

    let filter = data.filter(d=> {return d.id != id})

    this.formRef.current?.setFieldsValue({
      dataSource: filter
    })
    this.setState({
      dataSource: filter,
      disabledSaveBtn: false
    })
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * それぞれの値の変更
   * @param {*} id
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(id, field, value) {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // data[index][field] = value

    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
        console.log(obj)
      }
      tempArr.push(obj)
    })
    console.log(tempArr)

    this.formRef.current?.setFieldsValue({
      dataSource: tempArr
    })
    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
  }
  examDirectInput = (value, record) => {
    IntroduceLetterTargetCmtMaintainAction.getExamInfo({ searchChar: value })
      .then(async (res) => {
        // this.formRef.current?.setFieldsValue({
        //   Expresstion_9: res.data.exam_name
        // })
        // this.updateDatasource(record.id, 'exam_code', res.data.test_item_code)
        await this.updateDatasource(record.id, 'exam_code', res.data.test_item_code)
        this.updateDatasource(record.id, 'Expresstion_9', res.data.exam_name)
      })
  }
  departmentDirectInput = (value, record) => {
    IntroduceLetterTargetCmtMaintainAction.getDepartmentInfo({ various_codes: value })
      .then(async (res) => {
        await this.updateDatasource(record.id, 'department_code', res.data.various_codes)
        this.updateDatasource(record.id, 'department_name', res.data.department_name)
      })
  }






  render() {
    return (
      <div className='WS0920008_IntroduceLetterTargetCmtRight' style={{ width: 1000 }}>
        <Card title='検査設定'>

          <Form
            ref={this.formRef}
          // autoComplete='off'
          >



            <Table bordered
              size='small'
              // loading={this.state.isLoading2}
              scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
              pagination={false}
              // rowClassName={(record, index) => record.id === this.state.rowSelected2[0]?.id ? 'table-row-light' : ''}
              // dataSource={this.getRawValue('dataSource2')}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.setState({
                      rowSelected2: [record],
                      selectedRowKeys2: [record.id],
                      indexTable2: rowIndex
                    })
                  }
                }
              }}
            >
              <Table.Column
                // width={280}
                className='column-size-60'
                title='検査情報'
                dataIndex='exam_code'
                // sorter={(a, b) => a.exam_code - b.exam_code}
                render={(value, record) => {
                  // let index = this.getRawValue('dataSource2').findIndex(el => el.id == record.id)
                  return (
                    <div className='box_inner_horizontal'>
                      <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'exam_code']}>
                        <Input.Search
                          className='input-search-size-4'
                          onPressEnter={(e) => this.examDirectInput(e.target.value, record)}
                          onSearch={(code, event) => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0271001_InspectItemSearchQuerySingle
                                    InspectCode={code}
                                    onFinishScreen={async ({ recordData }) => {
                                      // this.formRef.current?.setFieldsValue({
                                      //   Expresstion_9: recordData.exam_name
                                      // })
                                      // this.updateDatasource(record.id, 'exam_code', recordData.test_item_code)
                                      await this.updateDatasource(record.id, 'exam_code', recordData.test_item_code)
                                      this.updateDatasource(record.id, 'Expresstion_9', recordData.exam_name)
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='Expresstion_9'>
                        <div>{record.Expresstion_9}</div>
                      </Form.Item>

                    </div>
                  )
                }} />
              <Table.Column
                // width={190}
                className='column-size-60'
                title='診療科'
                dataIndex='department_code'
                // sorter={(a, b) => a.department_code - b.department_code}
                render={(value, record) => {
                  return (
                    <div className='box_inner_horizontal'>
                      <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'department_code']}>
                        <Input.Search
                          className='input-search-size-4'
                          onPressEnter={(e) => this.departmentDirectInput(e.target.value, record)}
                          onSearch={(searchChar, event) => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0887001_IntroduceLetterVariousMasterInquiry
                                    Li_ManageCode={7}
                                    Li_SearchChar={searchChar}
                                    onFinishScreen={async ({ recordData }) => {
                                      this.formRef.current?.setFieldsValue({
                                        department_name: recordData.department_name
                                      })
                                      // this.updateDatasource(record.id, 'department_code', recordData.various_codes)
                                      await this.updateDatasource(record.id, 'department_code', recordData.various_codes)
                                      this.updateDatasource(record.id, 'department_name', recordData.department_name)
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='department_name'>
                        <div>{record.department_name}</div>
                      </Form.Item>
                    </div>
                  )
                  // let index = this.getRawValue('dataSource2').findIndex(el => el.id == record.id)
                  // return (
                  //   <Space direction='horizontal'>
                  //     <Form.Item name={['dataSource2', index, 'department_code']} style={{ margin: 0 }}>
                  //       <Input.Search onBlur={() => this.onSaveInspect(index)} type='number' maxLength={4} style={{ width: 120 }} onSearch={() => this.IntroduceLetterVariousMasterIn()} />
                  //     </Form.Item>
                  //     <Form.Item name={['dataSource2', index, 'department_name']} style={{ margin: 0 }}>
                  //       <span style={{ paddingLeft: 5 }}>{record.department_name}</span>
                  //     </Form.Item>
                  //   </Space>
                  // )
                }} />
              <Table.Column width={150} title='備考' dataIndex='remarks'
                render={(value, record) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'remarks']}>
                      <Input onBlur={(e) => this.updateDatasource(record.id, 'remarks', e.target.value)} />
                    </Form.Item>
                  )
                  // let index = this.getRawValue('dataSource2').findIndex(el => el.id == record.id)
                  // return (
                  //   <Form.Item name={['dataSource2', index, 'remarks']}>
                  //     <Input onBlur={() => this.onSaveInspect(index)} />
                  //   </Form.Item>
                  // )
                }} />
              {/* {(ColumnButtonCustom({
                addRow: () => this.addRow('dataSource2', 2), // in FE
                onSaveData: () => this.onSaveInspect(), // Call API
                deleteRow: () => this.removeRow('dataSource2', 2, this.state.indexTable2), // in FE
                deleteData: () => this.deleteInspect(), // Call API
                dataSource: this.getRawValue('dataSource2'),
                tableIndex: this.state.indexTable2,
                width: 130,
                hiddenSave: true
              }))} */}

              <Table.Column
                width={40}
                align='center'
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      onClick={() => this.add()}
                      type='primary'
                      icon={<PlusOutlined />}
                    />
                  </div>
                }
                render={(text, record, index) => {
                  return (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.delete(record.id)
                      }}
                    />
                  )
                }}
              />

            </Table>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.onSave()}
                disabled={this.state.disabledSaveBtn}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0920008_IntroduceLetterTargetCmtRight)
