import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, message } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import CodeSettingAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureDataCreate/CodeSetting.action.js'

// import CodeSettingService from 'services/AssociationHealthInsuranceReport/AssociateInsureDataCreate/CodeSettingService'

const labelStyle = {
  labelCol: { style: { width: 120 } },
}
class WS1220004_CodeSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コード設定'

    this.state = {
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }


  /**
   * 初期処理
   */
  index = () => {
    CodeSettingAction.index()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          BranchCode: res.BranchCode,
          MedicalExamInstitutionCode: res.MedicalExamInstitutionCode,
        })
        this.forceUpdate()
      })
  }

  /**
   * 登録
   */
  updateCodeSetting = () => {
    const {
      BranchCode,
      MedicalExamInstitutionCode
    } = this.formRef.current.getFieldsValue(true)

    let params = {
      ...this.formRef.current.getFieldValue(),
      BranchCode: BranchCode,
      MedicalExamInstitutionCode: MedicalExamInstitutionCode,
    }

    CodeSettingAction.updateCodeSetting(params)
      .then((res) => {
        // 画面閉じる
        this.props.onFinishScreen()
      })
  }

  render() {
    return (
      <div className='code-setting'>
        <Card title='コード設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div>
              <Form.Item
                label='支部コード'
                name='BranchCode'
                {...labelStyle}
              >
                <Input
                  className='input-size-number-4'
                  rules={[{ type: 'number' }]}
                />
              </Form.Item>
              <Form.Item
                label='健診機関コード'
                name='MedicalExamInstitutionCode'
                {...labelStyle}
              >
                <Input
                  className='input-size-number-15'
                  rules={[{ type: 'number' }]}
                />
              </Form.Item>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.updateCodeSetting()
                }}>
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1220004_CodeSetting)
