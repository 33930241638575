import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, message, } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import FollowUpLetterReturnInputAction from 'redux/IntroductionLetter/FollowUpLetter/FollowUpLetterReturnInput.action.js'
import PropTypes from 'prop-types'
import WS0887001_IntroduceLetterVariousMasterInquiry from '../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import ModalDraggable from 'components/Commons/ModalDraggable'

const labelCol = {
  labelCol: { style: { width: 60 } },
}
class FollowUpLetterReturnInputEdit extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_Department: PropTypes.any,
    Li_ReturnInfoDisplay: PropTypes.any,
    onFinishScreen: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '紹介状返送入力（経過手紙）'

    this.state = {
      screenData: {},
      count: 0,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    if (this.props.record) {
      const data = { ...this.props.record }
      // 編集の場合
      this.formRef.current.setFieldsValue({
        seq: data.seq,
        exam_code: data.exam_code,
        exam_content: data.exam_content,
        treatment_code: data.treatment_code,
        treatment_content: data.treatment_content,
        sick_name_code: data.sick_name_code,
        sick_name: data.sick_name,
        findings_code: data.findings_code,
        findings_content: data.findings_content,
      })
    } else {
      // 新規追加の場合
      this.formRef.current.setFieldsValue({
        seq: (this.props.maxSeq + 1)
      })
    }
  }

  /**
   * 保存ボタン処理(詳細データ)
   * @param {*} record
   */
  saveDetail = () => {
    const params = {
      id: this.props.record?.id ?? null,
      visit_date_on: this.props.Li_Date,
      pattern_code: this.props.Li_PatternCode,
      personal_number_id: this.props.Li_PersonalNum,
      reservation_number: this.props.Li_ReserveNum,
      receipt_number: this.props.Li_AcceptNum,
      course_level: this.props.Li_CourseLevel,
      department: this.props.Li_Department,
      seq: this.formRef.current.getFieldValue('seq'),
      exam_code: this.formRef.current.getFieldValue('exam_code'),
      exam_content: this.formRef.current.getFieldValue('exam_content'),
      findings_code: this.formRef.current.getFieldValue('findings_code'),
      findings_content: this.formRef.current.getFieldValue('findings_content'),
      sick_name_code: this.formRef.current.getFieldValue('sick_name_code'),
      sick_name: this.formRef.current.getFieldValue('sick_name'),
      treatment_code: this.formRef.current.getFieldValue('treatment_code'),
      treatment_content: this.formRef.current.getFieldValue('treatment_content'),
    }
    FollowUpLetterReturnInputAction.saveDetail(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
   * 削除ボタンの処理
   * @param {*} record 
   */
  delete = () => {
    const params = {
      id: this.props.record?.id ?? null,
    }
    FollowUpLetterReturnInputAction.delete(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  addNewRowToTable = (arrayName) => {
    let arrayFieldValue = this.formRef.current?.getFieldValue([arrayName])
    let isCreatedNew = arrayFieldValue.filter((item) => {
      if (isNaN(item.id)) {
        return item
      }
    })
    if (isCreatedNew.length > 0) return
    let newData = {
      id: 'new' + this.state.count,
      seq: '',
      exam_code: '',
      exam_content: '',
      findings_code: '',
      findings_content: '',
      sick_name_code: '',
      sick_name: '',
      treatment_code: '',
      treatment_content: '',
    }
    switch (arrayName) {
      case 'dataSource':
        newData = { ...newData }
        break

      default:
        break
    }
    let arrayNew = []
    if (arrayFieldValue) {
      arrayNew = [...arrayFieldValue]
    }
    this.formRef.current?.setFieldsValue({
      [arrayName]: [newData, ...arrayNew],
    })
    this.forceUpdate()
    this.setState({ count: this.state.count + 1 })
  }

  findIndexByID = (arrayName, keyName, record) => {
    let arrayFieldValue = this.formRef.current?.getFieldValue([arrayName])
    return arrayFieldValue.findIndex(
      (item) => record[keyName] === item[keyName]
    )
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }
  setFormFieldValue = (name, value) => {
    this.formRef.current?.setFields([{ name: name, value: value }])
    this.forceUpdate()
  }

  /**
   * 各種照会 モーダル
   * 2 : 検査
   * 3 : 治療
   * 5 : 傷病
   * 8 : 所見
   * @param {*} manageCode
   */
  showWS0887001_IntroduceLetterVariousMasterInquiry = (manageCode) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={manageCode}
            onFinishScreen={(output) => {

              let codeName = null
              let name = null
              switch (manageCode) {
                case 2: //検査
                  codeName = 'exam_code'
                  name = 'exam_content'
                  break
                case 3: //治療
                  codeName = 'treatment_code'
                  name = 'treatment_content'
                  break
                case 5: //傷病
                  codeName = 'sick_name_code'
                  name = 'sick_name'
                  break
                case 8: //所見
                  codeName = 'findings_code'
                  name = 'findings_content'
                  break
                default:
              }
              if (codeName) {
                this.formRef.current?.setFieldsValue({
                  [codeName]: output.variousCodes,
                  [name]: output.recordData[name],
                })
              }
              this.forceUpdate()
              this.closeModal()
            }
            }
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-return-input'>
        <Card
          title={`紹介状返送入力[${(this.props.record) ? '変更' : '新規追加'}]`}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              label='連番'
              name='seq'
              {...labelCol}
            >
              <Input
                className='input-size-number-4'
                type='number' />
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='検査'
                name='exam_code'
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-8'
                  onSearch={() => {
                    // 2: 検査 モーダル
                    this.showWS0887001_IntroduceLetterVariousMasterInquiry(2)
                  }}
                />
              </Form.Item>
              <Form.Item name='exam_content'>
                <Input className='input-size-60' />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal'>
              <Form.Item
                label='所見'
                name='findings_code'
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-8'
                  onSearch={() => {
                    // 8: 所見 モーダル
                    this.showWS0887001_IntroduceLetterVariousMasterInquiry(8)
                  }}
                />
              </Form.Item>
              <Form.Item name='findings_content'>
                <Input className='input-size-60' />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal'>

              <Form.Item
                label='傷病'
                name='sick_name_code'
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-8'
                  onSearch={() => {
                    // 5: 傷病 モーダル
                    this.showWS0887001_IntroduceLetterVariousMasterInquiry(5)
                  }}
                />
              </Form.Item>

              <Form.Item name='sick_name'>
                <Input className='input-size-60' />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal'>


              <Form.Item
                label='治療'
                name='treatment_code'
                {...labelCol}
              >
                <Input.Search
                  className='input-search-size-number-8'
                  onSearch={() => {
                    // 3: 治療 モーダル
                    this.showWS0887001_IntroduceLetterVariousMasterInquiry(3)
                  }}
                />
              </Form.Item>

              <Form.Item name='treatment_content'>
                <Input className='input-size-60' />
              </Form.Item>
            </div>

          </Form>

          {/* Footer */}
          <div className='box_button_bottom_right'>

            <Button
              type='primary'
              danger
              icon={<DeleteOutlined />}
              hidden={!(this.props.record)}
              onClick={() => {
                Modal.confirm({
                  content: '削除しますか？',
                  okText: '削除',
                  cancelText: 'キャンセル',
                  onOk: () => this.delete(),
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() =>
                this.saveDetail()
              }
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>

          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpLetterReturnInputEdit)
