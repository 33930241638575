import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/overall-result-display-input/guide-matter-content',
  changeAutomaticJudgeBasicJudge: '/api/not-input-check-category/overall-result-display-input/guide-matter-content/change-automatic-judge-basic-judge',
  save: '/api/not-input-check-category/overall-result-display-input/guide-matter-content/save',
  delete: '/api/not-input-check-category/overall-result-display-input/guide-matter-content/delete',
}

const GuideMatterContentService = {
  async index(params) {
    return axios.post(apiPaths.index, params)
  },
  async changeAutomaticJudgeBasicJudge(params) {
    return axios.get(apiPaths.changeAutomaticJudgeBasicJudge, { params })
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },
}

export default GuideMatterContentService
