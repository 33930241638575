import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Row,
  Col,
  Input,
  Form,
  Card,
  Button,
  Table,
  Select,
  Tabs,
  Modal,
  Spin,
  message,
  Checkbox,
  Space,
  Tag,
  Tooltip,
  Dropdown,
  Menu,
  Typography
} from "antd";
import { debounce } from "lodash";

import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx";
import WS0247001_OfficeInfoRetrievalQuery from "./WS0247001_OfficeInfoRetrievalQuery.jsx";
import WS0341008_BranchShopInquiry from "./WS0341008_BranchShopInquiry.jsx";
import WS0341009_BranchStoreGenerated from "./WS0341009_BranchStoreGenerated.jsx";
import WS0308017_OrganizationCreateNew from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0308017_OrganizationCreateNew.jsx";
import WS0265001_BasicCourseInquiry from "../V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS2584019_PersonalInfoInquirySub from "../../KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";
import WS0246001_InsurerInfoSearchQuery from "../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0307008_ContractHistorySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx";
import { history } from "constants/BrowserHistory";
import {
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  MenuOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import OfficeInfoMaintainService from "services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js";
import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import PropTypes from "prop-types";
import WS0344001_SupplementalInfoSetting from "../V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx";
import Color from "constants/Color.js";
import * as wanakana from "wanakana";

const ModalEdit = (props) => {
  const formRef = React.createRef()
  const [itemChange, setItemChange] = React.useState([])
  const [disabledSaveBtn, setDisabledSaveBtn] = React.useState(true)
  /**
   * Modalを開いた時の処理
   */
  React.useEffect(() => {
    formRef.current?.setFieldsValue(props.record)
  }, [props.record])

  /**
   * 入力　変更処理
   * @param {*} e
   */
  const handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]
    setItemChange({
      ...itemChange,
      [columnName]: columnValue
    })
    // 登録ボタン 活性
    setDisabledSaveBtn(false)
  }

  return (
    <Card title={props.newFlag ? '新規' : '変更'}>
      <Form
        ref={formRef}
        onValuesChange={handleFormChange}
        autoComplete='off'
      >
        <label>重要度</label>
        <Form.Item name='WKV40MS0420_importance'>
          <Select
            style={{ width: '100px' }}
            onChange={() => { setDisabledSaveBtn(false) }}
          >
            <Select.Option key='info' value={1}>
              情報
            </Select.Option>
            <Select.Option key='warning' value={3}>
              警告
            </Select.Option>
            <Select.Option key='significant' value={5}>
              重要
            </Select.Option>
          </Select>
        </Form.Item>
        <label>内容</label>
        <Form.Item name='WKV40MS0420_content'>
          <Input />
        </Form.Item>
      </Form>
      <div style={{ display: 'flex', paddingTop: '10px', gap: '10px', justifyContent: 'center' }}>
        <Button
          danger
          type='primary'
          style={{ display: (props.newFlag) ? 'none' : '' }}
          icon={<DeleteOutlined />}
          onClick={() => {
            // 親画面に反映する
            props.onDelete(props.record.id)

          }}
        >
          <span className='btn_label'>
            削除
          </span>
        </Button>
        {/* 登録ボタン */}
        <Button
          type='primary'
          icon={<SaveOutlined />}
          disabled={disabledSaveBtn}
          onClick={() => {
            // 親画面に反映
            props.onUpdate(formRef.current?.getFieldsValue())
          }}
        >
          <span className='btn_label'>
            登録
          </span>
        </Button>
      </div>
    </Card>
  )
}

const selectList = [
  { value: 0, label: '' },
  { value: 1, label: '情報' },
  { value: 3, label: '警告' },
  { value: 5, label: '重要' }
]
class WS0341501_SpecialInfo extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      RecordedInfo: this.props.Li_RecordedInfo
    })
    this.setState({
      RecordedInfo: this.props.Li_RecordedInfo,
      OfficeCode: this.props.Li_OfficeCode,
      BranchStoreCode: this.props.Li_BranchStoreCode
    })
  }

  addNewRowToTable = arrayName => {
    // let arrayFieldValue = this.formRef.current?.getFieldValue([arrayName]);
    let arrayFieldValue = this.state.RecordedInfo;
    let newData;
    switch (arrayName) {
      case "RecordedInfo":
        newData = {
          id: "",
          WKV40MS0420_importance: "",
          WKV40MS0420_content: ""
        };
        break;
      case "ResultsTable":
        newData = {
          id: "",
          medical_exam_course: "",
          standard_printing_style_1: "",
          format_name_1: "",
          standard_printing_style_2: "",
          format_name_2: ""
        };
        break;
      case "AffiliationInfo":
        newData = {
          id: "",
          affiliation_cd: "",
          short_name_name: "",
          formal_name: "",
          in_force: 1
        };
        break;

      default:
        break;
    }

    let arrayNew = [];
    if (arrayFieldValue) {
      arrayNew = [...arrayFieldValue];
    }
    arrayNew.push(newData);
    this.formRef.current?.setFieldsValue({ RecordedInfo: arrayNew });
    this.forceUpdate();
    this.setState({
      RecordedInfo: arrayNew,
      count: this.state.count + 1,
      isFileEdited: true
    });
  };

  findIndexByID = (arrayData, recordID) => {
    if (arrayData && arrayData.length > 0) {
      return arrayData.findIndex(item => recordID === item.id);
    }
  };

  saveData = (record, data) => {
    const props = {
      id: data?.id ?? "",
      OfficeCode: this.state.OfficeCode,
      BranchStoreCode: this.state.BranchStoreCode,
      W1_importance: record.WKV40MS0420_importance,
      W1_content: record.WKV40MS0420_content,
      recording_date_on: this.props.Li_Date,
      millisecond: data?.millisecond ?? '',
      message: "事業所名(ｶﾅ) 必須入力"
    };
    OfficeInfoMaintainService.updateAddRecordedInfoService(props)
      .then(res => {
        message.success("成功");
        this.getAddRecordedInfo();
      })
      .catch(err => message.error("エラー"));
    this.closeModal()
  };

  deleteRecordTable = (record, arrayName) => {
    OfficeInfoMaintainService.deleteAddRecordedInfoService({
      id: record
    })
      .then(res => {
        message.success("成功");
        this.getAddRecordedInfo();
        this.setState({
          isFileEdited: true
        });
      })
      .catch(err => message.error("エラー"));
    let arr = [...this.formRef.current?.getFieldValue([arrayName])];
    arr.map((value, index) => {
      if (value.id === record.id) {
        arr.splice(index, 1);
        this.formRef.current?.setFieldsValue({
          [arrayName]: arr
        });
        this.setState({ isFileEdited: true });
      }
    });
  };

  getAddRecordedInfo() {
    const data = {
      OfficeCode: this.state.OfficeCode,
      BranchStoreCode: this.state.BranchStoreCode
    };
    OfficeInfoMaintainService.getAddRecordedInfoService(data)
      .then(res => {
        this.setState({
          RecordedInfo: res.data.RecordedInfo,
          selectedRowsRecordedInfo: [res.data.RecordedInfo[0]],
          indexTableRecordedInfo: 0
        });
        this.formRef.current.setFieldsValue({
          RecordedInfo: res.data.RecordedInfo
        });
        this.forceUpdate()

        this.props.onUpdate(res.data.RecordedInfo)
      })
      .catch(err => message.error("エラー"));
    this.closeModal()
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  render() {
    return (
      <div className="special-info">
        <Card title='特記'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
          >
            <Table
              dataSource={this.state.RecordedInfo}
              rowKey={record => record.id}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRowsRecordedInfo[0]?.id
                  ? "table-row-light"
                  : ""
              }
              onRow={(record, index) => ({
                onClick: e => {
                  this.setState({
                    rowSelectRecordedInfo: record,
                    indexTableRecordedInfo: index,
                    selectedRowsRecordedInfo: [record]
                  });
                }
              })}
              pagination={false}
              size="small"
              bordered
            >
              <Table.Column
                width={90}
                title="重要度"
                dataIndex="WKV40MS0420_importance"
                className="column-size-10"
                render={(text, record, index) => {
                  let findItem = selectList.find(v => v.value === record.WKV40MS0420_importance)
                  return findItem.label
                  // <>
                  //   <Form.Item name={["RecordedInfo", index, "id"]} hidden={true}>
                  //   <Input value={record.id || null} />
                  // </Form.Item>
                  //   <Form.Item
                  //     name={["RecordedInfo", index, "WKV40MS0420_importance"]}
                  //     required={true}
                  //     style={{ marginBottom: "0px" }}
                  //   >
                  //     <Select onChange={() => this.forceUpdate()}>
                  //       <Select.Option value={0}>　　</Select.Option>
                  //       <Select.Option value={1}>情報</Select.Option>
                  //       <Select.Option value={3}>警告</Select.Option>
                  //       <Select.Option value={5}>重要</Select.Option>
                  //     </Select>
                  //   </Form.Item>
                  // </>
                }}
              />
              <Table.Column
                dataIndex="WKV40MS0420_importance"
                className="column-size-2"
                align="center"
                key="icon"
                render={code => {
                  let icon = "";
                  switch (code) {
                    case 1:
                      icon = (
                        <InfoCircleOutlined
                          style={{
                            color: "#1890ff"
                          }}
                        />
                      );
                      break;
                    case 3:
                      icon = <WarningOutlined style={{ color: "#faad14" }} />;
                      break;
                    case 5:
                      icon = <CloseCircleOutlined style={{ color: "#ff4d4f" }} />;
                      break;
                    default:
                      icon = <MoreOutlined />;
                  }
                  return (
                    <Form.Item style={{ fontSize: 18, marginBottom: "0px" }}>
                      {icon}
                    </Form.Item>
                  );
                }}
              />
              <Table.Column
                title="内容"
                dataIndex="WKV40MS0420_content"
              />
              <Table.Column
                title="ユーザー"
                dataIndex="user_name"
                className="column-size-10"
              />
              <Table.Column
                width={40}
                key="action"
                className="column-size-2"
                align="center"
                title={() => (
                  <Button
                    // style={{ width: "35px" }}
                    size='small'
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      // this.addNewRowToTable("RecordedInfo");
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <ModalEdit
                              newFlag={true}
                              // columns={columns}
                              onUpdate={(output) => {
                                if (!(output.WKV40MS0420_importance ?? false)) {
                                  message.error('重要度が設定されていません')
                                  return
                                }

                                Modal.confirm({
                                  content: '新規登録します。よろしいですか？',
                                  okText: 'は　い',
                                  cancelText: 'いいえ',
                                  onOk: () => {
                                    // 変更内容を反映
                                    this.saveData(output)
                                  }
                                })
                              }}
                            />
                          )
                        }
                      })

                    }}
                  />
                )}
                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        // 編集、削除
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 500,
                            className: '',
                            component:
                              <ModalEdit
                                newFlag={false}
                                record={record}
                                onUpdate={(output) => {
                                  Modal.confirm({
                                    content: '上書き登録します。よろしいですか？',
                                    okText: 'は　い',
                                    cancelText: 'いいえ',
                                    onOk: () =>
                                      // 保存ボタン押下、変更内容を反映
                                      this.saveData(output, record)
                                  })
                                }}
                                onDelete={(output) => {
                                  Modal.confirm({
                                    title: "確認",
                                    icon: <QuestionCircleOutlined style={{ color: "#1890ff" }} />,
                                    content: "削除しますか",
                                    okText: "削除",
                                    cancelText: "キャンセル",
                                    onOk: () => {
                                      this.deleteRecordTable(output, "RecordedInfo");
                                    }
                                  });
                                }}
                              />
                          },
                        })
                      }}
                    />
                  );
                }}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    )
  }
}
export default WS0341501_SpecialInfo