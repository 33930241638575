import React from "react";
import PropTypes from 'prop-types';
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import { Card, Form, Table, Button, Tooltip, Input, Row, Col, InputNumber } from "antd";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { number_format } from "helpers/CommonHelpers";

import WS0309502_BillingTypeSelect from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309502_BillingTypeSelect.jsx'

import ModalDraggable from "components/Commons/ModalDraggable";
import BillingAddressSettingAction from "redux/basicInfo/ContractInfoMaintain/BillingAddressSetting.actions.js"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import Images from 'constants/Images';

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderLeft: 0,
  borderBottom: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const columnTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  margin: 0,
  textAlign: 'right',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}

class WS0309500_BillingAddressSetting extends React.Component {
  formRef = React.createRef();
  refCicContract = React.createRef();
  refCicOption = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "契約作成";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      isLoadingContractData: false,

      dataSource: [],
      columnList: [],
      contractTermData: {},
      itemList: [],
      contract_id: 0,
      course_start_date_on: '',
      course_end_date_on: '',
      anotherDataSource: []
    };
  }

  componentDidMount = () => {
    console.log(this.props);
    this.formRef.current?.setFieldsValue({
      LimitsInsurer: this.props.LimitsInsurer,
      LimitsOffice: this.props.LimitsOffice,
      LimitsOtherGroup: this.props.LimitsOtherGroup,
      LimitsPersonal1: this.props.LimitsPersonal1,
      LimitsPersonal2: this.props.LimitsPersonal2,
      LimitsPersonal3: this.props.LimitsPersonal3
    })
    this.setState({
      contractTermData: this.props.contractTermData,
      contract_id: this.props.contractTermData.id,
      course_start_date_on: this.props.course_start_date_on,
      course_end_date_on: this.props.course_end_date_on
    })
    this.getTotalLimits()
    this.loadTableData()
  }

  loadTableData = () => {
    let params = {
      contract_type: this.props.contractTermData.contract_type,
      contract_office_code: this.props.contractTermData.contract_office_code,
      contract_start_date_on: this.props.contractTermData.contract_start_date_on,
      contract_number: this.props.contractTermData.contract_number,
      medical_exam_course: this.props.contractTermData.medical_exam_course,
      contract_id: this.props.contractTermData.id
    }
    BillingAddressSettingAction.loadTableData(params)
      .then((res) => {
        let newItemList = []
        res.tableData.map((values) => {
          newItemList.push({
            set_code: values.set_code,
            data_division: values.data_division
          })
        })

        this.formRef.current?.setFieldsValue({
          anotherDataSource: res.anotherTableData
        })

        this.setState({
          columnList: res.columnList,
          dataSource: res.tableData,
          itemList: newItemList,
          anotherDataSource: res.anotherTableData
        })
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  addBillingAddress = (output) => {
    let recordData = output ? output.recordData : '';
    let billingType = output ? output.billingType : '';
    let serial_number = output ? output.serial_number : '';

    let params = {
      contract_id: this.state.contract_id,
      itemList: this.state.itemList,
      billing_type: billingType,
      office_code: recordData ? recordData.office_code ?? '' : '',
      insurer_code: recordData ? recordData.insurer_code ?? '' : '',
      branch_store_code: recordData ? recordData.branch_store_code : '',
      serial_number: serial_number ?? ''
    }

    BillingAddressSettingAction.addBillingAddress(params)
      .then((res) => { this.loadTableData() })

  }

  deleteColumn = (values) => {
    let params = {
      contract_type: this.state.contractTermData.contract_type,
      contract_office_code: this.state.contractTermData.contract_office_code,
      contract_start_date_on: this.state.contractTermData.contract_start_date_on,
      contract_number: this.state.contractTermData.contract_number,
      contract_id: this.state.contract_id,
      serial_number: values.serial_number,
      itemList: this.state.itemList,
      course_start_date_on: this.state.course_start_date_on,
      course_end_date_on: this.state.course_end_date_on
    }

    BillingAddressSettingAction.deleteAddress(params)
      .then((res) => { this.loadTableData() })
  }

  billingEditing = (totalPrice, index) => {
    let taxRate = this.props.taxRate + 1
    let taxValue, unitPrice

    switch (this.props.rounding) {
      case 0:
        taxValue = Math.round(totalPrice - totalPrice / taxRate)
        break;
      case 1:
        taxValue = Math.floor(totalPrice - totalPrice / taxRate)
        break;
      case 2:
        taxValue = Math.ceil(totalPrice - totalPrice / taxRate)
        break;
    }
    unitPrice = totalPrice - taxValue

    let newDataList = []
    let tempDataList = [...this.state.anotherDataSource]

    for (let i = 0; i < tempDataList.length; i++) {
      let element = { ...tempDataList[i] }
      if (index === i) {
        element.unit_price = unitPrice
        element.tax = taxValue
        element.total_price = totalPrice
      }
      newDataList.push(element)
    }

    this.setState({
      anotherDataSource: newDataList
    })

    this.formRef.current?.setFieldsValue({
      anotherDataSource: newDataList
    })
  }

  onSaveBilling = () => {
    let params = {
      contract_id: this.state.contract_id,
      dataList: this.state.anotherDataSource,
      contract_type: this.state.contractTermData.contract_type,
      contract_office_code: this.state.contractTermData.contract_office_code,
      contract_start_date_on: this.state.contractTermData.contract_start_date_on,
      contract_number: this.state.contractTermData.contract_number,
      course_start_date_on: this.state.course_start_date_on,
      course_end_date_on: this.state.course_end_date_on,
      LimitsInsurer: this.formRef.current?.getFieldValue('LimitsInsurer') ?? 0,
      LimitsOffice: this.formRef.current?.getFieldValue('LimitsOffice') ?? 0,
      LimitsOtherGroup: this.formRef.current?.getFieldValue('LimitsOtherGroup') ?? 0,
      LimitsPersonal1: this.formRef.current?.getFieldValue('LimitsPersonal1') ?? 0,
      LimitsPersonal2: this.formRef.current?.getFieldValue('LimitsPersonal2') ?? 0,
      LimitsPersonal3: this.formRef.current?.getFieldValue('LimitsPersonal3') ?? 0
    }

    BillingAddressSettingAction.saveBillingAmount(params)
      .then((res) => {
        console.log(res.data)
        if (this.props.onSave) {
          this.props.onSave()
        }
      })
  }

  getTotalLimits() {
    let sum = (
      (number_format(
        (this.formRef.current?.getFieldValue('LimitsInsurer') ?? 0) +
        (this.formRef.current?.getFieldValue('LimitsOffice') ?? 0) +
        (this.formRef.current?.getFieldValue('LimitsOtherGroup') ?? 0) +
        (this.formRef.current?.getFieldValue('LimitsPersonal1') ?? 0) +
        (this.formRef.current?.getFieldValue('LimitsPersonal2') ?? 0) +
        (this.formRef.current?.getFieldValue('LimitsPersonal3') ?? 0)
      ))
    )
    // return sum == 0 ? null : sum

    this.formRef.current?.setFieldsValue({
      totalLimit: sum == 0 ? null : sum
    })
    this.forceUpdate()
  }

  render() {
    const { objContract } = this.state;
    const colorExpression_42 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_42 ? '#FF0000' : '#1c66b9' };
    const colorExpression_43 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_43 ? '#FF0000' : '#1c66b9' };
    const colorExpression_44 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_44 ? '#FF0000' : '#1c66b9' };
    const colorExpression_45 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_45 ? '#FF0000' : '#1c66b9' };
    const colorExpression_46 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_46 ? '#FF0000' : '#1c66b9' };
    const colorExpression_47 = { ...styleDivTitle, backgroundColor: objContract?.Subtask1?.Expression_47 ? '#FF0000' : '#1c66b9' };
    return (
      <div className="billing-address-setting">
        <Card title='請求先設定'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className="box_container">
              <Table
                size='small'
                dataSource={this.state.dataSource}
                rowKey={record => record.id}
                pagination={false}
                bordered
                scroll={{ y: resizableTableScroll(150), x: 'max-content' }}
              >
                <Table.Column
                  title='名称'
                  dataIndex='name'
                  className="column-size-40"
                  render={(value, record) => {
                    return (
                      <div className="box_inner_horizontal">
                        <div><img src={Images(record.data_division == 20 ? 10 : record.data_division)} /></div>
                        <div>{value}</div>
                      </div>
                    )
                  }}
                />
                {
                  this.state.columnList.map((value, index) => {
                    let bill = 'bill_' + (index + 1)
                    return (
                      <Table.Column
                        title={() => {
                          return (
                            <Tooltip title={value.full_name}>
                              <div className="box_inner_horizontal">
                                <div>{value.kanji_name.substr(0, 7)}</div>
                                <Button
                                  icon={<CloseOutlined />}
                                  size='small'
                                  style={{ border: 'none', marginLeft: 'auto' }}
                                  onClick={() => this.deleteColumn(value)}
                                />
                              </div>
                            </Tooltip>
                          )
                        }}
                        key={index}
                        width='140px'
                        render={(item, record, index) => {
                          let newIndex = null
                          this.state.anotherDataSource.map((element, index) => {
                            if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                              newIndex = index
                            }
                          })
                          return (
                            <Form.Item name={['anotherDataSource', newIndex, 'total_price']}>
                              <Input
                                style={{ textAlign: 'right' }}
                                onBlur={(e) => { this.billingEditing(e.target.value, newIndex) }}
                              />
                            </Form.Item>
                            // <div
                            //   style={{ textAlign: 'right' }}
                            //   onDoubleClick={() => {
                            //     this.setState({
                            //       childModal: {
                            //         ...this.state.childModal,
                            //         visible: true,
                            //         width: 400,
                            //         component: (
                            //           <WS0309501_BillingEditing
                            //             values={record[bill]}
                            //             examName={record.name}
                            //             contract_id={this.state.contract_id}
                            //             contract_type={this.state.contractTermData.contract_type}
                            //             contract_office_code={this.state.contractTermData.contract_office_code}
                            //             contract_start_date_on={this.state.contractTermData.contract_start_date_on}
                            //             contract_number={this.state.contractTermData.contract_number}
                            //             medical_exam_course={this.state.contractTermData.medical_exam_course}
                            //             data_division={record.data_division}
                            //             rounding={this.props.rounding}
                            //             taxRate={this.props.taxRate}
                            //             set_code={record.set_code}
                            //             course_start_date_on={this.state.course_start_date_on}
                            //             course_end_date_on={this.state.course_end_date_on}
                            //             onFinishScreen={() => {
                            //               this.loadTableData()
                            //               this.closeModal()
                            //             }}
                            //           />
                            //         ),
                            //       },
                            //     });
                            //   }}
                            // >
                            //   {record[bill].total_price ?? 0}
                            // </div>
                          )
                        }}
                      />
                    )
                  })
                }
                <Table.Column
                  key='action'
                  fixed='right'
                  align='center'
                  className="column-size-2"
                  title={(
                    <Button type="primary" size="small" title="新規" icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => {
                      this.setState({
                        ...this.state,
                        childModal: {
                          width: 300,
                          visible: true,
                          component: (
                            <WS0309502_BillingTypeSelect
                              contractTermData={this.state.contractTermData}
                              columnList={this.state.columnList}
                              itemList={this.state.itemList}

                              onAddBillingAddress={(output) => {
                                this.addBillingAddress(output)
                                this.closeModal()
                              }}

                            />
                          )
                        }
                      });
                    }} />
                  )}
                />
              </Table>
              <Row style={{ marginTop: '10px' }}>
                <Col span={3}>
                  <div style={columnTitle}>金額一覧</div>
                  <div style={columnTitle}>限度金額</div>
                </Col>
                <Col span={3}>
                  <div style={{ ...styleDivTitle, borderLeft: '1px solid #FFFFFF' }}>保険者</div>
                  <Form.Item name="LimitsInsurer" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                    <InputNumber bordered={false} onBlur={() => this.getTotalLimits()} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div style={colorExpression_42}>事業所</div>
                  <Form.Item name="LimitsOffice" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                    <InputNumber bordered={false} onBlur={() => this.getTotalLimits()} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div style={colorExpression_43}>他団体</div>
                  <Form.Item name="LimitsOtherGroup" style={{ ...styleFormItemBottom }}>
                    <InputNumber bordered={false} onBlur={() => this.getTotalLimits()} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div style={colorExpression_44}>個人１</div>
                  <Form.Item name="LimitsPersonal1" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                    <InputNumber bordered={false} onBlur={() => this.getTotalLimits()} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div style={colorExpression_45}>個人２</div>
                  <Form.Item name="LimitsPersonal2" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                    <InputNumber bordered={false} onBlur={() => this.getTotalLimits()} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div style={colorExpression_46}>個人３</div>
                  <Form.Item name="LimitsPersonal3" style={{ ...styleFormItemBottom, padding: '0 3px' }}>
                    <InputNumber bordered={false} onBlur={() => this.getTotalLimits()} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <div style={{ ...colorExpression_47, borderRight: 0 }}>合計</div>
                  <Form.Item name='totalLimit' style={styleFormItemBottom}>
                    {/* <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getTotalLimits()}</div> */}
                    <div>{this.formRef.current?.getFieldValue('totalLimit')}</div>
                  </Form.Item>
                </Col>
              </Row>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  onClick={() => this.onSaveBilling()}
                >
                  <span className="btn_label">
                    確定
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }

}
export default WS0309500_BillingAddressSetting;
