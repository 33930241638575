import axios from 'configs/axios'

const API_LIST = {
  index: '/api/personal-reserve-process/personal-office-search-query',
}

const PersonalOfficeSearchQueryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default PersonalOfficeSearchQueryService
