import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { SearchOutlined, MoreOutlined } from "@ant-design/icons";

import { Card, Form, Input, Select, Button, Table, Row, Col, Space, TimePicker, } from "antd";
import TextArea from "antd/lib/input/TextArea";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS0262064_CharacterSearch from "./WS0262064_CharacterSearch";
import WS0262068_UserChoice from "./WS0262068_UserChoice";

const style = {
  grid: {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  },
  gridSM: {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  },
  formBtm: {
    marginBottom: 0
  },
  inputRead: {
    border: 'none',
    background: 'transparent'
  }
}

class WS0262001_LogDisplay extends React.Component {
  formSearch = React.createRef();
  formTable = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'ログ照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoadingTable: false
    };
  }

  componentDidMount = () => {
    this.callAPILoadData({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.callAPILoadData({});
    }
  }

  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API000262001001", params)
      .then((res) => {
        const data = {
          ...res,
          RecordingDateFChar: res.RecordingDateFChar == '0000/00/00' ? null : res.RecordingDateFChar,
          RecordingDateTChar: res.RecordingDateTChar == '0000/00/00' ? null : res.RecordingDateTChar,
          DateF: res.DateF == '0000/00/00' ? null : res.DateF,
          DateT: res.DateT == '0000/00/00' ? null : res.DateT,
        }
        this.formSearch.current?.setFieldsValue(data)
      }).finally(() => this.setState({ isLoading: false }))
  }

  onFinish() {
    let params = this.formSearch.current?.getFieldsValue()

    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API000262001002", params)
      .then((res) => {
        this.setState({ dataSource: res })
      }).finally(() => this.setState({ isLoading: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  render() {
    return (
      <div className="log-display">
        <Card className="mb-2" title="ログ照会">
          <Space className="mb-4">
            <Button type="primary" onClick={() => {}}>CSV取込</Button>
            <Button type="primary" onClick={() => {}}>CSV出力</Button>
            <Button type="primary"
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: '30%',
                    component: (<WS0262064_CharacterSearch
                      onFinishScreen={(output) => {
                        this.closeModal()
                      }}
                    />)
                  }
                })
              }}
            >文字検索</Button>
          </Space>
          <Form
            ref={this.formSearch}
            onFinish={this.onFinish}
          >
            <Row gutter={24}>
              <Col span={7} style={{ padding: '10px', borderRight: '1px solid #9dc2e8' }}>
                <Row align="middle" style={{ marginBottom: '8px' }}>
                  <Col span={4} align="center">記録日</Col>
                  <Col span={9}>
                    <Form.Item name="RecordingDateFChar" style={style.formBtm}>
                      <VenusDatePickerCustom formRefDatePicker={this.formSearch} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center">~</Col>
                  <Col span={9}>
                    <Form.Item name="RecordingDateTChar" style={style.formBtm}>
                      <VenusDatePickerCustom formRefDatePicker={this.formSearch} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: '8px' }}>
                  <Col span={4} align="center">時　間</Col>
                  <Col span={9}>
                    <Form.Item name="RecordingTimeF" style={style.formBtm}>
                      <TimePicker />
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center">~</Col>
                  <Col span={9}>
                    <Form.Item name="RecordingTimeT" style={style.formBtm}>
                      <TimePicker />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col span={4} align="center">検　索</Col>
                  <Col span={20}>
                    <Form.Item name="Searches" style={style.formBtm}>
                      <Input type='text' />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={7} style={{ padding: '10px', borderRight: '1px solid #9dc2e8' }}>
                <Row align="middle" style={{ marginBottom: '8px' }}>
                  <Col span={4} align="center">受診日</Col>
                  <Col span={9}>
                    <Form.Item name="DateF" style={style.formBtm}>
                      <VenusDatePickerCustom formRefDatePicker={this.formSearch} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center">~</Col>
                  <Col span={9}>
                    <Form.Item name="DateT" style={style.formBtm}>
                      <VenusDatePickerCustom formRefDatePicker={this.formSearch} format="YYYY/MM/DD" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: '8px' }}>
                  <Col span={4} align="center">個人番号</Col>
                  <Col span={9}>
                    <Form.Item name="PersonalNum" style={style.formBtm}>
                      <Input type='text' />
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center"></Col>
                  <Col span={9}>
                    <Form.Item name="kanji_name" style={style.formBtm}>
                      <Input type='text' readOnly style={style.inputRead} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col span={4} align="center">予約番号</Col>
                  <Col span={20}>
                    <Form.Item name="ReserveNum" style={style.formBtm}>
                      <Input type='text' style={{ width: '170px' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={7} style={{ padding: '10px' }}>
                <Row align="middle" style={{ marginBottom: '8px' }}>
                  <Col span={4} align="center">公開名</Col>
                  <Col span={9}>
                    <Form.Item name="Programid" style={style.formBtm}>
                      <Input type='text' />
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center"></Col>
                  <Col span={9}>
                    <Form.Item name="description" style={style.formBtm}>
                      <Input type='text' readOnly style={style.inputRead} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: '8px' }}>
                  <Col span={4} align="center">ユーザー</Col>
                  <Col span={9}>
                    <Form.Item name="User" style={style.formBtm}>
                      <Input type='text'
                        onDoubleClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '30%',
                              component: (<WS0262068_UserChoice
                                onFinishScreen={(output) => {
                                  console.log(output)
                                  this.formSearch.current?.setFieldsValue({ user_name: output })
                                  this.closeModal()
                                }}
                              />)
                            }
                          })
                      }} />
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center"></Col>
                  <Col span={9}>
                    <Form.Item name="user_name" style={style.formBtm}>
                      <Input type='text' readOnly style={style.inputRead} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col span={4} align="center">ログ種別</Col>
                  <Col span={20}>
                    <Form.Item name="LogType" style={style.formBtm}>
                      <Select style={{ width: '50%' }}>
                        <Select.Option value={0}>通常</Select.Option>
                        <Select.Option value={1}>検査取込</Select.Option>
                        <Select.Option value={2}>全て</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={22} style={{ textAlign: 'right' }}>
                <Form.Item>
                  <Button type="primary" icon={<SearchOutlined />} onClick={() => this.onFinish()} >　検　　索</Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>

          <Row gutter={24}>
            <Col span={14}>
              <Card>
                <Table bordered
                  loading={this.state.isLoadingTable}
                  dataSource={this.state.dataSource}
                  pagination={true}
                  rowKey={record => record.id}>
                  <Table.Column title="Lv" dataIndex="Expression_7" />
                  <Table.Column title="日付" dataIndex="recording_date" />
                  <Table.Column title="時間" dataIndex="RecordingTime" />
                  <Table.Column title="ユーザー" dataIndex="user_name" />
                  <Table.Column title="カテゴリ" dataIndex="Expression_27" />
                  <Table.Column title="発生プログラム" dataIndex="Expression_24" />
                  <Table.Column title="内容" dataIndex="title" />
                  <Table.Column title="受診日" dataIndex="consult_date" />
                  <Table.Column title="個人番号" dataIndex="person_num" />
                </Table>
              </Card>
            </Col>
            <Col span={10}>
              <Card>
                <Form ref={this.formTable} >
                  <div style={{ padding: '10px', border: '1px solid #9dc2e8', marginBottom: '10px' }}>
                    <Form.Item name="Expression_27" label="カテゴリ" {...style.gridSM}>
                      <Input type='text' style={{ width: '70%' }} />
                    </Form.Item>
                    <Form.Item name="Expression_22" label="起動プログラム" {...style.gridSM}>
                      <Input type='text' />
                    </Form.Item>
                    <Form.Item name="Expression_23" label="発生プログラム" {...style.gridSM}>
                      <Input type='text' style={{ width: '70%' }} />
                    </Form.Item>
                  </div>
                  <div style={{ padding: '10px', border: '1px solid #9dc2e8', marginBottom: '10px' }}>
                    <Form.Item name="version" label="バージョン" {...style.gridSM}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/YY" />
                    </Form.Item>
                    <Form.Item name="host_name" label="コンピュータ" {...style.gridSM}>
                      <Input type='text' style={{ width: '70%' }} />
                    </Form.Item>
                    <Form.Item name="Expression_25" label="ユーザー" {...style.gridSM}>
                      <Input type='text' style={{ width: '70%' }} />
                    </Form.Item>
                  </div>
                  <div style={{ padding: '10px', border: '1px solid #9dc2e8', marginBottom: '10px' }}>
                    <Form.Item name="Expression_32" label="受診日" {...style.gridSM}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/YY" />
                    </Form.Item>
                    <Form.Item name="Expression_33" label="個人番号" {...style.gridSM}>
                      <Space>
                        <Input type='text' />
                        <Button icon={<MoreOutlined />}></Button>
                        <Form.Item name="kanji_name" style={style.formBtm}>
                          <Input type='text' readOnly style={style.inputRead} />
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </div>
                  <div style={{ padding: '10px', border: '1px solid #9dc2e8', marginBottom: '10px' }}>
                    <Form.Item name="Expression_26" label="内容" {...style.gridSM}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format="YYYY/MM/YY" />
                    </Form.Item>
                    <Form.Item name="recorded_content" label="明細" {...style.gridSM}>
                      <TextArea rows={10} />
                    </Form.Item>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0262001_LogDisplay);
