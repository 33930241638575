import PostCodeSearchEngineService from 'services/basicInfo/InsurerInfoMaintain/PostCodeSearchEngineService'
import { message } from 'antd'

const PostCodeSearchEngineAction = {
  index() {
    return PostCodeSearchEngineService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changePublic(params) {
    return PostCodeSearchEngineService.changePublic(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeCity(params) {
    return PostCodeSearchEngineService.changeCity(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeArea(params) {
    return PostCodeSearchEngineService.changeArea(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}
export default PostCodeSearchEngineAction
