import React from "react";
import { connect } from "react-redux";

import { Card, Table, Input, Button, Checkbox, Form } from "antd";


class WS2249013_ContinuousPrint extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '連続印刷';

    this.state = {
    };
  }
  render() {
    return (
      <div className="continuous-print">
        <Card className="mb-2" title="連続印刷">
          <Table
             bordered
            dataSource={[]}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: 1,
              pageSize: 1,
              showSizeChanger: true,
              onChange: (page, pageSize) => { },
              onShowSizeChange: (page, pageSize) => { },
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="" align="center" dataIndex="11" key="" />
            <Table.Column title="様式" dataIndex="111" key="" />
            <Table.Column title="名称" dataIndex="1111" key="" />
          </Table>

          <Button type="primary" style={{ float: 'right', margin: '15px 30px 10px 0' }}>印　刷</Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2249013_ContinuousPrint);
