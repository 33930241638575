import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Table, Row, Col, Button } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

const columns = [
  { title: '実施機関コード', dataIndex: 'implementing_agency_code' },
  { title: '機関番号', dataIndex: 'institution_number' },
  { title: '機関名', dataIndex: 'kanji_name' }
]
class WS1391001_ImplementAgencyInquiry extends React.Component {

  static propTypes = {
    implementing_agency_code: PropTypes.any,
    kanji_name: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00210:実施機関照会';

    this.state = {
      dataSource: [],
      isLoading: true,
      selectedRows: [],
      initialValues: {
        implementing_agency_code: this.props.implementing_agency_code,
        kanji_name: '',
        page: 1,
        limit: 10,
      },
      pagination: {
        size: 'small',
        showQuickJumper: true
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.loadData(this.state.initialValues);
  }

  loadData = (params) => {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API001391001002", { ...params })
      .then((res) => {
        let dataRes = res;
        if (dataRes) {
          this.setState({
            dataSource: dataRes,
            pagination: {
              ...this.state.pagination,
              current: dataRes.current_page,
              pageSize: dataRes.per_page,
              total: dataRes.total,
            }
          })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
      <div className="implement-agency-inquiry">
        <Card className="mb-2" title="V4-TSB00210:実施機関照会">
          <Table
            bordered
            dataSource={this.state.dataSource}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: 1,
              pageSize: 1,
              showSizeChanger: true,
              onChange: (page, pageSize) => { },
              onShowSizeChange: (page, pageSize) => { },
            }}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                delete record.kanji_name;
                this.props.onFinishScreen(record);
              }
            })}
            rowKey={(record) => record.id}
          >
            {columns.map((item, index) => (
              <Table.Column
                key={'input' + index}
                title={item.title}
                dataIndex={item.dataIndex}
              />
            ))}

          </Table>
          <Row>
            <Col span={24} className="text-end" style={{ textAlign: "right" }}>
              <Button type="primary"
                disabled={this.state.selectedRows.length === 0}
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      implementing_agency_code: this.state.selectedRows[0].implementing_agency_code,
                      kanji_name: this.state.selectedRows[0].kanji_name,
                    });
                  }
                }}>
                選択
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1391001_ImplementAgencyInquiry);
