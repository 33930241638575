import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-init-input/support-plan-manual-create/get-screen-data",
  tableGet: "/api/insure-guide-init-input/support-plan-manual-create",
  save: "/api/insure-guide-init-input/support-plan-manual-create/save",
  delete: "/api/insure-guide-init-input/support-plan-manual-create/delete",
};

const SupportPlanManualCreateService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async tableGet(params) {
    return axios.get(API_LIST.tableGet, { params });
  },
  async save(params) {
    return axios.post(API_LIST.save, params);
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },
};

export default SupportPlanManualCreateService;
