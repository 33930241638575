import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Button, message, Table, Modal } from 'antd'
import { PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import WS0898002_IntroduceLetterEditMock from 'pages/Mock/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898002_IntroduceLetterEditMock.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import WS2589011_FormatSelect from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS2589011_FormatSelect.jsx'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'
import IntroduceLetterExtractMaintainAction from 'redux/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtractMaintain.action'
import WS0898001_dataList from './WS0898001_dataList.js'

class WS0898001_IntroduceLetterExtractMaintainMock extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = '紹介状抽出保守(モック)'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceIntroduction: [],
      values: {
        visit_date_on: '',
        receipt_number: '',
        kanji_name: '',
        reservation_number: 0,
        personal_number_id: '',
        course_level: 0,
        pattern_code: '',
      },
      subTitle: '',

      //  固定データ　本来は親からもらう　(this.propsに入る)
      Li_PersonalNum: 9999023,
      Li_ReserveNum: 202303030000045,
      Li_VisitDate: '2023-03-04',

    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    const params = {
      Li_PersonalNum: this.state.Li_PersonalNum ?? '',
      Li_ReserveNum: this.state.Li_ReserveNum ?? 0
    }

    // IntroduceLetterExtractMaintainAction.index(params)
    //   .then((res) => {
    //     // this.renderForm(res)

    //     // 受診者の情報設定
    //     this.setState({
    //       values: {
    //         ...this.state.values,
    //         visit_date_on: res.visit_date_on,
    //         receipt_number: res.receipt_number,
    //         kanji_name: res.kanji_name,
    //         course_level: res.course_level,
    //         pattern_code: res.pattern_code,
    //         personal_number_id: this.state.Li_PersonalNum,
    //         reservation_number: this.state.Li_ReserveNum
    //       },
    //       subTitle: `[${this.state.Li_PersonalNum ?? ''}] ${res.kanji_name ?? ''}`
    //     })
    //     // 紹介状のデータ取得
    //     this.getIntroductions()
    //   })

    // 固定データからとる
    const res = WS0898001_dataList.initData
    // 受診者の情報設定
    this.setState({
      values: {
        ...this.state.values,
        visit_date_on: res.visit_date_on,
        receipt_number: res.receipt_number,
        kanji_name: res.kanji_name,
        course_level: res.course_level,
        pattern_code: res.pattern_code,
        personal_number_id: this.state.Li_PersonalNum,
        reservation_number: this.state.Li_ReserveNum
      },
      subTitle: `[${this.state.Li_PersonalNum ?? ''}] ${res.kanji_name ?? ''}`
    })
    // 紹介状のデータ取得
    this.getIntroductions()
  }

  /**
   * 紹介状のデータ取得
   */
  getIntroductions = (recordId = null) => {
    const params = {
      Li_PersonalNum: this.state.Li_PersonalNum ?? '',
      Li_VisitDate: this.state.Li_VisitDate ?? '',
      Li_ReserveNum: this.state.Li_ReserveNum ?? 0
    }
    // IntroduceLetterExtractMaintainAction.getIntroductions(params)
    //   .then((res) => {
    //     if (res) {
    //       this.setState({ dataSourceIntroduction: res })

    //       if (recordId) {
    //         let record = res.find(x => x.id === recordId)
    //         if (record) {
    //           //recordIdと一致するデータが存在する場合、印刷・発行処理に進む
    //           this.printBefore(record)
    //         }
    //       }
    //     }
    //   })

    // 固定データからとる
    const res = WS0898001_dataList.introductionsData
    this.setState({ dataSourceIntroduction: res })
    if (recordId) {
      let record = res.find(x => x.id === recordId)
      if (record) {
        //recordIdと一致するデータが存在する場合、印刷・発行処理に進む
        this.printBefore(record)
      }
    }
  }

  /**
   * 紹介状発行（印刷・発行前処理）
   */
  printBefore = (record = null) => {
    // record=null以外の場合 → １行の印刷 : record=nullの場合 → 一括印刷
    IntroduceLetterExtractMaintainAction.printBefore()
      .then((res) => {
        if (res && res.message == 'Call Screen WS0433001') {
          this.showWS0433001_PrinterConfirm(res.variables, record)
        }
        if (res && res.message == 'Call Screen WS2589011') {
          this.showWS2589011_FormatSelect(res.variables, record)
        }
      })
  }

  /**
   * 書式選択　モーダル
   * @param {*} record
   */
  showWS2589011_FormatSelect = (res, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS2589011_FormatSelect
            Lio_PrinterNo={res.Lo_PrinterNo}
            Lio_Preview={res.Lo_Preview}
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                // 印刷・プレビュー前処理
                this.prePrintPreview(res, record, output)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * プリンター確認
   * @param {*} record
   */
  showWS0433001_PrinterConfirm(res, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={res.Li_PreviewSpecifiedValue}
            Li_PrinterNoSpecifiedValue={res.Li_PrinterNoSpecifiedValue}
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                // 印刷・プレビュー前処理
                this.prePrintPreview(res, record, output)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 印刷・プレビュー前処理
   * @param {*} res
   * @param {*} record
   * @param {*} output
   */
  prePrintPreview = (res, record, output) => {
    const params = {
      StsOutput: output.Lo_StsOutput ? 1 : 0,
      Preview: output.Lo_Preview ? 1 : 0,
      PrinterNum: output.Lo_PrinterNo,
      FormatList: res.FormatList,
      OptionHghp18C0: res.OptionHghp18C0,
      course_level: this.state.values.course_level,
      // record=null以外の場合 → １行の印刷 : record=nullの場合 → 一括印刷
      printData: (record) ? [record] : this.state.dataSourceIntroduction
    }
    if (output.Lo_Preview) {
      // プレビュー
      this.preview(params)
    } else {
      // 印刷
      this.print(params)
    }
  }

  /**
   * プレビュー実行
   */
  preview = (params) => {
    IntroduceLetterExtractMaintainAction.preview(params)
      .then(res => {
        // pdfのObject作成　erviceで{ responseType: "blob" }を設定する必要がある
        let fileURL = URL.createObjectURL(res.data)
        // pdfを別タブで表示
        var fileOpen = window.open(fileURL)

        // データ再取得
        this.getIntroductions()
      })
      .catch(err => {
        const res = err.response
        if (res.request.responseType === 'blob') {
          res.data.text()
            .then(str => {
              let json = JSON.parse(str);
              message.error(json.message)
            })
        }
        if (err.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          })
        }
      })
  }

  /**
   * 印刷実行
   */
  print = (params) => {
    IntroduceLetterExtractMaintainAction.print(params)
      .then(res => {
        if (res.data === 'true') {
          message.success('印刷が完了しました')

          // データ再取得
          this.getIntroductions()

        } else {
          message.error('処理の途中でエラーが発生しました')
        }
      }).catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
        if (err.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          })
        }
      })
  }

  /**
   * 紹介状編集 モーダル
   */
  showWS0898002_IntroduceLetterEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS0898002_IntroduceLetterEditMock
            record={record}
            values={this.state.values}
            subTitle={this.state.subTitle}
            onFinishScreen={() => {
              // データ再取得
              this.getIntroductions()
              this.closeModal()
            }}
            onPrint={(recordId) => {
              // データ再取得
              this.getIntroductions(recordId)
              this.closeModal()
            }}
          />,
      },
    })
  }

  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name)
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-extract-maintain'>
        <Card title={'紹介状抽出保守 ' + this.state.subTitle + '（モック）'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Table
                bordered
                dataSource={this.state.dataSourceIntroduction}
                scroll={{ y: 400 }}
                size='small'
                pagination={false}
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      // 変更
                      this.showWS0898002_IntroduceLetterEdit(record)
                    }
                  }
                }}
              >
                {/* <Table.Column title='受診日' width={100} dataIndex='visitDateOn' /> */}
                <Table.Column title='診療科(検査部門)' width={200} dataIndex='exam_department_name' />
                <Table.Column title='受診科' width={200} dataIndex='department_name' />
                <Table.Column title='判定医' width={200} dataIndex='doctor_name' />
                <Table.Column title='指導' dataIndex='comment_content' />
                <Table.Column title='発行' align='center' width={60} dataIndex='issue'
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'center', color: Color(record.issueColorNum)?.Foreground }}>
                      {value}
                    </div>}
                />
                <Table.Column title='返送' align='center' width={60} dataIndex='sendingBack'
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'center', color: Color(record.sendingBackColorNum)?.Foreground }}>
                      {value}
                    </div>
                  }
                />

                <Table.Column align='center' width={60}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() =>
                        // 新規作成
                        this.showWS0898002_IntroduceLetterEdit()
                      }
                    />}
                  render={(value, record) => {
                    return (
                      <Button size='small'
                        onClick={() =>
                          // 詳細
                          this.showWS0898002_IntroduceLetterEdit(record)
                        }
                      >詳細</Button>)
                  }}
                />

                <Table.Column align='center' width={60}
                  title=''
                  render={(value, record) => {
                    return (
                      <Button size='small'
                        onClick={() =>
                          // 印刷・発行前処理
                          this.printBefore(record)
                        }
                      >印刷</Button>)
                  }}
                />
              </Table>
            </div>
          </Form>

          <div className='box_button_bottom_right' >
            <Button
              type='primary'
              icon={<PrinterOutlined />}
              disabled={!(this.state.dataSourceIntroduction.length > 0)}
              onClick={() => {
                // 印刷・発行前処理
                this.printBefore()
              }}
            >
              一括印刷
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0898001_IntroduceLetterExtractMaintainMock)
