import axios from "configs/axios";

const API_LIST = {
  dunningContent: "/api/insure-guide-input/dunning-inquiry/dunningcontent",
  getScreenData: "/api/insure-guide-input/dunning-inquiry/getscreendata",
  delete: "/api/insure-guide-input/dunning-inquiry/delete",
};

const DunningInquiryService = {
  async getDunningContent(params) {
    return axios.get(API_LIST.dunningContent, { params });
  },
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },
};

export default DunningInquiryService;
