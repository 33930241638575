import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/condition-express-cmt-setting/condition-express-add-sub/get-screen-data',
  changeCode: '/api/condition-express-cmt-setting/condition-express-add-sub/change-code',
  righthandSideQuery: '/api/condition-express-cmt-setting/condition-express-add-sub/righthand-side-query',
  save: '/api/condition-express-cmt-setting/condition-express-add-sub/save'
};

const ConditionExpressAddSubService = {
  async getScreenData() {
    return axios.get(apiPaths.getScreenData);
  },
  async changeCode(params) {
    return axios.get(apiPaths.changeCode, { params });
  },
  async righthandSideQuery(params) {
    return axios.get(apiPaths.righthandSideQuery, { params });
  },
  async save(params) {
    return axios.post(apiPaths.save, params);
  },
};

export default ConditionExpressAddSubService;