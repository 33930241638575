import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Space, Table, Button, message, Form, Modal, Menu, Dropdown, Input, Row, Col } from 'antd'
import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
// import InspectCmtSearchQueryService from 'services/InputBusiness/SpreadInput/InspectCmtSearchQueryService'
import InspectCmtSearchQueryAction from 'redux/InputBusiness/SpreadInput/InspectCmtSearchQuery.action.js'
import PropTypes from 'prop-types'
import WS0358001_InspectCmtInfoMaintain from 'pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS0358001_InspectCmtInfoMaintain'
import WS2712001_InspectCmtInfoMaintainSub from 'pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS2712001_InspectCmtInfoMaintainSub'
import { registerDataAction, deleteDataAction } from 'redux/ExamCmtInfoMaintain/InspectCmtInfoMaintainSub.action'

class WS0274001_InspectCmtSearchQuery extends React.Component {
  static propTypes = {
    Lio_CmtClassify: PropTypes.any,
    LnkOutInspectCmtScreen: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '検査コメント検索・照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      screenData: {},
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }

  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    const { Lio_CmtClassify, LnkOutInspectCmtScreen } = this.props
    // データ取得
    this.index(Lio_CmtClassify, LnkOutInspectCmtScreen)
  }

  /**
   * 検査コメントのデータを取得
   * @param {*} Lio_CmtClassify
   * @param {*} LnkOutInspectCmtScreen
   */
  index = (Lio_CmtClassify, LnkOutInspectCmtScreen) => {
    const params = {
      // Lio_CmtClassify: Lio_CmtClassify,
      //2022-07-23 コメント区分0のときはnullを渡すように変更
      Lio_CmtClassify: Lio_CmtClassify === 0 ? null : Lio_CmtClassify,
      LnkOutInspectCmtScreen: LnkOutInspectCmtScreen,
    }
    InspectCmtSearchQueryAction.index(params)
      .then((res) => {
        if (res) {
          // データ反映
          this.setState({
            dataSource: res.ListData,
            screenData: res
          })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * コメント選択処理
   * @param {*} record
   */
  handleRowSelected = (record) => {
    this.setState({ record: record })
    if (this.props.onFinishScreen && record) {
      this.props.onFinishScreen({
        Lio_CmtClassify: record.comment_division,
        LnkOutInspectCmtScreen: record.exam_comment_screen,
        commentCode: record.exam_comment_code,
        recordData: record,
        flg_724: 1,
      })
    }
  }

  /**
   * 検査コメント情報保守　モーダルを開く
   */
  showWS0358001_InspectCmtInfoMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS0358001_InspectCmtInfoMaintain
            Lio_CmtClassify={this.state.selectedRows?.comment_division}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
    this.componentDidMount()
  }

  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS2712001_InspectCmtInfoMaintainSub
            Lio_CmtClassify={record.comment_division}
            Lio_CmtCode={record.exam_comment_code}
            screenData={this.state.screenData}
            StsEnable={true}
            record={record}
            newFlag={false}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addForm = () => {
    let commentDivision = this.state.dataSource[0].comment_division ?? ''
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS2712001_InspectCmtInfoMaintainSub
            comment_division={commentDivision}
            screenData={this.state.screenData}
            Lio_CmtClassify={0}
            Lio_CmtCode={''}
            newFlag={true}
            StsEnable={true}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  deleteData(record) {
    deleteDataAction({
      id: record.id
    })
      .then(() => {
        message.success('削除しました')
        this.componentDidMount()
      })
      .catch(() => message.success('エラーが発生しました'))
  }


  incrementalSearch = (value) => {

    let buf = '.*' + value.replace(/(.)/g, "$1.*")
    let reg = new RegExp(buf)

    setTimeout(() => {

      let all = this.state.screenData.ListData
      let fildata

      if (value.trim() === '') {
        fildata = all
      } else {
        if (isNaN(value)) {

          fildata = all.filter((d) => {
            return reg.test(d.exam_comment_screen)
              || reg.test(d.exam_comment_form)
          })

        } else {

          fildata = all.filter((d) => {
            return reg.test(d.exam_comment_code)
          })

        }
      }

      this.setState({
        dataSource: fildata
      })

    }, 500)
  }

  render() {
    return (
      <div className='inspect-cmt-search-query'>
        <Card title='検査コメント検索・照会'>

          <Form.Item name='Search' label='検索'>
            <Input onChange={(e) => this.incrementalSearch(e.target.value)} />
          </Form.Item>

          <Table
            bordered
            size='small'
            scroll={{ x: 'max-content', y: '300px' }}
            dataSource={this.state.dataSource}
            rowKey={(record) => record.id}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            onRow={(record) => {
              return {
                onDoubleClick: async () => {
                  // 編集
                  await this.handleRowSelected(record)
                }
              }
            }}
          >

            {this.state.screenData.commentFlag && (
              <Table.Column
                // width={80}
                className='column-size-40'
                align='center'
                title='ｺﾒﾝﾄ区分'
                dataIndex='comment_division'
                key=''
                render={(value, record, index) => (
                  <Form.Item style={{ textAlign: 'right' }}>
                    <span>{record.comment_division}</span>
                  </Form.Item>
                )}
              />
            )}

            <Table.Column
              // width={80}
              className='column-size-40'
              title='コード'
              dataIndex='exam_comment_code'
              render={(value) => (
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />
            <Table.Column
              // width={100}
              className='column-size-40'
              title={this.state.screenData.examCommentScreenTitle ?? ''}
              dataIndex='exam_comment_screen'
            />
            <Table.Column
              className='column-size-40'
              title={this.state.screenData.examCommentFormTitle ?? ''}
              dataIndex='exam_comment_form'
            />
            <Table.Column
              className='column-size-2'
              align='center'
              fixed='right'
              title={
                <Button
                  type='primary'
                  size='small'
                  icon={<PlusOutlined />}
                  onClick={this.addForm}
                >
                </Button>
              }
              render={(value, record, index) => {
                return (
                  <Dropdown
                    size='small'
                    trigger='click'
                    overlay={() => (
                      <Menu >
                        <Menu.Item
                          key='1'
                          onClick={() => {
                            this.editForm(record)
                          }
                          }
                        >
                          修正
                        </Menu.Item>
                        <Menu.Item
                          key='2'
                          onClick={() => {
                            const params = {
                              id: record.id,
                              course_code: record
                            }
                            Modal.confirm({
                              content: '削除を行いますか',
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                this.deleteData(record)
                              }
                            })
                          }}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}>
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )
              }}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0274001_InspectCmtSearchQuery)
