import React from 'react'
import moment from 'moment'
import { Space, Select, Input } from 'antd'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'

const defaultFormatDate = 'YYYY/MM/DD'

/**
 * 和暦の日付設定 カスタムコンポーネント
 * @param {*} props
 * formRef　・・・<From.Item>を使用する時に設定
 * value ・・・西暦の日付(YYYY/MM/DD)の値
 * onChange ・・・入力で値が変更された時
 * @returns
 */
const JapaneseDateCoustom = (props) => {
  const [eraName, setEraName] = React.useState('') //元号（アルファベット）
  const [year, setYear] = React.useState('') //元号の年
  const [month, setMonth] = React.useState('') //月
  const [day, setDay] = React.useState('') //日
  const [defaultDate, setDefaultDate] = React.useState('') //西暦の日付

  /**
   * 西暦の値が変更になった時
   */
  React.useEffect(() => {
    let date = ''
    let tempYearName = ''
    let tempYear = ''
    let tempMonth = ''
    let tempDay = ''

    if (props.value == undefined) {
      setEraName(tempYearName)
      setYear(tempYear)
      setMonth(tempMonth)
      setDay(tempDay)
      setDefaultDate(date)
    }

    if (props.value && !eraName) {
      // 日付チェック
      date = moment(props.value).isValid() ? props.value : ''
      if (date) {
        // フォーマット整える
        date = moment(date).format(defaultFormatDate)
        // 和暦を取得
        const jYear = new Date(date).toLocaleDateString('ja-JP-u-ca-japanese', {
          era: 'narrow',  // 時代 : 'long' → '平成'、 'narrow' → 'H'
          year: 'numeric'  // 年 : 'ja-JP-u-ca-japanese' の場合は和暦
        })

        // 元号（アルファベット）を取得
        tempYearName = jYear.substring(0, 1)
        // 年だけ抽出
        tempYear = jYear.replace(/[^0-9]/g, '')
        tempMonth = moment(date).format('M')
        tempDay = moment(date).format('D')
      }

      // 値設定
      setEraName(tempYearName)
      setYear(tempYear == '' ? 1 : tempYear)
      setMonth(tempMonth)
      setDay(tempDay)
      setDefaultDate(date)
    }
  }, [props.value])


  /**
   * 変更処理
   * 和暦から西暦に変換する
   * @param {*} value
   * @param {*} type
   */
  const onChange = (value, type) => {

    // 初期値
    let seireki = ''
    let tempBirthday = ''
    let tempYearName = eraName
    let olderaName = eraName
    let tempYear = year
    let tempMonth = month
    let tempDay = day
    let format = ''

    switch (type) {
      case 'eraName':
        // 元号（アルファベット）
        setEraName(value)
        tempYearName = value
        break
      case 'year':
        // 年
        setYear(value)
        tempYear = value
        break
      case 'month':
        // 月
        setMonth(value)
        tempMonth = value
        break
      case 'day':
        // 日
        setDay(value)
        tempDay = value
        break
      default:
    }

    // 西暦計算用
    const eraNameLit = { T: 1911, S: 1925, H: 1988, R: 2018 }

    // 入力内容がエラーの場合、
    let mDate = false
    if (tempYearName && tempYear && tempMonth && tempDay) {
      let ketasuu = String(tempYear).length;
      //和暦・西暦判定
      if (tempYearName == 'AD') {

        if (ketasuu == 4) {
          tempBirthday = tempYear + "-" + tempMonth + '-' + tempDay
          mDate = moment(tempBirthday).isValid() ? moment(tempBirthday).format(defaultFormatDate) : false
        } else {
          seireki = (olderaName === 'AD') ? Number(tempYear) : eraNameLit[olderaName] + Number(tempYear)
          tempBirthday = seireki + "-" + tempMonth + '-' + tempDay
          mDate = moment(tempBirthday).isValid() ? moment(tempBirthday).format(defaultFormatDate) : false

          setYear(seireki)
        }

      } else {
        //西暦→和暦変換
        if (ketasuu == 4) {
          format = tempYear + "-" + tempMonth + '-' + tempDay
          format = moment(format).format(defaultFormatDate)
          // 和暦を取得
          const jYear = new Date(format).toLocaleDateString('ja-JP-u-ca-japanese', {
            era: 'narrow',  // 時代 : 'long' → '平成'、 'narrow' → 'H'
            year: 'numeric'  // 年 : 'ja-JP-u-ca-japanese' の場合は和暦
          })

          // 元号（アルファベット）を取得
          tempYearName = jYear.substring(0, 1)
          // 年だけ抽出
          let repjYear = jYear.replace('元', '1')
          tempYear = repjYear.replace(/[^0-9]/g, '')

          setEraName(tempYearName)
          setYear(tempYear)
        }

        //和暦→西暦変換
        seireki = eraNameLit[tempYearName] + Number(tempYear);
        tempBirthday = seireki + "-" + tempMonth + '-' + tempDay
        mDate = moment(tempBirthday).isValid() ? moment(tempBirthday).format(defaultFormatDate) : false
      }
    }

    if (!tempYearName) {
      tempYear = ''
      tempMonth = ''
      tempDay = ''

      setEraName(tempYearName)
      setYear(tempYear)
      setMonth(tempMonth)
      setDay(tempDay)
    }

    if (tempYearName === '' && tempYear === '' && tempYear === '' && tempDay === '') {
      mDate = ''
    }

    if (props.onChange) {
      // 親画面に西暦の日付を渡す
      props.onChange(mDate)
    }

    if (props.id && props.formRef) {
      // formRefに設定
      props.formRef?.current?.setFields([
        { name: props.id, value: mDate },
      ])
    }
  }

  return (
    <Space>
      <Select
        id='japanese-date-input-1'
        style={{ width: 74 }}
        value={eraName}
        onChange={event => onChange(event, 'eraName')}
      >
        <Select.Option value='AD'>西暦</Select.Option>
        <Select.Option value='T'>大正</Select.Option>
        <Select.Option value='S'>昭和</Select.Option>
        <Select.Option value='H'>平成</Select.Option>
        <Select.Option value='R'>令和</Select.Option>
        <Select.Option value=''>{''}</Select.Option>
      </Select>

      <Input
        id='japanese-date-input-2'
        type='number'
        className='input-size-number-4'
        value={year}
        maxLength={2}
        onChange={event => onChange(event.target.value, 'year')}
        onKeyDown={(e) => moveFocusKeyEvent(e, 'japanese-date-input', 1, true, 0)}
      />
      <div style={{ marginBottom: 0 }}>年</div>

      <Input
        id='japanese-date-input-3'
        type='number'
        className='input-size-number-2'
        value={month}
        maxLength={2}
        onChange={event => onChange(event.target.value, 'month')}
        onKeyDown={(e) => moveFocusKeyEvent(e, 'japanese-date-input', 1, true, 0)}
      />
      <div style={{ marginBottom: 0 }}>月</div>

      <Input
        id='japanese-date-input-4'
        type='number'
        className='input-size-number-2'
        value={day}
        maxLength={2}
        onChange={event => onChange(event.target.value, 'day')}
        onKeyDown={(e) => moveFocusKeyEvent(e, 'japanese-date-input', 1, true, 0)}
      />
      <div style={{ marginBottom: 0 }}>日</div>
    </Space>
  )
}


export default JapaneseDateCoustom
