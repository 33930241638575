import { message } from 'antd'
import ExamDepartmentMasterService from 'services/IntroductionLetter/IntroduceLetterMasterMaintain/ExamDepartmentMasterService'

const ExamDepartmentMasterAction = {
  index(params) {
    return ExamDepartmentMasterService.index(params)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(data) {
    return ExamDepartmentMasterService.save(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  delete(data) {
    return ExamDepartmentMasterService.delete(data)
      .then(res => {
        if (res) {
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default ExamDepartmentMasterAction
