import axios from 'configs/axios'

const apiPaths = {
  index: '/api/mutual-aid-association-billing/mutual-aid-target-ken-course-maintain',
  save: '/api/mutual-aid-association-billing/mutual-aid-target-ken-course-maintain/save',
  delete: '/api/mutual-aid-association-billing/mutual-aid-target-ken-course-maintain/delete',
}

const MutualAidTargetKenCourseMaintainService = {
  async index() {
    return axios.get(apiPaths.index)
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },
}
export default MutualAidTargetKenCourseMaintainService
