import { message } from 'antd'
import MunicipalMutualAidAssociationMaintainService from 'services/AccountingBusiness/MutualAidAssociationBilling/MunicipalMutualAidAssociationMaintainService.js'

const MunicipalMutualAidAssociationMaintainAction = {
  index(data) {
    return MunicipalMutualAidAssociationMaintainService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveData(data) {
    return MunicipalMutualAidAssociationMaintainService.saveData(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getSiteClassification(data) {
    return MunicipalMutualAidAssociationMaintainService.getSiteClassification(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getMutualAidMaster(data) {
    return MunicipalMutualAidAssociationMaintainService.getMutualAidMaster(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveMutualAidMaster(data) {
    return MunicipalMutualAidAssociationMaintainService.saveMutualAidMaster(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getMedicalCheckMaster(data) {
    return MunicipalMutualAidAssociationMaintainService.getMedicalCheckMaster(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getMutualAidMasterReadOnly(data) {
    return MunicipalMutualAidAssociationMaintainService.getMutualAidMasterReadOnly(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default MunicipalMutualAidAssociationMaintainAction
