import axios from 'configs/axios'

const apiPaths = {
  index: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain',
  saveData: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain/save-data',
  getSiteClassification: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain/get-site-classification',
  getMutualAidMaster: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain/get-mutual-aid-master',
  saveMutualAidMaster: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain/save-mutual-aid-master',
  getMedicalCheckMaster: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain/get-medical-check-master',
  getMutualAidMasterReadOnly: '/api/mutual-aid-association-billing/municipal-mutual-aid-association-maintain/get-mutual-aid-master-read-only',
}

const MunicipalMutualAidAssociationMaintainService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async saveData(params) {
    return axios.post(apiPaths.saveData, params)
  },
  async getSiteClassification(params) {
    return axios.get(apiPaths.getSiteClassification, { params })
  },
  async getMutualAidMaster(params) {
    return axios.get(apiPaths.getMutualAidMaster, { params })
  },
  async saveMutualAidMaster(params) {
    return axios.post(apiPaths.saveMutualAidMaster, params)
  },
  async getMedicalCheckMaster(params) {
    return axios.get(apiPaths.getMedicalCheckMaster, { params })
  },
  async getMutualAidMasterReadOnly(params) {
    return axios.get(apiPaths.getMutualAidMasterReadOnly, { params })
  },
}
export default MunicipalMutualAidAssociationMaintainService
