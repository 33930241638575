import React from "react";
import { connect } from "react-redux";

import { Button, Card, Form, Input, Checkbox, Table, Space, message, Dropdown, Menu, Modal } from "antd";
import { MoreOutlined, PlusOutlined, } from "@ant-design/icons";
import { getCategoryData } from "redux/InspectionMaintenance/CategoryMasterMaintain/CategoryMasterMaintain.action";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS2704011_CategoryMasterMaintainSub from "./WS2704011_CategoryMasterMaintainSub";
import WS0356011_SiteFindingsMasterMaintainSub from 'pages/MS_InspectionMaintenance/V4MS0104000_SiteFindingsMasterMaintain/WS0356011_SiteFindingsMasterMaintainSub.jsx'
import { deleteDataAction } from "redux/InspectionMaintenance/CategoryMasterMaintain/CategoryMasterMaintain.action"

class WS2704001_CategoryMasterMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'カテゴリーマスタ保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      initValue: {
        SearchChar: '',
        ExamCode: ''
      },
      dataSource: [],
      isLoadingPage: false,
      colorText: 208,

    };
  }

  componentDidMount = () => {
    const params = {
      ...this.state.initValue,
      $Li_SearchChar: this.formRef.current.getFieldValue("SearchChar"),
      $stsUseOnly: this.formRef.current.getFieldValue("stsUseOnly")
    }
    this.loadData(params)
  }

  loadData = (params) => {
    let param = {
      SearchChar: this.formRef.current.getFieldValue("SearchChar"),
      stsUseOnly: this.formRef.current.getFieldValue("stsUseOnly") ? 1 : 0
    }
    getCategoryData(param)
      .then(res => {
        if (res) {
          let data = res.data
          this.setState({
            dataSource: data
          });
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  handleChangeValue = (objChange) => {
    const obj = {
      ...this.state.initValue,
      ...objChange
    }

    this.setState({
      initValue: obj
    })

    this.loadData(obj)
  }

  onCheckStsEnabled(record, event) {
    const array = [...this.state.dataSource];
    const checked = event.target.checked ? 1 : 0;
    const index = this.state.dataSource.findIndex(s => s.id === record.id);
    array[index].StsEnable = checked;
    this.setState({ dataSource: array });
    this.forceUpdate()
  }

  inspectChange(event) {
    let columnName = Object.keys(event)[0];
    let columnValue = Object.values(event)[0];
    if (columnName === "ItemCodeMedicalExam" && columnValue === "") {
      this.formRef.current?.setFieldsValue({ "exam_name": "" })
    }
  }

  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 450,
        component: (
          <WS2704011_CategoryMasterMaintainSub
            record={record}
            newFlag={false}
            Li_CategoryCode={record.category_code}
            onFinishScreen={() => {
              this.loadData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  openSiteFindingsEdit = (record) => {
    console.log(record)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <WS0356011_SiteFindingsMasterMaintainSub
            newFlag={false}
            Li_SiteClassificationCode={record.site_classification}
            Li_SiteClassificationName={record.site_classification_name}
            onFinishScreen={() => {
              this.loadData()
              // this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 450,
        component: (
          <WS2704011_CategoryMasterMaintainSub
            newFlag={true}
            Li_CategoryCode={0}
            onFinishScreen={() => {
              this.loadData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  delete(record) {
    deleteDataAction({
      id: record.id
    })
      .then(() => {
        message.success('削除しました');
        this.loadData()
      })
      .catch(() => message.success('エラーが発生しました'))
  }


  render() {
    return (
      <div className="use-inspect">
        <Card className="mb-2" title="カテゴリーマスタ保守">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={(event) => this.inspectChange(event)}
          >
            <div className="box_inner_horizontal">
              <Space>
                <Form.Item
                  name="SearchChar"
                  label="検索"
                  style={{ width: 400 }}
                >
                  <Input
                    maxLength={256}
                    onPressEnter={(e) => {
                      // if (e.key === 'Enter') {
                      this.handleChangeValue({ SearchChar: e.target.value })
                      // }
                    }}
                  />
                </Form.Item>
              </Space>

              <Form.Item
                name="stsUseOnly"
                valuePropName="checked"
                style={{ margin: 0 }}
              >
                <Checkbox
                  onChange={(e) => this.handleChangeValue({ stsUseOnly: e.target.checked ? 1 : 0 })}
                >
                  パターン使用
                </Checkbox>
              </Form.Item>
            </div>


            <Table bordered
              dataSource={this.state.dataSource}
              loading={false}
              size='small'
              scroll={{ y: 600 }}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () =>
                  this.editForm(record)
              })}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
            >
              <Table.Column
                width={70}
                title="コード"
                dataIndex="category_code"
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                width={300}
                title="カテゴリー名称"
                dataIndex="category_name"

              />

              <Table.Column
                width={100}
                title="部位分類"
                dataIndex="site_classification"
                render={(value, record, index) => (
                  <div style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                title="分類名称"
                dataIndex="site_classification_name"
              />

              <Table.Column
                width={50}
                title="使用"
                dataIndex="stsUse"
                render={(value, record, index) =>
                  <div style={{ textAlign: 'center' }}>
                    {value ? '○' : ''}
                  </div>
                }
              />

              <Table.Column
                width={40}
                align="center"
                title={
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={this.addForm}
                    size='small'
                  >
                  </Button>
                }
                render={(value, record, index) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='edit'
                          onClick={() => { this.editForm(record) }}
                        >
                          編集
                        </Menu.Item>
                        <Menu.Item
                          key='site_findings'
                          disabled={record.site_classification !== '' ? false : true}
                          onClick={() => this.openSiteFindingsEdit(record)}
                        >
                          部位・所見
                        </Menu.Item>
                        <Menu.Item
                          key='delete'
                          onClick={() => {
                            // 確認モーダルを表示してから、削除
                            Modal.confirm({
                              content: `削除を行いますか?`,
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                this.delete(record)
                              }
                            })
                          }}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                  // <Button
                  //   size="small"
                  //   icon={<MoreOutlined />}
                  //   onClick={() => { this.editForm(record) }}
                  // />
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2704001_CategoryMasterMaintain);
