import axios from "configs/axios";

const apiPaths = {
  getIndex: "/api/insure-guide-course/insure-guide-course",
  addData: "/api/insure-guide-course/insure-guide-course/add-data",
  postData: "/api/insure-guide-course/insure-guide-course/post-data",
  deleteData: "/api/insure-guide-course/insure-guide-course/delete-data",
};

const InsureGuideCourseService = {

  async getIndex() {
    return axios.get(apiPaths.getIndex);
  },

  async addData(params) {
    return axios.post(apiPaths.addData, params);
  },

  async postData(params) {
    return axios.post(apiPaths.postData, params);
  },

  async deleteData(params) {
    return axios.delete(apiPaths.deleteData, { params });
  },

};

export default InsureGuideCourseService;
