import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, InputNumber, Input, Select, Button, Table, Row, Col, Space, DatePicker, Modal, Menu, Dropdown, message, Spin, Tooltip } from "antd";

import { DeleteOutlined,SaveOutlined,SearchOutlined, MoreOutlined, PlusOutlined, CaretDownOutlined } from '@ant-design/icons';
import Checkbox from "antd/lib/checkbox/Checkbox";
import Color from "constants/Color";
import { width } from "dom-helpers";

import { ModalCustom } from "components/Commons/ModalCustom";
import { ModalConfirm } from "components/Commons/ModalConfirm";
import Menubar from "components/Commons/Menubar";

import WS1181001_GovernmentTubeConvertMaintain from "./WS1181001_GovernmentTubeConvertMaintain";
import axios from "configs/axios";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

import moment from "moment";


class WS1176003_CopyRegisterScreenInput extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_test_set_code  : PropTypes.any,
    Li_test_set_name  : PropTypes.any,   
    Li_2CopyName      : PropTypes.any, 
};

constructor(props) {
  super(props);
    // document.title = '複写登録（画面入力）';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
        _2_End_Ststus   :1,
    }
    this.onFinish = this.onFinish.bind(this)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  componentDidMount = () => {
    const data = {
      test_set_code: this.props.Li_test_set_code ?? '',
      test_set_name: this.props.Li_test_set_name ?? '',
      _2CopyName: this.props.Li_2CopyName ?? '',
    };
    this.formRef.current?.setFieldsValue(data);
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.formRef.current.resetFields();
      const data = {
        test_set_code: this.props.Li_test_set_code ?? '',
        test_set_name: this.props.Li_test_set_name ?? '',
        _2CopyName: this.props.Li_2CopyName ?? '',
      };
      this.formRef.current?.setFieldsValue(data);
    }
  }

 
  onFinish = (values) => {
    if (values._2CopyKey === '' || !values._2CopyKey) {
      message.error('既に複写先セット情報が存在します!');
      return;
    }
    VenusApiRoutesV2.callApi('API001176003001', values)
      .then(res => {
        VenusApiRoutesV2.callApi('API001176003003', values)
          .then(res => {
            if(res.message) {
              message.error(res.message);
            } else {
              this.closeModal();
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen();
              }
            }
          });
      });
  }  

  render() {
    return (
      <div className="copy-register-screen-input">
        <Card title="複写登録（画面入力）">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row gutter={16}>
              <Col span={6} style={{textAlign: "right"}}>複写元セット</Col>
              <Col span={8} >
                <Form.Item name="test_set_code" label="">
                  <Input type="text"  />
                </Form.Item>
              </Col>
              <Col span={10} >
                <Form.Item name="test_set_name" label="" >
                  <Input type="text"  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6} style={{textAlign: "right"}}>複写先ｾｯﾄ</Col>
              <Col span={8} >
                <Form.Item name="_2CopyKey" label="">
                  <Input type="text"  />
                </Form.Item>
              </Col>
              <Col span={10} >
                <Form.Item name="_2CopyName" label="" >
                  <Input type="text"  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10} offset={14}>
                <Button type="primary" htmlType="submit" >実  行</Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal()
            }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1176003_CopyRegisterScreenInput);
