import { message } from 'antd'
import AutoJudgeScreenService from 'services/InputBusiness/ExamineeList/AutoJudgeScreenService'

const AutoJudgeScreenAction = {
  index() {
    return AutoJudgeScreenService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  runAutoJudge(data) {
    return AutoJudgeScreenService.runAutoJudge(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  comprehensiveJudgment(data) {
    return AutoJudgeScreenService.comprehensiveJudgment(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}

export default AutoJudgeScreenAction
