import axios from 'configs/axios'

const API_LIST = {
  GetScreenDataAPI: '/api/sanai-medical-checkup-calendar/medical-checkup-calendar/get-screen-data',
  RecreateDisplayAPI: '/api/sanai-medical-checkup-calendar/medical-checkup-calendar/recreate-display',
  leftTableRecreateDisplayAPI: '/api/sanai-medical-checkup-calendar/medical-checkup-calendar/left-table-recreate-display',
  getCalendarPresentationHistory: '/api/sanai-medical-checkup-calendar/medical-checkup-calendar/get-calendar-presentation-history',
  confirmReserve: '/api/sanai-medical-checkup-calendar/medical-checkup-calendar/confirm-reserve',
}

const MedicalCheckupCalendarService = {
  async getScreenDataService() {
    return axios.get(API_LIST.GetScreenDataAPI)
  },
  async recreateDisplay(params) {
    return axios.get(API_LIST.RecreateDisplayAPI, { params })
  },
  async leftTableRecreateDisplay(params) {
    return axios.get(API_LIST.leftTableRecreateDisplayAPI, { params })
  },
  async getCalendarPresentationHistory(params) {
    return axios.get(API_LIST.getCalendarPresentationHistory, { params })
  },
  async confirmReserve(params) {
    return axios.post(API_LIST.confirmReserve, params)
  },
}

export default MedicalCheckupCalendarService
