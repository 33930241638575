import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Form, Input, Button, Table, Row, Col, Tabs, Modal, Space, Spin, Dropdown, Menu, InputNumber } from "antd";
import { getExamListAction } from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions';
import ModalDraggable from "components/Commons/ModalDraggable";
import { MoreOutlined } from '@ant-design/icons';
import WS0332001_ContractCourseBreakdownInquiry from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0332001_ContractCourseBreakdownInquiry'

class WS0605140_ExaminationSearch extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '契約照会';

    this.state = {
      initParams: {
        Li_ContractType: '',
        Li_ContractOrgCode: '',
        Li_ContractStartDate: '',
        Li_ContractNum: '',
        Zoning: '',
        Search: ''
      },
      selectedRows: {},
      objContract: {},
      isLoadPage: true,
      isContractInspectContentSub1: true,
      isContractInspectContentSub2: true,
      isExamList: true,
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    console.log(this.props);
    this.getExamList()
  }

  getExamList(value) {
    let params = {
      ContractType: this.props.contractType,
      ContractStartDate: this.props.ContractStartDate,
      ContractOrgCode: this.props.ContractOrgCode,
      ContractNum: this.props.ContractNum,
      medical_exam_course: this.props.medical_exam_course.replace('-', ''),
      Search: value ? value : ''
    }
    console.log(params);
    getExamListAction(params)
      .then((res) => {
        console.log(res);
        let data = res.data
        data.sort(function (a, b) {
          if (a.test_item_code > b.test_item_code) {
            return 1;
          } else {
            return -1;
          }
        })
        this.formRef.current?.setFieldsValue({ ExamList: data })
        this.forceUpdate()
      })
  }

  examType(value) {
    switch (value) {
      case 'S':
        return '所見'
      case 'X':
        return '文字'
      case 'N1':
        return '小数1'
      case 'N2':
        return '小数2'
      case 'J':
        return '文字'
      case 'N':
        return '整数'
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  render() {
    return (
      <div className="contract-line-res-displays">
        <Form ref={this.formRef}>
          <Card title="検査情報検索">
            <Form.Item name="Search" label="検索" >
              <Input onPressEnter={(e) => this.getExamList(e.target.value)} />
            </Form.Item>
            <Table
              size='small'
              dataSource={this.formRef.current?.getFieldValue('ExamList')}
              rowKey={record => record?.id}
              bordered
              // loading={isExamList}
              scroll={{ y: 500 }}
              pagination={this.formRef.current?.getFieldValue('ExamList').length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            >
              <Table.Column title="コード" dataIndex="test_item_code" width={80}
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />
              <Table.Column title="名称" dataIndex="exam_name" />
              <Table.Column title="タイプ" dataIndex="exam_type" width={80}
                render={(value, record, index) => {
                  return (
                    <div>{this.examType(value)}</div>
                  )
                }}
              />
              <Table.Column width={40} align='center'
                render={(value, record, index) => (
                  <Button size="small" icon={<MoreOutlined />} onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 700,
                        component: (<WS0332001_ContractCourseBreakdownInquiry
                          Li_ContractType={this.props.contractType}
                          Li_ContractOrgCode={this.props.ContractOrgCode}
                          Li_ContractStartDate={this.props.ContractStartDate}
                          Li_ContractNum={this.props.ContractNum}
                          Li_CourseCode={record.internal_exam_code}
                          Li_CourseInspectItemsMax1000={''}
                          onFinishScreen={(data) => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />),
                      },
                    })
                  }}></Button>
                )} />
            </Table>
            <ModalDraggable
              width={this.state.childModal.width}
              visible={this.state.childModal.visible}
              component={this.state.childModal.component}
              bodyStyle={{ margin: 0, padding: 0 }}
              maskClosable={false}
              onCancel={() => {
                this.closeModal()
              }}
            />
          </Card>
        </Form>
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0605140_ExaminationSearch);