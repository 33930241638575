import { message } from 'antd'
import CourseSpecificStardStyleSettingService from 'services/ResultOutput/CourseSpecificStardStyleSetting/CourseSpecificStardStyleSettingService.js'

const CourseSpecificStardStyleSettingAction = {
  index(data) {
    return CourseSpecificStardStyleSettingService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(data) {
    return CourseSpecificStardStyleSettingService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  // delete(data) {
  //   return CourseSpecificStardStyleSettingService.delete(data)
  //     .then((res) => {
  //       return res?.data
  //     })
  //     .catch((err) => {
  //       const res = err.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // },

  Configuration(data) {
    return CourseSpecificStardStyleSettingService.Configuration(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  captureCourse(data) {
    return CourseSpecificStardStyleSettingService.captureCourse(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  ChangeOfficeCode(data) {
    return CourseSpecificStardStyleSettingService.ChangeOfficeCode(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  ChangeMedicalExamCourse(data) {
    return CourseSpecificStardStyleSettingService.ChangeMedicalExamCourse(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  ChangeStandardPrintingStyle(data) {
    return CourseSpecificStardStyleSettingService.ChangeStandardPrintingStyle(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}

export default CourseSpecificStardStyleSettingAction
