/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Select, Radio, Checkbox, Input, Row, Tabs, Button, message, InputNumber } from "antd";
import { SaveOutlined } from '@ant-design/icons';

import WS0451001_UserParamQuery from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0451001_UserParamQuery.jsx';
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';
import WS0495001_ConditionExpressInfoInquiry from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0495001_ConditionExpressInfoInquiry.jsx';
import TextArea from "antd/lib/input/TextArea";
import CsvCreateParamMaintainAction from "redux/ResultOutput/CsvCreateParamMaintain/CsvCreateParamMaintain.action";

const radioStyle = {
  display: 'block',
  lineHeight: 1.5
};

const labelStyle = {
  fontWeight: 'bold',
  color: '#14468c',
  marginTop: 20,
  marginBottom: 5
}

const labelCol = { span: 4 }
const rightLabelCol = { span: 4 }

class WS0460501_CsvCreateParamDetails extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      advanceSettingDatas: {},
      nameCondition: '',
      ExcelFlag: true
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue(this.props.record)
    this.forceUpdate()

    this.getDataAdvancedSetting()
  }

  getDataAdvancedSetting() {
    this.setState({ isLoadingForm: true })
    let record = this.props.record;
    let params = {
      Lio_Csvhdr: record?.Csvhdr,
      Lio_Course: record?.Course,
      Lio_Id: record?.Id,
      Lio_AgeCalculate: record?.AgeCalculate,
      Lio_CsvFormat: record?.CsvFormat,
      Lio_Delimiter: record?.Delimiter,
      Lio_OutputCharSet: record?.OutputCharSet,
      Lio_Extract: record?.Extract,
      Lio_Exec: record?.Exec,
      Lio_Type: record?.Type,
      Lio_Run: record?.Run,
      Lio_InsuranceSign: record?.HealthSymbol,
      Lio_Fix: record?.Fix,
      Lio_Sort1: record?.Sort1,
      Lio_Sort2: record?.Sort2,
      Lio_Sort3: record?.Sort3,
      Lio_Sort4: record?.Sort4,
      Lio_Sort5: record?.Sort5,
      Lio_ConditionNum: record?.ConditionNum,
      Lio_OrgsTowards: record?.OrgsTowards,
    }

    CsvCreateParamMaintainAction.advanceSetitng(params)
      .then((res) => {
        let data = {
          ...res,
          ConditionNumCopy: res?.ConditionNum === 0 ? '' : res?.ConditionNum
        }
        this.formRef.current?.setFieldsValue(data)
        this.setState({
          advanceSettingDatas: data,
          ExcelFlag: this.props.record?.OutputType == 1 ? false : true
        })
      })
      .finally(() => this.setState({ isLoadingForm: false }))
  }

  saveData = () => {
    let params = {
      ...this.formRef.current?.getFieldValue()
    }

    CsvCreateParamMaintainAction.saveData(params)
      .then((res) => {
        this.props.onFinishScreen(res)
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource];

    data[index][field] = value;

    this.setState({
      dataSource: data
    });
  }

  uploadFile(file) {
    this.formRef.current?.setFieldsValue({
      CsvFile: file
    })
    this.forceUpdate()
  }

  getNameCondition() {
    let params = {
      ConditionNum: this.formRef.current?.getFieldValue('ConditionNum')
    }
    CsvCreateParamMaintainAction.getNameCondition(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          name: res?.data?.name
        })

        this.setState({
          nameCondition: res?.data?.name
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  searchFormat = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <WS0451001_UserParamQuery
            Lio_Format={this.formRef.current?.getFieldValue('Format')}
            onFinishScreen={(output) => {
              console.log(output)
              this.formRef.current?.setFieldsValue({ Format: output.format })
              this.closeModal()
            }} />
        ,
      },
    });
  }

  updateCourse(course) {
    let params = {
      IH_Course: this.formRef.current?.getFieldValue('Course'),
      IV_Course: course
    }
    CsvCreateParamMaintainAction.gzoomCource(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          Course: res?.Course
        })
      })
  }

  ExcelFlagCheck(event) {
    let value = event.target.value;
    if (value == 1) {
      this.setState({
        ExcelFlag: false
      })
    } else {
      this.setState({
        ExcelFlag: true
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render = () => {
    return (
      <div className="csv-create-param-details" style={{ width: 1400 }}>
        <Card title='編集'>
          <Form ref={this.formRef} autoComplete="off">
            <div className="box_container">
              <div className="box_inner_horizontal">
                <div className="box_inner_vertical" style={{ borderRight: '1px solid #cfcfcf', width: '45%' }}>
                  <Form.Item label='No' name='No' labelCol={labelCol}>
                    <InputNumber className="input-size-number-2" />
                  </Form.Item>
                  <Form.Item label='FORMAT' name='Format' labelCol={labelCol}>
                    <Input.Search
                      className="input-size-20"
                      onPressEnter={() => this.searchFormat()}
                      onSearch={(value, event) => {
                        if (event.target.localName === 'input') {
                          this.formRef.current?.setFieldsValue({
                            Format: ''
                          })
                          this.forceUpdate()
                        } else {
                          this.searchFormat()
                        }
                      }}
                      onBlur={(e) => {

                        let value = e.target.value
                        value = value.replace('　', ' ')

                        this.formRef.current?.setFieldsValue({ Format: value })

                      }}
                    />
                  </Form.Item>
                  <Form.Item label='帳票名' name='DocumentName' labelCol={labelCol}>
                    <Input className="input-size-40"
                      onBlur={(e) => {
                        let value = e.target.value
                        value = value.replace('　', ' ')
                        this.formRef.current?.setFieldsValue({ DocumentName: value })
                      }} />
                  </Form.Item>
                  <Form.Item label='個人情報' name='PersonalInfo' labelCol={labelCol}>
                    <Select
                      value={this.formRef.current?.getFieldValue('PersonalInfo')}
                      style={{ width: '100px' }}
                    >
                      <Select.Option value={1}> </Select.Option>
                      <Select.Option value={0}>あり</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="出力先" name="CsvFile" labelCol={labelCol}>
                    <Input
                      className="input-size-60"
                      onBlur={(e) => {
                        let value = e.target.value
                        value = value.replace('　', ' ')
                        this.formRef.current?.setFieldsValue({ CsvFile: value })
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="出力形式" name="OutputType" labelCol={labelCol}>
                    <Radio.Group
                      className="box_inner_horizontal"
                      onChange={(event) => this.ExcelFlagCheck(event)}
                    >
                      <Radio value={0} style={radioStyle}>CSV</Radio>
                      <Radio value={1} style={radioStyle}>Excel</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="テンプレート" name="Template" labelCol={labelCol}>
                    <Input
                      className="input-size-60"
                      disabled={this.state.ExcelFlag}
                      onBlur={(e) => {
                        let value = e.target.value
                        value = value.replace('　', ' ')
                        this.formRef.current?.setFieldsValue({ Template: value })
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="出力シート" name="Sheet" labelCol={labelCol}>
                    <Input
                      className="input-size-60"
                      disabled={this.state.ExcelFlag}
                      onBlur={(e) => {
                        let value = e.target.value
                        value = value.replace('　', ' ')
                        this.formRef.current?.setFieldsValue({ Sheet: value })
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="box_inner_vertical" style={{ width: '55%' }}>
                  <Tabs type='card'>
                    <Tabs.TabPane tab="内容" key={0}>
                      {/* <div style={{ ...labelStyle, marginTop: 0 }}>フォーマット</div> */}
                      <Form.Item
                        name="Csvhdr"
                        label='フォーマット'
                        labelCol={labelCol}
                        valuePropName="checked"
                        style={{ marginBottom: '5px' }}
                      >
                        <Checkbox><span >タイトルを出力する</span></Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="Fix"
                        label='　'
                        labelCol={labelCol}
                        valuePropName="checked"
                      >
                        <Checkbox><span>固定長で出力する</span></Checkbox>
                      </Form.Item>
                      {/* <div style={labelStyle}>区切</div> */}
                      <Form.Item
                        name="CsvFormat"
                        label='区切'
                        labelCol={labelCol}
                      >
                        <Radio.Group
                          style={{ marginBottom: '10px' }}
                          onChange={(e) => {
                            if (e.target.value === 2) {
                              this.setState({
                                showDelimiter: true
                              })
                            } else {
                              this.setState({
                                showDelimiter: false
                              })
                              this.formRef.current.setFieldsValue({
                                Delimiter: ''
                              })
                            }
                          }}
                        >
                          <Radio value={0} style={radioStyle}>「，」区切で出力する</Radio>
                          <Radio value={1} style={radioStyle}>各項目を「"」で囲む</Radio>
                          <Row>
                            <Radio value={2} style={{ ...radioStyle, width: 160 }}>区切文字を指定する</Radio>
                            <Form.Item name="Delimiter" style={{ width: 'calc(100% - 168px)', display: this.state.showDelimiter ? "" : "none" }}>
                              <Input type={this.state.showDelimiter ? "text" : "hidden"}></Input>
                            </Form.Item>
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                      {/* <div style={labelStyle}>個人番号</div> */}
                      <Form.Item
                        name="Id"
                        label='個人番号'
                        labelCol={labelCol}
                      >
                        <Radio.Group
                          style={{ marginBottom: '10px' }}
                        >
                          <Radio value={0} style={radioStyle}>健診の番号を出力する</Radio>
                          <Radio value={1} style={radioStyle}>患者番号を出力する</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {/* <div style={labelStyle}>年齢の算出</div> */}
                      <Form.Item
                        name="AgeCalculate"
                        label='年齢の算出'
                        labelCol={labelCol}
                      >
                        <Radio.Group
                          style={{ marginBottom: '10px' }}
                        >
                          <Radio value={0} style={radioStyle}>契約に設定された年齢</Radio>
                          <Radio value={1} style={radioStyle}>年度末の時点の年齢</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {/* <div style={labelStyle}>文字ｾｯﾄ</div> */}
                      <Form.Item
                        name="CharSet"
                        label='文字セット'
                        labelCol={labelCol}
                      >
                        <Radio.Group>
                          <Radio value={0} style={radioStyle}><span>ANSI形式で出力する</span></Radio>
                          <Radio value={1} style={radioStyle}><span>OEM 形式で出力する</span></Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="OrgsTowards"
                        label="団体向"
                        labelCol={labelCol}
                        style={{ marginTop: 20 }}
                      >
                        <Select style={{ width: 100 }}>
                          <Select.Option value=""> </Select.Option>
                          <Select.Option value="N">通常</Select.Option>
                          <Select.Option value="Y">団体</Select.Option>

                        </Select>
                      </Form.Item>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="絞込" key={1}>
                      {/* <div style={{ ...labelStyle, marginTop: 0 }}>状態</div> */}
                      <Form.Item label='状態' labelCol={rightLabelCol}>
                        {/* <Space> */}
                        <div className="box_inner_horizontal">
                          <Form.Item name="Accepted" valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox>受付</Checkbox>
                          </Form.Item>

                          <Form.Item name="Reserve" valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox>予約</Checkbox>
                          </Form.Item>
                          <Form.Item name="Pending" valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox>保留</Checkbox>
                          </Form.Item>
                          <Form.Item name="Waiting" valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox>キャンセル</Checkbox>
                          </Form.Item>
                        </div>
                        {/* </Space> */}
                      </Form.Item>
                      <br></br>
                      {/* <div style={labelStyle}>コースの一覧</div> */}
                      <Form.Item
                        name="_0Covered1Target"
                        label='コースの一覧'
                        labelCol={rightLabelCol}
                      >
                        <Radio.Group>
                          <Radio value={0} style={radioStyle}>対象外のコースを設定</Radio>
                          <Radio value={1} style={radioStyle}>対　象のコースを設定</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name="Course"
                        label='　'
                        labelCol={rightLabelCol}
                        style={{ marginTop: 8 }}
                      >
                        <TextArea rows={2}
                          onDoubleClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '70%',
                                component:
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={(output) => {
                                      this.updateCourse(output.Lo_CourseCode)

                                      this.closeModal()
                                    }} />
                                ,
                              },
                            });
                          }}
                          onBlur={(e) => {
                            let value = e.target.value
                            value = value.replace('　', ' ')
                            this.formRef.current?.setFieldsValue({ Course: value })
                          }}
                        />
                      </Form.Item>
                      {/* <div style={labelStyle}>保険記号の一覧</div> */}
                      <Form.Item
                        name="InsuranceSign"
                        label='保険記号の一覧'
                        labelCol={rightLabelCol}
                      >
                        <TextArea rows={2}
                          onBlur={(e) => {
                            let value = e.target.value
                            value = value.replace('　', ' ')
                            this.formRef.current?.setFieldsValue({ InsuranceSign: value })
                          }}
                        />
                      </Form.Item>
                      {/* <div style={labelStyle}>条件式</div> */}
                      <Form.Item
                        name="ConditionNum"
                        label='　'
                        labelCol={rightLabelCol}
                        hidden
                      ><Input /></Form.Item>
                      {/* <Space style={{ marginLeft: '20px' }} > */}
                      <Form.Item
                        label='条件式'
                        labelCol={rightLabelCol}
                      >
                        <div className="box_inner_horizontal">
                          <Form.Item
                            name="ConditionNumCopy"
                            style={{ width: '100px' }}
                          >
                            <Input.Search type='number' maxLength={4} style={{ textAlign: "right" }}
                              onSearch={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 600,
                                    component:
                                      <WS0495001_ConditionExpressInfoInquiry
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue({
                                            ConditionNum: output.Lo_ConditionSerialNum,
                                            name: output.Lo_Name,
                                            ConditionNumCopy: output.Lo_ConditionSerialNum === 0 ? '' : output.Lo_ConditionSerialNum
                                          })

                                          this.setState({
                                            nameCondition: output.Lo_Name,
                                          })
                                          this.closeModal()
                                        }} />
                                    ,
                                  },
                                });
                              }}
                              onBlur={(e) => {
                                let val = e.target.value
                                if (val.length > 4) {
                                  val = val.substring(0, 4)
                                }
                                if (val !== this.formRef.current?.getFieldValue('ConditionNum')) {
                                  this.formRef.current.setFieldsValue({
                                    ConditionNum: parseInt(val),
                                    ConditionNumCopy: parseInt(val) == 0 ? '' : parseInt(val),
                                  })

                                  this.getNameCondition()
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item name="name">
                            <span>{this.state.nameCondition}</span>
                          </Form.Item>
                        </div>
                      </Form.Item>
                      {/* </Space> */}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="出力順" key={2}>
                      {/* <div style={{ ...labelStyle, marginTop: 0, marginBottom: 8 }}>出力する順番</div> */}
                      <Form.Item label='出力する順番' labelCol={labelCol}>
                        <Form.Item name="Sort1" label="No.1">
                          <Select>
                            <Select.Option value=""></Select.Option>
                            <Select.Option value="健診コース">健診コース</Select.Option>
                            <Select.Option value="受診日">受診日</Select.Option>
                            <Select.Option value="カナ氏名">カナ氏名</Select.Option>
                            <Select.Option value="事業所コード">事業所コード</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="Sort2" label="No.2" >
                          <Select>
                            <Select.Option value=""></Select.Option>
                            <Select.Option value="健診コース">健診コース</Select.Option>
                            <Select.Option value="受診日">受診日</Select.Option>
                            <Select.Option value="カナ氏名">カナ氏名</Select.Option>
                            <Select.Option value="事業所コード">事業所コード</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="Sort3" label="No.3"  >
                          <Select>
                            <Select.Option value=""></Select.Option>
                            <Select.Option value="健診コース">健診コース</Select.Option>
                            <Select.Option value="受診日">受診日</Select.Option>
                            <Select.Option value="カナ氏名">カナ氏名</Select.Option>
                            <Select.Option value="事業所コード">事業所コード</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="Sort4" label="No.4" >
                          <Select>
                            <Select.Option value=""></Select.Option>
                            <Select.Option value="健診コース">健診コース</Select.Option>
                            <Select.Option value="受診日">受診日</Select.Option>
                            <Select.Option value="カナ氏名">カナ氏名</Select.Option>
                            <Select.Option value="事業所コード">事業所コード</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="Sort5" label="No.5" >
                          <Select>
                            <Select.Option value=""></Select.Option>
                            <Select.Option value="健診コース">健診コース</Select.Option>
                            <Select.Option value="受診日">受診日</Select.Option>
                            <Select.Option value="カナ氏名">カナ氏名</Select.Option>
                            <Select.Option value="事業所コード">事業所コード</Select.Option>
                          </Select>
                        </Form.Item>
                      </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="外部" key={3}>
                      {/* <div style={{ ...labelStyle, marginTop: 0 }}>プログラムの場所</div> */}
                      <Form.Item
                        name="Run"
                        label='プログラムの場所'
                        labelCol={rightLabelCol}
                      >
                        <Radio.Group>
                          <Radio value='N' style={radioStyle}>ネットワーク上のEXECフォルダ内</Radio>
                          <Radio value='L' style={radioStyle}>各端末のEXECフォルダ内</Radio>
                          <Radio value='D' style={radioStyle}>直接指定する</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {/* <div style={labelStyle}>起動方法</div> */}
                      <Form.Item
                        name="Type"
                        label='起動方法'
                        labelCol={rightLabelCol}
                      >
                        <Radio.Group>
                          <Radio value='H' style={radioStyle}>画面を表示しない</Radio>
                          <Radio value='N' style={radioStyle}>通常のサイズで起動する</Radio>
                          <Radio value='X' style={radioStyle}>最大化で起動する</Radio>
                          <Radio value='M' style={radioStyle}>最小化で起動する</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {/* <div style={labelStyle}>プログラムファイル</div> */}
                      <Form.Item
                        name="Expresstion_67"
                        label='プログラムファイル'
                        labelCol={rightLabelCol}
                      >
                        <TextArea rows={2} readOnly style={{ border: 'none', background: 'transparent' }}></TextArea>
                      </Form.Item>
                      <Form.Item
                        name="Exec"
                        label='　'
                        labelCol={rightLabelCol}
                      >
                        <TextArea rows={2}
                          onBlur={(e) => {
                            let value = e.target.value
                            value = value.replace('　', ' ')
                            this.formRef.current?.setFieldsValue({ Exec: value })
                            console.log('Exec:', value)
                          }}
                        ></TextArea>
                      </Form.Item>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => this.saveData()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0460501_CsvCreateParamDetails);