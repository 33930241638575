import { message } from "antd";
import RegisterModificationService from "services/InspectionMaintenance/CourseBasicTypeSetting/RegisterModificationService";

export const postRegisterBtnAction = (params) => {
  return RegisterModificationService.postRegisterBtnService(params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      const res = err.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
}
