import axios from 'configs/axios'

const API_LIST = {
  index: '/api/introduce-letter-issued-main/printer-confirm',
  print: '/api/introduce-letter-issued-main/printer-confirm/print',
  preview: '/api/introduce-letter-issued-main/printer-confirm/preview',
}

const PrinterConfirmService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async print(params) {
    return axios.post(API_LIST.print, params)
  },
  async preview(params) {
    return axios.post(API_LIST.preview, params)
  }
}

export default PrinterConfirmService
