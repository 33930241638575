import React from "react";
import { connect } from "react-redux";

import { Card, Col, Table, Tree, Row, Input, Form } from "antd";

const treeData = [
  {
    title: 'parent 1',
    key: '0-0',
    children: [
      {
        title: 'parent 1-0',
        key: '0-0-0',
        children: [
          {
            title: 'leaf',
            key: '0-0-0-0',
          },
          {
            title: 'leaf',
            key: '0-0-0-1',
          },
          {
            title: 'leaf',
            key: '0-0-0-2',
          },
        ],
      },
      {
        title: 'parent 1-1',
        key: '0-0-1',
        children: [
          {
            title: 'leaf',
            key: '0-0-1-0',
          },
        ],
      },
      {
        title: 'parent 1-2',
        key: '0-0-2',
        children: [
          {
            title: 'leaf',
            key: '0-0-2-0',
          },
          {
            title: 'leaf',
            key: '0-0-2-1',
          },
        ],
      },
      {
        title: 'parent 1-3',
        key: '0-0-3',
        children: [
          {
            title: 'leaf',
            key: '0-0-3-0',
          },
          {
            title: 'leaf',
            key: '0-0-3-1',
          },
        ],
      },
      {
        title: 'parent 1-4',
        key: '0-0-4',
        children: [
          {
            title: 'leaf',
            key: '0-0-4-0',
          },
          {
            title: 'leaf',
            key: '0-0-4-1',
          },
        ],
      },
      {
        title: 'parent 1-5',
        key: '0-0-5',
        children: [
          {
            title: 'leaf',
            key: '0-0-5-0',
          },
          {
            title: 'leaf',
            key: '0-0-5-1',
          },
        ],
      },
      {
        title: 'parent 1-6',
        key: '0-0-6',
        children: [
          {
            title: 'leaf',
            key: '0-0-6-0',
          },
          {
            title: 'leaf',
            key: '0-0-6-1',
          },
        ],
      },
    ]
  }
];

class WS1515001_OptionsItemInfoMaintain extends React.Component {

  constructor(props) {
    super(props);

    // document.title = 'オプションアイテム情報保守';

    this.state = {
    };
  }

  render() {
    return (
      <div className="options-item-info-maintain">
        <Card className="mb-2" title="オプションアイテム情報保守">
          <Row>
            <Col span={6}>
              <Row>
                <Form.Item name="" label="識別名検索">
                  <Input ></Input>
                </Form.Item>
              </Row>
              <Row>
                <Tree
                  defaultExpandAll
                  defaultSelectedKeys={[treeData[0].key]}
                  treeData={treeData}
                  onSelect={this.onSelectNode}
                />
              </Row>

              <div className="card-custom">
                <div className="scrollbar" style={{ overflow: 'auto', height: 'auto' }}>

                </div>
              </div>
            </Col>
            <Col span={18}>

              <Table bordered >
                <Table.Column title="連番" dataIndex="" key="" />
                <Table.Column title="種別コード" dataIndex="" key="" />
                <Table.Column title="識別名称" dataIndex="" key="" />
                <Table.Column title="補足説明" dataIndex="" key="" />
              </Table>

              <Table bordered >
                <Table.Column title="連番" dataIndex="" key="" />
                <Table.Column title="オプションコード" dataIndex="" key="" />
                <Table.Column title="識別名称" dataIndex="" key="" />
                <Table.Column title="補足説明" dataIndex="" key="" />
              </Table>

              <Table bordered >
                <Table.Column title="連番" dataIndex="" key="" />
                <Table.Column title="項目" dataIndex="" key="" />
                <Table.Column title="アイテム制限" dataIndex="" key="" />
                <Table.Column title="備考" dataIndex="" key="" />
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1515001_OptionsItemInfoMaintain);
