import { message } from "antd";
import SpecificDatePeopleNumSettingService from "services/SystemMaintenance/SpecificDatePeopleNumSetting/SpecificDatePeopleNumSettingService";

const SpecificDatePeopleNumSettingAction = {
  index() {
    return SpecificDatePeopleNumSettingService.index()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getDateInfo(data) {
    return SpecificDatePeopleNumSettingService.getDateInfo(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteSpecificNumPeople(data) {
    return SpecificDatePeopleNumSettingService.deleteSpecificNumPeople(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getNumPeople(data) {
    return SpecificDatePeopleNumSettingService.getNumPeople(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  updateNumPeople(data) {
    return SpecificDatePeopleNumSettingService.updateNumPeople(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  setClosedDay(data) {
    return SpecificDatePeopleNumSettingService.setClosedDay(data)
  }
}

export default SpecificDatePeopleNumSettingAction;
