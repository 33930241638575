/* eslint-disable no-useless-concat */
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Input, Form, Button, Card, Tooltip } from "antd";
import { PlusOutlined, DeleteFilled, SaveOutlined } from '@ant-design/icons';

import EscortDetailSubAction from "redux/AdvancePreparation/DocumentManageMaintain/EscortDetailSub.action";

const labelCol = {
  labelCol: { style: { width: 50 } },
}
class WS0641003_EscortDataLineEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      change: true,
      selectList: [],
    }
    this.addSelectItem = this.addSelectItem.bind(this)
  }

  /**
  * 初期表示処理
  */
  componentDidMount() {
    // 選択項目 入力用
    this.setState({
      selectList: this.props.LineInfo ?? []
    })
    this.formRef.current.setFieldsValue({ selectFormList: this.props.LineInfo })
  }

  /**
  * 追加ボタン押下処理
  * 選択肢を追加する
  */
  addSelectItem = () => {
    // 既に設定されている選択項目情報を取得
    let arrTemp = [...this.formRef.current?.getFieldValue('selectFormList')]
    let obj = {
      value: arrTemp.length + 1,
      label: ''
    }
    arrTemp.push(obj)

    // フィールドにデータ反映
    this.formRef.current.setFieldsValue({ combo_box: arrTemp })
    // 選択項目 データ反映
    this.setState({
      selectList: arrTemp,
    })
  }

  /**
  * 選択肢の削除ボタン押下処理
  * @param {*} deleteIndex
  */
  deleteSelectItem = (deleteIndex) => {
    console.log(deleteIndex);
    // 既に設定されている選択項目情報を取得
    let arrTemp = [...this.formRef.current?.getFieldValue('selectFormList')]

    // 該当箇所を削除
    arrTemp.splice(deleteIndex, 1)

    this.formRef.current.setFieldsValue({ selectFormList: arrTemp })
    this.setState({
      selectList: arrTemp,
    })
  }

  /**
   * 新規の状詰めの情報を追加
   */
  preUpdate() {
    let params = {
      List: this.formRef.current?.getFieldValue('selectFormList'),
      id: this.props.id
    }

    EscortDetailSubAction.updateLineDataTable(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={"エスコート保守　内容設定 [ 行詰 - 編集 ]"} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div
              style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}
            >
              {(this.state.selectList.length > 0) ?
                '※ 行詰の開始位置と行詰め範囲を入力してください'
                :
                '※ 設定なし'}
              <Tooltip title='行詰範囲を追加'>
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={this.addSelectItem}
                >
                  追加
                </Button>
              </Tooltip>
            </div>

            {this.state.selectList.map((item, index) => {
              return (
                <div
                  key={`select_combo_${index}`}
                  style={{
                    width: '100%',
                    display: 'flex',
                    gap: '10px',
                  }}
                >

                  <div style={{ marginRight: 10 }} />

                  {/* 選択肢 */}
                  <Form.Item
                    label={'開始位置'}
                    name={['selectFormList', index, 'start']}
                  >
                    <Input className="input-size-number-5 " />
                  </Form.Item>

                  <div style={{ marginRight: 20 }} />

                  <Form.Item
                    label={'範囲'}
                    name={['selectFormList', index, 'range']}
                  >
                    <Input className="input-size-number-5 " />
                  </Form.Item>

                  {/* 選択肢の削除ボタン */}
                  <Tooltip title='削除'>
                    <Button
                      size='small'
                      type='text'
                      style={{ marginTop: 5 }}
                      icon={<DeleteFilled style={{ fontSize: 16, color: '#666' }} />}
                      onClick={() =>
                        this.deleteSelectItem(index)
                      }
                    />
                  </Tooltip>
                </div>
              )
            })}

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.preUpdate()
                }}
                disabled={this.state.disabledBtn}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form >
        </Card >

        < ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0641003_EscortDataLineEdit);
