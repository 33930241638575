import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, } from "antd";
import WS1314008_DateDisplay from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314008_DateDisplay";
import  ModalDraggable  from "components/Commons/ModalDraggable";

class WS1314007_CsvOutputScreen extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'CSV出力画面';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="csv-output-screen">
        <Card className="mb-2" title="CSV出力画面">
          <Form
            ref={this.formRef} labelCol={{ span: 2 }}
            onFinish={this.onFinish}
          >
            <Form.Item
              name=""
              label="日  付"
            >
              <Input.Search
                type="text"
                onSearch={() => 
                  this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 400,
                    component: (
                      <WS1314008_DateDisplay
                      />),
                  }
                })}
              />
            </Form.Item>
            <Form.Item
              name=""
              label="出力先"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
            >
              <Button style={{ float: 'right',margin:'10px 10px 0 0 '}} type="primary">実  行</Button>
            </Form.Item>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314007_CsvOutputScreen);
