import axios from "configs/axios";

const groupPath = "/api/specific-health-data-xml-output";

const APP_LIST = {
  getScreenData: `${groupPath}/print-process/`,
  index: `${groupPath}/result-confirm_WS2655106/`,
  F11_KeyEvent: `${groupPath}/result-confirm_WS2655106/f11_keyevent`,
};
const PrintProcess = {
  async getScreenData(params) {
    return axios.post(APP_LIST.getScreenData, params);
  },
  async index(params) {
    return axios.get(APP_LIST.index, { params });
  },
  async F11_KeyEvent(params) {
    return axios.post(APP_LIST.F11_KeyEvent, params);
  },
};

export default PrintProcess;
