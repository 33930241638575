import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, message } from 'antd'
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import OcrCaptureStartUpAction from 'redux/CooperationRelated/OcrCaptureStartUp/OcrCaptureStartUp.action'
import ModalDraggable from 'components/Commons/ModalDraggable'
// import { saveAs } from 'file-saver'
import { download_file } from 'helpers/CommonHelpers'


class WS3108002_OcrResultConfirm extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '結果確認'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // this.getScreenData()
    // console.log(this.props);
  }

  // getScreenData() {
  //   // 親画面からパラメータ設定
  //   let param = {
  //     ErrorPeopleNum: this.props.ErrorPeopleNum,
  //     CheckErrorPeopleNum: this.props.CheckErrorPeopleNum,
  //     Visits_UserTotal: this.props.Visits_UserTotal
  //   }
  //   ResultConfirmAction.getScreenData(param)
  //     .then((res) => {
  //       this.setState({
  //         Expresstion_14: res.Expresstion_14,
  //         StsConfirm: res.StsConfirm,
  //         End: res.End,
  //         Confirm: res.Confirm,
  //         Confirmation: res.StsConfirm ? true : false,
  //       })
  //       this.formRef.current?.setFieldsValue({ Confirmation: res.StsConfirm ? true : false })
  //     })
  // }

  // LogDisplay() {
  //   let param = {
  //     Goutput: this.props.Goutput
  //   }
  //   ResultConfirmAction.LogDisplay(param)
  //     .then((res) => {
  //       download_file(res)
  //     })
  // }


  /**
   * ログをダウンロード
   * @param {*} filePath
   */
  downloadLog = (filePath) => {
    let param = {
      filePath: filePath
    }
    OcrCaptureStartUpAction.downloadLog(param)
      .then((res) => {
        download_file(res)
      })
      .catch((error) => {
        message.error('ログファイルのダウンロードに失敗しました')
        console.log(error);
      })
  }


  // xml() {
  //   let param = {
  //     RootFolderName: this.props.RootFolderName,
  //     Goutput: this.props.Goutput,
  //     fileName: this.props.fileName
  //   }
  //   ResultConfirmAction.xml(param)
  //     .then((res) => {
  //       this.downloadXml(res.outputPath)
  //     })
  // }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='ocr-result-confirm'>
        <Card title='結果確認'>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, paddingBottom: 10 }}>
            {this.props.updateFlag ?
              <ExclamationCircleOutlined
                style={{ color: '#00f', fontSize: 24 }}
              />
              :
              <CloseCircleOutlined
                style={{ color: '#f00', fontSize: 24 }}
              />
            }
            {this.props.message}
          </div>

          <div className='box_button_bottom_right'>
            {this.props.updateFlag ?
              <Button
                type='primary'
                onClick={() => {
                  this.downloadLog(this.props.logPath ?? '')
                }}
              >
                <span className='btn_label'>
                  ログ
                </span>
              </Button>
              :
              null
            }
            {this.props.errorLogFlag ?
              <Button
                type='primary'
                onClick={() => {
                  this.downloadLog(this.props.errorLogPath ?? '')
                }}
              >
                <span className='btn_label'>
                  エラー内容
                </span>
              </Button>
              :
              null
            }
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS3108002_OcrResultConfirm)
