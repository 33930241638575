import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/query-name-of-disease',
}

const QueryNameOfDiseaseService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },

}

export default QueryNameOfDiseaseService
