import React from "react";
import { connect } from "react-redux";
import { Card, Form, Table, Button, message, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";

import {
  getScreenDataInspectListSettingSubAction,
  getDataInspectListSettingSubAction,
  localAcquisitionInspectListSettingSubAction,
  saveAndUpdateInspectListSettingSubAction,
  deleteInspectListSettingSubAction
} from "redux/CooperationRelated/EMedicalRecordsInspectRequestMaintain/InspectListSettingSub.actions";
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';


class WS0102001_InspectListSettingSub extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '検査一覧設定SUB';

    this.formRef = React.createRef();
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      arrLo_ExamList: []
    };
  }

  componentDidMount = () => {
    const { Li_Title, Lio_ExamList } = this.props;
    this.loadInitData({
      Li_Title,
      Lio_ExamList
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { Li_Title, Lio_ExamList } = this.props;
      this.loadInitData({
        Li_Title,
        Lio_ExamList,
        InspectOr: Lio_ExamList
      });
    }
  }

  returnData = () => {
    localAcquisitionInspectListSettingSubAction()
      .then(res => {
      })
  }

  loadInitData = (params) => {
    getScreenDataInspectListSettingSubAction(params)
      .then((res) => {
        if (res?.data) {
          this.setState({
            dataSource: res.data,
            arrLo_ExamList: res.data.map(item => res.data.W1_inspect_cd)
          });
          this.formRef?.current?.setFieldsValue({ 'dataSource': res.data });
        }
      })

      .catch((err) =>
        message.error('エラー')
      )
  }

  setDataToForm = ({ Lio_InspectItemCode, recordData }, record) => {
    let index = this.findIndexByID(this.state.dataSource, record.id);
    let arrTemp = [...this.state.dataSource];
    let arrTempLo_ExamList = [...this.state.arrLo_ExamList];
    let isAdd = arrTemp.findIndex(item => item.W1_inspect_cd === Lio_InspectItemCode);
    if (isAdd === -1) {
      record = {
        ...record,
        W1_inspect_cd: Lio_InspectItemCode,
        exam_short_name: recordData.exam_short_name,
        exam_name: recordData.exam_name,
        exam_type: recordData.exam_type
      }
      arrTempLo_ExamList[index] = Lio_InspectItemCode.toString().padStart(8, '0');
      this.createOrUpdateData(record);
    } else {
      message.error('データが存在します');
    }
    arrTemp[index] = record;
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      dataSource: arrTemp,
      rowSelect: record,
      arrLo_ExamList: arrTempLo_ExamList
    });
    this.formRef.current.setFieldsValue({ 'dataSource': arrTemp });
  }

  createOrUpdateData = (params) => {
    saveAndUpdateInspectListSettingSubAction(params)
      .then(res => {
        message.success('成功');
        this.loadData();
      })
      .catch(err => message.error('エラー'))
  }

  deleteData = (record, index) => {
    if (record.id) {
      deleteInspectListSettingSubAction({ id: record.id })
        .then(res => {
          message.success('成功');
          this.loadData();
        })
        .catch(err => message.error('エラー'))
    } else {
      let arrTemp = [...this.state.dataSource];
      arrTemp.splice(index, 1);
      this.setState({ dataSource: arrTemp });
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  }

  render() {
    return (
      <div className="inspect-list-setting-sub">
        <Card className="mb-2"
          title={'対象の検査'}>
          <Form ref={this.formRef}>
            <Table
              size="small"
              bordered={true}
              dataSource={this.state.dataSource}
              loading={this.state.isLoading}
              pagination={{
                ...this.state.pagination,
                hideOnSinglePage: this.state.dataSource.length > 10 ? false : true
              }}
              rowKey={(record) => record.id}
              onRow={(record, index) => ({ onClick: event => this.setState({ rowSelect: record }) })}
            >
              <Table.Column
                title="検査コード"
                dataIndex="W1_inspect_cd"
                width={100}
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>)}
              />
              <Table.Column
                title="略式名称"
                dataIndex="exam_short_name"
              />
              <Table.Column
                title="正式名称"
                dataIndex="exam_name"
              />
              <Table.Column
                title="タイプ"
                dataIndex="exam_type"
                align='center'
                width={60}
              />
              <Table.Column
                align='center'
                width={40}
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1000,
                          component:
                            <WS0271001_InspectItemSearchQuerySingle
                              onFinishScreen={(obj) => {
                                this.props.onFinishScreen(obj);
                                this.closeModal()
                              }} />
                        }
                      })
                    }}
                  />
                }
                render={(record) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="削除"
                            onClick={() => {
                              this.props.onDelete(record);
                            }}
                          >削除
                          </Menu.Item>
                        </Menu>
                      )
                      }
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0102001_InspectListSettingSub);
