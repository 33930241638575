import React from "react";
import { connect } from "react-redux";
import AgencyInquirySubAction from "redux/SpecificMedicalExamination/ConsultTicketInputProcessList/AgencyInquirySub.action";
import { Card, Form, Input, Button, message } from "antd";

const labelCol = {
  labelCol: { style: { width: 95 } },
}
class WS1302005_AddCorrectScreen extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '追加・訂正画面';

    this.state = {
    };
  }

  onFinish = (values) => {
    let params = {
      ...values,
      Pl1Add: this.props.Pl1Add,
      Pl1Type: this.props.Pl1Type,
      newFlag: this.props.newFlag
    }
    AgencyInquirySubAction.Registration(params)
      .then((res) => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingFm: false }));
  }

  componentDidMount = () => {
    this.initScreenData();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.Gl0_No !== prevProps.Gl0_No) {
      this.initScreenData();
    }
  }

  initScreenData = () => {
    const { Gl0_No, Gl0AgencyNum, Gl0AgencyName } = this.props
    this.formRef.current.setFieldsValue({
      _No: Gl0_No,
      AgencyNum: Gl0AgencyNum,
      AgencyName: Gl0AgencyName,
    });
  };


  render() {
    const { Gl0_No, Gl0AgencyNum, Gl0AgencyName } = this.props
    console.log(Gl0_No, Gl0AgencyNum, Gl0AgencyName)
    return (
      <div className="add-correct-screen">
        <Card title={this.props.newFlag ? "追加" : "訂正画面"}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="_No"
              label="No"
              {...labelCol}
            >
              <Input style={{ width: '80px' }} />
            </Form.Item>
            <Form.Item
              name="AgencyNum"
              label="代行機関番号"
              {...labelCol}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="AgencyName"
              label="代行機関名称"
              {...labelCol}
            >
              <Input />
            </Form.Item>
            <Form.Item
            >
              <div className="box_button_bottom_right">
                <Button type="primary" htmlType="submit">登録</Button>
              </div>
            </Form.Item>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1302005_AddCorrectScreen);
