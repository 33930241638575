import { message } from 'antd'
import RadiographyJudgeMaintainService from 'services/InputBusiness/RadiographyJudgeMaintain/RadiographyJudgeMaintainService'

const RadiographyJudgeMaintainAction = {
  index() {
    ////確かめ方
    ////console.log(RadiographyJudgeMaintainService.index())
    // return RadiographyJudgeMaintainService.index()
    return RadiographyJudgeMaintainService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteRadioInfo(data) {
    console.log("アクションdel")
    return RadiographyJudgeMaintainService.deleteRadioInfo(data)
      .then((res) => {
        console.log(res)
        console.log("12345")
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveRadioInfo(data) {
    console.log("アクションsave")
    return RadiographyJudgeMaintainService.saveRadioInfo(data)
      .then((res) => {
        console.log(res)
        console.log("あああああ")
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  //   excelReport() {
  //     return RadiographyJudgeMaintainService.excelReport()
  //       .then((res) => {
  //         return res
  //       })
  //       .catch((err) => {
  //         const res = err.response
  //         if (!res || !res.data || !res.data.message) {
  //           message.error('エラーが発生しました')
  //           return
  //         }
  //         message.error(res.data.message)
  //       })
  //   },

  //   changeLogicAction(params) {
  //     return RadiographyJudgeMaintainService.changeLogicService(params)
  //   },

  //   changeLogicAllAction(params) {
  //     return RadiographyJudgeMaintainService.changeLogicAllService(params)
  //   }
}
export default RadiographyJudgeMaintainAction