import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insurer-number-conversion-master/insurer-number-conversion-master",
  save: "/api/insurer-number-conversion-master/insurer-number-conversion-master/save",
  delete: "/api/insurer-number-conversion-master/insurer-number-conversion-master/delete",
};

const InsurerNumberConversionMasterService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async save(params) {
    return axios.post(API_LIST.save, params);
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },
};

export default InsurerNumberConversionMasterService;
