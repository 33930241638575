import React from 'react'
import { connect } from 'react-redux'

import { Button, Card, Form, Input, Table, Space, Row } from 'antd'
import {
  MoreOutlined, PlusOutlined, CheckOutlined, SearchOutlined
} from '@ant-design/icons'
import { getScreenData } from 'redux/MN_Basis/MenuCustomization/MyMenu.action'
import ModalDraggable from 'components/Commons/ModalDraggable'


const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class WS2692011_MyMenu extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'マイメニュー'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initValue: {
        SearchChar: '',
        ExamCode: ''
      },
      dataSource: [],
      cardInfo1: [],
      cardInfo2: [],
      cardInfo3: [],
      cardInfo4: [],
      isLoadingPage: false,
      colorText: 208,

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount = () => {
    this.loadDataAction();
  }

  loadDataAction = () => {
    let param = {
      userName: this.props.userName
    }
    getScreenData(param)
      .then(res => {
        if (res) {
          let data = res.data;
          this.setState({
            dataSource: data,
          })
          this.getCardStatus(data)
        }
      })
      .catch()
  }

  getCardStatus = (data) => {
    let tempArray1 = [];
    let tempArray2 = [];
    let tempArray3 = [];
    let tempArray4 = [];
    for (var i = 0; i < 40; i++) {
      let obj = new Object;
      obj.width = '100%';
      obj.textAlign = 'left';
      obj.height = '100%';

      if (data[i]) {
        //名前あり、リンクあり
        if (data[i].name && data[i].exe) {
          obj.name = data[i].name;
          obj.exe = data[i].exe;
          obj.hover = true;
          obj.backgroundColor = "white";
          obj.color = "black"
          //名前のみ（ヘッダー）
        } else if (data[i].name && !data[i].exe) {
          obj.name = data[i].name;
          obj.exe = "";
          obj.hover = false;
          obj.backgroundColor = "#81c784";
          obj.color = "white"
          //リンクだけの場合（何もないのと同じ動き）
        } else {
          obj.name = '　';
          obj.exe = "";
          obj.hover = false;
          obj.backgroundColor = "white";
          obj.color = "white"
        }
        //そもそも行が存在しない場合
      } else {
        obj.name = '　';
        obj.exe = "";
        obj.hover = false;
        obj.backgroundColor = "white";
        obj.color = "white"

      }
      //データを４分割
      if (i < 10) {
        tempArray1.push(obj)
      } else if (i < 20) {
        tempArray2.push(obj)
      } else if (i < 30) {
        tempArray3.push(obj)
      } else if (i < 40) {
        tempArray4.push(obj)
      }
    }
    this.setState({
      cardInfo1: tempArray1,
      cardInfo2: tempArray2,
      cardInfo3: tempArray3,
      cardInfo4: tempArray4,
    })
  }

  openMenu = (exe) => {
    if (exe !== '') {
      //そのまま画面遷移
      // window.location.href = location.protocol + '//' + location.host + record.exe;
      //別タブ
      window.open(location.protocol + '//' + location.host + exe)
      //別ウィンドウ
      // window.open(location.protocol + '//' + location.host + record.exe, null, 'top=100, left=300, width=1400, height=900')
    }
  }

  reviewScreen() {
    this.loadData(this.state.initValue)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='my-menu'>
        <Card title='マイメニュー'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            {/* <div className="box_inner_horizontal" style={{ height: 750, overflowY: 'auto' }}> */}
            <div className="box_inner_horizontal" style={{ height: 700 }}>
              <div className='box_inner_vertical_label_top' >

                {this.state.cardInfo1.map((item, index) => (
                  <div key={index}>
                    <Card.Grid
                      style={{
                        width: item.width,
                        textAlign: item.textAlign,
                        height: item.height,
                        color: item.color,
                        backgroundColor: item.backgroundColor
                      }}

                      hoverable={item.hover}
                      onClick={(e) => { this.openMenu(item.exe) }}>
                      {item.name}</Card.Grid>
                  </div>
                ))
                }

              </div>
              <div className='box_inner_vertical_label_top'>
                {this.state.cardInfo2.map((item, index) => (
                  <div key={index}>
                    <Card.Grid
                      style={{
                        width: item.width,
                        textAlign: item.textAlign,
                        height: item.height,
                        color: item.color,
                        backgroundColor: item.backgroundColor
                      }}
                      hoverable={item.hover}
                      onClick={(e) => { this.openMenu(item.exe) }}>
                      {item.name}</Card.Grid>
                  </div>
                ))
                }
              </div>
              <div className='box_inner_vertical_label_top'>
                {this.state.cardInfo3.map((item, index) => (
                  <div key={index}>
                    <Card.Grid
                      style={{
                        width: item.width,
                        textAlign: item.textAlign,
                        height: item.height,
                        color: item.color,
                        backgroundColor: item.backgroundColor
                      }}
                      hoverable={item.hover}
                      onClick={(e) => { this.openMenu(item.exe) }}>
                      {item.name}
                    </Card.Grid>
                  </div>
                ))
                }
              </div>
              <div className='box_inner_vertical_label_top'>
                {this.state.cardInfo4.map((item, index) => (
                  <div key={index}>
                    <Card.Grid
                      style={{
                        width: item.width,
                        textAlign: item.textAlign,
                        height: item.height,
                        color: item.color,
                        backgroundColor: item.backgroundColor
                      }}
                      hoverable={item.hover}
                      onClick={(e) => { this.openMenu(item.exe) }}>
                      {item.name}</Card.Grid>
                  </div>
                ))
                }
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2692011_MyMenu)
