import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { SearchOutlined, MenuOutlined, SendOutlined, MoreOutlined } from '@ant-design/icons'
import { Card, Form, Button, Tooltip, Table, message, Dropdown, Menu, Checkbox, Modal } from 'antd'
import moment from 'moment'
import Color from 'constants/Color'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS2745009_ConfirmScreen from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2745009_ConfirmScreen.jsx'
import WS2739001_ErrorConfirmSub from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2739001_ErrorConfirmSub.jsx'
import WS2740001_ExtractBatchProcess from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2740001_ExtractBatchProcess.jsx'
import WS2749001_CheckAvailability from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2749001_CheckAvailability.jsx'
import WS2750001_MiraisTransmissionRecord from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2750001_MiraisTransmissionRecord.jsx'
import WS2737005_MiraisSingleDoctorSetting from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737005_MiraisSingleDoctorSetting.jsx'
import MiraisElectronicMedicalRecordsSentAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisElectronicMedicalRecordsSent.actions'
import WS2751001_MiraisInspectMaintain from './WS2751001_MiraisInspectMaintain'
import WS2752001_MiraisReserveMaintain from './WS2752001_MiraisReserveMaintain'
import WS2753001_MiraisImplementorMaintain from './WS2753001_MiraisImplementorMaintain'
import WS2737001_MiraisSingleTransmission from '../FJYM01100_MiraisSingleTransmission/WS2737001_MiraisSingleTransmission'

class WS2736001_MiraisElectronicMedicalRecordsSent extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = 'Mirais電子カルテ送信'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],

      MiraisStartupCheck: false,
      MiraisStartupStatus: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    console.log(this.props);
    if (this.props.DateFChar || this.props.DateTChar) {
      this.formRef.current?.setFieldsValue({
        DateFChar: this.props.DateFChar,
        DateTChar: this.props.DateTChar,
        orderStartTimeAt: true
      })
      // データ取得
      this.index()
    }

    // Mirais端末の起動状態チェック
    MiraisElectronicMedicalRecordsSentAction.checkMiraisStartup()
      .then(res => {
        this.setState({
          MiraisStartupCheck: true,
          MiraisStartupStatus: res.message,
        })
        if (!this.props.DateFChar || !this.props.DateTChar) {
          // 親画面から日付が渡っていなかった場合
          // 設定されている日数を引いた範囲を初期表示として扱う
          this.formRef.current?.setFieldsValue({
            DateFChar: moment().subtract(res.indent, 'days'),
            DateTChar: moment()
          })
          this.forceUpdate()
        }
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * 検索ボタン押下処理
   * データ取得
   */
  index() {
    let data = {
      DateFChar: this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : '',
      DateTChar: this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : '',
      orderStartTimeAt: this.formRef.current?.getFieldValue('orderStartTimeAt') ? 1 : 0,
      reserve_nums: this.props.reserve_nums ? this.props.reserve_nums : null
    }
    MiraisElectronicMedicalRecordsSentAction.index(data)
      .then(res => {
        this.setState({ dataSource: res })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  /**
   * 再抽出
   */
  showWS2740001_ExtractBatchProcess = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS2740001_ExtractBatchProcess
            DateF={this.formRef.current?.getFieldValue('DateFChar')}
            DateT={this.formRef.current?.getFieldValue('DateTChar')}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 送信記録
   */
  showWS2750001_MiraisTransmissionRecord = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2750001_MiraisTransmissionRecord
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 空き状況
   */
  showWS2749001_CheckAvailability = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2749001_CheckAvailability
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 検査設定
   */
  InspectMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '75%',
        component: (
          <WS2751001_MiraisInspectMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 枠人数設定
   */
  ReserveMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2752001_MiraisReserveMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 実施者設定
   */
  ImplementorMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2753001_MiraisImplementorMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
  * 医師設定
  */
  doctorSetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component:
          <WS2737005_MiraisSingleDoctorSetting
          />,
      },
    })
  }

  /**
   * 確認画面
   */
  ShowWS2745009_ConfirmScreen = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS2745009_ConfirmScreen
            Li_ExamDateF={this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : ''}
            Li_ExamDateT={this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : ''}
            onFinishScreen={(output) => {
              if (output?.Lo_StsConfirm) {
                this.sendMirais(index)
              }
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 送信ボタン押下処理
   */
  preSendMirais = () => {
    // 送信処理 初回
    this.sendMirais(0)
  }


  /**
   * 送信処理　ループ　(id ごとにAPIを呼ぶ)
   * @param {*} index
   */
  sendMirais(index) {
    const list = [...this.state.dataSource]
    if (list.length === 0) return

    let params = {
      DateF: this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : '',
      DateT: this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : '',
      Li_StsConfirm: 0,
      Miraisdt0100Id: list[index].id ?? 0 //Miraisdt0100TransmissionContents の id
    }

    MiraisElectronicMedicalRecordsSentAction.sendMirais(params)
      .then(res => {
        if (res.Lo_StsRun) {
          // 送信結果を反映
          let tempArray = []
          list.forEach((obj, i) => {
            obj = { ...obj }
            if (i === index) {
              // 送信状態を更新
              obj.miraisSendStatus = res.miraisSendStatus ?? ''
              obj.miraisSendStatusTooltip = res.miraisSendStatusTooltip ?? ''
            }
            tempArray.push(obj)
          })
          this.setState({
            dataSource: tempArray
          })

          const nextIndex = index + 1
          if (list.length > nextIndex) {
            // ループ続行
            // 再びMirais送信をする
            this.sendMirais(nextIndex)

          } else {
            // ループ終了
            if (this.props.onFinishScreen) {
              this.props.onFinishScreen()
            }
            // データ再取得
            this.index()
          }
        } else {
          if (!(res.miraisStartup ?? false)) {
            // Mirais端末エラー
            Modal.error({
              title: 'Mirais端末エラー',
              content: res.message ?? '',
              okText: 'OK',
            })
            this.setState({
              MiraisStartupStatus: res.message ?? ''
            })
          } else {
            // 確認
            this.ShowWS2745009_ConfirmScreen(index)
          }
        }
      })
      .catch(error => {
        console.log(error);
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * Mirais単体送信
   * @param {*} record
   */
  sowWS2737001_MiraisSingleTransmission = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2737001_MiraisSingleTransmission
            Li_ReserveNum={record.reservation_number_medical_exam}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * マウント接続（テスト）
   */
  mountTest() {
    MiraisElectronicMedicalRecordsSentAction.mountTest()
      .then(res => {
        console.log(res);
      })
  }

  /**
   * ORACLE接続（テスト）
   */
  getOracleTest() {
    MiraisElectronicMedicalRecordsSentAction.getOracleTest()
      .then(res => {
        console.log(res);
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='mirais-electronic-medical-records-sent'>
        <Card title={'Mirais電子カルテ送信'}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.DateFChar || this.props.DateTChar) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.InspectMaintain}>
                      検査設定
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.ReserveMaintain}>
                      枠人数設定
                    </Menu.Item>
                    <Menu.Item key='3' style={{ width: '100%' }} onClick={this.ImplementorMaintain}>
                      実施者設定
                    </Menu.Item>
                    <Menu.Item key='4' style={{ width: '100%' }} onClick={this.showWS2740001_ExtractBatchProcess}>
                      再抽出
                    </Menu.Item>
                    <Menu.Item key='5' style={{ width: '100%' }} onClick={this.showWS2750001_MiraisTransmissionRecord}>
                      送信記録
                    </Menu.Item>
                    <Menu.Item key='6' style={{ width: '100%' }} onClick={this.showWS2749001_CheckAvailability}>
                      空き状況
                    </Menu.Item>
                    <Menu.Item key='7' style={{ width: '100%' }} onClick={() => this.doctorSetting()}>
                      医師設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{
              // DateFChar: moment().subtract(this.formRef.current?.getFieldValue('subtractValue'), 'days'),
              // DateTChar: moment()
            }}
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>

                <div className='box_inner_horizontal' style={{ width: 'fit-content' }}>
                  <div className='box_search'>
                    <div className='box_search_inner'>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='DateFChar' label='検査日'>
                          <VenusDatePickerCustom formRefDatePicker={this.formRef} />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item name='DateTChar'>
                          <VenusDatePickerCustom formRefDatePicker={this.formRef} />
                        </Form.Item>
                      </div>
                      <Form.Item
                        name='orderStartTimeAt'
                        label='時間枠管理のみ'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'>
                        <Checkbox />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.index()}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  className='box_search_btn'
                  style={{
                    display: (this.state.MiraisStartupCheck ? '' : 'none'),
                  }}
                >
                  <Tooltip title={this.state.MiraisStartupStatus}>
                    <div className='box_search_btn_inner'
                      style={{
                        fontWeight: 'bold',
                        color: (this.state.MiraisStartupStatus === '') ? 'black' : 'red',
                        textAlign: 'right',
                      }}
                    >
                      <div>{'※Mirais端末'}</div>
                      <div>{(this.state.MiraisStartupStatus === '') ? '正常' : 'エラー'}</div>
                    </div>
                  </Tooltip>
                </div>

              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(item) => item.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(140) }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () =>
                    this.sowWS2737001_MiraisSingleTransmission(record)
                })}
              >
                <Table.Column
                  title='検査日'
                  className='column-size-date'
                  dataIndex='order_start_date_on'
                  render={(text) => (<div>{moment(text).format('YYYY/MM/DD (ddd)')}</div>)}
                />

                <Table.Column
                  title='検査時間'
                  className='column-size-8'
                  dataIndex='order_start_time_at'
                  render={(value, record, index) =>
                    <div>{value === '00:00' ? '' : value}</div>
                  }
                />

                <Table.Column
                  title='処理'
                  className='column-size-5'
                  dataIndex='processingDivisionName'
                  render={(value, record, index) => {
                    let color = 211
                    switch (record.processing_division) {
                      case '1':
                        color = 211
                        break
                      case '3':
                        color = 209
                        break
                      case '6':
                        color = 209
                        break
                      default:
                        color = 211
                    }
                    return (
                      <div
                        style={{
                          color: Color(color).Foreground,
                          textAlign: 'center'
                        }}
                      >
                        {value}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  className='column-size-30'
                />

                <Table.Column
                  title='予約'
                  dataIndex='typeName'
                  className='column-size-10'
                />

                <Table.Column
                  title='検査'
                  dataIndex='exam_name'
                  className='column-size-30'
                />

                <Table.Column
                  title='送信状態'
                  dataIndex='miraisSendStatus'
                  className='column-size-6'
                  render={(text, record) => (
                    <Tooltip title={record.miraisSendStatusTooltip}>
                      <div style={{ textAlign: 'center' }}>{text}</div>
                    </Tooltip>
                  )}
                />

                <Table.Column
                  title='エラー内容'
                  dataIndex='error_text'
                  render={(value, record, index) =>
                    <div className='box_inner_horizontal'>
                      <Button shape='circle' size='small' style={{ background: 'red', minWidth: 16, height: 16, fontSize: 5 }}
                        hidden={!record.error_text}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <WS2739001_ErrorConfirmSub
                                  Li_ReserveNum={record.reservation_number_medical_exam}
                                  Li_ProcessDivision={record.processing_division}
                                  Li_Type={record.kind}
                                  Li_Identify={record.identification}
                                  Li_transmission_state={record.transmission_state}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            }
                          })
                        }}>
                        {'　'}
                      </Button>
                      <div>{record.error_text}</div>
                    </div>
                  }
                />
                <Table.Column
                  width={40}
                  fixed='right'
                  dataIndex=''
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          onClick={() => {
                            this.sowWS2737001_MiraisSingleTransmission(record)

                          }}
                        />
                      </div>
                    )
                  }}
                />
              </Table>
            </div>

            <div className='box_button_bottom_right'>
              {/* mount接続 テスト用　※あとで消す */}
              <Button
                type='primary'
                onClick={() => this.mountTest()}
                hidden
              >
                <span className='btn_label'>
                  マウント接続
                </span>
              </Button>

              {/* ORACL接続 テスト用　※あとで消す */}
              <Button
                type='primary'
                onClick={() => this.getOracleTest()}
                hidden
              >
                <span className='btn_label'>
                  ORACL接続
                </span>
              </Button>

              <Button
                icon={<SendOutlined />}
                type='primary'
                onClick={() =>
                  this.preSendMirais()
                  // this.SubmitBtnBefore()
                }
              >
                <span className='btn_label'>
                  送信
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2736001_MiraisElectronicMedicalRecordsSent)
