import { message } from 'antd'
import MutualAidSubjectBillingUnitPriceMaintainService from 'services/AccountingBusiness/MutualAidAssociationBilling/MutualAidSubjectBillingUnitPriceMaintainService.js'

const MutualAidSubjectBillingUnitPriceMaintainAction = {
  index() {
    return MutualAidSubjectBillingUnitPriceMaintainService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getAmountMoneyList(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.getAmountMoneyList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


  save(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.save(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },



  getItemSettings(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.getItemSettings(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getItemSpecify(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.getItemSpecify(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveItemSettings(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.saveItemSettings(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteItemSettings(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.deleteItemSettings(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveItemSpecify(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.saveItemSpecify(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deleteItemSpecify(data) {
    return MutualAidSubjectBillingUnitPriceMaintainService.deleteItemSpecify(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default MutualAidSubjectBillingUnitPriceMaintainAction
