/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Card, Form, Input, Select, Checkbox, Button, message, } from "antd";
import PrintSubService from "services/ResultOutput/ResultsTblCollectOutput/PrintSubService";

const grid = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

class WS0810025_PrintSub extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Style: PropTypes.any,
    PrinterNo: PropTypes.any,
    Preview: PropTypes.any,
    NumOfCopies: PropTypes.any,
    Groups: PropTypes.any,
    Division: PropTypes.any,
    StsL2: PropTypes.any,
    StsL3: PropTypes.any,
    StsScreenControl: PropTypes.any,
    StsSubmission: PropTypes.any,
    Lo_StsRun: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = "印刷SUB";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      screenData: {},
      dataSource: [
        {
          id: 1,
          Code: "1-00",
          DocumentName: 80,
        },
      ],
      screenControlFlag: true
    };
  }

  componentDidMount = () => {
    this.getScreenData();
  };

  getScreenData = () => {
    PrintSubService.getScreenDataService()
      .then((res) => {
        const { Style, PrinterNo, Preview, NumOfCopies, Groups, Division, StsL2, StsL3, StsScreenControl, StsSubmission } = this.props;
        this.setState({
          screenData: res.data,
          screenControlFlag: StsScreenControl
        });

        let ComboBox_PrinterNo = res?.data?.ComboBox_PrinterNo
        let PrinterNo_Check = ComboBox_PrinterNo.find(x => x.LinkedField == PrinterNo)

        this.formRef.current.setFieldsValue({
          Style,
          PrinterNo: PrinterNo_Check ? PrinterNo : '',
          Preview,
          NumOfCopies,
          Groups,
          Division,
          StsL2,
          StsL3,
          StsScreenControl,
          StsSubmission,
        });

      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };


  onCheck(value) {
    this.setState({
      screenControlFlag: value
    });
  }


  render() {
    return (
      <div className="print-sub">
        <Card title="印刷指示">
          <div className="box_container">
            <Form ref={this.formRef} onFinish={this.onFinish}>
              <div className="box_inner_vertical">
                <Form.Item
                  name="NumOfCopies"
                  label="部数"
                  {...grid}
                  style={{ marginBottom: 0 }}
                >
                  <Input type="number" style={{ width: "150px" }} />
                </Form.Item>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="Division"
                    label="コメント"
                    style={{ marginLeft: '3px', marginBottom: 0 }}
                  >
                    <Select
                      style={{ width: "150px" }}
                      disabled={!this.state.screenControlFlag}
                    >
                      <Select.Option value="0">通常</Select.Option>
                      <Select.Option value="1">シークレット</Select.Option>
                      <Select.Option value="2">全て</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="StsL2"
                    label=""
                    valuePropName="checked"
                    style={{ marginBottom: 0, marginLeft: '8px' }}
                  >
                    <Checkbox
                      disabled={!this.state.screenControlFlag}
                    >
                      L2
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="StsL3"
                    label=""
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox
                      disabled={!this.state.screenControlFlag}
                    >
                      L3
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="StsScreenControl"
                    label="画面制御"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox
                      onChange={(event) => {
                        this.onCheck(event.target.checked);
                      }}
                    ></Checkbox>
                  </Form.Item>
                </div>

                <Form.Item
                  name="StsSubmission"
                  label="提出用"
                  valuePropName="checked"
                  {...grid}
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>

                <Form.Item
                  name="Preview"
                  label="ﾌﾟﾚﾋﾞｭｰ"
                  valuePropName="checked"
                  {...grid}
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>

                <Form.Item
                  name="PrinterNo"
                  label="ﾌﾟﾘﾝﾀｰ"
                  {...grid}
                  style={{ marginBottom: 0 }}
                >
                  <Select>
                    {this.state.screenData.ComboBox_PrinterNo ?
                      this.state.screenData.ComboBox_PrinterNo.map(
                        (item, index) => {
                          return (
                            <Select.Option value={item.LinkedField} key={index}>
                              {item.DiplayField}
                            </Select.Option>
                          );
                        }
                      )
                      :
                      null
                    }
                  </Select>
                </Form.Item>

                <div className="box_button_bottom_right">
                  <Form.Item style={{ float: "right" }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        if (this.props.onFinishScreen) {
                          this.props.onFinishScreen({
                            Lo_StsRun: true,
                            ...this.formRef.current?.getFieldsValue(true),
                          });
                        }
                      }}
                    >
                      印刷
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </Card >
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS0810025_PrintSub);
