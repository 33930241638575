import { message } from "antd";
import InspectItemMasterService from "services/CooperationRelated/InspectItemMaster/InspectItemMasterService";

const InspectItemMasterAction = {
  targetList() {
    return InspectItemMasterService.getTargetList()

  },
  screenData() {
    return InspectItemMasterService.getScreenData()

  },
  displayBtn(data) {
    return InspectItemMasterService.displayBtn(data)

  },
  print_F11(data) {
    return InspectItemMasterService.print_F11(data)

  },
  run_F12_Before(data) {
    return InspectItemMasterService.run_F12_Before(data)

  },
  run_F12_After(data) {
    return InspectItemMasterService.run_F12_After(data)

  },
  selectAll(data) {
    return InspectItemMasterService.selectAll(data)

  },
  selectOne(data) {
    return InspectItemMasterService.selectOne(data)
  },

  selectOne_W1_urgent_specimen_flg(data) {
    return InspectItemMasterService.SelectOne_W1_urgent_specimen_flg(data)
  },

  numberingProcessAction(params) {
    return InspectItemMasterService.numberingProcessAction(params)
  },

  getInstitutionList(data) {
    return InspectItemMasterService.getInstitutionList()
  },
};

export default InspectItemMasterAction;
