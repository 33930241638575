import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { InternalInspectItemSearchQueryAction } from "redux/InspectionMaintenance/InspectItemInfoMaintain/InternalInspectItemSearchQuery.action";

import { Card, Form, Input, Button, Table, } from "antd";

class WS0253001_InternalInspectItemSearchQuery extends React.Component {
  static propTypes = {
    Li_SearchChar: PropTypes.any,
    onFinishSceen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '内部検査項目検索・照会';

    this.state = {
      isLoading: true,
      data: [],
      selectedRows: {},
      initParams: {
        SearchAbbreviation: ''
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: false,
        showSizeChanger: false,
      },
    };
  }

  componentDidMount = () => {
    this.callAPILoadData(this.state.initParams);
  }

  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    InternalInspectItemSearchQueryAction(params)
      .then((res) => {
        this.setState({ data: res });
      })
      .finally(() => this.setState({ isLoading: false }))
  }


  returnValue = (params) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        Lio_InternalInspectCode: params.internal_exam_code,
        recordData: params
      });
    }
  }

  keyPress(e) {
    if (e.key === 'Enter') {
      this.callAPILoadData({ SearchAbbreviation: e.target.value });
    }
  }


  render() {
    const { data, pagination } = this.state;
    return (
      <div className="internal-inspect-item-search-query">
        <Card title="内部検査項目検索・照会" className="mb-2">
          <Form ref={this.formRef} >
            <Form.Item name="SearchAbbreviation" label="検索略称">
              <Input onKeyPress={(e) => this.keyPress(e)} />
            </Form.Item>

            <Table bordered
              size="small"
              pagination={false}
              dataSource={data}
              scroll={{ y: 400 }}
              rowKey={record => record.id}
              onRow={(record, index) => ({
                onDoubleClick: () => {
                  this.returnValue(record)
                }
              })}
            >
              <Table.Column title="内部コード" dataIndex="internal_exam_code" />
              <Table.Column title="略名" dataIndex="exam_short_name" />
              <Table.Column title="名称" dataIndex="exam_name" />
            </Table>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0253001_InternalInspectItemSearchQuery);
