import React from "react";
import { connect } from "react-redux";

import { Card, Table, message, Button} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1314005_XmlMedicalExamItemRef extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'XML用健診項目参照';

    this.state = {
      dataSource: [],
      selectedRow: {}
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData = () => {
    VenusApiRoutesV2.callApi("API001314005002", {})
      .then(res => {
        this.setState({dataSource: res});
      })
      .catch(error => {
        console.log(error);
        message.error("エラーが発生しました");
      }).finally();
  };

  selectRecord = (record) => {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen({
        recordData: record
      });
    }
  }

  render() {
    return (
      <div className="xml-medical-exam-item-ref">
        <Card className="mb-2" title="XML用健診項目参照">
          <Table bordered 
            dataSource={this.state.dataSource}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}
            rowKey={(record) => record.id}
            rowSelection={{
              type: 'radio',
              onChange: async(selectedRowKeys, selectedRows) => {
                  await this.setState({
                    selectedRow: selectedRows[0]
                  })
              }
            }} 
          >
            <Table.Column title="採用日" dataIndex="order_number" showSorterTooltip={false} sorter={(a, b) => a.order_number - b.order_number}/>
            <Table.Column title="項目コード" dataIndex="item_code_jlac10_15" showSorterTooltip={false} sorter={(a, b) => a.item_code_jlac10_15.localeCompare(b.item_code_jlac10_15, 'jp')}/>
            <Table.Column title="結果識別" dataIndex="result_identification_jlac10_2" showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2.localeCompare(b.result_identification_jlac10_2, 'jp')}/>
            <Table.Column title="厚労省項目名" dataIndex="ministry_item_name" showSorterTooltip={false} sorter={(a, b) => a.ministry_item_name.localeCompare(b.ministry_item_name, 'jp')}/>
            <Table.Column title="方法 一ド" dataIndex="xml_exam_method_code" showSorterTooltip={false} sorter={(a, b) => a.xml_exam_method_code.localeCompare(b.xml_exam_method_code, 'jp')}/>
            <Table.Column title="検査方法" dataIndex="exam_methods" showSorterTooltip={false} sorter={(a, b) => a.exam_methods.localeCompare(b.exam_methods, 'jp')}/>
            <Table.Column title="XML表示名" dataIndex="xml_display_name" showSorterTooltip={false} sorter={(a, b) => a.xml_display_name.localeCompare(b.xml_display_name, 'jp')}/>
            <Table.Column title="テーブルタイプ" dataIndex="data_type" showSorterTooltip={false} sorter={(a, b) => a.data_type.localeCompare(b.data_type, 'jp')}/>

          </Table>
          <Button type="primary" style={{ float: 'right', marginTop: '1em' }}
            onClick={() => {
              this.selectRecord(this.state.selectedRow)
            }}>
              遝抿
            </Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314005_XmlMedicalExamItemRef);
