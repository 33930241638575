import React from "react";
import { connect } from "react-redux";
import {
  Card,
  Form,
  Input,
  Select,
  Table,
  Row,
  Col,
  Space,
  Checkbox,
  Button,
  Modal,
  message,
  Tooltip,
  Dropdown,
  Menu,
} from "antd";

import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MoreOutlined,
  MenuOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import AnotherInspectItemSettingCategoryService from "services/InspectionMaintenance/AnotherInspectItemSettingCategory/AnotherInspectItemSettingCategoryService";
import WS0267001_CategorySearchQuerySingle from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle";
import WS2705075_PatternSettingSub from "./WS2705075_PatternSettingSub";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS0865001_UseInspectInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0865001_UseInspectInquiry";
import WS0294001_UseInspectCheck from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS0294001_UseInspectCheck";
import WS0431001_CsvPreviewConfirm from "pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm";


class WS2705502_CategoryInquirySetting extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "カテゴリ別検査項目設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchCategorySettingsDisplayList: [],
      isLoadingTableLeft: false,
      isLoadingTableRight: false,
      PatternCodeDisplayList: [],
      record: {},
      newId: ''
    };
  }

  componentDidMount() {
    this.setState({
      record: this.props.record
    })
    this.formRef.current.setFieldsValue(this.props.record)
  }

  saveDataInspectSet = (record) => {
    AnotherInspectItemSettingCategoryService.saveDataInspectSetService({
      category_code: this.props.record.category_code,
      pattern_code: this.props.record.pattern_code,
      display_order: this.formRef.current.getFieldValue('display_order'),
      exam_code: this.formRef.current.getFieldValue('exam_code'),
      id: this.props.record.id === "" ? "" : this.props.record.id,
      exam_code_before: this.props.record.exam_code
    })
      .then((res) => {
        message.success("成功");
        this.savePatternInspection();
      })
      .catch((err) => {
        const res = err.response
        if (res.data.message) {
          // message.error("エラーが発生しました");
          Modal.error({
            content: res.data.message,
            icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />,
          })
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.props.onFinishScreen({})
      });
  };

  // Save = () => {
  //   this.props.onFinishScreen({
  //     Li_record: this.formRef.current.getFieldsValue(),
  //     Li_newId: this.state.newId
  //   })
  // }

  //ms0611を更新する
  savePatternInspection = () => {
    let params = { pattern: this.props.record.pattern_code }
    AnotherInspectItemSettingCategoryService.reconstructionPatternData(params)
  }



  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="category-inquiry-setting">
        <Card title="修正" className="mb-2">
          <div className="box_container">
            <Form ref={this.formRef} onFinish={this.onFinish}>
              <Form.Item name='display_order' label='　　　　順'>
                <Input className="input-size-number-7"></Input>
              </Form.Item>
              <Form.Item name='exam_code' label='検査コード'>
                {/* <Input.Search
                  className="input-size-number-10"
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "800px",
                        component: (
                          <WS0271001_InspectItemSearchQuerySingle
                            InspectCode={this.formRef.current.getFieldValue('exam_code')}
                            onFinishScreen={(recordData) => {
                             
                              this.setState({
                                newId: recordData.id
                              })
                              this.formRef.current.setFieldsValue({
                                exam_code: recordData.test_item_code,
                                GY_exam_name: recordData.exam_name
                              })
                              this.closeModal()
                            }}
                          />
                        ),
                      }
                    })
                  }}
                /> */}
                <Input.Search allowClear
                  className='input-search-size-number-allow-clear-8'
                  maxLength={8}
                  style={{ textAlign: 'right' }}
                  onSearch={(value, event) => {
                    if (event.target.localName !== 'input') {
                      this.formRef.current.setFieldsValue({ exam_name: '' })
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component: (
                            <WS0271001_InspectItemSearchQuerySingle
                              InspectCode={this.formRef.current.getFieldValue('exam_code')}
                              onFinishScreen={({
                                recordData
                              }) => {
                                this.formRef.current.setFieldsValue({
                                  exam_code: recordData.test_item_code,
                                  GY_exam_name: recordData.exam_name
                                })
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: false,
                                  },
                                })
                              }}
                            />
                          ),
                        },
                      })
                    }
                  }}
                // onKeyPress={(e) => {
                //   if (e.key === 'Enter') {
                //     if (e.target.value) {
                //       // this.getExamName({ ExamCode: e.target.value });
                //     }
                //   }
                // }}
                />
              </Form.Item>
              <Form.Item name='GY_exam_name' label='　検査名称'>
                <Input className="input-size-20" bordered={false} readOnly></Input>
              </Form.Item>
            </Form>
          </div>
          <div className="box_button_bottom_right">
            <Button
              icon={<SaveOutlined />}
              type='primary'
              onClick={() => {
                this.saveDataInspectSet()
              }}
            >
              保存
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }

}
export default WS2705502_CategoryInquirySetting
