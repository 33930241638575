import axios from 'configs/axios'

const apiPaths = {
  index: '/api/inspect-group-maintain/inspect-group-maintain',
  save: '/api/inspect-group-maintain/inspect-group-maintain/save',
  delete: '/api/inspect-group-maintain/inspect-group-maintain/delete',
  getInspectGroupDetail: '/api/inspect-group-maintain/inspect-group-maintain/get-inspect-group-detail',
  saveInspectGroupDetail: '/api/inspect-group-maintain/inspect-group-maintain/save-inspect-group-detail',
  deleteInspectGroupDetail: '/api/inspect-group-maintain/inspect-group-maintain/delete-inspect-group-detail',
}

const InspectGroupMaintainService = {
  async index() {
    return axios.get(apiPaths.index)
  },

  async save(params) {
    return axios.post(apiPaths.save, params)
  },

  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },

  async getInspectGroupDetail(params) {
    return axios.get(apiPaths.getInspectGroupDetail, { params })
  },

  async saveInspectGroupDetail(params) {
    return axios.post(apiPaths.saveInspectGroupDetail, params)
  },

  async deleteInspectGroupDetail(params) {
    return axios.delete(apiPaths.deleteInspectGroupDetail, { params })
  },

}

export default InspectGroupMaintainService
