import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Form, Input, message, Button, Table, Modal, Row, Spin, Dropdown, Menu, Col, } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS2576004_CalendarSunSub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2576004_CalendarSunSub.jsx'
import IdConsultDateModifyAction from 'redux/CooperationRelated/OcrCaptureStartUp/IdConsultDateModify.action'
import { ConsoleSqlOutlined, MoreOutlined } from '@ant-design/icons'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain'
import WS2624002_VisitsChangeConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624002_VisitsChangeConfirm'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction'

const grid = {
  labelCol: { style: { width: 70 } },
  // wrapperCol: { style: { width: 100 } },
}
class WS3108016_IdConsultDateModify extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'ID・受診日修正'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      isLoadingTable: false,
      dataSource: [],
      isLoadingForm: false,
      kanji_name: '',
      PersonalNumChar: '',
      DateChar: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.dataConsultHistorySub()
    this.getScreenData()
  }

  onFinish = (values) => {

  }

  dataConsultHistorySub() {
    this.setState({ isLoadingTable: true })
    const data = {
      person_num: this.props.Li_PersonalNum,
    }
    IdConsultDateModifyAction.dataConsultHistorySubAction(data)
      .then((res) => {
        console.log(res)
        this.setState({ isLoadingTable: false })
        if (res) {
          this.setState({ dataSource: res ? res : [] })
        }
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(err.response.data.message)
      })
  }

  getScreenData() {
    this.setState({ isLoadingForm: true })
    const data = {
      serial_num: this.props.Li_SerialNum,
      branch_num: this.props.Li_BranchNum,
      person_num: this.props.Li_PersonalNum,
      consult_date: this.props.Li_Date,
    }
    IdConsultDateModifyAction.getScreenDataAction(data)
      .then((res) => {
        this.setState({ isLoadingForm: false })
        if (res) {
          this.formRef.current.setFieldsValue({
            PersonalNumChar: res.PersonalNumChar,
          })
          this.formRef.current.setFieldsValue({ DateChar: res.DateChar })
          this.setState({ kanji_name: res.kanji_name })
        }
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(err.response.data.message)
      })
  }

  onChangeInputPersonalNumChar(event) {
    this.setState({ PersonalNumChar: event.target.value })
  }

  onChangeInputDateChar(event) {
    this.setState({ DateChar: event.target.value })
  }

  update() {
    const data = {
      serial_num: this.props.Li_SerialNum,
      branch_num: this.props.Li_BranchNum,
      person_num: this.state.PersonalNumChar
        ? this.state.PersonalNumChar
        : this.formRef.current.getFieldValue('PersonalNumChar'),
      consult_date: this.state.DateChar
        ? this.state.DateChar
        : this.formRef.current.getFieldValue('DateChar'),
    }
    console.log(data)
    IdConsultDateModifyAction.updateAction(data)
      .then((res) => {
        message.success('成功')
        this.getScreenData()
        this.dataConsultHistorySub()
      })
      .catch((err) => message.error('エラー'))
  }

  handleSelectRowsTableFirst = (selectedRowTableFirst) => {
    console.log(selectedRowTableFirst)
    this.setState({ selectedRowTableFirst })
  }

  ReturnComponent = (component) => {
    let components = {
      WS2583001_ConsultInquirySub,
      WS0650001_DocumentBatchCreateSub,
      WS2637001_OverallResultDisplayInput,
      WS0898001_IntroduceLetterExtractMaintain,
      WS2624002_VisitsChangeConfirm,
      WS3020036_CoupledPrintInstruction,
    }
    return components[component]
  }

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === nameScreen) {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 受診照会 モーダル
   * @param {*} record
   */
  showWS2583001_ConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS2583001_ConsultInquirySub') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 予約関連 モーダル
   * @param {*} record
   */
  showWS0650001_DocumentBatchCreateSub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0650001_DocumentBatchCreateSub
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            Li_OutputUnit={''}
            Li_OutputPattern={''}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS0650001_DocumentBatchCreateSub') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 結果照会 モーダル
   * @param {*} record
   */
  showWS2637001_OverallResultDisplayInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637001_OverallResultDisplayInput
            Li_MenuOption={''}
            Li_MenuAdminRights={''}
            Li_MenuAuthority={''}
            Li_SubjectNotBeChangedMode={''}
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS2637001_OverallResultDisplayInput') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 結果印刷 モーダル
   * @param {*} record
   */
  showWS3020036_CoupledPrintInstruction = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS3020036_CoupledPrintInstruction') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 紹介状 モーダル
   * @param {*} record
   */
  showWS0898001_IntroduceLetterExtractMaintain = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0898001_IntroduceLetterExtractMaintain
            Li_MenuOption={''}
            Li_PersonalNum={record.id}
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS0898001_IntroduceLetterExtractMaintain') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 受付 モーダル
   * @param {*} record
   */
  showWS2624002_VisitsChangeConfirm = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2624002_VisitsChangeConfirm
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            Li_AcceptOrCancel={''}
            Li_Date={record.visit_date_on}
            Li_AcceptNum={''}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS2624002_VisitsChangeConfirm') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 個人情報検索・照会 モーダル
   */
  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component:
          <WS0248001_PersonalInfoSearchQuery
            // Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNum')}
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue('PersonalNumChar')}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                PersonalNumChar: output.recordData.personal_number_id,
                kanji_name: output.recordData.kanji_name
              })
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * メニュー一覧
   * @param {*} record
   * @returns
   */
  renderMenu = (record) => (
    <Menu>
      <Menu.Item
        key={'menu-1'}
        onClick={() => {
          // 受診照会 モーダル
          this.showWS2583001_ConsultInquirySub(record)
        }}
      >
        受診照会
      </Menu.Item>

      <Menu.Item
        key={'menu-2'}
        onClick={() => {
          // 予約関連 モーダル
          this.showWS0650001_DocumentBatchCreateSub(record)
        }}
      >
        予約関連
      </Menu.Item>

      <Menu.Item
        key={'menu-3'}
        hidden={record.Expression_7 === '予約'}
        onClick={() => {
          // 結果照会 モーダル
          this.showWS2637001_OverallResultDisplayInput(record)
        }}
      >
        結果照会
      </Menu.Item>

      <Menu.Item
        key={'menu-4'}
        hidden={record.Expression_7 === '予約'}
        onClick={() => {
          // 結果印刷 モーダル
          this.showWS3020036_CoupledPrintInstruction(record)
        }}
      >
        結果印刷
      </Menu.Item>

      <Menu.Item
        key={'menu-5'}
        hidden={record.Expression_7 === '予約'}
        onClick={() => {
          // 紹介状 モーダル
          this.showWS0898001_IntroduceLetterExtractMaintain(record)
        }}
      >
        紹介状
      </Menu.Item>

      <Menu.Item
        key={'menu-6'}
        hidden={record.Expression_7 === '受付'}
        onClick={() => {
          // 受付 モーダル
          this.showWS2624002_VisitsChangeConfirm(record)
        }}
      >
        受付
      </Menu.Item>
    </Menu>
  )

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const { selectedRowTableFirst } = this.state
    const rowSelectionTableFirst = {
      selectedRowTableFirst,
      onChange: this.handleSelectRowsTableFirst,
    }

    return (
      <div className='id-consult-date-modify'>
        <Card title='ID・受診日修正'>
          {/* <Spin spinning={this.state.isLoadingForm}> */}
          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              {...grid}>

              {/* <Form.Item
                name='PersonalNumChar'
                label='個人番号'>
                <Input.Search
                  type='text'
                  onChange={(e) =>
                    this.onChangeInputPersonalNumChar(e, 'PersonalNumChar')
                  }
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 1500,
                        component: (
                          <WS0248001_PersonalInfoSearchQuery
                            onClickedCreate={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: false,
                                },
                              })
                            }}
                          />
                        ),
                      },
                    })
                  }}
                />
              </Form.Item>

              <span style={{ lineHeight: '35px' }}>
                {this.state.kanji_name}
              </span> */}



              <div className='box_inner_horizontal'>
                <Form.Item
                  name='PersonalNumChar'
                  label='個人番号'
                >
                  <Input.Search
                    className='input-search-size-number-allow-clear-10'
                    type='text'
                    allowClear={true}
                    onSearch={(value, event) => {
                      if (value !== '' || event.target.localName !== 'input') {
                        // 個人情報検索・照会 モーダル
                        this.showWS0248001_PersonalInfoSearchQuery()
                      }
                    }}
                    onChange={(event) => {
                      this.onChangeInputPersonalNumChar(event, 'PersonalNumChar')
                      if (event.target.value === '') {
                        // 漢字名を空に変更
                        this.formRef.current.setFieldsValue({
                          kanji_name: ''
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name='kanji_name'>
                  <Input readOnly style={{ background: 'transparent', border: 'none' }} />
                </Form.Item>
              </div>

              <Form.Item
                name='DateChar'
                label='受診日'
              >
                <Input.Search
                  type='text'
                  className='input-search-size-number-allow-clear-10'
                  onChange={(e) =>
                    this.onChangeInputDateChar(e, 'DateChar')
                  }
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 800,
                        component: (
                          <WS2576004_CalendarSunSub
                            onClickedCreate={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: false,
                                },
                              })
                            }}
                          />
                        ),
                      },
                    })
                  }}
                />
              </Form.Item>


              <Button
                type='primary'
                style={{ float: 'right' }}
                onClick={() => {
                  this.update()
                }}
              >
                確定
              </Button>
            </Form>
            {/* </Spin> */}
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(res) => res.id}
              // rowSelection={{
              //   type: 'radio',
              //   onChange: (selectedRowKeys, selectedRows) => {
              //     IdConsultDateModifyAction.user_Action_4Action({
              //       person_num: this.state.PersonalNumChar
              //         ? this.state.PersonalNumChar
              //         : this.formRef.current.getFieldValue('PersonalNumChar'),
              //     })
              //       .then((res) => {
              //         if (!res || res.data || res.data.message) {
              //           // message.success(res.data.message)
              //           return
              //         }
              //       })
              //       .catch((err) => {
              //         const res = err.response
              //         if (!res || res.data || res.data.message) {
              //           message.error('エラーが発生しました')
              //           return
              //         }
              //         message.error(err.response.data.message)
              //       })
              //   },
              // }}
              pagination={false}
              scroll={{ y: 500 }}
              onRow={(record, index) => ({
                onClick: (e) => { },
              })}
            >
              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                width={100}
              />
              <Table.Column
                title='状態'
                dataIndex='Expression_7'
                width={80}
                render={(value) => (
                  <div style={{
                    color: (value === '受付') ? 'red' : 'blue',
                    textAlign: 'center'
                  }}>
                    {value}
                  </div>
                )}
              />
              <Table.Column
                title='契約情報'
                dataIndex='Expression_9'
              />
              <Table.Column
                width={45}
                render={(text, record, index) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => this.renderMenu(record)}
                  >
                    <Button size='small' icon={<MoreOutlined />} />
                  </Dropdown>
                )}
              />
            </Table>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3108016_IdConsultDateModify)
