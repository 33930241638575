import React from "react";

import { Card, Table, Form, Row, Col, Button, TimePicker, Input } from "antd";
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';

import WS1382001_ImplementorInquiry from './WS1382001_ImplementorInquiry';
import WS1383001_SupportItemInquiry from './WS1383001_SupportItemInquiry';
import InsureGuideCourseDetailAction from "redux/SpecificInsureMaintenance/InsureGuideCourse/InsureGuideCourseDetail.action";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import WS1388001_ImplementLocationQuery from './WS1388001_ImplementLocationQuery';
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment";
import WS1398011_InsureGuideCourseDetailChild from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1398011_InsureGuideCourseDetailChild.jsx";

const columns = [
  {
    title: '連番',
    dataIndex: 'serial_number',
    classNumber: '10',
    textAlign: 'right',
    type: 7,
  },

  {
    title: '支援項目',
    dataIndex: 'support',
    classNumber: '20',
    type: 4,
    childModal: {
      width: '60%',
      component: WS1383001_SupportItemInquiry
    },
  },
  {
    title: '支援項目コード',
    dataIndex: 'support_code',
    classNumber: '20',
    type: 9,
  },
  {
    title: '支援量',
    dataIndex: 'support_price',
    type: 7,
  },
  {
    title: "時期",
    dataIndex: "support_time",
    type: 6,
  },

  {
    title: '時期区分',
    dataIndex: 'support_time_division',
    classNumber: '40',
    type: 2,
    selectList: [{
      value: 1,
      label: '日後'
    },
    {
      value: 2,
      label: '週間後'
    },
    {
      value: 3,
      label: 'ヶ月後'
    }],
  },

  {
    title: '評価区分',
    dataIndex: 'rating_division',
    classNumber: '40',
    type: 2,
    selectList: [{
      value: 1,
      label: '計画'
    },
    {
      value: 0,
      label: '初回①'
    },
    {
      value: 2,
      label: '初期面談'
    },
    {
      value: 3,
      label: '中間評価'
    },
    {
      value: 4,
      label: '継続中'
    },
    {
      value: 9,
      label: '終了評価'
    }],
  },

  {
    title: "開始時間",
    dataIndex: "start_period_time",
    type: 8,
  },

  {
    title: "終了時間",
    dataIndex: "end_period_time",
    type: 8,
  },

  {
    title: '実施者',
    dataIndex: 'implement_prospective_code',
    classNumber: '40',
    // type: 2,
    // selectList: [{
    //   value: 1,
    //   label: '医師'
    // },
    // {
    //   value: 2,
    //   label: '保健師'
    // },
    // {
    //   value: 3,
    //   label: '管理栄養士'
    // },
    // {
    //   value: 4,
    //   label: '看護師'
    // },
    // {
    //   value: 5,
    //   label: '専門知識のある者'
    // },],
    type: 4,
    childModal: {
      width: '60%',
      component: WS1382001_ImplementorInquiry
    },
  },

  {
    title: '実施場所',
    dataIndex: 'implement_location',
    classNumber: '20',
    type: 4,
    childModal: {
      width: '60%',
      component: WS1388001_ImplementLocationQuery
    },
  },
  {
    title: '実施区分コード',
    dataIndex: 'implement_location_code',
    type: 9,
  },

]

class WS1398010_InsureGuideCourseDetail extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '保健指導コース明細';

    this.state = {
      dataSource: [],
      selectedRows: [],
      params: {
        Li_CourseCode: this.props.Li_CourseCode
      },
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
    };
  }

  componentDidMount() {
    this.loadData(this.state.params);
  }

  loadData(params) {
    this.setState({ isLoading: true });

    InsureGuideCourseDetailAction.getIndex(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          });
          this.formRef.current?.setFieldsValue({
            tableData: res || null,
          });
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }));
  }

  //実施者照会　★ダブルクリックで選択しても反映されない
  searchImplementProspectiveCode = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS1382001_ImplementorInquiry
            onFinishScreen={(output) => {
              let newDataSource = [...this.state.dataSource]
              newDataSource[index].implement_prospective_code = output.practitioner_code;
              newDataSource[index].kanji_name = output.kanji_name;
              this.setState({
                dataSource: [...newDataSource]
              });

              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  //支援項目照会
  searchSupportCode = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "100%",
        component: (
          <WS1383001_SupportItemInquiry
            onFinishScreen={(output) => {
              let newDataSource = [...this.state.dataSource]
              newDataSource[index].support_code = output.support_code;
              newDataSource[index].support = output.support;
              newDataSource[index].restriction_info_lowest_value = output.restriction_info_lowest_value;
              newDataSource[index].restriction_info_highest_value = output.restriction_info_highest_value;
              this.setState({
                dataSource: [...newDataSource]
              });

              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  // 削除処理
  Delete(params) {
    InsureGuideCourseDetailAction.deleteData(params.itemChange)
      .then(async (res) => {
        // ポイント計算処理
        await InsureGuideCourseDetailAction.pointCalculate(params.itemChange)
        await this.loadData(this.state.params);
        await this.closeModal();
        if (this.props.onScreenUpdate) {
          this.props.onScreenUpdate()
        }
      })
  }

  // 変更処理
  onSave(params) {
    InsureGuideCourseDetailAction.postData(params)
      .then(async (res) => {
        // ポイント計算処理
        await InsureGuideCourseDetailAction.pointCalculate(params)
        await this.loadData(this.state.params);
        await this.closeModal();
        if (this.props.onScreenUpdate) {
          this.props.onScreenUpdate()
        }
      })
  }

  // 新規追加処理
  onAdd(params) {
    let data = params//.itemChange;
    console.log(data)
    data['insure_guide_course_code'] = this.props.Li_CourseCode//.state.params.Li_CourseCode;

    InsureGuideCourseDetailAction.addData(data)//(params.itemChange)
      .then(async (res) => {
        // ポイント計算処理
        await InsureGuideCourseDetailAction.pointCalculate(data)//(params.itemChange)
        await this.loadData(this.state.params);
        await this.closeModal();
        if (this.props.onScreenUpdate) {
          this.props.onScreenUpdate()
        }
      })
  }

  //実施場所照会
  searchImplementLocationCode = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS1388001_ImplementLocationQuery
            onFinishScreen={(output) => {
              let newDataSource = [...this.state.dataSource]
              newDataSource[index].implement_location_code = output.implement_location_code;
              newDataSource[index].implement_location = output.implement_location;
              this.setState({
                dataSource: [...newDataSource]
              });

              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    });
  }


  //パラメータの別画面を開く部分
  //recordもデータベースの情報をもっている。（print-param-input）
  InsureGuideCourseDetailChild(record, flug) {
    this.setState({
      childModal: {
        //上のほうのstateのやつを持ってきている
        ...this.state.childModal,
        //上書きしている　オンの状態にする
        visible: true,
        width: 800,
        //開きたい画面をかく
        component: (
          <WS1398011_InsureGuideCourseDetailChild
            //左：次の画面でつかう名前　右：現在の画面で使用している名称　持ってくる前に書く
            //record.W1_instruction_sect 第2引数をHTMLの方（下の方）で渡している。recordの部分
            newFlag={flug}
            //連番
            serial_number={record ? record.serial_number : ''}

            //支援項目
            support={record ? record.support : ''}
            support_code={record ? record.support_code : ''}

            //支援量
            support_price={record ? record.support_price : ''}

            //時期
            support_time={record ? record.support_time : ''}

            //時期区分
            //★１　数字にする
            support_time_division={record ? Number(record.support_time_division) : ''}

            //時間帯
            start_period_time={record ? record.start_period_time : '00:00'}
            end_period_time={record ? record.end_period_time : '00:00'}

            //評価区分
            rating_division={record ? Number(record.rating_division) : ''}

            //★実施者
            kanji_name={record ? record.kanji_name : ''}
            implement_prospective_code={record ? record.implement_prospective_code : ''}

            //★実施場所
            implement_location={record ? record.implement_location : ''}
            implement_location_code={record ? record.implement_location_code : ''}

            onDelete={(output) => {
              let params = {
                ...output,
                id: record.id,
                insure_guide_course_code: record.insure_guide_course_code
              }

              InsureGuideCourseDetailAction.deleteData(params)
                .then(async (res) => {
                  // ポイント計算処理
                  await InsureGuideCourseDetailAction.pointCalculate(params)
                  await this.loadData(this.state.params);
                  await this.closeModal();
                  // if (this.props.onScreenUpdate) {
                  //   this.props.onScreenUpdate()
                  // }
                })
            }}

            onFinishScreen={(output) => {
              console.log(output)
              let param = {
                ...output,
                id: record ? record.id : null,
                insure_guide_course_code: this.props.Li_CourseCode ?? ''
              }
              if (flug) {
                this.onAdd(param)
              } else {
                this.onSave(param)
              }
            }}
          />
        ),
      }
    })
  }

  render() {
    return (
      <div className="insure-guide-course-detail">
        <Card title="保健指導コース明細">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete="off"
            initialValues={{ Classify: 5 }}
          >
            <Table
              bordered
              dataSource={
                this.formRef.current?.getFieldValue("tableData")
                  ? this.formRef.current?.getFieldValue("tableData")
                  : []
              }
              loading={false}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                showSizeChanger: true,
                onChange: (page, pageSize) => { },
                onShowSizeChange: (page, pageSize) => { }
              }}
              // onRow={(record, rowIndex) => ({
              //   onDoubleClick: () =>
              //     this.setState({
              //       childModal: {
              //         ...this.state.childModal,
              //         visible: true,
              //         centered: true,
              //         width: '550px',
              //         className: '',
              //         component:
              //           <ModalChange
              //             columns={columns}
              //             record={record}
              //             onUpdate={(output) => {
              //               this.onSave(output)
              //             }}
              //             onDelete={(output) => {
              //               this.Delete(output)
              //             }}
              //           />
              //       },
              //     })
              // })}

              rowKey={record => record.id}
            >
              {/* ★連番は入力してなかったらエラーにしたい　数字入力のみ */}
              <Table.Column
                width={40}
                title="連番"
                dataIndex="serial_number"
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />

              {/* 虫眼鏡の横に文字を出す。 */}
              <Table.Column
                width={300}
                title="支援項目"
                dataIndex="support_code"
                render={(value, record, index) => {
                  return <>
                    <Row>
                      <Col span={12}>
                        {record.support_code}
                      </Col>
                      <Col span={8} style={{ paddingTop: 5, marginLeft: 25 }}>
                        {record.restriction_info_lowest_value} 〜 {record.restriction_info_highest_value}
                      </Col>
                    </Row>
                    <Row>{record.support}</Row>
                  </>
                }}
              />

              {/* 〇数字入力のみ */}
              <Table.Column
                title="支援量"
                dataIndex="support_price"
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title="ポイント"
                dataIndex="support_unit_info_per_unit_point"
                render={(value, record) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {record.rating_division_code == 3 || record.rating_division_code == 4 ? value : null}
                    </div>
                  )
                }}
              />

              {/* 〇数字入力のみ */}
              {/* 少数第一位に表示をすればOK */}
              <Table.Column
                width={40}
                title="時期"
                dataIndex="support_time"
                // step='0.1'
                render={(value) => {
                  return (
                    //.slice(0, -1) 最初～最後から１番目までを抜き出す
                    <div style={{ textAlign: 'center' }}>{value.slice(0, -1)}</div>
                  )
                }}
              />

              <Table.Column
                title="時期区分"
                dataIndex="support_time_division"
                render={(value) => {
                  return (
                    //★４ここつける
                    <div >{value == 1 ? '日後' : value == 2 ? '週間後' : value == 3 ? 'ヶ月後' : ''}</div>
                  )
                }}
              />

              <Table.Column
                title="評価区分"
                dataIndex="rating_division"
                render={(value) => {
                  return (
                    //★４ここつける
                    <div >{
                      value == 0 ? '初回①' :
                        value == 1 ? '計画' :
                          value == 2 ? '初期面談' :
                            value == 3 ? '中間評価' :
                              value == 4 ? '継続中' :
                                value == 9 ? '終了評価' :
                                  ''}</div>
                  )
                }}

              />

              {/* ★時間のフォーマットにする！！ */}
              <Table.Column
                title="時間帯"
                dataIndex="start_period_time"
                // render={(value, record) => {
                // return (<div>{moment(record.start_period_time, 'HH:mm')} .'〜'. {moment(record.end_period_time, 'HH:mm')}</div>
                // )
                // }}
                render={(text, record) => (
                  <div>{(moment(record.start_period_time, 'HH:mm').format('HH:mm')) + '〜' + (moment(record.end_period_time, 'HH:mm').format('HH:mm'))}</div>

                )}
              />

              {/* ★虫眼鏡でテーブルが開くようにする！！　選択ができない。 */}
              <Table.Column
                title="実施者"
                dataIndex="implement_prospective_code"
                render={(value, record, index) => {
                  return <>
                    <Row>{record.kanji_name}</Row>
                  </>
                }}
              />

              {/* ★虫眼鏡の横に文字を出す。 */}
              <Table.Column
                title="実施場所"
                dataIndex="implement_location_code"
                render={(value, record, index) => {
                  return <><Row>
                  </Row>
                    <Row>{record.implement_location}</Row>
                  </>
                }}
              />

              <Table.Column
                align='center'
                width={40}
                fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.InsureGuideCourseDetailChild(null, true)
                      // this.setState({
                      // childModal: {
                      //   ...this.state.childModal,
                      //   visible: true,
                      //   centered: true,
                      //   width: '550px',
                      //   className: '',
                      //   component:
                      //     <ModalAdd
                      //       columns={columns}
                      //       onFinishScreen={(output) => {
                      //         this.onAdd(output)
                      //       }}
                      //     />
                      // },
                      // });
                    }}
                  />
                }
                //三点ボタンの行　次の画面はどうやって表示している？？
                render={(text, record, index) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      this.InsureGuideCourseDetailChild(record, false)
                      // // 編集、削除
                      // this.setState({
                      //   childModal: {
                      //     ...this.state.childModal,
                      //     visible: true,
                      //     centered: true,
                      //     width: '550px',
                      //     className: '',
                      //     component:
                      //       <ModalChange
                      //         columns={columns}
                      //         record={record}
                      //         onUpdate={(output) => {
                      //           this.onSave(output)
                      //         }}
                      //         onDelete={(output) => {
                      //           this.Delete(output)
                      //         }}
                      //       />
                      //   },
                      // });
                    }}
                  />

                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS1398010_InsureGuideCourseDetail;
