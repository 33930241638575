import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Row,
  Col,
  Input,
  Form,
  Card,
  Button,
  Table,
  Select,
  Tabs,
  Modal,
  Spin,
  message,
  Checkbox,
  Space,
  Tag,
  Tooltip,
  Dropdown,
  Menu
} from "antd";
import { debounce } from "lodash";

import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx";
import WS0247001_OfficeInfoRetrievalQuery from "./WS0247001_OfficeInfoRetrievalQuery.jsx";
import WS0341008_BranchShopInquiry from "./WS0341008_BranchShopInquiry.jsx";
import WS0341009_BranchStoreGenerated from "./WS0341009_BranchStoreGenerated.jsx";
import WS0308017_OrganizationCreateNew from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0308017_OrganizationCreateNew.jsx";
import WS0265001_BasicCourseInquiry from "../V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS2584019_PersonalInfoInquirySub from "../../KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";
import WS0246001_InsurerInfoSearchQuery from "../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0307008_ContractHistorySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx";
import { history } from "constants/BrowserHistory";
import {
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  MenuOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import OfficeInfoMaintainService from "services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js";
import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import PropTypes from "prop-types";
import WS0344001_SupplementalInfoSetting from "../V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx";
import Color from "constants/Color.js";
import * as wanakana from "wanakana";

const grid = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const smGrid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

class WS0341500_OfficeInfoSupplement extends React.Component {
  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0,
      dataSource: [],
      disabledSaveBtn: true
    };
    // this.getInitialDisplayCorrespondDataDisplay = debounce(
    //   this.getInitialDisplayCorrespondDataDisplay,
    //   500
    // );
  }

  componentDidMount = () => {
    this.setState({
      dataSource: this.props.Li_SupplementaryInfo
    })
    this.formRef.current?.setFieldsValue({
      tableData: this.props.Li_SupplementaryInfo
    })
  }

  onChangeValue = (value, record, index) => {
    let chnageValue = ''
    if (record.condition_1 === 'X') {
      chnageValue = wanakana.toRomaji(value)
    } else if (record.condition_1 === 'K') {
      chnageValue = wanakana.toKatakana(value)
    } else if (record.condition_1 === 'J') {
      chnageValue = wanakana.toHiragana(value)
    }

    let arrTemp = [...this.state.dataSource]
    let obj = {
      ...record,
      content: chnageValue, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj
    // this.formRef.current?.setFieldsValue({
    //   tableData: arrTemp
    // })
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false
    })
  }

  onClick = () => {
    let params = {
      OfficeCode: this.props.Li_OfficeCode,
      BranchStoreCode: this.props.Li_BranchStoreCode,
      SupplementaryInfo: this.state.dataSource
    }
    console.log(this.state.dataSource)
    OfficeInfoMaintainService.saveSupplementaryInfoService(params)
    this.props.onFinishScreen({
      Li_dataSource: this.state.dataSource,
      Li_isFileEdited: true
    })
  }

  onFinish = () => { }

  render() {
    return (
      <div className="office-info-supplement">
        <Card title='補足情報'>
          <Form
            // ref={this.formRef}
            // onFinish={this.onFinish}
            // {...grid}
            // onValuesChange={this.onValuesChange}
            // autoComplete="off"
            // size="small"
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Table
              size='small'
              pagination={false}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              scroll={{ y: 500 }}
              bordered
            >
              <Table.Column
                title='項目'
                dataIndex='Expression_22'
              />
              <Table.Column
                title='内容'
                dataIndex='content'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['tableData', index, 'content']}>
                      {(record.StsDirectInput && (record.contents ?? false)) ?
                        // 選択項目
                        <Select
                          onChange={(event) => {
                            this.onChangeValue(event, record, index)
                          }}
                        >
                          {record.contents.map((item, index) => (
                            <Select.Option
                              key={item.label + index}
                              value={item.value}
                            >
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                        :
                        <Input
                          maxLength={record?.condition_2 || 50}
                          onBlur={event => {
                            this.onChangeValue(event.target.value, record, index)
                          }}
                        />
                      }
                    </Form.Item>
                  )
                }}
              />
            </Table>
            <div className="box_button_bottom_right">
              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
                disabled={this.state.disabledSaveBtn}
                onClick={() => this.onClick()}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
            {/* <Form.List name="SupplementaryInfo">
              {fields => {
                if (!this.state.screenData.SupplementaryInfo) {
                  return null;
                }
                return this.state.screenData.SupplementaryInfo.map((value, index) => {
                  return (
                    <Row gutter={5} key={`supplementary-info${index}`}>
                      <Col span={4}>
                        <Tag
                          color={Color(156).Background}
                          style={{
                            width: "100%",
                            height: "calc(100% - 0.3rem)"
                          }}
                        >
                          {value.Expression_22}
                        </Tag>
                      </Col>
                      <Col span={20}>
                        <Form.Item name={[index, "content"]} labelCol={{ span: 5 }}>
                          <Input
                            onBlur={event => {
                              let namePath = ["SupplementaryInfo", index, "content"];
                              let inputValueJP = "";
                              if (value.condition_1 === "X") {
                                inputValueJP = wanakana.toRomaji(event.target.value);
                              }
                              if (value.condition_1 === "J") {
                                inputValueJP = wanakana.toKatakana(event.target.value);
                              }
                              if (value.condition_1 === "K") {
                                inputValueJP = wanakana.toHiragana(event.target.value);
                              }
                              this.formRef?.current?.setFields([
                                {
                                  name: namePath,
                                  value: inputValueJP
                                }
                              ]);
                              this.forceUpdate();
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )
                })
              }}
            </Form.List> */}
          </Form>
        </Card>
      </div>
    )
  }
}
export default WS0341500_OfficeInfoSupplement