import axios from "configs/axios";

const apiPaths = {
  getIndex: "/api/insure-guide-course/insure-guide-course-detail",
  addData: "/api/insure-guide-course/insure-guide-course-detail/add-data",
  postData: "/api/insure-guide-course/insure-guide-course-detail/post-data",
  deleteData: "/api/insure-guide-course/insure-guide-course-detail/delete-data",
  pointCalculate: "/api/insure-guide-course/insure-guide-course-detail/point-calculate",
};

const InsureGuideCourseDetailService = {

  async getIndex(params) {
    return axios.get(apiPaths.getIndex, { params });
  },

  async addData(params) {
    return axios.post(apiPaths.addData, params);
  },

  async postData(params) {
    return axios.post(apiPaths.postData, params);
  },

  async deleteData(params) {
    return axios.delete(apiPaths.deleteData, { params });
  },

  async pointCalculate(params) {
    return axios.post(apiPaths.pointCalculate, params);
  },

};

export default InsureGuideCourseDetailService;
