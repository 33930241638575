import { message } from 'antd'
import ProvisionalReservationService from 'services/Others/ProvisionalReservation/ProvisionalReservationService'

const ProvisionalReservationAction = {
  getCourseData(data) {
    return ProvisionalReservationService.getCourseData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default ProvisionalReservationAction
