import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React, { createRef, Component } from 'react';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Input, Tabs, InputNumber, Popconfirm, Select, Tooltip } from "antd";
import { DeleteOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import 'pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1400001_InspectItemMaster.scss';
import AddApplyDate3 from "./AddApplyDate3";
import FacilityConsumptionTaxSettingAction from "redux/SystemMaintenance/FacilityConsumptionTaxSetting/FacilityConsumptionTaxSetting.action";

const { TabPane } = Tabs;
const facilityList = [
  { date: '0000/00/00', name: '名称1' },
  { date: '2022/04/05', name: '名称2' },
  { date: '2022/04/10', name: '名称3' },
];
const taxList = [
  { date: '0000/00/00', name: '名称1' },
  { date: '2014/05/17', name: '名称2' },
  { date: '2019/12/06', name: '名称3' },
  { date: '2030/12/06', name: '名称4' },
];

const columns = [
  {
    title: '適用年月日',
    dataIndex: 'date',
    textAlign: 'center',
  },

  {
    title: '名称',
    dataIndex: 'name',
    textAlign: 'center',
  }
]
class WS1536001_FacilityConsumptionTaxSetting extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '施設・消費税設定';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      applyDateList: [],
      settedTax: {}
    };

  }

  componentDidMount() {
    FacilityConsumptionTaxSettingAction.getTaxApplyDate()
      .then(res => {
        this.setState({
          applyDateList: res.data
        })
        let idList = this.state.applyDateList.map(element => {
          return element.id
        });
        let latestId = Math.max.apply(null, idList)
        this.formRef.current.setFieldsValue({ date: latestId.toString() })
        this.handleDateChange(latestId)
      })
  }

  handleDateChange = (value) => {
    let params = {
      id: Number(value)
    }
    FacilityConsumptionTaxSettingAction.getTaxSetting(params)
      .then(res => {
        this.formRef.current.setFieldsValue(res.data);
        this.setState({
          settedTax: res.data,
        })
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: ""
      }
    });
  };

  reloadApplyDate = (id) => {
    //idとapply_date_onのみ更新してstateへ
    // FacilityConsumptionTaxSettingAction.getDateReload()
    FacilityConsumptionTaxSettingAction.getTaxApplyDate()
      .then(res => {
        // findで新規追加idと一致する日付のデータを取得する
        const applyDate = res.data.find((v) => v.id === id)
        const applyDateOn = applyDate.apply_date_on
        this.setState({
          applyDateList: res.data,
          settedTax: {
            //idとapply_date_onのみ更新してstateへ
            ...this.state.settedTax,
            id: id,
            apply_date_on: applyDateOn
          }
        })
        this.formRef.current.setFieldsValue({ date: id.toString() }) //適用年月日に新規追加した日付を設定
        this.onSave() //既に入力されている情報を保存(ページの更新などで消えないようにするため)
      })
  }

  deleteTax = () => {
    let params = {
      id: Number(this.formRef.current.getFieldValue('date'))
    }
    FacilityConsumptionTaxSettingAction.deleteTax(params)
      .then(res => {
        this.setState({
          applyDateList: res.data,
        })
        this.formRef.current.resetFields()
      })
  }

  onChangeSettedTax = (event, keyName) => {
    this.setState({
      settedTax: {
        ...this.state.settedTax,
        [keyName]: event
      }
    })
  }

  onSave = () => {
    let params = {
      data: { ...this.state.settedTax }
    }
    FacilityConsumptionTaxSettingAction.postTax(params)
  }

  render() {
    return (
      <div className="facility-consumption-tax-setting">
        <Form ref={this.formRef}>
          <Card title="消費税設定" className="mb-3 scrollbar" style={{ overflow: 'auto', width: 'fit-content' }}>
            <div className='box_inner_horizontal'>
              <Form.Item name='date' label='適用年月日'>
                <Select
                  className='input-size-10'
                  placeholder='選択してください'
                  onChange={this.handleDateChange}
                  style={{ width: '200px' }}
                >
                  {this.state.applyDateList.map((item) => {
                    return (
                      <Select.Option
                        key={item.id}
                        value={item.id.toString()}//valueは文字列
                      >
                        {item.apply_date_on}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Tooltip title='施設設定の追加'>
                <Button
                  size='small'
                  type='primary'
                  style={{ borderRadius: '12px', marginTop: '0.2rem' }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 250,
                        component: (
                          // 渡すパラメータは適宜設定
                          <AddApplyDate3
                            onFinishScreen={(id) => {
                              this.closeModal()
                              this.reloadApplyDate(id)
                            }}
                          />
                        )
                      }
                    });
                  }}
                >
                </Button>
              </Tooltip>
            </div>
            <div className='box_search' style={{ width: '100%' }}>
              <div className='box_inner_vertical'>
                {/* <Form.Item name={['dataConsumptionTax', 0, "consumption_tax_1"]} label="消費税１"> */}
                <Form.Item name={"consumption_tax_1"} label="消費税１">
                  <InputNumber
                    className='input-size-number-4'
                    step="0.01"
                    max={9.99}
                    maxLength={4}
                    onChange={(event) => this.onChangeSettedTax(event, 'consumption_tax_1')}
                  />
                </Form.Item>
                <Form.Item name={"consumption_tax_2"} label="消費税２">
                  <InputNumber
                    className='input-size-number-4'
                    step="0.01"
                    max={9.99}
                    maxLength={4}
                    onChange={(event) => this.onChangeSettedTax(event, 'consumption_tax_2')}
                  />
                </Form.Item>
                <Form.Item name={"consumption_tax_3"} label="消費税３">
                  <InputNumber
                    className='input-size-number-4'
                    step="0.01"
                    max={9.99}
                    maxLength={4}
                    onChange={(event) => this.onChangeSettedTax(event, 'consumption_tax_3')}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="box_button_bottom_right">
              <Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                cancelButtonProps={{ style: { height: '32px' } }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                okText='削除'
                onConfirm={this.deleteTax}
                cancelText='キャンセル'
              >
                <Button
                  danger
                  type="primary"
                  style={{}}
                  icon={<DeleteOutlined />}
                >削除
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => {
                  this.onSave()
                }}
              >保存
              </Button>
            </div>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Form>
      </div>
    );
  }

}
export default (WS1536001_FacilityConsumptionTaxSetting);