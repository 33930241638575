import axios from 'configs/axios'

const API_LIST = {
  index: '/api/follow-up-letter/follow-up-letter-maintain',
  getFollowUpList: '/api/follow-up-letter/follow-up-letter-maintain/get-follow-up-list',
  save: '/api/follow-up-letter/follow-up-letter-maintain/save',
  deleteData: '/api/follow-up-letter/follow-up-letter-maintain/delete-data',
  printBefore: '/api/follow-up-letter/follow-up-letter-maintain/print-before',
  print: '/api/follow-up-letter/follow-up-letter-maintain/print',
  preview: '/api/follow-up-letter/follow-up-letter-maintain/preview',
  getPreviousData: '/api/follow-up-letter/follow-up-letter-maintain/get-previous-data',
}

const FollowUpLetterMaintainService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getFollowUpList(params) {
    return axios.get(API_LIST.getFollowUpList, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async deleteData(params) {
    return axios.post(API_LIST.deleteData, params)
  },
  async printBefore(params) {
    return axios.get(API_LIST.printBefore, { params })
  },
  async print(params) {
    return axios.post(API_LIST.print, params)
  },
  async preview(params) {
    return axios.post(API_LIST.preview, params, { responseType: 'blob' })
  },
  async getPreviousData(params) {
    return axios.get(API_LIST.getPreviousData, { params })
  },
}

export default FollowUpLetterMaintainService
