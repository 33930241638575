import { message } from "antd";
import IntroduceLetterReturnInfoInputService from "services/IntroductionLetter/IntroduceLetterReturnInfoInput/IntroduceLetterReturnInfoInputService.js"
const IntroduceLetterReturnInfoInputAction = {
  getScreenData(data) {
    return IntroduceLetterReturnInfoInputService.getScreenData(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveIntroduceLetterInfo(data) {
    return IntroduceLetterReturnInfoInputService.saveIntroduceLetterInfo(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveReturnInfo(data) {
    return IntroduceLetterReturnInfoInputService.saveReturnInfo(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  deleteReturnInfo(data) {
    return IntroduceLetterReturnInfoInputService.deleteReturnInfo(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default IntroduceLetterReturnInfoInputAction;