import changeHankakuText from 'components/Commons/changeHankakuText'
/**
 * 
 * @param {*} value
 * @returns
 */

const NumberFormat = {
  /**
   * 全角を半角に変換
   * @param {*} value 
   * @returns 
   */
  changeHankaku(value) {
    value = changeHankakuText(value)
    return value
  },
  /**
   * ３桁のカンマ区切り
   * @param {*} value 
   * @returns 
   */
  formatter(value) {
    value = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value
  },
  /**
   * カンマ区切りを消す
   * @param {*} value 
   * @returns 
   */
  parser(value) {
    value = value.replace(/\$\s?|(,*)/g, '')
    return value
  },
}
export default NumberFormat
