import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0262064_CharacterSearch extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '文字検索';

    this.state = {
    };
  }

  componentDidMount = () => {
    this.callAPILoadData({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.callAPILoadData({});
    }
  }

  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API000262064001", params)
      .then((res) => {
        this.formRef.current?.setFieldsValue(res)
      }).finally(() => this.setState({ isLoading: false }))
  }

  onFinish(values) {
    this.props.onFinishScreen(this.formRef.current?.getFieldsValue())
  }

  render() {
    return (
      <div className="character-search">
        <Card className="mb-2" title="文字検索">
          <Form
            ref={this.formRef}
            onFinish={() => { this.onFinish() }}
          >
            <Form.Item name="SearchString">
              <Input></Input>
            </Form.Item>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0262064_CharacterSearch);
