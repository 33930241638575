import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Form,
  Card,
  Button,
  Table,
} from "antd";
import WS2584019_PersonalInfoInquirySub from "../../KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";
import {
  InfoOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import PropTypes from "prop-types";


class WS0341505_PersonalInfo extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0,
      PersonalInfo: []
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      PersonalInfo: this.props.Li_PersonalInfo
    })
    this.setState({
      PersonalInfo: this.props.Li_PersonalInfo
    })
  }

  render() {
    return (
      <div className="personal-info">
        <Card title='個人'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
          >
            <Table
              dataSource={this.state.PersonalInfo}
              rowKey={record => record.id}
              pagination={this.state.PersonalInfo.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              scroll={{ y: 500, x: 'max-content' }}
              size="small"
              bordered
            >
              <Table.Column title="個人番号" dataIndex="personal_number_id" />
              <Table.Column
                title=""
                dataIndex="importance"
                align="center"
                render={(text, record, index) => (
                  <Button
                    icon={<InfoOutlined />}
                    size='small'
                    style={{ borderRadius: '12px' }}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1200,
                          component: (
                            <WS2584019_PersonalInfoInquirySub
                              Li_PersonalNum={record.personal_number_id}
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: false
                                  }
                                });
                              }}
                            />
                          )
                        }
                      })
                    }
                  ></Button>
                )}
              />
              <Table.Column title="カナ氏名" dataIndex="kana_name" />
              <Table.Column title="漢字氏名" dataIndex="kanji_name" />
              <Table.Column
                title="性別"
                dataIndex="Expression_4"
                render={(text, record, index) =>
                  text === "男性" ? (
                    <div style={{ color: "blue" }}>{text}</div>
                  ) : (
                    <div style={{ color: "red" }}>{text}</div>
                  )
                }
              />
              <Table.Column
                title="生年月日"
                dataIndex="birthday_on"
                render={text => <>{moment(text).format("NNy/MM/DD")}</>}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    )
  }
}
export default WS0341505_PersonalInfo