/* eslint-disable no-useless-concat */
import { CloseCircleOutlined, InfoCircleOutlined, WarningOutlined, MoreOutlined } from '@ant-design/icons'
import { Form, Select, Card, Button, Table } from 'antd'
import Color from 'constants/Color'
import moment from 'moment-timezone'
import WS0251003_OfficeSpecialDisplay from 'pages/BS_BasicInfo/V4MS0003300_PersonalNumberMigration/WS0251003_OfficeSpecialDisplay.jsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import VisitHistoricalQueryAction from 'redux/basicInfo/PersonalNumberMigration/VisitHistoricalQuery.action'
import WS2584019_PersonalInfoInquirySub from '../../KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS0252001_OfficeSpecialMaintain from './WS0252001_OfficeSpecialMaintain'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'

class WS0264001_VisitHistoricalQuery extends React.Component {
  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '受診履歴照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      data: {},
      dataSource: [],
      importance: null,
      index: null
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      Li_PersonalNum: this.props.Li_PersonalNum,
      stateFlag: this.formRef.current?.getFieldValue('stateFlag') ?? ''
    }
    VisitHistoricalQueryAction.index(params)
      .then(res => {
        if (res) {
          this.setState({
            data: res,
            dataSource: res.ResultTable
          })
        }
      })
  }

  checkIcon(code) {
    var icon = ''
    switch (code) {
      case 1:
        icon = <InfoCircleOutlined style={{ color: '#1890ff', fontSize: '20px' }} />
        break
      case 3:
        icon = <WarningOutlined style={{ color: '#faad14', fontSize: '20px' }} />
        break
      case 5:
        icon = <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: '20px' }} />
        break
      default:
        icon = <Button size='small' icon={<MoreOutlined />} />
        break
    }
    return icon
  }


  showModalOffice(text, record, index) {
    if (record.Expression_11 === null) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: '60%',
          component: (
            <WS0252001_OfficeSpecialMaintain
              Li_OfficeCode={record.office_code}
              Li_BranchStoreCode={record.branch_store_code}
              onChangeData={(output) => {
                this.setState({
                  importance: output?.importance,
                  index: index
                })
              }}

              onFinishScreen={(output) => {
                this.setState({
                  importance: output?.importance
                })
                this.changeImportance(output?.importance, index)
                this.closeModal()
              }}
            />
          ),
        }
      })
    } else {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: '60%',
          component: (
            <WS0251003_OfficeSpecialDisplay
              Li_OfficeCode={record.office_code}
              Li_BranchStoreCode={record.branch_store_code}
              onChangeData={(output) => {
                this.setState({
                  importance: output?.importance,
                  index: index
                })
              }}

              onFinishScreen={(output) => {
                this.setState({
                  importance: output?.importance,
                  index: index
                })
                this.closeModal()
              }}
            />
          ),
        }
      })
    }
  }

  changeImportance(importanceCode, index) {
    if (importanceCode) {
      const path = ['ResultTable', index, 'Expression_11']
      this.formRef.current?.setFields([{
        name: path,
        value: importanceCode
      }])
      this.forceUpdate()
    }
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='visit-historical-query'>
        <Card title='受診履歴照会'>
          <div className='box_container'>
            <div className='box_inner_horizontal' style={{ alignItems: 'center' }}>
              <Form
                ref={this.formRef}
                initialValues={{ stateFlag: '' }}
                autoComplete='off'
              >
                <Form.Item
                  label='状態' name='stateFlag'
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    style={{ width: 120 }}
                    onChange={() => {
                      this.index()
                    }}
                  >
                    <Select.Option value=''>全て</Select.Option>
                    <Select.Option value={0}>予約</Select.Option>
                    <Select.Option value={1}>受付</Select.Option>
                    <Select.Option value={2}>保留</Select.Option>
                    <Select.Option value={3}>キャンセル</Select.Option>
                  </Select>
                </Form.Item>
              </Form>

              <div style={{ width: 40 }} />
              <span>{this.state.data?.Li_PersonalNum}</span>
              <span
                style={{ cursor: 'pointer', display: this.state.data ? '' : 'none' }}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 1400,
                      component: (
                        <WS2584019_PersonalInfoInquirySub
                          Li_PersonalNum={this.props.Li_PersonalNum}
                          onFinishScreen={() => {
                            this.closeModal()
                          }}
                        />
                      ),
                    }
                  })
                }}>
                {this.checkIcon(this.state.data.Importance)}
              </span>
              <span>{this.state.data?.KanjiName}</span>

              <span style={{
                background: this.state.data.Gender === '男性' ? '#0000FF' : '#FF0000', color: 'white',
                padding: '3px 5px', display: this.state.data.Gender ? '' : 'none'
              }}
              >
                {this.state.data?.Gender}
              </span>
              <div>{this.state.data?.DateOfBirth}</div>
              <div>{this.state.data?.Age} {this.state.data?.Age ? '歳' : ''}</div>
            </div>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: 450 }}
            >
              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                className='column-size-date'
                render={(value) => (
                  <div>{moment(value)?.format('YYYY/MM/DD(ddd)')}</div>
                )}
              />
              <Table.Column
                title='受付No'
                dataIndex='receipt_number'
                className='column-size-5'
                render={(value) => (
                  <div style={{ textAlign: 'right' }} >{value}</div>
                )}
              />
              <Table.Column
                title='状態'
                dataIndex='Expression_15'
                className='column-size-8'
                render={(text, record) => (
                  <div style={{ color: Color(record.Expression_16).Background, textAlign: 'center' }}>
                    {text}
                  </div>
                )}
              />
              <Table.Column
                title='時間'
                dataIndex='period_time'
                className='column-size-5'
              />
              <Table.Column title='受診ｺｰｽ' dataIndex='visit_course'
                render={(text, row) => (
                  <span>{text} {row?.contract_short_name}</span>
                )}
              />
              <Table.Column
                title='事業所'
                dataIndex='office_kanji_name'
                render={(text, record, index) => {
                  let icon = ''
                  switch (record?.Expression_11) {
                    case 1:
                      icon = (<InfoCircleOutlined style={{ color: '#1890ff', marginRight: '10px', float: 'right', fontSize: '20px' }} onClick={() => this.showModalOffice(text, record, index)} />)
                      break
                    case 3:
                      icon = (<WarningOutlined style={{ color: '#faad14', marginRight: '10px', float: 'right', fontSize: '20px' }} onClick={() => this.showModalOffice(text, record, index)} />)
                      break
                    case 5:
                      icon = (<CloseCircleOutlined style={{ color: '#ff4d4f', marginRight: '10px', float: 'right', fontSize: '20px' }} onClick={() => this.showModalOffice(text, record, index)} />)
                      break
                    default: icon = <Button size='small' icon={<MoreOutlined />} onClick={() => this.showModalOffice(text, record, index)} />
                      break
                  }
                  return (
                    <div>{text} {icon}</div>
                  )
                }} />
              <Table.Column
                title='施設'
                dataIndex='facility_name'
                className='column-size-5'
              />
              <Table.Column
                title='判定'
                dataIndex='comprehensive_judgment'
                className='column-size-5'
                render={(text) => (
                  <div style={{ textAlign: 'center' }}>
                    {text}
                  </div>
                )}
              />
              <Table.Column
                title='協会受付番号'
                dataIndex='association_acceptance_number'
                className='column-size-8'
                render={(value, record, index) => (
                  <span>{parseInt(value) === 0 ? '' : value}</span>
                )}
              />
            </Table>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0264001_VisitHistoricalQuery)
