import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Input, Checkbox, Button, Table, Select, Space, message, Modal, Tooltip } from 'antd'
import { ArrowDownOutlined, SearchOutlined, SaveOutlined } from '@ant-design/icons'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery.jsx'
import DepartmentChangeOnceAction from 'redux/basicInfo/DepartmentChangeOnce/DepartmentChangeOnce.actions'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import './WS0345001_DepartmentChangeOnce.scss'

// 初期値
const objNull = {
  // 事業所元
  'insurerNumber_F': '',
  'insurerKanjiName_F': '',
  'insurerCardSymbol_F': '',
  'insurerCardNumber_F': '',
  'recipientNumber_F': '',
  'insurerStartDate_F': '',
  'insurerEndDate_F': '',
  'relationshipName_F': '',
  'employmentName_F': '',
  'jobName_F': '',
  // 事業所先
  'insurerNumber_T': '',
  'insurerKanjiName_T': '',
  'recipientNumber_T': '',
  'insurerCardSymbol_T': '',
  'insurerCardNumber_T': '',
  'insurerStartDate_T': '',
  'insurerEndDate_T': '',
  'relationshipName_T': '',
  'employmentName_T': '',
  'jobName_T': '',
}
const labelCol = {
  labelCol: { style: { width: 60 } }
}
const labelCol2 = {
  labelCol: { style: { width: 80 } }
}
const marginStyle = { marginBottom: 5 }

class WS0345001_DepartmentChangeOnce extends React.Component {
  static propTypes = {
    Li_Option: PropTypes.any,
    Li_AdministratorPrivileges: PropTypes.number,
    Li_UseRights: PropTypes.number,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.office = React.createRef()
    this.branchCode = React.createRef()

    // document.title = '部署一括変更'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      checkCondition: true,

      employmentList: [],
      jobList: [],
      relationshipList: [],

      checkDateStart: false,
      checkDateEnd: false,

      checkOfficeCodeF: true,
      checkOfficeCodeT: true,
      dataSource: [],
      dataSourceOrg: [], // 元データ
      selectedRowKeys: [], //選択行（複数、idのみ）
      selectedRows: [], //選択行（複数）
      selectedRow: {}, //選択行(１行)

      officeInfoF: '',
      officeInfoT: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.office?.current?.focus()
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    DepartmentChangeOnceAction.index()
      .then((res) => {
        if (res) {
          this.setState({
            relationshipList: res.relationshipList,
            employmentList: res.employmentList,
            jobList: res.jobList
          })
        }
      })
  }

  /**
   * 事業所元の個人一覧を取得
   */
  getDataTableSelect() {

    let params = {
      OfficeCodeF: this.getFormFieldValue('OfficeCodeF'),
      BranchStoreCodeF: this.getFormFieldValue('BranchStoreCodeF'),
      OfficeCodeT: this.getFormFieldValue('OfficeCodeT'),
      BranchStoreCodeT: this.getFormFieldValue('BranchStoreCodeT'),
      StsBelongsTakeover: this.formRef.current?.getFieldValue('StsBelongsTakeover') ? 1 : 0
    }

    DepartmentChangeOnceAction.getDataTableSelect(params)
      .then((res) => {
        let arrRowSelectKey = []
        let arrRowSelect = []
        if (res && res.length > 0) {
          this.getInsuranceInfoSet(res[0])
          arrRowSelectKey = res.map(x => {
            if (x.enabled_disabled) {
              return x.id
            }
          })
          arrRowSelect = res.filter(x => x.enabled_disabled)
          this.setState({
            dataSource: res,
            dataSourceOrg: res,
          })
        } else {
          this.setState({
            dataSource: [],
            dataSourceOrg: [],
          })
        }

        let officeInfoF = ''
        let officeInfoT = ''
        if (this.getFormFieldValue('OfficeCodeF')) {
          officeInfoF = '：[' + this.getFormFieldValue('OfficeCodeF') + '-' + (this.getFormFieldValue('BranchStoreCodeF') ?? 0) + '] ' + this.getFormFieldValue('office_kanji_name')
        }
        if (this.getFormFieldValue('OfficeCodeT')) {
          officeInfoT = '：[' + this.getFormFieldValue('OfficeCodeT') + '-' + (this.getFormFieldValue('BranchStoreCodeT') ?? 0) + '] ' + this.getFormFieldValue('office_kanji_nameT')
        }

        // 初期化
        this.formRef.current.setFieldsValue(objNull)
        // 選択状態を初期化
        this.setState({
          selectedRowKeys: arrRowSelectKey,
          selectedRows: arrRowSelect,
          selectedRow: {},
          officeInfoF: officeInfoF,
          officeInfoT: officeInfoT
        })
      })
  }

  /**
   * 選択された個人の保険者データを取得
   * @param {*} selectData
   */
  getInsuranceInfoSet(selectData) {

    let params = {
      Li_PersonalNum: selectData.personal_number_id,
      OfficeCodeF: this.formRef.current?.getFieldValue('OfficeCodeF'),
      BranchStoreCodeF: this.formRef.current?.getFieldValue('BranchStoreCodeF') || 0,
      insurerNumber_T: selectData.insurer_number,
      insurer_number: selectData.insurer_number,
      insurer_card_symbol: selectData.insurer_card_symbol,
      insurer_card_number: selectData.insurer_card_number,
      recipient_number: selectData.recipient_number,
      insurer_start_date_on: selectData.insurer_start_date_on,
      insurer_end_date_on: selectData.insurer_end_date_on,
      relationship: selectData.relationship,
      employment_status: selectData.employment_status,
      occupations: selectData.occupations
    }
    DepartmentChangeOnceAction.getInsuranceInfoSet(params)
      .then((res) => {
        if (res) {
          let obj = { ...res }
          obj.insurerEndDate_T = (obj.insurerEndDate_T === '0000-00-00' || !obj.insurerEndDate_T) ? null : moment(obj.insurerEndDate_T)
          obj.insurerStartDate_T = (obj.insurerStartDate_T === '0000-00-00' || !obj.insurerStartDate_T) ? null : moment(obj.insurerStartDate_T)
          this.formRef.current.setFieldsValue(obj)
          this.forceUpdate()
        }
      }).catch(error => {
        console.log(error);
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  /**
   * setFieldsValue
   * @param {*} namePath
   * @param {*} value
   */
  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ])
  }

  /**
   * getFieldValue
   * @param {*} namePath
   * @returns
   */
  getFormFieldValue(namePath) {
    return this.formRef.current?.getFieldValue(namePath) || this.formRef.current?.getFieldValue(namePath) === 0 ? this.formRef.current?.getFieldValue(namePath) : null
  }

  /**
   * 事業所先の各項目の変更処理　
   * @param {*} field
   * @param {*} id
   */
  changeData(field, value) {
    let dataTable = [...this.state.dataSource]
    let rowClick = { ...this.state.selectedRow }

    switch (field) {
      case 'relationship_T':
        rowClick.relationship = value
        // this.state.relationshipList.forEach(value => {
        //   if (value.key === id) {
        //     rowClick.name = value.value
        //   }
        // })
        break
      case 'employment_T':
        rowClick.employment_status = value
        break
      case 'job_T':
        rowClick.occupations = value
        break
      case 'Expression_13':
        rowClick.Expression_13 = this.getFormFieldValue('insurerCardSymbol_T') + ' / ' + this.getFormFieldValue('insurerCardNumber_T')
        break
      case 'insurerCardNumber_T':
        rowClick.insurer_card_number = this.getFormFieldValue('insurerCardNumber_T')
        break
      case 'insurerCardSymbol_T':
        rowClick.insurer_card_symbol = this.getFormFieldValue('insurerCardSymbol_T')
        break
      case 'recipientNumber_T':
        rowClick.recipient_number = this.getFormFieldValue('recipientNumber_T')
        break
      case 'insurerStartDate_T':
        rowClick.insurer_start_date_on = this.checkDataNull('insurerStartDate_T') ? moment(this.checkDataNull('insurerStartDate_T'))?.format('YYYY/MM/DD') : null
        break
      case 'insurerEndDate_T':
        rowClick.insurer_end_date_on = this.checkDataNull('insurerEndDate_T') ? moment(this.checkDataNull('insurerEndDate_T'))?.format('YYYY/MM/DD') : null
        break
      case 'insurerNumber_T':
        rowClick.insurer_number = this.getFormFieldValue('insurerNumber_T')
        break
      default:
        break
    }

    let tempRowKeys = [...this.state.selectedRowKeys]

    let dataChange = []
    dataTable?.forEach(value => {
      if (value.id === rowClick.id) {
        // 変更あり
        rowClick['changeFlag'] = true
        dataChange.push(rowClick)

        if (tempRowKeys.indexOf(rowClick.id) === -1) {
          // selectedRowKeysに該当のidが存在しない場合
          tempRowKeys.push(rowClick.id)
        }
      } else {
        // そのまま追加
        dataChange.push(value)
      }
    })

    let tempRows = []
    if (dataChange.length === dataTable.length) {

      dataChange.forEach(item => {
        if (tempRowKeys.indexOf(item.id) > -1) {
          // 選択状態に含まれているか
          tempRows.push(item)
        }
      })
      // 配列と選択行（複数）を更新
      this.setState({
        dataSource: dataChange,
        selectedRowKeys: tempRowKeys,
        selectedRows: tempRows,
        selectedRow: rowClick
      })
    }
  }

  /**
   * null のチェック
   * @param {*} namePath
   * @returns
   */
  checkDataNull(namePath) {
    if (this.formRef.current?.getFieldValue(namePath)) {
      if (this.formRef.current?.getFieldValue(namePath) && this.formRef.current?.getFieldValue(namePath) != '0000-00-00') {
        return this.formRef.current?.getFieldValue(namePath)
      }
    }
    return null
  }

  /**
   * 有効期限　開始日
   */
  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('insurerStartDate_T') ? this.formRef.current?.getFieldValue('insurerStartDate_T').format('YYYY/MM/DD') : null
    let dateEnd = this.formRef.current?.getFieldValue('insurerEndDate_T') ? this.formRef.current?.getFieldValue('insurerEndDate_T').format('YYYY/MM/DD') : null
    if ((dateEnd && (dateStart > dateEnd))) {
      this.formRef.current?.setFieldsValue({
        insurerStartDate_T: this.formRef.current?.getFieldValue('insurerEndDate_T')
      })
    }
    this.checkDateFormat(dateStart, 'insurerStartDate_T')
  }

  /**
   * 有効期限　終了日
   */
  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('insurerStartDate_T') ? this.formRef.current?.getFieldValue('insurerStartDate_T').format('YYYY/MM/DD') : null
    let dateEnd = this.formRef.current?.getFieldValue('insurerEndDate_T') ? this.formRef.current?.getFieldValue('insurerEndDate_T').format('YYYY/MM/DD') : null
    if ((!dateEnd && dateStart) || (dateStart && (dateStart > dateEnd))) {
      this.formRef.current?.setFieldsValue({
        insurerEndDate_T: this.formRef.current?.getFieldValue('insurerStartDate_T')
      })
    }
    this.checkDateFormat(dateEnd, 'insurerEndDate_T')
  }

  /**
   * 日付　フォーマットチェック
   * @param {*} value
   * @param {*} namePath
   */
  checkDateFormat(value, namePath) {
    if (value && value.trim().length > 0) {
      if (moment(value).format('YYYY/MM/DD') === 'Invalid date' && value.length < 8) {
        message.error('不正な日付です')
        this.setValidDate(namePath, true)
      } else {
        this.setValidDate(namePath, false)
        this.changeData(namePath)
      }
    } else {
      this.setValidDate(namePath, false)
      this.changeData(namePath)
    }
  }

  setValidDate(namePath, value) {
    if (namePath === 'insurerStartDate_T') {
      this.setState({ checkDateStart: value })
    }
    if (namePath === 'insurerEndDate_T') {
      this.setState({ checkDateEnd: value })
    }
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  showOfficeInfoRetrievalQuery(condition) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={
              condition
                ? this.formRef.current?.getFieldValue('OfficeCodeF')
                : this.formRef.current?.getFieldValue('OfficeCodeT')
            }
            Lio_BranchStoreCode={
              condition
                ? this.formRef.current?.getFieldValue('BranchStoreCodeF')
                : this.formRef.current?.getFieldValue('BranchStoreCodeT')
            }
            onFinishScreen={(output) => {
              if (output) {
                if (condition) {
                  this.setState({ checkOfficeCodeF: false })
                  this.formRef.current?.setFieldsValue({
                    OfficeCodeF: output.Lio_OfficeCode,
                    BranchStoreCodeF: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : null,
                    office_kanji_name: output.Lo_Kanji_Name,
                  })
                  this.forceUpdate()
                  setTimeout(() => {
                    this.checkConditionCode()
                  }, 500)
                } else {
                  this.setState({ checkOfficeCodeT: false })
                  this.formRef.current?.setFieldsValue({
                    OfficeCodeT: output.Lio_OfficeCode,
                    BranchStoreCodeT: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : null,
                    office_kanji_nameT: output.Lo_Kanji_Name,
                  })
                  this.forceUpdate()
                  setTimeout(() => {
                    this.checkConditionCode()
                  }, 500)
                }
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  setFieldOfficeCodeF() {
    if (!this.state.checkOfficeCodeF) {
      this.formRef.current?.setFieldsValue({
        OfficeCodeT: this.formRef.current?.getFieldValue('OfficeCodeF'),
        BranchStoreCodeT: this.formRef.current?.getFieldValue('BranchStoreCodeF'),
        office_kanji_nameT: this.formRef.current?.getFieldValue('office_kanji_name')
      })
      this.forceUpdate()
      this.setState({ checkOfficeCodeT: false })
      setTimeout(() => {
        this.checkConditionCode()
      }, 300)
    }
  }

  /**
   * チェック
   */
  checkConditionCode() {
    let checkCondition
    if (this.state.checkOfficeCodeF || this.state.checkOfficeCodeT) {
      checkCondition = true
    } else {
      checkCondition = false
      let stsBelongsTakeover = false
      if (this.formRef.current?.getFieldValue('OfficeCodeF') === this.formRef.current?.getFieldValue('OfficeCodeT')) {
        stsBelongsTakeover = true
      }
      this.formRef.current?.setFieldsValue({
        StsBelongsTakeover: stsBelongsTakeover
      })
    }
    this.setState({ checkCondition: checkCondition })
  }


  CheckError() {
    if (this.isEmpty(this.formRef.current?.getFieldValue('OfficeCodeF')) || this.state.checkOfficeCodeF) {
      message.error('指定の事業所は存在しません')
      this.branchCode?.current?.focus()
      return
    }
  }

  /**
   * チェックボックス 選択
   * @param {*} record
   * @param {*} selected
   */
  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }


  /**
   * 更新処理
   */
  update() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      insurerStartDate_T: this.formRef.current?.getFieldValue('insurerStartDate_T') ? this.formRef.current.getFieldValue('insurerStartDate_T').format('YYYY/MM/DD') : '',
      insurerEndDate_T: this.formRef.current?.getFieldValue('insurerEndDate_T') ? this.formRef.current?.getFieldValue('insurerEndDate_T')?.format('YYYY/MM/DD') : '',
      OfficeCodeT: this.formRef.current?.getFieldValue('OfficeCodeT'),
      BranchStoreCodeT: this.formRef.current?.getFieldValue('BranchStoreCodeT'),
      selectedData: this.state.selectedRows
    }
    DepartmentChangeOnceAction.update(params)
      .then((res) => {
        if (res) {
          this.getDataTableSelect()
        }
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='department-change-once'>
        <Card title='部署一括変更' >
          <Form
            autoComplete='off'
            ref={this.formRef}
            style={{ marginBottom: 0 }}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <Space>
                      <Form.Item
                        label='事務所元'
                        name='OfficeCodeF'
                        {...labelCol}
                      >
                        <Input.Search
                          ref={this.office}
                          className='input-search-size-number-8'
                          onSearch={() => {
                            this.showOfficeInfoRetrievalQuery(1)
                          }} />
                      </Form.Item>

                      <Form.Item name='BranchStoreCodeF'
                      >
                        <Input.Search
                          className='input-search-size-number-4'
                          ref={this.branchCode}
                          onSearch={() => {
                            !this.formRef.current?.getFieldValue('OfficeCodeF')
                              ? this.CheckError()
                              : this.showOfficeInfoRetrievalQuery(1)
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 9) {
                              !this.formRef.current?.getFieldValue('OfficeCodeF')
                                ? this.CheckError()
                                : this.showOfficeInfoRetrievalQuery(1)
                              if (!this.formRef.current?.getFieldValue('OfficeCodeF')) e.preventDefault()
                            }
                          }}
                          // readOnly={!this.formRef.current?.getFieldValue('OfficeCodeF')}
                          onClick={() => {
                            this.CheckError()
                          }}


                        />
                      </Form.Item>
                      <div style={{ marginTop: 5, marginRight: 5 }} >
                        {this.formRef.current?.getFieldValue('office_kanji_name')}
                      </div>
                    </Space>
                    {/* <div>
                      <ArrowDownOutlined style={{ marginLeft: 150 }} />
                    </div> */}
                    <Space >
                      <Form.Item
                        label='事業所先'
                        name='OfficeCodeT'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-8'
                          onClick={() => {
                            this.CheckError()
                            if (this.isEmpty(this.formRef.current?.getFieldValue('OfficeCodeT'))) {
                              this.setFieldOfficeCodeF()
                            }
                          }}

                          onSearch={() => {
                            if (this.isEmpty(this.formRef.current?.getFieldValue('OfficeCodeF')) || this.state.checkOfficeCodeF) {
                              message.error('指定の事業所は存在しません')
                              this.branchCode?.current?.focus()
                              return
                            } else {
                              if (this.isEmpty(this.formRef.current?.getFieldValue('OfficeCodeT'))) {
                                this.setFieldOfficeCodeF()
                              }
                              this.showOfficeInfoRetrievalQuery()
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='BranchStoreCodeT'
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Search
                          className='input-search-size-number-4'
                          onClick={() => {
                            this.CheckError()
                            if (this.isEmpty(this.formRef.current?.getFieldValue('BranchStoreCodeT'))) {
                              this.setFieldOfficeCodeF()
                            }
                          }}

                          onSearch={() => {
                            if (this.isEmpty(this.formRef.current?.getFieldValue('OfficeCodeF')) || this.state.checkOfficeCodeF) {
                              message.error('指定の事業所は存在しません')
                              this.branchCode?.current?.focus()
                              return
                            } else {
                              if (this.formRef.current?.getFieldValue('BranchStoreCodeT')?.length < 1) {
                                this.setFieldOfficeCodeF()
                              }
                              this.showOfficeInfoRetrievalQuery()
                            }
                          }}
                        />
                      </Form.Item>
                      <div style={{ marginTop: 5, marginRight: 5 }} >
                        {this.formRef.current?.getFieldValue('office_kanji_nameT')}
                      </div>
                    </Space>

                  </div>
                  <div className='box_search_inner'>
                    <Form.Item
                      label='引継'
                      name='StsBelongsTakeover'
                      valuePropName='checked'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Checkbox />
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => this.getDataTableSelect()}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>

              </div>

              <div className='box_inner_horizontal'>
                <div style={{ width: '50%' }}>
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.dataSource}
                    pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
                    rowKey={(record) => record.id}
                    scroll={{ x: 500, y: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE) ? 555 : 605 }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          // this.formRef.current?.setFieldsValue({ selectedRow: record })
                          // this.forceUpdate()
                          this.setState({ selectedRow: record })
                          // 保険者データを取得
                          this.getInsuranceInfoSet(record)
                        }
                      }
                    }}
                    rowSelection={{
                      type: 'checkbox',
                      selectedRowKeys: this.state.selectedRowKeys,
                      onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRows: selectedRows })
                      },
                      onSelect: (record, selected) => {
                        // 選択
                        this.onSelect(record, selected)
                      },
                      onSelectAll: (selected) => {
                        // 全選択
                        this.onSelectAll(selected)
                      }
                    }}
                  >
                    <Table.Column
                      title='個人番号'
                      dataIndex='personal_number_id'
                      width={120}
                      render={(value, record, index) => {
                        return (
                          <div style={{ display: 'flex', width: '100%', fontWeight: record.changeFlag ? 'bold' : '' }} >
                            <Tooltip title={'変更あり'}>
                              <div style={{ display: record.changeFlag ? '' : 'none' }}>{'*'}</div>
                            </Tooltip>
                            <div style={{ width: '100%' }} />
                            <div>{value}</div>
                            {/* <span style={this.state.selectedRowKeys.indexOf(record.id) != -1 ? color.colorCheck : color.colorUncheck}>{value}</span> */}
                          </div>
                        )
                      }} />
                    <Table.Column
                      title='氏名'
                      dataIndex='kanji_name'
                      render={(value, record, index) => {
                        return (
                          // <span style={this.state.selectedRowKeys.indexOf(record.id) != -1 ? color.colorCheck : color.colorUncheck}>{value}</span>
                          <span style={{ fontWeight: record.changeFlag ? 'bold' : '' }}>{value}</span>
                        )
                      }} />
                    <Table.Column
                      title='続柄'
                      dataIndex='name'
                      width={70}
                      render={(value, record, index) => {
                        return (
                          // <span style={this.state.selectedRowKeys.indexOf(record.id) != -1 ? color.colorCheck : color.colorUncheck}>{value}</span>
                          <span style={{ fontWeight: record.changeFlag ? 'bold' : '' }}>{value}</span>
                        )
                      }} />
                    <Table.Column title='保険証記号/番号' dataIndex='Expression_13'
                      render={(value, record, index) => {
                        return (
                          // <span style={this.state.selectedRowKeys.indexOf(record.id) != -1 ? color.colorCheck : color.colorUncheck}>{value}</span>
                          <span style={{ fontWeight: record.changeFlag ? 'bold' : '' }}>{value}</span>
                        )
                      }} />
                  </Table>
                </div>

                <div style={{ width: '50%' }}>
                  <div className='box_container' style={{ gap: 0 }}>
                    <div className='table_header_filter' style={{ fontWeight: 'bold' }}>
                      {'事業所元' + this.state.officeInfoF}
                    </div>
                    <div className='box_border' style={{ gap: 0, padding: 5 }}>
                      <Space>
                        <Form.Item
                          label='保険者'
                          className='input-search-size-number-10'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <span>{this.formRef.current?.getFieldValue('insurerNumber_F')} </span>
                        </Form.Item>
                        <div style={{ marginTop: 5 }}>
                          {this.formRef.current?.getFieldValue('insurerKanjiName_F')}
                        </div>
                      </Space>

                      <Space>
                        <Form.Item
                          label='保険記号'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <div className='input-size-12'>
                            {this.formRef.current?.getFieldValue('insurerCardSymbol_F')}
                          </div>
                        </Form.Item>

                        <Form.Item
                          label='/ 保険番号'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <div className='input-size-12'>
                            {this.formRef.current?.getFieldValue('insurerCardNumber_F')}
                          </div>
                        </Form.Item>
                      </Space>

                      <Form.Item
                        label='受給番号'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <div className='input-size-12'>
                          {this.formRef.current?.getFieldValue('recipientNumber_F')}
                        </div>
                      </Form.Item>

                      <Form.Item
                        label='有効期限'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <span> {this.checkDataNull('insurerStartDate_F') ? moment(this.checkDataNull('insurerStartDate_F'))?.format('YYYY/MM/DD') : null}</span>
                        <span style={{ display: this.checkDataNull('insurerEndDate_F') ? '' : 'none' }} > ~ </span>
                        <span>{this.checkDataNull('insurerEndDate_F') ? moment(this.checkDataNull('insurerEndDate_F'))?.format('YYYY/MM/DD') : null}</span>
                      </Form.Item>

                      <Form.Item
                        label='続柄'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <span> {this.formRef.current?.getFieldValue('relationshipName_F')} </span>
                      </Form.Item>

                      <Form.Item
                        label='雇用形態'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <span> {this.formRef.current?.getFieldValue('employmentName_F')} </span>
                      </Form.Item>

                      <Form.Item
                        label='職種'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <span> {this.formRef.current?.getFieldValue('jobName_F')} </span>
                      </Form.Item>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                      <ArrowDownOutlined />
                    </div>


                    <div className='table_header_filter' style={{ fontWeight: 'bold' }}>
                      {'事業所先' + this.state.officeInfoT}
                    </div>
                    <div className='box_border' style={{ gap: 0, padding: 5 }}>
                      <Space>
                        <Form.Item
                          label='保険者'
                          name='insurerNumber_T'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <Input.Search
                            className='input-search-size-number-10'
                            disabled={this.state.selectedRow?.id > 0 ? false : true}
                            onSearch={(value, event) => {
                              if (this.state.selectedRow?.id > 0) {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1200,
                                    component: (
                                      <WS0246001_InsurerInfoSearchQuery
                                        Li_insurerSearch={value}
                                        onFinishScreen={(output) => {
                                          if (output) {
                                            this.formRef.current?.setFieldsValue({
                                              insurerKanjiName_T: output.Lo_Name,
                                              insurerNumber_T: output.Lo_InsurerCode
                                            })
                                            this.changeData('insurerNumber_T')
                                          }
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }
                            }}
                          />
                        </Form.Item>
                        <div style={{ marginTop: 5 }}>
                          {this.formRef.current?.getFieldValue('insurerKanjiName_T')}
                        </div>
                      </Space>

                      <Space>
                        <Form.Item
                          label='保険記号'
                          name='insurerCardSymbol_T'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <Input
                            className='input-size-12'
                            disabled={this.state.selectedRow?.id > 0 ? false : true}
                            onPressEnter={() => this.changeData('insurerCardSymbol_T')}
                            onBlur={() => this.changeData('insurerCardSymbol_T')}
                          />
                        </Form.Item>

                        <Form.Item
                          label='/ 保険番号'
                          name='insurerCardNumber_T'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <Input
                            className='input-size-12'
                            disabled={this.state.selectedRow?.id > 0 ? false : true}
                            onPressEnter={() => this.changeData('insurerCardNumber_T')}
                            onBlur={() => this.changeData('insurerCardNumber_T')}
                          />
                        </Form.Item>
                      </Space>

                      <Form.Item
                        label='受給番号'
                        name='recipientNumber_T'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <Input
                          className='input-size-12'
                          disabled={this.state.selectedRow?.id > 0 ? false : true}
                          onPressEnter={() => this.changeData('recipientNumber_T')}
                          onBlur={() => this.changeData('recipientNumber_T')}
                        />
                      </Form.Item>

                      <Space>
                        <Form.Item
                          label='有効期限'
                          name='insurerStartDate_T'
                          style={marginStyle}
                          {...labelCol2}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format='YYYY/MM/DD'
                            disabled={this.state.selectedRow?.id > 0 ? false : true}
                            onChange={() => { this.checkDateStart() }}
                            onBlur={() => { this.checkDateStart() }}
                          />
                        </Form.Item>
                        <span style={{ margin: '10px 5px 0 5px' }}>~</span>

                        <Form.Item
                          label=''
                          name='insurerEndDate_T'
                          style={marginStyle}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format='YYYY/MM/DD'
                            disabled={this.state.selectedRow?.id > 0 ? false : true}
                            onChange={() => { this.checkDateEnd() }}
                            onBlur={() => { this.checkDateEnd() }}
                          />
                        </Form.Item>
                      </Space>

                      <Form.Item
                        label='続柄'
                        name='relationship_T'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <Select
                          style={{ width: '150px' }}
                          disabled={this.state.selectedRow?.id > 0 ? false : true}
                          onChange={(value) => this.changeData('relationship_T', value)} >
                          {this.state.relationshipList.map((value) => (
                            <Select.Option
                              key={'relationshipName_T-' + Math.random()}
                              value={value.key}
                            >
                              {value.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label='雇用形態'
                        name='employment_T'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <Select
                          style={{ width: '150px' }}
                          disabled={this.state.selectedRow?.id > 0 ? false : true}
                          onChange={(value) => this.changeData('employment_T', value)} >
                          <Select.Option key={'employmentName_T-a'} value={''}>{''}</Select.Option>
                          {this.state.employmentList.map((value) => (
                            <Select.Option
                              key={'employmentName_T-' + Math.random()}
                              value={value.key}
                            >
                              {value.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label='職種'
                        name='job_T'
                        style={marginStyle}
                        {...labelCol2}
                      >
                        <Select
                          style={{ width: '150px' }}
                          disabled={this.state.selectedRow?.id > 0 ? false : true}
                          onChange={(value) => this.changeData('job_T', value)} >
                          <Select.Option key={'jobName_T-a'} value={''} >{''}</Select.Option>
                          {this.state.jobList.map((value) => (
                            <Select.Option
                              key={'jobName_T-' + Math.random()}
                              value={value.key}
                            >
                              {value.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                Modal.confirm({
                  content: '選択された個人の所属情報を更新しますか？',
                  okText: '更　新',
                  cancelText: 'キャンセル',
                  onOk: () => {
                    // 更新
                    this.update()
                  },
                })
              }}
              disabled={this.state.selectedRowKeys.length > 0 ? false : true}
            >
              <span className='btn_label'>
                更新
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0345001_DepartmentChangeOnce)
