import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Card, Col, Row, Table, Form, Input, Select, PageHeader, Checkbox, Layout, Button, AutoComplete } from "antd";
import ReceiptProcessSubAction from "redux/CounterBusiness/Counter/ReceiptProcessSub.action";
import GetImage from "constants/Images";
import WS0946006_ReceiptIssueOnline from "pages/BL_AccountingBusiness/SMIYA0502_ReceiptPreIssue20/WS0946006_ReceiptIssueOnline";
import moment from 'moment';
import ModalDraggable from "components/Commons/ModalDraggable";

const { Footer } = Layout;

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '60px' } }
}

const formatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
class WS2622003_ReceiptProcessSub extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_ReceiptManageNum1Sheet: PropTypes.any,
    Li_ReceiptManageNum2Nd: PropTypes.any,
    Li_ReceiptManageNum3Rd: PropTypes.any,
    Li_Identify: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }
  constructor(props) {
    super(props);

    // document.title = '領収処理SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      initialValues: {},
      getIndex1: {},
      getIndex2: {},
      getIndex3: {},
      OptionInspect1: [],
      OptionInspect2: [],
      OptionInspect3: [],
      ScreenData: {},
      Cards: []
    };
  }

  handleSelectRowsTableFirst = selectedRowTableFirst => {
    this.setState({ selectedRowTableFirst });
  };

  componentDidMount = () => {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.forceUpdate()
      this.loadData()

    }
  }

  loadData() {
    const prams = {
      Li_SpecifiedIdentify: this.props.Li_SpecifiedIdentify
    }

    ReceiptProcessSubAction.getScreenData({
      ...prams,
      Li_ReserveNum: this.props.Li_ReserveNum
    })
      .then(res => {
        this.setFormFieldValue('sts1', res && res.sts1 ? res.sts1 : 0)
        this.setFormFieldValue('sts2', res && res.sts2 ? res.sts2 : 0)
        this.setFormFieldValue('sts3', res && res.sts3 ? res.sts3 : 0)

        let cards = [
          { sts: res.sts1, receipt: res.ReceiptManageNum1Sheet, title: res.Title1Sheet },
          { sts: res.sts2, receipt: res.ReceiptManageNum2Nd, title: res.Title2Nd },
          { sts: res.sts3, receipt: res.ReceiptManageNum3Rd, title: res.Title3Rd }
        ]

        this.setState({
          ...this.state,
          ScreenData: res,
          Cards: cards
        })

        const params = {
          Cards: cards,
          Li_ReserveNum: this.props.Li_ReserveNum,
        }

        ReceiptProcessSubAction.getIndex(params)
          .then(res => {
            res.forEach((item, i) => {
              // const index = item.identify
              // let array = []
              // item.W1_destination_name.forEach((item, l) => {
              //   array = [
              //     ...array,
              //     {
              //       value: item
              //     }
              //   ]
              // })

              // this.setState({
              //   ...this.state,
              //   [`getIndex${index}`]: item,
              //   [`OptionInspect${index}`]: item.OptionInspectData,
              //   [`W1_destination_name${index}`]: array,
              // })

              this.renderForm(item, i)
            })

            this.forceUpdate()
          })
      })
  }

  renderForm(values, i) {
    // const value = this.state[`getIndex${i}`]
    // this.setFormFieldValue(`ReceiptDateChar${i}`, value && value?.ReceiptDateChar ? moment(value?.ReceiptDateChar) : '')
    // this.setFormFieldValue(`W1_destination_name${i}`, value && value?.W1_destination_name[0] ? value?.W1_destination_name[0] : '')
    // this.setFormFieldValue(`receiptNumber${i}`, value && value?.receiptNumber ? value?.receiptNumber : '-')
    // this.setFormFieldValue(`W1_subject${i}`, value && value?.W1_subject ? value?.W1_subject : '')
    // this.setFormFieldValue(`DepositAndWithdrawalForm${i}`, value && value?.DepositAndWithdrawalForm ? value?.DepositAndWithdrawalForm : '')
    // this.setFormFieldValue(`identify${i}`, value && value?.identify ? value?.identify : '')

    let array = []
    values.W1_destination_name.forEach((item, l) => {
      array = [
        ...array,
        {
          value: item
        }
      ]
    })

    this.setState({
      ...this.state,
      [`getIndex${i + 1}`]: values,
      [`OptionInspect${i + 1}`]: values.OptionInspectData,
      [`W1_destination_name${i + 1}`]: array,
    })

    this.setFormFieldValue(`ReceiptDateChar${i + 1}`, values && values?.ReceiptDateChar ? moment(values?.ReceiptDateChar) : '')
    this.setFormFieldValue(`W1_destination_name${i + 1}`, values && values?.W1_destination_name[0] ? values?.W1_destination_name[0] : '')
    this.setFormFieldValue(`receiptNumber${i + 1}`, values && values?.receiptNumber ? values?.receiptNumber : '-')
    this.setFormFieldValue(`W1_subject${i + 1}`, values && values?.W1_subject ? values?.W1_subject : '')
    this.setFormFieldValue(`DepositAndWithdrawalForm${i + 1}`, values && values?.DepositAndWithdrawalForm ? values?.DepositAndWithdrawalForm : '')
    this.setFormFieldValue(`identify${i + 1}`, values && values?.identify ? values?.identify : '')

  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }

  onFinish = (values) => {
    let list = [];
    let receiptManagementNumberList = [];

    this.state.Cards.forEach((s, i) => (
      list.push({
        receipt: s.receipt,
        price: s.title,
        destination_name: this.formRef?.current?.getFieldValue(`W1_destination_name${i + 1}`),
        subject: this.formRef?.current?.getFieldValue(`W1_subject${i + 1}`),
        receiptDateChar: moment(this.formRef?.current?.getFieldValue(`ReceiptDateChar${i + 1}`)).format("YYYY/MM/DD"),
        receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
        flag: this.formRef?.current?.getFieldValue(`sts${i + 1}`) ? 1 : 0
      })
    ));

    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      list: list
    }

    //チェックが入っているモノのreceiptを取得
    this.state.Cards.forEach((s, i) => {
      if (this.formRef?.current?.getFieldValue(`sts${i + 1}`)) {
        receiptManagementNumberList.push({
          receiptManagementNumber: s.receipt,
          receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
        })
      }
    });

    ReceiptProcessSubAction.confirmF12(params)
      .then(() => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 400,
            component: (
              <WS0946006_ReceiptIssueOnline
                counterReceiptProcess={true}
                receiptManageNum={this.state.ScreenData.ReceiptManageNum1Sheet}
                receiptDateChar={moment(this.formRef.current?.getFieldValue('ReceiptDateChar1')).format("YYYY/MM/DD")}
                Li_ReserveNum={this.props.Li_ReserveNum}
                Li_BillingManageNum={this.props.Li_BillingManageNum}
                Li_ReceiptDateOn={this.state.ScreenData.ReceiptDateOn}
                Li_DestinationName={this.formRef.current?.getFieldValue('W1_destination_name1')}
                Li_Receipts={this.state.ScreenData.Receipts}
                Li_TaskType={1}
                receiptManagementNumberList={receiptManagementNumberList}

                onFinishScreen={(output) => {
                  this.props.onFinishScreen({ StsSend: true, nameScreen: 'WS2621001_PaymentProcessSub' })
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: false,
                    },
                  });
                }}
              />),
          },
        })
      })
  }

  returnCard() {
    const { selectedRowTableFirst } = this.state
    return (<div style={{ display: 'block' }}>
      {
        <div style={{ display: 'flex', gap: 10 }}>
          {this.state.Cards.map((s, i) => (
            // eslint-disable-next-line react/jsx-key
            <Card
              key={i}
              hidden={!s.sts > 0}
              style={{ overflowX: 40, overflowY: 40, paddingTop: 0, width: '100%' }}
            >

              <div style={{ textAlign: 'right' }}>
                領収書No{': '}{this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`)}
              </div>

              <PageHeader
                style={{ paddingTop: 0, paddingBottom: 0 }}
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Form.Item
                      name={`sts${s.sts > 0 ? i + 1 : ''}`}
                      valuePropName="checked"
                    >
                      <Checkbox style={{ fontSize: 20 }}>
                        <h4 style={{ marginBottom: 0 }}>￥{formatter(s.title)}</h4>
                      </Checkbox>
                    </Form.Item>
                  </div>}
              />
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name={`ReceiptDateChar${i + 1}`}
                    style={{ width: '100%' }}
                    label="領収日"
                    {...labelCol}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      format={'YYYY/MM/DD (ddd)'}
                      style={{ float: 'right', width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name={`W1_destination_name${i + 1}`}
                    label="宛先"
                    {...labelCol}
                  >
                    <AutoComplete
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state[`W1_destination_name${i + 1}`]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name={`W1_subject${i + 1}`}
                    label="コース"
                    {...labelCol}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name={`DepositAndWithdrawalForm${i + 1}`}
                    label="入金区分"
                    {...labelCol}
                  >
                    <Select>
                      <Select.Option key={1} value={1}>現金</Select.Option>
                      <Select.Option key={2} value={2}>クレジット</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}

              <Table
                bordered={true}
                showHeader={true}
                dataSource={this.state[`OptionInspect${i + 1}`]}
                style={{ display: this.state[`OptionInspect${i + 1}`].length === 0 ? 'none' : '' }}
                rowKey={(record) => record.id}
                pagination={false} scroll={{ y: 500 }}
              >
                <Table.Column
                  width={150}
                  title="オプション"
                  render={(text, record, index) => (
                    <div style={{ display: 'flex' }}>
                      <img
                        style={{
                          width: 'fit-content',
                          height: 'fit-content',
                          marginRight: '10px',
                          marginTop: 'auto',
                          marginBottom: 'auto'
                        }}
                        src={GetImage(record.Expresstion_8)}
                        alt="icon"
                      />
                      <div>{record.set_name}</div>
                    </div>
                  )}
                  dataIndex="set_name"
                />
                <Table.Column
                  width={60}
                  title="金額"
                  dataIndex="Expresstion_6"
                  render={(text, record, index) => (
                    <div style={{ textAlign: 'light' }}>
                      <span>{formatter(record.Expresstion_6)}</span>
                    </div>
                  )} />
              </Table>
            </Card>
          ))}
        </div>
      }

      <Footer style={{ padding: 0, paddingTop: '10px', marginTop: '10px' }}>
        <div className="box_inner_horizontal" style={{ justifyContent: "space-between", paddingLeft: '10px', paddingRight: '10px' }}>
          <Form.Item label="受領額" >
            <span>{formatter(this.state.ScreenData.Receipts ? this.state.ScreenData.Receipts : '')}</span>
          </Form.Item>
          {
            this.state.ScreenData.Otsuri === 0 || this.state.ScreenData.Otsuri === null ?
              <Col span={3}></Col>
              :
              <Form.Item label="釣銭">
                <span>{formatter(this.state.ScreenData.Otsuri ? this.state.ScreenData.Otsuri : '')}</span>
              </Form.Item>
          }
          <Col span={3} style={{ justifyContent: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right" }}>
              確定
            </Button>
          </Col>
        </div>
      </Footer>
    </div >)
  }

  render() {
    const { selectedRowTableFirst, OptionInspect } = this.state
    const rowSelectionTableFirst = {
      selectedRowTableFirst,
      onChange: this.handleSelectRowsTableFirst
    }
    return (
      <div className="receipt-process-sub">
        <Card
          title="領収処理"
        >
          <Form ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ ...this.state.initialValues }}
          >
            {this.returnCard()}
          </Form>

        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.props.onFinishScreen({
              StsSend: true,
              nameScreen: 'WS2621001_PaymentProcessSub'
            })

            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2622003_ReceiptProcessSub);
