import PersonalNumberIntegrationService from 'services/basicInfo/PersonalNumberIntegration/PersonalNumberIntegrationService'
import { message } from 'antd'

const PersonalNumberIntegrationAction = {
  index(params) {
    return PersonalNumberIntegrationService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  changePersonalNumber(params) {
    return PersonalNumberIntegrationService.changePersonalNumber(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  integrate(params) {
    return PersonalNumberIntegrationService.integrate(params)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default PersonalNumberIntegrationAction
