import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Modal, Button, Input, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import CourseDepartmentspecificFormatAction from 'redux/SK_IntroductionLetter/CourseDepartmentspecificFormat/CourseDepartmentspecificFormat.action.js'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS0923004_DepartmentspecificEdit from 'pages/SK_IntroductionLetter/V4SK0010010_CourseDepartmentspecificFormat/WS0923004_DepartmentspecificEdit.jsx'

class WS0923002_DepartmentspecificFormat extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コース・診療科別書式保守'

    this.state = {
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      course_code: ''
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      course_code: this.props.course_code
    })
    this.setState({
      course_code: this.props.course_code
    })
    this.getFormatList()
  }

  getFormatList = () => {
    let params = {
      Li_ConsultCourse: this.formRef.current?.getFieldValue('course_code')
    }
    CourseDepartmentspecificFormatAction.getFormatList(params)
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  openEditDisplay = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0923004_DepartmentspecificEdit
            id={record.id}
            department={record.department}
            department_name={record.department_name}
            format_name={record.format_name}
            course_code={this.state.course_code}
            onFinishScreen={(output) => {
              this.closeModal()
              this.getFormatList()
            }}
          />
        )
      }
    })
  }

  addDepartmentspecific = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0923004_DepartmentspecificEdit
            course_code={this.state.course_code}
            onFinishScreen={(output) => {
              this.closeModal()
              this.getFormatList()
            }}
          />
        )
      }
    })
  }

  deleteDepartmentspecificFormat = (record) => {
    let param = {
      id: record.id
    }
    CourseDepartmentspecificFormatAction.deleteDepartmentspecificFormat(param)
      .then(res => {
        this.getFormatList()
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render = () => {
    return (
      <div className='departmentspecific-format' style={{ width: 1000 }}>
        <Card title='診療科別書式保守'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Form.Item name='course_code' style={{ marginLeft: '10px', marginBottom: 0 }}>
                <div style={{ fontSize: 'large' }}>{this.state.course_code ? this.state.course_code : '【標準】'}</div>
                {/* <div>{this.formRef.current?.getFieldValue('course_code')}</div> */}
              </Form.Item>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.openEditDisplay(record)
                    }
                  };
                }}
              >
                <Table.Column
                  title='診療科'
                  dataIndex='department'
                  className='column-size-10'
                />
                <Table.Column
                  title='名称'
                  dataIndex='department_name'
                  className='column-size-60'
                />
                <Table.Column
                  title='書式'
                  dataIndex='format_name'
                  className='column-size-60'
                />
                <Table.Column
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => this.addDepartmentspecific()}
                    />
                  }
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              onClick={() => this.openEditDisplay(record)}
                            >
                              編集
                            </Menu.Item>
                            <Menu.Item
                              key='2'
                              onClick={() => {
                                Modal.confirm({
                                  content: '削除してもよろしいですか？',
                                  onOk: () => this.deleteDepartmentspecificFormat(record)
                                })
                              }}
                            >
                              削除
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0923002_DepartmentspecificFormat)