import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Checkbox, Button, Space, Row, Col, Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import WS1538001_MedicalInstitutionNumberQuerySub from 'pages/SM_SystemMaintenance/V4SM0000100_FacilityConsumptionTaxSetting/WS1538001_MedicalInstitutionNumberQuerySub.jsx';

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

import  ModalDraggable  from "components/Commons/ModalDraggable";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class WS1537001_MedicalInstitutionNumberUpdateSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '医療機関番号更新SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initialValues: {
        Address: "",
        FacilityName: "",
        MedicalInstitutionNum: 0,
        PhoneNum: "",
        StsHealthGuide: false,
        StsJapanHospitalKyokai: false,
        StsKyokai: false,
        StsSpecificMedicalExam: false,
        ZipCode: "",
      },
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.callAPILoadData({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.callAPILoadData({});
    }
  }

  callAPILoadData = (params) => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API001537001001", params)
      .then((res) => {
        this.formRef.current?.setFieldsValue(res)
      }).finally(() => this.setState({ isLoading: false }))
  }

  onFinish(values) {
    Modal.confirm({
      width: 250,
      title: '更新しますか？',
      icon: <QuestionCircleOutlined style={{ fontSize: '25px', color: '#08c' }} />,
      onOk: () => {
        this.setState({ isLoading:  true })
        VenusApiRoutesV2.callApi("API001537001003", values)
          .then((res) => {
            this.formRef.current?.setFieldsValue(res)
          }).finally(() => this.setState({ isLoading: false }))
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
          visible: false,
      }
    });
  }

  render() {
    return (
      <div className="medical-institution-number-update-sub">
        <Card title="医療機関番号更新SUB">
          <Form
            ref={this.formRef}
            {...formItemLayout}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="MedicalInstitutionNum"
              label="医療機関番号"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="FacilityName"
              label="施設名称"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="ZipCode"
              label="郵便番号"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="Address"
              label="住所"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="PhoneNum"
              label="電話番号"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="StsGroup"
              label="更新対象"
            >
              <Row>
                <Col span={24}>
                  <Form.Item name="StsHealthGuide" valuePropName="checked" label="">
                    <Checkbox style={{ lineHeight: '32px' }}>
                      STS[協会けんぽ]
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="StsKyokai" valuePropName="checked" label="">
                    <Checkbox style={{ lineHeight: '32px' }}>
                      特定健診
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="StsSpecificMedicalExam" valuePropName="checked" label="">
                    <Checkbox style={{ lineHeight: '32px' }}>
                      保健指導
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="StsJapanHospitalKyokai" valuePropName="checked" label="">
                    <Checkbox style={{ lineHeight: '32px' }}>
                      日本病院会
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Space style={{ float: 'right' }}>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 800,
                      component: (
                        <WS1538001_MedicalInstitutionNumberQuerySub
                          onFinishScreen={({}) => {
                            this.closeModal();
                          }}
                        />)
                    }
                  })
                }}
              >照会</Button>
              <Button type="primary"
                onClick={() => this.onFinish(this.formRef.current?.getFieldValue())} 
              >実行</Button>
            </Space>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1537001_MedicalInstitutionNumberUpdateSub);
