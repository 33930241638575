import BillingAddressSettingService from 'services/basicInfo/ContractInfoMaintain/BillingAddressSettingService.js'
import { message, } from "antd"

const BillingAddressSettingAction = {
  addBillingAddress(params) {
    return BillingAddressSettingService.addBillingAddress(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addBillingAddress_reservation(params) {
    return BillingAddressSettingService.addBillingAddress_reservation(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  loadTableData(params) {
    return BillingAddressSettingService.loadTableData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteAddress(params) {
    return BillingAddressSettingService.deleteAddress(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteBillingAddress(params) {
    return BillingAddressSettingService.deleteBillingAddress(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveBillingAmount(params) {
    return BillingAddressSettingService.saveBillingAmount(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  loadPersonalReserveData(params) {
    return BillingAddressSettingService.loadPersonalReserveData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  reacquisition(params) {
    return BillingAddressSettingService.reacquisition(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  savePersonalBillingAmount(params) {
    return BillingAddressSettingService.savePersonalBillingAmount(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addAddressToBA(params) {
    return BillingAddressSettingService.addAddressToBA(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteAddressFromBA(params) {
    return BillingAddressSettingService.deleteAddressFromBA(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default BillingAddressSettingAction