import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Select, Checkbox, Button, Row, Col } from "antd";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable  from "components/Commons/ModalDraggable";

import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

const styleFormItem = {
  marginRight: '10px',
  textAlign: 'right'
}
const styleFormLabel = {
  textAlign: 'right',
  float: 'right'
}

class WS0882001_CommunicationRosterOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '連名簿出力';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      ExtractCondition_List: [],
    };
  }

  componentDidMount = () => {
    this.callApiGetScreenData({});
  };

  callApiGetScreenData = (params) => {
    this.setState({ isLoading: true })
    VenusApiRoutesV2.callApi("API000882001001", params)
      .then((res) => {
        this.setState({ ExtractCondition_List: res.ExtractCondition_List })
        this.formRef.current?.setFieldsValue(res)
      }).finally(() => this.setState({ isLoading: false }))
  }

  onFinish() {
    this.setState({ isLoading: true })
    let params = this.formRef.current?.getFieldsValue()
    VenusApiRoutesV2.callApi("API000882001002", params)
      .then((res) => {
        console.log(res)
        this.props.onFinishScreen(res)
      }).finally(() => this.setState({ isLoading: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  render() {
    return (
      <div className="communication-roster-output">
        <Card className="mb-2" title="連名簿出力">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={4}>
                <Form.Item label="帳票様式" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='GstyleCode' style={styleFormItem}>
                  <Input style={{ textAlign: 'center' }} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item name='format_name' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='GseeOffice' style={styleFormItem}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='kanzi' label="管  掌" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={9} >
                <Form.Item name='GkanshoF' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item >
                  <span style={{ marginRight: '5px' }}>~</span>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name='GkanshoT' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='' label="事業所" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={6} >
                <Form.Item name='GofficeF' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col span={3} >
                <Form.Item name='GbranchStoreF' style={styleFormItem}>
                  <Input  />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item >
                  <span style={{ marginRight: '5px' }}>~</span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='GbranchStoreT' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='' label="受 診 日" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={9} >
                <Form.Item name='DateFChar' style={styleFormItem}>
                  <VenusDatePickerCustom formRefDatePicker={this.formRef} format='YYYY/MM/DD' style={{ textAlign: 'center' }} />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item >
                  <span style={{ marginRight: '5px' }}>~</span>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name='DateTChar' style={styleFormItem}>
                  <VenusDatePickerCustom formRefDatePicker={this.formRef} format='YYYY/MM/DD' style={{ textAlign: 'center' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='' label="受診ｺｰｽ" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={9} >
                <Form.Item name='GvisitsCourseF' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0265001_BasicCourseInquiry
                              onFinishScreen={(value) => {
                                console.log(value)
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item >
                  <span style={{ marginRight: '5px' }}>~</span>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name='GvisitsCourseT' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0265001_BasicCourseInquiry
                              onFinishScreen={(value) => {
                                console.log(value)
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>            
            <Row>
              <Col span={4}>
                <Form.Item name='' label="受付番号" style={styleFormLabel}>
                </Form.Item>
              </Col>
              <Col span={9} >
                <Form.Item name='GreceiptNumF' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item >
                  <span style={{ marginRight: '5px' }}>~</span>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name='GreceiptNumT' style={styleFormItem}>
                  <Input style={{ textAlign: 'right' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='' label="出力順" style={styleFormLabel}>
                </Form.Item>
              </Col>              
              <Col span={18} >
                <Form.Item name='OutputOrder' style={styleFormItem}>
                   <Select>
                    <Select.Option value={1}>事業所別カナ順</Select.Option>
                    <Select.Option value={2}>事業所別受付順</Select.Option>
                    <Select.Option value={3}>事業所別ＩＤ順</Select.Option>
                    <Select.Option value={4}>事業所別患者ID順</Select.Option>
                    <Select.Option value={5}>事業所別日付カナ順</Select.Option>                    
                    <Select.Option value={6}>カナ順</Select.Option>                                      
                    <Select.Option value={7}>受付順</Select.Option>                                      
                    <Select.Option value={8}>ＩＤ順</Select.Option>                                      
                    <Select.Option value={9}>患者ID順</Select.Option>     
                    <Select.Option value={10}>日付カナ順</Select.Option>
                    <Select.Option value={11}>事業所判定別カナ順</Select.Option>                                      
                    <Select.Option value={12}>事業所判定別受付順</Select.Option>
                    <Select.Option value={13}>事業所判定別ＩＤ順</Select.Option>
                    <Select.Option value={14}>事業所判定別患者ID順</Select.Option>
                    <Select.Option value={15}>事業所判定別日付カナ順</Select.Option>                    
                    <Select.Option value={16}>判定別カナ順</Select.Option>                    
                    <Select.Option value={17}>判定別受付順</Select.Option>                    
                    <Select.Option value={18}>判定別ＩＤ順</Select.Option>                    
                    <Select.Option value={19}>判定別患者ID順</Select.Option>
                    <Select.Option value={20}>判定別日付カナ順</Select.Option>
                  </Select>
                </Form.Item>
              </Col>              
            </Row>            
            <Row>
              <Col span={4}>
                <Form.Item name='' label="抽出条件" style={styleFormLabel}>
                </Form.Item>
              </Col>              
              <Col span={8} >
                <Form.Item name='ExtractCondition' style={styleFormItem}>
                  <Select>
                    {this.state.ExtractCondition_List.map((condition) => <>
                      <Select.Option value={1}>全て</Select.Option>
                    </> )}
                  </Select>
                </Form.Item>
              </Col>              
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='' label="ﾌﾟﾚﾋﾞｭｰ" style={styleFormLabel}>
                </Form.Item>
              </Col>              
              <Col span={8} >
                <Form.Item name='PreviewIndicationBool' valuePropName="checked">
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>              
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item name='' label="ﾌﾟﾘﾝﾀｰ" style={styleFormLabel}>
                </Form.Item>
              </Col>              
              <Col span={8} >
                <Form.Item name='PrinterNum' style={styleFormItem}>
                   <Select  >
                    <Select.Option value={1}>  ... </Select.Option>
                  </Select>
                </Form.Item>
              </Col>              
            </Row>
          
            <Form.Item>
              <Button
                type="primary"
                style={{float:'right', margin:'10px 30px 10px'}}
                onClick={() => this.onFinish()}
              >抽  出</Button>
            </Form.Item>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0882001_CommunicationRosterOutput);
