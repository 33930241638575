import axios from 'configs/axios'

const API_LIST = {
  index: '/api/spread-input/findings-input-physiciiagnosis',
  getCauseList: '/api/spread-input/findings-input-physiciiagnosis/get-cause-list',
  getDoctorDiagnosisList: '/api/spread-input/findings-input-physiciiagnosis/get-doctor-diagnosis-list',
  save: '/api/spread-input/findings-input-physiciiagnosis/save',
  delete: '/api/spread-input/findings-input-physiciiagnosis/delete',
  changeData: '/api/spread-input/findings-input-physiciiagnosis/change-data-up',
  exit: '/api/spread-input/findings-input-physiciiagnosis/exit'
}

const FindingsInputPhysiciiagnosisService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getCauseList(params) {
    return axios.get(API_LIST.getCauseList, { params })
  },
  async getDoctorDiagnosisList(params) {
    return axios.get(API_LIST.getDoctorDiagnosisList, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },

  async ChangeDataService(params) {
    return axios.post(API_LIST.changeData, params)
  },
  async exitService(params) {
    return axios.post(API_LIST.exit, params)
  },
}

export default FindingsInputPhysiciiagnosisService
