import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Checkbox, Button, message, Modal } from 'antd'
import PersonalAttributesReacquireAction from 'redux/basicInfo/PersonalInfoMaintain/PersonalAttributesReacquire.actions'
import Color from 'constants/Color'
import moment from 'moment'

const borderStyle = '1px solid #cfcfcf'

const styles = {
  // １行のスタイル
  rowBox: {
    width: '100%',
    display: 'flex',
    gap: '20px',
  },
  // ラベル
  labelCheckbox: {
    paddingTop: '8px',
    minWidth: '90px'
  },
  // ボックス
  borderBox: {
    width: '100%',
    border: borderStyle,
    borderBottom: 'none',
    padding: '8px',
  },
  // ボックス
  borderLastBox: {
    width: '100%',
    border: borderStyle,
    padding: '8px',
  },
}

// 医事属性、健診属性　タイトルスタイル
const styleDivTitle = {
  background: '#1B64B5',
  padding: '4px 10px',
  color: '#FFFFFF',
  display: 'inline-block'
}
class WS0382001_PersonalAttributesReacquire extends React.Component {
  static propTypes = {
    Li_User: PropTypes.any,
    Li_ReAcquireItems: PropTypes.any,
    Li_PatientNum: PropTypes.any,
    Lo_StsRun: PropTypes.any,
    Lo_KanaName: PropTypes.any,
    Lo_KanjiName: PropTypes.any,
    Lo_Gender: PropTypes.any,
    Lo_DateBirth: PropTypes.any,
    Lo_OldKanaName: PropTypes.any,
    Lo_ZipCode: PropTypes.any,
    Lo_PhoneNum1Fixed: PropTypes.any,
    Lo_PhoneNum2Mobile: PropTypes.any,
    Lo_Address1: PropTypes.any,
    Lo_Address2: PropTypes.any,
    Lo_InsurerNum: PropTypes.any,
    Lo_InsuranceCardSymbol: PropTypes.any,
    Lo_InsuranceCardNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '個人属性再取得'

    this.state = {
      objInit: {},
      DateBirthOrg: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    let params = {
      OptionUser: this.props.Li_User || '',
      OptionDataReAcquireItems: this.props.Li_ReAcquireItems || '',
      PersonalNum: this.props.Li_PatientNum || ''
    }
    this.loadInitData(params)
  }

  /**
   * データ取得
   * @param {*} params
   */
  loadInitData = (params) => {
    PersonalAttributesReacquireAction.index(params)
      .then(res => {
        if (res) {
          if (res.data.message) {
            Modal.error({
              okText: 'OK',
              content: res.data.message || 'エラーが発生しました',
              onOk: () => {
                if (this.props.onFinishScreen) this.props.onFinishScreen()
              }
            })
          } else {
            let data = {
              ...res.data,
              DateBirth: res.data?.DateBirth ? moment(res.data.DateBirth).format('NNy/MM/DD') : '',
              birthday_on: res.data?.birthday_on ? moment(res.data.birthday_on).format('NNy/MM/DD') : '',
            }
            this.setState({ objInit: data, DateBirthOrg: res.data.DateBirth })
            this.formRef?.current?.setFieldsValue(data)
          }
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  /**
   * チェックボックス　render
   * @param {*} _name
   * @param {*} title
   * @param {*} name1
   * @param {*} name2
   * @param {*} name3
   * @param {*} name4
   * @returns
   */
  renderCheckbox = (_name, title, name1, name2, name3 = '', name4 = '') => (
    <Form.Item
      name={_name}
      valuePropName='checked'
    >
      <Checkbox
        style={{
          fontWeight: 'bold',
          color: (this.checkValueDiff(name1, name2) || this.checkValueDiff(name3, name4))
            ? Color(169).Foreground
            : Color(163).Foreground
        }}
      >
        {title}
      </Checkbox>
    </Form.Item>
  )

  /**
   * 値のdiff
   * @param {*} name1
   * @param {*} name2
   * @returns
   */
  checkValueDiff = (name1, name2) => {
    return name1 !== name2 ? true : false
  }

  /**
   * 再取得ボタン押下処理
   */
  onFinish = () => {
    const values = this.formRef.current.getFieldsValue()

    if (!(values.StsKanaName ||
      values.StsKanjiName ||
      values.StsGender ||
      values.StsDateBirth ||
      values.StsZipCode ||
      values.StsPhoneNum1Fixed ||
      values.StsPhoneNum2Mobile ||
      values.StsAddress ||
      values.StsHealthInfo)
    ) {
      message.error('取得するデータを選択してください')
      return
    }


    if (this.props.onFinishScreen) {
      // 親画面に反映
      this.props.onFinishScreen({
        Lo_StsRun: true,
        Lo_KanaName: (values.StsKanaName) ? this.state.objInit.KanaName : '',
        Lo_KanaName1: (values.StsKanaName) ? this.state.objInit.KanaName_1 : '',
        Lo_KanjiName: (values.StsKanjiName) ? this.state.objInit.KanjiName : '',
        Lo_KanjiName1: (values.StsKanjiName) ? this.state.objInit.KanjiName_1 : '',
        Lo_Gender: (values.StsGender) ? this.state.objInit.Gender : 0,
        Lo_DateBirth: (values.StsDateBirth) ? this.state.DateBirthOrg : '',
        Lo_OldKanaName: (values.StsKanaName) ? this.state.objInit.OldKanaName : '',
        Lo_ZipCode: (values.StsZipCode) ? this.state.objInit.ZipCode : '',
        Lo_PhoneNum1Fixed: (values.StsPhoneNum1Fixed) ? this.state.objInit.PhoneNum1Fixed : '',
        Lo_PhoneNum2Mobile: (values.StsPhoneNum2Mobile) ? this.state.objInit.PhoneNum2Mobile : '',
        Lo_Address1: (values.StsAddress) ? this.state.objInit.Address1 : '',
        Lo_Address2: (values.StsAddress) ? this.state.objInit.Address2 : '',
        Lo_InsurerNum: (values.StsHealthInfo) ? this.state.objInit.InsurerNum : '',
        Lo_InsuranceCardSymbol: (values.StsHealthInfo) ? this.state.objInit.InsuranceCardSymbol : '',
        Lo_InsuranceCardNum: (values.StsHealthInfo) ? this.state.objInit.InsuranceCardNum : '',
      })
    }
  }

  render() {
    return (
      <div className='personal-attributes-reacquire'>
        <Card
          title='個人属性再取得'
        >
          <Form
            ref={this.formRef}
          >

            <div style={{ ...styles.rowBox, paddingBottom: '10px' }}>
              <div style={styles.labelCheckbox}>
              </div>
              <div style={{ width: '100%' }}>
                <span style={styleDivTitle}>医事属性</span>
              </div>
              <div style={{ width: '100%' }}>
                <span style={styleDivTitle}>健診属性</span>
              </div>
            </div>

            {/* カナ氏名 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsKanaName',
                  'カナ氏名',
                  this.state.objInit.KanaName,
                  this.state.objInit.kana_name
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.KanaName}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.kana_name}
              </div>
            </div>

            {/* 漢字氏名 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsKanjiName',
                  '漢字氏名',
                  this.state.objInit.KanjiName,
                  this.state.objInit.kanji_name
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.KanjiName}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.kanji_name}
              </div>
            </div>

            {/* 性別 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsGender',
                  '性　　別',
                  this.state.objInit.Gender,
                  this.state.objInit.sex
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.Gender == 1 ? '男' : this.state.objInit.Gender == 2 ? '女' : ''}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.sex == 1 ? '男' : this.state.objInit.sex == 2 ? '女' : ''}
              </div>
            </div>

            {/* 生年月日 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsDateBirth',
                  '生年月日',
                  this.state.objInit.DateBirth,
                  this.state.objInit.birthday_on
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.DateBirth}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.birthday_on}
              </div>
            </div>

            {/* 自宅番号 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsPhoneNum1Fixed',
                  '自宅番号',
                  this.state.objInit.PhoneNum1Fixed,
                  this.state.objInit.phone_number
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.PhoneNum1Fixed}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.phone_number}
              </div>
            </div>

            {/* 携帯番号 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsPhoneNum2Mobile',
                  '携帯番号',
                  this.state.objInit.PhoneNum2Mobile,
                  this.state.objInit.cell_phone_number
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.PhoneNum2Mobile}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.cell_phone_number}
              </div>
            </div>

            {/* 郵便番号 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsZipCode',
                  '郵便番号',
                  this.state.objInit.ZipCode,
                  this.state.objInit.postal_code
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.ZipCode}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.postal_code}
              </div>
            </div>

            {/* 住所 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsAddress',
                  '住　　所',
                  this.state.objInit.Address1,
                  this.state.objInit.Address2
                )}
              </div>
              <div style={styles.borderBox}>
                {this.state.objInit.Address1}
                {this.state.objInit.Address2}
              </div>

              <div style={styles.borderBox}>
                {this.state.objInit.address_1}
                {this.state.objInit.address_2}
              </div>
            </div>

            {/* 保険情報 */}
            <div style={styles.rowBox}>
              <div style={styles.labelCheckbox}>
                {this.renderCheckbox(
                  'StsHealthInfo',
                  '保険情報',
                  this.state.objInit.InsurerData,
                  this.state.objInit.insurer_data,
                  this.state.objInit.InsurerNum,
                  this.state.objInit.insurer_number
                )}
              </div>
              <div style={styles.borderLastBox}>
                {this.state.objInit.InsurerNum}
                {this.state.objInit.InsurerData}
              </div>
              <div style={styles.borderLastBox}>
                {this.state.objInit.insurer_number}
                {this.state.objInit.insurer_data}
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  type='primary'
                  onClick={() => this.onFinish()}
                >
                  <span className='btn_label'>
                    再取得
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0382001_PersonalAttributesReacquire)
