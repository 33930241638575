import React from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, message, Tooltip } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0344003_SupplementalInfoInput from './WS0344003_SupplementalInfoInput'
import { MoreOutlined } from '@ant-design/icons'
import SupplementalInfoSettingAction from 'redux/basicInfo/InsurerInfoMaintain/SupplementalInfoSetting.actions'

const typeList = [{
  value: 'X',
  label: '英数'
},
{
  value: 'K',
  label: 'カナ'
},
{
  value: 'J',
  label: '漢字'
}]

const selectItemList = [{
  value: 1,
  label: 'あり'
},
{
  value: 0,
  label: 'なし'
}]

class WS0344001_SupplementalInfoSetting extends React.Component {
  static propTypes = {
    Li_IdentifyChar: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '補足情報設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }

    this.renderToolTip = this.renderToolTip.bind(this)
    this.renderToolTipRight = this.renderToolTipRight.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getSupplementList(this.props.Li_IdentifyChar)
  }

  /**
   * アンマウント時の処理
   */
  componentWillUnmount = () => {
    if (this.props.onFinishScreen) this.props.onFinishScreen()
  }

  /**
   * 補足情報一覧を取得
   * @param {*} IdentifyChar
   */
  getSupplementList(IdentifyChar) {
    const params = {
      IdentifyChar: IdentifyChar
    }
    SupplementalInfoSettingAction.index(params)
      .then(res => {
        if (!res) return
        // テーブルに反映
        this.setState({ dataSource: res })

      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  /**
   * 保存ボタン押下処理
   * @param {*} values
   * @param {*} record
   */
  onUpdate = (values, record) => {
    const params = {
      ...record,
      ...values.itemChange,
      identifyChar: this.props.Li_IdentifyChar
    }
    SupplementalInfoSettingAction.save(params)
      .then(res => {
        if (res) {
          // 補足情報を再取得
          this.getSupplementList(this.props.Li_IdentifyChar)
          this.closeModal()
        }
      })
  }

  /**
   * 削除ボタン押下処理
   * @param {*} record
   */
  onDelete = (record) => {
    const params = {
      item_id: record.item_id,
      item: record.item,
    }
    // テーブルデータを初期化して削除したようにする
    SupplementalInfoSettingAction.delete(params)
      .then(res => {
        if (res) {
          // 補足情報を再取得
          this.getSupplementList(this.props.Li_IdentifyChar)
          this.closeModal()
        }
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  /**
   * ツールチップ設定
   * @param {*} value
   * @param {*} record
   * @returns
   */
  renderToolTip = (value, record) => {
    return (
      <Tooltip title={record?.toolTip}>
        <div style={{ height: '100%', width: '100%' }}>
          {value}
        </div>
      </Tooltip>
    )
  }

  /**
   * ツールチップ設定　右寄せ
   * @param {*} value
   * @param {*} record
   * @returns
   */
  renderToolTipRight = (value, record) => {
    return (
      <Tooltip title={record?.toolTip}>
        <div style={{ height: '100%', width: '100%', textAlign: 'right' }}>
          {value}
        </div>
      </Tooltip>
    )
  }

  render() {
    return (
      <div className='supplemental-info-setting'>
        <Card title='補足情報設定' >
          <Table
            size='small'
            bordered
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({
              onClick: (event) => {
                this.setState({ rowSelect: record })
              }
            })}
            scroll={{ y: 600, x: 'max-content' }}
          >
            <Table.Column
              title='表示名称'
              dataIndex='item'
              className='column-size-40'
              render={this.renderToolTip}
            />
            <Table.Column
              title='順番' //1ケタ数字のみ
              dataIndex='serialNum'
              width={45}
              render={this.renderToolTipRight}
            />
            <Table.Column
              title='タイプ'
              dataIndex='condition_1'
              className='column-size-5'
              render={(value, record) => {
                const label = typeList.find(item => item.value === value)?.label
                return (
                  <Tooltip title={record?.toolTip}>
                    <div style={{ height: '100%', width: '100%' }}>
                      {label}
                    </div>
                  </Tooltip>
                )
              }}
            />
            <Table.Column
              title='桁数'
              dataIndex='condition_2'
              className='column-size-5'
              // width={60}
              render={this.renderToolTipRight}
            />
            <Table.Column
              title='備考'
              dataIndex='remarks'
              className='column-size-40'
              render={this.renderToolTip}
            />
            <Table.Column
              title='選択項目'
              dataIndex='selectItem'
              className='column-size-10'
              render={(value, record) => {
                return (
                  <Tooltip title={record?.toolTip}>
                    <div style={{ textAlign: 'center' }}>
                      {value ? '有り' : '-'}
                    </div>
                  </Tooltip>
                )
              }}
            />
            <Table.Column
              title=''
              dataIndex=''
              align='center'
              width={30}
              render={(value, record, index) => (
                <Tooltip title={record?.toolTip}>
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // 編集、削除
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component:
                            <WS0344003_SupplementalInfoInput
                              record={record}
                              typeList={typeList}
                              selectItemList={selectItemList}
                              onUpdate={(output) => {
                                // 保存ボタン
                                this.onUpdate(output, record)
                              }}
                              onDelete={(output) => {
                                // 削除ボタン
                                this.onDelete(record)
                                this.closeModal()
                              }}
                            />
                        },
                      })
                    }}
                  />
                </Tooltip>
              )}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

export default WS0344001_SupplementalInfoSetting
