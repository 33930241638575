/* eslint-disable react/jsx-key */

import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Checkbox, Button, Select, message, Modal } from "antd";

import print from 'assets/img/print.png'
import coppy from 'assets/img/coppy.png'
import ReceiptIssueOnlineAction from 'redux/AccountingBusiness/ReceiptPreIssue20/ReceiptIssueOnline.actions'

const grid = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const styleImg = {
  marginBottom: '0.5em',
  background: '#C8DCF5',
  width: '50px'
}

class WS0946006_ReceiptIssueOnline extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '領収書発行（オンライン）';

    this.state = {
      PrinterNum_List: [],
      ReceiptStyle_List: [],
      form_data: {},
      Li_UsuallyInAdvance: ""
    };
  }

  componentDidMount() {
    let parram = {
      TypeCode: this.props.TypeCode ?? "",
      OptionCode: this.props.OptionCode ?? "",
      ItemDataPrinter: this.props.ItemDataPrinter ?? "",
      ReceiptDateScreen: this.props.ReceiptDateScreen ?? 0,
      OfficeCodeNew: this.props.OfficeCodeNew ?? "",
      StsDateReceiptMore: this.props.StsDateReceiptMore ? 1 : 0,
      Li_Parameters: this.props.Li_Parameters ?? "",
      Proviso: this.props.Proviso ?? "",
      ChineseCharInputMode: this.props.ChineseCharInputMode ?? 0,
      InspectIssue: this.props.InspectIssue ? 1 : 0,
      StsPrintPreview: this.props.StsPrintPreview ? 1 : 0,
    }
    this.getScreenData(parram);
  }

  componentDidUpdate(PropPev) {
    if (this.props !== PropPev) {
      let parram = {
        TypeCode: this.props.TypeCode,
        OptionCode: this.props.OptionCode,
        ItemDataPrinter: this.props.ItemDataPrinter,
        ReceiptDateScreen: this.props.ReceiptDateScreen,
        OfficeCodeNew: this.props.OfficeCodeNew,
        StsDateReceiptMore: this.props.StsDateReceiptMore,
        Li_Parameters: this.props.Li_Parameters,
        Proviso: this.props.Proviso,
        ChineseCharInputMode: this.props.ChineseCharInputMode,
        InspectIssue: this.props.InspectIssue,
        StsPrintPreview: this.props.StsPrintPreview,
      }
      this.getScreenData(parram);
    }
  }

  getScreenData(parram) {
    ReceiptIssueOnlineAction.getScreenData(parram)
      .then((res) => {
        var StyleListCmbName_list = res.StyleListCmbName.split(",");
        var StyleListCmbNum_list = res.StyleListCmbNum.split(",");
        let array_temp = []
        for (let i = 0; i < StyleListCmbName_list.length; i++) {
          let obj_temp = {
            StyleListCmbName: StyleListCmbName_list[i],
            StyleListCmbNum: StyleListCmbNum_list[i]
          }
          array_temp.push(obj_temp)
        }
        let printerNumConfirmation = res.printNumberList.some(elem => {
          return elem === res.PrinterNum;
        })
        if (!printerNumConfirmation) {
          res.PrinterNum = res.PrinterNum_List[0]?.printer_number
        }
        this.setState({
          PrinterNum_List: res.PrinterNum_List,
          ReceiptStyle_List: array_temp,
          form_data: res,
          Li_UsuallyInAdvance: this.props.Li_UsuallyInAdvance === '2' ? true : false
        })

        this.formRef.current?.setFieldsValue(res)
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          return;
        }
      }).finally()
  }

  print() {
    let parram = {
      Preview: this.formRef.current?.getFieldValue('Preview') ? 1 : 0,
      InspectIssue: this.props.InspectIssue ? 1 : 0,
      ReceiptDatePresence: this.formRef.current?.getFieldValue('ReceiptDatePresence') ? 1 : 0,
      ReissueMarkOutputPresence: this.formRef.current?.getFieldValue('ReissueMarkOutputPresence') ? 1 : 0,
      OfficeNameOutputPresence: this.formRef.current?.getFieldValue('OfficeNameOutputPresence') ? 1 : 0,
      PrinterNum: this.formRef.current?.getFieldValue('PrinterNum') ?? 0,
      UnexecutedEditingChar: this.formRef.current?.getFieldValue('UnexecutedEditingChar') ?? "",
      Proviso: this.formRef.current?.getFieldValue('Proviso') ?? "",
      PersonalNameOutputPresence: this.formRef.current?.getFieldValue('PersonalNameOutputPresence') ? 1 : 0,
      StyleListCmbNum: this.formRef.current?.getFieldValue('StyleListCmbNum') ?? "",
      ReceiptStyle: this.formRef.current?.getFieldValue('ReceiptStyle') ?? "",
      Li_TaskType: this.props.Li_TaskType,
      Li_Parameters: this.props.Li_Parameters,

      //以下、窓口における入金後の領収発行時に使用するパラメータ
      Li_CounterReceiptProcess: this.props.counterReceiptProcess ? 1 : 0,
      Li_ReceiptManageNum: this.props.receiptManageNum ?? null,
      Li_ReceiptDateChar: this.props.receiptDateChar ?? null,
      Li_ReserveNum: this.props.Li_ReserveNum ?? null,
      Li_BillingManageNum: this.props.Li_BillingManageNum ?? null,
      Li_ReceiptDateOn: this.props.Li_ReceiptDateOn ?? null,
      Li_DestinationName: this.props.Li_DestinationName ?? null,
      Li_Receipts: this.props.Li_Receipts ?? null,
      Li_receiptManagementNumberList: this.props.receiptManagementNumberList ?? null,
    }

    ReceiptIssueOnlineAction.print(parram)
      .then((res) => {
        if (res.data === 'true') {
          message.success('印刷が完了しました')
          this.props.onFinishScreen()
        } else {
          message.error('処理の途中でエラーが発生しました')
        }
      }).catch(error => {
        const res = error.response;
        if (error.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          });
        }
      })
  }

  preview() {
    let parram = {
      Preview: this.formRef.current?.getFieldValue('Preview') ? 1 : 0,
      InspectIssue: this.props.InspectIssue ? 1 : 0,
      ReceiptDatePresence: this.formRef.current?.getFieldValue('ReceiptDatePresence') ? 1 : 0,
      ReissueMarkOutputPresence: this.formRef.current?.getFieldValue('ReissueMarkOutputPresence') ? 1 : 0,
      OfficeNameOutputPresence: this.formRef.current?.getFieldValue('OfficeNameOutputPresence') ? 1 : 0,
      PrinterNum: this.formRef.current?.getFieldValue('PrinterNum') ?? 0,
      UnexecutedEditingChar: this.formRef.current?.getFieldValue('UnexecutedEditingChar') ?? "",
      Proviso: this.formRef.current?.getFieldValue('Proviso') ?? "",
      PersonalNameOutputPresence: this.formRef.current?.getFieldValue('PersonalNameOutputPresence') ? 1 : 0,
      StyleListCmbNum: this.formRef.current?.getFieldValue('StyleListCmbNum') ?? "",
      ReceiptStyle: this.formRef.current?.getFieldValue('ReceiptStyle') ?? "",
      StsPrintPreview: this.props.StsPrintPreview ? 1 : 0,
      Li_UsuallyInAdvance: this.state.Li_UsuallyInAdvance,
      Li_TaskType: this.props.Li_TaskType,
      Li_Parameters: this.props.Li_Parameters,

      //以下、窓口における入金後の領収発行時に使用するパラメータ
      Li_CounterReceiptProcess: this.props.counterReceiptProcess ? 1 : 0,
      Li_ReceiptManageNum: this.props.receiptManageNum ?? null,
      Li_ReceiptDateChar: this.props.receiptDateChar ?? null,
      Li_ReserveNum: this.props.Li_ReserveNum ?? null,
      Li_BillingManageNum: this.props.Li_BillingManageNum ?? null,
      Li_ReceiptDateOn: this.props.Li_ReceiptDateOn ?? null,
      Li_DestinationName: this.props.Li_DestinationName ?? ' ',
      Li_Receipts: this.props.Li_Receipts ?? null,
      W1_subject: ' ',
      Li_receiptManagementNumberList: this.props.receiptManagementNumberList ?? null,
    }

    ReceiptIssueOnlineAction.preview(parram)
      .then((res) => {
        // pdfのObject作成
        // Serviceで{ responseType: "blob" }を設定する必要がある
        let fileURL = URL.createObjectURL(res.data);
        // pdfを別タブで表示
        var fileOpen = window.open(fileURL)
        this.props.onFinishScreen()
      })
      .catch(error => {
        const res = error.response;
        if (res.request.responseType === 'blob') {
          res.data.text()
            .then(str => {
              let json = JSON.parse(str);
              message.error(json.message)
            })
        }
        if (error.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          });
        }
      })
  }

  render() {
    return (
      <div className="receipt-issue-online" >
        <Card className="mb-2" title="領収書発行（オンライン）">
          <Form ref={this.formRef}>
            <Form.Item hidden={this.state.Li_UsuallyInAdvance} name="ReceiptDatePresence" label="領収日"  {...grid}
              valuePropName="checked">
              <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item name="ReissueMarkOutputPresence" label="再発行印"  {...grid}
              valuePropName="checked">
              <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item hidden={this.state.Li_UsuallyInAdvance} name="OfficeNameOutputPresence" label="事業所名"  {...grid}
              valuePropName="checked">
              <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item hidden={this.state.Li_UsuallyInAdvance} name="PersonalNameOutputPresence" label="個人氏名"  {...grid}
              valuePropName="checked">
              <Checkbox></Checkbox>
            </Form.Item>

            <Form.Item name="Proviso" label="但し書き"  {...grid}>
              <Input type='text' />
            </Form.Item>

            <Form.Item
              name="PrinterNum"
              label="プリンター"
              {...grid}
            >
              <Select>
                {this.state.PrinterNum_List?.map((value, index) => (
                  <Select.Option
                    key={index}
                    value={value.printer_number}
                  >
                    {value.printer_name ?? ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="box_button_bottom_center">
              <div style={{ textAlign: 'center', border: '1px solid #14468C', padding: '0.5em', width: '105px' }}>
                <img alt="print" src={print} style={styleImg} /><br />
                <Button style={{ background: '#C8DCF5', width: '100%' }} type="text" onClick={() => { this.print() }} >印刷</Button>
              </div>
              <div style={{ textAlign: 'center', border: '1px solid #14468C', padding: '0.5em', width: '105px' }}>
                <img alt="coppy" src={coppy} style={styleImg} /><br />
                <Button style={{ background: '#C8DCF5', width: '100%' }} type="text" onClick={() => { this.preview() }} >プレビュー</Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0946006_ReceiptIssueOnline);
