import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Table, Form, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';

import WS0487001_ConditionExpressAddSub from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487001_ConditionExpressAddSub.jsx";
import WS0487500_ConditionExpressEdit from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487500_ConditionExpressEdit'
import WS0493001_ConditionCopyingScreen from "./WS0493001_ConditionCopyingScreen";
import WS0486001_ConditionCorrectSub from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0486001_ConditionCorrectSub'
import ConditionExpressCmtSubAction from 'redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressCmtSub.action'
import ModalDraggable from "components/Commons/ModalDraggable";
class WS0483002_ConditionExpressCmtSubDetail extends React.Component {

  static propTypes = {
    Li_GuideMattersCode: PropTypes.number,
    Li_AdoptionDate: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '条件式コメントSUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource1: [],
      isLoadingTable1: false,
      selectedRow1: [],
      selectedRowKeys1: [],
      indexTable1: 0,

      dataSource2: [],
      isLoadingTable2: false,
      selectedRow2: [],
      selectedRowKeys2: [],
      indexTable2: 0,

      dataSource: [],
      isLoadingTable: false,
      selectedRow: [],
      selectedRowKeys: [],
      indexTable: 0,
      old_branch_number: '',
      old_serial_number: '',
    };
  }

  componentDidMount() {
    let param = {
      Li_GuideCmtCode: this.props.record.guidance_comment_code,
      Li_StartDate: this.props.record.start_date_on,
      Li_SerialNum: this.props.record.serial_number
    }

    this.getDataRight(param)
  }

  getDataRight(param) {
    ConditionExpressCmtSubAction.details(param)
      .then((res) => {
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * 新規・編集登録
   */
  newAdditionData = (newFlag, record) => {
    if (newFlag) {
      // 新規追加
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          centered: true,
          width: 900,
          component: (
            <WS0487001_ConditionExpressAddSub
              params={this.props.record}
              newFlag={newFlag}
              onSave={() => {
                let param = {
                  Li_GuideCmtCode: this.props.record.guidance_comment_code,
                  Li_StartDate: this.props.record.start_date_on,
                  Li_SerialNum: this.props.record.serial_number
                }

                this.getDataRight(param)
                this.closeModal()
              }}
            />
          ),
        },
      })
    } else {
      // 編集
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          centered: true,
          width: 600,
          component: (
            <WS0487500_ConditionExpressEdit
              params={this.props.record}
              record={record}
              onSave={() => {
                //更新・削除後の再取得
                let param = {
                  Li_GuideCmtCode: this.props.record.guidance_comment_code,
                  Li_StartDate: this.props.record.start_date_on,
                  Li_SerialNum: this.props.record.serial_number
                }

                this.getDataRight(param)
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  /**
   * 条件式内容変更
   */
  showConditionCorrectSub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0486001_ConditionCorrectSub
            record={record}

            onFinishScreen={(output) => {
              let param = {
                Li_GuideCmtCode: this.props.record.guidance_comment_code,
                Li_StartDate: this.props.record.start_date_on,
                Li_SerialNum: this.props.record.serial_number
              }

              this.getDataRight(param)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   *  複写処理
   */
  WS0493001ConditionCopyingScreen() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component:
          <WS0493001_ConditionCopyingScreen
            Li_GuideCmt={''}
            Li_StartDate={''}
            Li_SerialNum={''}
            Li_StsGuideCmt={true}
            Li_StsStartDate={true}
            Li_StsSerialNum={true}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ,
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="condition-express-cmt-sub">
        <Card title="条件式コメント 詳細">
          <div className='box_inner_vertical' >
            <div className='box_inner_horizontal'>
              <div className='box_inner_vertical' >
                <Form.Item
                  label='条件式'
                  style={{ marginBottom: 0 }}
                >
                  <div >{this.props.record.method == 0 ? 'OR' : 'AND'}</div>
                </Form.Item>


                <Table
                  bordered
                  size='small'
                  style={{ cursor: 'pointer' }}
                  dataSource={this.state.dataSource}
                  loading={this.state.isLoadingTable}
                  pagination={false}
                  scroll={{ x: 350, y: 700 }}
                  rowKey={(res) => res.id}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="branch_number"
                    width={80}
                  />

                  <Table.Column
                    title="条件式"
                    dataIndex="EquationDisplay"
                  />

                  <Table.Column
                    title="メモ"
                    dataIndex="formula"
                  />

                  <Table.Column
                    width={40}
                    title={
                      <Button
                        size='small'
                        onClick={() => this.newAdditionData(true)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                      </Button>
                    }

                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger='click'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='変更'
                                onClick={() => this.newAdditionData(false, record)}
                              >
                                変更
                              </Menu.Item>

                              <Menu.Item
                                key='条件変更'
                                onClick={() => this.showConditionCorrectSub(record)}
                              >
                                条件式訂正
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                            style={{ width: '100%' }}
                          ></Button>
                        </Dropdown>
                      )
                    }}
                  />
                </Table>
              </div>
            </div>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0483002_ConditionExpressCmtSubDetail);
