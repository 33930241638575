import React from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import axios from 'configs/axios'

/**
 * グローバルなSpinの設定
 */
class GlobalLoader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    const self = this

    axios.interceptors.request.use(
      function (config) {
        // console.log(config);
        self.setState({ loading: true })
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      function (response) {
        self.setState({ loading: false })
        return response
      },
      function (error) {
        self.setState({ loading: false })
        return Promise.reject(error)
      }
    )
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: 'white',
              opacity: 0.8,
              zIndex: 99999,
              height: '200vh',
              width: '200vw'
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: 'calc(50% - 50px)',
                left: 'calc(50% - 50px)'
              }}
            >
              <Spin tip='Loading...' size='large' />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalLoader)
