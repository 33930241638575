import { message } from 'antd'
import RadiographyFindingInputSubService from 'services/InputBusiness/RadiographyFindingInput/RadiographyFindingInputSubService'

const RadiographyFindingInputSubAction = {
  index(data) {
    return RadiographyFindingInputSubService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getListDataFindingsContent(data) {
    return RadiographyFindingInputSubService.listDataFindingsContent(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getListDataPreviousFindingsContent(data) {
    return RadiographyFindingInputSubService.listDataPreviousFindingsContent(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  postLastTimeDoBtn(data) {
    return RadiographyFindingInputSubService.lastTimeDoBtn(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
      })
  },
  postLastTimeDoBtnYes(data) {
    return RadiographyFindingInputSubService.lastTimeDoBtnYes(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  postSettingBtn(data) {
    return RadiographyFindingInputSubService.settingBtn(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getChangeSiteAndFindingsCode(data) {
    return RadiographyFindingInputSubService.changeSiteAndFindingsCode(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  saveFindingsContent(data) {
    return RadiographyFindingInputSubService.saveFindingsContent(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
      })
  },
  deleteFindingsContent(data) {
    return RadiographyFindingInputSubService.deleteFindingsContent(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  confirm(data) {
    return RadiographyFindingInputSubService.confirm(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}

export default RadiographyFindingInputSubAction
