import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Select, Table, } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0415001_MasterListOutputLeadershipMatter extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力[指導事項]';

    this.state = {
      search: "",
      guideContentKeyword: "",
      identifyCode: "",
      categoryCode: [],
      dataSource: [],
      indexTable: 0
    };
  }

  componentDidMount() {
    this.setState({ isLoadingTable: true })

    VenusApiRoutesV2.callApi("API000415001001", {})
      .then((res) => {
        this.setState({
          search: res.Search,
          guideContentKeyword: res.GuideContentKeyword,
          identifyCode: res.IdentifyCode,
          categoryCode: res.CategoryCode,
          isLoadingTable: false,
        });

        this.formRef.current.setFieldsValue({
          search: res.Search,
          guideContentKeyword: res.GuideContentKeyword,
          identifyCode: res.IdentifyCode,
          categoryCode: res.CategoryCode,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    VenusApiRoutesV2.callApi("API000415001002", {})
      .then((res) => {
        this.setState({
          dataSource: res? res : [],
          isLoadingTable: false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) { }
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="master-list-output-leadership-matter">
        <Card className="mb-2" title="マスタ一覧出力[指導事項]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="search"
              label="検索キーワード"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="guideContentKeyword"
              label="指導内容キーワード"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="identifyCode"
              label="識別コード"
            >
              <Select>
                <Select.Option value=""></Select.Option>
                <Select.Option value="">注意事項</Select.Option>
                <Select.Option value="">指導事項</Select.Option>

              </Select>
            </Form.Item>
            <Form.Item
              name="categoryCode"
              label="カテゴリコード"
            >
              <Select>
                <Select.Option value=""></Select.Option>

              </Select>
            </Form.Item>
          </Form>
          
          <Table bordered 
            size="small"
            dataSource={this.state.dataSource} 
            loading={this.state.isLoadingTable}
            pagination={true}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="コメントコード" dataIndex="comment_code" key="1" 
              showSorterTooltip={false}
              sorter={(a, b) => a.comment_code - b.comment_code}
            />
            <Table.Column title="検索キー" dataIndex="search_key" key="2" 
              showSorterTooltip={false}
              sorter={(a, b) => a.search_key.localeCompare(b.search_key, 'ja')}
            />
            <Table.Column title="コメント内容" dataIndex="comment_content" key="3" 
              showSorterTooltip={false}
              sorter={(a, b) => a.comment_content.localeCompare(b.comment_content, 'ja')}
            />
            <Table.Column title="優先度" dataIndex="priority" key="4" 
              showSorterTooltip={false}
              sorter={(a, b) => a.priority - b.priority}
            />
            <Table.Column title="コメント群" dataIndex="comment_group" key="5" 
              showSorterTooltip={false}
              sorter={(a, b) => a.comment_group.localeCompare(b.comment_group, 'ja')}
            />
            <Table.Column title="使用回数" dataIndex="number_of_times_of_use" key="6" 
              showSorterTooltip={false}
              sorter={(a, b) => a.number_of_times_of_use - b.number_of_times_of_use}
            />
            <Table.Column title="最終使用日" dataIndex="last_used_on" key="7" 
              showSorterTooltip={false}
              sorter={(a, b) => a.last_used_on.localeCompare(b.last_used_on, 'ja')}
            />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0415001_MasterListOutputLeadershipMatter);
