import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import { Card, Form, Button, Table, message, Modal, Tooltip, Menu, Dropdown, Select, Input, Checkbox } from 'antd'
import { MoreOutlined, SaveOutlined, LeftOutlined, RightOutlined, QuestionCircleTwoTone, RedoOutlined } from '@ant-design/icons'
import moment from 'moment'
import axios from 'configs/axios'
import GetImage from 'constants/Images'
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import WS2637036_DesignatedInspectVisitsHistory from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637036_DesignatedInspectVisitsHistory.jsx'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import WS0723001_AutoJudgeScreen from 'pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS0723001_AutoJudgeScreen.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0061003_ConfirmCheckYesYesNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061003_ConfirmCheckYesYesNo.jsx'
import WS2637014_InspectResult from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637014_InspectResult.jsx'
import WS2637002_OverallResultDisplayChange from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637002_OverallResultDisplayChange.jsx'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import DetailResultDisplay from 'pages/IN_InputBusiness/SANAI_InterviewSupport/DetailResultDisplay.jsx'
import DetailInspectResultDisplay from 'pages/IN_InputBusiness/SANAI_InterviewSupport/DetailInspectResultDisplay.jsx'
import MemoFunction from 'pages/ZZ_Others/SANAI_Memo/MemoFunction.jsx'
import WSFileManager_FileManager from 'pages/ZZ_Others/CUSTOMIZE_Custom/WSFileManager_FileManager.jsx'
import WS2644008_SpecifiedValueConfirm from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2644008_SpecifiedValueConfirm.jsx'
import ImageDisplay from 'pages/IN_InputBusiness/SANAI_InterviewSupport/ImageDisplay.jsx'
import ChartDisplay from 'pages/IN_InputBusiness/SANAI_InterviewSupport/ChartDisplay.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import initialDisplayIcon from 'assets/img/A02-アイアイ-白衣&赤インナー.jpg'
import CoupledPrintInstruction from 'redux/InputBusiness/NotInputCheckCategory/CoupledPrintInstruction.action'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm'
import FollowUpLetterMaintain from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterMaintain.jsx'
import status_input_lock from 'assets/img/status_input_lock.png'
import status_input_lock_disabled from 'assets/img/status_input_lock_disabled.png'

const labelSize = {
  labelCol: { style: { width: 65 } },
  wrapperCol: { style: { width: 'calc(100% - 65px)' } },
  style: { marginBottom: 0 }
}

class ResultDisplay extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Li_MenuAdminRights: PropTypes.any,
    Li_MenuAuthority: PropTypes.any,
    Li_SubjectNotBeChangedMode: PropTypes.any,
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '総合結果表示'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      values: {},
      initialValues: {},

      categoryJudgeList: [], // カテゴリ別結果一覧
      InspectResult: [], // 検査別結果一覧
      guideCommentInputList: [], // 指導事項コメント一覧
      selectedInspectId: null,

      selectedRowKeys: [],
      selectedRows: [],
      SelectCategoryList: '',
      loadScreenData: false,
      AllSelect: true,
      selectedInspectRowKeys: [],
      selectedInspectRows: [],
      indexInspectTable: 0,
      HitoryCount: 0,
      isSelectCategoryList: 0,
      defaultCategoryList: '',
      checkCategoryAll: 0,
      Lio_FindingsCategoryUpdate: 0,
      error: { message: '', id: '' },
      ReserveNum: 0, //現在表示中の予約番号
      guideCommentAll: '',
      selectedRecord: [],
      displayAll: 1,
      bloodTest: 0,
      changeData: [],
      categoryComment: '',
      categoryJudge: '',
      categoryJudgmentChangeFlag: false,
      categoryJudgeColorNum: '',
      initialDisplayFlag: true,
      protectFlag: 0,
      provisionalFlag: 0,
      dataSource: [],
      currentIndex: 0,
      L1Output: false,
      L2Output: false,
      L3Output: false,
      userList: [],
      categorys: '',

      // 自動判定モーダルを×で閉じた場合に初期表示を動かせるように
      autoJudgeScreenClose: true,
      // 精密検査用（二次検査）のデータ
      detailInspectiData: {},
      // 保存ボタンの活性
      disabledUpdateBtn: true,
      // メモ保存ボタンの活性化情報
      MemoFunctionUpdateBtn: true,
      // 前回DOを押したときに配列を格納するため
      setPreviousInspectDo: [],
      setPreviousCommentDo: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = async () => {
    this.formRef.current?.setFieldsValue({
      protect: this.props.protectFlag,
      provisional: this.props.provisionalFlag,
    })
    await this.setState({
      ReserveNum: this.props.Li_ReserveNum, //選択された予約番号
      dataSource: this.props.dataSource, //親画面で抽出された受診者一覧
      currentIndex: this.props.currentIndex,
      protectFlag: this.props.protectFlag,
      provisionalFlag: this.props.provisionalFlag,
    })

    if (this.props.protectFlag) {
      this.setStateValues('Li_ReserveNum', this.props.Li_ReserveNum)
      this.index()
    } else {
      //自動判定
      this.showWS0723001_AutoJudgeScreen(this.props.Li_ReserveNum)

      // if (this.props.protectImage) {
      // 自動判定
      //   this.showWS0723001_AutoJudgeScreen(this.props.Li_ReserveNum)
      // } else {
      //   this.setStateValues('Li_ReserveNum', this.props.Li_ReserveNum)
      //   this.index()
    }
  }

  /**
   * 自動判定 モーダル
   * @param {*} reserveNum
   * @param {*} newIndex
   */
  showWS0723001_AutoJudgeScreen = (reserveNum, newIndex = null) => {
    this.setState({
      ReserveNum: reserveNum, //表示する予約番号設定
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0723001_AutoJudgeScreen
            Li_ReserveNum={reserveNum}
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_TrueOrFalse={1} //メモリーテーブルを使用しない為には「1」にする
            TotalJudge={this.state.values.OverallJudgeG}
            onFinishScreen={(output) => {
              if (output) {
                // 自動判定でデータが変更になった場合
                // データ再取得
                let newArray = {
                  ...this.state.selectedRecord,
                  autoJudge: true
                }
                this.setState({
                  //右テーブルを更新するため
                  selectedRecord: newArray
                })

                this.index()
                if (newIndex === null) {
                  if (this.props.onUpdate) {
                    // 親画面の受診者一覧もデータを再取得させる
                    this.props.onUpdate()
                  }
                } else {
                  if (this.state.dataSource[newIndex].onUpdate) {
                    // 親画面の受診者一覧もデータを再取得させる
                    this.props.onUpdate()
                  }
                }

              } else {
                if (reserveNum) {
                  this.setStateValues('Li_ReserveNum', reserveNum)
                  this.index()
                }
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 該当の予約番号のデータ取得
   */
  index = () => {
    // パラメータ取得
    let params = this.requestParamScreenData()
    // データ取得
    ResultDisplayAction.index(params)
      .then(async (res) => {
        this.formRef.current?.setFieldsValue({
          protect: res.protectFlag,
          provisional: res.provisionalFlag
        })

        // state変数：valuesに設定
        await this.setStateValuesAll(res)
        await this.setState({
          initialValues: res,
          userList: res.UserList,
          // 初期表示を一回でも行った場合はfalseにする
          // 最初の自動判定画面を×で閉じた場合の対処
          autoJudgeScreenClose: false
        })
        await this.formRef.current?.setFieldsValue({
          OverallJudgeG: res.OverallJudgeG,
          manager: this.props.user.name
        })

        // 面談支援では必要なし？
        // if (modalUpdateFlag) {
        //   // 総合結果入力 モーダルに最新の総合判定を反映させる
        //   this.showWS2637002_OverallResultDisplayChange()
        //   // 最新の総合判定を取得したら、カテゴリ別判定結果と指導事項コメントは取得しない
        //   return
        // }

        // カテゴリ別判定結果の一覧を取得
        await this.getCategoryJudgeList()
        // 指導事項コメントを取得
        await this.getGuideCommentInput()
        // 総合判定の文字色の変更
        await this.changeInputSearchStyle('overallJudgeGInput', this.state.initialValues.OverallJudgeGColorNum)
      })
  }

  /**
   * データチェック
   * @param {*} value
   * @param {*} type
   * @returns
   */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  /**
   * 空チェック
   * @param {*} val
   * @returns
   */
  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * 共通でparamsに
   * @returns
   */
  getParamObject() {
    let result = {
      Li_ReserveNum: this.state.ReserveNum ? this.state.ReserveNum : this.props.Li_ReserveNum,
      Li_CourseLevel: this.checkData(this.props.Li_CourseLevel, 'number'),
      Li_PatternCode: this.state.values.pattern_code,
      Li_MenuOption: this.checkData(this.props.Li_MenuOption, 'number'),
      Li_MenuAdminRights: this.checkData(this.props.Li_MenuAdminRights, 'number'),
      Li_MenuAuthority: this.checkData(this.props.Li_MenuAuthority, 'number'),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
      displayAll: this.state.displayAll,
      bloodTest: this.state.bloodTest
    }
    return result
  }

  requestParamScreenData(param = undefined) {
    const NonInput = this.state.values.NonInput
    const Outliers = this.state.values.Outliers
    const HistoryAdded = this.state.values.HistoryAdded
    const isNonInput = (NonInput === 1) ? { NonInput } : { Outliers }
    const params = {
      ...this.getParamObject(),
      HistoryAdded
    }
    const request = param === undefined ? params : { ...params, ...isNonInput }
    return request
  }

  /**
   * カテゴリ別判定結果の一覧を取得
   * @param {*} modalUpdateFlag
   */
  getCategoryJudgeList = (modalUpdateFlag = false) => {
    const HistoryAdded = this.state.values.HistoryAdded
    const SelectCategoryList = this.state.values.SelectCategoryList

    const params = {
      ...this.getParamObject(),
      Li_PersonalNum: this.state.values.personal_number_id,
      Li_VisitDate: moment(this.state.values.DateChar).format('YYYY/MM/DD'),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
      SelectCategoryList: SelectCategoryList,
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeCategoryJudge: this.state.values.StsInputModeCategoryJudge,
      InspectNormalValueUpdateOp: this.state.values.InspectNormalValueUpdateOp,
      HandInputConfirm: this.state.values.HandInputConfirm ? 1 : 0,
      pattern_code: this.state.values.pattern_code,
      Li_PatternCode: this.state.values.pattern_code,
      HistoryAdded,
      AllSelect: this.state.values.AllSelect,
      pre1ReserveNum: this.state.values.pre1ReserveNum,
      pre1VisitDate: this.state.values.pre1VisitDate,
      pre2ReserveNum: this.state.values.pre2ReserveNum,
      pre2VisitDate: this.state.values.pre2VisitDate,
      pre3ReserveNum: this.state.values.pre3ReserveNum,
      pre3VisitDate: this.state.values.pre3VisitDate,
      pre4ReserveNum: this.state.values.pre4ReserveNum,
      pre4VisitDate: this.state.values.pre4VisitDate,
    }

    ResultDisplayAction.getCategoryJudgeList(params)
      .then((res) => {
        if (res) {
          const data = [...res.categoryJudgeList]
          this.setState({
            categoryJudgeList: data,
          })
          if (this.state.selectedInspectId !== null) {
            this.setState({
              selectedRecord: data.find(item => item.id === this.state.selectedInspectId)
            })
          }
          if (modalUpdateFlag) {
            // 子モーダルでテーブル更新がされた場合
            const index = data.findIndex(x => x.id === this.state.selectedInspectId)
            let record = null
            if (index > -1) {
              record = { ...data[index] }
            }
            //  判定一覧モーダルに最新カテゴリ別判定を反映させる
            this.showWS2637014_InspectResult(record)
          }

          // 精密検査（二次検査）のデータを保持
          const categoryData = data.find(x => x.category_code == 9900) //あとでSy2100でオプション設定できるようにする
          if (categoryData) {
            this.setState({ detailInspectiData: categoryData })
          }
        }
      })
  }

  /**
   * 指導事項コメントを取得
   * @param {*} modalUpdateFlag
   */
  getGuideCommentInput = (modalUpdateFlag = false) => {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
    }

    OverallResultDisplayInputAction.getGuideCommentInput(params)
      .then((res) => {
        if (res) {
          // 返却値をコピー
          let data = [...res]

          // コメントまとめ用変数
          let commentAll = ''
          data.forEach(item => {
            // general_comment_codeを加工
            item.general_comment_code = (item.general_comment_code === 0) ? '' : item.general_comment_code
            // １つの変数にコメントをまとめる
            commentAll += (item.overall_comment + '\r\n')
          })

          this.setState({
            guideCommentInputList: data,
            guideCommentAll: commentAll
          })

          if (modalUpdateFlag) {
            // 総合結果入力 モーダルに最新の指導事項コメントを反映させる
            this.showWS2637002_OverallResultDisplayChange()
          }
        }
      })
  }

  /**
   * state変数：values の更新
   * @param {*} namePath
   * @param {*} value
   */
  setStateValues = (namePath, value) => {
    this.setState({
      values: {
        ...this.state.values, //現行のvaluesをコピー
        [namePath]: value
      }
    })
  }

  /**
   * state変数：values の更新 ※返却値のデータをまるごと
   * @param {*} data
   */
  setStateValuesAll = (data) => {
    if (data) {
      const keys = !this.isEmpty(Object.keys(data)) ? Object.keys(data) : []
      const values = !this.isEmpty(Object.values(data)) ? Object.values(data) : []
      let dataObj = null, key = null, value = null
      if (values.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          key = keys[i]
          value = values[i]

          // 日付の値はフォーマットを変えて設定する
          if (key === 'DateChar') {
            if (value) {
              value = moment(value)
            }
          } else if (key === 'birthday_on') {
            if (value) {
              value = moment(value).format('NNy/MM/DD')
            }
          } else if (key === 'pre1VisitDate' || key === 'pre2VisitDate' || key === 'pre3VisitDate' || key === 'pre4VisitDate') {
            if (value) {
              value = moment(value).format('YYYY/MM/DD')
            }
          }

          dataObj = {
            ...dataObj,
            [key]: value
          }
        }
        this.setState({
          values: {
            ...this.state.values,
            ...dataObj
          }
        })
      }
    }
  }

  /**
   * 判定選択
   * @param {*} data
   */
  showWS0285001_JudgeQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {

              if (output.recordData) {
                Modal.confirm({
                  content: `総合判定を [${output.recordData.judgment_result}] に変更します。よろしいですか？`,
                  okText: 'はい',
                  cancelText: 'いいえ',
                  onOk: () => {
                    // 総合判定を保存
                    this.saveJudgmentBasic(output.recordData)
                  }
                })
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 総合判定を保存
   * @param {*} value
   */
  saveJudgmentBasic = (value) => {
    const params = {
      Li_ReserveNum: this.state.ReserveNum,
      Li_CourseLevel: this.checkData(this.props.Li_CourseLevel, 'number'),
      Li_TotalJudge: value.judgment_result,
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
    }
    OverallResultDisplayInputAction.saveJudgmentBasic(params)
      .then((res) => {
        this.index()

        if (this.props.onUpdate) {
          // 親画面の受診者一覧も更新
          this.props.onUpdate()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({ content: res.data.message })
      })
  }

  /**
   * 個人情報照会　モーダルを表示
   */
  showWS2584019_PersonalInfoInquirySub() {
    this.setState({
      ...this.state,
      childModal: {
        width: 1000,
        visible: true,
        component:
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={this.state.values.personal_number_id}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
      },
    })
  }

  /**
   * 指定検査受診履歴　モーダル
   */
  showWS2637036_DesignatedInspectVisitsHistory = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component:
          <WS2637036_DesignatedInspectVisitsHistory
            Li_PersonalNum={this.state.values.personal_number_id}
            Li_VisitDate={this.state.values.visit_date_on}
            Li_InspectHistoryOp={this.state.values.InspectHistoryOp}
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 経過手紙一覧 モーダル
   */
  showFollowUpLetterMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <FollowUpLetterMaintain
            Li_PersonalNum={this.state.values.personal_number_id}
            Li_VisitDate={this.state.values.visit_date_on}
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            // 面談支援用
            values={this.state.values}
            record={this.state.detailInspectiData}
            paramObject={this.getParamObject()}
            historyFlag={(this.state.values?.history ?? []).length > 0} //履歴があるか
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 紹介状抽出保守　モーダル
   */
  showWS0898001_IntroduceLetterExtractMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0898001_IntroduceLetterExtractMaintain
            Li_PersonalNum={this.state.values.personal_number_id}
            Li_VisitDate={this.state.values.visit_date_on}
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            // 面談支援用
            values={this.state.values}
            record={this.state.detailInspectiData}
            paramObject={this.getParamObject()}
            historyFlag={(this.state.values?.history ?? []).length > 0} //履歴があるか
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * カテゴリ別結果一覧のメニューリスト
   * @param {*} record
   * @returns
   */
  renderMenuCategory = (record) => (
    <Menu>
      <Menu.Item
        key='category-1'
        onClick={() => (this.showCheckCofirmYesOrNo(record))}>
        前回DO
      </Menu.Item>
      <Menu.Item
        key='category-2'
        onClick={() => (this.showWS2637053_NotesInquiry(record))}>
        指導事項
      </Menu.Item>
    </Menu>
  )

  /**
   * カテゴリ前回DO モーダル開く
   * @param {*} record
   */
  showCheckCofirmYesOrNo(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component:
          <WS0061003_ConfirmCheckYesYesNo
            Li_Title={'カテゴリ前回DO'}
            Li_Message={'カテゴリ内の前回検査結果を取得します。よろしいですか？'}
            onFinishScreen={(output) => {
              if (output && output.Lio_StsReturn) {
                // 「はい」ボタンが押下された時
                this.getPreviousCategoryDo(record)
              }
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 検査別結果一覧 モーダル
   * @param {*} record
   */
  showWS2637014_InspectResult = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637014_InspectResult
            values={this.state.values}
            record={record}
            paramObject={this.getParamObject()}
            protectFlag={this.state.protectFlag}
            onUpdateCategory={() => {
              // WS2637014_InspectResultモーダルの表示内容を更新するため、第１引数をtrueにする
              this.getCategoryJudgeList(true)
            }}
          />
        ),
      },
    })
  }

  /**
   * 変更ボタン押下処理
   * 総合結果入力 モーダルを表示
   */
  showWS2637002_OverallResultDisplayChange = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637002_OverallResultDisplayChange
            values={this.state.values}
            paramObject={this.getParamObject()}
            guideCommentInputList={this.state.guideCommentInputList}
            onUpdateGuide={() => {
              // 指導事項コメントを取得
              // WS2637002_OverallResultDisplayChangeモーダルの表示内容を更新するため、第１引数をtrueにする
              this.getGuideCommentInput(true)

              if (this.props.onUpdate) {
                // 親画面の受診者一覧も更新
                this.props.onUpdate()
              }
            }}
            onUpdateJudgment={() => {
              // 総合判定を取得
              // WS2637002_OverallResultDisplayChangeモーダルの表示内容を更新するため、第１引数をtrueにする
              this.index(true)

              if (this.props.onUpdate) {
                // 親画面の受診者一覧も更新
                this.props.onUpdate()
              }
            }}
          />
        ),
      },
    })
  }

  /**
   * 電子カルテ起動のAPIを実行
   */
  runEMedical = () => {
    let params = {
      personalNumberId: this.state.values.personal_number_id
    }

    let medicalApi = async (url, params) => {

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin': '*'
      }

      axios.post(url, params, { headers: headers })
        .then((response) => {
          console.debug(response)
        })
        .catch((error) => {
          message.error(error.message)
        })

    }

    ResultDisplayAction.runEMedical(params)
      .then((res) => {
        if (res) {
          let paramsUrl = {
            exe: res.exe,
            personal: res.personal
          }

          medicalApi(res.url, paramsUrl)
        }
      })
  }

  /**
   * 規定値の存在チェック
   */
  checkDefaultValue(selectedRecord) {
    const record = selectedRecord
    const values = { ...this.state.values }
    const params = {
      Li_ReserveNum: values.Li_ReserveNum,
      Li_PatternCode: record.pattern_code,
      Li_JudgeLevel: record.Li_JudgeLevel,
      Li_CategoryCode: record.category_code,
      Li_CategoryJudge: record.category_judge,
    }
    OverallResultDisplayInputAction.checkDefaultValue(params)
      .then(res => {
        const mess = 'Call Screen WS2644008'
        if (res) {
          if (res && res?.message === mess) {
            // 該当の規定値があれば、規定値確認モーダルを開く
            this.showWS2644008_SpecifiedValueConfirm(selectedRecord, res.inspectList, res.format)
          } else {
            // 規定値設定が可能な検査が存在しません
            message.warning(res?.message)
          }
        }
      })
  }

  /**
   * 規定値確認　モーダル
   * @param {*} inspectList
   * @param {*} format
   */
  showWS2644008_SpecifiedValueConfirm = (selectedRecord, inspectList = [], format = '') => {
    const record = selectedRecord
    const values = { ...this.state.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2644008_SpecifiedValueConfirm
            inspectList={inspectList}
            format={format}
            Li_JudgeLevel={record.Li_JudgeLevel}
            Li_PatternCode={record.pattern_code}
            Li_CategoryCode={record.category_code}
            Li_ReserveNum={values.Li_ReserveNum}
            onFinishScreen={(output) => {
              if (output) {
                // モーダルで更新があった場合
                // 検査結果一覧を再取得
                let newArray = {
                  ...this.state.selectedRecord,
                  specifiedValue: true
                }
                this.setState({
                  //右テーブルを更新するため
                  displayAll: 0,
                  selectedRecord: newArray,
                })
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 前回の検査結果を反映する
   */
  setPreviousInspectDo = (selectedRecord) => {
    const pre1ReserveNum = this.state.values.pre1ReserveNum

    if (pre1ReserveNum === '' || pre1ReserveNum === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果を反映します。よろしいですか？`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: this.getParamObject().Li_ReserveNum,
            PreviousReserveNum: pre1ReserveNum,
            SelectCategory: record?.SelectCategory,
            Li_PatternCode: record?.pattern_code
          }
          OverallResultDisplayInputAction.setPreviousInspectDo(params)
            .then((res) => {
              // 検査結果一覧を再取得
              let newArray = {
                ...this.state.selectedRecord,
                previousDo: true
              }
              console.log(newArray);
              this.setState({
                //右テーブルを更新するため
                displayAll: 0,
                selectedRecord: newArray,
                setPreviousInspectDo: res.dt0420InsertList,
                setPreviousCommentDo: res.dt0430InsertList
              })

              this.closeModal()
            })
        }
      })
    }
  }

  /**
   * 保存 処理
   */
  saveResultValueList = () => {
    const params = {
      Li_CourseLevel: this.checkData(this.props.Li_CourseLevel, 'number'),
      Li_ReserveNum: this.state.ReserveNum,
      changeData: this.state.changeData,
      categoryComment: this.state.categoryComment,
      categoryCode: this.state.selectedRecord.category_code,
      categoryJudge: this.state.categoryJudge,
      categoryJudgmentChangeFlag: this.state.categoryJudgmentChangeFlag,
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      user: this.formRef.current?.getFieldValue('manager')
    }

    OverallResultDisplayInputAction.saveResultValueList(params)
      .then((res) => {
        if (res) {
          let newArray = {
            ...this.state.selectedRecord,
            category_comment: this.state.categoryComment,
            category_judge: this.state.categoryJudge,
            categoryJudgeColorNum: this.state.categoryJudgeColorNum,
            disabledUpdateBtn: true
          }
          this.setState({
            //右テーブルを更新するため
            selectedRecord: newArray,
            categoryJudgmentChangeFlag: false,
            setPreviousInspectDo: [],
            setPreviousCommentDo: []
          })

          // 保存ボタンの状態を親画面にも送る
          this.props.onFlagUpdate(true)

          this.index()
        }
      })
  }

  /**
   * 添付ファイル
   */
  openFileManager = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WSFileManager_FileManager
            Params_2612={{
              Li_Division: '個人',
              Li_Identify: MagicXpaFunc.Str(
                MagicXpaFunc.Val(
                  this.state.values.personal_number_id,
                  '10'
                ),
                '10P0'
              )
            }}
          />
        )
      }
    });
  }

  /**
   * 画像　モーダル
   */
  showImageDisplay = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ImageDisplay
            personal_number_id={this.state.values.personal_number_id}
            reservation_number={this.state.values.Li_ReserveNum}
            categoryCode={record.category_code}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * グラフ　モーダル
   */
  showChartDisplay = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1000,
        component: (
          <ChartDisplay
            values={this.state.values}
            record={record}
            paramObject={this.getParamObject()}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  changeProgressData = (check, program, process) => {
    let params = {
      check: check,
      reserveNum: this.state.values.Li_ReserveNum,
      program: program,
      process: process
    }
    ResultDisplayAction.changeProgressData(params)
      .then(res => {
        if (process == 'temporary') {
          // 仮確定
          this.setState({
            provisionalFlag: check
          })
        } else if (process == 'protection') {
          // 保護
          this.setState({
            protectFlag: check
          })
        }
        this.props.onUpdate()
      })
  }

  /**
   * 同一受診日の他の受診者の面談支援画面に移動
   */
  changePatient = (newIndex) => {
    const reserveNum = this.state.dataSource[newIndex]?.reservation_number
    this.setState({
      currentIndex: newIndex,
    })
    // 自動判定
    this.showWS0723001_AutoJudgeScreen(reserveNum, newIndex)
  }

  /**
  * プリンター確認 モーダル
  */
  showWS0433001_PrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS0433001_PrinterConfirm
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                this.print(output)
              }
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ,
      },
    })
  }

  /**
  * 診断メモ モーダル
  */
  showMemoFunction = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <MemoFunction
            date={this.formRef.current?.getFieldValue('date')}
            KanjiName={this.state.values.kanji_name}
            personal_number_id={this.state.values.personal_number_id}
            reservation_number={this.state.ReserveNum}
            onSaveMemos={async () => {
              if (this.props.onUpdate) {
                this.props.onUpdate()
              }
            }}

            // メモ入力画面での保存ボタンの状態
            onFlagUpdate={(bool) => {
              this.setState({
                MemoFunctionUpdateBtn: bool
              })
            }}
          />
        ),
      }
    })
  }

  /**
   * 要精密検査
   */
  showDetailInspectResultDisplay = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <DetailInspectResultDisplay
            values={this.state.values}
            paramObject={this.getParamObject()}
          // subTitle={this.state.subTitle}
          // visitDate={this.formRef.current?.getFieldValue('date')}
          />
        ),
      }
    })
  }

  /**
   * 印刷処理
   * @param {*} output
   */
  print = (output) => {
    this.setState({ isLoadingPrint: true })
    if (output.Lo_Preview) {
      let params = {
        StsConfirm: 6,
        Li_CourseLevel: 0 || '',
        Li_ReserveNum: this.state.values.Li_ReserveNum || '',
        Style: this.state.initialValues.StandardStyle,
        PrinterNum: output.Lo_PrinterNo,
        Preview: output.Lo_Preview ? 1 : 0,
        NumCopies: 1,
        InputFlag: '',
        L1Output: this.state.L1Output ? 1 : 0,
        L2Output: this.state.L2Output ? 1 : 0,
        L3Output: this.state.L3Output ? 1 : 0,
        OrgsTowards: 0,
        Submission: 0,
      }
      CoupledPrintInstruction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          // fileOpen.onload = function () {
          //   fileOpen.print();
          // };
        })
        .catch(error => {
          const res = error.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
        .finally(() => {
          this.setState({ isLoadingPrint: false })
        })
    } else {
      let params = {
        StsConfirm: 6,
        Li_CourseLevel: 0 || '',
        Li_ReserveNum: this.state.values.Li_ReserveNum || '',
        Style: this.state.initialValues.StandardStyle,
        PrinterNum: output.Lo_PrinterNo,
        Preview: output.Lo_Preview ? 1 : 0,
        NumCopies: 1,
        InputFlag: '',
        L1Output: this.state.L1Output ? 1 : 0,
        L2Output: this.state.L2Output ? 1 : 0,
        L3Output: this.state.L3Output ? 1 : 0,
        OrgsTowards: 0,
        Submission: 0,
      }
      CoupledPrintInstruction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
        .catch(error => {
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
        .finally(() => {
          this.setState({ isLoadingPrint: false })
        })
    }
  }

  /**
   * ビューワ表示
   * @param {*} record
   */
  getImageRefelenceUrl(record) {
    let param = {
      categoryCode: record.category_code,
      personalNumberId: this.state.values.personal_number_id,
      reservationNumber: this.state.values.Li_ReserveNum,
    }
    ResultDisplayAction.getImageRefelenceUrl(param)
      .then(res => {
        if (res) {
          // window.open(res, null, 'top=100,left=800,width=800,height=800')
          // URL実行してプログラムを開く
          let data = res

          let params = {
            exe: data.exe,
            personal: data.personal,
            modality: data.modality,
            date: data.date,
          }

          // URL実行
          axios({
            method: 'post',
            url: data.url,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              // 'Access-Control-Allow-Origin': '*'
            },
            params: params,
          })
        }
      })
  }

  /**
   * 履歴にある受診日へ移動
   * @param {*} record
   */
  previousResultDisplay = async (record) => {
    const reserveNum = record.reservation_number
    this.setState({
      initialDisplayFlag: true,
    })

    await this.setState({
      dataSource: [], // 前回の履歴に戻る為、親画面の受診者を空にする。左右の矢印ボタンを使えないようにする為
      ReserveNum: reserveNum
    })

    // 保護ありのコースかどうか
    if (record.protectFlag) {
      await this.setStateValues('Li_ReserveNum', reserveNum)
      await this.index()
    } else {
      // 自動判定
      await this.showWS0723001_AutoJudgeScreen(reserveNum)
    }
  }

  /**
   * <Input.Search>のstyle設定
   * @param {*} id <Input.Search>のid
   * @param {*} colorNum 色番号
   */
  changeInputSearchStyle = (id, colorNum) => {
    // <Input.Search>のstyleにてcolor設定ができなかった為、getElementByIdでドキュメント要素を取得する
    let target = document.getElementById(id)
    if (target != null) {
      // 文字色設定
      target.style.color = Color(colorNum)?.Foreground
    }
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
        closable: true
      }
    })
  }

  render() {
    return (
      <div className='overall-result-display-input' >
        <Card
          // title='総合結果表示'
          id='ResultDisplayCard'
          style={{ position: 'relative', width: '100%', height: '100vh', paddingTop: 20 }}
        >
          <Form
            ref={this.formRef}
            initialValues={this.state.DataScreenData}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Button
                  icon={<LeftOutlined />}
                  style={{ height: 80, marginTop: 'auto', marginBottom: 'auto' }}
                  disabled={this.state.dataSource ? this.state.dataSource[this.state.currentIndex - 1] ? false : true : true}
                  onClick={() => this.changePatient(this.state.currentIndex - 1)}
                />
                <div className='box_search'>
                  <div className='box_search_inner' style={{ minWidth: 180, maxWidth: 220 }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='personal_number_id'
                        label='個人番号'
                        {...labelSize}
                      >
                        <div>
                          {this.state.values.personal_number_id}
                        </div>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          size='small'
                          onClick={() => this.showWS2584019_PersonalInfoInquirySub()}
                          style={{ float: 'right', display: 'inline-block' }}
                          icon={<MoreOutlined />}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='kanji_name'
                      label='氏名'
                      {...labelSize}
                    >
                      <div>
                        {this.state.values.kanji_name}
                      </div>
                    </Form.Item>

                  </div>
                  <div className='box_inner_horizontal' style={{ gap: 0 }}>
                    <div className='box_search_inner' style={{ minWidth: 220, maxWidth: 240 }}>
                      <Form.Item
                        name='address'
                        label='住所'
                        {...labelSize}
                      >
                        <div>{this.state.values.address}</div>
                      </Form.Item>

                      <Form.Item
                        name='birthday_on'
                        label='生年月日'
                        {...labelSize}
                      >
                        <div style={{ display: 'flex', gap: 10 }}>
                          <span>
                            {this.state.values.birthday_on}
                          </span>
                          {this.state.values.Age ?
                            <span>
                              {this.state.values.Age} 歳
                            </span>
                            : null
                          }
                        </div>
                      </Form.Item>

                    </div>
                    <div style={{ marginTop: 'auto' }}>
                      <img
                        src={GetImage(this.state.values.genderImage)}
                        style={{
                          width: 40, height: 40,
                          display: (this.state.values.genderImage) ? '' : 'none'
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='box_search'>
                  <div className='box_inner_vertical'>
                    <div className='box_inner_horizontal' style={{ gap: 0 }}>
                      <div className='box_search_inner' style={{ minWidth: 180, maxWidth: 180 }}>
                        <Form.Item
                          name='DateChar'
                          label='受診日'
                          {...labelSize}
                        >
                          <div>
                            {this.state.values.DateChar ? moment(this.state.values.DateChar).format('YYYY/MM/DD (ddd)') : ''}
                          </div>
                        </Form.Item>
                        <Form.Item
                          name='AcceptNum'
                          label='受付No'
                          {...labelSize}
                        >
                          <div>
                            {this.state.values.AcceptNum}
                          </div>
                        </Form.Item>
                      </div>

                      <div className='box_search_inner' style={{ minWidth: 280, maxWidth: 330 }}>
                        <Form.Item
                          name='visit_course'
                          label='契約'
                          {...labelSize}
                        >
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <span>
                              {this.state.values.visit_course}
                            </span>
                            <span>
                              {this.state.values.contract_short_name}
                            </span>
                          </div>
                        </Form.Item>
                        <div className='box_inner_horizontal'>
                          <Form.Item
                            name='number'
                            label='受診回数'
                            {...labelSize}
                          >
                            <div >{(this.state.values?.number ? this.state.values?.number : '') + ' ' + '回'}</div>
                          </Form.Item>
                          <Button
                            type='primary'
                            size='small'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  centered: true,
                                  component: (
                                    <WS2537001_PersonalReserveProcess
                                      Li_Date={this.state.values.DateChar ? moment(this.state.values.DateChar).format('YYYY/MM/DD') : ''}
                                      Li_CourseLevel={0}
                                      Li_ReserveNum={this.state.ReserveNum}
                                      Li_PersonalNum={
                                        this.state.values.personal_number_id
                                      }
                                      Li_Getctxname={''}
                                      Li_ProcessDivision={''}
                                      Li_Option={''}
                                      Li_Child={true}
                                      onFinishScreen={(output) => {
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            <span className='btn_label'>
                              {'受診変更'}
                            </span>
                          </Button>
                          <Button
                            type='primary'
                            size='small'
                            onClick={() => {
                              // 電子カルテ起動のAPIを実行
                              this.runEMedical()
                            }}
                          >
                            <span className='btn_label'>
                              {'電子カルテ'}
                            </span>
                          </Button>
                        </div>

                        <div className='box_button_bottom_right' style={{ gap: 0, paddingTop: 0 }}>
                          <Button
                            type='primary'
                            size='small'
                            style={{ width: 168, marginRight: 10 }}
                            icon={<RedoOutlined />}
                            onClick={() => {
                              // 画面情報の再取得
                              this.index()
                            }}
                          >
                            <span className='btn_label'>
                              {'最新表示'}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ width: '25%' }}>
                  <Table
                    id='customTable2'
                    bordered={true}
                    size='small'
                    pagination={false}
                    showHeader={false}
                    dataSource={this.state.values?.history}
                    rowKey={record => record.id}
                    scroll={{ x: 'max-content', y: 103 }}
                    rowClassName={(record) => record.reservation_number === this.state.ReserveNum ? 'table-row-light' : ''}
                  >
                    <Table.Column
                      dataIndex='date'
                      className='column-size-date'
                      render={(value) => {
                        return (
                          <div>{moment(value).format('YYYY/MM/DD (ddd)')}</div>
                        )
                      }}
                    />
                    <Table.Column
                      dataIndex='course'
                      className='column-size-60'
                      render={(value, record) => {
                        // 保護マークとコース名
                        return (
                          <div style={{ display: 'flex', gap: 8 }}>
                            <img src={(record.protectFlag) ? status_input_lock : status_input_lock_disabled} />
                            <div>{value}</div>
                          </div>
                        )
                      }}
                    />
                    <Table.Column
                      key='action'
                      fixed='right'
                      width={30}
                      render={(value, record) =>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          onClick={() => {
                            // 履歴にある受診日へ移動
                            this.previousResultDisplay(record)
                          }}
                        />
                      }
                    />
                  </Table>
                </div>

                <div className='box_search' >
                  <div className='box_search_inner' style={{ minWidth: 180 }}>
                    <Form.Item
                      name='user'
                      label='ログインユーザー'
                    >
                      <div>{this.props.user.name}</div>
                    </Form.Item>

                    <Form.Item
                      name='manager'
                      label='担当者'
                    >
                      <Select
                        onChange={() => this.forceUpdate()}
                      >
                        {this.state.userList.map((value) => {
                          return (
                            <Select.Option
                              key={value.id}
                              value={value.userId}
                            >
                              {value.name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <Button
                  icon={<RightOutlined />}
                  style={{ height: 80, marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto' }}
                  disabled={this.state.dataSource ? this.state.dataSource[this.state.currentIndex + 1] ? false : true : true}
                  onClick={() => this.changePatient(this.state.currentIndex + 1)}
                />
              </div>

              <div className='box_inner_horizontal'>
                {/* カテゴリ別結果一覧 */}
                <div className='box_inner_vertical' style={{ width: this.state.initialDisplayFlag == true ? '75%' : '25%' }}>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='OverallJudgeG'
                      label='総合判定'
                      style={{ marginBottom: 0 }}
                    >
                      <Input.Search
                        id='overallJudgeGInput' //getElementByIdの為にid設定
                        onSearch={() => this.showWS0285001_JudgeQuery()}
                        style={{ width: 100 }}
                        disabled={this.state.protectFlag}
                      />
                    </Form.Item>

                    <Form.Item
                      name='provisional'
                      label='仮確定'
                      style={{ marginBottom: 0 }}
                      valuePropName='checked'
                    >
                      <Checkbox
                        onChange={(e) => this.changeProgressData(e.target.checked ? 1 : 0, '結果表', 'temporary')}
                      />
                    </Form.Item>

                    <Form.Item
                      name='protect'
                      label='保護'
                      style={{ marginBottom: 0 }}
                      valuePropName='checked'
                    >
                      <Checkbox
                        onChange={(e) => this.changeProgressData(e.target.checked ? 1 : 0, '結果表', 'protection')}
                      />
                    </Form.Item>
                  </div>

                  <Table
                    bordered
                    size='small'
                    id={'ResultDisplay'}
                    pagination={false}
                    dataSource={this.state.categoryJudgeList}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => record.category_code === this.state.selectedRecord.category_code ? 'table-row-light' : ''}
                    scroll={{ x: 'max-content', y: resizableTableScroll(60, 'ResultDisplay') }}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: (event) => {
                          if (this.state.disabledUpdateBtn) {
                            if (record.category_code == this.state.selectedRecord.category_code) {
                              //全カテゴリ表示
                              this.setState({
                                selectedRecord: [],
                                selectedInspectId: record.id,
                                displayAll: 1,
                                bloodTest: 0
                              })
                            } else {
                              //選択カテゴリのみ表示
                              this.setState({
                                selectedRecord: record,
                                selectedInspectId: record.id,
                                displayAll: 0,
                                initialDisplayFlag: false,
                                bloodTest: 0
                              })
                            }
                          } else {
                            Modal.confirm({
                              title: '確認',
                              content: '保存処理がされていません。このままカテゴリを変更しますか？',
                              width: 520,
                              icon: <QuestionCircleTwoTone />,
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                if (record.category_code == this.state.selectedRecord.category_code) {
                                  //全カテゴリ表示
                                  this.setState({
                                    selectedRecord: [],
                                    selectedInspectId: record.id,
                                    displayAll: 1,
                                    bloodTest: 0
                                  })
                                } else {
                                  //選択カテゴリのみ表示
                                  this.setState({
                                    selectedRecord: record,
                                    selectedInspectId: record.id,
                                    displayAll: 0,
                                    initialDisplayFlag: false,
                                    bloodTest: 0
                                  })
                                }
                              }
                            })
                          }
                        }
                      }
                    }}
                  >

                    <Table.Column
                      title='カテゴリ名称'
                      dataIndex='category_name'
                      className='category-table'
                      render={(item, record, index) => (
                        <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '15px'
                          }}>
                            <Tooltip title={record.categoryCodeStr}>
                              <span>{record.category_name}</span>
                            </Tooltip>
                          </div>

                          <div style={{ display: 'flex', gap: 5 }}>
                            <div style={{ display: record.categoryNameChangeFlag ? '' : 'none' }}>
                              <Tooltip title='判定の内容を直接変更しています。'>*</Tooltip>
                            </div>

                            <div style={{ display: record.alreadyInput ? '' : 'none' }}>
                              *
                            </div>
                          </div>
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values.DateChar) ? '' : 'none' }}>
                          <Tooltip
                            title={
                              <div className='box_inner_vertical'>
                                <div>{moment(this.state.values.DateChar).format('YYYY/MM/DD')}</div>
                                <div>{this.state.values?.contract_short_name}</div>
                              </div>
                            }
                          >
                            <div>{'今回'}</div>
                          </Tooltip>
                        </div>
                      }
                      dataIndex='category_judge'
                      className='this-time-result'
                      width={100}
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.categoryJudgeColorNum)?.Foreground,
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values?.pre1VisitDate) ? '' : 'none' }}>
                          <Tooltip
                            title={
                              <div className='box_inner_vertical'>
                                <div>{this.state.values?.pre1VisitDate}</div>
                                <div>{this.state.values?.pre1CourseName}</div>
                              </div>
                            }
                          >
                            <div>{'前回'}</div>
                          </Tooltip>
                        </div>
                      }
                      dataIndex='pre_1_category_judge'
                      width={75}
                      className='category-table'
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.pre1CategoryJudgeColorNum)?.Foreground
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values?.pre2VisitDate) ? '' : 'none' }}>
                          <Tooltip
                            title={
                              <div className='box_inner_vertical'>
                                <div>{this.state.values?.pre2VisitDate}</div>
                                <div>{this.state.values?.pre2CourseName}</div>
                              </div>
                            }
                          >
                            <div>{'前々回'}</div>
                          </Tooltip>
                        </div>
                      }
                      dataIndex='pre_2_category_judge'
                      width={75}
                      className='category-table'
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.pre2CategoryJudgeColorNum)?.Foreground
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values?.pre3VisitDate) ? '' : 'none' }}>
                          <Tooltip
                            title={
                              <div className='box_inner_vertical'>
                                <div>{this.state.values?.pre3VisitDate}</div>
                                <div>{this.state.values?.pre3CourseName}</div>
                              </div>
                            }
                          >
                            <div>{'３回前'}</div>
                          </Tooltip>
                        </div>
                      }
                      dataIndex='pre_3_category_judge'
                      width={75}
                      className='category-table'
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.pre3CategoryJudgeColorNum)?.Foreground
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values?.pre4VisitDate) ? '' : 'none' }}>
                          <Tooltip
                            title={
                              <div className='box_inner_vertical'>
                                <div>{this.state.values?.pre4VisitDate}</div>
                                <div>{this.state.values?.pre4CourseName}</div>
                              </div>
                            }
                          >
                            <div>{'４回前'}</div>
                          </Tooltip>
                        </div>
                      }
                      dataIndex='pre_4_category_judge'
                      width={75}
                      className='category-table'
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.pre4CategoryJudgeColorNum)?.Foreground
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      key='action'
                      fixed='right'
                      width={30}
                      className='category-table'
                      render={(value, record, index) => {
                        return (
                          <Dropdown
                            trigger='click'
                            size='small'
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  style={{ display: record.keyImageExistCheck == true ? '' : 'none' }}
                                  onClick={() => this.showImageDisplay(record)}
                                >
                                  <label>キー</label>
                                </Menu.Item>
                                <Menu.Item
                                  key='2'
                                  style={{ display: record.keyImageExistCheck == true ? '' : 'none' }}
                                  onClick={() => {
                                    this.getImageRefelenceUrl(record)
                                  }}
                                >
                                  <label>画像</label>
                                </Menu.Item>
                                <Menu.Item
                                  key='3'
                                  onClick={() => this.showChartDisplay(record)}
                                >
                                  <label>グラフ</label>
                                </Menu.Item>
                                <Menu.Item
                                  key='4'
                                  onClick={() => this.checkDefaultValue(record)}
                                >
                                  <label>規定値</label>
                                </Menu.Item>
                                <Menu.Item
                                  key='5'
                                  onClick={() => { this.setPreviousInspectDo(record) }}
                                >
                                  <label>前回DO</label>
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              size='small'
                              icon={<MoreOutlined />}
                            />
                          </Dropdown>
                        )
                      }}
                    />
                  </Table>
                </div>

                <div className='box_inner_vertical' style={{ width: '75%' }}>
                  <div
                    style={{ display: !(this.state.initialDisplayFlag) ? '' : 'none' }}
                  >
                    <DetailResultDisplay
                      categorys={this.state.categorys}
                      Li_ReserveNum={this.state.ReserveNum}
                      formRef={this.formRef}
                      values={this.state.values}
                      record={this.state.selectedRecord}
                      user={this.formRef.current?.getFieldValue('manager')}
                      paramObject={this.getParamObject()}
                      protectFlag={this.state.protectFlag}
                      changeFlag={this.state.changeFlag}
                      protectImage={this.props.protectImage}
                      setPreviousInspectDo={this.state.setPreviousInspectDo}
                      setPreviousCommentDo={this.state.setPreviousCommentDo}
                      onChangeData={(output) => {
                        this.setState({
                          changeData: output.changeData,
                          categoryComment: output.category_comment,
                          categoryJudge: output.category_judge,
                          categoryJudgmentChangeFlag: output.categoryJudgmentChangeFlag,
                          categoryJudgeColorNum: output.categoryJudgeColorNum,
                        })
                      }}
                      onDisabledUpdateBtn={(bool) => {
                        // 保存ボタンの活性変更
                        this.setState({ disabledUpdateBtn: bool })
                        if (this.props.onFlagUpdate) {
                          // 保存ボタンの状態を親画面にも送る
                          this.props.onFlagUpdate(bool)
                        }
                      }}
                    />

                  </div>

                  {/* 初期表示時にのみアイコンを表示 */}
                  <img
                    src={initialDisplayIcon}
                    style={{
                      width: '300px',
                      marginLeft: 'auto',
                      marginTop: 'auto',
                      display: this.state.initialDisplayFlag ? '' : 'none'
                    }}
                  />

                </div>
              </div>
            </div>
          </Form>

          {/* Footer */}
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                // 総合結果入力 モーダルを表示
                this.showWS2637002_OverallResultDisplayChange()
              }}
            >
              <span className='btn_label'>
                指導事項
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => {
                this.showWS0723001_AutoJudgeScreen(this.state.ReserveNum)
              }}
              disabled={this.state.protectFlag === 1}
              hidden={this.state.protectFlag}
            >
              <span className='btn_label'>
                自動判定
              </span>
            </Button>

            {/* ALPHASALUS-2377課題でコメントアウト */}
            {/* <Button
              type='primary'
              onClick={() => {
                this.showDetailInspectResultDisplay()
              }}
            >
              <span className='btn_label'>
                要精密検査
              </span>
            </Button> */}

            <Button
              type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 'fit-content',
                    component: (
                      <WS3020036_CoupledPrintInstruction
                        Li_ReserveNum={this.state.values.Li_ReserveNum}
                        Li_CourseLevel={0}
                        onFinishScreen={(obj) => {
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              }}
            >
              <span className='btn_label'>
                報告書出力
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => {
                this.showWS0433001_PrinterConfirm()
              }}
            >
              <span className='btn_label'>
                仮報告書出力
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => {
                // 経過手紙
                this.showFollowUpLetterMaintain()
              }}
            >
              <span className='btn_label'>
                経過手紙
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => {
                // 精密検査の紹介状
                this.showWS0898001_IntroduceLetterExtractMaintain()
              }}
            >
              <span className='btn_label'>
                要精密手紙
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => {
                this.showMemoFunction()
              }}
            >
              <span className='btn_label'>
                メモ
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => this.openFileManager()}
            >
              <span className='btn_label'>
                添付ファイル
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => this.saveResultValueList()}
              disabled={this.state.disabledUpdateBtn}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          centered={this.state.childModal.centered}
          closable={this.state.childModal.closable}
          onCancel={() => {
            if (this.state.MemoFunctionUpdateBtn) {
              // 画面を閉じる
              this.closeModal()
              // 最初の自動判定で×を押された場合
              if (this.state.autoJudgeScreenClose) {
                this.index()
              }
            } else {
              // 保存しますかを聞く
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    MemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => { console.log('閉じません'); }
              })
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultDisplay)
