import { message } from 'antd'
import NormalValueSettingEditService from "services/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSettingEditService"

const NormalValueSettingEditAction = {
  GetData(params) {
    console.log("actionの作成")
    console.log(params)
    ////確かめ方
    ////console.log(FormatCopyService.index())
    // return FormatCopyService.index()
    return NormalValueSettingEditService.GetData(params)
      .then((res) => {
        console.log("actionの中")
        console.log(res)
        return res?.data
      })
      .catch((err) => {
        console.log(err)
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


}
export default NormalValueSettingEditAction