import axios from "configs/axios";
const apiPaths = {
  onClickUpdate: "/api/main/todo-create/onclickupdate",
};
const TodoCreateService = {
    async onClickUpdate(params) {
        return axios.get(apiPaths.onClickUpdate, { params });
    },
};

export default TodoCreateService;