import React from "react";
import { connect } from "react-redux";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Table, Space, Button, Form, Dropdown, Menu, Modal, message } from "antd";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import AgencyInquirySubAction from "redux/SpecificMedicalExamination/ConsultTicketInputProcessList/AgencyInquirySub.action";
import WS1302005_AddCorrectScreen from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302005_AddCorrectScreen";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
const { confirm } = Modal;
class WS1302001_AgencyInquirySub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '代行機関照会SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      dataSource: [],
      selectedRow: [],
      selectedRowKey: [1],
      isLoadingTable: false,
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
    };

    // this.addTo = this.addTo.bind(this);
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    AgencyInquirySubAction.Index()
      .then(res => {
        this.setState({
          ...this.state.dataSource,
          dataSource: res || [],
          selectedRow: res[0]
        });
      })
  }

  DeleteFlag(record) {
    // Modal.info({
    //   title: '削除を行いますか',
    //   okText: 'はい',
    //   cancelText: 'いいえ',
    //   width: 400,
    //   onOk: () => {
    //     this.delete(record)
    //   },
    //   onCancel() {
    //   },
    // })
    confirm({
      title: '削除を行いますか',
      // icon: <ExclamationCircleFilled />,
      okText: 'はい',
      okType: 'danger',
      cancelText: 'いいえ',
      onOk: () => {
        this.delete(record)
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  }

  delete(record) {
    console.log(record);
    let param = {
      id: record.id
    }
    AgencyInquirySubAction.delete(param)
      .then(res => {
        message.success('削除が完了しました')
        this.getScreenData()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className="agency-inquiry-sub">
        <Card title="代行機関照会">
          <Form ref={this.formRef} autoComplete="off">
            <Table
              size="small"
              bordered
              dataSource={this.state.dataSource}
              pagination={false}
              rowClassName={(record, index) => record.optionfile.id === this.state.selectedRow?.optionfile.id ? 'table-row-light' : ''}
              rowKey={record => record.Gl0_No}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.setState({
                      selectedRow: record
                    })
                  },
                  onDoubleClick: () => {
                    if (this.props.onFinishScreen) {
                      this.props.onFinishScreen({
                        recordData: record,
                        Gl0AgencyNum: record.Gl0AgencyNum
                      });
                    }
                  }
                };
              }}
              scroll={{ y: resizableTableScroll(120) }}
            >
              <Table.Column title="No" dataIndex="Gl0_No" width={60} />
              <Table.Column
                title="代行機関番号"
                dataIndex="Gl0AgencyNum"
                width={150}
              />
              <Table.Column title="名称" dataIndex="Gl0AgencyName" />
              <Table.Column
                align='center'
                width={40}
                fixed='right'
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      let lastAgency = this.state.dataSource[this.state.dataSource.length - 1];
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <WS1302005_AddCorrectScreen
                              newFlag={true}
                              Gl0_No={lastAgency.Gl0_No + 1}
                              Gl0AgencyNum=""
                              Gl0AgencyName=""
                              Pl1Add={true}
                              Pl1Type={this.state.selectedRow.optionfile.option_type}
                              onFinishScreen={output => {
                                this.forceUpdate();
                                this.closeModal();
                                this.getScreenData();
                              }}
                            />
                          )
                        }
                      })
                    }}
                  />}
                render={(text, record, index) => (
                  <Dropdown size='small' trigger='click' overlay={() => (
                    <Menu >
                      <Menu.Item key="1" onClick={() => (
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            centered: true,
                            width: 400,
                            component: (
                              <WS1302005_AddCorrectScreen
                                newFlag={false}
                                Gl0_No={record.Gl0_No}
                                Gl0AgencyNum={record.Gl0AgencyNum}
                                Gl0AgencyName={record.Gl0AgencyName}
                                Pl1Add={false}
                                Pl1Type={record.optionfile.option_type}
                                onFinishScreen={output => {
                                  this.forceUpdate();
                                  this.closeModal();
                                  this.getScreenData();
                                }}
                              />
                            ),
                          },
                        })
                      )}>訂正</Menu.Item>
                      <Menu.Item key="2" onClick={() => (
                        this.DeleteFlag(record.optionfile)
                      )}>削除</Menu.Item>
                    </Menu>
                  )}>
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )}
              />
            </Table>
            <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              {/* <Button
                type="primary"
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  let lastAgency = this.state.dataSource[this.state.dataSource.length - 1];
                  this.setState({
                    childModal: {
                      visible: true,
                      width: 300,
                      component: (
                        <WS1302005_AddCorrectScreen
                          newFlag={true}
                          Gl0_No={lastAgency.Gl0_No + 1}
                          Gl0AgencyNum=""
                          Gl0AgencyName=""
                          Pl1Add={true}
                          Pl1Type={this.state.selectedRow.optionfile.option_type}
                          onFinishScreen={output => {
                            this.forceUpdate();
                            this.closeModal();
                            this.getScreenData();
                          }}
                        />
                      )
                    }
                  });
                }}
              >
                追加
              </Button> */}
              {/* <Button
                type="primary"
                style={{ marginLeft: "5px", fontWeight: "bold" }}
                onClick={() => {
                  this.setState({
                    childModal: {
                      visible: true,
                      width: 300,
                      component: (
                        <WS1302005_AddCorrectScreen
                          newFlag={false}
                          Gl0_No={this.state.selectedRow.Gl0_No}
                          Gl0AgencyNum={this.state.selectedRow.Gl0AgencyNum}
                          Gl0AgencyName={this.state.selectedRow.Gl0AgencyName}
                          Pl1Add={false}
                          Pl1Type={this.state.selectedRow.optionfile.option_type}
                          onFinishScreen={output => {
                            this.forceUpdate();
                            this.closeModal();
                            this.getScreenData();
                          }}
                        />
                      )
                    }
                  });
                }}
              >
                訂正
              </Button> */}
              <Button
                type="primary"
                style={{ marginLeft: "5px", fontWeight: "bold" }}
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      recordData: this.state.selectedRow,
                      Gl0AgencyNum: this.state.selectedRow.Gl0AgencyNum
                    });
                  }
                }}
              >
                選択
              </Button>
            </Space>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1302001_AgencyInquirySub);
