import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Checkbox, Radio, Button, Table, Row, Col, Space, DatePicker, Modal, message, Spin, Tooltip } from "antd";
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import WS1302001_AgencyInquirySub from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302001_AgencyInquirySub.jsx';
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';
import WS2656088_DetailsExtract from 'pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656088_DetailsExtract.jsx';
import SpecificInsureXmlOutputAction from 'redux/SpecificInsureGuide/SpecificCoerciveFingerXmlOutput/SpecificInsureXmlOutput.action';
import WS2656096_OutputConfirm from 'pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656096_OutputConfirm';
import WS2656103_ResultConfirm from 'pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656103_ResultConfirm.jsx';
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

import moment from 'moment';
const dateFormat = 'YYYY/MM/DD';
const opacity = 0.5;

const smGrid = {
  labelCol: { style: { width: 65 } },
};

const smGrid2 = {
  labelCol: { style: { width: 50 } },
};
class WS2656001_SpecificInsureXmlOutput extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '特定保指XML出力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      Paginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      GimplementDateFromChars: '',
      GimplementDateToChars: '',
      Type: '',
      Gfirst: '',
      GfinalEvaluation: '',
      Ginterruption: '',
      GcontinuedSupport: '',
      GmidTermEvaluation: '',
      Gagency: '',
      StsMore: 0,
      GinsuranceNum: 0,
      ImplementDateFrom: '',
      ImplementDateTo: '',
      xml02010W1_4: '',
      selectall: false,
      MemberListFlag: false,
      KanaName: '',
      selectedRowKeys: [],
      isloading: false,
      ExamPersonGroupHealthDivision: 0,
      Relationship: 9,
      CreateDivision1New2ReCreate: 3,
      GinsuranceNumName: '',
      insurer_kanji_name: '',
    };
  }
  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    SpecificInsureXmlOutputAction.getScreenData()
      .then((res) => {
        this.setState({
          GimplementDateFromChars: res.GimplementDateFromChars,
          GimplementDateToChars: res.GimplementDateToChars,
          Type: res.Type,
          SettlementInfo: res.SettlementInfo,
          Gfirst: res.Gfirst,
          GfinalEvaluation: res.GfinalEvaluation,
          Ginterruption: res.Ginterruption,
          GcontinuedSupport: res.GcontinuedSupport,
          GmidTermEvaluation: res.GmidTermEvaluation,
          ImplementDateFrom: res.GimplementDateFromChars,
          ImplementDateTo: res.GimplementDateToChars,
        })
        this.formRef.current?.setFieldsValue({
          GimplementDateFromChars: res.GimplementDateFromChars,
          GimplementDateToChars: res.GimplementDateToChars,
          Type: res.Type,
          SettlementInfo: res.SettlementInfo,
          Gfirst: res.Gfirst,
          GfinalEvaluation: res.GfinalEvaluation,
          Ginterruption: res.Ginterruption,
          GcontinuedSupport: res.GcontinuedSupport,
          GmidTermEvaluation: res.GmidTermEvaluation
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  SearchBtn() {
    if (this.state.Gagency === '' && this.state.Type === 1) {
      let bool = true;
      this.AgencyInquirySub(bool);
      return;
    } else if (this.state.GinsuranceNum === 0 && this.state.Type === 6) {
      let bool = true;
      this.InsurerNumberInquiry(bool);
      return;
    }
    const GimplementDateFromChars = this.formRef.current.getFieldValue("GimplementDateFromChars");
    const GimplementDateToChars = this.formRef.current.getFieldValue("GimplementDateToChars");
    if (!GimplementDateFromChars || !GimplementDateToChars) {
      message.error('実施日を入力してください')
      return
    }
    this.setState({ isloading: true });
    let param = {
      GimplementDateFromChars: this.state.GimplementDateFromChars,
      GimplementDateToChars: this.state.GimplementDateToChars,
      StsM33: this.state.StsMore,
      GinsuranceNum: this.state.GinsuranceNum,
      Type: this.state.Type,
      Gagency: this.state.Gagency,
      Gfirst: this.state.Gfirst,
      GfinalEvaluation: this.state.GfinalEvaluation,
      Ginterruption: this.state.Ginterruption,
      GcontinuedSupport: this.state.GcontinuedSupport,
      GmidTermEvaluation: this.state.GmidTermEvaluation,
      Li_More: this.state.StsMore,
      ImplementDateFrom: this.state.ImplementDateFrom,
      ImplementDateTo: this.state.ImplementDateTo,
      ExamPersonGroupHealthDivision: this.state.ExamPersonGroupHealthDivision,
      Grelationship: this.state.Relationship,
      CreateDivision1New2ReCreate: this.state.CreateDivision1New2ReCreate
    }
    SpecificInsureXmlOutputAction.SearchBtn(param)
      .then((res) => {
        this.indexMemberList();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  indexMemberList(bool, name) {
    let param = {
      GimplementDateFromChars: this.state.GimplementDateFromChars,
      GimplementDateToChars: this.state.GimplementDateToChars,
      StsM33: this.state.StsMore,
      GinsuranceNum: this.state.GinsuranceNum,
      Type: this.state.Type,
      Gagency: this.state.Gagency,
      Gfirst: this.state.Gfirst,
      GfinalEvaluation: this.state.GfinalEvaluation,
      Ginterruption: this.state.Ginterruption,
      GcontinuedSupport: this.state.GcontinuedSupport,
      GmidTermEvaluation: this.state.GmidTermEvaluation,
      Li_More: (bool !== undefined) ? bool : this.state.StsMore,
      ImplementDateFrom: this.state.ImplementDateFrom,
      ImplementDateTo: this.state.ImplementDateTo,
      KanaName: (name !== undefined) ? name : this.state.KanaName,
    }
    SpecificInsureXmlOutputAction.indexMemberList(param)
      .then((res) => {
        this.setState({
          xml02010W1_4: res,
          selectAll: true,
          MemberListFlag: true
        })
        this.formRef.current?.setFieldsValue({
          xml02010W1_4: res,
          selectAll: true
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isloading: false }))
  }

  xmlCreate(output) {
    this.setState({ isloading: true });
    let param = {
      Goutput: output.Lio_Output,
      Type: this.state.Type,
      Gagency: this.state.Gagency,
      GinsuranceNum: this.state.GinsuranceNum,
      StsConfirm: output.Lo_StsOutput,
      SettlementInfo: this.state.SettlementInfo,
      FilingDate: output.Lio_FilingDate,
      NumTransmissions: output.Lio_NumTransmissions,
      ProcessDivision1XmlCheck2Create: output.Lio_ProcessDivision,
      GimplementDateFromChars: this.state.GimplementDateFromChars,
      GimplementDateToChars: this.state.GimplementDateToChars,
      Price_CalculatedAmountTotalNum: 0,
      GoutputForm: output.GoutputForm,
      Gfirst: this.state.Gfirst,
      GfinalEvaluation: this.state.GfinalEvaluation,
      Ginterruption: this.state.Ginterruption,
      GcontinuedSupport: this.state.GcontinuedSupport,
      GmidTermEvaluation: this.state.GmidTermEvaluation,
      ContinuationEndDate1Final2Continued: output.Li_ContinuationEndDate
    }
    SpecificInsureXmlOutputAction.xmlCreate(param)
      .then((res) => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 500,
            component: (
              <WS2656103_ResultConfirm
                Goutput={res.Goutput}
                fileName={res.fileName}
                RootFolderName={res.RootFolderName}
                ErrorPeopleNum={res.ErrorPeopleNum}
                CheckErrorPeopleNum={res.CheckErrorPeopleNum}
                Visits_UserTotal={res.Visits_UserTotal}
                onFinishScreen={(output) => {
                  this.forceUpdate()
                  this.closeModal()
                }}
              />),
          },
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isloading: false }))
  }

  onFinish(values) {

  }

  onChangeGimplementDateFromChars(event) {
    if (event === null) {
      this.setState({
        GimplementDateFromChars: '',
        ImplementDateFrom: ''
      })
      this.formRef.current?.setFieldsValue({
        GimplementDateFromChars: null
      })
    } else {
      this.setState({
        GimplementDateFromChars: event.format(dateFormat),
        ImplementDateFrom: event.format(dateFormat)
      })
      this.formRef.current?.setFieldsValue({
        GimplementDateFromChars: event.format(dateFormat)
      })
    }
  }

  onChangeGimplementDateToChars(event) {
    if (event === null) {
      this.setState({
        GimplementDateToChars: '',
        ImplementDateTo: ''
      })
      this.formRef.current?.setFieldsValue({
        GimplementDateToChars: null
      })
    } else {
      this.setState({
        GimplementDateToChars: event.format(dateFormat),
        ImplementDateTo: event.format(dateFormat)
      })
      this.formRef.current?.setFieldsValue({
        GimplementDateToChars: event.format(dateFormat)
      })
    }
  }

  onChangeGfirst(event) {
    let checked = event.target.checked;
    this.setState({
      Gfirst: checked
    })
    this.formRef.current?.setFieldsValue({
      Gfirst: checked
    })
  }

  onChangeGfinal(event) {
    let checked = event.target.checked;
    this.setState({
      GfinalEvaluation: checked
    })
    this.formRef.current?.setFieldsValue({
      GfinalEvaluation: checked
    })
  }

  onChangeGinterruption(event) {
    let checked = event.target.checked;
    this.setState({
      Ginterruption: checked
    })
    this.formRef.current?.setFieldsValue({
      Ginterruption: checked
    })
  }

  onChangeGcontinuedSupport(event) {
    let checked = event.target.checked;
    this.setState({
      GcontinuedSupport: checked
    })
    this.formRef.current?.setFieldsValue({
      GcontinuedSupport: checked
    })
  }

  onChangeGmidTermEvaluation(event) {
    let checked = event.target.checked;
    this.setState({
      GmidTermEvaluation: checked
    })
    this.formRef.current?.setFieldsValue({
      GmidTermEvaluation: checked
    })
  }

  onChangeSettlementInfo(event) {
    let checked = event.target.checked;
    this.setState({
      SettlementInfo: checked
    })
    this.formRef.current?.setFieldsValue({
      SettlementInfo: checked
    })
  }

  updateCheckbox(event, record) {
    let checked = event.target.checked;
    let id = record.id;
    let xml02010W1_4 = '';
    let data = [...this.state.xml02010W1_4];
    let selectall = false;
    for (var i = 0; i < data.length; i++) {
      if (id === data[i].id) {
        data[i].W1_output_flg = checked ? 1 : 0;
        xml02010W1_4 = data[i].id;
      }
      if (data[i].W1_output_flg) {
        selectall = true;
      }
    }
    let param = {
      Li_StsSelect: checked ? 1 : 0,
      id: xml02010W1_4
    }
    SpecificInsureXmlOutputAction.updateCheckbox(param)
      .then((res) => {
        this.setState({
          xml02010W1_4: data,
          selectAll: selectall
        })
        this.formRef.current?.setFieldsValue({ selectAll: selectall });
      })
  }

  changeStsSelectAllMemberList(event) {
    let checked = event.target.checked;
    let data = [...this.state.xml02010W1_4];
    for (let i = 0; i < data.length; i++) {
      data[i].W1_output_flg = checked ? 1 : 0;
    }
    let param = {
      Li_StsSelect: checked ? 1 : 0
    }
    SpecificInsureXmlOutputAction.changeStsSelectAllMemberList(param)
      .then((res) => {
        this.setState({
          selectAll: checked,
          xml02010W1_4: data
        })
        this.formRef.current?.setFieldsValue({ xml02010W1_4: data });
      })
  }

  onChangeShow(event) {
    let bool = event.target.checked ? 1 : 0
    this.setState({
      StsMore: bool
    })
    this.formRef.current?.setFieldsValue({ StsMore: bool });
    if (this.state.MemberListFlag) {
      if (this.state.Gagency === '' && this.state.Type === 1) {
        let bool = true;
        this.AgencyInquirySub(bool);
        return;
      } else if (this.state.GinsuranceNum === 0 && this.state.Type === 6) {
        let bool = true;
        this.InsurerNumberInquiry(bool);
        return;
      }
      const GimplementDateFromChars = this.formRef.current.getFieldValue("GimplementDateFromChars");
      const GimplementDateToChars = this.formRef.current.getFieldValue("GimplementDateToChars");
      if (!GimplementDateFromChars || !GimplementDateToChars) {
        message.error('実施日を入力してください')
        return
      }
      this.indexMemberList(bool);
    }
  }

  changeName(event) {
    let name = event.target.value;
    this.setState({
      KanaName: name
    })
    this.formRef.current?.setFieldsValue({ KanaName: name });
    if (this.state.MemberListFlag) {
      if (this.state.Gagency === '' && this.state.Type === 1) {
        let bool = true;
        this.AgencyInquirySub(bool);
        return;
      } else if (this.state.GinsuranceNum === 0 && this.state.Type === 6) {
        let bool = true;
        this.InsurerNumberInquiry(bool);
        return;
      }
      const GimplementDateFromChars = this.formRef.current.getFieldValue("GimplementDateFromChars");
      const GimplementDateToChars = this.formRef.current.getFieldValue("GimplementDateToChars");
      if (!GimplementDateFromChars || !GimplementDateToChars) {
        message.error('実施日を入力してください')
        return
      }
      this.indexMemberList(undefined, name);
    }

  }

  changeRadio(event) {
    let gtype = event.target.value;
    if (gtype === 6 || gtype === 9) {
      this.setState({
        AgencyName: ''
      })
    }
    this.setState({
      Type: gtype,
      Gagency: '',
      GinsuranceNum: 0,
      insurer_kanji_name: ''
    })
    this.formRef.current?.setFieldsValue({
      ...this.formRef.current?.getFieldsValue,
      Type: gtype,
      Gagency: '',
      GinsuranceNum: '',
      insurer_kanji_name: ''
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  ConditionAddBtn() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS2656088_DetailsExtract
            Lio_ExamPersonGroupHealthDivisi={this.formRef.current?.getFieldValue("ExamPersonGroupHealthDivision")}
            Lio_Relationship={this.formRef.current?.getFieldValue("Relationship")}
            Lio_CreateDivision1New2ReCreate={this.formRef.current?.getFieldValue("CreateDivision1New2ReCreate")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                ExamPersonGroupHealthDivision: output.Lio_ExamPersonGroupHealthDivisi,
                Relationship: output.Lio_Relationship,
                CreateDivision1New2ReCreate: output.Lio_CreateDivision1New2ReCreate
              })
              this.setState({
                ExamPersonGroupHealthDivision: output.Lio_ExamPersonGroupHealthDivisi,
                Relationship: output.Lio_Relationship,
                CreateDivision1New2ReCreate: output.Lio_CreateDivision1New2ReCreate
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />),
      },
    })
  }

  insurerNumberInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS1290001_InsurerNumberInquiry
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                GinsuranceNum: output.Lo_InsurerNum
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />),
      },
    })
  }

  AgencyInquirySub(bool) {
    if (bool) {
      message.error("代行機関を入力して下さい");
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS1302001_AgencyInquirySub
            Lio_AgencyNum={this.formRef.current?.getFieldValue("Gagency")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                Gagency: output.Gl0AgencyNum
              })
              this.setState({
                Gagency: output.Gl0AgencyNum,
                AgencyName: output.recordData.Gl0AgencyName
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />),
      },
    })
  }

  changeGagency(event) {
    let param = {
      Gagency: event.target.value
    }
    SpecificInsureXmlOutputAction.changeGagency(param)
      .then((res) => {
        this.setState({
          Gagency: event.target.value,
          AgencyName: res.AgencyName
        })
      })
  }

  InsurerNumberInquiry(bool) {
    if (bool) {
      message.error("保険者番号を入力して下さい");
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS1290001_InsurerNumberInquiry
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                GinsuranceNum: output.Lo_InsurerNum
              })
              this.setState({
                GinsuranceNum: output.Lo_InsurerNum,
                insurer_kanji_name: output.Lo_InsurerKanjiName
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />),
      },
    })
  }

  getNameGinsuranceNum(event) {
    let param = {
      GinsuranceNum: event.target.value
    }
    SpecificInsureXmlOutputAction.getNameGinsuranceNum(param)
      .then((res) => {
        this.setState({
          GinsuranceNum: event.target.value,
          insurer_kanji_name: res.insurer_kanji_name
        })
      })
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onSelect: (record, selected, selectedRows, nativeEvent) => {

      },
      onSelectAll: (selected, selectedRows, changeRows) => {

      }
    }
    var today = new Date().getFullYear() + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + ("0" + new Date().getDate()).slice(-2)
    return (
      <div className="specific-insure-xml-output">
        <Card
          style={{ position: 'relative' }}
          title='保健指導ＸＭＬデータ出力'
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
            initialValues={{ GimplementDateFromChars: moment(today), GimplementDateToChars: moment(today) }}
          >
            <div className='box_inner_horizontal'>
              <div className='box_search'>
                <div className='box_search_inner_border'>
                  <Space style={{ paddingRight: '10px' }}>
                    <Form.Item name="GimplementDateFromChars" label="&emsp;実施日" {...smGrid}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format={dateFormat} onChange={(event) => this.onChangeGimplementDateFromChars(event)} />
                    </Form.Item>
                    <Form.Item>~</Form.Item>
                    <Form.Item name="GimplementDateToChars" >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} format={dateFormat} onChange={(event) => this.onChangeGimplementDateToChars(event)} />
                    </Form.Item>
                  </Space>
                  <Form.Item name="Type" label="&emsp;種別" {...smGrid}>
                    <Radio.Group onChange={(event) => this.changeRadio(event)} >
                      <Radio value={1}>代行機関</Radio>
                      <Radio value={6}>保 険 者</Radio>
                      <Radio value={9}>そ の 他</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Space>
                    <Form.Item name="Gagency" label="代行機関" {...smGrid}>
                      <Input.Search
                        className="input-search-size-number-10"
                        onBlur={(event) => { this.changeGagency(event) }}
                        onSearch={() => { this.AgencyInquirySub() }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div style={{ marginTop: '5px' }}>{this.state.AgencyName}</div>
                    </Form.Item>
                  </Space>
                  <Space>
                    <Form.Item name="GinsuranceNum" label="&emsp;保険者" {...smGrid}>
                      <Input.Search
                        onBlur={(event) => { this.getNameGinsuranceNum(event) }}
                        onSearch={() => { this.InsurerNumberInquiry() }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <span>{this.state.insurer_kanji_name}</span>
                    </Form.Item>
                  </Space>
                </div>
                <div className='box_search_inner'>
                  <Form.Item name="SettlementInfo" label="&emsp;決済" valuePropName="checked" {...smGrid2}>
                    <Checkbox onChange={(event) => this.onChangeSettlementInfo(event)} ></Checkbox>
                  </Form.Item>
                  <Form.Item label="&emsp;評価" style={{ marginBottom: '0px' }} {...smGrid2}>
                    <div className="box_inner_horizontal">
                      <Form.Item name="Gfirst" valuePropName="checked">
                        <Checkbox onChange={(event) => this.onChangeGfirst(event)}>初回面談</Checkbox>
                      </Form.Item>
                      <Form.Item name="GfinalEvaluation" valuePropName="checked">
                        <Checkbox onChange={(event) => this.onChangeGfinal(event)} >最終評価</Checkbox>
                      </Form.Item>
                      <Form.Item name="Ginterruption" valuePropName="checked">
                        <Checkbox onChange={(event) => this.onChangeGinterruption(event)} >中　　断</Checkbox>
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Form.Item label="&emsp;&emsp;&emsp;&emsp;">
                    <div className="box_inner_horizontal">
                      <Form.Item name="GcontinuedSupport" valuePropName="checked">
                        <Checkbox
                          style={{ width: '150px' }}
                          onChange={(event) => this.onChangeGcontinuedSupport(event)} disabled={this.state.SettlementInfo}
                        >
                          継続支援
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="GmidTermEvaluation" valuePropName="checked">
                        <Checkbox
                          style={{ width: '150px' }}
                          onChange={(event) => this.onChangeGmidTermEvaluation(event)} disabled={this.state.SettlementInfo}
                        >
                          中間評価
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>
              </div>
              <div className='box_search_btn'>
                <div className='box_search_btn_inner'>
                  <Button icon={<SearchOutlined />} onClick={() => this.SearchBtn()} >検　　索</Button>
                  <Tooltip title='詳細な条件追加'>
                    <Button
                      size='small'
                      style={{ borderRadius: '12px' }}
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => this.ConditionAddBtn()}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div
              className="table_header_filter"
              style={{ marginTop: '10px' }}
            >
              <Form.Item name="KanaName" label={<label style={{ color: 'white' }}>カナ氏名：</label>} style={{ marginBottom: '0px' }}>
                <Input size="small" onBlur={e => this.changeName(e)} />
              </Form.Item>
              <Form.Item name="StsMore" style={{ marginBottom: '0px' }} label={<label style={{ color: 'white' }}>実施日のみで抽出：</label>}>
                <Checkbox onChange={(event) => this.onChangeShow(event)} />
              </Form.Item>
            </div>
            <Table
              bordered
              dataSource={this.state.xml02010W1_4}
              size="small"
              pagination={this.state.xml02010W1_4.length > process.env.REACT_APP_PAGE_SIZE ? this.state.Paginate : false}
              scroll={{ x: 'max-content', y: resizableTableScroll(70) }}
              rowKey={(record) => record.id}
            >

              <Table.Column
                align="center"
                className="column-size-40px"
                title={(row, record, index) => {
                  return (
                    <Form.Item name='selectAll' valuePropName="checked" style={{ margin: 0 }}>
                      <Checkbox onChange={(event) => { this.changeStsSelectAllMemberList(event) }} />
                    </Form.Item>
                  )
                }}
                dataIndex="W1_output_flg"
                render={(row, record, index) => {
                  return (
                    <Form.Item name={["xml02010W1_4", index, 'W1_output_flg']} valuePropName="checked" style={{ margin: 0 }}>
                      {record.W1_output_already_flg === 1 ?
                        <Checkbox onChange={(event) => { this.updateCheckbox(event, record) }} />
                        : null}
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title="区分"
                dataIndex="ReCreateNewAnd"
                className="column-size-10"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                title="実施日"
                dataIndex="W1_date_of"
                className="column-size-10"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                title="評価区分"
                dataIndex="Expression_9"
                className="column-size-10"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                title="個人番号"
                dataIndex="W1_id"
                className="column-size-10"
                // align="right"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity, textAlign: 'right' }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                title="氏名"
                dataIndex="kanji_name"
                //className="column-size-40"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                title="保険者番号"
                dataIndex="W1_insurers"
                className="column-size-10"
                // align="right"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity, textAlign: 'right' }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                title="代行機関"
                dataIndex="other_agency"
                className="column-size-10"
                // align="right"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity, textAlign: 'right' }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                align="center"
                title="利用券"
                dataIndex="Expression_19"
                className="column-size-5"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity }}>{row}</div>
                  )
                }}
              />
              <Table.Column
                align="center"
                title="決済"
                dataIndex="Expression_21"
                className="column-size-5"
                render={(row, record, index) => {
                  return (
                    <div style={{ opacity: record.W1_output_already_flg === 1 ? '' : opacity }}>{row}</div>
                  )
                }}
              />
            </Table>
            <div className='box_button_bottom_right'>
              <Button type="primary" onClick={() => {
                if (!this.state.xml02010W1_4.length) {
                  message.error("対象者を検索してください");
                  return;
                }
                if (!this.state.selectAll) {
                  message.error("対象者を選択してください");
                  return;
                }
                this.setState({
                  childModal: {
                    visible: true,
                    width: 600,
                    component: (
                      <WS2656096_OutputConfirm
                        onFinishScreen={output => {
                          this.closeModal();
                          this.xmlCreate(output);
                        }}
                      />
                    )
                  }
                });
              }}>
                <span className='btn_label'>
                  ＸＭＬ
                </span>
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2656001_SpecificInsureXmlOutput);
