import MasterListOutputContractInfoService from 'services/basicInfo/ContractInfoMaintain/MasterListOutputContractInfoService';
import { message, } from "antd"

const MasterListOutputContractInfoAction = {
  OutputF12(params) {
    return MasterListOutputContractInfoService.OutputF12(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
export default MasterListOutputContractInfoAction;