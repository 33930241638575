import axios from "configs/axios";

const apiPaths = {
  getScreenData: "/api/receipt-pre-issue20/receipt-issue-online/get-screen-data",
  preview: "/api/receipt-pre-issue20/receipt-issue-online/preview",
  print: "/api/receipt-pre-issue20/receipt-issue-online/print",
};

const ReceiptIssueOnlineService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },

  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' });
  },

  async print(params) {
    return axios.post(apiPaths.print, params);
  },
};

export default ReceiptIssueOnlineService;
