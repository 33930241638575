import React from "react";
import ComprehensiveMenuMaintenanceAction from "redux/MN_Basis/ComprehensiveMenuMaintenance/ComprehensiveMenuMaintenance.action";
import { EditOutlined, PlusOutlined, CheckOutlined, MoreOutlined } from '@ant-design/icons';
import { Card, Form, Table, Button, Input, message, Row, Col, Checkbox, Tooltip, Modal, Spin, Dropdown, Menu } from "antd";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import WS2690501_ComprehensiveMenuMaintenanceSub from './WS2690501_ComprehensiveMenuMaintenanceSub'


const authorityList = [
  { name: 'UsageRightsA' },
  { name: 'UseAuthorityB' },
  { name: 'UseAuthorityC' },
  { name: 'UsageRightsD' },
  { name: 'UsageRightsE' },
  { name: 'UseAuthorityF' },
  { name: 'UsageRightsG' },
  { name: 'UsageRightsH' },
  { name: 'UseAuthorityI' },
]
class WS2690001_ComprehensiveMenuMaintenance extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '';

    const initOption = {
      tableData: [],
      UsageRightsA: false,
      UseAuthorityB: false,
      UseAuthorityC: false,
      UsageRightsD: false,
      UsageRightsE: false,
      UseAuthorityF: false,
      UsageRightsG: false,
      UsageRightsH: false,
      UseAuthorityI: false,
      Option: '',
      published_name: '',
      icon: ''
    }
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      businessNameList: [],
      managementList: [],
      selectedBusinessCode: '',
      selectedId: '',
      isLoadingTable: false,
      selectedRowIndex: 0,
      businessCode: [],
      selectedOption: initOption,
      newFlag: false
    };
  }
  componentDidMount() {
    this.getScreenData();
  }
  getScreenData(businessCode = null) {
    this.setState({ isLoadingTable: true })
    let index = 0
    ComprehensiveMenuMaintenanceAction.getScreenData()
      .then((res) => {
        if (res.length === 0) {
          this.setState({
            businessNameList: [],
            selectedBusinessCode: 0,
            managementList: []
          })
          this.selectBusinessCode()
        } else if (businessCode !== null || res.length !== 0) {
          for (let i = 0; i < res.length; i++) {
            if (businessCode === res[i].business_code) {
              index = i;
              break;
            }
          }
          this.setState({
            businessNameList: res ? res : [],
            selectedBusinessCode: res[index].business_code,
          })
          this.selectBusinessCode(res[index].business_code)
          this.forceUpdate();
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  selectBusinessCode(businessCode) {
    this.setState({ isLoadingTable: true })
    let param = {
      Li_BusinessCode: businessCode
    }
    ComprehensiveMenuMaintenanceAction.selectBusinessCode(param)
      .then((res) => {
        if (res.length === 0) {
          const optionObj = {
            tableData: res ? res : [],
            UsageRightsA: false,
            UseAuthorityB: false,
            UseAuthorityC: false,
            UsageRightsD: false,
            UsageRightsE: false,
            UseAuthorityF: false,
            UsageRightsG: false,
            UsageRightsH: false,
            UseAuthorityI: false,
            Option: '',
            published_name: '',
            icon: ''
          }
          this.setState({
            managementList: [],
            selectedId: 0,
            selectedRowIndex: 0,
            selectedOption: optionObj
          })
          return
        } else {
          const optionObj = {
            tableData: res ? res : [],
            UsageRightsA: res[0].UsageRightsA ?? false,
            UseAuthorityB: res[0].UseAuthorityB ?? false,
            UseAuthorityC: res[0].UseAuthorityC ?? false,
            UsageRightsD: res[0].UsageRightsD ?? false,
            UsageRightsE: res[0].UsageRightsE ?? false,
            UseAuthorityF: res[0].UseAuthorityF ?? false,
            UsageRightsG: res[0].UsageRightsG ?? false,
            UsageRightsH: res[0].UsageRightsH ?? false,
            UseAuthorityI: res[0].UseAuthorityI ?? false,
            Option: res[0].Option ?? '',
            published_name: res[0].published_name ?? '',
            icon: res[0].icon ?? ''
          }
          this.setState({
            managementList: res ? res : [],
            selectedId: res[0].id,
            selectedRowIndex: 0,
            selectedOption: optionObj
          })
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingTable: false }))
  }

  selectIndex(id) {
    if (id === "") return
    let data = this.state.managementList;
    let obj;
    for (let i = 0; i < data.length; i++) {
      if (id === data[i].id) {
        obj = data[i];
        break;
      }
    }
    const optionObj = {
      UsageRightsA: obj.UsageRightsA ? true : false,
      UseAuthorityB: obj.UseAuthorityB ? true : false,
      UseAuthorityC: obj.UseAuthorityC ? true : false,
      UsageRightsD: obj.UsageRightsD ? true : false,
      UsageRightsE: obj.UsageRightsE ? true : false,
      UseAuthorityF: obj.UseAuthorityF ? true : false,
      UsageRightsG: obj.UsageRightsG ? true : false,
      UsageRightsH: obj.UsageRightsH ? true : false,
      UseAuthorityI: obj.UseAuthorityI ? true : false,
      Option: obj.Option ?? '',
      published_name: obj.published_name ?? '',
      icon: obj.icon ?? ''
    }
    this.setState({
      selectedOption: optionObj
    })
  }

  deleteData(business_code, id) {
    let param = {
      Li_BusinessCode: business_code,
      Li_id: id,
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        ComprehensiveMenuMaintenanceAction.deleteData(param)
          .then((res) => {
            this.getScreenData()
            message.success('正常に削除されました');
          }).catch((err) => {
            message.error(err.response.data.message);
          })
      }
    });
  }

  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className='comprehensive-menu-maintenance' >
        <Card title='メニューカスタマイズ'>
          <div className="box_inner_horizontal">
            <Table bordered
              style={{ cursor: 'pointer', width: 300 }}
              id='table1'
              rowKey={(record) => record.id}
              size="small"
              rowClassName={(record, index) => record.business_code === this.state.selectedBusinessCode ? 'table-row-light' : ''}
              dataSource={this.state.businessNameList}
              // loading={this.state.isLoadingTable}

              pagination={false}
              // className="mb-3"
              scroll={{ y: resizableTableScroll(30, 'table1') }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: async (e) => {
                    if (e.target.nodeName === 'TD') {
                      let businessCode = record.business_code
                      this.selectBusinessCode(businessCode)
                      await this.setState({
                        selectedBusinessCode: record.business_code,
                      });
                    }
                  }
                }
              }}
            >
              <Table.Column title="無効" dataIndex="StsDisabled" align='center' width={40}
                render={(value, record, index) => {
                  return (
                    this.checkJudgment(value)
                  )
                }}
              />
              <Table.Column title="業務名" dataIndex="W1_mn0900_business_name" />
              <Table.Column
                width={40}
                fixed={'right'}
                title={(<div style={{ textAlign: "center" }}>
                  <Tooltip placement="topLeft" title={'追加'}>
                    {/* 追加ボタン */}
                    <Button
                      size='small'
                      onClick={() => {
                        let newFlag = true
                        this.setState({
                          newFlag: true,
                          selectedBusinessCode: undefined,
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 900,
                            component: (
                              <WS2690501_ComprehensiveMenuMaintenanceSub
                                selectedBusinessCode={undefined}
                                businessNameList={this.state.businessNameList}
                                getScreenData={(event) => {
                                  this.getScreenData(event);
                                }}
                                // test={this.getScreenData()}
                                onFinishScreen={() => {
                                  this.closeModal();
                                }}
                                newFlag={newFlag}
                              />
                            ),
                          }
                        })
                      }}
                      type="primary" icon={<PlusOutlined />}>
                    </Button>
                  </Tooltip>
                </div>)
                }
                render={(row, record, index) => (
                  <Dropdown
                    trigger="click"
                    size="small"
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key="1"
                          onClick={() => {
                            let newFlag = false
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1050,
                                component: (
                                  <WS2690501_ComprehensiveMenuMaintenanceSub
                                    selectedBusinessCode={record.business_code}
                                    businessNameList={this.state.businessNameList}
                                    getScreenData={(event) => {
                                      this.getScreenData(event);
                                    }}
                                    // test={this.getScreenData()}
                                    onFinishScreen={() => {
                                      this.closeModal();
                                    }}
                                    newFlag={newFlag}
                                  />
                                ),
                              },
                            });
                          }}
                        >
                          編集
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={() => {
                            this.deleteData(record.business_code, record.id)
                          }}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button size="small" icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )}
              />
            </Table>
            <div className="box_inner_vertical" style={{ width: 'calc(100% - 300px)' }}>
              <Table bordered
                style={{ cursor: 'pointer' }}
                id='table2'
                size="small"
                rowKey={(record) => record.id}
                rowClassName={(record, index) => record.id === this.state.selectedId ? 'table-row-light' : ''}
                dataSource={this.state.managementList}
                // loading={this.state.isLoadingTable}

                pagination={false}
                // className="name"
                scroll={{ y: resizableTableScroll(150, 'table2') }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: async (event) => {
                      let id = record.id
                      if (record.id === "") {
                        return
                      } else {
                        this.selectIndex(id)
                        await this.setState({
                          selectedId: record.id,
                          selectedRowIndex: rowIndex
                        })
                      }
                    }
                  }
                }}
              >
                <Table.Column
                  title="無効"
                  dataIndex="invalid"
                  align='center' width={40}
                  render={(row, record, index, value) => {
                    return (
                      this.checkJudgment(row)
                    )
                  }}
                />
                <Table.Column
                  title="連番"
                  dataIndex="DisplaySerialNum"
                  width={120}
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )
                  }
                  }
                ></Table.Column>
                <Table.Column
                  title="名称"
                  dataIndex="name"
                  width={240}
                />
                <Table.Column
                  title="コマンド"
                  dataIndex="Exe"
                  width={360}
                />
                <Table.Column title="権限" dataIndex="usage_rights" width={60} />
              </Table>
              <div style={{ border: '1px solid #cccccc', padding: '5px', marginTop: '5px' }} >
                <div className="box_inner_horizontal" >
                  <div className="box_inner_vertical">
                    <div style={{ display: "flex", paddingLeft: '37px' }}>
                      {authorityList.map((val, index) => {
                        return (
                          <Form.Item key={val.name} label={(index === 0) ? '権限' : ''}
                            style={{ paddingLeft: '5px' }}>
                            <div className="box_inner_vertical" style={{ gap: 0 }}>
                              <div style={{ margin: 'auto', color: '#777', height: 17 }}>{val.name.slice(-1)}</div>
                              <Checkbox checked={this.state.selectedOption[val.name]}></Checkbox>
                            </div>
                          </Form.Item>
                        )
                      })}
                    </div>
                    <Form.Item label="&emsp;&emsp;アイコン">
                      {/* <Input maxLength={30} value={this.state.selectedOption.icon} /> */}
                      <div>{this.state.selectedOption.icon}</div>
                    </Form.Item>
                  </div>
                  <div className="box_inner_vertical">
                    <Form.Item label="&emsp;&emsp;&emsp;公開名">
                      {/* <Input maxLength={220} value={this.state.selectedOption.published_name} /> */}
                      <div>{this.state.selectedOption.published_name}</div>
                    </Form.Item>
                    <Form.Item label="&emsp;オプション" >
                      {/* <Input maxLength={50} value={this.state.selectedOption.Option} /> */}
                      <div style={{ width: '50%' }}>{this.state.selectedOption.Option}</div>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            footer={null}
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            bodyStyle={{ margin: 0, padding: 0 }}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => {
              this.closeModal();
            }}
          >
            {/* 連続で保存をしようとしたときにselectedBusinessCodeが変わっていないため */}
            {this.state.childModal.component}
          </Modal>
        </Card>
      </div >
    );
  }
}
export default WS2690001_ComprehensiveMenuMaintenance;
