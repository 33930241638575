import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, Form, Row, Col, Button, message } from "antd";
import man_img from 'assets/img/性別-男性.png';
import woman_img from 'assets/img/性別-女性.png';
import ImplementRecordRefService from "services/SpecificInsureGuide/InsureGuideInput/ImplementRecordRefService";
import WS2653001_GuideInputProvenSub from 'pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653001_GuideInputProvenSub.jsx';
import WS1444001_DunningInquiry from 'pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444001_DunningInquiry.jsx';
import WS1423001_SupportPlanManualCreate from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1423001_SupportPlanManualCreate.jsx";

const smGrid = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 70px)' } }
}

const formStyle = {
  marginBottom: 0
}
class WS1436001_ImplementRecordRef extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-THK00093:実施状況[実績照会]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
      pagination: {
        defaultPageSize: 10,
        size: 1,
        showQuickJumper: true
      },
      DisplayList: [],
      DunningDisplayList: [],
      rowSelect: {},
      dunningRowSelect: {},
    };
  }

  componentDidMount = () => {
    this.getScreenData();
    this.getTableData();
    this.dunningInquiry();
  }

  getScreenData = () => {
    this.setState({ isLoading: true, rowSelect: {} });

    ImplementRecordRefService.getScreenData(
      {
        Li_Date: this.props.Li_Date,
        Li_Id: this.props.Li_PersonalNum,
      }
    )
      .then((res) => {
        let birthday_on = res.data.birthday_on
        if (birthday_on !== '') {
          res.data.birthday_on = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { year: 'numeric', month: '2-digit', day: 'numeric' }).format(new Date(birthday_on))
        }
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
        this.forceUpdate();
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  getTableData = () => {
    this.setState({ isLoading: true, rowSelect: {} });

    ImplementRecordRefService.getImplementRecordRef(
      {
        Li_Date: this.props.Li_Date,
        Li_Id: this.props.Li_PersonalNum,
      }
    )
      .then((res) => {
        if (res.data[0]) {
          // Set first row as selected yellow
          this.setState({ DisplayList: res.data });
          this.setState({
            rowSelect: res.data[0],
          });
        }
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  dunningInquiry = () => {
    this.setState({ isLoading: true, dunningRowSelect: {} });

    ImplementRecordRefService.dunningInquiry(
      {
        Li_Date: this.props.Li_Date,
        Li_Id: this.props.Li_PersonalNum,
      }
    )
      .then((res) => {
        if (res.data[0]) {
          // Set first row as selected yellow
          this.setState({ DunningDisplayList: res.data });
          this.setState({
            dunningRowSelect: res.data[0],
          });
        }
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onFinish(values) {
  }

  render() {
    return (
      <div className="implement-record-ref">
        <Card className="mb-2" title="実施状況">
          <div
            className="mb-3"
          >
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <div className="box_inner_horizontal">
                <div className="box_border">
                  <Form.Item
                    label="カナ氏名"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('kana_name')}
                  </Form.Item>
                  <Form.Item
                    label="漢字氏名"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('kanji_name')}
                  </Form.Item>
                  <div className="box_inner_horizontal" style={{ position: 'relative' }}>
                    <Form.Item
                      label="生年月日"
                      style={formStyle}
                      {...smGrid}
                    >
                      {this.formRef.current?.getFieldValue('birthday_on')}
                    </Form.Item>
                    <Form.Item
                      label="年齢"
                      style={formStyle}
                    >
                      {this.formRef.current?.getFieldValue('Expresstion_18') + '歳'}
                    </Form.Item>
                    <img
                      style={{ bottom: '5px', right: 5, position: 'absolute' }}
                      src={this.formRef.current?.getFieldValue("Expresstion_27") ? this.formRef.current?.getFieldValue("Expresstion_27").includes('男性.png') ? man_img : woman_img : ""}
                      width={40}
                    />
                  </div>
                </div>
                <div className="box_border">
                  <Form.Item
                    label="保険者番号"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('insurer_number')}
                  </Form.Item>
                  <Form.Item
                    label="保険者名"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('insurer_kanji_name')}
                  </Form.Item>
                  <Form.Item
                    label="保険証"
                    style={formStyle}
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('Expresstion_28')}
                  </Form.Item>
                </div>
                <div className="box_border">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical">
                      <Form.Item
                        label=""
                      >
                        {this.formRef.current?.getFieldValue('Expresstion_19')}
                      </Form.Item>
                      <Form.Item
                        label=""
                        style={formStyle}
                      >
                        {this.formRef.current?.getFieldValue('course_name')}
                      </Form.Item>
                    </div>
                    <div className="box_inner_vertical">
                      <Form.Item
                        label="支援A"
                        {...smGrid}
                      >
                        {this.formRef.current?.getFieldValue('Expresstion_24')}
                      </Form.Item>
                      <Form.Item
                        label="支援B"
                        {...smGrid}
                      >
                        {this.formRef.current?.getFieldValue('Expresstion_25')}
                      </Form.Item>
                      <Form.Item
                        label="合計"
                        style={formStyle}
                        {...smGrid}
                      >
                        {this.formRef.current?.getFieldValue('Expresstion_23')}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>

          <Table
            size="small"
            bordered
            style={{ marginBottom: '20px' }}
            rowKey={(record) => record.id}
            // rowClassName={(record, index) => record.id === this.state.rowSelect.id ? 'table-row-light' : ''}
            scroll={{ y: '150px' }}
            onRow={(record, index) => ({
              onClick: (event) => {
                this.setState({ rowSelect: record })
              },
              onDoubleClick: () => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: 1050,
                    visible: true,
                    component: (
                      <WS2653001_GuideInputProvenSub
                        Li_Date={record.Li_Date}
                        Li_PersonalNum={record.Li_Id}
                        Li_SerialNum={record.serial_number}
                        onFinishScreen={() => {
                          this.closeModal();
                        }}
                      />
                    ),
                  },
                });
              },
            })}
            dataSource={
              this.state.DisplayList
            }
            pagination={false}
          >
            <Table.Column title="連番" dataIndex="serial_number" key="" width={40} align='right' />
            <Table.Column title="予定日" dataIndex="implement_date_on" key="" width={100} />
            <Table.Column title="実施日" dataIndex="implement_date" key="" width={100} />
            <Table.Column title="完了日" dataIndex="completion_date_on" key="" width={100} />
            <Table.Column title="評価区分" dataIndex="EvaluationCategoryName" key="" width={120} />
            <Table.Column title="支 援 項 目" dataIndex="" key="" width={240}
              render={(value, record, index) => {
                return (
                  <Row>
                    <Col span={16}>
                      {record.support}
                    </Col>
                    <Col span={8}>
                      {record.Expresstion_20}
                    </Col>
                  </Row>
                )
              }}
            />
            <Table.Column title="支援量" dataIndex="" key="" width={80}
              render={(value, record, index) => {
                return (
                  <Row>
                    <Col span={12}>
                      {record.SupportAmount}
                    </Col>
                    <Col span={12}>
                      {record.Expresstion_21}
                    </Col>
                  </Row>
                )
              }}
            />
            <Table.Column title="ポイント" dataIndex="Point" key="" width={80} align='right' />
            <Table.Column title="実施者" dataIndex="kanji_name" key="" width={120} />
          </Table>

          <Table
            bordered
            size="small"
            rowKey={(record) => record.healthguidedunningdata.id}
            // rowClassName={(record, index) => record.dunning_implement_date_on === this.state.rowSelect.dunning_implement_date_on ? 'hightlight-row-selected' : ''}
            onRow={(record, index) => ({
              onClick: (event) => this.setState({ dunningRowSelect: record }),
              onDoubleClick: () => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: "30%",
                    visible: true,
                    component: (
                      <WS1444001_DunningInquiry
                        Li_Date={this.props.Li_Date}
                        Li_PersonalNum={this.props.Li_PersonalNum}
                        onFinishScreen={() => {
                          this.dunningInquiry();
                        }}
                      />
                    ),
                  },
                });
              }
            })}
            dataSource={
              this.state.DunningDisplayList
            }
            scroll={{ y: '150px' }}
            pagination={false}
          >
            <Table.Column title="督促" dataIndex="DuringDunning" key="" />
            <Table.Column title="督促日" dataIndex="dunning_implement_date_on" key="" />
            <Table.Column title="督 促 項 目" dataIndex="support" key="" />
            <Table.Column title="内　　容" dataIndex="content" key="" />
          </Table>
          <div style={{ marginTop: '1em', textAlign: 'right' }}>
            <Button type="primary"
              onClick={() => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: "70%",
                    visible: true,
                    component: (
                      <WS1423001_SupportPlanManualCreate
                        Li_FirstDate={this.props.Li_Date}
                        Li_Id={this.props.Li_PersonalNum}
                        onFinishScreen={() => {
                          this.closeModal();
                        }}
                      />
                    ),
                  },
                });
              }}
            >計画変更</Button>

            <Button type="primary" style={{ marginLeft: 10 }}
              onClick={() => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: "30%",
                    visible: true,
                    component: (
                      <WS1444001_DunningInquiry
                        Li_Date={this.props.Li_Date}
                        Li_PersonalNum={this.props.Li_PersonalNum}
                        onFinishScreen={() => {
                          this.dunningInquiry();
                        }}
                      />
                    ),
                  },
                });
              }}
            >督促入力
            </Button>

            <Button type="primary" style={{ marginLeft: 10 }}
              onClick={() => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: 1050,
                    visible: true,
                    component: (
                      <WS2653001_GuideInputProvenSub
                        Li_Date={this.props.Li_Date}
                        Li_PersonalNum={this.props.Li_PersonalNum}
                        Li_SerialNum={this.props.Li_SerialNum}
                        onFinishScreen={() => {
                          this.closeModal();
                        }}
                      />
                    ),
                  },
                });
              }}
            >指導入力
            </Button>
          </div>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1436001_ImplementRecordRef);
