import axios from "configs/axios";

const API_LIST = {
  GetScreenData: "/api/spread-input/spread-input/get-screen-data",
  Display: "/api/spread-input/spread-input/display",
  DataUpdate: "/api/spread-input/spread-input/data-update",
  zoomProcess: "/api/spread-input/spread-input/zoom-process",
  Download: "/api/spread-input/spread-input/download",
  listData: "/api/spread-input/spread-input/get-list-data",
  onUpdate: "/api/spread-input/spread-input/on-update",
  dataUpdateConfirm: "/api/spread-input/data-update-confirm/update-yes",
  callScreenGet: "/api/spread-input/spread-input/call-screen-get",
  specifiedValueSub: "/api/spread-input/spread-input/specified-value-sub",
}

const SpreadInputService = {
  async GetScreenData(params) {
    return axios.get(API_LIST.GetScreenData, { params });
  },
  async Display(params) {
    return axios.get(API_LIST.Display, { params });
  },
  async DataUpdate(params) {
    return axios.put(API_LIST.DataUpdate, params);
  },
  async zoomProcess(params) {
    return axios.post(API_LIST.zoomProcess, params);
  },
  async download(params) {
    return axios.post(API_LIST.Download, params, { responseType: 'blob' });
  },
  async listData(params) {
    return axios.post(API_LIST.listData, params);
  },
  async onUpdate(params) {
    return axios.put(API_LIST.onUpdate, params);
  },
  async dataUpdateConfirmService(params) {
    return axios.post(API_LIST.dataUpdateConfirm, params);
  },
  async callScreenGet(params) {
    return axios.get(API_LIST.callScreenGet, { params });
  },
  async specifiedValueSub(params) {
    return axios.post(API_LIST.specifiedValueSub, params);
  },

}

export default SpreadInputService;
