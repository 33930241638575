import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Table,
  Input,
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  Select,
  Space,
  Form,
  message,
  Dropdown,
  Menu,
  List,
  Typography
} from "antd";


import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import WS0495001_ConditionExpressInfoInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0495001_ConditionExpressInfoInquiry.jsx";
import WS3022500_BindingModeSettingEdit from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS3022500_BindingModeSettingEdit.jsx'
import WS3022100_BindingModeSettingDetails from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS3022100_BindingModeSettingDetails'
import BindingModeSettingService from "services/ResultOutput/BindingModeSetting/BindingModeSettingService";
import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MoreOutlined,
  CheckOutlined
} from "@ant-design/icons";
class WS3022038_BindingModeSetting extends React.Component {
  static propTypes = {
    Li_ParentCode: PropTypes.any,
  };
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "結合様式設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTable: false,
      selectedRowKeyLeft: [],
      selectedRowKeyRight: [],
      selectedRowRight: {},
      BindingModeSettingList: [],
      BeforeBindingModeSettingList: [],
      isRebind: false,
    };
  }

  componentDidMount = () => {
    BindingModeSettingService.getScreenDataService()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          PrinterNoList: res.data,
        });
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    this.getDataBindingModeSetting(true);
  };

  getDataBindingModeSetting = (isFirstLoading) => {
    this.setState({ isLoadingTable: true });
    BindingModeSettingService.getDataBindingModeSettingService()
      .then((res) => {
        let isUpdateSelectedRows = res.data.filter((item) => {
          if (
            this.formRef.current.getFieldValue("selectedRows") &&
            this.formRef.current.getFieldValue("selectedRows").id === item.id
          )
            return item;
        });
        this.formRef.current.setFieldsValue({
          BindingModeSettingList: res.data,
          selectedRows:
            isUpdateSelectedRows.length > 0 ? isUpdateSelectedRows[0] : [],
        });
        if (res.data.length > 0) {
          this.setState({
            selectedRowKeyLeft: [res.data[0]?.id],
            selectedRowRight: res.data[0]?.ModeUse[0],
            selectedRowKeyRight: [res.data[0]?.ModeUse[0]?.id],
            BindingModeSettingList: res.data
          });

          if (!this.state.isRebind) {

            this.setState({
              BeforeBindingModeSettingList: JSON.parse(JSON.stringify(res.data))
            });

          }

          this.setState({ isRebind: true });

          this.formRef.current.setFieldsValue({
            PrinterNo: res.data[0].PrinterNo,
            selectedRows: res.data[0],
          });
        }
        this.forceUpdate();
        this.setState({ isLoadingTable: false });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
        this.setState({ isLoadingTable: false });
      });
  };

  getDataModeUseBindingModeSetting = () => {
    BindingModeSettingService.getDataModeUseBindingModeSettingService({
      parent_code: this.state.selectedRows.parent_code,
    })
      .then((res) => {
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  };

  saveAndUpdateBindingModeSetting = (id, editData) => {
    let params = {
      id: id,
      display_order: editData?.display_order,
      parent_code: editData?.parent_code,
      style_name: editData?.style_name,
      enabled: editData?.enabled,
      bulk: editData?.bulk,
    }
    BindingModeSettingService.saveAndUpdateBindingModeSettingService(params)
      .then((res) => {
        message.success("成功");
        this.getDataBindingModeSetting(true);
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
        this.setState({ isLoadingTable: false });
      });
  };

  syncSaveAndUpdateBindingModeSetting = async (id, editData) => {

    let params = {
      id: id,
      display_order: editData?.display_order,
      parent_code: editData?.parent_code,
      style_name: editData?.style_name,
      enabled: editData?.enabled,
      bulk: editData?.bulk,
    }


    return await BindingModeSettingService.saveAndUpdateBindingModeSettingService(params)
      .then((res) => {
        message.success("成功");
        return null
      })
      .catch((err) => {
        const res = err.response;

        message.error(res.data.message);
        return err


      });

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  deleteBindingModeSetting = (id, parent_code) => {
    if (id === "create_new" && parent_code === "") {
      this.getDataBindingModeSetting();
      this.formRef.current.setFieldsValue({
        PrinterNo: "",
        selectedRows: [],
      });
      this.forceUpdate();
      return;
    }
    this.setState({ isLoadingTable: true });
    BindingModeSettingService.deleteBindingModeSettingService({
      id: id,
      parent_code: parent_code,
    })
      .then((res) => {
        message.success("成功");
        this.getDataBindingModeSetting();
        this.formRef.current.setFieldsValue({
          PrinterNo: "",
          selectedRows: [],
        });
        this.setState({ isLoadingTable: false });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
        this.setState({ isLoadingTable: false });
      });
  };
  changeConditionalExpression(e, record, index) {
    const data = {
      conditional_expression: e,
    };
    BindingModeSettingService.changeConditionalExpressionService(data)
      .then((res) => {
        record = {
          ...record,
          name: res.data.name,
          conditional_expression: e,
        };
        const tempObj = [
          ...this.formRef.current.getFieldValue("selectedRows").ModeUse,
        ];
        tempObj[index] = record;
        this.formRef.current.setFieldsValue({
          ...this.formRef.current.getFieldValue(),
          selectedRows: {
            ...this.formRef.current.getFieldValue("selectedRows"),
            ModeUse: tempObj,
          },
        });
      })
      .catch((err) => { });

  }

  /**
   * チェックのアイコンを出す出さないの判定
   * @param {*} value
   * @returns
   */
  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }

  /**
   * WS3022500_BindingModeSettingEdit呼び出し
   * @param {*} recordData
   */
  callEdit(recordData) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "800px",
        component: (
          <WS3022500_BindingModeSettingEdit
            newFlag={false}
            BindingModeSettingFlag={true}
            record={recordData}
            onFinishScreen={(output) => {
              this.saveAndUpdateBindingModeSetting(recordData.id, output)
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  /**
   * 明細画面呼び出し
   * @param {*} record
   */
  callDetails(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "800px",
        component: (
          <WS3022100_BindingModeSettingDetails
            parent_code={record.parent_code}

            onFinishScreen={() => {

              this.getDataBindingModeSetting()

            }}
          />
        ),
      },
    });
  }

  isDisabled = (record) => {

    if (!this.state.BeforeBindingModeSettingList) return true

    try {

      const foundItem = this.state.BeforeBindingModeSettingList.find((item) => item.id === record.id);

      return foundItem.display_order === record.display_order
        && foundItem.parent_code === record.parent_code
        && foundItem.style_name === record.style_name
        && foundItem.enabled === record.enabled
        && foundItem.bulk === record.bulk

    } catch (error) {

      return true

    }
  }

  render() {
    return (
      <div className="binding-mode-setting">
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Card title="結合様式設定">
            <Table
              style={{ cursor: "pointer" }}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRowKeyLeft[0]
                  ? "table-row-light"
                  : ""
              }
              dataSource={this.state.BindingModeSettingList}
              bordered={true}
              pagination={false}
              scroll={{ y: 700 }}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.setState({
                      selectedRowKeyLeft: [record.id],
                      selectedRowRight: record.ModeUse[0],
                      selectedRowKeyRight: [record.ModeUse[0]?.id],
                    });
                    this.formRef.current.setFieldsValue({
                      PrinterNo: record.PrinterNo,
                      selectedRows: record,
                    });
                  },
                };
              }}
              size="small"
            >
              <Table.Column
                title="表示順"
                dataIndex="display_order"
                key="1"
                width='10%'
                render={(text, record, index) => (
                  <>
                    <Input type="text"
                      defaultValue={text}
                      onBlur={(e) => {
                        record.display_order = e.target.value
                      }

                      }
                    />
                  </>
                )}
              />
              <Table.Column
                title="様式"
                dataIndex="parent_code"
                key="2"
                width='15%'
                render={(text, record, index) => (
                  <>
                    <Input.Search
                      defaultValue={text}
                      style={{ width: 120 }}
                      value={record.parent_code}
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: "800px",
                            component: (
                              <WS0286001_PrintStyleInquiry
                                Li_UsuallyWrenRoster={""}
                                Lio_StyleCode={text}
                                onFinishScreen={({
                                  Lio_StyleCode,
                                  Lo_FormatName,
                                }) => {
                                  record.parent_code = Lio_StyleCode
                                  record.style_name = Lo_FormatName
                                  this.closeModal()

                                }}
                              />
                            ),
                          },
                        });
                      }}

                    />
                  </>
                )}


              />
              <Table.Column
                title="名称"
                dataIndex="style_name"
                key=""
                width='45%'
                render={(text, record, index) => (

                  <Input
                    defaultValue={record.style_name}
                    value={record.style_name}
                    onChange={(e) => {
                      record.style_name = e.target.value;
                      this.forceUpdate();
                    }}

                  />
                )}
              />
              <Table.Column
                title="有効"
                dataIndex="enabled"
                align="center"
                width='5%'
                key=""
                render={(text, record, index) => (
                  // this.checkJudgment(text)

                  <Checkbox
                    defaultChecked={record.enabled == 1 ? true : false}

                    onChange={(e) => {
                      record.enabled = e.target.checked ? 1 : 0
                    }}

                  />

                )}
              />
              <Table.Column
                title="一括"
                align="center"
                dataIndex="bulk"
                width='5%'
                key=""
                render={(text, record, index) => (
                  // this.checkJudgment(text)
                  <Checkbox
                    defaultChecked={record.bulk == 1 ? true : false}

                    onChange={(e) => {
                      record.bulk = e.target.checked ? 1 : 0
                    }}

                  />

                )}
              />

              <Table.Column
                title="明細様式"
                align="center"
                dataIndex="ModeUse"
                width='10%'
                key=""
                render={(text, record, index) => (
                  // this.checkJudgment(text)
                  <List
                    // bordered
                    size='small'
                    dataSource={record.ModeUse}
                    renderItem={(item) => (
                      <List.Item>
                        {/* <Typography.Text mark>{item.child_code}</Typography.Text> */}
                        {item.child_code}
                      </List.Item>
                    )}
                  />

                )}
              />
              <Table.Column
                title="保存"
                align="center"
                width='5%'
                key=""
                render={(text, record, index) => (
                  <Button
                    size="small"
                    type="primary"
                    disabled={this.isDisabled(record)}
                    icon={<SaveOutlined />}
                    onClick={async () => {

                      let ret = await this.syncSaveAndUpdateBindingModeSetting(record.id, record)

                      if (ret == null) {

                        this.setState({
                          BeforeBindingModeSettingList: JSON.parse(JSON.stringify(this.state.BindingModeSettingList))
                        });
                      } else {

                        this.setState({
                          BindingModeSettingList: JSON.parse(JSON.stringify(this.state.BeforeBindingModeSettingList))
                        });
                      }

                      this.forceUpdate()

                    }}
                  />
                )}
              />

              <Table.Column
                width='5%'
                align="center"
                title={() => (
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "800px",
                          component: (
                            <WS3022500_BindingModeSettingEdit
                              newFlag={true}
                              BindingModeSettingFlag={true}
                              onFinishScreen={(output) => {
                                this.saveAndUpdateBindingModeSetting(null, output)
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                )}
                dataIndex=""
                key=""
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            onClick={() => {
                              this.callEdit(record)
                            }}
                          >
                            <label>編集</label>
                          </Menu.Item>
                          <Menu.Item
                            key='2'
                            onClick={() => {
                              this.callDetails(record)
                            }}
                          >
                            <label>明細</label>
                          </Menu.Item>
                          <Menu.Item
                            key='3'
                            onClick={() => {
                              Modal.confirm({
                                title: '確認',
                                icon: (
                                  <QuestionCircleOutlined
                                    style={{ color: '#1890ff' }}
                                  />
                                ),
                                content: '削除しますか？',
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onOk: () => {
                                  this.deleteBindingModeSetting(
                                    record.id,
                                    record.parent_code
                                  )
                                },
                              })
                            }}
                          >
                            <label>削除</label>
                          </Menu.Item>

                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Form>
      </div>
    );
  }
}

export default WS3022038_BindingModeSetting;
