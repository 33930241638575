import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Radio, Input, Checkbox, Button, Modal } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import MutualAidSubjectBillingItemMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MutualAidSubjectBillingItemMaintain.actions.js'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'

class WS1778003_MutualAidSubjectBillingItemItemSpecifyEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '共済組合請求項目保守　詳細(検査コード) [変更]'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: []
    }

  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の場合
      this.formRef.current.setFieldsValue({
        exam_code: this.props.record.exam_code,
        exam_name: this.props.record.exam_name,
      })
      this.forceUpdate()
    }
  }

  /**
 * 保存ボタン処理
 */
  save = () => {
    const values = this.formRef.current?.getFieldsValue()
    const params = {
      id: this.props.record?.id ?? null,
      add_and_unimplemented: this.props.ItemSettingsSelectedRow?.add_and_unimplemented,
      serial_number: this.props.ItemSettingsSelectedRow?.serial_number,
      ...values
    }
    MutualAidSubjectBillingItemMaintainAction.saveItemSpecify(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 削除ボタン処理
   */
  delete = () => {
    const params = {
      id: this.props.record?.id ?? null,
    }
    MutualAidSubjectBillingItemMaintainAction.deleteItemSpecify(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 検査コード照会
   */
  showWS0271001_InspectItemSearchQuerySingle = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component:
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={this.formRef.current?.getFieldValue('exam_code')}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                exam_code: output.recordData.test_item_code,
                exam_name: output.recordData.exam_name
              })
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='mutual-aid-target-ken-course-maintain'>
        <Card title={`共済組合請求項目 詳細 [${(this.props.record === null) ? '新規追加' : '変更'}]`}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item
                label='検査コード'
                name='exam_code'>
                <Input.Search
                  className='input-search-size-number-8'
                  onSearch={() => {
                    this.showWS0271001_InspectItemSearchQuerySingle()
                  }}
                />
              </Form.Item>
              <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('exam_name')}</div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              hidden={!(this.props.record)}
              onClick={() => {
                Modal.confirm({
                  content: '削除しますか？',
                  okText: <span className='btn_label'>{'削除'}</span>,
                  okButtonProps: {
                    icon: <DeleteOutlined />,
                    type: 'primary',
                    danger: true,
                  },
                  cancelText: 'キャンセル',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1778003_MutualAidSubjectBillingItemItemSpecifyEdit)
