import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { Card, Form, Input, Button, Table, Modal, message } from 'antd'
import moment from 'moment-timezone'
import DispatchManageAction from 'redux/ResultOutput/DispatchManage/DispatchManage.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

const smGrid = {
  labelCol: { style: { width: 50 } },
};

const { TextArea } = Input

class WS2977500_ShippingDateRegistration extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.string,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '発送管理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      isLoadingTable: false,
      selectedRowKeys: [],
      selectedRow: {},

      isLoadingPage: false,

      closeModalReserveSelect: false,
      PersonalId: ''
    }
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      postDate: this.props.postDate,
      remarks: this.props.remarks
    })
    this.getDataSub()
  }

  getDataSub() {
    let params = {
      Li_DateF: this.props.ReserveDateFChar ? moment(this.props.ReserveDateFChar).format('YYYY/MM/DD') : '',
      Li_DateT: this.props.ReserveDateTChar ? moment(this.props.ReserveDateTChar).format('YYYY/MM/DD') : '',
      // postInfo: this.formRef.current?.getFieldValue('postInfo'),
      postInfo: this.props.postInfo ?? '予約関連',
      personalNumberId: this.props.personalNumberId ?? ''
    }

    DispatchManageAction.getDataSub(params)
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
          selectedRowKeys: res && res.length === 1 ? [res[0].id] : [],
          selectedRow: res && res.length === 1 ? res[0] : {},
          isLoadingTable: false,
        })
        this.formRef.current?.setFieldsValue({
          remarks: res && res.length === 1 ? [res[0].remarks] : ''
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='dispatch-manage p-td' style={{ width: 1000 }}>
        <Form
          ref={this.formRef}
        >
          <Card title='発送日登録' className='mb-2'>
            <div className='box_container'>
              <Form.Item
                name='postDate'
                label='発送日'
                {...smGrid}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={'YYYY/MM/DD'}
                  style={{ width: 112 }}
                />
              </Form.Item>
              <Table
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                bordered
                rowKey={(record) => record.id}
                rowClassName={(record, index) => record.id === this.state.selectedRow.id ? 'table-row-light' : ''}
                scroll={{ x: 'max-content', y: resizableTableScroll(200) }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedRow: record,
                        selectedRowKeys: record.id
                      })
                      this.formRef.current?.setFieldsValue({
                        remarks: record.remark
                      })
                    },
                  }
                }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  className='column-size-date'
                  render={(value, record, index) => {
                    return (
                      <div>{value !== '0000/00/00' ? moment(value)?.format('YYYY/MM/DD (ddd)') : ''}  </div>
                    )
                  }}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  width={70}
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}  </div>
                    )
                  }}
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  width={150}
                />

                <Table.Column
                  title='事業所名'
                  dataIndex='office_kanji_name'
                  width={230}
                />

                <Table.Column
                  title='契約名称'
                  dataIndex='contract_short_name'
                />
              </Table>

              <Form.Item
                name='remarks'
                label='備考'
                {...smGrid}
              >
                <TextArea
                  style={{ height: 115 }}
                />
              </Form.Item>

              <div className='box_button_bottom_right'>
                <Button type='primary'
                  onClick={() => {
                    if (this.state.selectedRowKeys.length === 0) {
                      Modal.error({
                        title: '予約を選択してください',
                        content: '',
                      });
                    } else {
                      this.props.onFinishScreen({
                        fieldValue: this.formRef.current?.getFieldValue(),
                        reservationNumber: this.state.selectedRow.reservation_number
                      })
                      console.log('予約選択済み')
                    }
                  }}
                >登録</Button>
              </div>
            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            if (this.state.closeModalReserveSelect) {
              this.personalConfirm_2()
            }

            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2977500_ShippingDateRegistration)
