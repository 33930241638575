import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

class WS1386001_InspectItemInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00160:検査項目照会';

    this.state = {
      dataSource: [],
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    VenusApiRoutesV2.callApi("API001386001002")
      .then(res => this.setState({ dataSource: res }));
  }

  render() {
    return (
      <div className="inspect-item-inquiry">
        <Card className="mb-2" title="検査項目照会">
          <Table
            size="small"
            bordered
            dataSource={this.state.dataSource}
            pagination={false}
            scroll={{ y: resizableTableScroll(0) }}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({
              onDoubleClick: () => {
                this.props.onFinishScreen(record)
              }
            })}
          >
            <Table.Column title="検査コード"
              width={100}
              dataIndex="exam_code"
              showSorterTooltip={false}
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column title="略称名"
              width={200}
              dataIndex="short_name"
              showSorterTooltip={false}
            />

            <Table.Column title="正式名称"
              dataIndex="formal_name"
              showSorterTooltip={false}
            />

            <Table.Column title="検査タイプ"
              width={100}
              dataIndex="InspectType"
              showSorterTooltip={false}
            />

            {/* <Table.Column title="" width={40} render={(value, record) => {
              return (
                <Button type="primary" onClick={() => this.props.onFinishScreen(record)}>選択</Button>
              )
            }} /> */}
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1386001_InspectItemInquiry);
