import axios from "configs/axios";

const API_LIST = {
  confirm: "/api/insure-guide-init-input/targetives-result-input/verification-ctrl-result",
};

const TargetivesResultInputService = {
  async confirm(params) {
    return axios.post(API_LIST.confirm, params);
  },
};

export default TargetivesResultInputService;
