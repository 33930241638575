import axios from 'configs/axios'

const apiPaths = {
  index: '/api/insurer-info-maintain/insurer-info-search-query',
  getScreenData: '/api/insurer-info-maintain/insurer-info-search-query/get-screen-data',
}

const InsurerInfoSearchQueryService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
}

export default InsurerInfoSearchQueryService
