import React from "react";
import { connect } from "react-redux";

import { Card, Col, Row, Table, Tree, Button, Form, Input, Modal } from "antd";
import { SearchOutlined, } from '@ant-design/icons'
import moment from 'moment'
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import VirusCheckLogAction from 'redux/Others/VirusCheckLog/VirusCheckLog.action'

const { TextArea } = Input;
const format = 'YYYY/MM/DD'
const today = moment(new Date().toISOString())

class VirusCheckLog extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);

    // document.title = '特定日時間帯人数設定';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      rowSelect: {},
      detalLog: ''
    };
  }

  /**
    * 初期表示処理
    */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      DateFChar: today.format(format),
      DateTChar: today.format(format),
    })
    // 当日のログを取る
    this.onSearch()
  }

  /**
   * 期間の検索
   */
  onSearch() {
    let params = {
      DateFChar: this.formRef.current?.getFieldValue('DateFChar'),
      DateTChar: this.formRef.current?.getFieldValue('DateTChar')
    }
    VirusCheckLogAction.getLog(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  getLogDetail(record) {
    let params = {
      fileName: record.file_name
    }
    VirusCheckLogAction.getLogDetail(params)
      .then((res) => {
        if (res) {
          this.setState({
            detalLog: res
          })
        }
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="specific-date-period-time-people-num-set">
        <Card title="ウイルスチェック結果確認">
          <Form ref={this.formRef}>
            <div className='box_inner_horizontal' style={{ width: '100%', paddingBottom: '10px' }}>
              <Form.Item label='期間' name='DateFChar' style={{ marginBottom: 0 }}>
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={format}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}> ~ </Form.Item>

              <Form.Item name='DateTChar' style={{ marginBottom: 0 }}>
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={format}
                />
              </Form.Item>

              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  this.onSearch()
                }}
              >
                <span className='btn_label'>
                  検索
                </span>
              </Button>
            </div>
            <div className='box_container' style={{ gap: 0 }}>
              <Table
                bordered
                size='small'
                style={{ cursor: 'pointer' }}
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                scroll={{ y: (this.state.rowSelect?.id) ? 300 : resizableTableScroll(90) }}
                rowClassName={(record, index) => record.id === this.state.rowSelect?.id ? 'table-row-light' : ''}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: async () => {
                      await this.setState({
                        rowSelect: record
                      })
                      // 詳細ログを取得
                      await this.getLogDetail(record)
                    }
                  }
                }}
                // eslint-disable-next-line no-undef
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              >
                <Table.Column
                  title='日時'
                  dataIndex='date_on'
                  width={180}
                />
                <Table.Column
                  title='場所'
                  dataIndex='location'
                  width={120}
                />
                <Table.Column
                  title='結果'
                  dataIndex='result'
                  width={120}
                />
              </Table>
              <TextArea
                style={{ display: (this.state.rowSelect?.id) ? '' : 'none', marginTop: 10 }}
                value={this.state.detalLog}
                rows={10}
                maxLength={10}
                readOnly
              />
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(VirusCheckLog);
