import axios from 'configs/axios';

const API_LIST = {
  loadScreenData: "/api/contract-info-maintain/condition-copy/getScreenData",
  fillInput: '/api/contract-info-maintain/condition-copy/contract-type-original',
  CopyExec: '/api/contract-info-maintain/condition-copy/CopyExec',
  getContractName: '/api/contract-info-maintain/condition-copy/get-contract-name',
};

const ConditionCopyService = {
  async loadScreenData(data) {
    return axios.get(API_LIST.loadScreenData, { data });
  },
  async fillInput(data) {
    return axios.get(API_LIST.fillInput, { data });
  },
  async CopyExec(data) {
    return axios.post(API_LIST.CopyExec, data);
  },
  async getContractName(params) {
    return axios.get(API_LIST.getContractName, { params })
  },
};

export default ConditionCopyService;
