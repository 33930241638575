import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Checkbox, Button, Modal } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import AutoJudgeScreenAction from 'redux/InputBusiness/ExamineeList/AutoJudgeScreen.action'

class WS0723001_AutoJudgeScreen extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_TrueOrFalse: PropTypes.any,
    Lo_TotalJudge: PropTypes.any,
    Lo_TotalCommentEditingArea: PropTypes.any,
    Lo_StsRun: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '自動判定：画面'

    this.state = {
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    AutoJudgeScreenAction.index()
      .then((res) => {
        this.formRef.current?.setFieldsValue(res)
      })
  }

  /**
   * 「実行」ボタン押下処理
   */
  runAutoJudge = () => {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_TrueOrFalse: this.props.Li_TrueOrFalse,
      TotalJudge: this.props.TotalJudge,
      StsUndeterminedOnly: this.formRef.current?.getFieldValue('StsUndeterminedOnly') ? 1 : 0,
      HierarchicalPresence: this.formRef.current?.getFieldValue('HierarchicalPresence') ? 1 : 0
    }

    AutoJudgeScreenAction.runAutoJudge(params)
      .then((res) => {
        let title = ''
        if (res?.message === 'Success') {
          if (params.HierarchicalPresence === 1) {
            if (res?.Warning1) {
              title = res?.Warning1
            } else {
              title = <span style={{ fontWeight: 'normal' }}>
                ＜判定＞<br />
                メタボリック = {res?.InspectValueMetabolic}<br />
                階層化　　　 = {res?.LaboratoryValuesHierarchical}<br />
                <br />
                ＜ﾘｽｸ情報＞<br />
                メタボリック = {res?.MetabolicSyndromeJudgeRisk}<br />
                階層化　　　 = {res?.HierarchicalJudgeRisk}<br />
              </span>
            }
            Modal.warning({
              width: 400,
              title: title,
              icon: <WarningOutlined />,
              okText: 'OK',
              onOk: () => {
                if (this.props.onFinishScreen) {
                  // データが変更になった場合、trueを渡す
                  this.props.onFinishScreen(true)
                }
              }
            })
          } else {
            if (this.props.onFinishScreen) {
              this.props.onFinishScreen(true)
            }
          }
        }
      })
  }

  render() {
    return (
      <div className='auto-judge-screen'>
        <Card title='自動判定'>
          <Form
            ref={this.formRef}
          >
            <div className='box_border'>
              <Form.Item
                name='StsUndeterminedOnly'
                valuePropName='checked'
              >
                <Checkbox>未判定の検査のみ</Checkbox>
              </Form.Item>
              <Form.Item
                name='HierarchicalPresence'
                valuePropName='checked'
                style={{ marginBottom: 0 }}
              >
                <Checkbox>階層化判定を実行</Checkbox>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen()
                  }
                }}
              >
                キャンセル
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.runAutoJudge()
                }}
              >
                <span className='btn_label'>
                  実行
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0723001_AutoJudgeScreen)
