import React from "react";
import { connect } from "react-redux";

import { Card, Form, Select, Button, Col } from "antd";

import RisingDocumentCreateAction from "redux/Others/RisingDocumentCreate/RisingDocumentCreate.action";

class WS2290003_JudgeUnitList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '判定・単位一覧表';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      JudgeLevelList: [],
      PatternList: [],
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true })
    RisingDocumentCreateAction.getLists()
      .then((res) => {
        console.log(res)
        this.setState({
          JudgeLevelList: res.JudgeLevel_List,
          PatternList: res.Pattern_List,
        })
        this.formRef?.current?.setFieldsValue({
          JudgeLevel: res.JudgeLevel_List[0].condition_1,
          Pattern: res.Pattern_List[0].pattern_code,
        })

        if(this.props.Lio_JudgeLevel) {
          this.formRef?.current?.setFieldsValue({
            JudgeLevel: this.props.Lio_JudgeLevel
          })
        }
        if(this.props.Lio_Pattern) {
          this.formRef?.current?.setFieldsValue({
            Pattern: this.props.Lio_Pattern
          })
        }
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  onFinish() {
    this.props.onFinishScreen({
      JudgeLevel: this.formRef.current?.getFieldValue("JudgeLevel"),
      Pattern: this.formRef.current?.getFieldValue("Pattern"),
    })
  }

  render() {
    const { JudgeLevelList, PatternList } = this.state
    return (
      <div className="judge-unit-list">
        <Card className="mb-2" title="判定・単位一覧表">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="JudgeLevel"
              label="判定レベル"
              labelCol={{ span: 6 }}
            >
              <Select>
                {JudgeLevelList?.map(judge => (
                  <Select.Option key={"JudgeLevel-" + judge.condition_1} value={judge.condition_1}>{judge.item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="Pattern"
              label="パターン"
              labelCol={{ span: 6 }}
            >
              <Select>
                {PatternList?.map(pattern => (
                  <Select.Option key={"Pattern-" + pattern.pattern_code} value={pattern.pattern_code}>{pattern.pattern_name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Col style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => this.onFinish()}>確定</Button>
            </Col>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2290003_JudgeUnitList);
