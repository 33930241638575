import axios from "configs/axios";

const apiPaths = {
  getDate: "/api/bulk-auto-calculate/bulk-auto-calculate/get-date",
  exec: "/api/bulk-auto-calculate/bulk-auto-calculate/exec",
};

const BulkAutoCalculateService = {
  async getDate(params) {
    return axios.get(apiPaths.getDate, { params });
  },
  async exec(params) {
    return axios.post(apiPaths.exec, params);
  },
};

export default BulkAutoCalculateService;
