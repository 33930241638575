import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Form, Button, Upload, Modal, message, Space } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import upFile from 'assets/img/uploadFile.svg';

import OptionSettingAction from 'redux/Others/OptionSetting/OptionSetting.actions.js'

class OptionSettingCsvCapture extends React.Component {
  static propTypes = {
    Lio_Output: PropTypes.any,
    Lo_StsOutput: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'オプション設定対応表CSV取込';

    this.state = {
      fileList: []
    };
    this.onFinish = this.onFinish.bind(this);
  }

  CaptureF12 = () => {
    let formData = new FormData();
    formData.append("FileName", this.state.fileList[0].originFileObj);
    OptionSettingAction.captureFile(formData)
      .then(res => {
        if(res) {
          Modal.info({
            content: res.message,
            okText: 'はい',
          })
          if(this.props.onFinishScreen) this.props.onFinishScreen()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleUpload = ({ fileList }) => {
    this.setState({ fileList });
  };

  onFinish = (values) => {
    if (values.Lio_Output) {
      Modal.confirm({
        content: '選択したファイルを取り込みますか？',
        icon: <QuestionCircleOutlined style={{ color: '#006DC9' }} />,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          this.CaptureF12();
        }
      });
    } else {
      Modal.error({
        content: '取込元を入力してください',
        okText: 'OK',
      })
    }

  }

  render() {
    return (
      <div className="inspect-request-convert-master-maintain">
        <Card title="オプション対応表CSV取込">
          <Form onFinish={this.onFinish} style={{marginLeft: '24px'}}>
          <div style={{marginBottom: '12px'}}>ファイル名は「OptionSetting.csv」にしてください。</div>
          <div style={{marginBottom: '12px'}}>ヘッダーは「fileName」「typeCode」「optionCode」としてください。</div>
            <Form.Item name="Lio_Output" label="取込元"  >
              <Upload
                maxCount={1}
                listType="application/vnd.ms-excel"
                fileList={this.state.fileList}
                onChange={this.handleUpload}
                beforeUpload={() => false}
              >
                <Button>
                  <Space>
                    <img src={upFile} alt="icon" width={20} />
                    <span>クリックしてファイルを選択してください</span>
                  </Space>
                </Button>
              </Upload>
            </Form.Item>
            <Button style={{ float: 'right' }} htmlType='submit' type="primary">取込</Button>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionSettingCsvCapture);
