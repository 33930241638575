import axios from 'configs/axios'

const apiPaths = {
  index: '/api/inspect-cmt-info-maintain/inspect-cmt-info-maintain',
  getExamName: '/api/inspect-cmt-info-maintain/inspect-cmt-info-maintain/get-exam-name',
  copyRegister: '/api/inspect-cmt-info-maintain/inspect-cmt-info-maintain/copy-register',
}

const InspectCmtInfoMaintainService = {
  async index(params) {
    return axios.get(apiPaths.index, { params });
  },
  async getExamName(params) {
    return axios.get(apiPaths.getExamName, { params });
  },
  async copyRegister(params) {
    return axios.post(apiPaths.copyRegister, params);
  },
}
export default InspectCmtInfoMaintainService;
