import ContractHistoricalQuerySubService from 'services/basicInfo/ContractInfoMaintain/ContractHistoricalQuerySubService'
import { message, } from "antd"

const ContractHistoricalQuerySubAction = {
  getHistoryList(params) {
    return ContractHistoricalQuerySubService.getHistoryList(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        message.error(res.data.message)
      })
  },
}
export default ContractHistoricalQuerySubAction
