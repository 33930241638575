import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import { Form, Button, Table, message, Modal, Tooltip, Input, Dropdown, Menu, Card } from 'antd'
import { MoreOutlined, SaveOutlined } from '@ant-design/icons'
import GetImage from 'constants/Images'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274002_InspectCmtSearchInput from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274002_InspectCmtSearchInput'
import WS0730001_FindingsInputPhysiciiagnosis from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx'
import WS0729001_FindingsInputRadiography from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729001_FindingsInputRadiography.jsx'
import WS0728001_FindingsInputNormal from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0728001_FindingsInputNormal.jsx'
import WS2637015_InspectResultValueInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637015_InspectResultValueInput.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import recalculate from 'components/Commons/recalculate'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import InspectionResultsChangeHistory from 'pages/IN_InputBusiness/SANAI_InterviewSupport/InspectionResultsChangeHistory.jsx'
import moment from 'moment'

class DetailInspectResultDisplay extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '精密検査結果一覧'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      inspectResultList: [],
      changeData: [],
      // categoryComment: '',
      categoryJudgmentChangeFlag: 0,

      disabledUpdateBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getDetailInspectResultList()
  }

  /**
   * レコード（検査値）が変更（編集）された時の処理
   * @param {*} prevProps
   */
  componentDidUpdate = (prevProps) => {
    // if (this.props.record) {
    //   if (this.props.record !== prevProps.record) {
    //     this.formRef.current?.setFieldsValue({
    //       category_judge: this.props.record?.category_judge,
    //       category_comment: this.props.record?.category_comment,
    //       categoryJudgmentChangeFlag: 0
    //     })
    //     this.getDetailInspectResultList()
    //   }
    // }
  }

  /**
   * カテゴリ別の詳細な精密検査結果一覧を取得
   */
  getDetailInspectResultList = (changeFlg) => {
    // console.log(this.props.values);
    // 親画面から引数取得
    // const record = { ...this.props.record }
    const values = { ...this.props.values }

    let SelectCategoryList = ''
    const HistoryAdded = values.HistoryAdded //履歴
    const NonInput = values.NonInput
    const Outliers = values.Outliers
    const NonInputOrOutliers = NonInput === 1 ? { NonInput } : { Outliers }
    const NormalJudge = values.NormalJudge

    const params = {
      ...this.props.paramObject,
      StsInputModeInspect: values.StsInputModeInspect,
      StsInputModeFindings: values.StsInputModeFindings,
      SelectCategoryList: SelectCategoryList,
      // Li_JudgeLevel: record.Li_JudgeLevel,
      // SelectCategory: record.SelectCategory,
      UndeterminedColorOp: values.UndeterminedColorOp,
      StsParkModeCategoryUpAndDown: values.StsParkModeCategoryUpAndDown,
      StsInputModeInspectCmtChiba: values.StsInputModeInspectCmtChiba,
      HistoryAdded,
      ...NonInputOrOutliers,
      NormalJudge,
      CategoryDisplayPosition: values.CategoryDisplayPosition,
      sex: values.sex,
      birthday_on: values.birthday_on,
      visit_date_on: values.visit_date_on,
      Li_DateOfBirth: values.Li_DateOfBirth,
      pre1ReserveNum: values.pre1ReserveNum ?? '',
      pre2ReserveNum: values.pre2ReserveNum ?? '',
      pre3ReserveNum: values.pre3ReserveNum ?? '',
      pre4ReserveNum: values.pre4ReserveNum ?? '',
    }
    ResultDisplayAction.getDetailInspectResultList(params)
      .then((res) => {
        if (res) {
          this.setState({
            inspectResultList: res,
            changeData: res,
            disabledUpdateBtn: true,
          })
          this.formRef?.current?.setFieldsValue({ formInspectResultList: res })
          if (changeFlg && this.props.onUpdateCategory) {
            this.props.onUpdateCategory()
          }
        }
      })
  }

  /**
   * データチェック
   * @param {*} value
   * @param {*} type
   * @returns
   */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  getRawValue = (name) => this.propps.formRef?.current?.getFieldValue(name)

  /**
   * suffixResultValue のパラメータを取得
   * @param {*} record
   * @returns
   */
  getSuffixResultValueParams = (record) => {

    const params = {
      id: this.checkData(record.id, 'number'),
      display_no: this.checkData(this.props.record?.display_no, 'number'), //カテゴリ
      display_order: this.checkData(record.display_order, 'number'), //検査
      Li_Gender: this.props.values.Li_Gender,
      Li_DateOfBirth: this.props.values.Li_DateOfBirth,
      judgment_value: this.checkData(record.judgment_value, 'text'),
      result_value: this.checkData(record.result_value, 'text'),
      remarks: this.checkData(record.remarks, 'text'),
      StsAutomaticCalculate: this.checkData(record.StsAutomaticCalculate, 'number'),
      exam_code: this.checkData(record.exam_code, 'number'),
      visit_date_on: this.props.values.visit_date_on,
      judgment_code: this.checkData(record.judgment_code, 'number'),
      NormalJudge: this.getRawValue('NormalJudge'),
      UpAndDown: this.checkData(record.UpAndDown, 'text'),
      display_remark: this.checkData(record.display_remark, 'text'),
    }
    return params
  }


  /**
   * 編集用のモーダルを表示する
   * @param {*} record
   */
  showEditModal = (record) => {
    // ロックされていない受診者だけ
    if (!this.props.protectFlag) {
      // if (record.StsInputModeInspectCmtChiba === 1 && record.textPositionExamType && record.StsInspectCmtInfo) { ※いるかも！
      if (record.textPositionExamType && record.StsInspectCmtInfo) {
        // 検査コメント検索・照会　モーダル 大体はこのモーダルを使用
        this.showWS0274001_InspectCmtSearchQuery(record)
        // this.showWS0274002_InspectCmtSearchInput(record)

      } else if (record.exam_type.slice(0, 1) === 'N') {
        // 直接入力系（N系）は何も呼ばない
        message.info('入力フォームに直接入力してください')
        return
        // 数値系結果値入力 モーダル
        // this.showWS2637015_InspectResultValueInput(record)

      } else {
        // 検査コメント照会以外(所見関連)のモーダル
        // 検査によって表示する編集用モーダルを切り替える
        this.switchEditModal(record)
      }
    }
  }

  /**
   * 検査コメント検索・照会　モーダルを開く
   */
  showWS0274001_InspectCmtSearchQuery = (record) => {
    // コメント区分(exam_comment_code)が0の場合はモーダルは表示しない
    if ((record.exam_comment_code ?? 0) > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 600,
          component: (
            <WS0274001_InspectCmtSearchQuery
              Li_PatternCode={this.props.paramObject.Li_PatternCode}
              Li_CategoryCode={record.category_code}
              Li_CategoryName={record.category_name}
              Lio_StsNotesChange={record.StsNotesChange}
              Lio_CmtClassify={record.exam_comment_code}
              LnkOutInspectCmtScreen={record.result_value}
              onFinishScreen={(output) => {
                // 選択された検査コメントを反映
                const value = output.LnkOutInspectCmtScreen
                // 結果値が変更になった場合
                this.changeResultValue(value, record)
                // モーダルを閉じる
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  /**
   *　検査コメント照会　モーダル
   * @param {*} record
   */
  showWS0274002_InspectCmtSearchInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <WS0274002_InspectCmtSearchInput
            Li_ReserveNum={this.props.paramObject.Li_ReserveNum}
            Li_InspectCode={record.exam_code}
            Li_PatternCode={this.props.paramObject.Li_PatternCode}
            Li_CategoryCode={record.category_code}
            Li_CategoryName={record.category_name}
            Lio_StsNotesChange={record.StsNotesChange}
            Lio_CmtClassify={record.exam_comment_code}
            Lio_JudgmentValue={record.judgment_value}
            LnkOutInspectCmtScreen={record.result_value}
            onUpdateResultValue={() => {
              // 検査結果一覧を再取得
              this.getDetailInspectResultList()
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   *　結果値入力　モーダル
   * @param {*} record
   */
  showWS2637015_InspectResultValueInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 300,
        component:
          <WS2637015_InspectResultValueInput
            Li_ReserveNum={this.props.paramObject.Li_ReserveNum}
            Li_InspectCode={record.exam_code}
            Li_PatternCode={this.props.paramObject.Li_PatternCode}
            Li_CategoryCode={record.category_code}
            Lio_JudgmentValue={record.judgment_value}
            Lio_ResultValue={record.result_value}
            onUpdateResultValue={() => {
              // 検査結果一覧を再取得
              this.getDetailInspectResultList()
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   *
   * @param {*} record
   */
  switchEditModal = (record) => {
    const params = {
      ...this.props.paramObject,
      Li_JudgeLevel: record.Li_JudgeLevel,
      display_order: record.display_order,
      display_no: record.display_no,
      pattern_code: record.pattern_code,
      category_code: record.category_code,
      personal_number_id: this.props.values.personal_number_id,
      exam_code: record.exam_code,
      GuideHowToAddOp: this.props.values.GuideHowToAddOp,
      StsInputModeFindings: this.props.values.StsInputModeFindings,
    }

    // exam_typeが「S」である場合
    OverallResultDisplayInputAction.findingsEditingBefore(params)
      .then((res) => {
        if (res) {
          const messagex = res.message ?? ''
          const variables = res.variables ?? null
          if (this.props.values.StsInputModeFindings === 1) {

            if (messagex === 'Call Screen WS0730001') {
              // 所見入力[医師診断]
              this.showWS0730001_FindingsInputPhysiciiagnosis(variables, record)
            }
            if (messagex === 'Call Screen WS0729001') {
              // 所見入力[読影]
              this.showWS0729001_FindingsInputRadiography(variables, record)
            }
            if (messagex === 'Call Screen WS0731001' || messagex === 'Call Screen WS0728001') {
              // 所見入力[通常]
              this.showWS0728001_FindingsInputNormal(variables, record)

              // ※「WS0731001_FindingsInputNormalSelect」は「WS0728001_FindingsInputNormal」と同じレイアウト、処理になるので統一する
              // 所見入力[通常選択]
              // this.showWS0731001_FindingsInputNormalSelect(variables, record)
            }
          }
        }
      })
  }

  /**
   * 所見入力[医師診断]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0730001_FindingsInputPhysiciiagnosis = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getDetailInspectResultList()
            }}
          />
        ),
      },
    })
  }

  /**
   * 所見入力[読影]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0729001_FindingsInputRadiography(variables, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0729001_FindingsInputRadiography
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getDetailInspectResultList()
            }}
          />
        ),
      },
    })
  }

  /**
 * 所見入力[通常]　モーダル
 * @param {*} variables
 * @param {*} record
 */
  showWS0728001_FindingsInputNormal = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0728001_FindingsInputNormal
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getDetailInspectResultList(true)
            }}
          />
        ),
      },
    })
  }

  /**
  * 判定選択　(検査コード毎用)
  * @param {*} data
  */
  showWS0285001_JudgeQueryInspect = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {
              if (output.recordData) {
                this.changeJudgmentValue(output.recordData.judgment_result, record)
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   *　判定一覧モーダルを開く（カテゴリ用）
   * @param {*} data
   * @param {*} index
   */
  showWS0285001_JudgeQueryCategory = () => {
    const data = { ...this.props.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={data?.judgment_level_division}
            Lio_Judge={data?.category_judge}
            onFinishScreen={async (output) => {
              if (output.recordData) {

                let judgmentResult = output.recordData.judgment_result
                if (judgmentResult === '') {
                  judgmentResult = '判定なし'
                }

                Modal.confirm({
                  content: `カテゴリ判定を [${output.recordData.judgment_result}] に変更します。よろしいですか？`,
                  okText: '保　存',
                  cancelText: 'キャンセル',
                  onOk: () => {
                    // カテゴリ判定を保存
                    this.formRef.current?.setFieldsValue({
                      category_judge: judgmentResult,
                      categoryJudgmentChangeFlag: 1
                    })
                    this.changeJudgmentValue()
                  }
                })
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * カテゴリ判定を保存
   * @param {*} value
   */
  saveJudgmentCategory = (value) => {
    const params = {
      Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
      Li_CategoryCode: this.props.record?.category_code,
      Li_CourseLevel: this.props.paramObject.Li_CourseLevel,
      Li_CategoryJudgment: value.judgment_result,
      Li_JudgeLevel: this.props.values.Li_JudgeLevel,
    }
    OverallResultDisplayInputAction.saveJudgmentCategory(params)
      .then((res) => {
        if (this.props.onUpdateCategory) {
          // 親画面で変更されたカテゴリ判定を反映する
          this.props.onUpdateCategory()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({ content: res.data.message })
      })
  }

  /**
   * 結果値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeResultValue(value, record) {
    record.inspectComments.map((element, index) => {
      let newElement = element.exam_comment_code.trim()
      if (value == newElement) {
        value = element.exam_comment_screen.trim()
      }
    })

    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (exam_type.slice(0, 1) === 'N' && value !== '') {
      // exam_typeが「N、N1、N2、N3」の場合
      resultValue = recalculate(exam_type, value)
    } else {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.result_value !== value) {
        // 検査結果値の設定
        element.result_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // this.props.onChangeData({
    //   changeData: copyData,
    //   category_comment: this.formRef.current?.getFieldValue('category_comment'),
    // })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }

  /**
   * 検査ごとの判定値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeJudgmentValue(value, record) {
    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (value !== '') {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.judgment_value !== value) {
        // 検査結果値の設定
        element.judgment_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    this.props.onChangeData({
      changeData: copyData,
      category_comment: this.formRef.current?.getFieldValue('category_comment'),
      category_judge: this.formRef.current?.getFieldValue('category_judge'),
      categoryJudgmentChangeFlag: this.formRef.current?.getFieldValue('categoryJudgmentChangeFlag')
    })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({
      formInspectResultList: copyData,
    })
  }

  /**
   * 変更された検査結果の更新
   */
  saveResultValueList = () => {
    const params = {
      Li_CourseLevel: this.props.paramObject.Li_CourseLevel,
      Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
      changeData: this.state.changeData
    }
    OverallResultDisplayInputAction.saveResultValueList(params)
      .then((res) => {
        // カテゴリ別の詳細な検査結果一覧を取得
        this.getDetailInspectResultList(true)
      })

  }

  /**
   * 前回の検査結果を反映する
   * @param {*} selectedRecord
   */
  setPreviousExamCodeDo = (selectedRecord) => {
    const pre1ReserveNum = this.props.values.pre1ReserveNum

    if (pre1ReserveNum === '' || pre1ReserveNum === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果 [${selectedRecord.inspect_name}] を反映します。よろしいですか？`,
        okText: '保　存',
        cancelText: 'キャンセル',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
            PreviousReserveNum: pre1ReserveNum,
            Li_ExamCode: record?.exam_code,
            Li_ExamType: record?.exam_type,
          }
          OverallResultDisplayInputAction.setPreviousExamCodeDo(params)
            .then((res) => {
              // 検査結果一覧を再取得
              // カテゴリ別の詳細な検査結果一覧を取得
              this.getDetailInspectResultList(true)
              this.closeModal()
            })
        }
      })
    }
  }

  /**
   * 変更履歴を表示する
   */
  showInspectionResultsChangeHistory = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <InspectionResultsChangeHistory
            reservationNumber={this.props.paramObject.Li_ReserveNum}
            examCode={record.exam_code}
          />
        ,
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='detail-result-display'>
        <Card title='精密検査結果'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Table
                bordered
                size='small'
                id={'DetailInspectResultDisplay'}
                dataSource={this.state.inspectResultList}
                rowKey={record => record?.id}
                pagination={false}
                scroll={{ x: 'max-content', y: resizableTableScroll(60, 'DetailInspectResultDisplay') }}
                onRow={(record, rowIndex) => {
                  return {
                  }
                }}
                rowClassName={(record, index) =>
                  record.dt0420_id ? '' : 'table-row-no-accepted'
                }
              >
                <Table.Column
                  dataIndex='inspect_name'
                  title='検査名称'
                  className='column-size-40'
                  render={(value, record, index) => (
                    <Tooltip title={record?.inspectNameTooltip}>
                      <div style={{ textAlign: 'left' }}>
                        <div style={{
                          marginLeft: 5,
                          fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : '',
                          fontSize: '18px'
                        }}>
                          {(record?.imageNum === '') ?
                            null
                            :
                            <img src={GetImage(record?.imageNum)} alt='icon' />
                          }
                          {value}
                        </div>
                      </div>
                    </Tooltip>
                  )}
                />

                <Table.Column
                  dataIndex='normal_value'
                  title='参考値'
                  className='column-size-40'
                  render={(item, record, index) => (
                    <div style={{ textAlign: 'center', fontSize: '18px' }}>
                      {record.normal_value}
                      {record.dataConfirmFlag === 0 ?
                        null
                        :
                        <span
                          style={{
                            float: 'right',
                            fontWeight: record?.resultValueFontWeight === 82 ? 'bold' : '',
                            fontSize: '18px'
                          }}>
                          *
                        </span>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  className='this-time'
                  render={(value, record, index) => (
                    <Tooltip title={record.valueTooltip}>
                      {(!record.dt0420_id) ?
                        // dt0420のテーブルに存在してない検査項目の場合、編集不可
                        <div />
                        :
                        (record.exam_type === 'S') ?
                          // 所見 (モーダルを開いて値を変更)
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : '',
                              fontSize: '18px',
                              color: Color(record.judgmentValueColorNum)?.Foreground,
                              border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            {value}
                          </div>
                          :

                          <div
                            name={['formInspectResultList', index, 'judgment_value']}
                            onDoubleClick={() => {
                              if (!this.props.protectFlag) {
                                // 編集画面の表示処理
                                this.showWS0285001_JudgeQueryInspect(record)
                              }
                            }}
                            style={{
                              textAlign: 'center',
                              fontWeight: (record?.resultValueFontWeight === 82 || (record.change_flg ?? 0)) ? 'bold' : '',//変更時は太字
                              fontSize: '18px',
                              color: Color(record.judgmentValueColorNum)?.Foreground,
                              border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                              whiteSpace: 'pre-wrap',
                              cursor: 'pointer'
                            }}
                          >
                            {value ? value : '　'}
                          </div>
                      }
                    </Tooltip>
                  )}
                />

                <Table.Column
                  className='this-time'
                  dataIndex='result_value'
                  title={
                    <Tooltip
                      title={
                        <div className='box_inner_vertical'>
                          <div>{moment(this.props.values.DateChar).format('YYYY/MM/DD')}</div>
                          <div>{this.props.values?.contract_short_name}</div>
                        </div>
                      }
                    >
                      <div style={{ whiteSpace: 'pre-wrap' }}>{'今回'}</div>
                    </Tooltip>
                  }
                  width={220}
                  render={(value, record, index) => (
                    <Tooltip title={record.valueTooltip}>
                      {(!record.dt0420_id) ?
                        // dt0420のテーブルに存在してない検査項目の場合、編集不可
                        <div />
                        :
                        (record.exam_type === 'S' || (this.props.protectFlag && (record.exam_type.slice(0, 1) !== 'N'))) ?
                          // 所見 (モーダルを開いて値を変更)
                          // または、ロック時 かつ exam_typeが「N」以外
                          <div
                            style={{
                              color: Color(record.resultValueColorNum)?.Foreground,
                              whiteSpace: 'pre-wrap',
                              padding: '5px 8px',
                              fontSize: '18px',
                            }}
                            onDoubleClick={() => {
                              // 編集画面の表示処理
                              this.showEditModal(record)
                            }}
                          >
                            {value}
                          </div>
                          :
                          (record.StsAutomaticCalculate || ((record.exam_type.slice(0, 1) === 'N') && this.props.protectFlag)) ?
                            // 自動計算あり（BMIや基準体重など）入力なし
                            // または、ロック時 かつ exam_typeが「N」
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  width: '30px',
                                  cursor: 'pointer',
                                  color: Color(record.upAndDownColorNum)?.Foreground,
                                  textAlign: 'center',
                                  fontSize: '18px',
                                }}>
                                {record.UpAndDown}
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', width: '100%' }}>
                                <div
                                  style={{
                                    color: Color(record.resultValueColorNum)?.Foreground,
                                    width: '6rem',
                                    textAlign: 'right',
                                    fontSize: '18px',
                                  }}
                                >
                                  {value}
                                </div>
                              </div>
                            </div>
                            :
                            // 値の変更、入力あり
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onDoubleClick={() => {
                                // 編集画面の表示処理
                                this.showEditModal(record)
                              }}
                            >
                              <div
                                style={{
                                  display: (record.UpAndDown !== '') ? '' : 'none',
                                  width: '30px',
                                  cursor: 'pointer',
                                  color: Color(record.upAndDownColorNum)?.Foreground,
                                  textAlign: 'center',
                                  fontSize: '18px',
                                }}>
                                {record.UpAndDown}
                              </div>
                              <Form.Item
                                label=''
                                name={['formInspectResultList', index, 'result_value']}
                                style={{ marginBottom: 0 }}
                              >
                                <Input
                                  id={'spreadInput-2-' + record.id + '-' + record.category_code}
                                  type='tel'
                                  onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', 1, true, 0)}
                                  onPressEnter={(e) => this.changeResultValue(e.target.value, record)}
                                  onBlur={(e) => this.changeResultValue(e.target.value, record)}
                                  style={{
                                    textAlign: (record.exam_type.slice(0, 1) === 'N') ? 'right' : 'left',
                                    fontWeight: (record.change_flg ?? 0) ? 'bold' : 'normal', //変更時は太字
                                    fontSize: '18px',
                                  }}
                                />
                              </Form.Item>
                            </div>
                      }
                    </Tooltip>
                  )}
                />

                <Table.Column
                  dataIndex='pre_1_judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                        fontSize: '18px',
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_1_result_value'
                  title={
                    <Tooltip
                      title={
                        <div className='box_inner_vertical'>
                          <div>{this.props.values?.pre1VisitDate}</div>
                          <div>{this.props.values?.pre1CourseName}</div>
                        </div>
                      }
                    >
                      <div style={{ whiteSpace: 'pre-wrap' }}>{'前回'}</div>
                    </Tooltip>
                  }
                  className='column-size-40'
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_2_judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                        fontSize: '18px',
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_2_result_value'
                  title={
                    <Tooltip
                      title={
                        <div className='box_inner_vertical'>
                          <div>{this.props.values?.pre2VisitDate}</div>
                          <div>{this.props.values?.pre2CourseName}</div>
                        </div>
                      }
                    >
                      <div style={{ whiteSpace: 'pre-wrap' }}>{'前々回'}</div>
                    </Tooltip>
                  }
                  className='column-size-40'
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_3_judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre3JudgmentValueColorNum)?.Foreground,
                        fontSize: '18px',
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_3_result_value'
                  title={
                    <Tooltip
                      title={
                        <div className='box_inner_vertical'>
                          <div>{this.props.values?.pre3VisitDate}</div>
                          <div>{this.props.values?.pre3CourseName}</div>
                        </div>
                      }
                    >
                      <div style={{ whiteSpace: 'pre-wrap' }}>{'3回前'}</div>
                    </Tooltip>
                  }
                  className='column-size-40'
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre3JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre3JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_4_judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre4JudgmentValueColorNum)?.Foreground,
                        fontSize: '18px',
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_4_result_value'
                  title={
                    <Tooltip
                      title={
                        <div className='box_inner_vertical'>
                          <div>{this.props.values?.pre4VisitDate}</div>
                          <div>{this.props.values?.pre4CourseName}</div>
                        </div>
                      }
                    >
                      <div style={{ whiteSpace: 'pre-wrap' }}>{'4回前'}</div>
                    </Tooltip>
                  }
                  className='column-size-40'
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre4JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre4JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap',
                            fontSize: '18px',
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />


                {(!this.props.protectFlag) ?
                  <Table.Column
                    width={40}
                    style={{ textAlign: 'center' }}
                    render={(value, record, index) => (
                      (record.dt0420_id) ?
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='1'
                                disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                onClick={() => {
                                  // 編集画面の表示処理
                                  this.showEditModal(record)
                                }}
                              >
                                編集
                              </Menu.Item>
                              <Menu.Item
                                key='2'
                                disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                onClick={() => {
                                  this.setPreviousExamCodeDo(record)
                                }}
                              >
                                前回DO
                              </Menu.Item>
                              <Menu.Item
                                key='3'
                                disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                onClick={() => {
                                  this.showInspectionResultsChangeHistory(record)
                                }}
                              >
                                変更履歴
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                        :
                        // dt0420のテーブルに存在してない検査項目の場合、編集不可
                        null
                    )} />
                  :
                  //ロック時は非表示
                  null
                }
              </Table>
            </div>
          </Form>
          {/* Footer */}
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => this.saveResultValueList()}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailInspectResultDisplay)
