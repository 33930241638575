import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  InputNumber,
  Input,
  Select,
  Button,
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Modal,
  Menu,
  Dropdown,
  message,
  Spin,
  Tooltip
} from "antd";

import {
  DeleteOutlined,
  SaveOutlined,
  SearchOutlined,
  MoreOutlined,
  PlusOutlined,
  CaretDownOutlined,
  ArrowDownOutlined
} from "@ant-design/icons";

import Color from "constants/Color";
import { width } from "dom-helpers";
import axios from "configs/axios";

import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle";


class WS1181003_Copy extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_CopySourceCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = '複写';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      isLoading: false,
      isLoadingTable: false,

      a1_kyokai_conversions: {
        source: {
          exam_code: this.props.Li_CopySourceCode,
          exam_name: '',
        },
        destination: {
          exam_code: '',
          exam_name: '',
        }
      },

    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  getScreenData = () => {
    this.setState({ isLoading: true });
    this.setState({ isLoadingTable: true });

    axios
      .get("/api/associate-insure-param-maintain/copy/getscreenData", {
        params: {
          Li_CopySourceCode: this.props.Li_CopySourceCode,
        }
      })
      .then(res => {
        this.setState({
          isLoadingTable: false,
          isLoading: false
        });

        this.setState(prevState => ({
          a1_kyokai_conversions: {
            ...prevState.a1_kyokai_conversions,
            source: {
              ...prevState.a1_kyokai_conversions.source,
              exam_code: res.data.exam_code,
              exam_name: res.data.exam_name
            }
          }
        }));

      })
      .catch(error => {
        this.setState({ isLoading: false });
        this.setState({ isLoadingTable: false });

        message.error(error.response.data.message)
      });
  };

  updateOrCreateRecord(index) {
    this.setState({ isLoading: true });
    const params = {
      exam_code: this.state.a1_kyokai_conversions.source.exam_code,
      CopyCode: this.state.a1_kyokai_conversions.destination.exam_code,
    };

    axios('/api/associate-insure-param-maintain/copy/save', { params })
      .then(res => {
        console.log('copy/save', res);
        this.props.onFinishScreen();
      })
      .catch(err => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }


  render() {
    return (

      <>
        <Card title="複写">
          <Card>
            <Spin spinning={this.state.isLoading}>
              <Space direction="vertical">
                <Space>
                  {'検査'}
                  <Input
                    readOnly
                    style={{ width: "100px", marginLeft: '10px', backgroundColor: 'lightgray', textAlign: 'right' }}
                    defaultValue={this.state.a1_kyokai_conversions.source.exam_code} />
                  <div style={{ marginLeft: '10px' }}>{this.state.a1_kyokai_conversions.source.exam_name}</div>
                </Space>
                <ArrowDownOutlined style={{ marginLeft: '90px' }} />
                <Space>
                  {'検査'}
                  <Input style={{ width: "100px", marginLeft: '10px', textAlign: 'right' }}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          component: (
                            <WS0271001_InspectItemSearchQuerySingle
                              // InspectCode={this.state.a1_kyokai_conversions.destination.exam_code}
                              Li_Select={2}
                              onFinishScreen={async (output) => {

                                this.setState(prevState => ({
                                  a1_kyokai_conversions: {
                                    ...prevState.a1_kyokai_conversions,
                                    destination: {
                                      ...prevState.a1_kyokai_conversions.destination,
                                      exam_code: output.recordData.test_item_code,
                                      exam_name: output.recordData.exam_name
                                    }
                                  }
                                }));

                                this.closeModal();

                                // const isexist = await this.exist(output.recordData.test_item_code);

                                // if (isexist) {
                                //   message.warning('データが既に存在しています')
                                //   this.setState({ execute: true })
                                // } else {
                                //   this.setState({ execute: false })
                                // }
                              }}
                            />
                          ),
                          width: "70%"
                        }
                      });
                    }}

                    value={this.state.a1_kyokai_conversions.destination.exam_code}
                  />
                  <div style={{ marginLeft: '10px' }} >{this.state.a1_kyokai_conversions.destination.exam_name}</div>
                </Space>
              </Space>
            </Spin>
          </Card>
          <Button
            // disabled={this.state.execute}
            style={{ float: "right", marginTop: "10px" }}
            type="primary"
            onClick={() => {
              this.updateOrCreateRecord();
            }}
          >
            実 行
          </Button>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1181003_Copy);
