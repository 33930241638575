import { message } from "antd";
import IntroduceLetterIssuedMainService from "services/SK_IntroductionLetter/IntroduceLetterIssuedMain/IntroduceLetterIssuedMainService";

const IntroduceLetterIssuedMainAction = {
  GetScreenData(data) {
    return IntroduceLetterIssuedMainService.GetScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  loadTableData(data) {
    return IntroduceLetterIssuedMainService.loadTableData(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  createW2LetterOfIntroduceExtract(data) {
    return IntroduceLetterIssuedMainService.createW2LetterOfIntroduceExtract(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  Print_F12(data) {
    return IntroduceLetterIssuedMainService.Print_F12(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
      });
  },

  preview(data) {
    return IntroduceLetterIssuedMainService.preview(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
      });
  },

  changeDepartmentName(data) {
    return IntroduceLetterIssuedMainService.changeDepartmentName(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default IntroduceLetterIssuedMainAction;
