import React from "react";
import { connect } from "react-redux";

import { Card, Form, message, Select, Space, Table, Button, Row } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import FormCreateEditingService from "services/UserTools/FormCreateEditing/FormCreateEditingService";
import WS1568001_FileNameAcquisition from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1568001_FileNameAcquisition.jsx";
import WS1567009_OutputDestinationAcquisition from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1567009_OutputDestinationAcquisition.jsx";
import WS0400001_OptionalInputSub from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS0400001_OptionalInputSub.jsx";

class WS1567001_FormCreateEditing extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "帳票作成・編集";

    this.state = {
      isLoadingTable: false,
      tableData: [
        {
          key: '1',
          W1_file_name: 'TEST',
          W1_size_display: '1KB',
          W1_date_creation: '2022/02/14',
          W1_creation_time: '09:02',
          W1_update_date: '2022/02/14',
          W1_update_time: '09:02',
          W1_output_destination: 'テストテストテストテストテストテストテストテストテスト'
        },
        {
          key: '2',
          W1_file_name: 'TEST',
          W1_size_display: '1KB',
          W1_date_creation: '2022/02/14',
          W1_creation_time: '09:02',
          W1_update_date: '2022/02/14',
          W1_update_time: '09:02',
          W1_output_destination: 'テストテストテストテストテストテストテストテストテスト'
        },
        {
          key: '3',
          W1_file_name: 'TEST',
          W1_size_display: '1KB',
          W1_date_creation: '2022/02/14',
          W1_creation_time: '09:02',
          W1_update_date: '2022/02/14',
          W1_update_time: '09:02',
          W1_output_destination: 'テストテストテストテストテストテストテストテストテスト'
        },
        {
          key: '4',
          W1_file_name: 'TEST',
          W1_size_display: '1KB',
          W1_date_creation: '2022/02/14',
          W1_creation_time: '09:02',
          W1_update_date: '2022/02/14',
          W1_update_time: '09:02',
          W1_output_destination: 'テストテストテストテストテストテストテストテストテスト'
        },
        {
          key: '5',
          W1_file_name: 'TEST',
          W1_size_display: '1KB',
          W1_date_creation: '2022/02/14',
          W1_creation_time: '09:02',
          W1_update_date: '2022/02/14',
          W1_update_time: '09:02',
          W1_output_destination: 'テストテストテストテストテストテストテストテストテスト'
        },
        {
          key: '6',
          W1_file_name: 'TEST',
          W1_size_display: '1KB',
          W1_date_creation: '2022/02/14',
          W1_creation_time: '09:02',
          W1_update_date: '2022/02/14',
          W1_update_time: '09:02',
          W1_output_destination: 'テストテストテストテストテストテストテストテストテスト'
        },
      ],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    this.searchData(this.state.tableData);
  };

  searchData = (tableData) => {
    // this.setState({ isLoadingTable: true });
    // FormCreateEditingService.getTableDataService({
    //   Extension: this.formRef.current.getFieldValue("Expression_7"),
    // })
    // .then((res) => {
    //   this.formRef.current.setFieldsValue({
    //     getTableDataDisplayList: res.data,
    //   });
    //   this.forceUpdate();
    // })
    // .catch((err) => {
    //   const res = err.response;
    //   if (!res || !res.data || !res.data.message) {
    //     message.error("エラーが発生しました");
    //     return;
    //   }
    //   message.error(res.data.message);
    // })
    // .finally(() => {
    //   this.setState({ isLoadingTable: false });
    // });
    this.formRef.current.setFieldsValue({
      getTableDataDisplayList: tableData,
    });
  };

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  createProc = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: <WS1568001_FileNameAcquisition
          onFinishScreen={() => {
            this.closeModal()
          }}
          />
      },
    });
  }

  copyProc = (record) => {
    this.setState({ isLoadingTable: true })
    const temp = {
      ...record,
      key: String(this.state.tableData.length + 1),
    }
    let tableData = this.state.tableData
    tableData.push(temp)
    this.setState({ tableData, isLoadingTable: false })
    this.searchData(tableData)
  }

  deleteProc = (record) => {
    var temp = this.state.tableData
    var index = temp.indexOf()
  }

  outputDestination = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: <WS1567009_OutputDestinationAcquisition
          onFinishScreen={() => {
            this.closeModal()
          }}
          />
      },
    });
  }

  optionSub = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: <WS0400001_OptionalInputSub
          onFinishScreen={() => {
            this.closeModal()
          }}
          />
      },
    });
  }

  render() {
    return (
      <div className="form-create-editing">
        <Card title="帳票作成・編集" className="mb-2">
          <Space className="mb-3">
            <Button
              type="primary"
              style={{ marginRight: 4 }}
              onClick={this.createProc}>
              作成
            </Button>
            <Button
              style={{ marginRight: 4 }}
              type="primary"
              onClick={this.outputDestination}>
              出力先
            </Button>
            <Button
              type="primary"
              onClick={this.optionSub}>
              オッショーン
            </Button>
          </Space>
          <Form ref={this.formRef} initialValues={{ Expression_7: ".csv" }}>
            <Form.Item name="Expression_7" label="拡張子">
              <Select
                style={{ width: "150px" }}
                onSelect={() => this.searchData(this.state.tableData)}
              >
                <Select.Option value=".xlsx">通常</Select.Option>
                <Select.Option value=".csv">CSV</Select.Option>
              </Select>
            </Form.Item>
            <Table bordered 
              size="small"
              dataSource={this.state.tableData}
              rowKey={(record) => record.key}
              loading={this.state.isLoadingTable}
              onRow={(record, rowIndex) => {
                return {
                  // double click row
                  onDoubleClick: () => {
                    console.log("record", record);
                  },
                };
              }}
            >
              <Table.Column title="帳票名" dataIndex="W1_file_name" />
              <Table.Column title="サイズ" dataIndex="W1_size_display" />
              <Table.Column
                title="作成日時"
                dataIndex=""
                render={(text, record, index) => (
                  <Space>
                    {record.W1_date_creation}
                    {record.W1_creation_time}
                  </Space>
                )}
              />
              <Table.Column
                title="更新日時"
                dataIndex=""
                render={(text, record, index) => (
                  <Space>
                    {record.W1_update_date}
                    {record.W1_update_time}
                  </Space>
                )}
              />
              <Table.Column title="ＣＳＶ出力先" dataIndex="W1_output_destination" />
              <Table.Column
                title="アクション"
                dataIndex=""
                render={(text, record, index) => (
                  <Row>
                    <Button
                      style={{ marginRight: 10 }}
                      type="error"
                      onClick={() => {
                        this.deleteProc(record)
                      }}>
                      削除
                    </Button>
                    <Button
                      type="error"
                      onClick={() => {
                        this.copyProc(record)
                      }}>
                      複製
                    </Button>
                  </Row>
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1567001_FormCreateEditing);
