import { message } from "antd";
import VisitsCancelConfirmService from "services/Others/CreateTestForMedicalExamInfo/VisitsCancelConfirmService";


// export const eventAcceptedCancellation = (params) => {
//   console.log('テスト・アクション')
//   return VisitsCancelConfirmService.eventAcceptedCancellation(params)
// };

const VisitsCancelConfirmAction = {
  eventAcceptedCancellation(params) {
    return VisitsCancelConfirmService.eventAcceptedCancellation(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default VisitsCancelConfirmAction;