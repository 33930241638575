import axios from 'configs/axios'

const apiPaths = {
  index: '/api/personal-number-migration/visit-history-cal-query',
}

const VisitHistoricalQueryService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  }
}
export default VisitHistoricalQueryService
