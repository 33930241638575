
import { message } from "antd";
import ConvertTblReplicationProcessService from "services/SystemMaintenance/UserParamMaintain/ConvertTblReplicationProcessService"


const ConvertTblReplicationProcessAction = {
  getscreendata(params) {

    return ConvertTblReplicationProcessService.getscreendata(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  convertTableReplicateRegister(data) {

    return ConvertTblReplicationProcessService.convertTableReplicateRegister(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.message)
        throw err
      })
  },




};

export default ConvertTblReplicationProcessAction;
