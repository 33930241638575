import axios from 'configs/axios'

const API_LIST = {
  getjudgeQuery: '/api/person-require-exam-introduce-letter/judge-query',
}

const PersonRequireExamIntroduceLetterService = {

  async getjudgeQuery(params) {
    return axios.get(API_LIST.getjudgeQuery, { params })
  }
}

export default PersonRequireExamIntroduceLetterService
