import axios from "configs/axios";

const apiPaths = {
  deleteIncorrectData: "/api/recovery-processes/recovery-processes/delete-incorrect-data",
  getDate: "/api/recovery-processes/recovery-processes/get-date",
  inspectToFindingInfoChange: "/api/recovery-processes/recovery-processes/inspect-to-finding-info-change",
  changePastInspectCode: "/api/recovery-processes/recovery-processes/change-past-inspect-code",
};

const RecoveryProcessesService = {
  async deleteIncorrectData(params) {
    return axios.post(apiPaths.deleteIncorrectData, params);
  },
  async getDate(params) {
    return axios.get(apiPaths.getDate, {params});
  },
  async inspectToFindingInfoChange(params) {
    return axios.post(apiPaths.inspectToFindingInfoChange, params);
  },
  async changePastInspectCode(params) {
    return axios.post(apiPaths.changePastInspectCode, params);
  },
};

export default RecoveryProcessesService;