import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Tree, Table, Row, Col, Menu, Dropdown, Modal, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";

import { MoreOutlined, SyncOutlined, PlusCircleOutlined, DeleteOutlined, SwitcherOutlined, FolderOpenOutlined } from "@ant-design/icons";
import WS1512074_AddItem from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512074_AddItem.jsx';
import WS1512066_ItemModification from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512066_ItemModification.jsx';
import WS1512135_AddCode from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512135_AddCode.jsx';
import WS1512138_DirectEditing from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512138_DirectEditing.jsx';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import OptionalInfoMaintainAction from 'redux/SM_SystemMaintenance/OptionalInfoMaintain/OptionalInfoMaintain.action'

import './WS1512001_OptionalInfoMaintain.scss'

const style = {
  btm: {
    paddingBottom: '10px',
    marginBottom: '10px',
    borderBottom: '1px solid #d9d9d9',
  },

  icon: {
    margin: '5px',
    padding: '5px',
    cursor: 'pointer',
    fontSize: '18px'
  }

}
class WS1512001_OptionalInfoMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'オプション情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      treeData: [],
      options: [],
      selectoption: '',
      optionlist: [],
      selectedNodes: [],
      dataSource: [],
      rowSelect: [],
      isLoadingTable: false,
    };
  }

  componentDidMount() {
    this.loadScreenData()
  }

  loadScreenData() {
    const params = {}
    OptionalInfoMaintainAction.loadScreenData()
      .then(res => {
        this.setState({
          options: res.data,
        })
        console.log(this.state.options)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  loadTreeView() {
    const params = {}
    VenusApiRoutesV2.callApi("API001512001002", params)
      .then(res => {
        console.log(res.data)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  onClickSearch = () => {
    console.log(this.state)
    const params = {
      type_code: this.state.selectoption,
    }
    OptionalInfoMaintainAction.searchOption(params)
      .then(res => {
        this.setState({
          optionlist: res,
        })
        console.log(res)
        console.log(this.state.optionlist)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  loadDataFormTable(values) {
    this.formRefMain.current.setFieldsValue({
      dataTable: [],
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onSelectNode = (selectedKeys, info) => {
    this.setState({
      selectedNodes: info.selectedNodes
    })
  };

  renderTableParent() {
    return (
      <Table bordered
        dataSource={this.state.dataSource}

        loading={this.state.isLoadingTable}
        pagination={this.state.dataSource?.length > 10 ? true : false}
        rowKey={(record) => record.id}
      >
        <Table.Column title="識別" dataIndex="Expression_9" width={80} />
        <Table.Column title="ｺｰﾄﾞ" dataIndex="W1_option_cd" width={150} />
        <Table.Column title="名称" dataIndex="Expression_11" />
        <Table.Column title="説明" dataIndex="additional_explanation" />
      </Table>
    )
  }

  renderTableChildren() {
    return (
      <div>
        <Table bordered
          dataSource={this.state.dataSource}

          loading={this.state.isLoadingTable}
          pagination={this.state.dataSource?.length > 10 ? true : false}
          rowKey={(record) => record.id}
        >
          <Table.Column title="項目" dataIndex="W2_item" width={'20%'} />
          <Table.Column title="種類" dataIndex="Division" width={70} />
          <Table.Column title="内容" dataIndex="Setting"
            render={(value, record, index) => {
              return (
                <Form.Item name={["dataTable", index, "Setting"]}>
                  <Input.Search type="text"
                    onSearch={() => { }}
                  />
                </Form.Item>
              )
            }}
          />
          <Table.Column title="説明" dataIndex="Expression_15" />
          <Table.Column width={70}
            render={(value, record, index) => {
              return (
                <Dropdown
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: "60%",
                              visible: true,
                              component: (
                                <WS1512066_ItemModification
                                  Li_ItemName={''}
                                  Li_ItemLimit={''}
                                  Li_ItemDescription={''}
                                  Li_SupplementaryExplanation={''}
                                  Li_SelectSection={''}
                                  Lio_ItemDescription={''}
                                  onFinishScreen={() => {
                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      >
                        変更
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: "60%",
                              visible: true,
                              component: (
                                <WS1512074_AddItem
                                  onFinishScreen={() => {
                                    this.closeModal();
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      >
                        新規
                      </Menu.Item>
                    </Menu>
                  )} >
                  <Button icon={<MoreOutlined />}></Button>
                </Dropdown>
              )
            }}
          />
        </Table>
        <br></br>
        <Form.Item name="Remarks">
          <TextArea rows={3} />
        </Form.Item>
      </div>
    )
  }


  onFinish(values) { }

  render() {
    const { treeData, selectedNodes } = this.state
    return (
      <div className="optional-info-maintain">
        <Card className="mb-2" title="オプション情報保守">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Row gutter={24} className="mb-4">
              <Col span={10}>
                <Form.Item name="DistinguishedNameSearch" style={{ marginBottom: 0, cursor: 'pointer' }}>
                  <Input autoComplete="off" list="list" onChange={(e) => {
                    this.setState({
                      selectoption: e.target.value
                    })
                  }} />
                  <datalist id="list" size="20" style={{ width: 100 }} >
                    {this.state.options?.map((option, i) => (
                      <option
                        key={i}
                        value={option.type_code}
                      />
                    ))}
                  </datalist>
                </Form.Item>
              </Col>
              <Col span={10} style={{ paddingLeft: 0 }}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button type="primary"
                    onClick={() => this.onClickSearch()}
                  >検索</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={10}>
                <div>
                  <i style={style.icon}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component:
                            <WS1512135_AddCode
                              Li_Type={selectedNodes[0]?.W1_type_cd}
                              onFinishScreen={(output) => {
                                this.closeModal()
                              }}
                            />
                          ,
                        },
                      });
                    }}
                  ><SyncOutlined /></i>
                  <i style={style.icon}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component:
                            <WS1512138_DirectEditing
                              Li_Type={selectedNodes[0]?.W1_type_cd}
                              Li_OptionCode={selectedNodes[0]?.W1_option_cd}
                              onFinishScreen={(output) => {
                                this.closeModal()
                              }}
                            />
                          ,
                        },
                      });
                    }}
                  ><PlusCircleOutlined /></i>
                  <i style={style.icon}
                    onClick={() => { }}
                  ><DeleteOutlined /></i>
                  <Col style={{ marginRight: "10px" }}>
                    <Table bordered size='small'
                      dataSource={this.state.optionlist}
                      scroll={{ y: resizableTableScroll(80) }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            console.log("record", record);

                          },
                        }
                      }}
                    >
                      <Table.Column title="" dataIndex="id_name" key="" />
                    </Table>
                  </Col>
                </div>
                <div className="card-custom">
                  <div className="scrollbar" style={{ overflow: 'auto', height: '500px' }}>
                    <Tree
                      defaultExpandAll
                      defaultSelectedKeys={[treeData[0]?.key]}
                      treeData={treeData}
                      onSelect={this.onSelectNode}
                    />
                  </div>
                </div>
              </Col>
              <Col span={14} style={{ paddingLeft: 0 }}>
                <div>
                  {selectedNodes[0]?.children && selectedNodes[0].children.length > 0
                    ? this.renderTableParent()
                    : this.renderTableChildren()}
                </div>
              </Col>
            </Row>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1512001_OptionalInfoMaintain);
