import axios from "configs/axios";

const apiPaths = {
  getSetIncludesQuery: "/api/personal-reserve-process/set-includes-query",
  onSave: "/api/personal-reserve-process/on-save",
  onDelete: "/api/personal-reserve-process/on-delete",
};

const SetIncludesQueryService = {
  async getSetIncludesQuery(params) {
    return axios.get(apiPaths.getSetIncludesQuery, { params });
  },
  async onSave(params) {
    return axios.post(apiPaths.onSave, params);
  },
  async onDelete(params) {
    return axios.delete(apiPaths.onDelete, { params });
  },
};

export default SetIncludesQueryService;
