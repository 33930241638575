import { message } from 'antd'
import PrinterConfirmService from 'services/IntroductionLetter/IntroduceLetterMasterMaintain/PrinterConfirmService'
const PrinterConfirmAction = {
  index() {
    return PrinterConfirmService.index()
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  print(data) {
    return PrinterConfirmService.print(data)
  },
  preview(data) {
    return PrinterConfirmService.preview(data)
  },

}

export default PrinterConfirmAction
