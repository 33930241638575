import { message } from "antd";
import RecoveryProcessesService from "services/Others/RecoveryProcesses/RecoveryProcessesService";


const RecoveryProcessesAction = {
  deleteIncorrectData(data) {
    return RecoveryProcessesService.deleteIncorrectData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  getDate(data) {
    return RecoveryProcessesService.getDate(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  inspectToFindingInfoChange(data) {
    return RecoveryProcessesService.inspectToFindingInfoChange(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  changePastInspectCode(data) {
    return RecoveryProcessesService.changePastInspectCode(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default RecoveryProcessesAction;