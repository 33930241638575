import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import axios from "configs/axios";
import { Card, Checkbox, Form, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import { SearchOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import WS2650016_ReserveChange from "./WS2650016_ReserveChange";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";
import WS2653001_GuideInputProvenSub from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653001_GuideInputProvenSub.jsx";
import WS1423001_SupportPlanManualCreate from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1423001_SupportPlanManualCreate.jsx";
import WS2657078_VouchersInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2657078_VouchersInput.jsx";
import InsureGuideInitInputAction from "redux/SpecificInsureGuide/InsureGuideInitInput/InsureGuideInitInput.actions"
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

/** Program [2650] InsureGuideInitInput 保健指導初回入力 Prg_5359.xml */
class WS2650001_InsureGuideInitInput extends React.Component {
  constructor(props) {
    super(props);
    // document.title = "請求書発行";
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        // defaultPageSize: 10,
        size: 1,
        pageSize: 10,
        defaultCurrent: 1,
        showQuickJumper: false,
      },
      listID: [],
      searchForm: {
        Li_FirstDateF: moment(new Date()).format("YYYY/MM/DD"),
        Li_FirstDateT: moment(new Date()).format("YYYY/MM/DD"),
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      initObj: {},
      initParams: {
        ClaimYearsChar: '',
        ClaimNum: '',
        Outstanding: 2,
        Target: 0,
        ClaimIdentify: 0,
        InsurerNum: '',
        OfficeNum: '',
        BranchStoreCode: '',
        AddressSearch: '',
        StsSelectAll: '',
        ClaimYears: ''
      },
    }
  }

  callAPILoadData() {
    this.setState({ isLoading: true });
    let params = this.state.searchForm
    InsureGuideInitInputAction.getTargetList(params)
      .then((res) => {
        this.setState({ dataSource: res })
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  createNew() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        component: (
          <WS2650016_ReserveChange
            Li_FirstDate={null}
            Li_PersonalNum={null}
            newFlag={true}
            onFinishScreen={() => {
              this.closeModal()
              this.callAPILoadData()
            }}
          />
        ),
      },
    });
  }

  edit(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2650016_ReserveChange
            Li_FirstDate={record.date_on}
            Li_PersonalNum={record.personal_number_id}
            Li_SerialNum={record.serial_number}
            Course={record.insure_guide_course_code}
            Li_implementation={record.Expression_27}
            newFlag={false}
            onFinishScreen={() => {
              this.callAPILoadData()
            }}
          />
        ),
      },
    });
  }

  more(PersonalCode) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={PersonalCode}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  voucherInput(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 750,
        component: (
          <WS2657078_VouchersInput
            Li_Date={moment(record.date_on).format("YYYY/MM/DD")}
            Li_PersonalNum={record.personal_number_id}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  delete(record) {
    const { date_on, personal_number_id } = record;
    Modal.confirm({
      content: "消去してもよろしいですか？",
      okText: "はい",
      cancelText: "いいえ",
      onOk: () => {
        this.setState({ isLoading: true });
        let params = {
          date_on: date_on,
          personal_number_id: personal_number_id
        }
        InsureGuideInitInputAction.delete(params)
          .then((res) => {
            this.callAPILoadData();
          })
          .finally(() => this.setState({ isLoading: false }))
      }
    });
  }

  changeSearchForm(searchField, value) {
    let searchForm = { ...this.state.searchForm };
    searchForm[searchField] = value;
    this.setState({ ...this.state, searchForm });
  }

  plan(record) {
    console.log(record);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1423001_SupportPlanManualCreate
            Li_FirstDate={record.date_on}
            Li_Id={record.personal_number_id}
            Li_SerialNum={record.serial_number}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  input(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS2653001_GuideInputProvenSub
            Li_Date={record.date_on}
            Li_PersonalNum={record.personal_number_id}
            Li_SerialNum={record.serial_number}
            kanji_name={record.kanji_name}
            record={record}
            onFinishScreen={() => {
              this.callAPILoadData();
            }}
          />
        ),
      },
    });
  }

  render() {
    return (
      <div className='invoice'>
        <Card className="mb-2" title='保健指導初回入力'>
          <div className="box_inner_vertical">
            <div className="box_inner_horizontal">
              <Space>
                <div style={{ marginTop: '5px' }}>初回日</div>
                <VenusDatePickerCustom format="YYYY/MM/DD" value={this.state.searchForm.Li_FirstDateF} onChange={val => this.changeSearchForm("Li_FirstDateF", moment(val).format("YYYY/MM/DD"))} />
                <div style={{ marginTop: '5px' }}>～</div>
                <VenusDatePickerCustom format="YYYY/MM/DD" value={this.state.searchForm.Li_FirstDateT} onChange={val => this.changeSearchForm("Li_FirstDateT", moment(val).format("YYYY/MM/DD"))} />
              </Space>
              <Button
                icon={<SearchOutlined />}
                onClick={() => this.callAPILoadData()}
              >
                検　索
              </Button>
            </div>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
              onRow={(record, index) => {
                return {
                  onDoubleClick: () => this.voucherInput(record),
                }
              }}
            >
              <Table.Column title="初回日" width={120} dataIndex="date_on" showSorterTooltip={false} />
              <Table.Column title="個人番号" width={100} dataIndex="personal_number_id" showSorterTooltip={false} />
              <Table.Column title="メモ" width={50} align='center'
                render={(value, record) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => this.more(record.personal_number_id)}
                    />
                  )
                }} />
              <Table.Column title="氏名" dataIndex="kanji_name" showSorterTooltip={false} />
              <Table.Column width={120} title="保険者番号" dataIndex="insurer_number" showSorterTooltip={false} />
              <Table.Column title="区分" dataIndex="Expression_9" />
              <Table.Column title="コース情報" dataIndex="Expression_11" />
              <Table.Column width={60} title="実施" dataIndex="Expression_27" align='center' />
              <Table.Column width={60} title="利用券" dataIndex="Expression_29" align='center' />
              <Table.Column width={60} title="決済" dataIndex="Expression_31" align='center' />

              <Table.Column
                width={40}
                title={
                  <Button
                    type='primary'
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => this.createNew()} />
                }
                render={(value, record) => {
                  return (
                    <Dropdown
                      icon={<MoreOutlined />}
                      trigger='click'
                      overlay={() => (
                        <Menu >
                          <Menu.Item key="menu-2" onClick={() => this.edit(record)}>修正</Menu.Item>
                          <Menu.Item key="menu-3" onClick={() => this.delete(record)}>削除</Menu.Item>
                          <Menu.Item key="menu-4" onClick={() => this.plan(record)}>計画</Menu.Item>
                          <Menu.Item key="menu-5" onClick={() => this.voucherInput(record)}>利用券・契約</Menu.Item>
                          <Menu.Item key="menu-6" onClick={() => this.input(record)}>入力</Menu.Item>
                        </Menu>
                      )}>
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )
                }} />
            </Table>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>

    );
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const { Search } = Input;

const onSearch = value => console.log(value);

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WS2650001_InsureGuideInitInput)
