import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Card, Table, } from "antd";
import ItemCheckupsForXmlAction from "redux/SpecificInsureMaintenance/XmlParamMaintain/ItemCheckupsForXml.action";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1310002_ItemCheckupsForXml extends React.Component {

  static propTypes = {
    InkIoitemCode: PropTypes.any,
    InkIoresultIdentify: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'XML用健診項目';

    this.state = {
      dataSource: [],
      isLoadingTable: false
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    this.setState({ isLoadingTable: true });

    VenusApiRoutesV2.callApi("API001310002002", {})
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
          isLoadingTable: false
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  render() {
    return (
      <div className="item-checkups-for-xml">
        <Card title="XML用健診項目">
          <Table bordered 
            size='small'
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}            
            pagination={true}
            rowKey={(record) => record.id}
          >
            <Table.Column title="順番号" dataIndex="order_number" showSorterTooltip={false} sorter={(a, b) => a.order_number - b.order_number}/>
            <Table.Column title="項目コード" dataIndex="item_code_jlac10_15" showSorterTooltip={false} sorter={(a, b) => a.item_code_jlac10_15?.localeCompare(b.item_code_jlac10_15, 'jp')}/>
            <Table.Column title="結果識別" dataIndex="result_identification_jlac10_2" showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2?.localeCompare(b.result_identification_jlac10_2, 'jp')}/>
            <Table.Column title="厚労省項目名" dataIndex="ministry_item_name" showSorterTooltip={false} sorter={(a, b) => a.ministry_item_name?.localeCompare(b.ministry_item_name, 'jp')}/>
            <Table.Column title="方法コード" dataIndex="xml_exam_method_code" showSorterTooltip={false} sorter={(a, b) => a.xml_exam_method_code?.localeCompare(b.xml_exam_method_code, 'jp')}/>
            <Table.Column title="検査方法" dataIndex="exam_methods" showSorterTooltip={false} sorter={(a, b) => a.exam_methods?.localeCompare(b.exam_methods, 'jp')}/>
            <Table.Column title="ＸＭＬ表示名" dataIndex="xml_display_name" showSorterTooltip={false} sorter={(a, b) => a.xml_display_name?.localeCompare(b.xml_display_name, 'jp')}/>
            <Table.Column title="ﾃﾞｰﾀﾀｲﾌﾟ" dataIndex="data_type" showSorterTooltip={false} sorter={(a, b) => a.data_type?.localeCompare(b.data_type, 'jp')}/>
            <Table.Column width={80}
              render={(value, record, index) => {
                return (
                  <Button type='primary'
                    onClick={() => {
                      if (this.props.onFinishScreen) {
                        this.props.onFinishScreen({
                          InkIoitemCode: record.item_code_jlac10_15,
                          InkIoresultIdentify: record.result_identification_jlac10_2,
                          InkIoexamMethod: record.exam_methods,
                          InkIoministryItemName: record.ministry_item_name,
                        });
                      }
                    }}
                  >選択</Button>
                )
              }}
            />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1310002_ItemCheckupsForXml);
