import React from 'react'
import { Card, Table, Button } from 'antd'
import { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub'
class WS0339502_InsurerOfficeInfo extends React.Component {

  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  componentDidMount = () => {
    console.log(this.props.insurerData.offices);
    this.setState({
      dataSource: this.props.insurerData.offices
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: ''
      }
    })
  }

  render() {
    return (
      <div className='insurer-info-maintenance'>
        <Card title='事業所一覧'>
          <Table
            bordered
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={record => record.id}
            size='small'
            scroll={{ y: 400 }}
          >
            <Table.Column
              width={100}
              title='事業所'
              dataIndex='office_code'
              render={(value) => {
                const text = (value === '0' || value === 0) ? '' : value
                return <div style={{ textAlign: 'right' }}>{text}</div>
              }}
            />
            <Table.Column
              width={70}
              title='支店'
              dataIndex='branch_store_code'
              render={(value) => {
                const text = (value === '0' || value === 0) ? '' : value
                return <div style={{ textAlign: 'right' }}>{text}</div>
              }}
            />
            <Table.Column
              width={40}
              title='メモ'
              dataIndex='office_importance'
              render={(value, record, index) => {
                let icon = null
                switch (value) {
                  case 1:
                    icon = <InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />
                    break
                  case 3:
                    icon = <WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />
                    break
                  case 5:
                    icon = <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
                    break
                  default:
                    icon = (<Button size='small' icon={<MoreOutlined />}></Button>)
                    break
                }
                return (
                  <div style={{ textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1200,
                          component: (
                            <WS2585001_OfficeInfoInquirySub
                              Li_OfficeCode={record.office_code}
                              Li_BranchCode={record.branch_store_code}
                              onClick={() => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}>
                    {icon}
                  </div>
                )
              }} />
            <Table.Column
              title='名称'
              dataIndex='office_kanji_name' />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
} export default WS0339502_InsurerOfficeInfo
