import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Button, Table, Form, Tooltip } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import TentativeReserveAction from 'redux/ReservationBusiness/SANAI_TentativeReserve/TentativeReserve.action.js'
import moment from 'moment-timezone'

const formatDate = 'YYYY/MM/DD'

class TimeSelectDisplay extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '予約空き状況'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      periodTimes: [],
      showTable: false,

      selectedRows: [],
      selectedReserveTime: null,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 日付設定
    this.formRef.current.setFieldsValue({ DateChar: this.props.Li_Date ?? '' })
    this.index(true)
  }

  /**
   * 空き状況のデータを取得
   * @param {*} params
   */
  index = (initFlag = false) => {
    const params = {
      Li_Date: moment(this.formRef.current.getFieldValue('DateChar')).format(formatDate),
      initFlag: initFlag ? 1 : 0
    }

    TentativeReserveAction.getAvailableTime(params)
      .then(res => {
        if (res) {
          // 受付時間帯
          const periodData = [...res.periodTimes]
          this.setState({
            periodTimes: periodData,
          })
        }
      })
  }

  /**
   * 確定ボタン押下処理
   */
  confirm = () => {
    const date = moment(this.formRef.current.getFieldValue('DateChar'))
    let obj = {
      // dateChar: date.format(formatDate),
      timeZone: this.state.selectedReserveTime,
      // selectedRows: this.state.selectedRows
    }
    if (this.props.onFinishScreen) {
      // 親画面に受付時間と検査ごとの時間を返却
      this.props.onFinishScreen(obj)
    }
  }

  /**
   * 前日
   */
  previousDay = () => {
    const date = this.formRef.current.getFieldValue('DateChar')
    // 前日に設定
    this.formRef.current.setFieldsValue({
      DateChar: moment(date).add(-1, 'days').format(formatDate)
    })
    this.index()
  }

  /**
   * 翌日
   */
  nextDay = () => {
    const date = this.formRef.current.getFieldValue('DateChar')
    // 翌日に設定
    this.formRef.current.setFieldsValue({
      DateChar: moment(date).add(1, 'days').format(formatDate)
    })
    this.index()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='time-select-display' style={{ width: 'fit-content' }} >
        <Card
          title={'予約空き状況'}
          style={{ minWidth: 280 }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div
              className='box_border'
              style={{ minWidth: 245, maxWidth: 245, gap: 10 }}
            >
              <div>受付をする時間帯</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  height: 380,
                  gap: 10
                }}>
                {this.state.periodTimes.map((item, index) => {
                  // 縦に並ぶボタンのラベル開始位置を揃える為、空白を設定
                  // もっといい方法があれば、そっちにする
                  let label = ''
                  let others = item.othersFlag ? '　　' : ''
                  if (item.othersCount > 0) {
                    // 保留、キャンセルの件数
                    others = '(' + item.othersCount + ')'
                  }

                  //フロントの状況を考慮した予約済み人数の算出
                  let allMembers = item.reserveCount
                  this.props.dataSource.map((element) => {
                    if (item.time == element.period_time) {
                      allMembers++
                    }
                  })

                  // 時間と予約済み件数を表示する
                  label = item.time + '　' + allMembers + '人 ' + others
                  return (
                    <Button
                      key={'btn-' + index}
                      type={(this.state.selectedReserveTime === item.time) ? 'primary' : 'default'}
                      style={{ fontWeight: (this.state.selectedReserveTime === item.time) ? 'bold' : 'normal' }}
                      onClick={() => {
                        this.setState({ selectedReserveTime: item.time })
                      }}
                    >
                      {label}
                    </Button>
                  )
                })}
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                this.index()
              }}
            >
              再取得
            </Button>
            <Button
              type='primary'
              disabled={!(this.state.selectedReserveTime)}
              onClick={() => {
                if (this.props.onFinishScreen) {
                  this.confirm()
                }
              }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeSelectDisplay)
