import axios from "configs/axios";

const APP_LIST = {
  execCapture:"/api/xml-medical-exam-item-master/csv-capture-screen/csv-exec-capture",
};

const CsvCaptureScreenService = {
  async execCapture(params){
    return axios.get(APP_LIST.execCapture, params );
  },
};

export default CsvCaptureScreenService;
