import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'

import { Card, Form, Radio, Button, Input, message, } from 'antd';
import WS0247001_OfficeInfoRetrievalQuery from '../V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery';
import WS0246001_InsurerInfoSearchQuery from '../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery';
import ModalDraggable from "components/Commons/ModalDraggable";
import CreateNewAction from "redux/basicInfo/ContractInfoMaintain/CreateNew.actions"

/**
* @extends {React.Component<{Li_ContractType:number.isRequired, Li_ContractOrgCode:any.isRequired, onFinishScreen:Function}>}
*/
class WS0306011_CreateNew extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.number,
    Li_ContractOrgCode: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '新規作成';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isCreateSending: false,
    };
  }

  componentDidMount() {
    this.yearProcess()

  }

  yearProcess(param) {
    let value = {
      value: param ?? ''
    }

    console.log(param);

    CreateNewAction.yearProcess(value)
      .then((res) => {
        if (res) {
          this.formRef.current.setFieldsValue({
            YearChar: res.startYear,
            japaneseCalendar: res.japaneseCalendar,
          })
        }
        this.forceUpdate()
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.formRef.current.resetFields();
      this.forceUpdate();
    }
  }

  onFinish = values => {
    this.setState({
      isCreateSending: true,
    });
    let params = {
      ContractType: values.ContractType,
      ContractOrgCode: values.ContractOrgCode,
      Year: values.YearChar,
    }
    CreateNewAction.CreateBtn(params)
      .then((res) => {
        if (this.props.onCreated) {
          this.props.onCreated(values);
        }
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen(values);
        }
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({
          isCreateSending: false,
        });
      });
  };

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    const action = 'create';

    return (
      <div className="create-new">
        <Card title={[this.props.newFlag === true ? "新規作成" : '年度更新']}>
          <Form
            autoComplete='off'
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              ContractType: this.props.Li_ContractType || 0,
              ContractOrgCode: this.props.Li_ContractOrgCode || (!this.props.Li_ContractType ? 0 : null),
            }}
          >
            <Form.Item name="ContractType">
              <Radio.Group onChange={(e) => {
                this.formRef.current.setFieldsValue({
                  ContractOrgCode: e.target.value === 0 ? 0 : null,
                  Expression_8: ''
                });
                this.forceUpdate();
              }}>
                <Radio value={2}>事業所</Radio>
                <Radio value={1}>保険者</Radio>
                <Radio value={0}>共通</Radio>
              </Radio.Group>
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                label="団体"
                name="ContractOrgCode"
                style={{ maxWidth: 200 }}
                rules={[{ required: true }]}
              >
                <Input.Search
                  disabled={this.formRef.current?.getFieldValue('ContractType') === 0}
                  onSearch={(value) => {
                    const ContractType = this.formRef.current.getFieldValue('ContractType');
                    let component = null;
                    if (ContractType === 1) {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1200,
                          component: (
                            <WS0246001_InsurerInfoSearchQuery
                              Li_insurerSearch={value}
                              onFinishScreen={({ Lo_InsurerCode, Lo_Name }) => {
                                this.formRef.current.setFieldsValue({
                                  ContractOrgCode: Lo_InsurerCode,
                                  Expression_8: Lo_Name
                                })
                                this.closeModal()
                              }}
                            // onFinishScreen={(output) => {
                            //   this.formRef.current.setFieldsValue({
                            //     ContractOrgCode: output.Lo_InsurerCode,
                            //     Expression_8: output.recordData.insurer_kanji_name,
                            //   })
                            // }}
                            />
                          )
                        }
                      })
                    } else if (ContractType === 2) {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1200,
                          component: (
                            <WS0247001_OfficeInfoRetrievalQuery
                              Lio_OfficeCode={value}
                              onFinishScreen={(output) => {
                                this.formRef.current.setFieldsValue({
                                  ContractOrgCode: output.Lio_OfficeCode,
                                  Expression_8: output.recordData.office_kanji_name
                                })
                                this.closeModal()
                              }}
                            // onFinishScreen={(output) => {
                            //   this.formRef.current.setFieldsValue({
                            //     ContractOrgCode: output.Lio_OfficeCode,
                            //     Expression_8: output.recordData.office_kanji_name,
                            //   })
                            // }}
                            />
                          )
                        }
                      })
                    }

                    if (component) {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          width: 800,
                          visible: true,
                          component: component,
                        }
                      });
                    }
                  }}
                />
              </Form.Item>
              <div style={{ marginTop: '5px', marginLeft: '5px' }}>
                {this.formRef.current ? this.formRef.current.getFieldValue('Expression_8') : ''}
              </div>
            </div>

            <div className='box_inner_horizontal'>
              <Form.Item label="年度" name="YearChar" rules={[{ required: true }]}  >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  style={{ width: 200 }}
                  onChange={(e) => {
                    if (e) {
                      let date = moment(e).format('YYYY/MM/DD')
                      this.yearProcess(date)
                    } else {
                      this.formRef.current.setFieldsValue({
                        japaneseCalendar: ''
                      })
                      this.forceUpdate()
                    }

                  }}
                />
              </Form.Item>
              <div style={{ marginTop: '5px', marginLeft: '5px' }}>
                {this.formRef.current ? this.formRef.current.getFieldValue('japaneseCalendar') : ''}
              </div>
            </div>

            <Form.Item style={{ float: "right" }}>
              <Button type="primary" htmlType="submit" loading={this.state.isCreateSending}>{action === "create" ? "作成" : "年度"}</Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS0306011_CreateNew;
