import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Row,
  Col,
  Input,
  Form,
  Card,
  Button,
  Table,
  Select,
  Tabs,
  Modal,
  Spin,
  message,
  Checkbox,
  Space,
  Tag,
  Tooltip,
  Dropdown,
  Menu
} from "antd";
import { debounce } from "lodash";

import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx";
import WS0247001_OfficeInfoRetrievalQuery from "./WS0247001_OfficeInfoRetrievalQuery.jsx";
import WS0341008_BranchShopInquiry from "./WS0341008_BranchShopInquiry.jsx";
import WS0341009_BranchStoreGenerated from "./WS0341009_BranchStoreGenerated.jsx";
import WS0308017_OrganizationCreateNew from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0308017_OrganizationCreateNew.jsx";
import WS0265001_BasicCourseInquiry from "../V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import WS2584019_PersonalInfoInquirySub from "../../KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";
import WS0246001_InsurerInfoSearchQuery from "../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0307008_ContractHistorySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx";
import { history } from "constants/BrowserHistory";
import {
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  MenuOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import OfficeInfoMaintainService from "services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js";
import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import PropTypes from "prop-types";
import WS0344001_SupplementalInfoSetting from "../V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx";
import Color from "constants/Color.js";
import * as wanakana from "wanakana";


class WS0341506_ResultTableEdit extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0
    };
  }

  componentDidMount = () => {
    if (!this.props.newFlag) {
      this.formRef.current?.setFieldsValue({
        medical_exam_course: this.props.record.medical_exam_course ?? '',
        standard_printing_style_1: this.props.record.standard_printing_style_1 ?? '',
        format_name_1: this.props.record.format_name_1 ?? '',
      })
    }
    this.forceUpdate()
  }

  saveResultTableEdit = () => {
    this.props.onUpdate({
      Li_medical_exam_course: this.formRef.current?.getFieldValue('medical_exam_course'),
      Li_standard_printing_style_1: this.formRef.current?.getFieldValue('standard_printing_style_1'),
      Li_format_name_1: this.formRef.current?.getFieldValue('format_name_1'),
    })
  }

  deleteResultTableEdit = () => {
    this.props.onDelete({
      Li_id: this.props.record.id
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  render() {
    return (
      <div className="result-table-edit">
        <Card title={this.props.newFlag ? '新規' : '変更'}>
          <Form
            ref={this.formRef}
            onValuesChange={this.handleFormChange}
            autoComplete='off'
          // onFinish={() => this.saveResultTableEdit()}
          >
            <label>コース</label>
            <Form.Item
              name="medical_exam_course"
              className="column-size-10"
              rules={[{ required: true }]}
            >
              <Input.Search
                onSearch={() =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 600,
                      component: (
                        <WS0265001_BasicCourseInquiry
                          onFinishScreen={({ Lo_CourseCode }) => {
                            this.formRef.current?.setFieldsValue({
                              medical_exam_course: Lo_CourseCode
                            })
                            // record.medical_exam_course = Lo_CourseCode;
                            this.closeModal();
                          }}
                        />
                      )
                    }
                  })
                }
              />
            </Form.Item>
            <label>独自様式</label>
            <div className="box_inner_horizontal">
              <Form.Item
                name="standard_printing_style_1"
                // style={{ width: "100px" }}
                className="column-size-10"
              >
                <Input.Search
                  onSearch={() =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 600,
                        component: (
                          <WS0286001_PrintStyleInquiry
                            onFinishScreen={({ Lio_StyleCode, Lo_FormatName }) => {
                              this.formRef.current?.setFieldsValue({
                                standard_printing_style_1: Lio_StyleCode,
                                format_name_1: Lo_FormatName
                              })
                              // record.standard_printing_style_1 = Lio_StyleCode;
                              // record.format_name_1 = Lo_FormatName;
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                name='format_name_1'
              >
                <div>{this.formRef.current?.getFieldValue('format_name_1')}</div>
              </Form.Item>
            </div>
          </Form>
          <div className="box_button_bottom_right">
            <Button
              danger
              type='primary'
              style={{ display: (this.props.newFlag) ? 'none' : '' }}
              icon={<DeleteOutlined />}
              onClick={() => {
                // 親画面に反映する
                this.deleteResultTableEdit()
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  // 親画面に反映
                  this.saveResultTableEdit()
                }}
              // htmlType="submit"
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </Form.Item>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    )
  }
}
export default WS0341506_ResultTableEdit