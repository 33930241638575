import axios from 'configs/axios';

const apiPaths = {
  checkDataExistenceAction: '/api/reserves-display-item-setting/reserves-display-item-setting/check-data-existence',
  getReserveCourseItem: '/api/reserves-display-item-setting/reserves-display-item-setting/get-reserve-course-item',
  getReserveInspectItem: '/api/reserves-display-item-setting/reserves-display-item-setting/get-reserve-inspect-item',
  getReserveInspectGroupItem: '/api/reserves-display-item-setting/reserves-display-item-setting/get-reserve-inspect-group-item',
  registerCourseDataAction: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/register-course-data',
  registerInspectDataAction: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/register-inspect-data',
  getCourseItems: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/get-course-items',
  getTargetCourse: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/get-target-course',
  getInspectItems: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/get-inspect-items',
  getTargetInspect: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/get-target-inspect',
  saveReserveInspectGroupItem: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/save-reserve-inspect-group-item',
  deleteReserveInspectGroupItem: '/api/reserves-display-item-setting/reserves-display-item-setting-sub/delete-reserve-inspect-group-item'
};

const ReservesDisplayItemSettingService = {

  //初期データチェック
  async checkDataExistenceAction() {
    return axios.post(apiPaths.checkDataExistenceAction);
  },

  //予約表示項目取得
  async getReserveCourseItem() {
    return axios.get(apiPaths.getReserveCourseItem);
  },

  async getReserveInspectItem() {
    return axios.get(apiPaths.getReserveInspectItem)
  },

  async getReserveInspectGroupItem() {
    return axios.get(apiPaths.getReserveInspectGroupItem)
  },

  //コースSub
  async registerCourseDataAction(params) {
    return axios.post(apiPaths.registerCourseDataAction, params)
  },

  async getCourseItems(params) {
    return axios.get(apiPaths.getCourseItems, { params })
  },

  async getTargetCourse(params) {
    return axios.get(apiPaths.getTargetCourse, { params })
  },

  //検査sub
  async registerInspectDataAction(params) {
    return axios.post(apiPaths.registerInspectDataAction, params)
  },

  async getInspectItems(params) {
    return axios.get(apiPaths.getInspectItems, { params })
  },

  async getTargetInspect(params) {
    return axios.get(apiPaths.getTargetInspect, { params })
  },

  async saveReserveInspectGroupItem(params) {
    return axios.post(apiPaths.saveReserveInspectGroupItem, params)
  },

  async getDisplayName(params) {
    return axios.post(apiPaths.getDisplayName, params)
  },

  async deleteReserveInspectGroupItem(params) {
    return axios.delete(apiPaths.deleteReserveInspectGroupItem, { params })
  },

};
export default ReservesDisplayItemSettingService;
