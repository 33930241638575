import { message } from 'antd'
import DestinationManagementService from 'services/Others/CorporateHearingSheet/DestinationManagementService.js'

const DestinationManagementAction = {

  getSendingHistory(data) {
    return DestinationManagementService.getSendingHistory(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveSendingHistory(data) {
    return DestinationManagementService.saveSendingHistory(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteSendingHistory(data) {
    return DestinationManagementService.deleteSendingHistory(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}
export default DestinationManagementAction