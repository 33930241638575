import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, Button, Form, Dropdown, Menu } from "antd";
import { PlusOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons';
import OptionTypeMaintainAction from "redux/SystemMaintenance/UserOptionInfoMaintain/OptionTypeMaintain.actions";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

const styleFormItem = {
  margin: 0
}
const styleInput = {
  border: 'none'
}
class WS1523005_OptionTypeMaintain extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = 'オプション種別保守';

    this.state = {
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: true,
      rowSelect: {}
    };
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = () => {
    OptionTypeMaintainAction.GetScreenData()
      .then(res => {
        if (res) {
          this.setState({ dataSource: res });
          this.formRef?.current?.setFieldsValue({ 'dataSource': res });
        }
      })
  }

  handleChangeInput = (record, value, name) => {
    let arrTemp = [...this.state.dataSource];
    let index = arrTemp.indexOf(record);
    if (index !== -1) {
      let objTemp = {
        ...record,
        [name]: value
      }
      arrTemp[index] = objTemp;
      this.setState({
        dataSource: arrTemp,
        rowSelect: objTemp
      });
      this.formRef.current.setFieldsValue({ 'dataSource': arrTemp });
    }
  }

  addData = () => {
    let arrTemp = [...this.state.dataSource];
    arrTemp.push({})
    this.formRef?.current?.setFieldsValue({ 'dataSource': arrTemp });
    this.setState({ dataSource: arrTemp });
  }

  deleteData = (record) => {
    let arrTemp = [...this.state.dataSource];
    console.log(arrTemp)
    const index = arrTemp.findIndex((item) => item.id == record.id);

    console.log(index)

    if (index > -1) {
      arrTemp = arrTemp.splice(index, 1)
      this.formRef?.current?.setFieldsValue({ 'dataSource': arrTemp });
      this.setState({ dataSource: arrTemp });
    }
  }

  render() {
    return (
      <div className="option-type-maintain">
        <Card title="オプション種別保守" className="mb-2">
          <Form ref={this.formRef}>
            <Table bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record, index) => index}
              onRow={(record, index) => ({ onClick: event => this.setState({ rowSelect: record }) })}
              scroll={{ x: 'max-content', y: 600 }}
            >
              <Table.Column title="種別" dataIndex="type_code" />
              <Table.Column align='center' width={70}
                title={() => (
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                  // onClick={this.addData}
                  />
                )}
              // render={(text, record, index) => (
              //   <Dropdown
              //     trigger='click'
              //     overlay={() => (
              //       <Menu>
              //         <Menu.Item
              //           key='1'
              //           onClick={() =>
              //             this.setState({
              //               childModal: {
              //                 ...this.state.childModal,
              //                 visible: true,
              //                 width: 600,
              //                 component: (
              //                   <WS1523500_UserOptionInfoMaintainEdit
              //                     newFlag={false}
              //                     record={record}
              //                     leftTable={true}
              //                     deleteFlag={true}
              //                     OptionType={this.state.OptionType}
              //                     onFinishScreen={(output) => {
              //                       this.createOrUpdateData(output, 'optionCode')
              //                       this.closeModal()
              //                     }}
              //                     onDelete={(output) => {
              //                       this.deleteData(record, 'optionCode')
              //                       this.closeModal()
              //                     }}
              //                   />
              //                 ),
              //               },
              //             })
              //           }
              //         >
              //           編集
              //         </Menu.Item>
              //       </Menu>
              //     )}
              //   >
              //     <Button size='small' icon={<MoreOutlined />}></Button>
              //   </Dropdown>
              // )}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1523005_OptionTypeMaintain);
