import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Space, InputNumber, Row, Modal, message, Col } from 'antd'
import WS0286001_PrintStyleInquiry from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry'
import PrintStyleInquiryAction from 'redux/ResultOutput/BindingModeSetting/PrintStyleInquiry.action'
import {
  postRegisterBtnAction
} from 'redux/InspectionMaintenance/CourseBasicTypeSetting/RegisterModification.action'
import VenusApiRoutesV2 from 'redux/VenusApiRoutesV2/VenusApiRoutesV2'
import { getScreenDataCourseBasicTypeSettingAction } from 'redux/InspectionMaintenance/CourseBasicTypeSetting/CourseBasicTypeSetting.actions'

const grid = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}
class WS2696052_RegisterModification extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_Action: PropTypes.any,
    Li_CourseCode: PropTypes.any,
    ReserveItem: PropTypes.any,
    Pattern: PropTypes.any,
    onFinishScreen: PropTypes.func,
    onChangeTableState: PropTypes.func
  }

  constructor(props) {
    super(props)

    // document.title = '登録・修正'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      // formData: {
      //   StandardStyle: '',
      //   course_code: '',
      //   course_name_formal: '',
      //   course_name_short_name: '',
      //   expression: null,
      //   format_name: '',
      //   id: null,
      //   judgment_level_division: '',
      //   medical_exam_dates: null,
      //   option_remark: '',
      //   pattern_code: '',
      //   reservation_display_item_number: '',
      //   specimen_label_number: null,
      // }

      dataStyles: [],
      Combobox_Pattern: [],
      Combobox_JudgeLevel: [],
      Combobox_ReserveItem: [],
      format_name: ''
    }

    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    this.setState({ Combobox_ReserveItem: this.props.ReserveItem })
    this.loadInitData()
    this.getInitValues()
    this.loadDataStyle()
    // this.getFirstValuse()
    // this.loadDataStyle()
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.getInitValues()
      //  this.getFirstValuse()
    }
  }

  loadInitData = () => {
    getScreenDataCourseBasicTypeSettingAction()
      .then(res => {
        if (res) {
          console.log(res);
          this.setState({
            Combobox_Pattern: res.data.Pattern,
            Combobox_JudgeLevel: res.data.judgment_level_division
          })
        }
      })
  }

  getInitValues = () => {
    const fleldData = this.props.record.Li_CourseCode
    this.formRef.current?.setFieldsValue({
      CourseCode: fleldData.course_code,
      CourseName: fleldData.course_name_formal,
      CourseAbbreviation: fleldData.course_name_short_name,
      ReserveItem: fleldData.reservation_display_item_number,
      identification: fleldData.identification,
      Label: fleldData.specimen_label_number,
      NumDays: fleldData.medical_exam_dates ? fleldData.medical_exam_dates : 1,
      Pattern: fleldData.pattern_code ? fleldData.pattern_code : '',
      JudgeLevel: fleldData.judgment_level_division ? fleldData.judgment_level_division : '標準(人間ドック)',
      // JudgeLevel: fleldData.judgment_level_division,
      Style: fleldData.StandardStyle,
      format_name: fleldData.format_name,
      Option: fleldData.option_remark
    })
  }

  loadDataStyle() {
    PrintStyleInquiryAction.GetListData()
      .then(res => {
        this.setState({ dataStyles: res })
      })
  }

  getStyleFormatName(style) {
    let data = this.state.dataStyles?.filter(x => x.style_code === style)
    this.formRef.current?.setFieldsValue({
      format_name: data.length > 0 ? data[0].format_name : ''
    })

    this.setState({
      format_name: data.length > 0 ? data[0].format_name : ''
    })
  }

  changeParentrowData = (values) => {
    let params = {
      // CourseCode: values.CourseCode,
      CourseCode: values.CourseCode,
      CourseAbbreviation: values.CourseAbbreviation,
      CourseName: values.CourseName,
      JudgeLevel: values.JudgeLevel,
      Label: values.Label,
      NumDays: values.NumDays,
      Option: values.Option,
      Pattern: values.Pattern,
      ReserveItem: values.ReserveItem,
      Style: values.Style,
      format_name: values.format_name
      // CourseAbbreviation: '政管 一般＋付加健診',
      // CourseCode: 'A01',
      // CourseName: '一般+付加健診',
      // JudgeLevel: '標準(人間ドック)',
      // Label: 3,
      // NumDays: 1,
      // Option: '12',
      // Pattern: '政府管掌分類',
      // ReserveItem: '一般',
      // Style: '050',
      // format_name: '健診結果通知票(A)[政管用]'
    }
    this.props.onChangeTableState({ ...params, Li_Action: this.props.record.Li_Action })
    this.props.onFinishScreen({
      // call api
    })
  }
  onFinish(values) {

    if (!values.CourseCode) {
      Modal.error({
        width: 360,
        title: 'コースコードを入力してください。'
      })
    } else {
      // this.changeParentrowData(values)
      var codeValue = String(values.CourseCode).replace('-', '')
      // パターンコード取得
      let patternCode = ''
      const pattern = this.state.Combobox_Pattern.find(item => item.value === values.Pattern)
      if (pattern) {
        patternCode = pattern.key
      }

      let params = {
        newFlag: this.props.record.newFlag,
        CourseCode: codeValue,
        CourseAbbreviation: values.CourseAbbreviation,
        CourseName: values.CourseName,
        JudgeLevel: values.JudgeLevel,
        Label: values.Label,
        NumDays: values.NumDays,
        Option: values.Option,
        Pattern: patternCode,
        ReserveItem: values.ReserveItem,
        identification: values.identification,
        Style: values.Style,
        format_name: values.format_name
        // CourseAbbreviation: '政管 一般＋付加健診',
        // CourseCode: 'A01',
        // CourseName: '一般+付加健診',
        // JudgeLevel: '標準(人間ドック)',
        // Label: 3,
        // NumDays: 1,
        // Option: '12',
        // Pattern: '政府管掌分類',
        // ReserveItem: '一般',
        // Style: '050',
        // format_name: '健診結果通知票(A)[政管用]'
      }

      postRegisterBtnAction(params)
        .then(res => {
          console.log('API002696052002', res)
          // this.props.onChangeTableState({ ...params, Li_Action: this.props.record.Li_Action })
          this.props.onFinishScreen({
            // Li_id: this.props.record.Li_CourseCode.id,
            Li_newFlag: this.props.record.newFlag,
            Li_newData: res
            // call api
          })
        }).catch(error => {
          console.log('err->', message.info(error))
        }).finally(() => {

        })

      // VenusApiRoutesV2.callApi('API002696052002', params)
      //   .then(res => {
      //     console.log('API002696052002', res)
      //   }).catch(error => {
      //     console.log('err->', message.info(error))
      //   }).finally(() => {

      //   })

    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  getFirstValuse = () => {
    this.setState({
      isLoadingTable: true
    })
    let params = {
      Li_CourseCode: this.props.course_code,
    }
    console.log('propd---', this.props)
    VenusApiRoutesV2.callApi('API002696052001', params)
      .then(res => {
        console.log('res-->re', res)
        this.setState({
          // Combobox_ReserveItem: res ? res : [],
          Combobox_Pattern: res ? res.Pattern : [],
          Combobox_JudgeLevel: res ? res.JudgeLevel : [],
          // dataSource: res ? res : [],
        })
        console.log(' Combobox_ReserveItem', this.state.Combobox_ReserveItem)
        console.log(' Combobox_Pattern', this.state.Combobox_Pattern)
        console.log(' Combobox_JudgeLevel', this.state.Combobox_JudgeLevel)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }

  render() {
    return (
      <div className='register-modification'>
        <Card title='登録・修正' className='mb-2'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            // initialValues={{ NumDays: 1 }}
            {...grid}
          >
            <Form.Item name='CourseCode' label='コード'>
              <Input type='text' style={{ width: 70, textAlign: 'center' }}
                disabled={this.props.record.Li_Action === 'edit'}
                maxLength={3} />
            </Form.Item>

            <Form.Item name='CourseName' label='名称'>
              <Input type='text' />
            </Form.Item>

            <Form.Item name='CourseAbbreviation' label='略称'>
              <Input type='text' />
            </Form.Item>

            <Form.Item name='ReserveItem' label='予約項目'>
              <Select style={{ width: 180 }}>
                {this.state.Combobox_ReserveItem?.map((record, index) => (
                  <Select.Option key={index} value={record.key}>{record.key === 0 ? '　' : record.value}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name='identification' label='識別'>
              <Select style={{ width: 180 }}>
                <Select.Option key={0} value={0}>{'　'}</Select.Option>
                <Select.Option key={1} value={1}>{'ドック'}</Select.Option>
                <Select.Option key={2} value={2}>{'協会'}</Select.Option>
                <Select.Option key={3} value={3}>{'定期'}</Select.Option>
                <Select.Option key={4} value={4}>{'特定'}</Select.Option>
                <Select.Option key={5} value={5}>{'単独'}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label='ラベル'>
              <Row>
                <Space style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Form.Item name='Label' style={{ width: 50, marginRight: 40 }}>
                    <InputNumber key='ラベル1' min={0} maxLength={3} />
                  </Form.Item>
                  <Form.Item name='NumDays' label='日数'>
                    <InputNumber key='ラベル2' min={0} maxLength={2} style={{ width: '35px', marginRight: '100px' }} />
                  </Form.Item>
                </Space>
              </Row>
            </Form.Item>

            <Form.Item name='Pattern' label='パターン'>
              <Select key='Pattern' style={{ width: '100%' }}>
                {this.state.Combobox_Pattern?.map((record) => {
                  return (
                    <Select.Option key={record.key} value={record.value}>{record.value}</Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='JudgeLevel' label='判定レベル'>
              <Select key='JudgeLevel' style={{ width: '100%' }}>
                {this.state.Combobox_JudgeLevel?.map((value, index) => {
                  return (
                    <Select.Option key={index} value={value.key}>{value.value}</Select.Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item label='様式'>
              <Row>
                <Col span={6}>
                  <Form.Item name='Style'>
                    <Input.Search maxLength={3}
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              <WS0286001_PrintStyleInquiry
                                Li_UsuallyWrenRoster={''}
                                Lio_StyleCode={this.formRef.current?.getFieldValue('Style')}
                                onFinishScreen={(output) => {
                                  this.formRef.current?.setFieldsValue({
                                    Style: output.Lio_StyleCode,
                                    format_name: output.Lo_FormatName
                                  })
                                  this.setState({
                                    format_name: output.Lo_FormatName
                                  })
                                  this.closeModal()
                                }}
                              />),
                          },
                        })
                      }}

                      onBlur={(e) => {
                        this.getStyleFormatName(e.target.value)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  {/* <span>{this.state.format_name}</span> */}
                  <Form.Item name='format_name'  >
                    <Input readOnly bordered={false} />
                  </Form.Item>
                </Col>


              </Row>
            </Form.Item>

            <Form.Item name='Option' label='オプション'>
              <Input type='text' />
            </Form.Item>

            <Space style={{ float: 'right', marginTop: 15 }} >
              <Button
                type='primary'
                htmlType='submit'
              // onClick={() => this.onFinish()}
              >登録</Button>
            </Space>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2696052_RegisterModification)
