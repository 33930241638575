import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, message} from "antd";
import CsvCaptureScreenAction from "redux/SpecificInsureMaintenance/XmlMedicalExamItemMaster/CsvCaptureScreen.action";

class WS1313010_CsvCaptureScreen extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'CSV取込画面';

    this.state = {
    };
  }

  onFinish(values) {
    let params = {CapturingDestination: ''}
    CsvCaptureScreenAction.ExecCapture(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
          });
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
    }

  render() {
    return (
      <div className="csv-capture-screen">
        <Card title="CSV取込画面">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name=""
              label="出力先"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
            >
              <Button type="primary" style={{float: 'right'}} onClick={() => this.onFinish()}>実行</Button>
            </Form.Item>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313010_CsvCaptureScreen);
