/* eslint-disable no-useless-concat */
import React from 'react'
import Color from 'constants/Color'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Checkbox, Input, Button, Form } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

const labelSize = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 60px)' } }
}

class BankHolidaySettingEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '予約状況検索（カスタム）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      tableData: []
    }
  }

  componentDidMount() {
    console.log(this.props);
    this.formRef.current.setFieldsValue(this.props.record)
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: '',
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='bank-holiday-setting'>
        <Card title={'法定休日設定'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item name="closed_days" label="休診日" valuePropName="checked" {...labelSize}>
              <Checkbox />
            </Form.Item>
            <Form.Item name="holiday_name" label="名称" {...labelSize}>
              <Input style={{ width: 150 }} />
            </Form.Item>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.props.onFinishScreen(this.formRef.current?.getFieldValue())}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankHolidaySettingEdit)