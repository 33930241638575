import TargetivesResultInputService from "services/SpecificInsureGuide/InsureGuideInitInput/TargetivesResultInputService.js"
import { message, } from "antd"

const TargetivesResultInputAction = {
  confirm(params) {
    return TargetivesResultInputService.confirm(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default TargetivesResultInputAction;