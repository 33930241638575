import React from "react";
import { connect } from "react-redux";
import axios from "configs/axios";
import { Card, Table, message } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0262068_UserChoice extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'ユーザー選択';

    this.state = {
      dataSource: [],
      initialValue: {
        user_code: "",
        user_name: "",
      },
      isLoading: false,
    };
  }

  componentDidMount = () => {
    // this.callAPILoadData({});
    this.getscreendata()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      // this.callAPILoadData({});
    }
  }

  // callAPILoadData = (params) => {
  //   this.setState({ isLoading: true })
  //   VenusApiRoutesV2.callApi("API000262068001", params)
  //     .then((res) => {
  //       this.setState({ initialValue: res })
  //       VenusApiRoutesV2.callApi("API000262068002", params)
  //         .then((res) => {
  //           this.setState({ dataSource: res })
  //         }).finally(() => this.setState({ isLoading: false }))
  //     }).catch(() => this.setState({ isLoading: false }))
  // }
  getscreendata = () => {
    this.setState({ isLoading: true })
    axios.get('/api/log-display/log-display/index', {})
      .then(res => {
        console.log(res);
        this.setState({ dataSource: res.data, isLoading: false })
      })
      .catch(error => {
        const res = error.response;
        this.setState({ isLoading: false })
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
  }

  render() {
    return (
      <div className="user-choice">
        <Card className="mb-2" title="ユーザー選択">
          <Table bordered
            dataSource={this.state.dataSource}
            loading={this.state.isLoading}
            pagination={true}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      recordData: record,
                    })
                  }
                }
              }
            }}
          >
            <Table.Column title="コード" dataIndex="user_code" key="user_code" />
            <Table.Column title="名称" dataIndex="user_name" key="user_name" />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0262068_UserChoice);
