import axios from "configs/axios";

const APP_LIST = {
  getScreenData: `/api/specific-medical-exam-settle-process/contract-select`,
};
const ContractSelectService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
};

export default ContractSelectService;
