import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Checkbox, Button, Tabs, Row, Col, Space, message, Spin, InputNumber, Popconfirm } from "antd";
import { LeftOutlined, RightOutlined, MoreOutlined, DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import CmtRegisterCopyAction from 'redux/InspectionMaintenance/CmtRegisterCopy/CmtRegisterCopy.actions'
import moment from 'moment';
import { ModalError } from "components/Commons/ModalConfirm";

const dateFormat = 'YYYY/MM/DD';
const { TabPane } = Tabs;
const { TextArea } = Input;
const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
};
class WS2701025_CmtRegisterCopy extends React.Component {
  static propTypes = {
    Li_CommentCode: PropTypes.any,
    Li_IdentifyCode: PropTypes.any,
    Li_Copy: PropTypes.any,
    Li_status: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'コメント登録・複写';

    this.state = {
      title: '',
      isloadingFm: true,
      dataInit: {
        "CmtCode": "",
        "SearchKey": "",
        "CmtContent": "",
        "Priority": "",
        "CmtGroup": "",
        "NumTimesUse": "",
        "FinalDayUse": "",
        "FinalDayUseChar": "",
        "InputFlag": "",
        "CourseLevel2": false,
        "CourseLevel3": false,
        "OutputType": "",
        "Vl2Secret": false,
        "StsEnable": false
      }
    };
    //this.callback = this.callback
  }

  componentDidMount() {
    let tempTitle = '';
    this.props?.Li_status === "RegisterComment" ? "新規登録" : this.props?.Li_status === "UpdateComment" ? "編集・削除" : "複写"
    if (this.props?.Li_status === "RegisterComment") {
      tempTitle = '新規登録'
    } else if (this.props?.Li_status === "UpdateComment") {
      tempTitle = '編集・削除'
    } else if (this.props?.Li_status === "CopyComment") {
      tempTitle = '複写'
    }

    this.setState({
      title: tempTitle
    })

    this.getScreenData()
  }

  componentDidUpdate(propPrve) {
    if (this.props != propPrve) {
      this.getScreenData()
    }
  }

  getScreenData() {
    this.formRef.current?.setFieldsValue(this.state.dataInit)
    this.setState({ isloadingFm: true })
    let data = {
      Li_Copy: this.props.Li_Copy ? this.props.Li_Copy : "",
      IdentifyCode: this.props.Li_IdentifyCode ? this.props.Li_IdentifyCode : "",
      comment_code: this.props.Li_CommentCode ? this.props.Li_CommentCode : ""
    }
    CmtRegisterCopyAction.getScreenData(data).then(res => {
      if (res.CmtCode) {
        res.CmtCode = this.props?.Li_status === "CopyComment" ? "" : res.CmtCode
        res.FinalDayUseChar = this.isDateNotNull(res.FinalDayUseChar) ? "" : moment(res.FinalDayUseChar);
        res.CourseLevel2 = res.CourseLevel2 === 0 ? false : true;
        res.CourseLevel3 = res.CourseLevel3 === 0 ? false : true;
        this.formRef.current?.setFieldsValue(res ? res : [])
        this.forceUpdate()
      }
    })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isloadingFm: false }))
  }

  isDateNotNull(date) {
    return (date === "0000-00-00" || date === "0000/00/00" || date === undefined || date == null || date.length <= 0) ? true : false;
  }

  CopyComment(data) {
    CmtRegisterCopyAction.CopyComment(data)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
   * 変更処理
   * @param {*} data
   */
  UpdateComment(data) {
    CmtRegisterCopyAction.UpdateComment(data)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({ search_key: this.formRef.current?.getFieldValue("SearchKey"), comment_content: this.formRef.current?.getFieldValue("CmtContent") })
        }
      })
  }

  /**
   * 新規登録処理
   * @param {*} data
   */
  RegisterComment(data) {
    CmtRegisterCopyAction.RegisterComment(data)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
   * 削除処理
   * @param {*} record
   */
  DeleteComment(record) {
    let data = {
      IdentifyCode: this.props.Li_IdentifyCode ? this.props.Li_IdentifyCode : "",
      comment_code: this.props.Li_CommentCode ? this.props.Li_CommentCode : ""
    }
    CmtRegisterCopyAction.DeleteComment(data)
      .then(() => {
        this.props.onFinishScreen()
      })
  }

  onBlurNumber = (e, namePath) => {
    const { value } = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value))) {
      null
    } else {
      this.formRef.current?.setFields([{
        name: namePath,
        value: ""
      }])
    }
    this.forceUpdate()
  };

  onSubmit(values) {
    values.FinalDayUseChar = moment(values.FinalDayUseChar).format("YYYY/MM/DD") === "Invalid date" ? "" : moment(values.FinalDayUseChar).format("YYYY/MM/DD");
    values.CourseLevel2 = values.CourseLevel2 ? 1 : 0
    values.CourseLevel3 = values.CourseLevel3 ? 1 : 0
    values.StsEnable = values.StsEnable ? 1 : 0
    values.Vl2Secret = values.Vl2Secret ? 1 : 0
    const data = { ...values, IdentifyCode: this.props.Li_IdentifyCode, comment_code: this.props.Li_CommentCode }
    if (values.CmtCode.length === 0) {
      message.error("コードを入力してください。")
    } else {
      if (this.props.Li_status === "RegisterComment") {
        if (values.CmtContent.length === 0) {
          message.error("コメントを入力してください。")
        } else {
          this.RegisterComment(data)
        }
      } else if (this.props.Li_status === "UpdateComment") {
        if (values.CmtContent.length === 0) {
          message.error("コメントを入力してください。")
        } else {
          this.UpdateComment(data)
        }
      } else if (this.props.Li_status === "CopyComment") {
        if (values.CmtContent.length === 0) {
          message.error("コメントを入力してください。")
        } else {
          console.log('CopyComment');
          this.CopyComment(data)
        }
      }
    }

  }

  render() {
    return (
      <div className="cmt-register-copy">
        <Card title={"コメント入力　" + this.state.title}>
          <Spin spinning={this.state.isloadingFm}>
            <Form
              autoComplete="off"
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <Tabs defaultActiveKey="0"  >
                <TabPane tab='コメント入力' key="0">
                  <Row>
                    <Col span={4}>
                      <Form.Item name="CmtCode" label="コード">
                        <Input maxLength={5} disabled={this.props?.Li_status === "UpdateComment" ? true : false}
                          onBlur={(e) => {
                            if (this.props?.Li_status !== "UpdateComment") {
                              this.onBlurNumber(e, "CmtCode")
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={1}>
                      <Form.Item name="SearchKey" label="検索キー">
                        <Input maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col span={9} style={{ textAlign: 'right' }}>
                      <Form.Item name="StsEnable" valuePropName="checked" >
                        <Checkbox>有効</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label>コメント</label>
                      <Form.Item name="CmtContent">
                        <TextArea rows={4} maxLength={500} />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab='詳細設定' key="1">
                  <Form.Item  {...layout} name="CmtGroup" label="コメント群">
                    <Input maxLength={10} style={{ width: "98%" }} />
                  </Form.Item>
                  <Form.Item  {...layout} label="優先" name="Priority" >
                    <Input maxLength={2} style={{ width: "45px" }} onBlur={(e) => this.onBlurNumber(e, "Priority")} />
                  </Form.Item>
                  <Form.Item  {...layout} label="使用回数" name="NumTimesUse">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item  {...layout} label="最終使用日" name="FinalDayUseChar">
                    <VenusDatePickerCustom formRefDatePicker={this.formRef} format={dateFormat} />
                  </Form.Item>
                  <Form.Item  {...layout} label="印字条件" >
                    <Space>
                      <Form.Item name="Vl2Secret" label="S" valuePropName="checked">
                        <Checkbox></Checkbox>
                      </Form.Item>
                      <Form.Item name="CourseLevel2" label="L2" valuePropName="checked">
                        <Checkbox></Checkbox>
                      </Form.Item>
                      <Form.Item name="CourseLevel3" label="L3" valuePropName="checked">
                        <Checkbox></Checkbox>
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item  {...layout} label="出力種別" name="OutputType">
                    <Input maxLength={10} />
                  </Form.Item>
                </TabPane>
              </Tabs>


              <div className="box_button_bottom_right">
                {this.props.Li_status === "UpdateComment" ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_status === "UpdateComment") }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    onConfirm={() => this.DeleteComment()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.Li_status !== "UpdateComment"}
                    >
                      <span className='btn_label'>
                        削除
                      </span>
                    </Button>
                  </Popconfirm>
                  :
                  null}
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.onSubmit(this.formRef.current.getFieldValue())
                  }}>
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </Form>
          </Spin>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2701025_CmtRegisterCopy);
