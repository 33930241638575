import axios from "configs/axios";

const API_LIST = {
  deleteDataInspect: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/inspect-maintain/delete-data',
  deleteMaintain: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/delete-data',
  getMaintain: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain',
  getInspectMaintain: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/inspect-maintain',
  saveDataInspect: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/inspect-maintain/save-data',
  saveMaintain: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/save-data',
  userAction1: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/user-action-1',
  getExamInfo: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/get-exam-info',
  getDepartmentInfo: '/api/introduce-letter-master-maintain/follow-up-letter-target-cmt-maintain/get-department-info',
};

const FollowUpLetterTargetCmtMaintainService = {
  async getMaintain(params) {
    return axios.get(API_LIST.getMaintain, { params })
  },
  async getInspectMaintain(params) {
    return axios.get(API_LIST.getInspectMaintain, { params })
  },
  async saveMaintain(params) {
    return axios.post(API_LIST.saveMaintain, params)
  },
  async saveDataInspect(params) {
    return axios.post(API_LIST.saveDataInspect, params)
  },
  async deleteDataInspect(params) {
    return axios.delete(API_LIST.deleteDataInspect, { params })
  },
  async deleteMaintain(params) {
    return axios.delete(API_LIST.deleteMaintain, { params })
  },
  async userAction1(params) {
    return axios.delete(API_LIST.userAction1, { params })
  },
  async getExamInfo(params) {
    return axios.get(API_LIST.getExamInfo, { params })
  },
  async getDepartmentInfo(params) {
    return axios.get(API_LIST.getDepartmentInfo, { params })
  },
};

export default FollowUpLetterTargetCmtMaintainService
