import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Button, Input, Modal, message } from 'antd'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import ExamDepartmentMasterAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/ExamDepartmentMaster.action'
import WS0302001_SetInfoSearch from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0302001_SetInfoSearch'

const labelStyle = {
  labelCol: { style: { width: 110 } }
}

class WS0922012_ExamDepartmentMasterEdit extends React.Component {

  constructor(props) {
    super(props)

    // document.title = '診療科(検査部門)マスタ'
    this.formRef = React.createRef()
    this.state = {
      childModal: {
        width: 0,
        visible: false,
        component: null
      },
      dataSource: [],
      addCount: 0
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の場合
      this.formRef.current.setFieldsValue({
        exam_department_code: this.props.record.exam_department_code,
        search_key: this.props.record.search_key,
        exam_department_name: this.props.record.exam_department_name,
        remarks: this.props.record.remarks,
        tableData: this.props.record.setInfo
      })
      this.setState({
        dataSource: this.props.record.setInfo
      })
      this.forceUpdate()
    }
  }

  /**
   * セット情報照会
   */
  showWS0302001_SetInfoSearch = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        className: '',
        component:
          <WS0302001_SetInfoSearch
            onFinishScreen={(output) => {
              this.changeSetInfo(record, output)
              this.closeModal()
            }}
          />
      },
    })
  }

  /**
   * セットコードを変更
   */
  changeSetInfo = (record, output) => {
    let newDataSource = [...this.state.dataSource]
    const index = newDataSource.findIndex((item) => record.id === item.id)
    if (index > -1) {
      // 取得したデータを設定
      let obj = {
        ...newDataSource[index],
        set_code: output.recordData.set_code ?? '',
        set_short_name: output.recordData.set_short_name ?? '',
        changeFlag: 1,
      }
      // データ置換
      newDataSource[index] = obj

      this.formRef.current?.setFieldsValue({ tableData: newDataSource })
      this.setState({ dataSource: newDataSource })
    }
  }

  /**
   * セット情報　行を新規追加
   */
  addSetCode = () => {
    let newDataSource = [...this.state.dataSource]
    const obj = {
      id: 'new-' + String(this.state.addCount),
      set_code: '',
      set_short_name: '',
      changeFlag: 1,
    }
    // 配列に追加
    newDataSource.push(obj)

    this.formRef.current?.setFieldsValue({ tableData: newDataSource })
    this.setState({
      dataSource: newDataSource,
      addCount: (this.state.addCount + 1)
    })
  }

  /**
   * セット情報　行を削除
   */
  deleteSetCode = (record) => {
    let newDataSource = [...this.state.dataSource]
    const index = newDataSource.findIndex((item) => record.id === item.id)
    if (index > -1) {
      // 配列から削除
      newDataSource.splice(index, 1)

      this.formRef.current?.setFieldsValue({ tableData: newDataSource })
      this.setState({ dataSource: newDataSource })
    }
  }

  /**
   * 保存ボタン処理
   */
  save = () => {
    const data = this.state.dataSource

    // 重複チェック
    const setCodeList = data.map(item => item['set_code'])
    const setElements = new Set(setCodeList)
    if (setElements.size !== setCodeList.length) {
      message.error('セット情報が重複しています')
      return
    }

    const values = this.formRef.current?.getFieldsValue()
    const params = {
      id: this.props.record?.id ?? null,
      LnkInmanageCode: 9,
      dataSource: data,
      ...values
    }
    ExamDepartmentMasterAction.save(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.closeModal()
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 削除ボタン処理
   */
  delete = () => {
    const params = {
      id: this.props.record?.id ?? null,
      LnkInmanageCode: 9
    }
    ExamDepartmentMasterAction.delete(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='department-master'>
        <Card title={'診療科(検査部門)マスタ編集 ' + (this.props.record ? '[変更]' : '[新規追加]')}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Form.Item
              label='コード'
              name='exam_department_code'
              {...labelStyle}
            >
              <Input
                className='input-size-number-10'
                onChange={() => this.forceUpdate()}
              />
            </Form.Item>

            <Form.Item
              label='検索キー'
              name='search_key'
              {...labelStyle}
            >
              <Input
                className='input-size-10'
              />
            </Form.Item>

            <Form.Item
              label='診療科(検査部門)'
              name='exam_department_name'
              {...labelStyle}
            >
              <Input
                className='input-size-40'
              />
            </Form.Item>

            <Form.Item
              label='受診科'
              name='remarks'
              {...labelStyle}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='セット情報'
              name='setInfo'
              {...labelStyle}
            >
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                loading={false}
                pagination={false}
                rowKey={(record) => record.id}
                scroll={{ y: 200 }}
              >
                <Table.Column
                  title='セットコード'
                  dataIndex='set_code'
                  width={200}
                  render={(value, record, index) => (
                    <Form.Item name={['tableData', index, 'set_code']} style={{ marginBottom: 0 }}>
                      <Input.Search
                        onSearch={() => {
                          // 検査照会
                          this.showWS0302001_SetInfoSearch(record)
                        }}
                      />
                    </Form.Item>
                  )}
                />
                <Table.Column
                  title='セット名称'
                  dataIndex='set_short_name'
                />
                <Table.Column
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      disabled={!(this.formRef.current?.getFieldValue('exam_department_code') ?? false)}
                      onClick={() =>
                        // セットコード 行追加
                        this.addSetCode()
                      }
                    />
                  }
                  width={40}
                  align='center'
                  render={(value, record) => (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        // セットコード 行削除
                        this.deleteSetCode(record)
                      }}
                    />
                  )}
                />
              </Table>
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              hidden={!(this.props.record)}
              onClick={() => {
                Modal.confirm({
                  content: '削除しますか？',
                  okButtonProps: {
                    danger: true,
                    icon: <DeleteOutlined />
                  },
                  okText: <span className='btn_label'>{'削除'}</span>,
                  cancelText: 'キャンセル',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                this.save()
              }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0922012_ExamDepartmentMasterEdit)
