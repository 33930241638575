import axios from "configs/axios";

const API_LIST = {
    getScreenData: "/api/associate-insure-param-maintain/year-select"
}


const DetailInfoCorrect = {
    async getScreenData(){
        return axios.get(API_LIST.getScreenData);
    }
};
export default DetailInfoCorrect;