import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Card, Form, Input, Button, Modal, Checkbox, message, Select } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import WS0104001_CoursesSettingSub from 'pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0104001_CoursesSettingSub.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import TextArea from "antd/lib/input/TextArea";

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

class WS0483005_ConditionExpressCmtSubEdit extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Lio_CommentContent: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'コメント内容修正';

    this.state = {
      Lio_method: 0,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }

    // Form設定
    this.formRef.current?.setFieldsValue(data)

    this.setState({
      Lio_method: this.props.record.method ?? 0
    })

    if (this.formRef.current?.getFieldValue('enabled') == 1) {
      this.setState({
        StsEnable: true
      })
    } else {
      this.setState({
        StsEnable: false
      })
    }
  }

  /**
   * チェックボックスが更新された場合
   * @param {*} e 
   */
  changeData = (e) => {
    if (e.target.checked) {
      this.setState({
        StsEnable: true
      })
      this.formRef.current?.setFieldsValue({
        enabled: 1
      })
    } else {
      this.setState({
        StsEnable: false
      })
      this.formRef.current?.setFieldsValue({
        enabled: 0
      })
    }
  }

  /**
   * コースのサーチが押された時の処理
   * @param {*} condition
   */
  ShowWS0104001_CoursesSettingSub(condition) {
    this.setState({
      ...this.state,
      childModal: {
        width: '50%',
        visible: true,
        component: (
          <WS0104001_CoursesSettingSub
            Li_Title={condition === "CourseOr" ? '対象コース' : '対象外コース'}
            Lio_CourseList={condition === "CourseOr" ? this.formRef.current?.getFieldValue("CourseOr") : this.formRef.current?.getFieldValue("CourseNot")}
            management_division={this.props.record.management_division}
            //項目を追加する場合
            onFinishScreen={(output) => {
              this.setDataCourses(condition, output)
            }}
            //項目を削除する場合
            onDelete={(output) => {
              this.deleteDataCourses(condition, output)
            }}
          />)
      }
    });
  }

  /**
   * コースフォーム項目の登録用関数
   * @param {*} condition
   * @param {*} record
   */
  setDataCourses(condition, record) {
    const item = record.recordData.course_code
    let formItem = record.FieldValue ?? ''
    let arrayItem = ''
    let duplication = ''
    let outputItem

    if (formItem === '') {
      //フォームに何も登録されていない場合
      outputItem = item + ";"

    } else {
      //フォームに項目が登録されている場合
      arrayItem = formItem.substr(0, formItem.length - 1)
      arrayItem = arrayItem.split(',')
      arrayItem.push(item)
      arrayItem.sort()

      duplication = arrayItem.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplication.length === 0) {
        //重複がない場合
        arrayItem.join(',');
        outputItem = arrayItem + ";"

      } else {
        //重複がある場合
        message.error("検査コードが重複しています")

        //重複を削除したリスト
        arrayItem = arrayItem.filter((x, i, self) => self.indexOf(x) === i)
        arrayItem.join(',');
        outputItem = arrayItem + ";"
      }
    }

    //登録するフォームの選択
    if (condition === "CourseOr") {
      //コース〇
      this.formRef.current?.setFieldsValue({
        CourseOr: outputItem
      })

    } else {
      //コース×
      this.formRef.current?.setFieldsValue({
        CourseNot: outputItem
      })
    }

    this.forceUpdate()
    this.ShowWS0104001_CoursesSettingSub(condition)
  }

  /**
   * コースフォーム項目の削除用関数
   * @param {*} condition
   * @param {*} record
   */
  deleteDataCourses(condition, record) {
    const item = record.record.W1_course_cd
    let formItem = record.FieldValue
    let arrayItem = ''
    let linkArrayItem = ''
    let outputItem

    arrayItem = formItem.substr(0, formItem.length - 1)
    arrayItem = arrayItem.split(',')

    //削除対象の絞り込み・削除
    arrayItem.map((value, index) => {
      if (value === item) {
        arrayItem.splice(index, 1)
      }
    })

    arrayItem.sort()
    linkArrayItem = arrayItem.join(',')

    if (linkArrayItem === "") {
      //消去後、項目が残らない場合
      outputItem = ""

    } else {
      //消去後、項目が残る場合
      outputItem = linkArrayItem + ";"
    }

    //削除するフォームの選択
    if (condition === "CourseOr") {
      //コース〇
      this.formRef.current?.setFieldsValue({
        CourseOr: outputItem
      })

    } else {
      //コース×
      this.formRef.current?.setFieldsValue({
        CourseNot: outputItem
      })
    }

    this.forceUpdate()
    this.ShowWS0104001_CoursesSettingSub(condition)
  }

  /**
   * 保存ボタンが押された場合
   */
  save = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      id: this.props.record.id ?? '',
      Lio_method: this.state.Lio_method
    }

    this.props.onSave(values)
  }

  /**
   * 削除ボタンが押された場合
   */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="cmt-content-modification">
        <Card title="コメント内容修正" className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Form.Item
              name="enabled"
              label='有効'
              {...labelCol}
            >
              <Checkbox
                checked={this.state.StsEnable}
                onChange={(event) => { this.changeData(event) }}
              >
              </Checkbox>
            </Form.Item>


            <Form.Item
              name="serial_number"
              label='連番'
              {...labelCol}
            >
              <Input className="input-size-number-5" />
            </Form.Item>

            <Form.Item
              name="name"
              label='名称'
              {...labelCol}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="priority"
              label='優先'
              {...labelCol}
            >
              <Input className="input-size-number-5" />
            </Form.Item>

            <Form.Item
              name="method"
              label='条件式'
              {...labelCol}
            >
              <Select
                value={this.state.Lio_method}
                style={{ width: 80 }}
                onChange={(value) => this.setState({ Lio_method: value })}
              >
                <Select.Option value={0}>OR</Select.Option>
                <Select.Option value={1}>AND</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="CourseOr"
              label='対象コース'
              {...labelCol}
            >
              <Input.Search
                onSearch={() => this.ShowWS0104001_CoursesSettingSub("CourseOr")}
              />
            </Form.Item>

            <Form.Item
              name="CourseNot"
              label='対象外コース'
              {...labelCol}
            >
              <Input.Search
                onSearch={() => this.ShowWS0104001_CoursesSettingSub("CourseNot")}
              />
            </Form.Item>

            <Form.Item
              name="Remarks"
              label="メモ"
              {...labelCol}
            >
              <TextArea rows={5} type="text" />
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0483005_ConditionExpressCmtSubEdit);
