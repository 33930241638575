import axios from 'configs/axios'
const API_LIST = {
  index: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting/caution-guide-notes-search-query',
  getGuideItemInfo: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting/caution-guide-notes-search-query/get-guide-item-info',
  Calculation: '/api/inspect-item-judge-value-setting/inspect-item-judge-value-setting/caution-guide-notes-search-query/calculation'
}

const CautionGuideNotesSearchQueryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getGuideItemInfo(params) {
    return axios.get(API_LIST.getGuideItemInfo, { params })
  },
  async Calculation(params) {
    return axios.get(API_LIST.Calculation, { params })
  },
}

export default CautionGuideNotesSearchQueryService
