import React from "react";
import { connect } from "react-redux";
import axios from "configs/axios";

import { Card, Table, message } from "antd";

class WS2670033_InspectAdvanced extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '検査詳細設定';

    this.state = {
      isLoadingTable: false,
      inspectData: []
    };
  }

  componentDidMount() {
    this.loadInspectAdvanced();
  }

  loadInspectAdvanced() {
    this.setState({ isLoadingTable: true });

    console.log(this.props.startDate);

    axios
      .get("/api/specific-health-data-xml-output/inspect-advanced")
      .then(res => {
        this.setState({
          inspectData: res.data
        });
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  }

  render() {
    return (
      <div className="inspect-advanced">
        <Card title="検査詳細設定">
          <Table
            dataSource={this.state.inspectData}
            loading={this.state.isLoadingTable}
            pagination={false}
            rowKey={record => record.id}
            scroll={{ y: 350 }}
          >
            <Table.Column title="No" dataIndex="group" width={100} align="center" />
            <Table.Column title="検査名称" dataIndex="display_exam_name" />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2670033_InspectAdvanced);
