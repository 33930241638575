import axios from "configs/axios";

const apiPaths = {
  getDataScreen: "/api/create-result-tbl/create-result-tbl/get-screen-data",
  TargetSelectSub: "/api/create-result-tbl/create-result-tbl/target-select-sub",
  print: "/api/create-result-tbl/create-result-tbl/print",
  preview: "/api/create-result-tbl/create-result-tbl/preview",
};

const CreateAResultTableService = {
  async getDataScreen(params) {
    return axios.get(apiPaths.getDataScreen, { params });
  },
  async TargetSelectSub(params) {
    return axios.get(apiPaths.TargetSelectSub, { params });
  },
  async print(params) {
    return axios.post(apiPaths.print, params);
  },

  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' });
  },
};

export default CreateAResultTableService;
