import InputOutputService from "services/SystemMaintenance/UserParamMaintain/InputOutputService"
import { message } from "antd";

const InputOutputAction = {
  getScreenData(data) {
    return InputOutputService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  runBtn_F12(data) {
    console.log('通過')
    return InputOutputService.runBtn_F12(data)
  },
  Input_F12(data) {
    return InputOutputService.Input_F12(data)
  },
  upload(data) {
    console.log('通過')
    return InputOutputService.upload(data)
  },

}
export default InputOutputAction;
