import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Form, } from 'antd'
import OutputPatternSubAction from 'redux/AdvancePreparation/DocumentManageMaintain/OutputPatternSub.action'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS1545002_OutputPatternTableView extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '出力パターン[詳細]'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSourcePattern: [],
      selectedRowKeysPattern: [],
      rowSelectedPattern: [],
      indexTablePattern: 0,
      old_document_number: null,

      dataSourceDetail: [],

    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getDataDetail(this.props.record)
  }

  /**
   * table Detail
   * 詳細情報の取得
   * @param {*} document_title
   */
  getDataDetail(record) {
    let params = {
      document_title: record.document_title
    }

    OutputPatternSubAction.getDataDetail(params)
      .then((res) => {
        //チェックボックス初期状態の設定
        let rows = []
        // StsOutputTargetがtrueのidを配列化
        res.forEach((item) => {
          if (item.StsOutputTarget) {
            rows.push(item.id)
          }
        })
        //selectedRowKeysにtrueのデータを設定
        this.setState({
          dataSourceDetail: res,
          selectedRowKeys: rows,
        })

        this.formRef.current?.setFieldsValue({
          dataTable: res
        })
      })
  }

  /**
   * チェックボックス個別選択
   * @param {*} record
   */
  eventSelectRecord(record) {
    console.log(record)
    OutputPatternSubAction.updateDetail(record)
      .then((res) => {
        this.getDataDetail(this.props.record)
      })
  }

  /**
   * チェックボックス全選択
   * @param {*} record 
   */
  eventSelectAllRecord(record) {
    OutputPatternSubAction.updateAllDetail(record)
      .then((res) => {
        this.getDataDetail(this.props.record)
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='output-pattern-sub'>
        <Card title={this.props.record.document_title + ' [詳細]'}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              size='small'
              dataSource={this.state.dataSourceDetail}
              pagination={false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ y: 550 }}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedRowKeys,
                //チェックボックス個別選択
                onSelect: (record, selected) =>
                  this.eventSelectRecord({
                    id: record.id,
                    targetTitle: record.document_title,
                    targetNumber: record.document_management_number,
                    document_title: this.props.record.document_title,
                    selected: selected,
                  }),

                //チェックボックス全選択
                onSelectAll: (selected) =>
                  this.eventSelectAllRecord({
                    selected: selected,
                    document_title: this.props.record.document_title,
                  }),
              }}
            >

              <Table.Column
                width={140}
                title='管理番号'
                dataIndex='document_management_number'
              />

              <Table.Column
                title='資料名称'
                dataIndex='document_title'
              />

              <Table.Column
                title='指定'
                align='center'
                width={40}
                render={(value, record, index) => {
                  return (
                    <div hidden={!record.StsDesignatedPrint}>
                      ○
                    </div>
                  )
                }}
              />

            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1545002_OutputPatternTableView)
