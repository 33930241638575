import { message } from "antd";
import CourseDepartmentspecificFormatService from "services/SK_IntroductionLetter/CourseDepartmentspecificFormat/CourseDepartmentspecificFormatService.js";

const CourseDepartmentspecificFormatAction = {
  getFormatList(data) {
    return CourseDepartmentspecificFormatService.getFormatList(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getSettedCourseList(data) {
    return CourseDepartmentspecificFormatService.getSettedCourseList(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveCourse(data) {
    return CourseDepartmentspecificFormatService.saveCourse(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteCourse(data) {
    return CourseDepartmentspecificFormatService.deleteCourse(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveDepartmentspecificFormat(data) {
    return CourseDepartmentspecificFormatService.saveDepartmentspecificFormat(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteDepartmentspecificFormat(data) {
    return CourseDepartmentspecificFormatService.deleteDepartmentspecificFormat(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default CourseDepartmentspecificFormatAction;