import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Checkbox, Select, Table, Row, Col, Button, Space, Dropdown, Menu, message, Modal } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import WS2713087_FormatCopy from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713087_FormatCopy.jsx'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery.jsx'
import WS0178001_QuerySiteInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0178001_QuerySiteInfo.jsx'
import WS0179001_InquiryFindingInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0179001_InquiryFindingInfo.jsx'
import NormalValueSettingAction from 'redux/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSetting.action'
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import WS2713501_NormalValueSettingEdit from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713501_NormalValueSettingEdit.jsx"
import WS2713502_NormalValueSettingInpu_sub from "./WS2713502_NormalValueSettingInput_sub.jsx";
const labelCol = {
  span: 4
}
const styleFormItem = {
  margin: 0,
}

const columns = [
  {
    title: 'フォーマット',
    dataIndex: 'format',
    // classNumber: '10',
    type: 1,
  },
]

class WS2713014_NormalValueSetting extends React.Component {

  constructor(props) {
    super(props)
    this.onFinish = this.onFinish.bind(this);
    this.formRef = React.createRef()

    // document.title = '正常値設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      patternSelectBox: [],
      formatSelectBox: [],
      mainSource: [],
    }
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true })
    NormalValueSettingAction.GetPatterFormatData()
      .then((res) => {
        console.log(res)
        this.setState({
          patternData: res.pattern
        })
        this.setState({
          formatData: res.formats
        })
        let patternSelectBox = []
        let formatSelectBox = []
        for (let i = 0; i < this.state.patternData.length; i++) {
          patternSelectBox.push(<Select.Option key={i} value={this.state.patternData[i].id}>{this.state.patternData[i].pattern_name}</Select.Option>);
        }

        for (let i = 0; i < this.state.formatData.length; i++) {
          formatSelectBox.push(<Select.Option key={i} value={this.state.formatData[i]}>{this.state.formatData[i]}</Select.Option>);
        }

        this.setState({ patternSelectBox: patternSelectBox })
        this.setState({ formatSelectBox: formatSelectBox })

      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingContractData: false });
      });
  }

  //★★編集保存ボタン★★//
  onSave(output) {
    this.setState({ isLoading: true });
    NormalValueSettingAction.onSave(output.itemChange)
      .then(res => {
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  //★★編集削除ボタン★★//
  Delete(params) {
    this.setState({ isLoading: true });
    NormalValueSettingAction.deleteRadioInfo(params.itemChange)
      .then(res => {
        this.loadMainSource();
        this.closeModal();
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  onFinish(values) {
    NormalValueSettingAction.GetScreenData(values)
      .then((res) => {
        this.setState({ mainSource: res })
      })
      .catch(error => {
        message.error(res.data.message);
      })
    // .finally(() => {
    //   this.setState({ isLoadingContractData: false });
    // });
  }

  normalValueSettingSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0285001_JudgeQuery
          Li_JudgeLevel={''}
          Lio_Judge={''}
          onFinishScreen={() => {
            this.formRef.current.setFieldsValue({
              Judge: ''
            })
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              }
            })
          }}
        />),
      },
    })
  }

  findingCodeSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0179001_InquiryFindingInfo
          Li_FindingsClassify
          Lo_FindingsCode
          Lo_JudgeValue
          onFinishScreen={() => {
            this.formRef.current.setFieldsValue({
              Judge: ''
            })
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              }
            })
          }}
        />),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='normal-value-setting'>
        <Card title='正常値設定保守'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Row gutter={24} className='mb-3'>
              <Col span={5}>
                <Form.Item name='Pattern' label='パターン' >
                  <Select
                    onSelect={() => { }}
                  >
                    {this.state.patternSelectBox}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='forMat' label='フォーマット'>
                  <Select
                    onSelect={() => { }}
                  >
                    {this.state.formatSelectBox}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Button type="primary" htmlType='submit' loading={this.state.isLoadingInsurersList}>
                  表示
                </Button>
              </Col>
            </Row>
            <Row gutter={24} className='mb-3'>
              <Col span={8}>
                <Form.Item name='SearchChar' label='検索' >
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={2}>
                <Form.Item name='StsUse' label='使用' valuePropName='checked' >
                  <Checkbox style={{ paddingTop: 5 }} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name='StsConfigured' label='設定済み' valuePropName='checked' >
                  <Checkbox style={{ paddingTop: 5 }} />
                </Form.Item>
              </Col> */}
            </Row>

            <Table bordered style={{ borderRight: '1px solid #F0F0F0', marginRight: 10 }} dataSource={this.state.mainSource} rowKey={record => record.seq}>
              <Table.Column title='検査' dataIndex='inspection' />
              <Table.Column title='名称' dataIndex='name' />
              <Table.Column title='タイプ' dataIndex='type' />
              <Table.Column title='判定' dataIndex='judgement' />
              <Table.Column title='規定値' dataIndex='minimal_required_value' />
              <Table.Column title='使用' dataIndex='use_status' />

              <Table.Column
                align='center'
                width={40}
                fixed='right'
                render={(text, record, index) => (
                  <Space>
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key='1' style={{ width: '100%' }}
                            size="small"
                            onClick={() => {
                              // 編集
                              let order = "edit";
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '400px',
                                  className: '',
                                  component: (
                                    <WS2713502_NormalValueSettingInpu_sub
                                      order={order}
                                      value={text}
                                      onClick={() => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                        this.loadMainSource(this.props);
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >変更</Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </Space>
                )}
              />

            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2713014_NormalValueSetting)
