import axios from "configs/axios";

const apiPaths = {
  addLocation: "/api/implementation-location-master/implement-location-master/add-location",
  postLocation: "/api/implementation-location-master/implement-location-master/post-location",
  getIndex: "/api/implementation-location-master/implement-location-master/get-index",
  deleteLocation: "/api/implementation-location-master/implement-location-master/delete-location",
};

const ImplementLocationMasterService = {

  async addLocation(params) {
    return axios.post(apiPaths.addLocation, params);
  },

  async postLocation(params) {
    return axios.post(apiPaths.postLocation, params);
  },

  async getIndex(params) {
    return axios.get(apiPaths.getIndex, { "params": params });
  },

  async deleteLocation(params) {
    return axios.delete(apiPaths.deleteLocation, { params });
  },

};

export default ImplementLocationMasterService;