import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { connect } from 'react-redux'
import moment from 'moment'
import { getMenuList } from 'redux/user/user.actions'
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Card, Form, Button, AutoComplete, Input, InputNumber, Modal } from 'antd'
import CorporateHearingSheetAction from 'redux/Others/CorporateHearingSheet/CorporateHearingSheet.action'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0341001_OfficeInfoMaintain from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341001_OfficeInfoMaintain'
import WS0084001_PostCodeSearchEngine from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import DestinationManagementAction from 'redux/Others/CorporateHearingSheet/DestinationManagement.action'

const destination = [
  {
    label: '自宅',
    value: '自宅'
  },
  {
    label: '本社',
    value: '本社'
  },
  {
    label: '支店',
    value: '支店'
  },
]

const sending_product = [
  {
    label: '案内状',
    value: '案内状'
  },
  {
    label: '結果表',
    value: '結果表'
  },
  {
    label: '紹介状',
    value: '紹介状'
  },
  {
    label: '請求書',
    value: '請求書'
  },
]

class SendingHistoryEdit extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '送付情報編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      id: '',
      office_code: '',
      branch_store_code: 0
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      id: this.props.id,
      office_code: this.props.office_code,
      branch_store_code: this.props.branch_store_code,
      date: this.props.date,
      destination: this.props.destination,
      sending_product: this.props.sending_product,
      number_of_people: this.props.number_of_people,
      remarks: this.props.remarks
    })
    this.setState({
      id: this.props.id,
      office_code: this.props.office_code,
      branch_store_code: this.props.branch_store_code,
    })
  }

  saveSendingHistory = () => {
    let params = {
      id: this.state.id,
      office_code: this.state.office_code,
      branch_store_code: this.state.branch_store_code,
      date: this.formRef.current?.getFieldValue('date'),
      destination: this.formRef.current?.getFieldValue('destination'),
      sending_product: this.formRef.current?.getFieldValue('sending_product'),
      number_of_people: this.formRef.current?.getFieldValue('number_of_people'),
      remarks: this.formRef.current?.getFieldValue('remarks'),
    }
    DestinationManagementAction.saveSendingHistory(params)
      .then(res => { this.props.onFinishScreen() })
  }

  deleteSendingHistory = () => {
    let params = {
      id: this.state.id
    }
    DestinationManagementAction.deleteSendingHistory(params)
      .then(res => { this.props.onFinishScreen() })
  }

  render() {
    return (
      <div className='destination-management' style={{ width: 600 }}>
        <Card title='送付情報'>
          <div className='box_container'>
            <Form ref={this.formRef}>
              <Form.Item label='日付' name='date' labelCol={{ span: 2 }}>
                <VenusDatePickerCustom
                  className='input-size-10'
                  formRefDatePicker={this.formRef}
                  format='YYYY/MM/DD'
                  // style={{ width: '100%' }}
                  allowClear={true}
                />
              </Form.Item>
              <Form.Item label='送付先' name='destination' labelCol={{ span: 2 }}>
                <AutoComplete options={destination} />
              </Form.Item>
              <Form.Item label='送付物' name='sending_product' labelCol={{ span: 2 }}>
                <AutoComplete options={sending_product} />
              </Form.Item>
              <Form.Item label='人数' name='number_of_people' labelCol={{ span: 2 }}>
                <InputNumber />
              </Form.Item>
              <Form.Item label='備考' name='remarks' labelCol={{ span: 2 }}>
                <Input.TextArea
                  style={{ height: '150px' }}
                />
              </Form.Item>
              <div className='box_button_bottom_right'>
                <Button
                  type='danger'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: "確認",
                      icon: <QuestionCircleOutlined style={{ color: "#1890ff" }} />,
                      content: "削除しますか？",
                      okText: "削除",
                      cancelText: "キャンセル",
                      onOk: () => {
                        this.deleteSendingHistory();
                      }
                    });
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
                <Button
                  type='primary'
                  onClick={() => this.saveSendingHistory()}
                >
                  <span className='btn_label'>
                    登録
                  </span>
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
  getMenuList: () => dispatch(getMenuList())
})
export default connect(mapStateToProps, mapDispatchToProps)(SendingHistoryEdit)