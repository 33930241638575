import React from "react";
import { connect } from "react-redux";

import { Card, Form, Modal, Button, Select, Radio, Input } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import ConditionsConfigurationEditAction from "redux/Others/ProgressManagement/ConditionsConfigurationEdit.action.js";
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '90px' } }
}

class ConditionsConfigurationEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "検索条件設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      screenData: [],
      course_name: '',
    };

    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    let data
    this.getScreenData()

    if (!this.props.newflag) {
      //初期データの反映
      data = {
        ...this.props.record,
      }
      this.formRef.current?.setFieldsValue(data)

      //コース名称の取得
      this.props.courseData.map((value, index) => {
        if (this.props.record.medical_exam_course == value.course_code) {
          this.setState({ course_name: value.course_name_short_name })
        }
      })
    }
  }

  /**
   * 初期表示処理
   */
  getScreenData() {
    ConditionsConfigurationEditAction.getScreenData()
      .then((res) => {
        this.setState({
          screenData: res ?? []
        })
      });
  }

  //保存ボタンが押された場合
  onFinish(values) {
    const data = {
      id: this.formRef.current?.getFieldValue('id'),
      medical_exam_course: values.medical_exam_course,
      conditions_age_code: values.conditions_age_code,
      enabled: values.enabled,
    }

    ConditionsConfigurationEditAction.setRegister(data)
      .then((res) => {
        this.props.onUpdate()
      });
  }

  //削除ボタンが押された場合
  delete() {
    const data = {
      id: this.formRef.current?.getFieldValue('id'),
    }

    ConditionsConfigurationEditAction.delete(data)
      .then((res) => {
        this.props.onUpdate()
      });
  }

  showWS0265001_BasicCourseInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (<WS0265001_BasicCourseInquiry
          onFinishScreen={(output) => {
            this.formRef.current?.setFieldsValue({
              medical_exam_course: output.Lo_CourseCode,
            });
            this.setState({
              course_name: output.Lo_CourseName
            })
            this.closeModal()
          }}
        />)
      }
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Card title={'検索条件設定 [' + (this.props.newflag ? '新規' : '変更') + ']'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <div className='box_container'>
              <div className="box_inner_horizontal">
                <Form.Item
                  label='コースコード'
                  name='medical_exam_course'//対応したものに書き換え
                  {...labelCol}
                >
                  <Input.Search
                    className="input-search-size-number-4"
                    onSearch={() => {
                      this.showWS0265001_BasicCourseInquiry()
                    }} />
                </Form.Item>
                <Form.Item>
                  <span>{this.state.course_name}</span>
                </Form.Item>
              </div>

              <Form.Item
                label='年齢条件'
                name='conditions_age_code'//対応したものに書き換え
                {...labelCol}
              >
                <Select allowClear>
                  {this.state.screenData.map((item, index) => {
                    return (
                      <Select.Option
                        key={item.id}
                        value={item.age_id_code}
                      >
                        {item.age_title}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label='検査状態'
                name='enabled'//対応したものに書き換え
                {...labelCol}
              >
                <Radio.Group>
                  <Radio value={1}>有効</Radio>
                  <Radio value={0}>無効</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionsConfigurationEdit);