import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import PropTypes from 'prop-types'
import { Card, Table, Input, Form } from 'antd'
import VenusApiRoutesV2 from 'redux/VenusApiRoutesV2/VenusApiRoutesV2'
import SiteInquiryAction from 'redux/InspectionMaintenance/RadiographyInspectMaintain/SiteInquiry.actions'

class WS1873003_SiteInquiry extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Lo_SiteClassify: PropTypes.any,
    Li_SiteClassifyCode: PropTypes.any,
    Lo_FindingsClassifyCode: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = '部位照会'

    this.state = {
      isLoading: true,
      isLoadingTable: true,
      dataSource: [],
      //dataSource_2: [],
      format: '',
      remarks: '',
      arrKeyCheck: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 部位一覧を取得
   */
  index = () => {
    SiteInquiryAction.index()
      .then(res => {
        console.log('res111->', res)
        this.setState({
          dataSource: res ? res : [],
          // remarks: res ? res[0].remarks : ''
        })
        // this.getInitialValue_secondTable(res[0].format)
      })
      .catch(error => {
        // console.log(error)
      })

  }

  getInitialValue = () => {
    this.setState({
      isLoadingTable: true
    })
    let params = {
      Li_InspectClassifyCode: 'CT',
      Li_Division: 1
    }

    VenusApiRoutesV2.callApi('API001873003002', params)
      .then(res => {
        console.log('res222=>', res)
        this.formRef.current?.setFieldsValue({
          tableData: res.data
        })

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }

  getInitialValue_secondTable = (value) => {
    this.setState({
      isLoadingTable: true
    })
    let params = {
      Li_InspectClassifyCode: 'CT',
      Li_Division: 1
    }

    VenusApiRoutesV2.callApi('API001873003003', params)
      .then(res => {
        console.log('res222=>', res)
        this.formRef.current?.setFieldsValue({
          tableData: res.data
        })

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ isLoadingTable: false })
      })
  }


  // getInitialValue_secondTable(value) {
  //   // console.log(this.state.format)
  //   let params = { Li_SiteClassifyCode: value }
  //   axios.get('/api/v2/radiography-inspect-maintain/site-inquiry/site-table', { params })
  //     .then(res => {
  //       console.log('res222=>', res)
  //       this.formRef.current?.setFieldsValue({
  //         tableData: res.data
  //       })
  //       this.forceUpdate()
  //       // this.setState({
  //       //   dataSource_2: res.data
  //       // })
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  //     .finally(() => this.setState({ isLoadingTable: false }))
  // }

  // getTableSiteTable() {
  //   let SiteTableDetail = this.dataSource2.filter(data => data.site_code === this.state.selectedRows[0].site_classification_code)
  //   this.setState({ dataSource2: SiteTableDetail })
  // }

  // getDataScreenInput(value) {
  //   this.setState({ isLoadingTable: true, format: value })
  //   AssociateInsureParamMaintainAction.input({ format: value }).then(res => {
  //     this.formRef.current?.setFieldsValue({
  //       tableData: res
  //     })
  //     this.forceUpdate()
  //   })
  //     .finally(() => this.setState({ isLoadingTable: false }))
  // }

  TableSiteInquiry() {
    return
  }

  TableSiteTable() {
    return
  }

  onSubmit() {
    const { onFinishScreen } = this.props
    if (onFinishScreen) {
      onFinishScreen({ Lo_SiteClassify: '' })
    }

  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {

    return (
      <div className='site-inquiry'>
        <Card title='所見照会' >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                name='searchChar'
                label='検索'
                style={{ marginBottom: 0 }}
              >
                <Input className='input-size-40' />
              </Form.Item>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={false}
                rowKey={(record) => record.id}
                scroll={{ y: 650 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        format: record.format,
                        remarks: record.remarks,
                      })
                      this.getInitialValue_secondTable(record.format)
                    }
                  }
                }}
              >
                <Table.Column
                  width={90}
                  title='部位コード'
                  dataIndex='site_classification_code'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='検索略称'
                  dataIndex='site_classification_name'
                />
                <Table.Column
                  title='部位名称'
                  dataIndex='site_classification_name'
                />
              </Table>
              {/* </Col>

            <Col span={16}>
              <Table bordered
                // dataSource={this.formRef.current?.getFieldValue('tableData') ? this.formRef.current?.getFieldValue('tableData') : []}
                dataSource={this.formRef?.current?.getFieldValue('dataSource')}
                size='small'
                pagination={false}
                loading={this.state.isLoadingTable}
                rowKey={(record) => record.id}
                scroll={{ y: 680 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      const found = this.state.arrKeyCheck.indexOf(record.id)
                      if (found < 0) {
                        let data = [...this.state.arrKeyCheck]
                        data.push(record.id)
                        this.setState({
                          arrKeyCheck: data
                        })
                      }
                    }

                  }
                }}
              >
                <Table.Column title='SEQ' width={100} render={(value, record, index) => {
                  return <Form.Item name={['tableData', index, 'seq']} style={{ marginBottom: '0px' }}>
                    <Input type='number' style={{ border: '0px' }} />
                  </Form.Item>
                }} />

                <Table.Column title='ｺｰﾄﾞ' dataIndex='site_code'
                  width={90}
                  render={(value, record, index) => {
                    return (<div style={{ textAlign: 'right' }}><span>{record?.site_code}</span></div>)
                  }}
                />
                <Table.Column width={180} title='検索略名' dataIndex='search_short_name' />
                <Table.Column title='部位名称' dataIndex='site_name' />

              </Table> */}
              {/* </Col>
              </Row> */}
              {/* <Row gutter={24}>
                <Col span={20}></Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <Button onClick={() => this.onSubmit()} type='primary'>選択</Button></Col>
              </Row> */}
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1873003_SiteInquiry)
