import SiteFindingsMasterMaintainService from "services/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintainService";

export const getDataSiteFindingsMasterMaintainAction = () => {
  return SiteFindingsMasterMaintainService.getDataSiteFindingsMasterMaintainService()
}

export const getSiteTableAndFindingsTableAction = (params) => {
  return SiteFindingsMasterMaintainService.getSiteTableAndFindingsTableService(params)
}

export const saveAndUpdateSiteFindingsMasterMaintainAction = (params) => {
  return SiteFindingsMasterMaintainService.saveAndUpdateSiteFindingsMasterMaintainService(params)
}

export const deleteSiteFindingsMasterMaintainAction = (id) => {
  return SiteFindingsMasterMaintainService.deleteSiteFindingsMasterMaintainService(id)
}

export const deleteSiteTableAction = (params) => {
  return SiteFindingsMasterMaintainService.deleteSiteTableService(params)
}

export const deleteFindingsTableAction = (params) => {
  return SiteFindingsMasterMaintainService.deleteFindingsTableService(params)
}

//SiteFindingsMasterMaintainSub
export const registerSiteFindingsData = (params) => {
  return SiteFindingsMasterMaintainService.registerSiteFindingsDataService(params)
}

export const getPartsData = (params) => {
  return SiteFindingsMasterMaintainService.getPartsDataService(params)
}

export const getFindingsData = (params) => {
  return SiteFindingsMasterMaintainService.getFindingsDataService(params)
}

export const deleteSiteFindingsData = (params) => {
  return SiteFindingsMasterMaintainService.deleteSiteFindingsDataService(params)
}


//WS0356021_RegisterPartsData
export const registerParts = (params) => {
  return SiteFindingsMasterMaintainService.registerPartsService(params)
}

export const deleteParts = (params) => {
  return SiteFindingsMasterMaintainService.deletePartsService(params)
}

//WS0356022_RegisterFindingsData
export const registerFindings = (params) => {
  return SiteFindingsMasterMaintainService.registerFindingsService(params)
}

export const deleteFindings = (params) => {
  return SiteFindingsMasterMaintainService.deleteFindingsService(params)
}