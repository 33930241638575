import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import WS0431001_CsvPreviewConfirm from 'pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import { download_file } from "helpers/CommonHelpers";
import { Card, Table, Form, Checkbox, Select, Button, Spin, message } from "antd";
import BusinessEstablishmentsSubjectToSelectAction from "redux/StatisticalServices/OfficeVisitsAchievementSituation/BusinessEstablishmentsSubjectToSelect.action";
const statusArr = [
  { DisplayField: '未受診', LinkedField: 0 },
  { DisplayField: '予約', LinkedField: 1 },
  { DisplayField: '受付', LinkedField: 2 },
  { DisplayField: '予約受付', LinkedField: 3 },
  { DisplayField: '新規', LinkedField: 4 },
]
const dataSource = [{
  id: 1,
  W3_object_sect: 1,
  W3_status_sect: 1, OfficeCode: '14',
  W3_office_name_kana: 'John', office_kanji_name: 'small',
  Expression_4: '40', Expression_5: '40', Expression_6: 0, Expression_7: 1
},
{
  id: 2,
  W3_object_sect: 0,
  W3_status_sect: 3, OfficeCode: '14',
  W3_office_name_kana: 'XXXX', office_kanji_name: '12',
  Expression_4: '40', Expression_5: '45', Expression_6: 1, Expression_7: 1
}
]
class WS1015015_BusinessEstablishmentsSubjectToSelect extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    onFinishScreen: PropTypes.func,
    /**
     * 実績期間from 
     * YYYY/MM/DD
     */
    implementPeriodFChar: PropTypes.string,
    /**
     * 実績期間to
     * YYYY/MM/DD 
     */
    implementPeriodTChar: PropTypes.string,
    /**
     * チェック期間from
     * YYYY/MM/DD 
     */
    checkPeriodFChar: PropTypes.string,
    /**
     * チェック期間to
     * YYYY/MM/DD
     */
    checkPeriodTChar: PropTypes.string,
  }
  constructor(props) {
    super(props);

    // document.title = '対象事業所選択';

    this.state = {
      selectedRows: [],
      selectedRowKeys: [],
      indexTable: 0,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      /**
       * ローディング中か？
       * @type {boolean}
       */
      isLoading: false,
      /**
       * 表のデータ
       * @type {Array.<string>}
       */
      dataRows: [],
      /**
       * 出力用headerの定義
       * @type {Array.<Object>}
       */
      headers: [
        'W3_status_sect',
        'OfficeCode',
        'W3_office_name_kana',
        'office_kanji_name',
        'Expression_4',
        'Expression_5'
      ],
    };
  }
  /**
   * init処理
   */
  componentDidMount() {
    dataSource.forEach((data) => {
      // W3_object_sect をbooleanに変換
      data.W3_object_sect = !!data.W3_object_sect;
    })
    this.setState({
      dataRows: dataSource,
    })
  }
  /**
   * 状況select選択時
   * @param {number} status
   * @param {number} index 行のindex
   */
  onChangeStatus(status, index) {
    this.state.dataRows[index].W3_status_sect = status;
    this.setState({
      dataRows: this.state.dataRows,
    })
  }
  /**
   * 選択ON OFF時
   * @param {boolean} isSelected
   * @param {number} index 行のindex
   */
  onChangeSelect(isSelected, index) {
    this.state.dataRows[index].W3_object_sect = isSelected;
    this.setState({
      dataRows: this.state.dataRows,
    })
  }

  /**
   * 出力モーダル表示
   */
  outputSelect() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component:
          <WS0431001_CsvPreviewConfirm
            Lio_OutputMethod
            Lio_Output
            Lio_Preview
            onFinishScreen={(output) => {
              if (output.Lio_OutputMethod) {
                this.closeModal();
                this.downloadFile(output)
              }
            }}
          />
        ,
      },
    });
  }
  /**
   * 印刷/プレビュー/csv出力
   * @param {Object} output 出力形式
   */
  downloadFile(output) {
    // try {
    this.setState({ isLoading: true });

    // 出力するデータをフィルタリング,整形する
    const exportRows = this.state.dataRows.filter((row) => {
      return row.W3_object_sect;
    }).map((row) => {
      return this.exportRowShaping(row);
    });

    let params = {
      // 出力する行のデータ
      exportRows: exportRows,
      // 実績期間
      implementPeriodFChar: this.props.implementPeriodFChar,
      implementPeriodTChar: this.props.implementPeriodTChar,
      // チェック期間
      checkPeriodFChar: this.props.checkPeriodFChar,
      checkPeriodTChar: this.props.checkPeriodTChar,
      // ファイル名 csvの場合に使用
      Output: output.Lio_Output + '.csv',
    }
    let promise;
    if (output.Lio_OutputMethod === '0') { // 印刷/プレビュー
      // バックエンドで印刷用htmlを生成して返してもらう
      promise = BusinessEstablishmentsSubjectToSelectAction.Print(params);
    } else { // csv出力
      promise = BusinessEstablishmentsSubjectToSelectAction.ExportCsv(params);
    }
    promise.then(res => {
      download_file(res, output.Lio_Preview);
    }).catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました');
        return;
      }
      message.error(res.data.message);
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }
  /**
   * @param {Array.<string|number>} row 出力する行のデータ
   * @return {Array.<string|number>}
   */
  exportRowShaping(row) {
    return this.state.headers.map((header) => {
      if (header === 'W3_status_sect') { // 状況を変数から日本語に変換
        const val = row[header];
        return statusArr.find((status) => {
          return val === status.LinkedField;
        }).DisplayField;
      }
      return row[header];
    })
  }
  onFinish = (values) => { }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="business-establishments-subject-to-select">
        <Spin spinning={this.state.isLoading}>
          <Form ref={this.formRef} onFinish={this.onFinish}
            initialValues={{
              dataRows: this.state.dataRows,
            }}
          >
            <Card title="対象事業所選択">
              <Table bordered
                dataSource={this.state.dataRows}
                pagination={false}
                size='small'
                scroll={{ y: 600 }}
                loading={false}
                rowKey={(record) => record.id}
                rowClassName={(record, index) => record.id === this.state.selectedRows[0]?.id ? 'table-row-light' : ''}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedRows: [record],
                        selectedRowKeys: [record.id],
                        indexTable: rowIndex,
                      })
                    }
                  };
                }}
              >
                <Table.Column width={50} title="" dataIndex="W3_object_sect"
                  render={(value, record, index) => {
                    return <Checkbox
                      key={record.W3_object_sect}
                      defaultChecked={record.W3_object_sect}
                      onChange={(e) => this.onChangeSelect(e.target.checked, index)}
                    ></Checkbox>
                  }} />
                <Table.Column title="状況" width={120} dataIndex="W3_status_sect"
                  render={(value, record, index) => {
                    return <Select
                      key={record.W3_status_sect}
                      defaultValue={record.W3_status_sect}
                      onChange={(e) => this.onChangeStatus(e, index)}
                    >
                      {statusArr.map((status, i) => (
                        <Select.Option key={i} value={status.LinkedField}>{status.DisplayField}</Select.Option>
                      ))}
                    </Select>
                  }}
                />
                <Table.Column title="事業所ｺｰﾄﾞ" width={150} dataIndex="OfficeCode" />
                <Table.Column title="事業所名(カナ)" width={170} dataIndex="W3_office_name_kana" />
                <Table.Column title="事業所名(漢字)" width={170} dataIndex="office_kanji_name" />
                <Table.Column title="前年度情報" width={170} dataIndex="Expression_4" render={(value, record, index) => {
                  return <div hidden={record.Expression_7 === 0}>{value}</div>
                }} />
                <Table.Column title="今年度情報" width={170} dataIndex="Expression_5"
                  render={(value, record, index) => {
                    return <div hidden={record.Expression_6 === 0}>{value}</div>
                  }}
                />

              </Table>
              <div style={{ marginTop: '10px', float: 'right' }} ><Button type="primary" onClick={() => this.outputSelect()}>出力</Button></div>
            </Card>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1015015_BusinessEstablishmentsSubjectToSelect);
