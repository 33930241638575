import { message } from "antd";
import RadiographyGuideMaintainService from "services/InspectionMaintenance/RadiographyInspectMaintain/RadiographyGuideMaintainService.js";

const RadiographyGuideMaintainAction = {
  getScreenData(params) {
    return RadiographyGuideMaintainService.getScreenData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  save(params) {
    return RadiographyGuideMaintainService.save(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  delete(params) {
    return RadiographyGuideMaintainService.delete(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  getCommentMaster(params) {
    return RadiographyGuideMaintainService.getCommentMaster(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }

}

export default RadiographyGuideMaintainAction;