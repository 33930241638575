
import axios from 'configs/axios'

const API_LIST = {
  index: '/api/personal-reserve-process',
  getDataScreenUpdate: '/api/personal-reserve-process/input-item-update',
  getDataSetSelect: '/api/personal-reserve-process/set-select',
  getDataInspectSelect: '/api/personal-reserve-process/inspect-select',
  getDataSelectOptions: '/api/personal-reserve-process/select-options',
  getDataInspectChange: '/api/personal-reserve-process/inspect-change',
  getNewInspectChange: '/api/personal-reserve-process/get-new-inspect-change',
  contractRedisplay: '/api/personal-reserve-process/contract-redisplay',
  setChange: '/api/personal-reserve-process/set-change',
  setDelete: '/api/personal-reserve-process/set-delete',
  breakDown: '/api/personal-reserve-process/breakdown',
  checkLatestReserve: '/api/personal-reserve-process/check-latest-reserve',

  ConfirmProcessBtn_F12: '/api/personal-reserve-process/confirm-process-btn-f12',
  newConfirm: '/api/personal-reserve-process/new-confirm',
  acceptChangeConfirm: '/api/personal-reserve-process/accept-change-confirm',
  reserveChangeConfirm: '/api/personal-reserve-process/reserve-change-confirm',
  ReserveChangeAndAccept: '/api/personal-reserve-process/reserve-change-and-accept',

  updateData: '/api/personal-reserve-process/update-or-create',
  updateDataTax: '/api/personal-reserve-process/save-data-right',
  updateDataBilling: '/api/personal-reserve-process/update-billing-data',

  userAction3: '/api/personal-reserve-process/user-action-3',

  getInfoPersonal: '/api/personal-reserve-process/get-info-personal-basic',
  getInfoOffice: '/api/personal-reserve-process/get-info-office-code',
  changeBelongOffice: '/api/personal-reserve-process/change-belong-office',
  getInfoConsultCourse: '/api/personal-reserve-process/get-info-consult-course',
  changeReserveNum: '/api/personal-reserve-process/reserve-num',
  ConsultHistory: '/api/personal-reserve-process/consult-history',
  getDataIndex: '/api/personal-reserve-process/get-data-index', // get data input

  getOtionUnitTaxPrice: '/api/personal-reserve-process/get-otion-unit-tax-price', // 一時的な使用

  billingAddressSave: '/api/personal-reserve-process/billing-address-save',

  getBillingAddressList: '/api/personal-reserve-process/get-billing-address-list',
  runEMedicalRecords: '/api/personal-reserve-process/run-e-medical-records',
}

const PersonalReserveProcessService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },

  async getDataScreenUpdate(params) {
    return axios.get(API_LIST.getDataScreenUpdate, { params })
  },

  async getDataSetSelect(params) {
    return axios.get(API_LIST.getDataSetSelect, { params })
  },

  async getDataInspectSelect(params) {
    return axios.post(API_LIST.getDataInspectSelect, params)
  },

  async getDataSelectOptions(params) {
    return axios.get(API_LIST.getDataSelectOptions, { params })
  },

  async getDataInspectChange(params) {
    return axios.get(API_LIST.getDataInspectChange, { params })
  },

  async getNewInspectChange(params) {
    return axios.post(API_LIST.getNewInspectChange, params)
  },

  async contractRedisplay(params) {
    return axios.post(API_LIST.contractRedisplay, params)
  },

  async setChange(params) {
    return axios.get(API_LIST.setChange, { params })
  },

  async setDelete(params) {
    return axios.delete(API_LIST.setDelete, { params })
  },

  async breakDown(params) {
    return axios.get(API_LIST.breakDown, { params })
  },

  async checkLatestReserve(params) {
    return axios.get(API_LIST.checkLatestReserve, { params })
  },

  async ConfirmProcessBtn_F12(params) {
    return axios.post(API_LIST.ConfirmProcessBtn_F12, params)
  },

  // async amountCalculate(params) {
  //   return axios.post(API_LIST.amountCalculate, params)
  // },

  // async newReceptConfirmed(params) {
  //   return axios.post(API_LIST.newReceptConfirmed, params)
  // },

  async newConfirm(params) {
    return axios.post(API_LIST.newConfirm, params)
  },

  async acceptChangeConfirm(params) {
    return axios.post(API_LIST.acceptChangeConfirm, params)
  },

  async reserveChangeConfirm(params) {
    return axios.post(API_LIST.reserveChangeConfirm, params)
  },

  async ReserveChangeAndAccept(params) {
    return axios.post(API_LIST.ReserveChangeAndAccept, params)
  },

  async updateData(params) {
    return axios.post(API_LIST.updateData, params)
  },

  async updateDataTax(params) {
    return axios.post(API_LIST.updateDataTax, params)
  },

  async updateDataBilling(params) {
    return axios.post(API_LIST.updateDataBilling, params)
  },

  async userAction3(params) {
    return axios.post(API_LIST.userAction3, params)
  },

  async getInfoPersonal(params) {
    return axios.get(API_LIST.getInfoPersonal, { params })
  },

  async getInfoOffice(params) {
    return axios.get(API_LIST.getInfoOffice, { params })
  },

  async changeBelongOffice(params) {
    return axios.get(API_LIST.changeBelongOffice, { params })
  },

  async getInfoConsultCourse(params) {
    return axios.get(API_LIST.getInfoConsultCourse, { params })
  },

  async changeReserveNum(params) {
    return axios.post(API_LIST.changeReserveNum, params)
  },

  async ConsultHistory(params) {
    return axios.post(API_LIST.ConsultHistory, params)
  },

  async getDataIndex(params) {
    return axios.post(API_LIST.getDataIndex, params)
  },

  async getOtionUnitTaxPrice(params) {
    return axios.post(API_LIST.getOtionUnitTaxPrice, params)
  },

  async billingAddressSave(params) {
    return axios.post(API_LIST.billingAddressSave, params)
  },

  async getBillingAddressList(params) {
    return axios.post(API_LIST.getBillingAddressList, params)
  },

  async runEMedicalRecords(params) {
    return axios.post(API_LIST.runEMedicalRecords, params)
  },

}

export default PersonalReserveProcessService
