import axios from 'configs/axios'

const apiPaths = {
  index: '/api/radiography-finding-input/radiography-finding-input-sub',
  listDataFindingsContent: '/api/radiography-finding-input/radiography-finding-input-sub/get-list-findings-content',
  listDataPreviousFindingsContent: '/api/radiography-finding-input/radiography-finding-input-sub/get-list-previous-findings-content',
  lastTimeDoBtn: '/api/radiography-finding-input/radiography-finding-input-sub/last-time-do-btn',
  lastTimeDoBtnYes: '/api/radiography-finding-input/radiography-finding-input-sub/last-time-do-btn-yes',
  settingBtn: '/api/radiography-finding-input/radiography-finding-input-sub/setting-btn',
  changeSiteAndFindingsCode: '/api/radiography-finding-input/radiography-finding-input-sub/change-site-and-findings',
  saveFindingsContent: '/api/radiography-finding-input/radiography-finding-input-sub/save-findings-content',
  deleteFindingsContent: '/api/radiography-finding-input/radiography-finding-input-sub/delete-findings-content',
  confirm: '/api/radiography-finding-input/radiography-finding-input-sub/confirm',
}

const RadiographyFindingInputSubService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async listDataFindingsContent(params) {
    return axios.get(apiPaths.listDataFindingsContent, { params })
  },
  async listDataPreviousFindingsContent(params) {
    return axios.get(apiPaths.listDataPreviousFindingsContent, { params })
  },
  async lastTimeDoBtn(params) {
    return axios.get(apiPaths.lastTimeDoBtn, { params })
  },
  async lastTimeDoBtnYes(params) {
    return axios.post(apiPaths.lastTimeDoBtnYes, params)
  },
  async settingBtn(params) {
    return axios.post(apiPaths.settingBtn, params)
  },
  async changeSiteAndFindingsCode(params) {
    return axios.get(apiPaths.changeSiteAndFindingsCode, { params })
  },
  async saveFindingsContent(params) {
    return axios.post(apiPaths.saveFindingsContent, params)
  },
  async deleteFindingsContent(params) {
    return axios.delete(apiPaths.deleteFindingsContent, { params })
  },
  async confirm(params) {
    return axios.post(apiPaths.confirm, params)
  }
}

export default RadiographyFindingInputSubService
