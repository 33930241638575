import axios from 'configs/axios';

const apiPaths = {
  getScreenData: "/api/menu-customization/my-menu/getscreendata",

};

const MyMenuService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },

};
export default MyMenuService;