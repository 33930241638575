import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Table, Select, Input, Menu, Tooltip, Dropdown, Modal, Descriptions, message } from 'antd'
import { MoreOutlined, QuestionCircleTwoTone, SaveOutlined, EditOutlined } from '@ant-design/icons'
import Color from 'constants/Color'
import moment from 'moment'
import GetImage from 'constants/Images'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import recalculate from 'components/Commons/recalculate'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2637002_OverallResultDisplayChange from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637002_OverallResultDisplayChange.jsx'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS0728001_FindingsInputNormal from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0728001_FindingsInputNormal.jsx'
import WS0729001_FindingsInputRadiography from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729001_FindingsInputRadiography.jsx'
import WS0730001_FindingsInputPhysiciiagnosis from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0723001_AutoJudgeScreen from 'pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS0723001_AutoJudgeScreen.jsx'


const labelSize = {
  labelCol: { style: { width: 65 } },
  wrapperCol: { style: { width: 'calc(100% - 65px)' } },
  style: { marginBottom: 0 }
}

const labelSize2 = {
  labelCol: { style: { width: 55 } },
  wrapperCol: { style: { width: 'calc(100% - 65px)' } },
  style: { marginBottom: 0 }
}

class WS2637090_OverallResultDisplayInputHybrid extends React.Component {

  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '総合結果表示'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      values: {},
      selectedRecord: false,
      changeData: [],
      categoryJudgeList: [], // カテゴリ別結果一覧
      inspectResultList: [], // 検査別結果一覧
      guideCommentInputList: [],
      selectCategory: null,
      disabledUpdateBtn: true,
      MemoFunctionUpdateBtn: true,
      protectFlag: false
    }
  }


  /**
  * 初期表示処理
  */
  componentDidMount = async () => {
    this.index()
  }


  /**
   * 該当の予約番号のデータ取得
   */
  index = () => {
    // パラメータ取得
    const params = this.requestParamScreenData()

    // データ取得
    ResultDisplayAction.index(params)
      .then(async (res) => {
        this.formRef.current?.setFieldsValue({
          provisional: res.provisionalFlag
        })
        this.setState({
          values: res
        })

        // カテゴリ別判定結果の一覧を取得
        await this.getCategoryJudgeList()
        // 指導事項コメントを取得
        await this.getGuideCommentInput()
      })
  }


  /**
  * カテゴリ別判定結果の一覧を取得
  */
  getCategoryJudgeList = () => {
    const HistoryAdded = this.state.values.HistoryAdded

    const params = {
      ...this.getParamObject(),
      Li_PersonalNum: this.state.values.personal_number_id,
      Li_VisitDate: moment(this.state.values.DateChar).format('YYYY/MM/DD'),
      SelectCategoryList: this.state.values.SelectCategoryList,
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeCategoryJudge: this.state.values.StsInputModeCategoryJudge,
      InspectNormalValueUpdateOp: this.state.values.InspectNormalValueUpdateOp,
      HandInputConfirm: this.state.values.HandInputConfirm ? 1 : 0,
      pattern_code: this.state.values.pattern_code,
      Li_PatternCode: this.state.values.pattern_code,
      HistoryAdded,
      AllSelect: this.state.values.AllSelect,
      pre1ReserveNum: this.state.values.pre1ReserveNum,
      pre1VisitDate: this.state.values.pre1VisitDate,
      pre2ReserveNum: this.state.values.pre2ReserveNum,
      pre2VisitDate: this.state.values.pre2VisitDate,
    }

    OverallResultDisplayInputAction.getCategoryJudgeList(params)
      .then((res) => {
        if (res) {
          const data = [...res.categoryJudgeList]

          this.setState({
            categoryJudgeList: data,
          })

          if (this.state.selectedRecord) {
            const newSelectedData = data.filter((row) => row["category_code"] == this.state.selectedRecord.category_code);
            this.setState({ selectedRecord: newSelectedData[0] })
          }
        }
      })
  }


  /**
  * 指導事項コメントを取得
  * @param {*} modalUpdateFlag
  */
  getGuideCommentInput = (modalUpdateFlag = false) => {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: this.state.values.Li_JudgeLevel,
      StsInputModeGuideCmt: this.state.values.StsInputModeGuideCmt,
    }

    OverallResultDisplayInputAction.getGuideCommentInput(params)
      .then((res) => {
        if (res) {
          // 返却値をコピー
          let data = [...res]

          // コメントまとめ用変数
          let commentAll = ''
          data.forEach(item => {
            // general_comment_codeを加工
            item.general_comment_code = (item.general_comment_code === 0) ? '' : item.general_comment_code
            // １つの変数にコメントをまとめる
            commentAll += (item.overall_comment + '\r\n')
          })

          this.setState({
            guideCommentInputList: data,
            guideCommentAll: commentAll
          })

          if (modalUpdateFlag) {
            // 総合結果入力 モーダルに最新の指導事項コメントを反映させる
            this.showWS2637002_OverallResultDisplayChange()
          }
        }
      })
  }



  /**
  * 変更ボタン押下処理
  * 総合結果入力 モーダルを表示
  */
  showWS2637002_OverallResultDisplayChange = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637002_OverallResultDisplayChange
            values={this.state.values}
            paramObject={this.getParamObject()}
            guideCommentInputList={this.state.guideCommentInputList}
            onUpdateGuide={() => {
              if (this.props.onUpdate) {
                this.getGuideCommentInput(true)
              }
            }}

            onUpdateJudgment={() => {
              // 総合判定を取得
              this.index()
            }}
          />
        ),
      },
    })
  }


  /**
  *　判定一覧モーダルを開く（カテゴリ用）
  * @param {*} data
  * @param {*} index
  */
  showWS0285001_JudgeQueryCategory = () => {
    const data = { ...this.state.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={data?.judgment_level_division}
            Lio_Judge={data?.category_judge}
            onFinishScreen={async (output) => {
              if (output.recordData) {

                let judgmentResult = output.recordData.judgment_result
                if (judgmentResult === '') {
                  judgmentResult = '判定なし'
                }

                Modal.confirm({
                  content: `カテゴリ判定を [${output.recordData.judgment_result}] に変更します。よろしいですか？`,
                  okText: '保　存',
                  cancelText: 'キャンセル',
                  onOk: () =>
                    // カテゴリ判定を保存
                    this.saveJudgmentCategory(output.recordData)
                })
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
  * カテゴリ判定を保存
  * @param {*} value
  */
  saveJudgmentCategory = (value) => {
    let paramObject = this.getParamObject()
    const params = {
      Li_ReserveNum: paramObject.Li_ReserveNum,
      Li_CategoryCode: this.state.selectedRecord.category_code,
      Li_CourseLevel: paramObject.Li_CourseLevel,
      Li_CategoryJudgment: value.judgment_result,
      Li_JudgeLevel: this.state.selectedRecord.Li_JudgeLevel,
    }

    OverallResultDisplayInputAction.saveJudgmentCategory(params)
      .then(async (res) => {
        this.getCategoryJudgeList()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({ content: res.data.message })
      })
  }


  /**
  * 紹介状抽出保守　モーダル
  */
  showWS0898001_IntroduceLetterExtractMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0898001_IntroduceLetterExtractMaintain
            Li_PersonalNum={this.state.values.personal_number_id}
            Li_VisitDate={this.state.values.visit_date_on}
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            values={this.state.values}
            onClickedCreate={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }


  /**
   * 編集用のモーダルを表示する
   * @param {*} record
   */
  showEditModal = async (record) => {
    if (!this.state.disabledUpdateBtn) {
      await this.setState({ saveFlag: true })
    }

    if (record.textPositionExamType && record.StsInspectCmtInfo) {
      // 検査コメント検索・照会　モーダル 大体はこのモーダルを使用
      this.showWS0274001_InspectCmtSearchQuery(record)
    } else if (record.exam_type.slice(0, 1) === 'N') {
      // 直接入力系（N系）は何も呼ばない
      message.info('入力フォームに直接入力してください')
      return
    } else {
      // 検査コメント照会以外(所見関連)のモーダル
      // 検査によって表示する編集用モーダルを切り替える
      this.switchEditModal(record)
    }
  }


  /**
  * 検査コメント検索・照会　モーダルを開く
  */
  showWS0274001_InspectCmtSearchQuery = (record) => {
    // コメント区分(exam_comment_code)が0の場合はモーダルは表示しない
    if ((record.exam_comment_code ?? 0) > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 600,
          component: (
            <WS0274001_InspectCmtSearchQuery
              // Li_PatternCode={this.props.paramObject.Li_PatternCode}
              Li_CategoryCode={record.category_code}
              Li_CategoryName={record.category_name}
              Lio_StsNotesChange={record.StsNotesChange}
              Lio_CmtClassify={record.exam_comment_code}
              LnkOutInspectCmtScreen={record.result_value}
              onFinishScreen={(output) => {
                // 選択された検査コメントを反映
                const value = output.LnkOutInspectCmtScreen
                // 結果値が変更になった場合
                this.changeResultValue(value, record)
                // モーダルを閉じる
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }


  /**
  * 変更された検査結果の更新
  */
  saveResultValueList = () => {
    let paramObject = this.getParamObject()

    const params = {
      Li_CourseLevel: paramObject.Li_CourseLevel,
      Li_ReserveNum: paramObject.Li_ReserveNum,
      changeData: this.state.changeData,
      saveFlag: this.state.saveFlag
    }

    OverallResultDisplayInputAction.saveResultValueList(params)
      .then((res) => {
        // カテゴリ別の詳細な検査結果一覧を取得
        this.getInspectResultList()
        this.setState({ saveFlag: false })
      })
  }


  /**
  * カテゴリ別の詳細な検査結果一覧を取得
  */
  getInspectResultList = () => {
    const values = this.state.values
    const HistoryAdded = values.HistoryAdded //履歴
    const NonInput = values.NonInput
    const Outliers = values.Outliers
    const NonInputOrOutliers = NonInput === 1 ? { NonInput } : { Outliers }
    const NormalJudge = values.NormalJudge

    const params = {
      ...this.getParamObject(),
      StsInputModeInspect: values.StsInputModeInspect,
      StsInputModeFindings: values.StsInputModeFindings,
      SelectCategoryList: values.SelectCategoryList,
      Li_JudgeLevel: this.state.selectedRecord.Li_JudgeLevel,
      SelectCategory: this.state.selectedRecord.SelectCategory,
      UndeterminedColorOp: values.UndeterminedColorOp,
      StsParkModeCategoryUpAndDown: values.StsParkModeCategoryUpAndDown,
      StsInputModeInspectCmtChiba: values.StsInputModeInspectCmtChiba,
      HistoryAdded,
      ...NonInputOrOutliers,
      NormalJudge,
      CategoryDisplayPosition: values.CategoryDisplayPosition,
      sex: values.sex,
      birthday_on: values.birthday_on,
      visit_date_on: values.visit_date_on,
      Li_DateOfBirth: values.Li_DateOfBirth,
      pre1ReserveNum: values.pre1ReserveNum,
      pre2ReserveNum: values.pre2ReserveNum,
    }

    OverallResultDisplayInputAction.getInspectResultList(params)
      .then((res) => {
        this.setState({
          inspectResultList: res,
          changeData: res,
          disabledUpdateBtn: true,
        })
        this.formRef?.current?.setFieldsValue({
          formInspectResultList: res
        })
      })
  }

  /**
  *
  * @param {*} record
  */
  switchEditModal = (record) => {
    const params = {
      ...this.getParamObject(),
      Li_JudgeLevel: record.Li_JudgeLevel,
      display_order: record.display_order,
      display_no: record.display_no,
      pattern_code: record.pattern_code,
      category_code: record.category_code,
      personal_number_id: this.state.values.personal_number_id,
      exam_code: record.exam_code,
      GuideHowToAddOp: this.state.values.GuideHowToAddOp,
      StsInputModeFindings: this.state.values.StsInputModeFindings,
    }

    // exam_typeが「S」である場合
    OverallResultDisplayInputAction.findingsEditingBefore(params)
      .then((res) => {
        if (res) {
          const messagex = res.message ?? ''
          const variables = res.variables ?? null

          if (this.state.values.StsInputModeFindings === 1) {
            if (messagex === 'Call Screen WS0730001') {
              // 所見入力[医師診断]
              this.showWS0730001_FindingsInputPhysiciiagnosis(variables, record)
            }
            if (messagex === 'Call Screen WS0729001') {
              // 所見入力[読影]
              this.showWS0729001_FindingsInputRadiography(variables, record)
            }
            if (messagex === 'Call Screen WS0731001' || messagex === 'Call Screen WS0728001') {
              // 所見入力[通常]
              this.showWS0728001_FindingsInputNormal(variables, record)
            }
          }
        }
      })
  }


  /**
   * 所見入力[医師診断]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0730001_FindingsInputPhysiciiagnosis = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.state.values.personal_number_id}
            KanjiName={this.state.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }


  /**
  * 所見入力[読影]　モーダル
  * @param {*} variables
  * @param {*} record
  */
  showWS0729001_FindingsInputRadiography(variables, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0729001_FindingsInputRadiography
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.state.values.personal_number_id}
            KanjiName={this.state.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }


  /**
  * 所見入力[通常]　モーダル
  * @param {*} variables
  * @param {*} record
  */
  showWS0728001_FindingsInputNormal = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1100,
        component: (
          <WS0728001_FindingsInputNormal
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.state.values.personal_number_id}
            KanjiName={this.state.values.kanji_name}
            onUpdateFindings={() => {
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }


  /**
  * 判定選択　(検査コード毎用)
  * @param {*} data
  */
  showWS0285001_JudgeQueryInspect = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={Number(record.Li_JudgeLevel)}
            onFinishScreen={async (output) => {
              if (output.recordData) {
                this.changeJudgmentValue(output.recordData.judgment_result, record)
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
  * 検査ごとの判定値が変更になった場合
  * @param {*} event
  * @param {*} record
  */
  changeJudgmentValue(value, record) {
    let copyData = [...this.state.changeData]
    let id = record?.id
    let resultValue
    let disabledChange = false

    if (value !== '') {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.judgment_value !== value) {
        // 検査結果値の設定
        element.judgment_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }


  /**
  * 前回の検査結果を反映する（全体）
  */
  setPreviousInspectDo = (selectedRecord) => {
    const pre1categoryjudge = selectedRecord.pre_1_category_judge

    if (pre1categoryjudge === '' || pre1categoryjudge === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果を反映します。よろしいですか？`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: this.getParamObject().Li_ReserveNum,
            PreviousReserveNum: this.state.values.pre1ReserveNum,
            SelectCategory: record?.SelectCategory,
            Li_PatternCode: record?.pattern_code,
            Li_CategoryCode: record?.category_code
          }

          OverallResultDisplayInputAction.setPreviousExamCategoryCodeDo(params)
            .then(async (res) => {
              await this.getCategoryJudgeList()
              await this.getInspectResultList()
            })
        }
      })
    }
  }



  /**
   * 前回の検査結果を反映する（検査単位）
  * @param {*} selectedRecord
  */
  setPreviousExamCodeDo = (selectedRecord) => {
    const pre1ReserveNum = this.state.values.pre1ReserveNum
    let paramObject = this.getParamObject()

    if (!this.state.disabledUpdateBtnv && pre1ReserveNum.exam_type === 'S') {
      this.saveResultValueList()
    }

    if (pre1ReserveNum === '' || pre1ReserveNum === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果 [${selectedRecord.inspect_name}] を反映します。よろしいですか？`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: paramObject.Li_ReserveNum,
            PreviousReserveNum: pre1ReserveNum,
            Li_ExamCode: record?.exam_code,
            Li_ExamType: record?.exam_type,
          }
          OverallResultDisplayInputAction.setPreviousExamCodeDo(params)
            .then((res) => {
              // 前回DOを反映
              let copyData = [...this.state.inspectResultList]
              // 前回値がなかった場合は空にする
              if (res.dt0420InsertList.length > 0) {
                for (let i = 0; i < copyData.length; i++) {
                  for (let k = 0; k < res.dt0420InsertList.length; k++) {
                    if (res.dt0420InsertList[k].exam_code === copyData[i]?.exam_code) {
                      this.changeResultValue(res.dt0420InsertList[k].result_value, copyData[i])
                      this.changeJudgmentValue(res.dt0420InsertList[k].judgment_value, copyData[i])
                    }
                  }
                }
              } else {
                this.changeResultValue('', record)
              }

              // 所見の場合、画面更新
              if (record?.exam_type === 'S') {
                this.getInspectResultList()
              }
            })
        }
      })
    }
  }


  requestParamScreenData(param = undefined) {
    const NonInput = this.state.values.NonInput
    const Outliers = this.state.values.Outliers
    const HistoryAdded = this.state.values.HistoryAdded
    const isNonInput = (NonInput === 1) ? { NonInput } : { Outliers }
    const params = {
      ...this.getParamObject(),
      HistoryAdded
    }
    const request = param === undefined ? params : { ...params, ...isNonInput }
    return request
  }


  /**
   * 結果値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeResultValue(value, record) {
    record.inspectComments.map((element, index) => {
      let newElement = element.exam_comment_code.trim()
      if (value == newElement) {
        value = element.exam_comment_screen.trim()
      }
    })

    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (exam_type.slice(0, 1) === 'N' && value !== '') {
      // exam_typeが「N、N1、N2、N3」の場合
      resultValue = recalculate(exam_type, value)
    } else {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.result_value !== value) {
        // 検査結果値の設定
        element.result_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }


  /**
  * 自動判定
  */
  showWS0723001_AutoJudgeScreen = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0723001_AutoJudgeScreen
            Li_ReserveNum={this.state.values.Li_ReserveNum}
            Li_CourseLevel={this.state.values.Li_CourseLevel}
            Li_TrueOrFalse={1} //メモリーテーブルを使用しない為には「1」にする
            TotalJudge={this.state.values.OverallJudgeG}
            onFinishScreen={(output) => {
              if (output) {
                // 自動判定でデータが変更になった場合
                // データ再取得
                this.index()
                if (this.props.onUpdate) {
                  // 親画面の受診者一覧もデータを再取得させる
                  this.props.onUpdate()
                }
              }
              this.closeModal()
            }} />
        ),
      },
    })
  }


  /**
  * 共通でparamsに
  * @returns
  */
  getParamObject() {
    let result = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_CourseLevel: this.checkData(this.props.Li_CourseLevel, 'number'),
      Li_PatternCode: this.state.values.pattern_code,
      Li_MenuOption: this.checkData(this.props.Li_MenuOption, 'number'),
      Li_MenuAdminRights: this.checkData(this.props.Li_MenuAdminRights, 'number'),
      Li_MenuAuthority: this.checkData(this.props.Li_MenuAuthority, 'number'),
      Li_SubjectNotBeChangedMode: this.checkData(this.props.Li_SubjectNotBeChangedMode, 'number'),
    }
    return result
  }

  /**
 * データチェック
 * @param {*} value
 * @param {*} type
 * @returns
 */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  /**
   * 空チェック
   * @param {*} val
   * @returns
   */
  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
        closable: true
      }
    })
  }

  render() {
    return (
      <div className='overall-result-display-input-hybrid' >
        <Card
          // title='総合結果表示'
          id='OverallResultDisplayInputHybrid'
          style={{ position: 'relative', width: '100%', height: '100vh', paddingTop: 20 }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ marginTop: 5 }}>
                <div className='box_search'>
                  <div className='box_search_inner' style={{ minWidth: 180, maxWidth: 220 }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='personal_number_id'
                        label='個人番号'
                        {...labelSize}
                      >
                        <div>
                          {this.state.values.personal_number_id}
                        </div>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          size='small'
                          onClick={() => this.showWS2584019_PersonalInfoInquirySub()}
                          style={{ float: 'right', display: 'inline-block' }}
                          icon={<MoreOutlined />}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='kanji_name'
                      label='氏名'
                      {...labelSize}
                    >
                      <div>
                        {this.state.values.kanji_name}
                      </div>
                    </Form.Item>

                  </div>
                  <div className='box_inner_horizontal' style={{ gap: 0 }}>
                    <div className='box_search_inner' style={{ minWidth: 220, maxWidth: 240 }}>
                      <Form.Item
                        name='address'
                        label='住所'
                        {...labelSize}
                      >
                        <div>{this.state.values.address}</div>
                      </Form.Item>

                      <Form.Item
                        name='birthday_on'
                        label='生年月日'
                        {...labelSize}
                      >
                        <div style={{ display: 'flex', gap: 10 }}>
                          <span>
                            {this.state.values.birthday_on}
                          </span>
                          {this.state.values.Age ?
                            <span>
                              {this.state.values.Age} 歳
                            </span>
                            :
                            null
                          }
                        </div>
                      </Form.Item>

                    </div>
                    <div style={{ marginTop: 'auto' }}>
                      <img
                        src={GetImage(this.state.values.genderImage)}
                        style={{
                          width: 40, height: 40,
                          display: (this.state.values.genderImage) ? '' : 'none'
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='box_search'>
                  <div className='box_inner_vertical'>
                    <div className='box_inner_horizontal' style={{ gap: 0 }}>
                      <div className='box_search_inner' style={{ minWidth: 180, maxWidth: 180 }}>
                        <Form.Item
                          name='DateChar'
                          label='受診日'
                          {...labelSize2}
                        >
                          <div>
                            {this.state.values.DateChar ? moment(this.state.values.DateChar).format('YYYY/MM/DD (ddd)') : ''}
                          </div>
                        </Form.Item>
                        <Form.Item
                          name='AcceptNum'
                          label='受付No'
                          {...labelSize2}
                        >
                          <div>
                            {this.state.values.AcceptNum}
                          </div>
                        </Form.Item>
                      </div>

                      <div className='box_search_inner' style={{ minWidth: 280, maxWidth: 330 }}>
                        <Form.Item
                          name='visit_course'
                          label='契約'
                          {...labelSize}
                        >
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <span>
                              {this.state.values.visit_course}
                            </span>
                            <span>
                              {this.state.values.contract_short_name}
                            </span>
                          </div>
                        </Form.Item>

                        <div className='box_inner_horizontal'>
                          <Form.Item
                            name='number'
                            label='受診回数'
                            {...labelSize}
                          >
                            <div >{(this.state.values?.number ? this.state.values?.number : '') + ' ' + '回'}</div>
                          </Form.Item>
                          <Button
                            type='primary'
                            size='small'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  centered: true,
                                  component: (
                                    <WS2537001_PersonalReserveProcess
                                      Li_Date={this.state.values.DateChar ? moment(this.state.values.DateChar).format('YYYY/MM/DD') : ''}
                                      Li_CourseLevel={0}
                                      Li_ReserveNum={this.state.ReserveNum}
                                      Li_PersonalNum={
                                        this.state.values.personal_number_id
                                      }
                                      Li_Getctxname={''}
                                      Li_ProcessDivision={''}
                                      Li_Option={''}
                                      Li_Child={true}
                                      onFinishScreen={(output) => {
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            <span className='btn_label'>
                              {'受診変更'}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Table
                  bordered
                  pagination={false}
                  dataSource={this.state.guideCommentInputList}
                  size='small'
                  scroll={{ x: 'max-content', y: 103 }}
                  rowKey={record => record.id}
                >
                  <Table.Column
                    title='指導事項内容'
                    dataIndex='overall_comment'
                    render={(value, record, index) => (
                      <div style={{ color: Color(record.changeColorNum)?.Foreground }}>
                        {value}
                      </div>
                    )}
                  />
                </Table>
              </div>

              <div className='box_inner_horizontal'>
                {/* カテゴリ別結果一覧 */}
                <div className='box_inner_vertical' style={{ width: '25%' }}>
                  <div className='box_inner_horizontal'>
                    <Descriptions
                      bordered
                      size='small'
                      title=''
                      style={{ width: 300 }}
                    >
                      <Descriptions.Item
                        label='総合判定'
                        labelStyle={{ width: 100 }}
                      >
                        <div
                          style={{
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            color: Color(this.state.values.OverallJudgeGColorNum)?.Foreground
                          }}
                        >
                          {this.state.values.OverallJudgeG}
                        </div>
                      </Descriptions.Item>
                    </Descriptions>

                    <Button
                      type='primary'
                      icon={<EditOutlined />}
                      onClick={() => this.showWS2637002_OverallResultDisplayChange()}
                      style={{ marginTop: 5 }}
                    >
                      変更
                    </Button>
                  </div>

                  {/* カテゴリ別結果一覧 */}
                  <Table
                    bordered
                    size='small'
                    id={'ResultDisplay'}
                    pagination={false}
                    dataSource={this.state.categoryJudgeList}
                    rowKey={record => record.id}
                    scroll={{ x: 'max-content', y: resizableTableScroll(60, 'ResultDisplay') }}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: async (event) => {
                          await this.setState({ selectedRecord: record })

                          if (this.state.disabledUpdateBtn) {
                            // 選択された検査別結果一覧を取得
                            await this.getInspectResultList()
                          } else {
                            await Modal.confirm({
                              title: '確認',
                              content: '保存処理がされていません。このままカテゴリを変更しますか？',
                              width: 520,
                              icon: <QuestionCircleTwoTone />,
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                // 選択された検査別結果一覧を取得
                                this.getInspectResultList()
                              }
                            })
                          }
                        }
                      }
                    }}
                  >
                    <Table.Column
                      title='カテゴリ名称'
                      dataIndex='category_name'
                      className='category-table'
                      render={(item, record, index) => (
                        <div className='box_inner_horizontal' style={{ justifyContent: 'space-between' }}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: (record?.changeColorNum === 82) ? 'bold' : ''
                          }}>
                            <Tooltip title={record.categoryCodeStr}>
                              <span>{record.category_name}</span>
                            </Tooltip>
                          </div>

                          <div style={{ display: 'flex', gap: 5 }}>
                            <div style={{ display: record.categoryNameChangeFlag ? '' : 'none' }}>
                              <Tooltip title='判定の内容を直接変更しています。'>*</Tooltip>
                            </div>

                            <div style={{ display: record.alreadyInput ? '' : 'none' }}>
                              *
                            </div>
                          </div>
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values.DateChar) ? '' : 'none' }}>
                          <div>{'今回'}</div>
                          <div>{moment(this.state.values.DateChar).format('YYYY/MM/DD')}</div>
                        </div>
                      }
                      dataIndex='category_judge'
                      className='this-time'
                      width={100}
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.categoryJudgeColorNum)?.Foreground,
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values?.pre1VisitDate) ? '' : 'none' }}>
                          <div>{'前回'}</div>
                          <div>{this.state.values?.pre1VisitDate}</div>
                        </div>
                      }
                      dataIndex='pre_1_category_judge'
                      width={100}
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.pre1CategoryJudgeColorNum)?.Foreground
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      title={
                        <div style={{ display: (this.state.values?.pre2VisitDate) ? '' : 'none' }}>
                          <div>{'前々回'}</div>
                          <div>{this.state.values?.pre2VisitDate}</div>
                        </div>
                      }
                      dataIndex='pre_2_category_judge'
                      width={100}
                      render={(item, record, index) => (
                        <div
                          style={{
                            textAlign: 'center',
                            color: Color(record.pre2CategoryJudgeColorNum)?.Foreground
                          }}>
                          {item}
                        </div>
                      )}
                    />

                    <Table.Column
                      width={45}
                      style={{ textAlign: 'center' }}
                      render={(text, record, index) => (
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='1'
                                disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                onClick={async () => {
                                  await this.setState({ selectedRecord: record })

                                  if (this.state.disabledUpdateBtn) {
                                    // 選択された検査別結果一覧を取得
                                    await this.getInspectResultList()
                                  } else {
                                    await Modal.confirm({
                                      title: '確認',
                                      content: '保存処理がされていません。このままカテゴリを変更しますか？',
                                      width: 520,
                                      icon: <QuestionCircleTwoTone />,
                                      okText: 'は　い',
                                      cancelText: 'いいえ',
                                      onOk: () => {
                                        // 選択された検査別結果一覧を取得
                                        this.getInspectResultList()
                                      }
                                    })
                                  }
                                }}
                              >
                                編集
                              </Menu.Item>

                              <Menu.Item
                                key='2'
                                disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                onClick={() => {
                                  this.setPreviousInspectDo(record)
                                }}
                              >
                                前回DO
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      )}>
                    </Table.Column>
                  </Table>
                </div>

                <div className='box_inner_vertical' style={{ width: '75%' }}>
                  <div className='box_container'>
                    <div className='box_inner_horizontal'>
                      <Descriptions
                        bordered
                        size='small'
                        title=''
                        style={{ width: '320px' }}
                      >
                        <Descriptions.Item
                          label='カテゴリ判定'
                          labelStyle={{ width: '130px' }}
                        >
                          <div
                            style={{
                              fontWeight: 'bold',
                              width: '100%',
                              textAlign: 'center',
                              color: Color(this.state.selectedRecord?.categoryJudgeColorNum)?.Foreground
                            }}
                          >
                            {this.state.selectedRecord?.category_judge}
                          </div>
                        </Descriptions.Item>
                      </Descriptions>

                      <Button
                        type='primary'
                        icon={<EditOutlined />}
                        style={{ marginTop: 5 }}
                        onClick={() => this.showWS0285001_JudgeQueryCategory()}
                      >
                        判定
                      </Button>
                    </div>

                    <Table
                      bordered
                      size='small'
                      dataSource={this.state.inspectResultList}
                      rowKey={record => record?.id}
                      pagination={false}
                      scroll={{ x: 'max-content', y: resizableTableScroll(60, 'DetailResultDisplay') }}
                    >
                      <Table.Column
                        dataIndex='inspect_name'
                        title='検査名称'
                        width={155}
                        render={(value, record, index) => (
                          <Tooltip title={record?.inspectNameTooltip}>
                            <div style={{ textAlign: 'left' }}>
                              <div style={{
                                marginLeft: 5,
                                fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : ''
                              }}>
                                {(record?.imageNum === '') ?
                                  null
                                  :
                                  <img src={GetImage(record?.imageNum)} alt='icon' />
                                }
                                {value}
                              </div>
                            </div>
                          </Tooltip>
                        )}
                      />

                      <Table.Column
                        dataIndex='normal_value'
                        title='参考値'
                        width={140}
                        render={(item, record, index) => (
                          <div style={{ textAlign: 'center' }}>
                            {record.normal_value}
                            {record.dataConfirmFlag === 0 ?
                              null
                              :
                              <span
                                style={{
                                  float: 'right',
                                  fontWeight: record?.resultValueFontWeight === 82 ? 'bold' : ''
                                }}>
                                *
                              </span>
                            }
                          </div>
                        )}
                      />

                      <Table.Column
                        dataIndex='judgment_value'
                        title='判'
                        width={50}
                        align='center'
                        className='this-time'
                        render={(value, record, index) => (
                          <Tooltip title={record.valueTooltip}>
                            {(record.exam_type === 'S') ?
                              // 所見 (モーダルを開いて値を変更)
                              <div
                                style={{
                                  textAlign: 'center',
                                  fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : '',
                                  color: Color(record.judgmentValueColorNum)?.Foreground,
                                  border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                                  whiteSpace: 'pre-wrap'
                                }}
                              >
                                {value}
                              </div>
                              :
                              <div
                                name={['formInspectResultList', index, 'judgment_value']}
                                onDoubleClick={() => {
                                  // 編集画面の表示処理
                                  this.showWS0285001_JudgeQueryInspect(record)
                                }}
                                style={{
                                  textAlign: 'center',
                                  fontWeight: (record?.resultValueFontWeight === 82 || (record.change_flg ?? 0)) ? 'bold' : '',//変更時は太字
                                  color: Color(record.judgmentValueColorNum)?.Foreground,
                                  border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                                  whiteSpace: 'pre-wrap',
                                  cursor: 'pointer'
                                }}
                              >
                                {value ? value : '　'}
                              </div>
                            }
                          </Tooltip>
                        )}
                      />

                      <Table.Column
                        className='this-time'
                        dataIndex='result_value'
                        title={
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.values?.headerTitle}
                          </div>
                        }
                        render={(value, record, index) => (
                          <Tooltip title={record.valueTooltip}>
                            {(record.exam_type === 'S' || ((record.exam_type.slice(0, 1) !== 'N') && this.props.protectFlag)) ?
                              // 所見 (モーダルを開いて値を変更)
                              // または、ロック時 かつ exam_typeが「N」以外
                              <div
                                style={{
                                  color: Color(record.resultValueColorNum)?.Foreground,
                                  whiteSpace: 'pre-wrap',
                                  padding: '5px 8px'
                                }}
                                onDoubleClick={() => {
                                  // 編集画面の表示処理
                                  this.showEditModal(record)
                                }}
                              >
                                {value}
                              </div>
                              :
                              (record.StsAutomaticCalculate || ((record.exam_type.slice(0, 1) === 'N') && this.props.protectFlag)) ?
                                // 自動計算あり（BMIや基準体重など）入力なし
                                // または、ロック時 かつ exam_typeが「N」
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div
                                    style={{
                                      width: '30px',
                                      cursor: 'pointer',
                                      color: Color(record.upAndDownColorNum)?.Foreground,
                                      textAlign: 'center'
                                    }}>
                                    {record.UpAndDown}
                                  </div>
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', width: '100%' }}>
                                    <div
                                      style={{
                                        color: Color(record.resultValueColorNum)?.Foreground,
                                        width: '6rem',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {value}
                                    </div>
                                  </div>
                                </div>
                                :
                                // 値の変更、入力あり
                                <div
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onDoubleClick={() => {
                                    // 編集画面の表示処理
                                    this.showEditModal(record)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: (record.UpAndDown !== '') ? '' : 'none',
                                      width: '30px',
                                      cursor: 'pointer',
                                      color: Color(record.upAndDownColorNum)?.Foreground,
                                      textAlign: 'center'
                                    }}>
                                    {record.UpAndDown}
                                  </div>
                                  <Form.Item
                                    name={['formInspectResultList', index, 'result_value']}
                                    style={{ marginBottom: '0' }}
                                  >
                                    <Input
                                      id={'spreadInput-2-' + record.id}
                                      type='tel'
                                      onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', 1, true, 0)}
                                      onPressEnter={(e) => this.changeResultValue(e.target.value, record)}
                                      onBlur={(e) => this.changeResultValue(e.target.value, record)}
                                      style={{
                                        textAlign: (record.exam_type.slice(0, 1) === 'N') ? 'right' : 'left',
                                        fontWeight: (record.change_flg ?? 0) ? 'bold' : 'normal', //変更時は太字
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                            }
                          </Tooltip>
                        )}
                      />

                      <Table.Column
                        dataIndex='pre_1_judgment_value'
                        title='判'
                        width={50}
                        align='center'
                        render={(value, record) => (
                          <div
                            style={{
                              textAlign: 'center',
                              color: Color(record.pre1JudgmentValueColorNum)?.Foreground
                            }}>
                            {value}
                          </div>
                        )}
                      />

                      <Table.Column
                        dataIndex='pre_1_result_value'
                        title={
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.values?.pre1HeaderTitle}
                          </div>
                        }
                        render={(value, record) => (
                          <div style={{ display: 'flex' }}>
                            {(record.exam_type.slice(0, 1) === 'N') ?
                              //exam_type:N or N1 以外は非表示
                              <div
                                style={{
                                  color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                                  width: '7rem',
                                  textAlign: 'right'
                                }}>
                                {value}
                              </div>
                              :
                              <div
                                style={{
                                  color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                                  whiteSpace: 'pre-wrap'
                                }}>
                                {value}
                              </div>
                            }
                          </div>
                        )}
                      />

                      <Table.Column
                        dataIndex='pre_2_judgment_value'
                        title='判'
                        width={50}
                        align='center'
                        render={(value, record) => (
                          <div
                            style={{
                              textAlign: 'center',
                              color: Color(record.pre2JudgmentValueColorNum)?.Foreground
                            }}>
                            {value}
                          </div>
                        )}
                      />

                      <Table.Column
                        dataIndex='pre_2_result_value'
                        title={
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.values?.pre2HeaderTitle}
                          </div>
                        }
                        render={(value, record) => (
                          <div style={{ display: 'flex' }}>
                            {(record.exam_type.slice(0, 1) === 'N') ?
                              //exam_type:N or N1 以外は非表示
                              <div
                                style={{
                                  color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                                  width: '7rem',
                                  textAlign: 'right'
                                }}>
                                {value}
                              </div>
                              :
                              <div
                                style={{
                                  color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                                  whiteSpace: 'pre-wrap'
                                }}>
                                {value}
                              </div>
                            }
                          </div>
                        )}
                      />

                      <Table.Column
                        width={40}
                        style={{ textAlign: 'center' }}
                        render={(value, record, index) => (
                          <Dropdown
                            trigger='click'
                            size='small'
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                  onClick={() => {
                                    // 編集画面の表示処理
                                    this.showEditModal(record)
                                  }}
                                >
                                  編集
                                </Menu.Item>

                                <Menu.Item
                                  key='2'
                                  disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                                  onClick={() => {
                                    this.setPreviousExamCodeDo(record)
                                  }}
                                >
                                  前回DO
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              size='small'
                              icon={<MoreOutlined />}
                            />
                          </Dropdown>
                        )}
                      />
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          {/* Footer */}
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                // 総合結果入力 モーダルを表示
                this.showWS2637002_OverallResultDisplayChange()
              }}
            >
              <span className='btn_label'>
                指導事項
              </span>
            </Button>

            <Button
              type='primary'
              disabled={this.state.protectFlag === 1}
              hidden={this.state.protectFlag}
              onClick={() => this.showWS0723001_AutoJudgeScreen()}
            >
              <span className='btn_label'>
                自動判定
              </span>
            </Button>

            <Button
              type='primary'
              onClick={() => {
                this.showWS0898001_IntroduceLetterExtractMaintain()
              }}
            >
              <span className='btn_label'>
                紹介状
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              disabled={this.state.disabledUpdateBtn}
              onClick={() =>
                // 変更した検査結果を反映
                this.saveResultValueList()
              }
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          centered={this.state.childModal.centered}
          closable={this.state.childModal.closable}
          onCancel={() => {
            if (this.state.MemoFunctionUpdateBtn) {
              // 画面を閉じる
              this.closeModal()
              // 最初の自動判定で×を押された場合
              if (this.state.autoJudgeScreenClose) {
                this.index()
              }
            } else {
              // 保存しますかを聞く
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    MemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => { console.log('閉じません'); }
              })
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637090_OverallResultDisplayInputHybrid)
