import React, { createRef } from 'react'
import PropTypes from 'prop-types'

import { Card, Form, Table, Button, Input, Select, message } from 'antd'
import { SearchOutlined, CopyOutlined } from '@ant-design/icons'
import CopyContractSelectAction from 'redux/basicInfo/ContractInfoMaintain/CopyContractSelect.action'
import WS0330001_ContractHistoricalQuerySub from './WS0330001_ContractHistoricalQuerySub'
import WS0316026_ConfirmScreen from './WS0316026_ConfirmScreen'
import WS0265001_BasicCourseInquiry from '../V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0329001_ContractInsurerInfoInquirySub from './WS0329001_ContractInsurerInfoInquirySub'
import WS0328001_ContractOfficeInfoInquirySub from './WS0328001_ContractOfficeInfoInquirySub'
import moment from 'moment-timezone'
import ModalDraggable from 'components/Commons/ModalDraggable'

const styleFormItem = {
  marginBottom: 0
}
const smGrid = {
  labelCol: { style: { width: 55 } },
}
class WS0316001_CopyContractSelect extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  formRef = createRef()

  constructor(props) {
    super(props)

    // document.title = '複写契約選択'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      contract_history: {},
      screenData: {},
      selectedRowKeys: [],
      selectedRows: [],

      dataSource: []
    }

    this.loadScreenData = this.loadScreenData.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.loadScreenData()
  }

  /**
   * 初期データ取得
   */
  loadScreenData() {
    CopyContractSelectAction.getScreenData()
      .then(res => {
        this.setState({
          screenData: res.data,
        })
        this.formRef.current.setFieldsValue({
          ContractTypeOriginal: this.props.Li_ContractType,
          ContractOrgCodeOriginal: this.props.Li_ContractOrgCode,
          ContractStartDateOriginal: this.props.Li_ContractStartDate,
          ContractStartDateOriginalChar: moment(this.props.Li_ContractStartDate).format('YYYY/MM/DD'),
        })
        this.ContractSearch()
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  /**
   * 検索
   */
  ContractSearch = () => {
    const values = this.formRef.current.getFieldsValue()

    CopyContractSelectAction.ContractSearch(values)
      .then(res => {
        this.formRef.current.setFieldsValue({ tableData: res.data.tableData })

        this.setState({ dataSource: res.data.tableData })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })

  }

  handleCopy = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: 700,
        visible: true,
        component: (
          <WS0316026_ConfirmScreen
            Li_ContractTypeF={this.formRef.current.getFieldValue('ContractTypeOriginal')}
            Li_ContractOrgCodeF={this.formRef.current.getFieldValue('ContractOrgCodeOriginal')}
            Li_ContractStartDateF={this.formRef.current.getFieldValue('ContractStartDateOriginal')}
            Li_ContractTypeT={this.props.Li_ContractType}
            Li_ContractOrgCodeT={this.props.Li_ContractOrgCode}
            Li_ContractStartDateT={this.formRef.current.getFieldValue('ContractStartDateOriginal')}
            selectedRows={this.state.selectedRows}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                }
              })
              this.props.onFinishScreen()
            }}
          />),
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      }
    })
  }

  render() {
    const { Option } = Select


    return (
      <div className='copy-contract-select'>
        <Card title='複写契約選択'>
          <Form ref={this.formRef}>
            <div className='box_inner_horizontal' style={{ marginBottom: '10px' }}>
              <div className='box_search'>
                <div className='box_search_inner_border'>
                  <Form.Item style={{ display: 'none' }} name='ContractStartDateOriginal'>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='契約'
                    name='ContractTypeOriginal'
                    style={{ width: 200, marginRight: 10 }}
                    {...smGrid}
                  >
                    <Select onChange={() => {
                      this.formRef.current.resetFields(['ContractOrgCodeOriginal'])
                      const ContractTypeOriginal = this.formRef.current.getFieldValue('ContractTypeOriginal')
                      if (ContractTypeOriginal === 0) {
                        this.formRef.current.setFieldsValue({
                          ContractOrgCodeOriginal: 0,
                        })
                      }
                      this.formRef.current.setFieldsValue({
                        ContractTypeOriginal: ContractTypeOriginal,
                      })
                      this.forceUpdate()
                    }}>
                      <Option value={0}>共　通</Option>
                      <Option value={1}>保険者</Option>
                      <Option value={2}>事業所</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label='コード'
                    name='ContractOrgCodeOriginal'
                    style={{ width: 150, marginRight: 10 }}
                    {...smGrid}
                  >
                    <Input.Search
                      className='input-search-size-single-byte-8'
                      disabled={this.formRef.current?.getFieldValue('ContractTypeOriginal') === 0}
                      onSearch={(value) => {
                        const ContractType = this.formRef.current.getFieldValue('ContractTypeOriginal')
                        let component = null
                        if (ContractType === 1) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              centered: true,
                              width: 1200,
                              component: (
                                <WS0246001_InsurerInfoSearchQuery
                                  Li_insurerSearch={value}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      ContractOrgCodeOriginal: output.Lo_InsurerCode,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                          // component = (<WS0329001_ContractInsurerInfoInquirySub
                          //   Li_Type={ContractType}
                          //   onFinishScreen={(output) => {
                          //     this.formRef.current.setFieldsValue({
                          //       ContractOrgCodeOriginal: output.Lo_InsurerCode,
                          //     })

                          //     this.setState({
                          //       childModal: {
                          //         ...this.state.childModal,
                          //         visible: false,
                          //       }
                          //     })
                          //   }}
                          // />)
                        } else if (ContractType === 2) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              centered: true,
                              width: 1200,
                              component: (
                                <WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={value}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      ContractOrgCodeOriginal: output.Lio_OfficeCode,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                          // component = (<WS0328001_ContractOfficeInfoInquirySub
                          //   Li_Type={ContractType}
                          //   onFinishScreen={(output) => {
                          //     this.formRef.current.setFieldsValue({
                          //       ContractOrgCodeOriginal: output.Lio_OfficeCode,
                          //     })

                          //     this.setState({
                          //       childModal: {
                          //         ...this.state.childModal,
                          //         visible: false,
                          //       }
                          //     })
                          //   }}
                          // />)
                        }

                        if (component) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              width: 950,
                              visible: true,
                              component: component,
                            }
                          })
                        }
                      }
                      } />
                  </Form.Item>

                  <div className='box_inner_horizontal'>
                    <Form.Item
                      label='年度'
                      name='ContractStartDateOriginalChar'
                      style={{ marginBottom: 0 }}
                      {...smGrid}
                    >
                      <Input.Search
                        className='input-search-size-8'
                        onSearch={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 500,
                              visible: true,
                              component: (<WS0330001_ContractHistoricalQuerySub
                                Li_ContractType={this.formRef.current.getFieldValue('ContractTypeOriginal')}
                                Li_ContractOrgCode={this.formRef.current.getFieldValue('ContractOrgCodeOriginal')}
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    ContractStartDateOriginalChar: moment(output.Lo_ContractStartDate).format('YYYY/MM/DD'),
                                    ContractStartDateOriginal: output.Lo_ContractStartDate,
                                  })

                                  this.setState({
                                    contract_history: output.recordData,
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />)
                            }
                          })
                        }} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <div>{this.state.contract_history.contract_name}</div>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_inner'>
                  <div className='box_inner_horizontal'>
                    <Form.Item label='コース' name='CourseF' style={{ width: 150 }}  {...smGrid}>
                      <Input.Search
                        className='input-search-size-single-byte-4'
                        onSearch={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 800,
                              visible: true,
                              component: (<WS0265001_BasicCourseInquiry
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    CourseF: output.Lo_CourseCode,
                                  })

                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />)
                            }
                          })
                        }} />
                    </Form.Item>
                    <Form.Item name='CourseT' style={{ width: 120 }} label={<label style={{ width: 20 }}>~</label>}>
                      <Input.Search
                        className='input-search-size-single-byte-4'
                        onSearch={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 800,
                              visible: true,
                              component: (<WS0265001_BasicCourseInquiry
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    CourseT: output.Lo_CourseCode,
                                  })

                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />)
                            }
                          })
                        }} />
                    </Form.Item>
                  </div>
                  <Form.Item label='性別' name='Gender' style={{ width: 200 }} {...smGrid}>
                    <Select>
                      <Option value={0}> </Option>
                      <Option value={1}>男</Option>
                      <Option value={2}>女</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label='続柄' name='Relationship' style={{ width: 200, marginBottom: 0 }} {...smGrid}>
                    <Select>
                      {this.state.screenData.Relationship?.map(value => (
                        <Select.Option key={`rls-${value.id}`} value={value.node_code_name}>{value.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className='box_search_btn'>
                <div className='box_inner_horizontal' style={styleFormItem}>
                  <Form.Item style={{ float: 'right', marginBottom: 0 }}>
                    <Button
                      onClick={() => this.ContractSearch()}
                      icon={<SearchOutlined />}>
                      検　索
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
            <Table
              size='small'
              bordered
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: 500 }}
              rowSelection={{
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record) => {
                  let arrTemp = [...this.state.selectedRowKeys]
                  let arrTempRecord = [...this.state.selectedRows]
                  let idx = arrTemp.indexOf(record.id)
                  if (idx === -1) {
                    arrTemp.push(record.id)
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord,
                    })
                  } else {
                    arrTemp.splice(idx, 1)
                    arrTempRecord.splice(idx, 1)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord,
                    })
                  }
                },
                onSelectAll: (selected) => {
                  if (selected) {
                    let arrTemp = this.formRef.current?.getFieldValue('tableData').map(item => item.id)
                    let arrTempRecord = this.formRef.current?.getFieldValue('tableData')
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord
                    })
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: [],
                      selectedRowOnly: []
                    })
                  }
                }
              }}
            >
              <Table.Column title='番号' dataIndex='contract_number' />
              <Table.Column title='コース' dataIndex={'medical_exam_course'} />
              <Table.Column title='略称' dataIndex={'contract_short_name'} />
              <Table.Column title='正式' dataIndex={'contract_name'} />
              <Table.Column title='性' dataIndex={'conditions_gender'} render={(value) => (value === 1 ? '男' : (value === 2 ? '女' : ''))} />
              <Table.Column title='続' dataIndex={'various_names'} />
              <Table.Column title='金額' key='Expression_8' render={(value, record) => (
                record.insurer_total_price
                + record.office_total_price
                + record.organization_total_price
                + record.personal_1_total_price
                + record.personal_2_total_price
                + record.personal_3_total_price
              )} />
            </Table>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<CopyOutlined />}
                disabled={this.state.selectedRowKeys.length === 0}
                onClick={() => this.handleCopy()}
              >
                <span className='btn_label'>
                  複写
                </span>
              </Button>
            </div>
          </Form>
        </Card>


        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

export default WS0316001_CopyContractSelect
