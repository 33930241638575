import axios from 'configs/axios'
const apiPaths = {
  index: '/api/passing-manage-progress/passing-manage-progress',
  getStatusList: '/api/passing-manage-progress/passing-manage-progress/status-list',
  receptionAfterCancel: '/api/passing-manage-progress/passing-manage-progress/reception-after-cancel'
}

const PassingManageProgressService = {
  async index(params) {
    return axios.post(apiPaths.index, params)
  },
  async getStatusList(params) {
    return axios.post(apiPaths.getStatusList, params)
  },
  async receptionAfterCancel(params) {
    return axios.post(apiPaths.receptionAfterCancel, params)
  },
}

export default PassingManageProgressService
