import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Table, message } from 'antd'
import PropTypes from 'prop-types'
import IntroduceLetterSubjectCmtInquiryAction from 'redux/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterSubjectCmtInquiry.action.js'

class WS0888026_IntroduceLetterSubjectCmtInquiry extends React.Component {
  static propTypes = {
    Lo_CmtCode: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '紹介状対象コメント照会'

    this.state = {
      loading: false,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      SearchKey: this.formRef.current.getFieldValue('SearchKey')
    }
    IntroduceLetterSubjectCmtInquiryAction.index(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
      .catch(error => {
        message.error(error)
      })
  }

  /**
   * データ選択
   */
  selectData = (record) => {
    if (this.props.onFinishScreen) {
      const output = {
        Lo_CommentCode: record.Lo_CommentCode,
        recordData: record
      }
      // 親画面に反映する
      this.props.onFinishScreen(output)
    }
  }

  render() {
    return (
      <div className='introduce-letter-subject-cmt-inquiry'>
        <Card title='紹介状対象コメント照会'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              label='検索キー'
              name='SearchKey' >
              <Input
                onPressEnter={() => this.index()}
                maxLength={8}
                className='input-size-10'
              />
            </Form.Item>
          </Form>

          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            rowKey={(record) => record.id}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            scroll={{ y: 500 }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  // データ選択
                  this.selectData(record)
                }
              }
            }}
          >
            <Table.Column
              width={100}
              title='コード'
              dataIndex='comment_code'
              render={(value, record, index) =>
                <div style={{ textAlign: 'right' }}>{value}</div>
              }
            />
            <Table.Column
              width={100}
              title='検索キー'
              dataIndex='search_key'
            />
            <Table.Column
              title='コメント内容'
              dataIndex=''
              render={(value, record, index) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{record.FL_comment_content}</div>
                  <div>{record.FP_comment_content}</div>
                </div>
              )}
            />

            <Table.Column
              title='備考'
              dataIndex='remarks'
            />
          </Table>

        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0888026_IntroduceLetterSubjectCmtInquiry)
