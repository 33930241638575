import { message } from "antd";
import XmlOutputConfirmService from "services/SpecificInsureGuide/SpecificCoerciveFingerXmlOutput/XmlOutputConfirmService";
const XmlOutputConfirmAction = {
  GetScreenData(data) {
    return XmlOutputConfirmService.GetScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  XmlCreate(data) {
    return XmlOutputConfirmService.XmlCreate(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  XmlCheck(data) {
    return XmlOutputConfirmService.XmlCheck(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default XmlOutputConfirmAction;