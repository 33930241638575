/* eslint-disable eqeqeq */
/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, Button, Form, Input, Select, Dropdown, Menu, } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import ParamPromptedQuerySubAction from "redux/ResultOutput/PrintParamMaintain/ParamPromptedQuerySub/ParamPromptedQuerySub.action";
import ParamPromptedQueryContentAction from "redux/AdvancePreparation/DocumentManageMaintain/ParamPromptedQueryContent.action";

class WS0397001_ParamPromptedQuerySub extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'ﾊﾟﾗﾒｰﾀ指示照会 SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoadingTable: true,
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: 0,
      parramScreeenData: {
        Li_Format: "",
        Lo_IndicationDivision: ""
      },
      parramListTable: {
        Li_Format: "",
        Li_Division: "",
        Li_DivisionList: "",
        Li_ClassifyNameList: "",
        Lo_IndicationDivision: "",
        SearchInstructionDivision: "",
        ButtonSelect: "",
      },
      SearchString: "",
      Identify: "",
      Identify_DB: []
    };
  }

  componentDidMount() {
    let form = {
      Li_Format: "",
      Lo_IndicationDivision: ""
    }
    form.Li_Format = this.props.Li_Format
    form.Lo_IndicationDivision = this.props.Lo_IndicationDivision ?? ""
    this.setState({
      parramScreeenData: form
    })
    this.getScreenData(form);
  }

  componentDidUpdate(PropPev) {
    if (this.props !== PropPev) {
      let form = {
        Li_Format: "",
        Lo_IndicationDivision: ""
      }
      form.Li_Format = this.props.Li_Format
      form.Lo_IndicationDivision = this.props.Lo_IndicationDivision
      this.setState({
        parramScreeenData: form
      })
      this.getScreenData(form);
    }
  }

  async getScreenData(parram) {
    this.setState({ isLoadingTable: true })
    const res = await ParamPromptedQuerySubAction.getScreenData(parram)
    let data = res ? res : [];
    this.setState({
      parramListTable: data,
      SearchString: data.SearchString,
      Identify: data.Identify,
      isLoadingTable: false,
      Identify_DB: data.Identify_DB,
    })
    this.formRef.current?.setFieldsValue({ Identify: data.Identify })
    this.getScreenDataTable(true)
  }

  async getScreenDataTable(reload) {
    const request = await ParamPromptedQuerySubAction.InstructDivisionQuerySub(this.state.parramListTable)
    let data_table = request.data ? request.data : [];
    let index = reload ? 0 : this.state.indexTable
    data_table.forEach((q) => {
      let temp = this.state.Identify_DB.find((i) => i.LinkedField == q._id_);
      q._id_ = temp.DisplayField;
    });
    this.setState({
      dataSource: data_table ? data_table : [],
      isLoadingTable: false,

      rowSelected: data_table.length > 0 ? [data_table[index]] : [],
      selectedRowKeys: data_table.length > 0 ? [data_table[index].id] : [],
      indexTable: index,
    })
  }

  updateIdentify(value) {
    this.setState({
      Identify: value
    })
    this.state.parramListTable.Li_Division = value
    this.getScreenDataTable(true)
  }

  updateSearchString(value) {
    this.state.parramListTable.Lo_IndicationDivision = value
  }

  changeRow(index) {
    this.setState({
      indexTable: index
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  render() {
    return (
      <div className="param-prompted-query-sub">
        <Card title="ﾊﾟﾗﾒｰﾀ指示照会">
          <Form ref={this.formRef}
            onFinish={this.getScreenData}>

            <Form.Item
              name="Identify"
              label="識別"
            >
              <Select
                style={{ width: 160 }}
                onChange={(e) => this.updateIdentify(e)}>
                {this.state.Identify_DB?.map(value => (
                  <Select.Option
                    key={value.LinkedField}
                    value={value.LinkedField}>{value.DisplayField}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Table
              size="small"
              bordered={true}
              dataSource={this.state.dataSource}
              loading={this.state.isLoadingTable}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ x: 600, y: 400 }}
              rowClassName={(record, index) => record.id === this.state.rowSelected[0]?.id ? 'table-row-light' : ''}
              onRow={(record, index) => {
                return {
                  onClick: () => {
                    this.setState({
                      rowSelected: [record],
                    });
                    this.changeRow(index)
                  },

                  onDoubleClick: () => {
                    //選択できる「内容」があるかどうかの確認
                    let params = {
                      Format: 'Y',
                      W1_item: record.instruction_division,
                    }
                    ParamPromptedQueryContentAction.getDataScreen(params)
                      .then((res) => {
                        this.props.onFinishScreen(record, res.length)
                      })
                  }
                }
              }}
            >
              <Table.Column
                title="指示"
                dataIndex="instruction_division"
                width={240}
              />

              <Table.Column
                title="識別"
                dataIndex="_id_"
                key=""
                width={120}
              />

              <Table.Column
                title="名　称"
                dataIndex="name"
              />

              <Table.Column
                align='center'
                width={40}
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                  />
                }

                render={(value, record, index) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="登録"
                          >登録
                          </Menu.Item>

                          <Menu.Item
                            key="明細"
                          >明細
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>

            <Input.TextArea value={this.state.rowSelected[0]?.remarks} type="text" rows={5} />

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0397001_ParamPromptedQuerySub);
