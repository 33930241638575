import MiraisSingleTransmissionService from 'services/CooperationRelated/MiraisSingleTransmission/MiraisSingleTransmissionService'
import { message } from 'antd'

const MiraisSingleTransmissionAction = {
  index(params) {
    return MiraisSingleTransmissionService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  getListData(params) {
    return MiraisSingleTransmissionService.getListData(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  modifyDat(params) {
    return MiraisSingleTransmissionService.modifyDat(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  submitBtn(params) {
    return MiraisSingleTransmissionService.submitBtn(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  submitBtnBefore(params) {
    return MiraisSingleTransmissionService.submitBtnBefore(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  extractBtn(params) {
    return MiraisSingleTransmissionService.extractBtn(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  destroy(params) {
    return MiraisSingleTransmissionService.destroy(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  sendMirais(params) {
    return MiraisSingleTransmissionService.sendMirais(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  sendStatusModifyOrder(params) {
    return MiraisSingleTransmissionService.sendStatusModifyOrder(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  checkMiraisStartup() {
    return MiraisSingleTransmissionService.checkMiraisStartup()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  editConfirm(params) {
    return MiraisSingleTransmissionService.editConfirm(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  transmissionstatuschange(params) {
    return MiraisSingleTransmissionService.transmissionstatuschange(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
}
export default MiraisSingleTransmissionAction
