import React from "react";
import { connect } from "react-redux";

import { Button, Card, Input, message, Select, Space, Table, } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';
import WS1302001_AgencyInquirySub from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302001_AgencyInquirySub.jsx";
import WS2657078_VouchersInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2657078_VouchersInput.jsx";
import moment from "moment";

class WS1462006_ListProcess extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '一覧処理';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  getScreenData() {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API001462006002", {}).then(res => {
      this.setState({ dataSource: res, isLoading: false })
    });
  }

  ConfirmUpdateBtn(index, record) {
    VenusApiRoutesV2.callApi("API001462006004", record).then(res => {
      this.updateRecordInDataSource(index, {...record, W2_data_confirm_flg: "Y"});
    });
  }

  updateRecordInDataSource(index, record) {
    const copySource = [...this.state.dataSource];
    copySource.splice(index, 1, record);
    this.setState({ dataSource: copySource });
    this.saveRecord(record);
  }

  saveRecord(record) {
    VenusApiRoutesV2.callApi("API001462006016", {mainSource: [record]}).then(res => {
      if(res.message) {
        message.success(res.message);
      }
    });
  }

  f12Button(record) {

  }

  render() {
    const { DateFScreen, DateTScreen } = this.props;
    const { dataSource, isLoading, pagination } = this.state;
    return (
      <Card className="mb-2" title={`利用券入力[${DateFScreen}〜${DateTScreen}]`}>
        <Table bordered
          dataSource={dataSource}
          loading={isLoading}
          pagination={pagination}
          rowKey={(record) => record.id}
        >
          <Table.Column title="" width={80}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <span>{record.Expression_15}</span>
                <Button type="primary" onClick={() => this.ConfirmUpdateBtn(index, record)}>{record.W2_data_confirm_flg == "" ? "更新" : "確定"}</Button>
              </Space>
            )}
          />
          <Table.Column title="受診日/ＩＤ" dataIndex="W2_consult_date"
            showSorterTooltip={false}
            sorter={(a, b) => new Date(a.W2_consult_date).getTime() - new Date(b.W2_consult_date).getTime()}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <div style={{ textAlign: "right" }}>{value}</div>
                <div style={{ textAlign: "right" }}>{record.W2_id_cd}</div>
              </Space>
            )}
          />
          <Table.Column title="氏名" width={200} dataIndex="kana_name"
            showSorterTooltip={false}
            sorter={(a, b) => a.kana_name.localeCompare(b.kana_name, "ja")}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <div>{value}</div>
                <div>{record.kanji_name}</div>
              </Space>
            )}
          />
          <Table.Column title="性/生年" dataIndex="Expression_3"
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <div>{value}</div>
                <div>{record.birthday_on}</div>
              </Space>
            )}
          />
          <Table.Column title="保険者番号" dataIndex="insurer_num"
            showSorterTooltip={false}
            sorter={(a, b) => a.insurer_num - b.insurer_num}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Input readOnly value={value == 0 ? "" : value} onDoubleClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '60%',
                      component: (
                        <WS1290001_InsurerNumberInquiry
                          onFinishScreen={(output) => {
                            this.updateRecordInDataSource(index, { ...record, insurer_num: output.Lo_InsurerNum });
                            this.closeModal()
                          }}
                        />),
                    },
                  })
                }} />
              </Space>
            )}
          />
          <Table.Column title="利用/受診券番号" dataIndex="vouchers_docket_num"
            showSorterTooltip={false}
            sorter={(a, b) => a.vouchers_docket_num - b.vouchers_docket_num}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Input type="number" value={value == 0 ? "" : value} onChange={e => this.updateRecordInDataSource(index, { ...record, vouchers_docket_num: e.target.value })} />
                <Input type="number"
                  value={record.consult_ticket_docket_num == 0 ? "" : record.consult_ticket_docket_num}
                  onChange={e => this.updateRecordInDataSource(index, { ...record, consult_ticket_docket_num: e.target.value })}
                />
              </Space>
            )}
          />
          <Table.Column title="有効期限" dataIndex="ExpirationDate"
            showSorterTooltip={false}
            sorter={(a, b) => new Date(a.ExpirationDate).getTime() - new Date(b.ExpirationDate).getTime()}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <VenusDatePickerCustom value={value == "0000/00/00" ? null : value} format="YYYY/MM/DD"
                  onChange={(val, dateStr) => this.updateRecordInDataSource(index, { ...record, ExpirationDate: dateStr })}
                />
              </Space>
            )}
          />
          <Table.Column title="指導区分" dataIndex="health_guide_sect"
            showSorterTooltip={false}
            sorter={(a, b) => a.health_guide_sect - b.health_guide_sect}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Select value={value} onChange={(val) => this.updateRecordInDataSource(index, { ...record, health_guide_sect: val })}>
                  <Select.Option value={1}>動機付支援</Select.Option>
                  <Select.Option value={2}>積極的支援</Select.Option>
                  <Select.Option value={3}>動機付相当</Select.Option>
                  <Select.Option value={4}>モデル実施</Select.Option>
                </Select>
              </Space>
            )}
          />
          <Table.Column title="保険負担/率額・上限" dataIndex="specify_health_burden_sect"
            showSorterTooltip={false}
            sorter={(a, b) => a.specify_health_burden_sect - b.specify_health_burden_sect}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Select value={value} onChange={(val) => this.updateRecordInDataSource(index, { ...record, specify_health_burden_sect: val })}>
                  <Select.Option value={0}> </Select.Option>
                  <Select.Option value={1}>負担なし</Select.Option>
                  <Select.Option value={2}>定額負担</Select.Option>
                  <Select.Option value={3}>定率負担</Select.Option>
                  <Select.Option value={4}>保険者上限</Select.Option>
                  <Select.Option value={5}>定率(上限以下)</Select.Option>
                  <Select.Option value={6}>定額(上限以下)</Select.Option>
                </Select>
                <Space>
                  <Input className="mx-1" type="number"
                    value={record.SpecificInsuranceburdenContent == 0 ? "" : record.SpecificInsuranceburdenContent}
                    onChange={e => this.updateRecordInDataSource(index, { ...record, SpecificInsuranceburdenContent: e.target.value })}
                  />
                  <Input className="mx-1" type="number"
                    value={record.specify_insurer_pay_max == 0 ? "" : record.specify_insurer_pay_max}
                    onChange={e => this.updateRecordInDataSource(index, { ...record, specify_insurer_pay_max: e.target.value })}
                  />
                </Space>
              </Space>
            )}
          />
          <Table.Column title="代行機関" dataIndex="Agency"
            showSorterTooltip={false}
            sorter={(a, b) => a.Agency - b.Agency}
            render={(value, record, index) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Input readOnly type="number" value={value == 0 ? "" : value} 
                  onDoubleClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '60%',
                        component: (
                          <WS1302001_AgencyInquirySub
                            onFinishScreen={(output) => {
                              this.updateRecordInDataSource(index, { ...record, Agency: output.Gl0AgencyNum });
                              this.closeModal()
                            }}
                          />),
                      },
                    })
                  }}
                />
                <Select value={record.VisitsPropriety} onChange={(val) => this.updateRecordInDataSource(index, { ...record, VisitsPropriety: val })}>
                  <Select.Option value={0}>受診不可</Select.Option>
                  <Select.Option value={1}>受診可能</Select.Option>
                </Select>
              </Space>
            )}
          />
          <Table.Column title=""
            render={(value, record, index) => (
              <Button type="primary" onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: '60%',
                    component: (
                      <WS2657078_VouchersInput
                        Li_Date={moment(record.W2_consult_date).format("YYYY/MM/DD")}
                        Li_PersonalNum={record.insurer_num}
                        onFinishScreen={(output) => {
                          this.updateRecordInDataSource(index, { ...record, insurer_num: output.Lo_InsurerNum });
                          this.closeModal()
                        }}
                      />),
                  },
                })
              }}>明細</Button>
              )}
          />
        </Table>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1462006_ListProcess);
