/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Card, Form, Input, Button, Table, Row, Col, Tabs, Checkbox, Tooltip, Dropdown, Menu, } from 'antd'
import { MoreOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MenuOutlined } from '@ant-design/icons'
import OfficeInfoInquirySubAction from 'redux/ReservationBusiness/GroupBookings/OfficeInfoInquirySub.action'
import WS0341001_OfficeInfoMaintain from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341001_OfficeInfoMaintain.jsx'
import WS0339001_InsurerInfoMaintain from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import CorporateHearingSheet from 'pages/ZZ_Others/SANAI_CorporateHearingSheet/CorporateHearingSheet'
const { TabPane } = Tabs

const labelStyle = {
  labelCol: { style: { width: 70 } }
}

class WS2585001_OfficeInfoInquirySub extends React.Component {
  static propTypes = {
    Li_OfficeCode: PropTypes.any,
    Li_BranchCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '事業所情報照会SUB'

    this.state = {
      OfficeInfoInquirySub: {},
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      Notices: [],
      DestinationInfo: [],
      ResultsTable: [],
      PersonalInfo: [],
      SupplementaryInfo: [],
      ContractInfo: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // データ取得
    this.index()
  }

  /**
   * 事業所データ取得
   */
  index = () => {
    const params = {
      OfficeCode: this.props.Li_OfficeCode,
      BranchCode: this.props.Li_BranchCode,
    }
    OfficeInfoInquirySubAction.index(params)
      .then((res) => {
        if (res) {
          this.formRef?.current?.setFieldsValue(res)
          this.setState({
            OfficeInfoInquirySub: res?.OfficeInfoInquirySub,

            Notices: res?.Notices,
            DestinationInfo: res?.DestinationInfo,
            ResultsTable: res?.ResultsTable,
            PersonalInfo: res?.PersonalInfo,
            SupplementaryInfo: res?.SupplementaryInfo,
            ContractInfo: res?.ContractInfo,
          })
        }
      })
  }

  showWS0341001_OfficeInfoMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0341001_OfficeInfoMaintain
            Lio_OfficeCode={this.props.Li_OfficeCode}
            Lio_BranchStoreCode={this.props.Li_BranchCode}
            onFinishScreen={(output) => {
              if (output) {
                // データ再取得
                this.index()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showWS0339001_InsurerInfoMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0339001_InsurerInfoMaintain
            insurer_code={this.state.OfficeInfoInquirySub.insurer_code}
            onFinishScreen={(output) => {
              if (output) {
                // データ再取得
                this.index()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false
  }

  hearing() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <CorporateHearingSheet
            Lio_OfficeCode={this.state.OfficeInfoInquirySub.office_code}
            Lio_branch_store_code={this.state.OfficeInfoInquirySub.branch_store_code}
            Lio_insurer_code={this.state.OfficeInfoInquirySub.insurer_code}
            Lio_office_kanji_name={this.state.OfficeInfoInquirySub.office_kanji_name}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 個人情報照会 モーダル
   * @param {*} record
   */
  showWS2584019_PersonalInfoInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={record.personal_number_id}
            onClick={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    let br = this.isEmpty(this.props.Li_BranchCode)
      ? ''
      : this.props.Li_BranchCode
    let title =
      '事業所情報照会 ' + '[' + this.props.Li_OfficeCode + '-' + br + ']'
    return (
      <div className='office-info-inquiry-sub'>
        <Card title={title}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.Li_OfficeCode) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.hearing()}>
                      ヒアリングシート
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Row>
            <Col span={10}>
              <Form ref={this.formRef}>
                <div style={{ display: 'none' }}>
                  <Form.Item name='MedicalExamLocation'>
                    <Input />
                  </Form.Item>
                  <Form.Item name='LabelSelect'>
                    <Input />
                  </Form.Item>
                  <Form.Item name='StsOtherwise'>
                    <Input />
                  </Form.Item>
                </div>
                <div
                  style={{
                    border: '1px solid #E3E4E1',
                    padding: '0.5em',
                    width: '98%',
                  }}
                >
                  <Row>
                    <Col>
                      <Form.Item
                        label='名称'
                        {...labelStyle}
                      >
                        <span>
                          {' '}
                          {this.state.OfficeInfoInquirySub?.office_kana_name}
                        </span>
                      </Form.Item>
                      <Form.Item
                        label='　'
                        {...labelStyle}
                      >
                        <span>
                          {' '}
                          {this.state.OfficeInfoInquirySub?.office_kanji_name}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label='保険者'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.insurer_code}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item>
                        <Button size='small' onClick={() => this.showWS0339001_InsurerInfoMaintain()} icon={<MoreOutlined />} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item
                      label='　'
                      {...labelStyle}
                    >
                      <span>
                        {' '}
                        {this.state.OfficeInfoInquirySub?.insurer_kanji_name}
                      </span>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='保険証'
                      {...labelStyle}
                    >
                      <span>
                        {this.state.OfficeInfoInquirySub?.insurer_card_symbol}
                      </span>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label='代表者'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.representative}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='先方担当'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.office_personnel}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label='状況'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.Expression_9}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='担当営業'
                        {...labelStyle}
                      >
                        <span>
                          {
                            this.state.OfficeInfoInquirySub
                              ?.sales_representative
                          }
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    border: '1px solid #E3E4E1',
                    padding: '0.5em',
                    marginTop: '1em',
                    width: '98%',
                  }}
                >
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label='〒'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.postal_code}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='地区'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.district_name}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        label='住所'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.address_1}
                        </span>
                      </Form.Item>
                      <Form.Item
                        label='　'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.address_2}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label='電話'
                        {...labelStyle}
                      >
                        <span>
                          {this.state.OfficeInfoInquirySub?.phone_number}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='FAX'
                        {...labelStyle}
                      >
                        <span>{this.state.OfficeInfoInquirySub?.fax}</span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item
                      label='産業分類'
                      {...labelStyle}
                    >
                      <span>
                        {this.state.OfficeInfoInquirySub?.Expression_10}
                      </span>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='産業医'
                      {...labelStyle}
                    >
                      <span>
                        {
                          this.state.OfficeInfoInquirySub
                            ?.industrial_doctor_name
                        }
                      </span>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='場所'
                      {...labelStyle}
                    >
                      <span>
                        {this.state.OfficeInfoInquirySub?.short_name}
                      </span>
                    </Form.Item>
                  </Row>
                </div>
              </Form>
            </Col>
            <Col span={14}>
              <Tabs type='card'>
                <TabPane
                  tab='特記'
                  key='1'
                  style={{ overflow: 'auto', height: '60vh' }}
                >
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.Notices}
                    pagination={false}
                    rowKey={(record) => record.id}
                  >
                    <Table.Column
                      title='重要度'
                      dataIndex='importance'
                      width={90}
                      render={(value, record, index) => {
                        switch (value) {
                          case 1:
                            return '情報'
                          case 3:
                            return '警告'
                          case 5:
                            return '重要'
                          default:
                            return ''
                        }
                      }}
                    />
                    <Table.Column
                      dataIndex='importance'
                      width={60}
                      align='center'
                      render={(value, record, index) => {
                        let icon = ''
                        switch (value) {
                          case 1:
                            icon = (
                              <InfoCircleOutlined
                                style={{ color: '#1890ff', fontSize: 20 }}
                              />
                            )
                            break
                          case 3:
                            icon = (
                              <WarningOutlined
                                style={{ color: '#faad14', fontSize: 20 }}
                              />
                            )
                            break
                          case 5:
                            icon = (
                              <CloseCircleOutlined
                                style={{ color: '#ff4d4f', fontSize: 20 }}
                              />
                            )
                            break
                          default:
                            icon = (
                              <MoreOutlined />
                            )
                            break
                        }
                        return icon
                      }}
                    />
                    <Table.Column title='内容' dataIndex='content' />
                    <Table.Column title='ユーザー' dataIndex='recorder' />
                  </Table>
                </TabPane>
                <TabPane
                  tab='送付先'
                  key='2'
                  style={{ overflow: 'auto', height: '60vh' }}
                >
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.DestinationInfo}
                    pagination={false}
                    rowKey={(record) => record.id}
                  >
                    <Table.Column title='書　類' dataIndex='document_title' />
                    <Table.Column
                      title='事業所'
                      dataIndex='destination_office_1'
                      width={70}
                      align='center'
                      render={(value, record, index) => {
                        return <Checkbox checked={value === 1} />
                      }}
                    />
                    <Table.Column
                      title='本店'
                      dataIndex='destination_office_2'
                      width={70}
                      align='center'
                      render={(value, record, index) => {
                        return <Checkbox checked={value === 1} />
                      }}
                    />
                    <Table.Column
                      title='個人１'
                      dataIndex='destination_personal_1'
                      width={70}
                      align='center'
                      render={(value, record, index) => {
                        return <Checkbox checked={value === 1} />
                      }}
                    />
                    <Table.Column
                      title='個人２'
                      dataIndex='destination_personal_2'
                      width={70}
                      align='center'
                      render={(value, record, index) => {
                        return <Checkbox checked={value === 1} />
                      }}
                    />
                    <Table.Column
                      title='個人３'
                      dataIndex='destination_personal_3'
                      width={70}
                      align='center'
                      render={(value, record, index) => {
                        return <Checkbox checked={value === 1} />
                      }}
                    />
                  </Table>
                </TabPane>
                <TabPane
                  tab='結果表'
                  key='3'
                  style={{ overflow: 'auto', height: '60vh' }}
                >
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.ResultsTable}
                    pagination={false}
                    rowKey={(record) => record.id}
                  >
                    <Table.Column
                      title='コース'
                      dataIndex='medical_exam_course'
                    />
                    <Table.Column
                      title='独自様式'
                      dataIndex='standard_printing_style'
                      render={(value, record, index) => {
                        return (
                          <div>
                            <span>{value}</span>
                            <span>{record?.format_name}</span>
                          </div>
                        )
                      }}
                    />
                    <Table.Column
                      title='標準様式'
                      dataIndex='standard_printing_style'
                      render={(value, record, index) => {
                        return (
                          <div>
                            <span>{value}</span>
                            <span>{record?.format_name}</span>
                          </div>
                        )
                      }}
                    />
                  </Table>
                </TabPane>
                <TabPane
                  tab='個人'
                  key='4'
                  style={{ overflow: 'auto', height: '60vh' }}
                >
                  <Table
                    bordered
                    dataSource={this.state.PersonalInfo}
                    pagination={this.state.PersonalInfo.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    rowKey={(record) => record.personal_number_id}
                    size='small'
                  >
                    <Table.Column
                      title='個人番号'
                      dataIndex='personal_number_id'
                      render={(value) =>
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      }
                    />
                    <Table.Column
                      title=''
                      dataIndex='importance'
                      width={40}
                      align='center'
                      render={(text, record) => {
                        const iconSize = 20
                        switch (record.importance) {
                          case 1:
                            return (
                              <InfoCircleOutlined
                                style={{ fontSize: iconSize, color: '#1890ff' }}
                                onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                              />)
                          case 3:
                            return (<WarningOutlined
                              style={{ fontSize: iconSize, color: '#faad14' }}
                              onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                            />)
                          case 5:
                            return (<CloseCircleOutlined
                              style={{ fontSize: iconSize, color: '#ff4d4f' }}
                              onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                            />)
                          default:
                            return (<Button
                              size='small'
                              icon={<MoreOutlined />}
                              onClick={() => this.showWS2584019_PersonalInfoInquirySub(record)}
                            />)
                        }
                      }}
                    />
                    <Table.Column title='カナ氏名' dataIndex='kana_name' />
                    <Table.Column title='漢字氏名' dataIndex='kanji_name' />
                    <Table.Column
                      title='性別'
                      dataIndex='sex'
                      width={50}
                      align='center'
                      render={(value, record, index) => {
                        switch (value) {
                          case 1:
                            return '男性'
                          case 2:
                            return '女性'
                          default:
                            return ''
                        }
                      }}
                    />
                    <Table.Column
                      title='生年月日'
                      dataIndex='birthday_on'
                      render={(value, record, index) => {
                        return (
                          <div>
                            {moment(record?.birthday_on).format('YYYY/MM/DD')}
                          </div>
                        )
                      }}
                    />
                  </Table>
                </TabPane>
                <TabPane
                  tab='補足'
                  key='5'
                  style={{ overflow: 'auto', height: '60vh' }}
                >
                  <Table
                    bordered
                    rowKey={record => record.id}
                    dataSource={this.state.SupplementaryInfo}
                    size='small'
                    pagination={false}
                  >
                    <Table.Column
                      title='項目'
                      dataIndex='item'
                    />
                    <Table.Column
                      title='内容'
                      dataIndex='content'
                    />
                  </Table>
                </TabPane>
                <TabPane
                  tab='契約'
                  key='6'
                  style={{ overflow: 'auto', height: '60vh' }}
                >
                  <Table
                    size='small'
                    dataSource={this.state.ContractInfo}
                    pagination={this.state.ContractInfo.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                    bordered={true}
                    rowKey={(record) => record.id}
                  >
                    <Table.Column
                      title='開始日'
                      dataIndex='contract_start_date_on'
                      width={110}
                      render={(value, record, index) => {
                        return (
                          <div>
                            {moment(record?.contract_start_date_on).format(
                              'YYYY/MM/DD'
                            )}
                          </div>
                        )
                      }}
                    />
                    <Table.Column title='名称' dataIndex='contract_name' />
                  </Table>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => this.showWS0341001_OfficeInfoMaintain()}
            >
              <span className='btn_label'>
                変更
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2585001_OfficeInfoInquirySub)
