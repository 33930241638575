import React from 'react'
import { connect } from 'react-redux'

import { Card, Col, Form, Input, Row, Table } from 'antd'

class WS0350001_SiteFindingsMaintainSub extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '部位所見保守SUB'

    this.state = {}
  }

  render() {
    return (
      <div className='site-findings-maintain-sub'>
        <Card title='部位所見保守SUB'>
          <Form
            ref={this.formRef}
          >
            <Row>
              <Form.Item name='Lio_SiteClassify' label='所見分類'>
                <Input type='text' style={{ width: '100px' }} />
              </Form.Item>
              <span
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '300px',
                }}
              >
                Test data
              </span>
              <Form.Item name='Lio_CommonCategory' label='地域老人保健分類'>
                <Input type='text' style={{ width: '100px' }} />
              </Form.Item>
            </Row>
          </Form>
          <Row gutter={10}>
            <Col span={10}>
              <Table
                bordered
                size='small'
                pagination={false}
              >
                <Table.Column title='部位コード' dataIndex='site_code' />
                <Table.Column title='検索略名' dataIndex='search_short_name' />
                <Table.Column title='部位名称' dataIndex='site_name' />
              </Table>
            </Col>
            <Col span={14}>
              <Table
                bordered
                size='small'
                pagination={false}
              >
                <Table.Column title='所見コード' dataIndex='findings_code' />
                <Table.Column title='検索略名' dataIndex='search_short_name' />
                <Table.Column title='所見名称' dataIndex='findings_name' />
                <Table.Column title='判定' dataIndex='judgment_value' />
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0350001_SiteFindingsMaintainSub)
