import React from 'react'
import { connect } from 'react-redux'
import { Card, Table } from 'antd'

class WS2637046_TypeSelect extends React.Component {
  // formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '種別選択'

    this.state = {
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 種別一覧をテーブルに設定
    this.setTypeList()
  }

  /**
   * 種別一覧をテーブルに設定
   */
  setTypeList = () => {
    const { Li_TypeSelectList } = this.props
    const data = Li_TypeSelectList.split(',')
    let arrayTemp = [], obj
    // テーブル用に配列に加工する
    for (let i = 0; i < data.length; i++) {
      obj = {
        id: i,
        type: data[i]
      }
      arrayTemp.push(obj)
    }
    this.setState({
      dataSource: arrayTemp
    })
  }

  render() {
    return (
      <div className='type-select'>
        <Card title='種別選択'>
          <Table
            size='small'
            bordered
            pagination={false}
            dataSource={this.state.dataSource}
            rowKey={record => record.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  if (this.props.onScreenFinish) {
                    // 種別を選択して、親画面に返却
                    this.props.onScreenFinish({ stsSelect: true, Lio_Type: record.type })
                  }
                }
              }
            }}
          >
            <Table.Column
              title='種別'
              dataIndex={'type'}
              render={(value, record, index) => {
                return value
                // return record !== '' ? <span>{value}</span> : <div style={{ height: '15px' }}></div>
              }}>

            </Table.Column>
          </Table>

        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637046_TypeSelect)
