import axios from 'configs/axios'

const API_LIST = {
  index: '/api/introduce-letter-master-maintain/exam-department-master',
  save: '/api/introduce-letter-master-maintain/exam-department-master/save',
  delete: '/api/introduce-letter-master-maintain/exam-department-master/delete',
}

const ExamDepartmentMasterService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
}

export default ExamDepartmentMasterService
