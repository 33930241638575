import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Dropdown, Menu, Button } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import InputVoteResultInputAction from 'redux/InputBusiness/InputVoteResultInput/InputVoteResultInput.action.js'
import WS0738004_InputVoteResultInputItemSettingsEdit from 'pages/IN_InputBusiness/V4IN0201000_InputVoteResultInput/WS0738004_InputVoteResultInputItemSettingsEdit.jsx'
import WS0738005_InputVoteResultInputItemSettingsItemEdit from 'pages/IN_InputBusiness/V4IN0201000_InputVoteResultInput/WS0738005_InputVoteResultInputItemSettingsItemEdit.jsx'
import WS0738006_Copy from 'pages/IN_InputBusiness/V4IN0201000_InputVoteResultInput/WS0738006_Copy.jsx'


class WS0738003_InputVoteResultInputItemSettings extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      invoice: [],
      invoiceRecord: [],
      invoiceContents: [],
    }
  }


  componentDidMount() {
    this.index();
  }


  /**
   * 伝票情報の取得
   */
  index() {
    InputVoteResultInputAction.getInputVoteResultInputItem()
      .then((res) => {
        this.setState({
          invoice: res,
        })

        this.itemDetail(res[0])
      })
  }


  /**
   * 伝票単位の検査項目の取得
   * @param {*} record
   */
  itemDetail(record) {
    let params = {
      itemId: record.item_id ?? 0
    }

    InputVoteResultInputAction.getInputVoteResultInputItemDetail(params)
      .then((res) => {
        this.setState({
          invoiceContents: res,
        })
      })
  }


  /**
   * 伝票の編集
   */
  voucherEdit(record, flag) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0738004_InputVoteResultInputItemSettingsEdit
            newFlag={flag}
            record={record}
            onFinishScreen={(output) => {
              this.index()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * 伝票単位の検査編集
   */
  voucherItemEdit(record, flag) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0738005_InputVoteResultInputItemSettingsItemEdit
            newFlag={flag}
            record={record}
            invoiceRecord={this.state.invoiceRecord}
            onFinishScreen={(output) => {
              this.itemDetail(this.state.invoiceRecord);
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * 複写
   */
  copy(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS0738006_Copy
            record={record}
            onFinishScreen={(output) => {
              this.index()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }


  render() {
    return (
      <div className='target-user-screen'>
        <Card title='伝票項目設定' className="mb-3">
          <div className='box_inner_horizontal' >
            <div style={{ width: '30%' }}>
              <Table
                bordered
                size='small'
                dataSource={this.state.invoice}
                rowKey={record => record.id}
                pagination={this.state.invoice.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 600 }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.itemDetail(record)
                      this.setState({ invoiceRecord: record })
                    },
                  }
                }}
              >
                <Table.Column
                  title='伝票コード'
                  className='column-size-10'
                  dataIndex='seqno'
                  render={(value) => (
                    <div
                      style={{ textAlign: 'right' }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title='伝票名'
                  dataIndex='item'
                />

                <Table.Column
                  width={40}
                  align='center'
                  title={
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        size='small'
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => { this.voucherEdit(null, true) }}
                      >
                      </Button>
                    </div>
                  }

                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='編集'
                              onClick={() => {
                                this.voucherEdit(record, false)
                              }}
                            >
                              編集
                            </Menu.Item>
                            <Menu.Item
                              key='複写'
                              onClick={() => {
                                this.copy(record)
                              }}
                            >
                              複写
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

            <div style={{ width: '70%' }}>
              <Table
                bordered
                size='small'
                dataSource={this.state.invoiceContents}
                rowKey={record => record.id}
                pagination={this.state.invoiceContents.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 600 }}
              >
                <Table.Column
                  title='SEQ'
                  className='column-size-5'
                  dataIndex='no'
                  render={(value) => (
                    <div
                      style={{ textAlign: 'right' }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  title='コード'
                  className='column-size-10'
                  dataIndex='exam_item'
                />

                <Table.Column
                  title='略称'
                  dataIndex='exam_short_name'
                />

                <Table.Column
                  title='名称'
                  dataIndex='exam_name'
                />

                <Table.Column
                  title='タイプ'
                  className='column-size-5'
                  dataIndex='exam_type'
                />

                <Table.Column
                  title='使用'
                  width={45}
                  dataIndex='use'
                />

                <Table.Column
                  width={40}
                  align='center'
                  title={
                    <div style={{ textAlign: 'center' }}>
                      <Button size='small'
                        onClick={() => { this.voucherItemEdit(null, true) }}
                        type='primary'
                        icon={<PlusOutlined />}>
                      </Button>
                    </div>
                  }

                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='編集'
                              onClick={() => {
                                this.voucherItemEdit(record, false)
                              }}
                            >
                              編集
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0738003_InputVoteResultInputItemSettings)
