import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { PlusOutlined, DeleteOutlined, SaveOutlined, MenuOutlined, EditOutlined, MoreOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Button, Row, Col, Space, Modal, message, Spin, Table, Tooltip, Dropdown, Menu, Tabs, Checkbox, Typography, Radio, InputNumber } from "antd";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import WS1290001_InsurerNumberInquiry from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx";
import ContractMasterDetailsAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetails.actions'
import WS1391001_ImplementAgencyInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0001100_ImplementorMaster/WS1391001_ImplementAgencyInquiry";
// import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';

const columns = [
  {
    title: '保険者番号',
    dataIndex: 'insurer_number',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
    // childModal: {
    //   width: '60%',
    //   component: WS1290001_InsurerNumberInquiry
    // },
    // childModal: {
    //   width: '60%',
    //   component: WS1391001_ImplementAgencyInquiry
    // },
  },

  {
    title: '保険者名',
    dataIndex: 'insurer_kanji_name',
    classNumber: '30',
    // 保険者名は非表示にする
    type: 9,
  },
  {
    title: '健診',
    dataIndex: 'entrusted_range_specific_medical_exam',
    classNumber: '5',
    textAlign: 'center',
    selectList: [{
      value: 0,
      label: "　"
    },
    {
      value: 1,
      label: <CheckOutlined />
    }],
    radioList: [
      {
        value: 0,
        label: '無し'
      },
      {
        value: 1,
        label: '有り'
      },
    ],
    type: 3,
  },

  {
    title: '指導',
    dataIndex: 'assistance_with_consignment_range_motive',
    classNumber: '5',
    textAlign: 'center',
    selectList: [{
      value: 0,
      label: "　"
    },
    {
      value: 1,
      label: <CheckOutlined />
    }],
    radioList: [
      {
        value: 0,
        label: '無し'
      },
      {
        value: 1,
        label: '有り'
      },
    ],
    type: 3,
  },
]

class WS1316800_ContractMasterMaintainConsignor extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = 'V4-TMS01000:契約マスタ保守'; 
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      allData: [],
      year: []
    };
  }

  componentDidMount() {
    console.log(this.props.onRowData);
    console.log(columns);
    this.GetScreenDataCommissionedOriginalInput()
  }

  GetScreenDataCommissionedOriginalInput() {
    this.setState({ isLoadingFm: true })
    ContractMasterDetailsAction.GetScreenDataCommissionedOriginalInput({
      year: this.props.onRowData.year
      , serial_number: this.props.onRowData.serial_number
    }).then(res => {
      console.log(res);
      this.formRef.current?.setFieldsValue(res)
      this.setState({
        allData: res
      })
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    }).finally(() => this.setState({ isLoadingFm: false }))
  }

  updateConsignor(output, ID, Insurer_number) {
    console.log(output.itemChange.insurer_number);
    let insurer_number = Insurer_number
    let param = {
      id: ID ?? undefined,
      year: this.state.year,
      changeData: output.itemChange,
      serial_number: this.props.onRowData.serial_number,
      insurer_number: Insurer_number ?? output.itemChange.insurer_number
    }
    console.log(param);
    ContractMasterDetailsAction.updateConsignor(param)
      .then((res) => {
        if (res) {
          console.log('if');
          this.GetScreenDataCommissionedOriginalInput()
          this.closeModal();
          // return true;
        }
        this.setState({
          year: []
        })

      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })

    // return false;
  }

  deleteConsignor(ID) {
    let param = {
      id: ID
    }
    console.log(param);
    ContractMasterDetailsAction.deleteConsignor(param)
      .then((res) => {
        this.GetScreenDataCommissionedOriginalInput()
        this.closeModal();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        value: ''
      },
    });
  }

  render() {
    return (
      <Card className="mb-2" title="委託元">
        <Table
          bordered
          dataSource={this.state.allData ? this.state.allData : []}
          size='small'
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ y: resizableTableScroll(70), x: 'max-content' }}
        >
          {columns.map((item, index) => (
            item.type === 1 ?
              <Table.Column
                key={'input' + index}
                title={item.title}
                dataIndex={item.dataIndex}
                className={'column-size-' + item.classNumber}
                align={item.textAlign ?? ''}
              /> : item.type === 2 ?
                <Table.Column
                  key={'check' + index}
                  title={item.title}
                  dataIndex={item.dataIndex}
                  className={'column-size-' + item.classNumber}
                  align={item.textAlign}
                  render={(value, record) => {
                    // findを使ってvalueを元に検索する
                    let findItem = item.selectList.find(selected => selected.value === value);
                    // labelを指定してreturn
                    return findItem.label
                  }}
                /> : item.type === 3 ?
                  <Table.Column
                    key={'check' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign}
                    render={(value, record) => {
                      // findを使ってvalueを元に検索する
                      let findItem = item.radioList.find(radioLabel => radioLabel.value === value);
                      // labelを指定してreturn
                      return findItem.label
                    }}
                  /> :
                  item.type === 9 ?
                    <Table.Column
                      key={'input' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      className={'column-size-' + item.classNumber}
                      align={item.textAlign ?? ''}
                    /> : null
          ))}
          <Table.Column
            width={30}
            align='center'
            title={
              <Button
                size='small'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() =>
                  this.setState({
                    year: this.props.onRowData.year,
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 550,
                      component: (
                        <ModalAdd
                          columns={columns}
                          onFinishScreen={(itemChange) => {
                            this.updateConsignor(itemChange)
                          }}
                        />
                      )
                    }
                  })}
              />}
            render={(text, record, index) => {
              return <>
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    // 編集、削除
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '550px',
                        className: '',
                        component:
                          <ModalChange
                            columns={columns}
                            record={record}
                            onUpdate={(output) => {
                              console.log(output);
                              this.updateConsignor(output, record.id, record.insurer_number)
                            }}
                            onDelete={(output) => {
                              this.deleteConsignor(record.id)
                            }}
                          />
                      },
                    });
                  }}
                ></Button>
              </>
            }}
          />
        </Table>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </Card>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1316800_ContractMasterMaintainConsignor);