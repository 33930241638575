import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Select, Checkbox, Radio, Button, Table, Space, message, Modal } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import DocumentBatchCreateSubAction from 'redux/AdvancePreparation/DocumentBatchCreate/DocumentBatchCreateSub.actions'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

const labelStyle = {
  color: 'white'
}
class WS0650001_DocumentBatchCreateSub extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_OutputUnit: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '資料一括作成SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      OutputPattern: [],
      CheckSpecifiedIssue: false,
      PatientNumCode: null,
      DocumentPrintingTypeOp: '',
      SpecifiedIssueNarrowing: false,

      selectedForm: [],
      dataSource: [],
      w7PrintedMaterialNumList: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index() {
    let data = {
      Li_CourseLevel: this.isEmpty(this.props.Li_CourseLevel) ? '' : this.props.Li_CourseLevel,
      Li_ReserveNum: this.isEmpty(this.props.Li_ReserveNum) ? '' : this.props.Li_ReserveNum,
      Li_OutputUnit: this.isEmpty(this.props.Li_OutputUnit) ? '' : this.props.Li_OutputUnit,
      Li_OutputPattern: this.isEmpty(this.props.Li_OutputPattern) ? '' : this.props.Li_OutputPattern
    }
    DocumentBatchCreateSubAction.index(data)
      .then(async (res) => {
        this.formRef.current?.setFieldsValue(res?.dataView)
        await this.setState({
          OutputPattern: res?.OutputPattern,
          CheckSpecifiedIssue: res?.dataView?.SpecifiedIssue,
          PatientNumCode: res?.dataView?.PatientNumCode,
          DocumentPrintingTypeOp: res?.dataView?.DocumentPrintingTypeOp,
          SpecifiedIssueNarrowing: res?.dataView?.SpecifiedIssueNarrowing,
          w7PrintedMaterialNumList: res?.w7PrintedMaterialNumList,
        })
        await this.selectForm()
      })
  }

  /**
   * 「指定」チェックボックス変更処理
   * @param {*} check
   */
  changeSpecifiedIssue = async (check) => {
    // 指定のチェックボックスがfalseの場合、印刷フラグを全てtureにする
    let w7PrintedMaterialNums = []
    const list = [...this.state.w7PrintedMaterialNumList]
    list.forEach(item => {
      item = { ...item }
      item.W7_issue_flg_y_n = (!check) ? 'Y' : 'N'
      w7PrintedMaterialNums.push(item)
    })

    let tempArray = []
    const list2 = [...this.state.dataSource]

    list2.forEach(item2 => {
      item2 = { ...item2 }
      item2.Print = !check
      item2.W7_issue_flg_y_n = (!check) ? 'Y' : 'N'
      tempArray.push(item2)
    })

    // テーブルデータを反映
    this.formRef.current?.setFieldsValue({
      tableData: tempArray
    })
    await this.setState({
      dataSource: tempArray,
      w7PrintedMaterialNumList: w7PrintedMaterialNums,
      CheckSpecifiedIssue: check
    })

    if (check) {
      this.selectForm()
    } else {
      this.index()
    }
  }

  /**
   * 帳票のデータを取得する
   */
  selectForm = () => {
    const params = {
      OutputUnit: this.formRef.current?.getFieldValue('OutputUnit'), //個人単位/帳票単位
      SpecifiedIssue: this.formRef.current?.getFieldValue('SpecifiedIssue') ? 1 : 0, //指定
      ForcedOutput: this.formRef.current?.getFieldValue('ForcedOutput') ? 1 : 0, //強制
      w7PrintedMaterialNumList: this.state.w7PrintedMaterialNumList
    }
    DocumentBatchCreateSubAction.selectForm(params)
      .then(res => {
        if (res) {
          // テーブルデータを反映
          this.formRef.current?.setFieldsValue({
            tableData: res
          })
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 個人単位 / 帳票単位の変更
   */
  changeOutputUnit() {
    let data = {
      SpecifiedIssue: this.formRef.current?.getFieldValue('SpecifiedIssue') ? 1 : 0, //指定
      OutputPattern: this.formRef.current?.getFieldValue('OutputPattern'),
      OutputUnit: this.formRef.current?.getFieldValue('OutputUnit'), //個人単位/帳票単位
      DocumentPrintingTypeOp: this.state.DocumentPrintingTypeOp,
      SpecifiedIssueNarrowing: this.state.SpecifiedIssueNarrowing,
      w7PrintedMaterialNumList: this.state.w7PrintedMaterialNumList
    }
    DocumentBatchCreateSubAction.changeOutputUnit(data)
      .then(async (res) => {
        await this.setState({
          w7PrintedMaterialNumList: res?.w7PrintedMaterialNumList,
        })
        await this.selectForm()
      })
  }

  /**
   * パターン変更
   * @param {*} value
   */
  changeOutputPattern(value) {
    let data = {
      OutputPattern: value,
      SpecifiedIssue: this.formRef.current?.getFieldValue('SpecifiedIssue') ? 1 : 0, //指定
      OutputUnit: this.formRef.current?.getFieldValue('OutputUnit'), //個人単位/帳票単位
      DocumentPrintingTypeOp: this.state.DocumentPrintingTypeOp,
      SpecifiedIssueNarrowing: this.state.SpecifiedIssueNarrowing,
      // dataView: dataView
    }
    DocumentBatchCreateSubAction.changeOutputPattern(data)
      .then(async (res) => {
        await this.setState({
          w7PrintedMaterialNumList: res?.w7PrintedMaterialNumList,
        })
        await this.selectForm()
      })
  }

  /**
   * 印刷前確認
   */
  ShowWS0433001_PrinterConfirm() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={this.formRef.current?.getFieldValue('PreviewMode')}
            Li_PrinterNoSpecifiedValue={this.formRef.current?.getFieldValue('PrinterNum')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                PreviewMode: output?.Lo_Preview,
                PrinterNum: output?.Lo_PrinterNo,
                StsConfirm: output?.Lo_StsOutput
              })
              // 印刷・プレビュー
              this.PrintProcess()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 印刷・プレビュー
   */
  async PrintProcess() {

    let w7PrintedMaterialNums = []
    const list = [...this.state.w7PrintedMaterialNumList]

    // 指定の場合のみ
    if (this.state.CheckSpecifiedIssue) {
      const tableList = this.formRef.current?.getFieldValue('tableData') ?? []
      // 印刷のチェックボックスの設定を基にw7PrintedMaterialNumListを変更
      list.forEach(item => {
        item = { ...item }
        // デフォルト「N」
        item.W7_issue_flg_y_n = 'N'

        // 印刷のチェックがつけられているidをもとに「Y」に変更
        const check = tableList.find((item2) => (item2.Print && item2.id === item.id))

        if (check) {
          item.W7_issue_flg_y_n = 'Y'
        }
        w7PrintedMaterialNums.push(item)
      })
    } else {
      w7PrintedMaterialNums = list
    }

    let params = {
      selectedList: this.props.selectedList ?? [],
      Li_ReserveNum: this.props.Li_ReserveNum ?? 0,
      Li_CourseLevel: this.props.Li_CourseLevel,
      SpecifiedIssue: this.formRef.current?.getFieldValue('SpecifiedIssue') ? 1 : 0,
      ForcedOutput: this.formRef.current.getFieldValue('ForcedOutput') ? 1 : 0,
      OutputUnit: this.formRef.current.getFieldValue('OutputUnit'), //個人単位/帳票単位
      StsAccommodationUnitsIssued: this.formRef.current.getFieldValue('StsAccommodationUnitsIssued') ? 1 : 0,
      PreviewMode: this.formRef.current.getFieldValue('PreviewMode') ? 1 : 0,
      PrinterNum: this.formRef.current.getFieldValue('PrinterNum'),
      Submission: this.formRef.current.getFieldValue('Submission') ? 1 : 0,
      PatientNumCode: this.state.PatientNumCode,
      w7PrintedMaterialNumList: w7PrintedMaterialNums,
      currentUser: this.props.currentUser.username
    }

    if (this.formRef.current.getFieldValue('PreviewMode')) {
      // プレビュー
      DocumentBatchCreateSubAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示F
          window.open(fileURL)
          this.props.onFinishScreen()
        })
        .catch(error => {
          const res = error.response
          if (res) {
            if (res.request.responseType === 'blob') {
              res.data.text()
                .then(str => {
                  let json = JSON.parse(str);
                  message.error(json.message)
                })
            }
            if (error.message.includes('504')) {
              return Modal.error({
                title: 'タイムアウトしました',
                content: '',
              });
            }
          }
        })

    } else {
      // 印刷
      DocumentBatchCreateSubAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            // message.error('処理の途中でエラーが発生しました')
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
          this.props.onFinishScreen()
        })
        .catch(error => {
          const res = error.response
          message.error(res.message)
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
    }
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='document-batch-create-sub'>
        <Card title='資料一括作成'>
          <Form
            ref={this.formRef}
            initialValues={{ OutputPattern: '', OutputUnit: '' }}
            autoComplete='off'
          >
            <div
              className='default_main'
              style={{ padding: 10 }}
            >
              <Space>
                <Form.Item name='OutputUnit' style={{ color: 'white' }} >
                  <Radio.Group
                    onChange={(value) => this.changeOutputUnit()}
                  >
                    <Radio value={1} style={labelStyle}>個人単位</Radio>
                    <Radio value={2} style={labelStyle}>帳票単位</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name='StsAccommodationUnitsIssued' valuePropName='checked'>
                  <Checkbox
                    style={{
                      color: 'white',
                      display: this.formRef.current?.getFieldValue('OutputUnit') === 2 && this.formRef.current?.getFieldValue('AccommodationUnitsIssuedDisplay')
                        ? '' : 'none'
                    }}>
                    施設単位
                  </Checkbox>
                </Form.Item>
              </Space>
              <Form.Item
                name='OutputPattern'
                label={<label style={labelStyle}>パターン</label>}
              >
                <Select
                  style={{ width: '85%' }}
                  onChange={(value) => this.changeOutputPattern(value)}
                >
                  <Select.Option value=''>全て</Select.Option>
                  {this.state.OutputPattern?.map(value => (
                    <Select.Option key={'OutputPattern-' + Math.random()} value={value.Linked}>{value.Display}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Space>
                <Form.Item name='SpecifiedIssue' valuePropName='checked' >
                  <Checkbox
                    style={labelStyle}
                    onChange={(e) => this.changeSpecifiedIssue(e.target.checked)}
                  >
                    指定
                  </Checkbox>
                </Form.Item>
                <Form.Item name='ForcedOutput' valuePropName='checked' >
                  <Checkbox
                    style={labelStyle}
                  >
                    強制
                  </Checkbox>
                </Form.Item>
                <Form.Item name='Submission' valuePropName='checked' >
                  <Checkbox
                    style={labelStyle}
                    disabled={!this.formRef.current?.getFieldValue('UseSubmission')}
                  >
                    提出用
                  </Checkbox>
                </Form.Item>
              </Space>
            </div>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              scroll={{ y: 400 }}
              rowKey={(record) => record.id}
            >
              {this.state.CheckSpecifiedIssue ?
                <Table.Column
                  title='印刷'
                  width={40}
                  dataIndex='Print'
                  align='center'
                  render={(value, record, index) => {
                    return (
                      <Form.Item
                        name={['tableData', index, 'Print']}
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox />
                      </Form.Item>
                    )
                  }} />
                :
                null
              }
              <Table.Column
                title='帳票'
                dataIndex='formName'
                render={(value, record, index) => (
                  <div style={{ fontWeight: record.Print ? 'bold' : '' }}>
                    {value}
                  </div>
                )}
              />
            </Table>

          </Form>
          <div className='box_button_bottom_right'>
            <Button
              icon={<PrinterOutlined />}
              type='primary'
              onClick={() => this.ShowWS0433001_PrinterConfirm()}
            >
              <span className='btn_label'>
                印刷
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  currentUser: userReducer.user
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0650001_DocumentBatchCreateSub)
