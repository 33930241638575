import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Card, Col, Row, Table, Form, Input, PageHeader, Checkbox, Layout, Button, Select, message, Modal } from "antd";
import ReceiptProcessSubAction from "redux/CounterBusiness/Counter/ReceiptProcessSub.action";
import ModalDraggable from "components/Commons/ModalDraggable";
import GetImage from "constants/Images";
import moment from 'moment';
import print from 'assets/img/print.png'
import coppy from 'assets/img/coppy.png'
import ReceiptIssueOnlineAction from 'redux/AccountingBusiness/ReceiptPreIssue20/ReceiptIssueOnline.actions'
import PaymentProcessSubAction from "redux/CounterBusiness/Counter/PaymentProcessSub.action";
import WS2623002_PayTypeChangeSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2623002_PayTypeChangeSub.jsx';

const styleImg = {
  marginBottom: '0.5em',
  width: '50px',
  padding: '2px'
}

const buttonStyle = {
  height: 116,
  width: 105,
  borderRadius: 5
}

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '60px' } }
}

const labelCol2 = {
  labelCol: { style: { width: '70px' } }
}

const formatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
class WS2622004_ReceiptProcessSubBulk extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_ReceiptManageNum1Sheet: PropTypes.any,
    Li_ReceiptManageNum2Nd: PropTypes.any,
    Li_ReceiptManageNum3Rd: PropTypes.any,
    Li_Identify: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_SelectOptions: PropTypes.any,
  }
  constructor(props) {
    super(props);

    // document.title = '領収処理SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      initialValues: {},
      getIndex1: {},
      getIndex2: {},
      getIndex3: {},
      OptionInspect1: [],
      OptionInspect2: [],
      OptionInspect3: [],
      ScreenData: {},
      Cards: [],
      Li_UsuallyInAdvance: "",
      specifiedIdentify: '',
      PrinterNum_List: [],
      ReceiptStyle_List: [],
      form_data: {},
      billingManageNum: '',
      receiptManagementNumberList: [],
    };
  }

  handleSelectRowsTableFirst = selectedRowTableFirst => {
    this.setState({ selectedRowTableFirst });
  };

  convertBoleanToNum = (input) => input ? 1 : 0

  componentDidMount = () => {
    if (this.props.paymentFlag) {
      // 入金処理
      this.getInitialData()
    } else {
      this.setState({ specifiedIdentify: this.props.Li_SpecifiedIdentify })
      // 領収処理
      this.getScreenDataForIssue()
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.forceUpdate()
      this.loadData()
    }
  }

  getInitialData = () => {
    PaymentProcessSubAction.getScreenData({ Li_ReserveNum: this.props.Li_ReserveNum })
      .then(res => {
        if (res) {
          this.setState({
            billingManageNum: res.data.BillingManageNum
          })
          this.paymentProcess(res.data)
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }

  // 入金処理
  paymentProcess = (data) => {
    let date = new Date()

    const params = {
      PayDay: data.Date,
      AmountBilled: data.AmountBilled,
      DepositAmount: data.AmountBilled,
      StsAnotherDay1: this.convertBoleanToNum(data.StsAnotherDay1),
      Li_ReserveNum: this.props.Li_ReserveNum,
      BillingManageNum: data.BillingManageNum,
      PayType: data.PayType,
      Date: data.Date,
      StsPreReceipt: this.convertBoleanToNum(data.StsPreReceipt),
      ReceiptDateInitialValue: data.ReceiptDateInitialValue,
      StsAnotherDate2: this.convertBoleanToNum(data.StsAnotherDate2),
      StsAnotherDate3: this.convertBoleanToNum(data.StsAnotherDate3),
    }
    PaymentProcessSubAction.confirmF12(params)
      .then(res => {
        this.props.reloadData()
        this.setState({ specifiedIdentify: res.data.Li_SpecifiedIdentify })
        this.getScreenDataForIssue()
      })
  }

  // 領収書発行（オンライン）の前準備処理
  getScreenDataForIssue = () => {
    ReceiptIssueOnlineAction.getScreenData()
      .then((res) => {
        var StyleListCmbName_list = res.StyleListCmbName.split(",");
        var StyleListCmbNum_list = res.StyleListCmbNum.split(",");
        let array_temp = []
        for (let i = 0; i < StyleListCmbName_list.length; i++) {
          let obj_temp = {
            StyleListCmbName: StyleListCmbName_list[i],
            StyleListCmbNum: StyleListCmbNum_list[i]
          }
          array_temp.push(obj_temp)
        }
        let printerNumConfirmation = res.printNumberList.some(elem => {
          return elem === res.PrinterNum;
        })
        if (!printerNumConfirmation) {
          res.PrinterNum = res.PrinterNum_List[0]?.printer_number
        }
        this.setState({
          PrinterNum_List: res.PrinterNum_List,
          ReceiptStyle_List: array_temp,
          form_data: res,
          Li_UsuallyInAdvance: this.props.Li_UsuallyInAdvance === '2' ? true : false
        })

        this.formRef.current?.setFieldsValue(res)
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          return;
        }
      })
      .finally(() => {
        this.loadData()
      })
  }

  // 領収処理
  loadData() {
    const prams = {
      Li_SpecifiedIdentify: this.state.specifiedIdentify
    }

    ReceiptProcessSubAction.getScreenData({
      ...prams,
      Li_ReserveNum: this.props.Li_ReserveNum
    })
      .then(res => {
        this.setFormFieldValue('sts1', res && res.sts1 ? res.sts1 : 0)
        this.setFormFieldValue('sts2', res && res.sts2 ? res.sts2 : 0)
        this.setFormFieldValue('sts3', res && res.sts3 ? res.sts3 : 0)

        this.formRef.current?.setFieldsValue({
          PersonalNameOutputPresence1: true,
          PersonalNameOutputPresence2: true,
          PersonalNameOutputPresence3: true,
        })

        let cards = [
          { sts: res.sts1, receipt: res.ReceiptManageNum1Sheet, title: res.Title1Sheet },
          { sts: res.sts2, receipt: res.ReceiptManageNum2Nd, title: res.Title2Nd },
          { sts: res.sts3, receipt: res.ReceiptManageNum3Rd, title: res.Title3Rd }
        ]

        this.setState({
          ...this.state,
          ScreenData: res,
          Cards: cards
        })

        const params = {
          Cards: cards,
          Li_ReserveNum: this.props.Li_ReserveNum,
        }

        ReceiptProcessSubAction.getIndex(params)
          .then(res => {
            res.forEach((item, i) => {
              this.renderForm(item, i)
            })
            this.forceUpdate()
          })
          .finally(() => {
            this.onFinish()
          })
      })
  }

  renderForm(values, i) {
    let array = []
    values.W1_destination_name.forEach((item, l) => {
      array = [
        ...array,
        {
          value: item
        }
      ]
    })

    this.setState({
      ...this.state,
      [`getIndex${i + 1}`]: values,
      [`OptionInspect${i + 1}`]: values.OptionInspectData,
      [`W1_destination_name${i + 1}`]: array,
    })

    this.setFormFieldValue(`ReceiptDateChar${i + 1}`, values && values?.ReceiptDateChar ? moment(values?.ReceiptDateChar) : '')
    this.setFormFieldValue(`W1_destination_name${i + 1}`, values && values?.W1_destination_name[0] ? values?.W1_destination_name[0] : '')
    this.setFormFieldValue(`receiptNumber${i + 1}`, values && values?.receiptNumber ? values?.receiptNumber : '-')
    this.setFormFieldValue(`W1_subject${i + 1}`, values && values?.W1_subject ? values?.W1_subject : '')
    this.setFormFieldValue(`DepositAndWithdrawalForm${i + 1}`, values && values?.DepositAndWithdrawalForm ? values?.DepositAndWithdrawalForm : '')
    this.setFormFieldValue(`identify${i + 1}`, values && values?.identify ? values?.identify : '')
  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }

  // 印刷処理
  print() {
    let receiptManagementNumberList = []
    //チェックが入っているモノのreceiptを取得
    this.state.Cards.forEach((s, i) => {
      if (this.formRef?.current?.getFieldValue(`sts${i + 1}`)) {
        receiptManagementNumberList.push({
          receiptManagementNumber: s.receipt,
          receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
          OfficeNameOutputPresence: this.formRef.current?.getFieldValue(`OfficeNameOutputPresence${i + 1}`),
          PersonalNameOutputPresence: this.formRef.current?.getFieldValue(`PersonalNameOutputPresence${i + 1}`),
          Li_ReceiptDateChar: this.formRef.current?.getFieldValue(`ReceiptDateChar${i + 1}`).format("YYYY-MM-DD") ?? null,
          Li_DestinationName: this.formRef.current?.getFieldValue(`W1_destination_name${i + 1}`),
          W1_subject: this.formRef.current?.getFieldValue(`W1_subject${i + 1}`),
          Li_ReserveNum: this.props.Li_ReserveNum ?? null,
        })
      }
    });

    let parram = {
      Preview: false,
      InspectIssue: this.props.InspectIssue ? 1 : 0,
      ReceiptDatePresence: this.formRef.current?.getFieldValue('ReceiptDatePresence') ? 1 : 0,
      ReissueMarkOutputPresence: this.formRef.current?.getFieldValue('ReissueMarkOutputPresence') ? 1 : 0,
      OfficeNameOutputPresence: this.formRef.current?.getFieldValue('OfficeNameOutputPresence') ? 1 : 0,
      PrinterNum: this.formRef.current?.getFieldValue('PrinterNum') ?? 0,
      UnexecutedEditingChar: this.formRef.current?.getFieldValue('UnexecutedEditingChar') ?? "",
      Proviso: this.formRef.current?.getFieldValue('Proviso') ?? "",
      PersonalNameOutputPresence: this.formRef.current?.getFieldValue('PersonalNameOutputPresence') ? 1 : 0,
      StyleListCmbNum: this.formRef.current?.getFieldValue('StyleListCmbNum') ?? "",
      ReceiptStyle: this.formRef.current?.getFieldValue('ReceiptStyle') ?? "",
      Li_TaskType: this.props.Li_TaskType,
      Li_Parameters: this.props.Li_Parameters,

      //以下、窓口における入金後の領収発行時に使用するパラメータ
      Li_CounterReceiptProcess: 1,
      Li_ReceiptManageNum: this.state.ScreenData.ReceiptManageNum1Sheet ?? null,
      Li_ReceiptDateChar: moment(this.formRef.current?.getFieldValue('ReceiptDateChar1')).format("YYYY/MM/DD") ?? null,
      Li_ReserveNum: this.props.Li_ReserveNum ?? null,
      Li_BillingManageNum: this.state.billingManageNum ?? null,
      Li_ReceiptDateOn: this.state.ScreenData.ReceiptDateOn ?? null,
      Li_DestinationName: this.formRef.current?.getFieldValue('W1_destination_name1') ?? null,
      Li_Receipts: this.state.ScreenData.Receipts ?? null,
      Li_receiptManagementNumberList: receiptManagementNumberList ?? null,
    }

    ReceiptIssueOnlineAction.print(parram)
      .then((res) => {
        if (res.data === 'true') {
          message.success('印刷が完了しました')
          this.props.onFinishScreen()
        } else {
          message.error('処理の途中でエラーが発生しました')
        }
      }).catch(error => {
        const res = error.response;
        if (error.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          });
        }
      })
  }

  // プレビュー処理
  preview() {
    let receiptManagementNumberList = []
    //チェックが入っているモノのreceiptを取得
    this.state.Cards.forEach((s, i) => {
      if (this.formRef?.current?.getFieldValue(`sts${i + 1}`)) {
        receiptManagementNumberList.push({
          receiptManagementNumber: s.receipt,
          receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
          OfficeNameOutputPresence: this.formRef.current?.getFieldValue(`OfficeNameOutputPresence${i + 1}`),
          PersonalNameOutputPresence: this.formRef.current?.getFieldValue(`PersonalNameOutputPresence${i + 1}`),
          Li_ReceiptDateChar: this.formRef.current?.getFieldValue(`ReceiptDateChar${i + 1}`).format("YYYY-MM-DD") ?? null,
          Li_DestinationName: this.formRef.current?.getFieldValue(`W1_destination_name${i + 1}`),
          W1_subject: this.formRef.current?.getFieldValue(`W1_subject${i + 1}`),
          Li_ReserveNum: this.props.Li_ReserveNum ?? null
        })
      }
    });

    let parram = {
      Preview: true,
      InspectIssue: this.props.InspectIssue ? 1 : 0,
      ReceiptDatePresence: this.formRef.current?.getFieldValue('ReceiptDatePresence') ? 1 : 0,
      ReissueMarkOutputPresence: this.formRef.current?.getFieldValue('ReissueMarkOutputPresence') ? 1 : 0,
      OfficeNameOutputPresence: this.formRef.current?.getFieldValue('OfficeNameOutputPresence') ? 1 : 0,
      PrinterNum: this.formRef.current?.getFieldValue('PrinterNum') ?? 0,
      UnexecutedEditingChar: this.formRef.current?.getFieldValue('UnexecutedEditingChar') ?? "",
      Proviso: this.formRef.current?.getFieldValue('Proviso') ?? "",
      PersonalNameOutputPresence: this.formRef.current?.getFieldValue('PersonalNameOutputPresence') ? 1 : 0,
      StyleListCmbNum: this.formRef.current?.getFieldValue('StyleListCmbNum') ?? "",
      ReceiptStyle: this.formRef.current?.getFieldValue('ReceiptStyle') ?? "",
      StsPrintPreview: this.props.StsPrintPreview ? 1 : 0,
      Li_UsuallyInAdvance: this.state.Li_UsuallyInAdvance,
      Li_TaskType: this.props.Li_TaskType,
      Li_Parameters: this.props.Li_Parameters,

      //以下、窓口における入金後の領収発行時に使用するパラメータ
      Li_CounterReceiptProcess: 1,
      Li_ReceiptManageNum: this.state.ScreenData.ReceiptManageNum1Sheet ?? null,
      Li_ReceiptDateChar: moment(this.formRef.current?.getFieldValue('ReceiptDateChar1')).format("YYYY-MM-DD") ?? null,
      Li_ReserveNum: this.props.Li_ReserveNum ?? null,
      Li_BillingManageNum: this.state.billingManageNum ?? null,
      Li_ReceiptDateOn: this.state.ScreenData.ReceiptDateOn ?? null,
      Li_DestinationName: this.formRef.current?.getFieldValue('W1_destination_name1') ?? null,
      Li_Receipts: this.state.ScreenData.Receipts ?? null,
      Li_receiptManagementNumberList: receiptManagementNumberList ?? null,
    }

    ReceiptIssueOnlineAction.preview(parram)
      .then((res) => {
        // pdfのObject作成
        // Serviceで{ responseType: "blob" }を設定する必要がある
        let fileURL = URL.createObjectURL(res.data);
        // pdfを別タブで表示
        var fileOpen = window.open(fileURL)
        this.props.onFinishScreen()
      })
      .catch(error => {
        const res = error.response;
        if (res.request.responseType === 'blob') {
          res.data.text()
            .then(str => {
              let json = JSON.parse(str);
              message.error(json.message)
            })
        }
        if (error.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          });
        }
      })
  }

  // 入金区分変更
  PayTypeChange(i) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '300px',
        component: (
          <WS2623002_PayTypeChangeSub
            DepositAndWithdrawalForm={this.formRef.current?.getFieldValue(`DepositAndWithdrawalForm${i + 1}`)}
            identify={this.formRef.current?.getFieldValue(`identify${i + 1}`)}
            ReserveNum={this.props.Li_ReserveNum ?? null}
            onFinish={() => {
              this.getScreenDataForIssue()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  onFinish = (values) => {
    let list = [];
    let receiptManagementNumberList = [];

    this.state.Cards.forEach((s, i) => (
      list.push({
        receipt: s.receipt,
        price: s.title,
        destination_name: this.formRef?.current?.getFieldValue(`W1_destination_name${i + 1}`),
        subject: this.formRef?.current?.getFieldValue(`W1_subject${i + 1}`),
        receiptDateChar: moment(this.formRef?.current?.getFieldValue(`ReceiptDateChar${i + 1}`)).format("YYYY/MM/DD"),
        receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
        flag: this.formRef?.current?.getFieldValue(`sts${i + 1}`) ? 1 : 0
      })
    ));

    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      list: list
    }

    //チェックが入っているモノのreceiptを取得
    this.state.Cards.forEach((s, i) => {
      if (this.formRef?.current?.getFieldValue(`sts${i + 1}`)) {
        receiptManagementNumberList.push({
          receiptManagementNumber: s.receipt,
          receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
        })
      }
    });

    ReceiptProcessSubAction.confirmF12(params)
      .then(() => {
        this.setState({ receiptManagementNumberList: receiptManagementNumberList })
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const { selectedRowTableFirst, OptionInspect } = this.state
    const rowSelectionTableFirst = {
      selectedRowTableFirst,
      onChange: this.handleSelectRowsTableFirst
    }
    return (
      <div className="receipt-process-sub">
        <Card
          title="領収処理"
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ ...this.state.initialValues }}
          >
            <div className="box_container">
              <div className="box_inner_horizontal">
                <Form.Item
                  hidden={this.state.Li_UsuallyInAdvance}
                  name="ReceiptDatePresence"
                  label="領収日"
                  valuePropName="checked"
                  style={{ marginLeft: 10 }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>

                <Form.Item
                  name="ReissueMarkOutputPresence"
                  label="再発行印"
                  valuePropName="checked"
                  style={{ marginLeft: 10 }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </div>

              <div style={{ display: 'flex', gap: 10 }}>
                {this.state.Cards.map((s, i) => (
                  <Card
                    key={i}
                    hidden={!s.sts > 0}
                    style={{ overflowX: 40, overflowY: 40, paddingTop: 0, width: 500 }}
                  >
                    <div className="box_inner_horizontal" style={{ justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <Form.Item
                          hidden={this.state.Li_UsuallyInAdvance}
                          name={`OfficeNameOutputPresence${i + 1}`}
                          label="事業所名"
                          valuePropName="checked"
                          style={{ marginLeft: 10 }}
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>

                        <Form.Item
                          hidden={this.state.Li_UsuallyInAdvance}
                          name={`PersonalNameOutputPresence${i + 1}`}
                          label="個人氏名"
                          valuePropName="checked"
                          style={{ marginLeft: 10 }}
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </div>

                      <div style={{ marginTop: 5 }}>領収書No:{this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`)}</div>
                    </div>

                    <PageHeader
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      title={
                        <div style={{ textAlign: 'center' }}>
                          <Form.Item
                            name={`sts${s.sts > 0 ? i + 1 : ''}`}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ fontSize: 20 }}>
                              <h4 style={{ marginBottom: 0 }}>￥{formatter(s.title)}</h4>
                            </Checkbox>
                          </Form.Item>
                        </div>}
                    />

                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item
                          name={`ReceiptDateChar${i + 1}`}
                          style={{ width: '100%' }}
                          label="領収日"
                          {...labelCol}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={'YYYY/MM/DD'}
                            style={{ float: 'right', width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item
                          name={`W1_destination_name${i + 1}`}
                          label="宛先"
                          {...labelCol}
                        >
                          <Select mode="tags" options={this.props.Li_SelectOptions} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item
                          name={`W1_subject${i + 1}`}
                          label="コース"
                          {...labelCol}
                        >
                          <Input type="text" />
                        </Form.Item>
                      </Col>
                    </Row>

                    {this.props.paymentFlag ?
                      null
                      :
                      <Row gutter={24}>
                        <Col span={16}>
                          <Form.Item
                            label="入金区分"
                            {...labelCol}
                          >
                            <span style={{ marginBottom: 1 }}>{this.formRef.current?.getFieldValue(`DepositAndWithdrawalForm${i + 1}`) == 1 ? '現金' : 'クレジット'}</span>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Button
                            type="primary"
                            style={{ float: "right" }}
                            onClick={() => { this.PayTypeChange(i) }}
                          >
                            入金区分変更
                          </Button>
                        </Col>
                      </Row>
                    }

                    <Table
                      bordered={true}
                      showHeader={true}
                      dataSource={this.state[`OptionInspect${i + 1}`]}
                      // スクロールを入れると幅がおかしくなるためwidthで調整
                      style={{ display: this.state[`OptionInspect${i + 1}`].length === 0 ? 'none' : '' }}
                      rowKey={(record) => record.id}
                      pagination={false}
                      scroll={{ x: 'max-content', y: 200 }}
                    >
                      <Table.Column
                        className="column-size-40"
                        title="オプション"
                        dataIndex="set_name"
                        render={(text, record, index) => (
                          <div style={{ display: 'flex' }}>
                            <img
                              style={{
                                width: 'fit-content',
                                height: 'fit-content',
                                marginRight: '10px',
                                marginTop: 'auto',
                                marginBottom: 'auto'
                              }}
                              src={GetImage(record.Expresstion_8)}
                              alt="icon"
                            />
                            <div>{record.set_name}</div>
                          </div>
                        )}
                      />
                      <Table.Column
                        width={80}
                        title="金額"
                        dataIndex="Expresstion_6"
                        render={(text, record, index) => (
                          <div style={{ textAlign: 'light' }}>
                            <span>{formatter(record.Expresstion_6)}</span>
                          </div>
                        )} />
                    </Table>
                  </Card>
                ))}
              </div>

              <div className="box_inner_horizontal">
                <div className="box_inner_vertical">
                  <Form.Item
                    name="Proviso"
                    label="但し書き"
                    {...labelCol2}
                  >
                    <Input type='text' />
                  </Form.Item>

                  <Form.Item
                    name="PrinterNum"
                    label="プリンター"
                    {...labelCol2}
                  >
                    <Select>
                      {this.state.PrinterNum_List?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.printer_number}
                        >
                          {value.printer_name ?? ''}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="box_button_bottom_right">
                  <Button
                    onClick={() => this.print()}
                    style={buttonStyle}
                  >
                    <div
                      style={{ textAlign: 'center', padding: '0.5em' }}
                      key='print'
                    >
                      <img src={print} style={styleImg} />
                      <br />
                      印刷
                    </div>
                  </Button>

                  <Button
                    onClick={() => this.preview()}
                    style={buttonStyle}
                  >
                    <div
                      style={{ textAlign: 'center', padding: '0.5em' }}
                      key='preview'
                    >
                      <img src={coppy} style={styleImg} />
                      <br />
                      プレビュー
                    </div>
                  </Button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });

            if (this.props.onFinishScreen) {
              this.props.onFinishScreen({
                StsSend: true,
                nameScreen: 'WS2621001_PaymentProcessSub'
              })
            }
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2622004_ReceiptProcessSubBulk);
