import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Space } from "antd";
import axios from 'configs/axios';

class WS1871014_Copy extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "複写";

    this.state = {
      initialValues: {
        Li_CopySource: "",
        Li_CopySourceName: "",
        Copy: "",
        interpretation_exam_name: "",
      }
    };
  }

  componentDidMount() {
    this.getInitialValues()
  }
  componentDidUpdate(PropPev) {
    if (this.props !== PropPev) {
      this.getInitialValues()
    }
  }
  getInitialValues = () => {
    this.setState({
      initialValues: {
        Li_CopySource: this.props.Li_CopySource,
        Li_CopySourceName: this.props.Li_CopySourceName,
        Copy: this.props.Lo_Copy,
        interpretation_exam_name: this.props.Li_CopySourceName
      }
    })
  }

  getNameCopy = (value) => {
    let params = {
      Copy: value
    }
    axios.get('/api/radio-graphy-inspect-maintain/copy/get-name-copy', { params })
      .then(res => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            interpretation_exam_name: res.data.interpretation_exam_name ?? ""
          }
        })
      }).catch(error => {
        console.log('error->', error)
      })
  }

  onFinish(values) { }

  render() {
    return (
      <div className="copy">
        <Card title="複写" className="mb-2">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: "space-between"
                }}>
                <Form.Item
                  name="Li_CopySource"
                  label="複写元"
                >
                  <span>{this.state.initialValues.Li_CopySource}</span>
                  {/* <Input value='303333' readOnly style={{border: 'none'}} type="text" /> */}
                </Form.Item>
                <Form.Item
                  style={{
                    marginRight: '70px'
                  }}
                  name="Li_CopySourceName"
                  label=""
                >
                  <span>{this.state.initialValues.Li_CopySourceName}</span>
                  {/* <Input value='303333' readOnly style={{border: 'none'}} type="text" /> */}
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: "space-between"
                }} >
                <Form.Item name="Copy" label="複写先">
                  <Input type="text" onChange={(e) => this.getNameCopy(e.target.value)} />
                </Form.Item>
                <Form.Item
                  style={{
                    marginRight: '70px'
                  }}
                  name="interpretation_exam_name"
                  label=""
                >
                  <span>{this.state.initialValues.interpretation_exam_name}</span>
                  {/* <Input value='303333' readOnly style={{border: 'none'}} type="text" /> */}
                </Form.Item>
              </div>
            </div>
            <Form.Item
              style={{
                textAlign: "end",
                marginTop: "20px"
              }}>
              <Button type="primary">複写</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1871014_Copy);
