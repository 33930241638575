import { message } from 'antd'
import OverallResultDisplayInputService from 'services/InputBusiness/NotInputCheckCategory/OverallResultDisplayInputService'

const OverallResultDisplayInputAction = {
  index(data) {
    return OverallResultDisplayInputService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getCategoryJudgeList(data) {
    return OverallResultDisplayInputService.getCategoryJudgeList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getPreviousCategoryDo(data) {
    return OverallResultDisplayInputService.getPreviousCategoryDo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeCategoryJudge(data) {
    return OverallResultDisplayInputService.changeCategoryJudge(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getInspectResultList(data) {
    return OverallResultDisplayInputService.getInspectResultList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  suffixResultValue(data) {
    return OverallResultDisplayInputService.suffixResultValue(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  setPreviousInspectDo(data) {
    return OverallResultDisplayInputService.setPreviousInspectDo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveResultValueList(data) {
    return OverallResultDisplayInputService.saveResultValueList(data)
      .then((res) => {
        if (res.data.message === 'OK') {
          return
        } else {
          message.success(res.data.message)
          return res?.data
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getGuideCommentInput(data) {
    return OverallResultDisplayInputService.getGuideCommentInput(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  checkJudgmentExist(data) {
    return OverallResultDisplayInputService.checkJudgmentExist(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveJudgmentBasic(data) {
    return OverallResultDisplayInputService.saveJudgmentBasic(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveJudgmentCategory(data) {
    return OverallResultDisplayInputService.saveJudgmentCategory(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  checkDefaultValue(data) {
    return OverallResultDisplayInputService.checkDefaultValue(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  setPreviousExamCodeDo(data) {
    return OverallResultDisplayInputService.setPreviousExamCodeDo(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  setPreviousExamCategoryCodeDo(data) {
    return OverallResultDisplayInputService.setPreviousExamCategoryCodeDo(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


  // ↓使用無し
  FindingsDelete(data) {
    return OverallResultDisplayInputService.FindingsDelete(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  findingsEditingBefore(data) {
    return OverallResultDisplayInputService.findingsEditingBefore(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  findingsEditingAfter(data) {
    return OverallResultDisplayInputService.findingsEditingAfter(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  acceptedNo(data) {
    return OverallResultDisplayInputService.acceptedNo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  accepted(data) {
    return OverallResultDisplayInputService.accepted(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  AcceptedNoIt(data) {
    return OverallResultDisplayInputService.acceptedNoIt(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  EventChangeStsInputModeAll(data) {
    return OverallResultDisplayInputService.EventChangeStsInputModeAll(data)
      .then((res) => {
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  EventChangeGuideItemDisplay(data) {
    return OverallResultDisplayInputService.EventChangeGuideItemDisplay(data)
      .then((res) => {
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  CategoryJudgeSaveRow(data) {
    return OverallResultDisplayInputService.CategoryJudgeSaveRow(data)
      .then()
  },

  EventDbClickExpression4(data) {
    return OverallResultDisplayInputService.EventDbClickExpression4(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  EventClickExpression4(data) {
    return OverallResultDisplayInputService.EventClickExpression4(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  EventAllSelect(data) {
    return OverallResultDisplayInputService.EventAllSelect(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  Ctrl_F(data) {
    return OverallResultDisplayInputService.Ctrl_F(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  PrefixW1ResultValue(data) {
    return OverallResultDisplayInputService.PrefixW1ResultValue(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  SuffixW1InspectJugde(data) {
    return OverallResultDisplayInputService.SuffixW1InspectJugde(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  ChangeW1InspectJudge(data) {
    return OverallResultDisplayInputService.ChangeW1InspectJudge(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  SaveRowInspectResult(data) {
    return OverallResultDisplayInputService.SaveRowInspectResult(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  GuideCommentInputSaveRow(data) {
    return OverallResultDisplayInputService.GuideCommentInputSaveRow(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  EventChangeW3GeneralCmtCd(data) {
    return OverallResultDisplayInputService.EventChangeW3GeneralCmtCd(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  EventChangeW3OverallCmt(data) {
    return OverallResultDisplayInputService.EventChangeW3OverallCmt(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  GuideCommentInputDeleteRow(data) {
    return OverallResultDisplayInputService.GuideCommentInputDeleteRow(data)
      .then()
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getResultValue(data) {
    return OverallResultDisplayInputService.getResultValue(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}

export default OverallResultDisplayInputAction
