import axios from 'configs/axios'
const APP_LIST = {
  index: '/api/print-param-maintain/old-item',
  paramIndicationNameObtainingSu: '/api/print-param-maintain/old-item/paramIndicationNameObtainingSu',
  save: '/api/print-param-maintain/old-item/save',
  instructiondivisionchange: '/api/print-param-maintain/old-item/instruction-division-change',
  outputExcel: '/api/print-param-maintain/old-item/output-excel',
  downloadExcelFile: '/api/print-param-maintain/old-item/download-excel-file',
  readExcelFile: '/api/print-param-maintain/old-item/read-excel-file',
}
const OldItemService = {
  async index(params) {
    return axios.get(APP_LIST.index, { params })
  },
  async paramIndicationNameObtainingSu(data) {
    return axios.get(APP_LIST.paramIndicationNameObtainingSu, data)
  },
  async save(params) {
    return axios.post(APP_LIST.save, params)
  },
  async instructiondivisionchange(params) {
    return axios.post(APP_LIST.instructiondivisionchange, params)
  },
  async outputExcel(params) {
    return axios.post(APP_LIST.outputExcel, params);
  },
  async downloadExcelFile(params) {
    return axios.post(APP_LIST.downloadExcelFile, params, { responseType: 'arraybuffer' });
  },
  async readExcelFile(params) {
    return axios.post(APP_LIST.readExcelFile, params);
  },
}

export default OldItemService
