import { message } from 'antd'
import ResultAcquisitionProcessService from 'services/CooperationRelated/ResultAcquisitionProcess/ResultAcquisitionProcessService.js'

const ResultAcquisitionProcessAction = {
  getMiraisData(data) {
    return ResultAcquisitionProcessService.getMiraisData(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data.UpdateLogList
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}

export default ResultAcquisitionProcessAction