import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Form, Input, Radio, Select, Button, Table, Menu, Dropdown, Tooltip, message, Space, InputNumber } from 'antd'
import { MoreOutlined, SearchOutlined, MenuOutlined, InfoOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import Color from 'constants/Color'
import {
  getScreenInvoiceAction,
  DisplayBtnInvoiceAction,
  QueryResultsDisplayInvoiceAction,
  OptionsDisplayInvoiceAction,
  Issue_F12InvoiceAction,
  MissingOrDeletedInvoiceAction,
  ChangeInvoiceAction,
} from 'redux/AccountingBusiness/Invoice/Invoice.actions'
import WS2354001_BillingManageLedgerInstruction from '../V3BILL200_BillingManageLedgerInstruction/WS2354001_BillingManageLedgerInstruction'
import WS0963001_InvoiceMaintain from '../V4BL0031000_DepositWithdrawalInquiry/WS0963001_InvoiceMaintain'
import WS0948001_BillingAggregationProcess from './WS0948001_BillingAggregationProcess'
import WS0947001_Invoice from '../V4BL0008000_BillingIntegration/WS0947001_Invoice'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0952001_CounterPayment from './WS0952001_CounterPayment'
import WS2626028_IntegrationReleased from './WS2626028_IntegrationReleased'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS0339001_InsurerInfoMaintain from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain'
import WS1512001_OptionalInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512001_OptionalInfoMaintain'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS2626001_Invoice extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '請求書発行'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        // size: 'small',
        showQuickJumper: true
      },
      listID: [],
      selectedRowKeys: [],
      selectedRows: [],
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      initObj: {},
      office_kanji_name: '',
      initParams: {
        ClaimYearsChar: '',
        ClaimNum: '',
        Outstanding: 2,
        Target: 0,
        ClaimIdentify: 0,
        InsurerNum: '',
        OfficeNum: '',
        BranchStoreCode: '',
        AddressSearch: '',
        StsSelectAll: '',
        ClaimYears: ''
      },
      menuItems: [
        { id: 1, label: '管理台帳', handleClick: this.eventF9 },
        { id: 2, label: '新規', handleClick: this.eventF10 },
        { id: 3, label: '集計', handleClick: this.eventF11 },
        { id: 4, label: '発行', handleClick: this.Issue_F12 },
      ],
    }
  }

  componentDidMount = () => {
    this.loadInitData()
  }

  loadInitData = () => {
    getScreenInvoiceAction()
      .then(res => {
        if (res?.data) {
          this.setState({
            initObj: res.data,
            initParams: {
              ...this.state.initParams,
              StsSelectAll: res.data.StsSelectAll,
              ClaimYears: res.data.ClaimYears,
              ClaimYearsChar: res.data.ClaimYearsChar
            }
          })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  loadData = () => {
    this.setState({ isLoading: true })
    QueryResultsDisplayInvoiceAction()
      .then(res => {
        if (res?.data) {
          let arrID = []
          if (res.data.length > 0) {
            res.data.forEach(element => {
              if (element.W1_target) arrID.push(element.id)
            })
          }
          this.setState({
            listID: arrID,
            selectedRowKeys: arrID,
            selectedRows: res.data,
            dataSource: res.data,
            rowSelect: res.data
          })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  DisplayBtn = () => {
    DisplayBtnInvoiceAction(this.state.initParams)
      .then(res => {
        if (res?.data) {
          let arrID = []
          if (res.data.length > 0) {
            res.data.forEach(element => {
              if (element.W1_target) arrID.push(element.id)
            })
          }
          this.setState({
            listID: arrID,
            selectedRowKeys: arrID,
            selectedRows: res.data,
            dataSource: res.data,
            rowSelect: res.data
          })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      }
      )
  }

  QueryBtn = (record) => {
    const { identification, office_code, branch_store_code, insurer_number, personal_code } = record
    if ((identification === 6 && office_code !== '') || identification === 5) {
      this.callModal({
        Li_OfficeCode: office_code,
        Li_BranchCode: branch_store_code,
      }, '60%', 'WS2585001_OfficeInfoInquirySub')
    } else if ((identification === 6 && office_code === '') || identification === 4) {
      this.callModal({ insurer_code: insurer_number }, '60%', 'WS0339001_InsurerInfoMaintain')
    } else {
      this.callModal({ Li_PersonalNum: personal_code }, '60%', 'WS2584019_PersonalInfoInquirySub')
    }
  }

  MissingOrDeleted = (params) => {
    MissingOrDeletedInvoiceAction(params)
      .then(() => this.loadData())
  }

  OptionsDisplay = () => {
    OptionsDisplayInvoiceAction()
      .then((res) => {
        let dataRes = res?.data
        if (dataRes?.message.includes('WS1512001')) {
          this.callModal(dataRes.variables, '80%', 'WS1512001_OptionalInfoMaintain')
        }
      })
  }

  Issue_F12 = () => {
    Issue_F12InvoiceAction()
      .then((res) => this.callModal({ Li_SpecifyIssuedByPresence: res.data.Li_SpecifyIssuedByPresence }, 400, 'WS0947001_Invoice'))
  }

  Change = (params) => {
    ChangeInvoiceAction(params)
      .then(() => this.loadData())
  }

  onChangeInitValue = (objChange) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        ...objChange,
        ClaimYears: objChange.ClaimYearsChar
          ? moment(objChange.ClaimYearsChar).format('YYYY/MM/DD')
          : this.state.initParams.ClaimYears
      }
    })
  }

  ReturnComponent = (component) => {
    let components = {
      WS2354001_BillingManageLedgerInstruction,
      WS0963001_InvoiceMaintain,
      WS0948001_BillingAggregationProcess,
      WS0947001_Invoice,
      WS0246001_InsurerInfoSearchQuery,
      WS0247001_OfficeInfoRetrievalQuery,
      WS0952001_CounterPayment,
      WS2626028_IntegrationReleased,
      WS2585001_OfficeInfoInquirySub,
      WS2584019_PersonalInfoInquirySub,
      WS0339001_InsurerInfoMaintain,
      WS1512001_OptionalInfoMaintain
    }
    return components[component]
  }

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        centered: true,
        component: (
          <Component
            {...props}
            onFinishScreen={(objData) => {
              this.closeModal()
              if (nameScreen === 'WS0963001_InvoiceMaintain' || nameScreen === 'WS0947001_Invoice') {
                this.DisplayBtn()
              }
              if (nameScreen === 'WS0247001_OfficeInfoRetrievalQuery') {
                const { Lio_OfficeCode, Lio_BranchStoreCode } = objData
                this.formRef?.current?.setFieldsValue({
                  OfficeNum: Lio_OfficeCode,
                  BranchStoreCode: Lio_BranchStoreCode === 0 ? null : Lio_BranchStoreCode
                })
                this.onChangeInitValue({
                  OfficeNum: Lio_OfficeCode,
                  BranchStoreCode: Lio_BranchStoreCode === 0 ? null : Lio_BranchStoreCode
                })
              } else if (nameScreen === 'WS0246001_InsurerInfoSearchQuery') {
                const { Lo_InsurerCode } = objData
                this.formRef?.current?.setFieldsValue({ InsurerNum: Lo_InsurerCode })
                this.onChangeInitValue({ InsurerNum: Lo_InsurerCode })
              }
            }}
          />
        ),
      },
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  renderRightClick = (record) => {
    const {
      delete_flag,
      integrated_destination_manageme,
      integrated_order,
      invoice_number,
      billing_management_number,
      identification,
      W1_billing_manage_num,
      W1_identify
    } = record
    // IF(delete_flag=0,IF(invoice_number=0,'削除','欠損'),IF(integrated_order=0,'復元',''))
    let eventName3 =
      delete_flag === 0
        ? invoice_number === 0 ? '削除' : '欠損'
        : integrated_order === 0 ? '復元' : ''
    return (
      <Menu>
        <Menu.Item
          key='1'
          onClick={() => {
            !(identification === 1 || billing_management_number === 2 || billing_management_number === 3)
              ?
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: 1400,
                  component: (
                    <WS0963001_InvoiceMaintain
                      Li_BillingManageNum={billing_management_number}
                      Li_flag={1}
                      Li_W1_identify={record.W1_identify}
                      onFinishScreen={(output) => {
                        this.loadData()
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: false,
                          },
                        })
                      }}
                    />
                  ),
                },
              })
              : this.callModal({ Li_BillingManageNum: billing_management_number }, 1400, 'WS0952001_CounterPayment')
          }}
        >
          修正
        </Menu.Item>

        <Menu.Item
          key='2'
          hidden={!eventName3}
          onClick={() => {
            const params = {
              invoice_number,
              W1_identify,
              W1_billing_manage_num,
              delete_flag
            }
            this.MissingOrDeleted(params)
          }}
        >
          {eventName3}
        </Menu.Item>

        {/* IF(delete_flag=0 AND integrated_destination_manageme>0 AND integrated_order=0,'統合解除','') */}
        <Menu.Item
          key='3'
          disabled={!(delete_flag === 0 && integrated_destination_manageme > 0 && integrated_order === 0)}
          onClick={() => {
            this.callModal({ Li_Identify: W1_identify, Li_BillingManageNum: W1_billing_manage_num }, '40%', 'WS2626028_IntegrationReleased')
          }}
        >
          統合解除
        </Menu.Item>
      </Menu>
    )
  }

  eventF9 = () => {
    this.callModal(
      {
        Li_MenuOption: '',
        Li_Year: moment(this.state.initParams.ClaimYears).format('YYYY'),
        Li_Month: moment(this.state.initParams.ClaimYears).format('MM')
      },
      600,
      'WS2354001_BillingManageLedgerInstruction'
    )
  }

  eventF10 = () => {
    this.callModal({
      Li_BillingManageNum: '',
      Li_flag: 1,
    },
      '80%',
      'WS0963001_InvoiceMaintain'
    )
  }

  eventF11 = () => {
    this.callModal({
      Li_Ctxgetname: this.state.initParams.Li_Ctxgetname
    },
      'fit-content',
      'WS0948001_BillingAggregationProcess'
    )
  }

  render() {
    return (
      <div className='invoice'>
        <Card
          title='請求書発行'
          style={{ position: 'relative' }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.eventF9}>
                      管理台帳
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.eventF10}>
                      新規
                    </Menu.Item>
                    <Menu.Item key='3' style={{ width: '100%' }} onClick={this.eventF11}>
                      集計
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form
              ref={this.formRef}
              initialValues={{
                Outstanding: 2,
                Target: 0,
                ClaimIdentify: 0,
                ClaimYearsChar: moment()
              }}>
              <div className='box_inner_horizontal'>
                <div className='box_search'>

                  <div className='box_search_inner_border'>
                    <Form.Item name='ClaimYearsChar' label='請求年月'>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef}
                        format='YYYY/MM'
                        picker='month'
                        style={{ width: '120px' }}
                        onChange={(date, dateString) => this.onChangeInitValue({ ClaimYearsChar: dateString })}
                      />
                    </Form.Item>
                    <Form.Item name='ClaimNum' label='請求番号'>
                      <InputNumber
                        className='input-search-size-8'
                        onChange={(value) => this.onChangeInitValue({ ClaimNum: value })}
                      />
                    </Form.Item>
                    <Form.Item name='Outstanding' label='　発　行'>
                      <Radio.Group onChange={(e) => this.onChangeInitValue({ Outstanding: e.target.value })}>
                        <Radio value={0}>全て</Radio>
                        <Radio value={1}>済</Radio>
                        <Radio value={2}>未</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name='Target' label='　形　態'>
                      <Radio.Group onChange={(e) => this.onChangeInitValue({ Target: e.target.value })}>
                        <Radio value={0}>有効</Radio>
                        <Radio value={1}>削除</Radio>
                        <Radio value={3}>全て</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className='box_search_inner'>
                    <Form.Item name='ClaimIdentify' label='　請求先'>
                      <Select
                        style={{ width: '120px' }}
                        onChange={(option) => this.onChangeInitValue({ ClaimIdentify: option })}
                      >
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={4}>保険者</Select.Option>
                        <Select.Option value={5}>事業所</Select.Option>
                        <Select.Option value={6}>他団体</Select.Option>
                        <Select.Option value={9}>個人未収</Select.Option>
                      </Select>
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item name='InsurerNum' label='　保険者'>
                        <Input.Search
                          maxLength={10}
                          allowClear={true}
                          className='input-search-size-8'
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerNum')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        InsurerNum: output.Lo_InsurerCode,
                                        insurer_kanji_name: output.Lo_Name
                                      })
                                      this.setState({
                                        insurer_kanji_name: output.Lo_Name,
                                        initParams: {
                                          ...this.state.initParams,
                                          insurer_kanji_name: output.Lo_Name
                                        }
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onChange={(e) => this.onChangeInitValue({ InsurerNum: e.target.value })}
                          onSearch={(value, event) => {
                            if (event.target.localName === "input") {
                              this.formRef.current.setFieldsValue({
                                insurer_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0246001_InsurerInfoSearchQuery
                                      Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerNum')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          InsurerNum: output.Lo_InsurerCode,
                                          insurer_kanji_name: output.Lo_Name
                                        })
                                        this.setState({
                                          insurer_kanji_name: output.Lo_Name,
                                          initParams: {
                                            ...this.state.initParams,
                                            insurer_kanji_name: output.Lo_Name
                                          }
                                        })
                                        this.onChangeInitValue({ InsurerNum: output.Lo_InsurerCode, })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='insurer_kanji_name'>
                        {this.formRef.current?.getFieldValue('insurer_kanji_name')}
                      </Form.Item>
                    </div>
                    <Form.Item label='　事業所'>
                      <Space>
                        <Form.Item name='OfficeNum' style={{ marginBottom: 0 }}>
                          <Input.Search
                            maxLength={8}
                            allowClear={true}
                            className='input-search-size-8'
                            onPressEnter={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1400,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeNum')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeNum: output.Lio_OfficeCode,
                                          BranchStoreCode: output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.setState({
                                          office_kanji_name: output.Lo_Kanji_Name,
                                          initParams: {
                                            ...this.state.initParams,
                                            OfficeNum: output.Lio_OfficeCode
                                          }
                                        })
                                        this.onChangeInitValue({ OfficeNum: output.Lio_OfficeCode })
                                        this.closeModal()
                                      }
                                      }
                                    />
                                  ),
                                },
                              })
                            }}
                            onChange={(e) => this.onChangeInitValue({ OfficeNum: e.target.value })}
                            onSearch={(value, event) => {
                              if (event.target.localName === "input") {
                                this.formRef.current.setFieldsValue({
                                  office_kanji_name: '',
                                  BranchStoreCode: ''
                                })
                                this.forceUpdate()
                              } else {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1400,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeNum')}
                                        Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue({
                                            OfficeNum: output.Lio_OfficeCode,
                                            BranchStoreCode: output.Lio_BranchStoreCode,
                                            office_kanji_name: output.Lo_Kanji_Name
                                          })
                                          this.setState({
                                            office_kanji_name: output.Lo_Kanji_Name,
                                            initParams: {
                                              ...this.state.initParams,
                                              OfficeNum: output.Lio_OfficeCode
                                            }
                                          })
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name='BranchStoreCode' style={{ marginBottom: 0 }}>
                          <Input.Search
                            allowClear={true}
                            maxLength={5}
                            className='input-search-size-4'
                            onPressEnter={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1400,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeNum')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeNum: output.Lio_OfficeCode,
                                          BranchStoreCode: output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.setState({
                                          office_kanji_name: output.Lo_Kanji_Name,
                                          initParams: {
                                            ...this.state.initParams,
                                            OfficeNum: output.Lio_OfficeCode
                                          }
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                            onSearch={(value, event) => {
                              if (event.target.localName === "input") {
                                this.formRef.current.setFieldsValue({
                                  office_kanji_name: ''
                                })
                                this.setState({
                                  office_kanji_name: ''
                                })
                              } else {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1400,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeNum')}
                                        Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                        onFinishScreen={(output) => {
                                          this.formRef.current.setFieldsValue({
                                            OfficeNum: output.Lio_OfficeCode,
                                            BranchStoreCode: output.Lio_BranchStoreCode,
                                            office_kanji_name: output.Lo_Kanji_Name
                                          })
                                          this.setState({
                                            office_kanji_name: output.Lo_Kanji_Name,
                                            initParams: {
                                              ...this.state.initParams,
                                              OfficeNum: output.Lio_OfficeCode
                                            }
                                          })
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name='office_kanji_name' style={{ marginBottom: 0 }}>
                          <div>{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                        </Form.Item>
                      </Space>
                    </Form.Item>
                    <Form.Item name='AddressSearch' label='　検　索'>
                      <Input
                        style={{ width: '100%' }}
                        maxLength={100}
                        onChange={(e) => this.onChangeInitValue({ AddressSearch: e.target.value })} />
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={this.DisplayBtn}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                  let arrTemp = [...this.state.selectedRowKeys]
                  let arrTempRecord = [...this.state.selectedRows]
                  let idx = arrTemp.indexOf(record.id)
                  if (idx === -1) {
                    arrTemp.push(record.id)
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                  } else {
                    arrTemp.splice(idx, 1)
                    arrTempRecord.splice(idx, 1)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                  }
                },

                onSelectAll: (selected, selectedRows, changeRows) => {
                  if (selected) {
                    let arrTemp = this.state.dataSource.map(item => item.id)
                    let arrTempRecord = this.state.dataSource
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      isSelectAll: true
                    })
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: [],
                      isSelectAll: false
                    })
                  }
                },
              }}

              onRow={(record, rowIndex) => ({
                onClick: event => this.setState({ rowSelect: record }),
                onDoubleClick: (event) => this.callModal({
                  Li_BillingManageNum: record.billing_management_number,
                  Li_identification: record.identification,
                  Li_flag: 1
                },
                  1400,
                  'WS0963001_InvoiceMaintain'
                ),
              })}
              scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
            >
              <Table.Column title='請求日' dataIndex='billing_date_on' align='center'
                className='column-size-5'
                showSorterTooltip={false}
                render={(text, record) => (
                  <div style={{ color: Color(record.Expresstion_7)?.Foreground }}>
                    {text && moment(text).isValid()
                      ? moment(text).format('YYYY/MM/DD(ddd)')
                      : null}
                  </div>
                )}
              />

              <Table.Column
                title='請求先'
                dataIndex='Expresstion_4'
                className='column-size-10'
                showSorterTooltip={false}
                render={(text, record) => (
                  <Tooltip title={record.Expresstion_13 || ''}>
                    <div style={{
                      textAlign: 'center',
                      padding: '1px 4px',
                      color: Color(record.Expresstion_5)?.Foreground,
                      background: Color(record.Expresstion_5)?.Background
                    }}>{text}</div>
                  </Tooltip>
                )}
              />

              <Table.Column
                title='請求番号'
                dataIndex='invoice_number'
                className='column-size-10'
                showSorterTooltip={false}
                render={(text, record) => (
                  <div style={{ textAlign: 'right', color: Color(record.Expresstion_7)?.Foreground }}>{text === 0 ? null : text}</div>
                )}
              />

              <Table.Column
                title='請求発行日'
                sdataIndex='invoice_date_on'
                className='column-size-10'
                showSorterTooltip={false}
                render={(text, record) => (
                  <div style={{ color: Color(record.Expresstion_7)?.Foreground }}>
                    {text && moment(text).isValid()
                      ? moment(text).format('YYYY/MM/DD')
                      : null}
                  </div>
                )}
              />

              <Table.Column
                title='請求金額'
                dataIndex='billing_price'
                className='column-size-10'
                showSorterTooltip={false}
                render={(text, record) => (
                  <div style={{ textAlign: 'right', color: Color(record.Expresstion_7)?.Foreground }}>
                    {text === 0 ? null : text.toLocaleString()}
                  </div>
                )}
              />

              <Table.Column
                title='コード'
                dataIndex='CodeOfficesAndInsurers'
                className='column-size-10'
                showSorterTooltip={false}
                render={(text, record) => (
                  <div style={{ textAlign: 'right' }}>{text}</div>
                )}
              />

              <Table.Column
                title='メモ'
                dataIndex=''
                width={60}
                render={(text, record) => (
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      style={{ borderRadius: '12px' }}
                      icon={<InfoOutlined />}
                      onClick={() => this.QueryBtn(record)}
                    />
                  </div>
                )}
              />

              <Table.Column
                title='宛名'
                dataIndex='according_to_destination_name'
                className='column-size-40'
                showSorterTooltip={false}
                render={(text, record) => (
                  <Tooltip title={record.Expresstion_13 || ''}>
                    <div style={{ color: Color(record.Expresstion_7)?.Foreground }}>{text}</div>
                  </Tooltip>
                )}
              />

              <Table.Column
                title='担当'
                dataIndex='UserName'
                className='column-size-40'
                showSorterTooltip={false}
                render={(record) => (record ? record.name : '')}
              />

              <Table.Column
                title='形態'
                dataIndex='State'
                className='column-size-5'
                showSorterTooltip={false}
                render={(text, record) => (
                  <div style={{ color: Color(record.Expresstion_7)?.Foreground }}>{text}</div>
                )}
              />

              <Table.Column
                title='備考'
                dataIndex='remarks'
                className='column-size-40'
                render={(text, record) => (
                  <div style={{ color: Color(record.Expresstion_7)?.Foreground }}>{text}</div>
                )}
              />

              <Table.Column
                fixed='right'

                //新規作成は保留
                title={
                  <Button
                    type='primary'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={this.eventF10}
                  />
                }

                width={60}
                trigger='click'
                render={(value, record) => (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      icon={<MoreOutlined />}
                      size='small'
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1400,
                            centered: true,
                            component: (
                              <WS0963001_InvoiceMaintain
                                Li_BillingManageNum={record.billing_management_number}
                                Li_flag={1}
                                Li_identification={record.identification}
                                onFinishScreen={(output) => {
                                  this.DisplayBtn()
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    />
                  </div>
                )}
              />
            </Table>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<PrinterOutlined />}
              onClick={() => this.callModal({ Li_SpecifyIssuedByPresence: 1, selectList: this.state.selectedRows }, 400, 'WS0947001_Invoice')}
            >
              <span className='btn_label'>
                発行
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS2626001_Invoice)
