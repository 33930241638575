import axios from 'configs/axios'

const API_LIST = {
  saveMemo: '/api/sanai-reserve-status-search/memo-function/save-memo',
  deleteMemo: '/api/sanai-reserve-status-search/memo-function/delete-memo',
  savePersonalMemo: '/api/sanai-reserve-status-search/memo-function/save-personal-memo',
  getHistoryData: '/api/sanai-reserve-status-search/memo-function/get-history-data',
  getTabooOptions: '/api/sanai-reserve-status-search/memo-function/get-taboo-options',
  saveTabooOptions: '/api/sanai-reserve-status-search/memo-function/save-taboo-options',
  deleteTabooOptions: '/api/sanai-reserve-status-search/memo-function/delete-taboo-options',
  getPersonalInfo: '/api/sanai-reserve-status-search/memo-function/get-personal-info',
  getRemarksHistoryData: '/api/sanai-reserve-status-search/memo-function/get-remarks-history-data',
}

const MemoFunctionService = {
  async saveMemo(params) {
    return axios.post(API_LIST.saveMemo, params)
  },
  async deleteMemo(params) {
    return axios.post(API_LIST.deleteMemo, params)
  },
  async savePersonalMemo(params) {
    return axios.post(API_LIST.savePersonalMemo, params)
  },
  async getHistoryData(params) {
    return axios.get(API_LIST.getHistoryData, { params })
  },
  async getTabooOptions(params) {
    return axios.get(API_LIST.getTabooOptions, { params })
  },
  async saveTabooOptions(params) {
    return axios.post(API_LIST.saveTabooOptions, params)
  },
  async deleteTabooOptions(params) {
    return axios.post(API_LIST.deleteTabooOptions, params)
  },
  async getPersonalInfo(params) {
    return axios.post(API_LIST.getPersonalInfo, params)
  },
  async getRemarksHistoryData(params) {
    return axios.post(API_LIST.getRemarksHistoryData, params)
  }
}
export default MemoFunctionService
