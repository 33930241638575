import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, message } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { Margin } from 'devextreme-react/chart';

const smGrid = {
  labelCol: { style: { width: 70 } },
};

const { TextArea } = Input

class WS2977002_DispatchManageRemarksEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '発送管理　備考編集'
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  onFinish() {
    let param = this.formRef.current?.getFieldValue()
    this.props.onFinish(param)
  }

  render() {
    return (
      <div className="dispatch-manage-remarks-edit">
        <Card title={'発送管理 [備考変更]'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' >
                <div style={{ width: 60, textAlign: 'right' }}>受診日</div>
                <div style={{}}>{this.props.visit_date_on}</div>
              </div>

              <div className='box_inner_horizontal' >
                <div style={{ width: 60, textAlign: 'right' }}>個人番号</div>
                <div style={{}}>{this.props.personal_number_id}</div>
              </div>

              <div className='box_inner_horizontal' style={{ marginBottom: 10 }}>
                <div style={{ width: 60, textAlign: 'right' }}>氏名</div>
                <div style={{}}>{this.props.kanji_name}</div>
              </div>

              <Form.Item
                label='備考'
                name='remark'
                {...smGrid}
              >
                <TextArea
                  style={{ height: 115 }}
                />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right' >
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.formRef.current?.setFieldsValue({ remark: '' })
                      this.onFinish()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.onFinish()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2977002_DispatchManageRemarksEdit)
