import { message } from "antd";
import axios from "configs/axios";

const apiPaths = {
  outputStatus: "/api/associate-insure-qualify-confirm/result-confirm-csv-output/outputStatus"
};

const ResultConfirmCsvOutputService = {

  async outputStatus(params) {
    console.log('test2')
    return axios.post(apiPaths.outputStatus, params, { responseType: 'blob' });
  },

};

export default ResultConfirmCsvOutputService;