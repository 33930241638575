import React from "react";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import 'assets/scss/_calendar.scss'
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Button, Modal } from "antd";

import WS1494003_CollectSetting from 'pages/SM_SystemMaintenance/V4SM0004200_NonConsultDateSetting/WS1494003_CollectSetting.jsx';
import WS1494006_SingleSetting from 'pages/SM_SystemMaintenance/V4SM0004200_NonConsultDateSetting/WS1494006_SingleSetting.jsx';
import { getDataVariousAggregateAction, updateAction } from 'redux/SystemMaintenance/NonConsultDateSetting/NonConsultDateSetting.actions'
import moment from "moment";

const btnStyle = {
  marginBottom: '15px',
  padding: '10px 25px',
  height: 'auto',
  width: '150px',
  borderRadius: '5px'
}
class WS1494001_NonConsultDateSetting extends React.Component {
  constructor(props) {
    super(props);
    // document.title = '休診日設定';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      selectedMonth: null,
      selectedYear: null,
      selectedDay: null
    };
  }

  formRef = React.createRef();

  componentDidMount = () => {
    const params = {
      Month: moment().format('MM'),
      Year: moment().format('YYYY'),
    }

    this.setState({
      selectedMonth: params.Month,
      selectedYear: params.Year,
      selectedDay: new Date()
    })

    this.getDataVariousAggregate(params);
  }

  //calenderのtileClassNameの設定の為、componentDidUpdateを使用
  componentDidUpdate(prevState) {
    if (this.state.dataSource !== prevState.dataSource) {
      // Condition on which tileClassName result depends got updated, so we need to re-create this function to let React-Calendar know
      this.getTileClassIsKyusin = (event) => {

        if (event.view !== 'month') {
          return null
        }
        // フォーマット変更
        const targetDay = moment(event.date).format('YYYY-MM-DD')
        let kyusinkubun = false
        let date = this.state.dataSource

        // 祝日判定
        for (let i = 0; i < date.length; i++) {
          if (targetDay === date[i].date_on) {
            kyusinkubun = false
            if (date[i].kyusinkbn === 1) {
              kyusinkubun = true
            }
          }
        }
        return (kyusinkubun) ? 'react-calendar-kyusin' : 'react-calendar-kaijyo'
      }
    }
  }

  getTileClassIsKyusin = (event) => {
    // 月表示のときのみ
    if (event.view !== 'month') {
      return null
    }
    // フォーマット変更
    const targetDay = moment(event.date).format('YYYY-MM-DD')

    let kyusinkubun = false
    let date = this.state.dataSource

    // 祝日判定
    for (let i = 0; i < date.length; i++) {
      if (targetDay === date[i].date_on) {
        kyusinkubun = false
        if (date[i].kyusinkbn === 1) {
          kyusinkubun = true
        }
      }
    }
    //休診日だったら、色を赤く
    return (kyusinkubun) ? 'react-calendar-kyusin' : 'react-calendar-kaijyo'
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  //カレンダー情報を取得
  getDataVariousAggregate = (params) => {
    getDataVariousAggregateAction(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res.data,
            changeMonthFlg: false
          });
        }
      })
      .catch(err => console.log(err.response.data.message))
  }

  /**
   * 次の月に移動した時のデータリロード
   * @param {*} activeStartDate
   * @param {*} view
   */
  reloadDate = (activeStartDate, view) => {
    const params = {
      Month: moment(activeStartDate).format('MM'),
      Year: moment(activeStartDate).format('YYYY'),
    }
    this.setState({
      selectedMonth: params.Month,
      selectedYear: params.Year,
      selectedDay: activeStartDate
    })
    // if (view === 'month') {
    this.getDataVariousAggregate(params);
    // }
  }

  moveMonthPage = (activeStartDate, view) => {
    //先月・次月に遷移
    this.reloadDate(activeStartDate, view)
  }

  //当月ボタンの処理
  thisMonth = async () => {
    const params = {
      Month: moment(new Date()).format('MM'),
      Year: moment(new Date()).format('YYYY')
    }
    this.setState({
      selectedMonth: params.Month,
      selectedYear: params.Year,
      selectedDay: new Date()
    })

    await this.getDataVariousAggregate(params);
  }

  onFinish(values) { }

  render() {
    return (
      <div className="non-consult-date-setting" style={{ width: 800 }}>
        <Card title="休診日設定">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Calendar
              className='kyusinbi'
              theme='ios'
              themeVariant='light'
              locale={'ja-JP'}
              calendarType={'US'}
              controls={['calendar']}
              touchUi={true}
              display='inline'
              prev2Label={null}
              next2Label={null}
              tileClassName={this.getTileClassIsKyusin}
              formatDay={(locale, date) => moment(date).format('D')}
              onActiveStartDateChange={({ activeStartDate, view }) => {
                this.moveMonthPage(activeStartDate, view)
              }}
              activeStartDate={this.state.selectedDay}
            />
          </div>

          <div className='box_button_bottom_right'>
            <Button type="primary"
              //クリックしたらthisMonthに飛ぶ
              onClick={() => {
                this.thisMonth()
              }}
            >当月</Button>

            <Button type="primary"
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 520,
                    component: (
                      <WS1494003_CollectSetting
                        onFinishScreen={() => {
                          const params = {
                            Month: this.state.selectedMonth,
                            Year: this.state.selectedYear
                          }
                          this.getDataVariousAggregate(params)
                          this.closeModal()
                        }}
                      />),
                  },
                })
              }}
            >一括設定</Button>

            <Button type="primary"
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 520,
                    component: (
                      <WS1494006_SingleSetting
                        Lio_Date={moment().format('YYYY/MM')}
                        selectedMonth={this.state.selectedMonth}
                        selectedYear={this.state.selectedYear}

                        onFinishScreen={() => {
                          const params = {
                            Month: this.state.selectedMonth,
                            Year: this.state.selectedYear
                          }
                          this.getDataVariousAggregate(params)
                          this.closeModal()
                        }}
                      />),
                  },
                })
              }}
            >個別設定</Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1494001_NonConsultDateSetting);
