import React from "react";
import PropTypes from 'prop-types';

import { Card, Form, Input, Button, Modal, Upload } from "antd";
import { basename } from "helpers/CommonHelpers";

class WS0432001_CsvConfirm extends React.Component {
  static propTypes = {
    Csv: PropTypes.any,
    Lio_Output: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'CSV確認';

    this.state = {
    };
  }

  componentDidMount() {
    this.setFileName(this.props.Csv, this.props.Lio_Output);
  }

  componentDidUpdate(PreV) {
    if (PreV !== this.props) {
      this.setFileName(this.props.Csv, this.props.Lio_Output);
    }
  }

  setFileName = (path, name) => {
    let fileName = typeof name === 'string' ? name : ''
    this.formRef.current?.setFieldsValue({
      File: basename(fileName),
      Path: path
    });
  }

  onFinish = (values) => {
    Modal.confirm({
      title: '出力を実行しますか',
      onOk: () => {
        this.props.onFinishScreen({
          Lio_OutputPath: values.Path,
          Lio_Output: values.File,
          Lo_StsOutput: true,
        });
      },
    });
  }

  render() {
    return (
      <div className="csv-confirm">
        <Card title="CSV出力" className="mb-2">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Upload id="idUpload"
              beforeUpload={(file) => {
                return new Promise(resolve => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    console.log(file)
                    this.formRef.current?.setFieldsValue({
                      Path: file.name
                    })
                  };
                });
              }}>&emsp;
            </Upload>

            <Form.Item
              name="File"
              label="ファイル名"
              rules={[{ required: true }]}
            >
              <Input suffix=".csv" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{ float: "right", marginTop: 20 }}
                htmlType="submit"
              >
                出力
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default WS0432001_CsvConfirm;
