import AutoJudgeService from 'services/InputBusiness/ExamineeList/AutoJudgeService'

const AutoJudgeAction = {
  index(data) {
    return AutoJudgeService.index(data)
  },

  eventYes(data) {
    return AutoJudgeService.eventYes(data)
  }
}

export default AutoJudgeAction
