import { message } from 'antd'
import BankHolidaySettingService from 'services/SM_SystemMaintenance/BankHolidaySetting/BankHolidaySettingService'

const BankHolidaySettingAction = {
  getOneMonthList(data) {
    return BankHolidaySettingService.getOneMonthList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  save(data) {
    return BankHolidaySettingService.save(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  capturing(data) {
    return BankHolidaySettingService.capturing(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default BankHolidaySettingAction
