import axios from 'configs/axios'

const apiPaths = {
  index: '/api/create-contract-history-together',
  getData: '/api/create-contract-history-together/get-data',
  createData: '/api/create-contract-history-together/create-data',
}

const CreatingContractHistoryTogetherService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },

  async getData(params) {
    return axios.get(apiPaths.getData, { params })
  },

  async createData(params) {
    return axios.post(apiPaths.createData, params)
  },
}

export default CreatingContractHistoryTogetherService
