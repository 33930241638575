import React, { useState, useEffect, createRef } from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, Typography, Popconfirm, Radio, InputNumber } from 'antd'
import { SaveOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'

/**
 * 共通コンポーネント
 * 編集、削除用モーダル
 * @param {*} props
 * columns・・・
 * @returns
 */
const ModalChange = (props) => {
  const formRef = createRef()
  // Modal情報
  const [childModal, setChildModal] = useState({
    visible: false,
    component: null,
    width: 0,
  })
  const [keyChange, setKeyChange] = useState([])
  const [itemChange, setItemChange] = useState([])

  /**
   * Modalを開いた時の処理
   */
  useEffect(() => {
    formRef.current?.setFieldsValue(props.record)
    setItemChange(props.record)
  }, [props.record])

  /**
    * keyChangeが変更されたとき
    */
  useEffect(() => {
    formRef.current?.setFieldsValue(keyChange)
  }, [keyChange])

  /**
   * 子モーダルでonFinishScreenイベント発生時の処理
   * @param {子モーダルの返却値} output
   */
  const onFinish = (output, dataIndex) => {
    // outputのkey名を取得
    let keys = Object.keys(output)
    for (let i = 0; i < keys.length; i++) {

      if (keys[i] === dataIndex) {
        // 変更するカラム情報を取る
        let keyName = keys[i]
        setItemChange({
          ...itemChange,
          [keyName]: output[keyName]
        })
        setKeyChange(
          { [keyName]: output[keyName] }
        )
      } else {
        let keyName = keys[i]
        setItemChange({
          ...itemChange,
          [keyName]: output[keyName]
        })
      }
    }
    // 閉じる
    closeModal()
  }

  /**
   * 入力　変更処理
   * @param {*} e
   */
  const handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]
    setItemChange({
      ...itemChange,
      [columnName]: columnValue
    })
  }

  /**
   * モーダルを閉じる処理
   */
  const closeModal = () => {
    setChildModal({
      ...childModal,
      visible: false,
    })
  }

  const required = [{ required: true, message: '必須項目です' }]

  return (
    <div className='modal-change' >
      <Card title={(props.newFlag ?? false) === true ? '新規' : '変更'}>
        <Form
          ref={formRef}
          onValuesChange={handleFormChange}
          autoComplete='off'
        >
          {props.columns.map((item, index) => (
            <div key={item + index}>
              {item.type !== 9 ?
                <Typography style={{ marginBottom: '5px' }}>
                  {item.title}
                </Typography> : null
              }
              {(item.type === 0) ?
                <Form.Item name={item.dataIndex}>
                  <Input readOnly
                    bordered={false}>
                  </Input>
                </Form.Item>
                :
                (item.type === 1) ?
                  // <Input>
                  <Form.Item name={item.dataIndex} rules={item.required ? required : ''}>
                    <Input
                      style={{ marginBottom: '5px' }}
                      className={'input-size-' + item.classNumber}
                      onDoubleClick={() => {
                        if (item.childModal !== undefined) {
                          setChildModal({
                            visible: true,
                            width: item.childModal.width,
                            // 可変のコンポーネント設定の為にReact.createElementを使用する
                            component: React.createElement(item.childModal.component, { onFinishScreen: (output) => onFinish(output, item.dataIndex) }, null)
                          })
                        }
                      }}
                    />
                  </Form.Item>
                  :
                  (item.type === 2) ?
                    // <Select>
                    <Form.Item
                      name={item.dataIndex}
                      className={'input-size-' + item.classNumber}
                    >
                      <Select
                        style={{ marginBottom: '5px' }}
                      >
                        {item.selectList === undefined ? [] : item.selectList.map((item, index) => (
                          <Select.Option
                            key={item.label + index}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    :
                    (item.type === 3) ?
                      // <Radio>
                      <Form.Item name={item.dataIndex}>
                        <Radio.Group
                          style={{ marginBottom: '5px', display: 'flex' }}
                        >
                          {item.radioList === undefined ? [] : item.radioList.map((item, index) => (
                            <Radio
                              key={item.label + index}
                              value={item.value}
                            >
                              {item.label}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                      :
                      (item.type === 4) ?
                        //Search
                        <Form.Item name={item.dataIndex}>
                          <Input.Search
                            allowClear={true}
                            className={'input-size-' + item.classNumber}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                setChildModal({
                                  visible: false
                                })
                                return
                              }

                              if (item.childModal !== undefined) {
                                setChildModal({
                                  visible: true,
                                  width: item.childModal.width,
                                  // 可変のコンポーネント設定の為にReact.createElementを使用する
                                  component: React.createElement(item.childModal.component, { onFinishScreen: (output) => onFinish(output, item.dataIndex) }, null)
                                })
                              }
                            }}

                          />
                        </Form.Item>
                        :
                        (item.type === 5) ?
                          //InputNumber
                          <Form.Item name={item.dataIndex} rules={item.required ? required : ''}>
                            <InputNumber
                              style={{ marginBottom: '5px' }}
                              min={item.min}
                              max={item.max}
                              defaultValue={item.defaultValue}
                              onDoubleClick={() => {
                                if (item.childModal !== undefined) {
                                  setChildModal({
                                    visible: true,
                                    width: item.childModal.width,
                                    // 可変のコンポーネント設定の為にReact.createElementを使用する
                                    component: React.createElement(item.childModal.component, { onFinishScreen: (output) => onFinish(output, item.dataIndex) }, null)
                                  })
                                }
                              }}
                            />
                          </Form.Item>
                          :
                          null
              }
            </div>
          ))}
        </Form>

        {/* Footer */}
        <div className='box_button_bottom_right'>
          {/* {props.deleteFlag !== false ? */}
          < Popconfirm
            title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
            overlayStyle={{ width: '250px' }}
            overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
            icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
            okText='削除'
            okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
            onConfirm={() =>
              // 削除
              props.onDelete({
                itemChange: itemChange,
              })
            }
            cancelText='キャンセル'
            cancelButtonProps={{ style: { height: '32px' } }}
          >
            <Button
              danger
              type='primary'
              style={{
                display: (props.deleteFlag ?? true) ? '' : 'none' // deleteFlag:falseの場合、非表示
              }}
              icon={<DeleteOutlined />}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>
          </Popconfirm>

          {/* 保存ボタン */}
          <Button
            type='primary'
            icon={<SaveOutlined />}
            onClick={() => {
              props.onUpdate({
                itemChange: {
                  ...itemChange,
                  ...keyChange
                }
              })
            }}
          >
            <span className='btn_label'>
              保存
            </span>
          </Button>
        </div>
      </Card >

      <ModalDraggable
        width={childModal.width}
        visible={childModal.visible}
        component={childModal.component}
        onCancel={() => {
          closeModal()
        }}
      />
    </div >
  )
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ModalChange)
