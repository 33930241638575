import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";
import { connect } from "react-redux";
import { Card, Form, Button, Table } from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { getReserveCourseItem, getReserveInspectItem, getReserveInspectGroupItem, checkDataExistenceAction } from "redux/SM_SystemMaintenance/ReservesDisplayItemSetting/ReservesDisplayItemSetting.action";
import "./WS2702007_ReservesDisplayItemSetting.scss";
import WS2702017_ReservesDisplayItemSettingSub_Course from "./WS2702017_ReservesDisplayItemSettingSub_Course.jsx"
import WS2702027_ReservesDisplayItemSettingSub_Inspect from "./WS2702027_ReservesDisplayItemSettingSub_Inspect.jsx"
import WS2702037_InspectionFrameSetting from './WS2702037_InspectionFrameSetting.jsx'
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

class WS2702007_ReservesDisplayItemSetting extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "予約表示項目設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource1: [],
      dataSource2: [],
      selectedNodes: [],
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
    };
  }

  componentDidMount = () => {
    this.checkDataExistence();
    this.loadCourseData();
    this.loadInspectData();
    this.loadInspectGroupData();
  }

  checkDataExistence() {
    checkDataExistenceAction();
  }

  loadCourseData = () => {
    this.setState({ isLoadingPage: true });
    getReserveCourseItem()
      .then(res => {
        if (res) {
          this.setState({ dataSource1: res.data })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  loadInspectData = () => {
    this.setState({ isLoadingPage: true });
    getReserveInspectItem()
      .then(res => {
        if (res) {
          this.setState({ dataSource2: res.data })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  loadInspectGroupData = () => {
    this.setState({ isLoadingPage: true });
    getReserveInspectGroupItem()
      .then(res => {
        if (res) {
          this.setState({ dataSource3: res.data })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  editFormCourse(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1100,
        component: (
          <WS2702017_ReservesDisplayItemSettingSub_Course
            record={record}
            course={this.state.dataSource1}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editFormInspect(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1100,
        component: (
          <WS2702027_ReservesDisplayItemSettingSub_Inspect
            record={record}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editInspectionFrameSetting(record) {
    let flag = true;
    if (record) {
      flag = false;
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 350,
        component: (
          <WS2702037_InspectionFrameSetting
            record={record}
            Li_status={flag}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  reviewScreen() {
    this.loadCourseData();
    this.loadInspectData();
    this.loadInspectGroupData();
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="reserves-displayitem-setting">
        <Card title="予約表示項目設定" className="mb-2">
          <Form ref={this.formRef} >
            <div className="box_inner_horizontal" style={{ gap: 150, width: '100%', height: '85vh' }}>
              <div className="box_inner_vertical_label_top">
                <div className="table_header_filter">
                  <div>コース</div>
                </div>
                <Table
                  bordered
                  dataSource={this.state.dataSource1}
                  loading={false}
                  size='small'
                  pagination={false}
                  showHeader={false}
                  rowKey={(record) => record.nameId}
                  scroll={{ x: 'max-content', y: resizableTableScroll(180) }}
                  onRow={(record, rowIndex) => ({
                    onDoubleClick: () =>
                      this.editFormCourse(record)
                  })}
                >
                  <Table.Column width={20} title="No." dataIndex="nameId" key="" />
                  <Table.Column title="名称" dataIndex="displayName" key="" />
                  <Table.Column width={20} align="center"
                    render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => { this.editFormCourse(record) }}
                      />
                    )}
                  />

                </Table>
              </div>

              <div className="box_inner_vertical_label_top">
                <div className="table_header_filter" >
                  <div>検査</div>
                </div>
                <Table
                  bordered
                  dataSource={this.state.dataSource2}
                  loading={false}
                  size='small'
                  pagination={false}
                  showHeader={false}
                  rowKey={(record) => record.nameId}
                  scroll={{ x: 'max-content', y: resizableTableScroll(180) }}
                  onRow={(record, rowIndex) => ({
                    onDoubleClick: () =>
                      this.editFormInspect(record)
                  })}
                >
                  <Table.Column width={20} title="No." dataIndex="nameId" key="" />
                  <Table.Column title="名称" dataIndex="displayName" key="" />
                  <Table.Column width={20} align="center"
                    render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => { this.editFormInspect(record) }}
                      />
                    )}
                  />
                </Table>
              </div>

              {/* <div className="box_inner_vertical_label_top">
                <div className="table_header_filter">
                  <div>検査グループ</div>
                </div>
                <Table
                  bordered
                  dataSource={this.state.dataSource3}
                  loading={false}
                  size='small'
                  pagination={false}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: resizableTableScroll(180) }}
                  onRow={(record) => ({
                    onDoubleClick: () =>
                      this.editInspectionFrameSetting(record)
                  })}
                >
                  <Table.Column title="No" dataIndex="groupNo" key="" />
                  <Table.Column title="グループ" dataIndex="groupName" key="" />
                  <Table.Column title="No" dataIndex="childNo" key="" />
                  <Table.Column title="名称" dataIndex="displayName" key="" />
                  <Table.Column width={40} align="center"
                    title={
                      //新規登録のボタン
                      <Button
                        size='small'
                        type='primary'
                        style={{ textAlign: 'center' }}
                        icon={<PlusOutlined />}
                        onClick={() => { this.editInspectionFrameSetting() }}
                      ></Button>
                    }
                    render={(record) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => { this.editInspectionFrameSetting(record) }}
                      />
                    )}
                  />
                </Table>
              </div> */}
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }


}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2702007_ReservesDisplayItemSetting);
