import BusinessEstablishmentsSubjectToSelectService from 'services/StatisticalServices/OfficeVisitsAchievementSituation/BusinessEstablishmentsSubjectToSelectService';
import { message, } from "antd"

const BusinessEstablishmentsSubjectToSelectAction = {
  /**
   * 印刷/プレビュー
   */
  Print(params) {
    return BusinessEstablishmentsSubjectToSelectService.Print(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  /**
   * csv出力 
   */
  ExportCsv(params) {
    return BusinessEstablishmentsSubjectToSelectService.ExportCsv(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default BusinessEstablishmentsSubjectToSelectAction;