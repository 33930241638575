import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";

import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Button, Select, Table, Modal, InputNumber, message } from "antd";
import { MoreOutlined, QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";

import WS0975001_BillingInquiry from "../V4BL0030000_BillingInquiry/WS0975001_BillingInquiry";
import WS0963004_TamperProofRelease from "./WS0963004_TamperProofRelease";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx";
import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine";
import WS0967003_BillingNameQuerySub from "./WS0967003_BillingNameQuerySub";
import WS0961001_BillingIntegration from "../V4BL0008000_BillingIntegration/WS0961001_BillingIntegration";
import WS0964001_Itemized from "./WS0964001_Itemized";
import WS2585001_OfficeInfoInquirySub from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";
import WS0339001_InsurerInfoMaintain from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain";
import WS0963002_RemarkChangeModal from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0963002_RemarkChangeModal.jsx";

import InvoiceMaintainAction from "redux/AccountingBusiness/DepositWithdrawalInquiry/InvoiceMaintain.action";
import moment from "moment-timezone";
import { number_format } from "helpers/CommonHelpers";
import WS0947001_Invoice from "../V4BL0008000_BillingIntegration/WS0947001_Invoice";

const styleHr = {
  color: '#cfcfcf',
  margin: '1em 0',
  opacity: '0.3'
}

const smGridLeft = {
  labelCol: { style: { width: 50 } },
}

const smGridRight = {
  labelCol: { style: { width: 60 } },
}

class WS0963001_InvoiceMaintain extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_BillingManageNum: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = "請求書保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingForm: false,

      isLoadingTable: false,
      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      indexTable: 0,

      ClassifyState: 5,
      TaxClassifyState: null,
      TaxCalculateUnit: null,
      ListStyleNum: [],
      dataScreen: {
        Address1: '',
        Address2: '',
        AmountBilled: 0,
        BranchStoreCode: 0,
        BillingManageNumBefore: 0,
        BillingManageNumYearsBeforeChange: 0,
        Classify: null,
        ClaimNum: 0,
        ClaimDateIssue: null,
        CompletedEffective: false,
        DeleteFlag: 0,
        DateTmp: '',
        Destination: '',
        DepositAmount: 0,
        DestinationZipCode: '',
        DisplayProcessDuringExec: false,
        KeyEditingPermission: true,
        OfficeCode: '',
        PeopleNum: 1,
        PersonalNumAddressSearch: '',
        ProtectionFlag: 0,
        ProcessDivision: 0,
        PersonalCode: '',
        Remarks: '',
        Subject: '',
        ScreenEditing: false,
        Tax: 0,
        TaxRate: null,
        TaxRate_1: 0,
        TotalAmount: 0,
        InsurerCode: 0,
        IntegrationFlag: 0,
        IdentifyBefore: 0,
        IdentifyDateChangeBefore: 0,
        OptionSk0002: '',
      },

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      isClose974: false,

      ProtectionLiftFlag: false

    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      BillingManageNum: this.props.Li_BillingManageNum ?? '',
      Classify: 5,
    })
    this.getScreenData()
  }

  /**
   * 初期表示用データ取得
   * @param {*} value
   */
  getScreenData(value) {
    let params = {
      Li_BillingManageNum: this.formRef.current?.getFieldValue('BillingManageNum'),
      Classify: this.props.Li_identification ?? 5,
      DisplayProcessDuringExec: this.state.dataScreen.DisplayProcessDuringExec,
      PersonalNumAddressSearch: this.state.dataScreen.PersonalNumAddressSearch,
      ProtectionFlag: this.state.dataScreen.ProtectionFlag,
      IntegrationFlag: this.state.dataScreen.IntegrationFlag,
      DeleteFlag: this.state.dataScreen.DeleteFlag,
      ProcessDivision: this.state.dataScreen.ProcessDivision,
      DateTmp: this.state.dataScreen.DateTmp,
      OfficeCode: this.state.dataScreen.OfficeCode,
      BranchStoreCode: this.state.dataScreen.BranchStoreCode,
      PersonalCode: this.state.dataScreen.PersonalCode,
      InsurerCode: this.state.dataScreen.InsurerCode,
      DeleteButtonEnable: true,
    }

    this.setState({ isLoadingForm: true })
    InvoiceMaintainAction.getScreenData(params)
      .then(async (res) => {
        let data = {
          ...this.state.dataScreen,
          ...res?.data
        }

        await this.setState({
          dataScreen: data,
          ListStyleNum: res.data.ListStyleNum,
          ClassifyState: res?.data?.Classify ?? this.state.ClassifyState,
        })

        this.formRef.current?.setFieldsValue(data)
        this.getInitData()
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 日付情報の取得
   */
  getInitData() {
    let params = {
      ClaimDateIssue: this.state.dataScreen.ClaimDateIssue,
      Classify: this.state.dataScreen.Classify,
      OptionSk0002: this.state.dataScreen.OptionSk0002,
      SelectListForm: this.state.dataScreen.SelectListForm,
      TaxRate_1: this.state.dataScreen.TaxRate_1,
      BillingPeriodBeginning: this.state.dataScreen.BillingPeriodBeginning,
      BillingPeriodFinal: this.state.dataScreen.BillingPeriodFinal
    }

    InvoiceMaintainAction.getInitData(params)
      .then(async (res) => {
        let data = {
          ...this.state.dataScreen,
          ...res?.data
        }
        await this.setState({
          dataScreen: data,
          isLoadingForm: false,
        })

        this.formRef.current?.setFieldsValue(data)
        this.getDisplayData()
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 上部入力フォーム内情報の取得
   */
  getDisplayData() {
    let params = {
      DisplayProcessDuringExec: this.state.dataScreen.DisplayProcessDuringExec ? 1 : 0,
      BillingManageNum: this.state.dataScreen.BillingManageNum,
      Classify: this.state.dataScreen.Classify,
      integrated_destination_management_number: this.state.dataScreen.billingdata_2_Dt0601?.integrated_destination_management_number,
      billing_management_number: this.state.dataScreen.BillingManageNum,
      SpecifyValid: this.state.dataScreen.SpecifyValid ? 1 : 0,
      ExistsDetailedData: this.state.dataScreen.ExistsDetailedData ? 1 : 0,
      SpecifyRemarksNumBytes: this.state.dataScreen.SpecifyRemarksNumBytes,
      ExistsBillingControlNum: this.state.dataScreen.ExistsBillingControlNum ? 1 : 0,
      OptionSk0003: this.state.dataScreen.OptionSk0003,
      KanRemarksNumBytes: this.state.dataScreen.KanRemarksNumBytes,
      ProtectionFlag: this.state.dataScreen.ProtectionFlag
    }

    InvoiceMaintainAction.getDisplayData(params)
      .then(async (res) => {
        let data = res?.data

        let code = data.Classify === 4 ? data.InsurerCode
          : (data.Classify === 5 || data.Classify === 6) ? data.OfficeCode
            : data.Classify === 9 ? data.PersonalCode : ''

        let dataSc = {
          ...this.state.dataScreen,
          ...res?.data,
          Code: code,
        }

        let dataResult = {
          ...data,
          Expression_26: this.state.dataScreen.Expression_26,
          Code: code,
        }

        this.formRef.current?.setFieldsValue(dataResult)

        await this.setState({
          isLoadingForm: false,
          TaxCalculateUnit: res?.data?.TaxCalculateUnit,
          TaxClassifyState: res?.data?.TaxClassify,
          dataScreen: dataSc
        })

        if (this.state.dataScreen.ProtectionFlag === 1) {
          this.setState({ ProtectionLiftFlag: true })
        }

        // 編集時のみ、動作
        if (this.props.Li_BillingManageNum) {
          this.getDataTable(true)
        }
      })
      .catch((err) => {
        this.reSetFormField()
        this.setState({ isLoadingForm: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 下部表内情報の取得
   * @param {*} reload
   */
  getDataTable(reload) {
    let params = {
      identification: this.props.Li_identification,
      billingManagementNumber: this.props.Li_BillingManageNum,
      Li_Modify: this.state.dataScreen.StsKanModify ? 1 : 0,
      ProtectionFlag: this.state.dataScreen.ProtectionFlag,
      IntegrationFlag: this.state.dataScreen.IntegrationFlag,
      CompletedEffective: this.state.dataScreen.CompletedEffective ? 1 : 0,
      KanRemarksNumBytes: this.state.dataScreen.KanRemarksNumBytes,
    }
    this.setState({ isLoadingTable: true })

    InvoiceMaintainAction.getSubContent(params)
      .then(async (res) => {
        let index = reload ? 0 : this.state.indexTable
        await this.setState({
          dataSource: res && res.data.length > 0 ? res.data : [],
          isLoadingTable: false,

          selectedRow: res && res.data.length > 0 ? res.data[index] : {},
          selectedRowKeys: res && res.data.length > 0 ? [res.data[index]] : [],
          indexTable: index,
        })
      })
      .catch((err) => {
        this.setState({ isLoadingTable: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  reSetFormField() {
    let date = new Date()
    this.formRef.current?.resetFields()
    this.formRef.current?.setFieldsValue({
      BillingManageNum: this.props.Li_BillingManageNum || '',
      Classify: 5,
      ClaimNum: 0,
      Expression_26: '解 除',
      TaxCalculateUnit: 0,
      LessThanTaxCircle: 0,
      BillingDateChar: moment(date.toISOString()),
      BillingPeriodBeginningChar: moment(new Date(date.getFullYear(), date.getMonth(), 1)),
      BillingPeriodFinalChar: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    })

    let data = {
      KeyEditingPermission: false,
      ProcessDivision: 0,
      Expression_26: '解 除',
    }

    this.setState({
      dataSource: [],
      isLoadingTable: false,

      selectedRow: {},
      selectedRowKeys: [],
      indexTable: 0,
      dataScreen: data
    })
  }

  /**
   *
   */
  eventGzoomDestination() {
    let params = {
      ProtectionFlag: this.state.dataScreen.ProtectionFlag,
      IntegrationFlag: this.state.dataScreen.IntegrationFlag,
      Classify: this.state.ClassifyState,
      Destination: this.formRef.current?.getFieldValue('Destination'),
      InsurerCode: this.formRef.current?.getFieldValue('InsurerCode'),
      PersonalCode: this.formRef.current?.getFieldValue('PersonalCode'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      PersonalNumAddressSearch: this.formRef.current?.getFieldValue('PersonalCode'),
      InsuranceNumAddressSearch: this.formRef.current?.getFieldValue('InsurerCode'),
      OfficeCodeEnvelopesSearch: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCodeEnvelopesSearch: this.formRef.current?.getFieldValue('BranchStoreCode'),
      DestinationZipCode: this.formRef.current?.getFieldValue('DestinationZipCode'),
      Address1: this.formRef.current?.getFieldValue('Address1'),
      Address2: this.formRef.current?.getFieldValue('Address2'),
    }
    InvoiceMaintainAction.eventGzoomDestination(params)
      .then(async (res) => {
        let data = {
          ...this.state.dataScreen,
          ...res?.data
        }
        this.setState({
          dataScreen: data
        })
        this.formRef.current?.setFieldsValue({
          Address1: res?.data?.Address1,
          Address2: res?.data?.Address2,
          Destination: res?.data?.Destination,
          DestinationZipCode: res?.data?.DestinationZipCode
        })
        this.changeScreenEditting(true)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 明細内訳処理
   * @param {*} status
   */
  eventF7(status) {
    let params = {
      flag_WS0964001: status, // 0 open 964, 1 next
      SpecifyValid: this.state.dataScreen.SpecifyValid,
      ProcessDivision: this.state.dataScreen.ProcessDivision,
      ProtectionFlag: this.state.dataScreen.ProtectionFlag,
      DeleteFlag: this.state.dataScreen.DeleteFlag,
      IntegrationFlag: this.state.dataScreen.IntegrationFlag,
      OptionSk0002: this.state.dataScreen.OptionSk0002,
      PeopleNum: this.state.dataScreen.PeopleNum,
      Classify: this.state.ClassifyState,
      Destination: this.formRef.current?.getFieldValue('Destination'),
      BillingManageNum: this.formRef.current?.getFieldValue('BillingManageNum'),
      Subject: this.formRef.current?.getFieldValue('Subject'),
      BillingPeriodBeginning: this.formRef.current?.getFieldValue('BillingPeriodBeginning'),
      BillingPeriodFinal: this.formRef.current?.getFieldValue('BillingPeriodFinal'),
      BillingPeriodBeginningChar: moment(this.formRef.current?.getFieldValue('BillingPeriodBeginningChar'))?.format('YYYY/MM/DD'),
      BillingPeriodFinalChar: moment(this.formRef.current?.getFieldValue('BillingPeriodFinalChar'))?.format('YYYY/MM/DD'),
      AmountBilled: this.formRef.current?.getFieldValue('AmountBilled'),
      TaxRate_1: this.formRef.current?.getFieldValue('TaxRate') / 100,
      LessThanTaxCircle: this.formRef.current?.getFieldValue('LessThanTaxCircle'),
      TaxCalculateUnit: this.formRef.current?.getFieldValue('TaxCalculateUnit'),
      TaxClassify: this.formRef.current?.getFieldValue('TaxClassify'),
      TotalAmount: this.formRef.current?.getFieldValue('TotalAmount'),
      Tax: this.formRef.current?.getFieldValue('Tax'),
      Remarks: this.formRef.current?.getFieldValue('Remarks'),
    }

    InvoiceMaintainAction.eventF7(params)
      .then(async (res) => {
        if (status === 0) {
          this.showModalItemzed_964(res?.data?.variables)
        } else {
          let data = {
            ...this.state.dataScreen,
            TaxCalculateUnit: res?.data?.TaxCalculateUnit,
            LessThanTaxCircle: res?.data?.LessThanTaxCircle,
            TaxClassify: res?.data?.TaxClassify,
            TaxRate_1: res?.data?.TaxRate_1,
            TotalAmount: res?.data?.TotalAmount,
            Tax: res?.data?.Tax,
            AmountBilled: res?.data?.AmountBilled,
            PeopleNum: res?.data?.PeopleNum,
          }

          this.setState({
            dataScreen: data
          })

          this.formRef.current?.setFieldsValue({
            TaxCalculateUnit: data.TaxCalculateUnit,
            LessThanTaxCircle: data.LessThanTaxCircle,
            TotalAmount: data.TotalAmount,
            Tax: data.Tax,
            AmountBilled: data.AmountBilled
          })
          this.getDataTable(true)
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 削除・復元処理判定
   * @param {*} DeleteFlag
   * @param {*} ClaimNum
   * @param {*} CompletedEffective
   */
  branchF11 = (DeleteFlag, ClaimNum, CompletedEffective) => {
    if (((DeleteFlag == 0 && ClaimNum != 0) || DeleteFlag == 2) && CompletedEffective) {
      this.eventF11_LossTreatment()
    } else {
      this.eventF11_DeleteProcess()
    }
  }

  eventF11_LossTreatment() {
    const { DeleteFlag } = this.state.dataScreen
    let title = ''
    if (DeleteFlag == 0) {
      title = '欠損処理を行いますか？'
    }

    if (DeleteFlag == 2) {
      title = '欠損処理を解除しますか？'
    }

    Modal.confirm({
      width: 300,
      title: title,
      icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
      onOk: () => {
        this.eventF11()
      }
    })
  }

  eventF11_DeleteProcess() {
    const { DeleteFlag } = this.state.dataScreen
    let title = ''
    if (DeleteFlag == 0) {
      title = 'データを削除しますか？'
    }

    if (DeleteFlag == 1) {
      title = 'データを復活しますか？'
    }

    Modal.confirm({
      width: 300,
      title: title,
      icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
      onOk: () => {
        this.eventF11()
      }
    })
  }

  eventF11() {
    let params = {
      IntegrationFlag: this.state.dataScreen.IntegrationFlag,
      ProtectionFlag: this.state.dataScreen.ProtectionFlag,
      DeleteFlag: this.state.dataScreen.DeleteFlag,
      ProcessDivision: this.state.dataScreen.ProcessDivision,
      MsgSts: 6,
      CompletedEffective: this.state.dataScreen.CompletedEffective ? 1 : 0,
      BillingDate: this.state.dataScreen.BillingDate,
      ClaimDateIssue: this.state.dataScreen.ClaimDateIssue,
      OptionSk0003: this.state.dataScreen.OptionSk0003,
      PeopleNum: this.state.dataScreen.PeopleNum,
      SelectListForm: this.state.dataScreen.SelectListForm_1,
      BillingManageNumYearsBeforeChange: this.state.dataScreen.BillingManageNumYearsBeforeChange,
      deposit_price: this.state.dataScreen.billingdata_2_Dt0601?.deposit_price,
      Classify: this.state.ClassifyState,
      ClaimNum: this.formRef.current?.getFieldValue('ClaimNum'),
      Destination: this.formRef.current?.getFieldValue('Destination'),
      BillingManageNum: this.formRef.current?.getFieldValue('BillingManageNum'),
      Subject: this.formRef.current?.getFieldValue('Subject'),
      BillingPeriodBeginning: this.formRef.current?.getFieldValue('BillingPeriodBeginning'),
      BillingPeriodFinal: this.formRef.current?.getFieldValue('BillingPeriodFinal'),
      DestinationZipCode: this.formRef.current?.getFieldValue('DestinationZipCode'),
      Address1: this.formRef.current?.getFieldValue('Address1'),
      Address2: this.formRef.current?.getFieldValue('Address2'),
      AmountBilled: this.formRef.current?.getFieldValue('AmountBilled'),
      LessThanTaxCircle: this.formRef.current?.getFieldValue('LessThanTaxCircle'),
      TaxCalculateUnit: this.formRef.current?.getFieldValue('TaxCalculateUnit'),
      TaxClassify: this.formRef.current?.getFieldValue('TaxClassify'),
      TotalAmount: this.formRef.current?.getFieldValue('TotalAmount'),
      DepositAmount: this.formRef.current?.getFieldValue('DepositAmount'),
      Tax: this.formRef.current?.getFieldValue('Tax'),
      Remarks: this.formRef.current?.getFieldValue('Remarks'),
      StyleNum: this.formRef.current?.getFieldValue('StyleNum'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      PersonalCode: this.formRef.current?.getFieldValue('PersonalCode'),
      InsurerCode: this.formRef.current?.getFieldValue('InsurerCode'),
    }
    InvoiceMaintainAction.eventF11(params)
      .then(async (res) => {
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 完了ボタン処理
   */
  eventF12() {
    let params = {
      Address1: this.formRef.current?.getFieldValue('Address1'),
      Address2: this.formRef.current?.getFieldValue('Address2'),
      AmountBilled: this.formRef.current?.getFieldValue('AmountBilled'),
      BillingDate: this.formRef.current?.getFieldValue('BillingDateChar') ? moment(this.formRef.current?.getFieldValue('BillingDateChar')).format('YYYY/MM/DD') : '0000/00/00',
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      BillingManageNum: this.formRef.current?.getFieldValue('BillingManageNum'),
      BillingPeriodFinal: this.formRef.current?.getFieldValue('BillingPeriodFinalChar') ? moment(this.formRef.current?.getFieldValue('BillingPeriodFinalChar')).format('YYYY/MM/DD') : '0000/00/00',
      BillingPeriodBeginning: this.formRef.current?.getFieldValue('BillingPeriodBeginningChar') ? moment(this.formRef.current?.getFieldValue('BillingPeriodBeginningChar')).format('YYYY/MM/DD') : '0000/00/00',
      BillingManageNumYearsBeforeChange: this.state.dataScreen.BillingManageNumYearsBeforeChange,
      ClaimNum: this.formRef.current?.getFieldValue('ClaimNum'),
      Classify: this.state.ClassifyState,
      ClaimDateIssue: this.state.dataScreen.ClaimDateIssue,
      CompletedEffective: this.state.dataScreen.CompletedEffective ? 1 : 0,
      DeleteFlag: this.state.dataScreen.DeleteFlag,
      Destination: this.formRef.current?.getFieldValue('Destination'),
      DepositAmount: this.formRef.current?.getFieldValue('DepositAmount'),
      deposit_price: this.state.dataScreen.billingdata_2_Dt0601?.deposit_price,
      DestinationZipCode: this.formRef.current?.getFieldValue('DestinationZipCode'),
      InsurerCode: this.formRef.current?.getFieldValue('InsurerCode'),
      IntegrationFlag: this.state.dataScreen.IntegrationFlag,
      LessThanTaxCircle: this.formRef.current?.getFieldValue('LessThanTaxCircle'),
      MsgSts: 6,
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      OptionSk0003: this.state.dataScreen.OptionSk0003,
      PeopleNum: this.state.dataScreen.PeopleNum,
      PersonalCode: this.formRef.current?.getFieldValue('PersonalCode'),
      ProtectionFlag: this.state.dataScreen.ProtectionFlag,
      ProcessDivision: this.state.dataScreen.ProcessDivision,
      Remarks: this.formRef.current?.getFieldValue('Remarks'),
      Subject: this.formRef.current?.getFieldValue('Subject'),
      StyleNum: this.formRef.current?.getFieldValue('StyleNum'),
      SelectListForm: this.state.dataScreen.SelectListForm,
      Tax: this.formRef.current?.getFieldValue('Tax'),
      TaxClassify: this.formRef.current?.getFieldValue('TaxClassify'),
      TotalAmount: this.formRef.current?.getFieldValue('TotalAmount'),
      TaxCalculateUnit: this.formRef.current?.getFieldValue('TaxCalculateUnit'),
    }

    InvoiceMaintainAction.eventF12(params)
      .then(async (res) => {
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 税計算のselectを変更した時の処理
   */
  eventChangeTaxCalculateUnit() {
    let params = {
      SpecifyValid: this.state.dataScreen.SpecifyValid,
      Classify: this.state.ClassifyState,
      AmountBilled: this.formRef.current?.getFieldValue('AmountBilled'),
      TaxRate_1: this.formRef.current?.getFieldValue('TaxRate') / 100,
      LessThanTaxCircle: this.formRef.current?.getFieldValue('LessThanTaxCircle'),
      TaxCalculateUnit: this.formRef.current?.getFieldValue('TaxCalculateUnit'),
      TaxClassify: this.formRef.current?.getFieldValue('TaxClassify'),
      TotalAmount: this.formRef.current?.getFieldValue('TotalAmount'),
      Tax: this.formRef.current?.getFieldValue('Tax'),
    }

    InvoiceMaintainAction.eventChangeTaxCalculateUnit(params)
      .then((res) => {
        if (params.TaxCalculateUnit === 1) {
          this.eventChangeTaxClassify()
        } else {
          this.formRef.current?.setFieldsValue({
            TotalAmount: res?.data?.TotalAmount,
            Tax: res?.data?.Tax,
            AmountBilled: res?.data?.AmountBilled,
          })

          let data = {
            ...this.state.dataScreen,
            ...res?.data
          }

          this.setState({
            dataScreen: data
          })
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  eventChangeTaxClassify() {
    let params = {
      SpecifyValid: this.state.dataScreen.SpecifyValid,
      Classify: this.state.ClassifyState,
      AmountBilled: this.formRef.current?.getFieldValue('AmountBilled'),
      TaxRate_1: this.formRef.current?.getFieldValue('TaxRate') / 100,
      LessThanTaxCircle: this.formRef.current?.getFieldValue('LessThanTaxCircle'),
      TaxCalculateUnit: this.formRef.current?.getFieldValue('TaxCalculateUnit'),
      TaxClassify: this.formRef.current?.getFieldValue('TaxClassify'),
      TotalAmount: this.formRef.current?.getFieldValue('TotalAmount'),
      Tax: this.formRef.current?.getFieldValue('Tax'),
    }
    InvoiceMaintainAction.eventChangeTaxClassify(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          TotalAmount: res?.data?.TotalAmount,
          Tax: res?.data?.Tax,
          AmountBilled: res?.data?.AmountBilled,
        })

        let data = {
          ...this.state.dataScreen,
          ...res?.data
        }

        this.setState({
          dataScreen: data
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  eventChangeLessThanTaxCircle() {
    let params = {
      SpecifyValid: this.state.dataScreen.SpecifyValid,
      Classify: this.state.ClassifyState,
      MsgSts: 6,
      Amount1Fixed: this.state.dataScreen.Amount1Fixed,
      ReferenceNum: this.state.dataScreen.ReferenceNum,
      AmountBilled: this.formRef.current?.getFieldValue('AmountBilled'),
      TaxRate_1: this.formRef.current?.getFieldValue('TaxRate') / 100,
      LessThanTaxCircle: this.formRef.current?.getFieldValue('LessThanTaxCircle'),
      TaxCalculateUnit: this.formRef.current?.getFieldValue('TaxCalculateUnit'),
      TaxClassify: this.formRef.current?.getFieldValue('TaxClassify'),
      TotalAmount: this.formRef.current?.getFieldValue('TotalAmount'),
      Tax: this.formRef.current?.getFieldValue('Tax'),
    }
    InvoiceMaintainAction.eventChangeLessThanTaxCircle(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          TotalAmount: res?.data?.TotalAmount,
          Tax: res?.data?.Tax,
          AmountBilled: res?.data?.AmountBilled
        })

        let data = {
          ...this.state.dataScreen,
          ...res?.data
        }

        this.setState({
          dataScreen: data
        })

        this.changeScreenEditting(true)
        this.getDataTable()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  changeScreenEditting(Lo_ScreenEditing) {
    let data = {
      ...this.state.dataScreen,
      ScreenEditing: Lo_ScreenEditing
    }

    this.setState({
      dataScreen: data
    })
  }

  /**
   * 明細内訳画面の表示
   * @param {*} data
   */
  showModalItemzed_964(data) {
    if (data.Li_BillingManageNum != '0')  // MagicXPA doesn't open new modal for this case
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: "70%",
          centered: true,
          component: (
            <WS0964001_Itemized
              {...data}

              // onFinishScreen={(output) => {
              //   this.changeScreenEditting(output.Lo_ScreenEditing)
              //   this.eventF7(1)
              //   this.closeModal();
              // }}

              onFinishScreen={(output) => {
                this.getDataTable()
              }}
            />
          ),
        },
      });
  }

  showModalInsuranPeopleQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "70%",
        component: (
          <WS0339001_InsurerInfoMaintain
            Li_InsurerCode={this.formRef.current?.getFieldValue('InsurerCode')}
            onFinishScreen={(output) => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  showModalOfficeInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "70%",
        component: (
          <WS2585001_OfficeInfoInquirySub
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
            onFinishScreen={(output) => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  showModalPersonalInquiry() {
    // let title = '個人情報照会' + ' [' + this.formRef.current?.getFieldValue('PersonalCode') + ']'
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "70%",
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalCode')}
            onFinishScreen={(output) => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  showModalInvoice_947() {
    this.setState({ isClose974: true })
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0947001_Invoice
            Li_SpecifyIssuedByPresence={this.state.ClassifyState == 3 ? 0 : 1}
            onFinishScreen={(output) => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource];

    data[index][field] = value;

    this.setState({
      dataSource: data
    });
  }

  saveDataSubContent(record) {
    let params = {
      id: record.id,
      W1_remark: record.W1_remark,
    }
    InvoiceMaintainAction.saveDataSubContent(params)
      .then(async (res) => {
        this.changeScreenEditting(true)
        this.getDataTable(false)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  findIndexByID = (recordID) => {
    return this.state.dataSource.findIndex((item) => recordID === item.id);
  };

  changeRow(record) {
    let data = [...this.state.dataSource]

    let index = this.findIndexByID(record.id)
    this.setState({
      selectedRow: data[index],
      selectedRowKeys: [data[index]?.id],
      indexTable: index
    });
  }

  renderButtonCloseModalCustom() {
    return (
      <Button className='close-button'
        onClick={() => {
          if (this.state.dataScreen.ScreenEditing) {
            let title = <span>更新中のデータは無効になります <br /> よろしいですか?</span>
            Modal.confirm({
              title: title,
              width: 350,
              icon: <QuestionCircleOutlined style={{ color: '#1890ff', fontSize: 28 }} />,
              onOk: () => {
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    ScreenEditing: this.state.dataScreen.ScreenEditing,
                    screenName: 'WS0963001_InvoiceMaintain'
                  })
                }
              }
            })
          } else {
            if (this.props.onFinishScreen) {
              this.props.onFinishScreen({
                ScreenEditing: false,
                screenName: 'WS0963001_InvoiceMaintain'
              })
            }
          }
        }}
      >
        <CloseOutlined />
      </Button>
    )
  }

  eventF8 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0963004_TamperProofRelease
            Li_OptionSk0002={this.state.dataScreen.OptionSk0002}
            onFinishScreen={(output) => {
              if (output) {
                let newDataScreen = {
                  ...this.state.dataScreen,
                  ProtectionFlag: 0
                }
                this.setState({
                  dataScreen: newDataScreen,
                  ProtectionLiftFlag: false
                })

                this.closeModal()
              }
            }}
          />
        ),
      },
    });
  }

  eventF9 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0961001_BillingIntegration
            Li_BillingManageNum={this.formRef.current?.getFieldValue('BillingManageNum')}
            onFinishScreen={(output) => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  /**
   * 請求管理番号検索画面
   */
  searchBillingManageNum = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "80%",
        component: (
          <WS0975001_BillingInquiry
            Li_ProcessDivision={3}
            Li_IdentifyInitialDisplay={5}
            Li_TargetInitialDisplay={0}
            Li_PayRemainingInitialDisplay={0}
            Li_OutstandingInitialDisplay={1}
            Li_flag={1}
            onFinishScreen={(output) => {
              if (this.state.dataScreen.ScreenEditing) {
                let title = <span>更新中のデータは無効になります <br /> よろしいですか?</span>
                Modal.confirm({
                  width: 350,
                  title: title,
                  icon: <QuestionCircleOutlined style={{ color: '#1890ff', fontSize: 28 }} />,
                  onOk: () => {
                    this.formRef.current?.setFieldsValue({
                      BillingManageNum: output.Lo_BillingManageNum,
                      Classify: output.Lo_Identify
                    })
                    this.setState({
                      ClassifyState: output.Lo_Identify,
                    });

                    this.getScreenData(output.recordData.W1_identify)
                  }
                })
              } else {
                this.formRef.current?.setFieldsValue({
                  BillingManageNum: output.Lo_BillingManageNum,
                  Classify: output.Lo_Identify
                })
                this.setState({
                  ClassifyState: output.Lo_Identify,
                });

                this.getScreenData(output.recordData.W1_identify)
              }

              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  /**
   * 宛名検索画面
   */
  doubleClickDestination = () => {
    console.log(this.state.ClassifyState);
    switch (this.state.ClassifyState) {
      case 4:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "70%",
            component: (
              <WS0246001_InsurerInfoSearchQuery
                onFinishScreen={(output) => {
                  if (output.Lo_InsurerCode !== 0) {
                    this.formRef.current?.setFieldsValue({
                      Code: output.Lo_InsurerCode,
                      InsurerCode: output.Lo_InsurerCode,
                    })
                    this.eventGzoomDestination()
                  }

                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      case 5:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "70%",
            component: (
              <WS0247001_OfficeInfoRetrievalQuery
                onFinishScreen={(output) => {
                  this.formRef.current?.setFieldsValue({
                    Code: output.Lio_OfficeCode,
                    OfficeCode: output.Lio_OfficeCode,
                    BranchStoreCode: output.Lio_BranchStoreCode,
                  })

                  this.eventGzoomDestination()
                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      case 9:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "70%",
            component: (
              <WS0248001_PersonalInfoSearchQuery
                date={moment(new Date()).format('YYYY/MM/DD')}
                onFinishScreen={(output) => {
                  this.formRef.current?.setFieldsValue({
                    Code: output.Lo_PersonalNumId,
                    PersonalCode: output.Lo_PersonalNumId,
                  })

                  this.eventGzoomDestination()
                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      default:
        break;
    }
  }

  /**
   * 郵便番号検索画面
   */
  doubleClickDestinationZipCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "70%",
        component: (
          <WS0084001_PostCodeSearchEngine
            onFinishScreen={(output) => {
              let address = this.formRef.current?.getFieldValue('Address1')
              this.formRef.current?.setFieldsValue({
                DestinationZipCode: output.Lio_ZipCode,
                Address1: address ? address : output.Lio_Address
              })
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  remarkChangeModal = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0963002_RemarkChangeModal
            kanren_num={record.W1_kanren_num}
            kan_sect={record.W1_kan_sect}
            content={record.W1_content}
            remarks={record.W1_remark}
            onFinishScreen={(output) => {
              this.remarkChangeProcess(output, record)
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  remarkChangeProcess = (output, record) => {
    let params = {
      identification: this.props.Li_identification,
      billingManagementNumber: this.props.Li_BillingManageNum,
      kanren_num: record.W1_kanren_num,
      new_kanren_num: output.kanren_num,
      kan_sect: output.kan_sect,
      content: output.content,
      remarks: output.remarks
    }

    console.log(params);

    InvoiceMaintainAction.updateRecordData(params)
      .then(res => {
        message.success(res.data.message);
        this.getScreenData()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    const { ClaimNum, StsSubjectsQuery, ProtectionFlag, IntegrationFlag, Li_Modify, CompletedEffective, DeleteFlag, SpecifyValid, ProcessDivision } = this.state.dataScreen
    return (
      <div className="invoice-maintain">
        <Card className="mb-2" title="請求書保守">
          <Form ref={this.formRef} onFinish={this.onFinish} autoComplete='off'>
            <div className="box_container">
              <div className="box_inner_horizontal">
                <Form.Item
                  name="BillingManageNum"
                  label="請求管理番号"
                >
                  <Input.Search
                    readOnly
                    style={{ textAlign: "right" }}
                    className='input-search-size-8'
                    onSearch={() => {
                      this.searchBillingManageNum()
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="Classify"
                  label="請求先"
                  className="input-size-20"
                >
                  <Select
                    disabled={!this.state.dataScreen.KeyEditingPermission && ProcessDivision !== 1}
                    onChange={(value) => {
                      console.log(value)
                      this.setState({ ClassifyState: value })
                    }
                    }>
                    <Select.Option value={4}>保険者</Select.Option>
                    <Select.Option value={5}>事業所</Select.Option>
                    <Select.Option value={6}>他団体</Select.Option>
                    <Select.Option value={9}>個人未収</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="InsurerCode"
                  hidden={this.state.ClassifyState !== 4}
                >
                  <div className="input-size-10" style={{ textAlign: 'right' }}>
                    {this.formRef.current?.getFieldValue('InsurerCode')}
                  </div>
                </Form.Item>

                <Form.Item
                  name="OfficeCode"
                  hidden={!(this.state.ClassifyState == 5 || this.state.ClassifyState == 6)}
                >
                  <div
                    className="input-size-10"
                    style={{ textAlign: 'right' }}
                  >
                    {this.formRef.current?.getFieldValue('OfficeCode')}
                  </div>
                </Form.Item>

                <Form.Item
                  name="PersonalCode"
                  hidden={this.state.ClassifyState !== 9}
                >
                  <div
                    className="input-size-10"
                    style={{ textAlign: 'right' }}
                  >
                    {this.formRef.current?.getFieldValue('PersonalCode')}
                  </div>
                </Form.Item>

                <Form.Item
                  hidden={(!this.formRef.current?.getFieldValue('InsurerCode') && this.state.ClassifyState === 4)
                    || (!this.formRef.current?.getFieldValue('OfficeCode') && (this.state.ClassifyState == 5 || this.state.ClassifyState == 6))
                    || (!this.formRef.current?.getFieldValue('PersonalCode') && this.state.ClassifyState == 9)
                  }
                >
                  <Button
                    icon={<MoreOutlined />}
                    size='small'
                    style={{ display: this.formRef.current?.getFieldValue('Code') > 0 ? '' : 'none' }}
                    onClick={() => {
                      let InsurerCode = this.formRef.current?.getFieldValue('InsurerCode')
                      let OfficeCode = this.formRef.current?.getFieldValue('OfficeCode')
                      let condition = this.state.ClassifyState

                      if (condition === 6 && !OfficeCode && InsurerCode) {
                        condition = 4
                      }

                      if (condition === 6 && OfficeCode) {
                        condition = 5
                      }
                      switch (condition) {
                        case 4:
                          this.showModalInsuranPeopleQuery()
                          break;
                        case 5:
                          this.showModalOfficeInquiry()
                          break;
                        case 9:
                          this.showModalPersonalInquiry()
                          break;
                        default:
                          break;
                      }
                    }}
                  ></Button>
                </Form.Item>

                <Form.Item
                  name="ClaimNum"
                  label="請求番号"
                >
                  <div className="input-size-10" style={{ textAlign: 'right' }} >
                    {this.formRef.current?.getFieldValue('ClaimNum')}
                  </div>
                </Form.Item>

                {/**
                <Form.Item
                  name="StyleNum"
                  label="様式"
                >
                  <Select
                    style={{ width: 160 }}
                    onChange={(value) => this.changeScreenEditting(true)}
                  >
                    {this.state.ListStyleNum.map((item) => (
                      <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                */}

                <Form.Item name="Expression_26" style={{ marginLeft: 'auto' }}>
                  <div
                    style={{
                      border: 'solid 1px #ABADB3',
                      width: 80,
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {this.state.ProtectionLiftFlag ? '保護' : '解除'}
                  </div>
                </Form.Item>

                {/**
                <Form.Item name="Expression_28">
                  <Input
                    className="input-size-5"
                    style={{
                      textAlign: 'center',
                      background: (DeleteFlag === 1 || DeleteFlag === 2 || IntegrationFlag === 1) ? '' : "transparent",
                      border: (DeleteFlag === 1 || DeleteFlag === 2 || IntegrationFlag === 1) ? '' : "none"
                    }} />
                </Form.Item>
                 */}

                <Form.Item name="Expression_28" style={{ marginLeft: 'auto' }}>
                  <div
                    style={{
                      border: (DeleteFlag === 1 || DeleteFlag === 2 || IntegrationFlag === 1) ? 'solid 1px #ABADB3' : "none",
                      width: 80,
                      padding: '3px',
                      textAlign: 'center',
                      background: (DeleteFlag === 1 || DeleteFlag === 2 || IntegrationFlag === 1) ? '' : "transparent"
                    }}
                  >
                    {this.state.dataScreen.Expression_28}
                  </div>
                </Form.Item>

                <Form.Item name="Expression_224" style={{ marginLeft: 'auto' }}>
                  <div
                    style={{
                      border: ProcessDivision > 0 ? 'solid 1px #ABADB3' : "none",
                      width: 80,
                      padding: '3px',
                      textAlign: 'center',
                      background: ProcessDivision > 0 ? '' : "transparent"
                    }}
                  >
                    {this.state.dataScreen.Expression_224}
                  </div>
                </Form.Item>
              </div>

              <div className="box_search" style={{ width: '100%' }}>
                <div className="box_search_inner_border" style={{ width: '45%' }}>
                  <Form.Item
                    name="Destination"
                    label="宛名"
                    style={{ marginRight: '20px' }}
                    {...smGridLeft}
                  >
                    <Input.Search
                      className='custom-disabled-input'
                      onSearch={(value, event) => {
                        this.doubleClickDestination()
                      }}
                      disabled={
                        //保護も統合もされてもいない場合入力可
                        ProtectionFlag === 1 ? true
                          : IntegrationFlag === 1 ? true
                            : false
                      }
                      onBlur={(e) => {
                        if (this.state.dataScreen.Destination !== e.target.value) {
                          this.changeScreenEditting(true)
                        }

                        if (ProcessDivision == 1 && !this.formRef.current?.getFieldValue('Subject')) {
                          this.formRef.current?.setFieldsValue({
                            Subject: this.state.dataScreen.SubjectOp
                          })
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="DestinationZipCode"
                    label="送付先"
                    style={{ marginRight: '20px' }}
                    {...smGridLeft}
                  >
                    <Input.Search
                      className='input-search-size-single-byte-8'
                      onSearch={(value, event) => {
                        this.doubleClickDestinationZipCode()
                      }}
                      disabled={
                        //保護も統合もされてもいない場合入力可
                        ProtectionFlag === 1 ? true
                          : IntegrationFlag === 1 ? true
                            : false
                      }
                      onChange={e =>
                        this.changeScreenEditting(true)
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="Address1"
                    label=" "
                    style={{ marginRight: '20px' }}
                    {...smGridLeft}
                  >
                    <Input maxLength={60}
                      className='custom-disabled-input'
                      onDoubleClick={() => {
                        this.doubleClickDestinationZipCode()
                      }}
                      disabled={
                        //保護も統合もされてもいない場合入力可
                        ProtectionFlag === 1 ? true
                          : IntegrationFlag === 1 ? true
                            : false
                      }
                      onBlur={(e) => {
                        if (this.state.dataScreen.Address1 !== e.target.value) {
                          this.changeScreenEditting(true)
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Address2"
                    label=' '
                    style={{ marginRight: '20px' }}
                    {...smGridLeft}
                  >
                    <Input
                      maxLength={60}
                      className='custom-disabled-input'
                      disabled={
                        //保護も統合もされてもいない場合入力可
                        ProtectionFlag === 1 ? true
                          : IntegrationFlag === 1 ? true
                            : false
                      }
                      onBlur={(e) => {
                        if (this.state.dataScreen.Address2 !== e.target.value) {
                          this.changeScreenEditting(true)
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Subject"
                    label="件名"
                    style={{ marginRight: '20px' }}
                    {...smGridLeft}
                  >
                    <Input
                      maxLength={100}
                      className='custom-disabled-input'
                      disabled={
                        //保護も統合もされてもいない場合入力可
                        ProtectionFlag === 1 ? true
                          : IntegrationFlag === 1 ? true
                            : false
                      }
                      onBlur={(e) => {
                        if (this.state.dataScreen.Subject !== e.target.value) {
                          this.changeScreenEditting(true)
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Remarks"
                    label="備考"
                    style={{ marginRight: '20px' }}
                    {...smGridLeft}
                  >
                    <Input
                      maxLength={100}
                      className='custom-disabled-input'
                      disabled={
                        //保護も統合もされてもいない場合入力可
                        ProtectionFlag === 1 ? true
                          : IntegrationFlag === 1 ? true
                            : false
                      }
                      onBlur={(e) => {
                        if (this.state.dataScreen.Remarks !== e.target.value) {
                          this.changeScreenEditting(true)
                        }
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="box_search_inner" style={{ width: '55%' }}>
                  <div style={{ marginLeft: '10px' }}>
                    <div className="box_inner_horizontal">

                      <Form.Item
                        name="BillingDateChar"
                        label="請求年月"
                        {...smGridRight}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="ClaimDateOfIssueChar"
                        label="発行日"
                        {...smGridRight}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                        />
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        name="BillingPeriodBeginningChar"
                        label="請求期間"
                        {...smGridRight}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                          onChange={(e) => {
                            let date = moment(e).format('YYYY/MM/DD')
                            this.formRef.current?.setFieldsValue({
                              BillingPeriodBeginning: date
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="BillingPeriodFinalChar"
                        label="~"
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                          onChange={(e) => {
                            let date = moment(e).format('YYYY/MM/DD')
                            this.formRef.current?.setFieldsValue({
                              BillingPeriodFinal: date
                            })
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <hr style={styleHr} />

                  <div className="box_inner_horizontal" style={{ width: '100%' }}>
                    <div className="box_search_inner_border" style={{ width: '50%', marginLeft: '10px' }}>
                      <Form.Item
                        name="TaxRate"
                        label="税率"
                        style={{ marginRight: '20px' }}
                        {...smGridRight}
                      >
                        <div className="input-size-10" >
                          {this.formRef.current?.getFieldValue('TaxRate') + '%'}
                        </div>
                      </Form.Item>

                      <Form.Item
                        name="TaxCalculateUnit"
                        label="税計算"
                        style={{ marginRight: '20px' }}
                        {...smGridRight}
                      >
                        <Select
                          onChange={(value) => {
                            this.setState({
                              TaxCalculateUnit: value
                            })
                            this.eventChangeTaxCalculateUnit()
                            this.changeScreenEditting(true)
                          }}
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                        >
                          <Select.Option value={0}>明細</Select.Option>
                          <Select.Option value={1}>合計</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="LessThanTaxCircle"
                        label="税円未満"
                        style={{ marginRight: '20px' }}
                        {...smGridRight}
                      >
                        <Select
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                          onChange={(value) => {
                            let title = <span>明細の内容も変更されます。<br /> 変更しますか？</span>
                            if (value == 1 || value == 2) {
                              Modal.confirm({
                                width: 300,
                                title: title,
                                onOk: () => {
                                  this.eventChangeLessThanTaxCircle()
                                  this.changeScreenEditting(true)
                                },
                                onCancel: () => {
                                  this.formRef.current?.setFieldsValue({
                                    LessThanTaxCircle: 0
                                  })
                                }
                              })
                            }
                          }}>
                          <Select.Option value={0}>四捨五入</Select.Option>
                          <Select.Option value={1}>切捨</Select.Option>
                          <Select.Option value={2}>切上</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="TaxClassify"
                        label="税区分"
                        hidden={this.state.TaxCalculateUnit !== 1}
                        style={{ marginRight: '20px' }}
                        {...smGridRight}
                      >
                        <Select
                          disabled={
                            //保護も統合もされてもいない場合入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : false
                          }
                          onChange={(value) => {
                            this.setState({ TaxClassifyState: value })
                            this.eventChangeTaxClassify()
                            this.changeScreenEditting(true)
                          }}>
                          <Select.Option value={0}>消費税指定</Select.Option>
                          <Select.Option value={1}>外税</Select.Option>
                          <Select.Option value={2}>内税</Select.Option>
                          <Select.Option value={3}>非課税</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="box_search_inner" style={{ marginLeft: '10px' }}>
                      <Form.Item
                        name="TotalAmount"
                        label="本体金額"
                        {...smGridRight}
                      >
                        <InputNumber
                          maxLength={10}
                          min={0}
                          formatter={value => value == 0 ? '' : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          disabled={
                            //保護状態でなく、統合されておらず、税区分が税指定か外税で、新規の場合のみ入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : this.state.dataScreen.ProcessDivision !== 1 ? true
                                  : (this.formRef.current?.getFieldValue('TaxClassify') !== 0 && this.formRef.current?.getFieldValue('TaxClassify') !== 1) ? true
                                    : false
                          }
                          onBlur={(value) => {
                            if (this.state.dataScreen.TotalAmount !== value) {
                              this.changeScreenEditting(true)
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="Tax"
                        label="税"
                        {...smGridRight}
                      >
                        <InputNumber
                          maxLength={10}
                          min={0}
                          formatter={value => value == 0 ? '' : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          disabled={
                            //保護状態でなく、統合されておらず、税区分が税指定で、新規の場合のみ入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : this.state.dataScreen.ProcessDivision !== 1 ? true
                                  : this.formRef.current?.getFieldValue('TaxClassify') !== 0 ? true
                                    : false
                          }
                          onBlur={(value) => {
                            if (this.state.dataScreen.TotalAmount !== value) {
                              this.changeScreenEditting(true)
                            }
                          }} />
                      </Form.Item>

                      <Form.Item
                        name="AmountBilled"
                        label="請求金額"
                        {...smGridRight}
                      >
                        <InputNumber
                          maxLength={10}
                          min={0}
                          formatter={value => value == 0 ? '' : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          disabled={
                            //保護状態でなく、統合されておらず、税区分が内税で、新規の場合のみ入力可
                            ProtectionFlag === 1 ? true
                              : IntegrationFlag === 1 ? true
                                : this.state.dataScreen.ProcessDivision !== 1 ? true
                                  : this.formRef.current?.getFieldValue('TaxClassify') !== 2 ? true
                                    : false
                          }
                          onBlur={(value) => {
                            if (this.state.dataScreen.TotalAmount !== value) {
                              this.changeScreenEditting(true)
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="DepositAmount"
                        label="入金額"
                        {...smGridRight}
                      >
                        <InputNumber
                          formatter={value => value == 0 ? '' : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                style={{ cursor: 'pointer' }}
                rowClassName={(record, index) => record.id === this.state.selectedRow.id ? 'table-row-light' : ''}
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                scroll={{ y: 180, x: 'max-content' }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.changeRow(record)
                    }
                  };
                }}
              >
                <Table.Column
                  title="連番"
                  dataIndex="W1_kanren_num"
                  className="column-size-5"
                  render={(value, record, index) => {
                    let condition = (ProtectionFlag == 0 && IntegrationFlag == 0 && Li_Modify && CompletedEffective) ? true : false
                    return (
                      <div>
                        {condition ?
                          <InputNumber value={value} maxLength={4} min={1}
                            onBlur={(value) => {
                              this.updateDatasource(this.findIndexByID(record.id), "W1_kanren_num", value);
                            }} />
                          :
                          <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value}</div>}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title="区分"
                  dataIndex="W1_kan_sect"
                  className="column-size-10"
                  render={(value, record) => {
                    let condition = (ProtectionFlag == 0 && IntegrationFlag == 0 && Li_Modify && CompletedEffective) ? true : false
                    return (
                      <div>
                        {condition ?
                          <Select defaultValue={record.W1_kan_sect} style={{ width: "100%" }}
                            onChange={(value) => {
                              this.updateDatasource(this.findIndexByID(record.id), "W1_kan_sect", value);
                            }}>
                            <Select.Option value={1}>ｾｯﾄ</Select.Option>
                            <Select.Option value={3}>ｺｰｽ</Select.Option>
                            <Select.Option value={5}>ｺﾒﾝﾄ</Select.Option>
                            <Select.Option value={7}>明細無効</Select.Option>
                            <Select.Option value={9}>無効</Select.Option>
                          </Select>
                          :
                          <div>
                            {value == 1 ? 'セット'
                              : value == 3 ? 'コース'
                                : value == 5 ? 'コメント'
                                  : value == 7 ? '明細無効'
                                    : value == 9 ? '無効' : ''
                            }
                          </div>
                        }
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title="内容"
                  dataIndex="W1_content"
                  className="column-size-60"
                  render={(value, record) => {
                    let condition = (ProtectionFlag == 0 && IntegrationFlag == 0 && Li_Modify && CompletedEffective) ? true : false
                    return (
                      <div
                        onDoubleClick={() => {
                          if (StsSubjectsQuery && ProtectionFlag == 0 && IntegrationFlag == 0 && Li_Modify && CompletedEffective) {
                            if (record.W1_kan_sect == 1 || record.W1_kan_sect == 3) {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: "70%",
                                  component: (
                                    <WS0967003_BillingNameQuerySub
                                      Li_Classify={record.W1_kan_sect == 1 ? 20 : 10}
                                      onFinishScreen={(output) => {
                                        this.updateDatasource(this.findIndexByID(record.id), "W1_content", output.Lo_Name);
                                        this.closeModal();
                                      }}
                                    />
                                  ),
                                },
                              });
                            }
                          }
                        }
                        }
                      >
                        {condition ?
                          <Input.TextArea rows={1} defaultValue={value} maxLength={100}
                            onBlur={(e) => {
                              this.updateDatasource(this.findIndexByID(record.id), "W1_content", e.target.value);
                            }}
                          />
                          :
                          <div>{record.W1_content}</div>
                        }

                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="税区分"
                  dataIndex="W1_tax_sect"
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div hidden={record.W1_kan_sect == 5}>
                        {value == 0 ? '消費税指定'
                          : value == 1 ? '外税'
                            : value == 2 ? '内税'
                              : value == 3 ? '非課税' : ''
                        }
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title="単価"
                  className="column-size-10"
                  render={(value, record) => {
                    return (<div style={{ textAlign: 'right' }}>
                      <div>{record.W1_price_excluding_tax == 0 ? '' : number_format(record.W1_price_excluding_tax)}</div>
                      <div>{record.W1_unit_price_tax == 0 ? '' : '(' + number_format(record.W1_unit_price_tax) + ')'}</div>
                    </div>
                    );

                    // return (
                    //   <div style={{ textAlign: 'right' }}>
                    //     <div hidden={!(record.W1_tax_sect == 2 && record.W1_kan_sect != 5)}>
                    //       {record.W1_tax_unit_price == 0 ? '' : number_format(record.W1_tax_unit_price)}
                    //     </div>
                    //     <div hidden={!(record.W1_tax_sect != 2 && record.W1_kan_sect != 5)}>
                    //       {record.W1_price_excluding_tax == 0 ? '' : number_format(record.W1_price_excluding_tax)}
                    //     </div>
                    //     <div hidden={record.W1_kan_sect == 5}>
                    //       ({record.W1_unit_price_tax == 0 ? '' : number_format(record.W1_unit_price_tax)})
                    //     </div>
                    //   </div>
                    // );
                  }}
                />

                <Table.Column
                  title="人数"
                  dataIndex="W1_person"
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}
                        hidden={record.W1_kan_sect == 5}
                      >
                        {value == 0 ? '' : value}
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="本体金額"
                  className="column-size-10"
                  render={(value, record) => {
                    return (<div style={{ textAlign: 'right' }}>
                      <div>{record.W1_price_exclude_tax == 0 ? '' : number_format(record.W1_price_exclude_tax)}</div>
                      <div>{record.W1_price_exclude_tax_tax == 0 ? '' : '(' + number_format(record.W1_price_exclude_tax_tax) + ')'}</div>
                    </div>
                    );

                    // return (
                    //   <div style={{ textAlign: 'right' }}>
                    //     <div hidden={!(record.W1_tax_sect == 2 && record.W1_kan_sect != 5)}>
                    //       {record.W1_tax_price_exclude_tax == 0 ? '' : number_format(record.W1_tax_price_exclude_tax)}
                    //     </div>
                    //     <div hidden={!(record.W1_tax_sect != 2 && record.W1_kan_sect != 5)}>
                    //       {record.W1_price_exclude_tax == 0 ? '' : number_format(record.W1_price_exclude_tax)}
                    //     </div>
                    //     <div hidden={record.W1_kan_sect == 5}>
                    //       ({record.W1_price_exclude_tax_tax == 0 ? '' : number_format(record.W1_price_exclude_tax_tax)})
                    //     </div>
                    //   </div>
                    // );
                  }}
                />

                <Table.Column
                  title="合計金額"
                  dataIndex="W1_total_price"
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}
                        hidden={record.W1_kan_sect == 5}
                      >
                        {value == 0 ? '' : number_format(value)}
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="備考"
                  dataIndex="W1_remark"
                  className="column-size-40"
                  render={(value, record) => {
                    let condition = (ProtectionFlag == 0 && IntegrationFlag == 0 && CompletedEffective) ? true : false
                    return (
                      <div>{value}</div>
                    );
                  }}
                />

                <Table.Column
                  align='center'
                  className="column-size-2"
                  render={(text, record, index) => (
                    <Button
                      icon={<MoreOutlined />}
                      size='small'
                      disabled={this.state.ProtectionLiftFlag}
                      onClick={() => {
                        this.remarkChangeModal(record)
                      }}
                    />
                  )}
                />
              </Table>
            </div>

            <div className="box_button_bottom_right">
              {/* F7 */}
              <Button
                type="primary"
                disabled={this.state.ProtectionLiftFlag}
                onClick={() => {
                  this.eventF7(0)
                }}
              >
                <span className='btn_label'>
                  明細内訳
                </span>
              </Button>

              {/* F8 */}
              <Button
                type="primary"
                hidden={ProtectionFlag !== 1}
                disabled={!this.state.ProtectionLiftFlag}
                onClick={() => {
                  this.eventF8()
                }}
              >
                <span className='btn_label'>
                  保護解除
                </span>
              </Button>

              {/* F11 */}
              <Button
                type={(DeleteFlag == '0') ? "danger" : "primary"}
                disabled={this.state.ProtectionLiftFlag}
                onClick={() => {
                  this.branchF11(DeleteFlag, ClaimNum, CompletedEffective)
                }}
              >
                <span className='btn_label'>
                  {DeleteFlag == '0' ? '削除' : '復元'}
                </span>
              </Button>

              {/* F12 */}
              <Button
                type="primary"
                disabled={this.state.ProtectionLiftFlag}
                onClick={() => {
                  if ((ProtectionFlag == 0 && IntegrationFlag == 0 && CompletedEffective)
                    || (ProcessDivision == 2 && IntegrationFlag == 0)
                    || ProcessDivision == 4
                    || ProcessDivision == 5) {
                    this.eventF12()
                  }
                }}
              >
                <span className='btn_label'>
                  完了
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          onCancel={() => {
            if (this.state.isClose974) {
              this.getInitData()
              this.setState({ isClose974: false })
            }
            this.closeModal();
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0963001_InvoiceMaintain);
