import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Form, Input, Menu, Dropdown, message, Button } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import MiraisImplementorMaintainAction from 'redux/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisImplementorMaintain.actions'
import { SaveOutlined } from '@ant-design/icons'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
class WS2753001_MiraisImplementorMaintain extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'Mirais実施者保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isloadding: false,
      changeFlag: false,
      changeData: []
    }
  }
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      tableData: []
    })
    this.GetListData()
  }
  GetListData() {
    this.setState({ isloadding: true })
    MiraisImplementorMaintainAction.GetListData().then(res => {
      this.formRef.current?.setFieldsValue({
        tableData: res ? res : []
      })
    }).catch(error => {
      const res = error.response
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました')
        return
      }
    }).finally(() => this.setState({ isloadding: false }))
  }
  save() {
    let params = {
      changeData: this.state.changeData
    }
    MiraisImplementorMaintainAction.SaveData(params).then(res => {
      message.success('保存が完了しました')
      this.GetListData()
    }).catch(error => {
      const res = error.response
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました')
        return
      }
    })
  }
  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  handleChage(e, record) {
    let obj = {}
    let copyData = [...this.state.changeData]
    let id = record?.id
    let newFlag = true
    copyData.forEach(element => {
      if (element.id === id) {
        element.staff_code = e.target.value
        newFlag = false
      }
    })
    if (newFlag) {
      obj = {
        id: record?.id,
        staff_code: e.target.value
      }
      copyData.push(obj)
    }
    this.setState({
      changeFlag: true,
      changeData: copyData,
    })
  }

  render() {
    return (
      <div className='mirais-implementor-maintain'>
        <Card title='Mirais実施者保守'>
          <Form ref={this.formRef} autoComplete='off' >
            <Table
              dataSource={this.formRef.current?.getFieldValue('tableData') ? this.formRef.current?.getFieldValue('tableData') : []}
              pagination={false} bordered={true} size='small'
              rowKey={(record) => record.id} scroll={{ y: 700 }}
            //  rowSelection={{
            //    type: 'radio',
            //    onChange: (selectedRowKeys, selectedRows) => {
            //      console.log('selectedRows: ', selectedRows)
            //    }
            //  }}
            >
              <Table.Column title='ﾕｰｻﾞｰID' dataIndex='user_code' width={150} />
              <Table.Column title='名称' dataIndex='user_name' width={150} />
              <Table.Column title='職員ｺｰﾄﾞ' width={200}
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'staff_code']} style={{ marginBottom: '0px' }}>
                    <Input
                      maxLength={10}
                      onKeyDown={(e) => moveFocusKeyEvent(e, 'tableData', 1, false, 0)}
                      onChange={(e) => this.handleChage(e, record)}
                    />
                  </Form.Item>
                )}
              />
            </Table>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={!this.state.changeFlag}
                onClick={() =>
                  this.save()
                }
              >
                保存
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2753001_MiraisImplementorMaintain)
