import axios from 'configs/axios';

const apiPaths = {
  loadContracts: '/api/contract-info-maintain/contract-info-maintain',
  eventF11: '/api/contract-info-maintain/contract-info-maintain/f11',
  DeleteLine: '/api/contract-info-maintain/contract-info-maintain/DeleteLine',
  DeleteHistoryContract: '/api/contract-info-maintain/contract-info-maintain/DeleteHistoryContract',
  setRCV: '/api/contract-info-maintain/contract-info-maintain/setRCV',
  billRCV: '/api/contract-info-maintain/contract-info-maintain/billRCV',
}

const ContractInfoMaintainService = {
  async loadContracts(params) {
    return axios.get(apiPaths.loadContracts, { params })
  },

  async eventF11(params) {
    return axios.post(apiPaths.eventF11, params)
  },

  async DeleteLine(params) {
    return axios.post(apiPaths.DeleteLine, params)
  },

  async DeleteHistoryContract(params) {
    return axios.post(apiPaths.DeleteHistoryContract, params)
  },

  async setRCV(params) {
    return axios.post(apiPaths.setRCV, params)
  },

  async billRCV(params) {
    return axios.post(apiPaths.billRCV, params)
  },
}

export default ContractInfoMaintainService;
