import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/specimen-label-print/specimen-label-print/get-screen-data',
  displayBtn: '/api/specimen-label-print/specimen-label-print/display-btn',
  print: '/api/specimen-label-print/specimen-label-print/print',
  preview: '/api/specimen-label-print/specimen-label-print/preview',
  printNumberLabel: '/api/specimen-label-print/specimen-label-print/print-number',
  previewNumberLabel: '/api/specimen-label-print/specimen-label-print/preview-number',
  errorCheck: '/api/specimen-label-print/specimen-label-print/error-check',
  getLabelListData: '/api/specimen-label-print/specimen-label-print/get-label-list-data',
};

const SpecimenLabelPrintService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },

  async displayBtn(params) {
    return axios.get(apiPaths.displayBtn, { params });
  },

  async print(params) {
    return axios.post(apiPaths.print, params);
  },

  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' });
  },

  async printNumberLabel(params) {
    return axios.post(apiPaths.printNumberLabel, params);
  },

  async previewNumberLabel(params) {
    return axios.post(apiPaths.previewNumberLabel, params, { responseType: 'blob' });
  },

  async errorCheck(params) {
    return axios.post(apiPaths.errorCheck, params);
  },

  async getLabelListData(params) {
    return axios.get(apiPaths.getLabelListData, { params });
  },

};

export default SpecimenLabelPrintService;