import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Card, Form, Input, Button, Select, Table, Dropdown, Modal, message, Tooltip, Menu } from 'antd'
import { MoreOutlined, SearchOutlined, MenuOutlined, PrinterOutlined, InfoOutlined, PlusOutlined } from '@ant-design/icons'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS0855001_PrintParamMaintain from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855001_PrintParamMaintain.jsx'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0341008_BranchShopInquiry from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341008_BranchShopInquiry.jsx'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import WS0802001_PrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0802001_PrintInstruction.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';

import CreateAResultTableAction from 'redux/ResultOutput/ResultsTblCollectOutput/CreateAResultTable.action'

import moment from 'moment-timezone'



const labelCol = {
  labelCol: { style: { width: 70 } }
}

class WS0807001_CreateAResultTable extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '結果表一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      isLoadingTable: false,

      ComboBox_GfacilityType: [],
      ComboBox_OutputOrder: [],
      importance: 0,

      dataPrintStyle: [],
      previewIndex: 0,
      params: {},
      isSelectAll: false,

      office_kanji_name: '',

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount() {
    this.GetDataScreen()
  }

  GetDataScreen() {
    CreateAResultTableAction.GetDataScreen()
      .then(res => {
        if (res) {
          console.log(res)
          this.setState({
            ComboBox_OutputOrder: res?.ComboBox_OutputOrder,
          })
          this.formRef.current.setFieldsValue({
            OutputOrder: res?.ComboBox_OutputOrder[0].LinkedField
          });
        }
      })
  }

  // 印刷パラメータ
  printparam = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0855001_PrintParamMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  // 検索
  loadDataTable() {
    console.log(this.formRef.current?.getFieldValue())
    let params = {
      ...this.formRef.current?.getFieldValue(),
      IssueStartDateChar: this.formRef.current?.getFieldValue('IssueStartDateChar')?.format('YYYY/MM/DD'),
      IssueEndDateChar: this.formRef.current?.getFieldValue('IssueEndDateChar')?.format('YYYY/MM/DD'),
      GissueStartDate: this.formRef.current?.getFieldValue('IssueStartDateChar')?.format('YYYY/MM/DD'),
      GissueEndDate: this.formRef.current?.getFieldValue('IssueEndDateChar')?.format('YYYY/MM/DD'),
    }

    CreateAResultTableAction.TargetSelectSub(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res,
            isLoadingTable: false,
            selectedRows: res,
            selectedRowKeys: res.map(x => x.id)
          })
        }
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  clickprint() {
    let params = {
      ...this.state.params,
      selectedList: this.state.selectedRows
    }
    console.log(params)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0802001_PrintInstruction
            StsSubmission={false}
            StsScreenControl={false}
            onFinishScreen={(output) => {
              if (output) {
                console.log(output)
                this.printF12(output)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }
  async printF12(data) {
    let params = {
      OutputData: data.Lo_StsRun ? 1 : 0,
      NumCopies: data.NumOfCopies,
      Submission: data.Submission ? 1 : 0,
      OrgsTowards: data.Groups,
      InputFlag: data.InputFlag,
      L2Output: data.L2Output,
      L3Output: data.L3Output,
      Style: data.Style ?? '',
      format_name: data.format_name,
      StsScreenControl: data.StsScreenControl
    }
    await this.setState({
      params: params,
    })
    if (data.Style) {
      await this.PrintOrPreview()
    } else { message.error("様式を指定してください"); }
  }
  PrintOrPreview() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 300,
        component: (
          <WS0433001_PrinterConfirm
            StsSubmission={false}
            StsScreenControl={false}
            onFinishScreen={(output) => {
              if (output) {
                console.log(output)
                this.prePreview(0, output.Preview, 0, output.Lo_PrinterNo)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }
  prePreview(index, Preview, retryCount, PrinterNum) {
    let params = {
      ...this.state.params,
      PrinterNum: PrinterNum,
      Preview: Preview,
      selectedList: this.state.selectedRows[index]
    }

    if (Preview) {
      CreateAResultTableAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          let newIndex = index + 1

          if (this.state.selectedRows.length > newIndex) {
            this.prePreview(newIndex, Preview, 0)
          }
        }).catch(err => {
          const res = err.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }

          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }

          if (err.message.includes('500')) {
            if (retryCount <= 3) {
              let newRetryCount = retryCount + 1;
              this.prePreview(index, Preview, newRetryCount)
            } else {
              return Modal.error({
                title: '処理中にエラーが発生しました。',
                content: '',
              });
            }
          }
        })
    } else {
      CreateAResultTableAction.print(params)
        .then(res => {
          let newIndex = index + 1
          if (this.state.selectedRows.length > newIndex) {
            this.prePreview(newIndex, Preview, 0)
          } else {
            if (res.data === 'true') {
              message.success('印刷が完了しました')
            } else {
              message.error('処理の途中でエラーが発生しました')
            }
          }
        }).catch(err => {
          message.error(err?.response?.data?.message || 'エラーが発生しました')
          if (err.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }

          if (err.message.includes('500')) {
            if (retryCount <= 3) {
              let newRetryCount = retryCount + 1;
              this.prePreview(index, Preview, newRetryCount)
            } else {
              return Modal.error({
                title: '処理中にエラーが発生しました。',
                content: '',
              });
            }
          }
        })
    }
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='result-tbl-batch-create'>
        <Card title='結果表一括出力（帳票別）'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.printparam()}
                    >
                      印刷パラメータ
                    </Menu.Item>
                    {/* <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                    //onClick={this.eventF11}
                    >
                      印刷
                    </Menu.Item> */}
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <div className='box_inner_horizontal'>
                <div className='box_search' style={{ width: 'fit-content' }}>
                  <div className='box_inner_vertical' style={{ width: 'fit-content' }}>

                    <div className='box_inner_horizontal' >
                      <Form.Item
                        label='保険者'
                        name='InsurerNum'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input.Search
                          style={{ cursor: 'pointer', width: 122 }}
                          allowClear={true}
                          className='input-search-size-8'
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0246001_InsurerInfoSearchQuery
                                    Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerNum')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        InsurerNum: output.Lo_InsurerCode,
                                        insurer_kanji_name: output.Lo_Name
                                      })
                                      this.setState({
                                        insurer_kanji_name: output.Lo_Name,
                                        initParams: {
                                          ...this.state.initParams,
                                          insurer_kanji_name: output.Lo_Name
                                        }
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === "input") {
                              this.formRef.current.setFieldsValue({
                                insurer_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0246001_InsurerInfoSearchQuery
                                      Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerNum')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          InsurerNum: output.Lo_InsurerCode,
                                          insurer_kanji_name: output.Lo_Name
                                        })
                                        this.setState({
                                          insurer_kanji_name: output.Lo_Name,
                                          initParams: {
                                            ...this.state.initParams,
                                            insurer_kanji_name: output.Lo_Name
                                          }
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='insurer_kanji_name' style={{ marginBottom: 0 }}>
                        {this.formRef.current?.getFieldValue('insurer_kanji_name')}
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal' >
                      <Form.Item
                        label='事業所'
                        name='GofficeCd'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input.Search
                          allowClear={true}
                          style={{ cursor: 'pointer', width: 122 }}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '90%',
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                    Lio_BranchStoreCode={this.formRef.current?.getFieldValue('GbranchOfficeShopCdf')}
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        GofficeCd: output.Lio_OfficeCode,
                                        GstoreCd: output.Lio_BranchStoreCode,
                                        GbranchOfficeShopCdf: output.Lio_BranchStoreCode,
                                        GbranchOfficeShopCdfCopy: output.Lio_BranchStoreCode === 0 ? null : output.Lio_BranchStoreCode,
                                        office_kanji_name: output.Lo_Kanji_Name
                                      })
                                      this.setState({
                                        importance: output.recordData.importance,
                                        office_kanji_name: output.Lo_Kanji_Name
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}

                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '90%',
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('GbranchOfficeShopCdf')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          GofficeCd: output.Lio_OfficeCode,
                                          GstoreCd: output.Lio_BranchStoreCode,
                                          GbranchOfficeShopCdf: output.Lio_BranchStoreCode,
                                          GbranchOfficeShopCdfCopy: output.Lio_BranchStoreCode === 0 ? null : output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.setState({
                                          importance: output.recordData.importance,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='GbranchOfficeShopCdf'
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Search
                          type='number'
                          style={{ width: 100 }}
                          min={0}
                          onPressEnter={event => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 700,
                                component: (
                                  <WS0341008_BranchShopInquiry
                                    Li_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                    onFinishScreen={({ Li_OfficeCode, Lo_BranchStoreCode, recordData }) => {
                                      this.formRef.current.setFieldsValue({
                                        GbranchOfficeShopCdf: Lo_BranchStoreCode,
                                        office_kanji_name: recordData.office_kanji_name
                                      })
                                      this.setState({
                                        office_kanji_name: recordData.office_kanji_name
                                      })

                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }}

                          onSearch={(value, event) => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 700,
                                component: (
                                  <WS0341008_BranchShopInquiry
                                    Li_OfficeCode={this.formRef.current?.getFieldValue('GofficeCd')}
                                    onFinishScreen={({ Li_OfficeCode, Lo_BranchStoreCode, recordData }) => {
                                      this.formRef.current.setFieldsValue({
                                        GbranchOfficeShopCdf: Lo_BranchStoreCode,
                                        office_kanji_name: recordData.office_kanji_name
                                      })
                                      this.setState({
                                        office_kanji_name: recordData.office_kanji_name
                                      })

                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='office_kanji_name'
                        style={{ marginBottom: 0 }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='受診日'
                        name='IssueStartDateChar'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                          style={{ width: 122 }}
                        />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}> ~ </Form.Item>
                      <Form.Item
                        name='IssueEndDateChar'
                        style={{ marginBottom: 0 }}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={false}
                          style={{ width: 122 }}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item
                      label='コース'
                      name='CourseCodeF'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Input.Search
                        style={{ width: '35%' }}
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '100%',
                              component: (
                                <WS0265001_BasicCourseInquiry
                                  Lo_CourseCode={this.formRef.current.getFieldValue(
                                    'CourseCodeF'
                                  )}
                                  Lo_CourseName={''}
                                  onFinishScreen={(output) => {
                                    if (output.recordData) {
                                      console.log(output.recordData)
                                      this.formRef.current.setFieldsValue({
                                        CourseCodeF: output.recordData.course_code
                                          ? output.recordData.course_code
                                          : '',
                                      })
                                    }
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Form.Item>

                    <div style={{ display: 'flex', gap: 10 }}>
                      <Form.Item
                        name='ReceiptNumF'
                        label='受付No'
                        {...labelCol}>
                        <Input className='input-size-number-5' />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='ReceiptNumT'>
                        <Input className='input-size-number-5' />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='出力順'
                        name='OutputOrder'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Select
                          style={{ width: 180 }}
                        >
                          {this.state.ComboBox_OutputOrder?.map((item, index) => (
                            <Select.Option
                              key={`select${index}`}
                              value={item.LinkedField}
                            >
                              {item.DisplayField}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.loadDataTable()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                    <Tooltip title='詳細な条件追加'>
                      <Button size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component: (
                                <WS2786001_ConditionAddSub
                                  InsurerCode={this.formRef.current.getFieldValue('InsurerNum')}
                                  OfficeCode={this.formRef.current.getFieldValue('GofficeCd')}
                                  BranchStoreCode={this.formRef.current.getFieldValue('GbranchOfficeShopCdf')}
                                  Li_DateF={this.formRef.current?.getFieldValue('IssueStartDateChar') ? this.formRef.current?.getFieldValue('IssueStartDateChar').format('YYYY/MM/DD') : ''}
                                  Li_DateT={this.formRef.current?.getFieldValue('IssueEndDateChar') ? this.formRef.current?.getFieldValue('IssueEndDateChar').format('YYYY/MM/DD') : ''}
                                  Li_CourseF={this.formRef.current.getFieldValue('CourseCodeF')}
                                  Li_CourseT={this.formRef.current.getFieldValue('CourseCodeF')}
                                  Li_AcceptNoF={this.formRef.current?.getFieldValue('ReceiptNumF')}
                                  Li_AcceptNoT={this.formRef.current.getFieldValue('ReceiptNumT')}
                                  Lio_KeyInfo={this.formRef.current.getFieldValue('OutputOrder')}
                                  Li_State='1'
                                  onFinishScreen={(output) => {
                                    // 追加条件のデータをstate変数に保持
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                    })
                                    this.formRef.current.setFieldsValue({
                                      OutputOrder: output.Lio_KeyInfo ? output.Lio_KeyInfo : '',
                                      conditionAddData: output.conditionAddData
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              size='small'
              className='scrollbar'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                  let arrTemp = [...this.state.selectedRowKeys]
                  let arrTempRecord = [...this.state.selectedRows]
                  let idx = arrTemp.indexOf(record.id)
                  if (idx === -1) {
                    arrTemp.push(record.id)
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                  } else {
                    arrTemp.splice(idx, 1)
                    arrTempRecord.splice(idx, 1)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord
                    })
                  }
                },

                onSelectAll: (selected, selectedRows, changeRows) => {
                  if (selected) {
                    let arrTemp = this.state.dataSource.map(item => item.id)
                    let arrTempRecord = this.state.dataSource
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      isSelectAll: true
                    })
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: [],
                      isSelectAll: false
                    })
                  }
                },
              }}
            >
              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                className='column-size-date'
                render={(value) => {
                  return (
                    <div>{moment(value).format('YYYY/MM/DD (ddd)') ?? ''}</div>
                  )
                }}
              />

              <Table.Column
                title='時間'
                dataIndex='period_time'
                className='column-size-date'
                render={(value, record, index) => {
                  return (
                    <div>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='受付No'
                dataIndex='receipt_number'
                className='column-size-10'
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      <span>{record.receipt_number}</span>
                    </div>
                  )
                }}
              />

              <Table.Column
                title='個人番号'
                dataIndex='personal_number_id'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='メモ'
                dataIndex='memo1'
                className='column-size-5'
                render={(value, record, index) => {
                  let icon = ''
                  switch (record.Expresstion_17) {
                    default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                  }
                  return (
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (
                              <WS2584019_PersonalInfoInquirySub
                                Li_PersonalNum={record.personal_number_id}
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    >
                      {icon}
                    </div>
                  )
                }}
              />

              <Table.Column
                title='漢字氏名'
                dataIndex='KanjiName'
                className='column-size-40'
                render={(value, record) => {
                  return (
                    <div style={{ textAlign: 'left' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='性別'
                dataIndex='Gender'
                className='column-size-5'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='生年月日'
                dataIndex='DateBirth'
                className='column-size-40'
                render={(value, record, index) => {
                  return (
                    <div>
                      {moment(value).format('YYYY/MM/DD') ?? ''}
                    </div>
                  )
                }}
              />

              <Table.Column
                title='区分'
                dataIndex='classification'
                className='column-size-10'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='年齢'
                dataIndex='Age'
                className='column-size-5'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='メモ'
                dataIndex='memo2'
                className='column-size-5'
                render={(value, record, index) => (
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small' icon={<MoreOutlined />}
                      onClick={() => this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '90%',
                          component: (
                            <WS2585001_OfficeInfoInquirySub
                              Li_OfficeCode={record.office}
                              Li_BranchCode={record.branch}
                              onFinishScreen={(output) => {
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })}></Button>
                  </div>
                )}
              />
              <Table.Column
                title='事業所'
                dataIndex='office_kanji_name'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div>{value}</div>
                  )
                }}
              />

              <Table.Column
                title='契約情報'
                dataIndex='contract_short_name'
                className='column-size-40'
                render={(value, record, index) => {
                  return (
                    <div><span style={{ marginRight: 5 }}>
                      {record.visit_course ? (record.visit_course?.toString().substr(0, 1) + '-' + record.visit_course?.toString().substr(1, 2)) : ''}
                    </span>
                      <span>{value}</span></div>
                  )
                }}
              />

              <Table.Column
                title='状態'
                dataIndex='Expresstion_15'
                className='column-size-5'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />
              <Table.Column
                title='備考'
                dataIndex='remarks'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div>{value}</div>
                  )
                }}
              />
              <Table.Column
                title='検査状況'
                dataIndex='InspectStatus'
                className='column-size-40'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )
                }}
              />
            </Table>
          </div>

          <div className='box_button_bottom_right' >
            <Button
              icon={<PrinterOutlined />}
              type='primary'
              onClick={() => {
                this.clickprint(0)
              }}
            >
              <span className='btn_label'>
                印刷
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0807001_CreateAResultTable)
