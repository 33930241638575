import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
class WS1229013_Inquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '照会';

    this.state = {
      dataSource: [],
    };
  }
  
  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.index !== prevProps.index) {
      this.getData();
    }
  }

  getData () {
    VenusApiRoutesV2.callApi("API001229013002")
    .then(res => {
      this.setState({
        dataSource: res
      });
    })
    .catch(error => {
      console.log(error);

      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました');
        return;
      }

      message.error(res.data.message);
    })
    .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <div className="inquiry">
        <Card title="照会">
          <Table bordered 
            dataSource={this.state.dataSource}
            loading={false}
            pagination={{
              defaultPageSize: 10,
              size: 'small',
              showQuickJumper: true
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="承認番号" dataIndex="approval_number" key="approval_number" />
            <Table.Column title="カナ氏名" dataIndex="kana_name" key="kana_name" />
            <Table.Column title="漢字氏名" dataIndex="kanji_name" key="kanji_name" />
            <Table.Column title="生年月日" dataIndex="birthday" key="birthday" />
            <Table.Column title="年齢" dataIndex="age" key="age" />
            <Table.Column title="続柄" dataIndex="Relationship" key="Relationship" />
            <Table.Column title="性別" dataIndex="Gender" key="Gender" />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1229013_Inquiry);
