import React from 'react';
import PropTypes from 'prop-types';
import axios from 'configs/axios';
import moment from 'moment';
import Color from 'constants/Color';
import { number_format } from 'helpers/CommonHelpers';
import { ReloadOutlined, PlusOutlined, } from '@ant-design/icons';
import { Form, Input, Card, Col, Row, Select, Table, Modal, Menu, Popconfirm, Dropdown, Button, Space, Spin, message, Tag, } from 'antd';
import { connect } from "react-redux";
import { Next } from 'react-bootstrap/esm/PageItem';

class WS3468002 extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '2024年度　特定健診・特定保健指導改正';

    this.state = {
      selectedRows: [],
      selectedRowKeys: [],
      tabledata: []
    };
  }

  componentDidMount() {
    this.index()
  }

  index = () => {
    let params = this.props.params
    axios.get('/api/amendment/amendment-2024/get-screen-data', { params })
      .then(res => {
        console.log(res)
        console.log(this.props.params)
        axios.get('/api/amendment/amendment-2024/index-WS3468022')
          .then(res => {
            console.log(res)
            this.setState({ tabledata: res.data })
          })
          .catch(error => {
            message.error(error)
          })
      })
      .catch(error => {
        message.error(error)
      })
  }

  execution = () => {
    console.log('tuka')
    let params = this.props.params
    axios.get('/api/amendment/amendment-2024/execution', { params })
      .then(res => {
        console.log(res)
        message.success("成功")
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
      .catch(error => {
        message.error(error)
      })
  }

  FDparam = () => {
    console.log('tuka')
    let params = this.props.params
    axios.get('/api/amendment/amendment-2024/fd-param', { params })
      .then(res => {
        console.log(res)
        message.success("成功")
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
      .catch(error => {
        message.error(error)
      })
  }


  render() {
    return (
      <div className="xml-document-query-select-sub">
        <Card title="設定確認">
          <Form ref={this.formRef}>
            <Table
              size='small'
              bordered
              dataSource={this.state.tabledata}
              rowKey={record => record.id}
            >
              <Table.Column
                title='検査コード'
                dataIndex='W1_inspect_cd'
                key=""
                // className='column-size-5'
                render={(value) => (
                  <div>{value}</div>
                )}
              />
              <Table.Column
                title='検査略名'
                dataIndex='Expresstion_1'
                key=""
                // className='column-size-5'
                render={(value) => (
                  <div>{value}</div>
                )}
              />
              <Table.Column
                title='検査名称'
                dataIndex='Expresstion_2'
                key=""
                // className='column-size-5'
                render={(value) => (
                  <div>{value}</div>
                )}
              />
              <Table.Column
                title='検査'
                dataIndex='Expresstion_4'
                key=""
                // className='column-size-5'
                render={(value) => (
                  <div>{value}</div>
                )}
              />
              <Table.Column
                title='コメント'
                dataIndex='Expresstion_5'
                key=""
                // className='column-size-5'
                render={(value) => (
                  <div>{value}</div>
                )}
              />
              <Table.Column
                title='重複検査'
                dataIndex='Expresstion_3'
                key=""
                // className='column-size-5'
                render={(value) => (
                  <div>{value}</div>
                )}
              />

            </Table>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                // disable=
                onClick={() => { this.execution() }}
              >
                随時中性脂肪実行</Button>

              <Button
                type='primary'
                // disable=
                onClick={() => { this.FDparam() }}
              >
                協会けんぽFDﾊﾟﾗﾒｰﾀ作成</Button>
            </div>


          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS3468002);
