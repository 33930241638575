import axios from 'configs/axios';

const API_LIST = {
  // loadScreenData: "/api/contract-info-maintain/condition-copy/getScreenData",
  // fillInput: '/api/contract-info-maintain/condition-copy/contract-type-original',
  CopyExec: '/api/normal-value-setting-maintain/format-copy/CopyExec',
};

const FormatCopyService = {
  // async loadScreenData(data) {
  //   return axios.get(API_LIST.loadScreenData, { data });
  // },
  // async fillInput(data) {
  //   return axios.get(API_LIST.fillInput, { data });
  // },
  async CopyExec(data) {
    console.log("serviceの作成")
    console.log(data)
    return axios.post(API_LIST.CopyExec, data);
  },
};

export default FormatCopyService;