import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/coupled-print-instruction',
  print: '/api/not-input-check-category/coupled-print-instruction/print',
  preview: '/api/not-input-check-category/coupled-print-instruction/preview',
  outputExcel: '/api/not-input-check-category/coupled-print-instruction/excel'
}

const CoupledPrintInstructionService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async print(params) {
    return axios.post(apiPaths.print, params, { responseType: 'blob' })
  },
  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' })
  },
  async outputExcel(params) {
    return axios.post(apiPaths.outputExcel, params, { responseType: 'blob' })
  }

}

export default CoupledPrintInstructionService
