import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Select, Button, Table, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined, MoneyCollectOutlined } from '@ant-design/icons'
import MutualAidSubjectBillingItemMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MutualAidSubjectBillingItemMaintain.actions.js'
import WS1778002_MutualAidSubjectBillingItemItemSettingsEdit from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1778002_MutualAidSubjectBillingItemItemSettingsEdit.jsx'
import WS1778003_MutualAidSubjectBillingItemItemSpecifyEdit from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1778003_MutualAidSubjectBillingItemItemSpecifyEdit.jsx'
import WS1779001_MutualAidSubjectBillingUnitPriceMaintain from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1779001_MutualAidSubjectBillingUnitPriceMaintain.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS1778001_MutualAidSubjectBillingItemMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '共済組合請求項目保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      itemSettingsDataSource: [],
      itemSpecifyDataSource: [],
      AgeIdentifyList: [],
      selectedRow: {}
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    MutualAidSubjectBillingItemMaintainAction.index()
      .then((res) => {
        if (res) {
          this.formRef.current.setFieldsValue({ AddAndImplement: res.AddAndImplement ?? '' })
          this.forceUpdate()
          this.setState({ AgeIdentifyList: res.AgeIdentifyList })

          // 大枠の検査・オプションのデータを取得
          this.getItemSettings()
        }
      })
  }

  /**
   * 大枠の検査・オプションのデータを取得
   * ※左側のデータ
   */
  getItemSettings = () => {
    const params = {
      Li_AddedOrNotImplemented: this.formRef.current.getFieldValue('AddAndImplement')
    }
    MutualAidSubjectBillingItemMaintainAction.getItemSettings(params)
      .then(async (res) => {
        if (res) {
          await this.setState({ itemSettingsDataSource: res })

          if (res.length > 0) {
            if ((this.state.selectedRow?.id ?? 0) === 0) {
              await this.setState({ selectedRow: res[0] })
            }
            // 詳細な検査コードのデータを取得
            await this.getItemSpecify()
          }
        }
      })
  }

  /**
   * 詳細な検査コードのデータを取得
   * ※右側のデータ
   */
  getItemSpecify = () => {
    const params = {
      Li_AddedOrNotImplemented: this.formRef.current.getFieldValue('AddAndImplement'),
      Li_SerialNum: this.state.selectedRow?.serial_number ?? 0
    }
    MutualAidSubjectBillingItemMaintainAction.getItemSpecify(params)
      .then((res) => {
        if (res) {
          this.setState({ itemSpecifyDataSource: res })
        }
      })
  }

  /**
   * [金額]ボタン押下
   * 共済組合請求金額保守 モーダルを開く
   */
  showWS1779001_MutualAidSubjectBillingUnitPriceMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1200,
        component: (
          <WS1779001_MutualAidSubjectBillingUnitPriceMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />)
      }
    })
  }

  /**
   * 共済組合請求項目保守 新規・変更　モーダル
   */
  showWS1778002_MutualAidSubjectBillingItemItemSettingsEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS1778002_MutualAidSubjectBillingItemItemSettingsEdit
            Li_AddAndImplement={this.formRef.current.getFieldValue('AddAndImplement')}
            Li_AgeIdentifyList={this.state.AgeIdentifyList}
            record={record}
            onFinishScreen={async () => {
              await this.closeModal()

              // 大枠の検査・オプションのデータを取得
              await this.getItemSettings()
            }}
          />)
      }
    })
  }

  /**
   * 共済組合請求項目保守 詳細（検査コード） 新規・変更　モーダル
   */
  showWS1778003_MutualAidSubjectBillingItemItemSpecifyEdit = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1778003_MutualAidSubjectBillingItemItemSpecifyEdit
            ItemSettingsSelectedRow={this.state.selectedRow}
            record={record}
            onFinishScreen={async () => {
              await this.closeModal()

              // 詳細な検査コードのデータを取得
              await this.getItemSpecify()
            }}
          />)
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  render() {
    return (
      <div className='mutual-aid-subject-billing-item-maintain'>
        <Card title='共済組合請求項目保守'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <Form.Item
                name='AddAndImplement'
                style={{ marginBottom: 0 }}
              >
                <Select
                  onChange={() => {
                    // 選択の初期化
                    this.setState({ selectedRow: {} })
                    // 大枠の検査・オプションのデータを取得
                    this.getItemSettings()
                  }}
                  style={{ width: 120 }}>
                  <Select.Option value={'C'}>未実施</Select.Option>
                  <Select.Option value={'B'}>オプション</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <div className='box_inner_horizontal'>
              <div style={{ width: '50%' }}>
                {/*  大枠の検査・オプション */}
                <Table
                  size='small'
                  bordered
                  dataSource={this.state.itemSettingsDataSource}
                  rowKey={record => record.id}
                  pagination={this.state.itemSettingsDataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  scroll={{ x: 'max-content', y: 500 }}
                  rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
                  onRow={(record) => {
                    return {
                      onClick: async () => {
                        if (record.id !== this.state.selectedRow?.id) {
                          //　選択変更
                          await this.setState({ selectedRow: record })
                          // 詳細な検査コードのデータを取得
                          await this.getItemSpecify()
                        }
                      }
                    }
                  }}
                >
                  <Table.Column
                    title='連番'
                    dataIndex='serial_number'
                    className='column-size-5'
                    render={(value) => (
                      <div style={{ textAlign: 'right' }}>
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    title='名称'
                    dataIndex='name'
                    className='column-size-30'
                  />
                  <Table.Column
                    title='共通年齢区分'
                    dataIndex='AgeIndentifyCode'
                    render={(value) => {
                      let ageText = ''
                      // 共通年齢区分のタイトルを取得して表示
                      const obj = this.state.AgeIdentifyList.find(item => item.LinkedField === value)
                      if (obj) {
                        ageText = obj.DisplayField
                      }
                      return (
                        <div>
                          {ageText}
                        </div>
                      )
                    }}
                  />
                  <Table.Column
                    width={40}
                    align='center'
                    title={
                      <Button
                        size='small'
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.showWS1778002_MutualAidSubjectBillingItemItemSettingsEdit()
                        }}
                      />
                    }
                    render={(text, record) => (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu-1-1'
                              onClick={() => {
                                this.showWS1778002_MutualAidSubjectBillingItemItemSettingsEdit(record)
                              }}>
                              変更
                            </Menu.Item>
                          </Menu>
                        )}>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )}
                  />
                </Table>
              </div>
              <div style={{ width: '50%' }}>
                {/* 詳細な検査コード */}
                <Table
                  size='small'
                  bordered
                  dataSource={this.state.itemSpecifyDataSource}
                  rowKey={record => record.id}
                  pagination={this.state.itemSpecifyDataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  scroll={{ x: 'max-content', y: 500 }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        // 変更
                        this.showWS1778003_MutualAidSubjectBillingItemItemSpecifyEdit(record)
                      }
                    }
                  }}
                >
                  <Table.Column
                    title='検査コード'
                    dataIndex='exam_code'
                    className='column-size-10'
                    render={(value) => (
                      <div style={{ textAlign: 'right' }}>
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    title='検査名称'
                    dataIndex='exam_name'
                  />
                  <Table.Column
                    width={40}
                    align='center'
                    title={
                      <Button
                        size='small'
                        type='primary'
                        icon={<PlusOutlined />}
                        disabled={!(this.state.selectedRow?.id ?? false)}
                        onClick={() => {
                          this.showWS1778003_MutualAidSubjectBillingItemItemSpecifyEdit()
                        }}
                      />
                    }
                    render={(text, record) => (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu-2-1'
                              onClick={() => {
                                this.showWS1778003_MutualAidSubjectBillingItemItemSpecifyEdit(record)
                              }}>
                              変更
                            </Menu.Item>
                          </Menu>
                        )}>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )}
                  />
                </Table>
              </div>
            </div>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<MoneyCollectOutlined />}
              onClick={() => {
                // this.save()
                this.showWS1779001_MutualAidSubjectBillingUnitPriceMaintain()
              }}
            >
              <span className='btn_label'>
                金額
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1778001_MutualAidSubjectBillingItemMaintain)
