import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Card, Form, Input, Button, Row, Col, message } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1168005_CopyingProcess extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_format: PropTypes.any,
    Li_remarks: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '複写処理';

    this.state = {
      isLoading: false,
      dataSource: [],

    };
    this.executeCopy = this.executeCopy.bind(this)
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      format: this.props.Li_format ? this.props.Li_format : '',
      remarks: this.props.Li_remarks ? this.props.Li_remarks : '',
      Format: "",
      Remarks: "",
    });
    VenusApiRoutesV2.callApi("API001168005001").then(res => {
      console.log(res);
    })
  }

  componentDidUpdate(prv) {

    if (this.props !== prv) {
      this.formRef.current?.setFieldsValue({
        format: this.props.Li_format ? this.props.Li_format : '',
        remarks: this.props.Li_remarks ? this.props.Li_remarks : '',
        Format: "",
        Remarks: "",
      })
    }
  }

  executeCopy(params){
    this.setState({ isLoading: true });

    VenusApiRoutesV2.callApi("API001168005002",params).then(res => {
      if(!res.message && this.props.onFinishScreen) {
        this.props.onFinishScreen();
      }
    });
  }

  render() {
    return (
      <div className="copying-process">
        <Card title="複写処理">
          <Form
            ref={this.formRef}
            onFinish={this.executeCopy}
          >
            <Row gutter={5}>
              <Col span={12} style={{ textAlign: "center" }}>
                <Form.Item name="format" label="複写元" >
                  <Input type="text" />
                </Form.Item>
              </Col>

              <Col span={12} style={{ textAlign: "center" }}>
                <Form.Item name="remarks" label="" >
                  <Input type="text" readOnly={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={5}>
              <Col span={12} style={{ textAlign: "center" }}>
                <Form.Item name="Format" label="複写先" >
                  <Input type="text" />
                </Form.Item>
              </Col>

              <Col span={12} style={{ textAlign: "center" }}>
                <Form.Item name="Remarks" label="" >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={5}>
              <Col span={12} offset={12} style={{ textAlign: "center" }}>
                <Form.Item name="CSVButton">
                  <Button type="primary" htmlType="submit" style={{ float: "right" }}
                  >
                    実施
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1168005_CopyingProcess);
