import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Form, message, Dropdown, Menu, Button } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'

import ModalDraggable from 'components/Commons/ModalDraggable'
import WS3200006_detailSubEdit from 'pages/KS_CooperationRelated/V4CP0004000_ElectronicMedicalRecordsLinkageFrameSet/WS3200006_detailSubEdit.jsx'
import detailSubAction from 'redux/CooperationRelated/ElectronicMedicalRecordsLinkageFrameSet/detailSub.action.js'

class WS3200005_detailSub extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: []
    }
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      tableData: []
    })
    this.index()
  }

  index() {
    let params = {
      examId: this.props.record.exam_id
    }

    detailSubAction.index(params)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          tableData: res ? res : []
        })
        this.forceUpdate()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  /**
   * 新規・編集モーダル
   * @param {} record
   */
  addOrEdit(record) {
    let newFlag = true

    if (record) {
      newFlag = false
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '350px',
        className: '',
        component:
          <WS3200006_detailSubEdit
            record={record}
            newFlag={newFlag}

            onNewData={async (output) => {
              await this.addNewData(output)
              await this.closeModal()
              await this.props.onFinishScreen()
            }}

            onUpdate={async (output) => {
              let params = {
                ...output,
                id: record.id
              }

              await this.editData(params)
              await this.closeModal()
              await this.props.onFinishScreen()
            }}

            onDelete={async (output) => {
              await this.delete(output)
              await this.closeModal()
              await this.props.onFinishScreen()
            }}
          />
      }
    })
  }

  /**
   * 新規登録処理
   * @param {} record
   */
  addNewData(record) {
    let params = {
      ...record,
      examId: this.props.record.exam_id
    }

    detailSubAction.addNewData(params)
      .then((res) => { this.index() })
  }

  /**
   * 編集処理
   * @param {*} record
   */
  editData(record) {
    let params = {
      ...record,
      examId: this.props.record.exam_id
    }

    detailSubAction.editData(params)
      .then((res) => { this.index() })
  }

  /**
   * 削除処理
   * @param {*} record
   */
  delete(record) {
    let params = {
      ...record,
      examId: this.props.record.exam_id
    }
    detailSubAction.delete(params)
      .then((res) => { this.index() })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='detail-sub'>
        <Card title={'電子カルテ連携枠 [' + this.props.record.exam_id + '] : ' + this.props.record.exam_name}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              style={{ cursor: 'pointer' }}
              size='small'
              dataSource={this.formRef.current?.getFieldValue('tableData') ? this.formRef.current?.getFieldValue('tableData') : []}
              loading={this.state.isLoadingTable}
              pagination={false}
              rowKey={(record) => record.id}
              bordered={true}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: async () => {
                    this.addOrEdit(record)
                  }
                }
              }}
            >
              <Table.Column
                title='検査コード'
                dataIndex='exam_code'
                width={90}
                render={(text) => <div style={{ textAlign: 'right' }}>{text}</div>}
              />

              <Table.Column
                title='検査名称'
                dataIndex='exam_name'
              />

              <Table.Column
                width={40}
                title={
                  //新規登録のボタン
                  <Button
                    size='small'
                    type='primary'
                    style={{ textAlign: 'center' }}
                    icon={<PlusOutlined />}
                    onClick={() => { this.addOrEdit() }}
                  >
                  </Button>
                }

                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='編集'
                            onClick={() => { this.addOrEdit(record) }}
                          >編集
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        style={{ width: '100%' }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3200005_detailSub)
