import { message } from "antd";
import testActions from "pages/testActions/testActions";
import ImplementLocationMasterService from "services/SpecificInsureMaintenance/ImplementLocationMaster/ImplementLocationMasterService";

const ImplementLocationMasterAction = {


  getIndex(params) {
    return ImplementLocationMasterService.getIndex(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addLocation(data) {
    return ImplementLocationMasterService.addLocation(data)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  postLocation(data) {
    return ImplementLocationMasterService.postLocation(data)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteLocation(data) {
    return ImplementLocationMasterService.deleteLocation(data)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default ImplementLocationMasterAction;