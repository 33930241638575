import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Row, Col, message, Tooltip, Dropdown, Menu, Tabs, Popconfirm } from "antd"
import { DeleteOutlined, MenuOutlined, EditOutlined } from '@ant-design/icons'
import ContractMasterDetailsAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetails.actions'
import WS1316700_ContractMasterMaintainEdit from './WS1316700_ContractMasterMaintainEdit'
import WS1316800_ContractMasterMaintainConsignor from './WS1316800_ContractMasterMaintainConsignor'
import WS1316900_ContractMasterMaintainImplementation from './WS1316900_ContractMasterMaintainImplementation'
import WS1316026_ContractMasterMaintainAddInspection from "./WS1316026_ContractMasterMaintainAddInspection"

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  // borderRight: 0,
  margin: 0,
  textAlign: 'center',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}

class WS1316600_ContractMasterMaintainInquiry extends React.Component {
  static propTypes = {
    Li_Year: PropTypes.any,
    Li_Name: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = 'V4-TMS01000:契約マスタ保守';
    this.state = {
      childModal: {
        visible: false,
        component: null,
        title: null,
        width: 0,
      },
      tableDataYear: [],
      checkDelete: false,
      isloadfrm: false,
      selectedRow: null,
      dataSourceLength: 0,
      tableData1: [],
      checkActivelyUnitPrice: false,
      checkFiduciaryBusinessSpecificMedica: false,
      checkFiduciaryBusinessMotivatedScree: false,
      activeTab: "0",
      allData: [],
    };
  }
  componentDidMount() {
    console.log(this.props.rowData);
    this.getContentInput()
  }

  getContentInput() {
    this.setState({ isLoadingFm: true })
    ContractMasterDetailsAction.getContentInput({
      year: this.props.rowData.year
      , serial_number: this.props.rowData.serial_number
    }).then(res => {
      console.log(res);
      res["FiduciaryBusinessSpecificMedica"] = parseInt(res["FiduciaryBusinessSpecificMedica"]);
      res["support_with_fiduciary_duties_motive"] = parseInt(res["support_with_fiduciary_duties_motive"]);
      res["servicing_active_support"] = parseInt(res["servicing_active_support"]);
      res["FiduciaryBusinessAdd01Screen"] = parseInt(res["FiduciaryBusinessAdd01Screen"]);
      res["FiduciaryBusinessAdd02Screen"] = parseInt(res["FiduciaryBusinessAdd02Screen"]);
      res["FiduciaryBusinessAdd03Screen"] = parseInt(res["FiduciaryBusinessAdd03Screen"]);
      res["FiduciaryBusinessAdd04Screen"] = parseInt(res["FiduciaryBusinessAdd04Screen"]);
      res["FiduciaryBusinessAdd05Screen"] = parseInt(res["FiduciaryBusinessAdd05Screen"]);
      res["FiduciaryBusinessAdd06Screen"] = parseInt(res["FiduciaryBusinessAdd06Screen"]);
      res["FiduciaryBusinessAdd07Screen"] = parseInt(res["FiduciaryBusinessAdd07Screen"]);
      res["FiduciaryBusinessAdd08Screen"] = parseInt(res["FiduciaryBusinessAdd08Screen"]);
      res["FiduciaryBusinessAdd09Screen"] = parseInt(res["FiduciaryBusinessAdd09Screen"]);
      res["FiduciaryBusinessAdd10Screen"] = parseInt(res["FiduciaryBusinessAdd10Screen"]);
      this.formRef.current?.setFieldsValue(res)
      this.setState({
        allData: res
      })
      res["servicing_active_support"] === 1 ? this.setState({ checkActivelyUnitPrice: false }) : this.setState({ checkActivelyUnitPrice: true })
      res["FiduciaryBusinessSpecificMedica"] === 1 ? this.setState({ checkFiduciaryBusinessSpecificMedica: false }) : this.setState({ checkFiduciaryBusinessSpecificMedica: true })
      res["support_with_fiduciary_duties_motive"] === 1 ? this.setState({ checkFiduciaryBusinessMotivatedScree: false }) : this.setState({ checkFiduciaryBusinessMotivatedScree: true })
      // res["FiduciaryBusinessAdd01Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd01Screen: false }) : this.setState({ checkFiduciaryBusinessAdd01Screen: true })
      // res["FiduciaryBusinessAdd02Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd02Screen: false }) : this.setState({ checkFiduciaryBusinessAdd02Screen: true })
      // res["FiduciaryBusinessAdd03Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd03Screen: false }) : this.setState({ checkFiduciaryBusinessAdd03Screen: true })
      // res["FiduciaryBusinessAdd04Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd04Screen: false }) : this.setState({ checkFiduciaryBusinessAdd04Screen: true })
      // res["FiduciaryBusinessAdd05Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd05Screen: false }) : this.setState({ checkFiduciaryBusinessAdd05Screen: true })
      // res["FiduciaryBusinessAdd06Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd06Screen: false }) : this.setState({ checkFiduciaryBusinessAdd06Screen: true })
      // res["FiduciaryBusinessAdd07Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd07Screen: false }) : this.setState({ checkFiduciaryBusinessAdd07Screen: true })
      // res["FiduciaryBusinessAdd08Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd08Screen: false }) : this.setState({ checkFiduciaryBusinessAdd08Screen: true })
      // res["FiduciaryBusinessAdd09Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd09Screen: false }) : this.setState({ checkFiduciaryBusinessAdd09Screen: true })
      // res["FiduciaryBusinessAdd10Screen"] === 1 ? this.setState({ checkFiduciaryBusinessAdd10Screen: false }) : this.setState({ checkFiduciaryBusinessAdd10Screen: true })
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    }).finally(() => this.setState({ isLoadingFm: false }))
  }

  deleteCommissionedOriginalInput() {
    let param = {
      id: this.formRef.current?.getFieldValue("id"),
      serial_number: this.props.rowData.serial_number,
      year: this.props.rowData.year
    }
    console.log(param);
    ContractMasterDetailsAction.deleteCommissionedOriginalInput(param)
      .then((res) => {
        this.props.onFinishScreen()
        this.props.closeModal()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          console.log(res);
          message.error('エラーが発生しました');
        }
      })
  }

  tabChange(e) {
    this.setState({ activeTab: e })
    this.formRef.current?.setFieldsValue(this.state.allData)
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        value: ''
      },
    });
  }


  render() {
    return (
      <div className="contract-master-maintain-inquiry">
        <Card className="mb-2" title="契約保守明細">
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top_modal'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 650,
                            component: (
                              <WS1316800_ContractMasterMaintainConsignor
                                onRowData={this.props.rowData}
                                onFinishScreen={output => {
                                  this.closeModal();
                                }}
                              />
                            )
                          }
                        })}
                    >
                      委託元
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 650,
                            component: (
                              <WS1316026_ContractMasterMaintainAddInspection
                                allData={this.state.allData}
                                onFinishScreen={output => {
                                  this.closeModal();
                                }}
                                getContentInput={output => { this.getContentInput() }}
                              />
                            )
                          }
                        })}
                    >
                      追加検査
                    </Menu.Item>
                    <Menu.Item key='3' style={{ width: '100%' }}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 650,
                            component: (
                              <WS1316900_ContractMasterMaintainImplementation
                                allData={this.state.allData}
                                onFinishScreen={output => {
                                  this.closeModal();
                                  this.getContentInput()
                                }}
                              />
                            )
                          }
                        })}
                    >
                      同時実施
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />} />
            </Dropdown>
          </Tooltip>
          <Form ref={this.formRef}>
            <Tabs
              activeKey={this.state.activeTab}
              onChange={(e) => this.tabChange(e)}
            >
              <Tabs.TabPane tab='基本' key={"0"}>
                {/* 基本 */}
                <Row>
                  <Col span={12}>
                    <div className="div_label">契約番号</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("contract_number")}</div>
                    <div className="div_label">契約形態</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("contract_type") === 1 ? '個別' : '集合'}</div>

                  </Col>
                  <Col span={12}>
                    <div className="div_label">契約名称</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("contract_name")}</div>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab='特定健診' key={"1"}>
                {/* 特定健診 */}
                <Row>
                  <Col span={12}>
                    <div className="div_label">取りまとめ</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("name")}</div>
                    <div className="div_label">委託者</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("representative_consignor_name")}</div>
                  </Col>
                  <Col span={12}>
                    <div className="div_label">受託者</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("representative_trustee_name")}</div>
                    <div className="div_label">特定健診</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("representative_trustee_name") === 0 ? '対象外' : '対象'}</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={4}>
                    <div style={{ ...styleDivTitle, borderRight: '1px solid #FFFFFF' }}>項目</div>
                    <div style={styleDivTitle}>個別健診</div>
                    <div style={{ ...styleDivTitle, borderBottom: '1px solid #FFFFFF', }}>集団健診</div>
                  </Col>
                  <Col span={4}>
                    <div style={styleDivTitle}>基  本</div>
                    <Form.Item style={styleFormItem}>
                      <div>{this.formRef.current?.getFieldValue("basic_individual_medical_exam_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div>{this.formRef.current?.getFieldValue("unit_price_exam_basic_population_health"?.toLocaleString())}</div>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <div style={styleDivTitle}>貧  血</div>
                    <Form.Item style={styleFormItem}>
                      <div>{this.formRef.current?.getFieldValue("anemia_individual_medical_exam_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div>{this.formRef.current?.getFieldValue("unit_price_exam_anemia_population_health")?.toLocaleString()}</div>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <div style={styleDivTitle}>心電図</div>
                    <Form.Item style={styleFormItem}>
                      <div>{this.formRef.current?.getFieldValue("electrocardiograph_individual_medical_exam_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div>{this.formRef.current?.getFieldValue("unit_price_diagnosis_electrocardiogram_population_ken")?.toLocaleString()}</div>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <div style={styleDivTitle}>眼  底</div>
                    <Form.Item style={styleFormItem}>
                      <div>{this.formRef.current?.getFieldValue("fundus_individual_medical_exam_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                    <Form.Item style={styleFormItemBottom}>
                      <div>{this.formRef.current?.getFieldValue("fundus_group_medical_exam_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <div style={styleDivTitle}>ｸﾚｱﾁﾆﾝ</div>
                    <Form.Item style={{ ...styleFormItem, borderRight: '1px solid #808080', }}>
                      <div>{this.formRef.current?.getFieldValue("h30_creatinine_individual_medical_exam_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                    <Form.Item style={{ ...styleFormItemBottom, borderRight: '1px solid #808080', }}>
                      <div>{this.formRef.current?.getFieldValue("h30_creatinine_population_checkup_unit_price")?.toLocaleString()}</div>
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab='積極的' key={"2"}>
                {/* 積極的 */}
                <Row>
                  <Col span={12}>
                    <div className="div_label">積極的</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("servicing_active_support") === 0 ? '対象外' : '対象'}</div>

                    <div className="div_label">初回時全額徴収方式</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("aggressive_counter_burden_hikisa_f") === 0 ? '無し' : '有り'}</div>
                    <div className="div_label">単価</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("actively_unit_price")?.toLocaleString() + '円'}</div>
                    <div className="div_label">実施ポイント数</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("active_constant_support_enforcement_point") + 'ポイント'}</div>
                  </Col>
                  <Col span={12}>
                    <div className="div_label">初回</div>
                    {this.formRef.current?.getFieldValue("actively_payment_division_01") !== 0 ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("actively_payment_division_01") === 1 ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_01")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_01")?.toLocaleString() + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">初①</div>
                    {this.formRef.current?.getFieldValue("actively_payment_division_04") !== '0' ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("actively_payment_division_04") === "1" ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_04")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_04")?.toLocaleString() + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">継続</div>
                    {this.formRef.current?.getFieldValue("actively_payment_division_02") !== 0 ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("actively_payment_division_02") === 1 ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_02")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_02") + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">終了</div>
                    {this.formRef.current?.getFieldValue("actively_payment_division_03") !== 0 ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("actively_payment_division_03") === 1 ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_03")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("aggressive_price_rate_03")?.toLocaleString() + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">備考</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("actively_remarks")}</div>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab='動機付' key={"3"}>
                {/* 動機付 */}
                <Row>
                  <Col span={12}>
                    <div className="div_label">動機付</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("support_with_fiduciary_duties_motive") === 0 ? '対象外' : '対象'}</div>
                    <div className="div_label">単価</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("motivation_unit_price")?.toLocaleString() + '円'}</div>
                    <div className="div_label">初回時全額徴収方式</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("motivation_counter_burden_hikisa_f") === 0 ? '無し' : '有り'}</div>
                  </Col>
                  <Col span={12}>
                    <div className="div_label">初回</div>
                    {this.formRef.current?.getFieldValue("payment_division_motivation_01") !== 0 ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("payment_division_motivation_01") === 1 ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("price_motivation_rate_01")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("price_motivation_rate_01")?.toLocaleString() + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">初①</div>
                    {this.formRef.current?.getFieldValue("payment_division_motivation_04") !== 0 ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("payment_division_motivation_04") === 1 ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("price_motivation_rate_04")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("price_motivation_rate_04")?.toLocaleString() + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">終了</div>
                    {this.formRef.current?.getFieldValue("motivation_payment_division_02") !== "" ?
                      <div className="div_data">
                        {this.formRef.current?.getFieldValue("motivation_payment_division_02") === "1" ?
                          '定額指定' + '　' + this.formRef.current?.getFieldValue("price_motivation_rate_02")?.toLocaleString() + '円' :
                          '定率指定' + '　' + this.formRef.current?.getFieldValue("price_motivation_rate_02")?.toLocaleString() + '%'}
                      </div> :
                      <div className="div_data">--</div>
                    }
                    <div className="div_label">備考</div>
                    <div className="div_data">{this.formRef.current?.getFieldValue("motivation_remarks")}</div>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
            {/* <div style={{ borderBottom: '1px solid #ccc', marginTop: '10px' }} /> */}
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 550,
                      component: (
                        <WS1316700_ContractMasterMaintainEdit
                          activeTab={this.state.activeTab}
                          data={this.state.allData}
                          rowData={this.props.rowData}
                          onFinishScreen={output => {
                            this.getContentInput();
                            this.closeModal();
                            this.props.onFinishScreen()
                          }}
                        />
                      )
                    }
                  })
                }
              >編集</Button>
              {this.state.activeTab === '0' ?
                <Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  cancelButtonProps={{ style: { height: '32px' } }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  onConfirm={() => this.deleteCommissionedOriginalInput()}
                  okText='削除'
                  cancelText='キャンセル'
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                  >
                    削除
                  </Button>
                </Popconfirm>
                : null
              }
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
            this.setState({ isloadfrm: false })
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1316600_ContractMasterMaintainInquiry);
