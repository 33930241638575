import { message } from 'antd'
import PersonRequireExamIntroduceLetterService from 'services/IntroductionLetter/PersonRequireExamIntroduceLetter/PersonRequireExamIntroduceLetterService'

const PersonRequireExamIntroduceLetterAction = {

  getjudgeQuery(data) {
    return PersonRequireExamIntroduceLetterService.getjudgeQuery(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveMaintain(data) {
    return PersonRequireExamIntroduceLetterService.saveMaintain(data)
  }
}

export default PersonRequireExamIntroduceLetterAction
