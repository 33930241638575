import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Select, Input, Button } from 'antd'
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import ModalDraggable from "components/Commons/ModalDraggable"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import MunicipalMutualAidAssociationMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MunicipalMutualAidAssociationMaintain.actions.js'


class WS1789002_MutualAidMaster extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '共済マスタ'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      addCount: 0,
      disabledSaveBtn: true,
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    MunicipalMutualAidAssociationMaintainAction.getMutualAidMaster()
      .then((res) => {
        this.setState({
          dataSource: res,
          addCount: 0,
        })
        this.formRef.current?.setFieldsValue({
          tableData: res
        })
      })
  }

  /**
   * 新規追加、行追加
   */
  add = async () => {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      selectedRow: { ...newRow },
      addCount: idCount,
    })
    this.formRef.current?.setFieldsValue({
      tableData: data
    })
    this.forceUpdate()
  }

  /**
   *  削除
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   */
  delete = (id) => {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: data
    })
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * それぞれの値の変更
   * @param {*} id
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(id, field, value) {

    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: tempArr
    })
  }

  /**
   * 区分のselectが変更された場合
   */
  onChangeSelect = (e, record) => {
    this.updateDatasource(record.id, 'site_findings_division', e)
  }

  /**
   * 保存ボタンが押された場合の処理
   */
  saveData() {
    let params = {
      data: this.state.dataSource
    }

    MunicipalMutualAidAssociationMaintainAction.saveMutualAidMaster(params)
      .then((res) => {
        if (res) {
          this.getScreenData()
        }
      })
  }

  /**
   * 健診マスタの情報を取得する
   */
  getMedicalCheckMaster(record, value) {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, record.id)

    // 変更された項目に、所見項目と共済コードが設定されているか
    let findngItemsCode = data[index].findng_items_code ?? 0;
    let contentCode = data[index].content_code ?? 0;

    if (findngItemsCode > 0 && contentCode > 0) {
      let params = {
        ...record
      }

      MunicipalMutualAidAssociationMaintainAction.getMedicalCheckMaster(params)
        .then((res) => {
          if (res) {
            this.updateDatasource(record.id, 'medical_check_master', res)
          }
        })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className='mutual-aid-master'>
        <Card title='共済マスタ'>

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(250) }}
            >
              <Table.Column
                title='区分'
                dataIndex='site_findings_division'
                className='column-size-10'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'site_findings_division']} >
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => this.onChangeSelect(e, record)}
                    >
                      <Select.Option value={1}>部位</Select.Option>
                      <Select.Option value={2}>所見</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              />

              <Table.Column
                title='所見項目'
                dataIndex='findng_items_code'
                className='column-size-10'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'findng_items_code']} >
                    <Input
                      maxLength='5'
                      style={{ width: '100%', textAlign: 'right' }}
                      onBlur={async (e) => {
                        await this.updateDatasource(record.id, 'findng_items_code', e.target.value)
                        await this.getMedicalCheckMaster(record, e.target.value)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='共済コード'
                dataIndex='content_code'
                className='column-size-10'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'content_code']} >
                    <Input
                      maxLength='7'
                      style={{ width: '100%', textAlign: 'right' }}
                      onBlur={async (e) => {
                        await this.updateDatasource(record.id, 'content_code', e.target.value)
                        await this.getMedicalCheckMaster(record, e.target.value)
                      }}
                    />
                  </Form.Item>
                )} />

              <Table.Column
                title='共済略称'
                dataIndex='short_name'
                className='column-size-30'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'short_name']} >
                    <Input
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        this.updateDatasource(record.id, 'short_name', e.target.value)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='共済内容'
                dataIndex='content'
                className='column-size-30'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'content']} >
                    <Input
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        this.updateDatasource(record.id, 'content', e.target.value)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='健診マスタ'
                dataIndex='medical_check_master'
              />

              <Table.Column
                width={40}
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      onClick={() => this.add()}
                      type='primary'
                      icon={<PlusOutlined />}
                    />
                  </div>
                }
                render={(text, record, index) => {
                  return (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.delete(record.id)
                      }}
                    />
                  )
                }}
              />
            </Table>

            <Form.Item >
              <Button
                type='primary'
                icon={<SaveOutlined />}
                style={{ float: 'right', marginTop: '1em' }}
                disabled={this.state.disabledSaveBtn}
                onClick={() => {
                  this.saveData()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1789002_MutualAidMaster)