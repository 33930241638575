import React from "react";
import { connect } from "react-redux";
import { download_file } from "helpers/CommonHelpers";
import { Card, Form, Input, Select, Upload, Button, message, Spin, Space } from "antd";
import InputOutputAction from 'redux/SystemMaintenance/UserParamMaintain/InputOutput.action'
import { DeleteOutlined, DownloadOutlined, UploadOutlined, MoreOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import axios from 'configs/axios'

class WS0449009_InputOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '入出力';

    this.state = {
      isLoadingData: false,
      infom: '',
      value: 0,
      InputAndOutput: 0,

      filename: '',
      path: '',
      Li_Format: this.props.Li_Format,
      InputAndOutputFile: this.props.Li_Format
    };
  }

  componentDidMount() {
    // this.callAPILoadScreenData()
  }



  // callAPILoadScreenData() {
  //   this.setState({ isLoadingData: true })
  //   let params = {
  //     Li_Format: this.props.Li_Format
  //   }
  //   InputOutputAction.getScreenData(params)
  //     .then((res) => {

  //       this.setState({
  //         InputAndOutput: res.InputAndOutput,
  //         InputAndOutputFile: res.InputAndOutput_1,
  //       })

  //     }).finally(() => this.setState({ isLoadingData: false }))
  // }

  // upload() {
  //   this.setState({ isLoadingData: true })
  //   InputOutputAction.upload()
  //     .then(() => { }).finally(() => this.setState({ isLoadingData: false }))

  // }


  onFinish() {
    const params = { ...this.state }

    if (this.state.InputAndOutput === 1) {

      InputOutputAction.Input_F12(params)
        .then((res) => {
          this.props.onFinishScreen()
        })
    } else {

      InputOutputAction.runBtn_F12(params)
        .then((res) => {
          download_file(res);
          this.props.onFinishScreen()
        })
    }
  }


  render() {

    return <>

      <div className="input-output">
        <Card title="入出力" className="mb-2">
          <Spin spinning={this.state.isLoadingData}>

            <Space direction="horizontal"
              style={{ display: 'flex', }}>
              {'選択　　　'}
              <Select
                defaultValue={this.state.InputAndOutput}
                onChange={(value) => {

                  this.setState({ InputAndOutput: value })

                  // 入力は呼び元からのパラメータを設定
                  if (value === 1) {
                    this.setState({ InputAndOutputFile: this.state.Li_Format })
                  }

                }}
              >
                <Select.Option value={0}>出力</Select.Option>
                <Select.Option value={1}>入力</Select.Option>
              </Select>
            </Space>

            <Space direction="horizontal"
              style={{ display: 'flex', }}>
              {'ファイル名'}
              <Input type="text"
                defaultValue={this.state.Li_Format}
                readOnly={this.state.InputAndOutput === 1 ? true : false}
                value={this.state.InputAndOutputFile}
                onChange={(e) => { this.setState({ InputAndOutputFile: e.target.value }) }}>
              </Input>
              {(this.state.filename !== '' && this.state.path !== '') ? <div style={{ color: '#a0b9c8' }}>{'アップロードが完了しました'}</div> : null}
            </Space>

            <br />

            {this.state.InputAndOutput === 1 ?
              <Upload
                headers={{
                  'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
                }}
                withCredentials={true}
                showUploadList={false}

                action='/api/input-output/upload'

                onChange={(info) => {
                  this.setState({ isLoadingData: true, filename: '', path: '' })

                  if (info.file.status === 'uploading') {
                    this.setState({ isLoadingData: true })
                  } else {
                    if (info.file.status === 'done') {
                      console.log(info.file.response)

                      this.setState({ filename: info.file.name, path: info.file.response })

                    } else if (info.file.status === 'error') {
                      message.error('エラーが発生しました')

                    }
                    this.setState({ isLoadingData: false })
                  }
                }
                }>
                <Button
                  type='primary'
                  icon={<UploadOutlined />} >
                  アップロード
                </Button>

              </Upload>

              : null}
            <Button type="primary"
              style={{ float: 'right' }}
              onClick={() => this.onFinish()}>実行</Button>

          </Spin>
        </Card>

      </div>

    </>

  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0449009_InputOutput);
