import { message } from 'antd'
import AddDateService from 'services/Others/AddDate/AddDateService'

const AddDateAction = {
  loadScreenData(data) {
    return AddDateService.loadScreenData(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default AddDateAction
