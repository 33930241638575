import axios from "configs/axios";

const APP_LIST = {
  index: "/api/convert-tbl-sub-all/index",
  save: "/api/convert-tbl-sub-all/save",
  gettestresult: "/api/convert-tbl-sub-all/gettestresult",
  delete: "/api/convert-tbl-sub-all/delete",
};

const ConvertTblSubAllService = {
  async index(params) {
    console.log('通過')
    return axios.get(APP_LIST.index, { params });
  },
  async getSiteClassification(params) {
    return axios.get(APP_LIST.getSiteClassification, params)
  },
  async save(params) {
    console.log('通過')
    return axios.post(APP_LIST.save, params).then((res) => {
      return res
    }).catch((err) => {
      throw err.response.data
    })
  },

  async delete(params) {

    return axios.post(APP_LIST.delete, params).then((res) => {
      return res
    }).catch((err) => {
      throw err.response.data
    })
  },

  async gettestresult(params) {
    console.log('通過')
    return axios.post(APP_LIST.gettestresult, params)
  },
  // async getScreenData() {
  //     return axios.get(APP_LIST.getScreenData);
  // },
  // async saveData(params) {
  //     return axios.post(APP_LIST.saveData, params);
  // },
  // async deleteData(params) {
  //     return axios.delete(APP_LIST.deleteData, { params });
  // },
  // async changeexamcode(params) {
  //     return axios.post(APP_LIST.changeexamcode, params);
  // }
};

export default ConvertTblSubAllService;
