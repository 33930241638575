import axios from "configs/axios";

const apiPaths = {
  getTreeData: "/api/implementation-division-master/implement-division-master",
  deleteDivisionInfo: "/api/implementation-division-master/implement-division-master/delete",
  addNewDivision: "/api/implementation-division-master/implement-division-master/new-division-info",
  postDivision: "/api/implementation-division-master/implement-division-master/post-division"
};

const ImplementDivisionMasterService = {
  async getTreeData() {
    return axios.get(apiPaths.getTreeData);
  },

  async deleteDivisionInfo(params) {
    return axios.delete(apiPaths.deleteDivisionInfo, { params });
  },

  async addNewDivision(params) {
    return axios.post(apiPaths.addNewDivision, params);
  },

  async postDivision(params) {
    return axios.post(apiPaths.postDivision, params);
  },

};


export default ImplementDivisionMasterService;