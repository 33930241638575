import React from "react";
import { Card, Checkbox, Form, Popconfirm, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Color from "constants/Color";
import InsurerNumberConversionMasterAction from 'redux/SpecificInsureMaintenance/InsurerNumberConversionMaster/InsurerNumberConversionMaster.action'
import InsurerNumberConversionMasterEdit from 'pages/TM_SpecificInsureMaintenance/InsurerNumberConversionMaster/InsurerNumberConversionMasterEdit'
import ModalDraggable from "components/Commons/ModalDraggable"

const width = '650px'

class InsurerNumberConversionMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
  }

  componentDidMount() {
    this.getScreenData()
  }

  /**
   * 初期表示
   */
  getScreenData() {
    InsurerNumberConversionMasterAction.getScreenData()
      .then((res) => {
        this.setState({
          dataSource: res
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  /**
  * モーダルを閉じる
  */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }


  render() {
    return (
      <div className="insurer-number-conversion-master">
        <Card className="mb-2" title="保険者番号変換マスタ">
          <Form ref={this.formRef}>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => (record.id)}
              onRow={(record, rowIndex) => ({
                //ダブルクリックイベント
                onDoubleClick: () =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: width,
                      component:
                        <InsurerNumberConversionMasterEdit
                          newFlag={false}
                          record={record}
                          onFinishScreen={(output) => {
                            this.getScreenData()
                            this.closeModal()
                          }}
                        />
                    },
                  })
              })}
            >
              <Table.Column
                title='変更元保健番号'
                dataIndex='conversion_insurer_number_from'
              />
              <Table.Column
                title='保険者名称'
                dataIndex='insurer_name_from'
              />
              <Table.Column
                title='変換後保険者番号'
                dataIndex='conversion_insurer_number_to'
              />
              <Table.Column
                title='保険者名称'
                dataIndex='insurer_name_to'
              />
              <Table.Column
                fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: width,
                          component:
                            <InsurerNumberConversionMasterEdit
                              newFlag={true}
                              onFinishScreen={(output) => {
                                this.getScreenData()
                                this.closeModal()
                              }}
                            />
                        },
                      })
                    }} />}
                dataIndex=""
                align="center"
                width={30}
                render={(value, record, index) => (
                  <Button
                    size="small"
                    icon={<MoreOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: width,
                          component:
                            <InsurerNumberConversionMasterEdit
                              newFlag={false}
                              record={record}
                              onFinishScreen={(output) => {
                                this.getScreenData()
                                this.closeModal()
                              }}
                            />
                        },
                      })
                    }}
                  />

                )} />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default InsurerNumberConversionMaster;
