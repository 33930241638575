import axios from "configs/axios";

const apiPaths = {
  index: "/api/implement-agencies-master/implement-agencies-master/",
  delete: "/api/implement-agencies-master/implement-agencies-master/delete",
  // addNewDivision: "/api/implementation-division-master/implement-division-master/new-division-info",
  save: "/api/implement-agencies-master/implement-agencies-master/save"
};

const ImplementAgenciesMasterService = {
  async index() {
    return axios.get(apiPaths.index);
  },

  async delete(params) {
    return axios.post(apiPaths.delete, { params });
  },

  // async addNewDivision(params) {
  //   return axios.post(apiPaths.addNewDivision, params);
  // },

  async save(params) {
    return axios.post(apiPaths.save, params);
  },

};


export default ImplementAgenciesMasterService;