import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getScreenDataConditionSettingAction, saveButtonConditionSettingAction, deleteButtonConditionSettingAction } from "redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions";
import { Card, Form, Checkbox, Select, Button, message } from "antd";
import { SaveOutlined } from '@ant-design/icons'
import { ModalConfirm } from "components/Commons/ModalConfirm";

const smGrid = {
  labelCol: { style: { width: 55 } },
}

class WS0310120_ConditionSettingMock extends React.Component {
  static propTypes = {
    Li_ContractStartDate: PropTypes.any,
    Li_SetCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '条件設定';

    this.state = {
      condition_relationData: [],
      CourseConditionHospitalOutData: [],
      CourseConditionNTsugikenmiData: [],
      condition_age_identify_cdData: [],
      isCourseConditionPresence: true,
      isChangeValue: false,
      initObj: {
        CourseConditionPresence: 0,
        condition_gender: '',
        condition_relationship: '',
        condition_am_pm: '',
        CourseConditionHospitalOut: '',
        CourseConditionNTsugikenmi: '',
        condition_age_identify_code: '',
        deleteButtonFlag: true, // hidden button delete,
        id: ''
      }
    };
  }

  componentDidMount = () => {
    this.callAPILoadData({
      Li_ContractStartDate: this.props.Li_ContractStartDate,
      Li_SetCode: this.props.Li_SetCode,
      Li_ContractOrgs: this.props.Li_ContractOrgCode,
      Li_ContractNum: this.props.Li_ContractNum,
      Li_ContractType: this.props.Li_ContractType,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.callAPILoadData({
        Li_ContractStartDate: this.props.Li_ContractStartDate,
        Li_SetCode: this.props.Li_SetCode
      })
    }
  }

  callAPILoadData = (params) => {
    getScreenDataConditionSettingAction(params)
      .then(res => {
        if (res.data) {
          let data = res.data;
          let objTemp = {
            id: data.id,
            condition_gender: String(data.condition_gender),
            condition_relationship: data.conditions_relationship,
            condition_am_pm: data.condition_am_pm,
            CourseConditionHospitalOut: String(data.CourseConditionHospitalOut),
            CourseConditionNTsugikenmi: String(data.CourseConditionNTsugikenmi),
            condition_age_identify_code: String(data.conditions_age_identification_code),
            CourseConditionPresence: data.CourseConditionPresence,
            deleteButtonFlag: data.deleteButtonFlag
          }
          this.formRef?.current?.setFieldsValue({ ...objTemp })

          this.setState({
            condition_relationData: data.condition_relationData,
            CourseConditionHospitalOutData: data.CourseConditionHospitalOutData,
            CourseConditionNTsugikenmiData: data.CourseConditionNTsugikenmiData,
            condition_age_identify_cdData: data.condition_age_identify_cdData,
            isCourseConditionPresence: data.CourseConditionPresence,
            initObj: {
              ...this.state.initObj,
              ...objTemp
            }
          });
        }
      })
  }

  saveAndUpdateData() {
    const params = {
      contractType: this.props.Li_ContractType,
      contractOrgCode: this.props.Li_ContractOrgCode,
      contractStartDate: this.props.Li_ContractStartDate,
      contractNum: this.props.Li_ContractNum,
      setCode: this.props.Li_SetCode,
      courseConditions: this.props.Li_CourseConditions,

      condition_gender: this.formRef.current?.getFieldValue('condition_gender'),
      condition_relationship: this.formRef.current?.getFieldValue('condition_relationship'),
      CourseConditionHospitalOut: this.formRef.current?.getFieldValue('CourseConditionHospitalOut'),
      condition_am_pm: this.formRef.current?.getFieldValue('condition_am_pm'),
      conditions_next_medical_exam: this.formRef.current?.getFieldValue('CourseConditionNTsugikenmi'),
      condition_age_identify_code: this.formRef.current?.getFieldValue('condition_age_identify_code'),
    }

    saveButtonConditionSettingAction(params)
      .then((res) => {
        message.success('保存しました');
        this.props.onFinishScreen()
      })
      .catch(error => {
        this.setState({ isLoadingContractTerm: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  deleteButtonConditionSetting() {
    const params = {
      Li_ContractStartDate: this.props.Li_ContractStartDate,
      Li_SetCode: this.props.Li_SetCode,
      Li_ContractOrgs: this.props.Li_ContractOrgCode,
      Li_ContractNum: this.props.Li_ContractNum,
      Li_ContractType: this.props.Li_ContractType,
    }

    deleteButtonConditionSettingAction(params)
      .then((res) => {
        message.success('削除しました');

        this.props.onFinishScreen()
      })
      .catch(error => {
        this.setState({ isLoadingContractTerm: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className="condition-setting">
        <Card title="契約内容設定（モック）">
          <Form ref={this.formRef} onFinish={this.onFinish} >
            <Form.Item
              name="CourseConditionPresence"
              label="条件"
              valuePropName="checked"
              {...smGrid}
            >
              <Checkbox
                onChange={(e) => this.setState({ isCourseConditionPresence: e.target.checked, isChangeValue: e.target.checked })}
              >
                有り
              </Checkbox>
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                name="condition_gender"
                label="性別"
                {...smGrid}
              >
                <Select
                  style={{ width: '100px' }}
                  disabled={!this.state.isCourseConditionPresence}
                  allowClear
                >
                  <Select.Option value='1'>男</Select.Option>
                  <Select.Option value='2'>女</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="condition_relationship"
                label="続柄"
                {...smGrid}
              >
                <Select
                  style={{ width: '100px' }}
                  disabled={!this.state.isCourseConditionPresence}
                  allowClear
                >
                  {this.state.condition_relationData?.map((item, index) => (
                    <Select.Option
                      key={"続柄" + index}
                      value={item.key}
                    >
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="condition_am_pm"
                label="AMPM"
                {...smGrid}
              >
                <Select
                  style={{ width: '100px' }}
                  disabled={!this.state.isCourseConditionPresence}
                  allowClear
                >
                  <Select.Option value="AM">AM</Select.Option>
                  <Select.Option value="PM">PM</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="condition_first_time"
                label='初回'
                {...smGrid}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={!this.state.isCourseConditionPresence}
                />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal'>
              <Form.Item
                name="CourseConditionHospitalOut"
                label="院内"
                {...smGrid}
              >
                <Select
                  style={{ width: '100px' }}
                  disabled={!this.state.isCourseConditionPresence}
                  allowClear
                >
                  {this.state.CourseConditionHospitalOutData?.map((item, index) => (
                    <Select.Option
                      key={index + item.value}
                      value={item.key}
                    >
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="CourseConditionNTsugikenmi"
                label="ｎ次"
                {...smGrid}
              >
                <Select
                  style={{ width: '100px' }}
                  disabled={!this.state.isCourseConditionPresence}
                  allowClear
                >
                  {this.state.CourseConditionNTsugikenmiData?.map((item, index) => (
                    <Select.Option
                      key={"ｎ次" + index}
                      value={item.key}
                    >
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="condition_age_identify_code"
                label="年齢"
                {...smGrid}
              >
                <Select
                  style={{ minWidth: '300px' }}
                  disabled={!this.state.isCourseConditionPresence}
                  allowClear
                >
                  {this.state.condition_age_identify_cdData?.map((item, index) => (
                    <Select.Option
                      key={"年齢" + index}
                      value={item.key}
                    >
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type="primary"
                hidden={!this.state.isCourseConditionPresence}
                onClick={() => {
                  ModalConfirm({
                    content: '削除を実施しますか？',
                    okText: 'はい',
                    cancelText: 'いいえ',
                    styleIconModal: { color: '#006DC9' },
                    onOk: () => {
                      this.deleteButtonConditionSetting()
                    }
                  })
                }}>
                <span className='btn_label'>
                  条件解除
                </span>
              </Button>

              <Button
                type="primary"
                hidden={!this.state.isCourseConditionPresence}
                icon={<SaveOutlined />}
                onClick={() => this.saveAndUpdateData()}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0310120_ConditionSettingMock);
