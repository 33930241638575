import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Button, Space, Modal } from 'antd'
import WS0460001_CsvCreateParamMaintain from '../V4MS9901100_CsvCreateParamMaintain/WS0460001_CsvCreateParamMaintain'
import FormInquiryAction from 'redux/ResultOutput/MedicalExamDataOutputCsv/FormInquiry.action'
class WS0784003_FormInquiry extends React.Component {
  static propTypes = {
    Lo_Code: PropTypes.any,
    Lo_DocumentName: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '帳票照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      selectedRows: [],
      isLoading: false,
      indexTable: 0,
      flg: 0,
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  componentWillUnmount() {
    if (this.state.flg === 0) {
      if (this.props.onFinishScreen) {
        this.props.onFinishScreen({
          flg: 1,
        })
      }
    }
  }

  getScreenData() {
    this.setState({ isLoading: true })
    FormInquiryAction.GetScreenData()
      .then((response) => {
        this.setState({
          dataSource: response,
          selectedRows: [response[0]],
          indexTable: 0,
        })
        // document.getElementById('event_2').focus()
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='form-inquiry'>
        <Card title='帳票照会'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            rowClassName={(record, index) =>
              record.id === this.state.selectedRows[0]?.id
                ? 'table-row-light'
                : ''
            }
            scroll={{ y: 500 }}
            onRow={(record, index) => ({
              onDoubleClick: () => {
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    Lo_Code: record.Code,
                    Lo_DocumentName: record.DocumentName,
                    flg: 0,
                  })
                  this.setState({ flg: 1 })
                }
              }
            })}
          >
            <Table.Column
              title='No'
              dataIndex='Code'
              width={60}
              render={(value) => <div style={{ textAlign: 'right' }}>{value}</div>}
            />
            <Table.Column
              title='帳票名'
              dataIndex='DocumentName'
              width={150}
            />
          </Table>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 1200,
                    component: (
                      <WS0460001_CsvCreateParamMaintain
                        modalFlag={true}
                        onFinishScreen={(output) => {
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              }}
            >
              <span className='btn_label'>
                設定
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0784003_FormInquiry)
