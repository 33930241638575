import axios from 'configs/axios'

const API_LIST = {
  index: '/api/spread-input/inspect-cmt-search-query',
}

const InspectCmtSearchQueryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default InspectCmtSearchQueryService
