import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Select, Table, } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0413001_MasterListOutputFindingInfo extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力[所見情報]';

    this.state = {
      findingsCodeF: 0,
      findingsCodeT: 0,
      findingsClassify: [],
      dataSource: [],
      indexTable: 0
    };
  }


  componentDidMount() {
    this.setState({ isLoadingTable: true })

    VenusApiRoutesV2.callApi("API000413001001", {})
      .then((res) => {
        this.setState({
          findingsCodeF: res.FindingsCodeF,
          findingsCodeT: res.FindingsCodeT,
          findingsClassify: res.FindingsClassify,
          isLoadingTable: false,
        });

        this.formRef.current.setFieldsValue({
          findingsCodeF: res.FindingsCodeF,
          findingsCodeT: res.FindingsCodeT,
          findingsClassify: res.FindingsClassify,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    VenusApiRoutesV2.callApi("API000413001002", {})
      .then((res) => {
        this.setState({
          dataSource: res? res : [],
          isLoadingTable: false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) { }
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="master-list-output-finding-info">
        <Card className="mb-2" title="マスタ一覧出力[所見情報]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="findingsCodeF"
              label="所見コード[F]"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="findingsCodeT"
              label="所見コード[T]"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="findingsClassify"
              label="所見分類"
            >
              <Select>
                <Select.Option value="">\</Select.Option>

              </Select>
            </Form.Item>

          </Form>

          <Table bordered 
            size="small"
            dataSource={this.state.dataSource} 
            loading={this.state.isLoadingTable}
            pagination={true}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="所見分類" dataIndex="findings_classification" key="1" 
              showSorterTooltip={false}
              sorter={(a, b) => a.findings_classification - b.findings_classification}
            />
            <Table.Column title="部位分類名称" dataIndex="site_classification_name" key="2" 
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification_name.localeCompare(b.site_classification_name, 'ja')}
            />
            <Table.Column title="所見コード" dataIndex="findings_code" key="3" 
              showSorterTooltip={false}
              sorter={(a, b) => a.findings_code - b.findings_code}
            />
            <Table.Column title="検索略名 / 所見名称" dataIndex="search_short_name" key="4" />
            <Table.Column title="判定値" dataIndex="judgment_value" key="5" 
              showSorterTooltip={false}
              sorter={(a, b) => a.judgment_value.localeCompare(b.judgment_value, 'ja')}
            />

          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0413001_MasterListOutputFindingInfo);
