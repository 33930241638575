

import React from "react"
import ModalDraggable from "components/Commons/ModalDraggable"
import { connect } from "react-redux"
import { Card, Form, Input, Button, message, Modal } from "antd"
import { PrinterOutlined } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers'
import moment from "moment-timezone"
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom"
// import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import AccountsReceivableListOutputInstructionAction from 'redux/AccountingBusiness/AccountsReceivableListOutputInstruction/AccountsReceivableListOutputInstruction.actions.js'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import WS0432001_CsvConfirm from 'pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0432001_CsvConfirm.jsx';

const labelCol = {
  labelCol: { style: { width: 50 } }
}

/** Program [985] AccountsReceivableListOutputInstruction 未収金一覧表出力指示 Prg_964.xml */
class WS0985001_AccountsReceivableListOutputInstruction extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '未収金一覧表出力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      listID: [],
      dataSource: [],
      dataSourceRF01: [],
      dataSourceRF02: [],
      dataSourceRF03: [],
      isLoading: false,
      rowSelect: {},
      initObj: {},
      CsvOutputFile: '',
      initParams: {
        ClaimYearsChar: '',
        ClaimNum: '',
        Outstanding: 2,
        Target: 0,
        ClaimIdentify: 0,
        InsurerNum: '',
        OfficeNum: '',
        BranchStoreCode: '',
        AddressSearch: '',
        StsSelectAll: '',
        ClaimYears: ''
      },
    };
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData = () => {
    AccountsReceivableListOutputInstructionAction.GetScreenData()
      .then(res => {
        let today = new Date();
        this.formRef.current.setFieldsValue({
          Gl0DateFChar: moment(today).format('YYYY/MM/DD'),
          Gl0DateTChar: moment(today).format('YYYY/MM/DD'),
          DateChar: moment(today).format('YYYY/MM/DD'),
          CsvOutputFile: res.Output
        })
      })
  }

  // csvファイル名設定
  showCsvConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS0432001_CsvConfirm
            Lio_Output={this.formRef.current?.getFieldValue("CsvOutputFile")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                StsOutputConfirm: output.Lo_StsOutput ? 1 : 0,
                CsvOutputFile: output.Lio_Output + '.csv'
              })
              this.OutputCsv();
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  // CSV出力
  OutputCsv() {
    let params = {
      Gl0DateFChar: moment(this.formRef.current.getFieldValue('Gl0DateFChar')).format('YYYY/MM/DD'),
      Gl0DateTChar: moment(this.formRef.current.getFieldValue('Gl0DateTChar')).format('YYYY/MM/DD'),
      DateChar: moment(this.formRef.current.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      StsOutputConfirm: this.formRef.current.getFieldValue('StsOutputConfirm'),
      CsvOutputFile: this.formRef.current.getFieldValue('CsvOutputFile'),
    }

    AccountsReceivableListOutputInstructionAction.Csv_F11(params)
      .then(res => {
        if (res.data.message) {
          Modal.warning({
            title: res.data.message,
            width: 300,
          });
        } else {
          download_file(res);
          message.success("完了！");
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
  }

  // 印刷画面モーダル
  showPrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            onFinishScreen={(output) => {
              if (output.Preview) {
                this.preview(output)
              } else {
                this.print(output)
              }
            }}
          />
        ),
      },
    });
  }

  // プレビュー処理
  preview = (output) => {
    let params = {
      previewFlag: true,
      Gl0DateFChar: moment(this.formRef.current.getFieldValue('Gl0DateFChar')).format('YYYY/MM/DD'),
      Gl0DateTChar: moment(this.formRef.current.getFieldValue('Gl0DateTChar')).format('YYYY/MM/DD'),
      DateChar: moment(this.formRef.current.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      PrinterNum: output.Lo_PrinterNo
    }

    AccountsReceivableListOutputInstructionAction.Printer_F12(params)
      .then(res => {
        if (res.data.message) {
          Modal.warning({
            title: res.data.message,
            width: 300,
          });
        } else {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          window.open(fileURL)
        }
      }).catch(error => {
        const res = error.response
        if (res) {
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        }
      })
  }

  // 印刷処理
  print = (output) => {
    let params = {
      previewFlag: false,
      Gl0DateFChar: moment(this.formRef.current.getFieldValue('Gl0DateFChar')).format('YYYY/MM/DD'),
      Gl0DateTChar: moment(this.formRef.current.getFieldValue('Gl0DateTChar')).format('YYYY/MM/DD'),
      DateChar: moment(this.formRef.current.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      PrinterNum: output.Lo_PrinterNo
    }

    AccountsReceivableListOutputInstructionAction.Printer_F12(params)
      .then(res => {
        if (res.data === 'true') {
          message.success('印刷が完了しました')
        } else {
          message.error('処理の途中でエラーが発生しました')
        }
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      }).catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
        if (err.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          });
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className='invoice' >
        <Card className="mb-2" title='未収金一覧表出力' style={{ width: 450 }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            {/* {this.renderMenuBar()} */}
            <div className='box_inner_horizontal'>
              <Form.Item
                name="Gl0DateFChar"
                label="請求日"
                {...labelCol}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>

              <Form.Item
                name="Gl0DateTChar"
                label='～'
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>
            </div>

            <Form.Item
              name="DateChar"
              label="基準日"
              {...labelCol}
            >
              <VenusDatePickerCustom
                formRefDatePicker={this.formRef}
              />
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
            <Button
              type="primary"
              onClick={() => {
                this.showCsvConfirm()
              }}
            >
              CSV
            </Button>

            <Button
              icon={<PrinterOutlined />}
              type="primary"
              onClick={() => {
                this.showPrinterConfirm()
              }}
            >
              印刷
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>

    );
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const { Search } = Input;

const onSearch = value => console.log(value);

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WS0985001_AccountsReceivableListOutputInstruction)
