import React from 'react'
import moment from 'moment'
import ja_JP from 'antd/es/date-picker/locale/ja_JP'
import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import { VenusDateValidation } from 'helpers/VenusDateValidation'

const defaultFormatDate = 'YYYY/MM/DD (ddd)'

const VenusDatePickerCustom = (props) => {
  const [date, setDate] = React.useState(null)
  const [dateValidate, setDateValidate] = React.useState(null)
  const [isPressKeyEnter, setIsPressKeyEnter] = React.useState(false)

  React.useEffect(() => {
    setDate(props.value)
  }, [props.value])

  React.useEffect(() => {
    if (isPressKeyEnter) {
      if (dateValidate === '') {
        // message.error('不正な日付です')
        setDate(null)
      } else {
        const mDate = moment(dateValidate)
        if (props.id && props.formRefDatePicker)
          props.formRefDatePicker?.current?.setFields([
            { name: props.id, value: mDate },
          ])
        if (props.onChange) props.onChange(mDate, moment(mDate).format(defaultFormatDate))
        setDate(mDate)
      }
      setIsPressKeyEnter(false)
      // setDateValidate(null)
    }
  }, [isPressKeyEnter])

  return (
    <DatePicker
      {...props}
      // type={'tel'}
      locale={ja_JP}
      format={props.format || defaultFormatDate}
      value={date ? moment(date) : null}
      ref={props.innerRef}
      style={{ width: props.width || '160px' }}
      onBlur={(e) => {
        if (dateValidate) setIsPressKeyEnter(true)
        if (props.onBlur) props.onBlur(e)
      }}
      onChange={(value, dateString) => {
        // console.log(value, dateString)
        if (props.onChange) props.onChange(value, dateString)
        // restore to dafault date
        if (!value) {
          value = moment()
        }
        setDate(value)
      }}
      onKeyDown={(e) => {
        if (e.key !== 'Enter' && e.key !== 'Tab') {
          setDateValidate(VenusDateValidation(e.target.value + e.key))
        }
        else {
          let year = ''
          let month = ''
          let date = ''
          if (props.picker == 'month') {
            console.log(e.target.value)
            const regex1 = /^\d{6}$/
            const regex2 = /^\d{4}$/
            if (regex1.test(e.target.value)) {
              year = e.target.value.substr(0, 4)
              month = e.target.value.substr(-2)
              setDateValidate(VenusDateValidation(year + '/' + month + '/01'))
            } else if (regex2.test(e.target.value)) {
              year = e.target.value.substr(0, 2)
              month = e.target.value.substr(-2)
              setDateValidate(VenusDateValidation(year + '/' + month + '/01'))
            } else {
              setDateValidate(VenusDateValidation(e.target.value + '/01'))
            }
          } else {
            const regex1 = /^\d{4}$/
            const regex2 = /^\d{2}\/\d{2}$/
            if (regex1.test(e.target.value) || regex2.test(e.target.value)) {
              const today = new Date()
              year = today.getFullYear()
              month = e.target.value.substr(0, 2)
              date = e.target.value.substr(-2)
            } else {
              const targetValue = new Date(moment(e.target.value, 'YYYY-MM-DD'))
              year = targetValue.getFullYear()
              month = targetValue.getMonth() + 1
              date = targetValue.getDate()
            }
            setDateValidate(VenusDateValidation(year + '/' + month + '/' + date))
          }

        }
        if (e.key === 'Enter' || e.key === 'Tab') {
          setIsPressKeyEnter(true)
          return
        }
      }}
    ></DatePicker>
  )
}

VenusDatePickerCustom.propTypes = {
  format: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.any,
  allowClear: PropTypes.bool,
}
export default VenusDatePickerCustom
