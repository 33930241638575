import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Button, message, Dropdown, Menu, Modal, } from "antd";
import { MoreOutlined, PlusOutlined, QuestionCircleOutlined, } from "@ant-design/icons";

import DunningInquiryService from "services/SpecificInsureGuide/InsureGuideInput/DunningInquiryService";
import WS1444016_DunningInput from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444016_DunningInput.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1445001_DunningPrint from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1445001_DunningPrint.jsx";
import moment from "moment";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

const style = {
  label: {
    fontWeight: 'bold',
    color: '#14468C',
  },
}

class WS1444001_DunningInquiry extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '督促照会';

    this.state = {
      isLoading: false,

      DisplayList: [],
      rowSelect: {},
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 1,
        showQuickJumper: true
      },
    };
  }

  componentDidMount = () => {
    this.getScreenData();
    this.getTableData();
  }

  getScreenData = () => {
    this.setState({ isLoading: true, rowSelect: {} });

    DunningInquiryService.getScreenData(
      {
        Li_Date: this.props.Li_Date,
        Li_PersonalNum: this.props.Li_PersonalNum,
      }
    )
      .then((res) => {
        if (res.data.ScheduledImplementDate == '0000-00-00') {
          res.data.ScheduledImplementDate = ''
        } else {
          res.data.ScheduledImplementDate = moment(res.data.ScheduledImplementDate).format('YYYY/MM/DD')
        }

        this.formRef.current.setFieldsValue({
          ...res.data,
        });
        this.forceUpdate();
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  getTableData = () => {
    this.setState({ isLoading: true, rowSelect: {} });

    DunningInquiryService.getDunningContent(
      {
        Li_Date: this.props.Li_Date,
        Li_Id: this.props.Li_PersonalNum,
        Li_PersonalNum: this.props.Li_PersonalNum,
      }
    )
      .then((res) => {
        this.setState({ DisplayList: res.data });
        if (res.data[0]) {
          this.setState({
            rowSelect: res.data[0],
          });
        }
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  createNew = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          < WS1444016_DunningInput
            Li_ScheduledImplementDate={this.formRef.current.getFieldValue('ScheduledImplementDate')}
            Li_Date={this.props.Li_Date}
            Li_Id={this.props.Li_PersonalNum}
            tableData={this.state.DisplayList}
            onFinishScreen={(output) => {
              this.closeModal();
              this.getTableData();
              this.props.onFinishScreen()
            }}
          />
        ),
      },
    });
  }

  update = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          < WS1444016_DunningInput
            Li_ScheduledImplementDate={record?.dunning_implement_date_on}
            Li_Date={this.props.Li_Date}
            Li_Id={this.props.Li_PersonalNum}
            Li_SupportCode={record?.supportitem?.support_code}
            tableData={this.state.DisplayList}
            onFinishScreen={(output) => {
              this.closeModal();
              this.getTableData();
              this.props.onFinishScreen()
            }}
          />
        ),
      },
    });
  }

  delete = (record) => {
    Modal.confirm({
      width: 300,
      title: '削除してよろしいですか？',
      icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
      onOk: () => {
        let params = {
          Li_Date: this.props?.Li_Date,
          Li_Id: this.props?.Li_PersonalNum,
          Li_SupportCode: record?.supportitem?.support_code,
          Li_DunningImplementDate: record?.dunning_implement_date_on,
        }
        DunningInquiryService.delete(params)
          .then(res => {
            this.getTableData()
          })
          .catch(error => {
          })
          .finally(() => {
            this.setState({ isLoadingTable: false });
          });
      }
    })
  }

  closeModal = () => {
    this.setState((prevState) => ({
      childModal: { ...prevState.childModal, visible: false },
    }));
  };

  render() {
    return (
      <div className="dunning-inquiry">
        <Card className="mb-2" title="督促照会">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className="box_inner_vertical">
              <Form.Item label="予定日">
                {this.formRef.current?.getFieldValue('ScheduledImplementDate')}
              </Form.Item>

              <div className="box_inner_horizontal">
                <Form.Item label="評価">
                  {this.formRef.current?.getFieldValue('Expresstion_1')}
                </Form.Item>
              </div>
            </div>

            <Table
              bordered
              size="small"
              rowKey={(record) => record.healthguidedunningdata.id}
              rowClassName={(record, index) => record.id === this.state.rowSelect.id ? 'hightlight-row-selected' : ''}
              onRow={(record, index) => ({
                onClick: (event) => this.setState({ rowSelect: record }),
              })}
              pagination={false}
              scroll={{ y: resizableTableScroll(130) }}
              dataSource={
                this.state.DisplayList
              }>

              <Table.Column
                width={40}
                title=""
                dataIndex="Expresstion_6"
              />

              <Table.Column
                title="督促日"
                dataIndex="dunning_implement_date_on"
              />

              <Table.Column
                title="方法"
                dataIndex="support"
              />

              <Table.Column
                width={40}
                title={
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={() => this.createNew()}
                  />
                }
                align='center'
                render={(value, record) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="menu-1"
                            onClick={() => this.update(record)}>
                            修正
                          </Menu.Item>

                          <Menu.Item
                            key="menu-2"
                            onClick={() => this.delete(record)}>
                            削除
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        icon={<MoreOutlined />}
                        size="small"
                      ></Button>
                    </Dropdown>
                  )
                }} />

            </Table>
            <div className="box_button_bottom_right">
              <Button type="primary" onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: '50%',
                    component: (
                      < WS1445001_DunningPrint
                        Li_ScheduledImplementDate={this.formRef.current.getFieldValue('ScheduledImplementDate')}
                        Li_Date={this.props.Li_Date}
                        Li_Id={this.props.Li_PersonalNum}
                        onFinishScreen={(output) => {
                          this.closeModal();
                          this.getTableData();
                        }}
                      />
                    ),
                  },
                });
              }}>督促状</Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1444001_DunningInquiry);
