import React from "react";
import { connect } from "react-redux";
import { Card, Table, Form, Button } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import man_img from 'assets/img/性別-男性.png';
import woman_img from 'assets/img/性別-女性.png';
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import SupportPlanManualCreateAction from "redux/SpecificInsureGuide/InsureGuideInitInput/SupportPlanManualCreate.action"
import WS1423100_SupportPlanManualCreateEdit from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1423100_SupportPlanManualCreateEdit.jsx"

const format = 'HH:mm'

const smGrid = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 70px)' } }
}

const formStyle = {
  marginBottom: 0
}
class WS1423001_SupportPlanManualCreate extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      data: {},
      dataSource: [],
    };
  }

  componentDidMount() {
    this.getScreenData();
  }


  getScreenData() {
    let params = {
      Li_FirstDate: this.props.Li_FirstDate,
      Li_Id: this.props.Li_Id,
    }
    SupportPlanManualCreateAction.getScreenData(params)
      .then(res => {
        this.setState({
          data: { ...this.state.data, ...res },
        });
        this.index()
      });
  }

  index() {
    let params = {
      Li_FirstDate: this.props.Li_FirstDate,
      Li_Id: this.props.Li_Id,
    }
    SupportPlanManualCreateAction.tableGet(params)
      .then(res => {
        for (let i = 0; i < res.length; i++) {
          switch (res[i].EvaluationDivisionScreen) {
            case '0':
              res[i].EvaluationDivisionScreen = '初回①'
              break;
            case '2':
              res[i].EvaluationDivisionScreen = '初期面談'
              break;
            case '3':
              res[i].EvaluationDivisionScreen = '中間評価'
              break;
            case '4':
              res[i].EvaluationDivisionScreen = '継続中'
              break;
            case '9':
              res[i].EvaluationDivisionScreen = '終了評価'
              break;
          }
        }
        this.setState({ dataSource: res });
        this.formRef.current?.setFieldsValue({ MainSource: res });
        this.forceUpdate();
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.Li_FirstDate !== prevProps.Li_FirstDate || this.props.Li_Id !== prevProps.Li_Id) {
      this.getScreenData();
    }
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    const { data } = this.state;
    return (
      <div className="support-plan-manual-create">
        <Card className="mb-2" title="支援計画手動作成">
          <div
            className="mb-3"
          >
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <div className="box_inner_horizontal">

                <div className="box_border">
                  <Form.Item
                    label="カナ氏名"
                    {...smGrid}
                  >
                    {data.kana_name}
                  </Form.Item>
                  <Form.Item
                    label="漢字氏名"
                    {...smGrid}
                  >
                    {data.kanji_name}
                  </Form.Item>
                  <div className="box_inner_horizontal" style={{ position: 'relative' }}>
                    <Form.Item
                      label="生年月日"
                      style={formStyle}
                      {...smGrid}
                    >
                      {data.birthday_on}
                    </Form.Item>
                    <Form.Item
                      label="年齢"
                      style={formStyle}
                    >
                      {data.Expression_8 + '歳'}
                    </Form.Item>
                    <img
                      style={{ bottom: '5px', right: 5, position: 'absolute' }}
                      src={data.Expression_7 ? data.Expression_7.includes('男性.png') ? man_img : woman_img : ""}
                      width={40}
                    />
                  </div>
                </div>

                <div className="box_border">
                  <Form.Item
                    label="保険者番号"
                    {...smGrid}
                  >
                    {data.insurer_number}
                  </Form.Item>
                  <Form.Item
                    label="保険者名"
                    {...smGrid}
                  >
                    {data.insurer_kanji_name}
                  </Form.Item>
                  <Form.Item
                    label="保険証"
                    style={formStyle}
                    {...smGrid}
                  >
                    {data.Expression_54}
                  </Form.Item>
                </div>

                <div className="box_border">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical">
                      <Form.Item
                        label=""
                      >
                        {data.Expression_11}
                      </Form.Item>
                      <Form.Item
                        label=""
                        style={formStyle}
                      >
                        {data.course_name}
                      </Form.Item>
                    </div>

                    <div className="box_inner_vertical">
                      <Form.Item
                        label="支援A"
                        {...smGrid}
                      >
                        {data.PlanningPointA}
                      </Form.Item>
                      <Form.Item
                        label="支援B"
                        {...smGrid}
                      >
                        {data.PlanningPointB}
                      </Form.Item>
                      <Form.Item
                        label="合計"
                        style={formStyle}
                        {...smGrid}
                      >
                        {data.Expression_43}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          {/* <div className="box_inner_horizontal">
            <div className="box_border">
              <div className={`py-1`}>{data.kana_name}</div>
              <div className={`py-1`}>{data.kanji_name}</div>
              <div className={`py-1 px-1`}>{data.birthday_on}  {data.Expression_8}歳</div>
              <img src={data.Expression_7 === "%IMAGE%/性別-男性.png" ? man : woman} width={32} alt={``} />
            </div>

            <div className="box_border">
              <p className={`py-1`}>{data.insurer_number}</p>
              <p className={`py-1`}>{data.insurer_kanji_name}</p>
              <p className={`py-1`}>{data.Expression_54}</p>
            </div>

            <div className="box_border">
              <div className={`py-1`}>{data.Expression_11}</div>
              <div className={`py-1`}>{data.course_name}</div>
              <div className={`py-1`}>支援Ａ {data.PlanningPointA}</div>
              <div className={`py-1`}>支援Ｂ {data.PlanningPointB}</div>
              <div className={`py-1`}>合 計 {data.Expression_43}</div>
            </div>
          </div> */}

          <Form ref={this.formRef}>
            <Table
              bordered
              size="small"
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ x: 1500 }}
            >
              <Table.Column
                width={50}
                title="連番"
                dataIndex="serial_number"
                showSorterTooltip={false}
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                width={200}
                title="実施予定日"
                dataIndex="ScheduledImplementDateChars"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div className="box_inner_horizontal">
                      <div>{record.implement_date_on}</div>
                      <div>{record.Expression_32}</div>
                    </div>
                  )
                }}
              />

              <Table.Column
                width={150}
                title="評価区分"
                dataIndex="EvaluationDivisionScreen"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div>{record.EvaluationDivisionScreen}</div>
                  )
                }}
              />

              <Table.Column
                title="支援項目"
                dataIndex="support_code"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div className="box_inner_vertical">
                      {/* <div>{record.support_code}</div> */}
                      <div>{record.support}</div>
                    </div>
                  )
                }}
              />

              <Table.Column
                width={100}
                title="支援量"
                dataIndex="support_price"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div>{record.support_price + '分'}</div>
                  )
                }}
              />

              <Table.Column
                width={150}
                title="時間帯"
                dataIndex="start_period_time"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div className="box_inner_horizontal">
                      <div>{record.start_period_time}</div>
                      <div>{'~'}</div>
                      <div>{record.end_period_time}</div>
                    </div>
                  )
                }}
              />

              <Table.Column
                title="実施予定者"
                dataIndex="implement_prospective_code"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div className="box_inner_horizontal">
                      {/* <div>{record.implement_prospective_code}</div> */}
                      <div>{record.Eligibility}</div>
                      <div>{record.kanji_name}</div>
                    </div>
                  )
                }}
              />

              <Table.Column
                title="実施場所"
                dataIndex="implement_location_code"
                showSorterTooltip={false}
                render={(value, record) => {
                  return (
                    <div className="box_inner_horizontal">
                      {/* <div>{record.implement_location_code}</div> */}
                      <div>{record.implement_location}</div>
                    </div>
                  )
                }}
              />

              <Table.Column
                title={
                  <Button
                    type='primary'
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          centered: true,
                          width: 700,
                          component: (
                            <WS1423100_SupportPlanManualCreateEdit
                              // data={record}
                              newFlag={true}
                              Li_FirstDate={this.props.Li_FirstDate}
                              date_on={data.date_on}
                              Li_Id={this.props.Li_Id}
                              onFinishScreen={() => {
                                this.closeModal();
                                this.getScreenData();
                              }}
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: false,
                                  },
                                });
                              }}
                            />
                          ),
                        },
                      })}
                  />
                }
                width={40}
                align='center'
                fixed='right'
                render={(text, record, index) => (
                  record.editFlag ?
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => (
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            centered: true,
                            width: 700,
                            component: (
                              <WS1423100_SupportPlanManualCreateEdit
                                data={record}
                                newFlag={false}
                                Li_Id={this.props.Li_Id}
                                Li_FirstDate={this.props.Li_FirstDate}
                                onFinishScreen={() => {
                                  console.log('onFinish');
                                  this.closeModal();
                                  this.getScreenData();
                                }}
                              />
                            ),
                          },
                        })
                      )}
                    />
                    :
                    null
                )}
              />
            </Table>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            console.log('onCancel');
            let data = [...this.state.dataSource]
            for (let i = 0; i < data.length; i++) {
              switch (data[i].EvaluationDivisionScreen) {
                case '0':
                  data[i].EvaluationDivisionScreen = '初回①'
                  break;
                case '2':
                  data[i].EvaluationDivisionScreen = '初期面談'
                  break;
                case '3':
                  data[i].EvaluationDivisionScreen = '中間評価'
                  break;
                case '4':
                  data[i].EvaluationDivisionScreen = '継続中'
                  break;
                case '9':
                  data[i].EvaluationDivisionScreen = '終了評価'
                  break;
              }
            }
            this.setState({
              dataSource: data,
              childModal: {
                ...this.state.childModal,
                visible: false
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1423001_SupportPlanManualCreate);
