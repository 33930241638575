import ContractSelectService from "services/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ContractSelectService"
import { message } from "antd";

const ContractSelectAction = {
  getScreenData(data) {
    return ContractSelectService.getScreenData(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}
export default ContractSelectService;