import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { connect } from 'react-redux'
import moment from 'moment'
import { getMenuList } from 'redux/user/user.actions'
import { SaveOutlined, PrinterOutlined, MenuOutlined } from '@ant-design/icons'
import { Card, Form, Radio, Button, Input, Space, message, Menu, Checkbox, Dropdown, Tooltip, Select, Descriptions } from 'antd'
import CorporateHearingSheetAction from 'redux/Others/CorporateHearingSheet/CorporateHearingSheet.action'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0341001_OfficeInfoMaintain from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341001_OfficeInfoMaintain'
import WS0084001_PostCodeSearchEngine from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import { download_file_binary } from 'helpers/CommonHelpers'
import DestinationManagement from './DestinationManagement'

const smGrid = {
  labelCol: { style: { width: 70 } },
  // wrapperCol: { style: { width: 'calc(100% - 70px)' } }
}

const forwarding = {
  labelCol: { style: { width: 42 } },
  // wrapperCol: { style: { width: 'calc(100% - 42px)' } }
}

const style = {
  marginBottom: 0,
  marginTop: '6px',
}

const bottomStyle = {
  marginBottom: 0,
}

const vertical = {
  gap: 0
}

const labelStyle = {
  width: 145
}

const contentStyle = {
  width: 180,
  minWidth: 180
}

class CorporateHearingSheet extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '企業ヒアリングシート'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      office_code: 0,
      branch_store_code: 0,
      insurer_code: 0,
      hearingId: null
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      gender: 1,
      classification: 1
    })

    if (this.props) {
      let params = {
        office_code: this.props.Lio_OfficeCode,
        branch_store_code: this.props.Lio_branch_store_code,
        insurer_code: this.props.Lio_insurer_code,
        officeName: this.props.Lio_office_kanji_name
      }
      this.formRef.current?.setFieldsValue({
        contractingOrganizationNO: this.props.Lio_OfficeCode,
        branch_store_code: this.props.Lio_branch_store_code,
        insurer_code: this.props.Lio_insurer_code,
        officeName: this.props.Lio_office_kanji_name
      })

      console.log(params);
      this.getHearingList(params)
    }
  }

  /**
   * 企業ヒアリングシートのデータを取得
   * @param {*} params
   */
  getHearingList(params) {
    CorporateHearingSheetAction.getHearingList(params)
      .then((res) => {
        if (res.id) {
          // データが存在する場合
          this.setState({ hearingId: res.id })

          if (res?.gender === null) {
            res.gender = 1
          }
          if (res?.classification === 0) {
            res.classification = 1
          }
          if (res?.date !== null) {
            res.date = moment(res.date)
          }
          this.formRef.current?.setFieldsValue(res)
          // 登録時、印刷時のパラメータで使うため
          this.setState({
            office_code: res.office_code,
            branch_store_code: res.branch_store_code,
            insurer_code: res.insurer_code,
          })

          // CheckBox用にFieldValueを設定
          this.convertSetFieldsValue('medical_questionnaire_sent', res)
          this.convertSetFieldsValue('send_results', res)
          this.convertSetFieldsValue('business_copy', res)
          this.forceUpdate()

        } else {
          // データが存在しない場合
          // 初期化
          this.resetFieldsData()
          // Form.itemがリセットされるため再度必要な情報をsetFieldsValue
          this.formRef.current?.setFieldsValue({
            officeName: params.officeName,
            contractingOrganizationNO: params.office_code,
            branch_store_code: params.branch_store_code,
            insurer_code: params.insurer_code,
            postal_code: res.postal_code ?? '',
            address: res.address ?? '',
            phone_number: res.phone_number ?? '',
            fax: res.fax ?? '',
          })
          console.log(params);
          this.setState({
            office_code: params.office_code,
            branch_store_code: params.branch_store_code,
            insurer_code: params.insurer_code,
          })
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res || res.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  changeValue(value, name) {
    this.formRef.current?.setFieldsValue({
      [name]: value,
    })
    this.forceUpdate()
  }

  /**
   * 登録ボタン押下処理
   */
  onRegistration() {
    let fieldsValue = this.formRef.current?.getFieldsValue()
    let params = {
      ...fieldsValue,
      date: moment(fieldsValue.date).format('YYYY/MM/DD'),
      office_code: this.state.office_code,
      branch_store_code: this.state.branch_store_code ?? 0,
      insurer_code: this.state.insurer_code ?? 0,
    }
    if (fieldsValue.date !== null || fieldsValue.date !== undefined) {
      fieldsValue.date = moment(fieldsValue.date).format('YYYY-MM-DD')
    }

    CorporateHearingSheetAction.onRegistration(params)
      .then((res) => {
        let reacquisition = {
          office_code: this.state.office_code,
          branch_store_code: this.state.branch_store_code ?? 0,
          insurer_code: this.state.insurer_code ?? 0,
        }
        console.log(reacquisition);
        this.getHearingList(reacquisition)
      }).catch(error => {
        const res = error.response;
        if (!res || res || res.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  /**
   * 印刷処理
   */
  print() {
    let fieldsValue = this.formRef.current?.getFieldsValue()
    if (fieldsValue.date !== null || fieldsValue.date !== undefined) {
      fieldsValue.date = moment(fieldsValue.date).format('YYYY-MM-DD')
    }
    let params = {
      ...fieldsValue,
      office_code: this.state.office_code,
      branch_store_code: this.state.branch_store_code,
      insurer_code: this.state.insurer_code,
      institution_name: this.formRef.current?.getFieldValue('institution_name')
    }
    CorporateHearingSheetAction.print(params)
      .then((res) => {
        if (res) {
          this.downloadExcelFile(res.filePath)
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res || res.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  /**
   * Excelファイル ダウンロード
   * @param {*} path
   */
  downloadExcelFile(path) {
    let params = {
      filePath: path
    }
    CorporateHearingSheetAction.downloadExcelFile(params)
      .then((res) => {
        if (res) {
          // xlsxファイルダウンロード
          download_file_binary(res)
          this.onRegistration()
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  /**
   * 変更内容をチェックする
   * @param {*} checked
   * @param {*} num
   * @param {*} name
   */
  checkChangeValue = (checked, num, name) => {
    const numStr = num.toString()

    let formObj = {
      [name + '_1']: false,
      [name + '_2']: false,
      [name]: 0,
    }

    // 初期化
    this.formRef.current?.setFieldsValue(formObj)

    if (checked) {
      this.formRef.current?.setFieldsValue({
        [name + '_' + numStr]: true,
        [name]: num,
      })
      this.forceUpdate()
    } else {
      switch (name) {
        case 'business_copy':
          this.setFieldsValueDefault('business_copy_payment')
          break;

        default:
          break;
      }
    }
  }

  /**
   * 詳細の選択を初期化する
   * @param {*} name
   */
  setFieldsValueDefault = (name) => {
    this.formRef.current?.setFieldsValue({ [name]: 0 })

    this.forceUpdate()
  }

  /**
   * CheckBox用にFieldValueを設定
   * @param {*} name
   * @param {*} data
   */
  convertSetFieldsValue = (name, data) => {
    this.formRef.current?.setFieldsValue({
      [name + '_' + data[name]]: true,
    })
  }

  /**
   * データを初期化
   */
  resetFieldsData = () => {
    // id初期化
    this.setState({ hearingId: null })
    // リセット
    this.formRef.current?.resetFields()
    // Form.itemがリセットされるため再度必要な情報をsetFieldsValue
    this.formRef.current?.setFieldsValue({
      gender: 1,
      classification: 1
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  openSendingHistory = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <DestinationManagement
            office_code={this.props.Lio_OfficeCode}
            branch_store_code={this.props.Lio_branch_store_code}
          />
        )
      }
    })
  }

  /**
   * 発送票
   */
  shippingLabel() {
    let fieldsValue = this.formRef.current?.getFieldsValue()
    if (fieldsValue.date !== null || fieldsValue.date !== undefined) {
      fieldsValue.date = moment(fieldsValue.date).format('YYYY-MM-DD')
    }
    let params = {
      officeName: fieldsValue.officeName,
      postal_code: fieldsValue.postal_code,
      address: fieldsValue.address,
      corporation_manager: fieldsValue.corporation_manager,
      medical_checkup_manager: fieldsValue.medical_checkup_manager
    }
    CorporateHearingSheetAction.shippingLabel(params)
      .then((res) => {
        if (res) {
          this.downloadExcelFile(res.filePath)
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res || res.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {
    return (
      <div className='comprehensive-menu-maintenance' style={{ minWidth: 1800 }}>
        <Card title={'企業ヒアリングシート [' + ((this.state.hearingId) ? '編集' : '新規') + ']'}>
          {
            this.props.screen !== 'WS0341001_OfficeInfoMaintain' ?
              <Tooltip title='オプションメニュー' placement='topRight'>
                <Dropdown
                  className={(this.props.Lio_OfficeCode) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                  trigger='click'
                  size='small'
                  overlay={() => {
                    return (
                      <Menu>
                        <Menu.Item
                          key='1'
                          style={{ width: '100%' }}
                          disabled={!this.formRef.current?.getFieldValue('contractingOrganizationNO')}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0341001_OfficeInfoMaintain
                                    Lio_OfficeCode={this.formRef.current?.getFieldValue('contractingOrganizationNO')}
                                    screen={'CorporateHearingSheet'}
                                  />
                                )
                              }
                            })
                          }}
                        >
                          事業所情報保守
                        </Menu.Item>
                      </Menu>
                    )
                  }}
                >
                  <Button
                    className={(this.props.Lio_OfficeCode) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                    size='small'
                    icon={<MenuOutlined />}
                  />
                </Dropdown>
              </Tooltip> : null
          }
          <Form ref={this.formRef} autoComplete='off' >
            <div className='box_inner_horizontal'>
              <Form.Item label='契約団体NO.' name='contractingOrganizationNO'>
                <div style={{ fontWeight: 'bold' }}>{this.formRef.current?.getFieldValue('contractingOrganizationNO')}</div>
                {/* <Input.Search
                  allowClear={true}
                  cleartriggersearch='true'
                  style={{ margin: 0 }}
                  className='input-search-size-number-allow-clear-8'
                  onSearch={(value, event) => {
                    if (event.target.localName !== 'input') {
                      // 虫眼鏡ボタンが押下された時
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1200,
                          component: (
                            <WS0247001_OfficeInfoRetrievalQuery
                              Lio_OfficeCode={value}
                              onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name, recordData }) => {
                                this.formRef.current?.setFieldsValue({
                                  contractingOrganizationNO: Lio_OfficeCode,
                                  officeName: Lo_Kanji_Name,
                                })
                                let params = {
                                  office_code: Lio_OfficeCode,
                                  branch_store_code: Lio_BranchStoreCode,
                                  insurer_code: recordData.insurer_code,
                                  officeName: Lo_Kanji_Name,
                                }
                                this.getHearingList(params)
                                this.closeModal()
                              }}
                            />
                          )
                        }
                      })
                    } else {
                      // allowClearのボタンが押下された時
                      // 初期化
                      this.resetFieldsData()
                      // Form.itemがリセットされるため再度必要な情報をsetFieldsValue
                      this.formRef.current?.setFieldsValue({
                        officeName: '',
                        contractingOrganizationNO: '',
                        branch_store_code: 0,
                        insurer_code: 0,
                      })
                      this.setState({
                        office_code: 0,
                        branch_store_code: 0,
                        insurer_code: 0,
                      })
                      this.forceUpdate()
                    }
                  }}
                /> */}
              </Form.Item>

              <Form.Item label='枝番' name='branch_store_code'>
                <div style={{ fontWeight: 'bold' }}>{this.formRef.current?.getFieldValue('branch_store_code')}</div>
              </Form.Item>

              <Form.Item label='事業所名' name='officeName'>
                <div style={{ fontWeight: 'bold' }}>{this.formRef.current?.getFieldValue('officeName')}</div>
              </Form.Item>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: 0
              }}>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Descriptions
                  title=''
                  column={2}
                  bordered
                  size='small'
                // labelStyle={labelStyle}
                >
                  <Descriptions.Item label='問診票送付先' contentStyle={contentStyle} labelStyle={labelStyle}>
                    {/* <Form.Item name='medical_questionnaire_sent'>
                    <Radio.Group>
                      <Space direction='vertical'>
                        <Radio value={1}>会社</Radio>
                        <Radio value={2}>自宅</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item> */}

                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item name='medical_questionnaire_sent_1' valuePropName='checked'>
                        <Checkbox onChange={(e) => this.checkChangeValue(e.target.checked, 1, 'medical_questionnaire_sent')}>
                          会社
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name='medical_questionnaire_sent_2' valuePropName='checked' style={bottomStyle}>
                        <Checkbox onChange={(e) => this.checkChangeValue(e.target.checked, 2, 'medical_questionnaire_sent')}>
                          自宅
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={null} labelStyle={{ width: 0 }}>
                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item label='〒' name='postal_code' {...forwarding}>
                        <Input.Search
                          className='input-search-size-single-byte-8'
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0084001_PostCodeSearchEngine
                                    onFinishScreen={async ({ Lio_ZipCode, Lio_Address }) => {
                                      this.formRef.current?.setFieldsValue({
                                        postal_code: Lio_ZipCode,
                                        address: Lio_Address,
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='住所' name='address' {...forwarding}>
                        <Input.TextArea style={{ width: '100%' }} rows={2} />
                      </Form.Item>
                      {/* <div className='box_inner_horizontal'> */}
                      <Form.Item label='TEL' name='phone_number' {...forwarding}>
                        <Input className='input-size-12' />
                      </Form.Item>
                      <Form.Item label='FAX' name='fax' {...forwarding} style={bottomStyle}>
                        <Input className='input-size-12' />
                      </Form.Item>
                      {/* </div> */}
                    </div>
                  </Descriptions.Item>

                  <Descriptions.Item label='結果送付先' contentStyle={contentStyle} labelStyle={labelStyle}>
                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item name='send_results_1' valuePropName='checked'>
                        <Checkbox onChange={(e) => this.checkChangeValue(e.target.checked, 1, 'send_results')}>
                          会社
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name='send_results_2' valuePropName='checked' style={bottomStyle}>
                        <Checkbox onChange={(e) => this.checkChangeValue(e.target.checked, 2, 'send_results')}>
                          自宅
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <Form.Item name='send_results_remark' style={bottomStyle}>
                      <Input.TextArea style={{ width: '100%' }} rows={2} />
                    </Form.Item>
                  </Descriptions.Item>

                  <Descriptions.Item label='企業用控え' contentStyle={contentStyle} labelStyle={labelStyle}>
                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item name='business_copy_1' valuePropName='checked'>
                        <Checkbox onChange={(e) => this.checkChangeValue(e.target.checked, 1, 'business_copy')}>
                          要　労安法
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name='business_copy_2' valuePropName='checked' style={bottomStyle}>
                        <Checkbox onChange={(e) => this.checkChangeValue(e.target.checked, 2, 'business_copy')}>
                          要　個人結果写し
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='business_copy_payment' style={{ minWidth: 155, ...bottomStyle }}>
                        <Radio.Group
                          disabled={this.formRef.current?.getFieldValue('business_copy') === 0 || this.formRef.current?.getFieldValue('business_copy') === undefined}>
                          <Space direction='vertical'>
                            <Radio value={1}>請求と同時</Radio>
                            <Radio value={2}>本人用結果と同時　</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name='business_copy_remark' style={{ width: '100%', ...bottomStyle }} >
                        <Input.TextArea style={{ width: '100%' }} rows={2} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>

                  <Descriptions.Item label='請求方法領収書' style={{ height: '100%' }} contentStyle={contentStyle} labelStyle={labelStyle}>
                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item name='payment_on_the_day' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            this.changeValue(e.target.checked, 'payment_on_the_day')
                            if (!e.target.checked) { this.setFieldsValueDefault('payment_on_the_day_flag') }
                          }}>
                          当日支払
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name='company_request' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            this.changeValue(e.target.checked, 'company_request')
                            if (!e.target.checked) { this.setFieldsValueDefault('company_request_sending') }
                          }}>
                          会社請求
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name='data_provision' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            this.changeValue(e.target.checked, 'data_provision')
                            if (!e.target.checked) { this.setFieldsValueDefault('data_provision_flag') }
                          }}>
                          データ提供
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name='other' valuePropName='checked'>
                        <Checkbox>その他</Checkbox>
                      </Form.Item>
                      <Form.Item name='agency_request' valuePropName='checked'>
                        <Checkbox onChange={(e) => this.changeValue(e.target.checked, 'agency_request')}>代行機関請求</Checkbox>
                      </Form.Item>
                      <div style={{ minHeight: 50 }} />
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <div className='box_inner_vertical' style={vertical}>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='payment_on_the_day_flag'>
                          <Radio.Group disabled={!this.formRef.current?.getFieldValue('payment_on_the_day')}>
                            <Radio value={1}>個人名のみ</Radio>
                            <Radio value={2}>企業名のみ</Radio>
                            <Radio value={3}>個人名+企業名</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <Form.Item name='company_request_sending' valuePropName='checked'>
                        <Checkbox disabled={!this.formRef.current?.getFieldValue('company_request')}>月末締めの翌月請求書送付</Checkbox>
                      </Form.Item>

                      <Form.Item name='data_provision_flag'>
                        <Radio.Group disabled={!this.formRef.current?.getFieldValue('data_provision')}>
                          <Radio value={1}>XML</Radio>
                          <Radio value={2}>CSV</Radio>
                          <Radio value={3}>無</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label='備考' name='other_remark'>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item label='種別選択' name='classification' style={{ marginBottom: 3 }}>
                        <Select style={{ width: 100 }} disabled={!this.formRef.current?.getFieldValue('agency_request')}>
                          <Select.Option value={1}>事業所</Select.Option>
                          <Select.Option value={2}>保険者</Select.Option>
                        </Select>
                      </Form.Item>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='契約No.' style={style} name='contract_type'>
                          <Input.Search
                            className='input-search-size-number-10'
                            allowClear={true}
                            disabled={!this.formRef.current?.getFieldValue('agency_request')}
                            onPressEnter={(event) => {
                              if (this.formRef.current?.getFieldValue('agency') === 1) {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: '80%',
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={event.target.value}
                                        onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name, recordData }) => {
                                          this.formRef.current?.setFieldsValue({
                                            contract_type: Lio_OfficeCode,
                                            institution_name: Lo_Kanji_Name,
                                          })
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              } else {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 800,
                                    component: (
                                      <WS0246001_InsurerInfoSearchQuery
                                        Li_insurerSearch={event.target.value}
                                        onFinishScreen={async ({ Lo_InsurerCode, Lo_Name, recordData }) => {
                                          this.formRef.current?.setFieldsValue({
                                            contract_type: Lo_InsurerCode,
                                            institution_name: Lo_Name,
                                          })
                                          this.closeModal()
                                        }}
                                      />
                                    )
                                  }
                                })
                              }
                            }}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.formRef.current?.setFieldsValue({
                                  institution_name: ''
                                })
                                this.forceUpdate()
                              } else {
                                if (this.formRef.current?.getFieldValue('agency') === 1) {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 800,
                                      component: (
                                        <WS0247001_OfficeInfoRetrievalQuery
                                          Lio_OfficeCode={event.target.value}
                                          onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name, recordData }) => {
                                            this.formRef.current?.setFieldsValue({
                                              contract_type: Lio_OfficeCode,
                                              institution_name: Lo_Kanji_Name,
                                            })
                                            this.closeModal()
                                          }}
                                        />
                                      )
                                    }
                                  })
                                } else {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 800,
                                      component: (
                                        <WS0246001_InsurerInfoSearchQuery
                                          Li_insurerSearch={event.target.value}
                                          onFinishScreen={async ({ Lo_InsurerCode, Lo_Name, recordData }) => {
                                            this.formRef.current?.setFieldsValue({
                                              contract_type: Lo_InsurerCode,
                                              institution_name: Lo_Name,
                                            })
                                            this.closeModal()
                                          }}
                                        />
                                      )
                                    }
                                  })
                                }
                              }
                            }
                            }
                          />
                        </Form.Item>
                        <Form.Item label='機関名' style={style} name='institution_name'>
                          <div>{this.formRef.current?.getFieldValue('institution_name')}</div>
                        </Form.Item>
                      </div>
                      <div style={{ minHeight: 13 }} />
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Descriptions
                  title=''
                  column={1}
                  bordered
                  size='small'
                  labelStyle={labelStyle}
                >
                  <Descriptions.Item label='コース' >
                    <div className='box_inner_vertical' style={vertical}>

                      <div className='box_inner_horizontal'>
                        <Form.Item
                          label='保険種類　・・・'
                          name='insurance_type'
                          style={{ minWidth: 340 }}
                        >
                          <Radio.Group>
                            <Radio value={1}>協会けんぽ</Radio>
                            <Radio value={2}>その他組合</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name='association' style={{ width: '100%' }}>
                          <Input />
                        </Form.Item>
                      </div>

                      <Form.Item
                        label='35才未満のコース　・・・'
                        name='under_35_course'
                      // {...receipt}
                      >
                        <Radio.Group>
                          <Radio value={1}>Aコース</Radio>
                          <Radio value={2}>Bコース</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label='その他組合指定コース　・・・'
                        name='union_designated_course'
                        style={bottomStyle}
                      // {...receipt}
                      >
                        <Radio.Group>
                          <Radio value={1}>有</Radio>
                          <Radio value={2}>無</Radio>
                        </Radio.Group>
                      </Form.Item>

                    </div>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  title=''
                  column={2}
                  bordered
                  size='small'
                // labelStyle={labelStyle}
                >
                  <Descriptions.Item label='ＧＦ変更' contentStyle={contentStyle} labelStyle={labelStyle}>
                    <Form.Item name='gastroscope_change' valuePropName='checked'>
                      <Checkbox
                        onChange={(e) => {
                          this.changeValue(e.target.checked, 'gastroscope_change')
                          if (!e.target.checked) { this.setFieldsValueDefault('gastroscope_change_payment') }
                        }}>
                        胃カメラに変更
                      </Checkbox>
                    </Form.Item>
                    <br />
                  </Descriptions.Item>

                  <Descriptions.Item labelStyle={{ width: 0 }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='gastroscope_change_payment' style={{ minWidth: 110, ...bottomStyle }}>
                        <Radio.Group disabled={!this.formRef.current?.getFieldValue('gastroscope_change')}>
                          <Space direction='vertical'>
                            <Radio value={1}>個人支払</Radio>
                            <Radio value={2}>会社請求</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name='gastroscope_change_remark' style={{ width: '100%', ...bottomStyle }}>
                        <Input.TextArea style={{ width: '100%' }} rows={2} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label='協会けんぽ対象者' contentStyle={contentStyle} labelStyle={labelStyle}>
                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item name='cancer' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            this.changeValue(e.target.checked, 'cancer')
                            if (!e.target.checked) { this.setFieldsValueDefault('cancer_payment') }
                          }}>
                          乳・子宮がん
                        </Checkbox>
                      </Form.Item>
                      <div style={{ minHeight: 32 }} />
                      <br />
                      <Form.Item name='medical_checkup_target' valuePropName='checked' style={bottomStyle}>
                        <Checkbox
                          onChange={(e) => {
                            this.changeValue(e.target.checked, 'medical_checkup_target')
                            if (!e.target.checked) { this.setFieldsValueDefault('medical_checkup_target_payment') }
                          }}>
                          付加健診対象者
                        </Checkbox>
                      </Form.Item>
                      <div>{'（今年度40歳・50歳）'}</div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <div className='box_inner_vertical' style={vertical}>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='cancer_payment' style={{ minWidth: 110 }}>
                          <Radio.Group disabled={!this.formRef.current?.getFieldValue('cancer')} >
                            <Space direction='vertical'>
                              <Radio value={1}>個人支払</Radio>
                              <Radio value={2}>会社請求</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name='cancer_remark' style={{ width: '100%' }} >
                          <Input.TextArea style={{ width: '100%' }} rows={2} />
                        </Form.Item>
                      </div>
                      <br />
                      <div className='box_inner_horizontal'>
                        <Form.Item name='medical_checkup_target_payment' style={{ minWidth: 110, ...bottomStyle }}>
                          <Radio.Group disabled={!this.formRef.current?.getFieldValue('medical_checkup_target')}>
                            <Space direction='vertical'>
                              <Radio value={1}>個人支払</Radio>
                              <Radio value={2}>会社請求</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name='medical_checkup_target_remark' style={{ width: '100%', ...bottomStyle }}>
                          <Input.TextArea style={{ width: '100%' }} rows={2} />
                        </Form.Item>
                      </div>
                    </div>
                  </Descriptions.Item>

                  <Descriptions.Item label='オプション' labelStyle={labelStyle}>
                    <Form.Item name='option' valuePropName='checked'>
                      <Checkbox onChange={(e) => {
                        this.changeValue(e.target.checked, 'option')
                        if (!e.target.checked) { this.setFieldsValueDefault('option_payment') }
                      }}>
                        その他オプション
                      </Checkbox>
                    </Form.Item>
                    <br />
                  </Descriptions.Item>

                  <Descriptions.Item>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='option_payment' style={{ minWidth: 110, ...bottomStyle }}>
                        <Radio.Group disabled={!this.formRef.current?.getFieldValue('option')}>
                          <Space direction='vertical'>
                            <Radio value={1}>個人支払</Radio>
                            <Radio value={2}>会社請求</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name='option_remark' style={{ width: '100%', ...bottomStyle }}>
                        <Input.TextArea style={{ width: '100%' }} rows={2} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  title=''
                  column={1}
                  bordered
                  size='small'
                  labelStyle={labelStyle}
                >
                  <Descriptions.Item label='確認日'>
                    <div className='box_inner_vertical' style={vertical}>
                      <Form.Item
                        label='年月日'
                        {...smGrid} name='date'
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format={'YYYY/MM/DD (ddd)'}
                        />
                      </Form.Item>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='企業担当者'{...smGrid} name='corporation_manager'>
                          <Input className='input-size-20' />
                        </Form.Item>
                        <Form.Item name='gender'>
                          <Select style={{ width: 100 }}>
                            <Select.Option value={1}>男性</Select.Option>
                            <Select.Option value={2}>女性</Select.Option>
                            <Select.Option value={3}>本人</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='健診担当者' {...smGrid} name='medical_checkup_manager' style={{ minWidth: 240 }}>
                          <Input className='input-size-20' />
                        </Form.Item>
                        <Form.Item label='備考' {...forwarding} name='remarks' style={{ width: '100%', ...bottomStyle }}>
                          <Input.TextArea style={{ width: '100%' }} rows={2} />
                        </Form.Item>
                      </div>
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </div>

              <div style={{ display: 'none' }}>
                {/* getFieldsValueで取得する為 */}
                <Form.Item name='medical_questionnaire_sent' ><Input /></Form.Item>
                <Form.Item name='send_results' ><Input /></Form.Item>
                <Form.Item name='business_copy' ><Input /></Form.Item>
              </div>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                disabled={!this.formRef.current?.getFieldValue('contractingOrganizationNO')}
                onClick={() => this.openSendingHistory()}
              >
                <span className='btn_label'>
                  送付先管理
                </span>
              </Button>
              <Button
                type='primary'
                icon={<PrinterOutlined />}
                onClick={() => this.shippingLabel()}
                disabled={!this.formRef.current?.getFieldValue('contractingOrganizationNO')}
              >
                <span className='btn_label'>
                  発送票
                </span>
              </Button>
              <Button
                type='primary'
                icon={<PrinterOutlined />}
                onClick={() => this.print()}
                disabled={!this.formRef.current?.getFieldValue('contractingOrganizationNO')}
              >
                <span className='btn_label'>
                  印刷
                </span>
              </Button>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.onRegistration()}
                disabled={!this.formRef.current?.getFieldValue('contractingOrganizationNO')}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
  getMenuList: () => dispatch(getMenuList())
})
export default connect(mapStateToProps, mapDispatchToProps)(CorporateHearingSheet)
