/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import { MoreOutlined, MenuOutlined, EditOutlined, QuestionCircleOutlined, RightOutlined, DoubleRightOutlined, LeftOutlined, DoubleLeftOutlined, DeleteOutlined, QuestionCircleTwoTone, TagFilled } from '@ant-design/icons'
import { Card, Form, Button, Select, Row, Col, Menu, message, Tooltip, Dropdown, Modal, Spin, Skeleton } from 'antd'
import moment, { max } from 'moment-timezone'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'
import ReserveStatusSearchService from 'services/ReservationBusiness/ReserveStatusSearch/ReserveStatusSearchService'
import WS2737001_MiraisSingleTransmission from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737001_MiraisSingleTransmission'
import WS2580001_ScheduleChange from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2580001_ScheduleChange.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2528031_SettingDisplayItem from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2528031_SettingDisplayItem.jsx'
import WS1490001_SpecificDatePeopleNumSetting from 'pages/SM_SystemMaintenance/V4SM0004000_SpecificDatePeopleNumSetting/WS1490001_SpecificDatePeopleNumSetting'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import WS2577003_CalendarEmptySub from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577003_CalendarEmptySub.jsx'
import WS2532001_GroupBookings from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532001_GroupBookings.jsx'
import FrameReserve from 'pages/ZZ_Others/SANAI_FrameReserve/FrameReserve'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS0548001_ReservesPersonsBulkUpdate from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS0548001_ReservesPersonsBulkUpdate.jsx'
import { download_file } from 'helpers/CommonHelpers'
import ReservationDetails from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/ReservationDetails'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import MedicalCheckupCalendar from 'pages/ZZ_Others/SANAI_MedicalCheckupCalendar/MedicalCheckupCalendar.jsx'
import CustomInspectionDisplay from 'pages/ZZ_Others/SANAI_ReserveStatusSearch/CustomInspectionDisplay'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import TentativeReserveCaptureConfirmed from 'pages/YK_ReservationBusiness/SANAI_TentativeReserve/TentativeReserveCaptureConfirmed.jsx'
import DetermineTentativeReserve from 'pages/YK_ReservationBusiness/SANAI_TentativeReserve/DetermineTentativeReserve'
import DataGrid, { Column, Sorting, Export } from 'devextreme-react/data-grid'

// Excel出力
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
// Excel出力に使う
import { exportDataGrid } from 'devextreme/excel_exporter'

const timeFrame = [
  { time: '08:00', frame: 6 },
  { time: '08:30', frame: 9 },
  { time: '09:00', frame: 9 },
  { time: '09:30', frame: 9 },
  { time: '10:00', frame: 8 },
  { time: '10:30', frame: 6 },
  { time: '11:00', frame: 5 },
  { time: '11:30', frame: 3 },
]

const columnCount = 15

const cardStyle = {
  padding: 10
}

const labelList = [
  { key: 0, label: '無し', color: '#ffffff' },
  { key: 1, label: 'オレンジ', color: '#ee7800' },
  { key: 2, label: '赤', color: '#FF0000' },
  { key: 3, label: '黄', color: '#ffd900' },
  { key: 4, label: '紫', color: '#C030C0' },
  { key: 5, label: '緑', color: '#14d357' },
]

class ReserveStatusSearchCustom extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '予約状況検索（カスタム）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataTitle: {},
      dataShowColumnExamineeList: {},
      ExamineeList: [],
      ReserveStatusDisplay: [],
      screenData: {},
      //Fields search
      YearsChar: '',
      FacilityType: '',
      TimeZone: '',
      PersonsSituation: '',
      WS2528047_DetailsExtract: {
        Lio_OfficeCode: '', // Office_Extract
        Lio_TimeZone: '',  // TimeZone_Extract
        office_kanji_name: '',
      },
      //selected row left table
      selectedRowRecordReserveStatusDisplay: {},
      selectedRowRecordReserveStatusDisplay2: {},
      isCloseModalLoadReserveStatusDisplayAgain: false,
      isCloseModalLoadExamListAgain: false,
      // selected Column
      selectedColumn: {
        num_01: false,
        num_02: false,
        num_03: false,
        num_04: false,
        num_05: false,
        num_06: false,
        num_07: false,
        num_08: false,
        num_09: false,
        num_10: false,
        num_11: false,
        num_12: false,
        num_13: false,
        num_14: false,
        num_15: false,
      },
      num_final: null,
      CourseList: null,
      ExamList: null,
      SelectState: null,
      rowSelectRight: {},
      rowSelectLeft: {},
      selectedRowKeysLeft: [],
      selectedDate: '',
      courseItemList: [],
      examItemList: [],
      frameNumList: [],
      actualPeopleNumList: [],
      CourseDisplay: [],
      InspectDisplay: [],
      // examIdList: [],
      selectedYear: '',
      selectedMonth: '',
      selectedDay: '',
      columnList: [], //2ケタゼロ埋め（表示行）リスト
      groupingFlagList: [], //検査項目のグルーピングフラグリスト
      groupingFlagExamineeList: [], //検査項目のグルーピングフラグリスト(詳細用)
      examArray: [],
      subTitle: '',
      AllMemoFunctionUpdateBtn: true,
      ColumnIsvisible: true
    }

    this.dataGrid = null
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    // 繰り返し実行　開始
    // this.startInterval()

    this.setState({
      WS2577003_CalendarEmptySub: {
        Lio_Date: moment(new Date()).format('YYYY/MM/DD')
      }
    })

    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < columnCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ columnList: array })
    // 初期データ取得
    this.index()
    this.getExamineeList({ date: new Date() })
  }

  /**
   * モーダルが開閉する時
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.childModal) {
      if (this.state.childModal.visible !== prevState.childModal.visible) {
        // 小モーダルが開いている時は繰り返し実行はしない
        if (this.state.childModal.visible) {
          // 繰り返し実行　停止
          clearInterval(this.interval)
        } else {
          // 繰り返し実行　開始
          // this.startInterval()
        }
      }
    }
  }

  /**
   * ページから離れる時
   */
  componentWillUnmount() {
    // 繰り返し実行　停止
    clearInterval(this.interval)
  }

  /**
   * 繰り返し実行　開始
   */
  startInterval() {
    this.interval = setInterval(() => {

      // データ再取得
      this.reloadDisplay()
    }, process.env.REACT_APP_INTERVAL)
  }

  /**
   * 初期データ取得
   */
  index = () => {
    ReserveStatusSearchService.index()
      .then((res) => {
        this.setState({
          screenData: res.data,
          selectedRowKeysLeft: [res.data.DateWithPosition],
          selectedDate: res.data.DateWithPosition
        })
        this.formRef.current?.setFieldsValue({
          ...res.data,
          FacilityType: 1,
          YearsChar: moment(res.data?.YearsDate).isValid()
            ? moment(res.data.YearsDate)
            : moment(),
        })
        // サブタイトル変更
        this.changeSubTitle(this.formRef.current.getFieldValue('PersonsSituation'))

        this.onSearchRecreateDisplay(null, true)
      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onSearchRecreateDisplay = (isClickReDisplayButton, reload, dateCurrent) => {
    const { YearsChar, FacilityType, TimeZone, PersonsSituation } = this.formRef.current.getFieldsValue()

    this.setState({ YearsChar, FacilityType, TimeZone, PersonsSituation, ColumnIsvisible: false, ReserveStatusDisplay: [] })

    ReserveStatusSearchService.recreateDisplay({
      YearsChar: moment(YearsChar).format('YYYY/MM/DD'),
      FacilityType: 1,
      TimeZone,
      PersonsSituation,
      OfficeExtract: this.state.WS2528047_DetailsExtract.Lio_OfficeCode,
      TimeZoneExtract: this.state.WS2528047_DetailsExtract.Lio_TimeZone,
    })
      .then((res) => {
        let datas = res?.data?.ReserveStatusDisplay?.length > 0 ? res?.data?.ReserveStatusDisplay : {}
        let date = dateCurrent ? moment(dateCurrent).format('YYYY/MM/DD') : reload ? moment(new Date()).format('YYYY/MM/DD') : moment(YearsChar).format('YYYY/MM/DD')
        let CourseDisplay = res.data?.CourseDisplay.split('')
        let InspectDisplay = res.data?.InspectDisplay.split('')

        this.setState({
          dataTitle: res.data,
          CourseList: '',
          ExamList: '',
          SelectState: '',
          WS2577003_CalendarEmptySub: {
            Lio_Date: date
          },
          CourseDisplay: CourseDisplay,
          InspectDisplay: InspectDisplay,
          rowSelectRight: datas.find(x => moment(x.date).format('YYYY/MM/DD') == date),
          ReserveStatusDisplay: res.data.ReserveStatusDisplay,
          groupingFlagList: res.data.groupingFlagList,
          ColumnIsvisible: true
        })

        this.forceUpdate()

      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  getExamineeList = ({ date, CourseDisplay = '', InspectDisplay = '' }, bool = false) => {

    ReserveStatusSearchService.getExamineeList({
      date: moment(date).format('YYYY/MM/DD'),
      CourseDisplay,
      InspectDisplay,
      FacilityType: this.state.FacilityType,
      TimeZone: this.state.TimeZone
    })
      .then((res) => {
        let ExamineeList = [...res.data?.ExamineeList]

        let available = 0
        timeFrame.forEach((item) => {
          let peopleCount = 0
          let position = 0
          ExamineeList.forEach((value, index) => {
            if (value.identification === 1) return
            if (value.tentativeReserveFlag !== 0) return
            if (item.time === value.period_time) {
              peopleCount++
              position = index + 1
            }
          })
          available = item.frame - peopleCount
          if (available > 0 && peopleCount > 0) {
            for (let i = 0; i < available; i++) {
              ExamineeList.splice(
                position,
                0,
                {
                  id: 'available' + item.time + i,
                  period_time: item.time
                })
            }
          } else if (available > 0 && peopleCount === 0) {

            for (let j = 0; j < ExamineeList.length; j++) {
              if (ExamineeList[j].tentativeReserveFlag === 0 && ExamineeList[j].identification !== 1 && ExamineeList[j].period_time > item.time) {
                position = j
                break
              } else if (ExamineeList[j].tentativeReserveFlag === 2) {
                position = j
                break
              }
            }

            for (let i = 0; i < available; i++) {
              ExamineeList.splice(
                position,
                0,
                {
                  id: 'available' + item.time + i,
                  period_time: item.time
                })
            }
          }
        })

        let newDataShowColumnExamineeList = {
          ...res.data,
          ExamineeList: ExamineeList
          // ExamineeList: res.data?.ExamineeList
        }

        let newObj1 = res.data?.itemList1
        let Array1 = Object.values(newObj1)
        let newObj2 = res.data?.itemList2
        let Array2 = Object.values(newObj2)

        this.setState({
          dataShowColumnExamineeList: newDataShowColumnExamineeList,
          ExamineeList: ExamineeList,
          // ExamineeList: res.data?.ExamineeList,
          courseItemList: Array1,
          examItemList: Array2,
          frameNumList: res.data?.frameNumList,
          actualPeopleNumList: res.data?.actualPeopleNumList,
          groupingFlagExamineeList: res.data.groupingFlagList,
        })

        try {
          this.formRef.current.setFieldsValue({
            memo: res.data?.title,
            date: moment(date).format('YYYY/MM/DD')
          })
        } catch (error) {
          //
        }

        //モーダルを開くタイミングで不具合が生じたためgetExamineeList内で実行
        if (bool) {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 'fit-content',
              className: '',
              component:
                <ReservationDetails
                  Li_Date={date}
                  Li_Examinee={{ ...res.data }}
                  onFinishScreen={() => {
                    this.closeModal()
                    this.onSearchRecreateDisplay()
                  }}
                />
            },
          })
        }
      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  ExcelDownload = (date) => {
    ReserveStatusSearchService.downloadExcel({
      date: moment(date).format('YYYY/MM/DD'),
    })
      .then((res) => {
        download_file(res)
        message.success('成功')
      })
      .catch((err) => {
        console.log(err);
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 予約削除（キャンセル）
   * @param {*} record
   */
  delete = (record) => {
    const params = {
      StsConfirm: 1,
      course_level: record.course_level,
      reserve_num: record.reserve_num,
    }

    if (record.state_flag === 3) {
      // state_flagが「キャンセル」の場合、削除のAPIを実行
      ReserveStatusSearchService.delete(params)
        .then((res) => {
          message.success(res.data?.message)
          this.onSearchRecreateDisplay()

          // 選択された日付け取得
          let date = this.state.selectedDate
          if (this.state.selectedRowKeysLeft.length > 0) {
            date = this.state.selectedRowKeysLeft[0]
          }
          this.getExamineeList({ date: date })
        })
        .catch((err) => {
          console.log(err);
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    } else {
      // state_flagが「キャンセル」以外の場合、キャンセルのAPIを実行
      ReserveStatusSearchService.cancel(params)
        .then((res) => {
          message.success(res.data?.message)
          if (res.data.Miraisdt0100ID) {
            let miraisParams = {
              Li_ReserveNum: record.reserve_num,
              Li_CourseLevel: record.course_level,
            }
            this.MiraisSingleTransmission(miraisParams)
          }
          this.onSearchRecreateDisplay()

          // 選択された日付け取得
          let date = this.state.selectedDate
          if (this.state.selectedRowKeysLeft.length > 0) {
            date = this.state.selectedRowKeysLeft[0]
          }
          this.getExamineeList({ date: date })
        })
        .catch((err) => {
          console.log(err);
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  /**
   * Mirais単体送信
   * @param {*} params
   */
  MiraisSingleTransmission(params) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2737001_MiraisSingleTransmission
            {...params}
            onFinishScreen={({ Lio_StsReturn }) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  closeModal = () => {
    if (this.state.isCloseModalLoadReserveStatusDisplayAgain) {
      this.onSearchRecreateDisplay()
      this.setState({
        isCloseModalLoadReserveStatusDisplayAgain: false,
      })
    }
    if (this.state.isCloseModalLoadExamListAgain) {
      this.getExamineeList(
        { date: this.state.rowSelectRight?.date }
      )
      this.setState({ isCloseModalLoadExamListAgain: false })
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: '',
        centered: false
      },
    })
  }

  recalculation = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0548001_ReservesPersonsBulkUpdate
            Li_DateF={this.state.YearsChar}
            Li_DateT={this.state.YearsChar}
            Li_Facility={this.state.FacilityType}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
      isCloseModalLoadExamListAgain: true,
    })
  }

  /**
   * 本日
   */
  selectToday = () => {
    let today = new Date().getFullYear() + '/' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '/' + ('0' + (new Date().getDate())).slice(-2)
    let todaySub = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2)
    let thisMonth = new Date().getFullYear() + '/' + ('0' + (new Date().getMonth() + 1)).slice(-2)

    this.setState({
      selectedDate: todaySub,
      selectedRowKeysLeft: [todaySub]
    })
    this.getExamineeList({ date: today })
    this.formRef.current?.setFieldsValue({ YearsChar: thisMonth })
    this.onSearchRecreateDisplay(true)
  }

  displaySetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS2528031_SettingDisplayItem
            Lio_CourseList={
              this.state
                .WS2528031_SettingDisplayItem
                ?.Lio_CourseList
            }
            Lio_ExamList={
              this.state
                .WS2528031_SettingDisplayItem
                ?.Lio_ExamList
            }
            onFinishScreen={({
              Lio_CourseList,
              Lio_ExamList,
            }) => {
              this.setState({
                WS2528031_SettingDisplayItem: {
                  Lio_CourseList,
                  Lio_ExamList,
                },
              })
              this.closeModal()
            }}
          />
        ),
      },
      isCloseModalLoadReserveStatusDisplayAgain: true,
    })
  }

  /**
   * 前月
   */
  previousMonth = () => {
    let newMonth = (Number(this.formRef.current.getFieldValue('YearsChar').format('MM')) - 1)
    let year = newMonth == 0 ? this.formRef.current.getFieldValue('YearsChar').format('YYYY') - 1 : this.formRef.current.getFieldValue('YearsChar').format('YYYY') //1月で前月ボタンを押した場合、前年に
    let month = newMonth == 0 ? 12 : ('00' + newMonth).slice(-2) //1月で前月ボタンを押した場合、12月に
    let day = ('00' + this.formRef.current.getFieldValue('YearsChar').format('DD')).slice(-2)
    let previousMonth = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(previousMonth) })
    this.onSearchRecreateDisplay(previousMonth)
  }

  /**
   * 前年
   */
  previousYear = () => {
    let year = (Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) - 1)
    let month = this.formRef.current.getFieldValue('YearsChar').format('MM')
    let day = this.formRef.current.getFieldValue('YearsChar').format('DD')
    let previousYear = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(previousYear) })
    this.onSearchRecreateDisplay(previousYear)
  }

  /**
   * 次月
   */
  nextMonth = () => {
    let newMonth = (Number(this.formRef.current.getFieldValue('YearsChar').format('MM')) + 1)
    let year = newMonth == 13 ? Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) + 1 : this.formRef.current.getFieldValue('YearsChar').format('YYYY') //12月で次月ボタンを押した場合、翌年に
    let month = newMonth == 13 ? ('00' + 1).slice(-2) : ('00' + newMonth).slice(-2) //12月で次月ボタンを押した場合、1月に
    let day = ('00' + this.formRef.current.getFieldValue('YearsChar').format('DD')).slice(-2)
    let nextMonth = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(nextMonth) })
    this.onSearchRecreateDisplay(nextMonth)
  }

  /**
   * 次年
   */
  nextYear = () => {
    let year = (Number(this.formRef.current.getFieldValue('YearsChar').format('YYYY')) + 1)
    let month = this.formRef.current.getFieldValue('YearsChar').format('MM')
    let day = this.formRef.current.getFieldValue('YearsChar').format('DD')
    let nextYear = (year + '-' + month + '-' + day)
    this.formRef.current.setFieldsValue({ YearsChar: moment(nextYear) })
    this.onSearchRecreateDisplay(nextYear)
  }

  /**
   * 再表示、データ再取得
   */
  reloadDisplay = () => {
    // console.log(this.state.selectedDate)
    this.onSearchRecreateDisplay()
    this.getExamineeList({ date: this.state.selectedDate ?? '' })
  }

  /**
   * サブタイトルの変更
   * @param {*} value
   */
  changeSubTitle = (value) => {
    let title = ''
    if (value === 0) {
      title = '（実人数）'
    }
    if (this.state.subTitle !== title) {
      this.setState({ subTitle: title })
    }
  }

  openMedicalCheckupCalendar = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <MedicalCheckupCalendar />
        ),
      },
    })
  }

  /**
   * 一覧CSV 出力処理
   */
  checkOutputList = () => {
    let examItemList = []
    let courseItemList = []

    // コース表示選定
    this.state.courseItemList.map((item, index) => {
      if (this.state.CourseDisplay[index] == 1) {
        courseItemList.push(item)
      }
    })

    // 検査表示選定
    this.state.examItemList.map((item, index) => {
      const groupingFlag = this.state.groupingFlagExamineeList[index] ?? 0
      if (this.state.InspectDisplay[index] == 1 && !groupingFlag) {
        examItemList.push(item)
      }
    })

    ReserveStatusSearchService.Downloadfile({
      date: moment(this.state.selectedDate).format('YYYY/MM/DD'),
      dataRows: this.state.dataShowColumnExamineeList.ExamineeList,
      courseItemList: courseItemList,
      AllcourseItemList: this.state.courseItemList,
      examItemList: examItemList,
      AllExamItemList: this.state.examItemList,
      InspectDisplay: this.state.InspectDisplay,
      CourseDisplay: this.state.CourseDisplay
    })
      .then((res) => {
        download_file(res);
      })
      .catch((err) => {
        console.log(err);
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 文字数を制限して表示するときに使用
   */
  characterLimit(string, int) {
    if (string.length > int) {
      return string.substr(0, int) + '...'
    } else {
      return string
    }
  }

  /**
   * 検査名をダブルクリックしたときに表示するモーダルを呼ぶ
   * @param {*} name
   * @param {*} index
   */
  examItemDisplay(name, index) {
    console.log(name);
    let ret = ('00' + (index + 1)).slice(-2)
    console.log(ret);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <CustomInspectionDisplay
            name={name}
            date={moment(this.state.selectedDate).format('YYYY/MM/DD') ?? ''}
            index={ret}
          />
        ),
      },
    })
  }

  /**
  * 仮予約取込確定 モーダル
  */
  showTentativeReserveCaptureConfirmed = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <TentativeReserveCaptureConfirmed
          />
        ),
      },
    })
  }

  /**
  * 仮予約出力 モーダル
  */
  showDetermineTentativeReserve = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <DetermineTentativeReserve
          />
        ),
      },
    })
  }

  /**
   * ラベルの変更
   * @returns
   */
  changeLabel(key, id, reserveNum) {
    let params = {
      key: Number(key),
      id: id,
      reserveNum: reserveNum
    }

    ReserveStatusSearchService.changeLabel(params)
      .then((res) => {
        this.reloadDisplay()
      })
  }

  /**
  * csv出力
  * @param {*} e
  */
  csvCreate(e) {
    // Excel
    const workbook = new Workbook();

    // シート指定
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      // Excel出力 ファイル名指定
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), '予約状況検索エクセル出力.xlsx');
      });
    });
  }

  detailsMenu = (id, reserveNum) => {
    return (
      <Menu>
        <Menu.Item
          key='1'
          icon={
            <div style={{ color: labelList[1].color }}>
              <TagFilled />
            </div>
          }
          onClick={(event) => { this.changeLabel(event.key, id, reserveNum) }}
        >
          <label >{labelList[1].label}</label>
        </Menu.Item>
        <Menu.Item
          key='2'
          icon={
            <div style={{ color: labelList[2].color }}>
              <TagFilled />
            </div>
          }
          onClick={(event) => { this.changeLabel(event.key, id, reserveNum) }}
        >
          <label >{labelList[2].label}</label>
        </Menu.Item>
        <Menu.Item
          key='3'
          icon={
            <div style={{ color: labelList[3].color }}>
              <TagFilled />
            </div>
          }
          onClick={(event) => { this.changeLabel(event.key, id, reserveNum) }}
        >
          <label >{labelList[3].label}</label>
        </Menu.Item>
        <Menu.Item
          key='4'
          icon={
            <div style={{ color: labelList[4].color }}>
              <TagFilled />
            </div>
          }
          onClick={(event) => { this.changeLabel(event.key, id, reserveNum) }}
        >
          <label >{labelList[4].label}</label>
        </Menu.Item>
        <Menu.Item
          key='5'
          icon={
            <div style={{ color: labelList[5].color }}>
              <TagFilled />
            </div>
          }
          onClick={(event) => { this.changeLabel(event.key, id, reserveNum) }}
        >
          <label >{labelList[5].label}</label>
        </Menu.Item>
        <Menu.Item
          key='0'
          onClick={(event) => { this.changeLabel(event.key, id, reserveNum) }}
        >
          <label >{labelList[0].label}</label>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    return (
      <div className='reserve-status-search-custom'>
        <Card title={'予約状況検索' + this.state.subTitle + '時間枠表示'}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={this.props.Li_Child ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.recalculation}>
                      人数再計算
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.displaySetting}>
                      表示設定
                    </Menu.Item>
                    <Menu.Item key='3' style={{ width: '100%' }} onClick={this.showDetermineTentativeReserve}>
                      仮予約出力
                    </Menu.Item>
                    <Menu.Item key='4' style={{ width: '100%' }} onClick={this.showTentativeReserveCaptureConfirmed}>
                      仮予約確定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              initialValues={{
                TimeZone: '',
                PersonsSituation: 0,
              }}
            >
              <div className='box_inner_horizontal'>
                <div className='box_inner_horizontal' style={{ gap: '20px' }}>
                  <div className='box_inner_horizontal' style={{ width: 'fit-content' }}>
                    <Tooltip title='前年'>
                      <Button
                        type='primary'
                        icon={<DoubleLeftOutlined />}
                        onClick={() => { this.previousYear() }}
                      />
                    </Tooltip>
                    <Tooltip title='前月'>
                      <Button
                        type='primary'
                        icon={<LeftOutlined />}
                        onClick={() => { this.previousMonth() }}
                      />
                    </Tooltip>
                    <Form.Item name='YearsChar'>
                      <VenusDatePickerCustom
                        picker='month'
                        format='YYYY年MM月'
                        width={128}
                        allowClear={false}
                        onChange={() => this.onSearchRecreateDisplay(true)}
                      />
                    </Form.Item>
                    <Tooltip title='次月'>
                      <Button
                        type='primary'
                        icon={<RightOutlined />}
                        onClick={() => { this.nextMonth() }}
                      />
                    </Tooltip>
                    <Tooltip title='次年'>
                      <Button
                        type='primary'
                        icon={<DoubleRightOutlined />}
                        onClick={() => { this.nextYear() }}
                      />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => this.selectToday()}
                    >
                      本日
                    </Button>
                  </Form.Item>
                  <Form.Item
                    name='TimeZone'
                    label='時間区分'
                  >
                    <Select onChange={() => this.onSearchRecreateDisplay(true)}>
                      <Select.Option value={''}>すべて</Select.Option>
                      <Select.Option value={'AM'}>ＡＭ</Select.Option>
                      <Select.Option value={'PM'}>ＰＭ</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='PersonsSituation'
                    label='人数表示'
                  >
                    <Select
                      onChange={(event) => {
                        this.changeSubTitle(event)
                        this.onSearchRecreateDisplay(true)
                      }}>
                      <Select.Option value={0}>実人数</Select.Option>
                      <Select.Option value={1}>空状況</Select.Option>
                    </Select>
                  </Form.Item>
                  <Button
                    onClick={() => { this.reloadDisplay() }}
                  >
                    再表示
                  </Button>
                  <Button
                    onClick={() => { window.open(location.protocol + '//' + location.host + '/medical-checkup-calendar/medical-checkup-calendar') }}
                  >
                    ドックカレンダー
                  </Button>
                </div>
              </div>

              <div className='box_inner_horizontal' style={{ marginBottom: '10px' }}>
                <div className='box_inner_vertical' style={{ width: '17%' }}>
                  <Spin tip="Loading..." size="small" spinning={!this.state.ColumnIsvisible}>
                    <Skeleton loading={!this.state.ColumnIsvisible}>
                      <Card bodyStyle={cardStyle}>
                        <DataGrid
                          id='customTable'
                          style={{ height: 'calc(100vh - 240px)', fontSize: 11 }}
                          dataSource={this.state.ReserveStatusDisplay}
                          showBorders={true} //周りの線
                          showRowLines={true} //横線
                          wordWrapEnabled={false} //折り返し
                          allowColumnReordering={true} //カラム移動
                          allowColumnResizing={true} //カラムの幅リサイズ
                          columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                          paging={{ enabled: false }} //ページ設定
                          hoverStateEnabled={true} //マウスオーバー設定
                          selection={{ mode: 'single' }}
                          scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない
                          keyExpr='date' //選択行のkeyとなるカラム（一意のカラムを設定する）
                          selectedRowKeys={this.state.selectedRowKeysLeft} //選択行指定（配列）
                          onSelectionChanged={(event) => {

                            // 選択変更
                            try {

                              const recordDate = event.selectedRowKeys
                              if (recordDate.length > 0) {
                                this.getExamineeList({ date: recordDate[0] })
                                // 選択された日付を変更
                                this.setState({
                                  selectedDate: recordDate[0],
                                  selectedRowKeysLeft: recordDate
                                })

                                this.dataGrid.instance.clearSorting()
                              }
                            } catch (error) {
                              //
                            }
                          }}
                          onRowPrepared={(record) => {
                            // 行ごとの背景色設定
                            if (record.rowType === 'data') {
                              if (record.data.holidayColor === 281) {
                                record.rowElement.style.backgroundColor = '#fff5ff' //table-row-holiday
                              } else if (this.formRef.current?.getFieldValue('PersonsSituation') == 1) {
                                record.rowElement.style.backgroundColor = '#fad3d3' //table-row-upperlimit
                              } else {
                                record.rowElement.style.backgroundColor = ''
                              }
                            }
                          }}
                        >
                          <Sorting mode='none' />
                          <Column
                            caption='日付'
                            dataField='dateStr'
                            width={40}
                            fixed={true}
                            alignment='center'
                            cellRender={(event) =>
                              <div
                                style={{
                                  color: event.data.dateColor ? Color(event.data.dateColor)?.Foreground : '',
                                }}
                              >
                                {event.value + ' ' + event.data.weekStr}
                              </div>
                            }
                            visible={this.state.ColumnIsvisible}
                          />
                          <Column
                            width={40}
                            caption='男'
                            dataField='MalePersons'
                            cellRender={(event) => {
                              return event.value === '0' || event.value === 0 ? '' :
                                <div
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: '20%'
                                  }}
                                >
                                  {event.value}
                                </div>
                            }}
                            visible={this.state.ColumnIsvisible}
                          />
                          <Column
                            width={40}
                            caption='女'
                            dataField='WomanPersons'
                            cellRender={(event) => {
                              return event.value === '0' || event.value === 0 ? '' :
                                <div
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: '20%'
                                  }}
                                >
                                  {event.value}
                                </div>
                            }}
                            visible={this.state.ColumnIsvisible}
                          />
                          <Column
                            width={50}
                            caption='計'
                            dataField='totalPersons'
                            cellRender={(event) => {
                              return event.value === '0' || event.value === 0 ? '' :
                                <div
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: '20%'
                                  }}
                                >
                                  {event.value}
                                </div>
                            }}
                            visible={this.state.ColumnIsvisible}
                          />
                          <Column
                            width={50}
                            caption='仮予約'
                            dataField='tentativeReservation'
                            cellRender={(event) => {
                              return (
                                <div
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: '20%'
                                  }}
                                >
                                  {event.value ? event.value : ''}
                                </div>
                              )
                            }}
                            visible={this.state.ColumnIsvisible}
                          />
                          {this.state.columnList.map((item, index) => {
                            const numIndex = index + 1
                            const groupingFlag = this.state.groupingFlagList[index] ?? 0
                            let columnComp = null

                            if (!groupingFlag) {
                              // グルーピングされていないデータだけ表示
                              columnComp = (
                                <Column
                                  key={`table-column-${index}`}
                                  alignment='center'
                                  width={50}
                                  caption={(this.state.dataTitle?.[`columnTitle_${numIndex}`]) ?? ''}
                                  dataField={`customPersonNum_${numIndex}`}
                                  cellRender={(event) => {
                                    return (
                                      event.value === '0' || event.value === 0 ?
                                        '' :
                                        <div
                                          style={{
                                            color: event.data[`skyPersonNumColor_${numIndex}`] ?
                                              Color(event.data[`skyPersonNumColor_${numIndex}`])?.Foreground
                                              :
                                              '',
                                            textAlign: 'right',
                                            paddingRight: '25%'
                                          }}
                                        >
                                          {event.value}
                                        </div>
                                    )
                                  }}
                                  visible={this.state.ColumnIsvisible}
                                />
                              )
                            }
                            return columnComp
                          })}
                          <Column
                            width={200}
                            caption='フリーコメント'
                            dataField='title'
                            cellRender={(event) => {
                              return (
                                <div style={{ textAlign: 'center' }}>{event.value}</div>
                              )
                            }}
                            visible={this.state.ColumnIsvisible}
                          />
                          <Column
                            key='action'
                            width={40}
                            alignment='center'
                            headerCellRender={() =>
                              <div style={{ height: 22 }} />
                            }
                            cellRender={(event) => {
                              if (event.data.weekStr === '数') return <div style={{ minHeight: 22 }} />
                              return (
                                <Dropdown
                                  icon={<MoreOutlined />}
                                  size='small'
                                  trigger='click'
                                  overlay={() => (
                                    <Menu>
                                      <Menu.Item
                                        key='1'
                                        onClick={() => {
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: true,
                                              width: 500,
                                              component: (
                                                <WS2580001_ScheduleChange
                                                  Li_Date={event.data.date}
                                                  onFinishScreen={() => {
                                                    this.onSearchRecreateDisplay(false, false, event.data.date)
                                                  }}
                                                />
                                              ),
                                            },
                                            isCloseModalLoadReserveStatusDisplayAgain: false,
                                          })
                                        }}
                                      >
                                        {/* 2580 */}
                                        <label>メモ欄</label>
                                      </Menu.Item>
                                      <Menu.Item
                                        key='2'
                                        onClick={() => {
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: true,
                                              width: 'fit-content',
                                              centered: true,
                                              component: (
                                                <WS2537001_PersonalReserveProcess
                                                  Li_CourseLevel={''}
                                                  Li_ReserveNum={''}
                                                  Li_PersonalNum={''}
                                                  Li_Date={event.data.date}
                                                  Li_Getctxname={''}
                                                  Li_ProcessDivision={''}
                                                  Li_Option={''}
                                                  Li_Child={true}
                                                  onFinishScreen={() => {
                                                    this.onSearchRecreateDisplay()
                                                  }}
                                                />
                                              ),
                                            },
                                            isCloseModalLoadExamListAgain: false,
                                            isCloseModalLoadReserveStatusDisplayAgain: false,
                                          })
                                        }}
                                      >
                                        {/* 2537 */}
                                        <label>個人予約</label>
                                      </Menu.Item>
                                      <Menu.Item
                                        key='3'
                                        onClick={() => {
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: true,
                                              width: '600px',
                                              component: (
                                                <WS1490001_SpecificDatePeopleNumSetting
                                                  Li_Date={event.data.date}
                                                  Li_Facility={this.state.FacilityType}
                                                  onFinishScreen={() => {
                                                    //データ再取得　休診日が変更になった為
                                                    this.onSearchRecreateDisplay(true)
                                                  }}
                                                />
                                              ),
                                            },
                                          })
                                        }}
                                      >
                                        {/* 232 */}
                                        <label>人数枠設定</label>
                                      </Menu.Item>
                                    </Menu>
                                  )}
                                >
                                  <Button
                                    size='small'
                                    icon={<MoreOutlined />}
                                  />
                                </Dropdown>
                              )
                            }}
                          />
                        </DataGrid>
                      </Card>
                    </Skeleton>
                  </Spin>
                </div>

                <div className='box_inner_vertical' style={{ width: '83%' }}>
                  <Card bodyStyle={cardStyle}>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='date' style={{ marginBottom: 0 }}>
                        <div >
                          {this.state.selectedDate ? moment(this.state.selectedDate).format('YYYY/MM/DD(ddd)') : ''}
                        </div>
                      </Form.Item>
                      <Form.Item
                        name='memo'
                        style={{
                          marginBottom: 0,
                          minWidth: '40%',
                          backgroundColor: '#f3f3f3'
                        }}>
                        <div
                          style={{
                            paddingLeft: '20px',
                            paddingRight: '20px'
                          }}
                        >
                          {this.formRef.current?.getFieldValue('memo')}
                        </div>
                      </Form.Item>
                      <Tooltip title='メモ編集'>
                        <Button
                          type='primary'
                          icon={<EditOutlined />}
                          style={{
                            marginBottom: 0
                          }}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 500,
                                component: (
                                  <WS2580001_ScheduleChange
                                    Li_Date={this.state.selectedDate}
                                    onFinishScreen={(Li_Title) => {
                                      this.formRef.current.setFieldsValue({ 'memo': Li_Title })
                                      this.onSearchRecreateDisplay(false, false, this.state.selectedDate)
                                    }}
                                  />
                                ),
                              },
                              isCloseModalLoadReserveStatusDisplayAgain: false,
                            })
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Card>

                  <Card
                    title={this.props.Li_Date}
                    bodyStyle={cardStyle}
                    style={{ height: '100%' }}
                  >
                    <div className='box_inner_vertical'>

                      <DataGrid
                        ref={(ref) => { this.dataGrid = ref; }}
                        style={{ height: 'calc(100vh - 360px)', fontSize: 11 }}
                        dataSource={this.state.dataShowColumnExamineeList.ExamineeList}
                        showBorders={true} //周りの線
                        showRowLines={true} //横線
                        wordWrapEnabled={false} //折り返し
                        allowColumnReordering={true} //カラム移動
                        allowColumnResizing={true} //カラムの幅リサイズ
                        columnResizingMode={'widget'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                        paging={{ enabled: false }} //ページ設定
                        hoverStateEnabled={true} //マウスオーバー設定
                        selection={{ mode: 'single' }}
                        scrolling={{ mode: 'Standard' }} //スクロールがもっさりしないおまじない
                        columnAutoWidth={true}
                        onSelectionChanged={(event) => { }}

                        onRowPrepared={(event) => {
                          const record = event.data
                          // 行ごとの背景色設定
                          if (event.rowType === 'data') {
                            let color = ''
                            switch (record.tentativeReserveFlag) {
                              case 1: //ドック仮確定
                                color = '#92e1b8'  //table-row-dock-tentative-onfirm
                                break
                              case 2: //仮予約
                                color = '#b8e9a2' //table-row-tentative
                                break
                              case 3: //ドック仮予約
                                color = '#f9f5bf' //table-row-dock-tentative
                                break
                              case 4: //枠取予約
                              case 5: //保留
                              case 6: //キャンセル
                                color = '#c2c2c2' //table-row-waku
                                break
                              case 0:
                                color = (record.identification === 1) ? 'table-row-dock-confirm' : ''
                                break
                              default:
                            }
                            event.rowElement.style.backgroundColor = color
                          }
                        }}

                        onRowDblClick={(event) => {
                          const record = event.data
                          // 選択変更
                          this.setState({
                            rowSelectRight: record
                          })
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              centered: true,
                              component: (
                                <WS2537001_PersonalReserveProcess
                                  Li_CourseLevel={record.course_level}
                                  Li_ReserveNum={record.reserve_num}
                                  Li_PersonalNum={record.personal_number_id}
                                  Li_Date={this.state.selectedDate}
                                  Users={this.state.screenData.Users}
                                  Li_Getctxname={''}
                                  Li_ProcessDivision={''}
                                  Li_Option={''}
                                  Li_Child={true}
                                  onFinishScreen={() => {
                                    this.reloadDisplay()
                                  }}
                                />
                              ),
                            },
                          })
                        }}

                        // Excel出力 ExportとonExportingはセット
                        onExporting={(e) => this.csvCreate(e)}
                      >
                        {/* エクセル出力ボタン */}
                        <Export enabled={true} allowExportSelectedData={true} />

                        <Column
                          caption='時間'
                          dataField='period_time'
                          cssClass='column-size-4'
                          cellRender={(event) => {
                            if (event.rowIndex == 0) {
                              return event.value
                            } else {
                              let before = event.rowIndex - 1
                              try {
                                if (event.value == this.state.ExamineeList[before].period_time) {
                                  return ''

                                } else {
                                  return event.value
                                }
                              } catch (error) {
                                //
                              }
                            }
                          }}

                        />

                        <Column
                          caption='ID'
                          dataField='personal_number_id'
                          cssClass='column-size-5'
                          cellRender={(event) =>
                            <div style={{ textAlign: 'right' }}>{event.value}</div>
                          }
                        />

                        <Column
                          caption='氏名'
                          dataField='KanjiName'
                          cssClass='column-character-10'
                          sortingMethod={(a, b) => {
                            return a.localeCompare(b, 'ja')
                          }}
                          cellRender={(event) => {
                            return (
                              // 10文字以上なら...で省略するため 超えた場合、見えない部分を出すためにTooltipを採用
                              <Tooltip caption={event.value}>
                                <div>{event.value ? this.characterLimit(event.value, 10) : ''}</div>
                              </Tooltip>
                            )
                          }}
                        />

                        <Column
                          caption='生年月日'
                          dataField='DateOfBirth'
                          width={80}
                          cellRender={(event) => <>{event.value ? moment(event.value).format('NNy/MM/DD') : ''}</>}
                        />

                        <Column
                          caption='年'
                          dataField='yearAge'
                          width={40} //ソートあり幅
                          alignment="left"
                          cellRender={(event) =>
                            <div style={{ textAlign: 'right' }}>{event.value}</div>
                          }
                        />

                        <Column
                          caption='性'
                          dataField='Gender'
                          width={40}
                          cellRender={(event) => {
                            return (
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: event.data.sexColorNum
                                    ? Color(event.data.sexColorNum)?.Foreground
                                    : '',
                                }}
                              >
                                {event.value}
                              </div>
                            )
                          }}
                        />

                        <Column
                          caption='ラベル'
                          dataField='label'
                          width={55}
                          alignment="left"
                          cellRender={(event) => {
                            try {
                              if (event.value == undefined || event.value == 0) {
                                return (
                                  <div style={{ textAlign: 'center' }}>
                                    <Dropdown
                                      trigger='click'
                                      size='small'
                                      overlay={() => this.detailsMenu(event.data.id, event.data.reserve_num)}
                                    >
                                      <div>　</div>
                                    </Dropdown>
                                  </div>
                                )
                              } else {
                                return (
                                  <div style={{ textAlign: 'center' }}>
                                    <Dropdown
                                      trigger='click'
                                      size='small'
                                      overlay={() => this.detailsMenu(event.data.id, event.data.reserve_num)}
                                    >
                                      <Button
                                        size='small'
                                        icon={<div style={{ color: labelList[event.value].color }}><TagFilled /></div>}
                                      />
                                    </Dropdown>
                                  </div>
                                )
                              }
                            } catch (error) {
                              //
                            }
                          }}
                        />

                        <Column
                          cssClass='column-size-2'
                          caption='メモ'
                          dataField='memo'
                          cellRender={(event) => {
                            const value = event.value ?? []
                            const record = event.data
                            return (
                              // 仮予約の人はメモを出さないようにするため
                              (record.tentativeReserve) ?
                                <div />
                                :
                                <Tooltip title='メモ画面表示'>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: true,
                                          width: 'fit-content',
                                          centered: true,
                                          component: (
                                            <AllMemosFunction
                                              date={this.state.selectedDate}
                                              reservation_number={record.reserve_num}
                                              personal_number_id={record.personal_number_id}
                                              KanjiName={record.KanjiName}
                                              onFinishScreen={async () => {
                                                await this.reloadDisplay();
                                              }}

                                              // メモ全体入力画面での保存ボタンの状態
                                              onFlagUpdate={(bool) => {
                                                console.log('onFlagUpdate:' + bool);
                                                this.setState({
                                                  AllMemoFunctionUpdateBtn: bool
                                                })
                                              }}
                                            />
                                          ),
                                        }
                                      })
                                    }}
                                  >
                                    {value.length > 0 ?
                                      value.map((items, index) =>
                                        <div
                                          key={index}
                                          style={{ display: 'flex' }}
                                        >
                                          <div
                                            style={{ color: items.color }}
                                          >
                                            {items.text}
                                          </div>
                                          {((value.length - 1) > index) ?
                                            <div>
                                              {'・'}
                                            </div>
                                            :
                                            null
                                          }
                                        </div>
                                      )
                                      :
                                      <div>{'　'}</div>
                                    }
                                  </div>
                                </Tooltip>
                            )
                          }}
                        />

                        <Column
                          cssClass='column-size-2'
                          caption='禁忌'
                          dataField='taboo'
                          cellRender={(event) => {
                            const value = event.value ?? ''
                            const record = event.data
                            return (
                              // 仮予約の人はメモを出さないようにするため
                              (record.tentativeReserve) ?
                                <div />
                                :
                                <Tooltip title='禁忌画面表示'>
                                  <div
                                    style={{ textAlign: 'center' }}
                                    onClick={() => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: true,
                                          width: 'fit-content',
                                          centered: true,
                                          component: (
                                            <AllMemosFunction
                                              date={this.state.selectedDate}
                                              reservation_number={record.reserve_num}
                                              personal_number_id={record.personal_number_id}
                                              KanjiName={record.KanjiName}
                                              onFinishScreen={async () => {
                                                await this.reloadDisplay();
                                              }}

                                              // メモ全体入力画面での保存ボタンの状態
                                              onFlagUpdate={(bool) => {
                                                console.log('onFlagUpdate:' + bool);
                                                this.setState({
                                                  AllMemoFunctionUpdateBtn: bool
                                                })
                                              }}
                                            />
                                          ),
                                        }
                                      })
                                    }}
                                  >
                                    {value.length > 0 ?
                                      <div>{event.value}</div>
                                      :
                                      <div>{'　'}</div>
                                    }
                                  </div>
                                </Tooltip>
                            )
                          }}
                        />

                        <Column
                          cssClass='column-character-15'
                          caption='事業所名称'
                          dataField='office_kanji_name'
                          cellRender={(event) => {
                            return (
                              // 15文字以上なら...で省略するため 超えた場合、見えない部分を出すためにTooltipを採用
                              <Tooltip caption={event.value}>
                                <div>{event.value ? this.characterLimit(event.value, 15) : ''}</div>
                              </Tooltip>
                            )
                          }}
                        />

                        <Column
                          caption='主コース'
                          cssClass='column-size-40'
                          dataField='contract_short_name'
                          sortingMethod={(a, b) => {
                            return a.localeCompare(b, 'ja')
                          }}
                        />

                        <Column
                          caption='OP'
                          width={45}
                          dataField='options'
                          cellRender={(event) => {
                            const value = event.value
                            return (
                              value ?
                                <Tooltip
                                  title={() => {
                                    return (
                                      value.map((value, index) => (
                                        <div key={index}>{value.set_name}</div>
                                      )))
                                  }}
                                >
                                  <div style={{ textAlign: 'center' }}>{value.length > 0 ? '〇' : ''}</div>
                                </Tooltip>
                                : null
                            )
                          }}
                        />

                        {
                          this.state.courseItemList.map((item, index) => {
                            return (
                              this.state.CourseDisplay[index] == 1 ?
                                <Column
                                  key={index}
                                  caption={item}
                                  width={45}
                                  cellRender={(event) => {
                                    return (
                                      <div style={{ textAlign: 'center' }}>{event.data.reservation_display_item_number == (index + 1) ? '〇' : '　'}</div>
                                    )
                                  }}
                                /> : null
                            )
                          })
                        }

                        {
                          this.state.examItemList.map((item, index) => {
                            const groupingFlag = this.state.groupingFlagExamineeList[index] ?? 0
                            return (
                              (this.state.InspectDisplay[index] == 1 && !groupingFlag) ?
                                <Column
                                  key={index}
                                  caption={item}
                                  width={45}
                                  cellRender={(event) => {
                                    const record = event.data ?? ''
                                    const examStatus = record.exam_status ? record.exam_status.split(',') : []
                                    const status = examStatus[index] ?? ''
                                    let label = ''
                                    if (status === '1') {
                                      label = '〇'
                                    } else if (status === '0' || status === '') {
                                      label = '　'
                                    } else {
                                      // MRIやCTなどの予約時間
                                      label = status
                                    }
                                    return (
                                      <div style={{ textAlign: 'center' }}>{label}</div>
                                    )
                                  }}
                                /> : null
                            )
                          })
                        }

                        <Column
                          caption='案内状'
                          width={45}
                          dataField='send'
                          cellRender={(event) => {
                            return (
                              <div style={{ textAlign: 'center' }}>{event.value === 1 ? '〇' : ''}</div>
                            )
                          }}
                        />

                        <Column
                          caption='担当'
                          cssClass='column-size-40'
                          dataField='user'
                        />

                        <Column
                          caption='状態'
                          dataField='ReserveState'
                        />

                        <Column
                          caption='電子カルテ'
                          cssClass='column-size-5'
                          dataField='miraisSendStatus'
                          cellRender={(event) => (
                            <Tooltip caption={event.data.miraisSendStatusTooltip}>
                              <div style={{ textAlign: 'center' }}>{event.value}</div>
                            </Tooltip>
                          )}
                        />

                        <Column
                          key='action'
                          alignment='center'
                          width={40}
                          fixed={true}
                          fixedPosition='right'
                          cellRender={(event) => {
                            const record = event.data
                            return (
                              <Dropdown
                                trigger='click'
                                size='small'
                                overlay={() => (
                                  <Menu>
                                    <Menu.Item
                                      key='1'
                                      onClick={() => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: true,
                                            width: 1000,
                                            component: (
                                              <WS2583001_ConsultInquirySub
                                                Li_ReserveNum={record.reserve_num}
                                                Li_PersonalNum={record.personal_number_id}
                                                Users={this.state.screenData.Users}
                                                onFinishScreen={() => {
                                                  this.closeModal()
                                                }}
                                              />
                                            ),
                                          },
                                        })
                                      }}
                                    >
                                      {/* 2583 */}
                                      <label>照会</label>
                                    </Menu.Item>
                                    <Menu.Item
                                      key='3'
                                      onClick={() => {
                                        Modal.confirm({
                                          caption: '確認',
                                          icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
                                          okButtonProps: {
                                            danger: true,
                                            icon: <DeleteOutlined />
                                          },
                                          content: '予約を削除しますか？',
                                          okText: <span className='btn_label'>{'削除'}</span>,
                                          cancelText: 'キャンセル',
                                          onOk: () => {
                                            this.delete(record)
                                          },
                                        })
                                      }}
                                    >
                                      <label>削除</label>
                                    </Menu.Item>
                                    <Menu.Item
                                      key='4'
                                      onClick={() => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: true,
                                            width: '40%',
                                            component: (
                                              <WS0650001_DocumentBatchCreateSub
                                                Li_CourseLevel={record.course_level}
                                                Li_ReserveNum={record.reserve_num}
                                                onFinishScreen={() => {
                                                  this.closeModal()
                                                }}
                                              />
                                            ),
                                          },
                                        })
                                      }}
                                    >
                                      {/* WS0650001 */}
                                      <label>資料出力</label>
                                    </Menu.Item>
                                    <Menu.Item
                                      key='5'
                                      hidden={record.state_flag === 0 || record.state_flag === 3}
                                      onClick={() => {
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: true,
                                            width: 'fit-content',
                                            component: (
                                              <WS3020036_CoupledPrintInstruction
                                                Li_CourseLevel={record.course_level}
                                                Li_ReserveNum={record.reserve_num}
                                                onFinishScreen={(obj) => {
                                                  this.closeModal()
                                                }}
                                              />
                                            ),
                                          },
                                        })
                                      }}
                                    >
                                      {/* WS0802001 */}
                                      <label>結果印刷</label>
                                    </Menu.Item>
                                  </Menu>
                                )}
                              >
                                <Button
                                  size='small'
                                  icon={<MoreOutlined />}
                                />
                              </Dropdown>
                            )
                          }}
                        />
                      </DataGrid>
                    </div>

                    <div style={{ position: 'absolute', bottom: 10 }} >
                      <div style={{ border: '1px solid #808080', width: 'fit-content' }}>
                        <Row>
                          {this.state.examItemList.map((item, index) => {
                            let frameNum = this.state.frameNumList[index]
                            let actualPeopleNum = this.state.actualPeopleNumList[index]

                            return (
                              this.state.InspectDisplay[index] == 1 ?
                                <Col
                                  style={{ width: 60 }}
                                  key={index}
                                  onDoubleClick={() => this.examItemDisplay(item, index)}>
                                  <div className='style-div-title'>{item}</div>
                                  <div
                                    size='small'
                                    style={{ width: '100%', textAlign: 'center', background: (frameNum != 0 && actualPeopleNum >= frameNum) ? '#ff6455' : '' }}
                                    className='style-form-item'
                                  >
                                    {frameNum == 0 ? actualPeopleNum : actualPeopleNum + '/' + frameNum}
                                  </div>
                                </Col>
                                :
                                null
                            )
                          })}
                        </Row>
                      </div>
                    </div>

                  </Card>
                </div>
              </div>


              <div className='box_inner_horizontal'>
                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 900,
                        component: (
                          <WS2577003_CalendarEmptySub
                            Lio_Date={this.state.YearsChar}
                            onFinishScreen={({ Lio_Date }) => {
                              this.setState({
                                WS2577003_CalendarEmptySub: {
                                  Lio_Date: Lio_Date,
                                },
                              })
                              this.formRef.current.setFieldsValue({
                                YearsChar: moment(Lio_Date).format('YYYY/MM'),
                              })
                              this.onSearchRecreateDisplay(true, false, Lio_Date)
                              this.closeModal()
                            }}
                          />
                        ),
                      },
                    })
                  }}
                >
                  受入状況
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '90%',
                        component: (
                          <WS0248001_PersonalInfoSearchQuery
                            date={this.state.selectedDate}
                          />
                        ),
                      },
                    })
                  }}
                >
                  個人情報
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 'fit-content',
                        centered: true,
                        component: (
                          <WS2537001_PersonalReserveProcess
                            Li_Date={this.state.selectedDate}
                            Li_Child={true}
                            Users={this.state.screenData.Users}
                            onFinishScreen={() => {
                              this.onSearchRecreateDisplay()
                              this.getExamineeList({ date: this.state.selectedDate })
                            }}
                          />
                        ),
                      },
                      isCloseModalLoadExamListAgain: false,
                      isCloseModalLoadReserveStatusDisplayAgain: false,
                    })
                  }}
                >
                  個人予約
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '80%',
                        component: (
                          <WS2532001_GroupBookings
                            Li_ReserveDate={
                              this.state.selectedRowRecordReserveStatusDispla?.date
                            }
                            Li_Way={''}
                            onFinishScreen={() => {
                              this.closeModal()
                              this.onSearchRecreateDisplay()
                            }}
                          />
                        ),
                      },
                    })
                  }}
                >
                  団体予約
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '70%',
                        component: (
                          // 事業所およびコース選択時にレイアウトが崩れるため検索ボックスとテーブル幅を合わせることを断念
                          <FrameReserve
                            modalFlag={true}
                            Li_ReserveDate={
                              this.formRef.current
                                ? this.formRef.current.getFieldValue('YearsChar')
                                : ''
                            }
                            onFinishScreen={() => {
                              this.closeModal()
                              this.onSearchRecreateDisplay()
                            }}
                          />
                        ),
                      },
                    })
                  }}
                >
                  枠取予約
                </Button>
                <div className='box_inner_horizontal' style={{ justifyContent: 'right' }}>
                  {/* <Button
                    type='primary'
                  >
                    一覧印刷
                  </Button> */}

                  <Button
                    type='primary'
                    onClick={() => {
                      this.checkOutputList();
                    }}
                  >
                    <span className='btn_label'>
                      一覧CSV
                    </span>
                  </Button>

                  {/* <Button
                    type='primary'
                  >
                    詳細一覧CSV
                  </Button> */}
                </div>
              </div>
            </Form >
          </div >
        </Card >

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.AllMemoFunctionUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    AllMemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveStatusSearchCustom)
