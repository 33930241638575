import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  InputNumber,
  Input,
  Select,
  Button,
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Modal,
  Menu,
  Dropdown,
  message,
  Spin,
  Tooltip
} from "antd";

import {
  DeleteOutlined,
  SaveOutlined,
  SearchOutlined,
  MoreOutlined,
  PlusOutlined,
  CaretDownOutlined
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Color from "constants/Color";
import { width } from "dom-helpers";

import { ModalCustom } from "components/Commons/ModalCustom";
import { ModalConfirm } from "components/Commons/ModalConfirm";
import Menubar from "components/Commons/Menubar";

import WS1181001_GovernmentTubeConvertMaintain from "./WS1181001_GovernmentTubeConvertMaintain";
import axios from "configs/axios";
import WS0865001_UseInspectInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0865001_UseInspectInquiry";

import { generateUUID } from 'components/Commons/generateUUID'

class WS1180001_GovernmentTubeParam extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    // Li_format : PropTypes.any,
    // Li_remarks: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS05910:政管パラメータ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      count: "a",
      isLoadingForm: false,
      isLoadingTable: false,

      dataSource: [],
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      menuItems: [
        { id: 1, label: "複写", handleClick: this.eventF9 },
        { id: 2, label: "複写", handleClick: this.eventF9 }
      ]
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  eventF9 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "70%",
        component: (
          <WS1181001_GovernmentTubeConvertMaintain
            // Lio_SiteClassify={this.state.rowSelectMain.site_classification_code}
            onFinishScreen={output => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  eventF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "60%",
        component: (
          <WS0865001_UseInspectInquiry
            onFinishScreen={output => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData = params => {
    this.setState({ isLoadingTable: true, dataAddNew: null });
    axios
      .get("/api/associate-insure-param-maintain/government-tube-param/getScreenData", {
        params: {
          params: params
        }
      })
      .then(res => {

        let tmp = res.data.map(item => ({ ...item, changed: false }))
        this.setState({ dataSource: tmp })


      })
      .catch(error => {
        message.error(error.response.data.message)
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  };

  updateOrCreateRecord(record) {

    const params = {
      params: record
    }
    axios
      .get("/api/associate-insure-param-maintain/government-tube-param/updateOrCreate", params)
      .then(res => {
        message.success('保存が完了しました');
        this.getScreenData();
      })
      .catch(err => {
        message.error(err.response.data.message)
      });
  }

  deleteRecord(record) {

    const params = {
      params: record
    };

    Modal.confirm({
      width: "250px",
      content: "削除を行いますか ?",
      okText: "はい",
      cancelText: "いいえ",
      onOk: () => {
        console.log('deleteRecord:onOk', record);
        axios
          .get("/api/associate-insure-param-maintain/government-tube-param/deleteRecord", params)
          .then(res => {
            message.success(res?.data?.message);
            this.getScreenData();
          })
          .catch(err => {
            message.error(err.response.data.message)
          });
      }
    });
  }

  renderMenuBar = () => (
    <Space>
      <Button type="primary" onClick={this.eventF9}>
        変換保守
      </Button>
      {/* <Button type="primary" onClick={this.eventF10}>
        検査照会
      </Button> */}
    </Space>
  );

  addNewData() {
    this.handleAdd();
  }

  handleAdd() {

    const newData = {
      id: generateUUID(),
      seq: 0,
      remarks: 0,
      kind: "",
      attribute: "",
      number_of_digits: 0,
      position: 0,
      set_pattern: "",
      error_checking: "",
      option_remark: ""
    };

    const dataSource = [...this.state.dataSource]
    this.setState({ dataSource: [] })
    dataSource.length > 0 ? dataSource.unshift(newData) : dataSource.push(newData)
    this.setState({ dataSource: dataSource })

  }

  changeValue = (key, record, event) => {
    if (record[key] !== event.target.value) {
      record[key] = event.target.value
      record.changed = true
      this.forceUpdate()
    }
  }

  render() {
    const { childModal } = this.state;
    return (
      <div className="government-tube-param">
        <Card title="V4-VNS05910:政管パラメータ">
          <Form ref={this.formRef} onFinish={this.onFinish} autoComplete="off">
            {this.renderMenuBar()}
            <Table
              bordered
              dataSource={this.state.dataSource}
              loading={this.state.isLoadingTable}
              pagination={false}
              rowKey={record => record.id}
              scroll={{ y: "85vh" }}
              size="small"
              style={{ marginTop: "15px" }}
            >
              <Table.Column
                width={'5%'}
                align={'right'}
                title="SEQ"
                dataIndex="seq"
                showSorterTooltip={false}
                sorter={(a, b) => a.seq - b.seq}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} style={{ textAlign: 'right' }} defaultValue={value}
                      onBlur={(e) => { this.changeValue('seq', record, e) }}
                      onPressEnter={(e) => { this.changeValue('seq', record, e) }}
                    />
                  );
                }}
              />

              <Table.Column
                width={'15%'}
                title="備　考"
                dataIndex="remarks"
                showSorterTooltip={false}
                sorter={(a, b) => a.remarks.localeCompare(b.remarks, 'jp')}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} defaultValue={value}
                      onBlur={(e) => { this.changeValue('remarks', record, e) }}
                      onPressEnter={(e) => { this.changeValue('remarks', record, e) }}
                    />

                  );
                }}
              />

              <Table.Column
                width={'10%'}
                title="種　別"
                dataIndex="kind"
                showSorterTooltip={false}
                sorter={(a, b) => a.kind.localeCompare(b.kind)}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} defaultValue={value}
                      onBlur={(e) => { this.changeValue('kind', record, e) }}
                      onPressEnter={(e) => { this.changeValue('kind', record, e) }}
                    />
                  );
                }}
              />

              <Table.Column
                width={'4%'}
                align={'center'}
                title="属"
                dataIndex="attribute"
                showSorterTooltip={false}
                sorter={(a, b) => a.attribute.localeCompare(b.attribute)}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} style={{ textAlign: 'center' }} defaultValue={value}
                      onBlur={(e) => { this.changeValue('attribute', record, e) }}
                      onPressEnter={(e) => { this.changeValue('attribute', record, e) }}
                    />

                  );
                }}
              />

              <Table.Column
                width={'10%'}
                align={'right'}
                title="桁数"
                dataIndex="number_of_digits"
                showSorterTooltip={false}
                sorter={(a, b) => a.number_of_digits - b.number_of_digits}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} style={{ textAlign: 'right' }} defaultValue={value}
                      onBlur={(e) => { this.changeValue('number_of_digits', record, e) }}
                      onPressEnter={(e) => { this.changeValue('number_of_digits', record, e) }}
                    />
                  );
                }}
              />

              <Table.Column
                width={'10%'}
                title="位置"
                dataIndex="position"
                showSorterTooltip={false}
                sorter={(a, b) => a.position - b.position}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} defaultValue={value}
                      onBlur={(e) => { this.changeValue('position', record, e) }}
                      onPressEnter={(e) => { this.changeValue('position', record, e) }}
                    />
                  );
                }}
              />

              <Table.Column
                width={'7%'}
                title="設定"
                dataIndex="set_pattern"
                showSorterTooltip={false}
                sorter={(a, b) => a.set_pattern.localeCompare(b.set_pattern)}
                render={(value, record) => {

                  return (
                    <Input maxLength={40} defaultValue={value}
                      onBlur={(e) => { this.changeValue('set_pattern', record, e) }}
                      onPressEnter={(e) => { this.changeValue('set_pattern', record, e) }}
                    />
                  );
                }}
              />

              <Table.Column
                width={'4%'}
                align={'center'}
                title="チェック"
                dataIndex="error_checking"
                showSorterTooltip={false}
                sorter={(a, b) => a.error_checking.localeCompare(b.error_checking)}
                render={(value, record) => {
                  return (
                    <Input maxLength={40} style={{ textAlign: 'center' }} defaultValue={value}
                      onBlur={(e) => { this.changeValue('error_checking', record, e) }}
                      onPressEnter={(e) => { this.changeValue('error_checking', record, e) }}
                    />
                  );
                }}
              />

              <Table.Column
                width={'30%'}
                title="オプション"
                dataIndex="option_remark"
                showSorterTooltip={false}
                sorter={(a, b) => a.option_remark.localeCompare(b.option_remark)}
                render={(value, record) => {
                  return (
                    <Input maxLength={40} defaultValue={value}
                      onBlur={(e) => { this.changeValue('option_remark', record, e) }}
                      onPressEnter={(e) => { this.changeValue('option_remark', record, e) }}
                    />

                  );
                }}
              />

              <Table.Column
                width={'5%'}
                align={"center"}
                title={
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => this.addNewData()}
                  ></Button>
                }
                render={(text, record, index) => {
                  return (
                    <Space>

                      <Button
                        size="small"
                        onClick={() => {
                          this.updateOrCreateRecord(record);
                        }}
                        style={{ color: "#42b10b", border: "none", marginRight: "5px" }}
                        icon={<SaveOutlined style={{ color: !record.changed ? 'gainsboro' : 'green' }} />}
                        disabled={!record.changed}
                      ></Button>
                      <Button
                        size="small"
                        style={{ border: "none" }}
                        onClick={() => this.deleteRecord(record)}
                        danger
                        icon={<DeleteOutlined />}
                      ></Button>

                    </Space>
                  );
                }}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1180001_GovernmentTubeParam);
