import { message, Modal } from 'antd'
import MemoFunctionService from 'services/Others/ReserveStatusSearch/MemoFunctionService.js'

const MemoFunctionAction = {
  saveMemo(data) {
    return MemoFunctionService.saveMemo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteMemo(data) {
    return MemoFunctionService.deleteMemo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  savePersonalMemo(data) {
    return MemoFunctionService.savePersonalMemo(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },

  getHistoryData(data) {
    return MemoFunctionService.getHistoryData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getTabooOptions(data) {
    return MemoFunctionService.getTabooOptions(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  saveTabooOptions(data) {
    return MemoFunctionService.saveTabooOptions(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteTabooOptions(data) {
    return MemoFunctionService.deleteTabooOptions(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getPersonalInfo(data) {
    return MemoFunctionService.getPersonalInfo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getRemarksHistoryData(data) {
    return MemoFunctionService.getRemarksHistoryData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default MemoFunctionAction
