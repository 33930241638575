import React from "react";

import {
  Card, Form, Table, Button, Tooltip, Input
} from "antd";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import WS0309501_BillingEditing from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309501_BillingEditing.jsx'
import WS0309502_BillingTypeSelect from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309502_BillingTypeSelect.jsx'

import ModalDraggable from "components/Commons/ModalDraggable";
import BillingAddressSettingAction from "redux/basicInfo/ContractInfoMaintain/BillingAddressSetting.actions.js"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import Images from 'constants/Images';

class WS0309500_BillingAddressSettingMock extends React.Component {
  formRef = React.createRef();
  refCicContract = React.createRef();
  refCicOption = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "契約作成";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      isLoadingContractData: false,

      dataSource: [],
      columnList: [],
      contractTermData: {},
      itemList: [],
      contract_id: 0,
      course_start_date_on: '',
      course_end_date_on: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      contractTermData: this.props.contractTermData,
      contract_id: this.props.contractTermData.id,
      course_start_date_on: this.props.course_start_date_on,
      course_end_date_on: this.props.course_end_date_on
    })
    this.loadTableData()
  }

  loadTableData = () => {
    let params = {
      contract_type: this.props.contractTermData.contract_type,
      contract_office_code: this.props.contractTermData.contract_office_code,
      contract_start_date_on: this.props.contractTermData.contract_start_date_on,
      contract_number: this.props.contractTermData.contract_number,
      medical_exam_course: this.props.contractTermData.medical_exam_course,
      contract_id: this.props.contractTermData.id
    }
    BillingAddressSettingAction.loadTableData(params)
      .then((res) => {
        let newItemList = []
        res.tableData.map((values) => {
          newItemList.push({
            set_code: values.set_code,
            data_division: values.data_division
          })
        })

        this.formRef.current?.setFieldsValue({
          anotherDataSource: res.anotherTableData
        })

        this.setState({
          columnList: res.columnList,
          dataSource: res.tableData,
          itemList: newItemList,
          anotherDataSource: res.anotherTableData
        })
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  addOfficeBilling = (recordData, billingType) => {
    let params = {
      contract_id: this.state.contract_id,
      itemList: this.state.itemList,
      billing_type: billingType,
      office_code: recordData.office_code ?? '',
      branch_store_code: recordData.branch_store_code,
    }
    BillingAddressSettingAction.addBillingAddress(params)
      .then((res) => { this.loadTableData() })
  }

  addInsurerBilling = (recordData, billingType) => {
    let params = {
      contract_id: this.state.contract_id,
      itemList: this.state.itemList,
      billing_type: billingType,
      insurer_code: recordData.insurer_code ?? '',
    }
    BillingAddressSettingAction.addBillingAddress(params)
      .then((res) => { this.loadTableData() })
  }

  addPersonalbilling = (recordData, billingType) => {
    let params = {
      contract_id: this.state.contract_id,
      itemList: this.state.itemList
    }
    BillingAddressSettingAction.addBillingAddress(params)
      .then((res) => { this.loadTableData() })
  }

  deleteColumn = (values) => {
    let params = {
      contract_type: this.state.contractTermData.contract_type,
      contract_office_code: this.state.contractTermData.contract_office_code,
      contract_start_date_on: this.state.contractTermData.contract_start_date_on,
      contract_number: this.state.contractTermData.contract_number,
      contract_id: this.state.contract_id,
      serial_number: values.serial_number,
      itemList: this.state.itemList,
      course_start_date_on: this.state.course_start_date_on,
      course_end_date_on: this.state.course_end_date_on
    }

    BillingAddressSettingAction.deleteAddress(params)
      .then((res) => { this.loadTableData() })
  }

  confirmButton = () => {
    this.props.onFinishScreen()
  }

  render() {
    return (
      <div className="billing-address-setting">
        <Card title='請求先設定（モック）'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className="box_container">
              <Table
                size='small'
                dataSource={this.state.dataSource}
                rowKey={record => record.id}
                pagination={false}
                bordered
                scroll={{ y: resizableTableScroll(150), x: 'max-content' }}
              >
                <Table.Column
                  title='名称'
                  dataIndex='name'
                  className="column-size-40"
                  render={(value, record) => {
                    return (
                      <div className="box_inner_horizontal">
                        <div><img src={Images(record.data_division == 20 ? 10 : record.data_division)} /></div>
                        <div>{value}</div>
                      </div>
                    )
                  }}
                />
                {
                  this.state.columnList.map((value, index) => {
                    let bill = 'bill_' + (index + 1)
                    return (
                      <Table.Column
                        title={() => {
                          return (
                            <Tooltip title={value.kanji_name}>
                              <div className="box_inner_horizontal">
                                <div>{value.kanji_name.substr(0, 7)}</div>
                                <Button
                                  icon={<CloseOutlined />}
                                  size='small'
                                  style={{ border: 'none', marginLeft: 'auto' }}
                                  onClick={() => this.deleteColumn(value)}
                                />
                              </div>
                            </Tooltip>
                          )
                        }}
                        key={index}
                        width='140px'
                        render={(item, record, index) => {
                          let newIndex = null
                          this.state.anotherDataSource.map((element, index) => {
                            if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                              newIndex = index
                            }
                          })
                          return (
                            <Form.Item name={['anotherDataSource', newIndex, 'total_price']}>
                              <Input
                                style={{ textAlign: 'right' }}
                              />
                            </Form.Item>
                            // <div
                            //   style={{ textAlign: 'right' }}
                            //   onDoubleClick={() => {
                            //     this.setState({
                            //       childModal: {
                            //         ...this.state.childModal,
                            //         visible: true,
                            //         width: 400,
                            //         component: (
                            //           <WS0309501_BillingEditing
                            //             values={record[bill]}
                            //             examName={record.name}
                            //             contract_id={this.state.contract_id}
                            //             contract_type={this.state.contractTermData.contract_type}
                            //             contract_office_code={this.state.contractTermData.contract_office_code}
                            //             contract_start_date_on={this.state.contractTermData.contract_start_date_on}
                            //             contract_number={this.state.contractTermData.contract_number}
                            //             medical_exam_course={this.state.contractTermData.medical_exam_course}
                            //             data_division={record.data_division}
                            //             rounding={this.props.rounding}
                            //             taxRate={this.props.taxRate}
                            //             set_code={record.set_code}
                            //             course_start_date_on={this.state.course_start_date_on}
                            //             course_end_date_on={this.state.course_end_date_on}
                            //             onFinishScreen={() => {
                            //               this.loadTableData()
                            //               this.closeModal()
                            //             }}
                            //           />
                            //         ),
                            //       },
                            //     });
                            //   }}
                            // >
                            //   {record[bill].total_price ?? 0}
                            // </div>
                          )
                        }}
                      />
                    )
                  })
                }
                <Table.Column
                  key='action'
                  fixed='right'
                  align='center'
                  className="column-size-2"
                  title={(
                    <Button type="primary" size="small" title="新規" icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => {
                      this.setState({
                        ...this.state,
                        childModal: {
                          width: 300,
                          visible: true,
                          component: (
                            <WS0309502_BillingTypeSelect
                              contractTermData={this.state.contractTermData}
                              columnList={this.state.columnList}
                              itemList={this.state.itemList}
                              onAddOfficeBilling={(output) => {
                                this.addOfficeBilling(output.recordData, output.billingType)
                                this.closeModal()
                              }}
                              onAddInsurerBilling={(output) => {
                                this.addInsurerBilling(output.recordData, output.billingType)
                                this.closeModal()
                              }}
                              onAddPersonalBilling={() => {
                                this.addPersonalbilling()
                                this.closeModal()
                              }}
                            />
                          )
                        }
                      });
                    }} />
                  )}
                />
              </Table>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  onClick={() => this.confirmButton()}
                >
                  <span className="btn_label">
                    確定
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }

}
export default WS0309500_BillingAddressSettingMock;
