import { connect } from 'react-redux'
import { Card, Dropdown, Form, Input, Menu, message, Modal, Select, Space, Table, Button, Tooltip, Radio, Checkbox } from 'antd'
import { CopyOutlined, DeleteOutlined, MoreOutlined, PlusOutlined, SaveOutlined, ScissorOutlined, SearchOutlined, SnippetsOutlined, MenuOutlined } from '@ant-design/icons'
import React from 'react'

import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx";


const formItemStyle = {
  labelCol: { style: { width: 60 } },
}

class WS2737006_MiraisSingleDoctorSettingAdd extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'Mirais検査保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      courseName: '',
      doctorList: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    console.log(this.props.record);
    if (this.props.record) {
      this.formRef.current.setFieldsValue({
        medical_exam_course: this.props.record.visit_course,
        week: this.props.record.weekNumber,
        doctor: this.props.record.doctor,
      })

      this.setState({
        courseName: this.props.record.visit_course_name ?? ''
      })
    }
  }

  /**
   * 保存ボタンが押された処理
   */
  saveAndUpdateData = () => {
    let valse = this.formRef.current?.getFieldsValue()

    let params = {
      ...valse,
      id: this.props.record?.id ?? '',
      courseName: this.state.courseName
    }

    this.props.onFinishScreen(params)
  }

  /**
   * 削除ボタンが押された処理
   */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      loaddingTable: false
    })
  }

  render() {
    return (
      <div className='mirais-single-doctor-setting'>
        <Card title={'医師設定：' + (this.props.record ? '変更' : '新規')}>
          <Form ref={this.formRef} autoComplete='off'  >
            <div className='box_container'>
              <Form.Item
                name='medical_exam_course'
                label='コース'
                {...formItemStyle}
              >
                <Input.Search
                  className='input-search-size-number-7'
                  onSearch={() =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 1200,
                        component: (
                          <WS0265001_BasicCourseInquiry
                            onFinishScreen={({ recordData }) => {
                              this.formRef.current?.setFieldsValue({
                                medical_exam_course: recordData.course_code
                              })

                              this.setState({
                                courseName: recordData.course_name_formal
                              })
                              this.closeModal();
                            }}
                          />
                        )
                      }
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                label='コース名'
                {...formItemStyle}
              >
                {this.state.courseName}
              </Form.Item>

              <Form.Item
                name='week'
                label='曜日'
                {...formItemStyle}
              >
                <Checkbox.Group
                  options={
                    [
                      { label: '日曜', value: 1 },
                      { label: '月曜', value: 2 },
                      { label: '火曜', value: 3 },
                      { label: '水曜', value: 4 },
                      { label: '木曜', value: 5 },
                      { label: '金曜', value: 6 },
                      { label: '土曜', value: 7 }
                    ]
                  }
                />
                {/* <Radio.Group>
                  <Radio value={1}>日曜</Radio>
                  <Radio value={2}>月曜</Radio>
                  <Radio value={3}>火曜</Radio>
                  <Radio value={4}>水曜</Radio>
                  <Radio value={5}>木曜</Radio>
                  <Radio value={6}>金曜</Radio>
                  <Radio value={7}>土曜</Radio>
                </Radio.Group> */}
              </Form.Item>

              <Form.Item
                name='doctor'
                label='医師名'
                {...formItemStyle}
              >
                <Input className='input-size-30' />
              </Form.Item>


              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  danger
                  icon={<DeleteOutlined />}
                  style={{ display: this.props.record ? '' : 'none' }}
                  onClick={() => {
                    // 確認モーダルを表示してから、削除
                    Modal.confirm({
                      content: `提示の取り消しを行いますか?`,
                      okText: 'は　い',
                      cancelText: 'いいえ',
                      onOk: () => {
                        this.delete()
                      }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => this.saveAndUpdateData()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2737006_MiraisSingleDoctorSettingAdd)
