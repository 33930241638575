import React from "react";
import { connect } from "react-redux";

import InspectNameConvertInMaintainTaAction from "redux/CooperationRelated/InspectItemConvertInternal/InspectNameConvertInMaintainTa.actions";
import InspectNameConvertInMaintainTaEdit from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1039002_InspectNameConvertInMaintainTaEdit.jsx";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, Form, Button } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';


class WS1039001_InspectNameConvertInMaintainTa extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '検査名称変換(ＩＮ)保守[ＴＡ]';


    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTable: false,
      dataSource: [],

      selectedRowKeys: [],
      selectedRowIndex: 0,

      isEdit: false,
    };
  }

  componentDidMount() {
    this.index();
  }

  index() {
    this.setState({ isLoadingTable: true })
    InspectNameConvertInMaintainTaAction.index()
      .then((res) => {
        this.setState({
          dataSource: res ? res : []
        });
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  // 編集画面
  InspectNameConvertInMaintainTaEdit(record) {
    let flag = true;
    if (record) {
      flag = false;
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 450,
        component: (
          <InspectNameConvertInMaintainTaEdit
            record={record}
            newFlag={flag}
            onFinishScreen={() => {
              this.closeModal()
              this.index()
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  render() {
    return (
      <div className="inspect-name-convert-in-maintain-ta" >
        <Card title="検査名称変換(ＩＮ)保守[ＴＡ]">
          <Form ref={this.formRef}>
            <Table
              size="small"
              dataSource={this.state.dataSource}
              loading={this.state.isLoadingTable}
              pagination={false}
              bordered={true}
              scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
              rowKey={(record) => record.id}
              onRow={(record) => ({
                onDoubleClick: () =>
                  this.InspectNameConvertInMaintainTaEdit(record)
              })}

            >
              <Table.Column
                title='検査名称(略称)'
                dataIndex='exam_name_short_name'
                className='column-size-20'
              />

              <Table.Column
                title='検査コード'
                dataIndex='exam_code'
                className='column-size-20'
              />

              <Table.Column
                title="外部検査名称"
                dataIndex="exam_name"
              />

              <Table.Column
                width={40}
                align="center"
                title={
                  <div style={{ textAlign: "center" }}>
                    <Button
                      size='small'
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => { this.InspectNameConvertInMaintainTaEdit() }}
                    >
                    </Button>
                  </div>
                }

                render={(record) => (
                  <Button
                    size="small"
                    icon={<MoreOutlined />}
                    onClick={() => { this.InspectNameConvertInMaintainTaEdit(record) }}
                  />
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1039001_InspectNameConvertInMaintainTa);
