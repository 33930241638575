import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import InspectInfoCorrectAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/InspectInfoCorrect.actions";
import { Card, Form, Input, Row, Col } from "antd";

const styleMarginRight30 = {
  marginRight: "30px"
}
const styleContainer = {
  width: "100%",
  border: '1px solid #eee',
  paddingBottom: "30px",
  marginTop: '30px'
}

const styleBlueTitle = {
  backgroundColor: "#1890ff",
  textAlign: 'center',
  padding: "5px 0",
  color: "#fff"
}

const styleRow = {
  marginTop: '10px',
  marginBottom: '10px',
  justifyContent: 'space-around'
}

const styleCol = {
  marginLeft: '5px', marginRight: '5px'
}

class WS1170001_InspectInfoCorrect extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_Format: PropTypes.any,
    Li_DateF: PropTypes.any,
    Li_DateT: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06920:   検査情報(修正)';

    this.state = {
      tableData: [],
      Li_format: "",
      isLoading: true,
      isLoadingTable: true,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.Li_Format !== prevProps.Li_Format) {
      this.formRef.current.setFieldsValue({ Li_Format: this.props.Li_Format });
      this.setState({ isLoading: true, Li_format: this.props.Li_Format })
      this.getScreenData(this.props.Li_Format)
    }
  }

  componentDidMount() {
    this.getInit();
  }
  getInit() {
    let params = {
      Li_Format: this.props.Li_Format,
      Li_DateF: this.props.Li_DateF,
      Li_DateT: this.props.Li_DateT,
    }
    this.setState({ isLoading: true, Li_format: params.Li_Format })
    this.getScreenData(params.Li_Format)
  }

  getScreenData = (value) => {
    this.setState({ isLoadingTable: true })
    this.setState({
      isLoadingForm: true,
    })
    InspectInfoCorrectAction.getInit({ Format: value })
      .then(res => {
        this.setState({
          tableData: res ? res : []
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }


  onFinish(values) {

  }

  render() {
    return (
      <div className="inspect-info-correct">
        <Card title="V4-VNS06920:   検査情報(修正)">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row>
              <Col span={2} >
                <Form.Item
                  name="format"
                  label="FORMAT"
                >
                </Form.Item>
              </Col>
              <Col span={2} style={styleMarginRight30}>

                <Input readOnly type="text" value={this.state.tableData.format} />
              </Col>
              <Col span={2}>
                <Form.Item
                  name="government_billing_date_from_on"
                  label="政管請求日[FROM]"
                >
                </Form.Item>
              </Col>
              <Col span={2} style={styleMarginRight30}>
                <Input readOnly type="text" value={this.state.tableData.government_billing_date_from_on} />
              </Col>
              <Col span={2}>
                <Form.Item
                  name="visit_date_on"
                  label="受診日"
                >
                </Form.Item>
              </Col>
              <Col span={2} style={styleMarginRight30}>
                <Input readOnly type="text" value={this.state.tableData.visit_date_on} />
              </Col>
              <Col span={2}>
                <Form.Item
                  name="accepted_no"
                  label="受付NO"
                >
                </Form.Item>
              </Col>
              <Col span={2} style={styleMarginRight30}>
                <Input readOnly type="text" value={this.state.tableData.accepted_no} />
              </Col>
              <Col span={2}>
                <Form.Item
                  name="visit_course"
                  label="受診コース"
                >
                </Form.Item>
              </Col>
              <Col span={2}>
                <Input readOnly type="text" value={this.state.tableData.visit_course} />
              </Col>

            </Row>
            <div style={styleContainer} >
              <div style={styleBlueTitle}>検 査 情 報</div>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_7} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_8} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_9} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_10} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_11} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_13} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_13} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_14} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_15} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_16} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_17} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_18} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_19} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_20} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_21} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_22} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_23} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_24} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_25} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_26} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_27} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_28} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_29} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_30} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_31} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_32} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_33} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_34} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_35} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_36} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_37} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_38} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_39} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_40} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_41} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_42} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_43} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_44} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_45} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_46} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_47} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_48} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_49} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_50} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_51} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_52} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_53} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_54} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_55} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_56} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_57} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_58} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_59} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_60} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_61} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_62} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_63} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_64} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_65} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_66} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_67} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_68} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_69} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_70} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_71} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_72} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_73} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_74} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_75} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_76} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_77} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_78} />
                </Col>
              </Row>
              <Row style={styleRow}>
                <Col span={2} style={styleCol}>
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_79} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_80} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_81} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_82} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_83} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_84} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_85} />
                </Col>
                <Col span={2} style={styleCol} >
                  <Input readOnly type="text" value={this.state.tableData.Expresstion_86} />
                </Col>
              </Row>

            </div>
            <Row>
              <Input.TextArea rows={15} value={this.state.tableData.exam_info_10_400} />
            </Row>

          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1170001_InspectInfoCorrect);
