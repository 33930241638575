import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, Form, Input, Table, } from "antd";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS0410001_MasterListOutputSiteClassify extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    onFinishScreen: PropTypes.func,
  };
  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力[部位分類]';

    this.state = {
      siteClassifyCodeF: 0,
      siteClassifyCodeT: 0,
      dataSource: [],
      indexTable: 0
    };
  }

  componentDidMount() {
    this.setState({ isLoadingTable: true })

    VenusApiRoutesV2.callApi("API000410001001", {})
      .then((res) => {
        this.setState({
          siteClassifyCodeF: res.SiteClassifyCodeF,
          siteClassifyCodeT: res.SiteClassifyCodeT,
          isLoadingTable: false,
        });

        this.formRef.current.setFieldsValue({
          siteClassifyCodeF: res.SiteClassifyCodeF,
          siteClassifyCodeT: res.SiteClassifyCodeT,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });

    VenusApiRoutesV2.callApi("API000410001002", {})
      .then((res) => {
        this.setState({
          dataSource: res? res : [],
          isLoadingTable: false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) { }
  }

  onFinish(values) {

  }

  render() {
    return (
      <div className="master-list-output-site-classify">
        <Card className="mb-2" title="マスタ一覧出力[部位分類]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="siteClassifyCodeF"
              label="部位分類コード[F]"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="siteClassifyCodeT"
              label="部位分類コード[T]"
            >
              <Input type="text" />
            </Form.Item>
          </Form>

          <Table bordered 
            size="small"
            dataSource={this.state.dataSource} 
            loading={this.state.isLoadingTable}
            pagination={true}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="部位分類コード" dataIndex="site_classification_code"  key="1"
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification_code - b.site_classification_code}
              />
            <Table.Column title="部位分類名称" dataIndex="site_classification_name" key="2"
              showSorterTooltip={false}
              sorter={(a, b) => a.site_classification_name.localeCompare(b.site_classification_name, 'ja')}
            />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0410001_MasterListOutputSiteClassify);
