import MiraisElectronicMedicalRecordsSentService from 'services/CooperationRelated/MiraisElectronicMedicalRecordsSent/MiraisElectronicMedicalRecordsSentService'
import { message } from 'antd'

const MiraisElectronicMedicalRecordsSentAction = {
  index(data) {
    return MiraisElectronicMedicalRecordsSentService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  sendMirais(data) {
    return MiraisElectronicMedicalRecordsSentService.sendMirais(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  checkMiraisStartup() {
    return MiraisElectronicMedicalRecordsSentService.checkMiraisStartup()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  // SubmitBtnBefore(data) {
  //   return MiraisElectronicMedicalRecordsSentService.SubmitBtnBefore(data)
  // },

  getOracleTest() {
    return MiraisElectronicMedicalRecordsSentService.getOracleTest()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  mountTest() {
    return MiraisElectronicMedicalRecordsSentService.mountTest()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  }
}
export default MiraisElectronicMedicalRecordsSentAction
