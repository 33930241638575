import axios from 'configs/axios'

const API_LIST = {
  index: '/api/follow-up-letter/follow-up-letter-subject-cmt-inquiry',
}

const FollowUpLetterSubjectCmtInquiryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default FollowUpLetterSubjectCmtInquiryService
