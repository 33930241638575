import axios from "configs/axios";

const API_LIST = {
    getInit: "/api/associate-insure-param-maintain/use-inspect-check/useinspectchecksub",
    getScreenData:"/api/associate-insure-param-maintain/use-inspect-check",
    getNameCategoryCode: "/api/associate-insure-param-maintain/use-inspect-check/getname_categorycode",
    getNameInspectCode: "/api/associate-insure-param-maintain/use-inspect-check/getname_inspectcode",
}


const UseInspectCheck = {
    async getInit(params){
        console.log('services_params->', params)
        return axios.get(API_LIST.getInit, {params});
    },
    async getScreenData(params){
        console.log('screendata_params->', params)
        return axios.get(API_LIST.getScreenData, {params});
    },
    async getNameCategoryCode(params){
        console.log('getnamecategory->', params)
        return axios.get(API_LIST.getNameCategoryCode, {params});
    },
    async getNameInspectCode(params){
        console.log('getnameinspect->', params)
        return axios.get(API_LIST.getNameInspectCode, {params});
    }
    
};
export default UseInspectCheck;