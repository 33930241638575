import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Input, Button, Modal } from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalDraggable from 'components/Commons/ModalDraggable';
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";

const labelCol = {
  labelCol: { style: { width: '80px' } },
}

const categoryLabelCol = {
  labelCol: { style: { width: '100px' } },
  style: { marginBottom: 0 }
}

class WS0745004_NotInputCheckMaintainDetailChange extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '注意事項入力 編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isSave: false,
      count: 0,
      newFlag: false,
      record: 0,
      ms1100category_code: '',
      ms1100category_name: '',
      new_Exam_name: '',
      change_Exam_name: '',
      change_Inspect_code: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 親画面のrecordをコピー
    const data = { ...this.props.record }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
    this.setState({
      ms1100category_code: data.ms1100category_code ?? '',
      ms1100category_name: data.ms1100category_name ?? '',
      change_Exam_name: data.exam_name ?? '',
      change_Inspect_code: data.change_Inspect_code ?? ''
    })
  }

  /**
   * データ保存
   */
  save = () => {
    this.props.onUpdate(this.formRef.current?.getFieldsValue())
  }

  /**
   * データ削除
   * @param {*} id
   */
  delete = (values) => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='notes-input'>
        <Card title={(this.props.type === 1 ? '表示カテゴリ' : '不要検査') +
          '[' + (this.props.newFlag ? '新規' : '変更') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            {this.props.newFlag ?
              //flagでtruthなら「新規」
              <div className='box_container'>
                <Form.Item
                  label='検査コード'
                  name='inspect_code'
                  {...labelCol}
                >
                  <Input.Search
                    className='input-search-size-number-8'
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1000,
                          component:
                            <WS0271001_InspectItemSearchQuerySingle
                              InspectCode={this.formRef.current?.getFieldValue('inspect_code')}
                              onFinishScreen={(output) => {
                                this.formRef.current.setFieldsValue({
                                  inspect_code: output.recordData.test_item_code,
                                  exam_name: output.recordData.exam_name
                                })
                                this.setState({ new_Exam_name: output.recordData.exam_name })
                                this.closeModal()
                              }}
                            />
                        }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label='名称'
                  name='exam_name'
                  {...labelCol}
                >
                  <div>
                    {this.state.new_Exam_name}
                  </div>
                </Form.Item>

                <Form.Item
                  label='グループ'
                  name='group'
                  {...labelCol}
                >
                  <Input
                  />
                </Form.Item>
              </div>

              :

              //flagでtruthなら「変更」
              (this.props.type === 1) ?
                //type=1なら「カテゴリ」
                <div className='box_container'>
                  <Form.Item
                    label='SEQ'
                    name='display_order'
                    {...categoryLabelCol}
                  >
                    <Input
                      className='input-size-number-4'
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item
                    label='名称'
                    name='category_name'
                    {...categoryLabelCol}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='カテゴリコード'
                    name='ms1100category_code'
                    {...categoryLabelCol}
                  >
                    <div>
                      {this.state.ms1100category_code}
                    </div>
                  </Form.Item>

                  <Form.Item
                    label='カテゴリ名称'
                    name='ms1100category_name'
                    {...categoryLabelCol}
                  >
                    <div>
                      {this.state.ms1100category_name}
                    </div>
                  </Form.Item>
                </div>

                :

                //type=1なら「不要検査」
                <div className='box_container'>
                  <Form.Item
                    label='検査コード'
                    name='inspect_code'
                    {...labelCol}
                  >
                    <div>
                      {this.state.change_Inspect_code}
                    </div>
                  </Form.Item>

                  <Form.Item
                    label='名称'
                    name='exam_name'
                    {...labelCol}
                  >
                    <div>
                      {this.state.change_Exam_name}
                    </div>
                  </Form.Item>

                  <Form.Item
                    label='グループ'
                    name='group'
                    {...labelCol}
                  >
                    <Input />
                  </Form.Item>
                </div>
            }

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.type === 1 || this.props.newFlag) ? "none" : "" }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => { this.save() }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0745004_NotInputCheckMaintainDetailChange)
