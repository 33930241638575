import axios from "configs/axios";
const apiPaths = {
    getMemo: "/api/main/dashboard/getmemo",
    getEmail: "/api/main/dashboard/getemail",
    getTODOList: "/api/main/dashboard/gettodolist",
    getExaminee: "/api/main/dashboard/get-examinee",
    deleteEmail: "/api/main/dashboard/delete-email",
    deleteTodo: "/api/main/dashboard/deletetodo",
    deleteMemo: "/api/main/dashboard/delete-memo",
    saveCompleted: "/api/main/dashboard/savecompleted",
    completed: "/api/main/dashboard/completed",
    getCapacity: "/api/main/dashboard/get-capacity",
};
const DashboardService = {
    async getMemo(params) {
        return axios.get(apiPaths.getMemo, { params });
    },
    async getEmail(params) {
        return axios.get(apiPaths.getEmail, { params });
    },
    async getTODOList(params) {
        return axios.get(apiPaths.getTODOList, { params });
    },
    async getExaminee() {
        return axios.get(apiPaths.getExaminee);
    },
    async deleteEmail(params) {
        return axios.put(apiPaths.deleteEmail, params);
    },
    async deleteMemo(params) {
        return axios.put(apiPaths.deleteMemo, params);
    },
    async deleteTodo(params) {
        return axios.put(apiPaths.deleteTodo, params);
    },
    async saveCompleted(params) {
        return axios.put(apiPaths.saveCompleted, params);
    },
    async completed(params) {
        return axios.get(apiPaths.completed, { params });
    },
    async getCapacity(params) {
        return axios.get(apiPaths.getCapacity, { params });
    },
};

export default DashboardService;
