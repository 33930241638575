import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Button, Modal, Spin } from "antd";

import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';
import WS1464003_ListProcess from "./WS1464003_ListProcess";

const dateFormat = 'YYYY/MM/DD';
class WS1464001_SpecificInsureGuideSettleProcessList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '特定保健指導決済処理[一覧]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      insurer_kanji_name: '',
      isLoadPage: false,
    };
    this.onFinish = this.onFinish.bind(this);
  }

  onFinish() {
    Modal.confirm({
      content: '確認して下さい！',
      onOk: () => {
        this.setState({
          childModal: {
            visible: true,
            width: "80%",
            component: (
              <WS1464003_ListProcess
                DateFScreen={moment(this.formRef.current.getFieldValue('DateFScreen')).format('YYYY/MM/DD')}
                DateTScreen={moment(this.formRef.current.getFieldValue('DateTScreen')).format('YYYY/MM/DD')}
                InsuranceNum={this.formRef.current.getFieldValue('InsuranceNum') ?? ''}
                onFinishScreen={() => {
                  this.closeModal();
                }}
              />
            )
          }
        });
      },
      cancelText: 'いいえ',
      okText: 'は　い',
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <Card
        className="mb-2"
        title="特定保健指導決済処理"
        style={{ width: 500 }}
      >
        <Spin
          tip="処理中です。しばらくお待ちください。"
          spinning={this.state.isLoadPage}
        >

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
            initialValues={{ DateFScreen: moment(), DateTScreen: moment() }}
          >
            <div className="box_inner_horizontal">
              <Form.Item
                name="DateFScreen"
                label="初回予定日"
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                />
              </Form.Item>
              <Form.Item>~</Form.Item>
              <Form.Item
                name="DateTScreen"
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={dateFormat}
                />
              </Form.Item>
            </div>

            <div className="box_inner_horizontal">
              <Form.Item
                className="input-search-size-allow-clear-10"
                name="InsuranceNum"
                label="保険者番号"
              >
                <Input.Search
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 1100,
                        component: (
                          <WS1290001_InsurerNumberInquiry
                            onFinishScreen={({ recordData, Lo_InsurerNum, Lo_InsurerKanjiName }) => {
                              this.formRef.current?.setFieldsValue({
                                InsuranceNum: Lo_InsurerNum
                              })
                              this.closeModal()
                              this.setState({ insurer_kanji_name: Lo_InsurerKanjiName })
                            }}
                          />),
                      },
                    })
                  }} />
              </Form.Item>

              <Form.Item
                style={{ marginTop: '6px', marginLeft: '10px' }}>
                <span>{this.state.insurer_kanji_name}</span>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type="primary"
                htmlType='submit'
              >
                実行
              </Button>
            </div>

          </Form>
        </Spin>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1464001_SpecificInsureGuideSettleProcessList);
