import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import WS2736001_MiraisElectronicMedicalRecordsSent from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2736001_MiraisElectronicMedicalRecordsSent.jsx'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Form, Button, Modal } from 'antd'
import TentativeReserveAction from 'redux/ReservationBusiness/SANAI_TentativeReserve/TentativeReserve.action.js'

class DockTentativeReserve extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '仮予約確定処理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      Expression_36: 163,
      recordData: {},
      disableBranch: true
    }
  }

  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue("ReserveDateFChar")
      ? this.formRef.current
        ?.getFieldValue("ReserveDateFChar")
        .format("YYYY/MM/DD")
      : null;
    let dateEnd = this.formRef.current?.getFieldValue("ReserveDateTChar")
      ? this.formRef.current
        ?.getFieldValue("ReserveDateTChar")
        .format("YYYY/MM/DD")
      : null;
    if (dateEnd && dateStart > dateEnd) {
      this.formRef.current?.setFieldsValue({
        ReserveDateFChar:
          this.formRef.current?.getFieldValue("ReserveDateFChar"),
      });
    }
  }

  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue("ReserveDateFChar")
      ? this.formRef.current
        ?.getFieldValue("ReserveDateFChar")
        .format("YYYY/MM/DD")
      : null;
    let dateEnd = this.formRef.current?.getFieldValue("ReserveDateTChar")
      ? this.formRef.current
        ?.getFieldValue("ReserveDateTChar")
        .format("YYYY/MM/DD")
      : null;
    if ((!dateEnd && dateStart) || (dateStart && dateStart > dateEnd)) {
      this.formRef.current?.setFieldsValue({
        ReserveDateTChar:
          this.formRef.current?.getFieldValue("ReserveDateFChar"),
      });
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  reserve = () => {
    let params = {
      ReserveDateFChar: this.formRef.current?.getFieldValue('ReserveDateFChar'),
      ReserveDateTChar: this.formRef.current?.getFieldValue('ReserveDateTChar')
    }
    TentativeReserveAction.dockTentativeReserve(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  cancel = () => {
    let params = {
      ReserveDateFChar: this.formRef.current?.getFieldValue('ReserveDateFChar'),
      ReserveDateTChar: this.formRef.current?.getFieldValue('ReserveDateTChar')
    }
    TentativeReserveAction.dockTentativeReserveCancel(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  MiraisElectronicMedicalRecordsSent = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2736001_MiraisElectronicMedicalRecordsSent
            DateFChar={this.formRef.current?.getFieldValue('ReserveDateFChar')}
            DateTChar={this.formRef.current?.getFieldValue('ReserveDateTChar')}
            onFinishScreen={() => {
              this.closeModal()
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen()
              }
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='dock-tentative-reserve'>
        <Card
          title='人間ドック仮予約'
          style={{ width: this.props.modalFlg ? '' : 'fit-content' }}
        >
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div className='box_inner_horizontal' >
                <Form.Item
                  label="受診日"
                  name="ReserveDateFChar"
                  style={{ marginBottom: 0 }}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    onBlur={() => {
                      this.checkDateStart();
                    }} />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>~</Form.Item>
                <Form.Item
                  name="ReserveDateTChar"
                  style={{ marginBottom: 0 }}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    onBlur={() => {
                      this.checkDateEnd();
                    }} />
                </Form.Item>
              </div>

              <div className='box_button_bottom_right'>
                <Button
                  type="danger"
                  onClick={() => {
                    Modal.confirm({
                      title: '確認',
                      icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
                      content: '予約を削除しますか？',
                      cancelText: 'いいえ',
                      okText: 'はい',
                      onOk: () => {
                        this.cancel()
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    取消
                  </span>
                </Button>
                <Button
                  type="primary"
                  onClick={() => { this.reserve() }}
                >
                  <span className='btn_label'>
                    確定
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DockTentativeReserve)
