import axios from 'configs/axios'

const API_LIST = {
  index: '/api/passing-control-inspects-display/passing-control-inspects-display',
  getStatusList: '/api/passing-control-inspects-display/passing-control-inspects-display/getstatuslist',
  acceptedState: '/api/passing-control-inspects-display/passing-control-inspects-display/acceptedstate',
  carriedOutState: '/api/passing-control-inspects-display/passing-control-inspects-display/carriedoutstate',
  cancel: '/api/passing-control-inspects-display/passing-control-inspects-display/cancel',
  unmeasurable: '/api/passing-control-inspects-display/passing-control-inspects-display/unmeasurable',
  getUserInfo: '/api/passing-control-inspects-display/passing-control-inspects-display/get-user-info',
  changeStateUpdate: '/api/passing-control-inspects-display/passing-control-inspects-display/change-state-update',
  exclusionSave: '/api/passing-control-inspects-display/passing-control-inspects-display/exclusion-save',
}

const PassingControlInspectsDisplayService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getStatusList(params) {
    return axios.get(API_LIST.getStatusList, { params })
  },
  async acceptedState(params) {
    return axios.get(API_LIST.acceptedState, { params })
  },
  async carriedOutState(params) {
    return axios.get(API_LIST.carriedOutState, { params })
  },
  async cancel(params) {
    return axios.get(API_LIST.cancel, { params })
  },
  async unmeasurable(params) {
    return axios.get(API_LIST.unmeasurable, { params })
  },
  async changeStateUpdate(params) {
    return axios.get(API_LIST.changeStateUpdate, { params })
  },
  async getUserInfo(params) {
    return axios.get(API_LIST.getUserInfo, { params })
  },
  async exclusionSave(params) {
    return axios.post(API_LIST.exclusionSave, params)
  },
}

export default PassingControlInspectsDisplayService

