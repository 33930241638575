import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Checkbox, Button, Modal, message } from "antd";
import { PrinterOutlined } from '@ant-design/icons'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import PaymentListOut from "redux/AccountingBusiness/PaymentListOutput/PaymentListOut.actions"
import moment from 'moment';

const labelCol = {
  labelCol: { style: { width: 50 } }
}

class WS0981001_PaymentListOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '入金一覧出力';

    this.state = {
      PayDateFChar: "",
      PayDateTChar: "",
      Cash: 1,
      Credit: 1,
      Transfer: 0,
      PrinterNo: 1,
      PreviewPresence: true,
      FormatName: "",
      OutputOrder: 1,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.GetScreenData()
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.GetScreenData()
    }
  }

  GetScreenData() {
    this.setState({ isLoading: true })
    PaymentListOut.GetScreenData()
      .then(res => {
        this.setState({
          PayDateFChar: res.PayDateFChar,
          PayDateTChar: res.PayDateTChar,
          Cash: res.Cash,
          Credit: res.Credit,
          Transfer: res.Transfer,
          PrinterNo: res.PrinterNo,
          PreviewPresence: res.PreviewPresence,
          FormatName: res.FormatName,
          OutputOrder: res.OutputOrder,
        });
        this.formRef.current?.setFieldsValue({
          ...res,
          PayDateFChar: moment(res.PayDateFChar),
          PayDateTChar: moment(res.PayDateTChar),
        });
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  Print_F12() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      PayDateFChar: moment(this.formRef.current.getFieldValue('PayDateFChar')).format('YYYY/MM/DD'),
      PayDateTChar: moment(this.formRef.current.getFieldValue('PayDateTChar')).format('YYYY/MM/DD'),
    };

    if (this.formRef.current?.getFieldValue('PreviewPresence')) {
      // プレビュー
      PaymentListOut.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          window.open(fileURL)
        })
        .catch(error => {
          const res = error.response
          if (res) {
            if (res.request.responseType === 'blob') {
              res.data.text()
                .then(str => {
                  let json = JSON.parse(str);
                  message.error(json.message)
                })
            }
            if (error.message.includes('504')) {
              return Modal.error({
                title: 'タイムアウトしました',
                content: '',
              });
            }
          }
        })
    } else {
      // 印刷
      PaymentListOut.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
        }).catch((err) => {
          message.error(err.response.data.message);
        });
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  showPrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={this.formRef.current?.getFieldValue("PreviewPresence")}
            Li_PrinterNoSpecifiedValue={this.formRef.current?.getFieldValue("PrinterNum")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                PreviewPresence: output.Lo_Preview ? 1 : 0,
                PrinterNum: output.Lo_PrinterNo,
                StsOutput: output.Lo_StsOutput ? 1 : 0
              })
              if (output.Lo_StsOutput) {
                this.Print_F12()
              }
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  render() {
    var today = new Date().getFullYear() + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + ("0" + new Date().getDate()).slice(-2)
    return (
      <div className="payment-list-output">
        <Card
          className="mb-2"
          title="入金一覧出力"
          style={{ width: 450 }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ PayDateFChar: moment(today), PayDateTChar: moment(today) }}
          >
            <div className="box_inner_horizontal">
              <Form.Item
                name="PayDateFChar"
                label="入金日"
                {...labelCol}
              >
                <VenusDatePickerCustom
                  width={160}
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>

              <Form.Item
                label='~'
                name="PayDateTChar"
              >
                <VenusDatePickerCustom
                  width={160}
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>
            </div>

            <div className="box_inner_horizontal">
              <Form.Item
                label="対象"
                {...labelCol}
              >
                <div className="box_inner_horizontal">
                  <Form.Item name="Cash" valuePropName="checked" >
                    <Checkbox>現金</Checkbox>
                  </Form.Item>
                  <Form.Item name="Credit" valuePropName="checked" >
                    <Checkbox>クレジット</Checkbox>
                  </Form.Item>
                  <Form.Item name="Transfer" valuePropName="checked" >
                    <Checkbox>振込</Checkbox>
                  </Form.Item>
                </div>
              </Form.Item>
            </div>

            <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
              <Button
                icon={<PrinterOutlined />}
                type="primary"
                onClick={() => {
                  this.showPrinterConfirm()
                }}
              >
                印刷
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0981001_PaymentListOutput);
