import React from 'react'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Checkbox, Select, Table, Dropdown, Menu, Tooltip, message } from 'antd'
import { MoreOutlined, DoubleRightOutlined, DoubleLeftOutlined, SearchOutlined } from '@ant-design/icons'
import WS0333001_SetIncludesQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQuery.jsx'
import WS0311005_MoneyAmountInputSub from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0311005_MoneyAmountInputSub.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay'
import PersonalReserveProcessAction from 'redux/ReservationBusiness/PersonalReserveProcess/PersonalReserveProcess.action'
import { VisitsInspectConsultInquirySubAction } from 'redux/InputBusiness/ProgressSetting/ConsultInquirySub.actions'
import AnotherInspectItemSettingCategory from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309017_ContractItemSub/AnotherInspectItemSettingCategory'
import GetImage from 'constants/Images'
const { Option } = Select

class WS2537500_PersonalReserveOption extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_PersonalNum: PropTypes.any,
    Li_Date: PropTypes.any,
    Li_Getctxname: PropTypes.any,
    Li_ProcessDivision: PropTypes.any,
    Li_Option: PropTypes.any,
    Li_Child: PropTypes.any, // required - value: true

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)
    // document.title = '個人予約処理'
    this.state = {
      valuePersonalNumber: '',
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSourceSelect: [],
      selectedRowSelect: [],
      selectedRowKeySelect: [],
      dataSourceInspectChange: [],
      selectedRowInspectChange: [],
      selectedRowKeyInspectChange: [],
      FacilityNumHospitaList: [],

      // Row 1: ReserveNu
      ReserveNum: null,
      Expression_99: '新規',
      Expression_100: 211,
      Continuous: false,
      Li_ProcessDivision: 0,
      StateFlag: 0,
      Age: '',

      // Row Personal
      sex: null,
      importance_Personal: null,
      importance_Office: null,
      PersonalNumId: '',
      OfficeCode: '',
      ConsultCourse: null,
      FacilityNumHospital_Out: 0,
      Expression_132: '登録',

      // Table
      Expression_140: 10,
      AddedOrUnnecessary: 0,
      ChangeClassify: false,
      dataScreen: {},
      dataOffice: [],
      dataTax: [],
      columnList: [],
      dataList: [],
      dataOfficeByPersonal: [],
      Lo_Update: false,
      isChangeData: false,
      isChangeForm: false,
      changeConsultCourse: false,
      isUnValidConsultCourse: true,
      isUnValidOffice: false,
      nameScreen: '',
      dataPersonal_2543: {},
      function_F12: '',
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      Date: '',
      Gender: '',
      DateBirth: '',
      Relationship: '',
      HospitalOut: '',
      TimeZone: '',
      NClassify: '',
      reservation_number: '',
      addSet: '',
      deleteSet: ''
    }
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      AddedOrUnnecessary: this.props.Li_AddedOrUnnecessary
    })

    this.setState({
      dataScreen: this.props.Li_dataScreen,
      AddedOrUnnecessary: this.props.Li_AddedOrUnnecessary,
      Expression_140: this.props.Li_Expression_140,
      dataSourceSelect: this.props.Li_dataSourceSelect,
      dataSourceInspectChange: this.props.Li_dataSourceInspectChange,
      columnList: this.props.columnList,
      dataList: this.props.dataList,
      Date: this.props.Li_Date,
      Gender: this.props.Li_Gender,
      DateBirth: this.props.Li_DateBirth,
      Relationship: this.props.Li_Relationship,
      HospitalOut: this.props.Li_FacilityNumHospital_Out,
      TimeZone: this.props.TimeZone,
      NClassify: this.props.Li_NClassify,
      reservation_number: this.props.Li_ReserveNum
    })
    this.getDataTableLeft()
  }

  /**
   * データ検索
   */
  getDataTableLeft(value) {
    if (this.formRef.current?.getFieldValue('ChangeClassify')) {
      //単品にチェックが入っている場合
      this.getDataTableInspectSelect(value)
    } else {
      if (this.formRef.current?.getFieldValue('AddedOrUnnecessary') === 2) {
        //オプションの場合
        this.getDataTableSelectOptions(value)
      } else {
        //追加指定「0」・不要指定「1」の場合
        this.getDataTableSetSelect(value)
      }
    }
  }

  /**
   * 単品にチェックが入っている場合
   */
  getDataTableInspectSelect(value) {
    let params = {
      Li_Pattern: this.props.Li_PatternCode,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      Li_AddedOrDeleted: this.formRef.current?.getFieldValue('AddedOrUnnecessary') === 2 ? 0 : this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      //PHPでは、false/trueが受け取れない
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify') ? 1 : 0,
      date: this.formRef.current?.getFieldValue('DateChar'),
      contractType: this.state.dataScreen.ContractType,
      contractOrgCode: this.state.dataScreen.ContractClassifyCode,
      contractStartDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      ConsultCourse: this.state.dataScreen.ConsultCourse,
      dataSourceInspectChange: this.state.dataSourceInspectChange,
      personalNumberId: this.props.Li_PersonalNum
    }

    //オプション変更で表示するデータを取得するＡＰＩ
    PersonalReserveProcessAction.getDataInspectSelect(params)
      .then(async (res) => {
        let resData = [...res.data ?? []]
        let SearchChar = this.formRef.current?.getFieldValue('SearchChar')
        let index = res?.ComboBox_AddedOrUnnecessary.findIndex(x => x.LinkedField === this.state.AddedOrUnnecessary)

        let Expression_140 = index === -1 ? (res?.ComboBox_AddedOrUnnecessary[0].LinkedField === 2 ? 40
          : res?.ComboBox_AddedOrUnnecessary[0].LinkedField === 0 ? 20
            : res?.ComboBox_AddedOrUnnecessary[0].LinkedField === 1 ? 30
              : null) : this.state.Expression_140

        let AddedOrUnnecessary = index === -1 ? res?.ComboBox_AddedOrUnnecessary[0].LinkedField : this.state.AddedOrUnnecessary
        let data = {
          ...this.state.dataScreen,
          ComboBox_AddedOrUnnecessary: res?.ComboBox_AddedOrUnnecessary,
        }

        // onPressEnterが押下された時に、実行
        // 検索フォームに入力したコードと完全一致したコードは、自動でオプションに追加する
        if (value) {
          for (let i = 0; i < resData.length; i++) {
            let setCode = resData[i].set_code
            if (Number(SearchChar) == Number(setCode)) {
              this.setOption(resData[i], 3)
            }
          }
        }

        //既にセットされているデータの場合、半透明化
        let setrectlist = res ? res.data : []
        let setData = [...this.state.dataSourceInspectChange]

        setrectlist.forEach((data) => {
          setData.forEach((item) => {
            if (item?.set_code === data?.set_code && item?.changeType === data?.addedOrDeleted) {
              data.textColor = '#b9b9b9'
            }
          })
        })

        await this.setState({
          dataScreen: data,
          Expression_140: Expression_140,
          AddedOrUnnecessary: AddedOrUnnecessary,

          dataSourceSelect: setrectlist,
          selectedRowSelect: res && res.data.length > 0 ? [res.data[0]] : [],
          selectedRowKeySelect: res && res.data.length > 0 ? [res.data[0].id] : [],
          isloadForm: false,
          addSet: res.addSet,
          deleteSet: res.deleteSet
        })

        this.formRef.current?.setFieldsValue({
          Expression_140: Expression_140,
          AddedOrUnnecessary: AddedOrUnnecessary
        })
      })
      .finally(() => this.setState({
        isloadForm: false,
      }))
  }

  /**
   * オプションの場合
   */
  getDataTableSelectOptions(value) {
    let params = {
      Li_ContractType: this.props.Li_dataScreen.ContractType,
      Li_ContractOrgs: this.props.Li_dataScreen.ContractClassifyCode,
      Li_ContractStartDate: this.props.Li_dataScreen.ContractDate,
      Li_ContractNum: this.props.Li_dataScreen.ContractNum,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      Li_AddedOrDeleted: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      Li_Date: this.props.Li_Date,
      Li_Gender: this.props.Li_Gender,
      Li_DateBirth: this.props.Li_DateBirth,
      Li_Relationship: this.props.Li_Relationship,
      Li_HospitalOut: this.props.Li_FacilityNumHospital_Out,
      TimeZone: this.props.TimeZone,
      Li_NClassify: this.props.Li_NClassify,
      PersonalNumId: this.props.Li_PersonalNum,
      reservation_number: this.props.Li_ReserveNum
    }

    PersonalReserveProcessAction.getDataSetSelect(params)
      .then((res) => {
        let SearchChar = this.formRef.current?.getFieldValue('SearchChar')
        let resData = [...res.result ?? []]

        // onPressEnterが押下された時に、実行
        // 検索フォームに入力したコードと完全一致したコードは、自動でオプションに追加する
        if (value) {
          for (let i = 0; i < resData.length; i++) {
            if (SearchChar == resData[i].set_code) {
              this.setOption(resData[i], 2)
            }
          }
        }

        //既にセットされているデータの場合、半透明化
        let setrectlist = res.result ? res.result : []
        let setData = [...this.state.dataSourceInspectChange]

        setrectlist.forEach((data) => {
          setData.forEach((item) => {
            if (item?.set_code === data?.set_code && item?.changeType === data?.data_division) {
              data.textColor = '#b9b9b9'
            }
          })
        })

        this.setState({
          dataSourceSelect: setrectlist,
          selectedRowSelect: res.result && res.result.length > 0 ? [res.result[0]] : [],
          selectedRowKeySelect: res.result && res.result.length > 0 ? [res.result[0].id] : [],
          addSet: res.addSet,
          deleteSet: res.deleteSet
        })
      })
  }

  /**
   * 追加指定「0」・不要指定「1」の場合
   */
  getDataTableSetSelect(value) {
    let params = {
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      Li_AddedOrDeleted: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      Li_ContractType: this.props.Li_dataScreen.ContractType,
      Li_ContractOrgs: this.props.Li_dataScreen.ContractClassifyCode,
      Li_ContractStartDate: this.props.Li_dataScreen.ContractDate,
      Li_ContractNum: this.props.Li_dataScreen.ContractNum,
      Li_Date: this.props.Li_Date,
      Li_Gender: this.props.Li_Gender,
      Li_DateBirth: this.props.Li_DateBirth,
      Li_Relationship: this.props.Li_Relationship,
      Li_HospitalOut: this.props.Li_FacilityNumHospital_Out,
      TimeZone: this.props.TimeZone,
      Li_NClassify: this.props.Li_NClassify,
      reservation_number: this.props.Li_ReserveNum
    }

    PersonalReserveProcessAction.getDataSetSelect(params)
      .then((res) => {
        let SearchChar = this.formRef.current?.getFieldValue('SearchChar')
        let copyRes = [...res.result ?? []]

        // oPressEnter押された時に、実行
        // 検索フォームに入力したコードと完全一致したコードは、自動でオプションに追加する
        if (value) {
          for (let i = 0; i < copyRes.length; i++) {
            if (SearchChar == copyRes[i].set_code) {
              this.setOption(copyRes[i], 1)
            }
          }
        }

        //既にセットされているデータの場合、半透明化
        let setrectlist = res.result ? res.result : []
        let setData = [...this.state.dataSourceInspectChange]
        setrectlist.forEach((data) => {
          setData.forEach((item) => {
            if (item?.set_code === data?.set_code && item?.changeType === data?.addedOrDeleted) {
              data.textColor = '#b9b9b9'
            }
          })
        })

        this.setState({
          dataSourceSelect: copyRes ? copyRes : [],
          selectedRowSelect: res.result && res.result.length > 0 ? [res.result[0]] : [],
          selectedRowKeySelect: res.result && res.result.length > 0 ? [res.result[0].id] : [],
          addSet: res.addSet,
          deleteSet: res.deleteSet
        })
      })
  }

  getDataTableInspectChange() {
    let params = {
      Li_ContractType: this.state.dataScreen.ContractType,
      Li_ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      Li_ContractDate: this.state.dataScreen.ContractDate,
      Li_ContractNum: this.state.dataScreen.ContractNum,
    }

    PersonalReserveProcessAction.getDataInspectChange(params)
      .then((res) => {
        const data = [...res.data]

        this.setState({
          dataSourceInspectChange: data ? data : [],
          selectedRowInspectChange: data && data.length > 0 ? [data[0]] : [],
          selectedRowKeyInspectChange: data && data.length > 0 ? [data[0].id] : [],
        })
        this.getDataTableLeft()
      })
  }

  /**
   * 選択状態
   * @param {*} record
   * @param {*} pattern
   */
  setOption = (record, pattern) => {

    if (record.unChangeable && (pattern == 1 || pattern == 2)) {
      //オプション・追加・不要指定の場合、unChangeableがtrueなら追加不可
      return
    }

    let addData = [...this.state.dataSourceInspectChange]
    let copyData = [...this.state.dataSourceSelect]
    let addObject = {}

    switch (pattern) {
      case 1:
        //追加・不要
        addObject = {
          id: record.id,
          changeType: record.addedOrDeleted,
          set_code: record.set_code,
          Expression_17: record.set_short_name,

          W2_reserve_num: 0,
          W2_manage_sect: 0,
          W2_change_type: record.addedOrDeleted,
          W2_set_inspect_cd: record.set_code,
          W2InsurerTotal: 0,
          W2OfficeTotal: 0,
          W2OtherGroupTotal: 0,
          W2Personal1Total: record.UnitPrice, // UnitPriceは[個人１]に設定
          W2Personal2Total: 0,
          W2Personal3Total: 0,

          personalTotalPrice: record.UnitPrice,
          totalPrice: record.UnitPrice,
        }
        break

      case 2:
        //オプション
        addObject = {
          id: record.id,
          changeType: record.Expression_22,
          set_code: record.set_code,
          Expression_17: record.set_short_name,
          W2_reserve_num: 0,
          W2_manage_sect: 0,
          W2_change_type: record.addedOrDeleted,
          W2_set_inspect_cd: record.set_code,
          W2InsurerTotal: record.insurer_total_price,
          W2OfficeTotal: record.office_total_price,
          W2OtherGroupTotal: record.organization_total_price,
          W2Personal1Total: record.personal_1_total_price,
          W2Personal2Total: record.personal_2_total_price,
          W2Personal3Total: record.personal_3_total_price,

          personalTotalPrice: record.personal_1_total_price + record.personal_2_total_price + record.personal_3_total_price,
          totalPrice: record.totalPrice,
        }
        break

      case 3:
        //単品の追加・不要
        addObject = {
          id: record.id,
          changeType: record.addedOrDeleted,
          set_code: record.set_code,
          Expression_17: record.exam_name,
          W2_reserve_num: 0,
          W2_manage_sect: 0,
          W2_change_type: record.addedOrDeleted,
          W2_set_inspect_cd: record.set_code,
          // 単品はいらないかな？？
          W2InsurerTotal: 0,
          W2OfficeTotal: 0,
          W2OtherGroupTotal: 0,
          W2Personal1Total: record.UnitPrice, // UnitPriceは[個人１]に設定
          W2Personal2Total: 0,
          W2Personal3Total: 0,

          personalTotalPrice: record.UnitPrice,
          totalPrice: record.UnitPrice,
        }
        break
    }

    let addSet = this.state.addSet
    let deleteSet = this.state.deleteSet
    let addSetFlag = false;
    let Itemnumber = 0;
    let settingDelete

    // 設定した追加セットと一致するか検索
    for (let I = 0; I < addSet.length; I++) {
      if (addSet[I].set_code == record.set_code) {
        Itemnumber = I
        addSetFlag = true
      }
      if (addSetFlag) break
    }

    // 設定した追加セットと一致する場合
    if (addSetFlag && record.data_division !== 60) {
      settingDelete = {
        id: deleteSet[Itemnumber].id,
        changeType: deleteSet[Itemnumber].addedOrDeleted,
        set_code: deleteSet[Itemnumber].set_code,
        Expression_17: deleteSet[Itemnumber].set_short_name,

        W2_reserve_num: 0,
        W2_manage_sect: 0,
        W2_change_type: deleteSet[Itemnumber].addedOrDeleted,
        W2_set_inspect_cd: deleteSet[Itemnumber].set_code,
        W2InsurerTotal: 0,
        W2OfficeTotal: 0,
        W2OtherGroupTotal: 0,
        W2Personal1Total: deleteSet[Itemnumber].UnitPrice, // UnitPriceは[個人１]に設定
        W2Personal2Total: 0,
        W2Personal3Total: 0,

        personalTotalPrice: deleteSet[Itemnumber].UnitPrice,
        totalPrice: deleteSet[Itemnumber].UnitPrice,
      }

      addData.push(settingDelete)
    }

    addData.push(addObject)
    let addDataDuplicationCheck = addData.filter((e, index, self) => {
      return self.findIndex((data) => data.changeType === e.changeType && data?.set_code === e?.set_code) === index
    })

    // 項目の灰色化
    copyData.forEach((item) => {
      if (item?.set_code === record?.set_code) {
        item.textColor = '#b9b9b9'
      }
    })

    this.setState({
      dataSourceInspectChange: addDataDuplicationCheck,
      dataSourceSelect: copyData
    })
  }

  contractRedisplay() {
    let params = {
      ...this.state.dataScreen,
      Li_PersonalNum: this.props.Li_PersonalNum,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_ConsultCourse: this.props.Li_ConsultCourse,
      Li_Date: this.props.Li_Date,
      Li_TimeZone: this.props.Li_TimeZone,
      Li_OfficeCode: this.props.Li_OfficeCode,
      Li_BranchStoreCode: this.props.Li_BranchStoreCode,
      Li_FacilityNumHospital_Out: this.props.Li_FacilityNumHospital_Out,
      Li_NClassify: this.props.Li_NClassify,
      Li_StsOption: this.props.Li_StsOption,
      Li_ChangeClassify: this.props.Li_ChangeClassify,
      ...this.formRef.current?.getFieldValue(),
      TimeZone: this.props.TimeZone,
    }

    PersonalReserveProcessAction.contractRedisplay(params)
      .then(async (res) => {
        let selectJudge = this.formRef.current?.getFieldValue('AddedOrUnnecessary')

        let Expression_140 = selectJudge === 2 ?
          40 : selectJudge === 0 ?
            20 : selectJudge === 1 ?
              30 : null

        let data = {
          ...this.state.dataScreen,
          StsReserveChange: res?.data?.StsReserveChange,
          StsCourseWarning: res?.data?.StsCourseWarning,
          ContractInfo: res?.data?.ContractInfo,
          ComboBox_AddedOrUnnecessary: res?.data?.ComboBox_AddedOrUnnecessary,
          OneOClockTotalAmount: res?.data?.OneOClockTotalAmount,
        }

        await this.setState({
          dataScreen: data,
          Expression_140: Expression_140,
          AddedOrUnnecessary: selectJudge,
          isChangeForm: true,
        })

        this.formRef.current?.setFieldsValue({
          Expression_118: res?.data?.Expression_118,
          Expression_140: Expression_140,
          AddedOrUnnecessary: selectJudge
        })

        this.getDataTableLeft()
      })
      .catch((err) => {
      })
  }

  optionConfirm = () => {
    this.props.onFinishScreen({
      dataSourceSelect: this.state.dataSourceSelect,
      dataSourceInspectChange: this.state.dataSourceInspectChange,
      selectedRowInspectChange: this.state.selectedRowInspectChange,
      selectedRowKeyInspectChange: this.state.selectedRowKeyInspectChange,
    })
  }

  /**
   * 選択状態を解除
   * @param {*} record
   */
  deleteOption = (record) => {
    if (record.unChangeable) {
      //unChangeableがtrueのとき削除不可
      return
    }

    let newDataSourceInspectChange = [...this.state.dataSourceInspectChange]
    let newDataSourceSelect = [...this.state.dataSourceSelect]

    let addSet = this.state.addSet
    let deleteSet = this.state.deleteSet
    let addSetFlag = false;
    let Itemnumber = 0;

    // 設定した追加セットと一致するか検索
    for (let I = 0; I < addSet.length; I++) {
      if (addSet[I].set_code == record.set_code) {
        Itemnumber = I
        addSetFlag = true
      }
      if (addSetFlag) break
    }

    if (addSetFlag && record.W2_change_type !== 60) {
      // 設定した追加セットと一致する場合
      let dataDelete = []
      for (let k = 0; k < newDataSourceInspectChange.length; k++) {
        if (newDataSourceInspectChange[k] === record || newDataSourceInspectChange[k].set_code == deleteSet[Itemnumber].set_code) {
          dataDelete.push(newDataSourceInspectChange[k])
        }
      }
      newDataSourceInspectChange = newDataSourceInspectChange.filter(item => dataDelete.indexOf(item) === -1);
    } else {
      // 設定した追加セットと一致しない場合
      for (let k = 0; k < newDataSourceInspectChange.length; k++) {
        let dataDelete = k
        if (newDataSourceInspectChange[k] === record) {
          newDataSourceInspectChange.splice(dataDelete, 1)
        }
      }
    }

    // 項目の灰色化（左の表）
    newDataSourceSelect.forEach((item) => {
      if (
        (item.Expression_22 === record.Expression_22 && item?.id === record?.id)
        || (item.addedOrDeleted === record.addedOrDeleted && item?.id === record?.id)
        || (item.set_code === record.set_code && item?.id === record?.id)
      ) {
        item.textColor = ''
      }
    })

    this.setState({
      dataSourceInspectChange: newDataSourceInspectChange,
      dataSourceSelect: newDataSourceSelect
    })
  }

  /**
   * 変更 - 金額入力 ※一時的に使用する
   * @param {} record
   */
  changePrice = (record) => {
    const params = {
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      ...record,
      ...this.formRef.current.getFieldsValue(),
    }
    PersonalReserveProcessAction.getOtionUnitTaxPrice(params) //合計値から単価と税金を計算するAPIを作成（一時的な使用）
      .then(res => {
        if (res.data) {
          const data = res.data
          // 金額入力のモーダルを表示する
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 1000,
              component: (
                <WS0311005_MoneyAmountInputSub
                  Li_TaxClassify={data?.TaxClassify}
                  Li_Rounding={data?.Rounding}
                  Li_TaxRate={data?.TaxRate}
                  Li_OtherGroupDivision={data?.OtherGroupDivision}
                  Li_Title={data?.Title}
                  Lio_InsurerUnitPriceAmount={data.Insurer_UnitPrice}
                  Lio_InsurerTax={data.Insurer_Tax}
                  Lio_InsurerTotal={data.Insurer_Total}
                  Lio_OfficeUnitPriceAmount={data.Office_UnitPrice}
                  Lio_OfficeTax={data.Office_Tax}
                  Lio_OfficeTotal={data.Office_Total}
                  Lio_OtherGroupUnitPriceAmount={data.OtherGroup_UnitPrice}
                  Lio_OtherGroupTax={data.OtherGroup_Tax}
                  Lio_OtherGroupTotal={data.OtherGroup_Total}
                  Lio_Personal1UnitPriceAmount={data.Personal1_UnitPrice}
                  Lio_Personal1Tax={data.Personal1_Tax}
                  Lio_Personal1Total={data.Personal1_Total}
                  Lio_Personal2UnitPriceAmount={data.Personal2_UnitPrice}
                  Lio_Personal2Tax={data.Personal2_Tax}
                  Lio_Personal2Total={data.Personal2_Total}
                  Lio_Personal3UnitPriceAmount={data.Personal3_UnitPrice}
                  Lio_Personal3Tax={data.Personal3_Tax}
                  Lio_Personal3Total={data.Personal3_Total}
                  Lio_StsChange={data?.Lio_StsChange}
                  Li_Protection={data?.Li_Protection}

                  onFinishScreen={(output) => {
                    let data = {
                      Lio_InsurerTax: output.Lio_InsurerTax,
                      Lio_InsurerTotal: output.Lio_InsurerTotal,
                      Lio_InsurerUnitPriceAmount: output.Lio_InsurerUnitPriceAmount,
                      Lio_OfficeTax: output.Lio_OfficeTax,
                      Lio_OfficeTotal: output.Lio_OfficeTotal,
                      Lio_OfficeUnitPriceAmount: output.Lio_OfficeUnitPriceAmount,
                      Lio_OtherGroupTax: output.Lio_OtherGroupTax,
                      Lio_OtherGroupTotal: output.Lio_OtherGroupTotal,
                      Lio_OtherGroupUnitPriceAmount: output.Lio_OtherGroupUnitPriceAmount,
                      Lio_Personal2Tax: output.Lio_Personal2Tax,
                      Lio_Personal2Total: output.Lio_Personal2Total,
                      Lio_Personal2UnitPriceAmount: output.Lio_Personal2UnitPriceAmount,
                      Lio_Personal1Tax: output.Lio_Personal1Tax,
                      Lio_Personal1Total: output.Lio_Personal1Total,
                      Lio_Personal1UnitPriceAmount: output.Lio_Personal1UnitPriceAmount,
                      Lio_Personal3Tax: output.Lio_Personal3Tax,
                      Lio_Personal3Total: output.Lio_Personal3Total,
                      Lio_Personal3UnitPriceAmount: output.Lio_Personal3UnitPriceAmount,
                    }

                    // 右側のテーブル配列に金額入力モーダルで入力された金額を反映する
                    let inspectChanges = [...this.state.dataSourceInspectChange]
                    let obj
                    let index2 = inspectChanges.findIndex(x => x.set_code === record.set_code)
                    if (index2 > -1) {
                      obj = { ...inspectChanges[index2] }

                      // 入力された値に置換
                      obj.W2InsurerTotal = data.Lio_InsurerTotal
                      obj.W2OfficeTotal = data.Lio_OfficeTotal
                      obj.W2OtherGroupTotal = data.Lio_OtherGroupTotal
                      obj.W2Personal1Total = data.Lio_Personal1Total
                      obj.W2Personal2Total = data.Lio_Personal2Total
                      obj.W2Personal3Total = data.Lio_Personal3Total
                      // 個人を計算
                      obj.personalTotalPrice = Number(data.Lio_Personal1Total) +
                        Number(data.Lio_Personal2Total) +
                        Number(data.Lio_Personal3Total)
                      // 総計を計算
                      obj.totalPrice = Number(data.Lio_InsurerTotal) +
                        Number(data.Lio_OfficeTotal) +
                        Number(data.Lio_OtherGroupTotal) +
                        Number(data.Lio_Personal1Total) +
                        Number(data.Lio_Personal2Total) +
                        Number(data.Lio_Personal3Total)

                      if (record.changeType === 60) {
                        // 不要の場合、マイナス表示させる
                        obj.W2InsurerTotal = this.setMinusNum(obj.W2InsurerTotal)
                        obj.W2OfficeTotal = this.setMinusNum(obj.W2OfficeTotal)
                        obj.W2OtherGroupTotal = this.setMinusNum(obj.W2OtherGroupTotal)
                        obj.W2Personal1Total = this.setMinusNum(obj.W2Personal1Total)
                        obj.W2Personal2Total = this.setMinusNum(obj.W2Personal2Total)
                        obj.W2Personal3Total = this.setMinusNum(obj.W2Personal3Total)
                        obj.personalTotalPrice = this.setMinusNum(obj.personalTotalPrice)
                        obj.totalPrice = this.setMinusNum(obj.totalPrice)
                      }
                      inspectChanges[index2] = obj
                    }
                    // 右側のテーブル配列に反映
                    this.setState({
                      dataSourceInspectChange: inspectChanges,
                      isChangeData: true
                    })
                    this.closeModal()
                  }}
                />),
            },
          })
        }

      })
  }

  /**
   * 不要の場合、金額をマイナスで表示する
   * @param {*} changeType
   * @param {*} oldNum
   * @returns
   */
  setMinusNum = (oldNum) => {
    let number = Number(oldNum)
    if (number > 0) {
      // マイナス化
      number = -(number)
    } else if (number === 0) {
      // 0の場合は空
      number = ''
    }
    return number
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  /**
  * 検査照会
  */
  medicalExamContentsInquiry() {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      date: this.props.Li_Date,
      contractType: this.props.Li_dataScreen.ContractType,
      contractOrgCode: this.props.Li_dataScreen.ContractClassifyCode,
      ContractNum: this.props.Li_dataScreen.ContractNum,
      ConsultCourse: this.props.Li_ConsultCourse,
      courseLevel: this.props.Li_dataScreen.course_level,
      addressCategory: this.props.Li_dataScreen.address_category,
      jInspectGCategory: this.props.Li_dataScreen.JInspectGCategory,
      dataSourceInspectChange: this.state.dataSourceInspectChange,
      contractStartDate: this.props.Li_dataScreen.ContractDate,
      personalFlag: 1,
    }

    VisitsInspectConsultInquirySubAction(params)
      .then(res => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "1150px",
            style: { top: 10 },
            component: (
              <AnotherInspectItemSettingCategory
                ContractType={this.props.Li_dataScreen.ContractType}
                ContractStartDate={this.props.Li_dataScreen.ContractDate}
                ContractOrgCode={this.props.Li_dataScreen.ContractClassifyCode}
                ContractNum={this.props.Li_dataScreen.ContractNum}
                Medical_exam_course={this.props.Li_ConsultCourse}
                checkFlag={true}
                JInspectGCategory={res.data.JInspectGCategory}
                onAdd={(output) => {
                  let obj = {}
                  // 子画面からもらった情報を画面に合わせたオブジェクトにする
                  obj.id = output.id
                  obj.addedOrDeleted = 50
                  obj.set_code = output.set_code
                  obj.exam_name = output.exam_name
                  obj.UnitPrice = output.unit_price
                  this.setOption(obj, 3)
                }}
                onDelete={(output) => {
                  let obj = {}
                  // 子画面からもらった情報を画面に合わせたオブジェクトにする
                  obj.id = output.id
                  obj.addedOrDeleted = 60
                  obj.set_code = output.set_code
                  obj.exam_name = output.exam_name
                  obj.UnitPrice = output.unit_price
                  this.setOption(obj, 3)
                }}
              />

            )
          }
        })
      }).catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='personal-reserve-process'>
        <Card title='オプション変更'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <div style={{ minWidth: 400, maxWidth: 400 }}>
                <div className='default_main' style={{ padding: 10 }}>
                  <div className='box_inner_horizontal'>

                    <div style={{ width: 16, marginTop: 5 }}>
                      <img src={GetImage(this.state.Expression_140)} alt='icon' />
                    </div>

                    <Form.Item
                      name='AddedOrUnnecessary'
                      style={{ width: 100, marginRight: 10 }}>
                      <Select style={{ width: '100%' }}
                        onChange={(value) => {
                          this.getDataTableLeft()
                          this.setState({
                            AddedOrUnnecessary: value,
                            Expression_140: value === 2 ? 40 : value === 0 ? 20 : value === 1 ? 30 : null
                          })
                        }}
                      >
                        {this.state.dataScreen.ComboBox_AddedOrUnnecessary?.map((item, index) => (
                          <Option
                            key={index}
                            value={item.LinkedField}>
                            {item.DisplayField}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='ChangeClassify'
                      valuePropName='checked'
                      style={{ width: 60 }}>
                      <Checkbox checked={this.state.ChangeClassify}
                        onChange={(e) => {
                          this.contractRedisplay()
                          this.setState({ ChangeClassify: e.target.checked })
                        }}
                      >
                        <label style={{ color: 'white' }}>
                          単品
                        </label>
                      </Checkbox>
                    </Form.Item>
                  </div>

                  <div className='box_inner_horizontal' style={{ marginLeft: 25, width: 'calc(100% - 25px)' }}>
                    <Form.Item
                      name='SearchChar'
                      style={{ marginBottom: 0, width: '100%' }}>
                      <Input onPressEnter={(event) => this.getDataTableLeft(event.target.value)} />
                    </Form.Item>
                    <Button
                      onClick={() =>
                        // データ検索
                        this.getDataTableLeft()
                      }
                      icon={<SearchOutlined />}
                    >
                      検索
                    </Button>
                  </div>

                </div>

                <div hidden={this.state.ChangeClassify}>
                  {/* Table OptionSelect */}
                  {/**
                   * オプションの表示
                   */}
                  <div hidden={this.state.AddedOrUnnecessary != 2} >
                    <Table
                      size='small'
                      dataSource={this.state.dataSourceSelect}
                      rowKey={(record) => record.id}
                      bordered
                      scroll={{ y: 500, x: 'max-content' }}
                      pagination={this.state.dataSourceSelect.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                      onRow={(record, index) => {
                        return {
                          onDoubleClick: () => {
                            this.setOption(record, 2)
                          }
                        }
                      }}
                    >

                      <Table.Column
                        title=''
                        dataIndex='Expression_22'
                        width={35}
                        align='center'
                        render={(value, record, index) =>
                          <div style={{ textAlign: 'center' }}>
                            {record.Expression_22 ?
                              <img
                                style={{ width: 16 }}
                                src={GetImage(record.Expression_22)}
                                alt='icon' />
                              :
                              null
                            }
                          </div>
                        } />

                      <Table.Column
                        title='名称'
                        dataIndex='set_short_name'
                        render={(value, record, index) => (
                          <Tooltip
                            title={record.unChangeable === true ? '請求済みのため追加できません' : ''}
                          >
                            <div style={{ color: record.textColor }}>{record.set_short_name}</div>
                          </Tooltip>
                        )
                        }
                      />

                      <Table.Column
                        width={40}
                        render={(value, record, index) => (
                          <div style={{ textAlign: 'center' }}>
                            <Dropdown
                              trigger='click'
                              style={{
                                display: 'inline-block',
                              }} overlay={() => (
                                <Menu >
                                  <Menu.Item
                                    key='追加1'
                                    hidden={record.unChangeable === true}
                                    onClick={() => {
                                      this.setOption(record, 2)
                                    }}
                                  >
                                    <DoubleRightOutlined />追加
                                  </Menu.Item>
                                  <Menu.Item key='照会1' onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: '50%',
                                        component: (
                                          <WS0333001_SetIncludesQuery
                                            Li_StartDate={this.state.dataScreen.Date}
                                            Li_SetCode={record.set_code ? record.set_code : ''}
                                            Li_CourseCode={this.formRef.current?.getFieldValue('ConsultCourse')}
                                            onRow={this.state.dataScreen}
                                            onFinishScreen={(obj) => {
                                              this.setState({
                                                childModal: {
                                                  ...this.state.childModal,
                                                  visible: false,
                                                },
                                              })
                                            }}
                                          />
                                        ),
                                      },
                                    })
                                  }}>
                                    照会
                                  </Menu.Item>
                                </Menu>
                              )}>
                              <Button size='small' icon={<MoreOutlined />}></Button>
                            </Dropdown>
                          </div>
                        )}
                      />
                    </Table>
                  </div>

                  {/* Table SetSelect */}
                  {/**
                   * 追加・不要
                   */}
                  <div hidden={this.state.AddedOrUnnecessary == 2} >
                    <Table
                      size='small'
                      dataSource={this.state.dataSourceSelect}
                      rowKey={(record) => record.id}
                      bordered
                      scroll={{ y: 500, x: 'max-content' }}
                      pagination={this.state.dataSourceSelect.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                      onRow={(record, index) => {
                        return {
                          onDoubleClick: () => {
                            this.setOption(record, 1)
                          }
                        }
                      }}
                    >

                      <Table.Column
                        title=''
                        dataIndex='addedOrDeleted'
                        width={35}
                        render={(value, record, index) =>
                          <div style={{ textAlign: 'center' }}>
                            {record.addedOrDeleted ?
                              <img style={{ width: 16 }} src={GetImage(record.addedOrDeleted)} alt='icon' />
                              : ''
                            }
                          </div>
                        } />
                      <Table.Column
                        title='名称'
                        dataIndex='set_short_name'
                        render={(value, record, index) => (
                          <Tooltip
                            title={record.unChangeable === true ? '請求済みのため追加できません' : ''}
                          >
                            <div style={{ color: record.textColor }}>{record.set_short_name}</div>
                          </Tooltip>
                        )
                        }
                      />

                      <Table.Column width={40}
                        render={(value, record, index) => (
                          <div style={{ textAlign: 'center' }}>
                            <Dropdown
                              trigger='click'
                              style={{
                                display: 'inline-block',
                              }} overlay={() => (
                                <Menu >
                                  <Menu.Item
                                    key='追加2'
                                    hidden={record.unChangeable === true}
                                    onClick={() => {
                                      this.setOption(record, 1)
                                    }}>
                                    <DoubleRightOutlined />追加
                                  </Menu.Item>
                                  <Menu.Item key='照会2' onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: '50%',
                                        component: (
                                          <WS0333001_SetIncludesQuery
                                            Li_StartDate={this.state.dataScreen.Date}
                                            Li_SetCode={record.set_code ? record.set_code : ''}
                                            Li_CourseCode={this.props.Li_ConsultCourse}
                                            onRow={this.state.dataScreen}
                                            onFinishScreen={(obj) => {
                                              this.setState({
                                                childModal: {
                                                  ...this.state.childModal,
                                                  visible: false,
                                                },
                                              })
                                            }}
                                          />
                                        ),
                                      },
                                    })
                                  }}>
                                    照会
                                  </Menu.Item>
                                </Menu>
                              )}>
                              <Button size='small' icon={<MoreOutlined />}></Button>
                            </Dropdown>
                          </div>
                        )}
                      />
                    </Table>
                  </div>
                </div>

                {/* Table InspectSelect */}
                {/**
                 * 単品の追加・不要
                 */}
                <div hidden={!this.state.ChangeClassify}>
                  <Table
                    size='small'
                    dataSource={this.state.dataSourceSelect}
                    rowKey={(record) => record.id}
                    bordered
                    scroll={{ y: 500 }}
                    pagination={this.state.dataSourceSelect.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                    onRow={(record, index) => {
                      return {
                        onDoubleClick: () => {
                          this.setOption(record, 3)
                        }
                      }
                    }}
                  >
                    <Table.Column
                      title=''
                      dataIndex='addedOrDeleted'
                      width={30}
                      render={(value, record, index) => {
                        return <div style={{ textAlign: 'center' }}>
                          {record.addedOrDeleted ?
                            <img style={{ width: 16 }} src={GetImage(record.addedOrDeleted)} alt='icon' />
                            : ''
                          }
                        </div>
                      }} />
                    <Table.Column
                      title='名称'
                      dataIndex='exam_name'
                      render={(value, record, index) =>
                        <div style={{ color: record.textColor }}>{record.exam_name}</div>
                      }
                    />
                    <Table.Column width={40}
                      render={(value, record, index) => (
                        <div style={{ textAlign: 'center' }}>
                          <Dropdown
                            trigger='click'
                            style={{
                              display: 'inline-block',
                            }} overlay={() => (
                              <Menu >
                                <Menu.Item key='追加3'
                                  onClick={() => {
                                    this.setOption(record, 3)
                                  }}
                                >
                                  <DoubleRightOutlined />追加
                                </Menu.Item>
                              </Menu>
                            )}>
                            <Button size='small' icon={<MoreOutlined />} />
                          </Dropdown>
                        </div>
                      )}
                    />
                  </Table>
                </div>
              </div>

              {/**
               * 右側の表
               */}
              <Table
                size='small'
                dataSource={this.state.dataSourceInspectChange}
                rowKey={(record) => record.id}
                bordered
                scroll={{ x: 'max-content', y: 500 }}
                pagination={false}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      if (record.changeType !== 10) {
                        this.deleteOption(record)
                      }
                    }
                  }
                }}
              >
                <Table.Column
                  title=''
                  dataIndex='changeType'
                  className='column-size-2'
                  render={(value, record, index) => {
                    return <div style={{ textAlign: 'center' }}>
                      {record.changeType ?
                        <img style={{ width: 16 }} src={GetImage(record.changeType)} alt='icon' />
                        : ''
                      }
                    </div>
                  }} />

                <Table.Column
                  title='名称'
                  dataIndex='Expression_17'
                  className='column-size-40'
                  render={(value, record, index) => (
                    <Tooltip
                      title={((record.W2_change_type >= 40 && record.W2_change_type <= 60) && record.unChangeable === true) ? '請求済みのため削除できません' : ''}
                    >
                      <div>{value}</div>
                    </Tooltip>
                  )
                  }
                />

                <Table.Column
                  className='column-size-2'
                  title={
                    <div style={{ textAlign: 'center' }}>
                    </div>
                  }
                  render={(value, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                      <Dropdown
                        trigger='click'
                        style={{
                          display: 'inline-block',
                        }} overlay={() => (

                          <Menu >
                            <Menu.Item
                              key='削除4'
                              hidden={record.W2_change_type < 40 || record.W2_change_type > 60 || record.unChangeable === true}
                              onClick={() => {
                                this.deleteOption(record)
                              }}>
                              <DoubleLeftOutlined />削除
                            </Menu.Item>

                            <Menu.Item
                              key='照会4'
                              onClick={() => {
                                {
                                  record.changeType == 10 ? this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '50%',
                                      component: (
                                        <WS0605127_ContractLineItemDisplay
                                          Li_ContractType={this.state.dataScreen.ContractType}
                                          Li_ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
                                          Li_ContractStartDate={this.state.dataScreen.ContractDate}
                                          Li_ContractNum={this.state.dataScreen.ContractNum}
                                          onFinishScreen={(output) => {
                                            this.closeModal()
                                          }}
                                        />
                                      )
                                    },
                                  })
                                    : this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: '50%',
                                        component: (
                                          <WS0333001_SetIncludesQuery
                                            Li_StartDate={this.state.dataScreen.Date}
                                            Li_SetCode={record.set_code ? record.set_code : ''}
                                            Li_CourseCode={this.formRef.current?.getFieldValue('ConsultCourse')}
                                            onRow={this.state.dataScreen}
                                            onFinishScreen={(obj) => {
                                              this.setState({
                                                childModal: {
                                                  ...this.state.childModal,
                                                  visible: false,
                                                },
                                              })
                                            }}
                                          />
                                        )
                                      },
                                    })
                                }
                              }}
                            >
                              照会
                            </Menu.Item>
                          </Menu>

                        )}>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </div>
                  )}
                />
              </Table>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => this.medicalExamContentsInquiry()}
            >
              <span className='btn_label'>
                検査照会
              </span>
            </Button>
            <Button
              type='primary'
              onClick={() => {
                this.optionConfirm()
              }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
export default WS2537500_PersonalReserveOption
