export const InsurerInfoMaintainTypes = {
  GET_INDUSTRIES: "GET_INDUSTRIES",
  GET_SITUATIONS: "GET_SITUATIONS",
  GET_DISTRICTS: "GET_DISTRICTS",
  GET_DETAIL_INSURER: "GET_DETAIL_INSURER",
  SET_PUBLICS: 'SET_PUBLICS',
  SET_CITIES: 'SET_CITIES',
  SET_AREAS: 'SET_AREAS',
  SET_CURRENT_PUBLIC: 'SET_CURRENT_PUBLIC',
  SET_CURRENT_CITY: 'SET_CURRENT_CITY',
  SET_CURRENT_AREA: 'SET_CURRENT_AREA'
}
