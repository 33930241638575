import React from "react";
import { connect } from "react-redux";
import {
  Form, Card, Row, message, Table
} from 'antd';
import moment from "moment";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import HistoryListAction from 'redux/MN_Basis/HistoryList/HistoryList.action';
import WS2521001_Emaillnquiry from 'pages/MN_Basis/V5MN0001000_Main/WS2521001_Emaillnquiry.jsx';
import Checkbox from "antd/lib/checkbox/Checkbox";

moment.locale("ja", {
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});
class WS2519150_HistoryList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'メール送信';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      historyList: [],
    };
  }


  /**
   * 初期設定
   */
  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let param = {
      Li_userName: this.props.userName
    }
    HistoryListAction.getScreenData(param)
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            historyList: []
          })
        } else {
          this.setState({
            historyList: res
          })
          this.formRef.current?.setFieldsValue({
            medicalExamMmails: res ? res : []
          });
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  addChangeData(event, name, record) {
    let data = this.state.historyList;
    let obj;
    let check;
    // チェックを付けた行を取得する
    for (let i = 0; i < data.length; i++) {
      if (record === data[i].id) {
        obj = data[i];
        break;
      }
    }
    check = event.target.checked ? 1 : 0;
    let param = {
      Li_id: obj.id,
      Li_obj: check
    }
    HistoryListAction.addChangeData(param)
      .then((res) => {
        this.getScreenData();
        this.props.index();

      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  /**
   * opacityの分岐
   * @param {*} check
   * @returns
   */
  getOpacity(check) {
    if (check) {
      return 0.3
    } else {
      return 1
    }
  }

  render() {
    return (
      <div className="send-email">
        <Card title={'送信済み'}>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Table
              style={{ cursor: 'pointer' }}
              id='medicalExamMmails'
              rowKey={(record) => record.id}
              size="small"
              // rowClassName={(record, index) => record.business_code === this.state.selectedBusinessCode ? 'table-row-light' : ''}
              dataSource={this.state.historyList}
              bordered={true}
              pagination={false}
              scroll={{ y: resizableTableScroll(50, 'medicalExamMmails') }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: async () => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 600,
                        component: (
                          <WS2521001_Emaillnquiry
                            selectHistoryId={record.id}
                            getScreenData={() => this.getScreenData()}
                          />
                        ),
                      },
                    });
                  }
                }
              }}
            >
              <Table.Column title="日付" width={100} dataIndex="date_on"
                render={(value, record, index) => {
                  return (
                    <div style={{ opacity: this.getOpacity(record.delete) }}>{moment(value).format('YYYY/MM/DD (ddd)')}</div>
                  )
                }}
              />
              <Table.Column title="時間" width={60} dataIndex="time_at"
                render={(value, record, index) => {
                  return (
                    <div style={{ opacity: this.getOpacity(record.delete) }}>{value}</div>
                  )
                }}
              />
              <Table.Column title="送信先" width={80} dataIndex="visit_user_code"
                render={(value, record, index) => {
                  return (
                    <div style={{ opacity: this.getOpacity(record.delete) }}>{value}</div>
                  )
                }}
              />
              <Table.Column title="件名" width={260} dataIndex="title"
                render={(value, record, index) => {
                  return (
                    <div style={{ opacity: this.getOpacity(record.delete) }}>{value}</div>
                  )
                }}
              />
              <Table.Column title="×" width={40} dataIndex="delete" align='center'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['medicalExamMmails', index, 'delete']} valuePropName="checked">
                      <Checkbox
                        onChange={(event) => { this.addChangeData(event, "delete", record.id) }}
                      />
                    </Form.Item>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2519150_HistoryList);
