import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DeleteOutlined,
  SaveOutlined
} from "@ant-design/icons";
import {
  Card,
  Select,
  Button,
  Form,
  Popconfirm,
  Input
} from "antd";
import WS0494006_HeaderInput from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494006_HeaderInput.jsx';
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx';
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import WS0494007_DataInput from 'pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494007_DataInput.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";

const labelSize = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 60px)' } }
}

class WS0494200_ConditionExpressSetGetCode extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_ParentNum: PropTypes.any,
    Lio_ConditionalExpression: PropTypes.any,
    Lio_System: PropTypes.any,
  };
  constructor(props) {
    super(props);

    // document.title = "条件式情報保守編集";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      data: {
        ConditionalExpressionDisplay: '',
        ConditionalExpression: ''
      },
      textFocus: '',
      positionPointer: 0,
      Lio_Operator: 0,
      Lo_Variable: '+',
      stsFocusAll: false,
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({ 'select': 0 })
  }

  /**
   * 現在のselectから呼ぶ処理を場合分け
   */
  callEvent() {
    let select = this.formRef.current?.getFieldValue('select')
    console.log(select);
    switch (select) {
      case 0:
        this.eventF4()
        break
      case 1:
        this.eventF7()
        break
      case 2:
        this.eventF8()
        break
      case 3:
        this.eventF9()
        break
      case 4:
        this.eventF10()
        break
    }
  }

  /**
   * ヘッダ
   */
  eventF4 = () => {
    let props = { Lo_HeaderInfo: 0 };
    this.callModal(props, 400, 'WS0494006_HeaderInput')
  }

  /**
   * 総合判定
   */
  eventF7 = () => {
    let Li_InsertedChar = '[H5010029]'; // '[H'&Trim(Str(05010029,'8'))&']'
    let Li_SelectPosition = this.state.positionPointer; // CaretPosGet(): vị trí con trỏ trong chuỗi
    let Li_MarkNumChars = this.state.textFocus.length; // Len(MarkedTextGet()): chiều dài chuỗi đang được bôi đen
    this.CharacterInsertion({ Li_InsertedChar, Li_SelectPosition, Li_MarkNumChars })
  }

  /**
   * dataに入れる
   * @param {*} value 
   * @param {*} name 
   */
  handleChangeData = (value, name) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    })
    this.formRef.current?.setFieldsValue({ 'ConditionalExpression': value })
  }

  /**
   * コードを入れる
   * @param {*} param0 
   */
  CharacterInsertion = ({ Li_InsertedChar, Li_SelectPosition, Li_MarkNumChars, event }) => {
    const { data, stsFocusAll, textFocus } = this.state;
    let valueTemp = data.ConditionalExpression;
    if (stsFocusAll) {// replaceAll value
      valueTemp = Li_InsertedChar;
      this.handleChangeData(valueTemp, 'ConditionalExpression');
    } else {
      if (Li_MarkNumChars > 0) {
        valueTemp = valueTemp.replace(textFocus, '');
      }
      if (valueTemp?.length > 0) {
        let arr, str;
        arr = valueTemp.split('');
        arr.splice(Li_SelectPosition, 0, Li_InsertedChar)
        str = arr.toString();
        valueTemp = str.replaceAll(',', '');
      } else {
        valueTemp = Li_InsertedChar;
      }
      console.log(valueTemp);
      this.setState({
        data: {
          ...data,
          ConditionalExpression: valueTemp
        }
      })
      this.formRef.current?.setFieldsValue({ 'ConditionalExpression': valueTemp })
    }
    if (event === 'WS0494006_HeaderInput') {
      this.setState({
        stsFocusAll: false,
        textFocus: '',
        positionPointer: valueTemp.length
      }, () => this.inputRef?.current?.focus({ cursor: 'end' }))
    } else {
      this.setState({
        stsFocusAll: true,
        textFocus: valueTemp,
        positionPointer: valueTemp.length
      }, () => this.inputRef?.current?.focus({ cursor: 'all' }))
    }
  }

  /**
   * ｶﾃｺﾞﾘ
   */
  eventF8 = () => {
    let props = {
      Lio_CategoryCode: 0,
      Li_UnusedInspectDisplay: ''
    };
    this.callModal(props, 500, 'WS0267001_CategorySearchQuerySingle')
  }

  /**
   * 検査
   */
  eventF9 = () => {
    let props = {
      Lio_InspectItemCode: 0,
      Li_UnusedInspectDisplay: ''
    }
    this.callModal(props, 800, 'WS0271001_InspectItemSearchQuerySingle')
  }

  /**
   * 演算子
   */
  eventF10 = () => {
    let props = {
      Lio_Operator: this.state.Lio_Operator,
      Lo_Variable: this.state.Lo_Variable
    }
    this.callModal(props, 400, 'WS0494007_DataInput')
  }

  /**
   * 呼ぶ画面を返す
   * @param {*} component 
   * @returns 
   */
  ReturnComponent = (component) => {
    let components = {
      WS0494006_HeaderInput,
      WS0267001_CategorySearchQuerySingle,
      WS0271001_InspectItemSearchQuerySingle,
      WS0494007_DataInput
    };
    return components[component];
  }

  /**
   * モーダル呼び出し
   * @param {*} props 
   * @param {*} width 
   * @param {*} nameScreen 
   */
  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={({ Lo_HeaderInfo, Lio_CategoryCode, Lio_InspectItemCode, Lio_Operator, Lo_Variable, recordData }) => {
              this.closeModal();
              let value = '';
              console.log(Lo_HeaderInfo);
              console.log(Lio_CategoryCode);
              console.log(Lio_InspectItemCode);
              console.log(Lio_Operator);
              console.log(Lo_Variable);
              console.log(recordData);
              if (Lo_HeaderInfo && parseInt(Lo_HeaderInfo) > 0) { // F4
                value = '[H' + Lo_HeaderInfo + ']' + '\n';
              } else if (Lio_CategoryCode && parseInt(Lio_CategoryCode) > 0) { // F8
                value = "'[C" + Lio_CategoryCode + "]'";
              } else if (recordData?.test_item_code && parseInt(recordData?.test_item_code) > 0) { // F9
                if (recordData.exam_type === 'S') {
                  // message.warning('所見は使用できません')
                }
                value = "'[K" + recordData?.test_item_code + "]'";
                console.log(value);
              } else if (Lo_Variable !== '') { // F10
                value = Lo_Variable;
                this.setState({ Lo_Variable, Lio_Operator })
              }
              console.log('value.length > 0 前');
              if (value?.length > 0) {
                console.log('if');
                this.CharacterInsertion({
                  Li_InsertedChar: value,
                  Li_SelectPosition: this.state.positionPointer,
                  Li_MarkNumChars: this.state.textFocus.length,
                  event: nameScreen
                })
              }
            }}
          />
        ),
      },
    });
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  /**
   * 保存処理
   */
  onSave() {
    this.props.onSave(this.formRef.current?.getFieldValue())
  }

  render() {
    return (
      <div className="condition-info-maintain-edit">
        <Form
          ref={this.formRef}
        >
          <Card title='コード取得'>
            <div className="box_inner_horizontal">
              <Form.Item name='select'>
                <Select
                  style={{ width: "200px" }}
                >
                  <Select.Option value={0}>ヘッダ</Select.Option>
                  <Select.Option value={1}>総合判定</Select.Option>
                  <Select.Option value={2}>ｶﾃｺﾞﾘ</Select.Option>
                  <Select.Option value={3}>検査</Select.Option>
                  <Select.Option value={4}>演算子</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name='ConditionalExpression'>
                <Input
                  style={{ width: '150px' }}
                  onDoubleClick={() => {
                    this.callEvent()
                  }}
                />
              </Form.Item>
            </div>
            {/* <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.props.onFinishScreen(this.formRef.current?.getFieldValue('ConditionalExpression'))}
              >
                <span className='btn_label'>
                  取得
                </span>
              </Button>
            </div> */}
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0494200_ConditionExpressSetGetCode);