import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Radio, Button, Table, Dropdown, Menu } from 'antd'
import { getSetInfoSearchListAction, getScreenDataSetInfoSearchAction } from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions'
import { MoreOutlined } from '@ant-design/icons'
import WS0333001_SetIncludesQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQuery.jsx'
import { debounce } from 'lodash'
import Color from 'constants/Color'
class WS0302001_SetInfoSearch extends React.Component {
  static propTypes = {
    Li_SetIdentify: PropTypes.any,
    Li_StartDate: PropTypes.any,
    Li_CourseCode: PropTypes.any,
    _Lo_Return: PropTypes.any,
    Li_ContextId: PropTypes.any,
    Li_RangeSetting: PropTypes.any,
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    Lo_SetCode: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = 'セット情報検索'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initialValues: {
        Li_SetIdentify: 'All',
        Li_SetShortName: '',
        Li_RangeSetting: '',
        Li_ExamList: '',
        Li_ContractPresence: true,
        Li_ContractType: '',
        Li_ContractOrgCode: '',
        Li_ContractStartDate: '',
        Li_ContractNum: '',
        Li_InspectCategoryBreakdown: ''
      },
      isShowCategory2: true,
      setDefaultRadio: {
        Category1: 'All',
        Category2: 'Cos'
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      tableDataPaginate: {},
      selectedRowKeys: [],
      selectedRows: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      selectedRowKeys: this.props.selectedRowKeys ? this.props.selectedRowKeys : [],
      selectedRows: this.props.selectedRows ? this.props.selectedRows : []
    })
    this.loadScreenData()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      let params = {
        Li_SetIdentify: this.props.Li_SetIdentify,
        Li_CourseLevel: this.props.Li_CourseLevel,
        Li_ReserveNum: this.props.Li_ReserveNum,
        Li_ContractType: this.props.Li_ContractType,
        Li_ContractOrgCode: this.props.Li_ContractOrgCode,
        Li_ContractStartDate: this.props.Li_ContractStartDate,
        Li_ContractNum: this.props.Li_ContractNum
      }
      this.loadScreenData(params)
    }
  }

  loadScreenData = (page, pageSize) => {
    let params = {
      Li_SetIdentify: this.props.Li_SetIdentify,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_ContractType: this.props.Li_ContractType,
      Li_ContractOrgCode: this.props.Li_ContractOrgCode,
      Li_ContractStartDate: this.props.Li_ContractStartDate,
      Li_ContractNum: this.props.Li_ContractNum
    }
    getScreenDataSetInfoSearchAction(params)
      .then(res => {
        let values = {
          ...this.state.initialValues,
          Li_ExamList: res.data.ExamList,
          Li_ContractPresence: res.data.StsTermsAndConditionInfo,
          Li_InspectCategoryBreakdown: res.data.exam_category,
        }

        this.setState({ initialValues: values })

        this.callAPILoadData(values)
      })
      .catch()
  }

  callAPILoadData = (params) => {
    getSetInfoSearchListAction(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res,
          })
        }
      })
  }

  handleSearch = (e) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        Li_SetShortName: e.target.value,
      }
    }, () => this.callAPILoadData(this.state.initialValues))
  }

  handleButton = () => {
  }

  handleChangeRadio = (e) => {
    const formInstance = this.formRef.current
    if (e.target.value === 'All' || e.target.value === 'Tan') {
      const SetIdentifyTemp = formInstance.getFieldValue('Category1')
      this.setState({
        isShowCategory2: true,
        initialValues: {
          ...this.state.initialValues,
          Li_SetIdentify: SetIdentifyTemp
        }
      }, () => this.callAPILoadData(this.state.initialValues))
    } else {
      const SetIdentifyTemp = formInstance.getFieldValue('Category2')
      this.setState({
        isShowCategory2: false,
        initialValues: {
          ...this.state.initialValues,
          Li_SetIdentify: SetIdentifyTemp
        }
      }, () => this.callAPILoadData(this.state.initialValues))
    }
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  /**
   * 選択 処理
   * @param {*} record
   */
  onSelect = (record) => {
    let arrTemp = [...this.state.selectedRowKeys]
    let arrTempRecord = [...this.state.selectedRows]
    let idx = arrTemp.indexOf(record.id)
    if (idx === -1) {
      arrTemp.push(record.id)
      arrTempRecord.push(record)
    } else {
      arrTemp.splice(idx, 1)
      arrTempRecord.splice(idx, 1)
    }
    this.setState({
      selectedRowKeys: arrTemp,
      selectedRows: arrTempRecord
    })
    if (this.props.onSelectedRowKeys) {
      // selectedRowKeysを親画面に渡す
      this.props.onSelectedRowKeys(arrTemp)
    }
    if (this.props.onSelectedData) {
      // 選択されているデータ配列を親画面に渡す
      this.props.onSelectedData(arrTempRecord)
    }
  }

  /**
   * 全選択 処理
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let arrTemp = []
    let arrTempRecord = []
    if (selected) {
      arrTempRecord = [...this.state.dataSource]
      arrTemp = arrTempRecord.map(item => item.id)
      this.setState({
        selectedRowKeys: arrTemp,
        selectedRows: arrTempRecord
      })
    } else {
      this.setState({
        selectedRowKeys: [],
        selectedRows: []
      })
    }
    if (this.props.onSelectedRowKeys) {
      // selectedRowKeysを親画面に渡す
      this.props.onSelectedRowKeys(arrTemp)
    }
    if (this.props.onSelectedData) {
      // 選択されているデータ配列を親画面に渡す
      this.props.onSelectedData(arrTempRecord)
    }
  }

  render() {
    const { setDefaultRadio, isShowCategory2, } = this.state
    return (
      <div className='set-info-search'>
        <Card title='セット情報検索'>
          <Form
            ref={this.formRef}
            initialValues={setDefaultRadio}
            autoComplete='off'
          >
            <div className='box_inner_horizontal' style={{ marginBottom: 10 }}>

              <div className='box_search' style={{ width: 250 }}>
                <Form.Item
                  name='Category1'
                  style={{ marginBottom: 0 }}
                >
                  <Radio.Group
                    name='Category1'
                    onChange={this.handleChangeRadio}
                  >
                    <Radio value='All'>全て</Radio>
                    <Radio value='condition'>セット</Radio>
                    <Radio value='Tan'>単品</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className='box_search' style={{ width: 270 }}>
                <Form.Item
                  name='Category2'
                  style={{ marginBottom: 0 }}
                >
                  <Radio.Group
                    name='Category2'
                    disabled={isShowCategory2}
                    onChange={this.handleChangeRadio}>
                    <Radio value='Cos'>コース</Radio>
                    <Radio value='Opt'>ｵﾌﾟｼｮﾝ</Radio>
                    <Radio value='Set'>その他</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className='box_search'>
                <Form.Item
                  name='SetShortName'
                  label='検索'
                  style={{ marginBottom: 0 }}>
                  <Input
                    onPressEnter={debounce((e) => this.handleSearch(e), 300)}
                  />
                </Form.Item>
              </div>
            </div>

            <Table
              size='small'
              bordered
              dataSource={this.state.dataSource}
              rowKey={record => record.id}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    if (this.props.onFinishScreen) {
                      this.props.onFinishScreen({
                        Lo_SetCode: record.set_code,
                        recordData: record
                      })
                    }
                  }
                }
              }}
              scroll={{ y: 500 }}
              rowSelection={
                this.props.multipleSelect ?
                  {
                    type: 'checkbox',
                    fixed: 'left',
                    selectedRowKeys: this.state.selectedRowKeys,
                    onSelect: (record) => {
                      // 行選択
                      this.onSelect(record)
                    },
                    onSelectAll: (selected) => {
                      // 行全選択
                      this.onSelectAll(selected)
                    },
                  }
                  :
                  undefined
              }
            >
              <Table.Column title='コード' dataIndex='set_code' width={95} />
              <Table.Column title='セット名称' dataIndex='set_name' />
              <Table.Column title='セット略称' dataIndex='set_short_name' />
              <Table.Column title='検索略称' dataIndex='search_short_name' width={120} />
              <Table.Column title='条' dataIndex='conditions_effective' width={40} align='center'
                render={(value) => {
                  return ''
                  // 必要なし？？
                  // const color = Color(value).Foreground
                  // return (
                  //   <div
                  //     style={{
                  //       background: Color(value).Background,
                  //       border: '1px solid',
                  //       borderColor: color,
                  //       color: color,
                  //       textAlign: 'center',
                  //     }}
                  //   >
                  //     条
                  //   </div>
                  // )
                }}
              />
              <Table.Column
                title='性別'
                dataIndex='conditions_gender'
                width={60}
                render={(value, record) =>
                  <div style={{
                    color: Color(record.genderColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {value}
                  </div>
                }
              />
              <Table.Column title='続柄' dataIndex='name' width={80} />
              <Table.Column title='単価' dataIndex='unit_price' width={100}
                render={(text) =>
                  <div style={{ textAlign: 'right' }}>
                    {text === 0 || text === '0' ? '' : text.toLocaleString()}
                  </div>
                }
              />
              <Table.Column
                width={40}
                align='center'
                render={(value, row) => (
                  <Dropdown
                    type='primary'
                    trigger='click'
                    overlay={
                      <Menu>
                        <Menu.Item
                          key='menu-1'
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 900,
                                component: (
                                  <WS0333001_SetIncludesQuery
                                    Li_StartDate={this.props.Li_StartDate || '0000/00/00'}
                                    Li_SetCode={row.SetCodeSetIncludeQuery}
                                    Li_CourseCode={this.props.Li_CourseCode}
                                    onClickedSelect={() => {
                                      this.closeModal()
                                    }}
                                  />
                                )
                              }
                            })
                          }}>
                          セット内訳
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button size='small' icon={<MoreOutlined />} />
                  </Dropdown>
                )} />
            </Table>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                disabled={(this.props.multipleSelect && this.state.selectedRowKeys.length > 0) ? false : true}
                onClick={() => {
                  this.props.multipleSelect({
                    selectedRowKeys: this.state.selectedRowKeys,
                    selectedRows: this.state.selectedRows
                  })
                }}
                style={{ display: this.props.multipleSelect ? '' : 'none' }}
              >
                <span className='btn_label'>
                  複数選択
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0302001_SetInfoSearch)
