import axios from 'configs/axios'

const apiPaths = {
  index: '/api/ocr-capture-start-up/ocr-capture-start-up',
  getInitialData: '/api/ocr-capture-start-up/ocr-capture-start-up/get-initial-data',
  read: '/api/ocr-capture-start-up/ocr-capture-start-up/read',
  getOcrCaptures: '/api/ocr-capture-start-up/ocr-capture-start-up/get-ocr-captures',
  downloadLog: '/api/ocr-capture-start-up/ocr-capture-start-up/download-log',

  deleteBatchBefore: '/api/ocr-capture-start-up/ocr-capture-start-up/delete-batch-before',
  batchDelete: '/api/ocr-capture-start-up/ocr-capture-start-up/batch-delete',
}

const OcrCaptureStartUpService = {

  async index() {
    return axios.get(apiPaths.index)
  },

  async getInitialData() {
    return axios.get(apiPaths.getInitialData)
  },

  async read(params) {
    return axios.get(apiPaths.read, { params })
  },

  async getOcrCaptures(params) {
    return axios.post(apiPaths.getOcrCaptures, params)
  },

  async downloadLog(params) {
    return axios.post(apiPaths.downloadLog, params, { responseType: 'blob' });
  },

  async deleteBatchBefore() {
    return axios.get(apiPaths.deleteBatchBefore)
  },

  async batchDelete() {
    return axios.get(apiPaths.batchDelete)
  },
}

export default OcrCaptureStartUpService
