import axios from 'configs/axios'

const API_LIST = {
  index: '/api/course-specific-stard-style-setting/print-style-inquiry',
}

const PrintStyleInquiryService = {
  async index() {
    return axios.get(API_LIST.index)
  },
}

export default PrintStyleInquiryService
