import ExportDataService from 'services/AccountingBusiness/DepositWithdrawalInquiry/ExportDataService';
import { message, } from "antd"

const ExportDataAction = {
  /**
   * txt出力 
   */
  ExportTxt(params) {
    return ExportDataService.ExportTxt(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  /**
   * html出力 
   */
  ExportHtml(params) {
    return ExportDataService.ExportHtml(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  /**
   * xml出力 
   */
  ExportXml(params) {
    return ExportDataService.ExportXml(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  /**
   * xsd出力 
   */
  ExportXsd(params) {
    return ExportDataService.ExportXsd(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default ExportDataAction;