/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import { MoreOutlined, MenuOutlined, EditOutlined, QuestionCircleOutlined, RightOutlined, DoubleRightOutlined, LeftOutlined, DoubleLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Button, Select, Table, Row, Col, Menu, message, Tooltip, Dropdown, Modal, } from 'antd'
import ReserveStatusSearchService from 'services/ReservationBusiness/ReserveStatusSearch/ReserveStatusSearchService'
import moment, { max } from 'moment-timezone'
import Color from 'constants/Color'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS2737001_MiraisSingleTransmission from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737001_MiraisSingleTransmission'
import WS2580001_ScheduleChange from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2580001_ScheduleChange.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2528031_SettingDisplayItem from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2528031_SettingDisplayItem.jsx'
import WS1490001_SpecificDatePeopleNumSetting from 'pages/SM_SystemMaintenance/V4SM0004000_SpecificDatePeopleNumSetting/WS1490001_SpecificDatePeopleNumSetting'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import WS2577003_CalendarEmptySub from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577003_CalendarEmptySub.jsx'
import WS2532001_GroupBookings from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532001_GroupBookings.jsx'
import FrameReserve from 'pages/ZZ_Others/SANAI_FrameReserve/FrameReserve'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS0548001_ReservesPersonsBulkUpdate from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS0548001_ReservesPersonsBulkUpdate.jsx'
import { download_file } from 'helpers/CommonHelpers'
import ReservationDetails from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/ReservationDetails'
import MemoFunction from 'pages/ZZ_Others/SANAI_Memo/MemoFunction'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx'
import MedicalCheckupCalendar from 'pages/ZZ_Others/SANAI_MedicalCheckupCalendar/MedicalCheckupCalendar.jsx'
import CustomInspectionDisplayAction from 'redux/Others/ReserveStatusSearch/CustomInspectionDisplay.action'

class CustomInspectionDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '予約状況検索（カスタム）'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    console.log(this.props);
    this.index()
  }

  index() {
    let params = {
      date_on: this.props.date,
      index: this.props.index
    }
    console.log(params);
    CustomInspectionDisplayAction.index(params)
      .then((res) => {
        console.log(res);
        this.setState({
          dataSource: res.result
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='reserve-status-search-custom'>
        <Card title={'予約状況検索検査明細　' + this.props.name}>
          <Form
            ref={this.formRef}
          >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(212) }}
            >
              <Table.Column
                className='column-size-2'
                title='No'
                align='left'
                width={50}
                dataIndex='No'
                render={(text, record, index) => {
                  return (
                    <div>
                      {index + 1}
                    </div>
                  )
                }}
              />
              <Table.Column
                title='予約時間'
                align='left'
                width={100}
                dataIndex='period_time'
              />
              <Table.Column
                title='ID'
                align='left'
                width={120}
                dataIndex='ID'
              />
              <Table.Column
                title='名前'
                align='left'
                width={240}
                dataIndex='kanji_Name'
              />
              <Table.Column
                title='フリガナ'
                align='left'
                width={240}
                dataIndex='kana_name'
              />
              <Table.Column
                title='性別'
                align='left'
                width={50}
                dataIndex='Gender'
              />
              <Table.Column
                title='生年月日'
                align='left'
                dataIndex='birthday_on'
                width={140}
              />
              <Table.Column
                title='予約団体名称'
                align='left'
                width={250}
                dataIndex='office_kanji_name'
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomInspectionDisplay)
