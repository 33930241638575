import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Modal, message } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}

class WS1543002_DocumentEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '資料管理保守';

  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
  * データ保存
  */
  onFinish = (values) => {
    if (!values.document_management_number) {
      message.error('管理番号を入力してください')
      return
    } else {
      this.props.onUpdate(values)
    }
  }

  /**
  * データ削除
  * @param {*} id
  */
  delete = () => {
    this.props.onDelete()
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className="Document-Manage-Maintain p-td">
        <Card title={'資料管理保守 [' + (this.props.newFlag ? '新規' : '変更') + ']'} style={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='管理番号'
                name='document_management_number'//対応したものに書き換え
                {...labelCol}
              >
                <Input
                  min="0"
                  className="input-size-number-5"
                />
              </Form.Item>

              <Form.Item
                label='資料名称'
                name='document_title'//対応したものに書き換え
                {...labelCol}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1543002_DocumentEdit);
