import CodeChangesService from "services/basicInfo/ContractInfoMaintain/CodeChangesService";
import { message, } from "antd"

const CodeChangesAction = {
  codeChange(params) {
    return CodeChangesService.codeChange(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

};
export default CodeChangesAction;