import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Color from "constants/Color";
import GetImage from "constants/Images.js";
import moment from 'moment';
import NumberFormat from "react-number-format";

import { Card, Form, Input, Radio, Button, Select, Space, Table, Row, Col, Modal, message, Tooltip, InputNumber } from "antd";
import { MoreOutlined, QuestionCircleOutlined, SaveOutlined, DeleteOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, } from '@ant-design/icons';

import WS2533001_ConfirmSub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2533001_ConfirmSub.jsx';
import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx';
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx';
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx';
import GroupBookingsAction from "redux/ReservationBusiness/GroupBookings/GroupBookings.action";
import WS2556001_ReservesBulkChanges from "../V5YK0005000_ReservesBulkChanges/WS2556001_ReservesBulkChanges";
import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx';
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx';
import WS2787004_AffiliationSelectSub from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS2787004_AffiliationSelectSub.jsx';
import WS2532008_InsuranceCardInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532008_InsuranceCardInquiry.jsx';
import WS2577003_CalendarEmptySub from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577003_CalendarEmptySub.jsx';
import WS2553003_PeriodTimeInquiry from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2553003_PeriodTimeInquiry.jsx';
import WS2532012_DateOfBirthInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532012_DateOfBirthInquiry.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";

import { ReturnIcon } from "components/Commons/ReturnIcon";

const { Option } = Select;

class WS2532500_GroupBookingsAdd extends React.Component {
  static propTypes = {
    Li_ReserveDate: PropTypes.any,
    Li_Way: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef();
  inputRefReserveDate = React.createRef();
  inputTimezone = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '団体予約';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 19,
        size: 'small',
        showQuickJumper: true,
        pageSizeOptions: [19]
      },
      RelationshipList: [],
      FacilityNumList: [],
      listSelectedId: [],
      initObj: {},
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      importance: 0,
      initParams: {
        OfficeCode: '',
        BranchStoreCode: '',
        SortOrder: 0,
        TmpplaceCode: '',
        Relationship: '',
        Gender: '0',
        StsPreviousYearExtract: 0,
        StsThisYearNotYetVisited: 0,
        InsuranceCardNum: '',
        DateOfBirthChar: '', // 
        DateChar: '', //
        TimeZone: '',
        FacilityNum: 1,
        Course: '',
      },
    };
  }

  componentDidMount = () => {
    console.log(this.props.Li_initParams)
    console.log(this.props.Li_initObj)
    this.setState({
      initParams: this.props.Li_initParams,
      initObj: this.props.Li_initObj,
      FacilityNumList: this.props.Li_FacilityNumList
    })
    this.formRef.current.setFieldsValue({
      DateChar: this.props.Li_initParams.DateChar,
      TimeZone: this.props.Li_initParams.TimeZone,
      FacilityNum: this.props.Li_initParams.FacilityNum,
      Course: this.props.Li_initParams.Course
    })
  }

  ReturnComponent = (component) => {
    let components = {
      WS0247001: WS0247001_OfficeInfoRetrievalQuery,
      WS2585001: WS2585001_OfficeInfoInquirySub,
      WS2787004: WS2787004_AffiliationSelectSub,
      WS2532008: WS2532008_InsuranceCardInquiry,
      WS2577003: WS2577003_CalendarEmptySub,
      WS2553003: WS2553003_PeriodTimeInquiry,
      WS2532012: WS2532012_DateOfBirthInquiry,
    };
    return components[component];
  }

  callbackOpenModal = (screen, props, nameForm) => {
    let Component = this.ReturnComponent(screen);

    switch (screen) {
      case 'WS2532012':
      case 'WS2532008':
      case 'WS2787004':
      case 'WS2585001':
      case 'WS0247001': {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: screen === 'WS2787004' ? '60%' : '80%',
            component: (
              <Component
                // WS0247001 (props)
                Li_NewlyRegisteredPresence={''}
                Lio_OfficeCode={this.state.initParams.OfficeCode}
                Lio_BranchStoreCode={this.state.initParams.BranchStoreCode}
                Li_1HeadOffice2BranchShop={1}
                // WS2585001, WS2787004, WS2532008, WS2532012
                Li_OfficeCode={this.state.initParams.OfficeCode}
                Li_BranchStoreCode={this.state.initParams.BranchStoreCode}
                Lio_AffiliationCode={this.state.initParams.TmpplaceCode} // WS2787004
                Li_Relationship={this.state.initParams.Relationship} // WS2532008, WS2532012

                onFinishScreen={({
                  Lio_OfficeCode,
                  Lio_BranchStoreCode,
                  Lo_Kanji_Name,
                  Lio_AffiliationCode,
                  Lo_InsuranceCardNum,
                  Lo_DateOfBirth,
                  recordData
                }) => {
                  if (screen === 'WS0247001') {
                    this.formRef.current.setFieldsValue({
                      OfficeCode: Lio_OfficeCode,
                      BranchStoreCode: Lio_BranchStoreCode === 0 ? '' : Lio_BranchStoreCode,
                      office_kanji_name: Lo_Kanji_Name
                    });
                    this.setState({
                      initParams: {
                        ...this.state.initParams,
                        BranchStoreCode: Lio_BranchStoreCode,
                        OfficeCode: Lio_OfficeCode,
                      },
                      importance: recordData.importance
                    });
                  }
                  if (screen === 'WS2787004') {
                    this.setStateInitParams(Lio_AffiliationCode, 'TmpplaceCode');
                  }
                  if (screen === 'WS2532008') {
                    this.setStateInitParams(Lo_InsuranceCardNum, 'InsuranceCardNum');
                  }
                  if (screen === 'WS2532012') {
                    this.setStateInitParams(Lo_DateOfBirth, 'DateOfBirthChar');
                    this.formRef?.current?.setFieldsValue({ DateOfBirthChar: moment(Lo_DateOfBirth).format('NNy/MM/DD') })
                  }

                  this.closeModal();
                }}
              />
            ),
          },
        });
        break;
      }
      case 'WS2553003':
      case 'WS2577003': {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: screen === 'WS2553003' ? '20%' : '70%',
            component: (
              <Component
                {...props}
                onFinishScreen={(output) => {
                  if (screen === 'WS2577003') {
                    if (nameForm === 'ReserveDate') {
                      this.handleChangeRecord(output, 'ReserveDate');
                    } else {
                      this.setStateInitParams(output, 'DateChar');
                    }
                  }
                  if (screen === 'WS2553003') {
                    if (nameForm === 'W1_time_zone') {
                      this.handleChangeRecord(output.Lio_TimeZone, 'W1_time_zone');
                    } else {
                      this.setStateInitParams(output.Lio_TimeZone, 'TimeZone');
                    }
                  }

                  this.closeModal()
                }}
              />
            ),
          },
        })
        break;
      }
      case 'WS0289012': {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1400,
            component: (
              <WS0289012_ContractInfoInquiry
                {...props}
                onFinishScreen={({
                  Lio_ConsultCourse,
                  Lo_ContractType,
                  Lo_ContractOrgCode,
                  Lo_ContractStartDate,
                  Lo_ContractNum,
                  Lo_Status,
                  Lo_ErrorMessage,
                  recordData,
                }) => {
                  Lio_ConsultCourse = Lio_ConsultCourse ? Lio_ConsultCourse.replace('-', '') : '';
                  if (nameForm === 'W1_visits_courses') {
                    let total = recordData.Expression_42 + recordData.Expression_43 + recordData.Expression_44 + recordData.Expression_36;
                    let obj = {
                      ...this.state.rowSelect,
                      W1_visits_courses: Lio_ConsultCourse,
                      Expresstion_45: Lio_ConsultCourse,
                      Expresstion_46: recordData.W1_contract_short_name,
                      contract_short_name: recordData.W1_contract_short_name,
                      W1_contract_type: Lo_ContractType,
                      W1_contract_org_cd: Lo_ContractOrgCode,
                      W1_contract_date: Lo_ContractStartDate,
                      W1_contract_num: Lo_ContractNum,
                      insurer_total_price: recordData.Expression_42 ? recordData.Expression_42.toLocaleString() : null,
                      office_total_price: recordData.Expression_43 ? recordData.Expression_43.toLocaleString() : null,
                      organization_total_price: recordData.Expression_44 ? recordData.Expression_44.toLocaleString() : null,
                      Expresstion_57: recordData.Expression_36 ? recordData.Expression_36.toLocaleString() : null,
                      Expresstion_58: total ? total.toLocaleString() : null,
                    }
                    let arr = [...this.state.dataSource];
                    let index = arr.findIndex(item => item.id === obj.id);
                    arr[index] = obj;
                    this.setState({
                      dataSource: arr,
                      rowSelect: obj
                    });
                    this.updateData(obj)
                    this.formRef?.current?.setFieldsValue({ rowSelect: obj, dataSource: arr })
                  } else {
                    this.setStateInitParams(Lio_ConsultCourse, 'Course');
                    this.setState({
                      initObj: {
                        ...this.state.initObj,
                        contract_short_name: recordData.W1_contract_short_name,
                        ContractType: Lo_ContractType,
                        ContractOrgCode: Lo_ContractOrgCode,
                        ContractDate: Lo_ContractStartDate,
                        ContractNum: Lo_ContractNum,
                      }
                    })
                  }
                  this.closeModal()
                }}
              />
            ),
          },
        })
        break;
      }
      default: break;
    }
  }

  setStateInitParams = (value, name) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        [name]: value
      }
    });
    if (name === 'OfficeCode' || name === 'BranchStoreCode') this.setState({ importance: 0 })
    this.formRef.current.setFieldsValue({ [name]: value });
  }

  ModalError = (content, refName) => {
    Modal.error({
      content: content || '事業所が無効です',
      okText: 'OK',
      onOk: () => {
        if (typeof refName == 'string') {
          this[refName]?.current?.focus()
        } else if (typeof refName == 'function') {
          refName();
        }
      }
    })
  }

  checkTimeZone = (value) => {
    if (value) {
      let arr = value.split(":");
      if ((8 <= parseInt(arr[0]) <= 12) && (parseInt(arr[1]) === 0 || parseInt(arr[1]) === 30)) {
        return true
      }
    }
    return false
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  Extract = () => {
    this.props.onFinishScreen({
      initParams: {
        ...this.state.initParams,
        DateChar: this.formRef.current.getFieldValue('DateChar'),
        TimeZone: this.formRef.current.getFieldValue('TimeZone'),
        FacilityNum: this.formRef.current.getFieldValue('FacilityNum'),
        Course: this.formRef.current.getFieldValue('Course'),
      },
      initObj: {
        ...this.state.initObj,
        ContractType: this.state.initObj.ContractType,
        ContractOrgCode: this.state.initObj.ContractOrgCode,
        ContractDate: this.state.initObj.ContractDate,
        ContractNum: this.state.initObj.ContractNum,
      }
    })
  }

  render() {
    // console.log(this.state.initObj.ContractType)
    // console.log(this.state.initObj.ContractOrgCode)
    // console.log(this.state.initObj.ContractDate)
    // console.log(this.state.initObj.ContractNum)
    return (
      <div className="group-bookings">
        <Form ref={this.formRef}>
          <Card title='設定'>
            <Form.Item name="DateChar" label="受診日" >
              <Input.Search
                style={{ width: '160px' }}
                onChange={(e) => this.setStateInitParams(e.target.value, 'DateChar')}
                onBlur={e => {
                  if (moment(e.target.value, 'YYYY/MM/DD', true).isValid()) {
                    this.setStateInitParams(e.target.value, 'DateChar')
                  } else {
                    this.ModalError('不正な日付です', () => {
                      this.formRef.current.getFieldInstance('DateChar').focus();
                    })
                  }
                }}
                onSearch={() => {
                  let props = {
                    Lio_Date: this.state.initObj.Date,
                    Li_Alone: '',
                  }
                  this.state.initParams.OfficeCode
                    ? this.callbackOpenModal('WS2577003', props)
                    : this.ModalError()
                }}
              />
            </Form.Item>
            <Form.Item className='mt-2' name="TimeZone" label="時間帯">
              <Input.Search
                style={{ width: '150px' }}
                readOnly={!this.state.initParams.OfficeCode}
                onChange={(e) => this.setStateInitParams(e.target.value, 'TimeZone')}
                onSearch={() => {
                  let props = {
                    Li_Date: this.state.initObj.Date,
                    Li_FacilityType: this.state.initParams.FacilityNum,
                    Li_CourseCode: this.state.initParams.Course,
                    // Li_ProcessDivision: '',
                    Lio_TimeZone: this.state.initParams.TimeZone,
                  }
                  this.state.initParams.OfficeCode
                    ? this.callbackOpenModal('WS2553003', props)
                    : this.ModalError()
                }}
                onBlur={(e) => {
                  let props = {
                    Li_Date: this.state.initObj.Date,
                    Li_FacilityType: this.state.initParams.FacilityNum,
                    Li_CourseCode: this.state.initParams.Course,
                    Li_ProcessDivision: '',
                    Lio_TimeZone: this.state.initParams.TimeZone,
                  }
                  if (!this.checkTimeZone(e.target.value) && this.state.initParams.OfficeCode) {
                    this.callbackOpenModal('WS2553003', props)
                  }
                }}
              />
            </Form.Item>
            <Form.Item className='mt-2' name="FacilityNum" label="施　設" >
              <Select
                disabled={!this.state.initParams.OfficeCode}
                style={{ width: '150px' }}
                onChange={(value) => this.setStateInitParams(value, 'FacilityNum')}
              >
                {this.state.FacilityNumList?.map(value => (
                  <Option key={"FacilityNum-" + value.facility_type} value={value.facility_type}>{value.facility_name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className='mt-2' label="コース">
              <Space>
                <Form.Item name="Course">
                  <Input.Search
                    style={{ width: '150px' }}
                    readOnly={!this.state.initParams.OfficeCode}
                    allowClear={true}
                    onChange={(e) => {
                      this.setStateInitParams(e.target.value?.toLocaleUpperCase(), 'Course');
                      this.setState({ initObj: { ...this.state.initObj, contract_short_name: '' } });
                    }}
                    // onKeyDown={(event) => {
                    //   var code = event.keyCode || event.which;
                    //   if (code === 9 && event.target.value) {
                    //     let props = {
                    //       Li_State: 0,
                    //       Li_EffectiveLimitedDisplay: 2,
                    //       Lio_ConsultCourse: this.state.initParams.Course,
                    //       Li_OfficeCode: this.state.initParams.OfficeCode,
                    //       Li_BranchStoreCode: this.state.initParams.BranchStoreCode,
                    //       Li_Date: this.state.initObj.Date || moment().format('YYYY/MM/DD'),
                    //       Li_Gender: this.state.initParams.Gender,
                    //       Li_DateBirth: '',
                    //       Li_Relationship: '',
                    //       Li_HospitalOut: 0,
                    //       Li_Am_Pm: '',
                    //       Li_NTsugikenmi: 0,
                    //       Li_Other: '',
                    //       Lio_ContractType: this.state.initObj.ContractType,
                    //       Lio_ContractOrgCode: this.state.initObj.ContractOrgCode,
                    //       Lio_ContractStartDate: this.state.initObj.ContractDate,
                    //       Lio_ContractNum: this.state.initObj.ContractNum,
                    //       Lo_Status: '',
                    //       Lo_ErrorMessage: '',
                    //     }
                    //     // this.getNameCourseCode(event.target.value, {}, props, 'Course')
                    //     this.state.initParams.OfficeCode
                    //       ? this.callbackOpenModal('WS0289012', props)
                    //       : this.ModalError()
                    //   }
                    // }}
                    onSearch={(value, event) => {
                      if (event.target.localName === "input") {
                        this.formRef.current?.setFieldsValue({
                          Course: '',
                          contract_short_name: ''
                        })
                        this.setState({
                          initParams: {
                            ...this.state.initParams,
                            Course: '',
                          },
                          initObj: {
                            ...this.state.initObj,
                            contract_short_name: ''
                          }
                        })
                      } else {
                        let props = {
                          Li_State: 0,
                          Li_EffectiveLimitedDisplay: 2,
                          Lio_ConsultCourse: this.state.initParams.Course,
                          Li_OfficeCode: this.state.initParams.OfficeCode,
                          Li_BranchStoreCode: this.state.initParams.BranchStoreCode,
                          Li_Date: this.state.initObj.Date || moment().format('YYYY/MM/DD'),
                          Li_Gender: this.state.initParams.Gender,
                          Li_DateBirth: '',
                          Li_Relationship: '',
                          Li_HospitalOut: 0,
                          Li_Am_Pm: '',
                          Li_NTsugikenmi: 0,
                          Li_Other: '',
                          Lio_ContractType: this.state.initObj.ContractType,
                          Lio_ContractOrgCode: this.state.initObj.ContractOrgCode,
                          Lio_ContractStartDate: this.state.initObj.ContractDate,
                          Lio_ContractNum: this.state.initObj.ContractNum,
                          Lo_Status: '',
                          Lo_ErrorMessage: '',
                        }
                        this.state.initParams.OfficeCode
                          ? this.callbackOpenModal('WS0289012', props)
                          : this.ModalError()
                      }
                    }
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={!this.state.initParams.Course}
                    icon={<MoreOutlined />}
                    onClick={() => {
                      if (this.formRef.current.getFieldValue("Course")) {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '80%',
                            component: (
                              <WS0605127_ContractLineItemDisplay
                                Li_ContractType={this.state.initObj?.ContractType}
                                Li_ContractOrgCode={this.state.initObj?.ContractOrgCode}
                                Li_ContractStartDate={this.state.initObj?.ContractDate}
                                Li_ContractNum={this.state.initObj?.ContractNum}
                              />
                            ),
                          },
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name='contract_short_name'>
                  <span>{this.state.initObj?.contract_short_name}</span>
                </Form.Item>
              </Space>
            </Form.Item>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                onClick={() => {
                  this.Extract('追加', true)
                }}
              >
                追　加
              </Button>
            </div>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Form>
      </div>
    )
  }
} export default WS2532500_GroupBookingsAdd
