import { message } from 'antd'
import IntroduceLetterExtractMaintainService from 'services/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtractMaintainService'

const IntroduceLetterExtractMaintainAction = {
  index(data) {
    return IntroduceLetterExtractMaintainService.index(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getIntroductions(data) {
    return IntroduceLetterExtractMaintainService.getIntroductions(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  printBefore(data) {
    return IntroduceLetterExtractMaintainService.printBefore(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  save(data) {
    return IntroduceLetterExtractMaintainService.save(data)
      .then(res => {
        if (res) {
          console.log(res);
          message.success(res.data.message)
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteData(data) {
    return IntroduceLetterExtractMaintainService.deleteData(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  print(data) {
    return IntroduceLetterExtractMaintainService.print(data)
  },

  preview(data) {
    return IntroduceLetterExtractMaintainService.preview(data)
  },

  getPreviousData(data) {
    return IntroduceLetterExtractMaintainService.getPreviousData(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeDepartmentName(data) {
    return IntroduceLetterExtractMaintainService.changeDepartmentName(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getDetailedData(data) {
    return IntroduceLetterExtractMaintainService.getDetailedData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default IntroduceLetterExtractMaintainAction
