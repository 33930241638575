import { message } from 'antd'
import NonConsultDateSettingService from "services/SystemMaintenance/NonConsultDateSetting/NonConsultDateSettingService";

// WS1494003_CollectSetting
export const batchSettingProcessDateNoCorreAction = (params) => {
  return NonConsultDateSettingService.batchSettingProcessDateNoCorreService(params)
    .then((res) => {
      message.success(res?.data.message)
      return res?.data
    })
    .catch((err) => {
      message.error(err.response.data.message)
    })
}

// WS1494006_SingleSetting
export const getDataVariousAggregateAction = (params) => {
  return NonConsultDateSettingService.getDataVariousAggregateService(params)
}

// WS1494006_SingleSetting
export const updateAction = (params) => {
  return NonConsultDateSettingService.update(params)
    .then((res) => {
      message.success(res?.data.message)
      return res?.data
    })
    .catch((err) => {
      message.error(err.response.data.message)
    })
}

// export const tableRewriteAction = (params) => {
//   return NonConsultDateSettingService.tableRewriteService(params)
// }
