import React from "react";
import { connect } from "react-redux";
import  ModalDraggable  from "components/Commons/ModalDraggable";
import { ModalConfirm } from 'components/Commons/ModalConfirm'

import { Card, Form, Modal, Button, message, Checkbox } from "antd";

import SpaceTrimmingAction from "redux/Others/SpaceTrimming/SpaceTrimming.action";
// import CsvInputOutputAction from "redux/Others/CsvInputOutput/CsvInputOutput.action";

const styleFormItem = {
  marginTop: '12px',
  marginLeft: '24px',
}

class SpaceTrimming extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '検査コメント関係';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
      TableNames: [],
      // columns: []
    };
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  getScreenData() {
    SpaceTrimmingAction.getScreenData()
    // CsvInputOutputAction.getScreenData()
      .then((res) => {
        this.setState({
          TableNames: res ? res.TableNames : [],
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoading: false })
        this.closeModal()
      })
  }

  exec(trimFlg) {
    let fields = this.formRef.current?.getFieldsValue();

    let tableNames = [];
    Object.keys(fields).filter((key) => {
      if (fields[key] == true) {
        tableNames.push(key);
      }
    });

    if (tableNames.length == 0) {
      Modal.warning({
        content: 'テーブルを選択してください',
        okText: '確認',
      })
      return
    }

    let params = {
      trimFlg: trimFlg,
      tableNames: tableNames,
    }

    ModalConfirm({
      content: trimFlg ? '選択したテーブルの各カラムに対し、空白がある値を追加します' : '選択したテーブルの各カラムに対し、空白の除去を実行します',
      styleIconModal: { color: '#006CC9' },
      onOk: () => {
        SpaceTrimmingAction.exec(params)
          .then((res) => {
            if(res.data.message){
              Modal.info({
                content: res.data.message,
              })
            } else {
              reject(new Error('エラーが発生しました'));
            }
          })
          .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      },
      onCancel: () => {
        Modal.info({
          content: 'キャンセルしました',
        })
      },
    })
  }

  render() {
    return (
      <div className="space-trimming">
        <Card className="mb-2" title="検査コメント関係">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            {this.state.TableNames?.map(value => (
              <Form.Item
                name={value}
                key={value}
                valuePropName="checked"
                style={{marginLeft: '24px'}}
              >
                <Checkbox>{value}</Checkbox>
              </Form.Item>
            ))}
            <Form.Item
              style={styleFormItem}
            >
              <Button
                type='primary'
                onClick={() => {
                  this.exec(0)
                }} >
                <span className='btn_label'>
                  空白除去
                </span>
              </Button>
            </Form.Item>
            <Form.Item
              style={styleFormItem}
            >
              <Button
                type='primary'
                onClick={() => {
                  this.exec(1)
                }} >
                <span className='btn_label'>
                  空白追加
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0}}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceTrimming);
