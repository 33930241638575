import axios from 'configs/axios'
const apiPaths = {
  index: '/api/user-info/user-register',
}
const UserRegisterService = {
  async index() {
    return axios.get(apiPaths.index)
  },
}

export default UserRegisterService
