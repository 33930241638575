import React from "react";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card, Form, Input, Button, Row, Space, Tabs, Col, InputNumber,
  Modal, message, Descriptions
} from "antd";
import {
  ArrowRightOutlined
} from "@ant-design/icons";

import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';
import CodeChangesAction from 'redux/basicInfo/ContractInfoMaintain/CodeChanges.action'
import axios from "axios";

/**
* @extends {React.Component<{Li_ContractType:any, Li_ContractOrgCode:any, Li_ContractStartDate:any, Li_ContractNum:any, Li_Course:any, Li_ContractAbbreviation:any, Li_ContractName:any, onChange:Function}>}
*/
class WS0307082_CodeChanges extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_ContractAbbreviation: PropTypes.any,
    Li_ContractName: PropTypes.any,

    onChange: PropTypes.func,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'コード変更';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      tabNum: "0",
    };

    this.onFinish = this.onFinish.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.Li_ContractType !== this.props.Li_ContractType)
      || (prevProps.Li_ContractOrgCode !== this.props.Li_ContractOrgCode)
      || (prevProps.Li_ContractStartDate !== this.props.Li_ContractStartDate)
      || (prevProps.Li_ContractNum !== this.props.Li_ContractNum)
      || (prevProps.Li_Course !== this.props.Li_Course)
    ) {
      this.formRef.current.resetFields();
    }
  }

  onFinish(values) {
    // Verify
    if (values.contract_number === 0) {
      return message.error('番号に0は指定できません');
    }
    if (!this.props.Li_ContractNum === values.contract_number) {
      let duplicateCheck = this.props.dataSource.find((item) => item.contract_number === Number(values.contract_number))
      if (duplicateCheck) {
        return message.error('番号が重複しています')
      }
    }
    const { Li_ContractType, Li_ContractOrgCode, Li_ContractStartDate, Li_ContractNum } = this.props;
    let params = {
      ...values,
      Li_ContractType: Li_ContractType,
      Li_ContractOrgCode: Li_ContractOrgCode,
      Li_ContractStartDate: Li_ContractStartDate,
      Li_ContractNum: Li_ContractNum
    }
    CodeChangesAction.codeChange(params)
      .then((res) => {
        this.props.onChanged();
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => {

      });
  }

  render() {
    return (
      <div className="code-changes">
        <Card title="コード変更">
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{
            Li_Course: this.props.Li_Course,
            medical_exam_course: this.props.Li_Course,
            Li_ContractNum: this.props.Li_ContractNum,
            contract_number: this.props.Li_ContractNum,
          }}>
            <div className="box_inner_vertical" style={{ width: "100%", marginBottom: '10px' }}>
              <Descriptions
                title=''
                labelStyle={{ width: '120px' }}
                column={1}
                bordered
                size='small'
              >
                <Descriptions.Item
                  label='契約略名称'
                  contentStyle={{ textAlign: 'center' }}>
                  {this.props.Li_ContractAbbreviation}
                </Descriptions.Item>

                <Descriptions.Item
                  label='契約名称'
                  contentStyle={{ textAlign: 'center' }}>
                  {this.props.Li_ContractName}
                </Descriptions.Item>
              </Descriptions>
              <div className="box_inner_horizontal">
                <Form.Item name="contract_number" required={true} label="番　号">
                  <InputNumber className="input-size-number-7" />
                </Form.Item>
              </div>
              <div className="box_inner_horizontal">
                <Form.Item name="medical_exam_course" required={true} label="コース">
                  <Input.Search
                    className="input-search-size-single-byte-4"
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component: (<WS0265001_BasicCourseInquiry
                            onSelect={({ Lo_CourseCode }) => {
                              this.formRef.current.setFieldsValue({
                                medical_exam_course: Lo_CourseCode
                              });

                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: false,
                                }
                              });
                            }}
                          />)
                        }
                      });
                    }} />
                </Form.Item>
              </div>
            </div>
            <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
              <Button type="primary" htmlType="submit">実　行</Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS0307082_CodeChanges;
