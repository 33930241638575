import axios from 'configs/axios'

const API_LIST = {
  index: '/api/examinee-search/examinee-search',
  getScreenDataAPI: '/api/examinee-search/examinee-search/get-screen-data',
  displayExamineeSearchAPI: '/api/examinee-search/examinee-search/display',
  delete: '/api/examinee-search/examinee-search/delete',
  excel: '/api/examinee-search/examinee-search/excel',
}

const ExamineeSearchService = {
  async index(params) {
    return axios.post(API_LIST.index, params)
  },
  async getScreenData() {
    return axios.get(API_LIST.getScreenDataAPI)
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params)
  },
  async excel(params) {
    return axios.post(API_LIST.excel, params, { responseType: 'blob' })
  },
}

export default ExamineeSearchService
