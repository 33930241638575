import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Button,
  Input,
  Checkbox
} from "antd";
import {
  SaveOutlined
} from "@ant-design/icons";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import WS3401002_AutoJudgmentNameSetting from 'pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401002_AutoJudgmentNameSetting.jsx'
import AutoJudgmentManagementAction from 'redux/SM_SystemMaintenance/AutoJudgmentManagement/AutoJudgmentManagement.action.js'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'

const layout = { style: { width: 100 } }

class WS3401004_JudgmentStorageSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '資料一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedJudgmentNameCode: ''
    }
  }

  componentDidMount = () => {
    if (this.props.newFlag === false) {
      this.formRef.current?.setFieldsValue({
        display_number: this.props.record.display_number,
        inspection_code: this.props.record.inspection_code,
        inspection_short_name: this.props.record.inspection_short_name,
        inspection_name: this.props.record.inspection_name,
        invalid_flag: this.props.record.invalid_flag,
        start_date: this.props.record.start_date,
        end_date: this.props.record.end_date
      })
    }
    this.setState({
      selectedJudgmentNameCode: this.props.selectedJudgmentNameCode
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  save = () => {
    if (this.props.newFlag === true) {
      let params = {
        ...this.formRef.current?.getFieldsValue(''),
        judgment_name_code: this.state.selectedJudgmentNameCode
      }
      AutoJudgmentManagementAction.addNewJudgmentStorageSetting(params)
        .then(res => {
          if (res) {
            this.props.onFinishScreen()
          }
        })
    } else {
      let params = {
        ...this.formRef.current?.getFieldsValue(''),
        judgment_name_code: this.state.selectedJudgmentNameCode,
        id: this.props.record.id
      }
      AutoJudgmentManagementAction.saveJudgmentStorageSetting(params)
        .then(res => {
          if (res) {
            this.props.onFinishScreen()
          }
        })
    }
  }

  render() {
    return (
      <div className="judgment-storage-setting" style={{ width: 700 }}>
        <Card title='判定格納先設定'>
          <Form
            ref={this.formRef}
            autoComplete={false}
          >
            <div className="box_container">
              <div className="box_inner_vertical">
                <Form.Item
                  name='display_number'
                  label='No'
                  labelCol={layout}
                >
                  <Input
                    className="input-size-number-4"
                  />
                </Form.Item>
                <Form.Item
                  name='inspection_code'
                  label='検査コード'
                  labelCol={layout}
                >
                  <Input.Search
                    className='input-search-size-number-allow-clear-10'
                    allowClear={true}
                    disabled={this.props.newFlag === false}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.formRef.current?.setFieldsValue({
                          inspection_short_name: '',
                          inspection_name: ''
                        })
                        this.forceUpdate();
                      } else {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                onFinishScreen={(output) => {
                                  this.formRef.current?.setFieldsValue({
                                    inspection_code: output.recordData.test_item_code ?? '',
                                    inspection_short_name: output.recordData.exam_short_name ?? '',
                                    inspection_name: output.recordData.exam_name ?? ''
                                  })
                                  this.forceUpdate()
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='inspection_short_name'
                  label='検査略名'
                  labelCol={layout}
                >
                  <div>{this.formRef.current?.getFieldValue('inspection_short_name')}</div>
                </Form.Item>
                <Form.Item
                  name='inspection_name'
                  label='検査名称'
                  labelCol={layout}
                >
                  <div>{this.formRef.current?.getFieldValue('inspection_name')}</div>
                </Form.Item>
                <Form.Item
                  name='invalid_flag'
                  label='無効'
                  labelCol={layout}
                  valuePropName='checked'
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  name='start_date'
                  label='開始日'
                  labelCol={layout}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM/DD'
                  />
                </Form.Item>
                <Form.Item
                  name='end_date'
                  label='終了日'
                  labelCol={layout}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM/DD'
                  />
                </Form.Item>
              </div>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => this.save()}
                >
                  <span className="btn_label">
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3401004_JudgmentStorageSetting);