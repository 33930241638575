import SpecimenLabelPrintService from "services/AdvancePreparation/SpecimenLabelPrint/SpecimenLabelPrintService";


const SpecimenLabelPrintAction = {
  getScreenData(params) {
    return SpecimenLabelPrintService.getScreenData(params)
  },

  displayBtn(params) {
    return SpecimenLabelPrintService.displayBtn(params)
  },

  print(params) {
    return SpecimenLabelPrintService.print(params)
  },

  preview(params) {
    return SpecimenLabelPrintService.preview(params)
  },

  printNumberLabel(params) {
    return SpecimenLabelPrintService.printNumberLabel(params)
  },

  previewNumberLabel(params) {
    return SpecimenLabelPrintService.previewNumberLabel(params)
  },

  errorCheck(params) {
    return SpecimenLabelPrintService.errorCheck(params)
  },

  getLabelListData(params) {
    return SpecimenLabelPrintService.getLabelListData(params)
  },
}
export default SpecimenLabelPrintAction;
