import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DoubleRightOutlined, ReloadOutlined } from '@ant-design/icons'
import { Card, Form, Input, Button, Descriptions } from 'antd'

const labelStyle = {
  width: '40px',
  padding: '4px 0px'
}
const valueStyle = {
  width: '64px',
  padding: '4px 8px',
  textAlign: 'right',
}
const unitStyle = {
  width: '30px',
  padding: '4px 0px',
}

const rules = [
  {
    pattern: new RegExp(/^[0-9]+$/),
    message: '半角数字のみ'
  },
]

class WS1490002_SpecificDatePeopleNumEdit extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    limitAM: PropTypes.number,
    limitPM: PropTypes.number,
  };

  constructor(props) {
    super(props);

    // document.title = '特定日人数編集';

    this.state = {
      isLoading: false,

      limitTotal: 0,
      ReferenceLimitAM: 0,
      ReferenceLimitPM: 0,
      ReferenceLimitTotal: 0,
    };
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    const data = { ...this.props.selectRecord }

    // 入力部のAMとPM
    this.formRef.current?.setFieldsValue({
      limitAM: data.limitAM ?? 0,
      limitPM: data.limitPM ?? 0,
      limitTotal: data.limitTotal ?? 0,
    })

    // 合計値、基準日の値
    this.setState({
      limitTotal: data.limitTotal ?? 0,
      // 基準日
      ReferenceLimitAM: data.ReferenceLimitAM ?? 0,
      ReferenceLimitPM: data.ReferenceLimitPM ?? 0,
      ReferenceLimitTotal: data.ReferenceLimitTotal ?? 0,
    })
  }

  /**
   * 入力値変更
   * @param {} event
   */
  onValuesChange(event) {

    // 入力値によって合計値を計算、反映
    const amCnt = this.formRef.current?.getFieldValue('limitAM')
    const pmCnt = this.formRef.current?.getFieldValue('limitPM')

    let totalCnt = 0
    if (!isNaN(amCnt)) {
      totalCnt = totalCnt + Number(amCnt)
      // 数値で設定　先頭に0が入力された時の対策
      this.formRef.current?.setFieldsValue({ limitAM: Number(amCnt) })
    }
    if (!isNaN(pmCnt)) {
      totalCnt = totalCnt + Number(pmCnt)
      // 数値で設定　先頭に0が入力された時の対策
      this.formRef.current?.setFieldsValue({ limitPM: Number(pmCnt) })
    }
    // 合計値
    this.setState({ limitTotal: totalCnt })
  }

  /**
   * 変更ボタン押下処理
   * @param {*} event
   */
  onChange = () => {
    // AM
    const amCnt = Number(this.formRef.current?.getFieldValue('limitAM') ?? 0)
    // PM
    const pmCnt = Number(this.formRef.current?.getFieldValue('limitPM') ?? 0)
    const output = {
      id: this.props.selectRecord.id,
      limitAM: amCnt,
      limitPM: pmCnt
    }
    // 変更内容を親画面に返却
    this.props.onFinishScreen(output)
  }

  render() {
    return (
      <div className='specific-date-people-num-edit'>
        <Card
          title='特定日人数編集'
        >
          <div className='box_container'>
            <Form
              ref={this.formRef}
              onValuesChange={(event) => this.onValuesChange(event)}
              autoComplete='off'
            // onFinish={() => this.onFinish()}
            // onFinishFailed={this.onFinishFailed}
            >
              <div className='box_inner_vertical'>
                <Descriptions
                  title=''
                  column={2}
                  bordered
                  size='small'
                >
                  <Descriptions.Item
                    label='表示名称'
                    contentStyle={{ textAlign: 'center' }}>
                    {this.props.displayName}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label='日付'
                    contentStyle={{ textAlign: 'center' }}>
                    {this.props.dateChar}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label='施設'
                    contentStyle={{ textAlign: 'center' }}>
                    {this.props.facilityTypeName}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label='タイプ'
                    contentStyle={{ textAlign: 'center' }}>
                    {this.props.manageDivisionName}
                  </Descriptions.Item>
                </Descriptions>

                <div className='box_inner_horizontal' style={{ padding: '10px' }}>
                  <div className='box_inner_vertical specific-box' style={{ alignItems: 'center' }}>
                    <span className='specific-title'>
                      現在の人数枠設定
                    </span>
                    <div className='box_inner_horizontal' style={{ justifyContent: 'center' }}>
                      <div style={labelStyle}>
                        AM
                      </div>
                      <div style={valueStyle}>
                        {this.state.ReferenceLimitAM}
                      </div>
                      <div style={unitStyle}>人</div>
                    </div>
                    <div className='box_inner_horizontal' style={{ justifyContent: 'center' }}>
                      <div style={labelStyle}>
                        PM
                      </div>
                      <div style={valueStyle}>
                        {this.state.ReferenceLimitPM}
                      </div>
                      <div style={unitStyle}>人</div>
                    </div>
                    <div className='box_inner_horizontal' style={{ justifyContent: 'center' }}>
                      <div style={labelStyle}>
                        合計
                      </div>
                      <div style={valueStyle}>
                        {this.state.ReferenceLimitTotal}
                      </div>
                      <div style={unitStyle}>人</div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {<DoubleRightOutlined />}
                  </div>

                  <div className='box_inner_vertical specific-box' style={{ alignItems: 'center' }}>
                    <span className='specific-title'>
                      新しい人数枠設定
                    </span>
                    <div className='box_inner_horizontal' style={{ justifyContent: 'center' }}>
                      <div style={labelStyle}>
                        AM
                      </div>
                      <Form.Item
                        name='limitAM'
                        rules={rules}
                        style={{ margin: 0 }}
                      >
                        <Input className='input-size-number-4' type='tel' />
                      </Form.Item>
                      <div style={unitStyle}>人</div>
                    </div>

                    <div className='box_inner_horizontal' style={{ justifyContent: 'center' }}>
                      <div style={labelStyle}>
                        PM
                      </div>
                      <Form.Item
                        name='limitPM'
                        rules={rules}
                        style={{ margin: 0 }}
                      >
                        <Input className='input-size-number-4' type='tel' />
                      </Form.Item>
                      <div style={unitStyle}>人</div>
                    </div>

                    <div className='box_inner_horizontal' style={{ justifyContent: 'center' }}>
                      <div style={labelStyle}>
                        合計
                      </div>
                      <div style={valueStyle}>
                        {this.state.limitTotal}
                      </div>
                      <div style={unitStyle}>人</div>
                    </div>
                  </div>
                </div>

              </div>

              <div className='box_button_bottom_right'>
                <Button
                  onClick={() => {
                    if (this.props.onFinishScreen) {
                      // nullで返す
                      this.props.onFinishScreen(null)
                    }
                  }}
                >
                  <span className='btn_label'>
                    キャンセル
                  </span>
                </Button>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type='primary'
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      this.onChange()
                    }}
                  >
                    <span className='btn_label'>
                      変更
                    </span>
                  </Button>
                </Form.Item>
              </div>

            </Form>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1490002_SpecificDatePeopleNumEdit);
