import axios from 'configs/axios'

const API_LIST = {
  index: '/api/disease-name-master-maintain/disease-name-master-maintain/index',
  save: '/api/disease-name-master-maintain/disease-name-master-maintain/save',
  delete: '/api/disease-name-master-maintain/disease-name-master-maintain/delete',
}

const DiseaseNameMasterMaintainService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  }
}

export default DiseaseNameMasterMaintainService
