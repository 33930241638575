import ContractInsurerInfoInquirySubService from 'services/basicInfo/ContractInfoMaintain/ContractInsurerInfoInquirySubService';
import { message, } from "antd"

const ContractInsurerInfoInquirySubAction = {
  getInsuranceList(params) {
    return ContractInsurerInfoInquirySubService.getInsuranceList(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default ContractInsurerInfoInquirySubAction;