import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/specific-coercive-finger-xml-output/result-confirm/get-screen-data",
  xml: "/api/specific-coercive-finger-xml-output/result-confirm/event-xml",
  downloadXml: "/api/specific-coercive-finger-xml-output/result-confirm/download-xml",
  LogDisplay: "/api/specific-coercive-finger-xml-output/result-confirm/event-log-display",
  downloadLog: "/api/specific-coercive-finger-xml-output/result-confirm/download-log",
};
const ResultConfirmService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
  async xml(params) {
    return axios.post(apiPaths.xml, params);
  },
  async downloadXml(params) {
    return axios.post(apiPaths.downloadXml, params, { responseType: 'arraybuffer' });
  },
  async LogDisplay(params) {
    return axios.post(apiPaths.LogDisplay, params);
  },
  async downloadLog(params) {
    return axios.post(apiPaths.downloadLog, params, { responseType: 'blob' });
  },
};

export default ResultConfirmService;