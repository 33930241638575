import axios from 'configs/axios'

const API_LIST = {
  index: '/api/sanai-reserve-status-search/custom-inspection-display/',
}

const MemoFunctionService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}
export default MemoFunctionService