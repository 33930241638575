import axios from 'configs/axios'

const apiPaths = {
  index: '/api/personal-info-maintain-directly/personal-attributes-reacquire',
  reacquisition: '/api/personal-info-maintain-directly/personal-attributes-reacquire/reacquisition',
}

const PersonalAttributesReacquireService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async reacquisition(params) {
    return axios.get(apiPaths.reacquisition, { params })
  },
}

export default PersonalAttributesReacquireService
