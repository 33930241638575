import React from "react";
import { connect } from "react-redux";
import { Card, Input, Form, Modal, Button, InputNumber } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";


// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

class WS1872006_EditDataSite2 extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '読影検査保守 部位2';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  // 保存ボタンが押された場合
  save = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      id: this.props.record.id ?? '',
    }
    this.props.onSave(values)
  }

  // 削除が押された場合
  delete = () => {
    let values = this.formRef.current?.getFieldsValue()
    values = {
      ...values,
      id: this.props.record.id ?? '',
    }
    this.props.onDelete(values)
  }

  render() {
    return (
      <div className="EditDataSite2">
        <Card
          title={'部位2 [' + (this.props.newFlag ? '新規' : '変更') + ']'}
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='コード'
                name="site_code"
                {...labelCol}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label='部位2'
                name="site_name"
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='SEQ'
                name="display_order"
                {...labelCol}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label='所見分類2'
                name="findings_classification_i"
                {...labelCol}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1872006_EditDataSite2);