import React from "react";
import { connect } from "react-redux";

import { Card } from "antd";

class WS1351001_SpecificHealthDataXmlOutput extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '該当者一覧';

    this.state = {};
  }

  render() {
    return (
      <div className="specific-health-data-xml-output">
        <Card></Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1351001_SpecificHealthDataXmlOutput);