import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row,Form,Col,Input, Button} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";


class WS1385001_SentenceMasterInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00150:文章マスタ照会';

    this.state = {
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    const {SentenceCode, DocumentClassify} = this.props;
    VenusApiRoutesV2.callApi("API001385001002", {SentenceCode, DocumentClassify}).then(res => this.setState({dataSource: res}));
  }

  select(record) {
    if(this.props.onFinishScreen) {
      this.props.onFinishScreen(record);
    }
  }

  render() {
    return (
      <div className="sentence-master-inquiry">
        <Card className="mb-2" title="V4-TSB00150:文章マスタ照会">
          <Row className='mt-1 mb-3'>
            <Col span={24}>
              <span className="p-2">文章分類</span>
              <Input readOnly value={this.state.dataSource[0]?.document_classification_name} style={{width: 200}} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table bordered
                dataSource={this.state.dataSource}
                pagination={this.state.pagination}
                rowKey={(record) => record.id}
              >
                <Table.Column title="コード" width={250}
                  dataIndex="sentence_code"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.sentence_code.localeCompare(b.sentence_code)}
                  />
                <Table.Column title="検索文字" width={150}
                  dataIndex="search_character"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.search_character.localeCompare(b.search_character)}
                />
                <Table.Column title="内　　容" dataIndex="content"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.content.localeCompare(b.content)}
                />
                <Table.Column title="" width={80}
                  render={(item, record, index) => {
                    return (
                      <Button type="primary" onClick={() => this.select(record)}>選択</Button>)
                  }}
                />
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1385001_SentenceMasterInquiry);
