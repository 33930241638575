import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Checkbox, Row, Col, message } from 'antd'
import ErrorListCreateService from 'services/AssociationHealthInsuranceReport/AssociateInsureDataCreate/ErrorListCreateService'
import { DownloadOutlined } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers'

class WS1227001_AssociateInsureErrorListCreatedSub extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '協会けんぽエラーリスト作成SUB'

    this.state = {
      initParams: {
        Li_TargetPeopleNum: props.Li_TargetPeopleNum,
        Li_ErrorPeopleNum: props.Li_ErrorPeopleNum
      },
      check: false

    }
  }

  onFinish(values) {
  }

  /**
   * エラー出力　ダウンロード　
   */
  download() {
    ErrorListCreateService.download()
      .then((res) => {
        download_file(res)
      })
      .catch((error) => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='associate-insure-error-list-created-sub'>
        <Card title='協会けんぽエラーリスト'>
          <div className='box_container'>
            <div style={{
              height: 40,
              display: 'flex',
              alignItems: 'center',
              background: 'red',
              color: 'white',
              padding: 10
            }}>
              出力内容にエラーがありました
            </div>
            <div style={{ marginLeft: 10 }}>
              <div>変換を出来ない検査がありました</div>
              <div>エラー出力をして、検査内容を確認してください</div>
            </div>
            <Row gutter={24}>
              <Col span={8} style={{ textAlign: 'right' }}>
                <label>受診/利用者総数</label>
              </Col>
              <Col span={4}>
                <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {this.props.Li_TargetPeopleNum}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8} style={{ textAlign: 'right' }}>
                <label>エラー人数</label>
              </Col>
              <Col span={4}>
                <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {this.props.Li_ErrorPeopleNum}
                </div>
              </Col>
            </Row>
          </div>
          <div className='box_border_bottom' style={{ paddingRight: 0 }}>
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              onClick={() => this.download()}
            >
              <span className='btn_label'>
                エラー出力
              </span>
            </Button>
          </div>
          <div className='box_button_bottom_right' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Checkbox
              checked={this.state.check}
              onChange={(event) => {
                this.setState({ check: event.target.checked })
              }}
            >
              エラーを確認しました
            </Checkbox>
            <Button
              type='primary'
              disabled={!this.state.check}
              onClick={() => {
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen()
                }
              }}
            >
              O K
            </Button>
          </div>

        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1227001_AssociateInsureErrorListCreatedSub)
