import axios from "configs/axios";

const apiPaths = {
  getTreeData: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting",

  getFacilityInfo: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/facility-info",
  getScreenDataFacilityInfo: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/facility-info/get-screen-data",
  updateFacilityInfo: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/facility-info/update",

  getDateList: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list",
  addNewDateFacility: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list/new-facility-info",
  updateDateFacility: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list/modify-facility-info",
  deleteDateFacility: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list/delete-facility-info",
  addNewDateConsumptionTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list/new-consumption-tax",
  updateDateConsumptionTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list/modify-consumption-tax",
  deleteDateConsumptionTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/date-list/delete-consumption-tax",

  getConsumptionTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/consumption-tax",
  updateConsumptionTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/consumption-tax/update",
  getApplyDate: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/get-apply-date",
  getFacility: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/get-facility",
  postFacility: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/post-facility",
  deleteFacilityInfo: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/delete-facility-info",
  getTaxApplyDate: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/get-tax-apply-date",
  getTaxSetting: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/get-tax-setting",
  getDateReload: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/get-date-reload",
  postAddApplyDate: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/post-add-apply-date",
  deleteTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/delete-tax",
  postTax: "/api/facility-consumption-tax-setting/facility-consumption-tax-setting/post-tax",
};

const FacilityConsumptionTaxSettingService = {
  async getTreeData() {
    return axios.get(apiPaths.getTreeData);
  },

  async getFacilityInfo(params) {
    return axios.get(apiPaths.getFacilityInfo, { params });
  },

  async getScreenDataFacilityInfo() {
    return axios.get(apiPaths.getScreenDataFacilityInfo);
  },

  async updateFacilityInfo(params) {
    return axios.put(apiPaths.updateFacilityInfo, params);
  },

  async getDateList(params) {
    return axios.get(apiPaths.getDateList, { params });
  },

  async addNewDateFacility(params) {
    return axios.post(apiPaths.addNewDateFacility, params);
  },

  async updateDateFacility(params) {
    return axios.put(apiPaths.updateDateFacility, params);
  },

  async deleteDateFacility(params) {
    return axios.delete(apiPaths.deleteDateFacility, { params });
  },

  async addNewDateConsumptionTax(params) {
    return axios.post(apiPaths.addNewDateConsumptionTax, params);
  },

  async updateDateConsumptionTax(params) {
    return axios.put(apiPaths.updateDateConsumptionTax, params);
  },

  async deleteDateConsumptionTax(params) {
    return axios.delete(apiPaths.deleteDateConsumptionTax, { params });
  },

  async getConsumptionTax(params) {
    return axios.get(apiPaths.getConsumptionTax, { params });
  },

  async updateConsumptionTax(params) {
    return axios.put(apiPaths.updateConsumptionTax, params);
  },

  async getApplyDate() {
    return axios.get(apiPaths.getApplyDate);
  },

  async getFacility(params) {
    return axios.get(apiPaths.getFacility, { params });
  },

  async postFacility(params) {
    return axios.post(apiPaths.postFacility, params);
  },

  async deleteFacilityInfo(params) {
    return axios.delete(apiPaths.deleteFacilityInfo, { params });
  },

  async getTaxApplyDate(params) {
    return axios.get(apiPaths.getTaxApplyDate, { params });
  },

  async getTaxSetting(params) {
    return axios.get(apiPaths.getTaxSetting, { params });
  },

  async getDateReload(params) {
    return axios.get(apiPaths.getDateReload, { params });
  },

  async postAddApplyDate(params) {
    return axios.post(apiPaths.postAddApplyDate, params);
  },

  async deleteTax(params) {
    return axios.delete(apiPaths.deleteTax, { params });
  },

  async postTax(params) {
    return axios.post(apiPaths.postTax, params);
  },
};

export default FacilityConsumptionTaxSettingService;
