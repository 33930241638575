import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Button, Table, Input, Select, message, Tooltip, Dropdown, Menu } from 'antd'
import { PlusOutlined, DeleteOutlined, MenuOutlined, SaveOutlined } from '@ant-design/icons'
import ModalDraggable from "components/Commons/ModalDraggable"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import MunicipalMutualAidAssociationMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MunicipalMutualAidAssociationMaintain.actions.js'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'
import WS0178001_QuerySiteInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0178001_QuerySiteInfo'
import WS0179001_InquiryFindingInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0179001_InquiryFindingInfo'
import WS1789002_MutualAidMaster from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1789002_MutualAidMaster'
import WS1789003_MutualAidMasterReadOnly from 'pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1789003_MutualAidMasterReadOnly'

class WS1789001_MunicipalMutualAidAssociationMaintain extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Lio_DisplayItems: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '市町村職員共済組合部位所見変換保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      addCount: 0,
      disabledSaveBtn: true,
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    MunicipalMutualAidAssociationMaintainAction.index()
      .then((res) => {
        this.setState({
          dataSource: res,
          addCount: 0,
        })
        this.formRef.current?.setFieldsValue({
          tableData: res
        })
      })
  }

  /**
   * 区分のselectが変更された場合
   */
  onChangeSelect = (e, record) => {
    this.updateDatasource(record.id, 'site_findings_common', e)
  }

  /**
   * 検査項目検索・照会
   * @param {*} value
   */
  InspectItemSearchQuerySingle = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={record.test_item_code}
            onFinishScreen={(output) => {
              let params = {
                ...output.recordData
              }

              MunicipalMutualAidAssociationMaintainAction.getSiteClassification(params)
                .then(async (res) => {
                  await this.updateDatasource(record.id, 'classification', res)
                  await this.updateDatasource(record.id, 'test_item_code', output.recordData.internal_exam_code)
                  await this.updateDatasource(record.id, 'exam_name', output.recordData.exam_name)
                  await this.updateDatasource(record.id, 'exam_type', output.recordData.exam_type)
                })

              this.closeModal();
            }}
          />
        ),
      }
    })
  }

  /**
   * 部位照会
   * @param {*} value
   */
  QuerySiteInfo = (record) => {
    {
      record.site_findings_common == null ?
        message.error('区分を設定してください')
        :
        record.site_findings_common == 0 ?
          message.error('区分が所見になっています')
          :
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: '60%',
              component: (
                <WS0178001_QuerySiteInfo
                  Li_SiteClasify={record.classification}
                  onFinishScreen={async (output) => {

                    await this.updateDatasource(record.id, 'site_code', output.recordData.site_code)
                    await this.updateDatasource(record.id, 'site_name', output.recordData.site_name)

                    this.closeModal();
                  }}
                />
              ),
            }
          })
    }
  }

  /**
   * 所見照会
   * @param {*} value
   */
  InquiryFindingInfo = (record) => {
    record.site_findings_common == null ?
      message.error('区分を設定してください')
      :
      record.site_findings_common == 1 ?
        message.error('区分が部位になっています')
        :
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '60%',
            component: (
              <WS0179001_InquiryFindingInfo
                Li_FindingsClassify={record.classification}
                onFinishScreen={async (output) => {

                  await this.updateDatasource(record.id, 'findings_code', output.recordData.findings_code)
                  await this.updateDatasource(record.id, 'findings_name', output.recordData.findings_name)

                  this.closeModal();
                }}
              />
            ),
          }
        })
  }

  /**
   * 新規追加、行追加
   */
  add = async () => {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      selectedRow: { ...newRow },
      addCount: idCount,
    })
    this.formRef.current?.setFieldsValue({
      tableData: data
    })
    this.forceUpdate()
  }

  /**
   *  削除
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   */
  delete = (id) => {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: data
    })
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * それぞれの値の変更
   * @param {*} id
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(id, field, value) {

    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: tempArr
    })
  }

  /**
   * 保存ボタンが押された場合の処理
   */
  saveData() {
    let params = {
      data: this.state.dataSource
    }

    MunicipalMutualAidAssociationMaintainAction.saveData(params)
      .then((res) => {
        if (res) {
          this.getScreenData()
        }
      })
  }

  /**
   * 共済マスタ
   */
  MutualAidMaster() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1789002_MutualAidMaster
          />
        ),
      },
    })
  }

  /**
   * サーチが押された時に表示する共済マスタ
   */
  MutualAidMasterReadOnly(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '50%',
        component: (
          <WS1789003_MutualAidMasterReadOnly
            siteFindingsCommon={record.site_findings_common}
            onFinishScreen={async (output) => {

              await this.updateDatasource(record.id, 'findings_item_code', output.findings_item_code)
              await this.updateDatasource(record.id, 'mutual_aid_code', output.mutual_aid_code)
              await this.updateDatasource(record.id, 'content', output.content)

              this.closeModal();
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className='municipal-mutual-aid-association-maintain'>
        <Card title='市町村職員共済組合部位所見変換保守'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => { this.MutualAidMaster() }}>
                      共済マスタ
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>


          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(250) }}
            >
              <Table.Column
                title='検査コード'
                dataIndex='test_item_code'
                className='column-size-20'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'test_item_code']} >
                    <Input.Search
                      style={{ width: '100%', textAlign: 'right' }}
                      onSearch={() => {
                        this.InspectItemSearchQuerySingle(record)
                      }}
                    />
                  </Form.Item>
                )}
              />

              < Table.Column
                title='検査名称'
                dataIndex='exam_name'
              />

              <Table.Column
                title='タイプ'
                dataIndex='exam_type'
                className='column-size-5'
                render={(value) => (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )}
              />

              <Table.Column
                title='区分'
                dataIndex='site_findings_common'
                className='column-size-10'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'site_findings_common']} >
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => this.onChangeSelect(e, record)}
                    >
                      <Select.Option value={null}>{''}</Select.Option>
                      <Select.Option value={0}>所見</Select.Option>
                      <Select.Option value={1}>部位</Select.Option>
                      <Select.Option value={2}>共通</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              />

              <Table.Column
                title='部位'
                dataIndex='site_code'
                className='column-size-10'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'site_code']} >
                    <Input.Search
                      style={{ width: '100%', textAlign: 'right' }}
                      onSearch={() => {
                        this.QuerySiteInfo(record)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='部位名称'
                dataIndex='site_name'
              />

              <Table.Column
                title='所見'
                dataIndex='findings_code'
                className='column-size-10'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'findings_code']} >
                    <Input.Search
                      style={{ width: '100%', textAlign: 'right' }}
                      onSearch={() => {
                        this.InquiryFindingInfo(record)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='所見名称'
                dataIndex='findings_name'
              />

              <Table.Column
                title='所見項目'
                dataIndex='findings_item_code'
                className='column-size-20'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'findings_item_code']} >
                    <Input.Search
                      style={{ width: '100%', textAlign: 'right' }}
                      onSearch={() => {
                        this.MutualAidMasterReadOnly(record)
                      }}
                      onBlur={(e) => {
                        this.updateDatasource(record.id, 'findings_item_code', e.target.value)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='共済コード'
                dataIndex='mutual_aid_code'
                className='column-size-20'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'mutual_aid_code']} >
                    <Input.Search
                      style={{ width: '100%', textAlign: 'right' }}
                      onSearch={() => {
                        this.MutualAidMasterReadOnly(record)
                      }}
                      onBlur={(e) => {
                        this.updateDatasource(record.id, 'mutual_aid_code', e.target.value)
                      }}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title='共済内容'
                dataIndex='content'
              />

              <Table.Column
                width={40}
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      onClick={() => this.add()}
                      type='primary'
                      icon={<PlusOutlined />}
                    />
                  </div>
                }
                render={(text, record, index) => {
                  return (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.delete(record.id)
                      }}
                    />
                  )
                }}
              />
            </Table>

            <Form.Item >
              <Button
                type='primary'
                icon={<SaveOutlined />}
                style={{ float: 'right', marginTop: '1em' }}
                disabled={this.state.disabledSaveBtn}
                onClick={() => {
                  this.saveData()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1789001_MunicipalMutualAidAssociationMaintain)
