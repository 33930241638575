import axios from "configs/axios";
const API_LIST = {
  GetScreenData: "/api/billing-integration/invoice/get-screen-data",
  print: "/api/billing-integration/invoice/print",
  preview: "/api/billing-integration/invoice/preview",
};

const InvoiceService = {
  async GetScreenData(params) {
    return axios.get(API_LIST.GetScreenData, { params });
  },
  async print(params) {
    return axios.post(API_LIST.print, params);
  },
  async preview(params) {
    return axios.post(API_LIST.preview, params, { responseType: "blob" });
  },
};
export default InvoiceService;
