import axios from 'configs/axios';

const apiPaths = {
  getScreenData: '/api/label-print/address-label-insurer/get-screen-data',
  displayBtn: '/api/label-print/address-label-insurer/display-btn',
  print: '/api/label-print/address-label-insurer/print',
  preview: '/api/label-print/address-label-insurer/preview',
  errorCheck: '/api/label-print/address-label-insurer/error-check'
};

const AddressLabelPrintService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },

  async displayBtn(params) {
    return axios.get(apiPaths.displayBtn, { params });
  },

  async print(params) {
    return axios.post(apiPaths.print, params);
  },

  async preview(params) {
    return axios.post(apiPaths.preview, params, { responseType: 'blob' });
  },

  async errorCheck(params) {
    return axios.get(apiPaths.errorCheck, { params });
  },

};

export default AddressLabelPrintService;
