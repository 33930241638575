import { message } from 'antd'
import FormatCopyService from 'services/InspectionMaintenance/NormalValueSettingMaintain/FormatCopyService'

const FormatCopyAction = {
  CopyExec(params) {
    console.log("actionの作成")
    console.log(params)
    ////確かめ方
    ////console.log(FormatCopyService.index())
    // return FormatCopyService.index()
    return FormatCopyService.CopyExec(params)
      .then((res) => {
        console.log("actionの中")
        console.log(res)
        return res?.data
      })
      .catch((err) => {
        console.log(err)
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  // deleteRadioInfo(data) {
  //   console.log("アクションdel")
  //   return RadiographyJudgeMaintainService.deleteRadioInfo(data)
  //     .then((res) => {
  //       console.log(res)
  //       console.log("12345")
  //       return res?.data
  //     })
  //     .catch((err) => {
  //       const res = err.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // },

  // saveRadioInfo(data) {
  //   console.log("アクションsave")
  //   return RadiographyJudgeMaintainService.saveRadioInfo(data)
  //     .then((res) => {
  //       console.log(res)
  //       console.log("あああああ")
  //       return res?.data
  //     })
  //     .catch((err) => {
  //       const res = err.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // },
}
export default FormatCopyAction