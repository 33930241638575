import React from "react";
import { Button, Space, Dropdown, Menu } from "antd";
import './Menubar.scss';

const styleButton = {
  color: '#000000',
  border: 'solid 1px #d9d9d9',
}

class Menubar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <Space>
        <Button type='text' style={styleButton} hidden={true}>編集</Button>
        {this.props.isHideButton ? '' : (<Dropdown
          trigger='click'
          overlay={() => (
            <Menu
              mode={'vertical'}
            >
              <Menu.Item key={'MenuItem_option'} style={styleButton} onClick={this.props?.OptionsDisplay}>オプション情報</Menu.Item>
              {this.props.isHideUser ? '' : (
                <Menu>
                  <Menu.Item key={'MenuItem_user'} style={styleButton}>ユーザー定義</Menu.Item>
                </Menu>
              )}
              {this.props.menus?.map(menu => (
                <Menu.Item key={'MenuItem_' + menu.id} style={styleButton} onClick={menu.handleClick}>{menu.label}</Menu.Item>
              ))}
            </Menu>
          )}
        >
          <Button type='text' style={styleButton}>設定</Button>
        </Dropdown>)}
        {this.props.isHideButton ? '' : (<Button type='text' style={styleButton}>ﾍﾙﾌﾟ</Button>)}
        {this.props.isHideButton ? '' : (<Button type='text' style={{ padding: 0 }} hidden={!(this.props.items?.length > 0)}>|</Button>)}
        {this.props.items?.map(item => (
          <Button className="menu-bar-button" key={'Button_' + item.id} disabled={item.disabled || false} type='text' onClick={item.handleClick}>{item.label}</Button>
        ))}
      </Space>
    )
  }
}

export default Menubar;
