import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Space, Divider, Row, Col } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import TargetivesResultInputAction from "redux/SpecificInsureGuide/InsureGuideInitInput/TargetivesResultInput.action"
import WS1387001_CmtClassifyInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1387001_CmtClassifyInquiry.jsx";

class WS2654016_TargetivesResultInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '目標・結果入力';

    this.state = {
      data: {
        Result: "",
        Goal: "",
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      ResultDisabled: false,
      GoalDisabled: false,
      CommentDivision: null
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    if (this.props.result === '') {
      this.setState({
        ResultDisabled: true
      })
    }

    if (this.props.goal === '') {
      this.setState({
        GoalDisabled: true
      })
    }

    this.formRef.current?.setFieldsValue({
      Result: this.props.healthguideresultdata.result,
      Goal: this.props.healthguideresultdata.goal,
      screenContent: this.props.Comment
    })

    // this.setState({
    //   data: {
    //     Result: this.props.healthguideresultdata.result,
    //     Goal: this.props.healthguideresultdata.goal,
    //     CommentDivision: this.props.inspectionitemmaster.exam_comment_division
    //   }
    // })
  }

  confirm() {
    const { Li_InspectCode, onFinishScreen } = this.props;
    let Result = this.formRef.current?.getFieldValue("Result")
    let Goal = this.formRef.current?.getFieldValue("Goal")
    let params = {
      id: this.props.healthguideresultdata.id,
      exam_code: this.props.healthguideresultdata.exam_code,
      Result: Result,
      Goal: Goal,
    }

    TargetivesResultInputAction.confirm(params)
      .then(res => {
        if (onFinishScreen) {
          onFinishScreen();
        }
      });
  }

  show_WS1387001() {
    if (!this.props.inspectionitemmaster.exam_comment_division == 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 400,
          component: (
            <WS1387001_CmtClassifyInquiry
              commentDivision={this.props.inspectionitemmaster.exam_comment_division}
              onFinishScreen={(record) => {
                this.formRef.current?.setFieldsValue({
                  Result: record.exam_comment_code,
                  screenContent: record.screen_content
                })

                this.setState({
                  data: { Result: record.exam_comment_code }
                })

                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="targetives-result-input">
        <Card className="mb-2" title="指導入力[結果入力]">
          <Form
            ref={this.formRef}
            initialValues={{ Division: "1" }}
            autoComplete="off"
          >
            {this.props.inspectionitemmaster.exam_comment_division == 0 ?
              <Form.Item
                name="Result"
                label="実績"
              >
                <Input
                  type="text"
                  disabled={this.state.ResultDisabled}
                  //value={this.state.data.Result}
                  onChange={event => this.setState({ data: { ...this.state.data, Result: event.target.value } })}
                />
              </Form.Item>
              :
              <div className='box_inner_horizontal'>
                <Form.Item
                  name="Result"
                  label="実績"
                >
                  <Input.Search
                    className="input-search-size-number-2"
                    type="text"
                    disabled={this.state.ResultDisabled}
                    //value={this.state.data.Result}
                    onDoubleClick={() => this.show_WS1387001()}
                    onSearch={() => this.show_WS1387001()}
                    onChange={event => this.setState({ data: { ...this.state.data, Result: event.target.value } })}
                  />
                </Form.Item>
                <Form.Item style={{ marginLeft: 10 }}>
                  {this.formRef.current?.getFieldValue('screenContent')}
                </Form.Item>
              </div>
            }


            <Form.Item
              name="Goal"
              label="目標"
            >
              <Input
                type="text"
                disabled={this.state.GoalDisabled}
                //value={this.state.data.Goal}
                onChange={event => this.setState({ data: { ...this.state.data, Goal: event.target.value } })}
              />
            </Form.Item>

            <div className="box_inner_horizontal">
              <div className="box_button_bottom_right">
                <Button
                  type="primary"
                  onClick={() => this.confirm()}
                >
                  確定
                </Button>
              </div>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />

      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2654016_TargetivesResultInput);
