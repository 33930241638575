import { message } from 'antd';
import YearSelect from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/YearSelect'

const YearSelectAction = {
    getScreenData(){
        return YearSelect.getScreenData()
        .then((res)=>{
          
            return res?.data;
        })
        .catch((err)=>{
            const res = err.response;
            if(!res|| !res.data || !res.data.message){
                message.error("エラーが発生しました...");
                return;
            }
            message.error(res.data.message);
        })
    }
}
export default YearSelectAction;