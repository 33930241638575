import { message } from "antd";
import GuideMatterContentService from "services/InputBusiness/NotInputCheckCategory/GuideMatterContentService";

const GuideMatterContentAction = {
  index(data) {
    return GuideMatterContentService.index(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  changeAutomaticJudgeBasicJudge(data) {
    return GuideMatterContentService.changeAutomaticJudgeBasicJudge(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  save(data) {
    return GuideMatterContentService.save(data)
      .then((res) => {
        console.log(res);
        message.success(res?.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  delete(data) {
    return GuideMatterContentService.delete(data)
      .then((res) => {
        console.log(res);
        message.success(res?.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default GuideMatterContentAction;
