import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Form, Card, Button, Table, Modal, message, } from "antd";

import { MoreOutlined, PlusOutlined, QuestionCircleOutlined, } from "@ant-design/icons";
import OfficeInfoMaintainService from "services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js";
import WS0341506_ResultTableEdit from "./WS0341506_ResultTableEdit.jsx";
import PropTypes from "prop-types";

class WS0341503_ResultTable extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      pagination: {
        size: 1,
        showQuickJumper: false
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      ResultsTable: this.props.Li_ResultsTable
    })
    this.setState({
      ResultsTable: this.props.Li_ResultsTable,
      OfficeCode: this.props.Li_OfficeCode,
      BranchStoreCode: this.props.Li_BranchStoreCode
    })
  }

  findIndexByID = (arrayData, recordID) => {
    if (arrayData && arrayData.length > 0) {
      return arrayData.findIndex(item => recordID === item.id);
    }
  };

  saveData = (record, data) => {
    const params = {
      id: data?.id ?? "",
      OfficeCode: this.state.OfficeCode,
      BranchStoreCode: this.state.BranchStoreCode,
      medical_exam_course: record.Li_medical_exam_course,
      standard_printing_style: record.Li_standard_printing_style_1
    };
    OfficeInfoMaintainService.updateAddResultsTableService(params)
      .then(res => {
        message.success("成功");
        this.getAddResultsTable();
      })
      .catch(err => {
        const res = err.response;
        if (!res?.data?.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      });
  };

  getAddResultsTable() {
    const data = {
      OfficeCode: this.state.OfficeCode,
      BranchStoreCode: this.state.BranchStoreCode
    };
    OfficeInfoMaintainService.getAddResultsTableService(data)
      .then(res => {
        this.setState({
          ResultsTable: res.data.ResultsTable,
          selectedRowsResultsTable: [res.data.ResultsTable[0]],
          indexTableResultsTable: 0
        });
        this.formRef.current.setFieldsValue({
          ResultsTable: res.data.ResultsTable
        });
        this.props.onUpdate(res.data.ResultsTable)
      })
      .catch(err => message.error("エラー"));
    this.closeModal()
  }

  deleteRecordTable = (record, arrayName) => {
    if (record) {
      OfficeInfoMaintainService.deleteAddResultsTableService({
        id: record
      })
        .then(res => {
          message.success("成功");
          this.getAddResultsTable();
          this.setState({
            isFileEdited: true
          });
        })
        .catch(err => message.error("エラー"));
    } else {
      let arr = [...this.formRef.current?.getFieldValue([arrayName])];
      arr.map((value, index) => {
        if (value.id === record.id) {
          arr.splice(index, 1);
          this.formRef.current?.setFieldsValue({
            [arrayName]: arr
          });
          this.setState({ isFileEdited: true });
        }
      });
    }
    this.closeModal()
  };

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  render() {
    return (
      <div className="result-table">
        <Card title='結果表'>
          <Form
            ref={this.formRef}
            // onFinish={this.onFinish}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
          >
            <Table
              dataSource={this.state.ResultsTable}
              rowKey={record => record.id}
              // pagination={{
              //   ...this.state.pagination,
              // }}
              size="small"
              bordered
              rowClassName={(record, index) =>
                record.id === this.state.selectedRowsResultsTable[0]?.id
                  ? "table-row-light"
                  : ""
              }
              onRow={(record, index) => ({
                onClick: e => {
                  this.setState({
                    rowSelectResultsTable: record,
                    indexTableResultsTable: index,
                    selectedRowsResultsTable: [record]
                  });
                }
              })}
            >
              <Table.Column
                title="コース"
                dataIndex="medical_exam_course"
                width={100}
              />
              <Table.Column
                title="独自様式"
                dataIndex="standard_printing_style_1"
                render={(text, record, index) => (
                  <div className="box_inner_horizontal">
                    <span>{record.standard_printing_style_1}</span>
                    <span>{record.format_name_1}</span>
                  </div>
                )}
              />
              <Table.Column
                title="標準様式"
                dataIndex="standard_printing_style_2"
                render={(text, record, index) => (
                  <>
                    <span>{text}</span>
                    <span style={{ marginLeft: "5px" }}>{record.format_name_2}</span>
                  </>
                )}
              />
              <Table.Column
                width={40}
                key="action"
                align="center"
                title={() => (
                  <Button
                    type="primary"
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 400,
                          className: '',
                          component: (
                            <WS0341506_ResultTableEdit
                              newFlag={true}
                              onUpdate={(output) => {
                                if (!output.Li_medical_exam_course) {
                                  message.error('コースが設定されていません')
                                  return
                                }
                                Modal.confirm({
                                  content: '新規登録します。よろしいですか？',
                                  okText: 'は　い',
                                  cancelText: 'いいえ',
                                  onOk: () =>
                                    // 保存ボタン押下、変更内容を反映
                                    this.saveData(output)
                                })
                              }}
                            />
                          )
                        },
                      })
                    }}
                  />
                )}

                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        // 編集、削除
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 400,
                            className: '',
                            component: (
                              <WS0341506_ResultTableEdit
                                newFlag={false}
                                record={record}
                                onUpdate={(output) => {
                                  Modal.confirm({
                                    content: '上書き登録します。よろしいですか？',
                                    okText: 'は　い',
                                    cancelText: 'いいえ',
                                    onOk: () =>
                                      // 保存ボタン押下、変更内容を反映
                                      this.saveData(output, record)
                                  })
                                }}
                                onDelete={(output) => {
                                  Modal.confirm({
                                    title: "確認",
                                    icon: <QuestionCircleOutlined style={{ color: "#1890ff" }} />,
                                    content: "削除しますか？",
                                    okText: "削除",
                                    cancelText: "キャンセル",
                                    onOk: () => {
                                      this.deleteRecordTable(output.Li_id, "RecordedInfo");
                                    }
                                  });
                                }}
                              />
                            )
                          },
                        })
                      }}
                    />
                  );
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    )
  }
}
export default WS0341503_ResultTable