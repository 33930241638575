import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment-timezone";
import PrintProcessAction from "redux/SpecificInsureGuide/SpecificCoerciveFingerXmlOutput/PrintProcess.action";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';

import { Card, Table, } from "antd";


const dateFormat = 'YYYY/MM/DD';
const columns = [
  {
    title: '受診日',
    dataIndex: 'W2_date',
    classNumber: '10',
    textAlign: 'center',
  },
  {
    title: 'ＩＤコード',
    dataIndex: 'W2_id',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '実施日',
    dataIndex: 'W2_date_of',
    classNumber: '10',
    textAlign: 'center',
  },
  {
    title: '保険者',
    dataIndex: 'W2_insurer_num',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: 'カナ氏名',
    dataIndex: 'W2_examinee_kana_name',
    classNumber: '40',
    textAlign: '',
  },
  {
    title: '受診券',
    dataIndex: 'W2_consult_docket_num',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '利用券',
    dataIndex: 'W2_vouchers_docket_num',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '単価',
    dataIndex: 'W4_unit_price',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '実施P',
    dataIndex: 'W4_performed_num_points',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '算定額',
    dataIndex: 'W4_calc_price',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '窓口',
    dataIndex: 'W4_window_burden_price',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '請求金額',
    dataIndex: 'W4_billing_amount',
    classNumber: '10',
    textAlign: 'right',
  },
  {
    title: '有効期限',
    dataIndex: 'W2_vouchers_expiration_date',
    classNumber: '10',
    textAlign: 'center',
  },
  {
    title: '保険記号',
    dataIndex: 'W2_insurer_card_symbol',
    classNumber: '60',
    textAlign: '',
  },
  {
    title: '保険番号',
    dataIndex: 'W2_insurer_num_char',
    classNumber: '60',
    textAlign: '',
  },
]
class WS1456003_PrintProcess extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '印刷処理';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      printingProcess: []
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let param = {
      StsConfirm: this.props.StsConfirm
    }
    PrintProcessAction.getScreenData(param)
      .then((res) => {
        this.setState({
          printingProcess: res
        })
      })
  }

  render() {
    return (
      <div className="print-process">
        <Card title="印刷処理">
          <Table
            dataSource={this.state.printingProcess}
            loading={false}
            size='small'
            bordered
            scroll={{ x: 'max-content', y:  resizableTableScroll(70) }}
            pagination={false}
            rowKey={(record) => record.id}
          >
            {columns.map((item, index) => (
              <Table.Column
                key={'input' + index}
                title={item.title}
                dataIndex={item.dataIndex}
                className={'column-size-' + item.classNumber}
                align={item.textAlign ?? ''}
              />
            ))}
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1456003_PrintProcess);
