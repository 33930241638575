import axios from 'configs/axios'

const apiPaths = {
  getScreenData: '/api/invoice/billing-aggregation-process/get-screen-data',
  Extract: '/api/invoice/billing-aggregation-process/extract',
  aggregateSpecify: '/api/invoice/billing-aggregation-process/aggregate-specify',
  create_F12: '/api/invoice/billing-aggregation-process/create-f12',
}

const BillingAggregationProcessService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },

  async Extract(params) {
    return axios.post(apiPaths.Extract, params)
  },

  async aggregateSpecify(params) {
    return axios.post(apiPaths.aggregateSpecify, params)
  },

  async createInvoiceConfirm(params) {
    return axios.post(apiPaths.create_F12, params)
  }
}
export default BillingAggregationProcessService
