import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Form, Input, Radio, Select, Button, Table, Menu, Dropdown, Tooltip, message, Modal } from 'antd'
import { MoreOutlined, SearchOutlined, MenuOutlined, InfoOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import Color from 'constants/Color'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import SalesSituationTableInstructsAction from 'redux/AccountingBusiness/SalesSituationTableInstructs/SalesSituationTableInstructs.action.js'

class WS0983001_SalesSituationTableInstructs extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '売上状況表'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      facilityList: [],
      TextFile: ''
    }
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  getScreenData = () => {
    SalesSituationTableInstructsAction.getScreenData()
      .then(res => {
        this.formRef.current?.setFieldsValue({
          DateFA: res.DateFA,
          DateTA: res.DateTA,
          facility: res.CarCmbbox_List[0].facility_type,
          outputTarget: 1,
          StsCourseMeter: res.StsCourseMeter
        })
        this.setState({
          TextFile: res.TextFile,
          facilityList: res.CarCmbbox_List,
          FormatName: res.FormatName,
          StsContractUnits: res.StsContractUnits
        })
      })
  }

  printConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            onFinishScreen={(output) => {
              const params = {
                TextFile: this.state.TextFile,
                FormatName: this.state.FormatName,
                StsContractUnits: this.state.StsContractUnits,
                facility: this.formRef.current?.getFieldValue('facility'),
                outputTarget: this.formRef.current?.getFieldValue('outputTarget'),
                DateFA: this.formRef.current?.getFieldValue('DateFA'),
                DateTA: this.formRef.current?.getFieldValue('DateTA'),
                PreviewMode: output?.Lo_Preview,
                PrinterNum: output?.Lo_PrinterNo,
                StsConfirm: output?.Lo_StsOutput,
                StsCourseMeter: this.formRef.current?.getFieldValue('StsCourseMeter')
              }
              // 印刷・プレビュー
              this.PrintProcess(params)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  PrintProcess = (params) => {
    if (params.PreviewMode) {
      SalesSituationTableInstructsAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示F
          window.open(fileURL)
        })
        .catch(error => {
          const res = error.response
          if (res) {
            if (res.request.responseType === 'blob') {
              res.data.text()
                .then(str => {
                  let json = JSON.parse(str);
                  message.error(json.message)
                })
            }
            if (error.message.includes('504')) {
              return Modal.error({
                title: 'タイムアウトしました',
                content: '',
              });
            }
          }
        })
    } else {
      // 印刷
      SalesSituationTableInstructsAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            // message.error('処理の途中でエラーが発生しました')
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
        })
        .catch(error => {
          const res = error.response
          message.error(res.message)
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        })
    }
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='sales-situation-table-instructs'>
        <Card title='売上状況表' style={{ width: 'fit-content' }}>
          <div className='box_container'>
            <Form ref={this.formRef}>
              <div className='box_inner_vertical'>
                <div className='box_search'>
                  <div className='box_search_inner'>
                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item
                          label='受診日'
                          name='DateFA'
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                          />
                        </Form.Item>
                        <Form.Item
                          label='～'
                          name='DateTA'
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item
                        label='施　設'
                        name='facility'
                      >
                        <Select
                          style={{ width: 200 }}
                        >
                          {this.state.facilityList.map((value, index) => {
                            return (
                              <Select.Option key={value.id} value={value.facility_type}>{value.facility_name}</Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label='協　会'
                        name='outputTarget'
                      >
                        <Radio.Group
                          defaultValue={1}
                        >
                          <div className='box_inner_vertical'>
                            <Radio value={1}>すべて</Radio>
                            <Radio value={2}>協会</Radio>
                            <Radio value={3}>協会外</Radio>
                          </div>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='box_button_bottom_right'>
                  <Button
                    type='primary'
                    icon={<PrinterOutlined />}
                    onClick={() => { this.printConfirm() }}
                  >
                    <span className='btn_label'>
                      印刷
                    </span>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0983001_SalesSituationTableInstructs)
