import { message } from "antd";
import SpecificHealthDataXMLOutput from "services/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/SpecifiHealthDataXMLOutputService";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
};

const SpecificHealthDataXMLOutputAction = {
  getScreenData(request) {
    return post(SpecificHealthDataXMLOutput.getScreenData, request);
  },
  MemberList(request) {
    return post(SpecificHealthDataXMLOutput.MemberList, request);
  },
  Extract(request) {
    return post(SpecificHealthDataXMLOutput.Extract, request);
  },
  F12_KeyEvent(request) {
    return post(SpecificHealthDataXMLOutput.F12_KeyEvent, request);
  },
  changeGagency(request) {
    return post(SpecificHealthDataXMLOutput.changeGagency, request);
  },
  changeGinsuranceNum(request) {
    return post(SpecificHealthDataXMLOutput.changeGinsuranceNum, request);
  },
  updateCheckbox(request) {
    return post(SpecificHealthDataXMLOutput.updateCheckbox, request);
  },
  selectAll(request) {
    return post(SpecificHealthDataXMLOutput.selectAll, request);
  },
};

export default SpecificHealthDataXMLOutputAction;
