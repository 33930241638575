import axios from "configs/axios";

const API_LIST = {
  targetList: "/api/insure-guide-init-input/insure-guide-init-input/target-list",
  delete: "/api/insure-guide-init-input/insure-guide-init-input/f10Key-target-list",
};

const InsureGuideInitInputService = {
  async getTargetList(params) {
    return axios.get(API_LIST.targetList, { params });
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },
};

export default InsureGuideInitInputService;
