import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Select, Space, Button, message } from 'antd'
import printImagge from 'assets/img/print.png'
import copyImage from 'assets/img/coppy.png'
import PropTypes from 'prop-types'
import PrinterConfirmAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/PrinterConfirm.action'
import excelImage from 'assets/img/excel.png'

const styleImg = {
  marginBottom: '0.5em',
  width: '50px',
  padding: '2px'
}

const buttonStyle = {
  height: 100,
  width: 125,
  // border: '1px solid #14468C',
  borderRadius: 5
}

class WS0433001_PrinterConfirm extends React.Component {
  static propTypes = {
    Li_BlankAdded: PropTypes.number,
    Lo_SelectList: PropTypes.string,
    Li_PreviewSpecifiedValue: PropTypes.any,
    Li_PrinterNoSpecifiedValue: PropTypes.any
  }

  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'プリンター確認'

    this.state = {
      printerNo: 1,
      PrinterNoList: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * プリンタの一覧を取得
   */
  index = () => {
    PrinterConfirmAction.index()
      .then(res => {
        this.setState({
          PrinterNoList: res.PrinterNo_List
        })
        this.setFormValue('PrinterNo', res.PrinterNum ?? 1)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 印刷
   */
  print = () => {
    const data = {
      Lo_Preview: false,
      Lo_PrinterNo: this.formRef.current?.getFieldValue('PrinterNo') ?? 0,
      Lo_StsOutput: true,
      Preview: false
    }
    // 親画面に印刷番号などを渡す
    this.valueToPropComponent(data)
  }

  /**
   * プレビュー
   */
  preview = () => {
    const data = {
      Lo_Preview: true,
      Lo_PrinterNo: this.formRef.current?.getFieldValue('PrinterNo') ?? 0,
      Lo_StsOutput: true,
      Preview: true
    }
    // 親画面に印刷番号などを渡す
    this.valueToPropComponent(data)
  }

  excel = () => {
    const data = {
      Lo_Preview: true,
      Lo_PrinterNo: this.formRef.current?.getFieldValue('PrinterNo') ?? 0,
      Lo_StsOutput: true,
      Preview: true,
      Excel: true
    }

    this.valueToPropComponent(data)
  }

  /**
   * 親画面に印刷番号などを渡す
   * @param {*} data
   */
  valueToPropComponent = (data) => {
    if (this.props.onFinishScreen) {
      // 親画面に渡す
      this.props.onFinishScreen(data)
    }
  }

  setFormValue = (namePath, value) => {
    this.formRef.current.setFields([
      {
        name: namePath,
        value
      }
    ])
  }

  render() {
    return (
      <div className='printer-confirm'>
        <Card title='プリンター確認'>
          <Form
            ref={this.formRef}
          >
            <Form.Item
              name='PrinterNo'
              label='プリンタ－'
            >
              <Select
                style={{ width: 180 }}
              >
                {this.state.PrinterNoList?.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={item.Linked}
                  >
                    {item.Display}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Space style={{ marginTop: 10, float: 'center' }}>
              <Button
                onClick={() => this.print()}
                style={buttonStyle}
              >
                <div
                  style={{ textAlign: 'center', padding: '0.5em' }}
                  key='print'
                >
                  <img src={printImagge} style={styleImg} />
                  <br />
                  印刷
                </div>
              </Button>

              <Button
                onClick={() => this.preview()}
                style={buttonStyle}
              >
                <div
                  style={{ textAlign: 'center', padding: '0.5em' }}
                  key='preview'
                >
                  <img src={copyImage} style={styleImg} />
                  <br />
                  プレビュー
                </div>
              </Button>

              <Button
                onClick={() => this.excel()}
                style={{
                  height: 100,
                  width: 125,
                  borderRadius: 5,

                  //excelOutputがtrueのときのみ表示（現状、結合印刷指示から開くときのみ）
                  display: this.props.excelOutput ? '' : 'none'
                }}
              >
                <div
                  style={{ textAlign: 'center', padding: '0.5em' }}
                  key='excel'
                >
                  <img src={excelImage} style={styleImg} />
                  <br />
                  Excel出力
                </div>
              </Button>
            </Space>
          </Form>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0433001_PrinterConfirm)
