import React from 'react'
import { Card, Table, Button, message } from 'antd'
import { MoreOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons'
import WS0343106_BelongsInput from './WS0343106_BelongsInput'
import ModalDraggable from 'components/Commons/ModalDraggable'

const relationshipList = [
  { value: '0', label: '本人' },
  { value: '1', label: '配偶者' },
  { value: '2', label: '家族' }
]

/**
 * 個人情報保守 - 所属情報選択
 */
class WS0343105_BelongsInfo extends React.Component {
  static propTypes = {
  }

  constructor(props) {
    super(props)

    // document.title = '個人情報保守 - 所属情報選択'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      personalBelongs: [],
      selectedId: null
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      personalBelongs: this.props.personal.personal_belongs ?? [],
      selectedId: this.props.objBelongs.id, // 現在、親画面で表示されている所属のid
    })
  }

  /**
   * 名称系を取得して、設定する
   * @param {*} newbelongs
   */
  setBelongsName = (newbelongs) => {
    let arrNew = [...newbelongs]
    let arrTmp = []
    if (arrNew.length > 0) {
      // 名称系を追加する
      arrTmp = arrNew.map(item => (
        {
          ...item,
          workplace_code: item.personal_workplaces?.workplace_code || '',
          office_kanji_name: item.office?.office_kanji_name || '',
          insurer_kanji_name: item.insurer?.insurer_kanji_name || '',
          short_name: item.personal_workplaces?.office_workplace?.short_name || '',
        }
      ))
    }
    return arrTmp
  }

  /**
   * 選択された所属を親画面に反映
   * @param {*} record
   */
  changeSelectedId = (record) => {
    if (!record.enabled_disabled) {
      message.error('選択した所属情報は無効です')
      return
    }
    // 選択行を変更
    this.setState({
      selectedId: record.id,
    })
    // 親画面に反映
    this.props.onUpdate(record, this.state.personalBelongs, true)
  }


  /**
   * 新規追加、編集 モーダルを開く
   * @param {*} record
   * @param {*} newFlag
   */
  editModal = (record, newFlag) => {
    // モーダル設定
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        className: '',
        component:
          <WS0343106_BelongsInput
            newFlag={newFlag}
            record={record}
            personalNumId={this.props.personal.personal_number_id}
            personalBelongs={this.state.personalBelongs}
            onSave={(arrNewbelongs) => {
              // personal_belongsに反映
              this.setState({
                personalBelongs: arrNewbelongs ?? [],
              })

              // 名称系取得
              const newbelongs = this.setBelongsName(arrNewbelongs)
              let objBelong = newbelongs.find((item) => item.id === this.state.selectedId)

              console.log(arrNewbelongs);
              console.log(objBelong);

              if (objBelong == undefined) {
                objBelong = {}
              }

              // 親画面に反映
              this.props.onUpdate(objBelong, newbelongs, false)
              // モーダルを閉じる
              this.closeModal()
            }}
          />
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  chack(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
    }
  }

  render() {
    return (
      <div className='belongs-info'>
        <Card title='所属情報選択'>
          <Table
            size='small'
            dataSource={this.state.personalBelongs}
            rowKey={record => record.id}
            rowClassName={(record) => record.id === this.state.selectedId ? 'table-row-light' : ''}
            onRow={(record, index) => {
              return {
                onDoubleClick: (event) => {
                  // 選択された所属情報を親画面に反映
                  this.changeSelectedId(record)
                }
              }
            }}
            pagination={false}
            bordered
            scroll={{ x: 'max-content' }}
          >
            <Table.Column
              title='有効'
              dataIndex='enabled_disabled'
              className='column-size-2'
              align='center'
              render={(value, record) => {
                return (
                  this.chack(value)
                )
              }}
            />
            <Table.Column
              title='事業所コード'
              dataIndex='office_code'
              className='column-size-10'
              align='right'
            />
            <Table.Column
              title='事業所名称'
              dataIndex='office_kanji_name'
              className='column-size-40'
            />
            <Table.Column
              title='枝番'
              dataIndex='branch_store_code'
              className='column-size-5'
              align='right'
            />
            <Table.Column
              title='保険者番号'
              dataIndex='insurer_number'
              className='column-size-5'
            />
            <Table.Column
              title='保険者名称'
              dataIndex='insurer_kanji_name'
              className='column-size-40'
            />
            <Table.Column
              title='保険証記号'
              dataIndex='insurer_card_symbol'
              className='column-size-5'
            />
            <Table.Column
              title='保険証番号'
              dataIndex='insurer_card_number'
              className='column-size-5'
              align='right'
              render={(value) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                )
              }}
            />
            <Table.Column
              title='保険証枝番'
              dataIndex='insurer_card_serial_number'
              className='column-size-2'
              align='right'
              render={(value) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                )
              }}
            />
            <Table.Column
              title='続柄'
              dataIndex='relationship'
              className='column-size-5'
              render={(value) => {
                let name = ''
                if (value !== '') {
                  // 続柄名称の取得
                  name = relationshipList.find(item => item.value === String(value))?.label
                }
                return name
              }}
            />
            <Table.Column
              title='所属番号'
              dataIndex='workplace_code'
              className='column-size-10'
            />
            <Table.Column
              title='所属名称'
              dataIndex='short_name'
              className='column-size-10'
            />

            <Table.Column
              fixed='right'
              dataIndex=''
              align='center'
              width={30}
              title={
                <Button
                  type='primary'
                  size='small'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    // 新規追加 モーダル
                    this.editModal(null, true)
                  }}
                />
              }
              render={(value, record, index) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    // 編集 モーダル
                    this.editModal(record, false)
                  }}
                />
              )}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
export default WS0343105_BelongsInfo
