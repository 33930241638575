import axios from 'configs/axios'

const API_LIST = {
  index: '/api/passing-manage-progress/passing-list',
}

const PassingListService = {
  async index() {
    return axios.get(API_LIST.index)
  },
}

export default PassingListService
