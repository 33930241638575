import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, Table, Row, Col, Modal, message } from 'antd'
import { SearchOutlined, ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS0946006_ReceiptIssueOnline from './WS0946006_ReceiptIssueOnline'
import ReceiptPreIssueService from 'services/AccountingBusiness/ReceiptPreIssue20/ReceiptPreIssueService'
import moment from 'moment'
import WS0061012_CheckYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061012_CheckYes'
import WS1512001_OptionalInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512001_OptionalInfoMaintain'
import WS0061003_ConfirmCheckYesYesNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061003_ConfirmCheckYesYesNo'
import WS0061002_ConfirmCheckYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061002_ConfirmCheckYes'
import WS0061009_CheckYesNoYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061009_CheckYesNoYes'
import WS0061015_CheckYesNoNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo'
import ModalDraggable from 'components/Commons/ModalDraggable'

const labelCol = {
  labelCol: { style: { width: 50 } },
}
class WS1915001_ReceiptPreIssue extends React.Component {
  formRef = React.createRef()
  containerRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '領収書事前発行'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      defaultData: {},
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowOnly: [],
      stsEnd: null,
      tableLoading: false,
      Paginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      disabledFlag: true
    }

    this.setFormFieldValue = this.setFormFieldValue.bind(this)
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      tableData: []
    })
    this.getScreenData()
  }

  getScreenData = () => {
    this.setState({
      loading: true,
    })
    ReceiptPreIssueService.getScreenData().then(res => {
      this.formRef.current.setFieldsValue({
        DateFChar: moment(res.data.DateFChar, 'YYYY/MM/DD'),
        OfficeCode: res.data.OfficeCode,
        BranchStoreCode: res.data.BranchStoreCode,
        CourseCode: res.data.CourseCode,
        course_name_formal: res.data.course_name_formal,
        StatusFlag: res.data.StatusFlag,
        Issue: res.data.Issue ? res.data.Issue : '',
      })
      this.setState({
        defaultData: res.data
      })
    }).catch(error => {
      //message.error(error)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }

  checkInputField = (e, fieldName) => {
    this.setState({ loading: true })
    if (e.target.value.length === 0) {
      fieldName.forEach(item => {
        this.setFormFieldValue(item, '')
      })
    }
    this.setState({ loading: false })
  }

  rowSelectedChange = (data) => {
    let arr = []
    let arrTemp = []
    data.forEach(item => {
      if (item.W2_issue) {
        arr.push(item.tableKey)
        arrTemp.push(item)
      }
    })

    this.setState({
      selectedRowKeys: arr,
      selectedRows: arrTemp
    })
  }

  onChangeCheckBox = (index, selected, record) => {
    let parram = {}
    if (index || index === 0) {
      parram.id = index
      parram.W2_issue = selected
      parram.W2_date_receipt = record.W2_date_receipt
      parram.W2_office_name = record.W2_office_name
      parram.W2_person_name = record.W2_person_name
      parram.W2_remark = record.W2_remark
    } else {
      parram.change = selected
    }
    this.setState({
      loading: true,
    })
    ReceiptPreIssueService.changeCheckbox(parram)
      .then(res => {
        this.DisplayList()
      })
      .catch(error => {
        message.error(error)
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }

  onChangeOfficeCode = (e) => {
    if (!!this.formRef.current.getFieldValue('OfficeCode') && this.formRef.current.getFieldValue('OfficeCode').length > 0) {
      this.setState({
        loading: true
      })
      let params = {
        OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
        StsOffice: this.state.defaultData.StsOffice,
        StsOfficeInfo: this.state.defaultData.StsOfficeInfo,
      }
      ReceiptPreIssueService.changeOfficeCode(params)
        .then(res => {
          console.log(res)
          this.setFormFieldValue('OfficeCode', res.data.OfficeCode)
          this.setFormFieldValue('BranchStoreCode', res.data.BranchStoreCode)
          this.setFormFieldValue('office_kanji_name', res.data.office_kanji_name)
        })
        .catch(error => {
          message.error(error)
        }).finally(() => {
          this.setState({
            loading: false
          })
        })
    } else {
      return
    }
  }

  checkMesstoScreen = (mess, data = null) => {
    let screen = mess?.slice(mess.toLowerCase().indexOf('ws'))
    let component = null
    console.log(data, '_______datadatadatadatadata')
    switch (screen) {
      // @desc: List Screen Event F3
      // @data: W2_reserve_num, W2_identify
      case 'WS0061009':
        return component = (
          <WS0061009_CheckYesNoYes
            Li_Title='欠損'
            Li_Message='領収ﾃﾞｰﾀを欠損しますか?'
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                let params = { Li_ReserveNum: data?.W2_reserve_num, Li_Idenfity: data?.W2_identify }
                this.onEventF3After(params)
                this.closeModal()
              }
            }}
          />
        )
      case 'WS0061015':
        return component = (
          <WS0061015_CheckYesNoNo
            Li_Title='欠損'
            Li_Message='領収ﾃﾞｰﾀを欠損しますか?'
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                let params = { Li_ReserveNum: data?.W2_reserve_num, Li_Idenfity: data?.W2_identify }
                this.onEventF3After(params)
                this.closeModal()
              }
            }}
          />
        )
      case 'WS0061012':
        return component = (
          <WS0061012_CheckYes
            Li_Title='欠損'
            Li_Message='領収ﾃﾞｰﾀを欠損しますか?'
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                let params = { Li_ReserveNum: data?.W2_reserve_num, Li_Idenfity: data?.W2_identify }
                this.onEventF3After(params)
                this.closeModal()
              }
            }}
          />
        )
      case 'WS0061002':
        return component = (
          <WS0061002_ConfirmCheckYes
            Li_Title='欠損'
            Li_Message='領収ﾃﾞｰﾀを欠損しますか?'
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                let params = { Li_ReserveNum: data?.W2_reserve_num, Li_Idenfity: data?.W2_identify }
                this.onEventF3After(params)
                this.closeModal()
              }
            }}
          />
        )
      case 'WS0061003':
        return component = (
          <WS0061003_ConfirmCheckYesYesNo
            Li_Title='欠損'
            Li_Message='領収ﾃﾞｰﾀを欠損しますか?'
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                let params = { Li_ReserveNum: data?.W2_reserve_num, Li_Idenfity: data?.W2_identify }
                this.onEventF3After(params)
                this.closeModal()
              }
            }}
          />
        )
      // @desc: Event F12
      case 'WS0946006':
        return component = <WS0946006_ReceiptIssueOnline
          counterReceiptProcess={true}
          receiptManageNum={null}
          receiptDateChar={moment(new Date()).format("YYYY/MM/DD")}
          Li_ReserveNum={null}
          Li_BillingManageNum={null}
          Li_ReceiptDateOn={moment(new Date()).format("YYYY/MM/DD")}
          Li_DestinationName={null}
          Li_Receipts={null}
          Li_TaskType={1}
          receiptManagementNumberList={this.state.selectedRows}
          onFinishScreen={(output) => {
            this.DisplayList()
            this.closeModal()
          }} />
      // @desc: Event Ctrl + O
      // @data: Li_TypeCode, Li_OptionCode, Li_Expansion
      case 'WS1512001':
        return component = (
          <WS1512001_OptionalInfoMaintain
            {...data}
            onFinishScreen={(output) => {
            }}
          />)
      default:
        return component
    }
  }

  onEventF3 = (record) => {
    this.setState({
      loading: true
    })
    if (record.receipt_number >= 0) {
      let component = null
      let error = null
      ReceiptPreIssueService.eventF3()
        .then(res => {
          if (res.data.warning) {
            error = res.data.warning
          } else {
            component = this.checkMesstoScreen(res.data.message, record)
          }
        })
        .catch(error => {
          message.error(error)
        }).finally(() => {
          if (component !== null) {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: '40%',
                component: component
              }
            })
          } else {
            return message.info(error)
          }
        })
    } else {
      Modal.info({
        content: (<p>領収書が未発行です</p>),
        icon: <ExclamationCircleOutlined />,
        okText: 'OK',
      })
    }
    this.setState({
      loading: false,
    })
  }

  onEventF3After = (params) => {
    this.setState({
      loading: true,
    })
    ReceiptPreIssueService.eventF3After(params)
      .then(res => {
        this.DisplayList()
      }).catch(error => {
        message.error(error)
      }).finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  onEventF12 = () => {
    this.setState({
      loading: true,
    })
    Modal.confirm({
      title: '領収書の事前発行を実行しますか',
      icon: <ExclamationCircleOutlined />,
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        let params = {
          selectedRows: this.state.selectedRows
        }
        ReceiptPreIssueService.runBtn(params)
          .then(res => {
            let data = {
              TypeCode: res.data.variables,
              OptionCode: res.data.variables,
              ItemDataPrinter: res.data.variables,
              ReceiptDateScreen: res.data.variables,
              OfficeCodeNew: res.data.variables,
              StsDateReceiptMore: res.data.variables,
              Proviso: res.data.variables,
              InspectIssue: res.data.variables,
              StsPrintPreview: res.data.variables,
              Li_Parameters: res.data.variables.param,
              Li_UsuallyInAdvance: res.data.variables.param,
              selectedRows: this.state.selectedRows
            }
            let component = this.checkMesstoScreen(res.data.message, data)
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 500,
                component
              },
            })
          })
          .catch((err) => {
            const res = err.response
            if (!res || !res.data || !res.data.message) {
              message.error('エラーが発生しました')
              return
            }
            message.error(res.data.message)
          })
      }
    })
  }

  onEventCtrlO = () => {
    this.setState({
      loading: true
    })
    let component = null
    ReceiptPreIssueService.eventCtrlo()
      .then(res => {
        let data = {
          Li_TypeCode: res.data.variables.Li_TypeCode,
          Li_OptionCode: res.data.variables.Li_OptionCode,
          Li_Expansion: res.data.variables.Li_Expansion,
        }
        component = this.checkMesstoScreen(res.data.message, data)
      }).catch(error => {
        message.error(error)
      }).finally(() => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            width: '80%',
            visible: true,
            component
          },
          loading: false,
        })
      })
  }

  onEventExit = () => {
    this.setState({
      loading: true,
    })
    ReceiptPreIssueService.exit().then(res => {
      this.setState({
        stsEnd: res.data.variables.StsEnd,
      })
      message.success(res.data.message ? res.data.message : '')
    }).catch(error => {
      message.error(error)
    }).finally(() => {
      this.setState({
        loading: false,
      })
    })
  }

  onSearchBtn = () => {
    this.setState({
      tableLoading: true,
    })
    let params = {
      Li_DateF: moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
      Li_DateT: moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
      Li_OfficeCode: this.formRef.current.getFieldValue('OfficeCode'),
      Li_BranchStoreCode: this.formRef.current.getFieldValue('BranchStoreCode'),
      Li_Course: this.formRef.current.getFieldValue('CourseCode'),
      Li_StatusFlag: this.formRef.current.getFieldValue('StatusFlag'),
      Li_Issue: this.formRef.current.getFieldValue('Issue') ? this.formRef.current.getFieldValue('Issue') : '',
      Li_Run: this.state.defaultData.AllPart,
      Li_KanaName: this.state.defaultData.KanaName ? this.state.defaultData.KanaName : '',
      Li_DateBirth: this.state.defaultData.BirthDateDate ? this.state.defaultData.BirthDateDate : '',
      Li_DisplayOrder: this.state.defaultData.DisplayOrder,
    }
    ReceiptPreIssueService.searchBtn(params)
      .then(res => {
        this.DisplayList()
      }).catch(error => {
        message.error(error)
      })
  }

  DisplayList() {
    let params = {
      Li_DateF: moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
      Li_DateT: moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
      Li_OfficeCode: this.formRef.current.getFieldValue('OfficeCode'),
      Li_BranchStoreCode: this.formRef.current.getFieldValue('BranchStoreCode'),
      Li_Course: this.formRef.current.getFieldValue('CourseCode'),
      Li_StatusFlag: this.formRef.current.getFieldValue('StatusFlag'),
      Li_Issue: this.formRef.current.getFieldValue('Issue') ? this.formRef.current.getFieldValue('Issue') : '',
      Li_Run: this.state.defaultData.AllPart,
      Li_KanaName: this.state.defaultData.KanaName ? this.state.defaultData.KanaName : '',
      Li_DateBirth: this.state.defaultData.BirthDateDate ? this.state.defaultData.BirthDateDate : '',
      Li_DisplayOrder: this.state.defaultData.DisplayOrder,
    }
    ReceiptPreIssueService.displayList(params)
      .then(res => {
        // 件数が0件だった時は領収書発行をさせないため
        let disabledFlag = true
        if (res.data.length > 0) {
          disabledFlag = false
        }
        this.formRef.current.setFieldsValue({
          tableData: res.data
        })
        let arrTemp = res.data.map(item => item.tableKey)
        this.setState({
          selectAll: true,
          selectedRowKeys: arrTemp,
          selectedRows: res.data,
          disabledFlag: disabledFlag
        })
      }).catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  searchOfficeCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            onFinishScreen={(output) => {
              this.setFormFieldValue('OfficeCode', output.Lio_OfficeCode)
              this.setFormFieldValue('BranchStoreCode', output.Lio_BranchStoreCode)
              this.setFormFieldValue('office_kanji_name', output.Lo_Kanji_Name)
              this.closeModal()
            }}
          />),
      },
    })
  }

  searchBranchStoreCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            onFinishScreen={(output) => {
              this.setFormFieldValue('OfficeCode', output.Lio_OfficeCode)
              this.setFormFieldValue('BranchStoreCode', output.Lio_BranchStoreCode)
              this.setFormFieldValue('office_kanji_name', output.Lo_Kanji_Name)

              this.closeModal()
            }}
          />),
      },
    })
  }

  searchCourseCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={(output) => {
              this.setFormFieldValue('CourseCode', output.Lo_CourseCode)
              this.setFormFieldValue('course_name_formal', output.Lo_CourseName)
              this.closeModal()
            }}
          />),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='receipt-pre-issue'>
        <Card className='mb-2' title='領収書事前発行'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className='box_inner_horizontal' style={{ marginBottom: 10 }}>
              <div className='box_search'>
                <div className='box_search_inner_border' style={{ paddingTop: 0 }}>
                  <Form.Item
                    name='DateFChar'
                    label='受診日'
                    {...labelCol}
                  >
                    <VenusDatePickerCustom formRefDatePicker={this.formRef} />
                  </Form.Item>

                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='OfficeCode'
                      label='事業所'
                      {...labelCol}
                    >
                      <Input.Search
                        allowClear={true}
                        type='text'
                        className='input-search-size-number-allow-clear-8'
                        style={{ textAlign: 'right' }}
                        onChange={(e) => this.checkInputField(e, ['office_kanji_name', 'BranchStoreCode'])}
                        onBlur={(e) => this.onChangeOfficeCode(e)}
                        onSearch={(value, e) => {
                          if (e.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              OfficeCode: '',
                              BranchStoreCode: '',
                            })
                          } else {
                            this.searchOfficeCode()
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name='BranchStoreCode'
                      label=''
                    >
                      <Input.Search
                        allowClear={true}
                        type='text'
                        className='input-search-size-number-allow-clear-4'
                        style={{ textAlign: 'right' }}
                        onSearch={(e) => {
                          if (e.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              OfficeCode: '',
                              BranchStoreCode: '',
                            })
                          } else {
                            this.searchBranchStoreCode()
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name='office_kanji_name'
                      label=''
                    >
                      <div style={{ width: 'fit-content' }}>{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                    </Form.Item>
                  </div>

                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='CourseCode'
                      label='コース'
                      {...labelCol}
                    >
                      <Input.Search
                        allowClear={true}
                        type='text'
                        className='input-search-size-number-allow-clear-4'
                        onChange={(e) => this.checkInputField(e, ['course_name_formal'])}

                        onSearch={(value, e) => {
                          if (e.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              CourseCode: ''
                            })
                          } else {
                            this.searchCourseCode()
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name='course_name_formal'
                    >
                      <span>{this.formRef.current?.getFieldValue('course_name_formal')}</span>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_inner' style={{ paddingTop: 0 }}>
                  <Form.Item
                    name='StatusFlag'
                    label='状態'
                    {...labelCol}
                  >
                    <Select> {/** 4:全て,0:予約,1:受付,2:保留,3:待ち */}
                      <Select.Option value={4}>全て</Select.Option>
                      <Select.Option value={0}>予約</Select.Option>
                      <Select.Option value={1}>受付</Select.Option>
                      <Select.Option value={2}>保留</Select.Option>
                      <Select.Option value={3}>待ち</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='Issue'
                    label='発行'
                    {...labelCol}
                  >
                    <Select>
                      {/** 全て,新規,再発行 */}
                      <Select.Option value=''>全て</Select.Option>
                      <Select.Option value={1}>新規</Select.Option>
                      <Select.Option value={2}>再発行</Select.Option>

                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className='box_search_btn'>
                <div className='box_search_btn_inner'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => this.DisplayList()}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>
            </div>

            <Table
              bordered
              size='small'
              dataSource={this.formRef.current?.getFieldValue('tableData')}
              pagination={this.formRef.current?.getFieldValue('tableData').length > process.env.REACT_APP_PAGE_SIZE ? this.state.Paginate : false}
              rowKey={(record) => record.tableKey}
              sticky={true}
              rowSelection={{
                selectedRowKeys: this.state.selectedRowKeys,
                onSelect: (record) => {
                  let arrTemp = [...this.state.selectedRowKeys];
                  let arrTempRecord = [...this.state.selectedRows];
                  let idx = arrTemp.indexOf(record.tableKey);
                  if (idx === -1) {
                    arrTemp.push(record.tableKey);
                    arrTempRecord.push(record)
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord,
                    });
                  } else {
                    arrTemp.splice(idx, 1);
                    arrTempRecord.splice(idx, 1);
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord,
                    });
                  }
                },
                onSelectAll: (selected) => {
                  if (selected) {
                    let arrTemp = this.formRef.current?.getFieldValue('tableData').map(item => item.tableKey);
                    let arrTempRecord = this.formRef.current?.getFieldValue('tableData');
                    this.setState({
                      selectedRowKeys: arrTemp,
                      selectedRows: arrTempRecord,
                      selectedRowOnly: arrTempRecord
                    });
                  } else {
                    this.setState({
                      selectedRowKeys: [],
                      selectedRows: [],
                      selectedRowOnly: []
                    });
                  }
                }
              }}
            >
              <Table.Column
                title='個人番号'
                dataIndex='personal_number_id'
                width={100}
              />
              <Table.Column
                title='氏名'
                dataIndex='kanji_name'
              />
              <Table.Column
                title='コース'
                width={200}
                dataIndex=''
                render={(value, record, index) => {
                  return (
                    <Row>
                      <Col>
                        <Form.Item name={['tableData', index, 'visit_course']}>
                          <span>{record.visit_course || 'xx xxx'}</span>
                        </Form.Item>
                      </Col>
                      <Col style={{ paddingLeft: '5px' }}>
                        <Form.Item name={['tableData', index, 'contract_name']}>
                          <span>{record.W2_target || 'xx xxx'}</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  )
                }}
              />
              <Table.Column
                title='事業所'
                dataIndex='office_kanji_name'
              />
              <Table.Column
                title='領収番号'
                dataIndex='receipt_number'
                width={80}
              />
              <Table.Column
                title='金額'
                dataIndex='total_price'
                width={80}
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right', margin: 0 }}>{value === 0 ? null : value.toLocaleString()}</div>
                  )
                }}
              />
              <Table.Column
                title='領収日'
                dataIndex='date_of_receipt'
                key=''
                width={60}
                render={(value, record, index) => {
                  return (
                    value ?
                      <div style={{ textAlign: 'center' }}><CheckOutlined /></div> :
                      null
                  )
                }}
              />
              <Table.Column
                title='事業所'
                dataIndex='StsOffice'
                key=''
                width={60}
                render={(value, record, index) => {
                  return (
                    value ?
                      <div style={{ textAlign: 'center' }}><CheckOutlined /></div> :
                      null
                  )
                }}
              />
              <Table.Column
                title='個　人'
                dataIndex='StsPerson'
                width={60}
                render={(value, record, index) => {
                  return (
                    value ?
                      <div style={{ textAlign: 'center' }}><CheckOutlined /></div> :
                      null
                  )
                }}
              />
              <Table.Column
                title='備考'
                dataIndex='Remarks'
              />
            </Table>

            <div className='box_button_bottom_right' >
              <Button
                type='primary'
                disabled={this.state.disabledFlag}
                onClick={() => {
                  this.onEventF12()
                }}
              >
                領収発行
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1915001_ReceiptPreIssue)
