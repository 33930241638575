import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Col, Button, Space, message } from 'antd'
import PropTypes from "prop-types"
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery.jsx'
import WS0178001_QuerySiteInfo from './WS0178001_QuerySiteInfo.jsx'
import WS0179001_InquiryFindingInfo from './WS0179001_InquiryFindingInfo.jsx'
import { SaveOutlined } from '@ant-design/icons'
import NormalValueSettingAction from 'redux/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSetting.action'

// ★★

const labelCol = {
  span: 4
}
const styleFormItem = {
  margin: 0,
}

class WS2713502_NormalValueSettingInput extends React.Component {
  static propTypes = {
    Li_FormatCopySource: PropTypes.string,
  }
  formRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      order: this.props.order,
      id: this.props.a6_id,
      findingsCode: this.props.findingsCode,
      classification: this.props.site_classification,
      siteCode: this.props.site_code,
    }
    this.onFinish = this.onFinish.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      id: this.props.value?.a6_id ?? "",
      kind: this.props.value?.inspection ?? "",
      Judge: this.props.value?.judgement ?? "",
      SiteName: this.props.value?.site_name ?? "",
      FindingsName: this.props.value?.minimal_required_value ?? "",
      SiteClassification: this.props.value?.site_classification ?? 0,
      SiteCode: this.props.value?.site_code ?? 0,
      FindingsCode: this.props.value?.findings_code ?? 0,
      FindingsName: this.props.value?.minimal_required_value ?? "",
      InspectResults: this.props.value?.minimal_required_value ?? ""
    })
  }

  /**
     * モーダル閉じる
     */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  findingCodeSearch = ({ findings_code, site_classification, findings_name }) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0179001_InquiryFindingInfo
          Li_FindingsClassify={site_classification}
          Lo_FindingsCode={findings_code}
          Lo_FindingsName={findings_name}
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              FindingsName: value.Lo_FindingsName,
              FindingsCode: value.Lo_FindingsCode
            })
            this.closeModal()
          }}
        />),
      },
    })
  }

  siteCodeSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0178001_QuerySiteInfo
          Li_SiteClasify={this.props.value?.site_classification}
          Lo_SiteCode
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              SiteCode: value.Lo_SiteCode,
              SiteName: value.Lo_SiteName
            })
            this.closeModal()
          }}
        />),
      },
    })
  }

  normalValueSettingSearch = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (<WS0285001_JudgeQuery
          Li_JudgeLevel={''}
          Lio_Judge={''}
          onFinishScreen={(value) => {
            this.formRef.current.setFieldsValue({
              Judge: value.recordData.judgment_result
            })
            this.closeModal()
          }}
        />),
      },
    })
  }

  onFinish(values) {
    const { a6_id, site_classification } = this.props.value
    const pattern = this.props.pattern

    values = { ...values, a6_id, site_classification, pattern }
    NormalValueSettingAction.onSave(values)
      .then((res) => {
        this.props.onFinish()
        if (res?.message) {
          message.info(res.message)
        }
      })
      .catch(error => {
        message.error(res.data.message);
      })
    this.props.onCloseModal()
  }

  render(record) {
    return (
      <div className='normal-value-setting'>
        <Card title='編集' >
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ Pattern: 1 }}>
            <Col>

              <Form.Item label='規定値設定' labelCol={{ span: 4 }} className='mt-3'></Form.Item>
              <div style={{ border: '1px solid #F0F0F0', padding: '7px' }}>
                <Form.Item name='Judge' label='判定' labelCol={labelCol} style={{ marginTop: '1rem' }}>
                  <Input.Search
                    className='input-search-size-judgment'
                    onSearch={this.normalValueSettingSearch}
                  />
                </Form.Item>
                {this.props.value.type !== '所見' ?
                  <Form.Item name='InspectResults' label='検査結果' labelCol={labelCol}>
                    <Input.Search onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (<WS0274001_InspectCmtSearchQuery
                            Lio_CommentsClassify={''}
                            LnkOutInspectCommentsScreen={''}
                            onFinishScreen={(value) => {
                              this.formRef.current.setFieldsValue({
                                InspectResults: value.LnkOutInspectCmtScreen
                              })
                              this.closeModal()
                            }}
                          />),
                        },
                      })
                    }} />
                  </Form.Item>
                  : ''}
                <Form.Item name="SiteCode" hidden>
                  <Input />
                </Form.Item>
                {this.props.value.type === '所見' ?
                  <Form.Item label='部位' labelCol={labelCol} style={styleFormItem}>
                    <Space>
                      <Form.Item name='SiteName'>
                        <Input.Search
                          onChange={(e) => {
                            this.formRef.current.setFieldsValue({
                              SiteCode: e.target.value
                            })
                          }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 500,
                                component: (<WS0178001_QuerySiteInfo
                                  Li_SiteClasify={this.props.value?.site_classification}
                                  Lo_SiteCode
                                  onFinishScreen={(value) => {
                                    this.formRef.current.setFieldsValue({
                                      SiteCode: value.Lo_SiteCode,
                                      SiteName: value.Lo_SiteName
                                    })
                                    this.closeModal()
                                  }}
                                />),
                              },
                            })
                          }} />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  : ''}
                <Form.Item name="FindingsCode" hidden>
                  <Input />
                </Form.Item>
                {this.props.value.type === '所見' ?
                  <Form.Item label='所見' labelCol={labelCol} style={styleFormItem}>
                    <Space>
                      <Form.Item name='FindingsName'>
                        <Input.Search
                          onChange={(e) => {
                            this.formRef.current.setFieldsValue({
                              FindingsName: e.target.value
                            })
                          }}
                          onSearch={() => {
                            this.findingCodeSearch(this.props.value)
                          }} />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  : ''}
              </div>
            </Col>
            {this.state.order === "edit" ? (
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  htmlType='submit'
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            ) :
              <div className='box_button_bottom_right'
                style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            }
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

export default WS2713502_NormalValueSettingInput
