import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Button } from 'antd'
import { MoreOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons'
import UserService from 'services/UserService'
import UserRegisterAction from 'redux/MN_Basis/UserInfo/UserRegister.action.js'
import WS2706002_UserRegisterForm from './WS2706002_UserRegisterForm.jsx'
import { danger } from 'redux/alert/alert.actions.js'
import moment from 'moment-timezone'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS2706001_UserRegister extends React.Component {
  constructor(props) {
    super(props)

    // document.title = 'ユーザー登録'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.loadUserList()
  }

  /**
   * ユーザ一覧を取得
   */
  loadUserList() {
    UserRegisterAction.index()
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 削除処理
   * @param {*} id
   */
  deleteUser(id) {
    UserService.deleteUser(id)
      .then(res => {
        this.loadUserList()
      })
      .catch(error => {
        this.props.alert(danger, error.response.data.message)
      })
  }

  /**
   * ユーザー登録フォーム モーダル
   * @param {*} record
   */
  showWS2706002_UserRegisterForm = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        component: (
          <WS2706002_UserRegisterForm
            record={record}
            onUpdateSuccess={() => {
              this.loadUserList()
              this.closeModal()
            }}
            onFinishScreen={(id) => {
              this.deleteUser(id)
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='user-register'>
        <Card title='ユーザー登録'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
            rowKey={record => record.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  // ユーザー登録フォーム
                  this.showWS2706002_UserRegisterForm(record)
                }
              }
            }
            }
          >
            <Table.Column
              title='ユーザーID'
              dataIndex='username'
            />
            <Table.Column
              title='名前'
              dataIndex='name'
            />
            <Table.Column
              title='管理者'
              dataIndex='authority_level'
              width={70}
              render={text => <div style={{ textAlign: 'center' }}>{text === '1' ? <CheckOutlined /> : ''}</div>}
            />
            <Table.Column
              title='A'
              dataIndex='usageRightsA'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='B'
              dataIndex='usageRightsB'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='C'
              dataIndex='usageRightsC'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='D'
              dataIndex='usageRightsD'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='E'
              dataIndex='usageRightsE'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='F'
              dataIndex='usageRightsF'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='G'
              dataIndex='usageRightsG'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='H'
              dataIndex='usageRightsH'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='I'
              dataIndex='usageRightsI'
              width={45}
              render={(value) => {
                return (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )
              }}
            />
            <Table.Column
              title='有効期限'
              dataIndex=''
              width={220}
              render={(text, record) => {
                const valid_f = moment(record.valid_f).isValid() ? moment(record.valid_f).format('YYYY/MM/DD') : ''
                const valid_t = moment(record.valid_t).isValid() ? moment(record.valid_t).format('YYYY/MM/DD') : ''
                let validRangeDate = ''
                if (valid_f || valid_t) {
                  validRangeDate = valid_f + ' ～ ' + valid_t
                }
                return (
                  <span>
                    {validRangeDate}
                  </span>
                )
              }}
            />
            <Table.Column
              title='パスワード更新日'
              dataIndex='password_updated_at'
              width={130}
              render={value =>
                moment(value).isValid() ? moment(value).format('YYYY/MM/DD') : null
              }
            />
            <Table.Column
              key='action'
              align='center'
              width={40}
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    // ユーザー登録フォーム
                    this.showWS2706002_UserRegisterForm()
                  }}
                />
              }
              render={(text, record) => {
                return (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // ユーザー登録フォーム
                      this.showWS2706002_UserRegisterForm(record)
                    }}
                  />
                )
              }}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({
  alert: (action, message) => dispatch(action(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2706001_UserRegister)
