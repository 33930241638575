import React from "react";
import { connect } from "react-redux";
import { Card, Button, Form, Input, Row, Col, Table } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS0265001 from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";

class TestActions extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      formRef: {},
      loading: false,
      coursesData: [],
      childModal: {},
      searchParams: {}
    };
    this.screens = {
      WS0265001
    };
  }

  handleChange = (value, name) => {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [name]: value
      }
    });
  };

  tableSearch() {
    this.setState({ loading: true });
    // api returns array data pattern
    VenusApiRoutesV2.callApi("API002786020002", this.state.searchParams).then(res => {
      this.setState({ coursesData: res, loading: false });
    });
    // post api call test
    VenusApiRoutesV2.callApi("API000339001011",{test:111,test2:222});
  }

  searchBtnClicked() {
    // api returns "call screen XXXXX" pattern
    VenusApiRoutesV2.callApi("API000331001008", {}).then(res => {
      const prop = {
        // modal initial props.
        ...res.variables,
        // when finish on modal.
        // write code manually for screen function.
        onFinishScreen: output => {
          console.log("onFinishScreen", output);
          this.formRef.current.setFieldsValue({
            Lo_CourseCode: output.Lo_CourseCode,
            Lo_CourseName: output.Lo_CourseName
          });
          this.handleChange(output.Lo_CourseCode, "Lo_CourseCode");
          this.closeModal();
        }
      };
      VenusApiRoutesV2.openModal(res, prop, this);
    });
  }
  componentDidMount() {
    // this.tableSearch()
  }

  componentWillUnmount() {}

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        component: null
      }
    });
  };

  render() {
    return (
      <Card>
        <h2>VenusApiRoutesV2 test</h2>
        <Form ref={this.formRef}>
          <Form.Item label="コース">
            <Row>
              <Col>
                <Form.Item name="Lo_CourseName">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="Lo_CourseCode">
                  <Input.Search
                    onChange={e => {
                      this.handleChange(e.target.value, "Lo_CourseCode");
                    }}
                    onSearch={() => {
                      this.searchBtnClicked();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Button type="primary" onClick={() => this.tableSearch()}>
            tableSearch()
          </Button>
        </Form>

        <Table
          bordered
          size="small"
          dataSource={this.state.coursesData}
          loading={this.state.loading}
          rowKey={record => record.course_code}
          pagination={{ showQuickJumper: true, showSizeChanger: true }}
          scroll={{ x: 700, y: 700 }}
        >
          <Table.Column title="コード" dataIndex="course_code" align="center" width={80} />
          <Table.Column title="略称" dataIndex="course_name_short_name" />
          <Table.Column title="名称" dataIndex="course_name_formal" />
        </Table>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
                component: null
              }
            });
          }}
        />
      </Card>
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TestActions);
