import axios from "configs/axios";
const apiPaths = {
    getScreenData: "/api/startup-and-maintenance/getscreendata",
};
const StartupAndMaintenanceService = {
    async getScreenData() {
        return axios.get(apiPaths.getScreenData);
    },
};

export default StartupAndMaintenanceService;