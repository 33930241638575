import InquiryFindingInfoService from 'services/InspectionMaintenance/NormalValueSettingMaintain/InquiryFindingInfoService'
import { message } from 'antd'

const InquiryFindingInfoAction = {
  index(params) {
    return InquiryFindingInfoService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  getFinding(params) {
    return InquiryFindingInfoService.getFinding(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
}
export default InquiryFindingInfoAction
