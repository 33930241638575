import axios from 'configs/axios'

const APP_LIST = {
  index: '/api/create-document-batch/document-batch-create-sub',
  selectForm: '/api/create-document-batch/document-batch-create-sub/select-form',
  changeSpecifiedIssue: '/api/create-document-batch/document-batch-create-sub/change-specified-issue',
  changeOutputPattern: '/api/create-document-batch/document-batch-create-sub/change-out-put-pattern',
  changeOutputUnit: '/api/create-document-batch/document-batch-create-sub/change-output-unit',
  print: '/api/create-document-batch/document-batch-create-sub/print',
  preview: '/api/create-document-batch/document-batch-create-sub/preview',

}

const DocumentBatchCreateSubService = {
  async index(params) {
    return axios.get(APP_LIST.index, { params })
  },
  async selectForm(params) {
    return axios.post(APP_LIST.selectForm, params)
  },
  async changeSpecifiedIssue(params) {
    return axios.post(APP_LIST.changeSpecifiedIssue, params)
  },
  async changeOutputPattern(params) {
    return axios.post(APP_LIST.changeOutputPattern, params)
  },
  async changeOutputUnit(params) {
    return axios.post(APP_LIST.changeOutputUnit, params)
  },
  async print(params) {
    return axios.post(APP_LIST.print, params)
  },
  async preview(params) {
    return axios.post(APP_LIST.preview, params, { responseType: 'blob' })
  }
}

export default DocumentBatchCreateSubService
