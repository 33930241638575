import { connect } from 'react-redux'
import { Card, Dropdown, Form, Input, Menu, message, Modal, Select, Space, Table, Button, Tooltip } from 'antd'
import { CopyOutlined, DeleteOutlined, MoreOutlined, PlusOutlined, SaveOutlined, ScissorOutlined, SearchOutlined, SnippetsOutlined, MenuOutlined } from '@ant-design/icons'
import React from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2737006_MiraisSingleDoctorSettingAdd from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737006_MiraisSingleDoctorSettingAdd.jsx'
import MiraisSingleDoctorSettingActions from 'redux/CooperationRelated/MiraisSingleTransmission/MiraisSingleDoctorSetting.actions.js'


class WS2737005_MiraisSingleDoctorSetting extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'Mirais検査保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.GetScreenData()
  }

  GetScreenData = () => {
    MiraisSingleDoctorSettingActions.index()
      .then((res) => {
        this.setState({ dataSource: res })
      })
  }


  /**
   * 新規・編集
   * @param {*} record
   */
  newAndAddItem = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS2737006_MiraisSingleDoctorSettingAdd
            record={record}
            onFinishScreen={(data) => {
              console.log(data);
              MiraisSingleDoctorSettingActions.saveAndUpdateData(data)
                .then((res) => {
                  this.GetScreenData()
                  this.closeModal()
                })
            }}

            onDelete={() => {
              MiraisSingleDoctorSettingActions.delete(record)
                .then((res) => {
                  this.GetScreenData()
                  this.closeModal()
                  console.log(record);
                  console.log('削除');
                })
            }}
          />,
      },
    })
  }


  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      loaddingTable: false
    })
  }

  render() {
    return (
      <div className='mirais-single-doctor-setting'>
        <Card title='医師設定'>
          <Form ref={this.formRef} autoComplete='off'  >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              scroll={{ y: 600, x: 'max-content' }}
              pagination={false}
              bordered={true}
              rowKey={(record) => record.id}
            >
              <Table.Column
                title='コース'
                dataIndex='visit_course'
                className='column-size-10'
              />

              <Table.Column
                title='コース名'
                dataIndex='visit_course_name'
              />

              <Table.Column
                title='曜日'
                dataIndex='week'
                className='column-size-40'
              />

              <Table.Column
                title='ドクター'
                dataIndex='doctor'
                className='column-size-20'
              />

              <Table.Column
                width={40}
                align='center'
                fixed='right'
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.newAndAddItem()
                    }}
                  />
                }

                render={(text, record, index) =>
                  <Button size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      this.newAndAddItem(record)
                    }}
                  />
                }
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2737005_MiraisSingleDoctorSetting)
