/**
 * 各画面の共通アクション
 * - 今後共通アクションを定義したい場合はここに定義する
 * - 各画面は親コンポーネントでcomposeする
 *
 * [ modules ]
 * - updateScreenLoading: メイン領域にローディングを表示/非表示
 */
import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loading } from "redux/common/common.reducer";

const screenPresetWrapper = WrappedComponent => {
  return class extends PureComponent {
    updateScreenLoading = isLoading => this.props.dispatch(loading(isLoading));

    render() {
      return <WrappedComponent {...this.props} updateScreenLoading={this.updateScreenLoading} />;
    }
  };
};

export const withScreenPreset = compose(connect(), screenPresetWrapper);
