import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Card,
  Form,
  Tabs,
  Descriptions,
  Input,
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Select,
  InputNumber
} from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import MemoFunctionAction from 'redux/Others/ReserveStatusSearch/MemoFunction.action.js'
import { SaveOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

const { TextArea } = Input

const FormStyle = {
  marginBottom: 0
}

class TabooEditDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = 'メモ編集画面'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      date: '',
      reservation_number: '',
      personal_number_id: '',
      type: '',
      serial_number: '',
      id: '',
      newFlag: 0
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      serial_number: this.props.serial_number,
      select: this.props.select,
      convert: this.props.convert
    })
    this.setState({
      dataSource: this.props.dataSource,
      id: this.props.id,
      serial_number: this.props.serial_number,
      newFlag: this.props.newFlag
    })
  }

  saveTabooOptions = () => {

    let duplication = false
    this.state.dataSource.map((value, index) => {
      if (
        this.state.serial_number !== this.formRef.current?.getFieldValue('serial_number')
        && (this.formRef.current?.getFieldValue('serial_number') == value.serial_number)
      ) {
        return duplication = true
      }
    })

    if (duplication == true) {
      Modal.error({ title: '番号が重複しています' })
    } else {
      let params = {
        id: this.state.id,
        newFlag: this.state.newFlag,
        serial_number: this.formRef.current?.getFieldValue('serial_number'),
        select: this.formRef.current?.getFieldValue('select'),
        convert: this.formRef.current?.getFieldValue('convert')
      }
      MemoFunctionAction.saveTabooOptions(params)
        .then(res => {
          this.props.onFinishScreen()
        })
    }
  }

  deleteTabooOptions = () => {
    MemoFunctionAction.deleteTabooOptions({ id: this.state.id })
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  render() {
    const { rowSelect } = this.state
    const format = 'YYYY/MM/DD'

    return (
      <div className='taboo-edit-display' style={{ width: 700 }}>
        <Card title='禁忌設定'>
          <Form
            ref={this.formRef}
          >
            <div className='box_container'>
              <div className='box_inner_vertical'>
                <Form.Item name='serial_number' label='番号' labelCol={{ span: 3 }} >
                  <InputNumber className='input-size-10' />
                </Form.Item>
                <Form.Item name='select' label='内容' labelCol={{ span: 3 }}>
                  <Input style={{ width: '90%' }} />
                </Form.Item>
                <Form.Item name='convert' label='記号' labelCol={{ span: 3 }}>
                  <Input className='input-size-2' />
                </Form.Item>
              </div>

              <div className='box_button_bottom_right'>
                <Button
                  type='danger'
                  onClick={() => {
                    Modal.confirm({
                      content: '削除を行いますか？',
                      onOk: () => { this.deleteTabooOptions() }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => this.saveTabooOptions()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabooEditDisplay)
