import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Table,
  Input,
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  Select,
  Space,
  Form,
  message,
  Dropdown,
  Menu
} from "antd";

import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import WS0495001_ConditionExpressInfoInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0495001_ConditionExpressInfoInquiry.jsx";
import BindingModeSettingService from "services/ResultOutput/BindingModeSetting/BindingModeSettingService";
import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MoreOutlined
} from "@ant-design/icons";
class WS3022500_BindingModeSettingEdit extends React.Component {
  static propTypes = {
    Li_ParentCode: PropTypes.any,
  };
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "結合様式設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTable: false,
      selectedRowKeyLeft: [],
      selectedRowKeyRight: [],
      selectedRowRight: {},
    };
  }

  componentDidMount = () => {
    if (this.props.record) {
      this.formRef.current.setFieldsValue(this.props.record)
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="binding-mode-setting-edit">
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Card title={"結合様式設定" + ((this.props.newFlag) ? '新規作成' : '編集')}>
            {this.props.BindingModeSettingFlag ?
              <div className="box_inner_vertical">
                <Form.Item
                  label='表示順'
                  name='display_order'
                  labelCol={{ span: 2 }}
                >
                  <Input style={{ width: 120 }} type="number" min="0" />
                </Form.Item>
                <Form.Item
                  name='parent_code'
                  label='様式'
                  labelCol={{ span: 2 }}
                >
                  <Input.Search
                    style={{ width: 120 }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "800px",
                          component: (
                            <WS0286001_PrintStyleInquiry
                              Li_UsuallyWrenRoster={""}
                              Lio_StyleCode={this.formRef.current.getFieldsValue('parent_code')}
                              onFinishScreen={({
                                Lio_StyleCode,
                                Lo_FormatName,
                              }) => {
                                this.formRef.current.setFieldsValue({
                                  parent_code: Lio_StyleCode,
                                  style_name: Lo_FormatName
                                })

                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='style_name'
                  label='名称'
                  labelCol={{ span: 2 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='有効'
                  name='enabled'
                  labelCol={{ span: 2 }}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  label='一括'
                  name='bulk'
                  labelCol={{ span: 2 }}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </div>
              :
              <div className="box_inner_vertical">
                <Form.Item
                  label='表示順'
                  name='display_order'
                  labelCol={{ span: 2 }}
                >
                  <Input style={{ width: 120 }} type="number" min="0" />
                </Form.Item>
                <Form.Item
                  name='child_code'
                  label='様式'
                  labelCol={{ span: 2 }}
                >
                  <Input.Search
                    style={{ width: 120 }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "800px",
                          component: (
                            <WS0286001_PrintStyleInquiry
                              Li_UsuallyWrenRoster={""}
                              Lio_StyleCode={this.formRef.current.getFieldsValue('parent_code')}
                              onFinishScreen={({
                                Lio_StyleCode,
                                Lo_FormatName,
                              }) => {
                                this.formRef.current.setFieldsValue({
                                  child_code: Lio_StyleCode,
                                  format_name: Lo_FormatName
                                })

                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='format_name'
                  label='様式名'
                  labelCol={{ span: 2 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='有効'
                  name='enabled'
                  labelCol={{ span: 2 }}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  name='conditional_expression'
                  label='条件'
                  labelCol={{ span: 2 }}
                >
                  <Input.Search
                    style={{ textAlign: "right", width: 120 }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "800px",
                          component: (
                            <WS0495001_ConditionExpressInfoInquiry
                              onFinishScreen={({
                                Lo_ConditionSerialNum,
                                Lo_Name,
                              }) => {
                                this.formRef.current.setFieldsValue({
                                  conditional_expression: Lo_ConditionSerialNum,
                                  name: Lo_Name
                                })
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='name'
                  label='条件名称'
                  labelCol={{ span: 2 }}
                >
                  <Input readOnly />
                </Form.Item>
              </div>}
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.props.onFinishScreen(this.formRef.current.getFieldsValue())}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Card>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Form>
      </div>
    );
  }
}

export default WS3022500_BindingModeSettingEdit;
