import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { SaveOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Button, message, Radio } from "antd";
import ContractMasterDetailsAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetails.actions'
import WS1289001_ContractCompiledQuery from 'pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1289001_ContractCompiledQuery.jsx';

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  // borderRight: 0,
  margin: 0,
  textAlign: 'center',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}

const formLableStyle = {
  labelCol: {
    style: {
      marginTop: '20px'
    }
  },
}

class WS1316600_ContractMasterMaintainEdit extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = 'V4-TMS01000:契約マスタ保守'; 
    this.state = {
      childModal: {
        visible: false,
        component: null,
        title: null,
        width: 0,
      },
      changeData: {},
      flag: false
    };
  }
  componentDidMount() {
    if (this.props.data) {
      this.getScreenData()
    }
    if (this.props.year) {
      this.setState({
        changeData: {
          ...this.state.changeData,
          year: this.props.year
        }
      })
      this.formRef.current?.setFields([])
    }
  }

  getScreenData() {
    let copyData = { ...this.props.data }
    if (copyData.actively_payment_division_01 === 0) {
      copyData.actively_payment_division_01 = null
    }
    if (copyData.actively_payment_division_02 === 0) {
      copyData.actively_payment_division_02 = null
    }
    if (copyData.actively_payment_division_03 === 0) {
      copyData.actively_payment_division_03 = null
    }
    if (copyData.payment_division_motivation_01 === 0) {
      copyData.payment_division_motivation_01 = null
    }
    if (copyData.payment_division_motivation_04 === 0) {
      copyData.payment_division_motivation_04 = null
    }
    if (copyData.motivation_payment_division_02 === "") {
      copyData.motivation_payment_division_02 = null
    }
    if (copyData.motivation_unit_price !== 0) {
      copyData.motivation_unit_price = copyData.motivation_unit_price.toLocaleString()
    }
    if (copyData.actively_unit_price !== 0) {
      copyData.actively_unit_price = copyData.actively_unit_price.toLocaleString()
    }
    console.log(copyData);
    this.formRef.current?.setFieldsValue(copyData)
    this.formRef.current?.setFieldsValue(this.props.rowData)
    this.forceUpdate()
  }

  update() {
    let param = {
      id: this.formRef.current?.getFieldValue("id"),
      year: this.props.year ? this.props.year : '',
      changeData: this.state.changeData,
    }
    ContractMasterDetailsAction.update(param)
      .then((res) => {
        // 保存ボタンを押したときにWS1316001_ContractMasterMaintainのloadInitを動かしている
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  handleFormChange(name, value, changeName) {
    let columnName = name;
    let columnValue = value;
    console.log(value);
    if (
      columnName === 'motivation_unit_price' ||
      columnName === 'actively_unit_price' ||
      columnName === 'aggressive_price_rate_01' ||
      columnName === 'aggressive_price_rate_04' ||
      columnName === 'aggressive_price_rate_02' ||
      columnName === 'aggressive_price_rate_03' ||
      columnName === 'price_motivation_rate_01' ||
      columnName === 'price_motivation_rate_04' ||
      columnName === 'price_motivation_rate_02' ||
      columnName === 'active_constant_support_enforcement_point'
    ) {
      // カンマを消す
      columnValue = columnValue.replace(/,/g, '');
      // カンマ区切り
      columnValue = Number(columnValue)?.toLocaleString()
      this.formRef.current?.setFieldsValue({
        [columnName]: columnValue
      })
      // changeDataに入れる前にカンマを消す
      columnValue = columnValue.replace(/,/g, '');
    }
    if (
      columnName === 'actively_payment_division_01' ||
      columnName === 'actively_payment_division_04' ||
      columnName === 'actively_payment_division_02' ||
      columnName === 'actively_payment_division_03' ||
      columnName === 'payment_division_motivation_01' ||
      columnName === 'payment_division_motivation_04' ||
      columnName === 'motivation_payment_division_02'
    ) {
      this.formRef.current?.setFieldsValue({
        [changeName]: 0
      })
      this.setState({
        changeData: {
          ...this.state.changeData,
          [changeName]: 0,
          [columnName]: columnValue
        }
      })
    } else {
      this.setState({
        changeData: {
          ...this.state.changeData,
          [columnName]: columnValue
        }
      })
    }
  }

  onFinish(values) {
    if (this.state.flag) {
      this.update()
    }
  }

  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  closeModal = () => {
    this.setState((prevState) => ({
      childModal: { ...prevState.childModal, visible: false },
    }));
  };

  render() {
    return (
      <div className="contract-master-maintain-edit">
        <Card className="mb-2" title="契約保守編集">
          <Form
            layout={'vertical'}
            ref={this.formRef}
            // onValuesChange={(event) => this.handleFormChange(event)}
            onFinish={(value) => this.onFinish(value)}
            onFinishFailed={(errorInfo) => this.onFinishFailed(errorInfo)}
          >
            {this.props.activeTab === '0' ?
              // 基本
              <div className="box_container">
                <div className="box_inner_horizontal">
                  <div className="box_inner_vertical" >
                    <Form.Item name='contract_number' label='契約番号' onBlur={(e) => this.handleFormChange('contract_number', e.target.value)} >
                      <Input className="input-size-10" />
                    </Form.Item>
                    <Form.Item name='contract_type' label='契約形態'>
                      <Select style={{ width: '90px' }} onChange={(e) => this.handleFormChange('contract_type', e.target.checked)}  >
                        <Select.Option value={1}>個別</Select.Option>
                        <Select.Option value={2}>集合</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="box_inner_vertical" >
                    <Form.Item name='contract_name' label='契約名称' onBlur={(e) => this.handleFormChange('contract_name', e.target.value)}>
                      <Input className="input-size-12" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              : this.props.activeTab === '1' ?
                // 特定健診
                <div className="box_container">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical" >
                      <div className="box_inner_horizontal">
                        <Form.Item name="contract_compiled_code" label='取りまとめ'>
                          <Input.Search style={{ width: '120px', marginRight: '10px' }}
                            onSearch={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '50%',
                                  component: (
                                    <WS1289001_ContractCompiledQuery
                                      ContractCompiled={this.formRef.current?.getFieldValue("contract_compiled_code")}
                                      onFinishScreen={(output) => {
                                        this.formRef.current?.setFieldsValue({
                                          contract_compiled_code: output?.contract_compiled_code,
                                          name: output.recordData?.name_contract_compiled_division_code
                                        })
                                        this.setState({
                                          changeData: {
                                            ...this.state.changeData,
                                            contract_compiled_code: output?.contract_compiled_code
                                          }
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }} />
                        </Form.Item>
                        <Form.Item label=' '>
                          <div>{this.formRef.current?.getFieldValue("name")}</div>
                        </Form.Item>
                      </div>
                      <Form.Item name='representative_consignor_name' label='委託者' onBlur={(e) => this.handleFormChange('representative_consignor_name', e.target.value)}>
                        <Input className="input-size-10" />
                      </Form.Item>
                    </div>
                    <div className="box_inner_vertical">
                      <Form.Item name='representative_trustee_name' label='受託者' onBlur={(e) => this.handleFormChange('representative_trustee_name', e.target.value)}>
                        <Input className="input-size-30" />
                      </Form.Item>
                      <Form.Item name='FiduciaryBusinessSpecificMedica' label='特定健診'>
                        <Select style={{ width: '90px' }} onChange={(e) => this.handleFormChange('FiduciaryBusinessSpecificMedica', e)}>
                          <Select.Option value={0}>対象外</Select.Option>
                          <Select.Option value={1}>対象</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="box_inner_horizontal">
                    <Card
                      title='個別'
                      size="small"
                      style={{ width: '100%', backgroundColor: '#f3f3f3' }}
                    >
                      <div className="box_container">
                        <div className="box_inner_horizontal">
                          <div className="box_inner_vertical" >
                            <Form.Item name='basic_individual_medical_exam_unit_price' label='基  本' onBlur={(e) => this.handleFormChange('basic_individual_medical_exam_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <Form.Item name='anemia_individual_medical_exam_unit_price' label='貧  血' onBlur={(e) => this.handleFormChange('anemia_individual_medical_exam_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <Form.Item name='electrocardiograph_individual_medical_exam_unit_price' label='心電図' onBlur={(e) => this.handleFormChange('electrocardiograph_individual_medical_exam_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                          </div>
                          <div className="box_inner_vertical">
                            <Form.Item name='fundus_individual_medical_exam_unit_price' label='眼  底' onBlur={(e) => this.handleFormChange('fundus_individual_medical_exam_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <Form.Item name='h30_creatinine_individual_medical_exam_unit_price' label='ｸﾚｱﾁﾆﾝ' onBlur={(e) => this.handleFormChange('h30_creatinine_individual_medical_exam_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card
                      title='集団'
                      size="small"
                      // headStyle={{background:'#bbb'}}
                      style={{ width: '100%', backgroundColor: '#f3f3f3' }}>
                      <div className="box_container">
                        <div className="box_inner_horizontal">
                          <div className="box_inner_vertical" >
                            <Form.Item name='unit_price_exam_basic_population_health' label='基  本' onBlur={(e) => this.handleFormChange('unit_price_exam_basic_population_health', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <Form.Item name='unit_price_exam_anemia_population_health' label='貧  血' onBlur={(e) => this.handleFormChange('unit_price_exam_anemia_population_health', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <Form.Item name='unit_price_diagnosis_electrocardiogram_population_ken' label='心電図' onBlur={(e) => this.handleFormChange('unit_price_diagnosis_electrocardiogram_population_ken', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                          </div>
                          <div className="box_inner_vertical" >
                            <Form.Item name='fundus_group_medical_exam_unit_price' label='眼  底' onBlur={(e) => this.handleFormChange('fundus_group_medical_exam_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <Form.Item name='h30_creatinine_population_checkup_unit_price' label='ｸﾚｱﾁﾆﾝ' onBlur={(e) => this.handleFormChange('h30_creatinine_population_checkup_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                : this.props.activeTab === '2' ?
                  <div className="box_container">
                    <div className="box_inner_horizontal">
                      <div className="box_inner_vertical">
                        <Form.Item name='servicing_active_support' label='積極的'>
                          <Select style={{ width: '90px' }} onChange={(e) => this.handleFormChange('servicing_active_support', e)}>
                            <Select.Option value={0}>対象外</Select.Option>
                            <Select.Option value={1}>対象</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name='aggressive_counter_burden_hikisa_f' label='初回時全額徴収方式'>
                          <Radio.Group onChange={(e) => this.handleFormChange('aggressive_counter_burden_hikisa_f', e.target.value)}>
                            <Radio value={1}>有り</Radio>
                            <Radio value={0}>無し</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div className="box_inner_horizontal">
                          <Form.Item name='actively_unit_price' label='単価' onBlur={(e) => this.handleFormChange('actively_unit_price', e.target.value)}>
                            <Input type="tel" className="input-size-number-7" />
                          </Form.Item>
                          <div style={{ marginTop: '30px' }}>円</div>
                        </div>
                        <Form.Item name='active_constant_support_enforcement_point' label='実施ポイント数' onBlur={(e) => this.handleFormChange('active_constant_support_enforcement_point', e.target.value)}>
                          <Input type="tel" className="input-size-number-7" />
                        </Form.Item>
                      </div>
                      <div className="box_inner_vertical">
                        <div className="box_inner_horizontal">
                          <Form.Item name='actively_payment_division_01' label='初回'>
                            <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('actively_payment_division_01', e, 'aggressive_price_rate_01')}>
                              <Select.Option value={0}>　</Select.Option>
                              <Select.Option value={1}>定額指定</Select.Option>
                              <Select.Option value={2}>定率指定</Select.Option>
                            </Select>
                          </Form.Item>
                          <div className="box_inner_horizontal">
                            <Form.Item name='aggressive_price_rate_01' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('aggressive_price_rate_01', e.target.value)}>
                              <Input className="input-size-number-7" />
                            </Form.Item>
                            {this.formRef.current?.getFieldValue("actively_payment_division_01") === 1 ?
                              <div style={{ marginTop: '30px' }}>円</div> :
                              <div style={{ marginTop: '30px' }}>%</div>
                            }
                          </div>
                        </div>
                        <div className="box_inner_horizontal">
                          <Form.Item name='actively_payment_division_04' label='初①'>
                            <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('actively_payment_division_04', e, 'aggressive_price_rate_04')}>
                              <Select.Option value={'0'}>　</Select.Option>
                              <Select.Option value={"1"}>定額指定</Select.Option>
                              <Select.Option value={"2"}>定率指定</Select.Option>
                            </Select>
                          </Form.Item>
                          <div className="box_inner_horizontal">
                            <Form.Item name='aggressive_price_rate_04' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('aggressive_price_rate_04', e.target.value)}>
                              <Input className="input-size-number-7" />
                            </Form.Item>
                            {this.formRef.current?.getFieldValue("actively_payment_division_04") === '1' ?
                              <div style={{ marginTop: '30px' }}>円</div> :
                              <div style={{ marginTop: '30px' }}>%</div>
                            }
                          </div>
                        </div>
                        <div className="box_inner_horizontal">
                          <Form.Item name='actively_payment_division_02' label='継続'>
                            <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('actively_payment_division_02', e, 'aggressive_price_rate_02')}>
                              <Select.Option value={0}>　</Select.Option>
                              <Select.Option value={1}>定額指定</Select.Option>
                              <Select.Option value={2}>定率指定</Select.Option>
                            </Select>
                          </Form.Item>
                          <div className="box_inner_horizontal">
                            <Form.Item name='aggressive_price_rate_02' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('aggressive_price_rate_02', e.target.value)}>
                              <Input className="input-size-number-7" />
                            </Form.Item>
                            {this.formRef.current?.getFieldValue("actively_payment_division_02") === 1 ?
                              <div style={{ marginTop: '30px' }}>円</div> :
                              <div style={{ marginTop: '30px' }}>%</div>
                            }
                          </div>
                        </div>
                        <div className="box_inner_horizontal">
                          <Form.Item name='actively_payment_division_03' label='終了'>
                            <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('actively_payment_division_03', e, 'aggressive_price_rate_03')}>
                              <Select.Option value={0}>　</Select.Option>
                              <Select.Option value={1}>定額指定</Select.Option>
                              <Select.Option value={2}>定率指定</Select.Option>
                            </Select>
                          </Form.Item>
                          <div className="box_inner_horizontal">
                            <Form.Item name='aggressive_price_rate_03' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('aggressive_price_rate_03', e.target.value)}>
                              <Input className="input-size-number-7" />
                            </Form.Item>
                            {this.formRef.current?.getFieldValue("actively_payment_division_03") === 1 ?
                              <div style={{ marginTop: '30px' }}>円</div> :
                              <div style={{ marginTop: '30px' }}>%</div>
                            }
                          </div>
                        </div>
                        <Form.Item name='actively_remarks' label='備考' onBlur={(e) => this.handleFormChange('actively_remarks', e.target.value)}>
                          <Input className="input-size-30" />
                        </Form.Item>
                      </div>
                    </div>
                  </div> :
                  this.props.activeTab === '3' ?
                    <div className="box_container">
                      <div className="box_inner_horizontal">
                        <div className="box_inner_vertical">
                          <Form.Item name='support_with_fiduciary_duties_motive' label='動機付'>
                            <Select style={{ width: '90px' }} onChange={(e) => this.handleFormChange('support_with_fiduciary_duties_motive', e)}>
                              <Select.Option value={0}>対象外</Select.Option>
                              <Select.Option value={1}>対象</Select.Option>
                            </Select>
                          </Form.Item>
                          <div className="box_inner_horizontal">
                            <Form.Item name='motivation_unit_price' label='単価' onBlur={(e) => this.handleFormChange('motivation_unit_price', e.target.value)}>
                              <Input type="tel" className="input-size-number-7" />
                            </Form.Item>
                            <div style={{ marginTop: '30px' }}>円</div>
                          </div>
                          <Form.Item name='motivation_counter_burden_hikisa_f' label='初回時全額徴収方式'>
                            <Radio.Group onChange={(e) => this.handleFormChange('motivation_counter_burden_hikisa_f', e.target.value)}>
                              <Radio value={1}>有り</Radio>
                              <Radio value={0}>無し</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="box_inner_vertical">
                          <div className="box_inner_horizontal">
                            <Form.Item name='payment_division_motivation_01' label='初回'>
                              <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('payment_division_motivation_01', e, 'price_motivation_rate_01')}>
                                <Select.Option value={0}>　</Select.Option>
                                <Select.Option value={1}>定額指定</Select.Option>
                                <Select.Option value={2}>定率指定</Select.Option>
                              </Select>
                            </Form.Item>
                            <div className="box_inner_horizontal">
                              <Form.Item name='price_motivation_rate_01' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('price_motivation_rate_01', e.target.value)}>
                                <Input className="input-size-number-7" />
                              </Form.Item>
                              {this.formRef.current?.getFieldValue("payment_division_motivation_01") === 1 ?
                                <div style={{ marginTop: '30px' }}>円</div> :
                                <div style={{ marginTop: '30px' }}>%</div>
                              }
                            </div>
                          </div>
                          <div className="box_inner_horizontal">
                            <Form.Item name='payment_division_motivation_04' label='初①'>
                              <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('payment_division_motivation_04', e, 'price_motivation_rate_04')}>
                                <Select.Option value={0}>　</Select.Option>
                                <Select.Option value={1}>定額指定</Select.Option>
                                <Select.Option value={2}>定率指定</Select.Option>
                              </Select>
                            </Form.Item>
                            <div className="box_inner_horizontal">
                              <Form.Item name='price_motivation_rate_04' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('price_motivation_rate_04', e.target.value)}>
                                <Input className="input-size-number-7" />
                              </Form.Item>
                              {this.formRef.current?.getFieldValue("payment_division_motivation_04") === 1 ?
                                <div style={{ marginTop: '30px' }}>円</div> :
                                <div style={{ marginTop: '30px' }}>%</div>
                              }
                            </div>
                          </div>
                          <div className="box_inner_horizontal">
                            <Form.Item name='motivation_payment_division_02' label='終了'>
                              <Select style={{ width: '130px' }} onChange={(e) => this.handleFormChange('motivation_payment_division_02', e, 'price_motivation_rate_02')}>
                                <Select.Option value={'0'}>　</Select.Option>
                                <Select.Option value={"1"}>定額指定</Select.Option>
                                <Select.Option value={"2"}>定率指定</Select.Option>
                              </Select>
                            </Form.Item>
                            <div className="box_inner_horizontal">
                              <Form.Item name='price_motivation_rate_02' label=' ' {...formLableStyle} onBlur={(e) => this.handleFormChange('price_motivation_rate_02', e.target.value)}>
                                <Input className="input-size-number-7" />
                              </Form.Item>
                              {this.formRef.current?.getFieldValue("motivation_payment_division_02") === "1" ?
                                <div style={{ marginTop: '30px' }}>円</div> :
                                <div style={{ marginTop: '30px' }}>%</div>
                              }
                            </div>
                          </div>
                          <Form.Item name='motivation_remarks' label='備考' onBlur={(e) => this.handleFormChange('representative_trustee_name', e.target.value)}>
                            <Input className="input-size-30" />
                          </Form.Item>
                        </div>
                      </div>
                    </div> : null
            }
            <Form.Item>
              <div className="box_button_bottom_right" style={{ display: 'flex', gap: '20px' }}>
                <Button
                  type="primary"
                  // htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.setState({
                      flag: true
                    })
                    this.update()
                  }
                  }
                >
                  保存</Button>
              </div>
            </Form.Item>
          </Form >
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
            this.setState({ isloadfrm: false })
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1316600_ContractMasterMaintainEdit);