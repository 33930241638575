import DunningInputService from "services/SpecificInsureGuide/InsureGuideInput/DunningInputService";
import { message } from "antd";

const DunningInputAction = {
  getScreenData(data) {
    return DunningInputService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  update(data) {
    return DunningInputService.update(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
};

export default DunningInputAction;