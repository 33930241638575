import { message } from "antd";
import testActions from "pages/testActions/testActions";
import RefPeopleNumSettingInfoService from "services/SystemMaintenance/RefPeopleNumSettingInfo/RefPeopleNumSettingInfoService";

const RefPeopleNumSettingInfoAction = {
  getScreenData(params) {
    return RefPeopleNumSettingInfoService.getScreenData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  referenceNumPeopleSet(params) {
    return RefPeopleNumSettingInfoService.referenceNumPeopleSet(params)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveReferenceNumPeopleSet(params) {
    return RefPeopleNumSettingInfoService.saveReferenceNumPeopleSet(params)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getHistoryOn(params) {
    return RefPeopleNumSettingInfoService.getHistoryOn(params)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addHistoryOn(params) {
    return RefPeopleNumSettingInfoService.addHistoryOn(params)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteHistoryOn(params) {
    return RefPeopleNumSettingInfoService.deleteHistoryOn(params)
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default RefPeopleNumSettingInfoAction;