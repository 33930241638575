import React from "react";
import { connect } from "react-redux";
import  ModalDraggable  from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined } from "@ant-design/icons";

import { Card, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin } from "antd";


// import WS2677012_CaptureResult from "/pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2677012_CaptureResult"
import RequestCsvOutputAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/RequestCsvOutput.action";
import SetCsvCreateAction from "redux/basicInfo/SetInfoMaintain/SetCsvCreate.action";

import moment from "moment";
import axios from "configs/axios";

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },  
};


class WS2681001_ResultConfirmCsvOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'セットCSV作成';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingForm: false
    };

    this.onFinish = this.onFinish.bind(this)

    this.onGetScreenData = this.onGetScreenData.bind(this)
  }


  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      Output: '協会資格確認',
    })
  }
  
getScreenData() {
  this.setState({
    // isLoadingTable: true,
    // ItemNameList: []
  });

  let params = {
    ...this.formRef.current?.getFieldValue(),
  };

  RequestCsvOutputAction.getScreenData(params)
    .then((res) => {
      this.setState({       
      })
      this.formRef.current?.setFieldsValue({
        // dataTable: res ? res : []       
      })
    })
    .finally(() => { this.setState({ isLoadingTable: false }) })
}
  onGetScreenData = () => {
    
    this.setState({ isLoading: true });
    axios.get('/api/associate-insure-qualify-confirm/result-confirm-csv-output/getscreenData', {     
      params: {
          
      },      
    })
      .then(res => {
        this.formRef.current?.setFieldsValue({        
          // ErrorContent          :  res ? res.data.ErrorContent : '',            
        })
        message.info(res.data.message);

      })
      .catch(error => {       

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  Capture_F12(params) {
    this.setState({ isLoadingForm: true })
    axios.get('/api/associate-insure-qualify-confirm/association-consult-qualify-result-capture/Capture_F12', {
      params: {params},    
      })

      .then((res) => {

        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '70%',
            // component: (
            //   // <WS2677012_CaptureResult           
            //   //     Li_ReserveNonGetNum         ={this.res.Li_ReserveNonGetNum? this.res.Li_ReserveNonGetNum:''}  
            //   //   onFinishScreen={(output) => {                                  
            //   //       this.closeModal(); 
            //   //   }} 
            //   // />
            // ),                            
          },
        });   

        this.setState({ isLoadingForm: false })
      })     
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  onFinish(values) {
    let params = {
      ...values,
      // Output: values.Output + '.csv'
    }

    if (this.formRef.current?.getFieldValue('Output')) {
      console.log(params)
      this.setState({
        childModal: {
          visible: true,
          width: 300,
          component: (
            <div style={{ padding: '20px' }}>
              <Row>
                <Col span={4}>
                  <QuestionCircleOutlined style={{ fontSize: '32px', color: '#08c' }} />
                </Col>
                <Col span={20}>
                  <p style={{ marginLeft: '0.5em' }}>出力を実行しますか</p>
                </Col>
              </Row>
              <div style={{ marginTop: '1em', textAlign: 'center' }}>
                <Space>
                  <Button type="primary"
                    onClick={() => {
                      this.Capture_F12(params)
                      this.closeModal()
                    }}
                  >は　い</Button>
                  <Button type="primary"
                    onClick={() => {

                      this.closeModal()
                    }}>いいえ</Button>
                </Space>
              </div>
            </div>
          )
        }
      });
    } else {
      Modal.error({
        width: 300,
        title: '出力先を入力してください'
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="set-csv-create">
        <Card title="結果確認CSV出力">
          <Spin spinning={this.state.isLoadingForm}>
            <Form ref={this.formRef} onFinish={this.onFinish}
              initialValues={{
                SetSpecify: 0
              }}
            >             
              <Form.Item name="Output" label="出力先" {...grid}>
                <Input type="text" suffix=".csv"
                  onDoubleClick={() => {
                    console.log(this.formRef.current?.getFieldValue('Output'))
                    document.getElementById("idUpload").click()
                  }} />
              </Form.Item>
              <Upload id="idUpload"
                accept=".csv"
                beforeUpload={(file) => {
                  return new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      console.log(file)
                      this.formRef.current?.setFieldsValue({
                        Output: file.name
                      })
                    };
                  });
                }}>&emsp;
              </Upload>

              {/* <Button type="primary" style={{ float: "right" }} htmlType="submit"> 出 力 </Button> */}
              <Form.Item name="CSVButton">
                <Button type="primary" style={{ float: "right" }}
                  onClick={() =>this.onGetScreenData()}>
                  出 力
                </Button>   
              </Form.Item>   
            </Form>
          </Spin>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => { 
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2681001_ResultConfirmCsvOutput);
