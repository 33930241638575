import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Modal, Checkbox, message, Tooltip, Dropdown, Menu } from 'antd'
import { MoreOutlined, SaveOutlined } from '@ant-design/icons'
import IntroduceLetterExtractService from 'services/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtract'
import moment from 'moment'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx'
import WS0919001_IntroduceLetterMasterMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0919001_IntroduceLetterMasterMaintain.jsx'
import Color from 'constants/Color'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import FollowUpAction from 'redux/IntroductionLetter/FollowUp/FollowUp.action.js'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx'

class FollowUpInput extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '経過観察'

    this.state = {
      loading: false,
      dataSource: [],
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      personal_number_id: this.props.personal_number_id,
      kanji_name: this.props.kanji_name,
      visit_date: this.props.visit_date
    })
    this.forceUpdate()
    this.getCategoryJudges()
  }

  getCategoryJudges = () => {
    let params = {
      course_level: this.props.course_level,
      reservation_number: this.props.reservation_number
    }
    FollowUpAction.getCategoryJudges(params)
      .then(res => {
        this.formRef.current.setFieldsValue({
          dataSource: res,

          //input_dateとgrasp_stateはすべて同じため、０行目を反映させる
          input_date: res[0].input_date,
          grasp_state: res[0].grasp_state
        })
        this.setState({
          dataSource: res,

          //input_dateとgrasp_stateはすべて同じため、０行目を反映させる
          input_date: res[0].input_date,
          grasp_state: res[0].grasp_state
        })
      })
  }

  showWS2584019_PersonalInfoInquirySub = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={this.formRef.current?.getFieldValue('personal_number_id')}
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  showIntroduceLetterVariousMasterInquiry = (manageCode, value, codeField, contentField, index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_SearchChar={value}
            Li_ManageCode={manageCode}
            onFinishScreen={(output) => {
              let code = ''
              let content = ''

              code = output.recordData.various_codes
              if (manageCode == 4) {
                content = output.recordData.medical_institutions_short_name
              } else if (manageCode == 5) {
                content = output.recordData.sick_name
              } else if (manageCode == 8) {
                content = output.recordData.findings_content
              }
              this.updateDataSource(
                code,
                content,
                codeField,
                contentField,
                index
              )
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  updateDataSource = (code, content, codeField, contentField, index) => {
    let data = [...this.state.dataSource]

    let tempArr = []
    let obj = {}

    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[codeField] = code
        obj[contentField] = content
      }
      tempArr.push(obj)
    })

    this.formRef.current?.setFieldsValue({
      dataSource: tempArr
    })
    this.setState({
      dataSource: tempArr
    })
  }

  updateExamDate = (value, index) => {
    let data = [...this.state.dataSource]

    let tempArr = []
    let obj = {}

    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj['inspect_date'] = value
      }
      tempArr.push(obj)
    })

    this.formRef.current?.setFieldsValue({
      dataSource: tempArr

    })
    this.setState({
      dataSource: tempArr
    })
  }

  //対象のカラムのみ空にする
  deleteContent = (codeField, contentField, index) => {
    let data = [...this.state.dataSource]

    let tempArr = []
    let obj = {}

    data.forEach((item, i) => {
      obj = { ...item }
      if (i == index) {
        obj[codeField] = ''
        obj[contentField] = ''
      }
      tempArr.push(obj)
    })

    this.formRef.current?.setFieldsValue({
      dataSource: tempArr
    })
    this.setState({
      dataSource: tempArr
    })
  }

  save = () => {
    let params = {
      course_level: this.props.course_level,
      reservation_number: this.props.reservation_number,
      dataSource: this.state.dataSource,
      input_date: moment(this.formRef.current?.getFieldValue('input_date')).format('YYYY/MM/DD'),
      grasp_state: this.formRef.current?.getFieldValue('grasp_state')
    }
    FollowUpAction.saveFollowUpInput(params)
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  render = () => {
    return (
      <div className='follow-up-input' style={{ width: 1300 }}>
        <Card title='経過観察入力'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div className='box_search'>
                <div className='box_search_inner'>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='input_date'
                      label='　入力日'
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>
                    <Form.Item
                      name='grasp_state'
                    >
                      <Select
                        // size='small'
                        style={{ width: 120 }}
                        initialvalues={0}
                      >
                        <Select.Option key={0} value={0}>{'未把握'}</Select.Option>
                        <Select.Option key={1} value={1}>{'年内把握'}</Select.Option>
                        <Select.Option key={2} value={2}>{'翌年把握'}</Select.Option>
                        <Select.Option key={3} value={3}>{'対象外'}</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='personal_number_id'
                      label='個人番号'
                    >
                      <div>{this.formRef.current?.getFieldValue('personal_number_id')}</div>
                    </Form.Item>
                    <Form.Item>
                      <Tooltip title='個人情報照会'>
                        <Button
                          icon={<MoreOutlined />}
                          size='small'
                          onClick={() => {
                            this.showWS2584019_PersonalInfoInquirySub()
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                    <Form.Item
                      name='kanji_name'
                    >
                      <div>{this.formRef.current?.getFieldValue('kanji_name')}</div>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name='visit_date'
                    label='　受診日'
                    style={{ marginBottom: 0 }}
                  >
                    <div>{moment(this.formRef.current?.getFieldValue('visit_date')).format('YYYY/MM/DD')}</div>
                  </Form.Item>
                </div>
              </div>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content' }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              >
                <Table.Column
                  title='カテゴリ名称'
                  dataIndex='category_name'
                  className='column-size-40'
                />
                <Table.Column
                  title='判定'
                  dataIndex='category_judgment'
                  className='column-size-5'
                  render={(value) => (
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  )}
                />
                <Table.Column
                  title='精検日'
                  dataIndex=''
                  className='column-size-8'
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', index, 'inspect_date']}>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onBlur={(e) => { this.updateExamDate(moment(e.target.value).format('YYYY/MM/DD'), index) }}
                        />
                      </Form.Item>
                    )
                  }}
                />
                <Table.Column
                  title='医療機関名'
                  dataIndex=''
                  className='column-size-40'
                  render={(value, record, index) => {
                    return (
                      <div className='box_inner_horizontal'>
                        <Form.Item name={['dataSource', index, 'medical_institution_cd']}>
                          <Input.Search
                            className='input-search-size-number-allow-clear-5'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.deleteContent('medical_institution_cd', 'medical_institution_name', index)
                              } else {
                                this.showIntroduceLetterVariousMasterInquiry(4, value, 'medical_institution_cd', 'medical_institution_name', index)
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name={['dataSource', index, 'medical_institution_name']}>
                          <div>{this.state.dataSource[index].medical_institution_name}</div>
                        </Form.Item>
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='診断名'
                  dataIndex=''
                  className='column-size-40'
                  render={(value, record, index) => {
                    return (
                      <div className='box_inner_horizontal'>
                        <Form.Item name={['dataSource', index, 'diagnostic_cd']}>
                          <Input.Search
                            className='input-search-size-number-allow-clear-5'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.deleteContent('diagnostic_cd', 'diagnosis', index)
                              } else {
                                this.showIntroduceLetterVariousMasterInquiry(5, value, 'diagnostic_cd', 'diagnosis', index)
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name={['dataSource', index, 'diagnosis']}>
                          <div>{this.state.dataSource[index].diagnosis}</div>
                        </Form.Item>
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='指示内容'
                  dataIndex=''
                  className='column-size-40'
                  render={(value, record, index) => {
                    return (
                      <div className='box_inner_horizontal'>
                        <Form.Item name={['dataSource', index, 'instruction_cd']}>
                          <Input.Search
                            className='input-search-size-number-allow-clear-5'
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.deleteContent('instruction_cd', 'instruction_content', index)
                              } else {
                                this.showIntroduceLetterVariousMasterInquiry(8, value, 'instruction_cd', 'instruction_content', index)
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name={['dataSource', index, 'instruction_content']}>
                          <div>{this.state.dataSource[index].instruction_content}</div>
                        </Form.Item>
                      </div>
                    )
                  }}
                />
                {/* <Table.Column
                  align='center'
                  fixed='right'
                  width={45}
                  render={(value, record) => {
                    return (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => { this.showFollowUpInputEdit() }}
                      />
                    )
                  }}
                /> */}
              </Table>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => this.save()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpInput)
