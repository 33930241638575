import axios from "configs/axios"

const API_LIST = {
  index: '/api/introduce-letter-extract/inspect-input',
  save: '/api/introduce-letter-extract/inspect-input/save',
  delete: '/api/introduce-letter-extract/inspect-input/delete',
}

const InspectInputService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async save(params) {
    return axios.post(API_LIST.save, params)
  },
  async delete(params) {
    return axios.delete(API_LIST.delete, { params })
  },
}

export default InspectInputService
