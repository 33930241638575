import axios from 'configs/axios'

const API_LIST = {
  index: '/api/group-bookings/office-info-inquiry-sub',
}

const OfficeInfoInquirySubService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default OfficeInfoInquirySubService
