import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Table, Tabs, Col, Row, Space, TimePicker, InputNumber, Modal, message, Input, } from "antd";
import { PlusOutlined, SaveOutlined, DeleteOutlined, QuestionCircleOutlined, InfoCircleOutlined, } from "@ant-design/icons";
import BasicPeriodTimeService from "services/SystemMaintenance/BasicPeriodTime/BasicPeriodTimeService";
import moment from "moment";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ModalDraggable from "components/Commons/ModalDraggable";

const { TabPane } = Tabs;
class WS2697011_BasicPeriodTime extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "基本時間帯";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      isLoadingTable: false,
      isComfirmCreateNew: false,
      dataSource: []
    };
  }

  componentDidMount = () => {
    this.getCourseSpecificStardStyleSetting();
  };

  getCourseSpecificStardStyleSetting = () => {
    this.setState({ isLoadingTable: true });
    BasicPeriodTimeService.getBasicPeriodTimeService()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          BasicPeriodTimeDisplayList: res.data.map((item) => {
            return { ...item, timeline_at: moment(item.timeline_at, "HH:mm") };
          }),
        });

        this.setState({
          dataSource: res.data.map((item) => {
            return { ...item, timeline_at: moment(item.timeline_at, "HH:mm") };
          })
        })
        this.forceUpdate();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  };

  deleteBasicPeriodTime = (id) => {
    console.log("id", id);
    BasicPeriodTimeService.deleteBasicPeriodTimeService({ id })
      .then((res) => {
        message.success("成功");
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.getCourseSpecificStardStyleSetting();
      });
  };

  registerButtonSelectBasicPeriodTime = () => {
    const { StartTime, EndTime, TimeZoneInterval } =
      this.formRef.current.getFieldsValue();
    BasicPeriodTimeService.registerButtonSelectBasicPeriodTimeService({
      StartTime: moment(StartTime).format("HH:mm"),
      EndTime: moment(EndTime).format("HH:mm"),
      TimeZoneInterval: TimeZoneInterval,
    })
      .then((res) => {
        message.success("成功");
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.getCourseSpecificStardStyleSetting();
      });
  };

  saveAndUpdateBasicPeriodTime = (id) => {
    const { BasicPeriodTimeDisplayList } =
      this.formRef.current.getFieldsValue();
    let dataFormatToCreateOrUpdate = BasicPeriodTimeDisplayList.filter(
      (item) => {
        if (item != undefined && item.id === id) {
          return item;
        }
      }
    );
    if (id === "create_new") {
      dataFormatToCreateOrUpdate = [
        { ...dataFormatToCreateOrUpdate[0], id: "" },
      ];
    }
    BasicPeriodTimeService.saveAndUpdateBasicPeriodTimeService({
      id: dataFormatToCreateOrUpdate[0].id,
      timeline_at: moment(dataFormatToCreateOrUpdate[0].timeline_at).format(
        "HH:mm"
      ),
    })
      .then((res) => {
        message.success("成功");
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.getCourseSpecificStardStyleSetting();
      });
  };

  addBasicPeriodTime = (time) => {
    BasicPeriodTimeService.saveAndUpdateBasicPeriodTimeService({
      id: undefined,
      timeline_at: moment(time).format(
        "HH:mm"
      ),
    })
      .then((res) => {
        message.success("成功");
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.getCourseSpecificStardStyleSetting();
      });
  }

  confirmCreateNew = () => {
    const modal = Modal.confirm({
      title: "上書確認：確認事項",
      icon: <InfoCircleOutlined style={{ color: "orange" }} />,
      content: (
        <>
          <div>
            予約済みの時間を確認してください。業務に支障が出る可能性があります。変更してよろしいですか？
          </div>
          <Checkbox
            name="isComfirmCreateNew"
            defaultChecked={this.state.isComfirmCreateNew}
            onChange={() => {
              this.setState(
                {
                  isComfirmCreateNew: !this.state.isComfirmCreateNew,
                },
                () => {
                  modal.update({
                    okButtonProps: {
                      disabled: this.state.isComfirmCreateNew ? false : true,
                    },
                  });
                }
              );
            }}
          >
            内容を確認しました
          </Checkbox>
        </>
      ),
      okButtonProps: { disabled: true },
      okText: "はい",
      cancelText: "いいえ",
      onOk: () => {
        this.registerButtonSelectBasicPeriodTime();
        this.setState({
          isComfirmCreateNew: false,
        });
      },
      onCancel: () => {
        this.setState({
          isComfirmCreateNew: false,
        });
      },
    });
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div
        className="basic-period-time"
        style={{ width: '50%' }}
      >
        <Card
          title="基本時間帯"
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table bordered
              width={250}
              dataSource={this.state.dataSource}
              style={{ marginBottom: '1em' }}
              rowKey={(record) => record.id}
              pagination={false}
              loading={this.state.isLoadingTable}
            >
              <Table.Column
                align="center"
                title="時間帯"
                dataIndex="timeline_at"
                render={(text, record, index) => (
                  <>
                    <Form.Item
                      name={["BasicPeriodTimeDisplayList", index, "id"]}
                      style={{ marginBottom: "-5px" }}
                      hidden
                    >
                      <Input></Input>
                    </Form.Item>
                    <Form.Item
                      name={[
                        "BasicPeriodTimeDisplayList",
                        index,
                        "timeline_at",
                      ]}
                      style={{ marginBottom: "-5px" }}
                    >
                      {/* 決定ボタンを押したタイミングで保存 */}
                      <TimePicker
                        allowClear={false}
                        format="HH:mm"
                        inputReadOnly={true}
                        onChange={() => {
                          this.saveAndUpdateBasicPeriodTime(record.id);
                        }}
                      />
                    </Form.Item>
                  </>
                )}
              >
              </Table.Column>

              <Table.Column
                align="center"
                dataIndex="timeline_at"
                width={50}
                title={
                  // 新規追加
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      console.log("ポップアップ")
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 250,
                          className: '',
                          component:
                            <div className="tax-inquiry" >
                              <Form ref={this.formRef}>
                                <Card>
                                  <div className='box_inner_horizontal' style={{ marginTop: '40px', marginBottom: '10px', justifyContent: 'center' }}>
                                    <Form.Item name="apply_date_on" >
                                      <TimePicker
                                        allowClear={false}
                                        format="HH:mm"
                                        inputReadOnly={true}
                                        onChange={(time) => {
                                          this.addBasicPeriodTime(time)
                                          this.closeModal()
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </Card>
                              </Form>
                            </div >
                        },
                      });
                    }}
                  />
                }
                render={(text, record, index) => {
                  return (
                    <Space>
                      <Button
                        size="small"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            title: "確認",
                            icon: (
                              <QuestionCircleOutlined
                                style={{ color: "#1890ff" }}
                              />
                            ),
                            content: "削除しますか",
                            okText: "削除",
                            cancelText: "キャンセル",
                            onOk: () => {
                              this.deleteBasicPeriodTime(record.id);
                            },
                          });
                        }}
                      ></Button>
                    </Space>
                  );
                }}
              ></Table.Column>
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2697011_BasicPeriodTime);
