import axios from 'configs/axios'

const apiPaths = {
  index: '/api/examinee-list/auto-judge-screen',
  runAutoJudge: '/api/examinee-list/auto-judge-screen/run-auto-judge',
  comprehensiveJudgment: '/api/examinee-list/auto-judge-screen/comprehensive-judgment'
}

const AutoJudgeScreenService = {
  async index() {
    return axios.get(apiPaths.index)
  },

  async runAutoJudge(params) {
    return axios.post(apiPaths.runAutoJudge, params)
  },

  async comprehensiveJudgment(params) {
    return axios.post(apiPaths.comprehensiveJudgment, params)
  },
}

export default AutoJudgeScreenService
