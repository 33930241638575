import InsurerInfoSearchQueryService from 'services/basicInfo/ContractInfoMaintain/InsurerInfoSearchQueryService'
import { message, } from "antd"

const InsurerInfoSearchQueryAction = {
  getScreenData(params) {
    return InsurerInfoSearchQueryService.getScreenData(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        message.error(res.data.message)
      })
  },
  index(params) {
    return InsurerInfoSearchQueryService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました")
          return
        }
        message.error(res.data.message)
      })
  },
}
export default InsurerInfoSearchQueryAction
