import CalendarEmptySubService from 'services/ReservationBusiness/ReserveStatusSearch/CalendarEmptySubService'
import { message } from 'antd'

const CalendarEmptySubAction = {
  index() {
    return CalendarEmptySubService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  redisplay(data) {
    return CalendarEmptySubService.redisplay(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  getDataDetail(data) {
    return CalendarEmptySubService.getDataDetail(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
}

export default CalendarEmptySubAction
