import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { CopyOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { Card, Form, Input, Button, message, Modal } from "antd";

import Copy1543004Action from "redux/AdvancePreparation/DocumentManageMaintain/WS1543004_Copy.action";

class WS1543004_Copy extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    ManageNum: PropTypes.any,
    MaterialPrintingType: PropTypes.any,
    MaterialManageNum: PropTypes.any,
    MaterialManageName: PropTypes.any,
    Lo_StsRun: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '複写';

    this.state = {
    };

    this.setFormValue = this.setFormValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    this.setFormValue();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setFormValue();
    }
  }

  setFormValue() {
    this.formRef.current?.setFieldsValue({
      MaterialManageNumBefore: this.props.MaterialManageNum,
      MaterialManageNameBefore: this.props.MaterialManageName,
    })
  }

  onFinish(values) {
    let params = {
      ...values,
      Managedivision: this.props.Managedivision,
      Managetype: this.props.Managetype,
      id_ms1600: this.props.id_ms1600,
      id_ms1605: this.props.id_ms1605
    }

    if (values.MaterialManageNumBefore === values.MaterialManageNumAfter) {
      message.error('複写元と複写先が同じです')
    } else {
      Modal.confirm({
        width: "300px",
        content: "複写処理を実行しますか ?",
        okText: "は　い",
        cancelText: "いいえ",
        onOk: () => {
          Copy1543004Action.copyData(params)
            .then(() => {
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen({
                  Lo_StsRun: true
                });
              }
            })
        }
      })
    }
  }

  render() {
    return (
      <div className="copy">
        <Card title="複写">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_inner_vertical' style={{ padding: '0px', alignItems: 'center' }}>
              <div className='box_inner_vertical specific-box'>
                <span className='specific-title'>
                  複写元
                </span>

                <Form.Item name="MaterialManageNumBefore" label="管理番号">
                  <Input readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>

                <Form.Item name="MaterialManageNameBefore" label="資料名称">
                  <Input readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
              </div>

              <div>
                <ArrowDownOutlined style={{ fontSize: 24 }} />
              </div>

              <div className='box_inner_vertical specific-box'
                style={{ marginTop: 0, marginBottom: 0 }}>
                <span className='specific-title'>
                  複写先
                </span>

                <Form.Item name="MaterialManageNumAfter" label="管理番号" >
                  <Input className="input-size-number-5" />
                </Form.Item>

                <Form.Item name="MaterialManageNameAfter" label="資料名称">
                  <Input />
                </Form.Item>
              </div>

              <div className='box_button_bottom_right'
                style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  icon={<CopyOutlined />}
                  htmlType='submit'
                >
                  <span className='btn_label'>
                    複写
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1543004_Copy);
