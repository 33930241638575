import axios from 'configs/axios'
const apiPaths = {
  index: '/api/menu-customization/menu-customization',
  saveData: '/api/menu-customization/menu-customization/savedata',
  deleteData: '/api/menu-customization/menu-customization/deletedata',
  getMenuInfo: '/api/menu-customization/menu-customization/getMenuInfo',
}
const MenuCustomizationService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async saveData(params) {
    return axios.post(apiPaths.saveData, params)
  },
  async deleteData(params) {
    return axios.get(apiPaths.deleteData, { params })
  },
  async getMenuInfo(params) {
    return axios.get(apiPaths.getMenuInfo, { params })
  },
}

export default MenuCustomizationService
