/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, message, Radio, Tabs } from 'antd'
import { UnlockOutlined } from '@ant-design/icons';
import ApplicationInfoRegisterAction from 'redux/ReservationBusiness/PersonalReserveProcess/ApplicationInfoRegister.actions'
import WS0343001_PersonalInfoMaintain from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import hankakuMoji from 'components/Commons/HankakuMoji'
import * as wanakana from 'wanakana'

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 22 },
}
class WS2543001_ApplicationInfoRegister extends React.Component {
  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    Li_Data: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.KanaName = React.createRef()
    // document.title = '申込情報登録'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      deleteFlag: true,
      KanaName: '',
      userList: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.KanaName?.current?.focus()
    this.formRef.current?.setFieldsValue(this.props.Li_Data)
    this.GetScreenData()
  }

  GetScreenData() {
    ApplicationInfoRegisterAction.GetScreenData({
      Li_ReserveNum: this.isEmpty(this.props?.Li_ReserveNum) ? '' : this.props.Li_ReserveNum,
      MedicalCheckFlag: this.props.MedicalCheckFlag ? true : false
    })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          ...res,
          RelationshipList: res.RelationshipList,
          user: this.props.user.username ?? ''
        })
        this.setState({
          KanaName: this.formRef.current?.getFieldValue('KanaName'),
          userList: res.UserList
        })

        if (!res.reservationNumber == '') {
          this.setState({
            deleteFlag: false
          })
        }
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  PersonalRegister() {
    let data = {
      Li_ReserveNum: this.isEmpty(this.props?.Li_ReserveNum) ? 0 : this.props.Li_ReserveNum,
      Li_PersonalNum: this.isEmpty(this.props?.Li_PersonalNum) ? 0 : this.props.Li_PersonalNum,
      personalNumberId: this.isEmpty(this.formRef.current?.getFieldValue('personal_number_id')) ? '' : this.formRef.current?.getFieldValue('personal_number_id'),
      KanaName: this.isEmpty(this.formRef.current?.getFieldValue('KanaName')) ? '' : this.formRef.current?.getFieldValue('KanaName'),
      KanjiName: this.isEmpty(this.formRef.current?.getFieldValue('KanjiName')) ? '' : this.formRef.current?.getFieldValue('KanjiName'),
      Gender: this.isEmpty(this.formRef.current?.getFieldValue('Gender')) ? '' : this.formRef.current?.getFieldValue('Gender'),
      Relationship: this.isEmpty(this.formRef.current?.getFieldValue('Relationship')) ? '' : this.formRef.current?.getFieldValue('Relationship'),
      BirthDateDate: this.isEmpty(this.formRef.current?.getFieldValue('DateBirthChar')) ? '' : moment(this.formRef.current?.getFieldValue('DateBirthChar')).format('YYYY/MM/DD'),
      HomePhoneNum: this.isEmpty(this.formRef.current?.getFieldValue('HomePhoneNum')) ? '' : this.formRef.current?.getFieldValue('HomePhoneNum'),
      MobilePhoneNum: this.isEmpty(this.formRef.current?.getFieldValue('MobilePhoneNum')) ? '' : this.formRef.current?.getFieldValue('MobilePhoneNum'),
    }

    if (this.props.MedicalCheckFlag) {
      this.props.onFinishScreen(data)
    } else {
      ApplicationInfoRegisterAction.PersonalRegister(data)
        .then(res => {
          let PersonalNumId = res?.data?.PersonalNumId
          let Li_AttributeInfo = res?.data.Li_AttributeInfo
          this.showModalPersonalInfoMaintain_WS0343001(PersonalNumId, Li_AttributeInfo)
        })
        .catch(err => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  delete() {
    if (this.props.MedicalCheckFlag) {
      this.props.onDelete()
    }
  }

  Write() {
    let data = {
      Li_ReserveNum: this.isEmpty(this.props?.Li_ReserveNum) ? '' : this.props.Li_ReserveNum,
      KanaName: this.isEmpty(this.formRef.current?.getFieldValue('KanaName')) ? '' : this.formRef.current?.getFieldValue('KanaName'),
      KanjiName: this.isEmpty(this.formRef.current?.getFieldValue('KanjiName')) ? '' : this.formRef.current?.getFieldValue('KanjiName'),
      Gender: this.isEmpty(this.formRef.current?.getFieldValue('Gender')) ? '' : this.formRef.current?.getFieldValue('Gender'),
      Relationship: this.isEmpty(this.formRef.current?.getFieldValue('Relationship')) ? '' : this.formRef.current?.getFieldValue('Relationship'),
      BirthDateDate: this.isEmpty(this.formRef.current?.getFieldValue('DateBirthChar')) ? '' : moment(this.formRef.current?.getFieldValue('DateBirthChar')).format('YYYY/MM/DD'),
      HomePhoneNum: this.isEmpty(this.formRef.current?.getFieldValue('HomePhoneNum')) ? '' : this.formRef.current?.getFieldValue('HomePhoneNum'),
      MobilePhoneNum: this.isEmpty(this.formRef.current?.getFieldValue('MobilePhoneNum')) ? '' : this.formRef.current?.getFieldValue('MobilePhoneNum'),
    }

    ApplicationInfoRegisterAction.Write(data)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            data: this.formRef.current?.getFieldValue()
          })
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  showModalPersonalInfoMaintain_WS0343001(PersonalNumId, Li_AttributeInfo) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component:
          <WS0343001_PersonalInfoMaintain
            Li_PersonalNum={PersonalNumId}
            Li_AttributeInfo={Li_AttributeInfo}
            onFinishScreen={(output) => {

              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * カナ氏名を半角カナに変換して設定
   * @param {*} event
   */
  setKaneName = (event) => {
    let zenkaku_kana = wanakana.toKatakana(event.target.value)
    let hannkaku_kana = hankakuMoji(zenkaku_kana)
    this.formRef.current?.setFieldsValue({
      KanaName: hannkaku_kana
    })
    this.setState({
      KanaName: hannkaku_kana
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='application-info-register'>
        <Card title='直接入力'>
          <Form
            {...grid}
            autoComplete='off'
            ref={this.formRef}
          >
            {/* <Form.Item
              name='personal_number_id'
              label='ID'
            >
              <Input.Search
                className='input-search-size-number-allow-clear-10'
                type='text'
                onSearch={(value, event) => {
                  if (value !== '' || event.target.localName !== 'input') {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '85%',
                        component:
                          <WS0248001_PersonalInfoSearchQuery
                            date={moment(new Date()).format('YYYY/MM/DD')}
                            searchName={this.formRef.current?.getFieldValue('personal_number_id')}
                            onFinishScreen={(output) => {
                              this.formRef.current.setFieldsValue({
                                personal_number_id: output.recordData.personal_number_id,
                                KanaName: output.recordData.kana_name,
                                KanjiName: output.recordData.kanji_name,
                                Gender: output.recordData.sex,
                                DateBirthChar: output.recordData.birthday_on
                              })
                              this.closeModal()
                            }}
                          />
                      }
                    })
                  }
                }}
              />
            </Form.Item> */}

            <Form.Item
              name='KanaName'
              label='カナ氏名'
            >
              <Input
                style={{ width: 300 }}
                maxLength={40}
                ref={this.KanaName}
                onBlur={(event) => {
                  this.setKaneName(event)
                }}
                onPressEnter={(evennt) => {
                  this.setKaneName(event)
                }}
              />
            </Form.Item>

            <Form.Item
              name='KanjiName'
              label='漢字氏名'
            >
              <Input
                style={{ width: 300 }}
                maxLength={40} />
            </Form.Item>

            <Form.Item
              name='Gender'
              label='性別'
            >
              <Radio.Group>
                <Radio value={1}>男性</Radio>
                <Radio value={2}>女性</Radio>
              </Radio.Group>

            </Form.Item>

            <Form.Item
              name='DateBirthChar'
              label='生年月日'
            >
              <VenusDatePickerCustom
                formRefDatePicker={this.formRef}
                format='NNy/MM/DD' />
            </Form.Item>

            <Form.Item
              name='HomePhoneNum'
              label='自宅電話'
            >
              <Input
                maxLength={20}
                style={{ width: 150 }}
              />
            </Form.Item>

            <Form.Item
              name='MobilePhoneNum'
              label='携帯電話'
            >
              <Input
                maxLength={20}
                style={{ width: 150 }}
              />
            </Form.Item>

            <Form.Item
              name='Relationship'
              label='続柄'
            >
              <Select style={{ width: 100 }}>
                <Select.Option value=''></Select.Option>
                {this.formRef.current?.getFieldValue('RelationshipList')?.map(value => (
                  <Select.Option
                    key={'Relationship-' + Math.random()}
                    value={value.LinkedField}>
                    {value.DisplayField}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item
              name='user'
              label='担当者'
            >
              <Select style={{ width: '200px' }}>
                {this.state.userList.map((value) => {
                  return (
                    <Select.Option
                      key={value.id}
                      value={value.userId}
                    >
                      {value.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item> */}
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<UnlockOutlined />}
              style={{ display: this.state.deleteFlag ? 'none' : '' }}
              onClick={() => this.delete()} >
              <span className='btn_label'>
                解除
              </span>
            </Button>

            <Button
              type='primary'
              style={{ display: this.props.Li_ReserveNum > 0 ? '' : 'none' }}
              onClick={() => this.PersonalRegister()} >
              <span className='btn_label'>
                登録
              </span>
            </Button>

            <Button
              type='primary'
              style={{ display: this.props.MedicalCheckFlag ? 'none' : '' }}
              disabled={!this.state.KanaName}
              onClick={() => this.Write()} >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2543001_ApplicationInfoRegister)
