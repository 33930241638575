import { message } from 'antd'
import detailSubService from 'services/CooperationRelated/ElectronicMedicalRecordsLinkageFrameSet/detailSubService.js'

const detailSubAction = {
  index(data) {
    return detailSubService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },

  addNewData(data) {
    return detailSubService.addNewData(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  editData(data) {
    return detailSubService.editData(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  delete(data) {
    return detailSubService.delete(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default detailSubAction
