import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS1362003_PrintProcess from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1362003_PrintProcess";
import ModalDraggable from "components/Commons/ModalDraggable";

class WS1367002_MemberList extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '該当者一覧';

    this.state = {
      isLoading: false,
      selectedRowKeys: [],
      selectedRows: [],
      selectedIDCD: [],
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.setState({isLoading: true});
    let rowKeysList = [];
    this.props.dataSource?.map(item => {
      rowKeysList.push(item.id);
    })
    let listedIDCD = this.props.dataSource?.map(item => item.W1_id_cd);
    this.setState({
      dataSource: this.props.dataSource ? this.props.dataSource : [],
      selectedRowKeys: rowKeysList,
      selectedIDCD: listedIDCD,
      isLoading: false,
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="member-list">
        <Card title="該当者一覧">
          <Table
            dataSource={this.state.dataSource ? this.state.dataSource : []}
            loading={this.state.isLoading}
            pagination={false}
            scroll={{ x: '1200px', y: "calc(100vh - 300px)" }}
            rowKey={(record) => record.id}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: this.state.selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                  ...this.state.selectedRows,
                  selectedRows: selectedRows,
                  selectedRowKeys: selectedRowKeys,
                  selectedIDCD: selectedRows.map(item => item.W1_id_cd),
                });
              },
            }}
          >
            <Table.Column title="" dataIndex="" key="" />
            <Table.Column title="カナ氏名" dataIndex="kana_name" key="" showSorterTooltip={false} sorter={(a, b) => a.kana_name?.localeCompare(b.kana_name, 'ja')}/>
            <Table.Column title="漢字氏名" dataIndex="kanji_name" key="" showSorterTooltip={false} sorter={(a, b) => a.kanji_name?.localeCompare(b.kanji_name, 'ja')}/>
            <Table.Column title="年齢" dataIndex="W1_age" key="" showSorterTooltip={false} sorter={(a, b) => a.W1_age - b.W1_age}/>
            <Table.Column title="性" dataIndex="Expression_13" key="" showSorterTooltip={false} sorter={(a, b) => a.Expression_13?.localeCompare(b.Expression_13, 'ja')}/>
            <Table.Column title="続柄" dataIndex="name" key="" showSorterTooltip={false} sorter={(a, b) => a.name?.localeCompare(b.name, 'ja')}/>
            <Table.Column title="保険者" dataIndex="W1_insurers" key="" showSorterTooltip={false} sorter={(a, b) => a.W1_insurers - b.W1_insurers}/>
            <Table.Column title="保険者名" dataIndex="insurer_kanji_name" key="" showSorterTooltip={false} sorter={(a, b) => a.insurer_kanji_name?.localeCompare(b.insurer_kanji_name, 'ja')}/>
            <Table.Column title="事業所" dataIndex="W1_office" key="" showSorterTooltip={false} sorter={(a, b) => a.W1_office?.localeCompare(b.W1_office, 'ja')}/>
            <Table.Column title="事業所名" dataIndex="office_kanji_name" key="" showSorterTooltip={false} sorter={(a, b) => a.office_kanji_name?.localeCompare(b.office_kanji_name, 'ja')}/>
            <Table.Column title="受診日" dataIndex="W1_consult_date" key="" showSorterTooltip={false} sorter={(a, b) => a.W1_consult_date?.localeCompare(b.W1_consult_date, 'ja')}/>
            <Table.Column title="コース" dataIndex="W1_visits_courses" key="" showSorterTooltip={false} sorter={(a, b) => a.W1_visits_courses?.localeCompare(b.W1_visits_courses, 'ja')}/>
            <Table.Column title="受診コース" dataIndex="contract_short_name" key="" showSorterTooltip={false} sorter={(a, b) => a.contract_short_name?.localeCompare(b.contract_short_name, 'ja')}/>
          </Table>
          <Button 
            type="primary" 
            style={{float: 'right', marginTop: '10px'}}
            onClick={() => {
              this.setState({
                childModal: {
                  visible: true,
                  width: 1200,
                  component: (
                    <WS1362003_PrintProcess
                      dataSource={this.state.dataSource.filter(item => this.state.selectedRowKeys.includes(item.id))}
                      selectedIDCD={this.state.selectedIDCD ? this.state.selectedIDCD : []}
                      onFinishScreen={output => {
                        this.closeModal();
                      }}
                    />
                  )
                }
              })
            }}
          >
            出力
          </Button>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1367002_MemberList);
