import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Dropdown, Form, Input, Menu, Select, Space, Table, message, Modal, Tooltip } from 'antd'
import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import {
  getScreenDataCourseBasicTypeSettingAction,
  getCourseBasicTypeSettingAction,
  deleteCourseBasicTypeSettingAction,
  EnableCourseBasicTypeSettingAction,
  DisableCourseBasicTypeSettingAction
} from 'redux/InspectionMaintenance/CourseBasicTypeSetting/CourseBasicTypeSetting.actions'
import WS2696052_RegisterModification from './WS2696052_RegisterModification'
import WS2696008_ContractInfoList from './WS2696008_ContractInfoList'
import Color from 'constants/Color'
import WS2705001_AnotherInspectItemSettingCategory from '../V4KB0011000_AnotherInspectItemSettingCategory/WS2705001_AnotherInspectItemSettingCategory'
import WS0306001_ContractInfoMaintain from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0306001_ContractInfoMaintain'
import WS2702007_ReservesDisplayItemSetting from 'pages/SM_SystemMaintenance/V4SM0001000_ReservesDisplayItemSetting/WS2702007_ReservesDisplayItemSetting'
import ModalDraggable from 'components/Commons/ModalDraggable'
import Menubar from 'components/Commons/Menubar'
import { times } from 'lodash'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS2696036_CourseBasicTypeSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コース基本種別設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      initParams: {
        SearchChar: '',
        ReserveItem: '',
        Pattern: '',
        Enable_Disable: 'tru'
      },

      pagination: {
        // defaultPageSize: 10,
        size: 1,
        // showQuickJumper: true
      },

      dataSource: [],

      rowSelect: {},
      isLoading: true,
      current_page: 1,
      pageSize: 15
    }
  }

  componentDidMount = () => {
    this.loadInitData()
  }

  loadInitData = () => {
    getScreenDataCourseBasicTypeSettingAction()
      .then(res => {
        if (res) {
          this.setState({
            ReserveItem: res.data.ReserveItem,
            Pattern: res.data.Pattern
          })
          this.loadData(this.state.initParams)
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  loadData = () => {
    let params = {
      SearchChar: '',
      ReserveItem: 0,
      Pattern: '',
      Enable_Disable: 'all'
    }
    this.setState({ isLoading: true })
    getCourseBasicTypeSettingAction(params)
      .then(res => {
        if (res?.data) {
          let cloneRes = JSON.parse(JSON.stringify(res.data))
          cloneRes.forEach(item => {
            item.course_code = item.course_code ? item.course_code : ''
            item.course_name_short_name = item.course_name_short_name ? item.course_name_short_name : ''
            item.course_name_formal = item.course_name_formal ? item.course_name_formal : ''
            item.reservation_display_item_number = item.reservation_display_item_number ?? 0
            item.pattern_code = item.pattern_code ? item.pattern_code : ''
            item.judgment_level_division = item.judgment_level_division ? item.judgment_level_division : ''
            item.StandardStyle = item.StandardStyle ? item.StandardStyle : ''
            item.format_name = item.format_name ? item.format_name : ''
          })

          this.setState({
            dataSource: cloneRes,
            rowSelect: cloneRes.length > 0 ? cloneRes[0] : {}
          })
          this.formRef.current?.setFieldsValue({
            dataSourceMain: cloneRes
          })
          this.forceUpdate()
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => {
        this.SearchFilter('')
        this.setState({ isLoading: false })
      })
  }

  getDataThisComponent = (current = this.state.current_page, pageSize = this.state.pageSize) => {
    return this.state.dataSource.slice((current - 1) * pageSize, current * pageSize)
  }

  handleSearch = (value, name) => {
    this.setState(
      {
        initParams: {
          ...this.state.initParams,
          [name]: value
        }
      },
      () => this.loadData(this.state.initParams)
    )
  }

  ReturnComponent = component => {
    let components = {
      WS2705001_AnotherInspectItemSettingCategory,
      WS2702007_ReservesDisplayItemSetting,
      WS0306001_ContractInfoMaintain,
      WS2696052_RegisterModification,
      WS2696008_ContractInfoList
    }
    return components[component]
  }

  callModal = (record, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            // {...props}
            record={record}
            ReserveItem={this.state.ReserveItem}
            Li_Flag={1}
            onFinishScreen={(output) => {
              this.closeModal()
              if (!output.Li_newFlag) {
                console.log(output)
                const index = this.state.dataSource.findIndex(v => v.id === output.Li_newData[0].id)
                const newData = [...this.state.dataSource]
                newData.splice(index, 1, output.Li_newData[0])
                this.setState({
                  dataSource: newData
                })
              } else {
                this.loadData()
              }
              this.SearchFilter('')
            }}
          />
        )
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  SearchFilter = (value, text) => {

    // 検索入力 負荷軽減
    setTimeout(() => {
      mainfunction()
    }, 500);

    const mainfunction = () => {

      try {
        let dataSource = [...this.state.dataSource]

        if (isNaN(value) && value.replace('　', ' ').trim() === '' && (text === undefined || text == '')) {
          this.formRef.current?.setFieldsValue({
            dataSourceMain: dataSource
          })
        } else {
          const SearchChar = this.formRef.current?.getFieldValue('SearchChar') ? this.formRef.current?.getFieldValue('SearchChar') : ''
          const ReserveItem = this.formRef.current?.getFieldValue('ReserveItem') ? this.formRef.current?.getFieldValue('ReserveItem') : ''
          const Pattern = this.formRef.current?.getFieldValue('Pattern') ? this.formRef.current?.getFieldValue('Pattern') : ''
          const Enable_Disable = this.formRef.current?.getFieldValue('Enable_Disable') ? this.formRef.current?.getFieldValue('Enable_Disable') : ''

          if (ReserveItem !== '') {
            dataSource = dataSource.filter((record) => {
              if (record.reservation_display_item_number === ReserveItem) {
                return record
              }
            })
          }

          if (Pattern !== '') {
            if (Pattern !== 'all') {
              dataSource = dataSource.filter((record) => {
                if (record.pattern_code === Pattern) {
                  return record
                }
              })
            }
          }

          if (Enable_Disable) {
            if (Enable_Disable === 'tru') {
              dataSource = dataSource.filter((record) => {
                if (record.medical_exam_dates !== 0) {
                  return record
                }
              })

            } else if (Enable_Disable === 'fal') {
              dataSource = dataSource.filter((record) => {
                if (record.medical_exam_dates === 0) {
                  return record
                }
              })
            }
          }

          // 検索ボックス入力のみ動作
          if (SearchChar) {

            dataSource = dataSource.filter((record) => {

              const reserve = this.state.ReserveItem.filter(item => item.value.includes(value))

              if (record.course_code.indexOf(value) > -1
                || record.course_name_short_name.indexOf(value) > -1
                || record.course_name_formal.indexOf(value) > -1
                || reserve.length > 0 && reserve[0].key == record.reservation_display_item_number
                || this.getIdentification(record.identification).indexOf(value) > -1
                || record.specimen_label_number == value
                || record.medical_exam_dates == value
                || record.pattern_code.indexOf(value) > -1
                || record.judgment_level_division.indexOf(value) > -1
                || record.StandardStyle.indexOf(value) > -1
                || record.format_name.indexOf(value) > -1
              ) {
                return record
              }

            })
          }

          this.formRef.current?.setFieldsValue({
            dataSourceMain: dataSource
          })

        }

        this.forceUpdate()

      } catch (error) {
        console.log(error)
      }

    }

  }

  eventF4 = () => {
    this.callModal(
      {
        Li_CourseCode: '',
        newFlag: 1
      },
      500,
      'WS2696052_RegisterModification'
    )
  }

  eventF9 = () => this.callModal(null, '80%', 'WS2702007_ReservesDisplayItemSetting')

  eventF10 = () => this.callModal(1, '80%', 'WS2705001_AnotherInspectItemSettingCategory')

  eventF11 = () => this.callModal(null, '80%', 'WS0306001_ContractInfoMaintain')


  getIdentification = (value) => {
    const identification = [
      '',
      'ドック',
      '協会',
      '定期',
      '特定',
      '単独'
    ]
    return identification[value]
  }

  render() {
    const dataSource = this.formRef.current?.getFieldValue('dataSourceMain')
    const pagination = this.state.pagination
    return (
      <div className='course-basic-type-setting'>
        <Card title='コース基本種別設定' className='mb-2'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.eventF9}>
                      予約項目
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.eventF10}>
                      ｶﾃｺﾞﾘ別検査
                    </Menu.Item>
                    <Menu.Item key='3' style={{ width: '100%' }} onClick={this.eventF11}>
                      契約登録
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={this.state.initParams}>
            <div className='mt-3'>
              <Space>
                <Form.Item name='SearchChar' label='検索' style={{ marginRight: '10px', marginBottom: 0 }}>
                  <Input
                    style={{ width: 300 }}
                    maxLength={50}
                    onChange={e => this.SearchFilter(e.target.value, 'SearchChar')}
                  />
                </Form.Item>
                <Form.Item name='ReserveItem' label='予約項目' style={{ marginRight: '10px', marginBottom: 0 }}>
                  <Select
                    style={{ width: 120 }}
                    onChange={value => this.SearchFilter(value, 'ReserveItem')}
                  >
                    {this.state.ReserveItem?.map((item, index) => (
                      <Select.Option key={index} value={item.key}>
                        {item.key === 0 ? '　' : item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name='Pattern' label='パターン' style={{ marginRight: '10px', marginBottom: 0 }}>
                  <Select
                    style={{ width: 120 }}
                    onChange={value => this.SearchFilter(value, 'Pattern')}
                  >
                    <Select.Option value='all'>　　</Select.Option>
                    {this.state.Pattern?.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name='Enable_Disable' label='有効/無効' style={{ marginRight: '10px', marginBottom: 0 }}>
                  <Select
                    style={{ width: 75 }}
                    // defaultValue='tru'
                    // initialvalues='tru'
                    onChange={value => this.SearchFilter(value, 'Enable_Disable')}
                  >
                    <Select.Option value='all'>　　</Select.Option>
                    <Select.Option value='tru'>有効</Select.Option>
                    <Select.Option value='fal'>無効</Select.Option>
                  </Select>
                </Form.Item>
              </Space>
            </div>

            <Table
              bordered
              size='small'
              className='mt-3'
              dataSource={dataSource}
              pagination={false}
              loading={this.state.isLoading}
              rowKey={record => record.id}
              sortDirections={['ascend', 'descend', 'ascend']}
              scroll={{ x: 'max-content', y: resizableTableScroll(40) }}
              // pagination={false}
              rowClassName={(record, index) =>
                record.id === this.state.rowSelect.id ? 'hightlight-row-selected' : ''
              }
              onRow={(record, index) => ({
                onClick: event => this.setState({ rowSelect: record }),
                onDoubleClick: () =>
                  this.callModal({ Li_CourseCode: record, Li_Action: 'edit', newFlag: 0 }
                    , 500,
                    'WS2696052_RegisterModification')
              })}
            >
              <Table.Column
                className='column-size-2'
                title='ｺｰｽ'
                dataIndex='course_code'
                width={80}
                sorter={(a, b) => a.course_code.localeCompare(b.course_code, 'jp')}
                render={(text, record) => (
                  // <Input
                  //   readOnly={record.Expression_8}
                  //   bordered={false}
                  //   value={text}
                  //   style={{ color: Color(record?.expression)?.Foreground }}
                  // />
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              />
              <Table.Column
                className='column-size-40'
                title='コース略称'
                dataIndex='course_name_short_name'
                sorter={(a, b) => a.course_name_short_name.localeCompare(b.course_name_short_name, 'jp')}
                render={(text, record) => (
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              />
              <Table.Column
                className='column-size-40'
                title='コース名称'
                dataIndex='course_name_formal'
                sorter={(a, b) => a.course_name_formal.localeCompare(b.course_name_formal, 'jp')}
                render={(text, record) => (
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              />
              <Table.Column
                className='column-size-10'
                title='予約項目'
                dataIndex='reservation_display_item_number'
                sorter={(a, b) => a.reservation_display_item_number - b.reservation_display_item_number}
                render={(text, record) => {
                  let itemName = record.reservation_display_item_number ? this.state.ReserveItem.find(v => v.key === record.reservation_display_item_number)?.value : ''
                  return <div style={{ color: Color(record?.expression)?.Foreground }}>{itemName}</div>
                }}
              />
              <Table.Column
                className='column-size-10'
                title='識別'
                dataIndex='identification'
                sorter={(a, b) => a.identification - b.identification}
                render={(value, record) => {
                  return (
                    <div>{this.getIdentification(value)}</div>
                  )
                }}
              />
              <Table.Column
                className='column-size-5'
                title='ラベル'
                align='center'
                dataIndex='specimen_label_number'
                sorter={(a, b) => a.specimen_label_number - b.specimen_label_number}
                render={(text, record) => (
                  <div style={{ textAlign: 'center', color: Color(record?.expression)?.Foreground }}>
                    {text === 0 ? null : text}
                  </div>
                )}
              />
              <Table.Column
                className='column-size-5'
                title='日数'
                align='center'
                dataIndex='medical_exam_dates'
                sorter={(a, b) => a.medical_exam_dates - b.medical_exam_dates}
                render={(text, record) => (
                  <div style={{ textAlign: 'center', color: Color(record?.expression)?.Foreground }}>
                    {text === 0 ? null : text}
                  </div>
                )}
              />
              <Table.Column
                className='column-size-10'
                title='パターン'
                dataIndex='pattern_code'
                sorter={(a, b) => a.pattern_code.localeCompare(b.pattern_code, 'jp')}
                render={(text, record) => (
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              />
              <Table.Column
                className='column-size-40'
                title='判定ﾚﾍﾞﾙ'
                dataIndex='judgment_level_division'
                sorter={(a, b) => a.judgment_level_division.localeCompare(b.judgment_level_division, 'jp')}
                render={(text, record) => (
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              />
              <Table.Column
                className='column-size-5'
                title='様式'
                dataIndex='StandardStyle'
                sorter={(a, b) => a.StandardStyle - b.StandardStyle}
                render={(text, record) => (
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              // sortDirections={['ascend', 'descend', 'ascend']}
              // sorter={(a, b) => a.StandardStyle.localeCompare(b.StandardStyle)}
              />
              <Table.Column
                className='column-size-40'
                title='様式名'
                dataIndex='format_name'
                sorter={(a, b) => a.format_name.localeCompare(b.format_name, 'jp')}
                render={(text, record) => (
                  <div style={{ color: Color(record?.expression)?.Foreground }}>{text}</div>
                )}
              />
              <Table.Column
                className='column-size-2'
                align='center'
                fixed='right'
                title={
                  <Button
                    type='primary'
                    size='small'
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={this.eventF4}>
                  </Button>
                }
                render={(value, record, index) => (
                  <Dropdown
                    size='small'
                    trigger='click'
                    overlay={() => (
                      <Menu >
                        <Menu.Item
                          key='1'
                          onClick={() => {
                            console.log('_________record', record)
                            this.callModal(
                              {
                                Li_Action: 'edit', // edit and save at screen WS2696052_RegisterModification
                                Li_CourseCode: record,
                                ReserveItem: this.state.ReserveItem,
                                Pattern: this.state.Pattern
                                // Li_CourseCode: record.course_code
                              },
                              500,
                              'WS2696052_RegisterModification'
                            )
                          }
                          }
                        >
                          修正
                        </Menu.Item>
                        <Menu.Item
                          key='2'
                          onClick={() => {
                            const params = {
                              id: record.id,
                              course_code: record
                            }
                            Modal.confirm({
                              content: '削除を行いますか',
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                deleteCourseBasicTypeSettingAction(params)
                                  .then(() => {
                                    message.success('正常に削除されました')
                                    this.loadData(this.state.initParams)
                                  })
                                  .catch(() => message.error('契約に使用中のコースは削除できません。'))
                              }
                            })
                          }}
                        >
                          削除
                        </Menu.Item>
                        <Menu.Item
                          key='3'
                          onClick={() => {
                            let content =
                              record.medical_exam_dates === 0
                                ? 'コースを有効にします。よろしいですか？'
                                : 'コースを無効にします。よろしいですか？'
                            Modal.confirm({
                              content: content,
                              okText: 'は い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                if (record.medical_exam_dates === 0) {
                                  EnableCourseBasicTypeSettingAction({ id: record.id })
                                    .then(() => this.loadData(this.state.initParams))
                                    .catch(() => message.error('処理中にエラーが発生しました'))
                                } else {
                                  DisableCourseBasicTypeSettingAction({ id: record.id })
                                    .then(() => this.loadData(this.state.initParams))
                                    .catch(() => message.error('処理中にエラーが発生しました'))
                                }
                              }
                            })
                          }}
                        >
                          {record.medical_exam_dates === 0 ? '有効化' : '無効化'}
                        </Menu.Item>
                        {/* ALPHASALUS-1673でコメントアウト */}
                        {/* <Menu.Item
                          key='4'
                          onClick={() =>
                            this.callModal(
                              {
                                record
                              },
                              900,
                              'WS2696008_ContractInfoList'
                            )
                          }
                        >
                          使用契約
                        </Menu.Item> */}
                      </Menu>
                    )}>
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )}
              />
            </Table>
            {/* <Form.Item>
              <Input.TextArea
                maxLength={200}
                readOnly
                autoSize={{ minRows: 2, maxRows: 2 }}
                value={this.state.rowSelect.option_remark}
              />
            </Form.Item> */}
            {/* <Pagination
              size='small'
              hideOnSinglePage={this.state.dataSource.length > 15 ? false : true}
              style={{ margin: '10px 0', textAlign: 'right' }}
              total={this.state.dataSource.length}
              pageSize={this.state.pageSize}
              current={this.state.current_page}
              pageSizeOptions={[10]}
              onChange={page => {
                this.setState({ current_page: page }, () => this.getDataThisComponent(page))
              }}
            /> */}
          </Form>
        </Card>
        <ModalDraggable
          key={parseInt(Date.now)}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS2696036_CourseBasicTypeSetting)
