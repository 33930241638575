import axios from 'configs/axios'

const APP_LIST = {
  getScreenData: `/api/specific-medical-exam-settle-process/consult-ticket-input-sub`,
  onSave: `/api/specific-medical-exam-settle-process/consult-ticket-input-sub/on-save`,
  contractSelect: `/api/specific-medical-exam-settle-process/consult-ticket-input-sub/contractselect`,
  DetailedInspectionData: `/api/specific-medical-exam-settle-process/consult-ticket-input-sub/detailed-inspection-data`,
  AdditionalMedicalExamination: `/api/specific-medical-exam-settle-process/consult-ticket-input-sub/additional-medical-examination`,
  ConcurrentMedicalExaminations: `/api/specific-medical-exam-settle-process/consult-ticket-input-sub/concurrent-medical-examinations`,
}
const ConsultTicketInputSubService = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params })
  },
  async contractSelect(params) {
    return axios.post(APP_LIST.contractSelect, params)
  },
  async onSave(params) {
    return axios.post(APP_LIST.onSave, params)
  },
  async DetailedInspectionData(params) {
    return axios.get(APP_LIST.DetailedInspectionData, { params })
  },
  async AdditionalMedicalExamination(params) {
    return axios.get(APP_LIST.AdditionalMedicalExamination, { params })
  },
  async ConcurrentMedicalExaminations(params) {
    return axios.get(APP_LIST.ConcurrentMedicalExaminations, { params })
  },
}

export default ConsultTicketInputSubService
