import axios from 'configs/axios';

const apiPaths = {
  addBillingAddress: '/api/contract-info-maintain/add-billing-address/add-billing-address',
  addBillingAddress_reservation: '/api/contract-info-maintain/add-billing-address/add-billing-address-reservation',
  loadTableData: '/api/contract-info-maintain/add-billing-address/load-table-data',
  deleteAddress: '/api/contract-info-maintain/add-billing-address/delete-address',
  deleteBillingAddress: '/api/contract-info-maintain/add-billing-address/delete-billing-address',
  saveBillingAmount: '/api/contract-info-maintain/add-billing-address/save-billing-amount',
  loadPersonalReserveData: '/api/contract-info-maintain/add-billing-address/load-personal-reserve-data',
  reacquisition: '/api/contract-info-maintain/add-billing-address/reacquisition',
  savePersonalBillingAmount: '/api/contract-info-maintain/add-billing-address/save-personal-billing-amount',
  addAddressToBA: '/api/contract-info-maintain/add-billing-address/add-address-to-ba',
  deleteAddressFromBA: '/api/contract-info-maintain/add-billing-address/delete-address-from-ba',
}

const BillingAddressSettingService = {
  async addBillingAddress(params) {
    return axios.post(apiPaths.addBillingAddress, params)
  },

  async addBillingAddress_reservation(params) {
    return axios.post(apiPaths.addBillingAddress_reservation, params)
  },

  async loadTableData(params) {
    return axios.get(apiPaths.loadTableData, { params })
  },

  async deleteAddress(params) {
    return axios.post(apiPaths.deleteAddress, params)
  },

  async deleteBillingAddress(params) {
    return axios.post(apiPaths.deleteBillingAddress, params)
  },

  async saveBillingAmount(params) {
    return axios.post(apiPaths.saveBillingAmount, params)
  },

  async loadPersonalReserveData(params) {
    return axios.post(apiPaths.loadPersonalReserveData, params)
  },

  async reacquisition(params) {
    return axios.post(apiPaths.reacquisition, params)
  },

  async savePersonalBillingAmount(params) {
    return axios.post(apiPaths.savePersonalBillingAmount, params)
  },

  async addAddressToBA(params) {
    return axios.post(apiPaths.addAddressToBA, params)
  },

  async deleteAddressFromBA(params) {
    return axios.post(apiPaths.deleteAddressFromBA, params)
  },
}
export default BillingAddressSettingService