import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Checkbox, Button, Table, Dropdown, Modal, message, Menu, Tooltip, } from 'antd'
import { SearchOutlined, MoreOutlined, MenuOutlined, PlusOutlined, } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import status_input_lock from 'assets/img/status_input_lock.png'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS0735028_DataUpdateConfirm from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0735028_DataUpdateConfirm.jsx'
import locale from 'antd/es/date-picker/locale/ja_JP'
import WS0456001_SpreadsheetInputSetting from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0456001_SpreadsheetInputSetting.jsx'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import SpreadInputService from 'services/InputBusiness/SpreadInput/SpreadInputService'
import moment from 'moment'
import WS0274001_InspectCmtSearchQuery from './WS0274001_InspectCmtSearchQuery'
import WS0728001_FindingsInputNormal from './WS0728001_FindingsInputNormal'
import WS0729001_FindingsInputRadiography from './WS0729001_FindingsInputRadiography'
import WS0730001_FindingsInputPhysiciiagnosis from './WS0730001_FindingsInputPhysiciiagnosis'
import WS0731001_FindingsInputNormalSelect from './WS0731001_FindingsInputNormalSelect'
import WS2583001_ConsultInquirySub from '../V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import { download_file } from 'helpers/CommonHelpers'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import Recalculate from 'components/Commons/recalculate'

const labelCol = {
  labelCol: { style: { width: 50 } },
}
class WS0735001_SpreadInput extends React.Component {
  formRef = React.createRef()
  inputRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'スプレッド入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataScreen: {},
      objParent: [],
      ReceiptNumF: null,
      ReceiptNumT: null,
      FacilityType: null,
      SheetStyle: null,
      dataSource: [],
      isLoadingTable: false,
      messCallMs: {},
      DateFDate: '',
      dataDisplay: {},
      loadingForm: false,
      messZoomProcessNOTShowMs: {},
      setIdx: '',
      setDisPlay: false,
      setOnBlur: false,
      changeFlag: false,
      buttonFlag: true,
      changeData: [],
      dt0430ChangeData: [],
      changeId: [],
      changeDB: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      conditionAddData: null,
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  async getScreenData() {
    await SpreadInputService.GetScreenData()
      .then((res) => {
        if (res) {
          this.setState({ dataScreen: res.data })
          this.setState({
            ReceiptNumF: res.data.ReceiptNumF,
            ReceiptNumT: res.data.ReceiptNumT,
            SheetStyle: res.data.SheetStyle,
            FacilityType: res.data.FacilityType,
            DateFDate: res.data.DateFDate,
          })
          this.formRef.current?.setFieldsValue({
            FacilityType: res.data.FacilityType[0].LinkedField,
            SheetStyle: res.data.SheetStyle[0].LinkedField,
            DateF: moment(res.data.DateF)
          })
          if (this.state.setDisPlay) {
            this.displayBtn()
          }
          this.setState({ setDisPlay: false })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  async displayBtn() {
    this.setState({ loadingForm: true })
    const data = {
      DateFDate: this.formRef.current?.getFieldValue('DateF') ? this.formRef.current?.getFieldValue('DateF').format('YYYY/MM/DD') : '',
      DateTDate: this.formRef.current?.getFieldValue('DateF')
        ? this.formRef.current?.getFieldValue('DateF').format('YYYY/MM/DD')
        : '',
      ReceiptNumF: this.formRef.current?.getFieldValue('ReceiptNumF'),
      ReceiptNumT: this.formRef.current?.getFieldValue('ReceiptNumT'),
      CourseCodeF: this.formRef.current?.getFieldValue('CourseCodeF')
        ? this.formRef.current?.getFieldValue('CourseCodeF')
        : '',
      FacilityType: this.formRef.current.getFieldValue('FacilityType')
        ? this.formRef.current.getFieldValue('FacilityType')
        : this.state.FacilityType[0].LinkedField
          ? this.state.FacilityType[0].LinkedField
          : 0,
      KeyNum: this.state.dataScreen.KeyNum,
      DataSourceName: this.state.dataScreen.DataSourceName,
      SheetStyle: this.formRef.current?.getFieldValue('SheetStyle')
        ? this.formRef.current?.getFieldValue('SheetStyle')
        : this.state.SheetStyle[0].LinkedField
          ? this.state.SheetStyle[0].LinkedField
          : 0,
      InspectCode: this.state.dataScreen.InspectCode,
      AllCasesExtractOp: this.formRef.current?.getFieldValue('AllCasesExtractOp') ? 1 : 0,
      InspectHasOnlyOp: this.formRef.current?.getFieldValue('InspectHasOnlyOp') ? 1 : 0,
      InputProtectionNumOp: this.state.dataScreen.InputProtectionNumOp,
      InputProtectionImagePath: this.state.dataScreen.InputProtectionImagePath
        ? this.state.dataScreen.InputProtectionImagePath
        : '',
      SideScrollingYes: this.state.dataScreen.SideScrollingYes ? 1 : 0,
      ChangeAfterFont: this.state.dataScreen.ChangeAfterFont,
      InvalidColor: this.state.dataScreen.InvalidColor,
      conditionAddData: this.state.conditionAddData,
    }
    await SpreadInputService.Display(data)
      .then((res) => {
        if (res) {
          if (res.data.message === '変更内容を破棄しますか') {
            Modal.confirm({
              content: '変更内容を破棄しますか',
              okText: 'いいえ',
              cancelText: 'は　い',
              width: 260,
              onOk: () => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: '15%',
                    component: (
                      <WS0735028_DataUpdateConfirm
                        SheetStyle={
                          this.formRef.current?.getFieldValue('SheetStyle')
                            ? this.formRef.current?.getFieldValue('SheetStyle')
                            : this.state.dataScreen?.SheetStyle[0].LinkedField
                        }
                        onFinishScreen={(output) => {
                          if (output.Lo_Confirm === false) {
                            this.getScreenData()
                            // this.getListData()
                            this.setState({ setDisPlay: true, dataSource: [] })
                          }
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              },
              onCancel: () => {
                this.setState({ setDisPlay: true, dataSource: [] })
                this.getScreenData()
              },
            })
          } else {
            this.setState({ dataDisplay: res.data, dataSource: [] })
          }
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        this.setState({ loadingForm: false })
      })
  }

  getListData(changeFlag) {
    // 受診日が設定されていない場合はエラーメッセージ
    if (!this.formRef.current?.getFieldValue('DateF') && !(this.state.conditionAddData?.DateFChar ?? false)) {
      message.error('受診日が設定されていません')
      return
    }

    const data = {
      ChangeAfterFont: this.state.dataScreen.ChangeAfterFont,
      InspectUnregisteredColor: this.state.dataDisplay.InspectUnregisteredColor,
      Li_FindingsDedicated: this.state.dataDisplay.Li_FindingsDedicated,
      Li_InspectItemList: this.state.dataDisplay.Li_InspectItemList,
      Li_InspectShortNameList: this.state.dataDisplay.Li_InspectShortNameList,
      Li_InspectTypeList: this.state.dataDisplay.Li_InspectTypeList,
      InsurerCode: this.state.output ? this.state.output.InsurerCode : null,
      StateFlag: this.state.output ? this.state.output.StateFlag : null,
      Li_AutomaticCalculateList: this.state.dataDisplay.Li_AutomaticCalculateList,
      DateF: this.formRef.current?.getFieldValue('DateF') ? this.formRef.current?.getFieldValue('DateF').format('YYYY/MM/DD') : '',
      SheetStyle: this.formRef.current?.getFieldValue('SheetStyle'),
      Li_InspectCmtScreen: '',
      AllCasesExtractOp: this.formRef.current?.getFieldValue('AllCasesExtractOp') ? true : false,
      LeadershipMattersHowToAdd: this.state.dataScreen.LeadershipMattersHowToAdd,
      ReceiptNumF: this.formRef.current?.getFieldValue('ReceiptNumF'),
      ReceiptNumT: this.formRef.current?.getFieldValue('ReceiptNumT'),
      FacilityType: this.formRef.current.getFieldValue('FacilityType')
        ? this.formRef.current.getFieldValue('FacilityType')
        : this.state.FacilityType[0].LinkedField
          ? this.state.FacilityType[0].LinkedField
          : 0,
      CourseCodeF: this.formRef.current?.getFieldValue('CourseCodeF') ? this.formRef.current?.getFieldValue('CourseCodeF') : '',
      InspectHasOnlyOp: this.formRef.current?.getFieldValue('InspectHasOnlyOp') ? 1 : 0,
      KeyNum: this.state.dataScreen.KeyNum,
      DataSourceName: this.state.dataScreen.DataSourceName,
      conditionAddData: this.state.conditionAddData,
    }
    SpreadInputService.listData(data)
      .then((res) => {
        if (res) {
          let SheetStyle = data.SheetStyle
          let buttonFlag = true
          let tableColumn = res.data.tableColumn
          let numKensaFlag = false
          // 更新ボタンを出すか出さないかを判定
          for (let i = 0; i < res.data.tableColumn.length; i++) {
            if (tableColumn[i].exam_type !== 'S' && tableColumn[i].exam_type !== 'J') {
              buttonFlag = false
            } else {
              numKensaFlag = true
            }
          }

          if (changeFlag) {
            this.setState({
              dataSource: res.data.Results ? res.data.Results : [],
              objParent: res.data.Results ? res.data.Results : [],
              addColumn: res.data.tableColumn ? res.data.tableColumn : [],
              changeData: [],
              changeFlag: false,
              buttonFlag: buttonFlag
            })
            this.formRef.current?.setFieldsValue({ tableData: res.data.Results })
          } else {
            let copyData = [...res.data.Results]
            let changeData = this.state.changeData
            for (let i = 0; i < changeData.length; i++) {
              copyData.forEach(element => {
                if (element?.['Column_' + changeData[i].exam_code + '_id'] === changeData[i].id) {
                  element['Column_' + changeData[i].exam_code] = changeData[i].result_value
                }
              });
            }
            this.setState({
              dataSource: res.data.Results ? res.data.Results : [],
              objParent: res.data.Results ? res.data.Results : [],
              addColumn: res.data.tableColumn ? res.data.tableColumn : [],
              buttonFlag: buttonFlag
            })
            this.formRef.current?.setFieldsValue({ tableData: copyData })
          }
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  handerChange(e, name, index, idx) {
    const tempObjParent = { ...this.state.objParent }
    tempObjParent[index].Column_Result[idx][name] = e
    this.setState({ objParent: tempObjParent, setOnBlur: true })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  ZoomProcess(record, idx, value, index) {
    const valueInspectIdx = `W1_inspect_val_${idx + 1}`
    const data = {
      Li_CourseLevel: record.W1_course_level,
      Li_ReserveNum: record.W1_reserve_num,
      Li_InspectCode: this.state.dataDisplay.Li_InspectItemList,
      W1_no: idx + 1,
      Li_FindingsDedicated:
        this.state.dataDisplay.Li_FindingsDedicated === null ? 0 : 1,
      FindingsLastTimeInitialDisplay:
        this.state.dataScreen.FindingsLastTimeInitialDisplay === true ? 1 : 0,
      LeadershipMattersHowToAdd:
        this.state.dataScreen.LeadershipMattersHowToAdd,
      flg_727: 0,
    }
    SpreadInputService.zoomProcess(data)
      .then((res) => {
        if (res) {
          this.CallMs(res.data, data, record, idx, index, valueInspectIdx)
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  reZoomProcess(
    dataReCall,
    flag,
    record,
    idx,
    index,
    valueInspectIdx,
    data274,
    LnkOutInspectCmtScreen
  ) {
    if (data274.message === 'Call Screen WS0274001') {
      const valueExpIdx = `Expression_491`
      const data = {
        Li_CourseLevel: dataReCall.Li_CourseLevel,
        Li_ReserveNum: dataReCall.Li_ReserveNum,
        Li_InspectCode: dataReCall.Li_InspectCode,
        W1_no: dataReCall.W1_no,
        Lo_InspectValue: LnkOutInspectCmtScreen,
        Li_FindingsDedicated: dataReCall.Li_FindingsDedicated,
        FindingsLastTimeInitialDisplay:
          dataReCall.FindingsLastTimeInitialDisplay,
        LeadershipMattersHowToAdd: dataReCall.LeadershipMattersHowToAdd,
        flg_727: flag,
      }
      SpreadInputService.zoomProcess(data)
        .then((res) => {
          const tempObjParent = { ...this.state.objParent }
          tempObjParent[index].Column_Result[idx][valueInspectIdx] =
            res.data.Lo_InspectValue

          tempObjParent[index].Column_Result[idx][valueExpIdx] =
            res.data.Expression_491
          if (res.data.Expression_491 === 35) {
            tempObjParent[index].Expression_491 = res.data.Expression_491
          }

          this.setState({ objParent: tempObjParent })
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    } else {
      const valueExpIdx = `Expression_491`
      const data = {
        Li_CourseLevel: dataReCall.Li_CourseLevel,
        Li_ReserveNum: dataReCall.Li_ReserveNum,
        Li_InspectCode: dataReCall.Li_InspectCode,
        W1_no: dataReCall.W1_no,
        Lo_InspectValue: dataReCall.Lo_InspectValue,
        Li_FindingsDedicated: dataReCall.Li_FindingsDedicated,
        FindingsLastTimeInitialDisplay:
          dataReCall.FindingsLastTimeInitialDisplay,
        LeadershipMattersHowToAdd: dataReCall.LeadershipMattersHowToAdd,
        flg_727: flag,
      }
      SpreadInputService.zoomProcess(data)
        .then((res) => {
          const tempObjParent = { ...this.state.objParent }
          tempObjParent[index].Column_Result[idx][valueInspectIdx] =
            res.data.Lo_InspectValue
          this.setState({ objParent: tempObjParent })

          tempObjParent[index].Column_Result[idx][valueExpIdx] =
            res.data.Expression_491
          if (res.data.Expression_491 === 35) {
            tempObjParent[index].Expression_491 = res.data.Expression_491
          }
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
    this.setState({ setOnBlur: false })
  }

  callScreenGet(record, exam_code, column, index) {
    let SheetStyle = this.formRef.current?.getFieldValue('SheetStyle')
    let addColumn = this.state.addColumn
    for (let i = 0; i < addColumn.length; i++) {
      if (addColumn[i].exam_type !== 'S' && addColumn[i].exam_comment_code === '0') {
        console.log(addColumn[i].exam_type);
        return
      }
    }
    console.log('if文を抜けた後');
    let params = {
      Li_InspectItemList: column.exam_code,
      exam_type: record?.['exam_type' + exam_code],
      Ms1400ID: record?.['Ms1400ID' + exam_code],
      course_level: record.course_level,
      reservation_number: record.reservation_number,
      pattern_code: record?.['pattern_code' + exam_code],
      category_code: record?.['category_code' + exam_code],
      judgment_level_division: record.judgment_level_division,
      FindingsLastTimeInitialDisplay: this.state.dataScreen.FindingsLastTimeInitialDisplay === true ? 1 : 0,
      LeadershipMattersHowToAdd: this.state.dataScreen.LeadershipMattersHowToAdd,
    }
    SpreadInputService.callScreenGet(params)
      .then((res) => {
        if (res.data.message === 'Call Screen WS0274001') {
          this.InspectCmtSearchQuery(record, exam_code, index)
        } else if (res.data.message === 'Call Screen WS0728001' || res.data.message === 'Call Screen WS0731001') {
          this.FindingsInputNormal(record, res, column)
        } else if (res.data.message === 'Call Screen WS0729001') {
          this.FindingsInputRadiography(record, res, column)
        } else {
          this.FindingsInputPhysiciiagnosis(record, res, column)
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  InspectCmtSearchQuery(record, exam_code, index) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS0274001_InspectCmtSearchQuery
            Lio_CmtClassify={record?.['exam_comment_code' + exam_code]}
            LnkOutInspectCmtScreen={record?.['Column_' + exam_code]}
            onFinishScreen={(output) => {
              let changeCopyData = [...this.state.changeData]
              let tableCopy = [...this.state.dataSource]
              let dataTableCopy = this.formRef.current.getFieldValue('tableData')
              let column = 'Column_' + exam_code
              let inspect = 'inspect_val_' + exam_code
              let changaId, changeData, obj
              let newFlag = true
              tableCopy.forEach(element => {
                if (element.id === record.id) {
                  element[column] = output.LnkOutInspectCmtScreen
                }
              })
              dataTableCopy.forEach(element => {
                if (element.id === record.id) {
                  element[column] = output.LnkOutInspectCmtScreen
                }
              })
              changeCopyData.forEach(element => {
                if (element.id === record.id) {
                  element[column] = output.LnkOutInspectCmtScreen
                  newFlag = false
                }
              })
              if (newFlag) {
                obj = {
                  id: record?.['Column_' + exam_code + '_id'],
                  result_value: output.LnkOutInspectCmtScreen,
                  exam_code: exam_code,
                }
                changeCopyData.push(obj)
              }
              this.setState({
                changeData: changeCopyData,
                dataSource: tableCopy,
                changeFlag: true
              })
              this.formRef.current.setFieldsValue({
                tableData: dataTableCopy
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  FindingsInputNormal(record, res, column) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS0728001_FindingsInputNormal
            record={column}
            personalNumberId={record.personal_number_id}
            KanjiName={record.kanji_name}
            Li_CourseLevel={res.data.Li_CourseLevel}
            Li_ReserveNum={res.data.Li_ReserveNum}
            Li_InspectCode={res.data.Li_InspectCode}
            Li_PatternCode={res.data.Li_PatternCode}
            Li_CategoryCode={res.data.Li_CategoryCode}
            Li_PersonalNum={res.data.Li_PersonalNum}
            Li_JudgeLevel={res.data.Li_JudgeLevel}
            Li_LastTimeInitialDisplay={res.data.Li_LastTimeInitialDisplay}
            Li_LeadershipMattersHowToAdd={res.data.Li_LeadershipMattersHowToAdd}
            Li_FindingsInputNumRows={res.data.Li_FindingsInputNumRows}
            Li_FindingsInputNumDigits={res.data.Li_FindingsInputNumDigits}
            Li_SerialNumAdded={res.data.Li_SerialNumAdded}
            closeFlag={true}
            onUpdateFindings={(output) => {
              this.getListData(false)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  FindingsInputRadiography(record, res, column) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0729001_FindingsInputRadiography
            record={column}
            personalNumberId={record.personal_number_id}
            KanjiName={record.kanji_name}
            Li_PatternCode={res.data.Li_PatternCode}
            Li_CategoryCode={res.data.Li_CategoryCode}
            Li_InspectCode={res.data.Li_InspectCode}
            Li_CourseLevel={res.data.Li_CourseLevel}
            Li_ReserveNum={res.data.Li_ReserveNum}
            Li_JudgeLevel={res.data.Li_JudgeLevel}
            Li_FindingsInputNumRows={res.data.Li_FindingsInputNumRows}
            Li_FindingsInputNumDigits={res.data.Li_FindingsInputNumDigits}
            closeFlag={true}
            onUpdateFindings={(output) => {
              this.getListData()
              this.closeModal(false)
            }}
          />
        ),
      },
    })
  }

  FindingsInputPhysiciiagnosis(record, res, column) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            record={column}
            personalNumberId={record.personal_number_id}
            KanjiName={record.kanji_name}
            Li_ReserveNum={res.data.Li_ReserveNum}
            Li_InspectCode={res.data.Li_InspectCode}
            Li_CategoryCode={res.data.Li_CategoryCode}
            Li_PersonalNum={res.data.Li_PersonalNum}
            closeFlag={true}
            onUpdateFindings={(output) => {
              this.getListData(false)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  CallMs(data, dataReCall, record, idx, index, valueInspectIdx) {
    switch (data.message) {
      case 'Call Screen WS0274001':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '50%',
            component: (
              <WS0274001_InspectCmtSearchQuery
                Lio_CmtClassify={data.Lio_CmtClassify}
                LnkOutInspectCmtScreen={data.LnkOutInspectCmtScreen}
                onFinishScreen={(output) => {
                  this.reZoomProcess(
                    dataReCall,
                    output.flg_724,
                    record,
                    idx,
                    index,
                    valueInspectIdx,
                    data,
                    output.LnkOutInspectCmtScreen
                  )
                  this.closeModal()
                }}
              />
            ),
          },
        })
        break
      case 'Call Screen WS0728001':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '50%',
            component: (
              <WS0728001_FindingsInputNormal
                Li_CourseLevel={data.Li_CourseLevel}
                Li_ReserveNum={data.Li_ReserveNum}
                Li_InspectCode={data.Li_InspectCode}
                Li_PatternCode={data.Li_PatternCode}
                Li_CategoryCode={data.Li_CategoryCode}
                Li_PersonalNum={data.Li_PersonalNum}
                Li_JudgeLevel={data.Li_JudgeLevel}
                Li_LastTimeInitialDisplay={data.Li_LastTimeInitialDisplay}
                Li_LeadershipMattersHowToAdd={data.Li_LeadershipMattersHowToAdd}
                Li_FindingsInputNumRows={data.Li_FindingsInputNumRows}
                Li_FindingsInputNumDigits={data.Li_FindingsInputNumDigits}
                Li_SerialNumAdded={data.Li_SerialNumAdded}
                onFinishScreen={(output) => {
                  this.reZoomProcess(
                    dataReCall,
                    output.flg_728,
                    record,
                    idx,
                    index,
                    valueInspectIdx,
                    data
                  )
                  this.closeModal()
                }}
              />
            ),
          },
        })
        break
      case 'Call Screen WS0729001':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1200,
            component: (
              <WS0729001_FindingsInputRadiography
                Li_PatternCode={data.Li_PatternCode}
                Li_CategoryCode={data.Li_CategoryCode}
                Li_InspectCode={data.Li_InspectCode}
                Li_CourseLevel={data.Li_CourseLevel}
                Li_ReserveNum={data.Li_ReserveNum}
                Li_JudgeLevel={data.Li_JudgeLevel}
                Li_FindingsInputNumRows={data.Li_FindingsInputNumRows}
                Li_FindingsInputNumDigits={data.Li_FindingsInputNumDigits}
                onFinishScreen={(output) => {
                  this.reZoomProcess(
                    dataReCall,
                    output.flg_729,
                    record,
                    idx,
                    index,
                    valueInspectIdx,
                    data
                  )
                  this.closeModal()
                }}
              />
            ),
          },
        })
        break
      case 'Call Screen WS0730001':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '50%',
            component: (
              <WS0730001_FindingsInputPhysiciiagnosis
                Li_ReserveNum={data.Li_ReserveNum}
                Li_InspectCode={data.Li_InspectCode}
                Li_CategoryCode={data.Li_CategoryCode}
                Li_PersonalNum={data.Li_PersonalNum}
                onFinishScreen={(output) => {
                  this.reZoomProcess(
                    dataReCall,
                    output.flg_730,
                    record,
                    idx,
                    index,
                    valueInspectIdx,
                    data
                  )
                  this.closeModal()
                }}
              />
            ),
          },
        })
        break
      default:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '77%',
            component: (
              <WS0731001_FindingsInputNormalSelect
                Li_PatternCode={data.Li_PatternCode}
                Li_CategoryCode={data.Li_CategoryCode}
                Li_InspectCode={data.Li_InspectCode}
                Li_CourseLevel={data.Li_CourseLevel}
                Li_ReserveNum={data.Li_ReserveNum}
                Li_JudgeLevel={data.Li_JudgeLevel}
                Li_PersonalNum={data.Li_PersonalNum}
                onFinishScreen={(output) => {
                  this.reZoomProcess(
                    dataReCall,
                    output.flg_731,
                    record,
                    idx,
                    index,
                    valueInspectIdx,
                    data
                  )
                  this.closeModal()
                }}
              />
            ),
          },
        })
    }
  }

  openExcel(record) {
    let ShortNameList = []
    let exam_code = []
    let obj, obj2
    let addColumn = this.state.addColumn
    for (let i = 0; i < addColumn.length; i++) {
      if (!addColumn[i].disabled) {
        obj = {
          insepct_name: addColumn[i].inspect_name
        }
        obj2 = {
          exam_code: addColumn[i].exam_code
        }
        ShortNameList.push(obj)
        exam_code.push(obj2)
      }
    }
    const data = {
      tableList: this.state.dataSource,
      ShortNameList: ShortNameList,
      ShortNameListLength: ShortNameList.length,
      exam_code: exam_code,
      exam_codeLength: exam_code.length,
    }
    SpreadInputService.download(data)
      .then((res) => {
        download_file(res)
      })
      .catch()
  }

  async reZoomProcessNOTShowMs(record, idx, value, index) {
    this.setState({ setIdx: idx })
    const valueInspectIdx = `W1_inspect_val_${idx + 1}`
    const valueExpIdx = `Expression_491`
    const data = {
      Li_CourseLevel: record.W1_course_level,
      Li_ReserveNum: record.W1_reserve_num,
      Li_InspectCode: this.state.dataDisplay.Li_InspectItemList,
      W1_no: idx + 1,
      Lo_InspectValue: record.Column_Result[idx][valueInspectIdx],
      Li_FindingsDedicated:
        this.state.dataDisplay.Li_FindingsDedicated === null ? 0 : 1,
      FindingsLastTimeInitialDisplay:
        this.state.dataScreen.FindingsLastTimeInitialDisplay === true ? 1 : 0,
      LeadershipMattersHowToAdd:
        this.state.dataScreen.LeadershipMattersHowToAdd,
      flg_727: 1,
    }
    await SpreadInputService.zoomProcess(data)
      .then((res) => {
        const tempObjParent = { ...this.state.objParent }
        tempObjParent[index].Column_Result[idx][valueInspectIdx] =
          res.data.Lo_InspectValue
        tempObjParent[index].Column_Result[idx][valueExpIdx] =
          res.data.Expression_491
        if (res.data.Expression_491 === 35) {
          tempObjParent[index].Expression_491 = res.data.Expression_491
        }
        this.setState({ objParent: tempObjParent })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
    this.setState({ setOnBlur: false })
  }

  spreadsetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0456001_SpreadsheetInputSetting
            onFinishScreen={(output) => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
            listGet={() => { this.getScreenData() }}
          />
        ),
      },
    })
  }

  spreadupdate = (changeCopyData, record) => {
    let params = {
      changeData: changeCopyData === undefined ? this.state.changeData : changeCopyData,
      tableData: this.formRef.current?.getFieldValue('tableData'),
      user: this.props.user.name
    }
    SpreadInputService.onUpdate(params)
      .then((res) => {
        message.success('保存が完了しました')
        this.setState({
          changeData: []
        })
        this.getListData(true)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  handleChage(e, record, exam_code, inspectComments) {
    if (inspectComments !== null) {
      inspectComments.map((element, index) => {
        let newElement = element.exam_comment_code.trim()
        if (e == newElement) {
          e = element.exam_comment_screen.trim()
        }
      })
    }
    let obj = {}
    let dbObj = {}
    let copyData = [...this.state.changeData]
    let dbCopyData = [...this.state.changeDB]
    let copyTableData = this.formRef.current?.getFieldValue('tableData')
    let id = record?.['Column_' + exam_code + '_id']
    let newFlag = true
    let value
    let exam_type = record?.['exam_type' + exam_code]
    if (exam_type !== 'S' && exam_type !== 'J' && exam_type !== 'X') {
      // exam_typeの頭１文字を元に再計算
      value = Recalculate(exam_type, e)
      console.log(value);
      copyData.forEach(element => {
        if (element.id === id) {
          console.log(value);
          element.result_value = value
          newFlag = false
        }
      })
    } else {
      value = e
    }

    copyTableData.forEach(element => {
      if (element?.['Column_' + exam_code + '_id'] === record?.['Column_' + exam_code + '_id']) {
        element['Column_' + exam_code] = value
      }
    })
    if (newFlag) {
      obj = {
        id: record?.['Column_' + exam_code + '_id'],
        result_value: value
      }
      dbObj = {
        db: 'dt0420'
      }
      copyData.push(obj)
      dbCopyData.push(dbObj)
    }
    this.setState({
      changeFlag: true,
      changeData: copyData,
      changeDB: dbCopyData
    })
    this.formRef.current?.setFieldsValue({ tableData: copyTableData })
  }

  minimalRequiredValue() {
    let addColumn = this.state.addColumn
    let tableData = this.formRef.current?.getFieldValue('tableData')
    let inspectCode = []
    let visitCourseList = []
    for (let i = 0; i < tableData.length; i++) {
      inspectCode.push({
        reservation_number: tableData[i].reservation_number
      })
      for (let k = 0; k < addColumn.length; k++) {
        visitCourseList.push({
          exam_code: addColumn[k].exam_code,
          exam_type: addColumn[k].exam_type,
          visit_course: tableData[i].visit_course,
        })
      }
    }

    let params = {
      tableData: visitCourseList,
      reservationNumberList: inspectCode,
      buttonFlag: this.state.buttonFlag,
      user: this.props.user.name
    }
    SpreadInputService.specifiedValueSub(params)
      .then((res) => {
        if (this.state.buttonFlag) {
          this.getListData(true)
        } else {
          let data = res.data.result_value
          for (let k = 0; k < tableData.length; k++) {
            for (let l = 0; l < data.length; l++) {
              if (tableData[k]?.['Column_' + data[l].exam_code] === '' && data[l].result_value !== '') {
                this.handleChage(data[l].result_value, tableData[k], data[l].exam_code, null)
              }
            }
          }
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  /**
 * idから行番号を取得
 * @param {*} arrayData
 * @param {*} recordID
 * @returns
 */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  render() {
    const SheetStyle = this.state.SheetStyle
    const FacilityType = this.state.FacilityType
    const dataScreen = this.state.dataScreen
    return (
      <div className='spread-input'>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete='off'
          initialValues={{
            ReceiptNumF: this.state.ReceiptNumF,
            ReceiptNumT: this.state.ReceiptNumT,
          }}
        >
          <Card title='スプレッド入力' style={{ position: 'relative' }}>
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className='option_btn_right_top'
                trigger='click'
                size='small'
                icon={<MenuOutlined />}
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.spreadsetting()}>
                        スプレッド設定
                      </Menu.Item>
                      {this.state.dataSource.length !== 0 ?
                        <Menu.Item key='2' onClick={() => this.openExcel()}>
                          EXCEL
                        </Menu.Item>
                        : null}
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border' style={{ paddingTop: 0 }}>
                    <Form.Item
                      name='DateF'
                      label='受診日'
                      style={{ width: '60%' }}
                      {...labelCol}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        locale={locale}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>

                    <div style={{ display: 'flex', gap: 10 }}>
                      <Form.Item
                        name='ReceiptNumF'
                        label='受付No'
                        {...labelCol}>
                        <Input className='input-size-number-5' />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='ReceiptNumT'>
                        <Input className='input-size-number-5' />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='FacilityType'
                      label='施設'
                      {...labelCol}>
                      <Select
                        style={{ width: 180 }}>
                        {FacilityType &&
                          FacilityType.map((item) => (
                            <Select.Option
                              key={item.LinkedField}
                              value={item.LinkedField}
                            >
                              {item.DisplayField}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='CourseCodeF'
                      label='コース'
                      style={{ marginBottom: 0 }}
                      {...labelCol}>
                      <Input.Search
                        style={{ width: '35%' }}
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '100%',
                              component: (
                                <WS0265001_BasicCourseInquiry
                                  Lo_CourseCode={this.formRef.current.getFieldValue(
                                    'CourseCodeF'
                                  )}
                                  Lo_CourseName={''}
                                  onFinishScreen={(output) => {
                                    if (output.recordData) {
                                      this.formRef.current.setFieldsValue({
                                        CourseCodeF: output.recordData.course_code
                                          ? output.recordData.course_code
                                          : '',
                                      })
                                    }
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className='box_search_inner' style={{ paddingTop: 0 }}>
                    <Form.Item
                      name='SheetStyle'
                      label='伝票'
                      {...labelCol}
                    >
                      <Select style={{ width: 160 }}>
                        {this.state.SheetStyle &&
                          this.state.SheetStyle.map((item) => (
                            item.DisplayField !== '' ?
                              <Select.Option
                                key={item.LinkedField}
                                value={item.LinkedField}
                              >
                                {item.DisplayField}
                              </Select.Option> : null
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='AllCasesExtractOp'
                      label='全件'
                      valuePropName='checked'
                      {...labelCol}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item
                      name='InspectHasOnlyOp'
                      label='検査有'
                      valuePropName='checked'
                      {...labelCol}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() =>
                        this.getListData(true)
                      }
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button size='small'
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 900,
                              component: (
                                <WS2786001_ConditionAddSub
                                  Li_DateF={this.formRef.current?.getFieldValue('DateF') ? this.formRef.current?.getFieldValue('DateF').format('YYYY/MM/DD') : ''}
                                  Li_DateT={this.formRef.current?.getFieldValue('DateF') ? this.formRef.current?.getFieldValue('DateF').format('YYYY/MM/DD') : ''}
                                  Li_AcceptNoF={this.formRef.current?.getFieldValue('ReceiptNumF')}
                                  Li_AcceptNoT={this.formRef.current.getFieldValue('ReceiptNumT')}
                                  Li_CourseF={this.formRef.current.getFieldValue('CourseCodeF')}
                                  Li_CourseT={this.formRef.current.getFieldValue('CourseCodeF')}
                                  Li_FacilityType={this.formRef.current.getFieldValue('FacilityType')}
                                  Li_ConditionAddData={this.state.conditionAddData}
                                  onFinishScreen={(output) => {
                                    // 追加条件のデータをstate変数に保持
                                    this.setState({
                                      conditionAddData: output.conditionAddData,
                                    })
                                    this.formRef.current.setFieldsValue({
                                      KeyInfo: output.Lio_KeyInfo ? output.Lio_KeyInfo : ''
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>

              </div>

              <Table
                dataSource={this.state.dataSource}
                bordered={true}
                size='small'
                rowKey={(record) => record.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
              >
                <Table.Column
                  width={40}
                  dataIndex='protectImage'
                  title={<img src={status_input_lock} />}
                  fixed='left'
                  align='center'
                  render={(W1_input_protection_state) => {
                    return W1_input_protection_state == 1 ? (<img src={status_input_lock} />) : ('')
                  }}
                />

                <Table.Column
                  width={80}
                  title='受付No'
                  dataIndex='receipt_number'
                  fixed='left'
                  render={(value, record, index) => (
                    <div
                      style={{
                        textAlign: 'right'
                      }}
                    >
                      {record.receipt_number}
                    </div>
                  )}
                />

                <Table.Column
                  width={100}
                  title='個人番号'
                  dataIndex='personal_number_id'
                  fixed='left'
                />

                <Table.Column
                  width={200}
                  title='漢字氏名'
                  dataIndex='kanji_name'
                  fixed='left'
                />

                <Table.Column
                  width={100}
                  title='受診日'
                  dataIndex='visit_date_on'
                  fixed='left'
                  render={(value, record, index) => (
                    <div
                      style={{
                        textAlign: 'right'
                      }}
                    >
                      {moment(record.visit_date_on)?.format('YYYY/MM/DD(ddd)')}
                    </div>
                  )}
                />

                <Table.Column
                  width={80}
                  title='コース'
                  dataIndex='visit_course'
                  fixed='left'
                />

                {this.state.addColumn?.map(
                  (title, idx) => {
                    return (
                      (!title.disabled) ?
                        <Table.Column
                          key={title.id}
                          className='column-size-5'
                          title={title.inspect_name}
                          dataIndex={'inspect' + title.exam_item}
                          width={200}
                          render={(value, record, index) => {
                            return <Form.Item style={{ marginBottom: '0' }} name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'Column_' + title.exam_code]}>
                              <Input
                                readOnly={this.state.buttonFlag ? true : false}
                                type='tel'
                                onDoubleClick={() => {
                                  this.callScreenGet(record, title.exam_code, title, this.findIndexByID(this.state.dataSource, record.id))
                                }}
                                onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', this.state.addColumn.length, true, 1)}
                                id={'spreadInput-' + title.id + '-' + record.id}
                                disabled={!record?.['Column_' + title.exam_code + '_id'] || title.Sy3200ID ? true : false}
                                onPressEnter={(e) => this.handleChage(e.target.value, record, title.exam_code, title.inspectComments)}
                                onBlur={(e) => this.handleChage(e.target.value, record, title.exam_code, title.inspectComments)}
                                style={{
                                  background: title.Sy3200ID ? '#f5f5f5' : '',
                                  borderColor: title.Sy3200ID ? '#d9d9d9' : ''
                                }}
                              />
                            </Form.Item>
                          }}
                        />
                        :
                        null
                    )
                  }
                )}

                <Table.Column
                  title=''
                  dataIndex=''
                  key=''
                  fixed='right'
                  width={40}
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='3'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 'fit-content',
                                    component: (
                                      <WS2583001_ConsultInquirySub
                                        Li_ReserveNum={record.reservation_number}
                                        onFinishScreen={(output) => {
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                            >
                              照会
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        >
                        </Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.minimalRequiredValue()}
                disabled={this.state.dataSource.length === 0}
              >
                <span className='btn_label'>
                  規定値
                </span>
              </Button>
              <Button
                type='primary'
                onClick={() => this.spreadupdate()}
                style={{ display: (this.state.buttonFlag ? 'none' : '') }}
              >
                <span className='btn_label'>
                  更新
                </span>
              </Button>
            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0735001_SpreadInput)
