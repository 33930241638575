import React from "react";
import { connect } from "react-redux";
import axios from "configs/axios";
import { Card, Button, Table, Row, Col, Menu, message, Spin } from "antd";
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";

import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";

const API_LIST = {
  index:
    "/api/process-menu/index"
  , add:
    "/api/process-menu/add"
  , update:
    "/api/process-menu/update"
  , delete:
    "/api/process-menu/delete"

};

class WS0439001_ProcessMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false
        , component: null
        , width: 0
      },
      pagination: {
        defaultPageSize: 15
        , size: 'small'
        , showQuickJumper: true
      },

      dataSource: []
      , isLoading: false
    };

  }


  columns = [
    {
      title: 'ＩＤ'
      , dataIndex: 'item_id'
      , width: 150
      , type: 1
      , required: true
    }
    , {
      title: '項目'
      , dataIndex: 'item'
      , width: 150
      , type: 1
      , required: true
    }
    , {
      title: '１'
      , dataIndex: 'condition_1'
      , width: 100
      , type: 1
      , required: true
    }
    , {
      title: '２'
      , dataIndex: 'condition_2'
      , width: 100
      , type: 5
      , min:0
      , max: 999999999
      , defaultValue: 0
    }
    , {
      title: '備考'
      , dataIndex: 'remarks'
      , width: 300
      , type: 1
    }
    , {
      align: 'center'
      , title: <Button
        type="primary"
        size="small"
        icon={<PlusOutlined />}
        onClick={() => {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: '550px',
              className: '',
              component:
                <ModalAdd
                  columns={this.tableColumns.filter(d => d.dataIndex ? d : '')}
                  onFinishScreen={(output) => {
                    this.AddData(output.itemChange)
                    this.closeModal()
                  }}
                />
            },
          })
        }} />
      , width: 30
      , render: (_, record) => (
        <Button
          size="small"
          icon={<MoreOutlined />}
          onClick={() => {
            // 編集、削除
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: '550px',
                className: '',
                component:
                  <ModalChange
                    columns={this.tableColumns.filter(d => d.dataIndex ? d : '')}
                    record={record}
                    onUpdate={(output) => {
                      this.UpdateData(output.itemChange)
                      this.closeModal()
                    }}
                    onDelete={(output) => {
                      this.DeleteData(record)
                      this.closeModal()
                    }}
                  />
              },
            });
          }}
        />
      )
    }

  ];

  tableColumns = this.columns.map((item) => ({
    ...item,
  }));

  componentDidMount() {
    this.loadData()
  }

  loadData = () => {
    this.setState({ isLoading: true });

    axios.get(API_LIST.index)
      .then(res => {
        this.setState({ dataSource: res.data })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  AddData = (values) => {
    this.setState({ isLoading: true });

    axios.post(API_LIST.add, { ...values })
      .then(res => {
        message.success('保存が成功しました')
        let data = this.state.dataSource.concat()

        values.id = res.data

        data.push(values)

        data.sort((a, b) => {if (a.item_id < b.item_id) return -1
          else if (a.item_id > b.item_id) return 1
          return 0})

        this.setState({ dataSource: data })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }


  UpdateData = (values) => {
    this.setState({ isLoading: true });

    axios.post(API_LIST.update, { ...values })
      .then(res => {
        message.success(res.data)
        let data = this.state.dataSource.filter(d => { return d.id != values.id })
        data.push(values)
        data.sort((a, b) => {if (a.item_id < b.item_id) return -1
                                        else if (a.item_id > b.item_id) return 1
                                        return 0})
        this.setState({ dataSource: data })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  DeleteData = (values) => {
    this.setState({ isLoading: true });

    axios.post(API_LIST.delete, { ...values })
      .then(res => {
        message.success(res.data)
        let data = this.state.dataSource.filter(d => { return d.id != values.id })
        this.setState({ dataSource: data })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal
        , visible: false
      },
    });
  }

  render() {
    return (
      <div className='process-menu'>

        <Card className="mb-2" title='処理メニュー メンテナンス' >
          <Table
            bordered
            size="small"
            loading={this.state.isLoading}
            dataSource={this.state.dataSource}
            columns={this.tableColumns}
            pagination={this.state.pagination}
            onRow={(record, rowIndex) => ({})}
          >
          </Table>

        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />

      </div>

    );
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WS0439001_ProcessMenu)
