import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Table } from 'antd'
import WS0350001_SiteFindingsMaintainSub from './WS0350001_SiteFindingsMaintainSub'
import QuerySiteInfoAction from 'redux/InspectionMaintenance/NormalValueSettingMaintain/QuerySiteInfo.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { debounce } from 'lodash'

class WS0178001_QuerySiteInfo extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '部位照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: []
    }

    // debounce
    this.onChangeSearch = debounce(this.onChangeSearch, 500)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 部位一覧を取得する
   */
  index = () => {
    const params = {
      Li_SearchChar: this.formRef.current.getFieldValue('SearchChar') ?? '',
      Li_SiteClasify: this.props.Li_SiteClasify ?? 0
    }
    QuerySiteInfoAction.index(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res
          })
        }
      })
  }

  /**
   * 検索処理
   */
  onChangeSearch = () => {
    this.index()
  }

  /**
  * モーダルを閉じる
  */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='query-site-info'>
        <Card title='部位照会'>
          <Form
            ref={this.formRef}
          >
            <Form.Item
              name='SearchChar'
              label='検索'>
              <Input
                type='text'
                className='input-size-40'
                onChange={(event) => {
                  // debounce使用して実行
                  this.onChangeSearch()
                }}
              />
            </Form.Item>
          </Form>

          <Table
            bordered
            size='small'
            pagination={false}
            dataSource={this.state.dataSource}
            rowKey={(record) => record.id}
            scroll={{ y: 450 }}
            onRow={(record, index) => {
              return {
                onDoubleClick: (event) => {
                  if (this.props.onFinishScreen) {
                    // 部位コードと部位名称
                    const output = {
                      Lo_SiteCode: record.site_code,
                      Lo_SiteName: record.site_name,
                      recordData: record
                    }
                    // 親画面に反映
                    this.props.onFinishScreen(output)
                  }
                }
              }
            }}
          >
            <Table.Column
              title='部位コード'
              dataIndex='site_code'
              width={100}
              render={(value) => (
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />
            <Table.Column
              title='検索略称'
              dataIndex='search_short_name'
              width={120}
            />
            <Table.Column
              title='部位名称'
              dataIndex='site_name'
            />
          </Table>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 800,
                    component: <WS0350001_SiteFindingsMaintainSub />
                  },
                })
              }}
            >
              設定
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0178001_QuerySiteInfo)
