import React from "react";
import { connect } from "react-redux";

import { Button, Card, Space, Table, Dropdown, Menu, Modal } from "antd";

import { MoreOutlined } from "@ant-design/icons";
import OnlineInstructionAction from "redux/CooperationRelated/OnlineInstruction/OnlineInstruction.action";
import WS2720007_Configuration from "./WS2720007_Configuration";
import Color from "constants/Color";
import ModalDraggable from "components/Commons/ModalDraggable";
import ResizableColumn from "components/Commons/ResizableColumn";

class WS2720001_OnlineInstruction extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'オンライン指示';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      dataSource: [],
      isLoadingTable: true,
      rowSelect: {},
      selectedRowKey: [],
      columns: [
        {
          title: "状態",
          dataIndex: "Expression_12",
          showSorterTooltip: false,
          width: 100,
          sorter: (a, b) => a.Expression_12 - b.Expression_12,
          render: (value, record) => {
            return <span style={{ color: Color(record.Expression_13)?.Foreground }}>{value}</span>;
          }
        },
        {
          title: "常駐プログラム",
          showSorterTooltip: false,
          dataIndex: "name",
          width: 120,
          sorter: (a, b) => a.name - b.name
        },
        {
          title: "起動",
          dataIndex: "Expression_14",
          showSorterTooltip: false,
          width: 70,
          sorter: (a, b) => a.Expression_14 - b.Expression_14,
          align: "center",
          render: (value, record, index) => value?.substr(0, 5)
        },
        {
          title: "停止",
          dataIndex: "stop_time_at",
          sorter: (a, b) => this.sortDate(a.stop_time_at, b.stop_time_at),
          width: 70,
          showSorterTooltip: false,
          align: "center",
          render: (value, record, index) => value?.substr(0, 5)
        },
        {
          title: "ﾀｲﾑｽﾀﾝﾌﾟ",
          showSorterTooltip: false,
          dataIndex: "Expression_15",
          sorter: (a, b) => a.Expression_15 - b.Expression_15,
          width: 100,
          align: "center"
        }
      ]
    };
  }
  sortDate = (a, b) => {
    let dateA = new Date(a);
    let dateB = new Date(b);
    return isFinite(dateA - dateB) ? dateA - dateB : isFinite(dateA) ? 1 : -1;
  };

  componentDidMount() {
    this.getListData();
  }
  tableColums = () =>
  this.state.columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: this.handleResize(index)
    })
  }));
  handleResize =
  index =>
  (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      };
      return { columns: nextColumns };
    });
  };
  components = {
    header: {
      cell: ResizableColumn
    }
  };
  getListData() {
    this.setState({
      isLoadingTable: true
    });

    OnlineInstructionAction.GetListData()
      .then(res => {
        this.setState({
          dataSource: res,
          selectedRowKey: [],
          rowSelect: {},
          isLoadingTable: false
        });
      })
      .finally(() => {
        this.setState({
          isLoadingTable: false
        });
      });
  }

  render() {
    return (
      <div className="online-instruction">
        <Card title="オンライン指示" style={{ width: 700 }}>
          <Space>
            <Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: "80%",
                    visible: true,
                    component: <WS2720007_Configuration onFinishScreen={() => {}} />
                  }
                });
              }}
            >
              各種設定
            </Button>
          </Space>
          <hr style={{ margin: "15px 0" }} />
          <Table
            size="small"
            dataSource={this.state.dataSource}
            columns={this.tableColums()}
            components={this.components}
            bordered={true}
            loading={this.state.isLoadingTable}
            pagination={this.state.dataSource?.length > 10 ? true : false}
            rowKey={record => record.id}
            scroll={{ x: 600 }}
            rowSelection={{
              type: "radio",
              selectedRowKeys: this.state.selectedRowKey,
              onSelect: async (record, selected, selectedRows) => {
                await this.setState({
                  rowSelect: selectedRows[0],
                  selectedRowKey: selectedRows.map(x => x.id)
                });
              }
            }}
          >
            <Table.Column
              title="状態"
              dataIndex="Expression_12"
              width={100}
              render={(value, record) => {
                return (
                  <span style={{ color: Color(record.Expression_13)?.Foreground }}>{value}</span>
                );
              }}
            />
            <Table.Column title="常駐プログラム" dataIndex="name" />
            <Table.Column
              title="起動"
              dataIndex="Expression_14"
              width={70}
              align="center"
              render={(value, record, index) => value?.substr(0, 5)}
            />
            <Table.Column
              title="停止"
              dataIndex="stop_time_at"
              width={70}
              align="center"
              render={(value, record, index) => value?.substr(0, 5)}
            />
            <Table.Column title="ﾀｲﾑｽﾀﾝﾌﾟ" dataIndex="Expression_15" width={100} align="center" />
            {/* <Table.Column width={60} align='center'
              render={(value, record) => {
                return (
                  <Dropdown
                    overlay={() => (
                      <Menu>
                        <Menu.Item hidden={!record.text}>
                          起　動
                        </Menu.Item>
                        <Menu.Item>
                          停　止
                        </Menu.Item>
                        <Menu.Item>
                          再表示
                        </Menu.Item>
                        <Menu.Item>
                          ローカル起動
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                );
              }}
            /> */}
          </Table>
          <Space style={{ float: "right", margin: "10px 0" }}>
            <Button type="primary">再表示</Button>
            <Button type="primary" disabled={!this.state.rowSelect.text}>
              起　動
            </Button>
            <Button type="primary">停　止</Button>
          </Space>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS2720001_OnlineInstruction);
