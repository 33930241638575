import axios from 'configs/axios';

const apiPaths = {
  getScreenData: "/api/insure-guide-init-input/reserve-change/get-screen-data",
  getPersonalBasic: "/api/insure-guide-init-input/reserve-change/get-personal-basic",
  register: "/api/insure-guide-init-input/reserve-change/register",
}

const ReserveChangeService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
  async getPersonalBasic(params) {
    return axios.get(apiPaths.getPersonalBasic, { params })
  },
  async register(params) {
    return axios.post(apiPaths.register, params)
  },
}

export default ReserveChangeService;