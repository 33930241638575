import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined } from "@ant-design/icons";

import { Card, Table, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin, Select } from "antd";

import RequestCsvOutputAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/RequestCsvOutput.action";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub";


import { SearchOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import Color from "constants/Color";

import moment from "moment";
import axios from "configs/axios";

const formlayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};


class WS2686001_QualifyResultManuallyUpdate extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    Li_State: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '資格結果手動更新';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      ErrorLevel_combo: '未受信',
      StsAlreadyOutput: "1",
      ErrorLevel_selected: "0",
      BreastCancer_selected: "0",
      UterineCancer_selected: "0",
      Type: "1",
    };

  }

  handleChange_UterineCancer = (value) => {
    this.setState({ UterineCancer_selected: value });
  }

  clearSelected_UterineCancer = () => {
    this.setState({ UterineCancer_selected: null });
  }

  handleChange_ErrorLevel = (value) => {
    this.setState({ ErrorLevel_selected: value });
  }

  clearSelected_ErrorLevel = () => {
    this.setState({ ErrorLevel_selected: null });
  }

  handleChange_BreastCancer = (value) => {
    this.setState({ BreastCancer_selected: value });
  }

  clearSelected_BreastCancer = () => {
    this.setState({ BreastCancer_selected: null });
  }


  onFinish = (values) => {
    const params = {
      ...values,

      ErrorLevel: this.state.ErrorLevel_selected,
      BreastCancer: this.state.BreastCancer_selected,
      UterineCancer: this.state.UterineCancer_selected,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Type: this.state.Type,
    }
    // console.log(params);
    axios.get('/api/associate-insure-qualify-confirm/qualify-result-manually-update/saveData', {
      params: params,
    })
      .then(res => {

        this.setState({
          // isLoadingTable: false
        })
        message.success(res.data.message);

      })
      .catch(error => {

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  componentDidMount() {
    // console.log(this.props.Li_ReserveNum)
    this.formRef.current.resetFields();
    this.getScreenData();
  }

  componentDidUpdate(prv) {

    if (this.props !== prv) {
      // console.log(this.props.Li_ReserveNum)
      this.formRef.current.resetFields();
      this.getScreenData();
    }
  }

  getScreenData = () => {

    axios.get('/api/associate-insure-qualify-confirm/qualify-result-manually-update/getscreenData', {
      params: {
        Li_ReserveNum: this.props.Li_ReserveNum,
        Li_State: this.props.Li_State,
      },
    })
      .then(res => {

        this.setState({
          // isLoadingTable: false
        })

        this.formRef.current?.setFieldsValue({

          Name: res ? res.data.Name : '',
          Date: res ? res.data.Date : '',
          DateBirth: res ? res.data.DateBirth : '',
          InsurerNum: res ? res.data.InsurerNum : '',
          InsuranceCardSymbol: res ? res.data.InsuranceCardSymbol : '',
          InsuranceCardNum: res ? res.data.InsuranceCardNum : '',
          Expresstion_2: res ? res.data.Expresstion_2 : '',
          StateList: res ? res.data.StateList : '',
          State: res ? res.data.State : '',
          Type: res ? res.data.Type : '',
        })

        this.setState({
          ErrorLevel_combo: (res.data.StsAlreadyOutput === false ? '未出力' : '未受信'),
          ErrorLevel_selected: res ? res.data.ErrorLevel : "0",
          BreastCancer_selected: res ? res.data.BreastCancer : "0",
          UterineCancer_selected: res ? res.data.UterineCancer : "0",
        });

      })
      .catch(error => {

        // this.setState({ isLoading     : false});
        // this.setState({isLoadingTable : false}); 

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }


  render() {
    return (
      <div className="qualify-result-manually-update">
        <Card title="資格結果手動更新">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            {...formlayout}
          >

            <label>※以下の情報で資格登録します。</label>
            <div>　</div>
            <Form.Item name="Name" label="氏 名" >
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item name="Date" label="受診日" >
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item name="DateBirth" label="生年月日" >
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item name="InsurerNum" label="保険者番号" >
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item name="InsuranceCardSymbol" label="保険証記号">
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item name="InsuranceCardNum" label="保険証番号" >
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item name="Expresstion_2" label="種別" align="left">
              <Input type="text" readOnly style={{ border: 'none' }} />
            </Form.Item>

            <Form.Item label='状態'>
              <Select value={this.state.ErrorLevel_selected} onChange={this.handleChange_ErrorLevel} style={{ width: '150px' }}>
                <Select.Option value="0">{this.state.ErrorLevel_combo}</Select.Option>
                <Select.Option value="4">エラー</Select.Option>
                <Select.Option value="7">資格無し</Select.Option>
                <Select.Option value="8">資格有り</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="追加検診（乳）" >
              <Select value={this.state.BreastCancer_selected} onChange={this.handleChange_BreastCancer} style={{ width: '150px' }}>
                <Select.Option value="0">&nbsp;</Select.Option>
                <Select.Option value="1">資格無し</Select.Option>
                <Select.Option value="2">資格有り</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="追加検診（子宮）"  >
              <Select value={this.state.UterineCancer_selected} onChange={this.handleChange_UterineCancer} style={{ width: '150px' }}>
                <Select.Option value="0">&nbsp;</Select.Option>
                <Select.Option value="1">資格無し</Select.Option>
                <Select.Option value="2">資格有り</Select.Option>
              </Select>
            </Form.Item>


            <Form.Item {...tailLayout}>
              <Button htmlType='submit' type='primary' style={{ float: 'right' }}>確定</Button>
            </Form.Item>


          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2686001_QualifyResultManuallyUpdate);
