import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Table, Form, Input, Button, message, Checkbox } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import { ModalError } from "components/Commons/ModalConfirm";
import {
  registerCourseDataAction,
  getCourseItems,
  getTargetCourse
} from "redux/SM_SystemMaintenance/ReservesDisplayItemSetting/ReservesDisplayItemSetting.action";
import { debounce } from "lodash";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import { SearchOutlined } from '@ant-design/icons'

class WS2702017_ReservesDisplayItemSettingSub_Course extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    nameId: PropTypes.any,
    displayName: PropTypes.any,
    keyName: PropTypes.any,
  };
  constructor(props) {
    super(props);

    // document.title = '予約表示項目Sub(コース)';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      courseItems: [],
      targetCourse: [],
      rowSelectLeft: {},
      rowSelectRight: {},
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
      enterKeyEvent: false,
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      unregisteredFlg: false,
      searchString: '',
      backwardRecord: []
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
    //右→左の順にテーブル作成
    this.loadDataRight()
    this.loadDataLeft()
  }

  loadDataRight = () => {
    this.setState({ isLoadingPage: true });
    let params = this.props.record;
    getTargetCourse(params)
      .then(res => {
        if (res) {
          this.setState({ targetCourse: res.data })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  onSearch() {
    const params = {
      unregisteredFlg: this.state.unregisteredFlg,
      Li_SearchChar: this.state.searchString,
      parentCourse: this.props.course
    }
    getCourseItems(params)
      .then(res => {
        if (res) {
          let textColor
          for (let l = 0; l < res.data.length; l++) {
            for (let k = 0; k < this.state.targetCourse.length; k++) {
              if (res.data[l].course_code === this.state.targetCourse[k].course_code) {
                textColor = {
                  ...res.data[l],
                  textColor: '#b9b9b9'
                }
                res.data[l] = textColor
              }
            }
          }
          // res.data.map(x => x.textColor = this.state.targetCourse.findIndex(y => y.course_code === x.course_code) === -1 ? '' : '#b9b9b9')
          this.setState({ courseItems: res.data })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  loadDataLeft = (searchString, unregisteredFlg) => {
    this.setState({ isLoadingPage: true });
    let params = {
      ...this.props.record,
      Li_SearchChar: searchString,
      parentCourse: this.props.course,
      unregisteredFlg: unregisteredFlg
    }
    getCourseItems(params)
      .then(res => {
        if (res) {
          let textColor
          for (let l = 0; l < res.data.length; l++) {
            for (let k = 0; k < this.state.targetCourse.length; k++) {
              if (res.data[l].course_code === this.state.targetCourse[k].course_code) {
                textColor = {
                  ...res.data[l],
                  textColor: '#b9b9b9'
                }
                res.data[l] = textColor
              }
            }
          }
          this.setState({ courseItems: res.data })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  //左側のテーブルから右側に移す
  onForward(selectValue) {
    let rightData = [...this.state.targetCourse]
    selectValue = {
      ...selectValue,
    }

    rightData.push(selectValue)

    // 重複チェック
    let duplicationCheck = rightData.filter((e, index, self) => {
      return self.findIndex((data) => data.course_code === e.course_code) === index
    })

    // 並び替え
    duplicationCheck.sort(function (a, b) {
      if (a.course_code > b.course_code) {
        return 1;
      } else {
        return -1;
      }
    })
    // 追加された行の色を変える
    let copyDataLeft = [...this.state.courseItems]
    for (let i = 0; i < copyDataLeft.length; i++) {
      if (copyDataLeft[i].course_code === selectValue.course_code) {
        copyDataLeft[i].textColor = '#b9b9b9';
      }
    }

    this.setState({
      courseItems: copyDataLeft,
      rowSelectLeft: null,
      targetCourse: duplicationCheck,
      enabledForward: false
    })
  }

  onBackward(record) {
    let copyTargetCourse = [...this.state.targetCourse]
    for (let i = 0; i < copyTargetCourse.length; i++) {
      if (copyTargetCourse[i] === record) {
        let delteLenght = i
        copyTargetCourse.splice(delteLenght, 1)
      }
    }
    // 選択した行の色を変える
    let copyCourseItems = [...this.state.courseItems]
    for (let l = 0; l < copyCourseItems.length; l++) {
      if (copyCourseItems[l].course_code === record.course_code) {
        copyCourseItems[l].textColor = '';
      }
    }

    this.setState({
      courseItems: copyCourseItems,
      targetCourse: copyTargetCourse,
      rowSelectLeft: null,
      enabledBackward: false,
      backwardRecord: record
    })
    if (this.state.backwardRecord) {
      this.setState({
        backwardRecord: [...this.state.backwardRecord, record]
      })
    }
  }

  onFinish = (value) => {
    let params = {
      ...value,
      keyName: this.props.record.keyName,
      nameId: this.props.record.nameId,
      displayName: this.formRef.current?.getFieldValue('displayName'),
      courseItems: this.state.courseItems,
      backwardRecord: this.state.backwardRecord ?? '',
      targetCourse: this.state.targetCourse ?? ''
    };
    this.RegisterBtn(params);
  }

  RegisterBtn(params) {
    this.setState({ isLoading: true })
    registerCourseDataAction(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しましたaaa"))
      .finally(() => this.setState({ isLoading: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="予約表示項目（コース）">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <Form.Item name='nameId' label="No.">
              <Input bordered={false} readOnly style={{ width: 100 }} />
            </Form.Item>

            <div style={{ display: "flex" }}>
              <Form.Item name="displayName" label="表示名称" >
                <Input type="text" maxLength={10} style={{ width: 100, marginRight: 20 }} />
              </Form.Item>
            </div>

            <div className="box_inner_horizontal">
              <div className="box_inner_vertical_label_top">
                <div className="table_header_filter">
                  <div>検索</div>
                  <Form.Item style={{ margin: 0 }}>
                    <Input size='small' onInput={(e) => this.setState({ searchString: e.target.value })} />
                  </Form.Item>
                  <Checkbox onClick={() => this.setState({ unregisteredFlg: !(this.state.unregisteredFlg) })}>未登録</Checkbox>
                  <Button type="primary" size="small" onClick={() => { this.onSearch() }} icon={<SearchOutlined />}>
                  </Button>
                </div>
                <Table bordered
                  dataSource={this.state.courseItems}
                  pagination={this.state.courseItems.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  loading={false}
                  size='small'
                  showHeader={true}
                  rowKey={(record) => record.course_code}
                  rowClassName={(record, index) => record.id === this.state.rowSelectLeft?.id ? 'table-row-light' : ''}
                  scroll={{ x: 'max-content', y: 450 }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        const item = { ...record, reservation_display_item_number: this.props.record.nameId }
                        this.onForward(item)
                      },
                      onClick: async () => {
                        this.setState({
                          rowSelectRight: null,
                          rowSelectLeft: record,
                          enabledForward: true,
                          enabledBackward: false,
                        });
                      }
                    };
                  }}
                >
                  <Table.Column width={100} title="コード" dataIndex="course_code" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.course_code}</span>
                    )} />

                  <Table.Column title="略称" dataIndex="course_name_short_name" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.course_name_short_name}</span>
                    )} />

                  <Table.Column width={150} title="登録済み予約項目" dataIndex="reservation_display_item_number" key=""
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.reservation_display_item_number == 0 ? '' : record.reservation_display_item_number + ':' + record.displayName}</span>
                    )} />

                </Table>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  gap: '16px',
                  padding: '16px',
                  margin: 'auto',
                  minWidth: '50px'
                }}>
                <Button size="small" type='primary'
                  disabled={!this.state.enabledForward}
                  onClick={() => {
                    this.onForward({ ...this.state.rowSelectLeft, reservation_display_item_number: this.props.record.nameId })
                  }}>
                  追加 <DoubleRightOutlined /></Button>
                <Button size="small" type='primary'
                  icon={<DoubleLeftOutlined />}
                  disabled={!this.state.enabledBackward}
                  onClick={() => this.onBackward(this.state.rowSelectRight)}>
                  削除</Button>
              </div>
              <div className="box_inner_vertical_label_top">
                <div className="table_header_filter">
                  <div>予約表示コース</div>
                </div>
                <Table bordered
                  dataSource={this.state.targetCourse}
                  pagination={this.state.targetCourse.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  loading={false}
                  size='small'
                  showHeader={true}
                  rowKey={(record) => record.course_code}
                  rowClassName={(record, index) => record.id === this.state.rowSelectRight?.id ? 'table-row-light' : ''}
                  scroll={{ x: 'max-content', y: 450 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.onBackward(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectRight: record,
                          rowSelectLeft: null,
                          enabledForward: false,
                          enabledBackward: true,
                        });
                      }
                    };
                  }}
                >
                  <Table.Column width={100} title="コード" dataIndex="course_code" key="" />
                  <Table.Column title="略称" dataIndex="course_name_short_name" key="" />
                </Table>
              </div>
            </div>


            <div className="box_button_bottom_right"
              style={{ marginTop: 0 }}>
              <Button type="primary" onClick={() => { this.onFinish(); }} icon={<SaveOutlined />}>
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>


          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2702017_ReservesDisplayItemSettingSub_Course);
