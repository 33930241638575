import axios from "configs/axios";

const API_LIST = {
  index: "/api/insure-guide-init-input/cmt-classify-inquiry-service",
};

const CmtClassifyInquiryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params });
  },
};

export default CmtClassifyInquiryService;