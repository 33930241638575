import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import InspectNameConvertInMaintainTaAction from "redux/CooperationRelated/InspectItemConvertInternal/InspectNameConvertInMaintainTa.actions";

const labelCol = {
  labelCol: { style: { width: 100 } }
}

class WS1039002_InspectNameConvertInMaintainTaEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = '検査名称変換(ＩＮ)保守[ＴＡ] 編集'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      examName: '',
    }
  }

  componentDidMount = () => {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }

    // Form設定
    this.formRef.current?.setFieldsValue(data)
    this.setState({
      examName: data.exam_name
    })
  }

  searchInspectCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        className: '',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={this.formRef.current?.getFieldValue("exam_code")}
            onFinishScreen={async (event) => {
              // 取得した検査コードと検査名称をFormに反映
              this.formRef.current?.setFieldsValue({
                exam_code: event.recordData.test_item_code,
              })
              this.setState({
                examName: event.recordData.exam_name
              })

              this.closeModal()
            }}
          />
        )
      }
    });
  }

  onChange = () => {
    this.setState({
      examName: ''
    })
  }

  // 保存ボタン
  onSave = () => {
    let valse = this.formRef.current?.getFieldsValue()
    let params = {
      ...valse,
      id: this.props.record?.id ?? false
    }

    InspectNameConvertInMaintainTaAction.save(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  // 削除ボタン
  onDelete = () => {
    let params = this.props.record
    InspectNameConvertInMaintainTaAction.delete(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  render() {
    return (
      <div className='inspect-name-convert-in-maintain-ta-edit'>
        <Card title={'検査名称変換(ＩＮ)保守[ＴＡ] [' + (this.props.newFlag ? '新規' : '編集') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              name='exam_name_short_name'
              label='検査名称(略称)'
              {...labelCol}
            >
              <Input
                className='input-size-20'
              />
            </Form.Item>

            <div className='box_inner_horizontal'>
              <Form.Item
                name='exam_code'
                label='検査コード'
                {...labelCol}
              >
                <Input
                  onChange={() => { this.onChange() }}
                  // onSearch={() => { this.searchInspectCode() }}
                  // onPressEnter={() => { this.searchInspectCode() }}
                  className='input-size-20'
                />
              </Form.Item>

              <div style={{ marginTop: 5 }}>{this.state.examName}</div>
            </div>

            <div className="box_button_bottom_right">
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                disabled={this.props.newFlag}
                onClick={() => {
                  Modal.confirm({
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    okButtonProps: {
                      icon: <DeleteOutlined />,
                      type: 'primary',
                      danger: true,
                    },
                    cancelText: 'キャンセル',
                    onOk: () => {
                      this.onDelete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => { this.onSave(); }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS1039002_InspectNameConvertInMaintainTaEdit)
