import axios from 'configs/axios';
const apiPaths = {
  getScreenData: '/api/radio-graphy-inspect-maintain/radio-graphy-guide-maintain/get-screen-data',
  save: '/api/radio-graphy-inspect-maintain/radio-graphy-guide-maintain/save',
  delete: '/api/radio-graphy-inspect-maintain/radio-graphy-guide-maintain/delete',
  getCommentMaster: '/api/radio-graphy-inspect-maintain/radio-graphy-guide-maintain/get-comment-master',
}

const RadiographyGuideMaintainService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
  async save(params) {
    return axios.post(apiPaths.save, params);
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params });
  },
  async getCommentMaster(params) {
    return axios.get(apiPaths.getCommentMaster, { params });
  },
}

export default RadiographyGuideMaintainService;