import axios from 'configs/axios'

const API_LIST = {
  index: '/api/east-fukyo-converse-process/east-fukyo-input',
  settingChange: '/api/east-fukyo-converse-process/east-fukyo-input/setting-change',
  confirmationChange: '/api/east-fukyo-converse-process/higashifukyo-data-input/confirmation-change',
}

const EastFukyoInputService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async settingChange(params) {
    return axios.post(API_LIST.settingChange, params)
  },
  async confirmationChange(params) {
    return axios.post(API_LIST.confirmationChange, params)
  },
}

export default EastFukyoInputService
