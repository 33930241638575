import axios from 'configs/axios'

const API_LIST = {
  index: '/api/consult-info-list/target-select-sub',
  excelReport: '/api/consult-info-list/target-select-sub/user-action-2',
  changeLogic: '/api/consult-info-list/target-select-sub/change-logic',
  changeLogicAll: '/api/consult-info-list/target-select-sub/change-logic-all',
}

const TargetSelectSubService = {
  async index(params) {
    // paramsの容量が大きい為、「post」を使用
    return axios.post(API_LIST.index, params)
  },

  async excelReport(visitData) {
    return axios.post(API_LIST.excelReport, visitData, { responseType: 'blob' })
  },

  async changeLogicService(params) {
    return axios.post(API_LIST.changeLogic, params)
  },

  async changeLogicAllService(params) {
    return axios.post(API_LIST.changeLogicAll, params)
  }
}

export default TargetSelectSubService
