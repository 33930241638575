import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Select, Table, Row, Col, Space, Dropdown, Menu, Button, Modal } from "antd";
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx';
import IntroduceLetterReturnInfoInputAction from 'redux/IntroductionLetter/IntroduceLetterReturnInfoInput/IntroduceLetterReturnInfoInput.action.js';

import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';

class WS2804500_ReturnInfoEdit extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '返送情報入力';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      reservationNumber: '',
      department: '',
      examDepartmentCode: '',
      id: null
    };
  }

  componentDidMount = () => {
    if (!this.props.newFlag) {
      this.formRef.current?.setFieldsValue({
        seq: this.props.record.seq,
        exam_code: this.props.record.exam_code,
        findings_code: this.props.record.findings_code,
        sick_name_code: this.props.record.sick_name_code,
        treatment_code: this.props.record.treatment_code,
        exam_content: this.props.record.exam_content,
        findings_content: this.props.record.findings_content,
        sick_name: this.props.record.sick_name,
        treatment_content: this.props.record.treatment_content
      })
    }
    this.setState({
      id: this.props.record ? this.props.record.id : null,
      reservationNumber: this.props.reservationNumber,
      department: this.props.department,
      examDepartmentCode: this.props.examDepartmentCode
    })
  }

  saveReturnInfo = () => {
    let params = {
      reservationNumber: this.state.reservationNumber,
      department: this.state.department,
      id: this.state.id,
      seq: this.formRef.current?.getFieldValue('seq'),
      exam_code: this.formRef.current?.getFieldValue('exam_code'),
      exam_content: this.formRef.current?.getFieldValue('exam_content'),
      findings_code: this.formRef.current?.getFieldValue('findings_code'),
      findings_content: this.formRef.current?.getFieldValue('findings_content'),
      sick_name_code: this.formRef.current?.getFieldValue('sick_name_code'),
      sick_name: this.formRef.current?.getFieldValue('sick_name'),
      treatment_code: this.formRef.current?.getFieldValue('treatment_code'),
      treatment_content: this.formRef.current?.getFieldValue('treatment_content')
    }

    IntroduceLetterReturnInfoInputAction.saveReturnInfo(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  setDataSend = (condition) => {
    if (condition === "exam_code") {
      return 2
    } else if (condition === "findings_code") {
      return 8
    } else if (condition === "sick_name_code") {
      return 5
    } else if (condition === "treatment_code") {
      return 3
    } else {
      return 4
    }
  }

  showIntroduceLetterVariousMasterInquiry(condition = null) {
    const value = this.setDataSend(condition)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={value}
            onFinishScreen={(output) => {
              if (condition === "exam_code") {
                this.formRef.current?.setFieldsValue({
                  exam_code: output?.variousCodes,
                  exam_content: output?.recordData?.exam_content
                })
              } else if (condition === "findings_code") {
                this.formRef.current?.setFieldsValue({
                  findings_code: output?.variousCodes,
                  findings_content: output?.recordData?.findings_content
                })
              } else if (condition === "sick_name_code") {
                this.formRef.current?.setFieldsValue({
                  sick_name_code: output?.variousCodes,
                  sick_name: output?.recordData?.sick_name
                })
              } else if (condition === "treatment_code") {
                this.formRef.current?.setFieldsValue({
                  treatment_code: output?.variousCodes,
                  treatment_content: output?.recordData?.treatment_content
                })
              }
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  deleteReturnInfo = () => {
    IntroduceLetterReturnInfoInputAction.deleteReturnInfo({ id: this.state.id })
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render = () => {
    return (
      <div className="return-info-edit" style={{ width: 500 }}>
        <Card title='返送情報編集'>
          <Form
            ref={this.formRef}
          >
            <div className="box_container">
              <div className="box_inner_vertical">
                <Form.Item
                  name='seq'
                  label='連番'
                >
                  <Input className="input-size-number-4" />
                </Form.Item>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name='exam_code'
                    label='検査'
                    className="input-search-size-number-allow-clear-15"
                  >
                    <Input.Search
                      onSearch={() => this.showIntroduceLetterVariousMasterInquiry("exam_code")}
                    />
                  </Form.Item>
                  <Form.Item
                    name='exam_content'
                    style={{ width: '100%' }}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name='findings_code'
                    label='所見'
                    className="input-search-size-number-allow-clear-15"
                  >
                    <Input.Search
                      onSearch={() => this.showIntroduceLetterVariousMasterInquiry("findings_code")}
                    />
                  </Form.Item>
                  <Form.Item
                    name='findings_content'
                    style={{ width: '100%' }}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name='sick_name_code'
                    label='傷病'
                    className="input-search-size-number-allow-clear-15"
                  >
                    <Input.Search
                      onSearch={() => this.showIntroduceLetterVariousMasterInquiry("sick_name_code")}
                    />
                  </Form.Item>
                  <Form.Item
                    name='sick_name'
                    style={{ width: '100%' }}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name='treatment_code'
                    label='治療'
                    className="input-search-size-number-allow-clear-15"
                  >
                    <Input.Search
                      onSearch={() => this.showIntroduceLetterVariousMasterInquiry("treatment_code")}
                    />
                  </Form.Item>
                  <Form.Item
                    name='treatment_content'
                    style={{ width: '100%' }}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="box_button_bottom_right">
                  <Button
                    danger
                    type='primary'
                    icon={<DeleteOutlined />}
                    onClick={() => { this.deleteReturnInfo() }}
                  >
                    <span className='btn_label'>
                      削除
                    </span>
                  </Button>
                  <Button
                    type='primary'
                    icon={<SaveOutlined />}
                    onClick={() => { this.saveReturnInfo() }}
                  >
                    <span className='btn_label'>
                      保存
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          destroyOnClose={true}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2804500_ReturnInfoEdit);
