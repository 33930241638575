import React from "react";
import { connect } from "react-redux";

import { Card, Form, Dropdown, Input, Menu, Table, Tooltip, Space, Button, message, Modal } from "antd";
import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { debounce } from "lodash";

import WS2698165_PrinterInfoInput from "pages/SM_SystemMaintenance/V4SM0021000_PrinterInfoMaintain/WS2698165_PrinterInfoInput.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import PrinterInfoMaintainAction from "redux/SM_SystemMaintenance/PrinterInfoMaintain/PrinterInfoMaintain.action"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import PrinterInfoInputAction from "redux/SM_SystemMaintenance/PrinterInfoMaintain/PrinterInfoInput.action"
class WS2698022_PrinterInfoMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'プリンタ情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      initialValues: {
        SearchChar: '',
      },
    };
  }

  componentDidMount() {
    this.index()
  }

  index() {
    const params = {
      SearchChar: this.formRef.current?.getFieldValue('SearchChar')
    }

    PrinterInfoMaintainAction.getScreenData(params)
      .then((res) => {
        // console.log(res)
        this.formRef.current?.setFieldsValue(res)
        this.getDataTableSubSet(this.formRef.current?.getFieldValue(), true);
      })
  }

  getDataTableSubSet = (paramsSearch, reload) => {
    PrinterInfoMaintainAction.printerInfoMaintenanceSub(paramsSearch)
      .then((res) => {
        let index = reload ? 0 : res?.findIndex(x => x.id === this.state.rowSelected[0]?.id)
        this.setState({
          dataSource: res ? res : [],
          rowSelected: res && res.length > 0 ? [res[index]] : [],
          selectedRowKeys: res && res.length > 0 ? [res[index]?.id] : [],
        });
      })
  }

  // onChangeInput() {
  //   const values = this.formRef.current?.getFieldValue()

  //   this.getDataTableSubSet({
  //     Li_SearchChar: values.SearchChar,
  //   });
  // }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }

  deletePrinterInfo(printerNumber) {
    const params = {
      Li_Printer: printerNumber
    }

    PrinterInfoInputAction.delete(params)
      .then(res => {
        this.index()
      })
      .catch(error => {

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  render() {
    return (
      <div className="printer-info-maintain">
        <Card title="プリンタ情報保守" className="mb-2">
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }}
                      onClick={() => {
                        window.open(location.protocol + '//' + location.hostname + ':631/admin')
                      }}
                    >
                      プリンタ管理
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          {/* <Space className="mb-4"> */}
          {/* <Button
              type="primary"
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: '40%',
                    component: (
                      <WS2698165_PrinterInfoInput
                        onFinishScreen={(output) => {
                          this.closeModal()
                        }}
                      />
                    )
                  }
                })
              }}
            >新規</Button> */}
          {/* <Button
              type="primary"
              onClick={
                () => {
                  window.open(location.protocol + '//' + location.host + ':631')
                }}
            >プリンタ情報</Button>
          </Space> */}
          <Form
            ref={this.formRef}
          // onFinish={this.onChangeInput}
          >
            <Form.Item
              className="input-size-40"
              name="SearchChar"
              label="検索"
            >
              <Input type="text"
                onPressEnter={() => {
                  this.index()
                }}
              // onChange={debounce((event) => this.onChangeInput(), 300)}
              />
            </Form.Item>
          </Form>

          <Table
            bordered hover
            style={{ cursor: 'pointer' }}
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
            rowKey={(record) => record.id}
            scroll={{ y: resizableTableScroll(160) }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: "fit-content",
                      component: (
                        <WS2698165_PrinterInfoInput
                          Li_PrinterNumber={record.printer_number}
                          onFinishScreen={() => {
                            this.index()
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  });
                }
              }
            }}
          >
            <Table.Column title="プリンタNo" dataIndex="printer_number"
            // showSorterTooltip={false}
            // sorter={(a, b) => String(a.printer_number).localeCompare(String(b.printer_number), 'ja')}
            />
            <Table.Column title="表示名称" dataIndex="printer_name"
            // showSorterTooltip={false}
            // sorter={(a, b) => String(a.printer_name).localeCompare(String(b.printer_name), 'ja')}
            />
            <Table.Column title="プリンター正式名" dataIndex="Prn"
            // showSorterTooltip={false}
            // sorter={(a, b) => String(a.Prn).localeCompare(String(b.Prn), 'ja')}
            />
            <Table.Column align='center' width={40} fixed='right'
              title={<Button type='primary' size='small'
                icon={<PlusOutlined />}

                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 'fit-content',
                      component: (
                        <WS2698165_PrinterInfoInput
                          new_Flag={true}
                          onFinishScreen={() => {
                            this.index()
                            this.closeModal()
                          }}
                        />
                      )
                    }
                  })
                }}></Button>}
              render={(text, record, index) => (
                <Space >
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item key='1' style={{ width: '100%' }}
                          onClick={() => (
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                centered: true,
                                width: 'fit-content',
                                component: (
                                  <WS2698165_PrinterInfoInput
                                    Li_PrinterNumber={record.printer_number}
                                    onFinishScreen={() => {
                                      this.index()
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          )}>修正
                        </Menu.Item>
                        <Menu.Item
                          key='2'
                          onClick={() => {
                            Modal.confirm({
                              content: '削除を行いますか',
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => {
                                this.deletePrinterInfo(record.printer_number)
                              }
                            })
                          }}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                </Space>
              )}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2698022_PrinterInfoMaintain);
