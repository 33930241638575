import React from 'react'
import { Card, Input, Form, Table, Button, Modal, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import * as wanakana from 'wanakana'

class WS0343102_SupplementtaryInfo extends React.Component {
  formRef = React.createRef()
  static propTypes = {
  }

  constructor(props) {
    super(props)

    // document.title = '個人情報保守 - 補足'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      dataSource: [],
      disabledSaveBtn: true
    }
  }
  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      dataSource: this.props.personal.personal_supplements
    })
    this.formRef.current?.setFieldsValue({
      tableData: this.props.personal.personal_supplements
    })
    console.log(this.props.personal.personal_supplements);
  }

  /**
   * 入力変更処理
   * @param {*} value
   * @param {*} record
   * @param {*} index
   */
  onChangeValue = (value, record, index) => {
    let chnageValue = ''
    if (record.condition_1 === 'X') {
      chnageValue = wanakana.toRomaji(value)
    } else if (record.condition_1 === 'K') {
      chnageValue = wanakana.toKatakana(value)
    } else if (record.condition_1 === 'J') {
      chnageValue = value
      // chnageValue = wanakana.toHiragana(value)
    }

    let arrTemp = [...this.state.dataSource]
    // 内容を反映
    let obj = {
      ...record,
      content: chnageValue, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj

    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false // 登録ボタン 活性
    })
  }

  /**
   * 登録ボタン押下処理
   */
  onFinish = () => {
    Modal.confirm({
      content: '上書き登録します。よろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        const data = {
          personal_supplements: [...this.state.dataSource]
        }
        // 親画面にてAPIを呼ぶ
        this.props.onUpdate(data)
        // 登録ボタン 非活性
        this.setState({
          disabledSaveBtn: true
        })
      }
    })
  }

  render() {
    return (
      <div className='supplementtary-info'>
        <Card title='補足'>

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ y: 500 }}
              bordered
            >
              <Table.Column
                title='項目'
                dataIndex='item' />
              <Table.Column
                title='内容'
                dataIndex='content'
                render={(value, record, index) => (
                  <Form.Item name={['tableData', index, 'content']} >
                    {(record.StsDirectInput && (record.contents ?? false)) ?
                      // 選択項目
                      <Select
                        onChange={(event) => {
                          this.onChangeValue(event, record, index)
                        }}
                      >
                        {record.contents.map((item, index) => (
                          <Select.Option
                            key={item.label + index}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      :
                      // テキスト入力
                      <Input
                        maxLength={record?.condition_2 || 50}
                        onChange={(event) => {
                          this.onChangeValue(event.target.value, record, index)
                        }}
                      />
                    }
                  </Form.Item>
                )}
              />
            </Table>
            <div className='box_button_bottom_right'>
              <Form.Item style={{ margin: 0 }}>
                {/* 登録ボタン */}
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  htmlType='submit'
                  disabled={this.state.disabledSaveBtn}
                >
                  <span className='btn_label'>
                    登録
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form>

        </Card>
      </div>
    )
  }
}

export default WS0343102_SupplementtaryInfo
