import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Input, message, Modal, Menu, Dropdown, Descriptions } from 'antd'
import { MoreOutlined, SaveOutlined, PrinterOutlined, DeleteOutlined } from '@ant-design/icons'
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import { Chart, Series, ArgumentAxis, CommonSeriesSettings, Export, Legend, Label, Title, Font, Tooltip, Grid, ValueAxis, VisualRange, ConstantLine } from 'devextreme-react/chart'
import moment from 'moment'

class ChartDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'グラフ'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      inspectResultList: [],
      comment: ''
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.getInspectResultList()
  }

  /**
   * カテゴリ別の詳細な検査結果一覧を取得
   */
  getInspectResultList = () => {

    // 親画面から引数取得
    const record = { ...this.props.record }
    const values = { ...this.props.values }

    let SelectCategoryList = ''
    const HistoryAdded = values.HistoryAdded //履歴
    const NonInput = values.NonInput
    const Outliers = values.Outliers
    const NonInputOrOutliers = NonInput === 1 ? { NonInput } : { Outliers }
    const NormalJudge = values.NormalJudge

    const params = {
      ...this.props.paramObject,
      StsInputModeInspect: values.StsInputModeInspect,
      StsInputModeFindings: values.StsInputModeFindings,
      SelectCategoryList: SelectCategoryList,
      Li_JudgeLevel: record.Li_JudgeLevel,
      SelectCategory: record.SelectCategory,
      UndeterminedColorOp: values.UndeterminedColorOp,
      StsParkModeCategoryUpAndDown: values.StsParkModeCategoryUpAndDown,
      StsInputModeInspectCmtChiba: values.StsInputModeInspectCmtChiba,
      HistoryAdded,
      ...NonInputOrOutliers,
      NormalJudge,
      CategoryDisplayPosition: values.CategoryDisplayPosition,
      sex: values.sex,
      birthday_on: values.birthday_on,
      visit_date_on: values.visit_date_on,
      Li_DateOfBirth: values.Li_DateOfBirth,
      pre1ReserveNum: values.pre1ReserveNum ?? '',
      pre2ReserveNum: values.pre2ReserveNum ?? '',
      pre3ReserveNum: values.pre3ReserveNum ?? '',
      pre4ReserveNum: values.pre4ReserveNum ?? '',
      externalFlag: this.props.externalFlag
    }
    ResultDisplayAction.getInspectResultList(params)
      .then((res) => {
        if (res) {
          let tempArr = []
          // グラフ用に配列を加工
          res.forEach((item) => {
            let obj = { ...item }

            // exam_typeが「N,N1,N2...」の場合グラフ作成 かつ　検査データが存在する場合
            if (obj.exam_type.slice(0, 1) === 'N' && obj.dt0420_id) {

              let maxRange = (obj.normal_max_value) ? parseFloat(obj.normal_max_value) : null
              let minRange = (obj.normal_max_value) ? parseFloat(obj.normal_min_value) : null
              // 基準範囲
              obj.normal_max_value = parseFloat(obj.normal_max_value) ? parseFloat(obj.normal_max_value) : 0
              obj.normal_min_value = parseFloat(obj.normal_min_value) ? parseFloat(obj.normal_min_value) : 0

              let tmpChartData = [], rValue
              // 今回
              if (values.DateChar) {
                const thisdDate = moment(values.DateChar).format('YYYY/MM/DD')
                rValue = parseFloat(item.result_value ?? 0)
                tmpChartData.push({
                  time: thisdDate,
                  time_name: `今回 (${thisdDate})`,
                  result_value: rValue,
                })
                if (maxRange) {
                  maxRange = (rValue > maxRange) ? rValue : maxRange
                  minRange = (rValue < minRange) ? rValue : minRange
                }
              }
              // 前回
              if (values.pre1VisitDate) {
                rValue = parseFloat(item.pre_1_result_value ?? 0)
                tmpChartData.push({
                  time: values.pre1VisitDate,
                  time_name: `前回 (${values.pre1VisitDate})`,
                  result_value: rValue,
                })
                if (maxRange) {
                  maxRange = (rValue > maxRange) ? rValue : maxRange
                  minRange = (rValue < minRange) ? rValue : minRange
                }
              }
              // 前々回
              if (values.pre2VisitDate) {
                rValue = parseFloat(item.pre_2_result_value ?? 0)
                tmpChartData.push({
                  time: values.pre2VisitDate,
                  time_name: `前々回 (${values.pre2VisitDate})`,
                  result_value: rValue,
                })
                if (maxRange) {
                  maxRange = (rValue > maxRange) ? rValue : maxRange
                  minRange = (rValue < minRange) ? rValue : minRange
                }
              }
              // 3回前
              if (values.pre3VisitDate) {
                rValue = parseFloat(item.pre_3_result_value ?? 0)
                tmpChartData.push({
                  time: values.pre3VisitDate,
                  time_name: `3回前 (${values.pre3VisitDate})`,
                  result_value: rValue
                })
                if (maxRange) {
                  maxRange = (rValue > maxRange) ? rValue : maxRange
                  minRange = (rValue < minRange) ? rValue : minRange
                }
              }
              // 4回前
              if (values.pre4VisitDate) {
                rValue = parseFloat(item.pre_4_result_value ?? 0)
                tmpChartData.push({
                  time: values.pre4VisitDate,
                  time_name: `4回前 (${values.pre4VisitDate})`,
                  result_value: rValue
                })
                if (maxRange) {
                  maxRange = (rValue > maxRange) ? rValue : maxRange
                  minRange = (rValue < minRange) ? rValue : minRange
                }
              }

              let arr = []
              arr.push(minRange)
              arr.push(maxRange)
              // Y軸の値の範囲
              obj.visualRange = arr
              // グラフ用のデータ
              obj.chartData = tmpChartData
              tempArr.push(obj)
            }
          })

          let commentStr = ''
          // 件数が0件の場合
          if (tempArr.length === 0) {
            commentStr = 'グラフ化できる検査データがありません'
          }

          this.setState({
            inspectResultList: tempArr,
            comment: commentStr
          })
        }
      })
  }

  render() {
    return (
      <div className='chart-display' >
        <Card title='グラフ'>
          <div className='box_container' style={{ maxHeight: 600, overflowY: 'auto' }} >
            {this.state.inspectResultList.length > 0 ?
              <Descriptions
                title=''
                layout='vertical'
                bordered
                column={1}
              >
                {this.state.inspectResultList.map((item, index) => {
                  return (
                    <Descriptions.Item
                      label={item.inspect_name}
                      key={'chart-' + index}
                    >
                      <Chart
                        dataSource={item.chartData}
                        style={{ height: 200 }}
                      >
                        <CommonSeriesSettings
                          argumentField='time_name'
                          type='line'>
                          <Label font={{ family: 'Verdana' }} />
                        </CommonSeriesSettings>

                        <Series valueField='result_value' />

                        <ArgumentAxis
                          valueMarginsEnabled={false}
                          discreteAxisDivisionMode='crossLabels'
                        >
                          <Grid visible={true} />
                          <Label font={{ family: 'Verdana' }} />
                        </ArgumentAxis>

                        <ValueAxis
                          maxValueMargin={0.01}
                          visualRange={item.visualRange}>
                          <Label font={{ family: 'Verdana' }} />
                          <ConstantLine
                            width={2}
                            value={item.normal_min_value}
                            color='#8c8cff'
                            dashStyle='dash'
                          >
                            <Label
                              text={'基準範囲 ' + item.normal_min_value}
                              font={{ family: 'Verdana' }} />
                          </ConstantLine>
                          <ConstantLine
                            width={2}
                            value={item.normal_max_value}
                            color='#ff7c7c'
                            dashStyle='dash'
                          >
                            <Label
                              text={'基準範囲 ' + item.normal_max_value}
                              font={{ family: 'Verdana' }} />
                          </ConstantLine>
                        </ValueAxis>

                        <Export enabled={false} />
                        <Legend visible={false} />
                        <Title text={''} horizontalAlignment='left'>
                          <Font size={16} />
                        </Title>
                        <Tooltip
                          enabled={true}
                          zIndex={9999}
                          font={{ family: 'Verdana' }}
                        >
                        </Tooltip>
                      </Chart>
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
              :
              <div>{this.state.comment}</div>
            }
          </div>
        </Card >
      </div >
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChartDisplay)
