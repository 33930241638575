import axios from 'configs/axios'

const API_LIST = {
  getStatuList: '/api/mirais-transmission-inquiry/mirais-transmission-inquiry/get-status-list'
}

const MiraisTransmissionInquiryService = {
  async getStatuList(params) {
    return axios.get(API_LIST.getStatuList, { params })
  },
}
export default MiraisTransmissionInquiryService;
