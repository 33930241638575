import React from 'react'
import { connect } from 'react-redux'
import { Card, Tabs, Form, Button, Table, Input, TimePicker } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { SearchOutlined, } from '@ant-design/icons'
import LogQueryAction from 'redux/Others/LogQuery/LogQuery.action.js'
import moment from 'moment'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

const { TextArea } = Input;

const today = moment(new Date().toISOString())
const format = 'YYYY/MM/DD'

// APIの選択　詳細ログ用配列
const apiDetailList = [
  { key: 'api-1', title: 'リクエスト', keyName: 'request' },
  // { key: 'api-1', title: 'ヘッダ', keyName: 'headers' },
  // { key: 'api-2', title: 'ペイロード', keyName: 'payload' },
  // { key: 'api-3', title: 'セッション', keyName: 'session' },
  { key: 'api-4', title: 'レスポンス', keyName: 'response' },
  // { key: 'api-5', title: 'ユーザー', keyName: 'user' },
  // { key: 'api-6', title: 'IPアドレス', keyName: 'ip_address' },
]

// SQLの選択　詳細ログ用配列
const sqlDetailList = [
  { key: 'sql-1', title: 'クエリ文', keyName: 'sql' },
  // { key: 'sql-2', title: 'ファイル', keyName: 'file' },
]

// 詳細ログ用配列
const detailList = [
  { key: '1', title: '内容詳細', keyName: 'item' },
]

class LogQuery extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = '検査依頼メイン'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedTabKey: '1',

      // Laravel関連
      dataSourceLaravel: [],
      rowSelectLaravel: {},
      detalLogLaravel: '',
      paginationLaravel: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({ rowSelectApi: {} })
          // 選択されたページ番号で再取得
          this.getLogLaravel(current)
        }

      },

      // API関連
      dataSourceApi: [],
      rowSelectApi: {},
      paginationApi: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({ rowSelectApi: {} })
          // 選択されたページ番号で再取得
          this.getLogApi(current)
        }
      },

      // SQL関連
      dataSourceSql: [],
      rowSelectSql: {},
      detalLogSql: '',
      paginationSql: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectSql: {},
            detalLogSql: '',
          })
          // 選択されたページ番号で再取得
          this.getLogSql(current)
        }
      },

      // Top関連
      dataSourceTop: [],
      rowSelectTop: {},
      paginationTop: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectTop: {}
          })
          // 選択されたページ番号で再取得
          this.getLogTop(current)
        }
      },

      // Free関連
      dataSourceFree: [],
      rowSelectFree: {},
      paginationFree: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectFree: {}
          })
          // 選択されたページ番号で再取得
          this.getLogFree(current)
        }
      },

      // Df関連
      dataSourceDf: [],
      rowSelectDf: {},
      paginationDf: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectDf: {}
          })
          // 選択されたページ番号で再取得
          this.getLogDf(current)
        }
      },

      // Docker関連
      dataSourceDocker: [],
      rowSelectDocker: {},
      paginationDocker: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectDocker: {}
          })
          // 選択されたページ番号で再取得
          this.getLogDocker(current)
        }
      },

      // Docker関連
      dataSourceSerrerError: [],
      rowSelectSerrerError: {},
      paginationSerrerError: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectSerrerError: {}
          })
          // 選択されたページ番号で再取得
          this.getLogSerrerError(current)
        }
      },

      // Docker関連
      dataSourceSerrerOut: [],
      rowSelectSerrerOut: {},
      paginationSerrerOut: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        current: 1, //初期表示のページ番号
        total: 0,
        onChange: (current) => {
          this.setState({
            rowSelectSerrerOut: {}
          })
          // 選択されたページ番号で再取得
          this.getLogSerrerOut(current)
        }
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      DateFChar: today.format(format),
      DateTChar: today.format(format),
      TimeFChar: moment('00:00:00', 'HH:mm'),
      TimeTChar: moment('23:59:00', 'HH:mm'),
    })

    // Laravelログを取得
    // this.getLogLaravel()
    // 端末容量取得
    this.getCapacity()
  }

  /**
   * 端末容量取得
   */
  getCapacity = () => {
    LogQueryAction.getCapacity()
      .then((res) => {
        if (res) {
          console.log(res)
          this.formRef.current?.setFieldsValue({
            Available: res.Available,
            Used: res.Used,
            Usepercent: res.Usepercent,
          })
          // Laravelログを取得
          this.getLogLaravel()
        }
      })
  }

  /**
   * 1:Laravelログを取得
   */
  getLogLaravel = (current) => {

    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: this.formRef.current.getFieldValue('TimeFChar') ? moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm') : null,
      TimeTChar: this.formRef.current.getFieldValue('TimeTChar') ? moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm') : null,
      Search: this.formRef.current.getFieldValue('Search'),
      pageSize: this.state.paginationSql.pageSize, //1ページあたりの件数
      current: current
    }
    LogQueryAction.getLogLaravel(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceLaravel: res.items,
            paginationLaravel: { //ペジネーション変更
              ...this.state.paginationLaravel,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 1:Laravel詳細ログを取得
   */
  getLogDetailLaravel = () => {
    let tempId = this.state.rowSelectLaravel.id?.split('_')
    // 選択行
    let line = tempId[1]

    const params = {
      selectedId: line,
      nextSelectedId: this.state.rowSelectLaravel.id,
      fileName: this.state.rowSelectLaravel.file_name,
    }
    LogQueryAction.getLogDetailLaravel(params)
      .then((res) => {
        if (res) {
          this.setState({
            detalLogLaravel: res
          })
        }
      })
  }


  /**
   * 2:APIログを取得
   */
  getLogApi = (current) => {
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      Search: this.formRef.current.getFieldValue('Search'),
      pageSize: this.state.paginationApi.pageSize, //1ページあたりの件数
      current: current
    }
    LogQueryAction.getLogApi(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceApi: res.items,
            paginationApi: { //ペジネーション変更
              ...this.state.paginationApi,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 3:Sqlログを取得
   */
  getLogSql = (current) => {
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      Search: this.formRef.current.getFieldValue('Search'),
      pageSize: this.state.paginationSql.pageSize, //1ページあたりの件数
      current: current
    }
    LogQueryAction.getLogSql(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSql: res.items,
            paginationSql: { //ペジネーション変更
              ...this.state.paginationSql,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 5:Topログを取得
   */
  getLogTop = (current) => {
    console.log('getLogTop');
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      pageSize: this.state.paginationTop.pageSize, // 1ページあたりの件数
      current: current
    }

    LogQueryAction.getLogTop(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceTop: res.items,
            paginationTop: { // ペジネーション変更
              ...this.state.paginationTop,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 6:Freeログを取得
   */
  getLogFree = (current) => {
    console.log('getLogFree');
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      pageSize: this.state.paginationTop.pageSize, // 1ページあたりの件数
      current: current
    }

    LogQueryAction.getLogFree(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceFree: res.items,
            paginationFree: { // ペジネーション変更
              ...this.state.paginationTop,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 7:Dfログを取得
   */
  getLogDf = (current) => {
    console.log('getLogDf');
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      pageSize: this.state.paginationTop.pageSize, // 1ページあたりの件数
      current: current
    }

    LogQueryAction.getLogDf(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceDf: res.items,
            paginationDf: { // ペジネーション変更
              ...this.state.paginationDf,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 8:Dockerログを取得
   */
  getLogDocker = (current) => {
    console.log('getLogDocker');
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      pageSize: this.state.paginationTop.pageSize, // 1ページあたりの件数
      current: current
    }

    LogQueryAction.getLogDocker(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceDocker: res.items,
            paginationDocker: { // ペジネーション変更
              ...this.state.paginationDocker,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 9:SerrerErrorログを取得
   */
  getLogSerrerError = (current) => {
    console.log('getLogSerrerError');
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      pageSize: this.state.paginationTop.pageSize, // 1ページあたりの件数
      current: current
    }

    LogQueryAction.getLogSerrerError(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSerrerError: res.items,
            paginatioSerrerError: { // ペジネーション変更
              ...this.state.paginatioSerrerError,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
  * 10:SerrerOutログを取得
  */
  getLogSerrerOut = (current) => {
    console.log('getLogSerrerOut');
    const params = {
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar')).format(format),
      DateTChar: moment(this.formRef.current.getFieldValue('DateTChar')).format(format),
      TimeFChar: moment(this.formRef.current.getFieldValue('TimeFChar')).format('HH:mm'),
      TimeTChar: moment(this.formRef.current.getFieldValue('TimeTChar')).format('HH:mm'),
      pageSize: this.state.paginationTop.pageSize, // 1ページあたりの件数
      current: current
    }

    LogQueryAction.getLogSerrerOut(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataSourceSerrerOut: res.items,
            paginatioSerrerOut: { // ペジネーション変更
              ...this.state.paginatioSerrerOut,
              current: res.current,
              total: res.total,
            }
          })
        }
      })
  }


  /**
   * 検索ボタン押下
   */
  onSearch = () => {
    this.setState({
      rowSelectLaravel: {},
      detalLogLaravel: '',
      rowSelectApi: {},
      rowSelectSql: {},
      detalLogSql: '',
      rowSelectTop: {},
      rowSelectFree: {},
      rowSelectDf: {},
      rowSelectDocker: {},
      rowSelectSerrerError: {},
      rowSelectSerrerOut: {}
    })

    let key = this.state.selectedTabKey

    switch (key) {
      case '1':
        // Laravel
        this.getLogLaravel(1)
        break
      case '2':
        // API
        this.getLogApi(1)
        break
      case '3':
        // SQL
        this.getLogSql(1)
        break
      case '4':
        // React
        break
      case '5':
        // Top
        this.getLogTop(1)
        break
      case '6':
        // Free
        this.getLogFree(1)
        break
      case '7':
        // Df
        this.getLogDf(1)
        break
      case '8':
        // Docker
        this.getLogDocker(1)
        break
      // case '9':
      //   // SerrerError
      //   this.getLogSerrerError(1)
      //   break
      // case '10':
      //   // SerrerOut
      //   this.getLogSerrerOut(1)
      //   break

      default:
    }
  }


  searchComponent = () => {
    return (
      <Form.Item
        label='検索'
        name='Search'
      >
        <Input
          onPressEnter={() => this.onSearch()}
        />
      </Form.Item>
    )
  }


  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='log-query'>
        <Card title='システムログ照会'>
          <Form ref={this.formRef}>
            <div className='box_inner_horizontal' style={{ width: '100%' }}>
              <Form.Item
                label='期間'
                name='DateFChar'
                style={{ marginBottom: 0 }}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                name='TimeFChar'
                label=''
                style={{ marginBottom: 0 }}
              >
                <TimePicker
                  format='HH:mm'
                  style={{ width: 85 }}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}> ~ </Form.Item>

              <Form.Item
                name='DateTChar'
                style={{ marginBottom: 0 }}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                name='TimeTChar'
                label=''
                style={{ marginBottom: 0 }}>
                <TimePicker
                  format='HH:mm'
                  style={{ width: 85 }}
                />
              </Form.Item>

              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  this.onSearch()
                }}
              >
                <span className='btn_label'>
                  検索
                </span>
              </Button>
            </div>

            <div className='box_inner_horizontal' style={{ width: '100%', paddingTop: 10 }}>
              <Form.Item
                label='使用容量'
                name='Used'
              >
                <div style={{ width: 'fit-content' }}>{this.formRef.current?.getFieldValue('Used')}</div>
              </Form.Item>
              <Form.Item
                label='空き容量'
                name='Available'
              >
                <div style={{ width: 'fit-content' }}>{this.formRef.current?.getFieldValue('Available')}</div>
              </Form.Item>
              <Form.Item
                label='使用率'
                name='Usepercent'
              >
                <div style={{ width: 'fit-content' }}>{this.formRef.current?.getFieldValue('Usepercent')}</div>
              </Form.Item>
            </div>

            <Tabs
              key='defalt'
              defaultActiveKey='1'
              onChange={async (activeKey) => {
                // 検索文字を初期化
                await this.formRef.current.setFieldsValue({ Search: '' })
                // タブキー削除
                await this.setState({ selectedTabKey: activeKey })
                // データ切替
                await this.onSearch()
              }}
            >
              <Tabs.TabPane
                tab='Laravel'
                key='1'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  {this.searchComponent()}
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceLaravel}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectLaravel?.id) ? 300 : resizableTableScroll(90) }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectLaravel?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectLaravel: record
                          })
                          // Laravel詳細ログを取得
                          await this.getLogDetailLaravel()
                        }
                      }
                    }}
                    // eslint-disable-next-line no-undef
                    pagination={this.state.paginationLaravel.total > this.state.paginationLaravel.pageSize ? this.state.paginationLaravel : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />
                    <Table.Column
                      title='レベル'
                      dataIndex='level'
                      width={120}
                    />
                    <Table.Column
                      title='ログ内容'
                      dataIndex='log_content'
                      render={(value, record) =>
                        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {value}
                        </div>
                      }
                    />
                  </Table>

                  <TextArea
                    style={{ display: (this.state.rowSelectLaravel?.id) ? '' : 'none', marginTop: 10 }}
                    value={this.state.detalLogLaravel}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane>


              <Tabs.TabPane
                tab='API'
                key='2'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  {this.searchComponent()}
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceApi}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectApi?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectApi?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectApi: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationApi.total > this.state.paginationApi.pageSize ? this.state.paginationApi : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />
                    <Table.Column
                      title='メソッド'
                      dataIndex='method'
                      width={120}
                    />
                    <Table.Column
                      title='APIパス'
                      dataIndex='uri'
                      render={(value, record) =>
                        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {value}
                        </div>
                      }
                    />
                    <Table.Column
                      title='ステータス'
                      dataIndex='response_status'
                      width={120}
                    />
                    <Table.Column
                      title='時間'
                      dataIndex='time'
                      width={120}
                      render={(value, record) =>
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      }
                    />
                  </Table>

                  <Tabs
                    style={{ display: (this.state.rowSelectApi?.id) ? '' : 'none' }}
                  >
                    {apiDetailList.map((obj, index) => {
                      let value = this.state.rowSelectApi[obj.keyName] ?? ''
                      if (value !== null && typeof value === 'object') {
                        // Object型をJSONに変換
                        value = JSON.stringify(value, null, "\t")
                      }
                      return (
                        <Tabs.TabPane
                          tab={obj.title}
                          key={obj.key}
                        >
                          <TextArea
                            value={value}
                            rows={10}
                            maxLength={10}
                            readOnly
                          />
                        </Tabs.TabPane>
                      )
                    })}
                  </Tabs>
                </div>
              </Tabs.TabPane>


              <Tabs.TabPane
                tab='SQL'
                key='3'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  {this.searchComponent()}
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceSql}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectSql?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectSql?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectSql: record,
                            detalLogSql: record.sql
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationSql.total > this.state.paginationSql.pageSize ? this.state.paginationSql : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />
                    <Table.Column
                      title='DB'
                      dataIndex='connection'
                      width={120}
                    />
                    <Table.Column
                      title='クエリ文'
                      dataIndex='sql'
                      render={(value, record) =>
                        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {value}
                        </div>
                      }
                    />
                    <Table.Column
                      title='時間'
                      dataIndex='time'
                      width={120}
                      render={(value, record) =>
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      }
                    />
                  </Table>

                  <Tabs
                    style={{ display: (this.state.rowSelectSql?.id) ? '' : 'none' }}
                  >
                    {sqlDetailList.map((obj, index) => {
                      let value = this.state.rowSelectSql[obj.keyName] ?? ''
                      return (
                        <Tabs.TabPane
                          tab={obj.title}
                          key={obj.key}
                        >
                          <TextArea
                            value={value}
                            rows={10}
                            maxLength={10}
                            readOnly
                          />
                        </Tabs.TabPane>
                      )
                    })}

                  </Tabs>
                </div>
              </Tabs.TabPane>

              {/*
               ※実装は保留、いずれ実装したいが、いいライブラリが見つからない。。
               <Tabs.TabPane
                 tab='React'
                 key='4'>
               </Tabs.TabPane>
              */}

              <Tabs.TabPane
                tab='Top'
                key='5'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceTop}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectTop?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectTop?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectTop: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationTop.total > this.state.paginationTop.pageSize ? this.state.paginationTop : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />

                    <Table.Column
                      title='ログ内容'
                      dataIndex='connection'
                    />
                  </Table>

                  < TextArea
                    style={{ display: (this.state.rowSelectTop?.id) ? '' : 'none', marginTop: 10 }}
                    value={JSON.stringify(this.state.rowSelectTop?.connection, null, "\t")}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab='Free'
                key='6'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceFree}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectFree?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectFree?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectFree: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationFree.total > this.state.paginationFree.pageSize ? this.state.paginationFree : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />

                    <Table.Column
                      title='ログ内容'
                      dataIndex='connection'
                    />
                  </Table>

                  < TextArea
                    style={{ display: (this.state.rowSelectFree?.id) ? '' : 'none', marginTop: 10 }}
                    value={JSON.stringify(this.state.rowSelectFree?.connection, null, "\t")}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab='Df'
                key='7'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceDf}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectDf?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectDf?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectDf: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationDf.total > this.state.paginationDf.pageSize ? this.state.paginationDf : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />

                    <Table.Column
                      title='ログ内容'
                      dataIndex='connection'
                    />
                  </Table>

                  < TextArea
                    style={{ display: (this.state.rowSelectDf?.id) ? '' : 'none', marginTop: 10 }}
                    value={JSON.stringify(this.state.rowSelectDf?.connection, null, "\t")}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab='Docker'
                key='8'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceDocker}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectDocker?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectDocker?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectDocker: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationDocker.total > this.state.paginationDocker.pageSize ? this.state.paginationDocker : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />

                    <Table.Column
                      title='ログ内容'
                      dataIndex='connection'
                    />
                  </Table>

                  < TextArea
                    style={{ display: (this.state.rowSelectDocker?.id) ? '' : 'none', marginTop: 10 }}
                    value={JSON.stringify(this.state.rowSelectDocker?.connection, null, "\t")}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane>

              {/* <Tabs.TabPane
                tab='SerrerError'
                key='9'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  {this.searchComponent()}
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceSerrerError}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectSerrerError?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectSerrerError?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectSerrerError: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationSerrerError.total > this.state.paginationSerrerError.pageSize ? this.state.paginationSerrerError : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />

                    <Table.Column
                      title='ログ内容'
                      dataIndex='connection'
                    />
                  </Table>

                  < TextArea
                    style={{ display: (this.state.rowSelectSerrerError?.id) ? '' : 'none', marginTop: 10 }}
                    value={JSON.stringify(this.state.rowSelectSerrerError?.connection, null, "\t")}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane> */}

              {/* <Tabs.TabPane
                tab='SerrerOut'
                key='10'
              >
                <div className='box_container' style={{ gap: 0 }}>
                  {this.searchComponent()}
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSourceSerrerOut}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelectSerrerOut?.id) ? 250 : 500 }}
                    rowClassName={(record, index) => record.id === this.state.rowSelectSerrerOut?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async () => {
                          await this.setState({
                            rowSelectSerrerOut: record,
                          })
                        }
                      }
                    }}
                    // ※特別仕様のpagination（取得件数が多い為バックエンド側でもペジネーションしている）
                    pagination={this.state.paginationSerrerOut.total > this.state.paginationSerrerOut.pageSize ? this.state.paginationSerrerOut : false}
                  >
                    <Table.Column
                      title='日時'
                      dataIndex='date_on'
                      width={180}
                    />

                    <Table.Column
                      title='ログ内容'
                      dataIndex='connection'
                    />
                  </Table>

                  < TextArea
                    style={{ display: (this.state.rowSelectSerrerOut?.id) ? '' : 'none', marginTop: 10 }}
                    value={JSON.stringify(this.state.rowSelectSerrerOut?.connection, null, "\t")}
                    rows={10}
                    maxLength={10}
                    readOnly
                  />
                </div>
              </Tabs.TabPane> */}
            </Tabs>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LogQuery)
