import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-input/dunning-print",
  handleF12UserPrint: "/api/insure-guide-input/dunning-print/f12-user-print",
  print: "/api/insure-guide-input/dunning-print/print",
  preview: "/api/insure-guide-input/dunning-print/preview",
};

const DunningPrintService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async handleF12UserPrint(params) {
    return axios.post(API_LIST.handleF12UserPrint, params);
  },
  async print(params) {
    return axios.post(API_LIST.print, params);
  },
  async preview(params) {
    return axios.post(API_LIST.preview, params, { responseType: 'blob' });
  },
};

export default DunningPrintService;
