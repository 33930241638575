import { message } from "antd";
import ElectronicMedicalRecordsLinkageFrameSetService from "services/CooperationRelated/ElectronicMedicalRecordsLinkageFrameSet/ElectronicMedicalRecordsLinkageFrameSetService.js";

const ElectronicMedicalRecordsLinkageFrameSetAction = {
  index(data) {
    return ElectronicMedicalRecordsLinkageFrameSetService.index(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  addNewData(data) {
    return ElectronicMedicalRecordsLinkageFrameSetService.addNewData(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  editData(data) {
    return ElectronicMedicalRecordsLinkageFrameSetService.editData(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(data) {
    return ElectronicMedicalRecordsLinkageFrameSetService.delete(data)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default ElectronicMedicalRecordsLinkageFrameSetAction;