import QuerySiteInfoService from 'services/InspectionMaintenance/NormalValueSettingMaintain/QuerySiteInfoService'
import { message } from 'antd'

const QuerySiteInfoAction = {
  index(params) {
    return QuerySiteInfoService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  getPosition(data) {
    return QuerySiteInfoService.getPosition(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  }
}
export default QuerySiteInfoAction
