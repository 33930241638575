import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Input } from 'antd'
import InspectInquiryAction from 'redux/IntroductionLetter/IntroduceLetterExtract/InspectInquiry.action.js'

class WS0903002_InspectInquiry extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '検査照会'
    this.state = {
      dataSource: [],
      pagination: {
        // eslint-disable-next-line no-undef
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      contract_short_name: '',
      kanji_name: '',
      sex: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    // データ取得
    this.index()
  }

  /**
   * データ取得
   */
  index = (value) => {
    console.log(this.props);
    const params = {
      visit_date_on: this.props.Li_Date,
      personal_number_id: this.props.Li_PersonalNum,
      accepted_no: this.props.Li_AcceptNum,
      reservation_number: this.props.Li_ReserveNum,
      Search: value ? value : null
    }
    InspectInquiryAction.index(params)
      .then((res) => {
        this.setState({
          dataSource: res.data,
          contract_short_name: res.contract_short_name,
          kanji_name: res.kanji_name,
          sex: res.sex,
        })
      })
  }

  /**
   * 検査コード選択
   * @param {*} record
   */
  select = (record) => {
    if (this.props.onFinishScreen) {
      const output = {
        exam_code: record.exam_code,
        exam_name: record.exam_name,
      }
      this.props.onFinishScreen(output)
    }
  }

  render() {
    return (
      <div className='inspect-inquiry'>
        <Card
          title={`検査照会 ${this.props.subTitle ?? ''}`}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            labelCol={{ span: 2 }}
            labelAlign='right'
          >
            <Form.Item
              name='Li_Date'
              label='受診日'
            >
              <div>{this.props.Li_Date}</div>
            </Form.Item>

            <Form.Item
              name='Li_AcceptNum'
              label='受診No'>
              <div>{this.props.Li_AcceptNum}</div>
            </Form.Item>

            <Form.Item label='コース' name='Li_Course'>
              <div style={{ display: 'flex' }}>
                <div>{this.props.Li_Course}</div>
                <div>{this.state.contract_short_name}</div>
              </div>
            </Form.Item>
            <Form.Item name='Search' label='検索'>
              <Input
                className='input-size-30'
                type='text'
                onPressEnter={(e) => this.index(e.target.value)}
              />
            </Form.Item>
          </Form>

          <Table
            bordered
            size='small'
            rowKey={(record) => record.id}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            dataSource={this.state.dataSource}
            scroll={{ y: 450 }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  // 親に反映
                  this.select(record)
                }
              }
            }}
          >
            <Table.Column
              title='検査コード'
              dataIndex='exam_code'
              width={120}
              render={(value, record, index) =>
                <div style={{ textAlign: 'right' }}>{value}</div>
              }
            />
            <Table.Column
              title='検査名称'
              dataIndex='exam_name'
            />
          </Table>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0903002_InspectInquiry)
