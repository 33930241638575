import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Modal, Checkbox, message, Tooltip, Dropdown, Menu } from 'antd'
import { InfoCircleOutlined, SearchOutlined, DeleteOutlined, PrinterOutlined, WarningOutlined, CloseCircleOutlined, InfoOutlined } from '@ant-design/icons'
import moment from 'moment'
import Color from 'constants/Color'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import FollowUpAction from 'redux/IntroductionLetter/FollowUp/FollowUp.action.js'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import FollowUpInput from 'pages/SK_IntroductionLetter/FollowUp/FollowUpInput.jsx'
import DeleteConfirm from 'pages/SK_IntroductionLetter/FollowUp/DeleteConfirm.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
class FollowUp extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '経過観察'

    this.state = {
      loading: false,
      dataSource: [],
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      StsAllSelectSwitch: 0,
      rowSelect: [],
      actionType: 0,
      selectedRowKeys: [],
      selectedRows: []
    }
  }

  componentDidMount = () => {
    //年月と受診日（年月の6ヶ月前）の初期値を設定
    let today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    //受診日（月初）
    startDate.setMonth(startDate.getMonth() - 6)
    startDate.setDate(1)
    //受診日（月末）
    endDate.setMonth(endDate.getMonth() - 5)
    endDate.setDate(0)

    this.formRef.current?.setFieldsValue({
      yearAndMonthF: moment(today),
      yearAndMonthT: moment(today),
      DateFChar: moment(startDate),
      DateTChar: moment(endDate)
    })
  }

  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0248001_PersonalInfoSearchQuery
            Li_PersonalNum={this.formRef.current?.getFieldValue('personalNum')}
            onFinishScreen={(output) => {
              if (output?.Lio_OfficeCode) {
                this.setState({ disableBranch: false })
              }
              this.formRef.current?.setFieldsValue({
                personalNum: output?.Lo_PersonalNumId,
                kanji_name: output?.recordData.kanji_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  onSearch = () => {
    let params = {
      DateFChar: this.formRef.current?.getFieldValue('DateFChar') ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD') : '',
      DateTChar: this.formRef.current?.getFieldValue('DateTChar') ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD') : '',
      personalNum: this.formRef.current?.getFieldValue('personalNum')
    }

    FollowUpAction.GetScreenData(params)
      .then(res => {
        let arrTemp = []
        let arrTempRecord = []
        res.forEach((value, index) => {
          if (value.grasp !== '〇') {
            arrTemp.push(value.id)
            arrTempRecord.push(value)
          }
        })

        this.setState({
          dataSource: res,
          selectedRowKeys: arrTemp,
          selectedRows: arrTempRecord
        })
      })
  }

  onSelect = (record) => {
    let arrTemp = [...this.state.selectedRowKeys]
    let arrTempRecord = [...this.state.selectedRows]
    let idx = arrTemp.indexOf(record.id)
    if (idx === -1) {
      arrTemp.push(record.id)
      arrTempRecord.push(record)
    } else {
      arrTemp.splice(idx, 1)
      arrTempRecord.splice(idx, 1)
    }
    this.setState({
      selectedRowKeys: arrTemp,
      selectedRows: arrTempRecord
    })
  }

  onSelectAll = (selected) => {
    let arrTemp = []
    let arrTempRecord = []
    if (selected) {
      arrTempRecord = [...this.state.dataSource]
      arrTemp = arrTempRecord.map(item => item.id)
      this.setState({
        selectedRowKeys: arrTemp,
        selectedRows: arrTempRecord
      })
    } else {
      this.setState({
        selectedRowKeys: [],
        selectedRows: []
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  changeFYearAndMonth = (day, dateString) => {
    let monthStart = dateString
    let monthEnd = this.formRef.current?.getFieldValue('yearAndMonthT').format('YYYY/MM')

    if (monthEnd && monthStart > monthEnd) {
      let newDateString = this.formRef.current?.getFieldValue('yearAndMonthT').format('YYYY/MM')
      let year = Number(newDateString.substr(0, 4))
      let month = Number(newDateString.substr(5, 2)) - 1
      let date = this.formRef.current?.getFieldValue('DateFChar') ? Number(this.formRef.current?.getFieldValue('DateFChar').format('DD')) : 1
      let newDate = new Date(year, month, date)
      newDate.setMonth(newDate.getMonth() - 6)

      this.formRef.current?.setFieldsValue({
        yearAndMonthF: moment(newDateString),
        DateFChar: moment(newDate)
      })
    } else {
      let newDateString = monthStart
      let year = Number(newDateString.substr(0, 4))
      let month = Number(newDateString.substr(5, 2)) - 1
      let date = this.formRef.current?.getFieldValue('DateFChar') ? Number(this.formRef.current?.getFieldValue('DateFChar').format('DD')) : 1
      let newDate = new Date(year, month, date)
      newDate.setMonth(newDate.getMonth() - 6)

      this.formRef.current?.setFieldsValue({
        yearAndMonthF: moment(newDateString),
        DateFChar: moment(newDate)
      })
    }
  }

  changeTYearAndMonth = (day, dateString) => {
    let monthStart = this.formRef.current?.getFieldValue('yearAndMonthF').format('YYYY/MM')
    let monthEnd = dateString

    // let newDateString = ''
    if ((!monthEnd && monthStart) || (monthStart && monthStart > monthEnd)) {
      let newDateString = this.formRef.current?.getFieldValue('yearAndMonthF').format('YYYY/MM')
      let year = Number(newDateString.substr(0, 4))
      let month = Number(newDateString.substr(5, 2)) - 1
      let date = this.formRef.current?.getFieldValue('DateTChar') ? Number(this.formRef.current?.getFieldValue('DateTChar').format('DD')) : 1
      let newDate = new Date(year, month, date)
      newDate.setMonth(newDate.getMonth() - 6)

      this.formRef.current?.setFieldsValue({
        yearAndMonthT: moment(newDateString),
        DateTChar: moment(newDate)
      })
    } else {
      let newDateString = dateString
      let year = Number(newDateString.substr(0, 4))
      let month = Number(newDateString.substr(5, 2)) - 1
      let date = this.formRef.current?.getFieldValue('DateTChar') ? Number(this.formRef.current?.getFieldValue('DateTChar').format('DD')) : 1
      let newDate = new Date(year, month, date)
      newDate.setMonth(newDate.getMonth() - 6)

      this.formRef.current?.setFieldsValue({
        DateTChar: moment(newDate)
      })
    }
  }

  /**
   * 受付日　始め
   */
  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('DateFChar')
      ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('DateTChar')
      ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD')
      : null
    if (dateEnd && dateStart > dateEnd) {
      this.formRef.current?.setFieldsValue({
        DateFChar:
          this.formRef.current?.getFieldValue('DateTChar'),
      })
    }
  }

  /**
   * 受付日　終わり
   */
  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('DateFChar')
      ? this.formRef.current?.getFieldValue('DateFChar').format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('DateTChar')
      ? this.formRef.current?.getFieldValue('DateTChar').format('YYYY/MM/DD')
      : null
    if ((!dateEnd && dateStart) || (dateStart && dateStart > dateEnd)) {
      this.formRef.current?.setFieldsValue({
        DateTChar:
          this.formRef.current?.getFieldValue('DateFChar'),
      })
    }
  }

  showFollowUpInput = (record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <FollowUpInput
            visit_date={record?.visit_date}
            personal_number_id={record?.personal_number_id}
            kanji_name={record?.kanji_name}
            course_level={record?.course_level}
            reservation_number={record?.reservation_number}
            onFinishScreen={() => {
              this.onSearch()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  deleteFollowUpData = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <DeleteConfirm
            selectedRows={this.state.selectedRows}
            dataSource={this.state.dataSource}
            onDeleteDatas={() => {
              this.onSearch()
              this.closeModal()
            }}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  print_f12 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            // Li_PreviewSpecifiedValue={false}
            Li_PrinterNoSpecifiedValue={0}
            Lo_Preview={false}
            Lo_PrinterNo={0}
            Lo_StsOutput={true}
            onFinishScreen={(res) => {
              this.closeModal()
              if (res.Lo_StsOutput) {
                let values = {
                  previewMode: res.Lo_Preview,
                  printerNumber: res.Lo_PrinterNo
                }
                this.printAction(values)
              }
            }}
          />),
      },
    })
  }

  printAction = (values) => {
    let params = {
      selectedRows: this.state.selectedRows,
      printerNumber: values.printerNumber,
      previewMode: values.previewMode
    }
    if (values.previewMode) {
      FollowUpAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          if (res) {
            let fileURL = URL.createObjectURL(res);
            // pdfを別タブで表示
            window.open(fileURL)
            this.onSearch()
          }
        })
    } else {
      FollowUpAction.print(params)
        .then(res => {
          if (res === 'true') {
            message.success('印刷が完了しました')
          } else {
            message.error('処理の途中でエラーが発生しました')
          }
        })
    }
  }

  render() {
    return (
      <div className='introduce-letter-extract'>
        <Card title='経過観察'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ ProcessDivision: 0 }}
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal' style={{ width: '100%' }}>
                <div className='box_inner_horizontal'>
                  <div className='box_search' >
                    <div className='box_search_inner'>
                      <div className='box_inner_horizontal'>
                        <Form.Item name='yearAndMonthF' label='　年　月'>
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            allowClear={false}
                            format='YYYY/MM'
                            picker='month'
                            style={{ width: '60px' }}
                            onChange={(date, dateString) => this.changeFYearAndMonth(date, dateString)}
                          />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item name='yearAndMonthT'>
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            allowClear={false}
                            format='YYYY/MM'
                            picker='month'
                            style={{ width: '60px' }}
                            onChange={(date, dateString) => this.changeTYearAndMonth(date, dateString)}
                          />
                        </Form.Item>
                      </div>
                      <div className='box_inner_horizontal' >
                        <Form.Item
                          label='　受診日'
                          name='DateFChar'
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            onBlur={() => {
                              this.checkDateStart()
                            }} />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item
                          name='DateTChar'
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            onBlur={() => {
                              this.checkDateEnd()
                            }} />
                        </Form.Item>
                      </div>

                      <div className='box_inner_horizontal'>
                        <Form.Item
                          name='personalNum'
                          label='個人番号'
                          style={{ marginBottom: 0 }}
                        >
                          <Input.Search
                            allowClear={true}
                            className='input-search-size-number-allow-clear-10'
                            style={{ textAlign: 'right' }}
                            onPressEnter={() => {
                              this.showWS0248001_PersonalInfoSearchQuery()
                            }}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.formRef.current?.setFieldsValue({
                                  personal_number_id: '',
                                  kanji_name: ''
                                })
                                this.setState({
                                  personal_number_id: '',
                                  kanji_name: ''
                                })
                              } else {
                                this.showWS0248001_PersonalInfoSearchQuery()
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name='kanji_name' style={{ marginBottom: 0 }}>
                          <div>{this.formRef.current?.getFieldValue('kanji_name')}</div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() =>
                          this.onSearch()
                        }
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </div>
                  </div>

                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                scroll={{ x: 'max-content' }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                onRow={(record, index) => ({
                  onDoubleClick: () => {
                    this.showFollowUpInput(record)
                  }
                })}
                rowKey={(record) => record.id}
                rowClassName={(record, index) => { return record.id === this.state.rowSelect.id ? 'active-row' : '' }}
                rowSelection={{
                  type: 'checkbox',
                  fixed: 'left',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record) => {
                    // 行選択
                    this.onSelect(record)
                  },
                  onSelectAll: (selected) => {
                    // 行全選択
                    this.onSelectAll(selected)
                  }
                }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date'
                  className='column-size-date'
                  render={(value, record) => {
                    let index = this.state.dataSource.findIndex(el => el.id == record.id)
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground }}>
                        {moment(record?.visit_date).format('YYYY/MM/DD (ddd)')}
                      </div>
                    )
                  }} />

                <Table.Column
                  title='受付No'
                  dataIndex='receipt_number'
                  // sorter={(a, b) => a.receipt_number - b.receipt_number}
                  render={(value, record) => {
                    let index = this.state.dataSource.findIndex(el => el.id == record.id)
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground, textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  // sorter={(a, b) => a.personal_number_id - b.personal_number_id}
                  render={(value, record) => {
                    let index = this.state.dataSource.findIndex(el => el.id == record.id)
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground, textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  width={40}
                  title='メモ'
                  dataIndex='importance'
                  render={(value, record, index) => {
                    let icon = null
                    switch (value) {
                      case 1:
                        icon = <InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />
                        break
                      case 3:
                        icon = <WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />
                        break
                      case 5:
                        icon = <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
                        break
                      default:
                        icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                        break
                    }
                    return (
                      <div
                        style={{ textAlign: 'center', cursor: 'pointer' }}
                        hidden={!record.personal_number_id}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component: (
                                <WS2584019_PersonalInfoInquirySub
                                  Li_PersonalNum={record.personal_number_id}
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}>
                        {icon}
                      </div>
                    )
                  }} />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  // sorter={(a, b) => a.kanji_name.localeCompare(b.kanji_name, 'ja')}
                  render={(value, record) => {
                    let index = this.state.dataSource.findIndex(el => el.id == record.id)
                    return (
                      <div style={{ color: Color(record?.Expression_23)?.Foreground }}>{value}</div>
                    )
                  }}
                />

                <Table.Column
                  title='発行'
                  dataIndex='issue'
                />

                <Table.Column
                  title='把握'
                  dataIndex='grasp'
                />

                <Table.Column
                  title='受診'
                  dataIndex='inspect'
                />
              </Table>

              <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
                <Button
                  danger
                  type='primary'
                  icon={<DeleteOutlined />}
                  style={{ float: 'right' }}
                  onClick={() => this.deleteFollowUpData()}
                >
                  削除
                </Button>

                <Button
                  icon={<PrinterOutlined />}
                  type='primary'
                  style={{ float: 'right' }}
                  onClick={() => this.print_f12()}
                >
                  印刷
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUp)
