import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DesignatedInspectVisitsHistoryAction from 'redux/InputBusiness/NotInputCheckCategory/DesignatedInspectVisitsHistory.action'
import Color from 'constants/Color'
import { Card, Table, Form, Tooltip } from 'antd'


class WS2637036_DesignatedInspectVisitsHistory extends React.Component {
  static propTypes = {
    Li_PersonalNum: PropTypes.any,

  }

  constructor(props) {
    super(props)

    // document.title = '指定検査受診履歴'

    this.state = {
      isLoading: false,
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      Li_PersonalNum: this.props.Li_PersonalNum,
      Li_VisitDate: this.props.Li_VisitDate,
      Li_InspectHistoryOp: this.props.Li_InspectHistoryOp,
    }
    DesignatedInspectVisitsHistoryAction.index(params)
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  render() {
    return (
      <div className='designated-inspect-visits-history'>
        <Card title='指定検査受診履歴'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.key}
            scroll={{ y: 500 }}
          >
            <Table.Column
              title='受診日'
              dataIndex='visit_date_on'
              width={110}
            />

            <Table.Column
              title='受付No'
              dataIndex='receipt_number'
              width={70}
              render={(value, record, index) => (
                <div style={{ textAlign: 'right' }}>{value}</div>
              )}
            />
            <Table.Column
              title='コース'
              dataIndex='visit_course'
              width={60}
            />
            <Table.Column
              title='契約名称'
              dataIndex='contract_short_name'
            />
            <Table.Column
              title='検査略称'
              dataIndex='exam_short_name'
              width={120}
              render={(value, record, index) => (
                <Tooltip title={record?.exam_code}>
                  <div>{value}</div>
                </Tooltip>
              )}
            />
            <Table.Column
              title='結果値'
              dataIndex='result_value'
              width={100}
              render={(value, record, index) => (
                <div>{value}</div>
              )}
            />
            <Table.Column
              title='判定'
              dataIndex='judgment_value'
              width={60}
              render={(value, record, index) => (
                <div style={{
                  color: Color(record.judgmentValueColorNum),
                  textAlign: 'center'
                }}>
                  {value}
                </div>
              )}
            />
          </Table>

        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637036_DesignatedInspectVisitsHistory)
