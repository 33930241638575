import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Space, Button, Input, Modal } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx'
import CourseDepartmentspecificFormatAction from 'redux/SK_IntroductionLetter/CourseDepartmentspecificFormat/CourseDepartmentspecificFormat.action.js'

class WS0923004_DepartmentspecificEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コース・診療科別書式保守'

    this.state = {
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      id: '',
      course_code: ''
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      id: this.props.id,
      department: this.props.department,
      department_name: this.props.department_name,
      format_name: this.props.format_name,
      course_code: this.props.course_code
    })
    this.setState({
      id: this.props.id,
      course_code: this.props.course_code
    })
  }

  showIntroduceLetterVariousMasterInquiry = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_SearchChar={this.formRef.current?.getFieldValue('Department')}
            Li_ManageCode={7}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                department: output.recordData.various_codes,
                department_name: output.recordData.department_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  save = () => {
    if (!this.formRef.current?.getFieldValue('department')) {
      Modal.error({ content: '診療科を指定してください' })
    } else {
      let params = {
        id: this.state.id,
        course_code: this.state.course_code,
        department: this.formRef.current?.getFieldValue('department'),
        format_name: this.formRef.current?.getFieldValue('format_name'),
      }
      CourseDepartmentspecificFormatAction.saveDepartmentspecificFormat(params)
        .then(res => {
          this.props.onFinishScreen()
        })
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render = () => {
    return (
      <div className='departmentspecific-edit' style={{ width: 700 }}>
        <Card title='診療科別書式編集'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item name='department' label='診療科'>
                  <Input.Search
                    className='input-search-size-number-allow-clear-5'
                    allowClear={true}
                    onPressEnter={(e) => { this.showIntroduceLetterVariousMasterInquiry(e.target.value) }}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.formRef.current?.setFieldsValue({
                          department: '',
                          department_name: ''
                        })
                      } else {
                        this.showIntroduceLetterVariousMasterInquiry(value)
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name='department_name'>
                  <div>{this.formRef.current?.getFieldValue('department_name')}</div>
                </Form.Item>
              </div>
              <Form.Item name='format_name' label='　書式'>
                <Input />
              </Form.Item>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  onClick={() => this.save()}
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0923004_DepartmentspecificEdit)
