import OptionalInfoMaintainService from "services/SM_SystemMaintenance/OptionalInfoMaintain/OptionalInfoMaintainService";
import { message } from "antd";

const OptionalInfoMaintainAction = {
  loadScreenData() {
    return OptionalInfoMaintainService.loadScreenData()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  searchOption(params) {
    return OptionalInfoMaintainService.searchOption(params)
      .then((res) => {
        console.log('OK');
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default OptionalInfoMaintainAction;