import axios from 'configs/axios';

const API_LIST = {
  index: '/api/passing-manage-progress/terminal-list',
};

const TerminalListService = {
  async index() {
    return axios.get(API_LIST.index);
  },
};

export default TerminalListService;
