import { message } from 'antd'
import MiraisSingleDoctorSettingService from 'services/CooperationRelated/MiraisSingleTransmission/MiraisSingleDoctorSettingService.js'

const MiraisSingleDoctorSettingActions = {
  index(params) {
    return MiraisSingleDoctorSettingService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  saveAndUpdateData(params) {
    return MiraisSingleDoctorSettingService.saveAndUpdateData(params)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  delete(data) {
    return MiraisSingleDoctorSettingService.delete(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default MiraisSingleDoctorSettingActions