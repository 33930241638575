import React from "react";
import { connect } from "react-redux";

import { Card, Button,Space } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";

import WS0479001_PersonalInfoCsvOutput from 'pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0479001_PersonalInfoCsvOutput.jsx';
import WS0480003_OfficeInfoCsvOutput from 'pages/TO_StatisticalServices/V2MS0150_OfficeInfoCsvOutput/WS0480003_OfficeInfoCsvOutput.jsx';
import WS0410001_MasterListOutputSiteClassify from './WS0410001_MasterListOutputSiteClassify';
import WS0411001_MasterListOutputCategoryInfo from "./WS0411001_MasterListOutputCategoryInfo";
import WS0412001_MasterListOutputSiteInfo from "./WS0412001_MasterListOutputSiteInfo";
import WS0413001_MasterListOutputFindingInfo from "./WS0413001_MasterListOutputFindingInfo";
import WS0414001_MasterListOutputInspectItem from "./WS0414001_MasterListOutputInspectItem";
import WS0415001_MasterListOutputLeadershipMatter from "./WS0415001_MasterListOutputLeadershipMatter";
import WS0417001_MasterListOutputInspectValueJudgeInfo from "./WS0417001_MasterListOutputInspectValueJudgeInfo";
import WS0420001_MasterListOutputInsuranceInfo from "./WS0420001_MasterListOutputInsuranceInfo";

class WS2694001_MasterListOutput extends React.Component {

  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingTableData: false,
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="master-list-output" style={{width: 700}}>
        <Card className="mb-2" title="マスタ一覧出力">
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.06)', padding: '0.5em', width: 400 }}>
            <h3>
              属 性
            </h3>
            <Space>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0479001_PersonalInfoCsvOutput
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >個　人</Button>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0480003_OfficeInfoCsvOutput
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >事業所</Button>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0420001_MasterListOutputInsuranceInfo
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >保険者</Button>
            </Space>
          </div>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.06)', padding: '0.5em', marginTop: '1em', width: 400 }}>
            <h3>
              検 査
            </h3>
            <Space>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0411001_MasterListOutputCategoryInfo
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >カテゴリ</Button>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0414001_MasterListOutputInspectItem
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >検　査</Button>
            </Space>
          </div>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.06)', padding: '0.5em', marginTop: '1em', width: 400 }}>
            <h3>
              所 見
            </h3>
            <Space>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0410001_MasterListOutputSiteClassify
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >部位分類</Button>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0412001_MasterListOutputSiteInfo
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >部　位</Button>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0413001_MasterListOutputFindingInfo
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >所　見</Button>
            </Space>
          </div>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.06)', padding: '0.5em', marginTop: '1em', width: 400 }}>
            <h3>
              判 定
            </h3>
            <Space>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0415001_MasterListOutputLeadershipMatter
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >指　導</Button>
              <Button type="primary"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    childModal: {
                      width: 800,
                      visible: true,
                      component: (
                        <WS0417001_MasterListOutputInspectValueJudgeInfo
                          onFinishScreen={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            });
                          }}
                        />
                      )
                    }
                  });
                }}
              >判　定</Button>
            </Space>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2694001_MasterListOutput);
