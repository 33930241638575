import axios from 'configs/axios';

const API_LIST = {
  getScreenData: '/api/csv-input-output/csv-input-output/get-screen-data',
  downloadFile: '/api/csv-input-output/csv-input-output/download-file',
  captureFile: '/api/csv-input-output/csv-input-output/capture-file',
};

const CsvInputOutputService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, {params});
  },
  async downloadFile(params) {
    return axios.post(API_LIST.downloadFile, params, { responseType: 'arraybuffer' });
  },
  async captureFile(params) {
    return axios.post(API_LIST.captureFile, params);
  },
};

export default CsvInputOutputService;