import axios from 'configs/axios'

const API_LIST = {
  index: '/api/introduce-letter-extract/format-select',
  confirm: '/api/introduce-letter-extract/format-select/confirm',
}

const FormatSelectService = {
  async index() {
    return axios.get(API_LIST.index)
  },
  async confirm(params) {
    return axios.post(API_LIST.confirm, params)
  }
}

export default FormatSelectService
