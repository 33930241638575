import { message } from "antd";
import testActions from "pages/testActions/testActions";
import FacilityConsumptionTaxSettingService from "services/SystemMaintenance/FacilityConsumptionTaxSetting/FacilityConsumptionTaxSettingService";

const FacilityConsumptionTaxSettingAction = {
  GetTreeData() {
    return FacilityConsumptionTaxSettingService.getTreeData()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  GetFacilityInfo(data) {
    return FacilityConsumptionTaxSettingService.getFacilityInfo(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  GetScreenDataFacilityInfo() {
    return FacilityConsumptionTaxSettingService.getScreenDataFacilityInfo()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  UpdateFacilityInfo(data) {
    return FacilityConsumptionTaxSettingService.updateFacilityInfo(data)
  },

  GetDateList(data) {
    return FacilityConsumptionTaxSettingService.getDateList(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  AddNewDateFacility(data) {
    return FacilityConsumptionTaxSettingService.addNewDateFacility(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  UpdateDateFacility(data) {
    return FacilityConsumptionTaxSettingService.updateDateFacility(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  DeleteDateFacility(data) {
    return FacilityConsumptionTaxSettingService.deleteDateFacility(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  AddNewDateConsumptionTax(data) {
    return FacilityConsumptionTaxSettingService.addNewDateConsumptionTax(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  UpdateDateConsumptionTax(data) {
    return FacilityConsumptionTaxSettingService.updateDateConsumptionTax(data)
  },

  DeleteDateConsumptionTax(data) {
    return FacilityConsumptionTaxSettingService.deleteDateConsumptionTax(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  GetConsumptionTax(data) {
    return FacilityConsumptionTaxSettingService.getConsumptionTax(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  UpdateConsumptionTax(data) {
    return FacilityConsumptionTaxSettingService.updateConsumptionTax(data)
  },

  getApplyDate() {
    return FacilityConsumptionTaxSettingService.getApplyDate()
  },

  getFacility(data) {
    return FacilityConsumptionTaxSettingService.getFacility(data)
  },

  postFacility(data) {
    return FacilityConsumptionTaxSettingService.postFacility(data)
  },

  deleteFacilityInfo(data) {
    return FacilityConsumptionTaxSettingService.deleteFacilityInfo(data)
  },

  getTaxApplyDate() {
    return FacilityConsumptionTaxSettingService.getTaxApplyDate()
  },

  getTaxSetting(data) {
    return FacilityConsumptionTaxSettingService.getTaxSetting(data)
  },

  getDateReload(data) {
    return FacilityConsumptionTaxSettingService.getDateReload(data)
  },

  postAddApplyDate(data) {
    return FacilityConsumptionTaxSettingService.postAddApplyDate(data)
  },

  deleteTax(data) {
    return FacilityConsumptionTaxSettingService.deleteTax(data)
  },

  postTax(data) {
    return FacilityConsumptionTaxSettingService.postTax(data)
  },
}

export default FacilityConsumptionTaxSettingAction;