import React from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row, Table, Menu, Form, Dropdown, } from "antd";
import { PlusOutlined, } from "@ant-design/icons";
import { MoreOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0745002_NotInputCheckMaintainInput from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745002_NotInputCheckMaintainInput.jsx";
import WS0745003_NotInputCheckMaintainDetail from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745003_NotInputCheckMaintainDetail.jsx";
import NotInputCheckMaintainAction from "redux/InputBusiness/NotInputCheckCategory/NotInputCheckMaintain.action";

class WS0745001_NotInputCheckMaintain extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Lo_StsModify: PropTypes.any,
    Li_SearchChar: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = "未入力チェック保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      treeData: [],
      defaultShowTableParent: true,
      selectedRows: [],
      selectedNodes: [],
      isLoadingData1: false,
      isLoadingData2: false,
      isLoading: false,
      selectedRowKeys: [],
      dataSource: [],
      dataSource1: [],
      dataSource2: [],
      disabledRadio: false,
      isSave: false,
      isCancel: false,
      isRemove: true,
      count: 1001,
      id: 0,
      node_id: "",
      StsPatternCorrection: 0,
      Pattern: "",
      patternList: [],
    };
  }

  componentDidMount() {
    this.index();
  }

  index(params) {
    NotInputCheckMaintainAction.index(params)
      .then((res) => {
        this.setState({
          node_id: res?.node_id,
          patternList: res?.patternList
        });
        this.getTypeMaintenance(res?.node_id);
      });
  }

  /**
   * 削除
   * @param {*} code
   */
  deleteTypeMaintainByCode(code) {
    const params = {
      Code: code
    };
    NotInputCheckMaintainAction.deleteTypeMaintenanceByCode(params)
      .then(
        (res) => {
          this.getTypeMaintenance(this.state.node_id);
        }
      );
  }

  saveTypeMaintenance(obj, id = null) {
    const params = {
      id: id,
      Code: obj.Code,
      remarks: obj.remarks,
      patternCode: obj.patternCode,
    };
    NotInputCheckMaintainAction.saveTypeMaintenance(params)
      .then((res) => {
        this.getTypeMaintenance(this.state.node_id);
      });
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }

  getTypeMaintenance(node_id) {
    this.setState({ isLoading: true });
    NotInputCheckMaintainAction.typeMaintenance({
      node_id: node_id,
    })
      .then((res) => {
        this.setFormFieldValue("tableData", res);
        this.setState({
          ...this.state,
          dataSource: res,
          dataSource2: [],
          selectedRows: [],
          isRemove: true,
          isCancel: true,
          id: 0,
          disabledRadio: false,
        });
      })
      .catch(() => { })
      .finally(() => this.setState({ isLoading: false }));
  }

  onFinish(values) { }

  StateEnabledSave(event, name, data, dataSourceName) {
    let record = dataSourceName.map((r) => r);
    const value = event;
    const index = record.findIndex((s) => s.id === data.id);
    if (record[index][name] !== value) {
      this.setState({ isSave: true });
    } else {
      this.setState({ isSave: false });
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };


  render() {
    const { current } = this.state;

    return (
      <div className="not-input-check-maintain">
        <Card title="未入力チェック保守">

          <Form ref={this.formRef} onFinish={this.onFinish} autoComplete="off">
            <Row gutter={24}>
              <Col span={24} style={{ paddingRight: "5px" }}>
                <Table
                  dataSource={
                    this.formRef.current?.getFieldValue("tableData")
                      ? this.formRef.current?.getFieldValue("tableData")
                      : []
                  }
                  loading={this.isLoading}
                  pagination={false}
                  bordered={true}
                  size="small"
                  rowKey={(record) => record.id}
                  onRow={(record) => {
                    return {
                      onDoubleClick: async () => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '1500px',
                            className: '',
                            component:
                              <WS0745003_NotInputCheckMaintainDetail
                                record={record}
                                node_id={this.state.node_id}
                              />
                          }
                        })
                      }
                    }
                  }}
                >

                  <Table.Column
                    title="種別"
                    dataIndex="Code"
                    width={80}
                    render={(value) => (
                      <div
                        style={{ textAlign: 'right' }}>
                        {value}
                      </div>)}
                  />

                  <Table.Column
                    width={340}
                    title="名称"
                    dataIndex="remarks"
                  />

                  <Table.Column
                    width={340}
                    title="パターン"
                    dataIndex="patternName"
                  />

                  <Table.Column
                    width={40}
                    dataIndex="patternList"
                    title={
                      //新規登録のボタン
                      <Button
                        size="small"
                        type="primary"
                        style={{ textAlign: "center" }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '400px',
                              className: '',
                              component:
                                <WS0745002_NotInputCheckMaintainInput
                                  newFlag={true}
                                  patternList={this.state.patternList}
                                  selectedPatternCode={this.state.patternList[0]?.pattern_code}
                                  onUpdate={(output) => {
                                    this.saveTypeMaintenance(output)
                                    this.closeModal()
                                  }}
                                />
                            },
                          });
                        }}
                      ></Button>
                    }

                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger="click"
                          overlay={() => (
                            <Menu>

                              <Menu.Item
                                key="変更"
                                onClick={() =>
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '400px',
                                      className: '',
                                      component:
                                        <WS0745002_NotInputCheckMaintainInput
                                          newFlag={false}
                                          record={record}
                                          patternList={this.state.patternList}
                                          onUpdate={(output) => {
                                            this.saveTypeMaintenance(output, record.id)
                                            this.closeModal()
                                          }}
                                          onDelete={() => {
                                            this.deleteTypeMaintainByCode(record.Code)
                                            this.closeModal()
                                          }}
                                        />
                                    }
                                  }
                                  )}
                              > 変更
                              </Menu.Item>

                              <Menu.Item
                                key="詳細"
                                onClick={() =>
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '1500px',
                                      className: '',
                                      component:
                                        <WS0745003_NotInputCheckMaintainDetail
                                          record={record}
                                          node_id={this.state.node_id}
                                        />
                                    }
                                  })
                                }
                              >
                                詳細
                              </Menu.Item>

                            </Menu>
                          )}
                        >
                          <Button
                            size="small"
                            icon={<MoreOutlined />}
                            style={{ width: "100%" }}
                          ></Button>
                        </Dropdown>
                      );
                    }}
                  />
                </Table>
              </Col>
            </Row >
          </Form >
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          destroyOnClose={true}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0745001_NotInputCheckMaintain);