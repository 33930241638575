import { message } from "antd";
import SendEmailService from "services/MN_Basis/SendEmail/SendEmail.Service";

const SendEmailAction = {
    getScreenData(data) {
        return SendEmailService.getScreenData(data)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                const res = err.response;
                if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                }
                message.error(res.data.message);
            });
    },
    onClickSend(data) {
        return SendEmailService.onClickSend(data)
            .then((res) => {
                return res?.data;
            })
            .catch((err) => {
                const res = err.response;
                if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                }
                message.error(res.data.message);
            });
    },
}
export default SendEmailAction;