import axios from "configs/axios";
const API_LIST = {
  GetScreenData: "/api/payment-list-output/payment-list-output/get-screen-data",
  Printer_F12: "/api/payment-list-output/payment-list-output/f12",
  print: "/api/payment-list-output/payment-list-output/print",
  preview: "/api/payment-list-output/payment-list-output/preview",
};

const PaymentListOutputService = {
  async GetScreenData() {
    return axios.get(API_LIST.GetScreenData);
  },
  async Printer_F12(params) {
    return axios.get(API_LIST.Printer_F12, { params, responseType: 'blob', });
  },
  async print(params) {
    return axios.post(API_LIST.print, params);
  },
  async preview(params) {
    return axios.post(API_LIST.preview, params, { responseType: "blob" });
  },
};
export default PaymentListOutputService;
