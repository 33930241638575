/* eslint-disable eqeqeq */
import React from 'react'
import { PlusCircleOutlined, SearchOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Card, Table, Form, Input, InputNumber, message, Row, Select, Space, Spin, Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import HigashifukyoDataSelectAction from 'redux/ResultOutput/EastFukyoConverseProcess/HigashifukyoDataSelect.action'


class WS1133001_HigashifukyoDataSelect extends React.Component {
  static propsType = {
    Li_Date: PropTypes.any,
    Li_Id: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    Li_Option: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.office = React.createRef()

    // document.title = '東振協コード選択'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    let params = {
      Li_CmtClassify: this.props.Li_CmtClassify,
      search: this.formRef.current?.getFieldValue('search')
    }
    HigashifukyoDataSelectAction.index(params)
      .then((res) => {
        console.log(res);
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='higashi-fukyo-datas-elect' >
        <Card
          title='東振協コード選択'
        >
          <Form ref={this.formRef} autoComplete='off'>
            <Form.Item name='search' label='検索'>
              <Input className='input-size-60'
                onPressEnter={() => this.index()}
              />
            </Form.Item>
            <Table
              size='small'
              bordered
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ y: 450 }}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () => {
                  // 東振協コード選択
                  this.props.onFinishScreen(record)
                }
              })}
            >
              <Table.Column title='グループ' dataIndex='exam_comment_options' width={120} />
              <Table.Column title='設定' dataIndex='code' width={40} />
              <Table.Column title='表示内容' dataIndex='exam_comment_form' width={120} />
            </Table>
          </Form>

        </Card>


        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1133001_HigashifukyoDataSelect)
