import { message, Modal } from 'antd'
import TentativeReserveCaptureConfirmedService from 'services/ReservationBusiness/SANAI_TentativeReserve/TentativeReserveCaptureConfirmedService.js'

const TentativeReserveCaptureConfirmedAction = {
  index(data) {
    return TentativeReserveCaptureConfirmedService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        // message.error(res.data.message)
        Modal.error({
          title: res.data.message,
          width: 400
        })
      })
  },
  readExcelFile(data) {
    return TentativeReserveCaptureConfirmedService.readExcelFile(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })

  },
}
export default TentativeReserveCaptureConfirmedAction
