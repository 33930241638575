import { message } from 'antd'
import FindingsInputRadiographyService from 'services/InputBusiness/SpreadInput/FindingsInputRadiographyService'

const FindingsInputRadiographyAction = {
  index(data) {
    return FindingsInputRadiographyService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getSiteFindingsInputThisTime(data) {
    return FindingsInputRadiographyService.getSiteFindingsInputThisTime(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  getSiteFindingsPrevious(data) {
    return FindingsInputRadiographyService.getSiteFindingsPrevious(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  setSelectedSiteFinding(data) {
    return FindingsInputRadiographyService.setSelectedSiteFinding(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },

  save(data) {
    return FindingsInputRadiographyService.save(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  delete(data) {
    return FindingsInputRadiographyService.delete(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  setPreviousFindingsDo(data) {
    return FindingsInputRadiographyService.setPreviousFindingsDo(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
  selectSitesAndFindings(data) {
    return FindingsInputRadiographyService.selectSitesAndFindings(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },

}

export default FindingsInputRadiographyAction
