import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Dropdown, Button, Menu, message } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'

import ModalDraggable from 'components/Commons/ModalDraggable'
import WS3200005_detailSub from 'pages/KS_CooperationRelated/V4CP0004000_ElectronicMedicalRecordsLinkageFrameSet/WS3200005_detailSub.jsx'
import WS3200002_ElectronicMedicalRecordsLinkageFrameSetEdit from 'pages/KS_CooperationRelated/V4CP0004000_ElectronicMedicalRecordsLinkageFrameSet/WS3200002_ElectronicMedicalRecordsLinkageFrameSetEdit.jsx'
import ElectronicMedicalRecordsLinkageFrameSetAction from 'redux/CooperationRelated/ElectronicMedicalRecordsLinkageFrameSet/ElectronicMedicalRecordsLinkageFrameSet.actions.js'

class WS3200001_ElectronicMedicalRecordsLinkageFrameSet extends React.Component {

  constructor(props) {
    super(props)

    // document.title = '電子カルテ連携枠設定'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: []
    }
  }

  componentDidMount() {
    this.index()
  }

  index() {
    ElectronicMedicalRecordsLinkageFrameSetAction.index()
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  detailSub(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '650px',
        className: '',
        component:
          <WS3200005_detailSub
            record={record}
            onFinishScreen={() => {
              this.index()
            }}
          />
      }
    })
  }

  /**
   * 新規・編集モーダル
   * @param {} record
   */
  addOrEdit(record = null) {
    let newFlag = true

    if (record) {
      newFlag = false
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '400px',
        className: '',
        component:
          <WS3200002_ElectronicMedicalRecordsLinkageFrameSetEdit
            record={record}
            newFlag={newFlag}
            onNewData={(output) => {
              // 新規追加
              this.addNewData(output)
              this.closeModal()
            }}
            onUpdate={(output) => {
              // 変更
              this.editData(output, record)
              this.closeModal()
            }}
            onDelete={(output) => {
              // 削除
              this.delete(output)
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * 新規追加
   * @param {*} record
   */
  addNewData(record) {
    ElectronicMedicalRecordsLinkageFrameSetAction.addNewData(record)
      .then((res) => { this.index() })
  }

  /**
   * 変更
   * @param {*} record
   */
  editData(record, oldRecord) {
    const params = {
      ...record,
      id: oldRecord.id,
      old_exam_id: oldRecord.exam_id
    }

    ElectronicMedicalRecordsLinkageFrameSetAction.editData(params)
      .then((res) => { this.index() })
  }

  /**
   * 削除
   * @param {*} record
   */
  delete(record) {
    ElectronicMedicalRecordsLinkageFrameSetAction.delete(record)
      .then((res) => { this.index() })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='electronic-medical-records-linkage-frame-set'>
        <Card title='電子カルテ連携枠設定'>
          <Table
            bordered
            dataSource={this.state.dataSource}
            style={{ cursor: 'pointer' }}
            pagination={false}
            rowKey={(record) => record.id}
            size='small'
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  this.detailSub(record)
                }
              }
            }}
          >

            <Table.Column
              width={120}
              title='exam_id'
              dataIndex='exam_id'
            />

            <Table.Column
              width={120}
              title='exam_item'
              dataIndex='exam_item'
            />

            <Table.Column
              //width={120}
              title='exam_item_name'
              dataIndex='exam_name'
            />

            <Table.Column
              title='exam_code(詳細)'
              dataIndex='exam_codes'
              width={90}
              render={(text) =>
                <div style={{
                  textAlign: 'right',
                  whiteSpace: 'pre-wrap'
                }}>
                  {text}
                </div>
              }
            />
            <Table.Column
              title='exam_name(詳細)'
              dataIndex='exam_names'
              width={200}
              render={(text) =>
                <div style={{
                  whiteSpace: 'pre-wrap'
                }}>
                  {text}
                </div>
              }
            />

            <Table.Column
              width={120}
              title='app_code'
              dataIndex='app_code'
            />

            <Table.Column
              width={120}
              title='seq'
              dataIndex='seq'
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              width={120}
              title='calender_id'
              dataIndex='calender_id'
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              width={150}
              title='dock_calender_id'
              dataIndex='dock_calender_id'
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              width={100}
              title='sort_type'
              dataIndex='sort_type'
              render={(value) => (
                <div>
                  {(value === 0) ? '昇順' : '降順'}
                </div>
              )}
            />

            <Table.Column
              width={40}
              title={
                //新規登録のボタン
                <Button
                  size='small'
                  type='primary'
                  style={{ textAlign: 'center' }}
                  icon={<PlusOutlined />}
                  onClick={() => { this.addOrEdit() }}
                >
                </Button>
              }

              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='詳細'
                          onClick={() => { this.detailSub(record) }}
                        >詳細
                        </Menu.Item>

                        <Menu.Item
                          key='編集'
                          onClick={() => { this.addOrEdit(record) }}
                        >編集
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                    ></Button>
                  </Dropdown>
                )
              }}
            />
          </Table>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3200001_ElectronicMedicalRecordsLinkageFrameSet)
