import React from "react";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Radio, Checkbox, Button, Row, Col, Popconfirm, Select, ConfigProvider, Spin, InputNumber, message } from "antd";
import Text from "antd/lib/typography/Text";

import axios from 'configs/axios';
import WS0302001_SetInfoSearch from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0302001_SetInfoSearch";
import { number_format } from "helpers/CommonHelpers";
import { getScreenMoneyAmountInputAction, taxCalculateMoneyAmountInputAction } from "redux/ReservationBusiness/ReservesBulkChanges/MoneyAmountInput.actions";

const smGrid = {
  labelCol: { style: { width: 50 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const columnTitle = {
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRight: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}

const styleFormItem = {
  borderTop: '1px solid #808080',
  borderRight: '1px solid #808080',
  borderBottom: 0,
  margin: 0,
  textAlign: 'right',
  height: '33px',
}

const styleFormItemBottom = {
  ...styleFormItem,
  borderBottom: '1px solid #808080',
  height: '33px',
}

const styleDivTitle = {
  border: '1px solid #FFFFFF',
  borderLeft: 0,
  borderBottom: 0,
  padding: '5px 10px',
  backgroundColor: '#1c66b9',
  color: 'white',
  textAlign: 'center'
}
class WS2556015_MoneyAmountInput extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    Li_Date: PropTypes.any,
    Lio_NewAndDelete: PropTypes.any,
    Lio_ContractPriority: PropTypes.any,
    Lio_Tax: PropTypes.any,
    Lo_StsEnter: PropTypes.any,

    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '金額入力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initialValues: {
        Lio_NewAndDelete: 0,
        W2_change_type: 50,
        W2_set_inspect_cd: '',
        set_short_name: '',
        Lio_ContractPriority: true,
        TaxClassify: 2,
        Lio_Tax: 0.10,
      },
      isLoading: false,
      isLoadingTaxCalculate: false,
      screenData: {},
      valueInput: {
        W2_insurer_unit_price: 0,
        W2_office_unit_price: 0,
        W2_other_org_unit_price: 0,
        W2_person_1_unit_price: 0,
        W2_person_2_unit_price: 0,
        W2_person_3_unit_price: 0,

        W2_insurer_tax: 0,
        W2_office_tax: 0,
        W2_other_org_tax: 0,
        W2_person_1_tax: 0,
        W2_person_2_tax: 0,
        W2_person_3_tax: 0,

        W2_insurer_total: 0,
        W2_office_total: 0,
        W2_other_org_total: 0,
        W2_person_1_total: 0,
        W2_person_2_total: 0,
        W2_person_3_total: 0,
      }
    };
  }

  componentDidMount = () => {
    this.loadInitData({
      Li_ContractType: this.props.Li_ContractType,
      Li_ContractOrgCode: this.props.Li_ContractOrgCode,
      Li_ContractStartDate: this.props.Li_ContractStartDate,
      Li_ContractNum: this.props.Li_ContractNum,
      Li_Date: this.props.Li_Date,
      Lio_NewAndDelete: this.props.Lio_NewAndDelete,
      Lio_ContractPriority: this.props.Lio_ContractPriority,
      Lio_Tax: this.props.Lio_Tax,
      Lo_StsEnter: this.props.Lo_StsEnter,
    });
    this.formRef?.current.setFieldsValue({
      Lio_NewAndDelete: this.props.Lio_NewAndDelete,
      Lio_Tax: this.props.Lio_Tax,
      Lio_ContractPriority: this.props.Lio_ContractPriority,
    })
  }

  componentDidMount = (prevProps) => {
    if (this.props !== prevProps) {
      this.loadInitData({
        Li_ContractType: this.props.Li_ContractType,
        Li_ContractOrgCode: this.props.Li_ContractOrgCode,
        Li_ContractStartDate: this.props.Li_ContractStartDate,
        Li_ContractNum: this.props.Li_ContractNum,
        Li_Date: this.props.Li_Date,
        Lio_NewAndDelete: this.props.Lio_NewAndDelete,
        Lio_ContractPriority: this.props.Lio_ContractPriority,
        Lio_Tax: this.props.Lio_Tax,
        Lo_StsEnter: this.props.Lo_StsEnter,
      });
      this.formRef?.current.setFieldsValue({
        Lio_NewAndDelete: this.props.Lio_NewAndDelete,
        Lio_Tax: this.props.Lio_Tax,
        Lio_ContractPriority: this.props.Lio_ContractPriority,
      })
    }
  }

  loadInitData = (params) => {
    this.setState({ isLoading: true });
    getScreenMoneyAmountInputAction(params)
      .then(res => {
        if (res?.data) {
          this.setState({ screenData: res.data });
          this.loadData();
        }
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  loadData = () => {
    this.setState({ isLoading: true });

    axios.get('/api/reserves-bulk-changes/money-amount-input/', {
      params: {
        Li_Date: this.props.Li_Date,
        Lo_Tax: this.props.Lio_Tax,
      },
    })
      .then(res => {
        this.formRef.current.setFieldsValue(res.data.W2_personal_exam_change);
      })
      .catch(error => {

      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleChangeInitValue = (objChange) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        ...objChange
      }
    })
  }

  handleChangeInputNumber = (objChange) => {
    this.setState({
      valueInput: {
        ...this.state.valueInput,
        ...objChange
      }
    })
  }

  sum1 = () => {
    const {
      W2_insurer_unit_price,
      W2_office_unit_price,
      W2_other_org_unit_price,
      W2_person_1_unit_price,
      W2_person_2_unit_price,
      W2_person_3_unit_price,
    } = this.state.valueInput;

    let value =
      + W2_insurer_unit_price
      + W2_office_unit_price
      + W2_other_org_unit_price
      + W2_person_1_unit_price
      + W2_person_2_unit_price
      + W2_person_3_unit_price;

    return value === 0 ? null : (parseInt(value)).toLocaleString();
  }

  sum2 = () => {
    const {
      W2_insurer_tax,
      W2_office_tax,
      W2_other_org_tax,
      W2_person_1_tax,
      W2_person_2_tax,
      W2_person_3_tax,
    } = this.state.valueInput;

    let value = W2_insurer_tax + W2_office_tax + W2_other_org_tax + W2_person_1_tax + W2_person_2_tax + W2_person_3_tax;

    return value === 0 ? null : (parseInt(value)).toLocaleString();
  }

  sum3 = () => {
    const {
      W2_insurer_total,
      W2_office_total,
      W2_other_org_total,
      W2_person_1_total,
      W2_person_2_total,
      W2_person_3_total
    } = this.state.valueInput;

    let value = W2_insurer_total + W2_office_total + W2_other_org_total + W2_person_1_total + W2_person_2_total + W2_person_3_total;

    return value === 0 ? null : (parseInt(value)).toLocaleString();
  }

  renderInputNumber = (key, value, condition) => (
    <Col span={3}>
      <Form.Item>
        <InputNumber
          min={0}
          maxLength={6}
          bordered={condition}
          formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(',', '')}
          style={{ backgroundColor: !condition ? '#F4F4F5' : '' }}
          readOnly={!condition}
          value={value === 0 ? null : value}
          onChange={(value) => this.handleChangeInputNumber({ [key]: value })}
        />
      </Form.Item>
    </Col>
  )

  TaxCalculate = () => {
    this.setState({ isLoadingTaxCalculate: true });
    let params = {
      ...this.state.valueInput,
      TaxClassify: this.state.initialValues.TaxClassify,
      W2_set_inspect_cd: this.state.initialValues.W2_set_inspect_cd,
      ClassifyTaxAfterDecimalPoint: this.state.screenData.ClassifyTaxAfterDecimalPoint,
      Lio_Tax: this.state.initialValues.Lio_Tax,
    }
    taxCalculateMoneyAmountInputAction(params)
      .then(res => {
        if (res?.data)
          this.setState({ valueInput: res.data })
      })
      .catch()
      .finally(() => this.setState({ isLoadingTaxCalculate: false }))
  }

  getFormFieldValue(namePath) {
    return this.formRef.current?.getFieldValue(namePath);
  }

  setFormFieldsValue = (data) => {
    this.formRef.current?.setFieldsValue(data);
  };

  getStsTaxChange(e, name, taxName, totalName) {
    let taxType = this.getFormFieldValue("TaxClassify")
    if (taxType === 0) {
      // 指定
      this.taxDesignated(e, name, taxName, totalName)
    } else if (taxType === 1) {
      // 外税
      this.taxExclusive(e, name, taxName, totalName)
    } else if (taxType === 2) {
      // 内税
      this.taxInclusive(e, name, taxName, totalName)
    } else if (taxType === 3) {
      // 非課税
      this.taxExemption(e, name, totalName)
    }
  }

  /**
   * 指定
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxDesignated(e, name, taxName, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = 0
    let unitPrice = this.getFormFieldValue(name)
    let tax = this.getFormFieldValue(taxName)
    let allTotal, result, taxResult
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(unitPrice)
        // 消費税
        taxResult = Math.round(tax)
        allTotal = parseInt(result) + parseInt(taxResult)
        break;
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(unitPrice)
        // 消費税
        taxResult = Math.floor(tax)
        allTotal = result + taxResult
        break;
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(unitPrice)
        // 消費税
        taxResult = Math.ceil(tax)
        allTotal = result + taxResult
        break;
    }
    this.formRef.current?.setFieldsValue({ [name]: result, [taxName]: taxResult, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 外税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxExclusive(e, name, taxName, totalName) {
    // 消費税
    let taxCalculation = this.getFormFieldValue('Lio_Tax')
    // 四捨五入か切り捨てか切り上げ
    let list = 0
    let value = e.target.value
    let result, taxResult, allTotal
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(value)
        // 消費税
        taxResult = Math.round(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break;
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(value)
        // 消費税
        taxResult = Math.floor(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break;
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(value)
        // 消費税
        taxResult = Math.ceil(e.target.value * taxCalculation)
        allTotal = result + taxResult
        break;
    }
    this.formRef.current?.setFieldsValue({ [name]: result, [taxName]: taxResult, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 内税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} taxName 消費税の名前
   * @param {*} totalName 合計値の名前
   */
  taxInclusive(e, name, taxName, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = 0
    // 消費税
    let taxCalculation = this.getFormFieldValue('Lio_Tax')
    let tax = this.getFormFieldValue('Lio_Tax') + 1
    let unitPrice, taxValue
    switch (list) {
      case 0:
        // 四捨五入
        // 消費税
        taxValue = Math.round(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break;
      case 1:
        // 切り捨て
        // 消費税
        taxValue = Math.floor(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break;
      case 2:
        // 切り上げ
        // 消費税
        taxValue = Math.ceil(e.target.value * taxCalculation)
        // 単価
        unitPrice = e.target.value - taxValue
        break;
    }
    this.formRef.current?.setFieldsValue({ [name]: unitPrice, [taxName]: taxValue, [totalName]: e.target.value })
    this.totalReset()
  }

  /**
   * 非課税
   * @param {*} e 入力値
   * @param {*} name 単価の名前
   * @param {*} totalName 合計の名前
   */
  taxExemption(e, name, totalName) {
    // 四捨五入か切り捨てか切り上げ
    let list = 0
    let unitPrice = this.getFormFieldValue(name)
    let allTotal, result
    switch (list) {
      case 0:
        // 四捨五入
        // 単価
        result = Math.round(unitPrice)
        // 合計
        allTotal = result
        break;
      case 1:
        // 切り捨て
        // 単価
        result = Math.floor(unitPrice)
        // 合計
        allTotal = result
        break;
      case 2:
        // 切り上げ
        // 単価
        result = Math.ceil(unitPrice)
        // 合計
        allTotal = result
        break;
    }
    this.formRef.current?.setFieldsValue({ [name]: result, [totalName]: allTotal })
    this.totalReset()
  }

  /**
   * 合計値再設定
   */
  totalReset() {
    // 合計の値を設定しなおす
    // 単価合計
    let unitTotal = number_format(
      (this.getFormFieldValue('W2_insurer_unit_price') || 0) +
      (this.getFormFieldValue('W2_office_unit_price') || 0) +
      (this.getFormFieldValue('W2_other_org_unit_price') || 0) +
      (this.getFormFieldValue('W2_person_1_unit_price') || 0) +
      (this.getFormFieldValue('W2_person_2_unit_price') || 0) +
      (this.getFormFieldValue('W2_person_3_unit_price') || 0)
    )
    // 消費税合計
    let taxTotal = number_format(
      (this.getFormFieldValue('W2_insurer_tax') || 0) +
      (this.getFormFieldValue('W2_office_tax') || 0) +
      (this.getFormFieldValue('W2_other_org_tax') || 0) +
      (this.getFormFieldValue('W2_person_1_tax') || 0) +
      (this.getFormFieldValue('W2_person_2_tax') || 0) +
      (this.getFormFieldValue('W2_person_3_tax') || 0)
    )
    // 全ての合計
    let totalNumber = number_format(
      (this.getFormFieldValue('W2_insurer_unit_price') || 0) +
      (this.getFormFieldValue('W2_office_unit_price') || 0) +
      (this.getFormFieldValue('W2_other_org_unit_price') || 0) +
      (this.getFormFieldValue('W2_person_1_unit_price') || 0) +
      (this.getFormFieldValue('W2_person_2_unit_price') || 0) +
      (this.getFormFieldValue('W2_person_3_unit_price') || 0) +
      (this.getFormFieldValue('W2_insurer_tax') || 0) +
      (this.getFormFieldValue('W2_office_tax') || 0) +
      (this.getFormFieldValue('W2_other_org_tax') || 0) +
      (this.getFormFieldValue('W2_person_1_tax') || 0) +
      (this.getFormFieldValue('W2_person_2_tax') || 0) +
      (this.getFormFieldValue('W2_person_3_tax') || 0)
    )
    this.setFormFieldsValue({ unitPriceTotal: unitTotal, taxTotal: taxTotal, allTotal: totalNumber });
    this.forceUpdate()
  }

  render() {
    const { TaxClassify, set_short_name } = this.state.initialValues;
    const { OtherGroupDivision, CourseCode } = this.state.screenData;
    const {
      W2_insurer_unit_price,
      W2_office_unit_price,
      W2_other_org_unit_price,
      W2_person_1_unit_price,
      W2_person_2_unit_price,
      W2_person_3_unit_price,
      W2_insurer_tax,
      W2_office_tax,
      W2_other_org_tax,
      W2_person_1_tax,
      W2_person_2_tax,
      W2_person_3_tax,
      W2_insurer_total,
      W2_office_total,
      W2_other_org_total,
      W2_person_1_total,
      W2_person_2_total,
      W2_person_3_total,
    } = this.state.valueInput;
    return (
      <div className="money-amount-input">
        <Card title="金額入力">
          <Form ref={this.formRef} initialValues={this.state.initialValues}>
            <div className="box_container">
              <div className="box_search">
                <div className="box_search_inner_border">
                  <Form.Item label='　' name='Lio_NewAndDelete' {...smGrid}>
                    <Radio.Group onChange={(e) => this.handleChangeInitValue({ Lio_NewAndDelete: e.target.value })}>
                      <Radio value={0}>新規</Radio>
                      <Radio value={1}>削除</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label='種別' name='W2_change_type'{...smGrid}>
                    <Select
                      style={{ width: '120px' }}
                      onChange={(value) => this.setState({ initValue: { ...this.state.initValue, ChangeType: value } })}
                    >
                      <Select.Option value={40}>オプション</Select.Option>
                      <Select.Option value={50}>追加選択</Select.Option>
                      <Select.Option value={60}>不要選択</Select.Option>
                    </Select>
                    {/* <Radio.Group onChange={(e) => this.handleChangeInitValue({ W2_change_type: e.target.value })}>
                          <Radio value={40}>ｵﾌﾟｼｮﾝ</Radio>
                          <Radio value={50}>追加</Radio>
                          <Radio value={60}>不要</Radio>
                        </Radio.Group> */}
                  </Form.Item>
                  <div className="box_inner_horizontal">
                    <Form.Item name="W2_set_inspect_cd" label="セット" {...smGrid}>
                      <Input.Search
                        className='input-search-size-single-byte-10'
                        allowClear={true}
                        onSearch={(value, event) => {
                          if (event.target.localName === 'input') {
                            this.formRef.current.setFieldsValue({
                              BranchStoreCode: '',
                              office_kanji_name: ''
                            })
                            this.forceUpdate()
                          } else {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1000,
                                component: (
                                  <WS0302001_SetInfoSearch
                                    Li_SetIdentify={''}
                                    Li_StartDate={this.props.Li_Date}
                                    Li_CourseCode={CourseCode}
                                    _Lo_Return={''}
                                    Li_ContextId={''}
                                    Li_RangeSetting={''}
                                    Li_CourseLevel={''}
                                    Li_ReserveNum={''}
                                    Li_ContractType={this.props.Li_ContractType}
                                    Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                                    Li_ContractStartDate={this.props.Li_ContractStartDate}
                                    Li_ContractNum={this.props.Li_ContractNum}
                                    Lo_SetCode={this.formRef?.current?.getFieldValue('W2_set_inspect_cd')}
                                    onFinishScreen={({ Lo_SetCode, recordData }) => {
                                      this.formRef.current.setFieldsValue({ W2_set_inspect_cd: Lo_SetCode });
                                      this.handleChangeInitValue({
                                        W2_set_inspect_cd: Lo_SetCode,
                                        set_short_name: recordData.set_short_name
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        }
                                      });
                                    }}
                                  />
                                ),
                              },
                            });
                          }
                        }} />
                    </Form.Item>
                    <Form.Item >{set_short_name}</Form.Item>
                  </div>
                </div>
                <div className="box_search_inner">
                  <Form.Item label='税区分' name="TaxClassify" {...smGrid}>
                    <Radio.Group onChange={(e) => this.handleChangeInitValue({ TaxClassify: e.target.value })}>
                      <Radio value={0}>指定</Radio>
                      <Radio value={1}>外税</Radio>
                      <Radio value={2}>内税</Radio>
                      <Radio value={3}>非課</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="box_inner_horizontal">
                    <Form.Item name="Lio_Tax" label="税率" {...smGrid}>
                      <InputNumber min={0} max={9.99} maxLength={4} step='9.99' stringMode
                        onChange={(value) => this.handleChangeInitValue({ Lio_Tax: value })}
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      loading={this.state.isLoadingTaxCalculate}
                      style={{ float: "right" }}
                      onClick={this.TaxCalculate}
                    >
                      税計算
                    </Button>
                  </div>
                  <Form.Item name='Lio_ContractPriority' label="契約の金額を優先する" valuePropName="checked">
                    <Checkbox onChange={(e) => this.handleChangeInitValue({ Lio_ContractPriority: e.target.checked })} />
                  </Form.Item>
                </div>
              </div>
              <div>
                <Row>
                  <Col span={3}>
                    <div style={columnTitle}>コース</div>
                    <div style={columnTitle}>単　価</div>
                    <div style={columnTitle}>消費税</div>
                    <div style={columnTitle}>合　計</div>
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  > */}
                    {/* <Tag
                      color={Color(156).Background}
                      style={{ width: "100%" }}
                    >
                      保険者
                    </Tag> */}
                    <div style={{ ...styleDivTitle, borderLeft: '1px solid #FFFFFF' }}>保険者</div>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_insurer_unit_price">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={(this.getFormFieldValue("TaxClassify") === 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_insurer_unit_price', 'W2_insurer_tax', 'W2_insurer_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_insurer_tax">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={(this.getFormFieldValue("TaxClassify") !== 0)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_insurer_unit_price', 'W2_insurer_tax', 'W2_insurer_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="W2_insurer_total">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_insurer_unit_price', 'W2_insurer_tax', 'W2_insurer_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    {/* </Text> */}
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  > */}
                    {/* <Tag
                      color={Color(156).Background}
                      style={{ width: "100%" }}
                    >
                      事業所
                    </Tag> */}
                    <div style={styleDivTitle}>事業所</div>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_office_unit_price">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_office_unit_price', 'W2_office_tax', 'W2_office_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_office_tax">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_office_unit_price', 'W2_office_tax', 'W2_office_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="W2_office_total">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_office_unit_price', 'W2_office_tax', 'W2_office_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    {/* </Text> */}
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  >
                    <Tag
                      color={
                        MagicXpaFunc.MID(
                          this.getFormFieldValue("Protection"),
                          3,
                          1
                        ) == "0" && this.props.Li_OtherGroupDivision > 0
                          ? Color(156).Background
                          : "grey"
                      }
                      style={{ width: "100%" }}
                    >
                      他団体
                    </Tag>
                  </Text> */}
                    <div style={styleDivTitle}>他団体</div>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_other_org_unit_price">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_other_org_unit_price', 'W2_other_org_tax', 'W2_other_org_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_other_org_tax">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_other_org_unit_price', 'W2_other_org_tax', 'W2_other_org_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="W2_other_org_total">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_other_org_unit_price', 'W2_other_org_tax', 'W2_other_org_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: "100%" }}
                    >
                      個人１
                    </Tag>
                  </Text> */}
                    <div style={styleDivTitle}>個人１</div>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_person_1_unit_price">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_1_unit_price', 'W2_person_1_tax', 'W2_person_1_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_person_1_tax">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_1_unit_price', 'W2_person_1_tax', 'W2_person_1_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="W2_person_1_total">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_1_unit_price', 'W2_person_1_tax', 'W2_person_1_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: "100%" }}
                    >
                      個人２
                    </Tag>
                  </Text> */}
                    <div style={styleDivTitle}>個人２</div>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_person_2_unit_price">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_2_unit_price', 'W2_person_2_tax', 'W2_person_2_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_person_2_tax">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_2_unit_price', 'W2_person_2_tax', 'W2_person_2_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="W2_person_2_total">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_2_unit_price', 'W2_person_2_tax', 'W2_person_2_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: "100%" }}
                    >
                      個人３
                    </Tag>
                  </Text> */}
                    <div style={styleDivTitle}>個人３</div>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_person_3_unit_price">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") != 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_3_unit_price', 'W2_person_3_tax', 'W2_person_3_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="W2_person_3_tax">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 0)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_3_unit_price', 'W2_person_3_tax', 'W2_person_3_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider direction="ltr">
                      <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="W2_person_3_total">
                        <InputNumber
                          size="small"
                          style={{ width: '100%', margin: 0, textAlign: 'right' }}
                          disabled={!(this.getFormFieldValue("TaxClassify") == 2)}
                          onBlur={(e) => this.getStsTaxChange(e, 'W2_person_3_unit_price', 'W2_person_3_tax', 'W2_person_3_total')}
                        />
                      </Form.Item>
                    </ConfigProvider>
                  </Col>
                  <Col span={3}>
                    {/* <Text
                    style={{
                      display: "block",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  >
                    <Tag
                      color={Color(156).Background}
                      style={{ width: "100%" }}
                    >
                      合計
                    </Tag>
                  </Text> */}
                    <div style={{ ...styleDivTitle, borderRight: 0 }}>合計</div>
                    <Form.Item style={{ ...styleFormItem, padding: '0 3px' }}>
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.formRef.current?.getFieldValue('unitPriceTotal')}</div>
                    </Form.Item>
                    <Form.Item style={{ ...styleFormItem, padding: '0 3px' }} name="taxTotal">
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFormFieldValue('taxTotal')}</div>
                    </Form.Item>
                    <Form.Item style={{ ...styleFormItemBottom, padding: '0 3px' }} name="allTotal">
                      <div style={{ marginRight: '10px' }} className="div_data_horizontal">{this.getFormFieldValue('allTotal')}</div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row gutter={16}>
                <Col span={4} offset={20}>
                  <Popconfirm
                    title={() => {
                      // CASE(W2_change_type,40,'ｵﾌﾟｼｮﾝ',50,'追加',60,'削除','')&'ｾｯﾄ['&Trim(W2_set_inspect_cd)&']を「'&IF(Lio_NewAndDelete=0,'追加','削除')&'」しますか?'
                      let change_type = '';
                      switch (this.formRef.current?.getFieldValue('W2_change_type')) {
                        case 40:
                          change_type = 'ｵﾌﾟｼｮﾝ';
                          break;
                        case 50:
                          change_type = '追加';
                          break;
                        case 60:
                          change_type = '削除';
                          break;
                        default:
                      }
                      return (
                        change_type
                        + 'ｾｯﾄ['
                        + (this.formRef.current?.getFieldValue('W2_set_inspect_cd') || '').trim()
                        + ']を「'
                        + (this.formRef.current?.getFieldValue('Lio_NewAndDelete') === 0 ? '追加' : '削除')
                        + '」しますか?'
                      );
                    }}
                    onConfirm={() => {
                      this.props.onFinishScreen({
                        Lo_StsEnter: true,
                        Lio_Tax: this.formRef.current.getFieldValue('Lio_Tax'),
                        Lio_ContractPriority: this.formRef.current.getFieldValue('Lio_ContractPriority'),
                        Lio_NewAndDelete: this.formRef.current.getFieldValue('Lio_NewAndDelete'),
                        values: this.formRef.current?.getFieldsValue()
                      });
                    }}
                    okText="はい"
                    cancelText="いいえ"
                  >
                    <Button type="primary" style={{ float: "right", width: "100%" }}>確定</Button>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS2556015_MoneyAmountInput;
