import EastFukyoConverseProcessService from 'services/ResultOutput/EastFukyoConverseProcess/HigashifukyoDataInputService'
import { message } from 'antd'

const HigashifukyoDataInputAction = {
  index(data) {
    return EastFukyoConverseProcessService.index(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },

  getInspectResults(data) {
    return EastFukyoConverseProcessService.getInspectResults(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
      })
  },
}

export default HigashifukyoDataInputAction
