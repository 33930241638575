import axios from 'configs/axios'

const apiPaths = {
  getHearingList: '/api/corporate-hearing-sheet/corporate-hearing-sheet/get-hearing-list',
  onRegistration: '/api/corporate-hearing-sheet/corporate-hearing-sheet/register',
  print: '/api/corporate-hearing-sheet/corporate-hearing-sheet/print',
  shippingLabel: '/api/corporate-hearing-sheet/corporate-hearing-sheet/shipping-label',
  downloadExcelFile: '/api/corporate-hearing-sheet/corporate-hearing-sheet/download-excel-file',
}

const CorporateHearingSheetService = {
  async getHearingList(params) {
    return axios.get(apiPaths.getHearingList, { params })
  },
  async onRegistration(params) {
    return axios.post(apiPaths.onRegistration, params)
  },
  async print(params) {
    return axios.post(apiPaths.print, params)
  },
  async shippingLabel(params) {
    return axios.post(apiPaths.shippingLabel, params)
  },
  async downloadExcelFile(params) {
    return axios.post(apiPaths.downloadExcelFile, params, { responseType: 'arraybuffer' })
  },
}

export default CorporateHearingSheetService
