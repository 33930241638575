import React from "react";
import { connect } from "react-redux";

import { Card, Table, Input, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import moment from "moment";
import WS1313006_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313006_XmlMedicalExamItemRef.jsx";
import  ModalDraggable  from "components/Commons/ModalDraggable";

class WS1313005_DataRangeCheck extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'XML03001:データ範囲チェック';

    this.state = {
      dataSource: [],
      isLoadingTable: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    this.setState({ isLoadingTable: true });
    VenusApiRoutesV2.callApi("API001313005002", {
      date_of_adoption_history_on: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
      item_code_jlac10_15: this.props.item_code_jlac10_15,
      result_identification_jlac10_2: this.props.result_identification_jlac10_2,
    }).then(res => {
      if (res && res.length > 0) {
        this.setState({ dataSource: res, isLoadingTable: false });
      } else {
        this.setState({
          dataSource: [
            {
              id: 0,
              DateAdoptionChars: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
              date_of_adoption_history_on: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
              item_code_jlac10_15: this.props.item_code_jlac10_15,
              result_identification_jlac10_2: this.props.result_identification_jlac10_2,
              input_min_value: 0,
              input_max_value: 0,
              character_input_min_value: "",
              character_input_max_value: "",
              option_remark: "",
            }
          ], isLoadingTable: false
        });
      }
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="data-range-check">
        <Card title="XML03001:データ範囲チェック">
          <Table bordered 
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={true}
            rowKey={(record) => record.id}
          >
            <Table.Column title="採用日" dataIndex="DateAdoptionChars" showSorterTooltip={false} sorter={(a, b) => a.DateAdoptionChars?.localeCompare(b.DateAdoptionChars, 'ja')}/>
            <Table.Column title="項目コード"
              dataIndex="item_code_jlac10_15"
              showSorterTooltip={false}
              sorter={(a, b) => a.item_code_jlac10_15?.localeCompare(b.item_code_jlac10_15, 'ja')}
              render={(value, record, index) => {
                return (
                  <Input.Search value={value} onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '80%',
                        component: (
                          <WS1313006_XmlMedicalExamItemRef
                            onFinishScreen={(output) => {
                              this.closeModal();
                              let data = [...this.state.dataSource];
                              data[index].item_code_jlac10_15 = output;
                              this.setState({dataSource: data});
                            }}
                          />
                        ),
                      },
                    });
                  }} />
                );
              }}
            />
            <Table.Column title="結果識別" dataIndex="result_identification_jlac10_2" showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2?.localeCompare(b.result_identification_jlac10_2, 'ja')}/>
            <Table.Column title="入力最小値" dataIndex="input_min_value" showSorterTooltip={false} sorter={(a, b) => a.input_min_value - b.input_min_value}/>
            <Table.Column title="入力最大値" dataIndex="input_max_value" showSorterTooltip={false} sorter={(a, b) => a.input_max_value - b.input_max_value}/>
            <Table.Column title="文字入力最小値" dataIndex="character_input_min_value" showSorterTooltip={false} sorter={(a, b) => a.character_input_min_value?.localeCompare(b.character_input_min_value, 'ja')}/>
            <Table.Column title="文字入力最大値" dataIndex="character_input_max_value" showSorterTooltip={false} sorter={(a, b) => a.character_input_max_value?.localeCompare(b.character_input_max_value, 'ja')}/>
            <Table.Column title="オプション" dataIndex="option_remark" showSorterTooltip={false} sorter={(a, b) => a.option_remark?.localeCompare(b.option_remark, 'ja')}/>
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313005_DataRangeCheck);
