import axios from 'configs/axios'
const API_LIST = {
  index: '/api/normal-value-setting-maintain/inquiry-finding-info',
  getFinding: '/api/normal-value-setting-maintain/inquiry-finding-info/get-finding',
}

const InquiryFindingInfoService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getFinding(params) {
    return axios.get(API_LIST.getFinding, { params })
  },
}

export default InquiryFindingInfoService
