import React from 'react'
import { connect } from 'react-redux'
import WS0270001_InspectItemSearchQueryMultipleChoice from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0270001_InspectItemSearchQueryMultipleChoice.jsx'
import { Dropdown, message, Card, Table, Form, Row, Col, Space, Input, Button, Modal, InputNumber, Menu } from 'antd'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'
import WS0887001_IntroduceLetterVariousMasterInquiry from '../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import WS0924001_IntroduceLetterTargetCmtList from './WS0924001_IntroduceLetterTargetCmtList'
import WS0920010_CopyingProcess from './WS0920010_CopyingProcess'
import ColumnButtonCustom from 'components/Commons/TableColumn'
import FollowUpLetterTargetCmtMaintainAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/FollowUpLetterTargetCmtMaintain.action'
import WS0921003_IntroduceLetterTargetCmtInspectMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0921003_IntroduceLetterTargetCmtInspectMaintain.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import FollowUpLetterTargetCmtEdit from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/FollowUpLetterTargetCmtEdit.jsx'
import FollowUpLetterInspectMaintain from 'pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/FollowUpLetterInspectMaintain.jsx'

class FollowUpLetterTargetCmtMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '経過手紙対象コメント保守'

    this.state = {
      rowSelected: [],
      rowSelected2: [],
      selectedRowKeys: [],
      selectedRowKeys2: [],
      indexTable: 0,
      indexTable2: 0,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      search_key: '',
      remarks: '',
      regional_insurer_group_classifi: null,
      count: 0,
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.getMaintain()
    this.forceUpdate()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selectedRowKeys[0] !== this.state.selectedRowKeys[0]) {
      this.formRef.current.setFieldsValue({
        search_key: this.state.search_key,
        tableData2: []
      })
      this.forceUpdate()
    }
  }

  /**
   * コメント一覧を取得
   */
  getMaintain() {
    FollowUpLetterTargetCmtMaintainAction.getMaintain()
      .then((res) => {
        const comment_code = (data) => data.comment_code === 0 ? '' : data.comment_code
        if (res) {
          const data = res.map(item => ({ ...item, comment_code: comment_code(item) }))
          this.formRef?.current?.setFieldsValue({
            dataSource: data,
          })
          this.setState({
            rowSelected: res[0] ?? [],
            selectedRowKeys: res.length > 0 ? res[0].id : [],
            search_key: res.length ? res[0].comment_content : '',
            indexTable: 0,
            dataSource: data,
          })
          // this.changeRow(res[0])
        }
      })
  }

  getInspectMaintain(data) {
    FollowUpLetterTargetCmtMaintainAction.getInspectMaintain({ comment_code: data ? data.comment_code : 0 })
      .then((res) => {
        const exam_code = (data) => data.exam_code === 0 ? '' : data.exam_code
        if (res) {
          const data = res.map(item => ({ ...item, comment_code: exam_code(item) }))
          this.formRef?.current?.setFieldsValue({
            dataSource2: data,
          })
          this.setState({
            rowSelected2: [res[0]],
            selectedRowKeys2: [res[0]?.id],
            indexTable2: 0
          })
        }
      })
  }

  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name)
  }

  InspectItemSearchQuerySingle = (condition = null) => {
    console.log('Modal InspectItemSearchQuerySingle: 271')
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component:
          <WS0271001_InspectItemSearchQuerySingle
            onFinishScreen={(output) => {
              this.setDataOutput(condition, output)
              this.closeModal()
            }}
          />
      }
    })
  }

  WS0270001_InspectItemSearchQueryMultipleChoice() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component:
          <WS0270001_InspectItemSearchQueryMultipleChoice
            Li_UnusedInspectDisplay={0}
            onFinishScreen={(output) => {
              if (output) {
                this.InspectItemSearchQuerySingle()
              }
              // this.closeModal()
            }}
          />
      }
    })
  }

  IntroduceLetterVariousMasterIn = (condition = null) => {
    console.log('Modal IntroduceLetterVariousMasterIn : 887')
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={7}
            Lo_VariousCodes={this.getRawValue('dataSource2')[this.state.indexTable2].department_code}
            onFinishScreen={(output) => {
              const data = this.getRawValue('dataSource2')
              if (output) {
                data[this.state.indexTable2].department_code = output?.Lo_VariousCodes
                data[this.state.indexTable2].department_name = output?.recordData?.department_name
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  setDataOutput = (condition, output) => {
    const data = this.getRawValue('dataSource2')
    if (data && data.length > 0) {
      if (output) {
        data[this.state.indexTable2].exam_code = output.Lio_InspectItemCode
        data[this.state.indexTable2].Expresstion_9 = output.Lo_exam_name
      }
    }
  }

  changeRow = (record) => {
    // API delays
    this.renderForm(record)
    this.getInspectMaintain(record)
  }

  /**
   * 複写
   * @param {*} condition 
   */
  f8 = (condition = null) => {
    const { comment_code, comment_content, } = this.state.rowSelected[0]
    const search_key = this.formRef.current.getFieldValue('search_key'),
      remarks = this.formRef.current.getFieldValue('remarks'),
      regional_insurer_group_classifi = this.formRef.current.getFieldValue('regional_insurer_group_classifi')
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '624px',
        component: (
          <WS0920010_CopyingProcess
            comment_code={comment_code}
            comment_content={comment_content}
            search_key={search_key}
            remarks={remarks}
            regional_insurer_group_classifi={regional_insurer_group_classifi}
            onFinishScreen={(output) => {
              this.setDataOutput(condition, output)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  f9 = (condition = null) => {
    console.log('Modal IntroduceLetterTargetCmtList : 924')
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0924001_IntroduceLetterTargetCmtList
            onFinishScreen={(output) => {
              this.setDataOutput(condition, output)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addRow(name, tableIndex) {
    let data = this.getRawValue(name) ?
      this.getRawValue(name) : []
    let arr = [...data]
    if (arr.length === 0) {
      this.handleAdd(name, tableIndex)
    } else {
      for (let index = 0; index < arr.length; index++) {
        if (arr[index]['isNew']) {
          return
        }
        if (index === arr.length - 1) {
          this.handleAdd(name, tableIndex)
        }
      }
    }
  }

  handleAdd(name, tableIndex) {
    const { count } = this.state
    let data = this.getRawValue(name) ?
      this.getRawValue(name) : []
    const params = {
      id: `A${count}`,
      isNew: true,
      comment_code: '',
      comment_content: '',
      regional_insurer_group_classification: '',
      remarks: '',
      search_key: ''
    }
    let arr = [...data]
    arr.unshift(params)
    console.log(tableIndex)
    if (tableIndex === 1) {
      setTimeout(() => {
        this.setState({
          count: count + 1,
          selectedRowKeys: arr.length > 0 ? [arr[0].id] : [],
          rowSelected: arr.length > 0 ? [arr[0]] : [],
          indexTable: 0
        })

      }, 50)
    } else {
      setTimeout(() => {
        this.setState({
          count: count + 1,
          selectedRowKeys2: arr.length > 0 ? [arr[0].id] : [],
          rowSelected2: arr.length > 0 ? [arr[0]] : [],
          indexTable2: 0
        })
      }, 50)
    }
    if (tableIndex === 1) {
      this.changeRow(arr[0])
    }
    this.setFormFieldValue(name, arr)
    this.forceUpdate()
  }

  removeRow(name, tableIndex, index) {
    const i = tableIndex === 1 ? '' : tableIndex
    let data = this.getRawValue(name) ?
      this.getRawValue(name) : []
    const table = data.filter((item, i) => index !== i)
    if (tableIndex === 1) {
      setTimeout(() => {
        this.setState({
          selectedRowKeys: table.length > 0 ? [table[0].id] : [],
          rowSelected: table.length > 0 ? [table[0]] : [],
          indexTable: 0
        })

      }, 50)
    } else {
      setTimeout(() => {
        this.setState({
          selectedRowKeys2: table.length > 0 ? [table[0].id] : [],
          rowSelected2: table.length > 0 ? [table[0]] : [],
          indexTable2: 0
        })
      }, 50)
    }
    if (tableIndex === 1) {
      this.changeRow(table[0])
    }
    this.setFormFieldValue(name, table)
    this.forceUpdate()
  }

  insertMaintainData(record) {
    // save data form FE
    const isNew = true
    this.SaveMaintainData(record, isNew)
  }

  SaveMaintainData(value, isNew) {
    const req = {
      search_key: this.getRawValue('search_key') || value.search_key,
      remarks: this.getRawValue('remarks') || value.remarks,
      regional_insurer_group_classification:
        this.getRawValue('regional_insurer_group_classification') ||
        value.regional_insurer_group_classification,
      category_name: value.category_name,
      comment_code: value.comment_code,
      comment_content: value.comment_content,
    }
    const params = isNew ? { ...req } : { ...req, id: value.id }
    FollowUpLetterTargetCmtMaintainAction.saveMaintain(params).then(() => {
      message.success('保存しました')
      this.getMaintain()
    }).catch(err => {
      const res = err.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
        return
      }
      message.error(res.data.message)
    })
  }

  onSave(index = this.state.indexTable) {
    // click event save data
    const record = this.getRawValue('dataSource')[index]
    record?.isNew ?
      this.insertMaintainData(record) : this.SaveMaintainData(record, false)
  }

  deleteMaintain() {
    const params = {
      id: this.state.selectedRowKeys[0],
      comment_code: this.state.rowSelected[0]?.comment_code
    }
    Modal.confirm({
      content: '消去してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => FollowUpLetterTargetCmtMaintainAction.deleteMaintain(params).then(() => {
        this.getMaintain()
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
    })
  }

  insertInspectData(record) {
    // save data form FE
    const isNew = true
    this.SaveInspectData(record, isNew)
  }

  SaveInspectData(value, isNew) {
    const req = {
      remarks: value.remarks,
      exam_code: value.exam_code,
      department_code: value.department_code,
      comment_code: this.state.rowSelected[0].comment_code
    }
    const params = isNew ? { ...req } : { ...req, id: value.id }
    FollowUpLetterTargetCmtMaintainAction.saveDataInspect(params).then(() => {
      message.success('保存しました')
      this.getInspectMaintain({ comment_code: this.state.rowSelected[0].comment_code })
    }).catch(err => {
      const res = err.response
      if (!res || !res.data || !res.data.message) {
        message.error('エラーが発生しました')
        return
      }
      message.error(res.data.message)
    })
  }

  onSaveInspect(index = this.state.indexTable2) {
    // click event save data
    const record = this.getRawValue('dataSource2')[index]
    record?.isNew ?
      this.insertInspectData(record) : this.SaveInspectData(record, false)
  }

  deleteInspect() {
    const params = {
      id: this.state.selectedRowKeys2[0],
      exam_code: this.state.rowSelected2[0]?.exam_code,
      department_code: this.state.rowSelected2[0]?.department_code,

    }
    Modal.confirm({
      content: '消去してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => FollowUpLetterTargetCmtMaintainAction.deleteDataInspect(params).then(() => {
        this.getInspectMaintain({ comment_code: this.state.rowSelected[0].comment_code })
      }).catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
    })
  }

  renderForm(record) {
    if (record) {
      const keys = Object.keys(record)
      const values = Object.values(record)
      keys.forEach((item, i) => {
        if (item == 'regional_insurer_group_classification') {
          this.setFormFieldValue(item, values[i] === 0 ? '' : values[i])
        } else {
          this.setFormFieldValue(item, values[i])
        }
      })
    }
  }

  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }

  f11 = () => {
    console.log('Modal LetterIntroduceSubjectInspec : 921')
    const data = this.state.rowSelected[0]
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0921003_IntroduceLetterTargetCmtInspectMaintain
            comment_code={data.comment_code}
            comment_content={data.comment_content}
            FW_comment_content={data.FW_comment_content}
            remarks={data.remarks}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  openFollowUpLetterTargetCmtEdit = (newFlag, record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <FollowUpLetterTargetCmtEdit
            newFlag={newFlag}
            record={record}
            dataSource={this.state.dataSource}
            onFinishScreen={(output) => {
              this.getMaintain()
              this.closeModal()
            }}
          />
      },
    })
  }

  openFollowUpLetterInspectMaintain = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <FollowUpLetterInspectMaintain
            comment_code={record.comment_code}
          />
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-target-cmt-maintain' style={{ width: 1500 }}>
        <Card title='経過手紙対象コメント保守'>

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table bordered
              size='small'
              rowClassName={(record, index) => record.id === this.state.rowSelected[0]?.id ? 'table-row-light' : ''}
              pagination={false}
              scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
            >
              <Table.Column
                title='コード'
                dataIndex='comment_code'
                key=''
                // width={80}
                className='column-size-10'
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />
              <Table.Column
                title='コメント'
                dataIndex='comment_content'
                key=''
                // width='25%'
                className='column-size-60'
              />
              <Table.Column
                title='コメント名称'
                dataIndex='FW_comment_content'
                key=''
                // width='60%'
                className='column-size-60'
              />
              <Table.Column
                width={40}
                align='center'
                fixed='right'
                title={
                  //新規登録のボタン
                  <Button
                    size='small'
                    type='primary'
                    style={{ textAlign: 'center' }}
                    icon={<PlusOutlined />}
                    onClick={() => this.openFollowUpLetterTargetCmtEdit(true)}
                  ></Button>
                }
                render={(text, record, index) => (
                  <Dropdown
                    trigger='click'
                    size='small'
                    overlay={() => (
                      <Menu>
                        <Menu.Item key='変更' onClick={() => this.openFollowUpLetterTargetCmtEdit(false, record)}>変更</Menu.Item>
                        <Menu.Item key='検査設定' onClick={() => this.openFollowUpLetterInspectMaintain(record)}>検査設定</Menu.Item>
                        {/* <Menu.Item key='検査複数選択' onClick={() => { this.WS0270001_InspectItemSearchQueryMultipleChoice() }}>検査複数選択</Menu.Item> */}
                      </Menu>
                    )}>
                    <Button
                      size='small'
                      icon={<MoreOutlined />} />
                  </Dropdown>
                )}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpLetterTargetCmtMaintain)
