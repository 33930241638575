import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, Input, Form, Select, Modal, Button } from "antd";
import PropTypes from 'prop-types';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import WS0887001_IntroduceLetterVariousMasterInquiry from "../V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry";
class WS0922010_DayOfWeekBasedDeterminePhysicianMaster extends React.Component {
  static propTypes = {
    onFinishScreen: PropTypes.func,
    Li_ManageCode: PropTypes.number,
  }
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = '曜日別判定医マスタ';
    this.state = {
      isLoadingTable: false,
      pagination: {
        defaultPageSize: 25,
        size: 'small',
      },
      childModal: {
        width: 0,
        visible: false,
        component: null
      }
    };
  }

  componentDidMount() {
    this.getInitValues()
  }
  getInitValues = () => {
    this.setState({
      isLoadingTable: true
    })
    VenusApiRoutesV2.callApi('API000922010002', this.props)
      .then(res => {
        console.log('res->', res)
        this.formRef.current?.setFieldsValue({
          mainSource: res ? res : []
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  }

  setDataOutput = (output) => {
    console.log(output);
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  onFinish = (values) => {
    const params = values;
    console.log('pa->', params)
    this.callAPILoadData(params)
  }

  callAPILoadData = (params) => {
    this.setState({ isLoadingTable: true })
    VenusApiRoutesV2.callApi('API000922010010', params)
      .then(res => {
        console.log('res-2->', res)
        this.getInitValues()
      })
      .catch(err => { })
      .finally(() => this.setState({ isLoadingTable: false }))
  }
  updateDatasource(index, field, value) {
    let mainSource = this.formRef.current?.getFieldValue("mainSource") ? this.formRef.current?.getFieldValue("mainSource") : []
    let data = [...mainSource];

    data[index][field] = value;

    // this.setState({
    //   dataSource: data,
    // });

    this.formRef.current?.setFieldsValue({
      mainSource: data,
    });
  }

  showIntroduceLetterVariousMasterInquiry = (index, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        width: '80%',
        visible: true,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={6}
            Lo_VariousCodes={record.doctor_cd}
            onFinishScreen={(output) => {
              this.updateDatasource(index, "doctor_cd", output?.Lo_VariousCodes);
              this.updateDatasource(index, "doctor_name", output?.recordData.doctor_name);
              this.closeModal()
            }
            }
          />
        )
      }
    })
  }

  changeDoctorCd = (record, index) => {
    
  }

  render() {
    return (
      <div className="day-of-week-based-determine-physician-master">
        <Card title="曜日別判定医マスタ">
          <Form
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <Table bordered
              size="small"
              dataSource={this.formRef.current?.getFieldValue("mainSource") ? this.formRef.current?.getFieldValue("mainSource") : []}
              loading={this.state.isLoadingTable}
              pagination={{
                ...this.state.pagination,
                hideOnSinglePage: false
              }}
              rowKey={(record) => record.id}
            >
              <Table.Column 
                title="コード" 
                width='100px' 
                dataIndex="various_codes" 
                key=""
                sorter={(a, b) => a.various_codes - b.various_codes}
                render={(value, record) => {
                  let index = this.formRef.current?.getFieldValue("mainSource").findIndex(el => el.id == record.id)
                  return (
                    <>
                      <Form.Item name={['mainSource', index, 'id']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={['mainSource', index, 'various_codes']}>
                        <Input readOnly type="number" min={0} max={9999} />
                      </Form.Item>
                    </>
                  )
                }} />
              <Table.Column 
                title="曜　日" 
                dataIndex="day_of_week_name" 
                key=""
                sorter={(a, b) => a.day_of_week_name.localeCompare(b.day_of_week_name, 'ja')}
                render={(value, record) => {
                  let index = this.formRef.current?.getFieldValue("mainSource").findIndex(el => el.id == record.id)
                  return <Form.Item name={['mainSource', index, 'day_of_week_name']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column 
                title="コード" 
                width='120px' 
                dataIndex="doctor_cd" 
                key=""
                sorter={(a, b) => a.doctor_cd - b.doctor_cd}
                render={(value, record) => {
                  let index = this.formRef.current?.getFieldValue("mainSource").findIndex(el => el.id == record.id)
                  return <Form.Item name={['mainSource', index, 'doctor_cd']}>
                    <Input.Search 
                      type="number" 
                      style={{ textAlign: 'right' }}
                      onBlur={this.changeDoctorCd(record, index)} 
                      onSearch={() => {
                        this.showIntroduceLetterVariousMasterInquiry(index, record)
                      }
                    }
                    />
                  </Form.Item>
                }} />
              <Table.Column 
                title="ドクター名" 
                dataIndex="doctor_name" 
                key=""
                sorter={(a, b) => a.doctor_name.localeCompare(b.doctor_name, 'ja')}
                render={(value, record) => {
                  let index = this.formRef.current?.getFieldValue("mainSource").findIndex(el => el.id == record.id)
                  return <Form.Item name={['mainSource', index, 'doctor_name']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column title="備　　考" 
                dataIndex="remarks" key="" render={(value, record) => {
                let index = this.formRef.current?.getFieldValue("mainSource").findIndex(el => el.id == record.id)
                return <Form.Item name={['mainSource', index, 'remarks']}>
                  <Input />
                </Form.Item>
              }} />
            </Table>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type='primary' htmlType='submit' loading={this.state.isLoading}>保存</Button>
            </Form.Item>
          </Form>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0922010_DayOfWeekBasedDeterminePhysicianMaster);
