import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Button, Dropdown, Menu } from 'antd'
import DoctorNameMasterAction from 'redux/IntroductionLetter/IntroduceLetterMasterMaintain/DoctorNameMaster.action'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import ModalAdd from 'components/Commons/ModalAdd'
import ModalChange from 'components/Commons/ModalChange'

const columns = [
  {
    title: 'コード',
    dataIndex: 'various_codes',
    classNumber: '5',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '検索キー',
    dataIndex: 'search_key',
    classNumber: '20',
    type: 1,
  },
  {
    title: 'ドクター名称',
    dataIndex: 'doctor_name',
    classNumber: '40',
    type: 1,
  },
  {
    title: '備考',
    dataIndex: 'remarks',
    type: 1,
  }
]

class WS0922004_DoctorNameMaster extends React.Component {
  constructor(props) {
    super(props)

    // document.title = 'ドクター名称マスタ'

    this.formRef = React.createRef()
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    const params = {
      LnkInmanageCode: 6
    }
    DoctorNameMasterAction.index(params)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          mainSource: res ? res : []
        })
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * 保存ボタン処理
   */
  save = (output) => {
    const params = {
      LnkInmanageCode: 6,
      ...output.itemChange
    }
    DoctorNameMasterAction.save(params)
      .then(res => {
        // データ再取得
        this.index()
        this.closeModal()
      })
  }

  /**
   * 削除ボタン処理
   */
  delete = (output) => {
    const params = {
      LnkInmanageCode: 6,
      id: output.itemChange.id
    }
    DoctorNameMasterAction.delete(params)
      .then(res => {
        // データ再取得
        this.index()
        this.closeModal()
      })
  }

  /**
   * 新規追加
   */
  add = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        className: '',
        component:
          <ModalAdd
            columns={columns}
            onFinishScreen={(output) => {
              this.save(output)

            }}
          />
      },
    })
  }

  /**
   * 編集
   * @param {*} record
   */
  edit = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 550,
        className: '',
        component:
          <ModalChange
            columns={columns}
            record={record}
            onUpdate={(output) => {
              this.save(output)
            }}
            onDelete={(output) => {
              this.delete(output)
            }}
          />
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='doctor-name-master'>
        <Card title='ドクター名称マスタ'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            loading={false}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ y: 400 }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: event => {
                  // 編集、削除
                  this.edit(record)
                }
              }
            }}
          >
            <Table.Column
              title='コード'
              dataIndex='various_codes'
              width={100}
            />
            <Table.Column
              title='検索キー'
              dataIndex='search_key'
              width={120}
            />
            <Table.Column
              title='ドクター名称'
              dataIndex='doctor_name'
            />
            <Table.Column
              title='備考'
              dataIndex='remarks'
            />
            <Table.Column
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() =>
                    // 新規作成
                    this.add()
                  }
                />
              }
              width={40}
              align='center'
              render={(value, record) => (
                <Dropdown
                  trigger='click'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='1'
                        onClick={() =>
                          // 編集、削除
                          this.edit(record)
                        }
                      >
                        変更
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            />
          </Table>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0922004_DoctorNameMaster)
