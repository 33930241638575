import axios from "configs/axios";

const groupPath = "/api/specific-health-data-xml-output";

const APP_LIST = {
  getScreenData: `${groupPath}/specific-health-data-xml-output_WS2655001/get-screen-data`,
  index: `${groupPath}/specific-health-data-xml-output_WS2655001/`,
  memberList: `${groupPath}/specific-health-data-xml-output_WS2655001/member-list`,
  xmlCreate: `${groupPath}/specific-health-data-xml-output_WS2655001/xmlcreate`,

  log: `${groupPath}/specific-health-data-xml-output_WS2655001/log`,
  Extract: `${groupPath}/specific-health-data-xml-output_WS2655001/extract`,
  F12_KeyEvent: `${groupPath}/specific-health-data-xml-output_WS2655001/f12_keyevent`,
  changeGagency: `${groupPath}/specific-health-data-xml-output_WS2655001/change-gagency`,
  changeGinsuranceNum: `${groupPath}/specific-health-data-xml-output_WS2655001/change-ginsurance-num`,
  updateCheckbox: `${groupPath}/specific-health-data-xml-output_WS2655001/update-checkbox`,
  selectAll: `${groupPath}/specific-health-data-xml-output_WS2655001/select-all`,
};
const SpecificHealthDataXMLOutput = {
  async getScreenData(params) {
    return axios.get(APP_LIST.getScreenData, { params });
  },
  async MemberList(params) {
    return axios.get(APP_LIST.memberList, { params });
  },
  async changeGagency(params) {
    return axios.get(APP_LIST.changeGagency, { params });
  },
  async changeGinsuranceNum(params) {
    return axios.get(APP_LIST.changeGinsuranceNum, { params });
  },
  async updateCheckbox(params) {
    return axios.get(APP_LIST.updateCheckbox, { params });
  },
  async selectAll(params) {
    return axios.get(APP_LIST.selectAll, { params });
  },
  async Extract(params) {
    return axios.post(APP_LIST.Extract, params);
  },
  async F12_KeyEvent(params) {
    return axios.post(APP_LIST.F12_KeyEvent, params);
  }
};

export default SpecificHealthDataXMLOutput;
