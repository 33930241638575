import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, message, Row, Col } from "antd";


const style = {
  label: {
    fontWeight: 'bold',
    color: '#14468C',
  },
}

class WS1226009_MediaManage extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '媒体管理';

    this.state = {
    };
  }

  componentDidMount = () => {
    this.getScreenData();
  }
  
  getScreenData = () => {
    this.setState({ isLoading: true });
    const data = JSON.parse(window.localStorage.getItem("WS1226_MEDIA"));
    this.formRef.current?.setFieldsValue({
      branch_cd: data[1],
      send_date: data[2],
      chkup_institution_cd: data[3],
      visit_years: data[4].trim(),
      accept_flg_flg: data[5],
      version_info: data[6].trim(),
      result_data_flg: data[7],
      billing_data_flg: data[8],
    });
  }

  render() {
    return (
      <div className="media-manage">
        <Card title="媒体管理">
          <Form
            ref={this.formRef}
            className="align-left"
          >
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
                <label style={style.label}>支部コード</label>
              </Col>
              <Col span={13}>
                <Form.Item name="branch_cd" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
                <label style={style.label}>送付年月日</label>
              </Col>
              <Col span={13}>
                <Form.Item name="send_date" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
                <label style={style.label}>健診機関コード</label>
              </Col>
              <Col span={13}>
                <Form.Item name="chkup_institution_cd" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
                <label style={style.label}>受診年月</label>
              </Col>
              <Col span={13}>
                <Form.Item name="visit_years" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
                <label style={style.label}>受付有無フラグ</label>
              </Col>
              <Col span={13}>
                <Form.Item name="accept_flg_flg" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
                <label style={style.label}>バージョン情報</label>
              </Col>
              <Col span={13}>
                <Form.Item name="version_info" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={11} style={{ textAlign: "right", paddingRight: 0 }}>
              </Col>
              <Col span={4} style={{ paddingRight: 0 }}>
                <Form.Item name="result_data_flg" label=""><Input type="text" /></Form.Item>
              </Col>
              <Col span={4} style={{ paddingLeft: 0 }}>
                <Form.Item name="billing_data_flg" label=""><Input type="text" /></Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1226009_MediaManage);
