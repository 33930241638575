import axios from 'configs/axios'

const apiPaths = {
  index: '/api/not-input-check-category/specified-value-confirm',
  save: '/api/not-input-check-category/specified-value-confirm/save',
  SettingBtn: '/api/not-input-check-category/specified-value-confirm/setting-btn',
}

const SpecifiedValueConfirmService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },
  async SettingBtn(params) {
    return axios.post(apiPaths.SettingBtn, params)
  },
}

export default SpecifiedValueConfirmService
