import React from "react";
import { connect } from "react-redux";
import {
  Form, Input, Button, Card, Row, Col, message, Space, Select
} from 'antd';
import ModalDraggable from "components/Commons/ModalDraggable";
import SendEmailAction from 'redux/MN_Basis/SendEmail/SendEmail.action';

const { Option } = Select;
class WS2520001_SendEmail extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'メール送信';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      modalTitle: '',
      userNameList: [],
      changeAdress: [],
    };
  }


  /**
   * 初期設定
   */
  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let param = {
      Li_userName: this.props.userName,
    }
    SendEmailAction.getScreenData(param)
      .then((res) => {
        // 初期表示
        let subject = '';
        let content = '';
        // 返信、新規を分ける処理
        if (this.props.Adress !== undefined) {
          this.formRef.current?.setFieldsValue({
            Adress: this.props.Adress,
            Subject: 'RE:' + this.props.title,
            Content: '----->>>>:' + this.props.content,
          })
          this.setState({
            userNameList: res,
            changeAdress: this.props.Adress
          })
        } else {
          this.formRef.current?.setFieldsValue({
            Subject: subject,
            Content: content,
          })
          this.setState({
            userNameList: res
          })
        }
      }).catch((err) => {
        const res = err.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }


  onClickSend() {
    const subject = this.formRef.current?.getFieldValue("Subject");
    const content = this.formRef.current?.getFieldValue("Content");
    // 件名の入力チェック
    if (subject === '') {
      message.error('件名が入力されていません');
      return;
    }
    let param = {
      Li_userName: this.props.userName,
      Li_adress: this.state.changeAdress,
      Li_subject: subject,
      Li_content: content
    }
    SendEmailAction.onClickSend(param)
      .then((res) => {
        if (this.props.Adress !== undefined) {
          this.props.onFinishScreen();
        } else {
          //親画面に渡す
          this.props.onFinishScreen();
          this.props.index();
        }
      }).catch((err) => {
        const res = err.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  handleChange(event) {
    let adress = event;
    this.setState({
      changeAdress: adress
    })
  }

  render() {

    return (
      <div className="send-email">
        <Card title={'メッセージ作成'}>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div style={{ padding: '10px' }} >
              <Row gutter={[10, 10]}>
                <Form.Item style={{ width: '100%' }} label="宛先">
                  <Select mode="multiple" onChange={(event) => { this.handleChange(event) }} defaultValue={(this.props.Adress !== undefined) ? this.props.Adress : undefined}>
                    {this.state.userNameList.map((item) => {
                      return (
                        <Option key={item.username} style={{ height: 30 }}>{item.username}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item style={{ width: '100%' }} label="件名" name="Subject" >
                  <Input maxLength={100} style={{ height: 30 }} />
                </Form.Item>
                <Form.Item style={{ width: '100%' }} label="内容" name="Content" >
                  <Input.TextArea rows="5" />
                </Form.Item>

                <Col span={24} style={{ textAlign: 'right' }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => { this.onClickSend() }}
                    >
                      {'送信'}
                    </Button>
                  </Space>
                </Col>

              </Row>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2520001_SendEmail);
