import React from "react";
import {
  Card, Form, Table, Button, Tooltip, message, Input
} from "antd";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import BillingAddressSettingAction from "redux/basicInfo/ContractInfoMaintain/BillingAddressSetting.actions.js"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import Images from 'constants/Images';
import GetImage from 'constants/Images';
import NumberFormat from 'components/Commons/NumberFormat';
import WS0309502_BillingTypeSelect from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309502_BillingTypeSelect.jsx'

class WS0309503_BillingAddressAdjust extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props);

    // document.title = "請求先調整";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingContractData: false,

      inspectChangeAdjust: [],
      dataSource: [],
      columnList: [],
      dataList: [],
      newDataList: [],
      neoDataList: [],
      contractTermData: {},
      setCodeList: [],
      selectedSetCode: [],
      selectedItems: [],
      taxRate: '',
      rounding: '',
      totalAmount: 0,
      addFlag: false,
      dragValue: 0,
      dragIndex: 0
    };
  }

  componentDidMount = () => {
    //set_codeのみの配列を作成
    this.props.dataSourceInspectChange.map((value) => {
      this.state.selectedSetCode.push(value.set_code)
      let newObj = {
        set_code: value.set_code,
        data_division: value.data_division !== undefined ? value.data_division : value.changeType
      }
      this.state.selectedItems.push(newObj)
    })

    this.formRef.current?.setFieldsValue({
      dataList: this.props.dataList,
      dataListAdjust: this.props.dataList,
      inspectChangeAdjust: this.props.dataSourceInspectChange,
      columnList: this.props.columnList,
      // dataSource: neoDataSourceInspectChange
    })

    this.setState({
      contractTermData: this.props.contractTermData,
      inspectChangeAdjust: this.props.dataSourceInspectChange,
      columnList: this.props.columnList,
      dataList: this.props.dataList,
      dataListAdjust: this.props.dataList,
      taxRate: this.props.taxRate,
      rounding: this.props.rounding,
      totalAmount: this.props.totalAmount
    })
    // this.loadPersonalReserveData()

    //請求先ごとの合計金額を計算し、表示（作成中）
    this.calculateTotalAmount()
  }

  loadPersonalReserveData = (reloadFlag) => {
    let params = {
      reloadFlag: reloadFlag,
      contract_type: this.props.contractTermData.contract_type,
      contract_office_code: this.props.contractTermData.contract_office_code,
      contract_start_date_on: this.props.contractTermData.contract_start_date_on,
      contract_number: this.props.contractTermData.contract_number,
      medical_exam_course: this.props.contractTermData.medical_exam_course,
      setCodeList: this.state.selectedSetCode,
      selectedItems: this.state.selectedItems,
      reservation_number: this.props.reservation_number,
      columnList: this.state.columnList,
      contract_id: this.props.contractTermData.ContractId,
      dataList: this.state.dataList
    }
    BillingAddressSettingAction.reacquisition(params)
      .then((res) => {
        let newSetCodeList = []
        let newDataList = []
        res.tableData.sort(function (a, b) {
          if (a.changeType > b.changeType) {
            return 1;
          } else {
            return -1;
          }
        })
        res.tableData.map((values, index) => {
          newSetCodeList.push(values.set_code)
          for (let i = 1; i < values.arraysCounter; i++) {
            newDataList.push(values[`bill_${i}`])
          }
        })
        this.formRef.current?.setFieldsValue({
          dataList: newDataList,
          // dataListAdjust: newDataList,
          dataSourceInspectChange: res.tableData,
          columnList: res.columnList,
        })

        this.setState({
          dataList: newDataList,
          // dataListAdjust: newDataList,
          columnList: res.columnList,
          dataSourceInspectChange: res.tableData,
          setCodeList: newSetCodeList,
          addFlag: true,
        })
        // this.props.onFinishScreen()

        //請求先ごとの合計金額を計算し、表示（作成中）
        this.calculateTotalAmount()
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  /**
   * 事業所追加
   * @param {*} recordData 
   * @param {*} billingType 
   */
  addOfficeBilling = (recordData, billingType, serial_number) => {
    let duplicationCheck = false
    if (recordData !== null) {
      this.state.columnList.map((value) => {
        if (billingType == value.billing_type && recordData.office_code == value.organization_code) {
          duplicationCheck = true
        }
      })
    }

    if (duplicationCheck) {
      message.error('すでに登録されています')
    } else {
      let params = {
        contract_id: this.props.contractTermData.ContractId,
        itemList: this.state.selectedItems,
        billing_type: billingType,
        office_code: recordData !== null ? recordData.office_code : '',
        branch_store_code: recordData !== null ? recordData.branch_store_code : 0,
        reservation_number: this.props.reservation_number,
        Li_CourseLevel: this.props.course_level,
        columnList: this.state.columnList,
        dataList: this.state.dataList,
        serial_number: serial_number ?? ''
      }
      BillingAddressSettingAction.addBillingAddress_reservation(params)
        .then((res) => {
          this.loadPersonalReserveData(true)
          // this.props.onFinishScreen()
        })
    }
  }

  /**
   * 保険者追加
   * @param {*} recordData 
   * @param {*} billingType 
   */
  addInsurerBilling = (recordData, billingType, serial_number) => {
    let duplicationCheck = false
    if (recordData !== null) {
      this.state.columnList.map((value) => {
        if (billingType == value.billing_type && recordData.insurer_code == value.organization_code) {
          duplicationCheck = true
        }
      })
    }

    if (duplicationCheck) {
      message.error('すでに登録されています')
    } else {
      let params = {
        contract_id: this.props.contractTermData.ContractId,
        itemList: this.state.selectedItems,
        billing_type: billingType,
        insurer_code: recordData !== null ? recordData.insurer_code : '',
        reservation_number: this.props.reservation_number,
        Li_CourseLevel: this.props.course_level,
        columnList: this.state.columnList,
        dataList: this.state.dataList,
        serial_number: serial_number ?? ''
      }
      BillingAddressSettingAction.addBillingAddress_reservation(params)
        .then((res) => {
          this.loadPersonalReserveData(true)
          // this.props.onFinishScreen()
        })
    }
  }

  /**
   * 個人追加
   * @param {*} recordData 
   * @param {*} billingType 
   * @returns 
   */
  addPersonalbilling = (recordData, billingType) => {
    let duplicationCheck = false
    let personalCounter = 0
    let addCheck
    this.state.columnList.map((value) => {
      addCheck = value.kanji_name.includes('個人');
      if (addCheck) {
        personalCounter++
      }
      if (personalCounter >= 3) {
        duplicationCheck = true
      }
    })
    if (duplicationCheck) return message.error('個人は３人までです。')
    let params = {
      reservation_number: this.props.reservation_number,
      Li_CourseLevel: this.props.course_level,
      columnList: this.state.columnList,
      dataList: this.state.dataList,
      itemList: this.state.selectedItems,
    }
    BillingAddressSettingAction.addBillingAddress_reservation(params)
      .then((res) => {
        this.loadPersonalReserveData(true)
        // this.props.onFinishScreen()
      })
  }

  deleteColumn = (values) => {
    let params = {
      contract_type: this.state.contractTermData.contract_type,
      contract_office_code: this.state.contractTermData.contract_office_code,
      contract_start_date_on: this.state.contractTermData.contract_start_date_on,
      contract_number: this.state.contractTermData.contract_number,
      medical_exam_course: this.state.contractTermData.medical_exam_course,
      organization_type: values.billing_type,
      organization_code: values.organization_code,
      billing_type: values.billing_type,
      setCodeList: this.state.setCodeList,
      serial_number: values.serial_number,
      reservation_number: this.props.reservation_number
    }

    BillingAddressSettingAction.deleteBillingAddress(params)
      .then((res) => {
        this.setState({
          addFlag: true
        })
        this.loadPersonalReserveData(true)
        // this.props.onFinishScreen()
      })
  }

  billingEditing = (totalPrice, index) => {
    totalPrice = Number(NumberFormat.changeHankaku(totalPrice));
    let taxRate = this.props.taxRate + 1
    let taxValue, unitPrice
    switch (this.props.rounding) {
      case 0:
        taxValue = Math.round(totalPrice - totalPrice / taxRate)
        break;
      case 1:
        taxValue = Math.floor(totalPrice - totalPrice / taxRate)
        break;
      case 2:
        taxValue = Math.ceil(totalPrice - totalPrice / taxRate)
        break;
    }

    unitPrice = totalPrice - taxValue

    let newDataList = []
    let tempDataList = [...this.state.dataList]


    for (let i = 0; i < tempDataList.length; i++) {
      let element = { ...tempDataList[i] }
      if (index === i) {
        element.organization_unit_price = unitPrice
        element.organization_consumption_tax = taxValue
        element.organization_total_price = totalPrice
      }
      newDataList.push(element)
    }

    let newTotalAmount = 0

    newDataList.map((value, index) => {
      if (value.data_division == 30 || value.data_division == 60) {
        newTotalAmount -= value.organization_total_price
      } else {
        newTotalAmount += value.organization_total_price
      }
    })
    this.setState({
      dataList: newDataList,
      // dataListAdjust: newDataList,
      totalAmount: newTotalAmount
    })

    this.formRef.current?.setFieldsValue({
      dataList: newDataList,
      // dataListAdjust: newDataList,
    })

    //請求先ごとの合計金額を計算
    this.calculateTotalAmount()
  }

  onFinish = () => {
    this.props.onFinishScreen({
      newDataList: this.state.dataList,
      newTotalAmount: this.state.totalAmount,
      newColumnList: this.state.columnList,
      addFlag: this.state.addFlag
    })
  }

  calculateTotalAmount = () => {
    let inspectChangeAdjust = this.formRef.current?.getFieldValue('inspectChangeAdjust')
    let dataList = this.formRef.current?.getFieldValue('dataList')
    let dataListAdjust = dataList
    let columnList = this.formRef.current?.getFieldValue('columnList')

    //合計金額を表示する行を追加
    let total = {
      'id': 'total',
      'Expression_17': '合計金額',
      'set_code': 'totalAmount'
    }

    let alreadyPushed = false
    inspectChangeAdjust.map((value) => {
      if (value.set_code == 'totalAmount') {
        alreadyPushed = true
      }
    })

    if (alreadyPushed == false) {
      inspectChangeAdjust.push(total)
    }
    let totlaAmountOfBillingAddress = {}

    columnList.map((value) => {
      let sum = 0
      dataList.map((item) => {
        if (value.serial_number == item.serial_number) {
          if (item.data_division === 60) {
            sum = sum - (item.organization_total_price ?? 0)
          } else {
            sum = sum + (item.organization_total_price ?? 0)
          }
        }
      })
      // dataListAdjust.map((item) => {
      //   if (value.serial_number == item.serial_number && item.set_code !== 'totalAmount') {
      //     sum = sum + (item.organization_total_price ?? 0)
      //   }
      // })
      totlaAmountOfBillingAddress = {
        'serial_number': value.serial_number,
        'set_code': 'totalAmount',
        'organization_total_price': sum
      }
      dataListAdjust.push(totlaAmountOfBillingAddress)
    })

    this.formRef.current?.setFieldsValue({
      inspectChangeAdjust: inspectChangeAdjust,
      dataListAdjust: dataListAdjust
    })

    this.setState({
      inspectChangeAdjust: inspectChangeAdjust,
      dataListAdjust: dataListAdjust
    })
  }

  /**
   * ドラック開始処理
   * @param {*} e 
   * @param {*} index 
   */
  dragEvent(e, index) {
    this.setState({
      dragValue: e.target.value,
      dragIndex: index
    })
  }



  /**
   * ドロップ後の処理
   * @param {*} e 
   * @param {*} tableIndex 
   */
  dropEvent(e, tableIndex) {
    // ドラック値格納
    let changeValue = this.state.dragValue
    let dragIndex = this.state.dragIndex
    console.log(changeValue);
    console.log(tableIndex);
    console.log(e);
    // どこにドロップしたのか
    let value = e.target.id.substring(0, e.target.id.indexOf('_organization_total_price'))
    let idIndex = Number(value.substr(value.indexOf('_') + 1))
    console.log(idIndex);
    // 入れ替える値を宣言
    let totalPrice = Number(NumberFormat.changeHankaku(changeValue));
    let totalPrice2 = Number(NumberFormat.changeHankaku(e.target.value));
    let taxRate = this.props.taxRate + 1
    let taxValue, unitPrice
    let taxValue2, unitPrice2
    // 税計算
    switch (this.props.rounding) {
      case 0:
        taxValue = Math.round(totalPrice - totalPrice / taxRate)
        break;
      case 1:
        taxValue = Math.floor(totalPrice - totalPrice / taxRate)
        break;
      case 2:
        taxValue = Math.ceil(totalPrice - totalPrice / taxRate)
        break;
    }
    switch (this.props.rounding) {
      case 0:
        taxValue2 = Math.round(totalPrice2 - totalPrice2 / taxRate)
        break;
      case 1:
        taxValue2 = Math.floor(totalPrice2 - totalPrice2 / taxRate)
        break;
      case 2:
        taxValue2 = Math.ceil(totalPrice2 - totalPrice2 / taxRate)
        break;
    }
    unitPrice = totalPrice - taxValue
    unitPrice2 = totalPrice2 - taxValue2

    let newDataList = []
    let tempDataList = [...this.state.dataList]

    // 値を入れるinputを探して入れる
    for (let i = 0; i < tempDataList.length; i++) {
      let element = { ...tempDataList[i] }
      if (idIndex === i) {
        element.organization_unit_price = unitPrice
        element.organization_consumption_tax = taxValue
        element.organization_total_price = totalPrice
      }
      if (dragIndex === i) {
        element.organization_unit_price = unitPrice2
        element.organization_consumption_tax = taxValue2
        element.organization_total_price = totalPrice2
      }
      newDataList.push(element)
    }

    let newTotalAmount = 0

    newDataList.map((value, index) => {
      if (value.data_division == 30 || value.data_division == 60) {
        newTotalAmount -= value.organization_total_price
      } else {
        newTotalAmount += value.organization_total_price
      }
    })
    this.setState({
      dataList: newDataList,
      // dataListAdjust: newDataList,
      totalAmount: newTotalAmount
    })

    this.formRef.current?.setFieldsValue({
      dataList: newDataList,
      // dataListAdjust: newDataList,
    })

    //請求先ごとの合計金額を計算
    this.calculateTotalAmount()

  }

  render() {
    return (
      <div className="WS0309503_BillingAddressAdjust">
        <Card title='請求先調整'>
          <Form ref={this.formRef}>
            <div className="box_container">
              <Table
                size='small'
                // dataSource={this.state.dataSourceInspectChange}
                dataSource={this.formRef.current?.getFieldValue('inspectChangeAdjust')}
                // dataSource={this.formRef.current?.getFieldValue('dataSourceInspectChange')}
                rowKey={record => record.id}
                pagination={false}
                bordered
                scroll={{ y: resizableTableScroll(150), x: 'max-content' }}
              >
                <Table.Column
                  title=''
                  dataIndex='changeType'
                  className='column-size-2'
                  render={(value, record, index) => {
                    return <div style={{ textAlign: 'center' }}>
                      {record.changeType ?
                        <img style={{ width: 16 }} src={GetImage(record.changeType)} alt='icon' />
                        : ''
                      }
                    </div>
                  }} />
                <Table.Column
                  title='名称'
                  dataIndex='Expression_17'
                  className="column-size-40"
                // render={(value, record) => {
                //   return (
                //     <div className="box_inner_horizontal">
                //       <div><img src={Images(record.data_division)} /></div>
                //       <div>{value}</div>
                //     </div>
                //   )
                // }}
                />
                {
                  this.state.columnList.map((value, index) => {
                    return (
                      <Table.Column
                        title={() => {
                          return (
                            <Tooltip
                              title={value.full_name}
                            >
                              <div className="box_inner_horizontal">
                                <div>{value.kanji_name.substr(0, 7)}</div>
                                <Button
                                  icon={<CloseOutlined />}
                                  size='small'
                                  disabled={this.props.unchangeableSerialNumberList.includes(value.serial_number)}
                                  style={{
                                    border: 'none',
                                    marginLeft: 'auto',
                                    display: this.props.reservation_number > 0 ? '' : 'none'
                                  }}
                                  onClick={() => this.deleteColumn(value)}
                                />
                              </div>
                            </Tooltip>
                          )
                        }}
                        key={index}
                        width='140px'
                        render={(item, record, index) => {
                          let price = 0
                          let total_price = 0
                          let consumption_tax = 0
                          let billing_limit = 0
                          let data_division = record.data_division > 0 ? record.data_division : record.changeType
                          let newIndex = null
                          // this.state.newDataList.push(this.state.dataList.find(e => e.serial_number == value.serial_number))
                          // this.formRef.current?.getFieldValue('dataList').map((element, index) => {
                          //   if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                          //     newIndex = index
                          //     console.log(newIndex);
                          //   }
                          // })

                          // this.state.neoDataList.map((element, index) => {
                          //   console.log()
                          //   if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                          //     newIndex = index
                          //   }
                          // })
                          // this.state.dataListAdjust.map((element, index) => {
                          this.state.dataListAdjust.map((element, index) => {
                            if (element.data_division == data_division && element.set_code == record.set_code && element.serial_number == value.serial_number) {
                              newIndex = index
                            }
                          })
                          return (
                            <Tooltip
                              title={this.props.unchangeableSerialNumberList.includes(value.serial_number) ? '請求済みのため変更できません' : ''}
                            >
                              <Form.Item
                                draggable="true"
                                name={['dataListAdjust', newIndex, 'organization_total_price']}
                              >
                                <Input
                                  style={{ textAlign: 'right' }}
                                  draggable="true"
                                  disabled={this.props.unchangeableSerialNumberList.includes(value.serial_number)}
                                  onBlur={(e) => { this.billingEditing(e.target.value, newIndex) }}
                                  onDrag={(e) => this.dragEvent(e, newIndex)}
                                  // onDragOverがないとonDropが機能しないので注意
                                  onDragOver={(event) => event.preventDefault()}
                                  onDrop={(e) => this.dropEvent(e, newIndex)}
                                  //合計金額の行のみ入力できないようにする
                                  readOnly={item.set_code == 'totalAmount' ? true : false}
                                />
                              </Form.Item>
                            </Tooltip>

                            //別画面で請求金額変更　必要に応じて復活
                            // <Form.Item name={[]}>
                            //   <Input />
                            // </Form.Item>
                            // <div
                            //   style={{
                            //     textAlign: 'right',
                            //   }}
                            //   onDoubleClick={() => {
                            //     this.setState({
                            //       childModal: {
                            //         ...this.state.childModal,
                            //         visible: true,
                            //         width: 400,
                            //         component: (
                            //           <WS0309501_BillingEditing
                            //             flag={true}
                            //             reservation_number={this.props.reservation_number}
                            //             setCodeList={this.state.setCodeList}
                            //             values={{
                            //               total_price: total_price,
                            //               billing_limit: billing_limit,
                            //               kanji_name: value.kanji_name,
                            //               code: value.organization_code,
                            //               type: value.billing_type,
                            //               tax: consumption_tax
                            //             }}
                            //             examName={record.name}
                            //             contract_type={this.state.contractTermData.contract_type}
                            //             contract_office_code={this.state.contractTermData.contract_office_code}
                            //             contract_start_date_on={this.state.contractTermData.contract_start_date_on}
                            //             contract_number={this.state.contractTermData.contract_number}
                            //             medical_exam_course={this.state.contractTermData.medical_exam_course}
                            //             data_division={data_division}
                            //             rounding={this.state.rounding}
                            //             taxRate={this.state.taxRate}
                            //             set_code={record.set_code}
                            //             onFinishScreen={(output) => {
                            //               console.log(output)
                            //               let newDataList = this.state.dataList
                            //               let newTotalAmount = 0
                            //               console.log(newDataList)
                            //               this.state.dataList.map((element, index) => {
                            //                 if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                            //                   newDataList[index].organization_unit_price = output.unit_price
                            //                   newDataList[index].organization_consumption_tax = output.tax
                            //                   newDataList[index].organization_total_price = output.total_price
                            //                   newDataList[index].billing_limit = output.billing_limit
                            //                 }
                            //               })

                            //               newDataList.map((value, index) => {
                            //                 if (value.data_division == 30 || value.data_division == 60) {
                            //                   newTotalAmount -= value.organization_total_price
                            //                 } else {
                            //                   newTotalAmount += value.organization_total_price
                            //                 }
                            //               })

                            //               this.setState({
                            //                 dataList: newDataList,
                            //               })
                            //               this.props.onFinishScreen({
                            //                 newDataList: newDataList,
                            //                 newTotalAmount: newTotalAmount
                            //               })
                            //               this.closeModal()
                            //             }}
                            //           />
                            //         ),
                            //       },
                            //     });
                            //   }}
                            // >
                            //   {this.state.dataList.map((element, index) => {
                            //     if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                            //       total_price = element.organization_total_price
                            //       consumption_tax = element.organization_consumption_tax
                            //       billing_limit = element.billing_limit
                            //       data_division = element.data_division

                            //       //表示用
                            //       if (element.data_division == 30 || element.data_division == 60) {
                            //         price = element.organization_total_price * (-1)
                            //       } else {
                            //         price = element.organization_total_price
                            //       }
                            //     }
                            //   })}
                            //   {price}
                            // </div>
                          )
                        }}
                      />
                    )
                  })
                }
                <Table.Column
                  key='action'
                  fixed='right'
                  align='center'
                  className="column-size-2"
                  title={(
                    <Button
                      type="primary"
                      size="small"
                      title="新規"
                      disabled={this.props.reservation_number > 0 ? false : true}
                      // style={{ display: this.props.reservation_number > 0 ? '' : 'none' }}
                      icon={<PlusOutlined />}
                      loading={this.state.isLoadingInsurersList} onClick={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            width: 300,
                            visible: true,
                            component: (
                              <WS0309502_BillingTypeSelect
                                contractTermData={this.state.contractTermData}
                                columnList={this.state.columnList}
                                setCodeList={this.state.setCodeList}
                                onAddOfficeBilling={(output) => {
                                  this.addOfficeBilling(output.recordData ?? null, output.billingType, output.serial_number ?? '')
                                  this.closeModal()
                                }}
                                onAddInsurerBilling={(output) => {
                                  this.addInsurerBilling(output.recordData ?? null, output.billingType, output.serial_number ?? '')
                                  this.closeModal()
                                }}
                                onAddPersonalBilling={(output) => {
                                  this.addPersonalbilling()
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        });
                      }} />
                  )}
                />
              </Table>
              <div className="box_button_bottom_right">
                <Button
                  type='primary'
                  onClick={() => this.onFinish()}
                >
                  <span className='btn_label'>
                    確定
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}
export default WS0309503_BillingAddressAdjust