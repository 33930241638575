import axios from "configs/axios";

const API_LIST = {
    getInit: "/api/associate-insure-param-maintain/inspect-info-correct",
    
}

const InspectInfoCorrect = {
    async getInit(params){
        console.log('services_params->', params)
        return axios.get(API_LIST.getInit, {params});
    },
    
};
export default InspectInfoCorrect;