import React from "react";
import { connect } from "react-redux";
import GetImage from "constants/Images";
import PropTypes from "prop-types";
import { Card, Form, Button, Table, Space, message, Dropdown, Menu } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import WS0331020_BatchUpdateProcessAdd from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0331020_BatchUpdateProcessAdd';
import { calculatorUpdateProcessAction } from "redux/basicInfo/ContractInfoBatchProcess/BatchUpdateProcess.actions.js";
import './WS0331019_BatchUpdateProcess.scss';
import { ModalConfirm } from "components/Commons/ModalConfirm.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";

class WS0331019_BatchUpdateProcess extends React.Component {
  static propTypes = {
    Lo_StsUpdate: PropTypes.any,
    Lio_TaxClassify: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '一括更新処理';

    this.state = {
      initValue: {
        ChangeType: 40,
        Lio_TaxClassify: 2,
        Rounding: 0,
        Tax: 0.10
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      dataSource: [],
      isLoading: false,
      rowSelect: {},
      isChangeValue: false,
    };
    this.handleAddRowTable = this.handleAddRowTable.bind(this)
  }

  updateBtn = () => {
    let params = {
      updateData: this.state.dataSource,
      selectRows: this.props.selectedRows
    }
    calculatorUpdateProcessAction.eventF12(params).then(res => {
      if (res.data.Lo_StsTaxClassifyExcluded) {
        message.warning(res.data.message)
      }
      this.props.onFinishScreen()
    })
      .catch(err => message.error(err.response.data.message || "エラーが発生しました"))
  }

  // add new record
  async handleAddRowTable(output) {
    let newRow = {
      ...output,
      id: Math.round(Math.random() * 1000)
    };
    let data = [...this.state.dataSource];

    data.push(newRow);

    await this.setState({
      dataSource: data,
      rowSelected: [newRow],
      selectedRowKeys: [newRow.id],
      selectedRows: [newRow],
      indexTable: 0,
    });
    this.formRef.current?.setFieldsValue(data)
    this.forceUpdate();
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  ChangeType(record) {
    let ChangeType
    switch (record) {
      case 10:
        ChangeType = 'コース'
        break;
      case 20:
        ChangeType = '追　加'
        break;
      case 30:
        ChangeType = '不　要'
        break;
      case 40:
        ChangeType = 'オプション'
        break;
      case 50:
        ChangeType = '追加選択'
        break;
      case 60:
        ChangeType = '不要選択'
        break;
    }
    return ChangeType
  }

  Rounding(record) {
    let Rounding
    switch (record) {
      case 0:
        Rounding = '四捨五入'
        break;
      case 1:
        Rounding = '切捨'
        break;
      case 2:
        Rounding = '切上'
        break;
    }
    return Rounding
  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centerd: false
      },
    })
  }

  render() {
    return (
      <div className="batch-update-process">
        <Card title="一括更新処理">
          <Form ref={this.formRef} initialValues={this.state.initValue}>
            <Table
              size='small'
              bordered
              dataSource={this.state.dataSource}
              loading={this.state.isLoading}
              pagination={{
                ...this.state.pagination,
                hideOnSinglePage: this.state.dataSource.length > 10 ? false : true
              }}
              rowKey={(record) => record.id}
            >
              <Table.Column title="種　別" width={90} render={(text, record, index) => (
                <Form.Item>
                  <div>{this.ChangeType(record.ChangeType)}</div>
                </Form.Item>
              )} />
              <Table.Column width={40} align='center' render={(text, record, index) => (
                <Form.Item>
                  <img src={GetImage(record.ChangeType)} alt='icon' />
                </Form.Item>
              )} />
              <Table.Column title="ｺｰﾄﾞ" render={(text, record, index) => (
                <Space className='cssSpaceLeft'>
                  <Form.Item >
                    <div>{record.set_code}</div>
                  </Form.Item>
                  <Form.Item name='set_short_name' >
                    <div>{record.set_short_name}</div>
                  </Form.Item>
                </Space>
              )} />
              <Table.Column title="端数" width={80} align='center' render={(text, record, index) => (
                <Form.Item>
                  <div>{this.Rounding(record.Rounding)}</div>
                </Form.Item>
              )} />
              <Table.Column title="税率" width={40} align='center' render={(text, record, index) => (
                <Form.Item>
                  <div>{record.TaxRate}</div>
                </Form.Item>
              )} />
              <Table.Column
                name='Lio_InsurerUnitPriceAmount'
                title="保険者"
                width={80}
                render={(text, record, index) => (
                  <>
                    <div>{record.Lio_InsurerUnitPriceAmount}</div>
                    <Space className='cssSpace'>
                      <span >(</span>
                      <div>{record.Lio_InsurerTax}</div>
                      <span >)</span>
                    </Space>
                  </>
                )}
              />
              <Table.Column title="事業所" width={80} render={(text, record, index) => (
                <>
                  <div>{record.Lio_OfficeUnitPriceAmount}</div>
                  <Space className='cssSpace'>
                    <span >(</span>
                    <div>{record.Lio_OfficeTax}</div>
                    <span >)</span>
                  </Space>
                </>
              )} />
              <Table.Column title="他団体" width={80} render={(text, record, index) => (
                <>
                  <div>{record.Lio_OtherGroupUnitPriceAmount}</div>
                  <Space className='cssSpace'>
                    <span >(</span>
                    <div>{record.Lio_OtherGroupTax}</div>
                    <span >)</span>
                  </Space>
                </>
              )} />
              <Table.Column title="個人1" width={80} render={(text, record, index) => (
                <>
                  <div>{record.Lio_Personal1UnitPriceAmount}</div>
                  <Space className='cssSpace'>
                    <span >(</span>
                    <div>{record.Lio_Personal1Tax}</div>
                    <span >)</span>
                  </Space>
                </>
              )} />
              <Table.Column title="個人2" width={80} render={(text, record, index) => (
                <>
                  <div>{record.Lio_Personal2UnitPriceAmount}</div>
                  <Space className='cssSpace'>
                    <span >(</span>
                    <div>{record.Lio_Personal2Tax}</div>
                    <span >)</span>
                  </Space>
                </>
              )} />
              <Table.Column title="個人3" width={80} render={(text, record, index) => (
                <>
                  <div>{record.Lio_Personal3UnitPriceAmount}</div>
                  <Space className='cssSpace'>
                    <span >(</span>
                    <div>{record.Lio_Personal3Tax}</div>
                    <span >)</span>
                  </Space>
                </>
              )} />
              <Table.Column title="合計" width={80} render={(text, record, index) => (
                <Form.Item>
                  <div>{record.allTotal}</div>
                </Form.Item>
              )} />
              <Table.Column align='center' width={40}
                title={() => (
                  <Button size='small' type='primary' icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 850,
                          component: (
                            <WS0331020_BatchUpdateProcessAdd
                              Rounding={0}
                              TaxRate={0.1}
                              TaxClassify={0}
                              set_short_name={''}
                              Lio_InsurerUnitPriceAmount={0}
                              Lio_InsurerTax={0}
                              Lio_InsurerTotal={0}
                              Lio_OfficeUnitPriceAmount={0}
                              Lio_OfficeTax={0}
                              Lio_OfficeTotal={0}
                              Lio_OtherGroupUnitPriceAmount={0}
                              Lio_OtherGroupTax={0}
                              Lio_OtherGroupTotal={0}
                              Lio_Personal1UnitPriceAmount={0}
                              Lio_Personal1Tax={0}
                              Lio_Personal1Total={0}
                              Lio_Personal2UnitPriceAmount={0}
                              Lio_Personal2Tax={0}
                              Lio_Personal2Total={0}
                              Lio_Personal3UnitPriceAmount={0}
                              Lio_Personal3Tax={0}
                              Lio_Personal3Total={0}

                              onFinishScreen={(data) => {
                                this.closeModal()
                                this.handleAddRowTable(data)
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                )}
                render={(text, record, index) => (
                  <Dropdown size='small' trigger='click' overlay={() => (
                    <Menu >
                      <Menu.Item key="1" onClick={() => (
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 850,
                            component: (
                              <WS0331020_BatchUpdateProcessAdd
                                Rounding={record.Rounding ? record.Rounding : 0}
                                TaxRate={record.TaxRate ? record.TaxRate : 0.1}
                                TaxClassify={record.TaxClassify ? record.TaxClassify : 0}
                                ChangeType={record.ChangeType ? record.ChangeType : ''}
                                set_code={record.set_code ? record.set_code : ''}
                                set_short_name={record.set_short_name ? record.set_short_name : ''}
                                Lio_InsurerUnitPriceAmount={record.Lio_InsurerUnitPriceAmount ? record.Lio_InsurerUnitPriceAmount : 0}
                                Lio_InsurerTax={record.Lio_InsurerTax ? record.Lio_InsurerTax : 0}
                                Lio_InsurerTotal={record.Lio_InsurerTotal ? record.Lio_InsurerTotal : 0}
                                Lio_OfficeUnitPriceAmount={record.Lio_OfficeUnitPriceAmount ? record.Lio_OfficeUnitPriceAmount : 0}
                                Lio_OfficeTax={record.Lio_OfficeTax ? record.Lio_OfficeTax : 0}
                                Lio_OfficeTotal={record.Lio_OfficeTotal ? record.Lio_OfficeTotal : 0}
                                Lio_OtherGroupUnitPriceAmount={record.Lio_OtherGroupUnitPriceAmount ? record.Lio_OtherGroupUnitPriceAmount : 0}
                                Lio_OtherGroupTax={record.Lio_OtherGroupTax ? record.Lio_OtherGroupTax : 0}
                                Lio_OtherGroupTotal={record.Lio_OtherGroupTotal ? record.Lio_OtherGroupTotal : 0}
                                Lio_Personal1UnitPriceAmount={record.Lio_Personal1UnitPriceAmount ? record.Lio_Personal1UnitPriceAmount : 0}
                                Lio_Personal1Tax={record.Lio_Personal1Tax ? record.Lio_Personal1Tax : 0}
                                Lio_Personal1Total={record.Lio_Personal1Total ? record.Lio_Personal1Total : 0}
                                Lio_Personal2UnitPriceAmount={record.Lio_Personal2UnitPriceAmount ? record.Lio_Personal2UnitPriceAmount : 0}
                                Lio_Personal2Tax={record.Lio_Personal2Tax ? record.Lio_Personal2Tax : 0}
                                Lio_Personal2Total={record.Lio_Personal2Total ? record.Lio_Personal2Total : 0}
                                Lio_Personal3UnitPriceAmount={record.Lio_Personal3UnitPriceAmount ? record.Lio_Personal3UnitPriceAmount : 0}
                                Lio_Personal3Tax={record.Lio_Personal3Tax ? record.Lio_Personal3Tax : 0}
                                Lio_Personal3Total={record.Lio_Personal3Total ? record.Lio_Personal3Total : 0}

                                onFinishScreen={(data) => {
                                  this.closeModal()
                                  this.formRef.current?.setFieldsValue(data)
                                  let copyData = [...this.state.dataSource]
                                  let id = record.id
                                  let length
                                  for (let i = 0; i < copyData.length; i++) {
                                    if (record.id === copyData[i].id) {
                                      copyData[i] = data
                                      copyData[i]['id'] = id
                                      length = i
                                    }
                                  }
                                  this.setState({
                                    dataSource: copyData,
                                    rowSelected: [copyData[length]],
                                    selectedRowKeys: [copyData[length].id],
                                    indexTable: 0,
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      )}>編集</Menu.Item>
                    </Menu>
                  )}>
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                )}
              />
            </Table>

            <div className="box_button_bottom_right">
              <Button
                type="primary"
                disabled={this.state.dataSource?.length === 0}
                onClick={() => {
                  ModalConfirm({
                    content: 'この内容を契約にー括で追加しますか？',
                    okText: 'はい',
                    cancelText: 'いいえ',
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => {
                      this.updateBtn();
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  更新
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0331019_BatchUpdateProcess);
