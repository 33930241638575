import React from "react";
import { connect } from "react-redux";

import { Card, Input, Table, message} from "antd";
import Form from "antd/lib/form/Form";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1384002_FinancialSupportInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '支援内容照会';

    this.state = {
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData = () => {
    this.setState({ isLoadingInsurersList: true });
    VenusApiRoutesV2.callApi("API001384002002", {'HealthGuideDivision': this.props?.healthGuideDivision?.value})
      .then(res => {
        
      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally(() => this.setState({ isLoadingInsurersList: false }));
  };


  render() {
    return (
      <div className="financial-support-inquiry">
        <Card className="mb-2" title="支援内容照会">

          <Table bordered
            dataSource={[]}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: 1,
              pageSize: 1,
              showSizeChanger: true,
              onChange: (page, pageSize) => { },
              onShowSizeChange: (page, pageSize) => { },
            }}
            rowKey={(record) => record.id}
          >
            <Table.Column title="支援内容" dataIndex="" key="" />
            <Table.Column title="区分" dataIndex="" key="" />
            <Table.Column title="形態" dataIndex="" key="" />
            <Table.Column title="値" dataIndex="" key="" render={(text, record, index) => (
              <Input readOnly
                value={text}
                style={{ textAlign: 'right', border: 'none' }}
              />
            )} />
            <Table.Column title="単位" dataIndex="" key="" />
            <Table.Column title="ポイント" dataIndex="" key="" render={(text, record, index) => (
              <Input readOnly
                value={text}
                style={{ textAlign: 'right', border: 'none' }}
              />
            )} />
            <Table.Column title="最低値" dataIndex="" key="" render={(text, record, index) => (
              <Input readOnly
                value={text}
                style={{ textAlign: 'right', border: 'none' }}
              />
            )} />
            <Table.Column title="最高値" dataIndex="" key="" render={(text, record, index) => (
              <Input readOnly
                value={text}
                style={{ textAlign: 'right', border: 'none' }}
              />
            )} />
          </Table>

        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1384002_FinancialSupportInquiry);
