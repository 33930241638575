import React from "react";
import { connect } from "react-redux";

import { Card, Table, } from "antd";
import CmtClassifyInquiryAction from "redux/SpecificInsureGuide/InsureGuideInitInput/CmtClassifyInquiry.action.js";

class WS1387001_CmtClassifyInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00170:コメント区分照会';

    this.state = {
      DisplayList: [],
    };
  }

  componentDidMount() {
    let params = {
      commentDivision: this.props.commentDivision
    }
    console.log(params);
    CmtClassifyInquiryAction.index(params)
      .then((res) => {
        this.setState({ DisplayList: res })
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="cmt-classify-inquiry">
        <Card className="mb-2" title="コメント区分照会">
          <Table
            bordered
            size="small"
            dataSource={this.state.DisplayList}
            loading={false}
            rowKey={(record) => record.id}
            pagination={false}
            onRow={(record) => ({
              onDoubleClick: (event) => {
                this.props.onFinishScreen(record)
              }
            })}
          >

            <Table.Column
              width={50}
              title="コード"
              dataIndex="exam_comment_code"
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />

            <Table.Column
              title="画面内容"
              dataIndex="screen_content"
            />

          </Table>
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1387001_CmtClassifyInquiry);
