import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Select, Button, Table, Radio, Modal, Descriptions, Tooltip } from 'antd'
import {
  QuestionCircleOutlined,
  MoreOutlined,
  CheckOutlined
} from '@ant-design/icons'
import WS1490002_SpecificDatePeopleNumEdit from './WS1490002_SpecificDatePeopleNumEdit.jsx'
import SpecificDatePeopleNumSettingAction from 'redux/SystemMaintenance/SpecificDatePeopleNumSetting/SpecificDatePeopleNumSetting.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moment from 'moment'

// タイプ リスト
const manageDivisionList = [
  { value: 1, label: 'コース' },
  { value: 2, label: '検査' },
]

// 表示MAX行数
const rowCount = 20
class WS1490001_SpecificDatePeopleNumSetting extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_Date: PropTypes.any,
    Li_Facility: PropTypes.any,
    Li_ManageDivision: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '特定日人数設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
      totalCount: 0, //合計値
      stsSetSpecificDatesPeopleNum: false, //特定日フラグ
      kyusinKbn: false, //休診日フラグ
      dataSource: [],
      facilityTypeList: [], //施設一覧
      dateChar: null, // 日付
      facilityTypeName: '', // 施設名
      manageDivisionName: '', // タイプ名
      rowList: [] //2ケタゼロ埋め（表示行）リスト
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < rowCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ rowList: array })

    // 日付とタイプの初期値を設定
    let date = moment(this.props.Li_Date) ?? moment(new Date()) //親から日付を渡されていない場合、本日の日付を設定する
    let manageDivisionValue = this.props.Li_ManageDivision ?? 1
    this.setState({
      dateChar: date.format('YYYY/MM/DD'),
      manageDivisionName: manageDivisionList.find(item => (item.value === manageDivisionValue)).label,
    })
    this.formRef.current?.setFieldsValue({
      DateChar: date,
      ManageDivision: manageDivisionValue
    })

    // 初期値（施設）取得
    this.index()
  }

  /**
   * 初期値取得
   */
  index() {
    this.setState({ isLoading: true })
    // 初期値（施設）取得
    SpecificDatePeopleNumSettingAction.index()
      .then((res) => {
        if (res) {
          // 施設一覧を取得
          let facilityList = [...res.FacilityType]
          let facilityTypeValue = this.props.Li_Facility ?? 1
          // 施設設定
          this.setState({
            facilityTypeList: facilityList,
            facilityTypeName: facilityList.find(item => (item.value === facilityTypeValue)).label,
          })
          this.formRef.current?.setFieldsValue({
            FacilityType: facilityTypeValue
          })
          // 選択されている日付のデータを取得
          this.getNumPeople()
        }
      })
  }

  /**
   * 指定されている日付と施設を基に人数枠を取得
   */
  getNumPeople() {
    this.setState({ isLoading: true })

    // 日付チェック
    let date = this.formRef.current?.getFieldValue('DateChar')
    // 日付が空の場合
    if (date === null) {
      // 初期化
      this.setState({
        dateChar: null,
        dataSource: [],
      })
      return
    }
    this.setState({ dateChar: moment(date).format('YYYY/MM/DD') })

    let params = {
      date_on: moment(date).format('YYYY/MM/DD'),
      FacilityType: this.formRef.current?.getFieldValue('FacilityType'),
      ManageDivision: this.formRef.current?.getFieldValue('ManageDivision')
    }
    // 人数枠を取得
    SpecificDatePeopleNumSettingAction.getNumPeople(params)
      .then((res) => {
        if (res) {
          let array = []
          let totalCnt = 0
          let obj, nameNumber, data, name
          // 2桁ゼロ埋めリスト
          let numberList = [...this.state.rowList]
          for (let i = 0; i < rowCount; i++) {
            // 2桁ゼロ埋めの値を取得
            nameNumber = numberList[i]
            // 表示名称
            name = res.DisplayList[0][`displayName_${nameNumber}`]
            obj = {
              id: `limit_${nameNumber}`,
              displayName: name
            }


            // 特定日フラグをチェック
            if (res.StsSetSpecificDatesPeopleNum) {
              // 特定日の値を取得
              data = { ...res.SpecificDay }

            } else {
              // 基準日の値を格納
              data = { ...res.ReferenceDay }
            }

            // 各合計の人数を足す
            totalCnt += data.Total[`limit_${nameNumber}`]

            // １行にデータをまとめる
            obj = {
              ...obj,
              limitAM: data.AM[`limit_${nameNumber}`],
              limitPM: data.PM[`limit_${nameNumber}`],
              limitTotal: data.Total[`limit_${nameNumber}`],
              // 基準日
              ReferenceLimitAM: res.ReferenceDay.AM[`limit_${nameNumber}`],
              ReferenceLimitPM: res.ReferenceDay.PM[`limit_${nameNumber}`],
              ReferenceLimitTotal: res.ReferenceDay.Total[`limit_${nameNumber}`]
            }

            // 表示名称がない && 人数設定無し の場合は、continue
            if (
              (name === '' || name === null) &&
              obj.limitTotal === 0 &&
              obj.ReferenceLimitTotal === 0
            ) {
              continue
            }

            // 配列に追加
            array.push(obj)
          }
          // テーブル用dataSourceと合計人数に設定
          this.setState({
            dataSource: array,
            totalCount: totalCnt, //合計値
            stsSetSpecificDatesPeopleNum: res.StsSetSpecificDatesPeopleNum, // 特定日ありorなし
            kyusinKbn: res.kyusinkbn, // 休診日フラグ
          })
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  /**
   * 特定日のAMまたはPMの変更時処理
   * @param {*} data 例：{id: 'limit_01', limitAM: 50, limitPM: 20}
   */
  changeNumPeople(data) {
    // AMの変更
    let limitAMsTemp = { [data.id]: data.limitAM }
    // PMの変更
    let limitPMsTemp = { [data.id]: data.limitPM }

    let params = {
      date_on: this.formRef.current?.getFieldValue('DateChar').format('YYYY/MM/DD'),
      FacilityType: this.formRef.current?.getFieldValue('FacilityType'),
      ManageDivision: this.formRef.current?.getFieldValue('ManageDivision'),
      dataAM: limitAMsTemp,
      dataPM: limitPMsTemp,
    }

    // 人数枠を更新
    SpecificDatePeopleNumSettingAction.updateNumPeople(params)
      .then(res => {
        // 選択されている日付のデータを取得
        this.getNumPeople()
      })
  }

  /**
   * 特定日取消ボタン押下処理
   */
  deleteSpecificNumPeople = () => {
    let params = {
      date_on: this.formRef.current?.getFieldValue('DateChar').format('YYYY/MM/DD'),
      FacilityType: this.formRef.current?.getFieldValue('FacilityType'),
      ManageDivision: this.formRef.current?.getFieldValue('ManageDivision'),
    }
    // 特定日のデータ削除
    SpecificDatePeopleNumSettingAction.deleteSpecificNumPeople(params)
      .then((res) => {
        //  人数枠を再取得
        this.getNumPeople()
      })
  }

  /**
   * 休診日設定ボタン押下処理
   */
  changeClosedDay() {
    let params = {
      date_on: this.formRef.current?.getFieldValue('DateChar').format('YYYY/MM/DD'),
      FacilityType: this.formRef.current?.getFieldValue('FacilityType'),
      ManageDivision: this.formRef.current?.getFieldValue('ManageDivision'),
      kyusinKbn: this.state.kyusinKbn ? 1 : 0 //休診日フラグ
    }
    // 休診日設定
    SpecificDatePeopleNumSettingAction.setClosedDay(params)
      .then((res) => {
        if (res) {
          //  人数枠を再取得
          this.getNumPeople()

          // 親画面でonFinishScreenの設定がある場合
          if (this.props.onFinishScreen) {
            // 休診日の変更を親画面に反映
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * 編集モーダルを開く
   * @param {選択された行の情報} record
   */
  openModal = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1490002_SpecificDatePeopleNumEdit
            dateChar={this.state.dateChar}
            displayName={record.displayName}
            selectRecord={record}
            stsSetSpecificDatesPeopleNum={this.state.stsSetSpecificDatesPeopleNum} // 特定日
            facilityTypeName={this.state.facilityTypeName} // 施設名
            manageDivisionName={this.state.manageDivisionName} // タイプ名
            onFinishScreen={(output) => {
              if (output !== null) {
                // 特定日のAMまたはPMの変更時処理
                this.changeNumPeople(output)
              }
              this.closeModal()
            }}
          />)
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='specific-date-people-num-setting'>
        <Card
          title='特定日人数設定'
          style={{ width: '600px' }}
        >
          <Form
            ref={this.formRef}
            initialValues={{ ManageDivision: 1 }}
          >
            <div className='box_container'>
              <div
                className='box_inner_horizontal'
                style={{ justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex', flexDirection: 'column',
                    paddingLeft: '10px'
                  }}>

                  <Form.Item
                    label='日　付'
                    name='DateChar'
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      format='YYYY/MM/DD'
                      allowClear={true}
                      onChange={() => {
                        // 選択されている日付のデータを取得
                        this.getNumPeople()
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label='施　設'
                    name='FacilityType'
                  >
                    <Select style={{ width: '180px' }}
                      onChange={() => {
                        // 選択されている日付のデータを取得
                        this.getNumPeople()
                      }}
                    >
                      {this.state.facilityTypeList?.map((item) => (
                        <Select.Option
                          key={`facility-type-${item.value}`}
                          value={item.value}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label='タイプ'
                    name='ManageDivision'
                  >
                    <Radio.Group name='ManageDivision'
                      onChange={() => {
                        // 選択されている日付のデータを取得
                        this.getNumPeople()
                      }}>
                      {manageDivisionList.map((item) => (
                        <Radio
                          key={`manage-division-${item.value}`}
                          value={item.value}
                        >
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <Descriptions
                    title=''
                    column={1}
                    bordered
                    size='small'
                  >
                    <Descriptions.Item
                      label='休診日'
                      className={(this.state.dateChar !== null && this.state.kyusinKbn) ? 'kyusin' : ''}
                      contentStyle={{ textAlign: 'center' }}>
                      {(this.state.dateChar !== null && this.state.kyusinKbn) ? //日付設定 && 休診日フラグ
                        <CheckOutlined />
                        :
                        '-'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='特定日'
                      className={(this.state.dateChar !== null && this.state.stsSetSpecificDatesPeopleNum) ? 'specific' : ''}
                      contentStyle={{ textAlign: 'center' }}>
                      {(this.state.dateChar !== null && this.state.stsSetSpecificDatesPeopleNum) ? //日付設定 && 特定日フラグ
                        <CheckOutlined />
                        :
                        '-'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Tooltip placement='bottom' title={'設定されている1日の合計人数'}>
                          {'合　計'}
                        </Tooltip>
                      }
                      contentStyle={{ textAlign: 'right' }}>
                      {this.state.dateChar === null ? ' - ' : `${this.state.totalCount}人`}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                onRow={(record) => ({
                  onDoubleClick: (event) => {
                    // 編集モーダルを開く
                    this.openModal(record)
                  }
                })}
                pagination={false}
                // rowSelection={{}}
                scroll={{ y: resizableTableScroll(130), x: 'max-content' }}
                className={
                  (this.state.dateChar !== null && this.state.stsSetSpecificDatesPeopleNum) ?
                    'specific'
                    : (this.state.dateChar !== null && this.state.kyusinKbn) ?
                      'kyusin'
                      :
                      ''
                }
              >
                <Table.Column
                  title='表示名称'
                  dataIndex='displayName'
                  className='column-size-10'
                />
                <Table.Column
                  title='AM'
                  dataIndex='limitAM'
                  className='column-size-5'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='PM'
                  dataIndex='limitPM'
                  className='column-size-5'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='計'
                  dataIndex='limitTotal'
                  className='column-size-5'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                    )
                  }}
                />
                <Table.Column
                  fixed='right'
                  width={50}
                  align='center'
                  render={(record) => {
                    return (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集モーダルを開く
                          this.openModal(record)
                        }}
                      />
                    )
                  }}
                />
              </Table>
            </div>
            <div className='box_button_bottom_right'>
              <Form.Item style={{ margin: 0 }}>
                <Button type='primary'
                  disabled={!this.state.stsSetSpecificDatesPeopleNum}
                  onClick={() => {
                    Modal.confirm({
                      title: 'この日付の人数設定を初期化しますか',
                      icon: <QuestionCircleOutlined style={{ fontSize: '28px', color: '#1890ff' }} />,
                      okText: 'は　い',
                      cancelText: 'いいえ',
                      onOk: () => { this.deleteSpecificNumPeople() }
                    })
                  }}
                >
                  特定日取消
                </Button>
              </Form.Item>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  type='primary'
                  onClick={() => this.changeClosedDay()}>
                  {this.state.kyusinKbn ?
                    '休診日解除'
                    :
                    '休診日設定'
                  }
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1490001_SpecificDatePeopleNumSetting)
