import React from "react";
import { connect } from "react-redux";
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom';
import PropTypes from "prop-types";
import moment from 'moment';
import { Button, Card, Form, Input, Checkbox, Col, Row, Table, Space, Dropdown, Menu, Tooltip, Select, Popconfirm, message } from "antd";
import {
  MenuOutlined, SearchOutlined, InfoCircleOutlined, WarningOutlined,
  CloseCircleOutlined, MoreOutlined, PlusOutlined, DeleteOutlined,
  InfoOutlined, FileTextOutlined, SaveOutlined, CheckOutlined, SyncOutlined, CopyOutlined
} from "@ant-design/icons";
import { debounce } from 'lodash';
import {
  getSingleJudgementData, getStartDate, getJudgementLevelList, getStartDateList, getStartDateItems,
  synchronizeJugement, getExamNameAction, copyJudgementDataAction
}
  from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions';
import WS0362021_RegisterJudgementDataSingle from "./WS0362021_RegisterJudgementDataSingle.jsx";
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';

import ModalDraggable from "components/Commons/ModalDraggable";
import ModalChange from "components/Commons/ModalChange"
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}
const dateFormat = 'YYYY/MM/DD';

class WS0362031_CopyJudgementData extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);

    // document.title = '検査項目判定値設定Sub';
    //「判定」の正確なスペルは"judgement"だが、テーブルのカラム名が"judgment"になっているため、そのまま"judgment"とする


    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceSingle: [],
      isLoadingPage: false,
      colorText: 208,
      standardDate: moment().format(dateFormat),
      judgementLevelItem: [],
      judgementLevelF: '00',
      judgementLevelT: '00',
      startDateItem: [],
      startDateF: '0000-00-00',
      judgmentCodeF: '',
      judgmentCodeT: '',
      sexF: 1,
      sexT: 1,
      stsJugdementCode: 0,
      stsJugdementLevel: 0,
      stsStartDate: 0,
      stsSex: 0,
    };
  }


  componentDidMount = () => {
    console.log(this.props)
    this.formRef.current.setFieldsValue({
      startDateT: this.state.standardDate,
      sexF: this.state.sexF,
      sexT: this.state.sexT,
    })
    if (this.props.judgment_code) {
      this.formRef.current.setFieldsValue({
        judgmentCodeF: this.props.judgment_code,
        judgmentCodeT: this.props.judgment_code,
        startDateF: this.props.startDateF,
        exam_name_f: this.props.exam_short_name,
        exam_name_t: this.props.exam_short_name,
        judgementLevelF: this.props.judgementLevel,
        judgementLevelT: this.props.judgementLevel,
        sexF: this.props.sex,
        sexT: this.props.sex,
        stsJugdementCode: 1,
        stsJugdementLevel: 1,
        stsStartDate: 1,
        stsSex: 1,
      })
      this.setState({
        judgementLevelItem: this.props.judgementLevelItem,
        judgementLevel: this.props.judgementLevel,
        startDateItem: this.props.startDateItem,
        startDateF: this.props.startDateF,
        stsJugdementCode: 1,
        stsJugdementLevel: 1,
        stsStartDate: 1,
        stsSex: 1,
      })
    } else {
      this.getJudgementLevel()
    }
  }


  /*   getCombobox = (params) => {
      // if (params) {
      let values = {
        judgment_code: params ? params.judgment_code : 0,
        standardDate: this.state.standardDate,
      }
      getComboboxList(values)
        .then(res => {
          if (res) {
            let judgementLevelItems = res.data.level
            let startDateItems = res.data.dates
            console.log(judgementLevelItems)
            this.setState({
              judgementLevelItem: judgementLevelItems,
              startDateItem: startDateItems,
              judgementLevelF: judgementLevelItems[0].condition_1,
              judgementLevelT: judgementLevelItems[0].condition_1,
              startDateF: res.data.date
            })
            this.formRef.current.setFieldsValue({
              judgementLevelF: judgementLevelItems[0].condition_1,
              judgementLevelT: judgementLevelItems[0].condition_1,
              startDateF: res.data.date
            })
          }
        }
        )
      // }
    } */

  getJudgementLevel = () => {
    getJudgementLevelList()
      .then(res => {
        if (res) {
          let judgementLevelItems = res.data
          this.setState({
            judgementLevelItem: judgementLevelItems,
            judgementLevelF: judgementLevelItems[0].condition_1,
            judgementLevelT: judgementLevelItems[0].condition_1,
          })
          this.formRef.current.setFieldsValue({
            judgementLevelF: judgementLevelItems[0].condition_1,
            judgementLevelT: judgementLevelItems[0].condition_1,
          })
        }
      }
      )
  }

  getStartDate = () => {
    let values = {
      judgeMethod: this.props.judgeMethod,
      judgment_level: this.state.judgementLevelF,
      judgment_code: this.formRef.current.getFieldValue("judgmentCodeF"),
      standardDate: this.state.standardDate,
    }
    if (values.judgment_code > 0) {
      getStartDateList(values)
        .then(res => {
          if (res) {
            let startDateItems = res.data.dates
            this.setState({
              startDateItem: startDateItems,
              startDate: res.data.date
            })
            this.formRef.current.setFieldsValue({
              startDate: res.data.date
            })
          }
        }
        )
    }
  }


  /*   handleChangeValue = (chengeObject) => {
      let params = {
        judgementLevel: chengeObject.judgementLevel ? chengeObject.judgementLevel : this.state.judgementLevel,
        startDateF: chengeObject.startDateF ? chengeObject.startDateF : this.state.startDateF
      }
      this.getCombobox()
    } */

  /*   loadSingleJudgementData = (params) => {
      this.setState({ isLoadingPage: true });
      let values = this.formRef.current.getFieldsValue(true)
      let paramsS = {
        ...this.props.record,
        ...values,
        ...params,
        sex: this.props.sex
      }
  
      getSingleJudgementData(paramsS)
        .then(res => {
          if (res) {
            this.setState({
              dataSourceSingle: res.data,
            });
          }
        })
        .catch()
        .finally(() => this.setState({ isLoadingPage: false }))
    } */


  syncJudgement = () => {
    this.setState({ isLoadingPage: true });
    let values = this.formRef.current.getFieldsValue(true);
    let params = {
      ...this.props.record,
      ...values,
      sexF: this.props.sex,
      sexT: this.props.sex === 1 ? 2 : 1,
    }
    synchronizeJugement(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }


  copyJudgementData = () => {
    this.setState({ isLoadingPage: true });
    let values = {
      ...this.formRef.current.getFieldsValue(true),
      judgeMethod: this.props.judgeMethod,
      stsJugdementCode: this.state.stsJugdementCode,
      stsJugdementLevel: this.state.stsJugdementLevel,
      stsStartDate: this.state.stsStartDate,
      stsSex: this.state.stsSex,
    }
    copyJudgementDataAction(values)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }



  onFinish() {
    let values = this.formRef.current.getFieldsValue(true);
    let getId = this.props.record ? this.props.record.id : null;
    let params = {
      ...values,
      id: getId
    };
    this.RegisterBtn(params);
  }


  reviewScreen() {
    let params = {
      judgementLevel: this.state.judgementLevel,
      startDateF: this.state.startDateF
    }
    this.loadSingleJudgementData(params)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }



  getExamName(value, field) {
    this.formRef.current.setFieldsValue({
      [field]: '',
    });

    let params = {
      examCode: value
    }

    getExamNameAction(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          [field]: res.data.exam_short_name
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }



  render() {
    return (
      <div className="use-inspect">
        <Card className="mb-2" title={'複写：' + (this.props.judgeMethod === 0 ? '単品' : '併合')}>
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ standardDate: moment(this.state.standardDate) }}>

            <div className='box_search' style={{ width: 'fit-content' }}>
              <div className='box_search_inner_border' style={{ minWidth: 380 }}>
                <div className='box_inner_horizontal'>

                  <Form.Item name="stsJugdementCode" valuePropName="checked" >
                    <Checkbox
                      onChange={(e) => { this.setState({ stsJugdementCode: e.target.checked ? 1 : 0 }) }
                      }

                    ></Checkbox>
                  </Form.Item>

                  <Form.Item label='検査コード' name='judgmentCodeF' style={{ width: 200 }} {...smGrid}>
                    <Input.Search type='number' min={0} maxLength={8} disabled={!this.state.stsJugdementCode}
                      onSearch={(value, event) => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    judgmentCodeF: output.recordData.test_item_code,
                                    exam_name_f: output.recordData.exam_short_name,
                                  });
                                  this.closeModal();
                                  this.getStartDate();
                                }}
                              />
                            ),
                          },
                        });
                      }}
                      onPressEnter={debounce((e) => {
                        this.getExamName(e.target.value, "exam_name_f");
                        this.getStartDate();
                      }, 150)}
                    />
                  </Form.Item>

                  <Form.Item name='exam_name_f' style={{ width: 120 }}>
                    <Input bordered={false} readOnly />
                  </Form.Item>
                </div>

                <div className='box_inner_horizontal'>
                  <Form.Item name="stsJugdementLevel" valuePropName="checked" >
                    <Checkbox onChange={(e) => { this.setState({ stsJugdementLevel: e.target.checked ? 1 : 0 }) }
                    }
                    ></Checkbox>
                  </Form.Item>
                  <Form.Item name="judgementLevelF" label="判定レベル" style={{ width: 300 }}  {...smGrid} >
                    <Select disabled={!this.state.stsJugdementLevel}
                      onChange={(e) => {
                        this.setState({ judgementLevelF: e });
                        this.getStartDate();
                      }}>
                      {this.state.judgementLevelItem?.map(item => (
                        <Select.Option key={item.condition_1} value={item.condition_1}>{item.item}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className='box_inner_horizontal'>
                  <Form.Item name="stsStartDate" valuePropName="checked" >
                    <Checkbox onChange={(e) => { this.setState({ stsStartDate: e.target.checked ? 1 : 0 }) }
                    }
                    ></Checkbox>
                  </Form.Item>
                  <Form.Item name="startDateF" label="履歴" style={{ width: 300 }}  {...smGrid}>
                    <Select disabled={!this.state.stsStartDate}>
                      {this.state.startDateItem?.map(item => (
                        <Select.Option key={item.start_date} value={item.start_date}>{item.start_date}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className='box_inner_horizontal'>
                  <Form.Item name="stsSex" valuePropName="checked" >
                    <Checkbox onChange={(e) => { this.setState({ stsSex: e.target.checked ? 1 : 0 }) }
                    }
                    ></Checkbox>
                  </Form.Item>
                  <Form.Item name="sexF" label="性別" style={{ width: 300 }}  {...smGrid} >
                    <Select disabled={!this.state.stsSex}>
                      <Select.Option value={1}>男性</Select.Option>
                      <Select.Option value={2}>女性</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className='box_search_inner_border' style={{ minWidth: 360 }}>
                <div className='box_inner_horizontal'>
                  <Form.Item label='検査コード' name='judgmentCodeT' style={{ width: 200 }}{...smGrid}>
                    <Input.Search type='number' min={0} maxLength={8} disabled={!this.state.stsJugdementCode}
                      onSearch={(value, event) => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    judgmentCodeT: output.recordData.test_item_code,
                                    exam_name_t: output.recordData.exam_short_name,
                                  });
                                  this.closeModal();
                                }}
                              />
                            ),
                          },
                        });
                      }}
                      onPressEnter={debounce((e) => {
                        this.getExamName(e.target.value, "exam_name_t")
                      }, 300)}
                    />
                  </Form.Item>

                  <Form.Item name='exam_name_t' style={{ width: 120 }}>
                    <Input bordered={false} readOnly />
                  </Form.Item>
                </div>
                <Form.Item name="judgementLevelT" label="判定レベル" style={{ width: 300 }}  {...smGrid}>
                  <Select disabled={!this.state.stsJugdementLevel}>
                    {this.state.judgementLevelItem?.map(item => (
                      <Select.Option key={item.condition_1} value={item.condition_1}>{item.item}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="startDateT" label='履歴'  {...smGrid}>
                  <VenusDatePickerCustom
                    disabled={!this.state.stsStartDate}
                    id={`event`}
                    tabIndex={`event`}
                    allowClear={false}
                    formRefDatePicker={this.formRef}
                    format={"YYYY/MM/DD"}
                    style={{ width: 120 }}
                  />
                </Form.Item>
                <Form.Item name="sexT" label="性別" style={{ width: 300 }}  {...smGrid} >
                  <Select disabled={!this.state.stsSex}>
                    <Select.Option value={1}>男性</Select.Option>
                    <Select.Option value={2}>女性</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                disabled={this.state.stsJugdementCode == 0 &&
                  this.state.stsJugdementLevel == 0 &&
                  this.state.stsStartDate == 0 &&
                  this.state.stsSex == 0}
                style={{ float: "right" }}
                icon={<CopyOutlined />}
                type="primary"
                onClick={() => {
                  this.copyJudgementData();
                }} >
                <span className='btn_label'>
                  複写
                </span>
              </Button>
            </div>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0362031_CopyJudgementData);