import React from 'react'
import { connect } from 'react-redux'

import { Card, Space, Form, Button, Checkbox } from 'antd'
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { download_file, download_file_binary } from 'helpers/CommonHelpers'
import ResultConfirmAction from 'redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/ResultConfirm.action'
import WS1362003_PrintProcess from 'pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1362003_PrintProcess'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS2655106_ResultConfirm extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '結果確認'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      Expresstion_14: '',
      StsConfirm: '',
      End: '',
      Confirm: '',
      Confirmation: false
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    // 親画面からパラメータ設定
    let param = {
      ErrorPeopleNum: this.props.ErrorPeopleNum,
      CheckErrorPeopleNum: this.props.CheckErrorPeopleNum,
      Visits_UserTotal: this.props.Visits_UserTotal
    }
    ResultConfirmAction.getScreenData(param)
      .then((res) => {
        this.setState({
          Expresstion_14: res.Expresstion_14,
          StsConfirm: res.StsConfirm,
          End: res.End,
          Confirm: res.Confirm,
          Confirmation: res.StsConfirm ? true : false,
        })
        this.formRef.current?.setFieldsValue({ Confirmation: res.StsConfirm ? true : false })
      })
  }

  LogDisplay() {
    let param = {
      Goutput: this.props.Goutput
    }
    ResultConfirmAction.LogDisplay(param)
      .then((res) => {
        this.downloadLog(res)
      })
  }

  downloadLog(filePaths) {
    // 引数として受け取ったファイルパスの数APIを呼ぶ
    for (let i = 0; i < filePaths.length; i++) {
      let filePath = filePaths[i].filePath
      let param = {
        filePath: filePath
      }
      ResultConfirmAction.downloadLog(param)
        .then((res) => {
          console.log(res)
          download_file(res)
        })
    }
  }

  xml() {
    let param = {
      RootFolderName: this.props.RootFolderName,
      Goutput: this.props.Goutput,
      fileName: this.props.fileName
    }
    ResultConfirmAction.xml(param)
      .then((res) => {
        this.downloadXml(res.outputPath)
      })
  }

  downloadXml(filePath) {
    let param = {
      filePath: filePath
    }
    ResultConfirmAction.downloadXml(param)
      .then((res) => {
        // XMLファイルダウンロード
        download_file_binary(res)
      })
  }

  updateCheckbox(event) {
    let checked = event.target.checked
    this.setState({
      Confirmation: checked,
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='result-confirm'>
        <Card title='結果確認'>
          <Form ref={this.formRef}>
            <Space style={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.StsConfirm ?
                (<CloseCircleOutlined style={{ color: '#f00', fontSize: '40px' }} />)
                :
                (<ExclamationCircleOutlined style={{ color: '#00f', fontSize: '40px' }} />)
              }
              {this.state.Expresstion_14}
            </Space>

            <Space
              className='box_border_bottom'
            >
              {this.state.StsConfirm ?
                <Button
                  type='primary'
                  onClick={() => { this.LogDisplay() }}
                >
                  ログ
                </Button>
                :
                null
              }
              <Button type='primary ' onClick={() => {
                this.setState({
                  childModal: {
                    visible: true,
                    width: '90%',
                    component: (
                      <WS1362003_PrintProcess
                        dataList={this.props.dataList}
                        onFinishScreen={output => {
                          this.closeModal()
                        }}
                      />
                    )
                  }
                })
              }}>
                対象者
              </Button>
              <Button
                type='primary'
                onClick={() => { this.xml() }}
              >
                XML
              </Button>
            </Space>

            <Space
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                padding: '0 10px'
              }}
            >
              <Form.Item
                name='Confirmation'
                valuePropName='checked'
                style={{ marginBottom: '0' }}
              >
                <Checkbox onChange={(event) => { this.updateCheckbox(event) }} />
              </Form.Item>
              <Space
                style={{ display: 'inline-block' }}
              >
                内容を確認しました
              </Space>
              <Button
                type='primary'
                disabled={!this.state.Confirmation}
                onClick={() => { this.props.onFinishScreen() }}
              >
                確認
              </Button>
            </Space>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              }
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS2655106_ResultConfirm)
