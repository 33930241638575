import axios from 'configs/axios'
const API_LIST = {
  index: '/api/normal-value-setting-maintain/query-site-info',
  getPosition: '/api/normal-value-setting-maintain/query-site-info/get-position',
}

const QuerySiteInfoService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
  async getPosition(params) {
    return axios.get(API_LIST.getPosition, { params })
  },
}

export default QuerySiteInfoService
