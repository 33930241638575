import UserService from '../../services/UserService'
import MainMenuService from 'services/MN_Basis/MainMenu/MainMenu.Service'
import { history } from 'constants/BrowserHistory'
import { UserActionTypes } from './user.types'
import { AppstoreOutlined, } from '@ant-design/icons'
import { danger } from '../alert/alert.actions'
import MenuCustomizationAction from 'redux/MN_Basis/MenuCustomization/MenuCustomization.action'
import moment from 'moment'

const dispatchDangerError = (dispatch, error) => {
  let msg
  const res = error.response
  if (res) {
    const resData = res.data
    if (resData) {
      const resErrors = resData.errors
      if (resErrors) {
        for (let i in resErrors) {
          for (let j in resErrors[i]) {
            msg = resErrors[i][j]
          }
        }
      } else {
        msg = resData.message
      }
    } else {
      msg = '処理が失敗しました'
    }
  }
  console.error('user.actions/dispatchDangerError', msg)
  dispatch(danger(msg || 'エラーが発生しました (no message)'))
}

export const loginUserInfo = (userInfo) => {
  return (dispatch) => {
    return UserService.login(userInfo)
      .then((res) => {
        // console.info('login successful', userInfo)
        let dataLogin = {
          loggedIn: true,
          user: res.data.user,
          hospitalCode: userInfo.hospital
        }
        dispatch({
          type: UserActionTypes.SET_USER,
          payload: dataLogin
        })

        let cookie = document.cookie.match(new RegExp('redirect' + '\=([^\;]*)\;*'))[1]
        let path = decodeURIComponent(cookie)
        document.cookie = "redirect=; path=/"

        const validDays = Number(process.env.REACT_APP_ACCOUNT_VALID_DAYS ?? 0)
        // 有効期限のチェック
        const valid_f = res.data.user.valid_f //有効期限開始日
        const valid_t = res.data.user.valid_t //有効期限終了日
        if (validDays > 0) {
          const fromDate = valid_f ? new Date(valid_f) : new Date()
          const toDate = valid_t ? new Date(valid_t) : new Date()
          const today = new Date() //本日

          // 有効期限が切れている場合
          if (today < fromDate || toDate < today) {
            // パスワード更新画面に遷移
            path = '/password-update'
          }
        }

        console.log(path);
        history.push(path)
      })
      .catch((error) => {
        console.warn('login error', userInfo)
        dispatchDangerError(dispatch, error)
      })
  }
}

export const logoutUserInfo = (hospitalCode) => {
  return (dispatch) => {
    return UserService.logout()
      .then(() => {
        dispatch({
          type: UserActionTypes.LOG_OUT_USER
        })
        history.push('/login/' + hospitalCode)
      })
      .catch((error) => {
        dispatchDangerError(dispatch, error)
      })
  }
}

export const createUserAction = (user) => {
  return (dispatch) => {
    return UserService.createUserService(user)
      .then((res) => {
        if (res.status === 200) {
          history.push('/user-info/user-register')
        }
      })
      .catch((error) => {
        dispatchDangerError(dispatch, error)
      })
  }
}

export const setHospital = (hospitalCode) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.SET_HOSPITAL_CODE,
      payload: hospitalCode
    })
  }
}

export const modifyUserpasswordAction = (user) => {
  return (dispatch) => {
    return UserService.modifyUserPasswordService(user)
  }
}

export const getUserProfileAction = (userId) => {
  return (dispatch) => {
    UserService.getUserProfileService(userId)
      .then((res) => {
        dispatch({
          type: UserActionTypes.GET_USER_PROFILE,
          payload: res.data
        })
      })
      .catch((error) => {
        dispatch(danger(error.response.data.message))
      })
  }
}

export const updateUserProfileAction = (userId, user) => {
  return (dispatch) => {
    UserService.updateUserProfileService(userId, user)
      .then((res) => {
        if (res.status === 200) {
          history.push('/user-info/user-register')
        }
      })
      .catch((error) => {
        dispatch(danger(error.response.data.message))
      })
  }
}

export const setSubMenus = (objMenu) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.SET_SUB_MENUS,
      payload: objMenu
    })
  }
}
// Get a screen ID for testing
export const setDisplayComponent = (componentName) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.SET_DISPLAY_COMPONENT,
      component: componentName
    })
  }
}

export const getMenuList = () => {
  return (dispatch) => {
    return MainMenuService.getMenuList()
      .then((res) => {
        let customList = []
        let list = []
        let listIndex = 0
        let obj1
        let obj2
        let obj3
        let businessList = res.data[0]
        let subMenuList = res.data[1]
        let myMenuList = res.data[2]
        let myMenu
        let myMenuObj
        let myMenuSub = []

        // マイメニューのサブデータを取る
        for (let k = 0; k < myMenuList.length; k++) {
          myMenuObj = {
            title: myMenuList[k].name,
            path: myMenuList[k].path_name,
            display_order: myMenuList[k].display_order,
            published_name: myMenuList[k].published_name
          }
          myMenuSub.push(myMenuObj)
        }
        // マイメニューを反映させる
        myMenu = {
          title: 'マイメニュー',
          submenu: myMenuSub,
          icon: '',
        }


        // 1行辺りのMn0900BusinessNameを取る
        for (let i = 0; i < businessList.length; i++) {
          list = []
          listIndex = 0
          // 1行辺りのMn0501GeneralMenuを取る
          for (let j = 0; j < subMenuList.length; j++) {
            if (businessList[i].business_code === subMenuList[j].business_code) {
              // 名称が空のnameの判定
              if (subMenuList[j].exe === '') {
                obj2 = {
                  title: subMenuList[j].name,
                  submenu: []
                }
                list.push(obj2)
                listIndex++
                // 親のsubmenuがないとき
              } else if (list.length === 0 || list[listIndex - 1].submenu === undefined) {
                obj2 = {
                  title: subMenuList[j].name,
                  path: subMenuList[j].path_name,
                  business_code: subMenuList[j].business_code,
                  display_serial_number: subMenuList[j].DisplaySerialNum,
                  published_name: subMenuList[j].published_name,
                  name: subMenuList[j].name,
                  // submenu: []
                }
                list.push(obj2)
                listIndex++
              } else {
                // 親のsubmenuがあるとき
                obj3 = {
                  title: subMenuList[j].name,
                  path: subMenuList[j].path_name,
                  business_code: subMenuList[j].business_code,
                  display_serial_number: subMenuList[j].DisplaySerialNum,
                  published_name: subMenuList[j].published_name,
                  name: subMenuList[j].name,
                }
                if (list.length > 0) {
                  list[listIndex - 1].submenu.push(obj3)
                }
              }
            }
          }
          obj1 = {
            title: businessList[i].business_name,
            icon: <AppstoreOutlined />,
            submenu: list
          }
          customList.push(obj1)
        }
        // myMenuList.unshift(myMenuSub)
        //マイメニューは別メニューになったため不要
        // customList.unshift(myMenu)
        dispatch({
          type: UserActionTypes.GET_MENU_LIST,
          data: customList
        })
      })
      .catch((error) => {
        // dispatch(danger(error.response.data.message))
      })
  }
}

export const getMyMenuList = (param) => {
  return (dispatch) => {
    MenuCustomizationAction.index(param)
      .then((res) => {
        dispatch({
          type: UserActionTypes.GET_MY_MENU_LIST,
          data: res
        })
      })
  }
}
