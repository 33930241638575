import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/main/emaillnquiry/getscreendata",
};
const EmaillnquiryService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
};

export default EmaillnquiryService;