import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Card, Form, Input, Button, Table, Row, Col, Space, Modal, Tooltip, Dropdown, Menu, Popconfirm, Descriptions, message } from 'antd'
import { DeleteOutlined, MenuOutlined, EditOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons'

import { SetIncludesQueryAction } from 'redux/ReservationBusiness/PersonalReserveProcess/SetIncludesQuery.action'
import SetInfoChangeSubAction from 'redux/basicInfo/SetInfoMaintain/SetInfoChangeSub.action'

import WS0333011_MoreDetail from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333011_MoreDetail.jsx'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0333100_SetIncludesQueryInquiryMock from 'pages/Mock/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333100_SetIncludesQueryInquiryMock.jsx'
import WS0333300_SetIncludesQueryInformationEdit from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333300_SetIncludesQueryInformationEdit'
import WS0333400_SetIncludesQueryInquiryAdd from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333400_SetIncludesQueryInquiryAdd'
import Color from 'constants/Color'
import { unbind } from 'wanakana'

class WS0333001_SetIncludesQueryMock extends React.Component {

  static propTypes = {
    Li_StartDate: PropTypes.any,
    Li_SetCode: PropTypes.any,
    Li_CourseCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = 'セット内容照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
      isLoading: true,
      dataExamList: [],
      objMoreDetail: {
        Li_StartDate: '',
        Li_SetCode: ''
      },
      initParams: {
        Li_StartDate: '',
        Li_SetCode: '',
        Li_CourseCode: '',
        Search: ''
      },
      textCondition: '条件なし',
      colorCondition: 156,
      title: '',
      tableDataPaginate: {},
      saveData: [],
      onRowData: []
    }
  }

  componentDidMount = () => {
    console.log(this.props)
    if (this.props) {
      const { Li_StartDate, Li_SetCode, Li_CourseCode } = this.props
      this.callAPILoadData()
      this.setState({
        ...this.state,
        objMoreDetail: {
          Li_StartDate: Li_StartDate,
          Li_SetCode: Li_SetCode
        }
      })
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { Li_StartDate, Li_SetCode, Li_CourseCode } = this.props
      this.callAPILoadData()
      this.setState({
        ...this.state,
        objMoreDetail: {
          Li_StartDate: Li_StartDate,
          Li_SetCode: Li_SetCode
        }
      })
    }
  }

  callAPILoadData = () => {
    this.setState({
      isLoading: true,
      dataExamList: []
    })
    const params = {
      Li_StartDate: this.props.Li_StartDate || '',
      Li_SetCode: this.props.Li_SetCode || '',
      Li_CourseCode: this.props.Li_CourseCode || '',
      Search: '',
    }
    SetIncludesQueryAction.getSetIncludesQuery(params)
      .then((res) => {
        if (res) {
          this.setState({
            dataExamList: res.DataTable,
            onRowData: res.result
          })
        }
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  handleSearch = (e) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        Search: e.target.value,
      }
    }, () => this.callAPILoadData(this.state.initParams))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      changeValueInfoChangeSub: false
    })
  }

  onSave(output) {
    let saveData = {
      set_code: this.props.onRow.set_code,
      exam_code: output.recordData.test_item_code,
    }
    let param = {
      id: undefined,
      set_code: this.props.onRow.set_code,
      exam_code: output.recordData.test_item_code,
      saveData: saveData
    }
    SetIncludesQueryAction.onSave(param)
      .then((res) => {
        this.callAPILoadData()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onDelete(record) {
    let param = {
      id: record.id
    }
    SetIncludesQueryAction.onDelete(param)
      .then((res) => {
        this.callAPILoadData()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  deleteTableData() {
    let params = {
      id: this.props.onRow.id,
      set_code: this.props.onRow.set_code,
      start_date_on: this.props.onRow.start_date_on,
    }
    SetInfoChangeSubAction.deleteTable(params)
      .then((res) => {
        this.props.closeModal()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='set-includes-query'>
        <Card title={'セット照会（モック）'} >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top_modal'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 550,
                            component: (
                              <WS0333100_SetIncludesQueryInquiryMock
                                onRow={this.state.onRowData}
                                onFinishScreen={(output) => {
                                  this.props.onFinishScreen()
                                }}
                              />
                            ),
                          },
                        })

                      }
                    >
                      明細
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />} />
            </Dropdown>
          </Tooltip>
          <Form ref={this.formRef}>
            <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
              <div className='box_border' >
                <Descriptions
                  title=''
                  column={2}
                  bordered
                  size='small'
                >
                  <Descriptions.Item
                    label='コード'
                    contentStyle={{ textAlign: 'center' }}>
                    {this.state.onRowData.set_code}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label='名　称'
                    contentStyle={{ textAlign: 'center' }}>
                    {this.state.onRowData.set_name}
                  </Descriptions.Item>
                </Descriptions>
                <div className='box_button_bottom_right'>
                  <Button
                    type='primary'
                    icon={<EditOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 550,
                          component: (
                            <WS0333300_SetIncludesQueryInformationEdit
                              data={this.state.onRowData}
                              newFlag={false}
                              onFinishScreen={output => {
                                this.closeModal()
                                this.callAPILoadData()
                                this.props.onFinishScreen()
                              }}
                            />
                          )
                        }
                      })
                    }
                  >
                    <span className='btn_label'>
                      編集
                    </span>
                  </Button>
                </div>
              </div>
              <div className='box_border' >
                <Table
                  size='small'
                  bordered
                  dataSource={this.state.dataExamList}
                  pagination={false}
                  scroll={{ y: 350 }}
                  rowKey={record => record.id}
                >
                  <Table.Column title={<div style={{ textAlign: 'center' }}>コード</div>} dataIndex='test_item_code' align='right' width={120} />
                  <Table.Column title={<div style={{ textAlign: 'center' }}>名称</div>} dataIndex='exam_name' />
                  <Table.Column title={<div style={{ textAlign: 'center' }}>タイプ</div>} dataIndex='Expresstion_2' />
                </Table>
                <div className='box_button_bottom_right'>
                  <Button
                    type='primary'
                    icon={<EditOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 1050,
                          component: (
                            <WS0333400_SetIncludesQueryInquiryAdd
                              onRow={this.state.onRowData}
                              // ホワイトアウトするエラー修正前
                              //onRow={this.props.onRow}
                              onFinishScreen={(output) => {
                                this.closeModal()
                                this.callAPILoadData()
                                this.props.onFinishScreen()
                              }}
                            />
                          )
                        }
                      })
                    }
                  >
                    <span className='btn_label'>
                      編集
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            {!this.props.batch ?
              <Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                cancelButtonProps={{ style: { height: '32px' } }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                onConfirm={() => this.deleteTableData()}
                okText='削除'
                cancelText='キャンセル'
              >
                <Button
                  danger
                  type='primary'
                  icon={<DeleteOutlined />}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
              </Popconfirm> : null
            }
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0333001_SetIncludesQueryMock)
