import React from "react";
import { connect } from "react-redux";

import {
  Card,
  Col,
  Table,
  Row,
  Form,
  message,
  Button,
  Modal,
  Space,
  Menu,
  Dropdown,
  Input,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import SpreadsheetInputSettingService from "services/InputBusiness/SpreadInput/SpreadsheetInputSettingService";
import WS0456005_BatchCapture from "./WS0456005_BatchCapture";
import WS0456007_Copy from "./WS0456007_Copy";
import WS0456009_Change from "./WS0456009_Change";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle";
import ModalDraggable from "components/Commons/ModalDraggable";

class WS0456100_SpreadsheetInputSettingAdd extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "スプレットシート入力設定";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTableParent: false,
      isLoadingTableChild: false,
      onBlurInput: true,
      count: 0,
      selectedRowRecordListParent: null,
      oldTableData: null,
    };
  }

  sheetSave() {
    let getFieldValue = this.formRef.current.getFieldValue()
    console.log(getFieldValue);
    let params = {
      Seqno: getFieldValue.Seqno,
      item: getFieldValue.item,
    }
    SpreadsheetInputSettingService.createParentSpreadsheetInputSettingService(params)
      .then((res) => {
        this.props.onFinishScreen();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <Card title="シート追加">
            <div className="box_inner_horizontal" style={{ gap: 50 }}>
              <Form.Item name='Seqno' label='コード'>
                <Input className="input-size-number-5" />
              </Form.Item>
              <Form.Item name='item' label='シート名'>
                <Input className="input-size-20" />
              </Form.Item>
            </div>
            <div className="box_button_bottom_right">
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.sheetSave();
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0456100_SpreadsheetInputSettingAdd);