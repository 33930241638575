import React from "react";

import { Card, Form, Button, Upload, message, Menu, Tooltip, Dropdown } from "antd";
import { MenuOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import WS1053004_ConsultSelect from 'pages/KS_CooperationRelated/V4CP0011000_InspectAcquisitionUpdateCustomizedVersion/WS1053004_ConsultSelect.jsx'
import WS0262001_LogDisplay from 'pages/ZZ_Others/V4DS0230000_LogDisplay/WS0262001_LogDisplay.jsx'
import WS1050001_AcquireSettingSub from 'pages/KS_CooperationRelated/V4CP0011000_InspectAcquisitionUpdateCustomizedVersion/WS1050001_AcquireSettingSub.jsx';
import WS0810025_PrintSub from 'pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0810025_PrintSub.jsx';
import InspectAcquisitionUpdateCustomizedVersionAction from 'redux/CooperationRelated/InspectAcquisitionUpdateCustomizedVersion/InspectAcquisitionUpdateCustomizedVersion.action'
import ModalDraggable from "components/Commons/ModalDraggable";
import { ThemeProvider } from "react-bootstrap";

class WS1046001_InspectAcquisitionUpdateCustomizedVersion extends React.Component {
  formRef = React.createRef();
  name = '取込対象のテキストファイルをアップロードする（複数選択可）'
  constructor(props) {
    super(props);

    // document.title = '検査取込更新[ｶｽﾀﾏｲｽﾞ版]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      list: [],
      fileList: [],
      FilterList: '',
      fileName: '',
      Upload: {},
      dataSource: [{ id: 1, CaptureFolderName: '', color: '' }],
      fileUpload: [],
      defaultF12: true,
      disableUpload: true,
      count: 1001,
      index: 0,
    };
  }

  /**
  * 重複要素を取得
  * @param {*} param0
  * @returns
  */
  getDuplicateValues = ([...array]) => {
    return array.filter((value, index, self) => self.indexOf(value) === index && self.lastIndexOf(value) !== index);
  }

  /**
   * 取込処理
   * @param {*} values
   */
  onFinish = (values) => {
    console.log('onFinish');
    let arrTemp = this.state.list
    let duplicationJ = [];
    let duplication = [];

    //this.state.list配列の内に、同じパスがある場合はエラーを出す
    for (var r = 0; r < arrTemp.length; r++) {
      duplication.push(this.state.list[r].originFileObj.webkitRelativePath);
    }

    //配列化したパスリスト内に重複しているファイルがあれば取り出し
    duplicationJ = this.getDuplicateValues(duplication);
    if (duplicationJ.length > 0) {
      message.error('ファイルが重複しています');
      return;
    }

    //ファイルの情報を配列化し、PHPに送るために必要な処理
    let formData = new FormData();
    for (var i = 0; i < arrTemp.length; i++) {
      formData.append('files[]', arrTemp[i].originFileObj);
    }

    InspectAcquisitionUpdateCustomizedVersionAction.uploadDirectory(formData)
      .then(res => {
        console.log(res);
        if (res.SuccessFlag) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => { });
  }

  /**
   * 印刷処理
   */
  printF11() {
    InspectAcquisitionUpdateCustomizedVersionAction.printF11()
      .then(res => {
        this.showPrintSub()
      }).catch(err => message.error(err.response.data.message || "エラーが発生しました"))
  }

  /**
   * 印刷処理・画面表示
   */
  showPrintSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS0810025_PrintSub
            onFinishScreen={(ouput) => {

              this.closeModal()
            }} />
      },
    });
  }

  /**
   * ログ処理
   */
  showWS0262001_LogDisplay() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0262001_LogDisplay />
        ),
      },
    })
  }

  /**
   * パターン設定処理
   */
  showWS1050001_AcquireSettingSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS1050001_AcquireSettingSub
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="inspect-acquisition-update-customized-version" style={{ width: 500 }} >
        <Card title="検査結果ファイル取込">
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      onClick={() => { this.showWS0262001_LogDisplay() }}
                    >
                      ログ
                    </Menu.Item>

                    <Menu.Item
                      key='2'
                      onClick={() => { this.printF11() }}
                    >
                      印刷
                    </Menu.Item>

                    <Menu.Item
                      key='4'
                      onClick={() => { this.showWS1050001_AcquireSettingSub() }}
                    >
                      パターン設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Upload
              //headersを記入しないと、Cookiesエラーになる
              headers={{
                'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
              }}
              beforeUpload={() => {
                this.setState({ list: [] })
                return false
              }}
              directory //フォルダー単位でアップロード出来る

              onChange={(info) => {
                this.setState({ list: info.fileList })

                //実行ボタンの活性化・非活性化
                if (this.state.list.length >= 0) {
                  this.setState({ defaultF12: false })
                }
              }}
            >
              <Button icon={<UploadOutlined />}>アップロードファイル選択</Button>
            </Upload>

            <Form.Item
              style={{ float: 'right' }}
              className='mt-3'>
              <Button
                disabled={this.state.list.length === 0}
                type="primary"
                htmlType="submit"
                icon={<DownloadOutlined />}
              >
                <span className='btn_label'>
                  取込
                </span>
              </Button>
            </Form.Item>

          </Form>
        </Card>

        < ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS1046001_InspectAcquisitionUpdateCustomizedVersion;