import React from 'react'

import { connect } from 'react-redux'
import { Card, Form, Table } from 'antd'
import ModalDraggable from "components/Commons/ModalDraggable"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import MunicipalMutualAidAssociationMaintainAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/MunicipalMutualAidAssociationMaintain.actions.js'


class WS1789003_MutualAidMasterReadOnly extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '共済マスタ'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    let params = {
      siteFindingsCommon: this.props.siteFindingsCommon
    }

    MunicipalMutualAidAssociationMaintainAction.getMutualAidMasterReadOnly(params)
      .then((res) => {
        this.setState({
          dataSource: res,
        })
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className='mutual-aid-master'>
        <Card title='共済マスタ'>

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(250) }}
              onRow={(record, index) => {
                return {
                  onDoubleClick: (event) => {
                    // 部位コードと部位名称
                    const output = {
                      findings_item_code: record.findng_items_code,
                      mutual_aid_code: record.content_code,
                      content: record.content
                    }
                    // 親画面に反映
                    this.props.onFinishScreen(output)
                  }
                }
              }}
            >
              <Table.Column
                title='所見項目'
                dataIndex='findng_items_code'
                className='column-size-10'
              />

              <Table.Column
                title='共済コード'
                dataIndex='content_code'
                className='column-size-10'
              />

              <Table.Column
                title='内容'
                dataIndex='content'
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1789003_MutualAidMasterReadOnly)