import { message } from "antd";
import ProgressInfoMaintainService from "services/SystemMaintenance/ProgressInfoMaintain/ProgressInfoMaintainService";

const ProgressInfoMaintainAction = {
  getScreenDataProgressInfoMaintain() {
    return ProgressInfoMaintainService.getScreenDataProgressInfoMaintainService()
  },

  getProgressListData(params) {
    return ProgressInfoMaintainService.getProgressListDataService(params)
  },

  addUpdateNodeData(params) {
    return ProgressInfoMaintainService.addUpdateNodeDataService(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  deleteNodeData(params) {
    return ProgressInfoMaintainService.deleteNodeDataService(params)
      .then((res) => {
        message.success(res.data.message);
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  getDetail(params) {
    return ProgressInfoMaintainService.getDetail(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}

export default ProgressInfoMaintainAction;