import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal, message } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import InspectGroupMaintainAction from 'redux/InspectionMaintenance/InspectGroupMaintain/InspectGroupMaintain.actions.js'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: 70 } }
}

class InspectGroupDetailEdit extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    // document.title = '検査グループマスタ保守(詳細) 新規・編集'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        centered: false,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の時のみ
      // 親画面のrecordをコピー
      const data = {
        ...this.props.record,
      }
      // Form設定
      this.formRef.current?.setFieldsValue(data)

    } else {
      // 新規の時だけ連番設定
      this.formRef.current?.setFieldsValue({
        seq: this.props.seq
      })
    }
    this.forceUpdate()
  }

  /**
 * 検査項目検索・照会(単品)　モーダル
 */
  showWS0271001_InspectItemSearchQuerySingle = (formName) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        centered: true,
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            onFinishScreen={async (event) => {
              // 取得した検査コードと検査名称をFormに反映
              this.formRef.current?.setFieldsValue({
                [formName]: event.recordData.test_item_code,
                [formName + '_name']: event.recordData.exam_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * コードと名称をリセット
   * @param {*} formName
   */
  resetForm = (formName) => {
    this.formRef.current?.setFieldsValue({
      [formName]: '',
      [formName + '_name']: ''
    })
    this.forceUpdate()
  }

  /**
   *  データ保存
   */
  save = () => {
    const values = this.formRef.current?.getFieldsValue()
    if (!values.seq) {
      return message.error('連番を入力してください')
    }

    const params = {
      id: this.props.record?.id ?? null,
      inspect_group_code: this.props.inspectGroupCode ?? '',
      seq: values.seq,
      inspect_code_site_1: values.inspect_code_site_1,
      inspect_code_site_2: values.inspect_code_site_2,
      inspect_code_findings_1: values.inspect_code_findings_1,
      inspect_code_findings_2: values.inspect_code_findings_2,
      inspect_code_findings_3: values.inspect_code_findings_3,
    }
    InspectGroupMaintainAction.saveInspectGroupDetail(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            // 親画面に戻る
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
   * データ削除
   */
  delete = () => {
    const params = {
      id: this.props.record?.id ?? null,
    }
    InspectGroupMaintainAction.deleteInspectGroupDetail(params)
      .then(res => {
        if (res) {
          if (this.props.onFinishScreen) {
            // 親画面に戻る
            this.props.onFinishScreen()
          }
        }
      })
  }

  /**
 * モーダルを閉じる
 */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div className='Electronic-Medical-Records-Linkage-Frame-Set-Edit'>
        <Card
          title={`検査グループマスタ保守 詳細 [${this.props.inspectGroupCode ?? ''}：${this.props.inspectGroupName ?? ''}] [${(this.props?.record ? '変更' : '新規')}]`}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='連番'
                name='seq'
                readOnly
                {...labelCol}
              >
                <Input
                  className='input-size-number-5'
                />
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='部位1'
                  name='inspect_code_site_1'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('inspect_code_site_1')
                      } else {
                        this.showWS0271001_InspectItemSearchQuerySingle('inspect_code_site_1')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('inspect_code_site_1_name')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='部位2'
                  name='inspect_code_site_2'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('inspect_code_site_2')
                      } else {
                        this.showWS0271001_InspectItemSearchQuerySingle('inspect_code_site_2')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('inspect_code_site_2_name')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='所見1'
                  name='inspect_code_findings_1'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('inspect_code_findings_1')
                      } else {
                        this.showWS0271001_InspectItemSearchQuerySingle('inspect_code_findings_1')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('inspect_code_findings_1_name')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='所見2'
                  name='inspect_code_findings_2'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('inspect_code_findings_2')
                      } else {
                        this.showWS0271001_InspectItemSearchQuerySingle('inspect_code_findings_2')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('inspect_code_findings_2_name')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='接尾語'
                  name='inspect_code_findings_3'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('inspect_code_findings_3')
                      } else {
                        this.showWS0271001_InspectItemSearchQuerySingle('inspect_code_findings_3')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('inspect_code_findings_3_name')}</div>
              </div>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.record) ? '' : 'none' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    okButtonProps: {
                      danger: true,
                      icon: <DeleteOutlined />
                    },
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    cancelText: 'キャンセル',
                    onOk: () => {
                      this.delete()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectGroupDetailEdit)
