import { message } from 'antd'
import CorporateHearingSheetService from 'services/Others/CorporateHearingSheet/CorporateHearingSheetService'

const CorporateHearingSheetAction = {
  getHearingList(data) {
    return CorporateHearingSheetService.getHearingList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  onRegistration(data) {
    return CorporateHearingSheetService.onRegistration(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  print(data) {
    return CorporateHearingSheetService.print(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  shippingLabel(data) {
    return CorporateHearingSheetService.shippingLabel(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  downloadExcelFile(data) {
    return CorporateHearingSheetService.downloadExcelFile(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}

export default CorporateHearingSheetAction
