import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Form, Button } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import NotesInputAction from 'redux/InputBusiness/NotInputCheckCategory/NotesInput.action'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2637054_NotesInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637054_NotesInput.jsx'

class WS2637053_NotesInquiry extends React.Component {

  constructor(props) {
    super(props)

    // document.title = '注意事項入力 照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 注意事項一覧を取得
    this.getNotesList()
  }

  /**
   * 注意事項一覧を取得
   */
  getNotesList = () => {
    const params = {
      Li_ReserveNum: this.checkData(this.props.Li_ReserveNum, 'text'),
      Li_CategoryCode: this.checkData(this.props.Li_CategoryCode, 'number'),
    }
    NotesInputAction.getNotesList(params)
      .then((res) => {
        this.setState({ dataSource: res })
      })
  }

  /**
   * 新規追加、編集 モーダルを開く
   * @param {*} record
   * @param {*} newFlag
   */
  editModal = (record, newFlag) => {
    // モーダル設定
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS2637054_NotesInput
            newFlag={newFlag}
            record={record}
            Li_ReserveNum={this.props.Li_ReserveNum}
            Li_CategoryCode={this.props.Li_CategoryCode}
            onFinishScreen={() => {
              // 注意事項の再取得
              this.getNotesList()
              // モーダル閉じる
              this.closeModal()
            }}
          />
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  checkData = (value, type) => {
    const val = type === 'number' ? 0 : ''
    return !this.isEmpty(value) ? value : val
  }

  render() {
    return (
      <div className='notes-input'>
        <Card title='注意事項入力'>
          <div className='box_container'>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={false}
              rowKey={(record) => record.id}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    // 編集 モーダル
                    this.editModal(record, false)
                  }
                }
              }}
            >
              <Table.Column
                title='連番'
                dataIndex='serial_number'
                className='column-size-5'
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='コード'
                dataIndex='category_determining_code'
                className='column-size-5'
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='注意事項'
                dataIndex='notes_comment'
              />

              <Table.Column
                fixed='right'
                dataIndex=''
                align='center'
                width={30}
                title={
                  <Button
                    type='primary'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      // 新規追加 モーダル
                      this.editModal(null, true)
                    }}
                  />
                }
                render={(value, record) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // 編集 モーダル
                      this.editModal(record, false)
                    }}
                  />
                )}
              />
            </Table>

          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637053_NotesInquiry)
