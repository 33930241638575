import React from 'react'
import { Card, Table, Checkbox, Form, Button, Modal } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

class WS0343103_DestinationInfo extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '個人情報保守 - 送付先'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      dataSource: [],
      disabledSaveBtn: true
    }
  }
  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      dataSource: this.props.personal.personal_individual_addresses
    })
    this.formRef.current?.setFieldsValue({
      tableData: this.props.personal.personal_individual_addresses,
    })
  }

  /**
   * チェックボックス変更処理
   * @param {*} changedValues
   */
  onValuesChange = (changedValues) => {
    let arrTemp = [...this.state.dataSource]
    let keyName, value
    const changeData = [...changedValues.tableData]

    changeData.forEach((item, index) => {
      // 変更された内容だけ取得
      if (item) {
        let obj = {
          ...arrTemp[index],
          destination_office_1: 0,
          destination_office_2: 0,
          destination_personal_1: 0,
          destination_personal_2: 0,
          destination_personal_3: 0,
        }

        // キー名取得
        keyName = Object.keys(item)[0]
        // 値取得 1:0で渡す
        value = (item[keyName]) ? 1 : 0
        // 内容を反映
        obj = {
          ...obj,
          [keyName]: value,
          changeFlag: 1 //変更フラグ
        }
        arrTemp[index] = obj
      }
    })

    this.formRef.current?.setFieldsValue({
      tableData: arrTemp,
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false // 登録ボタン 活性
    })
  }

  /**
   * 登録ボタン処理
   * @param {*} changedValues
   */
  onFinish = () => {
    Modal.confirm({
      content: '上書き登録します。よろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        const data = {
          personal_individual_addresses: [...this.state.dataSource]
        }
        // 親画面にてAPIを呼ぶ
        this.props.onUpdate(data)
        // 登録ボタン 非活性
        this.setState({
          disabledSaveBtn: true
        })
      }
    })
  }

  render() {
    return (
      <div className='destination-info'>
        <Card title='送付先'>
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              pagination={false}
              bordered
            >
              <Table.Column
                title='書類'
                dataIndex='document_title'
              />

              <Table.Column
                title='事業所'
                dataIndex='destination_office_1'
                align='center'
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'destination_office_1']} valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                )} />

              <Table.Column
                title='本店'
                dataIndex='destination_office_2'
                align='center'
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'destination_office_2']} valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                )} />

              <Table.Column
                title='個人1'
                dataIndex='destination_personal_1'
                align='center'
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'destination_personal_1']} valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                )} />

              <Table.Column
                title='個人2'
                dataIndex='destination_personal_2'
                align='center'
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'destination_personal_2']} valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                )} />

              <Table.Column
                title='個人3'
                dataIndex='destination_personal_3'
                align='center'
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'destination_personal_3']} valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                )} />
            </Table>

            <div className='box_button_bottom_right'>
              <Form.Item style={{ margin: 0 }}>
                {/* 登録ボタン */}
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  htmlType='submit'
                  disabled={this.state.disabledSaveBtn}
                >
                  <span className='btn_label'>
                    登録
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}
export default WS0343103_DestinationInfo

