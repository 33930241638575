import ContractInfoMaintainService from 'services/basicInfo/ContractInfoMaintain/ContractInfoMaintainService'
import { message, } from "antd"

const ContractInfoMaintainAction = {
  loadContracts(params) {
    return ContractInfoMaintainService.loadContracts(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  eventF11(params) {
    return ContractInfoMaintainService.eventF11(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  setRCV(params) {
    return ContractInfoMaintainService.setRCV(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  billRCV(params) {
    return ContractInfoMaintainService.billRCV(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  DeleteLine(params) {
    return ContractInfoMaintainService.DeleteLine(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  DeleteHistoryContract(params) {
    return ContractInfoMaintainService.DeleteHistoryContract(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default ContractInfoMaintainAction;
