import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row, Button } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
class WS1389001_DocumentClassifyInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00190:文章分類照会';

    this.state = {
      dataSource: []
    };
  }
  componentDidMount() {
    // handle load init data
    this.loadData();
  }

  loadData() {
    VenusApiRoutesV2.callApi("API001389001002", {DocumentClassifyCode: this.props.DocumentClassifyCode})
    .then(res => {
      let data = res;
      this.setState({
        dataSource: data,
        selectedRow: {}
      })

      this.formRef?.current?.setFieldsValue({
        dataTable: data,
      });
    })
    .finally(() => this.setState({ isLoadingTable: false }))
  } 

  render() {
    return (
      <div className="document-classify-inquiry">
        <Card title="V4-TSB00190:文章分類照会">
          <Table bordered 
            dataSource={this.state.dataSource}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}
            rowKey={(record) => record.id}

            rowSelection={{
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                  selectedRow: selectedRows[0]
                });
              }
            }}
          >
            <Table.Column title="文章分類コード" dataIndex="document_classification_code" key="document_classification_code" />
            <Table.Column title="文章分類名" dataIndex="document_classification_name" key="document_classification_name" />

          </Table>
            <Button type="primary" style={{ float: 'right' }}
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      document_classification_code: this.state.selectedRow ? this.state.selectedRow?.document_classification_code : null,
                      document_classification_name: this.state.selectedRow ? this.state.selectedRow?.document_classification_name : null,
                    });
                  }
                }}
              >
              選択
            </Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1389001_DocumentClassifyInquiry);
