import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Space, Button } from "antd";
import XmlDocumentQuerySelectSubAction from "redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/XmlDocumentQuerySelectSub.action";
class WS1291001_XmlDocumentQuerySelectSub extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'XML帳票照会選択SUB';

    this.state = {
      selectedRows: [],
      selectedRowKeys: [],
      xmlData: []
    };
  }

  componentDidMount() {
    this.loadTable()
  }

  loadTable() {
    this.setState({ isLoadingTable: true });

    let param = {
      Li_SpecificAndHealthGuideAndAll: this.props.Li_SpecificAndHealthGuideAndAll
    }
    XmlDocumentQuerySelectSubAction.GetScreenData(param)
      .then(res => {
        // const xmlDataDefault = res.find(item => item.format === "H30000000003");
        // if (xmlDataDefault !== undefined) {
        //   this.formRef.current?.setFieldsValue({
        //     Lio_OutputDocument: xmlDataDefault.format
        //   });
        // }
        this.setState({
          ...this.state,
          xmlData: res,
          selectedRows: res[0],
          selectedRowKeys: res[0],
        });
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  render() {
    return (
      <div className="xml-document-query-select-sub">
        <Card title="XML帳票照会選択">
          <Form ref={this.formRef}>
            <Table
              size='small'
              bordered
              dataSource={this.state.xmlData}
              pagination={false}
              rowKey={record => record.format}
              onRow={(record) => {
                return {
                  onDoubleClick: async () => {
                    if (this.props.onFinishScreen) {
                      this.props.onFinishScreen({
                        recordData: record
                      })
                    }
                  }
                }
              }}
              scroll={{ y: 500 }}
            >
              <Table.Column title="FORMAT" dataIndex="format" width={150} />
              <Table.Column title="帳  票  名" dataIndex="Expresstion_5" />
            </Table>
            {/* <Space style={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                style={{ fontWeight: "bold" }}
                onClick={() =>
                  this.props.onFinishScreen({
                    recordData: this.state.selectedRows
                  })
                }
              >
                選択
              </Button>
            </Space> */}
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1291001_XmlDocumentQuerySelectSub);
