import axios from 'configs/axios';

const API_LIST = {
  index: '/api/electronic-medical-records-linkage-frame-set/detail-sub',
  addNewData: '/api/electronic-medical-records-linkage-frame-set/detail-sub/add-new-data',
  editData: '/api/electronic-medical-records-linkage-frame-set/detail-sub/edit-data',
  delete: '/api/electronic-medical-records-linkage-frame-set/detail-sub/delete',
}

const ElectronicMedicalRecordsLinkageFrameSetService = {
  async index(params) {
    return axios.get(API_LIST.index, { params });
  },

  async addNewData(params) {
    return axios.post(API_LIST.addNewData, params);
  },

  async editData(params) {
    return axios.post(API_LIST.editData, params);
  },

  async delete(params) {
    return axios.delete(API_LIST.delete, { params });
  },

}
export default ElectronicMedicalRecordsLinkageFrameSetService;