import axios from 'configs/axios'

const APP_LIST = {
  initial: '/api/document-manage-maintain/escort-detail-sub/initial',
  getDataTable: '/api/document-manage-maintain/escort-detail-sub',
  updateRecordData: '/api/document-manage-maintain/escort-detail-sub/save-and-update',
  updateDataBtn: '/api/document-manage-maintain/escort-detail-sub/update-btn',
  deleteRecordData: '/api/document-manage-maintain/escort-detail-sub/delete',
  updateLineDataTable: '/api/document-manage-maintain/escort-detail-sub/update-line-data-table',
  deplicateCheck: '/api/document-manage-maintain/escort-detail-sub/deplicate-check'
}

const EscortDetailSubService = {
  async initial(params) {
    return axios.get(APP_LIST.initial, { params })
  },

  async getDataTable(params) {
    return axios.post(APP_LIST.getDataTable, params)
  },

  async updateRecordData(params) {
    return axios.post(APP_LIST.updateRecordData, params)
  },

  async updateDataBtn(params) {
    return axios.post(APP_LIST.updateDataBtn, params)
  },

  async deleteRecordData(params) {
    return axios.delete(APP_LIST.deleteRecordData, { params })
  },

  async updateLineDataTable(params) {
    return axios.post(APP_LIST.updateLineDataTable, params)
  },

  async deplicateCheck(params) {
    return axios.post(APP_LIST.deplicateCheck, params)
  },
}

export default EscortDetailSubService
