import React from 'react'
import { connect } from 'react-redux'

import { Card, Input, Button, message, Spin, Space, Row, Col, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import ModalDraggable from 'components/Commons/ModalDraggable'

import WS1226007_CorrectSelect from 'pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226007_CorrectSelect.jsx'

const MEDIA_FIELD_STR_LENGTHS = [1, 2, 7, 10, 13, 1, 13, 1, 1]
const TAB1_COL1 = [10, 1, 1, 20, 2, 7, 1, 4, 8, 6, 6, 12, 1, 1, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 20, 1, 20, 1, 20, 8, 1, 4, 1, 4, 1, 4, 1, 4, 1, 1, 1, 1, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 4, 1, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8]
const TAB1_COL2 = [1, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 1, 8, 1, 1, 8, 1, 1, 8, 1, 8, 1, 8, 8, 8, 8, 8, 8, 1, 8]
const TAB1_COL3 = [1, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 40, 1, 1, 28, 40, 1, 1, 8, 1, 8, 1, 8, 1, 8, 40, 40, 1, 1, 8, 1, 8, 1, 8]
const TAB2 = [1, 1, 12, 1, 1, 16, 1, 16, 1, 30, 1, 1, 8, 1, 8, 1, 12, 1, 18, 1, 28, 1, 1, 1, 1, 8, 1, 1, 1, 1, 1, 1, 1, 1, 384, 1, 1, 1, 40, 12, 1, 16, 16, 1, 28, 40, 1, 8, 4, 19]
const TAB3 = [9, 9, 9, 1, 20, 9, 1, 2, 9, 9, 1, 1, 1, 1, 1, 1]
const RESULT_FIELD_STR_LENGTHS = [...TAB1_COL1, ...TAB1_COL2, ...TAB1_COL3, ...TAB2, ...TAB3]

class WS1226001_FoundationFdInquiry extends React.Component {
  mediaRef = React.createRef()
  resultRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '財団ＦＤ照会'

    this.state = {
      TextMediaFile: null,
      TextResultFile: null,
      readMediaFileFinish: false,
      readResultFileFinish: false,

      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
    }
  }

  browseTextMediaFile = ({ file, onSuccess }) => {
    setTimeout(() => {
      this.setState({ TextMediaFile: file })
      onSuccess('ok')
    }, 0)
  }

  browseTextResultFile = ({ file, onSuccess }) => {
    setTimeout(() => {
      this.setState({ TextResultFile: file })
      onSuccess('ok')
    }, 0)
  }

  readTextMediaFile = info => { }

  readTextResultFile = info => { }

  saveToLocalStorage = () => {
    if (!this.state.TextMediaFile) {
      message.error('媒体管理ファイルを閲覧ください。')
      return
    }
    if (!this.state.TextResultFile) {
      message.error('健診結果ファイルを閲覧ください。')
      return
    }
    const mediaFileReader = new FileReader()
    mediaFileReader.onload = async (e) => {
      const lines = e.target.result.split('\n')
      const fields = []
      if (lines[0] !== '') {
        let idx = 0
        for (let j = 0; j < MEDIA_FIELD_STR_LENGTHS.length; j++) {
          fields[j] = lines[0].substring(idx, idx + MEDIA_FIELD_STR_LENGTHS[j])
          idx += MEDIA_FIELD_STR_LENGTHS[j]
        }
      }
      window.localStorage.setItem('WS1226_MEDIA', JSON.stringify(fields))
    }
    mediaFileReader.onloadend = () => {
      this.setState({ readMediaFileFinish: true })
      if (this.state.readResultFileFinish) {
        this.changeDisplay()
      }
    }
    mediaFileReader.readAsText(this.state.TextMediaFile, 'Shift-JIS')

    const resultFileReader = new FileReader()
    resultFileReader.onload = async (e) => {
      const lines = e.target.result.split('\n')
      const parseLines = Array.from(lines, x => [])
      for (let i = 0; i < lines.length; i++) {
        if (lines[i] !== '') {
          const fields = []
          let idx = 0
          for (let j = 0; j < RESULT_FIELD_STR_LENGTHS.length; j++) {
            fields[j] = lines[i].substring(idx, idx + RESULT_FIELD_STR_LENGTHS[j])
            idx += RESULT_FIELD_STR_LENGTHS[j]
          }
          parseLines[i] = fields
        }
      }
      window.localStorage.setItem('WS1226_RESULT', JSON.stringify(parseLines))
    }
    resultFileReader.onloadend = () => {
      this.setState({ readResultFileFinish: true })
      if (this.state.readMediaFileFinish) {
        this.changeDisplay()
      }
    }
    resultFileReader.readAsText(this.state.TextResultFile, 'Shift-JIS')
  }

  changeDisplay = () => {
    console.log(JSON.parse(window.localStorage.getItem('WS1226_MEDIA')))
    console.log(JSON.parse(window.localStorage.getItem('WS1226_RESULT')))
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: (
          <WS1226007_CorrectSelect
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className='foundation-fd-inquiry'>
        <Card title='協会けんぽデータ照会'>
          <Row className='mb-4'>
            <Col span={24}>
              <div style={{ width: 120, display: 'inline-block' }}>媒体管理</div>
              <div style={{ width: 200, display: 'inline-block' }}>
                <Upload customRequest={this.browseTextMediaFile}
                  onChange={e => this.readTextMediaFile(e)}
                  maxCount={1}
                  showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                >
                  <Button type='primary' icon={<UploadOutlined />}></Button>
                </Upload>
              </div>
            </Col>
          </Row>
          <Row className='my-4'>
            <Col span={24}>
              <div style={{ width: 120, display: 'inline-block' }}>健診結果</div>
              <div style={{ width: 200, display: 'inline-block' }}>
                <Upload customRequest={this.browseTextResultFile}
                  onChange={e => this.readTextResultFile(e)}
                  maxCount={1}
                  showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                >
                  <Button type='primary' icon={<UploadOutlined />} ></Button>
                </Upload>
              </div>
            </Col>
          </Row>
          <Row className='mt-1' style={{ display: 'flex', alignItems: 'center' }}>
            <Button type='primary'
              className='mx-1'
              onClick={() => this.saveToLocalStorage()}>
              データ読取
            </Button>
            <Button type='primary'
              className='mx-1'
              onClick={this.changeDisplay}>訂正表示</Button>
          </Row>
        </Card>
        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0, backgroundColor: '#1890ff' }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
                component: null,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1226001_FoundationFdInquiry)
