import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/specific-coercive-finger-xml-output/details-extract/get-screen-data",
};
const DetailsExtractService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params });
  },
};

export default DetailsExtractService;