import axios from 'configs/axios'

const pathPersonal = {
  index: '/api/personal-info-maintain-directly/personal-info-maintain/index',
  getPersonalInfo: '/api/personal-info-maintain-directly/personal-info-maintain/get-personal-info',
  updatePersonalInfo: '/api/personal-info-maintain-directly/personal-info-maintain/update-personal-info',
  updateDetails: '/api/personal-info-maintain-directly/personal-info-maintain/update-details',
  updateBelongs: '/api/personal-info-maintain-directly/personal-info-maintain/update-belongs',
  deleteSpecials: '/api/personal-info-maintain-directly/personal-info-maintain/delete-specials',
  getNamePostalCode: '/api/personal-info-maintain-directly/personal-info-maintain/get-name-postal-code',

  getPersonalInfoSearch: '/api/personal-info-maintain-directly/personal-info-search-query',
  getPersonalInfoSearchOffice: '/api/personal-info-maintain-directly/personal-info-search-query/office',

  deleteAffiliation: '/api/personal-info-maintain-directly/personal-info-maintain/affiliation-delete/',
  getVariousNamesList: '/api/personal-info-maintain-directly/extension/get-screen-data',
  setAddressToDefault: '/api/personal-info-maintain-directly/personal-info-maintain/set-address-to-default',

  deleteNotification: '/api/personal-info-maintain-directly/personal-info-maintain/delete-notification',
  deleteExecution: '/api/personal-info-maintain-directly/personal-info-maintain/delete-execution',
  deleteBelongs: '/api/personal-info-maintain-directly/personal-info-maintain/delete-belongs'
}

const PersonalInfoMaintainService = {
  async index() {
    return axios.get(pathPersonal.index)
  },

  async getInitialDisplayCreatePersonal() {
    return axios.get(pathPersonal.getInitPersonalInfo)
  },

  async getPersonalInfo(params) {
    return axios.get(pathPersonal.getPersonalInfo, { params })
  },

  async registerPersonal(personal) {
    return axios.post(pathPersonal.updatePersonalInfo, personal)
  },

  async updatePersonalInfo(personal) {
    return axios.post(pathPersonal.updatePersonalInfo, personal)
  },

  async updateDetails(personal) {
    return axios.put(pathPersonal.updateDetails, personal)
  },

  async updateBelongs(personal) {
    return axios.post(pathPersonal.updateBelongs, personal)
  },

  async getPersonalInfoSearch(params) {
    return axios.get(pathPersonal.getPersonalInfoSearch, { params })
  },

  async getPersonalInfoSearchOffice(params) {
    return axios.get(pathPersonal.getPersonalInfoSearchOffice, { params })
  },

  async getVariousNamesList() {
    return axios.get(pathPersonal.getVariousNamesList)
  },

  async setAddressToDefault(params) {
    return axios.put(pathPersonal.setAddressToDefault, params)
  },

  async deleteSpecials(params) {
    return axios.delete(pathPersonal.deleteSpecials, { params })
  },

  // async eventF11PersonalInfoMain(params) {
  //   return axios.delete(pathPersonal.eventF11PersonalInfoMain, { params })
  // },

  async getNamePostalCode(params) {
    return axios.get(pathPersonal.getNamePostalCode, { params })
  },

  async deleteNotification(params) {
    return axios.delete(pathPersonal.deleteNotification, { params })
  },

  async deleteExecution(params) {
    return axios.delete(pathPersonal.deleteExecution, { params })
  },

  async deleteBelongs(params) {
    return axios.delete(pathPersonal.deleteBelongs, { params })
  },

}

export default PersonalInfoMaintainService
