import axios from 'configs/axios'
const apiPaths = {
  index: '/api/user-info/user-register-form',
  RegisterBtn: '/api/user-info/user-register-form/RegisterBtn',
}
const UserRegisterFormService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async RegisterBtn(params) {
    return axios.post(apiPaths.RegisterBtn, params)
  },
}

export default UserRegisterFormService
