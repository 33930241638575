import React from 'react'
import { connect } from 'react-redux'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Input, Button, message, Form, Modal } from 'antd'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
// import InspectCmtSearchQueryAction from 'redux/InputBusiness/SpreadInput/InspectCmtSearchQuery.action.js'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import { SaveOutlined } from '@ant-design/icons'

const formItemStyle = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 60px)' } }
}

class WS2637015_InspectResultValueInput extends React.Component {

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '結果値入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      screenData: {},
      disabledSaveBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    console.log(this.props);
    // 検査コメントの初期値を設定
    this.formRef.current.setFieldsValue({
      judgmentValue: this.props.Lio_JudgmentValue,
      resultValue: this.props.Lio_ResultValue
    })
  }

  /**
 * 判定選択
 * @param {*} data
 */
  showWS0285001_JudgeQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {
              if (output.recordData) {
                // 判定 変更
                await this.formRef?.current?.setFieldsValue({
                  judgmentValue: output.recordData.judgment_result
                })
                // 保存ボタン活性
                await this.changeDisabledSaveBtn()
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 保存ボタン押下処理
   */
  save = () => {
    // Fromに設定されている値を取得
    const data = this.formRef.current.getFieldsValue(true)

    const params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_InspectCode: this.props.Li_InspectCode,
      Li_JudgmentValue: data.judgmentValue ?? '', //Input入力値
      Li_ResultValue: data.resultValue ?? '', //Input入力値
    }

    OverallResultDisplayInputAction.saveIspectResultValue(params)
      .then((res) => {
        if (this.props.onUpdateResultValue) {
          // 親画面に反映
          this.props.onUpdateResultValue()
        }
      })
  }

  /**
   * 保存ボタンの活性
   */
  changeDisabledSaveBtn = () => {
    if (this.state.disabledSaveBtn) {
      // 保存ボタンの活性
      this.setState({ disabledSaveBtn: false })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='inspect-cmt-search-query'>
        <Card title='結果値入力'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>

              <Form.Item
                name='judgmentValue'
                label='判定'
                {...formItemStyle}
              >
                <Input.Search
                  className='input-search-size-judgment'
                  onSearch={() => {
                    // 判定選択
                    this.showWS0285001_JudgeQuery()
                  }}
                />
              </Form.Item>
              <Form.Item
                label='結果値'
                name='resultValue' //結果値
                {...formItemStyle}
              >
                <Input
                  className='input-size-number-15'
                  type='tel'
                  onChange={() => {
                    this.changeDisabledSaveBtn()
                  }}
                />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
                onClick={() => {
                  // 保存
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2637015_InspectResultValueInput)
