import { message } from 'antd'
import EscortDetailSubService from 'services/AdvancePreparation/DocumentManageMaintain/EscortDetailSubService'

const EscortDetailSubAction = {
  initial(data) {
    return EscortDetailSubService.initial(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getDataTable(data) {
    return EscortDetailSubService.getDataTable(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  updateRecordData(data) {
    return EscortDetailSubService.updateRecordData(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  updateDataBtn(data) {
    return EscortDetailSubService.updateDataBtn(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteRecordData(data) {
    return EscortDetailSubService.deleteRecordData(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  updateLineDataTable(data) {
    return EscortDetailSubService.updateLineDataTable(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deplicateCheck(data) {
    return EscortDetailSubService.deplicateCheck(data)
      .then((res) => {
        message.success(res.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}
export default EscortDetailSubAction
