import axios from 'configs/axios'

const apiPaths = {
  searchData: '/api/associate-insure-qualify-confirm/associate-insure-qualify-confirm/search-data',
  updateData: '/api/associate-insure-qualify-confirm/associate-insure-qualify-confirm/update',
}

const AssociateInsureQualifyConfirmService = {
  async searchData(params) {
    return axios.post(apiPaths.searchData, params)
  },

  async updateData(params) {
    return axios.put(apiPaths.updateData, { data: params })
  }
}

export default AssociateInsureQualifyConfirmService


