import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { MenuOutlined, MoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { Card, Form, Input, Button, Table, Tooltip, message, Dropdown, Menu, Select, Modal } from 'antd'
import WS0432001_CsvConfirm from 'pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0432001_CsvConfirm.jsx'
import DispatchManageAction from 'redux/ResultOutput/DispatchManage/DispatchManage.action'
import WS0256001_ProgressSet from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0256001_ProgressSet'
import moment from 'moment-timezone'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS2977500_ShippingDateRegistration from 'pages/KK_ResultOutput/KURM1100_DispatchManage/WS2977500_ShippingDateRegistration'
import { download_file } from 'helpers/CommonHelpers'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import WS2977002_DispatchManageRemarksEdit from 'pages/KK_ResultOutput/KURM1100_DispatchManage/WS2977002_DispatchManageRemarksEdit.jsx'

const smGrid = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 60px)' } }
}
class WS2977001_DispatchManage extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.string,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '発送管理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataSource: [],
      isLoadingTable: false,
      selectedRowKeys: [],
      selectedRow: {},
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      isLoadingPage: false,
      closeModalReserveSelect: false,
      PersonalId: '',
      postDate: moment(new Date()),
      remarks: '',

      postInfoData: [
        { postKey: 1, value: "予約関連" },
        { postKey: 2, value: "結果表" },
        { postKey: 3, value: '精密検査' },
      ],

      // 全体メモ保存ボタンの活性化情報
      AllMemoFunctionUpdateBtn: true
    }
  }

  componentDidMount() {
    this.GetScreenData()
  }

  GetScreenData() {
    let params = {
      Li_MenuOption: this.props.Li_MenuOption
    }

    DispatchManageAction.getScreenData(params)
      .then((res) => {
        const date = new Date()
        const thisYear = date.getFullYear()
        const nextYear = date.getFullYear() + 1

        this.setState({ isLoadingPage: false })
        this.formRef.current?.setFieldsValue({
          name: res?.name,
          StsReshipment: res?.StsReshipment,
          PeriodPast: res?.PeriodPast,
          PeriodFuture: res?.PeriodFuture,
          DispatchManageType: res?.DispatchManageType,
          Csv: res?.Csv,
          CsvName: res?.name,
          postInfo: '予約関連',
          ReserveDateFChar: thisYear + '/04/01',
          ReserveDateTChar: nextYear + '/03/31',
        })
      })
  }

  getDataSub(reservationNumber = 0) {
    let params = {
      postInfo: this.formRef.current?.getFieldValue('postInfo'),
      reservationNumber: reservationNumber ?? ''
    }
    DispatchManageAction.getDataSub(params)
      .then((res) => {
        let judgment = true
        let list = [...this.state.dataSource]
        let data = [...this.state.dataSource, ...res]

        if (1 <= list.length) {
          // 同一のIDの検索
          for (let i = 0; i < list.length; i++) {
            if (list[i].id === res[0].id) {
              list[i] = res[0]
              judgment = false
            }
          }

          // 同一のIDが存在しなかった場合
          if (judgment) {
            list = [...list, ...res]
          }
        } else {
          // 初期
          list = [...res]
        }

        this.setState({
          dataSource: list,
          selectedRowKeys: res && res.length > 0 ? [res[0].id] : [],
          selectedRow: res && res.length > 0 ? res[0] : {},
          isLoadingTable: false,
        })

        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  CsvOutput() {
    let params = {
      Csv: this.formRef.current?.getFieldValue('CsvName'),
      dataSource: this.state.dataSource
    }
    DispatchManageAction.CsvOutput(params)
      .then((res) => {
        download_file(res)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * バーコード取込処理
   * @param {*} e
   */
  ShippingDateRegistration(e) {
    let postInfoNumber = null
    let personalNumber = e.target.value
    let postInfo = this.formRef.current?.getFieldValue('postInfo') ?? '予約関連'

    if (personalNumber.length === 11) {
      personalNumber = personalNumber.slice(1)
      postInfoNumber = e.target.value.substr(0, 1)
      personalNumber = parseInt(e.target.value.slice(1), 10)

      const result = this.state.postInfoData.find((v) => v.postKey == postInfoNumber);
      postInfo = result?.value
    }
    // 桁数関係なく数値に変換して0埋めに対応
    personalNumber = Number(personalNumber)

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <WS2977500_ShippingDateRegistration
            ReserveDateFChar={this.formRef.current?.getFieldValue('ReserveDateFChar')}
            ReserveDateTChar={this.formRef.current?.getFieldValue('ReserveDateTChar')}
            postDate={this.state.postDate}
            remarks={this.state.remarks}
            personalNumberId={personalNumber}
            postInfo={postInfo}
            onFinishScreen={(output) => {
              this.ReadingInfoUpdate(e.target.value, output)
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  /**
   * 発送日登録
   * @param {*} value
   * @param {*} output
   */
  ReadingInfoUpdate(value, output) {
    let reserve_num = []
    let dispatchType = this.formRef.current?.getFieldValue('postInfo')

    if (value.length === 11) {
      let postKey = value.substr(0, 1)
      if (postKey >= 1 && postKey <= 3) {
        dispatchType = this.state.postInfoData[postKey]
        this.formRef.current?.setFieldsValue({ postInfo: dispatchType })
      }
    }

    reserve_num.push(output.reservationNumber)

    let params = {
      DispatchManageType: dispatchType,
      reserve_num: reserve_num,
      postDate: moment(output.fieldValue.postDate).format('YYYY/MM/DD'),
      remarks: output?.fieldValue.remarks ? output?.fieldValue.remarks : '',
      currentUser: this.props.currentUser.username
    }

    DispatchManageAction.ReadingInfoUpdate(params)
      .then((res) => {
        // バーコードに入っている値をクリア
        this.formRef.current?.setFieldsValue({
          barcode: ''
        })
        // 発送日登録で入力した発送日と備考を保持
        this.setState({
          postDate: moment(output.postDate).format('YYYY/MM/DD'),
          remarks: output?.remarks ? output?.remarks : ''
        })
        // 一覧再取得
        this.getDataSub(output.reservationNumber)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 発送状態をキャンセルにする
   */
  postCancel(reserve_num, index) {
    let params = {
      DispatchManageType: this.formRef.current?.getFieldValue('postInfo'),
      reserve_num: reserve_num
    }
    DispatchManageAction.postCancel(params)
      .then((res) => {
        message.success('発送をキャンセルしました。')
        let data = [...this.state.dataSource]
        data.splice(index, 1);
        this.setState({ dataSource: data })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  // 備考の変更
  remarksEdit(reservationNumber, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2977002_DispatchManageRemarksEdit
            record={record}
            visit_date_on={record.visit_date_on}
            personal_number_id={record.personal_number_id}
            kanji_name={record.kanji_name}

            onFinish={async (output) => {
              let params = {
                ...output,
                reserve_num: record.reservation_number,
                postInfo: record.postInfo
              }
              DispatchManageAction.updateRemarks(params)
                .then((res) => {
                  // 一覧再取得
                  this.getDataSub(record.reservation_number)
                })

              await this.closeModal()
            }}
          />
        )
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='dispatch-manage p-td'>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
        >
          <Card title='発送管理' className='mb-2'>
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className={(this.props.insurer_code) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                trigger='click'
                size='small'
                icon={<MenuOutlined />}
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key='1'
                        style={{ width: '100%' }}
                        onClick={() => this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (<WS0256001_ProgressSet
                              modalFlag={true}
                              Li_MenuOption={''}
                              Lio_ItemCodeList={''}
                              Lio_ItemNameList={''}
                              onFinishScreen={(output) => {
                                this.closeModal()
                              }}
                            />)
                          }
                        })}
                      >
                        進捗状況
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className='option_btn_right_top'
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <div className='box_inner_horizontal' style={{ width: '100%', marginBottom: '10px' }}>
              <div className='box_search'>
                <div className='box_inner_horizontal' style={{ width: '100%', marginBottom: 0 }}>
                  <Form.Item
                    label='受診日'
                    name='ReserveDateFChar'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>~</Form.Item>
                  <Form.Item
                    name='ReserveDateTChar'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                    />
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'end', margin: '0 0 0 auto', gap: 10 }}>
                <Form.Item
                  label='バーコード'
                  style={{ marginBottom: 0 }}
                  name='barcode'
                >
                  <Input
                    className='input-size-20'
                    type='number'
                    onPressEnter={(e) => {
                      this.ShippingDateRegistration(e)
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name='postInfo'
                  style={{ marginBottom: 0 }}
                >
                  <Select style={{ width: 100 }} >
                    {this.state.postInfoData?.map((value, index) => (
                      <Select.Option key={index} value={value.value}>
                        {value.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <Table
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              bordered
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
            >
              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                className='column-size-date'
                render={(value, record, index) => {
                  return (
                    <div>{value !== '0000/00/00' ? moment(value)?.format('YYYY/MM/DD (ddd)') : ''}  </div>
                  )
                }}
              />

              <Table.Column
                title='個人番号'
                dataIndex='personal_number_id'
                width={70}
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}  </div>
                  )
                }}
              />

              <Table.Column
                title='氏名'
                dataIndex='kanji_name'
                width={150}
              />

              <Table.Column
                width={70}
                title='メモ'
                dataIndex='consultationMemo'
                render={(value, record, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 'fit-content',
                            centered: true,
                            component: (
                              <AllMemosFunction
                                date={record.visit_date_on}
                                reservation_number={record.reservation_number}
                                personal_number_id={record.personal_number_id}
                                KanjiName={record.kanji_name}
                                onFinishScreen={async () => {
                                  await this.closeModal();
                                  await this.getDataSub();
                                }}

                                // メモ全体入力画面での保存ボタンの状態
                                onFlagUpdate={(bool) => {
                                  console.log('onFlagUpdate:' + bool);
                                  this.setState({
                                    AllMemoFunctionUpdateBtn: bool
                                  })
                                }}
                              />
                            ),
                          }
                        })
                      }}
                    >
                      {/* {'〇'} */}
                      {value.map((items, index) =>
                        <div
                          key={index}
                          style={{ display: 'flex', }}
                        >
                          <div
                            style={{ color: items.color }}
                          >
                            {items.text}
                          </div>
                          {((value.length - 1) > index) ?
                            <div>
                              {'・'}
                            </div>
                            :
                            null
                          }
                        </div>
                      )}
                    </div>
                  )
                }}
              />

              <Table.Column
                title='事業所名'
                dataIndex='office_kanji_name'
                width={230}
              />

              <Table.Column
                title='契約名称'
                dataIndex='contract_short_name'
              />

              <Table.Column
                title='印刷日'
                dataIndex='printDate'
                className='column-size-date'
                render={(text, record, index) => {
                  return (
                    text !== '' && text !== '0000/00/00' ?
                      <Tooltip title={record.printUserName}>
                        <div>{moment(text)?.format('YYYY/MM/DD (ddd)')}</div>
                      </Tooltip> :
                      null
                  )
                }}
              />

              <Table.Column
                title='発送日'
                dataIndex='postDate'
                className='column-size-date'
                render={(text, record, index) => {
                  return (
                    text !== '' && text !== '0000/00/00' ?
                      <Tooltip title={record.postUserName}>
                        <div>{moment(text)?.format('YYYY/MM/DD (ddd)')}</div>
                      </Tooltip>
                      :
                      null
                  )
                }}
              />

              <Table.Column
                title='備考'
                width={200}
                dataIndex='remark'
              />

              <Table.Column
                key='action'
                fixed='right'
                width={40}
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            onClick={() => {
                              this.postCancel(record.reservation_number, index)
                            }}
                          >
                            <label>発送キャンセル</label>
                          </Menu.Item>
                          <Menu.Item
                            key='2'
                            onClick={() => {
                              this.remarksEdit(record.reservation_number, record)
                            }}
                          >
                            <label>備考編集</label>
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        hidden={record?.postDate === ''}
                        icon={<MoreOutlined />} />
                    </Dropdown>
                  )
                }}
              />
            </Table>

            <div className="box_button_bottom_right" style={{ paddingTop: 5 }}>
              <Button type='primary'
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 500,
                      component: (
                        <WS0432001_CsvConfirm
                          Lio_Output={this.formRef.current.getFieldValue('CsvName')}
                          onFinishScreen={(output) => {
                            if (output.Lo_StsOutput) {
                              this.formRef.current.setFieldsValue({
                                CsvName: output.Lio_Output,
                                StsConfirm: output.Lo_StsOutput
                              })
                              this.CsvOutput()
                            }
                            this.closeModal()
                          }}
                        />)
                    }
                  })
                }} >CSV</Button>
            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            if (this.state.AllMemoFunctionUpdateBtn) {
              this.closeModal()
            } else {
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    AllMemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  currentUser: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2977001_DispatchManage)
