import { message } from "antd";
import PrintProcess from "services/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/PrintProcessService";

const post = (callback, request) => {
  return callback(request)
    .then(res => {
      return res?.data;
    })
    .catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error("エラーが発生しました");
      }
    });
};

const PrintProcessAction = {
  getScreenData(request) {
    return post(PrintProcess.getScreenData, request);
  },
  F11_KeyEvent(request) {
    return post(PrintProcess.F11_KeyEvent, request);
  },
};

export default PrintProcessAction;
