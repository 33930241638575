import axios from 'configs/axios'
const apiPaths = {
  getScreenData: '/api/printer-info-maintain/printer-info-input/get-screen-data',
  save: '/api/printer-info-maintain/printer-info-input/save',
  delete: '/api/printer-info-maintain/printer-info-input/delete',
}
const PrinterInfoInputService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },
  async delete(params) {
    return axios.post(apiPaths.delete, params)
  },
}

export default PrinterInfoInputService
