//class WS0855010_PrintParamInputChild extends React.Componentに必要
import React from "react";
//export default connect(mapStateToProps, mapDispatchToProps)(WS0855010_PrintParamInputChild);に必要
import { connect } from "react-redux";
import PropTypes from 'prop-types';
//<Card title={'印刷パラメータ保守 明細 備考 [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'}>に必要
import { Card, Form, Table, Button, Modal, Space, Row, Col, Input, Menu, Dropdown, message, InputNumber } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
//PrintParamInputAction.saveParramItem(params)に必要
import PrintParamInputAction from 'redux/ResultOutput/PrintParamMaintain/PrintParamInput.action'
import WS0061015_CheckYesNoNo from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo";
import WS0398001_ParamIndicationItemDisplaySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0398001_ParamIndicationItemDisplaySub.jsx';
//ModalDraggable のインポートに必要
import ModalDraggable from "components/Commons/ModalDraggable";




//ファイル名に書き換える
class WS0855010_PrintParamInputChild extends React.Component {
  formRef = React.createRef();

  //親画面の情報を取ってくる
  constructor(props) {
    super(props);
    //stateにdataSourceParramItemを保持　初期値[]を設定
    //stateに保持させるときは現画面で加工したいとき　propsでとってくるときは、加工せずそのままのデータが欲しいとき
    this.state = {
      dataSourceParramItem: [],
      //子画面を開くのに必要
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  //componentDidMountが初期画面表示処理
  componentDidMount() {
    //this.getScreenData()を行う
    this.getScreenData()
  }

  //getScreenData()の中身をかく
  getScreenData() {
    //取ってきた配列を//で区切る
    let parameter = this.props.dataSourceParramItem
    let list = parameter.split('//')
    //さらに=で区切る
    let listChild = []
    for (let i = 1; i < list.length; i++) {
      let A = list[i].split('=')
      //２次元配列を組む
      listChild.push({
        id: i,
        leftItem: A[0],
        rightItem: A[1]
      })
    }

    //前画面で配列で渡ってきているため、前画面のデータをそのまま持ってくる
    this.setState({
      //右：現在の画面で使用する名前　左：this.props.親画面の関数名　で定義
      //dataSourceParramItemが現画面で定義されていない場合は使用不可→this.state = {dataSourceParramItem: []}で定義
      dataSourceParramItem: listChild
    })
    //<Form.Item name={['dataSource',のためにsetFieldsValueをしている
    this.formRef.current?.setFieldsValue({
      dataSource: listChild
    })
  }


  // 削除
  /**
  * idから行番号を取得
  * @param {*} arrayData
  * @param {*} recordID
  * @returns
  */
  findIndexByID(arrayData, recordID) {
    return arrayData.findIndex((item) => recordID == item.id)
  }

  deleteParramItem(id) {
    //PrintParamInputAction.deleteParramItem(params)でPHPにparamsをわたしている
    //paramsの中身を決めている。
    let params = {
      id: id,
      W1_instruction_sect: this.props.W1_instruction_sect,
      W1_record_num: this.props.W1_record_num,
      W1_style_cd: this.props.W1_style_cd
    }
    // this.setState({
    //   childModal: {
    //     ...this.state.childModal,
    //     visible: true,
    //     width: 300,
    //     component:
    //       <WS0061015_CheckYesNoNo
    //         Li_Message={'削除を実行しますか ?'}
    //         onFinishScreen={(ouput) => {
    //           if (ouput.Lio_StsReturn) {
    //
    let deleteDataSourceParramItem = [...this.state.dataSourceParramItem]
    const index = this.findIndexByID(this.state.dataSourceParramItem, id)

    // 該当の行を削除
    deleteDataSourceParramItem.splice(index, 1)

    this.formRef.current?.setFieldsValue({
      dataSource: deleteDataSourceParramItem
    })

    this.setState({
      dataSourceParramItem: deleteDataSourceParramItem
    })

    // PrintParamInputAction.deleteParramItem(params)
    //   //PHPの処理が終わったかどうかをもらう
    //   .then((res) => {
    //     message.success('正常に削除されました!');
    //     this.closeModal()
    //     this.props.onFinishScreen()
    //   })
    //   //PrintParamInputActionでエラーがおこったら流れる
    //   .catch((err) => {
    //     const res = err.response;
    //     if (!res || !res.data || !res.data.message) {
    //       message.error("エラーが発生しました");
    //       console.log('era-koko')
    //       return;
    //     }
    //     message.error(res.data.message);
    //   });
    // }
    // this.closeModal()
    //         }}
    //       />
    //   },
    // });
  }

  /**
   * idから行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }


  Item_F12() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component:
          <WS0061015_CheckYesNoNo
            Li_Message={'変更内容を更新しますか ?'}
            onFinishScreen={(ouput) => {
              if (ouput.Lio_StsReturn) {
                const params = {
                  // Li_StyleCode: this.state.parramIndex.Li_StyleCode,
                  // Li_StsListFormat: this.state.parramIndex.Li_StsListFormat,
                  // dataSource: { ...this.state.dataSource },
                  ...this.formRef.current?.getFieldsValue('dataSource'),
                  W1_instruction_sect: this.props.W1_instruction_sect,
                  W1_record_num: this.props.W1_record_num,
                  W1_style_cd: this.props.W1_style_cd
                }
                PrintParamInputAction.updatef12(params)
                  .then((res) => {
                    message.success('更新しました!')
                    this.props.onFinishScreen()
                  })
                  .catch((err) => {
                    const res = err.response;
                    if (!res || !res.data || !res.data.message) {
                      message.error("エラーが発生しました");
                      return;
                    }
                    message.error(res.data.message);
                  });
              }

              this.closeModal()
            }}
          />
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  handleAddRowTable = async () => {
    // let idCount = this.state.addCount + 1
    let newRow = { id: ' ', leftItem: '', rightItem: '' }

    let data = [...this.state.dataSourceParramItem]

    data.unshift(newRow)

    await this.setState({
      dataSourceParramItem: data,
      // selectedRow: { ...newRow },
      // addCount: idCount,
    })
    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.forceUpdate()

  }

  render() {
    return (
      // ファイル名を小文字にして-でつなげたもの
      <div
        className="print-param-input-child"
        style={{ width: '100%' }}
      >
        {/* 開けたときの最初の画面を定義する */}
        <Card title={'印刷パラメータ保守 明細 備考 [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'}>
          <Form
            ref={this.formRef}
          >
            <Table
              size='small'
              //this.state.→この画面のstateに入っているもの　
              dataSource={this.state.dataSourceParramItem}
              // loading={this.state.isLoadingTableParramItem}
              pagination={false}
              bordered={true}
              scroll={{ x: 500, y: 400 }}
              //rowKeyは被っちゃいけない
              rowKey={(record) => record.id}
            // rowSelection={{
            //   type: "radio",
            //   selectedRowKeys: this.state.selectedRowKeysParramItem,
            //   onSelect: (record, selected, selectedRows) => {
            //     let index = this.state.dataSourceParramItem.findIndex(x => x.id === record.id)
            //     this.setState({
            //       rowSelectedParramItem: selectedRows,
            //       selectedRowKeysParramItem: selectedRows.map(x => x.id),
            //       indexTableParramItem: index
            //     });
            //     this.changeRowParramItem(index)
            //   },
            // }}
            >
              <Table.Column
                title="項目"
                dataIndex='leftItem'
                //recordに行のデータが入っている。
                render={(value, record, index) => {
                  return (
                    //this.findIndexByID(this.state.dataSourceParramItem, record.id)　→this.state.dataSourceParramItemの中のrecord.idを探して、それがdataSourceの配列の何番をみる→leftItemを持ってくる
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSourceParramItem, record.id), 'leftItem']}>
                      {/* //Input.Search 虫眼鏡付きの入力フォーム　Input　入力のみのフォーム */}
                      <Input.Search
                        value={record.leftItem}
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '40%',
                              component: (
                                <WS0398001_ParamIndicationItemDisplaySub
                                  Li_Format={this.props.Li_StsListFormat ? "B" : "A"}
                                  // Li_IndicationDivision={this.props.Li_IndicationDivision}
                                  Li_StsSelectable={true}
                                // onFinishScreen={(output) => {
                                //   this.updateDatasource('ParramItem', this.findIndexByID(this.state.dataSourceParramItem, record.id), "W2_item", output.Lo_Item)
                                //   this.closeModal()        
                                // }}
                                />
                              )
                            }
                          });
                        }}
                      //           onChange={(e) => {
                      //             this.updateDatasource('ParramItem', this.findIndexByID(this.state.dataSourceParramItem, record.id), "W2_item", e.target.value)
                      //           }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title="内容"
                dataIndex='rightItem'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSourceParramItem, record.id), 'rightItem']}>
                      <Input
                        value={record.rightItem}
                      //       onChange={(e) => {
                      //         this.updateDatasource('ParramItem', this.findIndexByID(this.state.dataSourceParramItem, record.id), "W2_content", e.target.value)
                      //       }}
                      />
                    </Form.Item>
                  )
                }}
              />

              {/* 追加ボタン */}
              <Table.Column
                width={40}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Button
                      size='small'
                      // disabled={this.checkDisabledBtnAddParramItem() || this.checkDisabledBtnAdd()}
                      onClick={() => this.handleAddRowTable()}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                    </Button>
                  </div>
                }

                // 削除ボタン
                render={(text, record, index) => {
                  return <div style={{ textAlign: "center" }}>
                    <Button
                      size='small'
                      icon={<DeleteOutlined />}
                      // style={{ border: 'none' }}
                      onClick={() => {
                        this.deleteParramItem(record.id)
                      }}
                      danger
                    >
                    </Button>
                  </div>;
                }}
              />
            </Table>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => { this.Item_F12() }}
                icon={<SaveOutlined />}
                disabled={this.state.disabledSaveBtn}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        {/* 子画面に必要 */}
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}

//以下画面の新規作成に必要な行
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

//ファイル名に書き換える
export default connect(mapStateToProps, mapDispatchToProps)(WS0855010_PrintParamInputChild);
