import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Space, message, Table, Tooltip, Dropdown, Menu } from 'antd'
import { PlusOutlined, DeleteOutlined, SaveOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons'
import WS1316700_ContractMasterMaintainEdit from './WS1316700_ContractMasterMaintainEdit'
import WS1316600_ContractMasterMaintainInquiry from './WS1316600_ContractMasterMaintainInquiry'
import ContractMasterDetailsAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterDetails.actions'
import WS1288001_ContractYearSelectSub from 'pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1288001_ContractYearSelectSub.jsx'
import ContractMasterMaintainAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractMasterMaintain.actions'
import ContractYearSelectSubAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractYearSelectSub.actions'
import WS0061009_CheckYesNoYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061009_CheckYesNoYes.jsx'
import WS1318001_ContractCopyingProcessSub from './WS1318001_ContractCopyingProcessSub'
import WS1319001_InsurerInfo from './WS1319001_InsurerInfo'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

const columns = [
  {
    title: <div style={{ textAlign: 'left' }}>内部番号</div>,
    dataIndex: 'serial_number',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },

  {
    title: '契約番号',
    dataIndex: 'contract_number',
    classNumber: '40',
    type: 1,
  },
  {
    title: '契 約 名 称',
    dataIndex: 'contract_name',
    // classNumber: '40',
    type: 1,
  },

  {
    title: '契約形態',
    dataIndex: 'contract_type',
    classNumber: '10',
    type: 2,
    selectList: [{
      value: 1,
      label: '個別'
    },
    {
      value: 2,
      label: '集合'
    }],
  },
]

class WS1316001_ContractMasterMaintain extends React.Component {
  static propTypes = {
    Li_Year: PropTypes.any,
    Li_Name: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    // document.title = 'V4-TMS01000:契約マスタ保守' 
    this.state = {
      childModal: {
        visible: false,
        component: null,
        title: null,
        width: 0,
      },
      tableDataYear: [],
      checkDelete: false,
      isloadfrm: false,
      selectedRow: null,
      dataSourceLength: 0,
      tableData1: [],
      selectedRowKeys: []
    }
  }

  componentDidMount() {
    this.loadInit()
  }

  loadInit() {
    ContractYearSelectSubAction.getListData().then(res => {
      if (res.length > 0) {
        this.setState({
          tableDataYear: res
        })
        let YearDisplay = res[0].YearDisplay
        let month = YearDisplay?.split('年')?.[1]?.split('月')?.[0]
        if (!this.formRef.current?.getFieldValue('ContractYear') && !this.formRef.current?.getFieldValue('ContractMonth')) {
          this.formRef.current?.setFieldsValue({
            ContractYear: res[0].year,
            ContractName: res[0].contract_name,
            ContractMonth: month ?? month.trim()
          })
        }
        this.getDataInit()
        this.forceUpdate()
      }
    }).catch(error => {
      const res = error.response
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました')
      }
    })
  }

  getDataInit = async () => {
    const ContractYear = this.formRef.current?.getFieldValue('ContractYear')
    const ContractName = this.formRef.current?.getFieldValue('ContractName')
    let ContractYear2019
    if (ContractName === '2019年10月') {
      ContractYear2019 = ContractYear - 1000
    }
    try {
      if (!isNaN(ContractYear)) {
        this.setState({ isLoadingInit: true })
        let res = await ContractMasterDetailsAction.getListData({ Li_ContractYear: ContractYear2019 === undefined ? ContractYear : ContractYear2019 })
        this.setState({
          tableData1: res ? res : [],
          selectedRowKeys: res ? res[0] : []
        })
        this.formRef.current?.setFieldsValue({
          tableData1: res ? res : []
        })
        this.setState({ isLoadingInit: false })
      }
    } catch (error) {
      const res = error.response
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました')
      }
    }
  }

  onCheckYM = () => {
    this.setState({ ...this.state.checkDelete, checkDelete: false, serial_number: 0 })
    let value = this.formRef.current?.getFieldValue('ContractYear')
    const reg = /^-?[0-9]*(\.[0-9]*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      this.setFieldValueYM(value, this.formRef.current?.getFieldValue('ContractMonth'))
    } else {
      message.warning('正しい契約年度を入力して下さい。')
      this.setFieldValueYM(1000, 10)
    }
  }
  setFieldValueYM(year, month) {
    let checkLoop = 0
    let checkEnd = 0
    let thang = month ? month : 4
    this.state.tableDataYear.map((value, index) => {
      if (checkLoop == 0) {
        let monthVal = value.YearDisplay.split('年')[1].split('月')[0]
        if (value.year === year && parseInt(monthVal) === thang) {
          checkLoop = 1
          this.formRef.current?.setFieldsValue({
            ContractYear: year === 1000 ? 0 : year,
            ContractMonth: thang,
            ContractName: value.contract_name,
            serial_number: value.serial_number
          })
          this.setState({ ...this.state.checkDelete, checkDelete: true })
        }
      }
      checkEnd = index
    })
    // if (checkEnd + 1 === this.state.tableDataYear.length) {
    //   if (checkLoop === 0) {
    //     let nam = 0
    //     if (thang === 10) {
    //       nam = year === 1000 || year === 0 ? 0 : year > 1000 ? year : 1000 - year
    //     } else {
    //       nam = year
    //     }
    //     message.warning('正しい契約年度を入力して下さい。')
    //     this.formRef.current?.setFieldsValue({
    //       ContractMonth: thang,
    //       ContractYear: nam,
    //       ContractName: '',
    //       serial_number: 0
    //     })
    //   }
    // }
  }
  showContractYearSelectSub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS1288001_ContractYearSelectSub
            Lio_ContractYear={this.formRef.current?.getFieldValue('ContractYear')}
            onFinishScreen={(output) => {
              let YearDisplay = output.recordData?.YearDisplay
              let month = YearDisplay?.split('年')?.[1]?.split('月')?.[0]
              if (output.Lio_ContractYear === 1019) {
                this.formRef.current?.setFieldsValue({
                  ContractYear: output?.Lio_ContractYear + 1000,
                  ContractName: output?.recordData?.contract_name,
                  ContractMonth: month ?? month.trim(),
                  serial_number: output.recordData?.serial_number ? output.recordData.serial_number : 0
                })
              } else {
                this.formRef.current?.setFieldsValue({
                  ContractYear: output?.Lio_ContractYear,
                  ContractName: output?.recordData?.contract_name,
                  ContractMonth: month ?? month.trim(),
                  serial_number: output.recordData?.serial_number ? output.recordData.serial_number : 0
                })
              }
              this.getDataInit()
              this.closeModal()
            }}
          />),
      },
    })
  }

  yearDelete() {
    this.setState({
      isloadfrm: true,
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0061009_CheckYesNoYes
            Li_DisplayContent={'確認して下さい!'}
            Li_DisplayMethod={0}
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                ContractMasterMaintainAction.delete({ ContractYear: this.formRef.current?.getFieldValue('ContractYear') }).then(res => {
                })
                  .finally(() => this.setState({ isloadfrm: false }))
              }
              this.setState({ isloadfrm: false })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  yearUpdate(value) {
    if (this.formRef.current?.getFieldValue('ContractYear') === 0) {
      message.error('正しい契約年度を入力して下さい。')
    } else {
      this.setState({ isloadfrm: true })
      ContractMasterMaintainAction.update({
        ContractYear: this.formRef.current?.getFieldValue('ContractYear'),
        serial_number: this.formRef.current?.getFieldValue('serial_number'),
        ContractName: '4月'
        // ContractName: this.formRef.current?.getFieldValue('ContractName') ? this.formRef.current?.getFieldValue('ContractName') : ''
      }).then(res => { console.log(res) })
        .finally(() => this.setState({ isloadfrm: false }))
    }
  }

  onFinish(values) { }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        value: ''
      },
    })
  }

  eventF7 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '40%',
        component: (
          <WS1318001_ContractCopyingProcessSub
            onFinishScreen={output => {
              if (Object.keys(output).length > 0) {
                this.formRef.current?.setFieldsValue({
                  ContractMonth: parseInt(output?.ContractMonth),
                  ContractYear: parseInt(output?.ContractYear),
                  ContractName: output?.ContractName,
                })
              }
              this.closeModal()
              this.loadInit()
            }}
            Lio_ContractYear={this.formRef.current?.getFieldValue('ContractYear')}
            Lio_ContractMonth={this.formRef.current?.getFieldValue('ContractMonth')}
            selectedRow={this.state.selectedRow}
            maxSerialNumber={this.state.maxSerialNumber}
          />
        )
      }
    })
  }

  eventF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS1319001_InsurerInfo
            Li_Year={this.formRef.current?.getFieldValue('ContractYear')}
            onFinishScreen={output => {
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  renderMenuBar = () => (
    <Space className='mb-2'>
      <Button type='primary' onClick={this.eventF7}>
        複写
      </Button>
      <Button type='primary' onClick={this.eventF10}>
        規定値
      </Button>
    </Space>
  )

  render() {
    return (
      <div className='contract-master-maintain'>
        <Card title='契約マスタ保守' style={{ width: '70%' }}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              size='small'
              className='option_btn_right_top'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.eventF7}>
                      複写
                    </Menu.Item>
                    <Menu.Item key='2' style={{ width: '100%' }} onClick={this.eventF10}>
                      規定値
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />} />
            </Dropdown>
          </Tooltip>
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ serial_number: 0 }} >
            <Space>
              <Form.Item name='ContractYear' label='契約' >
                <Input.Search maxLength={4} style={{ width: '100px' }}
                  onPressEnter={(e) => {
                    this.getDataInit()
                  }}
                  onSearch={() => this.showContractYearSelectSub()} />
              </Form.Item>
              {/* <Form.Item name='ContractMonth' label='年' >
                  <Select style={{ width: '70px' }} onChange={() => this.onCheckYM()} >
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                  </Select>
                </Form.Item> */}
              {/* <Form.Item name='ContractName' label='月' >
                  <Input />
                </Form.Item> */}
            </Space>
            <Table
              bordered
              dataSource={this.state.tableData1 ? this.state.tableData1 : []}
              size='small'
              rowKey={(record) => record.id}
              pagination={false}
              scroll={{ y: resizableTableScroll(70), x: 'max-content' }}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 550,
                      component: (
                        <WS1316600_ContractMasterMaintainInquiry
                          rowData={record}
                          closeModal={output => { this.closeModal() }}
                          onFinishScreen={output => {
                            this.loadInit()
                            // this.closeModal()
                          }}
                        />
                      )
                    }
                  })
              }
              )}
            >
              {columns.map((item, index) => (
                item.type === 1 ?
                  <Table.Column
                    key={'input' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign ?? ''}
                  /> : item.type === 2 ?
                    <Table.Column
                      key={'select' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      className={'column-size-' + item.classNumber}
                      align={item.textAlign}
                      render={(value, record) => {
                        // findを使ってvalueを元に検索する
                        let findItem = item.selectList.find(selected => selected.value === value)
                        // labelを指定してreturn
                        return findItem?.label
                      }}
                    /> : null
              ))}
              <Table.Column
                width={30}
                align='center'
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 550,
                          component: (
                            <WS1316700_ContractMasterMaintainEdit
                              year={this.formRef.current?.getFieldValue('ContractYear')}
                              activeTab={'0'}
                              onFinishScreen={output => {
                                this.loadInit()
                                this.closeModal()
                              }}
                            />
                          )
                        }
                      })
                    }
                  />}
                render={(text, record, index) => {
                  return <>
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 550,
                            component: (
                              <WS1316600_ContractMasterMaintainInquiry
                                rowData={record}
                                closeModal={output => { this.closeModal() }}
                                onFinishScreen={output => {
                                  this.loadInit()
                                  // this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }
                    ></Button>
                  </>
                }}
              />
            </Table>
          </Form>
          {/* <WS1317001_ContractMasterDetails 
            Li_ContractYear={this.formRef.current?.getFieldValue('ContractYear')} 
            onSelecteRow={async (selectedRow) => {
              await this.setState({
                selectedRow: selectedRow
              })
            }}
            onInit={async (maxSerialNumber) => {
              await this.setState({
                maxSerialNumber
              })
            }}
          /> */}

        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1316001_ContractMasterMaintain)
