import { message } from "antd";
import FollowUpLetterTargetCmtMaintainService from "services/IntroductionLetter/IntroduceLetterMasterMaintain/FollowUpLetterTargetCmtMaintainService"


const FollowUpLetterTargetCmtMaintainAction = {
  getMaintain(data) {
    return FollowUpLetterTargetCmtMaintainService.getMaintain(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  getInspectMaintain(data) {
    return FollowUpLetterTargetCmtMaintainService.getInspectMaintain(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  userAction1(data) {
    return FollowUpLetterTargetCmtMaintainService.userAction1(data)
      .then(res => {
        if (res) {
          return res.data
        }
      }).catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  deleteDataInspect(data) {
    return FollowUpLetterTargetCmtMaintainService.deleteDataInspect(data)
  },
  deleteMaintain(data) {
    return FollowUpLetterTargetCmtMaintainService.deleteMaintain(data)
  },
  saveDataInspect(data) {
    return FollowUpLetterTargetCmtMaintainService.saveDataInspect(data)
  },
  saveMaintain(data) {
    return FollowUpLetterTargetCmtMaintainService.saveMaintain(data)
  },
  getExamInfo(data) {
    return FollowUpLetterTargetCmtMaintainService.getExamInfo(data)
  },
  getDepartmentInfo(data) {
    return FollowUpLetterTargetCmtMaintainService.getDepartmentInfo(data)
  }
}

export default FollowUpLetterTargetCmtMaintainAction;
