import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import CreatingContractHistoryTogetherAction from 'redux/basicInfo/CreatingContractHistoryTogether/CreatingContractHistoryTogether.action'
import { Card, Form, Checkbox, Button, Table, Space, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import Color from 'constants/Color'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0317001_CreateContractHistoryTogether extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.draggleRef = React.createRef()

    // document.title = '契約履歴一括作成'

    this.state = {
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      Ms0710ContractHistoryId: [],
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      objInit: {},
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }

  /**
   * 初期データ取得
   * @param {*} params
   */
  index = () => {
    const params = {
      Li_MenuOption: this.props.Li_MenuOption
    }
    CreatingContractHistoryTogetherAction.index(params)
      .then(res => {
        if (res) {
          this.setState({ objInit: res })
        }
      })
  }

  /**
   * データ取得
   */
  getData = () => {
    const values = this.formRef.current.getFieldsValue()
    const params = {
      ContractStartDateChar: values.ContractStartDateChar ? moment(values.ContractStartDateChar).format('YYYY/MM/DD') : '',
      AllPart: this.state.objInit.AllPart ? 1 : 0,
      UseContractOnly: values.UseContractOnly ? 1 : 0
    }
    CreatingContractHistoryTogetherAction.getData(params)
      .then(res => {
        let data = res
        if (data) {
          let selectedId = []
          let selectedData = []
          data.map(item => {
            if (item.enabled_disabled) {
              selectedId.push(item.id)
              selectedData.push(item)
            }
          })
          this.setState({
            dataSource: data,
            selectedRowKeys: selectedId,
            selectedRows: selectedData,
          })
        }
      })
  }

  /**
   * 一括作成処理
   */
  createData = () => {
    let ContractStartDateChar = this.formRef?.current?.getFieldValue('ContractStartDateChar')
    const params = {
      ContractStartDateChar: ContractStartDateChar ? moment(ContractStartDateChar).format('YYYY/MM/DD') : '',
      AllPart: this.state.objInit.AllPart ? 1 : 0,
      UseContractOnly: this.formRef?.current?.getFieldValue('UseContractOnly') ? 1 : 0,
      selectedData: this.state.selectedRows
    }

    Modal.confirm({
      content: '選択した契約の年度更新を実行しますか',
      okText: '実　行',
      cancelText: 'キャンセル',
      className: 'modal-confirm',
      onOk: () => {
        CreatingContractHistoryTogetherAction.createData(params)
          .then(() => {
            // データ再取得
            this.getData()
          })
      },
      modalRender: (modal) => this.DraggableRender(modal)
    })
    setTimeout(() => {
      const dialog = document.querySelector('.modal-confirm')
      const modalConfirm = dialog.querySelector('.react-draggable')
      if (modalConfirm) {
        modalConfirm.setAttribute('style', 'cursor: all-scroll')
      }
    })
  }

  /**
   * チェックボックス 選択
   * @param {*} record
   * @param {*} selected
   */
  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  resetDraggable() {
    if (this.draggleRef && this.draggleRef.current) {
      this.draggleRef.current.state.x = 0
      this.draggleRef.current.state.y = 0
    }
  }

  DraggableRender = (modal) => {
    return (<Draggable
      ref={this.draggleRef}
    >
      {modal}
    </Draggable>)
  }

  render() {
    let year = parseInt(moment().format('YYYY')) + 1
    let dateTemp = year + '/04'
    return (
      <div className='create-contract-history-together'>
        <Card title='契約履歴一括作成'>
          <Form
            ref={this.formRef}
            initialValues={{
              UseContractOnly: true,
              ContractStartDateChar: moment(dateTemp)
            }}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_inner_vertical' style={{ gap: 0 }}>
                    <Form.Item
                      label='作成したい契約日'
                      name='ContractStartDateChar'
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>
                    <Form.Item
                      label='使用契約のみ作成'
                      name='UseContractOnly'
                      valuePropName='checked'
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox />
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      size='middle'
                      onClick={() => this.getData()}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>

              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={record => record?.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
                rowSelection={{
                  selectedRowKeys: this.state.selectedRowKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    this.setState({ selectedRows: selectedRows })
                  },
                  onSelect: (record, selected) => {
                    this.onSelect(record, selected)
                  },
                  onSelectAll: (selected) => {
                    this.onSelectAll(selected)
                  }
                }}
              >
                <Table.Column
                  title='契約種別'
                  dataIndex='ContractTypeChar'
                  width={100}
                  render={(text, record) =>
                    <div style={{
                      textAlign: 'center',
                      color: Color(record.enabledDisabledColorNum).Foreground
                    }}>
                      {text}
                    </div>
                  }
                />
                <Table.Column
                  title='契約団体コード'
                  dataIndex='contract_organization_code'
                  width={140}
                  render={(text, record) => (
                    <div style={{ textAlign: 'right', marginRight: '5px', color: Color(record.enabledDisabledColorNum).Foreground }}>
                      {text}
                    </div>
                  )}
                />
                <Table.Column
                  title='契約名称'
                  dataIndex='contract_name'
                  render={(text, record) =>
                    <div style={{ color: Color(record.enabledDisabledColorNum).Foreground }}>
                      {text}
                    </div>
                  }
                />
                <Table.Column
                  title='履歴情報'
                  render={(text, record, index) => (
                    <Space>
                      <div style={{ color: Color(record.enabledDisabledColorNum).Foreground }}>
                        {moment(record.contract_start_date_on).format('YYYY/MM/DD')}
                      </div>
                      <div style={{ color: Color(record.enabledDisabledColorNum).Foreground }}>{record.history_contract_name}</div>
                    </Space>
                  )}
                />
                <Table.Column
                  title='使用'
                  dataIndex='Use'
                  width={50}
                  render={(text, record) =>
                    <div style={{
                      textAlign: 'center',
                      color: Color(record.enabledDisabledColorNum).Foreground
                    }}>
                      {text}
                    </div>
                  }
                />
              </Table>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              disabled={this.state.selectedRows.length === 0}
              onClick={() => this.createData()}
            >
              <span className='btn_label'>
                一括作成
              </span>
            </Button>
          </div>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0317001_CreateContractHistoryTogether)
