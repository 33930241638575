import axios from 'configs/axios'

const API_LIST = {
  index: '/api/tentative-reserve/tentative-reserve-capture-confirmed',
  readExcelFile: '/api/tentative-reserve/tentative-reserve-capture-confirmed/read-excel-file',
}

const TentativeReserveCaptureConfirmedService = {
  async index(params) {
    return axios.post(API_LIST.index, params)
  },
  async readExcelFile(params) {
    return axios.post(API_LIST.readExcelFile, params)
  },
}
export default TentativeReserveCaptureConfirmedService
