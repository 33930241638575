import axios from 'configs/axios'

const apiPaths = {
  index: '/api/examinee-list/auto-judge',
  eventYes: '/api/examinee-list/auto-judge/event-yes'
}

const AutoJudgeService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },

  async eventYes(params) {
    return axios.post(apiPaths.eventYes, params)
  }
}

export default AutoJudgeService
