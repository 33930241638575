import React from 'react'
import { Card, Table, Form, Input, Button, Modal } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

class WS0343104_CodeInfo extends React.Component {
  formRef = React.createRef()
  static propTypes = {
  }

  constructor(props) {
    super(props)

    // document.title = '個人情報保守 - コード'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      dataSource: [],
      disabledSaveBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.setState({
      dataSource: this.props.personal.personal_codes
    })
    this.formRef.current?.setFieldsValue({
      tableData: this.props.personal.personal_codes
    })
    console.log(this.props.personal.personal_codes);
  }

  /**
   * 入力変更処理
   * @param {*} value
   * @param {*} record
   * @param {*} index
   */
  onChangeValue = (value, record, index) => {
    let arrTemp = [...this.state.dataSource]
    // 内容を反映
    let obj = {
      ...record,
      code: value, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj

    this.formRef.current?.setFieldsValue({
      tableData: arrTemp
    })
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false // 登録ボタン 活性
    })
  }

  /**
   * 登録ボタン処理
   * @param {*} changedValues
   */
  onFinish = () => {
    Modal.confirm({
      content: '上書き登録します。よろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        const data = {
          personal_codes: [...this.state.dataSource]
        }
        // 親画面にてAPIを呼ぶ
        this.props.onUpdate(data)
        // 登録ボタン 非活性
        this.setState({
          disabledSaveBtn: true
        })
      }
    })
  }

  render() {
    return (
      <div className='code-info'>
        <Card title='コード'>
          <Form
            ref={this.formRef}
            // onValuesChange={this.onValuesChange}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              rowKey={record => record.id}
              pagination={false}
              bordered
            >
              <Table.Column
                title='名称'
                dataIndex='name'
                width={'20%'}
              />
              <Table.Column
                title='コード'
                dataIndex='code'
                width={'60%'}
                render={(text, record, index) => (
                  <Form.Item name={['tableData', index, 'code']} >
                    <Input
                      style={{ width: '100%' }}
                      onChange={(event) => {
                        // 数値の入力で実行
                        this.onChangeValue(event.target.value, record, index)
                      }}
                    />
                  </Form.Item>
                )} />
              <Table.Column
                title='説明'
                dataIndex='description'
                width={'20%'} />
            </Table>
            <div className='box_button_bottom_right'>
              <Form.Item style={{ margin: 0 }}>
                {/* 登録ボタン */}
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  htmlType='submit'
                  disabled={this.state.disabledSaveBtn}
                >
                  <span className='btn_label'>
                    登録
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}
export default WS0343104_CodeInfo
