import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Modal, message } from 'antd'
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from 'moment'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import ComprehensiveMedicalExamBillingAction from 'redux/AccountingBusiness/MutualAidAssociationBilling/ComprehensiveMedicalExamBilling.actions.js'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';

class WS1781001_ComprehensiveMedicalExamBilling extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '総合健診請求集計'

    this.state = {
      indexData: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount() {
    ComprehensiveMedicalExamBillingAction.index()
      .then((res) => {
        this.setState({
          indexData: res
        })
      })
  }

  totalling() {
    let ClaimYearsChar = this.formRef.current?.getFieldValue('ClaimYearsChar')

    let params = {
      ...this.state.indexData,
      Year: moment(ClaimYearsChar).format('YYYY'),
      Month: moment(ClaimYearsChar).format('MM'),
      Second: 0,
    }

    ComprehensiveMedicalExamBillingAction.totalling(params)
      .then((res) => {
        if (res.againAggregate) {
          Modal.confirm({
            width: "450px",
            content: "集計済のデータがあります。再集計をしますか。",
            okText: "はい",
            cancelText: "いいえ",
            onOk: () => {
              let params = {
                ...this.state.indexData,
                Year: moment(ClaimYearsChar).format('YYYY'),
                Month: moment(ClaimYearsChar).format('MM'),
                Second: 1
              }
              ComprehensiveMedicalExamBillingAction.totalling(params)
                .then((res) => {
                  message.success('再集計が終了しました')
                })
            }
          });
        } else {
          message.success('集計が終了しました')
        }
      })
  }

  InvoiceOutput() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                this.Print(output)
              }
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  Print(output) {
    let ClaimYearsChar = this.formRef.current?.getFieldValue('ClaimYearsChar')
    let params = {
      Year: moment(ClaimYearsChar).format('YYYY'),
      Month: moment(ClaimYearsChar).format('MM'),
    }

    if (output.Lo_Preview) {
      // プレビューの処理
      ComprehensiveMedicalExamBillingAction.preview(params)
        .then(res => {
          // pdfのObject作成　
          //Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data)
          // pdfを別タブで表示
          var fileOpen = window.open(fileURL)
          // this.loadTableData()
        })
        .catch(error => {
          const res = error.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    } else {
      // 印刷の処理
      ComprehensiveMedicalExamBillingAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            // message.error('処理の途中でエラーが発生しました')
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
          // this.loadTableData()
        })
        .catch(error => {
          const res = error.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    return (
      <div className='comprehensive-medical-exam-billing'>
        <Card
          title='総合健診請求集計'
          style={{ width: 400 }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{
              ClaimYearsChar: moment()
            }}
          >
            <Form.Item
              name='ClaimYearsChar'
              label='請求年月'
            >
              <VenusDatePickerCustom
                width='130px'
                formRefDatePicker={this.formRef}
                format='YYYY年MM月'
                picker='month'
              />
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                style={{ float: 'right', marginTop: '1em' }}
                onClick={() => {
                  this.totalling()
                }}
              >
                <span className='btn_label'>
                  集計
                </span>
              </Button>

              <Button
                type='primary'
                style={{ float: 'right', marginTop: '1em' }}
                onClick={() => {
                  this.InvoiceOutput()
                }}
              >
                <span className='btn_label'>
                  出力
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1781001_ComprehensiveMedicalExamBilling)
