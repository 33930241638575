import React from "react";
import { connect } from "react-redux";

import moment from 'moment'
import { Card, Form, Button, Table, Space } from "antd";
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import RefPeopleNumSettingInfoAction from "redux/SystemMaintenance/RefPeopleNumSettingInfo/RefPeopleNumSettingInfo.action";

class WS1485016_HistorySelect extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '履歴追加';

    this.state = {
      dataSource: [],
      selectedRowKeys: [],
      isLoadingTable: false,
    };
  }

  onFinish() {
    let params = {
      CopyFrom: this.state.selectedRowKeys[0],
      CopyTo: moment(this.formRef.current.getFieldValue('DateFChar'))?.format('YYYY-MM-DD'),
    }
    this.props.onFinishScreen(params)
  }

  componentDidMount() {
    this.getInitialValuse();
  }

  getInitialValuse = () => {
    this.setState({ isLoadingTable: true })
    RefPeopleNumSettingInfoAction.getHistoryOn({ Lio_History: this.props.Lio_History })
      .then(res => {
        this.setState({
          selectedRowKeys: res ? [res.History]: [],
          dataSource: res ? res.History_List : []
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => { this.setState({ isLoadingTable: false }) });
  }

  render() {
    return (
      <div className="history-select">
        <Card title="履歴追加">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space
              direction={'vertical'}
              style={{ width: '100%' }}
            >
              <div>複写元とする履歴を選択してください</div>
              <Table
                dataSource={this.state.dataSource}
                rowKey={(record) => record.history_on}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected, selectedRows) => {
                    let index = this.state.dataSource.findIndex(x => x.history_on === record.history_on)
                    this.setState({
                      rowSelected: selectedRows,
                      selectedRowKeys: selectedRows.map(x => x.history_on),
                      indexTable: index
                    });
                  },
                }}
                size={'small'}
                loading={this.state.isLoadingTable}
                pagination={false}
                showHeader={false}
              >
                <Table.Column dataIndex="history_on" />
              </Table>
              <div style={{ marginTop: '1em' }}>複写先とする日付を選択してください</div>
              <Form.Item name='DateFChar' style={{ marginBottom: 0 }}>
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => this.onFinish()}
                >
                  追加
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1485016_HistorySelect);
