import React from "react";
import moment from "moment";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import RadiographyInfoMaintainAction from "redux/InputBusiness/RadiologistsInfoMaintain/RadiographyInfoMaintain.action"


import {
  Card, Table, message, Button, Form, Input, Popconfirm, Space,
  Modal, Checkbox, InputNumber, Dropdown, Menu,
} from 'antd'
import {
  MoreOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, CheckOutlined
} from '@ant-design/icons';

import axios from "configs/axios";

const columns = [
  // {
  //   title: '健診ID',
  //   dataIndex: 'username',
  //   // classNumber: '10',
  //   // textAlign: 'right',
  //   type: 1,
  // },

  // {
  //   title: 'ユーザー名',
  //   dataIndex: 'name',
  //   // classNumber: '40',
  //   type: 1,
  // },
  {
    title: '読影ｺｰﾄﾞ',
    dataIndex: 'doctor_code',
    // classNumber: '40',
    type: 1,
  },

  {
    title: '区分',
    dataIndex: 'interpretation_division',
    // classNumber: '40',
    type: 1,
  },

  {
    title: '技師',
    dataIndex: 'status',
    // classNumber: '5',
    type: 3,
    radioList: [
      {
        value: 1,
        label: '技師'
      },
      {
        value: 0,
        label: '医師'
      }],
  },

]

class WS1877001_RadiographyInfoMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '読影者情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },

      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    ////確かめ方
    //// console.log("JSXで確認してるよ")
    //// console.log(RadiographyInfoMaintainAction.index())
    this.setState({ isLoading: true })
    RadiographyInfoMaintainAction.index()
      .then(res => {
        console.log("↓JSX")
        console.log(res);
        this.formRef.current.setFieldsValue({
          MainSource: res.MainSource,
        })
      })
      // this.setState({ isLoading: true });
      // axios.get('/api/radiologists-info-maintain/radiography-info-maintain')
      //   .then(res => {
      //     console.log(res.data.MainSource);
      //     this.formRef.current.setFieldsValue({
      //       MainSource: res.data.MainSource,
      //     });
      //   })
      //   .catch(error => {
      //     console.log(error);

      //     const res = error.response;
      //     if (!res || !res.data || !res.data.message) {
      //       message.error('エラーが発生しました');
      //       return;
      //     }

      //     message.error(res.data.message);
      //   })
      .then(() => this.setState({ isLoading: false }));
  }

  //★★編集保存ボタン★★//
  onSave = (output, id) => {
    console.log(output);
    let params = {
      id: id ?? null
    }
    let itemChange = output.itemChange
    Object.keys(itemChange).forEach(key => {
      console.log(itemChange)
      console.log(key)
      params = {
        ...params,
        [key]: itemChange[key]
      }
    })
    // let params = {
    //   data: record.itemChange,
    //   id: id ?? undefined,
    // }
    console.log("↓JSXのparams");
    console.log(params);
    console.log("↓JSXのoutput");
    console.log(output);
    RadiographyInfoMaintainAction.saveRadioInfo(params)
      .then((res) => {
        console.log(res)
        console.log("↑ここ")
        this.loadMainSource()
      })
      .then((res) => {
        console.log(res)
        console.log("↑次")
      })
      .catch((err) => {
        const res = err.response
        if (!res || res.data || res.data.massage) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }




  //★★削除ボタン★★//
  deleteRadioInfo = (id) => {
    // console.log(RadiographyInfoMaintainAction.deleteRadioInfo())
    let params = {
      id: Number(id)
    }
    RadiographyInfoMaintainAction.deleteRadioInfo(params)
      .then(res => {
        // console.log(res)
        this.setState({
          applyDateList: res
        })
        this.loadMainSource()
      })
  }

  onFinish = (values) => {
    this.setState({ isLoading: true });
    axios.post('/api/radiologists-info-maintain/radiography-info-maintain', values)
      .then(res => {
        message.success('保存完了');
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card title="読影者情報保守">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Table bordered
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={(record) => record.id}
              size="small"
              // rowSelection={{
              //   type: 'radio',
              //   onSelect: (record) => {
              //     this.setState({
              //       selectedRow: record,
              //       selectedRowKeys: [record?.id],
              //     })
              //   },
              //   selectedRowKeys: this.state.selectedRowKeys,
              // }}
              // rowKey={(record) => (record.id || Math.random())}
              onRow={(record) => ({
                onDoubleClick: () => {
                  console.log(record)
                  console.log("↑ここ")
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '550px',
                      className: '',
                      component:
                        <ModalChange
                          columns={columns}
                          record={record}
                          onUpdate={(output) => {
                            this.onSave(output, record.id)
                            this.closeModal()
                            console.log("更新")
                            console.log(output)
                          }}
                          onDelete={(output) => {
                            this.deleteRadioInfo(record.id)
                            console.log("削除")
                            this.closeModal()
                          }}
                        />
                    },
                  })
                }
              })}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}

            >
              <Table.Column title='健診ID' showSorterTooltip={false} dataIndex='username' />
              <Table.Column title='ユーザー名' showSorterTooltip={false} dataIndex='name' />
              <Table.Column title='読影ｺｰﾄﾞ' showSorterTooltip={false} dataIndex='doctor_code' />
              <Table.Column title='区分' showSorterTooltip={false} dataIndex='interpretation_division' />
              <Table.Column title="技師" showSorterTooltip={false} dataIndex="status" align='center' width={50}
                render={(value, record, index) => {
                  return (
                    this.checkJudgment(value)
                  )
                }}
              />


              {/* <Table.Column title="健診ID" dataIndex="username" render={(value, record, index) => (<>
                <Form.Item name={['MainSource', index, 'id']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={['MainSource', index, 'username']}>
                  <Input readOnly />
                </Form.Item>
              </>)} />
              <Table.Column title="ユーザー名" dataIndex="name" render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'name']}>
                  <Input readOnly />
                </Form.Item>
              )} />
              <Table.Column title="読影ｺｰﾄﾞ" key="doctor_code" render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'radiography_doctors', 'doctor_code']}>
                  <Input />
                </Form.Item>
              )} />
              <Table.Column title="区分" key="interpretation_division" render={(value, record, index) => (
                <Form.Item name={['MainSource', index, 'radiography_doctors', 'interpretation_division']}>
                  <Input type="number" maxLength="2" />
                </Form.Item>
              )} />
              <Table.Column title="技師" key="status" render={(value, record, index) => (<>
                <Form.Item name={['MainSource', index, 'radiography_doctors', 'status']} valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </>)} />
            </Table> */}

              {/* ＋ボタンの設定 */}
              <Table.Column align='center' width={40} fixed='right'
                // title={
                //   <Button
                //     type="primary"
                //     size="small"
                //     icon={<PlusOutlined />}
                //     loading={this.state.isLoadingInsurersList}
                //     onClick={() => {
                //       this.setState({
                //         childModal: {
                //           ...this.state.childModal,
                //           visible: true,
                //           width: '550px',
                //           className: '',
                //           component:
                //             <ModalAdd
                //               columns={columns}
                //               onFinishScreen={(output) => {
                //                 console.log(output)
                //                 console.log("新規保存output")
                //                 this.onSave(output)
                //                 this.closeModal()
                //               }}
                //             />
                //         },
                //       });
                //     }}
                //   />
                // }
                render={(text, record, index) => (
                  <Space>
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key='1' style={{ width: '100%' }}
                            size="small"
                            onClick={() => {
                              // 編集、削除
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  className: '',
                                  component:
                                    <ModalChange
                                      columns={columns}
                                      record={record}
                                      onUpdate={(output) => {
                                        this.closeModal()
                                      }}
                                      onDelete={(output) => {
                                        this.closeModal()
                                      }}
                                    />
                                },
                              });
                            }}
                          >編集</Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </Space>
                )}
              />

            </Table>

            <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              {/* <Button type="primary" htmlType="submit" loading={this.state.isLoading}>保存</Button> */}
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          //afterClose={this.state.childModal.afterClose}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS1877001_RadiographyInfoMaintain;
