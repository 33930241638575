import axios from 'configs/axios'

const apiPaths = {
  getNotesList: '/api/not-input-check-category/overall-result-display-input/category-judge/notes-input',
  save: '/api/not-input-check-category/overall-result-display-input/category-judge/notes-input/save',
  delete: '/api/not-input-check-category/overall-result-display-input/category-judge/notes-input/delete'
}

const NotesInputService = {
  async getNotesList(params) {
    return axios.get(apiPaths.getNotesList, { params })
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  },
}

export default NotesInputService
