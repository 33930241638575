import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Button, message, Input, Form, InputNumber, Menu, Dropdown, Tooltip } from 'antd'
import { PlusOutlined, DeleteOutlined, SaveOutlined, MenuOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import scrollIntoView from 'scroll-into-view'
import TextArea from 'antd/lib/input/TextArea'
import WS0452005_ConvertTblSubAll from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0452005_ConvertTblSubAll.jsx'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx'
import WS0397001_ParamPromptedQuerySub from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx'
import UserParamInput0Action from 'redux/ResultOutput/CsvCreateParamMaintain/UserParamInput0.action'
import WS0454004_ConvertTblSubAll from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0454004_ConvertTblSubAll'
import WS0461004_CharacterStringSearch from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0461004_CharacterStringSearch.jsx'
import WS0454003_ConvertTblSubInspect from 'pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0454003_ConvertTblSubInspect.jsx'
import CharacterStringSearchAction from 'redux/ResultOutput/CsvCreateParamMaintain/CharacterStringSearch.action'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS0461005_UserParamInput0 extends React.Component {

  static propTypes = {
    Li_Format: PropTypes.any,
    Li_Name: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'ﾕｰｻﾞｰﾊﾟﾗﾒｰﾀ入力0'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        onChange: (current) => {
          // 選択状態ナシ
          this.setState({ selectedRow: {} })
        }
      },
      selectedRows: [],
      code: '',
      isLoading: false,
      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: '',
      Lo_SeqSearch: '',
      SearchString: '',
      disabledSaveBtn: true,
      spinController: false
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  handleScroll() {
    scrollIntoView(document.querySelector('.scroll-row'), {
      align: {
        top: 0,
        left: 0
      },
    })
  }

  checkConditionIndex(series, kind) {
    let result
    let x = kind.slice(0, 1)
    let code = kind.slice(1, 7)
    result = series.indexOf(x)
    return result
  }

  componentDidMount() {
    this.getListData(this.props, true)
  }

  getListData(props, reload) {
    const params = {
      Li_Name: props?.Li_Name,
      Li_Format: props?.Li_Format,
    }
    this.setState({ isLoading: true })

    UserParamInput0Action.getListData(params)
      .then(async res => {
        let index = reload ? 0 : this.state.indexTable
        let data = res ? res : []
        console.log(data);
        await this.setState({
          Lo_SeqSearch: data[index].seq,
          dataSource: data,
          rowSelected: data.length > 0 ? [data[index]] : [],
          selectedRowKeys: data.length > 0 ? [data[index].id] : [],
          indexTable: index,
        })
        this.formRef.current?.setFieldsValue({ dataSource: data })
        this.handleScroll()
      }).finally(() => this.setState({
        isLoading: false,
      }))
  }

  async handleDeleteRowTable() {
    let data = [...this.state.dataSource]
    await data.splice(0, 1)
    await this.setState({
      dataSource: data,
      indexTable: 0,
      rowSelected: data.length > 0 ? [data[0]] : [],
      selectedRowKeys: data.length > 0 ? [data[0].id] : []
    })
    this.formRef.current?.setFieldsValue({ dataSource: data })
  }

  deleteData(id) {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  updateRecordData(index) {
    let params = {
      ...this.state.dataSource[index],
    }

    UserParamInput0Action.saveData(params)
      .then((res) => {
        this.getListData(this.props, this.state.dataSource[index].id ? false : true)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  createRecordData() {
    let data = this.state.dataSource && this.state.dataSource[0] ? this.state.dataSource[0] : null
    let params = {
    }
    if (!!data) {
      params = {
        Li_Format: data.Li_Format,
        seq: data.seq,
        kind: data.kind,
        remarks: data.remarks,
        number_of_digits: data.number_of_digits,
        position: data.position,
        set_pattern: data.set_pattern,
        error_checking: data.error_checking,
        option_remark: data.option_remark,
      }
      UserParamInput0Action.saveData(params)
        .then((res) => {
          this.getListData(this.props, true)
        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    }
  }

  // add new record
  async handleAddRowTable() {
    let data = this.state.dataSource.map(s => s)
    let newRow = {
      isNew: 1,
      id: '',
      disabledSave: true,
      Li_Format: this.props.Li_Format,
      seq: 0, position: 0,
      number_of_digits: 0
    }
    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      rowSelected: [newRow],
      selectedRowKeys: [newRow.id],
      indexTable: 0
    })
    this.formRef.current?.setFieldsValue({ dataSource: data })
    this.forceUpdate()
  }

  checkDataItem(isNew, record) {
    if (isNew === undefined) {
      if (!this.state.dataSource.some(s => s.id === record.id)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  updateDatasource(index, field, value) {
    console.log(index, field, value);

    let data = [...this.state.dataSource]
    data[index][field] = value

    this.setState({
      dataSource: data
    })
    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
  }

  showWS0271001_InspectItemSearchQuerySingle(record, Code) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            InspectCode={Code}
            onFinishScreen={(output) => {
              const res = {
                Code: output.Lio_InspectItemCode,
                exam_name: output?.Lo_exam_name,
                screen: 271,
                kind: ''
              }
              this.showScreenByAfter(record, res, true)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showWS0452003_ConvertTblSubInspect(record, res) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            Li_Format={res?.Li_Format}
            InspectCode={res.Li_InspectCode}
            Li_InspectName={res.Li_InspectName}
            Li_InspectCmtCode={res.Li_InspectCmtCode}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showWS0267001_CategorySearchQuerySingle(record, Code) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0267001_CategorySearchQuerySingle
            Lio_CategoryCode={Code}
            onFinishScreen={(output) => {
              const res = {
                Code: output.Lio_CategoryCode,
                category_name: output?.recordData?.category_name,
                screen: 267,
                kind: ''
              }
              this.showScreenByAfter(record, res, true)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showScreenByAfter(record, res, isntShowScreen) {
    const screen = res && res.screen
    const params = {
      screen: screen,
      Code: res?.Code,
      kind: record?.kind,
      category_name: !isntShowScreen ? res?.category_name : record?.category_name,
      name: record?.name,
      exam_name: !isntShowScreen ? res?.exam_name : record?.exam_name,
    }
    UserParamInput0Action.GzoomAfter(params)
      .then(result => {
        if (screen === 271) {
          if (!isntShowScreen) {
            this.showWS0271001_InspectItemSearchQuerySingle(record, res?.Code)
          } else {
            let data = [...this.state.dataSource]
            const index = data.findIndex(s => s.id === record.id)
            this.updateDatasource(index, 'kind', result.kind)
            this.updateDatasource(index, 'remarks', result.remarks)
          }

        }

        if (screen === 267) {
          if (!isntShowScreen) {
            this.showWS0267001_CategorySearchQuerySingle(record, res?.Code)
          } else {
            let data = [...this.state.dataSource]
            const index = data.findIndex(s => s.id === record.id)
            this.updateDatasource(index, 'kind', result.kind)
            this.updateDatasource(index, 'remarks', result.remarks)
          }
        }
        if (screen === 397) {
          this.showWS0397001_ParamPromptedQuerySub(result, record)
        }
      })
  }

  showWS0397001_ParamPromptedQuerySub() {
    const kind = this.state.rowSelected[0]?.kind
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0397001_ParamPromptedQuerySub
            Li_Format={'C'}
            Lo_IndicationDivision={kind ? kind : ''}
            onFinishScreen={(output) => {
              let data = [...this.state.dataSource]
              const index = data.findIndex(s => s.id === this.state.rowSelected[0]?.id)
              this.updateDatasource(index, 'kind', output.instruction_division)
              this.updateDatasource(index, 'remarks', output?.name)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  Gzoom(record) {
    UserParamInput0Action.GZoomBefore({ kind: record?.kind ? record?.kind : '' })
      .then(res => {
        this.showScreenByAfter(record, res)
      })
  }

  checkAddItem() {
    if (this.state.dataSource.length > 0) {
      let index = this.state.dataSource.findIndex(x => !x.id)
      if (index === -1) {
        return false
      }
      return true
    }
  }

  showWS0452005_ConvertTblSubAll(res) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0452005_ConvertTblSubAll
            Li_Format={res.Li_Format}
            onFinishScreen={({ output }) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  // showWS0454004_ConvertTblSubAll(res) {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: '70%',
  //       component: (
  //         <WS0454004_ConvertTblSubAll
  //           Li_Format={res.Li_Format}
  //           Li_InspectCode={res?.Li_InspectCode}
  //           onFinishScreen={({ output }) => {
  //             this.closeModal()
  //           }}
  //         />
  //       ),
  //     },
  //   })
  // }

  // showWS0454003_ConvertTblSubInspect(res) {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: '70%',
  //       component: (
  //         <WS0454003_ConvertTblSubInspect
  //           Li_Format={res.Li_Format}
  //           Li_InspectCode={res?.Li_InspectCode}
  //           Li_InspectName={res.Li_InspectName}
  //           Li_InspectCmtCode={res.Li_InspectCmtCode}
  //           onFinishScreen={({ output }) => {
  //             this.closeModal()
  //           }}
  //         />
  //       ),
  //     },
  //   })
  // }

  checkIdTemp(id) {
    if (id === '') {
      return true
    }
    return false
  }

  checkDisabledBtnAdd() {
    if (this.state.rowSelected.length > 0) {
      if (this.checkAddItem() || this.checkIdTemp(this.state.rowSelected[0].id)) {
        return true
      }
      return false
    }
    return false
  }

  eventF9(params) {
    UserParamInput0Action.f9({ Li_Format: params })
      .then(res => {
        if (res && res.message === 'Call Screen WS0452005') {
          this.showWS0452005_ConvertTblSubAll(res)
        } else {
          this.showWS0452003_ConvertTblSubInspect(res)
        }
      })
  }

  // eventF10() {
  //   UserParamInput0Action.f10()
  //     .then(res => {
  //       if (res && res.message !== 'Call Screen WS0454004') {
  //         this.showWS0454003_ConvertTblSubInspect(res)
  //       } else {
  //         this.showWS0454004_ConvertTblSubAll(res)
  //       }
  //     })
  // }

  showWS0461004_CharacterStringSearch(res) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS0461004_CharacterStringSearch
            Li_Format={res.Li_Format}
            onFinishScreen={(output) => {
              const index = this.state.dataSource.findIndex(s => s.seq === output?.Lo_SeqSearch)
              if (index !== -1) {
                this.setState({
                  SearchString: output?.recordData?.CharStringSearch,
                  Lo_SeqSearch: output?.Lo_SeqSearch,
                  rowSelected: [this.state.dataSource[index]],
                  selectedRowKeys: [this.state.dataSource[index]?.id],
                  indexTable: index
                })
                this.handleScroll()
              }

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  search() {
    const Lo_SeqSearch = (Number(this.state.Lo_SeqSearch) + 1)
    const req = {
      Li_Format: this.state.rowSelected[0].Li_Format,
      Li_SeqCurrent: Lo_SeqSearch,
      SearchString: this.state.SearchString,
    }
    CharacterStringSearchAction.getSearch(req)
      .then((res) => {
        if (res) {
          const index = this.state.dataSource.findIndex(s => s.seq === res.Lo_SeqSearch)
          if (index !== -1) {
            this.setState({
              Lo_SeqSearch: this.state.dataSource[index].seq,
              rowSelected: [this.state.dataSource[index]],
              selectedRowKeys: [this.state.dataSource[index]?.id],
              indexTable: index
            })
            this.handleScroll()
          }
        }
      })
  }

  ctrlN() {
    this.search()
  }

  returnKindHtml(record) {
    var span = () =>
    (<span
      style={{ cursor: 'pointer' }}
      onDoubleClick={async () => {
        await this.Gzoom(record)
      }}
    >
      {record.kind}
    </span>)

    var inputKind = () => (
      <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'kind']}>
        <Input
          onDoubleClick={async () => {
            if (record && !record.isNew) {
              await this.Gzoom(record)
            } else {
              await this.showWS0397001_ParamPromptedQuerySub()
            }
          }}
          onPressEnter={(e) => {
            this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'kind', e.target.value)
          }}
          onBlur={(e) => {
            this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'kind', e.target.value)
          }}
        >
        </Input>
      </Form.Item>
    )

    var checkDefaults = this.state.indexTable !== this.findIndexByID(this.state.dataSource, record.id)

    return (
      <div>
        {
          inputKind()
        }
      </div>
    )
  }

  saveUserParams = () => {
    let params = {
      dataSource: this.state.dataSource,
      Li_Name: this.props?.Li_Name,
      Li_Format: this.props?.Li_Format
    }

    UserParamInput0Action.saveData(params)
      .then((res) => {
        this.getListData(this.props, true)
      })
  }

  render() {
    return (
      <div className='user-param-input0'>
        <Card title='ユーザパラメータ入力0'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Tooltip>
              <Dropdown
                size='small'
                className={this.props.Li_Format ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                trigger='click'
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.eventF9(this.props.Li_Format)}>
                        検査変換
                      </Menu.Item>
                      {/* <Menu.Item key='2' style={{ width: '100%' }} onClick={() => this.eventF10()}>
                        所見変換
                      </Menu.Item> */}
                    </Menu>
                  )
                }}
              >
                <Button
                  size='small'
                  className='option_btn_right_top'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <TextArea name='Expression_7'
              readOnly
              value={this.state.rowSelected[0]?.option_remark}
              rows={3} type='text'
              style={{ marginBottom: '10px' }}
            />

            <Table
              dataSource={this.state.dataSource}
              rowClassName={(record, index) => record.id === this.state.rowSelected[0]?.id ? 'scroll-row table-row-light' : ''}
              size='small'
              scroll={{ x: 'max-content', y: '500px' }}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              bordered={true}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: async () => {
                    let oldRecord = this.state.rowSelected[0]
                    let index = this.state.dataSource.findIndex(x => x.id === record.id)
                    await this.setState({
                      Lo_SeqSearch: record?.seq,
                      rowSelected: [record],
                      selectedRowKeys: [record.id],
                      indexTable: index
                    })
                  }
                }
              }}
            >
              <Table.Column
                width={90}
                title='SEQ'
                dataIndex='seq'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'seq']}>
                      <InputNumber
                        className='input-size-number'
                        onPressEnter={(e) => {
                          if (e.target.value > 0) {
                            this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'seq', Number(e.target.value))
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value > 0) {
                            this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'seq', Number(e.target.value))
                          }
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  )
                }} />

              <Table.Column
                className='column-character-10'
                title='種別'
                dataIndex='kind'
                render={(value, record) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'kind']}>
                      <Input
                        onDoubleClick={async () => {
                          if (record && !record.isNew) {
                            await this.Gzoom(record)
                          } else {
                            await this.showWS0397001_ParamPromptedQuerySub()
                          }
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'kind', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'kind', e.target.value)
                        }}
                      >
                      </Input>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                className='column-size-40'
                title='名称'
                dataIndex='remarks'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'remarks']}>
                      <Input
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'remarks', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'remarks', e.target.value)
                        }}
                      >
                      </Input>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='桁数'
                dataIndex='number_of_digits'
                width={90}
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'number_of_digits']}>
                      <InputNumber
                        className='input-size-number'
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'number_of_digits', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'number_of_digits', e.target.value)
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='位置'
                dataIndex='position'
                width={90}
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'position']}>
                      <InputNumber
                        className='input-size-number'
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'position', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'position', e.target.value)
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='設定'
                dataIndex='set_pattern'
                width={90}
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'set_pattern']}>
                      <Input
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'set_pattern', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'set_pattern', e.target.value)
                        }}
                      >
                      </Input>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='C'
                dataIndex='error_checking'
                className='column-size-4'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'error_checking']}>
                      <Input
                        value={record?.error_checking}
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'error_checking', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'error_checking', e.target.value)
                        }}
                      >
                      </Input>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='オプション'
                dataIndex='option_remark'
                className='column-size-60'
                render={(value, record) => {
                  return (
                    <Form.Item name={['dataSource', this.findIndexByID(this.state.dataSource, record.id), 'option_remark']}>
                      <Input
                        onPressEnter={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'option_remark', e.target.value)
                        }}
                        onBlur={(e) => {
                          this.updateDatasource(this.findIndexByID(this.state.dataSource, record.id), 'option_remark', e.target.value)
                        }}
                      >
                      </Input>
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                className='column-size-2'
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      disabled={this.checkDisabledBtnAdd()}
                      onClick={() => this.handleAddRowTable()}
                      type='primary'
                      size='small'
                      icon={<PlusOutlined />}>
                    </Button>
                  </div>
                }
                render={(text, record, index) => {
                  return <div style={{ textAlign: 'center' }}>
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.deleteData(record.id)
                      }}
                    />
                  </div>
                }}
              />
            </Table>

            <div className='box_button_bottom_right'>
              <Tooltip title='保存ボタンを押下するまで編集内容や削除はデータベースに反映されません'>
                <Button
                  type='primary'
                  onClick={() => this.saveUserParams()}
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0461005_UserParamInput0)
