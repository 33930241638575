import HealthCheckupInfoSelectService from "services/SpecificInsureGuide/InsureGuideInitInput/HealthCheckupInfoSelectService.js"
import { message, } from "antd"

const HealthCheckupInfoSelectAction = {
  getScreenData(params) {
    return HealthCheckupInfoSelectService.getScreenData(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  inspectContentQuery(params) {
    return HealthCheckupInfoSelectService.inspectContentQuery(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  select(params) {
    return HealthCheckupInfoSelectService.select(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default HealthCheckupInfoSelectAction;