import VouchersInputProcessListService from "services/SpecificInsureGuide/VouchersInputProcessList/VouchersInputProcessListService"
import { message } from "antd";

const VouchersInputProcessListAction = {
  displayButton(data) {
    return VouchersInputProcessListService.displayButton(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  index(data) {
    return VouchersInputProcessListService.index(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

}
export default VouchersInputProcessListAction;