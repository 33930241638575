import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { SearchOutlined, MoreOutlined } from '@ant-design/icons'
import PassingManageBatchExtractAction from 'redux/Others/PassingManageBatchExtract/PassingManageBatchExtract.action'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import { Form, Card, Table, Modal, Button } from 'antd'

class WS3063001_PassingManageBatchExtract extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '通過管理一括抽出'

    this.state = {
      tabNum: 1,

      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      //Selected
      selectedRowsLeftCoursesWhole: {},
      selectedRowsCourseList: {},
      selectedRowsLeftExamListWhole: {},
      selectedRowsExamListWork: {},

      dataSource: [],
      selectedKey: [],
      listSelectedId: [],
      stsSelectAll: false,
    }
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ])
  }
  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    let param = {
      DateF: '',
      DateT: ''
    }
    PassingManageBatchExtractAction.getScreenData(param)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          DateFChar: moment(res.DateFChar),
        })
      })
  }

  getExtractList() {
    let param = {
      DateF: this.formRef.current.getFieldValue('DateFChar')?._i,
      DateT: this.formRef.current.getFieldValue('DateTChar')?._i,
      StsSelectAll: this.state.stsSelectAll ? 1 : 0
    }
    console.log(param)
    PassingManageBatchExtractAction.getExtractList(param)
      .then((res) => {
        const dataRes = res ? res : []
        let checkarray = []
        dataRes.forEach((x) => {
          if (x.W1_logic_01) {
            checkarray.push(x.id)
          }
        })
        this.setState({
          dataSource: dataRes,
          selectedKey: checkarray
        })
        this.formRef.current?.setFieldsValue({ AllSelect: true })
      })
  }


  getSearch() {
    this.setState({ stsSelectAll: true })
    let DateFChar = this.formRef.current.getFieldValue('DateFChar')?._i
    let DateTChar = this.formRef.current.getFieldValue('DateTChar')?._i
    let param = {
      DateF: DateFChar,
      DateT: DateTChar,
      StsSelectAll: 1
    }
    console.log(param)
    PassingManageBatchExtractAction.getDisplaySearch(param)
      .then((res) => {
        this.getExtractList()
      })
  }

  /**
   * 抽出
   */
  getBatchExtractBtn() {
    let DateFChar = this.formRef.current.getFieldValue('DateFChar') ? moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD') : ''
    let DateTChar = this.formRef.current.getFieldValue('DateTChar') ? moment(this.formRef.current.getFieldValue('DateTChar')).format('YYYY/MM/DD') : ''
    let param = {
      DateF: DateFChar,
      DateT: DateTChar ?? '',
      StsSelectAll: this.state.stsSelectAll ? 1 : 0,
      StsConfirm: 1,
      Li_SelectList: this.state.selectedKey ?? '',
    }
    console.log(param)
    PassingManageBatchExtractAction.getBatchExtractBtn(param)
      .then((res) => {
        this.getExtractList()
      })
  }

  changeDateF(event) {
    if (event === null) {
      this.formRef.current?.setFieldsValue({ DateFChar: null })
    } else {
      let date = event.format('YYYY/MM/DD')
      this.formRef.current?.setFieldsValue({ DateFChar: moment(date) })
    }
  }

  changeDateT(event) {
    if (event === null) {
      this.formRef.current?.setFieldsValue({ DateTChar: null })
    } else {
      let date = event.format('YYYY/MM/DD')
      this.formRef.current?.setFieldsValue({ DateTChar: moment(date) })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='passing-manage-batch-extract'>
        <Card title='通過管理一括抽出'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
            >
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <Form.Item
                    name='DateFChar' label='受診日'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      onChange={(event) => this.changeDateF(event)}
                    />
                  </Form.Item>
                  <Form.Item
                    name='DateTChar' label='~'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      onChange={(event) => this.changeDateT(event)}
                    />
                  </Form.Item>

                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        if (moment(this.formRef.current.getFieldValue('DateFChar')).valueOf() > moment(this.formRef.current.getFieldValue('DateTChar')).valueOf()) {
                          let title = moment(this.formRef.current.getFieldValue('DateFChar'))?.format('YYYY/MM/DD') + ' 以降の日付を設定してください'
                          Modal.error({
                            width: 350,
                            title: title,
                            okText: 'OK'
                          })
                        } else {
                          this.getSearch()
                        }
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>

              </div>
            </Form>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              scroll={{
                x: 'max-content', y: resizableTableScroll(120)
              }}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedKey,
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                  let arrTemp = [...this.state.selectedKey]
                  let idx = arrTemp.indexOf(record.id)
                  if (idx === -1) {
                    arrTemp.push(record.id)
                    this.setState({ selectedKey: arrTemp })
                  } else {
                    arrTemp.splice(idx, 1)
                    this.setState({ selectedKey: arrTemp })
                  }
                  if (arrTemp.length === this.state.dataSource.length) {
                    this.setState({ stsSelectAll: true })
                  } else {
                    this.setState({ stsSelectAll: false })
                  }
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                  if (selected) {
                    let arrTemp = this.state.dataSource.map(item => item.id)
                    this.setState({
                      selectedKey: arrTemp,
                      initObj: {
                        ...this.state.initObj,
                        AllSelect: 1,
                      },
                      stsSelectAll: true
                    })
                  } else {
                    this.setState({
                      selectedKey: [],
                      initObj: {
                        ...this.state.initObj,
                        AllSelect: 0,
                      },
                      stsSelectAll: false
                    })
                  }
                }
              }}
            >
              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                className='column-size-date'
                render={(value) => (
                  <div >{moment(value)?.format('YYYY/MM/DD(ddd)')}</div>
                )}
              />
              <Table.Column
                title='個人番号'
                dataIndex='personal_number_id'
                className='column-size-10'
                render={(velue) =>
                  <div style={{ textAlign: 'right' }}>{velue}</div>
                }
              />
              <Table.Column
                title='メモ'
                dataIndex='passing_official_name'
                width={40}
                render={(value, record) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1500,
                            component: (
                              // <Card title={'個人情報照会'}>
                              <WS2584019_PersonalInfoInquirySub
                                Li_PersonalNum={record.personal_number_id}
                              />
                              // </Card>
                            ),
                          },
                        })
                      }}
                    >
                    </Button>)
                }}

              />
              <Table.Column
                title='氏名'
                dataIndex='kanji_name'
                className='column-size-30'
              />
              <Table.Column
                title='コース'
                dataIndex='visit_course'
                width='75px'
              />
              <Table.Column
                title='契約略称'
                dataIndex='contract_short_name'
              />
            </Table>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              name='SendButton'
              type='primary'
              onClick={() => {
                Modal.confirm({
                  content: '通過管理の一括抽出を行いますか？',
                  okText: 'はい',
                  cancelText: 'いいえ',
                  onOk: () => this.getBatchExtractBtn(),
                })
              }}
            >
              <span className='btn_label'>
                抽出
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3063001_PassingManageBatchExtract)
