import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/progress-management/search-conditions-configuration/get-screen-data",
};

const SearchConditionsConfigurationService = {
  async getScreenData(params) {
    return axios.post(API_LIST.getScreenData, params);
  },
};

export default SearchConditionsConfigurationService;