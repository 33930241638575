import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { Card, Form, Input, Button, Checkbox, Modal, message } from 'antd'
import moment from 'moment-timezone'

import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle'

import BulkAutoCalculateAction from 'redux/Others/BulkAutoCalculate/BulkAutoCalculate.action'

const labelCol = {
  labelCol: { style: { width: 70 } }
}
const mb12 = {
  marginBottom: '12px',
}


class BulkAutoCalculate extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '結果表一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      nameScreen: '',
    }
  }

  componentDidMount() {
    this.getDate()
  }

  getDate() {
    BulkAutoCalculateAction.getDate()
      .then(res => {
        if (res) {
          let data = {
            startDate: moment(res?.startDate),
            endDate: moment(res?.endDate),
          }
          this.formRef.current?.setFieldsValue(data)
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  showModalContractInfoInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0289012_ContractInfoInquiry
            Li_EffectiveLimitedDisplay={1}
            Lio_ConsultCourse={this.formRef.current?.getFieldValue('ConsultCourse')}
            Li_Date={this.formRef.current?.getFieldValue('startDate') ? moment(this.formRef.current?.getFieldValue('startDate')).format('YYYY/MM/DD') : ''}

            onFinishScreen={(output) => {
              if (output.Lio_ConsultCourse) {
                this.formRef.current?.setFieldsValue({
                  ConsultCourse: output.Lio_ConsultCourse,
                  contract_short_name: output.recordData.contract_short_name,
                })
              } else {
                message.error('受診ｺｰｽを入力してください')
              }
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  InspectItemSearchQuerySingle = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0271001_InspectItemSearchQuerySingle
            onFinishScreen={(output) => {
              console.log(output)
              this.formRef.current?.setFieldsValue({
                Inspection: output.recordData.test_item_code,
                exam_short_name: output.recordData.exam_short_name,
              })
              this.closeModal();
            }}
          />
        ),
      }
    })
  }

  exec() {
    let params = {
      autoJudge: this.formRef.current?.getFieldValue('AutoJudge') ?? false,
      courseCode: this.formRef.current?.getFieldValue('ConsultCourse') ?? 0,
      examCode: this.formRef.current?.getFieldValue('Inspection') ?? 0,
      startDate: this.formRef.current?.getFieldValue('startDate'),
      endDate: this.formRef.current?.getFieldValue('endDate'),
    }
    ModalConfirm({
      content: '自動計算を実行しますか？',
      styleIconModal: { color: '#006CC9' },
      onOk: () => {
        BulkAutoCalculateAction.exec(params)
          .then(res => {
            if (res) {
              Modal.info({
                content: '一括自動計算が終了しました',
                okText: 'はい',
              })
            }
          })
          .catch(error => {
            const res = error.response
            if (!res || !res.data || !res.data.message) {
              message.error('エラーが発生しました')
              return
            }
            message.error(res.data.message)
          })
      },
      onCancel: () => {
        Modal.info({
          content: 'キャンセルしました',
        })
      },
    })
    
  }

  render() {
    return (
      <div className='bulk-auto-calculate'>
        <Card title='一括自動計算'>
          <Form
            ref={this.formRef}
          >
            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='受診日'
                name='startDate'
                style={{ marginBottom: 0 }}
                {...labelCol}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  allowClear={false}
                  style={{ width: 122 }}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}> ~ </Form.Item>
              <Form.Item
                name='endDate'
                style={{ marginBottom: 0 }}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  allowClear={false}
                  style={{ width: 122 }}
                />
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='コース'
                style={{ marginBottom: 0 }}
                name='ConsultCourse'
                {...labelCol}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-4'
                  style={{ width: 122 }}
                  onSearch={(value, event) => {
                    this.showModalContractInfoInquiry()
                  }}
                />
              </Form.Item>
              <Form.Item
                name='contract_short_name'
                style={{ marginBottom: 0 }}
              >
                <div>{this.formRef.current?.getFieldValue('contract_short_name')}</div>
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='対象検査'
                name='Inspection'
                style={{ marginBottom: 0 }}
                {...labelCol}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-4'
                  onSearch={() => {
                    this.InspectItemSearchQuerySingle()
                  }}
                />
              </Form.Item>
              <Form.Item
                name='exam_short_name'
                style={{ marginBottom: 0 }}
              >
                <div>{this.formRef.current?.getFieldValue('exam_short_name')}</div>
              </Form.Item>
            </div>

            <div className='box_inner_horizontal' style={mb12}>
              <Form.Item
                label='自動判定'
                name='AutoJudge'
                style={{ marginBottom: 0 }}
                {...labelCol}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </div>
          </Form>

          <Button
            type='primary'
            onClick={() => {
              this.exec();
            }}
          >
            <span className='btn_label'>実行</span>
          </Button>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkAutoCalculate)
