import OptionTypeMaintainService from "services/SystemMaintenance/UserOptionInfoMaintain/OptionTypeMaintainService";
import { message } from "antd";
const OptionTypeMaintainAction = {
  GetScreenData(data) {
    return OptionTypeMaintainService.getScreenDataUserOptionTypeMaintainService(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}
export default OptionTypeMaintainAction;