import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Checkbox, Button, Row, Col, message, InputNumber, Popconfirm, Modal } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'

import WS1389001_DocumentClassifyInquiry from 'pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1389001_DocumentClassifyInquiry.jsx';
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle";
import InspectItemMasterAction from "redux/SpecificInsureMaintenance/InspectItemMaster/InspectItemMaster.action";

class WS1400010_InspectItemMasterChangeSub extends React.Component {
  static propTypes = {
    Lio_InspectCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '検査項目マスタ修正・新規登録'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      indexTable: null,
      isLoading: false,
      initObj: {},
      Lio_InspectCode: 0,
      InspectType: 'N',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }

  index() {
    // console.log(this.props.Lio_InspectCode);
    let data = this.props.record
    // console.log(data)
    if (this.props.Lio_InspectCode) {
      this.formRef.current.setFieldsValue({
        id: data.id ?? null,
        isSave: data.isSave,
        exam_code: data.exam_code,
        exam_comment_division: data.exam_comment_division,
        exam_name: data.exam_name,
        exam_type: data.exam_type,
        expression_19: data.expression_19,
        expression_20: data.expression_20,
        expression_29: data.expression_29,
        document_classification_code: data.document_classification_code ?? '',
        document_classification_name: data.document_classification_name ?? null,
        formal_name: data.formal_name,
        judgment_code: data.judgment_code,
        short_name: data.short_name,
        InspectCode: data.InspectCode,
        InspectDivisionScreen: data.InspectDivisionScreen,
        InspectTypeScreen: data.InspectTypeScreen,
        NumOfDigits: data.NumOfDigits,
      });
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  changeInspectType(value) {
    this.setState({
      disabledNumOfDigits: true
    })

    if (value === 'J' || value === 'B') {
      this.setState({
        disabledNumOfDigits: false
      })
    }
    let content = value === 'X' ? '文字'
      : value === 'J' ? '１文章'
        : value === 'B' ? '文章入力'
          : value === 'N' ? '整数'
            : value === 'N1' ? '小数１桁'
              : value === 'N2' ? '小数２桁'
                : value === 'N3' ? '小数３桁'
                  : value === 'N4' ? '小数４桁'
                    : value === 'N5' ? '小数５桁' : '';

    this.formRef.current.setFieldsValue({
      InspectTypeScreen: value,
      expression_19: content,
    })
  }

  callApiSaveAndUpdateInspectItem() {
    const values = this.formRef.current.getFieldsValue()

    const params = {
      ...values,
      id: this.props.record?.id ?? null,
      InspectDivisionScreen: this.formRef.current.getFieldValue('InspectDivisionScreen'),
    }
    // console.log(params)

    InspectItemMasterAction.saveAndUpdateInspectItem(params)
      .then((res) => {
        message.success('更新しました。');
        this.props.onFinishScreen();
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(err.response.data.message);
      });
  }

  /**
 * 削除処理
 * @returns
 */
  callApiDeleteRecord() {
    let param = {
      id: this.props.record?.id ?? null,
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        InspectItemMasterAction.deleteInspectItem(param)
          .then((res) => {
            message.success('正常に削除されました')
            this.props.onFinishScreen();
          })
          .catch((err) => {
            const res = err.response;
            if (!res || !res.data || !res.data.message) {
              message.error('エラーが発生しました');
              return;
            }
            message.error(err.response.data.message);
          });
      },
    });

  }

  render() {
    return (
      <div className='inspect-item1-change-sub' style={{ width: '600px' }}>
        <Card title={this.props.Lio_InspectCode ? '修正' : '新規登録'}>
          {/* <Card title='修正'> */}

          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{ InspectDivisionScreen: '1', InspectTypeScreen: 'X', document_classification_code: '', document_classification_name: '', NumOfDigits: 0 }}
          >
            <Form.Item name='exam_code' label='検査ｺｰﾄﾞ'>
              <Input
                style={{ width: '150px' }}
                maxLength={4}
              // disabled={this.props.Lio_InspectCode}
              />
            </Form.Item>

            <Form.Item name='short_name' label='　略称名'>
              <Input maxLength={30} />
            </Form.Item>

            <Form.Item name='formal_name' label='　検査名' >
              <Input maxLength={30} />
            </Form.Item>

            <Form.Item name='InspectDivisionScreen' label="検査区分">
              <Select style={{ width: '150px' }} allowClear={false}
                onChange={(value) => {
                  // console.log(value)
                  this.formRef.current.setFieldsValue({
                    InspectDivisionScreen: value,
                    expression_20: value
                  })
                }}
              >
                <Select.Option value="1">目標</Select.Option>
                <Select.Option value="2">実績</Select.Option>
                <Select.Option value="9">共通</Select.Option>
                <Select.Option value="8">督促</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="judgment_code" label="判　　定">
              <Input type="number" style={{ width: '150px' }}
                onChange={(event) => {
                  this.formRef.current.setFieldsValue({
                    judgment_code: event.target.value
                  })
                }}
              />
            </Form.Item>
            <Form.Item name="exam_comment_division" label="検査ｺﾒﾝﾄ">
              <Input type="number" style={{ width: '150px' }}
                onChange={(event) => {
                  this.formRef.current.setFieldsValue({
                    exam_comment_division: event.target.value
                  })
                }}
              />
            </Form.Item>
            <Form.Item label="文章分類">
              <div className='box_inner_horizontal'>
                <Form.Item name="document_classification_code" style={{ marginBottom: 0 }}>
                  <Input.Search style={{ width: '100px' }}
                    readOnly
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component: (
                            <WS1389001_DocumentClassifyInquiry
                              DocumentClassifyCode={this.formRef.current?.getFieldValue('document_classification_code')}
                              onFinishScreen={(output) => {
                                this.formRef.current.setFieldsValue({
                                  document_classification_code: output.document_classification_code,
                                  document_classification_name: output.document_classification_name,
                                  expression_29: output.document_classification_name
                                });
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item name="document_classification_name" style={{ marginBottom: 0 }}>
                  <Input type="text" readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item label="健診検査">
              <div className='box_inner_horizontal'>
                <Form.Item name="InspectCode" style={{ marginBottom: 0 }}>
                  <Input.Search readOnly style={{ width: '150px' }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 800,
                          component: (
                            <WS0271001_InspectItemSearchQuerySingle
                              InspectCode={this.formRef.current?.getFieldValue('InspectCode')}
                              Li_Select={this.formRef.current?.getFieldValue('InspectCode')}
                              onFinishScreen={(output) => {
                                // console.log(output)
                                this.formRef.current.setFieldsValue({
                                  InspectCode: output.recordData.test_item_code,
                                  exam_name: output.recordData.exam_name,
                                  exam_type: output.recordData.exam_type,
                                })
                                this.closeModal();
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item name="exam_name" style={{ marginBottom: 0 }}>
                  <Input type="text" readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
                <Form.Item name="exam_type" style={{ marginBottom: 0 }}>
                  <Input type="text" readOnly style={{ border: 'none', background: 'transparent', width: '50px' }} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item name="InspectTypeScreen" label="検査ﾀｲﾌﾟ">
              <Select style={{ width: '150px' }} allowClear={false}
                onChange={(value) => {
                  this.changeInspectType(value)
                  // console.log(value)
                }}>
                <Select.Option value="X">文字</Select.Option>
                <Select.Option value="J">１文章</Select.Option>
                <Select.Option value="B">文章入力</Select.Option>
                <Select.Option value="N">整数</Select.Option>
                <Select.Option value="N1">小数１桁</Select.Option>
                <Select.Option value="N2">小数２桁</Select.Option>
                <Select.Option value="N3">小数３桁</Select.Option>
                <Select.Option value="N4">小数４桁</Select.Option>
                <Select.Option value="N5">小数５桁</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="NumOfDigits" label="桁　　数">
              <InputNumber maxLength={3} type="text" style={{ width: '150px' }} disabled={this.state.disabledNumOfDigits}
                onChange={(event) => {
                  // console.log(event.target.value)
                  this.formRef.current.setFieldsValue({
                    NumOfDigits: event.target.value
                  })
                }}
              />
            </Form.Item>

            <div className='box_button_bottom_right'>

              {this.props.Lio_InspectCode ?
                <Button
                  danger
                  type='primary'
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    this.callApiDeleteRecord()
                  }
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>
                : null
              }
              <Button
                type='primary'
                onClick={() => { this.callApiSaveAndUpdateInspectItem() }}
                icon={<SaveOutlined />}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>

            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1400010_InspectItemMasterChangeSub)
