/**
 * Realize automatic adjustment of visual height of antd <Table />
 * @param {*} extraHeight Any bottom height
 * @param {*} id <Table /> id
 * @return
 */
const resizableTableScroll = (extraHeight = undefined, id = null) => {
  if (typeof extraHeight == "undefined") {
    //   Default bottom 50
    extraHeight = 50
  }
  let tHeader = null
  let tbl = null
  // 画面の高さ
  // let clientHeight = document.documentElement.clientHeight
  if (id) {
    tHeader = document.getElementById(id) ? document.getElementById(id).getElementsByClassName("ant-table-thead")[0] : null
    // tbl = document.getElementById(id) ? document.getElementById(id).getElementsByClassName("ant-table-container")[0] : null
  } else {
    tHeader = document.getElementsByClassName("ant-table-thead")[0]
    // tbl = document.getElementsByClassName("ant-table-container")[0]
  }

  // The distance from the table content to the top
  let tHeaderBottom = 0
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom
  }
  // Formの高さ-テーブルコンテンツの上部の高さ-テーブルコンテンツの下部の高さ
  // let height = document.body.clientHeight - tHeaderBottom - extraHeight
  let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`


  // スクロール必要ない場合、スクロールを非表示にする ※挙動がおかしいので保留
  // if (tbl) {
  //   const preHeight = clientHeight - (tHeaderBottom + extraHeight)
  //   if (preHeight > tbl.clientHeight) {
  //     height = null
  //   }
  // }

  return height
}
export default resizableTableScroll
