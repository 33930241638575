/* eslint-disable eqeqeq */
import React from 'react'
import { PlusCircleOutlined, SearchOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Card, Table, Form, Input, InputNumber, message, Row, Select, Space, Spin, Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WS1133001_HigashifukyoDataSelect from 'pages/KK_ResultOutput/V4CP0044100_EastFukyoConverseProcess/WS1133001_HigashifukyoDataSelect'
import ModalDraggable from 'components/Commons/ModalDraggable'
import EastFukyoInputAction from 'redux/ResultOutput/EastFukyoConverseProcess/EastFukyoInput.action'

class WS1132011_EastFukyoInput extends React.Component {
  static propsType = {
    Li_Date: PropTypes.any,
    Li_Id: PropTypes.any,
    Li_Course: PropTypes.any,
    Li_OutputPattern: PropTypes.any,
    Li_Option: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.office = React.createRef()

    // document.title = '東振協入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      Li_XCoordinate: '',
      Li_YCoordinate: '',
      dataSource: [], //※APIからデータ取得するようになったら削除
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    let params = {
      itemCode: this.props.Li_ItemCode,
      reservation_number: this.props.Li_ReserveNum,
    }
    EastFukyoInputAction.index(params)
      .then((res) => {
        console.log(res);
        this.setState({
          dataSource: res
        })
      })
  }

  settingChange(outPut, record) {
    let params = {
      inspect_cd: record.inspect_cd,
      reservation_number: this.props.Li_ReserveNum,
      result_value: outPut.code,
      inspectionValueResultId: record.inspectionValueResultId,
    }
    console.log(params);
    EastFukyoInputAction.settingChange(params)
      .then((res) => {
        console.log(res);
        this.index()
        this.closeModal()
      })
  }

  /**
   * 東振協コード選択　モーダル
   */
  showWS1133001_HigashifukyoDataSelect = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (
          <WS1133001_HigashifukyoDataSelect
            Li_CmtClassify={record.exam_comment_code}
            onFinishScreen={(outPut) => {
              this.settingChange(outPut, record)
            }}
          />
        ),
      }
    })
  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='higashi-fukyo-data-input' >
        <Card
          title='東振協入力'
        >
          <Form ref={this.formRef} autoComplete='off'>
            <Table
              size='small'
              bordered
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ y: 450 }}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () => {
                  // 東振協コード選択
                  this.showWS1133001_HigashifukyoDataSelect(record)
                }
              })}
            >
              <Table.Column title='格納先' dataIndex='exam_short_name' width={120} />
              <Table.Column title='設定' dataIndex='HigashifukyoCode' width={40} />
              <Table.Column title='表示内容' dataIndex='exam_comment_form' width={120} />
            </Table>
          </Form>

        </Card>


        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1132011_EastFukyoInput)
