//class WS0855010_PrintParamInputChild extends React.Componentに必要
import React from "react";
//export default connect(mapStateToProps, mapDispatchToProps)(WS0855010_PrintParamInputChild);に必要
import { connect } from "react-redux";
import PropTypes from 'prop-types';
//<Card title={'印刷パラメータ保守 明細 備考 [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'}>に必要
import { Card, Form, Table, Button, Modal, Space, Row, Col, Input, Menu, Dropdown, message, Select, InputNumber, TimePicker } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import WS1382001_ImplementorInquiry from './WS1382001_ImplementorInquiry';
import WS1383001_SupportItemInquiry from './WS1383001_SupportItemInquiry';
import WS1388001_ImplementLocationQuery from './WS1388001_ImplementLocationQuery';
import InsureGuideCourseDetailAction from "redux/SpecificInsureMaintenance/InsureGuideCourse/InsureGuideCourseDetail.action";
//ModalDraggable のインポートに必要
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment";

//Form labelの幅
const labelCol = {
  labelCol: { style: { width: '85px' } }
}

class WS1398011_InsureGuideCourseDetailChild extends React.Component {
  formRef = React.createRef();

  //親画面の情報を取ってくる
  constructor(props) {
    super(props);
    //stateにdataSourceParramItemを保持　初期値[]を設定
    //stateに保持させるときは現画面で加工したいとき　propsでとってくるときは、加工せずそのままのデータが欲しいとき
    this.state = {
      dataSource: [],
      selectedRows: [],
      support_time_division_value: null,
      params: {
        Li_CourseCode: this.props.Li_CourseCode
      },
      //子画面を開くのに必要
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  //componentDidMountが初期画面表示処理
  componentDidMount() {
    this.setState({
      //★３ここつける
      support_time_division_value: this.props.support_time_division ?? '',
      rating_division_value: this.props.support_time_division ?? '',
    })

    this.formRef.current.setFieldsValue({
      //連番
      serial_number: this.props.serial_number ?? '',

      //★支援項目
      support: this.props.support ?? '',
      support_code: this.props.support_code ?? '',

      //支援量
      support_price: this.props.support_price ?? '',

      //時期
      support_time: this.props.support_time ?? '',

      //時期区分
      support_time_division: this.props.support_time_division ?? '',

      //時間帯
      start_period_time: moment(this.props.start_period_time, 'HH:mm') ?? '',
      end_period_time: moment(this.props.end_period_time, 'HH:mm') ?? '',

      //評価区分
      rating_division: this.props.rating_division ?? '',

      //実施者
      kanji_name: this.props.kanji_name ?? '',
      implement_prospective_code: this.props.implement_prospective_code ?? '',

      //実施場所
      implement_location_code: this.props.implement_location_code ?? '',
      implement_location: this.props.implement_location ?? '',
    })
  }

  //実施者照会　
  searchImplementProspectiveCode = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS1382001_ImplementorInquiry
            onFinishScreen={(output) => {
              let newDataSource = [...this.state.dataSource]
              // newDataSource[index].implement_prospective_code = output.practitioner_code;
              // newDataSource[index].kanji_name = output.kanji_name;
              this.setState({
                dataSource: [...newDataSource]
              });
              this.formRef.current.setFieldsValue({
                implement_prospective_code: output.practitioner_code,
                //下記いる
                kanji_name: output.kanji_name
              })
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  //支援項目照会
  searchSupportCode = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "90%",
        component: (
          <WS1383001_SupportItemInquiry
            onFinishScreen={(output) => {
              let newDataSource = [...this.state.dataSource]
              // newDataSource[index].support_code = output.support_code;
              // newDataSource[index].support = output.support;
              // newDataSource[index].restriction_info_lowest_value = output.restriction_info_lowest_value;
              // newDataSource[index].restriction_info_highest_value = output.restriction_info_highest_value;
              this.setState({
                dataSource: [...newDataSource]
              });
              this.formRef.current.setFieldsValue({
                support: output.support,
                //下記いらないかも
                support_code: output.support_code,
                restriction_info_lowest_value: output.restriction_info_lowest_value,
                restriction_info_highest_value: output.restriction_info_highest_value
              })

              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  //実施場所照会
  searchImplementLocationCode = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS1388001_ImplementLocationQuery
            onFinishScreen={(output) => {
              console.log(output)
              let newDataSource = [...this.state.dataSource]
              //★implement_location_codeとimplement_locationを取ってくる必要がありそう。
              // newDataSource[index].implement_location_code = output.implement_location_code;
              // newDataSource[index].implement_location = output.implement_location;
              this.setState({
                dataSource: [...newDataSource]
              });
              //ここまねする！！！
              this.formRef.current.setFieldsValue({
                implement_location_code: output.implement_location_code,
                implement_location: output.implement_location
              })
              console.log(output)
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  //★itemChangeがない
  // 削除処理
  Delete(params) {
    Modal.confirm({
      content: `削除を行いますか?`,
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        let output = ({
          // ...this.formRef.current.getFieldsValue(),
          //左：前の画面に持っていく名前　右：実際のデータの名前
          //連番
          serial_number: this.formRef.current.getFieldValue('serial_number'),
          //支援項目
          support: this.formRef.current.getFieldValue('support'),
          support_code: this.formRef.current.getFieldValue('support_code'),
          //支援量
          support_price: this.formRef.current.getFieldValue('support_price'),
          //？？？
          practitioner_code: this.formRef.current.getFieldValue('practitioner_code'),
          //時期
          support_time: this.formRef.current.getFieldValue('support_time'),
          //時期区分
          support_time_division: this.state.support_time_division_value,
          //★時間帯　formatがいる　未完成
          start_period_time: this.formRef.current.getFieldValue('start_period_time').format('HH:mm'),
          end_period_time: this.formRef.current.getFieldValue('end_period_time').format('HH:mm'),
          //評価区分
          rating_division: this.formRef.current.getFieldValue('rating_division'),
          //★実施者
          implement_prospective_code: this.formRef.current.getFieldValue('implement_prospective_code'),
          //★実施場所
          implement_location_code: this.formRef.current.getFieldValue('implement_location_code'),
          implement_location: this.formRef.current.getFieldValue('implement_location'),
        })

        this.props.onDelete(output)
        this.closeModal()
      }
    })

    // InsureGuideCourseDetailAction.deleteData(params.itemChange)
    //   .then(async (res) => {
    //     // ポイント計算処理
    //     await InsureGuideCourseDetailAction.pointCalculate(params.itemChange)
    //     await this.loadData(this.state.params);
    //     await this.closeModal();
    //     if (this.props.onScreenUpdate) {
    //       this.props.onScreenUpdate()
    //     }
    //   })
  }

  // 変更処理
  onSave(params) {
    let output = ({
      //左：前の画面に持っていく名前　右：実際のデータの名前
      //連番
      serial_number: this.formRef.current.getFieldValue('serial_number'),

      //支援項目
      support: this.formRef.current.getFieldValue('support'),
      support_code: this.formRef.current.getFieldValue('support_code'),

      //支援量
      support_price: this.formRef.current.getFieldValue('support_price'),

      //？？？
      practitioner_code: this.formRef.current.getFieldValue('practitioner_code'),

      //時期
      support_time: this.formRef.current.getFieldValue('support_time'),

      //時期区分
      support_time_division: this.state.support_time_division_value,

      //★時間帯　formatがいる　未完成
      start_period_time: this.formRef.current.getFieldValue('start_period_time').format('HH:mm'),
      end_period_time: this.formRef.current.getFieldValue('end_period_time').format('HH:mm'),

      //評価区分
      rating_division: this.formRef.current.getFieldValue('rating_division'),

      //★実施者
      implement_prospective_code: this.formRef.current.getFieldValue('implement_prospective_code'),

      //★実施場所
      implement_location_code: this.formRef.current.getFieldValue('implement_location_code'),
      implement_location: this.formRef.current.getFieldValue('implement_location'),
    })

    console.log(output)

    this.props.onFinishScreen(output)
    // InsureGuideCourseDetailAction.postData(params.itemChange)
    //   .then(async (res) => {
    //     // ポイント計算処理
    //     await InsureGuideCourseDetailAction.pointCalculate(params.itemChange)
    //     await this.loadData(this.state.params);
    //     await this.closeModal();
    //     if (this.props.onScreenUpdate) {
    //       this.props.onScreenUpdate()
    //     }
    //   })
  }

  // 新規追加処理
  onAdd(params) {
    let data = params.itemChange;
    data['insure_guide_course_code'] = this.state.params.Li_CourseCode;
    InsureGuideCourseDetailAction.addData(params.itemChange)
      .then(async (res) => {
        // ポイント計算処理
        await InsureGuideCourseDetailAction.pointCalculate(params.itemChange)
        await this.loadData(this.state.params);
        await this.closeModal();
        if (this.props.onScreenUpdate) {
          this.props.onScreenUpdate()
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      // ファイル名を小文字にして-でつなげたもの
      <div
        className="insure-guide-course-detail-child"
        style={{ width: '100%' }}
      >

        {/* 開けたときの最初の画面を定義する */}
        <Card title={'保健指導コース明細 [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'} stye={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            autoComplete="off"
          >
            <div className="box_container">
              <Form.Item
                label="連番"
                name="serial_number"
                {...labelCol}
                rules={[{ required: true, message: '半角整数で入力してください' }]}
              >
                <Input
                  type='number'
                />
              </Form.Item>

              {/* 虫眼鏡の横に文字を出す。 */}
              <Form.Item
                label="支援項目"
                name="support"
                {...labelCol}
              >
                <Input.Search
                  readOnly
                  onSearch={() => {
                    this.searchSupportCode()
                  }}
                />
              </Form.Item>

              {/* 〇数字入力のみ */}
              <Form.Item
                label="支援量"
                name="support_price"
                {...labelCol}
                rules={[{ required: true, message: '半角整数で入力してください' }]}
              >
                <Input
                  type='number'
                />
              </Form.Item>

              {/* ★ポイントが正しく計算されていない気がする。 */}
              {/* <Form.Item
                label="ポイント"
                name="support_unit_info_per_unit_point"
                {...labelCol}
                render={(value, record) => {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {record.rating_division_code == 3 || record.rating_division_code == 4 ? value : null}
                    </div>
                  )
                }}
              >
                <Input />
              </Form.Item> */}

              {/* 〇数字入力のみ */}
              <Form.Item
                label="時期"
                name="support_time"
                {...labelCol}
                rules={[{ required: true, message: '少数第１位までの数値を入力してください' }]}
              >
                <InputNumber
                  step='0.1'
                />
              </Form.Item>

              <Form.Item
                label="時期区分"
                name="support_time_division"
                {...labelCol}
              >
                <Select
                  //★２ここつける
                  onChange={value => this.setState({
                    support_time_division_value: value
                  })}
                >
                  <Select.Option value={1}>日後</Select.Option>
                  <Select.Option value={2}>週間後</Select.Option>
                  <Select.Option value={3}>ヶ月後</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="評価区分"
                name="rating_division"
                {...labelCol}
              >
                <Select
                  onChange={value => this.setState({
                    rating_division_value: value
                  })}
                >
                  <Select.Option value={1}>計画</Select.Option>
                  <Select.Option value={0}>初回①</Select.Option>
                  <Select.Option value={2}>初期面談</Select.Option>
                  <Select.Option value={3}>中間評価</Select.Option>
                  <Select.Option value={4}>継続中</Select.Option>
                  <Select.Option value={9}>終了評価</Select.Option>
                </Select>
              </Form.Item>

              {/* ★時間のフォーマットにする！！ */}
              <Form.Item
                label="開始時間帯"
                name="start_period_time"
                {...labelCol}
              >
                <TimePicker
                  format='HH:mm'
                />
              </Form.Item>

              <Form.Item
                label="終了時間帯"
                name="end_period_time"
                {...labelCol}
              >
                <TimePicker
                  format='HH:mm'
                />
              </Form.Item>

              {/* ★虫眼鏡でテーブルが開くようにする！！　選択ができない。 */}
              <Form.Item
                label="実施者"
                name="kanji_name"
                {...labelCol}
              >
                <Input.Search
                  readOnly
                  onSearch={() => {
                    this.searchImplementProspectiveCode()
                  }}
                />
              </Form.Item>

              {/* ★虫眼鏡の横に文字を出す。 */}
              <Form.Item
                label="実施場所"
                name="implement_location"
                {...labelCol}
              >
                <Input.Search
                  readOnly
                  onSearch={() => {
                    this.searchImplementLocationCode()
                  }}
                />
              </Form.Item>
            </div>

            <div className="box_button_bottom_right">
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag) ? 'none' : '' }}
                onClick={(params) => {
                  this.Delete(params)
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                //outputを追加した。→ローディングはかかるようになった。paramsか？
                onClick={(output) => {
                  this.onSave(output)
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>



        {/* /* 子画面に必要 */}
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}

//以下画面の新規作成に必要な行
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

//ファイル名に書き換える
export default connect(mapStateToProps, mapDispatchToProps)(WS1398011_InsureGuideCourseDetailChild);
