/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Input, Select, Button, Radio, Modal, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import ModalDraggable from 'components/Commons/ModalDraggable'
import MedicalCheckupCalendarAction from 'redux/Others/MedicalCheckupCalendar/MedicalCheckupCalendar.action'

class CheckupCalendarPresentationHistory extends React.Component {
  static propTypes = {
    Li_ReserveNum: PropTypes.any,
    Li_Data: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.KanaName = React.createRef()
    // document.title = '提示'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      deleteFlag: true,
      KanaName: '',
      Dt0320Id: '',
      RelationshipList: [],
      userList: [],
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  index = () => {
    let param = {
      ReserveNum: this.props.ReserveNum ?? '',
      Date: this.props.Date ?? '',
      personal_number_id: this.props.personalNumberId ?? '',
      KanjiName: this.props.KanjiName ?? '',
    }

    MedicalCheckupCalendarAction.getCalendarPresentationHistory(param)
      .then((res) => {
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='application-info-register'>
        <Card title={'提示：履歴'}>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ y: 500 }}
          >
            <Table.Column
              title='受診日'
              dataIndex='visit_data_on'
              width={110}
            />

            <Table.Column
              title='状態'
              dataIndex='state'
              width={110}
            />

            <Table.Column
              title='契約'
              dataIndex='contractType'

            />

            <Table.Column
              title='提示'
              dataIndex='presentation'
              width={200}
            />

          </Table>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckupCalendarPresentationHistory)
