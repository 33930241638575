import axios from "configs/axios";

const API_LIST = {
  getXmlContents:
    "/api/tojun-capture-radiological-findings/capture-radiological-findings/get-xml-contents",
};

const CaptureRadiologicalFindingsService = {
  async getXmlContents() {
    return axios.get(API_LIST.getXmlContents);
  },
};

export default CaptureRadiologicalFindingsService;