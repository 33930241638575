import React from "react";
import { connect } from "react-redux";

import { Button, Card, Table, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1318005_SerialNumberRef extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '連番参照';

    this.state = {
      dataSource: [],
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if(this.props.Li_ContractYear !== prevProps.Li_ContractYear) {
      this.getScreenData();
    }
  }

  getScreenData() {
    const {Li_ContractYear} = this.props;
    VenusApiRoutesV2.callApi("API001318005002", {Li_ContractYear}).then(res => this.setState({dataSource: res}));
  }

  select(record) {
    if(this.props.onFinishScreen) {
      this.props.onFinishScreen(record);
    }
  }

  render() {
    return (
      <div className="serial-number-ref">
        <Card title="連番参照">
          <Table bordered 
            dataSource={this.state.dataSource}
            pagination={this.state.pagination}
            rowKey={(record) => record.id}
          >
            <Table.Column title="内部番号" dataIndex="serial_number" 
              showSorterTooltip={false}
              sorter={(a,b) => a.serial_number - b.serial_number}
            />
            <Table.Column title="契約番号" dataIndex="contract_number"
              showSorterTooltip={false}
              sorter={(a,b) => a.contract_number.localeCompare(b.contract_number)}
            />
            <Table.Column title="契  約  名  称" dataIndex="contract_name"
              showSorterTooltip={false}
              sorter={(a,b) => a.contract_name.localeCompare(b.contract_name, "jp")}
            />
            <Table.Column title="契約形態" dataIndex="contract_type"
              showSorterTooltip={false}
              sorter={(a,b) => +a.contract_type - +b.contract_type}
              render={(value, record, index) => (
                <>{value == "1" ? "個別契約" :"集合契約"}</>
              )}
            />
            <Table.Column title="" render={(value, record, index) => {
              return (
                <Button type="primary" onClick={() => this.select(record)}>選択</Button>
              )
            }} />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1318005_SerialNumberRef);
