import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Modal } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import MemoFunctionAction from 'redux/Others/ReserveStatusSearch/MemoFunction.action.js'
import { SaveOutlined } from '@ant-design/icons'

const { TextArea } = Input

class MemoEditDisplay extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = 'メモ編集画面'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      memoList: [],
      date: '',
      reservation_number: '',
      personal_number_id: '',
      type: '',
      serial_number: '',
      newFlag: 0
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({ memo: this.props.memo ?? '' })
    this.setState({
      reservation_number: this.props.reservation_number ?? '',
      personal_number_id: this.props.personal_number_id ?? '',
      type: this.props.type ?? '',
      serial_number: this.props.serial_number ?? '',
      newFlag: this.props.newFlag
    })
  }

  saveMemo = () => {
    let params = {
      reservation_number: this.state.reservation_number,
      personal_number_id: this.state.personal_number_id,
      type: this.state.type,
      serial_number: this.state.serial_number,
      memo: this.formRef.current?.getFieldValue('memo'),
      newFlag: this.state.newFlag
    }

    MemoFunctionAction.saveMemo(params)
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  deleteMemo = () => {
    let params = {
      reservation_number: this.state.reservation_number,
      personal_number_id: this.state.personal_number_id,
      type: this.state.type,
      serial_number: this.state.serial_number,
    }
    MemoFunctionAction.deleteMemo(params)
      .then(res => {
        this.props.onFinishScreen()
      })
  }

  render() {
    return (
      <div className='memo-edit-display' style={{ width: 600 }}>
        <Card title={this.props.personal_number_id + '　' + this.props.KanjiName}>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Form.Item name='memo'>
                <TextArea
                  style={{ height: 200 }}
                />
              </Form.Item>

              <div className='box_button_bottom_right'>
                <Button
                  type='danger'
                  onClick={() => {
                    Modal.confirm({
                      content: '削除を行いますか？',
                      onOk: () => { this.deleteMemo() }
                    })
                  }}
                >
                  <span className='btn_label'>
                    削除
                  </span>
                </Button>

                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => this.saveMemo()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MemoEditDisplay)
