import React from 'react'
import PropTypes from 'prop-types'
import { Card, message, Table } from 'antd'
import moment from 'moment'
import HistorySwitchingAction from 'redux/basicInfo/ContractInfoMaintain/HistorySwitching.action'

class WS0307071_HistorySwitching extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,

    onSelected: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // // document.title = '契約年度'

    this.state = {
      dataSource: [],
    }

    this.index = this.index.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 親からのパラメータにて取得するデータを切替
    if ((this.props.Li_ContractType ?? false) && (this.props.Li_ContractOrgCode ?? false)) {
      this.index()
    } else {
      this.getDataAll()
    }
  }

  /**
   * データ取得（ContractTypeとContractOrgCodeに紐づく）
   */
  index() {
    console.log(this.props);
    const { Li_ContractType, Li_ContractOrgCode } = this.props
    let params = {
      Li_ContractType: Li_ContractType,
      Li_ContractOrgCode: Li_ContractOrgCode
    }
    HistorySwitchingAction.index(params)
      .then((res) => {
        this.setState({
          dataSource: res.data,
        })
      })
  }

  /**
   * データ取得（全ての契約を基に）
   */
  getDataAll() {
    HistorySwitchingAction.getDataAll()
      .then((res) => {
        if (res.data) {
          this.setState({
            dataSource: res.data,
          })
        }
      })
  }

  render() {
    return (
      <div className='history-switching'>
        <Card title='契約年度'>
          <Table
            size='small'
            bordered
            dataSource={this.state.dataSource}
            rowKey={record => record.id}
            pagination={false}
            scroll={{ y: 400 }}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  if (this.props.onSelected) {
                    this.props.onSelected({
                      Lio_Date: record.contract_start_date_on,
                      recordData: record,
                    })
                  }
                }
              }
            }}
          >
            <Table.Column
              title='契約開始日'
              dataIndex='contract_start_date_on'
              render={value => (
                moment(value).isValid() ? moment(value).format('YYYY/MM/DD') : value?.replace(/-/g, '/')
              )} />
            <Table.Column
              title='契約年度'
              dataIndex='contract_name'
            />
          </Table>
        </Card>
      </div>
    )
  }
}

export default WS0307071_HistorySwitching
