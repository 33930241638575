import CautionGuideNotesSearchQueryService from 'services/InspectionMaintenance/InspectItemJudgeValueSetting/CautionGuideNotesSearchQueryService'
import { message } from 'antd'
const CautionGuideNotesSearchQueryAction = {
  index(params) {
    return CautionGuideNotesSearchQueryService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  getGuideItemInfo(params) {
    return CautionGuideNotesSearchQueryService.getGuideItemInfo(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
  Calculation(params) {
    return CautionGuideNotesSearchQueryService.Calculation(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },
}
export default CautionGuideNotesSearchQueryAction
