import React from "react";
import { connect } from "react-redux";
import {
  Form, Button, Card, Row, Col, message, Space
} from 'antd';
import moment from "moment";
import ModalDraggable from "components/Commons/ModalDraggable";
import EmaillnquiryAction from 'redux/MN_Basis/Emaillnquiry/Emaillnquiry.action';
import WS2520001_SendEmail from './WS2520001_SendEmail'

const emaildetailsObject = {
  id: '',
  date_on: '',
  time_at: '',
  transmission_user_code: '',
  visit_user_code: '',
  title: '',
  content: ''
}
class WS2521001_Emaillnquiry extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '詳細';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      emaildetails: emaildetailsObject,
      date: '日付',
      time: '時間',
      transmission: '送信者'
    };
  }


  /**
   * 初期設定
   */
  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let param = {
      Li_EmailId: (this.props.selectEmail === undefined) ? this.props.selectHistoryId : this.props.selectEmail
    }
    EmaillnquiryAction.getScreenData(param)
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            emaildetails: emaildetailsObject
          })
        } else {
          this.setState({
            emaildetails: res
          })
          this.formRef.current?.setFieldsValue({
            date_on: res.date_on ? res.date_on : [],
            time_at: res.time_at ? res.time_at : [],
            transmission_user_code: res.transmission_user_code ? res.transmission_user_code : [],
            title: res.title ? res.title : [],
            content: res.content ? res.content : []
          });
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  closeModal = () => {
    if (this.props.selectHistoryId) {
      this.props.getScreenData();
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: false,
          component: null,
        },
      });
    } else if(this.props.selectEmail){
      this.props.index();
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: false,
          component: null,
        },
      });
    }
  }

  render() {

    return (
      <div className="emaillnquiry">
        <Card title={'メール詳細'}>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div name="emaildetails" style={{ padding: '10px' }} >
              <Row gutter={[10, 10]}>
                <Form.Item style={{ width: '25%' }} label="日付" name="date_on" >
                  <div>{moment(this.state.emaildetails.date_on).format('YYYY/MM/DD')}</div>
                </Form.Item>
                <Form.Item style={{ width: '25%' }} label="時間" name="time_at" >
                  <div>{this.state.emaildetails.time_at}</div>
                </Form.Item>
                <Form.Item style={{ width: '25%' }} label="送信者" name="transmission_user_code" >
                  <div>{this.state.emaildetails.transmission_user_code}</div>
                </Form.Item>
                <Form.Item style={{ width: '100%' }} label="件名" name="title" >
                  <div>{this.state.emaildetails.title}</div>
                </Form.Item>
                <Form.Item style={{ width: '100%' }} label="内容" name="content" >
                  <div>{this.state.emaildetails.content}</div>
                </Form.Item>

                <Col span={24} style={{ textAlign: 'right' }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              <WS2520001_SendEmail
                                Adress={this.state.emaildetails.transmission_user_code}
                                title={this.state.emaildetails.title}
                                content={this.state.emaildetails.content}
                                onFinishScreen={() => {
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    >
                      {'返信'}
                    </Button>
                  </Space>
                </Col>

              </Row>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2521001_Emaillnquiry);
