import React from 'react'
import { connect } from 'react-redux'

import { Card, Form, Input, Select, Checkbox, Table, Modal, Dropdown, Menu, Button, message, } from 'antd'
import { MoreOutlined, QuestionCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons'
import WS2700099_InputForm from 'pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS2700099_InputForm.jsx'
import WS1036001_InspectValueConvertSub from 'pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1036001_InspectValueConvertSub.jsx'
import WS1035001_QuantitativeQualitativeTransformationSub from 'pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1035001_QuantitativeQualitativeTransformationSub.jsx'
import InspectItemConvertInternalService from 'services/CooperationRelated/InspectItemConvertInternal/InspectItemConvertInternalService.js'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS2715042_InspectTakingPartialConvertSub from './WS2715042_InspectTakingPartialConvertSub'
import WS1039001_InspectNameConvertInMaintainTa from './WS1039001_InspectNameConvertInMaintainTa'
import WS1038001_InspectCmtConvertInternal from './WS1038001_InspectCmtConvertInternal'
import WS1041001_ExternalInspectAmountOfQualitativeTransformation from './WS1041001_ExternalInspectAmountOfQualitativeTransformation'
import WS1043001_InspectRequestConvertMasterMaintain from '../V4CP0002000_InspectRequestConvertMasterMaintain/WS1043001_InspectRequestConvertMasterMaintain'
import WS1040001_InspectValueConvertInternal from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1040001_InspectValueConvertInternal.jsx";

const labelStyle = { span: 4 }

class WS2700017_InspectItemConvertInternal extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査項目変換(内部)'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      isLoadingSubTable1: false,
      isLoadingSubTable2: false,
      dataSource: []
    }
  }

  componentDidMount = () => {
    this.searchInspectItemConverseSub()
  }

  searchInspectItemConverseSub = () => {
    const {
      SearchChar,
      ExternalInspectCodeF,
      ExternalInspectCodeT,
      InspectValueConvertDivision,
      StsInspectValue,
      StsQuantitativeQualitative,
      MedicalExamSet,
    } = this.formRef.current.getFieldsValue()
    InspectItemConvertInternalService.getInspectItemConverseSubService({
      SearchChar: SearchChar,
      ExternalInspectCodeF: ExternalInspectCodeF,
      ExternalInspectCodeT: ExternalInspectCodeT,
      InspectValueConvertDivision: InspectValueConvertDivision,
      StsInspectValue: StsInspectValue,
      StsQuantitativeQualitative: StsQuantitativeQualitative,
      MedicalExamSet: MedicalExamSet,
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          InspectItemConvertInternalDisplayList: res.data,
        })
        this.setState({
          dataSource: res.data,
        })
        // this.searchInspectValueConverse(
        //   res.data[0].item_code_external,
        //   res.data[0].exam_name
        // )
        // this.searchQuantityQuality(res.data[0].item_code_external)
        // this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
  searchInspectValueConverse = (item_code_external, exam_name) => {
    this.setState({ isLoadingSubTable1: true })
    InspectItemConvertInternalService.getInspectValueConverseService({
      item_code_external: item_code_external,
      exam_name: exam_name,
    })
      .then((res) => {
        this.setState({ isLoadingSubTable1: false })
        this.formRef.current.setFieldsValue({
          InspectValueConverseDisplayList: res.data,
        })

        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
        this.setState({ isLoadingSubTable1: false })
      })
  }

  searchQuantityQuality = (item_code_external) => {
    this.setState({ isLoadingSubTable2: true })
    InspectItemConvertInternalService.getQuantityQualityService({
      item_code_external: item_code_external,
    })
      .then((res) => {
        this.setState({ isLoadingSubTable2: false })
        this.formRef.current.setFieldsValue({
          QuantityQualityDisplayList: res.data,
        })
        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
        this.setState({ isLoadingSubTable2: false })
      })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  deleteRecord(record) {
    let params = {
      id: record.id,
      item_code_external: record.item_code_external
    }
    InspectItemConvertInternalService.deleteRecord(params)
      .then((res) => {
        this.searchInspectItemConverseSub()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='inspect-item-convert-internal p-td'>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete='off'
          initialValues={{
            SearchChar: '',
            ExternalInspectCodeF: 0,
            ExternalInspectCodeT: 9999999,
            InspectValueConvertDivision: '',
            StsInspectValue: false,
            StsQuantitativeQualitative: false,
            MedicalExamSet: false,
          }}
        >
          <Card title='検査項目変換マスタ' className='mb-2'>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <Form.Item
                      name='SearchChar'
                      label='検索'
                      labelCol={labelStyle}
                      style={{ marginRight: 20 }}
                    >
                      <Input
                        className='input-size-40'
                      />
                    </Form.Item>
                    <Form.Item
                      label='外部検査'
                      labelCol={labelStyle}
                      style={{ marginRight: 20, marginBottom: 0 }}
                    >
                      <div className='box_inner_horizontal'>
                        <Form.Item
                          name='ExternalInspectCodeF'
                        >
                          <Input
                            className='input-search-size-number-allow-clear-8'
                          />
                        </Form.Item>
                        <Form.Item
                          name='ExternalInspectCodeT'
                          label='~'
                        >
                          <Input
                            className='input-search-size-number-allow-clear-8'
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </div>
                  <div className='box_search_inner'>
                    <Form.Item
                      name='InspectValueConvertDivision'
                      label='変換'
                      // labelCol={labelStyle}
                      style={{ marginRight: 10 }}
                    >
                      <Select
                        style={{ width: 200 }}
                      >
                        <Select.Option value='0'> </Select.Option>
                        <Select.Option value='1'>変換無し</Select.Option>
                        <Select.Option value='2'>桁数変換</Select.Option>
                        <Select.Option value='3'>ﾃ-ﾌﾞﾙ変換</Select.Option>
                      </Select>
                    </Form.Item>
                    <div className='box_inner_horizontal' style={{ marginTop: 5 }}>
                      <Form.Item name='StsInspectValue' valuePropName='checked' style={{ marginBottom: 7 }}>
                        <Checkbox
                        // onChange={() => this.searchInspectItemConverseSub()}
                        >
                          検査値変換あり
                        </Checkbox>
                      </Form.Item>

                      <Form.Item name='StsQuantitativeQualitative' valuePropName='checked' style={{ marginBottom: 7 }} >
                        <Checkbox
                        // onChange={() => this.searchInspectItemConverseSub()}
                        >
                          定量定性変換あり
                        </Checkbox>
                      </Form.Item>

                      <Form.Item name='MedicalExamSet' valuePropName='checked' style={{ marginBottom: 7 }}>
                        <Checkbox
                        // onChange={() => this.searchInspectItemConverseSub()}
                        >
                          紐付
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => this.searchInspectItemConverseSub()}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <div className="box_inner_horizontal">
                <div style={{ minWidth: '65%' }} >
                  <Table
                    size='small'
                    bordered={true}
                    rowKey={(record) => record.id}
                    scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                    dataSource={this.formRef.current ? this.formRef.current.getFieldValue('InspectItemConvertInternalDisplayList') : []}
                    pagination={false}
                    // pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                    rowClassName={(record, index) => {
                      if (this.state.selectedRowRecordLeftTable) {
                        return record.id === this.state.selectedRowRecordLeftTable.id ? 'ant-table-row-selected'
                          :
                          ''
                      }
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 'fit-content',
                              component: (
                                <WS2700099_InputForm
                                  Li_InspectCodeOut={record.item_code_external}
                                  onFinishScreen={() => {
                                    this.searchInspectItemConverseSub()
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }
                      }
                    }}
                  >

                    <Table.Column
                      title='外部検査'
                      dataIndex='item_code_external'
                      style={{ marginLeft: '10px' }}
                      render={(text) => <div className='floatRight'>{text}</div>}
                    />

                    <Table.Column
                      title='外部検査名称'
                      dataIndex='exam_name'
                    />

                    <Table.Column
                      title='タイプ'
                      dataIndex='exam_type_outside'
                      render={(text, record, index) => {
                        let type = '';
                        switch (text) {
                          case 'N':
                            type = '整 数'
                            break;
                          case 'N1':
                            type = '小 数1'
                            break;
                          case 'N2':
                            type = '小 数2'
                            break;
                          case 'N3':
                            type = '小 数3'
                            break;
                          case 'N4':
                            type = '小 数4'
                            break;
                          case 'X':
                            type = '文 字'
                            break;
                        }

                        return (
                          <div style={{ textAlign: 'center' }}>{type}</div>
                        )
                      }}
                    />

                    <Table.Column
                      title='健診検査'
                      dataIndex='item_code_medical_exam'
                      render={(text) => <div className='floatRight'>{text}</div>}
                    />

                    <Table.Column
                      title='健診検査名称'
                      dataIndex='Expression_31'
                      render={(text, record, index) => (
                        <span>
                          {text}{'　'}{record.exam_short_name}{'　'}
                          {record.inspect_exam_name}
                        </span>
                      )}
                    />

                    <Table.Column
                      title='変換'
                      dataIndex='exam_value_conversion_division'
                      render={(text, record, index) => {
                        let conversionDivision = '';
                        switch (text) {
                          case 0:
                            conversionDivision = ''
                            break;
                          case 1:
                            conversionDivision = '変換なし'
                            break;
                          case 2:
                            conversionDivision = '桁変換'
                            break;
                          case 3:
                            conversionDivision = 'ﾃｰﾌﾞﾙ変換'
                            break;
                        }
                        return (
                          <div>{conversionDivision}</div>
                        )
                      }}
                    />

                    <Table.Column
                      title='桁数変換'
                      dataIndex='ConvertCoefficientChar2'
                    />

                    <Table.Column
                      width={40}
                      title={
                        //新規登録のボタン
                        <Button
                          size='small'
                          type='primary'
                          style={{ textAlign: 'center' }}
                          icon={<PlusOutlined />}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 'fit-content',
                                className: '',
                                component:
                                  <WS2700099_InputForm
                                    onFinishScreen={(value) => {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                              },
                            })
                          }}
                        ></Button>
                      }
                      render={(text, record, index) => (
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='1'
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 'fit-content',
                                      component: (
                                        <WS2700099_InputForm
                                          Li_InspectCodeOut={record.item_code_external}
                                          onFinishScreen={(value) => {
                                            this.searchInspectItemConverseSub()
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            })
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }}
                              >
                                <label>修正</label>
                              </Menu.Item>
                              <Menu.Item
                                key='2'
                                onClick={() => {
                                  Modal.confirm({
                                    title: '消去してもよろしいですか？',
                                    width: 315,
                                    icon: <QuestionCircleOutlined style={{ fontSize: 25, color: '#08c' }} />,
                                    onOk: () => {
                                      this.deleteRecord(record)
                                    }
                                  })
                                }}
                              >
                                <label>削除</label>
                              </Menu.Item>
                              <Menu.Item
                                key='3'
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '30%',
                                      component: (
                                        <WS1036001_InspectValueConvertSub
                                          Li_ExternalCode={record.item_code_external}
                                          Li_InspectName={record.inspect_exam_name}
                                          onFinishScreen={() => {

                                            this.searchInspectItemConverseSub()

                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            })
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }}
                              >
                                <label>検査値変換</label>
                              </Menu.Item>
                              <Menu.Item
                                key='4'
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: '90%',
                                      component: (
                                        <WS1035001_QuantitativeQualitativeTransformationSub
                                          Li_ExternalCode={record.item_code_external}
                                          Li_InternalInspectCode={record.item_code_medical_exam}
                                          Li_Name={record.exam_name}
                                          onFinishScreen={() => {
                                            this.setState({
                                              childModal: {
                                                ...this.state.childModal,
                                                visible: false,
                                              },
                                            })
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }}
                              >
                                <label>定量定性変換</label>
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />} />
                        </Dropdown>
                      )}
                    />
                  </Table>
                </div>
              </div>

              {/* Footer */}
              <div className='box_button_bottom_right' style={{ marginTop: 0 }}>
                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 'fit-content',
                        component: <WS2715042_InspectTakingPartialConvertSub />,
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    部分変換
                  </span>
                </Button>

                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '800px',
                        component: <WS1039001_InspectNameConvertInMaintainTa />,
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    名称変換
                  </span>
                </Button>

                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '600px',
                        component: <WS1038001_InspectCmtConvertInternal />,
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    コメント変換
                  </span>
                </Button>

                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '17%',
                        component:
                          <WS1040001_InspectValueConvertInternal />
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    検査値
                  </span>
                </Button>

                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '80%',
                        component: (
                          <WS1041001_ExternalInspectAmountOfQualitativeTransformation />
                        ),
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    定量定性
                  </span>
                </Button>

                <Button
                  type='primary'
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '80%',
                        component: <WS1043001_InspectRequestConvertMasterMaintain />,
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    依頼変換
                  </span>
                </Button>
              </div>
            </div>
          </Card>
        </Form>

        <ModalDraggable
          destroyOnClose={true}
          width={this.state.childModal.width}
          height={'800px'}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2700017_InspectItemConvertInternal)
