import axios from 'configs/axios'

const API_LIST = {
  index: '/api/introduce-letter-extract/inspect-inquiry',
}

const InspectInquiryService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default InspectInquiryService
