import axios from 'configs/axios'
const apiPaths = {
  getScreenData: '/api/printer-info-maintain/printer-info-maintain/get-screen-data',
  printerInfoMaintenanceSub: '/api/printer-info-maintain/printer-info-maintain/printer-info-maintenance-sub',
}
const PrinterInfoMaintainService = {
  async getScreenData(params) {
    return axios.get(apiPaths.getScreenData, { params })
  },
  async printerInfoMaintenanceSub(params) {
    return axios.get(apiPaths.printerInfoMaintenanceSub, { params })
  },
}

export default PrinterInfoMaintainService
