import React from 'react';
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Select, message, InputNumber } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

const labelCol = {
  labelCol: { style: { width: '100px' } }
}

class ConnectHostsSettingEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    // document.title = 'ホスト接続設定 新規・編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }
  componentDidMount() {
    // 親画面のrecordをコピー
    const data = {
      ...this.props.record,
    }
    // Form設定
    this.formRef.current?.setFieldsValue(data)
  }

  /**
  * 保存
  */
  save = () => {
    const values = this.formRef.current?.getFieldsValue()

    if (!values.ProgramId) {
      message.error('ProgramIDを入力してください')
      return
    } else {
      let Id = null

      if (!this.props.newFlag) {
        Id = this.props.record.Id
      }

      let param = {
        Id,
        ...values
      }

      this.props.onUpdate(param)
    }
  }

  /**
  * 削除
  */
  delete = () => {
    this.props.onDelete()
  }

  render() {
    return (
      <div className='Connect-Hosts-Setting-Edit'>
        <Card title={'ホスト接続設定 [' + (this.props.newFlag ? '新規' : '変更') + ']'}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='ProgramID'
                name='ProgramId'
                {...labelCol}
              >
                <Select>
                  <Select.Option value={'backup01000'}>backup01000</Select.Option>
                  <Select.Option value={'backup99999'}>backup99999</Select.Option>
                  <Select.Option value={'EMAIL'}>EMAIL</Select.Option>
                  <Select.Option value={'MDV01000'}>MDV01000</Select.Option>
                  <Select.Option value={'Mirais'}>Mirais</Select.Option>
                  <Select.Option value={'MIRAI01000'}>MIRAI01000</Select.Option>
                  <Select.Option value={'InspectionImport'}>InspectionImport</Select.Option>
                  <Select.Option value={'patientInfo'}>PatientInfo</Select.Option>
                  <Select.Option value={'ORDER01000'}>ORDER01000</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label='IPアドレス'
                name='IpAddress'
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='ポート番号'
                name='Port'
                {...labelCol}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label='ユーザー名'
                name='UserName'
                {...labelCol}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='パスワード'
                name='Password'
                {...labelCol}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label='Key'
                name='Key'
                {...labelCol}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label='ディレクトリ先'
                name='Directory'
                {...labelCol}
              >
                <Input />
              </Form.Item>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.newFlag ? 'none' : '') }} //新規追加の場合、削除ボタンは非表示
                onClick={() => {
                  // 削除
                  this.delete()
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  // 保存
                  this.save()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}


const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectHostsSettingEdit);
