import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Row, Col, Space, message, Popconfirm } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
// import WS2698172_PrinterSelect from "pages/SM_SystemMaintenance/V4SM0021000_PrinterInfoMaintain/WS2698172_PrinterSelect.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import PrinterInfoInputAction from "redux/SM_SystemMaintenance/PrinterInfoMaintain/PrinterInfoInput.action"
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS2698165_PrinterInfoInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'プリンタ情報入力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      Printer: '',
      UserSettingPrinterName: '',
      Prn: '',
      initialPrinterNumber: ''
    };
  }

  componentDidMount = () => {
    if (!this.props.new_Flag) {
      this.setState({
        initialPrinterNumber: this.props.Li_PrinterNumber
      })
    }
    this.index();
  }

  index() {
    const params = {
      Li_Printer: this.props.Li_PrinterNumber,
    }

    // VenusApiRoutesV2.callApi("API002698165001", params)
    PrinterInfoInputAction.getScreenData(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          Printer: res.printer_number,
          UserSettingPrinterName: res.printer_name,
          Prn: res.Prn,
        })
      })
  }

  save() {
    const params = {
      Li_Printer: this.formRef.current?.getFieldValue('Printer'),
      printer_name: this.formRef.current?.getFieldValue('UserSettingPrinterName'),
      Prn: this.formRef.current?.getFieldValue('Prn'),
      New_Flag: this.props.new_Flag,
      initialPrinterNumber: this.state.initialPrinterNumber
    }

    PrinterInfoInputAction.save(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  delete() {
    const params = {
      Li_Printer: this.formRef.current?.getFieldValue('Printer')
    }

    PrinterInfoInputAction.delete(params)
      .then(res => {
        this.props.onFinishScreen()
      })
      .catch(error => {

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  onFinish(values) {
    // let params = this.formRef.current?.getFieldsValue()

    // VenusApiRoutesV2.callApi("API002698165003", params)
    // .then((res) => {
    //   console.log(res)
    // }).finally(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
      <div className="printer-info-input" style={{ width: 500 }}>
        <Card
          title={'プリンタ情報' + ((this.props.new_Flag ? false : true) ? '修正' : '入力')}
          className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row style={{ width: '100%' }}>
              <Col span={20}>
                <Form.Item
                  name="Printer"
                  label="プリンターNo"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Input
                    type="text"
                  // disabled={this.props.new_Flag ? false : true}
                  // readOnly={this.props.new_Flag ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                <Form.Item
                  name="UserSettingPrinterName"
                  label="表示名称"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={20} textalign="right">
                <Form.Item
                  name="Prn"
                  label="プリンター正式名"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
              {/* <Col span={4}>
                <Button type="primary"
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "40%",
                        component: (
                          <WS2698172_PrinterSelect
                            onFinishScreen={(obj) => {
                              this.closeModal();
                            }}
                          />
                        ),
                      },
                    });
                  }}
                >参照</Button>
              </Col> */}
            </Row>
            <Row>

              <div className='box_button_bottom_right'>

                {!this.props.new_Flag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    onConfirm={() => this.delete()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type='primary'
                      icon={<DeleteOutlined />}
                    >
                      <span className='btn_label'>
                        削除
                      </span>
                    </Button>
                  </Popconfirm> : null}

                <Button
                  type='primary'
                  onClick={() => { this.save() }}
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>

              {/* <Col span={24} style={{ textAlign: "right", marginTop: "2em" }}>
                <Space>
                  <Button type="primary" onClick={() => this.save()}>登録</Button>
                </Space>
              </Col> */}
            </Row>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2698165_PrinterInfoInput);
