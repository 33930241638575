import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Form, Modal, Button, Input, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import WS0923002_DepartmentspecificFormat from 'pages/SK_IntroductionLetter/V4SK0010010_CourseDepartmentspecificFormat/WS0923002_DepartmentspecificFormat.jsx'
import WS0923003_CourseEdit from 'pages/SK_IntroductionLetter/V4SK0010010_CourseDepartmentspecificFormat/WS0923003_CourseEdit.jsx'
import CourseDepartmentspecificFormatAction from 'redux/SK_IntroductionLetter/CourseDepartmentspecificFormat/CourseDepartmentspecificFormat.action.js'

class WS0923001_CourseDepartmentspecificFormat extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'コース・診療科別書式保守'

    this.state = {
      childModal: {
        visible: false,
        width: 0,
        component: null
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  componentDidMount = () => {
    this.getSettedCourseList()
  }

  getSettedCourseList = () => {
    CourseDepartmentspecificFormatAction.getSettedCourseList()
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  openDepartmentspecificFormat = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0923002_DepartmentspecificFormat
            course_code={record ? record.course_code : ''}
          />
        )
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  addCourse = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0923003_CourseEdit
            onFinishScreen={() => {
              this.closeModal()
              this.getSettedCourseList()
            }}
          />
        )
      }
    })
  }

  openCourseEdit = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0923003_CourseEdit
            id={record.id}
            course_code={record.course_code}
            format_name={record.format_name}
            onFinishScreen={() => {
              this.closeModal()
              this.getSettedCourseList()
            }}
          />
        )
      }
    })
  }

  deleteCourse = (record) => {
    let param = {
      course_code: record.course_code
    }
    CourseDepartmentspecificFormatAction.deleteCourse(param)
      .then(res => {
        this.getSettedCourseList()
      })
  }

  render = () => {
    return (
      <div className='course-departmentspecific-format'>
        <Card title='コース・診療科別書式保守'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.openDepartmentspecificFormat(record)
                    }
                  };
                }}
              >
                <Table.Column
                  title='コース'
                  dataIndex='course_code'
                  className='column-size-10'
                />
                <Table.Column
                  title='名称'
                  dataIndex='course_name'
                  className='column-size-60'
                />
                <Table.Column
                  title='書式'
                  dataIndex='format_name'
                  className='column-size-60'
                />
                <Table.Column
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => this.addCourse()}
                    />
                  }
                  render={(value, record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              onClick={() => this.openDepartmentspecificFormat(record)}
                            >
                              診療科設定
                            </Menu.Item>
                            <Menu.Item
                              key='2'
                              onClick={() => this.openCourseEdit(record)}
                            >
                              コース編集
                            </Menu.Item>
                            <Menu.Item
                              key='3'
                              onClick={() => {
                                Modal.confirm({
                                  content: '削除してもよろしいですか？',
                                  onOk: () => this.deleteCourse(record)
                                })
                              }}
                            >
                              削除
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0923001_CourseDepartmentspecificFormat)
