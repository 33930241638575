import axios from 'configs/axios'
const API_LIST = {
  index: '/api/radio-graphy-inspect-maintain/site-inquiry',
}

const SiteInquiryService = {
  async index() {
    return axios.get(API_LIST.index)
  },
}

export default SiteInquiryService
