import React from 'react'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Card, Form, Input, Table, Button, message, Checkbox } from 'antd'
import { MoreOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons'
import ResizableColumn from 'components/Commons/ResizableColumn'
import WS0339001_InsurerInfoMaintain from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain'
import InsurerInfoSearchQueryAction from 'redux/basicInfo/InsurerInfoMaintain/InsurerInfoSearchQuery.action'

import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0246001_InsurerInfoSearchQuery extends React.Component {
  static propTypes = {
    Lo_InsurerCode: PropTypes.number,
    Lo_Name: PropTypes.any,

    onFinishScreen: PropTypes.func
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '保険者情報検索・照会'

    this.state = {
      SoucedataSource: [],
      dataSource: [],
      isLoadingInsurersList: false,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      columns: [
        {
          title: '保険者コード',
          dataIndex: 'insurer_code',
          width: 120,
          showSorterTooltip: false,
          render: value => <div style={{ textAlign: 'right' }}>{value}</div>
        },
        {
          title: 'メモ',
          width: 40,
          showSorterTooltip: false,
          render: (value, record) => this.renderImpotant(record)
        },
        {
          title: 'カナ名称',
          dataIndex: 'insurer_kana_name',
          width: '',
          showSorterTooltip: false,
        },
        {
          title: '漢字名称',
          dataIndex: 'insurer_kanji_name',
          width: '',
          showSorterTooltip: false,
        },
        {
          title: '電話番号',
          dataIndex: 'phone_number',
          width: 130,
          showSorterTooltip: false,
        },
        {
          title: '保険者番号',
          dataIndex: 'insurer_number',
          width: 120,
          showSorterTooltip: false,
        },
        {
          title: '契約',
          width: 70,
          showSorterTooltip: false,
          dataIndex: 'ContractPresence',
          render: (text, record) => {
            return text
          }
        }
      ]
    }
  }
  // テーブルカラム設定
  tableColums = () =>
    this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))

  handleResize =
    index =>
      (e, { size }) => {
        this.setState(({ columns }) => {
          const nextColumns = [...columns]
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width
          }
          return { columns: nextColumns }
        })
      }
  components = {
    header: {
      cell: ResizableColumn
    }
  }


  /**
   * 初期表示処理
   */
  componentDidMount() {
    const insurerSearch = this.props.Li_insurerSearch ?? ''
    if (insurerSearch !== '') {
      this.formRef.current?.setFieldsValue({
        SearchChar: insurerSearch
      })
    }
    this.index()
  }

  /**
   * 保険者データの取得
   * @param {*} page
   * @param {*} pageSize
   */
  index() {

    let params = {
      KanaSearch: this.formRef.current.getFieldValue('SearchChar'),
    }

    InsurerInfoSearchQueryAction.index(params)
      .then(res => {
        this.setState({
          SoucedataSource:res
          , dataSource: res
        })
      })
      .catch(error => {
        console.log(error)
        message.error('エラーが発生しました')
      })
  }

  incrementalSearch = (value) => {

    let buf = '.*' + value.replace(/(.)/g, "$1.*")
    let reg = new RegExp(buf)

    setTimeout(() => {

      let all = this.state.SoucedataSource
      let fildata

      if (value.trim() === '') {
        fildata = all
      } else {

          fildata = all.filter((d) => {
            return reg.test(d.insurer_kana_name) || reg.test(d.insurer_kanji_name)
          })

      }

      this.setState({
        dataSource: fildata
      })

    }, 500)
  }


  /**
   * メモ　render
   * @param {*} record
   * @returns
   */
  renderImpotant = (record) => {
    let icon = ''
    const iconSize = 20
    switch (record.importance) {
      case 1:
        icon = <InfoCircleOutlined style={{ fontSize: iconSize, color: '#1890ff' }} />
        break
      case 3:
        icon = <WarningOutlined style={{ fontSize: iconSize, color: '#faad14' }} />
        break
      case 5:
        icon = <CloseCircleOutlined style={{ fontSize: iconSize, color: '#ff4d4f' }} />
        break
      default:
        icon = <Button size='small' icon={<MoreOutlined />} />
    }
    return (
      <Form.Item style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1300,
            component: (
              <WS0339001_InsurerInfoMaintain
                insurer_code={record.insurer_code}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: false,
                    },
                  })
                }}
              />
            ),
          },
        })
      }}>
        {icon}
      </Form.Item>
    )
  }

  render() {
    return (
      <div className='insurer-info-search-query'>
        <Card title='保険者情報検索・照会'>
          <Form ref={this.formRef} autoComplete='off'>

            {/* 必要なし・・・？ */}
            <Form.Item name='InvalidCharOp' hidden>
              <Input />
            </Form.Item>
            {/* 必要なし・・・？ */}
            <Form.Item name='SearchMethodsOp' valuePropName='checked' hidden>
              <Checkbox />
            </Form.Item>

            <Form.Item
              name='SearchChar'
              label='検索'
            >
              <Input className='input-size-60'
                onChange={(e) => this.incrementalSearch(e.target.value)}
              />
            </Form.Item>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={record => record.insurer_code}
              columns={this.tableColums()}
              components={this.components}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              scroll={{ y: resizableTableScroll(100) }}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    const func = this.props.onFinishScreen || this.props.onClickedSelect
                    if (func) {
                      func({
                        Lo_InsurerCode: record.insurer_code,
                        Lo_Name: record.insurer_kanji_name,
                        recordData: record
                      })
                    }
                  }
                }
              }}
            >
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            })
          }}
        />
      </div>
    )
  }
}

export default WS0246001_InsurerInfoSearchQuery
