import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Button,
  Table,
  Modal,
  Dropdown,
  Menu
} from "antd";
import {
  MoreOutlined,
  PlusOutlined,
  MenuOutlined
} from "@ant-design/icons";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import WS3401003_AutoJudgmentNameEdit from 'pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401003_AutoJudgmentNameEdit.jsx'
import AutoJudgmentManagementAction from 'redux/SM_SystemMaintenance/AutoJudgmentManagement/AutoJudgmentManagement.action.js'

class WS3401002_AutoJudgmentNameSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '資料一括作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }
  }

  componentDidMount = () => {
    this.getAutoJudgmentNameData()
  }

  getAutoJudgmentNameData = () => {
    AutoJudgmentManagementAction.getAutoJudgmentNameData()
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  openAutoJudgmentNameEdit = (newFlag = false, record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: false,
        component: (
          <WS3401003_AutoJudgmentNameEdit
            record={record}
            newFlag={newFlag}
            onFinishScreen={() => {
              this.getAutoJudgmentNameData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  deleteAutoJudgmentName = (record) => {
    let params = {
      id: record.id,
      judgment_name_code: record.judgment_name_code
    }
    AutoJudgmentManagementAction.deleteAutoJudgmentName(params)
      .then(res => {
        if (res) {
          this.getAutoJudgmentNameData()
        }
      })
  }

  render() {
    return (
      <div className="auto-judgment-name-setting" style={{ width: 1200 }}>
        <Card title='自動判定名設定'>
          <Form ref={this.formRef}>
            <div className="box_container">
              <Table
                bordered
                dataSource={this.state.dataSource}
                loading={false}
                size='small'
                pagination={false}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(180) }}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () => { this.openAutoJudgmentNameEdit(false, record) }
                })}
              >
                <Table.Column
                  className="column-size-4"
                  title="No"
                  dataIndex="display_number"
                />
                <Table.Column
                  className="column-size-10"
                  title="コード"
                  dataIndex="judgment_name_code"
                />
                <Table.Column
                  className="column-size-60"
                  title="名称"
                  dataIndex="judgment_name"
                />
                <Table.Column
                  className="column-size-10"
                  title="判定レベル指定"
                  dataIndex="judgment_level_specification"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value === true ? '✓' : ''}</div>
                    )
                  }}
                />
                <Table.Column
                  className="column-size-40"
                  title="判定レベル"
                  dataIndex="judgment_level_name"
                />
                <Table.Column
                  width={45}
                  title="無効"
                  dataIndex="invalid_flag"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value === true ? '✓' : ''}</div>
                    )
                  }}
                />
                <Table.Column
                  className="column-size-20"
                  title="開始日"
                  dataIndex="start_date"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  className="column-size-20"
                  title="終了日"
                  dataIndex="end_date"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  width={45}
                  align='center'
                  fixed='right'
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => { this.openAutoJudgmentNameEdit(true) }}
                    />
                  }
                  render={(value, record) => (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='編集'
                            onClick={() => this.openAutoJudgmentNameEdit(false, record)}
                          >
                            編集
                          </Menu.Item>
                          <Menu.Item
                            key='削除'
                            onClick={() => {
                              Modal.confirm({
                                content: '削除しますか？',
                                onOk: () => this.deleteAutoJudgmentName(record)
                              })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3401002_AutoJudgmentNameSetting);