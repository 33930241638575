import axios from 'configs/axios'

const apiPaths = {
  index: '/api/insurer-info-maintain/post-code-search-engine',
  changePublic: '/api/insurer-info-maintain/post-code-search-engine/change-public',
  changeCity: '/api/insurer-info-maintain/post-code-search-engine/change-ctiy',
  changeArea: '/api/insurer-info-maintain/post-code-search-engine/change-area',
}

const PostCodeSearchEngineService = {
  async index() {
    return axios.get(apiPaths.index)
  },

  async changePublic(params) {
    return axios.get(apiPaths.changePublic, { params })
  },

  async changeCity(params) {
    return axios.get(apiPaths.changeCity, { params })
  },

  async changeArea(params) {
    return axios.get(apiPaths.changeArea, { params })
  },
}

export default PostCodeSearchEngineService
