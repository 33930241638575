import { message } from 'antd'
import AcceptanceBatchProcessService from 'services/CounterBusiness/AcceptanceBatchProcess/AcceptanceBatchProcessService'

const AcceptanceBatchProcessAction = {

  index() {
    return AcceptanceBatchProcessService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getVisitList(params) {
    return AcceptanceBatchProcessService.getVisitList(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  setAcceptOrCancel(params) {
    return AcceptanceBatchProcessService.setAcceptOrCancel(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}

export default AcceptanceBatchProcessAction
