import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Input, Space, Button, message, Modal, Row, Col, Switch } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import {
  getDataInputSupportPlanEtcAction, saveAndUpdateInputSupportPlanEtcAction, deleteInputSupportPlanEtcAction
} from "redux/SpecificInsureMaintenance/SpecificHealthTokuhoParamMaintain/InputSupportPlanEtc.actions";
import { deletePrintParamMaintainAction } from "redux/ResultOutput/PrintParamMaintain/PrintParamMaintain.actions";
import { generateUUID } from 'components/Commons/generateUUID'

const styleFormItem = {
  margin: 0
}
const styleInput = {
  border: 'none'
}
class WS1308004_InputSupportPlanEtc extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '入力[支援計画等]';

    this.state = {
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true,
        showSizeChanger: true
      },
      DataItem: [],
      isLoading: true,
      rowSelect: {},
      isSwitchOn: false
    };
  }

  componentDidMount = () => {
    const params = {
      format: this.props.format,
      remarks: this.props.remarks
    }
    this.getDataInputSupportPlanEtc(params);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const params = {
        format: this.props.format,
        remarks: this.props.remarks
      }
      this.getDataInputSupportPlanEtc(params);
    }
  }

  getDataInputSupportPlanEtc = (params) => {
    this.setState({ isLoading: true });
    getDataInputSupportPlanEtcAction(params)
      .then(res => {
        if (res) {
          this.setState({ DataItem: res.data.DataItem });
          this.formRef?.current?.setFieldsValue(res.data)
        }
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  saveAndUpdateInputSupportPlanEtc = (params) => {
    params = {
      ...params,
      format: this.props.format
    }
    saveAndUpdateInputSupportPlanEtcAction(params)
      .then(res => {
        message.success(res);
        this.getDataInputSupportPlanEtc({
          format: this.props.format,
          remarks: this.props.remarks
        });
      })
      .catch((err) => message.error(err))
  }

  deleteInputSupportPlanEtc = (record) => {
    if (record.id) {
      deleteInputSupportPlanEtcAction({ id: record.id })
        .then(res => {
          message.success(res);
          this.getDataInputSupportPlanEtc({
            format: this.props.format,
            remarks: this.props.remarks
          });
        })
        .catch(err => {
          message.error(err)

          let arrTemp = [...this.state.DataItem];
          let findData = arrTemp.filter((item) => item.id != record.id);

          if (findData.length > 0) {
            this.setState({ DataItem: findData })
            this.formRef.current.setFieldsValue({ 'DataItem': findData });
            this.forceUpdate()
          }
        })
    }
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  handleChangeInput = (event, record) => {
    let { value, name } = event.target;

    // 全角スペースは半角へ置換
    value = value.replace('　', ' ');


    let arrTemp = [...this.state.DataItem];
    let findData = arrTemp.filter((item) => item.id === record.id);

    let objTemp = {
      ...record,
      [name]: value
    }

    if (findData.length > 0) {
      let index = arrTemp.indexOf(findData[0]);
      arrTemp[index] = objTemp

      this.setState({ DataItem: arrTemp })
      this.formRef.current.setFieldsValue({ 'DataItem': arrTemp });
    }

  }

  render() {
    return (
      <div className="input-support-plan-etc">
        <Card title="入力[支援計画等]">
          <Form ref={this.formRef}>
            <Space>
              <Form.Item label='FORMAT' name='format'>
                <Input readOnly />
              </Form.Item>
              <Form.Item name='remarks'>
                <Input readOnly />
              </Form.Item>
            </Space>

            <Table bordered
              size="small"
              dataSource={this.formRef.current?.getFieldValue("DataItem")}
              loading={this.state.isLoading}
              pagination={this.state.isSwitchOn ? false : this.state.pagination}
              rowKey={(record) => record.id}
            >
              <Table.Column title='区分' dataIndex='division'
                width='10%'
                showSorterTooltip={false}
                sorter={(a, b) => a.division.localeCompare(b.division, 'ja')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'division']} style={styleFormItem} rules={[{ required: true, message: 'エラー' }]}>
                      <Input style={styleInput} maxLength={6} onBlur={(e) => this.handleChangeInput(e, record)} name='division' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='備　考' dataIndex='remarks'
                width='10%'
                showSorterTooltip={false}
                sorter={(a, b) => a.remarks.localeCompare(b.remarks, 'ja')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'remarks']} style={styleFormItem}>
                      <Input style={styleInput} maxLength={20} onBlur={(e) => this.handleChangeInput(e, record)} name='remarks' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='種  別' dataIndex='kind'
                width='10%'
                showSorterTooltip={false}
                sorter={(a, b) => a.kind.localeCompare(b.kind, 'ja')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'kind']} style={styleFormItem}>
                      <Input style={styleInput} maxLength={10} onBlur={(e) => this.handleChangeInput(e, record)} name='kind' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='属性' dataIndex='attribute'
                width='10%'
                showSorterTooltip={false}
                sorter={(a, b) => a.attribute.localeCompare(b.attribute, 'ja')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'attribute']} style={styleFormItem}>
                      <Input style={styleInput} maxLength={10} onBlur={(e) => this.handleChangeInput(e, record)} name='attribute' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='桁数' dataIndex='number_of_digits'
                width='8%'
                showSorterTooltip={false}
                sorter={(a, b) => a.number_of_digits - b.number_of_digits}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'number_of_digits']} style={styleFormItem}>
                      <Input style={styleInput} maxLength={6} type='number' min={1}
                        onBlur={(e) => this.handleChangeInput(e, record)} name='number_of_digits' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='位置' dataIndex='position'
                width='8%'
                showSorterTooltip={false}
                sorter={(a, b) => a.position - b.position}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'position']} style={styleFormItem}>
                      <Input style={styleInput} maxLength={6} type='number' min={1}
                        onBlur={(e) => this.handleChangeInput(e, record)} name='position' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='設定' dataIndex='set_pattern'
                width='10%'
                showSorterTooltip={false}
                sorter={(a, b) => a.set_pattern.localeCompare(b.set_pattern, 'ja')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'set_pattern']} style={styleFormItem}>
                      <Input style={styleInput} maxLength={10} onBlur={(e) => this.handleChangeInput(e, record)} name='set_pattern' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column title='オ プ シ ョ ン' dataIndex='option_remark'
                width='29%'
                showSorterTooltip={false}
                sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, 'ja')}
                render={(text, record) => {
                  let index = this.findIndexByID(this.state.DataItem, record.id);
                  return (
                    <Form.Item name={['DataItem', index, 'option_remark']} style={styleFormItem}>
                      <Input style={styleInput} onBlur={(e) => this.handleChangeInput(e, record)} name='option_remark' />
                    </Form.Item>
                  )
                }}
              />
              <Table.Column align='center' width='5%'
                title={() => (<Button size='small' type='primary'
                  icon={<PlusOutlined />} onClick={() => {
                    let arrTemp = [{ id: generateUUID() }];
                    this.formRef.current.setFieldsValue({ 'DataItem': [...arrTemp, ...this.state.DataItem] });
                    this.setState({ DataItem: [...arrTemp, ...this.state.DataItem] });
                  }}></Button>)}
                render={(text, record, index) => (
                  <>
                    <Button size='small' style={{ border: 'none', }}
                      icon={<SaveOutlined style={{ color: 'green' }} />}
                      onClick={() => this.saveAndUpdateInputSupportPlanEtc(record)}
                    ></Button>
                    <Button size='small' style={{ border: 'none', }}
                      danger icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          content: '消去してもよろしいですか？',
                          okText: 'は　い',
                          cancelText: 'いいえ',
                          onOk: () => this.deleteInputSupportPlanEtc(record)
                        })
                      }}
                    ></Button>
                  </>
                )}
              />
            </Table>
          </Form>
          <Row>
            <Col span={23}></Col>
            <Col span={1}>
              <Space>
                <Switch style={{ marginTop: '10px' }}
                  checkedChildren="全て"
                  defaultChecked={false}
                  onClick={(checked) => { this.setState({ isSwitchOn: checked }) }}></Switch>
              </Space>
            </Col>
          </Row>


        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1308004_InputSupportPlanEtc);
