import React from "react";
import { connect } from "react-redux";
import { DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import Color from 'constants/Color';
import { Card, Form, Input, Button, Popconfirm, Select, ColorPicker } from "antd";
import WS0447011_ColorSample from 'pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0447011_ColorSample.jsx';

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS0447500_DetermineLevelModifyMasterCoerciveEdit extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_CategoryCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = 'カテゴリーマスタ SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      screen_color: ''
    };
  }

  /**
   * 初期表示
   */
  componentDidMount = () => {
    if (this.props.Li_EditFlag) {
      this.formRef.current.setFieldsValue(this.props.record)
      this.setState({
        screen_color: this.props.record.W1_screen_color
      })
      this.forceUpdate()
    }
  }

  closeModal = () => {
    this.setState((prevState) => ({
      childModal: { ...prevState.childModal, visible: false },
    }));
  }

  /**
   * 保存
   */
  onSave() {
    let getFieldsValue = this.formRef.current?.getFieldsValue()
    let params = {
      ...getFieldsValue,
      W1_screen_color: this.state.screen_color
    }
    this.props.onSave(params)
  }

  /**
   * 画面色変更時
   * @param {*} value 
   */
  ColorChage(value) {
    this.setState({
      screen_color: value
    })
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title={this.props.Li_EditFlag ? "修正" : "登録"}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item name="JudgeLevelChar" label="判定レベル" {...smGrid}>
              <Select style={{ width: 150 }}>
                {this.props.JudgeLevel?.map(value => (
                  <Select.Option
                    key={"JudgeLevelChar-" + Math.random()}
                    value={value?.condition_1}>{value?.item}
                  </Select.Option>
                ))}</Select>
            </Form.Item>

            <Form.Item name="W1_judge_result" label="判定" {...smGrid}>
              <Input type="text" maxLength={3} style={{ width: 100 }} />
            </Form.Item>


            <Form.Item name="W1_weight_judge" label="重み" {...smGrid}>
              <Input
                maxLength={2}
                style={{ width: 100 }}
                onChange={(e) => {
                  const { value } = e.target;
                  const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                  if ((!isNaN(value) && reg.test(value))) {
                    this.formRef.current?.setFields([{
                      W1_weight_judge: value
                    }])
                  } else {
                    this.formRef.current?.setFields([{
                      W1_weight_judge: ""
                    }])
                  }
                  this.forceUpdate()
                }} />
            </Form.Item>
            <Form.Item name="W1_standard_judge_result" label="基準判定" {...smGrid}>
              <Select style={{ width: 100 }}>
                <Select.Option value={""}></Select.Option>
                <Select.Option value={"A"}>A</Select.Option>
                <Select.Option value={"B"}>B</Select.Option>
                <Select.Option value={"C"}>C</Select.Option>
                <Select.Option value={"D"}>D</Select.Option>
                <Select.Option value={"E"}>E</Select.Option>
                <Select.Option value={"F"}>F</Select.Option>
                <Select.Option value={"G"}>G</Select.Option>
                <Select.Option value={"H"}>H</Select.Option>
                <Select.Option value={"I"}>I</Select.Option>
                <Select.Option value={"J"}>J</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="W1_judge_name" label="判  定  名" {...smGrid}>
              <Input maxLength={40} style={{ width: 250 }} />
            </Form.Item>
            <div className="box_inner_horizontal">
              <Form.Item name="W1_screen_color" label="画面色" {...smGrid}>
                <Input.Search
                  style={{
                    width: 150,
                    color: Color(this.state.screen_color)?.Foreground,
                    textAlign: 'right'
                  }}
                  allowClear={true}
                  onBlur={(e) => this.ColorChage(e.target.value)}
                  onPressEnter={(e) => this.ColorChage(e.target.value)}
                  onSearch={(value, event) => {
                    if (event.target.localName === 'input') {
                      this.ColorChage(event.target.value)
                    } else {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 300,
                          component: (
                            <WS0447011_ColorSample
                              Lio_ColorCode={this.formRef.current?.getFieldsValue('ColorSample')}
                              onFinishScreen={(output) => {
                                this.setState({
                                  screen_color: output.recordData.Lio_ColorCode
                                })
                                this.formRef.current.setFieldsValue({
                                  'W1_screen_color': output.recordData.Lio_ColorCode
                                })
                                this.forceUpdate()
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }
                  }} />
              </Form.Item>
              <div style={{ marginTop: '5px', color: Color(this.state.screen_color)?.Foreground }}>AaBb12</div>
            </div>

            <Form.Item name="W1_web_color" label="WEB色" {...smGrid}>
              <Input maxLength={20} style={{ width: 150 }} />
            </Form.Item>

            <Form.Item >
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>

                {this.props.Li_EditFlag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_CategoryCode === 0) ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.props.onDelete()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.record.Li_CategoryCode === 0}
                    >
                      削除
                    </Button>
                  </Popconfirm> : null}

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => { this.onSave() }}
                >
                  保存
                </Button>

              </div>
            </Form.Item>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0447500_DetermineLevelModifyMasterCoerciveEdit);