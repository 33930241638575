import axios from "configs/axios"

const apiPaths = {
  index: '/api/personal-number-integration',
  changePersonalNumber: '/api/personal-number-integration/change-personal-number',
  integrate: '/api/personal-number-integration/integrate',
}

const PersonalNumberIntegrationService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async changePersonalNumber(params) {
    return axios.get(apiPaths.changePersonalNumber, { params })
  },
  async integrate(params) {
    return axios.post(apiPaths.integrate, params)
  }
}
export default PersonalNumberIntegrationService
