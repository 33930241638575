import React from 'react';
import { connect } from 'react-redux';

import { Card, Form, Input, Button, Col, Row, Space, Modal, Popconfirm } from 'antd';
import ModalDraggable from 'components/Commons/ModalDraggable';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons'
import WS2593056_BillingInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2593056_BillingInquirySub';
import moment from 'moment-timezone';
import VisitsCancelConfirmAction from 'redux/Others/CreateTestForMedicalExamInfo/VisitsCancelConfirm.action.js';
class WS2624003_VisitsCancelConfirm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '受診取消確認';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
    };
  }

  onFinish(values) {

  }

  // tConvert = (time) => {
  //   time =
  //     // Check correct time format and split into components
  //     time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  //   console.log('time', time)

  //   if (time.length > 1) { // If time format correct
  //     time = time.slice(1);  // Remove full string match value
  //     time[3] = +time[0] < 12 ? '   AM' : '   PM'; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  //   }
  //   return time.join(''); // return adjusted time or original string
  // }

  componentDidMount() {
    // console.log(this.props.Li_Record)
    // console.log('DateBirth:', moment(this.props.Expression_45).format('NNy/MM/DD'))
    // this.formRef.current.set
    this.formRef?.current?.setFieldsValue({
      personal_number_id: this.props.Li_Record.personal_number_id,
      Expression_43: this.props.Li_Record.Expression_43,
      Expression_44: this.props.Li_Record.Expression_44,
      Expression_45: this.props.Li_Record.Expression_45,
      Expression_52: this.props.Li_Record.Expression_52,
      Expression_48: this.props.Li_Record.Expression_48,
      receipt_number: this.props.Li_Record.receipt_number,
      state_flag: this.props.Li_Record.state_flag + ' 次',
      visit_course: this.props.Li_Record.visit_course,
      contract_short_name: this.props.Li_Record.contract_short_name,
      // period_time: this.tConvert(this.props.Li_Record.period_time),
      period_time: this.props.Li_Record.period_time,
      Expression_59: this.props.Li_Record.Expression_59 == '男性.png' ? '男' : '女',

    })
  }
  getSelectedBitrhday = (val) => {
    if (!val) return ''
    var new_year = '';
    const birthList = (String)(val).split('/');
    var source_year = birthList.at(0);
    var year_chars = source_year.split('');
    const year_type_tag = year_chars.at(0);
    if (year_type_tag === 'S' || year_type_tag === 'H') {
      this.selectedBitrhday = (String)(val)
      return;
    } else if (year_type_tag === '4') {
      new_year += 'H';
    } else if (year_type_tag === '3') {
      new_year += 'S';
    }
    year_chars.length === 2 ? new_year = new_year + '0' + year_chars.at(1) : new_year = new_year + year_chars.at(1) + year_chars.at(2);
    return new_year + '/' + birthList.at(1) + '/' + birthList.at(2);
    // console.log('tableClick:', record, 'importance:', this.state.importance, '--bir--', this.selectedBitrhday);

  }
  ModalError = () => {
    Modal.error({
      content: '個人番号が無効です',
      icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />,
      onOk: () => {

      }
    })
  }

  BillingInquirySub = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component:
          <WS2593056_BillingInquirySub
            Li_ReserveNum={this.props.Li_Record.reservation_number}
            onFinishScreen={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
              this.props.onFinishScreen({ Li_id: this.props.Li_Record.id })
            }}
          />

      },
    });
  }

  onCancel = () => {
    let params = {
      Li_ReserveNum: this.props.Li_Record.reservation_number,
    }
    VisitsCancelConfirmAction.eventAcceptedCancellation(params)
      .then(res => {
        this.props.onFinishScreen({
          Li_id: this.props.Li_Record.id
        });
      })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    // console.log(this.formRef.current?.getFieldValue('personal_number_id'))
    return (
      <div className='visits-cancel-confirm'>
        <Card title='受診取消確認'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className='box_container'>
              <div className='box_border'>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='個人番号'></Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name='personal_number_id'
                    >
                      <Input name='personal_number_id' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='氏名'></Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name='Expression_43'
                    >
                      <Input name='Expression_43' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    {/* <Form.Item label='氏名'></Form.Item> */}
                  </Col>
                  <Col span={15}>
                    <Form.Item
                      name='Expression_44'
                    >
                      <Input name='Expression_44' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='性別'></Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name='Expression_59'
                    >
                      <Input name='Expression_59' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label='生年月日'></Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name='Expression_45'
                    >
                      <Input name='Expression_45' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className='box_border'>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='受診日'></Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name='Expression_48'
                    >
                      <Input name='Expression_48' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='受付No'></Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name='receipt_number'
                    >
                      <Input name='receipt_number' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='施設'></Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name='Expression_52'
                    >
                      <Input name='Expression_52' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='時間帯'></Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name='period_time'
                    >
                      <Input name='period_time' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='ｎ次区分'></Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name='state_flag'
                    >
                      <Input name='state_flag' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='コース'></Form.Item>
                  </Col>
                  <Col span={2}>

                    <Form.Item style={{ width: 100 }}
                      name='visit_course'
                    >
                      <Input name='visit_course' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name='contract_short_name'
                    >
                      <Input name='contract_short_name' readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <Form.Item label='備考'></Form.Item>
                  </Col>
                  <Col span={2}>
                  </Col>
                </Row>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.BillingInquirySub()}
              >
                請求確認
              </Button>

              <Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>取消を行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                cancelButtonProps={{ style: { height: '32px' } }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                okText='取消'
                onConfirm={() => { this.onCancel() }}
                cancelText='キャンセル'
              >
                <Button
                  danger
                  type='primary'
                >
                  <span className='btn_label'>
                    取消
                  </span>
                </Button>
              </Popconfirm>
            </div>

          </Form>
        </Card>
        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2624003_VisitsCancelConfirm);
