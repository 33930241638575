import React from "react";
import {
  Input,
  Form,
  Card,
  Button,
  Table,
  Checkbox,
} from "antd";

import {
  SaveOutlined,
} from "@ant-design/icons";
import OfficeInfoMaintainService from "services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js";
import PropTypes from "prop-types";


class WS0341502_DestinationInfo extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0,
      DestinationInfo: []
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      DestinationInfo: this.props.Li_DestinationInfo
    })
    this.setState({
      DestinationInfo: this.props.Li_DestinationInfo
    })
  }

  saveDestination = () => {
    const newDestinationInfo = this.formRef.current?.getFieldsValue('DestinationInfo')

    let params = {
      OfficeCode: this.props.Li_OfficeCode,
      BranchStoreCode: this.props.Li_BranchStoreCode,
      newDestinationInfo: newDestinationInfo.DestinationInfo,
    }

    console.log(params);

    OfficeInfoMaintainService.saveDestinationService(params)
      .then(res => {
      })
    this.props.onFinishScreen(newDestinationInfo.DestinationInfo)
  }

  render() {
    const office_addresses_render = (name, text, record, index) => {
      const namePath = ["DestinationInfo", index, name];
      return (
        <Form.Item name={namePath} valuePropName="checked">
          <Checkbox
            onChange={e => {
              const formIns = this.formRef.current;
              formIns.setFields([
                {
                  name: ["DestinationInfo", index, "destination_office_1"],
                  value: 0
                },
                {
                  name: ["DestinationInfo", index, "destination_office_2"],
                  value: 0
                },
                {
                  name: ["DestinationInfo", index, "destination_personal_1"],
                  value: 0
                },
                {
                  name: ["DestinationInfo", index, "destination_personal_2"],
                  value: 0
                },
                {
                  name: ["DestinationInfo", index, "destination_personal_3"],
                  value: 0
                },
                // Update current field only
                {
                  name: namePath,
                  value: e.target.checked ? 1 : 0
                }
              ]);
            }}
          />
        </Form.Item>
      );
    };
    return (
      <div className="destination-info">
        <Card title='送付先'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
          >
            <Table
              dataSource={this.state.DestinationInfo}
              rowKey={record => record.id}
              pagination={false}
              size="small"
              bordered
            >
              <Table.Column
                width={250}
                title="書類"
                dataIndex="document_title"
                render={(text, record, index) => {
                  return (
                    <>
                      <Form.Item
                        name={["DestinationInfo", index, "id"]}
                        hidden={true}
                      >
                        <Input value={record.id || null} />
                      </Form.Item>
                      <Form.Item
                        name={["DestinationInfo", index, "send_document_code"]}
                        hidden={true}
                      >
                        <Input value={record.send_document_code || null} />
                      </Form.Item>
                      <Form.Item
                        name={["DestinationInfo", index, "document_option"]}
                        hidden={true}
                      >
                        <Input value={record.document_option || null} />
                      </Form.Item>
                      <Form.Item
                        name={["DestinationInfo", index, "document_title"]}
                        hidden={true}
                      >
                        <Input value={record.document_title || null} />
                      </Form.Item>
                      {text}
                    </>
                  );
                }}
              />
              <Table.Column
                title="事業所"
                align="center"
                dataIndex="destination_office_1"
                render={(text, record, index) =>
                  office_addresses_render("destination_office_1", text, record, index)
                }
              />
              <Table.Column
                title="本店"
                align="center"
                dataIndex="destination_office_2"
                render={(text, record, index) =>
                  office_addresses_render("destination_office_2", text, record, index)
                }
              />
              <Table.Column
                title="個人1"
                align="center"
                dataIndex="destination_personal_1"
                render={(text, record, index) =>
                  office_addresses_render("destination_personal_1", text, record, index)
                }
              />
              <Table.Column
                title="個人2"
                align="center"
                dataIndex="destination_personal_2"
                render={(text, record, index) =>
                  office_addresses_render("destination_personal_2", text, record, index)
                }
              />
              <Table.Column
                title="個人3"
                align="center"
                dataIndex="destination_personal_3"
                render={(text, record, index) =>
                  office_addresses_render("destination_personal_3", text, record, index)
                }
              />
            </Table>
            <div className="box_button_bottom_right" style={{ marginTop: '20px' }}>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  // 親画面に反映
                  this.saveDestination()
                }}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}
export default WS0341502_DestinationInfo