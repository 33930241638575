import { message } from 'antd'
import TargetSelectDocumentService from 'services/AdvancePreparation/DocumentBatchCreate/TargetSelectDocumentService'

const TargetSelectDocumentAction = {
  index(params) {
    return TargetSelectDocumentService.index(params)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  excelReport(params) {
    return TargetSelectDocumentService.excelReport(params)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  changeLogicAction(params) {
    return TargetSelectDocumentService.changeLogicService(params)
  },

  changeLogicAllAction(params) {
    return TargetSelectDocumentService.changeLogicAllService(params)
  }
}
export default TargetSelectDocumentAction
