import axios from "configs/axios";
const apiPaths = {
  getScreenData: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/get-screen-data",
  SearchBtn: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/event-search-btn",
  changeGagency: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/change-gagency",
  getNameGinsuranceNum: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/get-name-ginsurance-num",
  indexMemberList: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/index-member-list",
  updateCheckbox: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/update-checkbox",
  changeStsSelectAllMemberList: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/change-sts-select-all-member-list",
  xmlCreate: "/api/specific-coercive-finger-xml-output/specific-insure-xml-output/event-xml-create",
};
const SpecificInsureXmlOutputService = {
  async getScreenData() {
    return axios.get(apiPaths.getScreenData);
  },
  async SearchBtn(params) {
    return axios.post(apiPaths.SearchBtn, params);
  },
  async changeGagency(params) {
    return axios.post(apiPaths.changeGagency, params);
  },
  async getNameGinsuranceNum(params) {
    return axios.post(apiPaths.getNameGinsuranceNum, params);
  },
  async indexMemberList(params) {
    return axios.get(apiPaths.indexMemberList, { params });
  },
  async updateCheckbox(params) {
    return axios.get(apiPaths.updateCheckbox, { params });
  },
  async changeStsSelectAllMemberList(params) {
    return axios.post(apiPaths.changeStsSelectAllMemberList, params);
  },
  async xmlCreate(params) {
    return axios.post(apiPaths.xmlCreate, params);
  },
};

export default SpecificInsureXmlOutputService;