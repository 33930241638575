import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import { Card, Form, Descriptions, Button, Table, message, Modal, Tooltip, Input, Dropdown, Menu, } from 'antd'
import { MoreOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import GetImage from 'constants/Images'
import OverallResultDisplayInputAction from 'redux/InputBusiness/NotInputCheckCategory/OverallResultDisplayInput.action'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS0274002_InspectCmtSearchInput from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274002_InspectCmtSearchInput'
import WS0730001_FindingsInputPhysiciiagnosis from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx'
import WS0729001_FindingsInputRadiography from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729001_FindingsInputRadiography.jsx'
import WS0728001_FindingsInputNormal from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0728001_FindingsInputNormal.jsx'
import WS2637015_InspectResultValueInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637015_InspectResultValueInput.jsx'
import WS2644008_SpecifiedValueConfirm from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2644008_SpecifiedValueConfirm.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import InspectionResultsChangeHistory from 'pages/IN_InputBusiness/SANAI_InterviewSupport/InspectionResultsChangeHistory.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import recalculate from 'components/Commons/recalculate'

class WS2637014_InspectResult extends React.Component {
  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査別結果一覧 照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      inspectResultList: [],
      changeData: [],
      saveFlag: false,

      disabledUpdateBtn: true
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getInspectResultList()
  }

  /**
   * カテゴリ別の詳細な検査結果一覧を取得
   */
  getInspectResultList = (changeFlg) => {

    // 親画面から引数取得
    const record = { ...this.props.record }
    const values = { ...this.props.values }

    let SelectCategoryList = ''
    const HistoryAdded = values.HistoryAdded //履歴
    const NonInput = values.NonInput
    const Outliers = values.Outliers
    const NonInputOrOutliers = NonInput === 1 ? { NonInput } : { Outliers }
    const NormalJudge = values.NormalJudge

    const params = {
      ...this.props.paramObject,
      StsInputModeInspect: values.StsInputModeInspect,
      StsInputModeFindings: values.StsInputModeFindings,
      SelectCategoryList: SelectCategoryList,
      Li_JudgeLevel: record.Li_JudgeLevel,
      SelectCategory: record.SelectCategory,
      UndeterminedColorOp: values.UndeterminedColorOp,
      StsParkModeCategoryUpAndDown: values.StsParkModeCategoryUpAndDown,
      StsInputModeInspectCmtChiba: values.StsInputModeInspectCmtChiba,
      HistoryAdded,
      ...NonInputOrOutliers,
      NormalJudge,
      CategoryDisplayPosition: values.CategoryDisplayPosition,
      sex: values.sex,
      birthday_on: values.birthday_on,
      visit_date_on: values.visit_date_on,
      Li_DateOfBirth: values.Li_DateOfBirth,
      pre1ReserveNum: values.pre1ReserveNum,
      pre2ReserveNum: values.pre2ReserveNum,
    }
    OverallResultDisplayInputAction.getInspectResultList(params)
      .then((res) => {
        this.setState({
          inspectResultList: res,
          changeData: res,
          disabledUpdateBtn: true,
        })
        this.formRef?.current?.setFieldsValue({ formInspectResultList: res })
        if (changeFlg) {
          this.props.onUpdateCategory()
        }
      })
  }

  /**
   * データチェック
   * @param {*} value
   * @param {*} type
   * @returns
   */
  checkData = (value, type) => {
    const val = (type === 'number') ? 0 : ''
    // valueが空の場合は「0」か「空」を設定
    return !this.isEmpty(value) ? value : val
  }

  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }

  getRawValue = (name) => this.formRef?.current?.getFieldValue(name)

  /**
   * suffixResultValue のパラメータを取得
   * @param {*} record
   * @returns
   */
  getSuffixResultValueParams = (record) => {
    const params = {
      id: this.checkData(record.id, 'number'),
      display_no: this.checkData(this.props.record?.display_no, 'number'), //カテゴリ
      display_order: this.checkData(record.display_order, 'number'), //検査
      Li_Gender: this.props.values.Li_Gender,
      Li_DateOfBirth: this.props.values.Li_DateOfBirth,
      judgment_value: this.checkData(record.judgment_value, 'text'),
      result_value: this.checkData(record.result_value, 'text'),
      remarks: this.checkData(record.remarks, 'text'),
      StsAutomaticCalculate: this.checkData(record.StsAutomaticCalculate, 'number'),
      exam_code: this.checkData(record.exam_code, 'number'),
      visit_date_on: this.props.values.visit_date_on,
      judgment_code: this.checkData(record.judgment_code, 'number'),
      NormalJudge: this.getRawValue('NormalJudge'),
      // UpAndDownDisplay: this.getRawValue('UpAndDownDisplay'),
      UpAndDown: this.checkData(record.UpAndDown, 'text'),
      display_remark: this.checkData(record.display_remark, 'text'),
    }
    return params
  }

  /**
   * 編集用のモーダルを表示する
   * @param {*} record
   */
  showEditModal = async (record) => {
    if (!this.state.disabledUpdateBtn) {
      await this.setState({ saveFlag: true })
    }

    // ロックされていない受診者だけ
    if (!this.props.protectFlag) {
      // if (record.StsInputModeInspectCmtChiba === 1 && record.textPositionExamType && record.StsInspectCmtInfo) { ※いるかも！
      if (record.textPositionExamType && record.StsInspectCmtInfo) {
        // 検査コメント検索・照会　モーダル 大体はこのモーダルを使用
        this.showWS0274001_InspectCmtSearchQuery(record)
        // this.showWS0274002_InspectCmtSearchInput(record)

      } else if (record.exam_type.slice(0, 1) === 'N') {
        // 直接入力系（N系）は何も呼ばない
        message.info('入力フォームに直接入力してください')
        return
        // 数値系結果値入力 モーダル
        // this.showWS2637015_InspectResultValueInput(record)

      } else {
        if (!this.state.disabledUpdateBtn) {
          this.saveResultValueList()
        }
        // 検査コメント照会以外(所見関連)のモーダル
        // 検査によって表示する編集用モーダルを切り替える
        this.switchEditModal(record)
      }
    }
  }

  /**
 * 検査コメント検索・照会　モーダルを開く
 */
  showWS0274001_InspectCmtSearchQuery = (record) => {
    // コメント区分(exam_comment_code)が0の場合はモーダルは表示しない
    if ((record.exam_comment_code ?? 0) > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 600,
          component: (
            <WS0274001_InspectCmtSearchQuery
              Li_PatternCode={this.props.paramObject.Li_PatternCode}
              Li_CategoryCode={record.category_code}
              Li_CategoryName={record.category_name}
              Lio_StsNotesChange={record.StsNotesChange}
              Lio_CmtClassify={record.exam_comment_code}
              LnkOutInspectCmtScreen={record.result_value}
              onFinishScreen={(output) => {
                // 選択された検査コメントを反映
                const value = output.LnkOutInspectCmtScreen
                // 結果値が変更になった場合
                this.changeResultValue(value, record)
                // モーダルを閉じる
                this.closeModal()
              }}
            />
          ),
        },
      })
    }
  }

  /**
   *　検査コメント照会　モーダル
   * @param {*} record
   */
  showWS0274002_InspectCmtSearchInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component:
          <WS0274002_InspectCmtSearchInput
            Li_ReserveNum={this.props.paramObject.Li_ReserveNum}
            Li_InspectCode={record.exam_code}
            Li_PatternCode={this.props.paramObject.Li_PatternCode}
            Li_CategoryCode={record.category_code}
            Li_CategoryName={record.category_name}
            Lio_StsNotesChange={record.StsNotesChange}
            Lio_CmtClassify={record.exam_comment_code}
            Lio_JudgmentValue={record.judgment_value}
            LnkOutInspectCmtScreen={record.result_value}
            onUpdateResultValue={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   *　結果値入力　モーダル
   * @param {*} record
   */
  showWS2637015_InspectResultValueInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 300,
        component:
          <WS2637015_InspectResultValueInput
            Li_ReserveNum={this.props.paramObject.Li_ReserveNum}
            Li_InspectCode={record.exam_code}
            Li_PatternCode={this.props.paramObject.Li_PatternCode}
            Li_CategoryCode={record.category_code}
            Lio_JudgmentValue={record.judgment_value}
            Lio_ResultValue={record.result_value}
            onUpdateResultValue={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
              // モーダルを閉じる
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   *
   * @param {*} record
   */
  switchEditModal = (record) => {
    const params = {
      ...this.props.paramObject,
      Li_JudgeLevel: record.Li_JudgeLevel,
      display_order: record.display_order,
      display_no: record.display_no,
      // W1_inspect_item_display_num: record.W1_inspect_item_display_num,
      pattern_code: record.pattern_code,
      category_code: record.category_code,
      personal_number_id: this.props.values.personal_number_id,
      exam_code: record.exam_code,
      GuideHowToAddOp: this.props.values.GuideHowToAddOp,
      StsInputModeFindings: this.props.values.StsInputModeFindings,
    }

    // exam_typeが「S」である場合
    OverallResultDisplayInputAction.findingsEditingBefore(params)
      .then((res) => {
        if (res) {
          const messagex = res.message ?? ''
          const variables = res.variables ?? null

          if (this.props.values.StsInputModeFindings === 1) {
            if (messagex === 'Call Screen WS0730001') {
              // 所見入力[医師診断]
              this.showWS0730001_FindingsInputPhysiciiagnosis(variables, record)
            }
            if (messagex === 'Call Screen WS0729001') {
              // 所見入力[読影]
              this.showWS0729001_FindingsInputRadiography(variables, record)
            }
            if (messagex === 'Call Screen WS0731001' || messagex === 'Call Screen WS0728001') {
              // 所見入力[通常]
              this.showWS0728001_FindingsInputNormal(variables, record)

              // ※「WS0731001_FindingsInputNormalSelect」は「WS0728001_FindingsInputNormal」と同じレイアウト、処理になるので統一する
              // 所見入力[通常選択]
              // this.showWS0731001_FindingsInputNormalSelect(variables, record)
            }
          }
        }
      })
  }

  /**
   * 所見入力[医師診断]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0730001_FindingsInputPhysiciiagnosis = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0730001_FindingsInputPhysiciiagnosis
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }

  /**
   * 所見入力[読影]　モーダル
   * @param {*} variables
   * @param {*} record
   */
  showWS0729001_FindingsInputRadiography(variables, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0729001_FindingsInputRadiography
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }

  /**
 * 所見入力[通常]　モーダル
 * @param {*} variables
 * @param {*} record
 */
  showWS0728001_FindingsInputNormal = (variables, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1100,
        component: (
          <WS0728001_FindingsInputNormal
            record={record}
            Li_ReserveNum={variables?.Li_ReserveNum}
            Li_PatternCode={variables?.Li_PatternCode}
            Li_CategoryCode={variables?.Li_CategoryCode}
            Li_InspectCode={variables?.Li_InspectCode}
            Li_CourseLevel={variables?.Li_CourseLevel}
            Li_JudgeLevel={variables?.Li_JudgeLevel}
            Li_PersonalNum={variables?.Li_PersonalNum}
            Li_FindingsInputNumDigits={variables?.Li_FindingsInputNumDigits}
            Li_FindingsInputNumRows={variables?.Li_FindingsInputNumRows}
            Lio_FindingsCategoryChange={variables?.Lio_FindingsCategoryChange}
            personalNumberId={this.props.values.personal_number_id}
            KanjiName={this.props.values.kanji_name}
            onUpdateFindings={() => {
              // 検査結果一覧を再取得
              // this.getInspectResultList(true)
              this.getInspectResultList()
            }}
          />
        ),
      },
    })
  }

  /**
  * 判定選択　(検査コード毎用)
  * @param {*} data
  */
  showWS0285001_JudgeQueryInspect = (record) => {
    const recordData = { ...this.props.record }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={Number(recordData.Li_JudgeLevel)}
            onFinishScreen={async (output) => {
              if (output.recordData) {
                // 判定 変更
                //   await this.formRef?.current?.setFieldsValue({
                //     judgmentValue: output.recordData.judgment_result
                //   })

                this.changeJudgmentValue(output.recordData.judgment_result, record)
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   *　判定一覧モーダルを開く（カテゴリ用）
   * @param {*} data
   * @param {*} index
   */
  showWS0285001_JudgeQueryCategory = () => {
    const data = { ...this.props.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={data?.judgment_level_division}
            Lio_Judge={data?.category_judge}
            onFinishScreen={async (output) => {
              if (output.recordData) {

                let judgmentResult = output.recordData.judgment_result
                if (judgmentResult === '') {
                  judgmentResult = '判定なし'
                }

                Modal.confirm({
                  content: `カテゴリ判定を [${output.recordData.judgment_result}] に変更します。よろしいですか？`,
                  okText: '保　存',
                  cancelText: 'キャンセル',
                  onOk: () =>
                    // カテゴリ判定を保存
                    this.saveJudgmentCategory(output.recordData)
                })
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * カテゴリ判定を保存
   * @param {*} value
   */
  saveJudgmentCategory = (value) => {
    const params = {
      Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
      Li_CategoryCode: this.props.record?.category_code,
      Li_CourseLevel: this.props.paramObject.Li_CourseLevel,
      Li_CategoryJudgment: value.judgment_result,
      Li_JudgeLevel: this.props.values.Li_JudgeLevel,
    }
    OverallResultDisplayInputAction.saveJudgmentCategory(params)
      .then((res) => {
        if (this.props.onUpdateCategory) {
          // 親画面で変更されたカテゴリ判定を反映する
          this.props.onUpdateCategory()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({ content: res.data.message })
      })
  }

  /**
   * 規定値の存在チェック
   */
  checkDefaultValue() {
    const record = { ...this.props.record }
    const values = { ...this.props.values }
    const params = {
      Li_ReserveNum: values.Li_ReserveNum,
      Li_PatternCode: record.pattern_code,
      Li_JudgeLevel: record.Li_JudgeLevel,
      Li_CategoryCode: record.category_code,
      Li_CategoryJudge: record.category_judge,
    }
    OverallResultDisplayInputAction.checkDefaultValue(params)
      .then(res => {
        const mess = 'Call Screen WS2644008'
        if (res) {
          if (res && res?.message === mess) {
            // 該当の規定値があれば、規定値確認モーダルを開く
            this.showWS2644008_SpecifiedValueConfirm(res.inspectList, res.format)
          } else {
            // 規定値設定が可能な検査が存在しません
            message.warning(res?.message)
          }
        }
      })
  }

  /**
   * 規定値確認　モーダル
   * @param {*} inspectList
   * @param {*} format
   */
  showWS2644008_SpecifiedValueConfirm = (inspectList = [], format = '') => {
    const record = { ...this.props.record }
    const values = { ...this.props.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2644008_SpecifiedValueConfirm
            inspectList={inspectList}
            format={format}
            Li_JudgeLevel={record.Li_JudgeLevel}
            Li_PatternCode={record.pattern_code}
            Li_CategoryCode={record.category_code}
            Li_ReserveNum={values.Li_ReserveNum}
            onFinishScreen={(output) => {
              if (output) {
                // モーダルで更新があった場合
                // 検査結果一覧を再取得
                this.getInspectResultList()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 前回の検査結果を反映する（全体）
   */
  setPreviousInspectDo = () => {
    const pre1ReserveNum = this.props.values.pre1ReserveNum

    if (pre1ReserveNum === '' || pre1ReserveNum === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果を反映します。よろしいですか？`,
        okText: '保　存',
        cancelText: 'キャンセル',
        onOk: () => {
          const record = { ...this.props.record }
          const params = {
            Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
            PreviousReserveNum: pre1ReserveNum,
            SelectCategory: record?.SelectCategory,
            Li_PatternCode: record?.pattern_code,
            Li_CategoryCode: record?.category_code
          }

          OverallResultDisplayInputAction.setPreviousExamCategoryCodeDo(params)
            .then((res) => {
              this.getInspectResultList()
            })
        }
      })
    }
  }

  /**
    * 前回の検査結果を反映する（検査単位）
   * @param {*} selectedRecord
   */
  setPreviousExamCodeDo = (selectedRecord) => {
    const pre1ReserveNum = this.props.values.pre1ReserveNum
    if (!this.state.disabledUpdateBtnv && pre1ReserveNum.exam_type === 'S') {
      this.saveResultValueList()
    }

    if (pre1ReserveNum === '' || pre1ReserveNum === null) {
      // 前回の検査結果が存在しない場合
      message.warning('前回検査結果は存在しません')
    } else {
      Modal.confirm({
        content: `前回の検査結果 [${selectedRecord.inspect_name}] を反映します。よろしいですか？`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          const record = selectedRecord
          const params = {
            Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
            PreviousReserveNum: pre1ReserveNum,
            Li_ExamCode: record?.exam_code,
            Li_ExamType: record?.exam_type,
          }
          OverallResultDisplayInputAction.setPreviousExamCodeDo(params)
            .then((res) => {
              // 前回DOを反映
              let copyData = [...this.state.inspectResultList]
              // 前回値がなかった場合は空にする
              if (res.dt0420InsertList.length > 0) {
                for (let i = 0; i < copyData.length; i++) {
                  for (let k = 0; k < res.dt0420InsertList.length; k++) {
                    if (res.dt0420InsertList[k].exam_code === copyData[i]?.exam_code) {
                      this.changeResultValue(res.dt0420InsertList[k].result_value, copyData[i])
                      this.changeJudgmentValue(res.dt0420InsertList[k].judgment_value, copyData[i])
                    }
                  }
                }
              } else {
                this.changeResultValue('', record)
              }

              // 所見の場合、画面更新
              if (record?.exam_type === 'S') {
                this.getInspectResultList()
              }
            })
        }
      })
    }
  }

  /**
   * 結果値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeResultValue(value, record) {
    record.inspectComments.map((element, index) => {
      let newElement = element.exam_comment_code.trim()
      if (value == newElement) {
        value = element.exam_comment_screen.trim()
      }
    })

    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (exam_type.slice(0, 1) === 'N' && value !== '') {
      // exam_typeが「N、N1、N2、N3」の場合
      resultValue = recalculate(exam_type, value)
    } else {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.result_value !== value) {
        // 検査結果値の設定
        element.result_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }

  /**
   * 検査ごとの判定値が変更になった場合
   * @param {*} event
   * @param {*} record
   */
  changeJudgmentValue(value, record) {
    let copyData = [...this.state.changeData]
    let id = record?.id
    let exam_type = record?.exam_type
    let resultValue
    let disabledChange = false

    if (value !== '') {
      // それ以外
      resultValue = value
    }

    copyData.forEach(element => {
      if (element.id === id && element.judgment_value !== value) {
        // 検査結果値の設定
        element.judgment_value = resultValue
        // 変更フラグ
        element.change_flg = 1
        // 変更フラグ（保存ボタン用）
        disabledChange = true
      }
    })

    if (disabledChange) {
      // 保存ボタン活性
      this.setState({ disabledUpdateBtn: false })
    }

    // 変更配列設定
    this.setState({ changeData: copyData, })
    // <Table>にも反映
    this.formRef.current?.setFieldsValue({ formInspectResultList: copyData })
  }

  /**
   * 変更された検査結果の更新
   */
  saveResultValueList = () => {
    const params = {
      Li_CourseLevel: this.props.paramObject.Li_CourseLevel,
      Li_ReserveNum: this.props.paramObject.Li_ReserveNum,
      changeData: this.state.changeData,
      saveFlag: this.state.saveFlag
    }
    OverallResultDisplayInputAction.saveResultValueList(params)
      .then((res) => {
        // カテゴリ別の詳細な検査結果一覧を取得
        this.getInspectResultList(true)
        this.setState({ saveFlag: false })
      })
  }

  /**
  * 変更履歴を表示する
  */
  showInspectionResultsChangeHistory = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <InspectionResultsChangeHistory
            reservationNumber={this.props.Li_ReserveNum}
            examCode={record.exam_code}
          />
        ,
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='inspect-result'>
        <Card
          // title={`検査別結果一覧 【${this.props.record.category_name}】`}
          title={`${this.props.record?.category_name} : [${this.props.values?.personal_number_id}] ${this.props.values?.kanji_name}`}
          style={{ position: 'relative', minWidth: '890px' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ alignItems: 'center' }}>
                <Descriptions
                  bordered
                  size='small'
                  title=''
                  style={{ width: '320px' }}
                >
                  <Descriptions.Item
                    label='カテゴリ判定'
                    labelStyle={{ width: '130px' }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        width: '100%',
                        textAlign: 'center',
                        color: Color(this.props.record?.categoryJudgeColorNum)?.Foreground
                      }}
                    >
                      {this.props.record?.category_judge}
                    </div>
                  </Descriptions.Item>
                </Descriptions>
                <Button
                  type='primary'
                  icon={<EditOutlined />}
                  onClick={() => this.showWS0285001_JudgeQueryCategory()}
                  hidden={this.props.protectFlag}
                >
                  判定
                </Button>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.inspectResultList}
                rowKey={record => record?.id}
                pagination={false}
                scroll={{ y: '500px' }}
              >
                <Table.Column
                  dataIndex='inspect_name'
                  title='検査名称'
                  width={155}
                  render={(value, record, index) => (
                    <Tooltip title={record?.inspectNameTooltip}>
                      <div style={{ textAlign: 'left' }}>
                        <div style={{
                          marginLeft: 5,
                          fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : ''
                        }}>
                          {(record?.imageNum === '') ?
                            null
                            :
                            <img src={GetImage(record?.imageNum)} alt='icon' />
                          }
                          {value}
                        </div>
                      </div>
                    </Tooltip>
                  )}
                />

                <Table.Column
                  dataIndex='normal_value'
                  title='参考値'
                  width={140}
                  render={(item, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                      {record.normal_value}
                      {record.dataConfirmFlag === 0 ?
                        null
                        :
                        <span
                          style={{
                            float: 'right',
                            fontWeight: record?.resultValueFontWeight === 82 ? 'bold' : ''
                          }}>
                          *
                        </span>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  className='this-time'
                  render={(value, record, index) => (
                    <Tooltip title={record.valueTooltip}>
                      {(record.exam_type === 'S') ?
                        // 所見 (モーダルを開いて値を変更)
                        <div
                          style={{
                            textAlign: 'center',
                            fontWeight: (record?.resultValueFontWeight === 82) ? 'bold' : '',
                            color: Color(record.judgmentValueColorNum)?.Foreground,
                            border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {value}
                        </div>
                        :

                        <div
                          name={['formInspectResultList', index, 'judgment_value']}
                          onDoubleClick={() => {
                            if (!this.props.protectFlag) {
                              // 編集画面の表示処理
                              this.showWS0285001_JudgeQueryInspect(record)
                            }
                          }}
                          style={{
                            textAlign: 'center',
                            fontWeight: (record?.resultValueFontWeight === 82 || (record.change_flg ?? 0)) ? 'bold' : '',//変更時は太字
                            color: Color(record.judgmentValueColorNum)?.Foreground,
                            border: `${(record.Expression_22 === 1) ? '0.3px solid #A9A9A9' : ''}`,
                            whiteSpace: 'pre-wrap',
                            cursor: 'pointer'
                          }}
                        >
                          {value ? value : '　'}
                        </div>
                      }
                    </Tooltip>
                  )}
                />

                <Table.Column
                  className='this-time'
                  dataIndex='result_value'
                  title={
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {this.props.values?.headerTitle}
                    </div>
                  }
                  render={(value, record, index) => (
                    <Tooltip title={record.valueTooltip}>
                      {(record.exam_type === 'S' || (this.props.protectFlag && (record.exam_type.slice(0, 1) !== 'N'))) ?
                        // 所見 (モーダルを開いて値を変更)
                        // または、ロック時 かつ exam_typeが「N」以外
                        <div
                          style={{
                            color: Color(record.resultValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap',
                            padding: '5px 8px'
                          }}
                          onDoubleClick={() => {
                            // 編集画面の表示処理
                            this.showEditModal(record)
                          }}
                        >
                          {value}
                        </div>
                        :
                        (record.StsAutomaticCalculate || ((record.exam_type.slice(0, 1) === 'N') && this.props.protectFlag)) ?
                          // 自動計算あり（BMIや基準体重など）入力なし
                          // または、ロック時 かつ exam_typeが「N」
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              style={{
                                width: '30px',
                                cursor: 'pointer',
                                color: Color(record.upAndDownColorNum)?.Foreground,
                                textAlign: 'center'
                              }}>
                              {record.UpAndDown}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', width: '100%' }}>
                              <div
                                style={{
                                  color: Color(record.resultValueColorNum)?.Foreground,
                                  width: '6rem',
                                  textAlign: 'right'
                                }}
                              >
                                {value}
                              </div>
                            </div>
                          </div>
                          :
                          // 値の変更、入力あり
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            onDoubleClick={() => {
                              // 編集画面の表示処理
                              this.showEditModal(record)
                            }}
                          >
                            <div
                              style={{
                                display: (record.UpAndDown !== '') ? '' : 'none',
                                width: '30px',
                                cursor: 'pointer',
                                color: Color(record.upAndDownColorNum)?.Foreground,
                                textAlign: 'center'
                              }}>
                              {record.UpAndDown}
                            </div>
                            <Form.Item
                              name={['formInspectResultList', index, 'result_value']}
                              style={{ marginBottom: '0' }}
                            >
                              <Input
                                id={'spreadInput-2-' + record.id}
                                type='tel'
                                onKeyDown={(e) => moveFocusKeyEvent(e, 'spreadInput', 1, true, 0)}
                                onPressEnter={(e) => this.changeResultValue(e.target.value, record)}
                                onBlur={(e) => this.changeResultValue(e.target.value, record)}
                                style={{
                                  textAlign: (record.exam_type.slice(0, 1) === 'N') ? 'right' : 'left',
                                  fontWeight: (record.change_flg ?? 0) ? 'bold' : 'normal', //変更時は太字
                                }}
                              />
                            </Form.Item>
                          </div>
                      }
                    </Tooltip>
                  )}
                />

                <Table.Column
                  dataIndex='pre_1_judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre1JudgmentValueColorNum)?.Foreground
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_1_result_value'
                  title={
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {this.props.values?.pre1HeaderTitle}
                    </div>
                  }
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right'
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre1JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap'
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_2_judgment_value'
                  title='判'
                  width={50}
                  align='center'
                  render={(value, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        color: Color(record.pre2JudgmentValueColorNum)?.Foreground
                      }}>
                      {value}
                    </div>
                  )}
                />

                <Table.Column
                  dataIndex='pre_2_result_value'
                  title={
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {this.props.values?.pre2HeaderTitle}
                    </div>
                  }
                  render={(value, record) => (
                    <div style={{ display: 'flex' }}>
                      {(record.exam_type.slice(0, 1) === 'N') ?
                        //exam_type:N or N1 以外は非表示
                        <div
                          style={{
                            color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                            width: '7rem',
                            textAlign: 'right'
                          }}>
                          {value}
                        </div>
                        :
                        <div
                          style={{
                            color: Color(record.pre2JudgmentValueColorNum)?.Foreground,
                            whiteSpace: 'pre-wrap'
                          }}>
                          {value}
                        </div>
                      }
                    </div>
                  )}
                />

                {(!this.props.protectFlag) ?
                  <Table.Column
                    width={40}
                    style={{ textAlign: 'center' }}
                    render={(value, record, index) => (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                              onClick={() => {
                                // 編集画面の表示処理
                                this.showEditModal(record)
                              }}
                            >
                              編集
                            </Menu.Item>

                            <Menu.Item
                              key='2'
                              disabled={record.StsAutomaticCalculate} //自動計算ありの場合、非活性
                              onClick={() => {
                                this.setPreviousExamCodeDo(record)
                              }}
                            >
                              前回DO
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )}
                  />
                  :
                  //ロック時は非表示
                  null
                }
              </Table>
            </div>

            {/* Footer */}
            <div
              className='box_button_bottom_right'
              style={{ display: this.props.protectFlag ? 'none' : '' }} //ロックがかかっている場合、非表示
            >
              <Button
                type='primary'
                onClick={() => this.checkDefaultValue()}
              >
                規定値
              </Button>

              <Button
                type='primary'
                onClick={() =>
                  // 前回の検査結果を反映
                  this.setPreviousInspectDo()
                }
              >
                前回DO
              </Button>

              <Button
                icon={<SaveOutlined />}
                type='primary'
                disabled={this.state.disabledUpdateBtn}
                onClick={() =>
                  // 変更した検査結果を反映
                  this.saveResultValueList()
                }
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          component={this.state.childModal.component}
          visible={this.state.childModal.visible}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637014_InspectResult)
