import PersonalInfoMaintainService from 'services/basicInfo/PersonalInfoMaintain/PersonalInfoMaintainService'
import { message } from 'antd'

const PersonalInfoMaintainAction = {

  index() {
    return PersonalInfoMaintainService.index()
  },

  getPersonalInfo(personal) {
    return PersonalInfoMaintainService.getPersonalInfo(personal)
      .then((res) => {
        //console.log(res.data)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  registerPersonal(personal) {
    return PersonalInfoMaintainService.registerPersonal(personal)
      .then((res) => {
        message.success('完了！')
        //console.log(res.data)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  updatePersonalInfo(personal) {
    return PersonalInfoMaintainService.updatePersonalInfo(personal)
      .then((res) => {
        message.success(res.data.message)
        //console.log(res.data.message)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  updateDetails(personal) {
    return PersonalInfoMaintainService.updateDetails(personal)
      .then((res) => {
        message.success(res.data.message)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  updateBelongs(personal) {
    return PersonalInfoMaintainService.updateBelongs(personal)
      .then((res) => {
        message.success(res.data.message)
        //console.log(res.data.message)
        return res.data
      })
      .catch((err) => {
        message.error(err.response.data.message)
        console.log(err.response.data.message)
      })
  },

  getPersonalInfoSearch(data) {
    return PersonalInfoMaintainService.getPersonalInfoSearch(data)
      .then((res) => {
        // console.log(res.data)
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getPersonalInfoSearchOffice(data) {
    return PersonalInfoMaintainService.getPersonalInfoSearchOffice(data)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getVariousNamesList() {
    return PersonalInfoMaintainService.getVariousNamesList()
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },

  setAddressToDefault(params) {
    return PersonalInfoMaintainService.setAddressToDefault(params)
  },

  deleteSpecials(params) {
    return PersonalInfoMaintainService.deleteSpecials(params)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getNamePostalCode(params) {
    return PersonalInfoMaintainService.getNamePostalCode(params)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteNotification(params) {
    return PersonalInfoMaintainService.deleteNotification(params)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteExecution(params) {
    return PersonalInfoMaintainService.deleteExecution(params)
      .then((res) => {
        message.success(res.data.message)
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteBelongs(params) {
    return PersonalInfoMaintainService.deleteBelongs(params)
      .then((res) => {
        message.success(res.data.message)
        return res.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}

export default PersonalInfoMaintainAction
