import axios from 'configs/axios'

const API_LIST = {
  index: '/api/east-fukyo-converse-process/higashifukyo-data-input',
  getInspectResults: '/api/east-fukyo-converse-process/higashifukyo-data-input/get-inspect-results',
}

const HigashifukyoDataInputService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },

  async getInspectResults(params) {
    return axios.get(API_LIST.getInspectResults, { params })
  },
}

export default HigashifukyoDataInputService
