import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Color from 'constants/Color'
import DataGrid, { Column, ColumnChooser, ColumnFixing, Selection } from 'devextreme-react/data-grid';
import { Card, Col, Form, Input, Row, Table, Button, Menu, Dropdown, message } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'

const styleContentTableColumn = {
  color: Color(209).Foreground,
  fontWeight: 'lighter',
  fontSize: '18px',
  marginBottom: '5px',
  height: '22px',
  textAlign: 'center'
}

const styleDivTitle = {
  background: '#1C66B9',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em',
  color: '#FFFFFF'
}

const styleDivResult = {
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em'
}

const styleDivExpression = {
  width: '50px',
  height: '24px',
  border: '1px solid #ABADB3',
  textAlign: 'center',
  lineHeight: '22px',
}

class WS2620100_ReservationSelect extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataSource: [],
    }
  }

  componentDidMount = () => {
    this.setState({
      dataSource: this.props.VisitList
    })
  }

  render() {
    return (
      <div className='counter'>
        <Card title='受付選択'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <DataGrid
              style={{ height: 'calc(100vh - 215px)' }}
              dataSource={this.state.dataSource}
              showBorders={true} //周りの線
              showRowLines={true} //横線
              wordWrapEnabled={true} //折り返し
              allowColumnReordering={true} //カラム移動
              allowColumnResizing={true} //カラムの幅リサイズ
              columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
              columnAutoWidth={true}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              paging={{
                // ペジネーション設定
                enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
              }}
              onRowPrepared={(record) => {
                // 行ごとの背景色設定
                if (record.rowType === 'data') {
                  let color = ''
                  // クラスネームが効かないので直接カラーコードを入れる
                  if (record.data.id === this.state.selectedId) {
                    color = '#b5dbff';  //table-row-light
                  } else if (record.data.receipt_number > 0) {
                    color = '#ecf8ff'; //table-row-accepted
                  }
                  record.rowElement.style.backgroundColor = color
                }
              }}
              scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない　無限スクロール対応 それでも設定の入れ方によっては重くなる
              onRowDblClick={(event) => {
                const record = event.data
                this.props.onFinishScreen(record)
              }}
            >
              <Column
                caption='ID'
                cssClass='column-size-10'
                dataField='personal_number_id'
                cellRender={(event) => (
                  <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value}</div>
                )}
              />
              <Column
                caption='受付No'
                cssClass='column-size-5'
                dataField='receipt_number'
                cellRender={(event) => (
                  <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value}</div>
                )}
              />
              <Column
                caption='時間'
                cssClass='column-size-4'
                dataField='period_time'
              />
              <Column
                caption='氏名'
                cssClass='column-size-40'
                dataField='kanji_name'
              />
              <Column
                caption='カナ氏名'
                cssClass='column-size-40'
                dataField='kana_name'
              />
              <Column
                caption='性別'
                cssClass='column-size-4'
                dataField='sex'
                cellRender={(value) => {
                  return (
                    <div style={{
                      textAlign: 'center',
                      color: Color(value.data.sex_backGroundColor)?.Foreground
                    }}>
                      {value.value}
                    </div>
                  )
                }}
              />
              <Column
                caption='生年月日'
                cssClass='column-size-10'
                dataField='birthday'
              />
              <Column
                caption='年齢'
                cssClass='column-size-4'
                dataField='Age'
                cellRender={(value) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value.value}</div>
                  )
                }}
              />
              <Column
                caption='コース'
                cssClass='column-size-60'
                dataField=''
                cellRender={(text) => (
                  <div>
                    {text.data.visit_course} {text.data.contract_short_name}
                  </div>
                )}
              />
              <Column
                caption='事業所'
                cssClass='column-size-60'
                dataField='office_kanji_name'
              />
              <Column
                caption='請求額'
                cssClass='column-size-10'
                dataField='Expression_21'
                cellRender={(event) => (
                  <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value.toLocaleString()}</div>
                )}
              />
              <Column
                caption='受付'
                cssClass='column-size-4'
                dataField='Expression_23'
                cellRender={(event) => (
                  <div
                    style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                  >
                    {event.value}
                  </div>
                )} />
              <Column
                caption='入金'
                cssClass='column-size-4'
                dataField='Expression_25'
                cellRender={(event) => (
                  <div
                    style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                  >
                    {event.value}
                  </div>
                )}
              />
              <Column
                caption='領収'
                cssClass='column-size-4'
                dataField='Expression_27'
                cellRender={(event) => (
                  <div
                    style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                  >
                    {event.value}
                  </div>
                )}
              />
            </DataGrid>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2620100_ReservationSelect)