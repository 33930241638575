import axios from 'configs/axios'

const apiPaths = {
  index: '/api/contract-info-maintain/history-switching',
  getDataAll: '/api/contract-info-maintain/history-switching/get-data-all'
}

const HistorySwitchingService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },
  async getDataAll() {
    return axios.get(apiPaths.getDataAll)
  },
}

export default HistorySwitchingService
