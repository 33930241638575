import axios from 'configs/axios'

const apiPaths = {
  index: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain',
  getAmountMoneyList: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/get-amount-money-list',
  save: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/save',

  getItemSettings: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/get-item-settings',
  getItemSpecify: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/get-item-specify',
  saveItemSettings: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/save-item-settings',
  deleteItemSettings: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/delete-item-settings',
  saveItemSpecify: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/save-item-specify',
  deleteItemSpecify: '/api/mutual-aid-association-billing/mutual-aid-subject-billing-unit-price-maintain/delete-item-specify',
}

const MutualAidSubjectBillingUnitPriceMaintainService = {
  async index() {
    return axios.get(apiPaths.index)
  },
  async getAmountMoneyList() {
    return axios.get(apiPaths.getAmountMoneyList)
  },
  async save(params) {
    return axios.post(apiPaths.save, params)
  },

  async getItemSettings(params) {
    return axios.get(apiPaths.getItemSettings, { params })
  },
  async getItemSpecify(params) {
    return axios.get(apiPaths.getItemSpecify, { params })
  },
  async saveItemSettings(params) {
    return axios.post(apiPaths.saveItemSettings, params)
  },
  async deleteItemSettings(params) {
    return axios.delete(apiPaths.deleteItemSettings, { params })
  },
  async saveItemSpecify(params) {
    return axios.post(apiPaths.saveItemSpecify, params)
  },
  async deleteItemSpecify(params) {
    return axios.delete(apiPaths.deleteItemSpecify, { params })
  },
}
export default MutualAidSubjectBillingUnitPriceMaintainService
