import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";

import {
  Card, Table, message, Button, Form, Input, Select, Popconfirm, Space,
  Modal,
} from "antd";
import ModalChange from "components/Commons/ModalChange"
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import ModalAdd from "components/Commons/ModalAdd"

import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import ImplementDivisionMasterAction from "redux/SpecificInsureMaintenance/ImplementationDivisionMaster/ImpementDivisionMaster.action";
import { useParams } from "react-router-dom";


const columns = [
  {
    title: '実施区分コード',
    dataIndex: 'implement_division_code',
    classNumber: '50',
    // textAlign: 'right',
    type: 1,
  },

  {
    title: '実施区分',
    dataIndex: 'implement_division',
    classNumber: '60',
    type: 1,
  },

]

class WS1404001_ImplementDivisionMaster extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '実施区分マスタ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    ImplementDivisionMasterAction.GetTreeData()
      .then(res => {
        this.setState({
          dataSource: res.MainSource
        })
        this.formRef.current.setFieldsValue({
          MainSource: res.MainSource,
        });
      })
      .then(() => this.setState({ isLoading: false }));
  }

  //insert 新規作成
  Insert = (values) => {
    this.setState({ isLoading: true });
    ImplementDivisionMasterAction.AddNewDivision(values)
      .then(res => {
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }


  //record　更新
  Save = (record, newValues) => {
    this.setState({ isLoading: true });
    ImplementDivisionMasterAction.postDivision({ ...newValues, ...record })
      .then(res => {
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }


  //record　削除
  Delete = (values) => {
    this.setState({ isLoading: true });
    ImplementDivisionMasterAction.deleteDivisionInfo({ id: Number(values.id) })
      .then(() => {
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  render() {
    return (
      <div className="insure-guide-course">
        <Card className="mb-2" title="実施区分マスタ">
          <Form ref={this.formRef}>
            {/* <Form ref={this.formRef} onFinish={this.onFinish}> */}
            <Table bordered size='small'
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                onChange: (page, pageSize) => { },
                onShowSizeChange: (page, pageSize) => { },
              }}
              rowKey={(record) => (record.id || Math.random())}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '550px',
                      className: '',
                      component:
                        <ModalChange
                          columns={columns}
                          record={record}
                          onUpdate={(output) => {
                            if (!output.itemChange.implement_division_code) {
                              message.error('実施区分コードが入力されていません');
                            } else {
                              this.Save(record, output)
                              this.closeModal()
                            }
                          }}
                          onDelete={(output) => {
                            this.Delete(record)
                            this.closeModal()
                          }}
                        />
                    },
                  })
                }
              })}
            >
              {columns.map((item, index) => (
                item.type === 1 ?
                  <Table.Column
                    key={'input' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign ?? ''}
                    sorter={(a, b) => a[item.dataIndex].localeCompare(b[item.dataIndex], 'ja')}
                  /> : item.type === 2 ?
                    <Table.Column
                      key={'select' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      className={'column-size-' + item.classNumber}
                      align={item.textAlign}
                      sorter={(a, b) => parseInt(a[item.dataIndex]) - parseInt(b[item.dataIndex])}
                      render={(value, record) => {
                        // findを使ってvalueを元に検索する
                        let findItem = item.selectList.find(selected => selected.value === value);
                        // labelを指定してreturn
                        return findItem.label
                      }}
                    /> : null
              ))}
              <Table.Column
                fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {
                                if (!output.itemChange.implement_division_code) {
                                  message.error('実施区分コードが入力されていません');
                                } else {
                                  this.Insert(output.itemChange)
                                  this.closeModal()
                                }
                              }}
                            />
                        },
                      });
                    }} />} dataIndex="" align="center" width={30} render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集、削除
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalChange
                                  columns={columns}
                                  record={record}
                                  onUpdate={(output) => {
                                    if (!output.itemChange.implement_division_code) {
                                      message.error('実施区分コードが入力されていません');
                                    } else {
                                      this.Save(record, output)
                                      this.closeModal()
                                    }
                                  }}
                                  onDelete={(output) => {
                                    this.Delete(record)
                                    this.closeModal()
                                  }}
                                />
                            },
                          });
                        }}
                      />

                    )} />
            </Table>

            {/* <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              <Button type="primary" htmlType="submit" loading={this.state.isLoading}>保存</Button>
            </Form.Item> */}
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS1404001_ImplementDivisionMaster;
