import React from "react";
import { connect } from "react-redux";
import MenuCustomizationAction from 'redux/MN_Basis/MenuCustomization/MenuCustomization.action';
import { getMenuList, getMyMenuList } from "redux/user/user.actions";
import { Card, Form, Table, Button, Input, message, Checkbox, Tooltip, Modal, Spin } from "antd";
import { SaveOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import changeHankakuText from 'components/Commons/changeHankakuText'

class WS2692001_MenuCustomization extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '';

    this.state = {
      myMenusList: [],
      isLoadingTable: false,
      changedValue: [],
      selectedId: '',
      selectedRowIndex: '',
      setDropOption: '',
      newRowCount: '',
    };
  }

  componentDidMount() {
    this.index();
  }

  index() {
    this.setState({ isLoadingTable: true })
    let param = {
      userName: this.props.userName
    }
    MenuCustomizationAction.index(param)
      .then((res) => {
        if (res.length === 0) {
          this.setState({
            myMenusList: [],
            changedValue: [],
            selectedId: 0,
          })
        } else {
          this.formRef.current?.setFieldsValue({
            dataTable: res ? res : []
          })
          this.setState({
            myMenusList: res ? res : [],
            changedValue: [],
            selectedId: res[0].id,
          })
        }
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingTable: false }))
  }

  selectIndex(id) {
    if (id === "") return
    let data = this.state.myMenusList;
    for (let i = 0; i < data.length; i++) {
      if (id === data[i].id) {
        break;
      }
    }
  }

  eventData(event, index, name, inputFlag) {
    let eventValue
    if (inputFlag) {
      eventValue = event.target.value;
      if (this.state.setDropOption !== '') {
        eventValue = this.state.setDropOption
        name = 'options';
      }
      eventValue = eventValue.replace(/\\/g, '/').replace(/^[^/]*\/\/[^/]*/, '').replace(/[?#].*?$/, '');
    } else {
      eventValue = event.target.checked ? 1 : 0;
    }

    // 変更していないのにchangeDataを呼ばないようにするための処理
    let myMenu = [...this.state.myMenusList];
    let orgValue = myMenu[index][name]
    if (orgValue !== eventValue) {
      this.addChangeData(eventValue, index, name, inputFlag)
    }
  }

  addChangeData(eventValue, index, name, inputFlag) {
    let myMenu = [...this.state.myMenusList];
    let dataObj = myMenu[index];
    let changeArray = [...this.state.changedValue];
    if (inputFlag) {
      // 名前で比較をする
      if (name === 'display_order') {
        dataObj[name] = Number(eventValue);
      } else if (name === 'options' && eventValue !== '') {
        let path = eventValue;
        if (path === undefined) return;
        let copy = [...this.props.menuList];
        let submenuList;
        let submenuList2;
        let obj = null;
        // for文で一致するパスを取る
        for (let i = 0; i < copy.length; i++) {
          submenuList = copy[i].submenu;
          // さらに絞り込みをするために取った値をfor文で回す
          for (let k = 0; k < submenuList.length; k++) {
            submenuList2 = submenuList[k].submenu;
            if (path === submenuList[k].path) {
              obj = {
                published_name: submenuList[k].published_name ? submenuList[k].published_name : [],
                name: submenuList[k].name ? submenuList[k].name : [],
                options: path
              }
              break;
            }
            if (submenuList2 === undefined) continue;
            // さらに絞り込みをするために取った値をfor文で回す
            for (let j = 0; j < submenuList2.length; j++) {
              if (path === submenuList2[j].path) {
                obj = {
                  published_name: submenuList2[j].published_name ? submenuList2[j].published_name : [],
                  name: submenuList2[j].name ? submenuList2[j].name : [],
                  options: path
                }
                break;
              }
            }
          }
        }
        // パスから公開名や名称を取得できなかったとき入力された値をobjに入れる
        if (obj === null) {
          obj = {
            options: path
          }
        }
        let menuObj = { ...myMenu[index], ...obj };
        dataObj = menuObj;
        myMenu.splice(index, 1, dataObj);
      } else {
        dataObj[name] = eventValue;
      }
    } else {
      dataObj[name] = eventValue;
    }
    // 同じidにpushをしないように比較をする
    let changeBool = false;
    let keyIndex;
    for (let j = 0; j < changeArray.length; j++) {
      if (changeArray[j].id === dataObj.id) {
        changeBool = true;
        keyIndex = j;
        break;
      }
    }
    // 仮のデータにpushする
    if (changeBool && changeArray) {
      changeArray.splice(keyIndex, 1, dataObj);
    } else {
      changeArray.push(dataObj);
    }
    this.setState({
      myMenusList: myMenu,
      changedValue: changeArray,
      setDropOption: ''
    })
    this.formRef.current?.setFieldsValue({
      dataTable: myMenu,
    });
    if (this.checkDisplayOrder()) {
      message.error('連番が重複しています');
    }
  }

  saveData() {
    let param = {
      Li_menuObj: this.state.changedValue,
      userName: this.props.userName
    }

    console.log(this.state.changedValue);

    MenuCustomizationAction.saveData(param)
      .then((res) => {
        this.setState({
          changedValue: []
        })
        this.index();
        this.props.getMenuList();
        this.props.getMyMenuList({ userName: param.userName });
        message.success('更新しました。');
      }).catch((err) => {
        const res = err.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  deleteData(id) {
    let param = {
      Li_selectedId: id
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        MenuCustomizationAction.deleteData(param)
          .then((res) => {
            this.index()
            this.setState({
              changeData: [[], []]
            })
            this.props.getMenuList();
            message.success('正常に削除されました');
          }).catch((err) => {
            message.error(err.response.data.message);
          })
      }
    });
  }

  onDrop(event) {
    // ドラッグアンドドロップしたときに流れる処理
    let eventValue = event.dataTransfer.getData("text");
    if (eventValue === undefined) return;
    this.setState({
      setDropOption: eventValue
    })
  }

  checkAddItem() {
    if (this.state.myMenusList.length > 0) {
      let index = -1
      if (this.state.myMenusList.length > 0) {
        index = this.state.myMenusList.findIndex(x => !x.display_order);
      }
      if (index === -1) {
        return false;
      }
      return true
    }
  }

  checkDisplayOrder() {
    let lstData = [...this.state.myMenusList];

    const uniqueValues = new Set(lstData.map(v => v.display_order));
    const uniqueIds = new Set(lstData.map(v => v.id));


    if (uniqueIds.size < lstData.length || uniqueValues.size < lstData.length) {
      return true;
    }
    return false;
  }

  async createLineTable() {
    let myMenuList = [...this.state.myMenusList]

    let LastMyMenuList = myMenuList[myMenuList.length - 1]

    let newRow = {
      id: LastMyMenuList ? ('newRow' + LastMyMenuList.id + 1) : ('newRow' + 0),
      display_order: LastMyMenuList ? (LastMyMenuList.display_order + 1) : (1)
    };

    let setIndex = 0;

    if (myMenuList.length > 0) {
      setIndex = this.state.selectedRowIndex + 1;
    }
    myMenuList.splice(setIndex, 0, newRow);

    this.formRef.current?.setFieldsValue({
      dataTable: myMenuList
    })

    await this.setState({
      myMenusList: myMenuList,
      newRowCount: LastMyMenuList ? (LastMyMenuList.id + 1) : 1
    });

    let dataCopy = [...myMenuList]

    this.setState({
      myMenusList: dataCopy
    })

    this.formRef.current?.setFieldsValue({
      dataTable: dataCopy,
    })

    // 新しい行を選択状態にする
    this.setState({
      selectedId: newRow.id,
    })
  }

  deleteCreateLine(index) {
    let myMenusList = [...this.state.myMenusList];
    let deleteId = myMenusList[index].id;
    let deleteIndex = null;
    // 追加した行を取るためのfor文
    for (let j = 0; j < myMenusList.length; j++) {
      if (deleteId === myMenusList[j].id) {
        deleteIndex = j;
        break;
      }
    }
    if (myMenusList.length > 0) {
      myMenusList.splice(deleteIndex, 1);
    }

    let dataCopy = [...myMenusList]

    if (dataCopy.length > 0) {
      this.setState({
        myMenusList: dataCopy,
        selectedId: dataCopy[0].id,
      })
      this.formRef.current?.setFieldsValue({
        dataTable: dataCopy,
      });
      this.selectIndex(dataCopy[0].id);
    }
  }

  setValueHankaku(e, index, targetInputName,) {
    let value = this.formRef.current?.getFieldValue('dataTable')

    let hankakuValue = changeHankakuText(e.target.value)
    value[index][targetInputName] = hankakuValue

    this.formRef.current?.setFieldsValue({ dataTable: value })

    //this.eventData(e, index, "display_order", true)
    this.forceUpdate()

  }


  render() {
    return (
      <div className='menucustomization' >
        <Spin spinning={this.state.isLoadingTable}>
          <Card title={'マイメニューカスタマイズ'}>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              style={{ padding: '5px' }}
              autoComplete='off'
            >
              <Table
                style={{ cursor: 'pointer' }}
                bordered
                id="menucustomizationTable"
                rowKey={(record) => record.id}
                size="small"
                dataSource={this.state.myMenusList}
                rowClassName={(record, index) => record.id === this.state.selectedId ? 'table-row-light' : ''}
                pagination={false}
                scroll={{ y: resizableTableScroll(80) }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: async (event) => {
                      let id = record.id
                      if (record.id === "") {
                        return
                      } else {
                        this.selectIndex(id)
                        await this.setState({
                          selectedId: record.id,
                          selectedRowIndex: rowIndex
                        })
                      }
                    }
                  }
                }}
              >

                <Table.Column
                  title="無効"
                  align='center'
                  width={50}
                  dataIndex="invalid"
                  render={(row, record, index, value) => {
                    return (
                      <Form.Item
                        name={["dataTable", index, 'invalid']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          name="invalid"
                          onBlur={(event) => this.eventData(event, index, "invalid", false)}
                        />
                      </Form.Item>
                    )
                  }}
                />

                <Table.Column
                  title="連番"
                  width={80}
                  dataIndex="display_order"
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["dataTable", index, "display_order"]}>
                        <Input
                          name="display_order"
                          style={{ textAlign: 'right' }}
                          maxLength={10}
                          // onDrop={(event) => this.onDrop(event)}
                          // onChange={(event) => this.eventData(event, index, "display_order", true)}
                          onBlur={(e) => {
                            this.setValueHankaku(e, index, 'display_order')
                            this.eventData(e, index, "display_order", true)
                          }}
                          onPressEnter={(e) => this.setValueHankaku(e, index, 'display_order')}
                        />
                      </Form.Item>
                    )
                  }}
                />

                <Table.Column
                  title="名称"
                  width={300}
                  dataIndex="name"
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["dataTable", index, "name"]}>
                        <Input
                          name="name"
                          maxLength={50}
                          onDrop={(event) => this.onDrop(event)}
                          onChange={(event) => this.eventData(event, index, "name", true)}
                        />
                      </Form.Item>
                    )
                  }}
                />

                <Table.Column
                  title="オプション"
                  dataIndex="options"
                  render={(row, record, index) => {
                    return (
                      <Form.Item name={["dataTable", index, "options"]}>
                        <Input
                          name="options"
                          onDrop={(event) => this.onDrop(event)}
                          onChange={(event) => this.eventData(event, index, "options", true)}
                        />
                      </Form.Item>
                    )
                  }}
                />

                {/* 追加ボタン */}
                <Table.Column
                  width={40}
                  fixed={'right'}
                  title={
                    (<div style={{ textAlign: "center" }}>
                      <Tooltip
                        placement="topLeft"
                        title={'追加'}
                      >
                        <Button
                          disabled={this.checkAddItem() || this.checkDisplayOrder()}
                          onClick={() => { this.createLineTable() }}
                          type="primary"
                          icon={<PlusOutlined />}
                          size="small"
                        >
                        </Button>
                      </Tooltip>
                    </div>)
                  }
                  // 削除ボタン
                  render={(row, record, index) => {
                    return (
                      <Form.Item style={{ textAlign: 'center' }}>
                        <Button
                          style={{ color: 'red' }}
                          icon={<DeleteOutlined />}
                          size="small"
                          onClick={() => {
                            (String(record.id).indexOf('newRow') > -1) ? this.deleteCreateLine(index) : this.deleteData(record.id)
                          }}
                        />
                      </Form.Item>
                    )
                  }}
                />
              </Table>
            </Form>

            <div className='box_button_bottom_right'>
              {/* 保存ボタン */}
              <Button
                type="primary"
                disabled={this.checkAddItem() || this.checkDisplayOrder()}
                width={500}
                icon={<SaveOutlined />}
                onClick={() => {
                  this.saveData();
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>

          </Card>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  menuList: userReducer.menuList,
  userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
  getMenuList: () => dispatch(getMenuList()),
  getMyMenuList: username => dispatch(getMyMenuList(username))
});
export default connect(mapStateToProps, mapDispatchToProps)(WS2692001_MenuCustomization);
