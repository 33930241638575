import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Row } from "antd";

class WS1567009_OutputDestinationAcquisition extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '出力先取得';

    this.state = {
    };
  }

  onFinish(values) {

  }
  
  confirmProc = () => {
    this.props.onFinishScreen()
  }

  cancel = () => {
    this.props.onFinishScreen()
  }

  render() {
    return (
      <div className="output-destination-acquisition">
        <Card title="出力先取得" className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Form.Item
              name=""
              label="ファイル名"
            >
              <Input type="text" />
            </Form.Item>
            <Row gutter={24}>
              <Form.Item style={{ marginLeft: 'auto', marginRight: 10 }}>
                <Button onClick={this.confirmProc} type="primary">決定</Button>
              </Form.Item>
              <Form.Item style={{ marginRight: 10 }}>
                <Button onClick={this.cancel} type="primary">キャンセル</Button>
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1567009_OutputDestinationAcquisition);
