import axios from 'configs/axios'

const apiPaths = {
  index: '/api/department-change-once',
  getDataTableSelect: '/api/department-change-once/get-data-table-select',
  getInsuranceInfoSet: '/api/department-change-once/get-insurance-info-set',
  update: '/api/department-change-once/update',

  changeOfficeInfo: '/api/department-change-once/change-office-info',
  // displayButton: '/api/department-change-once/display-button',

  // getDataTableSelect: '/api/department-change-once/personal-select',
  // allSelected: '/api/department-change-once/all-select-change',
  // selectRecord: '/api/department-change-once/w2-enabled-disabled-change',
  // run_F12: '/api/department-change-once/f12',
  // updateInsuranceInfoSet: '/api/department-change-once/update-insurance-info-set',
}

const DepartmentChangeOnceService = {
  async index() {
    return axios.get(apiPaths.index)
  },

  async getDataTableSelect(params) {
    return axios.get(apiPaths.getDataTableSelect, { params })
  },

  async changeOfficeInfo(params) {
    return axios.get(apiPaths.changeOfficeInfo, { params })
  },

  // async displayButton(data) {
  //   return axios.post(apiPaths.displayButton, data)
  // },

  // async allSelected(data) {
  //   return axios.post(apiPaths.allSelected, data)
  // },

  // async selectRecord(data) {
  //   return axios.post(apiPaths.selectRecord, data)
  // },

  // async run_F12(data) {
  //   return axios.post(apiPaths.run_F12, data)
  // },

  async getInsuranceInfoSet(params) {
    return axios.post(apiPaths.getInsuranceInfoSet, params)
  },

  // async updateInsuranceInfoSet(data) {
  //   return axios.post(apiPaths.updateInsuranceInfoSet, data)
  // },

  async update(data) {
    return axios.post(apiPaths.update, data)
  },
}

export default DepartmentChangeOnceService
