import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Card, Select, Button, message } from 'antd'
import WS0307071_HistorySwitching from './WS0307071_HistorySwitching'
import WS0307010_ContractHistorySubTable from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307010_ContractHistorySubTable'
import ContractHistorySubAction from 'redux/basicInfo/ContractInfoMaintain/ContractHistorySub.actions'
import axios from 'configs/axios'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'

/**
* @extends {React.Component<{Li_ContractType:any, Li_ContractOrgCode:any, Lio_ContractStartDate:any}>}
*/
class WS0307008_ContractHistorySub extends React.Component {
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
  }

  formRef = React.createRef()
  termsFieldName = 'terms'

  constructor(props) {
    super(props)

    // document.title = '契約作成'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }

    this.loadContractData = this.loadContractData.bind(this)
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    console.log(this.props)
    this.loadContractData(this.props)
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.Li_ContractType !== prevProps.Li_ContractType)
      || (this.props.Li_ContractOrgCode !== prevProps.Li_ContractOrgCode)
      || (this.props.Li_ContractStartDate !== prevProps.Li_ContractStartDate)
    ) {
      this.loadContractData(this.props)
    }
  }

  loadContractData(inputData) {
    const { Li_ContractType, Li_ContractOrgCode, Lio_ContractStartDate } = inputData
    let params = {
      Li_ContractType: Li_ContractType,
      Li_ContractOrgCode: Li_ContractOrgCode,
      Lio_ContractStartDate: Lio_ContractStartDate
    }
    ContractHistorySubAction.loadContractData(params)
      .then(res => {
        const formIns = this.formRef.current

        const resData = res.data
        if (!resData) {
          formIns.resetFields()
          return
        }

        // Default data
        resData.contract_start_date_on = moment(resData.contract_start_date_on).isValid() ? moment(resData.contract_start_date_on).format('YYYY/MM/DD') : resData.contract_start_date_on
        resData.bill_rounding = (resData.bill_rounding === null) ? 0 : resData.bill_rounding
        resData.bill_rounding_following = (resData.bill_rounding_following === null) ? 0 : resData.bill_rounding_following
        resData.calculation_division = (resData.calculation_division === null) ? 0 : resData.calculation_division
        resData.personal_claims_division = (resData.personal_claims_division === null) ? 0 : resData.personal_claims_division
        resData.tax_calc_unit_division = (resData.tax_calc_unit_division === null) ? 0 : resData.tax_calc_unit_division
        resData.tax_decimal_point = (resData.tax_decimal_point === null) ? 0 : resData.tax_decimal_point

        formIns.setFieldsValue(resData)
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  getFormFieldValue(namePath) {
    return this.formRef.current ? this.formRef.current.getFieldValue(namePath) : undefined
  }

  onFinish(values) {
    const { Li_ContractType, Li_ContractOrgCode } = this.props
    let params = {
      ...values,
      Li_ContractType: Li_ContractType,
      Li_ContractOrgCode: Li_ContractOrgCode
    }
    ContractHistorySubAction.onFinish(params)
      .then((res) => {
        message.success('保存が完了しました')
        this.loadContractData(this.props)
      })
      .catch(error => {

        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }

        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='create-contract'>
        <Card title='契約照会'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className='box_inner_vertical'>
              <Form.Item label='契約年度' style={{ marginBottom: 0 }}>
                <Input.Group compact>
                  <Form.Item name='contract_start_date_on'>
                    <Input.Search
                      readOnly={true}
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 350,
                            component: (
                              <WS0307071_HistorySwitching
                                Li_ContractType={this.props.Li_ContractType}
                                Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                                onSelected={({ Lio_Date }) => {
                                  console.log(Lio_Date)
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })

                                  this.loadContractData({
                                    ...this.props,
                                    Lio_ContractStartDate: Lio_Date
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='contract_name' style={{ marginLeft: '5px' }}>
                    <Input />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </div>
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <Form.Item label='端数処理' style={{ marginBottom: 0 }}>
                  <Input.Group compact>
                    <Form.Item name='tax_decimal_point'>
                      <Select style={{ width: 100 }}>
                        <Select.Option value={0}>四捨五入</Select.Option>
                        <Select.Option value={1}>切捨</Select.Option>
                        <Select.Option value={2}>切上</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name='tax_calc_unit_division' style={{ marginBottom: 0 }}>
                      <Select>
                        <Select.Option value={0}>明細</Select.Option>
                        <Select.Option value={1}>合計</Select.Option>
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item name='personal_claims_division' label='未収請求'>
                  <Select>
                    <Select.Option value={0}>無し</Select.Option>
                    <Select.Option value={1}>有り</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item label='金額計算' style={{ marginBottom: 0 }}>
                  <Input.Group compact>
                    <Form.Item name='bill_rounding'>
                      <Select style={{ width: 100 }}>
                        <Select.Option value={0}>無し</Select.Option>
                        <Select.Option value={1}>10円単位</Select.Option>
                        <Select.Option value={2}>100円単位</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name='bill_rounding_following'>
                      <Select>
                        <Select.Option value={0}>明細</Select.Option>
                        <Select.Option value={1}>合計</Select.Option>
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item name='notices' label='備考'>
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 'fit-content',
                      component: (
                        <WS0307010_ContractHistorySubTable
                          Li_ContractType={this.props.Li_ContractType}
                          Li_ContractOrgCode={this.props.Li_ContractOrgCode}
                          Lio_ContractStartDate={this.getFormFieldValue('contract_start_date_on')}
                          onFinishScreen={() => {
                            this.loadContracts()

                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: false,
                              },
                            })
                          }}
                        />
                      )
                    }
                  })}
              >契約情報</Button>
              <Button type='primary' htmlType='submit'>登録</Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

export default WS0307008_ContractHistorySub
