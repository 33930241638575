import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Input, Form } from 'antd'
import CategoryListSettingSubAction from 'redux/InputBusiness/NotInputCheckCategory/CategoryListSettingSub.action'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS0267001_CategorySearchQuerySingle extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'カテゴリ検索・照会(単体)'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    };
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current.setFieldsValue({ codeSearch: this.props.Lio_CategoryCode })
    // カテゴリ一覧を取得する
    this.getCategorySearchQuery()
  }

  /**
   * カテゴリ一覧を取得する
   * @param {*} value
   */
  getCategorySearchQuery = (value) => {
    const params = {
      Lio_CategoryCode: this.formRef.current?.getFieldValue('codeSearch'),
      Li_UnusedInspectDisplay: this.props.Li_UnusedInspectDisplay,
      masterSettingFlg: this.props.masterSettingFlg,
      searchCategorySettingsDisplayList: this.props.searchCategorySettingsDisplayList
    }
    CategoryListSettingSubAction.getCategorySearchQuery(params)
      .then(((res) => {
        this.setState({ dataSource: res ? res : [] })
      }))
  }

  /**
   * 検索処理
   */
  search = () => {
    const value = this.formRef.current.getFieldValue('codeSearch') ?? ''
    // 検索
    this.getCategorySearchQuery(value)
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='category-search-query-single'>
        <Card title='カテゴリ検索・照会(単体)'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}>
            <Form.Item
              label='検索'
              name='codeSearch'
            >
              <Input
                className='input-size-30'
                onPressEnter={() => this.search()}
              />
            </Form.Item>
            <Table
              dataSource={this.state.dataSource}
              pagination={false}
              bordered
              scroll={{ y: 500 }}
              size='small'
              rowKey={(record) => record.id}
              onRow={(record) => {
                return {
                  onDoubleClick: async () => {
                    if (this.props.onFinishScreen) {
                      // 親画面に選択されたカテゴリ情報を渡す
                      this.props.onFinishScreen({
                        Lio_CategoryName: record.category_name,
                        Lio_CategoryCode: record.category_code
                      })
                    }
                  }
                }
              }}
            >
              <Table.Column
                title='コード'
                dataIndex='category_code'
                width={90}
                render={(value, record) => (
                  <div
                    style={{
                      textAlign: 'right',
                      opacity: record.configuredCategory == true ? 0.3 : 1
                    }}
                  >
                    {value}
                  </div>
                )} />
              <Table.Column
                title='名称'
                dataIndex='category_name'
                width={200}
                render={(value, record) => {
                  return (
                    <div style={{ opacity: record.configuredCategory == true ? 0.3 : 1 }}>{value}</div>
                  )
                }}
              />
            </Table>

          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0267001_CategorySearchQuerySingle)
