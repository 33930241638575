import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Checkbox, Button, Alert, Tabs, DatePicker, Modal, Space } from 'antd'
import { UserOutlined, LockOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { createUserAction, getUserProfileAction, updateUserProfileAction } from 'redux/user/user.actions'
import UserRegisterFormAction from 'redux/MN_Basis/UserInfo/UserRegisterForm.action.js'

const layout = {
  labelCol: { style: { width: 120 } },
}

const usageRightKeyPosMap = {
  A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6, H: 7, I: 8
}

const format = 'YYYY/MM/DD'

class WS2706002_UserRegisterForm extends React.Component {
  static propTypes = {
    Li_UserId: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'ユーザー登録フォーム'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      userId: null,
      oldPassword: '',
      validDays: Number(process.env.REACT_APP_ACCOUNT_VALID_DAYS ?? 0) //アカウント有効日数

      // usageRightValues: [],
      // isUsageRightsDisabled: false,
    }

    this.onFinish = this.onFinish.bind(this)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    if (this.props.record) {
      // 変更の場合
      const userId = this.props.record.username
      this.setState({
        userId: userId
      })

      this.loadUserInfo(userId)
    } else {
      // 新規の場合
      let validF = null
      let validT = null
      if (this.state.validDays > 0) {
        // 有効期限の設定がある場合
        validF = moment().format(format)
        validT = (moment().add(this.state.validDays, 'days')).format(format)
      }

      this.formRef.current.setFieldsValue({
        password_updated_at: moment().format(format),
        valid_f: validF, //有効期限 開始日
        valid_t: validT, //有効期限 終了日（デフォルト：60日ご）
        passwordChangeFlg: true,
      })
      this.forceUpdate()
    }
  }

  /**
   * ユーザ情報を取得
   * @param {*} userId
   */
  loadUserInfo(userId) {
    const params = {
      Li_UserId: userId
    }
    UserRegisterFormAction.index(params)
      .then((res) => {
        if (res) {
          const formData = {
            ...res,
            valid_f: moment(res.valid_f).isValid() ? moment(res.valid_f) : null,
            valid_t: moment(res.valid_t).isValid() ? moment(res.valid_t) : null,
            password_updated_at: moment(res.password_updated_at).isValid() ? moment(res.password_updated_at).format(format) : null,
            password_confirmation: res.password,
          }
          this.formRef.current.setFieldsValue(formData)

          // 変更される前にパスワードを保持
          this.setState({
            oldPassword: res.password
          })
        }
      })
  }

  onRightsChange(value) {
    this.setState({
      usageRightValues: value,
    })
  }

  onChangeAuthorityLevel(event) {
    this.setState({
      isUsageRightsDisabled: event.target.checked,
    })
  }

  /**
   * 保存・登録
  * @param {*} values
  * @returns
  */
  onFinish(values) {
    let validF = null
    let validT = null
    if (this.state.validDays > 0) {
      // 有効期限の設定がある場合
      validF = moment().format(format)
      validT = (moment().add(this.state.validDays, 'days')).format(format)
    }

    let detailParams = {}
    // 詳細タブを開いていない　かつ　新規追加の時
    if (!values?.authority_level && !this.props.record) {
      // 詳細タブの情報が取得できなかったとき
      detailParams = {
        authority_level: 0,
      }
    }

    //パスワード変更フラグ
    const passwordChangeFlg = this.formRef.current?.getFieldValue('passwordChangeFlg') ?? false
    // パスワード更新日
    if (passwordChangeFlg) {
      detailParams = {
        ...detailParams,
        password_updated_at: moment().format(format),
        valid_f: validF,
        valid_t: validT,
      }
    }

    // パラメータ　values → detailParams の順番で設定
    let params = {
      ...values,
      ...detailParams,
      passwordChangeFlg: passwordChangeFlg,
      usageRightsA: values.usageRightsA ? values.usageRightsA : false,
      usageRightsB: values.usageRightsB ? values.usageRightsB : false,
      usageRightsC: values.usageRightsC ? values.usageRightsC : false,
      usageRightsD: values.usageRightsD ? values.usageRightsD : false,
      usageRightsE: values.usageRightsE ? values.usageRightsE : false,
      usageRightsF: values.usageRightsF ? values.usageRightsF : false,
      usageRightsG: values.usageRightsG ? values.usageRightsG : false,
      usageRightsH: values.usageRightsH ? values.usageRightsH : false,
      usageRightsI: values.usageRightsI ? values.usageRightsI : false,
    }

    UserRegisterFormAction.RegisterBtn(params)
      .then((res) => {
        if (res) {
          if (this.props.onUpdateSuccess) {
            this.props.onUpdateSuccess()
          }
        }
      })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div className='user-register-form'>
        <Card title={`アカウント管理 [${(this.props.record === null) ? '新規追加' : '変更'}]`}>
          {this.props.alertReducer.message ? (
            <Alert
              style={{ marginBottom: '15px' }}
              showIcon
              message={this.props.alertReducer.message}
              type={this.props.alertReducer.type || 'error'}
            />
          ) : null}
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ active_flg: true }}
            autoComplete='off'
          >
            <Tabs>
              <Tabs.TabPane key='tab-user' tab='ユーザー'>
                <Form.Item
                  name='active_flg'
                  label='ユーザー有効'
                  valuePropName='checked'
                  {...layout}
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name='username'
                  label='ユーザーID'
                  rules={[{ required: this.state.userId ? false : true }]}
                  {...layout}
                >
                  {(this.props.record)
                    ?
                    // 変更の時
                    <div>{this.formRef.current?.getFieldValue('username')}</div>
                    :
                    // 新規追加の時
                    <Input
                      prefix={<UserOutlined className='site-form-item-icon' />}
                      maxLength={32}
                    />
                  }
                </Form.Item>

                <Form.Item
                  name='name'
                  label='名前'
                  {...layout}
                >
                  <Input
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    maxLength={32}
                  />
                </Form.Item>

                <Form.Item
                  name='passwordChangeFlg'
                  label='パスワード変更'
                  valuePropName='checked'
                  {...layout}
                  hidden={!(this.props.record)}
                >
                  <Checkbox
                    onChange={() => this.forceUpdate()}
                  />
                </Form.Item>

                <Form.Item
                  name='password'
                  label='パスワード'
                  rules={[
                    { required: this.state.userId ? false : true },
                    { min: process.env.REACT_APP_PASSWORD_RESTRICT.toLocaleLowerCase() === 'true'  ? 8 : 1 },
                  ]}
                  {...layout}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    maxLength={32}
                    disabled={(!this.formRef.current?.getFieldValue('passwordChangeFlg') ?? false)}
                  />
                </Form.Item>

                <Form.Item
                  name='password_confirmation'
                  label='パスワード(確認)'
                  rules={[
                    { required: this.state.userId ? false : true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const pass = getFieldValue('password')
                        if ((!pass && !value) || (pass === value)) {
                          return Promise.resolve()
                        }
                        return Promise.reject('新パスワードとパスワード（確認）が一致しません。')
                      },
                    }),
                  ]}
                  {...layout}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    maxLength={32}
                    disabled={(!this.formRef.current?.getFieldValue('passwordChangeFlg') ?? false)}
                  />
                </Form.Item>
              </Tabs.TabPane>

              <Tabs.TabPane key='tab-info' tab='詳細設定'>
                <Space>
                  <Form.Item
                    name='valid_f'
                    label='有効期限'
                    {...layout}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      format='YYYY/MM/DD'
                    />
                  </Form.Item>

                  <Form.Item
                    name='valid_t'
                    label='～'>
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      format='YYYY/MM/DD'
                    />
                  </Form.Item>
                </Space>

                <Form.Item
                  label='パスワード更新日'
                  name='password_updated_at'
                  {...layout}
                >
                  {/* <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM/DD'
                  /> */}
                  <div>{this.formRef.current?.getFieldValue('password_updated_at')}</div>
                </Form.Item>
                <Form.Item
                  name='authority_level'
                  label='管理者権限'
                  valuePropName='checked'
                  onChange={(event) => this.onChangeAuthorityLevel(event)}
                  {...layout}
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  label='個別権限詳細'
                  {...layout}
                >
                  <div className='box_inner_vertical'>
                    <div className='box_inner_horizontal' style={{ gap: 20 }}>
                      <Form.Item
                        label='A'
                        name='usageRightsA'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label='B'
                        name='usageRightsB'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label='C'
                        name='usageRightsC'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal' style={{ gap: 20 }}>
                      <Form.Item
                        label='D'
                        name='usageRightsD'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label='E'
                        name='usageRightsE'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label='F'
                        name='usageRightsF'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal' style={{ gap: 20 }}>
                      <Form.Item
                        label='G'
                        name='usageRightsG'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label='H'
                        name='usageRightsH'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label='I'
                        name='usageRightsI'
                        style={{ marginBottom: 0 }}
                        valuePropName='checked'
                      >
                        <Checkbox
                          disabled={this.formRef.current?.getFieldValue('authority_level') === true ? true : false}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>

            <div className='box_button_bottom_right'>
              <Button
                danger
                type='primary'
                icon={<DeleteOutlined />}
                hidden={!(this.props.record)}
                onClick={() => {
                  Modal.confirm({
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    okButtonProps: {
                      icon: <DeleteOutlined />,
                      type: 'primary',
                      danger: true,
                    },
                    cancelText: 'キャンセル',
                    onOk: () => {
                      this.onFinishScreen(this.props.record.id)
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Form.Item style={{ margin: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    登録
                  </span>
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  alertReducer: alertReducer,
  userProfile: userReducer.userProfile
})

const mapDispatchToProps = (dispatch) => ({
  alert: (action, message) => dispatch(action(message)),
  createUserAction: (user) => dispatch(createUserAction(user)),
  getUserProfileAction: (userId) => dispatch(getUserProfileAction(userId)),
  updateUserProfileAction: (userId, user) => dispatch(updateUserProfileAction(userId, user))
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2706002_UserRegisterForm)
