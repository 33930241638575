// import React from "react";
// import ModalDraggable from "components/Commons/ModalDraggable";

// import {
//   Card, Table, message, Button, Form, Input, Select, Popconfirm, Space,
//   Modal,
// } from "antd";
// import {
//   PlusOutlined, DeleteOutlined
// } from '@ant-design/icons';

// import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
// import ModalChange from "components/Commons/ModalChange"
import React from "react";
import { Card, Checkbox, Form, Popconfirm, Input, Radio, Select, Button, Table, Row, Col, Menu, DatePicker, Modal, Dropdown, Tooltip, message, Space, InputNumber } from "antd";
import { MoreOutlined, SearchOutlined, ReloadOutlined, PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Color from "constants/Color";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
//import WS1391001_ImplementAgencyInquiry from "./WS1391001_ImplementAgencyInquiry";
// import WS1406001_ImplementorMasterAdd from "./WS1406001_ImplementorMasterAdd"
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";
//import InspectCmtMasterAction from "redux/SystemMaintenance/FacilityConsumptionTaxSetting/FacilityConsumptionTaxSetting.action";
import InspectCmtMasterAction from "redux/SpecificInsureMaintenance/InspectCmtMaster/InspectCmtMaster.action.js";

const columns = [
  {
    title: 'コメント区分',
    dataIndex: 'comment_division',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: 'コメントコード',
    dataIndex: 'exam_comment_code',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '画面内容',
    dataIndex: 'screen_content',
    //classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '帳票内容',
    dataIndex: 'document_content',
    //classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: 'オプション',
    dataIndex: 'options',
    //classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
]
class WS1401001_InspectCmtMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '検査コメントマスタ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API001401001002")
      .then(res => {
        this.setState({
          dataSource: res
        });
        this.formRef.current.setFieldsValue({
          MainSource: res,
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  onFinish = (values) => {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API001401001004", values)
      .then(res => {
        message.success('保存完了');
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);

        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      });
  }

  findIndexByID = (arrayData, recordID) => {
    // console.log("aaaaaaa")
    // console.log(arrayData)
    // console.log("bbbbbbb")
    // console.log(recordID)
    return arrayData.findIndex((item) => recordID === item.id);
  };
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  //削除
  deleteInspectCmtInfo = (id) => {
    console.log("id")
    console.log(id)
    let params = {
      id: Number(id)
    }
    InspectCmtMasterAction.deleteInspectCmtMaster(params)
      //値を持ってきたら実行
      .then(() => this.loadMainSource())
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  //保存
  onSave = (record, id) => {
    let params = {
      data: record.itemChange,
      id: id ?? undefined,
      //id: id === null ? '' : id,
    }
    console.log("params");
    console.log(params);
    InspectCmtMasterAction.postInspectCmtMaster(params)
      //値を持ってきたら実行
      .then(() => this.loadMainSource())
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card className="mb-2" title="検査コメントマスタ">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Table bordered size='small'
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                onChange: (page, pageSize) => { },
                onShowSizeChange: (page, pageSize) => { },
              }}
              rowKey={(record) => (record.id || Math.random())}
              onRow={(record, rowIndex) => ({
                //ダブルクリックイベント
                onDoubleClick: () =>
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '550px',
                      className: '',
                      component:
                        //ポップアップ表示
                        <ModalChange
                          columns={columns}
                          record={record}
                          onUpdate={(output) => {
                            this.closeModal()
                            console.log("更新")
                            this.onSave(output, record.id)
                          }}
                          onDelete={(output) => {
                            this.closeModal()
                            console.log("削除")
                            this.deleteInspectCmtInfo(record.id);
                          }}
                        />
                    },
                  })
              })}
            >
              {/* <Table.Column showSorterTooltip={false} sorter={(a, b) => a.comment_division - b.comment_division} title="コメント区分" dataIndex="comment_division"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <>
                    <Form.Item name={['MainSource', index, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['MainSource', index, 'comment_division']}>
                      <Input />
                    </Form.Item>
                  </>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.exam_comment_code.localeCompare(b.exam_comment_code, 'ja')} title="コメントコード" dataIndex="exam_comment_code"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'exam_comment_code']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.screen_content.localeCompare(b.screen_content, 'ja')} title="画面内容" dataIndex="screen_content"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'screen_content']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.document_content.localeCompare(b.document_content, 'ja')} title="帳票内容" dataIndex="document_content"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'document_content']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.options.localeCompare(b.options, 'ja')} title="オプション" dataIndex="options"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'options']}>
                    <Input />
                  </Form.Item>
                }} /> */}
              {columns.map((item, index) => (
                item.type === 1 ?
                  <Table.Column
                    key={'input' + index}
                    title={item.title}
                    dataIndex={item.dataIndex}
                    className={'column-size-' + item.classNumber}
                    align={item.textAlign ?? ''}
                    sorter={(a, b) => a[item.dataIndex].localeCompare(b[item.dataIndex], 'ja')}
                  /> : item.type === 2 ?
                    /*実施者コード～実施者機関までのタイトル行表示*/
                    <Table.Column
                      key={'select' + index}
                      title={item.title}
                      dataIndex={item.dataIndex}
                      className={'column-size-' + item.classNumber}
                      align={item.textAlign}
                      sorter={(a, b) => parseInt(a[item.dataIndex]) - parseInt(b[item.dataIndex])}
                      render={(value, record) => {
                        // findを使ってvalueを元に検索する
                        let findItem = item.selectList.find(selected => selected.value === value);
                        // labelを指定してreturn
                        return findItem.label
                      }}
                    /> : null
              ))}
              {/* プラスボタン */}
              <Table.Column
                fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {
                                console.log("保存")
                                console.log(output)
                                //+から新規追加
                                this.onSave(output, null)
                                this.closeModal()
                              }}
                            />
                        },
                      });
                    }} />} dataIndex="" align="center" width={30} render={(value, record, index) => (
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集、削除
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalChange
                                  columns={columns}
                                  record={record}
                                  onUpdate={(output) => {
                                    this.closeModal()
                                    //ポップアップ保存
                                    this.onSave(output, record.id)
                                  }}
                                  onDelete={(output) => {
                                    this.closeModal()
                                    //let index = this.findIndexByID(this.state.dataSource, record.id);
                                    this.deleteInspectCmtInfo(record.id);
                                  }}
                                />
                            },
                          });
                        }}
                      />

                    )} />
              {/* <Table.Column
                key="action" align="center"
                title={(
                  <Button type="primary" size="small" icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => {
                    this.formRef.current.setFieldsValue({
                      MainSource: [
                        ...this.formRef.current.getFieldValue('MainSource'),
                        {},
                      ]
                    });
                    this.forceUpdate();
                  }} />
                )}
                render={(value, record) => (<Space>
                  <Popconfirm title="削除しますか？" onConfirm={() => {
                    this.setState({ isLoading: true });

                    return VenusApiRoutesV2.callApi("API001401001005", { mainSource: record })
                      .then(() => this.loadMainSource())
                      .catch(error => {
                        console.log(error);
                        this.setState({ isLoading: false });
                        const res = error.response;
                        if (!res || !res.data || !res.data.message) {
                          message.error('エラーが発生しました');
                          return;
                        }

                        message.error(res.data.message);
                      });
                  }}>
                    <Button type="primary" size="small" danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>)}
              /> */}
            </Table>

            <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              {/* <Button type="primary" htmlType="submit" loading={this.state.isLoading}>保存</Button> */}
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS1401001_InspectCmtMaster;
