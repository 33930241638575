import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import PropTypes from "prop-types";
import {
  Card,
  Form,
  Input,
  Checkbox,
  Radio,
  Table,
  Row,
  Col,
  DatePicker,
  Button,
  Space,
  Menu,
  Dropdown
} from "antd";
import WS1169004_YearSelect from "./WS1169004_YearSelect";
import WS1171001_AggregateInfoCorrect from "./WS1171001_AggregateInfoCorrect";

import DetailInfoCorrectAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/DetailInfoCorrect.actions";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
const styleMarginRight1e = {
  marginRight: "1em"
};
const styleTextCenter = {
  textAlign: "center"
};
const styleJustifyContent = {
  justifyContent: "center"
};
class WS1169001_DetailInfoCorrect extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_Format: PropTypes.any,
    Li_DateF: PropTypes.any,
    Li_DateT: PropTypes.any,

    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06910:   明細情報(修正)';

    this.state = {
      tableData: [],
      format: "",
      isLoading: true,
      isLoadingTable: true,
      pageSize: 15,
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      filter: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.Li_Format !== prevProps.Li_Format) {
      this.formRef.current.setFieldsValue({ Li_Format: this.props.Li_Format });
      this.setState({ isLoading: true, format: this.props.Li_Format });
      this.getScreenData(this.props.Li_Format);
    }
  }
  componentDidMount() {
    this.getInit();
  }
  getInit() {
    let params = {
      Li_Format: this.props.Li_Format,
      Li_DateF: this.props.Li_DateF,
      Li_DateT: this.props.Li_DateT
    };
    this.setState({ isLoading: true, format: params.Li_Format });
    this.formRef.current.setFieldsValue({ Li_Format: params.Li_Format });
    this.getScreenData(params.Li_Format);
  }

  getScreenData(value) {
    this.setState({ isLoadingTable: true });
    this.setState({
      isLoadingForm: true,
      format: value
    });
    // DetailInfoCorrectAction.getScreenData({ Li_Format: value })
    //   .then(res => {
    //     this.setState({
    //       tableData: res ? res : []
    //     });
    //   })
    //   .finally(() => this.setState({ isLoadingTable: false }));
    VenusApiRoutesV2.callApi("API001169001002", { Li_Format: value }).then(res => {
      console.log(res)
      this.setState({
        tableData: res
      });
    }).finally(() => this.setState({ isLoadingTable: false }));
  }

  selectYear = year => {
    this.setState({ isLoading: true, format: year });
    this.formRef.current.setFieldsValue({ Li_Format: year });
    this.closeModal();
    this.getScreenData(year);
  };

  onFinish(values) { }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  onFilterChange = (chagnedState, formState) => {
    let filters = {
      Li_Format: this.state.format
    };

    for (const [key, value] of Object.entries(formState)) {
      if (value) {
        if (key === "Li_DateF" || key === "Li_DateT") {
          filters[key] = value.format("YYYY/MM/DD");
        } else {
          filters[key] = value;
        }
      }
    }

    this.setState({ filter: formState });

    this.getFilteredData(filters);
  };

  getFilteredData = filters => {
    DetailInfoCorrectAction.getScreenData(filters)
      .then(res => {
        this.setState({
          tableData: res ? res : []
        });
      })
      .finally(() => this.setState({ isLoadingTable: false }));


  };

  onChangeF = (date, dateString) => {
    return dateString;
  };
  onChangeT = (date, dateString) => {
    return dateString;
  };

  ReturnComponent = component => {
    let components = {
      WS1171001_AggregateInfoCorrect
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };
  renderMenuBar = () => (
    <Space>
      {/* <Button type="text">編集(E)</Button>
      <Dropdown
        trigger="click"
        overlay={() => (
          <Menu>
            <Menu.Item>オプション情報</Menu.Item>
            <Menu.Item>ユーザー定義</Menu.Item>
          </Menu>
        )}
      >
        <Button type="text">設定 (O)</Button>
      </Dropdown>
      <Button type="text">ﾍﾙﾌﾟ (H)</Button> */}
      <Button
        type="primary"
        onClick={() => {
          this.callModal(
            {
              Li_Format: this.state.format
            },
            "80%",
            "WS1171001_AggregateInfoCorrect"
          );
        }}
      >
        合計 (F12)
      </Button>
    </Space>
  );

  render() {
    const dateFormat = "YYYY/MM/DD";
    return (
      <div className="detail-info-correct">
        <Card title="V4-VNS06910:   明細情報(修正)">
          {this.renderMenuBar()}
          <hr></hr>
          <Form ref={this.formRef} onValuesChange={this.onFilterChange} onFinish={this.onFinish}>
            <Row>
              <Col span={4} style={styleMarginRight1e}>
                <Form.Item
                  name="Li_Format"
                  label="年度"
                  initialValue={this.props.Li_Format}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: "50%",
                        component: (
                          <WS1169004_YearSelect
                            Li_Format={this.props.Li_Format}
                            onFinishScreen={output => {
                              this.closeModal();
                            }}
                            selectYear={this.selectYear}
                          />
                        )
                      }
                    });
                  }}
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={4} style={styleMarginRight1e}>
                <Form.Item name="Li_DateF" label="受診日" type="text">
                  <DatePicker onChange={this.onChangeF} format={dateFormat} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="Li_DateT" type="text" label="~">
                  <DatePicker onChange={this.onChangeT} format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={styleTextCenter}>
                <span>健診種類</span>
                <Row style={styleJustifyContent}>
                  <Col span={12}>
                    <Form.Item name="Li_StsGeneral" valuePropName="checked">
                      <Checkbox>一般</Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="Li_StsUterus" valuePropName="checked">
                      <Checkbox>子宮</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={styleJustifyContent}>
                  <Col span={12}>
                    <Form.Item name="Li_StsAdd" valuePropName="checked">
                      <Checkbox>付加</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="Li_StsHepatitis" valuePropName="checked">
                      <Checkbox>肝炎</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={13} style={styleTextCenter}>
                <span>実施区分</span>
                <Row>
                  <Col span={7}>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="Li_StsBreast" valuePropName="checked">
                          <Checkbox>胸</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          name="Li_ChestContent"
                          label=""
                          hidden={!this.state.filter.Li_StsBreast}
                          initialValue="0"
                        >
                          <Radio.Group>
                            <Radio value="0">直接</Radio>
                            <Radio value="1">間接</Radio>
                            <Radio value="2">未実施</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="Li_StsStomach" valuePropName="checked">
                          <Checkbox>胃</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          name="Li_StomachContent"
                          label=""
                          hidden={!this.state.filter.Li_StsStomach}
                          initialValue="0"
                        >
                          <Radio.Group>
                            <Radio value="0">直接</Radio>
                            <Radio value="1">間接</Radio>
                            <Radio value="2">未実施</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="Li_StsBreast_1" valuePropName="checked">
                          <Checkbox>マンモ</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="Li_BreastContent"
                          label=""
                          hidden={!this.state.filter.Li_StsBreast_1}
                          initialValue="0"
                        >
                          <Radio.Group>
                            <Radio value="0">全て</Radio>
                            <Radio value="1">1方向</Radio>
                            <Radio value="2">2方向</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <Row>
                  <Col span={12}>
                    <Form.Item name="Li_StsStomachCamera" valuePropName="checked">
                      <Checkbox>胃カメラ</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="Li_StsHepatitisRna" valuePropName="checked">
                      <Checkbox>肝炎RNA</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item name="Li_StsFundus" valuePropName="checked">
                      <Checkbox>眼底</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="Li_StsHepatitisHbs" valuePropName="checked">
                      <Checkbox>肝炎HBS</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item name="Li_StsUterus_1" valuePropName="checked">
                      <Checkbox>子宮</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="Li_StsHepatitisHcv" valuePropName="checked">
                      <Checkbox>肝炎HCV</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>

          <Table
            bordered
            dataSource={this.state.tableData}
            pagination={{
              defaultCurrent: 1,
              pageSize: this.state.pageSize,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                this.setState({ pageSize: pageSize });
              },
              onShowSizeChange: (page, pageSize) => { }
            }}
            loading={this.state.isLoadingTable}
            rowKey={record => record.id}
            scroll={{ y: 650 }}
          >
            <Table.Column
              title="受診日"
              width={100}
              dataIndex="visit_date_on"
              sorter={(a, b) => new Date(a.visit_date_on) - new Date(b.visit_date_on)}
            // render={(value, record, index) => {
            //   return <div>{value.visit_date_on}</div>;
            // }}
            />
            <Table.Column
              title="受付No"
              dataIndex="accepted_no"
              sorter={(a, b) => a.accepted_no - b.accepted_no}
            // render={(value, record, index) => {
            //   return <div>{value.accepted_no}</div>;
            // }}
            />
            <Table.Column
              title="漢字氏名"
              width={100}
              dataIndex="kanji_name"
              sorter={(a, b) => a.kanji_name.localeCompare(b.kanji_name, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.kanji_name}</div>;
            // }}
            />
            <Table.Column
              title="受診コース"
              width={100}
              render={(value, record, index) => {
                return (
                  <div>
                    {value.visit_course} {value.contract_short_name}
                  </div>
                );
              }}
            />
            <Table.Column
              title="一　般"
              dataIndex="Expresstion_28"
              sorter={(a, b) => a.Expresstion_28.localeCompare(b.Expresstion_28, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_28}</div>;
            // }}
            />
            <Table.Column
              title="付　加"
              dataIndex="Expresstion_29"
              sorter={(a, b) => a.Expresstion_29.localeCompare(b.Expresstion_29, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_29}</div>;
            // }}
            />
            <Table.Column
              title="子　宮"
              dataIndex="Expresstion_30"
              sorter={(a, b) => a.Expresstion_30.localeCompare(b.Expresstion_30, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_30}</div>;
            // }}
            />
            <Table.Column
              title="肝　炎"
              dataIndex="Expresstion_31"
              sorter={(a, b) => a.Expresstion_31.localeCompare(b.Expresstion_31, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_31}</div>;
            // }}
            />
            <Table.Column
              title="胸　部"
              dataIndex="Expresstion_32"
              sorter={(a, b) => a.Expresstion_32.localeCompare(b.Expresstion_32, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_32}</div>;
            // }}
            />
            <Table.Column
              title="胃　部"
              dataIndex="Expresstion_33"
              sorter={(a, b) => a.Expresstion_33.localeCompare(b.Expresstion_33, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_33}</div>;
            // }}
            />
            <Table.Column
              title="乳子宮"
              dataIndex="Expresstion_34"
              sorter={(a, b) => a.Expresstion_34.localeCompare(b.Expresstion_34, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_34}</div>;
            // }}
            />
            <Table.Column
              title="子　宮"
              dataIndex="Expresstion_35"
              sorter={(a, b) => a.Expresstion_35.localeCompare(b.Expresstion_35, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_35}</div>;
            // }}
            />
            <Table.Column
              title="胃内視"
              dataIndex="Expresstion_36"
              sorter={(a, b) => a.Expresstion_36.localeCompare(b.Expresstion_36, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_36}</div>;
            // }}
            />
            <Table.Column
              title="マンモ"
              dataIndex="Expresstion_37"
              sorter={(a, b) => a.Expresstion_37.localeCompare(b.Expresstion_37, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_37}</div>;
            // }}
            />
            <Table.Column
              title="眼　底"
              dataIndex="Expresstion_38"
              sorter={(a, b) => a.Expresstion_38.localeCompare(b.Expresstion_38, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_38}</div>;
            // }}
            />
            <Table.Column
              title="実付加"
              dataIndex="Expresstion_39"
              sorter={(a, b) => a.Expresstion_39.localeCompare(b.Expresstion_39, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_39}</div>;
            // }}
            />
            <Table.Column
              title="肝ＲＮ"
              dataIndex="Expresstion_40"
              sorter={(a, b) => a.Expresstion_40.localeCompare(b.Expresstion_40, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_40}</div>;
            // }}
            />
            <Table.Column
              title="肝Ｈｂ"
              dataIndex="Expresstion_41"
              sorter={(a, b) => a.Expresstion_41.localeCompare(b.Expresstion_41, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_41}</div>;
            // }}
            />
            <Table.Column
              title="肝ＨＣ"
              dataIndex="Expresstion_42"
              sorter={(a, b) => a.Expresstion_42.localeCompare(b.Expresstion_42, 'jp')}
            // render={(value, record, index) => {
            //   return <div>{value.Expresstion_42}</div>;
            // }}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1169001_DetailInfoCorrect);
