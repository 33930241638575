import axios from 'configs/axios'

const apiPaths = {
  getMiraisData: '/api/result-acquisition-process/result-acquisition-process/get-mirais-data',
}

const ResultTakingProcessResidentService = {
  async getMiraisData(params) {
    return axios.get(apiPaths.getMiraisData, { params })
  },
}

export default ResultTakingProcessResidentService