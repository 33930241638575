import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

class WS1313006_XmlMedicalExamItemRef extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'XML用健診項目参照';

    this.state = {
      dataSource: [],
      selectedRow: [],
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    this.setState({ isLoadingTable: true });
    VenusApiRoutesV2.callApi("API001313006002", {}).then(res => {
      this.setState({dataSource: res});
    });
  }

  onFinish(value) {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen(this.state.selectedRow?.item_code_jlac10_15)
    }
  }

  render() {
    return (
      <div className="xml-medical-exam-item-ref">
        <Card title="XML用健診項目参照">
          <Table bordered 
            dataSource={this.state.dataSource ? this.state.dataSource : []}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              showSizeChanger: true,
              onChange: (page, pageSize) => {},
              onShowSizeChange: (page, pageSize) => {},
            }}
            rowKey={(record) => record.id}
            rowSelection={{
              type: "radio",
              onChange: async (selectedRowKeys, selectedRows) => {
                await this.setState({
                  selectedRow: selectedRows[0],
                });
              },
            }}
          >
            <Table.Column title="順番号" dataIndex="order_number" />
            <Table.Column title="項目コード" dataIndex="item_code_jlac10_15" />
            <Table.Column title="結果識別" dataIndex="result_identification_jlac10_2" />
            <Table.Column title="厚労省項目名" dataIndex="ministry_item_name" />
            <Table.Column title="方法コード" dataIndex="xml_exam_method_code"/>
            <Table.Column title="検査方法" dataIndex="exam_methods"/>
            <Table.Column title="ＸＭＬ表示名" dataIndex="xml_display_name" />
            <Table.Column title="データタイプ" dataIndex="data_type" />

          </Table>
          <Button type="primary" style={{ float: 'right' }}
            onClick={() => {
              this.onFinish()
            }}>選択</Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313006_XmlMedicalExamItemRef);
