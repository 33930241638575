import React from 'react'
import { connect } from 'react-redux'

import { Card, Table, Button, Dropdown, Menu } from 'antd'
import { PlusOutlined, MoreOutlined, } from '@ant-design/icons'

import OutputPatternSubAction from 'redux/AdvancePreparation/DocumentManageMaintain/OutputPatternSub.action'
import WS1545002_OutputPatternTableView from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1545002_OutputPatternTableView.jsx'
import WS1545003_OutputPatternTableEdit from 'pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1545003_OutputPatternTableEdit.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS1545001_OutputPatternSub extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '出力パターンSUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourcePattern: [],
      isLoadingTablePattern: false,
      selectedRowKeysPattern: [],
      rowSelectedPattern: [],
      indexTablePattern: 0,
      old_document_number: null,

      dataSourceDetail: [],
      isLoadingTableDetail: false,

    }

    this.newAddTableData = this.newAddTableData.bind(this)
  }

  componentDidMount() {
    this.getDataOutputPattern()
  }

  // table pattern
  getDataOutputPattern() {
    this.setState({ isLoadingTable: true })
    OutputPatternSubAction.getDataOutputPattern()
      .then((res) => {
        this.setState({
          dataSourcePattern: res ? res : [],
          isLoadingTablePattern: false,

          rowSelectedPattern: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeysPattern: res && res.length > 0 ? [res[0].id] : [],
          indexTablePattern: 0,
          old_document_number: res && res.length > 0 ? res[0].document_management_number : null
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  /**
   * 出力パターン新規追加
   */
  newAddTableData(record) {
    let newflag = false

    if (!record.id) {
      newflag = true
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '450px',
        className: '',
        component:
          <WS1545003_OutputPatternTableEdit
            record={record}
            newflag={newflag}
            //新規・編集
            onUpdate={(output) => {
              OutputPatternSubAction.setAddTableData(output)
                .then((res) => {
                  this.closeModal()
                  this.getDataOutputPattern()
                })
            }}

            onDelete={() => {
              OutputPatternSubAction.deletePattern(record)
                .then((res) => {
                  this.closeModal()
                  this.getDataOutputPattern()
                })
            }}
          />
      }
    })
  }

  /**
   * 詳細画面表示
   * @param {*} record
   */
  detailTableData(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '750px',
        className: '',
        component:
          <WS1545002_OutputPatternTableView
            record={record}
          />
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='output-pattern-sub'>
        <Card title='出力パターン'>
          <Table
            size='small'
            dataSource={this.state.dataSourcePattern}
            loading={this.state.isLoadingTablePattern}
            pagination={false}
            bordered
            rowKey={(record) => record.id}
            scroll={{ y: 450 }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: async () => {
                  this.detailTableData(record)
                }
              }
            }}
          >

            <Table.Column
              width={120}
              title='出力管理番号'
              dataIndex='document_management_number'
              render={(value) => (
                <div
                  style={{ textAlign: 'right' }}>
                  {value}
                </div>)}
            />

            <Table.Column
              title='出力パターン名称'
              dataIndex='document_title'
            />

            <Table.Column
              width={40}
              title={
                <div style={{ textAlign: 'center' }}>
                  <Button size='small'
                    onClick={this.newAddTableData}
                    type='primary'
                    icon={<PlusOutlined />}>
                  </Button>
                </div>
              }

              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='変更'
                          onClick={() => {
                            this.newAddTableData(record)
                          }}
                        >変更
                        </Menu.Item>

                        <Menu.Item
                          key='詳細'
                          onClick={() => {
                            this.detailTableData(record)
                          }}
                        >詳細
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                    ></Button>
                  </Dropdown>
                )
              }}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS1545001_OutputPatternSub)
