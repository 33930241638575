import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import { PlusOutlined, DeleteOutlined, SaveOutlined, MenuOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Form, Input, Button, Checkbox, Row, Col, Space, Modal, Tooltip, Dropdown, Menu, Popconfirm, Descriptions, Tabs, Select, Radio, message } from "antd";
import SetInfoChangeSubAction from "redux/basicInfo/SetInfoMaintain/SetInfoChangeSub.action";


class WS0333200_SetIncludesQueryEdit extends React.Component {
  static propTypes = {
    Li_StartDate: PropTypes.any,
    Li_SetCode: PropTypes.any,
    Li_CourseCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = 'セット内容照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      changeData: {},
      identify_cd_option: [],
      relation_option: [],
      target_cd_option: [],
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    let copyData = { ...this.props.data }
    let identify_cd = copyData.conditions_age_id_code_selectList
    let relation = copyData.conditions_relationship_selectList
    let target_cd = copyData.subject_code_selectList
    let identify_cd_obj = []
    let relation_obj = []
    let target_cd_obj = []
    for (let i = 0; i < identify_cd.length; i++) {
      identify_cd_obj.push(<Select.Option key={i} value={identify_cd[i].age_id_code}>{identify_cd[i].age_title}</Select.Option>)
    }
    for (let j = 0; j < relation.length; j++) {
      relation_obj.push(<Select.Option key={j} value={relation[j].node_code_name}>{relation[j].name}</Select.Option>)
    }
    for (let k = 0; k < target_cd.length; k++) {
      target_cd_obj.push(<Select.Option key={k} value={target_cd[k].node_code_name}>{target_cd[k].name}</Select.Option>)
    }
    if (copyData.conditions_gender === 0) {
      copyData.conditions_gender = null
    }
    if (copyData.conditions_screening === 0) {
      copyData.conditions_screening = null
    }
    if (copyData.conditions_in_out_hospital === 0) {
      copyData.conditions_in_out_hospital = null
    }
    this.setState({
      identify_cd_option: identify_cd_obj,
      relation_option: relation_obj,
      target_cd_option: target_cd_obj
    })
    this.formRef.current?.setFieldsValue(copyData)
  }

  handleFormChange(e) {
    let columnName = Object.keys(e)[0];
    let columnValue = Object.values(e)[0];

    if (columnName == 'conditions_first_time') {
      columnValue = columnValue == true ? 1 : 0
    }

    if (columnValue === undefined) {
      switch (columnName) {
        case 'conditions_age_id_code':
        case 'subject_code':
        case 'conditions_time_division':
        case 'conditions_relationship':
          columnValue = ''
          break
        case 'conditions_screening':
        case 'conditions_gender':
        case 'conditions_in_out_hospital':
          columnValue = 0
          break
        case 'conditions_age_calc_division':
          columnValue = null
          break
        default:
      }
    }
    this.setState({
      changeData: {
        ...this.state.changeData,
        [columnName]: columnValue
      }
    })
  }

  onFinish(value) {
    const copyData = { ...this.props.data }
    // 変更した部分のみ置き換え
    const data = {
      ...this.props.data,
      ...value
    }
    let param = {
      id: this.props.data.id,
      Li_SetCode: this.props.onRowData.set_code,
      StartDate: this.props.onRowData.start_date_on,
      exam_code: this.props.data.exam_code,
      changeData: this.state.changeData,
    }
    SetInfoChangeSubAction.updateTable(param)
      .then((res) => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  onFinishFailed(errorInfo) {
    message.error(errorInfo)
  }

  render() {
    return (
      <div className="set-includes-query-edit">
        <Card title={'セット編集'} >
          <Form
            layout={'vertical'}
            ref={this.formRef}
            onValuesChange={(event) => this.handleFormChange(event)}
            onFinish={(value) => this.onFinish(value)}
            onFinishFailed={(errorInfo) => this.onFinishFailed(errorInfo)}
          >
            {this.props.activeTab === '0' ?
              // 単価
              <div className="box_container">
                <div className="box_inner_horizontal">
                  <div className="box_inner_vertical" >
                    <Form.Item name='additional_label_number' label='ラベル'
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "入力値が正しくありません"
                        },
                      ]}>
                      <Input className="input-size-10" allowClear={true} />
                    </Form.Item>
                    <Form.Item name='unit_price' label='単価'
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "入力値が正しくありません"
                        },
                      ]}>
                      <Input className="input-size-10" />
                    </Form.Item>
                    <Form.Item name='cost_price' label='原価'
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "入力値が正しくありません"
                        },
                      ]}>
                      <Input className="input-size-12" />
                    </Form.Item>
                  </div>
                  <div className="box_inner_vertical" >
                    <Form.Item name='subject_code' label='科目' >
                      <Select allowClear={true} >
                        {this.state.target_cd_option}
                      </Select>
                    </Form.Item>
                    <Form.Item name='remarks' label='備考' >
                      <Input className="input-size-12" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              : this.props.activeTab === '1' ?
                // 条件
                <div className="box_container">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical" >
                      <Form.Item name='conditions_effective' label='条件' >
                        <Radio.Group >
                          <Radio value={1}>有効</Radio>
                          <Radio value={0}>無効</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name='conditions_gender' label='性別' >
                        <Select style={{ width: '90px' }} allowClear={true} >
                          <Select.Option value={1}>男</Select.Option>
                          <Select.Option value={2}>女</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name='conditions_screening' label='N次' >
                        <Select style={{ width: '90px' }} allowClear={true}  >
                          <Select.Option value={1}>1次</Select.Option>
                          <Select.Option value={2}>2次</Select.Option>
                          <Select.Option value={3}>3次</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name='conditions_relationship' label='続柄' >
                        <Select style={{ width: '90px' }} allowClear={true}  >
                          {this.state.relation_option}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name='conditions_first_time'
                        label='初回'
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </div>
                    <div className="box_inner_vertical" >
                      <Form.Item name='conditions_in_out_hospital' label='院内外' >
                        <Select style={{ width: '90px' }} allowClear={true}  >
                          <Select.Option value={1}>院内</Select.Option>
                          <Select.Option value={2}>院外</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name='conditions_time_division' label='AMPM' >
                        <Select style={{ width: '90px' }} allowClear={true}  >
                          <Select.Option value={'AM'}>ＡＭ</Select.Option>
                          <Select.Option value={'PM'}>ＰＭ</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name='conditions_age_id_code' label='年齢' >
                        <Select className="column-size-10" allowClear={true}   >
                          {this.state.identify_cd_option}
                        </Select>
                      </Form.Item>
                      <Form.Item name='conditions_age_calc_division' label='年齢計算' >
                        <Select style={{ width: '90px' }} allowClear={true}  >
                          <Select.Option value={0}>受診日</Select.Option>
                          <Select.Option value={1}>年度末</Select.Option>
                          <Select.Option value={2}>学童</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div> : null
            }
            <Form.Item style={{ marginBottom: 0 }}>
              <div className="box_button_bottom_right" style={{ display: 'flex', gap: '20px' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  保存</Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0333200_SetIncludesQueryEdit);