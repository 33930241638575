import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GetImage from 'constants/Images'

import { DoubleLeftOutlined, DoubleRightOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons'
import { Card, Input, Select, Checkbox, Button, Row, Dropdown, Form, Menu, Table, message } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import {
  getContractEditingSubSetIncludeAction,
  getScreenDataContractEditingSubAction,
  getContractEditingSubInspectContentAction,
  saveAndUpdateContractEditingSubAction,
  saveAmountSub,
} from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions'
import axios from 'configs/axios'

import WS0333001_SetIncludesQueryMock from 'pages/Mock/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQueryMock.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx'
import WS0310120_ConditionSettingMock from 'pages/Mock/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0310120_ConditionSettingMock.jsx'
import Color from 'constants/Color'
import { debounce } from 'lodash'

const styleForm = { margin: 0 }
const styleSpan = {
  float: 'right',
  padding: '0px 5px',
}
class WS0310004_ContractEditingSubMock extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    Li_ContractType: PropTypes.any,
    Li_ContractOrgs: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,
    Li_BasicOption: PropTypes.any,
    Li_Tax: PropTypes.any,
    Li_Rounding: PropTypes.any,
    Li_TaxClassify: PropTypes.any,
    Li_OtherGroup: PropTypes.any,
    Li_Course: PropTypes.any,
    Lo_StsUpdate: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '契約内容編集'

    this.state = {
      isLoadingLeft: true,
      isLoadingRight: true,
      rowSelectLeft: {},
      selectedRowKeyLeft: [],
      rowSelectRight: {},
      selectedRowKeyRight: [],
      SetIncludes: [],  // list item left
      ContractInspectionContent: [], //list item right
      DataClassify: [], // onSelect right
      keyClassify: 10,

      enabledForward: false,
      enabledBackward: false,

      SetAmountTax: false,
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      dataScreen: {},
      tableDataPaginate: {},
      saveData: [],
      originalRightTable: []
    }

    this.onForward = this.onForward.bind(this)
    this.onBackward = this.onBackward.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    this.getDataInspectContent(true)
  }

  getDataScreen() {
    let params = {
      Li_ContractType: this.props.Li_ContractType ? this.props.Li_ContractType : 0,
      Li_ContractOrgs: this.props.Li_ContractOrgCode ? this.props.Li_ContractOrgCode : '',
      Li_ContractStartDate: this.props.Li_ContractStartDate ? this.props.Li_ContractStartDate?.replace(/-/g, '/') : '',
      Li_ContractNum: this.props.Li_ContractNum ? this.props.Li_ContractNum : 0,
      Li_BasicOption: this.props.Li_BasicOption ? this.props.Li_BasicOption : 0,
      Li_Tax: this.props.Li_Tax ? this.props.Li_Tax : 0,
      Li_Rounding: this.props.Li_Rounding ? this.props.Li_Rounding : 0,
      Li_TaxClassify: this.props.Li_TaxClassify ? this.props.Li_TaxClassify : 0,
      Li_OtherGroup: this.props.Li_OtherGroup ? this.props.Li_OtherGroup : 0,
      Li_Course: this.props.Li_Course ? this.props.Li_Course : ''
    }
    getScreenDataContractEditingSubAction(params)
      .then(res => {
        this.setState({
          DataClassify: res?.SetIncludes,
          keyClassify: res?.DataClassify,
          SetAmountTax: res?.SetAmountTax,
          isLoadingLeft: true,
          isLoadingRight: true,
          enabledForward: false,
          enabledBackward: false,
          dataScreen: res || {}
        })
        this.formRef.current?.setFieldsValue({
          DataClassify: res?.DataClassify
        })

        this.getDataInspectContent(true)
      })
  }

  /**
   * 左のテーブル表示
   * @param {*} page ページ数
   * @param {*} pageSize 表示件数
   */
  getDataSetIncludes(page, pageSize) {
    let setIdentifierF = this.formRef.current?.getFieldValue('Category1') ? ''
      : this.formRef.current?.getFieldValue('Category2') === 1 ? 'Cos'
        : this.formRef.current?.getFieldValue('Category2') === 2 ? 'Set'
          : this.formRef.current?.getFieldValue('Category2') === 3 ? 'Opt' : '!'

    let setIdentifierT = this.formRef.current?.getFieldValue('Category1') ? ''
      : this.formRef.current?.getFieldValue('Category2') === 1 ? 'Cos'
        : this.formRef.current?.getFieldValue('Category2') === 2 ? 'Set'
          : this.formRef.current?.getFieldValue('Category2') === 3 ? 'Opt' : 'ÿ'

    let params = {
      Li_ContractType: this.props.Li_ContractType ? this.props.Li_ContractType : 0,
      Li_ContractOrgs: this.props.Li_ContractOrgCode ? this.props.Li_ContractOrgCode : '',
      Li_ContractStartDate: this.props.Li_ContractStartDate ? this.props.Li_ContractStartDate?.replace(/-/g, '/') : '',
      Li_ContractNum: this.props.Li_ContractNum ? this.props.Li_ContractNum : 0,
      Li_BasicOption: this.props.Li_BasicOption ? this.props.Li_BasicOption : 0,
      Li_Tax: this.props.Li_Tax ? this.props.Li_Tax : 0,
      Li_Rounding: this.props.Li_Rounding ? this.props.Li_Rounding : 0,
      Li_TaxClassify: this.props.Li_TaxClassify ? this.props.Li_TaxClassify : 0,
      Li_OtherGroup: this.props.Li_OtherGroup ? this.props.Li_OtherGroup : 0,
      Li_Course: this.props.Li_Course ? this.props.Li_Course : '',
      Li_SetIdentifierF: setIdentifierF,
      Li_SetIdentifyT: setIdentifierT,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      DataClassify: this.formRef.current?.getFieldValue('DataClassify'),
      page: page ? page : 1,
      limit: pageSize || 10
    }

    this.setState({ isLoadingLeft: true })
    getContractEditingSubSetIncludeAction(params)
      .then(res => {
        let data = res.Reusult ? res.Reusult : []
        // 右テーブルのset_codeと左テーブルのset_codeが一致した場合色を変える
        let textColor
        for (let l = 0; l < data.length; l++) {
          for (let k = 0; k < this.state.ContractInspectionContent.length; k++) {
            if (data[l].set_code === this.state.ContractInspectionContent[k].set_code) {
              textColor = {
                ...data[l],
                textColor: '#b9b9b9'
              }
              data[l] = textColor
            }
          }
        }
        this.setState({
          SetIncludes: res.Reusult ? data : [],
          isLoadingLeft: false,
          rowSelectLeft: {},
          selectedRowKeyLeft: [],
          enabledForward: false,
        })

      })
      .finally(() => this.setState({ isLoadingLeft: false }))
  }

  /**
   * 右のテーブル表示
   * @param {*} getDataSet 
   */
  getDataInspectContent(getDataSet) {
    let params = {
      Li_ContractType: this.props.Li_ContractType ? this.props.Li_ContractType : 0,
      Li_ContractOrgCode: this.props.Li_ContractOrgCode ? this.props.Li_ContractOrgCode : '',
      Li_ContractStartDate: this.props.Li_ContractStartDate ? this.props.Li_ContractStartDate?.replace(/-/g, '/') : '',
      Li_ContractNum: this.props.Li_ContractNum ? this.props.Li_ContractNum : 0,
      Li_BasicOption: this.props.Li_BasicOption ? this.props.Li_BasicOption : 0,
      Li_Tax: this.props.Li_Tax ? this.props.Li_Tax : 0,
      Li_DataClassify: this.formRef.current?.getFieldValue('DataClassify') ? this.formRef.current?.getFieldValue('DataClassify') : ''
    }
    this.setState({ isLoadingRight: true })
    getContractEditingSubInspectContentAction(params)
      .then(async res => {
        if (!this.formRef.current?.getFieldValue('DataClassify')) {
          await this.setState({
            ContractInspectionContent: res[0] ? res[0] : [],
            originalRightTable: res[0] ? res[0] : [],
            isLoadingRight: false,
            selectedRowKeyRight: [],
            rowSelectRight: {},
            enabledBackward: false,
            DataClassify: res[1],
            keyClassify: res[3],
            SetAmountTax: res[4],
            isLoadingLeft: true,
            enabledForward: false,
            dataScreen: res || {}
          })
          this.formRef.current?.setFieldsValue({
            DataClassify: res[3]
          })
        } else {
          await this.setState({
            ContractInspectionContent: res[0] ? res[0] : [],
            originalRightTable: res[0] ? res[0] : []
          })
        }
        if (getDataSet) {
          this.getDataSetIncludes()
        }
      })
      .finally(() => this.setState({ isLoadingRight: false }))
  }

  /**
   * 追加
   * @param {*} selectValue 選択行
   */
  onForward(selectValue) {
    let rightData = [...this.state.ContractInspectionContent]
    // 四捨五入か切り捨てか切り上げ
    let list = this.props.Li_Rounding
    // 消費税
    let taxCalculation = this.props.Li_Tax
    let unitPrice, taxValue
    switch (list) {
      case 0:
        // 四捨五入
        // 消費税
        taxValue = Math.round(selectValue.unit_price * taxCalculation)
        // 単価
        unitPrice = selectValue.unit_price - taxValue
        break
      case 1:
        // 切り捨て
        // 消費税
        taxValue = Math.floor(selectValue.unit_price * taxCalculation)
        // 単価
        unitPrice = selectValue.unit_price - taxValue
        break
      case 2:
        // 切り上げ
        // 消費税
        taxValue = Math.ceil(selectValue.unit_price * taxCalculation)
        // 単価
        unitPrice = selectValue.unit_price - taxValue
        break
    }
    selectValue = {
      ...selectValue,
      data_division: this.formRef.current?.getFieldValue('DataClassify'),
      personal_1_unit_price: unitPrice,
      personal_1_consumption_tax: taxValue,
      personal_1_total_price: selectValue.unit_price,
      Expresstion_9: selectValue.unit_price,
    }
    rightData.push(selectValue)
    // 重複チェック
    let duplicationCheck = rightData.filter((e, index, self) => {
      return self.findIndex((data) => data.set_code === e.set_code) === index
    })
    // 並び替え
    duplicationCheck.sort(function (a, b) {
      if (a.set_code > b.set_code) {
        return 1
      } else {
        return -1
      }
    })
    // 追加する行のset_codeと一致したら色を変える
    let copySetIncludes = [...this.state.SetIncludes]
    for (let k = 0; k < copySetIncludes.length; k++) {
      if (selectValue.set_code === copySetIncludes[k].set_code) {
        copySetIncludes[k].textColor = '#b9b9b9'
      }
    }
    this.setState({
      rowSelectLeft: null,
      ContractInspectionContent: duplicationCheck,
      enabledForward: false
    })
  }

  /**
   * 削除
   * @param {*} record 選択行
   */
  onBackward(record) {
    let copyData = [...this.state.ContractInspectionContent]
    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i] === record) {
        let delteLenght = i
        copyData.splice(delteLenght, 1)
      }
    }
    // 削除する行のset_codeと一致したら色を変える
    let copySetIncludes = [...this.state.SetIncludes]
    for (let l = 0; l < copySetIncludes.length; l++) {
      if (copySetIncludes[l].set_code === record.set_code) {
        copySetIncludes[l].textColor = ''
      }
    }
    this.setState({
      ContractInspectionContent: copyData,
      rowSelectLeft: null,
      enabledBackward: false
    })
  }


  saveAndUpdateData() {
    let params = {
      Li_ContractType: this.props.Li_ContractType ? this.props.Li_ContractType : 0,
      Li_ContractOrgCode: this.props.Li_ContractOrgCode ? parseInt(this.props.Li_ContractOrgCode) : 0,
      Li_ContractStartDate: this.props.Li_ContractStartDate ? this.props.Li_ContractStartDate?.replace(/-/g, '/') : '',
      Li_ContractNum: this.props.Li_ContractNum ? this.props.Li_ContractNum : 0,
      Li_BasicOption: this.props.Li_BasicOption ? this.props.Li_BasicOption : 0,
      Li_medical_exam_course: this.props.Li_Course ? this.props.Li_Course : 0,
      DataClassify: this.formRef.current?.getFieldValue('DataClassify'),
      setTable: this.state.ContractInspectionContent,
      setTableOrg: this.state.originalRightTable
    }
    saveAndUpdateContractEditingSubAction(params)
      .then((res) => {
        this.props.onFinishScreen()
      })
  }

  saveAmountSub(values) {
    let params = { ...values }
    saveAmountSub(params)
      .then((res) => {
        this.getDataInspectContent(false)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  contractList() {
    const {
      Li_ContractType, Li_ContractOrgCode,
      Li_ContractStartDate, Li_ContractNum,
    } = this.props

    this.setState({ isLoadingContentClick: true })

    axios.post('/api/contract-info-batch-process/contract-item-sub/CourseBreakdownInquiry', {
      Li_ContractType,
      Li_ContractOrgCode,
      Li_ContractStartDate,
      Li_ContractNum,
      medical_exam_course: this.state.contractTermData?.medical_exam_course,
    })
      .then((res) => {
        this.showModalContractCourseBreakdownInquiry(res.data)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  showModalContractCourseBreakdownInquiry(data) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 850,
        component: (<WS0605127_ContractLineItemDisplay
          Li_ContractType={this.props.Li_ContractType}
          Li_ContractOrgCode={this.props.Li_ContractOrgCode}
          Li_ContractStartDate={this.props.Li_ContractStartDate}
          Li_ContractNum={this.props.Li_ContractNum}
          onFinishScreen={(data) => {
            this.closeModal()
          }}
        />),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const { childModal } = this.state
    return (
      <div className='contract-editing-sub' >
        <Card title='契約内容編集（モック）'>
          <Form
            ref={this.formRef}
            initialValues={{
              Category2: 0
            }}
          >
            <div className='box_inner_horizontal'>
              <div className='box_inner_vertical_label_top'>
                <div className='table_header_filter' >
                  <Form.Item
                    name='Category1'
                    valuePropName='checked'
                    style={styleForm}
                  >
                    <Checkbox onChange={() => this.getDataSetIncludes()}><span style={{ color: '#fff' }}>単品</span></Checkbox>
                  </Form.Item>

                  <Form.Item name='Category2' style={styleForm}>
                    <Select size='small' style={{ width: 100 }} onChange={() => this.getDataSetIncludes()}>
                      <Select.Option value={0}>全て</Select.Option>
                      <Select.Option value={1}>コース</Select.Option>
                      <Select.Option value={2}>セット</Select.Option>
                      <Select.Option value={3}>ｵﾌﾟｼｮﾝ</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name='SearchChar' style={styleForm}>
                    <Input
                      size='small'
                      onPressEnter={debounce((e) => this.getDataSetIncludes(), 300)}
                      onBlur={debounce((e) => this.getDataSetIncludes(), 300)}
                    />
                  </Form.Item>
                </div>

                <Table
                  size='small'
                  dataSource={this.state.SetIncludes}
                  bordered
                  showHeader={false}
                  style={{ cursor: 'pointer' }}
                  scroll={{ y: 500, x: 'max-content' }}
                  pagination={this.state.SetIncludes.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  rowKey={record => record.id}
                  rowClassName={(record, index) => record.id === this.state.rowSelectLeft?.id ? 'table-row-light' : ''}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.onForward(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectLeft: record,
                          enabledForward: true,
                          enabledBackward: false,
                        })
                      }
                    }
                  }}
                >

                  <Table.Column
                    className='column-size-40'
                    dataIndex='set_code'
                    render={(text, record, index) => (
                      <span style={{ color: record.textColor }}>{record.set_code}</span>
                    )}
                  />

                  <Table.Column
                    className='column-size-40'
                    dataIndex='set_short_name'
                    render={(text, record, index) => (
                      <div>
                        <span style={{ color: record.textColor }}>{record.set_short_name}</span>
                        {record.Expresstion_30 ?
                          <span style={{ ...styleSpan, background: Color(record.Expresstion_29).Background, color: Color(record.Expresstion_29).Foreground }}>
                            条
                          </span>
                          : ''
                        }
                      </div>
                    )} />

                  <Table.Column
                    className='column-size-2'
                    render={(text, record, index) => (
                      <Dropdown size='small' trigger='click'
                        overlay={() => (
                          <Menu >
                            <Menu.Item key='照  会'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 900,
                                    component: (<WS0333001_SetIncludesQueryMock
                                      Li_StartDate={this.props.Li_ContractStartDate}
                                      Li_SetCode={record.set_code}
                                      Li_CourseCode={this.props.Li_Course}
                                      batch={true}
                                      onFinishScreen={(data) => {

                                        this.closeModal()
                                      }}
                                    />),
                                  },
                                })
                              }}>照　会</Menu.Item>
                          </Menu>
                        )}>

                        <Button
                          size='small'
                          icon={<MoreOutlined />} />
                      </Dropdown>
                    )} />
                </Table>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  gap: '16px',
                  padding: '16px',
                  margin: 'auto',
                  minWidth: '50px'
                }}>
                <Button
                  size='small'
                  type='primary'
                  disabled={!this.state.enabledForward}
                  onClick={() => this.onForward(this.state.rowSelectLeft)}>
                  追加 <DoubleRightOutlined /></Button>

                <Button
                  size='small'
                  type='primary'
                  icon={<DoubleLeftOutlined />}
                  disabled={!this.state.enabledBackward}
                  onClick={() => this.onBackward(this.state.rowSelectRight)}>
                  削除</Button>
              </div>

              <div className='box_inner_vertical_label_top'>
                <div className='table_header_filter'>
                  <Form.Item style={styleForm}>
                    <img src={GetImage(this.state.keyClassify)} alt='icon' />
                  </Form.Item>

                  <Form.Item name='DataClassify' style={styleForm}>
                    <Select style={{ width: 120 }}
                      size='small'
                      onChange={(value) => {
                        this.setState({ keyClassify: value })
                        this.getDataInspectContent(true)
                      }}>
                      {this.state.DataClassify?.map((item, index) => (
                        <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      size='small'
                      onClick={() => {
                        this.contractList()
                      }}>内容</Button>
                  </Form.Item>
                </div >

                <Table
                  size='small'
                  dataSource={this.state.ContractInspectionContent}
                  loading={this.state.isLoadingRight}
                  bordered
                  pagination={this.state.ContractInspectionContent.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  scroll={{ y: 500, x: 'max-content' }}
                  rowKey={record => record.id}
                  showHeader={false}
                  rowClassName={(record, index) => record.id === this.state.ContractInspectionContent?.id ? 'table-row-light' : ''}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.onBackward(record)
                      },
                      onClick: async () => {
                        await this.setState({
                          rowSelectRight: record,
                          enabledForward: false,
                          enabledBackward: true,
                        })
                      }
                    }
                  }}
                >

                  <Table.Column
                    className='column-size-2'
                    render={(text, record, index) => (
                      <img src={GetImage(record.data_division)} alt='icon' />
                    )} />

                  <Table.Column
                    className='column-size-40'
                    dataIndex='set_code'
                  />

                  <Table.Column
                    className='column-size-40'
                    dataIndex='set_short_name'
                    render={(text, record, index) => (
                      <>
                        <span>{record.set_short_name}</span>
                        {record.Expresstion_18 ?
                          <span style={{ ...styleSpan, background: Color(123).Background, color: Color(123).Foreground }}>
                            条
                          </span>
                          :
                          ''
                        }
                      </>
                    )} />

                  <Table.Column
                    className='column-size-2'
                    render={(text, record, index) => (
                      <Dropdown size='small' trigger='click'
                        overlay={() => (
                          <Menu expandIcon >
                            <Menu.Item key='照会'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 900,
                                    component: (
                                      <WS0333001_SetIncludesQueryMock
                                        Li_StartDate={this.props.Li_ContractStartDate}
                                        Li_SetCode={record.set_code}
                                        batch={true}

                                        onFinishScreen={(data) => {
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}>照　会</Menu.Item>

                            <Menu.Item key='条件変更'
                              hidden={this.props.Li_BasicOption === 0 || record.W4_data_partition === 50 || record.W4_data_partition === 60}
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 800,
                                    component: (
                                      <WS0310120_ConditionSettingMock
                                        Li_ContractType={record.contract_type}
                                        Li_ContractOrgCode={record.contract_organization_code}
                                        Li_ContractStartDate={record.contract_start_date_on}
                                        Li_ContractNum={record.contract_number}
                                        Li_SetCode={record.set_code}
                                        Li_CourseConditions={this.props.Li_CourseConditions}

                                        onFinishScreen={() => {
                                          this.closeModal()
                                          this.getDataInspectContent(false)

                                          this.props.update()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}>条件変更</Menu.Item>
                          </Menu>

                        )}><Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )} />
                </Table>
              </div>
            </div>
            <Row style={{ float: 'right', marginTop: '20px' }}>
              <Button type='primary'
                icon={<SaveOutlined />}
                onClick={() => this.saveAndUpdateData()}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </Row>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0310004_ContractEditingSubMock)
