import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  InputNumber,
  Input,
  Select,
  Button,
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Modal,
  Menu,
  Dropdown,
  message,
  Spin,
  Tooltip
} from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";

import {
  DeleteOutlined,
  SaveOutlined,
  SearchOutlined,
  MoreOutlined,
  PlusOutlined,
  CaretDownOutlined
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Color from "constants/Color";
import { width } from "dom-helpers";

import { ModalCustom } from "components/Commons/ModalCustom";
import { ModalConfirm } from "components/Commons/ModalConfirm";
import Menubar from "components/Commons/Menubar";

import axios from "configs/axios";
import moment from "moment";

import {
  getDataMainCosAction,
  getSetSubprocessAction,
  getCourseSubInputAction,
  saveAndUpdateCourseSubInputAction,
  saveAndUpdateCourseSetSubprocessAction,
  BatchProcess_F9Action
} from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/InspectSetInfo.action";

import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";

import WS1176003_CopyRegisterScreenInput from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176003_CopyRegisterScreenInput.jsx";

const styleDiv = {
  textAlign: "right",
  padding: "7px 0",
  color: "#14468C",
  fontWeight: "bold",
  background: "#C8DCF5"
};
const styleCol = {
  borderRight: "1px solid #F0F0F0"
};

class WS1176001_InspectSetInfo extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    // Li_format : PropTypes.any,
    // Li_remarks: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      mainPageSize: 10,

      dataSourceMain: [],
      rowSelectMain: {},
      isLoadingTableMain: false,

      dataSourceSubSite: [],
      rowSelectSubSite: {},
      isLoadingTableSubSite: false,

      dataSourceSubFinding: [],
      rowSelectSubFinding: {},
      isLoadingTableSubFinding: false
    };
  }

  componentDidMount = () => {
    this.loadDataMainTable();
  };

  BatchProcess_F9 = () => {
    BatchProcess_F9Action()
      .then(res => {
        if (res?.data) {
          message.info(res.data.message);
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  loadDataMainTable = () => {
    getDataMainCosAction()
      .then(res => {
        if (res?.data) {
          this.setState({
            dataSourceMain: res.data,
            rowSelectMain: this.state.rowSelectMain.id ? this.state.rowSelectMain : res.data[0]
          });
          this.formRef.current?.setFieldsValue({
            tableMainData: res.data
          });
          this.forceUpdate();

          if (res.data.length > 0) {
            this.getCourseSubInput(res.data[0]["test_set_code"]);
          } else {
            this.setState({
              dataSourceSubSite: []
            });
          }
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  getCourseSubInput(Li_SetCode) {
    this.setState({
      isLoadingTableSubSite: true,
      isLoadingTableSubFinding: true
    });
    VenusApiRoutesV2.callApi("API001176001003", { Li_SetCode })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          dataSourceSubSite: res
        });
        this.forceUpdate();
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() =>
        this.setState({
          isLoadingTableSubSite: false,
          isLoadingTableSubFinding: false
        })
      );
  }

  // getSetSubprocess = (Li_SetCode) => {
  //   getSetSubprocessAction({Li_SetCode})
  //     .then(res => {
  //       if (res?.data) {
  //         this.setState({
  //             dataSourceSubFinding  : res.data,
  //             rowSelectMain         : this.state.rowSelectMain.id ? this.state.rowSelectMain : res.data[0]
  //         });
  //       }
  //     })
  //     .catch()
  //     .finally(() => this.setState({ isLoadingTableMain: false }))
  // }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  updateDatasource(index, field, flag = 0) {
    let data = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    if (flag === 0) {
      for (let k in field) {
        data[index][k] = field[k];
      }
      this.formRef.current?.setFieldsValue({
        dataSourceSubSite: data
      });
      this.forceUpdate();
      saveAndUpdateCourseSubInputAction(data[index]);
    } else {
      for (let k in field) {
        data[index][k] = field[k];
      }
      this.formRef.current?.setFieldsValue({
        dataSourceSubSite: data
      });
      this.forceUpdate();
      saveAndUpdateCourseSetSubprocessAction(data[index]);
    }
  }

  BatchProcess_F9 = () => {
    BatchProcess_F9Action()
      .then(res => {
        if (res?.data) {
          message.info(res.data.message);
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  renderMenuBar = () => (
    <Space>
      <Button type="primary" onClick={this.BatchProcess_F9}>
        一括処理
      </Button>
    </Space>
  );

  addRowTableMain = () => {
    const newData = {
      id: 0,
      test_set_code: "",
      test_set_name: ""
    };

    let data = [...this.formRef.current?.getFieldValue("tableMainData")];
    data.length > 0 ? data.unshift(newData) : data.push(newData);
    this.formRef.current?.setFieldsValue({
      tableMainData: data
    });
    this.forceUpdate();
  };

  addRowSourceSubSite = () => {
    const count = Math.random().toString(36).slice(-5);
    const newData = {
      id: count,
      screen_display_order: "",
      exam_item: "",
      exam_short_name: "",
      exam_name: "",
      exam_type: "",
    };

    let data = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    data.length > 0 ? data.unshift(newData) : data.push(newData);
    this.formRef.current?.setFieldsValue({
      dataSourceSubSite: data
    });
    this.forceUpdate();
  };

  onFinishMainForm = () => { };

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex(item => recordID === item.id);
  };

  SaveTableMain(record) {
    let arr = [...this.formRef.current?.getFieldValue("tableMainData")];
    for (let indx = 0; indx < arr.length; indx++) {
      if (arr[indx].id === record.id) {
        if (isNaN(record.id)) {
          let obj = { ...arr[indx] };
          obj.id = ""
          this._SaveDataTableMain(obj)
          return
        } else {
          this._SaveDataTableMain(arr[indx])
          return
        }
      }
    }
  }

  _SaveDataTableMain(record) {
    this.setState({ isLoadingTableMain: true })
    VenusApiRoutesV2.callApi("API001176001008", { mainSource: [record] }).then(res => {
      this.forceUpdate();
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    }).finally(() => this.setState({ isLoadingTableMain: false }))
  }

  SaveSourceSubSite(record) {
    let arr = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    for (let indx = 0; indx < arr.length; indx++) {
      if (arr[indx].id === record.id) {
        if (isNaN(record.id)) {
          let obj = { ...arr[indx] };
          obj.id = ""
          this._SaveDataSourceSubSite(obj)
          return
        } else {
          this._SaveDataSourceSubSite(arr[indx])
          return
        }
      }
    }
  }

  _SaveDataSourceSubSite(record) {
    this.setState({ isLoadingTableSubSite: true })
    const payload = {
      ...record,
      test_set_code: this.state.rowSelectMain.test_set_code
    }
    VenusApiRoutesV2.callApi("API001176001010", { mainSource: [record] }).then(res => {
      this.forceUpdate();
    }).catch(error => {
      const res = error.response;
      if (!res || res.data || res.data.message) {
        message.error('エラーが発生しました');
      }
    }).finally(() => this.setState({ isLoadingTableSubSite: false }))
  }

  DeleteTableMain(record) {
    let arr = [...this.formRef.current?.getFieldValue("tableMainData")];
    arr.map((value, index) => {
      if (value.id === record.id) {
        arr.splice(index, 1)
        this.formRef.current?.setFieldsValue({
          tableMainData: arr
        })
        this.forceUpdate()
      }
    })
    if (!isNaN(record.id)) {
      this.setState({
        isLoadingTableMain: true
      })
      VenusApiRoutesV2.callApi("API001176001009", { mainSource: [{ id: record.id }] }).finally(() => this.setState({ isLoadingTableMain: false }))
    }
  }

  DeleteSourceSubSite(record) {
    let arr = [...this.formRef.current?.getFieldValue("dataSourceSubSite")];
    arr.map((value, index) => {
      if (value.id === record.id) {
        arr.splice(index, 1)
        this.formRef.current?.setFieldsValue({
          dataSourceSubSite: arr
        })
        this.forceUpdate()
      }
    })
    if (!isNaN(record.id)) {
      this.setState({
        isLoadingTableSubSite: true
      })
      VenusApiRoutesV2.callApi("API001176001011", { mainSource: [{ id: record.id }] }).finally(() => this.setState({ isLoadingTableSubSite: false }))
    }
  }

  render() {
    return (
      <div className="inspect-set-info">
        <Card title="V4-VNS02600:検査セット情報">
          {this.renderMenuBar()}
          <hr></hr>
          <Form ref={this.formRef} onFinish={this.onFinishMainForm}>
            <Row>
              <Col span={8} style={styleCol}>
                <Table
                  bordered
                  size="small"
                  scroll={{ y: "85vh" }}
                  dataSource={
                    this.formRef.current?.getFieldValue("tableMainData")
                      ? this.formRef.current?.getFieldValue("tableMainData")
                      : []
                  }
                  loading={this.state.isLoadingTableMain}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: this.state.mainPageSize,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                      this.setState({
                        mainPageSize: pageSize
                      });
                    },
                    onShowSizeChange: (page, pageSize) => { }
                  }}
                  onRow={(record, index) => ({
                    onClick: event => {
                      this.getCourseSubInput(record.test_set_code);
                      this.setState({
                        rowSelectMain: record
                      });
                    }
                  })}
                  rowKey={record => record.id}
                >
                  <Table.Column
                    title="コード"
                    dataIndex="test_set_code"
                    sorter={(a, b) => a.test_set_code.localeCompare(b.test_set_code)}
                    showSorterTooltip={false}
                    width={120}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("tableMainData"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["tableMainData", index, "test_set_code"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input
                            readOnly={(record.id ?? 0) !== 0}
                            maxLength={30}
                            onDoubleClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: "60%",
                                  component: (
                                    <WS1176003_CopyRegisterScreenInput
                                      Li_test_set_code={record.test_set_code}
                                      Li_test_set_name={record.test_set_name}
                                      Li_2CopyName={record.test_set_name}
                                      onFinishScreen={output => {
                                        this.closeModal();
                                      }}
                                    />
                                  )
                                }
                              });
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="セット名称"
                    dataIndex="test_set_name"
                    sorter={(a, b) => a.test_set_name.localeCompare(b.test_set_name)}
                    showSorterTooltip={false}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("tableMainData"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["tableMainData", index, "test_set_name"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    width={70}
                    align="center"
                    title={
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={this.addRowTableMain}
                      ></Button>
                    }
                    render={(text, record, index) => {
                      return (
                        <div hidden={record.id === this.state.rowSelectMain.id ? false : true}>
                          <Space size={1}>
                            <Button
                              size="small"
                              style={{ color: "green", marginRight: "5px" }}
                              icon={<SaveOutlined />}
                              onClick={() => this.SaveTableMain(record)}
                            />
                            <Button
                              size="small"
                              style={{ color: "red" }}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  content: "消去してもよろしいですか？",
                                  okText: "は　い",
                                  cancelText: "いいえ",
                                  onOk: () => this.DeleteTableMain(record)
                                });
                              }}
                            />
                          </Space>
                        </div>
                      );
                    }}
                  />
                </Table>
              </Col>
              <Col span={16} style={styleCol}>
                <Table
                  bordered
                  size="small"
                  scroll={{ y: "85vh" }}
                  pagination={{
                    defaultCurrent: 1,
                    pageSize: this.state.subPageSize,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                      this.setState({
                        subPageSize: pageSize
                      });
                    },
                    onShowSizeChange: (page, pageSize) => { }
                  }}
                  dataSource={
                    this.formRef.current?.getFieldValue("dataSourceSubSite")
                      ? this.formRef.current?.getFieldValue("dataSourceSubSite")
                      : []
                  }
                  loading={this.state.isLoadingTableSubSite}
                  onRow={(record, index) => ({
                    onClick: event => {
                      this.setState({
                        rowSelectSubSite: record
                      });
                    }
                  })}
                  rowKey={record => record.id}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="screen_display_order"
                    sorter={(a, b) => a.screen_display_order - b.screen_display_order}
                    showSorterTooltip={false}
                    align={"center"}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["dataSourceSubSite", index, "screen_display_order"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="コード"
                    dataIndex="exam_item"
                    sorter={(a, b) => a.exam_item - b.exam_item}
                    showSorterTooltip={false}
                    width={100}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["dataSourceSubSite", index, "exam_item"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <InputNumber
                            maxLength={30}
                            readOnly
                            style={{ cursor: 'pointer' }}
                            bordered={record.id === this.state.rowSelectSubSite.id}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: "60%",
                                  component: (
                                    <WS0271001_InspectItemSearchQuerySingle
                                      InspectCode={record.exam_item}
                                      onFinishScreen={output => {
                                        let val = {
                                          exam_item: output.recordData.test_item_code,
                                          exam_name: output.recordData.exam_name,
                                          exam_type: output.recordData.exam_type
                                        };

                                        this.updateDatasource(index, val, 0);
                                        this.closeModal();
                                      }}
                                    />
                                  )
                                }
                              });
                            }}

                          // onChange={(e) => this.onChangeInput({ search_short_name: e.target.value }, 'dataSourceSubSite', record)}
                          />
                        </Form.Item>
                      )
                    }}
                  />
                  <Table.Column
                    title="略称名"
                    dataIndex="exam_short_name"
                    sorter={(a, b) => a.exam_short_name.localeCompare(b.exam_short_name, "jp")}
                    showSorterTooltip={false}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["dataSourceSubSite", index, "exam_short_name"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <span>{text}</span>
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="検査名称"
                    dataIndex="exam_name"
                    sorter={(a, b) => a.exam_name.localeCompare(b.exam_name, "jp")}
                    showSorterTooltip={false}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["dataSourceSubSite", index, "exam_name"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <span>{text}</span>
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="タイプ"
                    dataIndex="exam_type"
                    sorter={(a, b) => a.exam_type.localeCompare(b.exam_type)}
                    showSorterTooltip={false}
                    render={(text, record) => {
                      let index = this.findIndexByID(
                        this.formRef.current?.getFieldValue("dataSourceSubSite"),
                        record.id
                      );
                      return (
                        <Form.Item
                          name={["dataSourceSubSite", index, "exam_type"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <span>{text}</span>
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    width={70}
                    align="center"
                    title={
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={this.addRowSourceSubSite}
                      ></Button>
                    }
                    render={(text, record, index) => {
                      return (
                        <div hidden={record.id === this.state.rowSelectSubSite.id ? false : true}>
                          <Space size={1}>
                            <Button
                              size="small"
                              style={{ color: "green", marginRight: "5px" }}
                              icon={<SaveOutlined />}
                              onClick={() => this.SaveSourceSubSite(record)}
                            />
                            <Button
                              size="small"
                              style={{ color: "red" }}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  content: "消去してもよろしいですか？",
                                  okText: "は　い",
                                  cancelText: "いいえ",
                                  onOk: () => this.DeleteSourceSubSite(record)
                                });
                              }}
                            />
                          </Space>
                        </div>
                      );
                    }}
                  />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1176001_InspectSetInfo);
