import axios from 'configs/axios'

const API_LIST = {
  GetPatterFormatData: '/api/normal-value-setting-maintain/normal-value-setting/getPatternFormat',
  GetScreenData: '/api/normal-value-setting-maintain/normal-value-setting/getScreenData',
  onSave: '/api/normal-value-setting-maintain/normal-value-setting/onsave',
  deleteRadioInfo: '/api/normal-value-setting-maintain/normal-value-setting/delete',
}

const NormalValueSettingService = {
  async GetPatterFormatData() {
    return axios.get(API_LIST.GetPatterFormatData)
  },

  async GetScreenData(params) {
    return axios.post(API_LIST.GetScreenData, params)
  },

  async deleteRadioInfo(params) {
    return axios.post(API_LIST.deleteRadioInfo, params);
  },

  async onSave(params) {
    return axios.post(API_LIST.onSave, params);
  },

}

export default NormalValueSettingService
