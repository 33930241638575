import axios from 'configs/axios'

const apiPaths = {
  index: '/api/radiography-finding-input/radiography-finding-input',
  retrieval: '/api/radiography-finding-input/radiography-finding-input/retrival',
  getDataTable: '/api/radiography-finding-input/radiography-finding-input/get-data-table',

  checkPreFindings: '/api/radiography-finding-input/radiography-finding-input/check-pre-findings',
  updateSubEvent: ' /api/radiography-finding-input/radiography-finding-input/user-action-2',
  deleteSubEvent: '/api/radiography-finding-input/radiography-finding-input/user-action-5',
  updateData: '/api/radiography-finding-input/radiography-finding-input/updateData',
}

const RadiographyFindingInputService = {
  async index(params) {
    return axios.get(apiPaths.index, { params })
  },

  async retrieval(params) {
    return axios.post(apiPaths.retrieval, params)
  },

  async getDataTable(params) {
    return axios.post(apiPaths.getDataTable, params)
  },

  async checkPreFindings(params) {
    return axios.post(apiPaths.checkPreFindings, params)
  },

  async updateSubEvent(params) {
    return axios.post(apiPaths.updateSubEvent, params)
  },

  async deleteSubEvent(params) {
    return axios.post(apiPaths.deleteSubEvent, params)
  },

  async updateData(params) {
    return axios.post(apiPaths.updateData, params)
  },
}

export default RadiographyFindingInputService
