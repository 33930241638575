import axios from "configs/axios";

const apiPaths = {
  captureFile: '/api/associate-insure-qualify-confirm/association-consult-qualify-result-capture/capture-file'
};

const AssociationConsultQualifyResultCaptureService = {

  async captureFile(params) {
    return axios.post(apiPaths.captureFile, params);
  },

};

export default AssociationConsultQualifyResultCaptureService;
