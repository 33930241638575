import React from "react";
import { connect } from "react-redux";
import { LeftOutlined, RightOutlined, MoreOutlined, DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import WS2712016_UseInspect from 'pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS2712016_UseInspect.jsx'
import WS0061015_CheckYesNoNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo.jsx';
import WS0061012_CheckYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061012_CheckYes.jsx';
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Table, Row, Col, Modal, Checkbox, Popconfirm, InputNumber, message } from "antd";
import { registerParts, deleteParts } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";
import { ModalConfirm, ModalError } from "components/Commons/ModalConfirm";
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'
import { getPartsData } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class WS0356021_RegisterPartsData extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_SiteClassificationCode: PropTypes.any,
    Li_site_code: PropTypes.any,
    newFlag: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = '部位マスタ登録';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      newFlag: false,
      initial_site_code: 0,
      site_classification: 0
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    if (!this.props.newFlag) {
      this.getPartsData()
    } else {
      this.setState({
        newFlag: this.props.newFlag,
        site_classification: this.props.Li_SiteClassificationCode
      })
    }
    // this.formRef.current.setFieldsValue(this.props.record)
    // this.formRef.current.setFieldsValue({ Li_site_code: this.props.Li_site_code })
  }

  getPartsData = () => {
    let params = {
      site_classification: this.props.Li_SiteClassificationCode,
      site_code: this.props.Li_site_code
    }
    getPartsData(params)
      .then(res => {
        if (res) {
          let data = res.data[0]
          this.formRef.current?.setFieldsValue({
            site_code: data.site_code,
            search_short_name: data.search_short_name,
            site_name: data.site_name,
            site_address: data.site_address
          })
          this.setState({
            newFlag: this.props.newFlag,
            initial_site_code: this.props.Li_site_code,
            site_classification: this.props.Li_SiteClassificationCode
          });
        }
      })
      .catch()
  }

  onFinish() {
    // let values = this.formRef.current.getFieldsValue(true);
    // let getId = this.props.record ? this.props.record.id : null;
    let params = {
      // ...values,
      // site_classification: this.props.Li_SiteClassificationCode,
      // changeData: values,
      // id: getId,
      // Li_site_code: this.props.Li_site_code,
      newFlag: this.props.newFlag,
      initial_site_code: this.state.initial_site_code,
      site_classification: this.state.site_classification,
      site_code: this.formRef.current?.getFieldValue('site_code'),
      search_short_name: this.formRef.current?.getFieldValue('search_short_name'),
      site_name: this.formRef.current?.getFieldValue('site_name'),
      site_address: this.formRef.current?.getFieldValue('site_address')
    };
    this.registerAction(params);
  }

  registerAction(params) {
    this.setState({ isLoading: true })
    registerParts(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    deleteParts({
      id: this.props.record.id
    })
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="部位登録・修正">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <Col span={7}>
              <Form.Item name="site_code" label="部位コード" {...smGrid}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}>
                <Input
                  maxLength={5}
                  style={{ width: 70, textAlign: 'right' }}
                />
              </Form.Item>

              <Form.Item name="search_short_name" label="検索略名" {...smGrid}>
                <Input type="text" maxLength={30} style={{ width: 300 }} />
              </Form.Item>

              <Form.Item name="site_name" label="部位名称" {...smGrid}>
                <Input type="text" maxLength={30} style={{ width: 300 }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="site_address" label="部位アドレス" {...smGrid}
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}>
                <Input
                  maxLength={2}
                  style={{ width: 40, textAlign: 'right' }}
                />

              </Form.Item>

            </Col>



            <Form.Item >
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>

                {!this.props.newFlag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_SiteClassificationCode === 0) ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.deleteData()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.Li_SiteClassificationCode === 0}
                    >
                      削除
                    </Button>
                  </Popconfirm> : null}

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => { this.onFinish(); }}
                >
                  保存
                </Button>

              </div>
            </Form.Item>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0356021_RegisterPartsData);