import axios from "configs/axios";

const API_LIST = {
  getDataVariousAggregate: "/api/provisional-reservation/reservation-status/get-data-various-aggregate",
  Redisplay: "/api/provisional-reservation/reservation-status/redisplay",
  reservation: "/api/provisional-reservation/reservation-status/reservation",
};

const ReservationStatusService = {
  async getDataVariousAggregate(params) {
    return axios.get(API_LIST.getDataVariousAggregate, { params });
  },
  async Redisplay(params) {
    return axios.post(API_LIST.Redisplay, params);
  },
  async reservation(params) {
    return axios.post(API_LIST.reservation, params);
  },
};

export default ReservationStatusService;
