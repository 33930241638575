import axios from "configs/axios";

const apiPaths = {
  loadScreenData: '/api/optional-info-maintain/optional-info-maintain/load-screen-data',
  searchOption: '/api/optional-info-maintain/optional-info-maintain/search-option',
};

const OptionalInfoMaintainService = {
  async loadScreenData() {
    return axios.get(apiPaths.loadScreenData);
  },

  async searchOption(params) {
    console.log(params)
    return axios.get(apiPaths.searchOption, { params });
  },
};


export default OptionalInfoMaintainService;
