import DunningPrintService from "services/SpecificInsureGuide/InsureGuideInput/DunningPrintService";
import { message } from "antd";

const DunningPrintAction = {
  getScreenData(data) {
    return DunningPrintService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  handleF12UserPrint(data) {
    return DunningPrintService.handleF12UserPrint(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
  print(data) {
    return DunningPrintService.print(data)
  },
  preview(data) {
    return DunningPrintService.preview(data)
  },
};

export default DunningPrintAction;