import { message, Modal } from 'antd'
import TentativeReserveService from 'services/ReservationBusiness/SANAI_TentativeReserve/TentativeReserveService.js'

const TentativeReserveAction = {
  dockTentativeReserve(data) {
    return TentativeReserveService.dockTentativeReserve(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        Modal.error({
          title: res.data.message,
          width: 400
        })
      })
  },

  dockTentativeReserveCancel(data) {
    return TentativeReserveService.dockTentativeReserveCancel(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getTentativeReserve(data) {
    return TentativeReserveService.getTentativeReserve(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getPersonalInfo(data) {
    return TentativeReserveService.getPersonalInfo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getPeriodTime(data) {
    return TentativeReserveService.getPeriodTime(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },


  getAvailableTime(data) {
    return TentativeReserveService.getAvailableTime(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  determineProcess(data) {
    return TentativeReserveService.determineProcess(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  outputExcel(data) {
    return TentativeReserveService.outputExcel(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  downloadExcelFile(data) {
    return TentativeReserveService.downloadExcelFile(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
}
export default TentativeReserveAction
