import axios from 'configs/axios'

const API_LIST = {
  index: '/api/east-fukyo-converse-process/higashifukyo-data-select',
}

const HigashifukyoDataSelectService = {
  async index(params) {
    return axios.get(API_LIST.index, { params })
  },
}

export default HigashifukyoDataSelectService
