import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/insure-guide-init-input/guide-input-proven-sub/getscreendata",
  carriedOutInspectInquiry: "/api/insure-guide-init-input/guide-input-proven-sub/carriedoutinspectinquiry",
  Update: "/api/insure-guide-init-input/guide-input-proven-sub/update",
  delete: "/api/insure-guide-init-input/guide-input-proven-sub/delete",
  delete_f3: "/api/insure-guide-init-input/guide-input-proven-sub/delete_f3",
};

const GuideInputProvenSubService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async carriedOutInspectInquiry(params) {
    return axios.get(API_LIST.carriedOutInspectInquiry, { params });
  },
  async Update(params) {
    return axios.post(API_LIST.Update, params);
  },
  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },
  async delete_f3(params) {
    return axios.post(API_LIST.delete_f3, params);
  },
};

export default GuideInputProvenSubService;
