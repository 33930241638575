import React from "react";
import PropTypes from 'prop-types';
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import moment from 'moment'
import {
  Card, Form, Input, Button, Tooltip
} from "antd";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Text from "antd/lib/typography/Text";

import WS0310004_ContractEditingSub from './WS0310004_ContractEditingSub.jsx';
import WS0311005_MoneyAmountInputSub from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0311005_MoneyAmountInputSub';
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery';
import WS1505001_AgeManageInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0010000_AgeManageInfoMaintain/WS1505001_AgeManageInfoMaintain';

import ContractInspectContent from './WS0309017_ContractItemSub/ContractInspectContent';

import axios from "configs/axios";
import { number_format } from "helpers/CommonHelpers";
import WS0605127_ContractLineItemDisplay from "./WS0605127_ContractLineItemDisplay.jsx";
import Color from "constants/Color.js";
import { saveAndUpdateContractEditingSubAction } from "redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions.js";
import ModalDraggable from "components/Commons/ModalDraggable";
import BillingAddressSettingAction from "redux/basicInfo/ContractInfoMaintain/BillingAddressSetting.actions.js"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0309502_BillingTypeSelect extends React.Component {
  formRef = React.createRef();
  refCicContract = React.createRef();
  refCicOption = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "契約作成";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      isLoadingContractData: false,
      disableFlg: false,
      disableFlgInsurerBilling: false,
      disableFlgOfficeBilling: false,
      contractTermData: {},
    };
  }

  componentDidMount = () => {
    this.props.columnList.map((value, index) => {
      if (value.billing_type == 3) {
        this.setState({
          disableFlg: true
        })
      }
      switch (value.kanji_name) {
        case "保険者":
          this.setState({
            disableFlgInsurerBilling: true
          })
          break;
        case "事業所":
          this.setState({
            disableFlgOfficeBilling: true
          })
        default:
          break;
      }
    })

    this.setState({
      contractTermData: this.props.contractTermData
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  // addbillingAddress = (recordData, billingType) => {
  //   let params = {
  //     contract_type: this.state.contractTermData.contract_type,
  //     contract_office_code: this.state.contractTermData.contract_office_code,
  //     contract_start_date_on: this.state.contractTermData.contract_start_date_on,
  //     contract_number: this.state.contractTermData.contract_number,
  //     medical_exam_course: this.state.contractTermData.medical_exam_course,
  //     setCodeList: this.props.setCodeList
  //   }
  //   BillingAddressSettingAction.addBillingAddress(params)
  //     .then((res) => { this.props.onAddPersonalBilling() })
  // }

  render() {
    return (
      <div className="billing-type-select">
        <Card title='請求種別選択'>
          <div className="box_container">
            <Button
              disabled={this.state.disableFlg}
              onClick={() => {
                if (this.props.onAddPersonalBilling) {
                  this.props.onAddPersonalBilling()
                } else {
                  this.props.onAddBillingAddress()
                }
              }}
            >
              個人
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 800,
                    component: (
                      <WS0246001_InsurerInfoSearchQuery
                        onClickedSelect={({ recordData }) => {
                          if (this.props.onAddInsurerBilling) {
                            this.props.onAddInsurerBilling({
                              recordData: recordData,
                              billingType: 4
                            })
                          } else {
                            this.props.onAddBillingAddress({
                              recordData: recordData,
                              billingType: 4
                            })
                          }
                          this.closeModal()
                        }}
                      />
                    )
                  }
                })
              }}
            >
              保険者
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  childModal: {
                    width: 1200,
                    visible: true,
                    component: (
                      <WS0247001_OfficeInfoRetrievalQuery
                        onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name, recordData }) => {
                          if (this.props.onAddOfficeBilling) {
                            this.props.onAddOfficeBilling({
                              recordData: recordData,
                              billingType: 5
                            })
                          } else {
                            this.props.onAddBillingAddress({
                              recordData: recordData,
                              billingType: 5
                            })
                          }
                          this.closeModal();
                        }}
                      />
                    )
                  }
                });
              }}
            >
              事業所
            </Button>
            <Button
              disabled={this.state.disableFlgInsurerBilling}
              onClick={() => {
                if (this.props.onAddInsurerBilling) {
                  this.props.onAddInsurerBilling({
                    serial_number: 1,
                    billingType: 4
                  })
                } else {
                  this.props.onAddBillingAddress({
                    serial_number: 1,
                    billingType: 4
                  })
                }
              }}
            >
              所属保険者
            </Button>
            <Button
              disabled={this.state.disableFlgOfficeBilling}
              onClick={() => {
                if (this.props.onAddOfficeBilling) {
                  this.props.onAddOfficeBilling({
                    serial_number: 2,
                    billingType: 5
                  })
                } else {
                  this.props.onAddBillingAddress({
                    serial_number: 2,
                    billingType: 5
                  })
                }
              }}
            >
              所属事業所
            </Button>

          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }

}
export default WS0309502_BillingTypeSelect
