/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, Table } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import ConfirmAcquisitionTargetAction from 'redux/CooperationRelated/OcrCaptureStartUp/ConfirmAcquisitionTarget.action'
import WS3108016_IdConsultDateModify from './WS3108016_IdConsultDateModify'
import WS2584019_PersonalInfoInquirySub from '../V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'

class WS3108015_ConfirmAcquisitionTarget extends React.Component {

  static propTypes = {
    Li_GetTargetConfirm: PropTypes.any,
    Lo_StsDataUpdateRun: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '取込対象確認'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRowKeys: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // OCRデータを取得
    this.getOCRData()
  }

  /**
   * OCRデータを取得
   */
  getOCRData = () => {
    let params = {
      GetTargetConfirm: this.props.Li_GetTargetConfirm ?? '',
      ocrData: this.props.ocrData ?? []
    }
    ConfirmAcquisitionTargetAction.getOCRData(params)
      .then((res) => {
        let checkArr = []
        res.forEach((item) => {
          // チェックボックスの状態フラグと使用フラグ
          if (item.acquisition_target && item.acquisitionTargetCheckBox) {
            checkArr.push(item.id)
          }
        })

        this.setState({
          dataSource: res,
          selectedRowKeys: checkArr
        })
      })
  }

  /**
   * 更新処理
   */
  update = () => {
    //更新対象のOCRデータ
    let selectedOcrData = []

    const data = [...this.state.dataSource]
    const selectedRowKeys = [...this.state.selectedRowKeys]

    let bool
    // チェックボックスにチェックがついてるデータだけ取得
    data.forEach((item) => {
      // 選択idに含まれているか？
      bool = selectedRowKeys.includes(item.id)
      if (bool) {
        selectedOcrData.push(item)
      }
    })

    let params = {
      selectedOcrData: selectedOcrData,
      ProcessSelect: this.props.ProcessSelect,
      DateF: this.props.DateF,
      DateT: this.props.DateT,
    }

    ConfirmAcquisitionTargetAction.update(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen(res)
        }
      })
  }

  /**
   * 個人情報照会 モーダル
   */
  showWS2584019_PersonalInfoInquirySub = (personNnum) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personNnum}
          />
        ),
      },
    })
  }

  /**
   * ID・受診日修正 モーダル
   */
  showWS3108016_IdConsultDateModify = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component:
          <WS3108016_IdConsultDateModify
            Li_SerialNum={record.serial_num}
            Li_BranchNum={record.branch_num}
            Li_PersonalNum={parseInt(record.person_num)}
            Li_Date={record.consult_date}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * チェックボックスの１行選択
   * @param {*} record
   * @param {*} selected
   */
  onSelect = (record, selected) => {

    // チェックボックスの使用フラグが0の場合、処理を抜ける
    if (!record.acquisitionTargetCheckBox) return

    let tempArr = [...this.state.selectedRowKeys]

    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id);
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックスの全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      data.forEach((item) => {
        // チェックボックスの使用フラグが1の場合
        if (item.acquisitionTargetCheckBox) {
          tempArr.push(item.id)
        }
      })
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='confirm-acquisition-target'>
        <Card title='取込対象確認'>
          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ y: 560 }}
            rowSelection={{
              selectedRowKeys: this.state.selectedRowKeys,
              onSelect: (record, selected) => {
                // 1行選択
                this.onSelect(record, selected)
              },
              onSelectAll: (selected) => {
                // 全選択
                this.onSelectAll(selected)
              },
              getCheckboxProps: (record) => ({
                // チェックボックスのdisabled設定
                disabled: !(record.acquisitionTargetCheckBox)
              }),
            }}
          >
            <Table.Column
              title='状態'
              dataIndex='status'
              width={50}
              align='center'
              render={(value, record, index) => (
                <div style={{
                  textAlign: 'center',
                  color: Color(record.statusColorNum)?.Foreground
                }}>
                  {record.status}
                </div>
              )}
            />
            <Table.Column
              title='連番'
              width={70}
              dataIndex='serial_num'
              render={(value, record) => {
                const strSerialNum = String(record.serial_num)
                const strBranchNum = (record.branch_num > 0) ? (' - ' + String(record.branch_num)) : ''
                // 連番
                return <div>{strSerialNum + strBranchNum}</div>
              }}
            />
            <Table.Column
              title='帳票'
              dataIndex='form_id'
              width={50}
              render={(value) => (
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />
            <Table.Column
              title='帳票名'
              dataIndex='remarks'
            />
            <Table.Column
              title='受診日'
              dataIndex='consult_date'
              width={100}
              render={(value) => (
                <div>{value?.replace(/-/g, '/')}</div>
              )}
            />
            <Table.Column
              title='個人番号'
              dataIndex='person_num'
              width={100}
              render={(value, record, index) => (
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              )}
            />
            <Table.Column
              width={45}
              align='center'
              render={(value, record) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    // 個人情報照会 モーダル
                    this.showWS2584019_PersonalInfoInquirySub(parseInt(record.person_num))
                  }}
                />
              )}
            />
            <Table.Column
              title='氏名'
              dataIndex='kanji_name'
            />
            <Table.Column
              title='エラー内容'
              dataIndex='error'
              render={(value, record) => (
                <div style={{ color: Color(record.statusColorNum)?.Foreground }}>
                  {value}
                </div>
              )}
            />
            <Table.Column
              width={45}
              align='center'
              render={(value, record, index) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    // ID・受診日修正 モーダル
                    this.showWS3108016_IdConsultDateModify(record)
                  }}
                />
              )}
            />
          </Table>

          <div className='box_button_bottom_right'>
            <Button
              disabled={(this.state.selectedRowKeys.length === 0)} //1つ以上チェックボックスのがつけば活性
              type='primary'
              onClick={() => {
                // 更新処理
                this.update()
              }}
            >
              <span className='btn_label'>
                更新
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS3108015_ConfirmAcquisitionTarget)
