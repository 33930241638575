import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Button, Card, Table, message } from "antd";
import ContractYearSelectSubAction from 'redux/SpecificInsureMaintenance/ContractMasterMaintain/ContractYearSelectSub.actions'
class WS1288001_ContractYearSelectSub extends React.Component {
  static propTypes = {
    Lio_ContractYear: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props);

    // document.title = '契約年度選択SUB';

    this.state = {
      selectedRow: [],
      Lio_ContractYear: null,
      tableData: [],
      isloadingTable: false
    };
  }
  componentDidMount() {
    this.getInit()
  }

  componentDidUpdate(propsPrev) {
    if (this.props != propsPrev) {
      this.getInit()
    }
  }

  getInit() {
    this.setState({ isloadingTable: true })
    ContractYearSelectSubAction.getListData()
      .then(res => {
        this.setState({
          tableData: res ? res : [],
          selectedRow: res[0]
        })
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isloadingTable: false }))
  }

  render() {
    return (
      <div className="contract-year-select-sub">
        <Card className="mb-2" title="契約年度選択">
          <Table
            bordered
            size="small"
            dataSource={this.state.tableData}
            loading={this.state.isloadingTable}
            rowKey={(record) => record.id}
            rowClassName={(record, index) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  this.setState({
                    selectedRow: record,
                    Lio_ContractYear: record.year
                  })
                },
                onDoubleClick: () => {
                  this.props.onFinishScreen({
                    Lio_ContractYear: record.year,
                    recordData: record
                  })
                }
              };
            }}
            scroll={{ y: 500 }}
            pagination={false}
          >
            <Table.Column title="年度" dataIndex="YearDisplay" />
            <Table.Column title="名称" dataIndex="contract_name" />
          </Table>
          <Button type="primary" style={{ float: 'right', marginTop: '1em' }} onClick={() => {
            this.props.onFinishScreen({ Lio_ContractYear: this.state.Lio_ContractYear, recordData: this.state.selectedRow })
          }} >選　択</Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1288001_ContractYearSelectSub);
